import { all, fork } from "redux-saga/effects";
import { watchPaymentCardListSaga } from "./paymentCardsList";
import { watchAddNewCardSaga } from "./savePaymentCard";

export default function* rootPaymentSagas() {
  yield all([
    fork(watchAddNewCardSaga),
    fork(watchPaymentCardListSaga)
  ]);
}
