import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod, fetchMethod } from "../../services/httpService"
import messagePopup from "../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    SPORTS_LIST_START: 'SPORTS_LIST_START',
    SPORTS_LIST_SUCCESS: 'SPORTS_LIST_SUCCESS',
    SPORTS_LIST_FAILED: 'SPORTS_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const sportsListStart = (data) => ({
   type: actionType.SPORTS_LIST_START,
   payload: data
})

export const sportsListSuccess = (data) => ({
    type: actionType.SPORTS_LIST_SUCCESS,
    payload: data
})

export const sportsListFailed = (data) => ({
    type: actionType.SPORTS_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function sportsListReducer(state = INIT_STATE,action){
  switch(action.type){
      case actionType.SPORTS_LIST_START: 
        return {
            ...state,
            loading: true
        }
      case actionType.SPORTS_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            data: action.payload,
            error: null
        }  
      case actionType.SPORTS_LIST_FAILED:
        return {
            ...state,
            loading: false,
            data: null,
            error: action.payload
        }       
      default: return {...state}  
  }
} 

// ===================================================== side effect =============================================

export function* sportsListSaga(action){
   try {
    const response = yield fetchMethod(`/allSports/${action.payload.gender}`,null,'get',false);
    if(response && response.status === 200){
       const allAthletesports = response.data.data ? response.data.data : null
       yield put(sportsListSuccess(allAthletesports));
    } else {
      messagePopup('','Sports List failed','error')   
      yield put(sportsListFailed('Sports List failed')) 
    }
   } catch (error) {
     messagePopup('','Sports List failed','error')   
     yield put(sportsListFailed('Sports List failed')) 
   }
}

export function* watchSportsListSaga(){
    yield takeEvery(actionType.SPORTS_LIST_START, sportsListSaga)
}