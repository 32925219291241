import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { imageDownloadUrl } from '../../../config';
import athleteTestimonialData from './athleteTestimonialData';

const carouselOptions = {
  autoplay: true,
  loop: true,
  nav: false,
  dots: true,
  autoHeight: true,
  margin: 20,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 3,
      margin: 20,
    },
  },
};

const AthleteTestimonials = ({ grayBackground }) => {
  return (
    <section className={grayBackground ? 'testimonial-sec-athlete bg-gray' : 'sfwebfrom-testimonial-sec-athlete'}>
      <div className="container">
        <div className="top-block">
          <h3 className="title">Helping Families Find the Right College Fit</h3>
        </div>
        <OwlCarousel id="testimonial-slider" className="testimonial-slider owl-carousel" {...carouselOptions}>
          {athleteTestimonialData.map((testimonial, index) => (
            <div className="slide-item" key={index}>
              <div className="testimonial-img">
                <img src={`${imageDownloadUrl}/${testimonial.studentPhoto}`} alt={testimonial.studentName} />
              </div>
              <div className="testimonial-detail">
                <div className="short-text">
                  <p>{testimonial.text}</p>
                </div>
                <h5 className="name">{testimonial.studentName}</h5>
                <h6 className="designation">{testimonial.schoolName}</h6>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default AthleteTestimonials;
