import React, { useState } from "react";
import { getUserInfo } from "../../../services/authService";
import BackdropLoader from "../../common/Loader";

export default function SwitchAccountModal(props) {
  const [fields, setFields] = useState({});

  const handleCheckChieldElement = (e, data) => {
    let fieldsT = fields;
    if (e.target.checked) {
      fieldsT["selectedData"] = data;
    } else {
      fieldsT["selectedData"] = null;
    }
    setFields({ ...fieldsT });
  };

  const handleSelectChange = () => {
    if (fields.selectedData) {
      props.switchAthleteStart({
        data: {
          athleteId: +fields.selectedData,
          parentUserId: +getUserInfo().athleteLoggedInId,
        },
        refreshPage: props.onClose,
      });
    }
  };
  return (
    <div
      className="modal fade switch-user-modal show"
      id="SwitchAccountModal"
      style={{ display: "block" }}
    >
      <BackdropLoader open={props.loading} />
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Log into Another Account</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.onClose()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div
              className="switch-AccountUser-list"
            >
              {props.parentAthleteList && props.parentAthleteList.length
                ? props.parentAthleteList.map((item, index) => (
                    <div className="item form-check" key={index}>
                      <input
                        type="checkbox"
                        id={`Athlete${index}`}
                        checked={fields.selectedData === item.id}
                        onChange={(e) => handleCheckChieldElement(e, item.id)}
                      />
                      <label htmlFor={`Athlete${index}`}>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="31.304"
                            height="49.499"
                            viewBox="0 0 31.304 49.499"
                          >
                            <g
                              id="Group_56826"
                              data-name="Group 56826"
                              transform="translate(10649.08 11802.93)"
                            >
                              <g
                                id="user-multiple"
                                transform="translate(-10649.08 -11793.834)"
                              >
                                <path
                                  id="Path_83578"
                                  data-name="Path 83578"
                                  d="M33.7,33.666H31.465v-5.59a5.6,5.6,0,0,0-5.59-5.59V20.25A7.835,7.835,0,0,1,33.7,28.076Z"
                                  transform="translate(-2.397 -2.362)"
                                  fill="#929292"
                                />
                                <path
                                  id="Path_83579"
                                  data-name="Path 83579"
                                  d="M24.61,33.666H22.374v-5.59a5.6,5.6,0,0,0-5.59-5.59H10.076a5.6,5.6,0,0,0-5.59,5.59v5.59H2.25v-5.59a7.835,7.835,0,0,1,7.826-7.826h6.708a7.835,7.835,0,0,1,7.826,7.826Z"
                                  transform="translate(-2.25 -2.362)"
                                  fill="#929292"
                                />
                                <path
                                  id="Path_83580"
                                  data-name="Path 83580"
                                  d="M22.5,2.25V4.486a5.59,5.59,0,1,1,0,11.18V17.9a7.826,7.826,0,0,0,0-15.652Z"
                                  transform="translate(-2.376 -2.25)"
                                  fill="#929292"
                                />
                                <path
                                  id="Path_83581"
                                  data-name="Path 83581"
                                  d="M13.451,4.486a5.59,5.59,0,1,1-5.59,5.59,5.59,5.59,0,0,1,5.59-5.59m0-2.236a7.826,7.826,0,1,0,7.826,7.826A7.826,7.826,0,0,0,13.451,2.25Z"
                                  transform="translate(-2.271 -2.25)"
                                  fill="#929292"
                                />
                              </g>
                              <g
                                id="Group_56821"
                                data-name="Group 56821"
                                transform="translate(-10649.08 -11761.925)"
                              >
                                <path
                                  id="Path_83585"
                                  data-name="Path 83585"
                                  d="M19.025,17.759a.657.657,0,1,0-.933.926l2.437,2.45H2v1.314H20.529L18.092,24.9a.657.657,0,1,0,.933.92l3.993-4.033Z"
                                  transform="translate(7.195 -17.564)"
                                  fill="#929292"
                                />
                                <path
                                  id="Path_83590"
                                  data-name="Path 83590"
                                  d="M10,23h1.97v1.314H10Z"
                                  transform="translate(-7.373 -19.43)"
                                  fill="#929292"
                                />
                                <path
                                  id="Path_83589"
                                  data-name="Path 83589"
                                  d="M6,23H7.314v1.314H6Z"
                                  transform="translate(-0.089 -19.43)"
                                  fill="#929292"
                                />
                                <path
                                  id="Path_83591"
                                  data-name="Path 83591"
                                  d="M6,23H7.314v1.314H6Z"
                                  transform="translate(-6 -19.43)"
                                  fill="#929292"
                                />
                              </g>
                              <g
                                id="Group_56822"
                                data-name="Group 56822"
                                transform="translate(-10649.08 -11802.931)"
                              >
                                <path
                                  id="Path_83585-2"
                                  data-name="Path 83585"
                                  d="M5.993,17.759a.657.657,0,1,1,.933.926l-2.437,2.45H23.018v1.314H4.489L6.926,24.9a.657.657,0,1,1-.933.92L2,21.791Z"
                                  transform="translate(-2 -17.564)"
                                  fill="#929292"
                                />
                                <path
                                  id="Path_83590-2"
                                  data-name="Path 83590"
                                  d="M11.97,23H10v1.314h1.97Z"
                                  transform="translate(15.616 -19.43)"
                                  fill="#929292"
                                />
                                <path
                                  id="Path_83589-2"
                                  data-name="Path 83589"
                                  d="M7.314,23H6v1.314H7.314Z"
                                  transform="translate(16.988 -19.43)"
                                  fill="#929292"
                                />
                                <path
                                  id="Path_83591-2"
                                  data-name="Path 83591"
                                  d="M7.314,23H6v1.314H7.314Z"
                                  transform="translate(22.9 -19.43)"
                                  fill="#929292"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span>{item.fullname}</span>
                      </label>
                    </div>
                  ))
                : null}

              {/* <div className="item form-check">
                <input type="checkbox" id="Athlete2" />
                <label htmlFor="Athlete2">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31.304"
                      height="49.499"
                      viewBox="0 0 31.304 49.499"
                    >
                      <g
                        id="Group_56826"
                        data-name="Group 56826"
                        transform="translate(10649.08 11802.93)"
                      >
                        <g
                          id="user-multiple"
                          transform="translate(-10649.08 -11793.834)"
                        >
                          <path
                            id="Path_83578"
                            data-name="Path 83578"
                            d="M33.7,33.666H31.465v-5.59a5.6,5.6,0,0,0-5.59-5.59V20.25A7.835,7.835,0,0,1,33.7,28.076Z"
                            transform="translate(-2.397 -2.362)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83579"
                            data-name="Path 83579"
                            d="M24.61,33.666H22.374v-5.59a5.6,5.6,0,0,0-5.59-5.59H10.076a5.6,5.6,0,0,0-5.59,5.59v5.59H2.25v-5.59a7.835,7.835,0,0,1,7.826-7.826h6.708a7.835,7.835,0,0,1,7.826,7.826Z"
                            transform="translate(-2.25 -2.362)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83580"
                            data-name="Path 83580"
                            d="M22.5,2.25V4.486a5.59,5.59,0,1,1,0,11.18V17.9a7.826,7.826,0,0,0,0-15.652Z"
                            transform="translate(-2.376 -2.25)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83581"
                            data-name="Path 83581"
                            d="M13.451,4.486a5.59,5.59,0,1,1-5.59,5.59,5.59,5.59,0,0,1,5.59-5.59m0-2.236a7.826,7.826,0,1,0,7.826,7.826A7.826,7.826,0,0,0,13.451,2.25Z"
                            transform="translate(-2.271 -2.25)"
                            fill="#929292"
                          />
                        </g>
                        <g
                          id="Group_56821"
                          data-name="Group 56821"
                          transform="translate(-10649.08 -11761.925)"
                        >
                          <path
                            id="Path_83585"
                            data-name="Path 83585"
                            d="M19.025,17.759a.657.657,0,1,0-.933.926l2.437,2.45H2v1.314H20.529L18.092,24.9a.657.657,0,1,0,.933.92l3.993-4.033Z"
                            transform="translate(7.195 -17.564)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83590"
                            data-name="Path 83590"
                            d="M10,23h1.97v1.314H10Z"
                            transform="translate(-7.373 -19.43)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83589"
                            data-name="Path 83589"
                            d="M6,23H7.314v1.314H6Z"
                            transform="translate(-0.089 -19.43)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83591"
                            data-name="Path 83591"
                            d="M6,23H7.314v1.314H6Z"
                            transform="translate(-6 -19.43)"
                            fill="#929292"
                          />
                        </g>
                        <g
                          id="Group_56822"
                          data-name="Group 56822"
                          transform="translate(-10649.08 -11802.931)"
                        >
                          <path
                            id="Path_83585-2"
                            data-name="Path 83585"
                            d="M5.993,17.759a.657.657,0,1,1,.933.926l-2.437,2.45H23.018v1.314H4.489L6.926,24.9a.657.657,0,1,1-.933.92L2,21.791Z"
                            transform="translate(-2 -17.564)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83590-2"
                            data-name="Path 83590"
                            d="M11.97,23H10v1.314h1.97Z"
                            transform="translate(15.616 -19.43)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83589-2"
                            data-name="Path 83589"
                            d="M7.314,23H6v1.314H7.314Z"
                            transform="translate(16.988 -19.43)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83591-2"
                            data-name="Path 83591"
                            d="M7.314,23H6v1.314H7.314Z"
                            transform="translate(22.9 -19.43)"
                            fill="#929292"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span>Athlete 2</span>
                </label>
              </div>
              <div className="item form-check">
                <input type="checkbox" id="Athlete3" />
                <label htmlFor="Athlete3">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31.304"
                      height="49.499"
                      viewBox="0 0 31.304 49.499"
                    >
                      <g
                        id="Group_56826"
                        data-name="Group 56826"
                        transform="translate(10649.08 11802.93)"
                      >
                        <g
                          id="user-multiple"
                          transform="translate(-10649.08 -11793.834)"
                        >
                          <path
                            id="Path_83578"
                            data-name="Path 83578"
                            d="M33.7,33.666H31.465v-5.59a5.6,5.6,0,0,0-5.59-5.59V20.25A7.835,7.835,0,0,1,33.7,28.076Z"
                            transform="translate(-2.397 -2.362)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83579"
                            data-name="Path 83579"
                            d="M24.61,33.666H22.374v-5.59a5.6,5.6,0,0,0-5.59-5.59H10.076a5.6,5.6,0,0,0-5.59,5.59v5.59H2.25v-5.59a7.835,7.835,0,0,1,7.826-7.826h6.708a7.835,7.835,0,0,1,7.826,7.826Z"
                            transform="translate(-2.25 -2.362)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83580"
                            data-name="Path 83580"
                            d="M22.5,2.25V4.486a5.59,5.59,0,1,1,0,11.18V17.9a7.826,7.826,0,0,0,0-15.652Z"
                            transform="translate(-2.376 -2.25)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83581"
                            data-name="Path 83581"
                            d="M13.451,4.486a5.59,5.59,0,1,1-5.59,5.59,5.59,5.59,0,0,1,5.59-5.59m0-2.236a7.826,7.826,0,1,0,7.826,7.826A7.826,7.826,0,0,0,13.451,2.25Z"
                            transform="translate(-2.271 -2.25)"
                            fill="#929292"
                          />
                        </g>
                        <g
                          id="Group_56821"
                          data-name="Group 56821"
                          transform="translate(-10649.08 -11761.925)"
                        >
                          <path
                            id="Path_83585"
                            data-name="Path 83585"
                            d="M19.025,17.759a.657.657,0,1,0-.933.926l2.437,2.45H2v1.314H20.529L18.092,24.9a.657.657,0,1,0,.933.92l3.993-4.033Z"
                            transform="translate(7.195 -17.564)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83590"
                            data-name="Path 83590"
                            d="M10,23h1.97v1.314H10Z"
                            transform="translate(-7.373 -19.43)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83589"
                            data-name="Path 83589"
                            d="M6,23H7.314v1.314H6Z"
                            transform="translate(-0.089 -19.43)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83591"
                            data-name="Path 83591"
                            d="M6,23H7.314v1.314H6Z"
                            transform="translate(-6 -19.43)"
                            fill="#929292"
                          />
                        </g>
                        <g
                          id="Group_56822"
                          data-name="Group 56822"
                          transform="translate(-10649.08 -11802.931)"
                        >
                          <path
                            id="Path_83585-2"
                            data-name="Path 83585"
                            d="M5.993,17.759a.657.657,0,1,1,.933.926l-2.437,2.45H23.018v1.314H4.489L6.926,24.9a.657.657,0,1,1-.933.92L2,21.791Z"
                            transform="translate(-2 -17.564)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83590-2"
                            data-name="Path 83590"
                            d="M11.97,23H10v1.314h1.97Z"
                            transform="translate(15.616 -19.43)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83589-2"
                            data-name="Path 83589"
                            d="M7.314,23H6v1.314H7.314Z"
                            transform="translate(16.988 -19.43)"
                            fill="#929292"
                          />
                          <path
                            id="Path_83591-2"
                            data-name="Path 83591"
                            d="M7.314,23H6v1.314H7.314Z"
                            transform="translate(22.9 -19.43)"
                            fill="#929292"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span>Athlete 3</span>
                </label>
              </div> */}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn green"
              data-dismiss="modal"
              onClick={handleSelectChange}
              disabled={fields["selectedData"] ? false : true}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
