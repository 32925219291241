import { Fab } from "@mui/material";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";

const GoTopButton = (props) => {
  return (
    <Fab
    
      aria-label="add"
      style={{
        display: "block",
        position: "fixed",
        bottom: "8px",
        right: "75px",
        backgroundColor: "#ffffff",
        color: "#8F8F8F",
        zIndex: 111,
      }}
      onClick={props.goTop}
    >
      <ExpandLessRoundedIcon style={{ fontSize: "3.1875rem" }} />
    </Fab>
  );
};

export default GoTopButton;
