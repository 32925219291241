import { all, fork } from "redux-saga/effects";
import { watchFollowerAthleteListSaga } from './followerAthleteActivity';
import { watchFollowerCollegeCoachCountSaga } from "./followerCollegeCoachActivity";
import { watchFollowerAthleteViewCountSaga } from "./followerAthleteViewActivity";
import { watchFollowerCollegeCoachViewCountSaga } from "./followerCollegeCoachViewActivity";
import { watchFollowerCollegeCoachActivityListSaga } from "./followerCollegeCoachActivityList";
import { watchFollowerAthleteLocationSaga } from "./followerAthleteLocation";
import { watchFollowerCoachStateSaga } from "./followerCoachByState";
import { watchFollowerCoachLevelSaga } from "./followerCoachByLevel";
import { watchViewCoachLevelSaga } from "./viewCoachByLevel";
import { watchViewCoachStateSaga } from "./viewCoachByState";

export default function* rootMessageSagas() {
    yield all([
       fork(watchFollowerAthleteListSaga),
       fork(watchFollowerCollegeCoachCountSaga),
       fork(watchFollowerAthleteViewCountSaga),
       fork(watchFollowerCollegeCoachViewCountSaga),
       fork(watchFollowerCollegeCoachActivityListSaga),
       fork(watchFollowerAthleteLocationSaga),
       fork(watchFollowerCoachStateSaga),
       fork(watchFollowerCoachLevelSaga),
       fork(watchViewCoachStateSaga),
       fork(watchViewCoachLevelSaga)
    ])
}