export const getAllRecruitsListQuery = `
query allRecruitngmasters{
    allRecruitngmasters{
      Recruitngmasters{
        id
        name
        active
      }
    }
  }
`
export const saveRecruitingQuery = `
mutation saveRecruiting($obj :RecruitingInput!){
    saveRecruiting(obj :$obj){
      id
    }
  }
`
export const getAllRecruitingDataQuery = `
query allRecruitings($where : JSON){
    allRecruitings(where : $where){
      Recruitings{
        id
        coachid
        isyes
      }
    }
  }
`
export const getAllCoachGpaRangeQuery = `
query allCoachgparanges{
    allCoachgparanges{
      Coachgparanges{
        gpavalue
        id
      }
    }
    }
`

export const getAllCoachSatValueQuery = `
query allCoachsatranges{
    allCoachsatranges{
      Coachsatranges{
        id
        satvalue
      }
    }
  }
`
export const getAllCoachActValuesQuery = `
query allCoachactranges{
    allCoachactranges{
      Coachactranges{
        id
        actvalue
      }
    }
  }
`

export const saveRequirementsDataQuery = `
mutation saveRequirements($obj : RequirementsInput!){
    saveRequirements(obj : $obj){
    id
    }
    }
`

export const getAllRequirementsQuery = `
query allRequirements($where : JSON){
    allRequirements(where : $where){
      Requirements{
        id
        coachid
        minimumact
        minimumsat
        minimumunweightedgpa
        testoptional
        pitchersvelocitylhp
        pitchersvelocityrhp
        catcherspoptime
        postionofplayer
      }
    }
  }
`

export const getAllCoachCatchersTimeRanges = `
query allCoachcatcherstimeranges{
    allCoachcatcherstimeranges{
      Coachcatcherstimeranges{
        id
        timevalue
      
    }
  }
}
`

export const getAllPitchersVelocitylhpsQuery = `
query allCoachpitchersvelocitylhps{
    allCoachpitchersvelocitylhps{
      Coachpitchersvelocitylhps{
        id
        lhprange
      }
    }
  }
`
export const getAllPitchersVelocityrhpsQuery = `
query allCoachpitchersvelocityrhps{
    allCoachpitchersvelocityrhps{
      Coachpitchersvelocityrhps{
        id
        rhprange
      }
    }
  }
`
export const getAllCoachPositionPlayers = `
query allCoachpositionplayers{
    allCoachpositionplayers{
      Coachpositionplayers{
        id
        postionrange
      }
    }
  }
`

export const getAllProgramsDataQuery = `
query allPrograms($where : JSON){
    allPrograms(where : $where){
      Programs{
        id
        athleticscholarship
        financialpackagedetails
        about
        questionaire
        broucher
        programevideo
      }
    }
  }
`
export const saveProgramsDataQuery = `
mutation saveProgram($obj : ProgramInput!){
    saveProgram(obj : $obj){
      id
    }
  }
`
export const saveGeographicsDataQuery = `
mutation savegeo($data:JSON){
  GeographicCreate(data:$data){
   id 
  }
}
`;

export const deleteQueryData = `
mutation GeographicDestroyAll($where: JSON){
  GeographicDestroyAll(where:$where)
}
`;

export const saveCoachPostData = `
mutation saveCoachpost($obj : CoachpostInput!){
  saveCoachpost(obj : $obj){
    id
  }
}
`
export const updatePositionValueQuery = `
mutation($where:JSON ,$data:JSON){
  RecruitingneedsUpdateAll(where:$where,data:$data)
}
`;

export const getAllGeographicQuery = `
query allGeographics($where: JSON){
  allGeographics(where:$where){
    totalCount
    Geographics{
      id
      active
      isallstate
      regionid
      stateid
    }
  }
}
`;