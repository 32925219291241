import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  COACH_GPA_RANGE_LIST_START: "COACH_GPA_RANGE_LIST_START",
  COACH_GPA_RANGE_SUCCESS: "COACH_GPA_RANGE_SUCCESS",
  COACH_GPA_RANGE_FAILED: "COACH_GPA_RANGE_FAILED",
};

// ============================================ Actions ========================================================
export const coachGpaRangeListStart = (data) => ({
  type: actionType.COACH_GPA_RANGE_LIST_START,
  payload: data,
});

export const coachGpaRangeSuccess = (data) => ({
  type: actionType.COACH_GPA_RANGE_SUCCESS,
  payload: data,
});

export const coachGpaRangeFailed = (data) => ({
  type: actionType.COACH_GPA_RANGE_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function coachGpaRangeListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.COACH_GPA_RANGE_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.COACH_GPA_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.COACH_GPA_RANGE_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* coachGpaRangeListSaga(action) {
  try {
    const response = yield fetchGraphMethod(action.payload.query, null, true);
    if (response && response.status === 200) {
      const allCoachGpaRange = response.data.data.allCoachgparanges.Coachgparanges
        ? response.data.data.allCoachgparanges.Coachgparanges
        : null;
      yield put(coachGpaRangeSuccess(allCoachGpaRange));
    } else {
      messagePopup("", "Coach Gpa Range List failed", "error");
      yield put(coachGpaRangeFailed("Coach Gpa Range List failed"));
    }
  } catch (error) {
    messagePopup("", "Coach Gpa Range List failed", "error");
    yield put(coachGpaRangeFailed("Coach Gpa Range List failed"));
  }
}

export function* watchCoachGpaRangeListSaga() {
  yield takeEvery(actionType.COACH_GPA_RANGE_LIST_START, coachGpaRangeListSaga);
}
