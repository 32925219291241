import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import BaseLevel from '../views/athlet/onboarding/baselevel/BaseLevel';
import Academics from '../views/athlet/onboarding/academics/Academics';
import DOB from '../views/athlet/onboarding/dob/DOB';
import PhysicalMeasurements from '../views/athlet/onboarding/physicalMeasurements/PhysicalMeasurements';
import Login from '../views/auth/login/Login';
import { LoginCoach } from '../views/auth/coachLogin/login';
import CreatePassword from '../views/auth/signup/CreatePassword';
import SignupAthleteStep2 from '../views/auth/signup/SignupAthleteStep2';
import SignupGaurdianStep2 from '../views/auth/signup/SignupGaurdianStep2';
import SignupStep1 from '../views/auth/signup/SignupStep1';
import Recruiting from '../views/athlet/onboarding/recruiting/Recruiting';
import SchoolInformation from '../views/athlet/onboarding/schoolInformation/SchoolInformation';
import PrimaryCoach from '../views/athlet/onboarding/primaryCoach/PrimaryCoach';
import Header from '../layouts/header/Header';
import Footer from '../layouts/footer/Footer';
import Positions from '../views/athlet/onboarding/positions/Positions';
import BatThrow from '../views/athlet/onboarding/batAndThrow/BatThrow';
import PersonalRecords from '../views/athlet/onboarding/personalRecords/PersonalRecords';
import FirstYearVarsity from '../views/athlet/onboarding/firstYearVarsity/FirstYearVarsity';
import ProfilePhoto from '../views/athlet/onboarding/profilePhoto/ProfilePhoto';
import RecruitingProcess from '../views/athlet/onboarding/recruitingprocess/RecruitingProcess';
import Twitter from '../views/athlet/onboarding/twitter/Twitter';
import CongratulateAthelete from '../views/athlet/onboarding/congratulateAthelete/CongratulateAthelete';
import CongratulateParents from '../views/athlet/onboarding/congratulateParents/CongratulateParents';
import SettingAccountProgress from '../views/auth/signup/SettingAccountProgress';
import { getAuthToken } from '../../services/authService';
import Colleges from '../views/athlet/dashboard/colleges/myColleges/Colleges';
import SearchColleges from '../views/athlet/dashboard/colleges/searchColleges/SearchColleges';
import CollegesPreferences from '../views/athlet/dashboard/colleges/preferences/collegesPreferences';
import DashboardFooter from '../layouts/footer/DashboardFooter';
import DashboardHeader from '../layouts/header/DashboardHeader';
import Followers from '../views/athlet/dashboard/homepage/follow/Followers';
import Following from '../views/athlet/dashboard/homepage/follow/Following';
import HomePage from '../views/athlet/dashboard/homepage/posts/HomePage';
import Map from '../views/athlet/dashboard/colleges/Map';
import TrendingProfile from '../views/athlet/dashboard/homepage/TrendingProfile/TrendingProfile';
import SuggestedColleges from '../views/athlet/dashboard/homepage/suggestedColleges/SuggestedColleges';
import DarkEditProfile from '../views/athlet/dashboard/profile/ProfileEdit/DarkEditProfile';
import EditProfile from '../views/athlet/dashboard/profile/ProfileEdit/EditProfile';
import CommitmentStory from '../views/athlet/dashboard/profile/ProfileEdit/CommitmentStory';
import { ClaimProfileStep1 } from '../views/auth/claimProfile/claimProfileStep1/ClaimProfileStep2';
import ClaimProfile from '../views/auth/claimProfile/claimProfileStep1/ClaimProfile';
import NewHomePage from '../views/landingPage/HomePage';
import PrivacyPolicy from '../views/landingPage/PrivacyPolicy';
import TermsOfServices from '../views/landingPage/Terms-of-Services';
import EmailExists from '../views/auth/claimProfile/claimProfileStep2/EmailExists';
import EmailSentNotification from '../views/auth/claimProfile/claimProfileStep2/EmailSentNotification';
import AreYouSure from '../views/auth/claimProfile/claimProfileStep2/AreYouSure';
import Vitals from '../views/athlet/dashboard/editUserProfile/Vitals';
import Contact from '../views/athlet/dashboard/editUserProfile/Contact';
import EnterOtp from '../views/auth/claimProfile/claimProfileStep2/EnterOtp';
import ClaimProfileHeader from '../views/auth/claimProfile/claimProfileStep1/ClaimProfileHeader';
import ClaimYourProfile from '../views/auth/claimProfile/claimProfileStep2/ClaimYourProfile';
import ClaimProfileStep2Header from '../views/auth/claimProfile/claimProfileStep2/ClaimProfileStep2Header';
import ClaimProfileStep2Footer from '../views/auth/claimProfile/claimProfileStep2/ClaimProfileStep2Footer';
import CheckList from '../views/athlet/dashboard/editUserProfile/CheckList';
import Basic from '../views/athlet/dashboard/editUserProfile/Basic';
import ViewCommitments from '../views/athlet/dashboard/viewCommitments/ViewCommitments';
import ActivityPage from '../views/athlet/dashboard/activity/ActivityPage';
import CollegeInsights from '../views/athlet/dashboard/editUserProfile/CollegeInsights';
import GradesScores from '../views/athlet/dashboard/editUserProfile/GradesScores';
import VideoPhotos from '../views/athlet/dashboard/editUserProfile/videoPhoto/VideosPhotos';
import CollegeDetails from '../views/athlet/dashboard/colleges/searchColleges/CollegeDetails';

import VideoPage from '../views/athlet/dashboard/editUserProfile/videoPhoto/VideoPage';
import PhotoPage from '../views/athlet/dashboard/editUserProfile/videoPhoto/PhotoPage';
import ProfessionalEdit from '../views/athlet/dashboard/editUserProfile/videoPhoto/ProfessionalEdit';
import Offers from '../views/athlet/dashboard/editUserProfile/Offer';
import References from '../views/athlet/dashboard/editUserProfile/References';
import Press from '../views/athlet/dashboard/editUserProfile/Press';
import AdditionalSports from '../views/athlet/dashboard/editUserProfile/AdditionalSports';
import Academicss from '../views/athlet/dashboard/editUserProfile/Academics';
import Events from '../views/athlet/dashboard/editUserProfile/events/Events';
import AddEvent from '../views/athlet/dashboard/editUserProfile/events/AddEvent';
import AddEditEventForm from '../views/athlet/dashboard/editUserProfile/events/AddEditEventForm';
import Teams from '../views/athlet/dashboard/editUserProfile/teams/Teams';
import AddTeam from '../views/athlet/dashboard/editUserProfile/teams/AddTeam';
import TeamForm from '../views/athlet/dashboard/editUserProfile/teams/TeamForm';
import AddCoaches from '../views/athlet/dashboard/editUserProfile/teams/AddCoaches';
import ForgotPassword from '../views/auth/forgotPassword/ForgotPassword';
import ChangePassword from '../views/auth/forgotPassword/ChangePassword';
import Activity from '../views/athlet/dashboard/colleges/activity/Activity';
import Matches from '../views/athlet/dashboard/colleges/matches/Matches';
import WelcomeScreenMessage from '../views/athlet/dashboard/welcomeScreenMessage/WelcomeScreenMessage';
import { HowToVideo } from '../views/athlet/dashboard/resources/screens/HowToVideo/HowToVideo';
import MasterClasses from '../views/athlet/dashboard/resources/screens/MasterClass/MasterClasses';
import Webinar from '../views/athlet/dashboard/resources/screens/Webinars/Webinar';
import { ResourceLib } from '../views/athlet/dashboard/resources/screens/ResourceLib/ResourceLib';
import ResourceDetails from '../views/athlet/dashboard/resources/screens/Utils/ResourceDetails';
import Membership from '../views/athlet/dashboard/account/myAccounts/Membership';
import ManageAccess from '../views/athlet/dashboard/account/myAccounts/ManageAccess';
import Message from '../views/athlet/dashboard/Message/Message';
import AdvisorMessage from '../views/athlet/dashboard/Message/AdvisorMessage';
import TrashMessage from '../views/athlet/dashboard/Message/TrashMessage.js';
import SupportMessage from '../views/athlet/dashboard/Message/SupportMessage';
import ReplyMessage from '../views/athlet/dashboard/Message/ReplyMessage';
import AdvisorReplyMessage from '../views/athlet/dashboard/Message/AdvisorReplyMessage';
import SupportReplyMessage from '../views/athlet/dashboard/Message/SupportReplyMessage';
import ComposeHomeMessage from '../views/athlet/dashboard/Message/ComposeHomeMessage';
import ComposeMessage from '../views/athlet/dashboard/Message/ComposeMessage';
import AdvisorComposeMessage from '../views/athlet/dashboard/Message/AdvisorComposeMessage';
import AdvisorReportComposeMessage from '../views/athlet/dashboard/Message/AdvisorReportComposeMessage';
import SupportComposeMessage from '../views/athlet/dashboard/Message/SupportComposeMessage';
import Notification from '../views/athlet/dashboard/Notification/Notification';
import PasswordChange from '../views/athlet/dashboard/account/accountSetting/PasswordChange';
import FAQ from "../views/athlet/dashboard/help/FAQ's/FAQ";
import AskAQuestion from '../views/athlet/dashboard/homepage/rightSideBar/AskAQuestion';
import Stats from '../views/athlet/dashboard/editUserProfile/Stats';
import Payment from '../views/athlet/dashboard/payment/Payment';
import ProgramAndPricing from '../views/athlet/dashboard/account/programAndPricing/ProgramAndPricing';
import PricingPlan from '../views/athlet/dashboard/account/pricingPlan/PricingPlan';
import PricingPlanPage2 from '../views/athlet/dashboard/account/pricingPlan/PricingPlanPage2';
import PricingPlanPage3 from '../views/athlet/dashboard/account/pricingPlan/PricingPlanPage3';
import PricingPlanPage4 from '../views/athlet/dashboard/account/pricingPlan/PricingPlanPage4';
import PaymentInfo from '../views/athlet/dashboard/account/paymentInfo/PaymentInfo';
import ViewMoreExamle from '../views/athlet/dashboard/editUserProfile/videoPhoto/ViewMoreExamle';
import NotificationSetting from '../views/athlet/dashboard/account/accountSetting/NotificationSetting';
import Logout from '../views/auth/login/Logout';
import { CoachHeader } from '../layouts/header/coachHeader';
import { SignUpCoach } from '../views/auth/coachSignup/signup';
import CoachProfilePhoto from '../views/coach/onboarding/profilePhoto/ProfilePhoto';
import { CoachHomePage } from '../views/coach/dashboard/homepage/homepage';
import CoachTwitter from '../views/coach/onboarding/twitter/Twitter';
import CoachMobile from '../views/coach/onboarding/mobile/Mobile';
import CongratulateCoach from '../views/coach/onboarding/congratulateCoach/CongratulateCoach';
import ClaimProfileStep1Coach from '../views/coach/dashboard/ClaimProfile/ClaimProfileStep1Coach';
import ClaimProfileHeaderCoach from '../views/coach/dashboard/ClaimProfile/ClaimProfileHeaderCoach';
import ClaimProfileFooterCoach from '../views/coach/dashboard/ClaimProfile/ClaimProfileFooterCoach';
import MyAccountCoach from '../views/athlet/dashboard/account/accountSetting/MyAccountCoach';
import NotificationSettingsCoach from '../views/athlet/dashboard/account/accountSetting/NotificationSettingsCoach';
import MyRecruits from '../views/coach/dashboard/MyRecruits/MyRecruits';
import RecruitingGuide from '../views/landingPage/recruitingGuide';
import Advertisement from '../views/landingPage/advertisement';
import CollegeLP from '../views/landingPage/college';
import ClubLP from '../views/landingPage/club';
import AboutLP from '../views/landingPage/aboutLP';
import AdvisorLandingPage from '../views/landingPage/advisor';
import Careers from '../views/landingPage/Careers';
import InterviewSurvey from '../views/landingPage/InterviewSurvey';
import HighSchools from '../views/landingPage/HighSchools';
import AthleticScholarship from '../views/landingPage/AthleticScholarship';
import BaseballScholarship from '../views/landingPage/BaseballScholarship';
import CommitsLP from '../views/landingPage/commitslp';
import TestinomialsLP from '../views/landingPage/testinomialsLP';
import AssessmentLP from '../views/landingPage/assessmentLP';
import HighlightLP from '../views/landingPage/highlightLP';
import ClaimProfileStep2 from '../views/auth/claimProfile/claimProfileStep1/ClaimProfileStep1';
import Adminlogin from '../views/auth/adminLogin/adminLogin';
import ContactLP from '../views/landingPage/contact';
import PlaceOrderLP from '../views/landingPage/placeOrder';
import AthleteLP from '../views/landingPage/athleteLP';
import SignedCommitmentStory from '../views/athlet/dashboard/profile/ProfileEdit/SignedCommitmentStory';
import NewClub from '../views/landingPage/newClub';
import { getUserInfo } from '../../services/authService';
import { StatsReports } from '../views/athlet/dashboard/editUserProfile/stats/StatsReports';
import { PricingCheckout } from '../views/athlet/dashboard/account/pricingPlan/pricingCheckout';
import { TermCancellation } from '../views/athlet/dashboard/account/pricingPlan/TermCancellation';
import { WebFormLP } from '../views/landingPage/webFormLP';
import { AdvisorWebFormLP } from '../views/landingPage/advisorWebFormLP';
import { ConsultantForm } from '../views/landingPage/consultant';

export default function Routes() {
  return (
    <Switch>
      <Route
        exact={true}
        path="/map"
        render={(e, props) =>
          getAuthToken() ? (
            <Map {...e} data={props} />
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/admin/login/:emailid/:token"
        render={(e, props) => <Adminlogin {...e} data={props} />}
      />
      {/* ==================================================== Auth Routes =================================== */}
      <Route
        exact={true}
        path="/"
        render={(e, props) =>
          getAuthToken() ? (
            <Redirect to="/homepage"></Redirect>
          ) : (
            <>
              <ClaimProfileHeader />
              {/* <ClaimProfile {...e} data={props} /> */}
              <NewHomePage {...e} data={props} />
              <DashboardFooter />
            </>
          )
        }
      />
      {/* <Route
          exact={true}
          path="/Club"
          render={(e, props) => (
            <>
              <ClaimProfileHeader />
              <NewClub {...e} data={props} />
              <DashboardFooter />
            </>
          )}
        /> */}

      <Route
        exact={true}
        path="/partners"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <NewClub {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <>
              <ClaimProfileHeader />
              <NewClub {...e} data={props} />
              <DashboardFooter />
            </>
          )
        }
      />
      <Route
        exact={true}
        path="/Privacy-Policy"
        render={(e, props) =>
          getAuthToken() && getUserInfo()?.isonboardingdone == 1 ? (
            <>
              <DashboardHeader />
              <PrivacyPolicy {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <>
              <ClaimProfileHeader />
              <PrivacyPolicy {...e} data={props} />
              <DashboardFooter />
            </>
          )
        }
      />
      <Route
        exact={true}
        path="/Terms-of-Services"
        render={(e, props) =>
          getAuthToken() && getUserInfo()?.isonboardingdone == 1 ? (
            <>
              <DashboardHeader />
              <TermsOfServices {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <>
              <ClaimProfileHeader />
              <TermsOfServices {...e} data={props} />
              <DashboardFooter />
            </>
          )
        }
      />
      <Route
        exact={true}
        path="/ClaimProfileCoach"
        render={(e, props) => (
          // getAuthToken() ? (
          //   <Redirect to="/"></Redirect>
          // ) :
          <>
            <ClaimProfileHeaderCoach />
            <ClaimProfileStep1Coach {...e} data={props} />
            <ClaimProfileFooterCoach />
          </>
        )}
      />
      {/* Ask a question route -- Homepage */}
      <Route
        exact={true}
        path="/askaquestion"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <AskAQuestion {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/welcomeScreen"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <HomePage {...e} data={props} />
              {<WelcomeScreenMessage {...e} data={props} />}
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/login"
        render={(e, props) => <Login {...e} data={props} />}
      />

      <Route
        exact={true}
        path="/logout"
        render={(e, props) => <Logout {...e} data={props} />}
      />
      {/* route for forgot password */}
      <Route
        exact={true}
        path="/forgotpassword"
        render={
          (e, props) => (
            // getAuthToken() ? (
            //   <Redirect to="/homepage"></Redirect>
            // ) : (
            <ForgotPassword {...e} data={props} />
          )
          // {...e} data={props}
          //)
        }
      />
      <Route
        exact={true}
        path="/changePassword/:email"
        render={
          (e, props) => (
            // getAuthToken() ? (
            //   <Redirect to="/homepage"></Redirect>
            // ) : (
            <ChangePassword {...e} data={props} />
          )
          // {...e} data={props}
          // )
        }
      />
      <Route
        exact={true}
        path="/myAccount"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <MyAccountCoach {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/coach-homepage"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/myRecruits"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <MyRecruits {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/coach-homepage"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/notificationCoach"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <NotificationSettingsCoach {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/coach-homepage"></Redirect>
          )
        }
      />

      {/* {getCoachRoutes()} */}

      <Route
        exact={true}
        path="/coach-changePassword/:email"
        render={(e, props) => <ChangePassword {...e} data={props} />}
      />

      <Route
        exact={true}
        path="/sportforceAthleteSignup/K8-ao-TJ/step1"
        render={(e, props) =>
          getAuthToken() ? (
            <Redirect to="/homepage"></Redirect>
          ) : (
            <>
              <Header />
              <SignupStep1 {...e} data={props} />
              <Footer />
            </>
          )
        }
      />

      <Route
        exact={true}
        path="/sportforceAthleteSignup/K8-ao-TJ/step2"
        render={(e, props) =>
          getAuthToken() ? (
            <Redirect to="/homepage"></Redirect>
          ) : (
            <>
              <Header />
              <SignupAthleteStep2 {...e} data={props} />
              <Footer />
            </>
          )
        }
      />

      <Route
        exact={true}
        path="/signupstep2/K8-ao-TJ/guardian"
        render={(e, props) =>
          getAuthToken() ? (
            <Redirect to="/homepage"></Redirect>
          ) : (
            <>
              <Header />
              <SignupGaurdianStep2 {...e} data={props} />
              <Footer />
            </>
          )
        }
      />

      <Route
        exact={true}
        path="/K8-ao-TJ/setpassword"
        render={(e, props) =>
          getAuthToken() ? (
            <Redirect to="/homepage"></Redirect>
          ) : (
            <>
              <Header />
              <CreatePassword {...e} data={props} />
              <Footer />
            </>
          )
        }
      />
      <Route
        exact={true}
        path="/setpassword"
        render={(e, props) =>
          getAuthToken() ? (
            <Redirect to="/homepage"></Redirect>
          ) : (
            <>
              <Header />
              <CreatePassword {...e} data={props} />
              <Footer />
            </>
          )
        }
      />
      <Route
        exact={true}
        path="/settingAccount"
        render={(e, props) =>
          getAuthToken() ? (
            <Redirect to="/homepage"></Redirect>
          ) : (
            <>
              <Header />
              <SettingAccountProgress {...e} data={props} />
              <Footer />
            </>
          )
        }
      />
      {/* =============================================== Landing Routes ========================================== */}
      <Route
        exact={true}
        path="/recruitingGuide"
        render={(e, props) => (
          <>
            <RecruitingGuide {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/advertisement"
        render={(e, props) => (
          <>
            <Advertisement {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/clublp"
        render={(e, props) => (
          <>
            <ClubLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/collegelp"
        render={(e, props) => (
          <>
            <CollegeLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/aboutlp"
        render={(e, props) => (
          <>
            <AboutLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/commitslp"
        render={(e, props) => (
          <>
            <CommitsLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/team/baseball/:advisorName"
        render={(e, props) => (
          <>
            <AdvisorLandingPage {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />
      <Route
        exact={true}
        path="/athletes"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <AthleteLP {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <>
              <ClaimProfileHeader />
              <AthleteLP {...e} data={props} />
              <DashboardFooter />
            </>
          )
        }
      />

      <Route
        exact={true}
        path="/careers"
        render={(e, props) => (
          <>
            <Careers {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/interviewSurvey"
        render={(e, props) => (
          <>
            <InterviewSurvey {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/highSchools"
        render={(e, props) => (
          <>
            <HighSchools {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/athleticScholarShip"
        render={(e, props) => (
          <>
            <AthleticScholarship {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/baseballScholarship"
        render={(e, props) => (
          <>
            <BaseballScholarship {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/testinomialslp"
        render={(e, props) => (
          <>
            <TestinomialsLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/assessment"
        render={(e, props) => (
          <>
            <AssessmentLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/highlightlp"
        render={(e, props) => (
          <>
            <HighlightLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/contactlp"
        render={(e, props) => (
          <>
            <ContactLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/contactlp"
        render={(e, props) => (
          <>
            <ContactLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/pricingandprogramslp"
        render={(e, props) => (
          <>
            <PlaceOrderLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/webformlp"
        render={(e, props) => (
          <>
            <WebFormLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />
      <Route
        exact={true}
        path="/advisorWebForm"
        render={(e, props) => (
          <>
            <AdvisorWebFormLP {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />
      <Route
        exact={true}
        path="/consultant"
        render={(e, props) => (
          <>
            <ConsultantForm {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      {/* =============================================== Dashboard Routes ================================================== */}
      <Route
        exact={true}
        path="/dashboard"
        render={(e, props) => <Redirect to="/"></Redirect>}
      />

      {/* =============================================== Onboarding Routes ========================================== */}

      <Route
        exact={true}
        path="/onboarding/positions"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <Positions {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/onboarding/batthrow"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <BatThrow {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/onboarding/academics"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <Academics {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/onboarding/personalRecords"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <PersonalRecords {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/onboarding/dob"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <DOB {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/onboarding/profile"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <ProfilePhoto {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      {/* Recruiting */}

      <Route
        exact={true}
        path="/onboarding/recruiting"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <Recruiting {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      {/* /onboarding/recuritingprocess */}

      <Route
        exact={true}
        path="/onboarding/recruitingProcess"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <RecruitingProcess {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      {/* Twitter */}
      <Route
        exact={true}
        path="/onboarding/instagram"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <Twitter {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/onboarding/baseballLevel"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <BaseLevel {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/onboarding/firstYearVarsity"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <FirstYearVarsity {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/onboarding/physicalMeasurements"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <PhysicalMeasurements {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/onboarding/primaryCoach"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <PrimaryCoach {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/onboarding/schoolInfo"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <SchoolInformation {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/onboarding/congratulateathelete"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <CongratulateAthelete {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/onboarding/congratulateparents"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Header />
              <CongratulateParents {...e} data={props} />
              <Footer />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      {/* =============================================== Colleges Routes ========================================== */}
      <Route
        exact={true}
        path="/colleges"
        render={(e, props) =>
          getAuthToken() ? (
            <Redirect to="/colleges/list"></Redirect>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/colleges/list"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Colleges {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/colleges/search"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <SearchColleges {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/colleges/preferences"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <CollegesPreferences {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/colleges/details/:collegeid/:isactive"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <CollegeDetails />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/colleges/activity"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader data={props} />
              <Activity {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/searchCollege"
        render={(e, props) => <SearchColleges {...e} data={props} />}
      ></Route>

      {/* =============================================== Resource Routes ========================================== */}
      {/* <Route
          exact={true}
          path="/resources"
          render={(e, props) =>
            getAuthToken() ? (
              <Redirect to="/resources/HowToVideo"></Redirect>
            ) : (
              <Redirect to="/login"></Redirect>
            )
          }
        /> */}
      {/*<Route*/}
      {/*  exact={true}*/}
      {/*  path="/resources/HowToVideo"*/}
      {/*  render={(e, props) =>*/}
      {/*    getAuthToken() ? (*/}
      {/*      <>*/}
      {/*        <Redirect to="/resources/HowToVideo/Stats"></Redirect>*/}
      {/*      </>*/}
      {/*    ) : (*/}
      {/*      <Redirect to="/login"></Redirect>*/}
      {/*    )*/}
      {/*  }*/}
      {/*/>*/}
      <Route
        exact={true}
        path="/resources/MasterClasses"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Redirect to="/resources/MasterClasses/Stats"></Redirect>
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/resources/MasterClasses/Stats"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <MasterClasses {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/resources/Webinars"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <Redirect to="/resources/Webinars/Stats"></Redirect>
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/resources/Webinars/Stats"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Webinar {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/resources/ResourceLibrary"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ResourceLib {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/resources/HowToVideo"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <HowToVideo {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/resources/detailsPage/:id"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ResourceDetails {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/resources/detailsPage"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ResourceDetails {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      {/* ==================================================== Message ROUTES ================================== */}

      <Route
        exact={true}
        path="/message/coach"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Message {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/message/advisor"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <AdvisorMessage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/message/support"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <SupportMessage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/message/archive"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <TrashMessage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/message/coach/replyMessage"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ReplyMessage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/message/advisor/replyMessage"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <AdvisorReplyMessage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/message/support/replyMessage"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <SupportReplyMessage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/message/coach/composeMessage/:coachid/:collegeid"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ComposeHomeMessage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/message/coach/composeMessage"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ComposeMessage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/message/advisor/composeMessage"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <AdvisorComposeMessage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/message/advisor/composeMessage/:advisorid"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <AdvisorReportComposeMessage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/message/support/composeMessage"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <SupportComposeMessage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      {/* ==================================================== Notification ROUTES ================================== */}

      <Route
        exact={true}
        path="/notification"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Notification {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      {/* ==================================================== HOME ROUTES ================================== */}

      <Route
        exact={true}
        path="/homepage"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <HomePage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/homepage/followers"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Followers {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/homepage/following"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Following {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      {/* ================================== Matured Profile Routes ================================================ */}
      <Route
        exact={true}
        path="/homepage/suggestedcolleges"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <SuggestedColleges {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/homepage/trendingProfile"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <TrendingProfile {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      {/*this route is for when user click on profile on nav bar*/}
      <Route
        exact={true}
        path="/athlete/:athlete_U_ID_Type"
        render={(e, props) => (
          <>
            {/*<DashboardHeader/>*/}
            <DarkEditProfile {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />
      {/*this route is for when user click on profile on nav bar*/}
      <Route
        exact={true}
        path="/profile/:isToken/"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <DarkEditProfile {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      {/*this route is for when user search any athlete and click on searched athlete*/}
      <Route
        exact={true}
        path="/publicProfile/:isToken/:id"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <DarkEditProfile
                {...e}
                publicProfile={true}
                isPublic={true}
                data={props}
              />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      {/*this route is for sharing profile from share comp*/}
      <Route
        exact={true}
        path="/publicView/:isToken/:id/athlete/:fullName"
        render={(e, props) => (
          <>
            <ClaimProfileHeader />
            <DarkEditProfile
              {...e}
              publicProfile={true}
              isPublic={true}
              data={props}
              checkRemovedAthlete={true}
              isVisitor={true}
            />
            <DashboardFooter />
          </>
        )}
      />
      {/*this route is for when loggedin user see's his/her public view*/}
      <Route
        exact={true}
        path="/publicView/:isToken/:id"
        render={
          (e, props) => (
            // getAuthToken() ? (
            <>
              <ClaimProfileHeader />
              <DarkEditProfile
                {...e}
                publicProfile={true}
                isPublic={false}
                isVisitor={true}
                data={props}
              />
              <DashboardFooter />
            </>
          )
          // ) : (
          //   <Redirect to="/login"></Redirect>
          // )
        }
      />
      {/*this route when unlogged user claim his/her profile*/}
      <Route
        exact={true}
        path="/claimProfile/:isToken/:id/:isCPType"
        render={
          (e, props) => (
            // getAuthToken() ? (
            <>
              <ClaimProfileHeader />
              <DarkEditProfile
                {...e}
                publicProfile={true}
                isPublic={false}
                data={props}
              />
              <DashboardFooter />
            </>
          )
          // ) : (
          //   <Redirect to="/login"></Redirect>
          // )
        }
      />

      <Route
        exact={true}
        path="/viewCommitment"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <EditProfile {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/commitmentstory/:collegeid/:userid"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <CommitmentStory {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/publicCommitmentStory/:collegeid/:userid"
        render={(e, props) => (
          <>
            <ClaimProfileHeader />
            <CommitmentStory {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/signedcommitmentstory/:collegeid/:userid"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <SignedCommitmentStory {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/publicSignedCommitmentStory/:collegeid/:userid"
        render={(e, props) => (
          <>
            <ClaimProfileHeader />
            <SignedCommitmentStory {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      {/* ================================== Claim Profile Routes ================================================ */}
      <Route
        exact={true}
        path="/claimProfile"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <ClaimProfileHeader />
              <ClaimProfile {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/getscouted"
        render={(e, props) => (
          <>
            <ClaimProfileHeader />
            <ClaimProfileStep1 {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/claimProfileSearch"
        render={(e, props) => (
          <>
            <ClaimProfileHeader />
            <ClaimProfileStep2 {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/claimprofileheader"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <ClaimProfileHeader {...e} data={props} />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      {/* ================================== Claim Profile (Modals) Routes ================================================ */}
      <Route
        exact={true}
        path="/emailsent"
        render={(e, props) => (
          <>
            <EmailSentNotification {...e} data={props} />
          </>
        )}
      />

      <Route
        exact={true}
        path="/emailexists"
        render={(e, props) => <EmailExists {...e} data={props} />}
      />
      <Route
        exact={true}
        path="/areyousure"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <AreYouSure {...e} data={props} />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      {/* ================================== editUserProfile Routes ================================================ */}

      <Route
        exact={true}
        path="/claimyourprofile"
        render={(e, props) => (
          <>
            <ClaimProfileStep2Header />
            <ClaimYourProfile {...e} data={props} />
            <ClaimProfileStep2Footer />
          </>
        )}
      />

      {/* ================================== editUserProfile Routes ================================================ */}

      <Route
        exact={true}
        path="/vitals"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Vitals {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/contact"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Contact {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/basic"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Basic {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/checklist"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <CheckList {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/collegeinsights"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <CollegeInsights {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/enterotp"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <EnterOtp {...e} data={props} />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/activityPage"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ActivityPage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/commitment"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ViewCommitments {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/collegeInsights"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <CollegeInsights {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/gradesScores"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <GradesScores {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/videoPhotos"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <VideoPhotos {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/videoPhotos/Video"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <VideoPage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/videoPhotos/Photos"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <PhotoPage {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/videoPhotos/Professional"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ProfessionalEdit {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/Offers"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Offers {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/Stats"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Stats {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/St_view"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Stats {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/St_view/Reports"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <StatsReports {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/Stats/Reports"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <StatsReports {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/References"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <References {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/press"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Press {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/addtionalSports"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <AdditionalSports {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/academics"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Academicss {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/events"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Events {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/events/add-event"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <AddEvent {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/events/event-form/:eventId"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <AddEditEventForm {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/teams"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Teams {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/teams/add-team"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <AddTeam {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        // path="/teams/teams-form/:name/:cityName"
        path="/teams/teams-form"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <TeamForm {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      <Route
        exact={true}
        path="/add-coaches"
        render={(e, props) => {
          return getAuthToken() ? (
            <>
              <DashboardHeader />
              <AddCoaches {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          );
        }}
      />

      {/* =======================================Account========================================== */}
      <Route
        exact={true}
        path="/passwordChange"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <PasswordChange {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/notification-settings"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <NotificationSetting {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/"></Redirect>
          )
        }
      />

      {/* ---------------------Accounts Routes ----------------- */}
      <Route
        exact={true}
        path="/membership"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Membership {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/manageAccess"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ManageAccess {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/programAndPricing"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ProgramAndPricing {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/plans"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <ClaimProfileHeader />
              <PricingPlan {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <>
              <ClaimProfileHeader />
              <PricingPlan {...e} data={props} />
              <DashboardFooter />
            </>
          )
        }
      />

      <Route
        exact={true}
        path="/sportforcePricingPlan/SPP8-ao-TJ/step2"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <ClaimProfileHeader />
              <PricingPlanPage2 {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <>
              <ClaimProfileHeader />
              <PricingPlanPage2 {...e} data={props} />
              <DashboardFooter />
            </>
          )
        }
      />

      <Route
        exact={true}
        path="/sportforcePricingPlan/SPP8-ao-TJ/step3"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <ClaimProfileHeader />
              <PricingPlanPage3 {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <>
              <ClaimProfileHeader />
              <PricingPlanPage3 {...e} data={props} />
              <DashboardFooter />
            </>
          )
        }
      />

      <Route
        exact={true}
        path="/sportforcePricingPlan/SPP8-ao-TJ/step4"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <ClaimProfileHeader />
              <PricingPlanPage4 {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <>
              <ClaimProfileHeader />
              <PricingPlanPage4 {...e} data={props} />
              <DashboardFooter />
            </>
          )
        }
      />
      <Route
        exact={true}
        path="/programAndPricingDetail"
        render={(e, props) => (
          <>
            <DashboardHeader />
            <PricingPlanPage3 {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />
      <Route
        exact={true}
        path="/programAndPricingCheckout/:priceID"
        render={(e, props) => (
          <>
            <DashboardHeader />
            <PricingCheckout {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />
      <Route
        exact={true}
        path="/termsCancellationPolicy"
        render={(e, props) => (
          <>
            <DashboardHeader />
            <TermCancellation {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/payment/:redirectUrl"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <Payment {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/FAQ"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <FAQ {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/paymentInfo"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <PaymentInfo {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />

      <Route
        exact={true}
        path="/view-more-example"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader />
              <ViewMoreExamle {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
      {/* ==================================================== Coach Routes =================================== */}

      <Route
        exact={true}
        path="/coach-login"
        render={(e, props) => (
          <>
            <CoachHeader />
            <LoginCoach {...e} data={props} />
            <DashboardFooter />
          </>
        )}
      />

      <Route
        exact={true}
        path="/coach-signup"
        render={(e, props) => (
          <>
            <SignUpCoach {...e} data={props} />
          </>
        )}
      />

      <Route
        exact={true}
        path="/coach-homepage"
        render={(e, props) => (
          <>
            <DashboardHeader />
            <CoachHomePage {...e} data={props} />
          </>
        )}
      />

      <Route
        exact={true}
        path="/coach-onboarding/Profile"
        render={(e, props) => (
          <>
            <Header />
            <CoachProfilePhoto {...e} data={props} />
            <Footer />
          </>
        )}
      />
      <Route
        exact={true}
        path="/coach-onboarding/Twitter"
        render={(e, props) => (
          <>
            <Header />
            <CoachTwitter {...e} data={props} />
            <Footer />
          </>
        )}
      />
      <Route
        exact={true}
        path="/coach-onboarding/Mobile"
        render={(e, props) => (
          <>
            <Header />
            <CoachMobile {...e} data={props} />
            <Footer />
          </>
        )}
      />
      <Route
        exact={true}
        path="/coach-onboarding/congratulatecoach"
        render={(e, props) => (
          <>
            <Header />
            <CongratulateCoach {...e} data={props} />
            <Footer />
          </>
        )}
      />
      <Route
        exact={true}
        path="/colleges/matches"
        render={(e, props) =>
          getAuthToken() ? (
            <>
              <DashboardHeader data={props} />
              <Matches {...e} data={props} />
              <DashboardFooter />
            </>
          ) : (
            <Redirect to="/login"></Redirect>
          )
        }
      />
    </Switch>
  );
}
