import React, { useState, useEffect } from "react";
import ResourcesContainer from "../ResourcesContainer";
import { connect, useDispatch, useSelector } from "react-redux";
import { getResourceLibraryQuery } from "./ResourceLibraryQuery";
import Pagination from 'react-js-pagination';
import PlayButton from "../Utils/PlayButton";
import VideoPopup from "../Utils/VideoPopup";
import ReactPaginate from 'react-paginate'
import swal from "sweetalert";
import { Dialog } from "@mui/material";
import {getUserInfo} from "../../../../../../../services/authService";
import {fetchGraphMethod} from "../../../../../../../services/httpService";
import {getAllMembershipsQuery} from "../../../account/myAccounts/membershipQuery";
import {getHowToVideoListQuery} from "../HowToVideo/HowtoVideoQuery";
import {vidURL} from "../../../../../../common/thumbnail";
export const ResourceLib = (props) => {
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [perPage, setPerPage] = useState(10),
    [openModal, setOpenModal] = useState(false),
    [offset, setOffset] = useState(10),
    [currentPage, setCurrentPage] = useState(1),
    [isSearch, setIsSearch] = useState(true),
    [searchValue, setSearchValue] = useState(null),
    [goNum, setGoNum] = useState(10),
    [isPremium, setIsPremium] = useState(false),
    RefLib = useSelector(state => state),
    [playTutorialVideo, setPlayTutorialVideo] = useState([]),
    [vidData, setVidData] = useState([]),
    [isVideo, setIsVideo] = useState(false),
    dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: "GET_RESOURCE_LIBRARY_START",
      payload: {
        query: getResourceLibraryQuery,
        variables: { where: {}, first: offset, last: 10 }
      }
    })
    window.scrollTo(0, 0)
    isAthletePre()
  }, [])
  useEffect(()=>{
    getPlaytutorial()
  },[])
  const getPlaytutorial = async () =>{
    const resData = await fetchGraphMethod(getHowToVideoListQuery, {where:{videophotosuploadedtype:"resourcelibrary",isplaytutorial:1}}, true)
    let videoData = ""
    if (resData?.status == 200){
      videoData = resData?.data?.data?.allUploadedvideoandphotos?.Uploadedvideoandphotos?.length > 0 ?
          resData?.data?.data?.allUploadedvideoandphotos?.Uploadedvideoandphotos?.[0] : []
      setPlayTutorialVideo(videoData)
    }else {
      setPlayTutorialVideo(videoData)
    }
  }
  const searchHandle = e => {
    let value = !!e?.target?.value ? e?.target?.value : "" || ""
    if (value.length > 3) {
      dispatch({
        type: "GET_RESOURCE_LIBRARY_START",
        payload: {
          query: getResourceLibraryQuery,
          variables: {
            where: {
              and: [
                {
                  or: [
                    { linkname: { like: `%${value}%` } },
                    { description: { like: `%${value}%` } },
                  ]
                }
              ]
            },
            first: 10, last: 10
          }
        }
      })
      setIsSearch(false)
    } else {
      dispatch({
        type: "GET_RESOURCE_LIBRARY_START",
        payload: {
          query: getResourceLibraryQuery,
          variables: { where: {}, first: 10, last: perPage }
        }
      })
      setIsSearch(true)
    }
    setSearchValue(value)
  },
    handlePagination = (page_num) => {
      setCurrentPage(page_num)
      const offset_num = page_num * perPage
      setOffset(offset_num)
      dispatch({
        type: "GET_RESOURCE_LIBRARY_START",
        payload: {
          query: getResourceLibraryQuery,
          variables: { where: {}, first: offset_num, last: perPage }
        }
      })
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 300)
    },
    pagNum = (e) => {
      if (e <= 0) return swal({ text: "Invalid Number", icon: 'error' })
      setPerPage(e)
      // const value = pagination(e)
    }
  const handleClose = (e) => {
    e.preventDefault();
    setShowVideoPopup(false);
    setIsVideo(false)
  };
  const handleOpen = () => {
    isVideoPop(playTutorialVideo)
    // setShowVideoPopup(playTutorialVideo);
  };
  const isVideoPop = (vid) => {
    if (!!vid?.videolink){
      vid['videolink'] = vidURL(vid?.videolink)
      setVidData(vid)
    }else{
      setVidData(vid)
    }
    setIsVideo(true)
  }
  const handlePremiumResources = () => {
    setOpenModal(!openModal);
  //   swal({ 
  //     text: "To Access Premium Resources, upgrade yourself to premium member.",
  //     buttons: {
  //     clickHere: {
  //       text: "Click here",
  //       value: "clickHere"
  //     },
  //     learnMore: {
  //       text: "Learn More",
  //       value: "learnMore"
  //     }
  //   },
  //   className: "premiumResources"
  // })
  }
  const isAthletePre = async () =>{
    const finalObj={
      where:{
        userid: getUserInfo()?.id ?? 0,
        active: 1
      }
    }
    const _res = await fetchGraphMethod(getAllMembershipsQuery, finalObj, true)
    if(_res?.status == 200){
      const isPre = !!_res?.data?.data?.allMemberships?.Memberships ?
          _res?.data?.data?.allMemberships?.Memberships?.length > 0 ? true : false : false
      setIsPremium(isPre)
    }else{
      setIsPremium(false)
    }
  }
  return (
    <ResourcesContainer>
      <VideoPopup
        isResource={true}
        show={isVideo}
        close={handleClose}
        vidData={vidData}
      />
      <Dialog open={openModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  handlePremiumResources()
                }
              }}
              className="matDialogCustom"
      >
      <div class="resources-video-modal" id="ResourceLibraryModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
          <button onClick={() => handlePremiumResources()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
          </div>
          <div class="modal-body">
            <p>To Access Premium Resources, upgrade yourself to premium member. </p>
            <div class="button-wrap">
              <button class="btn blue" data-dismiss="modal" disabled={true}>Click here</button>
              <button class="btn gray" data-dismiss="modal" onClick={()=>{
                window.open(
                  'https://sfrglobal.helpscoutdocs.com/collection/70-faqs',
                  '_blank'
                );
                handlePremiumResources()
              }}>Learn More</button>
            </div>
          </div>
        </div>
      </div>
  </div>
      </Dialog>
      <div className="tab-content resources-box-wrap">
        <div className="tab-pane active">
            <div class="resources-content-flex">
                    <p>Join thousands of athletes, parents, and coaches that have benefitted from our resource library.</p>
                    <PlayButton open={handleOpen} />
                  </div>
          <ul className="resource-library-list">
            <li>
              <h4>Member Resources</h4>
              <p>Access key NCAA, NAIA, JC, Academic (SAT/ACT), & Financial Aid resources and links.</p>
              <button class="btn gray" onClick={()=>{
                //  window.open(
                //   'https://sfrglobal.helpscoutdocs.com/collection/43-member-resources',
                //   '_blank'
                // );
                window.open(
                    'https://sfrglobal.helpscoutdocs.com',
                    '_blank'
                )
              }}>Click to Access</button>
            </li>
            <li>
              <h4>Premium Resources</h4>
              <p>Access proven email templates for college outreach, coach replies, and DM outreach.</p>
              <button class="btn gray" onClick={() =>{
                isPremium ? window.open('https://sportsforce.helpscoutdocs.com','_blank') : handlePremiumResources()
              }}>Click to Access</button>
            </li>
            <li>
              <h4>FAQ's</h4>
              <p>Access FAQ's covering Recruiting 101, SportsForce Programs, and Why SportsForce is different.</p>
              <button class="btn gray" onClick={()=>{
               window.open(
                'https://general-faqs.helpscoutdocs.com',
                '_blank'
              );
              }}>Click to Access</button>
            </li>
          </ul>
        </div>
        {/* <div className="paginationBox">
          <div className="perPage">
            <input
                style={{width:"45px"}}
              type="number"
              onChange={(e)=>
                  pagNum(+e.target.value)
              }
            />
            <label>Per Page</label>
          </div>
          <div className="pageNav">
            <Pagination
                innerClass='pages pagination'
                itemClass='pagination'
                linkClass='page-link'
                activePage={currentPage}
                activeClass={"active"}
                itemsCountPerPage={perPage}
                totalItemsCount={RefLib?.athlete?.dashboard?.resources?.resourceLibrary?.data?.totalCount || 0}
                pageRangeDisplayed={5}
                onChange={handlePagination}
            />
          </div>
          <div className="goBox">
            <input
              type="text"
              onChange={(e) => setGoNum(e.target.value)}
            />
            <a
            >
              <span onClick={()=>handlePagination(goNum)}>Go </span>
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4.568"
                  height="7.99"
                  viewBox="0 0 4.568 7.99"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <path
                    className="a"
                    d="M1.377,3.993,4.4.972a.569.569,0,0,0,0-.806.576.576,0,0,0-.809,0L.166,3.589a.57.57,0,0,0-.017.787l3.44,3.447A.571.571,0,0,0,4.4,7.017Z"
                    transform="translate(4.568 7.99) rotate(180)"
                  />
                </svg>
              </span>
            </a>
          </div>
        </div> */}
      </div>
    </ResourcesContainer>
  );
};
