import React, { useState, useEffect } from "react";
import "../../../../../../assets/css/stylesheet.scss";
import "../assets/css/athleteDashfollow.scss";
import { Link } from "react-router-dom";
import RightSideBar from "../rightSideBar/RightSideBar";
import LeftSideBar from "../leftSideBar/LeftSideBar";
import FollowListRow from "./FollowListRow";
import { getFollowingListStart } from "../../../../../../redux/athlete/dashboard/homepage/followingsList";
import { connect } from "react-redux";
import { getUserInfo } from "../../../../../../services/authService";
import { getFollowingsQuery } from "./FollowQuery";
import BackdropLoader from "../../../../../common/Loader";
import { unfollowUserStart } from "../../../../../../redux/athlete/dashboard/homepage/followUnfollow";
import { getAllAthletesDataQuery } from "../../../../../common/CommonQuery";
import { athletesDetailsStart } from "../../../../../../redux/common/athletesDetails";
import { trendingProfileListStart } from "../../../../../../redux/athlete/dashboard/homepage/trendingProfileList";

function Following(props) {
  const [followingsList, setFollowingsList] = useState(null);
  useEffect(() => {
    getFollowingList();
  }, []);
  const getFollowingList = () => {
    props.getFollowingListStart({
      query: getFollowingsQuery,
      variables: {
        obj: {
          followerid: getUserInfo() ? getUserInfo().id : 0,
          isfollowed: 1,
        },
      },
    });
  };
  useEffect(() => {
    if (props.followingsList.data) {
      const tempData = props.followingsList.data;
      setFollowingsList(tempData);
    }
  }, [props.followingsList.data]);

  const handleUnFollow = (e, data) => {
    e.preventDefault();
    props.unfollowUserStart({
      data: {
        followerId: `${data.followerid}`,
        followingId: `${data.followingid}`,
      },
      refreshPage,
      follow: false,
    });
  };

  const refreshPage = () => {
    getFollowingList();
    getUserData();
    refreshTrendingProfile();
  };
  const getUserData = () => {
    props.athleteListStart({
      query: getAllAthletesDataQuery,
      variables: {
        obj: {
          id: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  };
  const refreshTrendingProfile = () => {
    props.trendingProfileListStart({ userid: getUserInfo().id });
  };

  //func used for scrolling the page in top
  useEffect(()=>{
    setTimeout(()=>{
      window?.scrollTo(0,0)
    },1200)
  },[])
  return (
    <div>
      <BackdropLoader
        open={props.followingsList.loading || props.followUnfollow.loading}
      />
      <div className="athleteDashFollowingSection">
        <div className="container">
          <div className="flexBox">
            <div className="leftDataBox">
              <LeftSideBar />
            </div>
            <div className="dataBox">
              <div className="backHome">
                <Link to="/homepage">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 447.243 447.243"
                    >
                      <path d="M420.361 192.229a31.967 31.967 0 00-5.535-.41H99.305l6.88-3.2a63.998 63.998 0 0018.08-12.8l88.48-88.48c11.653-11.124 13.611-29.019 4.64-42.4-10.441-14.259-30.464-17.355-44.724-6.914a32.018 32.018 0 00-3.276 2.754l-160 160c-12.504 12.49-12.515 32.751-.025 45.255l.025.025 160 160c12.514 12.479 32.775 12.451 45.255-.063a32.084 32.084 0 002.745-3.137c8.971-13.381 7.013-31.276-4.64-42.4l-88.32-88.64a64.002 64.002 0 00-16-11.68l-9.6-4.32h314.24c16.347.607 30.689-10.812 33.76-26.88 2.829-17.445-9.019-33.88-26.464-36.71z" />
                    </svg>
                  </span>
                  <span>Back to Homepage</span>
                </Link>
              </div>
              <div className="tabsBox">
                <div className="tabFlex">
                  <div className="tab ">
                    <Link to="/homepage/followers">Followers</Link>
                  </div>
                  <div className="tab selected">
                    <Link to="/homepage/following">Following</Link>
                  </div>
                </div>
                <div className="followingDataBox">
                  {followingsList && followingsList.length ? (
                    followingsList.map((item, index) => (
                      <FollowListRow
                        data={item}
                        key={index}
                        handleUnFollow={handleUnFollow}
                      />
                    ))
                  ) : (
                    <span>No following found</span>
                  )}
                </div>
                <div className="loadMoreBox">
                  <a href="">Load more feeds</a>
                </div>
              </div>
            </div>
            <div className="rightDataBox">
              <RightSideBar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    followingsList: state.athlete.dashboard.home.followingsList,
    followUnfollow: state.athlete.dashboard.home.followUnfollow,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFollowingListStart: (data) => dispatch(getFollowingListStart(data)),
    unfollowUserStart: (data) => dispatch(unfollowUserStart(data)),
    athleteListStart: (data) => dispatch(athletesDetailsStart(data)),
    trendingProfileListStart: (data) =>
      dispatch(trendingProfileListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Following);
