import { put, takeEvery } from "redux-saga/effects"
import { fetchMethod } from "../../../../services/httpService"
import messagePopup from "../../../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    SEARCH_STATE_BY_REGION_START: 'SEARCH_STATE_BY_REGION_START',
    SEARCH_STATE_BY_REGION_SUCCESS: 'SEARCH_STATE_BY_REGION_SUCCESS',
    SEARCH_STATE_BY_REGION_FAILED: 'SEARCH_STATE_BY_REGION_FAILED'
}

// ============================================ Actions ========================================================
export const searchStateByRegionStart = (data) => ({
   type: actionType.SEARCH_STATE_BY_REGION_START,
   payload: data
})

export const searchStateByRegionSuccess = (data) => ({
    type: actionType.SEARCH_STATE_BY_REGION_SUCCESS,
    payload: data
})

export const searchStateByRegionFailed = (data) => ({
    type: actionType.SEARCH_STATE_BY_REGION_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function searchStateByRegionReducer(state = INIT_STATE,action){
  switch(action.type){
      case actionType.SEARCH_STATE_BY_REGION_START: 
        return {
            ...state,
            loading: true
        }
      case actionType.SEARCH_STATE_BY_REGION_SUCCESS:
        return {
            ...state,
            loading: false,
            data: action.payload,
            error: null
        }  
      case actionType.SEARCH_STATE_BY_REGION_FAILED:
        return {
            ...state,
            loading: false,
            data: null,
            error: action.payload
        }       
      default: return {...state}  
  }
} 

// ===================================================== side effect =============================================

export function* searchStateByRegionSaga(action){
   try {
    const response = yield fetchMethod(
        `/api/States/SearchStateByRegion`,
        action.payload.data,
        "post",
        true
        );
    if(response && response.status === 200){
       const searchStateByRegion = response.data.response.data;
       let statesArray = searchStateByRegion ? searchStateByRegion.map((state)=> {
           return state.stateName
       }) : null;
    if(action.payload.nextRoute){
        action.payload.nextRoute(action.payload.checked, statesArray );
    } 
       yield put(searchStateByRegionSuccess(searchStateByRegion));
       
    } else {
      messagePopup('','Search State by region api failed','error')   
      yield put(searchStateByRegionFailed('Search State by region api failed')) 
    }
   } catch (error) {
     messagePopup('','Search State by region api failed','error')   
     yield put(searchStateByRegionFailed('Search State by region api failed')) 
   }
}

export function* watchSearchStateByRegionSaga(){
    yield takeEvery(actionType.SEARCH_STATE_BY_REGION_START, searchStateByRegionSaga)
}