import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../utils/messagePopup";
import history from "../../services/history";
import { fetchGraphMethod } from "../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  ALL_ATHLETE_LIST_START: "ALL_ATHLETE_LIST_START",
  ALL_ATHLETE_SUCCESS: "ALL_ATHLETE_SUCCESS",
  ALL_ATHLETE_FAILURE: "ALL_ATHLETE_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const allAthletesListStart = (data) => ({
  type: actionType.ALL_ATHLETE_LIST_START,
  payload: data,
});

export const allAthletesSuccess = (data) => ({  
    type: actionType.ALL_ATHLETE_SUCCESS,
    payload: data,
});
  
export const allAthletesFailure = (data) => ({
    type: actionType.ALL_ATHLETE_FAILURE,
    payload: data
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function allAthletesReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ALL_ATHLETE_LIST_START:
      return { ...state, loading: true };
    
    case actionType.ALL_ATHLETE_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.ALL_ATHLETE_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/


function* getAllAthletesSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true);
        if(response.status === 200){
            const allAthletes = response.data?.data?.allAthletes?.Athletes;

            yield put(allAthletesSuccess(allAthletes));
        } else {
          messagePopup('', 'All Athletes Data Failed', 'error');
          yield put(allAthletesFailure('Failed, Error occured'))
        }
       } catch (error) {
         messagePopup('', 'All Athletes Data Failed', 'error');
         yield put(allAthletesFailure('Failed, Error occured'))
       }
}

export function* watchAllAthletesSaga() {
  yield takeEvery(actionType.ALL_ATHLETE_LIST_START, getAllAthletesSaga);
}

