import { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { imageDownloadUrl } from "../../../../../../config";
import { profileVideoPhotoListStart } from "../../../../../../redux/athlete/profile/profilePhotoVideoList";
import { getUserInfo } from "../../../../../../services/authService";
import history from "../../../../../../services/history";
import { postDateFormat } from "../../../../../../utils/dateTimeFormatter";
import { VideoPop } from "../../../../landingPage/common/videoPopup";
import { getThumbnail, typeConvert } from '../../../../../common/thumbnail';
import SharePost from "../../homepage/posts/SharePost";
import { Dialog } from "@mui/material";
import { vidCountIncre } from '../../../../../common/userViewCount';
import { useLocation } from "react-router-dom";
import { profileURLData } from "../../../../../common/getProfileURLData";

const EditProfileButton = ({ route }) => {
  return (
    <a
      className="edit-link"
      style={{ cursor: "pointer", color: "#c0321f" }}
      onClick={() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
          history.push(route)
        }, 1000)
      }}
    >
      Edit
    </a>
  );
}

export function ProfileVideoPhoto(props) {
  const [photoVideoList, setPhotoVideoList] = useState(null);
  const [photoList, setPhotoList] = useState(null);
  const [videoList, setVideoList] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [selectedTab, setSelectedTab] = useState("all");
  const [vidID, setVidID] = useState(null);
  const [isVidPop, setIsVidPop] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const store = useSelector(store => store);
  const fakeInput = useRef(null);
  const location = useLocation();

  const Athid = profileURLData(location?.pathname, 'athlete_id');
  // +props?.props?.match?.params?.id;
  const isToken = profileURLData(location?.pathname, 'token');
  // props?.props?.match?.params?.isToken == 1 ? 1 : 0;
  const pubViewID = JSON.parse(localStorage.getItem('pubViewID'));

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsPhotoModalOpen(true);
  };

  useEffect(() => {
    props.profileVideoPhotoListStart({
      data: {
        athleteId: !!Athid ? Athid : store?.athlete?.dashboard?.home?.athleteProfileStatus?.status ?
          +pubViewID || 0 : !!getUserInfo() ? getUserInfo().id : +pubViewID,
        isAccessToken: isToken
        // getAuthToken() ? 1 : 0,
      },
    });
    fakeInput.current.focus();
    fakeInput.current.select();
  }, [pubViewID, store?.athlete?.dashboard?.home?.athleteProfileStatus?.status, Athid]);

  useEffect(() => {
    if (props?.profileVideosPhotos?.data?.allUploadedvideoandphotos?.length > 0) {
      let featuredList = [];
      let unfeaturedList = [];
      let videoFeaturedList = [];
      let photoFeaturedList = [];
      let videoUnfeaturedList = [];
      let photoUnfeaturedList = [];
      
      for (const data of props?.profileVideosPhotos?.data
        ?.allUploadedvideoandphotos || []) {
        if (data.isfeatured) {
          featuredList = [...featuredList, { ...data }];
        } else {
          unfeaturedList = [...unfeaturedList, { ...data }];
        }
      }
      for (const data of featuredList) {
        if (data.isphotosorvideosLik === 1 || data.isphotosorvideosLik === 2) {
          videoFeaturedList = [...videoFeaturedList, { ...data }];
        } else {
          photoFeaturedList = [...photoFeaturedList, { ...data }];
        }
      }

      let rankedItem = [];
      let nonRankedItem = [];
      for (const data of unfeaturedList) {
        if (data.uploadedrank) {
          rankedItem = [...rankedItem, { ...data }];
        } else {
          // if uploadedrank === null
          nonRankedItem = [...nonRankedItem, { ...data }];
        }
      }
      rankedItem = rankedItem.sort(
        //done b - a  for descending earlier it was a - b for ascending
        (a, b) => a.uploadedrank - b.uploadedrank
      );

      unfeaturedList = [...rankedItem, ...nonRankedItem];
      let programPricingVideo = [];
      let programPricingPhoto = [];

      for (const data of unfeaturedList) {
        if (data.isphotosorvideosLik === 1 || data.isphotosorvideosLik === 2) {
          if (data.videophotosuploadedtype === "ProgramAndPricing") {
            programPricingVideo = [...programPricingVideo, { ...data }];
          } else {
            videoUnfeaturedList = [...videoUnfeaturedList, { ...data }];
          }
        } else {
          if (data.videophotosuploadedtype === "ProgramAndPricing") {
            programPricingPhoto = [...programPricingPhoto, { ...data }];
          } else {
            photoUnfeaturedList = [...photoUnfeaturedList, { ...data }];
          }
        }
      }
      const videoPhotoList = [
        ...videoFeaturedList,
        ...photoFeaturedList,
        ...videoUnfeaturedList,
        ...programPricingVideo,
        ...photoUnfeaturedList,
        ...programPricingPhoto,
      ];
      setPhotoVideoList(videoPhotoList);
      const tempData = [...videoPhotoList];

      const videoListT = tempData.filter(
        (item) => item.isphotosorvideosLik !== 0
      );
      const photoListT = tempData.filter(
        (item) => item.isphotosorvideosLik === 0
      );
      setVideoList(videoListT);
      setPhotoList(photoListT);
      setFilteredData(tempData);
    }
  }, [props.profileVideosPhotos.data]);

  const openVideoOnNewTab = (url, linType, data) => {
    if (url) {
      // window.open(url, "_blank");
      setVidID(url)
      setIsLink(linType)
      setTimeout(()=>{
        setIsVidPop(true)
      },500)
      vidCountIncre(data, true)
    }
  };

  const onTabsChange = (e, tab) => {
    if (tab === "all") {
      setFilteredData([...photoVideoList]);
    }
    if (tab === "video") {
      setFilteredData([...videoList]);
    }
    if (tab === "photo") {
      setFilteredData([...photoList]);
    }
    setSelectedTab(tab);
  };
  const handleModal = (value) => {
    setModalData(value);
    setShowModal(!showModal);
  };

  return (
    <>
    <div className="TimelineData video-tab-data">
      <input className="fakeInput" type="text" ref={fakeInput} />
      <div className="item">
        <div className="video-and-photo-wrap">
          <div className="video-and-photo-main">
            <div className="sidebarbox">
              <ul className="menu-links">
                <li
                  className={selectedTab === "all" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <a href={void 0} onClick={(e) => onTabsChange(e, "all")}>
                    All
                  </a>
                </li>
                <li
                  className={selectedTab === "video" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <a href={void 0} className="videoTag" onClick={(e) => onTabsChange(e, "video")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26.334"
                      height="16.819"
                      viewBox="0 0 26.334 16.819"
                    >
                      <g transform="translate(8851.167 20311.82)">
                        <path
                          className="a"
                          d="M24.067,9.9a2.528,2.528,0,0,0-2.633-2.4H5.633A2.528,2.528,0,0,0,3,9.9V21.916a2.528,2.528,0,0,0,2.633,2.4h15.8a2.528,2.528,0,0,0,2.633-2.4v-4l5.267,4V9.9l-5.267,4ZM22.314,22.717H4.756V9.1H22.312l0,6.806,0,0,0,0,0,6.806Z"
                          transform="translate(-8854.167 -20319.32)"
                        />
                        <path
                          className="a"
                          d="M16.044,11.338a.322.322,0,0,0-.168-.282L8.793,6.967a.322.322,0,0,0-.506.264.361.361,0,0,0,0,.036h0V15.48h0a.319.319,0,0,0,.479.242l0,.005,7.112-4.106,0-.005a.321.321,0,0,0,.162-.278Z"
                          transform="translate(-8852.776 -20314.748)"
                        />
                      </g>
                    </svg>
                    <span>({videoList ? videoList.length : 0})</span>
                  </a>
                </li>
                <li
                  className={selectedTab === "photo" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <a href={void 0} className="videoTag" onClick={(e) => onTabsChange(e, "photo")}>
                  <svg xmlns="http://www.w3.org/2000/svg" id="image" width="24" height="24" viewBox="0 0 24 24">
                <g id="Group_1127" data-name="Group 1127">
                  <g id="Group_1126" data-name="Group 1126">
                    <path id="Path_2126" data-name="Path 2126" d="M21.75,0H2.25A2.25,2.25,0,0,0,0,2.25v19.5A2.25,2.25,0,0,0,2.25,24h19.5A2.25,2.25,0,0,0,24,21.75V2.25A2.25,2.25,0,0,0,21.75,0Zm.75,14.692-3.217-3.217a.75.75,0,0,0-1.058,0L14.25,15.442,9.532,10.725a.75.75,0,0,0-1.057,0L1.5,17.692V2.25a.75.75,0,0,1,.75-.75h19.5a.75.75,0,0,1,.75.75Z" fill="#3e3e3e" />
                    <path id="Path_2127" data-name="Path 2127" d="M227.093,80a3.093,3.093,0,1,0,3.093,3.093A3.093,3.093,0,0,0,227.093,80Zm0,4.64a1.547,1.547,0,1,1,1.547-1.547A1.547,1.547,0,0,1,227.093,84.64Z" transform="translate(-213.609 -76.289)" fill="#3e3e3e" />
                  </g>
                </g>
              </svg>
                    <span>({photoList ? photoList.length : 0})</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content-box">
              <div className="video-second-block">
                <div className="video-tab-contents pt-0">
                  <div className="edit-videolink-box">
                    {
                      props.isPublicView ? "" : (
                        <div className="edit-video-link common-item-box">
                          <h3 className="title" style={{ display: "flex", justifyContent: "space-between" }}>Videos & Photos
                            <EditProfileButton route={'/videoPhotos'} />
                          </h3>
                        </div>
                      )
                    }
                  </div>
                  <ul className="video-grid-list clearfix">
                    {filteredData?.length > 0
                      ? filteredData.map((item, index) => {
                        if (item.isphotosorvideosLik === 0) {
                          return (
                            <li className="video-box" key={index}>
                              <div className="img-box">
                                <img
                                  src={`${imageDownloadUrl + "/" + item?.photosname
                                    }`}
                                  alt="profile Bg"
                                  onClick={() => handleImageClick(`${imageDownloadUrl + "/" + item?.photosname}`)}
                                />
                                <ul className="info-icon">
                                 {item?.isfeatured ? <li
                                    className={`button-favorite ${item?.isfeatured && "active"
                                      }`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15.057"
                                      height="14.43"
                                      viewBox="0 0 15.057 14.43"
                                    >
                                      <defs></defs>
                                      <path
                                        className="a"
                                        d="M15.018,5.948a.8.8,0,0,0-.688-.55L9.983,5,8.265.982a.8.8,0,0,0-1.473,0L5.075,5,.727,5.4A.8.8,0,0,0,.272,6.8L3.558,9.68l-.969,4.267a.8.8,0,0,0,1.191.865l3.748-2.241,3.747,2.241a.8.8,0,0,0,1.192-.865L11.5,9.68,14.784,6.8a.8.8,0,0,0,.233-.851ZM7.6,12.528"
                                        transform="translate(0 -0.496)"
                                      />
                                    </svg>
                                  </li> : ""}
                                  {/* <li className="delete-button">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="9.677"
                                      height="11.91"
                                      viewBox="0 0 9.677 11.91"
                                    >
                                      <defs></defs>
                                      <g transform="translate(-48)">
                                        <path
                                          className="a"
                                          d="M56.746,1.489H54.7V1.117A1.117,1.117,0,0,0,53.583,0H52.094a1.117,1.117,0,0,0-1.117,1.117v.372H48.93a.93.93,0,0,0-.93.93v.744a.372.372,0,0,0,.372.372H57.3a.372.372,0,0,0,.372-.372V2.419A.93.93,0,0,0,56.746,1.489Zm-5.024-.372a.373.373,0,0,1,.372-.372h1.489a.373.373,0,0,1,.372.372v.372H51.722Z"
                                        />
                                        <path
                                          className="a"
                                          d="M73.48,184a.116.116,0,0,0-.116.122l.307,6.444a1.115,1.115,0,0,0,1.115,1.064h5.652a1.115,1.115,0,0,0,1.115-1.064l.307-6.444a.116.116,0,0,0-.116-.122Zm5.621.93a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Zm-1.861,0a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Zm-1.861,0a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Z"
                                          transform="translate(-24.774 -179.72)"
                                        />
                                      </g>
                                    </svg>
                                  </li> */}
                                  {/* <li className="share-button">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10.689"
                                     height="11.803"
                                     viewBox="0 0 10.689 11.803"
                                    >
                                     <path
                                        d="M13.4,11.457a1.623,1.623,0,0,0-1.161.445L7.984,9.44a1.994,1.994,0,0,0,.06-.415,1.993,1.993,0,0,0-.06-.415l4.2-2.432a1.782,1.782,0,1,0-.566-1.3,1.982,1.982,0,0,0,.06.415L7.478,7.72a1.792,1.792,0,0,0-1.221-.474A1.763,1.763,0,0,0,4.5,9.025a1.793,1.793,0,0,0,3.007,1.3l4.228,2.461a1.488,1.488,0,0,0-.06.385,1.727,1.727,0,1,0,1.727-1.72Z"
                                       transform="translate(-4.5 -3.094)"
                                      />
                                    </svg>
                                  </li> */}
                                </ul>
                              </div>
                              <div className="video-detail-box">
                                <div className="title-box">
                                  <div className="title half" style={{width: "100%"}}>
                                    {item?.originalname
                                      ? item.originalname
                                      : ""}
                                  </div>
                                </div>
                                <div className="video-info d-block">
                                  <div className="half">
                                    <span>
                                      Photo Type :{" "}
                                      {item?.videophotosuploadedtype
                                        ? item.videophotosuploadedtype
                                        : ""}
                                    </span>
                                    {!item?.actualProfilePhoto ? (
                                      <span>
                                        {/*below is commented as per task aug no 36*/}
                                        {/*{`${item?.totalViewCount ? item?.totalViewCount : 0} Views - `}*/}
                                        {" "}
                                        {postDateFormat(item?.createdOn)}
                                      </span>

                                    ) : null}
                                    <span className="shareLink">
                                      <button onClick={()=>handleModal(item)} className={"share-btn"} type={'button'} >
                                        Share
                                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16.804" height="14" viewBox="0 0 16.804 14"><defs><style></style></defs><path class="a" d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z" transform="translate(-4.502 -6.749)"></path></svg>
                                    </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        } else {
                          return (
                            <li className="video-box" key={index}>
                              <div className="img-box">
                                {item ? (
                                  item.isphotosorvideosLik === 1 ? (
                                      <>
                                    <video
                                        // controls
                                    // controlsList={isToken == 1 ? "" : "nodownload"}
                                    >
                                      <source
                                        src={typeConvert(`${imageDownloadUrl +
                                        "/" +
                                        item?.videoname
                                        }`, 'Timeline')+"#t=0.2"}
                                        alt="video"
                                      />
                                    </video>
                                      <div
                                          className="video-play-button"
                                          onClick={() =>
                                              openVideoOnNewTab(`${imageDownloadUrl +
                                              "/" +
                                              item?.videoname
                                              }`, false, item)
                                          }
                                      >
                                        <div className="video-play-inner">
                                          <button>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10.802"
                                                height="16.203"
                                                viewBox="0 0 10.802 16.203"
                                            >
                                              <defs></defs>
                                              <path
                                                  className="a"
                                                  d="M8.1,0l8.1,10.8H0Z"
                                                  transform="translate(10.802) rotate(90)"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                      </>
                                  ) : (
                                    <>
                                      <video
                                          poster={getThumbnail(item?.videolink, item, "ProfileVP")}
                                      // controlsList={isToken == 1 ? "" : "nodownload"}
                                      >
                                        <source
                                          src={item?.videolink}
                                          alt="video/youtube"
                                        />
                                      </video>
                                      <div
                                        className="video-play-button"
                                        onClick={() =>
                                          openVideoOnNewTab(item?.videolink, true, item)
                                        }
                                      >
                                        <div className="video-play-inner">
                                          <button>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="10.802"
                                              height="16.203"
                                              viewBox="0 0 10.802 16.203"
                                            >
                                              <defs></defs>
                                              <path
                                                className="a"
                                                d="M8.1,0l8.1,10.8H0Z"
                                                transform="translate(10.802) rotate(90)"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )
                                ) : null}

                                <ul className="info-icon">
                                  {item?.isfeatured ? <li
                                    className={`button-favorite ${item?.isfeatured && "active"
                                      }`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15.057"
                                      height="14.43"
                                      viewBox="0 0 15.057 14.43"
                                    >
                                      <defs></defs>
                                      <path
                                        className="a"
                                        d="M15.018,5.948a.8.8,0,0,0-.688-.55L9.983,5,8.265.982a.8.8,0,0,0-1.473,0L5.075,5,.727,5.4A.8.8,0,0,0,.272,6.8L3.558,9.68l-.969,4.267a.8.8,0,0,0,1.191.865l3.748-2.241,3.747,2.241a.8.8,0,0,0,1.192-.865L11.5,9.68,14.784,6.8a.8.8,0,0,0,.233-.851ZM7.6,12.528"
                                        transform="translate(0 -0.496)"
                                      />
                                    </svg>
                                  </li> : ""}

                                  {/* <li className="delete-button">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="9.677"
                                      height="11.91"
                                      viewBox="0 0 9.677 11.91"
                                    >
                                      <g transform="translate(-48)">
                                        <path
                                          className="a"
                                          d="M56.746,1.489H54.7V1.117A1.117,1.117,0,0,0,53.583,0H52.094a1.117,1.117,0,0,0-1.117,1.117v.372H48.93a.93.93,0,0,0-.93.93v.744a.372.372,0,0,0,.372.372H57.3a.372.372,0,0,0,.372-.372V2.419A.93.93,0,0,0,56.746,1.489Zm-5.024-.372a.373.373,0,0,1,.372-.372h1.489a.373.373,0,0,1,.372.372v.372H51.722Z"
                                        />
                                        <path
                                          className="a"
                                          d="M73.48,184a.116.116,0,0,0-.116.122l.307,6.444a1.115,1.115,0,0,0,1.115,1.064h5.652a1.115,1.115,0,0,0,1.115-1.064l.307-6.444a.116.116,0,0,0-.116-.122Zm5.621.93a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Zm-1.861,0a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Zm-1.861,0a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Z"
                                          transform="translate(-24.774 -179.72)"
                                        />
                                      </g>
                                    </svg>
                                  </li> */}
                                  {/*<li className="share-button">*/}
                                  {/*  <svg*/}
                                  {/*    xmlns="http://www.w3.org/2000/svg"*/}
                                  {/*    width="10.689"*/}
                                  {/*    height="11.803"*/}
                                  {/*    viewBox="0 0 10.689 11.803"*/}
                                  {/*  >*/}
                                  {/*    <path*/}
                                  {/*      d="M13.4,11.457a1.623,1.623,0,0,0-1.161.445L7.984,9.44a1.994,1.994,0,0,0,.06-.415,1.993,1.993,0,0,0-.06-.415l4.2-2.432a1.782,1.782,0,1,0-.566-1.3,1.982,1.982,0,0,0,.06.415L7.478,7.72a1.792,1.792,0,0,0-1.221-.474A1.763,1.763,0,0,0,4.5,9.025a1.793,1.793,0,0,0,3.007,1.3l4.228,2.461a1.488,1.488,0,0,0-.06.385,1.727,1.727,0,1,0,1.727-1.72Z"*/}
                                  {/*      transform="translate(-4.5 -3.094)"*/}
                                  {/*    />*/}
                                  {/*  </svg>*/}
                                  {/*</li>*/}
                                </ul>
                              </div>
                              <div className="video-detail-box">
                                <div className="title-box">
                                  <div className="title half" style={{width: "100%"}}>
                                    {item?.isphotosorvideosLik === 1
                                      ? item?.originalname
                                        ? item.originalname
                                        : ""
                                      : item?.originalname
                                        ? item.originalname === "Video"
                                          ? `${item.originalname} ${item.uploadedrank}`
                                          : `${item.originalname}`
                                        : ""}
                                  </div>
                                </div>
                                <div className="video-info d-block">
                                  <div className="half">
                                    <span>
                                      Video Type :{" "}
                                      {item?.videophotosuploadedtype
                                        ? item.videophotosuploadedtype
                                        : ""}
                                    </span>
                                    <span>
                                      {`${item?.viewcount ? item?.viewcount : 0} Views - `}{" "}
                                      {postDateFormat(item?.createdOn)}
                                    </span>
                                    <span className="shareLink">
                                      <button onClick={()=>handleModal(item)} className={"share-btn"} type={'button'} >
                                        Share 
                                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16.804" height="14" viewBox="0 0 16.804 14"><defs><style></style></defs><path class="a" d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z" transform="translate(-4.502 -6.749)"></path></svg>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        }
                      })
                      : "No Videos & Photos"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <VideoPop
        isTestSingle={isVidPop}
        vidID={vidID}
        setIsTestSingle={()=>setIsVidPop(false)}
        isLink={ isLink }
        isToken={ isToken }
        />
        <Dialog open={showModal}
                onClose={(event, reason)=> {
                  if (reason == "backdropClick" || reason == "escapeKeyDown") {
                    handleModal()
                  }
                }}
                className="matDialogCustom"
        >
          <SharePost
              handleModal={handleModal}
              pageType={"Vid/Photo_Timeline"}
              modalData={modalData}
          />
        </Dialog>
        <Dialog
          open={isPhotoModalOpen}
          onClose={() => setIsPhotoModalOpen(false)}
        >
          <img src={selectedImage} alt="Selected" />
        </Dialog>
     </>
  );
}

const mapStateToProps = (state) => {
  return {
    profileVideosPhotos: state.athlete.profile.profileVideosPhotos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    profileVideoPhotoListStart: (data) =>
      dispatch(profileVideoPhotoListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileVideoPhoto);