import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    REPORT_CARD_LIST_START: "REPORT_CARD_LIST_START",
    REPORT_CARD_LIST_SUCCESS: "REPORT_CARD_LIST_SUCCESS",
    REPORT_CARD_LIST_FAILED: "REPORT_CARD_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const reportCardListStart = (data) => ({
    type: actionType.REPORT_CARD_LIST_START,
    payload: data,
});

export const reportCardListSuccess = (data) => ({
    type: actionType.REPORT_CARD_LIST_SUCCESS,
    payload: data,
});

export const reportCardListFailed = (data) => ({
    type: actionType.REPORT_CARD_LIST_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function reportCardListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.REPORT_CARD_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.REPORT_CARD_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.REPORT_CARD_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* reportCardListSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            const allreportCard =  response.data.data.allReportcardtypes.Reportcardtypes
            yield put(reportCardListSuccess(allreportCard));
        } else {
            messagePopup("", "Report Card List failed", "error");
            yield put(reportCardListFailed("Report Card List failed"));
        }
    } catch (error) {
        messagePopup("", "Report Card List failed", "error");
        yield put(reportCardListFailed("Report Card List failed"));
    }
}

export function* watchReportCardListSaga() {
    yield takeEvery(actionType.REPORT_CARD_LIST_START, reportCardListSaga);
}
