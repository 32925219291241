import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { saveBasicUserProfileUpdateQuery } from "../BasicQuery";
import LeftSideEditProfile from "../LeftSideEditProfile";
import { VideoOverlay, SideBarVideo, SubHeader, Title } from "./Component";
import AddMoreTimeCodeDetails from "./AddMoreTimeCodeDetails";
import { updateBasicUserProfileListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/updateBasicUserProfile";
import { saveVideoDraftStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/videosandPhotos";
import { getMasterPlanStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/allMasterPlan";
import { saveVideoOrderStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/saveVideoOrder";
import { athleteParentMappingStart } from "../../../../../../redux/athlete/onBoarding/athleteParentMapping";
import {
  getVideoEditingMasterPlan,
  saveVideoEditingDetailsQuery,
  saveVideoOrderQuery,
  saveMembershipQuery,
  getPremiumUserQuery,
  getVideoDraftQuery,
} from "./VideoPhotoQueries";

import profileBg from "../assets/img/profileBg.png";
import isolationsIcon from "../assets/img/isolations-icon.png";
import cartIcon from "../assets/img/cart-icon.png";
import videoIcon from "../assets/img/video-icon.png";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import { getUserInfo } from "../../../../../../services/authService";
import swal from "sweetalert";
import { getVideoOrderStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/getVideoOrder";
import { membershipListStart } from "../../../../../../redux/athlete/dashboard/account/membership";
import { getVideoDraftStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/getVideoDraft";
import BackdropLoader from "../../../../../common/Loader";
import { getAllVideoPhotoInfoQuery } from "./VideoPhotoQuery";
import { allVideoPhotoListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/getVideoPhotoInfo";
import { allParentQuery } from "../ContactQuery";
import {
  getLatestSchoolQuery,
  getSchoolTeamUserMappingForTeams,
} from "../teams/TeamQuery";
import { schoolTeamUserMappingStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/schoolTeamUserMapping";
import { userAcademicSchoolStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/academicSchool";
import GoTopButton from "../GoTopButton";

const EditSection = (props) => {
  const [professionalEditCount, setProfessionalEditCount] = React.useState(0);
  const [commitEditCount, setCommitEditCount] = React.useState(0);
  const [videoDetails, setVideoDetails] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [infoId1, setInfoId1] = useState(false);
  const [infoId2, setInfoId2] = useState(false);
  const [infoId3, setInfoId3] = useState(false);
  const [timeCodeDetails, setTimeCodeDetails] = useState();
  const [jerseryNumber, setJerseyNumber] = useState("");
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    gradYear: "",
    position: "",
    team: "",
    school: "",
    parentEmail: "",
    parentPhone: "",
    athleteEmail: "",
  });

  const [professionalPlan, setProfessionalPlan] = useState([]);
  const [commitPlan, setCommitPlan] = useState([]);
  const [draftId, setDraftId] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);
  const [count, setCount] = useState(0);
  const [planId, setPlanId] = useState(0);
  const [purchasedItemList, setPurchasedItemList] = useState(null);
  const [purchasedVideoType, setPurchasedVideoType] = useState(null);
  const [videoEditingOrderPlanIdList, setVideoEditingOrderPlanIdList] =
    useState(null);
  const [activeTab, setActiveTab] = useState("order");
  const [invalidClipData, setInvalidClickData] = useState(true);

  //get master plan query
  useEffect(() => {
    props.getAllMasterPlan({
      query: getVideoEditingMasterPlan,
      variables: null,
    });
  }, []);

  //get membership plan query
  useEffect(() => {
    props.membershipList({
      query: getPremiumUserQuery,
      variables: {
        where: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          order: "id DESC",
        },
      },
    });
  }, []);

  //save membership plan locally
  useEffect(() => {
    if (props.getPremiumUser.data && props.getPremiumUser.data.allMemberships) {
      const tempData = [...props.getPremiumUser.data.allMemberships];
      const videoeditingorderplanidList = tempData.map((item) => {
        return item.membershipvideoeditingorderplanidmaprel
          ?.Videoedittingorders[0]
          ? item.membershipvideoeditingorderplanidmaprel?.Videoedittingorders[0]
              .palnid
          : null;
      });
      setVideoEditingOrderPlanIdList(videoeditingorderplanidList);
      setPurchasedItemList(props.getPremiumUser.data.allMemberships);
    } else {
      setVideoEditingOrderPlanIdList(null);
      setPurchasedItemList(null);
    }
  }, [props.getPremiumUser.data]);

  useEffect(() => {
    props.getPurchasedVideoType(purchasedVideoType);
  }, [purchasedVideoType]);
  //save master plan locally
  useEffect(() => {
    if (
      props.allMasterPlan &&
      props.allMasterPlan.data !== null &&
      props.allMasterPlan.data.length
    ) {
      setProfessionalEditCount(
        props.allMasterPlan.data[0].videoedittingtotalcount
      );
      setCommitEditCount(props.allMasterPlan.data[1].videoedittingtotalcount);
      const professionalPlanT = props.allMasterPlan.data.filter((item) => {
        return item.videofor !== "commit_edit";
      });
      setProfessionalPlan(professionalPlanT);
      const commitPlanT = props.allMasterPlan.data.filter((item) => {
        return item.videofor === "commit_edit";
      });
      setCommitPlan(commitPlanT);
    }
  }, [props.allMasterPlan]);

  useEffect(() => {
    if (
      videoEditingOrderPlanIdList &&
      videoEditingOrderPlanIdList.length &&
      props.allMasterPlan.data
    ) {
      let tempdata = {};
      for (const data of props.allMasterPlan.data) {
        if (videoEditingOrderPlanIdList.includes(data.id)) {
          tempdata =
            data.videofor === "commit_edit"
              ? { ...tempdata, commitEdit: true }
              : { ...tempdata, professionalVideo: true };
        }
      }
      setPurchasedVideoType(tempdata);
    }
  }, [videoEditingOrderPlanIdList, props.allMasterPlan.data]);

  // set athelet data
  useEffect(() => {
    if (
      props.athletesData &&
      props.athletesData.data &&
      props.athletesData.data.athletesData
    ) {
      let fields = userInfo;

      fields["firstName"] =
        props.athletesData &&
        props.athletesData.data &&
        props.athletesData.data.athletesData
          ? props.athletesData.data.athletesData.athletefirstname
          : "";
      fields["lastName"] =
        props.athletesData &&
        props.athletesData.data &&
        props.athletesData.data.athletesData &&
        props.athletesData.data.athletesData.athletelastname
          ? props.athletesData.data.athletesData.athletelastname
          : "";

      fields["athleteEmail"] =
        props.athletesData &&
        props.athletesData.data &&
        props.athletesData.data.athletesData &&
        props.athletesData.data.athletesData.email
          ? props.athletesData.data.athletesData.email
          : "";

      fields["gradYear"] =
        props.athletesData &&
        props.athletesData.data &&
        props.athletesData.data.athletesData &&
        props.athletesData.data.athletesData.graduationYear
          ? props.athletesData.data.athletesData.graduationYear
          : "";
      setUserInfo({ ...fields });
    }
  }, [props.athletesData.data]);

  useEffect(() => {
    if (
      props.athletesData &&
      props.athletesData.data &&
      props.athletesData.data.onboardingData
    ) {
      let fields = userInfo;
      fields["position"] =
        props.athletesData &&
        props.athletesData.data &&
        props.athletesData.data.onboardingData &&
        props.athletesData.data.onboardingData
          .onboardingprimarypositionmaprel &&
        props.athletesData.data.onboardingData.onboardingprimarypositionmaprel
          .Primarypositions[0]
          ? props.athletesData.data.onboardingData
              .onboardingprimarypositionmaprel.Primarypositions[0]
              .selectionfield
          : "";
      setUserInfo({ ...fields });
    }
  }, [props.athletesData.data]);

  useEffect(() => {
    if (props.team && props.team.data) {
      let teamArray =
        props.team &&
        props.team.data &&
        props.team.data.schoolTeamUserMapping &&
        props.team.data.schoolTeamUserMapping.Schoolteamusermappings
          ? props.team.data.schoolTeamUserMapping.Schoolteamusermappings
          : [];
      let teamsName = "";
      for (let i = 0; i < teamArray.length; i++) {
        if (teamArray[i].primaryteamstatus === 1) {
          teamsName = teamArray[i].schoolteamusermappingteamidmaprel.Teams[0]
            ?.teamname
            ? teamArray[i].schoolteamusermappingteamidmaprel.Teams[0]?.teamname
            : "";
          break;
        }
      }
      if (!teamsName) {
        for (let i = 0; i < teamArray.length; i++) {
          if (teamArray[i].primaryteamstatus === 0) {
            teamsName = teamArray[i].schoolteamusermappingteamidmaprel.Teams[0]
              ?.teamname
              ? teamArray[i].schoolteamusermappingteamidmaprel.Teams[0]
                  ?.teamname
              : "";
            break;
          }
        }
      }
      let fields = userInfo;
      fields["team"] = teamsName;
      setUserInfo({ ...fields });
    }
  }, [props.team]);

  useEffect(() => {
    if (props.school && props.school.data) {
      let fields = { ...userInfo };
      fields["school"] =
        props.school &&
        props.school.data &&
        props.school.data.allUserAcademicSchool &&
        props.school.data.allUserAcademicSchool.Athleteschools &&
        props.school.data.allUserAcademicSchool.Athleteschools[
          props.school.data.allUserAcademicSchool.Athleteschools.length - 1
        ]
          ? props.school.data.allUserAcademicSchool.Athleteschools[
              props.school.data.allUserAcademicSchool.Athleteschools.length - 1
            ].highschoolname
          : "";
      setUserInfo({ ...fields });
    }
  }, [props.school.data]);

  // set parent data
  useEffect(() => {
    if (
      props.parentInfo &&
      props.parentInfo.data &&
      props.parentInfo.data.parentData &&
      props.parentInfo.data.parentData.length
    ) {
      let details = {};
      details["parentPhone"] =
        props.parentInfo &&
        props.parentInfo.data.parentData &&
        props.parentInfo.data.parentData[0]
          ? props.parentInfo.data.parentData &&
            props.parentInfo.data.parentData[0].parentphonenumber
          : "";
      details["parentEmail"] =
        props.parentInfo &&
        props.parentInfo.data.parentData &&
        props.parentInfo.data.parentData[0]
          ? props.parentInfo.data.parentData[0].parentemail
          : "";
      setUserInfo({
        ...userInfo,
        parentEmail: details.parentEmail,
        parentPhone: details.parentPhone,
      });
    }
  }, [props.parentInfo.data]);

  useEffect(() => {
    props.getVideoDraft({
      query: getVideoDraftQuery,
      variables: {
        where: {
          order: "id DESC",
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  }, [props.videoList]);

  const getDraftData = () => {
    props.getVideoDraft({
      query: getVideoDraftQuery,
      variables: {
        where: {
          order: "id DESC",
          userid: getUserInfo() ? getUserInfo().id : 0,
          isdraft: 1,
        },
      },
    });
  };

  useEffect(() => {
    if (props.videoDraft.data && props.videoDraft.data.length) {
      const {
        additionaldetails,
        videodetails,
        jerseynumer,
        videoinformationid,
        isdraft,
        id,
      } = props.videoDraft.data[0];
      if (isdraft == 1) {
        let checkboxes = videoinformationid.split(",");
        setDraftId(id);
        setVideoDetails(videodetails);
        // setAdditionalDetails(additionaldetails);
        {
          checkboxes[0] == 1 && setInfoId1(true);
        }
        {
          checkboxes[1] == 1 && setInfoId2(true);
        }
        {
          checkboxes[2] == 1 && setInfoId3(true);
        }
        // setTimeCodeDetails({});
        setJerseyNumber(jerseynumer);
      } else {
        setInitialData();
      }
    }
  }, [props.videoDraft.data]);

  const setInitialData = () => {
    setDraftId("");
    setVideoDetails("");
    setAdditionalDetails("");
    setInfoId1(false);
    setInfoId2(false);
    setInfoId3(false);
    setTimeCodeDetails({});
    setJerseyNumber("");
  };

  const saveVideoDraft = (e, draft) => {
    //added on 31/1/2022 to prevent default behaviour of the event
    e.preventDefault();
    let errorInFields = { error: false, message: "" };
    let clipsource = [],
      starttime = [],
      endtime = [],
      details = [];
    // const pattern = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9])|(24:?00))/g;
    const onlyAlphaNumeric = /[^A-Za-z0-9]+/g;
    timeCodeDetails.length &&
      timeCodeDetails.map((item) => {
        clipsource = [...clipsource, item.clipsource];
        if (item.starttime) {
          // if (item.starttime.match(pattern)) {
          starttime = [...starttime, item.starttime];
          // } else {
          //   errorInFields.error = true;
          //   errorInFields.message =
          //     "Please enter time in 24 hr time format(HH:MM:SS)";
          // }
        }
        if (item.endtime) {
          endtime = [...endtime, item.endtime];
          // let endStarttime =
          //   item?.endtime?.length > 0 ? item.endtime.split(":") : "";
          // let starttime =
          //   item?.starttime?.length > 0 ? item.starttime.split(":") : "";
          // if (item.endtime.match(pattern)) {
          //   if (
          //     parseFloat(endStarttime[0]) < parseFloat(starttime[0]) ||
          //     parseFloat(endStarttime[1]) < parseFloat(starttime[1]) ||
          //     parseFloat(endStarttime[2]) < parseFloat(starttime[2])
          //   ) {
          //     errorInFields.error = true;
          //     errorInFields.message =
          //       "End Time Should Be Greater Than Start time";
          //   } else {
          //     endtime = [...endtime, item.endtime];
          //   }
          // } else {
          //   errorInFields.error = true;
          //   errorInFields.message =
          //     "Please enter time in 24 hr time format(HH:MM:SS)";
          // }
        }
        details = [...details, item.details];
      });
    let validJerseyNumber = "";
    if (jerseryNumber) {
      if (jerseryNumber.match(onlyAlphaNumeric) === null) {
        validJerseyNumber = jerseryNumber;
      } else {
        errorInFields.error = true;
        errorInFields.message = "Jersey number should be alpha numeric";
      }
    }
    let data = {};
    if (draft) {
      data = {
        id: null,
        isdraft: 1,
        userid: getUserInfo() ? getUserInfo().id : 0,
        videodetails: videoDetails ? videoDetails : "",
        jerseynumer: validJerseyNumber,
        videoinformationid: `${infoId1 ? "1" : "0"},${infoId2 ? "1" : "0"},${
          infoId3 ? "1" : "0"
        }`,
      };
    } else {
      data = {
        isdraft: 0,
        userid: getUserInfo() ? getUserInfo().id : 0,
        videodetails: videoDetails ? videoDetails : "",
        videoinformationid: `${infoId1 ? "1" : "0"},${infoId2 ? "1" : "0"},${
          infoId3 ? "1" : "0"
        }`,
        jerseynumer: validJerseyNumber,
        clipsource: timeCodeDetails.length ? clipsource.join() : "",
        starttime: timeCodeDetails.length ? starttime.join() : "",
        endtime: timeCodeDetails.length ? endtime.join() : "",
        timecodejerseynumber: timeCodeDetails.length ? details.join() : "",
        additionaldetails: additionalDetails ? additionalDetails : "",
      };
    }

    if (draftId || !draft) {
      data = {
        ...data,
        id: draftId ? draftId : null,
      };
    }
    if (errorInFields.error) {
      swal("", `${errorInFields.message}`, "error");
    } else {
      if (!draft && invalidClipData) {
        swal("", `Please enter valid timecode details`, "error");
        return;
      }
      props.saveDraft({
        query: saveVideoEditingDetailsQuery,
        variables: {
          obj: data,
        },
      });
    }
    setFormSubmit(!formSubmit);
  };

  const timeCodeData = (data, error = null) => {
    if (error) {
      setInvalidClickData(true);
    } else {
      setInvalidClickData(false);
    }
    setTimeCodeDetails(data);
  };

  // called on clicking purchase button
  const onPurchageClick = (data) => {
    setPlanId(data.id);
    props.onVideoClose();
    props.history.push({
      //earlier this code was there but as payment code is changes so it is been changed
      // pathname: "/payment",
      // state: {
      //   purchasePlanId: data.id,
      //   previousPage: "professional-edit",
      //   nextRoute: "/videoPhotos/Professional",
      // },
      //new code for payment
      pathname: "/payment/videoPhotos",
      state: {
        selectedPlan: {
          id: data?.id || 0,
          monthlyprice: data?.videoedittingplanprice || 1,
        },
        toggle: { isChecked: true },
        previousPage: "professional-edit",
        nextRoute: "/videoPhotos/Professional",
      },
    });
  };

  const handleActiveTab = (flag) => {
    if (flag === "order") {
      setActiveTab(flag);
      setInitialData();
    } else {
      if (
        purchasedVideoType &&
        (purchasedVideoType?.professionalVideo ||
          purchasedVideoType?.commitEdit)
      ) {
        getDraftData();
        if (!count) {
          props.open();
        }
        setCount(1);
        setActiveTab(flag);
      }
    }
  };

  return (
    <div className="editSection">
      <Title title="Videos & Photos" />
      <div className="check-list-sec professional-video-sec">
        <div className="video-and-photo-wrap">
          <div className="video-and-photo-main">
            <SideBarVideo
              photoCount={props.counts?.photoCount || 0}
              videoCount={props.counts?.videoCount || 0}
            />
            <div className="content-box">
              <div className="professional-video-wrap">
                <ul className="tabs">
                  <li
                    className={`tab-link ${activeTab === "order" && "current"}`}
                    onClick={() => handleActiveTab("order")}
                  >
                    Order
                  </li>
                  <li
                    className={`tab-link ${
                      activeTab === "edit-form" && "current"
                    }`}
                    onClick={() => handleActiveTab("edit-form")}
                  >
                    Edit Form
                  </li>
                </ul>
                <div className="professional-video-tab-data">
                  <div
                    id="tab1"
                    className={`tab-content ${
                      activeTab === "order" && "current"
                    }`}
                  >
                    <div className="video-button-box">
                      <a
                        className="btn"
                        href={void 0}
                        onClick={() => props.open("2enW8lkaANA")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="17"
                          viewBox="0 0 12 17"
                        >
                          <path
                            id="Polygon_15"
                            data-name="Polygon 15"
                            d="M8.5,0,17,12H0Z"
                            transform="translate(12) rotate(90)"
                            fill="#fff"
                          />
                        </svg>
                        Play Tutorial
                      </a>
                    </div>
                    <div className="professional-video-info-list">
                      <div className="professional-video-info">
                        <h3>
                          Professional Video{" "}
                          {purchasedVideoType &&
                          purchasedVideoType.professionalVideo ? (
                            <span className="number-of-edit">
                              {professionalEditCount} edits left
                            </span>
                          ) : null}
                        </h3>
                        <div className="short-text">
                          <p>
                            Grab Coaches attention with your professionally
                            edited <br />
                            custom video and maximize your exposure.
                          </p>
                        </div>
                        <ul className="professional-list">
                          <li>
                            <a>
                              <span className="icon-box">
                                <img
                                  src={isolationsIcon}
                                  alt="isolations icon"
                                />
                              </span>
                              Isolations Effects
                            </a>
                          </li>
                          <li>
                            <a>
                              <span className="icon-box">
                                <img src={cartIcon} alt="cart icon" />
                              </span>
                              Title Card
                            </a>
                          </li>
                          <li>
                            <a>
                              <span className="icon-box">
                                <img src={videoIcon} alt="videoIcon" />
                              </span>
                              Your video
                            </a>
                          </li>
                        </ul>
                        <div className="professional-video-profile">
                          {professionalPlan
                            ? professionalPlan.map((item, i) => {
                                return (
                                  <div key={i}>
                                    <ul className="professional-video-profile-list">
                                      <li>
                                        <div className="img-box">
                                          <img
                                            src={profileBg}
                                            alt="Profile Bg"
                                          />
                                        </div>
                                        <div className="content-box">
                                          <p>{item.description}</p>
                                        </div>
                                      </li>
                                    </ul>
                                    <div className="button-box">
                                      <div className="button-item">
                                        <a
                                          className="view-example-toggle"
                                          href={void 0}
                                          onClick={() =>
                                            props.history.push(
                                              "/view-more-example"
                                            )
                                          }
                                        >
                                          View Example
                                        </a>
                                      </div>
                                      <div className="button-item">
                                        {!(
                                          purchasedVideoType &&
                                          purchasedVideoType.professionalVideo
                                        ) ? (
                                          <a
                                            className={`btn green`}
                                            href={void 0}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              onPurchageClick(item);
                                            }}
                                          >
                                            Starting at $
                                            {item.videoedittingplanprice}
                                          </a>
                                        ) : null}
                                      </div>

                                      <div className="short-text mb-0">
                                        <p>
                                          Contact us for help
                                          <Tooltip
                                            TransitionComponent={Zoom}
                                            placement="top"
                                            title={"Contact us for help"}
                                            arrow
                                          >
                                            <svg
                                              className="question-circle ml-1"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="36"
                                              height="36"
                                              viewBox="0 0 36 36"
                                            >
                                              <path
                                                id="Path_63060"
                                                data-name="Path 63060"
                                                d="M36,18A18,18,0,1,1,18,0,18,18,0,0,1,36,18ZM14.783,13.574h-2.97c-.068-4.243,3.217-5.7,6.2-5.7,3.143,0,6.014,1.643,6.014,5.04,0,2.43-1.429,3.587-2.8,4.628-1.658,1.258-2.272,1.728-2.272,3.344v.8H16.013L16,20.644c-.085-2.086,1.114-3.37,2.628-4.471,1.327-1,2.171-1.656,2.171-3.085,0-1.856-1.413-2.628-2.957-2.628a2.871,2.871,0,0,0-3.056,3.114Zm2.815,14.5a2.107,2.107,0,1,1,0-4.2,2.11,2.11,0,1,1,0,4.2Z"
                                                fillRule="evenodd"
                                              ></path>
                                            </svg>
                                          </Tooltip>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                      <div className="professional-video-info">
                        <h3>
                          Commit Edit{" "}
                          {purchasedVideoType &&
                          purchasedVideoType.commitEdit ? (
                            <span className="number-of-edit">
                              {commitEditCount} edits left
                            </span>
                          ) : null}
                        </h3>
                        <div className="short-text">
                          <p>
                            Share your commitment with your professional edited
                            <br /> commit image or video.
                          </p>
                        </div>
                        <div className="professional-video-example">
                          <div className="item">
                            <a>
                              <span className="img-box">
                                <img
                                  src={profileBg}
                                  alt="profileBg"
                                  onClick={() => props.open("KGQLty_u6ok")}
                                />
                              </span>
                              <span>Example</span>
                            </a>
                          </div>
                          <div className="item">
                            <a>
                              <span className="img-box">
                                <img
                                  src={profileBg}
                                  alt="profileBg"
                                  onClick={() => props.open("KGQLty_u6ok")}
                                />
                              </span>
                              <span>Example</span>
                            </a>
                          </div>
                        </div>
                        <div className="professional-video-profile">
                          {commitPlan
                            ? React.Children.toArray(
                                commitPlan.map((item) => (
                                  <>
                                    <ul className="professional-video-profile-list">
                                      <li>
                                        <div className="img-box">
                                          <img
                                            src={profileBg}
                                            alt="profileBg"
                                          />
                                        </div>
                                        <div className="content-box">
                                          <p>{item.description}</p>
                                        </div>
                                      </li>
                                    </ul>
                                    <div className="button-box">
                                      <div className="button-item">
                                        <a
                                          className="view-example-toggle"
                                          href={void 0}
                                          onClick={() =>
                                            props.history.push(
                                              "/view-more-example"
                                            )
                                          }
                                        >
                                          View More Example
                                        </a>
                                      </div>
                                      <div className="button-item">
                                        {!(
                                          purchasedVideoType &&
                                          purchasedVideoType.commitEdit
                                        ) ? (
                                          <a
                                            className={`btn green `}
                                            href={void 0}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              onPurchageClick(item);
                                            }}
                                          >
                                            Starting at $
                                            {item.videoedittingplanprice}
                                          </a>
                                        ) : null}
                                      </div>
                                      <div className="short-text">
                                        <p>
                                          Contact us for help
                                          <Tooltip
                                            TransitionComponent={Zoom}
                                            placement="top"
                                            title={"Contact us for help"}
                                            arrow
                                          >
                                            <svg
                                              className="question-circle ml-1"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="36"
                                              height="36"
                                              viewBox="0 0 36 36"
                                            >
                                              <path
                                                id="Path_63060"
                                                data-name="Path 63060"
                                                d="M36,18A18,18,0,1,1,18,0,18,18,0,0,1,36,18ZM14.783,13.574h-2.97c-.068-4.243,3.217-5.7,6.2-5.7,3.143,0,6.014,1.643,6.014,5.04,0,2.43-1.429,3.587-2.8,4.628-1.658,1.258-2.272,1.728-2.272,3.344v.8H16.013L16,20.644c-.085-2.086,1.114-3.37,2.628-4.471,1.327-1,2.171-1.656,2.171-3.085,0-1.856-1.413-2.628-2.957-2.628a2.871,2.871,0,0,0-3.056,3.114Zm2.815,14.5a2.107,2.107,0,1,1,0-4.2,2.11,2.11,0,1,1,0,4.2Z"
                                                fillRule="evenodd"
                                              ></path>
                                            </svg>
                                          </Tooltip>
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                ))
                              )
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="tab2"
                    className={`tab-content ${
                      activeTab === "edit-form" && "current"
                    }`}
                  >
                    <div className="video-button-box">
                      <a
                        className="btn"
                        href={void 0}
                        onClick={() => props.open("2enW8lkaANA")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="17"
                          viewBox="0 0 12 17"
                        >
                          <path
                            id="Polygon_15"
                            data-name="Polygon 15"
                            d="M8.5,0,17,12H0Z"
                            transform="translate(12) rotate(90)"
                            fill="#fff"
                          />
                        </svg>
                        Play Tutorial
                      </a>
                    </div>
                    <div className="editprofile-common-form">
                      <form>
                        <div className="professional-video-edit-form">
                          <div className="item-row">
                            <h3>Athlete Information</h3>
                            <div className="fieldBox flexBox">
                              <div className="three-col">
                                <input
                                  disabled
                                  type="text"
                                  placeholder="First Name"
                                  defaultValue={userInfo.firstName}
                                />
                              </div>
                              <div className="three-col">
                                <input
                                  disabled
                                  type="text"
                                  placeholder="Last Name"
                                  defaultValue={userInfo.lastName}
                                />
                              </div>
                              <div className="three-col">
                                <input
                                  disabled
                                  type="text"
                                  placeholder="Year"
                                  defaultValue={userInfo.gradYear}
                                />
                              </div>
                            </div>
                            <div className="fieldBox flexBox">
                              <div className="three-col">
                                <input
                                  disabled
                                  type="text"
                                  placeholder="Position(s)"
                                  defaultValue={userInfo.position}
                                />
                              </div>
                              <div className="three-col">
                                <input
                                  disabled
                                  type="text"
                                  placeholder="Club Team / Teams"
                                  defaultValue={userInfo.team}
                                />
                              </div>
                              <div className="three-col">
                                <input
                                  disabled
                                  type="text"
                                  placeholder="School"
                                  defaultValue={userInfo.school}
                                />
                              </div>
                            </div>
                            <div className="fieldBox flexBox">
                              <div className="three-col">
                                <input
                                  disabled
                                  type="email"
                                  placeholder="Parent Email"
                                  defaultValue={userInfo.parentEmail}
                                />
                              </div>
                              <div className="three-col">
                                <input
                                  disabled
                                  type="text"
                                  placeholder="Parent Mobile"
                                  defaultValue={userInfo.parentPhone}
                                />
                              </div>
                              <div className="three-col">
                                <input
                                  disabled
                                  type="email"
                                  placeholder="Athlete Email"
                                  defaultValue={userInfo.athleteEmail}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="item-row">
                            <h3>
                              Video Details
                              <span className="question-mark-icon">
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  placement="top"
                                  title={"Video Details"}
                                  arrow
                                >
                                  <svg
                                    className="question-circle ml-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                  >
                                    <path
                                      id="Path_63060"
                                      data-name="Path 63060"
                                      d="M36,18A18,18,0,1,1,18,0,18,18,0,0,1,36,18ZM14.783,13.574h-2.97c-.068-4.243,3.217-5.7,6.2-5.7,3.143,0,6.014,1.643,6.014,5.04,0,2.43-1.429,3.587-2.8,4.628-1.658,1.258-2.272,1.728-2.272,3.344v.8H16.013L16,20.644c-.085-2.086,1.114-3.37,2.628-4.471,1.327-1,2.171-1.656,2.171-3.085,0-1.856-1.413-2.628-2.957-2.628a2.871,2.871,0,0,0-3.056,3.114Zm2.815,14.5a2.107,2.107,0,1,1,0-4.2,2.11,2.11,0,1,1,0,4.2Z"
                                      fillRule="evenodd"
                                    ></path>
                                  </svg>
                                </Tooltip>
                              </span>
                            </h3>
                            <div className="fieldBox flexBox">
                              <textarea
                                value={videoDetails ? videoDetails : ""}
                                onChange={(e) =>
                                  setVideoDetails(e.target.value)
                                }
                                placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
                              ></textarea>
                            </div>
                          </div>
                          <div className="item-row">
                            <h3>
                              Video Information
                              <span
                                className="question-mark-icon"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Video Information"
                              >
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  placement="top"
                                  title={"Video Information"}
                                  arrow
                                >
                                  <svg
                                    className="question-circle ml-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                  >
                                    <path
                                      id="Path_63060"
                                      data-name="Path 63060"
                                      d="M36,18A18,18,0,1,1,18,0,18,18,0,0,1,36,18ZM14.783,13.574h-2.97c-.068-4.243,3.217-5.7,6.2-5.7,3.143,0,6.014,1.643,6.014,5.04,0,2.43-1.429,3.587-2.8,4.628-1.658,1.258-2.272,1.728-2.272,3.344v.8H16.013L16,20.644c-.085-2.086,1.114-3.37,2.628-4.471,1.327-1,2.171-1.656,2.171-3.085,0-1.856-1.413-2.628-2.957-2.628a2.871,2.871,0,0,0-3.056,3.114Zm2.815,14.5a2.107,2.107,0,1,1,0-4.2,2.11,2.11,0,1,1,0,4.2Z"
                                      fillRule="evenodd"
                                    ></path>
                                  </svg>
                                </Tooltip>
                              </span>
                            </h3>
                            <div className="fieldBox flexBox">
                              <div className="full checkbox-block-item">
                                <p>What will you be sending?</p>
                                <p className="checkbox-list">
                                  <span>
                                    <input
                                      type="checkbox"
                                      checked={infoId1}
                                      onChange={(e) => {
                                        setInfoId1(!infoId1);
                                      }}
                                    />
                                    <label>
                                      Already uploaded videos & photos to my
                                      profile
                                    </label>
                                  </span>
                                </p>
                                <p className="checkbox-list">
                                  <span>
                                    <input
                                      type="checkbox"
                                      checked={infoId2}
                                      onChange={(e) => {
                                        setInfoId2(!infoId2);
                                      }}
                                    />
                                    <label>
                                      We will upload more video for this video
                                    </label>
                                  </span>
                                </p>
                                <p className="checkbox-list">
                                  <span>
                                    <input
                                      type="checkbox"
                                      checked={infoId3}
                                      onChange={(e) => {
                                        setInfoId3(!infoId3);
                                      }}
                                    />
                                    <label>
                                      We want instructions to mail in or
                                      transfer video
                                    </label>
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="fieldBox flexBox">
                              <div className="full">
                                <label>
                                  What is your most common jersey number in this
                                  video?
                                </label>
                                <input
                                  type="text"
                                  value={jerseryNumber}
                                  placeholder="Enter #"
                                  className="width150"
                                  onChange={(e) =>
                                    setJerseyNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="item-row">
                            <div className="form-button-box flexBox justify-content-center">
                              <button
                                className="btn blue"
                                onClick={(e) => {
                                  saveVideoDraft(e, true);
                                }}
                              >
                                Save Draft
                              </button>
                            </div>
                          </div>
                          <AddMoreTimeCodeDetails
                            timeCodeData={timeCodeData}
                            videoList={props.videoList}
                          />
                          <div className="item-row">
                            <h3>
                              Additional Details
                              <span
                                className="question-mark-icon"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Additional Details"
                              >
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  placement="top"
                                  title={"Additional Details"}
                                  arrow
                                >
                                  <svg
                                    className="question-circle ml-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                  >
                                    <path
                                      id="Path_63060"
                                      data-name="Path 63060"
                                      d="M36,18A18,18,0,1,1,18,0,18,18,0,0,1,36,18ZM14.783,13.574h-2.97c-.068-4.243,3.217-5.7,6.2-5.7,3.143,0,6.014,1.643,6.014,5.04,0,2.43-1.429,3.587-2.8,4.628-1.658,1.258-2.272,1.728-2.272,3.344v.8H16.013L16,20.644c-.085-2.086,1.114-3.37,2.628-4.471,1.327-1,2.171-1.656,2.171-3.085,0-1.856-1.413-2.628-2.957-2.628a2.871,2.871,0,0,0-3.056,3.114Zm2.815,14.5a2.107,2.107,0,1,1,0-4.2,2.11,2.11,0,1,1,0,4.2Z"
                                      fillRule="evenodd"
                                    ></path>
                                  </svg>
                                </Tooltip>
                              </span>
                            </h3>
                            <div className="fieldBox flexBox">
                              <textarea
                                placeholder="Add Special Instructions"
                                value={
                                  additionalDetails ? additionalDetails : ""
                                }
                                onChange={(e) =>
                                  setAdditionalDetails(e.target.value)
                                }
                              ></textarea>
                            </div>
                            <div className="form-button-box justify-content-center">
                              <button
                                className="btn blue"
                                onClick={(e) => {
                                  saveVideoDraft(e, false);
                                }}
                              >
                                Submit for Review
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfessionalEdit = (props) => {
  const [sideMenuOpen, setSideMenu] = useState(false);
  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };
  const [videoOpen, setVideoOpen] = useState(false);
  const [videoData, setVideoData] = useState("2enW8lkaANA");
  // const [videoCount, setVideoCount] = useState(0);
  // const [photoCount, setPhotoCount] = useState(0);
  const [counts, setCounts] = useState(null);
  const [purchasedVideoType, setPurchasedVideoType] = useState(null);
  const videoCloseHandler = () => {
    if (JSON.parse(localStorage.getItem("user")).isViewPhotoAndVideo === 0) {
      props.updateBasicUserProfileListStart({
        query: saveBasicUserProfileUpdateQuery,
        variables: {
          obj: {
            id: getUserInfo() ? getUserInfo().id : 0,
            isviewphotoandvideo: 1,
          },
        },
        nextScreen: () => {
          let localData = JSON.parse(localStorage.getItem("user"));
          localData.isViewPhotoAndVideo = 1;
          localStorage.setItem("user", JSON.stringify(localData));
          setVideoOpen(false);
        },
      });
    } else {
      setVideoOpen(false);
    }
  };
  const videoOpenHandler = (id) => {
    //earlier it was 0 but now it was one as user have to play the video again and again when click on play tutorial button
    if (JSON.parse(localStorage.getItem("user")).isViewPhotoAndVideo === 1) {
      setVideoOpen(true);
    }
    if (id) {
      setVideoOpen(true); // this is new code to play the video on play tutorial button click
      setVideoData(id);
    }
  };

  const getPurchasedVideoType = (purchasedId) => {
    setPurchasedVideoType(purchasedId);
  };
  const fakeInput = useRef(null);

  useEffect(() => {
    props.allVideoPhotoListStart({
      query: getAllVideoPhotoInfoQuery,
      variables: {
        obj: {
          and: [
            {
              or: [
                {
                  videophotosuploadedtype: "programAndPricing",
                },
                {
                  videophotosuploadedtype: "Post",
                  userid: getUserInfo() ? getUserInfo().id : null,
                  active: 1,
                },
                {
                  videophotosuploadedtype: "Uploaded",
                  userid: getUserInfo() ? getUserInfo().id : null,
                  active: 1,
                },
                {
                  videophotosuploadedtype: "Press",
                  userid: getUserInfo() ? getUserInfo().id : null,
                  active: 1,
                },
              ],
            },
          ],
          order: "id Desc",
        },
      },
    });

    // if (!props.team.data) {
    props.schoolTeamUserMappingStart({
      query: getSchoolTeamUserMappingForTeams,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
    // }
    // if (!props.schoolData.data) {
    props.userAcademicSchoolStart({
      query: getLatestSchoolQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
          islatest: 1,
          active: 1,
        },
      },
    });
    // }

    if (JSON.parse(localStorage.getItem("user")).isViewPhotoAndVideo === 0) {
      setVideoOpen(true);
    }
    fakeInput.current.focus();
    fakeInput.current.select();
    getAllParentData();
  }, []);

  const getAllParentData = () => {
    props.athleteParentMappingStart({
      query: allParentQuery,
      variables: {
        obj: {
          athleteid: getUserInfo() ? getUserInfo().id : 0,
          isaccessallowed: 1,
        },
      },
    });
  };

  useEffect(() => {
    if (props.allVideoPhotoInfo.data) {
      let actualProfile = {};
      let videoCount =
        props.allVideoPhotoInfo.data.allUploadedvideoandphotos.filter(
          (item) => item.isphotosorvideoslik !== 0
        ).length;
      let photoCount =
        props.allVideoPhotoInfo.data.allUploadedvideoandphotos.filter(
          (item) => item.isphotosorvideoslik === 0
        ).length;
      if (props.athletesData.data) {
        const { onboardingData } = props.athletesData.data;
        actualProfile = {
          photosname: onboardingData?.profileimages
            ? onboardingData?.profileimages
            : null,
          actualProfilePhoto: true,
          videophotosuploadedtype: "Profile Photo",
          videolink: "null",
          videoname: "null",
          originalname: "Profile Photo",
          isprofileimage: 1,
          isphotosorvideoslik: 0,
        };
      }
      if (actualProfile?.photosname) {
        photoCount += 1;
      }
      // setVideoCount(videoCountT);
      // setPhotoCount(photoCountT);
      setCounts({ videoCount, photoCount });
    }
  }, [props.allVideoPhotoInfo.data, props.athletesData.data]);

  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };

  return (
    <div className="pageContent">
      <BackdropLoader
        open={props.videoEditingList.loading || props.videoDraft.loading}
      />
      <div className="editProfile">
        <input className="fakeInput" type="text" ref={fakeInput} />
        <div className="container">
          <VideoOverlay
            purchasedVideoType={purchasedVideoType}
            videoOpen={videoOpen}
            onVideoClose={videoCloseHandler}
            videoData={videoData}
          />
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
          >
            <SubHeader onSideMenuClick={onSideMenuClick} />
            <div className="sidebar">
              <LeftSideEditProfile />
            </div>
            <EditSection
              open={videoOpenHandler}
              onVideoClose={videoCloseHandler}
              athletesData={props.athletesData ? props.athletesData : null}
              saveDraft={props.saveVideoDraft}
              videoList={props.videoEditingList && props.videoEditingList.data}
              school={props.schoolData}
              counts={counts}
              // videoCount={videoCount}
              // photoCount={photoCount}
              parentInfo={props.allParentData}
              getPurchasedVideoType={getPurchasedVideoType}
              {...props}
            />
          </div>
        </div>
      </div>
      <GoTopButton goTop={goTop} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getPremiumUser: state.athlete.account.allMemberships,
    allMasterPlan: state.athlete.editUserProfileData.allMasterPlan,
    team: state.athlete.editUserProfileData.schoolTeamUserMapping,
    schoolData: state.athlete.editUserProfileData.userAcademicSchool,
    athletesData: state.common.athletesData,
    videoDraft: state.athlete.editUserProfileData.getVideoDraft,
    videoEditingList: state.athlete.editUserProfileData.videoEditingDraft,
    allVideoPhotoInfo: state.athlete.editUserProfileData.allVideoPhotoInfo,
    allParentData: state.athlete.onBoarding.athleteParentMapping,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    membershipList: (data) => dispatch(membershipListStart(data)),
    getAllMasterPlan: (data) => dispatch(getMasterPlanStart(data)),
    getVideoOrderPlan: (data) => dispatch(getVideoOrderStart(data)),
    saveVideoOrder: (data) => dispatch(saveVideoOrderStart(data)),
    getVideoDraft: (data) => dispatch(getVideoDraftStart(data)),
    saveVideoDraft: (data) => dispatch(saveVideoDraftStart(data)),
    allVideoPhotoListStart: (data) => dispatch(allVideoPhotoListStart(data)),
    updateBasicUserProfileListStart: (data) =>
      dispatch(updateBasicUserProfileListStart(data)),
    athleteParentMappingStart: (data) =>
      dispatch(athleteParentMappingStart(data)),
    schoolTeamUserMappingStart: (data) =>
      dispatch(schoolTeamUserMappingStart(data)),
    userAcademicSchoolStart: (data) => dispatch(userAcademicSchoolStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalEdit);
