import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { imageDownloadUrl } from '../../../../../../config';
import { collegeAdmissionsListStart } from '../../../../../../redux/athlete/dashboard/colleges/collegeAdmissions';
import { getUserInfo } from '../../../../../../services/authService';
import recruitingImage from "../assets/img/recruiting_img2.png";
import { getAllCollegeAdmissionsQuery } from './CollegeDetailsQuery';
import TeamLogoPng from "../assets/img/TeamLogo.png";
import SideBarAthleteColleges from './SideBarAthleteColleges';
import BackdropLoader from "../../../../../common/Loader";
import {Link} from "react-router-dom";
const Admissions = (props) => {
  const [admissionData, setAdmissionData] = useState(null);
  useEffect(() => {
    getAllCollegeAdmissionsData();
  }, [])

  useEffect(() => {
    if (props.collegeAdmissions && props.collegeAdmissions.data && props.collegeAdmissions.data[0]) {
      setAdmissionData(props.collegeAdmissions.data[0]);
    }
  }, [props.collegeAdmissions.data])

  const getAllCollegeAdmissionsData = () => {
    props.collegeAdmissionsListStart({
      query: getAllCollegeAdmissionsQuery,
      variables: {
        where: {
          collegeid: props?.collegeid
        }
      }
    })
  }
  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return  match[1] + '-' + match[2] + '-' + match[3]
    }else{
      return str
    }
  }

  return (
    <>
      <div id="Admissions">
        <BackdropLoader open={props.collegeAdmissions.loading} />
        <div className="page-with-sidebar">
          <SideBarAthleteColleges handleSimilarCollgClick={props?.handleSimilarCollgClick ? props.handleSimilarCollgClick : () => console.log('FuncnClicked')}/>
          <div className="admissions-data">
            <div className="item">
              <h3 className="box-title">Admission Overview</h3>
              <ul>
                <li>
                  <span>Applicants Accepted:</span> <span>{admissionData && admissionData.applicationaccepted ? admissionData.applicationaccepted.toLocaleString('en-US', {maximumFractionDigits:2}) : "--"}</span>
                </li>
                <li>
                  <span>Acceptance Rate:</span> <span>{!!admissionData?.acceptancerate ? admissionData?.acceptancerate?.toLocaleString('en-US', {maximumFractionDigits:1}) : "--"}%</span>
                </li>
                <li>
                  <span>Total Applicants:</span> <span>{admissionData && admissionData.totalapplicants ? admissionData.totalapplicants.toLocaleString('en-US', {maximumFractionDigits:2}) : "--"}</span>
                </li>
                <li>
                  <span>Full-Time Undergrads:</span> <span>{admissionData && admissionData.fulltimeundergrades ? admissionData.fulltimeundergrades.toLocaleString('en-US', {maximumFractionDigits:2}) : "--"}</span>
                </li>
                <li>
                  <span>Admissions Phone:</span>{" "}
                  <span>{admissionData && admissionData.admissionsphone ?
                      <a className="phone-text" href={`tel:${formatPhoneNumber(admissionData?.admissionsphone)}`}>{formatPhoneNumber(admissionData?.admissionsphone)}</a> : "--"}</span>
                </li>
                <li>
                  <span>Part-Time Undergrads:</span> <span>{admissionData && admissionData.partTimeundergrads ? admissionData.partTimeundergrads.toLocaleString('en-US', {maximumFractionDigits:2}) : "--"}</span>
                </li>
                <li>
                  <span>Full-Time Retention Rate:</span>{" "}
                  <span>{admissionData && admissionData.fulltimeretentionrate ? admissionData.fulltimeretentionrate : "--"}</span>
                </li>
                <li>
                  <span>Open Admissions:</span> <span>{admissionData && admissionData.openadmission ? admissionData.openadmission : "--"}</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <h3 className="box-title">Application</h3>
              <ul>
                <li>
                  <span>Application Deadline:</span>{" "}
                  <span>{admissionData && admissionData.applicationdeadline ? admissionData.applicationdeadline : "--"}</span>
                </li>
                <li>
                  <span>Early Action Deadline:</span>{" "}
                  <span className="gray-text">{admissionData && admissionData.earlyactiondeadline ? admissionData.earlyactiondeadline : "--"}</span>
                </li>
                <li>
                  <span>Early Decision Acceptance Rate:</span>{" "}
                  <span>{`${admissionData && admissionData.earlydecisionacceptancerate ? parseFloat(admissionData.earlydecisionacceptancerate)?.toFixed(2) + "%" : "--"}`}</span>
                </li>
                <li>
                  <span>Early Decision Deadline:</span>{" "}
                  <span className="gray-text">{admissionData && admissionData.earlydecisiondeadline ? admissionData.earlydecisiondeadline : "--"}</span>
                </li>
                <li>
                  <span>Application Website:</span>{" "}
                  <span className="link">
                    <a href={admissionData && admissionData.applicationwebsite ? admissionData.applicationwebsite : "#"} target="_blank">{admissionData && admissionData.applicationwebsite ? admissionData.applicationwebsite : "--"}</a>
                  </span>
                </li>
                <li>
                  <span>Application Fee:</span> <span> {admissionData && admissionData.applicationfee ? "$" + admissionData.applicationfee : "--"}</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <h3 className="box-title">Student Primary Residence</h3>
              <ul>
                <li>
                  <span>In-State:</span> <span>{admissionData && admissionData.instate ? admissionData.instate + "%" : "--"}</span>
                </li>
                <li>
                  <span>Out-Of-State:</span> <span>{admissionData && admissionData.outofstate ? admissionData.outofstate + "%" : "--"}</span>
                </li>
                <li>
                  <span>International:</span> <span>{admissionData && admissionData.international ? admissionData.international : "--"}</span>
                </li>
                <li>
                  <span>Unknown:</span> <span>{admissionData?.unknown}</span>
                </li>
                <li>
                  <span>Common States of Residence:</span>{" "}
                  <span>{admissionData && admissionData.commonstatesofresidence ? admissionData.commonstatesofresidence : "--"}</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <h3 className="box-title">Considerations</h3>
              <ul>
                <li>
                  <span>SAT/ACT Requirement:</span>{" "}
                  <span>{admissionData && admissionData.satactrequirement ? admissionData.satactrequirement : "--"}</span>
                </li>
                <li>
                  <span>Recommendations:</span>{" "}
                  <span>{admissionData && admissionData.recommendations ? admissionData.recommendations : "--"}</span>
                </li>
                <li>
                  <span>Transcript:</span> <span>{admissionData && admissionData.transcript ? admissionData.transcript : "--"}</span>
                </li>
                <li>
                  <span>GPA:</span> <span>{admissionData && admissionData.gpa ? admissionData.gpa : "--"}</span>
                </li>
                <li>
                  <span>Class Rank:</span> <span>{admissionData && admissionData.classrank ? admissionData.classrank : "--"}</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <h3 className="box-title">Financial Aid / Cost</h3>
              <ul>
                <li>
                  <span>Receiving Any Financial Aid:</span>{" "}
                  <span>{admissionData && admissionData.receivinganyfinancialaid ? admissionData.receivinganyfinancialaid.toLocaleString('en-US', {maximumFractionDigits:2}) + "%" : "--"}</span>
                </li>
                <li>
                  <span>Net Price:</span>{" "}
                  <span>{admissionData && admissionData.netprice ? "$" + admissionData.netprice.toLocaleString('en-US', {maximumFractionDigits:2}) : "--"}</span>
                </li>
                <li>
                  <span>Financial Aid Website:</span> <span>{admissionData && (!!admissionData?.financialaidwebsite && admissionData?.financialaidwebsite?.includes("http") ) ?
                    <a style={{color:"#00b9ff"}} href={`${admissionData.financialaidwebsite}`}
                    target={'_blank'}
                    >{admissionData.financialaidwebsite}</a> : "--"}</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <h3 className="box-title">Scholarship</h3>
              <ul>
                <li>
                  <span>Federal Grant Aid Amount:</span>{" "}
                  <span>{admissionData && admissionData.federalgrantaidamount ? "$" + admissionData.federalgrantaidamount.toLocaleString('en-US', {maximumFractionDigits:2}) : "--"}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div className="back-button text-right pt-3">
        <Link className="btn green" to="/colleges/search">Back</Link>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    collegeAdmissions: state.athlete.dashboard.colleges.collegeAdmissions,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    collegeAdmissionsListStart: (data) => dispatch(collegeAdmissionsListStart(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Admissions);
