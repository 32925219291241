import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  REQUIREMENTS_LIST_START: "REQUIREMENTS_LIST_START",
  SAVE_REQUIREMENTS_START: "SAVE_REQUIREMENTS_START",
  REQUIREMENTS_SUCCESS: "REQUIREMENTS_SUCCESS",
  REQUIREMENTS_FAILED: "REQUIREMENTS_FAILED",
};

// ============================================ Actions ========================================================
export const getRequirementListStart = (data) => ({
  type: actionType.REQUIREMENTS_LIST_START,
  payload: data,
});

export const saveRequirementData = (data) => ({
    type: actionType.SAVE_REQUIREMENTS_START,
    payload: data,
  });

export const getRequirementSuccess = (data) => ({
  type: actionType.REQUIREMENTS_SUCCESS,
  payload: data,
});

export const getRequirementFailed = (data) => ({
  type: actionType.REQUIREMENTS_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function getRequirementReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.REQUIREMENTS_LIST_START:
      return {
        ...state,
        loading: true,
      };
      case actionType.SAVE_REQUIREMENTS_START:
        return {
          ...state,
          loading: true,
        };
    case actionType.REQUIREMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.REQUIREMENTS_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* requirementListSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            const allRequirementList =  response?.data?.data?.allRequirements?.Requirements;
            yield put(getRequirementSuccess(allRequirementList));
        } else {
            messagePopup("", "Requirement List failed", "error");
            yield put(getRequirementFailed("Requirement List failed"));
        }
    } catch (error) {
        messagePopup("", "Requirement List failed", "error");
        yield put(getRequirementFailed("Requirement List failed"));
    }
}

function* saveRequirementSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true)
        if(response && response.status == 200) {
            const recruitingPhilosophy = response;
            if(action.payload.nextScreen) action.payload.nextScreen();
            yield put(getRequirementSuccess(recruitingPhilosophy));
            
        } else {
          messagePopup('', 'Save Requirement failed', 'error');
          yield put(getRequirementFailed('Failed, Error occured'))
        }
    } catch (error) {
        messagePopup('', 'Save Requirement failed', 'error');
        yield put(getRequirementFailed('Failed, Error occured'))
    }
}

export function* watchRequirementListSaga() {
  yield takeEvery(actionType.REQUIREMENTS_LIST_START, requirementListSaga);
  yield takeEvery(actionType.SAVE_REQUIREMENTS_START, saveRequirementSaga);

}
