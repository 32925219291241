import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ASKED_QUESTIONS_LIST_START: "ASKED_QUESTIONS_LIST_START",
  ASKED_QUESTIONS_SUCCESS: "ASKED_QUESTIONS_SUCCESS",
  ASKED_QUESTIONS_FAILED: "ASKED_QUESTIONS_FAILED",
};

// ============================================ Actions ========================================================
export const askedQuestionsListStart = (data) => ({
  type: actionType.ASKED_QUESTIONS_LIST_START,
  payload: data,
});

export const askedQuestionsSuccess = (data) => ({
  type: actionType.ASKED_QUESTIONS_SUCCESS,
  payload: data,
});

export const askedQuestionsFailed = (data) => ({
  type: actionType.ASKED_QUESTIONS_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function askedQuestionsReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ASKED_QUESTIONS_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ASKED_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ASKED_QUESTIONS_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* askedQuestionsListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      let askedQuestions = response;
      yield put(askedQuestionsSuccess({ allAskedQuestions: askedQuestions }));
    } else {
      messagePopup("", "ASKED QUESTIONS List failed", "error");
      yield put(askedQuestionsFailed("ASKED QUESTIONS List failed"));
    }
  } catch (error) {
    messagePopup("", "ASKED QUESTIONS List failed", "error");
    yield put(askedQuestionsFailed("ASKED QUESTIONS List failed"));
  }
}

export function* watchAskedQuestionsSaga() {
  yield takeEvery(actionType.ASKED_QUESTIONS_LIST_START, askedQuestionsListSaga);
}
