import swal from "sweetalert";

export default async function warningMessagePopup(title,text,icon) {
    const willDelete = await swal({
        title: title,
        text: text,
        icon: icon,
        buttons: {
            confirm: { text: "Ok" },
            cancel: "Cancel",
          },
        dangerMode: true,
    })
    return willDelete
}
