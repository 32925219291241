import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod, fetchGraphMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
import history from "../../../services/history";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  ADD_MY_COMMITMENT_START: "ADD_MY_COMMITMENT_START",
  ADD_MY_COMMITMENT_SUCCESS: "ADD_MY_COMMITMENT_SUCCESS",
  ADD_MY_COMMITMENT_FAILED: "ADD_MY_COMMITMENT_FAILED",
  GET_COMMITMENT_START: "GET_COMMITMENT_START",
  GET_COMMITMENT_SUCCESS: "GET_COMMITMENT_SUCCESS",
  GET_COMMITMENT_FAILED: "GET_COMMITMENT_FAILED",
};

/* ======================================= Actions Creator ==========================================*/
export const addMyCommitmentStart = (data) => ({
  type: actionType.ADD_MY_COMMITMENT_START,
  payload: data,
});

export const getCommitmentStart = (data) => ({
  type: actionType.GET_COMMITMENT_START,
  payload: data,
});

export const addMyCommitmentSuccess = (data) => ({
  type: actionType.ADD_MY_COMMITMENT_SUCCESS,
  payload: data,
});

export const addMyCommitmentFailed = (data) => ({
  type: actionType.ADD_MY_COMMITMENT_FAILED,
  payload: data,
});

export const getCommitmentSuccess = (data) => ({
  type: actionType.GET_COMMITMENT_SUCCESS,
  payload: data,
});

export const getCommitmentFailed = (data) => ({
  type: actionType.GET_COMMITMENT_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================

const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function commitmentReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ADD_MY_COMMITMENT_START:
      return {
        ...state,
        loading: true,
      };

    case actionType.GET_COMMITMENT_START:
      return { ...state, loading: true };

    case actionType.ADD_MY_COMMITMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.ADD_MY_COMMITMENT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionType.GET_COMMITMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.GET_COMMITMENT_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* addMyCommitmentSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.data &&
        response.data.data.saveCollegecommitment.id
      ) {
        yield put(addMyCommitmentSuccess("Commitment add successfully"));
         if(action.payload.successMsg){
           action.payload.successMsg();
         } 
        if(action.payload.nextScreen){
          if(action.payload.collegeId){
            action.payload.nextScreen(action.payload.collegeId)
          }else{
           if(action.payload.nextScreen) action.payload.nextScreen();
          }
        }
      }
    } else {
      messagePopup("", "Add to favorite college failed", "error");
      yield put(addMyCommitmentFailed("Add to favorite college failed"));
    }
  } 
  catch (error) {
    messagePopup("", "Add to favorite college failed", "error");
    yield put(addMyCommitmentFailed("Add to favorite college failed"));
  }
}
function* getCommitmentSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200) {
      const myCommitment = response.data.data.allCollegecommitments
        .Collegecommitments
        ? response.data.data.allCollegecommitments.Collegecommitments
        : null;
      yield put(getCommitmentSuccess(myCommitment));
    } else {
      messagePopup("", "Fetch Onboarding Data Failed", "error");
      yield put(getCommitmentFailed("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch Onboarding Data Failed", "error");
    yield put(getCommitmentFailed("Failed, Error occured"));
  }
}

export function* watchCommitmentSaga() {
  yield takeEvery(actionType.ADD_MY_COMMITMENT_START, addMyCommitmentSaga);
  yield takeEvery(actionType.GET_COMMITMENT_START, getCommitmentSaga);
}
