import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  COACH_CATCHERS_TIME_RANGES_LIST_START: "COACH_CATCHERS_TIME_RANGES_LIST_START",
  COACH_CATCHERS_TIME_RANGES_SUCCESS: "COACH_CATCHERS_TIME_RANGES_SUCCESS",
  COACH_CATCHERS_TIME_RANGES_FAILED: "COACH_CATCHERS_TIME_RANGES_FAILED",
};

// ============================================ Actions ========================================================
export const coachCatchersTimeRangesListStart = (data) => ({
  type: actionType.COACH_CATCHERS_TIME_RANGES_LIST_START,
  payload: data,
});

export const coachCatchersTimeRangesSuccess = (data) => ({
  type: actionType.COACH_CATCHERS_TIME_RANGES_SUCCESS,
  payload: data,
});

export const coachCatchersTimeRangesFailed = (data) => ({
  type: actionType.COACH_CATCHERS_TIME_RANGES_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function coachCatchersTimeRangesReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.COACH_CATCHERS_TIME_RANGES_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.COACH_CATCHERS_TIME_RANGES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.COACH_CATCHERS_TIME_RANGES_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* coachCatchersTimeRangesListSaga(action) {
  try {
    const response = yield fetchGraphMethod(action.payload.query, null, true);
    if (response && response.status === 200) {
      const allCoachActValue = response.data.data.allCoachcatcherstimeranges.Coachcatcherstimeranges
        ? response.data.data.allCoachcatcherstimeranges.Coachcatcherstimeranges
        : null;
      yield put(coachCatchersTimeRangesSuccess(allCoachActValue));
    } else {
      messagePopup("", "Coach catchers time ranges List failed", "error");
      yield put(coachCatchersTimeRangesFailed("Coach catchers time ranges List failed"));
    }
  } catch (error) {
    messagePopup("", "Coach catchers time ranges List failed", "error");
    yield put(coachCatchersTimeRangesFailed("Coach catchers time ranges List failed"));
  }
}

export function* watchCatchersTimeRangesListSaga() {
  yield takeEvery(actionType.COACH_CATCHERS_TIME_RANGES_LIST_START, coachCatchersTimeRangesListSaga);
}
