import React from "react";
import { Link } from "react-router-dom";
import { getUserInfo } from "../../../../services/authService";

const stepperSvg = {
  profilePhoto: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.582"
      height="23.749"
      viewBox="0 0 28.582 23.749"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a,
          .b {
            fill: #1cb79e;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
          }
          .a {
            stroke: #1cb79e;
          }
          .b {
            stroke: #fff;
          }
          `,
          }}
        />
      </defs>
      <g transform="translate(-0.5 -3.5)">
        <path
          className="a"
          d="M28.082,23.832a2.417,2.417,0,0,1-2.417,2.417H3.917A2.417,2.417,0,0,1,1.5,23.832V10.541A2.417,2.417,0,0,1,3.917,8.125H8.75L11.166,4.5h7.25l2.417,3.625h4.833a2.417,2.417,0,0,1,2.417,2.417Z"
          transform="translate(0)"
        />
        <path
          className="b"
          d="M21.666,18.333A4.833,4.833,0,1,1,16.833,13.5,4.833,4.833,0,0,1,21.666,18.333Z"
          transform="translate(-2.042 -1.75)"
        />
      </g>
    </svg>
  ), 
  twitter: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.544"
      height="26.437"
      viewBox="0 0 32.544 26.437"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a{fill:#1cb79e;}
          `,
          }}
        />
      </defs>
      <path
        className="a"
        d="M32.544,3.131a13.52,13.52,0,0,1-3.336,3.448q.02.382.02.864a19.178,19.178,0,0,1-3.166,10.5A20.284,20.284,0,0,1,22.24,22.29a17.038,17.038,0,0,1-5.332,3.02,19.474,19.474,0,0,1-6.674,1.126A18.584,18.584,0,0,1,0,23.436a14.437,14.437,0,0,0,1.593.092,13.09,13.09,0,0,0,8.293-2.86,6.478,6.478,0,0,1-3.877-1.332,6.562,6.562,0,0,1-2.357-3.3,6.674,6.674,0,0,0,3.015-.112,6.507,6.507,0,0,1-3.833-2.3A6.46,6.46,0,0,1,1.312,9.376V9.294a6.589,6.589,0,0,0,3.025.835A6.632,6.632,0,0,1,2.167,7.75a6.515,6.515,0,0,1-.8-3.175,6.56,6.56,0,0,1,.9-3.355A19.01,19.01,0,0,0,8.355,6.148,18.553,18.553,0,0,0,16.027,8.2a6.529,6.529,0,0,1-.175-1.52,6.432,6.432,0,0,1,1.956-4.72,6.679,6.679,0,0,1,9.594.149A13.3,13.3,0,0,0,31.641.485,6.455,6.455,0,0,1,28.708,4.18a13.343,13.343,0,0,0,3.836-1.049Z"
      />
    </svg>
  ),
  mobile: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.381" height="24.381" viewBox="0 0 24.381 24.381">
    <path id="__TEMP__SVG__" d="M4.889,10.54a19.918,19.918,0,0,0,8.953,8.953l2.984-2.984a1.165,1.165,0,0,1,1.4-.318,15.159,15.159,0,0,0,4.825.762,1.324,1.324,0,0,1,.92.381,1.243,1.243,0,0,1,.412.952v4.762a1.416,1.416,0,0,1-1.333,1.333A22.661,22.661,0,0,1,11.46,21.3a22.745,22.745,0,0,1-8.381-8.381A22.661,22.661,0,0,1,0,1.333,1.274,1.274,0,0,1,.412.413,1.278,1.278,0,0,1,1.333,0H6.1a1.242,1.242,0,0,1,.952.412,1.328,1.328,0,0,1,.381.92,15.159,15.159,0,0,0,.762,4.825,1.3,1.3,0,0,1-.318,1.4Z" fill="#1cb79e"/>
</svg> 
  
  ),
};

export default function Stepper(props) {
  React.useEffect(() => {
    const { Athlete_Sports, gender } = getUserInfo()
      ? getUserInfo()
      : { Athlete_Sports: null, gender: null };
  }, []);

  return (
    <>
      <ul>
        {props.stepperData &&
          props.stepperData.map((item, index) => (
            <li className={item.className} key={index}>
              <Link to={item.link ? item.link : "#"}>
                <span>
                  {item.className === "active"
                    ? stepperSvg[props.stepperName]
                    : null}
                </span>
              </Link>
            </li>
          ))}


        {/* {props.stepperData &&
          props.stepperData.slice(5).map((item, index) => (
            <li className={item.className} key={index}>
              <Link to={item.link ? item.link : "#"}>
                <span>
                  {item.className === "active"
                    ? stepperSvg[props.stepperName]
                    : null}
                </span>
              </Link>
            </li>
          ))} */}
      </ul>
    </>
  );
}
