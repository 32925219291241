import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemSecondaryAction,
} from '@mui/material';
import { Box } from '@mui/system';
import {
  getAllStatLinks,
  createStatLink,
  updateStatLink,
  deleteStatLink,
} from './statLinkQueries';
import {
  statLinkStart,
  createStatLinkStart,
  updateStatLinkStart,
  deleteStatLinkStart,
} from '../../../../../../redux/athlete/onBoarding/statLinks';
import { getUserInfo } from '../../../../../../services/authService';
import { getSqlTimeStamp } from '../../../../../../utils/dateTimeFormatter';
import { imageDownloadUrl } from '../../../../../../config';
import { isValidPlatformLink } from '../../../../../../utils/validationUtils';
import { addSpaceToPascalCase } from '../../../../../../utils/addSpaceToPascalCase';
import InlineLoader from '../../../../../common/InlineLoader';
import { PlatformImg } from '../../../../../../constants';

const options = [
  { label: 'Select Website', value: '' },
  { label: 'GameChanger', value: 'GameChanger' },
  { label: 'MaxPreps', value: 'MaxPreps' },
  { label: 'Hudl', value: 'Hudl' },
  { label: 'Perfect Game', value: 'PerfectGame' },
  { label: 'Prep Baseball Report', value: 'PrepBaseballReport' },
  { label: 'Prospect Wire', value: 'ProspectWire' },
  { label: 'Five Tool', value: 'FiveTool' },
  { label: 'Prospect Select', value: 'ProspectSelect' },
  { label: 'College', value: 'College' },
  { label: 'Other', value: 'Other' },
];

const selectStyle = {
  position: 'relative',
  fontSize: '0.875rem',
  fontWeight: 400,
  color: '#3e3e3e',
  lineHeight: 1.4,
  marginBottom: '10px',
  height: '20px',
};

const iconButtonStyle = {
  marginLeft: '10px',
  borderRadius: '50px',
  fill: 'white',
  width: '36px',
  height: '36px',
  padding: '0',
};

let StatsSection = (props) => {
  const [currentValue, setCurrentValue] = useState({ select: '', text: '' });
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedText, setEditedText] = useState(null);
  const [editedSelect, setEditedSelect] = useState(null);
  const [linkError, setLinkError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [editLinkError, setEditLinkError] = useState(false);
  const [editLinkHelperText, setEditLinkHelperText] = useState('');
  const [platformError, setPlatformError] = useState(false);
  const [platformHelperText, setPlatformHelperText] = useState('');
  const [editPlatformError, setEditPlatformError] = useState(false);
  const [editPlatformHelperText, setEditPlatformHelperText] = useState('');
  const theme = useTheme();

  const resetErrors = () => {
    setLinkError(false);
    setHelperText('');
    setPlatformError(false);
    setPlatformHelperText('');
    setEditLinkError(false);
    setEditLinkHelperText('');
    setEditPlatformError(false);
    setEditPlatformHelperText('');
  };

  const getStatLinks = () => {
    props.getAllStatLinks({
      query: getAllStatLinks,
      variables: {
        obj: {
          athleteId: getUserInfo().id,
          deletedOn: null,
        },
      },
    });
  };

  useEffect(() => {
    getStatLinks();
  }, []);

  const handleOpen = (index) => {
    setDeleteIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();

    // Validate Input
    if (currentValue.select === '') {
      setPlatformError(true);
      setPlatformHelperText('Please select a platform.');
      return;
    }
    if (isValidPlatformLink(currentValue.select, currentValue.text) === false) {
      setLinkError(true);
      setHelperText('Please enter a valid URL, including http:// or https://');
      return;
    }

    resetErrors();

    props.createStatLink({
      query: createStatLink,
      variables: {
        obj: {
          athleteId: getUserInfo().id,
          link: currentValue.text,
          platform: currentValue.select,
        },
      },
    });

    // Clear the current values
    setCurrentValue({ select: '', text: '' });
  };

  const handleEditCancel = () => {
    setEditingIndex(null);
    setEditedSelect(null);
    setEditedText(null);
    resetErrors();
  };

  const handleEditSave = () => {
    // Validate Input
    if (editedSelect === '') {
      setPlatformError(true);
      setPlatformHelperText('Please select a platform.');
      return;
    }
    if (isValidPlatformLink(editedSelect, editedText) === false) {
      setEditLinkError(true);
      setEditLinkHelperText(
        'Please enter a valid URL, including http:// or https://'
      );
      return;
    }

    resetErrors();

    props.updateStatLink({
      query: updateStatLink,
      variables: {
        where: {
          id: props.statLinks[editingIndex].id,
        },
        obj: {
          link: editedText,
          platform: editedSelect,
        },
      },
    });

    setEditingIndex(null);
    setEditedSelect(null);
    setEditedText(null);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditLinkError(false);
    setEditLinkHelperText('');
    setEditedText(props.statLinks[index].link);
    setEditedSelect(props.statLinks[index].platform);
  };

  const handleDelete = () => {
    props.deleteStatLink({
      query: deleteStatLink,
      variables: {
        where: {
          id: props.statLinks[deleteIndex].id,
        },
        obj: {
          deletedOn: getSqlTimeStamp(new Date()),
        },
      },
    });

    setOpen(false);
  };

  return (
    <div id="StatsSection" className="stats-box-item">
      <h4 className="title">Stats</h4>
      <div className="editprofile-common-form">
        <form>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              gap: { xs: theme.spacing(2), md: 0 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: { xs: '100%', md: '30%' },
              }}
            >
              <div style={selectStyle}>
                Website<sup style={{ color: '#c0321f' }}>*</sup>
              </div>
              <FormControl style={{ width: '100%' }}>
                <Select
                  value={currentValue.select}
                  onChange={(e) =>
                    setCurrentValue({
                      ...currentValue,
                      select: e.target.value,
                    })
                  }
                  variant="outlined"
                  fullWidth
                  error={platformError}
                  helperText={platformHelperText}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {addSpaceToPascalCase(option.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: { xs: '100%', md: '50%' },
              }}
            >
              <div className="fieldBox" style={{ width: '100%' }}>
                <div style={selectStyle}>
                  Link<sup style={{ color: '#c0321f' }}>*</sup>
                </div>
                <TextField
                  value={currentValue.text}
                  onChange={(e) =>
                    setCurrentValue({
                      ...currentValue,
                      text: e.target.value,
                    })
                  }
                  variant="outlined"
                  placeholder="Enter Link URL Here"
                  error={linkError}
                  helperText={helperText}
                  fullWidth
                />
              </div>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                paddingTop: { xs: 0, md: '30px' },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                className="btn green"
                style={{ height: '50px' }}
              >
                Add Link
              </Button>
            </Box>
          </Box>
        </form>
      </div>
      <InlineLoader open={props.loading} />
      <List>
        {props.statLinks?.map((item, index) => (
          <ListItem key={index}>
            {editingIndex === index ? (
              <div
                className="editprofile-common-form"
                style={{ width: '100%', paddingTop: '10px' }}
              >
                <form>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      justifyContent: 'space-between',
                      gap: { xs: theme.spacing(2), md: theme.spacing(2) },
                    }}
                  >
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={editedSelect || item.platform}
                        onChange={(e) => setEditedSelect(e.target.value)}
                        variant="outlined"
                        fullWidth
                      >
                        {options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {addSpaceToPascalCase(option.label)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <div className="fieldBox" style={{ width: '100%' }}>
                      <TextField
                        value={editedText}
                        variant="outlined"
                        onChange={(e) => setEditedText(e.target.value)}
                        error={editLinkError}
                        helperText={editLinkHelperText}
                        fullWidth
                      />
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEditSave}
                      className="btn green"
                    >
                      Save
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEditCancel}
                      className="btn"
                    >
                      Cancel
                    </Button>
                  </Box>
                </form>
              </div>
            ) : (
              <>
                <ListItemAvatar>
                  <Avatar>
                    <img
                      src={imageDownloadUrl + '/' + PlatformImg[item.platform]}
                      alt="Platform"
                      style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    '@media (max-width:450px)': {
                      maxWidth: '100px', // Adjust this value as needed
                    },
                  }}
                >
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {addSpaceToPascalCase(item.platform)}
                  </div>
                </ListItemText>
                <ListItemSecondaryAction>
                  <>
                    <button
                      className="btn green"
                      onClick={() => handleEdit(index)}
                      style={iconButtonStyle}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.111"
                        height="20.028"
                        viewBox="0 0 20.111 20.028"
                        style={{ transform: 'translateY(-1px)' }}
                      >
                        <path
                          className="a"
                          d="M19.594,3.23,16.954.589A1.948,1.948,0,0,0,14.205.7a1.95,1.95,0,0,0-.111,2.751l2.64,2.64a1.95,1.95,0,0,0,2.751-.111,1.949,1.949,0,0,0,.109-2.751ZM2.765,12.139l5.28,5.28,8.58-8.578-5.28-5.28-8.58,8.578ZM0,20.1l6.964-1.394-5.57-5.569Z"
                          transform="translate(0 -0.074)"
                        />
                      </svg>
                    </button>
                    <button
                      className="btn"
                      onClick={() => handleOpen(index)}
                      style={iconButtonStyle}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.415"
                        height="20.203"
                        viewBox="0 0 16.415 20.203"
                        style={{ transform: 'translateY(-1px)' }}
                      >
                        <path
                          className="a"
                          d="M62.836,2.525H59.364V1.894A1.894,1.894,0,0,0,57.47,0H54.945a1.894,1.894,0,0,0-1.894,1.894v.631H49.578A1.578,1.578,0,0,0,48,4.1V5.366A.631.631,0,0,0,48.631,6H63.783a.631.631,0,0,0,.631-.631V4.1A1.578,1.578,0,0,0,62.836,2.525Zm-8.523-.631a.632.632,0,0,1,.631-.631H57.47a.632.632,0,0,1,.631.631v.631H54.313Z"
                          transform="translate(-48)"
                        />
                        <path
                          className="a"
                          d="M73.561,184a.2.2,0,0,0-.2.207l.521,10.932a1.892,1.892,0,0,0,1.892,1.8h9.588a1.892,1.892,0,0,0,1.892-1.8l.521-10.932a.2.2,0,0,0-.2-.207Zm9.534,1.578a.631.631,0,1,1,1.263,0v8.207a.631.631,0,1,1-1.263,0Zm-3.157,0a.631.631,0,1,1,1.263,0v8.207a.631.631,0,1,1-1.263,0Zm-3.157,0a.631.631,0,1,1,1.263,0v8.207a.631.631,0,1,1-1.263,0Z"
                          transform="translate(-72.363 -176.74)"
                        />
                      </svg>
                    </button>
                    <button
                      className="btn blue"
                      onClick={() => window.open(item.link, '_blank')}
                      style={iconButtonStyle}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-globe"
                        viewBox="0 0 16 16"
                        style={{ transform: 'translateY(-1px)' }}
                      >
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z" />
                      </svg>
                    </button>
                  </>
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
        ))}
      </List>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Item'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    statLinks: state.athlete.onBoarding.statLinks.data,
    loading: state.athlete.onBoarding.statLinks.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllStatLinks: (data) => dispatch(statLinkStart(data)),
    createStatLink: (data) => dispatch(createStatLinkStart(data)),
    updateStatLink: (data) => dispatch(updateStatLinkStart(data)),
    deleteStatLink: (data) => dispatch(deleteStatLinkStart(data)),
  };
};

StatsSection = connect(mapStateToProps, mapDispatchToProps)(StatsSection);

export default StatsSection;
