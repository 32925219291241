import React from "react";
import Link from "@mui/material/Link";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardFinishedMessage.css";
import "../assets/css/onBoardFinishedMessage.css.map";
import medal from "../assets/img/medal.png";
import { getUserInfo } from "../../../../../services/authService";
import { saveCoachPhoneNumber } from "../OnboardingQuery";
import {
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { connect } from "react-redux";


export function CongratulateCoach(props) {
  const handleSubmit = () => {
    props.saveOnboardingStart({
      query: saveCoachPhoneNumber,
      variables: {
          where: {
              id: getUserInfo().id,     //coach id hai ye
          },
          data: {
              isdoneonboardingprocess:1,
             
          },
        },
      nextScreen: routeNextPage,
    });
  }

  const routeNextPage = () => {
    props.history.push("/coach-homepage")
     };
  return (
    <div>
      <div className="onBoardCongratAthleteSection">
        <div className="congratAthleteBlock">
          <div className="medalImg">
            <img src={medal} alt="medalImg" />
          </div>
          <div className="data">
            <h1 className="heading">
              Great Job {getUserInfo() ? getUserInfo().FirstName : ""}!
            </h1>
            <p className="info">
              Now it's time to join other athletes like you and take your game
              to the next level!
            </p>
            <div className="btnBox">
              <Link to="#"  onClick={handleSubmit}
              // onClick={() => props.history.push("/coach-homepage")}
              >
                Let's Go!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
  
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CongratulateCoach);
// export default CongratulateCoach;
