export const getAllVideoPhotoInfoQuery = `
query allUploadedvideoandphotos($obj:JSON){
    allUploadedvideoandphotos(where:$obj){
        Uploadedvideoandphotos{
            addprofilephotodate
            active
            isprofileimage
            createdby
            createdon
            id
            pressid
            isfeatured
            isphotosorvideoslik
            ispurchasavailable
            isvisible
            jerseycolor
            jerseyno
            photosname
            position
            showontimelineordashboard
            updatedby
            updatedon
            createdon
            uploadedrank
            userid
            videolink
            videoname
            videophotosuploadedtype
            originalname
            tagid
            thumbnail
            albumid
            viewvideoVideoidMap{
               totalCount
            Viewvideos{
             id
             userid
             viewcount
              }
             }
            useracivityUploadedphotosandvideosid{
                Useracivities{
                  id
                  postid
                }
            }
            uploadedvideoandphotoalbumidmaprel{
                Albums{
                  id
                  albumname
                }
              }
            uploadedvideoandphototagidmaprel{
                Tags{
                  id
                  tagnmae
                }
            }
        }
    }
  }
`;

export const saveAllVideoPhotoInfoQuery = `
 mutation saveUploadedvideoandphoto ($obj : UploadedvideoandphotoInput!){
        saveUploadedvideoandphoto (obj :$obj) {
            active
            createdby
            createdon
            id
            isfeatured
            isphotosorvideoslik
            ispurchasavailable
            isvisible
            jerseycolor
            jerseyno
            photosname
            position
            showontimelineordashboard
            updatedby
            updatedon
            uploadedrank
            userid
            videolink
            videoname
            videophotosuploadedtype  
            originalname
            tagid
            albumid
        }
    }
`;
export const deleteUserActivityQuery = (userId) => `
mutation UseracivityDeleteById{
  UseracivityDeleteById (id : ${userId})
}
`;
// export const deleteUserActivityQuery =

export const getAllAlbumListQuery = `
query allAlbums($obj:JSON){
    allAlbums(where:$obj){
        Albums{
            active
            albumname
            id
        }
    }
  }
`;

export const getAllTagListQuery = `
query allTags($obj:JSON){
    allTags(where:$obj){
        Tags{
            id
            tagnmae
        }
    }
  }
`;

export const getPrivatePublicStatusQuery = `
query allUploadprivateorpublicstatuses($obj:JSON){
    allUploadprivateorpublicstatuses(where:$obj){
      Uploadprivateorpublicstatuses{
        isshowpopup
        popupvalue
        userid
        id 
      }
    }
  }
`; 

export const savePrivatePublicStatusQuery = `
 mutation saveUploadprivateorpublicstatus ($obj : UploadprivateorpublicstatusInput!){
      saveUploadprivateorpublicstatus (obj :$obj) {
        isshowpopup
        popupvalue
        userid
        id      
      }
    }
`;
