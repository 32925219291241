import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme();

const useStyles = makeStyles((theme) => {});

export default function BackdropLoader(props) {
  const classes = useStyles();
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Backdrop className={classes.backdrop} open={props.open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </div>
  );
}
