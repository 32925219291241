import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SAVE_MESSAGE_REPLY_START: "SAVE_MESSAGE_REPLY_START",
  MESSAGE_REPLY_LIST_START: "MESSAGE_REPLY_LIST_START",
  MESSAGE_REPLY_LIST_SUCCESS: "MESSAGE_REPLY_LIST_SUCCESS",
  MESSAGE_REPLY_LIST_FAILED: "MESSAGE_REPLY_LIST_FAILED"
};

// ============================================ Actions ========================================================
export const saveMessageReplyListStart = (data) => ({
  type: actionType.SAVE_MESSAGE_REPLY_START,
  payload: data,
});

export const messageReplyListStart = data => ({
  type: actionType.MESSAGE_REPLY_LIST_START,
  payload: data
});

export const messageReplyListSuccess = data => ({
  type: actionType.MESSAGE_REPLY_LIST_SUCCESS,
  payload: data
});

export const messageReplyListFailed = data => ({
  type: actionType.MESSAGE_REPLY_LIST_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function messageReplyListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_MESSAGE_REPLY_START:
      return { ...state, loading: true };

      case actionType.MESSAGE_REPLY_LIST_START:
      return {
        ...state,
        loading: true
      };

    case actionType.MESSAGE_REPLY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.MESSAGE_REPLY_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
function* saveMessageReplySaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      action.payload.nextScreen()
    } else {
      messagePopup("", "Save Message Reply Failed", "error");
      yield put(messageReplyListFailed("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Save Message Reply Failed", "error");
    yield put(messageReplyListFailed("Failed, Error occured"));
  }
}


export function* messageReplyListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        messageReplyListSuccess({data: response.data.data.allMessagereplies.Messagereplies, totalCount: response.data.data.allMessagereplies.totalCount})
      );
    } else {
      messagePopup("", "Message Reply Api failed", "error");
      yield put(messageReplyListFailed("Message Reply Api failed"));
    }
  } catch (error) {
    messagePopup("", "Message Reply Api failed", "error");
    yield put(messageReplyListFailed("Message Reply Api failed"));
  }
}

export function* watchMessageReplyListSaga() {
  yield takeEvery(actionType.SAVE_MESSAGE_REPLY_START, saveMessageReplySaga);
  yield takeEvery(actionType.MESSAGE_REPLY_LIST_START, messageReplyListSaga);
}