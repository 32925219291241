export const fetchAllMetrics = `
query allStatmatrices($where:JSON) {
    allStatmatrices(where:$where){
    Statmatrices {
      id
      active
      matrixname
      statmatrixmappingStatsmatrixIdMap {
        Statmatrixmappings {
          id
          unit
          statsmatrixid
          tabname
          datatype
          datafield
          maximumvalue
          minimumvalue
        }
      }
    }
  }
}
`;

export const fetchAllStatMetricsDetailsQuery = `
query allStatmatrixdetails($where:JSON){
  allStatmatrixdetails(where:$where){
    Statmatrixdetails{
      statmatrixmappingid
      id
      userid
      lasertime
      testdate
      updatedon
      result
      weight
      weblink
      verified
      videoname
      thumbnail
      isvideoorlink
      heightinfeetid
      heightininchecsid
      jumbidinfeet
      jumbidininches
      jumpincentimeter
      reps
      lbs
      photovideoid
      uploadedvideoandphotoStatidMap{
      Uploadedvideoandphotos{
      id
      uploadedrank 
      active
      userid
        }
      }
      statmatrixdetailsjumbidinfeetmaprel{
        Feet{
          feetvalue
        }
      }
      statmatrixdetailsjumbidininchesmaprel{
      Inches{
        inchvalue
      }  
      }
      statmatrixdetailsheightininchecsidmaprel{
        Heightininches{
          inchvalue
        }
      }
      statmatrixdetailsheightinfeetidmaprel{
        Heightinfeets{
          heightvalue
        }
      }
      statmatrixdetailsstatmatrixmappingidmaprel{
        Statmatrixmappings{
          id
          statsmatrixid
          tabname
        }
      }
    }
  }
}
`;

export const saveStatmatrixdetails = `
mutation SaveStatmatrixdetails($obj:StatmatrixdetailsInput!){
  saveStatmatrixdetails(obj:$obj){
    id
    active
    userid
    verified
    videoname
    videodescription
    isvideoorlink
    weblink
  }
}
`;

export const fetchAllMetricsWithDetailsQuery = ({ userid }) => `
query {
  allStatmatrices (where: {active: 1}){
    Statmatrices {
      id
      matrixname
      statmatrixmappingStatsmatrixIdMap(where:{active:1}) {
        Statmatrixmappings {
          unit
          brandlogo
          maximumvalue
          minimumvalue
          placeholdertext
          id
          datatype
          datafield
          tabname
          statsmatrixid
          whichoneisbest
          statmatrixdetailsstatMatrixmappingidMap(where: {userid: ${userid},active: 1}) {
            Statmatrixdetails {
              id
              userid
              lasertime
              testdate
              updatedon
              result
              weight
              weblink
              verified
              videoname
              active
              heightinfeetid
              heightininchecsid
              jumbidinfeet
              jumbidininches
              jumpincentimeter
              result
              reps
              lbs
              statmatrixmappingid
              videodescription
              isvideoorlink
              thumbnail
              eventid
              verifiedbrandlogo
              photovideoid
              uploadedvideoandphotoStatidMap{
              Uploadedvideoandphotos{
              uploadedrank
              id
              }
              }
              statmatrixdetailseventidmaprel{
                Events{
                  createdon
                  eventname
                }
              }
              statmatrixdetailsjumbidinfeetmaprel{
                Feet{
                  feetvalue
                }
              }
              statmatrixdetailsjumbidininchesmaprel{
              Inches{
                inchvalue
              }  
              }
              statmatrixdetailsheightininchecsidmaprel{
                Heightininches{
                  inchvalue
                }
              }
              statmatrixdetailsheightinfeetidmaprel{
                Heightinfeets{
                  heightvalue
                }
              }
              statmatrixdetailsstatmatrixmappingidmaprel{
                Statmatrixmappings{
                  id
                  statsmatrixid
                  tabname
                }
              }
            }
            totalCount
          }
        }
      }
    }
  }
}
`;

export const fetchPositionalMetricsWithDetailsQuery = ({
  userid,
  primarypositionid = null,
  secondarypositionid = null,
}) => `
query {
  allStatsoverviews (where: {active: 1,primarypositionid: ${primarypositionid},secondarypositionid: ${secondarypositionid}}){
    Statsoverviews {
      primarypositionid
      secondarypositionid
      id
      statematrixmappingid
      active
      statsoverviewstatematrixmappingidrel(where:{active:1}) {
        Statmatrixmappings {
          unit
          brandlogo
          maximumvalue
          minimumvalue
          placeholdertext
          id
          datatype
          datafield
          tabname
          statsmatrixid
          whichoneisbest
          statmatrixdetailsstatMatrixmappingidMap(where: {userid: ${userid},active: 1}) {
            Statmatrixdetails {
              id
              userid
              lasertime
              testdate
              updatedon
              result
              weight
              verified
              videoname
              active
              photovideoid              
              heightinfeetid
              heightininchecsid
              jumbidinfeet
              jumbidininches
              result
              statmatrixmappingid
              videodescription
              isvideoorlink
              thumbnail
              eventid
              verifiedbrandlogo
              videoname
              weblink
              uploadedvideoandphotoStatidMap{
              Uploadedvideoandphotos{
                    id
                    uploadedrank 
                    active
                    userid
                  }
              }
              statmatrixdetailseventidmaprel{
                Events{
                  createdon
                  eventname
                }
              }
              statmatrixdetailsjumbidinfeetmaprel{
                Feet{
                  feetvalue
                }
              }
              statmatrixdetailsjumbidininchesmaprel{
              Inches{
                inchvalue
              }  
              }
              statmatrixdetailsheightininchecsidmaprel{
                Heightininches{
                  inchvalue
                }
              }
              statmatrixdetailsheightinfeetidmaprel{
                Heightinfeets{
                  heightvalue
                }
              }
              statmatrixdetailsstatmatrixmappingidmaprel{
                Statmatrixmappings{
                  id
                  statsmatrixid
                  tabname
                }
              }
            }
            totalCount
          }
        }
      }
    }
  }
}
`;
export const getAllJumpData = `
{
  allFeet(where:{active:1}){
    Feet{
      id
      feetvalue
    }
  }
  allInches(where:{active:1}){
    Inches{
      id
      inchvalue
    }
  }
}
`;

export const getAllStatsQuery = `
query allStats($where:JSON){
  allStats(where:$where){
    Stats {
      id
      userid
      teamid
      positionid

    }
  }
}
`;

export const saveAllStatsQuery = `
mutation saveStat($obj:StatInput!){
  saveStat(obj:$obj){
      id
      userid
      teamid
      positionid
     
  }
}
`;

export const getAllSeasonStatsQuery = `
query allStats($where:JSON){
  allStats(where:$where){
    Stats {
      id
      userid
      teamid
      positionid
      seassionstatStatidMap(where:{active:1}){
        Seassionstats{
          seasonstartdate
          seasonenddate
        }
      }
    }
  }
}
`;

export const allStatsfieldbasedonpositionsQuery = `
query allStatsfieldbasedonpositions($where:JSON) {
  allStatsfieldbasedonpositions(where:$where){
    Statsfieldbasedonpositions{
      id
      positionid
      catchingfieldname
      fieldingfieldname
      hittingfieldname
      pitchingfieldname
      hittingheadername
    }
  }
}
`;
export const getAllUserStatsFieldsQuery = (columnList) => {
  return `
query allSeassionstats($where:JSON){
  allSeassionstats(where:$where){
    Seassionstats
    {id,
      seasonstartdate,
      seasonenddate,
      statid
    ${columnList.map((item) => `\n ${item}`)} 
  }
  }
}
`;
};
export const saveSessionStatQuery = `
mutation saveSeassionstat($obj:SeassionstatInput!){
  saveSeassionstat(obj:$obj){
    id
    statid
  }
}
`;

export const saveOnboardingVitalsDataQuery = `
  mutation saveOnboarding($obj: OnboardingInput!){
    saveOnboarding(obj: $obj){
       id
    }
  } 
  `;

  export const updateScoutingRequestQuery = `
  mutation($where:JSON,$data:JSON){
    StatmatrixdetailsUpdateAll(where:$where,data:$data)
  }
  `;

export const allStatsReportMat = `
query allStatmatrices($where:JSON){
  allStatmatrices(where:$where){
    Statmatrices{
      id
      matrixname
      isreportstats
    }
  }
}
`
export const allStatsEventType = `
query allStatseventtypemappings($where: JSON){
  allStatseventtypemappings(where: $where){
    Statseventtypemappings{
      eventtypeid
      statsid
      isshoworg
      statseventtypemappingeventtypeidmaprel{
        Eventcategories{
          id
          categoryname
        }
      }
    }
  }
}
`

export const allStatsTechCompData = `
query allCompanystatsmappings($where:JSON){
  allCompanystatsmappings(where:$where){
    totalCount
    Companystatsmappings{
      substatsid
      companystatsmappingsubstatsidmaprel{
        Statmatrices{
          statmatrixmappingStatsmatrixIdMap{
            Statmatrixmappings{
              id
              statsmatrixid
              placeholdertext
              tabname
              brandlogo
              abbreviation
              maximumvalue
              minimumvalue
              metricsslider
              datatype
              datafield
              unit
              isreportstats
            }
          }
        }
      }
      companystatsmappingtechcompanyidmaprel{
        Stattechcompanies{
          id
          name
        }
      }
    }
  }
}
`

export const allStatsMatCardData = `
query allStatmatrixmappings($where:JSON){
  allStatmatrixmappings(where:$where){
  Statmatrixmappings{
    id
    resultlebelname
    statsmatrixid
    maximumvalue
    minimumvalue
    isreportstats
    whichoneisbest
    placeholdertext
    abbreviation
    tabname
    brandlogo
    unit
    datatype
    datafield
  }
}
}
`
export const saveStatsDetailCreateReport = `
mutation savestats($data:JSON){
  StatmatrixdetailsCreate(data:$data){
    id
    statmatrixmappingid
    result
    testdate
  }
}
`