import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ADD_ADDED_EVENT_FORM_LIST_START: "ADD_ADDED_EVENT_FORM_LIST_START",
  ADD_ADDED_EVENT_FORM_LIST_SAVE_START: "ADD_ADDED_EVENT_FORM_LIST_SAVE_START",
  ADD_ADDED_EVENT_FORM_LIST_SUCCESS: "ADD_ADDED_EVENT_FORM_LIST_SUCCESS",
  ADD_ADDED_EVENT_FORM_LIST_FAILED: "ADD_ADDED_EVENT_FORM_LIST_FAILED",
  ALL_ADD_ADDED_EVENT_FORM_LIST_SUCCESS:
    "ALL_ADD_ADDED_EVENT_FORM_LIST_SUCCESS",
  ALL_ADD_ADDED_EVENT_FORM_LIST_FAILED: "ALL_ADD_ADDED_EVENT_FORM_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const addAddedEventFormListStart = (data) => ({
  type: actionType.ADD_ADDED_EVENT_FORM_LIST_START,
  payload: data,
});

export const addAddedEventFormsaveStart = (data) => ({
  type: actionType.ADD_ADDED_EVENT_FORM_LIST_SAVE_START,
  payload: data,
});

export const addAddedEventFormListSuccess = (data) => ({
  type: actionType.ADD_ADDED_EVENT_FORM_LIST_SUCCESS,
  payload: data,
});

export const addAddedEventFormListFailed = (data) => ({
  type: actionType.ADD_ADDED_EVENT_FORM_LIST_FAILED,
  payload: data,
});
export const allAddAddedEventFormListSuccess = (data) => ({
  type: actionType.ALL_ADD_ADDED_EVENT_FORM_LIST_SUCCESS,
  payload: data,
});

export const allAddAddedEventFormListFailed = (data) => ({
  type: actionType.ALL_ADD_ADDED_EVENT_FORM_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function addAddedEventFormListReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.ADD_ADDED_EVENT_FORM_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ADD_ADDED_EVENT_FORM_LIST_SAVE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ADD_ADDED_EVENT_FORM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ADD_ADDED_EVENT_FORM_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case actionType.ALL_ADD_ADDED_EVENT_FORM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case actionType.ALL_ADD_ADDED_EVENT_FORM_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* addAddedEventFormListSaveSaga(action) {
  try {
  const response = yield fetchGraphMethod(
    action.payload.query,
    action.payload.variables,
    true
  );
 
  if (response && response.status === 200) {
    if (response && response.data && response.data.errors) {
   
      messagePopup("", response.data.errors[0].message.msg,"error");
    } else {
      if (action.payload.handleAlreadyAddedEvent != undefined) {
        action.payload.handleAlreadyAddedEvent();
      }
      yield put(addAddedEventFormListSuccess(""));
    }
  } else {
    messagePopup("", "ADD ADDED EVENT FORM List failed", "error");
    yield put(addAddedEventFormListFailed("ADD EDIT EVENT FORM List failed"));
  }
  }
  catch (error) {
      messagePopup('', 'ADD ADDED EVENT FORM List failed', 'error')
      yield put(addAddedEventFormListFailed('ADD EDIT EVENT FORM List failed'))
  }
}

export function* addAddedEventFormlistSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
  
    if (response && response.status === 200) {
      let allAddAddedEventForm = response;
      yield put(allAddAddedEventFormListSuccess(allAddAddedEventForm));
    } else {
      messagePopup("", "ADD ADDED EVENT FORM List failed", "error");
      yield put(allAddAddedEventFormListFailed("ADD ADDED List failed"));
    }
  } catch (error) {
    messagePopup("", "ADD ADDED EVENT FORM List failed", "error");
    yield put(
      allAddAddedEventFormListFailed("ADD ADDED EVENT FORM List failed")
    );
  }
}

export function* watchAddAddedEventFormListSaveSaga() {
  yield takeEvery(
    actionType.ADD_ADDED_EVENT_FORM_LIST_START,
    addAddedEventFormlistSaga
  );
  yield takeEvery(
    actionType.ADD_ADDED_EVENT_FORM_LIST_SAVE_START,
    addAddedEventFormListSaveSaga
  );
}
