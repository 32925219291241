import React, { useEffect, useState } from 'react';
import ClaimProfileHeader from '../../auth/claimProfile/claimProfileStep1/ClaimProfileHeader';
import DashboardHeader from '../../../layouts/header/DashboardHeader';
import { getAuthToken } from '../../../../services/authService';
import '../assets/css/lading-page.scss';
import { imageDownloadUrl } from '../../../../config';
import { fetchMethod } from '../../../../services/httpService';
import SuccessStories from '../../../common/SuccessStories';
import AthleteTestimonials from '../../../common/AthleteTestimonials';
import CoachTestimonials from '../../../common/CoachTestimonials';
import advisorsData from './advisorData';

const styles = {
  footerSticky: {
    flexShrink: 0,
    position: 'sticky',
    bottom: 0,
    zIndex: 100,
    height: '80px',
    padding: '20px',
    background: 'black',
    display: 'flex',
    alignItems: 'center',
  },
};

const AdvisorLandingPage = (props) => {
  const [headerType, setHeaderType] = useState(true);
  //extract the advisor name from the url
  const advisorName = props.match.params.advisorName;
  const advisorData = advisorsData[advisorName];

  return (
    <>
      {headerType ? <ClaimProfileHeader /> : <DashboardHeader />}
      <section className="staff-bio-sec">
        <div className="container">
          <div className="flexbox">
            <div className="info-box">
              <div className="pic-box">
                <img
                  src={`${imageDownloadUrl}/${advisorData.photo}`}
                  alt={advisorData.fullName}
                />
              </div>
              <h3 className="name">{advisorData.fullName}</h3>
              <p className="position">{advisorData.title}</p>
              <ul className="social-links">
                {advisorData.twitter && (
                  <li>
                    <a
                      href={advisorData.twitter}
                      style={{ color: 'inherit', textDecoration: 'none' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        fill="rgb(255,255,255)"
                        class="bi bi-twitter-x"
                        viewBox="0 0 36 36"
                      >
                        <circle
                          id="background"
                          cx="18"
                          cy="18"
                          r="18"
                          fill="black"
                        />
                        <g
                          style={{
                            transform: 'translate(8px, 8px) scale(1.25)',
                          }}
                        >
                          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                        </g>
                      </svg>
                    </a>
                  </li>
                )}
                {advisorData.facebook && (
                  <li>
                    <a
                      href={advisorData.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        id="_01.facebook"
                        data-name="01.facebook"
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                      >
                        <circle
                          id="background"
                          cx="18"
                          cy="18"
                          r="18"
                          fill="#334c8c"
                        />
                        <path
                          id="icon"
                          d="M65.9,45.34l-.3,2.52a.476.476,0,0,1-.476.424H62.5v7.48a.338.338,0,0,1-.334.341H59.5a.338.338,0,0,1-.334-.343l.014-7.478H57.2a.476.476,0,0,1-.476-.476v-2.52a.476.476,0,0,1,.476-.476h1.973V42.367c0-2.828,1.682-4.367,4.145-4.367h2.018a.476.476,0,0,1,.476.476V40.6a.476.476,0,0,1-.476.476H64.1c-1.339.021-1.591.662-1.591,1.62V44.8h2.933a.476.476,0,0,1,.465.536Z"
                          transform="translate(-43.313 -29.053)"
                          fill="#fff"
                        />
                      </svg>
                    </a>
                  </li>
                )}
                {advisorData.instagram && (
                  <li>
                    <a
                      href={advisorData.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                      >
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x1="0.119"
                            y1="0.881"
                            x2="0.83"
                            y2="0.17"
                            gradientUnits="objectBoundingBox"
                          >
                            <stop offset="0" stop-color="#fee411" />
                            <stop offset="0.052" stop-color="#fedb16" />
                            <stop offset="0.138" stop-color="#fec125" />
                            <stop offset="0.248" stop-color="#fe983d" />
                            <stop offset="0.376" stop-color="#fe5f5e" />
                            <stop offset="0.5" stop-color="#fe2181" />
                            <stop offset="1" stop-color="#9000dc" />
                          </linearGradient>
                        </defs>
                        <g
                          id="sf-instagram"
                          transform="translate(-30.979 -30.978)"
                        >
                          <circle
                            id="Ellipse_4329"
                            data-name="Ellipse 4329"
                            cx="18"
                            cy="18"
                            r="18"
                            transform="translate(30.979 30.978)"
                            fill="url(#linear-gradient)"
                          />
                          <g
                            id="Group_57739"
                            data-name="Group 57739"
                            transform="translate(38.988 38.98)"
                          >
                            <path
                              id="Path_84457"
                              data-name="Path 84457"
                              d="M144.913,131h-7.639a6.179,6.179,0,0,0-6.175,6.175v7.639a6.179,6.179,0,0,0,6.175,6.175h7.639a6.179,6.179,0,0,0,6.175-6.175v-7.639A6.179,6.179,0,0,0,144.913,131Zm3.943,13.821a3.955,3.955,0,0,1-3.951,3.951h-7.639a3.955,3.955,0,0,1-3.951-3.951v-7.639a3.955,3.955,0,0,1,3.951-3.951h7.639a3.955,3.955,0,0,1,3.951,3.951Z"
                              transform="translate(-131.1 -131)"
                              fill="#fff"
                            />
                            <path
                              id="Path_84458"
                              data-name="Path 84458"
                              d="M197.211,192.1a5.111,5.111,0,1,0,5.111,5.111A5.12,5.12,0,0,0,197.211,192.1Zm0,8.214a3.1,3.1,0,1,1,3.1-3.1A3.106,3.106,0,0,1,197.211,200.314Z"
                              transform="translate(-187.221 -187.213)"
                              fill="#fff"
                            />
                            <circle
                              id="Ellipse_4330"
                              data-name="Ellipse 4330"
                              cx="0.864"
                              cy="0.864"
                              r="0.864"
                              transform="matrix(0.987, -0.16, 0.16, 0.987, 14.361, 3.88)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                    </a>
                  </li>
                )}
                {advisorData.linkedin && (
                  <li>
                    <a
                      href={advisorData.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36.073"
                        height="36.073"
                        viewBox="0 0 36.073 36.073"
                      >
                        <g id="sf-linkedin" transform="translate(0 0.001)">
                          <circle
                            id="Ellipse_4331"
                            data-name="Ellipse 4331"
                            cx="18.036"
                            cy="18.036"
                            r="18.036"
                            transform="translate(0 -0.001)"
                            fill="#007ab9"
                          />
                          <g
                            id="Group_57764"
                            data-name="Group 57764"
                            transform="translate(8.703 7.706)"
                          >
                            <path
                              id="Path_84459"
                              data-name="Path 84459"
                              d="M47.178,35.75v7.436H42.867V36.248c0-1.742-.622-2.932-2.183-2.932a2.358,2.358,0,0,0-2.211,1.576,2.951,2.951,0,0,0-.143,1.051v7.242H34.018s.058-11.75,0-12.966H38.33v1.837c-.009.014-.021.029-.029.042h.029v-.042a4.281,4.281,0,0,1,3.886-2.142c2.836,0,4.963,1.853,4.963,5.835ZM29.508,23.969a2.247,2.247,0,1,0-.057,4.481h.028a2.248,2.248,0,1,0,.029-4.481ZM27.324,43.186h4.31V30.219h-4.31Z"
                              transform="translate(-27.068 -23.969)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="detail-info">
              {advisorData.bio.map((paragraph) => {
                return <p>{paragraph}</p>;
              })}
              {advisorData.logoList && (
                <>
                  <h4>Sports Background</h4>
                  <ul className="brands-list">
                    {advisorData.logoList.map((logo, index) => {
                      return (
                        <li key={index}>
                          <img src={`${imageDownloadUrl}/${logo}`} alt={logo} />
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      {advisorData.careerHighlights.length > 0 && (
        <section className="common-staff-sec bg-gray">
          <div className="container">
            <div className="staff-content-box">
              <h3 className="title">Career Highlights</h3>
              <div className="short-text">
                <ul>
                  {advisorData.careerHighlights.map((highlight) => {
                    return <li>{highlight}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}

      {Object.values(advisorData.myPlayerCollegesByDivision).length > 0 && (
        <section className="common-staff-sec">
          <div className="container">
            <div className="staff-content-box">
              <h3 className="title">Colleges my players have played for</h3>
              <div className="short-text">
                {Object.entries(advisorData.myPlayerCollegesByDivision).map(
                  ([key, value]) => {
                    return (
                      value && (
                        <p>
                          <strong>{key}</strong>: {value}
                        </p>
                      )
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      <SuccessStories />
      <AthleteTestimonials />
      <CoachTestimonials />

      <section className="get-started-sec" style={styles.footerSticky}>
        <div className="container">
          <div
            className="get-started-bio-content"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0px',
            }}
          >
            <div
              className="short-text"
              style={{ margin: 0, padding: '0px 10px' }}
            >
              <p>See if Your Son Qualifies for Our Recruiting Program</p>
            </div>
            <div className="button-wrap">
              <a
                className="btn"
                href={`/advisorWebForm?advisorName=${advisorName}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Evaluated
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AdvisorLandingPage;
