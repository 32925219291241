export const getAllAdditionalSportsQuery = `
query {
    allAdditionalsports {
      Additionalsports {
        id
        additionalsportsname
      }
    }
  }
`;

export const getAllLevelQuery = `
query {
    allLevelmasters{
        Levelmasters{
            id
          levelname
        }
      }
  }
`;

export const addAdditionalSportsQuery = `
mutation SaveUseradditionalsports($obj:UseradditionalsportsInput!){
    saveUseradditionalsports(obj:$obj){
      id 
      userid
      active
      startyear
      endyear
      notsureplaysportsincollege
      baseballismyfirstsports
      sportsrecruitable
      useradditionalsportslevelidmaprel {
        Levelmasters {
          levelname
          id
        }
      }
      useradditionalsportsadditionalsportsidmaprel {
        Additionalsports {
          id
          additionalsportsname
        }
      }
    }
  }`;

export const getAllUserAdditionalSportQuery = `
query AllUserAdditionalSport($obj:JSON){
  allUseradditionalsports(where:$obj){
    Useradditionalsports{
      id
      userid
      active
      startyear
      endyear
      additionalsportsid
      notsureplaysportsincollege
      baseballismyfirstsports
      sportsrecruitable
      useradditionalsportslevelidmaprel {
        Levelmasters {
          levelname
          id
        }
      }
      useradditionalsportsadditionalsportsidmaprel {
        Additionalsports {
          id
          additionalsportsname
        }
      }
    }
  }
}
  `;
