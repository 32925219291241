import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import { Link, withRouter } from 'react-router-dom';
import { imageDownloadUrl } from "../../../../../../config";
import { Slider } from '@mui/material';
import moment from 'moment';
import Dialog from "@mui/material/Dialog";
import SharePost from "./SharePost";
import AthleteImage from "../assets/img/john-drew.png";
import BaseballImage from "../assets/img/baseball-position.jpg";
import { getAuthToken, getUserInfo } from "../../../../../../services/authService";
import messagePopup from "../../../../../../utils/messagePopup";
import {profileURLData} from "../../../../../common/getProfileURLData";
export const Report = withRouter((props) => {
    const [isShare, setIsShare] = useState(false);
    const profileAbout = useSelector(profileAbout => profileAbout?.athlete?.editUserProfileData?.getprofileTimelineReport?.data?.profileTimelineAbout?.data?.[0] ?? null),
        profileProjectData = useSelector(profileAbout => profileAbout?.athlete?.editUserProfileData?.getprofileTimelineReport?.data?.profileTimelineAbout?.projectedDivisions ?? null),
        state = useSelector(state => state),
        about = useDispatch(),
        location = useLocation(),
        Athid = profileURLData(location?.pathname, 'athlete_id'),
            // +props?.match?.params?.id,
        pubViewID = JSON.parse(localStorage.getItem('pubViewID')),
        tokenStatus = !!getAuthToken() ? 1 : 0,
        isToken =  profileURLData(location?.pathname, 'token')
            // props?.props?.match?.params?.isToken == 1 ? 1 : 0;
    const fakeInput = useRef(null);

    useEffect(() => {
        const athleteStatusID = !!Athid ? Athid : !!state?.athlete?.dashboard?.home?.athleteProfileStatus?.status ?
            +pubViewID || 0 : !!getUserInfo() ? getUserInfo().id : +pubViewID
        about({ type: "GET_PROFILE_TIMELINE_REPORT_START", payload: { athleteId: athleteStatusID, isAccessToken: isToken } })
        fakeInput.current.focus();
        fakeInput.current.select();
    }, [pubViewID, state?.athlete?.dashboard?.home?.athleteProfileStatus?.status, Athid])

    const calculateAge = (dateOfBirth) => {
        var dob = new Date(dateOfBirth);
        var dobYear = dob.getYear();
        var dobMonth = dob.getMonth();

        var now = new Date();
        var currentYear = now.getYear();
        var currentMonth = now.getMonth();

        var age = {};
        var ageString = "";

        //get years
        let yearAge = currentYear - dobYear;

        //get months
        if (currentMonth >= dobMonth)
            //get months when current month is greater
            var monthAge = currentMonth - dobMonth;
        else {
            yearAge--;
            var monthAge = 12 + currentMonth - dobMonth;
        }

        age = {
            years: yearAge,
            months: monthAge
        };


        if ((age.years > 0) && (age.months > 0))
            ageString = age.years + " years, " + age.months + " months";
        else if ((age.years > 0) && (age.months == 0))
            ageString = age.years + " years";
        else if ((age.years == 0) && (age.months > 0))
            ageString = age.months + " months";
        return ageString;
    }
    const routeToAthleteMessage = () => {
        props.history.push(`/message/advisor/composeMessage/${parseFloat(profileAbout?.advisorid)}`)
    }

    useEffect(()=>{
        if(getUserInfo() && getUserInfo().role !== "ADVISORS"){
            messagePopup("", "Coming Soon", "")
        }
    },[])
    
    return (
        <div className="report-data">
            <input className="fakeInput" type="text" ref={fakeInput} />
            <Dialog open={isShare} className="matDialogCustom">
                <SharePost
                    handleModal={() => setIsShare(!isShare)}
                    modalData={profileAbout}
                />
            </Dialog>
            {getUserInfo() && getUserInfo().role !== "ADVISORS" && getUserInfo().role !== "ATHLETES" ?
            getUserInfo().role === "ADVISORS" ? 
             profileAbout ? (
                <div>
                    <div className="report-profile-info">
                        <h3 className="title">Scouting Reports</h3>
                        <div className="scouting-info-box">
                            <h4>Evaluated by <span>{profileAbout?.advisorFirstName ? profileAbout?.advisorFirstName.concat(profileAbout?.advisorLastName ? " " + profileAbout?.advisorLastName : "") : ""}</span></h4>
                            <p>Last Updated: {profileAbout?.updatedOn ? moment(profileAbout?.updatedOn).format('MM/DD/YYYY') : ""}</p>
                        </div>
                        <div className="profile-info">
                            <div className="pick-box">
                                {profileAbout?.profileImages ?
                                    <img src={`${imageDownloadUrl + "/" + profileAbout?.profileImages || ""}`} alt="" /> :
                                    <span className="initialLetter">
                                        {`${profileAbout?.advisorFirstName?.split("")[0] || ""}${profileAbout?.advisorLastName?.split("")[0] || ''}`}
                                    </span>
                                }
                            </div>
                            <div className="detail-box">
                                <Link onClick={() => { }}>
                                    <h4 className="name">{profileAbout?.advisorFirstName ? profileAbout?.advisorFirstName.concat(profileAbout?.advisorLastName ? " " + profileAbout?.advisorLastName : "") : ""}</h4>
                                </Link>
                                <p>Sportsforce Team</p>
                            </div>
                        </div>
                        <ul>
                            <li><strong>Report:</strong> {profileAbout?.createdOn ? moment(profileAbout?.createdOn).format('MM/DD/YYYY') : ""}</li>
                            <li><strong>Athlete:</strong> {profileAbout?.athleteFirstName ? profileAbout?.athleteFirstName.concat(profileAbout?.athleteLastName ? " " + profileAbout?.athleteLastName : "") : ""}</li>
                            <li><strong>Grad Year:</strong> {profileAbout?.yearValue ? profileAbout?.yearValue : ""}</li>
                            <li><strong>Age:</strong> {profileAbout?.dateOfBirth ? calculateAge(profileAbout?.dateOfBirth) : ""}</li>
                            <li><strong>Projected Position:</strong> {profileAbout?.projectionposition ? profileAbout?.selectionField.concat(profileAbout?.shortform ? ` (${profileAbout?.shortform})` : "") : ""}</li>
                        </ul>
                        <div className="report-flexbox">
                            <div className="item">
                                <div className="report-range-item">
                                    <div className="range-top-bar">
                                        <h4>
                                            Athleticism
                                            <span className="icon">
                                                <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                    <g id="Group_57168" data-name="Group 57168">
                                                        <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </h4>
                                        <div className="range-info">
                                            <div className="item">Rating</div>
                                            <div className="item">{profileAbout?.athleticismlevel ? profileAbout?.athleticismlevel : 0}</div>
                                        </div>
                                    </div>
                                    <div className="range-progress-bar">
                                        <div className="progressbar small">
                                            <div className="progress" style={{ width: `${profileAbout?.athleticismlevel ? ((parseFloat(profileAbout?.athleticismlevel) / 5) * 100) + 0.9 : 3.9}%` }}></div>
                                        </div>
                                        <div className="range-point-number">
                                            <div className="item">0</div>
                                            <div className="item">1</div>
                                            <div className="item">2</div>
                                            <div className="item">3</div>
                                            <div className="item">4</div>
                                            <div className="item">5</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="report-range-item">
                                    <div className="range-top-bar">
                                        <h4>
                                            Skill Level
                                            <span className="icon">
                                                <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                    <g id="Group_57168" data-name="Group 57168">
                                                        <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </h4>
                                        <div className="range-info">
                                            <div className="item">Rating</div>
                                            <div className="item">{profileAbout?.skillLevel ? profileAbout?.skillLevel : 0}</div>
                                        </div>
                                    </div>
                                    <div className="range-progress-bar">
                                        <div className="progressbar small">
                                            <div className="progress" style={{ width: `${profileAbout?.skillLevel ? ((parseFloat(profileAbout?.skillLevel) / 5) * 100) + 0.9 : 3.9}%` }}></div>
                                        </div>
                                        <div className="range-point-number">
                                            <div className="item">0</div>
                                            <div className="item">1</div>
                                            <div className="item">2</div>
                                            <div className="item">3</div>
                                            <div className="item">4</div>
                                            <div className="item">5</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="report-range-item">
                                    <div className="range-top-bar">
                                        <h4>
                                            Academic Rating
                                            <span className="icon">
                                                <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                    <g id="Group_57168" data-name="Group 57168">
                                                        <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </h4>
                                        <div className="range-info">
                                            <div className="item">Rating</div>
                                            <div className="item">{profileAbout?.acadamicrating ? profileAbout?.acadamicrating : 0}</div>
                                        </div>
                                    </div>
                                    <div className="range-progress-bar">
                                        <div className="progressbar small">
                                            <div className="progress" style={{ width: `${profileAbout?.acadamicrating ? ((parseFloat(profileAbout?.acadamicrating) / 5) * 100) + 0.9 : 3.9}%` }}></div>
                                        </div>
                                        <div className="range-point-number">
                                            <div className="item">0</div>
                                            <div className="item">1</div>
                                            <div className="item">2</div>
                                            <div className="item">3</div>
                                            <div className="item">4</div>
                                            <div className="item">5</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="report-diagram">
                                <div className="diagram-box">
                                    <img src={BaseballImage} alt="" />
                                    <ul className="range-point">
                                        <li className={profileAbout?.shortform === "C" ? "active" : ""}>C</li>
                                        <li className={profileAbout?.shortform === "P" ? "active" : ""}>P</li>
                                        <li className={profileAbout?.shortform === "3B" ? "active" : ""}>3B</li>
                                        <li className={profileAbout?.shortform === "SS" ? "active" : ""}>SS</li>
                                        <li className={profileAbout?.shortform === "2B" ? "active" : ""}>2B</li>
                                        <li className={profileAbout?.shortform === "1B" ? "active" : ""}>1B</li>
                                        <li className={profileAbout?.shortform === "LF" ? "active" : ""}>LF</li>
                                        <li className={profileAbout?.shortform === "CF" ? "active" : ""}>CF</li>
                                        <li className={profileAbout?.shortform === "RF" ? "active" : ""}>RF</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="report-range-item">
                            <div className="range-top-bar">
                                <h4>
                                    Projected Level Range
                                    <span className="icon">
                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="Group_57168" data-name="Group 57168">
                                              <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e"/>
                                            </g>
                                        </svg>
                                    </span>
                                </h4>
                                <div className="range-info">
                                    <div className="item">Rating</div>
                                    <div className="item">{profileAbout?.projectedLevelRange ? profileAbout?.projectedLevelRange : "0-0"}</div>
                                </div>  
                            </div>
                            <div className="range-progress-bar">
                                <div className="user-icon" style={{margin: `0 ${profileAbout?.projectedLevelRange ? (((parseFloat(profileAbout?.projectedLevelRange.split("-")[0])*10 + parseFloat(profileAbout?.projectedLevelRange?.split("-")[1])*10)/2) - 2) : 0}% 15px`}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.139" height="18" viewBox="0 0 16.139 18">
                                        <path id="admin-users" d="M13.037,11.68c-2.53,0-3.043-3.834-3.043-3.834-.3-2,.613-4.246,3.009-4.246s3.321,2.251,3.02,4.246C16.024,7.846,15.567,11.68,13.037,11.68Zm0,2.864,3.032-2.028a5.365,5.365,0,0,1,5.038,5.049v2.775A31.434,31.434,0,0,1,13.037,21.6a30.767,30.767,0,0,1-8.069-1.259V17.565A5.107,5.107,0,0,1,9.95,12.572Z" transform="translate(-4.968 -3.6)" fill="#fff"/>
                                    </svg>                                      
                                </div>
                                <div>
                                <Slider
                                    value={profileAbout?.projectedLevelRange ? [profileAbout?.projectedLevelRange?.split("-")[0],profileAbout?.projectedLevelRange?.split("-")[1]] : [0,0]}
                                    min={0}
                                    step={1}
                                    max={10}
                                    aria-labelledby="non-linear-slider"
                                    marks={[
                                        {
                                            value: 0,
                                            scaledValue: 0,
                                            label: "0"
                                          },
                                          {
                                            value: 1,
                                            scaledValue: 1,
                                            label: "1"
                                          },
                                          {
                                            value: 2,
                                            scaledValue: 2,
                                            label: "2"
                                          },
                                          {
                                            value: 3,
                                            scaledValue: 3,
                                            label: "3"
                                          },
                                          {
                                            value: 4,
                                            scaledValue: 4,
                                            label: "4"
                                          },
                                          {
                                            value: 5,
                                            scaledValue: 5,
                                            label: "5"
                                          },
                                          {
                                            value: 6,
                                            scaledValue: 6,
                                            label: "6"
                                          },
                                          {
                                            value: 7,
                                            scaledValue: 7,
                                            label: "7"
                                          },
                                          {
                                            value: 8,
                                            scaledValue: 8,
                                            label: "8"
                                          },
                                          {
                                            value: 9,
                                            scaledValue: 9,
                                            label: "9"
                                          },
                                          {
                                            value: 10,
                                            scaledValue: 10,
                                            label: "10"
                                          },
                                    ]}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className="projected-division-wrap">
                            <div className="top-info">
                                <h5>
                                    Projected Divisions
                                    <span className="icon">
                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="Group_57168" data-name="Group 57168">
                                                <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                            </g>
                                        </svg>
                                    </span>
                                </h5>
                                <div className="info-box">
                                    <div className="item">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                    <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                    <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                </g>
                                            </svg>
                                        </span>
                                        Yes
                                    </div>
                                    <div className="item">
                                        <span className="icon">
                                            <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                        </span>
                                        Maybe
                                    </div>
                                    <button className="btn green">Scale</button>
                                </div>
                            </div>
                            <div className="division-table">
                                <table className="table">
                                    <tr>
                                        <td>Divisions
                                            {/* <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7">
                                                    <path id="arrow-up-b" d="M18.641,17.355l-.068-.077-5.1-5.708a.96.96,0,0,0-.72-.314.973.973,0,0,0-.72.314l-5.1,5.7-.086.095a.549.549,0,0,0-.1.317.6.6,0,0,0,.622.576H18.128a.6.6,0,0,0,.622-.576A.546.546,0,0,0,18.641,17.355Z" transform="translate(18.75 18.256) rotate(180)" fill="#3e3e3e" />
                                                </svg>
                                            </span> */}
                                        </td>
                                        <td>Top
                                            {/* <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7">
                                                    <path id="arrow-up-b" d="M18.641,17.355l-.068-.077-5.1-5.708a.96.96,0,0,0-.72-.314.973.973,0,0,0-.72.314l-5.1,5.7-.086.095a.549.549,0,0,0-.1.317.6.6,0,0,0,.622.576H18.128a.6.6,0,0,0,.622-.576A.546.546,0,0,0,18.641,17.355Z" transform="translate(18.75 18.256) rotate(180)" fill="#3e3e3e" />
                                                </svg>
                                            </span> */}
                                        </td>
                                        <td>High
                                            {/* <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7">
                                                    <path id="arrow-up-b" d="M18.641,17.355l-.068-.077-5.1-5.708a.96.96,0,0,0-.72-.314.973.973,0,0,0-.72.314l-5.1,5.7-.086.095a.549.549,0,0,0-.1.317.6.6,0,0,0,.622.576H18.128a.6.6,0,0,0,.622-.576A.546.546,0,0,0,18.641,17.355Z" transform="translate(18.75 18.256) rotate(180)" fill="#3e3e3e" />
                                                </svg>
                                            </span> */}
                                        </td>
                                        <td>
                                            Mid
                                            {/* <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7">
                                                    <path id="arrow-up-b" d="M18.641,17.355l-.068-.077-5.1-5.708a.96.96,0,0,0-.72-.314.973.973,0,0,0-.72.314l-5.1,5.7-.086.095a.549.549,0,0,0-.1.317.6.6,0,0,0,.622.576H18.128a.6.6,0,0,0,.622-.576A.546.546,0,0,0,18.641,17.355Z" transform="translate(18.75 18.256) rotate(180)" fill="#3e3e3e" />
                                                </svg>
                                            </span> */}
                                        </td>
                                        <td>
                                            Low
                                            {/* <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7">
                                                    <path id="arrow-up-b" d="M18.641,17.355l-.068-.077-5.1-5.708a.96.96,0,0,0-.72-.314.973.973,0,0,0-.72.314l-5.1,5.7-.086.095a.549.549,0,0,0-.1.317.6.6,0,0,0,.622.576H18.128a.6.6,0,0,0,.622-.576A.546.546,0,0,0,18.641,17.355Z" transform="translate(18.75 18.256) rotate(180)" fill="#3e3e3e" />
                                                </svg>
                                            </span> */}
                                        </td>
                                    </tr>
                                    {profileProjectData?.length > 0 ?
                                        profileProjectData?.map(item => {
                                            return <tr>
                                                <td>{item?.collegedivisionname}</td>
                                                <td>{item?.top === "yes" ? <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                        <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                            <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                            <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                        </g>
                                                    </svg>
                                                </span> : item?.top === "maybe" ? <span className="icon">
                                                    <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                </span> : ""}</td>
                                                <td>
                                                    {item?.high === "yes" ? <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                            <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                                <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                                <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            </g>
                                                        </svg>
                                                    </span> : item?.high === "maybe" ? <span className="icon">
                                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                    </span> : ""}
                                                </td>
                                                <td>
                                                    {item?.mid === "yes" ? <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                            <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                                <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                                <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            </g>
                                                        </svg>
                                                    </span> : item?.mid === "maybe" ? <span className="icon">
                                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                    </span> : ""}
                                                </td>
                                                <td>
                                                    {item?.low === "yes" ? <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                            <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                                <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                                <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            </g>
                                                        </svg>
                                                    </span> : item?.low === "maybe" ? <span className="icon">
                                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                    </span> : ""}
                                                </td>
                                            </tr>
                                        })
                                        : <tr>
                                            <td>No data found</td>
                                        </tr>}
                                </table>
                            </div>
                        </div>
                        <h5>Key Stats</h5>
                        <ul className="key-type-list">
                            {profileAbout?.keystats ? profileAbout?.keystats.split(",").map(item => {
                                return <li>{item}</li>
                            })
                                : ""}
                        </ul>
                    </div>
                    <div className="field-box-item">
                        <h5>
                            Scouting Summary <sup>*</sup>
                            <span className="icon">
                                <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <g id="Group_57168" data-name="Group 57168">
                                        <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                    </g>
                                </svg>
                            </span>
                        </h5>
                        <label>(Ex. Describe athletic, academic, performance and intangile)</label>
                        <textarea value={`${profileAbout?.scoutingsummary ? profileAbout?.scoutingsummary : ""}`}></textarea>
                    </div>
                    <div className="field-box-item">
                        <h5>
                            Areas for improvement
                            <span className="icon">
                                <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <g id="Group_57168" data-name="Group 57168">
                                        <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                    </g>
                                </svg>
                            </span>
                        </h5>
                        <label>(Ex. Describe areas for improvement)</label>
                        <textarea value={`${profileAbout?.areasforimprovement ? profileAbout?.areasforimprovement : ""}`}></textarea>
                    </div>
                    <div className="field-box-item">
                        <h5>Additional website link (Schedule, State, Report) <sup>*</sup></h5>
                        {/* <a href={`${profileAbout?.additionalwebsiteLink}`} target={"_blank"}>{profileAbout?.additionalwebsiteLink ? profileAbout?.additionalwebsiteLink : ""}</a> */}
                        <label>(Ex. Schedule, State, profile link, twitter)</label>
                        <a href={`${profileAbout?.additionalwebsiteLink}`} target={"_blank"}>{profileAbout?.additionalwebsiteLink ? profileAbout?.additionalwebsiteLink : ""}</a>
                    </div>
                    <div className="btn-wrap">
                        {/* <button className="btn green">
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.139" height="18" viewBox="0 0 16.139 18">
                                    <path id="admin-users" d="M13.037,11.68c-2.53,0-3.043-3.834-3.043-3.834-.3-2,.613-4.246,3.009-4.246s3.321,2.251,3.02,4.246C16.024,7.846,15.567,11.68,13.037,11.68Zm0,2.864,3.032-2.028a5.365,5.365,0,0,1,5.038,5.049v2.775A31.434,31.434,0,0,1,13.037,21.6a30.767,30.767,0,0,1-8.069-1.259V17.565A5.107,5.107,0,0,1,9.95,12.572Z" transform="translate(-4.968 -3.6)" fill="#fff"/>
                                </svg>                                                                
                            </span>
                            Add to My Recruits
                        </button> */}
                        <button className="btn blue" onClick={() => routeToAthleteMessage()} disabled={profileAbout?.advisorid ? false : true}>
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15.602" viewBox="0 0 18 15.602">
                                    <path id="send" d="M18.4,10.09l-.006,0L2.376,3.444a.884.884,0,0,0-.833.081.924.924,0,0,0-.418.772V8.547a.9.9,0,0,0,.732.884l8.736,1.615a.15.15,0,0,1,0,.295L1.857,12.956a.9.9,0,0,0-.732.883v4.25a.884.884,0,0,0,.4.739.9.9,0,0,0,.5.15.921.921,0,0,0,.357-.073L18.392,12.3l.007,0a1.2,1.2,0,0,0,0-2.205Z" transform="translate(-1.125 -3.375)" fill="#fff" />
                                </svg>
                            </span>
                            Discuss Athlete
                        </button>
                        <button className="btn yellow" onClick={() => setIsShare(!isShare)}>
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14.996" viewBox="0 0 18 14.996">
                                    <path id="Icon_ionic-ios-share-alt" data-name="Icon ionic-ios-share-alt" d="M22.342,13.049,15.73,6.862a.338.338,0,0,0-.262-.112c-.206.009-.469.155-.469.375v3.1a.2.2,0,0,1-.169.192C8.255,11.427,5.485,16.348,4.505,21.456c-.037.2.234.389.361.23A11.952,11.952,0,0,1,14.8,16.653a.232.232,0,0,1,.2.225v3.046a.4.4,0,0,0,.68.248l6.66-6.294a.517.517,0,0,0,.164-.394A.628.628,0,0,0,22.342,13.049Z" transform="translate(-4.502 -6.75)" fill="#fff" />
                                </svg>
                            </span>
                            Share
                        </button>
                    </div>
                </div>) : <div className="report-profile-info">
                <h3 className="title">Scouting Reports</h3>
                <div className="item" style={{ textAlign: "center" }}>
                    <span>No reports yet</span>
                </div>
            </div> : <div>
                <span>Coming Soon</span>
            </div>
            : <div>
                <span>Coming Soon</span>
            </div>
            }
        </div>
    );
})