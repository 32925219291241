export const getAllAskedQuestionsQuery = `
query allFaqs ($where : JSON, $first: Int, $last:Int){
    allFaqs(where: $where, first: $first , last: $last){
      totalCount
      Faqs{
        id
        userid
        question
        answer        
      }
    }
}
`

export const searchAllQuestionsQuery = `
mutation FaqFaqSearch ($searchItem:String , $faqtype:String ,$limit:Int ,$offset:Int){
  FaqFaqSearch(searchItem:$searchItem ,faqtype:$faqtype, limit:$limit ,offset:$offset)
}
`

