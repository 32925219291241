import React, { useEffect, useState } from 'react';
import ClaimProfileHeader from '../auth/claimProfile/claimProfileStep1/ClaimProfileHeader';
import './assets/css/lading-page.scss';
import OwlCarousel from 'react-owl-carousel';
import { VideoPop } from './common/videoPopup';

import { imageDownloadUrl } from '../../../config';
import CoachTestimonials from '../../common/CoachTestimonials';
const successStoriesSlide = {
  loop: true,
  autoplay: true,
  autoHeight: true,
  margin: 10,
  nav: true,
  dots: false,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,
  items: 1,
};

export const ConsultantForm = () => {
  const [vidID, setVidID] = useState(null),
    [isVideoPop, setIsVideoPop] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);
  const openModal = (type) => {
    setVidID('https://www.youtube.com/embed/pzXiI7Oyyp8');
    setIsVideoPop(true);
  };
  return (
    <>
      <ClaimProfileHeader />
      <section
        className="banner-lp-sec"
        style={{
          backgroundImage: `url(${imageDownloadUrl}/img/assessment-banner-img01.jpg)`,
        }}
      >
        <div className="container">
          <div className="banner-flexbox">
            <div className="banner-content">
              <h1 className="title">
                Get An Honest & Objective Evaluation Of Your Son
              </h1>
              <div className="short-text">
                <p>
                  Target the Right Colleges & Maximize <br />
                  Recruiting Opportunities
                </p>
              </div>
              <div className="button-wrap">
                <a
                  className="btn"
                  target={'_blank'}
                  href="https://calendly.com/sportsforce/complete-college-recruiting-consultation?month=2023-03"
                >
                  Schedule Your Honest Recruiting Consultation
                </a>
              </div>
            </div>
            <div className="video-box-wrap">
              <div className="video-img">
                <img
                  src={`${imageDownloadUrl}/img/club-video-thumbnail.png`}
                  alt=""
                />
                <div
                  className="video-play-button"
                  data-toggle="modal"
                  data-target="#VideoModal"
                >
                  <div className="video-play-inner">
                    <button
                      className="play-btn"
                      type={'button'}
                      onClick={() => openModal()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.191"
                        height="22.92"
                        viewBox="0 0 20.191 22.92"
                      >
                        <path
                          id="play"
                          d="M9.086,27.982a2.255,2.255,0,0,1-1.1-.291,2.442,2.442,0,0,1-1.228-2.138V7.492A2.442,2.442,0,0,1,7.982,5.353a2.242,2.242,0,0,1,2.257.028l15.641,9.24a2.226,2.226,0,0,1,0,3.8L10.236,27.663a2.262,2.262,0,0,1-1.151.319Z"
                          transform="translate(-6.754 -5.063)"
                          fill="#c0321f"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="athlete-recruiting-sec">
        <div className="container">
          <div className="flexbox">
            <div className="imgbox">
              <img
                src={'https://cdn.sfrglobal.com/college_recruiting_image.png'}
                alt="Do You Have Any Of These Questions"
              />
            </div>
            <div className="content-box">
              <h1 className="title">Do You Have Any Of These Questions</h1>
              <div className="number-type-row">
                <div className="number-type-col">
                  <ul className="number-type-list">
                    <li>
                      <span className="number-count">1</span> Is he a
                      scholarship level player?
                    </li>
                    <li>
                      <span className="number-count">2</span> When should we
                      start the recruiting process?
                    </li>
                    <li>
                      <span className="number-count">3</span> How can I make
                      this process simple?
                    </li>
                    <li>
                      <span className="number-count">4</span> I get emails all
                      the time from colleges, who should I trust?
                    </li>
                  </ul>
                </div>
                <div className="number-type-col">
                  <ul className="number-type-list">
                    <li>
                      <span className="number-count">5</span> How good is my
                      son? D1, D2, D3, Junior College?
                    </li>
                    <li>
                      <span className="number-count">6</span> What schools are
                      realistic for my son?
                    </li>
                    <li>
                      <span className="number-count">7</span> What camps or
                      showcases should we attend?
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="athlete-roadmap-sec">
        <div className="container">
          <div className="sec-title">A Proven Road Map To Success</div>
        </div>
        <div className="map-img">
          <img
            src={'https://cdn.sfrglobal.com/proven_road_map_to_success.png'}
            alt="A Proven Road Map To Success"
          />
          <img
            className="mobile"
            src={'https://cdn.sfrglobal.com/proveImageMobile.png'}
            alt="A Proven Road Map To Success"
          />
          <div className="brand-listing">
            <div className="item">
              <img
                src={`${imageDownloadUrl}/img/branding-ncaa-logo01.png`}
                alt="ncaa"
              />
            </div>
            <div className="item">
              <img
                src={`${imageDownloadUrl}/img/branding-ncaa-logo02.png`}
                alt="naia"
              />
            </div>
            <div className="item">
              <img
                src={`${imageDownloadUrl}/img/branding-ncaa-logo03.png`}
                alt="cccaa"
              />
            </div>
            <div className="item">
              <img
                src={`${imageDownloadUrl}/img/branding-ncaa-logo04.png`}
                alt="njcaa"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        className="get-started-sec"
        style={{
          backgroundImage: `url(${imageDownloadUrl}/img/assessment-cta-banner-img01.jpg)`,
        }}
      >
        <div className="container">
          <div className="get-started-flexbox">
            <h3 className="sec-title">
              Are You 100% Confident In Your Recruiting Plan?
            </h3>
            <div className="short-text">
              <p>
                Get 100% clarity on your recruiting game plan and meet 1-on-1
                with an expert College Recruiting Advisor.
              </p>
            </div>
            <div className="button-wrap">
              <a className="btn" href={void 0}>
                Get Your Personal Recruiting Roadmap
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="average-helped-sec">
        <div className="container">
          <div className="flexbox">
            <div className="title-box">
              <h4>
                We've helped families save an average of <span>$80,000</span>
              </h4>
            </div>
            <div className="short-text">
              <p>
                Over the last 14 years SportsForce has helped over 1,500
                student-athletes successfully navigate the college recruiting
                process while saving families, on average, a reported $80,000 in
                college expenses. Check out some of our past recruits below.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="lp-team-sec">
        <div className="container">
          <div className="top-block">
            <h2 className="title">
              Helping Athletes Find Their Best College Fit
            </h2>
            <p>
              Every athlete and family's recruiting journey is unique.
              <br /> It's all about finding the right fit.
            </p>
          </div>
          <div className="team-list">
            <div className="item">
              <div className="team-infobox">
                <div className="pic-box">
                  <img
                    src={`${imageDownloadUrl}/staticImg/tyler-dyson.png`}
                    alt="Tyler Dyson"
                  />
                </div>
                <div className="detail-info">
                  <h4 className="name">Tyler Dyson</h4>
                  <h5 className="commited-text">Saint Olaf College</h5>
                  <div className="university-logo">
                    <img
                      src={`${imageDownloadUrl}/staticImg/saint-olaf-college-logo.png`}
                      alt="Saint Olaf College"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="team-infobox">
                <div className="pic-box">
                  <img
                    src={`${imageDownloadUrl}/staticImg/bryce-berkemeier.png`}
                    alt="Bryce Berkemeier"
                  />
                </div>
                <div className="detail-info">
                  <h4 className="name">Bryce Berkemeier</h4>
                  <h5 className="commited-text">Franklin College</h5>
                  <div className="university-logo">
                    <img
                      src={`${imageDownloadUrl}/staticImg/franklin-college-logo.png`}
                      alt="Franklin College"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="team-infobox">
                <div className="pic-box">
                  <img
                    src={`${imageDownloadUrl}/staticImg/chase-brunson.png`}
                    alt="Chase Brunson"
                  />
                </div>
                <div className="detail-info">
                  <h4 className="name">Chase Brunson</h4>
                  <h5 className="commited-text">Loyola Marymount University</h5>
                  <div className="university-logo">
                    <img
                      src={`${imageDownloadUrl}/staticImg/loyola-marymount-university-logo.png`}
                      alt="Loyola Marymount University"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="team-infobox">
                <div className="pic-box">
                  <img
                    src={`${imageDownloadUrl}/staticImg/colin-johnston.png`}
                    alt="Colin Johnston"
                  />
                </div>
                <div className="detail-info">
                  <h4 className="name">Colin Johnston</h4>
                  <h5 className="commited-text">Regis University</h5>
                  <div className="university-logo">
                    <img
                      src={`${imageDownloadUrl}/staticImg/regis-university-logo.png`}
                      alt="Regis University"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="team-infobox">
                <div className="pic-box">
                  <img
                    src={`${imageDownloadUrl}/staticImg/daniel-horowitz.png`}
                    alt="Daniel Horowitz"
                  />
                </div>
                <div className="detail-info">
                  <h4 className="name">Daniel Horowitz</h4>
                  <h5 className="commited-text">Clarkson University</h5>
                  <div className="university-logo">
                    <img
                      src={`${imageDownloadUrl}/staticImg/clarkson-university-logo.png`}
                      alt="Clarkson University"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="team-infobox">
                <div className="pic-box">
                  <img
                    src={`${imageDownloadUrl}/staticImg/deegan-cordova.png`}
                    alt="Deegan Cordova"
                  />
                </div>
                <div className="detail-info">
                  <h4 className="name">Deegan Cordova</h4>
                  <h5 className="commited-text">Vanderbilt Athletics</h5>
                  <div className="university-logo">
                    <img
                      src={`${imageDownloadUrl}/staticImg/vanderbilt-athletics-logo.png`}
                      alt="Vanderbilt Athletics"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="team-infobox">
                <div className="pic-box">
                  <img
                    src={`${imageDownloadUrl}/staticImg/evyn-lewis.png`}
                    alt="Evyn Lewis"
                  />
                </div>
                <div className="detail-info">
                  <h4 className="name">Evyn Lewis</h4>
                  <h5 className="commited-text">Lewis Clark College</h5>
                  <div className="university-logo">
                    <img
                      src={`${imageDownloadUrl}/staticImg/lewis-clark-college-logo.png`}
                      alt="Lewis Clark College"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="team-infobox">
                <div className="pic-box">
                  <img
                    src={`${imageDownloadUrl}/staticImg/jace-gillmore.png`}
                    alt="Jace Gillmore"
                  />
                </div>
                <div className="detail-info">
                  <h4 className="name">Jace Gillmore</h4>
                  <h5 className="commited-text">Santa Clara University</h5>
                  <div className="university-logo">
                    <img
                      src={`${imageDownloadUrl}/staticImg/santa-clara-university-logo.png`}
                      alt="Santa Clara University"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="team-infobox">
                <div className="pic-box">
                  <img
                    src={`${imageDownloadUrl}/staticImg/jackson-bucholz.png`}
                    alt="Jackson Bucholz"
                  />
                </div>
                <div className="detail-info">
                  <h4 className="name">Jackson Bucholz</h4>
                  <h5 className="commited-text">George Fox University</h5>
                  <div className="university-logo">
                    <img
                      src={`${imageDownloadUrl}/staticImg/george-fox-university-logo.png`}
                      alt="George Fox University"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="team-infobox">
                <div className="pic-box">
                  <img
                    src={`${imageDownloadUrl}/staticImg/kade-thompson.png`}
                    alt="Kade Thompson"
                  />
                </div>
                <div className="detail-info">
                  <h4 className="name">Kade Thompson</h4>
                  <h5 className="commited-text">University Of Arizona</h5>
                  <div className="university-logo">
                    <img
                      src={`${imageDownloadUrl}/staticImg/university-of-arizona-logo.png`}
                      alt="University Of Arizona"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-wrap">
            <button className="btn lightblue">Load more</button>
          </div>
        </div>
      </section>

      <section className="success-stories-sec">
        <div className="container">
          <div className="sec-title">
            Here's What Parents are Saying About SportsForce
          </div>
          <OwlCarousel
            className="success-stories-list success-stories-slide owl-carousel owl-theme"
            {...successStoriesSlide}
          >
            <div className="item">
              <div className="pick-box">
                <img
                  className="pick-img"
                  src={`${imageDownloadUrl}/img/connor-Markl-img.png`}
                  alt="Connor Markl"
                />
                <span className="icon-box">
                  <img
                    src={`${imageDownloadUrl}/img/connor-markl-logoimg.png`}
                    alt="Grand Canyon University"
                  />
                </span>
              </div>
              <div className="detail-info-box">
                <div className="short-text">
                  <p>
                    My family will be paying very little for my college
                    education. I would recommend SportsForce because of their
                    hands-on approach.
                  </p>
                </div>
                <div className="info-box">
                  <h4>Connor Markl</h4>
                  <p>Grand Canyon University</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="pick-box">
                <img
                  className="pick-img"
                  src={`${imageDownloadUrl}/img/jimmy-chatfield-img.png`}
                  alt="Jimmy Chatfield"
                />
                <span className="icon-box">
                  <img
                    src={`${imageDownloadUrl}/img/jimmy-chatfield-logoimg.png`}
                    alt="Yale"
                  />
                </span>
              </div>
              <div className="detail-info-box">
                <div className="short-text">
                  <p>
                    The communications directly with coaches, and knowledge of
                    the coaches’ needs & interest was paramount! Coach Thompson
                    made all the difference!{' '}
                  </p>
                </div>
                <div className="info-box">
                  <h4>Jimmy Chatfield</h4>
                  <p>Yale</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="pick-box">
                <img
                  className="pick-img"
                  src={`${imageDownloadUrl}/img/home-success-img-03.png`}
                  alt="Jamir Gibson"
                />
                <span className="icon-box">
                  <img
                    src={`${imageDownloadUrl}/img/jamir-gibson-logoimg.png`}
                    alt="Grambling State"
                  />
                </span>
              </div>
              <div className="detail-info-box">
                <div className="short-text">
                  <p>
                    They know all the ins & outs, a huge network of college
                    connections. If you’re serious about college baseball they
                    are who you want on your side!!!
                  </p>
                </div>
                <div className="info-box">
                  <h4>Jamir Gibson</h4>
                  <p>Grambling State</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="pick-box">
                <img
                  className="pick-img"
                  src={`${imageDownloadUrl}/img/charles-beilenson-img.png`}
                  alt="Charles Beilenson"
                />
                <span className="icon-box">
                  <img
                    src={`${imageDownloadUrl}/img/charles-beilenson-logoimg.png`}
                    alt="Brown"
                  />
                </span>
              </div>
              <div className="detail-info-box">
                <div className="short-text">
                  <p>
                    My advisor kept me on task, helped me talk to coaches, and
                    told me everything I needed to do. I would 100% recommend
                    SportsForce.
                  </p>
                </div>
                <div className="info-box">
                  <h4>Charles Beilenson</h4>
                  <p>Brown</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="pick-box">
                <img
                  className="pick-img"
                  src={`${imageDownloadUrl}/img/bennett-markinson-img.png`}
                  alt="Bennett Markinson"
                />
                <span className="icon-box">
                  <img
                    src={`${imageDownloadUrl}/img/bennett-markinson-logoimg.png`}
                    alt="Northwestern"
                  />
                </span>
              </div>
              <div className="detail-info-box">
                <div className="short-text">
                  <p>
                    I am super excited for the next 4 years at Northwestern!
                    SportsForce and my advisor Ryan Thompson, were super
                    helpful!
                  </p>
                </div>
                <div className="info-box">
                  <h4>Bennett Markinson</h4>
                  <p>Northwestern</p>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <CoachTestimonials whiteBackground />

      <section className="get-started-sec">
        <div className="container">
          <div className="get-started-flexbox">
            <h3 className="sec-title">
              Ready to Take Control of Your Recruiting Plan?
            </h3>
            <div className="short-text">
              <p>
                Learn what scouting benchmarks are needed to unlock recruiting
                offers.
              </p>
            </div>
            <div className="button-wrap">
              <a
                className="btn"
                target={'_blank'}
                href="https://calendly.com/sportsforce/complete-college-recruiting-consultation?month=2023-03"
              >
                Schedule Your Honest Recruiting Consultation
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="athlete-team-sec-outer">
        <div className="container">
          <h3 className="sec-title">
            Built for Athletes & Coaches, By Athletes & Coaches
          </h3>
        </div>
        <div className="athlete-team-sec">
          <div className="container">
            <div className="content-box">
              <h3 className="title">We're on Your Team</h3>
              <div className="short-text">
                <p>
                  As former college athletes, our team understands the
                  recruiting process can be stressful. We're on your team and
                  we're ready to help.
                </p>
                <p>
                  SportsForce provides trusted and accurate data around your
                  recruiting process. Student-athletes shouldn’t have to wonder
                  if activity from college coaches is real.
                </p>
              </div>
              <div className="button-wrap">
                <button className="btn blue">Meet Our Team</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="brand-lp-sec">
        <div className="container">
          <div className="brand-listing">
            <div className="item">
              <img src={`${imageDownloadUrl}/img/ncaa-logo.png`} alt="" />
            </div>
            <div className="item">
              <img src={`${imageDownloadUrl}/img/naia-logo.png`} alt="" />
            </div>
            <div className="item">
              <img src={`${imageDownloadUrl}/img/njcaa-logo.png`} alt="" />
            </div>
            <div className="item">
              <img
                src={`${imageDownloadUrl}/img/california-community-college-athletic-association-logo.png`}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <VideoPop
        isTestSingle={isVideoPop}
        setIsTestSingle={() => setIsVideoPop(false)}
        vidID={vidID}
      />
    </>
  );
};
