import { put, takeEvery } from "redux-saga/effects";
import { getAuthToken, getUserInfo } from "../../../services/authService";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
// import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ATHLETE_PROFILE_DATA_START: "ATHLETE_PROFILE_DATA_START",
  ATHLETE_PROFILE_DATA_SUCCESS: "ATHLETE_PROFILE_DATA_SUCCESS",
  ATHLETE_PROFILE_DATA_FAILED: "ATHLETE_PROFILE_DATA_FAILED",
};

// ============================================ Actions ========================================================
export const athleteProfileDataStart = (data) => ({
  type: actionType.ATHLETE_PROFILE_DATA_START,
  payload: data,
});

export const athleteProfileDataSuccess = (data) => ({
  type: actionType.ATHLETE_PROFILE_DATA_SUCCESS,
  payload: data,
});

export const athleteProfileDataFailed = (data) => ({
  type: actionType.ATHLETE_PROFILE_DATA_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function athleteProfileDataReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ATHLETE_PROFILE_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ATHLETE_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ATHLETE_PROFILE_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* athleteProfileDataSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Publicprofilefieldpreviews/getAthleteProfileData`,
      action.payload.data,
      "post",
      action.payload.data.isAccessToken ? true : false
    );
    if (response && response.status === 200 && response.data.response) {
      let finalData = [];
      finalData = response.data.response;
      yield put(
        athleteProfileDataSuccess({
          allData: finalData,
        })
      );
    } else {
      messagePopup("", "Get Athlete Profile data failed", "error");
      yield put(athleteProfileDataFailed("Get Athlete Profile data failed"));
    }
  } catch (error) {
    messagePopup("", "Get Athlete Profile data failed", "error");
    yield put(athleteProfileDataFailed("Get Athlete Profile data failed"));
  }
}

export function* watchAthleteProfileDataSaga() {
  yield takeEvery(
    actionType.ATHLETE_PROFILE_DATA_START,
    athleteProfileDataSaga
  );
}
