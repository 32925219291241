import React, { useEffect, useState} from 'react'
import ClaimProfileHeader from "../auth/claimProfile/claimProfileStep1/ClaimProfileHeader";
import DashboardHeader from "../../layouts/header/DashboardHeader";
import {getAuthToken} from "../../../services/authService";
import './assets/css/lading-page.scss'
import {fetchMethod} from "../../../services/httpService";

const helpSlide = {
    loop: true,
    nav: true,
    dots: false,
    items: 1,
};

const ContactLP = (props) =>{
    const [headerType, setHeaderType] = useState(true),
          [contactData, setContactData] = useState({}),
        isToken = props?.match?.params?.isToken == 1 ? true : false;
    useEffect(()=>{
        getStatus(props)
        getContactData()
        //for scrolling the page on top
        setTimeout(()=>{
            window.scrollTo(0,0)
        },1000)
    },[])
    const getStatus = (props) =>{
        if (!!getAuthToken() || isToken){
            setHeaderType(false)
        }
    }
    const getContactData = async () =>{
        const _res = await fetchMethod('/api/landingpages/getlandingpagescontactus',null,'GET',null)
        if (_res?.status == 200 && _res?.data?.response?.status == 200){
            setContactData(_res?.data?.response?.data ?? {})
        }else{
            setContactData({})
        }
    }
    return(
        <>
            {
                headerType ?
                    <ClaimProfileHeader/>
                    :
                    <DashboardHeader/>
            }
            <section className="contact-banner-sec">
                <div className="container">
                    <div className="content-box">
                        <h1 className="title">We’d love to hear from you</h1>
                        <div className="short-text">
                            <p>Have questions about our products, features, trials, or pricing? Need a demo? Our teams
                                will help you.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-lp-sec">
                <div className="container">
                    <div className="contact-flexbox">
                        <div className="contact-detail-block">
                            <div className="item">
                                <h3 className="title">Contact SportsForce</h3>
                                <p>{contactData?.contactSportsForce?.content ?? ""}
                                    {/*We want to hear from you! Fill out this form if you would like to know more about our*/}
                                    {/*company.*/}
                                </p>
                                {/*<p>Interested in any of our products? Talk to our experts today</p>*/}
                                <ul className="contact-info-list">
                                    <li>
                                        <a href={void 0}>
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.999"
                                             viewBox="0 0 18 17.999">
                                            <path id="Icon_ionic-ios-call" data-name="Icon ionic-ios-call"
                                                  d="M21.97,18.6a15.119,15.119,0,0,0-3.158-2.113c-.947-.455-1.293-.445-1.963.037-.558.4-.918.778-1.56.637a9.308,9.308,0,0,1-3.135-2.319A9.241,9.241,0,0,1,9.834,11.7c-.136-.647.239-1,.637-1.56.483-.67.5-1.017.037-1.963A14.82,14.82,0,0,0,8.4,5.021c-.689-.689-.843-.539-1.223-.4a6.959,6.959,0,0,0-1.12.6,3.378,3.378,0,0,0-1.345,1.42c-.267.576-.576,1.649,1,4.452a24.839,24.839,0,0,0,4.367,5.824h0l0,0,0,0h0a24.936,24.936,0,0,0,5.824,4.367c2.8,1.574,3.875,1.265,4.452,1a3.321,3.321,0,0,0,1.42-1.345,6.96,6.96,0,0,0,.6-1.12C22.509,19.439,22.664,19.285,21.97,18.6Z"
                                                  transform="translate(-4.49 -4.503)" fill="#c0321f"/>
                                        </svg>
                                    </span>
                                            {contactData?.contactSportsForce?.phoneNumber ?? ""}
                                            {/*888.978.7084*/}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="item">
                                <h3 className="title">Already a Member?</h3>
                                <p>
                                    {contactData?.alreadyMember?.content ?? ""}
                                    {/*Contact our customer service team by email or call, they are available 7 days a week*/}
                                    {/*to answer your questions.*/}
                                </p>
                                <ul className="contact-info-list">
                                    <li>
                                        <a href={void 0}>
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.999"
                                             viewBox="0 0 18 17.999">
                                            <path id="Icon_ionic-ios-call" data-name="Icon ionic-ios-call"
                                                  d="M21.97,18.6a15.119,15.119,0,0,0-3.158-2.113c-.947-.455-1.293-.445-1.963.037-.558.4-.918.778-1.56.637a9.308,9.308,0,0,1-3.135-2.319A9.241,9.241,0,0,1,9.834,11.7c-.136-.647.239-1,.637-1.56.483-.67.5-1.017.037-1.963A14.82,14.82,0,0,0,8.4,5.021c-.689-.689-.843-.539-1.223-.4a6.959,6.959,0,0,0-1.12.6,3.378,3.378,0,0,0-1.345,1.42c-.267.576-.576,1.649,1,4.452a24.839,24.839,0,0,0,4.367,5.824h0l0,0,0,0h0a24.936,24.936,0,0,0,5.824,4.367c2.8,1.574,3.875,1.265,4.452,1a3.321,3.321,0,0,0,1.42-1.345,6.96,6.96,0,0,0,.6-1.12C22.509,19.439,22.664,19.285,21.97,18.6Z"
                                                  transform="translate(-4.49 -4.503)" fill="#c0321f"/>
                                        </svg>
                                    </span>
                                            {contactData?.alreadyMember?.phoneNumber ?? ""}
                                            {/*888.978.7084*/}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="item">
                                <h3 className="title">Partner with SportsForce</h3>
                                <p>
                                    {contactData?.partnerWithSportsForce?.content ?? ""}
                                    {/*Interested in partnering with SportsForce? Email our business development team.*/}
                                </p>
                                <ul className="contact-info-list">
                                    <li>
                                        <a href={void 0}>
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.195" height="13.646"
                                             viewBox="0 0 18.195 13.646">
                                            <path id="emailalt"
                                                  d="M9.115,6.823l-9.1-4.6V1.137A1.085,1.085,0,0,1,.355.329,1.107,1.107,0,0,1,1.155,0h15.92a1.107,1.107,0,0,1,.8.329,1.085,1.085,0,0,1,.337.808V2.221ZM8.546,7.96H9.684l8.529-4.424v8.973a1.152,1.152,0,0,1-1.137,1.137H1.156a1.092,1.092,0,0,1-.8-.337,1.092,1.092,0,0,1-.337-.8V3.536Z"
                                                  transform="translate(-0.018)" fill="#c0321f"/>
                                        </svg>
                                    </span>
                                            {contactData?.partnerWithSportsForce?.emailId ?? ""}
                                            {/*yourmail@sportsforce.com*/}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="item">
                                <h3 className="title">Press & Media</h3>
                                <p>
                                    {contactData?.pressAndMedia?.content ?? ""}
                                    {/*For press and media inquiries, contact Public Relations Director Lauren Pulte. For*/}
                                    {/*general information and access to our media kit, please visit our Press and Media*/}
                                    {/*page.*/}
                                </p>
                                <ul className="contact-info-list">
                                    <li>
                                        <a href={void 0}>
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.999"
                                             viewBox="0 0 18 17.999">
                                            <path id="Icon_ionic-ios-call" data-name="Icon ionic-ios-call"
                                                  d="M21.97,18.6a15.119,15.119,0,0,0-3.158-2.113c-.947-.455-1.293-.445-1.963.037-.558.4-.918.778-1.56.637a9.308,9.308,0,0,1-3.135-2.319A9.241,9.241,0,0,1,9.834,11.7c-.136-.647.239-1,.637-1.56.483-.67.5-1.017.037-1.963A14.82,14.82,0,0,0,8.4,5.021c-.689-.689-.843-.539-1.223-.4a6.959,6.959,0,0,0-1.12.6,3.378,3.378,0,0,0-1.345,1.42c-.267.576-.576,1.649,1,4.452a24.839,24.839,0,0,0,4.367,5.824h0l0,0,0,0h0a24.936,24.936,0,0,0,5.824,4.367c2.8,1.574,3.875,1.265,4.452,1a3.321,3.321,0,0,0,1.42-1.345,6.96,6.96,0,0,0,.6-1.12C22.509,19.439,22.664,19.285,21.97,18.6Z"
                                                  transform="translate(-4.49 -4.503)" fill="#c0321f"/>
                                        </svg>
                                    </span>
                                            {contactData?.pressAndMedia?.phoneNumber ?? ""}
                                            {/*888.978.7084*/}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={void 0}>
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.195" height="13.646"
                                             viewBox="0 0 18.195 13.646">
                                            <path id="emailalt"
                                                  d="M9.115,6.823l-9.1-4.6V1.137A1.085,1.085,0,0,1,.355.329,1.107,1.107,0,0,1,1.155,0h15.92a1.107,1.107,0,0,1,.8.329,1.085,1.085,0,0,1,.337.808V2.221ZM8.546,7.96H9.684l8.529-4.424v8.973a1.152,1.152,0,0,1-1.137,1.137H1.156a1.092,1.092,0,0,1-.8-.337,1.092,1.092,0,0,1-.337-.8V3.536Z"
                                                  transform="translate(-0.018)" fill="#c0321f"/>
                                        </svg>
                                    </span>
                                            {contactData?.pressAndMedia?.emailId ?? ""}
                                            {/*yourmail@sportsforce.com*/}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="item">
                                <h3 className="title">Advertising</h3>
                                <p>
                                    {contactData?.advertising?.content ?? ""}
                                    {/*For advertising inquiries, please email us.*/}
                                </p>
                                <ul className="contact-info-list">
                                    <li>
                                        <a href={void 0}>
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.195" height="13.646"
                                             viewBox="0 0 18.195 13.646">
                                            <path id="emailalt"
                                                  d="M9.115,6.823l-9.1-4.6V1.137A1.085,1.085,0,0,1,.355.329,1.107,1.107,0,0,1,1.155,0h15.92a1.107,1.107,0,0,1,.8.329,1.085,1.085,0,0,1,.337.808V2.221ZM8.546,7.96H9.684l8.529-4.424v8.973a1.152,1.152,0,0,1-1.137,1.137H1.156a1.092,1.092,0,0,1-.8-.337,1.092,1.092,0,0,1-.337-.8V3.536Z"
                                                  transform="translate(-0.018)" fill="#c0321f"/>
                                        </svg>
                                    </span>
                                            {contactData?.advertising?.emailId ?? ""}
                                            {/*yourmail@sportsforce.com*/}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="item">
                                <h3 className="title">Member Accounts & Billing</h3>
                                <p>
                                    {contactData?.memberAccountBilling?.content ?? ""}
                                    {/*For registered member billing and payment information, please email us.*/}
                                </p>
                                <ul className="contact-info-list">
                                    <li>
                                        <a href={void 0}>
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.195" height="13.646"
                                             viewBox="0 0 18.195 13.646">
                                            <path id="emailalt"
                                                  d="M9.115,6.823l-9.1-4.6V1.137A1.085,1.085,0,0,1,.355.329,1.107,1.107,0,0,1,1.155,0h15.92a1.107,1.107,0,0,1,.8.329,1.085,1.085,0,0,1,.337.808V2.221ZM8.546,7.96H9.684l8.529-4.424v8.973a1.152,1.152,0,0,1-1.137,1.137H1.156a1.092,1.092,0,0,1-.8-.337,1.092,1.092,0,0,1-.337-.8V3.536Z"
                                                  transform="translate(-0.018)" fill="#c0321f"/>
                                        </svg>
                                    </span>
                                            {contactData?.memberAccountBilling?.emailId ?? ""}
                                            {/*yourmail@sportsforce.com*/}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="contact-form-block">
                            <h2 className="title">Get in touch with us</h2>
                            <form>
                                <div className="fieldBox flexBox">
                                    <div className="half">
                                        <label>First Name <sup>*</sup></label>
                                        <input type="text" placeholder="First Name"/>
                                    </div>
                                    <div className="half">
                                        <label>Last Name <sup>*</sup></label>
                                        <input type="text" placeholder="Last Name"/>
                                    </div>
                                </div>
                                <div className="fieldBox">
                                    <label>Email <sup>*</sup></label>
                                    <input type="email" placeholder="yourmail@domain.com"/>
                                </div>
                                <div className="fieldBox">
                                    <label>Type of query <sup>*</sup></label>
                                    <select>
                                        <option selected="" value="1">Choose your query</option>
                                        <option value="2">Query 1</option>
                                        <option value="3">Query 2</option>
                                        <option value="4">Query 2</option>
                                        <option value="5">Query 4</option>
                                    </select>
                                </div>
                                <div className="fieldBox">
                                    <label>Mobile Phone</label>
                                    <input type="text" placeholder="888 000 2536"/>
                                </div>
                                <div className="fieldBox">
                                    <label>Your Message</label>
                                    <textarea placeholder="Type your message here !"></textarea>
                                </div>
                                <div className="fieldBox">
                                    <button className="btn">Submit</button>
                                </div>
                                <div className="fieldBox">
                                    <p>By clicking "SUBMIT", I accept the SportsForce <a href="#">Terms of
                                        Service</a> and <a href="#">Privacy Notice.</a></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default ContactLP
