import React, { useState, useEffect, useRef } from "react";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardbaseball.scss";
import "../assets/css/onBoardSteeper.scss";
import { Link } from "react-router-dom";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { connect } from "react-redux";
import BackdropLoader from "../../../../common/Loader";
import { getOnboardingQuery, saveOnboardingQuery, getAllVarsityQuery } from "../OnboardingQuery";
import { getUserInfo } from "../../../../../services/authService";
import usePrevious from "../../../../../utils/usePrevious";
import Stepper from "../Stepper";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import { Dialog } from "@mui/material";
import messagePopup from "../../../../../utils/messagePopup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MenuItem, Select } from "@mui/material";
import { fetchGraphMethod } from "../../../../../services/httpService";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "passed", link: "/onboarding/positions" },
  { className: "passed", link: "/onboarding/physicalMeasurements" },
  { className: "passed", link: "/onboarding/personalRecords" },
  { className: "active", link: "/onboarding/firstYearVarsity" },
  { className: ""  },
  { className: ""  },
  { className: ""  },
  { className: ""  },
  { className: ""  },
  { className: ""  },
  // { className: "" },
];

const FirstYearVarsity = (props) => {
  const [fields, setFields] = useState({});
  const [allVarsity, setAllvarsity] = useState([])
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    // props.history.push("/onboarding/primaryCoach");
    props.history.push("/onboarding/batthrow");
  };
  const fakeInput = useRef(null);
  useEffect(() => {
    setFields({});
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
    getAllVarsity()
  }, []);

  const getAllVarsity = async () =>{
    const _res = await fetchGraphMethod(getAllVarsityQuery,null,true);
    if(_res?.status == 200){
      setAllvarsity(_res?.data?.data?.allVarsities?.Varsities?.length > 0 ?
          _res?.data?.data?.allVarsities?.Varsities : []
      )
    }else{
     setAllvarsity([])
    }
  }

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = { ...fields };
        const { id, yearvarsity } = props.onboarding.data;
        fieldT["id"] = id;
        fieldT["yearvarsity"] = yearvarsity
          ? yearvarsity
        : null
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
    fakeInput.current.focus();
    fakeInput.current.select();
  }, [props]);

  const handleChange = (field, e) => {
    let fieldsT = fields;
    fieldsT[field] = e.target.value;
    setFields({
      ...fieldsT,
    });
  };

  const handleSubmit = () => {
    if (fields?.yearvarsity) {
      props.saveOnboardingStart({
        query: saveOnboardingQuery,
        variables: {
          obj: {
            id: fields.id ? fields.id : 0,
            userid: getUserInfo().id,
            yearvarsity: fields.yearvarsity
              ? parseFloat(fields.yearvarsity)
              : null,
          },
        },
        nextScreen: routeNextPage,
      });
    } else {
      messagePopup("", "Please select first year varsity.", "error");
    }
  };

  return (
    <div>
      <BackdropLoader open={props.onboarding.loading} />
      <input className="fakeInput" type="text" ref={fakeInput} />
      <div className="onBoardBaseballSection">
        <div className="baseBallBlock">
          <div className="stepper">
            <Stepper stepperName="baselevel" stepperData={stepperData} />
          </div>
          <div className="formSection">
          <FormLabel className="headingYear">First Year Varsity</FormLabel>
                              <div className="fieldBox">
                                <FormControl variant="outlined" fullWidth>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={fields?.yearvarsity || ""}
                                      onChange={(e) =>
                                          handleChange("yearvarsity", e)
                                      }
                                      label="Select"
                                      displayEmpty
                                  >
                                    <MenuItem value={""}>
                                      <em>Select</em>
                                    </MenuItem>
                                    {allVarsity.length > 0 ? allVarsity.map(
                                        (item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                              {item.varsityname}
                                            </MenuItem>
                                        )
                                    )
                                        :
                                        <MenuItem value={""}>
                                        </MenuItem>}
                                  </Select>
                                </FormControl>
                              </div>
            <p className="skip">
              Don't know?{" "}
              <span className="sikpBtnBox">
                <Link className="skipit-btn" to="#" onClick={handleClickOpen}>
                  Skip it
                </Link>
              </span>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="#" onClick={(e) => handleSubmit(e)}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            handleClose()
          }
        }}
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    onboarding: state.athlete.onBoarding.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstYearVarsity);