import React from "react";
import "./footer.scss";
import { websiteDefaultUrl } from "../../../config";
export default function Footer() {
  return (
    <div>
      <div className="footerSection">
        <ul>
          <li>
            <a href={void 0} onClick={()=>window?.Beacon('open')}>Help</a>
          </li>
          <li>
            <a href="https://general-faqs.helpscoutdocs.com/" target="_blank">FAQs</a>
          </li>
          <li>
            <a href={void 0} onClick={() => { window.open(`${websiteDefaultUrl}Privacy-Policy`, "_blank") }}>Privacy</a>
          </li>
          <li>
            <a href={void 0} onClick={() => { window.open(`${websiteDefaultUrl}Terms-of-Services`, "_blank") }}>Terms</a>
          </li>
          {/* <li>
            <a href="#">Support</a>
          </li> */}
          {/* <li>
            <a href="#">Legal</a>
          </li> */}
          {/* <li>
            <a href="#">Disclaimer</a>
          </li> */}
        </ul>
      </div>
    </div>
  );
}
