export default [
  {
    studentName: 'Jimmy Chatfield',
    studentPhoto: 'jimmy-chatfield.jpeg',
    schoolName: 'Yale University',
    schoolLogo: '599_yale_university.png',
    text: `We needed critical help in establishing the target school list, but the
    communications from our SportsForce advisor Ryan Thompson directly with
    coaches, and knowledge of the coaches’ needs/priorities/interest was paramount!
    His personal interest, assessments, and coaching were huge. Yale was in our top
    three from the start, so when they offered, it was over!`,
  },
  {
    studentName: 'CJ Whelan',
    studentPhoto: 'cj-whelan.jpeg',
    schoolName: 'Tulane University',
    schoolLogo: '984_tulane_university.png',
    text: `College recruiting is very difficult on your own! SportsForce was very helpful
    through the entire process which became very busy with consistent calls/texts
    from college coaches and was always available to give critical advice. Andrew (our
    personal recruiting advisor) spoke with the Tulane coaches about me (was his
    idea), their team needed pitching and had scholarship money available. Andrew
    was available to consult through the entire process and was very helpful.Tulane
    University. Coaches, team, and school were a perfect match.`,
  },
  {
    studentName: 'Gavin Napier',
    studentPhoto: 'gavin-napier.jpeg',
    schoolName: "Saint Mary's College of California",
    schoolLogo: '425_st_marys_college_of_california.png',
    text: `Based on my scholarship offer, my family is saving an estimated $200,000 over
    my college career. Getting your son recruited to top colleges is very competitive,
    and having a college baseball advisor that knows the rules of engagement, has
    the contacts, and has a proven system that works is a huge help. SportsForce
    helped us identify potential schools to consider/pursue, how the process goes,
    what to expect, and they are very connected with college coaches which helped
    inform us throughout the process. SportsForce also helped us evaluate which
    events to attend, and which ones not to attend based on which schools were
    attending or on past experience.`,
  },
  {
    studentName: 'Cade Nicol',
    studentPhoto: 'cade-nicol.png',
    schoolName: 'Colorado Mesa University',
    schoolLogo: '108_colorado_mesa_university.png',
    text: `There is no comparison to the other “recruiting services” out there. Having
    SportsForce was the key. Someone that has the college coaches’ ear, and when
    they tell a coach they need to look at this player, they do. Coach Thompson
    helped with so many things, answering questions about what college coaches
    were saying or not saying. Constantly giving advice, and helping mapping out
    strategies. Calming us down when college coaches kind of go in recruiting
    hibernation. Also, helping us save a ton of money by knowing which showcases to
    attend or not attend.`,
  },
  {
    studentName: 'Charles Beilenson',
    studentPhoto: 'charles-beilenson.webp',
    schoolName: 'Brown University',
    schoolLogo: '57_brown_university.png',
    text: `I am going to Brown University an Ivy League school because of the strong
    academics and D1 baseball. I have loved playing my whole life and getting the
    chance to continue playing in college is the best thing I could have ever asked
    for. Talking to all the coaches required a new skill that I learned and developed
    throughout the whole process and my SportsForce advisor tremendously helped
    me prepare for college coach conversations.`,
  },
  {
    studentName: 'Kyle Joye',
    studentPhoto: 'kyle-joye.jpeg',
    schoolName: 'Chapman University',
    schoolLogo: '85_chapman_university.png',
    text: `Get independent, qualified, connected, experienced help! SportsForce. Any
    parent that tries to take this process on by themselves is doing a disservice to their
    child...you cannot honestly evaluate and represent them without inherent bias,
    therefore you will project them falsely and set them up for failure! Hand this
    process off to the pro’s...they will be better for it. We will save over $100,000 in
    college costs.`,
  },
  {
    studentName: 'Wyatt Tucker',
    studentPhoto: 'wyatt-tucker.jpeg',
    schoolName: 'University of California - Davis',
    schoolLogo: '485_university_of_california_davis.png',
    text: `SportsForce helped me initiate and maintain contact with college coaches. They
    taught me how to effectively communicate via email and phone, along with helping
    me know what questions to ask. The knowledge I gained from everyone at
    SportsForce made the stressful recruiting process much simpler. SportsForce staff
    was always available to guide me through conversations with coaches which was
    especially helpful when I was asked questions I did not particularly know how to
    answer. I am very excited about UC Davis. I am counting down the days until I
    arrive on campus and meet my new teammates!`,
  },
  {
    studentName: 'Jamir Gibson',
    studentPhoto: 'jamir-gibson.jpeg',
    schoolName: 'Grambling State University',
    schoolLogo: '764_grambling_state_university.png',
    text: `The recruiting process was kind of overwhelming at first, but
    SportsForce started providing clear guidance and we began to feel
    much better. SportsForce can do so much for your college
    opportunity! They know all the ins and outs, and that huge network
    of coach contacts, if you’re serious about college baseball they are
    who you want on your side!!! We will save $80-90k through this
    process!`,
  },
  {
    studentName: 'Phillipe Roy',
    studentPhoto: 'phillipe-roy.jpeg',
    schoolName: 'Virginia Commonwealth University',
    schoolLogo: '1101_virginia_commonwealth_university.png',
    text: `SportsForce and my college baseball advisor assisted me in
    contacting colleges and sending my video to different colleges
    which generated interest. They also helped me with phone calls and
    preparing for campus visits giving me guidelines on what to say and
    do when talking to coaches. My advisor helped with my final
    decision-making process. Our family will save between $48,000 to
    $52,000.`,
  },
  {
    studentName: 'Austin Mowrey',
    studentPhoto: 'austin-mowrey.jpeg',
    schoolName: 'Columbia University',
    schoolLogo: '110_columbia_university_barnard_college.png',
    text: `I committed to Columbia University because it had always been my
    dream to play at a prestigious academic institution with a successful
    baseball program. As an Ivy League school, who had dominated in
    baseball during recent years, Columbia was the fit I was looking for.
    SportsForce helped my recruiting process by setting my profile,
    custom skills video, and connections to college coaches and
    colleges around the country that helped put me on the map.`,
  },
  {
    studentName: 'Matt McNaney',
    studentPhoto: 'matt-mcnaney.jpeg',
    schoolName: 'Trinity University',
    schoolLogo: '981_trinity_university_tx.png',
    text: `SportsForce and my advisor were so helpful in getting me in contact
    with all of the coaches and schools that I was interested in, as well
    as tracking which schools were responding and watching my video.
    The team also gave me and my Dad a ton of advice about what we
    should be doing in regards to emails, camps, and showcases. I am
    going to Trinity University in San Antonio, TX. I choose Trinity
    because I wanted to go to a smaller high academic school close to
    New Mexico with the best baseball possible. My family is saving
    about $100,000 with the academic scholarship that Trinity is offering
    alone because it is a division 3.`,
  },
];
