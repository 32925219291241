import React, { useState, useEffect, useMemo } from "react";
import "../../../../../../assets/css/stylesheet.css";
import "../assets/css/athleteProfileFinalDark.scss";
import {Link, useLocation} from "react-router-dom";
import EditTimeline from "./EditTimeline";
import {Tooltip} from "@mui/material";
import ProfileVideoPhoto from "./ProfileVideoPhoto";
import { getAuthToken } from "../../../../../../services/authService";
import { Report } from "./Report";
import ProfileStats from "./ProfileStats";
import { ProfileAbout } from "./ProfileAbout";
import {useDispatch, useSelector} from "react-redux";
import { checkListPercentage } from "../../../../../../utils/checkListPercentage";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";
import {profileURLData} from "../../../../../common/getProfileURLData";
const optionCarousel = {
  className: "owl-carousel owl-theme",
  loop:true,
  autoPlay:false,
  nav: true,
  dots:false,
  items: 1
}
export default function ProfileTabs({ isActive, isPublicView, isTab, props, checkAthleteRemoveToken }) {
  const [fields, setFields] = useState({
    myProfile: true,
    profilePhoto: true,
    dob: true,
    gaurdians: true,
    hometown: true,
    heightWeight: true,
    videosPhotos: true,
    stats: true,
    academicGPA: true,
    academicTranscript: false,
    academicActSat: false,
    academicPlannedMajor: true,
    events: true,
    references: true,
    offers: false,
  });
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Videos & Photos");
  const state = useSelector((state)=> state?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout ?? [] )
  const isRestrict = useSelector(state => state)
  const athlete = JSON.parse(localStorage.getItem('user'))
  const pubViewID = JSON.parse(localStorage.getItem('pubViewID'))
  const [finalPercentage, setFinalPercentage] = useState(0)
  const [checkListCount, setCheckListCount] = useState(0)
  const { checkListDataPrepare, getFinalPercentage } = checkListPercentage(
    props.allProfileCalculationData.data
  );
  const [tabSel, setTabSel] = useState(1)
  const location = useLocation()
  const Athid = profileURLData(location?.pathname, 'athlete_id')
      // +props?.match?.params?.id;
  let isToken = profileURLData(location?.pathname, 'token')
      // props?.match?.params?.isToken == 1 ? 1 : 0;
  const profileTabs = useDispatch()
  useEffect(()=>{
    // if(!!getAuthToken()){
      const athleteStatusID = !!Athid ? Athid : !!isRestrict?.athlete?.dashboard?.home?.athleteProfileStatus?.status ?
          +pubViewID || 0 : athlete.id
          profileTabs({type:"GET_PROFILE_TIMELINE_STATUS_START", payload:{
            athleteId: athleteStatusID,
            isAccessToken: isToken
                // !!getAuthToken() ? 1 : 0,
          }})
    // }
  },[pubViewID, state?.athlete?.dashboard?.home?.athleteProfileStatus?.status])
  useEffect(()=>{
    setSelectedTab("Videos & Photos");
  },[isTab])

  useEffect(()=>{
    if(props?.checkListData?.data?.allAthletes?.Athletes?.length > 0){
      let athleteData = props?.checkListData?.data?.allAthletes?.Athletes[0];
      let checkListDataT = checkListDataPrepare(athleteData);
      let finalPercentage = getFinalPercentage(checkListDataT);
      let athleteParentData = athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings.length ? athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings : [];
      let vCount = 1;
      let rCount = 1;
      let athleteOnboardingData = [];

      let checklistProfileCount = 0;
      Object.entries(checkListDataT).forEach(item => {
        if(!item[1]['completed']){
          checklistProfileCount++;
        }
      })
      // if(!athleteData.verifieduser){
      //   vCount++
      // }
      if(!athleteData.athletephonenumber){
        rCount++
      }
      if (athleteData.onBoardingUserIdMap && athleteData.onBoardingUserIdMap.Onboardings && athleteData.onBoardingUserIdMap.Onboardings[0]) {
            let onboardingData = athleteData.onBoardingUserIdMap.Onboardings[0];
            athleteOnboardingData = athleteData.onBoardingUserIdMap.Onboardings[0];
            if(!onboardingData.profileimages){
              vCount++
            }
            if(!onboardingData.primaryposition){
              vCount++
            }
            if(!onboardingData.gpascale){
              rCount++
            }
            if(!onboardingData.heightinfeet){
              rCount++
            }
            if(!onboardingData.heightInInches){
              rCount++
            }
            if(!onboardingData.weight){
              rCount++
            }
      }
      
      if (
          athleteData.athleteparentmappingAthleteIdmap &&
          athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings &&
          athleteData.athleteparentmappingAthleteIdmap
            .Athleteparentmappings[0] &&
          athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings[0]
            .id
        ) {
          if(athleteParentData.length){
            if(!athleteParentData[0]?.athleteparentmappingmappingparentidmaprel?.Athleteparents[0]?.parentphonenumber){
              vCount++;
              rCount++;
            }

          }
        }

        if(athleteData.athleteathletegraduationyearmaprel && athleteData.athleteathletegraduationyearmaprel.Graduationyears.length){
          if(!athleteData.athleteathletegraduationyearmaprel.Graduationyears[0]['yearvalue']){
            vCount++;
          }
        }

        if (
          athleteData.athleteathletegraduationyearmaprel.Graduationyears.length &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears[0]?.id &&
          athleteParentData.length && athleteParentData[0]?.athleteparentmappingmappingparentidmaprel?.Athleteparents[0]?.id &&
          athleteOnboardingData?.primaryposition &&
          athleteOnboardingData?.profileimages &&
          athleteData.verifieduser
        ) {
          setCheckListCount(parseInt(checklistProfileCount)
              // +parseInt(rCount)//review count dont have any meaning
          )
        }
        else{
          setCheckListCount(parseInt(checklistProfileCount)
              // +parseInt(vCount)//vCount dont have any meaning
          )
        }
      setFinalPercentage(finalPercentage)
    }
  },[props?.checkListData?.data])
  const onTabsChange = async (e, tab, tabNum) => {
    setTabSel(tabNum)
    await checkAthleteRemoveToken(props?.athleteProfileData?.data?.allData?.data?.[0]?.userId || 0);
    e.preventDefault();
    setOpenMenu(!openMenu)
    setSelectedTab(tab);
  };
  const handleOpenMenu = () => {
    setOpenMenu(true);
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  const onToggleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "myProfile") {
      if (!checked) {
        setFields({
          myProfile: false,
          profilePhoto: false,
          dob: false,
          gaurdians: false,
          hometown: false,
          heightWeight: false,
          videosPhotos: false,
          stats: false,
          academicGPA: false,
          academicTranscript: false,
          academicActSat: false,
          academicPlannedMajor: false,
          events: false,
          references: false,
          offers: false,
        });
      } else {
        setFields({
          ...fields,
          [name]: checked,
        });
      }
    } else {
      setFields({
        ...fields,
        [name]: checked,
      });
    }
  };
  const profileCall = async (e, item) =>{
    let athData = state,
          profileArray = [],
    status = e.target.checked ? 1 : 0
    if(item.publicprofilefieldid === 20){
      athData.map((el,ie)=>{
        if(status){
          if(el.publicprofilefieldid != 34 && el.publicprofilefieldid != 29 && el.publicprofilefieldid != 26){
            profileArray.push(el.id)
          }
        }else  if(el.publicprofilefieldid != 26  && el.publicprofilefieldid != 29 && el.publicprofilefieldid != 34 && status == 0){
            profileArray.push(el.id)
        }
      })
    }else{
      const filData = athData.filter(ed => ed.publicprofilefieldid === item.publicprofilefieldid || ed.publicprofilefieldid === 20)
      if(filData[0].active === 0 && e.target.checked){
        profileArray.push(filData[0].id)
        profileArray.push(filData[1].id)
      }
      else{
        profileArray.push(filData[1].id)
      }
    }
    let athleteStatusID = isRestrict?.athlete?.dashboard?.home?.athleteProfileStatus?.status ?
        isRestrict?.athlete?.dashboard?.home?.athleteProfileStatus?.athleteId || 0 : athlete.id
          await profileTabs({type:"SAVE_PROFILE_TIMELINE_STATUS_START", payload: {
        athleteId: athleteStatusID,
        updateProfileIds:profileArray || [],
        active: status
  },
  getProfileData
})
  }
  const getProfileData = () =>{
    let athleteStatusID = isRestrict?.athlete?.dashboard?.home?.athleteProfileStatus?.status ?
        isRestrict?.athlete?.dashboard?.home?.athleteProfileStatus?.athleteId || 0 : athlete.id
      profileTabs({type:"GET_PROFILE_TIMELINE_STATUS_START", payload:{athleteId: athleteStatusID,
          isAccessToken: !!getAuthToken() ? 1 : 0,}})
  }
  let reportData = useMemo(()=>{
    if(selectedTab === "Timeline")
    return <EditTimeline isPublicView={isPublicView} props={props} />;
    if(selectedTab === "Videos & Photos")
    return <ProfileVideoPhoto isPublicView={isPublicView} props={props}/>;
    if(selectedTab === "Stats")
    return <ProfileStats isPublicView={isPublicView} props={props}/>;
    if(selectedTab === "About")
    return <ProfileAbout isPublicView={isPublicView} props={props}/>;
    if(selectedTab === "Reports")
    return <Report  isPublicView={isPublicView} props={props}/>;
},[selectedTab, Athid]);
  return (
    <div>
      <div className="container">
          <div className="public-setting-wrapper">
            {
              // getAuthToken()
              isActive
                  && (
              <div className="profile-setting-menu">
                <div className="title-box">
                  {state && state.length > 0 ? state.slice(0,1).map((item, idx)=>{
                    return(
                        <div className="form-check form-switch">
                          <label className="form-check-label" htmlFor="Myprofile1">
                            {/*My profile's*/}
                            {/*<br /> public visibility*/}
                            {item?.fieldname ?? "N/A"}
                          </label>
                          <span style={{fontSize:"12px", marginRight:"5px"}}>{item?.active ? "ON" : "OFF"}</span>
                          <input
                              className="form-check-input"
                              type="checkbox"
                              // id="Myprofile1"
                              checked={item.active ?? 0}
                              name="myProfile"
                              onChange={(e) => profileCall(e, item)}
                          />
                        </div>
                    )
                  }) : ""}
                </div>
                <div className="profile-setting-info">
                  <h3>My Public Profile Settings</h3>
                  <p>
                    Information Including Your name, positions, recruiting
                    className, and teams will we seen publicly.
                  </p>
                  <p>
                    Other information like your phone number, email, home address
                    and transcripts are only available upon request from.
                  </p>
                </div>
                <div className="profile-menu">
                  <ul>
                    {state && state.length > 0  ? state.slice(1).map((item, idx)=>{
                      if(item.publicprofilefieldid != (26) && item.publicprofilefieldid != 29 && item.publicprofilefieldid != 34){
                        return(
                            <li>
                              <div className="form-check form-switch">
                                <label
                                    className="form-check-label"
                                    htmlFor="Profilemenu1"
                                >
                                  {item?.fieldname ?? "N/A"}
                                </label>
                                <span style={{fontSize:"12px"}}>{item?.active ? "Public" : "Private"}
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    // id="Profilemenu1"
                                    checked={item?.active ?? 0}
                                    name={item?.fieldname ?? "error"}
                                    onChange={(e) => profileCall(e, item)}
                                /> </span>
                              </div>
                            </li>
                        )
                      }
                    }) : ""}
                  </ul>
                </div>
              </div>
            )}

            <div className="public-setting-details" style={isActive ? {}: { width:"100%" }}>
              <div>
                {!isPublicView &&
                    <>
                <div className="checkListBox">
                  <div className="checkListNotify">
                   <Link to="/checklist">
                   <span className="label">Checklist</span>
                     <span className="icon">
                       <svg xmlns="http://www.w3.org/2000/svg" width="35.582" height="21.05" viewBox="0 0 35.582 21.05">
                         <g id="Group_51845" data-name="Group 51845" transform="translate(-1004 -289)">
                           <path id="Path_2196" data-name="Path 2196" d="M6,9A1.5,1.5,0,0,1,7.5,7.5h21a1.5,1.5,0,0,1,0,3H7.5A1.5,1.5,0,0,1,6,9Z" transform="translate(998 281.5)"/>
                           <path id="Path_2197" data-name="Path 2197" d="M6,27a1.5,1.5,0,0,1,1.5-1.5h21a1.5,1.5,0,0,1,0,3H7.5A1.5,1.5,0,0,1,6,27Z" transform="translate(998 272.5)"/>
                           <path id="Path_2202" data-name="Path 2202" d="M7.5,16.5a1.5,1.5,0,0,0,0,3h12a1.5,1.5,0,0,0,0-3Z" transform="translate(998 290.5)"/>
                           <path id="Path_2201" data-name="Path 2201" d="M24.75,12.375,15.3,23.625l-4.05-4.5" transform="translate(1013 285.125)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.6"/>
                         </g>
                       </svg>
                       <span className="count">{checkListCount}</span>
                     </span>
                   </Link>
                  </div>
                  <Tooltip placement="top" title={"Your progress"} arrow>
                  <div className="progressbar">
                        <div
                          className="progress"
                          style={{ width: `${finalPercentage}%` }}
                        >{`${finalPercentage}%`}</div>
                  </div>
                  </Tooltip>
                </div>
                </>
                }
                <div className={openMenu ? "tabBox profileTab-hide-mobile tabBoxshow" : "tabBox profileTab-hide-mobile"}>
                  <nav className={openMenu ? "show" : ""}>
                    <ul>
                      <li className="closeBtnBox">
                        <button className="closeBtn" onClick={()=>handleCloseMenu()}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.804"
                            height="15.803"
                            viewBox="0 0 15.804 15.803"
                          >
                            <defs>
                              <style></style>
                            </defs>
                            <g
                              className="a"
                              transform="translate(-6.776 -6.776)"
                            >
                              <path
                                d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                                transform="translate(0 0)"
                              />
                            </g>
                          </svg>
                        </button>
                      </li>
                      <li
                        className={selectedTab === "Timeline" ? "selected" : ""}
                      >
                        <Link
                          to="#"
                          onClick={(e) => onTabsChange(e, "Timeline")}
                        >
                          Timeline
                        </Link>
                      </li>
                      <li
                        className={
                          selectedTab === "Videos & Photos" ? "selected" : ""
                        }
                      >
                        <Link
                          to="#"
                          onClick={(e) => {
                          //  !!getAuthToken() ?  isPublicView ?
                          //       isRestrict?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((item, idx)=>{
                          //         if(item?.publicprofilefieldid == 26 && item?.active){
                          //          return  onTabsChange(e, "Videos & Photos")
                          //         }
                          //       })
                          //   :
                          //      onTabsChange(e, "Videos & Photos")
                          //    :
                               onTabsChange(e, "Videos & Photos")
                          }
                          }
                        >
                          Videos & Photos
                          {isPublicView &&
                          !isRestrict?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((item, idx)=>{
                            if(item?.publicprofilefieldid == 26 && item?.active){
                              return  <span className="icon" style={{marginLeft:"10px"}}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.286"
                                height="15.5"
                                viewBox="0 0 13.286 15.5"
                            >
                              <defs>
                                <style></style>
                              </defs>
                              <g transform="translate(-4.5 -2.25)">
                                <path
                                    fill={'#c0321f'}
                                    className="a"
                                    d="M15.571,8.893a2.214,2.214,0,0,1,2.214,2.214v4.429a2.214,2.214,0,0,1-2.214,2.214H6.714A2.214,2.214,0,0,1,4.5,15.536V11.107A2.214,2.214,0,0,1,6.714,8.893V6.679a4.429,4.429,0,1,1,8.857,0ZM11.143,3.726A2.952,2.952,0,0,1,14.1,6.679V8.893H8.19V6.679a2.952,2.952,0,0,1,2.952-2.952Zm4.429,6.643H6.714a.738.738,0,0,0-.738.738v4.429a.738.738,0,0,0,.738.738h8.857a.738.738,0,0,0,.738-.738V11.107A.738.738,0,0,0,15.571,10.369Z"
                                />
                              </g>
                            </svg>
                          </span>
                            }
                          })
                          }
                        </Link>
                      </li>
                      <li className={selectedTab === "About" ? "selected" : ""}>
                        <Link to="#" onClick={(e) => onTabsChange(e, "About")}>
                          About
                        </Link>
                      </li>
                      <li className={selectedTab === "Stats" ? "selected" : ""}>
                        <Link to="#" onClick={(e) =>{
                         !!getAuthToken() ? isPublicView ?
                              isRestrict?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((item, idx)=>{
                                if(item?.publicprofilefieldid == 27 && item.active) return onTabsChange(e, "Stats")
                              })
                              :
                              onTabsChange(e, "Stats")
                             :
                             onTabsChange(e, "Stats")
                        }}>
                          Stats
                          {isPublicView &&
                          isRestrict?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((item, idx)=>{
                            if(item?.publicprofilefieldid == 27 && !item.active) return <span className="icon" style={{marginLeft:"10px"}}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.286"
                                height="15.5"
                                viewBox="0 0 13.286 15.5"
                            >
                              <defs>
                                <style></style>
                              </defs>
                              <g transform="translate(-4.5 -2.25)">
                                <path
                                    fill={'#c0321f'}
                                    className="a"
                                    d="M15.571,8.893a2.214,2.214,0,0,1,2.214,2.214v4.429a2.214,2.214,0,0,1-2.214,2.214H6.714A2.214,2.214,0,0,1,4.5,15.536V11.107A2.214,2.214,0,0,1,6.714,8.893V6.679a4.429,4.429,0,1,1,8.857,0ZM11.143,3.726A2.952,2.952,0,0,1,14.1,6.679V8.893H8.19V6.679a2.952,2.952,0,0,1,2.952-2.952Zm4.429,6.643H6.714a.738.738,0,0,0-.738.738v4.429a.738.738,0,0,0,.738.738h8.857a.738.738,0,0,0,.738-.738V11.107A.738.738,0,0,0,15.571,10.369Z"
                                />
                              </g>
                            </svg>
                          </span>
                          })
                          }
                        </Link>
                      </li>
                      <li
                        className={`${
                          selectedTab === "Reports" ? "selected" : ""
                        } 
                        `}
                      >
                        <Link
                          to="#"
                          onClick={(e) =>{onTabsChange(e, "Reports")}}
                        >
                          Reports
                          {/* {isPublicView &&
                          <span className="icon" style={{marginLeft:"10px"}}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.286"
                                height="15.5"
                                viewBox="0 0 13.286 15.5"
                            >
                              <defs>
                                <style></style>
                              </defs>
                              <g transform="translate(-4.5 -2.25)">
                                <path
                                    fill={'#c0321f'}
                                    className="a"
                                    d="M15.571,8.893a2.214,2.214,0,0,1,2.214,2.214v4.429a2.214,2.214,0,0,1-2.214,2.214H6.714A2.214,2.214,0,0,1,4.5,15.536V11.107A2.214,2.214,0,0,1,6.714,8.893V6.679a4.429,4.429,0,1,1,8.857,0ZM11.143,3.726A2.952,2.952,0,0,1,14.1,6.679V8.893H8.19V6.679a2.952,2.952,0,0,1,2.952-2.952Zm4.429,6.643H6.714a.738.738,0,0,0-.738.738v4.429a.738.738,0,0,0,.738.738h8.857a.738.738,0,0,0,.738-.738V11.107A.738.738,0,0,0,15.571,10.369Z"
                                />
                              </g>
                            </svg>
                          </span>
                              } */}
                        </Link>
                      </li>
                    </ul>
                  </nav>
                  <div className="flexBox">
                    <div className="menuBtnBox">
                      <button className="tabMenuBtn" onClick={()=>handleOpenMenu()}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="12"
                          viewBox="0 0 16 12"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <g transform="translate(-284.664 -20.498)">
                            <rect
                              className="a"
                              width="16"
                              height="2"
                              rx="1"
                              transform="translate(284.664 20.498)"
                            />
                            <rect
                              className="a"
                              width="16"
                              height="2"
                              rx="1"
                              transform="translate(284.664 25.498)"
                            />
                            <rect
                              className="a"
                              width="16"
                              height="2"
                              rx="1"
                              transform="translate(284.664 30.498)"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div>
                      <p>{selectedTab}</p>
                    </div>
                  </div>
                </div>
                <div className="tabBox profileTab-show-mobile">
                  <nav>
                    <ul>
                    <OwlCarousel {...optionCarousel}
                                 startPosition={tabSel - 1}
                    >
                      <li
                        className={selectedTab === "Timeline" ? "selected" : ""}
                      >
                        <Link
                          to="#"
                          onClick={(e) => onTabsChange(e, "Timeline", 1)}
                        >
                          Timeline
                        </Link>
                      </li>
                      <li
                        className={
                          selectedTab === "Videos & Photos" ? "selected" : ""
                        }
                      >
                        <Link
                          to="#"
                          onClick={(e) => {
                          //  !!getAuthToken() ?  isPublicView ?
                          //       isRestrict?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((item, idx)=>{
                          //         if(item?.publicprofilefieldid == 26 && item?.active){
                          //          return  onTabsChange(e, "Videos & Photos")
                          //         }
                          //       })
                          //   :
                          //      onTabsChange(e, "Videos & Photos")
                          //    :
                               onTabsChange(e, "Videos & Photos", 2)
                          }
                          }
                        >
                          Videos & Photos
                          {isPublicView &&
                          !isRestrict?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((item, idx)=>{
                            if(item?.publicprofilefieldid == 26 && item?.active){
                              return  <span className="icon" style={{marginLeft:"10px"}}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.286"
                                height="15.5"
                                viewBox="0 0 13.286 15.5"
                            >
                              <defs>
                                <style></style>
                              </defs>
                              <g transform="translate(-4.5 -2.25)">
                                <path
                                    fill={'#c0321f'}
                                    className="a"
                                    d="M15.571,8.893a2.214,2.214,0,0,1,2.214,2.214v4.429a2.214,2.214,0,0,1-2.214,2.214H6.714A2.214,2.214,0,0,1,4.5,15.536V11.107A2.214,2.214,0,0,1,6.714,8.893V6.679a4.429,4.429,0,1,1,8.857,0ZM11.143,3.726A2.952,2.952,0,0,1,14.1,6.679V8.893H8.19V6.679a2.952,2.952,0,0,1,2.952-2.952Zm4.429,6.643H6.714a.738.738,0,0,0-.738.738v4.429a.738.738,0,0,0,.738.738h8.857a.738.738,0,0,0,.738-.738V11.107A.738.738,0,0,0,15.571,10.369Z"
                                />
                              </g>
                            </svg>
                          </span>
                            }
                          })
                          }
                        </Link>
                      </li>
                      <li className={selectedTab === "About" ? "selected" : ""}>
                        <Link to="#" onClick={(e) => onTabsChange(e, "About", 3)}>
                          About
                        </Link>
                      </li>
                      <li className={selectedTab === "Stats" ? "selected" : ""}>
                        <Link to="#" onClick={(e) =>{
                         !!getAuthToken() ? isPublicView ?
                              isRestrict?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((item, idx)=>{
                                if(item?.publicprofilefieldid == 27 && item.active) return onTabsChange(e, "Stats")
                              })
                              :
                              onTabsChange(e, "Stats", 4)
                             :
                             onTabsChange(e, "Stats", 4)
                        }}>
                          Stats
                          {isPublicView &&
                          isRestrict?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((item, idx)=>{
                            if(item?.publicprofilefieldid == 27 && !item.active) return <span className="icon" style={{marginLeft:"10px"}}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.286"
                                height="15.5"
                                viewBox="0 0 13.286 15.5"
                            >
                              <defs>
                                <style></style>
                              </defs>
                              <g transform="translate(-4.5 -2.25)">
                                <path
                                    fill={'#c0321f'}
                                    className="a"
                                    d="M15.571,8.893a2.214,2.214,0,0,1,2.214,2.214v4.429a2.214,2.214,0,0,1-2.214,2.214H6.714A2.214,2.214,0,0,1,4.5,15.536V11.107A2.214,2.214,0,0,1,6.714,8.893V6.679a4.429,4.429,0,1,1,8.857,0ZM11.143,3.726A2.952,2.952,0,0,1,14.1,6.679V8.893H8.19V6.679a2.952,2.952,0,0,1,2.952-2.952Zm4.429,6.643H6.714a.738.738,0,0,0-.738.738v4.429a.738.738,0,0,0,.738.738h8.857a.738.738,0,0,0,.738-.738V11.107A.738.738,0,0,0,15.571,10.369Z"
                                />
                              </g>
                            </svg>
                          </span>
                          })
                          }
                        </Link>
                      </li>
                      <li
                        className={`${
                          selectedTab === "Reports" ? "selected" : ""
                        } 
                        `}
                      >
                        <Link
                          to="#"
                          onClick={(e) =>{onTabsChange(e, "Reports", 5)}}
                        >
                          Reports
                          {/* {isPublicView &&
                          <span className="icon" style={{marginLeft:"10px"}}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.286"
                                height="15.5"
                                viewBox="0 0 13.286 15.5"
                            >
                              <defs>
                                <style></style>
                              </defs>
                              <g transform="translate(-4.5 -2.25)">
                                <path
                                    fill={'#c0321f'}
                                    className="a"
                                    d="M15.571,8.893a2.214,2.214,0,0,1,2.214,2.214v4.429a2.214,2.214,0,0,1-2.214,2.214H6.714A2.214,2.214,0,0,1,4.5,15.536V11.107A2.214,2.214,0,0,1,6.714,8.893V6.679a4.429,4.429,0,1,1,8.857,0ZM11.143,3.726A2.952,2.952,0,0,1,14.1,6.679V8.893H8.19V6.679a2.952,2.952,0,0,1,2.952-2.952Zm4.429,6.643H6.714a.738.738,0,0,0-.738.738v4.429a.738.738,0,0,0,.738.738h8.857a.738.738,0,0,0,.738-.738V11.107A.738.738,0,0,0,15.571,10.369Z"
                                />
                              </g>
                            </svg>
                          </span>
                              } */}
                        </Link>
                      </li>
                      </OwlCarousel>
                    </ul>
                  </nav>
                </div>
                <div>
                {reportData}
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>
  );
}
