import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  FOLLOWER_ATHLETE_LOCATION: "FOLLOWER_ATHLETE_LOCATION",
  FOLLOWER_ATHLETE_LOCATION_SUCCESS: "FOLLOWER_ATHLETE_LOCATION_SUCCESS",
  FOLLOWER_ATHLETE_LOCATION_FAILED: "FOLLOWER_ATHLETE_LOCATION_FAILED"
};

// ============================================ Actions ========================================================

export  const followerAthleteAddressStart = data => ({
  type: actionType.FOLLOWER_ATHLETE_LOCATION,
  payload: data
})

export const followerAthleteAddressSuccess = data => ({
  type: actionType.FOLLOWER_ATHLETE_LOCATION_SUCCESS,
  payload: data
});

export const followerAthleteAddressFailed = data => ({
  type: actionType.FOLLOWER_ATHLETE_LOCATION_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function follwerAthleteAddressReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.FOLLOWER_ATHLETE_LOCATION:
      return {
        ...state,
        loading: true
      };

    case actionType.FOLLOWER_ATHLETE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.FOLLOWER_ATHLETE_LOCATION_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

function* followerAthleteLocationSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(followerAthleteAddressSuccess({data: response.data.data.allAthletes.Athletes}));
    } else {
      messagePopup("", "Athlete Api Failed", "error");
      yield put(followerAthleteAddressFailed("Athlete Api Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Athlete Api Failed", "error");
    yield put(followerAthleteAddressFailed("Athlete Api Failed, Error occured"));
  }
}

export function* watchFollowerAthleteLocationSaga() {
  yield takeEvery(actionType.FOLLOWER_ATHLETE_LOCATION, followerAthleteLocationSaga);
}
