import React, {useEffect, useState} from "react";
import FeaturedVideo from "../Webinars/FeaturedVideos";
import SlideTab from "../HowToVideo/SlideTab";
import Stats from "../InnerList/Stats";
import ResourcesContainer from "../ResourcesContainer";
import DetailsBox from "../Utils/DetailsBox";
import PlayButton from "../Utils/PlayButton";
import SearchBox from "../Utils/SearchBox";
import VideoPopup from "../Utils/VideoPopup";
import { ScrollTo } from "react-scroll-to/dist";
import MostPopulars from "./MostPopulars";
import OwlCarousel from "react-owl-carousel";
import image from "../../assets/img/video-bg-img.png";
import moment from "moment";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getHowToVideoListQuery, getResourceHeaderQuery, saveViewVideo} from "../HowToVideo/HowtoVideoQuery";
import swal from "sweetalert";
import {saveBasicUserProfileUpdateQuery} from "../../../editUserProfile/BasicQuery";
import {getUserInfo} from "../../../../../../../services/authService";
import {imageDownloadUrl} from "../../../../../../../config";
import coacheImg1 from "../../../editUserProfile/assets/img/coache-img1.png";
import GoTopButton from "../../../editUserProfile/GoTopButton";
import {updateLS} from "../Utils/updateLS";
import {vidURL} from "../../../../../../common/thumbnail";
import {getUTCTimeDateDash} from '../../../../../../../utils/dateTimeFormatter'
const options = {
  margin: 10,
  responsiveClass: true,
  nav: true,
  dots: false,
  responsive: {
    0: {
      items: 1,
      center: true,
    },
    415: {
      items: 1,
      center: true,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
};

const Webinar = () => {
  const [showVideoPopup, setShowVideoPopup] = useState(false),
        [isVideo, setIsVideo] = useState(false),
        [isSearch, setIsSearch] = useState(true),
        [searchValue, setSearchValue] = useState(null),
        [tabSel, setTabSel] = useState(1),
        [offset, setOffset] = useState(3),
        [perPage, setPerPage] = useState(3),
        [vidData, setVidData] = useState([]),
        [isHide, setIsHide] = useState(true),
        userLS = JSON.parse(localStorage.getItem('user')),
        webinar = useSelector(state => state),
        dispatch = useDispatch()

  useEffect(() => {
    // dispatch({type:"GET_RESOURCE_HEADER_START", payload:{
    //     query: getResourceHeaderQuery,
    //     variables:{
    //       where:{
    //         resourcemainheaderid: 3
    //       }
    //     }
    //   }})
    // if(userLS.iswebvideoshow == 0) setShowVideoPopup(true)
  }, []);

  useEffect(()=>{
    // dispatch({type:"GET_HOW_TO_VIDEO_START", payload:{
    //     query: getHowToVideoListQuery,
    //     variables: {
    //       where: {
    //         isuploadedbyadmin: 1,
    //         resourceheaderid: webinar?.athlete?.dashboard?.resources?.resourcesHeader?.data?.Resourceheaders[0].id,
    //       },
    //       first:offset, last:perPage
    //     }
    //   }})
    // setTabSel(webinar?.athlete?.dashboard?.resources?.resourcesHeader?.data?.Resourceheaders[0].id)
    window.scroll(0,0)
  },[webinar?.athlete?.dashboard?.resources?.resourcesHeader?.data?.Resourceheaders])

  useEffect(()=>{
    // dispatch({type:"GET_HOW_TO_VIDEO_START", payload:{
    //     query: getHowToVideoListQuery,
    //     variables: {
    //       where: {
    //         isuploadedbyadmin: 1,
    //         resourceheaderid: webinar?.athlete?.dashboard?.resources?.resourcesHeader?.data?.Resourceheaders[0].id,
    //       },
    //       first:offset, last:perPage
    //     }
    //   }})
  },[tabSel])

  const handleClose = (e) => {
    e.preventDefault();
    dispatch({type:"SAVE_ATHLETE_VIEW_VIDEO_START",payload:{
        query:saveBasicUserProfileUpdateQuery,
        variables: {
          obj: {
            id: getUserInfo() ? getUserInfo().id : 0,
            iswebvideoshow: 1
          }}
      }})
    updateLS('iswebvideoshow', 1)
    setShowVideoPopup(false);
  };

  const handleOpen = () => {
    setShowVideoPopup(true);
  };

  const tabApiCall = id =>{
    dispatch({type:"GET_HOW_TO_VIDEO_START", payload:{
        query: getHowToVideoListQuery,
        variables: {
          where: {
            isuploadedbyadmin: 1,
            resourceheaderid: +id,
          },
          first: +3,
          last: + 3
        }
      }})
    setTimeout(()=>{
      dispatch({type:"GET_HOW_TO_VIDEO_START", payload:{
          query: getHowToVideoListQuery,
          variables: {
            where: {
              isuploadedbyadmin: 1,
              resourceheaderid: +id,
            },
            first: +3,
            last: + 3
          }
        }})
    },300)
    setTabSel(+id)
    setPerPage(3)
    setOffset(3)
  }
  const handleChange = (e) =>{
    let value = !!e?.target?.value ? e?.target?.value : ""  || ""
    if(value.length > 3){
      dispatch({type:"GET_HOW_TO_VIDEO_START",
        payload:{ query :getHowToVideoListQuery,
          variables:{ where:{
              isuploadedbyadmin: 1,
              resourceheaderid: tabSel,
              and: [
                {
                  or: [
                    { originalname: { like: `%${value}%` } },
                    { videodescription: { like: `%${value}%` }},
                  ]
                }
              ]
            }}
        }})
      setIsSearch(false)
    }else{
      dispatch({type:"GET_HOW_TO_VIDEO_START",
        payload:{query :getHowToVideoListQuery,
          variables: {
            where: {
              isuploadedbyadmin: 1,
              resourceheaderid: tabSel,
            }
          },
          first: offset,
          last: perPage
        }})
      setIsSearch(true)
    }
    setSearchValue(value)
  }
  const loadMore = () =>{
    dispatch({type:"GET_HOW_TO_VIDEO_START", payload:{
        query: getHowToVideoListQuery,
        variables: {
          where: {
            isuploadedbyadmin: 1,
            resourceheaderid: +tabSel,
          },
          first:offset +3,
          last:perPage + 3
        }
      }})
    setPerPage(perPage + 3)
    setOffset(offset + 3)
    setTimeout(()=>{
      window.scrollTo(0,0)
    },1000)
  },
      isVideoPop = (vid) => {
        if (!!vid?.videolink){
          vid['videolink'] = vidURL(vid?.videolink)
          setVidData(vid)
        }else{
          setVidData(vid)
        }
        setIsVideo(true)
        // setVidData(vid)
        // setIsVideo(true)
      }
  return (
      <>
              <ResourcesContainer>
                <VideoPopup
                    show={showVideoPopup}
                    close={handleClose}
                    videoId={"1mnYUP8IWz8"}
                />
                <VideoPopup
                    isResource={true}
                    vidData={vidData}
                    show={isVideo}
                    close={() => setIsVideo(false)}
                />
                {isHide ? <><h1 className={'tab-content resources-box-wrap'} style={{textAlign:"center"}}>Coming Soon</h1></>
                    :
                    <div className="tab-content resources-box-wrap">
                      <div className="tab-pane active" id="Webinars">
                        <DetailsBox open={handleOpen}/>
                        <div className="search-box-wrap">
                          <div className="item">
                            <form className="search-box">
                              <input
                                  type="text"
                                  style={{color: "black"}}
                                  value={searchValue}
                                  placeholder="Search By Title & Description"
                                  onChange={(e) => handleChange(e)}
                              />
                              {isSearch &&
                              <>
                                {/*<button type="button">*/}
                                {/*  <svg*/}
                                {/*      xmlns="http://www.w3.org/2000/svg"*/}
                                {/*      width="13.811"*/}
                                {/*      height="13.811"*/}
                                {/*      viewBox="0 0 13.811 13.811"*/}
                                {/*  >*/}
                                {/*    <g className="a" transform="translate(0.75 0.75)">*/}
                                {/*      <path*/}
                                {/*          className="b"*/}
                                {/*          d="M15.167,9.833A5.333,5.333,0,1,1,9.833,4.5,5.333,5.333,0,0,1,15.167,9.833Z"*/}
                                {/*          transform="translate(-4.5 -4.5)"*/}
                                {/*      />*/}
                                {/*      <path*/}
                                {/*          className="b"*/}
                                {/*          d="M27.875,27.875l-2.9-2.9"*/}
                                {/*          transform="translate(-15.875 -15.875)"*/}
                                {/*      />*/}
                                {/*    </g>*/}
                                {/*  </svg>*/}
                                {/*</button>*/}
                              </>
                              }
                              {!isSearch &&
                              <>
                                {/*<button type={'button'} onClick={()=>handleChange("")}>*/}
                                {/*  <svg xmlns="http://www.w3.org/2000/svg" width="11.271" height="11.253" viewBox="0 0 11.271 11.253">*/}
                                {/*    <path id="Mask"*/}
                                {/*          d="M6.767,5.63l4.26,4.26a.8.8,0,0,1-.292,1.319.689.689,0,0,1-.547,0,.778.778,0,0,1-.292-.189l-4.26-4.26-4.26,4.26a.913.913,0,0,1-.254.189.744.744,0,0,1-.311.056A.744.744,0,0,1,.5,11.209a.914.914,0,0,1-.254-.189.775.775,0,0,1,0-1.131L4.5,5.63.245,1.37A.775.775,0,0,1,.245.24.809.809,0,0,1,.81.013.809.809,0,0,1,1.376.24l4.26,4.279L9.9.24a.82.82,0,0,1,1.131,0,.775.775,0,0,1,0,1.131Z"*/}
                                {/*          transform="translate(0 -0.013)" fill="#a8b7c7"></path>*/}
                                {/*  </svg>*/}
                                {/*</button>*/}
                              </>
                              }
                            </form>
                          </div>
                          <div className="item">
                            <PlayButton open={handleOpen}/>
                          </div>
                        </div>
                        <div className="tabBox slidetab">
                          <ul className="nav nav-tabs tabs-slide owl-carousel">
                            {webinar && webinar?.athlete?.dashboard?.resources?.resourcesHeader?.data?.Resourceheaders?.length ?
                                <SlideTab
                                    resourcesHeaderData={webinar?.athlete?.dashboard?.resources?.resourcesHeader?.data?.Resourceheaders}
                                    tabApiCall={tabApiCall}
                                /> : ""}
                          </ul>
                        </div>
                        <div className="resources-inner-list">
                          <div className="tab-content current" id="Stats">
                            <div className="video-box-row">
                              {webinar?.athlete?.dashboard?.resources.howToVideoList?.data?.dataSection.length > 0 ?
                                  webinar?.athlete?.dashboard?.resources.howToVideoList?.data?.dataSection.map((item, index) => {

                                    return (
                                        <div className="video-box-item" style={{marginBottom: "20px"}}>
                                          <h3 className="sec-title">{item.title || "N/A"}</h3>
                                          <ul className="video-grid-list resources-video-slide owl-carousel owl-loaded">
                                            <OwlCarousel className="owl-theme" {...options}>
                                              {item?.data.length > 0 ?
                                                  item.data.map((vid, idx) => {
                                                    return (
                                                        <li className="video-box">
                                                          <div
                                                              className="img-box"
                                                          >
                                                            <video poster={!!vid?.thumbnail ? vid?.thumbnail : image}>
                                                              <source
                                                                  src={`https://www.youtube.com/embed/`}
                                                                  type="video/mp4"
                                                              />
                                                              <source
                                                                  src={`https://www.youtube.com/embed/`}
                                                                  type="video/ogg"
                                                              />
                                                            </video>
                                                            <div
                                                                className="video-play-button"
                                                                data-toggle="modal"
                                                                data-target="#ResourceVideoModal"
                                                            >
                                                              <div className="video-play-inner">
                                                                <button type={'button'} onClick={() => {
                                                                  vid.ispremium ?
                                                                      swal("", "Denied Video Is Premium", "")
                                                                      :
                                                                      (isVideoPop(vid) ||
                                                                          dispatch({
                                                                            type: "SAVE_VIEW_VIDEO_START", payload: {
                                                                              query: saveViewVideo,
                                                                              variables: {
                                                                                obj: {
                                                                                  // id:1,
                                                                                  userid: userLS.id || 0,
                                                                                  viewstatus: 1,
                                                                                  videoid: vid?.id
                                                                                }
                                                                              }
                                                                            }
                                                                          })
                                                                      )
                                                                }}>
                                                                  <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      width="10.802"
                                                                      height="16.203"
                                                                      viewBox="0 0 10.802 16.203"
                                                                  >
                                                                    <path
                                                                        className="a"
                                                                        d="M8.1,0l8.1,10.8H0Z"
                                                                        transform="translate(10.802) rotate(90)"
                                                                    />
                                                                  </svg>
                                                                </button>
                                                              </div>
                                                            </div>
                                                            <div
                                                                className={`video-tagmark ${vid?.ispremium && 'premium'}`}
                                                            >
                                                          <span
                                                              className="premium">{vid?.ispremium ? "Premium" : "Free"}</span>
                                                            </div>
                                                          </div>
                                                          <div className="video-detail-box">
                                                            <h3 className="title">{vid?.originalname || "N/A"}</h3>
                                                            <p>{vid?.videodescription || ""}</p>
                                                            <ul className="video-info">
                                                              <li>{vid?.viewvideoVideoidMap?.totalCount || 0} views</li>
                                                              <li>{getUTCTimeDateDash(item.data[0].createdon)}</li>
                                                            </ul>
                                                            <div className="video-text">
                                                              {vid?.viewvideoVideoidMap?.Viewvideos?.length > 0 ?
                                                                  <>
                                                                    {vid?.viewvideoVideoidMap?.Viewvideos?.slice(0, 2)?.map((ath, idx) => {
                                                                      return (
                                                                          <div className="video-info-img"
                                                                               style={idx == 0 ? {
                                                                                 width: "auto",
                                                                                 marginRight: 0
                                                                               } : {width: "auto"}}>
                                                                            <img
                                                                                src={!!ath?.viewvideouseridmaprel?.Athletes?.[0]?.onBoardingUserIdMap?.Onboardings[0]?.profileimages ? `${imageDownloadUrl}/${ath?.viewvideouseridmaprel?.Athletes?.[0]?.onBoardingUserIdMap?.Onboardings[0]?.profileimages}` : coacheImg1}
                                                                                alt=""/>
                                                                          </div>
                                                                      )
                                                                    })}
                                                                    {vid?.viewvideoVideoidMap?.Viewvideos?.slice(0, 2)?.map((ath, idx) => {
                                                                      return (
                                                                          <span>{ath?.viewvideouseridmaprel?.Athletes?.[0]?.athletefirstname || ""} {`${idx == 0 ? `,` : ""}`}
                                                                            {(idx == 1 && vid?.viewvideoVideoidMap?.Viewvideos?.length > 2) ? `and ${(parseInt(vid?.viewvideoVideoidMap?.totalCount) - 2)} more` : ""}
                                                          </span>
                                                                      )
                                                                    })
                                                                    }
                                                                  </>
                                                                  : ""
                                                                  ||
                                                                  <>
                                                                    <div className="video-info-img">
                                                                      {/*  <img src={coacheImg1} alt="" />*/}
                                                                      <span className="video-img">
                                                      {/*     <img src={coacheImg1} alt="" />*/}
                                                        </span>
                                                                    </div>
                                                                    {/*<p>salan</p>*/}
                                                                  </>
                                                              }
                                                              {/*<div className="video-info-img">*/}
                                                              {/*<img src="img/video-bg-img.png" alt="" />*/}
                                                              {/*<span className="video-img">*/}
                                                              {/*<img src="img/video-bg-img.png" alt="" />*/}
                                                              {/*               </span>*/}
                                                              {/*</div>*/}
                                                              {/*             <p>viewedBy</p>*/}
                                                            </div>

                                                            <div className="video-button-box text-center"
                                                                 style={vid?.viewvideoVideoidMap?.Viewvideos[0]?.viewvideouseridmaprel?.Athletes?.length > 0 ?
                                                                     {marginTop: 0} : {}}>
                                                              <a className={vid?.ispremium ? "btn blue-light" : "btn green"}
                                                                 onClick={() => {
                                                                   vid?.ispremium ?
                                                                       window.open('https://zoom.us/signin') :
                                                                       (isVideoPop(vid) ||
                                                                           dispatch({
                                                                             type: "SAVE_VIEW_VIDEO_START", payload: {
                                                                               query: saveViewVideo,
                                                                               variables: {
                                                                                 obj: {
                                                                                   // id:1,
                                                                                   userid: vid?.userid || 0,
                                                                                   viewstatus: 1,
                                                                                   videoid: vid?.id
                                                                                 }
                                                                               }
                                                                             }
                                                                           })
                                                                       )
                                                                 }}
                                                              >
                                                                {vid?.ispremium ? 'Sign Up' : 'Watch Now'}
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </li>
                                                    )
                                                  })
                                                  :
                                                  <h3>No record found</h3>}
                                            </OwlCarousel>
                                          </ul>
                                        </div>

                                        // <HowToVideoList
                                        //     Data={item || []}
                                        // />
                                    )
                                  })
                                  : <h3>No Record found</h3>
                              }
                            </div>
                            <div className="resources-loadmore-box">
                              {webinar?.athlete?.dashboard?.resources.howToVideoList?.data?.howToVid?.totalCount > 3 &&
                              perPage < webinar?.athlete?.dashboard?.resources.howToVideoList?.data?.howToVid?.totalCount &&
                              <button onClick={() => loadMore()} className="load-more-button">Load more</button>}
                            </div>
                          </div>
                          {/*<div className="tab-content" id="VideoExamples">*/}
                          {/*  <div className="video-box-row">*/}
                          {/*    <div className="video-box-item">*/}
                          {/*      <h3>Videos Examples</h3>*/}
                          {/*    </div>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                          {/*<div className="tab-content" id="EditProfile">*/}
                          {/*  <div className="video-box-row">*/}
                          {/*    <div className="video-box-item">*/}
                          {/*      <h3>Edit Profile</h3>*/}
                          {/*    </div>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                          {/*<div className="tab-content" id="MyColleges">*/}
                          {/*  <div className="video-box-row">*/}
                          {/*    <div className="video-box-item">*/}
                          {/*      <h3>My Colleges</h3>*/}
                          {/*    </div>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                          {/*<div className="tab-content" id="VideosandPhotos">*/}
                          {/*  <div className="video-box-row">*/}
                          {/*    <div className="video-box-item">*/}
                          {/*      <h3>Videos & Photos</h3>*/}
                          {/*    </div>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                }
              </ResourcesContainer>
              <GoTopButton goTop={() => {
                window.scrollTo(0, 0)
              }}/>
      </>
  )
};

export default Webinar;
