export const getAthleteAddressQuery = `
query allUserdata($obj: JSON){
        allUserdata(where: $obj){
          Userdata{
            address
            addresscountinue
            homecountryid
            city
            homezipcode
            stateid
          }
        }
}`;

export const saveCollegeInsightUpdateQuery = `
mutation saveCollegeinsight($obj:CollegeinsightInput!){
    saveCollegeinsight(obj:$obj){
    id
    }    
}
`;

export const getAllCollegeInsightsQuery = `
query allCollegeinsights($obj: JSON){
  allCollegeinsights(where: $obj){
    Collegeinsights{
      id
      userid
      annualhouseholdincomeid
      bestracialorethnicheritage
      haveanysiblingswhogonetocollege
      isfamilyaddandathleteaddsame
      influentialdecision
      isparentdivorced
      wholiveswithyou
      isyourfamilyqualifyanynationalprogram
      livesoutsidecanadaorus
      outsidecountryid
      homeaddress
      address
      efc
      addresscountinue
      city
      zipcode
      state
      countyid
      isviewable   
    }
 }
}`;
