import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/claimYourProfile.scss";
import "../../../../../assets/css/stylesheet.css";
import FollowDarkProfile from "../../../athlet/dashboard/profile/followprofile/FollowDarkProfile";
import AreYouSure from './AreYouSure'
import { matchotpStart } from "../../../../../redux/auth/signup/matchOtp"
import { connect } from "react-redux";

const ClaimYourProfile = (props) => {
  const [confirmModal, setConfirmModal] = useState(false);

 const handelconfirmModal = () =>{
   setConfirmModal(true)
  }
  const handelClaimProfile = () =>{
    props.matchotpStart({
      data: {
        otp: localStorage.getItem('validUserId').toString(),
        useFor: "login",
        otpOrId:"id"
      },
      nextScreen: '/login'
    })

  }
  return (
    <div>
      <div className="popUpClaimProfileSection popUpClaimWrap">
        <div className="popUpClaimBlock">
          <div className="closeBtn">
            <div>
              <h1 className="heading">Claim Your profile</h1>
            </div>
            <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.804"
                height="15.803"
                viewBox="0 0 15.804 15.803"
              >
                <defs>
                  <style>{`.a{opacity:0.7;}`}</style>
                </defs>
                <g className="a" transform="translate(-6.776 -6.776)">
                  <path
                    d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                    transform="translate(0 0)"
                  />
                </g>
              </svg>
            </button>
          </div>

          <FollowDarkProfile />

          <div className="actionBox">
            <button className="yesClaimProfileBtn" onClick={()=>{handelClaimProfile()}}>
              <span>Yes, Claim Profile</span>
            </button>
            <button className="noBtn" onClick={()=>handelconfirmModal()}>
              <span>No</span>
            </button>
          </div>
          {
            confirmModal == true?<AreYouSure/>:""}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    matchotpStart: (data) => dispatch(matchotpStart(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimYourProfile);



