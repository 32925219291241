import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ATHLETE_HEIGHT_WEIGHT_START: "ATHLETE_HEIGHT_WEIGHT_START",
  ATHLETE_HEIGHT_WEIGHT_SAVE_START: "ATHLETE_HEIGHT_WEIGHT_SAVE_START",
  ATHLETE_HEIGHT_WEIGHT_SUCCESS: "ATHLETE_HEIGHT_WEIGHT_SUCCESS",
  ATHLETE_HEIGHT_WEIGHT_FAILED: "ATHLETE_HEIGHT_WEIGHT_FAILED",
};

// ============================================ Actions ========================================================
export const athleteHeightWeightStart = (data) => ({
  type: actionType.ATHLETE_HEIGHT_WEIGHT_START,
  payload: data,
});
export const athleteHeightWeightSaveStart = (data) => ({
  type: actionType.ATHLETE_HEIGHT_WEIGHT_SAVE_START,
  payload: data,
});

export const athleteHeightWeightSuccess = (data) => ({
  type: actionType.ATHLETE_HEIGHT_WEIGHT_SUCCESS,
  payload: data,
});

export const athleteHeightWeightFailed = (data) => ({
  type: actionType.ATHLETE_HEIGHT_WEIGHT_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function athleteHeightWeightReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ATHLETE_HEIGHT_WEIGHT_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ATHLETE_HEIGHT_WEIGHT_SAVE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ATHLETE_HEIGHT_WEIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ATHLETE_HEIGHT_WEIGHT_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* athleteHeightWeightListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const athleteHeightAndWeight = response.data.data;
      yield put(
        athleteHeightWeightSuccess({
          athleteHeightAndWeight: athleteHeightAndWeight,
        })
      );
      if (action.payload.successMsg){
        messagePopup("", action.payload.successMsg, "success")
      }
    } else {
      messagePopup("", "athlete Height and Weight failed", "error");
      yield put(athleteHeightWeightFailed("athlete Height and Weight failed"));
    }
  } catch (error) {
    messagePopup("", "athlete Height and Weight failed", "error");
    yield put(athleteHeightWeightFailed("athlete Height and Weight failed"));
  }
}
export function* athleteHeightWeightSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const athleteHeightAndWeight = response.data.data;
      yield put(
        athleteHeightWeightSuccess({
          saveAthleteHeightAndWeight: athleteHeightAndWeight,
        })
      );
    } else {
      messagePopup("", "athlete Height and Weight Save failed", "error");
      yield put(
        athleteHeightWeightFailed("athlete Height and Weight Save failed")
      );
    }
  } catch (error) {
    messagePopup("", "athlete Height and Weight Save failed", "error");
    yield put(
      athleteHeightWeightFailed("athlete Height and Weight Save failed")
    );
  }
}

export function* watchAthleteHeightWeightSaga() {
  yield takeEvery(
    actionType.ATHLETE_HEIGHT_WEIGHT_START,
    athleteHeightWeightListSaga
  );
  yield takeEvery(
    actionType.ATHLETE_HEIGHT_WEIGHT_SAVE_START,
    athleteHeightWeightSaveSaga
  );
}
