import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SAVE_SEARCH_FILTER_START: "SAVE_SEARCH_FILTER_START",
  DELETE_SEARCH_FILTER_START: "DELETE_SEARCH_FILTER_START",
  SEARCH_FILTER_SUCCESS: "SEARCH_FILTER_SUCCESS",
  SEARCH_FILTER_FAILED: "SEARCH_FILTER_FAILED",
};

// ============================================ Actions ========================================================

export const saveSearchFilterStart = (data) => ({
  type: actionType.SAVE_SEARCH_FILTER_START,
  payload: data,
});

export const deleteSearchFilterStart = (data) => ({
  type: actionType.DELETE_SEARCH_FILTER_START,
  payload: data,
});

export const searchFilterSuccess = (data) => ({
  type: actionType.SEARCH_FILTER_SUCCESS,
  payload: data,
});

export const searchFilterFailed = (data) => ({
  type: actionType.SEARCH_FILTER_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function saveSearchFilterReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.DELETE_SEARCH_FILTER_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SAVE_SEARCH_FILTER_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SEARCH_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SEARCH_FILTER_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* saveSearchFilterSaga(action) {
  try {
    const { closeSaveFilter, refreshFilterList, ...restData } = action.payload;
    const response = yield fetchMethod(
      `/api/Searchfilters/replacefilter`,
      restData,
      "post",
      true
    );
    if (response && response.status === 200) {
      if (response.data && response.data.response) {
        if (response.data.response.status === 200) {
          messagePopup("", "Filter saved successfully", "success");
          yield put(searchFilterSuccess("Filter saveed successfully"));
          closeSaveFilter();
          refreshFilterList();
        } else if (response.data.response.status === 201) {
          messagePopup("", "Save filter failed", "error");
          yield put(searchFilterFailed("Save filter failed"));
        }
      } else {
        messagePopup("", "Save filter failed", "error");
        yield put(searchFilterFailed("Save filter failed"));
      }
    } else {
      messagePopup("", "Save filter failed", "error");
      yield put(searchFilterFailed("Save filter failed"));
    }
  } catch (error) {
    messagePopup("", "Save filter failed", "error");
    yield put(searchFilterFailed("Save filter failed"));
  }
}
export function* deleteSearchFilterSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      // messagePopup("", "Filter deleted successfully", "success");
      yield put(searchFilterSuccess("Filter deleted successfully"));
      // action.payload.refreshFilterList();
    } else {
      messagePopup("", "Delete filter failed", "error");
      yield put(searchFilterFailed("Delete filter failed"));
      action.payload.refreshFilterList();
    }
  } catch (error) {
    messagePopup("", "Delete filter failed", "error");
    yield put(searchFilterFailed("Delete filter failed"));
    action.payload.refreshFilterList();
  }
}

export function* watchSaveSearchFilterSaga() {
  yield takeEvery(actionType.SAVE_SEARCH_FILTER_START, saveSearchFilterSaga);
  yield takeEvery(
    actionType.DELETE_SEARCH_FILTER_START,
    deleteSearchFilterSaga
  );
}
