import React from "react";
import image from "../../assets/img/video-call-join-img.png";
import videoBG from "../../assets/img/video-bg-img.png";

const DetailsBox = (props) => {
  return (
    <div className="resources-join-video-call">
      <div className="detial-box">
        <p>
          Join thousands of athletes, parents, and coaches that have watched our Masterclasses.
        </p>
        {/*<div className="video-text">*/}
        {/*  <div className="video-info-img">*/}
        {/*    <img src={videoBG} alt="" />*/}
        {/*    <img src={videoBG} alt="" />*/}
        {/*    <img src={videoBG} alt="" />*/}
        {/*  </div>*/}
        {/*  <p>Sofia Wright, Chase Drowsy and 166 more</p>*/}
        {/*</div>*/}
      </div>
      <div className="join-video-box">
        <a
          onClick={() => {
            props.open();
          }}
        >
          <img src={image} />
          <span className="play-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.802"
              height="16.203"
              viewBox="0 0 10.802 16.203"
            >
              <path
                className="a"
                d="M8.1,0l8.1,10.8H0Z"
                transform="translate(10.802) rotate(90)"
              ></path>
            </svg>
          </span>
        </a>
      </div>
    </div>
  );
};

export default DetailsBox;
