import React, { useEffect, useState} from 'react'
import ClaimProfileHeader from "../auth/claimProfile/claimProfileStep1/ClaimProfileHeader";
import DashboardHeader from "../../layouts/header/DashboardHeader";
import {getAuthToken} from "../../../services/authService";
import NAIAIMG from './assets/img/naia-logo.png'
import NCAAIMG from './assets/img/ncaa-logo.png'
import NJCAAIMG from './assets/img/njcaa-logo.png'
import CCCAA from './assets/img/california-community-college-athletic-association-logo.png'
import './assets/css/lading-page.scss'
import {imageDownloadUrl} from "../../../config";
import {VideoPop} from "./common/videoPopup";
import {lpTypeCall} from "../../common/landingPageApi";
import {useSelector} from "react-redux";
import {fetchMethod} from "../../../services/httpService";

const helpSlide = {
    loop: true,
    nav: true,
    dots: false,
    items: 1,
};

const HighlightLP = (props) =>{
    const [headerType, setHeaderType] = useState(true),
          [isVideoPop, setIsVideoPop] = useState(false),
          [hightlightVideo, setHighlightVideo] = useState([]),
          [vidID, setVidID] = useState(null),
          isToken = props?.match?.params?.isToken == 1 ? true : false,
          store = useSelector(store => store);
    useEffect(()=>{
        getStatus(props)
        //for scrolling the page on top
        setTimeout(()=>{
            window.scrollTo(0,0)
        },1000)
    },[])
    useEffect(async ()=>{
        const type = !!localStorage.getItem("lpType") ? localStorage.getItem("lpType") :
            !!store?.common?.landingPageType?.lpType ? store?.common?.landingPageType?.lpType : null,
            lpRes = await lpTypeCall(type)
        setHighlightVideo(lpRes)
    },[])
    const getStatus = (props) =>{
        if (!!getAuthToken() || isToken){
            setHeaderType(false)
        }
    },
    openModal = async (type, isVidLink) =>{
        if(isVidLink){
            const _res = await fetchMethod("/api/Landingpages/increaseviews",{videoid : type?.id},"POST", false)
            if(_res?.status == 200){
                setVidID(type?.videolink)
            }
        }else{
            const getVid = hightlightVideo?.length > 0 ? hightlightVideo?.filter(el => el?.title == type) : null
            setVidID(getVid?.length > 0 ? getVid?.[0]?.videolink ?? "" : getVid)
        }
        setIsVideoPop(true)
    }
    return(
        <>
            {
                headerType ?
                    <ClaimProfileHeader/>
                    :
                    <DashboardHeader/>
            }
            <section className="banner-lp-sec banner-hightlight"
                     style={
                         {backgroundImage: `url(${imageDownloadUrl}/highlight-reels-banner.png)`,
                             backgroundRepeat:"no-repeat"
                         }}
            >
                <div className="container">
                    <div className="banner-flexbox">
                        <div className="banner-content">
                            <h1 className="title">Professional <span>Highlight Videos</span></h1>
                            <div className="short-text">
                                <p>Our expert video editing team has created thousands of college coach attention
                                    grabbing highlight videos.</p>
                                <p>Submit your video today and maximize your recruiting opportunities!</p>
                            </div>
                            <div className="button-wrap">
                                <a className="btn lightblue" href="#">Get Started</a>
                            </div>
                        </div>
                        <div className="video-box-wrap">
                            <div className="video-img">
                                <img src={`${imageDownloadUrl}/athlete-video-thumbnail-01.png`} alt=""/>
                                <div className="video-play-button" onClick={()=>openModal("professionalhighlightvideo", false)}
                                >
                                    <div className="video-play-inner">
                                        <button className="play-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.191" height="22.92"
                                                 viewBox="0 0 20.191 22.92">
                                                <path id="play"
                                                      d="M9.086,27.982a2.255,2.255,0,0,1-1.1-.291,2.442,2.442,0,0,1-1.228-2.138V7.492A2.442,2.442,0,0,1,7.982,5.353a2.242,2.242,0,0,1,2.257.028l15.641,9.24a2.226,2.226,0,0,1,0,3.8L10.236,27.663a2.262,2.262,0,0,1-1.151.319Z"
                                                      transform="translate(-6.754 -5.063)" fill="#c0321f"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="video-feature-sec">
                <div className="container">
                    <div className="top-block">
                        <h2 className="title">Video Features</h2>
                    </div>
                    <div className="feature-list">
                        <div className="item">
                            <div className="icon-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50.25" height="50.25"
                                     viewBox="0 0 50.25 50.25">
                                    <g id="play-circle-outline" transform="translate(-3.375 -3.375)">
                                        <path id="Path_84041" data-name="Path 84041"
                                              d="M52.5,28.5a24,24,0,1,0-24,24A24.006,24.006,0,0,0,52.5,28.5Z"
                                              transform="translate(0 0)" fill="none" stroke="#c0321f"
                                              strokeWidth="2.25"/>
                                        <path id="Path_84042" data-name="Path 84042"
                                              d="M16.1,32.124l14.267-8.618a1.358,1.358,0,0,0,0-2.319L16.1,12.568a1.344,1.344,0,0,0-2.034,1.161V30.964A1.344,1.344,0,0,0,16.1,32.125Z"
                                              transform="translate(7.463 6.154)" fill="#c0321f"/>
                                    </g>
                                </svg>
                            </div>
                            <h3>Expert Clip Sequencing</h3>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                        </div>
                        <div className="item">
                            <div className="icon-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                    <g id="Group_57622" data-name="Group 57622"
                                       transform="translate(-945.744 -1098.487)">
                                        <path id="Path_84421" data-name="Path 84421"
                                              d="M20.566,0A20.555,20.555,0,0,1,36.177,33.94l3.576,3.576a4.754,4.754,0,0,1,5.4.926L52.612,45.9A4.746,4.746,0,0,1,45.9,52.612l-7.458-7.458a4.754,4.754,0,0,1-.926-5.4L33.94,36.177A20.562,20.562,0,1,1,20.566,0ZM40.68,42.917l7.458,7.458a1.582,1.582,0,0,0,2.237-2.237L42.917,40.68a1.584,1.584,0,0,0-2.234,0l0,0,0,0A1.584,1.584,0,0,0,40.68,42.917ZM20.566,37.969a17.4,17.4,0,1,0-17.4-17.4A17.422,17.422,0,0,0,20.566,37.969Z"
                                              transform="translate(945.744 1098.487)" fill="#c0321f"/>
                                        <g id="Group_57614" data-name="Group 57614"
                                           transform="translate(957.491 1108.451)">
                                            <g id="Group_57612" data-name="Group 57612" transform="translate(3.837)">
                                                <path id="Path_84399" data-name="Path 84399"
                                                      d="M10.094,17.189a5.094,5.094,0,1,1,5.094-5.094A5.1,5.1,0,0,1,10.094,17.189Zm0-8.151a3.057,3.057,0,1,0,3.057,3.057A3.06,3.06,0,0,0,10.094,9.038Z"
                                                      transform="translate(-5 -7)" fill="#c0321f"/>
                                            </g>
                                            <g id="Group_57613" data-name="Group 57613" transform="translate(0 11.95)">
                                                <path id="Path_84400" data-name="Path 84400"
                                                      d="M20.321,21.151A1.019,1.019,0,0,1,19.3,20.132V18.094a3.06,3.06,0,0,0-3.057-3.057H8.094a3.06,3.06,0,0,0-3.057,3.057v2.038a1.019,1.019,0,1,1-2.038,0V18.094A5.1,5.1,0,0,1,8.094,13h8.151a5.1,5.1,0,0,1,5.094,5.094v2.038A1.019,1.019,0,0,1,20.321,21.151Z"
                                                      transform="translate(-3 -13)" fill="#c0321f"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <h3>Isolation</h3>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                        </div>
                        <div className="item">
                            <div className="icon-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="54.5" height="53.921"
                                     viewBox="0 0 54.5 53.921">
                                    <g id="Group_57623" data-name="Group 57623"
                                       transform="translate(-1451.863 -900.336)">
                                        <g id="play-circle-outline" transform="translate(1469.597 908.136)">
                                            <path id="Path_84041" data-name="Path 84041"
                                                  d="M23.99,14.245a9.745,9.745,0,1,0-9.745,9.745A9.748,9.748,0,0,0,23.99,14.245Z"
                                                  transform="translate(-4.5 -4.5)" fill="none" stroke="#c0321f"
                                                  strokeWidth="1.5"/>
                                            <path id="Path_84042" data-name="Path 84042"
                                                  d="M14.889,20.4l5.793-3.5a.551.551,0,0,0,0-.942l-5.793-3.5a.546.546,0,0,0-.826.472v7a.546.546,0,0,0,.826.472Z"
                                                  transform="translate(-7.15 -6.68)" fill="#c0321f"/>
                                        </g>
                                        <path id="Path_84408" data-name="Path 84408"
                                              d="M185.794,409.013a3.413,3.413,0,1,0,3.413,3.413A3.417,3.417,0,0,0,185.794,409.013Zm0,5.276a1.863,1.863,0,1,1,1.863-1.863A1.866,1.866,0,0,1,185.794,414.289Z"
                                              transform="translate(1289.026 534.64)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84409" data-name="Path 84409"
                                              d="M370.332,412.425a3.413,3.413,0,1,0-3.413,3.413A3.417,3.417,0,0,0,370.332,412.425Zm-5.276,0a1.863,1.863,0,1,1,1.863,1.863A1.866,1.866,0,0,1,365.055,412.425Z"
                                              transform="translate(1127.063 534.64)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84410" data-name="Path 84410"
                                              d="M441.285,215.87a.887.887,0,0,0,.886.886h2.95a.887.887,0,0,0,.886-.886v-2.95a.887.887,0,0,0-.886-.886h-2.95a.887.887,0,0,0-.886.886Zm1.549-2.287h1.623v1.623h-1.623Z"
                                              transform="translate(1057.524 710.671)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.2"/>
                                        <path id="Path_84411" data-name="Path 84411"
                                              d="M53.093,2.747H12.511a.791.791,0,0,0,0,1.582H52.418V37.175H37.777L40.94,31.7a4.061,4.061,0,0,0-1.493-5.551h0a1.1,1.1,0,0,0-1.5.408L31.815,37.176H1.582V4.329H19.03a.791.791,0,0,0,0-1.582H.907A.908.908,0,0,0,0,3.654v34.2a.908.908,0,0,0,.907.907H27.68l1.611,2.79L27.92,43.922a.192.192,0,0,1-.142.1.168.168,0,0,1-.144-.045A7.028,7.028,0,0,0,22.338,42a7.088,7.088,0,1,0,6.581,10.515l3.353-5.807,1.462,2.532a.791.791,0,0,0,1.37-.791l-1.919-3.323,1.154-2,.914,1.584a1.768,1.768,0,0,0,2.753.4,5.46,5.46,0,0,1,4.115-1.531A5.506,5.506,0,1,1,37,51.744a.791.791,0,1,0-1.384.767,7.089,7.089,0,1,0,1.289-8.538.167.167,0,0,1-.144.045.192.192,0,0,1-.142-.1l-1.371-2.375,1.611-2.79H53.093A.908.908,0,0,0,54,37.85V3.654a.908.908,0,0,0-.907-.907ZM39.055,27.8a2.48,2.48,0,0,1,.515,3.106l-5.243,9.081a2.583,2.583,0,0,0-1.731-1ZM32.272,42.548a1,1,0,1,1,1-1A1,1,0,0,1,32.272,42.548Zm-2.766-3.79h1.4l-.7,1.208ZM27.539,51.744a5.507,5.507,0,1,1-1-6.631,1.768,1.768,0,0,0,2.753-.4l.927-1.6a2.583,2.583,0,0,0,1.73,1c-.925,1.6-4.378,7.578-4.408,7.635Z"
                                              transform="translate(1452.113 897.839)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84412" data-name="Path 84412"
                                              d="M441.285,277.3a.887.887,0,0,0,.886.886h2.95a.887.887,0,0,0,.886-.886v-2.95a.887.887,0,0,0-.886-.886h-2.95a.887.887,0,0,0-.886.886Zm1.549-2.287h1.623v1.623h-1.623Z"
                                              transform="translate(1057.524 655.734)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.2"/>
                                        <path id="Path_84413" data-name="Path 84413"
                                              d="M29.722,28.632a.887.887,0,0,0-.886-.886h-2.95a.887.887,0,0,0-.886.886v2.95a.887.887,0,0,0,.886.886h2.95a.887.887,0,0,0,.886-.886Zm-1.549,2.287H26.549V29.3h1.623Z"
                                              transform="translate(1429.695 875.422)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.2"/>
                                        <path id="Path_84414" data-name="Path 84414"
                                              d="M441.285,154.441a.887.887,0,0,0,.886.886h2.95a.887.887,0,0,0,.886-.886v-2.95a.887.887,0,0,0-.886-.886h-2.95a.887.887,0,0,0-.886.886Zm1.549-2.287h1.623v1.623h-1.623Z"
                                              transform="translate(1057.524 765.608)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.2"/>
                                        <path id="Path_84415" data-name="Path 84415"
                                              d="M28.836,273.463h-2.95a.887.887,0,0,0-.886.886v2.95a.887.887,0,0,0,.886.886h2.95a.887.887,0,0,0,.886-.886v-2.95A.887.887,0,0,0,28.836,273.463Zm-.663,3.173H26.549v-1.623h1.623Z"
                                              transform="translate(1429.695 655.734)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.2"/>
                                        <path id="Path_84416" data-name="Path 84416"
                                              d="M29.722,151.491a.887.887,0,0,0-.886-.886h-2.95a.887.887,0,0,0-.886.886v2.95a.887.887,0,0,0,.886.886h2.95a.887.887,0,0,0,.886-.886Zm-1.549,2.287H26.549v-1.623h1.623Z"
                                              transform="translate(1429.695 765.608)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.2"/>
                                        <path id="Path_84417" data-name="Path 84417"
                                              d="M441.285,93.011a.887.887,0,0,0,.886.886h2.95a.887.887,0,0,0,.886-.886v-2.95a.887.887,0,0,0-.886-.886h-2.95a.887.887,0,0,0-.886.886Zm1.549-2.287h1.623v1.623h-1.623Z"
                                              transform="translate(1057.524 820.545)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.2"/>
                                        <path id="Path_84418" data-name="Path 84418"
                                              d="M442.171,32.468h2.95a.887.887,0,0,0,.886-.886v-2.95a.887.887,0,0,0-.886-.886h-2.95a.887.887,0,0,0-.886.886v2.95A.887.887,0,0,0,442.171,32.468Zm.663-3.173h1.623v1.623h-1.623Z"
                                              transform="translate(1057.524 875.422)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.2"/>
                                        <path id="Path_84419" data-name="Path 84419"
                                              d="M29.722,90.061a.887.887,0,0,0-.886-.886h-2.95a.887.887,0,0,0-.886.886v2.95a.887.887,0,0,0,.886.886h2.95a.887.887,0,0,0,.886-.886Zm-1.549,2.287H26.549V90.725h1.623Z"
                                              transform="translate(1429.695 820.545)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.2"/>
                                        <path id="Path_84420" data-name="Path 84420"
                                              d="M29.722,212.92a.887.887,0,0,0-.886-.886h-2.95a.887.887,0,0,0-.886.886v2.95a.887.887,0,0,0,.886.886h2.95a.887.887,0,0,0,.886-.886Zm-1.549,2.287H26.549v-1.623h1.623Z"
                                              transform="translate(1429.695 710.671)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.2"/>
                                    </g>
                                </svg>
                            </div>
                            <h3>Professional Editing</h3>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                        </div>
                        <div className="item">
                            <div className="icon-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="44.904" height="54.5"
                                     viewBox="0 0 44.904 54.5">
                                    <g id="Group_57621" data-name="Group 57621"
                                       transform="translate(-821.681 -1686.164)">
                                        <path id="Path_84042" data-name="Path 84042"
                                              d="M15.1,22.434l7.266-4.389a.692.692,0,0,0,0-1.181L15.1,12.475a.684.684,0,0,0-1.036.591v8.778a.684.684,0,0,0,1.036.591Z"
                                              transform="translate(825.754 1684.333)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84423" data-name="Path 84423"
                                              d="M44.9,11.979c0-.013,0-.026,0-.04s0-.051,0-.077c-.042-2.337-.339-3.237-2.318-5.388L39.123,2.714c-.2-.214-.374-.411-.537-.592C37.138.51,36.558,0,34.019,0H3.958A3.463,3.463,0,0,0,.5,3.46V29.229a.8.8,0,1,0,1.594,0V3.46A1.868,1.868,0,0,1,3.958,1.594H33.221l0,7.681a3.463,3.463,0,0,0,3.459,3.462H43.31v37.8a1.867,1.867,0,0,1-1.864,1.866H3.958A1.867,1.867,0,0,1,2.094,50.54V28.711a.8.8,0,1,0-1.594,0V50.54A3.463,3.463,0,0,0,3.958,54H41.446A3.463,3.463,0,0,0,44.9,50.54V12.863c0-.317,0-.61,0-.884Zm-8.223-.836a1.869,1.869,0,0,1-1.864-1.866l0-7.664c1.239.076,1.539.409,2.586,1.574.166.185.348.387.55.606l3.456,3.761c1.429,1.553,1.786,2.184,1.875,3.589Zm0,0"
                                              transform="translate(821.431 1686.414)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84426" data-name="Path 84426"
                                              d="M59.66,267.239V270.1a1.268,1.268,0,0,0,1.266,1.267h4.91A1.268,1.268,0,0,0,67.1,270.1v-2.858a1.268,1.268,0,0,0-1.266-1.266h-4.91A1.268,1.268,0,0,0,59.66,267.239Zm1.6.332h4.247v2.2H61.258Zm0,0"
                                              transform="translate(768.526 1448.486)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84427" data-name="Path 84427"
                                              d="M59.66,342.251a1.269,1.269,0,0,0,1.266,1.27h4.91a1.269,1.269,0,0,0,1.266-1.27v-2.856a1.269,1.269,0,0,0-1.266-1.27h-4.91a1.269,1.269,0,0,0-1.266,1.27Zm1.6-2.527h4.247v2.2H61.258Zm0,0"
                                              transform="translate(768.526 1383.94)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84428" data-name="Path 84428"
                                              d="M59.66,414.43a1.269,1.269,0,0,0,1.266,1.27h4.91a1.269,1.269,0,0,0,1.266-1.27v-2.856a1.268,1.268,0,0,0-1.266-1.266h-4.91a1.268,1.268,0,0,0-1.266,1.266Zm1.6-2.524h4.247v2.2H61.258Zm0,0"
                                              transform="translate(768.526 1319.353)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84429" data-name="Path 84429"
                                              d="M177.047,284.789H156.01a.8.8,0,1,0,0,1.6h21.038a.8.8,0,1,0,0-1.6Zm0,0"
                                              transform="translate(682.968 1431.659)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84430" data-name="Path 84430"
                                              d="M281.8,356.063h-8.653a.8.8,0,0,0,0,1.6H281.8a.8.8,0,0,0,0-1.6Zm0,0"
                                              transform="translate(578.219 1367.901)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84431" data-name="Path 84431"
                                              d="M156.011,357.661h8.725a.8.8,0,0,0,0-1.6h-8.725a.8.8,0,1,0,0,1.6Zm0,0"
                                              transform="translate(683.025 1367.902)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84432" data-name="Path 84432"
                                              d="M156.011,429.844h21.038a.8.8,0,1,0,0-1.6H156.011a.8.8,0,1,0,0,1.6Zm0,0"
                                              transform="translate(682.967 1303.313)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                        <path id="Path_84433" data-name="Path 84433"
                                              d="M122.676,52.742a10.6,10.6,0,1,0,10.6,10.6A10.609,10.609,0,0,0,122.676,52.742Zm0,19.481a8.879,8.879,0,1,1,8.873-8.88A8.885,8.885,0,0,1,122.676,72.223Zm0,0"
                                              transform="translate(720.821 1638.475)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.5"/>
                                    </g>
                                </svg>
                            </div>
                            <h3>Video Shooting Instructions</h3>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                        </div>
                        <div className="item">
                            <div className="icon-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="40.5" viewBox="0 0 54 40.5">
                                    <g id="sf-id-card" transform="translate(0 -3)">
                                        <g id="Group_57551" data-name="Group 57551" transform="translate(0 3)">
                                            <path id="Path_84398" data-name="Path 84398"
                                                  d="M48.375,43.5H5.625A5.633,5.633,0,0,1,0,37.875V8.625A5.633,5.633,0,0,1,5.625,3h42.75A5.633,5.633,0,0,1,54,8.625v29.25A5.633,5.633,0,0,1,48.375,43.5ZM5.625,5.25A3.379,3.379,0,0,0,2.25,8.625v29.25A3.379,3.379,0,0,0,5.625,41.25h42.75a3.379,3.379,0,0,0,3.375-3.375V8.625A3.379,3.379,0,0,0,48.375,5.25Z"
                                                  transform="translate(0 -3)" fill="#c0321f"/>
                                        </g>
                                        <g id="Group_57552" data-name="Group 57552"
                                           transform="translate(11.152 11.885)">
                                            <path id="Path_84399" data-name="Path 84399"
                                                  d="M10.811,18.623a5.811,5.811,0,1,1,5.811-5.811A5.819,5.819,0,0,1,10.811,18.623Zm0-9.3A3.487,3.487,0,1,0,14.3,12.811,3.491,3.491,0,0,0,10.811,9.325Z"
                                                  transform="translate(-5 -7)" fill="#c0321f"/>
                                        </g>
                                        <g id="Group_57553" data-name="Group 57553" transform="translate(6.974 25.287)">
                                            <path id="Path_84400" data-name="Path 84400"
                                                  d="M22.758,22.3A1.163,1.163,0,0,1,21.6,21.136V18.811a3.491,3.491,0,0,0-3.487-3.487h-9.3a3.491,3.491,0,0,0-3.487,3.487v2.325a1.162,1.162,0,1,1-2.325,0V18.811A5.819,5.819,0,0,1,8.811,13h9.3a5.819,5.819,0,0,1,5.811,5.811v2.325A1.163,1.163,0,0,1,22.758,22.3Z"
                                                  transform="translate(-3 -13)" fill="#c0321f"/>
                                        </g>
                                        <g id="Group_57554" data-name="Group 57554"
                                           transform="translate(30.755 14.228)">
                                            <path id="Path_84401" data-name="Path 84401"
                                                  d="M29.109,10.325H15.162a1.162,1.162,0,0,1,0-2.325H29.109a1.162,1.162,0,0,1,0,2.325Z"
                                                  transform="translate(-14 -8)" fill="#c0321f"/>
                                        </g>
                                        <g id="Group_57555" data-name="Group 57555"
                                           transform="translate(30.755 23.211)">
                                            <path id="Path_84402" data-name="Path 84402"
                                                  d="M29.109,14.325H15.162a1.162,1.162,0,0,1,0-2.325H29.109a1.162,1.162,0,0,1,0,2.325Z"
                                                  transform="translate(-14 -12)" fill="#c0321f"/>
                                        </g>
                                        <g id="Group_57556" data-name="Group 57556"
                                           transform="translate(30.755 32.193)">
                                            <path id="Path_84403" data-name="Path 84403"
                                                  d="M29.109,18.325H15.162a1.162,1.162,0,0,1,0-2.325H29.109a1.162,1.162,0,0,1,0,2.325Z"
                                                  transform="translate(-14 -16)" fill="#c0321f"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <h3>Title Card</h3>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                        </div>
                        <div className="item">
                            <div className="icon-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60.15" height="58.075"
                                     viewBox="0 0 60.15 58.075">
                                    <g id="Group_57620" data-name="Group 57620"
                                       transform="translate(-1187.85 -1822.85)">
                                        <g id="Group_57617" data-name="Group 57617"
                                           transform="translate(1220.729 1851.56)">
                                            <g id="Group_57552" data-name="Group 57552" transform="translate(5.282 0)">
                                                <path id="Path_84399" data-name="Path 84399"
                                                      d="M12.013,21.026a7.013,7.013,0,1,1,7.013-7.013A7.023,7.023,0,0,1,12.013,21.026Zm0-11.221a4.208,4.208,0,1,0,4.208,4.208A4.213,4.213,0,0,0,12.013,9.805Z"
                                                      transform="translate(-5 -7)" fill="#c0321f"/>
                                            </g>
                                            <g id="Group_57553" data-name="Group 57553" transform="translate(0 16.45)">
                                                <path id="Path_84400" data-name="Path 84400"
                                                      d="M26.845,24.221a1.4,1.4,0,0,1-1.4-1.4V20.013a4.213,4.213,0,0,0-4.208-4.208H10.013a4.213,4.213,0,0,0-4.208,4.208v2.805a1.4,1.4,0,0,1-2.805,0V20.013A7.023,7.023,0,0,1,10.013,13H21.234a7.023,7.023,0,0,1,7.013,7.013v2.805A1.4,1.4,0,0,1,26.845,24.221Z"
                                                      transform="translate(-3 -13)" fill="#c0321f"/>
                                            </g>
                                        </g>
                                        <path id="Path_84434" data-name="Path 84434"
                                              d="M34.089,38.664l4.574-4.575a.965.965,0,0,0,0-1.366l-2.437-2.437a14.712,14.712,0,0,0,1.2-2.89h3.444a.966.966,0,0,0,.966-.967V19.962A.966.966,0,0,0,40.869,19H37.432a14.6,14.6,0,0,0-1.2-2.892l2.433-2.433a.966.966,0,0,0,0-1.367L34.089,7.729a.965.965,0,0,0-1.366,0L30.29,10.163a14.6,14.6,0,0,0-2.892-1.2V5.523a.966.966,0,0,0-.967-.966H19.962A.966.966,0,0,0,19,5.523V8.967a14.729,14.729,0,0,0-2.889,1.2L13.67,7.729a.966.966,0,0,0-1.367,0L7.729,12.3a.966.966,0,0,0,0,1.367l2.436,2.436A14.739,14.739,0,0,0,8.966,19H5.523a.966.966,0,0,0-.966.966v6.468a.966.966,0,0,0,.966.966H8.96a14.594,14.594,0,0,0,1.2,2.892L7.729,32.724a.965.965,0,0,0,0,1.366L12.3,38.664a.968.968,0,0,0,1.367,0L16.1,36.231A14.627,14.627,0,0,0,19,37.433V40.87a.967.967,0,0,0,.966.967h6.469a.966.966,0,0,0,.967-.966V37.426a14.728,14.728,0,0,0,2.889-1.2l2.437,2.437A.966.966,0,0,0,34.089,38.664Zm-2.961-4.328a.965.965,0,0,0-1.189-.14,12.8,12.8,0,0,1-3.733,1.546.968.968,0,0,0-.742.939V39.9H20.928V36.69a.966.966,0,0,0-.743-.939A12.714,12.714,0,0,1,16.455,34.2a.967.967,0,0,0-1.191.139l-2.277,2.275L9.78,33.406l2.275-2.276a.967.967,0,0,0,.139-1.191,12.707,12.707,0,0,1-1.551-3.733.966.966,0,0,0-.939-.743H6.49V20.928H9.709a.967.967,0,0,0,.939-.742,12.768,12.768,0,0,1,1.546-3.732.968.968,0,0,0-.14-1.19L9.78,12.987,12.987,9.78l2.278,2.278a.967.967,0,0,0,1.19.14,12.793,12.793,0,0,1,3.733-1.546.968.968,0,0,0,.742-.939V6.49h4.536V9.7a.967.967,0,0,0,.743.939,12.71,12.71,0,0,1,3.733,1.551.964.964,0,0,0,1.191-.139l2.275-2.275,3.207,3.208-2.275,2.276a.968.968,0,0,0-.139,1.191,12.692,12.692,0,0,1,1.551,3.732.966.966,0,0,0,.939.743H39.9v4.535h-3.22a.968.968,0,0,0-.939.742A12.788,12.788,0,0,1,34.2,29.939a.965.965,0,0,0,.14,1.189l2.276,2.278-3.207,3.207Z"
                                              transform="translate(1183.443 1818.443)" fill="#c0321f" stroke="#c0321f"
                                              strokeWidth="0.3"/>
                                        <path id="Path_84435" data-name="Path 84435"
                                              d="M31.525,23.158a8.367,8.367,0,1,0,8.367,8.367,8.367,8.367,0,0,0-8.367-8.367Zm0,14.8a6.434,6.434,0,1,1,6.434-6.434,6.434,6.434,0,0,1-6.434,6.434Z"
                                              transform="translate(1174.964 1809.962)" fill="#c0321f"/>
                                        <path id="Path_84438" data-name="Path 84438"
                                              d="M76.149,19.322A24.8,24.8,0,0,1,89.84,29.446a4.253,4.253,0,1,0,1.695-.939A26.724,26.724,0,0,0,76.7,17.468a.966.966,0,1,0-.546,1.854ZM94.976,32.586a2.3,2.3,0,1,1-2.3-2.3,2.3,2.3,0,0,1,2.3,2.3Z"
                                              transform="translate(1151.053 1812.681)" fill="#c0321f"/>
                                        <path id="Path_84439" data-name="Path 84439"
                                              d="M34.431,92.932A24.8,24.8,0,0,1,20.744,82.807a4.253,4.253,0,1,0-1.695.939,26.722,26.722,0,0,0,14.837,11.04.966.966,0,0,0,.546-1.854ZM15.6,79.668a2.3,2.3,0,1,1,2.3,2.305A2.3,2.3,0,0,1,15.6,79.668Z"
                                              transform="translate(1179.378 1786.1)" fill="#c0321f"/>
                                    </g>
                                </svg>
                            </div>
                            <h3>Profile Integration</h3>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-started-sec">
                <div className="container">
                    <h3 className="sec-title">Need help with your Highlight Video?</h3>
                    <div className="short-text">
                        <p>We’ve filmed and edited thousands of highlight videos in more than 12 different sports over
                            the last 15 years. If you<br/> don’t see your specific sport please request a sample video.
                        </p>
                    </div>
                    <div className="button-wrap">
                        <a className="btn" href={void 0}>Contact Us</a>
                    </div>
                </div>
            </section>

            <section className="hightlight-video-sec">
                <div className="container">
                    <div className="top-block">
                        <div className="title">Some Highlight Reels</div>
                        <div className="filter-box">
                            <label>Sort by</label>
                            <select>
                                <option selected>Select</option>
                                <option>Select 1</option>
                                <option>Select 2</option>
                            </select>
                        </div>
                    </div>
                    <ul className="videos-listing">
                        {hightlightVideo?.length > 0 && hightlightVideo?.map((high, idx)=>{
                            if(high?.title == "highlightreels"){
                                return(
                                    <li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
                                        <div className="video-inner-box">
                                            <div className="img-box" onClick={()=>openModal(high,true)}
                                                 data-id="aqz-KE-bpKQ"
                                                 href="#aqz-KE-bpKQ">
                                                <video poster={`${imageDownloadUrl}/highlight-reels-video-img01.png`}>
                                                    <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>
                                                    <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>
                                                </video>
                                                <div className="video-play-button">
                                                    <div className="video-play-inner">
                                                        <button>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="62.25" height="62.25"
                                                                 viewBox="0 0 62.25 62.25">
                                                                <g id="play-circle-outline" transform="translate(-3.375 -3.375)">
                                                                    <path id="Path_84041" data-name="Path 84041"
                                                                          d="M64.5,34.5a30,30,0,1,0-30,30A30.008,30.008,0,0,0,64.5,34.5Z"
                                                                          transform="translate(0 0)" fill="none" stroke="#fff"
                                                                          strokeWidth="2.25"/>
                                                                    <path id="Path_84042" data-name="Path 84042"
                                                                          d="M16.644,37.44,34.752,26.5a1.724,1.724,0,0,0,0-2.943L16.644,12.619a1.706,1.706,0,0,0-2.581,1.474V35.968a1.706,1.706,0,0,0,2.581,1.474Z"
                                                                          transform="translate(11.541 9.47)" fill="#fff"/>
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="video-detail-box">
                                                <div className="title-info">
                                                    <h4>Megan Morneau</h4>
                                                    <div className="views">
                                  <span className="icon-box">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.999"
                                           viewBox="0 0 24 15.999">
                                          <g id="eye" transform="translate(-1.123 -6.75)">
                                            <path id="Path_84393" data-name="Path 84393"
                                                  d="M19.952,16.726A3.226,3.226,0,1,1,16.726,13.5,3.226,3.226,0,0,1,19.952,16.726Z"
                                                  transform="translate(-3.602 -1.977)" fill="#ccc"/>
                                            <path id="Path_84394" data-name="Path 84394"
                                                  d="M24.865,13.88A16.948,16.948,0,0,0,19.9,8.853a12.56,12.56,0,0,0-6.8-2.1A11.978,11.978,0,0,0,6.893,8.555a18.216,18.216,0,0,0-5.484,5.3,1.6,1.6,0,0,0-.032,1.777,16.706,16.706,0,0,0,4.914,5.032,12.387,12.387,0,0,0,6.816,2.081,12.619,12.619,0,0,0,6.81-2.086,16.709,16.709,0,0,0,4.954-5.046,1.61,1.61,0,0,0,0-1.738ZM13.124,19.549a4.8,4.8,0,1,1,4.8-4.8A4.8,4.8,0,0,1,13.124,19.549Z"
                                                  transform="translate(0 0)" fill="#ccc"/>
                                          </g>
                                      </svg>
                                  </span>
                                                        {high?.views ?? "error"}
                                                    </div>
                                                </div>
                                                <p>Catcher, Baseball</p>
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                        })}
                        {/*<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">*/}
                        {/*    <div className="video-inner-box">*/}
                        {/*        <div className="img-box" onClick={()=>setIsVideoPop(true)}*/}
                        {/*             data-id="aqz-KE-bpKQ"*/}
                        {/*             href="#aqz-KE-bpKQ">*/}
                        {/*            <video poster={`${imageDownloadUrl}/highlight-reels-video-img02.png`}>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>*/}
                        {/*            </video>*/}
                        {/*            <div className="video-play-button">*/}
                        {/*                <div className="video-play-inner">*/}
                        {/*                    <button>*/}
                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="62.25" height="62.25"*/}
                        {/*                             viewBox="0 0 62.25 62.25">*/}
                        {/*                            <g id="play-circle-outline" transform="translate(-3.375 -3.375)">*/}
                        {/*                                <path id="Path_84041" data-name="Path 84041"*/}
                        {/*                                      d="M64.5,34.5a30,30,0,1,0-30,30A30.008,30.008,0,0,0,64.5,34.5Z"*/}
                        {/*                                      transform="translate(0 0)" fill="none" stroke="#fff"*/}
                        {/*                                      strokeWidth="2.25"/>*/}
                        {/*                                <path id="Path_84042" data-name="Path 84042"*/}
                        {/*                                      d="M16.644,37.44,34.752,26.5a1.724,1.724,0,0,0,0-2.943L16.644,12.619a1.706,1.706,0,0,0-2.581,1.474V35.968a1.706,1.706,0,0,0,2.581,1.474Z"*/}
                        {/*                                      transform="translate(11.541 9.47)" fill="#fff"/>*/}
                        {/*                            </g>*/}
                        {/*                        </svg>*/}
                        {/*                    </button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="video-detail-box">*/}
                        {/*            <div className="title-info">*/}
                        {/*                <h4>Megan Morneau</h4>*/}
                        {/*                <div className="views">*/}
                        {/*          <span className="icon-box">*/}
                        {/*              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.999"*/}
                        {/*                   viewBox="0 0 24 15.999">*/}
                        {/*                  <g id="eye" transform="translate(-1.123 -6.75)">*/}
                        {/*                    <path id="Path_84393" data-name="Path 84393"*/}
                        {/*                          d="M19.952,16.726A3.226,3.226,0,1,1,16.726,13.5,3.226,3.226,0,0,1,19.952,16.726Z"*/}
                        {/*                          transform="translate(-3.602 -1.977)" fill="#ccc"/>*/}
                        {/*                    <path id="Path_84394" data-name="Path 84394"*/}
                        {/*                          d="M24.865,13.88A16.948,16.948,0,0,0,19.9,8.853a12.56,12.56,0,0,0-6.8-2.1A11.978,11.978,0,0,0,6.893,8.555a18.216,18.216,0,0,0-5.484,5.3,1.6,1.6,0,0,0-.032,1.777,16.706,16.706,0,0,0,4.914,5.032,12.387,12.387,0,0,0,6.816,2.081,12.619,12.619,0,0,0,6.81-2.086,16.709,16.709,0,0,0,4.954-5.046,1.61,1.61,0,0,0,0-1.738ZM13.124,19.549a4.8,4.8,0,1,1,4.8-4.8A4.8,4.8,0,0,1,13.124,19.549Z"*/}
                        {/*                          transform="translate(0 0)" fill="#ccc"/>*/}
                        {/*                  </g>*/}
                        {/*              </svg>*/}
                        {/*          </span>*/}
                        {/*                    2*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <p>Catcher, Baseball</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {/*<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">*/}
                        {/*    <div className="video-inner-box">*/}
                        {/*        <div className="img-box" onClick={()=>setIsVideoPop(true)}*/}
                        {/*             data-id="aqz-KE-bpKQ"*/}
                        {/*             href="#aqz-KE-bpKQ" >*/}
                        {/*            <video poster={`${imageDownloadUrl}/highlight-reels-video-img03.png`}>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>*/}
                        {/*            </video>*/}
                        {/*            <div className="video-play-button">*/}
                        {/*                <div className="video-play-inner">*/}
                        {/*                    <button>*/}
                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="62.25" height="62.25"*/}
                        {/*                             viewBox="0 0 62.25 62.25">*/}
                        {/*                            <g id="play-circle-outline" transform="translate(-3.375 -3.375)">*/}
                        {/*                                <path id="Path_84041" data-name="Path 84041"*/}
                        {/*                                      d="M64.5,34.5a30,30,0,1,0-30,30A30.008,30.008,0,0,0,64.5,34.5Z"*/}
                        {/*                                      transform="translate(0 0)" fill="none" stroke="#fff"*/}
                        {/*                                      strokeWidth="2.25"/>*/}
                        {/*                                <path id="Path_84042" data-name="Path 84042"*/}
                        {/*                                      d="M16.644,37.44,34.752,26.5a1.724,1.724,0,0,0,0-2.943L16.644,12.619a1.706,1.706,0,0,0-2.581,1.474V35.968a1.706,1.706,0,0,0,2.581,1.474Z"*/}
                        {/*                                      transform="translate(11.541 9.47)" fill="#fff"/>*/}
                        {/*                            </g>*/}
                        {/*                        </svg>*/}
                        {/*                    </button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="video-detail-box">*/}
                        {/*            <div className="title-info">*/}
                        {/*                <h4>Megan Morneau</h4>*/}
                        {/*                <div className="views">*/}
                        {/*          <span className="icon-box">*/}
                        {/*              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.999"*/}
                        {/*                   viewBox="0 0 24 15.999">*/}
                        {/*                  <g id="eye" transform="translate(-1.123 -6.75)">*/}
                        {/*                    <path id="Path_84393" data-name="Path 84393"*/}
                        {/*                          d="M19.952,16.726A3.226,3.226,0,1,1,16.726,13.5,3.226,3.226,0,0,1,19.952,16.726Z"*/}
                        {/*                          transform="translate(-3.602 -1.977)" fill="#ccc"/>*/}
                        {/*                    <path id="Path_84394" data-name="Path 84394"*/}
                        {/*                          d="M24.865,13.88A16.948,16.948,0,0,0,19.9,8.853a12.56,12.56,0,0,0-6.8-2.1A11.978,11.978,0,0,0,6.893,8.555a18.216,18.216,0,0,0-5.484,5.3,1.6,1.6,0,0,0-.032,1.777,16.706,16.706,0,0,0,4.914,5.032,12.387,12.387,0,0,0,6.816,2.081,12.619,12.619,0,0,0,6.81-2.086,16.709,16.709,0,0,0,4.954-5.046,1.61,1.61,0,0,0,0-1.738ZM13.124,19.549a4.8,4.8,0,1,1,4.8-4.8A4.8,4.8,0,0,1,13.124,19.549Z"*/}
                        {/*                          transform="translate(0 0)" fill="#ccc"/>*/}
                        {/*                  </g>*/}
                        {/*              </svg>*/}
                        {/*          </span>*/}
                        {/*                    2*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <p>Catcher, Baseball</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {/*<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">*/}
                        {/*    <div className="video-inner-box">*/}
                        {/*        <div className="img-box" onClick={()=>setIsVideoPop(true)}*/}
                        {/*             data-id="aqz-KE-bpKQ"*/}
                        {/*             href="#aqz-KE-bpKQ" >*/}
                        {/*            <video poster={`${imageDownloadUrl}/highlight-reels-video-img04.png`}>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>*/}
                        {/*            </video>*/}
                        {/*            <div className="video-play-button">*/}
                        {/*                <div className="video-play-inner">*/}
                        {/*                    <button>*/}
                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="62.25" height="62.25"*/}
                        {/*                             viewBox="0 0 62.25 62.25">*/}
                        {/*                            <g id="play-circle-outline" transform="translate(-3.375 -3.375)">*/}
                        {/*                                <path id="Path_84041" data-name="Path 84041"*/}
                        {/*                                      d="M64.5,34.5a30,30,0,1,0-30,30A30.008,30.008,0,0,0,64.5,34.5Z"*/}
                        {/*                                      transform="translate(0 0)" fill="none" stroke="#fff"*/}
                        {/*                                      strokeWidth="2.25"/>*/}
                        {/*                                <path id="Path_84042" data-name="Path 84042"*/}
                        {/*                                      d="M16.644,37.44,34.752,26.5a1.724,1.724,0,0,0,0-2.943L16.644,12.619a1.706,1.706,0,0,0-2.581,1.474V35.968a1.706,1.706,0,0,0,2.581,1.474Z"*/}
                        {/*                                      transform="translate(11.541 9.47)" fill="#fff"/>*/}
                        {/*                            </g>*/}
                        {/*                        </svg>*/}
                        {/*                    </button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="video-detail-box">*/}
                        {/*            <div className="title-info">*/}
                        {/*                <h4>Megan Morneau</h4>*/}
                        {/*                <div className="views">*/}
                        {/*          <span className="icon-box">*/}
                        {/*              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.999"*/}
                        {/*                   viewBox="0 0 24 15.999">*/}
                        {/*                  <g id="eye" transform="translate(-1.123 -6.75)">*/}
                        {/*                    <path id="Path_84393" data-name="Path 84393"*/}
                        {/*                          d="M19.952,16.726A3.226,3.226,0,1,1,16.726,13.5,3.226,3.226,0,0,1,19.952,16.726Z"*/}
                        {/*                          transform="translate(-3.602 -1.977)" fill="#ccc"/>*/}
                        {/*                    <path id="Path_84394" data-name="Path 84394"*/}
                        {/*                          d="M24.865,13.88A16.948,16.948,0,0,0,19.9,8.853a12.56,12.56,0,0,0-6.8-2.1A11.978,11.978,0,0,0,6.893,8.555a18.216,18.216,0,0,0-5.484,5.3,1.6,1.6,0,0,0-.032,1.777,16.706,16.706,0,0,0,4.914,5.032,12.387,12.387,0,0,0,6.816,2.081,12.619,12.619,0,0,0,6.81-2.086,16.709,16.709,0,0,0,4.954-5.046,1.61,1.61,0,0,0,0-1.738ZM13.124,19.549a4.8,4.8,0,1,1,4.8-4.8A4.8,4.8,0,0,1,13.124,19.549Z"*/}
                        {/*                          transform="translate(0 0)" fill="#ccc"/>*/}
                        {/*                  </g>*/}
                        {/*              </svg>*/}
                        {/*          </span>*/}
                        {/*                    2*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <p>Catcher, Baseball</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {/*<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">*/}
                        {/*    <div className="video-inner-box">*/}
                        {/*        <div className="img-box" onClick={()=>setIsVideoPop(true)}*/}
                        {/*             data-id="aqz-KE-bpKQ"*/}
                        {/*             href="#aqz-KE-bpKQ" >*/}
                        {/*            <video poster={`${imageDownloadUrl}/highlight-reels-video-img01.png`}>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>*/}
                        {/*            </video>*/}
                        {/*            <div className="video-play-button">*/}
                        {/*                <div className="video-play-inner">*/}
                        {/*                    <button>*/}
                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="62.25" height="62.25"*/}
                        {/*                             viewBox="0 0 62.25 62.25">*/}
                        {/*                            <g id="play-circle-outline" transform="translate(-3.375 -3.375)">*/}
                        {/*                                <path id="Path_84041" data-name="Path 84041"*/}
                        {/*                                      d="M64.5,34.5a30,30,0,1,0-30,30A30.008,30.008,0,0,0,64.5,34.5Z"*/}
                        {/*                                      transform="translate(0 0)" fill="none" stroke="#fff"*/}
                        {/*                                      strokeWidth="2.25"/>*/}
                        {/*                                <path id="Path_84042" data-name="Path 84042"*/}
                        {/*                                      d="M16.644,37.44,34.752,26.5a1.724,1.724,0,0,0,0-2.943L16.644,12.619a1.706,1.706,0,0,0-2.581,1.474V35.968a1.706,1.706,0,0,0,2.581,1.474Z"*/}
                        {/*                                      transform="translate(11.541 9.47)" fill="#fff"/>*/}
                        {/*                            </g>*/}
                        {/*                        </svg>*/}
                        {/*                    </button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="video-detail-box">*/}
                        {/*            <div className="title-info">*/}
                        {/*                <h4>Megan Morneau</h4>*/}
                        {/*                <div className="views">*/}
                        {/*          <span className="icon-box">*/}
                        {/*              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.999"*/}
                        {/*                   viewBox="0 0 24 15.999">*/}
                        {/*                  <g id="eye" transform="translate(-1.123 -6.75)">*/}
                        {/*                    <path id="Path_84393" data-name="Path 84393"*/}
                        {/*                          d="M19.952,16.726A3.226,3.226,0,1,1,16.726,13.5,3.226,3.226,0,0,1,19.952,16.726Z"*/}
                        {/*                          transform="translate(-3.602 -1.977)" fill="#ccc"/>*/}
                        {/*                    <path id="Path_84394" data-name="Path 84394"*/}
                        {/*                          d="M24.865,13.88A16.948,16.948,0,0,0,19.9,8.853a12.56,12.56,0,0,0-6.8-2.1A11.978,11.978,0,0,0,6.893,8.555a18.216,18.216,0,0,0-5.484,5.3,1.6,1.6,0,0,0-.032,1.777,16.706,16.706,0,0,0,4.914,5.032,12.387,12.387,0,0,0,6.816,2.081,12.619,12.619,0,0,0,6.81-2.086,16.709,16.709,0,0,0,4.954-5.046,1.61,1.61,0,0,0,0-1.738ZM13.124,19.549a4.8,4.8,0,1,1,4.8-4.8A4.8,4.8,0,0,1,13.124,19.549Z"*/}
                        {/*                          transform="translate(0 0)" fill="#ccc"/>*/}
                        {/*                  </g>*/}
                        {/*              </svg>*/}
                        {/*          </span>*/}
                        {/*                    2*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <p>Catcher, Baseball</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {/*<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">*/}
                        {/*    <div className="video-inner-box">*/}
                        {/*        <div className="img-box" onClick={()=>setIsVideoPop(true)}*/}
                        {/*             data-id="aqz-KE-bpKQ"*/}
                        {/*             href="#aqz-KE-bpKQ" >*/}
                        {/*            <video poster={`${imageDownloadUrl}/highlight-reels-video-img02.png`}>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>*/}
                        {/*            </video>*/}
                        {/*            <div className="video-play-button">*/}
                        {/*                <div className="video-play-inner">*/}
                        {/*                    <button>*/}
                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="62.25" height="62.25"*/}
                        {/*                             viewBox="0 0 62.25 62.25">*/}
                        {/*                            <g id="play-circle-outline" transform="translate(-3.375 -3.375)">*/}
                        {/*                                <path id="Path_84041" data-name="Path 84041"*/}
                        {/*                                      d="M64.5,34.5a30,30,0,1,0-30,30A30.008,30.008,0,0,0,64.5,34.5Z"*/}
                        {/*                                      transform="translate(0 0)" fill="none" stroke="#fff"*/}
                        {/*                                      strokeWidth="2.25"/>*/}
                        {/*                                <path id="Path_84042" data-name="Path 84042"*/}
                        {/*                                      d="M16.644,37.44,34.752,26.5a1.724,1.724,0,0,0,0-2.943L16.644,12.619a1.706,1.706,0,0,0-2.581,1.474V35.968a1.706,1.706,0,0,0,2.581,1.474Z"*/}
                        {/*                                      transform="translate(11.541 9.47)" fill="#fff"/>*/}
                        {/*                            </g>*/}
                        {/*                        </svg>*/}
                        {/*                    </button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="video-detail-box">*/}
                        {/*            <div className="title-info">*/}
                        {/*                <h4>Megan Morneau</h4>*/}
                        {/*                <div className="views">*/}
                        {/*          <span className="icon-box">*/}
                        {/*              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.999"*/}
                        {/*                   viewBox="0 0 24 15.999">*/}
                        {/*                  <g id="eye" transform="translate(-1.123 -6.75)">*/}
                        {/*                    <path id="Path_84393" data-name="Path 84393"*/}
                        {/*                          d="M19.952,16.726A3.226,3.226,0,1,1,16.726,13.5,3.226,3.226,0,0,1,19.952,16.726Z"*/}
                        {/*                          transform="translate(-3.602 -1.977)" fill="#ccc"/>*/}
                        {/*                    <path id="Path_84394" data-name="Path 84394"*/}
                        {/*                          d="M24.865,13.88A16.948,16.948,0,0,0,19.9,8.853a12.56,12.56,0,0,0-6.8-2.1A11.978,11.978,0,0,0,6.893,8.555a18.216,18.216,0,0,0-5.484,5.3,1.6,1.6,0,0,0-.032,1.777,16.706,16.706,0,0,0,4.914,5.032,12.387,12.387,0,0,0,6.816,2.081,12.619,12.619,0,0,0,6.81-2.086,16.709,16.709,0,0,0,4.954-5.046,1.61,1.61,0,0,0,0-1.738ZM13.124,19.549a4.8,4.8,0,1,1,4.8-4.8A4.8,4.8,0,0,1,13.124,19.549Z"*/}
                        {/*                          transform="translate(0 0)" fill="#ccc"/>*/}
                        {/*                  </g>*/}
                        {/*              </svg>*/}
                        {/*          </span>*/}
                        {/*                    2*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <p>Catcher, Baseball</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {/*<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">*/}
                        {/*    <div className="video-inner-box">*/}
                        {/*        <div className="img-box" onClick={()=>setIsVideoPop(true)}*/}
                        {/*             data-id="aqz-KE-bpKQ"*/}
                        {/*             href="#aqz-KE-bpKQ" >*/}
                        {/*            <video poster={`${imageDownloadUrl}/highlight-reels-video-img03.png`}>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>*/}
                        {/*            </video>*/}
                        {/*            <div className="video-play-button">*/}
                        {/*                <div className="video-play-inner">*/}
                        {/*                    <button>*/}
                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="62.25" height="62.25"*/}
                        {/*                             viewBox="0 0 62.25 62.25">*/}
                        {/*                            <g id="play-circle-outline" transform="translate(-3.375 -3.375)">*/}
                        {/*                                <path id="Path_84041" data-name="Path 84041"*/}
                        {/*                                      d="M64.5,34.5a30,30,0,1,0-30,30A30.008,30.008,0,0,0,64.5,34.5Z"*/}
                        {/*                                      transform="translate(0 0)" fill="none" stroke="#fff"*/}
                        {/*                                      strokeWidth="2.25"/>*/}
                        {/*                                <path id="Path_84042" data-name="Path 84042"*/}
                        {/*                                      d="M16.644,37.44,34.752,26.5a1.724,1.724,0,0,0,0-2.943L16.644,12.619a1.706,1.706,0,0,0-2.581,1.474V35.968a1.706,1.706,0,0,0,2.581,1.474Z"*/}
                        {/*                                      transform="translate(11.541 9.47)" fill="#fff"/>*/}
                        {/*                            </g>*/}
                        {/*                        </svg>*/}
                        {/*                    </button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="video-detail-box">*/}
                        {/*            <div className="title-info">*/}
                        {/*                <h4>Megan Morneau</h4>*/}
                        {/*                <div className="views">*/}
                        {/*          <span className="icon-box">*/}
                        {/*              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.999"*/}
                        {/*                   viewBox="0 0 24 15.999">*/}
                        {/*                  <g id="eye" transform="translate(-1.123 -6.75)">*/}
                        {/*                    <path id="Path_84393" data-name="Path 84393"*/}
                        {/*                          d="M19.952,16.726A3.226,3.226,0,1,1,16.726,13.5,3.226,3.226,0,0,1,19.952,16.726Z"*/}
                        {/*                          transform="translate(-3.602 -1.977)" fill="#ccc"/>*/}
                        {/*                    <path id="Path_84394" data-name="Path 84394"*/}
                        {/*                          d="M24.865,13.88A16.948,16.948,0,0,0,19.9,8.853a12.56,12.56,0,0,0-6.8-2.1A11.978,11.978,0,0,0,6.893,8.555a18.216,18.216,0,0,0-5.484,5.3,1.6,1.6,0,0,0-.032,1.777,16.706,16.706,0,0,0,4.914,5.032,12.387,12.387,0,0,0,6.816,2.081,12.619,12.619,0,0,0,6.81-2.086,16.709,16.709,0,0,0,4.954-5.046,1.61,1.61,0,0,0,0-1.738ZM13.124,19.549a4.8,4.8,0,1,1,4.8-4.8A4.8,4.8,0,0,1,13.124,19.549Z"*/}
                        {/*                          transform="translate(0 0)" fill="#ccc"/>*/}
                        {/*                  </g>*/}
                        {/*              </svg>*/}
                        {/*          </span>*/}
                        {/*                    2*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <p>Catcher, Baseball</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {/*<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">*/}
                        {/*    <div className="video-inner-box">*/}
                        {/*        <div className="img-box" onClick={()=>setIsVideoPop(true)}*/}
                        {/*             data-id="aqz-KE-bpKQ"*/}
                        {/*             href="#aqz-KE-bpKQ">*/}
                        {/*            <video poster={`${imageDownloadUrl}/highlight-reels-video-img04.png`}>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>*/}
                        {/*                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>*/}
                        {/*            </video>*/}
                        {/*            <div className="video-play-button">*/}
                        {/*                <div className="video-play-inner">*/}
                        {/*                    <button>*/}
                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="62.25" height="62.25"*/}
                        {/*                             viewBox="0 0 62.25 62.25">*/}
                        {/*                            <g id="play-circle-outline" transform="translate(-3.375 -3.375)">*/}
                        {/*                                <path id="Path_84041" data-name="Path 84041"*/}
                        {/*                                      d="M64.5,34.5a30,30,0,1,0-30,30A30.008,30.008,0,0,0,64.5,34.5Z"*/}
                        {/*                                      transform="translate(0 0)" fill="none" stroke="#fff"*/}
                        {/*                                      strokeWidth="2.25"/>*/}
                        {/*                                <path id="Path_84042" data-name="Path 84042"*/}
                        {/*                                      d="M16.644,37.44,34.752,26.5a1.724,1.724,0,0,0,0-2.943L16.644,12.619a1.706,1.706,0,0,0-2.581,1.474V35.968a1.706,1.706,0,0,0,2.581,1.474Z"*/}
                        {/*                                      transform="translate(11.541 9.47)" fill="#fff"/>*/}
                        {/*                            </g>*/}
                        {/*                        </svg>*/}
                        {/*                    </button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="video-detail-box">*/}
                        {/*            <div className="title-info">*/}
                        {/*                <h4>Megan Morneau</h4>*/}
                        {/*                <div className="views">*/}
                        {/*          <span className="icon-box">*/}
                        {/*              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.999"*/}
                        {/*                   viewBox="0 0 24 15.999">*/}
                        {/*                  <g id="eye" transform="translate(-1.123 -6.75)">*/}
                        {/*                    <path id="Path_84393" data-name="Path 84393"*/}
                        {/*                          d="M19.952,16.726A3.226,3.226,0,1,1,16.726,13.5,3.226,3.226,0,0,1,19.952,16.726Z"*/}
                        {/*                          transform="translate(-3.602 -1.977)" fill="#ccc"/>*/}
                        {/*                    <path id="Path_84394" data-name="Path 84394"*/}
                        {/*                          d="M24.865,13.88A16.948,16.948,0,0,0,19.9,8.853a12.56,12.56,0,0,0-6.8-2.1A11.978,11.978,0,0,0,6.893,8.555a18.216,18.216,0,0,0-5.484,5.3,1.6,1.6,0,0,0-.032,1.777,16.706,16.706,0,0,0,4.914,5.032,12.387,12.387,0,0,0,6.816,2.081,12.619,12.619,0,0,0,6.81-2.086,16.709,16.709,0,0,0,4.954-5.046,1.61,1.61,0,0,0,0-1.738ZM13.124,19.549a4.8,4.8,0,1,1,4.8-4.8A4.8,4.8,0,0,1,13.124,19.549Z"*/}
                        {/*                          transform="translate(0 0)" fill="#ccc"/>*/}
                        {/*                  </g>*/}
                        {/*              </svg>*/}
                        {/*          </span>*/}
                        {/*                    2*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <p>Catcher, Baseball</p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                    </ul>
                    <div className="btn-wrap">
                        <button className="btn lightblue">Load more</button>
                    </div>
                </div>
            </section>

            <section className="brand-lp-sec">
                <div className="container">
                    <div className="brand-listing">
                        <div className="item">
                            <img src={NCAAIMG} alt=""/>
                        </div>
                        <div className="item">
                            <img src={NAIAIMG} alt=""/>
                        </div>
                        <div className="item">
                            <img src={NJCAAIMG} alt=""/>
                        </div>
                        <div className="item">
                            <img src={CCCAA} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <VideoPop
            isTestSingle={isVideoPop}
            setIsTestSingle={()=>setIsVideoPop(false)}
            vidID={vidID}
            />

        </>
    )
}
export default HighlightLP
