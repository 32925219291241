import {React, useEffect, useState} from "react";
import { saveAskAQuestionQuery } from "./askAQuestionQuery";
import messagePopup from "../../../../../../utils/messagePopup";
import axios from "axios";
import { imageDownloadUrl, imageUploadUrl } from "../../../../../../config";
import {
  getUserInfo,
  getAuthToken,
} from "../../../../../../services/authService";
import { askAQuestionSaveStart } from "../../../../../../redux/athlete/dashboard/homepage/askAQuestion";
import { connect } from "react-redux";
import BackdropLoader from "../../../../../common/Loader";
import toastr from "toastr";

function AskAQuestion(props) {
  const [fields, setFields] = useState({
    subject: "",
    question: "",
    selectedFile: "",
    fileName: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e, field) => {
    setErrors({
      errors: "",
    });
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setFields({
      ...fieldsT,
    });
    setLoading(false);
  };

  const handleValidation = (fieldname, e, setError) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;

    //validation for mandatory subject
    if (fieldname === undefined || fieldname === "subject") {
      if (fieldname === undefined) {
        fieldValue = fields["subject"] ? fields["subject"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["subject"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["subject"] = "Please enter the Subject of the Question";
      }
    }

    //validation for mandatory question
    if (fieldname === undefined || fieldname === "question") {
      if (fieldname === undefined) {
        fieldValue = fields["question"] ? fields["question"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["question"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["question"] = "You can't send an empty question";
      }
    }

    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const selectQuestionFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let fileSize = parseInt(file.size) / (1024 * 1024);
    if (parseInt(file.size) / (1024 * 1024) > 2) {
      // messagePopup("", "File size not more than 2MB", "error");
      toastr.error("File size not more than 2MB", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    } else {
      localStorage.setItem("fileName", file.name);
      var reader = new FileReader();
      reader.onload = function (base64) {
        if (localStorage.getItem("URL")) {
          localStorage.removeItem("URL");
          localStorage.removeItem("fileName");
        }
        localStorage.setItem("URL", reader.result);
      };
      reader.readAsDataURL(file);
      let fieldsT = fields;
      fieldsT["selectedFile"] = file;
      fieldsT["fileName"] = file ? file.name : "";
      setFields({
        ...fieldsT,
      });
    }
  };

  const previewFile = (e) => {
    e.preventDefault();
    var base64 = localStorage.getItem("URL");
    var fileName = localStorage.getItem("fileName");

    urltoFile(base64, fileName).then(function (file) {
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, "_blank");
    });
  };

  const urltoFile = (url, filename, mimeType) => {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const saveQuestionDetails = async (e) => {
    e.preventDefault();

    const { formIsValid } = handleValidation();
    let formData = new FormData();
    formData.append("file", fields.selectedFile);
    const response =
      fields.selectedFile != ""
        ? await axios({
            url: imageUploadUrl,
            method: "POST",
            data: formData,
            headers: {
              access_token: getAuthToken(),
            },
          })
        : "";

    const UploadedFileName =
      response.data &&
      response.data.result &&
      response.data.result.files &&
      response.data.result.files.file &&
      response.data.result.files.file[0] &&
      response.data.result.files.file[0]
        ? response.data.result.files.file[0].name
        : "";

    if (formIsValid) {
      setLoading(true);
      await props.askAQuestionSaveStart({
        query: saveAskAQuestionQuery,
        variables: {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : "",
            subject: fields.subject ? fields.subject : "",
            question: fields.question ? fields.question : "",
            questionphotoupload: UploadedFileName,
          },
        },
        successMessage: successMessage,
      });
    }
  };

  const successMessage = () => {
    // messagePopup("", "Thank you for connecting with us ", "success");
    toastr.success("Thank you for connecting with us ", "", {
      progressBar : true,timeOut: 2000,
      closeButton: true, positionClass: "toast-top-full-width",
      showEasing: "swing", fadeIn: 40000,
      preventDuplicates: true,
    })
    setFields({
      ...fields,
      subject: "",
      question: "",
      selectedFile: "",
      fileName: "",
    });
    setLoading(false);
  };
//this is used for scrolling the page on the top
  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0,0)
    },300)
  },[])
  return (
    <div>
      <BackdropLoader open={loading} />
      <section className="faq-sec">
        <div className="container">
          <div className="faq-box-wrapper">
            <h1 className="sec-title">
              <span>Ask</span> How can we help?
            </h1>
            <div className="help-form-box">
              <form>
                <div className="form-group">
                  <label>Subject</label>
                  <input
                    value={fields.subject}
                    onChange={(e) => handleChange(e, "subject")}
                    className="form-control"
                    type="text"
                    placeholder="Subject"
                  />
                  <div className="error">{errors["subject"]}</div>
                </div>
                <div className="form-group">
                  <textarea
                    value={fields.question}
                    onChange={(e) => handleChange(e, "question")}
                    className="form-control"
                    placeholder="Your Message Here"
                  ></textarea>

                  {fields.fileName ? (
                    <button
                      className="file-upload-value"
                      onClick={(e) => previewFile(e)}
                    >
                      1 File
                    </button>
                  ) : (
                    ""
                  )}
                  <div className="upload-file-box">
                    <input
                      onChange={(e) => selectQuestionFile(e)}
                      type="file"
                    />
                  </div>
                </div>
                <div className="error question-error">{errors["question"]}</div>
                <div className="form-group text-center">
                  <button
                    onClick={(e) => saveQuestionDetails(e)}
                    className="btn lightblue"
                  >
                    Send message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    askAQuestionSaveStart: (data) => dispatch(askAQuestionSaveStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AskAQuestion);
