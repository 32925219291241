import React, { useState, useEffect, useRef, memo } from "react";
import "../../../../../assets/css/stylesheet.scss";
import "./assets/css/editProfile.scss";
import { connect } from "react-redux";
import IntroductionIcon from "./assets/img/introduction-icon.png";
import GreenCheckIcon from "./assets/img/green-check-icon.svg";
import LeftSideEditProfile from "./LeftSideEditProfile";
import callIcon from "./assets/img/icons/Call.svg";
import gpaIcon from "./assets/img/icons/GPA.svg";
import athleteContactIcon from "./assets/img/icons/AthleteContactNo.svg";
import parentContactIcon from "./assets/img/icons/ParentContactNumber.svg";
import gradYearsIcon from "./assets/img/icons/GradYears.svg";
import heightIcon from "./assets/img/icons/Height.svg";
import WeightIcon from "./assets/img/icons/Weight.svg";
import parentIcon from "./assets/img/icons/ParentGuardian.svg";
import PositionIcon from "./assets/img/icons/Position.svg";
import profilePhotoIcon from "./assets/img/icons/ProfilePhoto.svg";
import ProfileReviewIcon from "./assets/img/icons/RequestProfileReviews.svg";
import { checkListPercentage } from "../../../../../utils/checkListPercentage";
import SubHeader from "./SubHeader";
import GoTopButton from "./GoTopButton";
import { allCheckListDataStart } from "../../../../../redux/common/allCheckListData";
import {
  getAllCheckListDataQuery,
  getProfileCalculationListQuery,
} from "../../../../common/CommonQuery";
import BackdropLoader from "../../../../common/Loader";
import { getUserInfo } from "../../../../../services/authService";
import { allProfileCalculationListStart } from "../../../../../redux/common/allProfileCalculationMaster";
import { Link, useHistory } from "react-router-dom";
import ScreenSwitch from "./ScreenSwitch";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { websiteDefaultUrl } from "../../../../../config";
import {getAthleteSysID} from "../../../../common/getProfileURLData";

const listDisplay = (row) => {
  return (
    <>
      <div className="flex-box" key={row.id}>
        <div className="box detail">
          <div className="icon-box">{`+${row.percentage}%`}</div>
          <div className="content-box">
            <h3>{row.label_name}</h3>
          </div>
        </div>
        <div className="box-buton-box">
          <Link
            className="btn green"
            to={{
              pathname: row.link.split("#")[1],
              state: {
                previousPage: "checklist"
              }
            }}
          >
            Add to Profile
          </Link>
        </div>
      </div>
    </>
  );
};

const CheckListItem = (props) => {
  const comp = [];
  if (props.items != null) {
    const itemName = props.items;
    let count = 0;
    for (const key in itemName) {
      const row = itemName[key];
      const {
        percentage = "",
        label_name = "",
        link = "",
        completed = false,
        id = 0,
      } = row || {};
      if (!completed && count < props.showValue) {
        count++;
        comp.push(listDisplay(row));
      }
    }
  }
  return <>{comp}</>;
};

const VerificationProcess = (props) => {
  const [data, setData] = useState({});
  const history = useHistory()
  useEffect(() => {
    if (props.verificationData) {
      let dataT = {};
      dataT["primaryposition"] = props.verificationData.primaryposition;
      dataT["profileimages"] = props.verificationData.profileimages;
      dataT["verfiedUser"] = props.verificationData.verifieduser;
      dataT["athleteParent"] = props.verificationData.athleteParent;
      dataT["graduationYear"] = props.verificationData.graduationYear;

      setData({ ...dataT });
    }
  }, [props.verificationData]);

  return (
    <>
      <div className="common-box-list verification-block-box">
        <h3 className="title">Verification Process</h3>
        {/*<div className="flex-box">*/}
        {/*  <div className="box detail">*/}
        {/*    <div className="icon-box">*/}
        {/*      <span>*/}
        {/*        <img src={data.verfiedUser ? GreenCheckIcon : callIcon} alt="" />*/}
        {/*      </span>*/}
        {/*    </div>*/}
        {/*    <div className="content-box">*/}
        {/*      <h3>Complete verification call</h3>*/}
        {/*      <p>*/}
        {/*        Schedule a call with a SportsForce team member to verify your*/}
        {/*        identity and basic recruiting information.*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  {data.verfiedUser ? null :*/}
        {/*    <div className="box-buton-box">*/}
        {/*      <button className="btn green" onClick={() => window.open('https://calendly.com/sportsforce/recruiting-assessment-round-robin', '_blank')}>*/}
        {/*        Schedule Call*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*</div>*/}
        <div className="flex-box">
          <div className="box detail">
            <div className="icon-box">
              <span>
                <img
                  src={data.profileimages ? GreenCheckIcon : profilePhotoIcon}
                  alt=""
                />
              </span>
            </div>
            <div className="content-box">
              <h3>Upload your profile photo</h3>
              <p>
                College coaches are 11x more likely to reach out to athletes who
                have a profile photo!
              </p>
            </div>
          </div>
          {data.profileimages ? null : (
            <div className="box-buton-box">
              <Link className="btn green" to="/basic">
                Update now
              </Link>
            </div>
          )}
        </div>
        <div className="flex-box">
          <div className="box detail">
            <div className="icon-box">
              <span>
                <img
                  src={
                    data?.graduationYear?.id ? GreenCheckIcon : gradYearsIcon
                  }
                  alt=""
                />
              </span>
            </div>
            <div className="content-box">
              <h3>Add your grad year</h3>
              <p>
                Grad year is one of the most popular search criteria used by
                college coaches. It’s a small, but important piece of your
                recruiting resume.
              </p>
            </div>
          </div>
          {data?.graduationYear?.id ? null : (
            <div className="box-buton-box">
              <Link className="btn green" to="/basic">
                Update now
              </Link>
            </div>
          )}
        </div>
        <div className="flex-box">
          <div className="box detail">
            <div className="icon-box">
              <span>
                <img
                  src={data.primaryposition ? GreenCheckIcon : PositionIcon}
                />
              </span>
            </div>
            <div className="content-box">
              <h3>Add Your Position</h3>
              <p>
                College coaches search to fill certain spots on their rosters.
                Let coaches know what position you would fill on their team!
              </p>
            </div>
          </div>
          {data.primaryposition ? null : (
            <div className="box-buton-box">
              <Link className="btn green" to="/vitals">
                Update now
              </Link>
            </div>
          )}
        </div>
        <div className="flex-box">
          <div className="box detail">
            <div className="icon-box">
              <span>
                <img
                  src={data?.athleteParent?.id ? GreenCheckIcon : parentIcon}
                />
              </span>
            </div>
            <div className="content-box">
              <h3>Add Parent / Guardian</h3>
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly to demonstrate the visual form of a document
              </p>
            </div>
          </div>
          {data?.athleteParent?.id ? null : (
            <div className="box-buton-box">
              <Link className="btn green" to="/contact">
                Update now
              </Link>
            </div>
          )}
        </div>
        {/* <div className="next-prev-button">
          <button style={{background:"none", border:"none", color:"rgba(62,62,62,.6)"}}
                  type={'button'}
                  onClick={()=>history.push('/basic')}
          >
            Next<ArrowForwardIosRounded fontSize="small"/>
          </button>
        </div> */}
      </div>
    </>
  );
};
const CheckListReview = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    if (props.checkListReviewData) {
      let dataT = {};
      dataT["athletephonenumber"] =
        props.checkListReviewData.athletephonenumber;
      dataT["gpascale"] = props.checkListReviewData.gpascale;
      dataT["heightinfeet"] = props.checkListReviewData.heightinfeet;
      dataT["heightInInches"] = props.checkListReviewData.heightInInches;
      dataT["weight"] = props.checkListReviewData.weight;

      if (
        props.checkListReviewData.athleteParent &&
        props.checkListReviewData.athleteParent
          .athleteparentmappingmappingparentidmaprel &&
        props.checkListReviewData.athleteParent
          .athleteparentmappingmappingparentidmaprel.Athleteparents &&
        props.checkListReviewData.athleteParent
          .athleteparentmappingmappingparentidmaprel.Athleteparents[0]
      ) {
        dataT["athleteParent"] =
          props.checkListReviewData.athleteParent.athleteparentmappingmappingparentidmaprel.Athleteparents[0];
      }
      setData({ ...dataT });
    }
  }, [props.checkListReviewData]);

  return (
    <>
      <div className="common-box-list verification-block-box">
        <h3 className="title">Checklist for Profile Review</h3>
        <div className="flex-box">
          <div className="box detail">
            <div className="icon-box">
              <span>
                <img
                  src={
                    data.athletephonenumber
                      ? GreenCheckIcon
                      : athleteContactIcon
                  }
                  alt=""
                />
              </span>
            </div>
            <div className="content-box">
              <h3>Athlete Contact Info</h3>
            </div>
          </div>
          {data.athletephonenumber ? null : (
            <div className="box-buton-box">
              <Link className="btn green" to="/contact">
                Update now
              </Link>
            </div>
          )}
        </div>
        <div className="flex-box">
          <div className="box detail">
            <div className="icon-box">
              <span>
                <img
                  src={
                    data?.athleteParent?.parentphonenumber
                      ? GreenCheckIcon
                      : parentContactIcon
                  }
                  alt=""
                />
              </span>
            </div>
            <div className="content-box">
              <h3>Parent / Guardian Contact Info</h3>
            </div>
          </div>
          {data?.athleteParent?.parentphonenumber ? null : (
            <div className="box-buton-box">
              <Link className="btn green" to="/contact">
                Update now
              </Link>
            </div>
          )}
        </div>
        <div className="flex-box">
          <div className="box detail">
            <div className="icon-box">
              <span>
                <img
                  src={
                    data.heightinfeet || data.heightininches
                      ? GreenCheckIcon
                      : heightIcon
                  }
                  alt=""
                />
              </span>
            </div>
            <div className="content-box">
              <h3>Height</h3>
            </div>
          </div>
          {data.heightinfeet || data.heightininches ? null : (
            <div className="box-buton-box">
              <Link className="btn green" to="/vitals">
                Update now
              </Link>
            </div>
          )}
        </div>
        <div className="flex-box">
          <div className="box detail">
            <div className="icon-box">
              <span>
                <img src={data.weight ? GreenCheckIcon : WeightIcon} alt="" />
              </span>
            </div>
            <div className="content-box">
              <h3>Weight</h3>
            </div>
          </div>
          {data.weight ? null : (
            <div className="box-buton-box">
              <a className="btn green" href="#/vitals">
                Update now
              </a>
            </div>
          )}
        </div>
        <div className="flex-box">
          <div className="box detail">
            <div className="icon-box">
              <span>
                <img src={data.gpascale ? GreenCheckIcon : gpaIcon} alt="" />
              </span>
            </div>
            <div className="content-box">
              <h3>Add GPA</h3>
            </div>
          </div>
          {data.gpascale ? null : (
            <div className="box-buton-box">
              <a className="btn green" href="#/basic">
                Update now
              </a>
            </div>
          )}
        </div>
        {/* <div className="flex-box">
          <div className="box detail">
            <div className="icon-box">
              <span>
                <img src={ProfileReviewIcon} alt="" />
              </span>
            </div>
            <div className="content-box">
              <h3>Request Profile Review</h3>
            </div>
          </div>
          <div className="box-buton-box">
            <a className="btn green" href="https://calendly.com/sportsforce/recruiting-assessment-round-robin" target="_blank">
              Update now
            </a>
          </div>
        </div> */}
      </div>
    </>
  );
};

function CheckList(props) {
  const { checkListDataPrepare, getFinalPercentage } = checkListPercentage(
    props.allProfileCalculationData.data
  );

  const fakeInput = useRef(null);
  const [hamburger, setHamburger] = useState(true);
  const [showValue, setShowValue] = useState({
    isShowMore: true,
    value: 3,
  });
  const [changeState, setChangeState] = useState({
    contact: props.contact,
    allParentData: props.allParentData,
    onBoardingDetails: props.onBoardingDetails,
    allUserAcademicSchoolList: props.allUserAcademicSchoolList,
    allReferences: props.allReferences,
    latestTestScore: props.latestTestScore,
  });
  const [data, setData] = useState({
    finalItems: {},
    finalPercentage: 0,
    verificationData: {},
    checkListReviewData: {},
  });
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const getAllCheckListData = () => {
    if (!props.allProfileCalculationData.data) {
      props.profileCalculationListStart({
        query: getProfileCalculationListQuery,
      });
    }
    props.checkListDataStart({
      query: getAllCheckListDataQuery,
      variables: {
        obj: {
          id: getUserInfo().id,
        },
      },
    });
  };
  useEffect(() => {
    let timeout = setTimeout(() => {
      getAllCheckListData();
      fakeInput.current.focus();
      fakeInput.current.select();
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    changeState.contact,
    changeState.allParentData,
    changeState.onBoardingDetails,
    changeState.allUserAcademicSchoolList,
    changeState.allReferences,
    changeState.latestTestScore,
  ]);
  useEffect(() => {
    setChangeState({
      contact: props.contact,
      allParentData: props.allParentData,
      onBoardingDetails: props.onBoardingDetails,
      allUserAcademicSchoolList: props.allUserAcademicSchoolList,
      allReferences: props.allReferences,
      latestTestScore: props.latestTestScore,
    });
  }, [
    props.contact.data,
    props.allParentData.data,
    props.onBoardingDetails.data,
    props.allUserAcademicSchoolList.data,
    props.allReferences.data,
    props.latestTestScore.data,
  ]);
  const handleHamburger = () => {
    setHamburger(!hamburger);
  };

  useEffect(() => {
    let handler = setTimeout(() => {
      if (
        props.checkListData &&
        props.checkListData.data &&
        props.checkListData.data.allAthletes &&
        props.checkListData.data.allAthletes.Athletes &&
        props.checkListData.data.allAthletes.Athletes[0]
      ) {
        let athleteData = props.checkListData.data.allAthletes.Athletes[0];
        let checkListDataT = checkListDataPrepare(athleteData);
        let finalPercentage = getFinalPercentage(checkListDataT);

        let verificationDataT = { verifieduser: athleteData.verifieduser };
        let checkListReviewDataT = {
          athletephonenumber: athleteData.athletephonenumber,
        };
        if (
          athleteData.onBoardingUserIdMap &&
          athleteData.onBoardingUserIdMap.Onboardings &&
          athleteData.onBoardingUserIdMap.Onboardings[0]
        ) {
          let onboardingData = athleteData.onBoardingUserIdMap.Onboardings[0];
          verificationDataT["profileimages"] = onboardingData.profileimages;
          verificationDataT["primaryposition"] =
            onboardingData.primaryposition;
          checkListReviewDataT["gpascale"] = onboardingData.gpascale;
          checkListReviewDataT["heightinfeet"] = onboardingData.heightinfeet;
          checkListReviewDataT["heightInInches"] =
            onboardingData.heightInInches;
          checkListReviewDataT["weight"] = onboardingData.weight;
        }
        if (
          athleteData.athleteparentmappingAthleteIdmap &&
          athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings &&
          athleteData.athleteparentmappingAthleteIdmap
            .Athleteparentmappings[0] &&
          athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings[0]
            .id
        ) {
          verificationDataT["athleteParent"] =
            athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings[0];
          checkListReviewDataT["athleteParent"] =
            athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings[0];
        }
        if (
          athleteData.athleteathletegraduationyearmaprel &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears[0] &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears[0]
            .yearvalue
        ) {
          verificationDataT["graduationYear"] =
            athleteData.athleteathletegraduationyearmaprel.Graduationyears[0];
        }
        setData({
          finalItems: { ...checkListDataT },
          finalPercentage: finalPercentage,
          verificationData: { ...verificationDataT },
          checkListReviewData: { ...checkListReviewDataT },
        });
        setLoading(false);
      }
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [props.checkListData]);

  const showCheckReview = () => {
    if (
      data.verificationData &&
      data.verificationData?.graduationYear?.id &&
      data.verificationData?.athleteParent?.id &&
      data.verificationData?.primaryposition &&
      data.verificationData?.profileimages &&
      data.verificationData?.verifieduser
    ) {
      return true;
    }
    return false;
  };
  const showMoreHandler = (e, showMore) => {
    e.preventDefault();
    let count = 3;
    if (!showMore) {
      count = Object.keys(data.finalItems).length;
    } else {
      goTop();
    }
    setShowValue({
      isShowMore: showMore,
      value: count,
    });
  };
  const showMoreLessButton = () => {
    let show = false;
    let count = 0;
    if (data.finalItems != null) {
      for (const key in data.finalItems) {
        const row = data.finalItems[key];
        const {
          percentage = "",
          label_name = "",
          link = "",
          completed = false,
        } = row || {};
        if (!completed) {
          count++;
        }
      }
      if (count >= 3) {
        show = true;
      }
    }
    return { show, count };
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  const routeBackToProfile = () => {
    props.history.push(
        {pathname: `${getAthleteSysID()}`,
          state: {isPublic : false}
        }
        // '/profile/1'
    )
    sessionStorage.setItem('athlete_own_profile', true)
  }

  return (
    <div>
      <div className="pageContent">
        <div className="editProfile">
          <input className="fakeInput" type="text" ref={fakeInput} />
          {/* <BackdropLoader open={loading} open={props.checkListData.loading} /> */}
          <BackdropLoader open={loading} /> {/* This is change to fix the bug SPOR-411 */}
          <div className="container">
            <div
              className={hamburger == true ? "flex" : "flex inactive"}
              id="wrapper"
            >
              <div className="flex" id="wrapper">
                <SubHeader hamburger={handleHamburger} buttonHide={false} />

                <div className="sidebar">
                  <LeftSideEditProfile />
                </div>
                <div className="editSection">
                  <div className="flexRow">
                    <div className="title">Checklist</div>
                    <div className="btnWrap">
                      <button
                        className="btn outlined green rounded medium backprofileBtn"
                        onClick={() => routeBackToProfile()}
                      >
                        Back To Profile
                      </button>
                      <button className="btn outlined green rounded medium" onClick={() => { routeBackToProfile() }}>
                        Preview Profile
                      </button>
                      <button className="btn outlined blue rounded medium"
                        onClick={() => {
                          sessionStorage.setItem('athlete_own_profile', false)
                          setTimeout(() => {
                            window.open(`${websiteDefaultUrl}athlete/wyatt-johnson-74144936`, "_blank")
                          }, 500)
                        }
                        }
                      >
                        Sample Profile
                      </button>
                    </div>
                  </div>
                  <div className="check-list-sec">
                    <div className="profileCompletion">
                      <small>Strengthen Your Profile</small>
                      <div className="progressbar">
                        <div
                          className="progress"
                          style={{ width: `${data.finalPercentage}%` }}
                        >{`${data.finalPercentage}%`}</div>
                      </div>
                    </div>
                    <div className="strengthen-profile-box">
                      <div className="common-box-list strengthen-listing">
                        {
                          <CheckListItem
                            items={data.finalItems}
                            showValue={showValue.value}
                          />
                        }
                        {showMoreLessButton().show ? showMoreLessButton().count > 3 ? (
                          <>
                            {showValue.isShowMore ? (
                              <div className="show-less-link">
                                <a
                                  href={void 0}
                                  className="showMore"
                                  onClick={(e) => {
                                    showMoreHandler(e, false);
                                  }}
                                ></a>
                              </div>
                            ) : null}
                            {!showValue.isShowMore ? (
                              <div className="show-less-link show">
                                <a
                                  href="#"
                                  className="showMore showLess"
                                  onClick={(e) => {
                                    showMoreHandler(e, true);
                                  }}
                                ></a>
                              </div>
                            ) : null}
                          </>
                        ) : "" : (
                          ""
                        )}
                      </div>
                      {/*<div className="common-box-list request-block-box">*/}
                      {/*  <h3 className="title">Profile Requests</h3>*/}
                      {/*  <div className="flex-box">*/}
                      {/*    <div className="box detail">*/}
                      {/*      <div className="icon-box">*/}
                      {/*        <svg*/}
                      {/*          xmlns="http://www.w3.org/2000/svg"*/}
                      {/*          width="18.53"*/}
                      {/*          height="21.962"*/}
                      {/*          viewBox="0 0 18.53 21.962"*/}
                      {/*        >*/}
                      {/*          <defs>*/}
                      {/*            <style>*/}
                      {/*              {`.a {*/}
                      {/*          fill: #fff;*/}
                      {/*        }`}*/}
                      {/*            </style>*/}
                      {/*          </defs>*/}
                      {/*          <path*/}
                      {/*            className="a"*/}
                      {/*            d="M19.785,3.071A1.071,1.071,0,0,0,18.708,2H5.078A1.071,1.071,0,0,0,4,3.071V20.146a1.071,1.071,0,0,0,1.078,1.071h.357V3.421H19.785Z"*/}
                      {/*            transform="translate(-4 -2)"*/}
                      {/*          />*/}
                      {/*          <path*/}
                      {/*            className="a"*/}
                      {/*            d="M9.029,6H22.756a1.029,1.029,0,0,1,1.029,1.029V24.187a1.029,1.029,0,0,1-1.029,1.029H9.029A1.029,1.029,0,0,1,8,24.187V7.029A1.029,1.029,0,0,1,9.029,6Z"*/}
                      {/*            transform="translate(-5.255 -3.255)"*/}
                      {/*          />*/}
                      {/*        </svg>*/}
                      {/*      </div>*/}
                      {/*      <div className="content-box">*/}
                      {/*        <h3>Transcript</h3>*/}
                      {/*        <p>*/}
                      {/*          Drew Brink wants access for Transcript Section*/}
                      {/*        </p>*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*    <div className="box-buton-box">*/}
                      {/*      <a className="btn green"  href={void 0}>*/}
                      {/*        Accept*/}
                      {/*      </a>*/}
                      {/*      <a className="btn"  href={void 0}>*/}
                      {/*        Decline*/}
                      {/*      </a>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*  <div className="flex-box">*/}
                      {/*    <div className="box detail">*/}
                      {/*      <div className="icon-box">*/}
                      {/*        <svg*/}
                      {/*          xmlns="http://www.w3.org/2000/svg"*/}
                      {/*          width="21.547"*/}
                      {/*          height="25.098"*/}
                      {/*          viewBox="0 0 21.547 25.098"*/}
                      {/*        >*/}
                      {/*          <defs>*/}
                      {/*            <style>*/}
                      {/*              {`  .a {*/}
                      {/*          fill: #fff;*/}
                      {/*        }`}*/}
                      {/*            </style>*/}
                      {/*          </defs>*/}
                      {/*          <path*/}
                      {/*            className="a"*/}
                      {/*            d="M84.9,83.142a5.323,5.323,0,0,0-2.209-1.02l-3.931-.789a.887.887,0,0,1-.71-.878v-.9a10.773,10.773,0,0,0,.74-1.319,7.244,7.244,0,0,1,.631-1.106,5.861,5.861,0,0,0,1.8-2.856,2.754,2.754,0,0,0-.253-2.115,15.881,15.881,0,0,0-.307-3.623,3.955,3.955,0,0,0-.934-2.9,2.772,2.772,0,0,0-1.665-.7,2.91,2.91,0,0,1-.611-.143A6.326,6.326,0,0,0,74.428,64a7.079,7.079,0,0,0-6.235,4.277,10.175,10.175,0,0,0-.246,3.23l-.023.647a2.871,2.871,0,0,0-.235,2.115,5.85,5.85,0,0,0,1.816,2.87,7.869,7.869,0,0,1,.629,1.1,11.141,11.141,0,0,0,.746,1.314v.9a.891.891,0,0,1-.713.878l-3.935.789a5.335,5.335,0,0,0-2.2,1.018.9.9,0,0,0-.106,1.305,14.272,14.272,0,0,0,21.082,0,.9.9,0,0,0-.106-1.305Z"*/}
                      {/*            transform="translate(-63.693 -64)"*/}
                      {/*          />*/}
                      {/*        </svg>*/}
                      {/*      </div>*/}
                      {/*      <div className="content-box">*/}
                      {/*        <h3>Profile</h3>*/}
                      {/*        <p>*/}
                      {/*          Drew Brink wants access for Transcript Section*/}
                      {/*        </p>*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*    <div className="box-buton-box">*/}
                      {/*      <a className="btn green" href={void 0}>*/}
                      {/*        Accept*/}
                      {/*      </a>*/}
                      {/*      <a className="btn" href={void 0}>*/}
                      {/*        Decline*/}
                      {/*      </a>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*  <div className="flex-box">*/}
                      {/*    <div className="box detail">*/}
                      {/*      <div className="icon-box">*/}
                      {/*        <img src={IntroductionIcon} />*/}
                      {/*      </div>*/}
                      {/*      <div className="content-box">*/}
                      {/*        <h3>Introduction</h3>*/}
                      {/*        <p>*/}
                      {/*          Drew Brink Wants access for Introduction Section*/}
                      {/*        </p>*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*    <div className="box-buton-box">*/}
                      {/*      <a className="btn green" href={void 0}>*/}
                      {/*        Accept*/}
                      {/*      </a>*/}
                      {/*      <a className="btn" href={void 0}>*/}
                      {/*        Decline*/}
                      {/*      </a>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*  <div className="flex-box">*/}
                      {/*    <div className="box detail">*/}
                      {/*      <div className="icon-box">*/}
                      {/*        <svg*/}
                      {/*          xmlns="http://www.w3.org/2000/svg"*/}
                      {/*          width="23.918"*/}
                      {/*          height="15.276"*/}
                      {/*          viewBox="0 0 23.918 15.276"*/}
                      {/*        >*/}
                      {/*          <defs>*/}
                      {/*            <style>*/}
                      {/*              {` .a {*/}
                      {/*          fill: #fff;*/}
                      {/*        }`}*/}
                      {/*            </style>*/}
                      {/*          </defs>*/}
                      {/*          <g transform="translate(8851.167 20311.82)">*/}
                      {/*            <path*/}
                      {/*              className="a"*/}
                      {/*              d="M22.134,9.682A2.3,2.3,0,0,0,19.743,7.5H5.392A2.3,2.3,0,0,0,3,9.682V20.594a2.3,2.3,0,0,0,2.392,2.182H19.743a2.3,2.3,0,0,0,2.392-2.182V16.957l4.784,3.637V9.682l-4.784,3.637ZM20.542,21.321H4.595V8.955H20.54l0,6.182h0l0,6.182Z"*/}
                      {/*              transform="translate(-8854.167 -20319.32)"*/}
                      {/*            />*/}
                      {/*            <path*/}
                      {/*              className="a"*/}
                      {/*              d="M15.333,10.931a.292.292,0,0,0-.152-.256L8.747,6.962a.293.293,0,0,0-.46.24.328.328,0,0,0,0,.033h0v7.459h0a.289.289,0,0,0,.435.219l0,0,6.46-3.729,0,0a.292.292,0,0,0,.147-.252Z"*/}
                      {/*              transform="translate(-8853.389 -20315.113)"*/}
                      {/*            />*/}
                      {/*          </g>*/}
                      {/*        </svg>*/}
                      {/*      </div>*/}
                      {/*      <div className="content-box">*/}
                      {/*        <h3>Video</h3>*/}
                      {/*        <p>Drew Brink Wants access for Video Section</p>*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*    <div className="box-buton-box">*/}
                      {/*      <a className="btn green" href={void 0}>*/}
                      {/*        Accept*/}
                      {/*      </a>*/}
                      {/*      <a className="btn" href={void 0}>*/}
                      {/*        Decline*/}
                      {/*      </a>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      {!showCheckReview() ? (
                        <VerificationProcess
                          verificationData={data.verificationData}
                        />
                      ) : (
                        ""
                      )}
                      {showCheckReview() ? (
                        <CheckListReview
                          checkListReviewData={data.checkListReviewData}
                        />
                      ) : null}
                    </div>
                    <div className="next-prev-button">
                      <button style={{ background: "none", border: "none", color: "rgba(62,62,62,.6)" }}
                        type={'button'}
                        onClick={() => history.push('/basic')}
                      >
                        Next<ArrowForwardIosRounded fontSize="small" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <GoTopButton goTop={goTop} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    checkListData: state.common.checkListData,

    //states if changed fetch data
    allProfileCalculationData: state.common.profileCalculationData,
    contact: state.athlete.editUserProfileData.contactUserProfile,
    onBoardingDetails: state.athlete.onBoarding,
    allParentData: state.athlete.editUserProfileData.parentList,
    allUserAcademicSchoolList:
      state.athlete.editUserProfileData.userAcademicSchool,
    allReferences: state.athlete.editUserProfileData.allReferences,
    latestTestScore: state.athlete.editUserProfileData.testScores,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    checkListDataStart: (data) => dispatch(allCheckListDataStart(data)),
    profileCalculationListStart: (data) =>
      dispatch(allProfileCalculationListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(CheckList));
