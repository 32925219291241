import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SCOUTING_REQUEST_START: "SCOUTING_REQUEST_START",
  SCOUTING_REQUEST_SUCCESS: "SCOUTING_REQUEST_SUCCESS",
  SCOUTING_REQUEST_FAILED: "SCOUTING_REQUEST_FAILED",
};

// ============================================ Actions ========================================================

export const scoutingRequestStart = (data) => ({
  type: actionType.SCOUTING_REQUEST_START,
  payload: data,
});

export const scoutingRequestSuccess = (data) => ({
  type: actionType.SCOUTING_REQUEST_SUCCESS,
  payload: data,
});

export const scoutingRequestFailed = (data) => ({
  type: actionType.SCOUTING_REQUEST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function scoutingRequestReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SCOUTING_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SCOUTING_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SCOUTING_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* scoutingRequestSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Statmatrixdetails/scoutingRequest`,
      action.payload.data,
      "post",
      true
    );
    if (response && response.status === 200) {
      if (response.data.response.status === 200) {
        messagePopup("", action.payload.successMsg, "success");
        yield put(scoutingRequestSuccess(response.data.response.msg));
        // action.payload.openScoutingRequestPopup();
      } else {
        messagePopup("", response.data.response.msg, "error");
        yield put(scoutingRequestFailed(response.data.response.msg));
      }
    } else {
      messagePopup("", "Verify Stats Failed", "error");
      yield put(scoutingRequestFailed("Verify Stats Failed"));
    }
  } catch (error) {
    messagePopup("", "Verify Stats Failed", "error");
    yield put(scoutingRequestFailed("Verify Stats Failed"));
  }
}

export function* watchScoutingRequestSaga() {
  yield takeEvery(actionType.SCOUTING_REQUEST_START, scoutingRequestSaga);
}
