import {combineReducers} from 'redux'
import composeMessageListReducer from './composeMessage';
import coachDropdownReducer from './coachDropdown';
import athleteParentDropdownReducer from './athleteParentDropdown';
import getComposeMessageByIdReducer from "./getComposeHomeMessage";

export default combineReducers({
    composeMessageList: composeMessageListReducer,
    coachDropdown: coachDropdownReducer,
    athleteParentDropdown: athleteParentDropdownReducer,
    getUniversityById: getComposeMessageByIdReducer
})