import { React, useEffect, useState, Fragment, useRef } from "react";
import "../assets/css/account.scss";
import LeftNavigation from "./LeftNavigation";
import {connect, useDispatch} from "react-redux";
import history from "../../../../../../services/history";
import { membershipListStart } from "../../../../../../redux/athlete/dashboard/account/membership";
import { getAllMembershipsQuery } from "./membershipQuery";
import {getAuthToken, getUserInfo} from "../../../../../../services/authService";
import BackdropLoader from "../../../../../common/Loader";
import { getColonFormatedDate } from "../../../../../../utils/dateTimeFormatter";
import moment from "moment";
import {Link} from "react-router-dom";
import {fetchGraphMethod, fetchMethod} from "../../../../../../services/httpService";
import toastr from 'toastr'
import {allParentQuery} from "../../editUserProfile/ContactQuery";
import axios from "axios";
import {API_URL, zapierApiCall} from "../../../../../../config";
const AccountData = ({ AllMembershipListData }) => {
  const dollarUSLocale = Intl.NumberFormat('en-US');
  const dollarUSLocaleDec = Intl.NumberFormat('en-US',{maximumFractionDigits:2, minimumFractionDigits:2})
  const handleUpgradePlan = (e) => {
    history.push("/programAndPricing");
  };
  const dispatch = useDispatch()
  const diffDay = (value) => {
    const diffDate = moment(value),
        currDate = moment(new Date());
    let diffResult = `${currDate.diff(diffDate,'days')} days`
    return diffResult
  }
  const getValueDec = (value) =>{
    const valueSplit = value?.toString()?.includes(".")
    if (valueSplit){
      return dollarUSLocaleDec.format(value)
    }else{
      return dollarUSLocale.format(value)
    }
  }
  return (
    <div className="account-data-box">
      <h1 className="title">Membership</h1>
      {AllMembershipListData && AllMembershipListData.length !== 0
        ? AllMembershipListData.map((memberships, index) => (
            <Fragment key={index}>
              {memberships.membershipsubscriptionplanidmaprel &&
              memberships.membershipsubscriptionplanidmaprel.Subscriptionplans
                ? memberships.membershipsubscriptionplanidmaprel.Subscriptionplans.map(
                    (plans, index) => (
                        <>
                          <div className="membership-common-box">
                            <div className="membershipInfo">
                              <div className="membershipInfo-Detail">
                                <h4 className="plan-title">{plans.planname}
                                &nbsp;-&nbsp;<span style={{fontWeight:"bolder"}}>
                                    {/*<span className={'plan-title-sub'}>*/}
                                      {memberships?.membershipsubscriptionplantypemaprel?.Subscriptionplantypes?.[0]?.plantype ?? "NA"}
                                      {/*&nbsp;{memberships?.isUE == 1 ? "(Unlimited Video Editing)" : ""}*/}
                                    {/*</span>*/}
                                </span>
                                </h4>
                                <div className="membershipInfo-list">
                                  <div className="item">
                                    <p><span>Total Investment</span>
                                      {/*<span>*/}
                                        {memberships?.issubscriptioncancelled == 0 ?
                                          ( !!memberships?.totalinvestment ? `$${getValueDec(memberships?.totalinvestment)}`  :  "NA")  : "NA"}
                                      {/*</span>*/}
                                      </p>
                                  </div>
                                  <div className="item">
                                    <p><span>Start date </span>
                                      {memberships.planstartdate
                                          ? memberships.planstartdate.split("-")[1]
                                          : ""}
                                      {"-"}
                                      {memberships.planstartdate
                                          ? memberships.planstartdate.split("-")[2]
                                          : ""}
                                      {"-"}
                                      {memberships.planstartdate
                                          ? memberships.planstartdate.split("-")[0]
                                          : ""}
                                    </p>
                                  </div>
                                  <div className="item">
                                    <p><span>Total installment </span>
                                      {memberships?.issubscriptioncancelled == 0 ? (!!memberships?.totalinstallments ?
                                          memberships?.totalinstallments : "NA") : "NA"} </p>
                                  </div>
                                  <div className="item">
                                    <p><span>Installment paid</span>
                                      {!!memberships?.installmentnumber ? memberships?.installmentnumber : "NA"}
                                    </p>
                                  </div>
                                  <div className="item">
                                    <p><span>Installment remaining </span>
                                      {memberships?.issubscriptioncancelled == 0 ? (!!memberships?.installmentremaining ?
                                          memberships?.installmentremaining : "NA") : "NA"}
                                    </p>
                                  </div>
                                  <div className="item">
                                    <p><span>Next installment due date </span>
                                      {!!memberships?.planrenewaldate ? moment(memberships?.planrenewaldate).format("MM-DD-YYYY") : "NA"}
                                    </p>
                                  </div>
                                  <div className="item">
                                    <p><span>Next installment amount</span>
                                      {memberships?.issubscriptioncancelled == 0 ? `${!!memberships?.nextinstallmentamount ? 
                                          "$"+getValueDec(memberships?.nextinstallmentamount) : "NA"}` : "NA"}
                                    </p>
                                  </div>
                                  <div className="item">
                                    <p><span>Remaining amount to be paid</span>
                                      {memberships?.issubscriptioncancelled == 0 ?
                                          `${!!memberships?.remainingamount  ? ( memberships?.remainingamount?.includes('.') 
                                              ? "$" + getValueDec(Number(memberships?.remainingamount ?? 0)?.toFixed(2))
                                              : "$" + memberships?.remainingamount ) : "NA" }` : "NA"}
                                    </p>
                                  </div>
                                </div>
                                <div className="plan-benefit">
                                  <h4>Plan Benefits:</h4>
                                  <ul>
                                    {plans.subscriptionplanincludessubscriptionplanidMap &&
                                    plans
                                        .subscriptionplanincludessubscriptionplanidMap
                                        .Subscriptionplanincludes
                                        ? plans.subscriptionplanincludessubscriptionplanidMap.Subscriptionplanincludes.map(
                                            (planincludes, index) => (
                                                <li key={index}>
                                                  {planincludes.includeplanname}
                                                </li>
                                            )
                                        )
                                        : ""}
                                  </ul>
                                </div>
                              </div>
                              <div className="membershipInfo-buttons">
                                <Link
                                    // type="button"
                                    className="btn green"
                                    to={'/programAndPricingDetail'}
                                        onClick={(e) => {
                                          dispatch({ type: "RENDER_COUNT_START", payload: 1 })
                                          // history?.push('/programAndPricingDetail')
                                          // dispatch({ type: "RENDER_COUNT_START", payload: 1 })
                                        }}
                                >Upgrade Plan</Link>
                                <p className={'text-center'}>
                                  <a
                                      style={( getUserInfo()?.isadmin == 1 ) ? {opacity:"0.7"} : {}}
                                      className="update-link-btn"
                                      href={( getUserInfo()?.isadmin == 1 ) ?  void 0 :
                                          process?.env?.REACT_APP_STRIPE_SECRET_KEY
                                      }
                                      target={'_blank'}
                                  >Update Card Details</a>
                                </p>
                              </div>
                            </div>
                          </div>
                          {/*<div className="plan-message-successfull">*/}
                          {/*  <div className="checkIcon">*/}
                          {/*    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"*/}
                          {/*         xmlns="http://www.w3.org/2000/svg">*/}
                          {/*      <path*/}
                          {/*          d="M15 2.5C8.1125 2.5 2.5 8.1125 2.5 15C2.5 21.8875 8.1125 27.5 15 27.5C21.8875 27.5 27.5 21.8875 27.5 15C27.5 8.1125 21.8875 2.5 15 2.5ZM20.975 12.125L13.8875 19.2125C13.7125 19.3875 13.475 19.4875 13.225 19.4875C12.975 19.4875 12.7375 19.3875 12.5625 19.2125L9.025 15.675C8.6625 15.3125 8.6625 14.7125 9.025 14.35C9.3875 13.9875 9.9875 13.9875 10.35 14.35L13.225 17.225L19.65 10.8C20.0125 10.4375 20.6125 10.4375 20.975 10.8C21.3375 11.1625 21.3375 11.75 20.975 12.125Z"*/}
                          {/*          fill="#fff"/>*/}
                          {/*    </svg>*/}
                          {/*  </div>*/}
                          {/*  <div className="text">Plan purchased successfully</div>*/}
                          {/*  <button className="closeBtn">*/}
                          {/*    <span aria-hidden="true">×</span>*/}
                          {/*  </button>*/}
                          {/*</div>*/}
                    </>
                      )
                  )
                : ""}
            </Fragment>
          ))
        : <>
            <div className="membership-common-box">
              {/*<p><strong>You have not purchased any plan yet!</strong></p>*/}
              <Link className="btn green"
                    onClick={()=>dispatch({ type: "RENDER_COUNT_START", payload: 1 })}
                    to="/programAndPricingDetail">View Plans</Link>
            </div>
          </>
      }
    </div>
  );
};

const Membership = (props) => {
  const fakeInput = useRef(null);
  useEffect(() => {
    getStripeInfo()
    goTop();
  }, []);
  const getStripeInfo = async () =>{
    const search = props?.location?.search
    if (!!search && search?.includes('session_id')){
      const stripeSessionID = search?.split('=')?.[1]
      if (!!stripeSessionID){
        const body = {checkoutSessionId : stripeSessionID}
        const _resStripe = await fetchMethod('/api/Payments/afterPaymentRedirection', body, 'POST',true)
        if (_resStripe?.data?.response?.status == 201){
          toastr?.success(!!_resStripe?.data?.response?.message ? _resStripe?.data?.response?.message : "Plan Purchased",
             '', {
                progressBar : true,timeOut: 2000,
                closeButton: true, positionClass: "toast-top-full-width",
                showEasing: "swing", fadeIn: 40000,
                preventDuplicates: true,
              })
          if (_resStripe?.status == 200 && _resStripe?.data?.response?.isDataSentOnZapier == 0){
            const resPar = await fetchGraphMethod(allParentQuery,
                {obj: {athleteid: getUserInfo() ? getUserInfo().id : 0, active: 1,
                  },
                }, true)
            if (resPar?.status == 200){
              const zapBody = {
                "Athletefirstname": getUserInfo()?.FirstName ?? "",
                "Athletelastname": getUserInfo()?.lastName ?? "",
                "Athleteemail": getUserInfo()?.Email ?? "",
                "Parent/guardiaemail": resPar?.data?.data?.allAthleteparentmappings?.Athleteparentmappings?.length > 0 ?
                    resPar?.data?.data?.allAthleteparentmappings?.Athleteparentmappings?.[0]?.
                    athleteparentmappingmappingparentidmaprel?.Athleteparents?.[0]?.parentemail : "",
                "PrimaryParent/guardianfirstname":resPar?.data?.data?.allAthleteparentmappings?.Athleteparentmappings?.length > 0 ?
                    resPar?.data?.data?.allAthleteparentmappings?.Athleteparentmappings?.[0]?.
                        athleteparentmappingmappingparentidmaprel?.Athleteparents?.[0]?.parentfirstname : "",
                "PrimaryParent/guardianlastname":resPar?.data?.data?.allAthleteparentmappings?.Athleteparentmappings?.length > 0 ?
                    resPar?.data?.data?.allAthleteparentmappings?.Athleteparentmappings?.[0]?.
                        athleteparentmappingmappingparentidmaprel?.Athleteparents?.[0]?.parentlastname : "",
                "HSgradyear": getUserInfo()?.graduationyearValue?.toString() ?? "",
                "sport": getUserInfo()?.athleteSportName ?? "",
                "Advisor":"",
                "Name":"",
                "Referralpartner":"",
                "Frontendadvisor":"",
                "Leadsource":"",
                "Appointmentsetter":""
              }
              const result = await axios({
                url: zapierApiCall,
                method: 'POST',
                data: zapBody,
                headers: {"Content-Type": "text/plain"},
              })
            }
          }
          setTimeout(()=>{
            props?.history?.push('/membership')
            getAllMembershipData();
          },1000)
        }else{
          toastr?.error(!!_resStripe?.data?.response?.message ? _resStripe?.data?.response?.message : "In Process",
             '', {
                progressBar : true,timeOut: 2000,
                closeButton: true, positionClass: "toast-top-full-width",
                showEasing: "swing", fadeIn: 40000,
                preventDuplicates: true,
              }
              )
          setTimeout(()=>{
            props?.history?.push('/membership')
            getAllMembershipData();
          },1000)
        }
      }else{
        toastr.error('Failed To Process','',{
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      }
    }else{
      getAllMembershipData();
    }
  }
  const getAllMembershipData = () => {
    props.membershipListStart({
      query: getAllMembershipsQuery,
      variables: {
        where: {
          userid: getUserInfo() ? getUserInfo().id : "",
          active: 1
        },
      },
    });
  };

  const AllMembershipListData =
    props.allMemberships &&
    props.allMemberships.data &&
    props.allMemberships.data.allMemberships
      ? props.allMemberships.data.allMemberships
      : null;

    const goTop = () => {
        fakeInput.current.focus();
        fakeInput.current.select();
      };
//this is used for scrolling the page on top
    useEffect(()=>{
  window.scrollTo(0,0)
},[])
  return (
    <div>
      <section className="account-main-sec">
        <BackdropLoader open={props.allMemberships.loading} />
        <div className="container">
        <input className="fakeInput" type="text" ref={fakeInput} />
          <div className="account-with-sidebar">
            <LeftNavigation />
            <AccountData AllMembershipListData={AllMembershipListData} />
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allMemberships: state.athlete.account.allMemberships,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    membershipListStart: (data) => dispatch(membershipListStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Membership);
