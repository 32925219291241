import React, { useEffect } from "react";
import { useState } from "react";
import "./css/notification.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem, IconButton, Button } from "@mui/material";
import { getMessageQuery, saveMessageApi} from "./MessageQuery";
import moment from "moment";
import { messageListStart, saveMessageListStart } from "../../../../../redux/athlete/dashboard/message/message";
import { messageCountListStart } from '../../../../../redux/athlete/dashboard/message/messageCount';
import { connect } from "react-redux";
import {imageDownloadUrl} from '../../../../../config';
import closeGrayIcon from "./img/close-gray-icon.svg";
import trashCircleIcon from "./img/trash-circle-icon.svg";
import Dialog from "@mui/material/Dialog";
import messagePopup from "../../../../../utils/messagePopup";
import {
  NavigateBefore,
  NavigateNext
} from "@mui/icons-material";

const ITEM_HEIGHT = 48;

const Pagination = ({
  dataPerPage,
  totalData,
  paginate,
  initialPage,
  dataRange
}) => {
  const rows = parseInt(localStorage.getItem("rows"));
  const [defaultRows, setRows] = useState(rows);
  const [pageNumber, setPageNumber] = useState(initialPage);
  const pageNumbers = [];
  const NumberOfPages = totalData / defaultRows;

  for (let i = 1; i <= Math.ceil(NumberOfPages); i++) {
    pageNumbers.push(i);
  }

  const handlePageNumber = number => {
    setPageNumber(number);
    paginate(number * rows, defaultRows);
  };

  return (
    <div className="PaginationFlexWrap">
      <div className="PaginationNumberText">
        <span>
          {dataRange.first} - {dataRange.last}
        </span>
         of <span> {totalData}</span>
      </div>
      <nav className="paginationNav">
        <ul className="pagination">
          <li className="page-item">
            <span
              className="page-link"
              aria-label="Previous"
              onClick={() =>
                pageNumber !== 1 ? handlePageNumber(pageNumber - 1) : ""
              }
            >
              <NavigateBefore />
            </span>
          </li>
          <li className="page-item">
            <span
              className="page-link"
              aria-label="Next"
              onClick={() =>
                pageNumber < NumberOfPages
                  ? handlePageNumber(pageNumber + 1)
                  : ""
              }
            >
              <NavigateNext />
            </span>
          </li>
        </ul>
      </nav>
      </div>
  );
}

const SupportMessage = props => {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const openMenu = Boolean(anchorE2),
    [perPage, setPerPage] = useState(20),
    [filter, ] = useState({
        order: 'id desc',
        //or: [{messagesendto:parseFloat(JSON.parse(localStorage.getItem("user")).id)}, {messagesendby:parseFloat(JSON.parse(localStorage.getItem("user")).id)}],
        and: [{or:[{messagesendto:parseFloat(JSON.parse(localStorage.getItem("user")).id)}, {messagesendby:parseFloat(JSON.parse(localStorage.getItem("user")).id)}]},{or:[{messagesendbyadmin:{neq:null}},{messagesendtoadmin:{neq:null}}]}],
        active: 1, 
        istrash: 1,
        userrole: 'ADMIN'
    }),
    [offset, setOffSet] = useState(20),
    [id, setId] = useState(null),
    [showDeleteModal, setShowDeleteModal] = useState(false),
    [count, setCount] = useState(0),
    [coachCount, setCoachCount] = useState(""),
    [advisorCount, setAdvisorCount] = useState(""),
    [supportData, setSupportData] = useState([]),
    [supportCount, setSupportCount] = useState(""),
    [ischecked, setIsChecked] = useState('20');
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClose = value => {
    setAnchorEl(null);
    setIsChecked(value);
    setPerPage(parseFloat(value));
    setOffSet(parseFloat(value))
  }
  const openComposeMenu = event => {
    setAnchorE2(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorE2(null);
  };
  const handleCoachMenu = () => {
    setAnchorE2(null);
    history.push({ pathname: "/message/coach/composeMessage" });
  };
  const handleAdvisorMenu = () => {
    setAnchorE2(null);
    history.push({ pathname: "/message/advisor/composeMessage" });
  }
  const handleSupportMenu = () => {
    setAnchorE2(null);
    history.push({ pathname: "/message/support/composeMessage" });
  }
  const formatDateTime = newDate => {
      let dateTime = null;
      if(moment(newDate).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')){
        dateTime = moment(newDate).format('LT');
      }
      else{
        dateTime = moment(newDate).format('DD/MM/YYYY');
      }
      return dateTime;
  }
  const messageReadData = item => {
    if(item.isread === 0){
    props.saveMessageListStart({
        query: saveMessageApi,
        variables: {
          obj: {
            id: item.id,
            isread: 1    
        }
        },
        nextScreen: ()=>{
            history.push({ pathname: "/message/support/replyMessage", state: {data: item} });
        }
      });
    }
    else{
        history.push({ pathname: "/message/support/replyMessage", state: {data: item} });
    }
  }
  const onDeleteClick = () => {
      setShowDeleteModal(!showDeleteModal)
  }
  const DeleteItem = ({ onDeleteClick, onCancelClick }) => {
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img src={trashCircleIcon} alt="trash Circle Icon" />
              </div>
              <div className="content-box">
                <h4>
                  Are you sure you want to
                  <br /> delete this message?
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onDeleteClick}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn gray"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const onDeleteHandler = () => {
    props.saveMessageListStart({
        query: saveMessageApi,
        variables: {
          obj: {
            id: id,
            active: 0,  
        }
        },
        nextScreen: ()=>{
            messagePopup("", "Message deleted successfully", "success")
            getMessageData(perPage, offset);
        }
      });
      setShowDeleteModal(!showDeleteModal)
  }
  const deleteMessageData = (e, item) => {
      e.stopPropagation();
      setShowDeleteModal(!showDeleteModal);
      setId(item.id)
  }

  useEffect(() => {
    getMessageData(perPage, offset)
  }, []);

  const getMessageData = (first, last) =>{
    props.messageListStart({
        query: getMessageQuery,
        variables: {
          where: filter,
          first: first,
          limit: last
        }
      });
    props.messageCountListStart({
        query: getMessageQuery,
        variables: {
          where:{
                order: 'id desc',
                messagesendto: parseFloat(JSON.parse(localStorage.getItem("user")).id),
                active: 1, 
                istrash: 1,
            },
          first: first,
          limit: last
        }
      });  
  }

  useEffect(() => {
    let supportData = [];
    let data = props?.messageList?.data?.data?.length > 0 ? props?.messageList?.data?.data?.map(item=>{
      item['senderId'] = item?.messagemessagesendbyadminmaprel?.Userdata?.length > 0 ? item?.messagemessagesendbyadminmaprel?.Userdata[0]?.id : "";
      item['senderEmail'] = item?.messagemessagesendbyadminmaprel?.Userdata?.length > 0 ? item?.messagemessagesendbyadminmaprel?.Userdata[0]?.email : "";
      item['senderName'] = item?.messagemessagesendbyadminmaprel?.Userdata?.length > 0 ? item?.messagemessagesendbyadminmaprel?.Userdata[0]?.userfullname : "";
      // item['senderProfileImage'] = item?.messagemessagesendbyadminmaprel?.Userdata?.length > 0 && item?.messagemessagesendbyadminmaprel?.Userdata[0]?.athleteUserIdMap?.Athletes?.length > 0 && item?.messagemessagesendbymaprel?.Userdata[0]?.athleteUserIdMap?.Athletes[0]?.onBoardingUserIdMap?.Onboardings?.length > 0 ? item?.messagemessagesendbymaprel?.Userdata[0]?.athleteUserIdMap?.Athletes[0]?.onBoardingUserIdMap?.Onboardings[0]?.profileimages : "";
      item['dateTime'] = item.createdon ? formatDateTime(item.createdon) : "";
      let firstname = item?.messagemessagesendbyadminmaprel?.Userdata?.length > 0 && item?.messagemessagesendbyadminmaprel?.Userdata[0]?.userfullname ? item?.messagemessagesendbyadminmaprel?.Userdata[0]?.userfullname[0].split(/\s/)[0].slice(0, 1) : '';
      let lastname = item?.messagemessagesendbyadminmaprel?.Userdata?.length > 0 && item?.messagemessagesendbyadminmaprel?.Userdata[0]?.userfullname ? item?.messagemessagesendbyadminmaprel?.Userdata[0]?.userfullname.split(/\s/)[1].slice(0, 1) : '';
      item['senderInitials'] = firstname && lastname ? firstname[0] + lastname[0] : '';
      supportData.push(item);
    }) : [];
    setSupportData(supportData);
    setCount(supportData.length)
 }, [props.messageList.data]);

  useEffect(()=>{
    let coachData = [];
    let supportData = [];
    let advisorData = [];
    let data = props?.messageCountList?.data?.data?.length > 0 ? props?.messageCountList?.data?.data?.map(item=>{
      if(item.userrole === 'ADVISORS'){
        advisorData.push(item);
    }
    else if(item.userrole === 'ADMIN'){
        supportData.push(item)
    }
    else if(item.userrole === 'COLLEGECOUCHES'){
        coachData.push(item);
    }
    }) : [];
    setCoachCount(coachData && coachData.length ? coachData.filter(item => item.isread === 0).length : "");
    setAdvisorCount(advisorData && advisorData.length ? advisorData.filter(item => item.isread === 0).length : "");
    setSupportCount(supportData && supportData.length ? supportData.filter(item => item.isread === 0).length : "");
  },[props.messageCountList.data]);

const handlePagination = async (pageNumber, size) => {
  setPerPage(pageNumber);
  setOffSet(size);
  getMessageData(pageNumber, size)
}

let dataRange = {
  first:
    perPage - offset + 1,
  last:
    perPage -
    offset +
    supportData.length,
};
  return (
    <div className="pageContent">
      <div className="container">
        <div className="notification-with-sidebar">
          <nav className="notification-sidebar">
            <ul>
              <li className="button-link dropdown">
                <Button
                  className="btn lightblue dropdown-toggle"
                  onClick={e => openComposeMenu(e)}
                >
                  Compose
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button"
                  }}
                  anchorEl={anchorE2}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch"
                    }
                  }}
                >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  {/* <MenuItem onClick={handleCoachMenu}>Coach</MenuItem>
                  <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem> */}
                  <MenuItem onClick={handleSupportMenu}>Support</MenuItem>
                </Menu>
              </li>
              {/* <li
                className={window.location.href.includes('/coach') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/coach"})}
              >
                <div className = "link-item">
                  <span>Coaches</span> <span>{coachCount ? coachCount : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/advisor') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor"})}
              >
                <div className = "link-item">
                  <span>Advisor</span> <span>{advisorCount ? advisorCount : ""}</span>
                </div>
              </li> */}
              <li
                className={window.location.href.includes('/support') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/support"})}
              >
                <div className = "link-item">
                  <span>Support</span> <span>{supportCount ? supportCount : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/trash') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/trash"})}
              >
                <div  className = "link-item">
                  <span>Trash</span>
                </div>
              </li>
            </ul>
          </nav>
         <div className="notification-detail-box">
            <div className="notification-search-box">
              <div className="search-box">
                <label>Support</label>
              </div>
              <div className="notification-pagination">
                <ul>
                  <li>
                  {supportData?.length > 0 ?<Pagination 
                   dataPerPage={parseFloat(ischecked)}
                   totalData={count}
                   paginate={handlePagination}
                   initialPage={perPage / offset}
                   dataRange={dataRange}
                  />: ""}</li>
                  <li>
                    <div className="notification-dropdown-info">
                      {supportData?.length > 0 ?<IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls="long-menu"
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        size="large">
                        <MoreVertIcon />
                      </IconButton> : ""}
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button"
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch"
                          }
                        }}
                      >
                        <MenuItem>
                          <strong>Show up to</strong>
                        </MenuItem>
                        <MenuItem onClick={()=>handleMenuClose('20')}>
                         {ischecked === '20' ? <span className="icon-box" style={{marginRight: '10px'}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          20 items
                        </MenuItem>
                        <MenuItem onClick={()=>handleMenuClose('50')}>
                        {ischecked === '50' ? <span className="icon-box" style={{marginRight: '10px'}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          50 items
                          </MenuItem>
                        <MenuItem onClick={()=>handleMenuClose('75')}>
                        {ischecked === '75' ? <span className="icon-box" style={{marginRight: '10px'}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          75 items
                          </MenuItem>
                        <MenuItem onClick={()=>handleMenuClose('100')}>
                        {ischecked === '100' ? <span className="icon-box" style={{marginRight: '10px'}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          100 items
                        </MenuItem>
                      </Menu>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
      {supportData?.length > 0 ? (
        supportData.map(item => {
          return (
            <div 
            className={`item notification-item ${
            item.isread ? "active" : ""
            }`} onClick={()=>{messageReadData(item)}}>
              <div className="user-profile">
                {item.senderProfileImage ? <img src={imageDownloadUrl + `/${item.senderProfileImage}`} alt="" /> : <div className="initial">
                    {item.senderInitials}
                </div>}
              </div>
              <div className="user-detial-box">
                <div className="item">
                  <h4 className="name">{item.senderName}</h4>
                  <div className="text-box">
                    <span className="subject-line">{item.subject}</span>
                    <p>{item.message}</p>
                  </div>
                </div>
                <div className="item">
                  <div className="time-text">{item.dateTime}</div>
                  <div className="button-box">
                    <button
                      className="button"
                      onClick={() => {
                        history.push({ pathname: "/message/support/replyMessage", state: {data: item} });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.2"
                        height="16"
                        viewBox="0 0 19.2 16"
                      >
                        <path
                          id="Icon_material-reply"
                          data-name="Icon material-reply"
                          d="M11.967,11.767V7.5L4.5,14.967l7.467,7.467V18.06c5.333,0,9.067,1.707,11.733,5.44C22.633,18.167,19.433,12.833,11.967,11.767Z"
                          transform="translate(-4.5 -7.5)"
                          fill="#3e3e3e"
                        />
                      </svg>
                    </button>
                    <button className="button" onClick={(e)=>deleteMessageData(e,item)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                      >
                        <path
                          id="Icon_awesome-trash-alt"
                          data-name="Icon awesome-trash-alt"
                          d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z"
                          fill="#3e3e3e"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="noMsg">
          <span>No new message</span>
        </div>
      )}
    </div>
  </div> 
</div>
      </div>
      <Dialog open={showDeleteModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onDeleteClick()
                }
              }}
              className="matDialogCustom"
      >
        <DeleteItem
          onDeleteClick={onDeleteHandler}
          onCancelClick={onDeleteClick}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    messageList: state.athlete.dashboard.message.messageList,
    messageCountList: state.athlete.dashboard.message.messageCountList
  };
};
const mapDispatchToProps = dispatch => {
  return {
    messageListStart: data => dispatch(messageListStart(data)),
    messageCountListStart: data => dispatch(messageCountListStart(data)),
    saveMessageListStart: data => dispatch(saveMessageListStart(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportMessage);