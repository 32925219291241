import React, {useState} from "react";
import AthleteInfo from "./AthleteInfo";
import MyTeams from "./MyTeams";
import TargetColleges from "./TargetColleges";
import MyProfile from "./MyProfile";
import "./LeftSideBar.scss";
import MyOffers from "./MyOffers";
import MyCommitments from "./MyCommitments";
import {MySignedCommitment} from './SignedCommitment'

export default function LeftSideBar(props) {
    const [isShowMore, setIsShowMore] = useState(true)
  return (
    <>
    <div className={isShowMore ? "AthleteInfoBox" : "AthleteInfoBox show"}>
      <AthleteInfo isShowMore={isShowMore}  />
    </div>
  
      <div className={isShowMore ? "profileDataBox" : "profileDataBox show"}>
        <MyProfile />
      </div>
      <div className={isShowMore ? "teamOfferBox" : "teamOfferBox show"}>
        <MyTeams {...props} />
      </div>
      <div className={isShowMore ? "teamOfferBox" : "teamOfferBox show"}>
        <MyOffers />
      </div>
      <div className={isShowMore ? "teamOfferBox" : "teamOfferBox show"}>
        <MySignedCommitment />
      </div>
      <div className={isShowMore ? "teamOfferBox" : "teamOfferBox show"}>
        {/* <MyOffers /> */}
        <MyCommitments />
      </div>
      <div className={isShowMore ? "targetCollege sticky-box" : "targetCollege sticky-box show"}>
        <TargetColleges />
      </div>
      
      <div className="showMoreless">
        <a href={void 0} onClick={()=>setIsShowMore(!isShowMore)}>
          <span>{isShowMore ? "Show more" : "Show less"}</span>
          <span className="icon" style={isShowMore ? {transform: "none"} :{transform: "rotate(180deg)"}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="4"
              viewBox="0 0 8 4"
            >
              <defs>
                <style></style>
              </defs>
              <path
                className="a"
                d="M4,0,8,4H0Z"
                transform="translate(8 4) rotate(180)"
              />
            </svg>
          </span>
        </a>
      </div>
    </>
  );
}
