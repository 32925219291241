import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod, fetchMethod } from "../../services/httpService"
import messagePopup from "../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    RELATIONSHIP_LIST_START: 'RELATIONSHIP_LIST_START',
    RELATIONSHIP_LIST_SUCCESS: 'RELATIONSHIP_LIST_SUCCESS',
    RELATIONSHIP_LIST_FAILED: 'RELATIONSHIP_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const relationshipListStart = () => ({
   type: actionType.RELATIONSHIP_LIST_START
})

export const relationshipListSuccess = (data) => ({
    type: actionType.RELATIONSHIP_LIST_SUCCESS,
    payload: data
})

export const relationshipListFailed = (data) => ({
    type: actionType.RELATIONSHIP_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function relationshipListReducer(state = INIT_STATE,action){
  switch(action.type){
      case actionType.RELATIONSHIP_LIST_START: 
        return {
            ...state,
            loading: true
        }
      case actionType.RELATIONSHIP_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            data: action.payload,
            error: null
        }  
      case actionType.RELATIONSHIP_LIST_FAILED:
        return {
            ...state,
            loading: false,
            data: null,
            error: action.payload
        }       
      default: return {...state}  
  }
} 

// ===================================================== side effect =============================================

export function* relationshipListSaga(){
   try {
    const response = yield fetchMethod(`/allRelation`,null,'get',false);
    if(response && response.status === 200){
       const allRelationships = response.data.data ? response.data.data : null
       yield put(relationshipListSuccess(allRelationships));
    } else {
      messagePopup('','Relationship List failed','error')   
      yield put(relationshipListFailed('Relationship List failed')) 
    }
   } catch (error) {
     messagePopup('','Relationship List failed','error')   
     yield put(relationshipListFailed('Relationship List failed')) 
   }
}

export function* watchRelationshipListSaga(){
    yield takeEvery(actionType.RELATIONSHIP_LIST_START, relationshipListSaga)
}