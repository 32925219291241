import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SEARCH_COLLEGE_COUNT_START: "SEARCH_COLLEGE_COUNT_START",
  SEARCH_COLLEGE_COUNT_SUCCESS: "SEARCH_COLLEGE_COUNT_SUCCESS",
  SEARCH_COLLEGE_COUNT_FAILED: "SEARCH_COLLEGE_COUNT_FAILED"
};

// ============================================ Actions ========================================================

export const searchCollegeCountListStart = data => ({
  type: actionType.SEARCH_COLLEGE_COUNT_START,
  payload: data
});

export const searchCollegeCountListSuccess = data => ({
  type: actionType.SEARCH_COLLEGE_COUNT_SUCCESS,
  payload: data
});

export const searchCollegeCountListFailed = data => ({
  type: actionType.SEARCH_COLLEGE_COUNT_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function searchCollegeCountListReducer(state = INIT_STATE, action) {
  switch (action.type) {    
    case actionType.SEARCH_COLLEGE_COUNT_START:
      return {
        ...state,
        loading: true
      };

    case actionType.SEARCH_COLLEGE_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.SEARCH_COLLEGE_COUNT_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* searchCollegeCountListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
        const searchCollegeCount = response?.data?.data?.allSearchbycolleges;
      yield put(
        searchCollegeCountListSuccess(searchCollegeCount)
      );
    } else {
      messagePopup("", "Search Count Api failed", "error");
      yield put(searchCollegeCountListFailed("Search Count Api failed"));
    }
  } catch (error) {
    messagePopup("", "Search Count Api failed", "error");
    yield put(searchCollegeCountListFailed("Search Count Api failed"));
  }
}

export function* watchSearchCollegeCountListSaga() {
  yield takeEvery(actionType.SEARCH_COLLEGE_COUNT_START, searchCollegeCountListSaga);
}