import { Link } from "react-router-dom";
import { logout } from "../../../../../services/authService";
export const CoachHomePage = () =>{
    const handleLogout = () => {
        logout();
    }
    return(
        <>
        <br/><br/><br/><br/><br/>
        <h1>Welcome To Coach Dashboard</h1>
        <button onClick={()=>handleLogout()}>Logout</button>
         {/*<Link to="/coach-onboarding/Profile" > Claim Your Profile </Link> */}
        </>
    )
}