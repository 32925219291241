import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";
import {API_URL} from "../../../../config";
import toastr from "toastr";

// ========================================== Action Type =====================================================
export const actionType = {
  USER_ACADEMIC_SCHOOL_START: "USER_ACADEMIC_SCHOOL_START",
  USER_ACADEMIC_SCHOOL_SAVE: "USER_ACADEMIC_SCHOOL_SAVE",
  USER_ACADEMIC_REST_SCHOOL_SAVE: "USER_ACADEMIC_REST_SCHOOL_SAVE",
  USER_ACADEMIC_SCHOOL_SUCCESS: "USER_ACADEMIC_SCHOOL_SUCCESS",
  USER_ACADEMIC_SCHOOL_FAILED: "USER_ACADEMIC_SCHOOL_FAILED",
};

// ============================================ Actions ========================================================
export const userAcademicSchoolStart = (data) => ({
  type: actionType.USER_ACADEMIC_SCHOOL_START,
  payload: data,
});
export const userAcademicSchoolSave = (data) => ({
  type: actionType.USER_ACADEMIC_SCHOOL_SAVE,
  payload: data,
});
//this is used for rest api academic start and end date
export const userAcademicRestSchoolSave = (data) => ({
  type: actionType.USER_ACADEMIC_REST_SCHOOL_SAVE,
  payload: data,
});
export const userAcademicSchoolSuccess = (data) => ({
  type: actionType.USER_ACADEMIC_SCHOOL_SUCCESS,
  payload: data,
});

export const userAcademicSchoolFailed = (data) => ({
  type: actionType.USER_ACADEMIC_SCHOOL_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function userAcademicSchoolReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.USER_ACADEMIC_SCHOOL_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.USER_ACADEMIC_SCHOOL_SAVE:
      return {
        ...state,
        loading: true,
      };
    case actionType.USER_ACADEMIC_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.USER_ACADEMIC_SCHOOL_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* userAcademicSchoolSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const saveAthleteData = !!action?.payload?.isAcademic ?
          response?.data?.data?.saveAthletemoreschool
          :  response.data.data.saveAthleteschool
      const academicSchoolResponse = saveAthleteData;

      if (response && response.data && response.data.errors) {
        messagePopup("", response.data.errors[0].message.msg, "error");
      } else {
        if (action.payload.handleSchoolMessage) {
          action.payload.handleSchoolMessage();
        } else if (academicSchoolResponse.active === 0) {
          // messagePopup("", "School Removed successfully.", "success");
          toastr.success("School Removed successfully.", "", {
            progressBar : true,timeOut: 2000,
            closeButton: true, positionClass: "toast-top-full-width",
            showEasing: "swing", fadeIn: 40000,
            preventDuplicates: true,
          })
        }
      }
      yield put(
        userAcademicSchoolSuccess({
          saveUserAcademicSchool: academicSchoolResponse,
        })
      );
    } else {
      messagePopup("", "User School Save failed", "error");
      yield put(userAcademicSchoolFailed("User School Save failed"));
    }
  } catch (error) {
    messagePopup("", "User School Save failed", "error");
    yield put(userAcademicSchoolFailed("User School Save failed"));
  }
}
//this saga is for academic school CRUD
export function* userAcademicSchoolRestSaveSaga(action) {
  try {
    const response = yield fetchMethod(
        '/api/Athletemorecolleges/AddAthleteSchool',
        action?.payload?.variables?.obj,
        "POST",
        true
    );
    if (response && response.status === 200 && response?.data?.response?.status == 200) {
      messagePopup("", !!response?.data?.response?.msg ? response?.data?.response?.msg :
          `School ${(!!action?.payload?.variables?.obj?.id) ? "Updated" : "Added"}`, "success");
      yield put(userAcademicSchoolSuccess("School Added successfully"))
      action?.payload?.nextRoute()
      action?.payload?.getAllSchoolListAPI()
    } else if(response && response.status === 200 && response?.data?.response?.status == 201) {
      messagePopup("", !!response?.data?.response?.msg ? response?.data?.response?.msg :
          `School ${(!!action?.payload?.variables?.obj?.id) ? "Updated" : "Added"}`, "warning");
      yield put(userAcademicSchoolSuccess("School Added successfully"))
      action?.payload?.nextRoute()
      //this func is used for call the listing update operation
      action?.payload?.getAllSchoolListAPI()
    }else{
      messagePopup("", "User School Rest Save failed", "error");
      yield put(userAcademicSchoolFailed("User School Save failed"));

    }
  } catch (error) {
    messagePopup("", "User School Rest Save failed", "error");
    yield put(userAcademicSchoolFailed("User School Save failed"));
  }
}

export function* userAcademicSchoolListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      //this check is done as on academic we have to show all school and college
      // so as per said
      const academicData = !!action?.payload?.isAcademic ?
          response.data.data.allAthletemoreschools
          :  response.data.data.allAthleteschools
      yield put(
        userAcademicSchoolSuccess({
          allUserAcademicSchool: academicData,
        })
      );
    } else {
      messagePopup("", "User School List failed", "error");
      yield put(userAcademicSchoolFailed("User School List failed"));
    }
  } catch (error) {
    messagePopup("", "User School List failed", "error");
    yield put(userAcademicSchoolFailed("User School List failed"));
  }
}

//Watcher
export function* watchUserAcademicSchoolSaga() {
  yield takeEvery(
    actionType.USER_ACADEMIC_SCHOOL_SAVE,
    userAcademicSchoolSaveSaga
  );
  yield takeEvery(
      actionType.USER_ACADEMIC_REST_SCHOOL_SAVE,
      userAcademicSchoolRestSaveSaga
  );
  yield takeEvery(
    actionType.USER_ACADEMIC_SCHOOL_START,
    userAcademicSchoolListSaga
  );
}
