import React from 'react'
import "../../../../../../assets/css/owl.carousel.min.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";
const FollowMetricsCarousel = () => {
    return (
        <>
          <OwlCarousel className="owl-theme" nav>
                  <div className="item">
                    <div className="velocityMax">
                      <span>83.27</span>
                      <span className="label">Velocity Max</span>
                    </div>
                  </div>

                  <div className="item">
                    <div className="chValueBox">
                      <div className="iconBox">
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.504"
                            height="16.255"
                            viewBox="0 0 16.504 16.255"
                          >
                            <defs>
                              <style></style>
                            </defs>
                            <g transform="translate(-2.057 -2.086)">
                              <path
                                className="a"
                                d="M17.5,9.578v.667a7.25,7.25,0,1,1-4.3-6.627"
                                transform="translate(0 0)"
                              />
                              <path
                                className="a"
                                d="M22.925,6l-7.25,7.257L13.5,11.082"
                                transform="translate(-5.425 -1.555)"
                              />
                            </g>
                          </svg>
                        </span>
                        <span>68-69</span>
                      </div>
                      <span className="label">CB</span>
                    </div>
                  </div>

                  <div className="item">
                    <div className="chValueBox">
                      <div className="iconBox">
                        {/* <!-- <span className="icon"></span> --> */}
                        <span>76-77</span>
                      </div>
                      <span className="label">CH</span>
                    </div>
                  </div>
                  <div className="item">
                    <div className="velocityMax">
                      <span>83.27</span>
                      <span className="label">Velocity Max</span>
                    </div>
                  </div>
                  <div className="item">
                    <div className="velocityMax">
                      <span>83.27</span>
                      <span className="label">Velocity Max</span>
                    </div>
                  </div>
                  <div className="item">
                    <div className="velocityMax">
                      <span>83.27</span>
                      <span className="label">Velocity Max</span>
                    </div>
                  </div>
                  <div className="item">
                    <div className="velocityMax">
                      <span>83.27</span>
                      <span className="label">Velocity Max</span>
                    </div>
                  </div>
                  <div className="item">
                    <div className="chValueBox">
                      <div className="iconBox">
                        {/* <!-- <span className="icon"></span> --> */}
                        <span>76-77</span>
                      </div>
                      <span className="label">CH</span>
                    </div>
                  </div>
                  <div className="item">
                    <div className="chValueBox">
                      <div className="iconBox">
                        {/* <!-- <span className="icon"></span> --> */}
                        <span>76-77</span>
                      </div>
                      <span className="label">CH</span>
                    </div>
                  </div>
                </OwlCarousel>  
        </>
    )
}

export default FollowMetricsCarousel
