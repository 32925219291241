import {combineReducers} from 'redux'
import follwerAthleteCountReducer from './followerAthleteActivity';
import follwerCollegeCoachCountReducer from "./followerCollegeCoachActivity";
import follwerAthleteViewCountReducer from "./followerAthleteViewActivity";
import follwerCollegeCoachViewCountReducer from "./followerCollegeCoachViewActivity";
import follwerCollegeCoachActivityListReducer from "./followerCollegeCoachActivityList";
import follwerAthleteAddressReducer from "./followerAthleteLocation";
import follwerCoachStateReducer from "./followerCoachByState";
import follwerCoachLevelReducer from "./followerCoachByLevel";
import viewCoachLevelReducer from "./viewCoachByLevel";
import viewCoachStateReducer from './viewCoachByState';

export default combineReducers({
    followerAthleteList: follwerAthleteCountReducer,
    followerCollegeCoachCount: follwerCollegeCoachCountReducer,
    follwerAthleteViewCount: follwerAthleteViewCountReducer,
    follwerCollegeCoachViewCount: follwerCollegeCoachViewCountReducer,
    followerCollegeCoachActivityList: follwerCollegeCoachActivityListReducer,
    followerAthleteLocation: follwerAthleteAddressReducer,
    followerCoachState: follwerCoachStateReducer,
    followerCoachLevel: follwerCoachLevelReducer,
    viewCoachState: viewCoachStateReducer,
    viewCoachLevel: viewCoachLevelReducer
})