import { React, useState, useEffect } from "react";
import "./assets/css/plan-priing.scss";
import persoalizedRecruitingGuidance from "./assets/img/personalized-recruiting-guidance.png";
import messageSystem from "./assets/img/message-system-and-college-views.png";
import testimonial1 from "./assets/img/testimonial-img01.png";
import testimonial2 from "./assets/img/testimonial-img02.png";
import helpIMG from "./assets/img/help_img.png";
import videoThumb from "./assets/img/video-thum.png";
import { upgradePlanListStart } from "../../../../../../redux/athlete/dashboard/upgradePlans/upgradePlans";
import { askedQuestionsListStart } from "../../../../../../redux/athlete/dashboard/account/frequentlyAskedQuestions";
import {
  getUpgradePlansQuery,
  getAllSubscriptionFeatures,
} from "./programAndPricingQuery";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { getAllAskedQuestionsQuery } from "../../../dashboard/help/FAQ's/faqQuery";
import { getAllPhotosVideosQuery } from "./programAndPricingQuery";
import { allVideoPhotoListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/getVideoPhotoInfo";
import VideoPopup from "../../resources/screens/Utils/VideoPopup";
import { featuresListStart } from "../../../../../../redux/athlete/dashboard/upgradePlans/featuresList";
import { imageDownloadUrl } from "../../../../../../config";
import HelpImage from "./assets/img/help_img.png";
import ReactPaginate from "react-paginate";
import { getUserInfo } from "../../../../../../services/authService";
import { membershipListStart } from "../../../../../../redux/athlete/dashboard/account/membership";
import { getAllMembershipsQuery } from "../myAccounts/membershipQuery";

const FAQ = ({ membershipTab, allAskedQuestions, askedQuestionsListStart }) => {
  const [expand, setExpand] = useState([]);

  const handleExpandCollapse = (prevState, index) => {
    const tempData = [...expand];
    tempData[index] = prevState ? false : true;
    setExpand(tempData);
  };

  useEffect(() => {
    getAllMembershipQuestionsList();
  }, [membershipTab]);

  const getAllMembershipQuestionsList = () => {
    if (membershipTab === false) {
      askedQuestionsListStart({
        query: getAllAskedQuestionsQuery,
        variables: {
          where: {
            faqtype: "membership",
          },
        },
      });
    }

    if (membershipTab === true) {
      askedQuestionsListStart({
        query: getAllAskedQuestionsQuery,
        variables: {
          where: {
            faqtype: "recruiting guidence",
          },
        },
      });
    }
  };

  return (
      <>
        <section className="athlete-faq-sec gray-bg">
          <div className="container">
            <div className="faq-box-wrapper">
              <h1 className="sec-title">Frequently Asked Questions</h1>
                <ul className="faq-listing">
               {allAskedQuestions
                  ? allAskedQuestions.map((questions, index) => (
                      <li key={index} className="active">
                        <div
                          onClick={(e) => handleExpandCollapse(expand[index], index)}
                          className={`faq-title  ${expand[index] ? "active" : ""}`}
                        >
                          <span>Q{index + 1}</span> {questions.question}
                        </div>
                        <div
                          className="faq-data"
                          style={{ display: `${expand[index] ? "block" : "none"}` }}
                        >
                          <p>{questions.answer} </p>
                        </div>
                      </li>
                    ))
                  : ""}
              </ul>
              {/*<ul className="faq-listing">*/}
              {/*  <li className="active">*/}
              {/*    <div className="faq-title active">Lorem Ipsum is placeholder text commonly used in the graphic,print?*/}
              {/*    </div>*/}
              {/*    <div className="faq-data" style={{display: "block"}}>*/}
              {/*      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the*/}
              {/*        industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type*/}
              {/*        and scrambled it to make a type specimen book. </p>*/}
              {/*    </div>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
          </div>
        </section>
      </>
      //earlir thi FAQ was used
    // <section className="faq-sec">
    //   <h3 className="sec-title">Frequently Asked Questions</h3>
    //   <ul className="faq-listing">
    //     {allAskedQuestions
    //       ? allAskedQuestions.map((questions, index) => (
    //           <li key={index} className="active">
    //             <div
    //               onClick={(e) => handleExpandCollapse(expand[index], index)}
    //               className={`faq-title  ${expand[index] ? "active" : ""}`}
    //             >
    //               <span>Q{index + 1}</span> {questions.question}
    //             </div>
    //             <div
    //               className="faq-data"
    //               style={{ display: `${expand[index] ? "block" : "none"}` }}
    //             >
    //               <p>{questions.answer} </p>
    //             </div>
    //           </li>
    //         ))
    //       : ""}
    //   </ul>
    // </section>
  );
};

const VideoSection = ({ allVideoPhotoListStart, allVideoPhotoInfo }) => {
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [videoId, setVideoId] = useState();
  const [video, setVideoName] = useState();
  const [photo, setPhotoName] = useState([]);
  const [videoLink, setVideoLink] = useState();
  const handleClose = () => {
    setShowVideoPopup(false);
  };

  useEffect(()=> {
    getVideoData();
  }, [])

  const getVideoData = () => {
    allVideoPhotoListStart({
      query: getAllPhotosVideosQuery,
      variables: {
        where: {
          videophotosuploadedtype: "ProgramAndPricing",
        },
      },
    });
  };
  useEffect(()=> {
      videoWithTestimonial();
  }, [allVideoPhotoInfo])

  const videoWithTestimonial = () => {
   const videoNameForTestimonial = allVideoPhotoInfo ? allVideoPhotoInfo.filter((video)=> (
       video.videoname !== null
      )): null;
      const videoLinkForTestimonial = allVideoPhotoInfo ? allVideoPhotoInfo.filter((link)=>(
        link.videolink !== null
      )) : null;
      let videoNameTest = videoNameForTestimonial && videoNameForTestimonial[0] && videoNameForTestimonial[0].videoname ? videoNameForTestimonial[0].videoname : null;
      let videoLinkTest = videoLinkForTestimonial && videoLinkForTestimonial[0] && videoLinkForTestimonial[0].videolink ? videoLinkForTestimonial[0].videolink : null;
      if(videoNameTest){
        setVideoName(videoNameTest);
      }else if(videoLinkTest){
        setVideoLink(videoLinkTest);
      }
      if(allVideoPhotoInfo?.length > 0){
        let picArray = []
        allVideoPhotoInfo.map(el => !!el?.photosname && picArray.push(el))
        setPhotoName(picArray)
      }
  }

  const openURLVideo = () => {
    if(videoLink){
      window.open(videoLink, "_blank")
    }
  }
  const helpSlide = {
    loop: true,
    nav: true,
    dots: false,
    responsive:{
      0:{
        items:1
      },
      768:{
        items: 2
      },
      992:{
        items: 3
      }
    }
  };

  return (
    <>
      <section className="content-with-video-sec">
        <div className="help-box">
          <h3>Testimonial</h3>
          {photo?.length > 0 &&
          <OwlCarousel className=" owl-theme"
                       {...helpSlide}>
            {!!photo
                ? photo.map((photos, index) => (
                    <div>
                      {photos.photosname !== null ? (
                          <div key={index} className="item">
                            <div className="help-img">
                              <img
                                  src={imageDownloadUrl + "/" + photos.photosname}
                                  alt=""
                              />
                            </div>
                            <h3>{photos.videodescription}</h3>
                            <div className="button-box">
                              <a className="btn green">Schedule Call</a>
                            </div>
                          </div>
                      ) : null}
                    </div>
                ))
                : ""}
          </OwlCarousel>
          }
        </div>
        <div className="video-box">
          <h3>Video</h3>
          <div className="img-box" >
            {/*{video ? (*/}
            <video controls>
              {!!video &&
                  <>
              <source
                  src={`${imageDownloadUrl}/${!!video ? video : ""}`}>
              </source>
                  </>
              }
              </video>
            {/*) : (*/}
            {/*  <div*/}
            {/*  data-toggle="modal"*/}
            {/*  data-target="#VideoPricingModal"*/}
            {/*  className="video-play-button"*/}
            {/*>*/}
            {/*  <div className="video-play-inner">*/}
            {/*    <button*/}
            {/*        type={'button'}*/}
            {/*    onClick= {(e)=> {*/}
            {/*      openURLVideo(e)*/}
            {/*    }}*/}
            {/*    >*/}
            {/*      <svg*/}
            {/*        xmlns="http://www.w3.org/2000/svg"*/}
            {/*        width="20.802"*/}
            {/*        height="26.203"*/}
            {/*        viewBox="0 0 10.802 16.203"*/}
            {/*      >*/}
            {/*        <path*/}
            {/*          className="a"*/}
            {/*          d="M8.1,0l8.1,10.8H0Z"*/}
            {/*          transform="translate(10.802) rotate(90)"*/}
            {/*        />*/}
            {/*      </svg>*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*)}*/}
          </div>
        </div>
      </section>
    </>
  );
};

const FeatureComparison = ({
  membershipTab,
  nextPlanData,
  featuresListStart,
  planFeatures,
}) => {
  const [featureList, setFeatureList] = useState(null);
  useEffect(() => {
    featuresListStart({
      query: getAllSubscriptionFeatures,
      variables: null,
    });
  }, []);

  useEffect(() => {
    if (planFeatures.Subscriptionfeatures) {
      let tempPlanFeatureList = [];
      for (const data of planFeatures.Subscriptionfeatures) {
        if (data.featurename)
          tempPlanFeatureList = [...tempPlanFeatureList, data.featurename];
      }
      setFeatureList(tempPlanFeatureList);
    }
  }, [planFeatures]);

  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>Features</th>
            {nextPlanData
              ? nextPlanData.map((planData, index) => (
                  <th key={index}>{planData.plantype}</th>
                ))
              : null}
          </tr>
          {featureList
            ? featureList.map((featurename, index) => (
                <>
                  <tr key={index}>
                    <td>{featurename}</td>
                    {nextPlanData
                      ? nextPlanData.map((planData, index) => (
                          <>
                            {planData.subscriptionplanfeaturesmappingplanidMap
                              ?.Subscriptionplanfeaturesmappings
                              ? planData.subscriptionplanfeaturesmappingplanidMap?.Subscriptionplanfeaturesmappings.map(
                                  (item, index) => {
                                    if (
                                      item.subscriptionplanfeaturesmappingfeatureidmaprel &&
                                      item
                                        .subscriptionplanfeaturesmappingfeatureidmaprel
                                        .Subscriptionfeatures[0]
                                    ) {
                                      if (
                                        featurename ===
                                        item
                                          .subscriptionplanfeaturesmappingfeatureidmaprel
                                          .Subscriptionfeatures[0].featurename
                                      ) {
                                        if (item.availability === "Limited") {
                                          return <td>Limited</td>;
                                        } else if (
                                          item.availability === "Unlimited"
                                        ) {
                                          return (
                                            <td>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="26.18"
                                                height="26.18"
                                                viewBox="0 0 26.18 26.18"
                                              >
                                                <path
                                                  id="checkmark-filled"
                                                  d="M15.34,2.25A13.09,13.09,0,1,0,28.43,15.34,13.09,13.09,0,0,0,15.34,2.25ZM13.47,20.567,8.795,15.892,10.282,14.4l3.188,3.188L20.4,10.665l1.493,1.483Z"
                                                  transform="translate(-2.25 -2.25)"
                                                  fill="#1cb79e"
                                                />
                                              </svg>
                                            </td>
                                          );
                                        } else {
                                          return <td></td>;
                                        }
                                      } else {
                                        return null;
                                      }
                                    } else {
                                      return null;
                                    }
                                  }
                                )
                              : null}
                          </>
                        ))
                      : null}
                  </tr>
                </>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};
const helpSlide = {
  loop: true,
  nav: true,
  dots: true,
  items: 3,
  responsive:{
    0:{
      items:1
    },
    768:{
      items: 2
    },
    992:{
      items: 3
    }
  }
};
const optionCarousel = {
  className: "plans-listing owl-carousel",
  margin: 5,
  nav: true,
  responsive:{
    0:{
      items:1
    },
    768:{
      items: 2
    },
    992:{
      items: 3
    }
  }
}
function ProgramAndPricing(props) {
  const [membershipTab, setMembershipTab] = useState(false);
  const [UpgradePlanData, setUpgradePlanData] = useState([]);
  const [toggle, setToggle] = useState({ isChecked: true });
  const [nextPlanData, setNextPlanData] = useState(null);
  const [membershipData, setMembershipData] = useState([]);
  const [purchasedPlans, setPurchasedPlans] = useState(null);
  const [planNextButton , setPlanNextButton] = useState(true);
  const [planPrevButton, setPlanPrevButton] = useState(false);
  const [mouseIdx, setMouseIdx] = useState(1);
  const redirectUrl = "programAndPricing";
  const [offset, setOffset] = useState(0);
  // for fixed header
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
      if (parseInt(window.pageYOffset) > 1020 && parseInt(window.pageYOffset) < 1480) {
        document.querySelector("body").classList.add("fixed-plans");
      }
      else {
        document.querySelector("body").classList.remove("fixed-plans");
      }
    };
  }, []);

  useEffect(() => {
    getAllSubscriptionPlanData();
  }, [membershipTab]);

  useEffect(() => {
    if (
      props.subscriptionPlanData &&
      props.subscriptionPlanData.upgradePlans &&
      props.subscriptionPlanData.upgradePlans.data
    ) {
      setUpgradePlanData(props.subscriptionPlanData.upgradePlans.data);
    }
  }, [props.subscriptionPlanData.upgradePlans.data]);

  useEffect(()=>{
    handlePreviousPlans()
  }, [UpgradePlanData])
  useEffect(()=> {
   getAllMembershipData();
  }, [])
  //this is used for scroll on top
  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0,0)
    },200)
  },[])

  useEffect(()=> {
    if(props.allMemberships.data){
      setMembershipData(props.allMemberships.data);
    }
  }, [props.allMemberships.data])

  const getAllSubscriptionPlanData = () => {
    if (membershipTab == false) {
      props.upgradePlanListStart({
        query: getUpgradePlansQuery,
        variables: {
          where: {
            plansectiontype: "membership",
          },
        },
      });
    } else {
      props.upgradePlanListStart({
        query: getUpgradePlansQuery,
        variables: {
          where: {
            plansectiontype: "recruiting guidance",
          },
        },
      });
    }
  };

  const handleMembershipTab = () => {
    setMembershipTab(!membershipTab);
  };

  const alreadyPurchasedPlan = (planid) => {
     const purchasedPlan = membershipData.allMemberships ? membershipData.allMemberships.map((membership)=> {
       return (  membership.subscriptionplanid == planid ? true : false)
     })   : null
     const ifPurchasedPlanIsTrue = purchasedPlan ? purchasedPlan.filter((plan)=> {
       return plan == true ? true : false

     }) : null
     return ifPurchasedPlanIsTrue && ifPurchasedPlanIsTrue[0] == true ? true : false
  }

  const handleChange = (e) => {
    setToggle({ isChecked: !toggle.isChecked });
  };

  const handlePreviousPlans = () => {
    var previousUpgradePlanData = UpgradePlanData.slice(0,3);
    setNextPlanData(previousUpgradePlanData)
    setPlanNextButton(true)
    setPlanPrevButton(false)
  }

  const handleNextPlans = () => {
    var nextUpgradePlanData = UpgradePlanData.slice(3);
    setNextPlanData(nextUpgradePlanData)
    setPlanNextButton(false)
    setPlanPrevButton(true)
  }

  const getAllMembershipData = () => {
    props.membershipListStart({
      query: getAllMembershipsQuery,
      variables : {
        where : {
          userid : getUserInfo() ? getUserInfo().id : null
        }
      }
    })
  }
  return (
    <>
      {/*<section className="program-and-pricing-sec sticky-plans">*/}
      {/*  <div className="container">*/}
      {/*    <div className="program-pricing-white-box">*/}
      {/*      <div className="plans-main-sec">*/}
      {/*        <div className="plans-list-with-content">*/}
      {/*          <div className="plan-text-box"></div>*/}
      {/*          <ul className="plans-listing">*/}
      {/*            {nextPlanData*/}
      {/*              ? nextPlanData.map((plans, index) => (*/}
      {/*                  <li key={index} className="plan-item">*/}
      {/*                    <div className="plan-detail">*/}
      {/*                      <h3 className="title">{plans.plantype}</h3>*/}
      {/*                      <div className="sub-title">{plans.planname}</div>*/}
      {/*                      <div className="price">*/}
      {/*                        <sup>$</sup>*/}
      {/*                        <span>*/}
      {/*                          {toggle.isChecked === false*/}
      {/*                            ? plans.yearlyprice*/}
      {/*                            : plans.monthlyprice}*/}
      {/*                        </span>*/}
      {/*                        <sub>*/}
      {/*                          {toggle.isChecked === false*/}
      {/*                            ? "/year"*/}
      {/*                            : "/months"}*/}
      {/*                        </sub>*/}
      {/*                      </div>*/}
      {/*                    </div>*/}
      {/*                    <div className="plan-bottom-box">*/}
      {/*                   <div className="plan-btn">*/}
      {/*                    {plans.plantype == "FREE" ? (*/}
      {/*                      "Current Plan" ) :*/}
      {/*                          alreadyPurchasedPlan(plans.id) == true ? "Purchased" : */}
      {/*                          (*/}
      {/*                        <Link*/}
      {/*                          to={{*/}
      {/*                            pathname:`/payment/${redirectUrl}`,*/}
      {/*                            state: {*/}
      {/*                              selectedPlan: plans,*/}
      {/*                              toggle: toggle,*/}
      {/*                              previousPage: "program-and-pricing",*/}
      {/*                            },*/}
      {/*                        }}*/}
      {/*                        >*/}
      {/*                        Purchase Plan*/}
      {/*                      </Link>  */}
      {/*                      )}*/}
      {/*                      </div>*/}
      {/*                    </div>*/}
      {/*                  </li>*/}
      {/*                ))*/}
      {/*              : ""}*/}
      {/*          </ul>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*<div className="program-and-pricing-sec">*/}
      {/*  <div className="container">*/}
      {/*    <div className="program-pricing-white-box">*/}
            {/*<section className="plans-main-sec">*/}
            {/*  <div className="top-block">*/}
            {/*    <div className="tagline">Let's find the right fit for you!</div>*/}
            {/*    <div className="program-pricing-tabs">*/}
            {/*      <ul className={`tabs ${membershipTab ? "blue" : ""}`}>*/}
            {/*        <li className={`${membershipTab ? " " : "current"}`}>*/}
            {/*          <a onClick={() => handleMembershipTab()}>Membership</a>*/}
            {/*        </li>*/}
            {/*        <li className={`${membershipTab ? "current " : ""}`}>*/}
            {/*          <a onClick={() => handleMembershipTab()}>*/}
            {/*            Recruiting Guidance*/}
            {/*          </a>*/}
            {/*        </li>*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*    {membershipTab == true ? (*/}
            {/*      <h1 className="sec-title">*/}
            {/*        Dedicated Recruiting <br />*/}
            {/*        Guidance*/}
            {/*      </h1>*/}
            {/*    ) : (*/}
            {/*      <h1 className="sec-title">*/}
            {/*        Unlock your home for the <br />*/}
            {/*        recruiting process*/}
            {/*      </h1>*/}
            {/*    )}*/}
            {/*    <p>*/}
            {/*      Get the essential tools you need to tackle your recruiting{" "}*/}
            {/*      <br />*/}
            {/*      process and drive traction with college coaches.*/}
            {/*    </p>*/}
            {/*    <ul className="pricing-unlock-feature">*/}
            {/*      <li>*/}
            {/*        <a>*/}
            {/*          <span className="icon-box">*/}
            {/*            <img src={persoalizedRecruitingGuidance} alt="" />*/}
            {/*          </span>*/}
            {/*          Personalized Recruiting Guidance*/}
            {/*        </a>*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        <a>*/}
            {/*          <span className="icon-box">*/}
            {/*            <img src={messageSystem} alt="" />*/}
            {/*          </span>*/}
            {/*          Message System And College Views*/}
            {/*        </a>*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        <a>*/}
            {/*          <span className="icon-box">*/}
            {/*            <img src={persoalizedRecruitingGuidance} alt="" />*/}
            {/*          </span>*/}
            {/*          Access Through Graduation*/}
            {/*        </a>*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*    <div className="form-check form-switch">*/}
            {/*      <label*/}
            {/*        className="form-check-label"*/}
            {/*        htmlFor="flexSwitchCheckDefault"*/}
            {/*      >*/}
            {/*        Monthly*/}
            {/*      </label>*/}
            {/*      <input*/}
            {/*        className="form-check-input"*/}
            {/*        type="checkbox"*/}
            {/*        id="flexSwitchCheckDefault"*/}
            {/*        defaultChecked={true}*/}
            {/*        value={toggle.isChecked}*/}
            {/*        onChange={(e) => handleChange(e)}*/}
            {/*      />*/}
            {/*      <label*/}
            {/*        className="form-check-label"*/}
            {/*        htmlFor="flexSwitchCheckDefault"*/}
            {/*      >*/}
            {/*        Yearly <span>Save 67%</span>*/}
            {/*      </label>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="plans-list-with-content">*/}
            {/*    <div className="plan-text-box">*/}
            {/*      <p>*/}
            {/*        Plans provide full platform access through graduation.*/}
            {/*        Available for purchase as a one-time payment (save 16%) or*/}
            {/*        in 12 monthly installments.*/}
            {/*      </p>*/}
            {/*    </div>*/}

            {/*    <ul className="plans-listing">*/}
            {/*      {nextPlanData*/}
            {/*        ? nextPlanData.map((plans, index) => (*/}
            {/*            <li key={index} className="plan-item">*/}
            {/*              <div className="plan-detail">*/}
            {/*                <h3 className="title">{plans.plantype}</h3>*/}
            {/*                <div className="sub-title">{plans.planname}</div>*/}
            {/*                <div className="price">*/}
            {/*                  <sup>$</sup>*/}
            {/*                  <span>*/}
            {/*                    {toggle.isChecked === false*/}
            {/*                      ? plans.yearlyprice*/}
            {/*                      : plans.monthlyprice}*/}
            {/*                  </span>*/}
            {/*                  <sub>*/}
            {/*                    {toggle.isChecked === false*/}
            {/*                      ? "/year"*/}
            {/*                      : "/months"}*/}
            {/*                  </sub>*/}
            {/*                </div>*/}
            {/*                <div className="short-text">*/}
            {/*                  <p>{plans.description}. </p>*/}
            {/*                </div>*/}
            {/*                <div className="expert-button-box">*/}
            {/*                  <a>{plans.shortdescription}</a>*/}
            {/*                </div>*/}
            {/*              </div>*/}
            {/*              <div className="plan-info">*/}
            {/*                <h4>Plan Includes:</h4>*/}
            {/*                <ul>*/}
            {/*                  {plans.subscriptionplanincludessubscriptionplanidMap &&*/}
            {/*                  plans*/}
            {/*                    .subscriptionplanincludessubscriptionplanidMap*/}
            {/*                    .Subscriptionplanincludes*/}
            {/*                    ? plans.subscriptionplanincludessubscriptionplanidMap.Subscriptionplanincludes.map(*/}
            {/*                        (planincludes, index) => (*/}
            {/*                          <li key={index}>*/}
            {/*                            {planincludes.includeplanname}*/}
            {/*                          </li>*/}
            {/*                        )*/}
            {/*                      )*/}
            {/*                    : ""}*/}
            {/*                </ul>*/}
            {/*              </div>*/}
            {/*             <div className="plan-bottom-box">*/}
            {/*             <div className="plan-btn">*/}
            {/*              {plans.plantype == "FREE" ? (*/}
            {/*                "Current Plan" ) :*/}
            {/*                    alreadyPurchasedPlan(plans.id) == true ? "Purchased" : */}
            {/*                    (*/}
            {/*                  <Link*/}
            {/*                    to={{*/}
            {/*                      pathname: `/payment/${redirectUrl}`,*/}
            {/*                      state: {*/}
            {/*                        selectedPlan: plans,*/}
            {/*                        toggle: toggle,*/}
            {/*                        previousPage: "program-and-pricing",*/}
            {/*                      },*/}
            {/*                  }}*/}
            {/*                  >*/}
            {/*                  Purchase Plan*/}
            {/*                </Link>  */}
            {/*                )}*/}
            {/*                </div>*/}
            {/*              </div>*/}
            {/*            </li>*/}
            {/*          ))*/}
            {/*        : "No Plans found"}*/}
            {/*        {nextPlanData != [] ? (*/}
            {/*          <div className="owl-nav">*/}
            {/*          {planNextButton === true && UpgradePlanData && UpgradePlanData.length > 3 ? (*/}
            {/*              <button onClick={()=>handleNextPlans()} type="button" role="presentation" className="owl-next">*/}
            {/*              <span aria-label="Next">›</span>*/}
            {/*            </button>*/}
            {/*          ): null}*/}
            {/*            {planPrevButton === true ? (*/}
            {/*             <button onClick={()=>handlePreviousPlans()} type="button" role="presentation" className="owl-prev">*/}
            {/*             <span aria-label="Previous">‹</span>*/}
            {/*           </button>*/}
            {/*            ) : null}*/}
            {/*        </div>*/}
            {/*        ): null}*/}
            {/*    </ul>*/}
            {/*  </div>*/}
            {/*  <div className="plan-comparison-sec" id="plan-comparison-sec">*/}
            {/*    <h2 className="sec-title">Features comparison</h2>*/}
            {/*    <FeatureComparison*/}
            {/*      nextPlanData={nextPlanData}*/}
            {/*      membershipTab={membershipTab}*/}
            {/*      featuresListStart={props.featuresListStart}*/}
            {/*      planFeatures={*/}
            {/*        props.planFeatures &&*/}
            {/*        props.planFeatures.data &&*/}
            {/*        props.planFeatures.data.allfeaturesdata &&*/}
            {/*        props.planFeatures.data.allfeaturesdata*/}
            {/*          .allSubscriptionfeatures*/}
            {/*          ? props.planFeatures.data.allfeaturesdata*/}
            {/*              .allSubscriptionfeatures*/}
            {/*          : ""*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</section>*/}
            {/*<section className="content-with-video-sec">*/}
            {/*    <VideoSection*/}
            {/*    allVideoPhotoListStart={props.allVideoPhotoListStart}*/}
            {/*    allVideoPhotoInfo={*/}
            {/*      props.allVideoPhotoInfo.data &&*/}
            {/*      props.allVideoPhotoInfo.data.allUploadedvideoandphotos*/}
            {/*        ? props.allVideoPhotoInfo.data.allUploadedvideoandphotos*/}
            {/*        : ""*/}
            {/*    }*/}
            {/*  />*/}
            {/*</section>*/}
            {/*<section className="recruiting-guidance-sec">*/}
            {/*  <div className="icon-box">*/}
            {/*    <img src={persoalizedRecruitingGuidance} alt="" />*/}
            {/*  </div>*/}
            {/*  <div className="content-box">*/}
            {/*    <h3 className="title">*/}
            {/*      Looking for more guidance in your recruiting process?*/}
            {/*    </h3>*/}
            {/*    <div className="short-text">*/}
            {/*      <p>*/}
            {/*        If you are looking for guidance and support throughout your*/}
            {/*        recruiting process check out our recruiting guidance plans*/}
            {/*        which also provide platform access through graduation.*/}
            {/*      </p>*/}
            {/*      <p>*/}
            {/*        <a className="read-more-link" href="#">*/}
            {/*          See Recruiting Guidance Options*/}
            {/*        </a>*/}
            {/*      </p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</section>*/}
            {/*<FAQ*/}
            {/*  membershipTab={membershipTab}*/}
            {/*  allAskedQuestions={*/}
            {/*    props.allAskedQuestions &&*/}
            {/*    props.allAskedQuestions.allAskedQuestions &&*/}
            {/*    props.allAskedQuestions.allAskedQuestions.data &&*/}
            {/*    props.allAskedQuestions.allAskedQuestions.data.data &&*/}
            {/*    props.allAskedQuestions.allAskedQuestions.data.data.allFaqs &&*/}
            {/*    props.allAskedQuestions.allAskedQuestions.data.data.allFaqs.Faqs*/}
            {/*  }*/}
            {/*  askedQuestionsListStart={props.askedQuestionsListStart}*/}
            {/*/>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*//new program and prcing page below*/}
      <section className="plan-first-sec">
        <div className="container">
          <div className="top-block">
            <h1 className="sec-title">YOUR 1-ON-1 PERSONAL RECRUITER</h1>
            <p>Give Your Son The College Recruiting Advantage</p>
            <ul className="pricing-unlock-feature">
              <li>
                <a href="javascript:void(0);">
                            <span className="icon-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="111.883" height="110.344"
                                     viewBox="0 0 111.883 110.344">
                                    <g id="Group_2331" data-name="Group 2331" transform="translate(-397.555 -411.656)">
                                      <g id="Group_2330" data-name="Group 2330" transform="translate(-80)">
                                        <path id="Path_1182" data-name="Path 1182"
                                              d="M107.159,32.98A2.819,2.819,0,1,1,104.34,35.8a2.819,2.819,0,0,1,2.819-2.819"
                                              transform="translate(465.027 391.778)" fill="none" stroke="#00b9ff"
                                              stroke-width="2.8"/>
                                        <path id="Path_1183" data-name="Path 1183"
                                              d="M108.874,42h-3.6a2.226,2.226,0,0,0-2.226,2.226V48.18h8.035V44.226A2.226,2.226,0,0,0,108.874,42"
                                              transform="translate(465.359 389.451)" fill="none" stroke="#00b9ff"
                                              stroke-width="2.8"/>
                                        <path id="Path_1184" data-name="Path 1184"
                                              d="M97.537,20.13a4.927,4.927,0,1,1-4.927,4.927,4.927,4.927,0,0,1,4.927-4.927"
                                              transform="translate(465.945 395.094)" fill="none" stroke="#00b9ff"
                                              stroke-width="2.8"/>
                                        <path id="Path_1185" data-name="Path 1185"
                                              d="M114.231,17.17a5.416,5.416,0,1,1-5.417,5.416,5.416,5.416,0,0,1,5.417-5.416"
                                              transform="translate(466.191 395.886)" fill="none" stroke="#00b9ff"
                                              stroke-width="2.8"/>
                                        <path id="Path_1186" data-name="Path 1186"
                                              d="M98.951,36.59a4.255,4.255,0,0,1,.164-1.19H93.653a3.873,3.873,0,0,0-3.872,3.872v7.249h8.035V42.367a3.923,3.923,0,0,1,1.892-3.324,4.442,4.442,0,0,1-.743-2.463Z"
                                              transform="translate(466.675 391.154)" fill="none" stroke="#00b9ff"
                                              stroke-width="2.8"/>
                                        <path id="Path_1187" data-name="Path 1187"
                                              d="M118.667,33.7h-5.572a4.535,4.535,0,0,1-.1,4.771,4.006,4.006,0,0,1,1.9,3.383v4.2h8.035v-8.1a4.259,4.259,0,0,0-4.259-4.251"
                                              transform="translate(465.113 391.621)" fill="none" stroke="#00b9ff"
                                              stroke-width="2.8"/>
                                      </g>
                                      <g id="Group_2315" data-name="Group 2315" transform="translate(397.557 429.742)">
                                        <g id="Group_2314" data-name="Group 2314" transform="translate(0)">
                                          <path id="Path_2240" data-name="Path 2240"
                                                d="M89.721,158.065l-4.107-12.324a12.42,12.42,0,0,0-9.561-8.291L64.2,135.293v-.919a17.856,17.856,0,0,0,5.289-4.7h3.582a5.329,5.329,0,0,0,5.323-5.323v-21.29a23.065,23.065,0,0,0-46.129,0v12.419a7.108,7.108,0,0,0,5.658,6.95,17.775,17.775,0,0,0,8.536,11.94v.919L34.595,137.45a12.417,12.417,0,0,0-9.559,8.291l-.71,2.131-6.043-6.043a1.778,1.778,0,0,1,0-2.51l7.008-7.006L12.137,119.158l-7.92,7.92a12.419,12.419,0,0,0,0,17.563L28.2,168.619a12.413,12.413,0,0,0,17.508.053l8.642-7.918-2.695-2.69Zm-14.88-33.71a1.776,1.776,0,0,1-1.774,1.774H71.574a17.622,17.622,0,0,0,1.157-3.7,7.028,7.028,0,0,0,2.11-.839Zm-1.774-5.816v-6.11a3.518,3.518,0,0,1,0,6.11Zm-35.484,0a3.518,3.518,0,0,1,0-6.11Zm0-11.926v2.026a7.037,7.037,0,0,0-1.774.735v-6.309a19.516,19.516,0,1,1,39.032,0v6.309a6.973,6.973,0,0,0-1.774-.735v-2.026H71.292a19.52,19.52,0,0,1-13.89-5.754l-2.078-2.078-2.078,2.078a19.52,19.52,0,0,1-13.89,5.754Zm3.548,12.419v-8.938a23,23,0,0,0,14.194-6.305,22.993,22.993,0,0,0,14.194,6.305v8.938a14.087,14.087,0,0,1-1.92,7.1H55.325v3.548h9.357a14.171,14.171,0,0,1-23.551-10.645Zm19.516,16.924v.083l-5.323,5.323L50,136.04v-.083a17.728,17.728,0,0,0,10.645,0ZM28.4,146.862a8.874,8.874,0,0,1,6.829-5.924l12.4-2.253,7.695,7.695,7.693-7.693,12.4,2.253a8.871,8.871,0,0,1,6.829,5.924l2.553,7.652H48.1l-6.91-6.91-7.679,6.946a1.812,1.812,0,0,1-2.509,0l-3.875-3.875ZM12.135,124.176l8.136,8.136-1.927,1.927L10.209,126.1Zm31.11,41.938a8.873,8.873,0,0,1-12.545,0L6.722,142.134a8.873,8.873,0,0,1,0-12.545l.976-.976,8.136,8.136-.064.064a5.332,5.332,0,0,0,0,7.528l12.723,12.723a5.3,5.3,0,0,0,3.765,1.56,5.224,5.224,0,0,0,3.7-1.5l.133-.119,8.174,8.174Zm3.637-3.335-8.156-8.156,2.344-2.118,8.142,8.142Z"
                                                transform="translate(-0.58 -80)" fill="#00b9ff"/>
                                        </g>
                                      </g>
                                      <g id="Group_2325" data-name="Group 2325" transform="translate(397.555 417.323)">
                                        <g id="Group_2324" data-name="Group 2324">
                                          <path id="Path_2242" data-name="Path 2242"
                                                d="M55.318,24A49.646,49.646,0,0,0,6.977,62.4L3.615,56.8.572,58.623,6.808,69.015,17.2,62.779l-1.826-3.043-4.808,2.885A46.141,46.141,0,0,1,68.987,29.606l1.05-3.389A49.645,49.645,0,0,0,55.318,24Z"
                                                transform="translate(-0.572 -24)" fill="#00b9ff"/>
                                        </g>
                                      </g>
                                      <g id="Group_2327" data-name="Group 2327" transform="translate(488.142 445.048)">
                                        <g id="Group_2326" data-name="Group 2326">
                                          <path id="Path_2243" data-name="Path 2243"
                                                d="M427.333,155.252l-10.391-6.236-6.235,10.393,3.043,1.826,3.1-5.175a46.168,46.168,0,0,1-7.819,43.952l2.755,2.235a49.684,49.684,0,0,0,8.5-47.08l5.214,3.128Z"
                                                transform="translate(-409.036 -149.016)" fill="#00b9ff"/>
                                        </g>
                                      </g>
                                    </g>
                                </svg>
                            </span>
                  Personalized Recruiting Guidance
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                            <span className="icon-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="98.562" height="114.446"
                                     viewBox="0 0 98.562 114.446">
                                    <g id="Group_57835" data-name="Group 57835" transform="translate(-942.75 -353.711)">
                                      <g id="Group_2332" data-name="Group 2332" transform="translate(944 441.386)">
                                        <path id="Path_1169" data-name="Path 1169"
                                              d="M37.434,83.4h76.341a2.282,2.282,0,0,1,2.234,2.249v21.022a2.282,2.282,0,0,1-2.234,2.249H37.434a2.283,2.283,0,0,1-2.234-2.249V85.649A2.226,2.226,0,0,1,37.434,83.4"
                                              transform="translate(-35.2 -83.4)" fill="#fff" stroke="#00b9ff"
                                              stroke-width="2.5"/>
                                        <path id="Path_1171" data-name="Path 1171"
                                              d="M112.971,95.677H63.852a1.513,1.513,0,0,1,0-3.026h49.119a1.513,1.513,0,1,1,0,3.026"
                                              transform="translate(-39.034 -84.648)" fill="#00b9ff"/>
                                        <path id="Path_1172" data-name="Path 1172"
                                              d="M112.971,102.977H63.852a1.513,1.513,0,1,1,0-3.026h49.119a1.513,1.513,0,0,1,0,3.026"
                                              transform="translate(-39.034 -85.632)" fill="#00b9ff"/>
                                        <path id="Path_1173" data-name="Path 1173"
                                              d="M50.264,103.5l-.311,1.254h.622v2.192H47v-2.192h.777L50.264,93.8H52.44l2.487,10.956H55.7v2.192H52.13v-2.192h.622L52.44,103.5Zm1.71-2.19L51.352,98.5l-.622,2.817Z"
                                              transform="translate(-38.814 -87.744)" fill="#00b9ff"/>
                                      </g>
                                      <g id="e-mail" transform="translate(961.288 354)">
                                        <g id="Layer_2_00000147935678081845046720000006946314409726428092_"
                                           transform="translate(0 0)">
                                          <g id="Layer_1-2_00000031195242420224951830000005702998532892433805_"
                                             transform="translate(0)">
                                            <path id="e-mail_marketing"
                                                  d="M94.089,33.385H80.67c-.091-.485.2-8.209-.155-8.471a1.66,1.66,0,0,0-.341-.417l-10.238-7.94V9.975a1.289,1.289,0,0,0-1.289-1.289H59.781L48.925.264a1.308,1.308,0,0,0-1.577,0L36.489,8.685H27.627a1.289,1.289,0,0,0-1.285,1.289v6.582c-.023.03-10.26,7.928-10.264,7.97a1.17,1.17,0,0,0-.319.379,1.357,1.357,0,0,0-.159.61V57.985c0,1.043,1.138,1.289,1.285,1.289h13.43V74.627A1.289,1.289,0,0,0,31.6,75.913h9.453v7.735a1.292,1.292,0,0,0,1.983,1.1L56.8,75.928H94.089a1.285,1.285,0,0,0,1.285-1.285V34.67A1.281,1.281,0,0,0,94.1,33.385ZM60.539,41.753,78.084,28.141V55.358ZM77.284,25.517,69.932,31.2V19.81ZM48.137,2.915,55.58,8.686H40.69Zm19.216,8.342V33.214l-8.907,6.909-9.521-7.386a1.285,1.285,0,0,0-1.577,0l-9.525,7.386-8.907-6.909V11.26Zm-31.63,30.5-17.548,13.6V28.141ZM26.342,19.81V31.216l-7.356-5.7ZM48.137,35.383,75.627,56.7H68.642a1.289,1.289,0,0,0-1.289,1.289v6.666l-12.1-7.75a1.277,1.277,0,0,0-.69-.2H20.647ZM92.815,73.338h-36.4a1.294,1.294,0,0,0-.7.2l-12.1,7.746V74.627a1.285,1.285,0,0,0-1.282-1.289H32.894V59.275H54.188l13.76,8.812a1.331,1.331,0,0,0,1.376,0A1.282,1.282,0,0,0,69.932,67V59.275h9.453a1.357,1.357,0,0,0,1.285-1.289V35.956H92.8ZM35.981,24.364H60.3a1.289,1.289,0,0,1,0,2.575H35.981a1.289,1.289,0,0,1,0-2.575Zm0-7.553H52.539a1.285,1.285,0,1,1,0,2.571H35.981a1.285,1.285,0,1,1,0-2.571Z"
                                                  transform="translate(-15.6 0.001)" fill="#00b9ff" stroke="#00b9ff"
                                                  stroke-width="0.5"/>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                </svg>
                            </span>
                  Message System and College Views
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                            <span className="icon-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="93.894" height="110.908"
                                     viewBox="0 0 93.894 110.908">
                                    <g id="Group_57836" data-name="Group 57836"
                                       transform="translate(-1218.75 -354.746)">
                                      <g id="Group_2335" data-name="Group 2335" transform="translate(1246.705 381.636)">
                                        <path id="Path_1198" data-name="Path 1198"
                                              d="M103.09,83.717,97.366,86.2a5.485,5.485,0,0,1-3.789,0l-5.724-2.48L83.5,81.832l-.113-.045a11.516,11.516,0,0,0-.22,2.364,11.359,11.359,0,0,0,3.585,8.6,11.729,11.729,0,0,0,8.6,3.584,11.357,11.357,0,0,0,8.6-3.585,11.73,11.73,0,0,0,3.585-8.6,11.313,11.313,0,0,0-.173-2.283Z"
                                              transform="translate(-76.194 -61.516)" fill="#fff" stroke="#00b9ff"
                                              stroke-width="3"/>
                                        <path id="Path_1199" data-name="Path 1199"
                                              d="M110.228,65.377c1.04-.453,1.04-1.19,0-1.641L94.846,57.08a5.478,5.478,0,0,0-3.788,0L75.677,63.736c-1.041.453-1.041,1.189,0,1.641l1.165.5-.1.782a.946.946,0,0,0-.131,1.833l-.707,9.911a4.54,4.54,0,0,0,.183,1.467l.576,1.722c.064.2.151.3.238.3s.171-.1.237-.3l.576-1.722a4.52,4.52,0,0,0,.184-1.467L77.192,68.5a.945.945,0,0,0-.134-1.833l-.09-.729L79.659,67.1l1.319.573v5.086l4.356,1.884,5.725,2.479a5.483,5.483,0,0,0,3.788,0l5.725-2.479,4.355-1.886V67.672l1.318-.573Z"
                                              transform="translate(-73.914 -56.742)" fill="#fff" stroke="#00b9ff"
                                              stroke-width="3"/>
                                        <path id="Path_1200" data-name="Path 1200"
                                              d="M73.54,114.822a26.033,26.033,0,0,0,8.9,6.123A28.393,28.393,0,0,0,92.7,123.081a23.852,23.852,0,0,0,10.255-2.136,26.055,26.055,0,0,0,9.614-6.9c-1.441-2.611-5.794-9.055-13.658-9.055H86.721a11.491,11.491,0,0,0-8.125,2.629,13.459,13.459,0,0,0-5.02,6.93Z"
                                              transform="translate(-73.54 -65.774)" fill="#fff" stroke="#00b9ff"
                                              stroke-width="3"/>
                                      </g>
                                      <g id="shield" transform="translate(1220 356)">
                                        <g id="Group_2334" data-name="Group 2334" transform="translate(0)">
                                          <path id="Path_2244" data-name="Path 2244"
                                                d="M129.3,12.89,86.778.134a3.174,3.174,0,0,0-1.825,0L42.431,12.89a3.176,3.176,0,0,0-2.263,3.042V62.706c0,6.084,2.463,12.311,7.32,18.51a75.675,75.675,0,0,0,15.254,14.13,136.848,136.848,0,0,0,21.825,12.78,3.174,3.174,0,0,0,2.6,0,136.851,136.851,0,0,0,21.825-12.78,75.68,75.68,0,0,0,15.254-14.13c4.857-6.2,7.32-12.426,7.32-18.51V15.932A3.175,3.175,0,0,0,129.3,12.89Zm-4.089,49.815c0,10.767-10.8,20.862-19.865,27.436a134.472,134.472,0,0,1-19.48,11.577,134.471,134.471,0,0,1-19.48-11.577C57.323,83.568,46.52,73.473,46.52,62.706V18.3l39.346-11.8,39.345,11.8Z"
                                                transform="translate(-40.168 0)" fill="#00b9ff" stroke="#fff"
                                                stroke-width="2.5"/>
                                        </g>
                                      </g>
                                    </g>
                                </svg>
                            </span>
                  Access Through Graduation
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="plans-list-sec">
        <div className="container">
          <div className="top-block">
            {/*<h3 className="title">Plans provide full platform access through graduation. Available for purchase as a*/}
            {/*  one-time payment (Save 16%) or in 12 monthly installments.</h3>*/}
            {/*<h4 className="sub-title">Number Of Monthly Payments:</h4>*/}
            {/*<ul className="number-payment-type">*/}
            {/*  <li className="item active">12 Payments</li>*/}
            {/*  <li className="item">6 Payments</li>*/}
            {/*  <li className="item">1 One Time Payment (10% Saving)</li>*/}
            {/*</ul>*/}
            {/*<div className="plans-listing owl-carousel">*/}
              <OwlCarousel
              // className="plans-listing owl-carousel"
              {...optionCarousel}
              >
                {/*{UpgradePlanData?.length > 0 ? UpgradePlanData?.map((plan, idx)=>{*/}
                {/*  return(*/}
                {/*slide 1*/}
                      <div
                          className={'item'}
                          // className={mouseIdx == idx ? "item" :"item"}
                      >
                        <div className="plan-detail">
                          <div className="plan-type">Game Plan Pro</div>
                          <div className="price">
                            $166
                            <sub>/month</sub>
                            {/*{plan?.planname ?? ""}*/}
                          </div>
                          <div className="total-price">
                            {/*program*/}
                            $1,995 total
                          </div>
                          <div className="short-text">
                            <p>
                              {/*{plan?.description ?? ""}*/}
                              Kickstart the recruiting process with full platform access, a highlight reel, and one strategy
                              session.
                            </p>
                          </div>
                          <div className="plan-button">
                            <a className="btn" href={void 0} onClick={()=>window.open(
                                'https://calendly.com/sportsforce/recruiting-assessment-round-robin','_blank'
                            )}>View All Features</a>
                          </div>
                          {/*<div className="view-feature-text">View All Features</div>*/}
                        </div>
                      </div>
                {/*slider 2*/}
                <div
                    className={'item active'}
                    // className={mouseIdx == idx ? "item" :"item"}
                >
                  <div className="plan-detail">
                    <div className="plan-type">Premier</div>
                    <div className="price">
                      $333
                      <sub>/month</sub>
                      {/*{plan?.planname ?? ""}*/}
                    </div>
                    <div className="total-price">
                      {/*program*/}
                      $3,995 total
                    </div>
                    <div className="short-text">
                      <p>
                        {/*{plan?.description ?? ""}*/}
                        Kickstart the recruiting process with full platform access, a highlight reel, and one strategy
                        session.
                      </p>
                    </div>
                    <div className="plan-button">
                      <a className="btn" href={void 0} onClick={()=>window.open(
                          'https://calendly.com/sportsforce/recruiting-assessment-round-robin','_blank'
                      )}>View All Features</a>
                    </div>
                    {/*<div className="view-feature-text">View All Features</div>*/}
                  </div>
                </div>
                {/*slider 3*/}
                <div
                    className={'item'}
                    // className={mouseIdx == idx ? "item" :"item"}
                >
                  <div className="plan-detail">
                    <div className="plan-type">Premier Pro</div>
                    <div className="price">
                      $499
                      <sub>/month</sub>
                      {/*{plan?.planname ?? ""}*/}
                    </div>
                    <div className="total-price">
                      {/*program*/}
                      $5,995 total
                    </div>
                    <div className="short-text">
                      <p>
                        {/*{plan?.description ?? ""}*/}
                        Kickstart the recruiting process with full platform access, a highlight reel, and one strategy
                        session.
                      </p>
                    </div>
                    <div className="plan-button">
                      <a className="btn" href={void 0} onClick={()=>window.open(
                          'https://calendly.com/sportsforce/recruiting-assessment-round-robin','_blank'
                      )}>View All Features</a>
                    </div>
                    {/*<div className="view-feature-text">View All Features</div>*/}
                  </div>
                </div>
                {/*//   )*/}
                {/*//     })*/}
                {/*//   :""*/}
                {/*// }*/}
                {/*<div className="item active">*/}
                {/*  <div className="plan-detail">*/}
                {/*    /!*<div className="plan-type">Premier</div>*!/*/}
                {/*    <div className="price">*/}
                {/*      1-ON-1*/}
                {/*      /!*$333<sub>/month</sub>*!/*/}
                {/*    </div>*/}
                {/*    <div className="total-price">*/}
                {/*      program*/}
                {/*      /!*$3,995 total*!/*/}
                {/*    </div>*/}
                {/*    <div className="short-text">*/}
                {/*      <p>Kickstart the recruiting process with full platform access, a highlight reel, and one strategy*/}
                {/*        session.</p>*/}
                {/*    </div>*/}
                {/*    <div className="plan-button">*/}
                {/*      <a className="btn" href="#">Select</a>*/}
                {/*    </div>*/}
                {/*    <div className="view-feature-text">View All Features</div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div className="item">*/}
                {/*  <div className="plan-detail">*/}
                {/*    /!*<div className="plan-type">Premier Pro</div>*!/*/}
                {/*    <div className="price">*/}
                {/*      Future*/}
                {/*      /!*$499<sub>/months</sub>*!/*/}
                {/*    </div>*/}
                {/*    <div className="total-price">*/}
                {/*      program*/}
                {/*      /!*$5,995 total*!/*/}
                {/*    </div>*/}
                {/*    <div className="short-text">*/}
                {/*      <p>Kickstart the recruiting process with full platform access, a highlight reel, and one strategy*/}
                {/*        session.</p>*/}
                {/*    </div>*/}
                {/*    <div className="plan-button">*/}
                {/*      <a className="btn" href="#">Select</a>*/}
                {/*    </div>*/}
                {/*    <div className="view-feature-text">View All Features</div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </OwlCarousel>
            {/*</div>*/}
          </div>
        </div>
      </section>
      <section className="plan-feature-sec">
        <div className="container">
          <h4 className="sec-title">Plan Features</h4>
          <div className="plan-feature-table">
            <table className="table table-bordered">
              <tr>
                <th className="title">COLLEGE RECRUITING ASSESSMENT</th>
                <th>Game Plan Pro</th>
                <th className="active">Premier</th>
                <th>Premier Pro</th>
              </tr>
              <tr>
                <td>Scouting Video Evaluation</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>College Target List Development</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>College Game Plan Evaluation</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td className="title">COLLEGE RECRUITING TOOLS</td>
                <td>&nbsp;</td>
                <td className="active">&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Personal Recruiting Profile</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Expert Resume Analysis</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>College Coach Search System</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Target List Tutorial</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td className="title">SHOWCASE VIDEO PLAN</td>
                <td>&nbsp;</td>
                <td className="active">&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Video Shooting Tutorial</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Expert Analysis & Sequencing</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Highlight Video Editing / Updates</td>
                <td>1 Highlight Video</td>
                <td className="active">1 Highlight Video</td>
                <td>1 Highlight Video</td>
              </tr>
              <tr>
                <td className="title">DIRECT TO COLLEGE COACH MARKETING</td>
                <td>&nbsp;</td>
                <td className="active">&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Recruiting Email Letter Templates</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Email Draft Assistance & Proofreading</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Direct to Coach Marketing Campaigns</td>
                <td>Up to 2 per year</td>
                <td className="active">Up to 4 per year</td>
                <td>Up to 8 per year</td>
              </tr>
              <tr>
                <td>Coach Viewership Reports</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td className="title">SOCIAL MEDIA EXPOSURE</td>
                <td>&nbsp;</td>
                <td className="active">&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Feature Player Tweets</td>
                <td>&nbsp;</td>
                <td className="active">Up to 4 per year</td>
                <td>Up to 8 per year</td>
              </tr>
              <tr>
                <td className="title">1-ON-1 PERSONAL GUIDANCE</td>
                <td>&nbsp;</td>
                <td className="active">&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>1-on-1 Expert Guidance</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Coaching Call & Recruiting Assessment</td>
                <td>2</td>
                <td className="active">Every 90 Days</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Showcase/Camp Evaluation & Strategy</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Email Support (M-F 9-5PM PST)</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Recruiting Workshop & Video Library</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Year-Round Action Guides</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td className="title">DIRECT TO COLLEGE COACH OUTREACH</td>
                <td>&nbsp;</td>
                <td className="active">&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Individual Scouting Reports</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Advisor Recommendation Letter</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Recruiting Needs Timeline & Analysis</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td>Personal College Coach Engagement</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td className="title">PERSONAL ACADEMIC COUNSELING</td>
                <td>&nbsp;</td>
                <td className="active">&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Access to College Planning Workshops</td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td className="active">
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
                <td>
                            <span className="check-icon">
                                <svg id="Component_15_4" data-name="Component 15 – 4" xmlns="http://www.w3.org/2000/svg"
                                     width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2234" data-name="Path 2234"
                                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                                          fill="#1cb79e" fill-rule="evenodd"/>
                                </svg>
                            </span>
                </td>
              </tr>
              <tr>
                <td className="title">TOTAL INVESTMENT</td>
                <th>$1,995</th>
                <th className="active">$3,995</th>
                <th>$5,995</th>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                  <button className="btn">Select</button>
                </td>
                <td className="active">
                  <button className="btn">Select</button>
                </td>
                <td>
                  <button className="btn">Select</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
      <section className="testimonial-sec">
        <div className="top-block">
          <h3 className="title">Trusted and Loved by the world's best</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas rhoncus sed leo a faucibus. Sed dapibus,
            odio a</p>
        </div>
        {/*<div id="testimonial-slider" className="testimonial-slider owl-carousel">*/}
          <OwlCarousel
              className="testimonial-slider owl-carousel owl-theme"
              {...helpSlide}
          >
            <div className="slide-item" style={{margin:"10px"}}>
              <div className="testimonial-img">
                <img src={testimonial1} alt=""/>
              </div>
              <div className="testimonial-detail">
                <div className="short-text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas rhoncus sed leo a faucibus. Sed
                    dapibus, odio a</p>
                </div>
                <h5 className="name">Lorem ipsum</h5>
                <h6 className="designation">Lorem ipsum</h6>
              </div>
            </div>
            <div className="slide-item" style={{margin:"10px"}}>
              <div className="testimonial-img">
                <img src={testimonial2} alt=""/>
              </div>
              <div className="testimonial-detail">
                <div className="short-text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas rhoncus sed leo a faucibus. Sed
                    dapibus, odio a</p>
                </div>
                <h5 className="name">Lorem ipsum</h5>
                <h6 className="designation">Lorem ipsum</h6>
              </div>
            </div>
            <div className="slide-item" style={{margin:"10px"}}>
              <div className="testimonial-img">
                <img src={testimonial1} alt=""/>
              </div>
              <div className="testimonial-detail">
                <div className="short-text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas rhoncus sed leo a faucibus. Sed
                    dapibus, odio a</p>
                </div>
                <h5 className="name">Lorem ipsum</h5>
                <h6 className="designation">Lorem ipsum</h6>
              </div>
            </div>
            <div className="slide-item" style={{margin:"10px"}}>
              <div className="testimonial-img">
                <img src={testimonial2} alt=""/>
              </div>
              <div className="testimonial-detail">
                <div className="short-text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas rhoncus sed leo a faucibus. Sed
                    dapibus, odio a</p>
                </div>
                <h5 className="name">Lorem ipsum</h5>
                <h6 className="designation">Lorem ipsum</h6>
              </div>
            </div>
            <div className="slide-item" style={{margin:"10px"}}>
              <div className="testimonial-img">
                <img src={testimonial1} alt=""/>
              </div>
              <div className="testimonial-detail">
                <div className="short-text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas rhoncus sed leo a faucibus. Sed
                    dapibus, odio a</p>
                </div>
                <h5 className="name">Lorem ipsum</h5>
                <h6 className="designation">Lorem ipsum</h6>
              </div>
            </div>
            <div className="slide-item" style={{margin:"10px"}}>
              <div className="testimonial-img">
                <img src={testimonial2} alt=""/>
              </div>
              <div className="testimonial-detail">
                <div className="short-text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas rhoncus sed leo a faucibus. Sed
                    dapibus, odio a</p>
                </div>
                <h5 className="name">Lorem ipsum</h5>
                <h6 className="designation">Lorem ipsum</h6>
              </div>
            </div>
          </OwlCarousel>
        {/*</div>*/}
      </section>
      <section className="content-and-recruiting-sec">
        <div className="container">
          <div className="content-with-video-sec">
            <div className="help-box">
              <div className="item">
                <div className="help-img">
                  <img src={helpIMG} alt=""/>
                </div>
                <h3>We’re here to help.<br/> Schedule a call to discuss your family’s needs.</h3>
                <div className="button-box">
                  <a className="btn green" href="javascript:void(0);">Schedule Call</a>
                </div>
              </div>
            </div>
            <div className="video-box">
              <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
              <div className="img-box" onClick="myvideopopup($(this).attr('data-id'))" data-id="aqz-KE-bpKQ"
                   href="#aqz-KE-bpKQ">
                <video poster={videoThumb}>
                  <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>
                  <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>
                </video>
                <div data-toggle="modal" data-target="#VideoPricingModal" className="video-play-button">
                  <div className="video-play-inner">
                    <button>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20.802" height="26.203"
                           viewBox="0 0 10.802 16.203">
                        <defs>
                          <style>
                            {/*.a {fill: #fff;}*/}
                          </style>
                        </defs>
                        <path className="a" d="M8.1,0l8.1,10.8H0Z" transform="translate(10.802) rotate(90)"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="recruiting-guidance-sec">
            <div className="icon-box">
              <svg xmlns="http://www.w3.org/2000/svg" width="152.133" height="150.029" viewBox="0 0 152.133 150.029">
                <g id="Group_2358" data-name="Group 2358" transform="translate(-397.555 -411.956)">
                  <g id="Group_2330" data-name="Group 2330" transform="translate(505.414 413.056)">
                    <path id="Path_1182" data-name="Path 1182"
                          d="M108.194,32.98a3.854,3.854,0,1,1-3.854,3.854,3.854,3.854,0,0,1,3.854-3.854"
                          transform="translate(-86.69 -16.983)" fill="none" stroke="#fff" stroke-width="2.2"/>
                    <path id="Path_1183" data-name="Path 1183"
                          d="M111.012,42h-4.92a3.043,3.043,0,0,0-3.043,3.043v5.406h10.984V45.043A3.043,3.043,0,0,0,111.012,42"
                          transform="translate(-86.709 -16.854)" fill="none" stroke="#fff" stroke-width="2.2"/>
                    <path id="Path_1184" data-name="Path 1184"
                          d="M99.345,20.13a6.735,6.735,0,1,1-6.735,6.735,6.735,6.735,0,0,1,6.735-6.735"
                          transform="translate(-89.741 -17.166)" fill="none" stroke="#fff" stroke-width="2.2"/>
                    <path id="Path_1185" data-name="Path 1185"
                          d="M116.219,17.17a7.4,7.4,0,1,1-7.4,7.4,7.4,7.4,0,0,1,7.4-7.4"
                          transform="translate(-83.456 -17.17)" fill="none" stroke="#fff" stroke-width="2.2"/>
                    <path id="Path_1186" data-name="Path 1186"
                          d="M102.316,37.026a5.816,5.816,0,0,1,.225-1.626H95.074a5.294,5.294,0,0,0-5.293,5.293V50.6h10.984V44.924a5.363,5.363,0,0,1,2.587-4.544,6.073,6.073,0,0,1-1.016-3.367Z"
                          transform="translate(-89.781 -16.949)" fill="none" stroke="#fff" stroke-width="2.2"/>
                    <path id="Path_1187" data-name="Path 1187"
                          d="M120.75,33.7h-7.617a6.2,6.2,0,0,1-.141,6.522,5.476,5.476,0,0,1,2.6,4.625v5.741h10.984V39.51A5.821,5.821,0,0,0,120.75,33.7"
                          transform="translate(-83.397 -16.934)" fill="none" stroke="#fff" stroke-width="2.2"/>
                  </g>
                  <g id="Group_2315" data-name="Group 2315" transform="translate(397.557 435.866)">
                    <g id="Group_2314" data-name="Group 2314" transform="translate(0)">
                      <path id="Path_2240" data-name="Path 2240"
                            d="M122.438,186.716l-5.615-16.847a16.978,16.978,0,0,0-13.07-11.334l-16.209-2.949V154.33a24.41,24.41,0,0,0,7.23-6.42h4.9a7.285,7.285,0,0,0,7.276-7.276v-29.1a31.53,31.53,0,1,0-63.06,0v16.978a9.717,9.717,0,0,0,7.735,9.5A24.3,24.3,0,0,0,63.29,154.33v1.256l-16.211,2.949A16.975,16.975,0,0,0,34.011,169.87l-.97,2.913-8.261-8.261a2.43,2.43,0,0,1,0-3.432l9.58-9.578L16.379,133.53,5.552,144.357a16.978,16.978,0,0,0,0,24.009l32.779,32.779a16.969,16.969,0,0,0,23.934.073l11.814-10.824-3.684-3.677ZM102.1,140.634a2.428,2.428,0,0,1-2.425,2.425h-2.04a24.09,24.09,0,0,0,1.581-5.052,9.607,9.607,0,0,0,2.884-1.147Zm-2.425-7.95v-8.353a4.809,4.809,0,0,1,0,8.353Zm-48.507,0a4.809,4.809,0,0,1,0-8.353Zm0-16.3v2.77a9.62,9.62,0,0,0-2.425,1V111.53a26.679,26.679,0,1,1,53.358,0v8.625a9.531,9.531,0,0,0-2.425-1v-2.77H97.246a26.685,26.685,0,0,1-18.988-7.865l-2.84-2.84-2.84,2.84a26.685,26.685,0,0,1-18.988,7.865Zm4.851,16.978V121.139a31.445,31.445,0,0,0,19.4-8.62,31.432,31.432,0,0,0,19.4,8.62v12.219a19.257,19.257,0,0,1-2.624,9.7H75.417v4.851H88.209a19.372,19.372,0,0,1-32.194-14.552Zm26.679,23.136v.114l-7.276,7.276-7.276-7.276v-.114a24.234,24.234,0,0,0,14.552,0ZM38.61,171.4a12.131,12.131,0,0,1,9.335-8.1l16.953-3.08,10.519,10.519,10.516-10.516,16.951,3.08a12.127,12.127,0,0,1,9.335,8.1l3.49,10.461H65.544L56.1,172.419l-10.5,9.5a2.477,2.477,0,0,1-3.429,0l-5.3-5.3ZM16.376,140.389,27.5,151.512l-2.634,2.634L13.742,143.023ZM58.905,197.72a12.129,12.129,0,0,1-17.15,0L8.977,164.939a12.129,12.129,0,0,1,0-17.15l1.334-1.334,11.123,11.123-.087.087a7.288,7.288,0,0,0,0,10.291l17.392,17.392a7.238,7.238,0,0,0,5.147,2.132,7.141,7.141,0,0,0,5.057-2.047l.182-.163L60.3,196.444Zm4.972-4.56L52.728,182.011l3.2-2.9,11.13,11.13Z"
                            transform="translate(-0.58 -80)" fill="#fff"/>
                    </g>
                  </g>
                  <g id="Group_2325" data-name="Group 2325" transform="translate(397.555 418.888)">
                    <g id="Group_2324" data-name="Group 2324">
                      <path id="Path_2242" data-name="Path 2242"
                            d="M75.412,24A67.867,67.867,0,0,0,9.328,76.5l-4.6-7.662-4.16,2.5L9.1,85.537,23.3,77.011l-2.5-4.16L14.234,76.8A63.076,63.076,0,0,1,94.1,31.664l1.436-4.632A67.866,67.866,0,0,0,75.412,24Z"
                            transform="translate(-0.572 -24)" fill="#fff"/>
                    </g>
                  </g>
                  <g id="Group_2327" data-name="Group 2327" transform="translate(521.389 456.79)">
                    <g id="Group_2326" data-name="Group 2326">
                      <path id="Path_2243" data-name="Path 2243"
                            d="M434.049,157.541l-14.205-8.525-8.523,14.208,4.16,2.5,4.244-7.075a63.113,63.113,0,0,1-10.689,60.084l3.767,3.056a67.919,67.919,0,0,0,11.622-64.36l7.128,4.276Z"
                            transform="translate(-409.036 -149.016)" fill="#fff"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="content-box">
              <h3 className="title">Looking for more guidance in your recruiting process?</h3>
              <div className="short-text">
                <p>If you are looking for guidance and support throughout your recruiting process check out our
                  recruiting guidance plans which also provide platform access through graduation.</p>
                <p><a className="read-more-link" href="#">See Recruiting Guidance Options &gt;</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<section className="athlete-faq-sec gray-bg">*/}
      {/*  <div className="container">*/}
      {/*    <div className="faq-box-wrapper">*/}
      {/*      <h1 className="sec-title">Frequently Asked Questions</h1>*/}
      {/*      <ul className="faq-listing">*/}
      {/*        <li className="active">*/}
      {/*          <div className="faq-title active">Lorem Ipsum is placeholder text commonly used in the graphic,print?*/}
      {/*          </div>*/}
      {/*          <div className="faq-data" style={{display: "block"}}>*/}
      {/*            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the*/}
      {/*              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type*/}
      {/*              and scrambled it to make a type specimen book. </p>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="faq-title">Lorem Ipsum is placeholder text commonly used in the graphic,print?</div>*/}
      {/*          <div className="faq-data">*/}
      {/*            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the*/}
      {/*              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type*/}
      {/*              and scrambled it to make a type specimen book. </p>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="faq-title">Lorem Ipsum is placeholder text commonly used in the graphic,print?</div>*/}
      {/*          <div className="faq-data">*/}
      {/*            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the*/}
      {/*              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type*/}
      {/*              and scrambled it to make a type specimen book. </p>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <div className="faq-title">Lorem Ipsum is placeholder text commonly used in the graphic,print?</div>*/}
      {/*          <div className="faq-data">*/}
      {/*            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the*/}
      {/*              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type*/}
      {/*              and scrambled it to make a type specimen book. </p>*/}
      {/*          </div>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <FAQ
          membershipTab={membershipTab}
          allAskedQuestions={
            props.allAskedQuestions &&
            props.allAskedQuestions.allAskedQuestions &&
            props.allAskedQuestions.allAskedQuestions.data &&
            props.allAskedQuestions.allAskedQuestions.data.data &&
            props.allAskedQuestions.allAskedQuestions.data.data.allFaqs &&
            props.allAskedQuestions.allAskedQuestions.data.data.allFaqs.Faqs
          }
          askedQuestionsListStart={props.askedQuestionsListStart}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    subscriptionPlanData: state.athlete.dashboard.upgradePlans,
    allAskedQuestions: state.athlete.account.allAskedQuestions.data,
    allVideoPhotoInfo: state.athlete.editUserProfileData.allVideoPhotoInfo,
    planFeatures: state.athlete.dashboard.upgradePlans.planFeatures,
    allMemberships : state.athlete.account.allMemberships,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    upgradePlanListStart: (data) => dispatch(upgradePlanListStart(data)),
    askedQuestionsListStart: (data) => dispatch(askedQuestionsListStart(data)),
    allVideoPhotoListStart: (data) => dispatch(allVideoPhotoListStart(data)),
    featuresListStart: (data) => dispatch(featuresListStart(data)),
    membershipListStart: (data) => dispatch(membershipListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramAndPricing);
