import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";
import { imageDownloadUrl } from "../../../../config";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  POST_LIKE_UNLIKE_START: "POST_LIKE_UNLIKE_START",
  POST_LIKE_UNLIKE_SUCCESS: "POST_LIKE_UNLIKE_SUCCESS",
  POST_LIKE_UNLIKE_FAILURE: "POST_LIKE_UNLIKE_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const postLikeUnlikeStart = (data) => ({
  type: actionType.POST_LIKE_UNLIKE_START,
  payload: data,
});

export const postLikeUnlikeSuccess = (data) => ({
  type: actionType.POST_LIKE_UNLIKE_SUCCESS,
  payload: data,
});

export const postLikeUnlikeFailure = (data) => ({
  type: actionType.POST_LIKE_UNLIKE_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function postLikeUnlikeReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.POST_LIKE_UNLIKE_START:
      return { ...state, loading: true };

    case actionType.POST_LIKE_UNLIKE_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.POST_LIKE_UNLIKE_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* postLikeUnlikeSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200) {
      const saveUserpostlike = response.data
        ? response.data.data
          ? response.data.data.saveUserpostlike
          : null
        : null;
      if (saveUserpostlike && saveUserpostlike.id) {
        if (action.payload.type === "like") {
          const modifiedData = action.payload.initialData.map((item) => {
            if (item.serialNo === action.payload.variables.obj.postactivityid) {
              item.isPostLiked = 1;
              item.totalLikeCount = item.totalLikeCount + 1;
              item.postLikeId = saveUserpostlike.id;
            }
            return item;
          });
          action.payload.setPostData(modifiedData);
        }
        else{
          action.payload.refreshPage();
        }
      } else {
        const errMsg =
          action.payload.type === "like"
            ? "Post like failed"
            : messagePopup("", errMsg, "error");
        yield put(postLikeUnlikeFailure("Failed, Error occured"));
        action.payload.refreshPage();
      }
    } else {
      const errMsg =
        action.payload.type === "like"
          ? "Post like failed"
          : messagePopup("", errMsg, "error");
      yield put(postLikeUnlikeFailure("Failed, Error occured"));
      action.payload.refreshPage();
    }
  } catch (error) {
    const errMsg =
      action.payload.type === "like"
        ? "Post like failed"
        : messagePopup("", errMsg, "error");
    yield put(postLikeUnlikeFailure("Failed, Error occured"));
    action.payload.refreshPage();
  }
}

export function* watchPostLikeUnlike() {
  yield takeEvery(actionType.POST_LIKE_UNLIKE_START, postLikeUnlikeSaga);
}
