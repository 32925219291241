import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SideBarAthleteColleges from './SideBarAthleteColleges';
import { collegeAcademicsListStart } from '../../../../../../redux/athlete/dashboard/colleges/collegeAcademics';
import { getAllAcademicsDataQuery, majorsQuery } from './CollegeDetailsQuery';
import BackdropLoader from "../../../../../common/Loader";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../../services/httpService";
import {Link} from "react-router-dom";

const Academics = (props) => {
  const [academicsData, setAcademicsData] = useState(null);
  const [mejorData, setMejorData] = useState(null);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    getAllAcademicsData();
  }, [])

  useEffect(() => {
    if (props.allAcademics.data && props.allAcademics.data[0]) {
      setAcademicsData(props.allAcademics.data[0])
    }
  }, [props.allAcademics.data])
  const getAllAcademicsDetails = () => {
    const getCollegeId = window.location.href.split("/")
    const collegeDetailsId = getCollegeId != "" ? getCollegeId[getCollegeId.length - 2] : null
    props.collegeAcademicsListStart({
      query: getAllAcademicsDataQuery,
      variables: {
        where: {
          collegeid: +collegeDetailsId
        }
      }
    })
  }
  const getAllAreaOfStudyOfCoolege = async () => {
    const getCollegeIdA = window.location.href.split("/")
    const collegeDetailsIdA = getCollegeIdA != "" ? getCollegeIdA[getCollegeIdA.length - 2] : null
    setLoader(true)
    const variables = {
      where: {
        collegeid: +collegeDetailsIdA
      },
    };
    let res = await fetchGraphMethod(
      majorsQuery,
      variables,
      true
    )
    if (res.status == 200) {
      let allData = res.data.data.allCollegeareaofstudies.Collegeareaofstudies
      if (allData != "") {
        let allMajorArray = []
        allData.map(item => {
          allMajorArray.push(item.collegeareaofstudybranchnamerel.Areaofstudies[0].areaofstudyname)
        })
        setMejorData(allMajorArray)
        setLoader(false)
      }
    } else {
      setLoader(false)
    }
    setLoader(false)
  }

  const getAllAcademicsData = async () => {
    await getAllAcademicsDetails()
    await getAllAreaOfStudyOfCoolege()

  }
  return (
    <div id="Academics">
      <BackdropLoader open={props.allAcademics.loading || loader} />
      <div className="page-with-sidebar">
        <SideBarAthleteColleges handleSimilarCollgClick={props?.handleSimilarCollgClick ? props.handleSimilarCollgClick : () => console.log('FuncClicked')}/>
        <div className="admissions-data">
          <div className="item">
            <h3 className="box-title">Academics Information</h3>
            <ul>
              <li>
                <span>Calendar system:</span>{academicsData?.calendarsystem ?? "--" } <span></span>
              </li>
              <li>
                <span>Graduation Rate:</span> <span>{!!academicsData?.graduationrate ? parseFloat(academicsData?.graduationrate)?.toFixed(2) + "%" : "--"}</span>
              </li>
              <li>
                <span>College Type:</span> <span>{academicsData && academicsData.collegetype ? academicsData.collegetype : "--"}</span>
              </li>
              <li>
                <span>Female Professors:</span> <span>{academicsData && academicsData.femaleprofessors ? academicsData.femaleprofessors + "%" : "--"}</span>
              </li>
              <li>
                <span>Male Professors:</span> <span>{academicsData && academicsData.maleprofessors ? academicsData.maleprofessors + "%" : "--"}</span>
              </li>
              <li>
                <span>Online Courses:</span> <span>{academicsData && academicsData.onlinecourses == 1 ? "Yes" : academicsData && academicsData.onlinecourses == 0 ? "No" : "--"}</span>
              </li>
              <li>
                <span>Student Faculty Ratio:</span> <span>{academicsData && academicsData.studentfacultyratio ? academicsData.studentfacultyratio + "%" : "--"}</span>
              </li>
              <li>
                <span>Study Abroad:</span> <span>{academicsData && academicsData.studyabroad == 1 ? "Yes" : academicsData && academicsData.studyabroad == 0 ? "No" : "--"}</span>
              </li>
              <li>
                <span>Teacher Certification:</span> <span>{academicsData && academicsData.teachercertification == 1 ? "Yes" : academicsData && academicsData.teachercertification == 0 ? "No" : "--"}</span>
              </li>
              <li>
                <span>Selectivity:</span> <span>{academicsData?.selectivity || "--"}</span>
              </li>
            </ul>
          </div>
          <div className="item">
            <h3 className="box-title">Majors Offered</h3>
            <ul>
              {mejorData ? mejorData.map((majorData, index) => (
                <li>{majorData}</li>
              )) : null}
            </ul>
          </div>
        </div>

      </div>
      <div className="back-button text-right pt-3">
        <Link className="btn green" to="/colleges/search">Back</Link>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allAcademics: state.athlete.dashboard.colleges.collegeAcademics
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    collegeAcademicsListStart: (data) => dispatch(collegeAcademicsListStart(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Academics);
