import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Dialog } from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BackdropLoader from "../../../../common/Loader";
import { gpaListStart } from "../../../../../redux/athlete/onBoarding/gpaList";
import { getAllGPAScalesQuery, getAllGradeQuery } from "./AcademicsQuery";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardAcademics.scss";
import "../assets/css/onBoardSteeper.scss";

import { gradeListStart } from "../../../../../redux/athlete/onBoarding/gradeList";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import {
  getAthleteHeightWeight,
  getOnboardingQuery,
  saveOnboardingQuery,
} from "../OnboardingQuery";
import { getUserInfo } from "../../../../../services/authService";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import "../SkitPopup/SkipScreen.scss";
import usePrevious from "../../../../../utils/usePrevious";
import { NumFieldSymbolArr } from "../../../../../utils/validationUtils";
import Stepper from "../Stepper";
import {
  gpaMappingSaveStart,
  gpaMappingStart,
} from "../../../../../redux/athlete/onBoarding/gpaMapping";
import {
  getGpaMappingQuery,
  saveGpaMappingQuery,
} from "../../dashboard/editUserProfile/BasicQuery";
import messagePopup from "../../../../../utils/messagePopup";
import disableNotInHighSchool from "../../../../../utils/disabledNotInHighSchool";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "passed", link: "/onboarding/positions" },
  { className: "passed", link: "/onboarding/physicalMeasurements" },
  { className: "passed", link: "/onboarding/personalRecords" },
  { className: "passed", link: "/onboarding/schoolInfo" },
  { className: "passed", link: "/onboarding/schoolInfo" },//last moment work
  { className: "active", link: "/onboarding/academics" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
];

function Academics(props) {
  const [fields, setFields] = useState({
    user: getUserInfo() ? getUserInfo() : 0,
    //this obj key is added to get the defult value selected
    gpascalevalue: "1",
  });
  const [gpaPlaceHolder, setGpaPlaceHolder] = useState("");
  const [open, setOpen] = React.useState(false);
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [gradeData, setGradeData] = useState(null);
  const [isGpaGreater, setIsGpaGreater] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    props.history.push("/onboarding/recruiting");
  };
  useEffect(() => {
    getAllGradeList();
  }, [props.grades.data]);
  const getAllGradeList = () => {
    const allGrades = props.grades.data;
    const inHighSchoolList =
      allGrades && allGrades.filter((grades) => grades.ishighschool === 1);
    const notInHightSchoolList =
      allGrades && allGrades.filter((grades) => grades.ishighschool === 0);

    const FilteredGradeListData =
      disableNotInHighSchool(getUserInfo().graduationyearValue) === true
        ? notInHightSchoolList
        : inHighSchoolList;
    setGradeData(FilteredGradeListData);
  };
  useEffect(() => {
    setFields({ user: getUserInfo() ? getUserInfo() : 0, gpascalevalue: "1" });
    props.gpaListStart({ query: getAllGPAScalesQuery, variables: null });
    props.gradeListStart({ query: getAllGradeQuery, variables: null });
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
    props.gpaMappingListStart({
      query: getGpaMappingQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          // active:1
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = { ...fields };
        const {
          id,
          gradvalue,
          gpascalevalue,
          gpascale,
          weightgpa,
          unweightedgpa,
        } = props.onboarding.data;
        fieldT["id"] = id;
        fieldT["gradvalue"] = gradvalue;
        //below ternatry condition is done for by default select 4
        fieldT["gpascalevalue"] = !!gpascalevalue ? gpascalevalue : "1";
        fieldT["gpascale"] = gpascale;
        fieldT["unweightedgpa"] = unweightedgpa; //client changes
        fieldT["weightedGPA"] = weightgpa === 1 ? true : false;
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);

  const handleChange = (field, e) => {
    let fieldsT = fields;
    const updatedValue = e.target.value;
    if (field === "gpascale") {
      if ((updatedValue > 0 && updatedValue <= 100) || !updatedValue) {
        const numArr = updatedValue.split(".");
        if (numArr[1]) {
          if (numArr[1].length <= 2) {
            fieldsT[field] = updatedValue;
            setFields({
              ...fieldsT,
            });
          }
        } else {
          fieldsT[field] = updatedValue;
          setFields({
            ...fieldsT,
          });
        }
        if (field == "gpascale") {
          let val = e.target.value;
          if (fields.gpascalevalue == 1 && val > 5) {
            e.target.value = 5;
          }
          if (fields.gpascalevalue == 2 && val > 6) {
            e.target.value = 6;
          }
          if (fields.gpascalevalue == 3 && val > 100) {
            e.target.value = 100;
          }
          fieldsT[field] = e.target.value;
        }
        let value = parseFloat(updatedValue);
        fieldsT.weightedGPA = false;
        if (fieldsT["gpascalevalue"] == 1 && value > 4) {
          fieldsT.weightedGPA = true;
        }
        if (fieldsT["gpascalevalue"] == 2 && value > 5) {
          fieldsT.weightedGPA = true;
        }
        if (fieldsT["gpascalevalue"] == 3) {
          fieldsT.weightedGPA = false;
        }
        setFields({
          ...fieldsT,
        });
      }
    } else if (field === "unweightedgpa") {
      if ((updatedValue > 0 && updatedValue <= 100) || !updatedValue) {
        const numArr = updatedValue.split(".");
        if (numArr[1]) {
          if (numArr[1].length <= 2) {
            fieldsT[field] = updatedValue;
            setFields({
              ...fieldsT,
            });
          }
        } else {
          fieldsT[field] = updatedValue;
          setFields({
            ...fieldsT,
          });
        }
        if (field == "unweightedgpa") {
          let val = e.target.value;
          if (fields.gpascalevalue == 1 && val > 4) {
            e.target.value = 4;
          }
          if (fields.gpascalevalue == 2 && val > 5) {
            e.target.value = 5;
          }
          if (fields.gpascalevalue == 3 && val > 100) {
            e.target.value = 100;
          }
          fieldsT[field] = e.target.value;
        }
        let value = parseFloat(updatedValue);
        fieldsT.weightedGPA = false;
        if (fieldsT["gpascalevalue"] == 1 && value > 4) {
          fieldsT.weightedGPA = true;
        }
        if (fieldsT["gpascalevalue"] == 2 && value > 5) {
          fieldsT.weightedGPA = true;
        }
        if (fieldsT["gpascalevalue"] == 3) {
          fieldsT.weightedGPA = false;
        }
        setFields({
          ...fieldsT,
        });
      }
    } else {
      let placeholder = gpaPlaceHolder;
      if (field == "gpascalevalue") {
        if (updatedValue == 1) {
          placeholder = "ex 3.5";
        }
        if (updatedValue == 2) {
          placeholder = "ex 4.5";
        }
        if (updatedValue == 3) {
          placeholder = "ex 89";
        }
      }
      setGpaPlaceHolder(placeholder);
      fieldsT[field] = updatedValue;
      setFields({
        ...fieldsT,
      });
    }
    setIsGpaGreater(false)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      (fields.gradevalue || fields.gpascalevalue || fields.gpascale) !== null
    ) {
      if(!!fields?.unweightedgpa && !!fields.gpascale){
        if(parseFloat(fields?.unweightedgpa) > parseFloat(fields.gpascale)){
          setIsGpaGreater(true)
          return
        }
      }
      props.saveOnboardingStart({
        query: saveOnboardingQuery,
        variables: {
          obj: {
            id: fields.id ? fields.id : 0,
            userid: getUserInfo().id,
            gradvalue: fields.gradvalue ? +fields.gradvalue : null,
            gpascalevalue: fields.gpascalevalue ? +fields.gpascalevalue : null,
            unweightedgpa: !!fields?.unweightedgpa
              ? fields?.unweightedgpa
              : null,
            gpascale: fields.gpascale ? fields.gpascale : null,
            weightgpa: fields.weightedGPA ? 1 : 0,
          },
        },
        nextScreen: routeNextPage,
      });
    } else {
      messagePopup("", "Please enter your academic details.", "error");
    }
    let gpaMappingid = null;
    if (
      props.userGpaMapping &&
      props.userGpaMapping.data &&
      props.userGpaMapping.data.gpaMappingData &&
      props.userGpaMapping.data.gpaMappingData.allGpamappings &&
      props.userGpaMapping.data.gpaMappingData.allGpamappings.Gpamappings &&
      props.userGpaMapping.data.gpaMappingData.allGpamappings.Gpamappings[0]
    ) {
      gpaMappingid =
        props.userGpaMapping.data.gpaMappingData.allGpamappings.Gpamappings[0]
          .id;
    } else {
      if (
        props.userGpaMapping &&
        props.userGpaMapping.data &&
        props.userGpaMapping.data.saveGpamapping &&
        props.userGpaMapping.data.saveGpamapping.id
      ) {
        gpaMappingid = props.userGpaMapping.data.saveGpamapping.id;
      }
    }
    props.saveGpaMappingStart({
      query: saveGpaMappingQuery,
      variables: {
        obj: {
          id: gpaMappingid,
          userid: getUserInfo() ? getUserInfo().id : 0,
          weightedgpa: fields.weightedGPA == true ? 1 : 0,
          gparangevalueid: parseInt(fields.gpaScale),
          gpa: parseFloat(fields.gpa),
        },
      },
    });
  };

  const clearGpaValueOnChangeGpaScale = () => {
    if (fields.gpascalevalue) {
      setFields({
        ...fields,
        gpascale: "",
        weightedGPA: "",
        unweightedgpa: "",
      });
    }
  };

  return (
    <div>
      <div className="onBoardAcademicSection">
        <BackdropLoader
          open={
            props.gpaList.loading ||
            props.grades.loading ||
            props.onboarding.loading
          }
        />
        <div className="academicBlock">
          <div className="stepper">
            <Stepper stepperName="academics" stepperData={stepperData} />
          </div>
          <div className="formSection">
            <h1 className="heading">Academics</h1>
            <div className="fieldBox">
              <label>What's your Grade?</label>
              <select
                id="gradvalue"
                value={fields.gradvalue || ""}
                onChange={(e) => handleChange("gradvalue", e)}
                label="Select Grade"
              >
                <option value="">Select Grade</option>
                {gradeData &&
                  gradeData.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.gradevalue}
                    </option>
                  ))}
              </select>
            </div>
            <div className="fieldBox">
              <label>What's your GPA scale?</label>

              <select
                id="gpascalevalue"
                value={fields.gpascalevalue || ""}
                onChange={(e) => {
                  handleChange("gpascalevalue", e);
                  handleChange("gpascale", e);
                  clearGpaValueOnChangeGpaScale();
                }}
                label="Select Grade"
              >
                {/*earlier it was there but now it is selcted by default so as per discussion it is removed*/}
                {/*<option value="">Select GPA scale</option>*/}
                {props.gpaList.data && props.gpaList.data?.length > 0 ? (
                  props.gpaList.data.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.gpascalevalue}
                    </option>
                  ))
                ) : (
                  <option value="">None</option>
                )}
              </select>
            </div>
            <div className="fieldBox">
              <label>Enter GPA (Unweighted)</label>
              <TextField
                variant="outlined"
                id="gpascale"
                type="number"
                disabled={fields.gpascalevalue === "" ? true : false}
                value={fields.unweightedgpa ? fields.unweightedgpa || "" : ""}
                fullWidth
                onKeyDown={(e) =>
                  NumFieldSymbolArr.includes(e.key) && e.preventDefault()
                }
                onChange={(e) => {
                  handleChange("unweightedgpa", {
                    target: {
                      value: e.target.value
                        .toString()
                        .split(".")
                        .map((el, i) =>
                          i ? el.split("").slice(0, 2).join("") : el
                        )
                        .join("."),
                    },
                  });
                }}
                onBlur={(e) => {
                  handleChange("unweightedgpa", e);
                }}
                inputProps={{
                  max: 100,
                  min: 0,
                  pattern: "/^d+(.d{0,2})?$/",
                  step: 0.01,
                }}
                placeholder={
                  fields.gpascalevalue == 1
                    ? "ex 3.5"
                    : fields.gpascalevalue == 2
                    ? "ex 4.5"
                    : fields.gpascalevalue == 3
                    ? "ex 89"
                    : ""
                }
                required
              />
            </div>
            <div className="fieldBox">
              <label>Enter GPA (Weighted)</label>
              <TextField
                variant="outlined"
                id="gpascale"
                type="number"
                disabled={fields.gpascalevalue === "" ? true : false}
                value={fields.gpascalevalue ? fields.gpascale || "" : ""}
                fullWidth
                onKeyDown={(e) =>
                  NumFieldSymbolArr.includes(e.key) && e.preventDefault()
                }
                onChange={(e) => {
                  handleChange("gpascale", {
                    target: {
                      value: e.target.value
                        .toString()
                        .split(".")
                        .map((el, i) =>
                          i ? el.split("").slice(0, 2).join("") : el
                        )
                        .join("."),
                    },
                  });
                }}
                onBlur={(e) => {
                  handleChange("gpascale", e);
                }}
                inputProps={{
                  max: 100,
                  min: 0,
                  pattern: "/^d+(.d{0,2})?$/",
                  step: 0.01,
                }}
                // placeholder={gpaPlaceHolder}
                placeholder={
                  fields.gpascalevalue == 1
                    ? "ex 3.5"
                    : fields.gpascalevalue == 2
                    ? "ex 4.5"
                    : fields.gpascalevalue == 3
                    ? "ex 89"
                    : ""
                }
                required
              />
              {isGpaGreater && <span style={{color:"red", display:"block", marginTop: "8px"}}>GPA (W) should be greater than GPA (UW)</span>}
            </div>
            <div className="checkbox">
              {/*<span>*/}
              {/*  <input*/}
              {/*    name="weightedGPA"*/}
              {/*    type="checkbox"*/}
              {/*    checked={*/}
              {/*      fields.gpascalevalue === 1 && fields.gpascale > 4*/}
              {/*        ? true*/}
              {/*        : fields.gpascalevalue === 2 && fields.gpascale > 5*/}
              {/*        ? true*/}
              {/*        : fields.gpascalevalue === 3*/}
              {/*        ? false*/}
              {/*        : fields.weightedGPA || false*/}
              {/*    }*/}
              {/*    onChange={(e) =>*/}
              {/*      handleChange("weightedGPA", {*/}
              {/*        target: { value: e.target.checked },*/}
              {/*      })*/}
              {/*    }*/}
              {/*    id="weightedGPA"*/}
              {/*  />*/}
              {/*  <label htmlFor="weightedGPA">Weighted GPA</label>*/}
              {/*</span>*/}
            </div>

            <p className="skip">
              Don't know?{" "}
              <Link to="#" onClick={handleClickOpen}>
                Skip it
              </Link>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="/onBoardPosition" onClick={(e) => handleSubmit(e)}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    gpaList: state.athlete.onBoarding.gpaList,
    grades: state.athlete.onBoarding.grades,
    onboarding: state.athlete.onBoarding.onboarding,
    userGpaMapping: state.athlete.onBoarding.gpaMapping,
    // athleteHeightWeight: state.athlete.onBoarding.athleteHeightWeight,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    gpaListStart: (data) => dispatch(gpaListStart(data)),
    gradeListStart: (data) => dispatch(gradeListStart(data)),
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
    gpaMappingListStart: (data) => dispatch(gpaMappingStart(data)),
    saveGpaMappingStart: (data) => dispatch(gpaMappingSaveStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Academics);
