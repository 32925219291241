import { all, fork } from "redux-saga/effects";
import { watchAddRemoveUserCollegeSaga } from "./addRemoveUserCollege";
import { watchAreaOfStudyListSaga } from "./areaOfStudyList";
import { watchCategoryListSaga } from "./categoryList";
import { watchCheckSearchFilterSaga } from "./checkSearchFilter";
import { watchCollegeAcademicsSaga } from "./collegeAcademics";
import { watchCollegeAdmissionsSaga } from "./collegeAdmissions";
import { watchCollegeCampusesSaga } from "./collegeCampuses";
import { watchCollegesListSaga } from "./collegeList";
import {watchacademicSelectivityListSaga} from "./academicSelectivityList";
import {watchCollegeSettingsListSaga} from "./collegeSettingsList";
import {watchcollegeSizeListSaga} from "./collegeSizeList";
import {watchTutionCostsListSaga} from "./tutionCostsList";
import {watchCollegedivisionsListSaga} from "./collegedivisionsList";
import {watchAllRegionListSaga} from "./allRegionList";
import {watchAllStateListSaga} from "./allStateList";

import { watchSearchCollegeListSaga } from "./collegeSearchList";
import { watchMyProgressSaga } from "./myProgress";
import { watchSaveSearchFilterSaga } from "./saveSearchFilter";
import { watchSearchFiltersListSaga } from "./searchFilterList";
import { watchUpdateUserCollegeSaga } from "./updateUserCollege";
import { watchUserCollegeCardCountSaga } from "./userCollegeCardCount";
import { watchViewCollegeSaga } from "./viewCollege";
import { watchfollowRecruitCountListSaga } from './followRecruitCount';
import { watchClgActivitySaga } from './activity'
import { watchCollegeActivitySaga } from "./collegeActivity";
import { watchSearchCollegeCountListSaga } from "./searchCollegeCount";
import { watchUserProfileViewListSaga } from "./userProfileView";
import { watchLastContactListSaga } from "./messageSent";
import { watchSearchStateByRegionSaga } from "./searchStateByRegion";

export default function* rootCollegesSagas() {
  yield all([
    fork(watchSearchCollegeListSaga),
    fork(watchCollegesListSaga),
    fork(watchacademicSelectivityListSaga),
    fork(watchCollegeSettingsListSaga),
    fork(watchcollegeSizeListSaga),
    fork(watchTutionCostsListSaga),
    fork(watchCollegedivisionsListSaga),
    fork(watchAllRegionListSaga),
    fork(watchAllStateListSaga),

    fork(watchSearchFiltersListSaga),
    fork(watchCheckSearchFilterSaga),
    fork(watchSaveSearchFilterSaga),
    fork(watchAddRemoveUserCollegeSaga),
    fork(watchCategoryListSaga),
    fork(watchUpdateUserCollegeSaga),
    fork(watchUserCollegeCardCountSaga),
    fork(watchAreaOfStudyListSaga),
    fork(watchViewCollegeSaga),
    fork(watchfollowRecruitCountListSaga),
    fork(watchMyProgressSaga),
    fork(watchClgActivitySaga),
    fork(watchCollegeAdmissionsSaga),
    fork(watchCollegeCampusesSaga),
    fork(watchCollegeAcademicsSaga),
    fork(watchCollegeActivitySaga),
    fork(watchSearchCollegeCountListSaga),
    fork(watchUserProfileViewListSaga),
    fork(watchLastContactListSaga),
    fork(watchSearchStateByRegionSaga)
  ]);
}
