import React, { useState, useEffect, useRef } from "react";
import "./assets/css/editProfile.scss";
import LeftSideEditProfile from "./LeftSideEditProfile";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { footListStart } from "../../../../../redux/athlete/onBoarding/footList";
import { inchListStart } from "../../../../../redux/athlete/onBoarding/inchList";
import {
  getAllHeightInFeetsQuery,
  getAllHeightInInchesQuery,
} from "../../../athlet/onboarding/physicalMeasurements/PhysicalMeasurementsQuery";
import {
  getAllBattingPreferencesQuery,
  getAllThrowingPreferencesQuery,
} from "../../../athlet/onboarding/batAndThrow/BatThrowQuery";
import { battingPreferenceListStart } from "../../../../../redux/athlete/onBoarding/battingPreferences";
import { throwingPreferenceListStart } from "../../../../../redux/athlete/onBoarding/throwingPreferences";
import { primaryListStart } from "../../../../../redux/athlete/onBoarding/primaryPositionList";
import { secondaryListStart } from "../../../../../redux/athlete/onBoarding/secondaryPositionList";
import {
  getAllPrimaryPositionsQuery,
  getAllSecondaryPositionsQuery,
} from "../../../athlet/onboarding/positions/PositionsQuery";
import BackdropLoader from "../../../../common/Loader";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { getUserInfo } from "../../../../../services/authService";
import {
  getvitalDataQuery,
  saveOnboardingVitalsDataQuery,
  saveOnboardingHeightWeightDataQuery,
} from "./vitalQuery";
import TextField from "@mui/material/TextField";
import messagePopup from "../../../../../utils/messagePopup";
import { heigthWeightsaveStart } from "../../../../../redux/athlete/onBoarding/heightWeight";
import ScreenSwitch from "./ScreenSwitch";
import SubHeader from "./SubHeader";
import GoTopButton from "./GoTopButton";
import {websiteDefaultUrl} from "../../../../../config";
import toastr from "toastr";
import {AutoVerify} from "../../../../common/autoVerify";
import {getAthleteSysID} from "../../../../common/getProfileURLData";

const selectStyle = {
  position: "relative",
  fontSize: "0.875rem",
  fontWeight: 400,
  color: "#3e3e3e",
  lineHeight: 1.4,
  marginBottom: "10px"
}

function Vitals(props) {
  const [fields, setFields] = useState({
    onboardingId: 0,
    feetId: "",
    inchId: "",
    weight: "",
    battingId: "",
    throwingId: "",
    primaryPositionId: "",
    secondaryPositionId: "",
    other: "",
  });

  const [sideMenuOpen, setSideMenu] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [previousData, setPreviousData] = useState();
  //for progress bar
  const dispatch = useDispatch();
  const fakeInput = useRef(null);
  useEffect(() => {
    props.footListStart({
      query: getAllHeightInFeetsQuery,
      variables: null,
    });
    props.inchListStart({
      query: getAllHeightInInchesQuery,
      variables: null,
    });
    props.battingPreferenceListStart({
      query: getAllBattingPreferencesQuery,
      variables: {
        obj: {
          active: 1,
        },
      },
    });
    props.throwingPreferenceListStart({
      query: getAllThrowingPreferencesQuery,
      variables: {
        obj: {
          active: 1,
        },
      },
    });
    props.primaryListStart({
      query: getAllPrimaryPositionsQuery,
      variables: null,
    });
    props.secondaryListStart({
      query: getAllSecondaryPositionsQuery,
      variables: null,
    });
    getOnboardingVitals();
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);
  useEffect(() => {
    if (props.location.state) {
      setPreviousData(props.location.state);
    }
  }, [props.location.state]);

  const getOnboardingVitals = () => {
    props.getOnboardingStart({
      query: getvitalDataQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
  };
  useEffect(() => {
    if (
      props.onBoardingDetails &&
      props.onBoardingDetails.onboarding &&
      props.onBoardingDetails.onboarding.data
    ) {
      const fieldT = fields;
      const {
        id,
        heightinfeet,
        heightininches,
        battingprefencevalue,
        throwingPrefenceValue,
        primaryposition,
        secondaryposition,
        otherposition,
        weight,
      } = props.onBoardingDetails.onboarding.data;
      fieldT["onboardingId"] = id == null ? "" : id;
      fieldT["feetId"] = heightinfeet == null ? "" : heightinfeet;
      fieldT["inchId"] = heightininches == null ? "" : heightininches;
      fieldT["battingId"] =
        battingprefencevalue == null ? "" : battingprefencevalue;
      fieldT["throwingId"] =
        throwingPrefenceValue == null ? "" : throwingPrefenceValue;
      fieldT["primaryPositionId"] =
        primaryposition == null ? "" : primaryposition;
      fieldT["secondaryPositionId"] =
        secondaryposition == null ? "" : secondaryposition;
      fieldT["weight"] = weight == null ? "" : weight;
      fieldT["other"] = otherposition == null ? "" : otherposition;
      setFields({ ...fieldT });
    }
  }, [props.onBoardingDetails]);

  const handlePhysicalChange = (field, e) => {
    let fieldsT = fields;
    fieldsT[field] = e.target.value;
    setFields({
      ...fieldsT,
    });

    setIsSaved(false);
  };

  const convertPoundsToKg = (pound) => {
    if (pound) return (pound / 2.2046).toFixed(2);

    return null;
  };

  const convertFootInchIntoCm = () => {
    let fieldsValuesFoot = fields.feetId == "" ? 0 : parseInt(fields.feetId);
    let fieldsValuesInch = fields.inchId == "" ? 0 : parseInt(fields.inchId);
    let convertIntoCm = (
      fieldsValuesFoot * 30.48 +
      fieldsValuesInch * 2.54
    ).toFixed(2);
    return convertIntoCm;
  };

  const saveHeightWeight = () => {
    props.heigthWeightsaveStart({
      query: saveOnboardingHeightWeightDataQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
          onboardingid: fields.onboardingId == 0 ? null : fields.onboardingId,
          heightinfeet: fields.feetId == "" ? null : parseInt(fields.feetId),
          heightininches: fields.inchId == "" ? null : parseInt(fields.inchId),
          weight: fields.weight == "" ? null : parseFloat(fields.weight),
          heightincentimeter: parseFloat(convertFootInchIntoCm()),
          weightinkilogram: parseFloat(
            convertPoundsToKg(
              fields.weight == "" ? 0 : parseFloat(fields.weight)
            )
          ),
          updatedby: getUserInfo().id,
        },
      },
      nextScreen: routeNextPage,
    });
  };

  const routeNextPage = async () => {
    if (previousData && previousData.previousPage === "checklist") {
      props.history.push("/checklist");
      // messagePopup("", "Vitals data updated successfully.", "success");
      toastr.success("Vitals data updated successfully.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
      getOnboardingVitals();
      await AutoVerify()
      //for progress bar
      dispatch({ type: "RENDER_COUNT_START", payload: 1 })
    } else {
      // messagePopup("", "Vitals data updated successfully.", "success");
      toastr.success("Vitals data updated successfully.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
      getOnboardingVitals();
      await AutoVerify()
      //for progress bar
      dispatch({ type: "RENDER_COUNT_START", payload: 1 })
      //this for scroll the page on top
      window.scrollTo(0, 0)
    }
  };

  const handelvitalsSubmit = (e) => {
    e?.preventDefault()
    var existing = localStorage.getItem('user');
    existing = existing ? JSON.parse(existing) : {};
    existing['primaryPosition'] = fields.primaryPositionId == "" ? null : parseInt(fields.primaryPositionId)
    existing['secondaryPosition'] = fields.secondaryPositionId == "" ? null : parseInt(fields.secondaryPositionId)
    localStorage.setItem('user', JSON.stringify(existing));
    props.saveOnboardingStart({
      query: saveOnboardingVitalsDataQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : null,
          id: fields.onboardingId,
          heightinfeet: fields.feetId == "" ? null : parseInt(fields.feetId),
          heightininches: fields.inchId == "" ? null : parseInt(fields.inchId),
          battingprefencevalue:
            fields.battingId == "" ? null : parseInt(fields.battingId),
          throwingPrefenceValue:
            fields.throwingId == "" ? null : parseInt(fields.throwingId),
          primaryposition:
            fields.primaryPositionId == ""
              ? null
              : parseInt(fields.primaryPositionId),
          secondaryposition:
            fields.secondaryPositionId == ""
              ? null
              : parseInt(fields.secondaryPositionId),
          otherposition: fields.other == "" ? null : parseInt(fields.other),
          weight: fields.weight == "" ? null : parseFloat(fields.weight),
          heightincentimeter: parseFloat(convertFootInchIntoCm()),
          weightinkilogram: parseFloat(
            convertPoundsToKg(
              fields.weight == "" ? 0 : parseFloat(fields.weight)
            )
          ),
          updatedby: getUserInfo().id,
        },
      },
      nextScreen: saveHeightWeight,
      currentScreen: 'vital'
    });
    setIsSaved(true);
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  const handalPreviewProfile = () => {
    props.history.push(
        {
          pathname: `${getAthleteSysID()}`,
          state: {isPublic: false}
        }
        // "/profile/1"
    );
    sessionStorage.setItem('athlete_own_profile', true)
  };
  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };

  return (
    <div className="pageContent">
      <div className="editProfile">
        <input className="fakeInput" type="text" ref={fakeInput} />

        <BackdropLoader open={props.onBoardingDetails.onboarding.loading} />
        <div className="container">
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
          >
            <SubHeader
              hamburger={onSideMenuClick}
              onSave={handelvitalsSubmit}
            />

            <div className="sidebar">
              <LeftSideEditProfile isSaved={isSaved} />
            </div>
            <div className="editSection">
              <div className="flexRow">
                <div className="title">Vitals</div>
                <div className="btnWrap">
                  <button
                    className="btn outlined green rounded medium backprofileBtn"
                    onClick={() => handalPreviewProfile()}
                  >
                    Back To Profile
                  </button>
                  <button
                    className="btn outlined green rounded medium"
                    onClick={() => {
                      handalPreviewProfile();
                    }}
                  >
                    Preview Profile
                  </button>
                  <button className="btn outlined blue rounded medium"
                    onClick={() => {
                      sessionStorage.setItem('athlete_own_profile', false)
                      setTimeout(() => {
                        window.open(`${websiteDefaultUrl}athlete/wyatt-johnson-74144936`, "_blank")
                      }, 500)
                    }}
                  >
                    Sample Profile
                  </button>
                </div>
              </div>
              <div className="check-list-sec">
                <div className="editprofile-common-form">
                  <form>
                    <div className="form-info-box">
                      <div className="item">
                        <h5 className="title">
                          Physical Measurements{" "}
                          <span className="checkIcon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 29.432 29.47"
                            >
                              <g id="blueTick" transform="translate(0 0)">
                                <path
                                  id="Subtraction_10"
                                  data-name="Subtraction 10"
                                  d="M13.51.8a1.311,1.311,0,0,1,2.411,0l0,0a1.311,1.311,0,0,0,2.18.363h0a1.311,1.311,0,0,1,2.281.78v.018a1.311,1.311,0,0,0,1.943,1.049l0,0a1.311,1.311,0,0,1,1.9,1.476l-.008.032a1.311,1.311,0,0,0,1.5,1.621l.018,0a1.311,1.311,0,0,1,1.321,2.014l-.025.037a1.311,1.311,0,0,0,.887,2.017l.033.005a1.311,1.311,0,0,1,.593,2.333l-.038.029a1.311,1.311,0,0,0,.182,2.2l.04.021a1.311,1.311,0,0,1-.2,2.4L28.5,17.2a1.311,1.311,0,0,0-.542,2.135l.032.035a1.311,1.311,0,0,1-.97,2.2h-.026a1.311,1.311,0,0,0-1.208,1.844l.016.037a1.311,1.311,0,0,1-1.635,1.769l-.01,0a1.311,1.311,0,0,0-1.743,1.354l0,.026a1.311,1.311,0,0,1-2.122,1.144h0a1.311,1.311,0,0,0-2.09.716l0,.011a1.311,1.311,0,0,1-2.379.4h0a1.311,1.311,0,0,0-2.21,0h0a1.311,1.311,0,0,1-2.379-.4l0-.011a1.311,1.311,0,0,0-2.09-.716h0A1.311,1.311,0,0,1,7.016,26.6l0-.026a1.311,1.311,0,0,0-1.743-1.354l-.01,0a1.311,1.311,0,0,1-1.635-1.769l.016-.037a1.311,1.311,0,0,0-1.208-1.844H2.412a1.311,1.311,0,0,1-.97-2.2l.032-.035A1.311,1.311,0,0,0,.931,17.2l-.038-.013a1.311,1.311,0,0,1-.2-2.4l.04-.021a1.311,1.311,0,0,0,.182-2.2l-.038-.029a1.311,1.311,0,0,1,.593-2.333L1.5,10.2a1.311,1.311,0,0,0,.887-2.017l-.025-.037A1.311,1.311,0,0,1,3.688,6.136l.018,0A1.311,1.311,0,0,0,5.2,4.518l-.008-.032A1.311,1.311,0,0,1,7.1,3.009l0,0A1.311,1.311,0,0,0,9.045,1.962V1.944a1.311,1.311,0,0,1,2.281-.78h0A1.311,1.311,0,0,0,13.508.8Z"
                                  transform="translate(0 0)"
                                  fill="#00b9ff"
                                ></path>
                                <path
                                  id="Icon_feather-check"
                                  data-name="Icon feather-check"
                                  d="M16.188,9l-7,7.641L6,13.168"
                                  transform="translate(3.622 1.914)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </h5>
                        <div className="fieldBox flexBox heightFieldBoxMain">
                          <div className="half heightFieldBox">
                            <label>Height</label>
                            <div className="form-group  mb-0">
                              <span style={{position: "absolute", left: "8px", top: "11px"}}>
                                <span style={selectStyle}>ft</span>
                              </span>
                              <div className="fieldBox">
                                <FormControl variant="outlined" fullWidth>
                                  {/*<InputLabel id="demo-simple-select-outlined-label"></InputLabel>*/}
                                  <Select
                                    // labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={fields.feetId}
                                    onChange={(e) =>
                                      handlePhysicalChange("feetId", e)
                                    }
                                    // label="Select"
                                    displayEmpty
                                  >
                                    <MenuItem value="">
                                      <em>Select Feet</em>
                                    </MenuItem>
                                    {props.feetDetails.footList &&
                                      props.feetDetails.footList.data &&
                                      props.feetDetails.footList.data.map(
                                        (item, index) => (
                                          <MenuItem key={index} value={item.id}>
                                            {item.heightvalue}
                                          </MenuItem>
                                        )
                                      )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                            <div className="form-group mb-0">
                              {/*<FormLabel>*/}
                              {/*  <label>In</label>*/}
                              {/*</FormLabel>*/}
                              <span style={{position: "absolute", left: "8px", top: "11px"}}>
                                <span style={selectStyle}>In</span>
                              </span>
                              <div className="fieldBox">
                                <FormControl variant="outlined" fullWidth>
                                  {/*<InputLabel id="demo-simple-select-outlined-label"></InputLabel>*/}
                                  <Select
                                    // labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={fields.inchId}
                                    onChange={(e) =>
                                      handlePhysicalChange("inchId", e)
                                    }
                                    // label="Select"
                                    displayEmpty
                                  >
                                    <MenuItem value="">
                                      <em>Select Inches</em>
                                    </MenuItem>
                                    {props.feetDetails.inchList &&
                                      props.feetDetails.inchList.data &&
                                      props.feetDetails.inchList.data.map(
                                        (item, index) => (
                                          <MenuItem key={index} value={item.id}>
                                            {item.inchvalue}
                                          </MenuItem>
                                        )
                                      )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          <div className="half">
                            <label>Weight (LBS)</label>
                            <div className="form-group mb-0">
                              <TextField
                                variant="outlined"
                                id="weight"
                                type="number"
                                value={fields.weight || ""}
                                onChange={(e) => {
                                  handlePhysicalChange("weight", e);
                                }}
                                placeholder="Weight in lbs"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseFloat(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 3);
                                }}
                                onBlur={(e) => {
                                  handlePhysicalChange("weight", e);
                                }}
                              />
                              <span
                                style={{
                                  paddingLeft: "10px",
                                  lineHeight: "40px",
                                }}
                              >
                                lbs
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-info-box">
                      <div className="item">
                        <h5 className="title">Bat / Throw</h5>
                        <div className="fieldBox flexBox">
                          <div className="half">
                            <div className="form-group mb-0">
                              <div style={selectStyle}>Bat</div>
                              {/*<FormLabel>Bat</FormLabel>*/}
                              <div className="fieldBox">
                                <FormControl variant="outlined" fullWidth>
                                  <Select
                                    // labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={fields.battingId}
                                    onChange={(e) =>
                                      handlePhysicalChange("battingId", e)
                                    }
                                    // label="Select"
                                    displayEmpty
                                  >
                                    <MenuItem value="">
                                      <em>Select Bat</em>
                                    </MenuItem>
                                    {props.battingDetails.battingPreferences &&
                                      props.battingDetails.battingPreferences
                                        .data &&
                                      props.battingDetails.battingPreferences.data.map(
                                        (item, index) => (
                                          <MenuItem key={index} value={item.id}>
                                            {item.battingpreferencevalue}
                                          </MenuItem>
                                        )
                                      )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          <div className="half">
                            <div className="form-group  mb-0">
                              {/*<FormLabel>Throw</FormLabel>*/}
                              <div style={selectStyle}>Throw</div>
                              <div className="fieldBox">
                                <FormControl variant="outlined" fullWidth>
                                  <Select
                                    // labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={fields.throwingId}
                                    onChange={(e) =>
                                      handlePhysicalChange("throwingId", e)
                                    }
                                    // label="Select"
                                    displayEmpty
                                  >
                                    <MenuItem value="">
                                      <em>Select Throw</em>
                                    </MenuItem>
                                    {props.throwingDetails
                                      .throwingPreferences &&
                                      props.throwingDetails.throwingPreferences
                                        .data &&
                                      props.throwingDetails.throwingPreferences.data.map(
                                        (item, index) => (
                                          <MenuItem key={index} value={item.id}>
                                            {item.throwingpreferencevalue}
                                          </MenuItem>
                                        )
                                      )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-info-box">
                      <h5 className="title">Positions</h5>
                      <div className="fieldBox flexBox">
                        <div className="half">
                          <div className="form-group  mb-0">
                            <div style={selectStyle}>
                              Primary Position
                              <span className="checkIcon ml-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 29.432 29.47"
                                >
                                  <g id="blueTick" transform="translate(0 0)">
                                    <path
                                      id="Subtraction_10"
                                      data-name="Subtraction 10"
                                      d="M13.51.8a1.311,1.311,0,0,1,2.411,0l0,0a1.311,1.311,0,0,0,2.18.363h0a1.311,1.311,0,0,1,2.281.78v.018a1.311,1.311,0,0,0,1.943,1.049l0,0a1.311,1.311,0,0,1,1.9,1.476l-.008.032a1.311,1.311,0,0,0,1.5,1.621l.018,0a1.311,1.311,0,0,1,1.321,2.014l-.025.037a1.311,1.311,0,0,0,.887,2.017l.033.005a1.311,1.311,0,0,1,.593,2.333l-.038.029a1.311,1.311,0,0,0,.182,2.2l.04.021a1.311,1.311,0,0,1-.2,2.4L28.5,17.2a1.311,1.311,0,0,0-.542,2.135l.032.035a1.311,1.311,0,0,1-.97,2.2h-.026a1.311,1.311,0,0,0-1.208,1.844l.016.037a1.311,1.311,0,0,1-1.635,1.769l-.01,0a1.311,1.311,0,0,0-1.743,1.354l0,.026a1.311,1.311,0,0,1-2.122,1.144h0a1.311,1.311,0,0,0-2.09.716l0,.011a1.311,1.311,0,0,1-2.379.4h0a1.311,1.311,0,0,0-2.21,0h0a1.311,1.311,0,0,1-2.379-.4l0-.011a1.311,1.311,0,0,0-2.09-.716h0A1.311,1.311,0,0,1,7.016,26.6l0-.026a1.311,1.311,0,0,0-1.743-1.354l-.01,0a1.311,1.311,0,0,1-1.635-1.769l.016-.037a1.311,1.311,0,0,0-1.208-1.844H2.412a1.311,1.311,0,0,1-.97-2.2l.032-.035A1.311,1.311,0,0,0,.931,17.2l-.038-.013a1.311,1.311,0,0,1-.2-2.4l.04-.021a1.311,1.311,0,0,0,.182-2.2l-.038-.029a1.311,1.311,0,0,1,.593-2.333L1.5,10.2a1.311,1.311,0,0,0,.887-2.017l-.025-.037A1.311,1.311,0,0,1,3.688,6.136l.018,0A1.311,1.311,0,0,0,5.2,4.518l-.008-.032A1.311,1.311,0,0,1,7.1,3.009l0,0A1.311,1.311,0,0,0,9.045,1.962V1.944a1.311,1.311,0,0,1,2.281-.78h0A1.311,1.311,0,0,0,13.508.8Z"
                                      transform="translate(0 0)"
                                      fill="#00b9ff"
                                    ></path>
                                    <path
                                      id="Icon_feather-check"
                                      data-name="Icon feather-check"
                                      d="M16.188,9l-7,7.641L6,13.168"
                                      transform="translate(3.622 1.914)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></path>
                                  </g>
                                </svg>
                              </span>
                            </div>
                            <div className="fieldBox">
                              <FormControl variant="outlined" fullWidth>
                                <Select
                                  // labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={fields.primaryPositionId}
                                  onChange={(e) =>
                                    handlePhysicalChange("primaryPositionId", e)
                                  }
                                  // label="Select"
                                  displayEmpty
                                >
                                  <MenuItem value="">
                                    <em>Select Position</em>
                                  </MenuItem>
                                  {props.primaryPosition.primaryPositionList &&
                                    props.primaryPosition.primaryPositionList
                                      .data &&
                                    props.primaryPosition.primaryPositionList.data
                                      .filter(
                                        (item) =>
                                          item.id !==
                                          fields.secondaryPositionId &&
                                          item.id !== fields.other
                                      )
                                      .map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                          {item.shortform}
                                        </MenuItem>
                                      ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className="half">
                          <div className="form-group mb-0">
                            {/*<FormLabel>Secondary Position</FormLabel>*/}
                            <div style={selectStyle}>Position</div>
                            <div className="fieldBox">
                              <FormControl variant="outlined" fullWidth>
                                <Select
                                  // labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={fields.secondaryPositionId}
                                  onChange={(e) =>
                                    handlePhysicalChange(
                                      "secondaryPositionId",
                                      e
                                    )
                                  }
                                  // label="Select"
                                  displayEmpty
                                >
                                  <MenuItem value="">
                                    <em>Select Position</em>
                                  </MenuItem>
                                  {props.secondaryPosition
                                    .secondaryPositionList &&
                                    props.secondaryPosition
                                      .secondaryPositionList.data &&
                                    props.secondaryPosition.secondaryPositionList.data
                                      .filter(
                                        (item) =>
                                          item.id !==
                                          fields.primaryPositionId &&
                                          item.id !== fields.other
                                      )
                                      .map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                          {item.shortform}
                                        </MenuItem>
                                      ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fieldBox flexBox">
                        <div className="half">
                          <div className="form-group mb-0">
                            <div style={selectStyle}>Other Position</div>
                            {/*<FormLabel>Other Position</FormLabel>*/}
                            <div className="fieldBox">
                              <FormControl variant="outlined" fullWidth>
                                {/*<InputLabel*/}
                                {/*  className="mb-0"*/}
                                {/*  id="demo-simple-select-outlined-label"*/}
                                {/*></InputLabel>*/}
                                <Select
                                  // labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={fields.other}
                                  onChange={(e) =>
                                    handlePhysicalChange("other", e)
                                  }
                                  // label="Select"
                                  displayEmpty
                                >
                                  <MenuItem value="">
                                    <em>Select Position</em>
                                  </MenuItem>
                                  {props.secondaryPosition
                                    .secondaryPositionList &&
                                    props.secondaryPosition
                                      .secondaryPositionList.data &&
                                    props.secondaryPosition.secondaryPositionList.data
                                      .filter(
                                        (item) =>
                                          item.id !==
                                          fields.primaryPositionId &&
                                          item.id !== fields.secondaryPositionId
                                      )
                                      .map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                          {item.shortform}
                                        </MenuItem>
                                      ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-button-box with-next-prev">
                      <button
                        type={'button'}
                        className="btn blue"
                        onClick={(e) => {
                          handelvitalsSubmit(e);
                        }}
                      >
                        Save
                      </button>
                      <div className="next-prev-button">
                        <ScreenSwitch
                          prevLink={"/videoPhotos"}
                          nextLink={"/teams"}
                          isSaved={isSaved}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <GoTopButton goTop={goTop} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    feetDetails: state.athlete.onBoarding,
    inchesDetails: state.athlete.onBoarding,
    battingDetails: state.athlete.onBoarding,
    throwingDetails: state.athlete.onBoarding,
    primaryPosition: state.athlete.onBoarding,
    secondaryPosition: state.athlete.onBoarding,
    onBoardingDetails: state.athlete.onBoarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    footListStart: (data) => dispatch(footListStart(data)),
    inchListStart: (data) => dispatch(inchListStart(data)),
    battingPreferenceListStart: (data) =>
      dispatch(battingPreferenceListStart(data)),
    throwingPreferenceListStart: (data) =>
      dispatch(throwingPreferenceListStart(data)),
    primaryListStart: (data) => dispatch(primaryListStart(data)),
    secondaryListStart: (data) => dispatch(secondaryListStart(data)),
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
    heigthWeightsaveStart: (data) => dispatch(heigthWeightsaveStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vitals);
