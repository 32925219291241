import { React, useState, useEffect , useRef} from "react";
import "./assets/css/faq.scss";
import { askedQuestionsListStart } from "../../../../../../redux/athlete/dashboard/account/frequentlyAskedQuestions";
import { connect } from "react-redux";
import { getAllAskedQuestionsQuery } from "./faqQuery";
import ReactPaginate from "react-paginate";
import { searchAllQuestionsQuery } from "./faqQuery";
import BackdropLoader from "../../../../../common/Loader";
import { searchQuestionsStart } from "../../../../../../redux/athlete/dashboard/account/searchFAQ";
import { NumFieldSymbolArr } from "../../../../../../utils/validationUtils";
import messagePopup from "../../../../../../utils/messagePopup";
import toastr from "toastr";
import {fetchGraphMethod, fetchMethod} from "../../../../../../services/httpService";

function FAQ(props) {
  const [expand, setExpand] = useState([]);
  const [fields, setFields] = useState({
    search: "",
    getPage: 1,
    searchPage: 0,
    pageCount: 0,
    perPage: 5,
    go: 1,
  });
  const fakeInput = useRef();
  const [noData, setNoData] = useState("No data found");
  const [allAskedQuestionsList, setAllAskedQuestionsList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const questionData = props.allAskedQuestions;

  const handleExpandCollapse = (prevState, index) => {
    const tempData = [...expand];
    tempData[index] = prevState ? false : true;
    setExpand(tempData);
  };
  useEffect(() => {
    getAllAskedQuestionsData();
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);
  useEffect(() => {
    // if (allAskedQuestionsList) {
    //   let fieldsT = fields;
    //   fieldsT["pageCountT"] = allAskedQuestionsList.totalCount
    //     ? Math.ceil(+allAskedQuestionsList.totalCount / fieldsT["perPage"])
    //     : "";
    //   setFields({
    //     ...fieldsT,
    //   });
    // }
  }, [allAskedQuestionsList]);

  // useEffect(() => {
    // if (filteredAllAskedQuestions) {
    //   let fieldsT = fields;
    //   fieldsT["pageCountT"] = filteredAllAskedQuestions.totalCount
    //     ? Math.ceil(+filteredAllAskedQuestions.totalCount / fieldsT["perPage"])
    //     : "";
    //   setFields({
    //     ...fieldsT,
    //   });
    // }
  // }, [filteredAllAskedQuestions])

  const getAllAskedQuestionsData = async () => {
    // props.askedQuestionsListStart({
    //   query: getAllAskedQuestionsQuery,
    //   variables: {
    //     where: {
    //       faqtype: "faq",
    //     },
    //     first: fields.getPage * fields.perPage,
    //     last: Number(fields.perPage),
    //   },
    // });
    setIsLoading(true)
    const finalObj ={
      where: {
        faqtype: "faq",
      },
      first: fields.getPage * fields.perPage,
      last: Number(fields.perPage),
    }
    const res =  await fetchGraphMethod(getAllAskedQuestionsQuery, finalObj, true)
    if(res?.status == 200){
      const resData = res?.data?.data?.allFaqs?.Faqs?.length > 0 ? res?.data?.data?.allFaqs?.Faqs : []
      setAllAskedQuestionsList(resData)
      const filedT = fields
      filedT['pageCount'] = !!res?.data?.data?.allFaqs?.totalCount ?  Math.ceil(
          +res?.data?.data?.allFaqs?.totalCount / filedT["perPage"]
          )
          : 0;
      setFields({...filedT})
      setIsLoading(false)
      window.scrollTo(0,0)
    }else{
      setIsLoading(false)
      // messagePopup("","FAQ Failed","error")
      toastr.error("FAQ Failed", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
      setAllAskedQuestionsList([])
    }
  };

  const handlePageClick = (e) => {
    if (fields.search != "") {
      const fieldsT = fields
      fieldsT['getPage'] = fields.go
      fieldsT['searchPage'] = e.selected
      setFields(fieldsT);
      handleSearch("isPag");
    } else {
      const fieldsT = fields;
      fieldsT["getPage"] = e.selected + 1;
      setFields(fieldsT);
      handlenextPage();
    }
  };
  const handlenextPage = () => {
    getAllAskedQuestionsData();
  };
  const handleInputChange = (e) => {
    e?.preventDefault()
    setFields({
      ...fields,
      search: e.target.value,
    });
    if (e.target.value == "") {
      const fieldT = fields
      fieldT['search'] = ""
      setFields(fieldT);
      getAllAskedQuestionsData();
    //  let filteredQuestions = filteredAllAskedQuestions.data ;
    // if( filteredQuestions)  filteredQuestions.length = 0 ;
    //   setNoData("");
    }
  };

  const handleSearch = async (value) => {
    if (fields.search.length !== 0) {
      // props.searchQuestionsStart({
      //   data: {
      //     searchItem: fields.search,
      //     faqtype: "faq",
      //     limit: Number(fields.perPage),
      //     offset: fields.searchPage * fields.perPage,
      //   },
      // });
      setIsLoading(true)
      let offsetNum = !!value ? +fields?.searchPage :  (parseFloat(fields.searchPage) -1)
      const data = {
        searchItem: fields.search,
            faqtype: "faq",
            limit: parseFloat(fields.perPage),
            offset: offsetNum < 0 ? 0 : offsetNum * (parseFloat(fields.perPage)),
      }
      const resData = await fetchMethod(`/api/Faqs/faqSearch`, data, "POST",true)
      if (resData?.status == 200){
        const _r = resData?.data?.response?.data.length > 0 ? resData?.data?.response?.data : []
        setAllAskedQuestionsList(_r)
        const filedT = fields
        filedT['pageCount'] = !!resData?.data?.response?.totalCount
            ? Math.ceil(
                resData?.data?.response?.totalCount / filedT["perPage"]
            )
            : 0;
        setFields({...filedT})
        setIsLoading(false)
        window.scrollTo(0,0)
      }else{
        // messagePopup("","Search Failed","error")
        toastr.error("Search Failed", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
        setAllAskedQuestionsList([])
        setIsLoading(false)
      }
    }
    setTimeout(()=>{
      window.scrollTo(0,0)
    },300)
  };

  const handleGoToPage = (e) => {
    if((fields.go <= 0 ) || ( fields.perPage <= 0)){
      // messagePopup("", "Invalid Number", "warning");
      toastr.warning("Invalid Number", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }
    if (!!fields?.search) {
      // props.askedQuestionsListStart({
      //   query: getAllAskedQuestionsQuery,
      //   variables: {
      //     where: {
      //       faqtype: "faq",
      //     },
      //     first: fields.go * (fields.perPage ? fields.perPage : 5),
      //     last: (fields.perPage ? Number(fields.perPage) : 5),
      //   },
      // });
      // to set the indexes incase we click go to page
      const fieldT = fields
      fieldT['getPage'] = fields.go
      fieldT['searchPage'] = fields.go
      setFields({ ...fieldT});
      handleSearch()
    }else{
      const fieldT = fields
      fieldT['getPage'] = fields.go
      setFields({ ...fieldT});
      getAllAskedQuestionsData()
    }
    ////$$$$
    //this used for opening the page to the top
    window.scrollTo(0,0)
  };

  ////$$$$
  //this used for opening the page to the top
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <div>
      <BackdropLoader
          open={isLoading
            // questionData && questionData.loading || filteredQuestions && filteredQuestions.loading
          }
      />
      <section className="faq-sec">
      <input className="fakeInput" type="text" ref={fakeInput} />
        <div className="container">
          <div className="faq-box-wrapper">
            <h1 className="sec-title">Frequently Asked Questions</h1>
            <div className="search-box-wrap">
              <div
                  // onSubmit={(e) => handleSearch(e)}
                  className="search-box">
                <input
                  onChange={(e) => handleInputChange(e, "search")}
                  type="text"
                  placeholder="Enter Keyword"
                />
                <button onClick={(e) => handleGoToPage(e)} type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.811"
                    height="13.811"
                    viewBox="0 0 13.811 13.811"
                  >
                    <g className="a" transform="translate(0.75 0.75)">
                      <path
                        className="b"
                        d="M15.167,9.833A5.333,5.333,0,1,1,9.833,4.5,5.333,5.333,0,0,1,15.167,9.833Z"
                        transform="translate(-4.5 -4.5)"
                      ></path>
                      <path
                        className="b"
                        d="M27.875,27.875l-2.9-2.9"
                        transform="translate(-15.875 -15.875)"
                      ></path>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <ul className="faq-listing">
               {/*{allAskedQuestionsList?.Faqs?.length == 0 && filteredAllAskedQuestions?.data?.length == 0 && (*/}
               {/*  <h1>No records found.</h1>*/}
               {/*)}*/}
              {allAskedQuestionsList && allAskedQuestionsList.length > 0
                ? allAskedQuestionsList.map((questionslist, index) => (
                  <li key={index} className="active">
                    <div
                      onClick={(e) =>
                        handleExpandCollapse(expand[questionslist.id], questionslist.id)
                      }
                      className={`faq-title  ${expand[questionslist.id] ? "active" : ""
                        }`}
                    >
                      <span>Q{index + 1}
                        {/*{fields.perPage * (fields.getPage - 1) + index + 1}*/}
                      </span>{" "}
                      {questionslist.question}
                    </div>
                    <div
                      className="faq-data"
                      style={{
                        display: `${expand[questionslist.id] ? "block" : "none"}`,
                      }}
                    >
                      <p>{questionslist.answer} </p>
                    </div>
                  </li>
                ))
                : <p>No Record found</p>}

              {/*{filteredAllAskedQuestions &&*/}
              {/*  filteredAllAskedQuestions.length > 0*/}
              {/*  && filteredAllAskedQuestions.data.map(*/}
              {/*    (filteredQuestions, index) => (*/}
              {/*      <li key={index} className="active">*/}
              {/*        <div*/}
              {/*          onClick={(e) => handleExpandCollapse(expand[filteredQuestions.id], filteredQuestions.id)}*/}
              {/*          className={`faq-title  ${expand[filteredQuestions.id] ? "active" : ""}`}*/}
              {/*        >*/}
              {/*          <span>Q{index + 1}*/}
              {/*            /!*{fields.perPage * (fields.searchPage) + index + 1}*!/*/}
              {/*          </span>{" "}*/}
              {/*          {filteredQuestions.question}*/}
              {/*        </div>*/}
              {/*        <div*/}
              {/*          className="faq-data"*/}
              {/*          style={{ display: `${expand[filteredQuestions.id] ? "block" : "none"}` }}*/}
              {/*        >*/}
              {/*          <p>{filteredQuestions.answer}</p>*/}
              {/*        </div>*/}
              {/*      </li>*/}
              {/*    )*/}
              {/*  )*/}
              {/*  // :*/}
              {/*  // <p>No Record Found</p>*/}
              {/*    // (fields.pageCountT === "" ? <b>No data found</b> : "")*/}
              {/*}*/}
            </ul>
            <div className="paginationBox">
              <div className="perPage">
                <input
                  value={fields.perPage}
                  style={{width:"45px"}}
                  type="number"
                  placeholder="10"
                  onKeydown={(e) =>
                    NumFieldSymbolArr.includes(e.key) 
                    &&
                    e.preventDefault()
                  }
                  onChange={(e)=>((parseInt(e.target.value) <= 0) || (e.target.value == "")) ? console.log("invalid number") :
                      setFields({ ...fields, perPage: e.target.value })
                  }
                />
                <label>Per Page</label>
              </div>
              <div className="pageNav">
                <ReactPaginate
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={fields.pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                  onPageChange={handlePageClick}
                  forcePage={fields.getPage - 1}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
              {allAskedQuestionsList ? (
                <div className="goBox">
                  <input
                      style={{width:"45px"}}
                    value={fields.go}
                    onChange={(e) =>((parseInt(e.target.value) <= 0) || (e.target.value == "")) ? console.log("inter valid number") :
                      setFields({ ...fields, go: e.target.value })
                    }
                    type="number"
                    placeholder="10"
                  />
                  <a onClick={(e) => handleGoToPage(e)}
                  >
                    <span>Go </span>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4.568"
                        height="7.99"
                        viewBox="0 0 4.568 7.99"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <path
                          className="a"
                          d="M1.377,3.993,4.4.972a.569.569,0,0,0,0-.806.576.576,0,0,0-.809,0L.166,3.589a.57.57,0,0,0-.017.787l3.44,3.447A.571.571,0,0,0,4.4,7.017Z"
                          transform="translate(4.568 7.99) rotate(180)"
                        ></path>
                      </svg>
                    </span>
                  </a>
                </div>
              ) : ""}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allAskedQuestions: state.athlete.account.allAskedQuestions,
    allSearchQuestions: state.athlete.account.searchQuestions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    askedQuestionsListStart: (data) => dispatch(askedQuestionsListStart(data)),
    searchQuestionsStart: (data) => dispatch(searchQuestionsStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
