import React, { useState, useEffect } from "react";
import { relationshipListStart } from "../../../../../redux/common/relationships";
import { connect } from "react-redux";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MenuItem, Select } from "@mui/material";
import { getUserInfo } from "../../../../../services/authService";
import { allParentQuery, getAllParentNotifictionMasterQuery } from "./ContactQuery";
import {
  IsValidEmail,
  checkPhoneNumberValidation,
  checkPhoneNumberValidationForAll,
  formattedPhoneNumber,
  IsValidInstagramHandle,
  IsValidTwitterHandle,
  formatPhoneNumber,
} from "../../../../../utils/validationUtils";
import swal from "sweetalert";
import PhoneNumber from "../../../../common/PhoneNumber";
import { countryListStart } from "../../../../../redux/common/countriesList";
import warningMessagePopup from "../../../../../utils/warningMagPop";
import messagePopup from "../../../../../utils/messagePopup";
import { updateParentListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/updateParentData";
import { athleteParentMappingStart } from "../../../../../redux/athlete/onBoarding/athleteParentMapping";
import { getParentNotificationStart } from "../../../../../redux/athlete/dashboard/notification/parentNotification"
import toastr from "toastr";
import {fetchMethod} from "../../../../../services/httpService";
const warningDeleteParentPopup = async (title, text, icon) => {
  const willDelete = await swal({
    title: title,
    text: text,
    icon: icon,
    display: "inline-table",
    buttons: {
      confirm: { text: "Ok" },
      cancel: "Cancel",
    },
    dangerMode: true,
  });
  return willDelete;
};
const AddMoreParent = (props) => {
  const [defaultValue, setDefault] = useState(false);
  const [checkStatus, setcheckStatus] = useState([]);
  const [isParentChange, setisParentChange] = useState(false);
  const [isNotificationChange, setisNotificationChange] = useState(false);
  const [fields, setFields] = useState({
    user: getUserInfo() ? getUserInfo() : 0,
  });
  const [parentList, setParentList] = useState([
    // {
    //   id: 0,
    //   firstName: "",
    //   lastName: "",
    //   primaryEmail: "",
    //   relationshipWithAthlete: "",
    //   countryCodeId: {
    //     value: props.allCountries.data ? props.allCountries.data[0].id : "",
    //     label: props.allCountries.data
    //       ? props.allCountries.data[0].countryName +
    //       `(+${props.allCountries.data[0].countryCode})`
    //       : "",
    //     flag: props.allCountries.data
    //       ? props.allCountries.data[0].countryFlagImage
    //       : "",
    //     countryCode: props.allCountries.data
    //       ? props.allCountries.data[0].countryCode
    //       : "",
    //     countryShortName: props.allCountries.data
    //       ? props.allCountries.data[0].countryShortName
    //       : "",
    //   },
    //   phoneNumber: "",
    //   parentaddresscountryid: "",
    //   parentoutsideusorcanada: 0,
    // },
  ]);
  const [expand, setExpand] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);

  useEffect(() => {
    props.relationshipListStart();
    props.countryListStart();
    getNotificationMasterList()
    getAllParentData();
  }, []);
  const getAllParentData = () => {
    props.allParentListStart({
      query: allParentQuery,
      variables: {
        obj: {
          athleteid: getUserInfo() ? getUserInfo().id : 0,
          isaccessallowed: 1,
          active: 1 //this key is added on 8/4/2002 as per the new modification
        },
      },
    });
  };
  const getNotificationMasterList = () => {
    props.getParentNotificationStart({
      query: getAllParentNotifictionMasterQuery,
      variables: {
        where: {
          active: 1
        },
      },
    })
  }

  useEffect(() => {
    if (props?.allParentData?.data?.parentData){
      props?.setCheckParentLength(props?.allParentData?.data?.parentData?.length)
    }
    if (props?.allParentData?.data?.parentData?.length > 0) {
      let parentListT = [];
      props?.allParentData?.data?.parentData?.map((parent) => {
        let parentData = parent.parentnotificationParentidMap.Parentnotifications?.length ? parent.parentnotificationParentidMap.Parentnotifications.filter(item => item.athleteid == getUserInfo().id) : parent.parentnotificationParentidMap.Parentnotifications;
        parentListT = [
          ...parentListT,
          {
            id: parent.id,
            parentoutsideusorcanada: parent.parentoutsideusorcanada,
            firstName: parent.parentfirstname,
            lastName: parent.parentlastname,
            primaryEmail: parent.parentemail,
            editEmail: parent.parentemail,
            relationshipWithAthlete: parent.relationshipWithAthlete,
            notificationAccess: parentData,
            phoneNumber: parent.parentphonenumber,
            // countryCodeId: {
            //   value: mobileFlagData?.length > 0 ? mobileFlagData[0]?.id : parent.parentCountryCodeId,
            //   label: mobileFlagData?.length > 0
            //     ? mobileFlagData[0].countryName +
            //       `(+${mobileFlagData[0].countryCode})`
            //     : "",
            //   flag: mobileFlagData?.length > 0
            //     ? mobileFlagData[0].countryFlagImage
            //     : "",
            //   countryCode: mobileFlagData?.length > 0
            //     ? mobileFlagData[0].countryCode
            //     : "",
            //   countryShortName: mobileFlagData?.length > 0
            //     ? props.allCountries.data[0].countryShortName
            //     : "",
            // },
            countryCodeId:
              props.allCountries.data &&
              props.allCountries.data
                  //earlier it was parentCountryCodeId
                .filter((item) => +item.id === +parent.parentCountryCodeId || +item.id === +parent.parentaddresscountryid)
                .map((item1) => ({
                  value: item1 ? item1.id : "",
                  label: item1 ? item1.countryName + `(+${item1.countryCode})` : "",
                  flag: item1 ? item1.countryFlagImage : "",
                  countryCode: item1.countryCode ? item1.countryCode : 1,
                  countryShortName: item1.countryShortName,
                }))[0],
            parentaddresscountryid: !!parent.parentaddresscountryid
              ? parent.parentaddresscountryid
              : 0,
                // !!parent?.parentCountryCodeId ? parent?.parentCountryCodeId : "",
            countryCodeValue: !!parent?.parentCountryCodeId ? parent?.parentCountryCodeId
              : null,
          },
        ]; 
        setParentList(parentListT);
        setisParentChange(false)
        setisNotificationChange(false)
      });
    }
  }, [props?.allParentData?.data, props?.allCountries?.data]);

  const handleAddParentValidation = (fieldname, e, index) => {
    let errorsT = errorMsg;
    let tempErr = { ...errorsT[index] };
    if (e.target) {
      let { value } = e.target;
      if (fieldname === "lastName") {
        if (value) {
          tempErr[fieldname] = "";
        } else {
          tempErr[fieldname] = "Last Name is required";
        }
      }
      if (fieldname === "firstName") {
        if (!value) {
          tempErr[fieldname] = "First Name is required";
        } else {
          tempErr[fieldname] = "";
        }
      }
      if (fieldname === "phoneNumber") {
        if (!value) {
          tempErr[fieldname] = "Mobile Number is required";
        }
        // else if (value.length > 10) {
        //   tempErr[fieldname] = "Mobile Number should be less than 11 digits";
        // }
        else if (
          !checkPhoneNumberValidation(value, parentList[index]?.countryCodeId?.countryShortName ?? "US")
        ) {
          tempErr[fieldname] = "Please enter a valid Phone Number";
        } else {
          tempErr[fieldname] = "";
        }
      }
      if (fieldname === "primaryEmail") {
        if (!value) {
          tempErr[fieldname] = "Email Address is required";
        } else if (!IsValidEmail(value)) {
          tempErr[fieldname] = "Please enter Email in correct format";
        } else {
          tempErr[fieldname] = "";
        }
      }

      // relationshipWithAthlete
      if (fieldname === "relationshipWithAthlete") {
        if (value) {
          tempErr[fieldname] = "";
        } else {
          tempErr[fieldname] = "Please Select Relationship With Athlete";
        }
      }
      errorsT[index] = tempErr;
      setErrorMsg(errorsT);
    }
  };

  const handleChange = async (field, e, parentId, index) => {
    setErrorMsg([]);
    let updateParentData = [...parentList];
    // let objIndex = updateParentData.findIndex(
    //   (obj) => obj.id == parseInt(parentId)
    // );
    if (field === "parentoutsideusorcanada") {
      updateParentData[index][`${field}`] = e.target.checked ? 1 : 0;
      updateParentData[index]['parentaddresscountryid'] = null
    }else if(field === "parentaddresscountryid"){
      //this code line 228 added for api call as per discuss with  puja maam
      // updateParentData[index]['countryCodeValue'] = parseInt(e.target.value);
      // updateParentData[index]['parentCountryCodeId'] = parseInt(e.target.value);
      updateParentData[index][`${field}`] = parseInt(e.target.value);
    }
    else if (field == "phoneNumber"){
      if(e?.target?.value?.length < 11){
        // updateParentData[index][`${field}`] = e.target.value
        if(e?.target?.value?.length == 10 && !updateParentData[index]['countryCodeValue']){
          updateParentData[index]['countryCodeValue'] = updateParentData[index]?.countryCodeId?.value;
        }
        updateParentData[index][`${field}`] = formatPhoneNumber(e.target.value)
      }else{
        return
      }
    }
    else {
      updateParentData[index][field] = e.target.value;
    }
    setParentList(updateParentData);
    setisParentChange(true)
    setisNotificationChange(false)
    props.parentDataChange(true, isNotificationChange);
    props?.setIsParentChange(true)
    props?.setIsNotificationStatusChange(false)
    props?.setIsContactStatus(false)
    handleAddParentValidation(field, e, index);
  };

  const handleExpandCollapse = (value ,index, parent) => {
    const state = !!value ? true : false
    const cloneState = [...expand]
    cloneState[index] = !value
    setExpand(cloneState);
    //this code is used to show checkbox checked default
    if(parent?.notificationAccess?.length == 0){
      const isArrayNoty = [
          { "id": 0,
            "parentid": 0,
            "viaemailstatus": 1,
            "viatextstatus": 1,
            "parentnotificationmasterid": 8
          },
          { "id": 0,
          "parentid": 0,
          "viaemailstatus": 0,
          "viatextstatus": 0,
          "parentnotificationmasterid": 9
          },
          { "id": 0,
          "parentid": 0,
          "viaemailstatus": 0,
          "viatextstatus": 0,
          "parentnotificationmasterid": 11
          },
      ]
      let updateParentAccessData = [...parentList]
      updateParentAccessData[index]["notificationAccess"] = isArrayNoty
      setParentList(updateParentAccessData)
    }
  };

  const validateOnSubmit = () => {
    let errorsT = errorMsg;
    let finalTempArr = null;
    parentList.map((item, index) => {
      let tempErr = {};
      for (const [name, value] of Object.entries(item)) {
        if (name === "lastName") {
          if (value) {
            tempErr[name] = "";
          } else {
            tempErr[name] = "Last Name is required";
          }
        }
        if (name === "firstName") {
          if (!value) {
            tempErr[name] = "First Name is required";
          } else {
            tempErr[name] = "";
          }
        }
        if (name === "phoneNumber") {
          if (!value) {
            tempErr[name] = "Mobile Number is required";
          }
          // else if (value.length > 10) {
          //   tempErr[name] = "Mobile Number should be less than 11 digits";
          // }
          else if (
            !checkPhoneNumberValidation(item?.phoneNumber ?? "", item?.countryCodeId?.countryShortName ?? "US")
          ) {
            tempErr[name] = "Please enter a valid Phone Number";
          } else {
            tempErr[name] = "";
          }
        }
        if (name === "primaryEmail") {
          if (!value) {
            tempErr[name] = "Email Address is required";
          } else if (!IsValidEmail(value)) {
            tempErr[name] = "Please enter Email in correct format";
          }
          else {
            tempErr[name] = "";
          }
        }

        // relationshipWithAthlete
        if (name === "relationshipWithAthlete") {
          if (value) {
            tempErr[name] = "";
          } else {
            tempErr[name] = "Please Select Relationship With Athlete";
          }
        }
      }
      errorsT[index] = tempErr;
      finalTempArr = tempErr;
    });
    setErrorMsg(errorsT);
    return finalTempArr;
  };

  const addMoreParent = async (e, parent, index) => {
    e.preventDefault();
    const submitError = validateOnSubmit();
    if (
      submitError.firstName === "" &&
      submitError.lastName === "" &&
      submitError.phoneNumber === "" &&
      submitError.primaryEmail === "" &&
      submitError.relationshipWithAthlete === ""
    ) {
      let parentListT = [...parentList];
      if (parentListT.length >= 5) {
        // messagePopup("", "Parent / Guardian Limit Exceeded", "warning");
        toastr.warning("Parent / Guardian Limit Exceeded", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates:true,
        })
        return;
      }
 const emailObj=[]
      parentListT.map(el=>emailObj.push(el?.primaryEmail))
 const duplicate = emailObj.filter((item, index) => emailObj.indexOf(item) !== index)
      if (duplicate?.length > 0) return messagePopup("","Email already exist for parent / guardian","error")
      const resMail = await fetchMethod("/api/Userdata/checkParent",
          {"parentEmails" : emailObj, "athleteId": getUserInfo()?.id},
          "POST",true)
      if(resMail?.status == 200 && resMail?.data?.response?.status == 200){
        parentListT = [
          ...parentListT,
          {
            id: 0,
            firstName: "",
            lastName: "",
            primaryEmail: "",
            relationshipWithAthlete: "",
            notificationAccess: [],
            countryCodeId: {
              value: props.allCountries.data ? props.allCountries.data[0].id : "",
              label: props.allCountries.data
                  ? props.allCountries.data[0].countryName +
                  `(+${props.allCountries.data[0].countryCode})`
                  : "",
              flag: props.allCountries.data
                  ? props.allCountries.data[0].countryFlagImage
                  : "",
              countryCode: props.allCountries.data
                  ? props.allCountries.data[0].countryCode
                  : "",
              countryShortName: props.allCountries.data
                  ? props.allCountries.data[0].countryShortName
                  : "",
            },
            countryCodeValue: 1,//this key isnot added tha why country flag is not coming selected
            phoneNumber: "",
            parentoutsideusorcanada: 0,
            parentaddresscountryid: 0,
          },
        ];
        setParentList(parentListT);
      }else if (resMail?.status == 200 && resMail?.data?.response?.status == 201){
        const mailMSG =  resMail?.data?.response?.msg ?? "Email ID already connected with five Athlete."
        messagePopup("",mailMSG,"error")
      }else{
        console.log("check email p/g api failed", resMail)
      }
    }
    setDefault(!defaultValue);
    window.scrollTo(0, document.querySelector(".editprofile-common-form li.active div.address-box-wrap:last-child").offsetTop);
  };

  const removeParentData = async (e, index, parentEmailId, parentId) => {
    e.preventDefault();
    if (props.age < 18) {
      if (parentList.length < 1) {
        warningMessagePopup(
          "Warning",
          "Operation can't be performed.Please saved At least one or more parent / guardian.",
          "warning"
        );
        setTimeout(()=>{
          window.scrollTo(0,0)
        },1500)
        return;
      }
      if(parentId != 0){
          let count = 0;
          parentList.map((item) => {
            //earlier it was editEmail
            if (item?.id != 0) {
              count = count + 1;
            }
          });
          if (count < 2) {
            warningMessagePopup(
                "Warning",
                "Operation can't be performed.Please saved At least one or more parent / guardian.",
                "warning"
            );
            setTimeout(()=>{
              window.scrollTo(0,0)
            },1000)
            return;
          }else{
            const willAddParent = warningDeleteParentPopup(
                "Remove access for " + !!parentEmailId ? parentEmailId?.toString()?.length > 25 ?
                    parentEmailId?.slice(0,25) + "..." + "?"  : parentEmailId : ""     + " ?",
                "Are you sure you want to delete this Parent / Guardian and it's access from your account?",
                "warning"
            );
            willAddParent.then(async (decisionValue) => {
              if (decisionValue === true && parentId == 0) {
                let parentListT = [...parentList];
                parentListT.splice(index, 1);
                setParentList([...parentListT]);
                setTimeout(()=>{
                  window.scrollTo(0,0)
                },1000)
                return
              }
              else if (decisionValue === true) {
                let removeParent = {
                  parentId: +parentId,
                  status: "remove",
                  removeAccessFrom: "contact",
                  athleteId: +getUserInfo().id,
                };
                const _r = await fetchMethod('/api/Userdata/removeParentAnduardian', removeParent, "POST", true)
                if (_r?.status == 200) {
                  let parentListT = [...parentList];
                  parentListT.splice(index, 1);
                  setParentList([...parentListT]);
                  // props.updateParentListStart(removeParent);
                  swal({
                    text: " Parent / Guardian Removed Successfully.",
                    icon: "success",
                  }).then((okay) => {
                    if (okay || okay == null) {
                      window.scrollTo(0,0);
                      setTimeout(() => {
                        getAllParentData();
                      }, 400)
                      if (index === 0 && index === parentList.length - 1) {
                      }
                    }
                  });
                }
              }
            });
          }
        } else {
          const willAddParent = warningDeleteParentPopup(
            "Remove access for " + !!parentEmailId ? parentEmailId?.toString()?.length > 25 ?
                parentEmailId?.slice(0,25) + "..." + "?" : parentEmailId : "" + " ?",
            "Are you sure you want to delete this Parent / Guardian and it's access from your account?",
            "warning"
          );
          willAddParent.then(async (decisionValue) => {
            if (decisionValue === true && parentId == 0) {
              let parentListT = [...parentList];
              parentListT.splice(index, 1);
              setParentList([...parentListT]);
              setTimeout(()=>{
                window.scrollTo(0,0)
              },1000)
              return
            }
            else if (decisionValue === true) {
              let removeParent = {
                parentId: +parentId,
                status: "remove",
                removeAccessFrom: "contact",
                athleteId: +getUserInfo().id,
              };
              const _r = await fetchMethod('/api/Userdata/removeParentAnduardian', removeParent, "POST", true)
              if (_r?.status == 200) {
                let parentListT = [...parentList];
                parentListT.splice(index, 1);
                setParentList([...parentListT]);
              // props.updateParentListStart(removeParent);
              swal({
                text: " Parent / Guardian Removed Successfully.",
                icon: "success",
              }).then((okay) => {
                if (okay) {
                  if (index === 0 && index === parentList.length - 1) {
                    //this is not require we have to all the api for it to update the ui
                    // window.location.reload();
                    // props.getAllParentData()
                    // setParentList({
                    //   id: 0,
                    //   firstName: "",
                    //   lastName: "",
                    //   primaryEmail: "",
                    //   relationshipWithAthlete: "",
                    //   countryCodeId: {
                    //     value: props.allCountries.data
                    //       ? props.allCountries.data[0].id
                    //       : "",
                    //     label: props.allCountries.data
                    //       ? props.allCountries.data[0].countryName +
                    //       `(+${props.allCountries.data[0].countryCode})`
                    //       : "",
                    //     flag: props.allCountries.data
                    //       ? props.allCountries.data[0].countryFlagImage
                    //       : "",
                    //     countryCode: props.allCountries.data
                    //       ? props.allCountries.data[0].countryCode
                    //       : "",
                    //     countryShortName: props.allCountries.data
                    //       ? props.allCountries.data[0].countryShortName
                    //       : "",
                    //   },
                    //   phoneNumber: "",
                    //   parentaddresscountryid: "",
                    //   parentoutsideusorcanada: 0,
                    // });
                  }
                }
              });
            }
              // window.location.reload();
            }
          });
        }
    } else {
      const willAddParent = warningDeleteParentPopup(
        "Remove access for " + parentEmailId + " ?",
        "Are you sure you want to delete this Parent / Guardian and it's access from your account?",
        "warning"
      );
      willAddParent.then( async (decisionValue) => {
        if (decisionValue === true && parentId == 0) {
          let parentListT = [...parentList];
          parentListT.splice(index, 1);
          setParentList([...parentListT]);
          setTimeout(()=>{
            window.scrollTo(0,0)
          },1000)
          return
        }
         else if(decisionValue === true) {
          let removeParent = {
            parentId: parentId,
            status: "remove",
            removeAccessFrom: "contact",
            athleteId: getUserInfo().id,
          };
          // props.updateParentListStart(removeParent);
          const _r = await fetchMethod('/api/Userdata/removeParentAnduardian', removeParent, 'POST', true)
          if (_r?.status == 200) {
            let parentListT = [...parentList];
            parentListT.splice(index, 1);
            setParentList([...parentListT]);
            setTimeout(() => {
              getAllParentData();
            }, 400)
          swal({
            text: " Parent / Guardian Removed Successfully.",
            icon: "success",
          }).then((okay) => {
            if (okay) {
              if (index === 0 && index === parentList.length - 1) {
                //this is not require we have to all the api for it to update the ui
                // window.location.reload();
                // props.getAllParentData()

                //as we can delete the parent and gaurdian and no need to add obj

                // setParentList({
                //   id: 0,
                //   firstName: "",
                //   lastName: "",
                //   primaryEmail: "",
                //   relationshipWithAthlete: "",
                //   countryCodeId: {
                //     value: props.allCountries.data
                //       ? props.allCountries.data[0].id
                //       : "",
                //     label: props.allCountries.data
                //       ? props.allCountries.data[0].countryName +
                //       `(+${props.allCountries.data[0].countryCode})`
                //       : "",
                //     flag: props.allCountries.data
                //       ? props.allCountries.data[0].countryFlagImage
                //       : "",
                //     countryCode: props.allCountries.data
                //       ? props.allCountries.data[0].countryCode
                //       : "",
                //     countryShortName: props.allCountries.data
                //       ? props.allCountries.data[0].countryShortName
                //       : "",
                //   },
                //   phoneNumber: "",
                //   parentaddresscountryid: "",
                //   parentoutsideusorcanada: 0,
                // });
              }
            }
          })
        }
          setTimeout(()=>{
            window.scrollTo(0,0)
          },1000)
          // window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    props.parentData(parentList);
  }, [parentList]);

  const handlePhonenumberChange = async (
    field,
    parentId,
    e,
    selectedOption,
    index
  ) => {
    let updateParentCountryData = [...parentList];
    // let objIndex = updateParentCountryData.findIndex(
    //   (obj) => obj.id == parseInt(parentId)
    // );
    //earlier in countryCodeID it is selected and on countryCodeValue it is e.value which is creating the bug
    updateParentCountryData[selectedOption]["countryCodeId"] = e ;
    updateParentCountryData[selectedOption]["countryCodeValue"] = e?.value;
    updateParentCountryData[selectedOption]["phoneNumber"] = "";
    setParentList(updateParentCountryData);
    handleAddParentValidation(field, e, selectedOption);
    setisParentChange(true)
    props.parentDataChange(true, isNotificationChange);
    props?.setIsParentChange(true)
    props?.setIsNotificationStatusChange(false)
    props?.setIsContactStatus(false)
  };

  const saveNotification = async (selectedId, e, parentId, via) => {
    setisNotificationChange(true)
    setisParentChange(false)
    props?.setIsParentChange(false)
    props?.setIsNotificationStatusChange(true)
    props?.setIsContactStatus(false)
    let checkedValue = e.target.checked == true ? 1 : 0
    let updateParentAccessData = [...parentList];
    let objIndex = updateParentAccessData.findIndex(
      (obj) => obj.id == parseInt(parentId)
    );
    let isIdExist = updateParentAccessData[objIndex].notificationAccess.filter(obj => obj.parentnotificationmasterid === selectedId.id)
    if (isIdExist.length == 0) {
      updateParentAccessData[objIndex].notificationAccess.push({ "id": 0, "parentid": parentId, "viaemailstatus": via == 1 ? checkedValue : 0, "parentnotificationmasterid": selectedId.id, viatextstatus: via == 0 ? checkedValue : 0 })
      setParentList(updateParentAccessData);
      props.parentDataChange(isParentChange, true);
    } else {
      let latestArray = updateParentAccessData[objIndex].notificationAccess
      let objIndexValue = latestArray.findIndex((obj => obj.parentnotificationmasterid == selectedId.id));
      if (via == 1) {
        updateParentAccessData[objIndex].notificationAccess[objIndexValue].viaemailstatus = checkedValue
        setParentList(updateParentAccessData)
        props.parentDataChange(isParentChange, false);
      } else {
        updateParentAccessData[objIndex].notificationAccess[objIndexValue].viatextstatus = checkedValue
        setParentList(updateParentAccessData)
        props.parentDataChange(isParentChange, false);
      }

    }
  }

  //this func is for athlete more than 18 yr
  const addAthlete = () =>{
    let parentListT = [...parentList];
    parentListT = [
      ...parentListT,
      {
        id: 0,
        firstName: "",
        lastName: "",
        primaryEmail: "",
        relationshipWithAthlete: "",
        notificationAccess: [],
        countryCodeId: {
          value: props.allCountries.data ? props.allCountries.data[0].id : "",
          label: props.allCountries.data
              ? props.allCountries.data[0].countryName +
              `(+${props.allCountries.data[0].countryCode})`
              : "",
          flag: props.allCountries.data
              ? props.allCountries.data[0].countryFlagImage
              : "",
          countryCode: props.allCountries.data
              ? props.allCountries.data[0].countryCode
              : "",
          countryShortName: props.allCountries.data
              ? props.allCountries.data[0].countryShortName
              : "",
        },
        phoneNumber: "",
        parentoutsideusorcanada: 0,
        parentaddresscountryid: 0,
      },
    ];
    setParentList(parentListT)
  }

  return <>
    {parentList.length == 0 && <div className="form-button-box">
      <button
          className="btn green"
          // disabled={fields.Address != "" ? false : true}
          onClick={(e) => {
            addAthlete()
          }}
      >
        Add Parent / Guardian
      </button>
    </div>
    }
<li className="active">
  {parentList?.length > 0
    ? parentList.map((parent, index) => (
      <div className="address-box-wrap" key={index}>
        <div className="title flex-box">
          <div className="half">
            Parent / Guardian {index + 1}
            {index === 0 ? (
              <span className="checkIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 29.432 29.47"
                >
                  <g id="blueTick" transform="translate(0 0)">
                    <path
                      id="Subtraction_10"
                      data-name="Subtraction 10"
                      d="M13.51.8a1.311,1.311,0,0,1,2.411,0l0,0a1.311,1.311,0,0,0,2.18.363h0a1.311,1.311,0,0,1,2.281.78v.018a1.311,1.311,0,0,0,1.943,1.049l0,0a1.311,1.311,0,0,1,1.9,1.476l-.008.032a1.311,1.311,0,0,0,1.5,1.621l.018,0a1.311,1.311,0,0,1,1.321,2.014l-.025.037a1.311,1.311,0,0,0,.887,2.017l.033.005a1.311,1.311,0,0,1,.593,2.333l-.038.029a1.311,1.311,0,0,0,.182,2.2l.04.021a1.311,1.311,0,0,1-.2,2.4L28.5,17.2a1.311,1.311,0,0,0-.542,2.135l.032.035a1.311,1.311,0,0,1-.97,2.2h-.026a1.311,1.311,0,0,0-1.208,1.844l.016.037a1.311,1.311,0,0,1-1.635,1.769l-.01,0a1.311,1.311,0,0,0-1.743,1.354l0,.026a1.311,1.311,0,0,1-2.122,1.144h0a1.311,1.311,0,0,0-2.09.716l0,.011a1.311,1.311,0,0,1-2.379.4h0a1.311,1.311,0,0,0-2.21,0h0a1.311,1.311,0,0,1-2.379-.4l0-.011a1.311,1.311,0,0,0-2.09-.716h0A1.311,1.311,0,0,1,7.016,26.6l0-.026a1.311,1.311,0,0,0-1.743-1.354l-.01,0a1.311,1.311,0,0,1-1.635-1.769l.016-.037a1.311,1.311,0,0,0-1.208-1.844H2.412a1.311,1.311,0,0,1-.97-2.2l.032-.035A1.311,1.311,0,0,0,.931,17.2l-.038-.013a1.311,1.311,0,0,1-.2-2.4l.04-.021a1.311,1.311,0,0,0,.182-2.2l-.038-.029a1.311,1.311,0,0,1,.593-2.333L1.5,10.2a1.311,1.311,0,0,0,.887-2.017l-.025-.037A1.311,1.311,0,0,1,3.688,6.136l.018,0A1.311,1.311,0,0,0,5.2,4.518l-.008-.032A1.311,1.311,0,0,1,7.1,3.009l0,0A1.311,1.311,0,0,0,9.045,1.962V1.944a1.311,1.311,0,0,1,2.281-.78h0A1.311,1.311,0,0,0,13.508.8Z"
                      transform="translate(0 0)"
                      fill="#00b9ff"
                    ></path>
                    <path
                      id="Icon_feather-check"
                      data-name="Icon feather-check"
                      d="M16.188,9l-7,7.641L6,13.168"
                      transform="translate(3.622 1.914)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                  </g>
                </svg>
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="half">
            {index === 0 && index === parentList.length - 1 && (
              <button
                className="btn add"
                onClick={(e) => addMoreParent(e, parent, index)}
              // disabled={parent.firstName === "" ? true : false}
              >
                Add
              </button>
            )}

            {index === 0 &&
              index === parentList.length - 1 &&
              props?.age > 18 && (
                <button
                  className="btn remove"
                  onClick={(e) => {
                    removeParentData(
                      e,
                      index,
                      parent.primaryEmail,
                      parent.id
                    );
                  }}
                >
                  Remove
                </button>
              )}
            {index !== parentList.length - 1 && (
              <button
                className="btn remove"
                onClick={(e) =>
                  removeParentData(
                    e,
                    index,
                    parent.primaryEmail,
                    parent.id
                  )
                }
              >
                Remove
              </button>
            )}
            {index === parentList.length - 1 && index !== 0 && (
              <>
                <button
                  className="btn add"
                  onClick={(e) => addMoreParent(e, parent, index)}
                // disabled={parent.firstName === "" ? true : false}
                >
                  Add
                </button>
                <button
                  className="btn remove"
                  onClick={(e) =>
                    removeParentData(
                      e,
                      index,
                      parent.primaryEmail,
                      parent.id
                    )
                  }
                >
                  Remove
                </button>
              </>
            )}
          </div>
        </div>
        <div className="fieldBox flexBox">
          <div className="half">
            <label>
              First Name <sup>*</sup>
            </label>
            <input
              onChange={(e) =>
                handleChange(
                  "firstName",
                  {
                    target: {
                      value:e.target.value?.length == 1 ? e.target.value.replace(/[^a-zA-Z]/gi, "") :
                          e.target.value.replace(/[^a-zA-Z-.]/gi, ""),
                    },
                  },
                  parent.id,
                  index
                )
              }
              value={parent.firstName || ""}
              name="parentFname"
              id="parentFname"
              type="text"
              placeholder="First Name"
            />
            {errorMsg?.length > 0 &&
              errorMsg[errorMsg.length - 1] &&
              index === errorMsg.length - 1 ? (
              <div className="error">
                {errorMsg[errorMsg.length - 1].firstName
                  ? errorMsg[errorMsg.length - 1].firstName
                  : ""}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="half">
            <label>
              Last Name <sup>*</sup>
            </label>
            <input
              onChange={(e) =>
                handleChange(
                  "lastName",
                  {
                    target: {
                      value: e.target.value?.length == 1 ? e.target.value.replace(/[^a-zA-Z]/gi, ""):
                          e.target.value.replace(/[^a-zA-Z-.]/gi, ""),
                    },
                  },
                  parent.id,
                  index
                )
              }
              value={parent.lastName || ""}
              name="parentLname"
              id="parentLname"
              type="text"
              placeholder="Last Name"
            />
            {errorMsg?.length > 0 &&
              errorMsg[errorMsg.length - 1] &&
              index === errorMsg.length - 1 ? (
              <div className="error">
                {errorMsg[errorMsg.length - 1].lastName
                  ? errorMsg[errorMsg.length - 1].lastName
                  : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="fieldBox flexBox phone">
          <div className="half">
            <label>
              Mobile Number <sup>*</sup>
            </label>
            <PhoneNumber
              dropdownName="countryCode"
              dropdownOnChange={(e) => {
                handlePhonenumberChange(
                  "countryCodeId",
                  parent.id,
                  e,
                  index
                );
              }}
              dropdownValue={parent.countryCodeId || ""}
              dropDownOptions={
                props.allCountries &&
                props.allCountries.data &&
                props.allCountries.data.map((item) => ({
                  value: item.id,
                  label: item.countryName + `(+${item.countryCode})`,
                  flag: item.countryFlagImage,
                  countryCode: item.countryCode,
                  countryShortName: item.countryShortName,
                }))
              }
              formatOptionLabel={(option, { context, selectValue }) => {
                return context === "menu" ? (
                  <>
                    <img
                      src={option.flag}
                      alt=""
                      style={{ width: "40px", marginRight: "15px" }}
                    />
                    <span>{option.label}</span>
                  </>
                ) : (
                  <>
                    <img
                      src={option.flag}
                      alt=""
                      style={{ width: "40px", marginRight: "15px" }}
                    />
                    <span>+{option.countryCode}</span>
                  </>
                );
              }}
              inputPlaceholder="Phone Number"
              inputType="text"
              inputValue={parent.phoneNumber || ""}
              inputOnChange={(e) => {
                handleChange(
                  "phoneNumber",
                  {
                    target: {
                      value: e.target.value.replace(/[^0-9]/gi, ""),
                    },
                  },
                  parent.id,
                  index
                );
              }}
              inputOnBlur={(e) => {
                handleChange(
                  "phoneNumber",
                  {
                    target: {
                      value: e.target.value.replace(/[^0-9]/gi, ""),
                    },
                  },
                  parent.id,
                  index
                );
              }}
            />
            {/*{errorMsg?.length > 0 &&*/}
            {/*  errorMsg[errorMsg.length - 1] &&*/}
            {/*  index === errorMsg.length - 1 ? (*/}
            {/*  <div className="error">*/}
            {/*    {errorMsg[errorMsg.length - 1].phoneNumber*/}
            {/*      ? errorMsg[errorMsg.length - 1].phoneNumber*/}
            {/*      : ""}*/}
            {/*  </div>*/}
            {/*) : (*/}
            {/*  ""*/}
            {/*)}*/}
            {errorMsg?.length > 0 ?
                  <div className="error">
                    {!!errorMsg[index]?.phoneNumber
                  ? errorMsg[index]?.phoneNumber
                  : ""}
              </div>
                :""
            }
          </div>
          <div className="half">
            <label>
              Primary Email <sup>*</sup>
            </label>
            <input
              onChange={(e) =>
                handleChange("primaryEmail", e, parent.id, index)
              }
              value={parent.primaryEmail || ""}
              id="parentEmail"
              name="parentEmail"
              type="email"
              disabled={parent?.editEmail ? true : false}
              placeholder="Primary Email"
            />
            {errorMsg?.length > 0 &&
              errorMsg[errorMsg.length - 1] &&
              index === errorMsg.length - 1 ? (
              <div className="error">
                {errorMsg[errorMsg.length - 1].primaryEmail
                  ? errorMsg[errorMsg.length - 1].primaryEmail
                  : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="fieldBox flexBox">
          <div className="half">
            <label>
              Relationship with Athlete
              <sup>*</sup>
            </label>
            <select
              id="demo-simple-select-outlined"
              value={+parent.relationshipWithAthlete || ""}
              onChange={(e) =>
                handleChange(
                  "relationshipWithAthlete",
                  e,
                  parent.id,
                  index
                )
              }
              label="Select"
            >
              <option value="">Select Relationship</option>
              {props.allRelationships.data &&
                props.allRelationships.data.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.relationShipName}
                  </option>
                ))}
            </select>
            {errorMsg?.length > 0 &&
              errorMsg[errorMsg.length - 1] &&
              index === errorMsg.length - 1 ? (
              <div className="error">
                {errorMsg[errorMsg.length - 1].relationshipWithAthlete
                  ? errorMsg[errorMsg.length - 1].relationshipWithAthlete
                  : ""}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="half">
            <p className="checkbox-list">
              <span>
                <input
                  onChange={(e) =>
                    handleChange(
                      "parentoutsideusorcanada",
                      e,
                      parent.id,
                      index
                    )
                  }
                  checked={parent.parentoutsideusorcanada}
                  type="checkbox"
                />
                <label>Check if you live outside the US or Canada</label>
              </span>
            </p>
          </div>
        </div>
        {parent.parentoutsideusorcanada ? (
          <div className="fieldBox flexBox">
            <div className="half">
              <label>Country</label>
              <select
                name="countries"
                id="demo-simple-select-outlined"
                value={parent.parentaddresscountryid || ""}
                //earlier key is parentaddresscountryid
                onChange={(e) =>
                  handleChange(
                    "parentaddresscountryid",
                    e,
                    parent.id,
                    index
                  )
                }
                label="Select"
              >
                <option value="">Select Country</option>
                {props.allCountries.data &&
                  props.allCountries.data.map((item, index) => {
                    if(item?.id != 1 && item?.id != 2){
                     return <option key={index} value={item.id}>
                                {item.countryName}
                            </option>
                    }
                  })}
              </select>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className="notification-setting-box"
        >
          <div
            // className={`notification-title  ${expand ? 'active' : ""}`}
            className={
              // !!expand[index] == true
              //   ? "notification-title  active"
              //   : "notification-title"
                "notification-title after-remove"
            }
            // onClick={(e) => handleExpandCollapse(expand[index],index)}
          >
            Notification Settings
            <span
                onClick={(e) => handleExpandCollapse(expand[index],index, parent)}
                className={`notification-btn-toggle ${!!expand?.[index] == true && "active"}`}>
            </span>
          </div>
          <div
            className="notification-content"
            id={"noti" + index}
            // styles ={expand == true ? "block" : "none"}
            style={{
              display: expand[index] ? "block" : "none",
            }}
          >
            <div className="flexbox">
              <div className="item">Notify me when</div>
              <div className="item">Via <span className="text">Email</span></div>
              <div className="item">Via <span className="text">Text / SMS</span></div>
            </div>
            {props.allNotificationMasterData && props.allNotificationMasterData.data ?
              props.allNotificationMasterData.data.map((item, indexValue) => (
                <>
                  <div className={"flexbox"} key={indexValue}>
                    <div className="item">
                      {item.notificationlablename}
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input
                            type="checkbox"
                            checked={parent.notificationAccess == "" ? false : parent.notificationAccess == undefined ? 0 : ((parent.notificationAccess).filter(obj => obj.parentnotificationmasterid === item.id).length == 0 ? false : ((parent.notificationAccess).filter(obj => obj.parentnotificationmasterid === item.id)[0].viaemailstatus == 0 ? false : true))}
                            id={"email" + index + item.id}
                            onChange={(e) => { saveNotification(item, e, parent.id, "1") }}
                          />{" "}
                          <label htmlFor={"email" + index + item.id}></label>
                        </span>
                      </p>
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox"
                            id={"sms" + index + item.id}
                            checked={parent.notificationAccess == "" ? false : parent.notificationAccess == undefined ? 0 : ((parent.notificationAccess).filter(obj => obj.parentnotificationmasterid === item.id).length == 0 ? false : ((parent.notificationAccess).filter(obj => obj.parentnotificationmasterid === item.id)[0].viatextstatus == 0 ? false : true))}
                            onChange={(e) => { saveNotification(item, e, parent.id, "0") }}
                          />{" "}
                          <label htmlFor={"sms" + index + item.id}></label>
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              ))
              : null}
            {/* <div className="flexbox">
                    <div className="item">
                      My profile is viewed by a college coach
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="email2" />{" "}
                          <label htmlFor="email2"></label>
                        </span>
                      </p>
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="sms2" />{" "}
                          <label htmlFor="sms2"></label>
                        </span>
                      </p>
                    </div>
                  </div> */}
            {/* <div className="flexbox">
                    <div className="item">
                      My video is viewed by a college coach
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="email3" />{" "}
                          <label htmlFor="email3"></label>
                        </span>
                      </p>
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="sms3" />{" "}
                          <label htmlFor="sms3"></label>
                        </span>
                      </p>
                    </div>
                  </div> */}
            {/* <div className="flexbox">
                    <div className="item">
                      My document is viewed by a college coach
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="email4" />{" "}
                          <label htmlFor="email4"></label>
                        </span>
                      </p>
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="sms4" />{" "}
                          <label htmlFor="sms4"></label>
                        </span>
                      </p>
                    </div>
                  </div> */}
            {/* <div className="flexbox">
                    <div className="item">
                      My team's game film is viewed by a college coach on event
                      beacon on demand
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="email5" />{" "}
                          <label htmlFor="email5"></label>
                        </span>
                      </p>
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="sms5" />{" "}
                          <label htmlFor="sms5"></label>
                        </span>
                      </p>
                    </div>
                  </div> */}
            {/* <div className="flexbox">
                    <div className="item">When followed by another profile</div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="email6" />{" "}
                          <label htmlFor="email6"></label>
                        </span>
                      </p>
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="sms6" />{" "}
                          <label htmlFor="sms6"></label>
                        </span>
                      </p>
                    </div>
                  </div> */}
            {/* <div className="flexbox">
                    <div className="item">When followed by a college</div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="email7" />{" "}
                          <label htmlFor="email7"></label>
                        </span>
                      </p>
                    </div>
                    <div className="item">
                      <p className="checkbox-list">
                        <span>
                          <input type="checkbox" id="sms7" />{" "}
                          <label htmlFor="sms7"></label>
                        </span>
                      </p>
                    </div>
                  </div> */}
          </div>
        </div>
      </div>

    ))
    : ""}
</li>
  </>;
};

const mapStateToProps = (state) => {
  return {
    allRelationships: state.common.relationships,
    allCountries: state.common.countries,
    allParentData: state.athlete.onBoarding.athleteParentMapping,
    allNotificationMasterData: state.athlete.dashboard.notification.parentNotificationMaterList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    relationshipListStart: (data) => dispatch(relationshipListStart(data)),
    countryListStart: (data) => dispatch(countryListStart(data)),
    updateParentListStart: (data) => dispatch(updateParentListStart(data)),
    allParentListStart: (data) => dispatch(athleteParentMappingStart(data)),
    getParentNotificationStart: (data) => dispatch(getParentNotificationStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMoreParent);
