import { combineReducers } from "redux";
import sportsListReducer from "./allSports";
import stateListReducer from "./allStates";
import countryListReducer from "./countriesList";
import graduationMonthsListReducer from "./graduationMonth";
import graduationYearsListReducer from "./graduationYears";
import relationshipListReducer from "./relationships";
import userListReducer from "./userData";
import allSportsListReducer from "./allSportWithOutUsingGender";
import profileSectionReducer from "./profileSection";
import commitmentStatusReducer from "./commitmentStatus";
import allCollegeDetailsReducer from "./allCollegeData";
import annualHouseholdIncomeListReducer from "./allAnnualhouseholdincomes";
import racialEthnicHeritageListReducer from "./allRacialethnicheritages";
import satMathListReducer from "./satMathList";
import satReadingAndWritingListReducer from "./satReadingAndWritingList";
import psatMathListReducer from "./psatMathList";
import psatReadingAndWritingListReducer from "./psatReadingAndWritingList";
import actCompositeScoresListReducer from "./actCompositeScores";
import academicNotesListReducer from "./allAcademicNotes";
import reportCardListReducer from "./reportCard";
import apIbClassesTimePeroidListReducer from "./apIbTimePeriod";
import offerListReducer from "./offerList";
import interestLevelListReducer from "./interestLevelList";
import organizationsListReducer from "./allOrganizationsList";
import categoriesListReducer from "./allCatergoriesList";
import athletesDetailsReducer from "./athletesDetails";
import allCheckListDataReducer from "./allCheckListData";
import allProfileCalculationListReducer from "./allProfileCalculationMaster";
import saveCreatePaymentReducer from "./savePayments";
import countryDependentListReducer from './countryDependentList';
import allAthletesReducer from "./allAthleteData";
import photoVideoViewsReducer from "./allPhotoVideoViews";
import regionsListReducer from "./allRegions";
import allCollegeConferencesReducer from "./allCollegeConferences";
import landingPageReducer from './landingPage'

export default combineReducers({
  countries: countryListReducer,
  countryDependent: countryDependentListReducer,
  states: stateListReducer,
  graduationYears: graduationYearsListReducer,
  sportsList: sportsListReducer,
  relationships: relationshipListReducer,
  graduationMonths: graduationMonthsListReducer,
  userData: userListReducer,
  allSportList: allSportsListReducer,
  profileSection: profileSectionReducer,
  commitmentStatus: commitmentStatusReducer,
  collegeDetails: allCollegeDetailsReducer,
  annualHouseholdIncomes: annualHouseholdIncomeListReducer,
  racialEthnicHeritages: racialEthnicHeritageListReducer,
  satMathList: satMathListReducer,
  satReadingAndWritingList: satReadingAndWritingListReducer,
  psatMathList: psatMathListReducer,
  psatReadingAndWritingList: psatReadingAndWritingListReducer,
  actCompositeScoresList: actCompositeScoresListReducer,
  academicNotesList: academicNotesListReducer,
  reportCardList: reportCardListReducer,
  apIbClassesTimePeroidList: apIbClassesTimePeroidListReducer,
  offerList: offerListReducer,
  interestLevelList: interestLevelListReducer,
  organizations: organizationsListReducer,
  categories: categoriesListReducer,
  athletesData: athletesDetailsReducer,
  checkListData: allCheckListDataReducer,
  profileCalculationData: allProfileCalculationListReducer,
  saveCreatePayment: saveCreatePaymentReducer,
  allAthletes : allAthletesReducer,
  photoVideoViews: photoVideoViewsReducer,
  regionsList : regionsListReducer,
  collegeConferences : allCollegeConferencesReducer,
  landingPageType : landingPageReducer
});
