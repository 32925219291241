import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_HOW_TO_VIDEO_START: "GET_HOW_TO_VIDEO_START",
  HOW_TO_VIDEO_SUCCESS: "HOW_TO_VIDEO_SUCCESS",
  HOW_TO_VIDEO_FAILURE: "HOW_TO_VIDEO_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const getHowToVideoStart = (data) => ({
  type: actionType.GET_HOW_TO_VIDEO_START,
  payload: data,
});

export const HowToVideoSuccess = (data) => ({
  type: actionType.HOW_TO_VIDEO_SUCCESS,
  payload: data,
});

export const HowToVideoFailure = (data) => ({
  type: actionType.HOW_TO_VIDEO_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function howToVideoReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_HOW_TO_VIDEO_START:
      return { ...state, loading: true };

    case actionType.HOW_TO_VIDEO_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.HOW_TO_VIDEO_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}
//func for making filed section
const dataSection = (value) =>{
  let oriData = value.Uploadedvideoandphotos,
      oriArray = [],
      dataSec = []
  if(oriData.length > 0){
    oriData.map(el => oriArray.push(el?.uploadedvideoandphotoadminuploadedvideotypeidmaprel?.Videotypes[0].videotypename))
  }
  let arrayTitle = [...new Set(oriArray)]
  if(arrayTitle.length > 0){
    for (var i = 0; i<= arrayTitle.length -1; i++){
      const obj = {
        title : arrayTitle[i],
        data:[]
      }
      oriData.map((data, indx)=>{
        if(data?.uploadedvideoandphotoadminuploadedvideotypeidmaprel?.Videotypes[0].videotypename === obj.title){
          obj['data'].push(data)
        }
      })
      dataSec.push(obj)
    }
  }
  return dataSec
}

/* ======================================= Side Effects ==========================================*/

function*  getHowToVideoSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200) {
      const allHowToVideoList = response.data.data.allUploadedvideoandphotos
        ? response.data.data.allUploadedvideoandphotos
          : null;
      yield put(HowToVideoSuccess({
        howToVid: allHowToVideoList,
        dataSection:dataSection(allHowToVideoList)
      }));
    } else {
      messagePopup("", "Fetch Video List Failed", "error");
      yield put(HowToVideoFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch Video List Failed", "error");
    yield put(HowToVideoFailure("Failed, Error occured"));
  }
}

export function* watchHowToVideo() {
  yield takeEvery(actionType.GET_HOW_TO_VIDEO_START, getHowToVideoSaga);
}
