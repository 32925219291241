import { put, takeEvery } from "redux-saga/effects";
import { API_URL, imageDownloadUrl } from "../../config";
import { fetchGraphMethod, fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  COUNTRY_LIST_START: "COUNTRY_LIST_START",
  COUNTRY_LIST_SUCCESS: "COUNTRY_LIST_SUCCESS",
  COUNTRY_LIST_FAILED: "COUNTRY_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const countryListStart = () => ({
  type: actionType.COUNTRY_LIST_START,
});

export const countryListSuccess = (data) => ({
  type: actionType.COUNTRY_LIST_SUCCESS,
  payload: data,
});

export const countryListFailed = (data) => ({
  type: actionType.COUNTRY_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function countryListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.COUNTRY_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.COUNTRY_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* countryListSaga() {
  try {
    // const response = yield fetchGraphMethod(action.payload.query,null,false);
    const response = yield fetchMethod(`/allCountry`, null, "get", false);
    if (response && response.status === 200) {
      const allCountries = response.data.data
        ? response.data.data.map((item) => {
            if (item.countryFlagImage) {
              item.countryFlagImage = `${imageDownloadUrl}/${item.countryFlagImage}`;
            }
            // if (item.countryCode) {
            //   if (item.countryName === "Canada") {
            //     item.countryCode = `${item.countryCode} `;
            //   } else {
            //     item.countryCode = `${item.countryCode}`;
            //   }
            // }
            return item;
          })
        : null;
      yield put(countryListSuccess(allCountries));
    } else {
      messagePopup("", "Country List failed", "error");
      yield put(countryListFailed("Country List failed"));
    }
  } catch (error) {
    messagePopup("", "Country List failed", "error");
    yield put(countryListFailed("Country List failed"));
  }
}

export function* watchCountryListSaga() {
  yield takeEvery(actionType.COUNTRY_LIST_START, countryListSaga);
}
