import { put, takeEvery } from "redux-saga/effects";
import { imageDownloadUrl } from "../../../config";
import { getAuthToken, getUserInfo } from "../../../services/authService";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
// import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  PROFILE_TIMELINE_START: "PROFILE_TIMELINE_START",
  PROFILE_TIMELINE_SUCCESS: "PROFILE_TIMELINE_SUCCESS",
  PROFILE_TIMELINE_FAILED: "PROFILE_TIMELINE_FAILED",
};

// ============================================ Actions ========================================================
export const profileTimelineStart = (data) => ({
  type: actionType.PROFILE_TIMELINE_START,
  payload: data,
});

export const profileTimelineSuccess = (data) => ({
  type: actionType.PROFILE_TIMELINE_SUCCESS,
  payload: data,
});

export const profileTimelineFailed = (data) => ({
  type: actionType.PROFILE_TIMELINE_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function profileTimelineReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.PROFILE_TIMELINE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.PROFILE_TIMELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.PROFILE_TIMELINE_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* profileTimelineSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Userposts/timeLine`,
      action.payload.data,
      "post",
      action.payload.data.isAccessToken ? true : false
    );
    if (
      response &&
      response.status === 200 &&
      response.data?.response?.data?.ShareData !== []
    ) {
      const data = response.data.response.data.ShareData.myFeedData;
      let finalData = [];
      if (data.allFavCollege && data.allFavCollege.length) {
        finalData = [
          ...finalData,
          ...data.allFavCollege.map((item) => {
            item["postType"] = "favorite";
            if (item.profileImages) {
              item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.Event && data.Event.length) {
        finalData = [
          ...finalData,
          ...data.Event.map((item) => {
            item["postType"] = "event";
            if (item.profileImages) {
              item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.allEvent && data.allEvent.length) {
        finalData = [
          ...finalData,
          ...data.allEvent.map((item) => {
            item["postType"] = "eventNew";
            if (item.profileImages) {
              item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.allMessage && data.allMessage.length) {
        finalData = [
          ...finalData,
          ...data.allMessage.map((item) => {
            item["postType"] = "message";
            if (item.msgSendByProfileImage) {
              item.msgSendByProfileImage = `${imageDownloadUrl}/${item.msgSendByProfileImage}`;
            }
            if (item.msgSendToProfileImage) {
              item.msgSendToProfileImage = `${imageDownloadUrl}/${item.msgSendToProfileImage}`;
            }
            return item;
          }),
        ];
      }
      if (data.allPost && data.allPost.length) {
        finalData = [
          ...finalData,
          ...data.allPost.map((item) => {
            item["postType"] = "post";
            if (item.profileImages) {
              item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.allProfile && data.allProfile.length) {
        finalData = [
          ...finalData,
          ...data.allProfile.map((item) => {
            item["postType"] = "profile";
            if (item.profileViewedByImageName) {
              item.profileViewedByImageName = `${imageDownloadUrl}/${item.profileViewedByImageName}`;
            }
            if (item.profileViewedToImageName) {
              item.profileViewedToImageName = `${imageDownloadUrl}/${item.profileViewedToImageName}`;
            }
            return item;
          }),
        ];
      }
      const sortedData = finalData.sort((a, b) => {
        return b.serialNo - a.serialNo;
      });
      yield put(
        profileTimelineSuccess({
          allData: sortedData,
          totalCount: data.totalCount,
        })
      );
    }else {
      // messagePopup("", "No data found", "error");
      yield put(profileTimelineFailed("Timeline data failed"));
    }
  } catch (error) {
    // messagePopup("", "Server Error", "error");
    yield put(profileTimelineFailed("Timeline data failed"));
  }
}

export function* watchProfileTimelineSaga() {
  yield takeEvery(actionType.PROFILE_TIMELINE_START, profileTimelineSaga);
}
