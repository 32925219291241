import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import MuiAutoComplete from '../../../../../common/AutoComplete';
import { getCamelCase } from '../../../../../common/camelCaseFunc';
import {
  getMySignedCommitmentQuery,
  saveSignedCommitment,
} from '../../homepage/leftSideBar/query';
import {
  fetchGraphMethod,
  fetchMethod,
} from '../../../.../../../../../services/httpService';
import { getUserInfo } from '../../../../../../services/authService';
import { MenuItem, Select, FormControl, Dialog } from '@mui/material';
import {
  getAllSignedCommitmentType,
  userCollegeUpdateAll,
} from './profileQuery';
import { Link } from 'react-router-dom';
import ScreenSwitch from '../../editUserProfile/ScreenSwitch';
import {
  getColonSlotFormatedDate,
  getUTCTimeDateOverall,
  getUTCTimeDate,
} from '../../../../../../utils/dateTimeFormatter';
import messagePopup from '../../../../../../utils/messagePopup';
import swal from 'sweetalert';
import {
  sendAddSignedCommitmentNotification,
  sendAddOfferNotification,
  sendAddCommitmentNotification,
  sendRemoveCommitmentNotification,
  sendRemoveSignedCommitmentNotification,
} from '../../../../../../utils/sendNotificationToAdvisor';
import toastr from 'toastr';
import { getAthleteSysID } from '../../../../../common/getProfileURLData';

const selectStyle = {
  position: 'relative',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1.4,
  display: 'block',
  marginBottom: '0.5em',
  letterSpacing: '0.00938em',
};

const SignedCommitment = forwardRef((props, ref) => {
  const currentDate = new Date().toISOString().split('T')[0];
  const [signedFields, setSignedFields] = useState({
    typeOfSignedCommitmentId: '',
    signedDate: currentDate,
    isvisible: true,
  });
  const [signedErrors, setSignedErrors] = useState({});
  const [showSignedAddModal, setShowSignedAddModal] = useState(false);
  const [changeSignedCommitmentDisabled, setChangeSignedCommitmentDisabled] =
    useState(true);
  const [isSignedSaved, setIsSignedSaved] = useState(false);
  const [mySignedCommitment, setMySignedCommitment] = useState([]);
  const [mySignedCommitmentType, setMySignedCommitmentType] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  //This hook used for using child function from parent component.
  useImperativeHandle(ref, () => ({
    inActiveSignedComm: async () => {
      if (signedFields.commitmentId) {
        if (parseInt(signedFields.commitmentId) > 0) {
          await fetchGraphMethod(
            saveSignedCommitment,
            {
              obj: {
                id: parseInt(signedFields.commitmentId),
                active: 0,
              },
            },
            true
          ).then((res) => {
            sendRemoveSignedCommitmentNotification(
              signedFields?.collegeName &&
                signedFields?.collegeName?.collegename
                ? signedFields?.collegeName?.collegename?.split('-')?.[0]
                : ''
            );
          });
        }
        await getMySignedCommitment();
      }
    },
  }));

  useEffect(async () => {
    await getMySignedCommitment();

    await fetchGraphMethod(getAllSignedCommitmentType, null, true).then(
      (res) => {
        if (res?.data?.data?.allSignedcommitmenttypes) {
          setMySignedCommitmentType(
            res?.data?.data?.allSignedcommitmenttypes?.Signedcommitmenttypes
          );
        }
      }
    );
  }, []);

  useEffect(() => {
    if (mySignedCommitment && mySignedCommitment.length !== 0) {
      let fieldsT = signedFields;
      fieldsT = {
        collegeName: {
          id:
            mySignedCommitment && mySignedCommitment.length !== 0
              ? mySignedCommitment[0].collegeid
              : 0,
          collegename:
            mySignedCommitment && mySignedCommitment.length !== 0
              ? `${
                  mySignedCommitment[0]?.signedcommitmentcollegeidmaprel
                    ?.Colleges?.[0]?.collegename ?? ''
                } - [${
                  !!mySignedCommitment[0]?.signedcommitmentcollegeidmaprel
                    ?.Colleges?.[0]?.city
                    ? getCamelCase(
                        mySignedCommitment[0]?.signedcommitmentcollegeidmaprel
                          ?.Colleges?.[0]?.city
                      )
                    : ''
                }, ${
                  !!mySignedCommitment[0]?.signedcommitmentcollegeidmaprel
                    ?.Colleges?.[0]?.state
                    ? getCamelCase(
                        mySignedCommitment[0]?.signedcommitmentcollegeidmaprel
                          ?.Colleges?.[0]?.state
                      )
                    : ''
                }]`
              : '',
        },
        typeOfSignedCommitmentId:
          mySignedCommitment && mySignedCommitment.length !== 0
            ? parseInt(mySignedCommitment[0].commitmenttype)
            : '',
        signedDate:
          mySignedCommitment && mySignedCommitment.length !== 0
            ? !!mySignedCommitment[0].commitmentdate
              ? getUTCTimeDate(mySignedCommitment[0].commitmentdate)
              : currentDate
            : currentDate,
        commitmentId:
          mySignedCommitment && mySignedCommitment.length !== 0
            ? mySignedCommitment[0].id
            : '',
        isvisible: true,
      };
      setSignedFields({
        ...fieldsT,
      });
    } else {
      setSignedFields({
        collegeName:
          props.signedCollegeAuto && props.signedCollegeAuto !== ''
            ? props.signedCollegeAuto
            : null,
        typeOfSignedCommitmentId: '',
        signedDate: currentDate,
        commitmentId: '',
        isvisible: true,
      });

      if (props.signedCollegeAuto && props.signedCollegeAuto !== '') {
        setIsSignedSaved(false);
      }
    }
  }, [mySignedCommitment]);

  const getMySignedCommitment = async () => {
    await fetchGraphMethod(
      getMySignedCommitmentQuery,
      {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
      true
    ).then((res) => {
      if (res?.data?.data?.allSignedcommitments?.totalCount) {
        setMySignedCommitment(
          res?.data?.data?.allSignedcommitments?.Signedcommitments
        );
      } else {
        setMySignedCommitment([]);
      }
    });
  };

  const handleSignedChange = (field, e) => {
    let fieldsT = signedFields;
    if (field == 'isvisible') {
      fieldsT[field] = e ? (e.target ? e.target.checked : e) : e;
    } else {
      fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    }

    if (fieldsT['typeOfSignedCommitmentId']) {
      setShowSignedAddModal(true);
    } else {
      setShowSignedAddModal(false);
    }
    setSignedFields({
      ...fieldsT,
    });
    setChangeSignedCommitmentDisabled(false);
    setIsSignedSaved(false);
    setSignedErrors({});
  };

  const clearDate = (e) => {
    e.preventDefault();
    if (signedFields.signedDate) {
      let fieldsT = signedFields;
      fieldsT['signedDate'] = '';
      setChangeSignedCommitmentDisabled(false);
      setSignedFields({
        ...fieldsT,
      });
    }
  };

  const routeBackToProfile = () => {
    props.history.push(
      { pathname: `${getAthleteSysID()}`, state: { isPublic: false } }
      // "/profile/1"
    );
    sessionStorage.setItem('athlete_own_profile', true);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleSaveModal = () => {
    setShowSaveModal(!showSaveModal);
  };

  const saveAfterCheckingError = async () => {
    let errorsT = signedErrors;
    if (signedFields.collegeName !== null) {
      if (signedFields.collegeName.collegename === '') {
        errorsT['collegeName'] = 'Please select your college name.';
        setShowSaveModal(false);
      } else {
        if (
          props.commitCollgId !== null &&
          props.commitCollgId !== parseInt(signedFields?.collegeName?.id)
        ) {
          const res = await swal({
            text: 'Would you like to remove your current commitment to the other college?',
            icon: 'warning',
            buttons: {
              confirm: { text: 'Yes' },
              cancel: 'Cancel',
            },
          });
          if (res) {
            setShowSaveModal(true);
          }
        } else {
          setShowSaveModal(true);
        }
      }
    } else {
      errorsT['collegeName'] = 'Please select your college name.';
      setShowSaveModal(false);
    }
    setSignedErrors({
      ...errorsT,
    });
  };

  const handelDeleteSignedCommitment = async () => {
    if (signedFields.commitmentId) {
      if (parseInt(signedFields.commitmentId) > 0) {
        await fetchGraphMethod(
          saveSignedCommitment,
          {
            obj: {
              id: parseInt(signedFields.commitmentId),
              active: 0,
            },
          },
          true
        ).then((res) => {});

        await fetchGraphMethod(
          userCollegeUpdateAll,
          {
            where: {
              userid: getUserInfo() ? getUserInfo().id : 0,
              collegeid: parseInt(signedFields?.collegeName?.id),
            },
            data: {
              signed: 0,
            },
          },
          true
        ).then((res) => {});
        sendRemoveSignedCommitmentNotification(
          signedFields?.collegeName?.collegename?.split('-')?.[0]
        );
        messagePopup('', 'Signed commitment deleted successfully.', 'success');
        setShowDeleteModal(false);
        setSignedErrors({});
        await getMySignedCommitment();
      }
    }
  };

  const handelSignedCommitment = async (isChanged) => {
    if (isChanged) {
      await fetchMethod(
        '/api/Signedcommitments/addsignedcommitement',
        {
          collegeId: parseInt(signedFields?.collegeName?.id),
          userId: getUserInfo() ? getUserInfo().id : 0,
          commitmentType: String(signedFields.typeOfSignedCommitmentId),
          commitmentdate: signedFields.signedDate,
          collegename: signedFields?.collegeName?.collegename?.split('-')?.[0],
          id: parseInt(signedFields.commitmentId),
        },
        'post',
        true
      ).then((res) => {
        console.log('favres----', res);
      });
      setShowSaveModal(false);
      setIsSignedSaved(true);
      // messagePopup("", `Signed Commitment Updated Successfully.`, "success");
      toastr.success('Signed Commitment Updated Successfully.', '', {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: 'toast-top-full-width',
        showEasing: 'swing',
        fadeIn: 40000,
        preventDuplicates: true,
      });
    } else {
      await fetchMethod(
        '/api/Signedcommitments/addsignedcommitement',
        {
          collegeId: parseInt(signedFields?.collegeName?.id),
          userId: getUserInfo() ? getUserInfo().id : 0,
          commitmentType: String(signedFields.typeOfSignedCommitmentId),
          commitmentdate: signedFields.signedDate,
          collegename: signedFields?.collegeName?.collegename?.split('-')?.[0],
          id: 0,
        },
        'post',
        true
      ).then((res) => {});
      sendAddSignedCommitmentNotification(
        signedFields?.collegeName?.collegename?.split('-')?.[0]
      );
      setShowSaveModal(false);
      setIsSignedSaved(true);
      props.history.push(
        `/signedcommitmentstory/${signedFields?.collegeName?.id}/${
          getUserInfo()?.id
        }`
      );
      messagePopup(
        '',
        `Congratulations on your Signed Commitment to ${signedFields.collegeName.collegename}.`,
        'success'
      );
    }
  };

  return (
    <>
      <div className="fieldbox">
        {/* <div className="flexRow">
          <div className="title">Signed Commitment</div>
        </div> */}
        <div className="subTitle">Announce Signed Commitment</div>
        <div className="flexRow">
          <div className="col">
            <label>
              College Name <sup>*</sup>
            </label>
            <MuiAutoComplete
              id="free-solo-demo1"
              freeSolo={true}
              options={props.collegeList || null}
              getOptionLabel={(option) =>
                option.collegename && option?.city && option?.state
                  ? `${!!option.collegename ? option.collegename : ''} - [${
                      !!option?.city ? getCamelCase(option?.city) : ''
                    }, ${!!option?.state ? getCamelCase(option?.state) : ''}]`
                  : option?.collegename
              }
              value={signedFields.collegeName || null}
              disabled={
                mySignedCommitment && mySignedCommitment.length !== 0
                  ? true
                  : false
              }
              onChangeObj={(e, data) => {
                handleSignedChange('collegeName', {
                  target: { value: data },
                });
              }}
              inputPlaceholder="College Name"
            />
            <div className="error">{signedErrors['collegeName']}</div>
          </div>
        </div>

        <div className="flexRow">
          <div className="col">
            <div className="form-group">
              <span style={selectStyle}>Signed</span>
              <div className="fieldBox">
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="demo-simple-select-outlined"
                    value={signedFields.typeOfSignedCommitmentId || ''}
                    onChange={(e) =>
                      handleSignedChange('typeOfSignedCommitmentId', e)
                    }
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    {mySignedCommitmentType &&
                    mySignedCommitmentType.length !== 0
                      ? mySignedCommitmentType.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="error">{signedErrors['signed']}</div>
          <div className="col">
            <label>Signed Date</label>
            <div className="position-relative">
              <input
                type="date"
                placeholder="Signed Date"
                name="signedDate"
                id="signedDate"
                value={signedFields.signedDate || ''}
                onChange={(e) => handleSignedChange('signedDate', e)}
                max="9999-12-31"
              />
              {signedFields.signedDate !== '' ? (
                <span className="date-close-icon" onClick={(e) => clearDate(e)}>
                  &times;
                </span>
              ) : null}
            </div>
            <div className="note">
              Date displayed with this signed commitment story
            </div>
          </div>
          <div className="error">{signedErrors['signedDate']}</div>
        </div>

        {showSignedAddModal &&
        mySignedCommitment &&
        mySignedCommitment.length === 0 &&
        signedFields?.typeOfSignedCommitmentId ? (
          <div className="flexRow subscription">
            <div className="col">
              <input
                type="checkbox"
                name=""
                id="subscription"
                checked={signedFields.isvisible}
                onChange={(e) => {}}
                onClick={(e) => {
                  handleSignedChange('isvisible', e);
                }}
              />
              <label htmlFor="subscription">
                Allow news outlets to publish my signed commitments
              </label>
            </div>
          </div>
        ) : (
          ''
        )}

        {mySignedCommitment && mySignedCommitment.length !== 0 ? (
          signedFields?.typeOfSignedCommitmentId ? (
            <>
              <div className="flexRow subscription">
                <div className="col">
                  <input
                    type="checkbox"
                    name=""
                    id="subscription"
                    checked={signedFields.isvisible}
                    onChange={(e) => {}}
                    onClick={(e) => {
                      handleSignedChange('isvisible', e);
                    }}
                  />
                  <label htmlFor="subscription">
                    Allow news outlets to publish my signed commitments
                  </label>
                </div>
              </div>
              <div className="flexRow link">
                View full
                <Link
                  to={`/signedcommitmentstory/${
                    signedFields?.collegeName?.id
                  }/${getUserInfo()?.id}`}
                >
                  Signed Commitment Story
                </Link>
              </div>
              <div className="btnRow with-next-prev buttons">
                <div className="buttons">
                  <button
                    className="btn blue-light"
                    style={{ background: '#00B9FF' }}
                    onClick={() => {
                      handelSignedCommitment(true);
                    }}
                    disabled={changeSignedCommitmentDisabled}
                  >
                    Change Signed Commitment
                  </button>
                  <button
                    className="btn primary"
                    onClick={() => {
                      toggleDeleteModal();
                    }}
                  >
                    Decommit
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flexRow link">
                View full
                <Link
                  to={`/signedcommitmentstory/${
                    signedFields?.collegeName?.id
                  }/${getUserInfo()?.id}`}
                >
                  Signed Commitment Story
                </Link>
              </div>
              <div className="btnRow with-next-prev buttons">
                <div className="buttons">
                  <button
                    className="btn blue-light"
                    style={{ background: '#00B9FF' }}
                    onClick={() => {
                      handelSignedCommitment(true);
                    }}
                    disabled={changeSignedCommitmentDisabled}
                  >
                    Change Signed Commitment
                  </button>
                  <button
                    className="btn primary"
                    onClick={() => {
                      toggleDeleteModal();
                    }}
                  >
                    Decommit
                  </button>
                </div>
              </div>
            </>
          )
        ) : null}

        {mySignedCommitment && mySignedCommitment.length !== 0 ? (
          ''
        ) : (
          <div className="btnRow with-next-prev">
            <button
              className="btn blue"
              style={{ background: '#00B9FF' }}
              onClick={() => {
                saveAfterCheckingError();
              }}
              disabled={isSignedSaved}
            >
              Save
            </button>
            <div className="next-prev-button">
              <ScreenSwitch prevLink={'/Offers'} isSaved={true} />
            </div>
          </div>
        )}
      </div>

      <Dialog
        open={showDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            toggleDeleteModal();
          }
        }}
        className="matDialogCustom"
      >
        <div
          className="modal fade common-modal form-modal commitment show"
          id="ChangeCommitmentModal"
          style={{ display: 'block' }}
        >
          <div
            className="modal-dialog width-380 modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <button
                  onClick={toggleDeleteModal}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Change Signed Commitment</h4>
                <div className="bluelight-bg-box">
                  <p>
                    You are removing your signed commitment with{' '}
                    {signedFields.collegeName
                      ? signedFields.collegeName.collegename
                      : ''}
                    , but your commitment will still present.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn primary"
                  data-dismiss="modal"
                  onClick={handelDeleteSignedCommitment}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={showSaveModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            toggleSaveModal();
          }
        }}
        className="matDialogCustom"
      >
        <div
          className="modal fade common-modal form-modal commitment show"
          id="ChangeCommitmentModal"
          style={{ display: 'block' }}
        >
          <div
            className="modal-dialog width-380 modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <button
                  onClick={toggleSaveModal}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Add Signed Commitment</h4>
                <div className="bluelight-bg-box">
                  <div className="flexRow subscription">
                    <div className="row">
                      <div className="col">
                        I understand that my signed commitment will be shared
                        publicly and that my coach will review the validity of
                        the information.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn lightblue"
                    data-dismiss="modal"
                    onClick={() => handelSignedCommitment(false)}
                    style={{ marginTop: '10px' }}
                  >
                    I Agree
                  </button>
                  <button
                    className="btn primary"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      toggleSaveModal();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
});

export default SignedCommitment;
