import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCamelCase } from "../../../../../common/camelCaseFunc";
import swal from "sweetalert";
import { fetchMethod } from "../../../../../../services/httpService";
import {imageDownloadUrl, websiteDefaultUrl} from "../../../../../../config";
import {
  getAuthToken,
  getUserInfo,
} from "../../../../../../services/authService";
import { useDispatch } from "react-redux";
import moment from "moment";
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";
import { Dialog, Tooltip } from "@mui/material";
import SharePost from "../../homepage/posts/SharePost";
import {getUTCTimeDateDash} from '../../../../../../utils/dateTimeFormatter'

function SignedCommitmentStory(props) {
  const clgID = +props?.match?.params?.collegeid;
  const userID = +props?.match?.params?.userid;
  const [clgStoryObj, setClgStoryObj] = useState({});
  const [sharePop, setSharePop] = useState(false);
  const profileStatus = useDispatch();

  useEffect(() => {
    setSharePop(true);
    getClgStoryRestCall();
  }, []);

  //this is used to scroll the page on top
  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0,0)
    },1500)
  },[]);

  const getClgStoryRestCall = async () => {
    const finalObj = {
      collegeid: clgID || 0,
      userid: userID || 0,
    };
    const _res = await fetchMethod(
      "/api/Signedcommitments/signedcommitmentstory",
      finalObj,
      "POST",
      null
    );
    if (_res?.status == 200 && _res?.data?.response?.status == 200) {
      const clgStory =
        _res?.data?.response?.data?.length > 0
          ? _res?.data?.response?.data?.[0]
          : {};
      setClgStoryObj(clgStory);
    } else {
      swal({ title: "College Story Api Failed", icon: "error" });
      setClgStoryObj({});
    }
  };

  const getInitials = () => {
    let initial = "";
    if (clgStoryObj) {
      initial = `${
        !!clgStoryObj?.athleteFirstName
          ? clgStoryObj?.athleteFirstName?.split("")[0]?.toUpperCase() ?? ""
          : ""
      }${
        !!clgStoryObj?.athleteLastName
          ? clgStoryObj?.athleteLastName?.split("")[0]?.toUpperCase() ?? ""
          : ""
      }`;
    }
    return initial;
  };

  const onClickProfile = () => {
    if (!!getAuthToken()) {
      profileStatus({
        type: "CHANGE_ATHLETE_PROFILE_VIEW_STATE",
        payload: {
          id: userID || 0,
          status: true,
        },
      });
      localStorage.setItem("pubViewID", +userID);
    } else {
      console.log("no token");
    }
  };

  const handleCollegeClick = () => {
    if (!!getAuthToken()) {
      props?.history?.push(`/colleges/details/${clgID}/${clgStoryObj?.isfav}`);
    }
  };

  const ShareQuote = () => {
    const text = `View my @SportsForce ${clgStoryObj?.sportName} recruiting profile
                   ${websiteDefaultUrl}publicSignedCommitmentStory/${clgID}/${userID}
                 `;
    return text;
  };

  return (
    <>
      <Dialog open={sharePop}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setSharePop(!sharePop)
                }
              }}
              className="matDialogCustom"
      >
        <SharePost
          pageType={"CollegeSignedStory"}
          handleModal={() => setSharePop(!sharePop)}
          userID={userID}
          clgID={clgID}
          modalData={ShareQuote()}
          pubID={null}
        />
      </Dialog>
      <div className="pageContent">
        <div className="container">
          <div className="commitmentStory">
            <div className="row">
              <div className="col">
                <div className="success">
                  <p>
                    {!!clgStoryObj?.athleteFirstName
                      ? getCamelCase(clgStoryObj?.athleteFirstName)
                      : ""}{" "}
                    {!!clgStoryObj?.athleteLastName
                      ? getCamelCase(clgStoryObj?.athleteLastName)
                      : ""}{" "}
                    Announces Signed Commitment to Play{" "}
                    {!!clgStoryObj?.sportName
                      ? getCamelCase(clgStoryObj?.sportName) + " "
                      : ""}
                    at{" "}
                    {!!clgStoryObj?.collegename
                      ? getCamelCase(clgStoryObj?.collegename)
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex commit">
              <div className="item">
                {!!clgStoryObj?.profileimages ? (
                  <div className="pic">
                    <img
                      src={`${imageDownloadUrl}/${clgStoryObj?.profileimages}`}
                      style={{ borderRadius: "50px" }}
                      alt="Profile Picture"
                    />
                  </div>
                ) : (
                  <div
                    className="initialLetter"
                    style={{ fontWeight: 500, fontSize: "24px" }}
                  >
                    <span>{getInitials()}</span>
                  </div>
                )}
                <p>
                  <strong className="sfbluelink"
                    style={
                      !!getAuthToken()
                        ? {
                            cursor: "pointer",
                            color: "rgb(0, 123, 255)",
                            textDecoration: "none",
                          }
                        : {
                            cursor: "pointer",
                            color: "rgb(0, 123, 255)",
                            textDecoration: "none",
                          }
                    }
                  >
                    <Link
                      to={`/athlete/${clgStoryObj?.athleteFirstName
                        ?.toLowerCase()
                        ?.trim()?.split(" ").join("-")}-${clgStoryObj?.athleteLastName
                        ?.toLowerCase()
                        ?.trim()?.split(" ").join("-")}-${clgStoryObj?.sysGenId ?? 0}`}
                      target="_blank"
                      onClick={() => onClickProfile()}
                      style={
                        !!getAuthToken()
                          ? { cursor: "pointer", textDecoration: "none" }
                          : { cursor: "text", textDecoration: "none" }
                      }
                    >
                      {!!clgStoryObj?.athleteFirstName
                        ? getCamelCase(clgStoryObj?.athleteFirstName)
                        : ""}{" "}
                      {!!clgStoryObj?.athleteLastName
                        ? getCamelCase(clgStoryObj?.athleteLastName)
                        : ""}
                    </Link>
                  </strong>
                  <small>
                    {!!clgStoryObj?.commitmentdate
                      ? moment(clgStoryObj?.commitmentdate).format("YYYY")
                      : ""}{" "}
                    Commit
                  </small>
                </p>
              </div>
              <div className="item">
                <div
                  className="pic"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleCollegeClick()}
                >
                  {clgStoryObj.collegelogo ? (
                    <img
                      src={imageDownloadUrl + "/" + clgStoryObj?.collegelogo}
                      alt=""
                    />
                  ) : (
                    <img src={TeamLogo} alt="College Logo" />
                  )}
                </div>
                <p>
                  <strong className="sfbluelink"
                    style={
                      !!getAuthToken()
                        ? { cursor: "pointer", color: "#007bff" }
                        : { cursor: "text", color: "#007bff" }
                    }
                    onClick={() => handleCollegeClick()}
                  >
                    {clgStoryObj && clgStoryObj?.collegename
                      ? getCamelCase(clgStoryObj.collegename)
                      : ""}
                  </strong>
                  <small>
                  {clgStoryObj ? clgStoryObj.conferencename : " "}{!!clgStoryObj.conferencename ? <br /> : ""}
                  {clgStoryObj
                        ? `${
                          clgStoryObj.city === null || clgStoryObj.city === undefined
                              ? " "
                              : getCamelCase(clgStoryObj.city)
                          }${clgStoryObj ? (
                            (clgStoryObj.city === null || clgStoryObj.city === undefined) && (clgStoryObj.initials === null || clgStoryObj.initials === undefined) ?
                            " " : ","
                            ) : " "}
                           ${
                            clgStoryObj.initials === null || clgStoryObj.initials === undefined ? " " : clgStoryObj.initials
                          }`
                        : " "}<br />
                  {clgStoryObj
                        ? `${
                          clgStoryObj.collegedivisionname === null || clgStoryObj.collegedivisionname === undefined ? " " : clgStoryObj.collegedivisionname
                          }`
                        : " "}            
                  </small>
                </p>
              </div>
            </div>
            <div className="flex share">
              <div className="item">
                <div className="title">
                  Share this update
                  <Tooltip title={"Share"} placement={"top"}>
                    <button
                      className="followBtn"
                      style={{
                        width: "35px",
                        height: "30px",
                        borderRadius: "4px",
                        padding: "10px",
                        backgroundColor: "#FDB944",
                        fontSize: "0.9375rem",
                        fontWeight: 600,
                        border: "none",
                        color: "#000000",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span className="icon" onClick={() => setSharePop(true)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="19.995"
                          viewBox="0 0 24 19.995"
                        >
                          <path
                            id="Icon_ionic-ios-share-alt"
                            data-name="Icon ionic-ios-share-alt"
                            d="M28.289,15.148,19.472,6.9a.451.451,0,0,0-.35-.15c-.275.012-.625.206-.625.5v4.137a.267.267,0,0,1-.225.256c-8.767,1.343-12.46,7.9-13.766,14.716-.05.269.312.519.481.306,3.2-4.03,7.092-6.661,13.247-6.711a.309.309,0,0,1,.262.3v4.062a.536.536,0,0,0,.906.331l8.88-8.392a.69.69,0,0,0,.219-.525A.837.837,0,0,0,28.289,15.148Z"
                            transform="translate(-4.502 -6.749)"
                            fill="#fff"
                          />
                        </svg>
                      </span>
                      <span></span>
                    </button>
                  </Tooltip>
                </div>
              </div>
              <div className="item">
                <ul className="flex" style={{ marginLeft: 0 }}></ul>
              </div>
            </div>
            <div className="flex content">
              <p>
                {clgStoryObj && clgStoryObj?.city
                  ? getCamelCase(clgStoryObj?.city) + ","
                  : ""}
                {" "}
                {clgStoryObj && clgStoryObj?.state
                    ? getCamelCase(clgStoryObj?.state)
                    : ""}
                ,{" "}
                {!!clgStoryObj && clgStoryObj?.commitmentdate
                  ?
                    `
                    ${getUTCTimeDateDash(
                        clgStoryObj?.commitmentdate
                    )}
                    .`
                  : ""}
                {clgStoryObj && clgStoryObj?.athleteFirstName ? getCamelCase(clgStoryObj?.athleteFirstName) : ""}{" "}
                {clgStoryObj && clgStoryObj?.athleteLastName ? getCamelCase(clgStoryObj?.athleteLastName) : ""}{" "}
                {clgStoryObj?.heightValue !== null
                  ? `${clgStoryObj?.heightValue}'`
                  : ""}
                {clgStoryObj?.inchValue !== null ? (clgStoryObj?.inchValue == 0 ?
                  " 0''"
                    : ` ${clgStoryObj?.inchValue}''`) : ""
                }
                {" "}
                {!!clgStoryObj?.selectionfield ? clgStoryObj?.selectionfield  + ", "
                  : ""}
                 in the {!!clgStoryObj?.yearValue ? clgStoryObj?.yearValue ?? "" : ""} class has
                signed committed to play {getCamelCase(clgStoryObj?.sportName)} at{" "}
                <a
                    href={void 0}
                    style={!!getAuthToken() ? {
                      cursor: "pointer",
                      color: "#007bff",
                      fontWeight: "bolder"
                    }:
                        {
                          cursor: "text",
                          color: "#007bff",
                          fontWeight: "bolder"
                        }}
                    onClick={() => handleCollegeClick()}
                >
                  {clgStoryObj?.collegename
                    ? clgStoryObj?.collegename
                    : ""}
                  .
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignedCommitmentStory;
