import React, { useEffect, useState} from 'react'
import ClaimProfileHeader from "../auth/claimProfile/claimProfileStep1/ClaimProfileHeader";
import DashboardHeader from "../../layouts/header/DashboardHeader";
import {getAuthToken} from "../../../services/authService";
import './assets/css/lading-page.scss'

const helpSlide = {
    loop: true,
    nav: true,
    dots: false,
    items: 1,
};

const PlaceOrderLP = (props) =>{
    const [headerType, setHeaderType] = useState(true),
        isToken = props?.match?.params?.isToken == 1 ? true : false;
    useEffect(()=>{
        getStatus(props)
        //for scrolling the page on top
        setTimeout(()=>{
            window.scrollTo(0,0)
        },1000)
    },[])
    const getStatus = (props) =>{
        if (!!getAuthToken() || isToken){
            setHeaderType(false)
        }
    }
    return(
        <>
            {
                headerType ?
                    <ClaimProfileHeader/>
                    :
                    <DashboardHeader/>
            }
            <section className="place-order-sec">
                <div className="container">
                    <div className="top-block">
                        <div className="sec-title">Place Your Order</div>
                    </div>
                    <div className="order-form-box">
                        <form>
                            <div className="fieldbox-row">
                                <h3 className="title">Athlete Information</h3>
                                <div className="fieldBox flexBox">
                                    <div className="one-four">
                                        <label>Athlete first name</label>
                                        <input type="text" placeholder="Enter athlete first name"/>
                                    </div>
                                    <div className="one-four">
                                        <label>Athlete last name</label>
                                        <input type="text" placeholder="Enter athlete last name"/>
                                    </div>
                                    <div className="one-four">
                                        <label>Guardian first name</label>
                                        <input type="text" placeholder="Enter guardian first name"/>
                                    </div>
                                    <div className="one-four">
                                        <label>Guardian last name</label>
                                        <input type="text" placeholder="Guardian last name"/>
                                    </div>
                                    <div className="one-four">
                                        <label>Gender</label>
                                        <ul className="checkbox-list">
                                            <li>
                                                <input checked type="checkbox"/>
                                                    <label>Female</label>
                                            </li>
                                            <li>
                                                <input type="checkbox"/>
                                                    <label>Male</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="one-four">
                                        <label>Class Year</label>
                                        <select>
                                            <option selected="" value="1">Choose one</option>
                                            <option value="2">option 2</option>
                                            <option value="3">option 3</option>
                                            <option value="4">option 4</option>
                                            <option value="5">option 5</option>
                                        </select>
                                    </div>
                                    <div className="one-four">
                                        <label>Primary Phone</label>
                                        <input type="text" placeholder="888 222 0000"/>
                                    </div>
                                </div>
                            </div>
                            <div className="fieldbox-row">
                                <h3 className="title">Account Access</h3>
                                <div className="fieldBox flexBox">
                                    <div className="half">
                                        <h4>ACCOUNT TYPE : ATHLETE</h4>
                                        <div className="fieldBox flexBox">
                                            <div className="half">
                                                <label>Email 1</label>
                                                <input type="email" placeholder="yourmail@domail.com"/>
                                            </div>
                                            <div className="half">
                                                <label>Password</label>
                                                <input type="password" placeholder="*********"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="half">
                                        <h4>ACCOUNT TYPE : GUARDIAN</h4>
                                        <div className="fieldBox flexBox">
                                            <div className="half">
                                                <label>Email 1</label>
                                                <input type="email" placeholder="yourmail@domail.com"/>
                                            </div>
                                            <div className="half">
                                                <label>Password</label>
                                                <input type="password" placeholder="*********"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fieldbox-row">
                                <h3 className="title">Payment Information</h3>
                                <div className="fieldBox flexBox">
                                    <div className="one-four">
                                        <label>Name On Card</label>
                                        <input type="text" placeholder="e.g Andrew Beinbrink"/>
                                    </div>
                                    <div className="one-four">
                                        <label>Credit Card</label>
                                        <input className="credit-cart-field" type="text"
                                               placeholder="Enter card number"/>
                                    </div>
                                    <div className="one-four">
                                        <div className="fieldBox flexBox">
                                            <div className="half">
                                                <label>Card Expiry</label>
                                                <input type="text" placeholder="MM / YY"/>
                                            </div>
                                            <div className="half">
                                                <label>CVC</label>
                                                <input type="text" placeholder="CVC"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fieldbox-row">
                                <h3 className="title">Package Information</h3>
                                <div className="fieldBox">
                                    <div className="package-info-box">
                                        <div className="item">
                                            <h5>Package Name</h5>
                                            <p>Highlight Reel</p>
                                        </div>
                                        <div className="item">
                                            <h5>Total Amount Charged</h5>
                                            <p>$250.00</p>
                                        </div>
                                    </div>
                                    <ul className="checkbox-list">
                                        <li>
                                            <input type="checkbox"/>
                                                <label>I have read and understand the SportsForce <a href="#">Refund
                                                    Policy</a> and <a href={void 0}>Terms of Use.</a></label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="fieldBox text-center">
                                <button className="btn lightblue">Place Order</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

        </>
    )
}
export default PlaceOrderLP
