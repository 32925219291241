import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import BackdropLoader from "../../../../common/Loader";
import { connect } from "react-redux";
import {
  getAllAthleteSchoolQuery,
  getAllCoachDetailsQuery,
  getAllCoachTypesQuery,
  getAllTeamQuery,
  saveCoachDetailsQuery,
} from "./primaryCoachQuery";
import { coachTypeListStart } from "../../../../../redux/athlete/onBoarding/coachTypeList";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardCoachTeam.scss";
import "../assets/css/onBoardSteeper.scss";
import {
  IsValidEmail,
  checkPhoneNumberValidation,
  formattedPhoneNumber,
  IsValidName,
} from "../../../../../utils/validationUtils";
import PhoneNumber from "../../../../common/PhoneNumber";
import { countryListStart } from "../../../../../redux/common/countriesList";
import { getOnboardingQuery } from "../OnboardingQuery";
import { getUserInfo } from "../../../../../services/authService";
import { getOnboardingStart } from "../../../../../redux/athlete/onBoarding/onboarding";
import {
  getCoachDetailsStart,
  saveCoachDetailsStart,
} from "../../../../../redux/athlete/onBoarding/coachDetails";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import "../SkitPopup/SkipScreen.scss";
import { Dialog } from "@mui/material";
import usePrevious from "../../../../../utils/usePrevious";
import MuiAutoComplete from "../../../../common/AutoComplete";
import Stepper from "../Stepper";
import messagePopup from "../../../../../utils/messagePopup";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../services/httpService";
import history from "../../../../../services/history";
import { saveAllReferenceDataQuery } from "./primaryCoachQuery";
import { getAllAthletesQuery } from "../../dashboard/colleges/searchColleges/CollegeDetailsQuery";
import {
  saveSchoolQuery,
  saveSchoolTeamMapping,
  saveTeamQuery,
} from "../../dashboard/editUserProfile/teams/TeamQuery";
import { referencesListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/allReferences";
import { getAllReferenceDataQuery } from "../../dashboard/editUserProfile/ReferencesQuery";
import { formatPhoneNumber } from "../../../../../utils/validationUtils";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "passed", link: "/onboarding/positions" },
  { className: "passed", link: "/onboarding/physicalMeasurements" },
  { className: "passed", link: "/onboarding/personalRecords" },
  { className: "passed", link: "/onboarding/firstYearVarsity" },
  { className: "passed", link: "/onboarding/schoolInfo" },
  { className: "passed", link: "/onboarding/academics" },
  { className: "passed", link: "/onboarding/recruiting" },
  { className: "passed", link: "/onboarding/recruitingprocess" },
  { className: "passed", link: "/onboarding/instagram" },
  // { className: "passed", link: "/onboarding/baseballLevel" },
  { className: "active", link: "/onboarding/primaryCoach" },
];

const PrimaryCoach = (props) => {
  const [fields, setFields] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [allTeam, setAllTeam] = useState([]);
  const [allTeamUser, setAllTeamUser] = useState([]);
  const [highSchool, setHighSchool] = useState([]);
  const [references, setReferences] = useState(null);
  const prevCoachDetailsData = usePrevious(props.coachDetails.data);
  const prevOnboardData = usePrevious(props.onboarding.data);
  const fakeInput = useRef(null);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    let nextScreen = "";
    if (getUserInfo()) {
      if (getUserInfo().role === "ATHLETES") {
        nextScreen = "/onboarding/congratulateathelete";
      } else {
        nextScreen = "/onboarding/congratulateparents";
      }
    }
    props.history.push(nextScreen);
  };

  useEffect(() => {
    setFields({});
    props.coachTypeListStart({ query: getAllCoachTypesQuery, variables: null });
    props.countryListStart();
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
    props.getCoachDetailsStart({
      query: getAllCoachDetailsQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
    getAllReferences();
    getAllTeam();
    getAllTeamUser();
    getAllAThleteSchool();
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);

  const getInitialData = (data) => {
    const fieldT = fields;
    const {
      id,
      coachemail,
      coachfirstname,
      coachlastname,
      coachphonenumber,
      coachtypeid,
      typeofprimaryteam,
      teamname,
      wanttoconnectschoolcoachtosportforcecoach,
      coachcountrycode,
      onboardingid,
    } = data;

    fieldT["id"] = id;
    fieldT["onboardingid"] = onboardingid;
    fieldT["coachFirstName"] = coachfirstname ? coachfirstname : null;
    fieldT["coachLastName"] = coachlastname ? coachlastname : null;
    fieldT["phoneNumber"] = coachphonenumber ? coachphonenumber : null;
    fieldT["email"] = coachemail ? coachemail : null;
    fieldT["coachType"] = coachtypeid ? +coachtypeid : null;
    fieldT["teamType"] = typeofprimaryteam !== null ? typeofprimaryteam : null;
    // fieldT["teamName"] = teamname
    //   ? teamList
    //     ? teamList.filter((item) => item.label === teamname)[0]
    //     : null
    //   : null;
    fieldT["teamName"] = fieldT?.teamType
      ? fieldT?.teamType == "club"
        ? allTeam?.filter((item) => item.label === teamname)?.[0]
        : highSchool?.filter((item) => item.label === teamname)?.[0]
      : allTeam?.filter((item) => item.label === teamname)?.[0];

    fieldT["connectTSF"] =
      wanttoconnectschoolcoachtosportforcecoach !== null
        ? wanttoconnectschoolcoachtosportforcecoach
          ? "yes"
          : "no"
        : null;
    fieldT["countryCode"] =
      props.countries.data &&
      props.countries.data
        .filter((item) => +item.id === coachcountrycode)
        .map((item1) => ({
          value: item1 ? item1.id : "",
          label: item1 ? item1.countryName + `(+${item1.countryCode})` : "",
          flag: item1 ? item1.countryFlagImage : "",
          countryCode: item1.countryCode ? item1.countryCode : 1,
          countryShortName: item1.countryShortName,
        }))[0];

    setFields({ ...fieldT });
  };

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = { ...fields };
        fieldT["onboardingid"] = props.onboarding.data.id;
        setFields({ ...fieldT });
        // if (props.coachDetails.data && props.coachDetails.data.id) {
        //   getInitialData(props.coachDetails.data);
        // } else {
        //   // setFields({});
        // }
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);

  useEffect(() => {
    if (
      props.coachDetails.data &&
      // prevCoachDetailsData !== props.coachDetails.data &&
      props.coachDetails.data.id &&
      props.countries.data
    ) {
      getInitialData(props.coachDetails.data);
    } else {
      const fieldT = { ...fields };
      fieldT["countryCode"] = {
        value: props.countries.data ? props.countries.data[0].id : "",
        label: props.countries.data
          ? props.countries.data[0].countryName +
            `(+${props.countries.data[0].countryCode})`
          : "",
        flag: props.countries.data
          ? props.countries.data[0].countryFlagImage
          : "",
        countryCode: props.countries.data
          ? props.countries.data[0].countryCode
          : "",
        countryShortName: props.countries.data
          ? props.countries.data[0].countryShortName
          : "",
      };

      if (!(props.coachDetails.data && props.coachDetails.data.id)) {
        setFields({ countryCode: fieldT["countryCode"] });
      } else {
        setFields({ ...fieldT });
      }
    }
  }, [props.coachDetails, props.countries.data, allTeam, highSchool]);

  useEffect(() => {
    if (props.allReferences.data) {
      const reference = props.allReferences.data.allreferencedata?.References
        ? props.allReferences.data.allreferencedata?.References[0]
        : null;
      setReferences(reference);
    }
  }, [props.allReferences.data]);

  const getAllReferences = () => {
    props.referencesListStart({
      query: getAllReferenceDataQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };

  const getAllTeam = async () => {
    const _res = await fetchGraphMethod(
      getAllTeamQuery,
      { where: { active: 1, isverified: 1 } },
      true
    );
    if (_res?.status == 200) {
      if (_res?.data?.data?.allTeams?.Teams.length > 0) {
        const resData = _res?.data?.data?.allTeams?.Teams,
          obj = [];
        resData.map((el) => obj.push({ id: el?.id, label: (!!el?.city || !!el?.state)  ? `${el?.teamname} [${el?.city}${!!el?.state ?`, ${el?.state}` : ``}]` : `${el?.teamname}`}));
        setAllTeam(obj);
      } else {
        setAllTeam([]);
      }
    } else {
      messagePopup("", "All Team Query Failed", "error");
    }
  };
  const getAllTeamUser = async (value) => {
    const _res = await fetchGraphMethod(
      getAllTeamQuery,
      { where: { active: 1, userid: getUserInfo()?.id ?? 0 } },
      true
    );
    if (_res?.status == 200) {
      if (_res?.data?.data?.allTeams?.Teams.length > 0) {
        const resData = _res?.data?.data?.allTeams?.Teams;
        setAllTeamUser(resData);
      } else {
        setAllTeamUser([]);
      }
    } else {
      messagePopup("", "All Team Query Failed", "error");
    }
  };

  const getAllAThleteSchool = async () => {
    const _res = await fetchGraphMethod(
      getAllAthleteSchoolQuery,
      { where: { active: 1, isverified: 1 } },
      true
    );
    if (_res?.status == 200) {
      if (_res?.data?.data?.allAthleteschools?.Athleteschools?.length > 0) {
        const resData = _res?.data?.data?.allAthleteschools?.Athleteschools,
          obj = [];
        resData.map((el) =>
          obj.push({ id: el?.id, label: el?.highschoolname })
        );
        setHighSchool(obj);
      } else {
        setHighSchool();
      }
    } else {
      messagePopup("", "All HighSchool Query Failed", "error");
    }
  };
  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;

    //phoneNumber validations
    if (fieldname === undefined || fieldname === "phoneNumber") {
      if (fieldname === undefined) {
        fieldValue = fields["phoneNumber"] ? fields["phoneNumber"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["phoneNumber"];
      if (!updatedValue) {
        formIsValid = true;
        errorsT["phoneNumber"] = "";
      } else if (
        !checkPhoneNumberValidation(
          updatedValue,
          fields?.countryCode?.countryShortName ?? ""
        )
      ) {
        formIsValid = false;
        errorsT["phoneNumber"] = "Please enter a valid Phone Number";
      }
      // updatedValue = formattedPhoneNumber(
      //   updatedValue,
      //   fields?.countryCode?.countryShortName ?? ""
      // );
    }

    //email validations
    if (fieldname === undefined || fieldname === "email") {
      if (fieldname === undefined) {
        fieldValue = fields["email"] ? fields["email"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["email"];
      if (!updatedValue) {
        formIsValid = true;
        errorsT["email"] = "";
      } else if (!IsValidEmail(updatedValue)) {
        formIsValid = false;
        errorsT["email"] = "Please enter a valid email";
      }
    }

    //coachFirstName validations
    if (fieldname === undefined || fieldname === "coachFirstName") {
      if (fieldname === undefined) {
        fieldValue = fields["coachFirstName"] ? fields["coachFirstName"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["coachFirstName"];
      if (!IsValidName(updatedValue)) {
        formIsValid = false;
        errorsT["coachFirstName"] = "Please enter a valid name";
      }
    }

    //coachLastName validations
    if (fieldname === undefined || fieldname === "coachLastName") {
      if (fieldname === undefined) {
        fieldValue = fields["coachLastName"] ? fields["coachLastName"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["coachLastName"];
      if (!IsValidName(updatedValue)) {
        formIsValid = false;
        errorsT["coachLastName"] = "Please enter a valid name";
      }
    }

    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const handleChange = (field, e) => {
    const { formIsValid, updatedValue } = handleValidation(field, e);
    let fieldsT = { ...fields };
    if(field == "phoneNumber"){
      if(e?.target.value?.length < 11){
        fieldsT[field] = formatPhoneNumber(updatedValue);
        setFields({ ...fieldsT });
      }
    }else{
    fieldsT[field] = updatedValue;
      setFields({ ...fieldsT });
    }
    // fieldsT[field] = updatedValue;
    // setFields({
    //   ...fieldsT,
    // });
  };

  const handlePhonenumberChange = (selectedOption) => {
    const fieldsT = fields;
    fieldsT["countryCode"] = selectedOption;
    setFields({ ...fieldsT, phoneNumber: "" });
  };

  const checkEmailValidation = async (email) => {
    if (email) {
      const response = await fetchMethod(
        "/api/Coachandteamdetails/IsUserExist",
        {
          emailId: email,
          role: "COACH",
        },
        "post",
        true
      );
      if (response.data && response.data.response) {
        if (response.data.response.status === 200) {
          return true;
        }
        if (response.data.response.status === 201) {
          messagePopup("", response.data.response.msg, "error");
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { formIsValid, updatedValue } = handleValidation();
    if (
      fields.email ||
      fields.coachFirstName ||
      fields.coachLastName ||
      fields.phoneNumber ||
      fields.coachType ||
      fields.teamType ||
      fields.teamName ||
      fields.connectTSF
    ) {
      if (formIsValid) {
        let nextScreen = "";
        if (getUserInfo()) {
          if (getUserInfo().role === "ATHLETES") {
            nextScreen = "/onboarding/congratulateathelete";
          } else {
            nextScreen = "/onboarding/congratulateparents";
          }
        }
        const emailStatus = await checkEmailValidation(fields.email);
        if (emailStatus) {
          let isRefAdd = false
          const tempData = { ...fields };
          if (  fields.email ||
              fields.coachFirstName ||
              fields.coachLastName ||
              fields.phoneNumber) {
            props.saveCoachDetailsStart({
              query: saveCoachDetailsQuery,
              variables: {
                obj: {
                  id: fields.id ? fields.id : null,
                  userid: getUserInfo().id,
                  coachemail: fields.email ? fields.email : "",
                  coachfirstname: fields.coachFirstName
                      ? fields.coachFirstName
                      : "",
                  coachlastname: fields.coachLastName ? fields.coachLastName : "",
                  coachphonenumber: fields.phoneNumber ? fields.phoneNumber : "",
                  coachtypeid: fields.coachType ? +fields.coachType : null,
                  typeofprimaryteam: fields.teamType ? fields.teamType : "",
                  teamname: fields.teamName ? fields.teamName.label : null,
                  wanttoconnectschoolcoachtosportforcecoach: fields.connectTSF
                      ? fields.connectTSF === "yes"
                          ? 1
                          : 0
                      : 0,
                  onboardingid: fields.onboardingid ? fields.onboardingid : null,
                  coachcountrycode: fields.phoneNumber
                      ? fields.countryCode
                          ? +fields.countryCode.value
                          : 1
                      : null,
                },
              },
              nextScreen: async (data) => {
                let referenceData = {
                  obj: {
                    id: references ? references.id : 0,
                    userId: getUserInfo().id,
                    active: 1,
                    refemailid: data.coachemail ? data.coachemail : null,
                    reffirstname: data.coachfirstname ? data.coachfirstname : "",
                    reflastname: data.coachlastname ? data.coachlastname : "",
                    team: data.teamname ? data.teamname : null,
                    reftype: data.coachtypeid ? +data.coachtypeid : null,
                    refphone: data.coachphonenumber ? data.coachphonenumber : "",
                    refcountryid: data.coachcountrycode
                        ? data.coachcountrycode
                        : null,
                  },
                };
                const response = await fetchGraphMethod(
                    saveAllReferenceDataQuery,
                    referenceData,
                    true
                );
                if (response && response.status === 200) {
                  history.push(nextScreen);
                  isRefAdd = true
                } else {
                  messagePopup("", "Save Coach Details Failed", "error");
                }
              },
            });
          }
          if (tempData?.teamName?.id){
         await saveSchoolMapping(
                tempData?.teamName?.id,
                !!tempData?.teamType
                    ? tempData?.teamType == "club"
                    ? true
                    : false
                    : true
            );
            if (!isRefAdd){
              history.push(nextScreen);
            }
          }
        }
      }
    } else {
      messagePopup("", "Please add more information to complete your profile.", "error");
    }
  };
  const saveTeam = async () => {
    const finalObj = {
      obj: {
        userid: getUserInfo()?.id ?? 0,
        teamname: fields?.teamName?.label ?? "",
      },
    };
    const resTeam = await fetchGraphMethod(
      saveTeamQuery,
      finalObj,
      "POST",
      true
    );
    if (resTeam?.status == 200) {
      saveSchoolMapping(+resTeam?.data?.data?.saveTeam?.id, null);
    } else {
      console.log("team save failed");
    }
  };
  const saveSchool = async () => {
    const finalObj = {
      obj: {
        userid: getUserInfo() ? getUserInfo().id : 0,
        highschoolname: fields.teamName?.label,
      },
    };
    const res = await fetchGraphMethod(saveSchoolQuery, finalObj, true);
    if (res?.status == 200) {
      saveSchoolMapping(null, +res.data.data.saveAthleteschool.id ?? 0);
    }
  };
  const saveSchoolMapping = async (ID, isSchool) => {
    const finalObj = {
      obj: {
        userid: getUserInfo().id,
        teamid: isSchool ? ID : null,
        schoolid: isSchool ? null : ID,
      },
    };
    const resMap = await fetchGraphMethod(
      saveSchoolTeamMapping,
      finalObj,
      true
    );
    if (resMap?.status == 200) {
      console.log("team mapping created");
    } else {
      console.log("team maping failed");
    }
  };
  const handleRadioChange = (field, value) => {
    let fieldsT = fields;
    fieldsT[field] = value;
    setFields({ ...fieldsT });
  };

  return (
    <div>
      <BackdropLoader
        open={
          props.coachTypeList.loading ||
          props.countries.loading ||
          props.onboarding.loading ||
          props.coachDetails.loading
        }
      />
      <div className="onBoardCoachTeamSection">
        <input className="fakeInput" type="text" ref={fakeInput} />
        <div className="coachTeamBlock">
          <div className="stepper">
            <Stepper stepperName="primaryCoach" stepperData={stepperData} />
          </div>
          <div className="formSection">
            <h1 className="heading">
              Primary Coach{" "}
              <span className="checkIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <g transform="translate(-649.111 -507.111)">
                    <path
                      className="a"
                      d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                      transform="translate(649.111 507.111)"
                    />
                    <path
                      className="b"
                      d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                      transform="translate(511.436 348.256)"
                    />
                  </g>
                </svg>
              </span>
            </h1>
            <h2 className="coachSUbHead">You can add more teams later.</h2>
            <div className="coachFormSeciton">
              <form>
                <div className="fieldBox">
                  <label>Coach First Name</label>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Coach First Name"
                    type="text"
                    name="coachFirstName"
                    id="coachFirstName"
                    value={fields.coachFirstName || ""}
                    onChange={(e) => handleChange("coachFirstName", e)}
                    onBlur={(e) => handleChange("coachFirstName", e)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: false,
                    }}
                  />
                  <div style={{ color: "red" }}>{errors["coachFirstName"]}</div>
                </div>
                <div className="fieldBox">
                  <label>Coach Last Name</label>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="coachLastName"
                    placeholder="Coach Last Name"
                    type="text"
                    value={fields.coachLastName || ""}
                    onChange={(e) => handleChange("coachLastName", e)}
                    onBlur={(e) => handleChange("coachLastName", e)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: false,
                    }}
                  />
                  <div style={{ color: "red" }}>{errors["coachLastName"]}</div>
                </div>
                <div className="fieldBox">
                  <label>Coach Mobile Phone</label>
                  <PhoneNumber
                    dropdownName="countryCode"
                    dropdownOnChange={handlePhonenumberChange}
                    dropdownValue={fields.countryCode}
                    dropDownOptions={
                      props.countries.data &&
                      props.countries.data.map((item) => ({
                        value: item.id,
                        label: item.countryName + `(+${item.countryCode})`,
                        flag: item.countryFlagImage,
                        countryCode: item.countryCode,
                        countryShortName: item.countryShortName,
                      }))
                    }
                    formatOptionLabel={(option, { context, selectValue }) => {
                      return context === "menu" ? (
                        <>
                          <img
                            src={option.flag}
                            alt=""
                            style={{ width: "40px", marginRight: "15px" }}
                          />
                          <span>{option.label}</span>
                        </>
                      ) : (
                        <>
                          <img
                            src={option.flag}
                            alt=""
                            style={{ width: "40px", marginRight: "15px" }}
                          />
                          <span>+{option.countryCode}</span>
                        </>
                      );
                    }}
                    inputPlaceholder="Phone Number"
                    inputType="text"
                    inputValue={fields.phoneNumber || ""}
                    inputOnChange={(e) => {
                      handleChange("phoneNumber", {
                        target: {
                          value: e.target.value.replace(/[^0-9]/gi, ""),
                        },
                      });
                    }}
                    inputOnBlur={(e) => {
                      handleChange("phoneNumber", {
                        target: {
                          value: e.target.value.replace(/[^0-9]/gi, ""),
                        },
                      });
                    }}
                    // inputOnChange={(e) => {
                    //   handleChange("phoneNumber", e);
                    // }}
                    // inputOnBlur={(e) => {
                    //   handleChange("phoneNumber", e);
                    // }}
                  />
                  <div style={{ color: "red" }}>{errors["phoneNumber"]}</div>
                </div>
                <div className="fieldBox">
                  <label>Coach Email</label>

                  <TextField
                    variant="outlined"
                    fullWidth
                    id="email"
                    placeholder="Coach Email"
                    type="text"
                    value={fields.email || ""}
                    onChange={(e) => {
                      handleChange("email", e);
                    }}
                    onBlur={(e) => {
                      handleChange("email", e);
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: false,
                    }}
                  />
                  <div style={{ color: "red" }}>{errors["email"]}</div>
                </div>
                <div className="fieldBox">
                  <label>Coach Type</label>
                  <select
                    id="coachType"
                    label="Select position"
                    value={fields.coachType || ""}
                    onChange={(e) => handleChange("coachType", e)}
                  >
                    <option value="">Select Coach Type</option>
                    {props.coachTypeList.data &&
                      props.coachTypeList.data.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.coachtypename}
                        </option>
                      ))}
                  </select>
                </div>
              </form>
            </div>
            <div className="teamFormSection">
              <h2 className="heading">
                What's your Primary Team?{" "}
                <span className="checkIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g transform="translate(-649.111 -507.111)">
                      <path
                        className="a"
                        d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                        transform="translate(649.111 507.111)"
                      />
                      <path
                        className="b"
                        d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                        transform="translate(511.436 348.256)"
                      />
                    </g>
                  </svg>
                </span>
              </h2>
              <h3 className="subHead">You can add more teams later.</h3>
              <div className="fieldBox">
                <label>What type of team is your primary team?</label>
                <div className="radioFlexBox">
                  <div>
                    <span>
                      <input
                        type="radio"
                        name="teamType"
                        checked={
                          fields.teamType ? fields.teamType === "club" : true
                        }
                        onChange={(e) => handleRadioChange("teamType", "club")}
                      />
                      <label htmlFor="schoolCheck">Club</label>
                    </span>
                  </div>
                  <div>
                    {/*as of now it is commented 13/7/2022 */}
                    {/*<span>*/}
                    {/*  <input*/}
                    {/*    type="radio"*/}
                    {/*    name="teamType"*/}
                    {/*    checked={fields.teamType === "high school"}*/}
                    {/*    onChange={(e) =>*/}
                    {/*      handleRadioChange("teamType", "high school")*/}
                    {/*    }*/}
                    {/*  />*/}
                    {/*  <label htmlFor="schoolCheck">High School</label>*/}
                    {/*</span>*/}
                  </div>
                </div>
              </div>
              <div className="fieldBox">
                <label>Team Name</label>
                <MuiAutoComplete
                  id="free-solo-demo"
                  clearOnBlur={true}
                  freeSolo={true}
                  options={
                    !!fields?.teamType
                      ? fields?.teamType == "club"
                        ? allTeam
                        : highSchool
                      : allTeam
                  }
                  getOptionLabel={
                    (option) => option.label
                    // !!fields?.teamType ? fields?.teamType == "club" ? option?.teamname ?? "" : option?.highschoolname ?? "" :option?.teamname ?? ""
                  }
                  value={fields.teamName || null}
                  onChangeObj={(event, newValue) => {
                    handleChange("teamName", { target: { value: newValue } });
                  }}
                  inputPlaceholder="Team name"
                />
                <div style={{ color: "red" }}>{errors["teamName"]}</div>
              </div>
              <div className="fieldBox">
                <label>
                  Do you want college coaches to be able to connect with your
                  coach through SportsForce?
                </label>
                <div className="radioFlexBox">
                  <div>
                    <span>
                      <input
                        type="radio"
                        name="connectTSF"
                        checked={
                          fields.connectTSF ? fields.connectTSF === "yes" : true
                        }
                        onChange={(e) => handleRadioChange("connectTSF", "yes")}
                      />
                      <label htmlFor="schoolCheck">Yes</label>
                    </span>
                  </div>
                  <div>
                    <span>
                      <input
                        type="radio"
                        name="connectTSF"
                        checked={fields.connectTSF === "no"}
                        onChange={(e) => handleRadioChange("connectTSF", "no")}
                      />
                      <label htmlFor="schoolCheck">No</label>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <p className="skip">
              Don't know?{" "}
              <Link to="#" onClick={handleClickOpen}>
                Skip it
              </Link>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link onClick={(e) => handleSubmit(e)}>Finish</Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            handleClose()
          }
        }}
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    coachTypeList: state.athlete.onBoarding.coachTypeList,
    countries: state.common.countries,
    onboarding: state.athlete.onBoarding.onboarding,
    coachDetails: state.athlete.onBoarding.coachDetails,
    allReferences: state.athlete.editUserProfileData.allReferences,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    coachTypeListStart: (data) => dispatch(coachTypeListStart(data)),
    countryListStart: () => dispatch(countryListStart()),
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveCoachDetailsStart: (data) => dispatch(saveCoachDetailsStart(data)),
    getCoachDetailsStart: (data) => dispatch(getCoachDetailsStart(data)),
    referencesListStart: (data) => dispatch(referencesListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryCoach);
