import React, {useEffect} from "react";
import logo from "../../../assets/img/newLogo.png";
import "./header.scss";
import {useHistory} from "react-router-dom";
import {getAuthToken, getUserInfo} from "../../../services/authService";
export default function Header() {
    const history = useHistory()
    //this check is over athlete once onboarding is done he cannot do onboarding and guarding is not
    //is restrict to do onboarding
    useEffect(()=>{
        if(getUserInfo()?.role == "ATHLETES" && getUserInfo()?.isonboardingdone == 1 && getAuthToken() && getUserInfo()?.isadmin == 0){
            history.push('/')
        }
        else if(getUserInfo()?.role == "GUARDIAN"){
            history.push('/')
        }
    },[])
  return (
    <div className="headerSection">
      <div className="logoPart">
        <a href="#">
          <img src={logo}></img>
        </a>
      </div>
    </div>
  );
}
