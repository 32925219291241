import React, { useEffect, useState } from "react";
import "./assets/css/payment.scss";
import cvv from "./assets/img/cvv.png";
import paymentIcon from "./assets/img/payments-icon.png";
import { useHistory } from "react-router";
import {connect, useDispatch} from "react-redux";
import {
  getCardListQuery,
  getPaymentCartListQuery,
  saveMembershipQuery,
  saveNewCardDetails,
} from "../upgradePlans/UpgradePlansQuery";
import { cardTypeListStart } from "../../../../../redux/athlete/dashboard/upgradePlans/CardTypeList";
import { getUserInfo } from "../../../../../services/authService";
import { Dialog } from "@mui/material";
import { saveToMembershipStart } from "../../../../../redux/athlete/dashboard/upgradePlans/saveToMembership";
import { IsValidName } from "../../../../../utils/validationUtils";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { addNewCardStart } from "../../../../../redux/athlete/dashboard/payment/savePaymentCard";
import { paymentCardListStart } from "../../../../../redux/athlete/dashboard/payment/paymentCardsList";
import BackdropLoader from "../../../../common/Loader";
import { saveVideoOrderQuery } from "../editUserProfile/videoPhoto/VideoPhotoQueries";
import { saveVideoOrderStart } from "../../../../../redux/athlete/dashboard/editUserProfile/photo-video/saveVideoOrder";
import { saveCreatePaymentStart } from "../../../../../redux/common/savePayments";
import { imageDownloadUrl } from "../../../../../config";
import swal from "sweetalert";
import messagePopup from "../../../../../utils/messagePopup";
import {fetchMethod} from "../../../../../services/httpService";

const initialValues = {
  cardId: "",
  cardHolderName: "",
  cardNumber: "",
  selectedCardType: "",
  selectedMonth: "",
  selectedYear: "",
  securityNumber: "",
};

const CardPopup = (props) => {
  const [securityNum, setSecurityNum] = useState("");
  const [cardError, setCardError] = useState({
    securityNumber: "",
  });

  const handleValidation = (fieldname, e) => {
    let errorsT = cardError;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;

    //securityNumber
    if (fieldname === undefined || fieldname === "securityNumber") {
      if (fieldname === undefined) {
        fieldValue = securityNum ? securityNum : "";
        updatedValue = fieldValue;
      }
      delete errorsT["securityNumber"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["securityNumber"] = "Security Number is required";
      }
    }
    setCardError({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const handleChange = (field, e) => {
    const { formIsValid, updatedValue } = handleValidation(field, e);
    if (field === "securityNumber") {
      setSecurityNum(updatedValue);
      props.getCvvValue(updatedValue);
    }
  };

  const {
    meta,
    getCardNumberProps,
    getCardImageProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const handleClosePopup = (e) => {
    props.close(e)
  }

  return (
    <>
      <div id="add-cart-toggle" className="payment-detial-box">
        <form className="payment-form-box">
          <div className="modal-header">
            <h5 className="modal-title">Payment Details</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                e.preventDefault();
                handleClosePopup(e);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body bottom-border">
            <div className="payment-icons">
              <img src={paymentIcon} alt="" />
            </div>
          <div className="fieldBox">
            <svg {...getCardImageProps({ images })} />
            <label>Card Number</label>
            <input
              disabled
              {...getCardNumberProps()}
              type="text"
              value={props.cardDetails.cardnumber || ""}
            />
          </div>

          <div className="fieldBox flexBox">
            <div className="half">
              <label>Expiry Year</label>
              <input
                disabled
                value={props.cardDetails.expiryyear}
                type="text"
              />
            </div>
            <div className="half">
              <label>Security Number</label>
              <div className="cvv-flexbox">
                <div className="cvv-input-box">
                  <input
                    {...getCVCProps({
                      onChange: (e) => handleChange("securityNumber", e),
                    })}
                    type="text"
                    value={securityNum}
                  />
                  <div style={{ color: "red", fontSize: "14px" }}>
                    {cardError && cardError.securityNumber
                      ? cardError.securityNumber
                      : ""}
                  </div>
                </div>
                <div className="cvv-icon">
                  <img src={cvv} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="fieldBox mb-0">
            <label>Card Holder Name</label>
            <input
              disabled
              value={props.cardDetails.cardholdername}
              type="text"
            />
          </div>
          </div>
          <div className="payment-button-box">
            <button
              className="btn green"
              onClick={(e) => {
                e.preventDefault();
                const { formIsValid, updatedValue } = handleValidation();
                if (formIsValid) {
                  props.submitCartDetails(e, true);
                  // setSecurityNum("");
                }
              }}
            >
              Proceed Payment
            </button>
            <button onClick={(e) => props.close(e)} className="btn">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

const Payment1 = (props) => {
  const [saveHandler, setSaveHandler] = useState(null);
  const [previousData, setPreviousData] = useState();
  const [membershipId, setMembershipId] = useState(null);
  const [selectedCard, setSelectedCard] = useState({});
  const [showNewCardForm, setShowNewCardForm] = useState(false);
  const [futureRef, setFutureRef] = useState(false);
  const [open, setOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState();
  const history = useHistory();
  const saveMembership = useDispatch()

  const [cardListData, setCardListData] = useState(null);

  const [addCardFields, setAddCardFields] = useState({
    cardId: "",
    cardHolderName: "",
    cardNumber: "",
    selectedCardType: "",
    selectedMonth: "",
    selectedYear: "",
    securityNumber: "",
    membership: "",
  });

  const [cardError, setCardError] = useState({
    cardId: "",
    cardHolderName: "",
    cardNumber: "",
    selectedCardType: "",
    selectedMonth: "",
    selectedYear: "",
    securityNumber: "",
  });

  const {
    meta,
    getCardNumberProps,
    getCardImageProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const inputHandler = async (field, e) => {
    setCardError({});
    let fieldsT = addCardFields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    await setAddCardFields({ ...fieldsT });
    setCardType();
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = cardError;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    //card holder name validations
    if (fieldname === undefined || fieldname === "cardHolderName") {
      if (fieldname === undefined) {
        fieldValue = addCardFields["cardHolderName"]
          ? addCardFields["cardHolderName"]
          : "";
        updatedValue = fieldValue;
      }
      delete errorsT["cardHolderName"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["cardHolderName"] = "Name is required";
      } else if (!IsValidName(updatedValue)) {
        formIsValid = false;
        errorsT["cardHolderName"] = "Please enter a valid name";
      }
    }
    //card number validation

    if (fieldname === undefined || fieldname === "cardNumber") {
      if (fieldname === undefined) {
        fieldValue = addCardFields["cardNumber"]
          ? addCardFields["cardNumber"]
          : "";
        updatedValue = fieldValue;
      }
      delete errorsT["cardNumber"];
      if (meta.erroredInputs && meta.erroredInputs.cardNumber) {
        if (meta.erroredInputs.cardNumber === "Enter a card number") {
          formIsValid = false;
          errorsT["cardNumber"] = "Card number is required.";
        } else {
          formIsValid = false;
          errorsT["cardNumber"] = meta.erroredInputs.cardNumber;
        }
      }
    }

    if (fieldname === undefined || fieldname === "selectedYear") {
      if (fieldname === undefined) {
        fieldValue = addCardFields["selectedYear"]
          ? addCardFields["selectedYear"]
          : "";
        updatedValue = fieldValue;
      }
      delete errorsT["selectedYear"];
      if (meta.erroredInputs && meta.erroredInputs.expiryDate) {
        if (meta.erroredInputs.expiryDate === "Enter an expiry date") {
          formIsValid = false;
          errorsT["selectedYear"] = "Expiry Date is required";
        } else {
          formIsValid = false;
          errorsT["selectedYear"] = meta.erroredInputs.expiryDate;
        }
      }
    }

    //securityNumber
    if (fieldname === undefined || fieldname === "securityNumber") {
      if (fieldname === undefined) {
        fieldValue = addCardFields["securityNumber"]
          ? addCardFields["securityNumber"]
          : "";
        updatedValue = fieldValue;
      }
      delete errorsT["securityNumber"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["securityNumber"] = "Security Number is required";
      }
    }

    setCardError({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const setCardType = () => {
    let fieldsT = addCardFields;
    if (meta.cardType) {
      switch (meta.cardType.type) {
        case "visa":
          fieldsT["selectedCardType"] = 1;
          break;
        case "mastercard":
          fieldsT["selectedCardType"] = 2;
          break;
        case "amex":
          fieldsT["selectedCardType"] = 3;
          break;
        case "discover":
          fieldsT["selectedCardType"] = 4;
          break;
        case "secure":
          fieldsT["selectedCardType"] = 5;
          break;
        case "maestro":
          fieldsT["selectedCardType"] = 6;
          break;
        default:
          fieldsT["selectedCardType"] = 1;
      }
    } else {
      fieldsT["selectedCardType"] = null;
    }
    setAddCardFields({ ...fieldsT });
  };

  useEffect(() => {
    const redirectTo = window.location.hash.split("/")[2];
    if (props.location.state) {
      setPreviousData(props.location.state);
    }
    else {
      //this done to go back from prev route
       // window.history.back()
    props.history.push(`/payment/${redirectTo}`)
    }
    refresh();
  }, [props.location.state]);

  const refresh = () => {
    fetchAllCardList();
    fetchAllCardtypes();
    //this used for scroll the page on the top
    window.scrollTo(0,0)
  };

  const fetchAllCardList = () => {
    props.paymentCardListStart({
      query: getPaymentCartListQuery,
      variables: {
        obj: {
          addcarforfuturerefrence: "1",
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  };
  const fetchAllCardtypes = () => {
    props.cardTypeList({
      query: getCardListQuery,
    });
  };
  const submitCartDetails =  (e, flag = null) => {
    e.preventDefault()
    let isValid = true;
    if (!flag) {
      const { formIsValid, updatedValue } = handleValidation();
      isValid = formIsValid;
    }
    if (isValid) {
      const newCardData = {
        userid: getUserInfo() ? getUserInfo().id : "",
        cardholdername: addCardFields.cardHolderName
          ? addCardFields.cardHolderName
          : "",
        cardtype: addCardFields.selectedCardType
          ? +addCardFields.selectedCardType
          : null,
        expiryyear: addCardFields.selectedYear
          ? addCardFields.selectedYear
          : "",
        expirymonth: addCardFields.selectedMonth
          ? addCardFields.selectedMonth
          : "",
        cardnumber: addCardFields.cardNumber ? addCardFields.cardNumber : "",
        addcarforfuturerefrence: 1,
      };
      let cloneData = [...cardListData],
          isCardNum = addCardFields?.cardNumber || "",
          isCardAdd = cloneData.filter(el => el?.cardnumber == isCardNum)
      if (futureRef && !open) {
        if(isCardAdd?.length  == 0){
          // fetchAllCardList()
            saveCreatePayments(newCardData)
        }else{
          messagePopup("", "Card Already Exist", "warning");
        }
      }else{
        // fetchAllCardList()
          saveCreatePayments(newCardData)
      }
  }
  };
  const saveDataInMembership = () => {
    if (previousData.previousPage === "professional-edit") {
      let orderData = {
        palnid: previousData.selectedPlan.id,//done as payment comp except this only
        allusededittngcount: 0,
        userid: getUserInfo() ? getUserInfo().id : 0,
      };
      props.saveVideoOrderStart({
        query: {
          videoOrder: saveVideoOrderQuery,
          membership: saveMembershipQuery,
        },
        variables: {
          obj: orderData,
        },
        nextRoute: previousData.nextRoute,
      });
    }
    if (previousData.previousPage === "program-and-pricing") {
      const date = new Date().toISOString().split("T")[0];
      const nextMonth = new Date(
        new Date().setFullYear(
          new Date().getFullYear(),
          new Date().getMonth() + 1
        )
      )
        .toISOString()
        .split("T")[0];

      const nextYear = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      )
        .toISOString()
        .split("T")[0];

      const nextMonthNextDay = new Date(
        new Date().setFullYear(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          new Date().getDate() + 1
        )
      )
        .toISOString()
        .split("T")[0];

      const nextYearNextDay = new Date(
        new Date().setFullYear(
          new Date().getFullYear() + 1,
          new Date().getMonth(),
          new Date().getDate() + 1
        )
      )
        .toISOString()
        .split("T")[0];

      const membershipData = {
        userid: getUserInfo() ? getUserInfo().id : "",
        subscriptionplanid:
          previousData.selectedPlan && previousData.selectedPlan.id
            ? previousData.selectedPlan.id
            : 0,
        planprice:
          previousData.selectedPlan && previousData.toggle
            ? previousData.toggle.isChecked
              ? previousData.selectedPlan.monthlyprice
              : previousData.selectedPlan.yearlyprice
            : 0,
        plantimeperiod:
          previousData.selectedPlan && previousData.toggle
            ? previousData.toggle.isChecked
              ? "Monthly"
              : "Yearly"
            : "",
        planexpirydate:
          previousData.selectedPlan && previousData.toggle
            ? previousData.toggle.isChecked
              ? nextMonth
              : nextYear
            : "",
        planrenewaldate:
          previousData.selectedPlan && previousData.toggle
            ? previousData.toggle.isChecked
              ? nextMonthNextDay
              : nextYearNextDay
            : "",
        planstartdate: date,
      };
        props.saveToMembership({
          query: saveMembershipQuery,
          variables: {
            obj: membershipData,
          },
          //by this page is redirecting to the homepage because at saga there
          // is a func which is redirecting it which is invalid
          // closeModal: closeHandler,
        });
        props.history.push(`/${redirectUrl}`);
    }
    //this condition is for when user is purchasing the video
    else if (previousData.previousPage === "video-and-photo"){
      props.saveToMembership({
        query: saveMembershipQuery,
        variables: {
          obj: {
            userid : getUserInfo().id || 0,
            videoandphotoplanid : +previousData.selectedPlan.id || 0
          },
        },
        msg : "Video purchased successfully"
      });
      setOpen(false)
      props.history.push('/resources/MasterClasses')
      // props.history.push(`/resources/detailsPage/${previousData.selectedPlan.id}`)
    }
  };

useEffect(()=> {
if(previousData && previousData.selectedPlan ){
  setMembershipId(previousData.selectedPlan.id)
}
}, [previousData])

useEffect(() => {
const redirectTo = window.location.hash.split("/")[2];
setRedirectUrl(redirectTo);
}, [])
  const saveCardDetail = (newCardData) =>{
    let cloneData = [...cardListData],
        isCardNum = addCardFields?.cardNumber || "",
        isCardAdd = cloneData.filter(el => el?.cardnumber == isCardNum)
    if(isCardAdd.length  == 0){
      props.saveNewCardDetails({
        query: saveNewCardDetails,
        variables: {
          obj: newCardData,
        },
        refresh : refresh
      });
    }
  }

  const saveCreatePayments = async (cardDetail) => {
    const price =
      previousData && previousData.selectedPlan && previousData.toggle
        ? previousData.toggle.isChecked
          ? previousData.selectedPlan?.monthlyprice.toString()
          : previousData.selectedPlan?.yearlyprice.toString()
        : 0;
    if (
      addCardFields.cardNumber &&
      addCardFields.securityNumber &&
      membershipId &&
      addCardFields.selectedYear &&
      price
    ) {
      const data = {
        userId: getUserInfo() ? getUserInfo().id : "",
            cardNumber: addCardFields.cardNumber
            ? parseInt(addCardFields.cardNumber.replaceAll(/\s/g, ""))
            : "",
            cvv: addCardFields.securityNumber
            ? parseInt(addCardFields.securityNumber)
            : "",
            expireDate: addCardFields.selectedYear
            ? addCardFields.selectedYear
            : "",
            membershipid: membershipId ? membershipId : "",
            amount: price ? price : "",
      },
          _r = await fetchMethod(`/api/Payments/createPayment`,
          data,
          "post",
          true)
      if(_r?.status == 200 && _r?.data?.response?.status == 200){
           futureRef && saveCardDetail(cardDetail)
            saveDataInMembership()
      }else{
       messagePopup("",!!_r?.data?.response?.msg ? _r?.data?.response?.msg : "Payment Decline","error")
      }
    }
  };

  useEffect(() => {
    if (props.paymentCardList.data) {
      setCardListData(props.paymentCardList.data);
    }
  }, [props.paymentCardList.data]);

  const AddCardPopup = (e, data) => {
    e.preventDefault();
    setOpen(true);
    setAddCardFields({
      ...addCardFields,
      cardNumber: data && data.cardnumber ? data.cardnumber : "",
      selectedYear: data && data.expiryyear ? data.expiryyear : "",
    });
  };
  const closeHandler = (e) => {
    e.preventDefault();
    setOpen(false);
    setAddCardFields({ ...initialValues });
    // props.history.push("/programAndPricing")
    //this is used for going to the prev route
    // window.history.back()

  };
  const handleAddCardClick = () => {
    setSelectedCard({});
    setShowNewCardForm(!showNewCardForm);
  };

  const getCvvValue = (data) => {
    setAddCardFields({
      ...addCardFields,
      securityNumber: data ? data : "",
    });
  };
  return (
    <div>
      <BackdropLoader
        open={
          props.paymentCardList.loading ||
          props.newCardDetails.loading ||
          props.saveVideoOrder.loading ||
          props.saveToMembershipData.loading
        }
      />
      <section className="payment-main-sec">
        <div className="container">
          <div className="payment-data-box">
            <h1 className="title">Select Payment Option</h1>
            <div className="payment-detial-box">
              <div className="payment-listing">
                {cardListData?.length > 0 ? (
                  cardListData.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <input
                          type="radio"
                          name="payment"
                          value={item.id}
                          checked={item.id === selectedCard.id}
                          onChange={() => {
                            setShowNewCardForm(false);
                            setSelectedCard(item);
                          }}
                        />
                        <label className="payment-info-box">
                          <div className="detial-box">
                            <div className="payment-icon">
                              {item.carddetailscardtypemaprel.Cardtypes
                                ? item.carddetailscardtypemaprel.Cardtypes.map(
                                    (card, idx) => (
                                      <img
                                        key={idx}
                                        src={`${imageDownloadUrl}/${card.cardimage}`}
                                      />
                                    )
                                  )
                                : ""}
                            </div>
                            <p>
                              <span>
                                ****{item.cardnumber.toString().slice(8)}
                              </span>
                              <span>Expires {item.expiryyear}</span>
                            </p>
                            <h4>{item.cardholdername}</h4>
                          </div>
                        </label>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    {" "}
                    <p>No card saved, Please add new card for payment.</p>
                  </div>
                )}
              </div>
              {cardListData?.length > 0 ? (
                <div className="payment-button-box">
                  <button
                    disabled={Object.keys(selectedCard).length === 0}
                    className="btn green"
                    onClick={(e) => AddCardPopup(e, selectedCard)}
                  >
                    Proceed to Payment
                  </button>
                  <button
                    className="btn"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              ) : null}
            </div>
            <Dialog
              open={open}
              onClose={closeHandler}
              // maxWidth={"lg"}
              className={"paymentCartPopup"}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  closeHandler()
                }
              }}
              className="matDialogCustom"
            >
              <CardPopup
                getCvvValue={getCvvValue}
                close={closeHandler}
                cardDetails={selectedCard}
                submitCartDetails={submitCartDetails}
              />
            </Dialog>

            <div className="payment-detail-new-box">
              <div className="top-block">
                <h2 className="title">Add New Details</h2>
                <div className="payment-flexbox">
                  <div className="item">
                    <button
                      id="add-cart-button"
                      className="btn lightblue"
                      onClick={() => handleAddCardClick()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.25"
                        height="20.25"
                        viewBox="0 0 20.25 20.25"
                      >
                        <g id="plus" transform="translate(-7.875 -7.875)">
                          <path
                            id="Path_646"
                            data-name="Path 646"
                            d="M18,7.875A1.125,1.125,0,0,1,19.125,9v9A1.125,1.125,0,0,1,18,19.125H9a1.125,1.125,0,0,1,0-2.25h7.875V9A1.125,1.125,0,0,1,18,7.875Z"
                            fill="#fff"
                            fillRule="evenodd"
                          />
                          <path
                            id="Path_647"
                            data-name="Path 647"
                            d="M16.875,18A1.125,1.125,0,0,1,18,16.875h9a1.125,1.125,0,0,1,0,2.25H19.125V27a1.125,1.125,0,0,1-2.25,0Z"
                            fill="#fff"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>{" "}
                      Add Card
                    </button>
                  </div>
                  <div className="item">
                    <img src={paymentIcon} alt="Payment Card" />
                  </div>
                </div>
              </div>
              <div
                id="add-cart-toggle"
                className="payment-detial-box"
                style={{ display: `${showNewCardForm ? "block" : "none"}` }}
              >
                <form className="payment-form-box">
                  <div className="fieldBox">
                    <svg {...getCardImageProps({ images })} />
                    <label>Card Number<span style={{color:"red"}}>*</span></label>

                    <input
                      {...getCardNumberProps({
                        onChange: (e) => {
                          inputHandler("cardNumber", e);
                        },
                      })}
                      type="text"
                      value={addCardFields.cardNumber}
                    />

                    <div style={{ color: "red", fontSize: "14px" }}>
                      {cardError && cardError.cardNumber
                        ? cardError.cardNumber
                        : ""}
                    </div>
                  </div>
                  <div className="fieldBox flexBox">
                    <div className="half">
                      <label>Expiry Date<span style={{color:"red"}}>*</span></label>
                      <input
                        {...getExpiryDateProps({
                          onChange: (e) => inputHandler("selectedYear", e),
                        })}
                        value={addCardFields.selectedYear}
                      />
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {cardError && cardError.selectedYear
                          ? cardError.selectedYear
                          : ""}
                      </div>
                    </div>
                    <div className="half">
                      <label>Security Number<span style={{color:"red"}}>*</span></label>
                      <div className="cvv-flexbox">
                        <div className="cvv-input-box">
                          <input
                            {...getCVCProps({
                              onChange: (e) =>
                                inputHandler("securityNumber", e),
                            })}
                            value={addCardFields.securityNumber}
                          />
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {cardError && cardError.securityNumber
                              ? cardError.securityNumber
                              : ""}
                          </div>
                        </div>
                        <div className="cvv-icon">
                          <img src={cvv} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fieldBox">
                    <label>Name on Card<span style={{color:"red"}}>*</span></label>
                    <input
                      type="text"
                      value={addCardFields.cardHolderName}
                      onChange={(e) => inputHandler("cardHolderName", e)}
                    />
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {cardError && cardError.cardHolderName
                        ? cardError.cardHolderName
                        : ""}
                    </div>
                  </div>
                  <div className="fieldBox">
                    <p className="checkbox-list">
                      <span>
                        <input
                          type="checkbox"
                          checked={futureRef}
                          onChange={() => setFutureRef(!futureRef)}
                        />
                        <label> Add this card for future reference also.</label>
                      </span>
                    </p>
                  </div>
                  <div className="payment-button-box">
                    <button
                      className="btn green"
                      onClick={(e) => {
                        submitCartDetails(e);
                      }}
                    >
                      Add card and Proceed Payment
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentCardList: state.athlete.dashboard.payment.paymentCardList,
    newCardDetails: state.athlete.dashboard.payment.newCard,
    cardTypes: state.athlete.dashboard.upgradePlans,
    saveVideoOrder: state.athlete.editUserProfileData.saveVideoOrder,
    saveToMembershipData: state.athlete.dashboard.upgradePlans.saveToMembership,
    saveCreatePayment : state.common.saveCreatePayment,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paymentCardListStart: (data) => dispatch(paymentCardListStart(data)),
    cardTypeList: (data) => dispatch(cardTypeListStart(data)),
    saveNewCardDetails: (data) => dispatch(addNewCardStart(data)),
    saveToMembership: (data) => dispatch(saveToMembershipStart(data)),
    saveVideoOrderStart: (data) => dispatch(saveVideoOrderStart(data)),
    saveCreatePaymentStart: (data) => dispatch(saveCreatePaymentStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment1);
