import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_PARENT_LIST_START: "ALL_PARENT_LIST_START",
  ALL_PARENT_LIST_SUCCESS: "ALL_PARENT_LIST_SUCCESS",
  ALL_PARENT_LIST_FAILED: "ALL_PARENT_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const allParentListStart = (data) => ({
  type: actionType.ALL_PARENT_LIST_START,
  payload: data,
});

export const allParentListSuccess = (data) => ({
  type: actionType.ALL_PARENT_LIST_SUCCESS,
  payload: data,
});

export const allParentListFailed = (data) => ({
  type: actionType.ALL_PARENT_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allParentListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ALL_PARENT_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_PARENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_PARENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allParentListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      let responseData =
        response &&
        response.data &&
        response.data.data &&
        response.data.data.allAthleteparentmappings &&
        response.data.data.allAthleteparentmappings.Athleteparentmappings;
      yield put(
        allParentListSuccess({
          parentData: responseData,
        })
      );
    } else {
      messagePopup("", "Parent List failed", "error");
      yield put(allParentListFailed("Parent List failed"));
    }
  } catch (error) {
    messagePopup("", "Parent List failed", "error");
    yield put(allParentListFailed("Parent List failed"));
  }
}

export function* watchallParentListSaga() {
  yield takeEvery(actionType.ALL_PARENT_LIST_START, allParentListSaga);
}
