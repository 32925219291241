import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SAVE_VIDEO_DRAFT_START: "SAVE_VIDEO_DRAFT_START",
  SAVE_VIDEO_DRAFT_FAIL: "SAVE_VIDEO_DRAFT_FAIL",
  SAVE_VIDEO_DRAFT_SUCCESS: "SAVE_VIDEO_DRAFT_SUCCESS",
};

// ============================================ Actions ========================================================
export const saveVideoDraftStart = (data) => ({
  type: actionType.SAVE_VIDEO_DRAFT_START,
  payload: data,
});

export const saveVideoDraftFail = (data) => ({
  type: actionType.SAVE_VIDEO_DRAFT_FAIL,
  payload: data,
});

export const saveVideoDraftSuccess = (data) => ({
  type: actionType.SAVE_VIDEO_DRAFT_SUCCESS,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function videoEditingReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_VIDEO_DRAFT_START:
      return {
        ...state,
        loading: true,
      };

    case actionType.SAVE_VIDEO_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case actionType.SAVE_VIDEO_DRAFT_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };

    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* VideoDraftSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );

    if (
      response &&
      response.status === 200 &&
      response.data.data.saveVideoedittingdetails
    ) {
      if (response.data.data.saveVideoedittingdetails.isdraft) {
        messagePopup("", "Draft Saved Successfully", "success");
      } else {
        messagePopup("", "Video Details Submitted", "success");
      }
      yield put(
        saveVideoDraftSuccess({
          editingDetails: response.data.data.saveVideoedittingdetails,
        })
      );
    } else {
      messagePopup("", "Video Draft failed", "error");
      yield put(saveVideoDraftFail("Team List failed"));
    }
  } catch (error) {
    messagePopup("", "Video Draft failed", "error");
    yield put(saveVideoDraftFail("Team List failed"));
  }
}

//Watcher
export function* watchVideoDraftSaga() {
  yield takeEvery(actionType.SAVE_VIDEO_DRAFT_START, VideoDraftSaga);
}
