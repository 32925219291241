import { put, takeEvery } from "redux-saga/effects";
import {
  removeSignupData,
  setAuthToken,
  setUserInfo,
} from "../../../services/authService";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
import history from "../../../services/history";

// ========================================== Action Type =====================================================
export const actionType = {
  SIGNUP_START: "SIGNUP_START",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILED: "SIGNUP_FAILED",
  RESET_STATE: "RESET_STATE",
};

// ============================================ Actions ========================================================
export const signupStart = (data) => ({
  type: actionType.SIGNUP_START,
  payload: data,
});

export const resetState = () => ({
  type: actionType.RESET_STATE,
});

export const signupSuccess = (data) => ({
  type: actionType.SIGNUP_SUCCESS,
  payload: data,
});

export const signupFailed = (data) => ({
  type: actionType.SIGNUP_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  // loading: false,
  data: null,
  error: null,
};

export default function signupReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.RESET_STATE:
      return {
        ...state,
        data: null,
        error: null,
      };
    case actionType.SIGNUP_START:
      return {
        ...state,
        // loading: true,
      };
    case actionType.SIGNUP_SUCCESS:
      return {
        ...state,
        // loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SIGNUP_FAILED:
      return {
        ...state,
        // loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* signupSaga(action) {
  try {
    history.push("/settingAccount");
    const response = yield fetchMethod(
      `/api/Userdata/createUser`,
      action.payload,
      "post",
      false
    );
    if (response && response.status === 200) {
      
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        const userInfo = response.data.response.data
          ? response.data.response.data
          : null;
        if (userInfo) {
          setUserInfo(userInfo);
          setAuthToken(userInfo.token);
          yield put(signupSuccess(userInfo));
          removeSignupData();
        }
      } else if (response.data.response.status === 201) {
        messagePopup("", response.data.response.msg, "error");
        yield put(signupFailed(response.data.response.msg));
      }
    } else {
      messagePopup("", "Signup failed", "error");
      yield put(signupFailed("Signup failed"));
    }
  } catch (error) {
    messagePopup("", "Signup failed", "error");
    yield put(signupFailed("Signup failed"));
  }
}

export function* watchSignupSaga() {
  yield takeEvery(actionType.SIGNUP_START, signupSaga);
}
