import React from 'react';
import { Link } from 'react-router-dom';

export default function CollegesTabs(props) {
  const [expand, setExpand] = React.useState(false);
  const handleMenuClick = () => {
    setExpand(true);
  };
  const handleCloseBtn = () => {
    setExpand(false);
  };
  const getMobileRespoLabel = () => {
    switch (props.active) {
      case 'list':
        return 'My Colleges';
      case 'search':
        return 'Search';
      case 'activity':
        return 'Activity';
      case 'matches':
        return 'Matches';
      case 'preferences':
        return 'Preferences';
      default:
        break;
    }
  };

  return (
    <>
      <div className="tabs">
        <ul>
          <li>
            <Link
              to="/colleges/list"
              className={props.active === 'list' ? 'active' : ''}
            >
              My Colleges
            </Link>
          </li>
          <li>
            <Link
              to="/colleges/search"
              className={props.active === 'search' ? 'active' : ''}
            >
              Search
            </Link>
          </li>
          <li>
            <Link
              to="/colleges/activity"
              className={props.active === 'activity' ? 'active' : ''}
            >
              Activity{' '}
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12.888"
                  viewBox="0 0 12 12.888"
                >
                  <g transform="translate(-3.373 -1.125)">
                    <path
                      className="a"
                      d="M13.51,8.5V7.347h-.881V8.68a.406.406,0,0,0,.132.311l1.19,1.2V10.9h-9.7v-.711l1.19-1.2a.406.406,0,0,0,.132-.311V6.458A3.541,3.541,0,0,1,9.1,2.9a3.455,3.455,0,0,1,1.762.484V2.369a4.251,4.251,0,0,0-1.322-.356V1.125H8.662v.889A4.5,4.5,0,0,0,4.7,6.458V8.5L3.506,9.7a.406.406,0,0,0-.132.311v1.333a.42.42,0,0,0,.12.323.412.412,0,0,0,.32.121H6.9a2.2,2.2,0,1,0,4.407,0h3.085a.412.412,0,0,0,.32-.121.42.42,0,0,0,.12-.323V10.013A.406.406,0,0,0,14.7,9.7ZM9.1,13.124a1.328,1.328,0,0,1-1.322-1.333h2.644A1.328,1.328,0,0,1,9.1,13.124Z"
                      transform="translate(0 0)"
                    />
                    <path
                      className="a"
                      d="M29.079,6.664A2.164,2.164,0,1,1,26.914,4.5,2.164,2.164,0,0,1,29.079,6.664Z"
                      transform="translate(-13.706 -1.752)"
                    />
                  </g>
                </svg>
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/colleges/matches"
              className={props.active === 'matches' ? 'active' : ''}
            >
              Matches
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                >
                  <path
                    className="a"
                    d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                    transform="translate(-3 -3)"
                  />
                </svg>
              </span>
            </Link>
          </li>
        </ul>
        <div className="mobileMenuBox">
          <div className="menuBtn" id="menuBtn">
            <button className="btn" onClick={handleMenuClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="12"
                viewBox="0 0 16 12"
              >
                <g transform="translate(-284.664 -20.498)">
                  <rect
                    className="a"
                    width="16"
                    height="2"
                    rx="1"
                    transform="translate(284.664 20.498)"
                  />
                  <rect
                    className="a"
                    width="16"
                    height="2"
                    rx="1"
                    transform="translate(284.664 25.498)"
                  />
                  <rect
                    className="a"
                    width="16"
                    height="2"
                    rx="1"
                    transform="translate(284.664 30.498)"
                  />
                </g>
              </svg>
            </button>
          </div>
          <div className="label">
            <span>{getMobileRespoLabel()}</span>
          </div>
          <div className={`mobileMenu ${expand ? 'expandMenu' : ''}`}>
            <div className="closeBtnBox">
              <button className="closebtn" onClick={handleCloseBtn}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.804"
                  height="15.803"
                  viewBox="0 0 15.804 15.803"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <g className="a" transform="translate(-6.776 -6.776)">
                    <path
                      d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                      transform="translate(0 0)"
                    />
                  </g>
                </svg>
              </button>
            </div>
            <ul>
              <li>
                <Link
                  to="/colleges/list"
                  className={props.active === 'list' ? 'selected' : ''}
                >
                  <span>My Colleges</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/colleges/search"
                  className={props.active === 'search' ? 'selected' : ''}
                >
                  <span>Search</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/colleges/activity"
                  className={props.active === 'activity' ? 'selected' : ''}
                >
                  <span>
                    Activity
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12.888"
                        viewBox="0 0 12 12.888"
                      >
                        {/* <defs>
                                                        <style></style>
                                                    </defs> */}
                        <g transform="translate(-3.373 -1.125)">
                          <path
                            className="a"
                            d="M13.51,8.5V7.347h-.881V8.68a.406.406,0,0,0,.132.311l1.19,1.2V10.9h-9.7v-.711l1.19-1.2a.406.406,0,0,0,.132-.311V6.458A3.541,3.541,0,0,1,9.1,2.9a3.455,3.455,0,0,1,1.762.484V2.369a4.251,4.251,0,0,0-1.322-.356V1.125H8.662v.889A4.5,4.5,0,0,0,4.7,6.458V8.5L3.506,9.7a.406.406,0,0,0-.132.311v1.333a.42.42,0,0,0,.12.323.412.412,0,0,0,.32.121H6.9a2.2,2.2,0,1,0,4.407,0h3.085a.412.412,0,0,0,.32-.121.42.42,0,0,0,.12-.323V10.013A.406.406,0,0,0,14.7,9.7ZM9.1,13.124a1.328,1.328,0,0,1-1.322-1.333h2.644A1.328,1.328,0,0,1,9.1,13.124Z"
                            transform="translate(0 0)"
                          ></path>
                          <path
                            className="a"
                            d="M29.079,6.664A2.164,2.164,0,1,1,26.914,4.5,2.164,2.164,0,0,1,29.079,6.664Z"
                            transform="translate(-13.706 -1.752)"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/colleges/matches"
                  className={props.active === 'matches' ? 'selected' : ''}
                >
                  <span>
                    Matches
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <path
                          className="a"
                          d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                          transform="translate(-3 -3)"
                        ></path>
                      </svg>
                    </span>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="prefer">
        <Link
          to="/colleges/preferences"
          className={props.active === 'preferences' ? 'selected' : ''}
        >
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.365"
              height="18"
              viewBox="0 0 17.365 18"
            >
              <defs>
                <style></style>
              </defs>
              <path
                className="a"
                d="M19.212,12.655A3.434,3.434,0,0,1,20.48,7.971L18.711,4.907a3.39,3.39,0,0,1-1.72.466,3.434,3.434,0,0,1-3.423-3.445H10.031a3.389,3.389,0,0,1-.456,1.727A3.434,3.434,0,0,1,4.884,4.9L3.115,7.963A3.39,3.39,0,0,1,4.378,9.219a3.434,3.434,0,0,1-1.26,4.68l1.769,3.064A3.389,3.389,0,0,1,6.6,16.5a3.434,3.434,0,0,1,3.422,3.427h3.538a3.39,3.39,0,0,1,.456-1.709A3.434,3.434,0,0,1,18.7,16.971l1.769-3.064a3.39,3.39,0,0,1-1.256-1.252ZM11.8,14.573a3.645,3.645,0,1,1,3.645-3.645A3.645,3.645,0,0,1,11.8,14.573Z"
                transform="translate(-3.115 -1.928)"
              />
            </svg>
          </span>
          <span>Preferences</span>
        </Link>
      </div>
    </>
  );
}
