import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    ACT_COMPOSITE_SCORES_LIST_START: "ACT_COMPOSITE_SCORES_LIST_START",
    ACT_COMPOSITE_SCORES_LIST_SUCCESS: "ACT_COMPOSITE_SCORES_LIST_SUCCESS",
    ACT_COMPOSITE_SCORES_LIST_FAILED: "ACT_COMPOSITE_SCORES_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const actCompositeScoresListStart = (data) => ({
    type: actionType.ACT_COMPOSITE_SCORES_LIST_START,
    payload: data,
});

export const actCompositeScoresListSuccess = (data) => ({
    type: actionType.ACT_COMPOSITE_SCORES_LIST_SUCCESS,
    payload: data,
});

export const actCompositeScoresListFailed = (data) => ({
    type: actionType.ACT_COMPOSITE_SCORES_LIST_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function actCompositeScoresListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.ACT_COMPOSITE_SCORES_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.ACT_COMPOSITE_SCORES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.ACT_COMPOSITE_SCORES_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* actCompositeScoresListSaga(action) {
    try {
       
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            const allactCompositeScores =  response.data.data.allActcompositescores.Actcompositescores
            yield put(actCompositeScoresListSuccess(allactCompositeScores));
        } else {
            messagePopup("", "act Composite Scores List failed", "error");
            yield put(actCompositeScoresListFailed("act Composite Scores List failed"));
        }
    } catch (error) {
        messagePopup("", "act Composite Scores List failed", "error");
        yield put(actCompositeScoresListFailed("act Composite Scores List failed"));
    }
}

export function* watchActCompositeScoresListSaga() {
    yield takeEvery(actionType.ACT_COMPOSITE_SCORES_LIST_START, actCompositeScoresListSaga);
}
