import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";
import toastr from "toastr";

// ========================================== Action Type =====================================================
export const actionType = {
  USER_ACADEMIC_COLLEGE_START: "USER_ACADEMIC_COLLEGE_START",
  USER_ACADEMIC_COLLEGE_SAVE: "USER_ACADEMIC_COLLEGE_SAVE",
  USER_ACADEMIC_REST_COLLEGE_SAVE: "USER_ACADEMIC_REST_COLLEGE_SAVE",
  USER_ACADEMIC_COLLEGE_SUCCESS: "USER_ACADEMIC_COLLEGE_SUCCESS",
  USER_ACADEMIC_COLLEGE_FAILED: "USER_ACADEMIC_COLLEGE_FAILED",
};

// ============================================ Actions ========================================================
export const userAcademicCollegeStart = (data) => ({
  type: actionType.USER_ACADEMIC_COLLEGE_START,
  payload: data,
});
export const userAcademicCollegeSave = (data) => ({
  type: actionType.USER_ACADEMIC_COLLEGE_SAVE,
  payload: data,
});
export const userAcademicRestCollegeSave = (data) => ({
  type: actionType.USER_ACADEMIC_REST_COLLEGE_SAVE,
  payload: data,
});
export const userAcademicCollegeSuccess = (data) => ({
  type: actionType.USER_ACADEMIC_COLLEGE_SUCCESS,
  payload: data,
});

export const userAcademicCollegeFailed = (data) => ({
  type: actionType.USER_ACADEMIC_COLLEGE_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function userAcademicCollegeReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.USER_ACADEMIC_COLLEGE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.USER_ACADEMIC_COLLEGE_SAVE:
      return {
        ...state,
        loading: true,
      };
    case actionType.USER_ACADEMIC_COLLEGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.USER_ACADEMIC_COLLEGE_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* userAcademicCollegeSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const collegeSaveResponse = response.data.data.saveAthletemorecollege;
      yield put(
        userAcademicCollegeSuccess({
          saveUserAcademicCollege: collegeSaveResponse,
        })
      );
      if (action.payload.handleCollegeSaveMessage) {
        action.payload.handleCollegeSaveMessage();
      } else if (collegeSaveResponse.active == 0) {
        // messagePopup("", "College Removed successfully.", "success");
        toastr.success("College Removed successfully.", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      }
    } else {
      messagePopup("", "User College Save failed", "error");
      yield put(userAcademicCollegeFailed("User College Save failed"));
    }
  } catch (error) {
    messagePopup("", "User College Save failed", "error");
    yield put(userAcademicCollegeFailed("User College Save failed"));
  }
}

export function* userAcademicCollegeRestSaveSaga(action) {
  try {
    const response = yield fetchMethod(
        "/api/Athletemorecolleges/AddAthleteCollege",
        action.payload.variables?.obj,
        "POST",
        true
    );
    if (response && response.status === 200 && response?.data?.response?.status == 200) {
      messagePopup("",!!response?.data?.response?.msg ? response?.data?.response?.msg :
            `User College ${(!!action.payload.variables?.obj?.id) ? "Updated" : "Added"}`, 'success')
      yield put(
          userAcademicCollegeSuccess(`User College ${(!!action.payload.variables?.obj?.id) ? "Updated" : "Added"}`)
      );
      action?.payload?.nextRoute()
      //this func is used for call the listing update operation
      action?.payload?.getAllCollegeListAPI()
    }else if(response && response.status === 200 && response?.data?.response?.status == 201){
      messagePopup("",!!response?.data?.response?.msg ? response?.data?.response?.msg :
          `User College Error}`, 'warning')
      action?.payload?.getAllCollegeListAPI()
    }
    else {
      messagePopup("", "User Rest College Save failed", "error");
      yield put(userAcademicCollegeFailed("User College Save failed"));
    }
  } catch (error) {
    messagePopup("", "User Rest College Save failed", "error");
    yield put(userAcademicCollegeFailed("User College Save failed"));
  }
}

export function* userAcademicCollegeListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        userAcademicCollegeSuccess({
          allUserAcademicCollege: response.data.data.allAthletemorecolleges,
        })
      );
    } else {
      messagePopup("", "User College List failed", "error");
      yield put(userAcademicCollegeFailed("User College List failed"));
    }
  } catch (error) {
    messagePopup("", "User College List failed", "error");
    yield put(userAcademicCollegeFailed("User College List failed"));
  }
}

//Watcher
export function* watchUserAcademicCollegeSaga() {
  yield takeEvery(
    actionType.USER_ACADEMIC_COLLEGE_SAVE,
    userAcademicCollegeSaveSaga
  );
  yield takeEvery(
      actionType.USER_ACADEMIC_REST_COLLEGE_SAVE,
      userAcademicCollegeRestSaveSaga
  );
  yield takeEvery(
    actionType.USER_ACADEMIC_COLLEGE_START,
    userAcademicCollegeListSaga
  );
}
