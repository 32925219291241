import { put, takeEvery } from "redux-saga/effects";
import { getAuthToken, getUserInfo } from "../../../services/authService";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
// import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  PROFILE_VIDEO_PHOTO_LIST_START: "PROFILE_VIDEO_PHOTO_LIST_START",
  PROFILE_VIDEO_PHOTO_LIST_SUCCESS: "PROFILE_VIDEO_PHOTO_LIST_SUCCESS",
  PROFILE_VIDEO_PHOTO_LIST_FAILED: "PROFILE_VIDEO_PHOTO_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const profileVideoPhotoListStart = (data) => ({
  type: actionType.PROFILE_VIDEO_PHOTO_LIST_START,
  payload: data,
});

export const profileVideoPhotoListSuccess = (data) => ({
  type: actionType.PROFILE_VIDEO_PHOTO_LIST_SUCCESS,
  payload: data,
});

export const profileVideoPhotoListFailed = (data) => ({
  type: actionType.PROFILE_VIDEO_PHOTO_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function profileVideoPhotoListReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.PROFILE_VIDEO_PHOTO_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.PROFILE_VIDEO_PHOTO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.PROFILE_VIDEO_PHOTO_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* profileVideoPhotoListSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Uploadedvideoandphotos/getAllPhotosAndVideosData`,
      action.payload.data,
      "post",
      action.payload.data.isAccessToken ? true : false
    );
    if (response && response.status === 200) {
      let finalData = [];
      finalData = response?.data?.response?.data;
      yield put(
        profileVideoPhotoListSuccess({
          allUploadedvideoandphotos: finalData,
        })
      );
    } else {
      messagePopup("", "Video Photo List failed", "error");
      yield put(profileVideoPhotoListFailed("Video Photo List failed"));
    }
  } catch (error) {
    messagePopup("", "Video Photo List failed", "error");
    yield put(profileVideoPhotoListFailed("Video Photo List failed"));
  }
}

export function* watchProfileVideoPhotoListSaga() {
  yield takeEvery(
    actionType.PROFILE_VIDEO_PHOTO_LIST_START,
    profileVideoPhotoListSaga
  );
}
