import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BackdropLoader from "../../../../common/Loader";
import { footListStart } from "../../../../../redux/athlete/onBoarding/footList";
import { inchListStart } from "../../../../../redux/athlete/onBoarding/inchList";
import {
  getAllHeightInFeetsQuery,
  getAllHeightInInchesQuery,
} from "./PhysicalMeasurementsQuery";

import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardPhysicalMeasure.scss";
import "../assets/css/onBoardSteeper.scss";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import {
  getAthleteHeightWeight,
  getOnboardingQuery,
  saveAthleteHeightWeight,
  saveOnboardingQuery,
} from "../OnboardingQuery";
import { getUserInfo } from "../../../../../services/authService";
import usePrevious from "../../../../../utils/usePrevious";
import Stepper from "../Stepper";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import { Dialog } from "@mui/material";
import { heigthWeightsaveStart } from "../../../../../redux/athlete/onBoarding/heightWeight";
import {
  athleteHeightWeightSaveStart,
  athleteHeightWeightStart,
} from "../../../../../redux/athlete/onBoarding/athleteheightandweights";
import messagePopup from "../../../../../utils/messagePopup";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "passed", link: "/onboarding/positions" },
  { className: "active", link: "/onboarding/physicalMeasurements" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  // { className: "" },
];

function PhysicalMeasurements(props) {
  const [fields, setFields] = useState({});
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    props.history.push("/onboarding/personalRecords");
  };
  useEffect(() => {
    setFields({});
    props.footListStart({ query: getAllHeightInFeetsQuery, variables: null });
    props.inchListStart({ query: getAllHeightInInchesQuery, variables: null });
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
    let id = 0;
    if (props.onboarding.data && props.onboarding.data.id) {
      id = props.onboarding.data.id;
    }
    if (id) {
      props.athleteHeightWeightStart({
        query: getAthleteHeightWeight,
        variables: {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            onboardingId: id ? id : null,
          },
        },
      });
    } else {
      props.athleteHeightWeightStart({
        query: getAthleteHeightWeight,
        variables: {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = fields;
        const { id, heightinfeet, heightininches, weight } =
          props.onboarding.data;
        fieldT["id"] = id;
        fieldT["Foot"] = heightinfeet ? `${heightinfeet}` : null;
        fieldT["Inches"] = heightininches ? `${heightininches}` : null;
        fieldT["weight"] = weight ? weight : null;
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);

  const handleChange = (field, e) => {
    let updatedValue = e ? (e.target ? e.target.value : e) : e;
    let fieldsT = fields;
    if(field == "PreferNotToAnswer"){
      fieldsT[field] = updatedValue;
      fieldsT['weight'] = ""
    }else{
      fieldsT[field] = updatedValue;
    }
    setFields({
      ...fieldsT,
    });
  };

  const convertFootInchIntoCm = () => {
    let fieldsValuesFoot = fields.Foot;
    let fieldsValuesInch = fields.Inches;
    let convertIntoCm = (
      fieldsValuesFoot * 30.48 +
      fieldsValuesInch * 2.54
    ).toFixed(2);
    return convertIntoCm;
  };

  const convertPoundsToKg = (pound) => {
    if (pound) return (pound / 2.2046).toFixed(2);
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      fields.Foot ||
      fields.Inches ||
      fields.PreferNotToAnswer ||
      fields.weight
    ) {
      const height = convertFootInchIntoCm();
      let finalData = {};
      if (!fields.PreferNotToAnswer) {
        finalData = {
          weight: fields.weight,
          weightinkilogram: convertPoundsToKg(fields.weight),
        };
      }
      let athleteHeightId = null;
      if (
        props.athleteHeightWeight &&
        props.athleteHeightWeight.data &&
        props.athleteHeightWeight.data.athleteHeightAndWeight &&
        props.athleteHeightWeight.data.athleteHeightAndWeight
          .allAthleteheightandweights &&
        props.athleteHeightWeight.data.athleteHeightAndWeight
          .allAthleteheightandweights.Athleteheightandweights &&
        props.athleteHeightWeight.data.athleteHeightAndWeight
          .allAthleteheightandweights.Athleteheightandweights[0]
      ) {
        athleteHeightId =
          props.athleteHeightWeight.data.athleteHeightAndWeight
            .allAthleteheightandweights.Athleteheightandweights[0].id;
      }
      props.athleteHeightWeightSaveStart({
        query: saveAthleteHeightWeight,
        variables: {
          obj: {
            id: athleteHeightId,
            onboardingid: fields.id ? fields.id : 0,
            userid: getUserInfo().id,
            heightinfeet: fields.Foot ? +fields.Foot : null,
            heightininches: fields.Inches ? +fields.Inches : null,
            heightincentimeter: height ? +height : null,
            weight: finalData.weight ? +finalData.weight : null,
            weightinkilogram: finalData.weightinkilogram
              ? +finalData.weightinkilogram
              : null,
          },
        },
      });
      props.saveOnboardingStart({
        query: saveOnboardingQuery,
        variables: {
          obj: {
            id: fields.id ? fields.id : 0,
            userid: getUserInfo().id,
            heightinfeet: fields.Foot ? +fields.Foot : null,
            heightininches: fields.Inches ? +fields.Inches : null,
            heightincentimeter: height ? +height : null,
            weight: finalData.weight ? +finalData.weight : null,
            weightinkilogram: finalData.weightinkilogram
              ? +finalData.weightinkilogram
              : null,
          },
        },
        nextScreen: routeNextPage,
      });
    } else {
      messagePopup("", "Please enter your physical measurements", "error");
    }
  };

  return (
    <div>
      <div className="onBoardBaseballSection">
        <div className="baseBallBlock">
          <BackdropLoader
            open={
              props.footList.loading ||
              props.inchList.loading ||
              props.onboarding.loading
            }
          />
          <div className="stepper">
            <Stepper
              stepperName="physicalMeasurements"
              stepperData={stepperData}
            />
          </div>
          <div className="formSection">
            <h1 className="heading">Physical Measurements </h1>
            <div className="heightFieldBox">
              <label>Height</label>
              <div className="flexBox">
                <div className="fieldBox">
                  <select
                    id="Foot"
                    value={fields.Foot || ""}
                    onChange={(e) => handleChange("Foot", e)}
                    label="Select"
                  >
                    <option value="">Select</option>
                    {props.footList.data &&
                      props.footList.data.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.heightvalue}
                        </option>
                      ))}
                  </select>
                  <span>ft</span>
                </div>
                <div className="fieldBox">
                  <select
                    id="Inches"
                    value={fields.Inches || ""}
                    onChange={(e) => handleChange("Inches", e)}
                    label="Select"
                  >
                    <option value="">Select</option>

                    {props.inchList.data &&
                      props.inchList.data.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.inchvalue}
                        </option>
                      ))}
                  </select>
                  <span>in</span>
                </div>
              </div>
            </div>
            <div className="weightFieldBox">
              <label>Weight</label>
              <div className="fieldBox">
                <TextField
                  variant="outlined"
                  id="weight"
                  type="number"
                  value={fields.weight || ""}
                  disabled={fields?.PreferNotToAnswer || false}
                  fullWidth
                  onChange={(e) => {
                    handleChange("weight", e);
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseFloat(e.target.value))
                      .toString()
                      .slice(0, 5);
                  }}
                  onBlur={(e) => {
                    handleChange("weight", e);
                  }}
                />
                <span>lbs</span>
              </div>
            </div>

            <p className="prefer">
              <span>
                <input
                  name="PreferNotToAnswer"
                  id="preferNa"
                  checked={fields.PreferNotToAnswer || false}
                  onChange={(e) =>
                    handleChange("PreferNotToAnswer", {
                      target: { value: e.target.checked },
                    })
                  }
                  type="checkbox"
                />
                <label>Prefer not to answer </label>
              </span>
            </p>
            <p className="info">
              All info can be edited in your profile at any time.
            </p>
            <p className="skip">
              Don't know?{" "}
              <span className="sikpBtnBox">
                <Link className="skipit-btn" to="#" onClick={handleClickOpen}>
                  Skip it
                </Link>
              </span>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link
              to="/onBoarding/primaryCoach"
              onClick={(e) => handleSubmit(e)}
            >
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            handleClose()
          }
        }}
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    footList: state.athlete.onBoarding.footList,
    inchList: state.athlete.onBoarding.inchList,
    onboarding: state.athlete.onBoarding.onboarding,
    athleteHeightWeight: state.athlete.onBoarding.athleteHeightWeight,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    inchListStart: (data) => dispatch(inchListStart(data)),
    footListStart: (data) => dispatch(footListStart(data)),
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
    heigthWeightsaveStart: (data) => dispatch(heigthWeightsaveStart(data)),
    athleteHeightWeightStart: (data) =>
      dispatch(athleteHeightWeightStart(data)),
    athleteHeightWeightSaveStart: (data) =>
      dispatch(athleteHeightWeightSaveStart(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhysicalMeasurements);
