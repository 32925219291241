import React from "react";
import { Link } from "react-router-dom";
import { getUserInfo } from "../../../../services/authService";

const stepperSvg = {
  batthrow: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
              .a {
                fill: #1cb79e;
              }
              .b {
                fill: url(#a);
              }
              `,
          }}
        />
        <linearGradient
          id="a"
          x1="0.5"
          y1="1"
          x2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.997" stopColor="#f0fff4" />
          <stop offset="1" stopColor="#f0fff4" />
        </linearGradient>
      </defs>
      <g transform="translate(0 0)">
        <g transform="translate(0.022 0.022)">
          <path
            className="a"
            d="M32.742,16.626l1.475-.782A17.1,17.1,0,0,0,18.733.34l-.938,1.3L16.7.3A17.016,17.016,0,0,0,.3,16.7l1.387.8L.341,18.732A17.076,17.076,0,0,0,15.825,34.216l1.061-1.509.971,1.549a17.027,17.027,0,0,0,16.4-16.381Z"
            transform="translate(-0.301 -0.3)"
          />
        </g>
        <g transform="translate(0 0)">
          <g transform="translate(0)">
            <path
              className="b"
              d="M33.94,15.566a13.2,13.2,0,0,1-5.226.06l.239-.916a.988.988,0,1,0-1.912-.5l-.259.9a13.1,13.1,0,0,1-2.271-1.076l.538-.757a.995.995,0,1,0-1.634-1.136L22.9,12.9c-.339-.279-.657-.518-.976-.817a12.19,12.19,0,0,1-.8-.976l.737-.518a.995.995,0,1,0-1.136-1.634l-.757.538a13.1,13.1,0,0,1-1.076-2.271l.916-.259a.991.991,0,1,0-.518-1.912l-.917.239A13.2,13.2,0,0,1,18.454.06C17.976.02,17.478,0,17,0a5.6,5.6,0,0,0-.578.02,15.265,15.265,0,0,0,.04,5.784l-1.036.279A.991.991,0,1,0,15.944,8l1.036-.259a15.2,15.2,0,0,0,1.355,2.889l-.877.618a.995.995,0,0,0,1.136,1.634l.9-.618a11.913,11.913,0,0,0,1.036,1.215,15.343,15.343,0,0,0,1.2,1.076l-.6.857a.977.977,0,0,0,.259,1.375.988.988,0,0,0,1.375-.239l.6-.857a16.826,16.826,0,0,0,2.909,1.375l-.259,1a.991.991,0,1,0,1.912.518l.279-1a13.884,13.884,0,0,0,2.869.3A15.882,15.882,0,0,0,33.98,17.6c.02-.2.02-.4.02-.6q0-.717-.06-1.434ZM18.056,26l-1.036.279a14.3,14.3,0,0,0-1.355-2.909l.877-.618a.973.973,0,0,0,.239-1.375,1,1,0,0,0-1.375-.259l-.877.618a15.366,15.366,0,0,0-1.056-1.215,15.355,15.355,0,0,0-1.215-1.056l.618-.877a.995.995,0,0,0-1.634-1.136l-.618.877A14.305,14.305,0,0,0,7.716,16.98L8,15.944a.991.991,0,1,0-1.912-.518L5.8,16.462a15.265,15.265,0,0,0-5.784-.04A5.6,5.6,0,0,0,0,17c0,.478.02.976.06,1.454a13.193,13.193,0,0,1,5.226-.06l-.239.9a.989.989,0,0,0,.7,1.215.978.978,0,0,0,1.215-.717l.259-.9a13.1,13.1,0,0,1,2.271,1.076l-.538.757a1.011,1.011,0,0,0,.239,1.395.994.994,0,0,0,1.395-.259l.538-.757c.319.259.637.518.956.817.3.3.538.638.817.956l-.757.538a.995.995,0,1,0,1.136,1.634l.757-.538a13.1,13.1,0,0,1,1.076,2.271l-.916.259a.991.991,0,1,0,.518,1.912l.9-.239a13.193,13.193,0,0,1-.06,5.226c.478.04.976.06,1.454.06a5.6,5.6,0,0,0,.578-.02,15.262,15.262,0,0,0-.04-5.784l1.036-.279a1,1,0,0,0,.7-1.215.98.98,0,0,0-1.215-.7Z"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  positions: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
              .a {
                fill: #1cb79e;
              }
              .b {
                fill: url(#a);
              }
              `,
          }}
        />
        <linearGradient
          id="a"
          x1="0.5"
          y1="1"
          x2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.997" stopColor="#f0fff4" />
          <stop offset="1" stopColor="#f0fff4" />
        </linearGradient>
      </defs>
      <g transform="translate(0 0)">
        <g transform="translate(0.022 0.022)">
          <path
            className="a"
            d="M32.742,16.626l1.475-.782A17.1,17.1,0,0,0,18.733.34l-.938,1.3L16.7.3A17.016,17.016,0,0,0,.3,16.7l1.387.8L.341,18.732A17.076,17.076,0,0,0,15.825,34.216l1.061-1.509.971,1.549a17.027,17.027,0,0,0,16.4-16.381Z"
            transform="translate(-0.301 -0.3)"
          />
        </g>
        <g transform="translate(0 0)">
          <g transform="translate(0)">
            <path
              className="b"
              d="M33.94,15.566a13.2,13.2,0,0,1-5.226.06l.239-.916a.988.988,0,1,0-1.912-.5l-.259.9a13.1,13.1,0,0,1-2.271-1.076l.538-.757a.995.995,0,1,0-1.634-1.136L22.9,12.9c-.339-.279-.657-.518-.976-.817a12.19,12.19,0,0,1-.8-.976l.737-.518a.995.995,0,1,0-1.136-1.634l-.757.538a13.1,13.1,0,0,1-1.076-2.271l.916-.259a.991.991,0,1,0-.518-1.912l-.917.239A13.2,13.2,0,0,1,18.454.06C17.976.02,17.478,0,17,0a5.6,5.6,0,0,0-.578.02,15.265,15.265,0,0,0,.04,5.784l-1.036.279A.991.991,0,1,0,15.944,8l1.036-.259a15.2,15.2,0,0,0,1.355,2.889l-.877.618a.995.995,0,0,0,1.136,1.634l.9-.618a11.913,11.913,0,0,0,1.036,1.215,15.343,15.343,0,0,0,1.2,1.076l-.6.857a.977.977,0,0,0,.259,1.375.988.988,0,0,0,1.375-.239l.6-.857a16.826,16.826,0,0,0,2.909,1.375l-.259,1a.991.991,0,1,0,1.912.518l.279-1a13.884,13.884,0,0,0,2.869.3A15.882,15.882,0,0,0,33.98,17.6c.02-.2.02-.4.02-.6q0-.717-.06-1.434ZM18.056,26l-1.036.279a14.3,14.3,0,0,0-1.355-2.909l.877-.618a.973.973,0,0,0,.239-1.375,1,1,0,0,0-1.375-.259l-.877.618a15.366,15.366,0,0,0-1.056-1.215,15.355,15.355,0,0,0-1.215-1.056l.618-.877a.995.995,0,0,0-1.634-1.136l-.618.877A14.305,14.305,0,0,0,7.716,16.98L8,15.944a.991.991,0,1,0-1.912-.518L5.8,16.462a15.265,15.265,0,0,0-5.784-.04A5.6,5.6,0,0,0,0,17c0,.478.02.976.06,1.454a13.193,13.193,0,0,1,5.226-.06l-.239.9a.989.989,0,0,0,.7,1.215.978.978,0,0,0,1.215-.717l.259-.9a13.1,13.1,0,0,1,2.271,1.076l-.538.757a1.011,1.011,0,0,0,.239,1.395.994.994,0,0,0,1.395-.259l.538-.757c.319.259.637.518.956.817.3.3.538.638.817.956l-.757.538a.995.995,0,1,0,1.136,1.634l.757-.538a13.1,13.1,0,0,1,1.076,2.271l-.916.259a.991.991,0,1,0,.518,1.912l.9-.239a13.193,13.193,0,0,1-.06,5.226c.478.04.976.06,1.454.06a5.6,5.6,0,0,0,.578-.02,15.262,15.262,0,0,0-.04-5.784l1.036-.279a1,1,0,0,0,.7-1.215.98.98,0,0,0-1.215-.7Z"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  academics: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="21.001"
      viewBox="0 0 34 21.001"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a {
            fill: #1cb79e ;
          }
          `,
          }}
        />
      </defs>
      <g transform="translate(0 -6.073)">
        <g transform="translate(0 6.073)">
          <path
            className="a"
            d="M34,21.318a1.271,1.271,0,0,0-.723-1.141V12.309a.242.242,0,0,0,0-.454L17.037,6.129a.961.961,0,0,0-.643,0L.161,11.856a.242.242,0,0,0-.006.454l16.211,6.31a.977.977,0,0,0,.7,0l15.091-5.877v7.434a1.271,1.271,0,0,0,.058,2.319,10.575,10.575,0,0,0-.78,3.3,1.282,1.282,0,0,0,2.563,0,10.605,10.605,0,0,0-.78-3.3A1.28,1.28,0,0,0,34,21.318Z"
            transform="translate(0 -6.073)"
          />
          <path
            className="a"
            d="M4.888,14.87c0,1.7,0,3.692,0,4.009,0,3.158,5.247,5.729,11.844,5.89,6.6-.161,11.843-2.729,11.843-5.89,0-.315,0-2.312,0-4.009L17.093,18.921a1.093,1.093,0,0,1-.723,0Z"
            transform="translate(0 -4.696)"
          />
        </g>
      </g>
    </svg>
  ),
  baselevel: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.282"
      height="33.535"
      viewBox="0 0 33.282 33.535"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a {
            fill: #1cb79e ;
          }
          `,
          }}
        />
      </defs>
      <g transform="translate(-15.949 -4)">
        <g transform="translate(28.43 4)">
          <g transform="translate(0)">
            <path
              className="a"
              d="M200.109,0a4.224,4.224,0,0,0-4.16,4.23,4.16,4.16,0,0,0,8.32,0A4.224,4.224,0,0,0,200.109,0Z"
              transform="translate(-195.949)"
            />
          </g>
        </g>
        <g transform="translate(26.35 20.824)">
          <g transform="translate(0)">
            <path
              className="a"
              d="M177.39,271h-10.4a1.04,1.04,0,0,0-1.04,1.04v15.67H178.43V272.04A1.04,1.04,0,0,0,177.39,271Z"
              transform="translate(-165.949 -271)"
            />
          </g>
        </g>
        <g transform="translate(40.91 27.065)">
          <path
            className="a"
            d="M383.229,361h-7.28v10.47h7.28a1.039,1.039,0,0,0,1.04-1.04v-8.39A1.039,1.039,0,0,0,383.229,361Z"
            transform="translate(-375.949 -361)"
          />
        </g>
        <g transform="translate(15.949 24.985)">
          <g transform="translate(0)">
            <path
              className="a"
              d="M16.989,331a1.039,1.039,0,0,0-1.04,1.04v10.47a1.039,1.039,0,0,0,1.04,1.04h7.28V331Z"
              transform="translate(-15.949 -331)"
            />
          </g>
        </g>
        <g transform="translate(26.35 12.39)">
          <path
            className="a"
            d="M176.807,121a6.165,6.165,0,0,1-9.235,0,6.193,6.193,0,0,0-1.623,4.16v1.04a1.039,1.039,0,0,0,1.04,1.04h10.4a1.039,1.039,0,0,0,1.04-1.04v-1.04h0A6.193,6.193,0,0,0,176.807,121Z"
            transform="translate(-165.949 -121)"
          />
        </g>
      </g>
    </svg>
  ),
  firstVarsity: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.282"
      height="33.535"
      viewBox="0 0 33.282 33.535"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a {
            fill: #1cb79e ;
          }
          `,
          }}
        />
      </defs>
      <g transform="translate(-15.949 -4)">
        <g transform="translate(28.43 4)">
          <g transform="translate(0)">
            <path
              className="a"
              d="M200.109,0a4.224,4.224,0,0,0-4.16,4.23,4.16,4.16,0,0,0,8.32,0A4.224,4.224,0,0,0,200.109,0Z"
              transform="translate(-195.949)"
            />
          </g>
        </g>
        <g transform="translate(26.35 20.824)">
          <g transform="translate(0)">
            <path
              className="a"
              d="M177.39,271h-10.4a1.04,1.04,0,0,0-1.04,1.04v15.67H178.43V272.04A1.04,1.04,0,0,0,177.39,271Z"
              transform="translate(-165.949 -271)"
            />
          </g>
        </g>
        <g transform="translate(40.91 27.065)">
          <path
            className="a"
            d="M383.229,361h-7.28v10.47h7.28a1.039,1.039,0,0,0,1.04-1.04v-8.39A1.039,1.039,0,0,0,383.229,361Z"
            transform="translate(-375.949 -361)"
          />
        </g>
        <g transform="translate(15.949 24.985)">
          <g transform="translate(0)">
            <path
              className="a"
              d="M16.989,331a1.039,1.039,0,0,0-1.04,1.04v10.47a1.039,1.039,0,0,0,1.04,1.04h7.28V331Z"
              transform="translate(-15.949 -331)"
            />
          </g>
        </g>
        <g transform="translate(26.35 12.39)">
          <path
            className="a"
            d="M176.807,121a6.165,6.165,0,0,1-9.235,0,6.193,6.193,0,0,0-1.623,4.16v1.04a1.039,1.039,0,0,0,1.04,1.04h10.4a1.039,1.039,0,0,0,1.04-1.04v-1.04h0A6.193,6.193,0,0,0,176.807,121Z"
            transform="translate(-165.949 -121)"
          />
        </g>
      </g>
    </svg>
  ),
  dob: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.658"
      height="33.786"
      viewBox="0 0 27.658 33.786"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a {
            fill: #1cb79e !important;
          }
          
          `,
          }}
        />
      </defs>

      <g transform="translate(-42.56)">
        <g transform="translate(42.56)">
          <g transform="translate(0)">
            <path
              className="a"
              d="M194.964,9.214A3.073,3.073,0,0,0,197.6,4.561L194.964,0l-2.634,4.561a3.073,3.073,0,0,0,2.634,4.653Z"
              transform="translate(-181.143)"
            />
            <path
              className="a"
              d="M65.6,152.4H57.917v-3.071H54.846V152.4H47.167a4.611,4.611,0,0,0-4.607,4.607v2.365A3.008,3.008,0,0,0,47.7,161.5l3.286-3.279,3.279,3.271a3.08,3.08,0,0,0,4.254,0l3.286-3.271,3.279,3.271a3.008,3.008,0,0,0,5.137-2.127v-2.357A4.634,4.634,0,0,0,65.6,152.4Z"
              transform="translate(-42.56 -138.583)"
            />
            <path
              className="a"
              d="M63.438,319.731h0l-1.651-1.651-1.659,1.651a5.442,5.442,0,0,1-7.51,0l-1.651-1.651-1.659,1.651a5.206,5.206,0,0,1-3.739,1.559,5.3,5.3,0,0,1-3.01-.944v7.087a1.54,1.54,0,0,0,1.536,1.536H68.667a1.54,1.54,0,0,0,1.536-1.536v-7.087a5.287,5.287,0,0,1-6.765-.614Z"
              transform="translate(-42.56 -295.182)"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  personalRecords: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.335"
      height="30.583"
      viewBox="0 0 30.335 30.583"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a {
            fill: #1cb79e;
          }
          `,
          }}
        />
      </defs>
      <g transform="translate(-440.415 -127.565)">
        <g transform="translate(440.415 127.565)">
          <g transform="translate(0 10.274)">
            <g transform="translate(0 0)">
              <path
                className="a"
                d="M384.353,106.667h5.673a.552.552,0,0,1,.567.534v19.22a.552.552,0,0,1-.567.534h-5.673a.552.552,0,0,1-.567-.534V107.2A.552.552,0,0,1,384.353,106.667Z"
                transform="translate(-383.786 -106.667)"
              />
            </g>
          </g>
          <g transform="translate(23.527 18.464)">
            <path
              className="a"
              d="M384.353,106.667h5.673c.313,0,.567.143.567.319v11.481c0,.176-.254.319-.567.319h-5.673c-.313,0-.567-.143-.567-.319V106.986C383.786,106.81,384.04,106.667,384.353,106.667Z"
              transform="translate(-383.786 -106.667)"
            />
          </g>
          <g transform="translate(11.764 0)">
            <path
              className="a"
              d="M384.353,106.667h5.673c.313,0,.567.36.567.8v28.954c0,.444-.254.8-.567.8h-5.673c-.313,0-.567-.36-.567-.8V107.471C383.786,107.027,384.04,106.667,384.353,106.667Z"
              transform="translate(-383.786 -106.667)"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  physicalMeasurements: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.343"
      height="34.005"
      viewBox="0 0 23.343 34.005"
    >
      {" "}
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a {
            fill: #1cb79e ;
          }
          `,
          }}
        />
      </defs>
      <g transform="translate(-12302.999 -12828.729)">
        <path
          className="a"
          d="M1.134,35.005H5.1a1.134,1.134,0,0,0,1.134-1.134V2.134A1.134,1.134,0,0,0,5.1,1H1.134A1.134,1.134,0,0,0,0,2.134V33.872A1.134,1.134,0,0,0,1.134,35.005ZM5.1,2.134V33.872H1.134v-1.7h1.7a.567.567,0,1,0,0-1.134h-1.7V29.9H2.267a.567.567,0,0,0,0-1.134H1.134V27.637h1.7a.567.567,0,1,0,0-1.134h-1.7V25.37H2.267a.567.567,0,1,0,0-1.134H1.134V23.1h1.7a.567.567,0,1,0,0-1.134h-1.7V20.836H2.267a.567.567,0,1,0,0-1.134H1.134V18.569h1.7a.567.567,0,1,0,0-1.133h-1.7V16.3H2.267a.567.567,0,0,0,0-1.134H1.134V14.035h1.7a.567.567,0,1,0,0-1.134h-1.7V11.768H2.267a.567.567,0,0,0,0-1.134H1.134V9.5h1.7a.567.567,0,0,0,0-1.134h-1.7V7.234H2.267a.567.567,0,1,0,0-1.134H1.134V4.967h1.7a.567.567,0,1,0,0-1.134h-1.7v-1.7Z"
          transform="translate(12302.999 12827.729)"
        />
        <g transform="translate(12311.974 12828.729)">
          <g transform="translate(0 0)">
            <circle
              className="a"
              cx="2.862"
              cy="2.862"
              r="2.862"
              transform="translate(4.267)"
            />
            <path
              className="a"
              d="M23.585,11.1a5.21,5.21,0,0,0-2.189.027c-4.758.558-6.3,6.065-5.867,10.264.189,1.815,3.051,1.835,2.86,0a10.9,10.9,0,0,1,.723-5.481V21.9c0,.07.007.137.01.2,0,.032-.01.06-.01.093,0,4.749-.008,9.5-.2,14.242-.087,2.109,3.184,2.1,3.27,0,.154-3.719.192-7.44.2-11.162a3.785,3.785,0,0,0,.465,0c.01,3.722.047,7.443.2,11.161.087,2.1,3.358,2.109,3.27,0-.2-4.745-.2-9.493-.2-14.242a1.792,1.792,0,0,0-.05-.41c0-2.062-.083-4.126-.059-6.186a10.375,10.375,0,0,1,.894,5.794c-.192,1.833,2.671,1.815,2.86,0C30.2,17.107,28.584,11.457,23.585,11.1Z"
              transform="translate(-15.459 -4.015)"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  primaryCoach: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.25"
      height="31.25"
      viewBox="0 0 31.25 31.25"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a {
            fill: #1cb79e;
          }
          `,
          }}
        />
      </defs>
      <path
        className="a"
        d="M186.858,375.015a4.576,4.576,0,0,0,8.44,0,9.615,9.615,0,0,1-8.44,0Z"
        transform="translate(-175.453 -352.126)"
      />
      <path
        className="a"
        d="M25.845,368.736c-1.412-.231-2.713-.4-3.962-.553a6.4,6.4,0,0,1-12.517,0c-1.249.148-2.55.322-3.962.554A6.423,6.423,0,0,0,0,375.056v.989a.915.915,0,0,0,.916.916H30.335a.915.915,0,0,0,.916-.916v-.989a6.425,6.425,0,0,0-5.4-6.32Z"
        transform="translate(0 -345.711)"
      />
      <path
        className="a"
        d="M126.645,6.566a8.7,8.7,0,0,1,8.143,0,6.733,6.733,0,0,0,4.072.781A8.355,8.355,0,0,0,131.632,0V2.808a.916.916,0,1,1-1.831,0V0a8.355,8.355,0,0,0-7.228,7.351A6.728,6.728,0,0,0,126.645,6.566Z"
        transform="translate(-115.092)"
      />
      <circle
        className="a"
        cx="0.916"
        cy="0.916"
        r="0.916"
        transform="translate(10.617 16.541)"
      />
      <g transform="translate(5.554 7.385)">
        <path
          className="a"
          d="M109.311,122.988a8.02,8.02,0,0,1-4.987-1.169,6.869,6.869,0,0,0-6.505,0,7.825,7.825,0,0,1-4.034,1.025c-.295,0-.613.168-.953.144a2.747,2.747,0,0,0,.036,5.192,8.176,8.176,0,0,0,1.824,4.407,2.743,2.743,0,0,1,2.287-4.262h4.083a.916.916,0,1,1,0,1.831H99.556a2.715,2.715,0,0,1,.169.916,2.75,2.75,0,0,1-2.747,2.747,2.721,2.721,0,0,1-1.771-.665,8.207,8.207,0,0,0,14.067-4.973,2.747,2.747,0,0,0,.036-5.192Zm-10.986,2.59a.916.916,0,1,1,.916-.916A.916.916,0,0,1,98.324,125.578Zm5.493,0a.916.916,0,1,1,.916-.916A.916.916,0,0,1,103.817,125.578Z"
          transform="translate(-91 -121)"
        />
      </g>
    </svg>
  ),
  profilePhoto: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.582"
      height="23.749"
      viewBox="0 0 28.582 23.749"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a,
          .b {
            fill: #1cb79e;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
          }
          .a {
            stroke: #1cb79e;
          }
          .b {
            stroke: #fff;
          }
          `,
          }}
        />
      </defs>
      <g transform="translate(-0.5 -3.5)">
        <path
          className="a"
          d="M28.082,23.832a2.417,2.417,0,0,1-2.417,2.417H3.917A2.417,2.417,0,0,1,1.5,23.832V10.541A2.417,2.417,0,0,1,3.917,8.125H8.75L11.166,4.5h7.25l2.417,3.625h4.833a2.417,2.417,0,0,1,2.417,2.417Z"
          transform="translate(0)"
        />
        <path
          className="b"
          d="M21.666,18.333A4.833,4.833,0,1,1,16.833,13.5,4.833,4.833,0,0,1,21.666,18.333Z"
          transform="translate(-2.042 -1.75)"
        />
      </g>
    </svg>
  ),
  recruiting: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.515"
      height="30.515"
      viewBox="0 0 30.515 30.515"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a{fill:#1cb79e;}
          `,
          }}
        />
      </defs>
      <g transform="translate(-4 -4)">
        <path
          className="a"
          d="M10.466,45.554a.545.545,0,1,0-.771.77l3.853,3.853a.545.545,0,1,0,.771-.771Z"
          transform="translate(-2.519 -18.838)"
        />
        <path
          className="a"
          d="M6.791,54.938l2.7-2.7L7.176,49.929l-2.7,2.7a1.635,1.635,0,1,0,2.312,2.312Z"
          transform="translate(0 -20.902)"
        />
        <path
          className="a"
          d="M21.381,8.952a10.185,10.185,0,0,0-1.86.631l.451.992A9.308,9.308,0,0,1,30.36,12.459a9.253,9.253,0,1,1-11.416-1.334l-.573-.927a10.391,10.391,0,0,0-1.882,1.491,10.331,10.331,0,0,0-1.057,13.387l-1.64,1.64L16.1,29.027l1.642-1.641a10.35,10.35,0,0,0,3.637,1.679v5.45H38.819V4H21.381Zm15.8.5h-4.9V8.359h4.9ZM24.651,5.635H37.184v1.09H24.651Z"
          transform="translate(-4.303)"
        />
        <path
          className="a"
          d="M29.725,32.4a3.273,3.273,0,0,0-3.269,3.269h6.539A3.273,3.273,0,0,0,29.725,32.4Z"
          transform="translate(-10.22 -12.925)"
        />
        <circle
          className="a"
          cx="1.635"
          cy="1.635"
          r="1.635"
          transform="translate(17.871 15.117)"
        />
        <path
          className="a"
          d="M25.63,32.749a8.174,8.174,0,1,0-8.174-8.174A8.183,8.183,0,0,0,25.63,32.749ZM21.27,28.39a4.362,4.362,0,0,1,2.558-3.965,2.725,2.725,0,1,1,3.6,0,4.362,4.362,0,0,1,2.558,3.965v.545a.545.545,0,0,1-.545.545H21.815a.545.545,0,0,1-.545-.545Z"
          transform="translate(-6.124 -5.644)"
        />
      </g>
    </svg>
  ),
  recruitingProcess: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.983"
      height="30.599"
      viewBox="0 0 33.983 30.599"
    >
      {" "}
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a {
            fill: #1cb79e ;
          }
          .b {
            fill: #fff;
          }
          `,
          }}
        />
      </defs>
      <g transform="translate(-15353.978 -12286.451)">
        <g transform="translate(15353.978 12286.451)">
          <path
            className="a"
            d="M74.542,69.148H45.4a2.419,2.419,0,0,1-2.419-2.419V47.87A2.419,2.419,0,0,1,45.4,45.451H74.542a2.419,2.419,0,0,1,2.419,2.419V66.729A2.419,2.419,0,0,1,74.542,69.148Z"
            transform="translate(-42.978 -45.451)"
          />
          <path
            className="a"
            d="M178.4,236.649l-5.125-6.9,6.829-9.188,6.829,9.188-5.125,6.9A2.123,2.123,0,0,1,178.4,236.649Z"
            transform="translate(-163.115 -206.907)"
          />
        </g>
        <g transform="translate(15360.216 12294.795)">
          <path
            className="b"
            d="M90.809,143.867H88.09v3.3c0,.251-.284.382-.579.382s-.579-.131-.579-.382v-7.23c0-.262.295-.371.579-.371s.579.109.579.371v3.014h2.72v-3.014c0-.262.295-.371.579-.371s.579.109.579.371v7.23c0,.251-.284.382-.579.382s-.579-.131-.579-.382Z"
            transform="translate(-86.932 -139.564)"
          />
          <path
            className="b"
            d="M191.983,143.091h1.682a.418.418,0,0,1,.382.47.409.409,0,0,1-.382.448h-1.682v2.512h3.244c.24,0,.382.24.382.524,0,.24-.12.5-.382.5h-3.888c-.251,0-.513-.131-.513-.382v-7.219c0-.251.262-.382.513-.382h3.888c.262,0,.382.262.382.5,0,.284-.142.524-.382.524h-3.244Z"
            transform="translate(-184.349 -139.563)"
          />
          <path
            className="b"
            d="M282.968,147.548c-.251,0-.513-.131-.513-.382v-7.23c0-.262.295-.371.579-.371s.579.109.579.371v6.586h2.84c.229,0,.349.251.349.513s-.12.513-.349.513Z"
            transform="translate(-270.267 -139.564)"
          />
          <path
            className="b"
            d="M366.025,139.935a.4.4,0,0,1,.448-.371h2.13a2.154,2.154,0,0,1,2.392,2.4v.087a2.2,2.2,0,0,1-2.457,2.414h-1.354v2.7c0,.251-.284.382-.579.382s-.579-.131-.579-.382v-7.23Zm1.158.655v2.96h1.354c.808,0,1.3-.459,1.3-1.42V142c0-.961-.492-1.409-1.3-1.409Z"
            transform="translate(-348.627 -139.564)"
          />
        </g>
      </g>
    </svg>
  ),
  schoolInformation: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.152"
      height="30.152"
      viewBox="0 0 30.152 30.152"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .a
         {fill:#1cb79e;} 
          `,
          }}
        />
      </defs>
      <path
        className="a"
        d="M29.4,27.137h-.754V10.553a1.424,1.424,0,0,0-1.508-1.508V7.538A1.424,1.424,0,0,0,25.629,6.03H21.86L15.076,0,7.538,6.03H4.523A1.424,1.424,0,0,0,3.015,7.538V9.046a1.424,1.424,0,0,0-1.508,1.508V27.137H.754A.712.712,0,0,0,0,27.891V29.4a.712.712,0,0,0,.754.754H29.4a.712.712,0,0,0,.754-.754V27.891A.712.712,0,0,0,29.4,27.137ZM14.624,3.015a1.424,1.424,0,0,1,1.508,1.508A1.424,1.424,0,0,1,14.624,6.03a1.511,1.511,0,0,1-1.508-1.508A1.424,1.424,0,0,1,14.624,3.015ZM9.046,24.122H4.523V19.6H9.046Zm0-7.538H4.523V12.061H9.046Zm9.046,10.553h-6.03V22.614a3.015,3.015,0,0,1,6.03,0Zm0-10.553h-6.03V12.061h6.03Zm7.538,7.538H21.107V19.6h4.523Zm0-7.538H21.107V12.061h4.523Z"
      />
    </svg>
  ),
  twitter: (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30.051" viewBox="0 0 30 30.051">
      <g id="bxl-instagram-alt" transform="translate(-4.495 -4.47)">
        <path id="Path_2113" data-name="Path 2113" d="M34.4,13.341a10.878,10.878,0,0,0-.7-3.693,7.681,7.681,0,0,0-4.387-4.387,11.006,11.006,0,0,0-3.643-.7c-1.6-.071-2.11-.093-6.18-.093s-4.591,0-6.181.093a11.006,11.006,0,0,0-3.639.7A7.676,7.676,0,0,0,5.285,9.648a10.92,10.92,0,0,0-.7,3.639c-.071,1.6-.093,2.112-.093,6.181s0,4.587.093,6.181a10.981,10.981,0,0,0,.7,3.643,7.681,7.681,0,0,0,4.388,4.385,10.97,10.97,0,0,0,3.639.75c1.6.071,2.112.093,6.181.093s4.591,0,6.181-.093a10.98,10.98,0,0,0,3.643-.7A7.689,7.689,0,0,0,33.7,29.345a10.943,10.943,0,0,0,.7-3.643c.071-1.6.093-2.11.093-6.181s0-4.584-.1-6.18ZM19.486,27.165a7.7,7.7,0,1,1,7.7-7.7,7.7,7.7,0,0,1-7.7,7.7ZM27.5,13.271a1.793,1.793,0,1,1,1.269-.526,1.795,1.795,0,0,1-1.269.526Z" transform="translate(0 0)" fill="#1cb79e" />
        <path id="Path_2114" data-name="Path 2114" d="M23.729,18.586a5.121,5.121,0,1,1-5.121-5.123,5.121,5.121,0,0,1,5.121,5.123Z" transform="translate(0.878 0.877)" fill="#1cb79e" />
      </g>
    </svg>
  ),
};

export default function Stepper(props) {
  const [isBatThrow, setIsBatThrow] = React.useState(false);
  React.useEffect(() => {
    const { Athlete_Sports, gender } = getUserInfo()
      ? getUserInfo()
      : { Athlete_Sports: null, gender: null };
    if (
      (gender === "Male" && Athlete_Sports === 1) ||
      (gender === "Female" && Athlete_Sports === 4)
    ) {
      setIsBatThrow(true);
    }
  }, []);

  return (
    <>
      <ul>
        {props.stepperData &&
          props.stepperData.slice(0, 6).map((item, index) => (
            <li className={item.className} key={index}>
              <Link to={item.link ? item.link : "#"}>
                <span>
                  {item.className === "active"
                    ? stepperSvg[props.stepperName]
                    : null}
                </span>
              </Link>
            </li>
          ))}

        {isBatThrow && (
          <li
            className={
              props.stepperName === "batthrow"
                ? "active"
                : props.stepperData.filter((item) => item.className).length > 6//earlier it was 5
                  ? "passed"
                  : ""
            } //"passed"
          >
            <Link
              to={
                props.stepperData.filter((item) => item.className).length > 6//earlier it was 5
                  ? "/onboarding/batthrow"
                  : "#"
              }
            >
              <span>
                {props.stepperName === "batthrow"
                  ? stepperSvg["batthrow"]
                  : null}
              </span>
            </Link>
          </li>
        )}

        {props.stepperData &&
          props.stepperData.slice(6).map((item, index) => (//earlier it was 5
            <li className={item.className} key={index}>
              <Link to={item.link ? item.link : "#"}>
                <span>
                  {item.className === "active"
                    ? stepperSvg[props.stepperName]
                    : null}
                </span>
              </Link>
            </li>
          ))}
      </ul>
    </>
  );
}
