import { useHistory } from "react-router-dom";
import editProfilePopUp from "../../../../../utils/editProfilePopUp";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
const ScreenSwitch = (props) => {
  let history = useHistory();
  const onPrevNextClick = (e, isnext) => {
    e.preventDefault();
    if (!props.isSaved) {
      editProfilePopUp(
        "Warning",
        "Changes you made may not be saved.",
        "warning"
      ).then((data) => {
        if (data && !isnext) {
         props.prevLink();
        }
        if (data && isnext) {
          props.nextLink();
        }
      });
    } else {
      if (!isnext) {
        props.prevLink();
      }
      if (isnext) {
        props.nextLink();
      }
    }
  };
  return (
    <>
      {props.prevLink ? (
        <button
          onClick={(e) => onPrevNextClick(e, false)}
          disabled={props.prevLinkDisabled}
        >
          <ArrowBackIosRoundedIcon fontSize="small" />
          Previous
        </button>
      ) : (
        ""
      )}{" "}
      {props.nextLink ? (
        <button
          onClick={(e) => onPrevNextClick(e, true)}
          disabled={props.nextLinkDisabled}
        >
          Next <ArrowForwardIosRoundedIcon fontSize="small" />
        </button>
      ) : (
        ""
      )}
    </>
  );
};

export default ScreenSwitch;