import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  COLLEGE_ACTIVITY_LIST_START: "COLLEGE_ACTIVITY_LIST_START",
  COLLEGE_ACTIVITY_SUCCESS: "COLLEGE_ACTIVITY_SUCCESS",
  COLLEGE_ACTIVITY_FAILURE: "COLLEGE_ACTIVITY_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const collegeActivityListStart = (data) => ({
  type: actionType.COLLEGE_ACTIVITY_LIST_START,
  payload: data,
});

export const collegeActivitySuccess = (data) => ({  
    type: actionType.COLLEGE_ACTIVITY_SUCCESS,
    payload: data,
});
  
export const collegeActivityFailure = (data) => ({
    type: actionType.COLLEGE_ACTIVITY_FAILURE,
    payload: data
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function collegeActivityReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.COLLEGE_ACTIVITY_LIST_START:
      return { ...state, loading: true };
    
    case actionType.COLLEGE_ACTIVITY_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.COLLEGE_ACTIVITY_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/


function* getCollegeActivitySaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true);
        if(response.status === 200){
            const allCollegeActivity = response.data?.data?.allCollegecoachactivities;

            yield put(collegeActivitySuccess(allCollegeActivity));
        } else {
          messagePopup('', 'College Activity Data Failed', 'error');
          yield put(collegeActivityFailure('Failed, Error occured'))
        }
       } catch (error) {
         messagePopup('', 'College Activity Data Failed', 'error');
         yield put(collegeActivityFailure('Failed, Error occured'))
       }
}

export function* watchCollegeActivitySaga() {
  yield takeEvery(actionType.COLLEGE_ACTIVITY_LIST_START, getCollegeActivitySaga);
}

