import { put, takeEvery } from "redux-saga/effects";
import {
  removeSignupData,
} from "../../../services/authService";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";


// ========================================== Action Type =====================================================
export const actionType = {
  CLAIM_PROFILE_START: "CLAIM_PROFILE_START",
  CLAIM_PROFILE_SUCCESS: "CLAIM_PROFILE_SUCCESS",
  CLAIM_PROFILE_FAILED: "CLAIM_PROFILE_FAILED",
  CLAIM_PROFILE_STATE: "CLAIM_PROFILE_STATE",
};

// ============================================ Actions ========================================================

export const claimProfileStart = (data) => ({
  type: actionType.CLAIM_PROFILE_START,
  payload: data,
});

export const claimProfileState = () => ({
  type: actionType.CLAIM_PROFILE_STATE,
});

export const claimProfileSuccess = (data) => ({
  type: actionType.CLAIM_PROFILE_SUCCESS,
  payload: data,
});

export const claimProfileFailed = (data) => ({
  type: actionType.CLAIM_PROFILE_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  data: null,
  error: null,
};

export default function claimProfileReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.CLAIM_PROFILE_STATE:
      return {
        ...state,
        data: null,
        error: null,
      };
    case actionType.CLAIM_PROFILE_START:
      return {
        ...state,
      };
    case actionType.CLAIM_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case actionType.CLAIM_PROFILE_FAILED:
      return {
        ...state,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}


// ===================================================== side effect =============================================
export function* claimProfileSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Userdata/claimProfile`,
      action.payload.data,
      "post",
      false
    );
    if (response && response.status === 200) {
     
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
       
        messagePopup(
          "",
          "Passcode has been sent to registered email id/ mobile number",
          "success"
        );
        yield put(claimProfileSuccess(response));
        action.payload.handleOtpModal();
      
        removeSignupData();
      } else if (response.data.response.status === 201) {
        messagePopup("", "Please enter the correct email id", "error");
        yield put(claimProfileSuccess(response));
      }
    } else {
      messagePopup("", "Claimed failed", "error");
      yield put(claimProfileFailed("Claimed failed"));
    }
  } catch (error) {
    messagePopup("", "Claimed failed", "error");
    yield put(claimProfileFailed("Claimed failed"));
  }
}

export function* watchClaimProfileSaga() {
  yield takeEvery(actionType.CLAIM_PROFILE_START, claimProfileSaga);
}
