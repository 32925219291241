import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    AB_IP_LIST_START: " AB_IP_LIST_START",
    AB_IP_SAVE_LIST_START: " AB_IP_SAVE_LIST_START",
    AB_IP_LIST_SUCCESS: " AB_IP_LIST_SUCCESS",
    AB_IP_LIST_FAILED: " AB_IP_LIST_FAILED",
    AB_IP_SAVE_SUCCESS: " AB_IP_SAVE_SUCCESS",
    AB_IP_SAVE_FAILED: " AB_IP_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const apIpListStart = (data) => ({
    type: actionType.AB_IP_LIST_START,
    payload: data,
});
export const apIpSaveListStart = (data) => ({
    type: actionType.AB_IP_SAVE_LIST_START,
    payload: data,
});

export const apIpListSuccess = (data) => ({
    type: actionType.AB_IP_LIST_SUCCESS,
    payload: data,
});

export const apIpListFailed = (data) => ({
    type: actionType.AB_IP_LIST_FAILED,
    payload: data,
});
export const apIpSaveSuccess = (data) => ({
    type: actionType.AB_IP_SAVE_SUCCESS,
    payload: data,
});

export const apIpSaveFailed = (data) => ({
    type: actionType.AB_IP_SAVE_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function apIpListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.AB_IP_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.AB_IP_SAVE_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.AB_IP_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.AB_IP_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        case actionType.AB_IP_SAVE_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        case actionType.AB_IP_SAVE_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* apIpListSaga(action) {
    try {
        const response = yield fetchGraphMethod(
            action.payload.query,
            action.payload.variables,
            true
        );
        if (response && response.status === 200) {
            yield put(apIpListSuccess(response.data.data.allApandibs.Apandibs));
        } else {
            messagePopup("", "Test And Score List failed", "error");
            yield put(apIpListFailed(" Test And Score List failed"));
        }
    } catch (error) {
        messagePopup("", "Test And Score List failed", "error");
        yield put(apIpListFailed("Test And Score List failed"));
    }
}

export function* apIpSaveListSaga(action) {
    try {
        const response = yield fetchGraphMethod(
            action.payload.query,
            action.payload.variables,
            true
        );
        if (response && response.status === 200) {
            yield put(apIpSaveSuccess({ apIpData: response.data.data }));
        } else {
            messagePopup("", "Test And Score List failed", "error");
            yield put(apIpSaveFailed(" Test And Score List failed"));
        }
    } catch (error) {
        messagePopup("", "Test And Score List failed", "error");
        yield put(apIpSaveFailed("Test And Score List failed"));
    }
}


export function* watchApIpListSaga() {
    yield takeEvery(actionType.AB_IP_LIST_START, apIpListSaga);
    yield takeEvery(actionType.AB_IP_SAVE_LIST_START, apIpSaveListSaga);
}
