import React, { useState, useEffect } from 'react';
import TeamLogoPng from "../assets/img/TeamLogo.png";
import { imageDownloadUrl } from '../../../../../../config';
import { allAthletesListStart } from '../../../../../../redux/common/allAthleteData';
// import { allCollegeDetailsListStart } from '../../../../../../redux/common/allCollegeData';
import { connect, useDispatch } from 'react-redux';
import { collegeDetailsQuery, getAllAthletesQuery } from './CollegeDetailsQuery';
import { getUserInfo } from '../../../../../../services/authService';
import { Link } from 'react-router-dom';
import { userProfileApi } from '../../../../../common/userViewCount';
import { fetchGraphMethod, fetchMethod } from '../../../../../../services/httpService';
import {getCamelCase} from "../../../../../common/camelCaseFunc";
import { viewCollegeSaveStart } from "../../../../../../redux/athlete/dashboard/colleges/viewCollege";
import { saveViewCollegeQuery } from "./SearchCollegesQuery";

function SideBarAthleteColleges(props) {
  const [allColleges, setAllColleges] = useState(null);
  const [allAthletes, setAllAthletes] = useState(null);
  const [showMoreAthletes, setShowMoreAthletes] = useState(false);
  const [showMoreColleges, setShowMoreColleges] = useState(false);
  const [collegeList, setCollegeList] = useState(null);
  const [athleteList, setAthleteList] = useState(null);
  const profileStatus = useDispatch()

  useEffect(() => {
    if (allColleges) {
      let data = allColleges;
      if (showMoreColleges == true) {
        setCollegeList(data);
      } else {
        const tempData = [...data];
        setCollegeList(tempData && tempData.slice(0, 3))
      }
    }
  }, [allColleges, showMoreColleges])

  // This effect commented before but used on new logic.
  useEffect(() => {
    if (allAthletes) {
      const data = allAthletes;
      if (showMoreAthletes == true) {
        setAthleteList(data)
      } else {
        const tempData = [...data];
        setAthleteList(tempData && tempData.splice(0, 4))
      }
    }
  }, [allAthletes, showMoreAthletes])

  useEffect(() => {
    //getAllAthletesData('0', '4');
    getAllAthletesData();
    getAllCollegesData();
  }, [])

  const getAllCollegesData = async () => {
    // props.allCollegeDetailsListStart({
    //     query : collegeDetailsQuery(getUserInfo().id)
    // })

    //commented the earlier logic to fetch colleges only with /*
    /* const res = await fetchGraphMethod(
      collegeDetailsQuery(getUserInfo().id),
      { where: { active: 1 } },
      true
    )
    let data = res.data.data.allColleges.Colleges;
    const collegeListingExceptSelectedCollege = data.filter((college) => {
      return college.id != window.location.href.split("/")[6]
    });
    setAllColleges(collegeListingExceptSelectedCollege); */

    const variables = {
      'collegeid': String(+window.location.href.split("/")[5]),//earlier it was 6
      'ViewUserId': getUserInfo() ? getUserInfo().id : 0
    }
    const similarResp = await fetchMethod('/api/Collegecommitments/similarColleges', variables, "POST", true);
    if (similarResp && similarResp?.status == 200) {
      let totalData = similarResp.data.response.data;
      setAllColleges(totalData);
    }
  }
  const getAllAthletesData = async () => {
    const variables = {
      'collegeid': String(+window.location.href.split("/")[5])//earlier it was 6
    }

    const athleteResp = await fetchMethod('/api/Collegecommitments/collegeCommitedStudents', variables, "POST", true);
    if (athleteResp && athleteResp?.status == 200) {
      let totalData = athleteResp.data.response.data;
      setAllAthletes(totalData);
    }

    // props.allAthletesListStart({
    //   query: getAllAthletesQuery,
    //   variables: {
    //     where: {
    //       active: 1,
    //     },
    //     first: 15,
    //     last: 15,
    //   },
    // })
  }
  const handleShowMore = () => {
    //getAllAthletesData('0', '100');
    setShowMoreAthletes(true);
  }
  const handleShowLess = () => {
    //getAllAthletesData('0', '4');
    setShowMoreAthletes(false);
  }
  const handleShowMoreColleges = () => {
    setShowMoreColleges(true);
  }
  const handleShowLessColleges = () => {
    setShowMoreColleges(false);
  }
  const getInitials = (firstname, lastname) => {
    let initials = "";
    if (firstname && firstname[0] && lastname && lastname[0]) {
      initials = firstname[0] + lastname[0]
    }
    return initials
  }

  const handleCollegeClick = (collgId) => {
    if(collgId !== null) {
      props.handleSimilarCollgClick(collgId);
      props.viewCollegeSaveStart({
        query : saveViewCollegeQuery,
        variables : {
          obj : {
            athleteid : getUserInfo() ? getUserInfo().id : null,
            collegeid : collgId,
            collegeviewstatus : 1,
          }
        }
      })
    }
  }

  return (
    <div className="sidebar-box">
      <div className="item-box">
        <h3 className="title">
          Athletes at this College{" "}
          {showMoreAthletes == false ? (
            <span className="more-link" onClick={() => handleShowMore()}>More</span>
          ) : (
            <span className="more-link" onClick={() => handleShowLess()}>Less</span>
          )}
        </h3>
        <ul>
          {athleteList ? athleteList.map((athlete, index) => (
            <li key={index}>
              <div className="pic-box">
                {athlete?.profileImages ? (
                  <img src={imageDownloadUrl + "/" + athlete?.profileImages} alt="" />
                ) : (
                  <div className="username">
                    {getInitials(athlete?.athleteFirstName, athlete?.athleteLastName)}

                  </div>
                )}
              </div>
              <div className="detail-box">
              <h4>
                <Link
                  to={`/publicProfile/1/${+athlete.athleteid}`}
                  target="_blank"
                  onClick={() => {
                    profileStatus({
                      type: "CHANGE_ATHLETE_PROFILE_VIEW_STATE", payload: {
                        id: athlete.athleteid || 0,
                        status: true
                      }
                    });
                    localStorage.setItem("pubViewID", +athlete.athleteid)
                    userProfileApi({ obj: { userrole: "ATHLETES", profileviewedby: getUserInfo()?.id ?? 0, profileviewto: athlete.athleteId || 0 } }, true)
                  }}
                >
                  {`${athlete?.athleteFirstName} ${athlete?.athleteLastName}`}</Link>
                </h4>              
                <p>class of {athlete?.yearValue}</p>
              </div>
            </li>
          )) : null}
        </ul>
      </div>
      <div className="item-box similar">
        <h3 className="title">
          Similar Colleges
          {showMoreColleges == false ? (
            <span className="more-link" onClick={() => handleShowMoreColleges()}>More</span>
          ) : (
            <span className="more-link" onClick={() => handleShowLessColleges()}>Less</span>
          )}
        </h3>
        <ul>
          {collegeList ? collegeList.map((college, index) => (
            <li key={index}>
              <div className="pic-box">
                {college.collegelogo ? (
                  <img
                    src={imageDownloadUrl + "/" + college?.collegelogo}
                    alt=""
                  />
                ) : (
                  <img
                    src={TeamLogoPng}
                  />
                )}
              </div>
              <div className="detail-box">
                <h4>
                  <a href={`/colleges/details/${college?.collegename ? college?.id : null}/${college?.isfav} `}
                  onClick={() => handleCollegeClick(college?.collegename ? college?.id : null)}>
                  {college?.collegename}</a>
                </h4>                
                <p className='info-text'>
                  {college?.conferencename == null ? "N/A" : college?.conferencename}<br />
                  {college ? `${college?.city == null ? " " : getCamelCase(college?.city)}, ${college?.initials == null ? "" : college?.initials}` : " "}<br />
                  {college?.collegedivisionname == null ? "N/A" : college?.collegedivisionname}
                </p>
              </div>
            </li>
          )) : null}
        </ul>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    collegesData: state.common.collegeDetails,
    athleteData: state.common.allAthletes,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    // allCollegeDetailsListStart: (data) => dispatch(allCollegeDetailsListStart(data)),
    allAthletesListStart: (data) => dispatch(allAthletesListStart(data)),
    viewCollegeSaveStart: (data) => dispatch(viewCollegeSaveStart(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBarAthleteColleges);
