import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";
import toastr from "toastr";

// ========================================== Action Type =====================================================
export const actionType = {
  USER_ADDITIONAL_SPORT_START: "USER_ADDITIONAL_SPORT_START",
  USER_ADDITIONAL_SPORT_SAVE: "USER_ADDITIONAL_SPORT_SAVE",
  USER_ADDITIONAL_SPORT_SUCCESS: "USER_ADDITIONAL_SPORT_SUCCESS",
  USER_ADDITIONAL_SPORT_FAILED: "USER_ADDITIONAL_SPORT_FAILED",
};

// ============================================ Actions ========================================================
export const userAdditionalSportStart = (data) => ({
  type: actionType.USER_ADDITIONAL_SPORT_START,
  payload: data,
});
export const userAdditionalSportSave = (data) => ({
  type: actionType.USER_ADDITIONAL_SPORT_SAVE,
  payload: data,
});
export const userAdditionalSportSuccess = (data) => ({
  type: actionType.USER_ADDITIONAL_SPORT_SUCCESS,
  payload: data,
});

export const userAdditionalSportFailed = (data) => ({
  type: actionType.USER_ADDITIONAL_SPORT_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function userAdditionalSportReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.USER_ADDITIONAL_SPORT_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.USER_ADDITIONAL_SPORT_SAVE:
      return {
        ...state,
        loading: true,
      };
    case actionType.USER_ADDITIONAL_SPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.USER_ADDITIONAL_SPORT_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* userAdditionalSportSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );

    const userAdditionalSportResponse =
      response.data.data.saveUseradditionalsports;
    if (response && response.status === 200) {
      if (action.payload.handleAdditionalSportMessage) {
        action.payload.handleAdditionalSportMessage();
      } else if (userAdditionalSportResponse.active == 0) {
        // messagePopup("", "Additional Sport Removed successfully.", "success");
        toastr.success("Additional Sport Removed Successfully.", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates:true,
        })
      }
      yield put(
        userAdditionalSportSuccess({
          saveUseradditionalsports: userAdditionalSportResponse,
        })
      );
    } else {
      messagePopup("", "Additional Sports Save failed", "error");
      yield put(userAdditionalSportFailed("Additional Sports Save failed"));
    }
  } catch (error) {
    messagePopup("", "Additional Sports Save failed", "error");
    yield put(userAdditionalSportFailed("Additional Sports Save failed"));
  }
}

export function* userAdditionalSportListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        userAdditionalSportSuccess({
          allUseradditionalsports: response.data.data.allUseradditionalsports,
        })
      );
    } else {
      messagePopup("", "Additional Sports List failed", "error");
      yield put(userAdditionalSportFailed("Additional Sports List failed"));
    }
  } catch (error) {
    messagePopup("", "Additional Sports List failed", "error");
    yield put(userAdditionalSportFailed("Additional Sports List failed"));
  }
}

//Watcher
export function* watchUserAdditionalSportSaga() {
  yield takeEvery(
    actionType.USER_ADDITIONAL_SPORT_SAVE,
    userAdditionalSportSaveSaga
  );
  yield takeEvery(
    actionType.USER_ADDITIONAL_SPORT_START,
    userAdditionalSportListSaga
  );
}
