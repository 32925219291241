import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  DELETE_ALBUM_START: "DELETE_DELETE_ALBUM_START",
  DELETE_TAG_START: "DELETE_DELETE_TAG_START",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  DELETE_FAILED: "DELETE_FAILED",
};

// ============================================ Actions ========================================================

export const deleteAlbumStart = (data) => ({
  type: actionType.DELETE_ALBUM_START,
  payload: data,
});

export const deleteTagStart = (data) => ({
  type: actionType.DELETE_TAG_START,
  payload: data,
});

export const deleteSuccess = (data) => ({
  type: actionType.DELETE_SUCCESS,
  payload: data,
});

export const deleteFailed = (data) => ({
  type: actionType.DELETE_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function deleteAlbumTagReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.DELETE_ALBUM_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.DELETE_TAG_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.DELETE_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* deleteAlbumSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Uploadedvideoandphotos/removeAlbums`,
      action.payload.data,
      "post",
      true
    );
    if (response && response.status === 200) {
      messagePopup("", "Album Deleted Successfully", "success");
      yield put(deleteSuccess("Album Deleted Successfully"));
      action.payload.refreshPage();
    } else {
      messagePopup("", "Delete Album Failed", "error");
      yield put(deleteFailed("Delete Album Failed"));
    }
  } catch (error) {
    messagePopup("", "Delete Album Failed", "error");
    yield put(deleteFailed("Delete Album Failed"));
  }
}

export function* deleteTagSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      messagePopup("", "Tag Deleted Successfully", "success");
      yield put(deleteSuccess("Tag Deleted Successfully"));
      action.payload.refreshPage();
    } else {
      messagePopup("", "Delete Tag Failed", "error");
      yield put(deleteFailed("Delete Tag Failed"));
    }
  } catch (error) {
    messagePopup("", "Delete Tag Failed", "error");
    yield put(deleteFailed("Delete Tag Failed"));
  }
}

export function* watchDeleteAlbumTagSaga() {
  yield takeEvery(actionType.DELETE_ALBUM_START, deleteAlbumSaga);
  yield takeEvery(actionType.DELETE_TAG_START, deleteTagSaga);
}
