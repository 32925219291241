export const getAllPostsQuery = `
  query allUserposts($obj: JSON,$first: Int, $last: Int){
    allUserposts(where: $obj,first: $first, last: $last){
        Userposts{
            id
            userid
            postdescription
            lat
            lng
            createdon
            updatedon
            isprivate
            ispublic
            pinstatus
            postdetailsmappingPostIdMap{
              Postdetailsmappings{
                postimagevideoandlinkurl
                typeofpost
              }
            }
        }
    }
  } 
  `;

export const savePostQuery = `
  mutation saveUserpost($obj: UserpostInput!){
    saveUserpost(obj: $obj){
        id
        userid
        postdescription 
    }
  } 
  `;

export const saveMediaQuery = `
  mutation savePostdetailsmapping($obj:PostdetailsmappingInput!){
    savePostdetailsmapping(obj: $obj){
        id
        postid
        postimagevideoandlinkurl
        typeofpost
        photovideoid
    }
  } 
  `;

export const savePostLikeQuery = `
  mutation saveUserpostlike($obj: UserpostlikeInput!){
    saveUserpostlike(obj: $obj){
      active
      id
      postid
      userid
    }
  } 
  `;

export const updatePostsQuery = `
  mutation saveUseracivity($obj: UseracivityInput!){
    saveUseracivity(obj: $obj){
      active
      id
      ispined
      userid
    }
  } 
  `;
export const getAllUserActivity = `
query allUseracivities($where: JSON){
  allUseracivities(where: $where){
    Useracivities{
      postid
      id
      active
      postedUploadedPhotosAndVideosId
      useracivityposteduploadedphotosandvideosidrel{
        Uploadedvideoandphotos{
          id
        }
      }
      useracivityuploadedphotosandvideosidrel{
        Uploadedvideoandphotos{
          id
        }
      }
    }
  }
  }
`;

export const getPermalinkQuery = `
query allAthletes($obj: JSON){
  allAthletes(where: $obj){
    Athletes{
      permalinkurl
      }
  }
} 
`;