import React from "react";
import Rectangle132 from "../assets/img/Rectangle132.png";
import DummyAdvisor from '../assets/img/Screen Shot 2022-11-15 at 9.59.55 PM.png'

function ScoutRate() {
  return (
    <div className="scoutRate">
      <div className="headFlex">
        <div className="imgBox">
          <img src={DummyAdvisor} alt="" />
        </div>
        <div className="scoutRate-detail">
          <h5>Get Evaluated by a Recruiting Expert</h5>
        </div>
      </div>
      <p className="desc">
      Find out what colleges need for your grad year?
      </p>
      <button onClick={()=>window.open('https://calendly.com/sportsforce/recruiting-assessment', '_blank')} className="scoutBtn">Get Scouted</button>
    </div>
  );
}

export default ScoutRate;
