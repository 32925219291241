import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_ALL_COACH_DETAILS_START: "GET_ALL_COACH_DETAILS_START",
  ALL_COACH_DETAILS_SUCCESS: "ALL_COACH_DETAILS_SUCCESS",
  ALL_COACH_DETAILS_FAILURE: "ALL_COACH_DETAILS_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const getAllCoachDetailsStart = (data) => ({
  type: actionType.GET_ALL_COACH_DETAILS_START,
  payload: data,
});

export const allCoachDetailsSuccess = (data) => ({
  type: actionType.ALL_COACH_DETAILS_SUCCESS,
  payload: data,
});

export const allCoachDetailsFailure = (data) => ({
  type: actionType.ALL_COACH_DETAILS_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function allCoachDetailsReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_ALL_COACH_DETAILS_START:
      return { ...state, loading: true };

    case actionType.ALL_COACH_DETAILS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.ALL_COACH_DETAILS_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* getAllCoachDetailsSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200) {
      const allCoachandteamdetails =
        response.data.data.allCoachandteamdetails.Coachandteamdetails;
      // .Coachandteamdetails ? response.data.data.allCoachandteamdetails.Coachandteamdetails : null;

      yield put(allCoachDetailsSuccess(allCoachandteamdetails));
    } else {
      messagePopup("", "Fetch All Coach Details Data Failed", "error");
      yield put(allCoachDetailsFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch All Coach Details Data Failed", "error");
    yield put(allCoachDetailsFailure("Failed, Error occured"));
  }
}

export function* watchAllCoachDetailsSaga() {
  yield takeEvery(
    actionType.GET_ALL_COACH_DETAILS_START,
    getAllCoachDetailsSaga
  );
}
