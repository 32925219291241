import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  Collegedivisions_LIST_START: "Collegedivisions_LIST_START",
  Collegedivisions_LIST_SUCCESS: "Collegedivisions_LIST_SUCCESS",
  Collegedivisions_LIST_FAILED: "Collegedivisions_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const CollegedivisionsListStart = (data) => ({
  type: actionType.Collegedivisions_LIST_START,
  payload: data,
});

export const CollegedivisionsListSuccess = (data) => ({
  type: actionType.Collegedivisions_LIST_SUCCESS,
  payload: data,
});

export const CollegedivisionsListFailed = (data) => ({
  type: actionType.Collegedivisions_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function CollegedivisionsListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.Collegedivisions_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.Collegedivisions_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.Collegedivisions_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* CollegedivisionsListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const allCollegedivisions = response.data.data.allCollegedivisions?.Collegedivisions
        ? response.data.data.allCollegedivisions?.Collegedivisions
        : null;
      let finalData = [];
      yield put(CollegedivisionsListSuccess(allCollegedivisions));
    } else {
      messagePopup("", "COLLEGE List failed", "error");
      yield put(CollegedivisionsListFailed("Collegedivisions List failed"));
    }
  } catch (error) {
    messagePopup("", "Collegedivisions List failed", "error");
    yield put(CollegedivisionsListFailed("Collegedivisions List failed"));
  }
}

export function* watchCollegedivisionsListSaga() {
  yield takeEvery(actionType.Collegedivisions_LIST_START, CollegedivisionsListSaga);
}
