import React from "react";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/EmailSentNotification.scss";
import IosCheckMark from "../assets/img/iosCheckMark.png";
import { Dialog } from "@mui/material";
import { useHistory} from 'react-router-dom';

const EmailSentNotification = () => {
  let history = useHistory();

  const handleOnClick = () => {
    history.push("/login")
  }
  return (
    <div>
      <Dialog open={true} className="matDialogCustom">
        <div className="emailSentNotification">
          <div className="emailSent">
            <div className="closeButton">
            <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.804"
                    height="15.803"
                    viewBox="0 0 15.804 15.803"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g className="a" transform="translate(-6.776 -6.776)">
                      <path
                        d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </div>

            <div className="icon">
              <img src={IosCheckMark} alt="iosCheckMark" />
            </div>
            <p className="info">
              Email has been sent to Sportsforce team and you will be notified
              once verification done.
            </p>
            <div className="profileBtn">
              <button onClick={()=>handleOnClick()}>Ok</button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EmailSentNotification;
