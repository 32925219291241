import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  VIEW_COACH_LEVEL_START: "VIEW_COACH_LEVEL_START",
  VIEW_COACH_LEVEL_SUCCESS: "VIEW_COACH_LEVEL_SUCCESS",
  VIEW_COACH_LEVEL_FAILED: "VIEW_COACH_LEVEL_FAILED"
};

// ============================================ Actions ========================================================

export const viewCoachLevelStart = data => ({
  type: actionType.VIEW_COACH_LEVEL_START,
  payload: data
});

export const viewCoachLevelSuccess = data => ({
  type: actionType.VIEW_COACH_LEVEL_SUCCESS,
  payload: data
});

export const viewCoachLevelFailed = data => ({
  type: actionType.VIEW_COACH_LEVEL_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function viewCoachLevelReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.VIEW_COACH_LEVEL_START:
      return {
        ...state,
        loading: true
      };

    case actionType.VIEW_COACH_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.VIEW_COACH_LEVEL_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* viewCoachLevelSaga(action) {
  try {
    const response = yield fetchMethod(`/api/useracivities/coachViewsByLevel`,action.payload.variables,'post',true);
    if (response && response.status === 200) {
      yield put(
        viewCoachLevelSuccess({data: response.data.response.data})
      );
    } else {
      messagePopup("", "View Coach Level Api failed", "error");
      yield put(viewCoachLevelFailed("View Coach Level Api failed"));
    }
  } catch (error) {
    messagePopup("", "View Coach Level Api failed", "error");
    yield put(viewCoachLevelFailed("View Coach Level Api failed"));
  }
}

export function* watchViewCoachLevelSaga() {
  yield takeEvery(actionType.VIEW_COACH_LEVEL_START, viewCoachLevelSaga);
}
