import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  UPDATE_PRESS_LIST_START: "UPDATE_PRESS_LIST_START",
  UPDATE_PRESS_LIST_SUCCESS: "UPDATE_PRESS_LIST_SUCCESS",
  UPDATE_PRESS_LIST_FAILED: "UPDATE_PRESS_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const updateBasicPressListStart = (data) => ({
  type: actionType.UPDATE_PRESS_LIST_START,
  payload: data,
});

export const updateBasicPressListSuccess = (data) => ({
  type: actionType.UPDATE_PRESS_LIST_SUCCESS,
  payload: data,
});

export const updateBasicPressListFailed = (data) => ({
  type: actionType.UPDATE_PRESS_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function updateBasicPressListReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.UPDATE_PRESS_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPDATE_PRESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.UPDATE_PRESS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* updateBasicPressListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      if (action.payload.handleSaveMessage) {
        action.payload.handleSaveMessage();
      } else if (action.payload.handleEditPressMessage) {
        action.payload.handleEditPressMessage();
      } else if (action.payload.afterDeletePress) {
        action.payload.afterDeletePress();
      }
      yield put(updateBasicPressListSuccess({ response }));
    } else {
      messagePopup("", "Update Basic User Profile List failed", "error");
      yield put(
        updateBasicPressListFailed("Update Basic User Profile List failed")
      );
    }
  } catch (error) {
    messagePopup("", "Update Basic User Profile List failed", "error");
    yield put(
      updateBasicPressListFailed("Update Basic User Profile List failed")
    );
  }
}

export function* watchUpdateBasicPressListSaga() {
  yield takeEvery(actionType.UPDATE_PRESS_LIST_START, updateBasicPressListSaga);
}
