export const collegeDetailsQuery = (userId) => `
query allColleges($where : JSON){
    allColleges(where : $where){
      Colleges{
        id
        collegename
        updatedon
        active
        allowtosendmsg
        collegeinfo
        collegelogo
        collegebanner
        collegelevel
        collegedevisionid
        collegetutioninsidecost
        collegecollegesettingrel{
          Collegesettings{
            id
            name
          }
        }
        collegecollegedevisionidmaprel{
          Collegedivisions{
            collegedivisionname
            id
          }
        }
        collegecollegetutioncostrel{
          Tutioncosts{
            id
          name
            }
          }
        collegeathleticselectivityidrel{
        Athleticselectivities{
          id
          name
        }
      }
        collegestrength
        collegesetting
        collegetutioncost
        collegefllower
        collegetutionoutsidecost
        collegeconferenceidmaprel{
          Collegeconferences{
            id
            conferencenmae
          }
        }
        collegewebsite
        collegetwittername
        collegeinstagram
        collegefacebookid
        athletic
        city
        state
        collegestateidmaprel{
          States{
            id
            statename
            initials
          }
        }
        locationaaddress
        collegelat
        collegelng
        collegetype
        collegefacebookid
        academicsselectivity
        collegeacademicsselectivitymaprel{
          Acadamicselectivities{
            id
            name
            
          }
        }
        userCollegeCollegeIdMap(where : {active : 1, userid : ${userId}}){
          Usercolleges{
            id
            userid
            collegeid
            collegerank
            active
            collegeinterestlevel
            usercollegecollegeinterestlevelmaprel{
              Interestlevels{
                id
                interestlevelvalue
              }
            }
          }
        }
      }
    }
  }`

  export const getAllAthleticSelectivityQuery = `
  query allAthleticselectivities{
    allAthleticselectivities{
      Athleticselectivities{
        id
        name
      }
    }
  }
  `
  export const getAllCollegeAdmissionsQuery = `
  query allCollegeadmissions($where : JSON){
    allCollegeadmissions(where : $where){
      Collegeadmissions{
        collegeid
        acceptancerate
        openadmission
        totalapplicants
        fulltimeundergrades
        admissionsphone
        partTimeundergrads
        fulltimeretentionrate
        applicationaccepted
        applicationdeadline
        earlyactiondeadline
        earlydecisionacceptancerate
        earlydecisiondeadline
        applicationwebsite
        applicationfee
        instate
        outofstate
        international
        unknown
        commonstatesofresidence
        satactrequirement
        recommendations
        transcript
        gpa
        classrank
        receivinganyfinancialaid
        netprice
        financialaidwebsite
        federalgrantaidamount
              }
    }
  }
  `

export const getAllUserCollegesQuery = `
  query allUserColleges($where : JSON , $first: Int , $last : Int){
    allUsercolleges(where : $where, first : $first, last : $last){
      Usercolleges{
        id
        collegeactive
        updatedon
        active
        userid
        collegeid
        collegerank
        collegeinterestlevel
        categoryid
        usercollegecollegeinterestlevelmaprel{
          Interestlevels{
            id
            interestlevelvalue
          }
          }
        commit
        contact
        interest
        isfav
        visit
        isaddbyotherplace
        offer
        signed
        target
        alumni
        usercollegecollegeidmaprel{
          Colleges{
            id
            collegename
            
          }
        }
     }
    }
  }
  `

export const getAllOfferQuery = `
query allOffers($where: JSON) {
  allOffers(where: $where){
    totalCount
    Offers{
      id
      collegeid
      offerid
      active
      offerdate
      interestlevelid
    }
  }
} 
`;

export const getCoachDetailsQuery = `
query allCoachandteamdetails($where : JSON) {
    allCoachandteamdetails(where : $where){
      Coachandteamdetails{
        id
        collegeid
        coachemail
        coachphonenumber
        isverifiedcoach
        textmeimportantnotification
        isemailsenttoadmin
        coachfacebookid
        coachinstaid
        coachtwitterid
        coachandteamdetailscollegeidmaprel{
          Colleges{
            collegename
          }
        }
        coachfirstname
        coachlastname
        userid
        active
        teamname
        collegeid
        iscollegecoach
        isprestentcoach
        coachandteamdetailscoachtypeidmaprel{
          Coachtypes{
           coachtypename 
          }
        }
        coachandteamdetailsonboardingidmaprel{
          Onboardings{
            profileimages
          }
        }
        designation
        isathletesendmsgtocoach
        coachrequest
        coachopening
      }
    }
  }`;

export const getAllFollowersQuery = `
  query allFollowers($where : JSON){
    allFollowers(where : $where){
      totalCount
     Followers{
      id
      followercoachid
      followingcoachid
      followingid
      followerid
      collegeid
    } 
    }
  }`

export const getCollegeCoachActivityData = `
  query allCollegecoachactivities($where : JSON, $first : Int, $last : Int){
    allCollegecoachactivities(where : $where , first : $first, last : $last){
      Collegecoachactivities{
        id
        coachid
        updatedon
        viewathleteid
        activityname
        searchid
        coachfollowathleteid
        activityname
      }
    }
  }
  `


export const getAllProgresses = `
query allMyProgresses($where : JSON){
  allMyprogresses(where : $where){
   Myprogresses{
     id
     collegeid
     offertypeid
     commitmentid
    collegepreference
    matchanalysisconducted
    researchcollege
    followingfavaritecollege
    categorized
    sendmessage
    receiveview
    receivedmessage
    followedbycollege
    releaseprofile
    transcriptrequest
    videometricsrequest
    calltextrequest
    introductionrequest
    liveevaluation
    digitalconversation
    offcampushcontact
    phonezoomconversation
    unofficialvisit
    officialvisit
    lookatflsrlanguage
    partialathleticscholarship
    recruitedwalkon
    offertopalyanothersport
    fullathleticscholarship
    recruited
    unrecruitedwalkon
    verballycommittedtopalybaseball
    committedtopalyanothersport
    signedcommitmenttoplaybaseball
    academicwalkon
    signcommitmenttopalybaseball
    alumniloremipsum
  } 
  }
}`

export const getAllAcademicsDataQuery = `
query allAcademiccollegedetails($where : JSON){
  allAcademiccollegedetails(where : $where){
    Academiccollegedetails{
      id
      collegeid
      calendarsystem
      graduationrate
      collegetype
      femaleprofessors
      maleprofessors
      onlinecourses
      studentfacultyratio
      studyabroad
      teachercertification
      selectivity
      majorsdata
      
    }
  }
}
`

export const saveProgressQuery = `
mutation saveMyprogress($obj : MyprogressInput!){
  saveMyprogress(obj :$obj){
    id
  }
}
`

export const getViewCollegesQuery = `
query allViewcolleges($where : JSON){
  allViewcolleges(where : $where){
    totalCount
    Viewcolleges{
      collegeid
      collegeviewstatus
      athleteid
    }
  }
}
`

export const getAllAthletesQuery = `
query allAthletes($where : JSON,  $first: Int, $last:Int){
  allAthletes(where : $where,  first: $first , last: $last){
    Athletes{
      athletefirstname
      athletelastname
      id
      athletegraduationyear
      active
      athleteathletegraduationyearmaprel{
        Graduationyears{
          yearvalue
        }
      }
      onBoardingUserIdMap{
        Onboardings{
          profileimages
        }
      }
    }
  }
}
`

export const getAllCollegeCampusesQuery = `
query allCollegecampushes($where : JSON){
  allCollegecampushes(where : $where){
    Collegecampushes{
      id
      collegeid
      coed
      africanamerica
      international
      nativeamerica
      pacificislander
      asian
      hispanic
      multiracial
      unknown
      white
      femalevarsityathletes
      malevarsityathletes
      female
      male
      hbcu
      religiousaffiliation
      county
      metroarea
      housingtype
      oncampushousingavailable
      talktoalex
      airforce
      army
      navy
      collegecampushcollegeidmaprel{
        Colleges{
          locationaaddress
          city
          state
          collegelat
          collegelng
        }
      }
    }
  }
}
`

export const viewsCountQuery = `
query allUserprofileviews($where : JSON){
  allUserprofileviews(where :$where){
    totalCount
    Userprofileviews{
      id
      profileviewedby
      collegeid
    }
  }
}
`
export const searchCountQuery = `
query allSearchbycolleges($where: JSON){
  allSearchbycolleges(where :$where){
    totalCount
    Searchbycolleges{
      id
      coachid
      athleteid
      collegeid
      athleteid
    }
  }
}`

export const majorsQuery = `
query allCollegeareaofstudies($where: JSON){
  allCollegeareaofstudies(where :$where){
    Collegeareaofstudies{
      collegeareaofstudybranchnamerel{
        Areaofstudies{
          areaofstudyname
        }
      }
    }
  }
}`

export const getCollegesFavListQuery = `
  query allUsercolleges($obj: JSON){
    allUsercolleges(where: $obj){
        totalCount
        Usercolleges{
        id
        isfav
      }
    }
  } 
 `;