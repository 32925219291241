export const parentNameQuery = `
query allAthleteparentmappings($obj: JSON) {
    allAthleteparentmappings(where: $obj){
      Athleteparentmappings{
        id
        athleteid
        active
        
        athleteparentmappingmappingparentidmaprel{
          Athleteparents{
            id
          userid
          active
          parentfirstname
          isaccessallowed
          
          
          }
        }
      }
    }
    }
 `;