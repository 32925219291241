import { call, put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import { getUserInfo } from "../../../../services/authService";
import successMessagePopup from "../../../../utils/successMsgPopup";
import { successMessageToaster } from "../../../../utils/successMsgPopup";
import { saveAllVideoPhotoInfoQuery } from "../../../../components/views/athlet/dashboard/editUserProfile/videoPhoto/VideoPhotoQuery";
// import warningMessagePopup from "../../../../utils/warningMagPop";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  SAVE_POSTS_START: "SAVE_POSTS_START",
  REMOVE_POSTS_START: "REMOVE_POSTS_START",
  PIN_POSTS_START: "PIN_POSTS_START",
  UNPIN_POSTS_START: "UNPIN_POSTS_START",
  SAVE_POSTS_SUCCESS: "SAVE_POSTS_SUCCESS",
  SAVE_POSTS_FAILURE: "SAVE_POSTS_FAILURE",
  CLEAR_POSTS_SUCCESS: "CLEAR_POSTS_SUCCESS",
};

/* ======================================= Actions Creator ==========================================*/
export const clearPostSuccess = () => ({
  type: actionType.CLEAR_POSTS_SUCCESS,
});
export const savePostStart = (data) => ({
  type: actionType.SAVE_POSTS_START,
  payload: data,
});

export const removePostStart = (data) => ({
  type: actionType.REMOVE_POSTS_START,
  payload: data,
});

export const pinPostStart = (data) => ({
  type: actionType.PIN_POSTS_START,
  payload: data,
});

export const unpinPostStart = (data) => ({
  type: actionType.UNPIN_POSTS_START,
  payload: data,
});

export const savePostSuccess = (data) => ({
  type: actionType.SAVE_POSTS_SUCCESS,
  payload: data,
});

export const savePostFailure = (data) => ({
  type: actionType.SAVEPOSTS_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function savePostReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.CLEAR_POSTS_SUCCESS:
      return { ...state, loading: false, data: null };
    case actionType.REMOVE_POSTS_START:
      return { ...state };

    case actionType.PIN_POSTS_START:
      return { ...state, loading: true };

    case actionType.UNPIN_POSTS_START:
      return { ...state, loading: true };

    case actionType.SAVE_POSTS_START:
      return { ...state, loading: true };

    case actionType.SAVE_POSTS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.SAVE_POSTS_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* savePostSaga(action) {
  try {
    const {
      postQuery,
      mediaQuery,
      postData,
      mediaData,
      getPosts,
      userActivityQuery,
      photoAndVideoId = null,
      savePhotoVideo = null,
    } = action.payload;
    let shareData = {postDetail: "", activity: "", mediaData: []}
    const response = yield fetchGraphMethod(postQuery, postData, true);
    if (response && response.status === 200) {
      const saveUserpost = response.data.data
        ? response.data.data.saveUserpost
          ? response.data.data.saveUserpost
          : null
        : null;
      if (saveUserpost && saveUserpost.id) {
        shareData['postDetail'] =  saveUserpost
        if (savePhotoVideo) {
          savePhotoVideo(saveUserpost.id);
        }
        const activityResponse = yield fetchGraphMethod(
          userActivityQuery,
          {
            obj: {
              postid: saveUserpost.id,
              userid: getUserInfo().id,
              uploadedphotosandvideosid: photoAndVideoId
                ? photoAndVideoId
                : null,
            },
          },
          true
        );
        if (activityResponse.status === 200) {
          shareData['activity'] = activityResponse?.data?.data?.saveUseracivity
          let successFlag = [];
          if (mediaData && mediaData.length) {
            for (const data of mediaData) {
              const response1 = yield fetchGraphMethod(
                mediaQuery,
                {
                  obj: {
                    postid: saveUserpost.id,
                    ...data,
                  },
                },
                true
              );
              if (response1 && response1.status === 200) {
                const savePostdetailsmapping = response1.data.data
                  ? response1.data.data.savePostdetailsmapping
                    ? response1.data.data.savePostdetailsmapping
                    : null
                  : null;
                shareData?.mediaData?.push(savePostdetailsmapping)
                if (savePostdetailsmapping && savePostdetailsmapping.id) {
                  successFlag = [...successFlag, true];
                } else {
                  successFlag = [...successFlag, false];
                }
              }
            }
          } else {
            if (!photoAndVideoId)
              yield call(
                  successMessageToaster,
                "",
                "Post Uploaded Successfully.",
                "success"
              );
            if (photoAndVideoId) {
              yield put(
                savePostSuccess({
                  message: "Post Uploaded Successfully.",
                  ishome: "false",
                })
              );
            } else {
              yield put(
                savePostSuccess({
                  message: "Post Uploaded Successfully.",
                  ishome: "true",
                  shareData
                })
              );
            }

            if (getPosts) getPosts();
          }
          if (successFlag.length && !successFlag.includes(false)) {
            if (!photoAndVideoId)
              yield call(
                  successMessageToaster,
                "",
                "Post Uploaded Successfully.",
                "success"
              );
            if (photoAndVideoId) {
              yield put(
                savePostSuccess({
                  message: "Post Uploaded Successfully.",
                  ishome: "false",
                })
              );
            } else {
              yield put(
                savePostSuccess({
                  message: "Post Uploaded Successfully.",
                  ishome: "true",
                  shareData
                })
              );
            }
            if (getPosts) getPosts();
          }
        } else {
          messagePopup("", "upload Post Failed", "error");
          yield put(savePostFailure("upload, Error occured"));
        }
      } else {
        messagePopup("", "upload Post Failed", "error");
        yield put(savePostFailure("Failed, Error occured"));
      }
    } else {
      messagePopup("", "upload Post Failed", "error");
      yield put(savePostFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "upload Post Failed", "error");
    yield put(savePostFailure("Failed, Error occured"));
  }
}

function* removePostSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const saveUseracivity = response.data.data
        ? response.data.data.saveUseracivity
          ? response.data.data.saveUseracivity
          : null
        : null;

      if (action.payload.deletePostId) {
        const response1 = yield fetchMethod(
          `/api/Uploadedvideoandphotos/deleteUploadPhotoVideo`,
          { postid: action.payload.deletePostId, photovideoid : action?.payload?.uploadVideoAndPhotoId ?? 0 },
            //this photovideoid is added to delete the post where ever it was there
          "post",
          true
        );
      }
      if (saveUseracivity && saveUseracivity.id) {
        if (action.payload.uploadVideoAndPhotoId) {
          yield fetchGraphMethod(
            saveAllVideoPhotoInfoQuery,
            {
              obj: {
                id: action.payload.uploadVideoAndPhotoId,
                showontimelineordashboard: 0,
              },
            },
            true
          );
        }
        action.payload.getPosts();
        action?.payload?.removeMyPostPage(action?.payload?.page ?? "")
      } else {
        messagePopup("", "Delete Post Failed", "error");
        yield put(savePostFailure("Failed, Error occured"));
        action.payload.getPosts();
      }
    } else {
      messagePopup("", "Delete Post Failed", "error");
      yield put(savePostFailure("Failed, Error occured"));
      action.payload.getPosts();
    }
  } catch (error) {
    messagePopup("", "Delete Post Failed", "error");
    yield put(savePostFailure("Failed, Error occured"));
    action.payload.getPosts();
  }
}

function* pinPostSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const saveUseracivity = response.data.data
        ? response.data.data.saveUseracivity
          ? response.data.data.saveUseracivity
          : null
        : null;
      if (saveUseracivity && saveUseracivity.id) {
        action.payload.getPosts();
      } else {
        messagePopup("", "Pin Post Failed", "error");
        yield put(savePostFailure("Failed, Error occured"));
        action.payload.getPosts();
        if (action.payload.afterPinningPost) {
          action.payload.afterPinningPost();
        }
      }
    } else {
      messagePopup("", "Pin Post Failed", "error");
      yield put(savePostFailure("Failed, Error occured"));
      action.payload.getPosts();
    }
  } catch (error) {
    messagePopup("", "Pin Post Failed", "error");
    yield put(savePostFailure("Failed, Error occured"));
    action.payload.getPosts();
  }
}

function* unpinPostSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const saveUseracivity = response.data.data
        ? response.data.data.saveUseracivity
          ? response.data.data.saveUseracivity
          : null
        : null;
      if (saveUseracivity && saveUseracivity.id) {
        // yield put(savePostSuccess("Post unpinned successfully"));
        action.payload.getPosts();
        if (action.payload.afterUnPinningPost)
          action.payload.afterUnPinningPost();
      } else {
        messagePopup("", "Post unpinned Failed", "error");
        yield put(savePostFailure("Failed, Error occured"));
        action.payload.getPosts();
      }
    } else {
      messagePopup("", "Post unpinned Failed", "error");
      yield put(savePostFailure("Failed, Error occured"));
      action.payload.getPosts();
    }
  } catch (error) {
    messagePopup("", "Post unpinned Failed", "error");
    yield put(savePostFailure("Failed, Error occured"));
    action.payload.getPosts();
  }
}

export function* watchSavePost() {
  yield takeEvery(actionType.SAVE_POSTS_START, savePostSaga);
  yield takeEvery(actionType.REMOVE_POSTS_START, removePostSaga);
  yield takeEvery(actionType.UNPIN_POSTS_START, unpinPostSaga);
  yield takeEvery(actionType.PIN_POSTS_START, pinPostSaga);
}
