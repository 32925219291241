import { Dialog, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { footListStart } from '../../../../../../redux/athlete/onBoarding/footList';
import { inchListStart } from '../../../../../../redux/athlete/onBoarding/inchList';
import { getOnboardingStart } from '../../../../../../redux/athlete/onBoarding/onboarding';
import moment from 'moment';
import {
  getAuthToken,
  getUserInfo,
} from '../../../../../../services/authService';
import warningSign from '../assets/img/warningsign.svg';
import {
  getAllHeightInFeetsQuery,
  getAllHeightInInchesQuery,
} from '../../../onboarding/physicalMeasurements/PhysicalMeasurementsQuery';
import { getvitalDataQuery } from '../vitalQuery';
import addRoundIcon from '../assets/img/plus-round-icon.svg';
import closeGrayIcon from '../assets/img/close-gray-icon.svg';
import trashCircleIcon from '../assets/img/trash-circle-icon.svg';
import {
  getAllJumpData,
  saveStatmatrixdetails,
  updateScoutingRequestQuery,
} from './statsQuery';
import { allJumpDataListStart } from '../../../../../../redux/athlete/dashboard/editUserProfile/stats/allJumpData';
import messagePopup from '../../../../../../utils/messagePopup';
import toastr from 'toastr';
import BackdropLoader from '../../../../../common/Loader';
import axios from 'axios';
import {
  imageDownloadUrl,
  imageUploadUrl,
  videoUploadUrl,
} from '../../../../../../config';
import OwlCarousel from 'react-owl-carousel';
import {
  getSlashFormattedDate,
  getUTCTimeDate,
  getUTCTimeDateOverall,
} from '../../../../../../utils/dateTimeFormatter';
import swal from 'sweetalert';
import MuiAutoComplete from '../../../../../common/AutoComplete';
import { filterTeamSchoolNameQuery } from '../teams/TeamQuery';
import {
  fetchMethod,
  fetchGraphMethod,
} from '../../../../../../services/httpService';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { getAllCoachDetailsStart } from '../../../../../../redux/athlete/dashboard/editUserProfile/allCoachesDetails';
import { getAllCoachAndTeamDetails } from '../../../../../common/CommonQuery';
import {
  AplhaNumericInput,
  AplhaNumericInputComma,
  NumFieldSymbolArr,
  validURL,
  validVideoURL,
  validStatVideoURL,
} from '../../../../../../utils/validationUtils';
import { allStatMatricDetailsListSave } from '../../../../../../redux/athlete/dashboard/editUserProfile/stats/allStatmatrixdetails';
import {
  savePostQuery,
  updatePostsQuery,
  saveMediaQuery,
} from '../../../dashboard/homepage/posts/PostsQuery';
import thumbnail from '../../../../../../assets/img/video-to-link-thumbnail.jpg';
import { VideoPop } from '../../../../landingPage/common/videoPopup';
import {
  getThumbnail,
  getVimeoThumb,
  typeConvert,
} from '../../../../../common/thumbnail';
import { vidCountIncre } from '../../../../../common/userViewCount';
import {
  sendVideoPhotoNotification,
  sendStatsNotification,
} from '../../../../../../utils/sendNotificationToAdvisor';
import { calculate1RM } from './calculate1RM';

// flag 1 for video 2 for link and 3 for photo
const getMetricFields = (tabData, onChangeHandler, fields, error, jumpList) => {
  switch (tabData?.statsmatrixid) {
    case 1:
      return (
        <>
          <div className="half">
            <label>
              Enter Result (Sec) <sup>*</sup>
            </label>
            <input
              type="number"
              placeholder={tabData ? tabData.placeholdertext : ''}
              value={fields.result}
              onChange={(e) => onChangeHandler(e, 'result')}
            />
            <div className="error">{error.result ? error.result : ''} </div>
          </div>
          <div className="half">
            <p className="checkbox-list mt35">
              <span>
                <input
                  type="checkbox"
                  checked={fields.lasertime}
                  onChange={(e) => onChangeHandler(e, 'lasertime')}
                />
                <label>Laser time</label>
              </span>
            </p>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <div className="half">
            <label>
              Enter Result (Ft) <sup>*</sup>
            </label>
            <Select
              value={fields.jumbidinfeet}
              id="heightinfeet"
              onChange={(e) => onChangeHandler(e, 'jumbidinfeet')}
              style={{ width: '100%' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {tabData?.tabname == 'Vertical Jump'
                ? jumpList.feetList &&
                  jumpList.feetList.map((item, index) =>
                    item.feetvalue <= 5 && item.feetvalue > 0 ? (
                      <MenuItem key={index} value={item.id}>
                        {item.feetvalue}
                      </MenuItem>
                    ) : null
                  )
                : jumpList.feetList &&
                  jumpList.feetList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.feetvalue}
                    </MenuItem>
                  ))}
            </Select>
            <div className="error">
              {error.jumbidinfeet ? error.jumbidinfeet : ''}{' '}
            </div>
          </div>
          <div className="half">
            <label>
              Enter Result (In) <sup>*</sup>
            </label>
            <Select
              value={fields.jumbidininches}
              id="heightininches"
              onChange={(e) => onChangeHandler(e, 'jumbidininches')}
              style={{ width: '100%' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {jumpList.inchList &&
                jumpList.inchList.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.inchvalue}
                  </MenuItem>
                ))}
            </Select>
            <div className="error">
              {error.jumbidininches ? error.jumbidininches : ''}{' '}
            </div>
          </div>
        </>
      );
    case 3:
      return (
        <>
          <div className="half">
            <label>
              Enter Result (LBS) <sup>*</sup>
            </label>
            <input
              placeholder={tabData ? tabData.placeholdertext : ''}
              value={fields.lbs}
              type="number"
              onChange={(e) => onChangeHandler(e, 'lbs')}
            />
            <div className="error">{error.lbs ? error.lbs : ''} </div>
          </div>
          <div className="half">
            <label>
              Enter Result (REPS) <sup>*</sup>
            </label>
            <input
              placeholder={tabData ? tabData.placeholdertext : ''}
              value={fields.reps}
              type="number"
              onChange={(e) => onChangeHandler(e, 'reps')}
            />
            <div className="error">{error.reps ? error.reps : ''} </div>
          </div>
        </>
      );
    default:
      return (
        <>
          <div className="half">
            <label>
              Enter Result ({tabData.unit}) <sup>*</sup>
            </label>
            <input
              placeholder={tabData ? tabData.placeholdertext : ''}
              value={fields.result}
              type="number"
              onChange={(e) => onChangeHandler(e, 'result')}
            />
            <div className="error">{error.result ? error.result : ''} </div>
          </div>
        </>
      );
  }
};

let AddYardModal = (props) => {
  const [fields, setFields] = useState({
    id: props.isEdit ? props.metricData.id : 0,
    date: props.isEdit
      ? props.metricData.testdate
      : new Date().toISOString().substr(0, 10),
    heightinfeet: props.isEdit ? props.metricData.heightinfeetid : '',
    heightininches: props.isEdit ? props.metricData.heightininchecsid : '',
    weight: props.isEdit ? props.metricData.weight : '',
    lasertime: props.isEdit ? props.metricData.lasertime : false,
    result: props.isEdit ? props.metricData.result : '',
    jumbidinfeet: props.isEdit ? props.metricData.jumbidinfeet : '',
    jumbidininches: props.isEdit ? props.metricData.jumbidininches : '',
    lbs: props.isEdit ? props.metricData.lbs : '',
    reps: props.isEdit ? props.metricData.reps : '',
    weblink: props.isEdit ? props.metricData.weblink : '',
    videonameStat: props?.isEdit
      ? props?.metricData?.isvideoorlink == 0 ||
        props?.metricData?.isvideoorlink == 1 ||
        props?.metricData?.isvideoorlink == 3
        ? props?.metricData?.videoname
        : ''
      : '',
    videolinkStat: props?.isEdit
      ? props?.metricData?.isvideoorlink == 2
        ? props?.metricData?.videoname
        : ''
      : '',
    thumbnailStat: props?.isEdit
      ? !!props?.metricData?.thumbnail
        ? props?.metricData?.thumbnail
        : ''
      : '',
    isvideoorlinkStat: props?.isEdit
      ? !!props?.metricData?.isvideoorlink
        ? props?.metricData?.isvideoorlink
        : ''
      : '',
  });
  const [tabData, setTabData] = useState();
  const [showDeleteRecordModal, setShowDeleteRecordModal] = useState(false);
  const [error, setError] = useState({});
  const [jumpList, setJumpList] = useState({
    feetList: [],
    inchList: [],
  });
  const [showDeleteVideoModal, setShowDeleteModal] = useState(false);
  const [showVideoDesc, setShowVideoDesc] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImages, setIsImages] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFileUrl, seSelectedFileUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [vidThumbnail, setVidThumbnail] = useState(''); //state is added as per change thunmbail vimeo
  const [loading, setLoading] = useState(false);
  const [videoDesc, setVideoDesc] = useState('');
  const [filePath, setFilePath] = useState('');
  const [vidID, setVidID] = useState(null); //this state is for videoPop
  const [isVidPop, setIsVidPop] = useState(false); //this state sid for videoPop
  const [isLink, setIsLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorVid, setErrorVid] = useState('');
  useEffect(() => {
    setFields({
      ...fields,
      id: props.isEdit ? props.metricData.id : 0,
      date: props.isEdit
        ? props.metricData.testdate
        : new Date().toISOString().substr(0, 10),
      lasertime: props.isEdit ? props.metricData.lasertime : false,
      result: props.isEdit ? props.metricData.result : '',
      jumbidinfeet: props.isEdit ? props.metricData.jumbidinfeet : '',
      jumbidininches: props.isEdit ? props.metricData.jumbidininches : '',
    });
  }, [props.isEdit]);

  useEffect(() => {
    if (props.tabData.tabData) {
      setTabData(props.tabData.tabData);
    }
  }, [props.tabData]);
  useEffect(() => {
    props.footListStart({
      query: getAllHeightInFeetsQuery,
      variables: null,
    });
    props.inchListStart({
      query: getAllHeightInInchesQuery,
      variables: null,
    });
    props.getJumpData({
      query: getAllJumpData,
      variables: null,
    });

    getOnboardingVitals();
  }, []);
  useEffect(() => {
    if (
      props.allJumpDataList &&
      props.allJumpDataList.data &&
      props.allJumpDataList.data.allJumpData
    ) {
      if (
        props.allJumpDataList.data.allJumpData.allFeet &&
        props.allJumpDataList.data.allJumpData.allFeet.Feet &&
        props.allJumpDataList.data.allJumpData.allInches &&
        props.allJumpDataList.data.allJumpData.allInches.Inches
      ) {
        setJumpList({
          ...jumpList,
          feetList: props.allJumpDataList.data.allJumpData.allFeet.Feet,
          inchList: props.allJumpDataList.data.allJumpData.allInches.Inches,
        });
      }
    }
  }, [props.allJumpDataList]);
  useEffect(() => {
    if (
      props.onBoardingDetails &&
      props.onBoardingDetails.onboarding &&
      props.onBoardingDetails.onboarding.data
    ) {
      const { id, heightinfeet, heightininches, weight } =
        props.onBoardingDetails.onboarding.data;
      let fieldsT = fields;
      fieldsT['heightinfeet'] = fieldsT['heightinfeet']
        ? fieldsT['heightinfeet']
        : heightinfeet == null
        ? ''
        : heightinfeet;
      fieldsT['heightininches'] = fieldsT['heightininches']
        ? fieldsT['heightininches']
        : heightininches == null
        ? ''
        : heightininches;
      fieldsT['weight'] = fieldsT['weight']
        ? fieldsT['weight']
        : weight == null
        ? ''
        : weight;
      setFields({ ...fieldsT });
    }
  }, [props.onBoardingDetails]);
  const onChangeHandler = (e, field) => {
    let fieldsT = fields;
    if (field == 'lasertime') {
      fieldsT[field] = e.target.checked;
    } else {
      const updatedValue = e.target.value;
      if (field === 'result' || field === 'lbs' || field === 'reps') {
        const numArr = updatedValue.split('.');
        if (numArr[1]) {
          if (numArr[1].length <= 2) {
            fieldsT[field] = updatedValue;
          }
        } else {
          fieldsT[field] = updatedValue;
        }
      } else {
        fieldsT[field] = updatedValue;
      }
    }
    if (field == 'jumbidininches' || field == 'jumbidinfeet') {
      let feetValue = 0,
        inchvalue = 0;
      jumpList.feetList &&
        jumpList.feetList.map((item) => {
          if (item.id == fieldsT.jumbidinfeet) {
            feetValue = item.feetvalue;
          }
        });
      jumpList.inchList &&
        jumpList.inchList.map((item) => {
          if (item.id == fieldsT.jumbidininches) {
            inchvalue = item.inchvalue;
          }
        });
      fieldsT['jumpincentimeter'] = feetValue * 30.48 + inchvalue * 2.54;
    }
    setError({});
    setErrorVid('');
    setFields({ ...fieldsT });
  };
  const emptyWeblink = () => {
    let fieldsT = fields;
    fieldsT['weblink'] = '';
    setError({});
    setErrorVid('');
    setFields({ ...fieldsT });
  };
  const getOnboardingVitals = () => {
    props.getOnboardingStart({
      query: getvitalDataQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
  };
  const handleValidation = () => {
    let isValid = true;
    if (!fields.date) {
      setError({ ...error, date: 'Please Enter a date' });
      isValid = false;
    }
    let selectedDate = new Date(fields.date);
    if (selectedDate.getTime() > new Date().getTime()) {
      isValid = false;
      setError({ ...error, date: 'Performance Date can not be a future date' });
    }
    if (!fields.heightinfeet) {
      isValid = false;
      setError({ ...error, heightinfeet: 'Please choose height (Ft)' });
    }
    if (!fields.heightininches) {
      isValid = false;
      setError({ ...error, heightininches: 'Please choose height (In)' });
    }
    if (!fields.weight) {
      isValid = false;
      setError({ ...error, weight: 'Please enter weight' });
    }
    if (!!fields.weblink) {
      if (!fields.weblink?.includes('http')) {
        isValid = false;
        setError({ ...error, weblink: 'Please enter valid weblink' });
      }
    }
    if (fields.weight <= 0 || fields.weight > 999) {
      isValid = false;
      setError({ ...error, weight: 'Please enter valid weight' });
    }
    if (tabData?.statsmatrixid == 1) {
      if (!fields.result) {
        isValid = false;
        setError({ ...error, result: 'Please enter result' });
      }
      if (fields.result) {
        let value = fields.result;
        if (!value) {
          isValid = false;
          setError({ ...error, result: 'Please enter numeric value' });
        }
        if (value) {
          if (
            value < parseFloat(tabData.minimumvalue) ||
            value > parseFloat(tabData.maximumvalue)
          ) {
            isValid = false;
            setError({
              ...error,
              result: `Please enter value in range ${tabData.minimumvalue}-${tabData.maximumvalue} `,
            });
          }
        }
      }
    } else if (tabData?.statsmatrixid == 2) {
      if (!fields.jumbidinfeet) {
        isValid = false;
        setError({
          ...error,
          jumbidinfeet: 'Please select jump distance (Ft) ',
        });
      }
      if (!fields.jumbidininches) {
        isValid = false;
        setError({
          ...error,
          jumbidininches: 'Please select jump distance (In) ',
        });
      }
    } else if (tabData?.statsmatrixid == 3) {
      if (!fields.lbs) {
        isValid = false;
        setError({
          ...error,
          lbs: 'Please enter lbs ',
        });
      }
      if (fields.lbs) {
        let value = parseFloat(fields.lbs);
        if (!value) {
          isValid = false;
          setError({
            ...error,
            lbs: `Please enter value in range ${tabData.minimumvalue}-${tabData.maximumvalue} `,
          });
        }
        if (value) {
          if (
            value < parseFloat(tabData.minimumvalue) ||
            value > parseFloat(tabData.maximumvalue)
          ) {
            isValid = false;
            setError({
              ...error,
              lbs: `Please enter value in range ${tabData.minimumvalue}-${tabData.maximumvalue} `,
            });
          }
        }
      }
      if (!fields.reps) {
        isValid = false;
        setError({
          ...error,
          reps: 'Please enter reps ',
        });
      }
      if (fields.reps) {
        let value = parseFloat(fields.reps);

        if (!value) {
          isValid = false;
          setError({
            ...error,
            reps: `Please enter value in range ${tabData.minimumvalue}-${
              parseFloat(tabData.maximumvalue) - 470
            } `,
          }); //validation range 30
        }
        if (value) {
          if (
            value < parseFloat(tabData.minimumvalue) ||
            value > parseFloat(tabData.maximumvalue) - 470 //validation range 30
          ) {
            isValid = false;
            setError({
              ...error,
              reps: `Please enter value in range ${tabData.minimumvalue}-${
                parseFloat(tabData.maximumvalue) - 470
              } `, //validation range 30
            });
          }
        }
      }
    } else {
      if (!fields.result) {
        isValid = false;
        setError({ ...error, result: 'Please enter result' });
      }
      if (fields.result) {
        let value = fields.result;
        if (!value) {
          isValid = false;
          setError({ ...error, result: 'Please enter numeric value' });
        }
        if (value) {
          if (
            value < parseFloat(tabData.minimumvalue) ||
            value > parseFloat(tabData.maximumvalue)
          ) {
            isValid = false;
            setError({
              ...error,
              result: `Please enter value in range ${tabData.minimumvalue}-${tabData.maximumvalue} `,
            });
          }
        }
      }
    }
    return isValid;
  };
  const successMsg = async () => {
    setIsLoading(false);
    props.onClose();
    swal({
      text: 'Metrics Saved Successfully.',
      icon: 'success',
    }).then((okay) => {
      if (okay || okay == null) {
        props.refreshAfterSave();
      }
    });
  };
  const successUpdatedMsg = async () => {
    props.onClose();
    setIsLoading(false);
    // let variables = {
    //   where: {
    //     userid: getUserInfo().id,
    //   },
    //   data: {
    //     verifiedby: "Not Verified",
    //     isscoutingrequestsent: 0,
    //     verified: 0,
    //     isscoutingrequestupdateOn :null
    //   },
    // };
    // const UpdatestatsRequestData = await fetchGraphMethod(updateScoutingRequestQuery, variables, true)
    swal({
      text: 'Metrics updated Successfully.',
      icon: 'success',
    }).then((okay) => {
      if (okay || okay == null) {
        props.refreshAfterSave();
      }
    });
  };
  const successDeletedMsg = () => {
    props.onClose();
    setIsLoading(false);
    swal({
      text: 'Metrics are deleted successfully.',
      icon: 'success',
    }).then((okay) => {
      if (okay || okay == null) {
        props.refreshAfterSave();
      }
    });
  };
  const showDeleteRecordModalHandler = () => {
    setShowDeleteRecordModal(!showDeleteRecordModal);
  };

  const afterDeleteMetrics = async () => {
    showDeleteRecordModalHandler();
    props.onClose();
    let deleteuploadPhotoVideo = {
      statid: fields.id ? fields.id : null,
    };
    const delVidPhotoObj = {
      deletedRank:
        props?.metricData?.uploadedvideoandphotoStatidMap
          ?.Uploadedvideoandphotos?.[
          props?.metricData?.uploadedvideoandphotoStatidMap
            ?.Uploadedvideoandphotos?.length - 1
        ]?.uploadedrank ?? 0,
      photosVideosAndLinkId: props?.metricData?.photovideoid ?? 0,
      photosVideosAndLinkType: '0',
      postId: 0,
      postarray: [],
      userId: getUserInfo()?.id ?? 0,
      visibleId: 0,
      isstats: 1,
    };
    const saveInPostMapping = await fetchMethod(
      '/api/Statmatrixdetails/deleteStatPhotoVideo',
      deleteuploadPhotoVideo,
      'post',
      true
    );
    const deleteVidPhotoStatus = await fetchMethod(
      '/api/Uploadedvideoandphotos/removePhotosAndVideos',
      delVidPhotoObj,
      'POST',
      true
    );
    if (deleteVidPhotoStatus?.status == 200) {
    }
    swal({
      text: 'Metrics data deleted successfully.',
      icon: 'success',
    }).then((okay) => {
      if (okay || okay == null) {
        props.refreshAfterSave();
      }
    });
  };

  const onDeleteClick = () => {
    let variables = {
      obj: {
        id: fields.id ? fields.id : null,
        userid: getUserInfo().id,
        active: 0,
      },
    };
    props.statMetricDetailsSave({
      query: saveStatmatrixdetails,
      variables,
      afterDeleteMetrics: afterDeleteMetrics,
    });
  };
  const onSaveClick = async (e, flag) => {
    e.preventDefault();
    let isValid = handleValidation();
    let isVidValidation = validate(videoUrl);
    if (
      isValid &&
      (flag == 'save-video' || 'update-video' ? isVidValidation : true)
    ) {
      setIsLoading(true);
      if (flag === 'save-video') {
        const uploadVideo = await onUploadClick();
        let variables = {
          obj: {
            userid: getUserInfo().id,
            active: 1,
            heightinfeetid: fields.heightinfeet,
            heightininchecsid: fields.heightininches,
            weight: parseFloat(fields.weight),
            testdate: fields.date,
            weblink: fields.weblink,
            lasertime: fields.lasertime ? 1 : 0,
            result:
              props.tabData?.tabData?.statsmatrixid == 3
                ? calculate1RM(fields.lbs, fields.reps)
                : props.tabData?.tabData?.statsmatrixid == 2
                ? fields.jumpincentimeter == 0
                  ? '0'
                  : fields.jumpincentimeter.toString()
                : fields.result,
            statmatrixmappingid: props.tabData ? props.tabData.tabId : 0,
            jumbidinfeet: fields.jumbidinfeet ? +fields.jumbidinfeet : null,
            jumbidininches: fields.jumbidininches
              ? +fields.jumbidininches
              : null,
            reps: fields.reps ? fields.reps : null,
            lbs: fields.lbs ? fields.lbs : null,
            verified: 0,
            verifiedbrandlogo: null,
            eventid: null,
            jumpincentimeter: fields.jumpincentimeter
              ? fields.jumpincentimeter
              : null,
            videoname: uploadVideo.obj.videoname,
            isvideoorlink: uploadVideo.obj.isvideoorlink,
            thumbnail: uploadVideo.obj.thumbnail,
            verifiedby: uploadVideo.obj.verifiedby,
            isscoutingrequestsent: uploadVideo.obj.isscoutingrequestsent,
            verified: uploadVideo.obj.verified,
            isscoutingrequestupdateOn:
              uploadVideo.obj.isscoutingrequestupdateOn,
            isedit: uploadVideo.obj.isedit,
          },
        };
        props.saveStatsMatrixDetailsStart({
          query: saveStatmatrixdetails,
          variables,
          videoUploadedMsg: videoUploadedMsg,
          openSaveVideoAfterDataSave: (saveStatmatrixdetails) => {
            // saveMediaDetails(uploadVideo.obj);
            props.openSaveVideoAfterDataSave(
              saveStatmatrixdetails,
              props.tabData
            );
          },
        });
      }
      if (flag === 'update-video') {
        const uploadVideo = await onUploadClick();
        let variables = {
          obj: {
            userid: getUserInfo().id,
            active: 1,
            id: fields.id,
            heightinfeetid: fields.heightinfeet,
            heightininchecsid: fields.heightininches,
            weight: parseFloat(fields.weight),
            testdate: fields.date,
            weblink: fields.weblink,
            lasertime: fields.lasertime ? 1 : 0,
            result:
              props.tabData?.tabData?.statsmatrixid == 3
                ? calculate1RM(fields.lbs, fields.reps)
                : props.tabData?.statsmatrixid == 2
                ? fields.jumpincentimeter == 0
                  ? '0'
                  : fields.jumpincentimeter.toString()
                : fields.result,
            statmatrixmappingid: props.tabData ? props.tabData.tabId : 0,
            jumbidinfeet: fields.jumbidinfeet ? +fields.jumbidinfeet : null,
            reps: fields.reps ? fields.reps : null,
            lbs: fields.lbs ? fields.lbs : null,
            jumbidininches: fields.jumbidininches
              ? +fields.jumbidininches
              : null,
            verified: 0,
            verifiedbrandlogo: null,
            eventid: null,
            jumpincentimeter: fields.jumpincentimeter
              ? fields.jumpincentimeter
              : null,
            videoname: uploadVideo.obj.videoname,
            isvideoorlink: uploadVideo.obj.isvideoorlink,
            thumbnail: uploadVideo.obj.thumbnail,
            verifiedby: uploadVideo.obj.verifiedby,
            isscoutingrequestsent: uploadVideo.obj.isscoutingrequestsent,
            verified: uploadVideo.obj.verified,
            isscoutingrequestupdateOn:
              uploadVideo.obj.isscoutingrequestupdateOn,
            isedit: uploadVideo.obj.isedit,
          },
        };
        props.saveStatsMatrixDetailsStart({
          query: saveStatmatrixdetails,
          variables,
          videoUploadedMsg: videoUploadedMsgUpdated,
          openSaveVideoAfterDataSave: (saveStatmatrixdetails) => {
            //props.onClose();
            // saveMediaDetails(uploadVideo.obj);
            props.openSaveVideoAfterDataSave(
              saveStatmatrixdetails,
              props.tabData
            );
          },
        });
      }
      if (flag === 'save-done') {
        let variables = {
          obj: {
            userid: getUserInfo().id,
            active: 1,
            heightinfeetid: fields.heightinfeet,
            heightininchecsid: fields.heightininches,
            weight: parseFloat(fields.weight),
            testdate: fields.date,
            weblink: fields.weblink,
            lasertime: fields.lasertime ? 1 : 0,
            result:
              props.tabData?.tabData?.statsmatrixid == 3
                ? calculate1RM(fields.lbs, fields.reps)
                : props.tabData?.tabData?.statsmatrixid == 2
                ? fields.jumpincentimeter == 0
                  ? '0'
                  : fields.jumpincentimeter.toString()
                : fields.result,
            statmatrixmappingid: props.tabData ? props.tabData.tabId : 0,
            jumbidinfeet: fields.jumbidinfeet ? +fields.jumbidinfeet : null,
            reps: fields.reps ? fields.reps : null,
            lbs: fields.lbs ? fields.lbs : null,
            jumbidininches: fields.jumbidininches
              ? +fields.jumbidininches
              : null,
            verified: 0,
            verifiedby: 'Not verified',
            isscoutingrequestsent: 0,
            isedit: 1,
            verifiedbrandlogo: null,
            isscoutingrequestupdateOn: null,
            eventid: null,
            jumpincentimeter: fields.jumpincentimeter
              ? fields.jumpincentimeter
              : null,
            videoname: !!fields?.videonameStat ? fields?.videonameStat : null,
            isvideoorlink: !!fields?.isvideoorlinkStat
              ? fields?.isvideoorlinkStat
              : null,
            thumbnail: !!fields?.thumbnailStat ? fields?.thumbnailStat : null,
          },
        };
        props.saveStatsMatrixDetailsStart({
          query: saveStatmatrixdetails,
          variables,
          successMsg: successMsg,
        });
      }
      if (flag == 'update-done') {
        let variables = {
          obj: {
            userid: getUserInfo().id,
            active: 1,
            id: fields.id,
            heightinfeetid: fields.heightinfeet,
            heightininchecsid: fields.heightininches,
            weight: parseFloat(fields.weight),
            testdate: fields.date,
            weblink: fields.weblink,
            lasertime: fields.lasertime ? 1 : 0,
            result:
              props.tabData?.tabData?.statsmatrixid == 3
                ? calculate1RM(fields.lbs, fields.reps)
                : props.tabData?.statsmatrixid == 2
                ? fields.jumpincentimeter == 0
                  ? '0'
                  : fields.jumpincentimeter.toString()
                : fields.result,
            statmatrixmappingid: props.tabData ? props.tabData.tabId : 0,
            jumbidinfeet: fields.jumbidinfeet ? +fields.jumbidinfeet : null,
            reps: fields.reps ? fields.reps : null,
            lbs: fields.lbs ? fields.lbs : null,
            jumbidininches: fields.jumbidininches
              ? +fields.jumbidininches
              : null,
            verified: 0,
            verifiedby: 'Not verified',
            isscoutingrequestsent: 0,
            isscoutingrequestupdateOn: null,
            isedit: 1,
            verifiedbrandlogo: null,
            eventid: null,
            jumpincentimeter: fields.jumpincentimeter
              ? fields.jumpincentimeter
              : null,
            videoname: !!fields?.videonameStat ? fields?.videonameStat : null,
            isvideoorlink: !!fields?.isvideoorlinkStat
              ? fields?.isvideoorlinkStat
              : null,
            thumbnail: !!fields?.thumbnailStat ? fields?.thumbnailStat : null,
          },
        };
        props.saveStatsMatrixDetailsStart({
          query: saveStatmatrixdetails,
          variables,
          successUpdatedMsg: successUpdatedMsg,
        });
      }
      if (flag === 'save-another') {
        let variables = {
          obj: {
            userid: getUserInfo().id,
            active: 1,
            id: fields.id,
            heightinfeetid: fields.heightinfeet,
            heightininchecsid: fields.heightininches,
            weight: parseFloat(fields.weight),
            testdate: fields.date,
            lasertime: fields.lasertime ? 1 : 0,
            result:
              props.tabData?.tabData?.statsmatrixid == 3
                ? calculate1RM(fields.lbs, fields.reps)
                : props.tabData?.tabData?.statsmatrixid == 2
                ? fields.jumpincentimeter == 0
                  ? '0'
                  : fields.jumpincentimeter.toString()
                : fields.result,
            statmatrixmappingid: props.tabData ? props.tabData.tabId : 0,
            jumbidinfeet: fields.jumbidinfeet ? +fields.jumbidinfeet : null,
            reps: fields.reps ? fields.reps : null,
            lbs: fields.lbs ? fields.lbs : null,
            jumbidininches: fields.jumbidininches
              ? +fields.jumbidininches
              : null,
            verified: 0,
            verifiedbrandlogo: null,
            eventid: null,
            jumpincentimeter: fields.jumpincentimeter
              ? fields.jumpincentimeter
              : null,
          },
        };
        props.saveStatsMatrixDetailsStart({
          query: saveStatmatrixdetails,
          variables,
          successUpdatedMsg: successUpdatedMsg,
        });
      }
      if (flag === 'save') {
        let variables = {
          obj: {
            userid: getUserInfo().id,
            active: 1,
            id: fields.id,
            heightinfeetid: fields.heightinfeet,
            heightininchecsid: fields.heightininches,
            weight: fields.weight,
            testdate: fields.date,
            lasertime: fields.lasertime ? 1 : 0,
            result:
              props.tabData?.tabData?.statsmatrixid == 3
                ? calculate1RM(fields.lbs, fields.reps)
                : props.tabData?.tabData?.statsmatrixid == 2
                ? fields.jumpincentimeter == 0
                  ? '0'
                  : fields.jumpincentimeter.toString()
                : fields.result,
            statmatrixmappingid: props.tabData ? props.tabData.tabId : 0,
            jumbidinfeet: fields.jumbidinfeet ? +fields.jumbidinfeet : null,
            reps: fields.reps ? fields.reps : null,
            lbs: fields.lbs ? fields.lbs : null,
            jumbidininches: fields.jumbidininches
              ? +fields.jumbidininches
              : null,
            verified: 0,
            verifiedbrandlogo: null,
            eventid: null,
            jumpincentimeter: fields.jumpincentimeter
              ? fields.jumpincentimeter
              : null,
          },
        };
        props.saveStatsMatrixDetailsStart({
          query: saveStatmatrixdetails,
          variables,
          successUpdatedMsg: successUpdatedMsg,
        });
      }
      if (flag === 'delete') {
        let variables = {
          obj: {
            active: 0,
            id: fields.id,
          },
        };
        props.saveStatsMatrixDetailsStart({
          query: saveStatmatrixdetails,
          variables,
          successDeletedMsg: successDeletedMsg,
        });
        let deleteuploadPhotoVideo = {
          statid: fields.id,
        };
        const saveInPostMapping = await fetchMethod(
          '/api/Statmatrixdetails/deleteStatPhotoVideo',
          deleteuploadPhotoVideo,
          'post',
          true
        );
      }
      sendStatsNotification();
      // props.saveOnboardingStart({
      //   query: saveOnboardingVitalsDataQuery,
      //   variables: {
      //     obj: {
      //       userid: getUserInfo() ? getUserInfo().id : null,
      //       id: fields.onboardingId ? fields.onboardingId : null,
      //       heightinfeet: fields.heightinfeet,
      //       heightininches: fields.heightininches,
      //       weight: +fields.weight,
      //       updatedby: getUserInfo().id
      //     },
      //   },
      //   nextScreen: () => { }
      // });
    }
  };
  const videoUploadedMsg = (response, resMatVar) => {
    setIsLoading(false);
    swal({
      text: 'Metrics Saved Successfully.',
      icon: 'success',
    }).then(async (okay) => {
      if (okay || okay == null) {
        let res = response?.data?.data?.saveStatmatrixdetails;
        const savePostDetails = await saveMediaDetails(res, resMatVar);
        props.onClose();
        // props.refreshAfterSave()
      }
    });
  };
  const videoUploadedMsgUpdated = (response, resMatVar) => {
    setIsLoading(false);
    swal({
      text: 'Metrics Updated Successfully.',
      icon: 'success',
    }).then(async (okay) => {
      if (okay || okay == null) {
        let res = response?.data?.data?.saveStatmatrixdetails;
        const savePostDetails = await saveMediaDetails(res, resMatVar);
        props.onClose();
      }
    });
  };
  const saveMediaDetails = async (resValue, matVar) => {
    let obj = {
      userId: getUserInfo() ? getUserInfo().id : 0,
      photosAndVideosArray: [
        {
          name: resValue.videoname,
          type: selectedFile ? 1 : 2,
          position: null,
          jerseyno: null,
          jerseycolor: null,
          albumId: null,
          tagId: null,
          originalname: resValue.videoname,
          videophotosuploadedtype: 'Uploaded',
          type: selectedFile ? (isImages == true ? 0 : 1) : 2,
        },
      ],
      statid: resValue.id,
      isvisible: 1, //it should be 1 by default
      thumbnail: !!vidThumbnail
        ? vidThumbnail
        : !!matVar?.variables?.obj?.thumbnail
        ? matVar?.variables?.obj?.thumbnail
        : '', //this key is added as per change doc vimeo thumbnail
      uploadedfrom: 'stats', //this key is added as per change doc in sept 15/9/2022
    };
    const res = await fetchMethod(
      '/api/Uploadedvideoandphotos/addPhotosAndVideos',
      obj,
      'post',
      true
    );

    //this is done to save the id in stats metrix detail
    if (res?.status == 200) {
      const finalObj = {
        obj: {
          id: resValue?.id,
          photovideoid: res?.data?.response?.data?.id ?? null,
        },
      };
      const resStatsDetail = await fetchGraphMethod(
        saveStatmatrixdetails,
        finalObj,
        true
      );
      if (resStatsDetail?.status == 200) {
        props.onClose();
        props.refreshAfterSave();
        setIsLoading(false);
      }
    }
  };
  //fun for checking video
  const videoTypeDetect = (data) => {
    const dataLower = data?.name?.toLowerCase();
    if (
      dataLower?.includes('3g2') ||
      dataLower?.includes('3gp') ||
      dataLower?.includes('avi') ||
      dataLower?.includes('flv') ||
      dataLower?.includes('m4v') ||
      dataLower?.includes('mkv') ||
      dataLower?.includes('mov') ||
      dataLower?.includes('mp4') ||
      dataLower?.includes('mpg') ||
      dataLower?.includes('mts') ||
      dataLower?.includes('m2ts') ||
      dataLower?.includes('mpeg') ||
      dataLower?.includes('wmv')
    ) {
      return true;
    } else {
      return false;
    }
  };
  const onSaveFileHandler = async (thumb, urlVid) => {
    if (selectedFile) {
      try {
        setLoading(true);
        let formData = new FormData();
        formData.append('file', selectedFile);
        const response = await axios({
          url: videoTypeDetect(selectedFile) ? videoUploadUrl : imageUploadUrl, //videoDetect(props.selectedFile) func for change api vor video
          method: 'POST',
          data: formData,
          headers: {
            access_token: getAuthToken(),
          },
        });
        if (response && response.status === 200) {
          const pathUrl = response.data.result?.files?.file[0]?.name
            ? response.data.result.files.file[0].name
            : '';
          setFilePath(pathUrl);
          setLoading(false);
          let variables = {
            obj: {
              userid: getUserInfo().id,
              active: 1,
              id: props.metricData ? props.metricData.id : 0,
              videoname: pathUrl,
              isvideoorlink: isImages == true ? 3 : 1,
              thumbnail: !!vidThumbnail ? vidThumbnail : !!thumb ? thumb : null, //this key is added as per vimeo thumbnail
              verifiedby: 'Not verified',
              isscoutingrequestsent: 0,
              verified: 0,
              isscoutingrequestupdateOn: null,
              isedit: 1,
            },
          };
          return variables;
        } else {
          setLoading(false);
          // messagePopup("", "Upload  Failed", "error");
          toastr.error('Upload  Failed', '', {
            progressBar: true,
            timeOut: 2000,
            closeButton: true,
            positionClass: 'toast-top-full-width',
            showEasing: 'swing',
            fadeIn: 40000,
            preventDuplicates: true,
          });
        }
      } catch (error) {
        setLoading(false);
        // messagePopup("", "Upload  Failed", "error");
        toastr.error('Upload  Failed', '', {
          progressBar: true,
          timeOut: 2000,
          closeButton: true,
          positionClass: 'toast-top-full-width',
          showEasing: 'swing',
          fadeIn: 40000,
          preventDuplicates: true,
        });
      }
      // props.onCloseVideoDesc()
      // props.mainPopUp()
    } else {
      let variables = {
        obj: {
          userid: getUserInfo().id,
          active: 1,
          id: props.metricData ? props.metricData.id : 0,
          videoname: !!urlVid ? urlVid : '',
          videodescription: videoDesc ? videoDesc : '',
          isvideoorlink: 2,
          thumbnail: !!thumb ? thumb : null, //thi key is added a per
          // change doc vimeo
        },
      };
      return variables;
    }
  };
  const openUrlVideo = (videoUrl, linkType) => {
    if (videoUrl) {
      // window.open(videoUrl, "_blank");
      //below func is for videoPop change req 1/8/2022
      setVidID(videoUrl);
      setIsLink(linkType);
      setTimeout(() => {
        setIsVidPop(true);
      }, 500);
    }
  };
  const onUploadClick = async () => {
    if (Object.keys(error).length == 0) {
      //below if condition is added as per vimeo thumbnail
      let getthumbnail = '',
        urlVid = '';
      if (!!videoUrl) {
        if (videoUrl?.includes('vimeo')) {
          const vidID = videoUrl?.split('/');
          let _resThumb = await getVimeoThumb(vidID?.[vidID?.length - 1]);
          setVidThumbnail(_resThumb);
          getthumbnail = _resThumb;
        }
      }
      if (selectedFile || videoUrl) {
        if (videoUrl?.includes('youtu.be')) {
          const dataSplit = videoUrl?.split('/'),
            url = dataSplit[dataSplit?.length - 1];
          setVideoUrl(`https://youtube.com/embed/${url}`);
          urlVid = `https://youtube.com/embed/${url}`;
        } else if (videoUrl?.includes('youtube')) {
          const dataSplit = videoUrl?.split('='),
            url = dataSplit[dataSplit?.length - 1];
          setVideoUrl(`https://youtube.com/embed/${url}`);
          urlVid = `https://youtube.com/embed/${url}`;
        } else if (videoUrl?.includes('vimeo')) {
          const dataSplit = videoUrl?.split('/'),
            url = dataSplit[dataSplit?.length - 1];
          setVideoUrl(`https://player.vimeo.com/video/${url}`);
          urlVid = `https://player.vimeo.com/video/${url}`;
        } else if (videoUrl?.includes('hudl')) {
          const dataSplit = videoUrl?.split('video'),
            url = dataSplit[dataSplit?.length - 1];
          setVideoUrl(`https://www.hudl.com/embed/video${url}`);
          urlVid = `https://www.hudl.com/embed/video${url}`;
        }
        const uploadData = await onSaveFileHandler(
          !!getthumbnail ? getthumbnail : '',
          !!urlVid ? urlVid : ''
        );
        return uploadData;
      } else {
        // messagePopup("", "Please add a video or photo", "error");
        toastr.error('Please add a video or photo', '', {
          progressBar: true,
          timeOut: 2000,
          closeButton: true,
          positionClass: 'toast-top-full-width',
          showEasing: 'swing',
          fadeIn: 40000,
          preventDuplicates: true,
        });
      }
    } else {
      return;
    }
  };
  const onSelectFile = (e) => {
    const cloneField = fields;
    const file = e.target.files[0];
    const fileArr = file.name.split('.');
    fileArr[0] = new Date().getTime();
    //////////////
    ///below code is check over video format and uploading size
    let fileExtension = file.name
      .split('.')
      [file.name.split('.').length - 1]?.toLowerCase();
    if (file.type.includes('video')) {
      if (
        fileExtension != 'mp4' &&
        fileExtension != '3g2' &&
        fileExtension != '3gp' &&
        fileExtension != 'avi' &&
        fileExtension != 'flv' &&
        fileExtension != 'm4v' &&
        fileExtension != 'mkv' &&
        fileExtension != 'mov' &&
        fileExtension != 'mpg' &&
        fileExtension != 'mts' &&
        fileExtension != 'm2ts' &&
        fileExtension != 'mpeg' &&
        fileExtension != 'wmv'
      ) {
        toastr.error(
          'Video format must be 3g2, 3gp, avi, flv, m4v, mkv, mov, mp4, mpg, mts, m2ts, mpeg, wmv',
          '',
          {
            progressBar: true,
            timeOut: 2000,
            closeButton: true,
            positionClass: 'toast-top-full-width',
            showEasing: 'swing',
            fadeIn: 40000,
            preventDuplicates: true,
          }
        );
        return;
      } else {
        const fileSize = parseInt(e?.target?.files?.[0]?.size) / (1024 * 1024);
        if (parseFloat(fileSize).toFixed(3) > 150) {
          toastr.error('Max Limit 150MB', '', {
            progressBar: true,
            timeOut: 2000,
            closeButton: true,
            positionClass: 'toast-top-full-width',
            showEasing: 'swing',
            fadeIn: 40000,
            preventDuplicates: true,
          });
          return;
        }
      }
    }
    //////////////////
    const newFile = new File([file], fileArr.join('.'), {
      type: file.type,
      lastModified: file.lastModified,
    });
    setSelectedFile(newFile);
    seSelectedFileUrl(URL.createObjectURL(newFile));
    setSelectedFileName(newFile.name);
    setIsImages(newFile.type.includes('image'));
    setErrorVid('');
    cloneField['videonameStat'] = newFile?.name ?? '';
    setFields({ ...cloneField });
  };
  const onCloseVideoDescription = () => {
    setSelectedFile(null);
    seSelectedFileUrl('');
    setSelectedFileName('');
    setShowVideoDesc(false);
    setVideoUrl('');
  };
  const closeModalWithoutUploadingVideo = () => {
    props.onClose();
    // props.refreshAfterSave() // reloads the page -- not required on cancel button
  };
  const validate = (value) => {
    if (value != '') {
      if (!validStatVideoURL(value)) {
        setErrorVid('Please enter a valid Youtube, Hudl or vimeo link');
        return false;
      }
      //below check is over link of hudl
      else if (value?.includes('hudl') && !value?.includes('video')) {
        setErrorVid('Please enter a valid Youtube, Hudl or vimeo link');
        return false;
      } else {
        setErrorVid('');
        return true;
      }
    } else {
      setErrorVid('');
      return true;
    }
  };
  const photoVideoChange = (e, field) => {
    let cloneField = fields;
    if (field == 'vidlink') {
      cloneField['videolinkStat'] = e?.target?.value;
      cloneField['videonameStat'] = '';
      setVideoUrl(e?.target?.value);
      setSelectedFile(null);
      setSelectedFileName(null);
      setErrorVid('');
      setFields({ ...cloneField });
    } else {
      if (!!e?.target?.files?.[0]) {
        onSelectFile(e);
        cloneField['videolinkStat'] = '';
        setFields({ ...cloneField });
        setVideoUrl('');
      } else {
        setSelectedFile(null);
        setSelectedFileName(null);
        cloneField['videonameStat'] = '';
        setFields({ ...cloneField });
      }
    }
  };
  return (
    <div
      className="modal fade common-modal form-modal stats-modal show"
      id="Add60YardDataModalone"
      style={{ display: 'block' }}
    >
      <Dialog
        open={showDeleteVideoModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            setShowDeleteModal(false);
          }
        }}
        className="matDialogCustom"
      >
        <DeleteVideoModal
          onClose={() => setShowDeleteModal(false)}
          onDelete={(e) => {
            e.preventDefault();
            setShowDeleteModal(false);
            onSaveClick(e, 'delete');
          }}
          deleteMsg="Are you sure you want to delete the data?"
        />
      </Dialog>
      <BackdropLoader open={props.loading || isLoading} />
      <div
        className="modal-dialog modal-dialog-centered editprofile-common-form"
        role="document"
      >
        <form className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {props.isEdit == true ? 'Edit' : 'Add'} {tabData?.tabname}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {/* <div className="fieldBox">
              <label>Performance Date<sup>*</sup></label>
              <input
                type="date"
                value={fields.date}
                placeholder="09/14/2020"
                onChange={(e) => onChangeHandler(e, "date")}
              />
              <div className="error">{error.date ? error.date : ""} </div>
            </div> */}
            <div
              className={
                tabData?.statsmatrixid == 1 ||
                tabData?.statsmatrixid == 2 ||
                tabData?.statsmatrixid == 3
                  ? 'fieldBox flexBox stat-three-col'
                  : 'fieldBox flexBox'
              }
            >
              <div className="half">
                <label>
                  Performance Date <sup>*</sup>
                </label>
                <input
                  type="date"
                  value={fields.date}
                  placeholder="09/14/2020"
                  onChange={(e) => onChangeHandler(e, 'date')}
                />
                <div className="error">{error.date ? error.date : ''} </div>
              </div>
              {console.log('tabData ==>>', tabData)}
              {tabData
                ? getMetricFields(
                    tabData,
                    onChangeHandler,
                    fields,
                    error,
                    jumpList
                  )
                : ''}
            </div>
            <div className="fieldBox flexBox">
              <div className="half">
                <label>
                  Enter Height (Ft) <sup>*</sup>
                </label>

                <Select
                  value={fields.heightinfeet}
                  id="heightinfeet"
                  onChange={(e) => onChangeHandler(e, 'heightinfeet')}
                  style={{ width: '100%' }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {props.feetDetails.footList &&
                    props.feetDetails.footList.data &&
                    props.feetDetails.footList.data.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.heightvalue}
                      </MenuItem>
                    ))}
                </Select>
                <div className="error">
                  {error.heightinfeet ? error.heightinfeet : ''}{' '}
                </div>
              </div>
              <div className="half">
                <label>
                  Enter Height (In) <sup>*</sup>
                </label>
                <Select
                  value={fields.heightininches}
                  id="heightininches"
                  onChange={(e) => onChangeHandler(e, 'heightininches')}
                  style={{ width: '100%' }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {props.feetDetails.inchList &&
                    props.feetDetails.inchList.data &&
                    props.feetDetails.inchList.data.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.inchvalue}
                      </MenuItem>
                    ))}
                </Select>
                <div className="error">
                  {error.heightininches ? error.heightininches : ''}{' '}
                </div>
              </div>
            </div>
            <div className="fieldBox flexBox">
              <div className="half">
                <label>
                  Enter Body Weight (Lbs) <sup>*</sup>
                </label>
                <input
                  type="number"
                  placeholder="210"
                  value={fields.weight}
                  onChange={(e) => onChangeHandler(e, 'weight')}
                />
                <div className="error">{error.weight ? error.weight : ''} </div>
              </div>
            </div>
            <div className="fieldBox">
              <div className="stats-uploadbox">
                <label
                  htmlFor="uploadvideophoto"
                  className="uploadbtn"
                  onClick={() =>
                    !!fields?.videolinkStat
                      ? swal({
                          title: '',
                          text: 'You can only add one media option per submission',
                          icon: 'warning',
                        })
                      : null
                  }
                >
                  Upload image / video{' '}
                </label>
                {fields?.videonameStat && (
                  <button
                    className="video-trashbtn"
                    type={'button'}
                    onClick={() => {
                      setSelectedFile('');
                      setSelectedFileName('');
                      photoVideoChange('', 'vidPhoto');
                    }}
                  >
                    Delete
                  </button>
                )}
                {!!fields?.videonameStat && (
                  <div className="error text-center">
                    {fields?.videonameStat ?? ''}
                  </div>
                )}
                <input
                  id="uploadvideophoto"
                  hidden=""
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*,image/png,image/jpeg, image.jpg"
                  onChange={(e) => {
                    photoVideoChange(e, 'vidPhoto');
                    e.currentTarget.value = null;
                  }}
                  disabled={!!fields?.videolinkStat ? true : false}
                />
              </div>
            </div>
            <div className="fieldBox text-center">Or</div>
            <div className="fieldBox">
              <label>Add a video from YouTube, hudl and vimeo</label>
              <input
                onClick={() =>
                  !!fields?.videonameStat
                    ? swal({
                        title: '',
                        text: 'You can only add one media option per submission',
                        icon: 'warning',
                      })
                    : null
                }
                type="text"
                placeholder="Enter Video URL"
                value={fields?.videolinkStat ?? ''}
                onChange={(e) => photoVideoChange(e, 'vidlink')}
                // disabled={!!fields?.videonameStat ? true: false}
              />
            </div>
            {!!errorVid && <div className={'error'}>{errorVid}</div>}
            <div className="fieldBox">
              <label>Weblink</label>
              <div className={'sf-link-with-closebtn'}>
                <input
                  type="url"
                  placeholder="e.g https://www.sportsforce.io"
                  value={fields.weblink}
                  onChange={(e) => onChangeHandler(e, 'weblink')}
                />
                {fields.weblink?.length > 0 && (
                  <button
                    className={'btn'}
                    type={'button'}
                    onClick={(e) => emptyWeblink()}
                  >
                    <svg
                      id="close"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.047"
                      height="14.046"
                      viewBox="0 0 14.047 14.046"
                    >
                      <g id="Group_2347" data-name="Group 2347">
                        <path
                          id="Path_2319"
                          data-name="Path 2319"
                          d="M10.637,9.311A.939.939,0,1,0,9.31,10.638l5.421,5.421L9.309,21.48a.939.939,0,1,0,1.327,1.327l5.422-5.421,5.421,5.421a.939.939,0,1,0,1.327-1.327l-5.421-5.421,5.421-5.421a.939.939,0,1,0-1.327-1.327l-5.421,5.421L10.637,9.31Z"
                          transform="translate(-9.034 -9.036)"
                          fill="#c0321f"
                        />
                      </g>
                    </svg>
                  </button>
                )}
              </div>
              <div className="error">{error.weblink ? error.weblink : ''} </div>
            </div>
          </div>
          {!props.isEdit ? (
            <div className="modal-footer">
              {/* <button
                type="button"
                className="btn gray"
                onClick={(e) => onSaveClick(e, "save-video")}
              >
                Save & Add PHOTO / VIDEO
              </button> */}
              <button
                type="button"
                className="btn green"
                onClick={(e) => {
                  onSaveClick(
                    e,
                    selectedFileName || videoUrl ? 'save-video' : 'save-done'
                  );
                }}
              >
                Done
              </button>
            </div>
          ) : (
            <div className="modal-footer">
              <button
                type={'button'}
                className="btn"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#StatsAddYardDataTrashModal"
                onClick={showDeleteRecordModalHandler}
              >
                Delete
              </button>
              {/* <button
                type="button"
                className="btn gray"
                onClick={(e) => onSaveClick(e, "update-video")}
              >
                Update & Add PHOTO / VIDEO
              </button> */}
              <button
                type="button"
                className="btn green"
                onClick={(e) =>
                  onSaveClick(
                    e,
                    selectedFileName || videoUrl
                      ? 'update-video'
                      : 'update-done'
                  )
                }
              >
                Update
              </button>
              <button
                type={'button'}
                className="btn"
                data-dismiss="modal"
                data-toggle="modal"
                onClick={props.onClose}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
      </div>
      <Dialog
        open={showVideoDesc}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            onCloseVideoDescription();
          }
        }}
        className="matDialogCustom matDialogCustomOverlay"
      >
        <UploadVideoDescritpionModal
          onCloseVideoDesc={onCloseVideoDescription}
          {...props}
          mainPopUp={props.onClose}
          selectedFile={selectedFile}
          selectedFileUrl={selectedFileUrl}
          videoUrl={videoUrl}
          vidThumbnail={vidThumbnail} //this props is added as per vimeo thumbnail
          isImages={isImages}
        />
      </Dialog>
      <Dialog
        open={showDeleteRecordModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            showDeleteRecordModalHandler();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteRecordModal
          onClose={showDeleteRecordModalHandler}
          onDelete={onDeleteClick}
        />
      </Dialog>
    </div>
  );
};

let AddYardModalMetrics = (props) => {
  const [fields, setFields] = useState({
    id: props.isEdit ? (props.metricData ? props.metricData.id : 0) : 0,
    date: props.isEdit
      ? props.metricData && props.metricData.testdate
        ? props.metricData.testdate
        : null
      : new Date().toISOString().substr(0, 10),
    heightinfeet: props.isEdit
      ? props.metricData && props.metricData.heightinfeetid
        ? props.metricData.heightinfeetid
        : ''
      : '',
    heightininches: props.isEdit
      ? props.metricData && props.metricData.heightininchecsid
        ? props.metricData.heightininchecsid
        : ''
      : '',
    weight: props.isEdit
      ? props.metricData && props.metricData.weight
        ? props.metricData.weight
        : ''
      : '',
    lasertime: props.isEdit
      ? props.metricData && props.metricData.lasertime
        ? props.metricData.lasertime
        : ''
      : false,
    result: props.isEdit
      ? props.metricData && props.metricData.result
        ? props.metricData.result
        : ''
      : '',
    jumbidinfeet: props.isEdit
      ? props.metricData && props.metricData.jumbidinfeet
        ? props.metricData.jumbidinfeet
        : ''
      : null,
    jumbidininches: props.isEdit
      ? props.metricData && props.metricData.jumbidininches
        ? props.metricData.jumbidininches
        : ''
      : null,
    lbs: props.isEdit
      ? (
          props.metricData && props.metricData.result
            ? props.metricData.result
            : null
        )
        ? props.metricData.lbs
          ? props.metricData.lbs
          : null
        : null
      : null,
    reps: props.isEdit
      ? (
          props.metricData && props.metricData.result
            ? props.metricData.result
            : ''
        )
        ? props.metricData.reps
          ? props.metricData.reps
          : null
        : null
      : null,
    jumpincentimeter: 0,
  });
  const [tabData, setTabData] = useState();
  const [error, setError] = useState({});
  const [jumpList, setJumpList] = useState({
    feetList: [],
    inchList: [],
  });
  const [showDeleteRecordModal, setShowDeleteRecordModal] = useState(false);
  useEffect(() => {
    setFields({
      ...fields,
      id: props.isEdit
        ? props.metricData && props.metricData.id
          ? props.metricData.id
          : 0
        : 0,
      date: props.isEdit
        ? props.metricData && props.metricData.testdate
          ? props.metricData.testdate
          : null
        : new Date().toISOString().substr(0, 10),
      lasertime: props.isEdit
        ? props.metricData && props.metricData.lasertime
          ? props.metricData.lasertime
          : ''
        : false,
      result: props.isEdit
        ? props.metricData && props.metricData.result
          ? props.metricData.result
          : ''
        : '',
      jumbidinfeet: props.isEdit
        ? props.metricData && props.metricData.jumbidinfeet
          ? props.metricData.jumbidinfeet
          : ''
        : '',
      jumbidininches: props.isEdit
        ? props.metricData && props.metricData.jumbidininches
          ? props.metricData.jumbidininches
          : ''
        : '',
    });
  }, [props.isEdit]);
  useEffect(() => {
    if (props.tabData.tabData) {
      setTabData(props.tabData.tabData);
    }
  }, [props.tabData]);

  useEffect(() => {
    props.footListStart({
      query: getAllHeightInFeetsQuery,
      variables: null,
    });
    props.inchListStart({
      query: getAllHeightInInchesQuery,
      variables: null,
    });
    props.getJumpData({
      query: getAllJumpData,
      variables: null,
    });

    getOnboardingVitals();
  }, []);
  useEffect(() => {
    if (
      props.allJumpDataList &&
      props.allJumpDataList.data &&
      props.allJumpDataList.data.allJumpData
    ) {
      if (
        props.allJumpDataList.data.allJumpData.allFeet &&
        props.allJumpDataList.data.allJumpData.allFeet.Feet &&
        props.allJumpDataList.data.allJumpData.allInches &&
        props.allJumpDataList.data.allJumpData.allInches.Inches
      ) {
        setJumpList({
          ...jumpList,
          feetList: props.allJumpDataList.data.allJumpData.allFeet.Feet,
          inchList: props.allJumpDataList.data.allJumpData.allInches.Inches,
        });
      }
    }
  }, [props.allJumpDataList]);
  useEffect(() => {
    if (
      props.onBoardingDetails &&
      props.onBoardingDetails.onboarding &&
      props.onBoardingDetails.onboarding.data
    ) {
      const { id, heightinfeet, heightininches, weight } =
        props.onBoardingDetails.onboarding.data;
      let fieldsT = fields;
      fieldsT['onboardingId'] = id;
      fieldsT['heightinfeet'] = fieldsT['heightinfeet']
        ? fieldsT['heightinfeet']
        : heightinfeet == null
        ? ''
        : heightinfeet;
      fieldsT['heightininches'] = fieldsT['heightininches']
        ? fieldsT['heightininches']
        : heightininches == null
        ? ''
        : heightininches;
      fieldsT['weight'] = fieldsT['weight']
        ? fieldsT['weight']
        : weight == null
        ? ''
        : weight;
      setFields({ ...fieldsT });
    }
  }, [props.onBoardingDetails]);

  const showDeleteRecordModalHandler = () => {
    setShowDeleteRecordModal(!showDeleteRecordModal);
  };
  const onChangeHandler = (e, field) => {
    let fieldsT = fields;
    if (field == 'lasertime') {
      fieldsT[field] = e.target.checked;
    } else {
      const updatedValue = e.target.value;
      if (field === 'result' || field === 'lbs' || field === 'reps') {
        const numArr = updatedValue.split('.');
        if (numArr[1]) {
          if (numArr[1].length <= 2) {
            fieldsT[field] = updatedValue;
          }
        } else {
          fieldsT[field] = updatedValue;
        }
      } else {
        fieldsT[field] = updatedValue;
      }
    }

    if (field == 'jumbidininches' || field == 'jumbidinfeet') {
      let feetValue = 0,
        inchvalue = 0;
      jumpList.feetList &&
        jumpList.feetList.map((item) => {
          if (item.id == fieldsT.jumbidinfeet) {
            feetValue = item.feetvalue;
          }
        });
      jumpList.inchList &&
        jumpList.inchList.map((item) => {
          if (item.id == fieldsT.jumbidininches) {
            inchvalue = item.inchvalue;
          }
        });
      fieldsT['jumpincentimeter'] = feetValue * 30.48 + inchvalue * 2.54;
    }

    setError({});
    setFields({ ...fieldsT });
  };
  const getOnboardingVitals = () => {
    props.getOnboardingStart({
      query: getvitalDataQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
  };
  const handleValidation = () => {
    let isValid = true;
    if (!fields.date) {
      setError({ ...error, date: 'Please Enter a date' });
      isValid = false;
    }
    let selectedDate = new Date(fields.date);
    if (selectedDate.getTime() > new Date().getTime()) {
      isValid = false;
      setError({ ...error, date: 'Performance Date can not be a future date' });
    }
    if (!fields.heightinfeet) {
      isValid = false;
      setError({ ...error, heightinfeet: 'Please choose height (Ft)' });
    }
    if (!fields.heightininches) {
      isValid = false;
      setError({ ...error, heightininches: 'Please choose height (In)' });
    }
    if (!fields.weight) {
      isValid = false;
      setError({ ...error, weight: 'Please enter weight' });
    }
    if (fields.weight <= 0 || fields.weight > 999) {
      isValid = false;
      setError({ ...error, weight: 'Please enter valid weight' });
    }
    if (tabData?.statsmatrixid == 1) {
      if (!fields.result) {
        isValid = false;
        setError({ ...error, result: 'Please enter result' });
      }
      if (fields.weight) {
        let value = parseFloat(fields.weight);
        var regexp = /^\d+(\.\d{1,2})?$/;
        if (!value) {
          isValid = false;
          setError({ ...error, weight: 'Please enter numeric value' });
        }
        if (!regexp.test(fields.weight)) {
          isValid = false;
          setError({ ...error, weight: 'Please enter numeric value only.' });
        }
      }
      if (fields.result) {
        let value = fields.result;
        var regexp = /^\d+(\.\d{1,2})?$/;
        if (!value) {
          isValid = false;
          setError({ ...error, result: 'Please enter numeric value' });
        }
        if (!regexp.test(fields.result)) {
          isValid = false;
          setError({ ...error, result: 'Please enter numeric value only.' });
        }
        if (value) {
          if (
            value < parseFloat(tabData.minimumvalue) ||
            value > parseFloat(tabData.maximumvalue)
          ) {
            isValid = false;
            setError({
              ...error,
              result: `Please enter value in range ${tabData.minimumvalue}-${tabData.maximumvalue} `,
            });
          }
        }
      }
    } else if (tabData?.statsmatrixid == 2) {
      if (!fields.jumbidinfeet) {
        isValid = false;
        setError({
          ...error,
          jumbidinfeet: 'Please select jump distance (Ft) ',
        });
      }
      if (!fields.jumbidininches) {
        isValid = false;
        setError({
          ...error,
          jumbidininches: 'Please select jump distance (In) ',
        });
      }
    } else if (tabData?.statsmatrixid == 3) {
      if (!fields.lbs) {
        isValid = false;
        setError({
          ...error,
          lbs: 'Please enter lbs ',
        });
      }
      if (fields.lbs) {
        let value = parseFloat(fields.lbs);
        if (!value) {
          isValid = false;
          setError({
            ...error,
            lbs: `Please enter value in range ${tabData.minimumvalue}-${tabData.maximumvalue} `,
          });
        }
        if (value) {
          if (
            value < parseFloat(tabData.minimumvalue) ||
            value > parseFloat(tabData.maximumvalue)
          ) {
            isValid = false;
            setError({
              ...error,
              lbs: `Please enter value in range ${tabData.minimumvalue}-${tabData.maximumvalue} `,
            });
          }
        }
      }
      if (!fields.reps) {
        isValid = false;
        setError({
          ...error,
          reps: 'Please enter reps ',
        });
      }
      if (fields.reps) {
        let value = parseFloat(fields.reps);

        if (!value) {
          isValid = false;
          setError({
            ...error,
            reps: `Please enter value in range ${tabData.minimumvalue}-${
              parseFloat(tabData.maximumvalue) - 470
            } `,
          }); //validation range 30
        }
        if (value) {
          if (
            value < parseFloat(tabData.minimumvalue) ||
            value > parseFloat(tabData.maximumvalue) - 470 //validation range 30
          ) {
            isValid = false;
            setError({
              ...error,
              reps: `Please enter value in range ${tabData.minimumvalue}-${
                parseFloat(tabData.maximumvalue) - 470
              } `, //validation range 30
            });
          }
        }
      }
    } else {
      if (!fields.result) {
        isValid = false;
        setError({ ...error, result: 'Please enter result' });
      }
      if (fields.result) {
        let value = fields.result;
        if (!value) {
          isValid = false;
          setError({ ...error, result: 'Please enter numeric value' });
        }
        if (value) {
          if (
            value < parseFloat(tabData.minimumvalue) ||
            value > parseFloat(tabData.maximumvalue)
          ) {
            isValid = false;
            setError({
              ...error,
              result: `Please enter value in range ${tabData.minimumvalue}-${tabData.maximumvalue} `,
            });
          }
        }
      }
    }
    return isValid;
  };
  const onSaveClick = (e, isAddAnother = false) => {
    let isValid = handleValidation();
    if (isValid) {
      props.onSave(fields, isAddAnother);
      props.onClose();
    }
  };
  const onDeleteClick = () => {
    props.onDelete(fields);
    showDeleteRecordModalHandler();
  };
  return (
    <div
      className="modal fade common-modal form-modal stats-modal show"
      id="Add60YardDataModalone"
      style={{ display: 'block' }}
    >
      <div
        className="modal-dialog modal-dialog-centered editprofile-common-form"
        role="document"
      >
        <form className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {props.isAdd === true ? 'Add ' : 'Edit '}
              {tabData?.tabname}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="fieldBox">
              <label>
                Performance Date <sup>*</sup>
              </label>
              <input
                type="date"
                value={fields.date}
                placeholder="09/14/2020"
                onChange={(e) => onChangeHandler(e, 'date')}
              />
              <div className="error">{error.date ? error.date : ''} </div>
            </div>
            <div className="fieldBox flexBox">
              {tabData
                ? getMetricFields(
                    tabData,
                    onChangeHandler,
                    fields,
                    error,
                    jumpList
                  )
                : ''}
            </div>
            <div className="fieldBox flexBox">
              <div className="half">
                <label>
                  Enter Height (Ft) <sup>*</sup>
                </label>

                <Select
                  value={fields.heightinfeet}
                  id="heightinfeet"
                  onChange={(e) => onChangeHandler(e, 'heightinfeet')}
                  style={{ width: '100%' }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {props.feetDetails.footList &&
                    props.feetDetails.footList.data &&
                    props.feetDetails.footList.data.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.heightvalue}
                      </MenuItem>
                    ))}
                </Select>
                <div className="error">
                  {error.heightinfeet ? error.heightinfeet : ''}{' '}
                </div>
              </div>
              <div className="half">
                <label>
                  Enter Height (In) <sup>*</sup>
                </label>
                <Select
                  value={fields.heightininches}
                  id="heightininches"
                  onChange={(e) => onChangeHandler(e, 'heightininches')}
                  style={{ width: '100%' }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {props.feetDetails.inchList &&
                    props.feetDetails.inchList.data &&
                    props.feetDetails.inchList.data.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.inchvalue}
                      </MenuItem>
                    ))}
                </Select>
                <div className="error">
                  {error.heightininches ? error.heightininches : ''}{' '}
                </div>
              </div>
            </div>
            <div className="fieldBox mb-0">
              <label>
                Enter Body Weight (Lbs) <sup>*</sup>
              </label>
              <input
                type="number"
                placeholder="210"
                value={fields.weight}
                onChange={(e) => onChangeHandler(e, 'weight')}
              />
              <div className="error">{error.weight ? error.weight : ''} </div>
            </div>
          </div>
          {!props.isEdit ? (
            <div className="modal-footer">
              <button
                type="button"
                className="btn green"
                data-dismiss="modal"
                onClick={onSaveClick}
              >
                Done
              </button>
            </div>
          ) : (
            <div className="modal-footer with-trash">
              <div className="flex-item">
                {fields.date === undefined &&
                fields.result === undefined &&
                fields.lasertime === undefined &&
                fields.heightinfeet == '' &&
                fields.heightininches == '' &&
                fields.weight == '' &&
                fields.jumbidinfeet === undefined &&
                fields.jumbidininches === undefined ? null : (
                  <button
                    className="trash-button"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#StatsAddYardDataTrashModal"
                    onClick={showDeleteRecordModalHandler}
                  >
                    Delete
                  </button>
                )}
              </div>
              <div className="flex-item">
                <button
                  type="button"
                  className="btn green"
                  data-dismiss="modal"
                  onClick={onSaveClick}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
      <Dialog
        open={showDeleteRecordModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            showDeleteRecordModalHandler();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteRecordModal
          onClose={showDeleteRecordModalHandler}
          onDelete={onDeleteClick}
        />
      </Dialog>
    </div>
  );
};

const ScoutingRequest = (props) => {
  return (
    <div
      className="modal fade common-modal form-modal stats-request show"
      id="ScoutingRequestStatsModal"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Scouting Request</h5>
            <button type="button" className="close" onClick={props.onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="icon-box email-circle-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30.027"
                height="22.53"
                viewBox="0 0 30.027 22.53"
              >
                <g id="email-solid" transform="translate(-1.93 -5.989)">
                  <path
                    id="Path_2163"
                    data-name="Path 2163"
                    d="M30.546,6a1.877,1.877,0,0,0-.385,0H3.887a1.877,1.877,0,0,0-.5.075l13.559,13.5Z"
                    transform="translate(-0.09 0)"
                    fill="#1cb79e"
                  />
                  <path
                    id="Path_2164"
                    data-name="Path 2164"
                    d="M31.844,7.39,18.182,21a1.877,1.877,0,0,1-2.646,0L2,7.493a1.877,1.877,0,0,0-.066.469V26.729a1.877,1.877,0,0,0,1.877,1.877H30.08a1.877,1.877,0,0,0,1.877-1.877V7.962a1.877,1.877,0,0,0-.113-.572ZM5.092,26.729h-1.3V25.387l6.822-6.765,1.323,1.323Zm24.969,0H28.748l-6.841-6.784,1.323-1.323,6.822,6.765Z"
                    transform="translate(0 -0.086)"
                    fill="#1cb79e"
                  />
                </g>
              </svg>
            </div>
            <p>
              Your request to verify the metrics that you have added currently
              have been sent to the admin
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn green" onClick={props.onClose}>
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddEditModal = (props) => {
  return (
    <div
      className="modal fade common-modal trash-modal show"
      id="StatsAddEditStatModal"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog max-with380 modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" onClick={props.onClose}>
              <img src={closeGrayIcon} alt="" />
            </button>
            <div className="content-box">
              <h4>Add / Edit Stat</h4>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn green"
              onClick={(e) => {
                e.preventDefault();
                props.onClickHandler('add');
              }}
            >
              New Stat
            </button>
            <button
              type="button"
              className="btn gray"
              onClick={(e) => {
                e.preventDefault();

                props.onClickHandler('edit');
              }}
            >
              Edit Stat
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const EditStatWarningModal = (props) => {
  return (
    <div
      className="modal fade common-modal trash-modal stats-video-modal show"
      id="StatsWarningModal"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog max-with380 modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" onClick={props.onClose}>
              <img src={closeGrayIcon} alt="" />
            </button>
            <div className="icon-box-first">
              <img src={warningSign} alt="" />
            </div>
            <div className="content-box text-center">
              <h4>WARNING:</h4>
              {props.metricData && props.metricData.verified ? (
                <p>
                  Editing Verified Stats will remove your <br />
                  verified checkmark.
                </p>
              ) : (
                <p>
                  Editing an existing stats will remove the <br />
                  previous stat from your profile.
                </p>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn green"
              onClick={(e) => {
                e.preventDefault();
                props.onClickEditWarning();
              }}
            >
              Edit stat
            </button>
            <button type="button" className="btn" onClick={props.onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const UploadVideoModal = (props) => {
  const [showVideoDesc, setShowVideoDesc] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImages, setIsImages] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFileUrl, seSelectedFileUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [vidThumbnail, setVidThumbnail] = useState(''); //state is added as per change thunmbail vimeo
  const [error, setError] = useState('');
  const onUploadClick = async () => {
    if (error === '') {
      //below if condition is added as per vimeo thumbnail
      if (!!videoUrl) {
        if (videoUrl?.includes('vimeo')) {
          const vidID = videoUrl?.split('/');
          const _resThumb = await getVimeoThumb(vidID?.[vidID?.length - 1]);
          setVidThumbnail(_resThumb);
        }
      }
      if (selectedFile || videoUrl) {
        if (videoUrl?.includes('youtu.be')) {
          const dataSplit = videoUrl?.split('/'),
            url = dataSplit[dataSplit?.length - 1];
          setVideoUrl(`https://youtube.com/embed/${url}`);
        } else if (videoUrl?.includes('youtube')) {
          const dataSplit = videoUrl?.split('='),
            url = dataSplit[dataSplit?.length - 1];
          setVideoUrl(`https://youtube.com/embed/${url}`);
        } else if (videoUrl?.includes('vimeo')) {
          const dataSplit = videoUrl?.split('/'),
            url = dataSplit[dataSplit?.length - 1];
          setVideoUrl(`https://player.vimeo.com/video/${url}`);
        } else if (videoUrl?.includes('hudl')) {
          const dataSplit = videoUrl?.split('video'),
            url = dataSplit[dataSplit?.length - 1];
          // return `https://www.hudl.com/embed/video${url}`
          setVideoUrl(`https://www.hudl.com/embed/video${url}`);
        }
        setTimeout(() => {
          setShowVideoDesc(true);
        }, 500);
      } else {
        // messagePopup("", "Please add a video or photo", "error");
        toastr.error('Please add a video or photo', '', {
          progressBar: true,
          timeOut: 2000,
          closeButton: true,
          positionClass: 'toast-top-full-width',
          showEasing: 'swing',
          fadeIn: 40000,
          preventDuplicates: true,
        });
      }
    } else {
      return;
    }
  };
  const onSelectFile = (e) => {
    const file = e.target.files[0];
    const fileArr = file.name.split('.');
    fileArr[0] = new Date().getTime();
    //////////////
    ///below code is check over video format and uploading size
    let fileExtension = file.name
      .split('.')
      [file.name.split('.').length - 1]?.toLowerCase();
    if (file.type.includes('video')) {
      if (
        fileExtension != 'mp4' &&
        fileExtension != '3g2' &&
        fileExtension != '3gp' &&
        fileExtension != 'avi' &&
        fileExtension != 'flv' &&
        fileExtension != 'm4v' &&
        fileExtension != 'mkv' &&
        fileExtension != 'mov' &&
        fileExtension != 'mpg' &&
        fileExtension != 'mts' &&
        fileExtension != 'm2ts' &&
        fileExtension != 'mpeg' &&
        fileExtension != 'wmv'
      ) {
        toastr.error(
          'Video format must be 3g2, 3gp, avi, flv, m4v, mkv, mov, mp4, mpg, mts, m2ts, mpeg, wmv',
          '',
          {
            progressBar: true,
            timeOut: 2000,
            closeButton: true,
            positionClass: 'toast-top-full-width',
            showEasing: 'swing',
            fadeIn: 40000,
            preventDuplicates: true,
          }
        );
        return;
      } else {
        const fileSize = parseInt(e?.target?.files?.[0]?.size) / (1024 * 1024);
        if (parseFloat(fileSize).toFixed(3) > 150) {
          toastr.error('Max Limit 150MB', '', {
            progressBar: true,
            timeOut: 2000,
            closeButton: true,
            positionClass: 'toast-top-full-width',
            showEasing: 'swing',
            fadeIn: 40000,
            preventDuplicates: true,
          });
          return;
        }
      }
    }
    //////////////////
    const newFile = new File([file], fileArr.join('.'), {
      type: file.type,
      lastModified: file.lastModified,
    });
    setSelectedFile(newFile);
    seSelectedFileUrl(URL.createObjectURL(newFile));
    setSelectedFileName(newFile.name);
    setIsImages(newFile.type.includes('image'));
  };
  const onCloseVideoDescription = () => {
    setSelectedFile(null);
    seSelectedFileUrl('');
    setSelectedFileName('');
    setShowVideoDesc(false);
    setVideoUrl('');
  };
  const closeModalWithoutUploadingVideo = () => {
    props.onClose();
    // props.refreshAfterSave() // reloads the page -- not required on cancel button
  };
  const validate = (e) => {
    setVideoUrl(e.target.value);
    if (e.target.value !== '') {
      if (!validStatVideoURL(e.target.value)) {
        setError('Please enter a valid Youtube, Hudl or vimeo link');
      }
      //below check is over link of hudl
      else if (
        e?.target?.value?.includes('hudl') &&
        !e?.target?.value?.includes('video')
      ) {
        setError('Please enter a valid Youtube, Hudl or vimeo link');
      } else {
        setError('');
      }
    } else {
      setError('');
    }
  };
  return (
    <div
      className="modal fade common-modal stats-video-modal show"
      id="StatsVideoUploadModal"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="modal-title">Upload Photo / Video</h5>
            <div className="content-box">
              <div className="item">
                <div
                  className="link-box"
                  onClick={() =>
                    !!videoUrl
                      ? swal({
                          title: '',
                          text: 'You can only add one media option per submission',
                          icon: 'warning',
                        })
                      : null
                  }
                >
                  <span className="link-inner-box">
                    <span className="icon-box">
                      <img src={addRoundIcon} alt="" />
                    </span>
                    <span className="text">ADD PHOTO / VIDEO</span>
                  </span>
                  <input
                    style={
                      !!videoUrl ? { display: 'none' } : { display: 'block' }
                    }
                    className="browse-img"
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*,image/png,image/jpeg, image.jpg"
                    onChange={(e) => {
                      onSelectFile(e);
                      e.currentTarget.value = null;
                    }}
                    disabled={videoUrl}
                  />
                </div>
                {selectedFileName ? (
                  <>
                    <div className="error text-center">{selectedFileName}</div>
                    <div
                      className="error text-center"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedFile(null);
                        seSelectedFileUrl('');
                        setSelectedFileName('');
                        setIsImages(null);
                      }}
                    >
                      Delete
                    </div>
                  </>
                ) : null}
              </div>
              <div className="item">
                <label>OR</label>
              </div>
              <div className="item">
                <label>Add a Video From Youtube, Hudl or Vimeo</label>
                <input
                  onClick={() =>
                    !!selectedFile
                      ? swal({
                          title: '',
                          text: 'You can only add one media option per submission',
                          icon: 'warning',
                        })
                      : null
                  }
                  type="text"
                  placeholder="Enter Video Link"
                  value={videoUrl}
                  onChange={(e) => validate(e)}
                  // disabled={selectedFile}
                />
                <h3 className="error text-center">{error}</h3>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn green"
              onClick={() => onUploadClick()}
            >
              Upload
            </button>
            <button
              type="button"
              className="btn"
              onClick={closeModalWithoutUploadingVideo}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <Dialog
        open={showVideoDesc}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            onCloseVideoDescription();
          }
        }}
        className="matDialogCustom matDialogCustomOverlay"
      >
        <UploadVideoDescritpionModal
          onCloseVideoDesc={onCloseVideoDescription}
          {...props}
          mainPopUp={props.onClose}
          selectedFile={selectedFile}
          selectedFileUrl={selectedFileUrl}
          videoUrl={videoUrl}
          vidThumbnail={vidThumbnail} //this props is added as per vimeo thumbnail
          isImages={isImages}
        />
      </Dialog>
    </div>
  );
};

const UploadedVideoReview = (props) => {
  const [vidID, setVidID] = useState(null); //this state is for videoPopup
  const [isVidPop, setIsVidPop] = useState(false); //this state is for videoPopup
  const [isLink, setIsLink] = useState(false); //this state is for videoPopup

  const openUrlVideo = (data) => {
    if (data.isvideoorlink === 2) {
      // window.open(data.videoname, "_blank");
      //below func it for opening the ifram modal 1/8/2022 change doc
      setVidID(data?.videoname);
      setIsLink(true);
      setTimeout(() => {
        setIsVidPop(true);
      }, 500);
    } else {
      //below func it for opening the video modal 1/8/2022 change doc
      setVidID(`${imageDownloadUrl + '/' + data?.videoname}`);
      setIsLink(false);
      setTimeout(() => {
        setIsVidPop(true);
      }, 500);
    }
    if (
      !!data?.photovideoid &&
      (props?.page == 'Show_Stats_PR' || props?.page == 'Show_Stats_Profile')
    ) {
      const finalObj = {
        id: data?.photovideoid ?? 0,
        userid: data?.userid ?? 0,
        data,
      };
      vidCountIncre(finalObj, true);
    }
  };
  return (
    <>
      <div
        className="modal fade common-modal form-modal stats-uploaded-video show"
        id="StatsVideoUploadedModal"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Photo / Video</h5>
              <button type="button" className="close" onClick={props.onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="content-box">
                <div className="item">
                  <div className="img-box stats-video-thumbnail">
                    {props.metricData.isvideoorlink === 1 ? (
                      <>
                        <video
                        //    controls
                        // controlsList={props?.isAuth == 1 ? "" : "nodownload"}
                        >
                          <source
                            src={`${
                              imageDownloadUrl +
                              '/' +
                              typeConvert(
                                props.metricData?.videoname,
                                props?.page
                              ) +
                              '#t=0.2'
                            }`}
                            alt="video"
                          />
                        </video>
                        <div className="video-play-button">
                          <div className="video-play-inner">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                openUrlVideo(props.metricData);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18.172"
                                height="27.258"
                                viewBox="0 0 18.172 27.258"
                              >
                                <path
                                  className="a"
                                  d="M13.629,0,27.258,18.172H0Z"
                                  transform="translate(18.172) rotate(90)"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : props.metricData.isvideoorlink === 3 ? (
                      <img
                        src={`${
                          imageDownloadUrl + '/' + props.metricData?.videoname
                        }`}
                        height="300px"
                        width="300px"
                      ></img>
                    ) : (
                      <>
                        <video
                          poster={getThumbnail(
                            props.metricData?.videoname,
                            props.metricData,
                            props?.page
                          )}
                          // poster="img/video-thum-img1.png"
                        >
                          <source
                            src="http://clips.vorwaerts-gmbh.de/VfE_html5.mp4"
                            type="video/mp4"
                          />
                          <source
                            src="http://clips.vorwaerts-gmbh.de/VfE_html5.ogg"
                            type="video/ogg"
                          />
                        </video>
                        <div className="video-play-button">
                          <div className="video-play-inner">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                openUrlVideo(props.metricData);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18.172"
                                height="27.258"
                                viewBox="0 0 18.172 27.258"
                              >
                                <path
                                  className="a"
                                  d="M13.629,0,27.258,18.172H0Z"
                                  transform="translate(18.172) rotate(90)"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {props?.page == 'Show_Stats_PR' && (
                <button
                  type="button"
                  className="btn green"
                  data-dismiss="modal"
                  onClick={() => props.onClickUpdatePopReview()}
                >
                  update
                </button>
              )}
              <button
                type="button"
                className={props?.page == 'Show_Stats_PR' ? 'btn' : 'btn green'}
                data-dismiss="modal"
                onClick={
                  props?.page == 'Show_Stats_PR'
                    ? props?.onClickDeletePersonal
                    : props.onClose
                }
              >
                {props?.page == 'Show_Stats_PR' ? 'Delete' : 'Done'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <VideoPop
        isTestSingle={isVidPop}
        setIsTestSingle={() => setIsVidPop(false)}
        vidID={vidID}
        isToken={props?.isAuth == 1 ? 1 : 0}
        isLink={isLink}
      />
    </>
  );
};

const UploadVideoDescritpionModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [videoDesc, setVideoDesc] = useState('');
  const [filePath, setFilePath] = useState('');
  const [vidID, setVidID] = useState(null); //this state is for videoPop
  const [isVidPop, setIsVidPop] = useState(false); //this state sid for videoPop
  const [isLink, setIsLink] = useState(false); //this state sid for videoPop
  const videoUploadedMsg = (response) => {
    swal({
      text: 'Photo / Video Uploaded Publically',
      icon: 'success',
    }).then(async (okay) => {
      if (okay || okay == null) {
        let res = response?.data?.data?.saveStatmatrixdetails;
        const savePostDetails = await saveMediaDetails(res);
        props.refreshAfterSave();
      }
    });
  };

  const saveMediaDetails = async (resValue) => {
    let obj = {
      userId: getUserInfo() ? getUserInfo().id : 0,
      photosAndVideosArray: [
        {
          name: resValue.videoname,
          type: props.selectedFile ? 1 : 2,
          position: null,
          jerseyno: null,
          jerseycolor: null,
          albumId: null,
          tagId: null,
          originalname: resValue.videoname,
          videophotosuploadedtype: 'Uploaded',
          type: props.selectedFile ? (props.isImages == true ? 0 : 1) : 2,
        },
      ],
      statid: resValue.id,
      isvisible: 1, //it should be 1 by default
      thumbnail: !!props?.vidThumbnail ? props?.vidThumbnail : '', //this key is added as per change doc vimeo thumbnail
      uploadedfrom: 'stats', //this key is added as per change doc in sept 15/9/2022
    };
    const res = await fetchMethod(
      '/api/Uploadedvideoandphotos/addPhotosAndVideos',
      obj,
      'post',
      true
    );

    //this is done to save the id in stats metrix detail
    if (res?.status == 200) {
      const finalObj = {
        obj: {
          id: resValue?.id,
          photovideoid: res?.data?.response?.data?.id ?? null,
        },
      };
      const resStatsDetail = await fetchGraphMethod(
        saveStatmatrixdetails,
        finalObj,
        true
      );

      props.onClose();
      props.refreshAfterSave();
    }
  };
  //fun for checking video
  const videoTypeDetect = (data) => {
    const dataLower = data?.name?.toLowerCase();
    if (
      dataLower?.includes('3g2') ||
      dataLower?.includes('3gp') ||
      dataLower?.includes('avi') ||
      dataLower?.includes('flv') ||
      dataLower?.includes('m4v') ||
      dataLower?.includes('mkv') ||
      dataLower?.includes('mov') ||
      dataLower?.includes('mp4') ||
      dataLower?.includes('mpg') ||
      dataLower?.includes('mts') ||
      dataLower?.includes('m2ts') ||
      dataLower?.includes('mpeg') ||
      dataLower?.includes('wmv')
    ) {
      return true;
    } else {
      return false;
    }
  };
  const onSaveHandler = async () => {
    if (props.selectedFile) {
      try {
        setLoading(true);
        let formData = new FormData();
        formData.append('file', props.selectedFile);
        const response = await axios({
          url: videoTypeDetect(props.selectedFile)
            ? videoUploadUrl
            : imageUploadUrl, //videoDetect(props.selectedFile) func for change api vor video
          method: 'POST',
          data: formData,
          headers: {
            access_token: getAuthToken(),
          },
        });
        if (response && response.status === 200) {
          const pathUrl = response.data.result?.files?.file[0]?.name
            ? response.data.result.files.file[0].name
            : '';
          setFilePath(pathUrl);
          setLoading(false);
          let variables = {
            obj: {
              userid: getUserInfo().id,
              active: 1,
              id: props.metricData ? props.metricData.id : 0,
              videoname: pathUrl,
              videodescription: videoDesc ? videoDesc : '',
              isvideoorlink: props.isImages == true ? 3 : 1,
              thumbnail: !!props?.vidThumbnail ? props?.vidThumbnail : null, //this key is added as per vimeo thumbnail
              verifiedby: 'Not verified',
              isscoutingrequestsent: 0,
              verified: 0,
              isscoutingrequestupdateOn: null,
              isedit: 1,
            },
          };
          props.saveStatsMatrixDetailsStart({
            query: saveStatmatrixdetails,
            variables,
            onCloseVideoDesc: props.onCloseVideoDesc,
            videoUploadedMsg: videoUploadedMsg,
          });
          sendVideoPhotoNotification();
        } else {
          setLoading(false);
          // messagePopup("", "Upload  Failed", "error");
          toastr.error('Upload  Failed', '', {
            progressBar: true,
            timeOut: 2000,
            closeButton: true,
            positionClass: 'toast-top-full-width',
            showEasing: 'swing',
            fadeIn: 40000,
            preventDuplicates: true,
          });
        }
      } catch (error) {
        setLoading(false);
        // messagePopup("", "Upload  Failed", "error");
        toastr.error('Upload  Failed', '', {
          progressBar: true,
          timeOut: 2000,
          closeButton: true,
          positionClass: 'toast-top-full-width',
          showEasing: 'swing',
          fadeIn: 40000,
          preventDuplicates: true,
        });
      }
      props.onCloseVideoDesc();
      props.mainPopUp();
    } else {
      let variables = {
        obj: {
          userid: getUserInfo().id,
          active: 1,
          id: props.metricData ? props.metricData.id : 0,
          videoname: props.videoUrl,
          videodescription: videoDesc ? videoDesc : '',
          isvideoorlink: 2,
          thumbnail: !!props?.vidThumbnail ? props?.vidThumbnail : null, //thi key is added a per change doc vimeo
          verifiedby: 'Not verified',
          isscoutingrequestsent: 0,
          verified: 0,
          isscoutingrequestupdateOn: null,
          isedit: 1,
        },
      };
      props.saveStatsMatrixDetailsStart({
        query: saveStatmatrixdetails,
        variables,
        onCloseVideoDesc: props.onCloseVideoDesc,
        videoUploadedMsg: videoUploadedMsg,
      });
      props.onCloseVideoDesc();
      props.mainPopUp();
    }
  };

  const openUrlVideo = (videoUrl, linkType) => {
    if (videoUrl) {
      // window.open(videoUrl, "_blank");
      //below func is for videoPop change req 1/8/2022
      setVidID(videoUrl);
      setIsLink(linkType);
      setTimeout(() => {
        setIsVidPop(true);
      }, 500);
    }
  };
  return (
    <>
      <div
        className="modal fade common-modal stats-video-modal show"
        id="StatsVideoUploadModalTwo"
        style={{ display: 'block' }}
      >
        <BackdropLoader open={props.loading || loading} />
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                onClick={props.onCloseVideoDesc}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5 className="modal-title">Upload Photo / Video</h5>
              <div className="content-box max-with250">
                <div className="item">
                  <div className="img-box stats-video-thumbnail">
                    {props.selectedFileUrl ? (
                      props.isImages == false ? (
                        <>
                          <video
                          // controls
                          // controlsList={getAuthToken() ? "" : "nodownload"}
                          >
                            <source src={props.selectedFileUrl} alt="video" />
                          </video>
                          <div className="video-play-button">
                            <div className="video-play-inner">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  openUrlVideo(props.selectedFileUrl, false);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="14"
                                  viewBox="0 0 8 14"
                                >
                                  <path
                                    className="a"
                                    d="M8.1,0l8.1,10.8H0Z"
                                    transform="translate(8) rotate(90)"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <img
                          src={props.selectedFileUrl}
                          height="100px"
                          width="100px"
                        ></img>
                      )
                    ) : (
                      <>
                        <video
                          poster={getThumbnail(
                            props.videoUrl,
                            props,
                            'Save/Update_StatsVP'
                          )}
                        >
                          <source
                            src="http://clips.vorwaerts-gmbh.de/VfE_html5.mp4"
                            type="video/mp4"
                          />
                          <source
                            src="http://clips.vorwaerts-gmbh.de/VfE_html5.ogg"
                            type="video/ogg"
                          />
                        </video>
                        <div className="video-play-button">
                          <div className="video-play-inner">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                openUrlVideo(props.videoUrl, true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="14"
                                viewBox="0 0 8 14"
                              >
                                <path
                                  className="a"
                                  d="M8.1,0l8.1,10.8H0Z"
                                  transform="translate(8) rotate(90)"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    <ul className="info-icon">
                      <li
                        className="delete-button"
                        onClick={(e) => {
                          e.preventDefault();
                          props.onCloseVideoDesc();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.309"
                          height="18.842"
                          viewBox="0 0 15.309 18.842"
                        >
                          <g transform="translate(0)">
                            <path
                              className="a"
                              d="M61.837,2.355H58.6V1.766A1.766,1.766,0,0,0,56.832,0H54.477A1.766,1.766,0,0,0,52.71,1.766v.589H49.472A1.472,1.472,0,0,0,48,3.827V5a.589.589,0,0,0,.589.589H62.72A.589.589,0,0,0,63.309,5V3.827A1.472,1.472,0,0,0,61.837,2.355Zm-7.949-.589a.59.59,0,0,1,.589-.589h2.355a.59.59,0,0,1,.589.589v.589H53.888Z"
                              transform="translate(-48)"
                            />
                            <path
                              className="a"
                              d="M73.548,184a.184.184,0,0,0-.184.193l.486,10.2a1.764,1.764,0,0,0,1.764,1.682h8.942a1.764,1.764,0,0,0,1.764-1.682l.486-10.2a.184.184,0,0,0-.184-.193Zm8.892,1.472a.589.589,0,0,1,1.178,0v7.654a.589.589,0,1,1-1.178,0Zm-2.944,0a.589.589,0,0,1,1.178,0v7.654a.589.589,0,1,1-1.178,0Zm-2.944,0a.589.589,0,0,1,1.178,0v7.654a.589.589,0,1,1-1.178,0Z"
                              transform="translate(-72.431 -177.229)"
                            />
                          </g>
                        </svg>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <input
                    type="text"
                    placeholder="Add a description."
                    value={videoDesc}
                    onChange={(e) => setVideoDesc(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn green"
                onClick={(e) => {
                  e.preventDefault();
                  onSaveHandler();
                }}
              >
                Upload
              </button>
              <button
                type="button"
                className="btn"
                onClick={props.onCloseVideoDesc}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <VideoPop
        isTestSingle={isVidPop}
        setIsTestSingle={() => setIsVidPop(false)}
        vidID={vidID}
        isToken={1}
        isLink={isLink}
      />
    </>
  );
};

const DeleteVideoModal = (props) => {
  return (
    <div
      className="modal fade common-modal trash-modal stats-video-modal show"
      id="StatsVideoTrashModal"
      aria-modal="true"
      role="dialog"
      style={{ paddingRight: '9px', display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose}
            >
              <img src={closeGrayIcon} alt="" />
            </button>
            <div className="icon-box-first">
              <img src={trashCircleIcon} alt="" />
            </div>
            <div className="content-box">
              {props.deleteMsg ? (
                <h4>{props.deleteMsg}</h4>
              ) : (
                <h4>Are you sure you want to delete this photo / Video?</h4>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <a href={void 0} className="btn" onClick={props.onDelete}>
              Delete
            </a>
            <button
              type="button"
              className="btn gray"
              data-dismiss="modal"
              onClick={props.onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const StatsPersonalRecordCard = ({
  matrixDetails,
  tabData,
  totalCount,
  onEditClick,
  onClickVideoIcon,
  getMetricLabel,
  onClickGraphIcon,
}) => {
  const [openLinkPop, setOpenLinkPop] = useState(false); //state for open and close popup
  const [webLinkState, setWebLinkState] = useState(
    !!matrixDetails?.weblink ? matrixDetails?.weblink : ''
  ); //state for weblink data
  const [isValid, setIsValid] = useState(false); //state for validation
  const [isDelPop, setIsDelPop] = useState(false); //state for delete pop
  //func for saving the weblink
  const onClickDone = async (value) => {
    const valValue =
      value == 'del'
        ? true
        : webLinkState?.includes('http') ||
          webLinkState?.includes('https') ||
          webLinkState?.includes('www.');
    if (valValue) {
      setIsValid(false);
      const variables = {
        obj: {
          userid: getUserInfo().id,
          active: 1,
          id: matrixDetails.id,
          weblink: value == 'del' ? '' : webLinkState,
          verified: 0,
          verifiedby: 'Not verified',
          isscoutingrequestsent: 0,
          isscoutingrequestupdateOn: null,
          isedit: 1,
          verifiedbrandlogo: null,
        },
      };
      const _resMat = await fetchGraphMethod(
        saveStatmatrixdetails,
        variables,
        true
      );
      if (_resMat?.status == 200) {
        setOpenLinkPop(false);
        setIsDelPop(false);
        //for refreshing the state
        setTimeout(() => {
          window?.location?.reload();
        }, 500);
      } else {
        swal({ text: 'Failed', icon: 'error' });
      }
    } else {
      setIsValid(true);
    }
  };
  return (
    <div className="item">
      <div className="detial-box">
        <div className="inner-box">
          {!matrixDetails ? (
            <>
              {/*<button*/}
              {/*  className="edit-btn"*/}
              {/*  onClick={() => {*/}
              {/*    onEditClick(matrixDetails, tabData);*/}
              {/*  }}*/}
              {/*  style={{ cursor: "pointer" }}*/}
              {/*>*/}
              {/*  <svg*/}
              {/*    xmlns="http://www.w3.org/2000/svg"*/}
              {/*    width="14"*/}
              {/*    height="14"*/}
              {/*    viewBox="0 0 14 14"*/}
              {/*  >*/}
              {/*    <path*/}
              {/*      className="a"*/}
              {/*      d="M13.641,2.28,11.8.434A1.352,1.352,0,0,0,9.889.512a1.367,1.367,0,0,0-.077,1.923L11.649,4.28A1.353,1.353,0,0,0,13.564,4.2,1.367,1.367,0,0,0,13.64,2.28ZM1.925,8.507,5.6,12.2l5.973-6L7.9,2.511l-5.973,6ZM0,14.074,4.848,13.1.971,9.206Z"*/}
              {/*      transform="translate(0 -0.074)"*/}
              {/*    ></path>*/}
              {/*  </svg>*/}
              {/*</button>*/}
              {/*//////*/}
              <h5>
                <button
                  className="btn green plusbtn"
                  onClick={() => {
                    onEditClick(matrixDetails, tabData);
                  }}
                >
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                    >
                      <g transform="translate(1.553 1.553)">
                        <line
                          className="a"
                          x2="14"
                          transform="translate(-0.053 6.947)"
                        />
                        <line
                          className="a"
                          y2="14"
                          transform="translate(6.947 -0.053)"
                        />
                      </g>
                    </svg>
                  </span>
                </button>
              </h5>
            </>
          ) : (
            <>
              {matrixDetails.verified === 2 ||
              (matrixDetails.verified === 3 &&
                matrixDetails?.verifiedbrandlogo) ? (
                <h4>{matrixDetails?.verifiedbrandlogo}</h4>
              ) : null}
              <h5>
                <span className="text-with-tooltip">
                  {getMetricLabel
                    ? getMetricLabel(tabData, matrixDetails)
                    : null}
                  {matrixDetails.verified === 1 ? (
                    <span className="icon-box custom-tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.449"
                        height="16.117"
                        viewBox="0 0 14.449 16.117"
                      >
                        <g transform="translate(-2.872 -1.125)">
                          <path
                            className="a"
                            d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                            transform="translate(0)"
                          />
                        </g>
                      </svg>
                      <span className="tooltip-data">Coach Verified</span>
                    </span>
                  ) : matrixDetails.verified === 2 ? (
                    <span className="icon-box red custom-tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.449"
                        height="16.117"
                        viewBox="0 0 14.449 16.117"
                      >
                        <g transform="translate(-2.872 -1.125)">
                          <path
                            className="a"
                            d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                            transform="translate(0)"
                          />
                        </g>
                      </svg>
                      <span className="tooltip-data">SportsForce Verified</span>
                    </span>
                  ) : matrixDetails.verified === 3 ? (
                    <span>
                      <span className="icon-box custom-tooltip">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.449"
                          height="16.117"
                          viewBox="0 0 14.449 16.117"
                        >
                          <g transform="translate(-2.872 -1.125)">
                            <path
                              className="a"
                              d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                              transform="translate(0)"
                            />
                          </g>
                        </svg>
                        <span className="tooltip-data">Coach Verified</span>
                      </span>
                      <span className="icon-box red custom-tooltip">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.449"
                          height="16.117"
                          viewBox="0 0 14.449 16.117"
                        >
                          <g transform="translate(-2.872 -1.125)">
                            <path
                              className="a"
                              d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                              transform="translate(0)"
                            />
                          </g>
                        </svg>
                        <span className="tooltip-data">
                          SportsForce Verified
                        </span>
                      </span>
                    </span>
                  ) : null}
                </span>
              </h5>
            </>
          )}
          <h6>{tabData?.tabname}</h6>
          {matrixDetails ? (
            matrixDetails.statmatrixdetailseventidmaprel ? (
              matrixDetails.statmatrixdetailseventidmaprel.Events[0] ? (
                <p>
                  {
                    matrixDetails.statmatrixdetailseventidmaprel.Events[0]
                      .eventname
                  }{' '}
                  {getUTCTimeDateOverall(
                    matrixDetails.statmatrixdetailseventidmaprel.Events[0]
                      .createdon
                  )}
                </p>
              ) : (
                //earlier there were time  hh:mm:ss in below there
                <p>
                  {' '}
                  {(matrixDetails && matrixDetails.verified === 1) ||
                  (matrixDetails && matrixDetails.verified === 2) ||
                  (matrixDetails && matrixDetails.verified === 3)
                    ? 'Date : ' +
                      moment(matrixDetails.updatedon).format('MM/DD/YYYY')
                    : ''}
                </p>
              )
            ) : (
              <p>
                {(matrixDetails && matrixDetails.verified === 1) ||
                (matrixDetails && matrixDetails.verified === 2) ||
                (matrixDetails && matrixDetails.verified === 3)
                  ? 'Date : ' +
                    moment(matrixDetails.updatedon).format('MM/DD/YYYY')
                  : ''}
              </p>
            )
          ) : (
            <p>
              {(matrixDetails && matrixDetails.verified === 1) ||
              (matrixDetails && matrixDetails.verified === 2) ||
              (matrixDetails && matrixDetails.verified === 3)
                ? 'Date : ' +
                  moment(matrixDetails.updatedon).format('MM/DD/YYYY')
                : ''}
            </p>
          )}

          <div className="button-box">
            {!!matrixDetails && (
              <button>
                <span
                  disabled={!matrixDetails}
                  style={{ cursor: !matrixDetails ? 'not-allowed' : 'pointer' }}
                  className="icon-box green"
                  onClick={() => {
                    onEditClick(matrixDetails, tabData);
                  }}
                >
                  <svg
                    id="pencil"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.993"
                    height="16.993"
                    viewBox="0 0 16.993 16.993"
                  >
                    <path
                      id="pencil-2"
                      data-name="pencil"
                      d="M16.557,2.751,14.326.511A1.641,1.641,0,0,0,12,.606a1.659,1.659,0,0,0-.094,2.334l2.231,2.24a1.643,1.643,0,0,0,2.324-.094,1.659,1.659,0,0,0,.092-2.334ZM2.336,10.31,6.8,14.79l7.25-7.278L9.586,3.032,2.336,10.31ZM0,17.067l5.884-1.183L1.178,11.159Z"
                      transform="translate(0 -0.074)"
                      fill="#1cb79e"
                    />
                  </svg>
                </span>
              </button>
            )}
            <button
              disabled={!matrixDetails}
              style={{ cursor: !matrixDetails ? 'not-allowed' : 'pointer' }}
              className={`video-btn ${
                matrixDetails && matrixDetails.videoname ? 'red' : ''
              }`}
              onClick={() => onClickVideoIcon(matrixDetails, tabData)}
            >
              <svg
                id="Group_1127"
                data-name="Group 1127"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <g id="Group_1126" data-name="Group 1126">
                  <path
                    id="Path_2126"
                    data-name="Path 2126"
                    d="M16.313,0H1.688A1.688,1.688,0,0,0,0,1.688V16.313A1.688,1.688,0,0,0,1.688,18H16.313A1.688,1.688,0,0,0,18,16.313V1.688A1.688,1.688,0,0,0,16.313,0Zm.563,11.019L14.462,8.606a.562.562,0,0,0-.793,0l-2.981,2.975L7.149,8.044a.562.562,0,0,0-.793,0L1.125,13.269V1.688a.563.563,0,0,1,.563-.562H16.313a.563.563,0,0,1,.563.563Z"
                    fill="#3e3e3e"
                  />
                  <path
                    id="Path_2127"
                    data-name="Path 2127"
                    d="M226.373,80a2.373,2.373,0,1,0,2.373,2.373A2.373,2.373,0,0,0,226.373,80Zm0,3.56a1.187,1.187,0,1,1,1.187-1.187,1.187,1.187,0,0,1-1.187,1.187Z"
                    transform="translate(-216.269 -77.239)"
                    fill="#3e3e3e"
                  />
                </g>
              </svg>
            </button>
            <button
              className="link-btn"
              style={{ cursor: !matrixDetails ? 'not-allowed' : 'pointer' }}
              disabled={!matrixDetails}
              onClick={(e) => {
                e.preventDefault();
                onClickGraphIcon(matrixDetails, tabData);
              }}
            >
              {totalCount ? (
                <span className="number-of-value">{totalCount}</span>
              ) : null}
              <svg
                id="Component_30_2"
                data-name="Component 30 – 2"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <g
                  id="Rectangle_1230"
                  data-name="Rectangle 1230"
                  fill="#fff"
                  stroke="#3e3e3e"
                  strokeWidth="1.5"
                >
                  <rect width="18" height="18" rx="3" stroke="none" />
                  <rect
                    x="0.75"
                    y="0.75"
                    width="16.5"
                    height="16.5"
                    rx="2.25"
                    fill="none"
                  />
                </g>
                <g id="stats-chart-sharp" transform="translate(3.185 3.742)">
                  <path
                    id="Path_2181"
                    data-name="Path 2181"
                    d="M6.48,26.645h-3.1v-5.27h3.1Z"
                    transform="translate(-3.375 -16.012)"
                    fill="#3e3e3e"
                  />
                  <path
                    id="Path_2182"
                    data-name="Path 2182"
                    d="M22.23,22.53h-3.1v-7.9h3.1Z"
                    transform="translate(-10.431 -11.897)"
                    fill="#3e3e3e"
                  />
                  <path
                    id="Path_2184"
                    data-name="Path 2184"
                    d="M14.355,11.757h-3.1V1.125h3.1Z"
                    transform="translate(-6.903 -1.125)"
                    fill="#3e3e3e"
                  />
                </g>
              </svg>
            </button>
            <button
              className={!!matrixDetails ? 'link-btn red' : 'link-btn'}
              style={{ cursor: !!matrixDetails ? 'pointer' : 'not-allowed' }}
              disabled={!!matrixDetails ? false : true}
              onClick={(e) => setOpenLinkPop(true)}
            >
              <svg
                id="link"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16.002"
                viewBox="0 0 16 16.002"
              >
                <g
                  id="Group_2319"
                  data-name="Group 2319"
                  transform="translate(0 5.064)"
                >
                  <g
                    id="Group_2318"
                    data-name="Group 2318"
                    transform="translate(0)"
                  >
                    {!!matrixDetails?.weblink ? (
                      <>
                        <path
                          fill={'#00B9FF'}
                          id="Path_2307"
                          data-name="Path 2307"
                          d="M9.873,165.715a3.655,3.655,0,0,0-.631-.508,3.761,3.761,0,0,0-4.687.508l-3.417,3.419a3.761,3.761,0,0,0,5.316,5.321l2.82-2.82a.269.269,0,0,0-.191-.459H8.976a4.522,4.522,0,0,1-1.719-.333.269.269,0,0,0-.293.059l-2.028,2.031a1.613,1.613,0,1,1-2.281-2.281l3.43-3.428a1.612,1.612,0,0,1,2.278,0,1.1,1.1,0,0,0,1.51,0,1.064,1.064,0,0,0,0-1.51Z"
                          transform="translate(-0.039 -164.614)"
                        />
                        <path
                          fill={'#00B9FF'}
                          id="Path_2307"
                          data-name="Path 2307"
                          d="M9.873,165.715a3.655,3.655,0,0,0-.631-.508,3.761,3.761,0,0,0-4.687.508l-3.417,3.419a3.761,3.761,0,0,0,5.316,5.321l2.82-2.82a.269.269,0,0,0-.191-.459H8.976a4.522,4.522,0,0,1-1.719-.333.269.269,0,0,0-.293.059l-2.028,2.031a1.613,1.613,0,1,1-2.281-2.281l3.43-3.428a1.612,1.612,0,0,1,2.278,0,1.1,1.1,0,0,0,1.51,0,1.064,1.064,0,0,0,0-1.51Z"
                          transform="translate(-0.039 -164.614)"
                        />
                      </>
                    ) : (
                      <>
                        <path
                          fill={'#3e3e3e'}
                          id="Path_2307"
                          data-name="Path 2307"
                          d="M9.873,165.715a3.655,3.655,0,0,0-.631-.508,3.761,3.761,0,0,0-4.687.508l-3.417,3.419a3.761,3.761,0,0,0,5.316,5.321l2.82-2.82a.269.269,0,0,0-.191-.459H8.976a4.522,4.522,0,0,1-1.719-.333.269.269,0,0,0-.293.059l-2.028,2.031a1.613,1.613,0,1,1-2.281-2.281l3.43-3.428a1.612,1.612,0,0,1,2.278,0,1.1,1.1,0,0,0,1.51,0,1.064,1.064,0,0,0,0-1.51Z"
                          transform="translate(-0.039 -164.614)"
                        />
                        <path
                          fill={'#3e3e3e'}
                          id="Path_2307"
                          data-name="Path 2307"
                          d="M9.873,165.715a3.655,3.655,0,0,0-.631-.508,3.761,3.761,0,0,0-4.687.508l-3.417,3.419a3.761,3.761,0,0,0,5.316,5.321l2.82-2.82a.269.269,0,0,0-.191-.459H8.976a4.522,4.522,0,0,1-1.719-.333.269.269,0,0,0-.293.059l-2.028,2.031a1.613,1.613,0,1,1-2.281-2.281l3.43-3.428a1.612,1.612,0,0,1,2.278,0,1.1,1.1,0,0,0,1.51,0,1.064,1.064,0,0,0,0-1.51Z"
                          transform="translate(-0.039 -164.614)"
                        />
                      </>
                    )}
                  </g>
                </g>
                <g
                  id="Group_2321"
                  data-name="Group 2321"
                  transform="translate(5.847)"
                >
                  <g
                    id="Group_2320"
                    data-name="Group 2320"
                    transform="translate(0 0)"
                  >
                    {!!matrixDetails?.weblink ? (
                      <path
                        fill={'#00B9FF'}
                        id="Path_2308"
                        data-name="Path 2308"
                        d="M198.554,1.1a3.761,3.761,0,0,0-5.319,0l-2.818,2.815a.27.27,0,0,0,.2.459h.1a4.513,4.513,0,0,1,1.716.336.269.269,0,0,0,.293-.059l2.023-2.02a1.613,1.613,0,0,1,2.281,2.281l-2.52,2.517-.022.024-.881.876a1.612,1.612,0,0,1-2.278,0,1.1,1.1,0,0,0-1.51,0,1.071,1.071,0,0,0,0,1.515,3.728,3.728,0,0,0,1.074.752c.056.027.113.048.169.073s.115.043.172.064.115.04.172.056l.159.043c.107.027.215.048.325.067a3.739,3.739,0,0,0,.4.038h.2l.161-.019c.059,0,.121-.016.191-.016h.091l.185-.027.086-.016.156-.032h.03a3.76,3.76,0,0,0,1.743-.989l3.42-3.42A3.761,3.761,0,0,0,198.554,1.1Z"
                        transform="translate(-189.502 0)"
                      />
                    ) : (
                      <path
                        fill={'#3e3e3e'}
                        id="Path_2308"
                        data-name="Path 2308"
                        d="M198.554,1.1a3.761,3.761,0,0,0-5.319,0l-2.818,2.815a.27.27,0,0,0,.2.459h.1a4.513,4.513,0,0,1,1.716.336.269.269,0,0,0,.293-.059l2.023-2.02a1.613,1.613,0,0,1,2.281,2.281l-2.52,2.517-.022.024-.881.876a1.612,1.612,0,0,1-2.278,0,1.1,1.1,0,0,0-1.51,0,1.071,1.071,0,0,0,0,1.515,3.728,3.728,0,0,0,1.074.752c.056.027.113.048.169.073s.115.043.172.064.115.04.172.056l.159.043c.107.027.215.048.325.067a3.739,3.739,0,0,0,.4.038h.2l.161-.019c.059,0,.121-.016.191-.016h.091l.185-.027.086-.016.156-.032h.03a3.76,3.76,0,0,0,1.743-.989l3.42-3.42A3.761,3.761,0,0,0,198.554,1.1Z"
                        transform="translate(-189.502 0)"
                      />
                    )}
                  </g>
                </g>
              </svg>
            </button>
            <Dialog
              open={openLinkPop}
              onClose={(event, reason) => {
                if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
                  setOpenLinkPop(false); //state for popup
                  setWebLinkState(
                    !!matrixDetails?.weblink ? matrixDetails?.weblink : ''
                  );
                }
              }}
              className="matDialogCustom"
            >
              <WebLinkModal
                tableName={tabData?.tabname}
                onClose={() => {
                  setOpenLinkPop(false); //state for popup
                  setWebLinkState(
                    !!matrixDetails?.weblink ? matrixDetails?.weblink : ''
                  ); //re initializing the sate
                }}
                matWebLick={webLinkState} //weblink state
                onHandleWebLink={setWebLinkState} //handle for weblink
                onClickDone={onClickDone} //fun for saving the weblink
                isValid={isValid} //validation state
                setIsValid={setIsValid} //validation state handle
                setIsDelPop={setIsDelPop} //func for delete pop
                matData={matrixDetails}
              />
            </Dialog>
            <Dialog
              open={isDelPop}
              onClose={(event, reason) => {
                if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
                  setIsDelPop(false); //state for popup
                }
              }}
              className="matDialogCustom"
            >
              <DeleteWeblinkModal
                onClickCancel={() => setIsDelPop(false)}
                onClickDelete={onClickDone}
              />
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};
const EnterSeasonStat = (props) => {
  const todayDate = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [twoYearsAfterStartDate, setTwoYearsAfterStartDate] = useState();
  const [teamId, setTeamId] = useState({
    id: '',
    teamname: '',
  });
  const [positionId, setPositionId] = useState('');
  const [positionArr, setPositionArr] = useState([]);
  const [positions, setPositions] = useState([]);
  const [teamnameList, setTeamnameList] = useState([]);
  const [referenceEditValues, setReferenceEditValues] = useState({
    id: 0,
    team: '',
  });
  const [errors, setErrors] = useState({});
  const [coachDetailsList, setCoachDetailsList] = useState([
    {
      id: '',
      teamname: '',
      coachfirstname: '',
      coachemail: '',
      coachlastname: '',
      coachtypeid: '',
    },
  ]);
  const [fields, setFields] = useState({
    team: referenceEditValues.team ? referenceEditValues.team : '',
  });

  useEffect(() => {
    setTeamId('');
    setPositionId('');
    setStartDate(todayDate);
    setEndDate(todayDate);
  }, [props.close]);

  useEffect(() => {
    let itemsTobeRemoved = 'RHP' && 'LHP';
    if (
      props.primaryPositionList &&
      props.primaryPositionList.data &&
      props.primaryPositionList.data.length
    ) {
      setPositionArr(props.primaryPositionList.data);
    }
    const newArray = positionArr.filter(
      (positions) => positions.selectionfield !== 'RHP'
    );
    const finalArray = newArray.filter(
      (positions) => positions.selectionfield !== 'LHP'
    );
    setPositions(positionArr);
  }, [props.primaryPositionList]);

  useEffect(() => {
    if (
      props.teamList &&
      props.teamList.data &&
      props.teamList.data.allTeamsList
    ) {
      setTeamnameList(props.teamList.data.allTeamsList);
    }
  }, [props.teamList]);

  const startEndDateValidation = () => {
    let formIsValid = true;
    if (startDate === '') {
      formIsValid = false;
      setErrors({
        ...errors,
        startDate: 'Please enter the start date',
      });
    }
    if (endDate === '') {
      formIsValid = false;
      setErrors({
        ...errors,
        endDate: 'Please enter the end date',
      });
    }
    return {
      formIsValid,
    };
  };
  const statFormValidation = (e) => {
    if (teamId.id && positionId) {
      if (endDate !== '' && endDate < startDate) {
        swal(
          '',
          'End date should be greater than or equal to Start date',
          'error'
        );
      } else if (endDate > twoYearsAfterStartDate) {
        swal('', "A Season can't be of more than 2 years", 'error');
      } else if (startDate === '') {
        swal('', 'Please enter Start Date ', 'error');
      } else if (endDate === '') {
        swal('', 'Please enter End Date ', 'error');
      } else {
        let selectedPosition = positionArr.filter((item) => {
          return item.id == positionId;
        });
        props.onSave(e, teamId, selectedPosition, startDate, endDate);
        props.close(e);
      }
    } else if (teamId.id == undefined) {
      swal('', 'Please select the valid Team ', 'error');
    } else swal('', 'Please select the Position ', 'error');
  };
  const handleObjChange = (field, e) => {
    let value = '';
    if (field === 'team') {
      value = e ? (e.target ? (e.target.value ? e.target.value : '') : '') : '';
    }
    handleChange(field, { target: { value } });
  };
  const handleChange = (field, e) => {
    setErrors({});
    let fieldsT = fields;
    fieldsT[field] = e
      ? e.target
        ? e.target.value
          ? e.target.value
          : ''
        : ''
      : '';
    setFields({
      ...fieldsT,
    });
    if (fieldsT.team.id !== undefined && fieldsT.team.teamname !== undefined) {
      setTeamId({
        ...teamId,
        id: fieldsT.team && fieldsT.team.id ? fieldsT.team.id : null,
        teamname:
          fieldsT.team && fieldsT.team.teamnam ? fieldsT.team.teamname : '',
      });
    } else {
      setTeamId('');
    }
  };

  useEffect(() => {
    var date = new Date(startDate);
    var year = date.getFullYear();
    var month = date.getMonth();
    month = month + 1;
    if (month < 10) {
      month = '0' + month;
    }
    var day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    year = year + 2;
    var FullDate = year + '-' + month + '-' + day;
    setTwoYearsAfterStartDate(FullDate);
  }, [startDate, endDate]);

  return (
    <Dialog
      open={props.show}
      onClose={(event, reason) => {
        if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
          props.close();
        }
      }}
      className="matDialogCustom"
    >
      <div
        className={`modal fade common-modal form-modal stats-modal ${
          props.show ? 'show' : ''
        }`}
        id="EnterSeasonStatsModal"
        style={{ display: `${props.show ? 'block' : ''}` }}
      >
        <div
          className="modal-dialog modal-dialog-centered editprofile-common-form"
          role="document"
        >
          <form className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Enter Season Stats</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => props.close(e)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="fieldBox">
                <label>
                  Team <sup>*</sup>
                </label>
                <div className="autocomplete-class">
                  <Autocomplete
                    id="combo-box-demo"
                    freeSolo={true}
                    options={teamnameList}
                    onChange={(e, data) => {
                      handleObjChange('team', {
                        target: { value: data ? data : '' },
                      });
                    }}
                    value={teamnameList.id || ''}
                    getOptionLabel={(option) => {
                      return option.teamname ? option.teamname : '';
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={fields.team || ''}
                        onChange={(e) => {
                          handleChange('team', {
                            target: {
                              value: e.target.value ? e.target.value : '',
                            },
                          });
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="fieldBox">
                <label>
                  Position <sup>*</sup>
                </label>
                <select
                  value={positionId}
                  onChange={(e) => setPositionId(e.target.value)}
                >
                  <option value="DEFAULT">Position</option>
                  {positions.length &&
                    positions.map((position) => {
                      return (
                        <option value={position.id} key={position.id}>
                          {position.shortform}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="fieldBox flexBox">
                <div className="half">
                  <label>
                    Season Start Date <sup>*</sup>
                  </label>
                  <input
                    type="date"
                    value={startDate}
                    placeholder="07/10/2020"
                    onChange={(e) => setStartDate(e.target.value)}
                    max="9999-12-31"
                  />
                  <div className="error">{errors.startDate}</div>
                </div>
                <div className="half">
                  <label>
                    Season End Date <sup>*</sup>
                  </label>
                  <input
                    type="date"
                    value={endDate}
                    placeholder="07/10/2020"
                    onChange={(e) => setEndDate(e.target.value)}
                    max="9999-12-31"
                  />
                  <div className="error">{errors.endDate}</div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn green"
                onClick={(e) => {
                  e.preventDefault();
                  statFormValidation(e);
                }}
              >
                Save
              </button>
              <button className="btn" onClick={(e) => props.close(e)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

const DeleteRecordModal = (props) => {
  return (
    <div
      className="modal fade common-modal trash-modal stats-video-modal show"
      id="StatsAddYardDataTrashModal"
      aria-modal="true"
      role="dialog"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose}
            >
              <img src={closeGrayIcon} alt="" />
            </button>
            <div className="icon-box-first">
              <img src={trashCircleIcon} alt="" />
            </div>
            <div className="content-box">
              <h4>Delete Data?</h4>
              <p>
                <span
                  style={{
                    textAlign: 'center',
                    display: 'block',
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    color: '#3e3e3e',
                    marginBottom: '20px',
                    lineHeight: '22px',
                  }}
                >
                  Are you sure you want to delete your data?
                  <br /> You won't get it back if you do.
                </span>
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              onClick={props.onDelete}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn gray"
              data-dismiss="modal"
              onClick={props.onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DeleteWeblinkModal = ({ onClickDelete, onClickCancel }) => {
  return (
    <div
      className="modal fade common-modal trash-modal stats-video-modal show"
      id="StatsAddYardDataTrashModal"
      aria-modal="true"
      role="dialog"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => onClickCancel()}
            >
              <img src={closeGrayIcon} alt="" />
            </button>
            <div className="icon-box-first">
              <img src={trashCircleIcon} alt="" />
            </div>
            <div className="content-box">
              <p>
                <span
                  style={{
                    textAlign: 'center',
                    display: 'block',
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    color: '#3e3e3e',
                    marginBottom: '20px',
                    lineHeight: '22px',
                  }}
                >
                  Are you sure you want to delete this Weblink?
                </span>
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              onClick={() => onClickDelete('del')}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn gray"
              data-dismiss="modal"
              onClick={() => onClickCancel()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const StatsGraphModal = (props) => {
  const [statmatrixdetails, setStatmatrixdetails] = useState(null);
  useEffect(() => {
    const StatmatrixdetailsT = props.tabData
      ? props.tabData.statmatrixdetailsstatMatrixmappingidMap
        ? props.tabData.statmatrixdetailsstatMatrixmappingidMap
            .Statmatrixdetails
        : null
      : null;
    setStatmatrixdetails(StatmatrixdetailsT);
  }, []);

  const getSelectedTabYAxis = () => {
    let maxVal =
      props.tabData != undefined
        ? props.tabData.statsmatrixid == 3
          ? +props.tabData.maximumvalue + 500
          : +props.tabData.maximumvalue
        : null;
    let minVal = +props.tabData?.minimumvalue;
    let yAxis = [];
    if ((!minVal && isNaN(minVal)) || (!maxVal && isNaN(maxVal))) {
      minVal = 10;
      maxVal = 100;
    }
    if (parseFloat(maxVal) < 10) {
      if (props.tabData.statsmatrixid == 2) {
        for (let i = maxVal; i > minVal; i--) {
          yAxis.push(i + "'" + ' ' + '0' + `"`);
        }
      } else {
        for (let i = maxVal; i >= minVal; i--) {
          yAxis.push(parseInt(i));
        }
      }
    } else {
      if (props.tabData.statsmatrixid == 2) {
        for (let i = maxVal; i > minVal; i -= 2) {
          yAxis.push(i + "'" + ' ' + '0' + `"`);
        }
      } else if (parseFloat(maxVal) % 2) {
        for (let i = maxVal; i >= minVal; i -= maxVal / 10) {
          yAxis.push(parseInt(i));
        }
      } else {
        for (let i = maxVal; i >= minVal; i -= maxVal / 10) {
          yAxis.push(parseInt(i));
        }
        // this loop was adding +1 in the last item
        // for (let i = (maxVal+1); i >= minVal; i -= (maxVal + 1)/ 10) {
        //   yAxis.push(parseInt(i));
        // }
      }
    }
    return yAxis.map((value, index) => (
      <li className="item" key={index}>
        <span>{value}</span>
      </li>
    ));
  };

  const showGraph = (metricData) => {
    let percentage = null;
    let maxval =
      props.tabData != undefined
        ? props.tabData.statsmatrixid == 3
          ? +props.tabData.maximumvalue + 500
          : +props.tabData.maximumvalue
        : null;
    let maxVal = Math.floor(+maxval);
    let tabId = props?.tabData?.statsmatrixid;
    if (tabId == 2) {
      let resultData = parseFloat(metricData.jumpincentimeter * 0.0328084);
      const ftValue =
        metricData?.statmatrixdetailsstatmatrixmappingidmaprel
          ?.Statmatrixmappings?.[0]?.tabname == 'Broad Jump'
          ? Math.round(resultData * 7.5)
          : Math.round(resultData * 17);
      percentage = parseFloat(ftValue);
    } else {
      percentage = Math.round((parseFloat(metricData?.result) / maxVal) * 100);
    }
    return (
      <div className="item" key={metricData?.id}>
        <div
          className="progress-bar"
          style={{
            height: `${
              percentage != ''
                ? isNaN(percentage)
                  ? 0
                  : percentage <= 100
                  ? percentage == metricData.result
                    ? percentage + 1
                    : percentage
                  : 100
                : parseInt(metricData.result) < 5
                ? parseInt(metricData.result)
                : percentage == 0
                ? percentage + 1
                : 100
            }%`,
            backgroundColor: '#1CB79E',
            background: 'opaque',
          }}
        >
          {metricData.verified === 1 ? (
            <div className="icon-box" style={{ top: '-20px' }}>
              <span className="iconbox custom-tooltip">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.449"
                  height="16.117"
                  viewBox="0 0 14.449 16.117"
                >
                  <g transform="translate(-2.872 -1.125)">
                    <path
                      className="a"
                      d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                      transform="translate(0)"
                    ></path>
                  </g>
                </svg>
                <span clclassNameass="tooltip-data">Coach Verified</span>
              </span>
            </div>
          ) : metricData.verified === 2 ? (
            <div className="icon-box" style={{ top: '-20px' }}>
              <span className="iconbox red custom-tooltip">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.449"
                  height="16.117"
                  viewBox="0 0 20 22.309"
                >
                  <g transform="translate(-2.872 -1.125)">
                    <path
                      className="a"
                      d="M12.872,1.125a19.957,19.957,0,0,0-4.183.781q-2.075.557-4.123,1.212A2.163,2.163,0,0,0,3.074,4.879,18.012,18.012,0,0,0,6.6,18.807a16.645,16.645,0,0,0,3.595,3.421,10.354,10.354,0,0,0,1.5.872,3.09,3.09,0,0,0,1.184.334,3.08,3.08,0,0,0,1.184-.334,10.355,10.355,0,0,0,1.5-.872,16.644,16.644,0,0,0,3.6-3.421A18.018,18.018,0,0,0,22.67,4.879a2.163,2.163,0,0,0-1.492-1.761q-2.047-.655-4.123-1.212A19.954,19.954,0,0,0,12.872,1.125Zm4.244,9.45a.745.745,0,0,0-1.053-1.053l-3.935,3.937-1.7-1.706A.745.745,0,0,0,9.37,12.806L11.6,15.036a.744.744,0,0,0,1.053,0Z"
                    />
                  </g>
                </svg>
                <span className="tooltip-data">SportsForce Verified</span>
              </span>
            </div>
          ) : metricData.verified === 3 ? (
            <div className="icon-box" style={{ top: '-20px' }}>
              <span className="iconbox custom-tooltip">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.449"
                  height="16.117"
                  viewBox="0 0 14.449 16.117"
                >
                  <g transform="translate(-2.872 -1.125)">
                    <path
                      className="a"
                      d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                      transform="translate(0)"
                    ></path>
                  </g>
                </svg>
                <span className="tooltip-data">Coach Verified</span>
              </span>
              <span className="iconbox red custom-tooltip">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.449"
                  height="16.117"
                  viewBox="0 0 20 22.309"
                >
                  <g transform="translate(-2.872 -1.125)">
                    <path
                      className="a"
                      d="M12.872,1.125a19.957,19.957,0,0,0-4.183.781q-2.075.557-4.123,1.212A2.163,2.163,0,0,0,3.074,4.879,18.012,18.012,0,0,0,6.6,18.807a16.645,16.645,0,0,0,3.595,3.421,10.354,10.354,0,0,0,1.5.872,3.09,3.09,0,0,0,1.184.334,3.08,3.08,0,0,0,1.184-.334,10.355,10.355,0,0,0,1.5-.872,16.644,16.644,0,0,0,3.6-3.421A18.018,18.018,0,0,0,22.67,4.879a2.163,2.163,0,0,0-1.492-1.761q-2.047-.655-4.123-1.212A19.954,19.954,0,0,0,12.872,1.125Zm4.244,9.45a.745.745,0,0,0-1.053-1.053l-3.935,3.937-1.7-1.706A.745.745,0,0,0,9.37,12.806L11.6,15.036a.744.744,0,0,0,1.053,0Z"
                    />
                  </g>
                </svg>
                <span className="tooltip-data">SportsForce Verified</span>
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const showGraphLabel = (metricData) => {
    let heightinFeet = metricData.statmatrixdetailsheightinfeetidmaprel
      ? metricData.statmatrixdetailsheightinfeetidmaprel.Heightinfeets
        ? metricData.statmatrixdetailsheightinfeetidmaprel.Heightinfeets[0]
          ? metricData.statmatrixdetailsheightinfeetidmaprel.Heightinfeets[0]
              .heightvalue
          : ''
        : ''
      : '';
    let heightinInch = metricData.statmatrixdetailsheightininchecsidmaprel
      ? metricData.statmatrixdetailsheightininchecsidmaprel.Heightininches
        ? metricData.statmatrixdetailsheightininchecsidmaprel.Heightininches[0]
          ? metricData.statmatrixdetailsheightininchecsidmaprel
              .Heightininches[0].inchvalue
          : ''
        : ''
      : '';
    return (
      <div className="item" key={metricData.id}>
        <div className="detial-box">
          <h4>
            {metricData.jumpincentimeter
              ? metricData.jumpincentimeter + 'cm'
              : ''}
          </h4>
          <h4>
            {heightinFeet}'&nbsp;{heightinInch}" / {metricData.weight} lbs
          </h4>
          <h5>{getMetricLabel(props.tabData, metricData)}</h5>
          {metricData.verified ? (
            <p>
              VERIFIED <br />
              {!!metricData.testdate
                ? getUTCTimeDateOverall(metricData.testdate)
                : ''}
            </p>
          ) : (
            <p>
              UNVERIFIED <br />
              {!!metricData.testdate
                ? getUTCTimeDateOverall(metricData.testdate)
                : ''}
            </p>
          )}
        </div>
      </div>
    );
  };

  const getMetricLabel = (tabSelect, metricData) => {
    switch (tabSelect.statsmatrixid) {
      case 1:
        return `${metricData.result} ${tabSelect.unit}`;
      case 2:
        return `${
          metricData.statmatrixdetailsjumbidinfeetmaprel?.Feet[0]
            ? metricData.statmatrixdetailsjumbidinfeetmaprel?.Feet[0]?.feetvalue
            : ''
        }' ${
          metricData.statmatrixdetailsjumbidininchesmaprel?.Inches[0]
            ? metricData.statmatrixdetailsjumbidininchesmaprel?.Inches[0]
                ?.inchvalue
            : ''
        }"`;

      case 3:
        return `${metricData.result}`;
      default:
        return `${metricData.result} ${tabSelect.unit}`;
    }
  };

  return (
    <div
      className="modal fade common-modal stats-modal common-slide-modal show"
      id="StatsGraphModal"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.tabData?.tabname}</h5>
            <button type="button" className="close" onClick={props.onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {statmatrixdetails ? (
            <OwlCarousel
              className="modal-body stats-graph-slider owl-carousel"
              key={statmatrixdetails.length}
              loop={false}
              margin={5}
              items="1"
              nav
            >
              {statmatrixdetails.map((item, index) => (
                <div className="item" key={index}>
                  <div
                    className="stats-graph-box"
                    style={{ marginTop: '21px', marginLeft: '10px' }}
                  >
                    <div className="graph-inner">
                      <ul className="graph">{getSelectedTabYAxis()}</ul>
                      <div className="graph-flex-bar">{showGraph(item)}</div>
                    </div>
                    <div className="graph-progress-bar">
                      {showGraphLabel(item)}
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const WebLinkModal = ({
  onClose,
  matWebLick,
  onClickDone,
  onHandleWebLink,
  isValid,
  setIsValid,
  tableName,
  setIsDelPop,
  matData,
}) => {
  return (
    <div
      className="modal fade common-modal form-modal stats-modal show"
      id="WebLinkModal"
      aria-modal="true"
      role="dialog"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered editprofile-common-form">
        <form className="modal-content">
          <div className="modal-header border-none">
            <h5 className="modal-title">
              {!!tableName ? `${tableName} - Web link` : ''}{' '}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => {
                  onClose();
                  setIsValid(false);
                }}
              >
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="fieldBox mb-0">
              <label>Weblink</label>
              <div className={'sf-link-with-closebtn'}>
                <input
                  onChange={(e) => {
                    onHandleWebLink(e?.target?.value);
                    setIsValid(false);
                  }}
                  value={matWebLick}
                  type="url"
                  placeholder="e.g https://www.sportsforce.io"
                />
                {matWebLick?.length > 0 && (
                  <button
                    className={'btn'}
                    onClick={() => {
                      onHandleWebLink('');
                      setIsValid(false);
                    }}
                  >
                    <svg
                      id="close"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.047"
                      height="14.046"
                      viewBox="0 0 14.047 14.046"
                    >
                      <g id="Group_2347" data-name="Group 2347">
                        <path
                          id="Path_2319"
                          data-name="Path 2319"
                          d="M10.637,9.311A.939.939,0,1,0,9.31,10.638l5.421,5.421L9.309,21.48a.939.939,0,1,0,1.327,1.327l5.422-5.421,5.421,5.421a.939.939,0,1,0,1.327-1.327l-5.421-5.421,5.421-5.421a.939.939,0,1,0-1.327-1.327l-5.421,5.421L10.637,9.31Z"
                          transform="translate(-9.034 -9.036)"
                          fill="#c0321f"
                        />
                      </g>
                    </svg>
                  </button>
                )}
              </div>

              {isValid && (
                <p style={{ color: 'red' }}>Please enter valid Weblink</p>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn green"
              onClick={() => onClickDone()}
            >
              Update
            </button>
            <button
              type={'button'}
              className="btn"
              data-dismiss="modal"
              data-toggle="modal"
              onClick={() =>
                !!matData?.weblink
                  ? setIsDelPop(true)
                  : (onClose(), setIsValid(false))
              }
            >
              {!!matData?.weblink ? 'Delete' : 'Cancel'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export const ReportPop = ({ isClose }) => {
  return (
    <div
      className="modal common-modal submitRequest-modal"
      id="StatsSubmitRequestModal"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => isClose('cross')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.104"
                height="12.104"
                viewBox="0 0 12.104 12.104"
              >
                <path
                  id="close"
                  d="M21.1,10.059,20.045,9l-4.993,4.993L10.059,9,9,10.059l4.993,4.993L9,20.045,10.059,21.1l4.993-4.993L20.045,21.1,21.1,20.045l-4.993-4.993Z"
                  transform="translate(-9 -9)"
                  fill="#3e3e3e"
                  opacity="0.8"
                />
              </svg>
            </button>
            <div className="content-box">
              <p>
                Congrats! your stats has been submitted. Do you want to request
                for stats verification?
              </p>
              <h4>Mandatory fields</h4>
              <ul>
                <li>1. Upload image / video</li>
                <li>2. Fill weblink field</li>
              </ul>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn green" data-dismiss="modal">
              Yes, Sure
            </button>
            <button
              type="button"
              className="btn"
              onClick={() => isClose('NAG')}
            >
              No, All good
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    feetDetails: state.athlete.onBoarding,
    inchesDetails: state.athlete.onBoarding,
    onBoardingDetails: state.athlete.onBoarding,
    allJumpDataList: state.athlete.editUserProfileData.allJumpData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    footListStart: (data) => dispatch(footListStart(data)),
    inchListStart: (data) => dispatch(inchListStart(data)),
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    getJumpData: (data) => dispatch(allJumpDataListStart(data)),
    statMetricDetailsSave: (data) =>
      dispatch(allStatMatricDetailsListSave(data)),
  };
};

AddYardModalMetrics = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddYardModalMetrics);
AddYardModal = connect(mapStateToProps, mapDispatchToProps)(AddYardModal);
export {
  AddYardModalMetrics,
  AddYardModal,
  EnterSeasonStat,
  ScoutingRequest,
  AddEditModal,
  EditStatWarningModal,
  UploadVideoModal,
  DeleteVideoModal,
  UploadVideoDescritpionModal,
  StatsPersonalRecordCard,
  UploadedVideoReview,
  StatsGraphModal,
};
