import {
  AsYouType,
  parsePhoneNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import { postcodeValidator } from 'postcode-validator';

export const formatSSN = (field, value) => {
  if (!value) return value;
  let currentValue = value ? value.replace(/-|s/g, '') : '';
  const cvLength = currentValue.length;
  if (cvLength < 4) {
    // do nothing
  } else if (cvLength > 3 && cvLength < 6) {
    currentValue = `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}`;
  } else
    currentValue = `${currentValue.slice(0, 3)}-${currentValue.slice(
      3,
      5
    )}-${currentValue.slice(5)}`;

  if (currentValue.length >= 12) {
    currentValue = currentValue.substr(0, 11);
  }
  return currentValue;
};

export const IsValidEmail = (value) => {
  //this below code is done becaoue the regex only take alphabet in small letter
  const newValue = !!value ? value?.toLowerCase() : '';
  return new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)$/
  ).test(newValue);
};

export const IsValidSSN = (value) => {
  return new RegExp(/^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/).test(value);
};

export const isValidPhoneNumbers = (num) => {
  const regexArr = [
    ['^[2-9][0-9]{9}$', 'Please Enter a valid US Phone Number'],
    ['^[6-9][0-9]{9}$', 'Please Enter a valid India Phone Number'],
    ['^[0-9]{7,15}$', 'Number length should be be between 7 to 15 digits'],
  ];
  const numOnly = num
    .replace(/^[\S]+ /, '')
    .replace(/[\s()-]/g, '')
    .replace('+', '');
  if (num.indexOf('+1 ') == 0 && !new RegExp(regexArr[0][0]).test(numOnly))
    //US No
    return false;
  else if (
    num.indexOf('+91 ') == 0 &&
    !new RegExp(regexArr[1][0]).test(numOnly)
  )
    //India No
    return false;
  else if (!new RegExp(regexArr[2][0]).test(numOnly))
    //any no
    return false;
  else return true;
};

export const IsValidTwitterHandle = (value) => {
  return new RegExp(/^(?=.*\w)[\w]{1,20}$/).test(value);
};

export const IsValidInstagramHandle = (value) => {
  return new RegExp(/^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/).test(value);
};

export const IsValidName = (value) => {
  const status = new RegExp('^[a-zA-Z ]*$').test(value);
  return status;
};

export const NewValidName = (value) => {
  const status = new RegExp('^[a-zA-Z-. ]*$').test(value);
  return status;
};

export const checkPhoneNumberValidation = (number, countryShortForm = 'US') => {
  try {
    const phoneNumber = parsePhoneNumber(number, countryShortForm);
    if (phoneNumber && phoneNumber.nationalNumber) {
      const formattedNumber = new AsYouType(countryShortForm).input(
        phoneNumber.nationalNumber
      );
      const newphoneNumber = parsePhoneNumber(
        formattedNumber,
        countryShortForm
      );
      const status = newphoneNumber.isValid();
      return status;
    }
    return false;
  } catch (error) {
    return false;
  }
};
// This function is to format phone number into desired format like 000-000-0000
export const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value;
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength <= 5) return phoneNumber;
  if (phoneNumberLength <= 6) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}`;
  }
  if (phoneNumberLength <= 7) {
    return `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(2, 7)}`;
  }
  if (phoneNumberLength <= 8) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      5
    )}-${phoneNumber.slice(5, 8)}`;
  }
  if (phoneNumberLength <= 9) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 9)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

//this func is for for validation the phone number taking input (number and country short from) generic func
export const checkPhoneNumberValidationForAll = (number, countryShortForm) => {
  try {
    const phoneNumber = parsePhoneNumber(number, countryShortForm);
    if (phoneNumber && phoneNumber.nationalNumber) {
      // const formattedNumber = new AsYouType(countryShortForm).input(
      //     phoneNumber.nationalNumber
      // );
      // const newphoneNumber = parsePhoneNumber(
      //     formattedNumber,
      //     countryShortForm
      // );
      const status = isValidPhoneNumber(phoneNumber.number, countryShortForm);
      return status;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const formattedPhoneNumber = (number, countryShortForm = 'US') => {
  try {
    const phoneNumber = parsePhoneNumber(number, countryShortForm);
    if (phoneNumber && phoneNumber.nationalNumber) {
      const formattedNumber = parsePhoneNumber(
        phoneNumber.number
      ).formatNational();
      return formattedNumber;
    }
    return number;
  } catch (error) {
    return number;
  }
};

export const isValidZipcode = (zipcode, countryCode) => {
  return postcodeValidator(zipcode, countryCode);
};

export const AplhaNumericInput = (value) => {
  // return new RegExp(/^[a-z0-9]+$/i).test(value);
  return new RegExp(/^[a-z\d\-_\s]+$/i).test(value);
};
// ^[\.a-zA-Z0-9,!? ]*$
export const AplhaNumericInputComma = (value) => {
  // return new RegExp(/^[a-z0-9]+$/i).test(value);
  return new RegExp(/^[\.a-zA-Z0-9,!? ]*$/i).test(value);
};

export const alphaNumericValidation = (value) => {
  // return new RegExp(/^[a-z0-9]+$/i).test(value);
  return new RegExp(/[^A-Za-z0-9]+/g).test(value);
};

export const NumFieldSymbolArr = ['e', 'E', '+', '-'];

export const validURL = (str) => {
  const pattern = new RegExp(
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm
  );
  return !!pattern.test(str);
};

export const validVideoURL = (str) => {
  const pattern = new RegExp(
    /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com|www\.hudl\.com|youtu\.be)\/([\w\/]+)([\?].*)?$/
  );
  return !!pattern.test(str);
};

export const validStatVideoURL = (str) => {
  const pattern = new RegExp(
    /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)|vimeo|hudl?(\.com)?\/.+/gm
  );
  return !!pattern.test(str);
};

export const validYoutubeURL = (str) => {
  const pattern = new RegExp(
    /^((http|https)\:\/\/)?(www\.youtube\.com|youtu\.?be)\/((watch\?v=)?([a-zA-Z0-9]{11}))(&.*)*$/
  );
  return !!pattern.test(str);
};

export const isValidPlatformLink = (platform, link) => {
  const platformRegexMap = {
    GameChanger: /https?:\/\/web\.gc\.com/,
    MaxPreps: /https?:\/\/(www\.)?maxpreps\.com/,
    Hudl: /https?:\/\/(www\.)?hudl\.com/,
    PerfectGame: /https?:\/\/(www\.)?perfectgame\.org/,
    PrepBaseballReport: /https?:\/\/(www\.)?prepbaseballreport\.com/,
    ProspectWire: /https?:\/\/play\.prospectwire\.com/,
    FiveTool: /https?:\/\/(www\.)?fivetool\.org/,
    ProspectSelect: /https?:\/\/(www\.)?play\.ps-baseball\.com/,
    College: /https?:\/\/(www\.)?[a-z0-9]+\.[a-z0-9.]+/,
    Other: /https?:\/\/(www\.)?[a-z0-9]+\.[a-z0-9.]+/,
  };

  const regex = platformRegexMap[platform];
  return regex ? regex.test(link) : false;
};
