import axios from "axios";
import swal from "sweetalert";
import { API_URL, GRAPHQL_URL } from "../config";
import authService from "./authService";
import history from "./history";

// import { API_URL,GRAPHQL_URL } from "../Config";

// const API_URL = API_URL;
// const GRAPHQL_URL = GRAPHQL_URL;

// const API_URL = process.env.API_URL_SERVER;
// const GRAPHQL_URL = process.env.API_URL_SERVER + "/graphql";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    swal("Oops!", "Something went wrong!", "error");
  }
  if (error?.response?.status === 401) {
    history.push("/logout");
    // window.location = "/login";
    // logout();
  }
  return Promise.reject(error);
});

const setAuthTokenHeader = () => {
  const token = authService.getAuthToken();
  if (!token || token == "undefined") {
    // history.push("/logout");
    // window.location = "/login";
    // logout();
    return null;
  } else {
    return {
      access_token: token,
    };
  }
};

export const fetchMethod = (url, data, method, isAuthToken) => {
  return axios({
    url: API_URL + `${url}`,
    method,
    data,
    headers: isAuthToken ? { ...setAuthTokenHeader() } : {"x-api-key": process.env.REACT_APP_SECRET_KEY},
  });
};

export const fetchGraphMethod = (
  query,
  variables = null,
  isAuthToken = false
) => {
  let header = {};
  isAuthToken
    ? (header = setAuthTokenHeader() ? { headers: setAuthTokenHeader() } : null)
    : (header = null);
  let body;
  if (variables) {
    body = {
      query,
      variables,
    };
  } else {
    body = {
      query,
    };
  }
  if (header) {
    return axios.post(GRAPHQL_URL, body, header);
  }
  return axios.post(GRAPHQL_URL, body);
};

export default {
  fetchMethod,
  fetchGraphMethod,
};
