import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  RECRUITING_LIST_START: "RECRUITING_LIST_START",
  RECRUITING_SUCCESS: "RECRUITING_SUCCESS",
  RECRUITING_FAILED: "RECRUITING_FAILED",
};

// ============================================ Actions ========================================================
export const getRecruitingListStart = (data) => ({
  type: actionType.RECRUITING_LIST_START,
  payload: data,
});

export const getRecruitingSuccess = (data) => ({
  type: actionType.RECRUITING_SUCCESS,
  payload: data,
});

export const getRecruitingFailed = (data) => ({
  type: actionType.RECRUITING_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function getRecruitingReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.RECRUITING_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.RECRUITING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.RECRUITING_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* recruitingListSaga(action) {
    try {
        const response = yield fetchMethod(
            "/api/recruitings/recruitingValues",
            action.payload.data,
            "post",
            true
          );        
            if (response && response.status === 200) {
            const allRecruitingList =  response.data.response.data;
            yield put(getRecruitingSuccess(allRecruitingList));
        } else {
            messagePopup("", "Recruiting List failed", "error");
            yield put(getRecruitingFailed("Recruiting List failed"));
        }
    } catch (error) {
        messagePopup("", "Recruiting List failed", "error");
        yield put(getRecruitingFailed("Recruiting List failed"));
    }
}

export function* watchRecruitingListSaga() {
  yield takeEvery(actionType.RECRUITING_LIST_START, recruitingListSaga);
}
