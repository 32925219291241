import React, { useEffect, useState, useRef} from "react";
import {connect, useDispatch} from "react-redux";
import { getUserInfo, getAuthToken } from "../../../services/authService";
import { imageDownloadUrl } from "../../../config";
import "./header.scss";
import userImg from "../assets/Img/user.svg";
import { Link } from "react-router-dom";
import { athletesDetailsStart } from "../../../redux/common/athletesDetails";
import { getAllAthletesDataQuery } from "../../common/CommonQuery";
import { Dialog } from "@mui/material";
import SwitchAccountModal from "./SwitchAccountModal";
import { getParentAthleteListStart } from "../../../redux/athlete/dashboard/homepage/parentAllAthlete";
import { switchAthleteStart } from "../../../redux/athlete/dashboard/homepage/switchAthlete";
import { getParentAthleteListQuery } from "../../views/athlet/dashboard/homepage/leftSideBar/query";
import BackdropLoader from "../../common/Loader";
import { getAllCoachDetailsStart } from "../../../redux/athlete/dashboard/editUserProfile/allCoachesDetails";
import { getCoachDetailsQuery } from "../../views/athlet/dashboard/colleges/searchColleges/CollegeDetailsQuery";
import {getAthleteSysID} from '../../common/getProfileURLData'
function ProfileSettings(props) {
  const [switchModal, setSwitchModal] = useState(false);
  const [parentAthleteList, setParentAthleteList] = React.useState(null);
  const [coachData, setCoachData] = useState();
  const [dropdowMenuAvtaar, setdropdowMenuAvtaar] = useState(props.isProfileClose);
  const domRef = useRef();
  const dispatch = useDispatch()

  useEffect(()=>{
    setdropdowMenuAvtaar(props.isProfileClose)
  }, [props.isProfileClose])

  //func for closing when click outside
  useEffect(()=>{
    var handler = event =>{
      if (!domRef?.current?.contains(event?.target)){
        setdropdowMenuAvtaar(false)
      }
    }
    document?.addEventListener("mousedown", handler)
  })
  useEffect(() => {
    if (getAuthToken()) {
      if (!props.athleteData.data) {
        props.athleteListStart({
          query: getAllAthletesDataQuery,
          variables: {
            obj: {
              id: getUserInfo() ? getUserInfo().id : 0,
            },
          },
        });
      }
    }
    if (getUserInfo && getUserInfo()?.role === "GUARDIAN") {
      props.getParentAthleteListStart({
        query: getParentAthleteListQuery,
        variables: {
          obj: {
            userid: getUserInfo().athleteLoggedInId,
          },
        },
      });
    }
      props.getAllCoachDetailsStart({
        query : getCoachDetailsQuery,
        variables : {
         where : {
           id : getUserInfo() && getUserInfo().id ? getUserInfo().id : null
         }
        }
      })
  }, []);
  const getInitials = () => {
    let initial = "";
    if(getUserInfo && getUserInfo().role == "COLLEGECOUCHES"){
      let firstname = coachData && coachData[0] && coachData[0].coachfirstname && coachData[0].coachfirstname[0];
      let lastname = coachData && coachData[0] && coachData[0].coachlastname && coachData[0].coachlastname[0]  ;
      initial = firstname + lastname;
    }else{
      if (props.athleteData.data && getUserInfo && getAuthToken()) {
        let data = props.athleteData && props.athleteData.data && props.athleteData.data.athletesData && props.athleteData.data.athletesData
       let firstname = data && data.athletefirstname && data.athletefirstname[0] ;
       let lastname = data && data.athletelastname && data.athletelastname[0];
        initial= firstname + lastname ;
      }
    }
     
    return initial && initial.toUpperCase() ? initial.toUpperCase() : "";
   };

  useEffect(()=> {
  if(props.coachesList.data){
    setCoachData(props.coachesList.data)
  }
  }, [props.coachesList.data])
  useEffect(() => {
    if (props?.parentAthleteList?.data?.length > 0 && getUserInfo && getUserInfo()?.role === "GUARDIAN") {
      const filteredData = props.parentAthleteList.data[0].filter(
        (item) => item.id !== getUserInfo().id && item.isRemoved == 0
      );
      setParentAthleteList(filteredData);
    }
  }, [props.parentAthleteList.data]);

  const handlePrivacy = () => {
    localStorage.setItem("showWithSetting", true);
    dispatch({ type: "RENDER_COUNT_START", payload: 1 })
  };

  const coachProfilePhoto = coachData && coachData[0] && coachData[0].coachandteamdetailsonboardingidmaprel && 
  coachData[0].coachandteamdetailsonboardingidmaprel.Onboardings && coachData[0].coachandteamdetailsonboardingidmaprel.Onboardings[0] && 
  coachData[0].coachandteamdetailsonboardingidmaprel.Onboardings[0].profileimages ? coachData[0].coachandteamdetailsonboardingidmaprel.Onboardings[0].profileimages : "";
  //fix this bug on reference ==>> collegename
  const coachCollege = coachData && coachData[0] && coachData[0]?.coachandteamdetailscollegeidmaprel?.Colleges[0]?.collegename || "";
  const imageName =
    props.athleteData.data != null &&
    props.athleteData.data.onboardingData != null &&
    props.athleteData.data.onboardingData.profileimages != null
      ? props.athleteData.data.onboardingData.profileimages
      : "";

  const FirstName =
    props.athleteData.data != null &&
    props.athleteData.data.athletesData != null
      ? props.athleteData.data.athletesData.athletefirstname
      : "";
  const lastName =
    props.athleteData.data != null &&
    props.athleteData.data.athletesData != null
      ? props.athleteData.data.athletesData.athletelastname
      : "";

  const graduationyearValue =
    props.athleteData.data != null &&
    props.athleteData.data.athletesData != null &&
    props.athleteData.data.athletesData.graduationYear != undefined
      ? props.athleteData.data.athletesData.graduationYear
      : "";

  const sports =
    props.athleteData.data != null &&
    props.athleteData.data.athletesData != null &&
    props.athleteData.data.athletesData.sportsName != undefined
      ? props.athleteData.data.athletesData.sportsName
      : "";
  return (
    <li
        ref={domRef}
        //className={props?.isProfileClose == false ? "dropdown" : "dropdown avtaarmenudropdown"}
        className={dropdowMenuAvtaar==false?"dropdown":"dropdown avtaarmenudropdown"}
    >
      <div
      onClick={()=>props?.setIsProfileClose()}
          // onClick={()=>{console.log("11"); setdropdowMenuAvtaar(!dropdowMenuAvtaar)}}
          className="avtaar" data-toggle="dropdown">
        {!!imageName ?
            <div className="avtaar-pic">
              <img
                src={imageName ? imageDownloadUrl + `/${imageName}` : userImg}
                alt="User Profile Picture"
              />
            </div>:
            <span>{getInitials()}</span>
        }
        <ul className="dropdown-menu">
          <li className="profile-box">
            <div className="profile-info">
              <div className="pic">
                {getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ? 
                <img
                  src={coachProfilePhoto ? imageDownloadUrl + `/${coachProfilePhoto}` : userImg}/>
                  :   
                  <img
                    src={imageName ? imageDownloadUrl + `/${imageName}` : userImg}
                    alt="User Profile Picture"
                  />
              }
                
              </div>
              <div className="desgination">
                <p>
                  <strong>
                    {FirstName !== undefined ? FirstName : coachData && coachData[0] && coachData[0].coachfirstname ? coachData[0].coachfirstname : ""} {lastName !== undefined ? lastName : coachData && coachData[0] && coachData[0] && coachData[0].coachlastname ? coachData[0].coachlastname : ""}
                  </strong>
                  {getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ? 
                  <>
                    {coachData && coachData[0] && coachData[0].designation ? coachData[0].designation : null} 
                     {coachCollege ? coachCollege : ""}
                    </>
                     : 
                     <>
                  {sports} - {graduationyearValue}
                  </>
                    } 
                </p>
              </div>
            </div>
            <p>
              <Link className="view-profile-btn" to={{pathname: `/${getAthleteSysID()}`,
                state: {isPublic : false}
              }}
              onClick={()=>sessionStorage.setItem('athlete_own_profile', true)}
              >
                View Profile
              </Link>
            </p>
            {getUserInfo &&
            getUserInfo()?.role === "GUARDIAN" &&
            parentAthleteList &&
            parentAthleteList.length > 0 ? (
              <p>
                <a
                  className="view-profile-btn switch-accountProfile"
                  href={void 0}
                  onClick={(e) => {
                    e.preventDefault();
                    setSwitchModal(true);
                  }}
                >
                  Switch Account
                </a>
              </p>
            ) : null}
          </li>
          <li>
            <div className="menu-title">Account</div>
            <ul>
              <li>
                <Link to={getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ? "/myAccount" : "/passwordChange"} onClick={() => localStorage.setItem("showWithSetting", false)}>
                  <span className="icon settings"></span>
                  Account Settings
                </Link>
              </li>
              <li>
                <Link to={{pathname: `/${getAthleteSysID()}`,
                  state: {isPublic : false}
                }} onClick={() =>{
                  handlePrivacy()
                  sessionStorage.setItem('athlete_own_profile', true)
                }}>
                  <span className="icon privacy"></span>
                  Privacy Settings
                </Link>
              </li>
            {getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ? (
              <>
              <li>
                <a href ={void 0}>
                <span className="icon price-tag"></span>
                Add Staff
                </a>
              </li>
              <li>
                <a href ={void 0}>
                <span className="icon price-tag"></span>
                Search Athlete
                </a>
              </li>
              </>
            ) : (
              <>
              {/*<li>*/}
              {/*  <a href="/#/programAndPricing" onClick={() => localStorage.setItem("showWithSetting", false)}>*/}
              {/*    <span className="icon price-tag"></span>*/}
              {/*    Programs & Pricing*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li>
                <Link to="/manageAccess" onClick={() => localStorage.setItem("showWithSetting", false)}>
                  <span className="icon user"></span>
                  My Account
                </Link>
              </li>
              {/*<li>*/}
              {/*  <a href="#/paymentInfo" onClick={() => localStorage.setItem("showWithSetting", false)}>*/}
              {/*    <span className="icon dollar"></span>*/}
              {/*    Payment Info*/}
              {/*  </a>*/}
              {/*</li>*/}
              
              </>
            )}
            </ul>
          </li>
          <li>
            <div className="menu-title">Help</div>
            <ul>
              {getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ? (
                <>
                 <li>
                <a href={void 0}>
                  <span className="icon search"></span>
                  Recruiting Guidance
                </a>
              </li>
              <li>
                <Link to="/FAQ">
                  <span className="icon book"></span>
                  FAQ's
                </Link>
              </li>
              <li>
                <a href={void 0}>
                  <span className="icon help"></span>
                  Chat with Support
                </a>
              </li>
              <li>
                <a href={void 0}>
                  <span className="icon chat"></span>
                  Schedule a demo
                </a>
              </li>
                </>
              ) : (
                <>
              {/* <li>
                <a href={void 0}
                   onClick={()=> {
                    window.open('https://calendly.com/sportsforce/recruiting-assessment-round-robin','_blank');
                    localStorage.setItem("showWithSetting", false);
                   }}
                >
                  <span className="icon search"></span>
                  Schedule Evaluation
                </a>
              </li> */}
              <li>
                <Link to="/resources/HowToVideo" onClick={() => localStorage.setItem("showWithSetting", false)}>
                  <span className="icon book"></span>
                  Resource Center
                </Link>
              </li>
              <li>
                <a
                    // href="#/FAQ"
                    href={void 0}
                    onClick={() =>{
                      localStorage.setItem("showWithSetting", false)
                      window.open('https://general-faqs.helpscoutdocs.com','_blank')
                    }}
                >
                  <span className="icon help"></span>
                  FAQ's
                </a>
              </li>
              <li>
                <a href={void 0}
                   onClick={()=>window?.Beacon('open')}
                >
                  <span className={"icon chat"}></span>
                  Chat Support
                </a>
              </li>
                </>
              )}
            </ul>
          </li>
          <li>
            <div className="menu-title"></div>
            <ul>
              <li>
                <Link to="#" onClick={() => props.handleLogout()}>
                  <span className="icon signout"></span>
                  Log Out
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <Dialog open={switchModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setSwitchModal(false)
                }
              }}
              className="matDialogCustom"
      >
        <SwitchAccountModal
          onClose={() => setSwitchModal(false)}
          loading={props.switchAthlete.loading}
          parentAthleteList={parentAthleteList}
          switchAthleteStart={props.switchAthleteStart}
        />
      </Dialog>
    </li>
  );
}
const mapStateToProps = (state) => {
  return {
    athleteData: state.common.athletesData,
    parentAthleteList: state.athlete.dashboard.home.parentAthleteList,
    switchAthlete: state.athlete.dashboard.home.switchAthlete,
    coachesList : state.athlete.editUserProfileData.allCoachDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    athleteListStart: (data) => dispatch(athletesDetailsStart(data)),
    getParentAthleteListStart: (data) =>
      dispatch(getParentAthleteListStart(data)),
    switchAthleteStart: (data) => dispatch(switchAthleteStart(data)),
    getAllCoachDetailsStart : (data) => dispatch(getAllCoachDetailsStart(data)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
