import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ADD_MY_COLLEGE_START: "ADD_MY_COLLEGE_START",
  MY_COLLEGE_SUCCESS: "MY_COLLEGE_SUCCESS",
  MY_COLLEGE_FAILED: "MY_COLLEGE_FAILED",
};

// ============================================ Actions ========================================================

export const addMyCollegeStart = (data) => ({
  type: actionType.ADD_MY_COLLEGE_START,
  payload: data,
});

export const addMyCollegeSuccess = (data) => ({
  type: actionType.MY_COLLEGE_SUCCESS,
  payload: data,
});

export const addMyCollegeFailed = (data) => ({
  type: actionType.MY_COLLEGE_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function addMyCollegeReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ADD_MY_COLLEGE_START:
      return {
        ...state,
        loading: true,
      };

    case actionType.MY_COLLEGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.MY_COLLEGE_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* addMyCollegeSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Usercolleges/addCollege`,
      action.payload.data,
      "post",
      true
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        //messagePopup("", "College added successfully", "success");
        messagePopup("", "Congrats! school / college  has been listed as Favorite!", "success");
        yield put(addMyCollegeSuccess("College added successfully"));
        if (action.payload.getSearchCollegesData)
          action.payload.getSearchCollegesData();
        if (action.payload.refreshPage) action.payload.refreshPage();
      }
    } else {
      messagePopup("", "Add to favorite college failed", "error");
      yield put(addMyCollegeFailed("Add to favorite college failed"));
    }
  } catch (error) {
    messagePopup("", "Add to favorite college failed", "error");
    yield put(addMyCollegeFailed("Add to favorite college failed"));
  }
}

export function* watchAddMyCollegeSaga() {
  yield takeEvery(actionType.ADD_MY_COLLEGE_START, addMyCollegeSaga);
}
