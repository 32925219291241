import { combineReducers } from "redux";
import cardTypeListReducer from "./CardTypeList";
import saveToMembershipReducer from "./saveToMembership";
import upgradePlanReducer from "./upgradePlans";
import featuresReducer from "./featuresList";

export default combineReducers({
  upgradePlans: upgradePlanReducer,
  cardTypeList: cardTypeListReducer,
  saveToMembership: saveToMembershipReducer,
  planFeatures: featuresReducer,
});
