import React, { useEffect } from "react";
import { useState } from "react";
import "./css/notification.css";
import { Menu, MenuItem, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import closeGrayIcon from "./img/close-gray-icon.svg";
import trashCircleIcon from "./img/trash-circle-icon.svg";
import Dialog from "@mui/material/Dialog";
import messagePopup from "../../../../../utils/messagePopup";
import { saveMessageReplyApi, saveMessageApi, AddUserActivity, messageReplyUpdate, getMessageQuery, athleteParentQuery } from "./MessageQuery";
import { messageCountListStart } from '../../../../../redux/athlete/dashboard/message/messageCount';
import { connect } from "react-redux";
import moment from "moment";
import {athleteParentDropdownStart} from '../../../../../redux/athlete/dashboard/composeMessage/athleteParentDropdown';
import { messageReplyListStart, saveMessageReplyListStart } from "../../../../../redux/athlete/dashboard/messageReply/messageReply";
import {imageDownloadUrl} from '../../../../../config';
import {getMessageReplyQuery} from './MessageQuery';

const ITEM_HEIGHT = 48;

const ReplyMessage = (props) => {
    let history = useHistory();
    const [anchorE1, setAnchorE1] = React.useState(null);
  const open = Boolean(anchorE1);
  const [message, setMessage] = useState(undefined),
  [coachCount, setCoachCount] = useState(""),
  [advisorCount, setAdvisorCount] = useState(""),
  [showDeleteModal, setShowDeleteModal] = useState(false),
  [supportCount, setSupportCount] = useState(""),
  [error, setError] = useState({
      message: ""
  }),
  [messageReplyData, setMessageReplyData] = useState([]),
  [ispremium, setIsPremium] = useState(0),
  [parentData, setParentData] = useState([]),
  [placeHolderText, setPlaceholderText] = useState("Enter Your message here...");
  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };
  const handleCoachMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/coach/composeMessage" });
  };
  const handleAdvisorMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/advisor/composeMessage" });
  }
  const handleSupportMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/support/composeMessage" });
  }
  const deleteMessageData = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const createdTime = newDate => {
    let dateTime = null;
    if(moment(newDate).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')){
      dateTime = moment(newDate).format('LT');
    }
    else{
      dateTime = moment(newDate).format('DD/MM/YYYY');
    }
    return dateTime;
}
  const DeleteItem = ({ onDeleteClick, onCancelClick }) => {
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img src={trashCircleIcon} alt="trash Circle Icon" />
              </div>
              <div className="content-box">
                <h4>
                  Are you sure you want to
                  <br /> delete this message?
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onDeleteClick}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn gray"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
 
  const onDeleteHandler = (e) => {
    e.preventDefault();
    props.saveMessageReplyListStart({
      query: messageReplyUpdate,
      variables: {
        where: {msgid: parseFloat(history?.location?.state?.data?.id)},
        data: {active: 0}
      },
      nextScreen: ()=>{
        props.saveMessageReplyListStart({
          query: saveMessageApi,
          variables: {
           obj:{
             id: parseFloat(history?.location?.state?.data?.id),
             active: 0
           }
          },
          nextScreen: ()=>{
            messagePopup("", "Message deleted successfully", "success");
            history.push('/message/coach')
          }
        });
      }
    });
      setShowDeleteModal(!showDeleteModal)
  }
  const validateMessage = () => {
      let errors = error;
      errors.message = message ? '' : 'Please enter message';
      setError(errors);
      return errors.message === 'Please enter message' ? false : true;
  }
const sendMessageBtn = () => {
    if(validateMessage()){
    props.saveMessageReplyListStart({
        query: saveMessageReplyApi,
        variables: {
          obj: {
            msgid: parseFloat(history?.location?.state?.data?.id),
            msg: message,
            msgreceivercoachid: parseFloat(history?.location?.state?.data?.messagesendbycoachid),
            msgsenderid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
            subject: history?.location?.state?.data?.subject,
            collegeid: messageReplyData?.length > 0 ? parseFloat(messageReplyData[0].collegeid) : null,
            active: 1,
            parentnamevalue: JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN' && parentData?.length > 0 ? parentData[0].name : null,
            sendmsgrolevalue: JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN' ? 'Parent/Guardian' : null
        }
        },
        nextScreen: ()=>{
          props.saveMessageReplyListStart({
            query: AddUserActivity,
            variables: {
              obj: {
                messageid: parseFloat(history?.location?.state?.data?.id),
                userid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
                coachid: parseFloat(history?.location?.state?.data?.messagesendbycoachid),
            }
            },
            nextScreen: (data)=>{
              messagePopup("", "Message replied successfully", "success");
              setMessage('');
              getMessageReplyData();
              history.push({ pathname: "/message/coach/replyMessage", state: {data: history?.location?.state?.data} });
            }
          });
        }
      });
    }
}

  useEffect(()=>{
      if(history.location.state){
      getMessageReplyData();
      }
      else{
          history.push('/message/coach')
      }
  },[history.location.state]);

  const getMessageReplyData = () => {
    props.messageReplyListStart({
      query: getMessageReplyQuery,
      variables: {
        where: {
          msgid: history.location.state.data.id,
          active: 1,
          order: "id asc"
        }
      }
    });
    props.messageCountListStart({
      query: getMessageQuery,
      variables: {
        where:{
              order: 'id desc',
              messagesendto: parseFloat(JSON.parse(localStorage.getItem("user")).id),
              active: 1, 
              istrash: 1,
          }
      }
    });
    if(JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN'){
      props.athleteParentDropdownStart({
        query: athleteParentQuery,
        variables: {
          where:{
                order: 'id desc',
                userid: parseFloat(JSON.parse(localStorage.getItem("user")).athleteLoggedInId),
                active: 1,
            }
        }
      });  
    }
  }

  useEffect(()=>{
    let coachData = [];
    let supportData = [];
    let advisorData = [];
    let data = props?.messageCountList?.data?.data?.length > 0 ? props?.messageCountList?.data?.data?.map(item=>{
      if(item.userrole === 'ADVISORS'){
          advisorData.push(item);
      }
      else if(item.userrole === 'ADMIN'){
          supportData.push(item)
      }
      else if(item.userrole === 'COLLEGECOUCHES'){
          coachData.push(item);
      }
    }) : [];
    setCoachCount(coachData && coachData.length ? coachData.filter(item => item.isread === 0).length : "");
    setAdvisorCount(advisorData && advisorData.length ? advisorData.filter(item => item.isread === 0).length : "");
    setSupportCount(supportData && supportData.length ? supportData.filter(item => item.isread === 0).length : "");
  },[props.messageCountList.data]);

  useEffect(()=>{
    let data = props?.messageReplyList?.data?.data?.length > 0 ? props?.messageReplyList?.data?.data?.map(item=>{
        item['messagereplymsgsenderName'] =  item?.messagereplymsgsenderidrel?.Athletes?.length > 0 ? item?.messagereplymsgsenderidrel?.Athletes[0]?.athletefirstname.concat(item?.messagereplymsgsenderidrel?.Athletes[0]?.athletelastname ? " " + item?.messagereplymsgsenderidrel?.Athletes[0]?.athletelastname : '') : '';
        item['messagereplymsgsenderEmail'] =  item?.messagereplymsgsenderidrel?.Athletes?.length > 0 ? item?.messagereplymsgsenderidrel?.Athletes[0]?.athleteemailid : '';
        if(item?.parentnamevalue){
          let messagereplymsgsenderFirstName = item?.parentnamevalue ? item?.parentnamevalue[0].split(/\s/)[0].slice(0, 1) : '';
          let messagereplymsgsenderLastName = item?.parentnamevalue ?  item?.parentnamevalue.split(/\s/)[1].slice(0, 1) : '';
          item['messagereplymsgsenderInitials'] = messagereplymsgsenderFirstName && messagereplymsgsenderLastName ? messagereplymsgsenderFirstName[0] + messagereplymsgsenderLastName[0] : "";
        }
        else{
          let messagereplymsgsenderFirstName = item?.messagereplymsgsenderidrel?.Athletes?.length > 0 ?  item?.messagereplymsgsenderidrel?.Athletes[0]?.athletefirstname[0].split(/\s/).slice(0, 1) : '';
          let messagereplymsgsenderLastName = item?.messagereplymsgsenderidrel?.Athletes?.length > 0 ?  item?.messagereplymsgsenderidrel?.Athletes[0]?.athletelastname[0].split(/\s/).slice(0, 1) : '';
          item['messagereplymsgsenderInitials'] = messagereplymsgsenderFirstName && messagereplymsgsenderLastName ? messagereplymsgsenderFirstName[0] + messagereplymsgsenderLastName[0] : "";  
        }
        item['messagereplymsgsendercoachName'] =  item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails?.length > 0 ? item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails[0]?.coachfirstname.concat(item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails[0]?.coachlastname ? " " + item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails[0]?.coachlastname : '') : '';
        item['messagereplymsgsendercoachCollegeName'] = item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails?.length > 0 && item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails[0]?.coachandteamdetailscollegeidmaprel?.Colleges?.length > 0 ? 
                                                   item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails[0]?.coachandteamdetailscollegeidmaprel?.Colleges[0]?.collegename : '';
        item['messagereplymsgsendercoachEmail'] = item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails?.length > 0 ? item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails[0]?.coachemail : '';
        let messagereplymsgsendercoachFirstName = item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails?.length > 0 ?  item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails[0]?.coachfirstname[0].split(/\s/).slice(0, 1) : '';
        let messagereplymsgsendercoachLastName = item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails?.length > 0 ?  item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails[0]?.coachlastname[0].split(/\s/).slice(0, 1) : '';
        item['messagereplymsgsendercoachInitials'] = messagereplymsgsendercoachFirstName && messagereplymsgsendercoachLastName ? messagereplymsgsendercoachFirstName[0] + messagereplymsgsendercoachLastName[0] : ""; 
        return item;
    }) : [];
    if(data[0]?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails?.length > 0 && data[0]?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails[0]?.isathletesendmsgtocoach && data[0]?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails[0]?.coachandteamdetailscollegeidmaprel?.Colleges?.length > 0 && data[0]?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails[0]?.coachandteamdetailscollegeidmaprel?.Colleges[0]?.allowtosendmsg){
      setPlaceholderText('Enter Your message here...');
      setIsPremium(1);
    }
    else{
      setPlaceholderText('You are not allowed to send messages to coach. Please contact to administrator.');
      setIsPremium(0)
    }
   setMessageReplyData(data);
  },[props.messageReplyList.data])

  const handleSelect = e => {
    setMessage(e.target.value)
  }

  useEffect(()=>{
    if(JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN'){
      if(props?.athleteParentDropdown?.data?.data?.length > 0){
        let data = props?.athleteParentDropdown?.data?.data?.map(item=>{
          return {id: item.id, name: item.parentfirstname.concat(item.parentlastname ? ' ' + item.parentlastname : '')}
        });
        setParentData(data)
      }
    }
    },[props.athleteParentDropdown.data])

    let nameInitials = '';
    if(parentData?.length > 0){
      let firstname = parentData?.length > 0 ? parentData[0]?.name.split(/\s/)[0].slice(0, 1) : '';
      let lastname = parentData?.length > 0 ? parentData[0]?.name.split(/\s/)[1].slice(0, 1) : '';
      nameInitials = firstname?.length > 0 && lastname?.length > 0 ? firstname[0] + lastname[0] : ''; 
    }
    else{
        let firstname = JSON.parse(localStorage.getItem('user')).FirstName ? JSON.parse(localStorage.getItem('user')).FirstName[0].split(/\s/).slice(0, 1) : '';
        let lastname = JSON.parse(localStorage.getItem('user')).lastName ? JSON.parse(localStorage.getItem('user')).lastName[0].split(/\s/).slice(0, 1) : '';
        nameInitials = firstname?.length > 0 && lastname?.length > 0 ? firstname[0] + lastname[0] : ''; 
    }
  return (
    <div className="pageContent">
    <div className="container">
        <div className="notification-with-sidebar">
            <nav className="notification-sidebar">
                <ul>
                    <li className="button-link dropdown">
                        <Button className="btn lightblue dropdown-toggle" onClick={(e)=>handleClick(e)}>Compose</Button>
       <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorE1}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
          <MenuItem>
              <strong>Compose for</strong>
          </MenuItem>
          <MenuItem onClick={handleCoachMenu}>Coach</MenuItem>
          <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem>
          <MenuItem onClick={handleSupportMenu}>Support</MenuItem>
      </Menu>
                    </li>
                    <li
                className={window.location.href.includes('/coach') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/coach"})}
              >
                <div className = "link-item">
                  <span>Coaches</span> <span>{coachCount ? coachCount : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/advisor') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor"})}
              >
                <div className = "link-item">
                  <span>Advisor</span> <span>{advisorCount ? advisorCount : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/support') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/support"})}
              >
                <div className = "link-item">
                  <span>Support</span> <span>{supportCount ? supportCount : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/trash') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/trash"})}
              >
                <div  className = "link-item">
                  <span>Trash</span>
                </div>
              </li>
                </ul>
            </nav>
            <div className="notification-detail-box with-back">
                <div className="notification-search-box">
                    <div className="search-box">
                    <label>Coach</label>
                    </div>
                    <div className="notification-pagination">
                    <Link
                  to={{ pathname: "/message/coach" }}
                >
                    <span>{"<"}</span>
                    Back to messages
                </Link>
                    </div>
                </div>
                <div className="notification-messageDetail">
                   <div className="message-detailInfo">
                        <div className="subject-lineInfo">
                            <h2>{history?.location?.state?.data?.subject }</h2>
                            <button className="trash-button" onClick={()=>deleteMessageData()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                                    <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z" fill="#3e3e3e"></path>
                                </svg>                                          
                            </button>
                        </div>
                        <div className="replyMessageBlock">
                     { messageReplyData?.length > 0 ? 
                      messageReplyData?.map(item=>{
                         return item?.messagereplymsgsenderidrel?.Athletes?.length > 0 ? <div className="message-infobox replyMessage">
                            <div className="user-profile">
                            {item?.messagereplymsgsenderImage ? <img src={imageDownloadUrl + `/${item?.messagereplymsgsenderImage}`} alt="" /> : <div className="initial">
                             {item?.messagereplymsgsenderInitials}
                            </div>}
                            </div>
                            <div className="user-detial-box">
                                <div className="item">
                                    <h4 className="name">{item?.parentnamevalue ? item?.parentnamevalue : item?.messagereplymsgsenderName} {item?.messagereplymsgsenderEmail ? <span>{item?.sendmsgrolevalue ? item?.sendmsgrolevalue : `Athlete`}</span> : ''}</h4>
                                    <div className="text-box">
                                        <p>{item?.msg}</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="time-text">{createdTime(item?.createdon)}</div>
                                </div>
                            </div>
                      </div> : item?.messagereplymsgsendercoachidmaprel?.Coachandteamdetails?.length > 0 ? 
                      <div className="message-infobox replyMessage">
                      <div className="user-profile">
                      {item?.messagereplymsgsendercoachImage ? <img src={imageDownloadUrl + `/${item?.messagereplymsgsendercoachImage}`} alt="" /> : <div className="initial">
                       {item?.messagereplymsgsendercoachInitials}
                      </div>}
                      </div>
                      <div className="user-detial-box">
                          <div className="item">
                              <h4 className="name">{item?.messagereplymsgsendercoachName} {item?.messagereplymsgsendercoachEmail ? <span>{`College Coach from ${item?.messagereplymsgsendercoachCollegeName}`}</span> : ''}</h4>
                              <div className="text-box">
                                  <p>{item?.msg}</p>
                              </div>
                          </div>
                          <div className="item">
                              <div className="time-text">{createdTime(item?.createdon)}</div>
                          </div>
                      </div>
                </div>
                      : ''}) : ""}
                   </div>
                   </div>
                    <div className="message-hereInfo">
                        <div className="message-infobox">
                            <div className="user-profile">
                              {history?.location?.state?.data?.senderProfileImage ? <img src={imageDownloadUrl + `/${history?.location?.state?.data?.recieverProfileImage}`} alt="" /> : <div className="initial">
                             {nameInitials}
                            </div>}
                            </div>
                            <div className="user-detial-box">
                                <div className="item">
                                    <h4 className="name">You <span>{JSON.parse(localStorage.getItem('user')).role === 'ATHLETES' ? 'Athlete' : JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN' ? 'Parent/Guardian' : JSON.parse(localStorage.getItem('user')).role === 'COLLEGECOUCHES' ? 'College Coach' : ''}</span></h4>
                                </div>
                                <div className="item">
                                    <div className="time-text">{createdTime(new Date())}</div>
                                </div>
                            </div>
                        </div>
                        <div className="message-replyInfo">
                                <form>
                                    <div className="form-group">
                                        <label>Reply</label>
                                        <textarea 
                                          placeholder={placeHolderText}
                                          value={message ? message : ""}
                                          onChange={(e)=>handleSelect(e)}
                                          disabled = {ispremium === 1 ? false : ispremium === 0 ? true : ''}
                                          ></textarea>
                                          {error && error.message ? (
                                           <div className="error">
                                              <p>{error.message}</p>
                                            </div>
                                            ) : (
                                                 ""
                                            )}
                                    </div>
                                    <div className="form-group">
                                        <button 
                                        type="submit" 
                                        className="btn blue" 
                                        disabled = {ispremium === 1 ? false : ispremium === 0 ? true : ''}
                                        onClick={()=>sendMessageBtn()}>Send</button>
                                    </div>
                                </form>
                            </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog open={showDeleteModal}
            onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                    deleteMessageData()
                }
            }}
            className="matDialogCustom"
    >
        <DeleteItem
          onDeleteClick={(e)=>onDeleteHandler(e)}
          onCancelClick={deleteMessageData}
        />
      </Dialog>
</div>
  );
};

const mapStateToProps = state => {
  return {
    messageReplyList: state.athlete.dashboard.messageReply.messageReplyList,
    messageCountList: state.athlete.dashboard.message.messageCountList,
    athleteParentDropdown: state.athlete.dashboard.composeMessage.athleteParentDropdown
  };
};

const mapDispatchToProps = dispatch => {
    return {
      messageReplyListStart: data => dispatch(messageReplyListStart(data)),
      messageCountListStart: data => dispatch(messageCountListStart(data)),
      saveMessageReplyListStart: data => dispatch(saveMessageReplyListStart(data)),
      athleteParentDropdownStart: data => dispatch(athleteParentDropdownStart(data)) 
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(ReplyMessage);