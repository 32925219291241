import React from "react";
import TeamLogoNew from "../assets/img/TeamLogoNew.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { collegesListStart } from "../../../../../../redux/athlete/dashboard/colleges/collegeList";
import { getUserInfo } from "../../../../../../services/authService";
import { getCollegesListQuery } from "../../colleges/myColleges/CollegeListQuery";
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";
import {getCamelCase} from "../../../../../common/camelCaseFunc";
import warningMessagePopup from "../../../../../../utils/warningMagPop";
import { viewCollegeSaveStart } from "../../../../../../redux/athlete/dashboard/colleges/viewCollege";
import { saveViewCollegeQuery } from "../../colleges/searchColleges/SearchCollegesQuery";
import {imageDownloadUrl} from '../../../../../../config'
import { history } from '../../../../../../services/history'
function TargetColleges(props) {
  const [collegeList, setCollegeList] = React.useState(null);
  const history = useHistory();
  React.useEffect(() => {
    props.collegesListStart({
      query: getCollegesListQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          order: "collegerank ASC",
          active: 1,
        },
      },
    });
  }, []);

  React.useEffect(() => {
    if (props.collegesList.data) {
      const topFiveCollege = props.collegesList.data.slice(0, 5);
      setCollegeList(topFiveCollege);
    }
  }, [props.collegesList.data]);

  const handleOpenMsg = (id, isFavorite) => {
    warningMessagePopup(
      "",
      "The message center is coming soon. You are being redirected to the recruiting tab to contact college coaches directly",
      "warning"
    ).then(async (res) => {
      if(res){
        history?.push(`/colleges/details/${id ? id : null}/${isFavorite == 1 ? 1 : 0}`)
        // window.location.href = `/colleges/details/${id ? id : null}/${isFavorite == 1 ? 1 : 0}`;
      }
    })
    // history.push(`/message/coach/composeMessage/0/${id}`);
  };

  const handleCollegeClick = (collgId) => {
      props.viewCollegeSaveStart({
        query : saveViewCollegeQuery,
        variables : {
          obj : {
            athleteid : getUserInfo() ? getUserInfo().id : null,
            collegeid : collgId,
            collegeviewstatus : 1,
          }
        }
      })
  }

  return (
    <div>
      <div className="head">
        <h5>Target Colleges</h5>
        <Link to="/colleges/search">Add</Link>
      </div>
      <ul>
        {React.Children.toArray(
          collegeList && collegeList.length ? (
            collegeList.map((item) => (
              <li>
                <span className="count">#{item.collegerank}</span>
                <div className="infoBox">
                  <div className="imgBox">
                    <img src={!!item?.collegeInfo?.collegelogo ?
                        `${imageDownloadUrl}/${item?.collegeInfo?.collegelogo}`
                        :TeamLogo} alt="teamLogo" />
                  </div>
                  <div className="data">
                    <Link
                      to={`/colleges/details/${item?.collegeInfo?.id}/${item?.isfav}`}
                      onClick={() => handleCollegeClick(item?.collegeInfo?.id)}
                    >
                      <h5 className="title">
                        {item?.collegeInfo?.collegename
                          ? item.collegeInfo.collegename
                          : ""}
                      </h5>
                    </Link>
                    <div className="flexBox">
                      <span>
                            {item?.collegeInfo?.collegeconferenceidmaprel 
                              ? item?.collegeInfo?.collegeconferenceidmaprel?.Collegeconferences[0]?.conferencenmae 
                                : " "}{!!item?.collegeInfo?.collegeconferenceidmaprel?.Collegeconferences?.[0]?.conferencenmae ?
                          <br/> : ""}
                            {item?.collegeInfo ? `${item.collegeInfo.city === null || item.collegeInfo.city === undefined ? " " :
                              getCamelCase(item.collegeInfo.city)
                            }${item?.collegeInfo && item?.collegeInfo?.collegestateidmaprel ? (
                              (item.collegeInfo.city === null || item.collegeInfo.city === undefined) && (item?.collegeInfo?.collegestateidmaprel?.States[0]?.initials === null || item?.collegeInfo?.collegestateidmaprel?.States[0]?.initials === undefined) ?
                               " " : ","
                            ) : " "}
                             ${item?.collegeInfo?.collegestateidmaprel ?
                              (item?.collegeInfo?.collegestateidmaprel?.States[0]?.initials === null || item?.collegeInfo?.collegestateidmaprel?.States[0]?.initials === undefined ?
                                " " : item?.collegeInfo?.collegestateidmaprel?.States[0]?.initials
                              ) : " " }` : " "}<br />
                            {item?.collegeInfo ?
                            `${item?.collegeInfo?.collegecollegedevisionidmaprel ?
                              (item?.collegeInfo?.collegecollegedevisionidmaprel?.Collegedivisions[0]?.collegedivisionname === null || item?.collegeInfo?.collegecollegedevisionidmaprel?.Collegedivisions[0]?.collegedivisionname === undefined ?
                              " " : item?.collegeInfo?.collegecollegedevisionidmaprel?.Collegedivisions[0]?.collegedivisionname
                              ) : " " }` : " "}
                      </span>
                      {/* <span>
                        {item.collegeInfo
                          ? item.collegeInfo.collegelevel +
                            " - " +
                            getCamelCase(item.collegeInfo.locationaaddress)
                          : // +
                            // ", " +
                            // item.collegeInfo.state
                            ""}
                      </span> */}
                      <a
                        href={void 0}
                        className="forwardLink"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenMsg(item.collegeid, item.isfav);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18.37"
                          height="16"
                          viewBox="0 0 18.37 16"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <g transform="translate(-0.999 -4.999)">
                            <path
                              className="a"
                              d="M19.369,13a.3.3,0,0,1-.178.272l-17.777,7.7a.3.3,0,0,1-.374-.42L4.532,14.5a.3.3,0,0,1,.149-.128L8.186,13,4.681,11.628a.3.3,0,0,1-.149-.128L1.039,5.444a.3.3,0,0,1,.374-.42l17.777,7.7a.3.3,0,0,1,.179.272Z"
                              transform="translate(0)"
                            />
                          </g>
                        </svg> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.37px" height="16px" viewBox="0 0 24.006 19.205">
                          <g id="email" transform="translate(-1 -4)">
                            <path id="Path_369" data-name="Path 369" d="M24.973,6.512A2.948,2.948,0,0,0,22.066,4H3.963A2.948,2.948,0,0,0,1.056,6.512L13.014,14.25Z" transform="translate(-0.011)" fill="#3e3e3e" />
                            <path id="Path_370" data-name="Path 370" d="M13.438,16.971a.8.8,0,0,1-.869,0L1,9.486V21.349A2.955,2.955,0,0,0,3.952,24.3h18.1a2.955,2.955,0,0,0,2.952-2.952V9.485Z" transform="translate(0 -1.096)" fill="#3e3e3e" />
                          </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div
              style={{
                textAlign: "left",
                fontSize: "14px",
                padding: "0px 15px",
                lineHeight: "1.33",
              }}
            >
              No college is added to your target list yet
            </div>
          )
        )}
      </ul>
      <p className="manageLink">
        <Link to="/colleges/list">Manage Colleges</Link>
      </p>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    collegesList: state.athlete.dashboard.colleges.collegesList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    collegesListStart: (data) => dispatch(collegesListStart(data)),
    viewCollegeSaveStart: (data) => dispatch(viewCollegeSaveStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetColleges);
