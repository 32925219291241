import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/editProfile.scss';
import '../../../../../../assets/css/stylesheet.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  addMyCommitmentStart,
  getCommitmentStart,
} from '../../../../../../redux/athlete/profile/commitment';
import {
  saveMyCommitmentQuery,
  getAllCommitmentType,
  getAllCollegeQuery,
} from './profileQuery';
import toastr from 'toastr';
import { getMyCommitmentQuery } from '../../homepage/leftSideBar/query';
import { getUserInfo } from '../../../../../../services/authService';
import { getUTCTimeDate } from '../../../../../../utils/dateTimeFormatter';
import LeftSideEditProfile from '../../editUserProfile/LeftSideEditProfile';
import { commitmentStatusListStart } from '../../../../../../redux/common/commitmentStatus';
import { MenuItem, Select, FormControl, Dialog } from '@mui/material';
import { allCollegeDetailsListStart } from '../../../../../../redux/common/allCollegeData';
import MuiAutoComplete from '../../../../../common/AutoComplete';
import messagePopup from '../../../../../../utils/messagePopup';
import ScreenSwitch from '../../editUserProfile/ScreenSwitch';
import SubHeader from './SubHeader';
import GoTopButton from '../../editUserProfile/GoTopButton';
import { updateUserCollegeStart } from '../../../../../../redux/athlete/dashboard/colleges/updateUserCollege';
import {
  getAllOfferQuery,
  saveOffersQuery,
  saveUserCollegeQuery,
  getUserCollegedata,
} from '../../editUserProfile/OfferQuery';
import { collegesListStart } from '../../../../../../redux/athlete/dashboard/colleges/collegeList';
import { getCollegesListQuery } from '../../colleges/myColleges/CollegeListQuery';
import {
  offersListsaveStart,
  offersListStart,
} from '../../../../../../redux/athlete/dashboard/editUserProfile/offers';
import { saveUserCollege } from '../../editUserProfile/AcademicsQuery';
import {
  myProgressListStart,
  myProgressSaveStart,
} from '../../../../../../redux/athlete/dashboard/colleges/myProgress';
import {
  getAllProgresses,
  saveProgressQuery,
} from '../../colleges/searchColleges/CollegeDetailsQuery';
import {
  fetchGraphMethod,
  fetchMethod,
} from '../../../.../../../../../services/httpService';
import { getCamelCase } from '../../../../../common/camelCaseFunc';
import { getAllInterestLevelQuery } from '../../../../../common/CommonQuery';
import {
  sendAddCommitmentNotification,
  sendRemoveCommitmentNotification,
  sendAddOfferNotification,
} from '../../../../../../utils/sendNotificationToAdvisor';
import SignedCommitment from './SignedCommitment';
import { websiteDefaultUrl } from '../../../../../../config';
import { getAthleteSysID } from '../../../../../common/getProfileURLData';

const selectStyle = {
  position: 'relative',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1.4,
  display: 'block',
  marginBottom: '0.5em',
  letterSpacing: '0.00938em',
};

const EditProfile = (props) => {
  const currentDate = new Date().toISOString().split('T')[0];
  const [fields, setFields] = React.useState({
    typeOfCommitmentId: '',
    commitmentDate: currentDate,
    isvisible: true, //this is done as per aug changes doc
  });
  const [errors, setErrors] = React.useState({});
  const [collegeArray, setCollegeArray] = React.useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [previousData, setPreviousData] = useState();
  const [offersData, setOffersData] = useState(null);
  const [isFavCollegeOffer, setIsFavCollegeOffer] = useState([]);
  const [changeCommitmentDisabled, setChangeCommitmentDisabled] =
    useState(true);
  const fakeInput = useRef(null);
  const [isSaved, setIsSaved] = useState(true);
  const [sideMenuOpen, setSideMenu] = useState(false);
  const [progressData, setProgressData] = useState(null);
  const [progressId, setProgressId] = useState(null);
  const [interestLevel, setInterestLevel] = useState(null);
  //This state used for the intrestlevel as verstrong , Which will be used while commitment add for addcollege and addoffer.
  const [filteredIntrestLevel, setFilteredIntrestLevel] = useState([]);
  const signedCompRef = useRef();

  useEffect(async () => {
    let filteredIntrest = [];
    await fetchGraphMethod(getAllInterestLevelQuery, {}, true).then((res) => {
      if (res.status == 200) {
        const listData = res?.data?.data?.allInterestlevels?.Interestlevels;
        if (listData && listData.length > 0) {
          filteredIntrest = listData.filter(function (el) {
            return el.interestlevelvalue === 'Very Strong';
          });
        }
        setFilteredIntrestLevel(filteredIntrest);
      }
    });
  }, []);

  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };
  useEffect(() => {
    getCommitmentStart();
    props.commitmentStatusListStart({
      query: getAllCommitmentType,
      variables: null,
    });
    props.allCollegeDetailsListStart({
      query: getAllCollegeQuery,
      variables: {
        obj: {
          active: 1,
        },
      },
    });
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);

  useEffect(() => {
    if (props.history.location && props.history.location.state) {
      setPreviousData(props.history.location.state);
    }
  }, [props.history.location]);

  const getCommitmentStart = () => {
    props.getCommitmentStart({
      query: getMyCommitmentQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };
  useEffect(() => {
    fakeInput.current.focus();
    fakeInput.current.select();
    if (props.myCommitment && props.myCommitment.data != '') {
      let fieldsT = fields;
      fieldsT = {
        collegeName: {
          id: props.myCommitment.data
            ? props.myCommitment.data[0].collegeid
            : 0,
          collegename: props.myCommitment.data
            ? `${props?.myCommitment?.data?.[0]?.collegename ?? ''} - [${
                !!props?.myCommitment?.data[0]?.collegecommitmentcollegeidmaprel
                  ?.Colleges?.[0]?.city
                  ? getCamelCase(
                      props?.myCommitment?.data[0]
                        ?.collegecommitmentcollegeidmaprel?.Colleges?.[0]?.city
                    )
                  : ''
              }, ${
                !!props?.myCommitment?.data?.[0]
                  ?.collegecommitmentcollegeidmaprel?.Colleges?.[0]?.state
                  ? getCamelCase(
                      props?.myCommitment?.data[0]
                        ?.collegecommitmentcollegeidmaprel?.Colleges?.[0]?.state
                    )
                  : ''
              }]`
            : '',
        },
        typeOfCommitmentId: props.myCommitment.data
          ? parseInt(props.myCommitment.data[0].commitment)
          : '',
        commitmentDate: props.myCommitment.data
          ? !!props.myCommitment.data[0].commitmentdate
            ? getUTCTimeDate(props.myCommitment.data[0].commitmentdate)
            : ''
          : '',
        commitmentId: props.myCommitment.data
          ? props.myCommitment.data[0].id
          : '',
        isvisible: props.myCommitment.data
          ? props.myCommitment.data[0].isvisible
          : true, //this is done as per aug changes doc
        signedCollegeName:
          previousData && previousData.isSigned === true ? previousData : '',
      };
      setFields({
        ...fieldsT,
      });
    } else {
      setFields({
        typeOfCommitmentId:
          previousData && previousData.commitmentTypeId
            ? previousData.commitmentTypeId
            : null,
        commitmentDate: currentDate,
        isvisible: true, //this is done as per aug changes doc
        collegeName:
          previousData && previousData.isSigned === false ? previousData : '',
        commitment: '',
        signedCollegeName:
          previousData && previousData.isSigned === true ? previousData : '',
      });
    }
  }, [props.myCommitment]);

  const clearDate = (e) => {
    e.preventDefault();
    if (fields.commitmentDate) {
      const fieldsT = fields;
      fieldsT['commitmentDate'] = '';
      setChangeCommitmentDisabled(false);
      setFields({
        ...fieldsT,
      });
    }
  };
  const handleChange = (field, e) => {
    const fieldsT = fields;
    if (field == 'isvisible') {
      fieldsT[field] = e ? (e.target ? e.target.checked : e) : e;
    } else {
      fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    }

    fieldsT['isvisible'] = fields.typeOfCommitmentId === '' ? 0 : 1;

    if (fieldsT['typeOfCommitmentId']) {
      setShowAddModal(true);
    } else {
      setShowAddModal(false);
    }
    setFields({
      ...fieldsT,
    });
    setChangeCommitmentDisabled(false);
    setIsSaved(false);
    setErrors({});
  };
  useEffect(() => {
    getAllProgressData();
  }, []);
  const getAllProgressData = () => {
    props.myProgressListStart({
      query: getAllProgresses,
      variables: {
        where: {
          userid: getUserInfo() ? getUserInfo().id : null,
        },
      },
    });
  };
  useEffect(() => {
    if (props.myProgress.data && props.myProgress.data) {
      setProgressData(props.myProgress.data);
    }
  }, [props.myProgress.data]);

  useEffect(() => {
    getProgressId();
  }, [fields.collegeName]);

  const getProgressId = () => {
    const progressdata = progressData
      ? progressData?.filter((data) => {
          return data?.collegeid == fields?.collegeName?.id;
        })
      : null;
    if (progressdata && progressdata[0] && progressdata[0]?.id) {
      setProgressId(progressdata[0]?.id);
    }
  };
  useEffect(() => {
    const favCollegeData =
      props.collegesList && props.collegesList.data
        ? props.collegesList.data
        : null;
    const favCollegeId =
      favCollegeData &&
      favCollegeData?.filter((data) => {
        return data?.collegeid == fields?.collegeName?.id;
      });
    if (
      favCollegeId &&
      favCollegeId[0] &&
      favCollegeId[0].collegeinterestlevel
    ) {
      setInterestLevel(favCollegeId[0].collegeinterestlevel);
    }
  }, [props.collegesList.data]);

  const updateCommitStatus = () => {
    const favCollegeData =
      props.collegesList && props.collegesList.data
        ? props.collegesList.data
        : null;
    const favCollegeId =
      favCollegeData &&
      favCollegeData.filter((data) => {
        return data?.collegeid == fields?.collegeName?.id;
      });
    const collegeId =
      favCollegeId && favCollegeId[0] && favCollegeId[0]?.id
        ? favCollegeId[0]?.id
        : null;
    const variables = collegeId
      ? {
          obj: {
            userid: getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
            id: collegeId ? collegeId : null,
            target: 1,
            contact: 1,
            interest: 1,
            collegeactive: 1,
            offer: 1,
            commit: 1,
            visit: 1,
            signed: 0,
            alumni: 0,
          },
        }
      : {
          obj: {
            userid: getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
            collegeid: parseInt(fields.collegeName.id),
            active: 0,
            collegerank: 0,
            target: 1,
            contact: 1,
            interest: 1,
            collegeactive: 1,
            offer: 1,
            commit: 1,
            visit: 1,
            isaddbyotherplace: 1,
            signed: 0,
            alumni: 0,
          },
        };
    props.updateUserCollegeStart({
      query: saveUserCollegeQuery,
      variables,
    });
  };
  const decommitStatusUpdate = async () => {
    const favCollegeData =
      props.collegesList && props.collegesList.data
        ? props.collegesList.data
        : null;

    const favCollegeId =
      favCollegeData &&
      favCollegeData?.filter((data) => {
        return data?.collegeid == fields.collegeName?.id;
      });
    const collegeId =
      favCollegeId && favCollegeId[0] && favCollegeId[0]?.id
        ? favCollegeId[0]?.id
        : null;

    const variables =
      isFavCollegeOffer && isFavCollegeOffer.length !== 0
        ? {
            obj: {
              userid:
                getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
              id: collegeId ? collegeId : null,
              target: 1,
              contact: 1,
              interest: 1,
              collegeactive: 1,
              visit: 1,
              offer: 1,
              commit: 0,
              signed: 0,
            },
          }
        : {
            obj: {
              userid:
                getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
              id: collegeId ? collegeId : null,
              target: 1,
              contact: 1,
              interest: 1,
              collegeactive: 1,
              visit: 1,
              offer: 0,
              commit: 0,
              signed: 0,
            },
          };
    if (collegeId) {
      props.updateUserCollegeStart({
        query: saveUserCollegeQuery,
        variables,
      });
    } else {
      const commitCollegeVar = {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          order: 'id desc',
          commit: 1,
        },
        first: 1,
        last: 1,
      };
      const getUserCollegeDetails = await fetchGraphMethod(
        getUserCollegedata,
        commitCollegeVar,
        true
      );
      if (getUserCollegeDetails?.data?.data?.allUsercolleges?.Usercolleges) {
        const variables = {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : null,
            id: getUserCollegeDetails.data.data.allUsercolleges.Usercolleges[0]
              .id,
            target: 1,
            contact: 1,
            interest: 1,
            collegeactive: 1,
            visit: 1,
            offer: isFavCollegeOffer && isFavCollegeOffer.length !== 0 ? 1 : 0,
            commit: 0,
            signed: 0,
          },
        };
        await fetchGraphMethod(saveUserCollegeQuery, variables, true);
      }
    }
    props.myProgressSaveStart({
      query: saveProgressQuery,
      variables: {
        obj: {
          id: progressId,
          commitmentid: null,
          signcommitmenttopalybaseball: 0,
        },
      },
    });
  };
  useEffect(() => {
    getAllOfferList();
    getCollegesList();
  }, []);

  const getAllOfferList = () => {
    props.offersListStart({
      query: getAllOfferQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };
  useEffect(() => {
    if (props.allOffersList && props.allOffersList.data) {
      setOffersData(props.allOffersList.data);
      isCollegeAddedToOffer();
    }
  }, [props.allOffersList.data, fields.collegeName]);

  const isCollegeAddedToOffer = () => {
    const collegeOffers =
      offersData &&
      offersData.Offers &&
      offersData.Offers?.filter((collegeOffer) => {
        return (
          fields?.collegeName &&
          fields.collegeName?.id === collegeOffer?.collegeid
        );
      });
    if (collegeOffers) {
      setIsFavCollegeOffer(collegeOffers);
    }
  };
  const getCollegesList = () => {
    props.collegesListStart({
      query: getCollegesListQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          order: 'collegerank ASC',
          active: 1,
        },
      },
    });
  };

  const handelCommitment = async () => {
    const favCollegeData =
      props.collegesList && props.collegesList.data
        ? props.collegesList.data
        : null;
    const favCollegeId =
      favCollegeData &&
      favCollegeData?.filter((data) => {
        return data?.collegeid == fields.collegeName?.id;
      });
    if (
      favCollegeId &&
      favCollegeId[0] &&
      favCollegeId[0].collegeinterestlevel
    ) {
      setInterestLevel(favCollegeId[0].collegeinterestlevel);
    }
    setShowSaveModal(false);
    //split is on because clg is coming with
    // city and state
    const collegedata = {
      id: fields.collegeName.id,
      collegename: fields.collegeName.collegename?.split('-')?.[0],
      interestLevel: interestLevel,
    };
    props.addMyCommitmentStart({
      query: saveMyCommitmentQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          commitment: parseInt(fields.typeOfCommitmentId),
          collegeid: parseInt(fields.collegeName.id),
          collegename: !!fields.collegeName.collegename
            ? fields.collegeName.collegename?.split('-')?.[0]
            : 'SF', //split
          // is on because clg is coming with
          // city and state
          isvisible: fields.isvisible ? 1 : 0,
          commitmentdate: fields.commitmentDate
            ? fields.commitmentDate + 'T' + '00:00:00' + '.000Z'
            : null,
        },
      },
      nextScreen: nextScreen,
      collegeId: collegedata,
    });
    updateCommitStatus();
    updateCommitInProgressTab();
    setIsSaved(true);

    //When any offer added then marked college as fav.
    await fetchMethod(
      '/api/Usercolleges/addCollege',
      {
        collegeId: parseInt(fields.collegeName.id),
        userId: getUserInfo() ? getUserInfo().id : 0,
        interestLevel:
          filteredIntrestLevel.length > 0 ? filteredIntrestLevel[0].id : 0,
        isOffered: 1,
        isCommitment: 1,
      },
      'post',
      true
    ).then((res) => {});

    const commitmentCollageName = !!fields.collegeName.collegename
      ? fields.collegeName.collegename?.split('-')?.[0]
      : 'SF';
    sendAddCommitmentNotification(commitmentCollageName);
    sendAddOfferNotification(commitmentCollageName);
  };
  const updateCommitInProgressTab = () => {
    props.myProgressSaveStart({
      query: saveProgressQuery,
      variables: progressId
        ? {
            obj: {
              id: progressId,
              commitmentid: parseInt(fields.typeOfCommitmentId),
            },
          }
        : {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : null,
              collegeid: parseInt(fields.collegeName.id),
              commitmentid: parseInt(fields.typeOfCommitmentId),
            },
          },
    });
  };
  const saveOfferIfNotAddedBeforeCommit = (collegedata) => {
    props.offersListsaveStart({
      query: saveOffersQuery,
      variables: {
        obj: {
          userid: getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
          collegeid: collegedata.id,
          offerdate: currentDate,
          interestlevelid:
            filteredIntrestLevel.length > 0
              ? filteredIntrestLevel[0].id
              : collegedata.interestLevel,
        },
      },
    });
  };
  const nextScreen = (collegedata) => {
    const isAddedInOffer =
      offersData &&
      offersData.Offers?.filter((offer) => {
        return offer?.collegeid === fields.collegeName?.id;
      });
    props.history.push(
      `/commitmentstory/${fields?.collegeName?.id}/${getUserInfo()?.id}`
    );
    messagePopup(
      '',
      `Congrats you are committed to ${fields.collegeName.collegename}.`,
      'success'
    );
    getCommitmentStart();
    if (isAddedInOffer && isAddedInOffer.length == 0) {
      saveOfferIfNotAddedBeforeCommit(collegedata);
    }
  };

  const nextScreenUpdate = () => {
    toastr.success('Commitment Updated Successfully.', '', {
      progressBar: true,
      timeOut: 2000,
      closeButton: true,
      positionClass: 'toast-top-full-width',
      showEasing: 'swing',
      fadeIn: 40000,
      preventDuplicates: true,
    });
    getCommitmentStart();
  };

  const handelChangeCommitment = () => {
    if (
      fields.length != undefined ||
      (fields.collegeName != '' && fields.commitment != '')
    ) {
      props.addMyCommitmentStart({
        query: saveMyCommitmentQuery,
        variables: {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            id: fields.commitmentId,
            commitment: parseInt(fields.typeOfCommitmentId),
            isvisible: fields.isvisible ? 1 : 0,
            collegename:
              fields.collegeName && fields.collegeName.collegename
                ? fields.collegeName.collegename?.split('-')?.[0] ?? 'SF'
                : '', //split is on because clg is coming with
            // city and state
            collegeid:
              fields.collegeName && fields.collegeName.id
                ? parseInt(fields.collegeName.id)
                : null,
            commitmentdate: fields.commitmentDate
              ? fields.commitmentDate + 'T' + '00:00:00' + '.000Z'
              : null,
          },
        },
        nextScreen: nextScreenUpdate,
      });
      setIsSaved(true);
      setChangeCommitmentDisabled(true);
      updateCommitInProgressTab();
    }
  };
  useEffect(() => {
    if (props.collegeList.data) {
      setCollegeArray(props.collegeList.data);
    }
  }, [props.collegeList]);

  const deleteNextScreen = async () => {
    const commitmentCollageName = !!fields.collegeName.collegename
      ? fields.collegeName.collegename?.split('-')?.[0]
      : 'SF';
    await sendRemoveCommitmentNotification(commitmentCollageName);
    await getCommitmentStart();
    await decommitStatusUpdate();
    //This if condition will decommit the signed commitment on decommiting the commitment.
    if (signedCompRef.current) {
      await signedCompRef.current.inActiveSignedComm();
    }
    // messagePopup("", "Commitment Deleted Successfully.", "success");
    toastr.success('Commitment Deleted Successfully.', '', {
      progressBar: true,
      timeOut: 2000,
      closeButton: true,
      positionClass: 'toast-top-full-width',
      showEasing: 'swing',
      fadeIn: 40000,
      preventDuplicates: true,
    });
    setShowDeleteModal(false);
    setErrors({});
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const toggleSaveModal = () => {
    setShowSaveModal(!showSaveModal);
  };
  const saveAfterCheckingError = () => {
    const errorsT = errors;
    if (!fields.collegeName) {
      errorsT['collegeName'] = 'Please select your college name.';
      setShowSaveModal(false);
    }
    if (fields.collegeName) {
      setShowSaveModal(true);
    }
    setErrors({
      ...errorsT,
    });
  };
  const handelDeleteCommitment = () => {
    props.addMyCommitmentStart({
      query: saveMyCommitmentQuery,
      variables: {
        obj: {
          id: fields.commitmentId,
          active: 0,
        },
      },
      nextScreen: deleteNextScreen,
    });
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };

  const routeBackToProfile = () => {
    props.history.push({
      pathname: `${getAthleteSysID()}`,
      state: { isPublic: false },
    });
    sessionStorage.setItem('athlete_own_profile', true);
  };

  return (
    <div>
      <div className="pageContent">
        <div className="editProfile">
          <div className="container">
            <div
              id="wrapper"
              className={`flex ${sideMenuOpen ? 'inactive' : ''}`}
            >
              <SubHeader
                hamburger={onSideMenuClick}
                buttonHide={false}
                disabled={
                  fields?.commitmentId && changeCommitmentDisabled
                    ? true
                    : false
                }
                onSave={(e) =>
                  fields?.commitmentId
                    ? handelChangeCommitment()
                    : saveAfterCheckingError(e)
                }
              />
              <div className="sidebar">
                <LeftSideEditProfile isSaved={isSaved} />
              </div>
              <input className="fakeInput" type="text" ref={fakeInput} />

              {(previousData && previousData.isSigned === true) ||
              previousData === undefined ? (
                <div className="editSection">
                  <div className="flexRow">
                    <div className="title">Commitment</div>
                    <div className="btnWrap">
                      <button
                        className="btn outlined green rounded medium backprofileBtn"
                        onClick={() => routeBackToProfile()}
                      >
                        Back To Profile
                      </button>
                      <button
                        className="btn outlined green rounded medium"
                        onClick={() => routeBackToProfile()}
                      >
                        Preview Profile
                      </button>
                      <button
                        className="btn outlined blue rounded medium"
                        onClick={() => {
                          sessionStorage.setItem('athlete_own_profile', false);
                          setTimeout(() => {
                            window.open(
                              `${websiteDefaultUrl}athlete/wyatt-johnson-74144936`,
                              '_blank'
                            );
                          }, 500);
                        }}
                      >
                        Sample Profile
                      </button>
                    </div>
                  </div>
                  <div className="subTitle">Announce Commitment</div>
                  <div className="flexRow">
                    <div className="col">
                      <label>
                        College Name <sup>*</sup>
                      </label>
                      <MuiAutoComplete
                        id="free-solo-demo1"
                        freeSolo={true}
                        options={collegeArray}
                        getOptionLabel={(option) =>
                          option.collegename && option?.city && option?.state
                            ? `${
                                !!option.collegename ? option.collegename : ''
                              } - [${
                                !!option?.city ? getCamelCase(option?.city) : ''
                              }, ${
                                !!option?.state
                                  ? getCamelCase(option?.state)
                                  : ''
                              }]`
                            : option?.collegename
                        }
                        value={fields.collegeName || null}
                        disabled={
                          props.myCommitment &&
                          props.myCommitment.data &&
                          props.myCommitment.data.length !== 0
                            ? true
                            : false
                        }
                        onChangeObj={(e, data) => {
                          handleChange('collegeName', {
                            target: { value: data },
                          });
                        }}
                        inputPlaceholder="College Name"
                      />
                      <div className="error">{errors['collegeName']}</div>
                    </div>
                  </div>
                  <div className="flexRow">
                    <div className="col">
                      <div className="form-group">
                        <span style={selectStyle}>Commitment</span>
                        <div className="fieldBox">
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              id="demo-simple-select-outlined"
                              value={fields.typeOfCommitmentId || ''}
                              onChange={(e) =>
                                handleChange('typeOfCommitmentId', e)
                              }
                              displayEmpty
                            >
                              <MenuItem value="">
                                <em>Select</em>
                              </MenuItem>
                              {props.commitmentType &&
                                props.commitmentType.data &&
                                props.commitmentType.data.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    {item.commitmentstatusvalue}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="error">{errors['commitment']}</div>
                    <div className="col">
                      <label>Commitment Date</label>
                      <div className="position-relative">
                        <input
                          type="date"
                          placeholder="Commitment Date"
                          name="commitmentDate"
                          id="commitmentDate"
                          value={fields.commitmentDate || ''}
                          onChange={(e) => handleChange('commitmentDate', e)}
                          max="9999-12-31"
                        />
                        {fields.commitmentDate !== '' ? (
                          <span
                            className="date-close-icon"
                            onClick={(e) => clearDate(e)}
                          >
                            &times;
                          </span>
                        ) : null}
                      </div>
                      <div className="note">
                        Date displayed with this commitment story
                      </div>
                    </div>
                    <div className="error">{errors['commitmentDate']}</div>
                  </div>
                  {showAddModal &&
                  props.myCommitment.data == '' &&
                  fields?.typeOfCommitmentId ? (
                    <div className="flexRow subscription">
                      <div className="col">
                        <input
                          type="checkbox"
                          name=""
                          id="subscription"
                          checked={fields.isvisible}
                          onChange={(e) => {}}
                          onClick={(e) => {
                            handleChange('isvisible', e);
                          }}
                        />
                        <label htmlFor="subscription">
                          Allow news outlets to publish my commit.
                        </label>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {props?.myCommitment &&
                  props?.myCommitment?.data &&
                  props.myCommitment.data != '' ? (
                    fields?.typeOfCommitmentId ? (
                      <>
                        <div className="flexRow subscription">
                          <div className="col">
                            <input
                              type="checkbox"
                              name=""
                              id="subscription"
                              checked={fields.isvisible}
                              onChange={(e) => {}}
                              onClick={(e) => {
                                handleChange('isvisible', e);
                              }}
                            />
                            <label htmlFor="subscription">
                              Allow news outlets to publish my commit.
                            </label>
                          </div>
                        </div>
                        <div className="flexRow link">
                          View full
                          <Link
                            to={`/commitmentstory/${fields?.collegeName?.id}/${
                              getUserInfo()?.id
                            }`}
                          >
                            Commitment Story
                          </Link>
                        </div>
                        <div className="btnRow with-next-prev buttons">
                          <div className="buttons">
                            <button
                              className="btn blue-light"
                              style={{ background: '#00B9FF' }}
                              onClick={() => {
                                handelChangeCommitment();
                              }}
                              disabled={changeCommitmentDisabled}
                            >
                              Change Commitment
                            </button>
                            <button
                              className="btn primary"
                              onClick={() => {
                                toggleDeleteModal();
                              }}
                            >
                              Decommit
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flexRow link">
                          View full
                          <Link
                            to={`/commitmentstory/${fields?.collegeName?.id}/${
                              getUserInfo()?.id
                            }`}
                          >
                            Commitment Story
                          </Link>
                        </div>
                        <div className="btnRow with-next-prev buttons">
                          <div className="buttons">
                            <button
                              className="btn blue-light"
                              style={{ background: '#00B9FF' }}
                              onClick={() => {
                                handelChangeCommitment();
                              }}
                              disabled={changeCommitmentDisabled}
                            >
                              Change Commitment
                            </button>
                            <button
                              className="btn primary"
                              onClick={() => {
                                toggleDeleteModal();
                              }}
                            >
                              Decommit
                            </button>
                          </div>
                        </div>
                      </>
                    )
                  ) : null}
                  {props?.myCommitment &&
                  props?.myCommitment?.data &&
                  props.myCommitment.data != '' ? (
                    ''
                  ) : (
                    <>
                      <div className="btnRow with-next-prev">
                        <button
                          className="btn blue"
                          style={{ background: '#00B9FF' }}
                          onClick={() => {
                            saveAfterCheckingError();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </>
                  )}
                  <div
                    className="flexRow"
                    style={{ borderBottom: '1px solid #CCCCCC' }}
                  >
                    <div className="title">Signed Commitment</div>
                  </div>
                  <SignedCommitment
                    ref={signedCompRef}
                    collegeList={collegeArray}
                    history={props.history}
                    commitCollgId={
                      fields.collegeName
                        ? parseInt(fields.collegeName.id)
                        : null
                    }
                    signedCollegeAuto={fields.signedCollegeName}
                    isSigned={true}
                  />
                </div>
              ) : (
                <div className="editSection">
                  <div className="flexRow">
                    <div className="title">Commitment</div>
                    {previousData && previousData.isSigned === false ? (
                      <div className="btnWrap">
                        <button
                          className="btn outlined green rounded medium backprofileBtn"
                          onClick={() => routeBackToProfile()}
                        >
                          Back To Profile
                        </button>
                        <button
                          className="btn outlined green rounded medium"
                          onClick={() => routeBackToProfile()}
                        >
                          Preview Profile
                        </button>
                        <button
                          className="btn outlined blue rounded medium"
                          onClick={() => {
                            window.open(
                              `${websiteDefaultUrl}athlete/wyatt-johnson-74144936`,
                              '_blank'
                            );
                          }}
                        >
                          Sample Profile
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="subTitle">Announce Commitment</div>
                  <div className="flexRow">
                    <div className="col">
                      <label>
                        College Name <sup>*</sup>
                      </label>
                      <MuiAutoComplete
                        id="free-solo-demo1"
                        freeSolo={true}
                        options={collegeArray}
                        getOptionLabel={(option) =>
                          option.collegename && option?.city && option?.state
                            ? `${
                                !!option.collegename ? option.collegename : ''
                              } - [${
                                !!option?.city ? getCamelCase(option?.city) : ''
                              }, ${
                                !!option?.state
                                  ? getCamelCase(option?.state)
                                  : ''
                              }]`
                            : option?.collegename
                        }
                        value={fields.collegeName || null}
                        disabled={
                          props.myCommitment &&
                          props.myCommitment.data &&
                          props.myCommitment.data.length !== 0
                            ? true
                            : false
                        }
                        onChangeObj={(e, data) => {
                          handleChange('collegeName', {
                            target: { value: data },
                          });
                        }}
                        inputPlaceholder="College Name"
                      />
                      <div className="error">{errors['collegeName']}</div>
                    </div>
                  </div>
                  <div className="flexRow">
                    <div className="col">
                      <div className="form-group">
                        <span style={selectStyle}>Commitment</span>
                        <div className="fieldBox">
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              id="demo-simple-select-outlined"
                              value={fields.typeOfCommitmentId || ''}
                              onChange={(e) =>
                                handleChange('typeOfCommitmentId', e)
                              }
                              displayEmpty
                            >
                              <MenuItem value="">
                                <em>Select</em>
                              </MenuItem>
                              {props.commitmentType &&
                                props.commitmentType.data &&
                                props.commitmentType.data.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    {item.commitmentstatusvalue}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="error">{errors['commitment']}</div>
                    <div className="col">
                      <label>Commitment Date</label>
                      <div className="position-relative">
                        <input
                          type="date"
                          placeholder="Commitment Date"
                          name="commitmentDate"
                          id="commitmentDate"
                          value={fields.commitmentDate || ''}
                          onChange={(e) => handleChange('commitmentDate', e)}
                          max="9999-12-31"
                        />
                        {fields.commitmentDate !== '' ? (
                          <span
                            className="date-close-icon"
                            onClick={(e) => clearDate(e)}
                          >
                            &times;
                          </span>
                        ) : null}
                      </div>
                      <div className="note">
                        Date displayed with this commitment story
                      </div>
                    </div>
                    <div className="error">{errors['commitmentDate']}</div>
                  </div>

                  {showAddModal &&
                  props.myCommitment.data == '' &&
                  fields?.typeOfCommitmentId ? (
                    <div className="flexRow subscription">
                      <div className="col">
                        <input
                          type="checkbox"
                          name=""
                          id="subscription"
                          checked={fields.isvisible}
                          onChange={(e) => {}}
                          onClick={(e) => {
                            handleChange('isvisible', e);
                          }}
                        />
                        <label htmlFor="subscription">
                          Allow news outlets to publish my commit.
                        </label>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {props?.myCommitment &&
                  props?.myCommitment?.data &&
                  props.myCommitment.data != '' ? (
                    fields?.typeOfCommitmentId ? (
                      <>
                        <div className="flexRow subscription">
                          <div className="col">
                            <input
                              type="checkbox"
                              name=""
                              id="subscription"
                              checked={fields.isvisible}
                              onChange={(e) => {}}
                              onClick={(e) => {
                                handleChange('isvisible', e);
                              }}
                            />
                            <label htmlFor="subscription">
                              Allow news outlets to publish my commit.
                            </label>
                          </div>
                        </div>
                        <div className="flexRow link">
                          View full
                          <Link
                            to={`/commitmentstory/${fields?.collegeName?.id}/${
                              getUserInfo()?.id
                            }`}
                          >
                            Commitment Story
                          </Link>
                        </div>
                        <div className="btnRow with-next-prev buttons">
                          <div className="buttons">
                            <button
                              className="btn blue-light"
                              style={{ background: '#00B9FF' }}
                              onClick={() => {
                                handelChangeCommitment();
                              }}
                              disabled={changeCommitmentDisabled}
                            >
                              Change Commitment
                            </button>
                            <button
                              className="btn primary"
                              onClick={() => {
                                toggleDeleteModal();
                              }}
                            >
                              Decommit
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flexRow link">
                          View full
                          <Link
                            to={`/commitmentstory/${fields?.collegeName?.id}/${
                              getUserInfo()?.id
                            }`}
                          >
                            Commitment Story
                          </Link>
                        </div>
                        <div className="btnRow with-next-prev buttons">
                          <div className="buttons">
                            <button
                              className="btn blue-light"
                              style={{ background: '#00B9FF' }}
                              onClick={() => {
                                handelChangeCommitment();
                              }}
                              disabled={changeCommitmentDisabled}
                            >
                              Change Commitment
                            </button>
                            <button
                              className="btn primary"
                              onClick={() => {
                                toggleDeleteModal();
                              }}
                            >
                              Decommit
                            </button>
                          </div>
                        </div>
                      </>
                    )
                  ) : null}
                  {props?.myCommitment &&
                  props?.myCommitment?.data &&
                  props.myCommitment.data != '' ? (
                    ''
                  ) : (
                    <div className="btnRow with-next-prev">
                      <button
                        className="btn blue"
                        style={{ background: '#00B9FF' }}
                        onClick={() => {
                          saveAfterCheckingError();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  )}
                  <SignedCommitment
                    ref={signedCompRef}
                    collegeList={collegeArray}
                    history={props.history}
                    commitCollgId={
                      fields.collegeName
                        ? parseInt(fields.collegeName.id)
                        : null
                    }
                    signedCollegeAuto={fields.signedCollegeName}
                    isSigned={false}
                  />
                </div>
              )}
            </div>
          </div>
          <GoTopButton goTop={goTop} />
        </div>
      </div>
      <Dialog
        open={showDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            toggleDeleteModal();
          }
        }}
        className="matDialogCustom"
      >
        <div
          className="modal fade common-modal form-modal commitment show"
          id="ChangeCommitmentModal"
          style={{ display: 'block' }}
        >
          <div
            className="modal-dialog width-380 modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <button
                  onClick={toggleDeleteModal}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Change Commitment</h4>
                <div className="bluelight-bg-box">
                  <p>
                    Your commitment status will be set to uncommitted, your
                    commitment story will be removed and you will once again
                    appear in searches performed by college coaches.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn primary"
                  data-dismiss="modal"
                  onClick={handelDeleteCommitment}
                >
                  Decommit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={showSaveModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            toggleSaveModal();
          }
        }}
        className="matDialogCustom"
      >
        <div
          className="modal fade common-modal form-modal commitment show"
          id="ChangeCommitmentModal"
          style={{ display: 'block' }}
        >
          <div
            className="modal-dialog width-380 modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <button
                  onClick={toggleSaveModal}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Add Commitment</h4>
                <div className="bluelight-bg-box">
                  <div className="flexRow subscription">
                    <div className="row">
                      <div className="col">
                        I understand that my commitment will be shared publicly
                        and that my coach will review the validity of the
                        information.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn lightblue"
                    data-dismiss="modal"
                    onClick={handelCommitment}
                    style={{ marginTop: '10px' }}
                  >
                    I Agree
                  </button>
                  <button
                    className="btn primary"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      toggleSaveModal();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    myCommitment: state.athlete.profile.commitments,
    commitmentType: state.common.commitmentStatus,
    collegeList: state.common.collegeDetails,
    updateUserCollege: state.athlete.dashboard.colleges.updateUserCollege,
    collegesList: state.athlete.dashboard.colleges.collegesList,
    allOffersList: state.athlete.editUserProfileData.offersData,
    myProgress: state.athlete.dashboard.colleges.myProgress,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCommitmentStart: (data) => dispatch(getCommitmentStart(data)),
    addMyCommitmentStart: (data) => dispatch(addMyCommitmentStart(data)),
    commitmentStatusListStart: (data) =>
      dispatch(commitmentStatusListStart(data)),
    allCollegeDetailsListStart: (data) =>
      dispatch(allCollegeDetailsListStart(data)),
    updateUserCollegeStart: (data) => dispatch(updateUserCollegeStart(data)),
    collegesListStart: (data) => dispatch(collegesListStart(data)),
    offersListStart: (data) => dispatch(offersListStart(data)),
    offersListsaveStart: (data) => dispatch(offersListsaveStart(data)),
    myProgressListStart: (data) => dispatch(myProgressListStart(data)),
    myProgressSaveStart: (data) => dispatch(myProgressSaveStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
