export const getOnboardingQuery = `
  query allOnboardings($obj: JSON){
    allOnboardings(where: $obj){
      Onboardings{
        averagefastball
        battingprefencevalue
        coachid
        currentlevelofbaseball
        currentlevelofschool
         coachandteamdetailsOnBoardingIdMap{
        Coachandteamdetails{
          coachphonenumber
          
        }
      }
        dateofbirth
        doyouusetwitterforsports
        exitvelocity
        fastball
        fastballvelocity
        gpascale
        gradvalue
        gpascalevalue
        heightincentimeter
        heightinfeet
        heightininches
        highschoolname
        highscoolstatus
        id
        infieldvelocity
        needhelpforcollegerecruitingprocess
        recruitingcollagename
        primaryposition
        primarypositioncolumnvalue
        profileimages
        recruitingprocess
        schoolzipcode
        secondaryposition
        secondarypositioncolumnvalue
        spinratefastball
        textmeimportantnotification
        throwingprefencevalue
        twitteraccountname
        userid
        usetwitterforschool
        wanttolearnsocialmediaforsportgoal
        weight
        weightinkilogram
        weightgpa
        yardsprint
        gpaScaleValue
        primaryPosition
        currentLevelOfBaseball
        currentLevelOfSchool
        secondaryPosition
        heightInfeet
        heightInInches
      }
    }
  } 
  `;

export const saveOnboardingQuery = `
  mutation saveOnboarding($obj: OnboardingInput!){
    saveOnboarding(obj: $obj){
        averagefastball
        battingprefencevalue
        coachid
        currentlevelofbaseball
        currentlevelofschool
        dateofbirth
        doyouusetwitterforsports
        exitvelocity
        fastball
        fastballvelocity
        gpascale
        gradvalue
        gpascalevalue
        heightincentimeter
        heightinfeet
        heightininches
        highschoolname
        highscoolstatus
        id
        infieldvelocity
        needhelpforcollegerecruitingprocess
        primaryposition
        primarypositioncolumnvalue
        profileimages
        recruitingprocess
        schoolzipcode
        secondaryposition
        secondarypositioncolumnvalue
        spinratefastball
        throwingprefencevalue
        twitteraccountname
        userid
        usetwitterforschool
        wanttolearnsocialmediaforsportgoal
        recruitingcollagename
        weight
        weightinkilogram
        weightgpa
        yardsprint
        gpaScaleValue
        primaryPosition
        currentLevelOfBaseball
        currentLevelOfSchool
        secondaryPosition
        heightInfeet
        heightInInches
    }
  } 
  `;

export const getAthleteHeightWeight = `
  query allAthleteheightandweights($obj: JSON){
    allAthleteheightandweights(where: $obj){
      Athleteheightandweights{
        id
      }
    }
    }
  `;

  export const saveCoachPhoneNumber = `
  mutation   CoachandteamdetailsUpdateAll($where:JSON,$data:JSON){
    CoachandteamdetailsUpdateAll(where:$where,data:$data)
  }
  `;
  