export const getAllPrimaryPositionsQuery = `
query allPrimaryPosition{
    allPrimarypositions{
        Primarypositions{
           id
           selectionfield
           shortform
        }
    }
  } 
  `;

  export const getAllSecondaryPositionsQuery = `
  query allSecondarypositions{
    allSecondarypositions{
      Secondarypositions{
        id
        selectionfield
        shortform
      }
    }
  }
  `;