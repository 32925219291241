import React, { useRef } from "react";
import "../../../../assets/css/stylesheet.scss";
import "./assets/css/onBoardSettingAccount.scss";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import messagePopup from "../../../../utils/messagePopup";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          {...props}
          className="settingProgressBar"
        />
        <Box top={0} left={0} bottom={0} right={0} className="progressCount">
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

function SettingAccountProgress(props) {
  const [progress, setProgress] = React.useState(10);
  const [timer, setTimer] = React.useState(null);

  React.useEffect(() => {
    let prevProgress = progress;
    const timerT = setInterval(() => {
      if (prevProgress < 90) {
        prevProgress = prevProgress + 10;
      }
      setProgress(prevProgress);
    }, 200);
    setTimer(timerT);
    return () => {
      clearInterval(timerT);
    };
  }, []);

  React.useEffect(() => {
    let timerT = null;
    if (props.signup.data && props.signup.data.id) {
      let prevProgress = progress;
      timerT = setInterval(() => {
        prevProgress = prevProgress + 10;
        if (prevProgress === 100) {
          props.history.push("/onboarding/profile");
          // const { Athlete_Sports, gender } = props.signup.data;
          // if (
          //   (gender === "Male" && Athlete_Sports === 1) ||
          //   (gender === "Female" && Athlete_Sports === 4)
          // ) {
          //   props.history.push("/onboarding/batthrow");
          // } else {
          //   props.history.push("/onboarding/positions");
          // }
        }
        setProgress(prevProgress);
      }, 100);
      setTimer(timerT);
    }
    if (props.signup.error) {
      messagePopup("", props.signup.error, "error");
      props.history.push("/setpassword");
    }
    return () => {
      clearInterval(timerT);
    };
  }, [props.signup]);

  return (
    <div>
      <div className="settingAccountSection">
        <div className="settingAccount">
          <div className="heading">
            <h1>Setting up your account</h1>
          </div>
          <div>
            <LinearProgressWithLabel value={progress} />
          </div>

          <div className="info">
            <p>Next, you get to build your profile and take</p>
            <p>control of your sports goals!</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    signup: state.auth.signup,
  };
};

export default connect(mapStateToProps)(SettingAccountProgress);
