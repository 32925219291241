import '../../../layouts/assets/css/Coachheader.css'
import '../../../../assets/css/stylesheet.css'
import '../../../layouts/assets/css/Footer.css'
import '../../../layouts/assets/css/CoachSignUp.css'
import logoH from '../claimProfile/assets/img/newLogo.png';
import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import {fetchGraphMethod, fetchMethod} from "../../../../services/httpService";
import {filterCollegeNameQuery} from "../../athlet/dashboard/editUserProfile/OfferQuery";
import { Link } from 'react-router-dom'
import MuiAutoComplete from "../../../common/AutoComplete";
import {
    IconButton,
    InputAdornment,
    TextField,
  } from "@mui/material";
  import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailExists from '../claimProfile/claimProfileStep2/EmailExists';



const initialData = {
    email: "",
    password: "",
    showPassword: false,
    confirmPassword: false,
  };

  

export const SignUpCoach = (props) =>{
    const [fields, setFields] = useState({ ...initialData });
    const [isCoachEmail, setIsCoachEmail] = useState(false),
          [signUpData, setSignUpData] = useState({}),
          [isChecked, setIsChecked] = useState(false),
          [collegeArray, setCollegeArray] = useState([]),
          [sportArray, setSportArray] = useState([]),
          [previewProfileModal, setPreviewProfileModal] = useState(false);
    useEffect(()=>{
        const status = !!props?.location?.state
        if(status){
            setIsCoachEmail(true)
            const clone = {...signUpData}
            clone['Email'] = props?.location?.state ?? ""
            setSignUpData(clone)
        }else{
            setIsCoachEmail(false)
        }
        getAllSport()
        // getAllCollege()
    },[])
    const getAllSport = async () =>{
        const res = await fetchMethod('/allAthleteSports', '','GET',false)
            if (res?.status == 200){
                setSportArray(res?.data?.data ?? [])
        }
    },
   getAllCollege = async () =>{
   const res = await fetchMethod('/allCollegeList','','GET',false)
       if (res?.status == 200){
            setCollegeArray(res?.data?.data ?? [])
       }
   },
    onHandleChange = (e, name) =>{
        let clone = {...signUpData}
        if(name == "coachCollegeId"){
            clone['coachCollegeId'] = e.target.value?.id
            setSignUpData(clone)
        }
        else if(e.target.name == "SportId"){
            clone[e.target.name] = parseInt(e.target.value)
            setSignUpData(clone)
        }
        else{
            clone[e.target.name] = e.target.value
            setSignUpData(clone)
        }
    },
    handleDropDownChange = async (collegeName) => {
        if (collegeName.target.value.toString().length > 2) {
            let res = await fetchMethod(`/allCollegeList/${collegeName.target.value}`, '', 'GET', false);
            if (res?.status == 200 && res?.statusText == "OK") {
                setCollegeArray(Array.isArray(res?.data?.data) ? res?.data?.data ?? []  : [] || []);
            }
        }
    },
    checkIfUserExistsAlready = async() => {
        let data = {
         email: signUpData?.Email,
        role: "COLLEGECOUCHES",
        }
    const res = await fetchMethod('/api/Userdata/isUserExist', data, "post",false);
    if(res.data.response.status == 201){
        setPreviewProfileModal(true);
    }else if(res.data.response.status == 200){
        onSubmit();
    }
   },
 
    onSubmit = async () =>{
        if(!!signUpData?.Email && !!signUpData?.FirstName && !!signUpData?.LastName
            && !!signUpData?.coachCollegeId 
            // && !!signUpData?.password && !!signUpData?.confirmPassword
            ){
            if(signUpData?.password == signUpData?.confirmPassword){
                if(isChecked){
                  const clone = {...signUpData}
                  clone['role'] = "COLLEGECOUCHES"
                  delete clone.confirmPassword
                    const res = await fetchMethod('/api/UserData/createcoach',clone,"POST",null)
                        if(res?.data?.response?.status == 200){
                            swal({text: res?.data?.response?.msg || "Sign Up Created",icon:"success"}).then(()=>{
                                props.history.push('/coach-login')
                            })
                        }else{
                            setPreviewProfileModal(true);
                        }
                }else{
                    swal({text :"Terms & Condition is Required",icon: "warning"})
                }
            }else{
                swal({text:"Password didn't matched", icon : "warning"})
            }
        }else{
            swal({text:"All Field Is Required",icon: "warning"})
        }
    }
  const  handleShowPasswordClick = () => {
        setFields({
          ...fields,
          showPassword: !fields.showPassword,
        });
      };

      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };  

      const  handleShowConfirmPasswordClick = () => {
        setFields({
          ...fields,
          confirmPassword: !fields.confirmPassword,
        });
      };
return(
    <>
        <section className="headerSection">
            <div className="logoPart">
                <a href="#">
                    <img src={logoH} alt="logo"/>
                </a>
            </div>
        </section>
        <div className="signUpAthleteInfoSection">
            <div className="athleteInfoBlock">
                <div className="formSection">
                    <div className="athleteTab-info">
                        <h1>Sign Up</h1>
                        <ul className="flexBox">
                            <li style={{cursor:"pointer"}} className="active" onClick={()=>setIsCoachEmail(true)}>
                                <a>
                                <span className="icon">
                                    <svg id="People_Coach_1_copy_17" data-name="People_Coach_1 copy 17"
                                         xmlns="http://www.w3.org/2000/svg" width="35.542" height="43.535"
                                         viewBox="0 0 35.542 43.535">
                                        <path id="Path_83570" data-name="Path 83570"
                                              d="M88.293,102.162l7.444-6.855,4.884-9.151c-.047-.244-.332-3.445-.332-3.445-.19-2.54-.569-6.194.142-8.664a4.073,4.073,0,0,1,2.8-2.575,6.3,6.3,0,0,1,1.185-.278A3.918,3.918,0,0,1,102,67.853a3.314,3.314,0,0,1,.237-1.148,2.9,2.9,0,0,0-.379-.035c-.379,0-.616-.278-.427-.522l1.28-2.088a2.23,2.23,0,0,1,1.66-.939l4.552-.661a2.067,2.067,0,0,1,1.944.7l1.707,2.54,4.078.8a.475.475,0,0,1,.237.139.132.132,0,0,1-.047.209h-.047a22.045,22.045,0,0,1-4.315.383,2.792,2.792,0,0,1,.047.626c0,1.914-1.754,3.48-4.125,3.9a4.177,4.177,0,0,1,1.422,1.566L113,74.325l2.086-3.48h-.237c-.664-.07-1.138-.522-1.043-1.009a.168.168,0,0,1,.047-.139l-1.28-.278.095-.592,2.56.244h0a1.28,1.28,0,0,1,.948.557,3.139,3.139,0,0,1,2.513-.244c1.233.383,1.8,1.461,1.28,2.366l-3.414,5.672a2.723,2.723,0,0,1-2.276,1.079,3.278,3.278,0,0,1-1-.139l-2.75-.87c-.047.522-.095.835-.095.835a47.129,47.129,0,0,1-1.422,6.89l.19.139,8.013,8.595a2.172,2.172,0,0,1,.379.487l5.216,8.212a2.013,2.013,0,0,1-1.091,2.923,1.741,1.741,0,0,1-.474.174,3.768,3.768,0,0,1-4.267-1.148l-5.073-7.934-6.117-4.941-4.457,5.811a3.4,3.4,0,0,1-.379.487l-7.681,7.064a4.067,4.067,0,0,1-4.078.592l-.427-.209C87.392,104.632,87.155,103.171,88.293,102.162Zm26.79-32.569c-.237-.035-.474.1-.474.278-.047.174.142.313.379.348s.474-.1.474-.278C115.51,69.766,115.32,69.627,115.083,69.593Z"
                                              transform="translate(-87.569 -62.438)" fill="#3e3e3e"/>
                                        <path id="Path_83571" data-name="Path 83571"
                                              d="M138.055,109.476a1.645,1.645,0,0,1-.616-2.644L143.65,98.9a41.675,41.675,0,0,0,.142,5.986c.047.367.047.734.095,1.065h0a.981.981,0,0,0,.047.367L141.517,109A3,3,0,0,1,138.055,109.476Z"
                                              transform="translate(-131.831 -86.431)" fill="#3e3e3e"/>
                                    </svg>
                                </span>
                                    College Coach
                                </a>
                            </li>
                            <li style={{cursor:"pointer"}} onClick={()=>swal(""," functionality under development","")}>
                                <a>
                                <span className="icon">
                                    <svg id="coach_solid" xmlns="http://www.w3.org/2000/svg" width="41.049"
                                         height="41.049" viewBox="0 0 41.049 41.049">
                                        <path id="Path_794" data-name="Path 794"
                                              d="M186.858,375.015a6.011,6.011,0,0,0,11.087,0,12.631,12.631,0,0,1-11.087,0Z"
                                              transform="translate(-171.877 -344.949)" fill="#3e3e3e"/>
                                        <path id="Path_795" data-name="Path 795"
                                              d="M33.949,368.909c-1.855-.3-3.564-.532-5.2-.726a8.409,8.409,0,0,1-16.441,0c-1.64.195-3.35.424-5.2.728a8.437,8.437,0,0,0-7.1,8.3v1.3a1.2,1.2,0,0,0,1.2,1.2H39.846a1.2,1.2,0,0,0,1.2-1.2v-1.3a8.44,8.44,0,0,0-7.1-8.3Z"
                                              transform="translate(0 -338.665)" fill="#3e3e3e"/>
                                        <path id="Path_796" data-name="Path 796"
                                              d="M127.922,8.625a11.423,11.423,0,0,1,10.7,0,8.844,8.844,0,0,0,5.349,1.026A10.975,10.975,0,0,0,134.473,0V3.688a1.2,1.2,0,0,1-2.405,0V0a10.974,10.974,0,0,0-9.495,9.656A8.838,8.838,0,0,0,127.922,8.625Z"
                                              transform="translate(-112.746)" fill="#3e3e3e"/>
                                        <circle id="Ellipse_89" data-name="Ellipse 89" cx="1.203" cy="1.203" r="1.203"
                                                transform="translate(13.946 21.727)" fill="#3e3e3e"/>
                                        <g id="Group_928" data-name="Group 928" transform="translate(7.296 9.701)">
                                          <path id="Path_797" data-name="Path 797"
                                                d="M115.052,123.611a10.535,10.535,0,0,1-6.551-1.535,9.022,9.022,0,0,0-8.545,0,10.278,10.278,0,0,1-5.3,1.346c-.387,0-.8.22-1.252.189a3.608,3.608,0,0,0,.047,6.82,10.74,10.74,0,0,0,2.4,5.788,3.6,3.6,0,0,1,3-5.6h5.364a1.2,1.2,0,1,1,0,2.405h-1.977a3.565,3.565,0,0,1,.222,1.2,3.612,3.612,0,0,1-3.608,3.608,3.574,3.574,0,0,1-2.326-.873A10.78,10.78,0,0,0,115,130.43a3.608,3.608,0,0,0,.047-6.82Zm-14.431,3.4a1.2,1.2,0,1,1,1.2-1.2A1.2,1.2,0,0,1,100.621,127.013Zm7.216,0a1.2,1.2,0,1,1,1.2-1.2A1.2,1.2,0,0,1,107.836,127.013Z"
                                                transform="translate(-91 -121)" fill="#3e3e3e"/>
                                        </g>
                                    </svg>
                                </span>
                                    High School Coach
                                </a>
                            </li>
                        </ul>
                    </div>
                    {isCoachEmail &&
                    <form>
                        <h2 className="heading">
                        <span className="checkIcon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><style>
                                {/*.a{fill:#00b9ff;}.b{fill:#fafafa;}*/}
                            </style>
                            </defs><g
                                transform="translate(-649.111 -507.111)"><path className="a"
                                                                               d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                                                                               transform="translate(649.111 507.111)"/><path
                                className="b"
                                d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                                transform="translate(511.436 348.256)"/></g></svg>
                        </span>
                            Profile Verification Requirement
                        </h2>
                        <div className="fieldBox">
                            <label>Email <sup>*</sup></label>
                            <p>The email will be used to login to your account.</p>
                            <input type="text" name={'Email'} placeholder="Email address" value={signUpData?.Email ?? ""} onChange={(e)=>onHandleChange(e)}/>
                        </div>
                        <div className="fieldBox">
                            <label>First Name <sup>*</sup></label>
                            <input type="text" placeholder="First name"
                                   name={"FirstName"}
                                   value={signUpData?.FirstName ?? ""} onChange={(e)=>onHandleChange(e)}
                            />
                        </div>
                        <div className="fieldBox">
                            <label>Last Name <sup>*</sup></label>
                            <input type="text" placeholder="Last name"
                                   name={"LastName"}
                                   value={signUpData?.LastName ?? ""} onChange={(e)=>onHandleChange(e)}
                            />
                        </div>
                        <div className="fieldBox">
                            <label>Your Sport <sup>*</sup></label>
                            <select
                                value={signUpData?.SportId ?? ""} onChange={(e)=>onHandleChange(e)}
                                name={"SportId"}
                            >
                                <option value="0">Select Sport</option>
                                {sportArray && sportArray.map((spot)=><option selected value={spot.id}>{spot?.sportName ?? "N/A"}</option>)}
                            </select>
                        </div>
                        <div className="fieldBox">
                            <label>College <sup>*</sup></label>
                            <MuiAutoComplete
                                id="free-solo-demo"
                                freeSolo={false}
                                options={collegeArray}
                                // disabled={props.previousData.length !== 0 || addOffers.collegeOfferId !== "" ? true : false}
                                getOptionLabel={(option) =>
                                    option.collegename ? option.collegename : ""
                                }
                                // value={addOffers.collegeName || ""}
                                onChange={(event, newValue) => {
                                    handleDropDownChange(
                                        { target: { value: newValue } },
                                        "coachCollegeId"
                                    );
                                }}
                                onChangeObj={(event, newValue) => {
                                    onHandleChange({ target: { value: newValue } }, "coachCollegeId");
                                    // isExistInterestLevelForUser(
                                    //     addOffers.collegeName,
                                    //     "interestLevelId"
                                    // );
                                }}
                                inputPlaceholder=""
                            />
                            {/*<input type="text" placeholder="College name here"*/}
                            {/*       name={"coachCollegeId"}*/}
                            {/*       value={signUpData?.coachCollegeId ?? ""} onChange={(e)=>onHandleChange(e)}*/}
                            {/*/>*/}
                        </div>
                        {/* <div className="fieldBox">
                            <label>Password <sup>*</sup></label>
                             <TextField 
                              type={fields.showPassword ? "text" : "password"}
                             variant="outlined"
                             className="text-field"
                            
                              placeholder="Password"
                                name={"password"}
                               autoComplete={'off'}
                                   value={signUpData?.password ?? ""} onChange={(e)=>onHandleChange(e)}
                                   InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() => handleShowPasswordClick()}
                                           onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                           {fields.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}   
                         />

                        </div>
                        <div className="fieldBox">
                            <label>Confirm Password <sup>*</sup></label>
                            <TextField 
                              type={fields.confirmPassword ? "text" : "password"}
                               placeholder="Confirm Password"
                                   name={"confirmPassword"}
                                   autoComplete={'off'}
                                   variant="outlined"
                                   className="text-field"
                                   value={signUpData?.confirmPassword ?? ""}
                                   onChange={(e)=>onHandleChange(e)}
                                   InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() => handleShowConfirmPasswordClick()}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                           {fields.confirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}   

                            />
                        </div> */}
                        <div className="fieldBox">
                            <p className="shortText">Before using our products and services, please review our <a
                                href="#">Terms of Services</a></p>
                        </div>
                        <div className="pleaseCheck">
                            <input type="checkbox"
                                   value={isChecked}
                                   onChange={(e)=>setIsChecked(e.target.checked)}
                                   // name={"checked"}
                            /><label> I state that my use of athlete data is for recruiting
                            purpose
                            only and I will not share athlete data I have obtained through SportsForce with any
                            person or party outside my institution. I also agree to the <a href="#">full terms of
                                service outlined here.</a></label>
                        </div>
                        <div className="BtnBox">
                            <button className="btn" type="button" onClick={()=>checkIfUserExistsAlready()}>Submit</button>
                        </div>
                        <div className="bottomText">
                            If you have an account <Link to={'/coach-login'}>Sign In</Link>
                        </div>
                    </form>
                    }
                </div>
            </div>
        </div>
        <section className="footerSection">
            <ul>
                <li>
                    <a href="#">Help</a>
                </li>
                <li>
                    <a href="#">Support</a>
                </li>
                <li>
                    <a href="#">FAQs</a>
                </li>
                <li>
                    <a href="#">Legal</a>
                </li>
                <li>
                    <a href="#">Disclaimer</a>
                </li>
            </ul>
        </section>
        {previewProfileModal && (
            <EmailExists
             userEmail={signUpData?.Email}
             role="COLLEGECOUCHES"/>
        )}
    </>
)
}