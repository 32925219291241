import React, { useState, useEffect } from "react";
import { Zoom } from "@mui/material";
import { Tooltip } from "@mui/material";
import closeRedIcon from "../assets/img/close-red-icon.svg";
import swal from "sweetalert";
import { validURL } from "../../../../../../utils/validationUtils";

const AddMoreTimeCodeDetails = (props) => {
  const [timeCodeList, setTimeCodeList] = useState([
    {
      itemId: 0,
      clipsource: "",
      starttime: "",
      endtime: "",
      details: "",
    },
  ]);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    let finalBug = handleValidation();
    if (finalBug) {
      props.timeCodeData(timeCodeList, finalBug);
    } else {
      props.timeCodeData(timeCodeList);
    }
  }, [timeCodeList]);

  useEffect(() => {
    if (props.videoList) {
      setTimeCodeList([
        {
          itemId: 0,
          clipsource: "",
          starttime: "",
          endtime: "",
          details: "",
        },
      ]);
    }
  }, [props.videoList]);

  const handleValidation = () => {
    let errorsT = errorMsg ? errorMsg : [];
    let isInvalidData = "";
    const pattern = /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/;
    timeCodeList.map((item, index) => {
      let tempErr = {};
      if (item.clipsource) {
        if (!validURL(item.clipsource)) {
          tempErr["clipsource"] = "Please enter valid url link";
        } else {
          tempErr["clipsource"] = "";
        }
      } else {
        tempErr["clipsource"] = "Please enter clip source value.";
      }
      if (item.starttime) {
        if (!item.starttime.match(pattern)) {
          tempErr["starttime"] = "Please enter valid start date.";
        } else {
          tempErr["starttime"] = "";
        }
      } else {
        tempErr["starttime"] = "Please enter start time.";
      }
      if (item.endtime) {
        if (item.endtime.match(pattern) && item.starttime.match(pattern)) {
          var endStarttime = item.endtime;
          var starttime = item.starttime;
          var aa1 = endStarttime.split(":");
          var aa2 = starttime.split(":");

          var d1 = new Date(
            parseInt("2001", 10),
            parseInt("01", 10) - 1,
            parseInt("01", 10),
            parseInt(aa1[0], 10),
            parseInt(aa1[1], 10),
            parseInt(aa1[2], 10)
          );
          var d2 = new Date(
            parseInt("2001", 10),
            parseInt("01", 10) - 1,
            parseInt("01", 10),
            parseInt(aa2[0], 10),
            parseInt(aa2[1], 10),
            parseInt(aa2[2], 10)
          );
          var dd1 = d1.valueOf();
          var dd2 = d2.valueOf();

          if (dd1 < dd2 || dd1 === dd2) {
            tempErr["endtime"] = "End time must be greater than start time";
          } else {
            tempErr["endtime"] = "";
          }
        } else {
          tempErr["endtime"] = "Please enter valid end time.";
        }
      } else {
        tempErr["endtime"] = "Please enter end time.";
      }
      errorsT[index] = tempErr;
      isInvalidData = tempErr["clipsource"]
        ? true
        : tempErr["starttime"]
        ? true
        : tempErr["endtime"]
        ? true
        : false;
    });
    setErrorMsg([...errorsT]);
    return isInvalidData;
  };

  const AddMoreTimeCode = (e) => {
    e.preventDefault();
    let finalBug = handleValidation();
    if (finalBug) {
      swal("", "Please enter valid input.", "error");
      return;
    }
    let List = [...timeCodeList];
    if (List.length < 20) {
      let id = List[List.length - 1].itemId;
      List = [
        ...timeCodeList,
        {
          itemId: id + 1,
          clipsource: "",
          starttime: "",
          endtime: "",
          details: "",
        },
      ];
      setTimeCodeList(List);
    } else {
      swal("", "Maximum 20 Clips are allowed!", "error");
    }
  };

  const removeTimeCode = (e, index) => {
    e.preventDefault();
    let list = [...timeCodeList];
    list.splice(index, 1);
    setTimeCodeList(list);
  };

  const handleChange = (field, e, id) => {
    let updateTimeCodeList = [...timeCodeList];
    let objIndex = updateTimeCodeList.findIndex(
      (obj) => obj.itemId == parseInt(id)
    );
    updateTimeCodeList[objIndex][`${field}`] = e.target.value;
    setTimeCodeList(updateTimeCodeList);
  };
  return (
    <div className="item-row add-special-instructions-sec">
      <h3>
        <span className="question-mark-icon vertical-middle">
          Timecode Details
          <Tooltip
            TransitionComponent={Zoom}
            placement="top"
            title={"Timecode Details"}
            arrow
          >
            <svg
              className="question-circle ml-1"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
            >
              <path
                id="Path_63060"
                data-name="Path 63060"
                d="M36,18A18,18,0,1,1,18,0,18,18,0,0,1,36,18ZM14.783,13.574h-2.97c-.068-4.243,3.217-5.7,6.2-5.7,3.143,0,6.014,1.643,6.014,5.04,0,2.43-1.429,3.587-2.8,4.628-1.658,1.258-2.272,1.728-2.272,3.344v.8H16.013L16,20.644c-.085-2.086,1.114-3.37,2.628-4.471,1.327-1,2.171-1.656,2.171-3.085,0-1.856-1.413-2.628-2.957-2.628a2.871,2.871,0,0,0-3.056,3.114Zm2.815,14.5a2.107,2.107,0,1,1,0-4.2,2.11,2.11,0,1,1,0,4.2Z"
                fillRule="evenodd"
              ></path>
            </svg>
          </Tooltip>
        </span>
        <span
          id="add-special-instructions"
          className="btn green"
          onClick={(e) => AddMoreTimeCode(e)}
        >
          Add
        </span>
      </h3>
      <ul>
        {timeCodeList
          ? timeCodeList.map((item, index) => {
              return (
                <li key={index}>
                  <div className="fieldBox flexBox">
                    <div className="four-col">
                      <input
                        type="text"
                        placeholder={`Clip ${index + 1} Source`}
                        id="clipsource"
                        name="clipsource"
                        value={item.clipsource}
                        onChange={(e) =>
                          handleChange("clipsource", e, item.itemId)
                        }
                      />
                      {errorMsg ? (
                        errorMsg[index] && errorMsg[index].clipsource ? (
                          <div style={{ color: "red" }}>
                            {errorMsg[index].clipsource}
                          </div>
                        ) : null
                      ) : null}
                    </div>
                    <div className="four-col">
                      <input
                        type="text"
                        placeholder="Start (hh:mm:ss)"
                        value={item.starttime}
                        onChange={(e) =>
                          handleChange("starttime", e, item.itemId)
                        }
                      />
                      {errorMsg ? (
                        errorMsg[index] && errorMsg[index].starttime ? (
                          <div style={{ color: "red" }}>
                            {errorMsg[index].starttime}
                          </div>
                        ) : null
                      ) : null}
                    </div>
                    <div className="four-col">
                      <input
                        type="text"
                        placeholder="End (hh:mm:ss)"
                        value={item.endtime}
                        onChange={(e) =>
                          handleChange("endtime", e, item.itemId)
                        }
                      />
                      {errorMsg ? (
                        errorMsg[index] && errorMsg[index].endtime ? (
                          <div style={{ color: "red" }}>
                            {errorMsg[index].endtime}
                          </div>
                        ) : null
                      ) : null}
                    </div>
                    <div className="four-col">
                      <input
                        type="text"
                        placeholder="Notes, Jersey, etc."
                        value={item.details}
                        onChange={(e) =>
                          handleChange("details", e, item.itemId)
                        }
                      />
                    </div>
                  </div>
                  <div className="button-box">
                    <button
                      className="special-instructions-trash"
                      onClick={(e) => {
                        e.preventDefault();
                        if (index !== 0 || timeCodeList.length > 1) {
                          removeTimeCode(e, index, item.id);
                        }
                      }}
                    >
                      <img src={closeRedIcon} />
                    </button>
                  </div>
                </li>
              );
            })
          : ""}
      </ul>
    </div>
  );
};

export default AddMoreTimeCodeDetails;
