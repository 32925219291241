import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
   ANNUAL_HOUSEHOLD_INCOME_LIST_START: "ANNUAL_HOUSEHOLD_INCOME_LIST_START",
   ANNUAL_HOUSEHOLD_INCOME_LIST_SUCCESS: "ANNUAL_HOUSEHOLD_INCOME_LIST_SUCCESS",
   ANNUAL_HOUSEHOLD_INCOME_LIST_FAILED: "ANNUAL_HOUSEHOLD_INCOME_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const annualHouseholdIncomeListStart = (data) => ({
    type: actionType.ANNUAL_HOUSEHOLD_INCOME_LIST_START,
    payload: data,
});

export const annualHouseholdIncomeListSuccess = (data) => ({
    type: actionType.ANNUAL_HOUSEHOLD_INCOME_LIST_SUCCESS,
    payload: data,
});

export const annualHouseholdIncomeListFailed = (data) => ({
    type: actionType.ANNUAL_HOUSEHOLD_INCOME_LIST_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function annualHouseholdIncomeListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.ANNUAL_HOUSEHOLD_INCOME_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.ANNUAL_HOUSEHOLD_INCOME_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.ANNUAL_HOUSEHOLD_INCOME_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* annualHouseholdIncomeListSaga(action) {
    try {
        // const response = yield fetchGraphMethod(action.payload.query, null, true);
        const response = yield fetchGraphMethod(
            action.payload.query,
             action.payload.variables,
              true);
        if (response && response.status === 200) {
            const allcommitmentType =  response.data.data.allAnnualhouseholdincomes.Annualhouseholdincomes
            yield put(annualHouseholdIncomeListSuccess(allcommitmentType));
        } else {
            messagePopup("", "Annual Household Income List failed", "error");
            yield put(annualHouseholdIncomeListFailed("Annual Household Income List failed"));
        }
    } catch (error) {
        messagePopup("", "Annual Household Income", "error");
        yield put(annualHouseholdIncomeListFailed("Annual Household Income List failed"));
    }
}

export function* watchAnnualHouseholdIncomeListSaga() {
    yield takeEvery(actionType.ANNUAL_HOUSEHOLD_INCOME_LIST_START, annualHouseholdIncomeListSaga);
}
