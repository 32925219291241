import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SEARCH_COLLEGE_LIST_API_START: "SEARCH_COLLEGE_LIST_API_START",
  SEARCH_COLLEGE_LIST_START: "SEARCH_COLLEGE_LIST_START",
  SEARCH_COLLEGE_LIST_SUCCESS: "SEARCH_COLLEGE_LIST_SUCCESS",
  SEARCH_COLLEGE_LIST_FAILED: "SEARCH_COLLEGE_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const searchCollegeListAPIStart = (data) => ({
  type: actionType.SEARCH_COLLEGE_LIST_API_START,
  payload: data,
});
export const searchCollegeListStart = (data) => ({
  type: actionType.SEARCH_COLLEGE_LIST_START,
  payload: data,
});

export const searchCollegeListSuccess = (data) => ({
  type: actionType.SEARCH_COLLEGE_LIST_SUCCESS,
  payload: data,
});

export const searchCollegeListFailed = (data) => ({
  type: actionType.SEARCH_COLLEGE_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function searchCollegeListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SEARCH_COLLEGE_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SEARCH_COLLEGE_LIST_API_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SEARCH_COLLEGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SEARCH_COLLEGE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* searchCollegeListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const allColleges = response.data.data.allColleges.Colleges
        ? response.data.data.allColleges.Colleges
        : null;
      const totalCount = response.data.data.allColleges
        ? response.data.data.allColleges.totalCount
        : 0;
      yield put(searchCollegeListSuccess({ allColleges, totalCount }));
    } else {
      messagePopup("", "COLLEGE List failed", "error");
      yield put(searchCollegeListFailed("GPA List failed"));
    }
  } catch (error) {
    messagePopup("", "COLLEGE List failed", "error");
    yield put(searchCollegeListFailed("COLLEGE List failed"));
  }
}

export function* searchCollegeListAPISaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Colleges/searchCollegeDetails`,
      action.payload?.variables,
      "post",
      true
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        const allColleges = response.data.response.data
          ? response.data.response.data
            ? response.data.response.data.data
            : null
          : null;
        const totalCount = response.data.response.data
          ? response.data.response.data.totalCount
          : 0;
        yield put(
          searchCollegeListSuccess({ colleges: allColleges, totalCount })
        );
      }
      else if (response.data.response.status === 201) {
        const totalCount = response?.data?.response?.totalCount ?? 0 // this for pagination
        yield put(searchCollegeListSuccess({ colleges: null, totalCount }));
      }
      else if (response.data.response.status === 404) {
        action?.payload?.nextFunc(action)
        // messagePopup("",!!response?.data?.response?.data?.msg ? response?.data?.response?.data?.msg
        //     :  "College search List failed", "error")
        yield put(searchCollegeListSuccess("College search List failed"));
      }
      else {
        messagePopup("", "College search List failed", "error");
        yield put(searchCollegeListFailed("College search List failed"));
      }
    } else {
      messagePopup("", "College search List failed", "error");
      yield put(searchCollegeListFailed("College search List failed"));
    }
  } catch (error) {
    messagePopup("", "College search List failed", "error");
    yield put(searchCollegeListFailed("College search List failed"));
  }
}

export function* watchSearchCollegeListSaga() {
  yield takeEvery(
    actionType.SEARCH_COLLEGE_LIST_API_START,
    searchCollegeListAPISaga
  );
  yield takeEvery(actionType.SEARCH_COLLEGE_LIST_START, searchCollegeListSaga);
}
