import React, { useEffect, useRef, useState } from "react";
import LeftSideEditProfile from "./LeftSideEditProfile";
import {
  AplhaNumericInput,
  checkPhoneNumberValidation,
  checkPhoneNumberValidationForAll,
  formatPhoneNumber,
  formattedPhoneNumber,
  IsValidEmail,
  IsValidInstagramHandle,
  IsValidName,
  IsValidTwitterHandle,
  isValidZipcode,
} from "../../../../../utils/validationUtils";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MenuItem, Select } from "@mui/material";
import SubHeader from "./SubHeader";
import { connect, useDispatch } from "react-redux";
import { stateListStart } from "../../../../../redux/common/allStates";
import { countryListStart } from "../../../../../redux/common/countriesList";
import { countryDependentListStart } from "../../../../../redux/common/countryDependentList";
import { relationshipListStart } from "../../../../../redux/common/relationships";
import { updateBasicUserProfileListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/updateBasicUserProfile";
import { getUserInfo } from "../../../../../services/authService";
import { updateAthleteDataQuery, getCountryApiData } from "./ContactQuery";
import AutoCompleteCitySearch from "../../../../common/AutoCompleteCitySearch";
import AddMoreParent from "./AddMoreParent";
import PhoneNumber from "../../../../common/PhoneNumber";
import { saveOnboardingStart } from "../../../../../redux/athlete/onBoarding/onboarding";
import { saveOnboardingVitalsDataQuery } from "./vitalQuery";
import messagePopup from "../../../../../utils/messagePopup";
import { updateParentListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/updateParentData";
import ScreenSwitch from "./ScreenSwitch";
import GoTopButton from "./GoTopButton";
import { allParentQuery } from "./ContactQuery";
import { athletesDetailsStart } from "../../../../../redux/common/athletesDetails";
import { getAllAthletesDataQuery } from "../../../../common/CommonQuery";
import { athleteParentMappingStart } from "../../../../../redux/athlete/onBoarding/athleteParentMapping";
import { useHistory } from "react-router";
import { fetchMethod } from "../../../../../services/httpService";
import { websiteDefaultUrl } from "../../../../../config";
import toastr from "toastr";
import { AutoVerify } from "../../../../common/autoVerify";
import { getAthleteSysID } from "../../../../common/getProfileURLData";
function Contact(props) {
  const [fields, setFields] = useState({
    user: getUserInfo() ? getUserInfo() : 0,
    email: "",
    phoneNumber: "",
    twitterhandle: "",
    instagram: "",
    zipcode: "",
    state: "",
    relationship: "",
    notMyNumber: false,
    homeaddress: "",
    Address: "",
    addressContinued: "",
    city: "",
    outsideUSCanada: false,
    countryCodeId: "",
    phoneRelationship: "",
    homeCountry: "",
    onboardingId: "",
  });
  const [errors, setErrors] = useState({});
  const [hamburger, setHamburger] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [updatedParentData, setUpdatedParentData] = useState({});
  const [fullAddress, setFullAddress] = useState("");
  const [autocompleteValue, setAutocompleteValue] = useState(false);
  const [isSaved, setIsSaved] = useState(true),
    [isStateChange, setIsStateChange] = useState(false),
    [countryData, setCountryData] = useState([]);
  const [previousData, setPreviousData] = useState();
  const fakeInput = useRef(null);
  const [isContactStatus, setIsContactStatus] = useState(true),
    [isParentDataChange, setIsParentChange] = useState(false),
    [isNotificationStatusChange, setIsNotificationStatusChange] =
      useState(false),
    [isOut, setIsOut] = useState(false),
    [isAddEdit, setIsAddEdit] = useState(false),
    [checkParentLength, setCheckParentLength] = useState(0);
  //is to detect the length of p/g
  //for rendering the progress bar
  const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    props.countryListStart();
    props.stateListStart();
    props.relationshipListStart();
    getAthleteProfile();
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);

  useEffect(() => {
    if (props.location.state) {
      setPreviousData(props.location.state);
    }
  }, [props.location.state]);
  const getAthleteProfile = () => {
    if (props.athleteData.data) {
      props.athleteListStart({
        query: getAllAthletesDataQuery,
        variables: {
          obj: {
            id: getUserInfo() ? getUserInfo().id : 0,
          },
        },
      });
    }
    window.scrollTo(0, 0);
  };
  const getUserAge = () => {
    if (
      props.athleteData &&
      props.athleteData.data &&
      props.athleteData.data.onboardingData &&
      props.athleteData.data.onboardingData.dateofbirth
    ) {
      let dob = new Date(props.athleteData.data.onboardingData.dateofbirth);
      var month_diff = Date.now() - dob.getTime();

      //convert the calculated difference in date format
      var age_dt = new Date(month_diff);

      //extract year from date
      var year = age_dt.getUTCFullYear();

      //now calculate the age of the user
      var age = Math.abs(year - 1970);
      return age;
    }
    return 0;
  };

  useEffect(() => {
    if (props.allCountries.data) {
      //this code is commented as it is setting the state by defult usa which is a bug
      //and state is setting 2 time
      // const fieldsT = fields;
      // fieldsT["countryCodeId"] = {
      //   value: props.allCountries.data ? props.allCountries.data[0].id : "",
      //   label: props.allCountries.data
      //     ? props.allCountries.data[0].countryName +
      //     `(+${props.allCountries.data[0].countryCode})`
      //     : "",
      //   flag: props.allCountries.data
      //     ? props.allCountries.data[0].countryFlagImage
      //     : "",
      //   countryCode: props.allCountries.data
      //     ? props.allCountries.data[0].countryCode
      //     : "",
      //   countryShortName: props.allCountries.data
      //     ? props.allCountries.data[0].countryShortName
      //     : "",
      // };
      // setFields({
      //   ...fieldsT,
      // });
    }
  }, [props.allCountries.data]);

  useEffect(() => {
    if (props.athleteData.data) {
      const fieldT = fields;
      const { onboardingData, athletesData } = props.athleteData.data;
      let athleteCountryCodeId = athletesData?.athletecountrycodeid
        ? athletesData?.athletecountrycodeid
        : 1;
      fieldT["userData"] = athletesData;
      fieldT["email"] = athletesData.athleteemailid;
      fieldT["phoneNumber"] = athletesData.athletephonenumber;
      fieldT["gcountryCode"] = athletesData.athletecountryflag;
      fieldT["outsideUSCanada"] =
        athletesData.homeoutsideusorcanada == 0
          ? false
          : athletesData.homeoutsideusorcanada == 1
          ? true
          : false;
      fieldT["outsidecountryid"] = athletesData?.outsidecountryid ?? 1;
      fieldT["phoneRelationship"] = athletesData.phonenumberrelation
        ? athletesData.phonenumberrelation
        : "";
      fieldT["Address"] = athletesData.address;
      fieldT["addressContinued"] = athletesData.addresscountinue;
      fieldT["city"] = athletesData.city;
      if (!isStateChange) {
        fieldT["state"] = parseFloat(athletesData.stateid);
        fieldT["homeCountry"] = parseFloat(athletesData.homecountryid);
      }
      fieldT["zipcode"] = athletesData.homezipcode;
      fieldT["onboardingId"] =
        onboardingData && onboardingData.id ? onboardingData.id : "";
      fieldT["twitterhandle"] =
        onboardingData && onboardingData.twitteraccountname
          ? onboardingData.twitteraccountname
          : "";
      fieldT["instagram"] =
        onboardingData && onboardingData.instagramaccountname
          ? onboardingData.instagramaccountname
          : "";
      fieldT["zipcode"] = athletesData.athletezipcode;
      fieldT["countryCodeId"] =
        props.allCountries.data &&
        props.allCountries.data
          .filter((item) => +item.id === athleteCountryCodeId)
          .map((item1) => ({
            value: item1 ? item1.id : "",
            label: item1 ? item1.countryName + `(+${item1.countryCode})` : "",
            flag: item1 ? item1.countryFlagImage : "",
            countryCode: item1.countryCode ? item1.countryCode : 1,
            //earlier this key is not added due this bugs is created
            countryShortName: !!item1?.countryShortName
              ? item1?.countryShortName
              : "US",
          }))[0];
      //earlier this key is not there now this key is added to seperate
      // the phone number and manula address 28/4/2022
      fieldT["manualCountryShortName"] =
        props.allCountries.data &&
        props.allCountries.data
          .filter((item) => +item.id === +athletesData.homecountryid)
          .map((item1) => ({
            value: item1 ? item1.id : "",
            label: item1 ? item1.countryName + `(+${item1.countryCode})` : "",
            flag: item1 ? item1.countryFlagImage : "",
            countryCode: item1.countryCode ? item1.countryCode : 1,
            countryShortName: !!item1?.countryShortName
              ? item1?.countryShortName
              : "US",
          }))[0];
      if (
        athletesData?.outsidecountryid == 1 ||
        athletesData?.outsidecountryid == 2
      ) {
        setIsOut(false);
      } else {
        setIsOut(true);
      }
      setFields({ ...fieldT });
      let countyryIdData = props.allCountries.data
        ? props.allCountries.data.filter(
            (item) => item.id === athletesData.homecountryid
          )
        : athletesData.athleteathletecountrycodeidmaprel.Countries;
      setCountryData(countyryIdData);
      let isparentPhoneNumber =
        athletesData.isathletemobilenumber == 0 ? true : false;
      setIsChecked(isparentPhoneNumber);
      setIsAddEdit(true); //this  state is for add address manually disable
      // goTop();
    }
    //earlier it was props.athleteData
  }, [props?.athleteData?.data]);

  const handleHamburger = () => {
    setHamburger(!hamburger);
  };

  const addressDetail = (value) => {
    if (value != "") {
      const stateId =
        props.allStates.data &&
        props.allStates.data.filter((item) => {
          let itemState = item.initials ? item.initials.toLowerCase() : "";
          let valueState = value.state.toLowerCase();
          if (itemState == valueState || valueState.includes(itemState)) {
            return item;
          }
        });
      const homeCountryId =
        props.allCountries.data &&
        props.allCountries.data.filter((item) => {
          let filCountry = item?.countryName?.toLowerCase(),
            valCountry = value?.country?.toLowerCase();
          if (filCountry == valCountry || filCountry.includes(valCountry))
            return true;
        });
      const fieldT = fields;
      fieldT["Address"] = value.streetNumber + " " + value.route;
      fieldT["addressContinued"] = value.street;
      fieldT["city"] = value && value.city;
      fieldT["state"] = stateId.length != "" ? stateId[0]?.id : "";
      fieldT["zipcode"] = value.zip;
      fieldT["homeCountry"] = !!homeCountryId ? homeCountryId?.[0]?.id : 1;
      //this key is added because now phone number country data is diffrent
      // from athlete manual address 28/4/2022
      fieldT["manualCountryShortName"] =
        homeCountryId?.length > 0 ? homeCountryId?.[0]?.countryShortName : "US";
      if (
        homeCountryId?.[0]?.countryShortName != "US" &&
        homeCountryId?.[0]?.countryShortName != "CA"
      ) {
        fieldT["outsideUSCanada"] = true;
        fieldT["outsidecountryid"] =
          homeCountryId?.length > 0 ? homeCountryId?.[0]?.id : 1;
        setIsOut(true);
        getWholeCountry(homeCountryId?.length > 0 ? homeCountryId?.[0]?.id : 1);
      } else {
        fieldT["outsideUSCanada"] = false;
        fieldT["outsidecountryid"] = null;
        setIsOut(false);
        getCountryData(!!stateId[0]?.id ? stateId?.[0]?.id : 1);
      }
      setFields({ ...fieldT });
      setFullAddress(value.fullAddress());
      //earlier it was there but now it was placed in conditionally 10/10/2022
      // getCountryData(!!stateId[0]?.id ? stateId?.[0]?.id : 1)
      setIsStateChange(true);
      setIsAddEdit(true); //this  state is for add address manually disable
    }
  };

  //  validations
  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;

    // PhoneRelationship validations
    if (isChecked === true) {
      if (fieldname === undefined || fieldname === "phoneRelationship") {
        if (fieldname === undefined) {
          fieldValue = fields["phoneRelationship"]
            ? fields["phoneRelationship"]
            : "";
          updatedValue = fieldValue;
        }
        delete errorsT["phoneRelationship"];
        if (!updatedValue) {
          formIsValid = false;
          errorsT["phoneRelationship"] = "Please select type.";
        }
      }
    }
    //zipcode validation
    if (addAddress === true) {
      if (fieldname === undefined || fieldname === "zipcode") {
        if (fieldname === undefined) {
          fieldValue = fields["zipcode"] ? fields["zipcode"] : "";
          updatedValue = fieldValue;
        }
        delete errorsT["zipcode"];
        if (!updatedValue) {
          formIsValid = false;
          errorsT["zipcode"] = "Zip code is required";
        }
        // else if (
        //   !isValidZipcode(updatedValue, !!fields?.manualCountryShortName?.countryShortName ?
        //       fields?.manualCountryShortName?.countryShortName ?? "US" : fields?.manualCountryShortName ?? "US"
        //       //earlier countryShortNAme is coming from phone number so it has change now 28/4/2022
        //       // fields.user.countryShortName
        //   )
        // ) {
        //   formIsValid = false;
        //   errorsT["zipcode"] = "Please enter valid zipcode";
        // }
        else if (!!updatedValue) {
          if (updatedValue?.length > 2 && updatedValue?.length < 13) {
            if (!AplhaNumericInput(updatedValue)) {
              formIsValid = false;
              errorsT["zipcode"] = "Please enter valid zipcode";
            }
          } else {
            formIsValid = false;
            errorsT["zipcode"] = "Please enter valid zipcode";
          }
        }
      }

      if (fieldname === undefined || fieldname === "homeCountry") {
        if (fieldname === undefined) {
          fieldValue = fields["homeCountry"] ? fields["homeCountry"] : "";
          updatedValue = fieldValue;
        }
        delete errorsT["homeCountry"];
        if (!updatedValue) {
          formIsValid = false;
          errorsT["homeCountry"] = "Country is required";
        }
      }
      //in contact doc it is written that it is not mandodary test by alok sir
      // if (fieldname === undefined || fieldname === "Address") {
      //   if (fieldname === undefined) {
      //     fieldValue = fields["Address"] ? fields["Address"] : "";
      //     updatedValue = fieldValue;
      //   }
      //   delete errorsT["Address"];
      //   if (!updatedValue) {
      //     formIsValid = false;
      //     errorsT["Address"] = "Address is required";
      //   }
      // }

      if (fieldname === undefined || fieldname === "city") {
        if (fieldname === undefined) {
          fieldValue = fields["city"] ? fields["city"] : "";
          updatedValue = fieldValue;
        }
        delete errorsT["city"];
        if (!updatedValue) {
          formIsValid = false;
          errorsT["city"] = "City is required";
        }
      }
      //this isOut check over Athlete, if Athlete is outside from USA and Canada
      if (!isOut) {
        if (fieldname === undefined || fieldname === "state") {
          if (fieldname === undefined) {
            fieldValue = fields["state"] ? fields["state"] : "";
            updatedValue = fieldValue;
          }
          delete errorsT["state"];
          if (!updatedValue) {
            formIsValid = false;
            errorsT["state"] = "State is required";
          }
        }
      }
    }
    if (fieldname === undefined || fieldname === "phoneNumber") {
      if (fieldname === undefined) {
        fieldValue = fields["phoneNumber"] ? fields["phoneNumber"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["phoneNumber"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["phoneNumber"] = "Mobile Number is required";
      }
      //this code is commented  as many country has less than 10 or 11 digit
      // else if (!!updatedValue && updatedValue.length > 10) {
      //   formIsValid = false;
      //   errorsT["phoneNumber"] = 'Mobile Number should be less than 11 digits';
      // }
      //this check is now add to when user update any field and check the phone number
      else if (
        // !!fields.countryCodeId?.countryShortName && updatedValue) &&
        !checkPhoneNumberValidation(
          updatedValue,
          fields?.countryCodeId?.countryShortName ?? "US"
        )
      ) {
        formIsValid = false;
        errorsT["phoneNumber"] = "Please enter a valid phone number";
      }
      //this validation is not in need as above func is doing it $
      // updatedValue = formattedPhoneNumber(
      //   updatedValue,
      //   fields.countryCodeId?.countryShortName
      // );
    }
    if (
      (!!errorsT?.["twitterhandle"] && errorsT?.["twitterhandle"] != "") ||
      (!!errorsT?.["instagram"] && errorsT?.["instagram"] != "")
    ) {
      formIsValid = false;
    }
    setErrors({
      ...errorsT,
    });

    return {
      formIsValid,
      updatedValue,
    };
  };

  const getCountryData = (value) => {
    let countryid =
      props?.allStates?.data?.length > 0
        ? props?.allStates?.data?.filter(
            (item) => parseFloat(item.id) === parseFloat(value)
          )
        : null;
    if (countryid?.length > 0) {
      props.countryDependentListStart({
        query: getCountryApiData,
        variables: {
          where: {
            order: "id desc",
            id: parseFloat(countryid[0].countryid),
            active: 1,
          },
        },
      });
    }
  };

  const getWholeCountry = (value) => {
    if (!!value) {
      props.countryDependentListStart({
        query: getCountryApiData,
        variables: {
          where: {
            order: "id desc",
            id: parseFloat(value),
            active: 1,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (props?.allCountryDependentList?.data?.data?.length > 0) {
      const fieldsT = fields;
      fieldsT["homeCountry"] = parseFloat(
        props?.allCountryDependentList?.data?.data[0]?.id
      );
      setFields({ ...fieldsT });
      setCountryData(props?.allCountryDependentList?.data?.data);
    }
  }, [props.allCountryDependentList.data]);

  useEffect(() => {
    if (props?.allStates?.data?.length > 0) {
      props.allStates.data.sort((a, b) => {
        if (a.countryid === b.countryid) {
          return a.statename < b.statename ? -1 : 1;
        } else {
          return a.countryid < b.countryid ? -1 : 1;
        }
      });
    }
  }, [props.allStates.data]);

  const handleChange = (field, e) => {
    setErrors({
      errorsT: "",
    });
    if (field === "twitterhandle" || field === "instagram") {
      if (!e.target.value.includes("@")) {
        let fieldsT = fields,
          errorT = errors;
        fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
        setFields({
          ...fieldsT,
        });
        if (field === "twitterhandle") {
          if (!!e?.target.value) {
            if (!IsValidTwitterHandle(fieldsT["twitterhandle"])) {
              errorT["twitterhandle"] = "Please Enter Valid Twitter Handle";
            } else {
              errors["twitterhandle"] = "";
            }
          } else {
            errors["twitterhandle"] = "";
          }
        }
        if (field === "instagram") {
          if (!!e?.target?.value) {
            if (!IsValidInstagramHandle(fieldsT["instagram"])) {
              errorT["instagram"] = "Please Enter Valid Instagram Handle";
            } else {
              errors["instagram"] = "";
            }
          } else {
            errors["instagram"] = "";
          }
        }
        setErrors({
          ...errorT,
        });
        //this is for stay and leave popup
        setIsSaved(false);
      }
    } else if (field === "state") {
      let fieldsT = fields;
      fieldsT[field] = e?.target?.value ? e.target.value : null;
      fieldsT["homeCountry"] = "";
      setFields({
        ...fieldsT,
      });
      setIsSaved(false);
      setIsStateChange(true);
      setCountryData([]);
      getCountryData(e.target.value);
    } else if (field == "phoneNumber") {
      if (e?.target?.value?.length < 11) {
        let fieldsT = fields;
        // fieldsT[field] = e?.target?.value ? e.target.value : null;
        fieldsT[field] = formatPhoneNumber(e.target.value);
        setFields({
          ...fieldsT,
        });
      } else {
        return;
      }
    } else if (field == "outsidecountryid") {
      let fieldsT = fields;
      let countyryIdData = props.allCountries.data
        ? props.allCountries.data.filter(
            (item) => item.id === parseFloat(e.target.value)
          )
        : [];
      setCountryData(countyryIdData);
      fieldsT["outsidecountryid"] = e
        ? e.target
          ? parseFloat(e.target.value)
          : ""
        : "";
      fieldsT["homeCountry"] = e
        ? e.target
          ? parseFloat(e.target.value)
          : ""
        : "";
      if (e?.target?.value == 1 || e?.target?.value == 2) {
        setIsOut(false);
      } else {
        setIsOut(true);
      }
      fieldsT["city"] = "";
      fieldsT["zipcode"] = "";
      fieldsT["Address"] = "";
      fieldsT["addressContinued"] = "";
      setFullAddress(null);
      setIsAddEdit(false);
      setAutocompleteValue(!autocompleteValue);
      setFields({
        ...fieldsT,
      });
    } else {
      if (field == "homeCountry") {
        if (e?.target?.value == 1 || e?.target?.value == 2) {
          setIsOut(false);
        } else {
          setIsOut(true);
        }
      }
      let fieldsT = fields;
      fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
      setFields({
        ...fieldsT,
      });
      setIsSaved(false);
    }

    setIsNotificationStatusChange(false);
    setIsParentChange(false);
    setIsContactStatus(true);
  };
  const handleCheckbox = () => {
    setIsChecked(!isChecked);
    setErrors({
      errorsT: "",
    });
    setIsNotificationStatusChange(false);
    setIsParentChange(false);
    setIsContactStatus(true);
    //this is for stay and leave popup
    setIsSaved(false);
  };
  const handleUSCanada = () => {
    setFields({
      ...fields,
      outsideUSCanada: !fields.outsideUSCanada,
      outsidecountryid: null,
      homeCountry: null,
      state: "",
      city: "",
      zipcode: "",
      addressContinued: "",
      Address: "",
    });
    setIsOut(!fields.outsideUSCanada);
    setCountryData(props.allCountries.data);
    setAutocompleteValue(!autocompleteValue);
    setFullAddress(null);
    setIsNotificationStatusChange(false);
    setIsParentChange(false);
    setIsContactStatus(true);
    setIsAddEdit(false); //this  state is for add address manually disable
    //this is for stay and leave popup
    setIsSaved(false);
  };

  const nextSaveScreen = async () => {
    if (previousData && previousData.previousPage === "checklist") {
      props.history.push("/checklist");
      setFullAddress("");
      getAthleteProfile();
      getAllParentData();
      await AutoVerify();
      dispatch({ type: "RENDER_COUNT_START", payload: 1 });
    } else {
      setFullAddress("");
      getAthleteProfile();
      getAllParentData();
      await AutoVerify();
      dispatch({ type: "RENDER_COUNT_START", payload: 1 });
    }
  };
  const getAllParentData = () => {
    props.allParentListStart({
      query: allParentQuery,
      variables: {
        obj: {
          athleteid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };

  const getFullAddress = () => {
    let countryName = "";
    let stateName = "";
    if (fields.homeCountry) {
      if (props.allCountries.data) {
        props.allCountries.data.map((item) => {
          if (item.id == fields.homeCountry) {
            countryName = item.countryName;
          }
        });
      }
    }
    if (fields.state) {
      if (props.allStates.data) {
        props.allStates.data.map((item) => {
          if (item.id == fields.state) {
            stateName = item.initials;
          }
        });
      }
    }
    return `${fields?.Address ? fields.Address : ""}${
      fields?.addressContinued ? " " + fields.addressContinued : ""
    }${!!fields?.addressContinued && !!fields?.city ? "," : ""}${
      fields?.city ? " " + fields.city : ""
    }${!!fields?.zipcode && !!stateName ? "," : ""}
         ${stateName ? " " + stateName : " "}${
      !!stateName && !!countryName ? "," : " "
    } ${fields?.zipcode ? " " + fields.zipcode : ""}${
      !!countryName && fields.zipcode ? "," : ""
    }
         ${" " + countryName}`;
  };

  const handlePhonenumberChange = (selectedOption) => {
    const fieldsT = fields;
    fieldsT["countryCodeId"] = selectedOption;
    setFields({ ...fieldsT, phoneNumber: "" });
    setIsNotificationStatusChange(false);
    setIsParentChange(false);
    setIsContactStatus(true);
    //this is for stay and leave popup
    setIsSaved(false);
  };
  const handalManuallyAddress = (e) => {
    e.preventDefault();
    setAddAddress(!addAddress);
    setErrors({
      errorsT: "",
    });
    setIsNotificationStatusChange(false);
    setIsParentChange(false);
    setIsContactStatus(true);
    //this is for stay and leave popup
    setIsSaved(false);
  };

  const parentData = (parentData) => {
    setUpdatedParentData(parentData);
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  const refreshAfterSave = () => {
    if (previousData && previousData.previousPage === "checklist") {
      props.history.push("/checklist");
      // messagepopup changed to toastr
      toastr.success("Address updated successfully.", "", {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: "toast-top-full-width",
        showEasing: "swing",
        fadeIn: 40000,
        preventDuplicates: true,
      });
      setFullAddress("");
      getAthleteProfile();
      getAllParentData();
    } else {
      toastr.success("Address updated successfully.", "", {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: "toast-top-full-width",
        showEasing: "swing",
        fadeIn: 40000,
        preventDuplicates: true,
      });
      setFullAddress("");
      getAthleteProfile();
      getAllParentData();
    }
  };
  const parentDataChange = (valid, notificationStatus) => {
    setIsParentChange(valid);
    // setIsNotificationStatusChange(notificationStatus)
    //this is for stay and leave popup
    setIsSaved(false);
  };
  const saveHomeAddress = (e) => {
    e.preventDefault();
    const { formIsValid } = handleValidation();
    setIsStateChange(false);
    if (formIsValid) {
      props.updateBasicUserProfileListStart({
        query: updateAthleteDataQuery,
        variables: {
          obj: {
            id: getUserInfo() ? parseFloat(getUserInfo().id) : 0,
            address: fields.Address ? fields.Address : "",
            addresscountinue: fields.addressContinued,
            city: fields.city,
            stateid: fields.state == "" ? null : fields.state,
            athletezipcode: fields.zipcode == "" ? null : fields.zipcode,
            homecountryid:
              fields.homeCountry == "" ? null : parseFloat(fields.homeCountry),
            outsidecountryid: fields.outsidecountryid
              ? +fields.outsidecountryid
              : null,
            homeoutsideusorcanada: fields.outsideUSCanada == true ? 1 : 0,
          },
        },
        nextScreen: refreshAfterSave,
      });
      //this is for stay and leave popup
      setIsSaved(true);
    }
  };

  const validateOnSubmit = () => {
    let finalTempArr = null,
      status = true;
    updatedParentData?.map((item, index) => {
      let tempErr = {};
      for (const [name, value] of Object.entries(item)) {
        if (name === "lastName") {
          if (value) {
            tempErr[name] = "";
          } else {
            tempErr[name] = "Parent / Guardian Last Name is required";
            messagePopup(
              "",
              "Parent / Guardian Last Name is required",
              "warning"
            );
            status = false;
            return;
          }
        }
        if (name === "firstName") {
          if (!value) {
            tempErr[name] = "Parent / Guardian First Name is required";
            messagePopup(
              "",
              "Parent / Guardian First Name is required",
              "warning"
            );
            status = false;
            return;
          } else {
            tempErr[name] = "";
          }
        }
        if (name === "phoneNumber") {
          if (!item?.phoneNumber) {
            tempErr[name] = "Parent / Guardian Mobile Number is required";
            messagePopup(
              "",
              "Parent / Guardian Mobile Number is required",
              "warning"
            );
            status = false;
            return;
          }
          // else if (item?.phoneNumber?.toString().length > 10) {
          //   //thi is left
          //   tempErr[name] = 'Parent/Guardian Mobile Number should be less than 11 digits';
          // }
          else if (
            !checkPhoneNumberValidation(
              item?.phoneNumber,
              item?.countryCodeId?.countryShortName ?? "US"
            )
          ) {
            tempErr[name] =
              "Please enter a valid Parent / Guardian Phone Number";
            messagePopup(
              "",
              "Please enter a valid Parent / Guardian Phone Number",
              "warning"
            );
            status = false;
            return;
          } else {
            tempErr[name] = "";
          }
        }
        if (name === "primaryEmail") {
          if (!value) {
            tempErr[name] = "Parent / Guardian Email Address is required";
            messagePopup(
              "",
              "Parent / Guardian Email Address is required",
              "warning"
            );
            status = false;
            return;
          } else if (!IsValidEmail(value)) {
            tempErr[name] =
              "Please enter Parent / Guardian Email in correct format";
            messagePopup(
              "",
              "Please enter Parent / Guardian Email in correct format",
              "warning"
            );
            status = false;
            return;
          } else {
            tempErr[name] = "";
          }
        }

        // relationshipWithAthlete
        if (name === "relationshipWithAthlete") {
          if (value) {
            tempErr[name] = "";
          } else {
            tempErr[name] =
              "Please Select Parent / Guardian Relationship With Athlete";
            messagePopup(
              "",
              "Please Select Parent / Guardian Relationship With Athlete",
              "warning"
            );
            status = false;
            return;
          }
        }
      }
      finalTempArr = tempErr;
    });
    // return finalTempArr;
    return status;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const { formIsValid } = handleValidation();
    const submitError =
      updatedParentData?.length > 0
        ? validateOnSubmit()
        : {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            primaryEmail: "",
            relationshipWithAthlete: "",
          };
    setIsStateChange(false);
    if (formIsValid) {
      if (submitError) {
        // if (submitError.firstName == '') {
        //   if (submitError.lastName == '') {
        //     if (submitError.phoneNumber == '') {
        //       if (submitError.primaryEmail == '') {
        //         if (submitError.relationshipWithAthlete == '') {
        ////// this is email validation check by api start
        const mailObj = [];
        updatedParentData.map((el) => mailObj.push(el?.primaryEmail));
        const duplicate = mailObj.filter(
          (item, index) => mailObj.indexOf(item) !== index
        );
        if (duplicate?.length > 0)
          return messagePopup(
            "",
            "Email already exist for parent / guardian",
            "error"
          );
        const resMail = await fetchMethod(
          "/api/Userdata/checkParent",
          { parentEmails: mailObj, athleteId: getUserInfo()?.id },
          "POST",
          true
        );
        if (resMail?.status == 200 && resMail?.data?.response?.status == 200) {
          props.updateBasicUserProfileListStart({
            query: updateAthleteDataQuery,
            variables: {
              obj: {
                id: getUserInfo() ? parseFloat(getUserInfo().id) : 0,
                isathletemobilenumber: isChecked == false ? 1 : 0,
                athletephonenumber: fields.phoneNumber,
                athletecountrycodeid: fields.countryCodeId
                  ? fields.countryCodeId.value
                  : null,
                address: fields.Address,
                addresscountinue: fields.addressContinued,
                city: fields.city,
                stateid: fields.state == "" ? null : fields.state,
                athletezipcode: fields.zipcode == "" ? null : fields.zipcode,
                homecountryid:
                  fields.homeCountry == ""
                    ? null
                    : parseFloat(fields.homeCountry),
                outsidecountryid: fields.outsidecountryid
                  ? +fields.outsidecountryid
                  : null,
                phonenumberrelation: isChecked
                  ? fields.phoneRelationship == undefined
                    ? null
                    : parseFloat(fields.phoneRelationship)
                  : null,
                homeoutsideusorcanada: fields.outsideUSCanada == true ? 1 : 0,
              },
            },
            nextScreen: () => {
              // if (!isParentDataChange) {
              if (updatedParentData?.length == 0) {
                // messagePopup("", "Contact updated successfully.", "success");
                toastr.success("Contact updated successfully.", "", {
                  progressBar: true,
                  timeOut: 2000,
                  closeButton: true,
                  positionClass: "toast-top-full-width",
                  showEasing: "swing",
                  fadeIn: 40000,
                  preventDuplicates: true,
                });
              }
              nextSaveScreen();
            },
          });
          let data = updatedParentData?.map((item) => {
            item.id = item.editEmail ? item.id : 0;
            return item;
          });
          let addParent = {
            userId: getUserInfo() ? parseFloat(getUserInfo().id) : 0,
            status: "add",
            parentDetails: data,
            role: getUserInfo() ? getUserInfo().role : 0,
            athleteFirstName: getUserInfo() ? getUserInfo().FirstName : "",
            notificationStatus: isNotificationStatusChange,
            changeParentStaus: isParentDataChange,
            isContactStatus: isContactStatus,
            isParentAdd: checkParentLength != data?.length ? true : false,
            refreshPage: nextSaveScreen,
            // ()=>{
            // window.location.reload() onlu this line is comentted array func was there
            // }
          };
          if (updatedParentData.length > 0) {
            //   if (isParentDataChange || isNotificationStatusChange) {
            props.updateParentListStart(addParent);
            // }
          }
          props.saveOnboardingStart({
            query: saveOnboardingVitalsDataQuery,
            variables: {
              obj: {
                id: parseFloat(fields.onboardingId),
                twitteraccountname:
                  fields.twitterhandle == "" ? null : fields.twitterhandle,
                instagramaccountname:
                  fields.instagram == "" ? null : fields.instagram,
              },
            },
            nextScreen: nextSaveScreen,
          });
        } else if (
          resMail?.status == 200 &&
          resMail?.data?.response?.status == 201
        ) {
          const mailMSG =
            resMail?.data?.response?.msg ??
            "Email ID already connected with five Athlete.";
          messagePopup("", mailMSG, "error");
        } else {
          console.log("check email p/g api failed", resMail);
        }
        //// this is email validation check by api end
        // }
        //         else {
        //           messagePopup("", submitError.relationshipWithAthlete, "warning")
        //         }
        //       }
        //       else {
        //         messagePopup("", submitError.primaryEmail, "warning")
        //       }
        //     }
        //     else {
        //       messagePopup("", submitError.phoneNumber, "warning")
        //     }
        //   }
        //   else {
        //     messagePopup("", submitError.lastName, "warning")
        //   }
        // }
        // else {
        //   messagePopup("", submitError.firstName, "warning")
        // }
      } else {
        console.log("add parent validation failed");
      }
    } else {
      fakeInput.current.focus();
      fakeInput.current.select();
    }
    //this is for stay and leave popup
    setIsSaved(true);
  };
  const routeBackToProfile = () => {
    props.history.push(
      { pathname: `${getAthleteSysID()}`, state: { isPublic: false } }
      // '/profile/1'
    );
    sessionStorage.setItem("athlete_own_profile", true);
  };
  //this is used to scroll the page on top
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1800);
  }, []);
  return (
    <div className="pageContent">
      <input className="fakeInput" type="text" ref={fakeInput} />
      <div className="editProfile">
        <div className="container">
          <div
            className={hamburger == true ? "flex" : "flex inactive"}
            id="wrapper"
          >
            <SubHeader
              hamburger={handleHamburger}
              onSave={(e) => {
                // saveHomeAddress(e);
                handleSave(e);
              }}
            />

            <div className="sidebar">
              <LeftSideEditProfile isSaved={isSaved} />
            </div>
            {props.athleteData &&
            props.athleteData.data &&
            props.athleteData.data.athletesData ? (
              <div className="editSection">
                <div className="flexRow">
                  <div className="title">Contact</div>
                  <div className="btnWrap">
                    <button
                      className="btn outlined green rounded medium backprofileBtn"
                      onClick={() => routeBackToProfile()}
                    >
                      Back To Profile
                    </button>
                    <button
                      className="btn outlined green rounded medium"
                      onClick={() => routeBackToProfile()}
                    >
                      Preview Profile
                    </button>
                    <button
                      className="btn outlined blue rounded medium"
                      onClick={() => {
                        sessionStorage.setItem("athlete_own_profile", false);
                        setTimeout(() => {
                          window.open(
                            `${websiteDefaultUrl}athlete/wyatt-johnson-74144936`,
                            "_blank"
                          );
                        }, 500);
                      }}
                    >
                      Sample Profile
                    </button>
                  </div>
                </div>
                <div className="check-list-sec">
                  <div className="editprofile-common-form">
                    <form>
                      <div className="fieldBox flexBox phone block-on-mobile">
                        <div className="half full-on-mobile">
                          <label>
                            Mobile Number <sup>*</sup>
                          </label>
                          <PhoneNumber
                            dropdownName="countryCode"
                            dropdownOnChange={handlePhonenumberChange}
                            dropdownValue={fields.countryCodeId}
                            dropDownOptions={
                              props.allCountries.data &&
                              props.allCountries.data.map((item) => ({
                                value: item.id,
                                label:
                                  item.countryName + `(+${item.countryCode})`,
                                flag: item.countryFlagImage,
                                countryCode: item.countryCode,
                                countryShortName: item.countryShortName,
                              }))
                            }
                            formatOptionLabel={(
                              option,
                              { context, selectValue }
                            ) => {
                              return context === "menu" ? (
                                <>
                                  <img
                                    src={option.flag}
                                    alt=""
                                    style={{
                                      width: "40px",
                                      marginRight: "15px",
                                    }}
                                  />
                                  <span>{option.label}</span>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={option.flag}
                                    alt=""
                                    style={{
                                      width: "40px",
                                      marginRight: "15px",
                                    }}
                                  />
                                  <span>+{option.countryCode}</span>
                                </>
                              );
                            }}
                            inputPlaceholder="Phone Number"
                            inputType="text"
                            inputValue={fields.phoneNumber || ""}
                            inputOnChange={(e) => {
                              handleChange("phoneNumber", {
                                target: {
                                  value: e.target.value.replace(/[^0-9]/gi, ""),
                                },
                              });
                            }}
                          />
                          <div className="error">{errors["phoneNumber"]}</div>
                        </div>

                        <div className="half full-on-mobile">
                          <div className="fieldBox flex-box-second mt25">
                            <div className="half">
                              <p className="checkbox-list">
                                <span>
                                  <input
                                    onChange={() => handleCheckbox()}
                                    checked={isChecked}
                                    name="notMyNumber"
                                    id="notMyNumber"
                                    type="checkbox"
                                  />
                                  <label>Not Athlete's Mobile Number</label>
                                </span>
                              </p>
                            </div>
                            {isChecked == true ? (
                              <div className="half">
                                <select
                                  id="demo-simple-select-outlined"
                                  value={fields.phoneRelationship || ""}
                                  name="phoneRelationship"
                                  onChange={(e) =>
                                    handleChange("phoneRelationship", e)
                                  }
                                  label="Select"
                                >
                                  <option value="">Select Type</option>
                                  {props.allRelationships.data &&
                                    props.allRelationships.data.map(
                                      (item, index) => (
                                        <option key={index} value={item.id}>
                                          {item.relationShipName}
                                        </option>
                                      )
                                    )}
                                </select>
                                <div className="error">
                                  {errors["phoneRelationship"]}
                                </div>
                              </div>
                            ) : (
                              " "
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="fieldBox">
                        <label>Primary Email</label>
                        <input
                          onChange={(e) => handleChange("email", e)}
                          value={fields.email || ""}
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Primary email"
                          disabled
                        />
                        <div className="error">{errors["email"]}</div>
                        <span className="subtext-box">
                          * Your primary email is used to login and appears in
                          the top on your Profile. Make sure this is primary
                          email and will not change.
                        </span>
                      </div>
                      <div className="form-info-box">
                        <div className="item">
                          <h5 className="title">Social Media</h5>
                          <div className="fieldBox flexBox">
                            <div className="half">
                              <label>Twitter Handle</label>
                              <div className="box-with-icon">
                                <span>@</span>
                                <input
                                  onChange={(e) =>
                                    handleChange("twitterhandle", e)
                                  }
                                  value={fields.twitterhandle || ""}
                                  type="text"
                                  name="twitterhandle"
                                  id="twitterhandle"
                                  placeholder="Enter Handle"
                                />
                              </div>
                              <div className="error">
                                {errors["twitterhandle"]}
                              </div>
                            </div>
                            <div className="half">
                              <label>Instagram Handle</label>
                              <div className="box-with-icon">
                                <span>@</span>
                                <input
                                  value={fields.instagram || ""}
                                  onChange={(e) => handleChange("instagram", e)}
                                  type="text"
                                  name="instagram"
                                  id="instagram"
                                  placeholder="Enter Handle"
                                />
                              </div>
                              <div className="error">{errors["instagram"]}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-info-box">
                        <div className="item">
                          <h5 className="title">Home Address</h5>
                          <div className="sub-text">
                            Only your city and state will be displayed on your
                            profile as "Hometown"
                          </div>
                          <div className={"searchLocation contactPage"}>
                            <AutoCompleteCitySearch
                              addressDetails={addressDetail}
                              value={autocompleteValue}
                            />
                          </div>
                          {fullAddress !== "" ? (
                            <div
                              className="textBoxInfo"
                              style={
                                !!fullAddress
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              <p>{fullAddress}</p>
                            </div>
                          ) : getFullAddress().trim() ? (
                            <div className="textBoxInfo">
                              <p>{getFullAddress()}</p>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="fieldBox full">
                            <p className="checkbox-list">
                              <span>
                                <input
                                  onChange={() => handleUSCanada()}
                                  checked={fields.outsideUSCanada}
                                  type="checkbox"
                                />
                                <label>
                                  Check if you live outside the US or Canada
                                </label>
                              </span>
                            </p>
                          </div>
                          {fields.outsideUSCanada == true ? (
                            <div className="fieldBox full">
                              <label>Country</label>
                              <select
                                id="demo-simple-select-outlined"
                                value={fields.outsidecountryid || ""}
                                name="countries"
                                onChange={(e) =>
                                  handleChange("outsidecountryid", e)
                                }
                                label="Select"
                              >
                                <option value="">Select</option>
                                {props.allCountries.data &&
                                  props.allCountries.data.map((item, index) => {
                                    //this check is for bot selecting us and canada
                                    if (item?.id !== 1 && item?.id !== 2) {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.countryName}
                                        </option>
                                      );
                                    }
                                  })}
                              </select>
                            </div>
                          ) : null}

                          <div className="fieldBox full">
                            <button
                              className="btn green"
                              onClick={(e) => handalManuallyAddress(e)}
                            >
                              Add Address Manually
                            </button>
                          </div>
                        </div>
                      </div>
                      {addAddress === true ? (
                        <div className="address-box-wrap">
                          <div className="fieldBox flexBox">
                            <div className="half">
                              <label>
                                Address <sup></sup>
                              </label>
                              <input
                                onChange={(e) => handleChange("Address", e)}
                                value={fields.Address || ""}
                                name="Address"
                                id="Address"
                                type="text"
                                placeholder="Address"
                              />
                              <div className="error">{errors["Address"]}</div>
                            </div>
                            <div className="half">
                              <label>Address Continued</label>
                              <input
                                onChange={(e) =>
                                  handleChange("addressContinued", e)
                                }
                                type="text"
                                value={fields.addressContinued || ""}
                                placeholder="Address Continued"
                              />
                            </div>
                          </div>
                          <div className="fieldBox flexBox">
                            <div className="half">
                              <label>
                                City <sup>*</sup>
                              </label>
                              <input
                                onChange={(e) => handleChange("city", e)}
                                value={fields.city || ""}
                                type="text"
                                placeholder="city"
                                disabled={isAddEdit}
                              />
                              <div className="error">{errors["city"]}</div>
                            </div>
                            <div className="half">
                              <label>
                                State / Province <sup>*</sup>
                              </label>
                              <FormControl
                                className="legend-inner-none"
                                variant="outlined"
                                fullWidth
                              >
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  // id="demo-simple-select-outlined"
                                  value={fields.state || ""}
                                  onChange={(e) => handleChange("state", e)}
                                  label="Select"
                                  disabled={isOut || isAddEdit}
                                  style={
                                    isAddEdit ? { background: "#fafafa" } : {}
                                  }
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {props.allStates.data &&
                                    props.allStates.data.map((item, index) => (
                                      <MenuItem key={index} value={item.id}>
                                        {item.statename}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                              <div className="error">{errors["state"]}</div>
                            </div>
                          </div>
                          <div className="fieldBox flexBox">
                            <div className="half">
                              <label>
                                ZIP code <sup>*</sup>
                              </label>
                              <input
                                onChange={(e) => handleChange("zipcode", e)}
                                value={fields.zipcode || ""}
                                name="zipcode"
                                id="zipcode"
                                type="text"
                                placeholder="ZIP code"
                                disabled={isAddEdit}
                              />
                              <div className="error">{errors["zipcode"]}</div>
                            </div>
                            <div className="half">
                              <label>
                                Country
                                <sup>*</sup>
                              </label>
                              <select
                                value={fields.homeCountry || ""}
                                name="countries"
                                onChange={(e) => handleChange("homeCountry", e)}
                                label="Select"
                                disabled={isAddEdit}
                                style={
                                  isAddEdit ? { background: "#fafafa" } : {}
                                }
                              >
                                <option value="">Select</option>
                                {countryData?.length > 0
                                  ? countryData?.map((item, index) => {
                                      if (fields.outsideUSCanada) {
                                        if (
                                          item.countryShortName != "US" &&
                                          item.countryShortName != "CA"
                                        ) {
                                          return (
                                            <option key={index} value={item.id}>
                                              {item.countryname ||
                                                item.countryName}
                                            </option>
                                          );
                                        }
                                      } else {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.countryname ||
                                              item.countryName}
                                          </option>
                                        );
                                      }
                                    })
                                  : null}
                              </select>
                              <div className="error">
                                {errors["homeCountry"]}
                              </div>
                            </div>
                          </div>
                          <div className="form-button-box">
                            <button
                              className="btn blue"
                              // disabled={fields.Address != "" ? false : true}
                              onClick={(e) => {
                                saveHomeAddress(e);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <AddMoreParent
                        refreshAfterSave={refreshAfterSave}
                        parentData={parentData}
                        setCheckParentLength={setCheckParentLength}
                        nextSaveScreen={nextSaveScreen}
                        age={getUserAge()}
                        parentDataChange={parentDataChange}
                        getAllParentData={getAllParentData}
                        setIsContactStatus={setIsContactStatus}
                        setIsParentChange={setIsParentChange}
                        setIsNotificationStatusChange={
                          setIsNotificationStatusChange
                        }
                      />
                      <div className="form-button-box with-next-prev">
                        <button
                          className="btn blue"
                          onClick={(e) => {
                            handleSave(e);
                          }}
                        >
                          Save
                        </button>
                        <div className="next-prev-button">
                          <ScreenSwitch
                            prevLink={"/basic"}
                            nextLink={"/videoPhotos"}
                            isSaved={isSaved}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <GoTopButton goTop={goTop} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    athleteData: state.common.athletesData,
    allStates: state.common.states,
    allCountries: state.common.countries,
    allCountryDependentList: state.common.countryDependent,
    allRelationships: state.common.relationships,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    athleteListStart: (data) => dispatch(athletesDetailsStart(data)),
    stateListStart: (data) => dispatch(stateListStart(data)),
    countryListStart: (data) => dispatch(countryListStart(data)),
    relationshipListStart: (data) => dispatch(relationshipListStart(data)),
    updateBasicUserProfileListStart: (data) =>
      dispatch(updateBasicUserProfileListStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
    updateParentListStart: (data) => dispatch(updateParentListStart(data)),
    allParentListStart: (data) => dispatch(athleteParentMappingStart(data)),
    countryDependentListStart: (data) =>
      dispatch(countryDependentListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
