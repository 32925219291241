import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_STATS_OVERVIEW_START: "ALL_STATS_OVERVIEW_START",
  ALL_STATS_OVERVIEW_SUCCESS: "ALL_STATS_OVERVIEW_SUCCESS",
  ALL_STATS_OVERVIEW_FAILED: "ALL_STATS_OVERVIEW_FAILED",
};

// ============================================ Actions ========================================================
export const allStatsOverviewStart = (data) => ({
  type: actionType.ALL_STATS_OVERVIEW_START,
  payload: data,
});

export const allStatsOverviewSuccess = (data) => ({
  type: actionType.ALL_STATS_OVERVIEW_SUCCESS,
  payload: data,
});

export const allStatsOverviewFailed = (data) => ({
  type: actionType.ALL_STATS_OVERVIEW_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allStatsOverviewReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ALL_STATS_OVERVIEW_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_STATS_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_STATS_OVERVIEW_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allStatsOverviewSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.primaryPositionQuery,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const primaryPositionData = response.data.data.allStatsoverviews
        .Statsoverviews
        ? response.data.data.allStatsoverviews.Statsoverviews
        : [];
      let secondaryPositionData = [];
      const response1 = yield fetchGraphMethod(
        action.payload.secondaryPositionQuery,
        action.payload.variables,
        true
      );
      if (response1 && response1.status === 200) {
        secondaryPositionData = response1.data.data.allStatsoverviews
          .Statsoverviews
          ? response1.data.data.allStatsoverviews.Statsoverviews
          : [];
      }
      yield put(
        allStatsOverviewSuccess({
          allStatsoverviews: [...primaryPositionData, ...secondaryPositionData],
        })
      );
    } else {
      messagePopup("", "Stats overview list failed", "error");
      yield put(allStatsOverviewFailed("Stats overview list failed"));
    }
  } catch (error) {
    messagePopup("", "Stats overview list failed", "error");
    yield put(allStatsOverviewFailed("Stats overview list failed"));
  }
}

//Watcher
export function* watchAllStatsOverviewSaga() {
  yield takeEvery(actionType.ALL_STATS_OVERVIEW_START, allStatsOverviewSaga);
}
