export const getVideoEditingMasterPlan = `
query{
  allVideoedittingmasters{
    Videoedittingmasters {
      id
      createdby
      createdon
      description
      updatedby
      updatedon
      userid
      videoedittingplanprice
      videoedittingtotalcount
      videofor
    }
  }
}
`;
export const getVideoOrderPlanQuery = `
query  allVideoedittingorders($where:JSON){
    allVideoedittingorders(where:$where,first:3,last:3){
      Videoedittingorders {
        id
        allusededittngcount
        palnid
        userid
      }
    }
  }
`;

export const saveVideoOrderQuery = `
mutation saveVideoedittingorder($obj:VideoedittingorderInput!){
  saveVideoedittingorder(obj:$obj){
    id
    allusededittngcount
    palnid
    userid
  }
}
`;

export const saveVideoEditingDetailsQuery = `
mutation saveVideoedittingdetails($obj:VideoedittingdetailsInput!){
    saveVideoedittingdetails(obj:$obj){
        id
        userid
        isdraft
        additionaldetails
        videodetails
        videoinformationid
        jerseynumer
    }
  }
`;

export const getVideoDraftQuery = `
query allVideoedittingdetails($where:JSON)
{
  allVideoedittingdetails(where:$where){
    Videoedittingdetails{
      id
      userid
      isdraft
      videodetails
      videoinformationid
      jerseynumer
      additionaldetails
    }
  }
}
`;

export const getPremiumUserQuery = `
query allMemberships($where:JSON){
  allMemberships(where:$where){
    totalCount
    Memberships {
      id
      videoeditingorderplanid
      membershipvideoeditingorderplanidmaprel{
        Videoedittingorders{
          id
          palnid
        }
      }
    }
  }
}
`;

export const saveMembershipQuery = `
mutation saveMembership($obj:MembershipInput!){
  saveMembership(obj:$obj){
      id
      userid
      videoeditingorderplanid
  }
}
`;
