import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup"
import toastr from "toastr";

// ========================================== Action Type =====================================================
export const actionType = {
    ADD_EDIT_EVENT_FORM_LIST_START: 'ADD_EDIT_EVENT_FORM_LIST_START',
    ADD_EDIT_EVENT_FORM_LIST_SAVE_START: 'ADD_EDIT_EVENT_FORM_LIST_SAVE_START',
    ADD_EDIT_EVENT_FORM_LIST_SUCCESS: 'ADD_EDIT_EVENT_FORM_LIST_SUCCESS',
    ADD_EDIT_EVENT_FORM_LIST_FAILED: 'ADD_EDIT_EVENT_FORM_LIST_FAILED',
    ALL_ADD_EDIT_EVENT_FORM_LIST_SUCCESS: 'ALL_ADD_EDIT_EVENT_FORM_LIST_SUCCESS',
    ALL_ADD_EDIT_EVENT_FORM_LIST_FAILED: 'ALL_ADD_EDIT_EVENT_FORM_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const addEditEventFormListStart = (data) => ({
    type: actionType.ADD_EDIT_EVENT_FORM_LIST_START,
    payload: data
})

export const addEditEventFormsaveStart = (data) => ({
    type: actionType.ADD_EDIT_EVENT_FORM_LIST_SAVE_START,
    payload: data
})

export const addEditEventFormListSuccess = (data) => ({
    type: actionType.ADD_EDIT_EVENT_FORM_LIST_SUCCESS,
    payload: data
})

export const addEditEventFormListFailed = (data) => ({
    type: actionType.ADD_EDIT_EVENT_FORM_LIST_FAILED,
    payload: data
})
export const allAddEditEventFormListSuccess = (data) => ({
    type: actionType.ADD_EDIT_EVENT_FORM_LIST_SUCCESS,
    payload: data
})

export const allAddEditEventFormListFailed = (data) => ({
    type: actionType.ADD_EDIT_EVENT_FORM_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function addEditEventFormListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.ADD_EDIT_EVENT_FORM_LIST_START:
            return {
                ...state,
                loading: true
            }
        case actionType.ADD_EDIT_EVENT_FORM_LIST_SAVE_START:
            return {
                ...state,
                loading: true
            }
        case actionType.ADD_EDIT_EVENT_FORM_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        case actionType.ADD_EDIT_EVENT_FORM_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        case actionType.ALL_ADD_EDIT_EVENT_FORM_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        case actionType.ALL_ADD_EDIT_EVENT_FORM_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        default: return { ...state }
    }
}

// ===================================================== side effect =============================================

export function* addEditEventFormListSaveSaga(action) {
    try {
       
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
       
        if (response && response.status === 200) {
            if(response && response.data && response.data.errors ){
                messagePopup('', `${response.data.errors[0].message.msg}`, 'warning')
            }else {
                if(action.payload.routeNextPage != null){
                    action.payload.routeNextPage()
                }
                // messagePopup("", "Your Event is saved successfully", "success");
                toastr.success("Your Event is saved successfully", "", {
                    progressBar : true,timeOut: 2000,
                    closeButton: true, positionClass: "toast-top-full-width",
                    showEasing: "swing", fadeIn: 40000,
                    preventDuplicates:true,
                  })
                yield put(addEditEventFormListSuccess(''));
            }
        } else {
            messagePopup('', 'ADD EDIT EVENT FORM List failed', 'error')
            yield put(addEditEventFormListFailed('ADD EDIT EVENT FORM List failed'))
        }
    } catch (error) {
        messagePopup('', 'ADD EDIT EVENT FORM List failed', 'error')
        yield put(addEditEventFormListFailed('ADD EDIT EVENT FORM List failed'))
    }
}

export function* addEditEventFormlistSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            let allAddEditEventForm = response
            yield put(allAddEditEventFormListSuccess(allAddEditEventForm));
        } else {
            messagePopup('', 'ADD EDIT EVENT FORM List failed', 'error')
            yield put(allAddEditEventFormListFailed('OFFERS List failed'))
        }
    } catch (error) {
        messagePopup('', 'ADD EDIT EVENT FORM List failed', 'error')
        yield put(allAddEditEventFormListFailed('ADD EDIT EVENT FORM List failed'))
    }
}


export function* watchAddEditEventFormListSaveSaga() {
    yield takeEvery(actionType.ADD_EDIT_EVENT_FORM_LIST_START, addEditEventFormlistSaga)
    yield takeEvery(actionType.ADD_EDIT_EVENT_FORM_LIST_SAVE_START, addEditEventFormListSaveSaga)

}