import { all, fork } from "redux-saga/effects";
import { watchMessageListSaga } from "./message";
import { watchPremiumDropdownSaga } from './premiumDropdown';
import { watchMessageCountListSaga } from './messageCount';
import { watchCheckFollowDropdownSaga } from './checkFollowStatus';
import { watchGetUserdataListSaga } from "./getUserData";

export default function* rootMessageSagas() {
    yield all([
       fork(watchMessageListSaga),
       fork(watchPremiumDropdownSaga),
       fork(watchMessageCountListSaga),
       fork(watchCheckFollowDropdownSaga),
       fork(watchGetUserdataListSaga)
    ])
}