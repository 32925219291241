import {all, fork} from 'redux-saga/effects'
import rootAccountSagas from './dashboard/account/rootAccountSagas'
import rootEditUserProfileSagas from './dashboard/editUserProfile/rootEditUserProfileSagas'
import rootDashboardSagas from './dashboard/rootDashboardSagas'
import rootOnBoardingSagas from './onBoarding/rootOnBoardingSagas'
import rootProfileSagas from './profile/rootProfileSagas'
import rootFollowerAthleteSagas from "./dashboard/activity/rootFollowerAthleteSagas"

export default function* rootAthleteSagas() {
  yield all([ 
    rootOnBoardingSagas(),
    rootDashboardSagas(),
    rootProfileSagas(),
    rootEditUserProfileSagas(),
    rootAccountSagas(),
    rootFollowerAthleteSagas()
  ])
}