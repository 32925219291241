import React, { useEffect, useState } from 'react';
import ClaimProfileHeader from '../auth/claimProfile/claimProfileStep1/ClaimProfileHeader';
import DashboardHeader from '../../layouts/header/DashboardHeader';
import { getAuthToken } from '../../../services/authService';
import CCCAA from './assets/img/california-community-college-athletic-association-logo.png';
import ClubVideoThumbnail from './assets/img/club-video-thumbnail.png';
import './assets/css/lading-page.scss';
import { imageDownloadUrl } from '../../../config';
import { useSelector } from 'react-redux';
import { lpTypeCall } from '../../common/landingPageApi';
import { VideoPop } from './common/videoPopup';
import AthleteVideoThumbnail from './assets/img/assessment-call-video-thumbnail.png';
import QuestionAssesmentImg from './assets/img/question_assesment_img.png';
import CoachBrandLogo from './assets/img/coach-brand-logo.png';
import NaiaImg from './assets/img/naia-logo.png';
import NjcaaImg from './assets/img/njcaa-logo.png';
import NcaaImg from './assets/img/ncaa-logo.png';
import ProvenRoadMap from './assets/img/proven-road-map-to-success.png';
import BrandLogo1 from './assets/img/branding-ncaa-logo01.png';
import BrandLogo2 from './assets/img/branding-ncaa-logo02.png';
import BrandLogo3 from './assets/img/branding-ncaa-logo03.png';
import BrandLogo4 from './assets/img/branding-ncaa-logo04.png';
import ConnorMarlImg from './assets/img/connor-Markl-img.png';
import ConnorMarklLogoImg from './assets/img/connor-markl-logoimg.png';
import JimmyChatfieldImg from './assets/img/jimmy-chatfield-img.png';
import JimmyChatfieldLogoimg from './assets/img/jimmy-chatfield-logoimg.png';
import JamirGibsonImg from './assets/img/home-success-img-03.png';
import JamirGibsonLogoImg from './assets/img/jamir-gibson-logoimg.png';
import CharlesBeilensonImg from './assets/img/charles-beilenson-img.png';
import CharlesBeilensonLogoImg from './assets/img/charles-beilenson-logoimg.png';
import BennettMarkinsonImg from './assets/img/bennett-markinson-img.png';
import BennettMarkinsonLogoImg from './assets/img/bennett-markinson-logoimg.png';
import AssessmentBanner from './assets/img/assessment-banner-img01.jpg';
import ProvenRoadMapToSuccessMobileIMG from './assets/img/proven-road-map-to-success-mobile.png';
import AssessmentCTABanner from './assets/img/assessment-cta-banner-img01.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/owl.carousel.min.js';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import CoachTestimonials from '../../common/CoachTestimonials';

const successStoriesSlide = {
  loop: true,
  autoplay: true,
  autoHeight: true,
  margin: 10,
  nav: true,
  dots: false,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,
  items: 1,
};

const coachesSlider = {
  loop: true,
  autoplay: true,
  autoHeight: true,
  nav: true,
  dots: false,
  margin: 30,
  responsive: {
    0: {
      items: 1,
      margin: 0,
    },
    768: {
      items: 2,
    },
    992: {
      items: 3,
      margin: 30,
    },
  },
};

const AssessmentLP = (props) => {
  const [headerType, setHeaderType] = useState(true),
    [vidID, setVidID] = useState(null),
    [isVideoPop, setIsVideoPop] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  const openModal = (type) => {
    setVidID('https://www.youtube.com/embed/pzXiI7Oyyp8');
    setIsVideoPop(true);
  };
  return (
    <>
      {headerType ? <ClaimProfileHeader /> : <DashboardHeader />}

      <section
        className="banner-lp-sec"
        style={{
          backgroundImage: `url(${imageDownloadUrl}/img/assessment-banner-img01.jpg)`,
        }}
      >
        <div className="container">
          <div className="banner-flexbox">
            <div className="banner-content">
              <h1 className="title">
                Give Your Son The College Recruiting Advantage
              </h1>
              <div className="short-text">
                <p>
                  Maximize recruiting offers, unlock scholarship opportunities,
                  and find the right college fit
                </p>
                <p className="marktext">
                  * FREE COPY of our College Recruiting Guide When You Schedule{' '}
                  <br />
                  Your Assessment
                </p>
              </div>
              <div className="button-wrap width-460">
                <a
                  className="btn"
                  href="https://calendly.com/sportsforce/recruiting-assessment?month=2022-12"
                  target="_blank"
                >
                  Schedule a FREE Recruiting Assessment Call
                </a>
                <p className="text">Click above link now for a Free Call</p>
              </div>
            </div>
            <div className="video-box-wrap">
              <div className="video-img">
                <img
                  src={`${imageDownloadUrl}/img/club-video-thumbnail.png`}
                  alt=""
                />
                <div
                  className="video-play-button"
                  data-toggle="modal"
                  data-target="#VideoModal"
                >
                  <div className="video-play-inner">
                    <button className="play-btn" onClick={() => openModal()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.191"
                        height="22.92"
                        viewBox="0 0 20.191 22.92"
                      >
                        <path
                          id="play"
                          d="M9.086,27.982a2.255,2.255,0,0,1-1.1-.291,2.442,2.442,0,0,1-1.228-2.138V7.492A2.442,2.442,0,0,1,7.982,5.353a2.242,2.242,0,0,1,2.257.028l15.641,9.24a2.226,2.226,0,0,1,0,3.8L10.236,27.663a2.262,2.262,0,0,1-1.151.319Z"
                          transform="translate(-6.754 -5.063)"
                          fill="#c0321f"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="athlete-recruiting-sec">
        <div className="container">
          <div className="flexbox">
            <div className="imgbox">
              <img
                src={QuestionAssesmentImg}
                alt="Do You Have Any Of These Questions"
              />
            </div>
            <div className="content-box">
              <h1 className="title">Do You Have Any Of These Questions</h1>
              <div className="number-type-row">
                <div className="number-type-col">
                  <ul className="number-type-list">
                    <li>
                      <span className="number-count">1</span> Is he a
                      scholarship level player?
                    </li>
                    <li>
                      <span className="number-count">2</span> When should we
                      start the recruiting process?
                    </li>
                    <li>
                      <span className="number-count">3</span> How can I make
                      this process simple?
                    </li>
                    <li>
                      <span className="number-count">4</span> I get emails all
                      the time from colleges, who should I trust?
                    </li>
                  </ul>
                </div>
                <div className="number-type-col">
                  <ul className="number-type-list">
                    <li>
                      <span className="number-count">5</span> How good is my
                      son? D1, D2, D3, Junior College?
                    </li>
                    <li>
                      <span className="number-count">6</span> What schools are
                      realistic for my son?
                    </li>
                    <li>
                      <span className="number-count">7</span> What camps or
                      showcases should we attend?
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="athlete-roadmap-sec">
        <div className="container">
          <div className="sec-title">A Proven Road Map To Success</div>
        </div>
        <div className="map-img">
          <img
            src={`${imageDownloadUrl}/img/proven-road-map-to-success.png`}
            alt="A Proven Road Map To Success"
          />
          <img
            class="mobile"
            src={`${imageDownloadUrl}/img/proven-road-map-to-success-mobile.png`}
            alt="A Proven Road Map To Success"
          />
          <div className="brand-listing">
            <div className="item">
              <img src={BrandLogo1} alt="ncaa" />
            </div>
            <div className="item">
              <img src={BrandLogo2} alt="naia" />
            </div>
            <div className="item">
              <img src={BrandLogo3} alt="cccaa" />
            </div>
            <div className="item">
              <img src={BrandLogo4} alt="njcaa" />
            </div>
          </div>
        </div>
      </section>
      <section
        className="get-started-sec"
        style={{
          backgroundImage: `url(${imageDownloadUrl}/img/assessment-cta-banner-img01.jpg)`,
        }}
      >
        <div className="container">
          <div className="get-started-flexbox">
            <h3 className="sec-title">Do You Have a Recruiting Game Plan?</h3>
            <div className="short-text">
              <p>
                Get clarity by scheduling a FREE Recruiting Assessment Call with
                a SportsForce Recruiting Advisor. Just click below.{' '}
                <span className="marktext">
                  * FREE COPY of our College Baseball Recruiting Guide When You
                  Schedule Your Assessment
                </span>
              </p>
            </div>
            <div className="button-wrap">
              <a
                className="btn"
                href="https://calendly.com/sportsforce/recruiting-assessment?month=2022-12"
                target="_blank"
              >
                Schedule a FREE Recruiting Assessment Call
              </a>
              <p>Click above link now for a Free Call</p>
            </div>
          </div>
        </div>
      </section>

      <section className="average-helped-sec">
        <div className="container">
          <div className="flexbox">
            <div className="title-box">
              <h4>
                We've helped families save an average of <span>$80,000</span>
              </h4>
            </div>
            <div className="short-text">
              <p>
                Over the last 14 years SportsForce has helped over 1,500
                student-athletes successfully navigate the college recruiting
                process while saving families, on average, a reported $80,000 in
                college expenses. Check out some of our past recruits below.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="lp-team-sec">
                    <div className="container">
                        <div className="top-block">
                            <h2 className="title">Helping Athletes Find Their Best College Fit</h2>
                            <p>Every athlete and family's recruiting journey is unique. It's all about <br/> finding the right fit.</p>
                        </div>
                        <div className="team-list">
                            <div className="item">
                                <div className="team-infobox">
                                    <div className="pic-box">
                                        <img src={TylorDyson} alt="Tyler Dyson" />                       
                                    </div>
                                    <div className="detail-info">
                                        <h4 className="name">Tyler Dyson</h4>
                                        <h5 className="commited-text">COMMITTED TO</h5>
                                        <div className="university-logo">
                                            <img src={TylerDysonLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="team-infobox">
                                    <div className="pic-box">
                                        <img src={TylorDyson} alt="Tyler Dyson" />                       
                                    </div>
                                    <div className="detail-info">
                                        <h4 className="name">Tyler Dyson</h4>
                                        <h5 className="commited-text">COMMITTED TO</h5>
                                        <div className="university-logo">
                                            <img src={TylerDysonLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="team-infobox">
                                    <div className="pic-box">
                                        <img src={TylorDyson} alt="Tyler Dyson" />                       
                                    </div>
                                    <div className="detail-info">
                                        <h4 className="name">Tyler Dyson</h4>
                                        <h5 className="commited-text">COMMITTED TO</h5>
                                        <div className="university-logo">
                                            <img src={TylerDysonLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="team-infobox">
                                    <div className="pic-box">
                                        <img src={TylorDyson} alt="Tyler Dyson" />                       
                                    </div>
                                    <div className="detail-info">
                                        <h4 className="name">Tyler Dyson</h4>
                                        <h5 className="commited-text">COMMITTED TO</h5>
                                        <div className="university-logo">
                                            <img src={TylerDysonLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="team-infobox">
                                    <div className="pic-box">
                                        <img src={TylorDyson} alt="Tyler Dyson" />                       
                                    </div>
                                    <div className="detail-info">
                                        <h4 className="name">Tyler Dyson</h4>
                                        <h5 className="commited-text">COMMITTED TO</h5>
                                        <div className="university-logo">
                                            <img src={TylerDysonLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="team-infobox">
                                    <div className="pic-box">
                                        <img src={TylorDyson} alt="Tyler Dyson" />                       
                                    </div>
                                    <div className="detail-info">
                                        <h4 className="name">Tyler Dyson</h4>
                                        <h5 className="commited-text">COMMITTED TO</h5>
                                        <div className="university-logo">
                                            <img src={TylerDysonLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="team-infobox">
                                    <div className="pic-box">
                                        <img src={TylorDyson} alt="Tyler Dyson" />                       
                                    </div>
                                    <div className="detail-info">
                                        <h4 className="name">Tyler Dyson</h4>
                                        <h5 className="commited-text">COMMITTED TO</h5>
                                        <div className="university-logo">
                                            <img src={TylerDysonLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="team-infobox">
                                    <div className="pic-box">
                                        <img src={TylorDyson} alt="Tyler Dyson" />                       
                                    </div>
                                    <div className="detail-info">
                                        <h4 className="name">Tyler Dyson</h4>
                                        <h5 className="commited-text">COMMITTED TO</h5>
                                        <div className="university-logo">
                                            <img src={TylerDysonLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="team-infobox">
                                    <div className="pic-box">
                                        <img src={TylorDyson} alt="Tyler Dyson" />                       
                                    </div>
                                    <div className="detail-info">
                                        <h4 className="name">Tyler Dyson</h4>
                                        <h5 className="commited-text">COMMITTED TO</h5>
                                        <div className="university-logo">
                                            <img src={TylerDysonLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="team-infobox">
                                    <div className="pic-box">
                                        <img src={TylorDyson} alt="Tyler Dyson" />                       
                                    </div>
                                    <div className="detail-info">
                                        <h4 className="name">Tyler Dyson</h4>
                                        <h5 className="commited-text">COMMITTED TO</h5>
                                        <div className="university-logo">
                                            <img src={TylerDysonLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-wrap">
                            <button className="btn lightblue">Load more</button>
                        </div>
                    </div>
                </section> */}

      <section className="success-stories-sec">
        <div className="container">
          <div className="sec-title">
            Here's What Parents are Saying About SportsForce
          </div>
          <OwlCarousel
            className="success-stories-list success-stories-slide owl-carousel owl-theme"
            {...successStoriesSlide}
          >
            <div className="item">
              <div className="pick-box">
                <img
                  className="pick-img"
                  src={ConnorMarlImg}
                  alt="Connor Markl"
                />
                <span className="icon-box">
                  <img src={ConnorMarklLogoImg} alt="Grand Canyon University" />
                </span>
              </div>
              <div className="detail-info-box">
                <div className="short-text">
                  <p>
                    My family will be paying very little for my college
                    education. I would recommend SportsForce because of their
                    hands-on approach.
                  </p>
                </div>
                <div className="info-box">
                  <h4>Connor Markl</h4>
                  <p>Grand Canyon University</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="pick-box">
                <img
                  className="pick-img"
                  src={JimmyChatfieldImg}
                  alt="Jimmy Chatfield"
                />
                <span className="icon-box">
                  <img src={JimmyChatfieldLogoimg} alt="Yale" />
                </span>
              </div>
              <div className="detail-info-box">
                <div className="short-text">
                  <p>
                    The communications directly with coaches, and knowledge of
                    the coaches’ needs & interest was paramount! Coach Thompson
                    made all the difference!{' '}
                  </p>
                </div>
                <div className="info-box">
                  <h4>Jimmy Chatfield</h4>
                  <p>Yale</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="pick-box">
                <img
                  className="pick-img"
                  src={JamirGibsonImg}
                  alt="Jamir Gibson"
                />
                <span className="icon-box">
                  <img src={JamirGibsonLogoImg} alt="Grambling State" />
                </span>
              </div>
              <div className="detail-info-box">
                <div className="short-text">
                  <p>
                    They know all the ins & outs, a huge network of college
                    connections. If you’re serious about college baseball they
                    are who you want on your side!!!
                  </p>
                </div>
                <div className="info-box">
                  <h4>Jamir Gibson</h4>
                  <p>Grambling State</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="pick-box">
                <img
                  className="pick-img"
                  src={CharlesBeilensonImg}
                  alt="Charles Beilenson"
                />
                <span className="icon-box">
                  <img src={CharlesBeilensonLogoImg} alt="Brown" />
                </span>
              </div>
              <div className="detail-info-box">
                <div className="short-text">
                  <p>
                    My advisor kept me on task, helped me talk to coaches, and
                    told me everything I needed to do. I would 100% recommend
                    SportsForce.
                  </p>
                </div>
                <div className="info-box">
                  <h4>Charles Beilenson</h4>
                  <p>Brown</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="pick-box">
                <img
                  className="pick-img"
                  src={BennettMarkinsonImg}
                  alt="Bennett Markinson"
                />
                <span className="icon-box">
                  <img src={BennettMarkinsonLogoImg} alt="Northwestern" />
                </span>
              </div>
              <div className="detail-info-box">
                <div className="short-text">
                  <p>
                    I am super excited for the next 4 years at Northwestern!
                    SportsForce and my advisor Ryan Thompson, were super
                    helpful!
                  </p>
                </div>
                <div className="info-box">
                  <h4>Bennett Markinson</h4>
                  <p>Northwestern</p>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <CoachTestimonials whiteBackground />
      <section className="get-started-sec">
        <div className="container">
          <div className="get-started-flexbox">
            <h3 className="sec-title">
              Ready For An Honest Recruiting Assessment?
            </h3>
            <div className="short-text">
              <p>
                Get clarity by scheduling a FREE Recruiting Assessment Call with
                a SportsForce Recruiting Advisor. Just click below.
                <span className="marktext">
                  * FREE COPY of our College Baseball Recruiting Guide When You
                  Schedule Your Assessment
                </span>
              </p>
            </div>
            <div className="button-wrap">
              <a
                className="btn"
                href="https://calendly.com/sportsforce/recruiting-assessment?month=2022-12"
                target="_blank"
              >
                Schedule a Free Recruiting Assessment Call
              </a>
              <p>Click above link now for a Free Call</p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="about-mission-sec bg-gray">
                    <div className="container">
                    <div className="flexbox">
                            <div className="content-box">
                                <h3 className="sec-title font36">Meet SportsForce Baseball</h3>
                                <h4 className="postion-info">The Nations Premier Baseball College Recruiting Advisory Group</h4>
                                <div className="short-text">
                                    <p>What's different about SportsForce? We help a limited number of uncommitted players maximize their recruiting interest and secure offers to top colleges. As a personalized college recruiting advisory service we take the guesswork out of the recruiting process so you can maximize recruiting offers, get scholarships, and confidently sign with the college that best fits your student-athlete.</p>
                                    <p>Our mission at SportsForce is to maximize college offers and scholarship opportunities for our families. We do this by helping student-athletes find the college that matches the best all-around fit for them and their family. We evaluate and partner with qualified student-athletes to execute a personalized and comprehensive college recruiting game plan. To find out more about us just watch the video below.</p>
                                </div>
                            </div>
                            <div className="video-box-wrap">
                                <div className="video-img">
                                    <img src={AthleteVideoThumbnail} alt="" />
                                    <div className="video-play-button" data-toggle="modal" data-target="#VideoModal">
                                        <div className="video-play-inner">
                                        <button className="play-btn" onClick={()=>openModal()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.191" height="22.92" viewBox="0 0 20.191 22.92">
                                                    <path id="play" d="M9.086,27.982a2.255,2.255,0,0,1-1.1-.291,2.442,2.442,0,0,1-1.228-2.138V7.492A2.442,2.442,0,0,1,7.982,5.353a2.242,2.242,0,0,1,2.257.028l15.641,9.24a2.226,2.226,0,0,1,0,3.8L10.236,27.663a2.262,2.262,0,0,1-1.151.319Z" transform="translate(-6.754 -5.063)" fill="#c0321f"></path>
                                                </svg>
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>
                </section> */}

      <section className="athlete-team-sec-outer">
        <div className="container">
          <h3 className="sec-title">
            Built for Athletes & Coaches, By Athletes & Coaches
          </h3>
        </div>
        <div className="athlete-team-sec">
          <div className="container">
            <div className="content-box">
              <h3 className="title">We're on Your Team</h3>
              <div className="short-text">
                <p>
                  As former college athletes, our team understands the
                  recruiting process can be stressful. We're on your team and
                  we're ready to help.
                </p>
                <p>
                  SportsForce provides trusted and accurate data around your
                  recruiting process. Student-athletes shouldn’t have to wonder
                  if activity from college coaches is real.
                </p>
              </div>
              <div className="button-wrap">
                <button
                  className="btn blue"
                  onClick={(e) => {
                    window.open(
                      'https://www.sportsforceonline.com/baseball/team/',
                      '_blank'
                    );
                  }}
                >
                  Meet Our Team
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="brand-lp-sec">
        <div className="container">
          <div className="brand-listing">
            <div className="item">
              <img src={NcaaImg} alt="" />
            </div>
            <div className="item">
              <img src={NaiaImg} alt="" />
            </div>
            <div className="item">
              <img src={NjcaaImg} alt="" />
            </div>
            <div className="item">
              <img src={CCCAA} alt="" />
            </div>
          </div>
        </div>
      </section>

      <VideoPop
        isTestSingle={isVideoPop}
        setIsTestSingle={() => setIsVideoPop(false)}
        vidID={vidID}
      />
    </>
  );
};
export default AssessmentLP;
