import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  UPDATE_ALL_RECRUITING_LIST_START: "UPDATE_ALL_RECRUITING_LIST_START",
  UPDATE_ALL_RECRUITING_SUCCESS: "UPDATE_ALL_RECRUITING_SUCCESS",
  UPDATE_ALL_RECRUITING_FAILED: "UPDATE_ALL_RECRUITING_FAILED",
};

// ============================================ Actions ========================================================
export const updateAllRecruitingListStart = (data) => ({
  type: actionType.UPDATE_ALL_RECRUITING_LIST_START,
  payload: data,
});

export const updateAllRecruitingSuccess = (data) => ({
  type: actionType.UPDATE_ALL_RECRUITING_SUCCESS,
  payload: data,
});

export const updateAllRecruitingFailed = (data) => ({
  type: actionType.UPDATE_ALL_RECRUITING_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function updateAllRecruitingReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.UPDATE_ALL_RECRUITING_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPDATE_ALL_RECRUITING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.UPDATE_ALL_RECRUITING_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* updateAllRecruitingListSaga(action) {
    try {
        const response = yield fetchMethod(
            "/api/recruitings/updateRecruitingStatus",
            action.payload.data,
            "post",
            true
          );        
            if (response && response.status === 200) {
            const updateAllRecruitingList =  response.data.response.data;
            if(action.payload.getRecruitsListData) action.payload.getRecruitsListData();
            yield put(updateAllRecruitingSuccess(updateAllRecruitingList));
        } else {
            messagePopup("", "updateAll Recruiting List failed", "error");
            yield put(updateAllRecruitingFailed("updateAll Recruiting List failed"));
        }
    } catch (error) {
        messagePopup("", "updateAll Recruiting List failed", "error");
        yield put(updateAllRecruitingFailed("updateAll Recruiting List failed"));
    }
}

export function* watchUpdateAllRecruitingListSaga() {
  yield takeEvery(actionType.UPDATE_ALL_RECRUITING_LIST_START, updateAllRecruitingListSaga);
}
