/* BASED ON: To Title Case © 2018 David Gouch | https://github.com/gouch/to-title-case */

// THIS ISN'T REALLY CAMEL CASE, BUT I'M INHERITING THE NAME FROM THE ORIGINAL FUNCTION
// I'M USING THIS TO CAPITALIZE THE FIRST LETTER OF EACH WORD IN A STRING
// WITHOUT CAPITALIZING SMALL WORDS LIKE "a", "the", "and", "or", etc.
// AND WITHOUT MESSING UP GAELIC NAMES LIKE "O'Dowd" OR "McDonald"

// getCamelCase("this is a string-of-words") // "This is a String-of-Words"
// getCamelCase("Al-Hikmah Elementary School") // "Al-Hikmah Elementary School"
// getCamelCase("Bishop O'Dowd High School") // "Bishop O'Dowd High School"
// getCamelCase("McDonald's") // "McDonald's"
export function getCamelCase (str = "") {
    const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|is|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
    const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
    const wordSeparators = /([ :–—-])/
  
    return str.split(wordSeparators)
      .map(function (current, index, array) {
        if (
          /* Check for small words */
          current.search(smallWords) > -1 &&
          /* Skip first and last word */
          index !== 0 &&
          index !== array.length - 1 &&
          /* Ignore title end and subtitle start */
          array[index - 3] !== ':' &&
          array[index + 1] !== ':' &&
          /* Ignore small words that start a hyphenated phrase */
          (array[index + 1] !== '-' ||
            (array[index - 1] === '-' && array[index + 1] === '-'))
        ) {
          return current.toLowerCase()
        }
  
        /* Ignore intentional capitalization */
        if (current.substr(1).search(/[A-Z]|\../) > -1) {
          return current
        }
  
        /* Ignore URLs */
        if (array[index + 1] === ':' && array[index + 2] !== '') {
          return current
        }
  
        /* Capitalize the first letter */
        return current.replace(alphanumericPattern, function (match) {
          return match.toUpperCase()
        })
      })
      .join('');
  }