import { combineReducers } from "redux";
import contactUserProfileListReducer from "./contact";
import basicUserProfileListReducer from "./basicList";
import updateBasicUserProfileListReducer from "./updateBasicUserProfile";
import parentListReducer from "./allParentList";
import updateParentDataReducer from "./updateParentData";
import collegeInsightsReducer from "./collegeInsights";
import testScoresReducer from "./testScores";
import TranscriptsAndAcademicReducer from "./TranscriptsAndAcademic";
import apIpReducer from "./apIpclassDetails";
import ncaaAndNaidReducer from "./ncaaAndNaid";
import honorsDataReducer from "./academicHonors";
import additionalSportsReducer from "./allAdditionalSports";
import levelMasterReducer from "./allLevelMaster";
import userAdditionalSportReducer from "./userAdditionalSport";
import allTeamsListReducer from "./allTeams";
import pressListReducer from "./pressList";
import offersListReducer from "./offers";
import userAcademicCollegeReducer from "./academicCollege";
import userAcademicSchoolReducer from "./academicSchool";
import allLevelsSchoolReducer from "./academicLevels";
import allAcademicDivisionsListReducer from "./academicDivisions";
import allSchoolTypeListReducer from "./allSchoolType";
import allSchoolListReducer from "./allHighSchool";
import schoolAndTeamListReducer from "./schoolAndTeamType";
import allCoachDetailsReducer from "./allCoacheDetails";
import referencesReducer from "./allReferences";
import addEditEventFormListReducer from "./addEditEventForm";
import addAddedEventFormListReducer from "./addAddedEvent";
import saveVideoPhotoReducer from "./photo-video/saveVideoPhoto";
import allVideoPhotoListReducer from "./photo-video/getVideoPhotoInfo";
import deleteVideoPhotoReducer from "./photo-video/deleteVideoPhoto";
import updateVideoPhotoInfoReducer from "./photo-video/saveVideoPhotoInfo";
import getMasterPlanReducer from "./photo-video/allMasterPlan";
import allAlbumTagsListReducer from "./allAlbumTags";
import videoEditingReducer from "./videosandPhotos";
import schoolTeamUserMappingReducer from "./schoolTeamUserMapping";
import saveVideoOrderReducer from "./photo-video/saveVideoOrder";
import getVideoOrderReducer from "./photo-video/getVideoOrder";
import getVideoDraftReducer from "./photo-video/getVideoDraft";
import allMetricsListReducer from "./stats/allMetrics";
import allStatMatricDetailsListReducer from "./stats/allStatmatrixdetails";
import allJumpDataListReducer from "./stats/allJumpData";
import saveStatsMatrixDetailsReducer from "./stats/saveStatsMatrixDetails";
import allStatsOverviewReducer from "./stats/allStatsOverview";
import scoutingRequestReducer from "./stats/scoutingRequest";
import allStatsLIstReducer from "./stats/allStats";
import allSeasonStatsLIstReducer from "./stats/allSeasonStats";
import saveStatsLIstReducer from "./stats/saveAllStats";
import allStatsFieldWithPositionReducer from "./stats/allStatsfieldbasedonpositions";
import deleteAlbumTagReducer from "./photo-video/deleteAlbumTag";
import deleteSchoolTeamReducer from "./deleteSchoolTeam";
import publiPrivateStatusReducer from "./photo-video/publicPrivateStatus";
import profileTimelineAboutReducer from './profileAboutTimeline';
import profileTimelineReportReducer from './profileReportTimeline';
import profileTimelineStatusReducer from './profileTimelineTabsStatus'
import reqToAdvisorReducer from './reqToAdvisor'
import renderCountReducer from './renderCount'
import teamSideBarReducer from './teamSideHeader'
import eventSideBarReducer from "./eventSideHeader";
import profileTimelineStatReducer from './profileStatsTimeline';
import profileCardStatReducer from './profileStatsDataTimeline';
import profileSubCardStatReducer from './profileStatSubCardData';
import profileOverviewCardStatReducer from "./profileStatsOverviewDataTimeline";
export default combineReducers({
  contactUserProfile: contactUserProfileListReducer,
  basicUserProfile: basicUserProfileListReducer,
  updateBasicUserProfile: updateBasicUserProfileListReducer,
  parentList: parentListReducer,
  pressList: pressListReducer,
  updateParent: updateParentDataReducer,
  collegeInsightsUserProfile: collegeInsightsReducer,
  testScores: testScoresReducer,
  TranscriptsAndAcademic: TranscriptsAndAcademicReducer,
  apIp: apIpReducer,
  ncaaAndNaid: ncaaAndNaidReducer,
  honorsData: honorsDataReducer,
  allCoachDetails: allCoachDetailsReducer,
  allReferences: referencesReducer,
  additionalSportsList: additionalSportsReducer,
  levelMasterList: levelMasterReducer,
  userAdditionalSport: userAdditionalSportReducer,
  teamsList: allTeamsListReducer,
  offersData: offersListReducer,
  userAcademicCollege: userAcademicCollegeReducer,
  userAcademicSchool: userAcademicSchoolReducer,
  academiclevelsList: allLevelsSchoolReducer,
  academicDivisionsList: allAcademicDivisionsListReducer,
  schoolTypeList: allSchoolTypeListReducer,
  highSchoolList: allSchoolListReducer,
  addEditEventFormList: addEditEventFormListReducer,
  schoolAndTeamTypes: schoolAndTeamListReducer,
  addAddedEvent: addAddedEventFormListReducer,
  addVideoPhoto: saveVideoPhotoReducer,
  allVideoPhotoInfo: allVideoPhotoListReducer,
  deleteVideoPhoto: deleteVideoPhotoReducer,
  updateVideoPhotoInfo: updateVideoPhotoInfoReducer,
  albumTags: allAlbumTagsListReducer,
  schoolTeamUserMapping: schoolTeamUserMappingReducer,
  videoEditingDraft: videoEditingReducer,
  allMasterPlan: getMasterPlanReducer,
  saveVideoOrder: saveVideoOrderReducer,
  getVideoOrder: getVideoOrderReducer,
  getVideoDraft: getVideoDraftReducer,
  allMetrics: allMetricsListReducer,
  allStatMetricsDetails: allStatMatricDetailsListReducer,
  allJumpData: allJumpDataListReducer,
  saveStatsMatrixDetails: saveStatsMatrixDetailsReducer,
  allStatsOverview: allStatsOverviewReducer,
  scoutingRequest: scoutingRequestReducer,
  allStatsLIst: allStatsLIstReducer,
  allSeasonStatsLIst: allSeasonStatsLIstReducer,
  saveStatsLIst: saveStatsLIstReducer,
  allStatsFieldWithPosition: allStatsFieldWithPositionReducer,
  deleteAlbumTag: deleteAlbumTagReducer,
  deleteSchoolTeam: deleteSchoolTeamReducer,
  publicPrivateStatus: publiPrivateStatusReducer,
  getprofileTimelineAbout: profileTimelineAboutReducer,
  getprofileTimelineStat: profileTimelineStatReducer,
  getprofileCardStat: profileCardStatReducer,
  getProfileSubCardStat: profileSubCardStatReducer,
  getProfileOverviewCardStat: profileOverviewCardStatReducer,
  getprofileTimelineReport: profileTimelineReportReducer,
  getprofileTimelineStatusTabs : profileTimelineStatusReducer,
  reqToAdvisor : reqToAdvisorReducer,
  renderCount:  renderCountReducer,
  teamSideBarStatus: teamSideBarReducer,
  eventSideBarStatus: eventSideBarReducer
});
