import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";

export default function MuiAutoComplete(props) {
  return (
    <div className="autocomplete-class">
      <Autocomplete
        id={props.id}
        // classes={classes}
        // disablePortal={true}
        freeSolo={props.freeSolo ? true : false}
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChangeObj}
        inputValue={props.inputValue}
        onInputChange={props.onChange}
        clearOnBlur={props.clearOnBlur ? true : false}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.inputPlaceholder}
            margin="normal"
            variant="outlined"
            autoComplete="new-password"
            inputProps={{
                ...params.inputProps,
                onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                        e.stopPropagation();
                    }
                },
            }}
          />
        )}
      />
    </div>
  );
}
