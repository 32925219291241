import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  MY_PROGRESS_LIST_START: "MY_PROGRESS_LIST_START",
  MY_PROGRESS_SAVE_START: "MY_PROGRESS_SAVE_START",
  MY_PROGRESS_SUCCESS: "MY_PROGRESS_SUCCESS",
  MY_PROGRESS_FAILURE: "MY_PROGRESS_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const myProgressListStart = (data) => ({
  type: actionType.MY_PROGRESS_LIST_START,
  payload: data,
});

export const myProgressSaveStart = (data) => ({
  type: actionType.MY_PROGRESS_SAVE_START,
  payload: data,
});

export const myProgressSuccess = (data) => ({  
    type: actionType.MY_PROGRESS_SUCCESS,
    payload: data,
});
  
export const myProgressFailure = (data) => ({
    type: actionType.MY_PROGRESS_FAILURE,
    payload: data
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function myProgressReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.MY_PROGRESS_LIST_START:
      return { ...state, loading: true };

    case actionType.MY_PROGRESS_SAVE_START:
      return { ...state, loading: true };
    
    case actionType.MY_PROGRESS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.MY_PROGRESS_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* saveMyProgressSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true)
        if(response && response.status === 200) {
            if(action.payload.getAllProgressData) action.payload.getAllProgressData();
           const saveMyProgress = response;
           yield put(myProgressSuccess(""));
        } else {
          messagePopup('', 'Save My Progress Failed', 'error');
          yield put(myProgressFailure('Failed, Error occured'))
        }
    } catch (error) {
        messagePopup('', 'Save My Progress Failed', 'error');
        yield put(myProgressFailure('Failed, Error occured'))
    }
}

function* getMyProgressSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true)
        if(response.status === 200){
            const allMyProgress = response.data && response.data.data && response.data.data.allMyprogresses && response.data.data.allMyprogresses.Myprogresses;

            yield put(myProgressSuccess(allMyProgress));
        } else {
          messagePopup('', 'Fetch My Progress Data Failed', 'error');
          yield put(myProgressFailure('Failed, Error occured'))
        }
       } catch (error) {
         messagePopup('', 'Fetch My Progress Data Failed', 'error');
         yield put(myProgressFailure('Failed, Error occured'))
       }
}

export function* watchMyProgressSaga() {
  yield takeEvery(actionType.MY_PROGRESS_SAVE_START, saveMyProgressSaga);
  yield takeEvery(actionType.MY_PROGRESS_LIST_START, getMyProgressSaga);
}

