import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SEARCH_QUESTIONS_START: "SEARCH_QUESTIONS_START",
  SEARCH_QUESTIONS_SUCCESS: "SEARCH_QUESTIONS_SUCCESS",
  SEARCH_QUESTIONS_FAILED: "SEARCH_QUESTIONS_FAILED",
};

// ============================================ Actions ========================================================

export const searchQuestionsStart = (data) => ({
  type: actionType.SEARCH_QUESTIONS_START,
  payload: data,
});

export const searchQuestionsSuccess = (data) => ({
  type: actionType.SEARCH_QUESTIONS_SUCCESS,
  payload: data,
});

export const searchQuestionsFailed = (data) => ({
  type: actionType.SEARCH_QUESTIONS_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function searchQuestionsReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SEARCH_QUESTIONS_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SEARCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SEARCH_QUESTIONS_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* searchQuestionsSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Faqs/faqSearch`,
      action.payload.data,
     'post',
      true
    );
    if (response && response.status === 200) {
      let searchQuestions = response;
      yield put(searchQuestionsSuccess({ searchQuestions: searchQuestions }));
    } else {
      messagePopup("", "Search Questions Failed", "error");
      yield put(searchQuestionsFailed("Search Questions Failed"));
    }
  } catch (error) {
    messagePopup("", "Search Questions Failed", "error");
    yield put(searchQuestionsFailed("Search Questions Failed"));
  }
}

export function* watchSearchQuestionsSaga() {
  yield takeEvery(actionType.SEARCH_QUESTIONS_START, searchQuestionsSaga);
}
