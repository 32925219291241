export const getAllHeightInFeetsQuery = `
query {
    allHeightinfeets{
          Heightinfeets{
           id
           heightvalue      
        }
    }
  }
  `;

export const getAllHeightInInchesQuery = `
  query{
    allHeightininches{
      Heightininches{
        id
        inchvalue
      }
    }
  }
  `;
