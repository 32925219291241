import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    CONTACT_USER_PROFILE_LIST_START: "CONTACT_USER_PROFILE_LIST_START",
    CONTACT_USER_PROFILE_LIST_SUCCESS: "CONTACT_USER_PROFILE_LIST_SUCCESS",
    CONTACT_USER_PROFILE_LIST_FAILED: "CONTACT_USER_PROFILE_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const contactUserProfileListStart = (data) => ({
  type: actionType.CONTACT_USER_PROFILE_LIST_START,
  payload: data,
});

export const contactUserProfileListSuccess = (data) => ({
  type: actionType.CONTACT_USER_PROFILE_LIST_SUCCESS,
  payload: data,
});

export const contactUserProfileListFailed = (data) => ({
  type: actionType.CONTACT_USER_PROFILE_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function contactUserProfileListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.CONTACT_USER_PROFILE_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.CONTACT_USER_PROFILE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.CONTACT_USER_PROFILE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* contactUserProfileListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
          yield put(contactUserProfileListSuccess({ userData: response.data.data.allAthletes.Athletes}));
    } else {
      messagePopup("", "Contact User Profile List failed", "error");
      yield put(contactUserProfileListFailed("User List failed"));
    }
  } catch (error) {
    messagePopup("", "Contact User Profile List failed", "error");
    yield put(contactUserProfileListFailed("Contact User Profile List failed"));
  }
}

export function* watchContactUserProfileListSaga() {
  yield takeEvery(actionType.CONTACT_USER_PROFILE_LIST_START, contactUserProfileListSaga);
}
