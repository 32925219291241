import { put, takeEvery } from 'redux-saga/effects'
import messagePopup from '../../../../../utils/messagePopup'
import history from '../../../../../services/history'
import { fetchGraphMethod } from '../../../../../services/httpService'

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_PUBLIC_PRIVATE_STATUS_START: 'GET_PUBLIC_PRIVATE_STATUS_START',
  SAVE_PUBLIC_PRIVATE_STATUS_START: 'SAVE_PUBLIC_PRIVATE_STATUS_START',
  GET_PUBLIC_PRIVATE_STATUS_FAIL: 'GET_PUBLIC_PRIVATE_STATUS_FAIL',
  GET_PUBLIC_PRIVATE_STATUS_SUCCESS: 'GET_PUBLIC_PRIVATE_STATUS_SUCCESS',
}

/* ======================================= Actions Creator ==========================================*/

export const getPubliPrivateStatusStart = (data) => ({
  type: actionType.GET_PUBLIC_PRIVATE_STATUS_START,
  payload: data,
})

export const getPubliPrivateStatusSuccess = (data) => ({
  type: actionType.GET_PUBLIC_PRIVATE_STATUS_SUCCESS,
  payload: data,
})

export const getPubliPrivateStatusFail = (data) => ({
  type: actionType.GET_PUBLIC_PRIVATE_STATUS_FAIL,
  payload: data,
})

export const savePubliPrivateStatusStart = (data) => ({
  type: actionType.SAVE_PUBLIC_PRIVATE_STATUS_START,
  payload: data,
})
/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
}
export default function publiPrivateStatusReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_PUBLIC_PRIVATE_STATUS_START:
      return { ...state, loading: true }

    case actionType.GET_PUBLIC_PRIVATE_STATUS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null }

    case actionType.GET_PUBLIC_PRIVATE_STATUS_FAIL:
      return { ...state, loading: false, data: null, error: action.payload }

    default:
      return { ...state }
  }
}

/* ======================================= Side Effects ==========================================*/

function* getPubliPrivateStatusSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true,
    )
    if (response.status === 200 && response.data.data) {
      const finalData = response.data?.data?.allUploadprivateorpublicstatuses
        ?.Uploadprivateorpublicstatuses
        ? response.data.data.allUploadprivateorpublicstatuses
            .Uploadprivateorpublicstatuses[0]
        : null
      yield put(getPubliPrivateStatusSuccess(finalData))
    } else {
      messagePopup('', 'Get Public private status FAILED', 'error')
      yield put(getPubliPrivateStatusFail('Failed, Error occured'))
    }
  } catch (error) {
    messagePopup('', 'Get Public private status FAILED', 'error')
    yield put(getPubliPrivateStatusFail('Failed, Error occured'))
  }
}

function* savePubliPrivateStatusSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true,
    )
    if (response.status === 200 && response.data.data) {
      const finalData = response.data?.data?.saveUploadprivateorpublicstatus
      yield put(getPubliPrivateStatusSuccess(finalData))
    } else {
      messagePopup('', 'Save public private status failed', 'error')
    }
  } catch (error) {
    messagePopup('', 'Save public private status failed', 'error')
  }
}

export function* watchPubliPrivateStatusSaga() {
  yield takeEvery(
    actionType.GET_PUBLIC_PRIVATE_STATUS_START,
    getPubliPrivateStatusSaga,
  )
  yield takeEvery(
    actionType.SAVE_PUBLIC_PRIVATE_STATUS_START,
    savePubliPrivateStatusSaga,
  )
}
