import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_ACADEMIC_DIVISIONS_LIST_START: "ALL_ACADEMIC_DIVISIONS_LIST_START",
  ALL_ACADEMIC_DIVISIONS_SCHOOL_LIST_START: "ALL_ACADEMIC_DIVISIONS_LIST_START",
  ALL_ACADEMIC_DIVISIONS_LIST_SUCCESS: "ALL_ACADEMIC_DIVISIONS_LIST_SUCCESS",
  ALL_ACADEMIC_DIVISIONS_LIST_FAILED: "ALL_ACADEMIC_DIVISIONS_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const allAcademicDivisionsListStart = (data) => ({
  type: actionType.ALL_ACADEMIC_DIVISIONS_LIST_START,
  payload: data,
});
export const allAcademicDivisionsSchoolListStart = (data) => ({
  type: actionType.ALL_ACADEMIC_DIVISIONS_SCHOOL_LIST_START,
  payload: data,
});

export const allAcademicDivisionsListSuccess = (data) => ({
  type: actionType.ALL_ACADEMIC_DIVISIONS_LIST_SUCCESS,
  payload: data,
});

export const allAcademicDivisionsListFailed = (data) => ({
  type: actionType.ALL_ACADEMIC_DIVISIONS_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allAcademicDivisionsListReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.ALL_ACADEMIC_DIVISIONS_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_ACADEMIC_DIVISIONS_SCHOOL_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_ACADEMIC_DIVISIONS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_ACADEMIC_DIVISIONS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allAcademicDivisionsListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allAcademicDivisionsListSuccess({
          allCollegedivisions: response.data.data.allCollegedivisions,
        })
      );
    } else {
      messagePopup("", "Academic Divisions College List failed", "error");
      yield put(
        allAcademicDivisionsListFailed("Academic Divisions College List failed")
      );
    }
  } catch (error) {
    messagePopup("", "Academic Divisions College list failed", "error");
    yield put(
      allAcademicDivisionsListFailed("Academic Divisions College List failed")
    );
  }
}
export function* allAcademicDivisionsSchoolListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allAcademicDivisionsListSuccess({
          allSchoolDivision: response.data.data.allSchooldivisions,
        })
      );
    } else {
      messagePopup("", "Academic Divisions School List failed", "error");
      yield put(
        allAcademicDivisionsListFailed("Academic Divisions School List failed")
      );
    }
  } catch (error) {
    messagePopup("", "Academic Divisions School list failed", "error");
    yield put(
      allAcademicDivisionsListFailed("Academic Divisions School List failed")
    );
  }
}
//Watcher
export function* watchAllAcademicDivisionsListSaga() {
  yield takeEvery(
    actionType.ALL_ACADEMIC_DIVISIONS_LIST_START,
    allAcademicDivisionsListSaga
  );
  yield takeEvery(
    actionType.ALL_ACADEMIC_DIVISIONS_SCHOOL_LIST_START,
    allAcademicDivisionsSchoolListSaga
  );
}
