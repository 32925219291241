import React from "react";
import TrojanLogo from "../assets/img/trojanLogo.png";
import { imageDownloadUrl } from '../../../../../../config'
import PlayerJohn from "../assets/img/playerJohn.png";
import TrojansLogo from "../assets/img/1200px-USC_Trojans_logo.svg.png";
const Timeline = () => {
  return (
    <div>
      <div className="item">
        <div className="headFlex">
          <div className="imgBox">
            <img src={PlayerJohn} alt="playerPic" />
          </div>
          <div className="info">
            <h5 className="name">Joe Reeves</h5>
            <span>HS 2021 - RHP</span>
          </div>
          <div className="time">
            <span>9/23/2019</span>
          </div>
        </div>

        <div className="flexItem">
          <div className="imgBox">
            <img src={TrojanLogo} alt="trojanLogo" />
          </div>
          <div className="info">
            <h5>
              Wilson Hail joined the baseball roaster at Santa Margarita
              Catholic High School
            </h5>
            <span>High School in Rancho Santa Margarita,CA</span>
          </div>
        </div>
        <div className="flexItem">
          <div className="imgBox">
            <img src={TrojanLogo} alt="trojanLogo" />
          </div>
          <div className="info">
            <h5>
              Wilson Hail joined the baseball roaster at Santa Margarita
              Catholic High School
            </h5>
            <span>High School in Rancho Santa Margarita,CA</span>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="headFlex">
          <div className="imgBox">
            <img src={PlayerJohn} alt="playerPic" />
          </div>
          <div className="info">
            <h5 className="name">Joe Reeves</h5>
            <span>HS 2021 - RHP</span>
          </div>
          <div className="time">
            <span>9/23/2019</span>
          </div>
        </div>

        <div className="flexItem">
          <div className="imgBox">
            <img src={TrojanLogo} alt="trojanLogo" />
          </div>
          <div className="info">
            <h5>
              Wilson Hail joined the baseball roaster at Santa Margarita
              Catholic High School
            </h5>
            <span>High School in Rancho Santa Margarita,CA</span>
          </div>
        </div>
        <div className="flexItem">
          <div className="imgBox">
            <img src={TrojanLogo} alt="trojanLogo" />
          </div>
          <div className="info">
            <h5>
              Wilson Hail joined the baseball roaster at Santa Margarita
              Catholic High School
            </h5>
            <span>High School in Rancho Santa Margarita,CA</span>
            <div className="videoBox">
              <img src={imageDownloadUrl + "video.png"}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
