import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_LEVEL_MASTER_LIST_START: "ALL_LEVEL_MASTER_LIST_START",
  ALL_LEVEL_MASTER_LIST_SUCCESS: "ALL_LEVEL_MASTER_LIST_SUCCESS",
  ALL_LEVEL_MASTER_LIST_FAILED: "ALL_LEVEL_MASTER_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const allLevelMasterListStart = (data) => ({
  type: actionType.ALL_LEVEL_MASTER_LIST_START,
  payload: data,
});

export const allLevelMasterListSuccess = (data) => ({
  type: actionType.ALL_LEVEL_MASTER_LIST_SUCCESS,
  payload: data,
});

export const allLevelMasterListFailed = (data) => ({
  type: actionType.ALL_LEVEL_MASTER_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allLevelMasterListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ALL_LEVEL_MASTER_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_LEVEL_MASTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_LEVEL_MASTER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allLevelMasterListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allLevelMasterListSuccess({
          allLevelmasters: response.data.data.allLevelmasters.Levelmasters,
        })
      );
    } else {
      messagePopup("", "Level Master List failed", "error");
      yield put(allLevelMasterListFailed("Level Master List failed"));
    }
  } catch (error) {
    messagePopup("", "Level Master List failed", "error");
    yield put(allLevelMasterListFailed("Level Master List failed"));
  }
}

//Watcher
export function* watchAllLevelMasterListSaga() {
  yield takeEvery(
    actionType.ALL_LEVEL_MASTER_LIST_START,
    allLevelMasterListSaga
  );
}
