import React, {useEffect, useState} from 'react'
import '../assets/css/webForm.scss'
import {useDispatch, useSelector} from "react-redux";
import PhoneNumber from "../../../../../common/PhoneNumber";
import {getAuthToken, getUserInfo} from "../../../../../../services/authService";
import refresh from '../assets/img/refresh-icon.png'
import {checkPhoneNumberValidation, IsValidEmail} from "../../../../../../utils/validationUtils";
import {fetchMethod} from "../../../../../../services/httpService";
import messagePopup from "../../../../../../utils/messagePopup";
import { formatPhoneNumber } from '../../../../../../utils/validationUtils';

export const  WebForm  = ({countries, isSetFeatures, isToken, athleteInfo, athletePersonalInfoRest}) => {
    const [featuredInfo, setFeaturedInfo] = useState({}),
          [captcha, setCaptcha] = useState(""),
          [error, setError] = useState()

    useEffect(()=>{
        // if(isToken == 1){
            const cloneState = {...featuredInfo }
            cloneState['athleteFirstName'] = getUserInfo()?.FirstName ?? ""
            cloneState['athleteLastName'] = getUserInfo()?.lastName ?? ""
            cloneState['role'] = getUserInfo()?.role == "GUARDIAN" ? "Parent" : getUserInfo()?.role ?? ""
            cloneState['email'] = getUserInfo()?.Email ?? ""
            cloneState['countryDetails'] = getMyCountryDetails(athleteInfo?.athletesData.athletecountrycodeid ?? 1)
            cloneState['athleteNumber'] = athleteInfo?.athletesData?.athletephonenumber ?? ""
            setFeaturedInfo(cloneState)
        // }
        generateCaptcha()
    },[])

    const getMyCountryDetails = (id) => {
        let countryT = null;
        countries?.map((country) => {
            if (country.id === id) {
                countryT = {
                    value: country.id,
                    label: country.countryName + `(+${country.countryCode})`,
                    flag: country.countryFlagImage,
                    countryCode: country.countryCode,
                    countryShortName: country.countryShortName,
                };
            }
        });
        return countryT;
    };

    const handleChange = (field, e) =>{
        const cloneState = { ...featuredInfo }
              setError({})
        if(field == "athleteNumber"){
            if(e.target.value?.length > 10) return
            cloneState[field] = formatPhoneNumber(e.target.value);
            // cloneState[field] = e?.target?.value
            setFeaturedInfo(cloneState);
        }else if(field == "athleteFirstName" || field == "athleteLastName"){
            //this check is to except alphabets only and first letter need to be alphabet
            const regTest = /^[A-Za-z]+$/,
                  regSpace = /^[a-zA-Z ]*$/,
                testValue = e?.target?.value?.split("")[0]
            if (regTest.test(testValue)){
                if (!regSpace.test(e?.target?.value)) return;
                cloneState[field] = e?.target?.value
                setFeaturedInfo(cloneState)
            }else {
                return
            }
        }else{
            cloneState[field] = e?.target?.value
            setFeaturedInfo(cloneState)
        }
    }
    const handleCountryChange = (option) => {
        const fieldsT = { ...featuredInfo };
        setError({})
        fieldsT["countryDetails"] = option;
        setFeaturedInfo({ ...fieldsT, athleteNumber: "" });
    };

    const generateCaptcha = (chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ") =>{
        var result = '';
        for (var i = 5; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        setCaptcha(result);
    }
    const validate = () =>{
        let status = true,
            cloneState = { ...featuredInfo },
            cloneError = {...error}
        if(!cloneState?.role){
            cloneError['role'] = "Please Select Role"
            setError(cloneError)
            return status = false
        }
        else if(!cloneState?.athleteFirstName){
            cloneError['athleteFirstName'] = "Please Enter First Name"
            setError(cloneError)
            return status = false
        }
        else if(!cloneState?.athleteLastName){
            cloneError['athleteLastName'] = "Please Enter Last Name"
            setError(cloneError)
            return status = false
        }
        else if(!cloneState?.email || !IsValidEmail(cloneState?.email ?? "")){
                cloneError['email'] = "Please Enter Valid Email"
                setError(cloneError)
                return status = false
        }
        else if(!cloneState?.captcha || cloneState?.captcha != captcha){
            cloneError['captcha'] = "Please Enter Valid Captcha"
            setError(cloneError)
            return status = false
        }
        else if(!!cloneState?.athleteNumber){
         if(!checkPhoneNumberValidation(cloneState?.athleteNumber,
             cloneState?.countryDetails?.countryShortName ?? "US")){
           cloneError['athleteNumber'] = "Please Enter Valid Phone Number"
           setError(cloneError)
           return status = false
          }
        }
        return status
    }
    const onSave = async () =>{
        const validateStatus = validate()
        if(validateStatus){
            const body={
                    "requestToId":isToken == 1 ? athletePersonalInfoRest?.AthleteId : athletePersonalInfoRest?.userId,
                    "requestbyEmail":featuredInfo?.email ?? "" ,
                    "role":featuredInfo?.role ?? "",
                    "phonenumber":featuredInfo?.athleteNumber ?? "",
                    "countrycode":featuredInfo?.countryDetails?.countryCode?.toString() ?? "1",
                    "firstname":featuredInfo?.athleteFirstName ?? "",
                    "lastname":featuredInfo?.athleteLastName ?? "",
                    "message": featuredInfo?.featuredmsg ?? "",
                    "isAccessToken": isToken
            },
            _res = await fetchMethod('/api/Publicprofilefieldpreviews/requestFeaturedVideoToAdmin',
                body,
                "POST",
                isToken == 1 ? true : false)
            if(_res?.status == 200){
                messagePopup("","your request has been sent","success")
                isSetFeatures(false)
            }else{
                messagePopup("","your request has failed","error")
            }
        }else{
            console.log("validation failed")
        }
    }
    return (
        <div className="modal featured-video-modal" style={{display:"block"}} id="FeaturedVideoFromModal">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <form className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Contact - {athletePersonalInfoRest?.athleteFirstName} {athletePersonalInfoRest?.athleteLastName}</h5>
                        <button type="button" onClick={()=>isSetFeatures(false)} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body bottom-border">
                        <div className="fieldBox flexBox">
                            <div className="half">
                                <label>Role <sup>*</sup></label>
                                <select value={featuredInfo?.role ?? ""}
                                        disabled={!!getAuthToken() ? true : false}
                                onChange={(e)=>handleChange("role", e)}
                                >
                                    <option value="">Please Select</option>
                                    <option value="ATHLETES">Athlete</option>
                                    <option value="ADVISORS">Advisor</option>
                                    <option value="Parent">Parent</option>
                                    <option value="Coach">College Coach</option>
                                    <option value="Coach">Coach</option>
                                    <option value="Scout">Scout</option>
                                    <option value="Fan">Fan</option>
                                    <option value="Other">Other</option>
                                </select>
                                <div style={{ color: "red" }}>
                                    {error?.["role"] ?? ""}
                                </div>
                            </div>
                            <div className="half">
                                <label>Mobile Phone</label>
                                <PhoneNumber
                                            dropdownName="countryCode"
                                            dropdownOnChange={handleCountryChange}
                                            dropdownValue={featuredInfo?.countryDetails ?? 1}
                                            dropDownOptions={
                                                countries?.map((item) => ({
                                                    value: item.id,
                                                    label: item.countryName + `(+${item.countryCode})`,
                                                    flag: item.countryFlagImage,
                                                    countryCode: item.countryCode,
                                                    countryShortName: item.countryShortName,
                                                }))
                                            }
                                            formatOptionLabel={(option, { context, selectValue }) => {
                                                return context === "menu" ? (
                                                    <>
                                                        <img
                                                            src={option.flag}
                                                            alt=""
                                                            style={{ width: "40px", marginRight: "15px" }}
                                                        />
                                                        <span>{option.label}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <img
                                                            src={option.flag}
                                                            alt=""
                                                            style={{ width: "40px", marginRight: "15px" }}
                                                        />
                                                        <span>+{option.countryCode}</span>
                                                    </>
                                                );
                                            }}
                                            inputPlaceholder="Phone Number"
                                            inputType="phonenumber"
                                            inputValue={featuredInfo?.athleteNumber || ""}
                                            inputOnChange={(e) => {
                                                handleChange("athleteNumber", {
                                                    target: {
                                                        value: e.target.value.replace(/[^0-9]/gi, ""),
                                                    },
                                                });
                                            }}
                                        />
                                <div style={{ color: "red" }}>
                                    {error?.["athleteNumber"] ?? ""}
                                </div>
                            </div>
                        </div>
                        <div className="fieldBox flexBox">
                            <div className="half">
                                <label>First Name <sup>*</sup></label>
                                <input
                                    onChange={(e)=>handleChange("athleteFirstName", e)}
                                    value={featuredInfo?.athleteFirstName ?? ""}
                                    disabled={!!getAuthToken() ? true : false}
                                    type="text" placeholder="Enter first name"/>
                                <div style={{ color: "red" }}>
                                    {error?.["athleteFirstName"] ?? ""}
                                </div>
                            </div>
                            <div className="half">
                                <label>Last Name <sup>*</sup></label>
                                <input
                                    onChange={(e)=>handleChange("athleteLastName", e)}
                                    value={featuredInfo?.athleteLastName ?? ""}
                                    disabled={!!getAuthToken() ? true : false}
                                    type="text" placeholder="Enter last name"/>
                                <div style={{ color: "red" }}>
                                    {error?.["athleteLastName"] ?? ""}
                                </div>
                            </div>
                        </div>
                        <div className="fieldBox full">
                            <label>Email <sup>*</sup></label>
                            <input
                                onChange={(e)=>handleChange("email", e)}
                                value={featuredInfo?.email ?? ""}
                                disabled={!!getAuthToken() ? true : false}
                                type="text" placeholder="Enter Email"/>
                            <div style={{ color: "red" }}>
                                {error?.["email"] ?? ""}
                            </div>
                        </div>
                        <div className="fieldBox full">
                            <h5>Subject: Video Request - {athletePersonalInfoRest?.athleteFirstName} {athletePersonalInfoRest?.athleteLastName}</h5>
                            <label>Message</label>
                            <textarea
                                onChange={(e)=>handleChange("featuredmsg", e)}
                                value = {featuredInfo?.featuredmsg ?? ""}
                                placeholder="Enter your Message here...">
                            </textarea>
                        </div>
                        <div className="fieldBox full">
                            <label>Captcha <sup>*</sup></label>
                            <div className="captcha-flex-box">
                                <div className="item">
                                    <input
                                        value={featuredInfo?.captcha ?? ""}
                                        onChange={(e) =>handleChange("captcha", e)}
                                        type="text"
                                        placeholder="Enter Captcha"/>
                                    <div style={{ color: "red" }}>
                                        {error?.["captcha"] ?? ""}
                                    </div>
                                </div>
                                <div className="captcha-code">
                                    <span className="code">{captcha}</span>
                                    <span className="icon"><img
                                        onClick={()=>generateCaptcha()}
                                        src={refresh} alt="refresh"/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            onClick={()=>onSave()}
                            type="button"
                            className="btn blue"
                            data-dismiss="modal">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}