import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_RESOURCE_LIBRARY_START: "GET_RESOURCE_LIBRARY_START",
  RESOURCE_LIBRARY_SUCCESS: "RESOURCE_LIBRARY_SUCCESS",
  RESOURCE_LIBRARY_FAILURE: "RESOURCE_LIBRARY_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const getResourceLibraryStart = (data) => ({
  type: actionType.GET_RESOURCE_LIBRARY_START,
  payload: data,
});

export const ResourceLibrarySuccess = (data) => ({
  type: actionType.RESOURCE_LIBRARY_SUCCESS,
  payload: data,
});

export const ResourceLibraryFailure = (data) => ({
  type: actionType.RESOURCE_LIBRARY_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function resourceLibraryReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_RESOURCE_LIBRARY_START:
      return { ...state, loading: true };

    case actionType.RESOURCE_LIBRARY_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.RESOURCE_LIBRARY_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* getResourceLibrarySaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200) {
      const allResourceLibrary = response.data.data.allResourselibraries
        ? response.data.data.allResourselibraries
          : null;
      yield put(ResourceLibrarySuccess(allResourceLibrary));
    } else {
      messagePopup("", "Fetch Resource Library Data Failed", "error");
      yield put(ResourceLibraryFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch Resource Library Data Failed", "error");
    yield put(ResourceLibraryFailure("Failed, Error occured"));
  }
}

export function* watchResourceLibrary() {
  yield takeEvery(actionType.GET_RESOURCE_LIBRARY_START, getResourceLibrarySaga);
}
