import React, { useState } from 'react';

function CollegeOverview(props) {
  const {
    conference,
    academicSelectivity,
    schoolSize,
    tuitionInCost,
    tuitionOutCost,
  } = props;
  const [expand, setExpand] = React.useState(false);
  const handleExpandCollapseDetails = () => {
    setExpand(!expand);
  };

  return (
    <div>
      <button
        className={`btn ${expand ? 'expand' : 'collapse'}`}
        type="button"
        onClick={handleExpandCollapseDetails}
      >
        <h5>College Overview</h5>
        <div className="closeIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4.568"
            height="7.99"
            viewBox="0 0 4.568 7.99"
          >
            <defs>
              <style></style>
            </defs>
            <path
              className="a"
              d="M1.377,3.993,4.4.972a.569.569,0,0,0,0-.806.576.576,0,0,0-.809,0L.166,3.589a.57.57,0,0,0-.017.787l3.44,3.447A.571.571,0,0,0,4.4,7.017Z"
              transform="translate(4.568 7.99) rotate(180)"
            />
          </svg>
        </div>
      </button>
      <div className={`collapse ${expand ? 'show' : ''}`} id="collapseExample">
        <div className="card card-body">
          <div className="collegeOverView">
            <div className="flexBox">
              <div className="academicData">
                <ul>
                  <li>
                    <label>Athletic Conference</label>
                    <span>{conference}</span>
                  </li>
                  <li>
                    <label>Academic Selectivity</label>
                    <span>{academicSelectivity}</span>
                  </li>
                </ul>
              </div>
              <div className="tutionData">
                <ul>
                  <li>
                    <label>School Size</label>
                    <span>{Number(schoolSize).toLocaleString('en-US')}</span>
                  </li>
                  <li>
                    <label>Tuition In</label>
                    <span>
                      $
                      {Number(tuitionInCost).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </li>
                  <li>
                    <label>Tuition Out</label>
                    <span>
                      $
                      {Number(tuitionOutCost).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollegeOverview;
