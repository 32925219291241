import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from 'react-router-dom';
import moment from 'moment';
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";
import { imageDownloadUrl } from "../../../../../../config";
import teamDefault from "../../editUserProfile/assets/img/event_default.png";
import GoTopButton from "../../editUserProfile/GoTopButton";
import { getUserInfo } from '../../../../../../services/authService';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import eventLogoImg01 from "../assets/img/event_default.png"
import { VideoPop } from "../../../../landingPage/common/videoPopup";
import { getThumbnail, typeConvert } from '../../../../../common/thumbnail'
import { getCamelCase } from "../../../../../common/camelCaseFunc";
import { vidCountIncre } from "../../../../../common/userViewCount";
import { getUTCTimeDateOverall, getUTCTimeDateDash } from '../../../../../../utils/dateTimeFormatter'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";
import { profileURLData } from "../../../../../common/getProfileURLData";

const optionCarousel = {
    className: "owl-carousel owl-theme",
    loop:true,
    autoPlay:false,
    nav: true,
    dots:false,
    items: 1
}

const TopEditProfileButton = ({ route }) => {
  return (
    <a
      className="edit-link"
      style={{ cursor: "pointer", color: "#c0321f" }}
      onClick={() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
          history.push(route)
        }, 1000)
      }}
    >
      Edit
    </a>
  );
}

const EditProfileButton = ({ history, route }) => {
	return (
		<a
			className="edit-link"
			style={{ cursor: "pointer" }}
			onClick={() => {
				window.scrollTo(0, 0)
				setTimeout(() => {
					history.push(route)
				}, 1000)
			}}
		>
			Edit
		</a>
	);
}

export const ProfileAbout = withRouter((props) => {
    const profileAbout = useSelector(profileAbout => profileAbout?.athlete?.editUserProfileData?.getprofileTimelineAbout?.data?.profileTimelineAbout ?? null);
    const state = useSelector(state => state);
    const about = useDispatch();
    const location = useLocation();
    const fakeInput = useRef(null);
    const [activeClass, setActiveClass] = useState('Basic');
    const [allOfferPrivate, setAllOfferPrivate] = useState(false);
    const [vidID, setVidID] = useState(null);
    const [isVidPop, setIsVidPop] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [tabSel, setTabSel] = useState(1);
	
    const Athid = profileURLData(location?.pathname, 'athlete_id');
    const isToken = profileURLData(location?.pathname, 'token');
    const pubViewID = JSON.parse(localStorage.getItem('pubViewID'));
	
    useEffect(() => {
        const athleteStatusID = !!Athid ? Athid : !!state?.athlete?.dashboard?.home?.athleteProfileStatus?.status ?
            +pubViewID || 0 : !!getUserInfo() ? getUserInfo().id : +pubViewID
        about({ type: "GET_PROFILE_TIMELINE_ABOUT_START", payload: { athleteId: athleteStatusID, isAccessToken: isToken } })
        about({ type: "RELATIONSHIP_LIST_START" })
        fakeInput.current.focus();
        fakeInput.current.select();
    }, [pubViewID, state?.athlete?.dashboard?.home?.athleteProfileStatus?.status, Athid])

    //This effect will check if all the offers are private in public profile.
    useEffect(() => {
        if (profileAbout?.offerData.length && props.isPublicView) {
            const privateOffers = profileAbout?.offerData.filter((data) => data.managevisible === 0);
            if (privateOffers.length === profileAbout?.offerData.length) {
                setAllOfferPrivate(true);
            } else {
                setAllOfferPrivate(false);
            }
        }
    }, [profileAbout?.offerData]);

    const srcollToId = (value, tabNum) => {
        setTabSel(tabNum)
        const elm = document.getElementById(value)
        elm.scrollIntoView({ block: "center" });
        setActiveClass(value);
    }

    const openVideoOnNewTab = (url, linkType, data) => {
        if (url) {
            // window.open(url, "_blank");
            setVidID(url)
            setIsLink(linkType)
            setTimeout(()=>{
                setIsVidPop(true)
            },500)
            vidCountIncre(data, true)
        }
    };

    const getNameFromCount = (data) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthName = months[parseInt(data)-1];
        return monthName;
    }

    const showContact = () => {
        const aboutData = profileAbout?.athleteBasicInfo?.[0] ?? {}
        if (aboutData?.id == getUserInfo()?.id) {
            return true
        } else {
            return "visitor"
        }
    }


    return (
        <>
			<div className="TimelineData">
				<input className="fakeInput" type="text" ref={fakeInput} />
				<div className="item pt-0 pb-0 mb-0">
					<div className="about-sec-wrap">
						<div className="sidebar aboutsidebar-hide-mobile" id="myScrollspy">
							<ul className="menu-links nav">
								<li><a className={`nav-link ${activeClass === "Basic" ? "active" : ""}`} style={{ cursor: "pointer" }}
									// window.scrollTo(0, 504.79998779296875);
									onClick={() => { srcollToId('Basic') }}
								>Basic</a></li>
								<li><a className={`nav-link ${activeClass === "VideoandPhoto" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('VideoandPhoto')}
								// window.scrollTo(0,1153.5999755859375)}
								>Videos & Photos</a></li>
								<li><a className={`nav-link ${activeClass === "Contact" ? "active" : ""}`}
									style={{cursor: "pointer"}} onClick={() => srcollToId('Contact')
									// window.scrollTo(0,1876)
								}>Contact</a></li>
								<li><a className={`nav-link ${activeClass === "Vitals" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Vitals')
									// window.scrollTo(0,2707.199951171875)
								}>Vitals</a></li>
								<li><a className={`nav-link ${activeClass === "Teams" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Teams')
									// window.scrollTo(0,3202.39990234375)
								}>Teams</a></li>
								<li><a className={`nav-link ${activeClass === "Events" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Events')
									// window.scrollTo(0,3927.199951171875)
								}>Events</a></li>
								<li><a className={`nav-link ${activeClass === "References" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('References')
									// window.scrollTo(0,4319.2001953125)
								}>References</a></li>
								<li><a className={`nav-link ${activeClass === "AdditionalSports" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('AdditionalSports')
									// window.scrollTo(0,4934.39990234375)
								}>Additional Sports</a></li>
								<li><a className={`nav-link ${activeClass === "Press" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Press')
									// window.scrollTo(0,5098.39990234375)
								}>Press</a></li>
								<li><a className={`nav-link ${activeClass === "Academics" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Academics')
									// window.scrollTo(0,5239.2001953125)
								}>Academics</a></li>
								<li><a className={`nav-link ${activeClass === "GPAScores" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('GPAScores')
									// window.scrollTo(0,5779.2001953125)
								}>Grades & Scores</a></li>
								{!props.isPublicView &&
								<li><a className={`nav-link ${activeClass === "CollegeInsights" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('CollegeInsights')
									// window.scrollTo(0,7599.2001953125)
								}>College Insights</a></li>}
								<li><a className={`nav-link ${activeClass === "Offers" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Offers')
									// window.scrollTo(0,8212)
								}>Offers</a></li>
								<li><a className={`nav-link ${activeClass === "Commitment" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Commitment')
									// window.scrollTo(0,8212)
								}>Commitment</a></li>
							</ul>
						</div>
						<div className="sidebar aboutsidebar-show-mobile" id="myScrollspy">
							<ul className="menu-links nav">
							<OwlCarousel {...optionCarousel} startPosition={tabSel - 1}>
								<li><a className={`nav-link ${activeClass === "Basic" ? "active" : ""}`} style={{ cursor: "pointer" }}
									// window.scrollTo(0, 504.79998779296875);
									onClick={() => { srcollToId('Basic', 1) }}
								>Basic</a></li>
								<li><a className={`nav-link ${activeClass === "VideoandPhoto" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('VideoandPhoto', 2)}
									// window.scrollTo(0,1153.5999755859375)}
								>Videos & Photos</a></li>
								<li><a className={`nav-link ${activeClass === "Contact" ? "active" : ""}`}
									style={{cursor: "pointer"}} onClick={() => srcollToId('Contact', 3)
									// window.scrollTo(0,1876)
								}>Contact</a></li>
								<li><a className={`nav-link ${activeClass === "Vitals" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Vitals', 4)
									// window.scrollTo(0,2707.199951171875)
								}>Vitals</a></li>
								<li><a className={`nav-link ${activeClass === "Teams" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Teams', 5)
									// window.scrollTo(0,3202.39990234375)
								}>Teams</a></li>
								<li><a className={`nav-link ${activeClass === "Events" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Events', 6)
									// window.scrollTo(0,3927.199951171875)
								}>Events</a></li>
								<li><a className={`nav-link ${activeClass === "References" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('References', 7)
									// window.scrollTo(0,4319.2001953125)
								}>References</a></li>
								<li className={'tabslide-item'}><a className={`nav-link ${activeClass === "AdditionalSports" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('AdditionalSports', 8)
									// window.scrollTo(0,4934.39990234375)
								}>Additional Sports</a></li>
								<li><a className={`nav-link ${activeClass === "Press" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Press', 9)
									// window.scrollTo(0,5098.39990234375)
								}>Press</a></li>
								<li><a className={`nav-link ${activeClass === "Academics" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Academics', 10)
									// window.scrollTo(0,5239.2001953125)
								}>Academics</a></li>
								<li><a className={`nav-link ${activeClass === "GPAScores" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('GPAScores', 11)
									// window.scrollTo(0,5779.2001953125)
								}>Grades & Scores</a></li>
								{!props.isPublicView &&
								<li><a className={`nav-link ${activeClass === "CollegeInsights" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('CollegeInsights', 12)
									// window.scrollTo(0,7599.2001953125)
								}>College Insights</a></li>}
								<li><a className={`nav-link ${activeClass === "Offers" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Offers', 13)
									// window.scrollTo(0,8212)
								}>Offers</a></li>
								<li><a className={`nav-link ${activeClass === "Commitment" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Commitment', 14)
									// window.scrollTo(0,8212)
								}>Commitment</a></li>
							</OwlCarousel>
							</ul>
						</div>
						<div className="about-detail-box">
							<div id="Basic" className="common-item-box">
								<h3 className="title" style={{ display: "flex", justifyContent: "space-between" }}>Basic
									<TopEditProfileButton history={props.history} route={'/basic'} />
								</h3>
								<div className="content-box">
									<ul className="data-info-item">
										<li className="w-100">
											<span>Profile Photo</span>
											{
												props.isPublicView ? ( 
													// If you're viewing the profile of another user, display profile image only
													state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
														if (itm?.publicprofilefieldid == 21 && itm?.active) {
															return (
																<span className="profile-img">
																	{
																		profileAbout?.athleteBasicInfo?.[0]?.profileImages ? (
																			<img
																				src={`${imageDownloadUrl + "/" + profileAbout?.athleteBasicInfo?.[0]?.profileImages || ""}`}
																				alt={""}
																			/>
																		) : (
																			<span style={{ fontWeight: "700", fontSize: "28px" }}>
																				{`${profileAbout?.athleteBasicInfo?.[0]?.athleteFirstName ? (profileAbout?.athleteBasicInfo?.[0]?.athleteFirstName?.split("")[0].concat(profileAbout?.athleteBasicInfo?.[0]?.athleteLastName ? profileAbout?.athleteBasicInfo?.[0]?.athleteLastName?.split("")[0] : "")).toUpperCase() : ""}`}
																			</span>
																		)
																	}
																</span>
															)
														} else if (itm?.publicprofilefieldid == 21 && itm?.active == 0) {
															return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
														}
													}) || ""
												) : ( 
													// Else you're viewing the profile of the current user, display profile image and edit profile button
													<>
														<span className="profile-img">
															{
																profileAbout?.athleteBasicInfo?.[0]?.profileImages ? (
																	<img
																		src={`${imageDownloadUrl + "/" + profileAbout?.athleteBasicInfo?.[0]?.profileImages || ""}`}
																		alt={""}
																	/>
																) : (
																	<span style={{ fontWeight: "700", fontSize: "28px" }}>
																		{`${profileAbout?.athleteBasicInfo?.[0]?.athleteFirstName ? (profileAbout?.athleteBasicInfo?.[0]?.athleteFirstName?.split("")[0].concat(profileAbout?.athleteBasicInfo?.[0]?.athleteLastName ? profileAbout?.athleteBasicInfo?.[0]?.athleteLastName?.split("")[0] : "")).toUpperCase() : ""}`}
																	</span>
																)
															}
														</span>
													</>
												)
											}
										</li>
										<li>
											<span>First Name</span>
											<span>{profileAbout && getCamelCase(profileAbout.athleteBasicInfo[0]?.athleteFirstName || "")}</span>
										</li>
										<li>
											<span>Last Name</span>
											<span>{profileAbout && getCamelCase(profileAbout?.athleteBasicInfo[0]?.athleteLastName || "")}</span>
										</li>
										<li>
											<span>Gender</span>
											<span>{profileAbout && getCamelCase(profileAbout?.athleteBasicInfo[0]?.gender || "")}</span>
										</li>
										<li>
											<span>Date of Birth</span>
											{props.isPublicView ?
												state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
													if (itm?.publicprofilefieldid == 22 && itm?.active) return <span>{profileAbout && profileAbout?.athleteBasicInfo?.[0]?.dateOfBirth && getUTCTimeDateOverall(profileAbout?.athleteBasicInfo[0]?.dateOfBirth) || ""}</span>
													else if (itm?.publicprofilefieldid == 22 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
												}) || ""
												:
												<span>{profileAbout && profileAbout?.athleteBasicInfo?.[0]?.dateOfBirth && getUTCTimeDateOverall(profileAbout?.athleteBasicInfo?.[0]?.dateOfBirth) || ""}</span>
											}
										</li>
										<li>
											<span>High School Grad Year</span>
											<span>{profileAbout && profileAbout?.athleteBasicInfo[0]?.yearValue || ""}</span>
										</li>
										<li>
											<span>What's Your GPA scale?</span>
											{props.isPublicView ?
												state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
													if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{profileAbout && profileAbout?.athleteBasicInfo[0]?.GpaScaleValue || ""}</span>
													else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>

												}) || ""
												: <span>{profileAbout && profileAbout?.athleteBasicInfo[0]?.GpaScaleValue || ""}</span>}
										</li>
										<li>
											<span>GPA (Unweighted)</span>
											{props.isPublicView ?
												state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
													if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{profileAbout && profileAbout?.athleteBasicInfo[0]?.unweightedgpa || ""}</span>
													else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>

												}) || ""
												: <span>{profileAbout && profileAbout?.athleteBasicInfo[0]?.unweightedgpa || ""}</span>}
										</li>
										<li>
											<span>GPA (Weighted)</span>
											{props.isPublicView ?
												state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
													if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{profileAbout && profileAbout?.athleteBasicInfo[0]?.gpaScale || ""}</span>
													else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>

												}) || ""
												: <span>{profileAbout && profileAbout?.athleteBasicInfo[0]?.gpaScale || ""}</span>}
										</li>
										{/*<li>*/}
										{/*    <span>Weighted GPA</span>*/}
										{/*    <span><input type={'checkbox'} checked={profileAbout && profileAbout?.athleteBasicInfo[0]?.weightGpa ? true : false || false}/></span>*/}
										{/*</li>*/}
										<li>
											<span>
												{/*Not in high school currently?*/}
												Post High School
											</span>
											<span>{profileAbout && profileAbout?.athleteBasicInfo[0]?.highScoolstatus === 1 ? 'Yes' : 'No' || ""}</span>
										</li>
										{/* {value is notupdateing} */}
										<li>
											<span>Current Level</span>
											<span>{profileAbout?.athleteBasicInfo?.[0]?.currentLevelValue ? profileAbout?.athleteBasicInfo?.[0]?.currentLevelValue : ""}</span>
										</li>
									</ul>
									<h3>About Me</h3>
									<p>{profileAbout && profileAbout?.athleteBasicInfo[0]?.aboutMe || ""} </p>
								</div>
							</div>
							<div id="VideoandPhoto" className="common-item-box">
								<h3 className="title">Videos & Photos
									{/*    {props.isPublicView &&*/}
									{/*            state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout[4].active &&*/}
									{/*    <span className="icon" style={{marginLeft:"10px"}}>*/}
									{/*  <svg*/}
									{/*      xmlns="http://www.w3.org/2000/svg"*/}
									{/*      width="13.286"*/}
									{/*      height="15.5"*/}
									{/*      viewBox="0 0 13.286 15.5"*/}
									{/*  >*/}
									{/*    <defs>*/}
									{/*      <style></style>*/}
									{/*    </defs>*/}
									{/*    <g transform="translate(-4.5 -2.25)">*/}
									{/*      <path*/}
									{/*          fill="#c0321f"*/}
									{/*          className="a"*/}
									{/*          d="M15.571,8.893a2.214,2.214,0,0,1,2.214,2.214v4.429a2.214,2.214,0,0,1-2.214,2.214H6.714A2.214,2.214,0,0,1,4.5,15.536V11.107A2.214,2.214,0,0,1,6.714,8.893V6.679a4.429,4.429,0,1,1,8.857,0ZM11.143,3.726A2.952,2.952,0,0,1,14.1,6.679V8.893H8.19V6.679a2.952,2.952,0,0,1,2.952-2.952Zm4.429,6.643H6.714a.738.738,0,0,0-.738.738v4.429a.738.738,0,0,0,.738.738h8.857a.738.738,0,0,0,.738-.738V11.107A.738.738,0,0,0,15.571,10.369Z"*/}
									{/*      />*/}
									{/*    </g>*/}
									{/*  </svg>*/}
									{/*</span>*/}
									{/*}*/}
								</h3>
								<div className="content-box">
									{
										props.isPublicView ? "" : (
											<div className="edit-video-link">
													<EditProfileButton history={props.history} route={'/videoPhotos'} />
											</div>
										)
									}
									{props.isPublicView ?
										state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
											if (itm?.publicprofilefieldid == 26 && itm?.active) {
												return (<ul className="video-grid-list clearfix">
													{profileAbout && profileAbout.featuredPhotosAndVideos.length > 0 ?
														profileAbout.featuredPhotosAndVideos.map((item, index) => {
															if (item.isphotosorvideosLik === 1) {
																return (
																	<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
																		<div className="img-box">
																			<video 
																				//controls
																				controlsList={isToken == 1 ? "" : "nodownload"}
																			>
																				<source
																					src={`${imageDownloadUrl +
																						"/" +
																						typeConvert(item?.videoname, "Athlete_About")+"#t=0.2"
																						}`}
																					alt="video"
																				/>
																			</video>
																			<div className="video-play-button"  onClick={() =>
																				openVideoOnNewTab(`${imageDownloadUrl +
																				"/" +
																				item?.videoname+"#t=0.2"
																				}`, false, item)}>
																				<div className="video-play-inner">
																					<button style={{background: "#c0321f"}}>
																						<svg xmlns="http://www.w3.org/2000/svg" width="10.802"
																							fill={'#fff'}
																							height="16.203" viewBox="0 0 10.802 16.203">
																							<path className="a" d="M8.1,0l8.1,10.8H0Z"
																								transform="translate(10.802) rotate(90)"></path>
																						</svg>
																					</button>
																				</div>
																			</div>
																		</div>
																		<div className="video-detail-box">
																			<div className="title-box">
																				<div className="video-title half">
																					{item?.originalname || ""}
																				</div>
																				{/*<div className="share-video-button half">*/}
																				{/*    <svg xmlns="http://www.w3.org/2000/svg" width="10.689" height="11.803"*/}
																				{/*         viewBox="0 0 10.689 11.803">*/}
																				{/*        <path*/}
																				{/*            d="M13.4,11.457a1.623,1.623,0,0,0-1.161.445L7.984,9.44a1.994,1.994,0,0,0,.06-.415,1.993,1.993,0,0,0-.06-.415l4.2-2.432a1.782,1.782,0,1,0-.566-1.3,1.982,1.982,0,0,0,.06.415L7.478,7.72a1.792,1.792,0,0,0-1.221-.474A1.763,1.763,0,0,0,4.5,9.025a1.793,1.793,0,0,0,3.007,1.3l4.228,2.461a1.488,1.488,0,0,0-.06.385,1.727,1.727,0,1,0,1.727-1.72Z"*/}
																				{/*            transform="translate(-4.5 -3.094)"></path>*/}
																				{/*    </svg>*/}
																				{/*</div>*/}
																			</div>
																			<div className="video-info">
																				<div className="half">
																					<span>{getUTCTimeDateDash(item.createdOn)}</span>
																					<span>{moment(item.createdOn).format('LT')}</span>
																					<span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
																					<span>
																						<svg xmlns="http://www.w3.org/2000/svg" width="12"
																							height="16.679" viewBox="0 0 12 16.679">
																							<path className="a"
																								d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
																								transform="translate(-19.188 -1.747)"></path>
																							<path className="b"
																								d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
																								transform="translate(-37.815 -60.681)"></path>
																						</svg>
																						{item?.totalLikeCount ? item?.totalLikeCount : 0}
																					</span>
																				</div>
																			</div>
																		</div>
																	</li>
																)
															}
															else if (item.isphotosorvideosLik === 2) {
																return (
																	<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
																		<div className="img-box">
																			<video
																				poster={getThumbnail(item?.videolink, item, "ProfileABT")}
																			controlsList={isToken == 1 ? "" : "nodownload"}
																			>
																				<source
																					src={item?.videolink}
																					alt="video/youtube"
																				/>
																			</video>
																			<div className="video-play-button" onClick={() =>
																				openVideoOnNewTab(item?.videolink, true, item)
																			}>
																				<div className="video-play-inner">
																					<button style={{background: "#c0321f"}}>
																						<svg xmlns="http://www.w3.org/2000/svg" width="10.802"
																							fill={'#fff'}
																							height="16.203" viewBox="0 0 10.802 16.203">
																							<path className="a" d="M8.1,0l8.1,10.8H0Z"
																								transform="translate(10.802) rotate(90)"></path>
																						</svg>
																					</button>
																				</div>
																			</div>
																		</div>
																		<div className="video-detail-box">
																			<div className="title-box">
																				<div className="video-title half">
																					{item?.originalname || ""}
																				</div>
																			</div>
																			<div className="video-info">
																				<div className="half">
																					<span>{getUTCTimeDateDash(item.createdOn)}</span>
																					<span>{moment(item.createdOn).format('LT')}</span>
																					<span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
																					<span>
																						<svg xmlns="http://www.w3.org/2000/svg" width="12"
																							height="16.679" viewBox="0 0 12 16.679">
																							<path className="a"
																								d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
																								transform="translate(-19.188 -1.747)"></path>
																							<path className="b"
																								d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
																								transform="translate(-37.815 -60.681)"></path>
																						</svg>
																						{item?.totalLikeCount ? item?.totalLikeCount : 0}
																					</span>
																				</div>
																			</div>
																		</div>
																	</li>
																)
															}
															else if (item.isphotosorvideosLik === 0) {
																return (
																	<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
																		<div className="img-box">
																			<img
																				src={`${imageDownloadUrl + "/" + item?.photosname
																					}`}
																				alt="profile Bg"
																				style={{ width: "100%", height: "100%" }}
																			/>
																			{/*<source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>*/}
																			{/*<source src="https://www.youtube.com/embed/aqz-KE-bpKQ"*/}
																			{/*        type="video/ogg"/>*/}
																			<div className="video-play-button">
																				<div className="video-play-inner">
																					{/*<button>*/}
																					{/*    <svg xmlns="http://www.w3.org/2000/svg" width="10.802"*/}
																					{/*         height="16.203" viewBox="0 0 10.802 16.203">*/}
																					{/*        <path className="a" d="M8.1,0l8.1,10.8H0Z"*/}
																					{/*              transform="translate(10.802) rotate(90)"></path>*/}
																					{/*    </svg>*/}
																					{/*</button>*/}
																				</div>
																			</div>
																		</div>
																		<div className="video-detail-box">
																			<div className="title-box">
																				<div className="video-title half">
																					{item?.originalname || ""}
																				</div>
																				{/*<div className="share-video-button half">*/}
																				{/*    <svg xmlns="http://www.w3.org/2000/svg" width="10.689" height="11.803"*/}
																				{/*         viewBox="0 0 10.689 11.803">*/}
																				{/*        <path*/}
																				{/*            d="M13.4,11.457a1.623,1.623,0,0,0-1.161.445L7.984,9.44a1.994,1.994,0,0,0,.06-.415,1.993,1.993,0,0,0-.06-.415l4.2-2.432a1.782,1.782,0,1,0-.566-1.3,1.982,1.982,0,0,0,.06.415L7.478,7.72a1.792,1.792,0,0,0-1.221-.474A1.763,1.763,0,0,0,4.5,9.025a1.793,1.793,0,0,0,3.007,1.3l4.228,2.461a1.488,1.488,0,0,0-.06.385,1.727,1.727,0,1,0,1.727-1.72Z"*/}
																				{/*            transform="translate(-4.5 -3.094)"></path>*/}
																				{/*    </svg>*/}
																				{/*</div>*/}
																			</div>
																			<div className="video-info">
																				<div className="half">
																					<span>{getUTCTimeDateDash(item.createdOn)}</span>
																					<span>{moment(item.createdOn).format('LT')}</span>
																					<span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
																					<span>
																						<svg xmlns="http://www.w3.org/2000/svg" width="12"
																							height="16.679" viewBox="0 0 12 16.679">
																							<path className="a"
																								d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
																								transform="translate(-19.188 -1.747)"></path>
																							<path className="b"
																								d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
																								transform="translate(-37.815 -60.681)"></path>
																						</svg>
																						{item?.totalLikeCount ? item?.totalLikeCount : 0}
																					</span>
																				</div>
																			</div>
																		</div>
																	</li>
																)
															}
														}) : "No Videos or Photos"}
												</ul>)
											}
											else if (itm?.publicprofilefieldid == 26 && itm?.active == 0) {
												return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
											}
										}) || ""
										:
										<ul className="video-grid-list clearfix">
											{profileAbout && profileAbout.featuredPhotosAndVideos.length > 0 ?
												profileAbout.featuredPhotosAndVideos.map((item, index) => {
													if (item.isphotosorvideosLik === 1) {
														return (
															<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
																<div className="img-box">
																	<video 
																	//controls
																	controlsList={isToken == 1 ? "" : "nodownload"}
																	>
																		<source
																			src={`${imageDownloadUrl +
																				"/" +
																				item?.videoname+"#t=0.2"
																				}`}
																			alt="video"
																		/>
																	</video>
																	<div className="video-play-button" onClick={() =>
																		openVideoOnNewTab(`${imageDownloadUrl +
																		"/" +
																		item?.videoname
																		}`, false, item)}>
																		<div className="video-play-inner">
																			<button style={{background: "#c0321f"}}>
																				<svg xmlns="http://www.w3.org/2000/svg" width="10.802"
																					fill={'#fff'}
																					height="16.203" viewBox="0 0 10.802 16.203">
																					<path className="a" d="M8.1,0l8.1,10.8H0Z"
																						transform="translate(10.802) rotate(90)"></path>
																				</svg>
																			</button>
																		</div>
																	</div>
																</div>
																<div className="video-detail-box">
																	<div className="title-box">
																		<div className="video-title half">
																			{item?.originalname || ""}
																		</div>
																		{/*<div className="share-video-button half">*/}
																		{/*<svg SCHOOL & CLUB TEAMSxmlns="http://www.w3.org/2000/svg" width="10.689" height="11.803"*/}
																		{/*     viewBox="0 0 10.689 11.803">*/}
																		{/*    <path*/}
																		{/*        d="M13.4,11.457a1.623,1.623,0,0,0-1.161.445L7.984,9.44a1.994,1.994,0,0,0,.06-.415,1.993,1.993,0,0,0-.06-.415l4.2-2.432a1.782,1.782,0,1,0-.566-1.3,1.982,1.982,0,0,0,.06.415L7.478,7.72a1.792,1.792,0,0,0-1.221-.474A1.763,1.763,0,0,0,4.5,9.025a1.793,1.793,0,0,0,3.007,1.3l4.228,2.461a1.488,1.488,0,0,0-.06.385,1.727,1.727,0,1,0,1.727-1.72Z"*/}
																		{/*        transform="translate(-4.5 -3.094)"></path>*/}
																		{/*</svg>*/}
																		{/*</div>*/}
																	</div>
																	<div className="video-info">
																		<div className="half">
																			<span>{getUTCTimeDateDash(item.createdOn)}</span>
																			<span>{moment(item.createdOn).format('LT')}</span>
																			<span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
																			<span>
																				<svg xmlns="http://www.w3.org/2000/svg" width="12"
																					height="16.679" viewBox="0 0 12 16.679">
																					<path className="a"
																						d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
																						transform="translate(-19.188 -1.747)"></path>
																					<path className="b"
																						d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
																						transform="translate(-37.815 -60.681)"></path>
																				</svg>
																				{item?.totalLikeCount ? item?.totalLikeCount : 0}
																			</span>
																		</div>
																	</div>
																</div>
															</li>
														)
													}
													else if (item.isphotosorvideosLik === 2) {
														return (
															<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
																<div className="img-box">
																	<video
																	poster={getThumbnail(item?.videolink, item, "ProfileABT")}
																	controlsList={isToken == 1 ? "" : "nodownload"}
																	>
																		<source
																			src={item?.videolink}
																			alt="video/youtube"
																		/>
																	</video>
																	<div className="video-play-button" onClick={() =>
																		openVideoOnNewTab(item?.videolink, true, item)
																	}>
																		<div className="video-play-inner">
																			<button style={{background: "#c0321f"}}>
																				<svg xmlns="http://www.w3.org/2000/svg" width="10.802"
																					fill={'#fff'}
																					height="16.203" viewBox="0 0 10.802 16.203">
																					<path className="a" d="M8.1,0l8.1,10.8H0Z"
																						transform="translate(10.802) rotate(90)"></path>
																				</svg>
																			</button>
																		</div>
																	</div>
																</div>
																<div className="video-detail-box">
																	<div className="title-box">
																		<div className="video-title half">
																			{item?.originalname || ""}
																		</div>
																	</div>
																	<div className="video-info">
																		<div className="half">
																			<span>{getUTCTimeDateDash(item.createdOn)}</span>
																			<span>{moment(item.createdOn).format('LT')}</span>
																			<span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
																			<span>
																				<svg xmlns="http://www.w3.org/2000/svg" width="12"
																					height="16.679" viewBox="0 0 12 16.679">
																					<path className="a"
																						d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
																						transform="translate(-19.188 -1.747)"></path>
																					<path className="b"
																						d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
																						transform="translate(-37.815 -60.681)"></path>
																				</svg>
																				{item?.totalLikeCount ? item?.totalLikeCount : 0}
																			</span>
																		</div>
																	</div>
																</div>
															</li>
														)
													}
													else if (item.isphotosorvideosLik === 0) {
														return (
															<li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
																<div className="img-box">
																	<img
																		src={`${imageDownloadUrl + "/" + item?.photosname
																			}`}
																		alt="profile Bg"
																		style={{ width: "100%", height: "100%" }}
																	/>
																	{/*<source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>*/}
																	{/*<source src="https://www.youtube.com/embed/aqz-KE-bpKQ"*/}
																	{/*        type="video/ogg"/>*/}
																	<div className="video-play-button">
																		<div className="video-play-inner">
																			{/*<button>*/}
																			{/*    <svg xmlns="http://www.w3.org/2000/svg" width="10.802"*/}
																			{/*         height="16.203" viewBox="0 0 10.802 16.203">*/}
																			{/*        <path className="a" d="M8.1,0l8.1,10.8H0Z"*/}
																			{/*              transform="translate(10.802) rotate(90)"></path>*/}
																			{/*    </svg>*/}
																			{/*</button>*/}
																		</div>
																	</div>
																</div>
																<div className="video-detail-box">
																	<div className="title-box">
																		<div className="video-title half">
																			{item?.originalname || ""}
																		</div>
																		{/*<div className="share-video-button half">*/}
																		{/*<svg xmlns="http://www.w3.org/2000/svg" width="10.689" height="11.803"*/}
																		{/*     viewBox="0 0 10.689 11.803">*/}
																		{/*    <path*/}
																		{/*        d="M13.4,11.457a1.623,1.623,0,0,0-1.161.445L7.984,9.44a1.994,1.994,0,0,0,.06-.415,1.993,1.993,0,0,0-.06-.415l4.2-2.432a1.782,1.782,0,1,0-.566-1.3,1.982,1.982,0,0,0,.06.415L7.478,7.72a1.792,1.792,0,0,0-1.221-.474A1.763,1.763,0,0,0,4.5,9.025a1.793,1.793,0,0,0,3.007,1.3l4.228,2.461a1.488,1.488,0,0,0-.06.385,1.727,1.727,0,1,0,1.727-1.72Z"*/}
																		{/*        transform="translate(-4.5 -3.094)"></path>*/}
																		{/*</svg>*/}
																		{/*</div>*/}
																	</div>
																	<div className="video-info">
																		<div className="half">
																			<span>{getUTCTimeDateDash(item.createdOn)}</span>
																			<span>{moment(item.createdOn).format('LT')}</span>
																			<span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
																			<span>
																				<svg xmlns="http://www.w3.org/2000/svg" width="12"
																					height="16.679" viewBox="0 0 12 16.679">
																					<path className="a"
																						d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
																						transform="translate(-19.188 -1.747)"></path>
																					<path className="b"
																						d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
																						transform="translate(-37.815 -60.681)"></path>
																				</svg>
																				{item?.totalLikeCount ? item?.totalLikeCount : 0}
																			</span>
																		</div>
																	</div>
																</div>
															</li>
														)
													}
												}) : "No Videos or Photos"}
										</ul>

									}
									{props.isPublicView ?
										state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((_itm, idx) => {
											//   if(itm?.publicprofilefieldid == 26 && itm?.active) return <div className="buton-box text-center">
											//       <Link className="btn outlined blue rounded medium" to={'/videoPhotos'}>Go To Videos and Photos</Link>
											//   </div>
										}) || ""
										:
										<div className="buton-box text-center">
											<Link className="btn outlined blue rounded medium" to={'/videoPhotos'}>Go To Videos and Photos</Link>
										</div>
									}
								</div>
							</div>
							{showContact() == "visitor" ? 
							<div id="Contact" className="common-item-box">
								<h3 className="title">Contact</h3>
								<div className="content-box">Private athlete information</div>
							</div>    
							: showContact() == true ?
							<div id="Contact" className="common-item-box">
								<h3 className="title">Contact</h3>
								<div className="content-box">
									<div className="box-item">
										<h4>ATHLETE 
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/contact'} />}
										</h4>
										<ul className="data-info-item">
											<li>
												<span>Mobile Phone</span>
												<span>{profileAbout && profileAbout.athleteBasicInfo[0]?.athletePhoneNumber || ""}</span>
											</li>
											<li>
												<span>Phone Type</span>
												<span>{profileAbout && getCamelCase(profileAbout.athleteBasicInfo[0]?.relationShipName || "")}</span>
											</li>
											<li>
												<span>Primary Email</span>
												<span>{profileAbout && profileAbout.athleteBasicInfo[0]?.athleteEmailId || ""}</span>
											</li>
											<li>
												<span>Twitter</span>
												<span
													className="name">{profileAbout && profileAbout.athleteBasicInfo[0]?.twitterAccountName ?
													`@${profileAbout.athleteBasicInfo[0]?.twitterAccountName}` : ""}</span>
											</li>
											<li>
												<span>Instagram</span>
												<span
													className="name">{profileAbout && profileAbout.athleteBasicInfo[0]?.instagramAccountName ?
													`@${profileAbout.athleteBasicInfo[0]?.instagramAccountName}` : ""
												}</span>
											</li>
											<li>
												<span>Home Address</span>
												{props.isPublicView ?
													state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
														if (itm?.publicprofilefieldid == 24 && itm?.active) return <span>
															{/*comment as per said*/}
															{/*<svg className="pin-icon" xmlns="http://www.w3.org/2000/svg"*/}
															{/*     width="15.152" height="21.219"*/}
															{/*     viewBox="0 0 15.152 21.219">*/}
															{/*                  <g id="location" transform="translate(-6.75 -2.248)">*/}
															{/*                  <path id="Path_431" data-name="Path 431"*/}
															{/*                        d="M18.781,12.765a1.515,1.515,0,1,1-1.515-1.515A1.515,1.515,0,0,1,18.781,12.765Z"*/}
															{/*                        transform="translate(-2.938 -2.938)"*/}
															{/*                        fill="#ff0000"/>*/}
															{/*                  <path id="Path_432" data-name="Path 432"*/}
															{/*                        d="M14.326,2.25A7.426,7.426,0,0,0,6.75,9.5c0,1.9.867,4.432,2.577,7.519a55.569,55.569,0,0,0,3.788,5.839,1.5,1.5,0,0,0,2.424,0,55.789,55.789,0,0,0,3.788-5.835C21.036,13.932,21.9,11.4,21.9,9.5A7.426,7.426,0,0,0,14.326,2.25Zm0,10.607a3.031,3.031,0,1,1,3.031-3.031,3.031,3.031,0,0,1-3.031,3.031Z"*/}
															{/*                        transform="translate(0)" fill="#ff0000"/>*/}
															{/*                  </g>*/}
															{/*              </svg>*/}
															{`${profileAbout?.athleteBasicInfo?.[0]?.address ? profileAbout?.athleteBasicInfo?.[0]?.address + ", " : ""}
															${profileAbout?.athleteBasicInfo?.[0]?.addresscountinue ? profileAbout?.athleteBasicInfo?.[0]?.addresscountinue + ", " : ""}
														${profileAbout?.athleteBasicInfo?.[0]?.city ? profileAbout?.athleteBasicInfo?.[0]?.city + ", " : ""}
														${profileAbout?.athleteBasicInfo?.[0]?.stateName ? profileAbout?.athleteBasicInfo?.[0]?.stateName + ", " : ""}
														${profileAbout?.athleteBasicInfo?.[0]?.countryName ? profileAbout?.athleteBasicInfo?.[0]?.countryName + ", " : ""}${profileAbout?.athleteBasicInfo?.[0]?.athletezipcode ? profileAbout?.athleteBasicInfo?.[0]?.athletezipcode : ""}`}
														</span>
														else if (itm?.publicprofilefieldid == 24 && itm?.active == 0) {
															return <span><p style={{fontWeight: "bold"}}>Permission Not Granted</p></span>
														}
													}) || ""
													:
													<span className="address-link">
														{profileAbout && (profileAbout?.athleteBasicInfo?.[0]?.address || profileAbout?.athleteBasicInfo?.[0]?.city || profileAbout?.athleteBasicInfo?.[0]?.stateName || profileAbout?.athleteBasicInfo?.[0]?.countryName || profileAbout?.athleteBasicInfo?.[0]?.athletezipcode) &&
														<svg className="pin-icon" xmlns="http://www.w3.org/2000/svg"
															width="15.152" height="21.219"
															viewBox="0 0 15.152 21.219">
															<g id="location" transform="translate(-6.75 -2.248)">
																<path id="Path_431" data-name="Path 431"
																	d="M18.781,12.765a1.515,1.515,0,1,1-1.515-1.515A1.515,1.515,0,0,1,18.781,12.765Z"
																	transform="translate(-2.938 -2.938)"
																	fill="#ff0000"/>
																<path id="Path_432" data-name="Path 432"
																	d="M14.326,2.25A7.426,7.426,0,0,0,6.75,9.5c0,1.9.867,4.432,2.577,7.519a55.569,55.569,0,0,0,3.788,5.839,1.5,1.5,0,0,0,2.424,0,55.789,55.789,0,0,0,3.788-5.835C21.036,13.932,21.9,11.4,21.9,9.5A7.426,7.426,0,0,0,14.326,2.25Zm0,10.607a3.031,3.031,0,1,1,3.031-3.031,3.031,3.031,0,0,1-3.031,3.031Z"
																	transform="translate(0)" fill="#ff0000"/>
															</g>
														</svg>
														}
														{`${profileAbout?.athleteBasicInfo?.[0]?.address ? profileAbout?.athleteBasicInfo?.[0]?.address + ", " : ""}
														${profileAbout?.athleteBasicInfo?.[0]?.addresscountinue ? profileAbout?.athleteBasicInfo?.[0]?.addresscountinue + ", " : ""}
														${profileAbout?.athleteBasicInfo?.[0]?.city ? profileAbout?.athleteBasicInfo?.[0]?.city + ", " : ""}
														${profileAbout?.athleteBasicInfo?.[0]?.stateName ? profileAbout?.athleteBasicInfo?.[0]?.stateName + ", " : ""}
														${profileAbout?.athleteBasicInfo?.[0]?.countryName ? profileAbout?.athleteBasicInfo?.[0]?.countryName + ", " : ""}${profileAbout?.athleteBasicInfo?.[0]?.athletezipcode ? profileAbout?.athleteBasicInfo?.[0]?.athletezipcode : ""}`}
													</span>
												}
											</li>
										</ul>
									</div>
									{
										props.isPublicView ? (
											state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
												if (itm?.publicprofilefieldid == 23 && itm?.active) {
													return (
														profileAbout && profileAbout.parentData.length > 0 ? (
															profileAbout.parentData.map((el, idx) => {
																return (
																	<div className="box-item">
																		<h4>PARENT / GUARDIAN {idx + 1}</h4>
																		<ul className="data-info-item">
																			<li>
																				<span>Relationship With Athlete</span>
																				<span>{getCamelCase(el?.relationBetweenParentAndAthlete ?? "")}</span>
																			</li>
																			<li>
																				<span>First Name</span>
																				<span>{getCamelCase(el?.parentFirstName ?? "")}</span>
																			</li>
																			<li>
																				<span>Last Name</span>
																				<span>{getCamelCase(el?.parentLastName ?? "")}</span>
																			</li>
																			<li>
																				<span>Mobile Phone</span>
																				<span>{el?.parentPhoneNumber ?? ""}</span>
																			</li>
																			<li>
																				<span>Primary Email</span>
																				<span>{el?.parentEmail ?? ""}</span>
																			</li>
																		</ul>
																	</div>
																)
															})
														) : "No Record Found"
													)
												} else if (itm?.publicprofilefieldid == 23 && itm?.active == 0) {
													<span>
														<h4>PARENT / GUARDIAN</h4>
														<p style={{fontWeight: "bold"}}>Permission Not Granted</p>
													</span>
												}
											}) || ""
										) : (
											profileAbout && profileAbout.parentData.length > 0 ? (
												profileAbout.parentData.map((el, idx) => {
													return (
														<div className="box-item">
															<h4>PARENT / GUARDIAN {idx + 1}
																<EditProfileButton history={props.history} route={'/contact'} />
															</h4>
															<ul className="data-info-item">
																<li>
																	<span>Relationship With Athlete</span>
																	<span>{getCamelCase(el?.relationBetweenParentAndAthlete ?? "")}</span>
																</li>
																<li>
																	<span>First Name</span>
																	<span>{getCamelCase(el?.parentFirstName ?? "")}</span>
																</li>
																<li>
																	<span>Last Name</span>
																	<span>{getCamelCase(el?.parentLastName ?? "")}</span>
																</li>
																<li>
																	<span>Mobile Phone</span>
																	<span>{getCamelCase(el?.parentPhoneNumber ?? "")}</span>
																</li>
																<li>
																	<span>Primary Email</span>
																	<span>{el?.parentEmail ?? ""}</span>
																</li>
															</ul>
														</div>
													)
												})
											) : "No Record Found"
										)
									}
									{/*<div className="box-item">*/}
									{/*    <h4>PARENT/GUARDIAN 2 <a className="edit-link" href="#">Edit</a></h4>*/}
									{/*    <ul className="data-info-item">*/}
									{/*        <li>*/}
									{/*            <span>Relationship to Student-Athlete</span>*/}
									{/*            <span>Father</span>*/}
									{/*        </li>*/}
									{/*        <li>*/}
									{/*            <span>First Name</span>*/}
									{/*            <span>Donald</span>*/}
									{/*        </li>*/}
									{/*        <li>*/}
									{/*            <span>Last Name</span>*/}
									{/*            <span>Rice</span>*/}
									{/*        </li>*/}
									{/*        <li>*/}
									{/*            <span>Mobile Phone</span>*/}
									{/*            <span>--</span>*/}
									{/*        </li>*/}
									{/*        <li>*/}
									{/*            <span>Primary Email</span>*/}
									{/*            <span>donaldrice23@gmail.com</span>*/}
									{/*        </li>*/}
									{/*    </ul>*/}
									{/*</div>*/}
								</div>
							</div> : null
							}
							<div id="Vitals" className="common-item-box">
								<h3 className="title">Vitals</h3>
								<div className="content-box">
									<div className="box-item">
										<h4>PHYSICAL MEASUREMENTS
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/vitals'} />}
										</h4>
										<ul className="data-info-item">
											<li>
												<span>Height</span>
												{props.isPublicView ?
													state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
														if (itm?.publicprofilefieldid == 25 && itm?.active) return <span>{profileAbout?.vitalData?.[0]?.heightValue ? profileAbout?.vitalData?.[0]?.heightValue + " ft " : ""}{profileAbout?.vitalData?.[0]?.inchValue || profileAbout?.vitalData?.[0]?.inchValue === 0 ? profileAbout?.vitalData?.[0]?.inchValue + " inch" : ""}</span>
														else if (itm?.publicprofilefieldid == 25 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
													}) || ""
													:
													<span>{profileAbout?.vitalData?.[0]?.heightValue ? profileAbout?.vitalData?.[0]?.heightValue + " ft " : ""}{profileAbout?.vitalData?.[0]?.inchValue || profileAbout?.vitalData?.[0]?.inchValue === 0 ? profileAbout?.vitalData?.[0]?.inchValue + " inch" : ""}</span>
												}
											</li>
											<li>
												<span>Weight</span>
												{props.isPublicView ?
													state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
														if (itm?.publicprofilefieldid == 25 && itm?.active) return <span>{profileAbout?.vitalData?.[0]?.weight ? profileAbout?.vitalData?.[0]?.weight + " lbs" : ""}</span>
														else if (itm?.publicprofilefieldid == 25 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
													}) || ""
													:
													<span>{profileAbout?.vitalData?.[0]?.weight ? profileAbout?.vitalData?.[0]?.weight + " lbs" : ""}</span>
												}
											</li>
										</ul>
									</div>
									<div className="box-item">
										<h4>BAT / THROW
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/vitals'} />}
										</h4>
										<ul className="data-info-item">
											<li>
												<span>Bats</span>
												<span>{profileAbout && profileAbout?.vitalData[0]?.battingPreferenceValue || ""}</span>
											</li>
											<li>
												<span>Throws</span>
												<span>{profileAbout && profileAbout?.vitalData[0]?.throwingPreferenceValue || ""}</span>
											</li>
										</ul>
									</div>
									<div className="box-item">
										<h4>POSITIONS
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/vitals'} />}
										</h4>
										<ul className="data-info-item">
											<li>
												<span>Primary Position</span>
												<span>{profileAbout && profileAbout?.vitalData[0]?.primaryPositionshortform || ""}</span>
											</li>
											<li>
												<span>Secondary Position</span>
												<span>{profileAbout && profileAbout?.vitalData[0]?.secondaryPositionshortform || ""}</span>
											</li>
											<li>
												<span>Other Position</span>
												<span>{profileAbout && profileAbout?.vitalData[0]?.otherPositionshortform || ""}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div id="Teams" className="common-item-box">
								<h3 className="title">Teams</h3>
								<div className="content-box">
									<div className="box-item">
										<h4>CLUB TEAMS
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/teams'} />}
										</h4>
										<ul 
											className="data-info-item team-list"
											style={profileAbout?.teamData?.length == 0 ? {borderBottom:"none"} : {}}
										>
											{profileAbout?.teamData?.length > 0 ?
												profileAbout.teamData.map((ed, id) => {
													return (
														<li>
															<div className="img-box">
																<img
																	src={`${ed?.teamlogo ? imageDownloadUrl + "/" + ed?.teamlogo : teamDefault
																		}`}
																	alt="teamlogo"
																/>
															</div>
															<div className="detail-box">
																<h5>{ed?.teamname || ed?.highschoolname ? ed?.teamname || ed?.highschoolname : ""}</h5>
																<p>{ed?.schoolOrTeamType || ""}
																	{/*<br/>Varsity Team<br/>*/}
																	{ed?.city || ""}{(!!ed?.state && ed?.city)  ?", "+ed?.state : ed?.state ?? ""}</p>
															</div>
														</li>
													)
												})
												:
												""}
												{/*"No school team found" is has been removed as per bugs on regretion test 29/9/2022*/}
										</ul>
										<ul className="data-info-item team-list" style={profileAbout?.clubTeam?.length == 0 ?
											{borderBottom:"none"} : {}}
										>
											{profileAbout?.clubTeam?.length > 0 ?
												profileAbout.clubTeam.map((club, index) => {
													return (
														<li>
															<div className="img-box">
																<img
																	src={`${club?.teamlogo ? imageDownloadUrl + "/" + club?.teamlogo : teamDefault
																		}`}
																	alt="team logo" />
															</div>
															<div className="detail-box">
																<h5>{getCamelCase(club?.verifiedbycoachname || "")}</h5>
																<p>{club?.highschooltype || ""}</p>
																<p><span style={{ display: "block", color: "#000" }}><strong style={{ color: "#000", fontWeight: "700" }}>Club / Travel </strong>{club?.teamname ? `: ${club?.teamname}` : "" || ""}</span>
																	{club?.city || ""}{(!!club?.state && !!club?.city) ?`, ${club?.state}` : club?.state || ""}</p>
															</div>
														</li>
													)
												})
												:
												""
											}
											{/*"No club team found" is has been removed as per bugs on regretion test 29/9/2022*/}
										</ul>

									</div>
									{/*<div className="box-item">*/}
									{/*<h4>COACH INFORMATION <a className="edit-link" style={{cursor:"pointer"}}*/}
									{/*onClick={()=>{*/}
									{/*    window.scrollTo(0,0)*/}
									{/*    setTimeout(()=>{*/}
									{/*        props.history.push('/teams')*/}
									{/*    },1000)*/}
									{/*}}*/}
									{/*>{props.isPublicView ? "" : "Edit" }</a></h4>*/}
									{/*<ul className="data-info-item team-list circle">*/}
									{/*{profileAbout && profileAbout.coachData.length > 0 ?*/}
									{/*    profileAbout.coachData.map((coa, idx)=>{*/}
									{/*        return(*/}
									{/*            <li>*/}
									{/*                <div className="img-box">*/}
									{/*                    <img src={teamDefault} alt="no Image"/>*/}
									{/*                </div>*/}
									{/*                <div className="detail-box">*/}
									{/*                    <h5>{coa?.coachFirstName || ""} {coa?.coachLastName || ""}</h5>*/}
									{/*                    <p>Email: <span className="name">{coa?.coachEmail || ""}</span></p>*/}
									{/*                </div>*/}
									{/*            </li>*/}
									{/*        )*/}
									{/*    })*/}
									{/*    :*/}
									{/*    "No Record Found"*/}
									{/*}*/}
									{/*<li>*/}
									{/*    <div className="img-box">*/}
									{/*        <img src="img/john-drew.png" alt=""/>*/}
									{/*    </div>*/}
									{/*    <div className="detail-box">*/}
									{/*        <h5>Elan Walker, Assistance Coach</h5>*/}
									{/*        <p>Twitter: <span className="name">@elanwalker</span></p>*/}
									{/*    </div>*/}
									{/*</li>*/}
									{/*</ul>*/}
									{/*</div>*/}
									{/*<div className="box-item">*/}
									{/*<h4>CLUB TEAMS <a className="edit-link" style={{cursor:"pointer"}} onClick={()=>{*/}
									{/*    window.scrollTo(0,0)*/}
									{/*    setTimeout(()=>{*/}
									{/*          props.history.push('/teams')*/}
									{/*    },1000)*/}
									{/*}}>Edit</a></h4>*/}
									{/*<ul className="data-info-item team-list">*/}
									{/*{profileAbout &&  profileAbout.clubTeam.length > 0 ?*/}
									{/*    profileAbout.clubTeam.map((club, index)=>{*/}
									{/*        return(*/}
									{/*            <li>*/}
									{/*                <div className="img-box">*/}
									{/*                    <img*/}
									{/*                        src={`${*/}
									{/*                            club?.teamlogo ?   imageDownloadUrl + "/" + club?.teamlogo : teamDefault*/}
									{/*                        }`}*/}
									{/*                        alt="team logo"/>*/}
									{/*                </div>*/}
									{/*                <div className="detail-box">*/}
									{/*                    <h5>{club?.verifiedbycoachname || "N/A"}</h5>*/}
									{/*                    <p>Club/Travel - {club?.teamname || "N/A"}<br/>{club?.city || "N/A"}, {club?.state || "N/A"} - {club?.active === 1 ? "Active" : "inActive"}</p>*/}
									{/*                </div>*/}
									{/*            </li>*/}
									{/*        )*/}
									{/*    })*/}
									{/*    :*/}
									{/*    "No Record Found"*/}
									{/*}*/}
									{/*<li>*/}
									{/*    <div className="img-box">*/}
									{/*        <img src="img/arizona-christian-university-logo.png" alt=""/>*/}
									{/*    </div>*/}
									{/*    <div className="detail-box">*/}
									{/*        <h5>Slippery Rock University</h5>*/}
									{/*        <p>Club/Travel - Baseball<br/>Chino Hills, CA - Alumini</p>*/}
									{/*    </div>*/}
									{/*</li>*/}
									{/*</ul>*/}
									{/*</div>*/}
								</div>
							</div>
							<div id="Events" className="common-item-box">
								<h3 className="title">Events</h3>
								{props.isPublicView ?
									state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
										if (itm?.publicprofilefieldid === 32 && itm?.active) return <div className="content-box">
											<div className="box-item">
												<ul className="data-info-item team-list event">
													{profileAbout && profileAbout.EventData.length > 0 ?
														profileAbout.EventData.map((item, index) => {
															return (
																<li>
																	<div className="img-box">
																		{/* {item?.countryFlagImage ?
																		<img src={`${imageDownloadUrl}/${item?.countryFlagImage || ""}`} alt=""/>
																		:
																		<img
																			src={`${
																				item?.teamlogo ?   imageDownloadUrl + "/" + item?.teamlogo : teamDefault
																			}`}
																			alt="teamlogo"
																		/>
																	} */}
																		{/* default image is shown in events */}
																		{<img src={`${item?.eventlogoname ? imageDownloadUrl + "/" + item?.eventlogoname : eventLogoImg01}`}
																			alt="teamlogo"
																		/>
																		}
																	</div>
																	<div className="detail-box">
																		<h5>{item.eventname}
																			<span className="icon">
																				<svg xmlns="http://www.w3.org/2000/svg" width="23"
																					height="23" viewBox="0 0 23 23">
																					<g transform="translate(-471 -2209)"><rect
																						className="a" width="23" height="23"
																						rx="11.5" transform="translate(471 2209)" />
																						<path className="b"
																							d="M17.221,9,9.507,17.416,6,13.59"
																							transform="translate(471.125 2207.292)" />
																					</g>
																				</svg>
																			</span>
																		</h5>
																		<ul>
																			<li>
																				<span style={{ color: "#000", marginBottom: "0" }}>{getUTCTimeDateOverall(item.startdate)}<em>{getUTCTimeDateOverall(item.enddate)}</em></span>
																				<span style={{ color: "#000" }}>{item?.location || ""}</span>
																			</li>
																			<li><span style={{ color: "#000" }}>{item?.divisionorlevel || ""}</span><span style={{ color: "#000" }}>{(item?.city ? item?.city : "").concat(item?.city && item?.stateName ? ", " : "").concat(item?.stateName ? item?.stateName : "").concat(item?.stateName && item?.countryShortName ? ", " : "").concat(item?.countryShortName ? item?.countryShortName : "")}</span></li>

																		</ul>
																	</div>
																</li>
															)
														})
														:
														"No Record Found"}
												</ul>
											</div>
										</div>
										else if (itm?.publicprofilefieldid === 32 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
									}) || ""
									:
									<div className="content-box">
										<div className="edit-link-box">
											<EditProfileButton history={props.history} route={'/events'} />
										</div>
										<div className="box-item">
											<ul className="data-info-item team-list event">
												{profileAbout && profileAbout.EventData.length > 0 ?
													profileAbout.EventData.map((item, index) => {
														return (
															<li>
																<div className="img-box">
																	{/* {item?.countryFlagImage ?
																	<img src={`${imageDownloadUrl}/${item?.countryFlagImage || ""}`} alt=""/>
																	:
																	<img
																		src={`${
																			item?.teamlogo ?   imageDownloadUrl + "/" + item?.teamlogo : teamDefault
																		}`}
																		alt="teamlogo"
																	/>
																} */}
																	{/* default image is shown in events */}
																	{<img src={`${item?.eventlogoname ? imageDownloadUrl + "/" + item?.eventlogoname : eventLogoImg01}`}
																		alt="teamlogo"
																	/>
																	}
																</div>
																<div className="detail-box">
																	<h5>{item.eventname || ""}
																		<span className="icon">
																			<svg xmlns="http://www.w3.org/2000/svg" width="23"
																				height="23" viewBox="0 0 23 23">
																				<g transform="translate(-471 -2209)"><rect
																					className="a" width="23" height="23"
																					rx="11.5" transform="translate(471 2209)" />
																					<path className="b"
																						d="M17.221,9,9.507,17.416,6,13.59"
																						transform="translate(471.125 2207.292)" />
																				</g>
																			</svg>
																		</span>
																	</h5>
																	<ul>
																		<li><span style={{ color: "#000", marginBottom: "0" }}>{getUTCTimeDateOverall(item.startdate)}<em>{getUTCTimeDateOverall(item.enddate)}</em></span> <span style={{ color: "#000" }}>{item?.location || ""}</span>

																		</li>
																		<li><span style={{ color: "#000" }}>{item?.divisionorlevel || ""}</span> <span style={{ color: "#000" }}>{(item?.city ? item?.city : "").concat(item?.city && item?.stateName ? ", " : "").concat(item?.stateName ? item?.stateName : "").concat(item?.stateName && item?.countryShortName ? ", " : "").concat(item?.countryShortName ? item?.countryShortName : "")}</span></li>
																	</ul>
																</div>
															</li>
														)
													})
													:
													"No Record Found"}
											</ul>
										</div>
									</div>
								}
							</div>
							<div id="References" className="common-item-box">
								<h3 className="title">References</h3>
								{props.isPublicView ?
									state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
										if (itm?.publicprofilefieldid == 33 && itm?.active) return <div className="content-box">
											<div className="box-item">
												<ul className="data-info-item team-list circle">
													{profileAbout && profileAbout.ReferenceData.length > 0 ?
														profileAbout.ReferenceData.map((ref, index) => {
															return (
																<li>
																	<div className="img-box">
																		<img
																			src={`${ref?.teamlogo ? imageDownloadUrl + "/" + ref?.teamlogo : teamDefault
																				}`}
																			alt="teamlogo"
																		/>
																	</div>
																	<div className="detail-box">
																		<h5><strong>Coach Name:</strong> <span className="referenceName">{ref?.reffirstname ? ref?.reffirstname.concat(ref?.reflastName ? " " + ref?.reflastName : "") : ""}</span></h5>
																		<p className="reference-item">
																			<strong>Coach Type:</strong>
																			<span className="referenceName">{ref?.coachTypeName || ""}</span>
																		</p>
																		<p className="reference-item">
																			<strong>Mobile Phone: </strong>
																			<span className="referenceName">{ref?.refphone || ""}</span>
																		</p>
																	</div>
																</li>
															)
														})
														:
														"No Record Found"
													}
													{/*<li>*/}
													{/*    <div className="img-box">*/}
													{/*        <img src="img/john-drew.png" alt=""/>*/}
													{/*    </div>*/}
													{/*    <div className="detail-box">*/}
													{/*        <h5>Football</h5>*/}
													{/*        <p><strong>Years Experience :</strong> 1 <br/><strong>Level*/}
													{/*            :</strong> Freshman<br/>I'm not sure I want to play sports in college*/}
													{/*        </p>*/}
													{/*    </div>*/}
													{/*</li>*/}
												</ul>
											</div>
										</div>
										else if (itm?.publicprofilefieldid == 33 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
									}) || ""
									:
									<div className="content-box">
										<div className="edit-link-box">
											<EditProfileButton history={props.history} route={'/References'} />
										</div>
										<div className="box-item">
											<ul className="data-info-item team-list circle">
												{profileAbout && profileAbout.ReferenceData.length > 0 ?
													profileAbout.ReferenceData.map((ref, index) => {
														return (
															<li>
																<div className="img-box">
																	{/*<img src="img/john-drew.png" alt=""/>*/}
																	<img
																		src={`${ref?.teamlogo ? imageDownloadUrl + "/" + ref?.teamlogo : teamDefault
																			}`}
																		alt="teamlogo"
																	/>
																</div>
																<div className="detail-box">
																	<h5><strong>Coach Name:</strong> <span className="referenceName">{ref?.reffirstname ? ref?.reffirstname.concat(ref?.reflastName ? " " + ref?.reflastName : "") : ""}</span></h5>
																	<p className="reference-item"><strong>Coach Type
																		:</strong> <span className="referenceName">{ref?.coachTypeName || ""}</span>
																	</p>
																	<p className="reference-item"><strong>Mobile Phone
																		: </strong><span className="referenceName">{ref?.refphone || ""}</span></p>
																</div>
															</li>
														)
													})
													:
													"No Record Found"
												}
												{/*<li>*/}
												{/*    <div className="img-box">*/}
												{/*        <img src="img/john-drew.png" alt=""/>*/}
												{/*    </div>*/}
												{/*    <div className="detail-box">*/}
												{/*        <h5>Football</h5>*/}
												{/*        <p><strong>Years Experience :</strong> 1 <br/><strong>Level*/}
												{/*            :</strong> Freshman<br/>I'm not sure I want to play sports in college*/}
												{/*        </p>*/}
												{/*    </div>*/}
												{/*</li>*/}
											</ul>
										</div>
									</div>
								}
							</div>
							<div id="AdditionalSports" className="common-item-box">
								<h3 className="title">Additional Sports</h3>
								<div className="content-box">
									{
										props.isPublicView ? "" : (
											<div className="edit-link-box">
												<EditProfileButton history={props.history} route={'/addtionalSports'} />
											</div>
										)
									}
									<div className="box-item">
										<ul className="data-info-item team-list circle">
											{profileAbout && profileAbout.additionalSportData.length > 0 ?
												profileAbout.additionalSportData.map((add, inx) => {
													return (
														<li>
															<div className="img-box">
																{/*<img src="img/baseball-logo.png" alt=""/>*/}
																<img
																	src={`${add?.teamlogo ? imageDownloadUrl + "/" + add?.teamlogo : teamDefault
																		}`}
																	alt="teamlogo"
																/>
															</div>
															<div className="detail-box">
																<h5>{add?.additionalsportsname || ""}</h5>
																<p><strong>Years Experience :</strong> {add?.startyear || ""}{add?.endyear ? `-${add?.endyear}` : ""}<br /><strong>Level : </strong>
																	{add?.levelName || ""}<br /></p>
															</div>
														</li>
													)
												})
												: "No Record Found"
											}
											{/*<li>*/}
											{/*    <div className="img-box">*/}
											{/*        <img src="img/football-logo.png" alt=""/>*/}
											{/*    </div>*/}
											{/*    <div className="detail-box">*/}
											{/*        <h5>Football <a className="edit-link" href="#">Edit</a></h5>*/}
											{/*        <p><strong>Years Experience :</strong> 1<br/><strong>Level*/}
											{/*            :</strong> Freshman<br/>I'm not sure I want to play sports in college*/}
											{/*        </p>*/}
											{/*    </div>*/}
											{/*</li>*/}
										</ul>
									</div>
								</div>
							</div>
							<div id="Press" className="common-item-box">
								<h3 className="title">Press</h3>
								<div className="content-box">
									<div className="edit-link-box">
										{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/press'} />}
									</div>
									<div className="box-item">
										<ul className="data-info-item team-list">
											{profileAbout && profileAbout.pressData.length > 0 ?
												profileAbout.pressData.map((press) => {
												const imgLink = `${imageDownloadUrl}/${press?.fileName}`
													return (
														<li>
															<div className="detail-box">
																{press?.title ? <h5>{press?.title}</h5> : null}
																<p>
																{press?.link ?
																<><strong>Press weblink: </strong> <br />
																<a href={press?.link || "www.google.com"} target="_blank">{press?.link || ""}</a></>
																: null
																}
																{press?.fileName ?
																<a onClick={()=>window.open(imgLink, '_blank')}>
																	<img src={imgLink} style={{ width: '100%' }} />
																</a>
																: null
																}
																</p>
															</div>
														</li>
													)
												})
												:
												"No Record Found"}
										</ul>
									</div>
								</div>
							</div>
							<div id="Academics" className="common-item-box">
								<h3 className="title">Academics</h3>
								<div className="content-box">
									<div className="box-item">
										<h4>HIGH SCHOOL INFORMATION
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/academics'} />}
										</h4>
										{profileAbout && profileAbout.academicData[0]?.schoolData.length > 0 ?
											profileAbout.academicData[0]?.schoolData.map((aced, indx) => {
												return (indx === profileAbout?.academicData[0]?.schoolData?.length - 1 ?
													<ul className="data-info-item">
														<li>
															<span>Zip Code</span>
															<span>{aced?.zipcode && aced?.zipcode !== "null" ? aced?.zipcode : ""}</span>
														</li>
														<li>
															<span>High School Name</span>
															<span>{getCamelCase(aced?.highschoolname) || ""}</span>
														</li>
														<li>
															<span>High School Type</span>
															<span>{aced?.highschooltype === 1 ? "Public" : aced?.highschooltype === 2 ? "Private" : aced?.highschooltype === 3 ? "Home school" : ""}</span>
														</li>
														<li>
															<span>Division</span>
															<span>{aced?.division || ""}</span>
														</li>
														<li>
															<span>
																{/*Not in high school currently?*/}
																Post High School
															</span>
															<span>{aced?.currentltnotinhighschool === 1 ? "Yes" : aced?.currentltnotinhighschool === 0 ? "No" : ""}</span>
														</li>
														<li>
															<span>Option</span>
															<span>{aced?.currentLevelValue || ""}</span>
														</li>
														<li>
															<span>Start Date</span>
															<span>{aced?.startdatemonth ? getNameFromCount(aced?.startdatemonth).concat(aced?.startdateyear ? ", " + aced?.startdateyear : "") : ""}</span>
														</li>
														<li>
															<span>End Date</span>
															<span>{aced?.enddatemonth ? getNameFromCount(aced?.enddatemonth).concat(aced?.enddateyear ? ", " + aced?.enddateyear : "") : ""}</span>
														</li>
													</ul> : <ul className="data-info-item">
														<li>
															<span>Zip Code</span>
															<span>{aced?.zipcode && aced?.zipcode !== "null" ? aced?.zipcode : ""}</span>
														</li>
														<li>
															<span>High School Name</span>
															<span>{getCamelCase(aced?.highschoolname) || ""}</span>
														</li>
														<li>
															<span>High School Type</span>
															<span>{aced?.highschooltype === 1 ? "Public" : aced?.highschooltype === 2 ? "Private" : aced?.highschooltype === 3 ? "Home school" : ""}</span>
														</li>
														<li>
															<span>Division</span>
															<span>{aced?.division || ""}</span>
														</li>
														<li>
															<span>
																{/*Not in high school currently?*/}
																Post High School
															</span>
															<span>{aced?.currentltnotinhighschool === 1 ? "Yes" : aced?.currentltnotinhighschool === 0 ? "No" : ""}</span>
														</li>
														<li>
															<span>Option</span>
															<span>{aced?.currentLevelValue || ""}</span>
														</li>
														<li>
															<span>Start Date</span>
															<span>{aced?.startdatemonth ? getNameFromCount(aced?.startdatemonth).concat(aced?.startdateyear ? ", " + aced?.startdateyear : "") : ""}</span>
														</li>
														<li>
															<span>End Date</span>
															<span>{aced?.enddatemonth ? getNameFromCount(aced?.enddatemonth).concat(aced?.enddateyear ? ", " + aced?.enddateyear : "") : ""}</span>
														</li>
													</ul>
												)
											})
											:
											"No Record Found"
										}
									</div>
									<div className="box-item">
										<h4>COLLEGE INFORMATION
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/academics'} />}
										</h4>
										{profileAbout && profileAbout.academicData[0]?.collegeData.length > 0 ?
											profileAbout.academicData[0]?.collegeData.map((clg, indx) => {
												return (indx === profileAbout.academicData[0]?.collegeData.length - 1 ?
													<ul className="data-info-item">
														<li>
															<span>College Name</span>
															<span>{getCamelCase(clg?.collegename || "")}</span>
														</li>
														<li>
															<span>Division</span>
															<span>{clg?.collegedivisionname || ""}</span>
														</li>
														<li>
															<span>Current Level</span>
															<span>{clg?.collegelevelname || ""}</span>
														</li>
													</ul> : <ul className="data-info-item">
														<li>
															<span>College Name</span>
															<span>{getCamelCase(clg?.collegename) || ""}</span>
														</li>
														<li>
															<span>Division</span>
															<span>{clg?.collegedivisionname || ""}</span>
														</li>
														<li>
															<span>Current Level</span>
															<span>{clg?.collegelevelname || ""}</span>
														</li>
													</ul>
												)
											})
											:
											"No Record Found"
										}
									</div>
								</div>
							</div>
							<div id="GPAScores" className="common-item-box">
								<h3 className="title">Grades & Scores</h3>
								<div className="content-box">
									<div className="box-item">
										<h4>ACADEMIC STATUS
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/gradesScores'} />}
										</h4>
										<ul className="data-info-item">
											{profileAbout?.gpaScoreData?.gpaDtails?.length > 0 ?
												profileAbout.gpaScoreData.gpaDtails.map((gpa, indx) => {
													return (indx === 0 ?
														<>
															<li>
																<span>GPA range</span>
																{props.isPublicView ?
																	state?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
																		if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{gpa?.gpaRange || ""}</span>
																		else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p
																			style={{ fontWeight: "bold" }}>Permission not Granted</p></span>
																	}) || ""
																	:
																	<span>{gpa?.gpaRange || ""}</span>
																}
															</li>
															<li>
																<span>GPA (Unweighted)</span>
																{props.isPublicView ?
																	state?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
																		if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{gpa?.unweightedgpa || ""}</span>
																		else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p
																			style={{ fontWeight: "bold" }}>Permission not Granted</p></span>
																	}) || ""
																	:
																	<span>{gpa?.unweightedgpa || ""}</span>
																}
															</li>
															<li>
																<span>GPA (Weighted)</span>
																{props.isPublicView ?
																	state?.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
																		if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{gpa?.gpa || ""}</span>
																		else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p
																			style={{ fontWeight: "bold" }}>Permission not Granted</p></span>
																	}) || ""
																	:
																	<span>{gpa?.gpa || ""}</span>
																}
															</li>
															{/*<li>*/}
															{/*    <span>Weighted</span>*/}
															{/*    <span><input type={'checkbox'} checked={!!gpa?.weightGpa ? true : false}/></span>*/}
															{/*</li>*/}
															<li>
																<span>Class Rank</span>
																<span>{gpa?.classrank1 ? gpa?.classrank1 + (gpa?.outof ? ` out of ${gpa?.outof}` : "") : ""}</span>
															</li>
															<li>
																<span>NCAA Eligibility Number</span>
																<span>{profileAbout?.gpaScoreData?.gpanacci?.length > 0 ?
																	profileAbout?.gpaScoreData?.gpanacci?.[0]?.havencaaandnadianumber === 1 ? profileAbout?.gpaScoreData?.gpanacci[0]?.ncaaNumber : ""
																	: ""}</span>
															</li>
															<li>
																<span>NAIA Eligibility Number</span>
																<span>{profileAbout?.gpaScoreData?.gpanacci?.length > 0 ?
																	profileAbout?.gpaScoreData?.gpanacci?.[0]?.havencaaandnadianumber === 1 ? profileAbout?.gpaScoreData?.gpanacci?.[0]?.nadianumber : ""
																	: ""}</span>
															</li>
														</> : ""
													)
												})
												:
												"No Record Found"
											}
										</ul>
									</div>
									{props.isPublicView ? state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
										if (itm?.publicprofilefieldid == 28 && itm?.active) return <div className="box-item">
											<h4>ACADEMIC FILES
												<EditProfileButton history={props.history} route={'/gradesScores'} />
											</h4>
											<ul className="data-info-item fourColumn">
												{profileAbout && profileAbout.gpaScoreData.trenscriptFile.length > 0 ?
													profileAbout.gpaScoreData.trenscriptFile.map((tren) => {
														return (
															<>
																<li>
																	<span className="black-text">{tren?.fileName || ""}</span>
																</li>
																<li>
																	<span className="black-text">{tren?.type || ""}</span>
																</li>
																<li>
																	<span className="black-text">{moment(tren?.createdOn).format("MMM DD, YYYY") || ""}</span>
																</li>
																<li>
																	<span className="link-text" onClick={()=>window.open(`${imageDownloadUrl}/${tren?.fileName}`, '_blank')}
																	>View file</span>
																</li>
																{/*<li>*/}
																	{/*<span className="black-text">{tren?.type || ""}</span>*/}
																	{/*<span>No academic transcripts added</span>*/}
																	{/*</li>*/}
																</>
														)
													})
													:
													"No Record Found"
												}
											</ul>
										</div>
										else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <div className="box-item">
											<h4>ACADEMIC FILES</h4>
											<ul className="data-info-item fourColumn">
												{profileAbout && profileAbout.gpaScoreData.trenscriptFile.length > 0 ?
													profileAbout.gpaScoreData.trenscriptFile.map((tren) => {
														return (
															<>
																<li>
																	<span className="black-text">{tren?.fileName || ""}</span>
																</li>
																<li>
																	<span className="black-text">{tren?.type || ""}</span>
																</li>
																<li>
																	<span className="black-text">{moment(tren?.createdOn).format("MMM DD, YYYY") || ""}</span>
																</li>
																<li>
																	<span className="link-text" onClick={()=>window.open(`${imageDownloadUrl}/${tren?.fileName}`, '_blank')}
																	>View file</span>
																</li>
																{/*<li>*/}
																{/*<span className="black-text">{tren?.type || ""}</span>*/}
																{/*<span>No academic transcripts added</span>*/}
																{/* </li>*/}
															</>
														)
													})
													:
													"No Record Found"
												}
											</ul>
										</div>
										//   <span>
										//       <h4>ACADEMIC FILES</h4>
										//       <p style={{fontWeight:"bold"}}>Permission Not Granted</p>
										//   </span>
									}) || ""
										:
										<div className="box-item">
											<h4>ACADEMIC FILES
												<EditProfileButton history={props.history} route={'/gradesScores'} />
											</h4>
											<ul className="data-info-item fourColumn">
												{profileAbout && profileAbout.gpaScoreData.trenscriptFile.length > 0 ?
													profileAbout.gpaScoreData.trenscriptFile.map((tren) => {
														return (
															<>
															<li>
																<span className="black-text">{tren?.fileName || ""}</span>
															</li>
															<li>
																<span className="black-text">{tren?.type || ""}</span>
															</li>
																<li>
																	<span className="black-text">{moment(tren?.createdOn).format("MMM DD, YYYY") || ""}</span>
																</li>
																<li>
																	<span className="link-text" onClick={()=>window.open(`${imageDownloadUrl}/${tren?.fileName}`, '_blank')}
																	>View file</span>
																</li>
															</>
														)
													})
													:
													"No Record Found"
												}
											</ul>
										</div>
									}
									<div className="box-item">
											<h4>TEST SCORES
												{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/gradesScores'} />}
											</h4>
										<ul className="scores-listing">
											{props.isPublicView ?
												state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
													if (itm?.publicprofilefieldid == 30 && itm?.active) {
														return (profileAbout && profileAbout.gpaScoreData.testScore.length > 0 ?
															profileAbout.gpaScoreData.testScore.map((test, idx) => {
																return (
																	<>
																		<li>
																			<h5>SAT</h5>
																			<div className="circle-box">
																				<CircularProgressbar className="progressChart" value={test?.sattotalnumber ? Math.round((test?.sattotalnumber / 1600) * 100) : 0} text={`${test?.sattotalnumber || ""} / 1600`} />
																			</div>
																			<div className="botom-detail">
																				<div className="item-box">
																					<h6>{test?.mathValue || ""}<span> / 800
																					</span></h6>
																					<p>Math</p>
																				</div>
																				<div className="item-box">
																					<h6>{test?.readingandwritingValue || ""}
																						<span> / 800
																						</span></h6>
																					<p>Reading & Writing</p>
																				</div>
																			</div>
																		</li>
																		<li>
																			<h5>PSAT</h5>
																			<div className="circle-box">
																				<CircularProgressbar className="progressChart" value={test?.psatotalnumer ? Math.round((test?.psatotalnumer / 1520) * 100) : 0} text={`${test?.psatotalnumer || ""} / 1520`} />
																			</div>
																			<div className="botom-detail">
																				<div className="item-box">
																					<h6>{test?.psatmath || ""}
																						<span> / 760
																							{/*{test?.psatotalnumer || ""}*/}
																						</span></h6>
																					<p>Math</p>
																				</div>
																				<div className="item-box">
																					<h6>{test?.psatreadingandwritingvalue || ""}
																						<span> / 760
																							{/*{test?.psatotalnumer || ""}*/}
																						</span></h6>
																					<p>Reading & Writing</p>
																				</div>
																			</div>
																		</li>
																		<li>
																			<h5>ACT</h5>
																			<div className="circle-box" style={{ height: "175px" }}>
																				<div className="item-box">
																					<p>ACT Composite Score</p>
																					<h6>{test?.actcompletescoreid || ""}<span></span></h6>
																				</div>
																				<div className="item-box">
																					<p>Notes</p>
																					<h6>{test?.actNotes || ""}<span></span></h6>
																				</div>
																				<div className="item-box">
																					<p>Test Date</p>
																					<h6>{test?.acttestdate ? getUTCTimeDateOverall(test?.acttestdate) : ""}<span></span></h6>
																				</div>
																			</div>
																			<div className="botom-detail">
																			</div>
																		</li>
																	</>
																)
															})
															:
															"No Record Found"
														)
													}
													else if (itm?.publicprofilefieldid == 30 && itm?.active == 0) {
														return (profileAbout && profileAbout.gpaScoreData.testScore.length > 0 ?
															profileAbout.gpaScoreData.testScore.map((test, idx) => {
																return (
																	<>
																		<li>
																			<h5>SAT</h5>
																			<span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
																		</li>
																		<li>
																			<h5>PSAT</h5>
																			<div className="circle-box">
																				<CircularProgressbar className="progressChart" value={test?.psatotalnumer ? Math.round((test?.psatotalnumer / 1520) * 100) : 0} text={`${test?.psatotalnumer || ""} / 1520`} />
																			</div>
																			<div className="botom-detail">
																				<div className="item-box">
																					<h6>{test?.psatmath || ""}
																						<span> / 760
																							{/*{test?.psatotalnumer || ""}*/}
																						</span></h6>
																					<p>Math</p>
																				</div>
																				<div className="item-box">
																					<h6>{test?.psatreadingandwritingvalue || ""}
																						<span> / 760
																							{/*{test?.psatotalnumer || ""}*/}
																						</span></h6>
																					<p>Reading & Writing</p>
																				</div>
																			</div>
																		</li>
																		<li>
																			<h5>ACT</h5>
																			<span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
																		</li>
																	</>
																)
															})
															:
															"No Record Found"
														)
													}
												}) || ""
												:
												(profileAbout && profileAbout.gpaScoreData.testScore.length > 0 ?
													profileAbout.gpaScoreData.testScore.map((test, idx) => {
														return (
															<>
																<li>
																	<h5>SAT</h5>
																	<div className="circle-box">
																		<CircularProgressbar className="progressChart" value={test?.sattotalnumber ? Math.round((test?.sattotalnumber / 1600) * 100) : 0} text={`${test?.sattotalnumber || ""} / 1600`} />
																	</div>
																	<div className="botom-detail">
																		<div className="item-box">
																			<h6>{test?.mathValue || ""}<span> / 800
																				{/*{test?.sattotalnumber || ""}*/}
																			</span></h6>
																			<p>Math</p>
																		</div>
																		<div className="item-box">
																			<h6>{test?.readingandwritingValue || ""}
																				<span> / 800
																					{/*{test?.sattotalnumber || ""}*/}
																				</span></h6>
																			<p>Reading & Writing</p>
																		</div>
																	</div>
																</li>
																<li>
																	<h5>PSAT</h5>
																	<div className="circle-box">
																		<CircularProgressbar className="progressChart" value={test?.psatotalnumer ? Math.round((test?.psatotalnumer / 1520) * 100) : 0} text={`${test?.psatotalnumer || ""} / 1520`} />
																	</div>
																	<div className="botom-detail">
																		<div className="item-box">
																			<h6>{test?.psatmath || ""}
																				<span> / 760
																					{/*{test?.psatotalnumer || ""}*/}
																				</span></h6>
																			<p>Math</p>
																		</div>
																		<div className="item-box">
																			<h6>{test?.psatreadingandwritingvalue || ""}
																				<span> / 760
																					{/*{test?.psatotalnumer || ""}*/}
																				</span></h6>
																			<p>Reading & Writing</p>
																		</div>
																	</div>
																</li>
																<li>
																	<h5>ACT</h5>
																	<div className="circle-box" style={{ height: "175px" }}>
																		<div className="item-box">
																			<p>ACT Composite Score</p>
																			<h6>{test?.actcompletescoreid || ""}<span></span></h6>
																		</div>
																		<div className="item-box">
																			<p>Notes</p>
																			<h6>{test?.actNotes || ""}<span></span></h6>
																		</div>
																		<div className="item-box">
																			<p>Test Date</p>
																			<h6>{test?.acttestdate ? getUTCTimeDateOverall(test?.acttestdate) : ""}<span></span></h6>
																		</div>
																	</div>
																	<div className="botom-detail">
																	</div>
																</li>
															</>
														)
													})
													:
													"No Record Found"
												)
											}
										</ul>
									</div>
									<div className="box-item">
										<h4>HONORS CLASSES
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/gradesScores'} />}
										</h4>
										<ul className="data-info-item">
											{profileAbout && profileAbout.gpaScoreData.honorsAndAcadmicAccomplishment.length > 0 ?
												profileAbout.gpaScoreData.honorsAndAcadmicAccomplishment.map((hor, idx) => {
													return (
														<>
														<li>
															<span>Honors Classes</span>
															<span>{hor?.havehonors == 1 ? "Yes" : hor?.havehonors == 2 ? "No" : "" || ""}</span>
														</li>
															<li>
																<span>Taking Honors classes currently</span>
																<span>{hor?.honorclassestimeperiod || ""}</span>
															</li>
															<li>
																<span>Honors Class Details</span>
																<span>{hor?.havehonors == 1 ? hor?.classdetails ?? "" : ""}</span>
															</li>
														</>
													)
												})
												:
												"No Record Found"
											}
										</ul>
									</div>
									<div className="box-item">
										<h4>AP / IB CLASSES
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/gradesScores'} />}
										</h4>
										<ul className="data-info-item">
											{profileAbout && profileAbout.gpaScoreData.ap_IP_Class.length > 0 ?
												profileAbout.gpaScoreData.ap_IP_Class.map((ip, idx) => {
													return (
														<>
															<li>
																<span>AP / IB Classes</span>
																<span>
																	{ip?.areyoutakeapbpclass == 1 ? "Yes" : ip?.areyoutakeapbpclass == 2 ? "No" : "" || ""}
																</span>
															</li>
															<li>
																<span>Taking AP / IB classes currently</span>
																<span>{ip?.timeperiodforcources || ""}</span>
															</li>
															<li>
																<span>AP / IB Class Details</span>
																<span>{ip?.areyoutakeapbpclass == 1 && ip?.classdetails ? ip?.classdetails : ""}</span>
															</li>
														</>
													)
												})
												:
												"No Record Found"
											}
										</ul>
									</div>
									<div className="box-item">
										<h4>ACADEMIC ACCOMPLISHMENTS
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/gradesScores'} />}
										</h4>
										<ul className="data-info-item">
											{profileAbout && profileAbout.gpaScoreData.honorsAndAcadmicAccomplishment.length > 0 ?
												profileAbout.gpaScoreData.honorsAndAcadmicAccomplishment.map((hor, idx) => {
													return (
														<>
															<li>
																<span>Academic Honors</span>
																<span>{hor?.acadhonors || ""}</span>
															</li>
															<li>
																<span>Extracurricular Activities</span>
																<span>{hor?.extraactivity || ""}</span>
															</li>
															<li>
																<span>Intended Major / Area of Study</span>
																{props.isPublicView ?
																	state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx) => {
																		if (itm?.publicprofilefieldid == 31 && itm?.active) return <span>{hor?.areaOfStudyName || ""}</span>
																		else if (itm?.publicprofilefieldid == 31 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
																	}) || ""
																	:
																	<span>{hor?.areaOfStudyName || ""}</span>
																}
															</li>
														</>
													)
												})
												:
												"No Record Found"
											}
										</ul>
									</div>
								</div>
							</div>
							{!props.isPublicView &&
							<div id="CollegeInsights" className="common-item-box">
								<h3 className="title">College Insights</h3>
								<div className="content-box">
									<div className="box-item">
										<h4>FAMILY BACKGROUD
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/collegeInsights'} />}
										</h4>
										<ul className="data-info-item">
											{profileAbout && profileAbout.collegeInsightData.length > 0 ?
												profileAbout.collegeInsightData.map((clgIn, idx) => {
													return (
														<>
															<li>
																<span>Older siblings gone to college</span>
																<span>{clgIn?.haveanysiblingswhogonetocollege == 1 ? "Yes" : clgIn?.haveanysiblingswhogonetocollege == 0 ? "No" : ""}</span>
															</li>
															<li>
																<span>Influential college decision</span>
																{/* {state?.common?.relationships?.data?.length > 0 ?
																	<>
																		{state?.common?.relationships?.data?.map((clgInMap, idx) => {
																			if (clgInMap?.id == clgIn?.influentialdecision) {
																				return clgInMap?.relationShipName || ""
																			}
																		})}
																	</>
																	: ""} */}
																<span>{clgIn?.decisionRelationShip || ""}</span>    
															</li>
															<li>
																<span>Live with</span>
																<span>{clgIn?.wholiveswithyou || ""}</span>
															</li>
															<li>
																<span>Are Your parents divorced?</span>
																<span>{clgIn?.isparentdivorced == 1 ? "Yes" : clgIn?.isparentdivorced == 0 ? "No" : ""}</span>
															</li>
														</>
													)
												})
												:
												"No Record Found"
											}
										</ul>
									</div>
									<div className="box-item">
										<h4>COLLEGE FINANCIAL AID ASSESSMENT
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/collegeInsights'} />}
										</h4>
										<ul className="data-info-item">
											{profileAbout && profileAbout.collegeInsightData.length > 0 ?
												profileAbout.collegeInsightData.map((clgIn, idx) => {
													return (
														<>
															<li>
																<span>Ethnic heritage</span>
																{clgIn?.ethnicValue || ""}
																{/* {state?.common?.racialEthnicHeritages?.data?.length > 0 ?
																<>
																{state?.common?.racialEthnicHeritages?.data?.map((clgInMap, idx)=>{
																	if(clgInMap?.id == clgIn?.bestracialorethnicheritage){
																	return clgInMap?.ethnicvalue || ""
																}
																	})}

																</>
																:""} */}
															</li>
															<li>
																<span>Annual household income</span>
																{clgIn?.incomeRange || ""}
																{/* {state?.common?.annualHouseholdIncomes?.data?.length > 0 ? */}
																{/* <> */}
																{/* {state?.common?.annualHouseholdIncomes?.data?.map((clgInMap, idx)=>{
																	if(clgInMap?.id == clgIn?.annualhouseholdincomeid){
																	return clgInMap?.incomerange || ""
																}
																	})} */}

																{/* </> */}
																{/* :""} */}
															</li>
															<li>
																<span>EFC family contribution</span>
																<span>{clgIn?.efc || ""}</span>
															</li>
															<li>
																<span>Family qualify for The National School Lunch Program or other need-based assistance</span>
																<span>{clgIn?.isyourfamilyqualifyanynationalprogram == 1 ? "Yes" : clgIn?.isyourfamilyqualifyanynationalprogram == 0 ? "No" : ""}</span>
															</li>
														</>
													)
												})
												:
												"No Record Found"}
										</ul>
									</div>
									<div className="box-item">
										<h4>FAMILY RESIDENCE
											{props.isPublicView ? "" : <EditProfileButton history={props.history} route={'/collegeInsights'} />}
										</h4>
										<ul className="data-info-item">
											{
												(profileAbout && profileAbout.collegeInsightData.length > 0 ?
													profileAbout.collegeInsightData.map((clgIn, idx) => {
														return (
															<>
																<li>
																	<span>Home Address</span>
																	{/*comment as per said*/}
																	<span>
																		{/*      <svg className="pin-icon"*/}
																		{/*           xmlns="http://www.w3.org/2000/svg"*/}
																		{/*           width="15.152" height="21.219"*/}
																		{/*           viewBox="0 0 15.152 21.219">*/}
																		{/*    <g id="location" transform="translate(-6.75 -2.248)">*/}
																		{/*    <path id="Path_431" data-name="Path 431"*/}
																		{/*          d="M18.781,12.765a1.515,1.515,0,1,1-1.515-1.515A1.515,1.515,0,0,1,18.781,12.765Z"*/}
																		{/*          transform="translate(-2.938 -2.938)"*/}
																		{/*          fill="#ff0000"></path>*/}
																		{/*    <path id="Path_432" data-name="Path 432"*/}
																		{/*          d="M14.326,2.25A7.426,7.426,0,0,0,6.75,9.5c0,1.9.867,4.432,2.577,7.519a55.569,55.569,0,0,0,3.788,5.839,1.5,1.5,0,0,0,2.424,0,55.789,55.789,0,0,0,3.788-5.835C21.036,13.932,21.9,11.4,21.9,9.5A7.426,7.426,0,0,0,14.326,2.25Zm0,10.607a3.031,3.031,0,1,1,3.031-3.031,3.031,3.031,0,0,1-3.031,3.031Z"*/}
																		{/*          transform="translate(0)" fill="#ff0000"></path>*/}
																		{/*    </g>*/}
																		{/*</svg>*/}
																		{`${clgIn?.address ? clgIn?.address + ", " : ""}
															${clgIn?.addresscountinue ? clgIn?.addresscountinue + ", " : ""}
															${clgIn?.city ? clgIn?.city + ", " : ""}
															${clgIn?.state ? clgIn?.state + ", " : ""}
															${clgIn?.countryName ? clgIn?.countryName + ", " : ""}${clgIn?.zipcode ? clgIn?.zipcode : ""}`}
																	</span>
																</li>
															</>)
													})
													:
													"No Record Found")
											}
										</ul>
									</div>
								</div>
							</div>}
							<div id="Offers" className="common-item-box">
								<h3 className="title">Offers </h3>
								<div className="content-box">
									{
										props.isPublicView ? "" : (
											<div className="edit-link-box">
												<EditProfileButton history={props.history} route={'/Offers'} />
											</div>
										)
									}
									<div className="box-item">
										<ul className="data-info-item team-list">
											{/* {props.isPublicView ?
											state.athlete?.editUserProfileData?.getprofileTimelineStatusTabs?.data?.profileTimelineAbout?.map((itm, idx)=>{
												if(itm?.publicprofilefieldid == 34 && itm?.active) return  (profileAbout && profileAbout?.offerData.length> 0 ?
													profileAbout && profileAbout?.offerData.map((off, idx)=>{
														return(
															<>
																<li>
																	<div className="img-box">
																	{
																		off?.collegelogo ? <img src={`${imageDownloadUrl}/${off?.collegelogo ?? ""}`} alt=""/> : <img src={`${TeamLogo}`} alt=""/>
																	}
																	</div>
																	<div className="detail-box">
																		<h5>{off?.collegename || ""}</h5>
																		<p><strong>Offer:</strong>{off?.offeresvalue || ''}<br /><strong>Interest
																			Level:</strong> {off?.interestlevelValue || ''}<br /><strong>Offer Date:</strong> {off?.offerdate ? getUTCTimeDateOverall(off?.offerdate).format('MM/DD/YYYY') : ''}</p>
																	</div>
																</li>
															</>
														)
													})
													:
													"No Record Found")
												else if (itm?.publicprofilefieldid == 34 && itm?.active == 0) return <span><p style={{fontWeight:"bold"}}>Permission Not Granted</p></span>
											}) || ""
											: */}
											{profileAbout && profileAbout?.offerData.length > 0 ? allOfferPrivate ? "No Record Found" :
												profileAbout && profileAbout?.offerData.map((off, idx) => {
													if((props.isPublicView && off.managevisible === 1) || (!props.isPublicView)) {
														return (
															<>
																<li>
																	<div className="img-box">
																		{
																			off?.collegelogo ? <img src={`${imageDownloadUrl}/${off?.collegelogo ?? ""}`} alt="" /> : <img src={`${TeamLogo}`} alt="" />
																		}
																	</div>
																	<div className="detail-box">
																		<h5>{(!!off?.collegename && off?.city && off?.state) ? `${getCamelCase(off?.collegename)} - [${getCamelCase(off?.city) ?? ""}, ${getCamelCase(off?.state)}]` : `${getCamelCase(off?.collegename)}`}
																		</h5>
																		<p><strong>Offer: </strong>{off?.offeresvalue || ''}<br /><strong>Interest
																			Level:</strong> {off?.interestlevelValue || ''}<br /><strong>Offer Date:</strong> {off?.offerdate ? getUTCTimeDateOverall(off?.offerdate) : ''}</p>
																	</div>
																</li>
															</>
														)
													}
												})
												:
												"No Record Found"
											}
										</ul>
									</div>
								</div>
							</div>
							<div id="Commitment" className="common-item-box">
								<h3 className="title">Commitment</h3>
								<div className="content-box">
									{
										props.isPublicView ? "" : (
											<div className="edit-link-box">
												<EditProfileButton history={props.history} route={'/viewCommitment'} />
											</div>
										)
									}
									<div className="box-item">
										<ul className="data-info-item team-list">
											{profileAbout && profileAbout.collegeCommitmentData.length > 0 ?
												profileAbout.collegeCommitmentData.map((com, idx) => {
													return (
														<>
															<li>
																<div className="img-box">
																	{
																		com?.collegelogo ? <img src={`${imageDownloadUrl}/${com?.collegelogo ?? ""}`} alt="" /> : <img src={`${TeamLogo}`} alt="" />
																	}
																</div>
																<div className="detail-box">
																	<h5>{(!!com?.collegename && com?.city && com?.state) ? `${getCamelCase(com?.collegename)} - [${getCamelCase(com?.city) ?? ""}, ${getCamelCase(com?.state)}]` : `${getCamelCase(com?.collegename)}`}
																	</h5>
																	<p>{com?.commitmentStatusValue || ""}<br />{getUTCTimeDateOverall(com?.commitmentDate) || ""}</p>
																</div>
															</li>
														</>
													)
												})
												:
												"No Record Found"
											}
										</ul>
									</div>
								</div>
							</div>
							<GoTopButton goTop={() => { window.scrollTo(0, 0) }} />
						</div>
					</div>
				</div>
			</div>
            <VideoPop
				setIsTestSingle={() => setIsVidPop(false)}
				vidID={vidID}
				isTestSingle={isVidPop}
				isToken={isToken}
				isLink={isLink}
            />
        </>
    );
})