import { put, takeEvery } from "redux-saga/effects";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SAVE_MEMBERSHIP_PLAN_START: "SAVE_MEMBERSHIP_PLAN_START",
  SAVE_MEMBERSHIP_PLAN_FAIL: "SAVE_MEMBERSHIP_PLAN_FAIL",
  SAVE_MEMBERSHIP_PLAN_SUCCESS: "SAVE_MEMBERSHIP_PLAN_SUCCESS",
};

// ============================================ Actions ========================================================

export const saveToMembershipStart = (data) => ({
  type: actionType.SAVE_MEMBERSHIP_PLAN_START,
  payload: data,
});

export const saveToMembershipFail = (data) => ({
  type: actionType.SAVE_MEMBERSHIP_PLAN_FAIL,
  payload: data,
});

export const saveToMembershipSuccess = (data) => ({
  type: actionType.SAVE_MEMBERSHIP_PLAN_SUCCESS,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function saveToMembershipReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_MEMBERSHIP_PLAN_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SAVE_MEMBERSHIP_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SAVE_MEMBERSHIP_PLAN_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* saveToMembershipSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(saveToMembershipSuccess(response.data.data.saveMembership));
      //make this msg generic $
      messagePopup("",  action?.payload.msg ? action?.payload.msg : "Membership Plan Purchased", "success");
      if (action.payload.closeModal) {
        history.push(action.payload.closeModal);
      }
    } else {
      messagePopup("", "Save Membership failed", "error");
      yield put(saveToMembershipFail("Save Membership failed"));
    }
  } catch (error) {
    messagePopup("", "Save Membership failed", "error");
    yield put(saveToMembershipFail("Save Membership failed"));
  }
}

export function* watchSaveToMembershipSaga() {
  yield takeEvery(actionType.SAVE_MEMBERSHIP_PLAN_START, saveToMembershipSaga);
}
