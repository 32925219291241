import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod } from "../../../services/httpService"
import messagePopup from "../../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    HEIGHT_WEIGHT_SAVE_START: 'HEIGHT_WEIGHT_SAVE_START',
    HEIGHT_WEIGHT_SUCCESS: 'HEIGHT_WEIGHT_SUCCESS',
    HEIGHT_WEIGHT_FAILED: 'HEIGHT_WEIGHT_FAILED'
}

// ============================================ Actions ========================================================
export const heigthWeightsaveStart = (data) => ({
    type: actionType.HEIGHT_WEIGHT_SAVE_START,
    payload: data
})

export const heigthWeightSuccess = (data) => ({
    type: actionType.HEIGHT_WEIGHT_SUCCESS,
    payload: data
})

export const heigthWeightFailed = (data) => ({
    type: actionType.HEIGHT_WEIGHT_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function heigthWeightReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.HEIGHT_WEIGHT_SAVE_START:
            return {
                ...state,
                loading: true
            }
        case actionType.HEIGHT_WEIGHT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        case actionType.HEIGHT_WEIGHT_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        default: return { ...state }
    }
}

// ===================================================== side effect =============================================

export function* heigthWeightsaveSaga(action){
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            action.payload.nextScreen()
        } else {
            messagePopup('', 'INCH List failed', 'error')
            yield put(heigthWeightFailed('INCH List failed'))
        }
    } catch (error) {
        messagePopup('', 'INCH List failed', 'error')
        yield put(heigthWeightFailed('INCH List failed'))
    }
}

export function* watchheigthWeightsaveSaga() {
    yield takeEvery(actionType.HEIGHT_WEIGHT_SAVE_START, heigthWeightsaveSaga)
}