import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  CATEGORY_LIST_START: "CATEGORY_LIST_START",
  CATEGORY_LIST_SUCCESS: "CATEGORY_LIST_SUCCESS",
  CATEGORY_LIST_FAILED: "CATEGORY_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const categoryListStart = (data) => ({
  type: actionType.CATEGORY_LIST_START,
  payload: data,
});

export const categoryListSuccess = (data) => ({
  type: actionType.CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const categoryListFailed = (data) => ({
  type: actionType.CATEGORY_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function categoryListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.CATEGORY_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.CATEGORY_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* categoryListSaga(action) {
  try {
    const response = yield fetchGraphMethod(action.payload.query, null, true);
    if (response && response.status === 200) {
      const allCategories = response.data.data.allCategories.Categories
        ? response.data.data.allCategories.Categories
        : null;
      yield put(categoryListSuccess(allCategories));
    } else {
      messagePopup("", "category List failed", "error");
      yield put(categoryListFailed("GPA List failed"));
    }
  } catch (error) {
    messagePopup("", "category List failed", "error");
    yield put(categoryListFailed("category List failed"));
  }
}

export function* watchCategoryListSaga() {
  yield takeEvery(actionType.CATEGORY_LIST_START, categoryListSaga);
}
