import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    GET_STATS_OVERVIEW_CARD_DATA_START: "GET_STATS_OVERVIEW_CARD_DATA_START",
    GET_STATS_OVERVIEW_CARD_DATA_SUCCESS: "GET_STATS_OVERVIEW_CARD_DATA_SUCCESS",
    GET_STATS_OVERVIEW_CARD_DATA_FAIL: "GET_STATS_OVERVIEW_CARD_DATA_FAIL",
};

// ============================================ Actions ========================================================
export const profileOverviewCardStatStart = (data) => ({
    type: actionType.GET_STATS_OVERVIEW_CARD_DATA_START,
    payload: data,
});

export const profileOverviewCardStatFail = (data) => ({
    type: actionType.GET_STATS_OVERVIEW_CARD_DATA_FAIL,
    payload: data,
});

export const profileOverviewCardStatSuccess = (data) => ({
    type: actionType.GET_STATS_OVERVIEW_CARD_DATA_SUCCESS,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function profileOverviewCardStatReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.GET_STATS_OVERVIEW_CARD_DATA_START:
            return {
                ...state,
                loading: true,
            };

        case actionType.GET_STATS_OVERVIEW_CARD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };

        case actionType.GET_STATS_OVERVIEW_CARD_DATA_FAIL:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };

        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* profileOverviewCardStatSaga(action) {
    try {
        const response = yield fetchMethod(
            `/api/Publicprofilefieldpreviews/statsMatrixDetailFromOverView`,
            action.payload,
            "post",
            action?.payload?.isAccessToken
        );
        if (
            response &&
            response.status === 200 &&
            response.data.response.data
        ) {
            yield put(
                profileOverviewCardStatSuccess({
                    profileOverviewCardStat: response.data.response.data,
                })
            );
        }
        else if(response && response.status === 200 && response.data.response.status === 201){
            // messagePopup("", `${response.data.response.msg}`, "error");
            yield put(profileOverviewCardStatFail(`${response.data.response.msg}`));
        }
        else {
            messagePopup("", "Profile Overview Data failed", "error");
            yield put(profileOverviewCardStatFail("Profile Overview Data failed"));
        }
    } catch (error) {
        messagePopup("", "Profile Overview Data failed", "error");
        yield put(profileOverviewCardStatFail("Profile Overview Data failed"));
    }
}

//Watcher
export function* watchProfileOverviewCardStatSaga() {
    yield takeEvery(actionType.GET_STATS_OVERVIEW_CARD_DATA_START, profileOverviewCardStatSaga);
}