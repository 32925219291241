import { all, fork } from "redux-saga/effects";
import { watchFootListSaga } from "./footList";
import { watchGpaListSaga } from "./gpaList";
import { watchInchListSaga } from "./inchList";
import { watchGradeListSaga } from "./gradeList";
import { watchPrimaryListSaga } from "./primaryPositionList";
import { watchSecondaryListSaga } from "./secondaryPositionList";
import { watchOnboarding } from "./onboarding";
import { watchCoachTypeListSaga } from "./coachTypeList";
import { watchBattingPreferenceListSaga } from "./battingPreferences";
import { watchThrowingPreferenceListSaga } from "./throwingPreferences";
import { watchSchoolCurrentLevelListSaga } from "./schoolCurrentLevel";
import { watchCoachDetails } from "./coachDetails";
import { watchGetPositionalFieldsListSaga } from "./positionmappingWithPersonalRecord";
import { watchheigthWeightsaveSaga } from "./heightWeight";
import { watchGpaMappingSaga } from "./gpaMapping";
import { watchAthleteHeightWeightSaga } from "./athleteheightandweights";
import { watchathleteParentMappingSaga } from "./athleteParentMapping";
import { watchStatLinkSaga } from "./statLinks";

export default function* rootOnBoardingSagas() {
  yield all([
    fork(watchPrimaryListSaga),
    fork(watchSecondaryListSaga),
    fork(watchGradeListSaga),
    fork(watchGpaListSaga),
    fork(watchInchListSaga),
    fork(watchFootListSaga),
    fork(watchOnboarding),
    fork(watchCoachTypeListSaga),
    fork(watchBattingPreferenceListSaga),
    fork(watchThrowingPreferenceListSaga),
    fork(watchSchoolCurrentLevelListSaga),
    fork(watchCoachDetails),
    fork(watchGetPositionalFieldsListSaga),
    fork(watchheigthWeightsaveSaga),
    fork(watchGpaMappingSaga),
    fork(watchAthleteHeightWeightSaga),
    fork(watchathleteParentMappingSaga),
    fork(watchStatLinkSaga),
  ]);
}
