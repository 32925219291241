import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_PROFILE_CALCULATION_LIST_START: "ALL_PROFILE_CALCULATION_LIST_START",
  ALL_PROFILE_CALCULATION_LIST_SUCCESS: "ALL_PROFILE_CALCULATION_LIST_SUCCESS",
  ALL_PROFILE_CALCULATION_LIST_FAILED: "ALL_PROFILE_CALCULATION_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const allProfileCalculationListStart = (data) => ({
  type: actionType.ALL_PROFILE_CALCULATION_LIST_START,
  payload: data,
});

export const allProfileCalculationListSuccess = (data) => ({
  type: actionType.ALL_PROFILE_CALCULATION_LIST_SUCCESS,
  payload: data,
});

export const allProfileCalculationListFailed = (data) => ({
  type: actionType.ALL_PROFILE_CALCULATION_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allProfileCalculationListReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.ALL_PROFILE_CALCULATION_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_PROFILE_CALCULATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_PROFILE_CALCULATION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allProfileCalculationListSaga(action) {
  try {
    // const response = yield fetchGraphMethod(action.payload.query, null, true);
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const allProfilecalculationmasters =
        response.data.data.allProfilecalculationmasters;
      let data = {};
      if (
        allProfilecalculationmasters.Profilecalculationmasters &&
        allProfilecalculationmasters.Profilecalculationmasters[0]
      ) {
        allProfilecalculationmasters.Profilecalculationmasters.map((item) => {
          // let dataT = {};
          // dataT[`${item.profilesectionname}`]=
          let profileData = JSON.parse(item.profilecalculationvalue);
          let obj = { id: item.id };
          for (let key in profileData[0]) {
            obj[key] = profileData[0][key];
          }
          data[`${item.profilesectionname}`] = obj;
        });
      }

      yield put(allProfileCalculationListSuccess(data));
    } else {
      messagePopup("", "Profile Calculation failed", "error");
      yield put(
        allProfileCalculationListFailed("Profile Calculation List failed")
      );
    }
  } catch (error) {
    messagePopup("", "Profile Calculation failed", "error");
    yield put(allProfileCalculationListFailed("Profile Calculation failed"));
  }
}

export function* watchAllProfileCalculationListSaga() {
  yield takeEvery(
    actionType.ALL_PROFILE_CALCULATION_LIST_START,
    allProfileCalculationListSaga
  );
}
