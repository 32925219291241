import React, { useState, useEffect } from "react";
import { imageDownloadUrl } from "../../../../../config";
import { getUserInfo } from "../../../../../services/authService";
import downloadPng from "./assets/img/download.png";
import Rectangle132 from "./assets/img/Rectangle132.png";
import { fetchGraphMethod } from "../../../../../services/httpService";
import { usersForUnverifiedProfile } from "../../../../common/CommonQuery"
import { Link } from "react-router-dom";

export default function VerificationModal(props) {
  const [fields, setFields] = useState({
    user: getUserInfo() ? getUserInfo() : 0,
    profileImagename: "",
    profileImage: null,
  });
  const [profileImages, setProfileImages] = useState([]);
  const [isPic, setIsPic] = useState(true)

  useEffect(async() => {
    await fetchGraphMethod(usersForUnverifiedProfile, {
      obj: {
        and: [{ or: [{ id:1676 }, { id:2726 }, { id:2731 }] }],
        //verifieduser: 1,
      },
      first:3,
      last:3  
      },
      true
    ).then((res) => { 
      if(res.status == 200){
        setProfileImages(res.data.data.allAthletes.Athletes)
        getProfileStatus(res.data.data.allAthletes.Athletes?.length > 0 ?
            res.data.data.allAthletes.Athletes : []
        )
      }
    })
  }, [])

  useEffect(() => {
    if (props.athleteData.data) {
      const fieldT = fields;
      const { onboardingData, athletesData } = props.athleteData.data;
      fields["athleteData"] = athletesData;
      fieldT["profileImagename"] = onboardingData
        ? onboardingData.profileimages
          ? onboardingData.profileimages
          : ""
        : "";
      fieldT["profileImage"] = onboardingData
        ? onboardingData.profileimages
          ? `${imageDownloadUrl}/${onboardingData.profileimages}`
          : ""
        : "";
      setFields({ ...fieldT });
    }
  }, [props.athleteData.data]);
  const getProfileStatus = (data) =>{
    let status = false
    if (data?.length > 0){
      const proArr = []
      data?.map((item) =>{
        if (!!item?.onBoardingUserIdMap?.Onboardings?.[0]?.profileimages){
            proArr?.push("Yes")
        }
      })
      if (proArr?.length > 0){
          status = true
      }else{
         status = false
      }
    }
    setIsPic(status)
  }
  return (
    <div
      className={`modal fade getVerifiedModal show`}
      id="getVerifiedModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.setIsModal(false)}
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.804"
                  height="15.803"
                  viewBox="0 0 15.804 15.803"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <g className="a" transform="translate(-6.776 -6.776)">
                    <path
                      d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                      transform="translate(0 0)"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
          <div className="modal-body">
            <h1 className="heading">
              <p>Get Verified</p>
              Trusted by College Coaches
              {/* Maximize your Exposure. */}
            </h1>
            <div className="profileBox">
              <div className="profileImgBox">
                <div className="imgBox" style={{fontSize:"30px", fontWeight:"bold"}}>
                  {fields.profileImage ? (
                    <img src={fields.profileImage} alt="profile" />
                  ) : (
                      `${!!fields?.athleteData?.athletefirstname && 
                      fields?.athleteData?.athletefirstname?.split("")[0]?.toUpperCase()}${!!fields?.athleteData?.athletelastname 
                      && fields?.athleteData?.athletelastname?.split("")[0]?.toUpperCase()}`
                    // <img src={downloadPng} alt="profile" />
                  )}
                </div>
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.828"
                    height="25.997"
                    viewBox="0 0 25.828 25.997"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g transform="translate(-306 -179)">
                      <path
                        className="a"
                        d="M-5645.777-718.874a1.147,1.147,0,0,1,2.111,0l0,.009a1.147,1.147,0,0,0,1.91.317h0a1.146,1.146,0,0,1,2,.68l0,.034a1.146,1.146,0,0,0,1.7.916l.008,0a1.146,1.146,0,0,1,1.669,1.288l-.016.062a1.146,1.146,0,0,0,1.31,1.416l.033-.006a1.146,1.146,0,0,1,1.159,1.758l-.047.071a1.147,1.147,0,0,0,.776,1.761l.063.01a1.146,1.146,0,0,1,.521,2.038l-.071.055a1.146,1.146,0,0,0,.16,1.916l.074.04a1.146,1.146,0,0,1-.175,2.1l-.072.024a1.146,1.146,0,0,0-.474,1.864l.061.066a1.146,1.146,0,0,1-.852,1.924h-.049a1.147,1.147,0,0,0-1.058,1.61l.031.07a1.146,1.146,0,0,1-1.433,1.544l-.019-.007a1.147,1.147,0,0,0-1.527,1.182l0,.049a1.147,1.147,0,0,1-1.859,1l0,0a1.146,1.146,0,0,0-1.831.625l0,.02a1.146,1.146,0,0,1-2.082.344v0a1.146,1.146,0,0,0-1.937,0v0a1.146,1.146,0,0,1-2.082-.344l0-.02a1.146,1.146,0,0,0-1.831-.625l0,0a1.146,1.146,0,0,1-1.859-1l0-.049a1.146,1.146,0,0,0-1.527-1.182l-.019.007a1.146,1.146,0,0,1-1.434-1.544l.031-.07a1.146,1.146,0,0,0-1.057-1.61h-.049a1.146,1.146,0,0,1-.852-1.924l.061-.066a1.146,1.146,0,0,0-.474-1.864l-.072-.024a1.146,1.146,0,0,1-.175-2.1l.075-.04a1.146,1.146,0,0,0,.16-1.916l-.071-.055a1.146,1.146,0,0,1,.521-2.038l.062-.01a1.146,1.146,0,0,0,.777-1.761l-.047-.071a1.146,1.146,0,0,1,1.158-1.758l.034.006a1.146,1.146,0,0,0,1.31-1.416l-.016-.062a1.146,1.146,0,0,1,1.669-1.288l.008,0a1.146,1.146,0,0,0,1.7-.916l0-.034a1.146,1.146,0,0,1,2-.68h0a1.146,1.146,0,0,0,1.91-.317Z"
                        transform="translate(5963.635 898.573)"
                      />
                      <path
                        className="b"
                        d="M4.452-10.374,4.13-3.7H1.792L1.47-10.374ZM2.968.112A1.676,1.676,0,0,1,1.8-.287,1.309,1.309,0,0,1,1.358-1.3,1.33,1.33,0,0,1,1.8-2.324,1.658,1.658,0,0,1,2.968-2.73a1.638,1.638,0,0,1,1.155.406A1.33,1.33,0,0,1,4.564-1.3,1.315,1.315,0,0,1,4.123-.294,1.638,1.638,0,0,1,2.968.112Z"
                        transform="translate(315.642 197.374)"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <h5 className="name">{`${
                fields.athleteData && fields.athleteData.athletefirstname
              }  ${
                fields.athleteData && fields.athleteData.athletelastname
              }`}</h5>
              <ul>
                <li>
                  <label>Sport:</label>
                  <span>
                    {fields.athleteData && fields.athleteData.sportsName}
                  </span>
                </li>
                <li>
                  <label>Grad:</label>
                  <span>
                    {fields.athleteData && fields.athleteData.graduationYear}
                  </span>
                </li>
              </ul>
            </div>
            <p className="info">
              <i>Get your academic and athletic data verified by college recruiting experts.</i>
            </p>
            <p className="info">
              <i>Verified athletes you might know?</i>
            </p>
            <div className="infoUsers" style={isPic ? {} : {justifyContent : "center"}}>
              <div className="infouseritem" style={isPic ? {} : {display:"none"}}>
              {profileImages.map(item =>
                item?.onBoardingUserIdMap?.Onboardings?.length ?
                <div className="infouseritem-img">
                  <Link to={`/publicView/1/${item.id}`} target="_blank" >
                    <img alt="" src={`${imageDownloadUrl}/${item?.onBoardingUserIdMap?.Onboardings[0]['profileimages']}`} />
                  </Link>
                </div>
                : null
              )}
              </div>
              <div className="info">
                <i>
                Trusted by thousands of verified athletes.
                </i>
              </div>
            </div>
            {/* <p className="info">Learn how to unlock your account.</p> 
            <p className="info">
              <span className="row">
                <span className="col-1">
                  <img
                    style={{
                      width: "45px",
                      height: "45px",
                      marginBlockEnd: "5px",
                    }}
                    src={Rectangle132}
                    alt=""
                  />
                </span>
                <span className="col">
                  <i>
                    Verified athletes you might know: Jason Smith, John Doe,
                    Billy Smith
                  </i>
                </span>
              </span>
            </p>*/}

            <div className="btnBox">
              {/*<button*/}
              {/*  className="verifyBtn"*/}
              {/*  data-toggle="modal"*/}
              {/*  data-target="#shareUpdateModal"*/}
              {/*  onClick={()=>window.open('https://calendly.com/sportsforce/recruiting-assessment-round-robin','_blank')}*/}
              {/*>*/}
              {/*  Get Verified Today*/}
              {/*</button>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
