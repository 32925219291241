import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { imageDownloadUrl } from "../../../../../../config";
import { getAllCoachDetailsStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/allCoachesDetails";
import {
  getAllFollowersQuery,
  getCoachDetailsQuery,
  getCollegeCoachActivityData,
  getViewCollegesQuery,
  searchCountQuery,
  viewsCountQuery,
} from "./CollegeDetailsQuery";
import {Link, useHistory} from "react-router-dom";
import messagePopup from "../../../../../../utils/messagePopup";
import { collegeActivityListStart } from "../../../../../../redux/athlete/dashboard/colleges/collegeActivity";
import { getFollowersListStart } from "../../../../../../redux/athlete/dashboard/homepage/followersList";
import { searchCollegeCountListStart } from "../../../../../../redux/athlete/dashboard/colleges/searchCollegeCount";
import { userProfileViewListStart } from "../../../../../../redux/athlete/dashboard/colleges/userProfileView";
import { getSlashFormattedDate } from "../../../../../../utils/dateTimeFormatter";
import BackdropLoader from "../../../../../common/Loader";
import warningMessagePopup from "../../../../../../utils/warningMagPop";
import { fetchMethod } from "../../../../../../services/httpService";
import swal from "sweetalert";
import { membershipListStart } from "../../../../../../redux/athlete/dashboard/account/membership";
import { getAllMembershipsQuery } from "../../account/myAccounts/membershipQuery";
import { getUserInfo } from "../../../../../../services/authService";
import emailImg from "../assets/img/email_icon.svg";
import fecebookImg from "../assets/img/facebook_icon.svg";
import twitterImg from "../assets/img/twitter_icon.svg";
import instagramImg from "../assets/img/instagram_icon.svg";
import phonenoImage from "../assets/img/phone-icon-green.svg";
import toastr from "toastr";
import { fetchGraphMethod } from "../../../../../../services/httpService";
import { getAllAthletesDataQuery } from "../../../../../common/CommonQuery";
const Recruiting = (props) => {
  const [coachData, setCoachData] = useState(null);
  const [followersData, setFollowersData] = useState(null);
  const [views, setViews] = useState(null);
  const [searchCollegeCount, setSearchCollegeCount] = useState(null);
  const [coachIds, setCoachIds] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [memberships, setMemberships] = useState(null);
  const [lastActivitydate, setLastActivityDate] = useState();
  const [athleteDetail, setAthleteDetail] = useState(null);
  const [fields, setFields] = useState({});
  const history = useHistory();

  useEffect(() => {
    getAthleteDetail();
    getCoachData();
    getAllMembershipData();
  }, []);

  useEffect(() => {
    getFollowersData();
    getSearchCollegeCount();
    getViewsCountData();
    activityOfCollegeCoach();
  }, [coachData]);

  useEffect(() => {
    if (props.allCoachDetails.data) {
      setCoachData(props.allCoachDetails.data);
    }
  }, [props.allCoachDetails.data]);

  const allCoachesOfACollege = () => {
    let data =
      coachData &&
      coachData.map((coach, index) => {
        return coach.id;
      });
    if (data) {
      setCoachIds(data);
    }
  };

  const activityOfCollegeCoach = async () => {
    await allCoachesOfACollege();
    getCollegeActivityData();
  };

  useEffect(() => {
    if (props.followersList.data) {
      setFollowersData(props.followersList.data);
    }
  }, [props.followersList.data]);

  useEffect(() => {
    if (props.searchCollege.data) {
      setSearchCollegeCount(props.searchCollege.data?.totalCount);
    }
  }, [props.searchCollege.data]);

  useEffect(() => {
    if (props.userProfileView.data) {
      setViews(props.userProfileView.data);
    }
  }, [props.userProfileView.data]);

  useEffect(() => {
    if (props.collegeActivity.data) {
      activityData.push(props.collegeActivity.data);
    }
  }, [props.collegeActivity.data]);

  useEffect(() => {
    if (props.allMemberships.data) {
      setMemberships(props.allMemberships.data.allMemberships);
    }
  }, [props.allMemberships.data]);
  const getFollowersData = () => {
    props.getFollowersListStart({
      query: getAllFollowersQuery,
      variables: {
        where: {
          followingcoachid: coachData && coachData[0]?.id,
        },
      },
    });
  };
  const getAllMembershipData = () => {
    props.membershipListStart({
      query: getAllMembershipsQuery,
      variables: {
        where: {
          userid: getUserInfo() ? getUserInfo().id : "",
        },
      },
    });
  };
  const getViewsCountData = () => {
    props.userProfileViewListStart({
      query: viewsCountQuery,
      variables: {
        where: {
          profileviewedby: coachData && coachData[0]?.id,
        },
      },
    });
  };
  const getSearchCollegeCount = () => {
    props.searchCollegeCountListStart({
      query: searchCountQuery,
      variables: {
        where: {
          coachid: coachData && coachData[0]?.id,
        },
      },
    });
  };

  const getCoachData = () => {
    let collegeDetailsId = window.location.href.split("/");
    props.getAllCoachDetailsStart({
      query: getCoachDetailsQuery,
      variables: {
        where: {
          collegeid: collegeDetailsId[collegeDetailsId.length - 2],
          iscollegecoach: 1,
          order: "priority desc",
          active: 1
        },
      },
    });
  };
  const getCollegeActivityData = () => {
    let collegeDetailsId = window.location.href.split("/");
    for (let x in coachIds) {
      props.collegeActivityListStart({
        query: getCollegeCoachActivityData,
        variables: {
          where: {
            coachid: coachIds[x],
            collegeid: collegeDetailsId[collegeDetailsId.length - 2],
            order: "id desc",
          },
          first: 1,
          last: 1,
        },
      });
    }
  };
  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }
  const lastActivityOfCollegeCoach = (coachid) => {
    const lastActivity =
      activityData &&
      activityData.find((data, index) => {
        return data?.Collegecoachactivities[0]?.coachid == coachid;
      });
    const activityName = lastActivity?.Collegecoachactivities[0].activityname;
    const updatedOn = lastActivity?.Collegecoachactivities[0].updatedon
      ? getSlashFormattedDate(lastActivity?.Collegecoachactivities[0].updatedon)
      : "";
    const daysAgo = getNumberOfDays(
      updatedOn,
      getSlashFormattedDate(new Date())
    );
    return (
      <>
        <p>
          {activityName == "Group" ? <span>Group</span> : null}
          {activityName == "follow" ? (
            <span>Follow: {followersData?.length}</span>
          ) : null}
          {activityName == "View" ? (
            <span>View: {views?.totalCount}</span>
          ) : null}
          {activityName == "Search" ? (
            <span>Searches: {searchCollegeCount}</span>
          ) : null}
        </p>
        <p>
          {activityName == "Group" ? <span> {daysAgo} days ago</span> : null}{" "}
          {activityName == "follow" ? <span> {daysAgo} days ago</span> : null}{" "}
          {activityName == "View" ? <span> {daysAgo} days ago</span> : null}{" "}
          {activityName == "Search" ? <span> {daysAgo} days ago</span> : null}
        </p>
      </>
    );
  };
  const getInitials = (firstname, lastname) => {
    let initials = "";
    if (firstname && firstname[0] && lastname && lastname[0]) {
      initials = firstname[0] + lastname[0];
    }
    return initials;
  };

  function hideemail(data) {
    let email = data;
    let hiddenEmail = "";
    for (let i = 0; i < email.length; i++) {
      if (i > 2 && i < email.indexOf("@")) {
        hiddenEmail += "*";
      } else {
        hiddenEmail += email[i];
      }
    }
    return hiddenEmail;
  }
  const hidePhoneNumber = (data) => {
    let phonenumber = data;
    let hiddenNumber = "";
    if (data) {
      for (let i = 0; i < data?.length; i++) {
        if (i < data.length - 3) {
          hiddenNumber += "*";
        } else {
          hiddenNumber += data[i];
        }
      }
    }
    return hiddenNumber;
  };
  const goToMsgSectionIfAllowed = (isAllowed, isActive, coachid, collegeid) => {
    if (isAllowed == 1 && isActive == 1) {
      history.push(`/message/coach/composeMessage/${coachid}/${collegeid}`);
    } else if (isAllowed == 0 && isActive == 1) {
      // messagePopup("", "Your are not allowed to send message to selected university. Please contact your admin.", "warning");
      toastr.warning(
        "Your are not allowed to send message to selected university. Please contact your admin.",
        "",
        {
          progressBar: true,
          timeOut: 2000,
          closeButton: true,
          positionClass: "toast-top-full-width",
          showEasing: "swing",
          fadeIn: 40000,
          preventDuplicates: true,
        }
      );
    } else {
      return;
    }
  };
  const openNotifyStaffCoachChange = async (e, coach) => {
    if (coach.isemailsenttoadmin == 1) {
      return;
    } else {
      const confirmation = warningMessagePopup(
        "",
        "Notify Staff / Coach Change",
        "warning"
      );
      confirmation.then(async (value) => {
        if (value == true) {
          requestCoachChange(coach);
        }
      });
    }
  };
  const requestCoachChange = async (coach) => {
    let data = {
      firstName: coach.coachfirstname,
      lastName: coach.coachlastname,
      collegename: props?.collegeName,
      coachid: coach?.id,
    };
    const res = await fetchMethod(
      "/api/Coachandteamdetails/sendEmailToAdmin",
      data,
      "post",
      true
    );
    if (res?.data?.response?.status == 200) {
      // messagePopup("", "Your request has been sent to the admin ", "success");
      toastr.success("Your request has been sent to the admin.", "", {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: "toast-top-full-width",
        showEasing: "swing",
        fadeIn: 40000,
        preventDuplicates: true,
      });
      getCoachData();
    }
  };

  const getAthleteDetail = async () => {
    try {
      const response = await fetchGraphMethod(
        getAllAthletesDataQuery,
        { obj: { id: getUserInfo() ? getUserInfo().id : 0 } },
        true
      );
      if (response?.status == 200) {
        let athleteDetail = response?.data?.data?.allAthletes?.Athletes[0];
        let athleteFullName =
          athleteDetail?.athletefirstname +
            " " +
            athleteDetail?.athletelastname ?? "";
        let athleteGradYear =
          athleteDetail?.athleteathletegraduationyearmaprel?.Graduationyears[0]
            ?.yearvalue ?? "";
        let athletePosition =
          athleteDetail?.onBoardingUserIdMap?.Onboardings[0]
            ?.onboardingprimarypositionmaprel?.Primarypositions[0]
            ?.selectionfield ?? "";
        let athleteSchool =
          athleteDetail?.onBoardingUserIdMap?.Onboardings[0]?.highschoolname ??
          "";
        let ahleteSportName =
          athleteDetail?.athleteathletesportsmaprel?.Athletesports[0]
            ?.sportname ?? "";
        let athletePermalinkurl = athleteDetail?.permalinkurl ?? "";
        const fieldT = fields;
        fieldT["athleteFullName"] = athleteFullName;
        fieldT["athleteGradYear"] = athleteGradYear;
        fieldT["athletePosition"] = athletePosition;
        fieldT["athleteSchool"] = athleteSchool;
        fieldT["ahleteSportName"] = ahleteSportName;
        fieldT["athletePermalinkurl"] = athletePermalinkurl;
        setFields(fieldT);
      } else {
        messagePopup("", "Fetch athlete data Failed status === 200", "error");
      }
    } catch (error) {
      messagePopup("", "Fetch athlete data Failed error", "error");
    }
  };
  return (
    <>
      <div id="Recruiting">
        <BackdropLoader
          open={
            props.allCoachDetails.loading ||
            props.followersList.loading ||
            props.searchCollege.loading ||
            props.userProfileView.loading ||
            props.collegeActivity.loading
          }
        />
        <div className="recruiting-box-data">
          <div className="top-block">
            <h3>Recruiting</h3>
            <p>Baseball Coaching Staff</p>
          </div>
          <ul className="recruiting-list">
            {coachData
              ? coachData.map((coach, index) => {
                  return (
                    <li key={index}>
                      <div className="recruiting-profile-info">
                        <div className="profile-img">
                          {coach?.coachandteamdetailsonboardingidmaprel
                            ?.Onboardings?.profileimages ? (
                            <img
                              src={
                                imageDownloadUrl +
                                "/" +
                                coach?.coachandteamdetailsonboardingidmaprel
                                  ?.Onboardings?.profileimages
                              }
                              alt=""
                            />
                          ) : (
                            getInitials(
                              coach?.coachfirstname,
                              coach?.coachlastname
                            )
                          )}
                        </div>
                        <div className="profile-detail">
                          <h4 className="name">
                            {coach?.coachfirstname} {coach?.coachlastname}
                            {coach?.active == 1 ? <span>Active</span> : null}
                            {coach?.active == 1 ? (
                              <span
                                className="link-info"
                                disabled={
                                  coach.isemailsenttoadmin == 1 ? true : false
                                }
                                onClick={(e) =>
                                  openNotifyStaffCoachChange(e, coach)
                                }
                              >
                                <svg
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                                </svg>
                              </span>
                            ) : null}
                          </h4>
                          <div
                            className="position"
                            style={
                              coach?.active == 0
                                ? { display: "mone" }
                                : { display: "block" }
                            }
                          >
                            {coach?.active == 0
                              ? "No longer in this college"
                              : coach?.designation}
                          </div>
                          {memberships?.length == 0 ? (
                            <p>
                              {coach?.coachemail}
                              {/* {hideemail(coach?.coachemail)} */}
                            </p>
                          ) : (
                            <p>{coach?.coachemail}</p>
                          )}
                          {memberships?.length == 0 ? (
                            <p>
                              <a href={`tel:${coach?.coachphonenumber}`}>
                                {coach?.coachphonenumber}
                              </a>
                              {/* {hidePhoneNumber(coach?.coachphonenumber)} */}
                            </p>
                          ) : (
                            <p>
                              <a href={`tel:${coach?.coachphonenumber}`}>
                                {coach?.coachphonenumber}
                              </a>
                            </p>
                          )}
                          <div className="follow-links">
                            {props.collegeFollowAthlete?.length !== 0 ? (
                              <div className="title">Follow You</div>
                            ) : null}
                            {coach?.coachemail ? (
                              <a
                                // onClick={() => goToMsgSectionIfAllowed(coach?.isathletesendmsgtocoach, coach?.active, coach.id, coach.collegeid)}
                                href={`mailto:${coach?.coachemail}?subject=${fields?.athleteFullName} - ${fields?.athleteGradYear} - ${fields?.athletePosition} - ${fields?.athleteSchool}
                                &body=Hi, ${coach?.coachfirstname} ${coach?.coachlastname}%0A %0A %0A %0A${fields.athleteFullName}%0A${fields.ahleteSportName} - ${fields.athleteGradYear}%0A${fields.athletePermalinkurl}`}
                                className="icon-box ml-0"
                              >
                                <img src={emailImg} alt="Email" />
                              </a>
                            ) : null}
                            {coach?.coachphonenumber ? (
                              <a
                                target={"_blank"}
                                href={`tel:${coach?.coachphonenumber}`}
                                className="icon-box ml-0"
                              >
                                <img src={phonenoImage} alt="Phone no" />
                              </a>
                            ) : null}
                            {coach?.coachtwitterid ? (
                              <a
                                target={"_blank"}
                                href={`https://twitter.com/${coach?.coachtwitterid}`}
                                className="icon-box ml-0"
                              >
                                <img src={twitterImg} alt="Twitter" />
                              </a>
                            ) : null}
                            {coach?.coachinstaid ? (
                              <a
                                target={"_blank"}
                                href={`https://www.instagram.com/${coach?.coachinstaid}`}
                                className="icon-box ml-0"
                              >
                                <img src={instagramImg} alt="Instagram" />
                              </a>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {/*<div className="recruiting-detail-box">*/}
                      {/*    <div className="item-box">*/}
                      {/*        <h4>Recent Activity</h4>*/}
                      {/*        <div className="recent-activity-info">*/}
                      {/*            {lastActivityOfCollegeCoach(coach?.id)}*/}

                      {/*        </div>*/}
                      {/*    </div>*/}
                      {/*    {coach?.active == 1 ? (*/}
                      {/*        <div className="item-box">*/}
                      {/*            <h4>Coaches Request</h4>*/}
                      {/*            <p>*/}
                      {/*                {coach?.coachrequest || "N/A"}*/}
                      {/*            </p>*/}
                      {/*        </div>*/}
                      {/*    ) : null}*/}

                      {/*    {coach?.active == 1 ? (*/}
                      {/*        <div className="item-box">*/}
                      {/*            <h4>Roster Openings</h4>*/}
                      {/*            <p>*/}
                      {/*                {coach?.coachopening || "N/A"}*/}
                      {/*            </p>*/}
                      {/*        </div>*/}
                      {/*    ) : null}*/}
                      {/*</div>*/}
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      </div>
      <div className="back-button text-right pt-2">
        <Link className="btn green" to="/colleges/search">
          Back
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allCoachDetails: state.athlete.editUserProfileData.allCoachDetails,
    collegeActivity: state.athlete.dashboard.colleges.collegeActivity,
    followersList: state.athlete.dashboard.home.followersList,
    userProfileView: state.athlete.dashboard.colleges.userprofileView,
    searchCollege: state.athlete.dashboard.colleges.searchCollege,
    allMemberships: state.athlete.account.allMemberships,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllCoachDetailsStart: (data) => dispatch(getAllCoachDetailsStart(data)),
    collegeActivityListStart: (data) =>
      dispatch(collegeActivityListStart(data)),
    getFollowersListStart: (data) => dispatch(getFollowersListStart(data)),
    searchCollegeCountListStart: (data) =>
      dispatch(searchCollegeCountListStart(data)),
    userProfileViewListStart: (data) =>
      dispatch(userProfileViewListStart(data)),
    membershipListStart: (data) => dispatch(membershipListStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Recruiting);
