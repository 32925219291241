import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";

import "../welcomeScreenMessage/assets/css/weclomeScreenModal.scss";
import Slide1 from "./assets/img/slide1.svg";
import Slide2 from "./assets/img/slide2.svg";
import Slide3 from "./assets/img/slide3.svg";
import Slide4 from "./assets/img/slide4.svg";
import learn from "./assets/img/learn.svg";
import find from "./assets/img/find.svg";
import alert from "./assets/img/alert.svg";
import progress from "./assets/img/progress.svg";
import history from "../../../../../services/history";

const WelcomeScreenCarousel = () => {
  const [isModal, setModal] = useState(true);
  const handleCloseModal = () => setModal(false);

  return (
    <>
      {" "}
      {isModal && (
        <OwlCarousel
          className="owl-carousel owl-theme "
          loop
          margin={1}
          nav
          items="1"
        >
          <div className="item">
            <div className="title">Let's Supercharge Your Recruiting</div>
            <p>
              The dashboard is your starting point. <br />
              It's where you’ll find the SportsForce tools and resources that
              will help you on your recruiting journey
            </p>
            <div className="graphics">
              <img src={Slide1} alt="Let's Supercharge Your Recruiting" />
            </div>
          </div>
          <div className="item">
            <div className="title">Build Your Profile</div>
            <p>
              To get noticed by college coaches on the world's largest
              recruiting network, add more to your profile.
            </p>
            <div className="graphics">
              <img src={Slide2} alt="Build Your Profile" />
            </div>
          </div>
          <div className="item">
            <div className="title">Discover Colleges</div>
            <p>You'll see colleges with recent coach activity.</p>
            <p>
              To see coach rosters, academic and financial info and more, select
              a college. To Favorite a college and save it for future action,
              select its star.
            </p>
            <div className="graphics">
              <img src={Slide3} alt="Discover Colleges" />
            </div>
          </div>
          <div className="item">
            <div className="title">Get Inspired</div>
            <p>
              You'll see athletes in your sport who recently moved their
              recruiting forward.
            </p>
            <p>
              Check out their profiles to find videos and stats. Get inspired
              for what to include in your own profile.
            </p>
            <div className="graphics">
              <img src={Slide4} alt="Get Inspired" />
            </div>
          </div>
          <div className="item">
            <div className="title ">More Tools to Help You Succeed</div>
            <ul>
              <li>
                <span>
                  <img src={learn} alt="Learn about recruiting" />
                </span>
                Learn about recruiting
              </li>
              <li>
                <span>
                  <img src={find} alt="Find other colleges to research" />
                </span>
                Find other colleges to research
              </li>
              <li>
                <span>
                  <img src={progress} alt="Track progress with each college" />
                </span>
                Track progress with each college
              </li>
              <li>
                <span>
                  <img src={alert} alt="Get alerts about camps" />
                </span>
                Get alerts about camps
              </li>
              <button
              className="btn btn-secondary"
              onClick={() => history.push("/dashboard")}
            >
              Let's Go!
            </button>
            </ul>
          </div>
        </OwlCarousel>
      )}
    </>
  );
};

export default WelcomeScreenCarousel;
