import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    GET_PROFILE_TIMELINE_REPORT_START: "GET_PROFILE_TIMELINE_REPORT_START",
    GET_PROFILE_TIMELINE_REPORT_SUCCESS: "GET_PROFILE_TIMELINE_REPORT_SUCCESS",
    GET_PROFILE_TIMELINE_REPORT_FAIL: "GET_PROFILE_TIMELINE_REPORT_FAIL",
};

// ============================================ Actions ========================================================
export const profileTimelineReportStart = (data) => ({
    type: actionType.GET_PROFILE_TIMELINE_REPORT_START,
    payload: data,
});

export const profileTimelineReportFail = (data) => ({
    type: actionType.GET_PROFILE_TIMELINE_REPORT_FAIL,
    payload: data,
});

export const profileTimelineReportSuccess = (data) => ({
    type: actionType.GET_PROFILE_TIMELINE_REPORT_SUCCESS,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function profileTimelineReportReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.GET_PROFILE_TIMELINE_REPORT_START:
            return {
                ...state,
                loading: true,
            };

        case actionType.GET_PROFILE_TIMELINE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };

        case actionType.GET_PROFILE_TIMELINE_REPORT_FAIL:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };

        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* profileTimelineReportSaga(action) {
    try {
        const response = yield fetchMethod(
            `/api/Evaluationreports/getEvaluationReport`,
            action.payload,
            "post",
            action?.payload?.isAccessToken
        );
        if (response?.status === 200 && response?.data?.response?.data?.length > 0) {
            yield put(
                profileTimelineReportSuccess({
                    profileTimelineAbout: response.data.response,
                })
            );
        }
        else if(response?.status === 200 && response?.data?.response?.data?.length === 0){
            yield put(profileTimelineReportFail("No data found"));
        }
        else {
            messagePopup("", "Profile Report failed", "error");
            yield put(profileTimelineReportFail("Profile Timeline Report failed"));
        }
    } catch (error) {
        messagePopup("", "Profile Timeline Report failed", "error");
        yield put(profileTimelineReportFail("Profile Timeline Report failed"));
    }
}

//Watcher
export function* watchProfileTimelineReportSaga() {
    yield takeEvery(actionType.GET_PROFILE_TIMELINE_REPORT_START, profileTimelineReportSaga);
}
