import React, { useState, useEffect, useRef } from "react";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardbaseball.scss";
import "../assets/css/onBoardSteeper.scss";
import { Link } from "react-router-dom";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { connect } from "react-redux";
import BackdropLoader from "../../../../common/Loader";
import { getOnboardingQuery, saveOnboardingQuery } from "../OnboardingQuery";
import { getUserInfo } from "../../../../../services/authService";
import usePrevious from "../../../../../utils/usePrevious";
import Stepper from "../Stepper";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import { Dialog } from "@mui/material";
import messagePopup from "../../../../../utils/messagePopup";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "passed", link: "/onboarding/positions" },
  { className: "passed", link: "/onboarding/physicalMeasurements" },
  { className: "passed", link: "/onboarding/personalRecords" },
  { className: "passed", link: "/onboarding/schoolInfo" },
  { className: "passed", link: "/onboarding/academics" },
  { className: "passed", link: "/onboarding/recruiting" },
  { className: "passed", link: "/onboarding/recruitingprocess" },
  { className: "passed", link: "/onboarding/instagram" },
  // { className: "active", link: "/onboarding/baseballLevel" },
  { className: "" },
  { className: "" },
];

const checkboxData = [
  { name: "Pre High School", key: 1 },
  { name: "High School", key: 2 },
  { name: "Post Graduate", key: 3 },
  { name: "Junior College", key: 4 },
  { name: "4 Year College", key: 5 },
  { name: "NCAA Portal", key: 6 },
  { name: "Olympic", key: 7 },
  { name: "Professional-MLB", key: 8 },
  { name: "Professional-INDY", key: 9 },
  { name: "Unsigned Free Agent", key: 10 },
  { name: "NA", key: 11 },
];
const BaseLevel = (props) => {
  const [fields, setFields] = useState({});

  const checkInitialCheck = (grade) => {
    if (grade) {
      if (grade >= 6 && grade <= 14) {
        return 1;
      }
      if (grade < 6 && grade >= 1) {
        return 2;
      }
      if (grade === 15) {
        return 3;
      }
    } else {
      return null;//earlier it was 1 and Pre High School is getting selected by default
    }
  };
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    props.history.push("/onboarding/firstYearVarsity");
  };
  const fakeInput = useRef(null);
  useEffect(() => {
    setFields({});
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = { ...fields };
        const { id, currentlevelofbaseball, gradvalue } = props.onboarding.data;
        fieldT["id"] = id;
        fieldT["selectedData"] = currentlevelofbaseball
          ? currentlevelofbaseball
          : checkInitialCheck(gradvalue);
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
    fakeInput.current.focus();
    fakeInput.current.select();
  }, [props]);

  const handleCheckChieldElement = (e, data) => {
    let fieldsT = fields;
    if (e.target.checked) {
      fieldsT["selectedData"] = data;
    }
    setFields({ ...fieldsT });
  };

  const handleSubmit = () => {
    if (fields.selectedData) {
      props.saveOnboardingStart({
        query: saveOnboardingQuery,
        variables: {
          obj: {
            id: fields.id ? fields.id : 0,
            userid: getUserInfo().id,
            currentlevelofbaseball: fields.selectedData
              ? fields.selectedData
              : null,
          },
        },
        nextScreen: routeNextPage,
      });
    } else {
      messagePopup("", "Please select current level of baseball.", "error");
    }
  };
  return (
    <div>
      <BackdropLoader open={props.onboarding.loading} />
      <input className="fakeInput" type="text" ref={fakeInput} />
      <div className="onBoardBaseballSection">
        <div className="baseBallBlock">
          <div className="stepper">
            <Stepper stepperName="baselevel" stepperData={stepperData} />
          </div>
          <div className="formSection">
            <h1 className="heading">
              What is your current level of baseball?{" "}
            </h1>
            <div className="recruitProcessBox">
              {checkboxData.map((value, index) => {
                return (
                  <ul key={index}>
                    <li>
                      <span>
                        <input
                          type="checkbox"
                          id="baseballLevel"
                          checked={fields.selectedData === value.key}
                          onChange={(e) =>
                            handleCheckChieldElement(e, value.key)
                          }
                        />
                        <label htmlFor="baseBallLevel">{value.name}</label>
                      </span>
                    </li>
                  </ul>
                );
              })}
            </div>
            <p className="skip">
              Don't know?{" "}
              <span className="sikpBtnBox">
                <Link className="skipit-btn" to="#" onClick={handleClickOpen}>
                  Skip it
                </Link>
              </span>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="#" onClick={(e) => handleSubmit(e)}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    onboarding: state.athlete.onBoarding.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseLevel);
