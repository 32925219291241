import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
import history from "../../../services/history";
import { setAuthToken, setUserInfo } from "../../../services/authService";
import toastr from 'toastr'
// ========================================== Action Type =====================================================
export const actionType = {
  LOGIN_START: "LOGIN_START",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
};

// ============================================ Actions ========================================================
export const loginStart = (data) => ({
  type: actionType.LOGIN_START,
  payload: data,
});

export const loginSuccess = (data) => ({
  type: actionType.LOGIN_SUCCESS,
  payload: data,
});

export const loginFailed = (data) => ({
  type: actionType.LOGIN_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function loginReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.LOGIN_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// =========================================== side effect =================================================

export function* loginSaga(action) {
  try {
    const response = yield fetchMethod(
      `/login?email=${action.payload.email}&password=${action.payload.password?.includes("#") ?
          action?.payload?.password?.replace(/#/g, "%23") : action.payload.password}`,
        {email : action.payload.email, password: action.payload.password},
      "post",
      false
    );
    if (response && response.status === 200){
      if (
        response.data &&
        response.data.statusCode &&
        response.data.statusCode === 401
      ) {
        messagePopup("", !!response?.data?.message ?
            response?.data?.message :
            'Invalid Email ID or Password', "error");
        yield put(loginFailed(!!response?.data?.message ?
            response?.data?.message :
            'Invalid Email ID or Password'));
      }
      //this check is for athlete advisor login restrict
      if(!!response?.data?.length > 0 && (response?.data?.[0]?.role == "ADMIN" || response?.data?.[0]?.role == "ADVISORS" )){
        messagePopup('',"Access Denied",'error')
        yield put(loginFailed('Access Denied'));
      }
      if (response.data && response.data.token) {
        if(response.data.role === "ATHLETES"){
          if (response.data.isonboardingdone == 0 && response.data.ispasswordStatus == 0) {
            yield put(loginSuccess(response.data));
            // history.push(`/forgotpassword`)
            localStorage?.setItem('isVerified', true)//condition is for forgot password email header
            sessionStorage.setItem("ispasswordStatus", response.data.ispasswordStatus);
            history.push(`/changePassword/${action.payload.email}`);
            localStorage.removeItem("claimProfileKey")
          }
          else if(response.data.isonboardingdone == 0 && response?.data?.ispasswordStatus == 1){
            setUserInfo(response.data);
            setAuthToken(response.data.token);
            yield put(loginSuccess(response.data));
            history.push("/onboarding/profile");
          }
          else if(response.data.isonboardingdone == 1 && response?.data?.ispasswordStatus == 0){
            yield put(loginSuccess(response.data));
            sessionStorage.setItem("ispasswordStatus", response.data.ispasswordStatus);
            localStorage?.setItem('isVerified', true)//condition is for forgot password email header
            // history.push("/forgotpassword")
            history.push(`/changePassword/${action.payload.email}`);
          }
          else {
            //code commented task jira 226
            // toastr.success("Login Successfully","Success",{
            //   progressBar : true,timeOut: 2000,
            //   closeButton: true, positionClass: "toast-top-full-width",
            //   showEasing: "swing", fadeIn: 40000,
            // })
            localStorage.removeItem("claimProfileKey")
            setUserInfo(response.data);
            setAuthToken(response.data.token);
            yield put(loginSuccess(response.data));
            setTimeout(()=>{
              history.push("/dashboard");
            },500)
          }
        }
        else if (response.data.role == "COLLEGECOUCHES"){
          messagePopup('',"Access Denied",'error')
          yield put(loginFailed('Access Denied'));
        }
        else{
          if(response.data.ispasswordStatus == 0){
            yield put(loginSuccess(response.data));
            // history.push(`/forgotpassword`)
            localStorage?.setItem('isVerified', true)//condition is for forgot password email header
            sessionStorage.setItem("ispasswordStatus", response.data.ispasswordStatus);
            history.push(`/changePassword/${action.payload.email}`);
            localStorage.removeItem("claimProfileKey")
          }
          else{
          // below code is commented as per jira task 225
          // toastr.success("Login Successfully","Success",{
          //   progressBar : true,timeOut: 5000,
          //   closeButton: true, positionClass: "toast-top-full-width",
          //   showEasing: "swing", fadeIn: 40000,
          // })
          localStorage.removeItem("claimProfileKey")
          setUserInfo(response.data);
          setAuthToken(response.data.token);
          yield put(loginSuccess(response.data));
          history.push("/dashboard");
        }
        }
      }
      else{
        if(response.data.ispasswordStatus == 0){
          yield put(loginSuccess(response.data));
          // history.push(`/forgotpassword`)
          localStorage?.setItem('isVerified', true)//condition is for forgot password email header
          sessionStorage.setItem("ispasswordStatus", response.data.ispasswordStatus);
          history.push(`/changePassword/${action.payload.email}`);
          localStorage.removeItem("claimProfileKey")
        }
        //this else condition is commented as it is a bugs when athlete entered the wrong credential
        // than also it is getting login
        // else{
        //   toastr.success("Login Successfully","Success",{
        //     progressBar : true,timeOut: 5000,
        //     closeButton: true, positionClass: "toast-top-full-width",
        //     showEasing: "swing", fadeIn: 40000,
        //   })
        //   localStorage.removeItem("claimProfileKey")
        //   setUserInfo(response.data);
        //   setAuthToken(response.data.token);
        //   yield put(loginSuccess(response.data));
        //   history.push("/dashboard");
        // }
      }
    } else {
      messagePopup("", "Login failed", "error");
      yield put(loginFailed("Login failed"));
    }
  } catch (error) {
    messagePopup("", "Login failed", "error");
    yield put(loginFailed("Login failed"));
  }
}

export function* watchLoginSaga() {
  yield takeEvery(actionType.LOGIN_START, loginSaga);
}
