import { combineReducers } from "redux";
import footListReducer from "./footList";
import gpaListReducer from "./gpaList";
import inchListReducer from "./inchList";
import gradeListReducer from "./gradeList";
import primaryListReducer from "./primaryPositionList";
import secondaryListReducer from "./secondaryPositionList";
import onboardingReducer from "./onboarding";
import coachTypeListReducer from "./coachTypeList";
import battingPreferenceListReducer from "./battingPreferences";
import throwingPreferenceListReducer from "./throwingPreferences";
import schoolCurrentLevelListReducer from "./schoolCurrentLevel";
import coachDetailsReducer from "./coachDetails";
import getPositionalFieldsListReducer from "./positionmappingWithPersonalRecord";
import heightWeightReducer from "./heightWeight";
import gpaMappingReducer from "./gpaMapping";
import athleteHeightWeightReducer from "./athleteheightandweights";
import athleteParentMappingReducer from "./athleteParentMapping";
import statLinkReducer from "./statLinks";

export default combineReducers({
  primaryPositionList: primaryListReducer,
  secondaryPositionList: secondaryListReducer,
  grades: gradeListReducer,
  gpaList: gpaListReducer,
  inchList: inchListReducer,
  footList: footListReducer,
  onboarding: onboardingReducer,
  coachTypeList: coachTypeListReducer,
  battingPreferences: battingPreferenceListReducer,
  throwingPreferences: throwingPreferenceListReducer,
  schoolCurrentLevel: schoolCurrentLevelListReducer,
  coachDetails: coachDetailsReducer,
  positionalFieldsList: getPositionalFieldsListReducer,
  heightWeight: heightWeightReducer,
  gpaMapping: gpaMappingReducer,
  athleteHeightWeight: athleteHeightWeightReducer,
  athleteParentMapping: athleteParentMappingReducer,
  statLinks: statLinkReducer,
});
