import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod } from "../../../services/httpService"
import messagePopup from "../../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    INCH_LIST_START: 'INCH_LIST_START',
    INCH_LIST_SUCCESS: 'INCH_LIST_SUCCESS',
    INCH_LIST_FAILED: 'INCH_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const inchListStart = (data) => ({
   type: actionType.INCH_LIST_START,
   payload: data
})

export const inchListSuccess = (data) => ({
    type: actionType.INCH_LIST_SUCCESS,
    payload: data
})

export const inchListFailed = (data) => ({
    type: actionType.INCH_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function inchListReducer(state = INIT_STATE,action){
  switch(action.type){
      case actionType.INCH_LIST_START: 
        return {
            ...state,
            loading: true
        }
      case actionType.INCH_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            data: action.payload,
            error: null
        }  
      case actionType.INCH_LIST_FAILED:
        return {
            ...state,
            loading: false,
            data: null,
            error: action.payload
        }       
      default: return {...state}  
  }
} 

// ===================================================== side effect =============================================

export function* inchListSaga(action){
   try {
    const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true);
    if(response && response.status === 200){
       const allInch = response.data.data.allHeightininches.Heightininches ? response.data.data.allHeightininches.Heightininches : null
       yield put(inchListSuccess(allInch));
    } else {
      messagePopup('','INCH List failed','error')   
      yield put(inchListFailed('INCH List failed')) 
    }
   } catch (error) {
     messagePopup('','INCH List failed','error')   
     yield put(inchListFailed('INCH List failed')) 
   }
}

export function* watchInchListSaga(){
    yield takeEvery(actionType.INCH_LIST_START, inchListSaga)
}