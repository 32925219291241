export const getAllGPAScalesQuery = `
  query allGpascales{
    allGpascales{
        Gpascales{
           id
           gpascalevalue
        }
    }
  } 
  `;

  
  export const getAllGradeQuery = `
  query{
    allGrades{
      Grades{
        id
        gradevalue
        ishighschool
      }
    }
  }
  `;