export const getAllReferenceDataQuery = `
query AllReferences($obj:JSON){
  allReferences(where:$obj){
    References{
      id
      active
      isaccessallowed
      coachid
      reffirstname
      twittername
      reflastname
      refphone
      refemailid
      team
      notes
      refdocname
      refcountryid
      referencereftypemaprel{
        Coachtypes{
          id
          coachtypename
        }
      }
    }
  }
}
`;

export const saveAllReferenceDataQuery = `
 mutation saveReference ($obj : ReferenceInput!){
         saveReference (obj :$obj)
         {
            id
            active
            isaccessallowed
            reffirstname
            reflastname
            refdocname
            refphone
            twittername
            team 
            notes
            refemailid
            refcountryid
            referencereftypemaprel{
          Coachtypes{
                id
                coachtypename
              }
            }
          }
        }
`;

export const getTeamId = `
query AllTeams($obj:JSON){
  allTeams(where:$obj) {
    Teams {
      id
      teamname
			active
    }
  }
}
`;

export const saveTeam = `
mutation saveTeam($obj:TeamInput!){
  saveTeam(obj:$obj){
    id
  }
}
`;

export const filterFirstNameQuery = `
query AllReferences($where:JSON){
  allReferences(where:$where){
    References{
      id
      reffirstname
    }
  }
}
`;

export const filterLastNameQuery = `
query AllReferences($where:JSON){
  allReferences(where:$where){
    References{
      id
      reflastname
    }
  }
}
`;

export const filterTeamQuery = `
query AllReferences($where:JSON){
  allReferences(where:$where){
    References{
      id
      team
    }
  }
}
`;

export const saveCoachDetailsQuery = `
mutation saveCoachandteamdetails($obj: CoachandteamdetailsInput!){
  saveCoachandteamdetails(obj: $obj){
    id
    userid
    coachemail
    coachfirstname
    coachlastname
    coachphonenumber
    coachtypeid
    typeofprimaryteam
    teamname
    wanttoconnectschoolcoachtosportforcecoach
    coachcountrycode
    onboardingid
  }
} 
`;