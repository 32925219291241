import React, { useEffect, useState, useRef } from "react";
import {
  AddVideoPhoto, getPostId,
  PhotoBox,
  SideBarVideo,
  SubHeader,
  TimelinePostOverlay,
  Title,
  VideoOverlay,
} from "./Component";
import GoTopButton from "../GoTopButton";
import PlusRoundIcon from "../assets/img/plus-round-icon.svg";
import LeftSideEditProfile from "../LeftSideEditProfile";
import Dialog from "@mui/material/Dialog";
import SharePost from "../../homepage/posts/SharePost";
import { EditVideoPhotoOverlay } from "./VideoPhotoOverlay";
import {
  getAllVideoPhotoInfoQuery,
  getPrivatePublicStatusQuery,
  saveAllVideoPhotoInfoQuery,
} from "./VideoPhotoQuery";
import {
  getAuthToken,
  getUserInfo,
} from "../../../../../../services/authService";
import { allVideoPhotoListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/getVideoPhotoInfo";
import { saveVideoPhotoStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/saveVideoPhoto";
import { connect } from "react-redux";
import usePrevious from "../../../../../../utils/usePrevious";
import axios from "axios";
import { imageDownloadUrl, imageUploadUrl } from "../../../../../../config";
import { messages } from './constants';
import toastr from "toastr";
import BackdropLoader from "../../../../../common/Loader";
import history from "../../../../../../services/history";
import {
  updateVideoPhotoInfoStart,
  updateVisibleStatusStart,
} from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/saveVideoPhotoInfo";
import { deleteVideoPhotoStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/deleteVideoPhoto";
import { ReactSortable } from "react-sortablejs";
import { fetchMethod } from "../../../../../../services/httpService";
import fileDownload from "js-file-download";
import { updatePostsQuery } from "../../homepage/posts/PostsQuery";
import ReactTooltip from "react-tooltip";
import { getAllAlbumsTags } from "./AlbumTagsQuery";
import { allAlbumTagsListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/allAlbumTags";
import { clearPostSuccess } from "../../../../../../redux/athlete/dashboard/homepage/savePost";
import { athletesDetailsStart } from "../../../../../../redux/common/athletesDetails";
import { getAllAthletesDataQuery } from "../../../../../common/CommonQuery";
import {
  getPubliPrivateStatusStart,
  savePubliPrivateStatusStart,
} from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/publicPrivateStatus";
import DeleteVideoPhotoPopup from "./DeleteVideoPhotoPopup";
import swal from "sweetalert";
const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
const EditSection = (props) => {
  const { handleModal } = props;
  const [photoList, setPhotoList] = React.useState(null);
  const [gridLayout, setGridLayout] = useState(false),
    [tab, setTab] = React.useState(true),
    [tab2, setTab2] = React.useState(false),
    [tab3, setTab3] = React.useState(false);
  // const [videoCount, setVideoCount] = useState(0);
  // const [photoCount, setPhotoCount] = useState(0);
  let count = 0;
  const [videoPhotoSettingIcon, setVideoPhotoSettingIcon] = React.useState({});

  const handleSettingDropDown = (index) => {
    const tempData = {};
    tempData[index] = !videoPhotoSettingIcon[index];
    setVideoPhotoSettingIcon(tempData);
  };

  const onTabClick = () => {
    setTab(true);
    setTab2(false);
    setTab3(false);
  };
  const onTabClick2 = () => {
    setTab(false);
    setTab2(true);
    setTab3(false);
  };
  const onTabClick3 = () => {
    setTab(false);
    setTab2(false);
    setTab3(true);
  };
  useEffect(() => {
    if (props.photoList) {
      const finalData = [...props.photoList];
      setPhotoList(finalData);
    }
  }, [props.photoList]);
  const onGridClick = () => {
    setGridLayout(!gridLayout);
  };

  const onFileUploadChnage = async (event) => {
    let filesList = [];
    for (const file of event.target.files) {
      let fileExtension = file.name
        .split(".")
        [file.name.split(".").length - 1]?.toLowerCase();
      //earlier below code is use which not excepting certian file
      // let fileExtension = file.name.split(".")[1];

      if (
        fileExtension == "jpg" ||
        fileExtension == "png" ||
        fileExtension == "jpeg"
      ) {
        const fileType = file.type
          ? file.type.includes("video")
            ? 1
            : file.type.includes("image")
            ? 0
            : null
          : null;
        const fileArr = file.name.split(".");
        if (count > 25) count = 0;
        fileArr[0] = `${new Date().getTime()}` + alphabet[count++];
        const newFile = new File([file], fileArr.join("."), {
          type: file.type,
          lastModified: file.lastModified,
        });
        filesList = [
          ...filesList,
          {
            file: newFile,
            type: fileType,
            originalname: file.name.split(".")[0],
            fileUrl: URL.createObjectURL(newFile),
          },
        ];
      } else {
        // messagePopup("", "Photo format must be JPEG, JPG or PNG", "error");
        toastr.error("Photo format must be JPEG, JPG or PNG", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      }
    }
    props.showDataTemporary(filesList);
  };
  // React.useEffect(() => {
  //   if (props.totalData) {
  //     const videoCount = props.totalData.filter(
  //       (item) => item.isphotosorvideoslik !== 0
  //     ).length;
  //     const photoCount = props.totalData.filter(
  //       (item) => item.isphotosorvideoslik === 0
  //     ).length;
  //     // const photoTotal = props.profilePhotoCount
  //     //     ? props.profilePhotoCount + photoCount
  //     //     : photoCount;
  //     setVideoCount(videoCount);
  //     setPhotoCount(photoCount);
  //     //earlier on above func it was photoCount+1 which not correct
  //   }
  // }, [props.totalData]);

  const handleClearFilter = () => {
    history.push("/videoPhotos");
  };

  const handleListChange = (items) => {
    let newList = items.map((item, index) => {
      item.uploadedrank = index + 1;
      return item;
    });
    setPhotoList([...newList]);
  };

  const handleOnChoose = (data) => {
    const { oldIndex, newIndex } = data;
    let start = oldIndex > newIndex ? newIndex : oldIndex;
    let end = oldIndex < newIndex ? newIndex : oldIndex;
    const updatedCollegeRank = photoList.slice(start, end + 1).map((item) => {
      return { collegeId: item.id, rank: item.uploadedrank };
    });
    updatePhotoRank({
      userId: getUserInfo() ? getUserInfo().id : 0,
      tableName: "uploadedvideoandphoto",
      updatedCollegeRank,
    });
    setPhotoList([...photoList]);
  };
  const updatePhotoRank = async (data) => {
    try {
      const response = await fetchMethod(
        `/api/Usercolleges/changeRankOfCollege`,
        data,
        "post",
        true
      );
      if (response && response.status === 200) {
        if (
          response.data &&
          response.data.response &&
          response.data.response.status === 200
        ) {
        }
      } else {
        // messagePopup("", "Rank update failed", "error");
        toastr.error("Rank update failed", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
        setPhotoList(props.photoList);
      }
    } catch (error) {
      // messagePopup("", "Rank update failed", "error");
      toastr.error("Rank update failed", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
      setPhotoList(props.photoList);
    }
  };
  return (
    <div className="editSection">
      <Title title="Videos & Photos" />
      <ReactTooltip />
      <div className="check-list-sec">
        <div className="video-and-photo-wrap">
          <div className="video-share-box">
            {/* <button className="btn share-btn" onClick={handleModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10.689"
                height="11.803"
                viewBox="0 0 10.689 11.803"
              >
                <path
                  d="M13.4,11.457a1.623,1.623,0,0,0-1.161.445L7.984,9.44a1.994,1.994,0,0,0,.06-.415,1.993,1.993,0,0,0-.06-.415l4.2-2.432a1.782,1.782,0,1,0-.566-1.3,1.982,1.982,0,0,0,.06.415L7.478,7.72a1.792,1.792,0,0,0-1.221-.474A1.763,1.763,0,0,0,4.5,9.025a1.793,1.793,0,0,0,3.007,1.3l4.228,2.461a1.488,1.488,0,0,0-.06.385,1.727,1.727,0,1,0,1.727-1.72Z"
                  transform="translate(-4.5 -3.094)"
                />
              </svg>
              Share
            </button> */}
          </div>
          <div className="video-and-photo-main">
            <SideBarVideo
              photoCount={props.counts?.photoCount || 0}
              videoCount={props.counts?.videoCount || 0}
            />
            <div className="content-box">
              <div className="video-top-block">
                <div className="video-links full">
                  <div className="half">
                    <AddVideoPhoto
                      icon={PlusRoundIcon}
                      fileType="photo"
                      onFileUploadChnage={onFileUploadChnage}
                      text={"Add Photos"}
                    />
                  </div>
                </div>
                <div className="video-help-text">
                  <svg
                    id="question-circle-fill"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    data-tip="Tool tip text"
                  >
                    <path
                      id="Path_63060"
                      data-name="Path 63060"
                      d="M36,18A18,18,0,1,1,18,0,18,18,0,0,1,36,18ZM14.783,13.574h-2.97c-.068-4.243,3.217-5.7,6.2-5.7,3.143,0,6.014,1.643,6.014,5.04,0,2.43-1.429,3.587-2.8,4.628-1.658,1.258-2.272,1.728-2.272,3.344v.8H16.013L16,20.644c-.085-2.086,1.114-3.37,2.628-4.471,1.327-1,2.171-1.656,2.171-3.085,0-1.856-1.413-2.628-2.957-2.628a2.871,2.871,0,0,0-3.056,3.114Zm2.815,14.5a2.107,2.107,0,1,1,0-4.2,2.11,2.11,0,1,1,0,4.2Z"
                      fillRule="evenodd"
                    />
                  </svg>
                  <span
                      style={{cursor:"pointer", color:"#2177D6"}}
                      onClick={()=>window?.open('https://general-faqs.helpscoutdocs.com/','_blank')}
                  >I need help with my photos</span>
                </div>
              </div>
              <div className="video-second-block">
                <div className="top-block">
                  <ul className="tabs">
                    <li
                      className={`${tab && "current"}`}
                      onClick={() => onTabClick()}
                    >
                      My Photos
                    </li>
                    {/*<li*/}
                    {/*  className={`${tab2 && "current"}`}*/}
                    {/*  onClick={() => onTabClick2()}*/}
                    {/*>*/}
                    {/*  Purchased*/}
                    {/*</li>*/}
                    {/*<li*/}
                    {/*  className={`${tab3 && "current"}`}*/}
                    {/*  onClick={() => onTabClick3()}*/}
                    {/*>*/}
                    {/*  Available*/}
                    {/*</li>*/}
                  </ul>
                  <ul className="video-grid-control">
                    <li
                      className="grid-type-list"
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        className={!gridLayout ? "active" : "inActive"}
                        onClick={onGridClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.43"
                          height="12.43"
                          viewBox="0 0 12.43 12.43"
                        >
                          <defs></defs>
                          <g transform="translate(-2.25 -2.25)">
                            <path
                              className="a"
                              d="M7.022,8.021H3.249a1,1,0,0,1-1-1V3.249a1,1,0,0,1,1-1H7.022a1,1,0,0,1,1,1V7.022A1,1,0,0,1,7.022,8.021Z"
                            ></path>
                            <path
                              className="a"
                              d="M23.9,8.021H20.124a1,1,0,0,1-1-1V3.249a1,1,0,0,1,1-1H23.9a1,1,0,0,1,1,1V7.022A1,1,0,0,1,23.9,8.021Z"
                              transform="translate(-10.216)"
                            ></path>
                            <path
                              className="a"
                              d="M7.022,24.9H3.249a1,1,0,0,1-1-1V20.124a1,1,0,0,1,1-1H7.022a1,1,0,0,1,1,1V23.9A1,1,0,0,1,7.022,24.9Z"
                              transform="translate(0 -10.216)"
                            ></path>
                            <path
                              className="a"
                              d="M23.9,24.9H20.124a1,1,0,0,1-1-1V20.124a1,1,0,0,1,1-1H23.9a1,1,0,0,1,1,1V23.9A1,1,0,0,1,23.9,24.9Z"
                              transform="translate(-10.216 -10.216)"
                            ></path>
                          </g>
                        </svg>
                        <span>Grid</span>
                      </a>
                    </li>
                    <li
                      className="list-type-list"
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        className={gridLayout ? "active" : "inActive"}
                        onClick={onGridClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.465"
                          height="12.465"
                          viewBox="0 0 12.465 12.465"
                        >
                          <defs></defs>
                          <path
                            className="a"
                            d="M11.7,12.465H5.467a.743.743,0,0,1-.554-.231.758.758,0,0,1-.225-.548V10.128a.758.758,0,0,1,.225-.548.743.743,0,0,1,.554-.231H11.7a.79.79,0,0,1,.779.779v1.558a.743.743,0,0,1-.231.554A.758.758,0,0,1,11.7,12.465Zm0-4.674H5.467a.769.769,0,0,1-.779-.779V5.454a.769.769,0,0,1,.779-.779H11.7a.758.758,0,0,1,.548.225.743.743,0,0,1,.231.554V7.012a.743.743,0,0,1-.231.554.758.758,0,0,1-.548.225Zm0-4.674H5.467a.743.743,0,0,1-.554-.231.758.758,0,0,1-.225-.548V.779A.753.753,0,0,1,4.913.225.753.753,0,0,1,5.467,0H11.7a.758.758,0,0,1,.548.225.743.743,0,0,1,.231.554V2.337a.748.748,0,0,1-.231.548.748.748,0,0,1-.548.231ZM2.35,12.465H.792a.748.748,0,0,1-.548-.231.748.748,0,0,1-.231-.548V10.128A.748.748,0,0,1,.244,9.58a.748.748,0,0,1,.548-.231H2.35A.748.748,0,0,1,2.9,9.58a.748.748,0,0,1,.231.548v1.558a.769.769,0,0,1-.779.779Zm0-4.674H.792a.758.758,0,0,1-.548-.225.743.743,0,0,1-.231-.554V5.454A.743.743,0,0,1,.244,4.9a.758.758,0,0,1,.548-.225H2.35A.758.758,0,0,1,2.9,4.9a.743.743,0,0,1,.231.554V7.012a.769.769,0,0,1-.779.779Zm0-4.674H.792a.748.748,0,0,1-.548-.231.748.748,0,0,1-.231-.548V.779A.743.743,0,0,1,.244.225.758.758,0,0,1,.792,0H2.35A.758.758,0,0,1,2.9.225a.743.743,0,0,1,.231.554V2.337a.758.758,0,0,1-.225.548.743.743,0,0,1-.554.231Z"
                            transform="translate(-0.013)"
                          ></path>
                        </svg>
                        <span>List</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="video-tab-contents ">
                  <div id="tab1" className={`tab-content ${tab && "current"}`}>
                    <ul
                      id="sortable"
                      className={`video-grid-list clearfix ${
                        gridLayout && "list"
                      }`}
                    >
                      {photoList && photoList.length ? (
                        <ReactSortable
                          list={photoList}
                          setList={handleListChange}
                          onEnd={handleOnChoose}
                          handle=".handle"
                          draggable=".item"
                        >
                          {photoList
                            ? photoList.map((item, index) => {
                                return (
                                  <PhotoBox
                                    key={index}
                                    {...props}
                                    data={item}
                                    //videoPhotoLists={photoList}
                                    settingIconStatus={
                                      videoPhotoSettingIcon[index]
                                    }
                                    videoPhotoLists={photoList}
                                    handleSettingDropDown={() =>
                                      handleSettingDropDown(index)
                                    }
                                    index={index}
                                  />
                                );
                              })
                            : null}
                        </ReactSortable>
                      ) : (
                        ""
                      )}
                      <div className="clear-filter-box">
                        <button
                          className="btn clear-filters-button"
                          onClick={handleClearFilter}
                        >
                          Clear All Filters
                        </button>
                      </div>
                    </ul>
                  </div>
                  <div id="tab2" className={`tab-content ${tab2 && "current"}`}>
                    <ul
                      id="sortable"
                      className={`video-grid-list clearfix ${
                        gridLayout && "list"
                      }`}
                    >
                      {/*<PhotoBox {...props} />*/}
                    </ul>
                  </div>
                  <div id="tab3" className={`tab-content ${tab3 && "current"}`}>
                    <ul
                      id="sortable"
                      className={`video-grid-list clearfix ${
                        gridLayout && "list"
                      }`}
                    >
                      {/*<PhotoBox {...props} />*/}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PhotoPage = (props) => {
  const [sideMenuOpen, setSideMenu] = useState(false),
    [showModal, setShowModal] = useState(false),
    [showLinkModal, setShowLinkModal] = useState(false),
    [showTimeLine, setShowTimeLine] = useState(false),
    [editVideoModal, setEditVideoModal] = useState(false),
    [videoOpen, setVideoOpen] = React.useState(false),
    [videoPhotData, setVideoPhotData] = React.useState(null),
    [loading, setLoading] = useState(false),
    // [totalData, setTotalData] = React.useState(null),
    [timelineData, setsetTimelineData] = useState(null),
    [removeIndex, setRemoveIndex] = useState(null),
    [photoList, setPhotoList] = React.useState(null);
  const [allAlbumList, setAllAlbumList] = useState([]);
  const [allTagsList, setAllTagsList] = useState([]);
  const [counts, setCounts] = useState(null);
  // const [profilePhotoCount, setProfilePhotoCount] = useState(0);
  const prevVideoPhotoData = usePrevious(props.allVideoPhotoInfo.data);
  const prevSavePhotoVideoData = usePrevious(props.addVideoPhoto.data);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [singleDataItem, setSingleDataItem] = useState([]);
  const fakeInput = useRef(null);
  //this state is for share modal
  const [modalData, setModalData] = useState(null);
  //this state is for repost share
  const [shareModalData, setShareModalData] = useState(null)
  const [isShareModal, setIsShareModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };
  const handleModal = (value) => {
      setModalData(value);
    setShowModal(!showModal);
  };
  const onLinkModal = () => {
    setShowLinkModal(!showLinkModal);
  };
  const onShowTimeLine = (data = null) => {
    if (data) {
      setShowTimeLine(true);
      setsetTimelineData(data);
    } else {
      setShowTimeLine(false);
    }
  };

  useEffect(() => {
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);
  const onEditVideo = (data, editValue = false) => {
    if (data) {
      const name =
        data.isphotosorvideoslik === 0
          ? `${imageDownloadUrl + "/" + data.photosname}`
          : data.isphotosorvideoslik === 1
          ? `${imageDownloadUrl + "/" + data.videoname}`
          : data.videolink;
      setEditVideoModal(true);
      setIsEdit(editValue)
      setVideoPhotData([
        {
          name,
          type: data.isphotosorvideoslik,
          action: "update",
          ...data,
        },
      ]);
    }
    // setEditVideoModal(!editVideoModal);
  };

  const onVideoOpen = () => {
    setVideoOpen(!videoOpen);
  };
  const onVideoClose = () => {
    setVideoOpen(false);
  };
  React.useEffect(() => {
    if (props.allVideoPhotoInfo.data) {
      let featuredList = [];
      let unfeaturedList = [];
      let photoCount = 0;
      let videoCount = 0;
      for (const data of props.allVideoPhotoInfo.data
        ?.allUploadedvideoandphotos) {
        if (data.isphotosorvideoslik === 0) {
          photoCount += 1;
          if (data.isfeatured) {
            featuredList = [...featuredList, { ...data }];
          } else {
            unfeaturedList = [...unfeaturedList, { ...data }];
          }
          // if (data.isprofileimage) {
          //   profilePicCount += 1;
          // }
        } else {
          videoCount += 1;
        }
      }

      let rankedItem = [];
      let nonRankedItem = [];
      for (const data of unfeaturedList) {
        if (data.uploadedrank) {
          rankedItem = [...rankedItem, { ...data }];
        } else {
          // if uploadedrank === null
          nonRankedItem = [...nonRankedItem, { ...data }];
        }
      }

      rankedItem = rankedItem.sort(
        //done b - a  for descending earlier it was a - b for ascending
        (a, b) => a.uploadedrank - b.uploadedrank
      );

      unfeaturedList = [...rankedItem, ...nonRankedItem];

      let actualProfile = {};
      if (props.athleteData.data) {
        const { onboardingData } = props.athleteData.data;
        actualProfile = {
          photosname: onboardingData?.profileimages
            ? onboardingData?.profileimages
            : null,
          actualProfilePhoto: true,
          videophotosuploadedtype: "Profile Photo",
          videolink: "null",
          videoname: "null",
          originalname: "Profile Photo",
          isprofileimage: 1,
          isphotosorvideoslik: 0,
          updatedon: onboardingData?.profileupdatedate ?? "" //key added as per bugs 14/10/2022 new key
        };
      }
      if (actualProfile?.photosname) {
        setPhotoList([...featuredList, actualProfile, ...unfeaturedList]);
        photoCount += 1;
      } else {
        setPhotoList([...featuredList, ...unfeaturedList]);
      }
      // setProfilePhotoCount(profilePicCount);
      // setTotalData([
      //   ...props.allVideoPhotoInfo.data?.allUploadedvideoandphotos,
      //   actualProfile,
      // ]);
      setCounts({ videoCount, photoCount });
    }
  }, [props.allVideoPhotoInfo.data, props.athleteData.data]);

  React.useEffect(() => {
    let variables = {
      where: {
        userid: getUserInfo().id,
        active: 1,
      },
    };
    props.allAlbumTagsListStart({
      query: getAllAlbumsTags,
      variables: variables,
    });
    props.athleteListStart({
      query: getAllAthletesDataQuery,
      variables: {
        obj: {
          id: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
    props.getPubliPrivateStatusStart({
      query: getPrivatePublicStatusQuery,
      variables: {
        obj: {
          userid: getUserInfo ? getUserInfo().id : 0,
        },
      },
    });
    refreshPage();
  }, []);
  const refreshPage = () => {
    props.allVideoPhotoListStart({
      query: getAllVideoPhotoInfoQuery,
      variables: {
        obj: {
          and: [
            {
              or: [
                {
                  videophotosuploadedtype: "programAndPricing",
                },
                {
                  videophotosuploadedtype: "Post",
                  userid: getUserInfo() ? getUserInfo().id : null,
                  active: 1,
                },
                {
                  videophotosuploadedtype: "Uploaded",
                  userid: getUserInfo() ? getUserInfo().id : null,
                  active: 1,
                },
                {
                  videophotosuploadedtype: "Press",
                  userid: getUserInfo() ? getUserInfo().id : null,
                  active: 1,
                },
              ],
            },
          ],
          order: "id Desc",
        },
        /////////////earlier this used which is not required
        // obj : {
        //        or:[
        //            {
        //             videophotosuploadedtype : "programAndPricing"
        //            },
        //            {
        //             userid : getUserInfo() ? getUserInfo().id : null,
        //             active : 1
        //            }
        //        ]
        // }
        //////////////
      },
      // {
      //   obj: {
      //     userid: getUserInfo() ? getUserInfo().id : 0,
      //     active: 1,
      //   },
      // },
    });
    props.clearPostSuccess();
    setEditVideoModal(false);
  };

  const getAthleteData = () => {
    props.athleteListStart({
      query: getAllAthletesDataQuery,
      variables: {
        obj: {
          id: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  }

  const showDataTemporary = (dataList) => {
    if (dataList?.length) {
      let newArr = [];
      for (const item of dataList) {
        newArr = [
          ...newArr,
          {
            name: item.fileUrl,
            type: item.type,
            file: item.file,
            originalname: item.originalname,
          },
        ];
      }
      setEditVideoModal(true);
      setIsEdit(false)
      setVideoPhotData(newArr);
    }
  };
  const deleteTemporaryData = (index) => {
    const tempData = [...videoPhotData];
    tempData.splice(index, 1);
    setVideoPhotData(tempData);
    setEditVideoModal(true);
    if (tempData.length == 0) {
      setEditVideoModal(false);
    }
  };

  const closeVideoPhotoLink = () => {
    setEditVideoModal(false);
    setVideoPhotData(null);
    refreshPage();
  };

  const onSaveData = async (finalData, index) => {
    for (const data of finalData) {
      try {
        setLoading(true);
        let formData = new FormData();
        formData.append("file", data.file);
        const response = await axios({
          url: imageUploadUrl,
          method: "POST",
          data: formData,
          headers: {
            access_token: getAuthToken(),
          },
        });
        if (response && response.status === 200) {
          const pathUrl = response.data.result?.files?.file[0]?.name
            ? response.data.result.files.file[0].name
            : "";
          setLoading(false);
          props.saveVideoPhotoStart({
            variables: {
              userId: getUserInfo() ? getUserInfo().id : 0,
              photosAndVideosArray: [
                {
                  name: pathUrl,
                  type: data.type,
                  position: data.position ? data.position : null,
                  jerseyno: data.jerseyno ? data.jerseyno : null,
                  jerseycolor: data.jerseycolor ? data.jerseycolor : null,
                  albumId: data.albumId ? +data.albumId : null,
                  pressid: null,
                  tagId: data.tagId ? +data.tagId : null,
                  originalname: data.title ? data.title : data.originalname,
                  videophotosuploadedtype: "Uploaded",
                },
              ],
              isvisible: data.isvisible,
              uploadedfrom: "photovideo"
            },
            nextScreen: () => {
              toastr.success(messages.UPLOADED_SUCCESSFULLY, "", {
                progressBar : true,timeOut: 2000,
                closeButton: true, positionClass: "toast-top-full-width",
                showEasing: "swing", fadeIn: 40000,
                preventDuplicates: true,
              })
            },
          });
        } else {
          setLoading(false);
          // messagePopup("", "Save Video Photo Failed", "error");
          toastr.error("Save Video Photo Failed", "", {
            progressBar : true,timeOut: 2000,
            closeButton: true, positionClass: "toast-top-full-width",
            showEasing: "swing", fadeIn: 40000,
            preventDuplicates: true,
          })
        }
      } catch (error) {
        setLoading(false);
        // messagePopup("", "Save Video Photo Failed", "error");
        toastr.error("Save Video Photo Failed", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      }
    }
    setRemoveIndex(index);
  };

  const onSaveDataLink = async (data, index) => {
    props.saveVideoPhotoStart({
      variables: {
        userId: getUserInfo() ? getUserInfo().id : 0,
        photosAndVideosArray: [
          {
            name: data.name,
            type: data.type,
            position: data.position ? data.position : null,
            jerseyno: data.jerseyno ? data.jerseyno : null,
            jerseycolor: data.jerseycolor ? data.jerseycolor : null,
            albumId: data.albumId ? +data.albumId : null,
            pressid: null,
            tagId: data.tagId ? +data.tagId : null,
            originalname: "Video",
            videophotosuploadedtype: "Uploaded",
          },
        ],
      },
      nextScreen: () => {
        toastr.success(messages.UPLOADED_SUCCESSFULLY, "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      },
    });
    setRemoveIndex(index);
  };
  const shareVideoPhoto = (data) => {};
  const updateRecord = async (data) => {
    props.updateVideoPhotoInfoStart({
      query: saveAllVideoPhotoInfoQuery,
      variables: {
        obj: {
          userid: data.userid,
          id: data.id,
          isphotosorvideoslik: data.type,
          jerseycolor: data.jerseycolor ? data.jerseycolor : null,
          jerseyno: data.jerseyno ? data.jerseyno : null,
          position: data.position ? data.position : null,
          videophotosuploadedtype: "Uploaded",
          tagid: data.tagid ? +data.tagid : null,
          albumid: data.albumid ? +data.albumid : null,
          originalname: data.title ? data.title : null,
        },
      },
      successMsg: messages.UPDATED_SUCCESSFULLY,
      refreshPage,
    });
    setEditVideoModal(false);
  };

  const onDownloadClick = (data) => {
    if (data.isphotosorvideoslik === 0 || data.isphotosorvideoslik === 1) {
      const filename =
        data.isphotosorvideoslik === 1
          ? data.videoname
          : data.isphotosorvideoslik === 0
          ? data.photosname
          : "";
      axios
        .get(`${imageDownloadUrl + "/" + filename}`, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, filename);
        });
    }
  };
  const onRemoveFeatureClick = (data) => {
    props.updateVideoPhotoInfoStart({
      query: saveAllVideoPhotoInfoQuery,
      variables: {
        obj: {
          userid: data.userid,
          id: data.id,
          isfeatured: 0,
        },
      },
      refreshPage,
    });
  };
  const onClickFeatured = (data) => {
    props.updateVideoPhotoInfoStart({
      query: saveAllVideoPhotoInfoQuery,
      variables: {
        obj: {
          userid: data.userid,
          id: data.id,
          isfeatured: 1,
        },
      },
      refreshPage,
    });
  };

  const handleToggleClick = async (data) => {
    const finalObj ={
      photosVideosAndLinkId: data?.id ?? 0,
      photosVideosAndLinkType: data?.isphotosorvideoslik || data?.isphotosorvideoslik == 0  ? data?.isphotosorvideoslik?.toString() : "",
      postId: data?.videoPostId ?? 0,
      userId: data?.userid,
      visibleId: data?.useracivityUploadedphotosandvideosid?.Useracivities?.[0]?.id ?? 0,
      postarray: getPostId(data),
      ispublic: data?.isvisible == 1 ? 0 : 1
    }
    const _resToggle = await fetchMethod('/api/Uploadedvideoandphotos/publicOrprivateVideophoto',finalObj,'POST',true)
    if (_resToggle?.status == 200 && _resToggle?.data?.response?.status == 200){
      refreshPage()
    }else{
      refreshPage()
    }
    //below code and api is replace with above rest api

    // if (data.isvisible) {
    //   props.updateVisibleStatusStart({
    //     query: {
    //       updateVideoPhoto: saveAllVideoPhotoInfoQuery,
    //       userActivity: updatePostsQuery,
    //     },
    //     variables: {
    //       updateVideoPhoto: {
    //         obj: {
    //           id: data.id,
    //           userid: data.userid,
    //           isvisible: data.isvisible,
    //         },
    //       },
    //       userActivity: {
    //         obj: {
    //           userid: data.userid,
    //           uploadedphotosandvideosid: data.id,
    //           publicorprivatestatus: data.isvisible ? 0 : 1,
    //           active: data.isvisible ? 1 : 0,
    //           id: data.userActivityId ? data.userActivityId : 0,
    //         },
    //       },
    //     },
    //     refreshPage,
    //   });
    // } else {
    //   props.updateVisibleStatusStart({
    //     query: {
    //       updateVideoPhoto: saveAllVideoPhotoInfoQuery,
    //       userActivity: updatePostsQuery,
    //     },
    //     variables: {
    //       updateVideoPhoto: {
    //         obj: {
    //           id: data.id,
    //           userid: data.userid,
    //           isvisible: data.isvisible,
    //         },
    //       },
    //       userActivity: {
    //         obj: {
    //           userid: data.userid,
    //           publicorprivatestatus: data.isvisible ? 0 : 1,
    //           id: data.userActivityId,
    //           active: 0,
    //         },
    //       },
    //     },
    //     refreshPage,
    //   });
    // }
  };

  const deletePhotoVideo = (data, isProfilePic = 0) => {
    //this isProfilePic parameter is for profile pic
    const cloneData = data
    cloneData['isprofilephoto'] = isProfilePic
    if (isProfilePic == 1){
      cloneData['uploadedrank'] = 0
    }
    setShowDeleteModal(true);
    setSingleDataItem(data);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deletePhotoAndVideo = async () => {
    const userPostId = getPostId(singleDataItem)
    setShowDeleteModal(!showDeleteModal);
    props.deleteVideoPhotoStart({
      userId: singleDataItem?.userid ?? getUserInfo()?.id ?? 0,
      deletedRank: +singleDataItem.uploadedrank,
      photosVideosAndLinkId: singleDataItem.id,
      photosVideosAndLinkType: `${singleDataItem.isphotosorvideoslik}`,
      postId: singleDataItem.videoPostId ? +singleDataItem.videoPostId : 0,
      visibleId: singleDataItem.userActivityId
        ? singleDataItem.userActivityId
        : 0,
      postarray: userPostId,
      isprofilephoto : singleDataItem?.isprofilephoto,//this key is added on change sept
      refreshPage,
      getAthleteData
    });
  };

  const onSaveTimeline = (data) => {
    props.updateVideoPhotoInfoStart({
      query: saveAllVideoPhotoInfoQuery,
      variables: {
        obj: {
          userid: data.userid,
          id: data.id,
          showontimelineordashboard: 1,
        },
      },
      // successMsg: "Post is uploaded on your timeline",
      refreshPage,
      isVidPhotoShare : true,
      isSharePop: ()=>sharePopOpen(data)
    });
  };
  const sharePopOpen = async (data) =>{
    const _res = await swal({text : "Post is uploaded on your timeline", icon:"success"})
    if (_res){
      setShareModalData(data)
      setTimeout(()=>{
        setIsShareModal(true)
      },500)
    }
  }

  const removeFromTimeline = (data) => {
    props.updateVisibleStatusStart({
      query: {
        updateVideoPhoto: saveAllVideoPhotoInfoQuery,
        userActivity: updatePostsQuery,
      },
      variables: {
        updateVideoPhoto: {
          obj: {
            id: data.id,
            userid: data.userid,
            showontimelineordashboard: 0,
          },
        },
        userActivity: {
          obj: {
            userid: data.userid,
            uploadedphotosandvideosid: data.id,
            active: 0,
            id: data.userActivityWithPost,
          },
        },
      },
      successMsg: "Post is removed from your timeline",
      refreshPage,
    });
  };

  useEffect(() => {
    if (
      props.allAlbumsTags &&
      props.allAlbumsTags.data &&
      props.allAlbumsTags.data.allAlbumTagsList &&
      props.allAlbumsTags.data.allAlbumTagsList.allAlbums &&
      props.allAlbumsTags.data.allAlbumTagsList.allAlbums.Albums
    ) {
      setAllAlbumList([
        ...props.allAlbumsTags.data.allAlbumTagsList.allAlbums.Albums,
      ]);
    }
    if (
      props.allAlbumsTags &&
      props.allAlbumsTags.data &&
      props.allAlbumsTags.data.allAlbumTagsList &&
      props.allAlbumsTags.data.allAlbumTagsList.allTags &&
      props.allAlbumsTags.data.allAlbumTagsList.allTags.Tags
    ) {
      setAllTagsList([
        ...props.allAlbumsTags.data.allAlbumTagsList.allTags.Tags,
      ]);
    }
  }, [props.allAlbumsTags]);

  useEffect(() => {
    if (
      props.addVideoPhoto.data &&
      prevSavePhotoVideoData !== props.addVideoPhoto.data
    ) {
      const tempData = videoPhotData ? [...videoPhotData] : [];
      tempData.splice(removeIndex, 1);
      setVideoPhotData(tempData);
      setEditVideoModal(true);
      if (tempData.length == 0) {
        setEditVideoModal(false);
        refreshPage();
      }
    }
  }, [props.addVideoPhoto.data]);

  const onAddRemoveProfile = (data, flag) => {
    props.updateVideoPhotoInfoStart({
      query: saveAllVideoPhotoInfoQuery,
      variables: {
        obj: {
          userid: data.userid,
          id: data.id,
          addprofilephotodate: flag ? `${Date.now()}` : null,
          isprofileimage: flag ? 1 : 0,
        },
      },
      successMsg: flag
        ? "Photo added to profile picture"
        : "Photo removed from profile picture",
      refreshPage,
    });
  };

  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  return (
    <div className="pageContent">
      <BackdropLoader
        open={
          props.allVideoPhotoInfo.loading ||
          props.addVideoPhoto.loading ||
          loading ||
          props.updateVideoPhotoInfo.loading ||
          props.deleteVideoPhoto.loading
        }
      />
      <div className="editProfile">
        <input className="fakeInput" type="text" ref={fakeInput} />
        <div className="container">
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
          >
            <SubHeader onSideMenuClick={onSideMenuClick} buttonHide={true} />
            <div className="sidebar">
              <LeftSideEditProfile />
            </div>
            <EditSection
              handleModal={handleModal}
              onLinkModal={onLinkModal}
              onShowTimeLine={onShowTimeLine}
              onEditVideo={onEditVideo}
              onVideoOpen={onVideoOpen}
              showDataTemporary={showDataTemporary}
              photoList={photoList}
              //earlier it was ==>> totalData due to this profile pic was not added to the photo count
              // totalData={totalData}
              shareVideoPhoto={shareVideoPhoto}
              onDownloadClick={onDownloadClick}
              onRemoveFeatureClick={onRemoveFeatureClick}
              onClickFeatured={onClickFeatured}
              deletePhotoVideo={deletePhotoVideo}
              handleToggleClick={handleToggleClick}
              removeFromTimeline={removeFromTimeline}
              onAddRemoveProfile={onAddRemoveProfile}
              counts={counts}
              // profilePhotoCount={profilePhotoCount}
            />
          </div>
        </div>
      </div>
      <Dialog open={showModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  handleModal()
                }
              }}
              className="matDialogCustom"
      >
        <SharePost
            handleModal={handleModal}
            pageType={"Vid/Photo"}
            modalData={modalData}
        />
      </Dialog>
      {/* <Dialog open={showLinkModal}>
        <LinkVideoOverlay onLinkModal={onLinkModal} onSaveLink={onSaveLink} />
      </Dialog> */}
      <Dialog open={showTimeLine}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onShowTimeLine()
                }
              }}
              className="matDialogCustom"
      >
        <TimelinePostOverlay
          onShowTimeLine={onShowTimeLine}
          data={timelineData}
          onSaveTimeline={onSaveTimeline}
        />
      </Dialog>
      <EditVideoPhotoOverlay
        open={editVideoModal}
        isEdit = {isEdit}
        onSaveData={onSaveData}
        onSaveDataLink={onSaveDataLink}
        handleModal={handleModal}
        onVideoOpen={onVideoOpen}
        data={videoPhotData}
        closeVideoPhotoLink={closeVideoPhotoLink}
        deleteTemporaryData={deleteTemporaryData}
        allAlbum={allAlbumList}
        allTags={allTagsList}
        saveVideoPhotoStart={props.saveVideoPhotoStart}
        updateRecord={updateRecord}
        loading={
          props.addVideoPhoto.loading ||
          loading ||
          props.deleteVideoPhoto.loading
        }
        deletePhotoVideo={deletePhotoVideo}
        publicPrivateStatus={props.publicPrivateStatus}
        savePubliPrivateStatusStart={props.savePubliPrivateStatusStart}
      />
      <VideoOverlay
        onVideoOpen={onVideoOpen}
        onVideoClose={onVideoClose}
        videoOpen={videoOpen}
      />
      <Dialog open={showDeleteModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  closeDeleteModal()
                }
              }}
              className="matDialogCustom"
      >
        <DeleteVideoPhotoPopup
          onDeleteClick={deletePhotoAndVideo}
          onClose={closeDeleteModal}
        />
      </Dialog>
      <Dialog open={isShareModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setIsShareModal(false)
                }
              }}
              className="matDialogCustom"
      >
        <SharePost
            handleModal = {()=>setIsShareModal(false)}
            pageType = { 'Video/Photo_Share' }
            modalData = { shareModalData }
        />
      </Dialog>
      <GoTopButton goTop={goTop} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addVideoPhoto: state.athlete.editUserProfileData.addVideoPhoto,
    allVideoPhotoInfo: state.athlete.editUserProfileData.allVideoPhotoInfo,
    updateVideoPhotoInfo:
      state.athlete.editUserProfileData.updateVideoPhotoInfo,
    deleteVideoPhoto: state.athlete.editUserProfileData.deleteVideoPhoto,
    allAlbumsTags: state.athlete.editUserProfileData.albumTags,
    athleteData: state.common.athletesData,
    publicPrivateStatus: state.athlete.editUserProfileData.publicPrivateStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveVideoPhotoStart: (data) => dispatch(saveVideoPhotoStart(data)),
    allVideoPhotoListStart: (data) => dispatch(allVideoPhotoListStart(data)),
    updateVideoPhotoInfoStart: (data) =>
      dispatch(updateVideoPhotoInfoStart(data)),
    deleteVideoPhotoStart: (data) => dispatch(deleteVideoPhotoStart(data)),
    updateVisibleStatusStart: (data) =>
      dispatch(updateVisibleStatusStart(data)),
    allAlbumTagsListStart: (data) => dispatch(allAlbumTagsListStart(data)),
    clearPostSuccess: () => dispatch(clearPostSuccess()),
    athleteListStart: (data) => dispatch(athletesDetailsStart(data)),
    getPubliPrivateStatusStart: (data) =>
      dispatch(getPubliPrivateStatusStart(data)),
    savePubliPrivateStatusStart: (data) =>
      dispatch(savePubliPrivateStatusStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoPage);
