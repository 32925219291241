import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { imageDownloadUrl } from '../../../config';
import successTestimonialData from './successTestimonialData';

const carouselOptions = {
  loop: true,
  nav: true,
  dots: false,
  items: 1,
  autoplay: true,
  autoplayTimeout: 10000,
};

// Shuffle the testimonials using the Fisher-Yates algorithm
const shuffledTestimonials = [...successTestimonialData];
for (let i = shuffledTestimonials.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1));
  [shuffledTestimonials[i], shuffledTestimonials[j]] = [
    shuffledTestimonials[j],
    shuffledTestimonials[i],
  ];
}

const SuccessStories = () => {
  return (
    <section className="success-stories-sec bg-white">
      <div className="container">
        <div className="sec-title">Success Stories</div>
        <OwlCarousel
          className="success-stories-list success-stories-slide owl-carousel owl-theme"
          {...carouselOptions}
        >
          {shuffledTestimonials.map((testimonial, index) => (
            <div className="item" key={index}>
              <div className="pick-box">
                <img
                  className="pick-img"
                  src={`${imageDownloadUrl}/${testimonial.studentPhoto}`}
                  alt={testimonial.studentName}
                />
                <span className="icon-box">
                  <img
                    src={`${imageDownloadUrl}/${testimonial.schoolLogo}`}
                    alt={testimonial.schoolName}
                  />
                </span>
              </div>
              <div className="detail-info-box">
                <div className="short-text">
                  <p>{testimonial.text}</p>
                </div>
                <div className="info-box">
                  <h4>{testimonial.studentName}</h4>
                  <p>{testimonial.schoolName}</p>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default SuccessStories;
