import React from "react";
import PostCard from "./PostCard";

export default function MyFeed(props) {
  return (
    <>
      <div className="shareDataBox">
        {React.Children.toArray( 
          props.postList &&
            props.postList.map((post) => (
              <PostCard
                handleModal={props.handleModal}
                post={post}
                tab="myFeed"
                handleRemovePost={props.handleRemovePost}
                handlePinPost={props.handlePinPost}
                handleUnpinPost={props.handleUnpinPost}
                handleUnlike={props.handleUnlike}
                handleLike={props.handleLike}
                containPin={props.containPin}
                isTimeline={props.isTimeline}
                isPublicView={props.isPublicView}
                isTimelineValue={props.isTimelineValue}
                isAuth={ props?.isAuth }
                page = {props?.page}
              />
            ))
        )}
      </div>
    </>
  );
}
