export const getAllOfferForDashboardQuery = (userId) => `
query allOffers($obj: JSON) {
  allOffers(where: $obj){
    totalCount
    Offers{
      id
      managevisible
      collegeid
      createdon
      interestlevelid
      offerdate
      offerid
      active
      offercollegeidmaprel{
        Colleges{
          id
          collegename
          collegelogo
          active
          userCollegeCollegeIdMap(where:{active:1,userid:${userId}}){
            Usercolleges{
              isfav
            }
          }
        }
      }
  offerinterestlevelidmaprel{
    Interestlevels{
        id
           interestlevelvalue
         }
       }
       offerofferidmaprel{
         Offersdropdwns{
             id
           offeresvalue
         }
       }
       offeruseridmaprel{
        Athletes{
          onBoardingUserIdMap{
            Onboardings{
              twitteraccountname
            }
          }
         }
      }
    }
  }
} 
`;

export const getAllOfferQuery = `
query allOffers($obj: JSON) {
  allOffers(where: $obj){
    totalCount
    Offers{
      id
      managevisible
      collegeid
      createdon
      interestlevelid
      offerdate
      offerid
      active
      offercollegeidmaprel{
        Colleges{
          id
          collegename
          active
          city
          state
        }
      }
  offerinterestlevelidmaprel{
    Interestlevels{
        id
           interestlevelvalue
         }
       }
       offerofferidmaprel{
         Offersdropdwns{
             id
           offeresvalue
         }
       }
       offeruseridmaprel{
        Athletes{
          onBoardingUserIdMap{
            Onboardings{
              twitteraccountname
            }
          }
         }
      }
    }
  }
} 
`;

export const filterCollegeNameQuery = `
query filter($where:JSON){
    allColleges(where:$where){
      Colleges{
        id
        collegename
        city
        state
      }
    }
  }
`;

export const saveOffersQuery = `
mutation saveOffer($obj : OfferInput!){
    saveOffer(obj :$obj)
    {
      id
      active
    }
  }
`;

export const getUserInterestCollegeLevel = `
query allUsercolleges($obj: JSON) {
  allUsercolleges(where: $obj){
    Usercolleges{
      id
      collegeinterestlevel
      active
    }
    }
  } 
`;

export const getUserCollegedata = `
query allUsercolleges($obj: JSON,$first:Int,$last:Int) {
  allUsercolleges(where: $obj,first:$first,last:$last){
    Usercolleges{
      id
      collegeinterestlevel
      active
    }
    }
  } 
`;

export const saveUserCollegeQuery = `
mutation saveUsercollege($obj : UsercollegeInput!){
  saveUsercollege(obj :$obj)
    {
      id
    }
  }
`;

export const isfavCollege = `
query allUsercolleges($where : JSON){
  allUsercolleges(where: $where){
    Usercolleges{
      id
    }
  }
}
`;
