export const saveTestScoresQuery = `
mutation saveTestScore($obj:TestscoreInput!){
    saveTestscore(obj:$obj){
      id
    } 
  }
`;

export const saveGpaMappingQuery = `
mutation saveGpamapping($obj:GpamappingInput!){
    saveGpamapping(obj:$obj){
      id
    } 
  }
`;

export const saveAcademicFileQuery = `
mutation saveTranscriptsandacadmicfiels($obj:TranscriptsandacadmicfielsInput!){
  saveTranscriptsandacadmicfiels(obj:$obj){
      id
    } 
  }
`;

export const apIbClassesSaveQuery = `
mutation saveApandib($obj:ApandibInput!){
  saveApandib(obj:$obj){
      id
    } 
  }
`;


export const getallTranscriptsandacadmicfielsQuery = `
query allTranscriptsandacadmicfiels($obj: JSON){
  allTranscriptsandacadmicfiels(where: $obj){
    Transcriptsandacadmicfiels{
      id
      userid
      description
      filename
      filetype
      createdon
      fileavailableforallcolleges
      transcriptsandacadmicfielsfiletypemaprel{
        Reportcardtypes{
          type
        }
      }
    }
 }
}`

export const getLatestTestScoreQuery = `
query allTestscores($obj: JSON){
  allTestscores(where: $obj){
    Testscores{
      satnotes
      satmathid
      satreadingandwritingid
      sattestdate
      sattotalnumber
      psatnotes
      psatmathid
      psattestdate
      psatotalnumer
      actnotesid
      acttestdate
      actcompletescoreid
      pstreadingandwritingid
    }
 }
}`

export const getApIpClassDetailsQuery = `
query allApandibs($obj: JSON){
  allApandibs(where: $obj){
    Apandibs{
      id
      apandibtimeperiod
      apandibTotalTimePeriod
      areyoutakeapbpclass
      classdetails
    }
 }
}`

export const getNcaaAndNaidQuery = `
query allNcaaandnaids($obj: JSON){
  allNcaaandnaids(where: $obj){
    Ncaaandnaids{
      id
      havencaaandnadianumber
      nadianumber
      ncaanumber
    }
 }
}`

export const NcaaAndNaidSaveQuery = `
mutation saveNcaaandnaid($obj:NcaaandnaidInput!){
  saveNcaaandnaid(obj:$obj){
      id
    } 
  }
`;

export const getAllHonorsQuery = `
query allAcademicaccomplishments($obj: JSON){
  allAcademicaccomplishments(where: $obj){
    Academicaccomplishments{
      id
      havehonors
      areaofstudy
      acadhonors
      extraactivity
      classdetails
      honorclassestimeperiod
      honorclassestotaltimeperiod
    }
 }
}`

export const HonorsSaveQuery = `
mutation saveAcademicaccomplishments($obj:AcademicaccomplishmentsInput!){
  saveAcademicaccomplishments(obj:$obj){
      id
    } 
  }
`;

export const AllApIbTakenClasses = `
query{
  allApanibclassestotaltimeperoids{
    Apanibclassestotaltimeperoids{
      id
      totaltimeperiodforcources
    }
  }
}
`;

