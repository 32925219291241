import { put, takeEvery } from "redux-saga/effects";
import {fetchGraphMethod, fetchMethod} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";
import {referencesFailed, referencesSuccess} from "./allReferences";

// ========================================== Action Type =====================================================
export const actionType = {
    GET_PROFILE_TIMELINE_STATUS_START: "GET_PROFILE_TIMELINE_STATUS_START",
    GET_PROFILE_TIMELINE_STATUS_SUCCESS: "GET_PROFILE_TIMELINE_STATUS_SUCCESS",
    GET_PROFILE_TIMELINE_STATUS_FAIL: "GET_PROFILE_TIMELINE_STATUS_FAIL",
    SAVE_PROFILE_TIMELINE_STATUS_START: "SAVE_PROFILE_TIMELINE_STATUS_START",
    SAVE_PROFILE_TIMELINE_STATUS_SUCCESS: "SAVE_PROFILE_TIMELINE_STATUS_SUCCESS",
    SAVE_PROFILE_TIMELINE_STATUS_FAIL: "SAVE_PROFILE_TIMELINE_STATUS_FAIL",
};

// ============================================ Actions ========================================================
export const profileTimelineStatusStart = (data) => ({
    type: actionType.GET_PROFILE_TIMELINE_STATUS_START,
    payload: data,
});

export const profileTimelineStatusFail = (data) => ({
    type: actionType.GET_PROFILE_TIMELINE_STATUS_FAIL,
    payload: data,
});

export const profileTimelineStatusSuccess = (data) => ({
    type: actionType.GET_PROFILE_TIMELINE_STATUS_SUCCESS,
    payload: data,
});
export const saveprofileTimelineStatusStart = (data) => ({
    type: actionType.SAVE_PROFILE_TIMELINE_STATUS_START,
    payload: data,
});

export const saveprofileTimelineStatusFail = (data) => ({
    type: actionType.SAVE_PROFILE_TIMELINE_STATUS_FAIL,
    payload: data,
});

export const saveprofileTimelineStatusSuccess = (data) => ({
    type: actionType.SAVE_PROFILE_TIMELINE_STATUS_SUCCESS,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function profileTimelineStatusReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.GET_PROFILE_TIMELINE_STATUS_START:
            return {
                ...state,
                loading: true,
            };

        case actionType.GET_PROFILE_TIMELINE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };

        case actionType.GET_PROFILE_TIMELINE_STATUS_FAIL:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        case actionType.SAVE_PROFILE_TIMELINE_STATUS_START:
            return {
                ...state,
                loading: true,
            };

        case actionType.SAVE_PROFILE_TIMELINE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };

        case actionType.SAVE_PROFILE_TIMELINE_STATUS_FAIL:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };

        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* profileTimelineStatusSaga(action) {
    try {
        const response = yield fetchMethod(
            `/api/Publicprofilefieldpreviews/getProfileViewStatus`,
            action.payload,
            "post",
            action?.payload?.isAccessToken
        );
        if (
            response &&
            response.status === 200 &&
            response.data.response.data
        ) {
            yield put(
                profileTimelineStatusSuccess({
                    profileTimelineAbout: response.data.response.data,
                })
            );
        } else {
            messagePopup("", "Profile Status failed", "error");
            yield put(profileTimelineStatusFail("Profile Timeline Status failed"));
        }
    } catch (error) {
        messagePopup("", "Profile Timeline Status failed", "error");
        yield put(profileTimelineStatusFail("Profile Timeline Status failed"));
    }
}

export function* saveprofileTimelineStatusSaga(action) {
    try {
        const response = yield fetchMethod(
            `/api/Publicprofilefieldpreviews/updatePublicProfileStatus`,
            action.payload,
            "post",
            true
        );
        if (response && response.status === 200) {
             action?.getProfileData()
            messagePopup("", "Setting Are Updated", 'success',);
            yield put(saveprofileTimelineStatusSuccess("Profile Updated Successfully"));
        } else {
            messagePopup("", "Server Error,Try Again", "error");
            yield put(saveprofileTimelineStatusFail("Setting Are Updated failed"));
        }
    } catch (error) {
        messagePopup("", "Server Error, Try Again failed", "error");
        yield put(saveprofileTimelineStatusFail("Setting Are Updated failed"));
    }
}

//Watcher
export function* watchProfileTimelineStatusSaga() {
    yield takeEvery(actionType.GET_PROFILE_TIMELINE_STATUS_START, profileTimelineStatusSaga);
    yield takeEvery(actionType.SAVE_PROFILE_TIMELINE_STATUS_START, saveprofileTimelineStatusSaga);
}
