import React, { useState, useEffect, useRef } from 'react';
import './signup.scss';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { IsValidEmail } from '../../../../utils/validationUtils';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { connect } from 'react-redux';
import { graduationYearsListStart } from '../../../../redux/common/graduationYears';
import BackdropLoader from '../../../common/Loader';
import { sportsListStart } from '../../../../redux/common/allSports';
import { emailExistStart } from '../../../../redux/auth/signup/checkEmailExist';
import {
  getSignupData,
  removeSignupData,
  setSignupData,
} from '../../../../services/authService';
import { graduationMonthsListStart } from '../../../../redux/common/graduationMonth';
import EmailExists from '../claimProfile/claimProfileStep2/EmailExists';
import messagePopup from '../../../../utils/messagePopup';
const initialValues = {
  athleteActive: true,
  email: '',
  gender: 'Male',
  graduation: '',
  graduationMonthValue: '',
  sport: '',
};

function SignupStep1(props) {
  const [fields, setFields] = useState({ ...initialValues });
  const [errors, setErrors] = React.useState({});
  const [emailModal, setEmailModal] = React.useState(false);
  const fakeInput = useRef(null);

  useEffect(() => {
    getSportsList(fields.gender);
  }, [fields.gender]);

  useEffect(() => {
    props.graduationYearsListStart();
    props.graduationMonthsListStart();
    const signupData = getSignupData();
    if (signupData) {
      const finalData = {
        athleteActive: signupData.role
          ? signupData.role === 'ATHLETES'
            ? true
            : false
          : true,
        email: signupData.athleteEmail
          ? signupData.athleteEmail
          : signupData.parentEmailId
          ? signupData.parentEmailId
          : '',
        gender: signupData.gender ? signupData.gender : 'Male',
        graduation: signupData.athleteGraduationYear
          ? signupData.athleteGraduationYear
          : new Date().getFullYear(),
        graduationMonthValue: signupData.graduationMonthValue
          ? signupData.graduationMonthValue
          : 0,
        sport: signupData.athleteSports ? signupData.athleteSports : '',
      };
      setFields({
        ...fields,
        ...finalData,
      });
    }
  }, []);

  const getSportsList = (gender) => {
    props.sportsListStart({ gender });
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    //email validations
    if (fieldname === undefined || fieldname === 'email') {
      if (fieldname === undefined) {
        fieldValue = fields['email'] ? fields['email'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['email'];
      if (!updatedValue) {
        formIsValid = false;
        errorsT['email'] = 'Email Address is required';
      } else if (!IsValidEmail(updatedValue)) {
        formIsValid = false;
        errorsT['email'] = 'Please enter Email in correct format';
      }
    }
    //graduation years validations
    if (fieldname === undefined || fieldname === 'graduation') {
      if (fieldname === undefined) {
        fieldValue = fields['graduation'] ? fields['graduation'] : '';
        updatedValue =
          fieldValue == ''
            ? props.graduationYears.data.filter(
                (word) => word.yearValue == new Date().getFullYear()
              )[0].id
            : fieldValue;
      }
      delete errorsT['graduation'];
      if (!updatedValue) {
        formIsValid = false;
        errorsT['graduation'] = 'HS Grad Year is required';
      }
    }

    //sport validations
    if (fieldname === undefined || fieldname === 'sport') {
      if (fieldname === undefined) {
        fieldValue = fields['sport'] ? fields['sport'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['sport'];
      if (!updatedValue) {
        formIsValid = false;
        errorsT['sport'] = 'Sport is required';
      }
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };
  const handleChange = (field, e) => {
    //const { formIsValid, updatedValue } = handleValidation(field, e);
    setErrors({
      errorsT: '',
    });

    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;

    setFields({
      ...fieldsT,
    });
  };

  const handleRadioChange = (field, e) => {
    setErrors({
      errorsT: '',
    });

    if (fields.gender) {
      if (fields.gender !== 'Male') {
        fields.sport = '';
      } else if (fields.gender !== 'Female') {
        fields.sport = '';
      }
    }
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;

    setFields({
      ...fieldsT,
    });
  };

  const handleEmailModalOpen = () => {
    messagePopup('', 'Email id already exist for athlete', 'error');
    //setEmailModal(true);
  };

  const handleSaveSignUpData = (existingParentData = null) => {
    const emailObj = fields.athleteActive
      ? {
          athleteEmail: fields.email,
        }
      : {
          parentEmailId: fields.email,
        };
    const finalData = {
      athleteGraduationYear:
        fields.graduation == ''
          ? props.graduationYears.data.filter(
              (word) => word.yearValue == new Date().getFullYear()
            )[0].id
          : fields.graduation,
      athleteSports: fields.sport,
      gender: fields.gender,
      role: fields.athleteActive ? 'ATHLETES' : 'GUARDIAN',
      ...emailObj,
      graduationMonthValue: fields.graduationMonthValue
        ? fields.graduationMonthValue
        : 0,
      isParentExist: existingParentData ? 1 : 0,
      parentFirstName: existingParentData
        ? existingParentData.parentFirstName
        : '',
      parentLastName: existingParentData
        ? existingParentData.parentLastName
        : '',
      parentPhoneNumber: existingParentData
        ? existingParentData.parentPhoneNumber
        : '',
      parentCountryCodeId: existingParentData
        ? existingParentData.parentCountryCodeId
        : null,
      parentZipCode: existingParentData ? existingParentData.parentZipCode : '',
      parentOutsideUsOrCanada: existingParentData
        ? existingParentData.parentOutsideUsOrCanada
        : '',
    };
    let signupData = getSignupData() ? getSignupData() : {};
    signupData = {
      ...signupData,
      ...finalData,
      completed: signupData.completed ? signupData.completed : 'STEP1',
    };
    setSignupData(signupData);
    if (fields.athleteActive) {
      props.history.push('/sportforceAthleteSignup/K8-ao-TJ/step2');
    } else {
      props.history.push('/signupstep2/guardian');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { formIsValid, updatedValue } = handleValidation();
    if (formIsValid) {
      props.emailExistStart({
        data: {
          email: fields.email,
          role: fields.athleteActive ? 'ATHLETES' : 'GUARDIAN',
        },
        handleEmailModalOpen: handleEmailModalOpen,
        handleSaveSignUpData: handleSaveSignUpData,
      });
    } else {
      goTop();
    }
  };

  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  return (
    <div>
      {/* <Header /> */}

      <div className="signupContainerSection">
        <div className="signupContainer">
          <BackdropLoader
            open={
              props.graduationYears.loading ||
              props.sportsList.loading ||
              props.graduationMonths.loading
            }
          />
          <input className="fakeInput" type="text" ref={fakeInput} />
          <div className="signupHeader">
            <FormLabel>
              <h1>Athlete Sign Up</h1>
            </FormLabel>
          </div>
          <p className="subHead">
            <span className="checkIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <defs>
                  <style></style>
                </defs>
                <g transform="translate(-649.111 -507.111)">
                  <path
                    className="a"
                    d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                    transform="translate(649.111 507.111)"
                  />
                  <path
                    className="b"
                    d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                    transform="translate(511.436 348.256)"
                  />
                </g>
              </svg>
            </span>
            Profile Verification Requirement
          </p>
          <div className="signup-athlete">
            <form autoComplete="off">
              <div className="emailBoxField">
                <FormLabel>
                  <h2>
                    Athlete Email <sup>*</sup>
                  </h2>
                </FormLabel>
                <h2>The email will be used to login to your account</h2>
                <div className="fieldBox">
                  {/* <input id="email" type="email" value={fields.email} placeholder="Email Address"  onChange={(e) => {
                      handleChange("email", e);
                    }} /> */}
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="email"
                    placeholder="Email Address"
                    type="text"
                    value={fields.email}
                    onChange={(e) => {
                      handleChange('email', e);
                    }}
                    onBlur={(e) => {
                      handleChange('email', e);
                    }}
                    InputProps={{
                      readOnly: false,
                    }}
                    required
                  />
                  <div className="error">{errors['email']}</div>
                </div>
              </div>
              <div className="graduateYearBox">
                <FormLabel>
                  <h2>
                    Athlete HS Grad Year <sup>*</sup>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <g transform="translate(-649.111 -507.111)">
                          <path
                            className="a"
                            d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                            transform="translate(649.111 507.111)"
                          />
                          <path
                            className="b"
                            d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                            transform="translate(511.436 348.256)"
                          />
                        </g>
                      </svg>
                    </span>
                  </h2>
                </FormLabel>
                <div className="fieldBox">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={
                        fields.graduation == ''
                          ? props.graduationYears.data
                            ? props.graduationYears.data.filter(
                                (word) =>
                                  word.yearValue == new Date().getFullYear()
                              )[0].id
                            : ''
                          : fields.graduation
                      }
                      onChange={(e) => handleChange('graduation', e)}
                      label="Select"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {props.graduationYears.data &&
                        props.graduationYears.data.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.yearValue}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <div className="error">{errors['graduation']}</div>
                </div>
              </div>
              <div className="genderBox">
                <h2>
                  Athlete Gender <sup>*</sup>
                </h2>
                <div className="sf-radio-global">
                  <FormControl component="fieldset">
                    <FormLabel component="legend"></FormLabel>
                    <RadioGroup
                      name="radioGroup1"
                      row
                      value={fields.gender || 'male'}
                      onChange={(e) => handleRadioChange('gender', e)}
                    >
                      <FormControlLabel
                        control={
                          <Radio name="radiobuttons_option_C402B5A8-880B-4F2A-9CFA-94C7DA4E95A5" />
                        }
                        label="Male"
                        value="Male"
                      />
                      <FormControlLabel
                        control={
                          <Radio name="radiobuttons_option_AE894437-7C35-407F-A289-26D78B41FA9A" />
                        }
                        label="Female"
                        value="Female"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="sportBox">
                <FormLabel>
                  {' '}
                  <h2>
                    Athlete Sport <sup>*</sup>
                  </h2>
                </FormLabel>
                <div className="fieldBox">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={fields.sport}
                      onChange={(e) => handleChange('sport', e)}
                      label="Select"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {props.sportsList.data &&
                        props.sportsList.data.map((item, index) => {
                          if (item?.id == 1 || item?.id == 4) {
                            return (
                              <MenuItem key={index} value={item.id}>
                                {item.sportName}
                              </MenuItem>
                            );
                          }
                        })}
                    </Select>
                  </FormControl>
                  <div className="error">{errors['sport']}</div>
                </div>
              </div>
              <div className="continueBtnBox">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  id="continue"
                  size="large"
                >
                  Continue
                </Button>
              </div>
              {emailModal == true ? (
                <EmailExists
                  userEmail={fields.email}
                  isRedirect={() => setEmailModal(false)}
                />
              ) : null}

              <div className="signInLinkBox">
                <p>
                  <span>If you have an account? </span>
                  <Link to="/login">Log In</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    graduationYears: state.common.graduationYears,
    sportsList: state.common.sportsList,
    graduationMonths: state.common.graduationMonths,
    isEmailExist: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    graduationYearsListStart: () => dispatch(graduationYearsListStart()),
    graduationMonthsListStart: () => dispatch(graduationMonthsListStart()),
    sportsListStart: (data) => dispatch(sportsListStart(data)),
    emailExistStart: (data) => dispatch(emailExistStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupStep1);
