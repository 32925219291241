import React, { useState } from "react";
import "../../../../../assets/css/stylesheet.scss";
import IosCheckMark from "../assets/img/iosCheckMark.png";
import { Link } from "react-router-dom";
import { Dialog } from "@mui/material";
import EnterOtp from "./EnterOtp";
import { connect } from "react-redux";
import { claimProfileStart } from "../../../../../redux/auth/signup/userClaimProfile";
import history from "../../../../../services/history";
function EmailExists(props) {
  const [previewModal, setPreviewModal] = useState(true);
  const [openOtpModal, setOpenOtpModal] = useState(false);

  const handlepreviewModal = () => {
    setPreviewModal(false);
    setOpenOtpModal(true);
  };

  const handleModal = () => {
    props.claimProfileStart({
      data: {
        email: props.userEmail,
      },
      handleOtpModal: handlepreviewModal,
    });
  };
  const closeButton = () => {
    setPreviewModal(true);
    setOpenOtpModal(false);
  };
const coachData = props?.claimProfileData?.data?.data?.response?.data;
  const athleteMobileNumber =
    props.claimProfileData &&
    props.claimProfileData.data &&
    props.claimProfileData.data.data &&
    props.claimProfileData.data.data.response &&
    props.claimProfileData.data.data.response.data
      ? props.claimProfileData.data.data.response.data[0].athletePhoneNumber
      : "";

  return (
    <div>
      <Dialog open={previewModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  !!props?.isRedirect ? props?.isRedirect() :   history.goBack()
                }
              }}
              className="matDialogCustom"
      >
        <div className="emailSentNotification">
          <div className="emailExists second">
            <div className="closeButton">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => !!props?.isRedirect ? props?.isRedirect() :   history.goBack()}
              >
                <span aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.804"
                    height="15.803"
                    viewBox="0 0 15.804 15.803"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g className="a" transform="translate(-6.776 -6.776)">
                      <path
                        d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </div>

            <div className="icon">
              <img src={IosCheckMark} alt="iosCheckMark" />
            </div>

            <p className="info">
              Your email id is already registered please preview your profile
              and Claim it.
            </p>

            <p className="skip">
              <Link to="#" onClick={() => handleModal()}>
                <b>Preview Profile</b>
              </Link>
            </p>
          </div>
        </div>
      </Dialog>

      <div>
        <Dialog open={openOtpModal}
                onClose={(event, reason)=> {
                  if (reason == "backdropClick" || reason == "escapeKeyDown") {
                    closeButton()
                  }
                }}
                className="matDialogCustom"
        >
          <EnterOtp
            closeButton={closeButton}
            userEmail={props.userEmail}
            athleteMobileNumber={athleteMobileNumber ? athleteMobileNumber : ""}
            role={props.role ? props.role : ""}
            coachData={coachData}
          />
        </Dialog>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    claimProfileData: state.auth.claimProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    claimProfileStart: (data) => dispatch(claimProfileStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailExists);
