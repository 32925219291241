import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import React from "react";
import {imageDownloadUrl} from "../../../../../../../config";

const VideoPopup = (props) => {
  return <>
    <Dialog
      open={props.show}
      onClose={props.close}
      maxWidth={"lg"}
      className={"videoOverlayWrap matDialogCustom"}
    >
      <IconButton
          aria-label="close"
          className={"closeBtnVideo"}
          onClick={props.close}
          size="large">
        <CloseIcon />
      </IconButton>

        {!!props?.isResource ?
            !!props?.vidData?.videolink ?
                <>
                    {/*?autoplay=true&muted=true*/}
                    <iframe
                        width="700"
                        height="400"
                        src={!!props?.vidData?.videolink ? `${props?.vidData?.videolink}` : "https://www.youtube.com/embed/A0KOKdUOtjc"}
                        allowFullScreen="allowFullScreen"
                    ></iframe>
                </>
                :
            <>
            <video
                controls
                width="700"
                height="400"
                // src={!!props?.videoname ? `https://www.youtube.com/embed/${props?.videoname}` : `https://www.youtube.com/embed/A0KOKdUOtjc`}
                // frameBorder="0"
                // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                // allowFullScreen
            >
                <source
                    src={`${
                    imageDownloadUrl +
                    "/" +
                    props?.vidData?.videoname ?? null
                }`}
                    alt = "video"
                >
                </source>
            </video>
            </>
            :
            <>
                {console.log(" video popup ==>>>", props)}
            <iframe
                width="700"
                height="400"
                src={`https://www.youtube.com/embed/${
                    props.videoId ? props.videoId : "A0KOKdUOtjc"
                }`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
            </>
        }
    </Dialog>
  </>;
};

export default VideoPopup;
