import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { searchCollegeListAPIStart } from "../../../../../../redux/athlete/dashboard/colleges/collegeSearchList";
import { getUserInfo } from "../../../../../../services/authService";
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";
import { addMyCollegeStart } from "../../../../../../redux/athlete/dashboard/homepage/addMyColleges";
import BackdropLoader from "../../../../../common/Loader";
import {
  getCollegesCountsQuery,
  getCollegesListQuery,
} from "../../colleges/myColleges/CollegeListQuery";
import { collegesListStart } from "../../../../../../redux/athlete/dashboard/colleges/collegeList";
import { userCollegeCardCountStart } from "../../../../../../redux/athlete/dashboard/colleges/userCollegeCardCount";
import { Dialog } from "@mui/material";
import InterestLevelModal from "../InterestLevelModal";
import {fetchGraphMethod, fetchMethod} from "../../../../../../services/httpService";
import { getAllInterestLevelQuery } from "../../../../../common/CommonQuery";
import { getAllOfferQuery, saveOffersQuery } from "../../editUserProfile/OfferQuery";
import { getMyCommitmentQuery } from "../../homepage/leftSideBar/query";
import {getCamelCase} from "../../../../../common/camelCaseFunc";
import { viewCollegeSaveStart } from "../../../../../../redux/athlete/dashboard/colleges/viewCollege";
import { saveViewCollegeQuery } from "../../colleges/searchColleges/SearchCollegesQuery";
import {imageDownloadUrl} from '../../../../../../config'

function SuggestedCollegeBox(props) { 
  const [suggestedCollegeList, setSuggestedCollegeList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showInterestModal, setShowInterestModal] = useState(false);
  const [interesrLevelList, setInteresrLevelList] = useState([]);
  const [addCollageDetails, setAddCollageDetails] = useState({});
  const [collegeIsAddedToOffer, setCollegeIsAddedToOffer] = useState(null);
  useEffect(() => {
    getSearchCollegesData();
    getInterestLevelList();
  }, []);
  /*useEffect(() => {
    if (props.searchCollegeList.data) {
      const tempData = props.searchCollegeList.data
        ? props.searchCollegeList.data.colleges
          ? props.searchCollegeList.data.colleges <= 3
            ? props.searchCollegeList.data.colleges
            : props.searchCollegeList.data.colleges.slice(0, 3)
          : null
        : null;
      setSuggestedCollegeList(tempData);
      setTotalCount(
        props.searchCollegeList.data
          ? props.searchCollegeList.data.totalCount
          : 0
      );
    }
  }, [props.searchCollegeList.data]); */

  const getSearchCollegesData = async () => {
    //now how the obj has to be send in variable
    /*props.searchCollegeListAPIStart({
      academicsSelectivity: [],
      areaOfStudy: [],
      athleticselectivity: [],
      collegeConference: [],
      collegeNameAndCity: "",
      collegeSize1: [],
      collegeTutionFee1: [],
      favoriteValue: "false",
      first: 0,
      last: 3,
      level: [],
      setting: [],
      state: [],
      type: [],
      userId: getUserInfo() ? getUserInfo().id : 0,
    }); */

    await fetchMethod('/api/Collegepreferencesettings/suggestedcolleges', { athleteid: getUserInfo() ? getUserInfo().id : 0, limit: 3, offset: 0 }, "POST", true).then(res => {
      if(res.status == 200){
        setSuggestedCollegeList(res.data.response.data);
        setTotalCount(res.data.response.totalCount);
      }
    })
  };

  const refreshCollegeList = () => {
    props.collegesListStart({
      query: getCollegesListQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          order: "collegerank ASC",
          active: 1,
        },
      },
    });
  };

  const handleAddToFavorite = (e, interestLevelId=1) => {
    e?.e.preventDefault();
    props.addMyCollegeStart({
      data: {
        collegeId: addCollageDetails.id,
        userId: getUserInfo() ? getUserInfo().id : 0,
        //this interest level is added to add the college
        // by default when we give 0 it is not adding  so given 1
        interestLevel: parseInt(interestLevelId),
      },
      refreshPage,
    });
  };

  const refreshPage = () => {
    getSearchCollegesData();
    refreshCollegeList();
    props.userCollegeCardCountStart({
      query: getCollegesCountsQuery(getUserInfo().id),
    });
  };

  const getInterestLevelList = async () => {
    await fetchGraphMethod(getAllInterestLevelQuery, {}, true).then(res => {
      if(res.status == 200){
        setInteresrLevelList(res?.data?.data?.allInterestlevels?.Interestlevels)
      }
    })
  }

  const onCloseInterestModal = () => {
    setShowInterestModal(false);
  }

  const onSelectHandler = async (interestLevelId) => {
    setShowInterestModal(false);
    refreshPage();
  }

  const onAddCollage = async (e, item) => { 
    setAddCollageDetails(item)
    setShowInterestModal(true);
  }

  const handleCollegeClick = (collgId) => {
    props.viewCollegeSaveStart({
      query : saveViewCollegeQuery,
      variables : {
        obj : {
          athleteid : getUserInfo() ? getUserInfo().id : null,
          collegeid : collgId,
          collegeviewstatus : 1,
        }
      }
    })
  }

  return (
    <>
      <BackdropLoader open={props.addMyCollege.loading} />
      <div className="suggestCollegeBox">
        <div className="head">
          <h5 className="title">Suggested Colleges</h5>
        </div>
        <ul>
          {suggestedCollegeList && suggestedCollegeList.length > 0 ? 
            suggestedCollegeList.map((item, index) => (
              <li key={index}>
                <div className="imgBox">
                  <img src={!!item?.collegelogo ?`${imageDownloadUrl}/${item?.collegelogo}`  : TeamLogo} alt="" />
                </div>
                <div className="info">
                  <Link to={`/colleges/details/${item.id}/${item.isfav == undefined ? 0 : 1}`} onClick={() => handleCollegeClick(item.id)}>
                    <h5>{item.collegename}</h5>
                  </Link>
                  <p>
                    {item.conferencename == null ? "N/A" : item.conferencename}<br/>
                    {item ? `${item.city == null ? " " : getCamelCase(item.city)}, ${item.initials == null ? "" : item.initials}` : " "}<br/>
                    {item.collegedivisionname == null ? "N/A" : item.collegedivisionname}
                  </p>                  
                  <button
                    className="followBtn"
                    //onClick={(e) => handleAddToFavorite(e, item)}
                    onClick={(e) => onAddCollage(e, item)}
                  >
                    + Add College
                  </button>
                </div>
              </li>
            )) : <li className="link-text"><Link to="/colleges/preferences">Please set your preferences first</Link></li>}
        </ul>
        {totalCount && +totalCount > 3 ? (
          <div className="showMoreBox">
            <Link to="/homepage/suggestedcolleges">Show more</Link>
          </div>
        ) : null}
      </div>
      <Dialog
        open={showInterestModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            onCloseInterestModal()
          }
        }}
        className="matDialogCustom"
      >
        <InterestLevelModal
          interestValueList={interesrLevelList}
          onClose={onCloseInterestModal}
          onSelect={onSelectHandler}
          collegeDetails={addCollageDetails}
        />
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    /*searchCollegeList: state.athlete.dashboard.colleges.searchCollegeList,*/
    addMyCollege: state.athlete.dashboard.home.addMyCollege,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    /*searchCollegeListAPIStart: (data) =>
      dispatch(searchCollegeListAPIStart(data)),*/
    addMyCollegeStart: (data) => dispatch(addMyCollegeStart(data)),
    collegesListStart: (data) => dispatch(collegesListStart(data)),
    userCollegeCardCountStart: (data) =>
      dispatch(userCollegeCardCountStart(data)),
    viewCollegeSaveStart: (data) => dispatch(viewCollegeSaveStart(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestedCollegeBox);
