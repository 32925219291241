import React, { useState, useEffect, useRef } from "react";
import VerificationIcon from "./assets/img/complete-verification-icon.svg";
import LeftSideEditProfile from "./LeftSideEditProfile";
import FormControl from "@mui/material/FormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Title } from "./videoPhoto/Component";
import Dialog from "@mui/material/Dialog";
import closeGrayIcon from "./assets/img/close-gray-icon.svg";
import trashCircleIcon from "./assets/img/trash-circle-icon.svg";
import { getAllOfferListQuery } from "../../../../common/CommonQuery";
import {
  offersListStart,
  offersListsaveStart,
} from "../../../../../redux/athlete/dashboard/editUserProfile/offers";
import { connect, useDispatch } from "react-redux";
import { getUserInfo, getAuthToken } from "../../../../../services/authService";
import {
  getAllOfferQuery,
  saveOffersQuery,
  filterCollegeNameQuery,
  updateOfferQuery,
  getUserInterestCollegeLevel,
  saveUserCollegeQuery,
  getUserCollegedata,
  isfavCollege
} from "./OfferQuery";
import BackdropLoader from "../../../../common/Loader";
import { getAllInterestLevelQuery } from "../../../../common/CommonQuery";
import { offerListStart } from "../../../../../redux/common/offerList";
import { interestLevelListStart } from "../../../../../redux/common/interestLevelList";
import FormLabel from "@mui/material/FormLabel";
import MuiAutoComplete from "../../../../common/AutoComplete";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";
import toastr from "toastr";
import ScreenSwitch from "./ScreenSwitch";
import SubHeader from "./SubHeader";
import GoTopButton from "./GoTopButton";
import { getCollegesListQuery } from "../colleges/myColleges/CollegeListQuery";
import { collegesListStart } from "../../../../../redux/athlete/dashboard/colleges/collegeList";
import { getSlashFormattedDate, getUTCTimeDateOverall } from "../../../../../utils/dateTimeFormatter";
import { updateUserCollegeStart } from "../../../../../redux/athlete/dashboard/colleges/updateUserCollege";
import { myProgressListStart, myProgressSaveStart } from "../../../../../redux/athlete/dashboard/colleges/myProgress";
import { getAllProgresses, saveProgressQuery } from "../colleges/searchColleges/CollegeDetailsQuery";
import { addMyCommitmentStart, getCommitmentStart } from "../../../../../redux/athlete/profile/commitment";
import { getMyCommitmentQuery, getMySignedCommitmentQuery, saveSignedCommitment } from "../homepage/leftSideBar/query";
import { saveMyCommitmentQuery } from "../profile/ProfileEdit/profileQuery";
import swal from "sweetalert";
import {getCamelCase} from "../../../../common/camelCaseFunc";
import history from "../../../../../services/history";
import { Link } from "react-router-dom";
import { viewCollegeSaveStart } from "../../../../../redux/athlete/dashboard/colleges/viewCollege";
import { saveViewCollegeQuery } from "../colleges/searchColleges/SearchCollegesQuery";
import { sendAddOfferNotification, sendRemoveOfferNotification, sendRemoveCommitmentNotification,sendRemoveSignedCommitmentNotification } from "../../../../../utils/sendNotificationToAdvisor";

const CollageBox = ({
  collage,
  onAddEditClick,
  onDeleteClick,
  allOfferCollegeData,
  singleOfferValue,
  saveOfferData,
  listOfAllOffer,
  collegesList,
  progressId,
  myProgressSaveStart,
  myProgress,
  myCommitment,
  addMyCommitmentStart,
  visibleToggleData,
  viewCollegeSaveStart
}) => {
  const [visibleToggle, setVisibleToggle] = useState(visibleToggleData);
  const [showDeleteOfferModal, setshowDeleteOfferModal] = useState(false);
  const [offersId, setOffersId] = useState(0);
  const [favCollegeId, setFavCollegeId] = useState(null);
  const [isCollegeInProgress, setIsCollegeInProgress] = useState(null);
  const [isAddedInCommitment, setIsAddedInCommitment] = useState(null);
  const [deletedOfersId, setDeletedOfersId] = useState(0);
  const [deletedCollageName, setDeletedCollageName] = useState(null);
  const handleEdit = (e, data) => {
    e.preventDefault();
    onAddEditClick();
    singleOfferValue(data);
  };
  const listOfAllOffers = () => {
    listOfAllOffer();
  }

  const updateVisible = (isChecked, offersId) => {
    saveOfferData({
      query: saveOffersQuery,
      variables: {
        obj: {
          id: +offersId,
          managevisible: isChecked === true ? 1 : 0,
        },
      },
      listOfAllOffers: listOfAllOffers
    });
  };

  const showModal = async (e, offersId, index) => {
    const isChecked = e.target.checked;
    if(isChecked) {
      const willSave = await swal({
        text: "I understand that my offer will be shared publicly and that my coach will review the validity of the information.",
        icon: 'warning',
        buttons: {
            confirm: { text: "I Agree" },
            cancel: "Cancel",  
        },
      });

      if(willSave){
        handleVisibleChange(isChecked, offersId, index);
      }
    } else {
        handleVisibleChange(isChecked, offersId, index);
    }
  };

  const handleVisibleChange = async (isChecked, offersId, index) => {
    let visibleCheck = visibleToggle;
    visibleCheck[index] = isChecked == true ? 1 : 0;
    setVisibleToggle([...visibleCheck]);
    let handleVisible = await updateVisible(isChecked, offersId);
    // listOfAllOffer();
  };
  const handleDeleteModal = () => {
    setshowDeleteOfferModal(!showDeleteOfferModal);
  };
  const handleDeleteOffer = (e, offersId, collegeId, collegeDeletedId, collageName=null) => {
    const isInProgress = myProgress && myProgress.data ? myProgress.data.filter((progressdata) => {
      return progressdata.collegeid == collegeId
    }) : null;
    let commitmentId = null;
    if (myCommitment && myCommitment[0] && myCommitment[0].collegeid == collegeId) {
      commitmentId = myCommitment[0].id;
    }
    setDeletedCollageName(collageName)
    setIsAddedInCommitment(commitmentId);
    setDeletedOfersId(collegeDeletedId)
    setIsCollegeInProgress(isInProgress);
    e.preventDefault();
    setFavCollegeId(collegeId);
    handleDeleteModal();
    setOffersId(offersId);
  };

  useEffect(() => {
    if (allOfferCollegeData?.length > 0) {
      allOfferCollegeData?.map((item, index) => {
        changeToggleData(item, index)
      })
    }
  }, [])

  const changeToggleData = (data, indexKey) => {
    let fieldData = visibleToggle;
    fieldData[indexKey] = data.managevisible ? 1 : 0;
    setVisibleToggle(fieldData)
  }

  const redirectToCollege = async (collegeid) => {
    let variable = {
      "where": {
        "userId": +getUserInfo().id,
        "collegeId": +collegeid,
        "isfav": 1,
        "active": 1
      }
    }
    const getIsFav = await fetchGraphMethod(isfavCollege, variable, true);
    if (getIsFav?.data?.data?.allUsercolleges) {
      let favCollegeValue = getIsFav?.data?.data?.allUsercolleges
      if (favCollegeValue.Usercolleges != "") {
        history.push({ pathname: `/colleges/details/${collegeid}/1` });
      } else {
        history.push({ pathname: `/colleges/details/${collegeid}/0` });
      }
    }

    //Save view college.
    viewCollegeSaveStart({
      query : saveViewCollegeQuery,
      variables : {
        obj : {
          athleteid : getUserInfo() ? getUserInfo().id : null,
          collegeid : +collegeid,
          collegeviewstatus : 1,
        }
      }
    })
  }

  return (
    <>
      {allOfferCollegeData.map((offerDetails, index) => {
        return <li key={index}>
          <div className="reference-box">
            <div className="title-box">
              <h3>
                <Link to="#" onClick={(e) => { redirectToCollege(offerDetails?.offercollegeidmaprel?.Colleges[0]?.id) }}>
                  {offerDetails.offercollegeidmaprel === undefined
                    ? ""
                    : offerDetails.offercollegeidmaprel.Colleges[0] &&
                      offerDetails.offercollegeidmaprel.Colleges[0] &&
                      `${offerDetails?.offercollegeidmaprel?.Colleges?.[0]?.collegename ?? "" } - [${!!offerDetails?.offercollegeidmaprel?.Colleges[0]?.city ?
                          getCamelCase(offerDetails?.offercollegeidmaprel?.Colleges[0]?.city) :  ""}, ${!!offerDetails?.offercollegeidmaprel?.Colleges[0]?.state ?
                          getCamelCase(offerDetails?.offercollegeidmaprel?.Colleges[0]?.state) : ""
                      }]`
                  }
                </Link>
              </h3>
              <div className="button-box">
                <button
                  className="trash-button"
                  onClick={(e) => {
                    handleEdit(e, offerDetails);
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
            <ul className="reference-detail-box">
              <li>
                <span>College Name </span>{" "}
                <span>
                  {offerDetails.offercollegeidmaprel === undefined
                    ? ""
                    : offerDetails.offercollegeidmaprel.Colleges[0] &&
                      `${offerDetails?.offercollegeidmaprel?.Colleges?.[0]?.collegename ?? "" } - [${!!offerDetails?.offercollegeidmaprel?.Colleges[0]?.city ?
                          getCamelCase(offerDetails?.offercollegeidmaprel?.Colleges[0]?.city) :  ""}, ${!!offerDetails?.offercollegeidmaprel?.Colleges[0]?.state ?
                          getCamelCase(offerDetails?.offercollegeidmaprel?.Colleges[0]?.state) : ""
                      }]`}
                </span>
              </li>
              <li>
                <span>Offer Type</span>{" "}
                <span>
                  {offerDetails.offerofferidmaprel === undefined
                    ? ""
                    : offerDetails.offerofferidmaprel.Offersdropdwns[0]
                      ? offerDetails.offerofferidmaprel.Offersdropdwns[0]
                        .offeresvalue
                      : ""}
                </span>
              </li>
              <li>
                <span>Interest Level</span>{" "}
                <span>
                  {offerDetails.offerinterestlevelidmaprel === undefined
                    ? ""
                    : offerDetails.offerinterestlevelidmaprel.Interestlevels[0]
                      ? offerDetails.offerinterestlevelidmaprel.Interestlevels[0]
                        .interestlevelvalue
                      : ""}
                </span>
              </li>
              <li>
                <span>Offer Date</span>{" "}
                <span>
                  {offerDetails && offerDetails.offerdate ? (getUTCTimeDateOverall(offerDetails.offerdate)) : ""}
                </span>
              </li>
              <li className="flex-none">
                <div className="flex-box">
                  <span>
                    <div className="form-check form-switch">
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        {visibleToggle[index] === 0 ? "Private" : "Public"}
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={
                          visibleToggle[index]
                        }
                        onChange={(e) => {
                          showModal(e, offerDetails.id, index);
                        }}
                      />
                    </div>
                  </span>
                  <span>
                    <button
                      className="trash-button"
                      onClick={(e) => {
                        let collageName = offerDetails.offercollegeidmaprel.Colleges.length ? offerDetails?.offercollegeidmaprel?.Colleges?.[0]?.collegename : ""
                        handleDeleteOffer(e, offerDetails.id, offerDetails.offercollegeidmaprel.Colleges[0] && offerDetails.offercollegeidmaprel.Colleges[0].id, offerDetails.collegeid, collageName);
                      }}
                    >
                      <span className="icon-box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.9"
                          height="17.5"
                          viewBox="0 0 15.9 17.5"
                        >
                          <g transform="translate(-3.75 -2.25)">
                            <path
                              className="a"
                              d="M4.5,9H18.9"
                              transform="translate(0 -2.8)"
                            ></path>
                            <path
                              className="a"
                              d="M18.7,6.2V17.4A1.6,1.6,0,0,1,17.1,19h-8a1.6,1.6,0,0,1-1.6-1.6V6.2m2.4,0V4.6A1.6,1.6,0,0,1,11.5,3h3.2a1.6,1.6,0,0,1,1.6,1.6V6.2"
                              transform="translate(-1.4)"
                            ></path>
                            <path
                              className="a"
                              d="M15,16.5v4.8"
                              transform="translate(-4.9 -6.3)"
                            ></path>
                            <path
                              className="a"
                              d="M21,16.5v4.8"
                              transform="translate(-7.7 -6.3)"
                            ></path>
                          </g>
                        </svg>
                      </span>
                      Delete
                    </button>
                  </span>
                </div>
                <p className="string-text">
                </p>
              </li>
            </ul>
          </div>
        </li>
      })}
      <Dialog open={showDeleteOfferModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  handleDeleteModal()
                }
              }}
              className="matDialogCustom"
      >
        <DeleteItem
          onDeleteClick={handleDeleteModal}
          deleteOfferesId={offersId}
          favCollegeId={favCollegeId}
          deleteoffer={saveOfferData}
          allOfferAfterDeletion={listOfAllOffer}
          collegesList={collegesList}
          deletedOfersId={deletedOfersId}
          progressId={progressId}
          myProgressSaveStart={myProgressSaveStart}
          isCollegeInProgress={isCollegeInProgress}
          isAddedInCommitment={isAddedInCommitment}
          addMyCommitmentStart={addMyCommitmentStart}
          deletedCollageName={deletedCollageName}
        />
      </Dialog>
    </>
  );
};
const AddEditOverlay = (props) => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [addOffers, setAddOffers] = useState({
    collegeOfferId: "",
    collegeName: "",
    interestLevelId: "",
    offerId: "",
    offerDate: currentDate,
    alreadyAddCollege: 0,
    athleteFavCollege: 0,
  });
  const [errors, setErrors] = useState({});
  const [collegeArray, setCollegeArray] = useState([]);
  const [isCollegeInFav, setIsCollegeInFav] = useState([]);
  const [progressData, setProgressData] = useState(null);
  const [progressId, setProgressId] = useState(null);
  //for progress bar
  const dispatch = useDispatch()
  useEffect(async () => {
    if (props.singleOffer != "") {
      let isExistInterest = await isExistInterestLevelForUser(
        props.singleOffer.offercollegeidmaprel.Colleges[0],
        "interestLevelId",
        false
      );
      const offerObj = {
        active: props.singleOffer.offercollegeidmaprel.Colleges[0]?.active ?? 0,
        city: props.singleOffer.offercollegeidmaprel.Colleges[0]?.city ?? "",
        collegename: `${props?.singleOffer?.offercollegeidmaprel?.Colleges?.[0]?.collegename ?? ""} - [${getCamelCase(props?.singleOffer?.offercollegeidmaprel?.Colleges?.[0]?.city ?? "") },${getCamelCase(props?.singleOffer?.offercollegeidmaprel?.Colleges[0]?.state ?? "")}]`,
        id: props.singleOffer.offercollegeidmaprel.Colleges[0]?.id ?? 0,
        state: props.singleOffer.offercollegeidmaprel.Colleges[0]?.state ?? ""
      }
      let fieldsT = addOffers;
      fieldsT["collegeOfferId"] = props.singleOffer.id;
      fieldsT["collegeName"] = offerObj;
      fieldsT["interestLevelId"] = props.singleOffer.active == 1 ? props.singleOffer.interestlevelid : null;
      fieldsT["offerId"] = props.singleOffer.offerid;
      fieldsT["offerDate"] = props.singleOffer.offerdate;
      setAddOffers({ ...fieldsT });
    }
  }, [props.singleOffer]);
  useEffect(() => {
    if (props?.favCollegeOfferData?.length > 0) {
      let data = props.favCollegeOfferData;
      let fieldsT = addOffers;
      fieldsT["collegeOfferId"] = data.offercollegeidmaprel && data.offercollegeidmaprel.Colleges && data.offercollegeidmaprel.Colleges[0].id;
      fieldsT["collegeName"] = data.offercollegeidmaprel && data.offercollegeidmaprel.Colleges[0];
      fieldsT["interestLevelId"] = data.interestlevelid;
      fieldsT["offerId"] = data.offerid;
      fieldsT["offerDate"] = data.offerdate;
      setAddOffers({ ...fieldsT });
    }
    if (props?.previousData) {
      let fieldsT = addOffers;
      fieldsT["collegeOfferId"] = props.previousData && props.previousData.collegeOfferId ? props.previousData.collegeOfferId : null;
      fieldsT["collegeName"] = props.previousData && props.previousData ? props.previousData : null;
      fieldsT["interestLevelId"] = props.previousData && props.previousData.interestLevel ? props.previousData.interestLevel : null;
      fieldsT["offerId"] = props.previousData && props.previousData.offerType ? props.previousData.offerType : null;
      fieldsT["offerDate"] = props.previousData && props.previousData.offerDate ? new Date(`${props.previousData.offerDate}`).toISOString().split("T")[0] : currentDate;
      setAddOffers({ ...fieldsT });
    }
  }, [props.favCollegeOfferData])
  const isExistInterestLevelForUser = async (collegeId, field, value) => {
    if (collegeId != null) {
      const variables = {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          collegeid: +collegeId.id,
          active: 1,
        },
      };
      const interestLevel = await fetchGraphMethod(
        getUserInterestCollegeLevel,
        variables,
        true
      );
      if (interestLevel?.status == 200 && interestLevel?.statusText == "OK") {
        if (interestLevel.data.data.allUsercolleges.Usercolleges.length != 0) {
          let fieldsT = addOffers;
          fieldsT[field] = parseInt(
            interestLevel.data.data.allUsercolleges.Usercolleges[0]
              .collegeinterestlevel
          );
          fieldsT["alreadyAddCollege"] = 1;
          fieldsT["athleteFavCollege"] = parseInt(
            interestLevel.data.data.allUsercolleges.Usercolleges[0].id
          );
          setAddOffers({ ...fieldsT });
        }
      }
      if (value == true) {
        const isCollegeExist = await fetchGraphMethod(
          getAllOfferQuery,
          variables,
          true
        );
        if (isCollegeExist.data.data.allOffers.totalCount != 0) {
          // messagePopup("", "College offer already added.", "error");
          toastr.error("College offer already added.", "", {
            progressBar : true,timeOut: 2000,
            closeButton: true, positionClass: "toast-top-full-width",
            showEasing: "swing", fadeIn: 40000,
            preventDuplicates:true,
          })
        }
      }
    }
  };

  const handleDropDownChange = async (collegeName) => {
    const variables = {
      where: {
        active: 1,
        and: [
          {
            or: [
              {
                collegename: {
                  like: `%${collegeName.target.value}%`,
                  options: "i",
                },
              },
            ],
          },
        ],
      },
    };
    if (collegeName.target.value.toString().length > 2) {
      let res = await fetchGraphMethod(filterCollegeNameQuery, variables, true);
      if (res?.status == 200 && res?.statusText == "OK") {
        const newArray = []
        const clgData = res?.data?.data?.allColleges?.Colleges?.length > 0 ?
            res?.data?.data?.allColleges?.Colleges?.map(fil =>{
              fil['collegename'] = `${fil?.collegename} - [${!!fil?.city ? getCamelCase(fil?.city) : ""}, ${!!fil?.state ? getCamelCase(fil?.state) : ""}]`
              newArray.push(fil)
            })
            : []
        setCollegeArray(newArray);
      }
    }
  };

  const handleChange = (e, field) => {
    let fieldsT = addOffers;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setAddOffers({ ...fieldsT });
    setErrors({})
  };
  const clearDate = (e) => {
    e.preventDefault();
    if (addOffers.offerDate) {
      let fieldsT = addOffers;
      fieldsT["offerDate"] = "";
      setAddOffers({
        ...fieldsT
      })
    }
  }
  useEffect(() => {
    let collegeList = props.collegesList ? props.collegesList : "";
    const collegeId = collegeList && collegeList.filter((college) => {
      return addOffers && addOffers.collegeName && addOffers.collegeName.id === college.collegeid
    })
    if (collegeId) {
      setIsCollegeInFav(collegeId)
    }
  }, [addOffers])

  useEffect(() => {
    getAllProgressData();
  }, [])
  const getAllProgressData = () => {
    props.myProgressListStart({
      query: getAllProgresses,
      variables: {
        where: {
          userid: getUserInfo() ? getUserInfo().id : null,
          collegeid: addOffers.collegeName.id
        }
      }
    })
  }
  useEffect(() => {
    if (props.myProgress.data && props.myProgress.data) {
      setProgressData(props.myProgress.data);
      getProgressId();
    }
  }, [props.myProgress.data, addOffers.collegeName])

  const getProgressId = () => {
    const progressdata = progressData && addOffers?.collegeName?.id ? progressData.filter((data) => {
      return data.collegeid == addOffers.collegeName.id
    }) : null;
    if (progressdata && progressdata[0] && progressdata[0].id) {
      setProgressId(progressdata[0].id)
      props.getProgressId(progressdata[0].id);
    }
  }
  const addInAthleteCollege = async (offerid) => {
    const variables =
      isCollegeInFav.length !== 0
        ? {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : null,
            id: isCollegeInFav && isCollegeInFav[0] && isCollegeInFav[0].id ? +isCollegeInFav[0].id : null,
            collegeinterestlevel: parseInt(addOffers.interestLevelId),
            target: 1,
            contact: 1,
            interest: 1,
            collegeactive: 1,
            offer: 1,
            visit: 1,
          },
        }
        : {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : null,
            collegeinterestlevel: parseInt(addOffers.interestLevelId),
            collegeid: addOffers.collegeName && addOffers.collegeName.id ? parseInt(addOffers.collegeName.id) : null,
            active: 0,
            isfav: 0,
            collegerank: 0,
            target: 1,
            contact: 1,
            interest: 1,
            collegeactive: 1,
            offer: 1,
            visit: 1,
            signed: 0,
            alumni: 0,
            isaddbyotherplace: 1
          },
        };
    const addUserColege = await fetchGraphMethod(
      saveUserCollegeQuery,
      variables,
      true
    );
    props.getAllOffersData();
    if (addUserColege?.status == 200 && addUserColege?.statusText == "OK") {
      if (offerid) {
        // messagePopup("", "Offer Updated successfully.", "success")
        toastr.success("Offer Updated successfully.", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates:true,
        })
          ;
      } else {
        // messagePopup("", "Offer added successfully.", "success");
        toastr.success("Offer added successfully.", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates:true,
        })
      }
    } else {
      // messagePopup("", "Offer not added.", "success");
      toastr.success("Offer not added.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
    }
  };
  const formValidations = () => {
    let errorsT = errors;
    let formIsValid = true;

    if (addOffers.collegeName && addOffers.collegeName.length == 0 || addOffers.collegeName == null) {
      formIsValid = false;
      errorsT["collegeName"] = "Please enter the College Name";
    }
    if(!addOffers.interestLevelId) {
      formIsValid = false;
      errorsT["interestLevelId"] = "Please select the Interest Level";
    }
    setErrors({
      ...errorsT
    });
    return {
      formIsValid,
    };
  };

  const updateOfferTypeInProgressTab = () => {
    props.myProgressSaveStart({
      query: saveProgressQuery,
      variables: progressId ? {
        obj: {
          id: progressId,
          offertypeid: parseInt(addOffers.offerId),
        }
      } : {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : null,
          collegeid: addOffers.collegeName && addOffers.collegeName.id ? parseInt(addOffers.collegeName.id) : null,
          offertypeid: parseInt(addOffers.offerId),
        }
      }
    })
  }
  const saveOfferData = async (e) => {
    e.preventDefault();
    const validateForm = formValidations();
    if (validateForm.formIsValid == true) {
      let testObj = addOffers.collegeOfferId == "" || addOffers.collegeOfferId === null
        ? {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            collegeid: addOffers.collegeName && addOffers.collegeName.id ? parseInt(addOffers.collegeName.id) : null,
            interestlevelid: addOffers.interestLevelId ? parseInt(addOffers.interestLevelId) : null,
            offerid: addOffers.offerId ? parseInt(addOffers.offerId) : null,
            offerdate: addOffers.offerDate,
            managevisible:0,
          },
        }
        : {
          obj: {
            id: addOffers.collegeOfferId,
            userid: getUserInfo() ? getUserInfo().id : null,
            collegeid: addOffers.collegeName && addOffers.collegeName.id ? parseInt(addOffers.collegeName.id) : null,
            interestlevelid: parseInt(addOffers.interestLevelId),
            offerid: parseInt(addOffers.offerId),
            offerdate: addOffers.offerDate,
          },
        };
      props.saveData.offersListsaveStart({
        query: saveOffersQuery,
        variables: testObj,
        nextScreen: (response) => {
          if (response?.errors?.length > 0) {
            if (response?.errors[0]?.message?.status === 201) {
              messagePopup("", response?.errors[0]?.message?.msg, "warning")
              props.getAllOffersData();
            }
          }
          else {
            let offerCollageName = addOffers?.collegeName?.collegename ? addOffers?.collegeName?.collegename?.split("-")?.[0] : "SF"
            sendAddOfferNotification(offerCollageName)
            addInAthleteCollege(addOffers.collegeOfferId);
            updateOfferTypeInProgressTab();
            props.getAllCollegeData();
            props.getAllOffersData();
            props.onAddEditClick();
          }
        }
      });

      //When any new offer added then marked college as fav.
      if(addOffers.collegeOfferId == "" || addOffers.collegeOfferId === null) {
        await fetchMethod('/api/Usercolleges/addCollege', {
          collegeId: addOffers.collegeName && addOffers.collegeName.id ? parseInt(addOffers.collegeName.id) : null,
          userId: getUserInfo() ? getUserInfo().id : 0,
          interestLevel: parseInt(addOffers.interestLevelId),
          isOffered: 1,
          isCommitment: 0,
        }, "post", true).then(res => {
           
        });
        
      }

    } else {
      props.openModal();
    }
    dispatch({ type: "RENDER_COUNT_START", payload: 1 })
  };

  return (
    <div
      className="modal fade common-modal addEditOverlay show"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <form className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{addOffers && addOffers.collegeOfferId ? "Edit" : "Add"} New Offer</h5>
            <button
              type="button"
              className="close"
              style={{ color: "#000" }}
              onClick={props.onAddEditClick}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>College Name <span style={{ color: 'red' }}>*</span></label>
              <MuiAutoComplete
                id="free-solo-demo"
                freeSolo={false}
                options={collegeArray}
                disabled={props?.previousData?.length > 0 || addOffers?.collegeOfferId || props?.previousData != "" ? true : false}
                getOptionLabel={(option) =>
                  option.collegename ? option.collegename : ""
                }
                value={addOffers.collegeName || ""}
                onChange={(event, newValue) => {
                  handleDropDownChange(
                    { target: { value: newValue } },
                    "collegeName"
                  );
                }}
                onChangeObj={(event, newValue) => {
                  handleChange({ target: { value: newValue } }, "collegeName");
                  isExistInterestLevelForUser(
                    addOffers.collegeName,
                    "interestLevelId"
                  );
                }}
                inputPlaceholder=""
              />
              <div className="error">{errors["collegeName"]}</div>
            </div>
            <div className="form-group">
              <label>Interest Level <span style={{ color: 'red' }}>*</span></label>
              <FormControl variant="outlined" fullWidth>
                <Select
                  // labelId="demo-simple-select-outlined-label"
                  // id="demo-simple-select-outlined"
                  value={addOffers.interestLevelId || ""}
                  onChange={(e) => {
                    handleChange(e, "interestLevelId");
                  }}
                  // label="Select"
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {props.interestLevelDropDown
                    ? props.interestLevelDropDown.map((interestItem, idx) => (
                      <MenuItem key={idx} value={interestItem.id}>
                        {interestItem.interestlevelvalue}
                      </MenuItem>
                    ))
                    : null}
                </Select>
              </FormControl>
              <div className="error">{errors["interestLevelId"]}</div>
            </div>
            <div className="form-group">
              <label>Offer Type</label>
              <FormControl variant="outlined" fullWidth>
                <Select
                  // labelId="demo-simple-select-outlined-label"
                  // id="demo-simple-select-outlined"
                  value={addOffers.offerId || ""}
                  onChange={(e) => {
                    handleChange(e, "offerId");
                  }}
                  // label="Select"
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {props.offerListDropDown
                    ? props.offerListDropDown.map((interestItem, idx) => (
                      <MenuItem key={idx} value={interestItem.id}>
                        {interestItem.offeresvalue}
                      </MenuItem>
                    ))
                    : null}
                </Select>
              </FormControl>
            </div>
            <div className="form-group position-relative">
              <label>Offer Date </label>
              <input
                className="form-control"
                type="Date"
                name="date"
                id="date"
                placeholder="mm/dd/yyyy"
                value={addOffers.offerDate}
                max="9999-12-31"
                onChange={(e) => handleChange(e, "offerDate")}
              />
              {addOffers.offerDate ? (
                <span className="date-close-icon" onClick={(e) => clearDate(e)}>&times;</span>
              ) : null}
              <div className="error">{errors["offerDate"]}</div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn blue"
              onClick={(e) => {
                saveOfferData(e);
              }}>
              {addOffers.collegeOfferId ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const DeleteItem = ({
  onDeleteClick,
  deleteOfferesId,
  deleteoffer,
  allOfferAfterDeletion,
  favCollegeId,
  collegesList,
  progressId,
  myProgressSaveStart,
  isCollegeInProgress,
  isAddedInCommitment,
  addMyCommitmentStart,
  deletedOfersId,
  deletedCollageName
}) => {
  const [collegeId, setCollegeId] = useState();
  const [signedCommitment, setSignedCommitment] = useState([]);
  useEffect(() => {
    const collegeData = collegesList && collegesList.filter((college) => {
      return college.collegeid == favCollegeId
    })
    if (collegeData && collegeData[0] && collegeData[0].id) {
      setCollegeId(collegeData[0].id)
    }
  }, [])

  useEffect(async () => {
    if(deletedOfersId) {
      await fetchGraphMethod(
        getMySignedCommitmentQuery,
        {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            active: 1,
            collegeid: deletedOfersId
          },
        },
        true
      ).then((res) => {
        if (res?.data?.data?.allSignedcommitments?.totalCount) {
          setSignedCommitment(
            res?.data?.data?.allSignedcommitments?.Signedcommitments
          );
        } else {
          setSignedCommitment([]);
        }
      });
    }
  }, []);

  const deleteOffer = () => {
    deleteoffer({
      query: saveOffersQuery,
      variables: {
        obj: {
          id: +deleteOfferesId,
          active: 0,
        },
      },
    });
  }
  const deleteOfferTypeFromProgress = () => {
    myProgressSaveStart({
      query: saveProgressQuery,
      variables: {
        obj: {
          id: isCollegeInProgress && isCollegeInProgress[0] && isCollegeInProgress[0]?.id,
          offertypeid: null,
          signcommitmenttopalybaseball: 0,
          commitmentid: null,
        }
      }
    })
  }
  const deleteCommitmentIfAdded = () => {
    addMyCommitmentStart({
      query: saveMyCommitmentQuery,
      variables: {
        obj: {
          id: isAddedInCommitment,
          active: 0,
        },
      },
    })
  }

  const deleteSignedCommitment = async() => {
    if (signedCommitment.length > 0) {
      await fetchGraphMethod(
        saveSignedCommitment,
        {
          obj: {
            id: signedCommitment[0].id,
            active: 0,
          },
        },
        true
      ).then((res) => {
       
      });
    }
  }

  const deleteOffers = async () => {
    await deleteOffer();
    sendRemoveOfferNotification(deletedCollageName);
    allOfferAfterDeletion();
    await deleteOfferTypeFromProgress();
    if (isAddedInCommitment !== null) {
      await deleteCommitmentIfAdded();
      sendRemoveCommitmentNotification(deletedCollageName)
    }
    if(signedCommitment.length > 0) {
      await deleteSignedCommitment();
      sendRemoveSignedCommitmentNotification(deletedCollageName);
    }    
  };
  const removeOfferStatus = async () => {
    const variables = {
      obj: {
        userid: getUserInfo() ? getUserInfo().id : null,
        id: collegeId,
        target: 1,
        contact: 0,
        interest: 0,
        collegeactive: 0,
        visit: 0,
        offer: 0,
        commit: 0,
        signed: 0,
        alumni: 0
      },
    }
    if (collegeId !== null && collegeId !== undefined) {
      const removeUserCollege = await fetchGraphMethod(
        saveUserCollegeQuery,
        variables,
        true
      );
      allOfferAfterDeletion();
    }
  }

  const updateUerDetailsStatus = async () => {
    let userVar = {
      obj: {
        collegeid: deletedOfersId,
        userid: getUserInfo() ? getUserInfo().id : 0,
        order: "id desc",
      },
      first: 1,
      last: 1
    }
    const getUserDetailStatusData = await fetchGraphMethod(getUserCollegedata, userVar, true)
    if (getUserDetailStatusData?.data?.data?.allUsercolleges?.Usercolleges.length != 0) {
      const variables = {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : null,
          id: getUserDetailStatusData.data.data.allUsercolleges ? getUserDetailStatusData.data.data.allUsercolleges.Usercolleges[0].id : null,
          target: 1,
          contact: 0,
          interest: 0,
          collegeactive: 0,
          visit: 0,
          offer: 0,
          commit: 0,
          signed: 0,
        },
      }
      const removeUserCollege = await fetchGraphMethod(
        saveUserCollegeQuery,
        variables,
        true
      );
      allOfferAfterDeletion();
    }
  }

  const handleDelete = async () => {
    const deleteOfferData = await deleteOffers();
    onDeleteClick();
    allOfferAfterDeletion();
    if (collegeId !== null && collegeId !== undefined) {
      removeOfferStatus();
    } else {
      updateUerDetailsStatus()
    }
  };

  return (
    <div
      className="modal fade common-modal trash-modal show"
      id="ReferenceTrashModal"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" onClick={onDeleteClick}>
              <img src={closeGrayIcon} alt="close Gray Icon" />
            </button>
            <div className="icon-box-first">
              <img src={trashCircleIcon} alt="trash Circle Icon" />
            </div>
            <div className="content-box">
              <h4>Are you sure you want to delete this offer ?
              </h4>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              onClick={(e) => {
                handleDelete(e);
              }}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn gray"
              data-dismiss="modal"
              onClick={onDeleteClick}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditSection = (props) => {
  const [visibleToggle, setVisibleToggle] = useState([]);
  const handleAddOffer = (e) => {
    e.preventDefault();
    props.onAddEditClick();
    props.getSingleRowData();
  };

  useEffect(() => {
    if (props?.allCollegeOfferData?.length > 0) {
      let fields = visibleToggle
      props?.allCollegeOfferData?.map((item, index) => {
        fields[index] = item.managevisible == 1 ? 1 : 0;
      });
      setVisibleToggle([...fields])
    }
  }, [props?.allCollegeOfferData])
  return (
    <div className="editSection">
      <Title title={"Offers"} />
      <div className="check-list-sec">
        <div className="editprofile-common-form">
          <div className="event-main-wrapper">
            <div className="event-top-block">
              <h3 className="font15">
                Your offers are private unless you change settings to public
              </h3>
              <div className="short-text">
                <button
                  className="btn green"
                  onClick={(e) => {
                    handleAddOffer(e);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.75"
                    height="14.75"
                    viewBox="0 0 14.75 14.75"
                  >
                    <path
                      className="a"
                      d="M18.074,10.646H13.1V5.676a1.23,1.23,0,0,0-2.458,0v4.97H5.676a1.23,1.23,0,0,0,0,2.458h4.97v4.97a1.23,1.23,0,0,0,2.458,0V13.1h4.97a1.23,1.23,0,0,0,0-2.458Z"
                      transform="translate(-4.5 -4.5)"
                    />
                  </svg>
                  Add Offer
                </button>
              </div>
            </div>
            <div className="reference-data-box full">
              <ul className="reference-list">
                {props.allCollegeOfferData != "" ? (
                  <CollageBox
                    onAddEditClick={props.onAddEditClick}
                    onDeleteClick={props.onDeleteClick}
                    allOfferCollegeData={props.allCollegeOfferData}
                    singleOfferValue={props.getSingleRowData}
                    saveOfferData={props.data.offersListsaveStart}
                    listOfAllOffer={props.getAllOfferData}
                    collegesList={props.collegesList}
                    progressId={props.progressId}
                    myProgressSaveStart={props.myProgressSaveStart}
                    myProgress={props.myProgress}
                    myCommitment={props.myCommitment}
                    addMyCommitmentStart={props.addMyCommitmentStart}
                    visibleToggleData={visibleToggle}
                    viewCollegeSaveStart={props.viewCollegeSaveStart}
                  />
                ) : null}
              </ul>
            </div>
          </div>
          <div className="common-button-box with-next-prev center pl-0 pr-0">
            <div className="next-prev-button">
              <ScreenSwitch
                prevLink={"/collegeInsights"}
                nextLink={"/viewCommitment"}
                isSaved={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Offers = (props) => {
  useEffect(() => {
    getAllOfferList();
    getInterestLevelDropDownList();
    getOfferDropDownList();
    getUserCollegeList();
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);
  const [sideMenuOpen, setSideMenu] = useState(false),
    [showAddEditModal, setShowAddEditModal] = useState(false),
    [showDeleteModal, setShowDeleteModal] = useState(false),
    [singleOfferData, setSingleOfferData] = useState([]);
  const [collegesList, setCollegeList] = useState(null);
  const [previousData, setPreviousData] = useState();
  const [favCollegeOfferData, setFavCollgeOfferData] = useState([]);
  const [progressId, setProgressId] = useState(null);

  const getProgressId = (id) => {
    setProgressId(id);
  }
  //for progress bar
  const dispatch = useDispatch()
  const fakeInput = useRef(null);
  useEffect(() => {
    if (props.location.state) {
      setPreviousData(props.location.state)
      setShowAddEditModal(true);
    }
  }, [props.location.state])

  useEffect(() => {
    if (previousData && previousData.id) {
      getfavCollegeOfferData();
    }
  }, [previousData])
  const getAllOfferList = () => {
    props.offersListStart({
      query: getAllOfferQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
    setTimeout(()=>{
      window.scroll(0,0)
    },500)
  };
  const getInterestLevelDropDownList = () => {
    props.interestLevelListStart({
      query: getAllInterestLevelQuery,
      variables: null,
    });
  };
  const getUserCollegeList = () => {
    props.collegesListStart({
      query: getCollegesListQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          // active: 1,
          // active: 1, // cz of active 0 data offer status was not updating at my college screen
        },
      },
    });
  };
  const getCommitmentStart = () => {
    props.getCommitmentStart({
      query: getMyCommitmentQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  }
  useEffect(() => {
    if (props.collegesList.data) {
      setCollegeList(props.collegesList.data);
    }
  }, [props.collegesList]);
  const getOfferDropDownList = () => {
    props.offerListStart({
      query: getAllOfferListQuery,
      variables: null,
    });
  };
  const getfavCollegeOfferData = () => {
    let data = props.allOffersCollegeList && props.allOffersCollegeList.data && props.allOffersCollegeList.data.Offers;
    let collegeId = previousData && previousData.id;
    if (data) {
      const collegeData = data.find(function (college) {
        return college.collegeid === collegeId
      })
      if (collegeData) {
        setFavCollgeOfferData(collegeData);
      }
    }
  }
  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };
  useEffect(() => {
    getProgressdata();
    getCommitmentStart();
  }, [])

  const getProgressdata = () => {
    props.myProgressListStart({
      query: getAllProgresses,
      variables: {
        where: {
          userid: getUserInfo() ? getUserInfo().id : null,
        }
      }
    })
  }
  const onAddEditClick = () => {
    setShowAddEditModal(!showAddEditModal);
    setFavCollgeOfferData([]);
    setPreviousData([]);
  };
  const openModal = () => {
    setShowAddEditModal(true);
  }
  const onDeleteClick = () => {
    setShowDeleteModal(false);
  };

  const getSingleRowData = (singleOfferValue) => {
    setSingleOfferData(singleOfferValue == undefined ? [] : singleOfferValue);
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  return (

    <div className="pageContent">
      <BackdropLoader
        open={props.allOffersCollegeList.loading} />
      <div className="editProfile">
        <input className="fakeInput" type="text" ref={fakeInput} />

        <div className="container">
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
          >
            <SubHeader hamburger={onSideMenuClick} buttonHide={false} />
            <div className="sidebar">
              <LeftSideEditProfile isSaved={true} />
            </div>
            <EditSection
              data={props}
              onAddEditClick={onAddEditClick}
              onDeleteClick={onDeleteClick}
              allCollegeOfferData={
                props.allOffersCollegeList &&
                  props.allOffersCollegeList.data &&
                  props.allOffersCollegeList.data.Offers
                  ? props.allOffersCollegeList.data.Offers
                  : []
              }
              getSingleRowData={getSingleRowData}
              getAllOfferData={getAllOfferList}
              collegesList={collegesList}
              progressId={progressId}
              myProgressSaveStart={props.myProgressSaveStart}
              myProgress={props.myProgress}
              addMyCommitmentStart={props.addMyCommitmentStart}
              myCommitment={props.myCommitment && props.myCommitment.data ? props.myCommitment.data : null}
              viewCollegeSaveStart={props.viewCollegeSaveStart}
            />
          </div>
        </div>
        <GoTopButton goTop={goTop} />
      </div>
      <Dialog open={showAddEditModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onAddEditClick()
                }
              }}
              className="matDialogCustom"
      >
        <AddEditOverlay
          getAllOffersData={getAllOfferList}
          getAllCollegeData={getUserCollegeList}
          offerListDropDown={props.offerListDetails.data}
          interestLevelDropDown={props.interestLevelDetails.data}
          updateUserCollegeStart={props.updateUserCollegeStart}
          saveData={props}
          onAddEditClick={onAddEditClick}
          openModal={openModal}
          collegesList={collegesList}
          singleOffer={singleOfferData}
          favCollegeOfferData={favCollegeOfferData}
          collgesList={collegesList}
          previousData={previousData}
          myProgressListStart={props.myProgressListStart}
          myProgress={props.myProgress}
          myProgressSaveStart={props.myProgressSaveStart}
          getProgressId={getProgressId}
        />
      </Dialog>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allOffersCollegeList: state.athlete.editUserProfileData.offersData,
    offerListDetails: state.common.offerList,
    interestLevelDetails: state.common.interestLevelList,
    collegesList: state.athlete.dashboard.colleges.collegesList,
    updateUserCollege: state.athlete.dashboard.colleges.updateUserCollege,
    myProgress: state.athlete.dashboard.colleges.myProgress,
    myCommitment: state.athlete.profile.commitments,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    offersListStart: (data) => dispatch(offersListStart(data)),
    interestLevelListStart: (data) => dispatch(interestLevelListStart(data)),
    offerListStart: (data) => dispatch(offerListStart(data)),
    offersListsaveStart: (data) => dispatch(offersListsaveStart(data)),
    collegesListStart: (data) => dispatch(collegesListStart(data)),
    updateUserCollegeStart: (data) => dispatch(updateUserCollegeStart(data)),
    myProgressListStart: (data) => dispatch(myProgressListStart(data)),
    myProgressSaveStart: (data) => dispatch(myProgressSaveStart(data)),
    getCommitmentStart: (data) => dispatch(getCommitmentStart(data)),
    addMyCommitmentStart: (data) => dispatch(addMyCommitmentStart(data)),
    viewCollegeSaveStart: (data) => dispatch(viewCollegeSaveStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
