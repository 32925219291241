import React, { useEffect, useState} from 'react'
import ClaimProfileHeader from "../auth/claimProfile/claimProfileStep1/ClaimProfileHeader";
import DashboardHeader from "../../layouts/header/DashboardHeader";
import {getAuthToken} from "../../../services/authService";
import logo from './assets/img/logo.png'
import './assets/css/lading-page.scss'
import {fetchMethod} from "../../../services/httpService";

const RecruitingGuide = (props) =>{
    const [headerType, setHeaderType] = useState(true),
           [recData, setRecData] = useState({}),
          isToken = props?.match?.params?.isToken == 1 ? true : false;
    useEffect(()=>{
        getStatus(props)
        //for scrolling the page on top
        setTimeout(()=>{
            window.scrollTo(0,0)
        },1000)
        //api call for data
        recApiCall()
    },[])
    const getStatus = (props) =>{
        if (!!getAuthToken() || isToken){
            setHeaderType(false)
        }
    }
    const recApiCall = async () =>{
        const res = await fetchMethod('/api/landingpages/getLPRecruitingGuide',null,'GET',false)
        if(res?.status == 200 && res?.data?.response?.status == 200){
            const data = res?.data?.response?.data ?? {}
            setRecData(data)
        }else{
            setRecData({})
        }
    }
    return(
        <>
            {
            headerType ?
                <ClaimProfileHeader/>
                :
                <DashboardHeader/>
            }
            <section className="recruiting-guide-sec">
                <div className="block-info-box">
                    <div className="info-data">
                        <div className="brand-logo">
                            <a href={void 0}>
                                <img src={logo} alt="SportsForce"/>
                            </a>
                        </div>
                        <div className="tagline">Getting to the</div>
                        <h1 className="title">Commitment Letter</h1>
                        <p>The recruiting game has changed. The internet has revolutionized the way student-athletes
                            connect with college coaches.</p>
                    </div>
                </div>
                <div className="data-box">
                    <h2>How to use this guide</h2>
                    <p>
                        {recData?.howToUse?.content ?? ""}
                        {/*The recruiting game has changed. The internet has revolutionized the way student-athletes connect*/}
                        {/*with college coaches. Recruiting is no longer about settling for the poor fit school that*/}
                        {/*recruited you because you think it's your only option. It's about proactively getting yourself*/}
                        {/*to your dream school. That's the beauty of taking initiative - you get to pursue the options you*/}
                        {/*want, as opposed to settling for the options you get.*/}
                    </p>
                    <h2>What to do?</h2>
                    <p>
                        {recData?.whatToDoArr?.[0]?.content ?? ""}
                        {/*The most important thing you can do is to take action. This roadmap translates the complex*/}
                        {/*recruiting process into simple steps that tell you exactly what you need to do and when.*/}
                    </p>
                    <p>
                        <strong>
                            {recData?.whatToDoArr?.[1]?.content ?? ""}
                        {/*    If you see something in this guide that you can do NOW, immediately stop reading and DO*/}
                        {/*IT.*/}
                        </strong></p>
                    <p>
                        {recData?.whatToDoArr?.[2]?.content ?? ""}
                        {/*Begin with the fundamental chapters:*/}
                    </p>
                    <ol>
                        <li>
                            {recData?.whatToDoArr?.[3]?.content ?? ""}
                            {/*1. Building an Online Presence*/}
                        </li>
                        <li>
                            {recData?.whatToDoArr?.[4]?.content ?? ""}
                            {/*2. Contacting Coaches*/}
                        </li>
                    </ol>
                    <p>
                        {recData?.whatToDoArr?.[5]?.content ?? ""}
                        {/*Action items which you can complete instantly are organized in blocks fittingly labeled Do*/}
                        {/*It.*/}
                    </p>
                    <div className="button-wrap">
                        <button type={"button"} className="btn">Get Started</button>
                    </div>
                </div>
            </section>
        </>
    )
}
export default RecruitingGuide
