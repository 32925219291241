import React, { useState, useEffect, useRef, useCallback } from "react";
import "../../../../../../assets/css/stylesheet.scss";
import "../assets/css/suggestCollege.scss";
import LeftSideBar from "../leftSideBar/LeftSideBar";
import RightSideBar from "../rightSideBar/RightSideBar";
import { Link } from "react-router-dom";
import SuggestedCollegeCard from "./SuggestedCollegeCard";
import { connect } from "react-redux";
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";
import { searchCollegeListAPIStart } from "../../../../../../redux/athlete/dashboard/colleges/collegeSearchList";
import { getUserInfo } from "../../../../../../services/authService";
import BackdropLoader from "../../../../../common/Loader";
import { addMyCollegeStart } from "../../../../../../redux/athlete/dashboard/homepage/addMyColleges";
import {
  getCollegesCountsQuery,
  getCollegesListQuery,
} from "../../colleges/myColleges/CollegeListQuery";
import { getAllInterestLevelQuery } from "../../../../../common/CommonQuery";
import { collegesListStart } from "../../../../../../redux/athlete/dashboard/colleges/collegeList";
import { userCollegeCardCountStart } from "../../../../../../redux/athlete/dashboard/colleges/userCollegeCardCount";
import GoTopButton from "../../editUserProfile/GoTopButton";
import {fetchGraphMethod, fetchMethod} from "../../../../../../services/httpService";
import {imageDownloadUrl} from "../../../../../../config";

function SuggestedColleges(props) {
  const [suggestedCollegeList, setSuggestedCollegeList] = useState(null);
  const [interesrLevelList, setInteresrLevelList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [hasMore, setHasMore] = useState(false);

  const observer = useRef();
  const lastBookElementRef = useCallback(node => {
    if (observer?.current) observer?.current?.disconnect()
    observer.current = new IntersectionObserver(entires => {
      if (entires[0].isIntersecting && hasMore) {
        getSearchCollegesData(0, limit);
      }
    })
    if (node) observer.current.observe(node)
  }, [hasMore, limit]);

  useEffect(() => {
    getSearchCollegesData(0,10);
    getInterestLevelList();
  }, []);
  
  /*useEffect(() => {
    if (props.searchCollegeList.data) {
      const tempData = props.searchCollegeList.data
        ? props.searchCollegeList.data.colleges
        : null;
      setSuggestedCollegeList(tempData);
    }
  }, [props.searchCollegeList.data]);*/

  const getSearchCollegesData = async (offset, limitPara) => {
    /*
    props.searchCollegeListAPIStart({
      academicsSelectivity: [],
      areaOfStudy: [],
      athleticselectivity: [],
      collegeConference: [],
      collegeNameAndCity: "",
      collegeSize1: [],
      collegeTutionFee1: [],
      favoriteValue: "false",
      first: 0,
      last: 20,
      level: [],
      setting: [],
      state: [],
      type: [],
      userId: getUserInfo() ? getUserInfo().id : 0,
    }); */
    const res =  await fetchMethod('/api/Collegepreferencesettings/suggestedcolleges',
        { athleteid: getUserInfo() ? getUserInfo().id : 0, limit: limitPara, offset: offset },
        "POST", true)
      if(res.status == 200){
        setSuggestedCollegeList(res.data.response.data);
        setLimit(limitPara + 10)
        setHasMore(res.data.response.totalCount > res.data.response.data.length ?? 0 ? true : false)
      }
  };

  const handleAddToFavorite = (e) => {
    e?.e.preventDefault();
    /*props.addMyCollegeStart({
      data: {
        collegeId: data.id,
        userId: getUserInfo() ? getUserInfo().id : 0,
        interestLevel: parseInt(interestLevelId),
      },
      refreshPage,
    });*/
    refreshPage();
  };

  const refreshPage = () => {
    getSearchCollegesData();
    refreshCollegeList();
    props.userCollegeCardCountStart({
      query: getCollegesCountsQuery(getUserInfo().id),
    });
  };

  const refreshCollegeList = () => {
    props.collegesListStart({
      query: getCollegesListQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          order: "collegerank ASC",
          active: 1,
        },
      },
    });
  };

//func used for scrolling the page in top
  useEffect(()=>{
    setTimeout(()=>{
      window?.scrollTo(0,0)
    },1200)
  },[])
  const goTop=()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const getInterestLevelList = async () => {
    await fetchGraphMethod(getAllInterestLevelQuery, {}, true).then(res => {
      if(res.status == 200){
        setInteresrLevelList(res?.data?.data?.allInterestlevels?.Interestlevels)
      }
    })
  }

  return (
    <div>
      <BackdropLoader
        open={props.searchCollegeList.loading || props.addMyCollege.loading}
      />
      <div className="suggestCollegeSection">
        <div className="container">
          <div className="flexBox">
            <div className="leftDataBox">
              <LeftSideBar />
            </div>
            <div className="dataBox">
              <div className="backHome">
                <Link to="/homepage">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 447.243 447.243"
                    >
                      <path d="M420.361 192.229a31.967 31.967 0 00-5.535-.41H99.305l6.88-3.2a63.998 63.998 0 0018.08-12.8l88.48-88.48c11.653-11.124 13.611-29.019 4.64-42.4-10.441-14.259-30.464-17.355-44.724-6.914a32.018 32.018 0 00-3.276 2.754l-160 160c-12.504 12.49-12.515 32.751-.025 45.255l.025.025 160 160c12.514 12.479 32.775 12.451 45.255-.063a32.084 32.084 0 002.745-3.137c8.971-13.381 7.013-31.276-4.64-42.4l-88.32-88.64a64.002 64.002 0 00-16-11.68l-9.6-4.32h314.24c16.347.607 30.689-10.812 33.76-26.88 2.829-17.445-9.019-33.88-26.464-36.71z" />
                    </svg>
                  </span>
                  <span>Back to Homepage</span>
                </Link>
              </div>

              <div className="tabsBox">
                <h1 className="heading">Suggested for you</h1>
                <div className="tabFlex">
                  <div className="tab ">
                    <Link
                      to="#"
                      onClick={() =>
                        props.history.push("/homepage/trendingProfile")
                      }
                    >
                      Trending Profiles
                    </Link>
                  </div>
                  <div className="tab selected">
                    <Link to="#">Suggested Colleges</Link>
                  </div>
                </div>

                <div className="followingDataBox" style={ suggestedCollegeList?.length > 5 ?
                    {height: "460px", overflowY: "auto"} :
                    {} }
                > {suggestedCollegeList && suggestedCollegeList?.length > 0 ?
                <>
                  {React.Children.toArray(
                      suggestedCollegeList &&
                      suggestedCollegeList.map((item, index) => {
                        if(suggestedCollegeList?.length == index + 1){
                          return (
                              <div className={"active11"}
                                   key={index}
                                   ref={lastBookElementRef}
                              >
                                <SuggestedCollegeCard
                                    data={{ ...item, collegeImage: TeamLogo }}
                                    handleAddCollege={handleAddToFavorite}
                                    interestLevelList={interesrLevelList}
                                    updateList = {()=>getSearchCollegesData(0, limit)}
                                />
                              </div>
                          )
                        }
                        else{
                          return (
                              <div className={"active12"}
                                   key={index}
                              >
                                <SuggestedCollegeCard
                                    data={{ ...item, collegeImage: TeamLogo }}
                                    handleAddCollege={handleAddToFavorite}
                                    interestLevelList={interesrLevelList}
                                    updateList = {()=>getSearchCollegesData(0, limit)}
                                />
                              </div>
                          )
                        }
                      })
                  )}
                </>
                    :
                    <>
                    <ul>
                      <li className="link-text"
                      style={{
                        fontSize: "14px",
                        lineHeight: "18px",
                        margin: "0px 0px 5px 0px",
                        color: "#007bff",
                        fontWeight: "600",
                        cursor: "pointer"
                      }}
                      >
                        <Link to="/colleges/preferences">Please set your preferences first</Link>
                      </li>
                    </ul>
                    </>
                }
                </div>
                {/* <div className="loadMoreBox">
                  <a href="">Load more feeds</a>
                </div> */}
              </div>
            </div>

            <div className="rightDataBox">
              <RightSideBar />
            </div>
          </div>
        </div>
      </div>
      <GoTopButton  goTop={goTop}/>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    searchCollegeList: state.athlete.dashboard.colleges.searchCollegeList,
    addMyCollege: state.athlete.dashboard.home.addMyCollege,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchCollegeListAPIStart: (data) =>
      dispatch(searchCollegeListAPIStart(data)),
    addMyCollegeStart: (data) => dispatch(addMyCollegeStart(data)),
    collegesListStart: (data) => dispatch(collegesListStart(data)),
    userCollegeCardCountStart: (data) =>
      dispatch(userCollegeCardCountStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedColleges);
