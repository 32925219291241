import React, { useState, useEffect, useRef } from "react";
import LeftSideEditProfile from "./LeftSideEditProfile";
import FormControl from "@mui/material/FormControl";
import { MenuItem, Select } from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { Title } from "./videoPhoto/Component";
import Dialog from "@mui/material/Dialog";
import closeGrayIcon from "./assets/img/close-gray-icon.svg";
import trashCircleIcon from "./assets/img/trash-circle-icon.svg";

import messagePopup from "../../../../../utils/messagePopup";
//Redux Imports
import { allAdditionalSportsListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/allAdditionalSports";
import { allLevelMasterListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/allLevelMaster";
import {connect, useDispatch} from "react-redux";
import {
  addAdditionalSportsQuery,
  getAllAdditionalSportsQuery,
  getAllLevelQuery,
  getAllUserAdditionalSportQuery,
} from "./AdditionalSportsQuery";
import { getUserInfo } from "../../../../../services/authService";
import {
  userAdditionalSportStart,
  userAdditionalSportSave,
} from "../../../../../redux/athlete/dashboard/editUserProfile/userAdditionalSport";
import BackdropLoader from "../../../../common/Loader";
import SubHeader from "./SubHeader";
import ScreenSwitch from "./ScreenSwitch";
import GoTopButton from "./GoTopButton";
import toastr from "toastr";

const SportBox = ({ sport, onDeleteClick, onEditClick }) => {
  const onDeleteHandler = () => {
    onDeleteClick(sport.id);
  };
  const onEditHandler = () => {
    onEditClick(sport);
  };
  return (
    <li>
      <div className="reference-box">
        <div className="title-box">
          <h3>{sport.additionalSport.additionalsportsname}</h3>
          <div className="button-box">
            <button className="trash-button" onClick={onDeleteHandler}>
              <span className="icon-box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.9"
                  height="17.5"
                  viewBox="0 0 15.9 17.5"
                >
                  <g transform="translate(-3.75 -2.25)">
                    <path
                      className="a"
                      d="M4.5,9H18.9"
                      transform="translate(0 -2.8)"
                    ></path>
                    <path
                      className="a"
                      d="M18.7,6.2V17.4A1.6,1.6,0,0,1,17.1,19h-8a1.6,1.6,0,0,1-1.6-1.6V6.2m2.4,0V4.6A1.6,1.6,0,0,1,11.5,3h3.2a1.6,1.6,0,0,1,1.6,1.6V6.2"
                      transform="translate(-1.4)"
                    ></path>
                    <path
                      className="a"
                      d="M15,16.5v4.8"
                      transform="translate(-4.9 -6.3)"
                    ></path>
                    <path
                      className="a"
                      d="M21,16.5v4.8"
                      transform="translate(-7.7 -6.3)"
                    ></path>
                  </g>
                </svg>
              </span>
              Delete
            </button>
            <button className="edit-button" onClick={onEditHandler}>
              <span className="icon-box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.641"
                  height="15.578"
                  viewBox="0 0 15.641 15.578"
                >
                  <path
                    className="a"
                    d="M15.24,2.528,13.187.475a1.515,1.515,0,0,0-2.138.086A1.517,1.517,0,0,0,10.962,2.7l2.053,2.053a1.516,1.516,0,0,0,2.139-.086,1.516,1.516,0,0,0,.085-2.139ZM2.15,9.458l4.106,4.106L12.93,6.892,8.824,2.786,2.15,9.458ZM0,15.651l5.416-1.085L1.085,10.235Z"
                    transform="translate(0 -0.074)"
                  ></path>
                </svg>
              </span>
              Edit
            </button>
          </div>
        </div>
        <ul className="reference-detail-box additional">
          <li>
            <span>Start Year</span> <span>{sport.startyear}</span>
          </li>
          <li>
            <span>End Year</span> <span>{sport.endyear}</span>
          </li>
          <li>
            <span>Level</span> <span>{sport.level.levelname}</span>
          </li>
        </ul>
      </div>
    </li>
  );
};
const AddEditOverlay = ({
  onAddEditClick,
  additionalSportsList,
  levelList,
  allUserAdditionalSportList,
  onSave,
  onEditValue,
  onSportChange,
}) => {
  const setRadioValue = () => {
    if (onEditValue.sportsrecruitable === 1) {
      return "sportsrecruitable";
    } else if (onEditValue.baseballismyfirstsports) {
      return "baseballismyfirstsports";
    } else if (onEditValue.notsureplaysportsincollege) {
      return "notsureplaysportsincollege";
    }
    return "sportsrecruitable";
  };

  const [startYearErrorState, setStartYearErrorState] = React.useState(false);
  const [endYearErrorState, setEndYearErrorState] = React.useState(false);
  const [additionalSportError, setAdditionalSportError] = React.useState(false);
  const [value, setValue] = React.useState(setRadioValue());

  const [startYear, setStartYear] = React.useState(
    onEditValue.startyear ? onEditValue.startyear : ""
  );
  const [endYear, setEndYear] = React.useState(
    onEditValue.endyear ? onEditValue.endyear : ""
  );
  const [additionalSport, setAdditionalSport] = React.useState(
    onEditValue.additionalSport
      ? onEditValue.additionalSport.id
        ? onEditValue.additionalSport.id
        : 0
      : 0
  );
  const [level, setLevel] = React.useState(
    onEditValue.level ? (onEditValue.level.id ? onEditValue.level.id : 0) : 0
  );
  const startYearChangeHandler = (event) => {
    const year = event?.target?.value < 0 ?  Math.abs(event.target.value) : event?.target.value;
    setStartYear(year);
    setStartYearErrorState(false);
  };
  const endYearChangeHandler = (event) => {
    const year = event.target.value < 0 ? Math.abs(event.target.value) : event.target.value;
    setEndYear(year);
    setEndYearErrorState(false);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    onSportChange({
      id: onEditValue.id,
      startyear: startYear,
      endyear: endYear,
      additionalsportsid: additionalSport ? additionalSport : null,
      levelid: level ? level : null,
      sportsrecruitable: value === "sportsrecruitable" ? 1 : 0,
      baseballismyfirstsports: value === "baseballismyfirstsports" ? 1 : 0,
      notsureplaysportsincollege:
        value === "notsureplaysportsincollege" ? 1 : 0,
    });
  }, [startYear, endYear, additionalSport, level, value]);

  const [errorsMesaages, setErrorMessages] = useState("");

  const onSaveClickHandler = () => {
    const additionalSportObject = {
      id: onEditValue.id,
      startyear: startYear,
      endyear: endYear,
      additionalsportsid: additionalSport ? parseInt(additionalSport) : null,
      levelid: level ? parseInt(level) : null,
      sportsrecruitable: value === "sportsrecruitable" ? 1 : 0,
      baseballismyfirstsports: value === "baseballismyfirstsports" ? 1 : 0,
      notsureplaysportsincollege:
        value === "notsureplaysportsincollege" ? 1 : 0,
    };

    if (
      additionalSportObject.startyear === "" &&
      additionalSportObject.additionalsportsid === null
    ) {
      setStartYearErrorState(true);
      setAdditionalSportError(true);
      setErrorMessages("Please Enter the Start Year");
      return;
    }
    if (additionalSportObject.additionalsportsid === null) {
      setAdditionalSportError(true);
      return;
    }
    if (additionalSportObject.startyear === "") {
      setStartYearErrorState(true);
      setErrorMessages("Please Enter the Start Year");
      return;
    }
    if (
      additionalSportObject.startyear !== "" &&
      additionalSportObject.startyear.length !== 4
    ) {
      setStartYearErrorState(true);
      setErrorMessages("Please Enter Correct Year");
      return;
    }
    if (
      (additionalSportObject.endyear !== "" &&
        additionalSportObject.endyear.length !== 4) ||
      (additionalSportObject.endyear !== "" &&
        additionalSportObject.startyear > additionalSportObject.endyear)
    ) {
      setEndYearErrorState(true);
      return;
    }
    if (
      additionalSportObject.id !== 0 ||
      (additionalSportObject.startyear !== "" &&
        additionalSportObject.startyear.length === 4) ||
      (additionalSportObject.endyear !== "" &&
        additionalSportObject.endyear.length === 4) ||
      additionalSportObject.additionalsportsid ||
      additionalSportObject.levelid
    ) {
      onSave(additionalSportObject);
    }
    onAddEditClick();
  };

  const handleChangeAddSport = (e) => {
    setAdditionalSport(e.target.value);
    setAdditionalSportError(false);
  };

  const handleChangeLevel = (e) => {
    setLevel(e.target.value);
  };

  return (
    <div
      className="modal fade common-modal form-modal addEditOverlay show"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <form className="modal-content">
          <div className="modal-header">
            {onEditValue && onEditValue.id ? (
              <h5 className="modal-title">Edit Additional Sports</h5>
            ) : (
              <h5 className="modal-title">Add Additional Sports</h5>
            )}

            <button
              type="button"
              className="close"
              style={{ color: "#000" }}
              onClick={onAddEditClick}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body bottom-border">
            <div className="form-group">
              <label>
                Additional Sport <sup style={{color: "#c0321f"}}>*</sup>
              </label>
              <select
                id="demo-simple-select-outlined"
                value={additionalSport || ""}
                name="additionalSport"
                label="Select"
                onChange={(e) => handleChangeAddSport(e)}
              >
                <option value="">Select</option>
                {additionalSportsList &&
                  additionalSportsList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.value}
                    </option>
                  ))}
              </select>
              {additionalSportError && (
                <div className="error" style={{ textAlign: "left" }}>
                  Please Select Additional Sport
                </div>
              )}
            </div>
            <div className="form-group">
              <label>Level</label>
              <select
                id="demo-simple-select-outlined"
                value={level || ""}
                name="level"
                label="Select"
                onChange={(e) => handleChangeLevel(e)}
              >
                <option value="">Select</option>
                {levelList &&
                  levelList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Start Year <sup style={{color: "#c0321f"}}>*</sup>
                  </label>
                  <input
                    type="number"
                    value={startYear}
                    /* onChange={(e)=>parseFloat(!!e.target.value ? e.target.value : 0 ) <= (parseFloat(new Date().getFullYear())) ?
                        startYearChangeHandler(e) : console.log("invalid year")} */
                    onChange={(e)=> startYearChangeHandler(e)}    
                    min="2010"
                    max="2030"
                    placeholder="2020"
                  />

                  {startYearErrorState && (
                    <div className="error" style={{ textAlign: "left"  }}>{errorsMesaages}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label>End Year</label>
                  <input
                    type="number"
                    value={endYear}
                    /* onChange={(e)=>parseFloat(!!e.target.value ? e.target.value : 0) <=  parseFloat(new Date().getFullYear()) ?
                        endYearChangeHandler(e) : console.log("invalid year")} */
                    onChange={(e)=> endYearChangeHandler(e)}    
                    min="2010"
                    max="2030"
                    placeholder="2020"
                  />
                  {endYearErrorState && (
                    <p className="error" style={{ textAlign: "left"  }}>End year must be greater than or equal to start year</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group sf-radio-global">
              <FormControl component="fieldset" className={"radioGroup"}>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="sportsrecruitable"
                    control={<Radio />}
                    label="I'm recruitable in this sport"
                  />
                  <FormControlLabel
                    value="baseballismyfirstsports"
                    control={<Radio />}
                    label="Baseball is my #1 sport"
                  />
                  <FormControlLabel
                    className="mb-0"
                    value="notsureplaysportsincollege"
                    control={<Radio />}
                    label="I'm not sure I want to play sports in college"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="modal-footer">
            {onEditValue && onEditValue.id ? (
              <button
                type="button"
                className="btn blue"
                onClick={onSaveClickHandler}
              >
                Update
              </button>
            ) : (
              <button
                type="button"
                className="btn blue"
                onClick={onSaveClickHandler}
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const DeleteItem = ({ onDeleteClick, onCancelClick }) => {
  return (
    <div
      className="modal fade common-modal trash-modal show"
      id="ReferenceTrashModal"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" onClick={onCancelClick}>
              <img src={closeGrayIcon} alt="close Gray Icon" />
            </button>
            <div className="icon-box-first">
              <img src={trashCircleIcon} alt="trash Circle Icon" />
            </div>
            <div className="content-box">
              <h4>Are you sure you want to delete this ?</h4>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              onClick={onDeleteClick}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn gray"
              data-dismiss="modal"
              onClick={onCancelClick}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditSection = ({ data, onAddEditClick, onDeleteClick, onEditClick }) => {
  const onDeleteHandler = (id) => {
    onDeleteClick(id);
  };
  const onEditHandler = (updatedAdditionalSport) => {
    onEditClick(updatedAdditionalSport);
  };
  const getUserAdditionalSportList = () => {
    return data.map((additionalSport) => {
      if (additionalSport.id == 0) {
        return;
      }
      return (
        <SportBox
          key={additionalSport.id}
          sport={additionalSport}
          onAddEditClick={onAddEditClick}
          onDeleteClick={onDeleteHandler}
          onEditClick={onEditHandler}
        />
      );
    });
  };

  return (
    <div className="editSection">
      <Title title={"Additional Sports"} />
      <div className="check-list-sec padding-lf-none">
        <div className="event-main-wrapper">
          <div className="event-top-block with-border text-left">
            <div className="short-text w-100">
              <button className="btn green" onClick={onAddEditClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.75"
                  height="14.75"
                  viewBox="0 0 14.75 14.75"
                >
                  <path
                    className="a"
                    d="M18.074,10.646H13.1V5.676a1.23,1.23,0,0,0-2.458,0v4.97H5.676a1.23,1.23,0,0,0,0,2.458h4.97v4.97a1.23,1.23,0,0,0,2.458,0V13.1h4.97a1.23,1.23,0,0,0,0-2.458Z"
                    transform="translate(-4.5 -4.5)"
                  />
                </svg>
                Add Additional Sports
              </button>
            </div>
          </div>
          <div className="reference-data-box">
            <ul className="reference-list">{getUserAdditionalSportList()}</ul>
          </div>
          <div className="common-button-box with-next-prev center">
            <div className="next-prev-button">
              <ScreenSwitch
                prevLink={"/gradesScores"}
                nextLink={"/References"}
                isSaved={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdditionalSports = (props) => {
  const [sideMenuOpen, setSideMenu] = useState(false),
    [showAddEditModal, setShowAddEditModal] = useState(false),
    [showDeleteModal, setShowDeleteModal] = useState(false);
  const [additionalSportId, setAdditionalSportId] = useState(0);
  const fakeInput = useRef(null);
  const [saveButtonHide] = useState(false);
  const [additionalSportEditValues, setAdditionalSportEditValues] = useState({
    id: 0,
    additionalSport: {
      additionalsportsname: "",
      id: 0,
    },
    level: {
      levelname: "",
      id: 0,
    },
  });
  const [additionalSportsList, setAdditionalSportsList] = useState([
    {
      id: 0,
      value: "",
    },
  ]);
  const [allLevelList, setAllLevelList] = useState([
    {
      id: 0,
      value: "",
    },
  ]);
  const [allUserAdditionalSportList, setAllUserAdditionalSportList] = useState([
    {
      id: 0,
      additionalSport: {
        additionalsportsname: "",
        id: 0,
      },
      level: {
        levelname: "",
        id: 0,
      },
    },
  ]);
//for progress bar
  const dispatch = useDispatch()
  useEffect(() => {
    props.allLevelListStart({
      query: getAllLevelQuery,
    });
    props.allAdditionalsportsListStart({
      query: getAllAdditionalSportsQuery,
    });
    props.userAdditionalSportListStart({
      query: getAllUserAdditionalSportQuery,
      variables: {
        obj: {
          userId: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);

  //Effect to refresh after adding or editing
  useEffect(() => {
    if (
      props.saveUseradditionalsports &&
      props.saveUseradditionalsports.data &&
      props.saveUseradditionalsports.data.saveUseradditionalsports &&
      props.saveUseradditionalsports.data.saveUseradditionalsports.active !== 0
    ) {
      let allUserAdditionalSportListT = allUserAdditionalSportList.filter(
        (userAdditionalSport) => {
          if (
            userAdditionalSport.id !==
            props.saveUseradditionalsports.data.saveUseradditionalsports.id
          ) {
            return userAdditionalSport;
          }
        }
      );
      let additionalsportObject = {
        id: props.saveUseradditionalsports.data.saveUseradditionalsports.id,
        active:
          props.saveUseradditionalsports.data.saveUseradditionalsports.active,
        startyear:
          props.saveUseradditionalsports.data.saveUseradditionalsports
            .startyear,
        endyear:
          props.saveUseradditionalsports.data.saveUseradditionalsports.endyear,
        additionalSport: {
          id: props.saveUseradditionalsports.data.saveUseradditionalsports
            .useradditionalsportsadditionalsportsidmaprel.Additionalsports[0]
            ? props.saveUseradditionalsports.data.saveUseradditionalsports
                .useradditionalsportsadditionalsportsidmaprel
                .Additionalsports[0].id
              ? props.saveUseradditionalsports.data.saveUseradditionalsports
                  .useradditionalsportsadditionalsportsidmaprel
                  .Additionalsports[0].id
              : 0
            : 0,
          additionalsportsname: props.saveUseradditionalsports.data
            .saveUseradditionalsports
            .useradditionalsportsadditionalsportsidmaprel.Additionalsports[0]
            ? props.saveUseradditionalsports.data.saveUseradditionalsports
                .useradditionalsportsadditionalsportsidmaprel
                .Additionalsports[0].additionalsportsname
              ? props.saveUseradditionalsports.data.saveUseradditionalsports
                  .useradditionalsportsadditionalsportsidmaprel
                  .Additionalsports[0].additionalsportsname
              : ""
            : "",
        },
        level: {
          id: props.saveUseradditionalsports.data.saveUseradditionalsports
            .useradditionalsportslevelidmaprel.Levelmasters[0]
            ? props.saveUseradditionalsports.data.saveUseradditionalsports
                .useradditionalsportslevelidmaprel.Levelmasters[0].id
              ? props.saveUseradditionalsports.data.saveUseradditionalsports
                  .useradditionalsportslevelidmaprel.Levelmasters[0].id
              : 0
            : 0,
          levelname: props.saveUseradditionalsports.data
            .saveUseradditionalsports.useradditionalsportslevelidmaprel
            .Levelmasters[0]
            ? props.saveUseradditionalsports.data.saveUseradditionalsports
                .useradditionalsportslevelidmaprel.Levelmasters[0].levelname
              ? props.saveUseradditionalsports.data.saveUseradditionalsports
                  .useradditionalsportslevelidmaprel.Levelmasters[0].levelname
              : ""
            : "",
        },
        baseballismyfirstsports:
          props.saveUseradditionalsports.data.saveUseradditionalsports
            .baseballismyfirstsports,
        notsureplaysportsincollege:
          props.saveUseradditionalsports.data.saveUseradditionalsports
            .notsureplaysportsincollege,
        sportsrecruitable:
          props.saveUseradditionalsports.data.saveUseradditionalsports
            .sportsrecruitable,
      };
      allUserAdditionalSportListT.push(additionalsportObject);
      setAllUserAdditionalSportList(allUserAdditionalSportListT);
    }
    if (
      props.saveUseradditionalsports &&
      props.saveUseradditionalsports.data &&
      props.saveUseradditionalsports.data.saveUseradditionalsports &&
      props.saveUseradditionalsports.data.saveUseradditionalsports.active === 0
    ) {
      let allUserAdditionalSportListT = [...allUserAdditionalSportList];
      allUserAdditionalSportListT = allUserAdditionalSportListT.filter(
        (item) => {
          if (
            item.id !==
            props.saveUseradditionalsports.data.saveUseradditionalsports.id
          ) {
            return item;
          }
        }
      );
      setAllUserAdditionalSportList(allUserAdditionalSportListT);
    }
  }, [props.saveUseradditionalsports]);

  //Effect to get usersAdditionalSport
  useEffect(() => {
    if (
      props.allUserAdditionalSportList &&
      props.allUserAdditionalSportList.data &&
      props.allUserAdditionalSportList.data.allUseradditionalsports &&
      props.allUserAdditionalSportList.data.allUseradditionalsports
        .Useradditionalsports
    ) {
      if (
        props.allUserAdditionalSportList.data.allUseradditionalsports
          .Useradditionalsports !== ""
      ) {
        let allUserAdditionalSportListT = [];
        props.allUserAdditionalSportList.data.allUseradditionalsports.Useradditionalsports.map(
          (userAdditionalSport) => {
            allUserAdditionalSportListT = [
              ...allUserAdditionalSportListT,
              {
                id: userAdditionalSport.id,
                active: userAdditionalSport.active,
                startyear: userAdditionalSport.startyear,
                endyear: userAdditionalSport.endyear,
                additionalSport: {
                  id: userAdditionalSport
                    .useradditionalsportsadditionalsportsidmaprel
                    .Additionalsports[0]
                    ? userAdditionalSport
                        .useradditionalsportsadditionalsportsidmaprel
                        .Additionalsports[0].id
                      ? userAdditionalSport
                          .useradditionalsportsadditionalsportsidmaprel
                          .Additionalsports[0].id
                      : 0
                    : 0,
                  additionalsportsname: userAdditionalSport
                    .useradditionalsportsadditionalsportsidmaprel
                    .Additionalsports[0]
                    ? userAdditionalSport
                        .useradditionalsportsadditionalsportsidmaprel
                        .Additionalsports[0].additionalsportsname
                      ? userAdditionalSport
                          .useradditionalsportsadditionalsportsidmaprel
                          .Additionalsports[0].additionalsportsname
                      : ""
                    : "",
                },
                level: {
                  id: userAdditionalSport.useradditionalsportslevelidmaprel
                    .Levelmasters[0]
                    ? userAdditionalSport.useradditionalsportslevelidmaprel
                        .Levelmasters[0].id
                      ? userAdditionalSport.useradditionalsportslevelidmaprel
                          .Levelmasters[0].id
                      : 0
                    : 0,
                  levelname: userAdditionalSport
                    .useradditionalsportslevelidmaprel.Levelmasters[0]
                    ? userAdditionalSport.useradditionalsportslevelidmaprel
                        .Levelmasters[0].levelname
                      ? userAdditionalSport.useradditionalsportslevelidmaprel
                          .Levelmasters[0].levelname
                      : ""
                    : "",
                },
                baseballismyfirstsports:
                  userAdditionalSport.baseballismyfirstsports,
                notsureplaysportsincollege:
                  userAdditionalSport.notsureplaysportsincollege,
                sportsrecruitable: userAdditionalSport.sportsrecruitable,
              },
            ];
            setAllUserAdditionalSportList(allUserAdditionalSportListT);
          }
        );
      }
    }
  }, [props.allUserAdditionalSportList, props.allUserAdditionalSportList.data]);

  //Effect to get all Levels
  useEffect(() => {
    if (
      props.allLevelList &&
      props.allLevelList.data &&
      props.allLevelList.data.allLevelmasters
    ) {
      if (props.allLevelList.data.allLevelmasters !== "") {
        let allLevelListT = [];
        props.allLevelList.data.allLevelmasters.map((level) => {
          allLevelListT = [
            ...allLevelListT,
            {
              id: level.id,
              value: level.levelname,
            },
          ];
          setAllLevelList(allLevelListT);
        });
      }
    }
  }, [props.allLevelList]);

  //Effect to get all additional sports
  useEffect(() => {
    if (
      props.allAdditionalsports &&
      props.allAdditionalsports.data &&
      props.allAdditionalsports.data.allAdditionalsports
    ) {
      if (props.allAdditionalsports.data.allAdditionalsports !== "") {
        let primrarySportId = getUserInfo().athleteSportName;
        let additionalSportsListT = [];
        props.allAdditionalsports.data.allAdditionalsports.map((sport) => {
          if (sport.additionalsportsname?.toLowerCase() !== primrarySportId?.toLowerCase()) {
            additionalSportsListT.push({
              id: sport.id,
              value: sport.additionalsportsname,
            });
          }
        });
        additionalSportsListT.sort((a, b) =>
          a.value > b.value ? 1 : b.value > a.value ? -1 : 0
        );
        setAdditionalSportsList(additionalSportsListT);
      }
    }
  }, [props.allAdditionalsports]);

  const checkSportName = (data) => {
    let flag = true;
    allUserAdditionalSportList &&
      allUserAdditionalSportList.map((item) => {
        if (item.additionalSport?.id === data.additionalsportsid) {
          if (data.id) {
            if (data.id !== item.id) {
              flag = false;
              // messagePopup("", "Additional Sport Already Exist", "error");
              toastr.error("Additional Sport Already Exist", "", {
                progressBar : true,timeOut: 2000,
                closeButton: true, positionClass: "toast-top-full-width",
                showEasing: "swing", fadeIn: 40000,
                preventDuplicates: true,
              })
            }
          } else {
            flag = false;
            // messagePopup("", "Additional Sport Already Exist", "error");
            toastr.error("Additional Sport Already Exist", "", {
              progressBar : true,timeOut: 2000,
              closeButton: true, positionClass: "toast-top-full-width",
              showEasing: "swing", fadeIn: 40000,
              preventDuplicates: true,
            })
          }
        }
      });
      return flag;
  };
  //Additional Sport
  const onSaveHandler = (data) => {
  let flag =  checkSportName(data);
    if (flag) {
    if (
      additionalSportEditValues.startyear &&
      additionalSportEditValues.startyear.length !== 4
    ) {
      return;
    }
    if (
      (additionalSportEditValues.endyear &&
        additionalSportEditValues.endyear.length !== 4) ||
      (additionalSportEditValues.endyear &&
        additionalSportEditValues.startyear > additionalSportEditValues.endyear)
    ) {
      return;
    }
    if (additionalSportEditValues) {
      if (
        !additionalSportEditValues.id !== 0 &&
        !(
          additionalSportEditValues.startyear &&
          additionalSportEditValues.startyear.length === 4
        ) &&
        !(
          additionalSportEditValues.endyear &&
          additionalSportEditValues.endyear.length === 4
        ) &&
        !additionalSportEditValues.additionalSport.id &&
        !additionalSportEditValues.level.id
      ) {
        // messagePopup("", "Additional Sport Added Unsuccessful.", "warning");
        toastr.warning("Additional Sport Added Unsuccessful.", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
        return;
      }

      const additionalSportObject = {
        id: additionalSportEditValues.id,
        startyear: additionalSportEditValues.startyear,
        endyear: additionalSportEditValues.endyear,
        additionalsportsid: additionalSportEditValues.additionalSport.id
          ? parseInt(additionalSportEditValues.additionalSport.id)
          : null,
        levelid: additionalSportEditValues.level.id
          ? parseInt(additionalSportEditValues.level.id)
          : null,
        sportsrecruitable: additionalSportEditValues.sportsrecruitable ? 1 : 0,
        baseballismyfirstsports:
          additionalSportEditValues.baseballismyfirstsports ? 1 : 0,
        notsureplaysportsincollege:
          additionalSportEditValues.notsureplaysportsincollege ? 1 : 0,
      };

      props.addUserAdditionalSportSave({
        query: addAdditionalSportsQuery,
        variables: {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : null,
            active: 1,
            ...additionalSportObject,
          },
        },
        handleAdditionalSportMessage: handleAdditionalSportMessage,
      });
    }
    setAdditionalSportEditValues({
      id: 0,
      additionalSport: {
        additionalsportsname: "",
        id: 0,
      },
      level: {
        levelname: "",
        id: 0,
      },
    });
  }
    dispatch({type:"RENDER_COUNT_START", payload: 1})
  };

  const getAllAdditionalSportList = () => {
    props.userAdditionalSportListStart({
      query: getAllUserAdditionalSportQuery,
      variables: {
        obj: {
          userId: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };

  const handleAdditionalSportMessage = () => {
    if (additionalSportEditValues.id) {
      // messagePopup("", "Additional Sport Updated Successfully.", "success");
      toastr.success("Additional Sport Updated Successfully.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    } else {
      // messagePopup("", "Additional Sport Added Successfully.", "success");
      toastr.success("Additional Sport Added Successfully.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }
    getAllAdditionalSportList();
  };
  const onDeleteHandler = () => {
    props.addUserAdditionalSportSave({
      query: addAdditionalSportsQuery,
      variables: {
        obj: {
          id: additionalSportId,
          active: 0,
        },
      },
    });
    setShowDeleteModal(!showDeleteModal);
  };

  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };

  const onSportChange = (newAdditionalSport) => {
    setAdditionalSportEditValues({
      ...newAdditionalSport,
      additionalSport: { id: newAdditionalSport.additionalsportsid },
      level: { id: newAdditionalSport.levelid },
    });
  };
  const onAddEditClick = () => {
    setShowAddEditModal(!showAddEditModal);
    setAdditionalSportEditValues("");
  };
  const onDeleteClick = (additionalSportId) => {
    setShowDeleteModal(!showDeleteModal);
    setAdditionalSportId(additionalSportId);
  };

  const onEditHandler = (updatedAdditionalSport) => {
    setAdditionalSportEditValues(updatedAdditionalSport);
    setShowAddEditModal(!showAddEditModal);
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };

  return (
    <div className="pageContent">
      <BackdropLoader
        open={
          props.allUserAdditionalSportList.loading ||
          props.saveUseradditionalsports.loading
        }
      />
      <div className="editProfile">
        <input className="fakeInput" type="text" ref={fakeInput} />

        <div className="container">
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
          >
            <SubHeader
              hamburger={onSideMenuClick}
              buttonHide={saveButtonHide}
            />
            <div className="sidebar">
              <LeftSideEditProfile isSaved={true} />
            </div>
            <EditSection
              data={allUserAdditionalSportList}
              onAddEditClick={onAddEditClick}
              onDeleteClick={onDeleteClick}
              onEditClick={onEditHandler}
            />
          </div>
        </div>
        <GoTopButton goTop={goTop} />
      </div>
      <Dialog open={showAddEditModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onAddEditClick()
                }
              }}
              className="matDialogCustom"
      >
        <AddEditOverlay
          onAddEditClick={onAddEditClick}
          additionalSportsList={additionalSportsList}
          levelList={allLevelList}
          onSave={onSaveHandler}
          allUserAdditionalSportList={props.allUserAdditionalSportList}
          onEditValue={additionalSportEditValues}
          onSportChange={onSportChange}
        />
      </Dialog>
      <Dialog open={showDeleteModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onDeleteClick()
                }
              }}
              className="matDialogCustom"
      >
        <DeleteItem
          onDeleteClick={onDeleteHandler}
          onCancelClick={onDeleteClick}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allAdditionalsports: state.athlete.editUserProfileData.additionalSportsList,
    allLevelList: state.athlete.editUserProfileData.levelMasterList,
    allUserAdditionalSportList:
      state.athlete.editUserProfileData.userAdditionalSport,
    saveUseradditionalsports:
      state.athlete.editUserProfileData.userAdditionalSport,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    allAdditionalsportsListStart: (data) =>
      dispatch(allAdditionalSportsListStart(data)),
    allLevelListStart: (data) => dispatch(allLevelMasterListStart(data)),
    addUserAdditionalSportSave: (data) =>
      dispatch(userAdditionalSportSave(data)),
    userAdditionalSportListStart: (data) =>
      dispatch(userAdditionalSportStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalSports);
