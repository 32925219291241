export const getAllNotificationSettingCoachlabels = `
query allNotificationsettingcoachlabels{
    allNotificationsettingcoachlabels{
      Notificationsettingcoachlabels{
        id
        labelname
        coachnotificationsettingmasternotificationlabelidMap{
          Coachnotificationsettingmasters{
            id
            fieldname
            asithappens
            never
            weekly
            notificationstatus
            notificationlabelid
          }
        }
      }
    }
  }
`

export const saveCoachNotificationSettingQuery = `
mutation saveCoachnotificationsetting($obj :CoachnotificationsettingInput! ){
    saveCoachnotificationsetting(obj :$obj){
      id
    }
  }
`


