import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  COACH_SAT_VALUE_LIST_START: "COACH_SAT_VALUE_LIST_START",
  COACH_SAT_VALUE_SUCCESS: "COACH_SAT_VALUE_SUCCESS",
  COACH_SAT_VALUE_FAILED: "COACH_SAT_VALUE_FAILED",
};

// ============================================ Actions ========================================================
export const coachSatValueListStart = (data) => ({
  type: actionType.COACH_SAT_VALUE_LIST_START,
  payload: data,
});

export const coachSatValueSuccess = (data) => ({
  type: actionType.COACH_SAT_VALUE_SUCCESS,
  payload: data,
});

export const coachSatValueFailed = (data) => ({
  type: actionType.COACH_SAT_VALUE_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function coachSatValueReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.COACH_SAT_VALUE_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.COACH_SAT_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.COACH_SAT_VALUE_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* coachSatValueListSaga(action) {
  try {
    const response = yield fetchGraphMethod(action.payload.query, null, true);
    if (response && response.status === 200) {
      const allCoachSatValue = response.data.data.allCoachsatranges.Coachsatranges
        ? response.data.data.allCoachsatranges.Coachsatranges
        : null;
      yield put(coachSatValueSuccess(allCoachSatValue));
    } else {
      messagePopup("", "Coach Sat Value List failed", "error");
      yield put(coachSatValueFailed("Coach Sat Value List failed"));
    }
  } catch (error) {
    messagePopup("", "Coach Sat Value List failed", "error");
    yield put(coachSatValueFailed("Coach Sat Value List failed"));
  }
}

export function* watchCoachSatValueListSaga() {
  yield takeEvery(actionType.COACH_SAT_VALUE_LIST_START, coachSatValueListSaga);
}
