import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
    LAST_CONTACT_LIST_START: "LAST_CONTACT_LIST_START",
    LAST_CONTACT_LIST_SUCCESS: "LAST_CONTACT_LIST_SUCCESS",
    LAST_CONTACT_LIST_FAILURE: "LAST_CONTACT_LIST_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const lastContactListStart = (data) => ({
    type: actionType.LAST_CONTACT_LIST_START,
    payload: data,
  });

export const lastContactListSuccess = (data) => ({  
    type: actionType.LAST_CONTACT_LIST_SUCCESS,
    payload: data,
});
  
export const lastContactListFailure = (data) => ({
    type: actionType.LAST_CONTACT_LIST_FAILURE,
    payload: data
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function lastContactReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.LAST_CONTACT_LIST_START:
      return { ...state, loading: true };
    
    case actionType.LAST_CONTACT_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.LAST_CONTACT_LIST_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/


export function* lastContactListSaga(action) {
    try {
      const response = yield fetchGraphMethod(
        action.payload.query,
        action.payload.variables,
        true
      );
      if (response && response.status === 200) {
          let lastContactData = response.data.data.allMessagereplies.Messagereplies;
        yield put(
            lastContactListSuccess(lastContactData)
        );
      } else {
        messagePopup("", "Last Contact Api failed", "error");
        yield put(lastContactListFailure("Last Contact Api failed"));
      }
    } catch (error) {
      messagePopup("", "Last Contact Api failed", "error");
      yield put(lastContactListFailure("Last Contact Api failed"));
    }
  }

export function* watchLastContactListSaga() {
    yield takeEvery(actionType.LAST_CONTACT_LIST_START, lastContactListSaga);
}

