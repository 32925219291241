import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../utils/messagePopup";
import history from "../../../services/history";
import { fetchGraphMethod } from "../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  SAVE_COACH_DETAILS_START: "SAVE_COACH_DETAILS_START",
  GET_COACH_DETAILS_START: "GET_COACH_DETAILS_START",
  COACH_DETAILS_SUCCESS: "COACH_DETAILS_SUCCESS",
  COACH_DETAILS_FAILURE: "COACH_DETAILS_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const saveCoachDetailsStart = (data) => ({
  type: actionType.SAVE_COACH_DETAILS_START,
  payload: data,
});

export const getCoachDetailsStart = (data) => ({
  type: actionType.GET_COACH_DETAILS_START,
  payload: data,
});

export const coachDetailsSuccess = (data) => ({
  type: actionType.COACH_DETAILS_SUCCESS,
  payload: data,
});

export const coachDetailsFailure = (data) => ({
  type: actionType.COACH_DETAILS_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function coachDetailsReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_COACH_DETAILS_START:
      return { ...state, loading: true };

    case actionType.GET_COACH_DETAILS_START:
      return { ...state, loading: true };

    case actionType.COACH_DETAILS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.COACH_DETAILS_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* saveCoachDetailsSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const saveCoachandteamdetails = response.data.data
        ? response.data.data.saveCoachandteamdetails
          ? response.data.data.saveCoachandteamdetails
          : null
        : null;
      if (action.payload.nextScreen)
        action.payload.nextScreen(action.payload.variables.obj);
      yield put(coachDetailsSuccess(saveCoachandteamdetails));
    } else {
      messagePopup("", "Save Coach Details Failed", "error");
      yield put(coachDetailsFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Save Coach Details Failed", "error");
    yield put(coachDetailsFailure("Failed, Error occured"));
  }
}

function* getCoachDetailsSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200) {
      const allCoachandteamdetails = response.data.data.allCoachandteamdetails
        .Coachandteamdetails
        ? response.data.data.allCoachandteamdetails.Coachandteamdetails[0]
        : null;
      yield put(coachDetailsSuccess(allCoachandteamdetails));
    } else {
      messagePopup("", "Fetch Coach Details Data Failed", "error");
      yield put(coachDetailsFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch Coach Details Data Failed", "error");
    yield put(coachDetailsFailure("Failed, Error occured"));
  }
}

export function* watchCoachDetails() {
  yield takeEvery(actionType.SAVE_COACH_DETAILS_START, saveCoachDetailsSaga);
  yield takeEvery(actionType.GET_COACH_DETAILS_START, getCoachDetailsSaga);
}
