import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getCommitmentStart } from "../../../../../../redux/athlete/profile/commitment";
import { getUserInfo } from "../../../../../../services/authService";
import { getMyCommitmentForDashboardQuery } from "./query";
import Mcc from "../assets/img/mcc.png";
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";
import { viewCollegeSaveStart } from "../../../../../../redux/athlete/dashboard/colleges/viewCollege";
import { saveViewCollegeQuery } from "../../colleges/searchColleges/SearchCollegesQuery";
import {imageDownloadUrl} from '../../../../../../config'

const MyCommitments = (props) => {
  const [commitmentList, setCommitmentList] = useState(null);
  const [actualCommitmentList, setActualCommitmentList] = useState(null);
  useEffect(() => {
    if (props.myCommitment.data) {
      const tepmCommitmentData = props.myCommitment.data;
      const topTwoCommitment = tepmCommitmentData.slice(0, 2);
      setCommitmentList(topTwoCommitment);
      setActualCommitmentList(tepmCommitmentData);
    }
  }, [props.myCommitment.data]);
  useEffect(() => {
    getAllCommitment();
  }, []);
  const getAllCommitment = () => {
    props.getCommitmentStart({
      query: getMyCommitmentForDashboardQuery(
        getUserInfo() ? getUserInfo().id : 0
      ),
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
          order: "id DESC",
        },
      },
    });
  };

  const handleCollegeClick = (collgId) => {
    props.viewCollegeSaveStart({
      query : saveViewCollegeQuery,
      variables : {
        obj : {
          athleteid : getUserInfo() ? getUserInfo().id : null,
          collegeid : collgId,
          collegeviewstatus : 1,
        }
      }
    })
  }

  return (
    <div>
      <div className="commitment">
        <div className="head">
          <h5 className="title">Commitment</h5>
          <Link className="editLink" to="/viewCommitment">
            {commitmentList && commitmentList.length ? "Edit" : "Add"}
          </Link>
        </div>
        <ul>
          {commitmentList && commitmentList.length ? (
            commitmentList.map((item, index) => (
              <li key={index}>
                <div className="imgBox">
                  <img src={
                    !!item?.collegecommitmentcollegeidmaprel?.Colleges?.[0]?.collegelogo ?
                        `${imageDownloadUrl}/${item?.collegecommitmentcollegeidmaprel?.Colleges?.[0]?.collegelogo}`
                        : TeamLogo} />
                </div>
                <div className="info">
                  <Link
                    to={`/colleges/details/${item?.collegeid}/${
                      item?.collegecommitmentcollegeidmaprel &&
                      item?.collegecommitmentcollegeidmaprel?.Colleges != "" &&
                      item?.collegecommitmentcollegeidmaprel?.Colleges?.[0]
                        ?.userCollegeCollegeIdMap?.Usercolleges != ""
                        ? item?.collegecommitmentcollegeidmaprel?.Colleges?.[0]
                            ?.userCollegeCollegeIdMap?.Usercolleges?.[0]?.isfav
                        : 0
                    }`}
                    onClick={() => handleCollegeClick(item?.collegeid)}
                  >
                    {!!item?.collegename &&
                    <h5 className="title">
                      {item?.collegename ? item.collegename : ""}
                    </h5>
                    }
                  </Link>
                  <span>
                    {item.collegecommitmentcommitmentmaprel &&
                    item.collegecommitmentcommitmentmaprel.Commitmentstatuses &&
                    item.collegecommitmentcommitmentmaprel.Commitmentstatuses[0]
                      ? item.collegecommitmentcommitmentmaprel
                          .Commitmentstatuses[0].commitmentstatusvalue
                      : null}
                  </span>
                </div>
              </li>
            ))
          ) : (
            <div
              style={{
                textAlign: "left",
                fontSize: "14px",
                padding: "0px 6px",
              }}
            >
              No commitment is made yet
            </div>
          )}
        </ul>
        {actualCommitmentList && actualCommitmentList.length > 2 ? (
          <p className="viewMore">
            <Link to="/viewCommitment">View More</Link>
          </p>
        ) : null}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    myCommitment: state.athlete.profile.commitments,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCommitmentStart: (data) => dispatch(getCommitmentStart(data)),
    viewCollegeSaveStart: (data) => dispatch(viewCollegeSaveStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyCommitments);
