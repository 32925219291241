import React from "react";

export default function SavedFilterModal(props) {
  return (
    <div
      className={`modal savedFilersModal show fade`}
      id="savedFilersModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Saved Filters
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose}
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.804"
                  height="15.803"
                  viewBox="0 0 15.804 15.803"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <g className="a" transform="translate(-6.776 -6.776)">
                    <path
                      d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                      transform="translate(0 0)"
                    ></path>
                  </g>
                </svg>
              </span>
            </button>
          </div>
          <div className="modal-body">
            <h5 className="head">Saved Filters name</h5>
            <div className="savedFilterData">
              {props.filterList && props.filterList.length ? (
                props.filterList.map((val, index) => (
                  <FilterList
                    onDelete={props.handleDeleteFilter}
                    handleFilterClick={props.handleFilterClick}
                    onClose={props.onClose}
                    key={index}
                    filter={val}
                  />
                ))
              ) : (
                <div style={{ textAlign: "center" }}>
                  <span>No filter saved</span>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary cancelBtn "
              data-dismiss="modal"
              onClick={props.onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const FilterList = (props) => {
  const [expand, setExpand] = React.useState(false);
  const handleExpand = () => {
    setExpand(!expand);
  };

  const getObject = (data) => {
    const finalvalue = {};
    if (data !== undefined) {
      for (const iterator of data) {
        finalvalue[iterator.appliedfiltername] = iterator.appliedfiltervalue;
      }
    }
    return finalvalue;
  };
  const getSavedFilter = () => {
    let finalFilter = [];
    const objectData = getObject(props.filter.filterData);
    if (objectData.favorite) {
      finalFilter = [
        ...finalFilter,
        `Favorite `
      ];
    }
    if (objectData.promotions) {
      finalFilter = [
        ...finalFilter,
        `Promotions `
      ];
    }
    if (objectData.stateregion) {
      finalFilter = [
        ...finalFilter,
        `Location(State/Region) `
      ];
    }
    if (objectData.level) {
      finalFilter = [...finalFilter, `Level `];
    }
    if (objectData.academicselectivity) {
      finalFilter = [
        ...finalFilter,
        `Academic Selectivity `
      ];
    }
    if (objectData.collegesize) {
      finalFilter = [
        ...finalFilter,
        `College Size `
      ];
    }
    if (objectData.tuitioncost) {
      finalFilter = [
        ...finalFilter,
        `Tuition Cost `
      ];
    }
    if (objectData.settting) {
      finalFilter = [...finalFilter, `Settting `];
    }
    if (objectData.type) {
      finalFilter = [...finalFilter, `Type `];
    }
    if (objectData.areastudy) {
      finalFilter = [...finalFilter, `Area of study `];
    }
    if (objectData.athleticselectivityid || objectData.leveliddata) {
      finalFilter = [...finalFilter, `Athletic Selectivity `];
    }
    if (objectData.conference) {
      finalFilter = [
        ...finalFilter,
        `Conference `
      ];
    }
    return finalFilter;
  };
  const handleApplyFilter = (e) => {
    props.handleFilterClick(e, props.filter);
    props.onClose();
  }
  return (
    <div className="item">
      <h5 className="heading">
        <span>{props.filter.filtername}</span>
        <button onClick={() => props.onDelete(props.filter)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
          >
            <path
              className="a"
              d="M12.218,2.438H.843A.791.791,0,0,1,.272,2.2a.768.768,0,0,1-.241-.571.8.8,0,0,1,.235-.578A.773.773,0,0,1,.844.813H4.906A.791.791,0,0,1,5.141.242.775.775,0,0,1,5.719,0H7.344a.775.775,0,0,1,.578.241.791.791,0,0,1,.235.571h4.062a.781.781,0,0,1,.571.241.788.788,0,0,1,.241.578.767.767,0,0,1-.241.571.793.793,0,0,1-.571.235Zm0,8.938A1.625,1.625,0,0,1,10.593,13H2.468a1.567,1.567,0,0,1-1.149-.476,1.564,1.564,0,0,1-.476-1.149V3.251H12.218v8.125ZM4.093,5.688a.823.823,0,0,0-.819-.812.767.767,0,0,0-.571.241.793.793,0,0,0-.235.571v4.875a.8.8,0,0,0,.806.813.8.8,0,0,0,.578-.235.773.773,0,0,0,.241-.578V5.688Zm3.25,0a.823.823,0,0,0-.819-.812.767.767,0,0,0-.571.241.793.793,0,0,0-.235.571v4.875a.8.8,0,0,0,.806.813.8.8,0,0,0,.578-.235.773.773,0,0,0,.241-.578V5.688Zm3.25,0a.823.823,0,0,0-.819-.812.767.767,0,0,0-.571.241.793.793,0,0,0-.235.571v4.875a.8.8,0,0,0,.806.813.8.8,0,0,0,.578-.235.773.773,0,0,0,.241-.578V5.688Z"
              transform="translate(-0.031 -0.001)"
            />
          </svg>
        </button>
      </h5>
      <button
        className={`btn btn-primary ${expand ? "" : "collapsed"}`}
        type="button"
        onClick={() => handleExpand()}
      >
        <h5>Search Conditions Saved</h5>
        <span className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4.568"
            height="7.99"
            viewBox="0 0 4.568 7.99"
          >
            <defs>
              <style></style>
            </defs>
            <path
              className="a"
              d="M1.377,3.993,4.4.972a.569.569,0,0,0,0-.806.576.576,0,0,0-.809,0L.166,3.589a.57.57,0,0,0-.017.787l3.44,3.447A.571.571,0,0,0,4.4,7.017Z"
              transform="translate(4.568 7.99) rotate(180)"
            ></path>
          </svg>
        </span>
      </button>
      <div className="btn-wrap">
        <button className="btn green" onClick={(e) => handleApplyFilter(e)}>Apply </button>
      </div>
      <div className={`collapse ${expand ? "show" : ""}`} id="condtion1">
        <div className="card card-body">
          <p>
            {" "}
            {getSavedFilter() ? getSavedFilter().join(", ") : ""}
          </p>
        </div>
      </div>
    </div>
  );
};
