import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  GET_COMPOSE_BY_ID_START: "GET_COMPOSE_BY_ID_START",
  GET_COMPOSE_BY_ID_SUCCESS: " GET_COMPOSE_BY_ID_SUCCESS",
  GET_COMPOSE_BY_ID_FAILED: " GET_COMPOSE_BY_ID_FAILED"
};

// ============================================ Actions ========================================================
export const getComposeMessageByIdStart = data => ({
  type: actionType.GET_COMPOSE_BY_ID_START,
  payload: data
});

export const getComposeMessageByIdSuccess = data => ({
  type: actionType.GET_COMPOSE_BY_ID_SUCCESS,
  payload: data
});

export const getComposeMessageByIdFailed = data => ({
  type: actionType.GET_COMPOSE_BY_ID_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function getComposeMessageByIdReducer(state = INIT_STATE, action) {
  switch (action.type) {
      case actionType.GET_COMPOSE_BY_ID_START:
      return {
        ...state,
        loading: true
      };

    case actionType.GET_COMPOSE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.GET_COMPOSE_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* getComposeMessageByIdSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      action.payload.nextScreen();
      yield put(
        getComposeMessageByIdSuccess({data: response.data.data})
      );
    } else {
      messagePopup("", "University Api failed", "error");
      yield put(getComposeMessageByIdFailed("University Api failed"));
    }
  } catch (error) {
    messagePopup("", "University Api failed", "error");
    yield put(getComposeMessageByIdFailed("University Api failed"));
  }
}

export function* watchGetComposeByIdListSaga() {
  yield takeEvery(actionType.GET_COMPOSE_BY_ID_START, getComposeMessageByIdSaga);
}