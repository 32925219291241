import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    SAT_READING_AND_WRITING_LIST_START: "SAT_READING_AND_WRITING_LIST_START",
    SAT_READING_AND_WRITING_LIST_SUCCESS: "SAT_READING_AND_WRITING_LIST_SUCCESS",
    SAT_READING_AND_WRITING_LIST_FAILED: "SAT_READING_AND_WRITING_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const satReadingAndWritingListStart = (data) => ({
    type: actionType.SAT_READING_AND_WRITING_LIST_START,
    payload: data,
});

export const satReadingAndWritingListSuccess = (data) => ({
    type: actionType.SAT_READING_AND_WRITING_LIST_SUCCESS,
    payload: data,
});

export const satReadingAndWritingListFailed = (data) => ({
    type: actionType.SAT_READING_AND_WRITING_LIST_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function satReadingAndWritingListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.SAT_READING_AND_WRITING_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.SAT_READING_AND_WRITING_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.SAT_READING_AND_WRITING_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* satReadingAndWritingListSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            const allsatReadingAndWriting =  response.data.data.allSatreadingandwritings.Satreadingandwritings
            yield put(satReadingAndWritingListSuccess(allsatReadingAndWriting));
        } else {
            messagePopup("", "Sat Reading and Writing List failed", "error");
            yield put(satReadingAndWritingListFailed("Sat Reading and Writing List failed"));
        }
    } catch (error) {
        messagePopup("", "Sat Reading and Writing failed", "error");
        yield put(satReadingAndWritingListFailed("Sat Reading and Writing List failed"));
    }
}

export function* watchSatReadingAndWritingListSaga() {
    yield takeEvery(actionType.SAT_READING_AND_WRITING_LIST_START, satReadingAndWritingListSaga);
}
