import {fetchMethod} from "../../services/httpService";

export const lpTypeCall = async (type) =>{
    const _res = await fetchMethod('/api/Landingpages/getlandingpagesVideos',
        { pagename: !!type ? type : null },
        "POST",false)
    if(_res?.status == 200){
        return _res?.data?.response?.data
    }else{
        return []
    }
}

export const lpSectionCall = async (section) =>{
    const _res = await fetchMethod('/api/landingpages/getlandingpagescontent',
        { titleName : !!section ? section : null },
        "POST",false)
    if(_res?.status == 200 && _res?.data?.response?.status == 200){
        if (section == "ourmission"){
            return _res?.data?.response?.data?.content?.[0] ?? "Error"
        }
        else if (section == "coaching"){
            return _res?.data?.response?.data
        }
        else if (section == "management"){
            return _res?.data?.response?.data ?? "Error"
        }
        else if(section == "nationwideTeam"){
            return _res?.data?.response?.data
        }
        else{
        return _res?.data?.response?.data
        }
    }else{
        return []
    }
}


export const lpSuccessCall = async () =>{
    const _res = await fetchMethod('/api/Successstories/getsuccessStory',
        null,
        "GET",false)
    if(_res?.status == 200){
        return _res?.data?.response?.data
    }else{
        return []
    }
}