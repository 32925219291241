import React from "react";
import Rectangle132 from "../assets/img/Rectangle132.png";
import DummyAdvisor from '../assets/img/Screen Shot 2022-11-15 at 9.59.55 PM.png'
import {Link} from "react-router-dom";

function RecruitAdvisor() {
  return (
    <div className="recruitAdvisor sticky-box">
      <div className="headFlex">
        <div className="imgBox">
          <img
              src={DummyAdvisor} alt="" />
        </div>
        <div  className="recruitAdvisor-detail">
          <h5>Ask an Expert College Advisor</h5>
        </div>
      </div>
      <p className="desc">
      Get honest answers to your college related questions.
      </p>
      <Link to="/askaquestion">
        <button className="questionBtn">Ask a Question</button>
      </Link>
    </div>
  );
}

export default RecruitAdvisor;
