import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

export default function CountCard(props) {
  const { setCountCardFilters } = props;

  const [activeCard, setActiveCard] = useState("");

  useEffect(() => {
    setActiveCard(props?.activeTab ? props.activeTab : "target");
  }, [props.cardCount]);

  const handleOnCardClick = (e, card) => {
    if (props.cardCount.total && props.cardCount.total !== 0) {
      setActiveCard(card);
      setCountCardFilters((prevState) => {
        // Create a new object with the same keys as prevState, but all values set to false
        const newFilters = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});

        // Set the active card filter to true
        newFilters[card] = true;

        return newFilters;
      });
    }
  };

  return (
    <>
      <ReactTooltip />
      <div
        className={`item ${activeCard === "target" ? "activeCard" : ""}`}
        onClick={(e) => handleOnCardClick(e, "target")}
      >
        <Link to="#" data-tip="">
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <path
                className="a"
                d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                transform="translate(-3 -3)"
              ></path>
            </svg>
          </div>
          <span className="count" style={{ cursor: "pointer" }}>
            {props.cardCount
              ? props.cardCount.total
                ? props.cardCount.total
                : 0
              : 0}
          </span>
          <span>Total</span>
        </Link>
      </div>
      <div
        className={`item ${activeCard === "contact" ? "activeCard" : ""}`}
        onClick={(e) => handleOnCardClick(e, "contact")}
        data-tip=""
      >
        <Link to="#">
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <path
                className="a"
                d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                transform="translate(-3 -3)"
              ></path>
            </svg>
          </div>
          <span className="count" style={{ cursor: "pointer" }}>
            {props.cardCount
              ? props.cardCount.contact
                ? props.cardCount.contact
                : 0
              : 0}
          </span>
          <span>Contact</span>
        </Link>
      </div>
      <div
        className={`item ${activeCard === "interest" ? "activeCard" : ""}`}
        onClick={(e) => handleOnCardClick(e, "interest")}
        data-tip=""
      >
        <Link to="#">
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <path
                className="a"
                d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                transform="translate(-3 -3)"
              ></path>
            </svg>
          </div>
          <span className="count" style={{ cursor: "pointer" }}>
            {props.cardCount
              ? props.cardCount.interest
                ? props.cardCount.interest
                : 0
              : 0}
          </span>
          <span>Interest</span>
        </Link>
      </div>
      <div
        className={`item ${activeCard === "active" ? "activeCard" : ""}`}
        onClick={(e) => handleOnCardClick(e, "active")}
      >
        <Link to="#" data-tip="">
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <path
                className="a"
                d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                transform="translate(-3 -3)"
              ></path>
            </svg>
          </div>
          <span className="count" style={{ cursor: "pointer" }}>
            {props.cardCount
              ? props.cardCount.active
                ? props.cardCount.active
                : 0
              : 0}
          </span>
          <span>Active</span>
        </Link>
      </div>
      <div
        className={`item ${activeCard === "offer" ? "activeCard" : ""}`}
        onClick={(e) => handleOnCardClick(e, "offer")}
      >
        <Link to="#" data-tip="">
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <path
                className="a"
                d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                transform="translate(-3 -3)"
              ></path>
            </svg>
          </div>
          <span className="count" style={{ cursor: "pointer" }}>
            {props.cardCount
              ? props.cardCount.offer
                ? props.cardCount.offer
                : 0
              : 0}
          </span>
          <span>Offers</span>
        </Link>
      </div>
      <div
        className={`item ${activeCard === "commit" ? "activeCard" : ""}`}
        onClick={(e) => handleOnCardClick(e, "commit")}
      >
        <Link to="#" data-tip="">
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <path
                className="a"
                d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                transform="translate(-3 -3)"
              ></path>
            </svg>
          </div>
          <span className="count" style={{ cursor: "pointer" }}>
            {props.cardCount
              ? props.cardCount.commit
                ? props.cardCount.commit
                : 0
              : 0}
          </span>
          <span>Commit</span>
        </Link>
      </div>
    </>
  );
}
