import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
    SAVE_PARENT_NOTIFICATIONS_START: "SAVE_PARENT_NOTIFICATIONS_START",
    GET_PARENT_NOTIFICATION_START: "GET_PARENT_NOTIFICATION_START",
    PARENT_NOTIFICATION_LIST_SUCCESS: "PARENT_NOTIFICATION_LIST_SUCCESS",
    PARENT_NOTIFICATION_LIST_FAILURE: "PARENT_NOTIFICATION_LIST_FAILURE",
    PARENT_NOTIFICATION_SAVE_SUCCESS: "PARENT_NOTIFICATION_SAVE_SUCCESS",
    PARENT_NOTIFICATION_SAVE_FAILURE: "PARENT_NOTIFICATION_SAVE_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const saveParentNotificationStart = (data) => ({
    type: actionType.SAVE_PARENT_NOTIFICATIONS_START,
    payload: data,
});

export const getParentNotificationStart = (data) => ({
    type: actionType.GET_PARENT_NOTIFICATION_START,
    payload: data,
});

export const parentNotificationListSuccess = (data) => ({
    type: actionType.PARENT_NOTIFICATION_LIST_SUCCESS,
    payload: data,
});

export const parentNotificationListFailure = (data) => ({
    type: actionType.PARENT_NOTIFICATION_LIST_FAILURE,
    payload: data,
});
export const parentNotificationSaveSuccess = (data) => ({
    type: actionType.PARENT_NOTIFICATION_SAVE_SUCCESS,
    payload: data,
});

export const parentNotificationSaveFailure = (data) => ({
    type: actionType.PARENT_NOTIFICATION_SAVE_FAILURE,
    payload: data,
});
/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};
export default function parentNotificationReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.SAVE_PARENT_NOTIFICATIONS_START:
            return { ...state, loading: true };

        case actionType.GET_PARENT_NOTIFICATION_START:
            return { ...state, loading: true };

        case actionType.PARENT_NOTIFICATION_LIST_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: null };

        case actionType.PARENT_NOTIFICATION_LIST_FAILURE:
            return { ...state, loading: false, data: null, error: action.payload };

        case actionType.PARENT_NOTIFICATION_SAVE_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: null };

        case actionType.PARENT_NOTIFICATION_LIST_FAILURE:
            return { ...state, loading: false, data: null, error: action.payload };

        default:
            return { ...state };
    }
}

/* ======================================= Side Effects ==========================================*/

export function* parentNotificationsaveSaga(action) {
    try {
        const response = yield fetchGraphMethod(
            action.payload.query,
            action.payload.variables,
            true
        );
        if (response && response.status === 200) {
            yield put(parentNotificationSaveSuccess(""));
            // if (action.payload.getLatestList) {
            //   action.payload.getLatestList();
            // }
        } else {
            messagePopup("", "Notification List failed", "error");
            yield put(parentNotificationSaveFailure("Notification List failed"));
        }
    } catch (error) {
        messagePopup("", "Notification List failed", "error");
        yield put(parentNotificationSaveFailure("Notification List failed"));
    }
}

function* getParentNotificationSaga(action) {
    try {
        const response = yield fetchGraphMethod(
            action.payload.query,
            action.payload.variables,
            true
        );
        if (response.status === 200) {
            const allParentNotifications =
                response &&
                    response.data &&
                    response.data.data &&
                    response.data.data.allParentnotificationmasters
                    ? response.data.data.allParentnotificationmasters.Parentnotificationmasters
                    : null;
                  
            yield put(parentNotificationListSuccess(allParentNotifications));
        } else {
            messagePopup("", "Notification List Failed", "error");
            yield put(parentNotificationListFailure("Failed, Error occured"));
        }
    } catch (error) {
        messagePopup("", "Notification List Failed", "error");
        yield put(parentNotificationListFailure("Failed, Error occured"));
    }
}

export function* watchParentNotification() {
    yield takeEvery(actionType.SAVE_PARENT_NOTIFICATIONS_START, parentNotificationsaveSaga);
    yield takeEvery(actionType.GET_PARENT_NOTIFICATION_START, getParentNotificationSaga);
}