export const getvitalDataQuery = `
query allOnboardings($obj: JSON){
    allOnboardings(where: $obj){
        Onboardings{
        id
        heightinfeet
        heightininches
        battingprefencevalue
        throwingPrefenceValue
        primaryposition
        secondaryposition
        otherposition
        weight
      }
    }
  } 
  `;

export const saveOnboardingVitalsDataQuery = `
  mutation saveOnboarding($obj: OnboardingInput!){
    saveOnboarding(obj: $obj){
       id
    }
  } 
  `;

  export const saveOnboardingHeightWeightDataQuery = `
  mutation saveAthleteheightandweight($obj: AthleteheightandweightInput!){
    saveAthleteheightandweight(obj: $obj){
       id
    }
  } 
  `;
