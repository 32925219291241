import React, { useState, useEffect, useRef } from "react";
import closeGrayIcon from "./assets/img/close-gray-icon.svg";
import trashCircleIcon from "./assets/img/trash-circle-icon.svg";
import LeftSideEditProfile from "./LeftSideEditProfile";
import FormControl from "@mui/material/FormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { Title } from "./videoPhoto/Component";
import PhoneNumber from "../../../../common/PhoneNumber";
import { getAllCoachDetailsStart, saveCoachDetailListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/allCoachesDetails";
import { getAllCoachTypes } from "../../../../common/CommonQuery";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import flag from "./assets/img/americanFlag.png";
import {connect, useDispatch} from "react-redux";
import { coachTypeListStart } from "../../../../../redux/athlete/onBoarding/coachTypeList";
import { countryListStart } from "../../../../../redux/common/countriesList";
import {
  getAllCoachAndTeamDetails,
  getAllCountriesQuery,
} from "../../../../common/CommonQuery";
import Autocomplete from '@mui/material/Autocomplete';
import {
  checkPhoneNumberValidation,
  IsValidEmail,
  isValidPhoneNumbers,
  IsValidTwitterHandle,
  IsValidName
} from "../../../../../utils/validationUtils";
import { transcriptAndAcademicsaveStart } from "../../../../../redux/athlete/dashboard/editUserProfile/TranscriptsAndAcademic";
import messagePopup from "../../../../../utils/messagePopup";
import { getAuthToken, getUserInfo } from "../../../../../services/authService";
import axios from "axios";
import { imageUploadUrl } from "../../../../../config";
import { saveAcademicFileQuery } from "./GradesScoresQuery";
import {
  referencesListStart,
  referencessaveStart,
} from "../../../../../redux/athlete/dashboard/editUserProfile/allReferences";
import {
  getAllReferenceDataQuery,
  getTeamId,
  saveAllReferenceDataQuery,
  filterFirstNameQuery,
  filterLastNameQuery,
  filterTeamQuery,
  saveTeam,
  saveCoachDetailsQuery
} from "./ReferencesQuery";
import BackdropLoader from "../../../../common/Loader";
import {fetchGraphMethod, fetchMethod} from "../../../../../services/httpService";
import ScreenSwitch from "./ScreenSwitch";
import SubHeader from "./SubHeader";
import GoTopButton from "./GoTopButton";
import {saveSchoolTeamMapping} from "./teams/TeamQuery";
import {styleSheet} from '../../../../common/styleLabel'
import {getCamelCase} from "../../../../common/camelCaseFunc";
import toastr from 'toastr';
import { formatPhoneNumber } from "../../../../../utils/validationUtils";
import {getAllTeamQuery} from "../../onboarding/primaryCoach/primaryCoachQuery";
import {getAllTeamsNameQuery, getAllTeamsRefNameQuery} from "./events/AddEditEventFormQuery";


const SelectBox = ({
  dataList,
  keyValue,
  onChangeObj,
  selectedValue,
  getValue = "id",
}) => {
  const onChange = (event) => {
    onChangeObj(event, event.target.value);
  };
  const getMenuItems = () => {
    if (!dataList) {
      return;
    }
    return dataList.map((item) => {
      return (
        <MenuItem key={item.id} value={item[getValue] || ""}>
          {item[keyValue]}
        </MenuItem>
      );
    });
  };
  return (
    <FormControl variant="outlined" fullWidth>
      <Select
        onChange={onChange}
        // id="select1"
        // label="Select"
        placeholder={"sdfsdf"}
        value={selectedValue || ""}
        displayEmpty
      >
        <MenuItem value="">Select</MenuItem>
        {getMenuItems()}
      </Select>
    </FormControl>
  );
};
const ReferenceBox = ({
  Name,
  onAddEditClick,
  onDeleteClick,
  referenceData,
  handleEdit
}) => {
  const onDeleteHandler = () => {
    onDeleteClick(referenceData.id);
  };
  const onEditHandler = () => {
    onAddEditClick(referenceData);
  };
  return (
     <li>
      <div className="reference-box">
        <div className="title-box main">
          <h3>{Name}</h3>
          <div className="button-box">
            <button className="trash-button" onClick={onDeleteHandler}>
              <span className="icon-box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.9"
                  height="17.5"
                  viewBox="0 0 15.9 17.5"
                >
                  <g transform="translate(-3.75 -2.25)">
                    <path
                      className="a"
                      d="M4.5,9H18.9"
                      transform="translate(0 -2.8)"
                    />
                    <path
                      className="a"
                      d="M18.7,6.2V17.4A1.6,1.6,0,0,1,17.1,19h-8a1.6,1.6,0,0,1-1.6-1.6V6.2m2.4,0V4.6A1.6,1.6,0,0,1,11.5,3h3.2a1.6,1.6,0,0,1,1.6,1.6V6.2"
                      transform="translate(-1.4)"
                    />
                    <path
                      className="a"
                      d="M15,16.5v4.8"
                      transform="translate(-4.9 -6.3)"
                    />
                    <path
                      className="a"
                      d="M21,16.5v4.8"
                      transform="translate(-7.7 -6.3)"
                    />
                  </g>
                </svg>
              </span>
              Delete
            </button>
            <span onClick={()=>handleEdit("Edit")}>
            <button className="edit-button" onClick={onEditHandler}>
              <span className="icon-box">
                <svg
                  xmlns="m,m,m,,,,,,,,,,,,,,,,,,m,m,,mhttp://www.w3.org/2000/svg"
                  width="15.641"
                  height="15.578"
                  viewBox="0 0 15.641 15.578"
                >
                  <path
                    className="a"
                    d="M15.24,2.528,13.187.475a1.515,1.515,0,0,0-2.138.086A1.517,1.517,0,0,0,10.962,2.7l2.053,2.053a1.516,1.516,0,0,0,2.139-.086,1.516,1.516,0,0,0,.085-2.139ZM2.15,9.458l4.106,4.106L12.93,6.892,8.824,2.786,2.15,9.458ZM0,15.651l5.416-1.085L1.085,10.235Z"
                    transform="translate(0 -0.074)"
                  />
                </svg>
              </span>
              Edit
            </button>
              </span>
          </div>
        </div>
        <ul className="reference-detail-box main">
          <li>
            <span>Reference Type</span>{" "}
            <span>{referenceData.reftype.typename}</span>
          </li>
          <li>
            <span>Team / Organization</span> <span>{referenceData?.team?.length > 20 ?
              `${referenceData?.team?.slice(0,20)}...`: referenceData?.team }</span>
          </li>
          <li>
            <span>Mobile Phone</span> <span>{referenceData.refphone}</span>
          </li>
          <li>
            <span>Email Address</span> <span>{referenceData.refemailid}</span>
          </li>
          <li>
            <span>Twitter Handle</span>
            <span>{referenceData.twittername ? "@" : ""}{referenceData.twittername}</span>
          </li>
        </ul>
      </div>
        </li> 
  );
};

const AddEditOverlay = ({
  onAddEditClick,
  allCountryList,
  coachType,
  coachDetailsList,
  transcriptAndAcademicsaveStart,
  onSave,
  referenceEditValues,
  onReferenceChange,
  setRenderCount,
  renderCount,
    isEdit,
  onResend,
  setObjCoach
}) => {
  const getMyCountryDetails = (id) => {
    let countryT = null;
    allCountryList.map((country) => {
      if (country.id === id) {
        countryT = {
          value: country.id,
          label: country.countryName + `(+${country.countryCode})`,
          flag: country.countryFlagImage,
          countryCode: country.countryCode,
          countryShortName: country.countryShortName,
        };
      }
    });
    return countryT;
  };
  const defaultCountryValue = allCountryList.map((country) => {
    if (country.countryShortName === "US") {
      return {
        value: country.id,
        label: country.countryName + `(+${country.countryCode})`,
        flag: country.countryFlagImage,
        countryCode: country.countryCode,
        countryShortName: country.countryShortName,
      };
    }
  });
  const [coachfirstnameList, setCoachFirstNameList] = useState([]);
  const [coachlastnameList, setCoachLastNameList] = useState([]);
  const [teamnameList, setTeamnameList] = useState([]);
  const [academicFilesFields, setAcademicFilesFields] = useState({
    fileid: "",
    description: "",
    type: "",
    allowMytracscript: "",
    fileName: "",
    selectedFile: "",
  });
  const [error, setError] = React.useState({});
  const [fields, setFields] = React.useState({
    id: referenceEditValues.id ? referenceEditValues.id : null,
    countryDetails:
      getMyCountryDetails(referenceEditValues.refcountryid) ||
      defaultCountryValue[0],
    refemailid: referenceEditValues.refemailid
      ? referenceEditValues.refemailid
      : "",
    reftype: referenceEditValues.reftype
      ? referenceEditValues.reftype.id
        ? referenceEditValues.reftype.id
        : null
      : null,
    reffirstname: referenceEditValues.reffirstname
      ? referenceEditValues.reffirstname
      : "",
    reflastname: referenceEditValues.reflastname
      ? referenceEditValues.reflastname
      : "",
    refphone: referenceEditValues.refphone ? referenceEditValues.refphone : "",
    refdocname: referenceEditValues.refdocname
      ? referenceEditValues.refdocname
      : "",
    twittername: referenceEditValues.twittername
      ? referenceEditValues.twittername
      : "",
    team: referenceEditValues.team ? referenceEditValues.team : "",
    notes: referenceEditValues.notes ? referenceEditValues.notes : "",
  });
  useEffect(() => {
    let referenceObj = {
      id: fields.id,
      notes: fields.notes,
      refemailid: fields.refemailid,
      reftype: {
        id: fields.reftype,
      },
      reffirstname: fields.reffirstname,
      coachid: referenceEditValues.coachid,
      reflastname: fields.reflastname,
      refphone: fields.refphone,
      refdocname: fields.refdocname,
      twittername: fields.twittername,
      team: fields.team,
      refcountryid: fields.countryDetails.value,
    };
        onReferenceChange(referenceObj);
        setObjCoach(referenceObj)
  }, [fields]);
  const handleObjChange =async (field, e) => {
    let value = "";
    setError({
      error: "",
    });
    if (field === "reffirstname") {
      const variables = {
        where: {
          active: 1,
          and: [
            {
              or: [
                { reffirstname: { like: `%${e.target.value}%` } },
              ],
            },
          ],
        },
      };
      if (e.target.value.toString().length > 2) {
        let res = await fetchGraphMethod(
          filterFirstNameQuery,
          variables,
          true
        );
        let firstnamelist = res?.data?.data?.allReferences?.References?.length > 0 ? res?.data?.data?.allReferences?.References : [];
        setCoachFirstNameList([...firstnamelist]);
      }
    } else if (field === "reflastname") {
      const variables = {
        where: {
          active: 1,
          and: [
            {
              or: [
                { reflastname: { like: `%${e.target.value}%` } },
              ],
            },
          ],
        },
      };
      if (e.target.value.toString().length > 2) {
        let res = await fetchGraphMethod(
          filterLastNameQuery,
          variables,
          true
        );
        let lastnamelist = res?.data?.data?.allReferences?.References?.length > 0 ? res?.data?.data?.allReferences?.References : [];
        setCoachLastNameList([...lastnamelist]);
      }
    } else if (field === "team") {
      const variables = {
        where: {
          active: 1,
          isverified: 1,
          and: [
            {
              or: [
                { teamname: { like: `%${e.target.value}%` } },
              ],
            },
          ],
        },
      };
      if (e.target.value.toString().length > 2) {
        let res = await fetchGraphMethod(
          getAllTeamsRefNameQuery,
          variables,
          true
        );
        let teamlist = res?.data?.data?.allTeams?.Teams?.length > 0 ? res?.data?.data?.allTeams?.Teams?.map((el) =>{
          return(
              {...el,team : el?.teamname}
          )
        }) : [];
        setTeamnameList([...teamlist]);
      }
    } else if (field === "refemailid") {
      value = e ? (e.target ? (e.target.value ? e.target.value : "") : "") : "";
    }
    // handleChange(field, { target: { value } });
  };
  const handleChange = (field, e) => {
    if(field === 'twittername'){
      if(!e.target.value.includes('@')){
        let fieldsT = fields;
    fieldsT[field] = e
      ? e.target
        ? e.target.value
          ? e.target.value
          : ""
        : ""
      : "";
    setFields({
      ...fieldsT,
    });
    setError({
      ...error,
      reffirstname: false,
      twitterError: false,
      phoneNumberError: false,
      notesError: false,
      emailError: false,
      reftype: false
    });
      }
    }else if (field === 'refphone'){
      if(e?.target.value?.length < 11){
        let fieldsT = fields;
    fieldsT[field] = formatPhoneNumber(e.target.value)
    setFields({
      ...fieldsT,
    });
    setError({
      ...error,
      reffirstname: false,
      twitterError: false,
      phoneNumberError: false,
      notesError: false,
      emailError: false,
      reftype: false
    });
      }
    }else{
    let fieldsT = fields;
    fieldsT[field] = e
      ? e.target
        ? e.target.value
          ? e.target.value
          : ""
        : ""
      : "";
    setFields({
      ...fieldsT,
    });
    setError({
      ...error,
      reffirstname: false,
      reflastname: false,
      twitterError: false,
      phoneNumberError: false,
      notesError: false,
      emailError: false,
      reftype: false
    });
  }
  };
  const selectReferenceDocument = (event) => {
    const filedetails = event.target.files[0].name.split(".")
    let fileExtension = event.target.files[0].name.split(".")[filedetails.length - 1]?.toLowerCase();
    let { files } = event.target;
    // let fileExtension = files[0].name.split(".")[1];
    let fileSize = parseInt(event.target.files[0].size) / (1024 * 1024);
    if (
      fileExtension === "pdf" ||
      fileExtension === "jpg" ||
      fileExtension === "png" ||
      fileExtension === "doc" ||
      fileExtension === "docx" ||
      fileExtension === "gif"
    ) {
      if (parseFloat(fileSize).toFixed(2) <= 10) {
        let fieldsT = academicFilesFields;
        fieldsT["selectedFile"] = files[0];
        fieldsT["fileExtension"] = fileExtension;
        fieldsT["fileName"] = files ? files[0].name : "";
        setAcademicFilesFields({
          ...fieldsT,
        });
        setFields({ ...fields, refdocname: files ? files[0].name : ""});
        addFiles(event);
      } else {
        // messagePopup("", "File size not more than 10 MB.", "error");
        toastr.error("File size not more than 10 MB.", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates:true,
        })
        setFields({
          ...fields,
          refdocname: ""
        })
      }
    } else {
      // messagePopup(
      //   "",
      //   "File type must be .pdf, .doc, .docx, .jpg, .png, .gif",
      //   "error"
      // );
      toastr.error("File type must be .pdf, .doc, .docx, .jpg, .png, .gif.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
      setFields({
        ...fields,
        refdocname: ""
      })
    }
  };
  const addFiles = async (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("file", academicFilesFields.selectedFile);
    const response =
      academicFilesFields.selectedFile !== ""
        ? await axios({
            url: imageUploadUrl,
            method: "POST",
            data: formData,
            headers: {
              access_token: getAuthToken(),
            },
          })
        : "";
    if (academicFilesFields.selectedFile !== "" && response && response.status === 200) {
      const uploadedFileName =
        academicFilesFields.selectedFile !== ""
          ? response.data.result?.files?.file[0]?.name
            ? response.data.result.files.file[0].name
            : academicFilesFields.fileName
          : academicFilesFields.fileName;
    }
  };
  const handleCountryChange = (option) => {
    const fieldsT = { ...fields };
    fieldsT["countryDetails"] = option;
    setFields({ ...fieldsT, refphone: "" });
  };
  const onSaveHandler = async () => {
    //Validate Twitter Handle
    if(fields.reffirstname === ""){
      setError({...error, reffirstname: true});
      return;
    }
    //Validate last name
    if(fields.reflastname === ""){
      setError({...error, reflastname: true});
      return;
    }    
    //Validate ref type
    if(fields.reftype === null){
      setError({...error, reftype: true});
      return;
    }
    //Validate email id
    if(fields.refemailid === ""){
      setError({...error, emailError: true});
      return;
    }
    // let re = new RegExp(/[a-zA-Z]/);
    if (typeof fields?.reffirstname == "object" ? !IsValidName(fields?.reffirstname?.reffirstname) :  !IsValidName(fields?.reffirstname)) {
      setError({...error, reffirstname: true});
      return;
    }
    if(!!fields.reflastname){
    if (typeof fields?.reflastname == "object" ? !IsValidName(fields?.reflastname?.reflastname) : !IsValidName(fields?.reflastname)) {
      setError({...error, reflastname: true});
      return;
    }
  }
    if (fields.twittername && !IsValidTwitterHandle(fields.twittername)) {
      setError({ ...error, twitterError: true });
      return;
    }
    if (
      fields.refphone &&
      !checkPhoneNumberValidation(
        fields.refphone,
        fields.countryDetails.countryShortName
      )
    ) {
      setError({ ...error, phoneNumberError: true });
      return;
    }
    if (!!fields.refemailid && !IsValidEmail(fields.refemailid)) {
      setError({ ...error, emailError: true });
      return;
    }
    if (fields.notes && fields.notes.length > 1500) {
      setError({ ...error, notesError: true });
      return;
    }
    if (fields.team) {
      let variables = {
        obj: {
          id: fields?.team?.id ? fields?.team?.id : null,
          teamname: fields?.team?.team ? fields?.team?.team : fields?.team,
          active: 1,
        },
      };
      let response = await fetchGraphMethod(getTeamId, variables, true);
      if (response.data.data.allTeams.Teams) {
        if (!response.data.data.allTeams.Teams.length) {
          fetchGraphMethod(saveTeam, variables, true);
        }
      }
    }
    if (
      fields.refemailid ||
      fields.refphone ||
      fields.reftype ||
      fields.twittername ||
      fields.refdocname ||
      fields.reffirstname ||
      fields.team ||
      fields.reflastname
    ) {
      let referenceObj = {
        id: fields.id,
        notes: fields.notes,
        refemailid: fields.refemailid,
        reftype: fields.reftype,
        reffirstname: fields?.reffirstname?.reffirstname ? fields?.reffirstname?.reffirstname : fields?.reffirstname,
        reflastname: fields?.reflastname?.reflastname ? fields?.reflastname?.reflastname : fields?.reflastname,
        refphone: fields.refphone,
        refdocname: fields.refdocname,
        twittername: fields.twittername,
        team: fields?.team?.team ? fields?.team?.team : fields?.team,
        refcountryid: fields.countryDetails.value,
      };
      onSave(referenceObj);
      // await onResend(fields)
    }
    // onAddEditClick();
    setTimeout(()=>{
      setRenderCount(renderCount + 1)
    },1800)
  };
  return (
    <div
      className="modal fade common-modal addEditOverlay addReferenceOverlay show"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <form className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{isEdit ? "Edit" : "Add"} Reference</h5>
            <button
              type="button"
              className="close"
              style={{ color: "#000" }}
              onClick={onAddEditClick}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md">
                <div className="form-group">
                  <label>First Name <span style={{color:'red'}}>*</span></label>
                  <div className="autocomplete-class refemailid">
                    <TextField
                      style={{ width: "100%" }}
                      value={fields.reffirstname || ""}
                      onChange={(e) => {
                        handleChange("reffirstname", {
                          target: {
                            value: e.target.value ? e.target.value : "",
                          },
                        });
                      }}
                    />
                    {/* <Autocomplete
                      id="combo-box-demo"
                      freeSolo={true}
                      options={coachfirstnameList}
                      onChange={(e, data) => {
                        handleChange("reffirstname", {
                          target: {
                            value: data ? data : "",
                          },
                        });
                      }}
                      value={fields.reffirstname || ""}
                      getOptionLabel={(option) =>
                        option.reffirstname ? option.reffirstname : option
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={fields.reffirstname || ""}
                          placeholder = "First name"
                          onChange={(e) => {
                            handleObjChange("reffirstname", {
                              target: { value: e.target.value ? e.target.value : "" },
                            });
                            handleChange("reffirstname", {
                              target: {
                                value: e.target.value ? e.target.value : "",
                              },
                            });
                          }}
                        /> 
                      )}
                    /> */}
                     {error['reffirstname'] == true  && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        Please enter a valid first name.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="form-group">
                  <label>Last Name <span style={{color:'red'}}>*</span></label>
                  <div className="autocomplete-class refemailid">
                    <TextField
                      style={{ width: "100%" }}
                      value={fields.reflastname || ""}
                      onChange={(e) => {
                        handleChange("reflastname", {
                          target: {
                            value: e.target.value ? e.target.value : "",
                          },
                        });
                      }}
                    />
                    {/* <Autocomplete
                      id="combo-box-demo"
                      freeSolo={true}
                      options={coachlastnameList}
                      onChange={(e, data) => {
                        handleChange("reflastname", {
                          target: {
                            value: data ? data : "",
                          },
                        });
                      }}
                      value={fields.reflastname || ""}
                      getOptionLabel={(option) =>
                        option.reflastname ? option.reflastname : option
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={fields.reflastname || ""}
                          placeholder = "Last name"
                          onChange={(e) => {
                            handleObjChange("reflastname", {
                              target: { value: e.target.value ? e.target.value : "" },
                            });
                            handleChange("reflastname", {
                              target: {
                                value: e.target.value ? e.target.value : "",
                              },
                            });
                          }}
                        />
                      )}
                    /> */}
                    {/*{error["reflastname"] && (*/}
                    {/*  <p style={{ color: "red", textAlign: "left" }}>*/}
                    {/*    Please enter the Last name in alphabets.*/}
                    {/*  </p>*/}
                    {/*)}*/}
                    {error['reflastname'] == true && (
                            <p style={{ color: "red", textAlign: "left" }}>
                              Please enter a valid last name.
                            </p>
                        )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <div className="form-group">
                  <span style={{...styleSheet,textAlign:"left"}}>Reference Type <span style={{color:'red'}}>*</span></span>
                  <SelectBox
                    placeholder="Select"
                    keyValue="coachtypename"
                    selectedValue={fields.reftype || ""}
                    onChangeObj={(e, data) => {
                      handleChange("reftype", {
                        target: { value: data },
                      });
                    }}
                    dataList={coachType}
                  />
                  {error["reftype"] && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        Please select reference type.
                      </p>
                  )}
                </div>
              </div>
              <div className="col-md">
                <div className="form-group">
                  <label>Team / Organization </label>
                  <div className="autocomplete-class">
                    <Autocomplete
                      id="combo-box-demo"
                      freeSolo={true}
                      options={teamnameList}
                      onChange={(e, data) => {
                        handleChange("team", {
                          target: { value: data ? data : "" },
                        });
                      }}
                      value={fields.team || ""}
                      getOptionLabel={(option) =>
                        option.team ? option.team : option
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={fields.team || ""}
                          placeholder = "Team / Organization name"
                          onChange={(e) => {
                            handleObjChange("team", {
                              target: { value: e.target.value ? e.target.value : "" },
                            });
                            handleChange("team", {
                              target: { value: e.target.value ? e.target.value : "" },
                            });
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <div className="form-group">
                  <label>Email Address <span style={{color:'red'}}>*</span></label>
                  <div className="autocomplete-class refemailid">
                    <TextField
                      style={{ width: "100%" }}
                      value={fields.refemailid || ""}
                      onChange={(e) => {
                        handleChange("refemailid", {
                          target: {
                            value: e.target.value ? e.target.value : "",
                          },
                        });
                      }}
                    />
                    {error["emailError"] && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        Please enter valid email address.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="form-group">
                  <label>Mobile Phone</label>
                  <PhoneNumber
                    dropdownName="countryCode"
                    dropdownOnChange={handleCountryChange}
                    dropdownValue={fields.countryDetails || ""}
                    dropDownOptions={
                      allCountryList &&
                      allCountryList.map((item) => ({
                        value: item.id,
                        label: item.countryName + `(+${item.countryCode})`,
                        flag: item.countryFlagImage,
                        countryCode: item.countryCode,
                        countryShortName: item.countryShortName,
                      }))
                    }
                    formatOptionLabel={(option, { context, selectValue }) => {
                      return context === "menu" ? (
                        <>
                          <img
                            src={option.flag}
                            alt=""
                            style={{ width: "40px", marginRight: "2px" }}
                          />
                          <span>{option.label}</span>
                        </>
                      ) : (
                        <>
                          <img
                            src={option.flag}
                            alt=""
                            style={{ width: "40px", marginRight: "15px" }}
                          />
                          <span>+{option.countryCode}</span>
                        </>
                      );
                    }}
                    inputPlaceholder="Phone Number"
                    inputType="phonenumber"
                    inputValue={fields.refphone || ""}
                    inputOnChange={(e) => {
                      handleChange("refphone", {
                        target: {
                          value: e.target.value.replace(/[^0-9]/gi, ""),
                        },
                      });
                    }}
                    inputOnBlur={(e) => {
                      handleChange("refphone", {
                        target: {
                          value: e.target.value.replace(/[^0-9]/gi, ""),
                        },
                      });
                    }}
                  />
                  {error["phoneNumberError"] && (
              <p style={{ color: "red", textAlign: "left" }}>
                Invalid Phone Number
              </p>
            )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Twitter Handle</label>
                  <span className="iconTwitter">@</span>
                  <input
                    className="form-control"
                    type="text"
                    name="phone"
                    id="phone"
                    value={fields.twittername || ""}
                    style={{ paddingLeft: "55px" }}
                    onChange={(e) => {
                      handleChange("twittername", {
                        target: {
                          value: e.target.value ? e.target.value : "",
                        },
                      });
                    }}
                  />
                   {error["twitterError"] && (
              <p style={{ color: "red", textAlign: "left" }}>
                Invalid Twitter Handle
              </p>
            )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <div className="form-group">
                  <div className="uploadFeature">
                    <label>Upload Recommendation</label>
                    <h5>
                      Maximum size of 10mb. DOC, DOCX, PDF, JPG, GIF, PNG.
                    </h5>
                    <label className="btn green" htmlFor="uploadFile">
                      Choose File
                    </label>
                    <input
                      type="file"
                      id="uploadFile"
                      hidden=""
                      onChange={selectReferenceDocument}
                      style={{ display: "none" }}
                    />
                    <div className="fileName">
                      {fields.refdocname}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <div className="form-group mb-0">
                  <label>Coach Recommendation</label>
                  <textarea
                    placeholder={
                      "Coach Recommendation here (Max 1500 Characters)"
                    }
                    onChange={(e) => {
                      handleChange("notes", e);
                    }}
                    value={fields.notes || ""}
                  ></textarea>
                   {error.notesError && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    Recommendation can't be more than 1500 characters
                  </p>
                )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn blue" onClick={()=>onSaveHandler()}>
              {isEdit ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const DeleteItem = ({ onDeleteClick, onCancelClick }) => {
  return (
    <div
      className="modal fade common-modal trash-modal show"
      id="ReferenceTrashModal"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" onClick={onCancelClick}>
              <img src={closeGrayIcon} alt="close Gray Icon" />
            </button>
            <div className="icon-box-first">
              <img src={trashCircleIcon} alt="trash Circle Icon" />
            </div>
            <div className="content-box">
              <h4>
                You're about to delete this Reference
                <br /> from your profile
              </h4>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              onClick={onDeleteClick}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn gray"
              data-dismiss="modal"
              onClick={onCancelClick}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditSection = ({
  onAddEditClick,
  onEditClick,
  onDeleteClick,
  allReferences,
  handleEdit,
  isEdit
}) => {
  const getAllReferences = () => {
    return allReferences.map((reference) => {
      return (
        <ReferenceBox
          key={reference?.id ?? 0}
          Name={`${reference.reffirstname} ${reference.reflastname}`}
          referenceData={reference}
          onAddEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          handleEdit={handleEdit}
        />
      )});
  };
  return (
    <div className="editSection">
      <Title title={"References"} />
      <div className="check-list-sec padding-lf-none">
          <div className="event-main-wrapper">
            <div className="event-top-block with-border">
              <div className="short-text">
                <p>
                  Adding your refrences will help improve your exposure and
                  search ranking.
                </p>
                <span onClick={()=>handleEdit("add")}>
                <button className="btn green"
                        onClick={onAddEditClick}
                        >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.75"
                    height="14.75"
                    viewBox="0 0 14.75 14.75"
                  >
                    <path
                      className="a"
                      d="M18.074,10.646H13.1V5.676a1.23,1.23,0,0,0-2.458,0v4.97H5.676a1.23,1.23,0,0,0,0,2.458h4.97v4.97a1.23,1.23,0,0,0,2.458,0V13.1h4.97a1.23,1.23,0,0,0,0-2.458Z"
                      transform="translate(-4.5 -4.5)"
                    />
                  </svg>
                  Add Reference
                </button>
                  </span>
              </div>
            </div>
            <div className="reference-data-box">
              <ul className="reference-list">
                {getAllReferences()}
              </ul>
            </div>
            <div className="common-button-box with-next-prev center">
              <div className="next-prev-button">
                <ScreenSwitch
                  prevLink={"/addtionalSports"}
                  nextLink={"/press"}
                  isSaved={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

const References = (props) => {
  const [sideMenuOpen, setSideMenu] = useState(false),
    [showAddEditModal, setShowAddEditModal] = useState(false),
    [showDeleteModal, setShowDeleteModal] = useState(false);
  const [referenceIdDel, setReferenceIdDel] = useState(0);
  const [previousData, setPreviousData] = useState()
  const fakeInput = useRef(null);
  const [referenceEditValues, setReferenceEditValues] = useState({
    id: 0,
    notes: "",
    refemailid: "",
    reffirstname: "",
    reflastname: "",
    refphone: "",
    refdocname: "",
    twittername: "",
    team: "",
    refcountryid: "",
    reftype: {
      id: "",
      typename: "",
    },
  });
  const [allReferencesList, setAllReferencesList] = useState([
    {
      id: 0,
      notes: "",
      refemailid: "",
      reffirstname: "",
      reflastname: "",
      refphone: "",
      refdocname: "",
      twittername: "",
      team: "",
      refcountryid: "",
      reftype: {
        id: "",
        typename: "",
      },
    },
  ]);
  const [allCoachTypeList, setAllCoachTypeList] = useState([
    { id: 0, coachtypename: "" },
  ]);
  const [coachDetailsList, setCoachDetailsList] = useState([
    {
      id: "",
      teamname: "",
      coachfirstname: "",
      coachemail: "",
      coachlastname: "",
      coachtypeid: "",
    },
  ]);
  const [allCountryList, setAllCountryList] = useState([
    {
      id: 0,
      countrycode: "",
      countryname: "",
      countryflagimage: "",
    },
  ]);
  const [renderCount, setRenderCount] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [allRefCount, setAllRefCount] = useState(0)
  const [objCoach, setObjCoach] = useState({})
  const [oldRefEmail, setOldRefEmail] = useState("")
  //for progress bar
  const dispatch = useDispatch()

  //Invoke All List Data
  useEffect(() => {
    props.countryListStart({
      query: getAllCountriesQuery,
    });
    props.coachTypeListStart({
      query: getAllCoachTypes,
    });
    props.getAllCoachDetailsStart({
      query: getAllCoachAndTeamDetails,
    });
    props.referencesListStart({
      query: getAllReferenceDataQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
    fakeInput.current.focus();
    fakeInput.current.select();
  }, [renderCount]);
  
  useEffect(()=> {
    if(props.location.state){
      setPreviousData(props.location.state);
    }
   }, [props.location.state])
   

  //Effect To Map All Countries List
  useEffect(() => {
    if (props.allCountries && props.allCountries.data) {
      setAllCountryList(props.allCountries.data);
    }
  }, [props.allCountries]);

  //Effect to map all the coach type list
  useEffect(() => {
    if (props.coachType && props.coachType.data) {
      setAllCoachTypeList(props.coachType.data);
    }
  }, [props.coachType, allRefCount]);
  //Effect to map all the coach Info
  useEffect(() => {
    if (props.allCoachDetails && props.allCoachDetails.data) {
      setCoachDetailsList(props.allCoachDetails.data);
    }
  }, [props.allCoachDetails, allRefCount]);

  //Effect to map all references
  useEffect(() => {
    if (
      props.allReferences &&
      props.allReferences.data &&
      props.allReferences.data.allreferencedata &&
      props.allReferences.data.allreferencedata.References
    ) {
      let allReferencesT =
        props.allReferences.data.allreferencedata.References.map(
          (reference) => {
            return {
              id: reference.id,
              refemailid: reference.refemailid,
              reffirstname: reference.reffirstname,
              reflastname: reference.reflastname,
              refphone: reference.refphone,
              refdocname: reference.refdocname,
              twittername: reference.twittername,
              coachid: reference.coachid,
              notes: reference.notes,
              team: reference.team,
              refcountryid: reference.refcountryid,
              reftype: {
                id: reference.referencereftypemaprel
                  ? reference.referencereftypemaprel.Coachtypes
                    ? reference.referencereftypemaprel.Coachtypes[0]
                      ? reference.referencereftypemaprel.Coachtypes[0].id
                        ? reference.referencereftypemaprel.Coachtypes[0].id
                        : 0
                      : 0
                    : 0
                  : 0,
                typename: reference.referencereftypemaprel
                  ? reference.referencereftypemaprel.Coachtypes
                    ? reference.referencereftypemaprel.Coachtypes[0]
                      ? reference.referencereftypemaprel.Coachtypes[0]
                          .coachtypename
                        ? reference.referencereftypemaprel.Coachtypes[0]
                            .coachtypename
                        : ""
                      : ""
                    : ""
                  : "",
              },
            };
          }
        );
      setAllReferencesList(allReferencesT);
    }
  }, [props.allReferences, allRefCount]);

  //Effect to map SavedReferences
  useEffect(() => {
    if (
      props.saveReferenceData &&
      props.saveReferenceData.data &&
      props.saveReferenceData.data.saveReferenceData &&
      props.saveReferenceData.data.saveReferenceData.active !== 0
    ) {
      let allReferencesT = allReferencesList.filter((reference) => {
        if (
          reference.id !== props.saveReferenceData.data.saveReferenceData.id
        ) {
          return reference;
        }
      });
      let referenceObj = {
        id: props.saveReferenceData.data.saveReferenceData.id,
        refemailid: props.saveReferenceData.data.saveReferenceData.refemailid,
        reffirstname:
          props.saveReferenceData.data.saveReferenceData.reffirstname,
        reflastname: props.saveReferenceData.data.saveReferenceData.reflastname,
        refphone: props.saveReferenceData.data.saveReferenceData.refphone,
        refdocname: props.saveReferenceData.data.saveReferenceData.refdocname,
        twittername: props.saveReferenceData.data.saveReferenceData.twittername,
        team: props.saveReferenceData.data.saveReferenceData.team,
        coachid: props.saveReferenceData.data.saveReferenceData.coachid,
        notes: props.saveReferenceData.data.saveReferenceData.notes,
        refcountryid:
          props.saveReferenceData.data.saveReferenceData.refcountryid,
        reftype: {
          id: props.saveReferenceData.data.saveReferenceData
            .referencereftypemaprel
            ? props.saveReferenceData.data.saveReferenceData
                .referencereftypemaprel.Coachtypes
              ? props.saveReferenceData.data.saveReferenceData
                  .referencereftypemaprel.Coachtypes[0]
                ? props.saveReferenceData.data.saveReferenceData
                    .referencereftypemaprel.Coachtypes[0].id
                  ? props.saveReferenceData.data.saveReferenceData
                      .referencereftypemaprel.Coachtypes[0].id
                  : 0
                : 0
              : 0
            : 0,
          typename: props.saveReferenceData.data.saveReferenceData
            .referencereftypemaprel
            ? props.saveReferenceData.data.saveReferenceData
                .referencereftypemaprel.Coachtypes
              ? props.saveReferenceData.data.saveReferenceData
                  .referencereftypemaprel.Coachtypes[0]
                ? props.saveReferenceData.data.saveReferenceData
                    .referencereftypemaprel.Coachtypes[0].coachtypename
                  ? props.saveReferenceData.data.saveReferenceData
                      .referencereftypemaprel.Coachtypes[0].coachtypename
                  : ""
                : ""
              : ""
            : "",
        },
      };
      allReferencesT.push(referenceObj);
      setAllReferencesList(allReferencesT);
    }
    if (
      props.saveReferenceData &&
      props.saveReferenceData.data &&
      props.saveReferenceData.data.saveReferenceData &&
      props.saveReferenceData.data.saveReferenceData.active === 0
    ) {
      let allReferencesT = allReferencesList.filter((reference) => {
        if (
          reference.id !== props.saveReferenceData.data.saveReferenceData.id
        ) {
          return reference;
        }
      });
      setAllReferencesList(allReferencesT);
    }
  }, [props.saveReferenceData]);

  const onReferenceChange = (referenceData) => {
    setReferenceEditValues(referenceData);
  };
 
  const onSaveHandler = () => {
    if (
      !referenceEditValues.refemailid &&
      !referenceEditValues.refphone &&
      !referenceEditValues.reftype.id &&
      !referenceEditValues.twittername &&
      !referenceEditValues.refdocname &&
      !referenceEditValues.reffirstname &&
      !referenceEditValues.team &&
      !referenceEditValues.reflastname
    ) {
      return;
    } else {
      let referenceObj = {
        id: referenceEditValues.id,
        notes: referenceEditValues.notes,
        refemailid: referenceEditValues.refemailid,
        reftype: referenceEditValues?.reftype?.id ? referenceEditValues?.reftype?.id : null,
        reffirstname: referenceEditValues?.reffirstname?.reffirstname ? getCamelCase(referenceEditValues?.reffirstname?.reffirstname) : getCamelCase(referenceEditValues?.reffirstname),
       // referenceEditValues?.reffirstname?.reffirstname ? referenceEditValues?.reffirstname?.reffirstname : referenceEditValues?.reffirstname
        reflastname: referenceEditValues?.reflastname?.reflastname ? getCamelCase(referenceEditValues?.reflastname?.reflastname) : getCamelCase(referenceEditValues?.reflastname),
        //referenceEditValues?.reflastname?.reflastname ? referenceEditValues?.reflastname?.reflastname : referenceEditValues?.reflastname
        refphone: referenceEditValues.refphone,
        refdocname: referenceEditValues.refdocname,
        twittername: referenceEditValues.twittername,
        team: referenceEditValues?.team?.team ? referenceEditValues?.team?.team : referenceEditValues.team,
        refcountryid: referenceEditValues.refcountryid
          ? referenceEditValues.refcountryid
          : 0,
      };

      props.saveCoachDetailListStart({
        query: saveCoachDetailsQuery,
        variables: {
          obj: {
            id: referenceEditValues.coachid ? referenceEditValues.coachid : null,
            userid: getUserInfo().id,
            coachemail: referenceEditValues.refemailid,
            coachfirstname: referenceEditValues?.reffirstname?.reffirstname ? referenceEditValues?.reffirstname?.reffirstname : referenceEditValues?.reffirstname,
            coachlastname: referenceEditValues?.reflastname?.reflastname ? referenceEditValues?.reflastname?.reflastname : referenceEditValues?.reflastname,
            coachphonenumber: referenceEditValues.refphone,
            teamname: referenceEditValues?.team?.team ? referenceEditValues?.team?.team : referenceEditValues.team,
            coachcountrycode:  referenceEditValues.refcountryid
            ? referenceEditValues.refcountryid
            : 0
          },
        },
        nextScreen: (data) => {
          if(data?.saveCoachandteamdetails?.id){
          props.referencessaveStart({
            query: saveAllReferenceDataQuery,
            variables: {
              obj: {
                userid: getUserInfo() ? getUserInfo().id : 0,
                coachid: parseFloat(data?.saveCoachandteamdetails?.id),
                active: 1,
                ...referenceObj,
              },
            },
            handleReferenceMessage: (data) => {
              //this code is commented as per user cannot add same email id
              if(data?.errors?.length > 0){
                if(data?.errors[0]?.message?.status === 201){
                  //now this msg has change to generic
                  messagePopup("",
                      "Email Id already exist",
                      // data?.errors[0]?.message?.msg,
                      "warning");
                }
              }
              else{
                handleReferenceMessage();
                onResend(objCoach)
                setIsEdit(isEdit)
              }
              //last monent change 25/11/2022
              // handleReferenceMessage();
              // onResend(objCoach, data)
              // setIsEdit(isEdit)
            },
          });
        }
        }
      });
    }
    setTimeout(()=>{
      setAllRefCount(allRefCount + 1)
    },500)
    // setReferenceEditValues({
    //   id: 0,
    //   notes: "",
    //   refemailid: "",
    //   reffirstname: "",
    //   reflastname: "",
    //   refphone: "",
    //   refdocname: "",
    //   twittername: "",
    //   team: "",
    //   refcountryid: "",
    //   reftype: {
    //     id: "",
    //     typename: "",
    //   },
    // });
  };
  //send mail to caoch
  const onResend = async (coach, refSave) => {
    if(!!coach.reffirstname && !!coach.refemailid){
      if(coach?.refemailid == oldRefEmail) return console.log("same email ref id")
      const coachName = !!coach?.reffirstname?.id ? coach?.reffirstname?.reffirstname  : coach?.reffirstname
      let data = {
        refid: refSave?.data?.saveReference?.id ?? 0,
        athleteid: getUserInfo()?.id ?? 0,
        coachEmail: coach.refemailid,
        athleteName: getUserInfo().FirstName + " " + getUserInfo().lastName,
        coachName: coachName,
      };
      let res = await fetchMethod(
          "/api/Athleteschools/editCoachData",
          data,
          "post",
          true
      );
      if (res && res.status == 200) {
        setOldRefEmail("")
      } else {
        console.log("email send failed to caoch")
        // messagePopup("", "Something Went Wrong Please try again", "warning");
      }
    }
  };

  const handleReferenceMessage = () => {
    if(referenceEditValues.id ){
      setShowAddEditModal(false)
      // messagePopup("", "Reference Updated successfully.", "success");
      toastr.success("Reference Updated successfully.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }else {
      setShowAddEditModal(false)
      // messagePopup("", "Reference Saved successfully.", "success");
      toastr.success("Reference Saved successfully.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }
    if(previousData && previousData.previousPage === "checklist"){
      setShowAddEditModal(false)
      props.history.push("/checklist")
    }
    dispatch({type:"RENDER_COUNT_START", payload: 1})
  }
  const onDeleteHandler = () => {
    props.referencessaveStart({
      query: saveAllReferenceDataQuery,
      variables: {
        obj: {
          active: 0,
          id: referenceIdDel,
        },
      },
    });
    setShowDeleteModal(!showDeleteModal);
    setReferenceIdDel(0);
  };

  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };
  const onAddEditClick = () => {
    setShowAddEditModal(!showAddEditModal);
    setReferenceEditValues({})
  };
  const onEditHandler = (reference) => {
    setReferenceEditValues(reference);
    setShowAddEditModal(!showAddEditModal);
    setOldRefEmail(reference?.refemailid ?? "")
  };
  const onDeleteClick = (id) => {
    setReferenceIdDel(id);
    setShowDeleteModal(!showDeleteModal);
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  const handleEdit = (value) =>{
    if(value == "Edit") return setIsEdit(true)
    setIsEdit(false)
  }
  return (
    <div className="pageContent">
      <div className="editProfile">
        <BackdropLoader
          open={
            props.allReferences.loading ||
            props.allReferences.loading ||
            props.saveReferenceData.loading ||
            props.saveReferenceData.loading
          }
        />
        <input className="fakeInput" type="text" ref={fakeInput} />

        <div className="container">
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
          >
            <SubHeader hamburger={onSideMenuClick} buttonHide={false} />
            <div className="sidebar">
              <LeftSideEditProfile isSaved={true} />
            </div>
            <EditSection
              data={props}
              onEditClick={onEditHandler}
              onAddEditClick={onAddEditClick}
              onDeleteClick={onDeleteClick}
              allReferences={allReferencesList}
              isEdit={isEdit}
              handleEdit={handleEdit}
            />
          </div>
        </div>
        <GoTopButton goTop={goTop} />
      </div>
      <Dialog open={showAddEditModal} maxWidth={"lg"}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onAddEditClick()
                }
              }}
              className="matDialogCustom matDialogCustomOverlay"
      >
        <AddEditOverlay
          onAddEditClick={onAddEditClick}
          onSave={()=>onSaveHandler()}
          allCountryList={allCountryList}
          coachType={allCoachTypeList}
          coachDetailsList={coachDetailsList}
          transcriptAndAcademicsaveStart={props.transcriptAndAcademicsaveStart}
          referenceEditValues={referenceEditValues}
          onReferenceChange={onReferenceChange}
          setRenderCount={setRenderCount}
          renderCount={renderCount}
          isEdit={isEdit}
          onResend={onResend}
          setObjCoach={setObjCoach}
        />
      </Dialog>
      <Dialog open={showDeleteModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onDeleteClick()
                }
              }}
              className="matDialogCustom"
      >
        <DeleteItem
          onDeleteClick={onDeleteHandler}
          onCancelClick={onDeleteClick}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allCountries: state.common.countries,
    coachType: state.athlete.onBoarding.coachTypeList,
    allCoachDetails: state.athlete.editUserProfileData.allCoachDetails,
    allReferences: state.athlete.editUserProfileData.allReferences,
    saveReferenceData: state.athlete.editUserProfileData.allReferences,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    countryListStart: (data) => dispatch(countryListStart(data)),
    coachTypeListStart: (data) => dispatch(coachTypeListStart(data)),
    getAllCoachDetailsStart: (data) => dispatch(getAllCoachDetailsStart(data)),
    transcriptAndAcademicsaveStart: (data) =>
      dispatch(transcriptAndAcademicsaveStart(data)),
    referencesListStart: (data) => dispatch(referencesListStart(data)),
    saveCoachDetailListStart: (data) => dispatch(saveCoachDetailListStart(data)),
    referencessaveStart: (data) => dispatch(referencessaveStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(References);
