import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  FEATURES_LIST_START: "FEATURES_LIST_START",
  FEATURES_SUCCESS: "FEATURES_SUCCESS",
  FEATURES_FAILED: "FEATURES_FAILED",
};

// ============================================ Actions ========================================================
export const featuresListStart = (data) => ({
  type: actionType.FEATURES_LIST_START,
  payload: data,
});


export const featuresSuccess = (data) => ({
  type: actionType.FEATURES_SUCCESS,
  payload: data,
});

export const featuresFailed = (data) => ({
  type: actionType.FEATURES_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function featuresReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.FEATURES_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.FEATURES_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================


export function* featuresListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      let featuresData = response.data.data;
      yield put(featuresSuccess({ allfeaturesdata: featuresData }));
    } else {
      messagePopup("", "FEATURES List failed", "error");
      yield put(featuresFailed("FEATURES List failed"));
    }
  } catch (error) {
    messagePopup("", "FEATURES List failed", "error");
    yield put(featuresFailed("FEATURES List failed"));
  }
}

export function* watchFeaturesSaga() {
  yield takeEvery(actionType.FEATURES_LIST_START, featuresListSaga);
}
