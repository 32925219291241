import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { getAllCoachDetailsStart, saveCoachDetailListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/allCoachesDetails";
import { countryListStart } from "../../../../../../redux/common/countriesList";
import { getUserInfo } from "../../../../../../services/authService";
import { capitalizeFirstLetter } from "../../../../../../utils/capitalizeInitials";
import messagePopup from "../../../../../../utils/messagePopup";
import { checkPhoneNumberValidation, formattedPhoneNumber, IsValidName } from "../../../../../../utils/validationUtils";
import PhoneNumber from "../../../../../common/PhoneNumber";
import { saveCoachDetailsQuery } from "../../../onboarding/primaryCoach/primaryCoachQuery";
import { getCoachDetailsQuery } from "../../colleges/searchColleges/CollegeDetailsQuery";
import AccountSettingLeftNavigation from "./AccountSettingLeftNavigation";
import "./assets/css/coach-myaccount.scss";
import toastr from 'toastr';


function MyAccountCoach(props) {
    const [coachData, setCoachData] = useState();
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState({
        coachfullname: "",
        phoneNumber : "",
        importantNotification : false
       
    });

    useEffect(()=> {
    getCoachDetails();
    props.countryListStart();
    }, [])
    const getCoachDetails = () => {
        props.getAllCoachDetailsStart({
            query : getCoachDetailsQuery,
            variables : {
             where : {
               id : getUserInfo() && getUserInfo().id ? getUserInfo().id : null
             }
            }
          })
    }
    useEffect(()=> {
    if(props.coachesList && props.coachesList.data && props.coachesList.data[0]){
        setCoachData(props.coachesList.data[0])
    }
    let fieldsT = fields;
    fieldsT["coachfirstname"] = coachData && capitalizeFirstLetter(coachData.coachfirstname);
    fieldsT["coachlastname"] = coachData && capitalizeFirstLetter(coachData.coachlastname);
    fieldsT["phoneNumber"] = coachData && coachData.coachphonenumber;
    fieldsT["importantNotification"] = coachData && coachData.textmeimportantnotification;
    setFields({
        ...fieldsT
    })
    }, [props.coachesList.data]);

     useEffect(() => {
    if (props.allCountries.data) {
      const fieldsT = fields;
      fieldsT["countryCodeId"] = {
        value: props.allCountries.data ? props.allCountries.data[0].id : "",
        label: props.allCountries.data
          ? props.allCountries.data[0].countryName +
          `(+${props.allCountries.data[0].countryCode})`
          : "",
        flag: props.allCountries.data
          ? props.allCountries.data[0].countryFlagImage
          : "",
        countryCode: props.allCountries.data
          ? props.allCountries.data[0].countryCode
          : "",
        countryShortName: props.allCountries.data
          ? props.allCountries.data[0].countryShortName
          : "",
      };
      setFields({
        ...fieldsT,
      });
    }
  }, [props.allCountries.data]);

    const handleChange = (e, field) => {
        let errorsT = errors;
        setErrors({
            errorsT : ""
        });
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setFields({
        ...fieldsT
    });
    }

    const onChangeImportantNotification = (e) => {
        let fieldsT = fields;
        fieldsT["importantNotification"] = e.target.checked;
        setFields({
            ...fieldsT
        })
    }

    const handlePhonenumberChange = (selectedOption) => {
        const fieldsT = fields;
        fieldsT["countryCodeId"] = selectedOption;
        setFields({ ...fieldsT, phoneNumber: "" });
      };
      const handleValidation = (fieldname, e) => {
        let errorsT = errors;
        let formIsValid = true;
        let fieldValue = e ? (e.target ? e.target.value : e) : e;
        let updatedValue = fieldValue;
        //firstname validations
        if (fieldname === undefined || fieldname === "coachfirstname") {
          if (fieldname === undefined) {
            fieldValue = fields["coachfirstname"] ? fields["coachfirstname"] : "";
            updatedValue = fieldValue;
            delete errorsT["coachfirstname"];
          }
          if (!updatedValue) {
            formIsValid = false;
            errorsT["coachfirstname"] = "First name is required";
          } else if (!IsValidName(updatedValue)) {
            formIsValid = false;
            errorsT["coachfirstname"] = "Please enter a valid first name";
          } else if (updatedValue.length > 30) {
            formIsValid = false;
            errorsT["coachfirstname"] = "First name maximum length should be 30";
          }
        }
        //lastname validations
        if (fieldname === undefined || fieldname === "coachlastname") {
          if (fieldname === undefined) {
            fieldValue = fields["coachlastname"] ? fields["coachlastname"] : "";
            updatedValue = fieldValue;
          }
    
          delete errorsT["coachlastname"];
          if (!updatedValue) {
            formIsValid = false;
            errorsT["coachlastname"] = "Last name is required";
          } else if (!IsValidName(updatedValue)) {
            formIsValid = false;
            errorsT["coachlastname"] = "Please enter a valid last name";
          } else if (updatedValue.length > 30) {
            formIsValid = false;
            errorsT["coachlastname"] = "Last name maximum length should be 30";
          }
        }
       
        //phoneNumber validations
        if (fieldname === undefined || fieldname === "phoneNumber") {
          if (fieldname === undefined) {
            fieldValue = fields["phoneNumber"] ? fields["phoneNumber"] : "";
            updatedValue = fieldValue;
          }
          delete errorsT["phoneNumber"];
          if (!updatedValue) {
            formIsValid = false;
            errorsT["phoneNumber"] = "Mobile Number is required";
          } else if (
            !checkPhoneNumberValidation(
              updatedValue,
              fields.countryCodeId.countryShortName
            )
          ) {
            formIsValid = false;
            errorsT["phoneNumber"] = "Please enter a valid Phone Number";
          }
          updatedValue = formattedPhoneNumber(
            updatedValue,
            fields.countryCodeId.countryShortName
          );
        }
        
        setErrors({
          ...errorsT,
        });
        return {
          formIsValid,
          updatedValue,
        };
      };

    const onSave = () => {
        const { formIsValid } = handleValidation();
       if(formIsValid){
           props.saveCoachDetailListStart({
               query : saveCoachDetailsQuery,
               variables : {
                   obj : {
                       id : coachData.id,
                       coachfirstname: fields.coachfirstname,
                       coachlastname: fields.coachlastname,
                       coachphonenumber: fields.phoneNumber,
                       textmeimportantnotification : fields.importantNotification == true ? 1 : 0
                   }
               },
               nextScreen : nextScreen
           })
       }
    }
    const nextScreen = () => {
        getCoachDetails();
        // messagePopup("", "You have successfully updated your details", "success")
        toastr.success("You have successfully updated your details", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
    }
    
  return (
    <section className="account-main-sec">
      <div className="container">
        <div className="account-with-sidebar">
            
          <AccountSettingLeftNavigation />
          <div className="account-data-box">
            <h1 className="title">My Account</h1>
            <div className="account-form-box">
              <h4>Change your basic account setting</h4>
              <form>
                <div className="fieldBox flexBox">
                  <div className="half">
                    <label>First Name</label>
                    <input type="text" onChange={(e)=>handleChange(e, "coachfirstname")} value={fields.coachfirstname || ""} placeholder="First name" />
                    <div className="error">{errors["coachfirstname"]}</div>

                  </div>
                  <div className="half">
                    <label> Last Name</label>
                    <input type="text" onChange={(e)=>handleChange(e, "coachlastname")} value={fields.coachlastname || ""} placeholder="Last name" />
                    <div className="error">{errors["coachlastname"]}</div>
                  </div>
                </div>
                <div className="fieldBox flexBox">
                  <div className="half">
                    <label>
                      Email
                    </label>
                    <input type="email" value={coachData && coachData.coachemail || ""} disabled={true} placeholder=" " />
                  </div>
                  </div>
                <div className="fieldBox flexBox phone">
                  <div className="half">
                    <label>Mobile Phone <sup style={{color : "red"}}>*</sup></label>
                    <PhoneNumber
                      dropdownName="countryCode"
                      dropdownOnChange={handlePhonenumberChange}
                      dropdownValue={fields.countryCodeId || ""}
                      dropDownOptions={
                          props.allCountries && props.allCountries.data &&
                        props.allCountries.data.map((item) => ({
                          value: item.id,
                          label:
                            item.countryName + `(+${item.countryCode})`,
                          flag: item.countryFlagImage,
                          countryCode: item.countryCode,
                          countryShortName: item.countryShortName,
                        }))
                      }
                      formatOptionLabel={(
                        option,
                        { context, selectValue }
                      ) => {
                        return context === "menu" ? (
                          option.label
                        ) : (
                          <>
                            <img
                              src={option.flag}
                              alt=""
                              style={{
                                width: "40px",
                                marginRight: "15px",
                              }}
                            />
                            <span>+{option.countryCode}</span>
                          </>
                        );
                      }}
                      inputPlaceholder="Phone Number"
                      inputType="text"
                      inputValue={fields.phoneNumber || ""}
                      inputOnChange={(e) => {
                        handleChange({
                          target: {
                            value: e.target.value.replace(/[^0-9]/gi, ""),
                          },
                        } , "phoneNumber");
                      }}
                    />
              <div className="error">{errors["phoneNumber"]}</div>
                    <div className="info-text">
                      Who can see this?
                      <span
                        className="icon-box"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="gives an example bio"
                      >
                        <svg
                          id="information-button"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24.412"
                          height="24.412"
                          viewBox="0 0 24.412 24.412"
                        >
                          <path
                            id="Path_619"
                            data-name="Path 619"
                            d="M12.206,0A12.206,12.206,0,1,0,24.412,12.206,12.206,12.206,0,0,0,12.206,0Zm2.541,18.917q-.942.372-1.5.566a3.966,3.966,0,0,1-1.3.2,2.6,2.6,0,0,1-1.774-.557,1.8,1.8,0,0,1-.631-1.413,5.131,5.131,0,0,1,.046-.681c.032-.231.083-.492.152-.784l.786-2.778c.069-.267.129-.52.177-.755a3.349,3.349,0,0,0,.07-.654,1.007,1.007,0,0,0-.219-.741,1.244,1.244,0,0,0-.84-.208,2.2,2.2,0,0,0-.625.093c-.212.065-.4.124-.547.182l.208-.856q.772-.315,1.478-.538a4.365,4.365,0,0,1,1.333-.225,2.551,2.551,0,0,1,1.748.548,1.82,1.82,0,0,1,.614,1.422q0,.181-.042.638a4.266,4.266,0,0,1-.157.838l-.782,2.769c-.064.222-.121.476-.173.761a4.022,4.022,0,0,0-.075.647.929.929,0,0,0,.247.752,1.382,1.382,0,0,0,.855.2,2.479,2.479,0,0,0,.647-.1,3.678,3.678,0,0,0,.523-.176Zm-.138-11.24a1.867,1.867,0,0,1-1.317.508,1.886,1.886,0,0,1-1.323-.508,1.623,1.623,0,0,1-.551-1.233,1.639,1.639,0,0,1,.551-1.236,1.873,1.873,0,0,1,1.323-.514,1.852,1.852,0,0,1,1.317.514,1.665,1.665,0,0,1,0,2.469Z"
                            transform="translate(0 0)"
                            fill="#ccc"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="half">
                    <div className="checkbox-gorup">
                      <span>
                        <input type="checkbox" checked={fields.importantNotification == true ? true : false} onChange={(e)=>onChangeImportantNotification(e)}/>
                        <label>Text me important notification.</label>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="fieldBox">
                  <button className="btn green" onClick={()=>onSave()} type="button">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
const mapStateToProps = (state) => {
    return {
        coachesList : state.athlete.editUserProfileData.allCoachDetails,
        allCountries: state.common.countries,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllCoachDetailsStart : (data) => dispatch(getAllCoachDetailsStart(data)),
        saveCoachDetailListStart : (data) => dispatch(saveCoachDetailListStart(data)),
        countryListStart: (data) => dispatch(countryListStart(data)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCoach)


