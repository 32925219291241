import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  COLLEGES_LIST_START: "COLLEGES_LIST_START",
  COLLEGES_LIST_SUCCESS: "COLLEGES_LIST_SUCCESS",
  COLLEGES_LIST_FAILED: "COLLEGES_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const collegesListStart = (data) => ({
  type: actionType.COLLEGES_LIST_START,
  payload: data,
});

export const CollegesListSuccess = (data) => ({
  type: actionType.COLLEGES_LIST_SUCCESS,
  payload: data,
});

export const CollegesListFailed = (data) => ({
  type: actionType.COLLEGES_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function collegesListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.COLLEGES_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.COLLEGES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.COLLEGES_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* collegesListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const allUsercolleges = response.data.data.allUsercolleges.Usercolleges
        ? response.data.data.allUsercolleges.Usercolleges
        : null;
      let finalData = [];
      for (const iterator of allUsercolleges) {
        let tempData = { ...iterator };
        const collegeInfo = tempData.usercollegecollegeidmaprel
          ? tempData.usercollegecollegeidmaprel.Colleges
            ? tempData.usercollegecollegeidmaprel.Colleges[0]
            : null
          : null;
        if(tempData.usercollegecollegeidmaprel){
          delete tempData.usercollegecollegeidmaprel
        }
        tempData['collegeInfo'] = collegeInfo;
        finalData = [...finalData,tempData]
      }
      yield put(CollegesListSuccess(finalData));
    } else {
      messagePopup("", "COLLEGE List failed", "error");
      yield put(CollegesListFailed("GPA List failed"));
    }
  } catch (error) {
    messagePopup("", "COLLEGE List failed", "error");
    yield put(CollegesListFailed("COLLEGE List failed"));
  }
}

export function* watchCollegesListSaga() {
  yield takeEvery(actionType.COLLEGES_LIST_START, collegesListSaga);
}
