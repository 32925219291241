import React from 'react';
import { Dialog } from '@mui/material';
import { typeConvert } from '../../../common/thumbnail';
export const VideoPop = ({
  isTestSingle,
  setIsTestSingle,
  vidID,
  isLink = true,
  isToken,
}) => {
  return (
    <>
      <Dialog
        open={isTestSingle}
        maxWidth={'sm'}
        className={'video-modal matDialogCustom'}
        onClose={(event, reason) =>
          (reason == 'backdropClick' || reason == 'escapeKeyDown') &&
          setIsTestSingle()
        }
      >
        <>
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ margin: 0 }}
          >
            <div className="modal-content">
              <div
                className="modal-body youtube_embed_frame"
                id=""
                style={{ padding: '0px' }}
              >
                {isLink ? (
                  <iframe
                    id=""
                    height="315"
                    src={
                      !!vidID
                        ? `${vidID}?&autoplay=1&mute=1`
                        : 'https://www.youtube.com/embed/aqz-KE-bpKQ'
                    }
                    frameBorder="0"
                    allowFullScreen="allowFullScreen"
                    allow="autoplay"
                  ></iframe>
                ) : (
                  <video
                    preload={'metadata'}
                    autoPlay={true}
                    controls
                    controlsList={isToken == 1 ? '' : 'nodownload'}
                    style={{ backgroundColor: 'black' }}
                  >
                    <source
                      src={typeConvert(vidID, 'vidPop')}
                      alt="video/youtube"
                    />
                  </video>
                )}
              </div>
            </div>
          </div>
        </>
      </Dialog>
    </>
  );
};
