import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SCHOOL_TEAM_USER_MAPPING_LIST_START: "SCHOOL_TEAM_USER_MAPPING_LIST_START",
  SCHOOL_TEAM_USER_MAPPING_SAVE_START: "SCHOOL_TEAM_USER_MAPPING_SAVE_START",
  SCHOOL_TEAM_USER_MAPPING_LIST_SUCCESS:
    "SCHOOL_TEAM_USER_MAPPING_LIST_SUCCESS",
  SCHOOL_TEAM_USER_MAPPING_SAVE_SUCCESS:
    "SCHOOL_TEAM_USER_MAPPING_SAVE_SUCCESS",
  SCHOOL_TEAM_USER_MAPPING_LIST_FAILED: "SCHOOL_TEAM_USER_MAPPING_LIST_FAILED",
  SCHOOL_TEAM_USER_MAPPING_SAVE_FAILED: "SCHOOL_TEAM_USER_MAPPING_SAVE_FAILED",
};

// ============================================ Actions ========================================================
export const schoolTeamUserMappingStart = (data) => ({
  type: actionType.SCHOOL_TEAM_USER_MAPPING_LIST_START,
  payload: data,
});
export const schoolTeamUserMappingSave = (data) => ({
  type: actionType.SCHOOL_TEAM_USER_MAPPING_SAVE_START,
  payload: data,
});
export const schoolTeamUserMappingSaveSuccess = (data) => ({
  type: actionType.SCHOOL_TEAM_USER_MAPPING_SAVE_SUCCESS,
  payload: data,
});

export const schoolTeamUserMappingSaveFailed = (data) => ({
  type: actionType.SCHOOL_TEAM_USER_MAPPING_LIST_FAILED,
  payload: data,
});

export const schoolTeamUserMappingListSuccess = (data) => ({
  type: actionType.SCHOOL_TEAM_USER_MAPPING_LIST_SUCCESS,
  payload: data,
});

export const schoolTeamUserMappingListFailed = (data) => ({
  type: actionType.SCHOOL_TEAM_USER_MAPPING_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function schoolTeamUserMappingReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.SCHOOL_TEAM_USER_MAPPING_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SCHOOL_TEAM_USER_MAPPING_SAVE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SCHOOL_TEAM_USER_MAPPING_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.SCHOOL_TEAM_USER_MAPPING_SAVE_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionType.SCHOOL_TEAM_USER_MAPPING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SCHOOL_TEAM_USER_MAPPING_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* schoolTeamUserMappingSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        schoolTeamUserMappingSaveSuccess("Athlete Team saved successfully")
      );
      if (action.payload.refreshPage) action.payload.refreshPage();
    } else {
      messagePopup("", "User Team  Save failed", "error");
      yield put(schoolTeamUserMappingSaveFailed("User Team  Save failed"));
    }
  } catch (error) {
    messagePopup("", "User Team  Save failed", "error");
    yield put(schoolTeamUserMappingSaveFailed("User Team  Save failed"));
  }
}

export function* schoolTeamUserMappingListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        schoolTeamUserMappingListSuccess({
          schoolTeamUserMapping: response.data.data.allSchoolteamusermappings,
        })
      );
    } else {
      messagePopup("", "User Team  List failed", "error");
      yield put(schoolTeamUserMappingListFailed("User Team  List failed"));
    }
  } catch (error) {
    messagePopup("", "User Team  List failed", "error");
    yield put(schoolTeamUserMappingListFailed("User Team  List failed"));
  }
}

//Watcher
export function* watchSchoolTeamUserMappingSaga() {
  yield takeEvery(
    actionType.SCHOOL_TEAM_USER_MAPPING_SAVE_START,
    schoolTeamUserMappingSaveSaga
  );
  yield takeEvery(
    actionType.SCHOOL_TEAM_USER_MAPPING_LIST_START,
    schoolTeamUserMappingListSaga
  );
}
