export const addAlbumQuery = `
mutation SaveAlbum($obj:AlbumInput!){
    saveAlbum(obj:$obj){
      id
      albumname
    }
  }
`;
export const addTagQuery = `
mutation SaveTag($obj:TagInput!){
    saveTag(obj:$obj){
      id
      tagnmae
    }
  }
`;
export const getAllAlbumsTags = `
query fetch($where:JSON){
    allAlbums(where:$where){
      Albums{
        id
        albumname
        userid
        active
      }
    }
    allTags(where:$where){
      Tags{
        id
        tagnmae
        userid
      }
    }
  }
`;
