import "./App.css";
import Routes from "./components/routers/Routes";
import { heapToken } from "./config";
import { loadHeapEffect } from "./hooks/loadHeapEffect";

function App() {
  loadHeapEffect(heapToken);
  return <Routes />;
}

export default App;
