import {useEffect, useState} from "react";
import { ScrollTo } from "react-scroll-to/dist";
import "../../../../../../assets/css/owl.carousel.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";
import image from "../../../../../../assets/img/video-bg-img.png";

const options = {
  margin: 10,
  responsiveClass: true,
  nav: true,
  dots: false,
  responsive: {
    0: {
      items: 1,
      center: true,
    },
    415: {
      items: 1,
      center: true,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
};

const videoIds = [
  {
    id: "1mnYUP8IWz9",
    title: "Tournament Pitching",
    description: "Lorem ipsum dolor sit amet, consectetur",
    views: "39 Views",
    releaseData: "Apr 17, 2019",
    viewedByUsers: [
      "Sofia Wright",
      "Chase Drowsy",
      "Chase Drowsy",
      "Chase Drowsy",
    ],
  },
  {
    id: "1mnYUP8IWz8",
    title: "Tournament Pitching",
    description: "Lorem ipsum dolor sit amet, consectetur",
    views: "39 Views",
    releaseData: "Apr 17, 2019",
    viewedByUsers: [
      "Sofia Wright",
      "Chase Drowsy",
      "Chase Drowsy",
      "Chase Drowsy",
    ],
  },
  {
    id: "1mnYUP8IWz7",
    title: "Tournament Pitching",
    description: "Lorem ipsum dolor sit amet, consectetur",
    views: "39 Views",
    releaseData: "Apr 17, 2019",
    viewedByUsers: [
      "Sofia Wright",
      "Chase Drowsy",
      "Chase Drowsy",
      "Chase Drowsy",
    ],
  },
  {
    id: "1mnYUP8IWz6",
    title: "Tournament Pitching",
    description: "Lorem ipsum dolor sit amet, consectetur",
    views: "39 Views",
    releaseData: "Apr 17, 2019",
    viewedByUsers: [
      "Sofia Wright",
      "Chase Drowsy",
      "Chase Drowsy",
      "Chase Drowsy",
    ],
  },
  {
    id: "1mnYUP8IWz5",
    title: "title",
    description: "Lorem ipsum dolor sit amet, consectetur",
    views: "39 Views",
    releaseData: "Apr 17, 2019",
    viewedByUsers: [
      "Sofia Wright",
      "Chase Drowsy",
      "Chase Drowsy",
      "Chase Drowsy",
    ],
  },
];

function ViewMoreExamle(props) {
  const [showVideoPopup, setShowVideoPopup] = useState(true);
useEffect(()=>{
  window.scrollTo(0,0)
},[])
  return (
    <>
      <div
        className="tab-content resources-box-wrap"
        style={{ marginTop: "55px" }}
      >
        <div className="tab-pane active" id="HowtoVideo">
          <div className="resources-inner-list">
            <div className="tab-content current" id="Stats">
              <div className="video-box-row">
                <div className="video-box-item">
                  <h3 className="sec-title">Featured</h3>
                  <ul className="video-grid-list resources-video-slide owl-carousel owl-loaded">
                    <OwlCarousel className="owl-theme" {...options}>
                      {videoIds.map((data, index) => {
                        return (
                          <li key={index} className="video-box">
                            <div
                              className="img-box"
                              onClick={() => setShowVideoPopup(true)}
                            >
                              <video poster={image}>
                                <source
                                  src={`https://www.youtube.com/embed/${data.id}`}
                                  type="video/mp4"
                                />
                                <source
                                  src={`https://www.youtube.com/embed/${data.id}`}
                                  type="video/ogg"
                                />
                              </video>
                            </div>
                          </li>
                        );
                      })}
                    </OwlCarousel>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollTo>
        {({ scroll }) => (
          <a className="TopArrow" onClick={() => scroll({ x: 20, y: 0 })}></a>
        )}
      </ScrollTo>
    </>
  );
}
export default ViewMoreExamle;
