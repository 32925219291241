import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../../../../../assets/css/stylesheet.scss";
import "../assets/css/commitment-story.scss";
import { commitmentStoryStart } from "../../../../../../redux/athlete/profile/commitmentStory";
import {connect, useSelector} from "react-redux";
import {getAuthToken, getUserInfo} from "../../../../../../services/authService";
import { postDateFormat } from "../../../../../../utils/dateTimeFormatter";
import {imageDownloadUrl, websiteDefaultUrl} from "../../../../../../config";
import {fetchGraphMethod, fetchMethod} from "../../../../../../services/httpService";
import {getAllAthletesDataQuery} from "../../../../../common/CommonQuery";
import {TwitterShareButton, FacebookShareButton, LinkedinShareButton} from 'react-share'
import { viewCollegeListStart } from "../../../../../../redux/athlete/dashboard/colleges/viewCollege";
import { getAllFollowersQuery, getViewCollegesQuery, searchCountQuery } from "../../colleges/searchColleges/CollegeDetailsQuery";
import { photoVideoViewsListStart } from "../../../../../../redux/common/allPhotoVideoViews";
import { getVideoViewsQuery } from "../../colleges/myColleges/CollegeListQuery";
import { getFollowersListStart } from "../../../../../../redux/athlete/dashboard/homepage/followersList";
import { searchCollegeCountListStart } from "../../../../../../redux/athlete/dashboard/colleges/searchCollegeCount";
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";
import moment from 'moment'
import {getCamelCase} from "../../../../../common/camelCaseFunc";
import SharePost from "../../homepage/posts/SharePost";
import {Dialog, Tooltip} from "@mui/material";
import swal from 'sweetalert'
import { GetHelmet } from "../../../../../common/helmet";
import { useDispatch } from "react-redux";
import { viewCollegeSaveStart } from "../../../../../../redux/athlete/dashboard/colleges/viewCollege";
import { saveViewCollegeQuery } from "../../colleges/searchColleges/SearchCollegesQuery";
import {getUTCTimeDateDash} from '../../../../../../utils/dateTimeFormatter'
function CommitmentStory(props) {
  const clgID = +props?.match?.params?.collegeid;
  const userID = +props?.match?.params?.userid;
  const [isClgFav, setIsClgFav] = useState(0)
  const [sharePop, setSharePop] = useState(false)
  const [totatViews, setTotalViews] = useState({})
  const [clgStoryObj, setClgStoryObj] = useState({})
  const profileStatus = useDispatch()
  useEffect(() => {
    setSharePop(true)
    // findCollegeFav()
    getTotalVewies()
    getClgStoryRestCall()
  }, []);
  //this is used to scroll the page on top
  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0,0)
    },1500)
  },[])

  const getClgStoryRestCall = async () =>{
    const finalObj = {
      collegeid: clgID || 0,
      userid: userID || 0
    }
    const _res = await fetchMethod('/api/Collegecommitments/commitmentstory', finalObj, 'POST', null)
    if (_res?.status == 200 && _res?.data?.response?.status == 200){
      const clgStory = _res?.data?.response?.data?.length > 0 ? _res?.data?.response?.data?.[0] : {}
      setClgStoryObj(clgStory)
    }else{
      swal({title:"College Story Api Failed",icon:"error"})
      setClgStoryObj({})
    }
  }

  const getTotalVewies = async () =>{
   const body ={
     userid: userID
   }
   const _res = await fetchMethod('/api/Viewvideos/getProfileAndvideoviews', body,"POST",null)
    if (_res?.status == 200 && _res?.data?.response?.status == 200){
      const countObj = _res?.data?.response?.data
      setTotalViews(countObj)
    }else {
      setTotalViews({})
    }
  }
  //
  // const findCollegeFav = async () =>{
  //   const finalObj = {
  //     where:{
  //       userid: getUserInfo()?.id,
  //       collegeid:clgID
  //     }
  //   }
  //   const res = await fetchGraphMethod(allUserCollegeQuery, finalObj, true)
  //   if (res?.status == 200){
  //     const fav = res?.data?.data?.allUsercolleges?.Usercolleges?.length > 0 ?
  //         res?.data?.data?.allUsercolleges?.Usercolleges?.[0]?.isfav : 0
  //     setIsClgFav(fav)
  //   }else {
  //     setIsClgFav(0)
  //   }
  // }
const ShareQuote = () =>{
const text = `View my @SportsForce ${clgStoryObj?.sportName} recruiting profile
               ${websiteDefaultUrl}publicCommitmentStory/${clgID}/${userID}
             `
  return text
}
const ShareTitle = () =>{
    const title = `${!!clgStoryObj?.athleteFirstName ? getCamelCase(clgStoryObj?.athleteFirstName) : ""} ${!!clgStoryObj?.athleteLastName ? getCamelCase(clgStoryObj?.athleteLastName) : ""} 
    Announces Commitment to Play ${clgStoryObj?.sportsName ?? ""} at ${!!clgStoryObj?.collegename ? getCamelCase(clgStoryObj?.collegename) : ""}`
  return title
}

const getInitials = () => {
  let initial = "";
  if (clgStoryObj) {
   initial= `${!!clgStoryObj?.athleteFirstName ? clgStoryObj?.athleteFirstName?.split("")[0]?.toUpperCase() ?? "" : ""}${!!clgStoryObj?.athleteLastName ? clgStoryObj?.athleteLastName?.split("")[0]?.toUpperCase() ?? "" : ""}` ;
  }
  return initial;
};
const onClickProfile = () =>{
  if (!!getAuthToken()){
    profileStatus({
      type: "CHANGE_ATHLETE_PROFILE_VIEW_STATE", payload: {
        id: userID || 0,
        status: true
      }
    });
    localStorage.setItem("pubViewID", +userID);
  }else {
    console.log("no token")
  }
}

const handleCollegeClick = () => {
  if(!!getAuthToken()) {
    props?.history?.push(`/colleges/details/${clgID}/${clgStoryObj?.isfav}`);
    props.viewCollegeSaveStart({
      query : saveViewCollegeQuery,
      variables : {
        obj : {
          athleteid : getUserInfo() ? getUserInfo().id : null,
          collegeid : clgID,
          collegeviewstatus : 1,
        }
      }
    })
  }
}

  return (
    <>
      {/*<GetHelmet*/}
      {/*title={ShareTitle()}*/}
      {/*content={ShareQuote()}*/}
      {/*Ogurl={`${websiteDefaultUrl}publicCommitmentStory/${clgID}/${userID}`}*/}
      {/*/>*/}
      <Dialog
      open={sharePop}
      onClose={(event, reason)=> {
        if (reason == "backdropClick" || reason == "escapeKeyDown") {
          setSharePop(!sharePop)
        }
      }}
      className="matDialogCustom"
      >
        <SharePost
            pageType={"CollegeStory"}
            handleModal={()=>setSharePop(!sharePop)}
            userID={ userID }
            clgID = {clgID}
            modalData={ShareQuote()}
            pubID={ null }
        />
      </Dialog>
      <div className="pageContent">
        <div className="container">
          <div className="commitmentStory">
            <div className="row">
              <div className="col">
                <div className="success">
                  <p>
                    {!!clgStoryObj?.athleteFirstName ? getCamelCase(clgStoryObj?.athleteFirstName) : ""} {!!clgStoryObj?.athleteLastName ? getCamelCase(clgStoryObj?.athleteLastName) : ""}
                    {" "}Announces
                    Commitment to Play {!!clgStoryObj?.sportName ? getCamelCase(clgStoryObj?.sportName)+ " " : ""}
                    at {!!clgStoryObj?.collegename ? getCamelCase(clgStoryObj?.collegename) : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex commit">
              <div className="item">
                {!!clgStoryObj?.profileimages ? <div className="pic">
                  <img src={`${imageDownloadUrl}/${clgStoryObj?.profileimages}`} style={{borderRadius:"50px"}} alt="Profile Picture" />
                </div> : <div className="initialLetter" style={{fontWeight: 500,fontSize:'24px'}}>
                  <span>{getInitials()}</span>
                </div>
                }
                <p>
                  <strong className="sfbluelink"
                      style={!!getAuthToken() ? { cursor: 'pointer', color: 'rgb(0, 123, 255)', textDecoration:"none" } :
                          {cursor: "pointer", color: 'rgb(0, 123, 255)', textDecoration:"none"}}>
                    <Link to={`/athlete/${clgStoryObj?.athleteFirstName?.trim()?.toLowerCase()?.toString()?.split(" ").join("-")}-${clgStoryObj?.athleteLastName?.trim()?.toLowerCase()?.toString()?.split(" ").join("-")}-${clgStoryObj?.sysGenId ?? 0}`}
                            target="_blank"
                            onClick={()=>onClickProfile()}
                          style={!!getAuthToken() ? { cursor: "pointer", textDecoration:"none"} : {cursor: "pointer", textDecoration:"none"}}
                    >
                      {!!clgStoryObj?.athleteFirstName ? getCamelCase(clgStoryObj?.athleteFirstName) : ""} {!!clgStoryObj?.athleteLastName ? getCamelCase(clgStoryObj?.athleteLastName) : ""}
                      </Link>
                  </strong>
                  <small>
                    {!!clgStoryObj?.commitmentdate ?
                        getUTCTimeDateDash(clgStoryObj?.commitmentdate)
                        // moment(clgStoryObj?.commitmentdate).format('YYYY')
                        : ""}&nbsp;
                        Commit
                  </small>
                </p>
              </div>
              <div className="item">
                <div className="pic"
                     style={{cursor: "pointer"}}
                     onClick={() => handleCollegeClick()}
                >
                  {clgStoryObj.collegelogo ? (
                  <img
                    src={imageDownloadUrl + "/" + clgStoryObj?.collegelogo}
                    alt=""
                  />
                  ) : (
                    <img src={TeamLogo} alt="College Logo"/>
                  )}
                </div>
                <p>
                  <strong className="sfbluelink"
                      style={!!getAuthToken() ? {cursor: "pointer", color: "#007bff"} : {cursor: "text", color: "#007bff"}}
                      onClick={() => handleCollegeClick()}
                  >
                    {clgStoryObj && clgStoryObj?.collegename
                      ? getCamelCase(clgStoryObj.collegename)
                      : ""}
                  </strong>
                  <small>
                  {clgStoryObj ? clgStoryObj.conferencename : " "}{!!clgStoryObj.conferencename ? <br /> : ""}
                  {clgStoryObj
                        ? `${
                          clgStoryObj.city === null || clgStoryObj.city === undefined
                              ? " "
                              : getCamelCase(clgStoryObj.city)
                          }${clgStoryObj ? (
                            (clgStoryObj.city === null || clgStoryObj.city === undefined) && (clgStoryObj.initials === null || clgStoryObj.initials === undefined) ?
                            " " : ","
                            ) : " "}
                           ${
                            clgStoryObj.initials === null || clgStoryObj.initials === undefined ? " " : clgStoryObj.initials
                          }`
                        : " "}<br />
                  {clgStoryObj
                        ? `${
                          clgStoryObj.collegedivisionname === null || clgStoryObj.collegedivisionname === undefined ? " " : clgStoryObj.collegedivisionname
                          }`
                        : " "}            
                  </small>
                </p>
              </div>
            </div>
            <div className="flex share">
              <div className="item">
                <div className="title">
                  Share this update 
                  <Tooltip
                      title={"Share"}
                      placement={"top"}
                  >
                  <button className="followBtn"
                  style={{width: "35px",
                    height: "30px",
                    borderRadius: "4px",
                    padding: "10px",
                    backgroundColor: "#FDB944",
                    fontSize: "0.9375rem",
                    fontWeight: 600,
                    border: "none",
                    color: "#000000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  >
                  <span className="icon" onClick={() => setSharePop(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.995" viewBox="0 0 24 19.995">
                      <path id="Icon_ionic-ios-share-alt" data-name="Icon ionic-ios-share-alt" d="M28.289,15.148,19.472,6.9a.451.451,0,0,0-.35-.15c-.275.012-.625.206-.625.5v4.137a.267.267,0,0,1-.225.256c-8.767,1.343-12.46,7.9-13.766,14.716-.05.269.312.519.481.306,3.2-4.03,7.092-6.661,13.247-6.711a.309.309,0,0,1,.262.3v4.062a.536.536,0,0,0,.906.331l8.88-8.392a.69.69,0,0,0,.219-.525A.837.837,0,0,0,28.289,15.148Z" transform="translate(-4.502 -6.749)" fill="#fff" />
                    </svg>
                    {/*<svg*/}
                    {/*  xmlns="http://www.w3.org/2000/svg"*/}
                    {/*  width="14"*/}
                    {/*  height="15.458"*/}
                    {/*  viewBox="0 0 14 15.458"*/}
                    {/*>*/}
                    {/*  <defs>*/}
                    {/*    <style></style>*/}
                    {/*  </defs>*/}
                    {/*  <path*/}
                    {/*    className="a"*/}
                    {/*    d="M16.16,14.047a2.125,2.125,0,0,0-1.521.583L9.063,11.406a2.612,2.612,0,0,0,.078-.544,2.61,2.61,0,0,0-.078-.544l5.5-3.185a2.334,2.334,0,1,0-.741-1.709,2.6,2.6,0,0,0,.078.544L8.4,9.153a2.347,2.347,0,0,0-1.6-.621,2.309,2.309,0,0,0-2.3,2.33,2.348,2.348,0,0,0,3.939,1.709l5.538,3.224a1.948,1.948,0,0,0-.078.5,2.262,2.262,0,1,0,2.262-2.253Z"*/}
                    {/*    transform="translate(-4.5 -3.094)"*/}
                    {/*  />*/}
                    {/*</svg>*/}
                    {/* <span style={{ marginLeft: "8px" }}>Share</span> */}
                  </span>
                    <span></span>
                  </button>
                </Tooltip>
                </div>
              </div>
              <div className="item">
                <ul className="flex" style={{marginLeft:0}}>
                {/*<li>*/}
                    {/*<a>*/}
                      {/*<span>*/}
                      {/*  <FacebookShareButton*/}
                      {/*      quote={ShareQuote()}*/}
                      {/*      url={'http://sfrglobal.com/#/'}*/}
                      {/*  >*/}
                      {/*<img src={facebookDark} alt="Share us on Facebook" />*/}
                      {/*</FacebookShareButton>*/}
                      {/*</span>*/}
                    {/*</a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a*/}
                  {/*      // target={'_blank'}*/}
                  {/*      // href={`http://twitter.com/${athlete?.onBoardingUserIdMap?.Onboardings[0]?.twitteraccountname || null}`}*/}
                  {/*      //*/}
                    {/*>*/}
                      {/*<span>*/}
                      {/*  <TwitterShareButton*/}
                      {/*      quote={ShareQuote()}*/}
                      {/*      url={ShareQuote()}*/}
                      {/*  >*/}
                      {/*<img src={twitterDark} alt="Share us on Twitter" />*/}
                      {/*</TwitterShareButton>*/}
                      {/*</span>*/}
                    {/*</a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a >*/}
                  {/*    <img src={instagramDark} alt="Share us on Instagram" />*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                    {/*<a >*/}
                    {/*  <LinkedinShareButton*/}
                    {/*      quote={ShareQuote()}*/}
                    {/*      url={'http://sfrglobal.com/#/'}*/}
                    {/*  >*/}
                    {/*  <img src={linkedinDark} alt="Share us on LinkedIn" />*/}
                    {/*  </LinkedinShareButton>*/}
                    {/*</a>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </div>
            <div className="flex content">
              <p>
                {clgStoryObj && clgStoryObj?.city
                  ? getCamelCase(clgStoryObj?.city) + ","
                  : ""}
                {" "}
                {clgStoryObj && clgStoryObj?.state
                    ? getCamelCase(clgStoryObj?.state)
                    : ""}
                ,{" "}
                {!!clgStoryObj && clgStoryObj?.commitmentdate
                  ? `${getUTCTimeDateDash(
                        clgStoryObj?.commitmentdate
                    )}. `
                  : ""}
                {clgStoryObj && clgStoryObj?.athleteFirstName ? getCamelCase(clgStoryObj?.athleteFirstName) : ""}{" "}
                {clgStoryObj && clgStoryObj?.athleteLastName ? getCamelCase(clgStoryObj?.athleteLastName) : ""}{" "}
                {clgStoryObj?.heightValue !== null
                  ? `${clgStoryObj?.heightValue}'`
                  : ""}
                {clgStoryObj?.inchValue !== null ? (clgStoryObj?.inchValue == 0 ?
                  " 0''"
                    : ` ${clgStoryObj?.inchValue}''`) : ""
                }
                {" "}
                {!!clgStoryObj?.selectionfield ? clgStoryObj?.selectionfield  + ", "
                  : ""}
                 in the {!!clgStoryObj?.yearValue ? clgStoryObj?.yearValue ?? "" : ""} class has
                committed to play {getCamelCase(clgStoryObj?.sportName)} at{" "}
                <a
                    href={void 0}
                    style={!!getAuthToken() ? {
                      cursor: "pointer",
                      color: "#007bff",
                      fontWeight: "bolder"
                    }:
                        {
                          cursor: "text",
                          color: "#007bff",
                          fontWeight: "bolder"
                        }}
                    onClick={() => handleCollegeClick()}
                >
                  {clgStoryObj?.collegename
                    ? clgStoryObj?.collegename
                    : ""}
                  .
                </a>
              </p>
              {/*<p>*/}
              {/*  Since joining Sportsforce on{" "}*/}
              {/*  {!!clgStoryObj?.createdOn*/}
              {/*    ? moment(clgStoryObj?.createdOn).format("MMM DD, YYYY")*/}
              {/*    : ""}*/}
              {/*  , {!!clgStoryObj?.athleteFirstName ? getCamelCase(clgStoryObj?.athleteFirstName) : ""}{" "}*/}
              {/*  worked with his*/}
              {/*  coaches to target {!!clgStoryObj?.targetcollegecount ? clgStoryObj?.targetcollegecount ?? "" : ""}{" "}*/}
              {/*  different schools and received the following activity from*/}
              {/*  college coaches:*/}
              {/*</p>*/}
              <ul>
                {/*<li>*/}
                {/*  Search Appearances: <strong>{searchByCollegeCount || "N/A"}</strong>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  Profile Views: <strong>{totatViews?.profileviews ?? 0 }</strong>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  Video Views: <strong>{totatViews?.videocount ?? 0}</strong>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  Followers: <strong>{followersCount || "N/A"}</strong>*/}
                {/*</li>*/}
              </ul>
              {/*<p>*/}
              {/*  To read more commitment stories like {!!clgStoryObj?.athleteFirstName ? clgStoryObj?.athleteFirstName + "'s" : ""}{" "}, click here:{" "}*/}
              {/*  <a style={!!getAuthToken() ? {color: "#007bff", fontWeight: "bolder", textDecoration:"none"}:{*/}
              {/*    cursor: "text", color: "#007bff", fontWeight: "bolder"}} href={!!getAuthToken() ? "/#/commitment" : void 0}>Commitment Stories.</a>*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    commitmentStoryData: state.athlete.profile.commitmentStory,
    viewCollege : state.athlete.dashboard.colleges.viewCollege,
    videoViews : state.common.photoVideoViews,
    searchCollege : state.athlete.dashboard.colleges.searchCollege,
    followersList: state.athlete.dashboard.home.followersList,


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    commitmentStoryStart: (data) => dispatch(commitmentStoryStart(data)),
    viewCollegeListStart : (data) => dispatch(viewCollegeListStart(data)),
    photoVideoViewsListStart : (data) => dispatch(photoVideoViewsListStart(data)),
    getFollowersListStart: (data) => dispatch(getFollowersListStart(data)),
    searchCollegeCountListStart : (data) => dispatch(searchCollegeCountListStart(data)),
    viewCollegeSaveStart: (data) => dispatch(viewCollegeSaveStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommitmentStory);
