import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  GET_USERDATA_LIST_START: "GET_USERDATA_LIST_START",
  GET_USERDATA_LIST_SUCCESS: "GET_USERDATA_LIST_SUCCESS",
  GET_USERDATA_LIST_FAILED: "GET_USERDATA_LIST_FAILED"
};

// ============================================ Actions ========================================================
export const getUserdataListStart = data => ({
  type: actionType.GET_USERDATA_LIST_START,
  payload: data
});

export const getUserdataListSuccess = data => ({
  type: actionType.GET_USERDATA_LIST_SUCCESS,
  payload: data
});

export const getUserdataListFailed = data => ({
  type: actionType.GET_USERDATA_LIST_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function getUserdataListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_USERDATA_LIST_START:
      return {
        ...state,
        loading: true
      };

    case actionType.GET_USERDATA_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.GET_USERDATA_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* getUserDataListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        getUserdataListSuccess({data: response.data.data.allAdvisors.Advisors})
      );
    } else {
      messagePopup("", "User data Api failed", "error");
      yield put(getUserdataListFailed("User data Api failed"));
    }
  } catch (error) {
    messagePopup("", "User data Api failed", "error");
    yield put(getUserdataListFailed("User data Api failed"));
  }
}

export function* watchGetUserdataListSaga() {
  yield takeEvery(actionType.GET_USERDATA_LIST_START, getUserDataListSaga);
}
