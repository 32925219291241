import React, { useState, useEffect } from "react";
import ResourcesContainer from "../ResourcesContainer";
import PlayButton from "../Utils/PlayButton";
import SlideTab from "./SlideTab";
import VideoPopup from "../Utils/VideoPopup";
import {useDispatch, useSelector} from "react-redux";
import {
  getHowToVideoListQuery,
  getResourceHeaderQuery, getSubSection, saveViewVideo,
} from "./HowtoVideoQuery";
import "../../../../../../../assets/css/owl.carousel.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";
import image from "../../assets/img/video-bg-img.png";
import {getUserInfo} from "../../../../../../../services/authService";
import coacheImg1 from "../../../editUserProfile/assets/img/coache-img1.png";
import { saveBasicUserProfileUpdateQuery } from '../../../editUserProfile/BasicQuery'
import {imageDownloadUrl} from "../../../../../../../config";
import GoTopButton from "../../../editUserProfile/GoTopButton";
import {updateLS} from "../Utils/updateLS";
import {vidURL} from "../../../../../../common/thumbnail";
import {getUTCTimeDateDash} from '../../../../../../../utils/dateTimeFormatter'
import {fetchGraphMethod} from "../../../../../../../services/httpService";
import BackdropLoader from "../../../../../../common/Loader";
const options = {
  margin: 10,
  responsiveClass: true,
  nav: true,
  dots: false,
  responsive: {
    0: {
      items: 1,
      center: true,
    },
    415: {
      items: 1,
      center: true,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
};
export const HowToVideo = () => {
  const [showVideoPopup, setShowVideoPopup] = useState(false),
        [tabSel, setTabSel] = useState(1),
        [isSearch, setIsSearch] = useState(true),
        [searchValue, setSearchValue] = useState(null),
        [isVideo, setIsVideo] = useState(false),
        [offset, setOffset] = useState(3),
        [_perPage, setPerPage] = useState(3),
        [vidData, setVidData] = useState([]),
        [resSectionHeader, setResSectionHeader] = useState([]),
        [resHVData, setResHVData] = useState([]),
        [playTutorialVideo, setPlayTutorialVideo] = useState([]),
        [isLoading, setIsLoading] = useState(false),
        userLS = JSON.parse(localStorage.getItem('user')),
        howToVid = useSelector(state => state),
        dispatch = useDispatch()
  useEffect(() => {
    // dispatch({type:"GET_RESOURCE_HEADER_START", payload:{
    //     query: getResourceHeaderQuery,
    //     variables:{
    //       where:{
    //         resourcemainheaderid: 1
    //       }
    //     }
    //   }})
    // dispatch({type:"GET_HOW_TO_VIDEO_START", payload:{
    //     query: getHowToVideoListQuery,
    //     variables: {
    //       where: {
    //         isuploadedbyadmin: 1,
    //         resourceheaderid: 1,
    //       },
    //       // first:offset, last:perPage
    //     }
    // }})
    // if(userLS.isshowvideo == 0) setShowVideoPopup(true)
  }, []);
  useEffect(()=>{
    if(userLS.isshowvideo == 0){
      handleOpen()
    }
  },[playTutorialVideo])
  useEffect(()=>{
    // dispatch({type:"GET_HOW_TO_VIDEO_START", payload:{
    //     query: getHowToVideoListQuery,
    //     variables: {
    //       where: {
    //         isuploadedbyadmin: 1,
    //         resourceheaderid: 1,
    //       },
    //       // first:offset, last:perPage
    //     }
    //   }})
    // window.scroll(0, 0)
  },[howToVid?.athlete?.dashboard?.resources?.resourcesHeader?.data?.Resourceheaders])

  useEffect(()=>{
    getResourceHead()
    getResHeaderSubSecData()
    getPlaytutorial()
  },[])
  const getResourceHead = async () =>{
    const res = await fetchGraphMethod(getResourceHeaderQuery, {where:{ resourcemainheaderid: 1}},true)
    if (res?.status == 200){
      setResSectionHeader(res?.data?.data?.allResourceheaders?.Resourceheaders)
      }else{
      setResSectionHeader([])
    }
    }
  const getResHeaderSubSecData = async (secHead = 1) =>{
      //resourceheaderid this the carousel id
      //adminuploadedvideotypeid this is the section id
      setIsLoading(true)
      const resData = await fetchGraphMethod(getSubSection,{where:{resourceheaderid: secHead},first:3, last:3}, true)
      if (resData?.status == 200){
        const secData = resData?.data?.data?.allVideotypes?.Videotypes?.length > 0 ? resData?.data?.data?.allVideotypes?.Videotypes : []
       if (secData?.length > 0){
         const resSubSectionData = secData?.map(async (subSec, subIdx)=>{
           const resSubSec = await fetchGraphMethod(getHowToVideoListQuery, {where: {adminuploadedvideotypeid : subSec?.id,isuploadedbyadmin: 1, resourceheaderid: secHead}},true)
           if (resSubSec?.status == 200){
            const totalData =  dataSection(resSubSec?.data?.data?.allUploadedvideoandphotos)
             if (totalData?.length > 0){
               const cloneSate = JSON.parse(JSON.stringify(totalData))
               setResHVData(cloneSate)
               setIsLoading(false)
               setTimeout(()=>{
                 window.scrollTo(0,0)
               },500)
             }else{
               setResHVData([])
               setIsLoading(false)
               setTimeout(()=>{
                 window.scrollTo(0,0)
               },500)
             }
           }else {
             setIsLoading(false)
             setResHVData([])
             setTimeout(()=>{
               window.scrollTo(0,0)
             },500)
           }
         })
       }else {
         setIsLoading(false)
         setResHVData([])
         setTimeout(()=>{
           window.scrollTo(0,0)
         },500)
       }
      }else{
        setIsLoading(false)
        setResHVData([])
        setTimeout(()=>{
          window.scrollTo(0,0)
        },500)
      }
  }
  const getPlaytutorial = async () =>{
    const resData = await fetchGraphMethod(getHowToVideoListQuery, {where:{videophotosuploadedtype:"howtovideo",isplaytutorial:1}}, true)
    let videoData = ""
    if (resData?.status == 200){
      videoData = resData?.data?.data?.allUploadedvideoandphotos?.Uploadedvideoandphotos?.length > 0 ?
          resData?.data?.data?.allUploadedvideoandphotos?.Uploadedvideoandphotos?.[0] : []
          setPlayTutorialVideo(videoData)
    }else {
      setPlayTutorialVideo(videoData)
    }
  }
  const dataSection = (value) =>{
    const oriData = value.Uploadedvideoandphotos,
        oriArray = [],
        dataSec = []
    if(oriData.length > 0){
      oriData.map(el => oriArray.push(el?.uploadedvideoandphotoadminuploadedvideotypeidmaprel?.Videotypes[0].videotypename))
    }
    const arrayTitle = [...new Set(oriArray)]
    if(arrayTitle.length > 0){
      for (let i = 0; i<= arrayTitle.length -1; i++){
        const obj = {
          title : arrayTitle[i],
          data:[]
        }
        oriData.map((data, indx)=>{
          if(data?.uploadedvideoandphotoadminuploadedvideotypeidmaprel?.Videotypes[0].videotypename === obj.title){
            obj['data'].push(data)
          }
        })
        dataSec.push(obj)
      }
    }
    return dataSec
  }
  const handleClose = (e) => {
    e.preventDefault();
    dispatch({type:"SAVE_ATHLETE_VIEW_VIDEO_START",payload:{
      query:saveBasicUserProfileUpdateQuery,
        variables: {
          obj: {
            id: getUserInfo() ? getUserInfo().id : 0,
            isshowvideo: 1
          }}
    }})
    updateLS('isshowvideo', 1)
    setShowVideoPopup(false);
  }
  const handleOpen = () => {
    // setShowVideoPopup(true);
    isVideoPop(playTutorialVideo)
  };
  const tabApiCall = id =>{
    //   dispatch({type:"GET_HOW_TO_VIDEO_START", payload:{
    //       query: getHowToVideoListQuery,
    //       variables: {
    //         where: {
    //           isuploadedbyadmin: 1,
    //           resourceheaderid: +id,
    //         },
    //         // first: +3,
    //         // last: + 3
    //       }
    //     }})
    // setTimeout(()=>{
    //   dispatch({type:"GET_HOW_TO_VIDEO_START", payload:{
    //       query: getHowToVideoListQuery,
    //       variables: {
    //         where: {
    //           isuploadedbyadmin: 1,
    //           resourceheaderid: +id,
    //         },
    //         // first: +3,
    //         // last: + 3
    //       }
    //     }})
    // },300)
    // setTabSel(+id)
    // setPerPage(3)
    // setOffset(3)
    getResHeaderSubSecData(+id)
    setTabSel(+id)
  }
  const handleChange = async (e) =>{
    const value = !!e?.target?.value ? e?.target?.value : ""  || ""
    if(value.length > 3){
      setIsLoading(true)
      // dispatch({type:"GET_HOW_TO_VIDEO_START",
      //   payload:{ query :getHowToVideoListQuery,
      //     variables:{ where:{
      //         isuploadedbyadmin: 1,
      //         resourceheaderid: tabSel,
      //         and: [
      //           {
      //             or: [
      //               { originalname: { like: `%${value?.toLowerCase()}%` } },
      //               { videodescription: { like: `%${value?.toLowerCase()}%` }},
      //             ]
      //           }
      //         ]
      //       }}
      //   }})
      const resData = await fetchGraphMethod(getHowToVideoListQuery,
        { where:{
            isuploadedbyadmin: 1,
            resourceheaderid: tabSel,
            and: [
              {
                or: [
                  { originalname: { like: `%${value?.toLowerCase()}%` } },
                  { videodescription: { like: `%${value?.toLowerCase()}%` }},
                ]
              }
            ]
          }}, true)
      if (resData?.status == 200){
        const totalData =  dataSection(resData?.data?.data?.allUploadedvideoandphotos)
        if (totalData?.length > 0){
          const cloneSate = JSON.parse(JSON.stringify(totalData))
          setResHVData(cloneSate)
          setIsLoading(false)
          setTimeout(()=>{
            window.scrollTo(0,0)
          },500)
        }else{
          setResHVData([])
          setIsLoading(false)
          setTimeout(()=>{
            window.scrollTo(0,0)
          },500)
        }
      }
      setIsLoading(false)
      setIsSearch(false)
    }else{
      // dispatch({type:"GET_HOW_TO_VIDEO_START",
      //   payload:{query :getHowToVideoListQuery,
      //     variables: {
      //       where: {
      //         isuploadedbyadmin: 1,
      //         resourceheaderid: tabSel,
      //       }
      //     }
      //   }})
      getResHeaderSubSecData(tabSel)
      setIsSearch(true)
    }
    setSearchValue(value)
  },
  isVideoPop = (vid) => {
    if (!!vid?.videolink){
      vid['videolink'] = vidURL(vid?.videolink)
    }
    setVidData(vid)
    setIsVideo(true)
  }
  const vidPopClose = () =>{
    if(userLS.isshowvideo == 0) {
      dispatch({
        type: "SAVE_ATHLETE_VIEW_VIDEO_START", payload: {
          query: saveBasicUserProfileUpdateQuery,
          variables: {
            obj: {
              id: getUserInfo() ? getUserInfo().id : 0,
              isshowvideo: 1
            }
          }
        }
      })
      updateLS('isshowvideo', 1)
    }
    setIsVideo(!isVideo)
  }
  return (
      <>
        <BackdropLoader open={isLoading}/>
    <ResourcesContainer>
      <VideoPopup
        show={showVideoPopup}
        close={handleClose}
        videoId={vidData}
      />
      {isVideo && <VideoPopup isResource={true} vidData={vidData} show={isVideo} close={()=>vidPopClose()} />}
      <div className="tab-content resources-box-wrap">
        <div className="tab-pane active" id="HowtoVideo">
          <div className="search-box-wrap">
            <div className="item">
              <form className="search-box">
                <input
                    type="text"
                    style={{color:"black"}}
                    value={searchValue}
                    placeholder="Search By Title & Description"
                    onChange={(e)=>handleChange(e)}
                />
                {isSearch &&
                <button type="button">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.811"
                      height="13.811"
                      viewBox="0 0 13.811 13.811"
                  >
                    <g className="a" transform="translate(0.75 0.75)">
                      <path
                          className="b"
                          d="M15.167,9.833A5.333,5.333,0,1,1,9.833,4.5,5.333,5.333,0,0,1,15.167,9.833Z"
                          transform="translate(-4.5 -4.5)"
                      />
                      <path
                          className="b"
                          d="M27.875,27.875l-2.9-2.9"
                          transform="translate(-15.875 -15.875)"
                      />
                    </g>
                  </svg>
                </button>
                }
                {!isSearch &&
                <button type={'button'} onClick={()=>handleChange("")}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11.271" height="11.253" viewBox="0 0 11.271 11.253">
                    <path id="Mask"
                          d="M6.767,5.63l4.26,4.26a.8.8,0,0,1-.292,1.319.689.689,0,0,1-.547,0,.778.778,0,0,1-.292-.189l-4.26-4.26-4.26,4.26a.913.913,0,0,1-.254.189.744.744,0,0,1-.311.056A.744.744,0,0,1,.5,11.209a.914.914,0,0,1-.254-.189.775.775,0,0,1,0-1.131L4.5,5.63.245,1.37A.775.775,0,0,1,.245.24.809.809,0,0,1,.81.013.809.809,0,0,1,1.376.24l4.26,4.279L9.9.24a.82.82,0,0,1,1.131,0,.775.775,0,0,1,0,1.131Z"
                          transform="translate(0 -0.013)" fill="#a8b7c7"></path>
                  </svg>
                </button>
                }
              </form>

            </div>
            <div className="item">
              <PlayButton open={handleOpen} />
            </div>
          </div>
          <div className="tabBox slidetab">
            <ul className="nav nav-tabs tabs-slide owl-carousel">
              {resSectionHeader?.length ?
                  <SlideTab
                  resourcesHeaderData={resSectionHeader}
                  tabApiCall={tabApiCall}
                  tabSel={tabSel}
              /> : ""}
            </ul>
          </div>
          <div className="resources-inner-list">
            <div className="tab-content current">
              <div className="video-box-row">
            {resHVData.length > 0 ?
                resHVData.map((item, index)=>{
                  return(
                      <div className="video-box-item" style={{marginBottom:"20px"}}>
                        <h3 className="sec-title">
                          {item.title || "N/A"}
                          {/*&nbsp;*/}
                        </h3>
                        <ul className="video-grid-list resources-video-slide owl-carousel owl-loaded">
                          <OwlCarousel className="owl-theme" {...options}>
                            {item?.data.length > 0 ?
                                item.data.map((vid, idx)=>{
                                  return(
                                      <li className="video-box">
                                        <div
                                            className="img-box"
                                        >
                                          <video poster={!!vid?.thumbnail ? vid?.thumbnail : image}>
                                            <source
                                                src={`https://www.youtube.com/embed/`}
                                                type="video/mp4"
                                            />
                                            <source
                                                src={`https://www.youtube.com/embed/`}
                                                type="video/ogg"
                                            />
                                          </video>
                                          <div
                                              className="video-play-button"
                                              data-toggle="modal"
                                              data-target="#ResourceVideoModal"
                                          >
                                            <div className="video-play-inner">
                                              <button type={'button'} onClick={()=>isVideoPop(vid) ||
                                                  dispatch({type:"SAVE_VIEW_VIDEO_START",payload:{
                                                  query: saveViewVideo,
                                                  variables: {
                                                    obj:{
                                                      // id:1,
                                                      userid: userLS.id || 0,
                                                      viewstatus: 1,
                                                      videoid : vid?.id
                                                    }
                                                  }
                                                }})}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="10.802"
                                                    height="16.203"
                                                    viewBox="0 0 10.802 16.203"
                                                >
                                                  <path
                                                      className="a"
                                                      d="M8.1,0l8.1,10.8H0Z"
                                                      transform="translate(10.802) rotate(90)"
                                                  />
                                                </svg>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="video-detail-box">
                                          <h3 className="title">{vid?.originalname || "N/A"}</h3>
                                          <p>{vid?.videodescription || ""}</p>
                                          <ul className="video-info">
                                            <li>{vid?.viewvideoVideoidMap?.totalCount || 0} views</li>
                                            <li>{getUTCTimeDateDash(vid.createdon)}</li>
                                          </ul>
                                                  <div className="video-text">
                                                    {vid?.viewvideoVideoidMap?.Viewvideos?.length > 0 ?
                                                    <>
                                                    {vid?.viewvideoVideoidMap?.Viewvideos?.slice(0,2)?.map((ath, idx)=>{
                                                     return(
                                                         <div className="video-info-img" style={idx == 0 ? {width:"auto", marginRight:0} : {width:"auto",zIndex: "1", marginLeft: "-6px"}}>
                                                           <img src={!!ath?.viewvideouseridmaprel?.Athletes?.[0]?.onBoardingUserIdMap?.Onboardings[0]?.profileimages ? `${imageDownloadUrl}/${ath?.viewvideouseridmaprel?.Athletes?.[0]?.onBoardingUserIdMap?.Onboardings[0]?.profileimages}` : coacheImg1} alt="" />
                                                         </div>
                                                     )})}
                                                      {vid?.viewvideoVideoidMap?.Viewvideos?.slice(0,2)?.map((ath, idx)=>{
                                                      return(
                                                          <span style={vid?.viewvideoVideoidMap?.Viewvideos?.length == 1 ? {marginLeft:"5px"}: idx == 1 ?  {marginLeft:"5px"} : {}}>{ath?.viewvideouseridmaprel?.Athletes?.[0]?.athletefirstname || ""}{`${(vid?.viewvideoVideoidMap?.Viewvideos?.length > 1 && idx == 0) ? `,` : ""}`}
                                                            {(idx == 1 && vid?.viewvideoVideoidMap?.Viewvideos?.length > 2) ? ` and ${(parseInt(vid?.viewvideoVideoidMap?.totalCount)-2)} more` : ""}
                                                          </span>
                                                      )
                                                      })}
                                                    </>
                                                   :""
                                                     ||
                                                        <>
                                                          {/*<div className="video-info-img">*/}
                                                          {/*  <img src={coacheImg1} alt="" />*/}
                                                          {/*  <span className="video-img">*/}
                                                          {/*    <img src={coacheImg1} alt="" />*/}
                                                          {/* </span>*/}
                                                          {/*</div>*/}
                                                          {/*<p>viewedBy</p>*/}
                                                        </>
                                               }
                                                  </div>
                                        </div>
                                      </li>
                                  )
                                })
                                :
                                <h3>No record found</h3>}
                          </OwlCarousel>
                        </ul>
                      </div>
                  )
                })
                :<h3>No record found</h3>
            }
              </div>
              {/*<div className="resources-loadmore-box">*/}
              {/*  {howToVid?.athlete?.dashboard?.resources.howToVideoList?.data?.howToVid?.totalCount > 3 &&*/}
              {/*  perPage < howToVid?.athlete?.dashboard?.resources.howToVideoList?.data?.howToVid?.totalCount &&*/}
              {/*  <button onClick={()=>loadMore()} className="load-more-button">Load more</button>}*/}
              {/*</div>*/}
            </div>
            {/*<div className="tab-content" id="VideoExamples">*/}
            {/*  <div className="video-box-row">*/}
            {/*    <div className="video-box-item">*/}
            {/*      <h3>Videos Examples</h3>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="tab-content" id="EditProfile">*/}
            {/*  <div className="video-box-row">*/}
            {/*    <div className="video-box-item">*/}
            {/*      <h3>Edit Profile</h3>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="tab-content" id="MyColleges">*/}
            {/*  <div className="video-box-row">*/}
            {/*    <div className="video-box-item">*/}
            {/*      <h3>My Colleges</h3>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="tab-content" id="VideosandPhotos">*/}
            {/*  <div className="video-box-row">*/}
            {/*    <div className="video-box-item">*/}
            {/*      <h3>Videos & Photos</h3>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </ResourcesContainer>
        <GoTopButton goTop={()=>{window.scrollTo(0,0)}} />
    </>
  );
}