import React, { useEffect, useState} from 'react'
import ClaimProfileHeader from "../auth/claimProfile/claimProfileStep1/ClaimProfileHeader";
import DashboardHeader from "../../layouts/header/DashboardHeader";
import {getAuthToken} from "../../../services/authService";
import logo from './assets/img/logo.png';
import recruitImg from './assets/img/recruiting_img.png'
import './assets/css/lading-page.scss'
import {fetchMethod} from "../../../services/httpService";

const Advertisement = (props) =>{
    const [headerType, setHeaderType] = useState(true),
          [apiData, setApiData] = useState({}),
        isToken = props?.match?.params?.isToken == 1 ? true : false;
    useEffect(()=>{
        getStatus(props)
        //for scrolling the page on top
        setTimeout(()=>{
            window.scrollTo(0,0)
        },1000)
        //api all for ata
        recApiCall()
    },[])
    const getStatus = (props) =>{
        if (!!getAuthToken() || isToken){
            setHeaderType(false)
        }
    }
    const recApiCall = async () =>{
        const res = await fetchMethod('/api/landingpages/getLPHowToRecruted',null,'GET',false)
        if(res?.status == 200 && res?.data?.response?.status == 200){
            const data = res?.data?.response?.data ?? {}
            setApiData(data)
        }else{
            setApiData({})
        }
    }
    return(
        <>
            {
                headerType ?
                    <ClaimProfileHeader/>
                    :
                    <DashboardHeader/>
            }
            <section className="ads-recruiting-sec">
                <div className="block-info-box">
                    <div className="info-data">
                        <div className="brand-logo">
                            <a href={void 0}>
                                <img src={logo} alt="SportsForce"/>
                            </a>
                        </div>
                        <h1 className="sec-title">The Anti-Recruiting Service</h1>
                        <div className="service-item">
                            <div className="item">
                                <h3 className="title">Make a <br/>Dream.</h3>
                                <div className="short-text">
                                    <p>
                                        {apiData?.howtorecruited?.[0]?.content ?? ""}
                                        {/*Since 2008, SportsForce has been empowering student-athletes to pursue their*/}
                                        {/*dreams. We have done this by providing best in class technology to*/}
                                        {/*student-athletes, parents and clubs. Like you, we hate recruiting services*/}
                                        {/*making outlandish promises to parents that cannot be delivered on.*/}
                                    </p>
                                    {/*<p>Sign your club up free and give your student-athletes access to best in class*/}
                                    {/*    technology for the college search and recruiting process.</p>*/}
                                </div>
                                <div className="profile-bio-box">
                                    <div className="pic-box">
                                        <img src={recruitImg} alt=""/>
                                    </div>
                                    <div className="info-box">
                                        <h4>Andrew Beinbrink</h4>
                                        <p>CEO & Founder</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-block-box">
                    <div className="inner-box-wrap">
                        <h2 className="title">Sign up for free club.</h2>
                        <p>Sign your club up free and give your student-athletes access to best in class technology for
                            the college search and recruiting process.</p>
                        <form className="form-box">
                            <div className="field-item">
                                <label>First name <sup>*</sup></label>
                                <input type="text" placeholder="Enter your first name"/>
                            </div>
                            <div className="field-item">
                                <label>Last name <sup>*</sup></label>
                                <input type="text" placeholder="Enter your last name"/>
                            </div>
                            <div className="field-item">
                                <label>Email <sup>*</sup></label>
                                <input type="text" placeholder="Enter your email"/>
                            </div>
                            <div className="field-item">
                                <label>Mobile Phone</label>
                                <input type="text" placeholder="Enter your phone number"/>
                            </div>
                            <div className="field-item">
                                <label>Club name <sup>*</sup></label>
                                <input type="text" placeholder="Enter your club name"/>
                            </div>
                            <div className="field-item">
                                <button className="btn">Create Free Profile</button>
                            </div>
                        </form>
                        <div className="bottom-text">
                            <p><a href={void 0}>Privacy Policy</a><a href={void 0}>Terms of Use</a></p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default Advertisement
