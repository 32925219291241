import React from 'react';
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  LoadScript,
} from '@react-google-maps/api';

const mapContainerStyle = {
  height: '50vh',
};

const options = {
  imagePath:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
};

const MapWithAMarkerClusterer = (props) => {
  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={12}
      center={{
        lat: props.markers[0] ? props.markers[0].latitude : 41.850033,
        lng: props.markers[0] ? props.markers[0].longitude : -87.6500523,
      }}
    >
      <MarkerClusterer options={options}>
        {(clusterer) =>
          props.markers.map((marker, index) => (
            <Marker
              key={index}
              position={{ lat: marker.latitude, lng: marker.longitude }}
              clusterer={clusterer}
            />
          ))
        }
      </MarkerClusterer>
    </GoogleMap>
  );
};

export default function Map(props) {
  const [markers, setMarkers] = React.useState([]);
  React.useEffect(() => {
    if (props.location && props.location.search) {
      const query = new URLSearchParams(props.location.search);
      const latitude = query.get('lat');
      const longitude = query.get('long');
      setMarkers([{ latitude: +latitude, longitude: +longitude }]);
    }
    if (props.markers) {
      setMarkers(props.markers);
    }
  }, []);
  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_apiKey}>
      <MapWithAMarkerClusterer markers={markers} />
    </LoadScript>
  );
}
