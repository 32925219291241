import React, { useEffect } from "react";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardCollegeRecruit.scss";
import "../assets/css/onBoardSteeper.scss";
import { Link } from "react-router-dom";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { connect } from "react-redux";
import BackdropLoader from "../../../../common/Loader";
import { getOnboardingQuery, saveOnboardingQuery } from "../OnboardingQuery";
import { getUserInfo } from "../../../../../services/authService";
import usePrevious from "../../../../../utils/usePrevious";
import Stepper from "../Stepper";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import { Dialog } from "@mui/material";
import messagePopup from "../../../../../utils/messagePopup";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "passed", link: "/onboarding/positions" },
  { className: "passed", link: "/onboarding/physicalMeasurements" },
  { className: "passed", link: "/onboarding/personalRecords" },
  { className: "passed", link: "/onboarding/firstYearVarsity" },
  { className: "passed", link: "/onboarding/schoolInfo" },
  { className: "passed", link: "/onboarding/academics" },
  { className: "passed", link: "/onboarding/recruiting" },
  { className: "active", link: "/onboarding/recruitingprocess" },
  { className: "" },
  { className: "" },
  // { className: "" },
  // { className: "" },
];

const checkboxData = [
  "Not knowing exactly what to do",
  "What colleges to target",
  "Choosing the right camps / showcases",
  "Maximize recruiting exposure",
];
const RecruitingProcess = (props) => {
  const [fields, setFields] = React.useState({
    selectedArr: [],
  });
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    props.history.push("/onboarding/instagram");
  };
  useEffect(() => {
    setFields({ selectedArr: [] });
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = fields;
        const { id, needhelpforcollegerecruitingprocess } =
          props.onboarding.data;
        fieldT["id"] = id;
        fieldT["selectedArr"] = needhelpforcollegerecruitingprocess
          ? needhelpforcollegerecruitingprocess.split(",")
          : fieldT["selectedArr"];
        fieldT["selectAll"] =
          fieldT["selectedArr"].length === checkboxData.length ? true : false;
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if(fields?.selectedArr?.length > 0){
    props.saveOnboardingStart({
      query: saveOnboardingQuery,
      variables: {
        obj: {
          id: fields.id ? fields.id : 0,
          userid: getUserInfo().id,
          needhelpforcollegerecruitingprocess: fields["selectedArr"]
            ? fields["selectedArr"].join(",")
            : "",
        },
      },
      nextScreen: routeNextPage,
    });
  }
  else{
    messagePopup("", "Please select college recruiting process", "error");
  }
  };

  const handleCheckbox = (e, data) => {
    let fieldsT = fields;
    if (e.target.checked) {
      fieldsT["selectedArr"] = [...fieldsT["selectedArr"], data];
    } else {
      fieldsT["selectedArr"] = fieldsT["selectedArr"].filter(
        (item) => item !== data
      );
    }
    fieldsT["selectAll"] =
      fieldsT["selectedArr"].length === checkboxData.length ? true : false;
    setFields({ ...fieldsT });
  };

  const selectAll = (e) => {
    let fieldsT = fields;
    if (e.target.checked) {
      fieldsT["selectedArr"] = checkboxData;
      fieldsT["selectAll"] = true;
    } else {
      fieldsT["selectedArr"] = [];
      fieldsT["selectAll"] = false;
    }
    setFields({ ...fieldsT });
  };

  return (
    <div>
      <BackdropLoader open={props.onboarding.loading} />
      <div className="onBoardCollegeRecruitSection">
        <div className="collegeRecruitBlock">
          <div className="stepper">
            <Stepper
              stepperName="recruitingProcess"
              stepperData={stepperData}
            />
          </div>
          <div className="formSection">
            <h1 className="heading">
              Where do you feel you need help in the college recruiting process?{" "}
            </h1>
            <div className="recruitProcessBox">
              <ul>
                {checkboxData.map((item, index) => {
                  return (
                    <li key={index}>
                      <span>
                        <input
                          type="checkbox"
                          onChange={(e) => handleCheckbox(e, item)}
                          checked={
                            fields.selectedArr.includes(item) ? true : false
                          }
                        />
                        <label>{item}</label>
                      </span>
                    </li>
                  );
                })}

                <li>
                  <span>
                    <input
                      type="checkbox"
                      onChange={(e) => selectAll(e)}
                      checked={fields.selectAll || false}
                      className="checkBlock"
                    />
                    <label>All The Above</label>
                  </span>
                </li>
              </ul>
            </div>
            <p className="skip">
              Don't know?{" "}
              <span className="sikpBtnBox">
                <Link className="skipit-btn" to="#" onClick={handleClickOpen}>
                  Skip it
                </Link>
              </span>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="/onboarding/instagram" onClick={(e) => handleSubmit(e)}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            handleClose()
          }
        }}
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    onboarding: state.athlete.onBoarding.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruitingProcess);
