import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../services/httpService";
import { imageDownloadUrl } from "../../../../config";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_FOLLOWING_LIST_START: "GET_FOLLOWING_LIST_START",
  GET_FOLLOWING_LIST_SUCCESS: "GET_FOLLOWING_LIST_SUCCESS",
  GET_FOLLOWING_LIST_FAILURE: "GET_FOLLOWING_LIST_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const getFollowingListStart = (data) => ({
  type: actionType.GET_FOLLOWING_LIST_START,
  payload: data,
});

export const getFollowingListSuccess = (data) => ({
  type: actionType.GET_FOLLOWING_LIST_SUCCESS,
  payload: data,
});

export const getFollowingListFailure = (data) => ({
  type: actionType.GET_FOLLOWING_LIST_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function followingListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_FOLLOWING_LIST_START:
      return { ...state, loading: true };

    case actionType.GET_FOLLOWING_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.GET_FOLLOWING_LIST_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* getFollowingListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200) {
      const allFollowers = response.data.data.allFollowers.Followers
        ? response.data.data.allFollowers.Followers
        : null;
      let finalData = [];
      if (allFollowers) {
        for (const follower of allFollowers) {
          let TempData = follower;
          let userInfo = null;
          if (
            TempData.followersfollowingidmaprel &&
            TempData.followersfollowingidmaprel.Athletes
          ) {
            userInfo = TempData.followersfollowingidmaprel.Athletes
              ? TempData.followersfollowingidmaprel.Athletes[0]
              : {};

            const firstName = userInfo.athletefirstname;

            const lastName = userInfo.athletelastname;

            const sportsName = userInfo
              ? userInfo.athleteathletesportsmaprel
                ? userInfo.athleteathletesportsmaprel.Athletesports
                  ? userInfo.athleteathletesportsmaprel.Athletesports[0]
                      .sportname
                  : ""
                : ""
              : "";
            const graduationYear = userInfo
              ? userInfo.athleteathletegraduationyearmaprel
                ? userInfo.athleteathletegraduationyearmaprel.Graduationyears
                  ? userInfo.athleteathletegraduationyearmaprel
                      .Graduationyears[0].yearvalue
                  : ""
                : ""
              : "";
            let onboardingData = null;

            if (
              userInfo.onBoardingUserIdMap &&
              userInfo.onBoardingUserIdMap.Onboardings &&
              userInfo.onBoardingUserIdMap.Onboardings[0]
            ) {
              onboardingData = userInfo.onBoardingUserIdMap.Onboardings[0]
                ? userInfo.onBoardingUserIdMap.Onboardings[0]
                : {};

              const primaryPosition =
                onboardingData.onboardingprimarypositionmaprel
                  ? onboardingData.onboardingprimarypositionmaprel
                      .Primarypositions
                    ? onboardingData.onboardingprimarypositionmaprel
                        .Primarypositions[0]
                      ? onboardingData.onboardingprimarypositionmaprel
                          .Primarypositions[0].selectionfield
                      : ""
                    : ""
                  : "";
              const secondaryPosition =
                onboardingData.onboardingsecondarypositionmaprel
                  ? onboardingData.onboardingsecondarypositionmaprel
                      .Secondarypositions
                    ? onboardingData.onboardingsecondarypositionmaprel
                        .Secondarypositions[0]
                      ? onboardingData.onboardingsecondarypositionmaprel
                          .Secondarypositions[0].selectionfield
                      : ""
                    : ""
                  : "";
              if (onboardingData.onboardingsecondarypositionmaprel) {
                delete onboardingData.onboardingsecondarypositionmaprel;
              }
              if (onboardingData.onboardingprimarypositionmaprel) {
                delete onboardingData.onboardingprimarypositionmaprel;
              }
              onboardingData["primaryPosition"] = primaryPosition;
              onboardingData["secondaryPosition"] = secondaryPosition;
              if (onboardingData.profileimages) {
                onboardingData.profileimages = `${imageDownloadUrl}/${onboardingData.profileimages}`;
              }
            }
            if (userInfo.userdataathletegraduationyearmaprel) {
              delete userInfo.userdataathletegraduationyearmaprel;
            }
            if (userInfo.userdataathletesportsmaprel) {
              delete userInfo.userdataathletesportsmaprel;
            }
            if (userInfo.onBoardingUserIdMap) {
              delete userInfo.onBoardingUserIdMap;
            }

            userInfo["sportsName"] = sportsName;
            userInfo["graduationYear"] = graduationYear;
            userInfo["onboardingData"] = onboardingData;
            userInfo["firstName"] = firstName;
            userInfo["lastName"] = lastName;
            TempData["userInfo"] = userInfo;
            TempData["isFollowers"] = false;
          }
          if (TempData.followersfollowingidmaprel) {
            delete TempData.followersfollowingidmaprel;
          }
          finalData = [{ ...TempData }, ...finalData];
        }
      }
      yield put(getFollowingListSuccess(finalData.length ? finalData : []));
    } else {
      messagePopup("", "Fetch following list Failed", "error");
      yield put(getFollowingListFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch following list Failed", "error");
    yield put(getFollowingListFailure("Failed, Error occured"));
  }
}

export function* watchFollowingList() {
  yield takeEvery(actionType.GET_FOLLOWING_LIST_START, getFollowingListSaga);
}
