import { combineReducers } from "redux";
import addMyCollegeReducer from "./addMyColleges";
import followersListReducer from "./followersList";
import followingListReducer from "./followingsList";
import followUnfollowReducer from "./followUnfollow";
import globalFeedListReducer from "./globalFeeds";
import postLikeUnlikeReducer from "./postLikeUnlike";
import postReducer from "./posts";
import savePostReducer from "./savePost";
import suggestedCollegesReducer from "./suggestedColleges";
import trendingProfileListReducer from "./trendingProfileList";
import askAQuestionReducer from "./askAQuestion";
import saveProfilePhotoReducer from "./saveAthleteProfile";
import parentAthleteListReducer from "./parentAllAthlete";
import switchAthleteReducer from "./switchAthlete";
import getAthleteProfileStatusReducer from "./athleteProfileViewState";
export default combineReducers({
  posts: postReducer,
  savePost: savePostReducer,
  suggestedColleges: suggestedCollegesReducer,
  addMyCollege: addMyCollegeReducer,
  followersList: followersListReducer,
  followingsList: followingListReducer,
  followUnfollow: followUnfollowReducer,
  trendingProfileList: trendingProfileListReducer,
  globalFeeds: globalFeedListReducer,
  postLikeUnlike: postLikeUnlikeReducer,
  askAQuestion : askAQuestionReducer,
  saveProfilePhoto: saveProfilePhotoReducer,
  parentAthleteList: parentAthleteListReducer,
  switchAthlete: switchAthleteReducer,
  athleteProfileStatus: getAthleteProfileStatusReducer
});
