export const getHowToVideoListQuery = `
query allUploadedvideoandphotos($where : JSON, $first: Int, $last:Int){
    allUploadedvideoandphotos(where: $where, first: $first , last: $last)
    {
        totalCount
      Uploadedvideoandphotos
      {
        adminuploadedvideotypeid
        resourceheaderid
        id
        userid
        videolink
        videoprice
        ispremium
        thumbnail
        isfeatured
        videoname
        videodescription
        originalname
        createdon
            membershipvideoandphotoplanidMap(where: $where){
              totalCount
               Memberships{
                     id
                     userid
                     videoandphotoplanid
                }
        }
        viewvideoVideoidMap{ 
        totalCount
        Viewvideos{
          viewstatus
           viewvideouseridmaprel{
           totalCount
            Athletes{
            athletefirstname
              onBoardingUserIdMap{
                Onboardings{
                  profileimages
                }
              }
            }
          }
        }
        }
         uploadedvideoandphotoadminuploadedvideotypeidmaprel{
          Videotypes{
            videotypename
          }
        }
      }
    }
  }
`;

export const getResourceHeaderQuery = `query allResourceheaders($where : JSON, $first: Int, $last:Int){
    allResourceheaders(where: $where, first: $first , last: $last)
    {
      Resourceheaders
    {
      id
      resourcemainheaderid
      headername
      uploadedvideoandphotoResourceheaderidMap{
        Uploadedvideoandphotos{
          resourceheaderid
          adminuploadedvideotypeid
          videodescription
           adminuploadedvideotypeid
          id
          thumbnail
          videolink
          videoname
          createdon
           uploadedvideoandphotoadminuploadedvideotypeidmaprel
          {
            Videotypes
            {
              videotypename
              id
            }
          }
        }
      }
    }
    }
  }
  `;
export const saveViewVideo =`
mutation saveViewvideo($obj:ViewvideoInput!){
  saveViewvideo(obj: $obj){
    id
  }
}
`
export const getSubSection = `query allvideotypes($where:JSON,$first:Int,$last:Int){
  allVideotypes(where:$where,first:$first,last:$last){
    Videotypes{
      id
      videotypename
    }
  }
}`