import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { alphaNumericValidation, IsValidEmail } from "../../../../utils/validationUtils";
import "./assets/css/forgotPassword.scss";
import { matchChangePasswordStart } from "../../../../redux/auth/forgotpassword/ChangePassword";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import BackdropLoader from "../../../common/Loader";
import swal from "sweetalert";
import { getUserInfo, setAuthToken, setUserInfo } from "../../../../services/authService";
import toastr from "toastr";
import { put } from "redux-saga/effects";
import history from "../../../../services/history";
import { loginSuccess } from "../../../../redux/auth/login/login";

const warningDeleteParentPopup = async (title, icon) => {
  const willDelete = await swal({
    // title: title,
    text: title,
    icon: icon,
    display: "inline-table",
    buttons: {
      confirm: { text: "Ok" }
    },
    dangerMode: true,
  });
  return willDelete;
};

const ChangePassword = (props) => {
  const emailData = window.location.href.split("/");
  const [changePasswordData, setChangePasswordData] = useState({
    emailId: emailData[emailData.length - 1],
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    emailId: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, [])

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const handleshowConfirmPasswordClick = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const inputHandler = (field, e) => {
    setErrors({});
    let fieldsT = changePasswordData;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setChangePasswordData({ ...fieldsT });
  };

  const formvalidation = () => {
    let errorsT = errors;
    let formIsValid = true;
    let regularExp = new RegExp(
      /^(?=.*\d)(?=.*[a-zA-Z])/
    );
    let blankSpaceValidation = stringVal => {
      return /\s/g.test(stringVal);
    };
    if (changePasswordData.emailId == "") {
      formIsValid = false;
      errorsT["emailId"] = "Please enter emailId.";
      if (changePasswordData.emailId != "") {
        if (!IsValidEmail(changePasswordData.emailId)) {
          formIsValid = false;
          errorsT["emailId"] = "Please correct format of emailId.";
        }
      }
    }
    if (changePasswordData.newPassword == "") {
      formIsValid = false;
      errorsT["newPassword"] = "Please enter new password.";
      errorsT["confirmPassword"] = "";
    }
    else if (changePasswordData.newPassword.length < 6) {
      formIsValid = false;
      errorsT["newPassword"] = "Password must be at least 6 characters.";
    }
    else if (changePasswordData.newPassword.length > 32) {
      formIsValid = false;
      errorsT["newPassword"] = "Maximum length should be 32 characters.";
    }
    else if (regularExp.test(changePasswordData.newPassword) == false && changePasswordData.newPassword !== "") {
      formIsValid = false;
      errorsT["newPassword"] =
        "Password must contain 1 alphabetical letter and 1 numeral.";
    }
    if (changePasswordData.newPassword === changePasswordData.emailId) {
      formIsValid = false;
      errorsT["newPassword"] = "Email id can’t be set as your password.";
    }
    if (blankSpaceValidation(changePasswordData.newPassword)) {
      formIsValid = false;
      errorsT["newPassword"] = "Blank spaces are not allowed"
    }
    else if (changePasswordData.confirmPassword == "") {
      formIsValid = false;
      errorsT["confirmPassword"] = "Please enter confirm password.";
    }
    else if (
      changePasswordData.newPassword.localeCompare(
        changePasswordData.confirmPassword
      ) != 0 && changePasswordData.newPassword !== ""
    ) {
      formIsValid = false;
      errorsT["confirmPassword"] =
        "New password and confirm password should be same.";
    }

    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
    };
  };
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    const validateData = await formvalidation();
    if (validateData.formIsValid == true) {

      props.matchChangePasswordStart({
        variables: {
          emailId: changePasswordData.emailId,
          newPassword: changePasswordData.newPassword,
        },
        method: "POST",
        nextScreen: () => {
          if (getUserInfo()?.role === "ATHLETES") {
            const willAddParent = warningDeleteParentPopup(
              "Password changed successfully",
              "success"
            );
            willAddParent.then((decisionValue) => {
              if (decisionValue === true) {
                if (getUserInfo()?.isonboardingdone){
                  history?.push('/homepage')
                  // window.location.href = "/homepage";
                } else{
                  history?.push('/onboarding/profile')
                  // window.location.href = "/onboarding/profile";
                }
              }
            });
          }
          else if (getUserInfo()?.role === "GUARDIAN") {//this code is for redirecting the gaurding to the dashbaord
            const willAddParent = warningDeleteParentPopup(
              "Password changed successfully",
              "success"
            );
            willAddParent.then((decisionValue) => {
              if (decisionValue === true) {
                history?.push('/homepage')
                // window.location.href = "/homepage";
              }
            });
          }
          else {
            const willAddParent = warningDeleteParentPopup(
              "Password changed successfully.Please login with your new password",
              "success"
            );
            willAddParent.then((decisionValue) => {
              if (decisionValue === true) {
                if (localStorage.getItem("user") &&
                  !JSON.parse(localStorage.getItem("user")).isdoneonboardingprocess) {
                  history?.push('/coach-onboarding/Profile')
                  // window.location.href = '/coach-onboarding/Profile'
                }
                else if (localStorage.getItem("user") &&
                  JSON.parse(localStorage.getItem("user").isdoneonboardingprocess)) {
                  history?.push('/coach-homepage')
                  // window.location.href = "/coach-homepage"
                }
                else {
                  history?.push('/coach-login')
                  // window.location.href = '/coach-login';
                  // window.location.href = '/#/login';earlier it was there
                }
              }
            });
          }
        }
      });
    } else {
    }
  };
  return (
    <section className="forgot-pass-sec">
      <BackdropLoader open={props.changePassword.loading} />
      <div className="container">
        <div className="forgot-box">
          <div className="detail-text-box">
            <div className="logo-box">
              <img src="img/logoV.png" alt="" />
            </div>
            <h1>Change Your Password</h1>
          </div>
          <form className="form-box">
            <div className="form-group">
              <input
                type="email"
                placeholder="Registered email address"
                onChange={(e) => {
                  inputHandler("emailId", e);
                }}
                value={changePasswordData.emailId}
                disabled={true}
              />
              {errors.emailId != "" ? (
                <div className="error">{errors.emailId}</div>
              ) : null}
            </div>

            <div className="form-group">
              <TextField
                variant="outlined"
                id="password"
                placeholder="New Password*"
                type={showPassword ? "text" : "password"}
                className="text-field"
                fullWidth
                name="password"
                value={changePasswordData.newPassword}
                onChange={(e) => {
                  inputHandler("newPassword", e);
                }}
                onBlur={(e) => {
                  inputHandler("newPassword", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleShowPasswordClick()}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
              {errors.newPassword != "" ? (
                <div className="error">{errors.newPassword}</div>
              ) : null}
            </div>
            <div className="form-group">
              <TextField
                variant="outlined"
                id="password"
                placeholder="Confirm Password*"
                type={showConfirmPassword ? "text" : "password"}
                className="text-field"
                fullWidth
                name="password"
                value={changePasswordData.confirmPassword}
                onChange={(e) => {
                  inputHandler("confirmPassword", e);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleshowConfirmPasswordClick()}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large">
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
              {errors.confirmPassword != "" ? (
                <div className="error">{errors.confirmPassword}</div>
              ) : null}
            </div>

            <div className="form-group">
              <button className="btn" type="submit" onClick={onSubmitHandler}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    changePassword: state.auth.changePassword,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    matchChangePasswordStart: (data) =>
      dispatch(matchChangePasswordStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
