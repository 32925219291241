import React, {useState, useEffect} from 'react';
import { getUserInfo } from '../../../../../services/authService';
import { fetchGraphMethod, fetchMethod } from '../../../../../services/httpService';
import BackdropLoader from '../../../../common/Loader';
import { saveCoachPostData } from './MyRecruitsQuery';

function PermissionModal(props) {
 const [decription, setDescription] = useState("");
 const [radio, setRadio] = useState();
 const [postReviewModal, setPostReviewModal] = useState(false);
 const [loading, setLoading] = useState(false);

 const handlePostReview = () => {
     setPostReviewModal(true);
 }
 const closePostReviewModal = () => {
    setPostReviewModal(false);
 }

 const handleRadioChange = (e, field) => {
    setRadio(e.target.value);
 }
 const handleClickOkay = async () => {
    setLoading(true);
     const variables = {
         obj : {
            coachid : getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
            ispublic : radio == "allAthelete" ? 1 : 0,
            postdescription : decription ? decription : ""
        }
     }
     const res = await fetchGraphMethod(
        saveCoachPostData,
        variables,
        true
     )
     setLoading(false);
     const data = {
        id : props?.positionIds ,
        ispost: 0
    }
     const updateStatus = await fetchMethod(
         "/api/recruitingneeds/updateRecruitingneedsByid",
         data,
         "post",
         true
     )
     props.getAllPositionValues();
     setPostReviewModal(false);
     props.closePermissionModal();
 }
  return <div>
      <BackdropLoader open={loading}/>
       <div className="modal fade recruting-modal show" style={{display: "block"}} id="RecrutingPostModal">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <form className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Post</h5>
                    <button type="button" 
                    onClick={props.closePermissionModal}
                     className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body bottom-border">
                    <div className="fieldBox">
                        <h6>Who can see this post?</h6>
                        <div className="radioBox" onChange={(e)=>handleRadioChange(e)}>
                            <div className="option">
                                <input 
                                type="radio" 
                                checked={radio === "allAthelete"} 
                                value="allAthelete" />
                                
                                <label>All Athlete</label></div>
                            <div className="option">
                                <input 
                                type="radio" 
                                checked={radio === "athleteCollegeFollows"}
                              value="athleteCollegeFollows" />
                              
                              <label>Athlete my college follows</label></div>
                        </div>
                    </div>
                    <div className="fieldBox">
                        <h6>Add a description</h6>
                        <p>Describe the type of player you think best fits this need. Adding information about your program can help coaches send you the right student-athletes.</p>
                        <textarea value ={decription} onChange={(e)=>setDescription(e.target.value)}></textarea>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={handlePostReview} type="button" className="btn lightblue" data-dismiss="modal">Post</button>
                    <button type="button" onClick={props.closePermissionModal} className="btn" data-dismiss="modal">Cancel</button>
                </div>
          </form>
        </div>
                </div>
               {postReviewModal && (
                <div className="modal fade recruting-modal show" style={{display: "block"}} id="RecrutingPostModal">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <form className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Post Review</h5>
                    <button type="button" 
                    onClick={closePostReviewModal}
                     className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body bottom-border">
                    <div className="fieldBox">
                        <h6>Grad year  -- {props.gradYear ? props.gradYear : props.currentYear}</h6> 
                        <h6>Position  -- {props.position + " "}</h6>
                    </div>
                    <div className="fieldBox">
                       <h6>Who can see this post?</h6>
                       <p>{radio ? radio : "--"}</p>
                        </div>

                    <div className="fieldBox">
                        <h6>Notes</h6>
                        <p>{decription ? decription : "--"}</p>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={()=>handleClickOkay()} className="btn lightblue" data-dismiss="modal">Ok</button>
                    <button type="button" onClick={()=>closePostReviewModal()} className="btn" data-dismiss="modal">Cancel</button>
                </div>
          </form>
        </div>
        </div>
        )}
  </div>;
}

export default PermissionModal;
