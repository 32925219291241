import { TextField } from "@mui/material";
import React from "react";
import Select from "react-select";
import { logout } from "../../services/authService";
import "./phoneNumber.scss";
export default function PhoneNumber(props) {
  return (
    <div>
      <div className="countryPhoneFlexBox">
        <div className="countrySelect">
          <Select
            isDisabled={props.disabled}
            onMenuOpen={props.onMenuOpen}
            onMenuClose={props.onMenuClose}
            classNamePrefix="select"
            name={props.dropdownName}
            onChange={props.dropdownOnChange}
            value={props.dropdownValue}
            options={props.dropDownOptions}
            formatOptionLabel={props.formatOptionLabel}
          />
        </div>
        <div className="textBox">
          <TextField
            disabled={props.disabled}
            fullWidth
            variant="outlined"
            placeholder={props.inputPlaceholder}
            type={props.inputType}
            value={props.inputValue}
            onChange={props.inputOnChange}
            // onBlur={props.inputOnBlur}
          />
        </div>
      </div>
    </div>
  );
}
