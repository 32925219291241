import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import "../../../../../assets/css/stylesheet.scss";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardPersonalRecord.scss";
import "../assets/css/onBoardSteeper.scss";
import { getUserInfo } from "../../../../../services/authService";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { connect } from "react-redux";
import BackdropLoader from "../../../../common/Loader";
import { getOnboardingQuery, saveOnboardingQuery } from "../OnboardingQuery";
import { getPositionalFieldsListStart } from "../../../../../redux/athlete/onBoarding/positionmappingWithPersonalRecord";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../services/httpService";
import { Dialog } from "@mui/material";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import "../SkitPopup/SkipScreen.scss";
import usePrevious from "../../../../../utils/usePrevious";
import { NumFieldSymbolArr } from "../../../../../utils/validationUtils";
import Stepper from "../Stepper";
import messagePopup from "../../../../../utils/messagePopup";
import { saveStatmatrixdetails, fetchAllStatMetricsDetailsQuery } from "../../dashboard/editUserProfile/stats/statsQuery";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "passed", link: "/onboarding/positions" },
  { className: "passed", link: "/onboarding/physicalMeasurements" },
  { className: "active", link: "/onboarding/personalRecords" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  // { className: "" },//last moment work
];

const PersonalRecords = (props) => {
  const [fields, setFields] = React.useState({
    userid: getUserInfo() ? getUserInfo().id : 0,
  });
  const [errors, setErrors] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [allFieldValues, setAllFieldValues] = React.useState(null);
  const prevOnboardData = usePrevious(props.onboarding.data);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    const { Athlete_Sports, gender } = getUserInfo()
      ? getUserInfo()
      : { Athlete_Sports: null, gender: null };
    if (
      (gender === "Male" && Athlete_Sports === 1) ||
      (gender === "Female" && Athlete_Sports === 4)
    ) {
      // props.history.push("/onboarding/batthrow");
      props.history.push("/onboarding/firstYearVarsity");
    } else {
      // props.history.push("/onboarding/schoolInfo");
      props.history.push("/onboarding/firstYearVarsity");
    }
  };

  useEffect(() => {
    setFields({ userid: getUserInfo() ? getUserInfo().id : 0 });
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: fields.userid,
        },
      },
    });
  }, []);

  const getPositionsValue = async (data) => {
    try {
      const response = await fetchMethod(
        `/api/Positionmappings/getPositionField?primaryPossitionId=${
          data.primaryposition ? data.primaryposition : 0
        }&secondaryPositionId=${0}`,
        null,
        "get",
        true
      );
      //data.secondaryposition
      if (response && response.data && response.data.response.status === 200) {
        const primaryData = response.data.response.data
          ? response.data.response.data.primaryPositionFieldValue
          : null;
        const secondaryData = response.data.response.data
          ? response.data.response.data.secondaryPositionFieldValue
          : null;
        let finalData = [];
        if (primaryData && primaryData.length) {
          finalData = [...finalData, ...primaryData];
        }
        let fieldsT = fields;
        fieldsT["positionalFieldsList"] =
          finalData && finalData.length ? finalData : [];
        fieldsT["positionalFieldsKeys"] =
          finalData && finalData.length
            ? finalData.map((item) => item.keyValue)
            : [];
        for (const key of fieldsT["positionalFieldsKeys"]) {
          fieldsT[key] = data.primarypositioncolumnvalue[key]
            ? data.primarypositioncolumnvalue[key]
            : null;
        }
        setFields({
          ...fieldsT,
        });
      } else {
        props.history.push("/onboarding/positions");
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };
  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = fields;
        const {
          id,
          primaryposition,
          secondaryposition,
          primarypositioncolumnvalue,
        } = props.onboarding.data;
        const parseValue = primarypositioncolumnvalue
          ? JSON.parse(primarypositioncolumnvalue)
          : {};
        getPositionsValue({
          primaryposition,
          secondaryposition,
          primarypositioncolumnvalue: parseValue,
        });
        fields["id"] = id;
        fieldT["exitVelocity"] = !!parseValue?.exitVelocity
          ? `${parseValue?.exitVelocity}`
          : null;
        fieldT["secondaryposition"] = !!parseValue?.outfieldVelocity
          ? `${parseValue?.outfieldVelocity}`
          : null;
        fieldT["yardDash"] = !!parseValue?.yardDash
          ? parseValue?.yardDash
          : null;
        setFields({ ...fieldT });
        setAllFieldValues({ ...fields });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props.onboarding.data]);

  const handleSubmit = async () => {
      if (!isInvalidForm()) {
        const tempData = {},
          statArray = [];
        let isAllBlank = true;
        for (const key of fields.positionalFieldsKeys) {
          tempData[key] = fields[key];
          if (fields[key]) {
            isAllBlank = false;
          }
          fields?.positionalFieldsList?.map(
            (el) =>
              key == el?.keyValue &&
              statArray.push({
                statmatrixmappingid: el?.statmatrixmappingid,
                result: fields[key],
              })
          );
        }
        if (!isAllBlank) {
          props.saveOnboardingStart({
            query: saveOnboardingQuery,
            variables: {
              obj: {
                id: fields.id ? fields.id : 0,
                userid: getUserInfo() ? getUserInfo().id : 0,
                primarypositioncolumnvalue: tempData
                  ? JSON.stringify(tempData)
                  : null,
              },
            },
            nextScreen: routeNextPage,
          });
          //this check it for make the put req in graghQL in stats metrix detail
          if(statArray?.length > 0){
            statArray?.map(async (st, idx)=>{
              const finalObj = {
                where : {
                  statmatrixmappingid: st?.statmatrixmappingid,
                  active : 1,
                  userid: getUserInfo()?.id
                }
              }
              const _res = await fetchGraphMethod(fetchAllStatMetricsDetailsQuery, finalObj, true)
              if(_res?.status == 200){
                if(_res?.data?.data?.allStatmatrixdetails?.Statmatrixdetails?.length > 0){
                  statArray[idx]['id'] = _res?.data?.data?.allStatmatrixdetails?.Statmatrixdetails?.[0]?.id
                }
              }
            })
            setTimeout(()=>{
              saveStats(statArray)
            },1500)
          }else {
            saveStats(statArray);
          }
        } else {
          messagePopup("", "Please enter your personal records.", "error");
        }
      }
    // } else {
    //   messagePopup("", "Please enter your personal records.", "error");
    // }
  };
  const saveStats = async (value) => {
    value.map(async (st, index) => {
      if (st?.result) {
        const finalObj = {
          obj: {
            id: st?.id,
            active: 1,
            userid: getUserInfo()?.id ?? 0,
            statmatrixmappingid: st?.statmatrixmappingid,
            result: st?.result ? st?.result : "",
          },
        };
        const resStat = await fetchGraphMethod(
          saveStatmatrixdetails,
          finalObj,
          true
        );
        if (resStat?.status == 200) console.log(`stat saved ${index}`);
        else console.log(`stat failed ${index}`);
      }
    });
  };
  const handleChange = (field, e, min, max) => {
    const updatedValue = e.target.value;
    let fieldsT = fields;
    const numArr = updatedValue.split(".");
    fieldValues(e);
    if (numArr[1]) {
      if (numArr[1].length <= 2) {
        fieldsT[field] = updatedValue;
        checkValidation(field, updatedValue, +min, +max);
        setFields({
          ...fieldsT,
        });
      }
    } else {
      fieldsT[field] = updatedValue;
      checkValidation(field, updatedValue, +min, +max);
      setFields({
        ...fieldsT,
      });
    }
  };
  const fieldValues = (e, fieldValue) => {
    setAllFieldValues(e.target.value);
  };
  const checkValidation = (field, value, min, max) => {
    let errorsT = errors;
    if (value !== "") {
      if (+value < min || +value > max) {
        errorsT[field] = `Value should be between ${min} and ${max}`;
      } else {
        errorsT[field] = "";
      }
      setErrors(errorsT);
    } else {
      errorsT[field] = "";
      setErrors(errorsT);
    }
  };
  const isInvalidForm = () => {
    let errArr = [];
    for (const [key, value] of Object.entries(errors)) {
      if (value) {
        errArr = [...errArr, true];
      } else {
        errArr = [...errArr, false];
      }
    }
    return errArr.includes(true);
  };
  return (
    <div>
      <BackdropLoader open={props.onboarding.loading} />
      <div
        className={
          fields.positionalFieldsList
            ? "onBoardPersonalRecordSection"
            : "onBoardPersonalRecordSection topalign"
        }
      >
        <div className="personalRecordBlock">
          <div className="stepper">
            <Stepper stepperName="personalRecords" stepperData={stepperData} />
          </div>
          <div className="formSection">
            <h1 className="heading">Personal Records</h1>
            {fields.positionalFieldsList ? (
              fields.positionalFieldsList.map((item, index) => (
                <div className="fieldBox" key={index}>
                  <label>{item.label}</label>
                  <TextField
                    onKeyDown={(e) =>
                      NumFieldSymbolArr.includes(e.key) && e.preventDefault()
                    }
                    fullWidth
                    variant="outlined"
                    id={item.keyValue}
                    placeholder={item.placeHolderValue}
                    type="number"
                    name={item.keyValue}
                    value={fields[item.keyValue] || ""}
                    onChange={(e) =>
                      handleChange(
                        item.keyValue,
                        e,
                        item.minrangevalue,
                        item.maxrangevalue
                      )
                    }
                  />
                  <div style={{ color: "red" }}>{errors[item.keyValue]}</div>
                </div>
              ))
            ) : (
              <b>Primary position is required to enter personal records.</b>
            )}
            <p className="skip">
              <br />
              Don't know?{" "}
              <Link to="#" onClick={handleClickOpen}>
                Skip it
              </Link>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="#" onClick={() => handleSubmit()}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            handleClose()
          }
        }}
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    onboarding: state.athlete.onBoarding.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalRecords);
