import swal from "sweetalert";

export default async function editProfilePopUp(title, text, icon) {
  const willLeave = await swal({
    title: title,
    text: text,
    icon: icon,
    buttons: {
      confirm: { text: "Stay" },
      cancel: "Leave",
    },
    //buttons: ["Stay", "Leave"],
    dangerMode: true,
  });
  return willLeave;
}
