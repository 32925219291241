import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../utils/messagePopup";
import history from "../../services/history";
import { fetchGraphMethod } from "../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
    PHOTOS_VIDEOS_VIEWS_LIST_START: "PHOTOS_VIDEOS_VIEWS_LIST_START",
    PHOTOS_VIDEOS_VIEWS_SUCCESS: "PHOTOS_VIDEOS_VIEWS_SUCCESS",
    PHOTOS_VIDEOS_VIEWS_FAILURE: "PHOTOS_VIDEOS_VIEWS_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const photoVideoViewsListStart = (data) => ({
    type: actionType.PHOTOS_VIDEOS_VIEWS_LIST_START,
    payload: data,
  });

export const photoVideoViewsSuccess = (data) => ({  
    type: actionType.PHOTOS_VIDEOS_VIEWS_SUCCESS,
    payload: data,
});
  
export const photoVideoViewsFailure = (data) => ({
    type: actionType.PHOTOS_VIDEOS_VIEWS_FAILURE,
    payload: data
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function photoVideoViewsReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.PHOTOS_VIDEOS_VIEWS_LIST_START:
      return { ...state, loading: true };
    
    case actionType.PHOTOS_VIDEOS_VIEWS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.PHOTOS_VIDEOS_VIEWS_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/


export function* photosVideosViewsListSaga(action) {
    try {
      const response = yield fetchGraphMethod(
        action.payload.query,
        action.payload.variables,
        true
      );
      if (response && response.status === 200) {
          let photosVideosViews = response.data.data.allPhotovideoviews.Photovideoviews;
        yield put(
            photoVideoViewsSuccess(photosVideosViews)
        );
      } else {
        messagePopup("", "Photos Videos Views Api failed", "error");
        yield put(photoVideoViewsFailure("Photos Videos Views Api failed"));
      }
    } catch (error) {
      messagePopup("", "Photos Videos Views Api failed", "error");
      yield put(photoVideoViewsFailure("Photos Videos Views Api failed"));
    }
  }

export function* watchPhotosVideosViewsListSaga() {
    yield takeEvery(actionType.PHOTOS_VIDEOS_VIEWS_LIST_START, photosVideosViewsListSaga);
}

