export const getAllGraduationYearsQuery = `
  query allGraduationyears($obj: JSON){
    allGraduationyears(where: $obj){
      Graduationyears{
        active
        yearvalue
        id
      }
    }
  } 
  `;

export const getAthleteSportsQuery = `
  query allAthletesports($obj: JSON){
    allAthletesports(where: $obj){
      Athletesports{
        id
        sportname
        sportforgender
      }
    }
  } 
  `;

export const getRelationshipsQuery = `
  query allRelationships($obj: JSON){
    allRelationships(where: $obj){
        Relationships{
            active
            relationshipname
            id
        }
    }
  } 
  `;