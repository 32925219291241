import React, { useEffect } from "react";
import { useState, useRef } from "react";
import "./css/notification.css";
import { Menu, MenuItem, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useHistory, Prompt, useLocation } from "react-router-dom";
import closeGrayIcon from "./img/close-gray-icon.svg";
import trashCircleIcon from "./img/trash-circle-icon.svg";
import alertIcon from './img/alert.png';
import Dialog from "@mui/material/Dialog";
import messagePopup from "../../../../../utils/messagePopup";
import { saveMessageReplyApi, saveMessageApi, AddUserActivity, messageReplyUpdate, athleteParentQuery, getMessageQuery, premiumApiData, getUserData } from "./MessageQuery";
import { messageCountListStart } from '../../../../../redux/athlete/dashboard/message/messageCount';
import { premiumDropdownStart } from '../../../../../redux/athlete/dashboard/message/premiumDropdown';
import { connect } from "react-redux";
import moment from "moment";
import { athleteParentDropdownStart } from '../../../../../redux/athlete/dashboard/composeMessage/athleteParentDropdown';
import { messageReplyListStart, saveMessageReplyListStart } from "../../../../../redux/athlete/dashboard/messageReply/messageReply";
import { imageDownloadUrl } from '../../../../../config';
import { getMessageReplyQuery, getMessageUnreadCount, getAdvisorAthletes } from './MessageQuery';
import { fetchGraphMethod, fetchMethod } from "../../../../../services/httpService";
import { Tooltip } from "@mui/material";
import { dashboardCountStart } from "../../../../../redux/athlete/dashboard/dashboardCount/dashboardCount";
import { getUserInfo } from "../../../../../services/authService";
import BackdropLoader from "../../../../common/Loader";
import toastr from 'toastr';
import swal from "sweetalert";

const ITEM_HEIGHT = 48;

const AdvisorReplyMessage = (props) => {
  const location = useLocation();
  let history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [anchorE1, setAnchorE1] = React.useState(null);
  const open = Boolean(anchorE1);
  const [message, setMessage] = useState(undefined),
    [showDeleteModal, setShowDeleteModal] = useState(false),
    [openPremiumModal, setOpenPremiumModal] = useState(false),
    [supportCount, setSupportCount] = useState(""),
    [archiveCount, setArchiveCount] = useState(""),
    [advisorCount, setAdvisorCount] = useState(""),
    [error, setError] = useState({ message: "" }),
    [messageReplyData, setMessageReplyData] = useState([]),
    [ispremium, setIsPremium] = useState(0),
    [parentData, setParentData] = useState([]),
    [msgReplyId, setMsgReplyId] = useState(0),
    [isBlocking, setIsBlocking] = useState(false),
    [isLoading, setIsLoading] = useState(true),
    [limit, setLimit] = useState(4),
    [hasMore, setHasMore] = useState(false),
    [checkForScroll, setCheckForScroll] = useState(true),
    [athleteData, setAthleteData] = useState([]),
    [messageId, setMessageId] = useState(null),
    [currentAdvisor, setCurrentAdvisor] = useState(true),
    [placeHolderText, setPlaceholderText] = useState("Enter Your message here...");

  const messagesEndRef = useRef(null)
  const nodeRef = useRef(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }

  const showModal = async (nextLocation) => {
    const willSave = await swal({
      text: "Changes you made, may not be saved",
      icon: 'warning',
      buttons: {
        confirm: { text: "Stay" },
        cancel: "Leave",
      },
    })
    if (!willSave) {
      setConfirmedNavigation(true);
    }
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation) => {
    if (!confirmedNavigation && nextLocation.pathname !== history.location.pathname) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };
  const handleCoachMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/coach/composeMessage" });
  };
  const handleAdvisorMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/advisor/replyMessage", hash: "advisor", state: { data: { isComposeAdvisor: true }, composeData: { isComposeAdvisor: true } } });
  }
  const handleSupportMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/support/replyMessage" });
  }
  const archiveMessageData = async () => {
    await fetchGraphMethod(saveMessageApi, { obj: { id: history?.location?.state?.data?.id ? history?.location?.state?.data?.id : messageId, archivebyathlete: 1 } }, true).then(async (res) => {
      await fetchGraphMethod(messageReplyUpdate, {
        where: { msgid: parseFloat(history?.location?.state?.data?.id ? history?.location?.state?.data?.id : messageId) },
        data: { archivebyathlete: 1 }
      }, true).then(async (res) => {
        // messagePopup("", "Chat archived successfully", "success");
        toastr.success("Chat archived successfully", "", {
          progressBar: true, timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
        history.push('/message/advisor')
      })
    })
  }
  const deleteMessageData = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const deleteReplyMessageData = (e, id) => {
    setMsgReplyId(id);
    setShowDeleteModal(!showDeleteModal);
  }
  const cancelBtnClick = () => {
    setOpenPremiumModal(!openPremiumModal);
  }
  const createdTime = newDate => {
    let dateTime = null;
    if (moment(newDate).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')) {
      dateTime = moment(newDate).format('LT');
    }
    else {
      dateTime = moment(newDate).format('DD/MM/YYYY');
    }
    return dateTime;
  }

  const DeleteItem = ({ onDeleteClick, onCancelClick }) => {
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img src={trashCircleIcon} alt="trash Circle Icon" />
              </div>
              <div className="content-box">
                <h4>
                  Are you sure you want to
                  <br /> delete this message?
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn blue"
                data-dismiss="modal"
                onClick={onDeleteClick}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onDeleteHandler = (e) => {
    e.preventDefault();
    fetchMethod("/api/Messages/deleteOrMovetoInbox", {
      role: "ATHLETES",
      isdelete: 1,
      msgids: [history?.location?.state?.data?.id ? history?.location?.state?.data?.id : messageId]
    }, "post", true).then(res => {
      getMessageReplyData();
      toastr.success("Message deleted successfully").css({ "width": "auto" })
      history.push('/message/advisor')
    });
    setShowDeleteModal(!showDeleteModal)
  }

  const onReplyDeleteHandler = async (e) => {
    e.preventDefault();
    props.saveMessageReplyListStart({
      query: saveMessageReplyApi,
      variables: {
        obj: {
          id: parseFloat(msgReplyId),
          deletebyathlete: 1,
          isread: 1
        }
      },
      nextScreen: () => {
        toastr.success("Message deleted successfully").css({ "width": "auto" })
        messageId ? getMessageReplyData(limit, messageId) : getMessageReplyData();
        //getMessageReplyData();
      }
    });
    setMsgReplyId(0)
    setShowDeleteModal(!showDeleteModal)
  }

  const PremiumModal = ({ onActivateClick, onCancelClick }) => {
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img className='imgName' src={alertIcon} alt="Alert Circle Icon" />
              </div>
              <div className="content-box">
                <h4>
                  Sending message to admin is a premium feature
                  <br />Activate your plan to unlock messaging system.
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn blue"
                data-dismiss="modal"
                onClick={onActivateClick}
              >
                Activate
              </button>
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onActivateBtn = e => {
    history.push({ pathname: "/programAndPricing" });
    setOpenPremiumModal(!openPremiumModal);
  }

  const validateMessage = () => {
    //let errors = error;
    //errors.message = message ? '' : 'Please enter message';
    //setError(errors);
    return message ? true : false;
  }
  const sendMessageBtn = async () => {
    let loggedInId = JSON.parse(localStorage.getItem("user")).id;
    if (validateMessage()) {
      let advisorId = history?.location?.state?.data?.messagesendbyadvisor ? history?.location?.state?.data?.messagesendbyadvisor : history?.location?.state?.data?.messagesendtoadvisorid ? history?.location?.state?.data?.messagesendtoadvisorid : athleteData[0]['advisorathletemappingadvisoridmaprel']['Advisors'][0]['userid'];
      let notificationAdvisorId = history?.location?.state?.data?.advisorId ? history?.location?.state?.data?.advisorId : athleteData[0]['advisorathletemappingadvisoridmaprel']['Advisors'][0]['id'];
      await fetchMethod(
        "/api/Messages/composeMessage",
        {
          msgsenderrole: JSON.parse(localStorage.getItem('user')).role,
          msgrecieverrole: "ADVISORS",
          msgsenderid: loggedInId,
          msg: message.replace(/"/g, ""),
          userids: [advisorId],
          parentid: JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN' ? JSON.parse(localStorage.getItem('user')).athleteLoggedInId : 0
        },
        "post",
        true
      ).then(messageRes => {
        if (messageRes?.data?.response?.status == 200) {
          fetchMethod("/api/Notificationadvisorsettings/sendnotificationtoadvisor",
            {
              userId: loggedInId,
              title: "messagefromathlete",
              role: JSON.parse(localStorage.getItem('user')).role,
              msgrecieverids: [notificationAdvisorId],
            }, "post", true
          ).then(messageRes => { })
          toastr.success("Message has been sent successfully").css({ "width": "auto" })
          setIsBlocking(false)
          setMessage('');
          setLimit(4);
          setMessageReplyData([]);
          history.push({ pathname: "/message/advisor" })
          //messageId ? getMessageReplyData(4, messageId) : getMessageReplyData(4);
        } else {
          // messagePopup("", "Message has not been sent. Try again!", "error");
          toastr.error("Message has not been sent. Try again!", "", {
            progressBar: true, timeOut: 2000,
            closeButton: true, positionClass: "toast-top-full-width",
            showEasing: "swing", fadeIn: 40000,
            preventDuplicates: true,
          })
        }
      })
    }
    else {
      // messagePopup("", "Please enter message", "warning")
      toastr.warning("Please enter message", "", {
        progressBar: true, timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }
  }

  useEffect(async () => {
    await fetchGraphMethod(getAdvisorAthletes, { where: { athleteid: getUserInfo()?.id, active: 1 } }, true).then(async (res) => {
      if (res?.status == 200) {
        if (res?.data?.data?.allAdvisorathletemappings?.Advisorathletemappings?.length) {
          let advisorId = res?.data?.data?.allAdvisorathletemappings?.Advisorathletemappings[0]['advisorathletemappingadvisoridmaprel']['Advisors'][0]['userid']
          setAthleteData(res?.data?.data?.allAdvisorathletemappings?.Advisorathletemappings)
          if (!history?.location?.state?.data?.isComposeAdvisor) {
            if (history?.location?.state?.data?.id) {
              let stateAdvisorId = history?.location?.state?.data?.messagesendtoadvisorid ? history?.location?.state?.data?.messagesendtoadvisorid : history?.location?.state?.data?.messagesendbyadvisor
              if (stateAdvisorId != advisorId) {
                setCurrentAdvisor(false)
              }
              getMessageReplyData();
            }
            else {
              history.push({ pathname: "/message/advisor" })
            }
          }
          else {
            if (history?.location?.hash === "#advisor") {
              setCurrentAdvisor(true)
              setPlaceholderText("Enter Your message here...")
            }

            await fetchGraphMethod(getMessageQuery, {
              where:
              {
                order: 'updatedon desc',
                archivebyathlete: 0,
                deletebyathlete: 0,
                and: [{ or: [{ messagesendbyadvisor: advisorId }, { messagesendtoadvisorid: advisorId }] }, { or: [{ messagesendto: parseFloat(JSON.parse(localStorage.getItem("user")).id) }, { messagesendby: parseFloat(JSON.parse(localStorage.getItem("user")).id) }] }]
              }
            },
              true
            ).then(async (res) => {
              if (res?.status == 200 && res?.data?.data?.allMessages?.totalCount) {
                let messageId = res?.data?.data?.allMessages?.Messages[0]['id']
                setMessageId(messageId)
                getMessageReplyData(limit, messageId)
              }
              else {
                if (history?.location?.hash === "#advisor") {
                  setMessageReplyData([])
                }
                setIsLoading(false)
              }
            })
          }
        }
        else{
          if(history?.location?.state?.data?.id){
            getMessageReplyData();
            setPlaceholderText("You cannot message to your old Advisor, If you have any associated query, you can send connect with SportsForce Support");
            setCurrentAdvisor(false)
          }
          else{
            history.push({ pathname: "/message/advisor"})
          }
          // toastr.success("No advisor connected!", "", {
          //   progressBar : true,timeOut: 2000,
          //   closeButton: true, positionClass: "toast-top-full-width",
          //   showEasing: "swing", fadeIn: 40000,
          //   preventDuplicates: true,
          // })
          // history.push({ pathname: "/message/advisor"})
        }
      }
    })

    if (JSON.parse(localStorage.getItem('user')).role === 'ATHLETES' || JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN') {
      props.premiumDropdownStart({
        query: premiumApiData,
        variables: {
          where: {
            order: 'id desc',
            userid: parseFloat(JSON.parse(localStorage.getItem("user")).id),
            active: 1,
          }
        }
      });
    }
    if (JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN') {
      props.athleteParentDropdownStart({
        query: athleteParentQuery,
        variables: {
          where: {
            order: 'id desc',
            userid: parseFloat(JSON.parse(localStorage.getItem("user")).athleteLoggedInId),
            active: 1,
          }
        }
      });
    }
  }, [history.location.state]);

  useEffect(async () => {
    if (messageId) {
      await fetchGraphMethod(saveMessageApi, { obj: { id: messageId, isread: 1 } }, true).then(async (res) => {
        await fetchGraphMethod(messageReplyUpdate, {
          where: { msgid: parseFloat(messageId) },
          data: { isread: 1 }
        }, true).then(async (res) => {
          await fetchGraphMethod(getMessageReplyQuery, {
            where: {
              isread: 0,
              deletebyathlete: 0,
              msgreceiverid: getUserInfo()?.id
            }
          }, true
          ).then(res => {
            let supportData = [],
              advisorData = [],
              archiveData = [];
            res?.data?.data?.allMessagereplies?.Messagereplies.map(item => {
              if (item.archivebyathlete) {
                archiveData.push(item)
              }
              else if (item?.sendmsgrolevalue == "Admin" && !item.archivebyathlete) {
                supportData.push(item)
              }
              else if (item?.sendmsgrolevalue == "Advisor" && !item.archivebyathlete) {
                advisorData.push(item)
              }
            })
            setSupportCount(supportData.length);
            setArchiveCount(archiveData.length);
            setAdvisorCount(advisorData.length);
          })
          window.scrollTo(0, 0);
        })
      })
    }
  }, [messageId])
  
  const getMessageReplyData = async (count = limit, messageId = history.location.state.data.id) => {
    await fetchGraphMethod(getMessageReplyQuery, {
      where: {
        msgid: messageId,
        order: "id desc",
        archivebyathlete: history?.location?.state?.data?.archivebyathlete ? history?.location?.state?.data?.archivebyathlete : 0,
        deletebyathlete: 0,
      },
      first: count,
      last: count
    },
      true
    ).then(res => {
      let data = res?.data?.data?.allMessagereplies?.totalCount > 0 ? res?.data?.data?.allMessagereplies?.Messagereplies?.map(item => {
        item['msg'] = replaceURLs(item?.msg)
        item['messagereplymsgsenderImage'] = item?.messagereplymsgsenderidrel?.Athletes?.length ? item?.messagereplymsgsenderidrel?.Athletes[0]['onBoardingUserIdMap']['Onboardings'][0]['profileimages'] : "";
        item['messagereplymsgsenderName'] = item?.messagereplymsgsenderadvisoridmaprel?.Userdata?.length ? item?.messagereplymsgsenderadvisoridmaprel?.Userdata[0]?.advisorUseridMap?.Advisors[0]['firstname'] + " " + item?.messagereplymsgsenderadvisoridmaprel?.Userdata[0]?.advisorUseridMap?.Advisors[0]['lastname'] : item?.messagereplymsgsenderidrel?.Athletes?.length ? item?.messagereplymsgsenderidrel?.Athletes[0]['athletefirstname'] + " " + item?.messagereplymsgsenderidrel?.Athletes[0]['athletelastname'] :
          item?.messagereplymsgreceiveridmaprel?.Athletes?.length ? item?.messagereplymsgreceiveridmaprel?.Athletes[0]['athletefirstname'] + " " + item?.messagereplymsgreceiveridmaprel?.Athletes[0]['athletelastname'] : "";
        if (item?.parentid) {
          let parentDetails = item?.messagereplyparentidmaprel?.Userdata[0]?.athleteParentUserIdMap?.Athleteparents;
          let messagereplymsgsenderFirstName = parentDetails?.length ? parentDetails[0]?.parentfirstname : '';
          let messagereplymsgsenderLastName = parentDetails?.length ? parentDetails[0]?.parentlastname : '';
          item['messagereplymsgsenderInitials'] = messagereplymsgsenderFirstName && messagereplymsgsenderLastName ? messagereplymsgsenderFirstName[0].toUpperCase() + messagereplymsgsenderLastName[0].toUpperCase() : "";
          item['parentnamevalue'] = messagereplymsgsenderFirstName + " " + messagereplymsgsenderLastName;
        }
        else {
          item['messagereplymsgsenderInitials'] = item['messagereplymsgsenderName'].split(" ")[0][0].toUpperCase() + item['messagereplymsgsenderName'].split(" ")[1][0].toUpperCase();
        }
        return item;
      }) : [];

      setMessageReplyData(data.sort((a, b) => a.id - b.id));
      setLimit(limit + 4);
      setHasMore(res?.data?.data?.allMessagereplies?.totalCount > res?.data?.data?.allMessagereplies?.Messagereplies?.length ? true : false);
      checkForScroll && scrollToBottom();
      setCheckForScroll(false);
      hasMore && res?.data?.data?.allMessagereplies?.Messagereplies?.length > 4 && nodeRef.current.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" })
    })
    setIsLoading(false)
  }

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('user')).role === 'ATHLETES' || JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN') {
      if (props?.premiumDropdown?.data?.data?.length > 0 && props?.premiumDropdown?.data?.data[0]?.subscriptionplanid) {
        !currentAdvisor && setPlaceholderText("You cannot message to your old Advisor, If you have any associated query, you can send connect with SportsForce Support")
        setIsPremium(1);
        setOpenPremiumModal(false);
      }
      else if (props?.premiumDropdown?.data?.data?.length === 0) {
        setOpenPremiumModal(true);
        setIsPremium(0);
        setPlaceholderText('You are not allowed to send messages to admin. Please contact to administrator.');
      }
    }
  }, [props.premiumDropdown.data]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN') {
      if (props?.athleteParentDropdown?.data?.data?.length > 0) {
        let data = props?.athleteParentDropdown?.data?.data?.map(item => {
          return { id: item.id, name: item.parentfirstname.concat(item.parentlastname ? ' ' + item.parentlastname : '') }
        });
        setParentData(data)
      }
    }
  }, [props.athleteParentDropdown.data])

  let nameInitials = '';
  if (parentData?.length > 0) {
    let firstname = parentData?.length > 0 ? parentData[0]?.name.split(/\s/)[0].slice(0, 1) : '';
    let lastname = parentData?.length > 0 ? parentData[0]?.name.split(/\s/)[1].slice(0, 1) : '';
    nameInitials = firstname?.length > 0 && lastname?.length > 0 ? firstname[0].toUpperCase() + lastname[0].toUpperCase() : '';
  }
  else {
    let firstname = JSON.parse(localStorage.getItem('user')).FirstName ? JSON.parse(localStorage.getItem('user')).FirstName[0].split(/\s/).slice(0, 1) : '';
    let lastname = JSON.parse(localStorage.getItem('user')).lastName ? JSON.parse(localStorage.getItem('user')).lastName[0].split(/\s/).slice(0, 1) : '';
    nameInitials = firstname?.length > 0 && lastname?.length > 0 ? firstname[0].toUpperCase() + lastname[0].toUpperCase() : '';
  }

  const replaceURLs = (message) => {
    if (!message) return;
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let emailRegex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    message = message.replace(emailRegex, function (email) {
      return "<a href=mailto:'" + email + "'>" + email + "</a>"
    })
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return "<a href='" + hyperlink + "' target='_blank'>" + url + "</a>"
    });
  }

  const handleScroll = (e) => {
    if (e.currentTarget.scrollTop === 0) {
      hasMore && getMessageReplyData(limit)
    }
  }

  return (
    <div className="pageContent">
      <BackdropLoader open={isLoading} />
      <div className="container">
        <div className="notification-with-sidebar">
          <nav className="notification-sidebar">
            <ul>
              <li className="button-link dropdown">
                <Button className="btn lightblue dropdown-toggle" onClick={(e) => handleClick(e)}>Compose</Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorE1}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '20ch',
                    },
                  }}
                >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  {/* <MenuItem onClick={handleCoachMenu}>Coach</MenuItem> */}
                  <MenuItem onClick={handleSupportMenu}>Support</MenuItem>
                  <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem>
                </Menu>
              </li>
              {/* <li
                className={window.location.href.includes('/coach') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/coach"})}
              >
                <div className = "link-item">
                  <span>Coaches</span> <span>{coachCount ? coachCount : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/advisor') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor"})}
              >
                <div className = "link-item">
                  <span>Advisor</span> <span>{advisorCount ? advisorCount : ""}</span>
                </div>
              </li> */}
              <li
                className={window.location.href.includes('/support') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/support/replyMessage" })}
              >
                <div className="link-item">
                  <span>Support</span> <span>{supportCount ? supportCount : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/advisor') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor" })}
              >
                <div className="link-item">
                  <span>Advisor</span> <span>{advisorCount ? advisorCount : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/archive') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/archive" })}
              >
                <div className="link-item">
                  <span>Archive</span> <span>{archiveCount ? archiveCount : ""}</span>
                </div>
              </li>
            </ul>
          </nav>
          <div className="notification-detail-box with-back">
            <div className="notification-search-box">
              <div className="search-box">
                <label>Advisor</label>
              </div>
              <div className="notification-pagination">
                <Link to={{ pathname: "/message/advisor" }}>
                  <span>{"<"}</span>
                  Back to messages
                </Link>
              </div>
            </div>
            <div className="notification-messageDetail">
              <div className="message-detailInfo">
                <div className="subject-lineInfo">
                  {
                    history?.location?.state?.data?.senderName ? <h2>{history?.location?.state?.data?.senderName}</h2> :
                      <h2>{athleteData.length ? athleteData[0]['advisorathletemappingadvisoridmaprel']['Advisors'][0]['firstname'] + " " + athleteData[0]['advisorathletemappingadvisoridmaprel']['Advisors'][0]['lastname'] : "No New Message"} </h2>
                  }

                  <div className="reply-msg-options">
                    {messageReplyData?.length > 0 ?
                      <Tooltip placement="bottom" title={"Archive"} arrow>
                        <button className="trash-button" onClick={(e) => archiveMessageData()}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="19.2" height="16" viewBox="0 0 29.981 29.982">
                            <path id="archive-30_30" data-name="archive-30*30" d="M2,10.547a3,3,0,0,1,.657-1.873L6.646,3.688A4.5,4.5,0,0,1,10.158,2H23.824a4.5,4.5,0,0,1,3.512,1.688l3.989,4.986a3,3,0,0,1,.657,1.873V27.484a4.5,4.5,0,0,1-4.5,4.5H6.5a4.5,4.5,0,0,1-4.5-4.5ZM10.158,5H23.824a1.5,1.5,0,0,1,1.171.563l2.661,3.326a.375.375,0,0,1-.293.609H6.619a.375.375,0,0,1-.293-.609L8.987,5.561A1.5,1.5,0,0,1,10.158,5Zm8.335,10.493a1.5,1.5,0,1,0-3,0v7.625L14.3,21.924a1.5,1.5,0,0,0-2.12,2.12l2.691,2.69a3,3,0,0,0,4.24,0L21.8,24.05a1.5,1.5,0,1,0-2.12-2.12l-1.186,1.186Z" transform="translate(-2 -2)" fill="#3e3e3e" fill-rule="evenodd" />
                          </svg>
                        </button>
                      </Tooltip>
                      : ""}
                    {messageReplyData?.length > 0 ? <Tooltip placement="bottom" title={"Delete"} arrow><button className="trash-button" onClick={() => deleteMessageData()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                        <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z" fill="#3e3e3e"></path>
                      </svg>
                    </button></Tooltip> : ""}
                  </div>
                </div>
                <div className="replyMessageBlock" style={{ maxHeight: "200px", overflowY: "auto" }} /*style={ messageReplyData?.length && messageReplyData?.length > 3 ? {height: "200px", overflowY: "auto"} : {}}*/ onScroll={(e) => handleScroll(e)}>
                  {messageReplyData?.length > 0 ?
                    messageReplyData?.map((item, index) => {
                      if (index == 4) {
                        return (
                          <div key={index} ref={nodeRef} >
                            {item?.messagereplymsgsenderidrel?.Athletes?.length > 0 ? <div className="message-infobox replyMessage">
                              <div className="user-profile">
                                {item?.messagereplymsgsenderImage && !item?.parentid ? <img src={imageDownloadUrl + `/${item?.messagereplymsgsenderImage}`} alt="" /> : <div className="initial">
                                  {item?.messagereplymsgsenderInitials}
                                </div>}
                              </div>
                              <div className="user-detial-box">
                                <div className="item">
                                  <h4 className="name">{item?.parentnamevalue ? item?.parentnamevalue : item?.messagereplymsgsenderName} <span>{item?.sendmsgrolevalue}</span></h4>
                                  <div className="text-box">
                                    <p>
                                      <div
                                        dangerouslySetInnerHTML={{ __html: item?.msg }}
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="time-text">
                                    <p>{createdTime(item?.createdon)}</p>
                                    <Tooltip placement="bottom" title={"Delete"} arrow>
                                      <button className="msg-reply-trash-button" onClick={(e) => deleteReplyMessageData(e, item.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                                          <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z" fill="#3e3e3e"></path>
                                        </svg>
                                      </button>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div> : item?.messagereplymsgsenderadminidmaprel?.Userdata?.length > 0 ?
                              <div className="message-infobox replyMessage">
                                <div className="user-profile">
                                  {item?.messagereplymsgsenderadminImage ? <img src={imageDownloadUrl + `/${item?.messagereplymsgsendercoachImage}`} alt="" /> : <div className="initial">
                                    {item?.messagereplymsgsenderadminInitials}
                                  </div>}
                                </div>
                                <div className="user-detial-box">
                                  <div className="item">
                                    <h4 className="name">{item?.messagereplymsgsenderadminidmaprel?.Userdata[0]?.fullname.split(" ").join("")} <span>{item?.sendmsgrolevalue}</span></h4>
                                    <div className="text-box">
                                      <p>
                                        <div
                                          dangerouslySetInnerHTML={{ __html: item?.msg }}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                  <div className="item">
                                    <div className="time-text">
                                      <p>{createdTime(item?.createdon)}</p>
                                      <Tooltip placement="bottom" title={"Delete"} arrow>
                                        <button className="msg-reply-trash-button" onClick={(e) => deleteReplyMessageData(e, item.id)}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                                            <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z" fill="#3e3e3e"></path>
                                          </svg>
                                        </button>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              : ''}
                          </div>
                        )
                      }
                      else {
                        return (
                          <div key={index} >
                            <div className="message-infobox replyMessage">
                              <div className="user-profile">
                                {item?.messagereplymsgsenderImage && !item?.parentid ? <img src={imageDownloadUrl + `/${item?.messagereplymsgsenderImage}`} alt="" /> : <div className="initial">
                                  {item?.messagereplymsgsenderInitials}
                                </div>}
                              </div>
                              <div className="user-detial-box">
                                <div className="item">
                                  <h4 className="name">{item?.parentnamevalue ? item?.parentnamevalue : item?.messagereplymsgsenderName} <span>{item?.sendmsgrolevalue}</span></h4>
                                  <div className="text-box">
                                    <p>
                                      <div
                                        dangerouslySetInnerHTML={{ __html: item?.msg }}
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="time-text">
                                    <p>{createdTime(item?.createdon)}</p>
                                    <Tooltip placement="bottom" title={"Delete"} arrow>
                                      <button className="msg-reply-trash-button" onClick={(e) => deleteReplyMessageData(e, item.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                                          <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z" fill="#3e3e3e"></path>
                                        </svg>
                                      </button>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    }) : ""}
                  {<div ref={messagesEndRef} />}
                </div>
              </div>
              <div className="message-hereInfo">
                <div className="message-infobox">
                  <div className="user-profile">
                    {parentData?.length ? <div className="initial">{nameInitials}</div> : history?.location?.state?.data?.messagemessagesendtomaprel?.Athletes?.length && history?.location?.state?.data?.messagemessagesendtomaprel?.Athletes[0]['onBoardingUserIdMap']['Onboardings'][0]['profileimages'] ? <img src={imageDownloadUrl + `/${history?.location?.state?.data?.messagemessagesendtomaprel?.Athletes[0]['onBoardingUserIdMap']['Onboardings'][0]['profileimages']}`} alt="" /> : <div className="initial">
                      {nameInitials}
                    </div>}
                  </div>
                  <div className="user-detial-box">
                    <div className="item">
                      <h4 className="name">You<span>{JSON.parse(localStorage.getItem('user')).role === 'ATHLETES' ? 'Athlete' : JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN' ? JSON.parse(localStorage.getItem('user')).relationship : JSON.parse(localStorage.getItem('user')).role === 'COLLEGECOUCHES' ? 'College Coach' : ''}</span></h4>
                    </div>
                    <div className="item">
                      <div className="time-text">{createdTime(new Date())}</div>
                    </div>
                  </div>
                </div>
                <div className="message-replyInfo">
                  <form>
                    <Prompt
                      when={isBlocking}
                      // message={(location) => {
                      //   if (location.pathname !== history.location.pathname) {
                      //     return "Do you want to leave this page";
                      //   }
                      // }
                      // }
                      message={handleBlockedRoute}
                    />
                    <div className="form-group">
                      <label>Reply</label>
                      <textarea
                        placeholder={placeHolderText}
                        value={message ? message : ""}
                        onChange={(e) => { setMessage(e.target.value); e.target.value ? setIsBlocking(true) : setIsBlocking(false) }}
                        disabled={ispremium === 1 && currentAdvisor ? false : true}
                      ></textarea>
                      {error && error.message ? (
                        <div className="error">
                          <p>{error.message}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {currentAdvisor && <div className="form-group">
                      <button
                        type="button"
                        className="btn blue"
                        onClick={() => sendMessageBtn()}
                        disabled={ispremium === 1 && currentAdvisor ? false : true}
                      >Send</button>
                    </div>}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={showDeleteModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  deleteMessageData()
                }
              }}
              className="matDialogCustom"
      >
        {msgReplyId ? <DeleteItem
          onDeleteClick={(e) => onReplyDeleteHandler(e)}
          onCancelClick={deleteMessageData}
        /> : <DeleteItem
          onDeleteClick={(e) => onDeleteHandler(e)}
          onCancelClick={deleteMessageData}
        />}
      </Dialog>
      <Dialog open={openPremiumModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  cancelBtnClick()
                }
              }}
              className="matDialogCustom"
      >
        <PremiumModal
          onActivateClick={(e) => onActivateBtn(e)}
          onCancelClick={cancelBtnClick}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    messageReplyList: state.athlete.dashboard.messageReply.messageReplyList,
    premiumDropdown: state.athlete.dashboard.message.premiumDropdown,
    messageCountList: state.athlete.dashboard.message.messageCountList,
    athleteParentDropdown: state.athlete.dashboard.composeMessage.athleteParentDropdown,
    dashboardCount: state.athlete.dashboard.dashboardCount.dashboardCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    messageReplyListStart: data => dispatch(messageReplyListStart(data)),
    messageCountListStart: data => dispatch(messageCountListStart(data)),
    premiumDropdownStart: data => dispatch(premiumDropdownStart(data)),
    saveMessageReplyListStart: data => dispatch(saveMessageReplyListStart(data)),
    athleteParentDropdownStart: data => dispatch(athleteParentDropdownStart(data)),
    dashboardCountStart: (data) => dispatch(dashboardCountStart(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorReplyMessage);