import React from "react";
import PostCard from "./PostCard";

export default function GlobalFeed(props) {
  return (
    <>
      <div className="shareDataBox">
        {React.Children.toArray(
          props.postList &&
            props.postList.map((post) => (
              <PostCard
                handleModal={props.handleModal}
                post={post}
                tab="globalFeed"
                handleUnlike={props.handleUnlike}
                handleLike={props.handleLike}
                isAuth = { props?.isAuth }
                page = {props?.page ?? "Dashboard/Global"}
              />
            ))
        )}
      </div>
    </>
  );
}
