import React, {useEffect, useState} from "react";
import './activity.css'
import { useDispatch, useSelector} from "react-redux";
import { getAllCollegeQuery } from './query/activityQuery'
import {getUserInfo} from "../../../../../../services/authService";
import { getCollegesListQuery } from "../myColleges/CollegeListQuery";
import { Link } from 'react-router-dom'
import { fetchMethod } from '../../../../../../services/httpService'
import moment from 'moment'
import Pagination from 'react-js-pagination'
import BackdropLoader from "../../../../../common/Loader";
import swal from 'sweetalert'
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";
import CollegesTabs from "../CollegesTabs";
function Activity(props) {
    const dispatch = useDispatch(),
        store = useSelector(store => store),
        [currentPage, setCurrentPage] = useState(1),
        [goNum, setGoNum] = useState(1),
        [isLoading, setIsLoading] = useState(false),
        [whereObj, setWhereObj] = useState({
            isfav:0, userid:getUserInfo()?.id || 0,
            limit:10, offset:0, view:0, search:0, follow:0,
            group:0,state:0,division:0, viewVideo: 0
        }),
        [act, setAct] = useState([]),
        [expand, setExpand] = React.useState(false);
    useEffect(()=>{
        getApiCall(whereObj)
        // dispatch({ type:"CLG_ACTIVITY_LIST_START", payload: {
        //         variables: {active : 1},
        //         query:getAllCollegeQuery
        //     }})
        // dispatch({type:"COLLEGES_LIST_START", payload:{
        //         variables: whereObj,
        //         query:getCollegesListQuery
        //     }})
    },[])
    //this is used for scrolling on the top when user land on this page
    useEffect(()=>{
        setTimeout(()=>{
            window.scrollTo(0,0)
        },300)
    },[])
    //api call for college activity
    const getApiCall = async (value) =>{
        setIsLoading(true)
        const id = getUserInfo()?.id|| 0
        const obj ={
            url : '/api/Collegecoachactivities/searchCollegeActivity',
            method:"post",
            data: value,
            headers: true
        }
        const res = await fetchMethod(obj?.url, obj?.data, obj?.method, obj?.headers, )
        if(res?.status == 200){
            setAct(res?.data?.response)
            setIsLoading(false)
        }
        setTimeout(()=>{
            window.scrollTo(0,0)
        },1000)
    }
    //func for showing fav data
    const showFav = (e, name) =>{
        let clone = {...whereObj}
        if(name =="select"){
            if(e.target.value == "lastActDate"){
                getApiCall(clone)
            }
            else if (e.target.value == 'lastViewDate'){
                clone['view'] = 0
                clone['search'] = 0
                clone['follow'] = 0
                clone['group'] = 0
                clone['state'] = 0
                clone['division'] = 0
                clone['viewVideo'] = 0
                getApiCall(clone)
                setWhereObj(clone)
            }
            else if(e.target.value == "state"){
                clone['state'] = 1
                clone['division'] = 0
                getApiCall(clone)
                setWhereObj(clone)
            }
            else if(e.target.value == "division"){
                clone['state'] = 0
                clone['division'] = 1
                getApiCall(clone)
                setWhereObj(clone)
            }
        } else{
            clone[name] = name == "isfav" ? e.target.checked ? 1 : 0 :
                clone[name] == 0 ? 1 : 0
            getApiCall(clone)
            setWhereObj(clone)

        }
    }
    //func for activity listing date difference
    const ActDiff = value =>{
        const diffDate = moment(value),
            currDate = moment(new Date());
        let diffResult = `${currDate.diff(diffDate,'months')} months`
        if(diffResult  == "0 months"){
            diffResult = `${currDate.diff(diffDate,'days')} days`
        }
        return diffResult
    }
    //func for handle pagination
    const handlePagination = page_num =>{
        setCurrentPage(page_num)
        const clone =  {...whereObj}
        clone["offset"] = (page_num - 1) * clone['limit']
        setWhereObj(clone)
        getApiCall(clone)
    }
    //func for per page handle
    const onHandlePerPage = e =>{
        const clone = {...whereObj}
        if(+e.target.value <= 0){
            swal({text: "Invalid Number", icon: "error"})
        }else{
            clone["limit"] = parseInt(+e?.target?.value || 0)
            setWhereObj(clone)
        }
    }
    return (
        <div className="activity-data-sec">
            <BackdropLoader open={isLoading} />
            <div className="container">
                <div className="headerFlex">
                    <CollegesTabs active="activity" />
                    {/*<div className="tabs">*/}
                    {/*    <ul>*/}
                    {/*        <li><a onClick={()=>props.history.push('/colleges/list')} style={{cursor:"pointer"}}><span>My Colleges</span></a>*/}
                    {/*        </li>*/}
                    {/*        <li><a onClick={()=>props.history.push('/colleges/search')} style={{cursor:"pointer"}}><span>Search</span></a></li>*/}
                    {/*        <li>*/}
                    {/*            <a className="selected">*/}
                    {/*            <span>Activity<span className="icon"><svg*/}
                    {/*                xmlns="http://www.w3.org/2000/svg" width="12" height="12.888" viewBox="0 0 12 12.888">*/}
                    {/*                <defs><style></style></defs>*/}
                    {/*                <g transform="translate(-3.373 -1.125)">*/}
                    {/*                <path className="a"*/}
                    {/*                      d="M13.51,8.5V7.347h-.881V8.68a.406.406,0,0,0,.132.311l1.19,1.2V10.9h-9.7v-.711l1.19-1.2a.406.406,0,0,0,.132-.311V6.458A3.541,3.541,0,0,1,9.1,2.9a3.455,3.455,0,0,1,1.762.484V2.369a4.251,4.251,0,0,0-1.322-.356V1.125H8.662v.889A4.5,4.5,0,0,0,4.7,6.458V8.5L3.506,9.7a.406.406,0,0,0-.132.311v1.333a.42.42,0,0,0,.12.323.412.412,0,0,0,.32.121H6.9a2.2,2.2,0,1,0,4.407,0h3.085a.412.412,0,0,0,.32-.121.42.42,0,0,0,.12-.323V10.013A.406.406,0,0,0,14.7,9.7ZM9.1,13.124a1.328,1.328,0,0,1-1.322-1.333h2.644A1.328,1.328,0,0,1,9.1,13.124Z"*/}
                    {/*                      transform="translate(0 0)"></path><path*/}
                    {/*                    className="a"*/}
                    {/*                    d="M29.079,6.664A2.164,2.164,0,1,1,26.914,4.5,2.164,2.164,0,0,1,29.079,6.664Z"*/}
                    {/*                    transform="translate(-13.706 -1.752)"></path>*/}
                    {/*                </g>*/}
                    {/*            </svg>*/}
                    {/*        </span>*/}
                    {/*                </span>*/}
                    {/*            </a>*/}
                    {/*        </li>*/}
                    {/*        <li><a href={void 0} onClick={()=>props.history.push('/colleges/matches')}><span>Matches<span className="icon">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><defs><style></style></defs><path*/}
                    {/*                className="a"*/}
                    {/*                d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"*/}
                    {/*                transform="translate(-3 -3)"></path></svg></span></span></a></li>*/}
                    {/*    </ul>*/}
                    {/*    <div className="mobileMenuBox">*/}
                    {/*        <div className="menuBtn" id="menuBtn">*/}
                    {/*            <button className="btn"*/}
                    {/*            onClick={()=>setExpand(!expand)}*/}
                    {/*            >*/}
                    {/*                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12">*/}
                    {/*                    <defs>*/}
                    {/*                        <style></style>*/}
                    {/*                    </defs>*/}
                    {/*                    <g transform="translate(-284.664 -20.498)">*/}
                    {/*                        <rect className="a" width="16" height="2" rx="1"*/}
                    {/*                              transform="translate(284.664 20.498)"/>*/}
                    {/*                        <rect className="a" width="16" height="2" rx="1"*/}
                    {/*                              transform="translate(284.664 25.498)"/>*/}
                    {/*                        <rect className="a" width="16" height="2" rx="1"*/}
                    {/*                              transform="translate(284.664 30.498)"/>*/}
                    {/*                    </g>*/}
                    {/*                </svg>*/}
                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*        <div className="label"><span>My Colleges</span></div>*/}
                    {/*        <div className={`mobileMenu ${expand ? "expandMenu" : ""}`}>*/}
                    {/*            <div className="closeBtnBox">*/}
                    {/*                <button className="closebtn">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="15.804" height="15.803"*/}
                    {/*                         viewBox="0 0 15.804 15.803">*/}
                    {/*                        <defs>*/}
                    {/*                            <style></style>*/}
                    {/*                        </defs>*/}
                    {/*                        <g className="a" transform="translate(-6.776 -6.776)">*/}
                    {/*                            <path*/}
                    {/*                                d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"*/}
                    {/*                                transform="translate(0 0)"/>*/}
                    {/*                        </g>*/}
                    {/*                    </svg>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <ul>*/}
                    {/*                <li><a*/}
                    {/*                    onClick={()=>{*/}
                    {/*                        setExpand(false)*/}
                    {/*                        props.history.push('/colleges/list')*/}
                    {/*                    }}*/}
                    {/*                    ><span>My Colleges</span></a>*/}
                    {/*                </li>*/}
                    {/*                <li><a onClick={()=>{*/}
                    {/*                    props.history.push('/colleges/search')*/}
                    {/*                    setExpand(false)*/}
                    {/*                }}*/}
                    {/*                ><span>Search</span></a>*/}
                    {/*                </li>*/}
                    {/*                <li><a className="selected" href="javascript:void(0);">*/}
                    {/*                    <span>Activity*/}
                    {/*                        <span className="icon">*/}
                    {/*                            <svg xmlns="http://www.w3.org/2000/svg" width="12"*/}
                    {/*                                 height="12.888" viewBox="0 0 12 12.888">*/}
                    {/*                                /!*<defs><style></style></defs>*!/*/}
                    {/*                                <g*/}
                    {/*                                    transform="translate(-3.373 -1.125)">*/}
                    {/*                            <path className="a"*/}
                    {/*                                  d="M13.51,8.5V7.347h-.881V8.68a.406.406,0,0,0,.132.311l1.19,1.2V10.9h-9.7v-.711l1.19-1.2a.406.406,0,0,0,.132-.311V6.458A3.541,3.541,0,0,1,9.1,2.9a3.455,3.455,0,0,1,1.762.484V2.369a4.251,4.251,0,0,0-1.322-.356V1.125H8.662v.889A4.5,4.5,0,0,0,4.7,6.458V8.5L3.506,9.7a.406.406,0,0,0-.132.311v1.333a.42.42,0,0,0,.12.323.412.412,0,0,0,.32.121H6.9a2.2,2.2,0,1,0,4.407,0h3.085a.412.412,0,0,0,.32-.121.42.42,0,0,0,.12-.323V10.013A.406.406,0,0,0,14.7,9.7ZM9.1,13.124a1.328,1.328,0,0,1-1.322-1.333h2.644A1.328,1.328,0,0,1,9.1,13.124Z"*/}
                    {/*                                  transform="translate(0 0)"></path><path*/}
                    {/*                                    className="a"*/}
                    {/*                                    d="M29.079,6.664A2.164,2.164,0,1,1,26.914,4.5,2.164,2.164,0,0,1,29.079,6.664Z"*/}
                    {/*                                    transform="translate(-13.706 -1.752)"/></g>*/}
                    {/*                            </svg>*/}
                    {/*                </span>*/}
                    {/*                        </span>*/}
                    {/*                </a>*/}
                    {/*                </li>*/}
                    {/*                <li><a href="/colleges/matches"*/}
                    {/*                onClick={()=>{*/}
                    {/*                    setExpand(false)*/}
                    {/*                }}*/}
                    {/*                >*/}
                    {/*                    <span>Matches*/}
                    {/*                        <span className="icon"><svg*/}
                    {/*                    xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><defs><style></style></defs><path*/}
                    {/*                    className="a"*/}
                    {/*                    d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"*/}
                    {/*                    transform="translate(-3 -3)"></path></svg></span></span></a></li>*/}
                    {/*            </ul>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="prefer">*/}
                    {/*    <Link to="/colleges/preferences">*/}
                    {/*        <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="17.365"*/}
                    {/*                                    height="18" viewBox="0 0 17.365 18"><defs><style></style></defs><path*/}
                    {/*            className="a"*/}
                    {/*            d="M19.212,12.655A3.434,3.434,0,0,1,20.48,7.971L18.711,4.907a3.39,3.39,0,0,1-1.72.466,3.434,3.434,0,0,1-3.423-3.445H10.031a3.389,3.389,0,0,1-.456,1.727A3.434,3.434,0,0,1,4.884,4.9L3.115,7.963A3.39,3.39,0,0,1,4.378,9.219a3.434,3.434,0,0,1-1.26,4.68l1.769,3.064A3.389,3.389,0,0,1,6.6,16.5a3.434,3.434,0,0,1,3.422,3.427h3.538a3.39,3.39,0,0,1,.456-1.709A3.434,3.434,0,0,1,18.7,16.971l1.769-3.064a3.39,3.39,0,0,1-1.256-1.252ZM11.8,14.573a3.645,3.645,0,1,1,3.645-3.645A3.645,3.645,0,0,1,11.8,14.573Z"*/}
                    {/*            transform="translate(-3.115 -1.928)"/>*/}
                    {/*        </svg>*/}
                    {/*        </span>*/}
                    {/*        <span>Preferences</span>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                </div>
                {/* Coming soon only added for alpha 1.0 , You can remove this true ternary condition and use the false div in alpha 1.1 */}
                { true ? 
                    <div className="activity-box-wrap">
                        <h1 className="comingsoon-title">Coming Soon</h1>
                    </div> : 
                    <div className="activity-box-wrap">
                        <h1 className="activity-main-title">Coach Activity Report</h1>
                        <div className="activity-gray-box">
                            <div className="activity-check-box">
                                <p className="checkbox-list"><span>
                                    <input type="checkbox" value={whereObj?.isfav} name={'isfav'} onClick={(e)=>showFav(e, "isfav")}/><label>Only Show Favorites</label></span>
                                </p>
                            </div>
                            <div className="activity-info-box">
                                <ul className="flexBox">
                                    <li>
                                        <span className="count">{act?.toatlCount?.[0]?.totalvideoView || 0}</span>
                                        <span>Video Views</span>
                                    </li>
                                    <li>
                                        <span className="count">{act?.toatlCount?.[0]?.totalViewCount || 0}</span>
                                        <span>visits</span>
                                    </li>
                                    <li>
                                        <span className="count">{act?.toatlCount?.[0]?.totalSearchCount || 0}</span>
                                        <span>Searches</span>
                                    </li>
                                    <li>
                                        <span className="count">{act?.toatlCount?.[0]?.totalFollowCount || 0}</span>
                                        <span>Follows</span>
                                    </li>
                                    <li>
                                        <span className="count">{act?.toatlCount?.[0]?.totalGroupCount || 0}</span>
                                        <span>Group</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="filter-wrap">
                            <div className="sortFilter">
                                <form>
                                    <ul>
                                        <li className="title"><span>Filters</span></li>
                                        <li
                                            onClick={()=>showFav('','viewVideo')}
                                        >
                                            <button type={'button'} style={whereObj?.viewVideo ? {color:"white",background:"blue"} :{}}>Video Views</button>
                                        </li>
                                        <li
                                            onClick={()=>showFav('','view')}
                                        >
                                            <button type={'button'} style={whereObj?.view ? {color:"white",background:"blue"} :{}}>Visits</button>
                                        </li>
                                        <li
                                            onClick={()=>showFav('','search')}
                                        >
                                            <button type={'button'} style={whereObj?.search ? {color:"white",background:"blue"} :{}}>Searches</button>
                                        </li>
                                        <li
                                            onClick={()=>showFav('','follow')}
                                        >
                                            <button type={'button'} style={whereObj?.follow ? {color:"white",background:"blue"} :{}}>Follows</button>
                                        </li>
                                        <li
                                            onClick={()=>showFav('','group')}
                                        >
                                            <button type={'button'} style={whereObj?.group ? {color:"white",background:"blue"} :{}}>Group</button>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                            <div className="sortFilter">
                                <form>
                                    <label>
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.163" height="15.204"
                                            viewBox="0 0 12.163 15.204">
                                            <path id="bx-sort"
                                                d="M9.041,13.643H6L10.561,18.2V3H9.041ZM13.6,5.281V18.2h1.52V7.561h3.041L13.6,3Z"
                                                transform="translate(-6 -3)" fill="#3e3e3e">
                                            </path>
                                        </svg>
                                    </span>
                                        <span>Sort by</span>
                                    </label>
                                    <select onChange={(e)=>showFav(e,'select')}>
                                        <option>Select</option>
                                        <option value ='lastActDate'>Last Activity Date</option>
                                        {/*<option>Profile Visits Count</option>*/}
                                        <option>Match Precentage</option>
                                        <option value='lastViewDate' >Last View Date</option>
                                        <option value={'state'}>State(A - Z)</option>
                                        <option value={'division'}>Division</option>
                                    </select>
                                </form>
                            </div>
                        </div>
                        <div className="activity-listing">
                            {
                                // store && store?.athlete?.dashboard?.colleges?.clgActivity?.data?.
                                act?.data?.length > 0 ?
                                    act?.data?.map((clg, idx)=>{
                                        return(
                                            <div className="list-item">
                                                <div className="activity-detail-info">
                                                    <div className="wishlist-icon">
                                                        <svg className={clg?.isFavCollege ?  "favorate" : ""} xmlns="http://www.w3.org/2000/svg"
                                                            // xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            width="42.302" height="38.479"
                                                            viewBox="0 0 42.302 38.479">
                                                            <defs>
                                                                <style></style>
                                                                <filter id="a" x="0" y="0" width="42.302" height="38.479"
                                                                        filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha"/>
                                                                    <feGaussianBlur stdDeviation="3" result="b"/>
                                                                    <feFlood flood-opacity="0.161"/>
                                                                    <feComposite operator="in" in2="b"/>
                                                                    <feComposite in="SourceGraphic"/>
                                                                </filter>
                                                            </defs>
                                                            <g transform="translate(9.751 17.752)">
                                                                <g className="b" transform="matrix(1, 0, 0, 1, -9.75, -17.75)">
                                                                    <path className="a"
                                                                        d="M20.586,3.537a6.347,6.347,0,0,0-8.309.572l-.877.854-.877-.854a6.347,6.347,0,0,0-8.309-.572,5.817,5.817,0,0,0-.441,8.747l8.617,8.406a1.453,1.453,0,0,0,2.017,0l8.617-8.406a5.813,5.813,0,0,0-.436-8.747Z"
                                                                        transform="translate(9.75 4.5)"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div className="activity-logo">
                                                        <img src={TeamLogo} alt=""/>
                                                    </div>
                                                    <div className="detail-box">
                                                        <div className="activity-detail">
                                                            <div className="title-box-flex">
                                                                <Link to={`/colleges/details/${clg?.collegeid || 0}/${clg?.isFavCollege ?? 0}`}><h3>{clg?.collegename ?? ""}</h3></Link>
                                                                <ul>
                                                                    {!!clg?.FollowStatus?.[0]?.totalFollowerStatusCount && clg?.FollowStatus?.[0]?.totalFollowerStatusCount > 0 && <li style={{marginBottom:"2px"}}>Following</li>}
                                                                    {!!clg?.groupName?.[0]?.interestlevelname && <li>Interest Level: {clg?.groupName?.[0]?.interestlevelname}</li>}
                                                                </ul>
                                                            </div>
                                                            <ul className="address-info">
                                                                <li >{clg?.collegelevel || ""}</li>
                                                                <li>{clg?.city ?? ""}, {clg?.state ?? ""}</li>
                                                            </ul>
                                                            {!!clg?.coachLastActivity?.length > 0 && !!clg?.coachLastActivity?.[0]?.updatedOn &&
                                                            <p>{clg?.coachname ?? ""} {clg?.designation ? `(${clg?.designation})` : ""} {clg?.activityname || ""} your email {ActDiff(clg?.updatedOn || new Date())}.</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="activity-info-box" style={{width:"59%"}}>
                                                    <ul className="flexBox">
                                                        <li style={{cursor:"pointer"}} onClick={()=>props.history.push(`/colleges/details/${clg?.collegeid || 0}/${clg?.isFavCollege ?? 0}`)}>
                                                            <span className="count" style={clg?.viewCount > 0 ? {} : {opacity:"0.5"}}>{clg?.viewVideo || 0}</span>
                                                            <span style={clg?.viewVideo > 0 ? {} : {opacity:"0.5"}}>Video Views</span>
                                                        </li>
                                                        <li style={{cursor:"pointer"}} onClick={()=>props.history.push(`/colleges/details/${clg?.collegeid || 0}/${clg?.isFavCollege ?? 0}`)}>
                                                            <span className="count" style={clg?.viewCount > 0 ? {} : {opacity:"0.5"}}>{clg?.viewCount || 0}</span>
                                                            <span style={clg?.viewCount > 0 ? {} : {opacity:"0.5"}}>Visits</span>
                                                        </li>
                                                        <li style={{cursor:"pointer"}} onClick={()=>props.history.push(`/colleges/details/${clg?.collegeid || 0}/${clg?.isFavCollege ?? 0}`)}>
                                                            <span className="count" style={clg?.searchCount > 0 ? {} : {opacity:"0.5"}}>{clg?.searchCount || 0}</span>
                                                            <span style={clg?.searchCount > 0 ? {} : {opacity:"0.5"}}>Searches</span>
                                                        </li>
                                                        <li style={{cursor:"pointer"}} onClick={()=>props.history.push(`/colleges/details/${clg?.collegeid || 0}/${clg?.isFavCollege ?? 0}`)}>
                                                            <span className="count" style={clg?.followerCount > 0 ? {} : {opacity:"0.5"}}>{clg?.followerCount || 0}</span>
                                                            <span style={clg?.followerCount > 0 ? {} : {opacity:"0.5"}}>Follows</span>
                                                        </li>
                                                        <li style={{cursor:"pointer"}} onClick={()=>props.history.push(`/colleges/details/${clg?.collegeid || 0}/${clg?.isFavCollege ?? 0}`)}>
                                                            <span className="count" style={clg?.groupCount > 0 ? {} : {opacity:"0.5"}}>{clg?.groupCount || 0}</span>
                                                            <span style={clg?.groupCount > 0 ? {} : {opacity:"0.5"}}>Group</span>
                                                        </li>
                                                        <li className="percentage">
                                                    <span style={!!clg?.matchValue ?
                                                        clg?.matchValue < 50 ?  {color:"blue"} :
                                                            (clg?.matchValue > 50 && clg?.matchValue < 79) ? {color:"orange"} :
                                                                {color:"red"}
                                                        :{}
                                                    }>{clg?.matchValue || 0}%</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    }) : "No Record Found"}
                        </div>
                        <div className="paginationBox">
                            <div className="perPage">
                                <input
                                    style={{width:"45px"}}
                                    type="number"
                                    placeholder="10"
                                    value={whereObj?.limit}
                                    onChange={(e)=>((parseInt(e.target.value) <= 0) || (e.target.value == "")) ? ""
                                        : onHandlePerPage(e)} /><label>Per Page</label>
                            </div>
                            <div className="pageNav" style={{border:"none"}}>
                                {/*<ul>*/}
                                <Pagination
                                    innerClass='pages pagination'
                                    itemClass='pagination'
                                    linkClass='page-link'
                                    activePage={currentPage}
                                    activeClass={"active"}
                                    itemsCountPerPage={whereObj?.limit}
                                    totalItemsCount={act?.getTotalDataCount || 0}
                                    pageRangeDisplayed={5}
                                    onChange={handlePagination}
                                />
                                {/*<li className="backLink"><span><svg xmlns="http://www.w3.org/2000/svg"*/}
                                {/*                                    viewBox="0 0 192.689 192.689"><path*/}
                                {/*    d="M188.527 87.755l-83.009-84.2a11.952 11.952 0 00-17.011 0c-4.704 4.74-4.704 12.439 0 17.179l74.54 75.61-74.54 75.61c-4.704 4.74-4.704 12.439 0 17.179 4.704 4.74 12.319 4.74 17.011 0l82.997-84.2c4.535-4.558 4.547-12.606.012-17.178z"></path><path*/}
                                {/*    d="M104.315 87.755l-82.997-84.2c-4.704-4.74-12.319-4.74-17.011 0-4.704 4.74-4.704 12.439 0 17.179l74.528 75.61-74.54 75.61c-4.704 4.74-4.704 12.439 0 17.179s12.319 4.74 17.011 0l82.997-84.2c4.535-4.558 4.547-12.606.012-17.178z"></path></svg></span>*/}
                                {/*</li>*/}
                                {/*<li className="active"><span>1</span></li>*/}
                                {/*<li><span>2</span></li>*/}
                                {/*<li><span>3</span></li>*/}
                                {/*<li><span>4</span></li>*/}
                                {/*<li><span>5</span></li>*/}
                                {/*<li><span>...</span></li>*/}
                                {/*<li><span>10</span></li>*/}
                                {/*<li className="forLink"><span><svg xmlns="http://www.w3.org/2000/svg"*/}
                                {/*                                   viewBox="0 0 192.689 192.689"><path*/}
                                {/*    d="M188.527 87.755l-83.009-84.2a11.952 11.952 0 00-17.011 0c-4.704 4.74-4.704 12.439 0 17.179l74.54 75.61-74.54 75.61c-4.704 4.74-4.704 12.439 0 17.179 4.704 4.74 12.319 4.74 17.011 0l82.997-84.2c4.535-4.558 4.547-12.606.012-17.178z"></path><path*/}
                                {/*    d="M104.315 87.755l-82.997-84.2c-4.704-4.74-12.319-4.74-17.011 0-4.704 4.74-4.704 12.439 0 17.179l74.528 75.61-74.54 75.61c-4.704 4.74-4.704 12.439 0 17.179s12.319 4.74 17.011 0l82.997-84.2c4.535-4.558 4.547-12.606.012-17.178z"></path></svg></span>*/}
                                {/*</li>*/}
                                {/*</ul>*/}
                            </div>
                            <div className="goBox">
                                <input
                                    style={{width:"45px"}}
                                    type="number"
                                    placeholder="1"
                                    value={goNum}
                                    onChange={(e) =>((parseInt(e.target.value) <= 0) || (e.target.value == "")) ? "" : setGoNum(parseInt(e.target.value))}
                                />
                                <span className="inline-box">
                                    <span onClick={()=>handlePagination(!!goNum ? goNum : 1)}>Go </span><span className="icon"><svg
                                xmlns="http://www.w3.org/2000/svg" width="4.568" height="7.99"
                                viewBox="0 0 4.568 7.99"><defs><style></style></defs><path className="a"
                                                                                        d="M1.377,3.993,4.4.972a.569.569,0,0,0,0-.806.576.576,0,0,0-.809,0L.166,3.589a.57.57,0,0,0-.017.787l3.44,3.447A.571.571,0,0,0,4.4,7.017Z"
                                                                                        transform="translate(4.568 7.99) rotate(180)"></path></svg></span>
                                </span>
                            
                                
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

    )}

export default Activity