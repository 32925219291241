import React, { useEffect } from 'react';
import { useState } from 'react';
import './css/athlete-activity.scss';
// import { apiKey } from "../../../../../config";
import { connect } from 'react-redux';
import { getAthleteQuery } from './ActivityPageQuery';
import Geocode from 'react-geocode';
import moment from 'moment';
import { viewCoachLevelStart } from '../../../../../redux/athlete/dashboard/activity/viewCoachByLevel';
import { viewCoachStateStart } from '../../../../../redux/athlete/dashboard/activity/viewCoachByState';
import { followerCoachLevelStart } from '../../../../../redux/athlete/dashboard/activity/followerCoachByLevel';
import { followerCoachStateStart } from '../../../../../redux/athlete/dashboard/activity/followerCoachByState';
import { followerAthleteAddressStart } from '../../../../../redux/athlete/dashboard/activity/followerAthleteLocation';
import { followerAthleteViewCountStart } from '../../../../../redux/athlete/dashboard/activity/followerAthleteViewActivity';
import { followerCollegeCoachCountStart } from '../../../../../redux/athlete/dashboard/activity/followerCollegeCoachActivity';
import { followerAthleteListStart } from '../../../../../redux/athlete/dashboard/activity/followerAthleteActivity';
import { followerCollegeCoachViewCountStart } from '../../../../../redux/athlete/dashboard/activity/followerCollegeCoachViewActivity';
import { followerCollegeCoachActivityListStart } from '../../../../../redux/athlete/dashboard/activity/followerCollegeCoachActivityList';

const ActivityPage = (props) => {
  const [timeFilter, setTimeFilter] = useState('0');
  const [tabData, setTabData] = useState('Followers');
  const [latData, setLatData] = useState(undefined);
  const [lngData, setLngData] = useState(undefined);
  const [fieldFlag, setFieldFlag] = useState({
    athleteCountFlag: false,
    athleteViewCountFlag: false,
    collegeCoachFlag: false,
    collegeCoachViewFlag: false,
  });
  const [followCount, setFollowCount] = useState({
    followerCount: 0,
    athelteCount: 0,
    collegeCoachCount: 0,
    collegeCount: 0,
    otherCount: 0,
  });
  const [followViewCount, setFollowViewCount] = useState({
    totalViews: 0,
    profileView: 0,
    videoView: 0,
    transcriptView: 0,
    reportView: 0,
  });
  const [followCollegeCoachCount, setFollowCollegeCoachCount] = useState({
    followerCount: 0,
    divisionData: [],
  });
  const [followCollegeViewCount, setFollowCollegeViewCount] = useState({
    totalViews: 0,
    coachprofileView: 0,
    coachvideoView: 0,
    transcriptViews: 0,
    reportViews: 0,
  });
  const [listData, setListData] = useState([]);
  const [followerStateData, setFollowerStateData] = useState([]);
  const [followerLevelData, setFollowerLevelData] = useState([]);

  useEffect(() => {
    getFollowerAthleteCount();
  }, [fieldFlag.athleteCountFlag, timeFilter]);

  useEffect(() => {
    getFollowerCollegeCoachCount();
  }, [fieldFlag.collegeCoachFlag, timeFilter]);

  useEffect(() => {
    getFollowerAthleteViewCount();
  }, [fieldFlag.athleteViewCountFlag, timeFilter]);

  useEffect(() => {
    getFollowerCollegeCoachViewCount();
  }, [fieldFlag.collegeCoachViewFlag, timeFilter]);

  useEffect(() => {
    getFollowerCollegeCoachActivityList();
  }, [timeFilter]);

  useEffect(() => {
    if (tabData === 'Followers') {
      getFollowerAthleteLocation();
      getFollowerCoachLevelList();
      getFollowerCoachStateList();
    } else {
      getFollowerAthleteLocation();
      getViewCoachLevelList();
      getViewCoachStateList();
    }
  }, [timeFilter, tabData]);

  const getFollowerCollegeCoachCount = () => {
    props.followerCollegeCoachCountStart({
      variables: {
        athleteid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
        flag: fieldFlag?.collegeCoachFlag ? 1 : 0,
        dateFilterValue: parseFloat(timeFilter),
      },
    });
  };

  const getFollowerAthleteViewCount = () => {
    props.followerAthleteViewCountStart({
      variables: {
        athleteid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
        flag: fieldFlag?.athleteViewCountFlag ? 1 : 0,
        dateFilterValue: parseFloat(timeFilter),
      },
    });
  };

  const getFollowerAthleteCount = () => {
    props.followerAthleteListStart({
      variables: {
        athleteid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
        flag: fieldFlag?.athleteCountFlag ? 1 : 0,
        dateFilterValue: parseFloat(timeFilter),
      },
    });
  };

  const getFollowerCollegeCoachViewCount = () => {
    props.followerCollegeCoachViewCountStart({
      variables: {
        athleteid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
        flag: fieldFlag?.collegeCoachViewFlag ? 1 : 0,
        dateFilterValue: parseFloat(timeFilter),
      },
    });
  };

  const getFollowerCollegeCoachActivityList = () => {
    props.followerCollegeCoachActivityListStart({
      variables: {
        athleteid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
        flag: 0,
        dateFilterValue: parseFloat(timeFilter),
      },
    });
  };

  const getFollowerAthleteLocation = () => {
    props.followerAthleteAddressStart({
      query: getAthleteQuery,
      variables: {
        where: {
          id: parseFloat(JSON.parse(localStorage.getItem('user')).id),
          active: 1,
        },
      },
    });
  };

  const getFollowerCoachStateList = () => {
    props.followerCoachStateStart({
      variables: {
        athleteid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
        flag: 0,
        dateFilterValue: parseFloat(timeFilter),
      },
    });
  };

  const getFollowerCoachLevelList = () => {
    props.followerCoachLevelStart({
      variables: {
        athleteid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
        flag: 0,
        dateFilterValue: parseFloat(timeFilter),
      },
    });
  };

  const getViewCoachStateList = () => {
    props.viewCoachStateStart({
      variables: {
        athleteid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
        flag: 0,
        dateFilterValue: parseFloat(timeFilter),
      },
    });
  };

  const getViewCoachLevelList = () => {
    props.viewCoachLevelStart({
      variables: {
        athleteid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
        flag: 0,
        dateFilterValue: parseFloat(timeFilter),
      },
    });
  };

  useEffect(() => {
    if (props?.followerAthleteList?.data?.data) {
      let fieldsT = followCount;
      fieldsT['followerCount'] =
        props?.followerAthleteList?.data?.data?.totalFollowers;
      fieldsT['athelteCount'] =
        props?.followerAthleteList?.data?.data?.AthelteFollowers;
      fieldsT['collegeCoachCount'] =
        props?.followerAthleteList?.data?.data?.CoachFollowers;
      fieldsT['collegeCount'] =
        props?.followerAthleteList?.data?.data?.CollegeFollowers;
      fieldsT['otherCount'] =
        props?.followerAthleteList?.data?.data?.OtherFollowers;
      setFollowCount({ ...fieldsT });
    }
  }, [props.followerAthleteList.data]);

  useEffect(() => {
    if (props?.followerCollegeCoachCount?.data?.data) {
      let fieldsT = followCollegeCoachCount;
      fieldsT['followerCount'] =
        props?.followerCollegeCoachCount?.data?.data?.totalFollowers;
      fieldsT['divisionData'] =
        props?.followerCollegeCoachCount?.data?.data?.Followers;
      setFollowCollegeCoachCount({ ...fieldsT });
    }
  }, [props.followerCollegeCoachCount.data]);

  useEffect(() => {
    if (props?.follwerAthleteViewCount?.data?.data) {
      let fieldsT = followViewCount;
      fieldsT['totalViews'] =
        props?.follwerAthleteViewCount?.data?.data?.totalViews;
      fieldsT['profileView'] =
        props?.follwerAthleteViewCount?.data?.data?.profileView;
      fieldsT['videoView'] =
        props?.follwerAthleteViewCount?.data?.data?.videoView;
      fieldsT['transcriptView'] =
        props?.follwerAthleteViewCount?.data?.data?.transcriptView;
      fieldsT['reportView'] =
        props?.follwerAthleteViewCount?.data?.data?.reportView;
      setFollowViewCount({ ...fieldsT });
    }
  }, [props.follwerAthleteViewCount.data]);

  useEffect(() => {
    if (props?.follwerCollegeCoachViewCount?.data?.data) {
      let fieldsT = followCollegeViewCount;
      fieldsT['totalViews'] =
        props?.follwerCollegeCoachViewCount?.data?.data?.totalViews;
      fieldsT['coachprofileView'] =
        props?.follwerCollegeCoachViewCount?.data?.data?.coachprofileView;
      fieldsT['coachvideoView'] =
        props?.follwerCollegeCoachViewCount?.data?.data?.coachvideoView;
      fieldsT['transcriptViews'] =
        props?.follwerCollegeCoachViewCount?.data?.data?.transcriptViews;
      fieldsT['reportViews'] =
        props?.follwerCollegeCoachViewCount?.data?.data?.reportViews;
      setFollowCollegeViewCount({ ...fieldsT });
    }
  }, [props.follwerCollegeCoachViewCount.data]);

  useEffect(() => {
    if (
      props?.followerCollegeCoachActivityList?.data?.data?.activityarray
        ?.length > 0
    ) {
      setListData(
        props?.followerCollegeCoachActivityList?.data?.data?.activityarray
      );
    }
  }, [props.followerCollegeCoachActivityList.data]);

  useEffect(() => {
    if (props?.followerAthleteLocation?.data?.data?.length > 0) {
      let address = props?.followerAthleteLocation?.data?.data?.[0];
      address['statename'] =
        address?.athletestateidmaprel?.States?.length > 0
          ? address?.athletestateidmaprel?.States?.[0]?.statename
          : '';
      address['countryName'] =
        address?.athleteathletecountrycodeidmaprel?.Countries?.length > 0
          ? address?.athleteathletecountrycodeidmaprel?.Countries?.[0]
              ?.countryname
          : '';
      //this code need to check due to google api code is getting broken and if() check is not implement correctly 15/7/2022
      if (
        address.address ||
        address.city ||
        address.statename ||
        address.countryName ||
        address.athletezipcode
      ) {
        Geocode.setApiKey(process.env.REACT_APP_apiKey);
        Geocode.setLanguage('en');
        Geocode.setRegion('es');
        Geocode.fromAddress(
          (address.address ? address.address + ' ' : '')
            .concat(address.city ? address.city + ' ' : '')
            .concat(address.statename ? address.statename + ' ' : '')
            .concat(address.countryName ? address.countryName + ' ' : '')
            .concat(address.athletezipcode ? address.athletezipcode : '')
        ).then((resData) => {
          const { lat, lng } = resData.results[0].geometry.location;
          setLatData(lat);
          setLngData(lng);
        });
      }
    }
  }, [props.followerAthleteLocation.data]);

  useEffect(() => {
    if (props?.followerCoachState?.data?.data?.Followers?.length > 0) {
      setFollowerStateData(props?.followerCoachState?.data?.data?.Followers);
    }
  }, [props.followerCoachState.data]);

  useEffect(() => {
    if (props?.followerCoachLevel?.data?.data?.Followers?.length > 0) {
      setFollowerLevelData(props?.followerCoachLevel?.data?.data?.Followers);
    }
  }, [props.followerCoachLevel.data]);

  useEffect(() => {
    if (props?.viewCoachState?.data?.data?.Views?.length > 0) {
      setFollowerStateData(props?.viewCoachState?.data?.data?.Views);
    }
  }, [props.viewCoachState.data]);

  useEffect(() => {
    if (props?.viewCoachLevel?.data?.data?.Views?.length > 0) {
      setFollowerLevelData(props?.viewCoachLevel?.data?.data?.Views);
    }
  }, [props.viewCoachLevel.data]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);

  const handleChange = (value) => {
    setTimeFilter(value);
  };

  const handleCheckData = (name, event) => {
    let fieldsT = fieldFlag;
    fieldsT[name] = event.target.checked;
    setFieldFlag({ ...fieldsT });
  };

  const handleFollowerTab = (value) => {
    setTabData(value);
  };

  //This return is only added for alpha 1.0 , Activity main functionality is commented below please use that in alpha 1.1
  //Go through change doc april 2022 onwards (jully 2022 tab request no. 87)
  return (
    <div className="activity-data-sec">
      <div className="container">
        <div className="activity-box-wrap">
          <h1 className="comingsoon-title">Coming Soon</h1>
        </div>
      </div>
    </div>
  );

  //   return (
  //     <div className="activity-data-sec">
  //     <div className="container">
  //         <div className="activity-box-wrap">
  //             <h1 className="activity-main-title">Activity</h1>
  //             <div className="box-item">
  //                 <div className="filter-wrap">
  //                     <h3 className="heading">Profile Overview</h3>
  //                     <div className="sortFilter">
  //                         <select
  //                             name="category"
  //                             value={timeFilter || "0"}
  //                             onChange={(e) => handleChange(e.target.value)}>
  //                             <option value={"0"}>All Time</option>
  //                             <option value={"7"}>7 days</option>
  //                             <option value={"30"}>30 days</option>
  //                             <option value={"90"}>90 days</option>
  //                         </select>
  //                     </div>
  //                 </div>
  //                 <div className="overview-box-wrap">
  //                     <div className="overview-item">
  //                         <div className="flexbox">
  //                             <div className="info-box">
  //                                 <div className="icon-box">
  //                                     <svg id="user-role" xmlns="http://www.w3.org/2000/svg" width="17.143" height="24" viewBox="0 0 17.143 24">
  //                                         <path id="Path_2241" data-name="Path 2241" d="M19.393,30.13H17.679V26.014A4.2,4.2,0,0,0,13.393,21.9H8.25a4.2,4.2,0,0,0-4.286,4.117V30.13H2.25V26.014a5.885,5.885,0,0,1,6-5.764h5.143a5.885,5.885,0,0,1,6,5.764Z" transform="translate(-2.25 -6.13)" fill="#3e3e3e"/>
  //                                         <path id="Path_2242" data-name="Path 2242" d="M11.726,3.9a4.243,4.243,0,0,1,4.358,4.117,4.243,4.243,0,0,1-4.358,4.117A4.243,4.243,0,0,1,7.368,8.014,4.243,4.243,0,0,1,11.726,3.9m0-1.647a5.94,5.94,0,0,0-6.1,5.764,5.94,5.94,0,0,0,6.1,5.764,5.94,5.94,0,0,0,6.1-5.764A5.94,5.94,0,0,0,11.726,2.25Z" transform="translate(-3.155 -2.25)" fill="#3e3e3e"/>
  //                                     </svg>
  //                                 </div>
  //                                 <div className="detail-box">
  //                                     <h4>{followCount?.followerCount || 0}</h4>
  //                                     <p>Followers</p>
  //                                 </div>
  //                             </div>
  //                             <div className="switch-box">
  //                                 <div className="switch-button">
  //                                     <input id="switch" className="checkbox" checked={fieldFlag?.athleteCountFlag ? true : false}  type="checkbox" onChange={(e) => handleCheckData("athleteCountFlag", e)} />
  //                                     <label htmlFor="switch"># / %</label>
  //                                   </div>
  //                             </div>
  //                         </div>
  //                         <div className="overview-data">
  //                             <ul className="flexbox-row">
  //                                 <li>Athlete</li>
  //                                 <li className="progresbar">
  //                                     <span style={{width:`${Math.round(parseFloat(followCount?.athelteCount))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followCount?.athelteCount)) || 0}</li>
  //                             </ul>
  //                             <ul className="flexbox-row">
  //                                 <li>College Coaches</li>
  //                                 <li className="progresbar">
  //                                 <span style={{width:`${Math.round(parseFloat(followCount?.collegeCoachCount))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followCount?.collegeCoachCount)) || 0}</li>
  //                             </ul>
  //                             <ul className="flexbox-row">
  //                                 <li>College</li>
  //                                 <li className="progresbar">
  //                                 <span style={{width:`${Math.round(parseFloat(followCount?.collegeCount))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followCount?.collegeCount)) || 0}</li>
  //                             </ul>
  //                             <ul className="flexbox-row">
  //                                 <li>Other</li>
  //                                 <li className="progresbar">
  //                                 <span style={{width:`${Math.round(parseFloat(followCount?.otherCount))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followCount?.otherCount)) || 0}</li>
  //                             </ul>
  //                         </div>
  //                     </div>
  //                     <div className="overview-item">
  //                         <div className="flexbox">
  //                             <div className="info-box">
  //                                 <div className="icon-box">
  //                                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21">
  //                                         <g id="bar-chart-line-fill" transform="translate(0 -2.25)">
  //                                           <path id="Path_2251" data-name="Path 2251" d="M3.251,22.5h2c.553,0,1,.672,1,1.5v4.5c0,.829-.448,1.5-1,1.5h-2c-.553,0-1-.672-1-1.5V24C2.25,23.172,2.7,22.5,3.251,22.5Z" transform="translate(0.585 -6.754)" fill="#3e3e3e"/>
  //                                           <path id="Path_2252" data-name="Path 2252" d="M14.5,13.5h2c.553,0,1,.672,1,1.5V25.507c0,.829-.448,1.5-1,1.5h-2c-.553,0-1-.672-1-1.5V15C13.5,14.172,13.948,13.5,14.5,13.5Z" transform="translate(-3.168 -3.757)" fill="#3e3e3e"/>
  //                                           <path id="Path_2253" data-name="Path 2253" d="M25.751,2.25h2c.553,0,1,.672,1,1.5v18c0,.828-.448,1.5-1,1.5h-2c-.553,0-1-.672-1-1.5v-18C24.75,2.922,25.2,2.25,25.751,2.25Z" transform="translate(-6.92 0)" fill="#3e3e3e"/>
  //                                           <path id="Path_2254" data-name="Path 2254" d="M0,32.25a.75.75,0,0,1,.75-.75h22.5a.75.75,0,0,1,0,1.5H.75A.75.75,0,0,1,0,32.25Z" transform="translate(0 -9.751)" fill="#3e3e3e" fillRule="evenodd"/>
  //                                         </g>
  //                                     </svg>
  //                                 </div>
  //                                 <div className="detail-box">
  //                                     <h4>{followViewCount?.totalViews || 0}</h4>
  //                                     <p>Views</p>
  //                                 </div>
  //                             </div>
  //                             <div className="switch-box">
  //                                 <div className="switch-button">
  //                                 <input id="switch" className="checkbox" checked={fieldFlag?.athleteViewCountFlag ? true : false}  type="checkbox" onChange={(e) => handleCheckData("athleteViewCountFlag", e)} />
  //                                     <label htmlFor="switch"># / %</label>
  //                                   </div>
  //                             </div>
  //                         </div>
  //                         <div className="overview-data">
  //                             <ul className="flexbox-row">
  //                                 <li>Profile</li>
  //                                 <li className="progresbar">
  //                                 <span style={{width:`${Math.round(parseFloat(followViewCount?.profileView))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followViewCount?.profileView)) || 0}</li>
  //                             </ul>
  //                             <ul className="flexbox-row">
  //                                 <li>Video</li>
  //                                 <li className="progresbar">
  //                                 <span style={{width:`${Math.round(parseFloat(followViewCount?.videoView))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followViewCount?.videoView)) || 0}</li>
  //                             </ul>
  //                             <ul className="flexbox-row">
  //                                 <li>Transcript</li>
  //                                 <li className="progresbar">
  //                                 <span style={{width:`${Math.round(parseFloat(followViewCount?.transcriptView))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followViewCount?.transcriptView)) || 0}</li>
  //                             </ul>
  //                             <ul className="flexbox-row">
  //                                 <li>Report</li>
  //                                 <li className="progresbar">
  //                                 <span style={{width:`${Math.round((parseFloat(followViewCount?.reportView)/parseFloat(followViewCount?.totalViews))*100)}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followViewCount?.reportView)) || 0}</li>
  //                             </ul>
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <h3 className="heading">College Coach / Scout Activity</h3>
  //                 <div className="overview-box-wrap">
  //                     <div className="overview-item">
  //                         <div className="flexbox">
  //                             <div className="info-box">
  //                                 <div className="icon-box">
  //                                     <svg id="user-role" xmlns="http://www.w3.org/2000/svg" width="17.143" height="24" viewBox="0 0 17.143 24">
  //                                         <path id="Path_2241" data-name="Path 2241" d="M19.393,30.13H17.679V26.014A4.2,4.2,0,0,0,13.393,21.9H8.25a4.2,4.2,0,0,0-4.286,4.117V30.13H2.25V26.014a5.885,5.885,0,0,1,6-5.764h5.143a5.885,5.885,0,0,1,6,5.764Z" transform="translate(-2.25 -6.13)" fill="#3e3e3e"/>
  //                                         <path id="Path_2242" data-name="Path 2242" d="M11.726,3.9a4.243,4.243,0,0,1,4.358,4.117,4.243,4.243,0,0,1-4.358,4.117A4.243,4.243,0,0,1,7.368,8.014,4.243,4.243,0,0,1,11.726,3.9m0-1.647a5.94,5.94,0,0,0-6.1,5.764,5.94,5.94,0,0,0,6.1,5.764,5.94,5.94,0,0,0,6.1-5.764A5.94,5.94,0,0,0,11.726,2.25Z" transform="translate(-3.155 -2.25)" fill="#3e3e3e"/>
  //                                     </svg>
  //                                 </div>
  //                                 <div className="detail-box">
  //                                     <h4>{followCollegeCoachCount?.followerCount || 0}</h4>
  //                                     <p>Followers</p>
  //                                 </div>
  //                             </div>
  //                             <div className="switch-box">
  //                                 <div className="switch-button">
  //                                     <input id="switch" className="checkbox" checked={fieldFlag?.collegeCoachFlag ? true : false}  type="checkbox" onChange={(e) => handleCheckData("collegeCoachFlag", e)} />
  //                                     <label htmlFor="switch"># / %</label>
  //                                   </div>
  //                             </div>
  //                         </div>
  //                         <div className="overview-data">
  //                             {followCollegeCoachCount?.divisionData?.length > 0 ? followCollegeCoachCount?.divisionData?.map(item=>{
  //                                return <ul className="flexbox-row">
  //                                 <li>{item.collegedivisionname}</li>
  //                                 <li className="progresbar">
  //                                     <span style={{width:`${Math.round(parseFloat(item.divisionFollowers))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(item.divisionFollowers))}</li>
  //                             </ul>
  //                             }) : ""}
  //                         </div>
  //                     </div>
  //                     <div className="overview-item">
  //                         <div className="flexbox">
  //                             <div className="info-box">
  //                                 <div className="icon-box">
  //                                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21">
  //                                         <g id="bar-chart-line-fill" transform="translate(0 -2.25)">
  //                                           <path id="Path_2251" data-name="Path 2251" d="M3.251,22.5h2c.553,0,1,.672,1,1.5v4.5c0,.829-.448,1.5-1,1.5h-2c-.553,0-1-.672-1-1.5V24C2.25,23.172,2.7,22.5,3.251,22.5Z" transform="translate(0.585 -6.754)" fill="#3e3e3e"/>
  //                                           <path id="Path_2252" data-name="Path 2252" d="M14.5,13.5h2c.553,0,1,.672,1,1.5V25.507c0,.829-.448,1.5-1,1.5h-2c-.553,0-1-.672-1-1.5V15C13.5,14.172,13.948,13.5,14.5,13.5Z" transform="translate(-3.168 -3.757)" fill="#3e3e3e"/>
  //                                           <path id="Path_2253" data-name="Path 2253" d="M25.751,2.25h2c.553,0,1,.672,1,1.5v18c0,.828-.448,1.5-1,1.5h-2c-.553,0-1-.672-1-1.5v-18C24.75,2.922,25.2,2.25,25.751,2.25Z" transform="translate(-6.92 0)" fill="#3e3e3e"/>
  //                                           <path id="Path_2254" data-name="Path 2254" d="M0,32.25a.75.75,0,0,1,.75-.75h22.5a.75.75,0,0,1,0,1.5H.75A.75.75,0,0,1,0,32.25Z" transform="translate(0 -9.751)" fill="#3e3e3e" fillRule="evenodd"/>
  //                                         </g>
  //                                     </svg>
  //                                 </div>
  //                                 <div className="detail-box">
  //                                     <h4>{followCollegeViewCount?.totalViews || 0}</h4>
  //                                     <p>Views</p>
  //                                 </div>
  //                             </div>
  //                             <div className="switch-box">
  //                                 <div className="switch-button">
  //                                 <input id="switch" className="checkbox" checked={fieldFlag?.collegeCoachViewFlag ? true : false}  type="checkbox" onChange={(e) => handleCheckData("collegeCoachViewFlag", e)} />
  //                                     <label htmlFor="switch"># / %</label>
  //                                   </div>
  //                             </div>
  //                         </div>
  //                         <div className="overview-data">
  //                             <ul className="flexbox-row">
  //                                 <li>Profile</li>
  //                                 <li className="progresbar">
  //                                 <span style={{width:`${Math.round(parseFloat(followCollegeViewCount?.coachprofileView))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followCollegeViewCount?.coachprofileView)) || 0}</li>
  //                             </ul>
  //                             <ul className="flexbox-row">
  //                                 <li>Video</li>
  //                                 <li className="progresbar">
  //                                 <span style={{width:`${Math.round(parseFloat(followCollegeViewCount?.coachvideoView))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followCollegeViewCount?.coachvideoView)) || 0}</li>
  //                             </ul>
  //                             <ul className="flexbox-row">
  //                                 <li>Transcript</li>
  //                                 <li className="progresbar">
  //                                 <span style={{width:`${Math.round(parseFloat(followCollegeViewCount?.transcriptViews))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followCollegeViewCount?.transcriptViews)) || 0}</li>
  //                             </ul>
  //                             <ul className="flexbox-row">
  //                                 <li>Report</li>
  //                                 <li className="progresbar">
  //                                 <span style={{width:`${Math.round(parseFloat(followCollegeViewCount?.reportViews))}%`}}></span>
  //                                 </li>
  //                                 <li>{Math.round(parseFloat(followCollegeViewCount?.reportViews)) || 0}</li>
  //                             </ul>
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <div className="map-box-sec">
  //                     <div className="flexbox">
  //                         <ul className="nav nav-tabs">
  //                             <li className="nav-item">
  //                               <a className="nav-link active" data-toggle="tab" onClick={()=>handleFollowerTab("Followers")}>Followers</a>
  //                             </li>
  //                             <li className="nav-item">
  //                                 <a className="nav-link" data-toggle="tab" onClick={()=>handleFollowerTab("Views")}>Views</a>
  //                             </li>
  //                         </ul>
  //                         <div className="btn-wrap">
  //                             <button className="btn">View Report</button>
  //                         </div>
  //                     </div>
  //                     <div className="tab-content">
  //                         <div className="tab-pane active" id="All">
  //                             <div className="map-box-info">
  //                                 <div className="map-img">
  //                                     <Map
  //                                         markers={[
  //                                             { latitude: -25.2744, longitude: 133.7751 },
  //                                         ]}
  //                                         />
  //                                 </div>
  //                                 <div className="info-box">
  //                                     <div className="items">
  //                                         <h5>BY Level</h5>
  //                                         <div className="box">
  //                                         {/* {followCollegeCoachCount?.divisionData?.length > 0 ? followCollegeCoachCount?.divisionData?.map(item=>{
  //                                             return <ul className="flexbox-row">
  //                                                     <li>{item.collegedivisionname}</li>
  //                                                     <li className="progresbar">
  //                                                     <span style={{width:`${Math.round((parseFloat(item.divisionFollowers)/parseFloat(followCollegeCoachCount?.followerCount))*100)}%`}}></span>
  //                                                     </li>
  //                                                     <li>{item.divisionFollowers}</li>
  //                                                     </ul>
  //                                         }) : ""} */}
  //                                         </div>
  //                                     </div>
  //                                     <div className="items">
  //                                         <h5>BY State</h5>
  //                                         <div className="box">
  //                                             <ul className="flexbox-row">
  //                                                 <li>CA</li>
  //                                                 <li className="progresbar">
  //                                                     <span style={{width: "50%"}}></span>
  //                                                 </li>
  //                                                 <li>5</li>
  //                                             </ul>
  //                                             <ul className="flexbox-row">
  //                                                 <li>OK</li>
  //                                                 <li className="progresbar">
  //                                                     <span style={{width: "30%"}}></span>
  //                                                 </li>
  //                                                 <li>3</li>
  //                                             </ul>
  //                                             <ul className="flexbox-row">
  //                                                 <li>CO</li>
  //                                                 <li className="progresbar">
  //                                                     <span style={{width:"60%"}}></span>
  //                                                 </li>
  //                                                 <li>10</li>
  //                                             </ul>
  //                                             <ul className="flexbox-row">
  //                                                 <li>Other</li>
  //                                                 <li className="progresbar">
  //                                                     <span style={{width:"30%"}}></span>
  //                                                 </li>
  //                                                 <li>3</li>
  //                                             </ul>
  //                                         </div>
  //                                     </div>
  //                                 </div>
  //                             </div>
  //                         </div>
  //                         <div className="tab-pane fade" id="Following">
  //                             Following
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <div className="activity-table-box">
  //                     <h3 className="heading">College Coach / Scout Activity Details</h3>
  //                     <table className="table">
  //                         <tr>
  //                             <td width="25%">Activity</td>
  //                             <td width="30%">School</td>
  //                             <td width="23%">DIV</td>
  //                             <td width="22%">Time</td>
  //                         </tr>
  //                         {listData?.length > 0 ?
  //                             listData?.map(item=>{
  //                                 return  <tr>
  //                                 <td width="25%">{item.Activity}</td>
  //                                 <td width="30%">{item.college}</td>
  //                                 <td width="23%">{item.division}</td>
  //                                 <td width="22%">{moment(item.time).format('MMM Do, YYYY h:mm a')}</td>
  //                             </tr>
  //                             })
  //                         : <tr>
  //                             <td style={{fontWeight:600}}>No data found</td>
  //                             </tr>}
  //                     </table>
  //                 </div>
  //             </div>
  //         </div>
  //     </div>
  // </div>
  //   );
};

const mapStateToProps = (state) => {
  return {
    followerAthleteList: state.athlete.activity.followerAthleteList,
    followerCollegeCoachCount: state.athlete.activity.followerCollegeCoachCount,
    follwerAthleteViewCount: state.athlete.activity.follwerAthleteViewCount,
    follwerCollegeCoachViewCount:
      state.athlete.activity.follwerCollegeCoachViewCount,
    followerCollegeCoachActivityList:
      state.athlete.activity.followerCollegeCoachActivityList,
    followerAthleteLocation: state.athlete.activity.followerAthleteLocation,
    followerCoachState: state.athlete.activity.followerCoachState,
    followerCoachLevel: state.athlete.activity.followerCoachLevel,
    viewCoachState: state.athlete.activity.viewCoachState,
    viewCoachLevel: state.athlete.activity.viewCoachLevel,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    followerAthleteListStart: (data) =>
      dispatch(followerAthleteListStart(data)),
    followerCollegeCoachCountStart: (data) =>
      dispatch(followerCollegeCoachCountStart(data)),
    followerAthleteViewCountStart: (data) =>
      dispatch(followerAthleteViewCountStart(data)),
    followerCollegeCoachViewCountStart: (data) =>
      dispatch(followerCollegeCoachViewCountStart(data)),
    followerCollegeCoachActivityListStart: (data) =>
      dispatch(followerCollegeCoachActivityListStart(data)),
    followerAthleteAddressStart: (data) =>
      dispatch(followerAthleteAddressStart(data)),
    followerCoachStateStart: (data) => dispatch(followerCoachStateStart(data)),
    followerCoachLevelStart: (data) => dispatch(followerCoachLevelStart(data)),
    viewCoachStateStart: (data) => dispatch(viewCoachStateStart(data)),
    viewCoachLevelStart: (data) => dispatch(viewCoachLevelStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPage);
