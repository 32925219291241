import React, { useState, useEffect, useRef } from "react";
import LeftSideEditProfile from "./LeftSideEditProfile";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { MenuItem, Select } from "@mui/material";
import {connect, useDispatch} from "react-redux";
import { relationshipListStart } from "../../../../../redux/common/relationships";
import { annualHouseholdIncomeListStart } from "../../../../../redux/common/allAnnualhouseholdincomes";
import { racialEthnicHeritageListStart } from "../../../../../redux/common/allRacialethnicheritages";
import { stateListStart } from "../../../../../redux/common/allStates";
import { countryListStart } from "../../../../../redux/common/countriesList";
import {
  getAllAnnualhouseholdincomes,
  getAllAthletesDataQuery,
  getAllRelationshipsQuery,
} from "../../../../common/CommonQuery";
import { getAllRacialethnicheritages } from "../../../../common/CommonQuery";
import { getUserInfo } from "../../../../../services/authService";
import { saveCollegeInsightUpdateQuery } from "./CollegeInsightsQuery";
import { getAllStatesQuery } from "../../../../common/CommonQuery";
import {
  collegeInsightssaveStart,
  collegeInsightsListStart,
} from "../../../../../redux/athlete/dashboard/editUserProfile/collegeInsights";
import AutoCompleteCitySearch from "../../../../common/AutoCompleteCitySearch";
import { getAllCollegeInsightsQuery } from "../editUserProfile/CollegeInsightsQuery";
import messagePopup from "../../../../../utils/messagePopup";
import ScreenSwitch from "./ScreenSwitch";
import SubHeader from "./SubHeader";
import GoTopButton from "./GoTopButton";
import { athletesDetailsStart } from "../../../../../redux/common/athletesDetails";
import BackdropLoader from "../../../../common/Loader";
import swal from "sweetalert";
import {fetchGraphMethod} from "../../../../../services/httpService";
import {isValidZipcode} from "../../../../../utils/validationUtils";
import {websiteDefaultUrl} from "../../../../../config";
import history from "../../../../../services/history";
import {getAthleteSysID} from "../../../../common/getProfileURLData";

const labelStyle = {
  position: "relative",
  fontSize: "0.875rem",
  fontWeight: 400,
  color: "#3e3e3e",
  lineHeight: "1.4",
  marginBottom: "10px",
}

const Title = (props) => {
  const routeBackToProfile = () => {
    history?.push(
        {pathname: `${getAthleteSysID()}`,
          state: {isPublic : false}
        }
        // '/profile/1'
    )
    sessionStorage.setItem('athlete_own_profile', true)
    // window.location.href = '/profile/1';
  }
  return (
    <div className="flexRow">
      <div className="title">College Insights</div>
      <div className="btnWrap">
      <button
                      className="btn outlined green rounded medium backprofileBtn"
                      onClick={() => routeBackToProfile()}
                    >
                      Back To Profile
                    </button>
        <button className="btn outlined green rounded medium" onClick={()=>routeBackToProfile()}>
          Preview Profile
        </button>
        <button className="btn outlined blue rounded medium"
          onClick={() => {
            sessionStorage.setItem('athlete_own_profile', false)
            setTimeout(() => {
              window.open(`${websiteDefaultUrl}athlete/wyatt-johnson-74144936`, "_blank")
            }, 500)
          }}
        >
          Sample Profile
        </button>
      </div>
    </div>
  );
};

const FamilyBG = (props) => {
  const [fields, setFields] = useState({
    haveOlderSiblings: "",
    mostInfluential: "",
    youLiveWith: "",
    parentsDivorced: "",
  });

  useEffect(() => {
    let collegeInsightDetails = props.Relationships.data.collegeInsightsData;
    if (
      collegeInsightDetails &&
      collegeInsightDetails.data &&
      collegeInsightDetails.data.Collegeinsights != ""
    ) {
      let fieldsT = fields;
      fieldsT["haveOlderSiblings"] =
        collegeInsightDetails.data.Collegeinsights[0]
          .haveanysiblingswhogonetocollege == 1
          ? "Yes"
          : collegeInsightDetails.data.Collegeinsights[0]
              .haveanysiblingswhogonetocollege == 0
          ? "No"
          : "";
      fieldsT["mostInfluential"] =
        collegeInsightDetails.data.Collegeinsights[0].influentialdecision;
      fieldsT["youLiveWith"] =
        collegeInsightDetails.data.Collegeinsights[0].wholiveswithyou;
      fieldsT["parentsDivorced"] =
        collegeInsightDetails.data.Collegeinsights[0].isparentdivorced == 1
          ? "Yes"
          : collegeInsightDetails.data.Collegeinsights[0].isparentdivorced == 0
          ? "No"
          : "";
      setFields({ ...fieldsT });
    }
    //used for to do scroll on top
    window.scrollTo(0,0)
  }, [props.Relationships.data.collegeInsightsData]);

  useEffect(() => {
    props.familyBG(fields);
  }, [fields]);

  const handleChange = (field, e) => {
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setFields({ ...fieldsT });
    props.setIsSaved(false);
  };

  return (
    <div className="form-info-box">
      <div className="item">
        <h5 className="title">Family Background</h5>
        <div className="fieldBox flexBox">
          <div className="half">
            <label>
              Do you have any older siblings who have gone to college?
            </label>
            <>
              <select
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                // label="Select"
                // displayEmpty
                value={fields.haveOlderSiblings}
                onChange={(e) => handleChange("haveOlderSiblings", e)}
              >
                {/*<MenuItem value="">*/}
                {/*  <em>Select</em>*/}
                {/*</MenuItem>*/}
                {/*<MenuItem value="Yes">Yes</MenuItem>*/}
                {/*<MenuItem value="No">No</MenuItem>*/}
                <option value={""}>Select</option>
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </select>
            </>
          </div>
          <div className="half">
            <label>
              Who will be the most influential in your college decision?
            </label>
            <>
              <select
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                // label="Select"
                // displayEmpty
                onChange={(e) => handleChange("mostInfluential", e)}
                value={fields.mostInfluential || ""}
              >
                <option value={""}>
                  Select
                </option>
                {props?.allRelationData?.length
                  ? props?.allRelationData.map(
                      (item, index) => (
                        <option key={index} value={item.id}>
                          {item.relationshipname}
                        </option>
                      )
                    )
                  : null}
              </select>
            </>
          </div>
        </div>
        <div className="fieldBox flexBox">
          <div className="half">
            <label>Who do you live with?</label>
            <>
              <select
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                // label="Select"
                // displayEmpty
                onChange={(e) => handleChange("youLiveWith", e)}
                value={fields.youLiveWith || ""}
              >
                <option value={""}>
                 Select
                </option>
                {props?.allRelationData?.length
                  ? props?.allRelationData.map(
                      (item, index) => (
                        <option key={index} value={item.id}>
                          {item.relationshipname}
                        </option>
                      )
                    )
                  : null}
              </select>
            </>
          </div>
          <div className="half">
            <label>Are your parents divorced?</label>
            <>
              <select
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                // label="Select"
                // displayEmpty
                value={fields.parentsDivorced || ""}
                onChange={(e) => handleChange("parentsDivorced", e)}
              >
                <option value={""}>
                  Select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

const FinancialAidAssessment = (props) => {
  const [fields, setFields] = useState({
    yourHeritage: "",
    annualIncome: "",
    familyContribution: "",
    qualifyNSLProgram: "",
  });
  useEffect(() => {
    let collegeInsightDetailsForFinanc =
      props.allRacialEthnicHeritages.data.collegeInsightsData;
    if (
      collegeInsightDetailsForFinanc &&
      collegeInsightDetailsForFinanc.data &&
      collegeInsightDetailsForFinanc.data.Collegeinsights != ""
    ) {
      let fieldsT = fields;
      fieldsT["yourHeritage"] =
        collegeInsightDetailsForFinanc.data.Collegeinsights[0].bestracialorethnicheritage;
      fieldsT["annualIncome"] =
        collegeInsightDetailsForFinanc.data.Collegeinsights[0].annualhouseholdincomeid;
      fieldsT["familyContribution"] =
        collegeInsightDetailsForFinanc.data.Collegeinsights[0].efc;
      fieldsT["qualifyNSLProgram"] =
        collegeInsightDetailsForFinanc.data.Collegeinsights[0]
          .isyourfamilyqualifyanynationalprogram == 1
          ? "Yes" :
          collegeInsightDetailsForFinanc.data.Collegeinsights[0]
              .isyourfamilyqualifyanynationalprogram == 0
          ? 
           "No"
          : "";
      setFields({ ...fieldsT });
    }
  }, [props.allRacialEthnicHeritages.data.collegeInsightsData]);

  useEffect(() => {
    props.financialAidAssessment(fields);
  }, [fields]);

  const handleChange = (field, e) => {
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setFields({ ...fieldsT });
    props.setIsSaved(false);
  };

  return (
    <div className="form-info-box">
      <div className="item">
        <h5 className="title">College Financial Aid Assessment</h5>
        <div className="fieldBox full">
          <label>
            Which of the following best represents your racial or ethnic
            heritage?
          </label>
          <>
            <select
                value={fields.yourHeritage || ""}
                onChange={(e) => handleChange("yourHeritage", e)}
            >
              <option value={""}>Select</option>
              {props.allRacialEthnicHeritages &&
              props.allRacialEthnicHeritages.data &&
              props.allRacialEthnicHeritages.data.allRacialEthnicHeritages &&
              props.allRacialEthnicHeritages.data.allRacialEthnicHeritages.data
                  ? props.allRacialEthnicHeritages.data.allRacialEthnicHeritages.data.map(
                      (item, index) => (
                          <option key={index} value={item.id}>
                            {item.ethnicvalue}
                          </option>
                      )
                  )
                  : null}
            </select>
          </>
          {/*<FormControl variant="outlined" fullWidth>*/}
          {/*  <Select*/}
          {/*    labelId="demo-simple-select-outlined-label"*/}
          {/*    id="demo-simple-select-outlined"*/}
          {/*    value={fields.yourHeritage || ""}*/}
          {/*    label="Select"*/}
          {/*    onChange={(e) => handleChange("yourHeritage", e)}*/}
          {/*    displayEmpty*/}
          {/*  >*/}
          {/*    <MenuItem value="">*/}
          {/*      <em>Select</em>*/}
          {/*    </MenuItem>*/}
          {/*    {props.allRacialEthnicHeritages &&*/}
          {/*    props.allRacialEthnicHeritages.data &&*/}
          {/*    props.allRacialEthnicHeritages.data.allRacialEthnicHeritages &&*/}
          {/*    props.allRacialEthnicHeritages.data.allRacialEthnicHeritages.data*/}
          {/*      ? props.allRacialEthnicHeritages.data.allRacialEthnicHeritages.data.map(*/}
          {/*          (item, index) => (*/}
          {/*            <MenuItem key={index} value={item.id}>*/}
          {/*              {item.ethnicvalue}*/}
          {/*            </MenuItem>*/}
          {/*          )*/}
          {/*        )*/}
          {/*      : null}*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
        </div>
        <div className="fieldBox full">
          <label>
            Which category best describes your annual household income?
          </label>
          <>
            <select
                value={fields.annualIncome || ""}
                onChange={(e) => handleChange("annualIncome", e)}
            >
              <option value={""}>
                Select
              </option>
              {props.allAnnualHouseholdIncome &&
              props.allAnnualHouseholdIncome.data &&
              props.allAnnualHouseholdIncome.data.allAnnualHouseholdIncome &&
              props.allAnnualHouseholdIncome.data.allAnnualHouseholdIncome.data
                  ? props.allAnnualHouseholdIncome.data.allAnnualHouseholdIncome.data.map(
                      (item, index) => (
                          <option key={index} value={item.id}>
                            {item.incomerange}
                          </option>
                      )
                  )
                  : null}
            </select>
          </>
          {/*<FormControl variant="outlined" fullWidth>*/}
          {/*  <Select*/}
          {/*    labelId="demo-simple-select-outlined-label"*/}
          {/*    id="demo-simple-select-outlined"*/}
          {/*    value={fields.annualIncome || ""}*/}
          {/*    label="Select"*/}
          {/*    onChange={(e) => handleChange("annualIncome", e)}*/}
          {/*    displayEmpty*/}
          {/*  >*/}
          {/*    <MenuItem value="">*/}
          {/*      <em>Select</em>*/}
          {/*    </MenuItem>*/}
          {/*    {props.allAnnualHouseholdIncome &&*/}
          {/*    props.allAnnualHouseholdIncome.data &&*/}
          {/*    props.allAnnualHouseholdIncome.data.allAnnualHouseholdIncome &&*/}
          {/*    props.allAnnualHouseholdIncome.data.allAnnualHouseholdIncome.data*/}
          {/*      ? props.allAnnualHouseholdIncome.data.allAnnualHouseholdIncome.data.map(*/}
          {/*          (item, index) => (*/}
          {/*            <MenuItem key={index} value={item.id}>*/}
          {/*              {item.incomerange}*/}
          {/*            </MenuItem>*/}
          {/*          )*/}
          {/*        )*/}
          {/*      : null}*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
        </div>
        <div className="fieldBox full">
          <label>
            What is your expected family contribution (EFC) as calculated by the
            FAFSA? (
            <a
              className="link-red"
              href="https://bigfuture.collegeboard.org/pay-for-college/paying-your-share/expected-family-contribution-calculator"
              target="_blank"
              rel="noreferrer"
            >
              https://bigfuture.collegeboard.org/pay-for-college/paying-your-share/expected-family-contribution-calculator
            </a>
            )
          </label>
          <FormControl variant="outlined" fullWidth>
            <input
              onChange={(e) => handleChange("familyContribution", e)}
              value={fields.familyContribution || ""}
              name="Address"
              id="Address"
              type="text"
              placeholder="FAFSA contribution"
            />
          </FormControl>
        </div>
        <div className="fieldBox full">
          <label>
            Does your family currently qualify for National School Lunch Program
            or other need-based assistance?
          </label>
          <>
            <select
              value={fields.qualifyNSLProgram || ""}
              onChange={(e) => handleChange("qualifyNSLProgram", e)}
            >
              <option value={""}>
                Select
              </option>
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </select>
          </>
        </div>
      </div>
    </div>
  );
};

const FamilyResidence = (props) => {
  const [fields, setFields] = useState({
    sameAthleteAddress: false,
    livesoutsidecanadaorus: false,
    getsearchAddress: "",
    homeCountry: 0,
  });
  const athleteAddress = props.athletesData;
  const [fullAddress, setFullAddress] = useState("");
  const getFullAddress = () => {
    let country = "";
    let state = "";
    let zipcode = "";
    let address = '';
    if (
      props.familyRes &&
      props.familyRes.data &&
      props.familyRes.data.collegeInsightsData &&
      props.familyRes.data.collegeInsightsData.data &&
      props.familyRes.data.collegeInsightsData.data.Collegeinsights &&
      props.familyRes.data.collegeInsightsData.data.Collegeinsights[0]
    ) {
      address =
        props.familyRes.data.collegeInsightsData.data.Collegeinsights[0];
      if (props.allCountries && props.allCountries.data) {
        props.allCountries.data.map((item) => {
          if (item.id == address?.countyid ?? 1) {
            country = item?.countryName;
          }
        });
      }
      if (
        props.familyRes.data.allStates &&
        props.familyRes.data.allStates.data
      ) {
        props.familyRes.data.allStates.data.map((item) => {
          if (item.id == address.state) {
            state = item.statename;
          }
        });
      }
    }
    return address ? `${address?.address ? address.address : ""}${!!address?.address && !!address?.addresscountinue ? "," : ""} 
    ${address?.addresscountinue ? " "+address.addresscountinue : ""}${!!address?.addresscountinue && !!address?.city ? "," : ""}
     ${address?.city ? " "+address?.city : ""}${!!address?.city && !!address?.zipcode ? "," :""}${address?.zipcode ? " "+`${address?.zipcode}` : ''}${!!address?.zipcode && !!state ? "," : ""}
     ${state ? " "+state : ""}${!!state && !!country ? "," : ""}${country? " "+country : ''}` : '';
  };

  useEffect(() => {
    let collegeInsightDetailsForFamilyResidence =
      props.familyRes.data.collegeInsightsData;
    if (
      collegeInsightDetailsForFamilyResidence &&
      collegeInsightDetailsForFamilyResidence.data &&
      collegeInsightDetailsForFamilyResidence.data.Collegeinsights != ""
    ) {
      let fieldsT = fields;
      fieldsT["sameAthleteAddress"] =
        collegeInsightDetailsForFamilyResidence.data.Collegeinsights[0]
          .isfamilyaddandathleteaddsame == 1
          ? true
          : false;
      fieldsT["livesoutsidecanadaorus"] =
        collegeInsightDetailsForFamilyResidence.data.Collegeinsights[0]  
          .livesoutsidecanadaorus == 1
          ? true
          : false;
      fieldsT["homeCountry"] = collegeInsightDetailsForFamilyResidence.data.Collegeinsights[0].outsidecountryid;
      setFields({ ...fieldsT });
    }
  }, [props.familyRes.data.collegeInsightsData]);
  const handleCheckbox = async (field, e) => {
    let fieldsT = fields;
    fieldsT["sameAthleteAddress"] = !fields.sameAthleteAddress;
    setFields({ ...fieldsT });
    if(e.target.checked == false){
      let variables = !!props?.familyRes?.data?.collegeInsightsData?.data?.Collegeinsights[0]?.id
      ? {
        obj: {
            id: props?.familyRes?.data?.collegeInsightsData?.data?.Collegeinsights[0]?.id ?? 0,
            userid: getUserInfo() ? getUserInfo().id : 0,
            address: null,
            addresscountinue: null,
            city: null,
            zipcode: null,
            // efc: "",
            state: null,
            countyid: null,
            isfamilyaddandathleteaddsame: 0,
          }
      } :
          {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : 0,
              address: null,
              addresscountinue: null,
              city: null,
              zipcode: null,
              efc: "",
              state: null,
              countyid: null,
              isfamilyaddandathleteaddsame: 0,
            }
      }
      const res = await fetchGraphMethod(saveCollegeInsightUpdateQuery,variables,true)
      if(res?.status == 200){
        setTimeout(()=>{
          props.getCollegeInsightsData()
          setFullAddress("")
          props.setIsSaved(true);
        },400)
      }else{
     
      }
    }else{
      props.setIsSaved(false);
    }
  };

  const handleOutsideUSCanada = () => {
    let fieldsT = fields;
    fieldsT["livesoutsidecanadaorus"] = !fields.livesoutsidecanadaorus;
    //empty the field on chack and uncheck $
    fieldsT['homeCountry'] = null
    setFields({ ...fieldsT });
    props?.setIsSaved(false);
  };
  //this func is used only for selecting country
  const handleSelect = (e) => {
    let fieldsT = fields;
    fieldsT["homeCountry"] = parseInt(e.target.value);
    setFields({ ...fieldsT });
    props.setIsSaved(false);
  };
  const getAddress = (value) => {
    let fieldsT = fields;
    fieldsT["getsearchAddress"] = value;
    setFields({ ...fieldsT });
    setFullAddress(fields["getsearchAddress"].fullAddress());
    props.setIsSaved(false);
  };

  useEffect(() => {
    props.familyResidence(fields);
  }, [fields]);
  const getAthleteCountry = (data) =>{
  const courID = +data?.homecountryid ?? 1
     if(props.allCountries.data?.length > 0){
       const filData = props?.allCountries?.data?.filter(el => el?.id == courID)
       return filData?.[0]?.countryName
     }else{
       return "United State"
     }
 }
  return (
    <div className="form-info-box">
      <div className="item">
        <h5 className="title">Family Residence</h5>
        <div className="fieldBox full">
          <p className="checkbox-list">
            <span>
              <input
                type="checkbox"
                checked={fields.sameAthleteAddress || ""}
                onChange={(e) => handleCheckbox("sameAthleteAddress", e)}
                //this is used for toggle on same athlete address
                onClick={()=> props?.setIsAddressSame(!props?.isAddressSame)}
              />
              <label>Same as Athlete Address</label>
            </span>
          </p>
        </div>
        <div className="fieldBox full">
          <label>Home Address</label>
          <div className={"searchLocation"}>
            <AutoCompleteCitySearch
              addressDetails={(e)=>getAddress(e)}
              disabled={fields.sameAthleteAddress || ""}
            />
          </div>
          {fields["sameAthleteAddress"] == false ? (
            fullAddress ? (
              <div className="shortTextBox">
                <p>
                  {fullAddress ? fullAddress : ''}
                </p>
              </div>
            ) : getFullAddress().trim() ? (
              <div className="shortTextBox">
              <p
              >
                {getFullAddress() ? getFullAddress() : ''}
              </p>
              </div>
            ) : (
              ""
            )
          ) : (
              <div className="shortTextBox">
            <p>
              {athleteAddress && athleteAddress.address
                ? athleteAddress.address + ", "
                : ""}
              {athleteAddress && athleteAddress.addresscountinue
                ? athleteAddress.addresscountinue + ", "
                : ""}
              {athleteAddress && athleteAddress.homezipcode
                ? athleteAddress.homezipcode + ", "
                : ""}
              {athleteAddress && athleteAddress.city
                ? athleteAddress.city + ", "
                : ""}
              {!!athleteAddress?.athletezipcode ? `${athleteAddress?.athletezipcode || ""},` : ""}
              {athleteAddress &&
              athleteAddress.athletestateidmaprel &&
              athleteAddress.athletestateidmaprel.States &&
              athleteAddress.athletestateidmaprel.States[0] &&
              athleteAddress.athletestateidmaprel.States[0].statename
                ? athleteAddress.athletestateidmaprel.States[0].statename + ", "
                : ""}
              {athleteAddress && !!athleteAddress?.homecountryid ? getAthleteCountry(athleteAddress) :
              athleteAddress.athleteathletecountrycodeidmaprel &&
              athleteAddress.athleteathletecountrycodeidmaprel.Countries &&
              athleteAddress.athleteathletecountrycodeidmaprel.Countries[0]
                ?.countryname
                ? athleteAddress.athleteathletecountrycodeidmaprel.Countries[0]
                    ?.countryname
                : ""}
            </p>
            </div>
          )}
        </div>
        <div className="fieldBox full">
          <p className="checkbox-list">
            <span>
              <input
                type="checkbox"
                checked={fields.livesoutsidecanadaorus || ""}
                onChange={(e) => handleOutsideUSCanada("livesoutsidecanadaorus", e)}
              />
              <label>Check if you live outside the US or Canada</label>
            </span>
          </p>
        </div>
        {fields.livesoutsidecanadaorus == true ? (
          <div className="fieldBox full">
            <FormLabel>Country</FormLabel>
            <FormControl variant="outlined" fullWidth>
              <select
                // labelId="demo-simple-select-outlined-label"
                // id="countries"
                value={fields.homeCountry || ""}
                name="countries"
                onChange={handleSelect}
                // label="Select"
                // displayEmpty
              >
                {/*<MenuItem value="">*/}
                {/*  <em>Select</em>*/}
                {/*</MenuItem>*/}
                <option value={""}>Select</option>
                {props.allCountries.data &&
                  props.allCountries.data.map((item, index) => {
                        if ((item?.id != 1) && (item?.id != 2)) {
                          return <option key={index} value={item.id}>
                            {item?.countryName}
                          </option>
                          // <MenuItem key={index} value={item.id}>
                          //   {item?.countryName}
                          // </MenuItem>
                        }
                      })}
              </select>
            </FormControl>
          </div>
        ) : null}
        <div className="fieldBox full">
          <button
            onClick={(e) => props.handleAddAddress(e)}
            className="btn green"
            disabled={fields.sameAthleteAddress}
          >
            Add Address Manually
          </button>
        </div>
      </div>
    </div>
  );
};

const ManualAddress = (props) => {
  const [fields, setFields] = useState({
    Address: "",
    addressContinued: "",
    city: "",
    zipcode: "",
    state: "",
    countries: "",
  });
  const [countryData, setCountryData] = useState([]);
  const [statesList, setStatesList] = useState(null),
        [isZipValid, setIsZipVal] = useState(true);

  useEffect(() => {
    if (props.athleteAddress && props.athleteAddress.data) {
      let serchStateId = "";
      let countryId = "";
      if (!!props.searchAddress) {
        serchStateId = props?.allStates?.data?.allStates?.data?.length > 0
            ? props.allStates.data.allStates.data.filter((item) => {
                let itemState = item?.initials ? item.initials.toLowerCase() : null;
                let valueState = props.searchAddress.state.toLowerCase();
                if (itemState == valueState || valueState.includes(itemState)) {
                  return item;
                }
              })
            : "";
        countryId =
          props.allCountries &&
          props.allCountries.data &&
          props.allCountries.data.allCountries &&
          props.allCountries.data.allCountries.data
            ? props.allCountries.data.allCountries.data.filter(
                (item) =>
                  item?.countryName.toLowerCase() ==
                  props.searchAddress.country.toLowerCase()
              )
            : "";
      }
      const fieldsT = fields;
      const { athletesData } = props.athleteAddress.data;
      const collegeInssightData = props.allStates.data.collegeInsightsData.data
        ? props.allStates.data.collegeInsightsData.data.Collegeinsights
        : "";
      if (!props.searchAddress) { 
        fieldsT["Address"] =      
          props.athleteAndFamilyAddress == true
            ? athletesData.address
              ? athletesData.address
              : ""
            : collegeInssightData != ""
            ? collegeInssightData[0].address
              ? collegeInssightData[0].address
              : props.searchAddress
              ? props.searchAddress.streetNumber +
                " " +
                props.searchAddress.route
              : ""
            : "";
        fieldsT["addressContinued"] =
          props.athleteAndFamilyAddress == true
            ? athletesData.addresscountinue
              ? athletesData.addresscountinue
              : ""
            : collegeInssightData != ""
            ? collegeInssightData[0].addresscountinue
              ? collegeInssightData[0].addresscountinue
              : props.searchAddress
              ? props.searchAddress.street
              : ""
            : "";
        fieldsT["city"] =
          props.athleteAndFamilyAddress == true
            ? athletesData.city
              ? athletesData.city
              : props.searchAddress != ""
              ? props.searchAddress.city
              : ""
            : collegeInssightData != ""
            ? collegeInssightData[0].city
              ? collegeInssightData[0].city
              : props.searchAddress
              ? props.searchAddress.city
              : ""
            : "";
        fieldsT["zipcode"] =
          props.athleteAndFamilyAddress == true
            ? athletesData.homezipcode
              ? athletesData.homezipcode
              : props.searchAddress != ""
              ? props.searchAddress.zip
              : ""
            : collegeInssightData != ""
            ? collegeInssightData[0].zipcode
              ? collegeInssightData[0].zipcode
              : props.searchAddress
              ? props.searchAddress.zip
              : ""
            : "";
        fieldsT["state"] =
          props.athleteAndFamilyAddress == true
            ? athletesData.stateid
              ? athletesData.stateid
              : ""
            : collegeInssightData != ""
            ? collegeInssightData[0].state
              ? parseInt(collegeInssightData[0].state)
              : props.searchAddress
              ? serchStateId[0]
                ? serchStateId[0].id
                : ""
              : ""
            : "";
        fieldsT["countries"] = props.athleteAndFamilyAddress === true 
               ? athletesData.homecountryid 
                ? athletesData.homecountryid
                : ''
                : collegeInssightData && collegeInssightData[0] && collegeInssightData[0].countyid 
                ? parseInt(collegeInssightData[0].countyid)
                : props.searchAddress 
                ? countryId[0] 
                 ? countryId[0].id
                 : null
                 : null;
      } else {
        fieldsT["Address"] = props.searchAddress
          ? props.searchAddress.streetNumber + " " + props.searchAddress.route
          : "";
        fieldsT["addressContinued"] = props.searchAddress
          ? props.searchAddress.street
          : "";
        fieldsT["city"] = props.searchAddress ? props.searchAddress.city : "";
        fieldsT["zipcode"] = props.searchAddress ? props.searchAddress.zip : "";
        fieldsT["state"] = props.searchAddress
          ? serchStateId[0]
            ? serchStateId[0].id
            : ""
          : "";
          fieldsT["countries"] = props.athleteAndFamilyAddress === true 
          ? athletesData.homecountryid 
           ? athletesData.homecountryid
           : ''
           : collegeInssightData && collegeInssightData[0] && collegeInssightData[0].countyid 
           ? parseInt(collegeInssightData[0].countyid)
           : props.searchAddress 
           ? countryId[0] 
            ? countryId[0].id
            : null
            : null;
      }
      // let countyryIdData = props?.allCountries?.data?.allCountries?.data?.length > 0 ? props?.allCountries?.data?.allCountries?.data.filter(item=>item.id === athletesData.homecountryid) : athletesData.athleteathletecountrycodeidmaprel.Countries ;
      //this used for showing the selected country ealier it was not coming
      if((!!collegeInssightData)){
            const id = !!collegeInssightData?.[0]?.countyid ? collegeInssightData?.[0]?.countyid : 1 ,
                countryID = props?.allCountries?.data?.allCountries?.data?.filter(el => el.id == id)
            setCountryData(countryID?.length > 0 ? countryID : [])
      }
      // setCountryData(countyryIdData)
      setFields({ ...fieldsT });
      props.onAddressChange(fieldsT);
    }
  }, [
    props.athleteAddress,
    props.searchAddress,
    props.athleteAndFamilyAddress,
      //Earlier it was collegeInsightsData which is not a correct path below
    props.allStates.data.allStates,
  ]);
  useEffect(() => {
    props.onAddressChange(fields);
  }, [fields]);

  useEffect(()=>{
    if(!props?.isAddressSame){
      setFields({
        Address: "",
        addressContinued: "",
        city: "",
        zipcode: "",
        state: "",
        countries: "",
      })
    }
  },[props?.isAddressSame])

const getCountryData = value => {
  let countryid = props?.allStates?.data?.allStates?.data?.length > 0 ? props?.allStates?.data?.allStates?.data?.filter(item => parseFloat(item.id) === parseFloat(value)) : null;
  let countryData = countryid?.length > 0 ? props?.allCountries?.data?.allCountries?.data.filter(item=>item.id === parseFloat(countryid[0].countryid)):'';
  const fieldsT = fields;
  fieldsT['countries'] = parseFloat(countryData[0]?.id);
  setFields({ ...fieldsT });
  setCountryData(countryData)
}

  useEffect(()=> {
    //this check below is not able to set the state
    // if(fields.countries ){
    const dependentStates =  props.allStates &&
    props.allStates.data &&
    props.allStates.data.allStates &&
    props.allStates.data.allStates.data
      ? props.allStates.data.allStates.data : "";
      setStatesList([...dependentStates]);
    // }
  }, [props.allStates.data])

  const handleChange = (field, e) => {
    if(field === 'state'){
      let fieldsT = fields;
      fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
        const countryId = 
        props.allStates &&
            props.allStates.data &&
            props.allStates.data.allStates &&
            props.allStates.data.allStates.data
              ? props.allStates.data.allStates.data.filter((item)=> (
                fields.state === item.id
              )): "";
              fieldsT["countries"] = countryId && countryId[0] && countryId[0].countryid ? countryId[0].countryid :(e ? (e.target ? e.target.value : e) : e);
              setFields({
                ...fieldsT,
              });
      props.onAddressChange(fieldsT);
      props.setIsSaved(false);
      getCountryData(e.target.value);
    }
    else{
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
            if(fields.countries ){
              const dependentStates =  props.allStates &&
            props.allStates.data &&
            props.allStates.data.allStates &&
            props.allStates.data.allStates.data
              ? props.allStates.data.allStates.data : "";
              setStatesList([...dependentStates]);
              }
            setFields({
              ...fieldsT,
            });

    props.onAddressChange(fieldsT);
    props.setIsSaved(false);
      setIsZipVal(true)
  }
  };

  const validationHomeAdd = () =>{
    if(!!fields?.zipcode){
      if(!isValidZipcode(fields?.zipcode,countryData?.[0]?.countryShortName ?? "US")){
        setIsZipVal(false)
        return false
      }else{
        setIsZipVal(true)
        return true
      }
    }else{
      setIsZipVal(true)
      return true
    }
  }

  const handleSaveAddress = (e) => {
    e.preventDefault();
    const statusSave = validationHomeAdd()
    if (statusSave){
      props.saveAddress();
    }
  };
  return (
    <div>
      {props.athleteAndFamilyAddress === false
        ? props.addAddress && (
            <div className="address-box-wrap">
              <div className="fieldBox flexBox">
                <div className="half">
                  <label>Address</label>
                  <input
                    onChange={(e) => handleChange("Address", e)}
                    disabled={
                      props.athleteAndFamilyAddress == true ? true : false
                    }
                    value={fields.Address || ""}
                    name="Address"
                    id="Address"
                    type="text"
                    placeholder="Address"
                  />
                </div>
                <div className="half">
                  <label>Address Continued</label>
                  <input
                    onChange={(e) => handleChange("addressContinued", e)}
                    disabled={
                      props.athleteAndFamilyAddress == true ? true : false
                    }
                    value={fields.addressContinued || ""}
                    name="addressContinued"
                    type="text"
                    placeholder="Address Continued"
                  />
                </div>
              </div>
              <div className="fieldBox flexBox">
                <div className="half">
                  <label>City</label>
                  <input
                    onChange={(e) => handleChange("city", e)}
                    disabled={
                      props.athleteAndFamilyAddress == true ? true : false
                    }
                    value={fields.city}
                    name="city"
                    id="city"
                    type="text"
                    placeholder="city"
                  />
                </div>
                <div className="half">
                  <label>ZIP code</label>
                  <input
                    onChange={(e) => handleChange("zipcode", e)}
                    disabled={
                      props.athleteAndFamilyAddress == true ? true : false
                    }
                    value={fields.zipcode || ""}
                    name="zipcode"
                    id="zipcode"
                    type="text"
                    placeholder="ZIP code"
                  />
                  <span style={{color:"red"}}>
                    {isZipValid ? "" : <p>Please Enter Valid Zipcode</p>}
                  </span>
                </div>
              </div>
              <div className="fieldBox flexBox">
                <div className="half">
                  <label>State / Province</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      // labelId="demo-simple-select-outlined-label"
                      // id="demo-simple-select-outlined"
                      value={fields.state || ""}
                      disabled={
                        props.athleteAndFamilyAddress == true ? true : false
                      }
                      onChange={(e) => handleChange("state", e)}
                      // label="Select"
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>

                      {statesList ? statesList.map(
                            (item, index) => (
                              <MenuItem key={index} value={item.id}>
                                {item.statename}
                              </MenuItem>
                            )
                          )
                        : null}
                    </Select>
                  </FormControl>
                </div>
                <div className="half">
                <label>Country</label>
                              <select
                                value={fields.countries || ''}
                                name="countries"
                                onChange={(e) => handleChange("countries", e)}
                                label="Select"
                                disabled={
                                  props.athleteAndFamilyAddress == true ? true : false
                                }
                              >
                                <option value="">Select</option>
                                {countryData?.length > 0 ?
                                   countryData?.map((item, index) => {
                                       return <option key={index} value={item.id}>
                                          {item?.countryname || item?.countryName}
                                        </option>
                                   }
                                    ): null}
                              </select>
                </div>
              </div>
              <div className="form-button-box">
                <button
                  disabled={
                    props.athleteAndFamilyAddress == true ? true : false
                  }
                  onClick={(e) => handleSaveAddress(e)}
                  className="btn green"
                >
                  Save
                </button>
              </div>
            </div>
          )
        : null}
    </div>
  );
};

const EditSection = (props) => { 
  const [familyData, setFamilyData] = useState([]);
  const [financialAidData, setFinancialAidData] = useState([]);
  const [familyResidenceData, setFamilyResidenceData] = useState([]);
  const [addAddress, setAddAddress] = useState(false);
  const [manualAddressData, setManualAddress] = useState([]);
  const [collegeInsightData, setCollegeInsightData] = useState([]);
  const [isAddressSame, setIsAddressSame] = useState(false);
  const [fields, setFields] = useState();
  const familyBG = (familyValue) => {
    setFamilyData(familyValue);
  };
  const financialAidAssessment = (financialValue) => {
    setFinancialAidData(financialValue);
  };
  const familyResidence = (familyResidence) => {
    setFamilyResidenceData(familyResidence);
  };
  const manualAddress = (manualAddressValue) => {
    setManualAddress(manualAddressValue);
  };
  useEffect(() => {
    props.onChangeData({
      fields,
      familyResidenceData,
      financialAidData,
      familyData,
    });
  }, [fields, familyResidenceData, financialAidData, familyData]);
  const handleAddAddress = (e) => {
    e.preventDefault();
    setAddAddress(!addAddress);
  };

  const handleSaveCollegeInsights = (e) => {
    e.preventDefault();
    props.onSaveData();
  };

  const handleUpdateCollegeInsights = (e, collegeInsightsId) => {
    e.preventDefault();
    props.onSaveData();
  };
  const onAddressChange = (address) => {
    setFields(address);
    props.onChangeData({
      fields: address,
      familyResidenceData,
      financialAidData,
      familyData,
    });
  };
  const saveAddress = () => {
    props.onSaveAddress();
  };
  const toggleOnChange = (e) => {
    props.onSavePrivacyToggle(e.target.checked)
  }
  return (
    <div className="editSection">
      <Title />
      <div className="check-list-sec">
        <div className="editprofile-common-form">
          <form>
            <div className="collage-insight-help">
              <div className="help-message-box">
                <p>This information can help college coaches project potential financial aid packages.</p>
              </div>
              <div className="college-switch-button">
                <span className="switch-button">
                  <label>{ props.privacyToggle ? "Viewable by verified college coaches/scouts" : "Private (Nobody can view)" }</label> 
                  <input className="form-check-input" checked={props.privacyToggle} type="checkbox" onChange={e => toggleOnChange(e)} />
                </span>
              </div>
            </div>
            <FamilyBG
              Relationships={props}
              familyBG={familyBG}
              setIsSaved={props.setIsSaved}
              allRelationData={props.allRelationData}
            />
            <FinancialAidAssessment
              allRacialEthnicHeritages={props}
              allAnnualHouseholdIncome={props}
              financialAidAssessment={financialAidAssessment}
              setIsSaved={props.setIsSaved}
            />
            <FamilyResidence
              handleAddAddress={handleAddAddress}
              familyResidence={familyResidence}
              setIsAddressSame = {setIsAddressSame}
              isAddressSame = {isAddressSame}
              familyRes={props}
              getCollegeInsightsData={props.getCollegeInsightsData}
              allCountries={props.data.allCountries}
              setIsSaved={props.setIsSaved}
              athletesData={
                props.data &&
                props.data.athleteData &&
                props.data.athleteData.data &&
                props.data.athleteData.data.athletesData
                  ? props.data.athleteData.data.athletesData
                  : ""
              }
            />
            <ManualAddress
              setIsSaved={props.setIsSaved}
              addAddress={addAddress}
              onAddressChange={onAddressChange}
              isAddressSame= { isAddressSame }
              allStates={props}
              allCountries={props}
              athleteAddress={
                props.data && props.data.athleteData
                  ? props.data.athleteData
                  : ""
              }
              manualAddress={manualAddress}
              saveAddress={saveAddress}
              country={
                familyResidenceData ? familyResidenceData.homeCountry : 1
              }
              searchAddress={
                familyResidenceData ? familyResidenceData.getsearchAddress : ""
              }
              athleteAndFamilyAddress={
                familyResidenceData
                  ? familyResidenceData.sameAthleteAddress
                  : ""
              }
            />
            <div className="form-button-box with-next-prev">
              {props.data.collegeInsightsData &&
              props.data.collegeInsightsData.data &&
              props.data.collegeInsightsData.data.Collegeinsights == "" ? (
                <button
                  onClick={(e) => {
                    handleSaveCollegeInsights(e);
                  }}
                  className="btn blue"
                >
                  Save
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    handleUpdateCollegeInsights(
                      e,
                      props.data.collegeInsightsData &&
                        props.data.collegeInsightsData.data &&
                        props.data.collegeInsightsData.data.Collegeinsights ==
                          ""
                        ? parseInt(
                            props.data.collegeInsightsData.data
                              .Collegeinsights[0].id
                          )
                        : 0
                    );
                  }}
                  className="btn blue"
                >
                  Save
                </button>
              )}
              <div className="next-prev-button">
                <ScreenSwitch
                  prevLink={"/press"}
                  nextLink={"/Offers"}
                  isSaved={props.isSaved}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const CollegeInsights = (props) => {
  const [sideMenuOpen, setSideMenu] = useState(false);
  const [collegeInsightId, setcollegeInsightId] = useState(0);
  const [collegeInsightData, setCollegeInsightData] = useState();
  const [isSaved, setIsSaved] = useState(true);
  const fakeInput = useRef(null);
  const [loader, setLoader] = useState(false)
  const [privacyToggle, setPrivacyToggle] = useState(false);
  const [allRelation, setAllRelation] = useState([]);
  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };
  //forprogress bar
  const dispatch = useDispatch()
  useEffect(() => {
    if (
      props.collegeInsightsData &&
      props.collegeInsightsData.data &&
      props.collegeInsightsData.data.Collegeinsights
    ) {
      let collegeInsight = props.collegeInsightsData.data;
      setPrivacyToggle(collegeInsight.Collegeinsights!= undefined && collegeInsight.Collegeinsights != ""? collegeInsight.Collegeinsights[0]['isviewable'] ? true : false : false)
      if (collegeInsight != null && collegeInsight.Collegeinsights != "") {
        setcollegeInsightId(
          collegeInsight.Collegeinsights
            ? collegeInsight.Collegeinsights[0].id
            : 0
        );
      }
    }
  }, [props.collegeInsightsData]);

  useEffect(() => {
    props.relationshipListStart();
    props.annualHouseholdIncomeListStart({
      query: getAllAnnualhouseholdincomes,
      variables: null,
    });
    props.racialEthnicHeritageListStart({
      query: getAllRacialethnicheritages,
      variables: null,
    });
    props.stateListStart({ query: getAllStatesQuery });
    props.countryListStart();
    if (!props.athleteData.data) {
      props.athleteListStart({
        query: getAllAthletesDataQuery,
        variables: {
          obj: {
            id: getUserInfo() ? getUserInfo().id : 0,
          },
        },
      });
    }
    getAthleteadd();
    getAllRelations();
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);
  const getAthleteadd = () => {
    props.collegeInsightsListStart({
      query: getAllCollegeInsightsQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  };

  const getAllRelations = async () => {
    await fetchGraphMethod(getAllRelationshipsQuery,{obj: {}},true).then(res=>{ 
      if(res.status == 200){
        let relationshipData = res?.data?.data?.allRelationships?.Relationships;
        relationshipData.sort((a,b) => a.priority - b.priority);
        setAllRelation(relationshipData)
      }
    })
  }

  const routeNextPage = (value) => {
    getAthleteadd();
    swal({
      text: `${value} Updated Successfully.`,
      icon: "success",
      }).then((okay) => {
      dispatch({type:"RENDER_COUNT_START", payload: 1})
        fakeInput.current.focus();
        fakeInput.current.select();
        getAthleteadd()
        window?.location?.reload()
      // props?.history?.push('/collegeInsights')
      //   window.location.href = '/#/collegeInsights';
    });
    };
  const onChangeData = (data) => {
    setCollegeInsightData(data);
  };

  const onSaveAddress = () => {
    const { fields, familyResidenceData } = collegeInsightData;
    if (!collegeInsightId) {
      props.collegeInsightssaveStart({
        query: saveCollegeInsightUpdateQuery,
        variables: {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            address: fields.Address == "" ? null : fields.Address,
            addresscountinue:
              fields.addressContinued == "" ? null : fields.addressContinued,
            city: fields.city == "" ? null : fields.city,
            zipcode: fields.zipcode == "" ? null : fields.zipcode,
            //this below key is commented as per the bug
            // efc: "",
            state: fields.state == "" ? null : parseInt(fields.state),
            countyid:
              fields.countries == "" ? null : parseInt(fields.countries),
            isfamilyaddandathleteaddsame: familyResidenceData.sameAthleteAddress
              ? 1
              : 0,
          },
        },
        nextScreen: routeNextPage('Address'),
      });
    } else {
      props.collegeInsightssaveStart({
        query: saveCollegeInsightUpdateQuery,
        variables: {
          obj: {
            id: collegeInsightId,
            userid: getUserInfo() ? getUserInfo().id : 0,
            address: fields.Address == "" ? null : fields.Address,
            addresscountinue:
              fields.addressContinued == "" ? null : fields.addressContinued,
            city: fields.city == "" ? null : fields.city,
            zipcode: fields.zipcode == "" ? null : String(fields.zipcode),
            //this below key is commented as per the bug
            // efc: "",
            state: fields.state == "" ? null : parseInt(fields.state),
            countyid:
              fields.countries == "" ? null : parseInt(fields.countries),
            isfamilyaddandathleteaddsame: familyResidenceData.sameAthleteAddress
              ? 1
              : 0,
          },
        },
        nextScreen: routeNextPage('Address'),
      });
    }
    setIsSaved(true)
  };
  const onSaveData = () => {
    const { familyData, financialAidData, familyResidenceData, fields } =
    collegeInsightData;
    // return;
    const { athletesData } = props.athleteData.data;
    const collegeInsightAddr =
      props.collegeInsightsData &&
      props.collegeInsightsData.data &&
      props.collegeInsightsData.data.Collegeinsights &&
      props.collegeInsightsData.data.Collegeinsights[0]
        ? props.collegeInsightsData.data.Collegeinsights[0]
        : "";
    if (collegeInsightId == 0) {
      props.collegeInsightssaveStart({
        query: saveCollegeInsightUpdateQuery,
        variables: {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            haveanysiblingswhogonetocollege:
              familyData.haveOlderSiblings == ""
                ? null
                : familyData.haveOlderSiblings == "Yes"
                ? 1
                : 0,
            influentialdecision:
              familyData.mostInfluential == ""
                ? null
                : parseInt(familyData.mostInfluential),
            isparentdivorced:
              familyData.parentsDivorced == ""
                ? null
                : familyData.parentsDivorced == "Yes"
                ? 1
                : 0,
            wholiveswithyou:
              familyData.youLiveWith == ""
                ? null
                : parseInt(familyData.youLiveWith),
            efc: "",
            annualhouseholdincomeid:
              financialAidData.annualIncome == ""
                ? null
                : parseInt(financialAidData.annualIncome),
            bestracialorethnicheritage:
              financialAidData.yourHeritage == ""
                ? null
                : parseInt(financialAidData.yourHeritage),
            isyourfamilyqualifyanynationalprogram:
            financialAidData.qualifyNSLProgram == ""
                ? null
                : financialAidData.qualifyNSLProgram == "Yes"
                ? 1
                : 0,
            isfamilyaddandathleteaddsame:
              familyResidenceData.sameAthleteAddress == ""
                ? null
                : familyResidenceData.sameAthleteAddress == true
                ? 1
                : 0,
            efc: financialAidData.familyContribution
              ? financialAidData.familyContribution
              : "",
            livesoutsidecanadaorus:
              familyResidenceData.livesoutsidecanadaorus == ""
                ? null
                : familyResidenceData.livesoutsidecanadaorus == true
                ? 1
                : 0,
            address: familyResidenceData.sameAthleteAddress
              ? athletesData.address
              : fields.Address == ""
              ? collegeInsightAddr.address
              : fields.Address,
            addresscountinue: familyResidenceData.sameAthleteAddress
              ? athletesData.addresscountinue
              : fields.addressContinued == ""
              ? collegeInsightAddr.addresscountinue
              : fields.addressContinued,
            city: familyResidenceData.sameAthleteAddress
              ? athletesData.city
              : fields.city == ""
              ? collegeInsightAddr.city
              : fields.city,
            zipcode: familyResidenceData.sameAthleteAddress
              ? athletesData.athletezipcode
              : fields.zipcode == ""
              ? collegeInsightAddr.zipcode
              : String(fields.zipcode),
            state: familyResidenceData.sameAthleteAddress
              ? athletesData.stateid
              : fields.state == ""
              ? collegeInsightAddr.state
              : parseInt(fields.state),
            countyid: familyResidenceData.sameAthleteAddress
              ? (familyResidenceData.homeCountry == 0 ? null : familyResidenceData.homeCountry)
              : !!fields.countries
              ? collegeInsightAddr?.countyid ?? null
              : parseInt(fields.countries),
              outsidecountryid: familyResidenceData && 
              familyResidenceData.homeCountry ? familyResidenceData.homeCountry : null
          },
        },
        nextScreen: routeNextPage('College Insights'),
      });
    } else {
      props.collegeInsightssaveStart({
        query: saveCollegeInsightUpdateQuery,
        variables: {
          obj: {
            id: collegeInsightId,
            userid: getUserInfo() ? getUserInfo().id : 0,
            haveanysiblingswhogonetocollege:
              familyData.haveOlderSiblings == ""
                ? null
                : familyData.haveOlderSiblings == "Yes"
                ? 1
                : 0,
            influentialdecision:
              familyData.mostInfluential == ""
                ? null
                : parseInt(familyData.mostInfluential),
            isparentdivorced:
              familyData.parentsDivorced == ""
                ? null
                : familyData.parentsDivorced == "Yes"
                ? 1
                : 0,
            wholiveswithyou:
              familyData.youLiveWith == ""
                ? null
                : parseInt(familyData.youLiveWith),
            efc: "",
            annualhouseholdincomeid:
              financialAidData.annualIncome == ""
                ? null
                : parseInt(financialAidData.annualIncome),
            bestracialorethnicheritage:
              financialAidData.yourHeritage == ""
                ? null
                : parseInt(financialAidData.yourHeritage),
            isyourfamilyqualifyanynationalprogram:
            financialAidData.qualifyNSLProgram == ""
                ? null
                : financialAidData.qualifyNSLProgram == "Yes"
                ? 1
                : 0,
            isfamilyaddandathleteaddsame:
              familyResidenceData.sameAthleteAddress == ""
                ? null
                : familyResidenceData.sameAthleteAddress == true
                ? 1
                : 0,
            livesoutsidecanadaorus:
              familyResidenceData.livesoutsidecanadaorus == ""
                ? null
                : familyResidenceData.livesoutsidecanadaorus == true
                ? 1
                : 0,
            address: familyResidenceData.sameAthleteAddress
              ? athletesData.address
              : fields.Address == ""
              ? collegeInsightAddr.address
              : fields.Address,
            addresscountinue: familyResidenceData.sameAthleteAddress
              ? athletesData.addresscountinue
              : fields.addressContinued == ""
              ? collegeInsightAddr.addresscountinue
              : fields.addressContinued,
            city: familyResidenceData.sameAthleteAddress
              ? athletesData.city
              : fields.city == ""
              ? collegeInsightAddr.city
              : fields.city,
            zipcode: familyResidenceData.sameAthleteAddress
              ? athletesData.athletezipcode
              : fields.zipcode == ""
              ? collegeInsightAddr.zipcode
              : String(fields.zipcode),
            efc: financialAidData.familyContribution
              ? financialAidData.familyContribution
              : "",
            state: familyResidenceData.sameAthleteAddress
              ? athletesData.stateid
              : fields.state == ""
              ? collegeInsightAddr.state
              : parseInt(fields.state),
            countyid: familyResidenceData.sameAthleteAddress
              ? (familyResidenceData?.homeCountry == 0 ? null : familyResidenceData?.homeCountry)
              : fields.countries == ""
              ? collegeInsightAddr.countyid
              : parseInt(fields.countries),
            outsidecountryid: familyResidenceData && 
              familyResidenceData.homeCountry ? familyResidenceData.homeCountry : null
          },
        },
        nextScreen: routeNextPage('College Insights'),
      });
    }
    //this is for stay and leave popup up
    setIsSaved(true)
  };
  const onSavePrivacyToggle = async (privacyValue) => {
    setPrivacyToggle(privacyValue)
    if (!collegeInsightId) {
      await fetchGraphMethod(saveCollegeInsightUpdateQuery,{obj: {
        userid: getUserInfo() ? getUserInfo().id : 0,
        isviewable: privacyValue ? 1 : 0
      }},true).then(res=>{ })
    } else {
      await fetchGraphMethod(saveCollegeInsightUpdateQuery,{obj: {
        id: collegeInsightId,
        userid: getUserInfo() ? getUserInfo().id : 0,
        isviewable: privacyValue ? 1 : 0
      }},true).then(res=>{ })
    }
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  return (
    <div className="pageContent">
      <div className="editProfile">
        <input className="fakeInput" type="text" ref={fakeInput} />
        <BackdropLoader open={props.collegeInsightsData.loading || loader} />
        <div className="container">
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
          >
            <SubHeader hamburger={onSideMenuClick} onSave={onSaveData} />
            <div className="sidebar">
              <LeftSideEditProfile isSaved={isSaved} />
            </div>
            <EditSection
              data={props}
              getCollegeInsightsData={getAthleteadd}
              collegeInsightsId={collegeInsightId}
              onSaveData={onSaveData}
              onChangeData={onChangeData}
              onSaveAddress={onSaveAddress}
              onSavePrivacyToggle={onSavePrivacyToggle}
              privacyToggle={privacyToggle}
              isSaved={isSaved}
              setIsSaved={setIsSaved}
              allRelationData={allRelation}
            />
          </div>
        </div>
        <GoTopButton goTop={goTop} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allRelationships: state.common.relationships,
    allRacialEthnicHeritages: state.common.racialEthnicHeritages,
    allAnnualHouseholdIncome: state.common.annualHouseholdIncomes,
    allStates: state.common.states,
    allCountries: state.common.countries,
    collegeInsightsData:
      state.athlete.editUserProfileData.collegeInsightsUserProfile,
    athleteData: state.common.athletesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    relationshipListStart: (data) => dispatch(relationshipListStart(data)),
    annualHouseholdIncomeListStart: (data) =>
      dispatch(annualHouseholdIncomeListStart(data)),
    racialEthnicHeritageListStart: (data) =>
      dispatch(racialEthnicHeritageListStart(data)),
    collegeInsightssaveStart: (data) =>
      dispatch(collegeInsightssaveStart(data)),
    collegeInsightsListStart: (data) =>
      dispatch(collegeInsightsListStart(data)),
    stateListStart: (data) => dispatch(stateListStart(data)),
    countryListStart: (data) => dispatch(countryListStart(data)),
    athleteListStart: (data) => dispatch(athletesDetailsStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollegeInsights);
