export default [
  {
    conferenceLogo: 'ivy-league-logo.png',
    text: `Your program is a HUGE help because we can’t see every potential recruit nationwide that might be a great fit for us.
          Your program gives us the type of scouting reports, videos, & metrics we need.`,
    coachPositionText: 'NCAA DI - Head Coach',
    coachConferenceText: '',
  },
  {
    conferenceLogo: 'ncaa-logo.png',
    text: `The SportsForce platform makes it easy for me to watch quality video and get verified stats on players which saves me 
          time so I can focus on the right kids to recruit.`,
    coachPositionText: 'NCAA DII - Head Coach',
    coachConferenceText: '',
  },
  {
    conferenceLogo: 'pac12-logo.png',
    text: `I trust and listen to your coaching staff’s recommendations because they are honest, accurate, and the players you recommend 
          are the right type of kids for our program.`,
    coachPositionText: 'NCAA DI - Recruiting Coordinator',
    coachConferenceText: 'PAC 12',
  },
  {
    conferenceLogo: 'ncaa-logo.png',
    text: `Having all the video, stats, grades, contact info, and academic transcript in ONE PLACE makes our job much easier to recruit 
          the athletes on your platform.`,
    coachPositionText: 'NCAA DIII - Head Coach',
    coachConferenceText: '',
  },
];
