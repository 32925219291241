export const getAllSchoolCurrentLevelQuery = `
  query allSchoolcurrentlevels($obj: JSON){
    allSchoolcurrentlevels(where: $obj){
        Schoolcurrentlevels{
            id
            currentlevelvalue
            ishighschool
        }
    }
  } 
  `;
export const saveUserSchool = `
mutation SaveAthleteschool($obj:AthleteschoolInput!){
  saveAthleteschool(obj:$obj){
    id
    active
    userid
    highschoolname
    currentltnotinhighschool
    enddateyear
    enddatemonth
    startdateyear
    startdatemonth
    zipcode
  }
}
`;