import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  GET_USER_DATA_START: "GET_USER_DATA_START",
  DELETE_MESSAGE_START: "DELETE_MESSAGE_START",
  SAVE_MESSAGE_START: "SAVE_MESSAGE_START",
  MESSAGE_LIST_START: "MESSAGE_LIST_START",
  MESSAGE_LIST_SUCCESS: "MESSAGE_LIST_SUCCESS",
  MESSAGE_LIST_FAILED: "MESSAGE_LIST_FAILED"
};

// ============================================ Actions ========================================================

export const saveMessageListStart = (data) => ({
  type: actionType.SAVE_MESSAGE_START,
  payload: data,
});

export const getUserDataListStart = (data) => ({
  type: actionType.GET_USER_DATA_START,
  payload: data,
});

export const deleteForeverMessageStart = (data) => ({
  type: actionType.DELETE_MESSAGE_START,
  payload: data,
});

export const messageListStart = data => ({
  type: actionType.MESSAGE_LIST_START,
  payload: data
});

export const messageListSuccess = data => ({
  type: actionType.MESSAGE_LIST_SUCCESS,
  payload: data
});

export const messageListFailed = data => ({
  type: actionType.MESSAGE_LIST_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function messageListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_MESSAGE_START:
      return { ...state, loading: true };

    case actionType.GET_USER_DATA_START:
      return { ...state, loading: true };
    
    case actionType.DELETE_MESSAGE_START:
        return { ...state, loading: true };

    case actionType.MESSAGE_LIST_START:
      return {
        ...state,
        loading: true
      };

    case actionType.MESSAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.MESSAGE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

function* saveMessageSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      action.payload.nextScreen()
    } else {
      messagePopup("", "Save Message Failed", "error");
      yield put(messageListFailed("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Save Message Failed", "error");
    yield put(messageListFailed("Failed, Error occured"));
  }
}

function* deleteForeverMessageSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      action.payload.nextScreen()
    } else {
      messagePopup("", "Delete Message Failed", "error");
      yield put(messageListFailed("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Delete Message Failed", "error");
    yield put(messageListFailed("Failed, Error occured"));
  }
}

export function* getUserDataListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        messageListSuccess({data: response.data.data.allUserdata.Userdata})
      );
    } else {
      messagePopup("", "User data Api failed", "error");
      yield put(messageListFailed("User data Api failed"));
    }
  } catch (error) {
    messagePopup("", "User data Api failed", "error");
    yield put(messageListFailed("User data Api failed"));
  }
}

export function* messageListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        messageListSuccess({data: response.data.data.allMessages.Messages, totalCount: response.data.data.allMessages.totalCount})
      );
    } else {
      messagePopup("", "Message Api failed", "error");
      yield put(messageListFailed("Message Api failed"));
    }
  } catch (error) {
    messagePopup("", "Message Api failed", "error");
    yield put(messageListFailed("Message Api failed"));
  }
}

export function* watchMessageListSaga() {
  yield takeEvery(actionType.GET_USER_DATA_START, getUserDataListSaga);
  yield takeEvery(actionType.SAVE_MESSAGE_START, saveMessageSaga);
  yield takeEvery(actionType.DELETE_MESSAGE_START, deleteForeverMessageSaga);
  yield takeEvery(actionType.MESSAGE_LIST_START, messageListSaga);
}
