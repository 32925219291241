import React, { useState, useEffect } from "react";
import "../../../../assets/css/stylesheet.scss";
import "../../../../assets/css/signIn.scss";
import "./login.scss";
import logoVPng from "../../../../assets/img/newLogoV.png";
import {
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { IsValidEmail } from "../../../../utils/validationUtils";
import { Link, useHistory } from "react-router-dom";
import { loginStart } from "../../../../redux/auth/login/login";
import { connect } from "react-redux";
import BackdropLoader from "../../../common/Loader";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CryptoJS from "crypto-js";
import Dialog from "@mui/material/Dialog";
import { fetchMethod } from "../../../../services/httpService";
const initialData = {
  email: "",
  password: "",
  showPassword: false,
  rememberPassword: false,
};

const useEnterKeyListener = ({ querySelectorToExecuteClick }) => {
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        handlePressEnter();
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const handlePressEnter = () => {
    const mouseClickEvents = ["mousedown", "click", "mouseup"];
    function simulateMouseClick(element) {
      mouseClickEvents.forEach((mouseEventType) =>
        element.dispatchEvent(
          new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1,
          })
        )
      );
    }

    const element = document.querySelector(querySelectorToExecuteClick);
    simulateMouseClick(element);
  };
};

function Login(props) {
  const [fields, setFields] = useState({ ...initialData });
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [openEmailDialog, setOpenEmailDialog] = useState(false)
  const [openThankuDialog, setOpenThankuDialog] = useState(false)
  const [emailError, setEmailError] = useState(null)
  const [thankuDialogText, setThankuDialogText] = useState("Thank you for registering with us, Now you can login with your credentials send on your registered email id.")
  const history = useHistory()

  useEffect(async() => {
    if(props?.location?.search && props?.location?.search.includes("emailid")){
      const sysGenId = props?.location?.search.split('emailid/');
      await fetchMethod("/api/Athletes/checklink", {
        sysGenId : parseInt(sysGenId[1])
      }, "post", false).then(res => {
        if(res?.status == 200){ 
          if(res?.data?.response?.status == 200){
            setOpenEmailDialog(true)
          }
          else{
            setThankuDialogText("Link expired")
            setOpenThankuDialog(true)
          }
        }
      }); 
    }
  },[])

  useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.reload
    ) {
      // Reload page after logout to clear the chache data
      // window.location.reload();
    }
    localStorage.clear()
    sessionStorage.clear()
  }, []);


  useEffect(() => {
    const isRem = localStorage.getItem('rememberMe')
    if(isRem){
      let credentials = JSON.parse(localStorage.getItem('credentials'));
      let bytes = CryptoJS.AES.decrypt(credentials.password, "password"),
        passString = (bytes && bytes.toString(CryptoJS.enc.Utf8)) || "";
      credentials.password = JSON.parse(passString || null);
      const rememberPassword = isRem;
      const email = credentials.email;
      const password = credentials.password;
      setFields({email,rememberPassword, password});
    }
  },[])

  const handleValidation = (fieldname, e) => {
    const errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    //email validations
    if (fieldname === undefined || fieldname === "email") {
      if (fieldname === undefined) {
        fieldValue = fields["email"] ? fields["email"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["email"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["email"] = "Email is required";
      } else if (!IsValidEmail(updatedValue)) {
        formIsValid = false;
        errorsT["email"] = "Please enter Email in correct format";
      }
    }
    //password validations
    if (fieldname === undefined || fieldname === "password") {
      if (fieldname === undefined) {
        fieldValue = fields["password"] ? fields["password"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["password"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["password"] = "Password is required";
      } else if (!(updatedValue.length >= 6)) {
        formIsValid = false;
        errorsT["password"] = "Invalid Password";
      }
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  useEnterKeyListener({
    querySelectorToExecuteClick: "#submitButton",
  });

  const handleChange = (field, e) => {
    const { updatedValue } = handleValidation(field, e);
    const fieldsT = fields;
    fieldsT[field] = updatedValue;
    setFields({
      ...fieldsT,
    });
  };
  const handleShowPasswordClick = () => {
    setFields({
      ...fields,
      showPassword: !fields.showPassword,
    });
  };

  const handleChechbox = (e) => {
    // if(e.target.type === "checkbox"){
    //   localStorage.setItem("isRememberKey", e.target.checked)
    // }
    if (e.target.type === "checkbox" && !e.target.checked) {
      setFields((fields) => ({ ...fields, rememberPassword: !fields.rememberPassword }));
    } 
    else {
      setFields((fields) => ({ ...fields, rememberPassword: !fields.rememberPassword }));
    }   
  };

  const handleForgetPswd = () => {
    localStorage?.setItem('isVerified', false);
    sessionStorage.setItem("ispasswordStatus", "1");
  };

  const handleSubmit = (e) => {
     e.preventDefault();
    const { formIsValid, } = handleValidation();
    const {email, rememberPassword} = fields;
    if (formIsValid) {
      props.loginStart({ email: fields.email, password: fields.password, props: props });
      if(rememberPassword){
        const password = CryptoJS.AES.encrypt(
          JSON.stringify(fields.password),
          "password"
        ).toString();
        localStorage.setItem('rememberMe',rememberPassword);
        localStorage.setItem('credentials', JSON.stringify({ email, password}));
      }
      else{
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('credentials');
      }
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const AddAthleteEmail = async () => {
    if (IsValidEmail(email)) { 
      let sysGenId = props?.location?.search.split('emailid/');
      await fetchMethod("/api/Athletes/addEmailId", {
        sysGenId : parseInt(sysGenId[1]),
        athleteEmailId : email
      }, "post", false).then(res => {
        if(res?.status == 200){ 
          if(res?.data?.response?.status == 401){
            setEmailError(res?.data?.response?.message)
          }
          else{
            setOpenEmailDialog(!openEmailDialog)
            setOpenThankuDialog(!openThankuDialog)
          }
        }
        
      });
    }
    else{
      if(email){ setEmailError("Please enter Email in correct format") }
      else{ setEmailError("Email is required") }
    }
  }

  const RedirectToLogin = () => {
    history.push('/login')
    setOpenThankuDialog(!openThankuDialog)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    if(e.target.value){
      if (!IsValidEmail(e.target.value)) { 
        setEmailError("Please enter Email in correct format")
      }
      else{
        setEmailError(null)
      }
    }
    else{
      setEmailError("Email is required")
    }
  }

  return (
    <div>
      <BackdropLoader open={props.login.loading} />
      <div className="signIn"
           style={{
        backgroundImage:`url("https://cdn.sportsforce.io/loginBG.jpg")`, 
        backgroundRepeat:"no-repeat",
      }}>
        <div className="container">
          <div className="flex">
            <div className="d-block">
              <div className="home">
                <div>
                  <Link to="/" className="homeLink">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9.337"
                      height="16.828"
                      viewBox="0 0 9.337 16.828"
                    >
                      <path
                        id="Path_117"
                        data-name="Path 117"
                        d="M6.922,0,0,7l6.922,7"
                        transform="translate(1 1.414)"
                        fill="none"
                        stroke="#c0321f"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </svg>
                    <span>Home</span>
                  </Link>
                </div>
                <div className="logo">
                  <Link to="/">
                    <img src={logoVPng} alt="Logo | SportsForce" />
                  </Link>
                </div>
              </div>

              <div className="form">
                <h1>Log In</h1>
                <ul>
                  <li>
                    <TextField
                      fullWidth
                      variant="outlined"
                      className="text-field"
                      id="email"
                      placeholder="Email*"
                      type="text"
                      value={fields.email}
                      onChange={(e) => {
                        handleChange("email", e);
                      }}
                      onBlur={(e) => {
                        handleChange("email", e);
                      }}
                      InputProps={{
                        readOnly: false,
                      }}
                      required
                    />
                    <div className="error">{errors["email"]}</div>
                  </li>
                  <li>
                    <TextField
                      variant="outlined"
                      id="password"
                      placeholder="Password*"
                      type={fields.showPassword ? "text" : "password"}
                      className="text-field"
                      fullWidth
                      name="password"
                      value={fields.password || ""}
                      onChange={(e) => {
                        handleChange("password", e);
                      }}
                      onBlur={(e) => {
                        handleChange("password", e);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleShowPasswordClick()}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large">
                              {fields.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                    <div className="error">{errors["password"]}</div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="rememberPassword"
                      name="checkbox"
                      checked={fields.rememberPassword}
                      onChange={(e) => handleChechbox(e)}
                    />
                    <label htmlFor="rememberPassword">Remember me</label>
                  </li>
                  <li>
                    <button
                      id="submitButton"
                      className="btn primary"
                      onClick={handleSubmit}
                    >
                      Log In
                    </button>
                  </li>
                  <li className="forgotPwd">
                    <Link onClick={()=> handleForgetPswd()} to="/forgotpassword">Forgot Password?</Link>
                  </li>
                </ul>
              </div>

              {/* <div className="link">
                <p>
                  Don't have an account? <Link to="/sportforceAthleteSignup/step1" onClick={()=>sessionStorage.clear()}>Sign Up</Link>
                </p>
              </div> */}
          </div>
        </div>
        </div>
      </div>

      {/* <!---- Registration Popup Modal ------> */}
      <Dialog open={openEmailDialog}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setOpenEmailDialog(!openEmailDialog)
                }
              }}
              className="matDialogCustom"
      >
        <div className="modal fade message-link-modal show" id="MessageLinkModal" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <form className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Registration</h5>
                        <button type="button" onClick={e=>setOpenEmailDialog(!openEmailDialog)} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body"> 
                        <div className="fieldBox">
                            <label>Please enter your email id</label>
                            <input type="email" onChange={e => handleEmailChange(e)} placeholder="yourmail@domain.com" />
                            <div className="error">{emailError}</div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={e => AddAthleteEmail(e)} className="btn" data-dismiss="modal" data-toggle="modal" data-target="#AddParentGuardianSuccessModal">Submit</button>
                    </div>
                </form>
            </div>
        </div>
      </Dialog>
     {/* End Registration Popup Modal  */}
    {/* <!---- Registration Thank You Popup Modal ------> */}
    <Dialog open={openThankuDialog}
            onClose={(event, reason)=> {
              if (reason == "backdropClick" || reason == "escapeKeyDown") {
                setOpenThankuDialog(!openThankuDialog)
              }
            }}
            className="matDialogCustom"
    >
      <div className="modal fade message-link-modal thanksyou show" id="ThankMessageLinkModal" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                  <button type="button" onClick={e => setOpenThankuDialog(!openThankuDialog)} className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="modal-body"> 
                      <p>{thankuDialogText}</p>
                  </div>
                  <div className="modal-footer">
                      <button type="button" onClick={e => RedirectToLogin()} className="btn" data-dismiss="modal" data-toggle="modal">Ok</button>
                  </div>
              </div>
          </div>
      </div>
    </Dialog>
    {/* <!---- End Registration Thank You Popup Modal ------> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.auth.login,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginStart: (data) => dispatch(loginStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);