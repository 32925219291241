import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_CHECKLIST_DATA_START: "ALL_CHECKLIST_DATA_START",
  ALL_CHECKLIST_DATA_SUCCESS: "ALL_CHECKLIST_DATA_SUCCESS",
  ALL_CHECKLIST_DATA_FAILED: "ALL_CHECKLIST_DATA_FAILED",
};

// ============================================ Actions ========================================================
export const allCheckListDataStart = (data) => ({
  type: actionType.ALL_CHECKLIST_DATA_START,
  payload: data,
});

export const allCheckListDataSuccess = (data) => ({
  type: actionType.ALL_CHECKLIST_DATA_SUCCESS,
  payload: data,
});

export const allCheckListDataFailed = (data) => ({
  type: actionType.ALL_CHECKLIST_DATA_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allCheckListDataReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ALL_CHECKLIST_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_CHECKLIST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_CHECKLIST_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allCheckListDataSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const allCheckListData = response.data.data;
      yield put(allCheckListDataSuccess(allCheckListData));
    } else {
      messagePopup("", "Check List failed", "error");
      yield put(allCheckListDataFailed("Check List failed"));
    }
  } catch (error) {
    messagePopup("", "Check List failed", "error");
    yield put(allCheckListDataFailed("Check List failed"));
  }
}

export function* watchAllCheckListDataSaga() {
  yield takeEvery(actionType.ALL_CHECKLIST_DATA_START, allCheckListDataSaga);
}
