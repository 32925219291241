import React, {useState, useEffect} from "react";
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";
import ActivityOverview from "../ActivityOverview";
import { Link } from "react-router-dom";
import { showInMapClicked } from "../commonMethods";
import { connect } from "react-redux";
import { viewCollegeSaveStart } from "../../../../../../redux/athlete/dashboard/colleges/viewCollege";
import { saveViewCollegeQuery } from "./SearchCollegesQuery";
import { getUserInfo } from "../../../../../../services/authService";
import { Dialog } from "@mui/material";
import Map from "../Map";
import { messageReplyListStart } from "../../../../../../redux/athlete/dashboard/messageReply/messageReply";
import { getMessageRecievedQuery } from "../myColleges/CollegeListQuery";
import { userProfileViewListStart } from "../../../../../../redux/athlete/dashboard/colleges/userProfileView";
import { viewsCountQuery } from "./CollegeDetailsQuery";
import { imageDownloadUrl } from "../../../../../../config";
import messagePopup from "../../../../../../utils/messagePopup";
import warningMessagePopup from "../../../../../../utils/warningMagPop";
import {getCamelCase} from '../../../../../common/camelCaseFunc'
import toastr from "toastr";
import swal from "sweetalert";
import { getMySignedCommitmentQuery } from "../../homepage/leftSideBar/query";
import { fetchGraphMethod } from "../../../../../../services/httpService";

function CollegeItemCard(props) {
  const [openMapModal, setOpenMapModal] = useState(false);
  const [collegeLatLng, setCollegeLatLng] = useState();
  const [msgReplyArray, setMsgReplyArray] = useState([]);
  const [profileViews, setProfileViews] = useState([]);
  const [signedList, setSignedList] = useState([]);

  useEffect(async ()=> {
    getMessageReplyList();
    getViewsCountData();
    await getSignedCommitmentList();
  }, [])
  useEffect(()=> {
    if(props.profileView.data && props.profileView.data.totalCount !== 0){
      profileViews.push(props.profileView.data.Userprofileviews);
    }
    }, [props.profileView.data])
  
  const getSignedCommitmentList = async() => {
      if (props.data.id) {
        await fetchGraphMethod(
          getMySignedCommitmentQuery,
          {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : 0,
              active: 1,
              collegeid: props.data.id
            },
          },
          true
        ).then((res) => {
          if (res?.data?.data?.allSignedcommitments?.totalCount) {
            setSignedList(
              res?.data?.data?.allSignedcommitments?.Signedcommitments
            );
          } else {
            setSignedList([]);
          }
        });
      }    
  };

  const handleLocationClick = (e, data) => {
    e.preventDefault();
    // showInMapClicked(data?.collegelat, data?.collegelng);
    setOpenMapModal(true);
    setCollegeLatLng(data);
  };
  const onCloseMap = () => {
    setOpenMapModal(false);
  }
  const onClickHandler = (event) => {
    if (!props.data.isFavorite) {
      props.handleAddToFavorite(event, props.data);
    } else {
      let warningMessage = '';
      if(props.data?.offer === 1 && props.data?.commit === 1) {
        if(signedList && signedList.length > 0) {
          warningMessage = "Are you sure you want to unfollow this college? Offers , commitments and signed commitment will be deleted as well.";
        } else {
          warningMessage = "Are you sure you want to unfollow this college? Offers and commitments will be deleted as well.";
        }        
      } else if(props.data?.offer === 1 && props.data?.commit === 0) {
        warningMessage = "Are you sure you want to unfollow this college? Offers will be deleted as well.";
      } else {
        warningMessage = "Are you sure you want to unfollow this college?";
      }

      warningMessagePopup(
        "",
        warningMessage,
        "warning"
      ).then(async (res) => {
        if(res){
          props.handleRemoveCollege(event, props.data);
          // messagePopup("", "School / College  has been removed as Favorite!", "success");
          toastr.success("School / College  has been removed as Favorite!", "", {
            progressBar : true,timeOut: 2000,
            closeButton: true, positionClass: "toast-top-full-width",
            showEasing: "swing", fadeIn: 40000,
            preventDuplicates:true,
          })
        }
      })
    }
  };
  const getViewsCountData = () => {
    props.userProfileViewListStart({
        query : viewsCountQuery,
        variables : {
            where : {
              collegeid : props.data.collegeid,
            }
        }
    })
  }

  const saveViewsOfCollege = () => {
    props.viewCollegeSaveStart({
      query : saveViewCollegeQuery,
      variables : {
        obj : {
          athleteid : getUserInfo() ? getUserInfo().id : null,
          collegeid : props.data.id,
          collegeviewstatus : 1,
        }
      }
    })
  }
  
  useEffect(()=> {
    if(props.messageReplyList.data && props.messageReplyList.data.data && props.messageReplyList.data.data.length !== 0){ 
      msgReplyArray.push(props.messageReplyList.data.data)
    }
    }, [props.messageReplyList.data])
  const getMessageReplyList = () => {
    props.messageReplyListStart({
      query: getMessageRecievedQuery,
      variables: {
        where: {
          msgreceiverid : {neq: null},
          collegeid : props.data.collegeid,
          order: "id desc"
        },
        first : 1,
        last : 1
      }
    });
  }

  //This will warn while clicking and once they press ok then only redirect otherwise not.
  const handleMessageEnvelop = () => {
    warningMessagePopup(
      "",
      "The message center is coming soon. You are being redirected to the recruiting tab to contact college coaches directly",
      "warning"
    ).then(async (res) => {
      if(res){
        window.location.href = `/colleges/details/${props.data ? props.data.id : null}/${props.data && props.data.isFavorite == 1 ? 1 : 0}`;
      }
    })
  }

  return (
    <div>
      <div className="dataItem">
        <div className="dataFlex">
          <div className="leftData">
            <div className="data">
              <div className="headFlex">
                <div className="icon">
                  <img src={props.data.collegelogo ? imageDownloadUrl + "/" +props.data.collegelogo : TeamLogo} alt="teamLogo" />
                </div>
                <div>
                  <Link
                  to={`/colleges/details/${props.data.id}/${props.data.isFavorite == 1 ? 1 : 0}`} onClick={saveViewsOfCollege}
                  >{props.data ? props.data.collegename : ""}</Link>
                  
                  <ul className="college-info-text">
                    {!!props?.data?.conferencenmae &&
                    <li>
                      <span>
                        {props.data ? `${props.data.conferencenmae == null ? " " : props.data.conferencenmae}` : " "}
                      </span>
                    </li>
                    }
                    <li>
                      <span>
                        {props.data ? `${props.data.city === null || props.data.city === undefined ? " " : getCamelCase(props.data.city)
                        }${props.data ? ((props.data.city === null || props.data.city === undefined) && (props.data.initials === null || props.data.initials === undefined) ? " " : ",") : " "}
                         ${props.data.initials === null || props.data.initials === undefined ? " " :props.data.initials}` : " "}
                      </span>
                    </li>
                    <li>
                      <span>
                        {props.data ? `${props.data.collegedivisionname === null || props.data.collegedivisionname === undefined ? " " : props.data.collegedivisionname}` : " "}
                      </span>
                    </li>
                  </ul>
                  {props.data && props.data.rank ? (
                    <button className="targetBtn">
                      #{props.data.rank} Target
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="rightData">
            <div className="actionBox">
              <div className="actionFlexBox">
                <ul>
                  <li>
                    <span className="percentValue">
                    <span className="icon iconlock">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.961"
                          height="18.773"
                          viewBox="0 0 14.961 18.773"
                        >
                          <g
                            id="padlock_1_"
                            data-name="padlock (1)"
                            transform="translate(-433.592 -454.859)"
                          >
                            <g
                              id="Group_691"
                              data-name="Group 691"
                              transform="translate(427.639 471.39)"
                            >
                              <g id="Group_690" data-name="Group 690">
                                <path
                                  id="Path_373"
                                  data-name="Path 373"
                                  d="M64.761,6.894H63.439V3.878a3.961,3.961,0,0,0-7.92,0V6.894H54.2a2.2,2.2,0,0,0-2.2,2.2v7.48a2.2,2.2,0,0,0,2.2,2.2H64.761a2.2,2.2,0,0,0,2.2-2.2V9.094A2.2,2.2,0,0,0,64.761,6.894ZM56.986,3.878a2.495,2.495,0,0,1,4.987,0V6.894H56.986Zm8.508,12.7a.734.734,0,0,1-.733.733H54.2a.734.734,0,0,1-.733-.733V9.094A.734.734,0,0,1,54.2,8.36H64.761a.734.734,0,0,1,.733.733Z"
                                  transform="translate(-46.047 -16.529)"
                                  fill="#333"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_693"
                              data-name="Group 693"
                              transform="translate(435.859 485.467)"
                            >
                              <g id="Group_692" data-name="Group 692">
                                <path
                                  id="Path_374"
                                  data-name="Path 374"
                                  d="M220.357,286a1.357,1.357,0,0,0-.735,2.5v1.61a.733.733,0,1,0,1.467,0V288.5a1.357,1.357,0,0,0-.732-2.5Z"
                                  transform="translate(-215.143 -306.12)"
                                  fill="#333"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                      <label>Match</label>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <path
                            className="a"
                            d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                            transform="translate(-3 -3)"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className={
                        !props.data.isFavorite
                          ? "powerLinkUnfilled"
                          : "powerLinkFilled"
                      }
                      onClick={onClickHandler}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="42.302"
                        height="38.479"
                        viewBox="0 0 42.302 38.479"
                      >
                        <defs>
                          <style></style>
                          <filter
                            id="a"
                            x="0"
                            y="0"
                            width="42.302"
                            height="38.479"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="b" />
                            <feFlood floodOpacity="0.161" />
                            <feComposite operator="in" in2="b" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g transform="translate(9.751 17.752)">
                          <g
                            className="b"
                            transform="matrix(1, 0, 0, 1, -9.75, -17.75)"
                          >
                            <path
                              className="a"
                              d="M20.586,3.537a6.347,6.347,0,0,0-8.309.572l-.877.854-.877-.854a6.347,6.347,0,0,0-8.309-.572,5.817,5.817,0,0,0-.441,8.747l8.617,8.406a1.453,1.453,0,0,0,2.017,0l8.617-8.406a5.813,5.813,0,0,0-.436-8.747Z"
                              transform="translate(9.75 4.5)"
                            />
                          </g>
                        </g>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => handleMessageEnvelop()} to="#" className="directionLink">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16.839px" height="23.574px" viewBox="0 0 24.006 19.205">
                        <g id="email" transform="translate(-1 -4)">
                          <path id="Path_369" data-name="Path 369" d="M24.973,6.512A2.948,2.948,0,0,0,22.066,4H3.963A2.948,2.948,0,0,0,1.056,6.512L13.014,14.25Z" transform="translate(-0.011)" fill="#3e3e3e" />
                          <path id="Path_370" data-name="Path 370" d="M13.438,16.971a.8.8,0,0,1-.869,0L1,9.486V21.349A2.955,2.955,0,0,0,3.952,24.3h18.1a2.955,2.955,0,0,0,2.952-2.952V9.485Z" transform="translate(0 -1.096)" fill="#3e3e3e" />
                        </g>
                      </svg>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="locationLink"
                      onClick={(e) => handleLocationClick(e, props.data)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.839"
                        height="23.574"
                        viewBox="0 0 16.839 23.574"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <g transform="translate(-6.75 -2.25)">
                          <path
                            className="a"
                            d="M19.118,12.934a1.684,1.684,0,1,1-1.684-1.684A1.684,1.684,0,0,1,19.118,12.934Z"
                            transform="translate(-2.264 -2.264)"
                          />
                          <path
                            className="a"
                            d="M15.17,2.25A8.252,8.252,0,0,0,6.75,10.3c0,2.114.963,4.925,2.863,8.355a61.752,61.752,0,0,0,4.21,6.484,1.67,1.67,0,0,0,2.7,0,62,62,0,0,0,4.21-6.484c1.9-3.429,2.861-6.24,2.861-8.355A8.252,8.252,0,0,0,15.17,2.25Zm0,11.788a3.368,3.368,0,1,1,3.368-3.368A3.368,3.368,0,0,1,15.17,14.038Z"
                            transform="translate(0)"
                          />
                        </g>
                      </svg>
                    </Link>
                  </li>

                  <li className="badgeBox"
                      onClick={()=>swal({text:"This feature is available for premium members only", icon:"warning"})}
                  >
                    <Link to="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="27.533" height="27.533" viewBox="0 0 27.533 27.533"
                      fill={props?.data?.istagged == 1 ? "#ff6624" : "none"}
                      >
                        <g id="tag-black-shape" transform="translate(0.498 0.5)">
                          <path id="Path_438" data-name="Path 438" d="M25.887,14.1,13.365,1.594A5.424,5.424,0,0,0,11.579.464,5.411,5.411,0,0,0,9.529,0H2.244A2.155,2.155,0,0,0,.667.665,2.154,2.154,0,0,0,0,2.242V9.527a5.407,5.407,0,0,0,.464,2.049A5.219,5.219,0,0,0,1.6,13.345l12.522,12.54a2.145,2.145,0,0,0,1.576.648,2.243,2.243,0,0,0,1.594-.648l8.6-8.617a2.145,2.145,0,0,0,.648-1.576A2.244,2.244,0,0,0,25.887,14.1ZM7.191,7.189a2.161,2.161,0,0,1-1.585.657A2.243,2.243,0,0,1,3.365,5.6,2.243,2.243,0,0,1,5.606,3.363,2.243,2.243,0,0,1,7.848,5.6,2.159,2.159,0,0,1,7.191,7.189Z"
                                transform="translate(0 0)" fill={props?.data?.istagged == 1 ? "#ff6624" : "none"} stroke="#ff6624" strokeWidth="1"/>
                        </g>
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Dialog open={openMapModal}
                onClose={(event, reason)=> {
                  if (reason == "backdropClick" || reason == "escapeKeyDown") {
                    onCloseMap()
                  }
                }}
        >
      <div className="map-modal-wrap">
        <div className="mapclose-button">
          <button 
           onClick={()=>onCloseMap()}
          >&times;</button>
        </div>
      <Map
          markers={[
          { latitude: collegeLatLng?.collegelat, longitude: collegeLatLng?.collegelng },
        ]}
      /> 
         </div>
      </Dialog>
        <div className="collapseBox">
          <ActivityOverview 
          collegeInfo={props.data}
          collegeid={props.data}
          msgReplyArray={msgReplyArray}
          messageReplyListStart={props.messageReplyListStart}
          profileViews={profileViews}
          viewdata={props.profileView.data}
          />
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    viewCollege : state.athlete.dashboard.colleges.viewCollege,
    messageReplyList: state.athlete.dashboard.messageReply.messageReplyList,
    profileView : state.athlete.dashboard.colleges.userprofileView,

  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    viewCollegeSaveStart: (data) => dispatch(viewCollegeSaveStart(data)),
    messageReplyListStart: data => dispatch(messageReplyListStart(data)),
    userProfileViewListStart : (data) => dispatch(userProfileViewListStart(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CollegeItemCard);