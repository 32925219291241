import React from 'react';
import { ThreeDots } from 'react-loader-spinner';


export default function InlineLoader(props) {
  return <ThreeDots 
    height="80" 
    width="80" 
    radius="9"
    color="#dd2720" 
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClassName=""
    visible={props.open}
  />
}