import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardRecruitProcess.scss";
import "../assets/css/onBoardSteeper.scss";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { connect } from "react-redux";
import { getUserInfo } from "../../../../../services/authService";
import { getOnboardingQuery, saveOnboardingQuery } from "../OnboardingQuery";
import BackdropLoader from "../../../../common/Loader";
import usePrevious from "../../../../../utils/usePrevious";
import { IsValidName } from "../../../../../utils/validationUtils";
import Stepper from "../Stepper";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import { Dialog } from "@mui/material";
import { allCollegeDetailsListStart } from "../../../../../redux/common/allCollegeData";
import { getAllCollegeQuery } from "../../dashboard/profile/ProfileEdit/profileQuery";
import Autocomplete from '@mui/material/Autocomplete';
import messagePopup from "../../../../../utils/messagePopup";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "passed", link: "/onboarding/positions" },
  { className: "passed", link: "/onboarding/physicalMeasurements" },
  { className: "passed", link: "/onboarding/personalRecords" },
  { className: "passed", link: "/onboarding/firstYearVarsity" },
  { className: "passed", link: "/onboarding/schoolInfo" },
  { className: "passed", link: "/onboarding/academics" },
  { className: "active", link: "/onboarding/recruiting" },
  { className: "" },
  { className: "" },
  { className: "" },
  // { className: "" },
  // { className: "" },
];

const checkBoxList = [
  { label: "Just getting started", key: 1 },
  { label: "Marketing myself", key: 2 },
  { label: "Coach calls / texts", key: 3 },
  { label: "Received offers", key: 4 },
  { label: "Verbally committed", key: 5 },
  { label: "Already signed", key: 6 },
];

function Recruiting(props) {
  const [fields, setFields] = useState({
    selectedData: "",
  });
  const [errors, setErrors] = useState({});
  const [collegeArray, setCollegeArray] = useState([]);
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    props.history.push("/onboarding/recruitingprocess");
  };

  useEffect(() => {
    setFields({ selectedData: "" });
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
    props.allCollegeDetailsListStart({
      query: getAllCollegeQuery,
      variables: {
        obj: {
          active: 1,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        let fieldT = fields;
        const { id, recruitingprocess, recruitingcollagename } =
          props.onboarding.data;
        fieldT["id"] = id;
        fieldT["selectedData"] = recruitingprocess
          ? recruitingprocess
          : fieldT["selectedData"];
        fieldT["collegename"] = recruitingcollagename
          ? recruitingcollagename
          : "";
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);
  useEffect(() => {
    if (props.collegeList.data) {
      let collegeArrayT = props.collegeList.data.filter((college) => {
        if (college.collegename) {
          return college;
        }
      });
      setCollegeArray(collegeArrayT);
    }
  }, [props.collegeList]);

  const handleCheckboxElement = (e, data) => {
    let fieldsT = fields;
    if (e.target.checked) {
      fieldsT["selectedData"] = data;
    }
    if (
      fieldsT["selectedData"] !== "Verbally committed" &&
      fieldsT["selectedData"] !== "Already signed"
    ) {
      fieldsT["collegename"] = "";
    }
    setFields({ ...fieldsT });
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;

    if (fieldname === undefined || fieldname === "collegename") {
      if (fieldname === undefined) {
        fieldValue = fields["collegename"] ? fields["collegename"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["collegename"];
      if (!IsValidName(updatedValue)) {
        formIsValid = false;
        errorsT["collegename"] = "Please enter a valid college name";
      }
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const handleInputChange = (field, e) => {
    const { formIsValid, updatedValue } = handleValidation(field, e);
    let fieldsT = fields;
    fieldsT[field] = updatedValue;
    setFields({ ...fieldsT });
  };
  const handleObjChange = (field, e) => {
    let fieldsT = fields;

    fieldsT[field] = e
      ? e.target
        ? e.target.value
          ? e.target.value[field]
            ? e.target.value[field]
            : null
          : null
        : null
      : null;

    setFields({
      ...fieldsT,
    });
  };
  
  const handleSubmit = () => {
    const { formIsValid, updatedValue } = handleValidation();
    if(fields?.selectedData){
    if (formIsValid) {
      props.saveOnboardingStart({
        query: saveOnboardingQuery,
        variables: {
          obj: {
            id: fields.id ? fields.id : 0,
            userid: getUserInfo() ? getUserInfo().id : 0,
            recruitingprocess: fields.selectedData ? fields.selectedData : null,
            recruitingcollagename: fields.collegename
              ? fields.collegename
              : null,
          },
        },
        nextScreen: routeNextPage,
      });
    }
  }
    else{
      messagePopup("", "Please select recruiting process", "error");
    }
  };

  return (
    <div>
      <BackdropLoader open={props.onboarding.loading} />
      <div className="onBoardRecruitProcessSection">
        <div className="recruitProcessBlock">
          <div className="stepper">
            <Stepper stepperName="recruiting" stepperData={stepperData} />
          </div>
          <div className="formSection">
            <h1 className="heading">
              Where are you in the recruiting process?{" "}
            </h1>
            <div className="recruitProcessBox">
              <ul>
                {checkBoxList &&
                  checkBoxList.map((value, index) => (
                    <li key={index}>
                      <span>
                        <input
                          type="checkbox"
                          checked={fields.selectedData === value.label}
                          onChange={(e) =>
                            handleCheckboxElement(e, value.label)
                          }
                          id="recruitingProcess"
                        />
                        <label htmlFor="recruitingProcess">{value.label}</label>
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="fieldBox">
              {(fields.selectedData === "Verbally committed" ||
                fields.selectedData === "Already signed") && (
                <div>
                  <label>What college?</label>
                  <div className="autocomplete-class">
                    <Autocomplete
                      id="combo-box-demo"
                      freeSolo={true}
                      options={collegeArray}
                      onChange={(e, data) => {
                        handleObjChange("collegename", {
                          target: { value: data ? data : "" },
                        });
                      }}
                      // value={fields.highschoolname || null}
                      defaultValue={fields.collegename}
                      getOptionLabel={(option) =>
                        option.collegename ? option.collegename : option
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={fields.collegename}
                          onChange={(e) => {
                            handleInputChange("collegename", {
                              target: {
                                value: e.target.value ? e.target.value : "",
                              },
                            });
                          }}
                        />
                      )}
                    />
                  </div>
                  <div style={{ color: "red" }}>{errors["collegename"]}</div>
                </div>
              )}
            </div>
            <p className="skip">
              Don't know?{" "}
              <span className="sikpBtnBox">
                <Link className="skipit-btn" to="#" onClick={handleClickOpen}>
                  Skip it
                </Link>
              </span>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="#" onClick={() => handleSubmit()}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            handleClose()
          }
        }}
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    onboarding: state.athlete.onBoarding.onboarding,
    collegeList: state.common.collegeDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
    allCollegeDetailsListStart: (data) =>
      dispatch(allCollegeDetailsListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recruiting);
