import swal from "sweetalert";
import toastr from 'toastr'

export default async function successMessagePopup(title, text, icon) {
  const success = await swal({
    title: title,
    text: text,
    icon: icon,
    // buttons: true,
    // dangerMode: true,
  });
  return success;
}
export const  successMessageToaster = (title, text, icon) => {
  toastr.success(text,"",
      {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      }
  );
  return true;
}
