import React, { useEffect, useRef, useState } from 'react';
import { Search, GpsFixed } from '@mui/icons-material';
import './autoCompleteCitySearch.css';
// import { apiKey, geocodeJson, mapApiJs } from "../../config";
// import {geocodeJson} from '../../config'
// const apiKey = "AIzaSyD4pmDReyz6px3lTCv1S-p9HZU1ccDg7-I&v=3.exp";
// const mapApiJs = "https://maps.googleapis.com/maps/api/js";
// const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

// load google map api js

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src,
    });
    script.addEventListener('load', () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place, palceLatLong) => {
  const address = {
    placeLat: palceLatLong?.lat,
    placeLng: palceLatLong?.long,
    streetNumber: '',
    route: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    plain() {
      const city = this.city ? this.city + ', ' : '';
      const zip = this.zip ? this.zip + ', ' : '';
      const state = this.state ? this.state + ', ' : '';
      return city + zip + state + this.country;
    },
    fullAddress() {
      const streetNumber = this.streetNumber ? this.streetNumber + ' ' : '';
      const route = this.route ? this.route + ', ' : '';
      const street = this.street ? this.street + ', ' : '';
      const city = this.city ? this.city + ', ' : '';
      const zip = this.zip ? this.zip + ', ' : '';
      const state = this.state ? this.state + ', ' : '';
      return streetNumber + route + street + city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;
    if (types.includes('street_number')) {
      address.streetNumber = value;
    }
    if (types.includes('route')) {
      address.route = value;
    }
    if (types.includes('sublocality')) {
      address.street = value;
    }
    if (types.includes('neighborhood')) {
      address.city = value;
    }
    if (types.includes('locality') && address.city === '') {
      // if city is not found in neighborhood
      address.city = value;
    }
    const short = component.short_name;

    if (types.includes('administrative_area_level_1')) {
      address.state = value;
      address.state = short;
    }

    if (types.includes('postal_code')) {
      address.zip = value;
    }

    if (types.includes('country')) {
      address.country = value;
    }
  });

  return address;
};

function AutoCompleteCitySearch(props) {
  const searchInput = useRef(null);
  const [address, setAddress] = useState(null);
  const [_latlong, setLatLong] = useState({});
  const [value, setValue] = useState(props.value);

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${process.env.REACT_APP_mapApiJs}?key=${process.env.REACT_APP_apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete, add = null) => {
    const place = autocomplete.getPlace();
    const palceLatLong = {
      lat: place.geometry.location.lat(),
      long: place.geometry.location.lng(),
    };
    setAddress(extractAddress(place, palceLatLong));
    setValue(add);
    setLatLong(palceLatLong);
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );

    autocomplete.setFields(['address_component', 'geometry']);
    autocomplete.addListener('place_changed', () =>
      onChangeAddress(autocomplete, searchInput.current.value)
    );
  };

  // const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
  //   const url = `${geocodeJson}?key=${process.env.REACT_APP_apiKey}&latlng=${lat},${lng}`;
  //   searchInput.current.value = "Getting your location...";
  //   fetch(url)
  //     .then((response) => response.json())
  //     .then((location) => {
  //       const place = location.results[0];
  //       const _address = extractAddress(place);
  //       setAddress(_address);
  //       searchInput.current.value = _address.plain();
  //     });
  // };

  // const findMyLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       reverseGeocode(position.coords);
  //     });
  //   }
  // };

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  useEffect(() => {
    if (address) {
      props.addressDetails(address);
    }
  }, [address]);

  useEffect(() => {
    setValue('');
  }, [props.value]);

  return (
    <div className="App">
      <div>
        <div className="search">
          <span className={'searchIcon'}>
            <Search />
          </span>
          <input
            value={value}
            ref={searchInput}
            type="text"
            placeholder="Search location...."
            disabled={props.disabled}
            defaultValue={props.cityName ? props.cityName : ''}
            onChange={(e) => setValue(e.target.value)}
          />
          <button
            className="locationLock"
            type={'button'}
            // onClick={findMyLocation}
          >
            <GpsFixed />
          </button>
        </div>
      </div>
    </div>
  );
}

export default AutoCompleteCitySearch;
