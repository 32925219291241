import React, {useEffect} from "react";
import '../../../../landingPage/assets/css/privacy-policy.scss'

export const TermCancellation = () =>{
    //func for scrolling the page in top
    useEffect(()=>{
        setTimeout(()=>{
            window.scrollTo(0,0)
        },800)
    },[])

    return(
        <>
            <section className="privacy-policy-content">
                <div className="pagelinks">
                    <div className="container">
                        <ul className="links text-center">
                            <li><a style={{lineHeight:"1.2"}} className="active" href={void 0}>Premium Plan Terms & Services Cancellation Policy</a></li>
                        </ul>
                    </div>
                </div>
                <div className="container">
                    <div className="contentwrap">
                        <h1>Service Agreement</h1>
                        <p><strong>Terms of Service:</strong> All services are guaranteed through June of the
                            student-athlete’s high school senior year.</p>
                        <h4>Post High School Terms of Service:</h4>
                        <p>If the student-athlete/client requires additional college recruiting assistance including but
                            not limited to post-grad, junior college and/or transfer portal related recruitment the
                            student-athlete/client is able to continue to the access the following services including
                            their premium athlete profile membership, website support, college resource and masterclass
                            library, and any unused custom video edits.</p>
                        <p>Additional premium services for post high school recruitment including but not limited to
                            custom video edits, college coach marketing campaigns, personalized college recruiting
                            guidance, college coach outreach and advocacy is available at an additional cost.</p>
                        <p><strong>College Coach Introductions:</strong> If you plan includes college coach
                            introductions SportsForce reserves the final right in deciding which college coaches the
                            student-athlete/client will be introduced to, based on a number of factors, including but
                            not limited to the following: club/high school coach recommendations, athletic ability,
                            personal statistics, academic background, video, etc. However, a student-athlete may choose
                            to introduce themselves to schools on their own that SportsForce has not included on the
                            target list, if they so desire. The introduction process to college coaches can be adjusted
                            based on the improvement level in both athletic and/or academic achievements for the
                            client/student-athlete. The initial college target promotion must be sent at one time, and
                            the additional email campaigns must be sent at one time. SportsForce will create an initial
                            target list for the student-athlete based on their collegiate qualifications, the student
                            will review, add, or remove any schools of their choosing. SportsForce will have final say
                            over completed target list.</p>
                        <p><strong>Student-Athlete/Client Responsibilities:</strong> The student-athlete/client agrees
                            to submit all necessary updated academic and athletic information (including video,
                            transcripts, test scores, significant accomplishments, etc.) on a continual and as needed
                            basis. It is the student-athletes’/client’s responsibility to contact SportsForce for any
                            and all pertinent recruiting needs and questions, and to keep in contact with SportsForce
                            during the recruiting process. The student-athlete/client agrees to respond to college
                            coaches in a timely manner, and notify SportsForce when an athletic and/or academic offer
                            has been accepted. It is the understanding of both parties that SportsForce may use the
                            student-athlete’s name, photograph or videotape in promotional material and/or for media
                            purposes.</p>
                        <p><strong>Personal Conduct Clause:</strong> SportsForce reserves the right to terminate/void
                            this contract if the student-athlete shall at any time fail, refuse or neglect to conform
                            his personal conduct to the standards of good citizenship and good sportsmanship or engage
                            in any unethical/illegal conduct.</p>
                        <p><strong>Disclaimer:</strong> All services are guaranteed once the agreement has been signed
                            and processed, and to use its influence to obtain exposure for the prospect; however, this
                            does not guarantee that a scholarship or financial assistance will be offered, or that
                            placement to any particular college or university will be granted to our
                            student-athletes/clients. SportsForce is not an agent of the student-athlete/client. It is
                            the sole responsibility of the client (i.e. student-athlete and family) to determine the
                            best-fit school for themselves.</p>
                        <p><strong>Right to Cancel:</strong> SportsForce is an independent service, not affiliated with
                            any college or university.The guarantor shall have seven (7) days to cancel this contract.
                            SportsForce must receive any cancellation in writing within seven days of the date of this
                            contract. The student-athlete/client may discontinue the program at any time, and it is the
                            responsibility of the student-athlete/client to notify SportsForce in writing of their
                            decision. However, under no circumstances will any portion of the investment be refunded if
                            the cancellation occurs beyond seven (7) days of the enrollment date.</p>
                        <p><em>* SPORTSFORCE reserves the right to modify the services of this agreement to better serve
                            the needs of prospects and coaches. SportsForce is responsible for no other representations
                            or verbal agreement.</em></p>
                    </div>
                </div>
            </section>

        </>
    )
}