import { all, fork } from "redux-saga/effects";
import { watchLoginSaga } from "./login/login";
import { watchCoachLoginSaga } from "./login/coachLogin";
import { watchSignupSaga } from "./signup/signup";
import { watchsearchUserSaga } from "./signup/claimProfile";
import { watchClaimProfileSaga } from "./signup/userClaimProfile";
import { watchMatchOtpSaga } from "./signup/matchOtp";
import { watchchaeckEmailExistSaga } from "./signup/checkEmailExist";
import { watchPreviewProfileSaga } from "./signup/previewProfile";
import { watchAllUserDataSaga } from "./forgotpassword/forgotPassword";
import { watchSendEmailSaga } from "./forgotpassword/sendEmail";
import { watchMatchForgotOtpSaga } from "./forgotpassword/matchForgotOtp"
import { watchChangePasswordSaga } from "./forgotpassword/ChangePassword"
export default function* rootAuthSagas() {
  yield all([
    fork(watchLoginSaga),
    fork(watchCoachLoginSaga),
    fork(watchSignupSaga),
    fork(watchsearchUserSaga),
    fork(watchClaimProfileSaga),
    fork(watchMatchOtpSaga),
    fork(watchchaeckEmailExistSaga),
    fork(watchPreviewProfileSaga),
    fork(watchAllUserDataSaga),
    fork(watchSendEmailSaga),
    fork(watchMatchForgotOtpSaga),
    fork(watchChangePasswordSaga),
  ]);
}
