import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  FOLLOW_RECRUIT_COUNT_START: "FOLLOW_RECRUIT_COUNT_START",
  FOLLOW_RECRUIT_COUNT_SUCCESS: "FOLLOW_RECRUIT_COUNT_SUCCESS",
  FOLLOW_RECRUIT_COUNT_FAILED: "FOLLOW_RECRUIT_COUNT_FAILED"
};

// ============================================ Actions ========================================================

export const followRecruitCountListStart = data => ({
  type: actionType.FOLLOW_RECRUIT_COUNT_START,
  payload: data
});

export const followRecruitCountListSuccess = data => ({
  type: actionType.FOLLOW_RECRUIT_COUNT_SUCCESS,
  payload: data
});

export const followRecruitCountListFailed = data => ({
  type: actionType.FOLLOW_RECRUIT_COUNT_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function followRecruitCountListReducer(state = INIT_STATE, action) {
  switch (action.type) {    
    case actionType.FOLLOW_RECRUIT_COUNT_START:
      return {
        ...state,
        loading: true
      };

    case actionType.FOLLOW_RECRUIT_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.FOLLOW_RECRUIT_COUNT_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* followRecruitCountListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        followRecruitCountListSuccess({totalCount: response.data.data.allFollowers.totalCount})
      );
    } else {
      messagePopup("", "Follow Count Api failed", "error");
      yield put(followRecruitCountListFailed("Follow Count Api failed"));
    }
  } catch (error) {
    messagePopup("", "Follow Count Api failed", "error");
    yield put(followRecruitCountListFailed("Follow Count Api failed"));
  }
}

export function* watchfollowRecruitCountListSaga() {
  yield takeEvery(actionType.FOLLOW_RECRUIT_COUNT_START, followRecruitCountListSaga);
}