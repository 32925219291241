import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ATHLETE_PARENT_DROPDOWN_START: "ATHLETE_PARENT_DROPDOWN_START",
  ATHLETE_PARENT_DROPDOWN_SUCCESS: "ATHLETE_PARENT_DROPDOWN_SUCCESS",
  ATHLETE_PARENT_DROPDOWN_FAILED: "ATHLETE_PARENT_DROPDOWN_FAILED"
};

// ============================================ Actions ========================================================
export const athleteParentDropdownStart = data => ({
  type: actionType.ATHLETE_PARENT_DROPDOWN_START,
  payload: data
});

export const athleteParentDropdownSuccess = data => ({
  type: actionType.ATHLETE_PARENT_DROPDOWN_SUCCESS,
  payload: data
});

export const athleteParentDropdownFailed = data => ({
  type: actionType.ATHLETE_PARENT_DROPDOWN_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function athleteParentDropdownReducer(state = INIT_STATE, action) {
  switch (action.type) {
      case actionType.ATHLETE_PARENT_DROPDOWN_START:
      return {
        ...state,
        loading: true
      };

    case actionType.ATHLETE_PARENT_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.ATHLETE_PARENT_DROPDOWN_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* athleteParentDropdownSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        athleteParentDropdownSuccess({data: response.data.data.allAthleteparents.Athleteparents})
      );
    } else {
      messagePopup("", "Athlete Parent Api failed", "error");
      yield put(athleteParentDropdownFailed("Athlete Parent Api failed"));
    }
  } catch (error) {
    messagePopup("", "Athlete Parent Api failed", "error");
    yield put(athleteParentDropdownFailed("Athlete Parent Api failed"));
  }
}

export function* watchAthleteParentDropdownListSaga() {
  yield takeEvery(actionType.ATHLETE_PARENT_DROPDOWN_START, athleteParentDropdownSaga);
}