import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
    SAVE_ATHLETE_VIEW_VIDEO_START: "SAVE_ATHLETE_VIEW_VIDEO_START",
    SAVE_ATHLETE_VIEW_VIDEO_SUCCESS: "SAVE_ATHLETE_VIEW_VIDEO_SUCCESS",
    SAVE_ATHLETE_VIEW_VIDEO_FAILURE: "SAVE_ATHLETE_VIEW_VIDEO_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const getHowToVideoStart = (data) => ({
    type: actionType.SAVE_ATHLETE_VIEW_VIDEO_START,
    payload: data,
});

export const HowToVideoSuccess = (data) => ({
    type: actionType.SAVE_ATHLETE_VIEW_VIDEO_SUCCESS,
    payload: data,
});

export const HowToVideoFailure = (data) => ({
    type: actionType.SAVE_ATHLETE_VIEW_VIDEO_FAILURE,
    payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};
export default function saveAthleteViewVideoReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.SAVE_ATHLETE_VIEW_VIDEO_START:
            return { ...state, loading: true };

        case actionType.SAVE_ATHLETE_VIEW_VIDEO_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: null };

        case actionType.SAVE_ATHLETE_VIEW_VIDEO_FAILURE:
            return { ...state, loading: false, data: null, error: action.payload };

        default:
            return { ...state };
    }
}
/* ======================================= Side Effects ==========================================*/

function*  saveAthleteViewVideoSaga(action) {
    try {
        const response = yield fetchGraphMethod(
            action.payload.query,
            action.payload.variables,
            true
        );
        if (response.status === 200) {
            const saveAthlete = response.data.data.saveAthlete
                ? response.data.data.saveAthlete
                : null;
            yield put(HowToVideoSuccess({
                saveAthleteViewVid: saveAthlete
            }));
        } else {
            // messagePopup("", "Fetch Video List Failed", "error");
            yield put(HowToVideoFailure("Failed, Error occured"));
        }
    } catch (error) {
        // messagePopup("", "Fetch Video List Failed", "error");
        yield put(HowToVideoFailure("Failed, Error occured"));
    }
}

export function* saveAthleteViewVideo() {
    yield takeEvery(actionType.SAVE_ATHLETE_VIEW_VIDEO_START, saveAthleteViewVideoSaga);
}
