import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { coachActValueListStart } from '../../../../../redux/coach/dashboard/myRecruits/allCoachActValues';
import { coachCatchersTimeRangesListStart } from '../../../../../redux/coach/dashboard/myRecruits/allCoachCatchersTimeRanges';
import { coachGpaRangeListStart } from '../../../../../redux/coach/dashboard/myRecruits/allCoachgparanges';
import { getRequirementListStart, saveRequirementData } from '../../../../../redux/coach/dashboard/myRecruits/allCoachRequirements';
import { coachSatValueListStart } from '../../../../../redux/coach/dashboard/myRecruits/allCoachSatValue';
import { getUserInfo } from '../../../../../services/authService';
import { fetchGraphMethod } from '../../../../../services/httpService';
import messagePopup from '../../../../../utils/messagePopup';
import toastr from 'toastr';
import ScreenSwitch from './ScreenSwitch';
import { getAllCoachActValuesQuery, getAllCoachCatchersTimeRanges, getAllCoachGpaRangeQuery, getAllCoachPositionPlayers, getAllCoachSatValueQuery, getAllPitchersVelocitylhpsQuery, getAllPitchersVelocityrhpsQuery, getAllRequirementsQuery, saveRequirementsDataQuery } from './MyRecruitsQuery';

function RequirementsTab(props) {
    const [gpaRangeList, setGpaRangeList] = useState();
    const [satValuesList, setSatValuesList] = useState();
    const [actValuesList, setActValuesList] = useState();
    const [allRequirements, setAllRequirements] = useState();
    const [isSaved, setIsSaved] = useState(true);
    const [catchersTimeRangesList, setCatchersTimeRangesList] = useState();
    const [pitchersVelocityList, setPitchersVelocityList] = useState();
    const [pitchersVelocityRhpsList, setPitchersRhpsVelocityList] = useState();
    const [positionPlayers, setPositionPlayers] = useState();
    const [fields, setFields] = useState({
        minUnweightedGpa : 0,
        testOptional : "",
        minSat : 0,
        minAct : 0,
        pichersVelRhp : 0,
        pichersVelLhp : 0,
        catchersPopTime : 0,
        positionPlayer : 0

    })

    useEffect(()=> {
     getAllCoachGpaRangeData();
     getAllCoachSatRangesValueList();
     getAllCoachActRangesList();
     getAllRequirementsListData();
     getAllCoachCatchersTimeRangesData();
     getAllCoachPitchersVelocityData();
     getAllCoachPitchersrhpsVelocityData();
     getPositionPlayers();
    }, [])

    useEffect(()=> {
    if(props.allRequirements.data && props.allRequirements.data[0]){
        setAllRequirements(props.allRequirements.data[0])
    }
    }, [props.allRequirements.data])

    useEffect(()=> {
     if(props.catchersTimeRanges.data){
         setCatchersTimeRangesList(props.catchersTimeRanges.data);
     }
    }, [props.catchersTimeRanges.data])

    useEffect(()=> {
    if(allRequirements){
        let fieldsT = fields;
        fieldsT["id"] =allRequirements && allRequirements.id ? allRequirements.id : null;
        fieldsT["minUnweightedGpa"] =allRequirements && allRequirements.minimumunweightedgpa ? allRequirements.minimumunweightedgpa : null;
        fieldsT["testOptional"] = allRequirements && allRequirements.testoptional ? allRequirements.testoptional : null;
        fieldsT["minSat"] = allRequirements && allRequirements.minimumsat ? allRequirements.minimumsat : null;
        fieldsT["minAct"] = allRequirements && allRequirements.minimumact ? allRequirements.minimumact : null;
        fieldsT["pichersVelRhp"] =allRequirements && allRequirements.pitchersvelocityrhp ? allRequirements.pitchersvelocityrhp : null;
        fieldsT["pichersVelLhp"] =allRequirements && allRequirements.pitchersvelocitylhp ? allRequirements.pitchersvelocitylhp : null;
        fieldsT["catchersPopTime"] =allRequirements && allRequirements.catcherspoptime ? allRequirements.catcherspoptime : null;
        fieldsT["positionPlayer"] =allRequirements && allRequirements.postionofplayer ? allRequirements.postionofplayer : null;
        setFields({
            ...fieldsT
        });
    }
    }, [allRequirements])

    useEffect(()=> {
     if(props.coachGpaRangeList.data){
         setGpaRangeList(props.coachGpaRangeList.data);
     }
    }, [props.coachGpaRangeList.data])
    
    useEffect(()=> {
        if(props.coachSatValue.data){
            setSatValuesList(props.coachSatValue.data);
        }
       }, [props.coachSatValue.data])

       useEffect(()=> {
        if(props.coachActValue.data){
            setActValuesList(props.coachActValue.data);
        }
       }, [props.coachActValue.data])

       useEffect(()=>{
           props.handleCheckSaved(isSaved)
       },[isSaved])
    const getAllCoachGpaRangeData = () => {
        props.coachGpaRangeListStart({
            query :getAllCoachGpaRangeQuery
        })
    }
    const getAllCoachSatRangesValueList = () => {
        props.coachSatValueListStart({
            query : getAllCoachSatValueQuery
        })
    }
    const getAllCoachActRangesList = () => {
        props.coachActValueListStart({
            query : getAllCoachActValuesQuery
        })
    }
    const getAllCoachCatchersTimeRangesData = () => {
        props.coachCatchersTimeRangesListStart({
            query : getAllCoachCatchersTimeRanges
        })
    }
    const getAllRequirementsListData = () => {
        props.getRequirementListStart({
            query : getAllRequirementsQuery,
            variables : {
                where : {
                    coachid : getUserInfo() && getUserInfo().id ? getUserInfo().id : null
                }
            }
        })
    }

    const getAllCoachPitchersVelocityData = async () => {
        let res = await fetchGraphMethod(
            getAllPitchersVelocitylhpsQuery,
            null,
            true
        )
       let data =  res?.data?.data?.allCoachpitchersvelocitylhps?.Coachpitchersvelocitylhps;
        setPitchersVelocityList(data);
    };

    const getAllCoachPitchersrhpsVelocityData = async () => {
        let res = await fetchGraphMethod(
            getAllPitchersVelocityrhpsQuery,
            null,
            true
        )
       let data =  res?.data?.data?.allCoachpitchersvelocityrhps?.Coachpitchersvelocityrhps;
        setPitchersRhpsVelocityList(data);
    }
    const getPositionPlayers = async () => {
        let res = await fetchGraphMethod(
            getAllCoachPositionPlayers,
            null,
            true
        )
        let data = res?.data?.data?.allCoachpositionplayers?.Coachpositionplayers;
        setPositionPlayers(data);
    }
    const nextScreen = () => {
        setIsSaved(true);
        // messagePopup("", "Your requirements have been successfully saved.", "success")
        toastr.success("Your requirements have been successfully saved.", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
    }
    const saveRequirementsData = () => {
        props.saveRequirementData({
            query : saveRequirementsDataQuery,
            variables : 
            fields.id ? {
                obj : {
                    id : fields.id ? fields.id : null,
                    coachid : getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
                    minimumact : fields.minAct ? +(fields.minAct) : null,
                    minimumsat : fields.minSat ? +(fields.minSat) : null,
                    minimumunweightedgpa : fields.minUnweightedGpa ? +(fields.minUnweightedGpa) : null,
                    testoptional : fields.testOptional ? fields.testOptional : null,
                    pitchersvelocitylhp : fields.pichersVelLhp ? +(fields.pichersVelRhp) : null,
                    pitchersvelocityrhp : fields.pichersVelRhp ? +(fields.pichersVelRhp) : null,
                    catcherspoptime : fields.catchersPopTime ? +(fields.catchersPopTime) : null,
                    postionofplayer : fields.positionPlayer ? +(fields.positionPlayer) : null
                }
            }
                :
                {
                    obj : {
                        coachid : getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
                        minimumact : fields.minAct ? +(fields.minAct) : null,
                        minimumsat : fields.minSat ? +(fields.minSat) : null,
                        minimumunweightedgpa : fields.minUnweightedGpa ? +(fields.minUnweightedGpa) : null,
                        testoptional : fields.testOptional ? fields.testOptional : null,
                        pitchersvelocitylhp : fields.pichersVelLhp ? +(fields.pichersVelRhp) : null,
                        pitchersvelocityrhp : fields.pichersVelRhp ? +(fields.pichersVelRhp) : null,
                        catcherspoptime : fields.catchersPopTime ? +(fields.catchersPopTime) : null,
                        postionofplayer : fields.positionPlayer ? +(fields.positionPlayer) : null 
                    }
            },
          nextScreen : nextScreen
        })
    }
    const handleChange = (e, field) => {
        let fieldsT = fields;
        fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
        setFields({
            ...fieldsT
        });
        setIsSaved(false);
    }
  return <div>
      <div className="top-box">
                    <p>
                      Set your program's academic and athletic requirements to
                      help improve your potential athlete recommendations.
                    </p>
                  </div>
                  <div className="recruiting-detail-box">
                    <div className="field-box-item">
                      <h4>Academic Requirements</h4>
                      <div className="fieldBox flexBox">
                        <div className="column">
                          <label>Minimum Unweighted GPA (Out of 4.0)</label>
                          <select
                          value ={fields.minUnweightedGpa}
                          onChange={(e)=>handleChange(e, "minUnweightedGpa")}>
                            <option selected="" value="0">
                              Select
                            </option>
                            {gpaRangeList ? gpaRangeList.map((gparange, index)=> {
                                return (
                                    <option value={gparange.id}>{gparange.gpavalue}</option>
                                )
                            }) : null}
                          </select>
                        </div>
                        <div className="column">
                          <label>Test Optional</label>
                          <select
                            value ={fields.testOptional}
                            onChange={(e)=>handleChange(e, "testOptional")}
                            >
                                 <option selected="" value="">
                              Select
                            </option>
                            <option value="-">-</option>
                            <option value="Yes">
                              Yes
                            </option>
                            <option value="No">No</option>
                            <option value="Recommended">Recomended</option>
                          </select>
                        </div>
                        <div className="column">
                          <label>Minimum SAT (Out of 1600)</label>
                          <select
                          value={fields.minSat}
                          onChange={(e)=>handleChange(e, "minSat")}
                          >
                            <option selected="" value="0">
                              Select
                            </option>
                            {satValuesList ? satValuesList.map((satvalues, index)=> {
                                return (
                                    <option value={satvalues.id}>{satvalues.satvalue}</option>
                                )
                            }) : null}
                          </select>
                        </div>
                      </div>
                      <div className="fieldBox flexBox">
                        <div className="column">
                          <label>Minimum ACT (Out of 36)</label>
                          <select
                          value = {fields.minAct}
                          onChange={(e)=>handleChange(e, "minAct")}
                          >
                            <option selected="" value="0">
                              Select
                            </option>
                            {actValuesList ? actValuesList.map((actvalues, index)=> {
                                return (
                                    <option value={actvalues.id}>{actvalues.actvalue}</option>
                                )
                            }) : null}
                            
                            </select>
                        </div>
                      </div>
                    </div>
                    <div className="field-box-item">
                      <h4>Athletic Requirements</h4>
                      <div className="fieldBox flexBox">
                        <div className="column">
                          <label>Pitcher's Velocity (RHP)</label>
                          <select
                          value={fields.pichersVelRhp}
                          onChange={(e)=>handleChange(e, "pichersVelRhp")}
                          >
                            <option selected="" value="0">
                              Select
                            </option>
                            {pitchersVelocityRhpsList ? pitchersVelocityRhpsList.map((rhpslist, index)=> {
                                return (
                                    <option value={rhpslist.id}>{rhpslist.rhprange}</option>
                                )
                            }): null}
                            
                          </select>
                        </div>
                        <div className="column">
                          <label>Pitcher's Velocity (LHP)</label>
                          <select
                          value={fields.pichersVelLhp}
                          onChange={(e)=>handleChange(e, "pichersVelLhp")}>
                            <option selected value="0">
                              Select
                            </option>
                            {pitchersVelocityList ? pitchersVelocityList.map((picherslist, index)=> {
                                return (
                                    <option value={picherslist.id}>{picherslist.lhprange}</option>
                                )
                            }): null}
                            
                          </select>
                        </div>
                        <div className="column">
                          <label>Catcher's Pop Time (Sec.)</label>
                          <select
                          value={fields.catchersPopTime}
                          onChange={(e)=>handleChange(e, "catchersPopTime")}>
                            <option selected="" value="0">
                              Select
                            </option>
                            {catchersTimeRangesList ? catchersTimeRangesList.map((catchersTime, index)=> {
                                return (
                                    <option key ={index} value={catchersTime.id}>{catchersTime.timevalue}</option>
                                )
                            }): null}
                          </select>
                        </div>
                      </div>
                      <div className="fieldBox flexBox">
                    <div className="column">
                          <label>60 Yard dash (position player)</label>
                          <select
                            value={fields.positionPlayer}
                            onChange={(e)=>handleChange(e, "positionPlayer")}>
                            <option selected="" value="0">
                              Select
                            </option>
                            {positionPlayers ? positionPlayers.map((positions, index)=> {
                                return (
                                    <option key ={index} value={positions.id}>{positions.postionrange}</option>
                                )
                            }): null}
                          </select>
                        </div>
                    </div>
                    </div>
                   


                    <div className="btn-wrap">
                      <div className="item">
                        <button className="btn blue" onClick={saveRequirementsData}>Save</button>
                      </div>
                      <div className="next-prev-button">
                          <ScreenSwitch
                            prevLink={()=>props.handleSwitchTabData("needs")}
                            nextLink={()=>props.handleSwitchTabData("geographic")}
                            isSaved={isSaved}
                          />
                        </div>
                    </div>
                  </div>
  </div>;
}

 const mapStateToProps = (state) => {
    return {
    coachGpaRangeList : state.coach.coachDashboard.coachGpaRange,
    coachSatValue : state.coach.coachDashboard.coachSatValue,
    coachActValue : state.coach.coachDashboard.coachActValues,
    allRequirements : state.coach.coachDashboard.coachRequirements,
    catchersTimeRanges : state.coach.coachDashboard.coachCatchersTimeRanges
    }
};
 const mapDispatchToProps = (dispatch) => {
    return {
        coachGpaRangeListStart : (data) => dispatch(coachGpaRangeListStart(data)),
        coachSatValueListStart : (data) => dispatch(coachSatValueListStart(data)),
        coachActValueListStart : (data) => dispatch(coachActValueListStart(data)),
        saveRequirementData : (data) => dispatch(saveRequirementData(data)),
        getRequirementListStart : (data) => dispatch(getRequirementListStart(data)),
        coachCatchersTimeRangesListStart : (data) => dispatch(coachCatchersTimeRangesListStart(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RequirementsTab);
