import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  DASHBOARD_COUNT_START: "DASHBOARD_COUNT_START",
  DASHBOARD_COUNT_SUCCESS: "DASHBOARD_COUNT_SUCCESS",
  DASHBOARD_COUNT_FAILED: "DASHBOARD_COUNT_FAILED"
};

// ============================================ Actions ========================================================

export const dashboardCountStart = data => ({
  type: actionType.DASHBOARD_COUNT_START,
  payload: data
});

export const dashboardCountSuccess = data => ({
  type: actionType.DASHBOARD_COUNT_SUCCESS,
  payload: data
});

export const dashboardCountFailed = data => ({
  type: actionType.DASHBOARD_COUNT_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function dashboardCountReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.DASHBOARD_COUNT_START:
      return {
        ...state,
        loading: true
      };

    case actionType.DASHBOARD_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.DASHBOARD_COUNT_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* dashboardCountSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        dashboardCountSuccess({data: response.data.data.allMessagereplies.Messages, totalCount: response.data.data.allMessagereplies.totalCount})
      );
    } else {
      messagePopup("", "Message Count failed", "error");
      yield put(dashboardCountFailed("Message Count failed"));
    }
  } catch (error) {
    messagePopup("", "Message Count failed", "error");
    yield put(dashboardCountFailed("Message Count failed"));
  }
}

export function* watchDashboardCountSaga() {
  yield takeEvery(actionType.DASHBOARD_COUNT_START, dashboardCountSaga);
}