import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  FOLLOW_UNFOLLOW_START: "FOLLOW_UNFOLLOW_START",
  FOLLOW_UNFOLLOW_SUCCESS: "FOLLOW_UNFOLLOW_SUCCESS",
  FOLLOW_UNFOLLOW_FAILED: "FOLLOW_UNFOLLOW_FAILED",
};

// ============================================ Actions ========================================================

export const followUserStart = (data) => ({
  type: actionType.FOLLOW_UNFOLLOW_START,
  payload: data,
});

export const unfollowUserStart = (data) => ({
  type: actionType.FOLLOW_UNFOLLOW_START,
  payload: data,
});

export const followUnfollowSuccess = (data) => ({
  type: actionType.FOLLOW_UNFOLLOW_SUCCESS,
  payload: data,
});

export const followUnfollowFailed = (data) => ({
  type: actionType.FOLLOW_UNFOLLOW_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function followUnfollowReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.FOLLOW_UNFOLLOW_START:
      return {
        ...state,
        loading: true,
      };

    case actionType.FOLLOW_UNFOLLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.FOLLOW_UNFOLLOW_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* followUnfollowSaga(action) {
  const { data, refreshPage, follow, userListStart } = action.payload;
  try {
    const response = yield fetchMethod(
      `/api/Followers/FollowUnfollow`,
      data,
      "post",
      true
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        const msg = follow
          ? "User is followed successfully"
          : "User is Unfollowed";
        yield put(followUnfollowSuccess(msg));
        messagePopup("", msg, "success");
        refreshPage();
      }
    } else {
      const errorMsg = follow ? "Follow user failed" : "Unfollow user failed";
      messagePopup("", errorMsg, "error");
      yield put(followUnfollowFailed(errorMsg));
    }
  } catch (error) {
    const errorMsg = follow ? "Follow user failed" : "Unfollow user failed";
    messagePopup("", errorMsg, "error");
    yield put(followUnfollowFailed(errorMsg));
  }
}

export function* watchFollowUnfollowSaga() {
  yield takeEvery(actionType.FOLLOW_UNFOLLOW_START, followUnfollowSaga);
}
