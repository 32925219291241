import React from "react";
import logoH from "../assets/img/logoH.png";
import "../assets/css/claimProfileStep2Header.scss";
const ClaimProfileStep2Header = () => {
  return (
    <>
      <header className="dashboardLogo claimProfileHeader2">
        <div className="flex">
          <div className="logoP">
            <a href={void 0}>
              <img src={logoH} alt="logoH" />
              {/* <img src={logoH} alt="logo" className="mobile" /> */}
            </a>
          </div>
        </div>
      </header>
    </>
  );
};

export default ClaimProfileStep2Header;