import React from "react";
import { useState, useEffect } from "react";
import "./css/notification.css";
import { Menu, MenuItem, Button, TextField } from "@mui/material";
import { useHistory, Prompt } from "react-router-dom";
import messagePopup from '../../../../../utils/messagePopup';
import alertIcon from './img/alert.png';
import closeGrayIcon from "./img/close-gray-icon.svg";
import Dialog from "@mui/material/Dialog";
import { connect } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { getMessageQuery, saveMessageApi, premiumApiData, AddUserActivity, saveMessageReplyApi, athleteParentQuery, getAdvisorAthletes, getMessageReplyQuery } from './MessageQuery';
import { premiumDropdownStart } from '../../../../../redux/athlete/dashboard/message/premiumDropdown';
import { messageListStart } from "../../../../../redux/athlete/dashboard/message/message";
import { messageCountListStart } from '../../../../../redux/athlete/dashboard/message/messageCount';
import { athleteParentDropdownStart } from '../../../../../redux/athlete/dashboard/composeMessage/athleteParentDropdown';
import { saveMessageReplyListStart } from '../../../../../redux/athlete/dashboard/composeMessage/composeMessage';
import { fetchGraphMethod, fetchMethod } from "../../../../../services/httpService";
import { getUserInfo } from "../../../../../services/authService";
import toastr from 'toastr';

const ITEM_HEIGHT = 48;

const AdvisorComposeMessage = (props) => {
  let history = useHistory();
  const [anchorE1, setAnchorE1] = React.useState(null);
  const open = Boolean(anchorE1),
    [coachCount, setCoachCount] = useState(0),
    [advisorCount, setAdvisorCount] = useState(0),
    [archiveCount, setArchiveCount] = useState(0),
    [subject, setSubject] = useState(undefined),
    [message, setMessage] = useState(undefined),
    [advisorData, setAdvisorData] = useState([]),
    [openPremiumModal, setOpenPremiumModal] = useState(false),
    [ispremium, setIsPremium] = useState(0),
    [parentData, setParentData] = useState([]),
    [allAthlete, setAllAthlete] = useState([]),
    [selectedAthletes, setSelectedAthletes] = useState([]),
    [isBlocking, setIsBlocking] = useState(false),
    [supportCount, setSupportCount] = useState("");

  const handleAllAthleteChange = async (values) => {
    let searchText = values.target.value;
    if(searchText){
      // await services.location.searchAthlete({ athleteName: searchText, role: getUserInfo()?.role ?? "" }).then(res =>{
      //   if(res?.data?.response?.status == 200){
      //     setAllAthlete(res?.data?.response?.data)
      //   }
      // })
    }
  }  
  
  const changeSelectedAthlete = async (values) => {
    let selVal = values?.target?.value?.length ? values?.target?.value : [];
    setSelectedAthletes(selVal)
  }

  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };
  const handleCoachMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/coach/composeMessage" });
  };
  const handleAthleteMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/advisor/composeMessage" });
  }
  const handleSupportMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/support/replyMessage" });
  }
  const cancelBtnClick = () => {
    setOpenPremiumModal(!openPremiumModal);
  }
  const sendClickBtn = (e) => {
    e.preventDefault();
    setIsBlocking(true);
    if (selectedAthletes.length) {
        if (message) {
          let userIds = [];
          selectedAthletes.map(item => {
            userIds.push(item?.advisorId)
          })
          fetchMethod("/api/Messages/composeMessage", {
                msgsenderrole: JSON.parse(localStorage.getItem('user')).role,
                msgrecieverrole: "ADVISORS",
                msgsenderid: getUserInfo()?.id,
                msg: message.replace (/"/g, ""),
                userids: userIds,
                parentid: JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN' ? JSON.parse(localStorage.getItem('user')).athleteLoggedInId : 0
              },
              "post",
              true
            ).then(messageRes => {
                setIsBlocking(false);
                if (messageRes?.data?.response?.status == 200) {
                  toastr.success("Message has been sent successfully")
                  history.push('/message/advisor');
                } else {
                  messagePopup("", "Message has not been sent. Try agai!", "error");
                }
            })
        }
        else {
          messagePopup("", "Please enter message", "warning")
        }
    }
    else {
      messagePopup("", "Please specify recipient", "warning")
    }
  }

  const PremiumModal = ({ onActivateClick, onCancelClick }) => {
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img className='imgName' src={alertIcon} alt="Alert Circle Icon" />
              </div>
              <div className="content-box">
                <h4>
                  Sending message to advisor is a premium feature
                  <br />Activate your plan to unlock messaging system.
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn blue"
                data-dismiss="modal"
                onClick={onActivateClick}
              >
                Activate
              </button>
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onActivateBtn = e => {
    history.push({ pathname: "/programAndPricing" });
    setOpenPremiumModal(!openPremiumModal);
  }

  useEffect(() => {
    getMessageData()
    getAllAthletesData()
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  const getAllAthletesData = () => {
    fetchGraphMethod(getAdvisorAthletes, {
          where : { 
            athleteid: getUserInfo()?.id,
            active: 1
          }
        },
        true
    ).then(async (res) => {
        if(res?.status == 200){  
          res?.data?.data?.allAdvisorathletemappings?.Advisorathletemappings.map(item => {
            item['advisorName'] = item?.advisorathletemappingadvisoridmaprel?.Advisors[0]['firstname']+" "+item?.advisorathletemappingadvisoridmaprel?.Advisors[0]['lastname'];
            item['advisorEmail'] = item?.advisorathletemappingadvisoridmaprel?.Advisors[0]['emailid'];
            item['advisorId'] = item?.advisorathletemappingadvisoridmaprel?.Advisors[0]['userid'];
            return item;
          })
          setAllAthlete(res?.data?.data?.allAdvisorathletemappings?.Advisorathletemappings)
        }
    })
  }

  const getMessageData = () => {
    props.messageListStart({
      query: getMessageQuery,
      variables: {
        where: {
          order: 'id desc',
          messagesendto: parseFloat(JSON.parse(localStorage.getItem("user")).id),
          active: 1,
          istrash: 1,
          userrole: 'ADVISORS'
        }
      }
    });
    props.messageCountListStart({
      query: getMessageQuery,
      variables: {
        where: {
          order: 'id desc',
          messagesendto: parseFloat(JSON.parse(localStorage.getItem("user")).id),
          active: 1,
          istrash: 1,
        }
      }
    });
    if (JSON.parse(localStorage.getItem('user')).role === 'ATHLETES' || JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN') {
      props.premiumDropdownStart({
        query: premiumApiData,
        variables: {
          where: {
            order: 'id desc',
            userid: parseFloat(JSON.parse(localStorage.getItem("user")).id),
            active: 1,
          }
        }
      });
    }
    if (JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN') {
      props.athleteParentDropdownStart({
        query: athleteParentQuery,
        variables: {
          where: {
            order: 'id desc',
            userid: parseFloat(JSON.parse(localStorage.getItem("user")).athleteLoggedInId),
            active: 1,
          }
        }
      });
    }
  }

  useEffect(() => {
    let advisorData = [];
    let data = props?.messageList?.data?.data?.length > 0 ? props?.messageList?.data?.data?.map(item => {
      item['senderId'] = item?.messagemessagesendbyadvisormaprel?.Userdata?.length > 0 ? item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.id : "";
      item['senderName'] = item?.messagemessagesendbyadvisormaprel?.Userdata?.length > 0 ? item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.userfullname : "";
      advisorData.push(item)
    }) : [];
    setAdvisorData(advisorData);
  }, [props.messageList.data]);

  /*
  useEffect(() => {
    let coachData = [];
    let supportData = [];
    let advisorData = [];
    let data = props?.messageCountList?.data?.data?.length > 0 ? props?.messageCountList?.data?.data?.map(item => {
      if (item.userrole === 'ADVISORS') {
        advisorData.push(item);
      }
      else if (item.userrole === 'ADMIN') {
        supportData.push(item)
      }
      else if (item.userrole === 'COLLEGECOUCHES') {
        coachData.push(item);
      }
    }) : [];
    setCoachCount(coachData && coachData.length ? coachData.filter(item => item.isread === 0).length : "");
    setAdvisorCount(advisorData && advisorData.length ? advisorData.filter(item => item.isread === 0).length : "");
    setSupportCount(supportData && supportData.length ? supportData.filter(item => item.isread === 0).length : "");
  }, [props.messageCountList.data]); */

  useEffect(async () => {
    await fetchGraphMethod(getMessageReplyQuery, {
      where: {
        isread: 0,
        deletebyathlete: 0,
        msgreceiverid: getUserInfo()?.id
      }
    }, true
    ).then(res => {
      let supportData = [],
          advisorData = [],
          archiveData = [];
      res?.data?.data?.allMessagereplies?.Messagereplies.map(item => { 
        if (item.archivebyathlete) {
          archiveData.push(item)
        }
        else if(item?.sendmsgrolevalue == "Admin" && !item.archivebyathlete){
          supportData.push(item)
        }
        else if(item?.sendmsgrolevalue == "Advisor" && !item.archivebyathlete){
          advisorData.push(item)
        }
      })
      setSupportCount(supportData.length);
      setArchiveCount(archiveData.length);
      setAdvisorCount(advisorData.length);
    })
  })

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('user')).role === 'ATHLETES' || JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN') {
      if (props?.premiumDropdown?.data?.data?.length > 0 && props?.premiumDropdown?.data?.data[0]?.subscriptionplanid) {
        setIsPremium(1);
        setOpenPremiumModal(false)
      }
      else if (props?.premiumDropdown?.data?.data?.length === 0) {
        setOpenPremiumModal(true);
        setIsPremium(0);
      }
    }
  }, [props.premiumDropdown.data]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN') {
      if (props?.athleteParentDropdown?.data?.data?.length > 0) {
        let data = props?.athleteParentDropdown?.data?.data?.map(item => {
          return { id: item.id, name: item.parentfirstname.concat(item.parentlastname ? ' ' + item.parentlastname : '') }
        });
        setParentData(data)
      }
    }
  }, [props.athleteParentDropdown.data])

  return (
    <div className="pageContent">
      <div className="container">
        <div className="notification-with-sidebar">
          <nav className="notification-sidebar">
            <ul>
              <li className="button-link dropdown">
                <Button
                  className="btn lightblue dropdown-toggle"
                  onClick={e => handleClick(e)}
                >
                  Compose
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button"
                  }}
                  anchorEl={anchorE1}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch"
                    }
                  }}
                >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  {/* <MenuItem onClick={handleCoachMenu}>Coach</MenuItem> */}
                  <MenuItem onClick={handleSupportMenu}>Support</MenuItem>
                  <MenuItem onClick={handleAthleteMenu}>Advisor</MenuItem>
                </Menu>
              </li>
              <li
                className={window.location.href.includes('/support') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/support/replyMessage" })}
              >
                <div className="link-item">
                  <span>Support</span> <span>{supportCount ? supportCount : ""}</span>
                </div>
              </li>
              <li onClick={() => history.push({ pathname: "/message/advisor" })} >
                <div className="link-item">
                  <span>Advisor</span> <span>{advisorCount ? advisorCount : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/archive') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/archive" })}
              >
                <div className="link-item">
                  <span>Archive</span> <span>{archiveCount ? archiveCount : ""}</span>
                </div>
              </li>
            </ul>
          </nav>
          <div className="notification-detail-box">
            <div className="notification-search-box">
              <div className="search-box">
                <h1>New Message</h1>
              </div>
            </div>
            <div className="notification-messageDetail">
              <div className="message-hereInfo newMessage">
                <div className="message-replyInfo">
                  <form>
                    <Prompt
                      when={isBlocking}
                      message={(location) => {
                          if(location.pathname !== history.location.pathname){
                            return "Do you want to leave this page";
                          }
                      }
                      }
                    />
                    <div className="form-group flexbox d-block">
                      <label>Select Advisor</label>
                      <div className="autocomplete-class">
                        <Autocomplete
                          id="combo-box-demo"
                          multiple={true}
                          freeSolo={true}
                          options={allAthlete}
                          getOptionLabel={(option) => {
                              return option?.advisorName ? option?.advisorName : option.toString()
                            }
                          }
                          onChange={(e, data) => {
                            changeSelectedAthlete({
                              target: { value: data ? data : "" },
                            });
                          }}
                          value={selectedAthletes || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Enter Advisor Name"
                              value={selectedAthletes || ""}
                              onChange={(e) => {
                                handleAllAthleteChange({
                                  target: {
                                    value: e.target.value ? e.target.value : "",
                                  },
                                });
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {/* <div className="form-group flexbox">
                      <label>Subject:</label>
                      <input
                        type="text"
                        value={subject ? subject : ""}
                        disabled={advisorData?.length > 0 ? ispremium === 1 ? false : ispremium === 0 ? true : '' : true}
                        onChange={(e) => { setSubject(e.target.value) }} />
                    </div>
                    <div className="form-group flexbox">
                      <label>To:</label>
                      {selectedAthletes.map(item => <span className="toName">{item.advisorEmail}</span> )}
                    </div>
                    */}
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        placeholder="Enter Your message here..."
                        value={message ? message : ""}
                        disabled={ ispremium === 1 ? false : true }
                        onChange={(e) => { setMessage(e.target.value); setIsBlocking(true); }}></textarea>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn blue"
                        disabled={ispremium === 1 ? false : true}
                        onClick={(e) => { sendClickBtn(e) }}>Send</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={openPremiumModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  cancelBtnClick()
                }
              }}
              className="matDialogCustom"
      >
        <PremiumModal
          onActivateClick={(e) => onActivateBtn(e)}
          onCancelClick={cancelBtnClick}
        />
      </Dialog>
    </div>
  );
};


const mapStateToProps = state => {
  return {
    messageList: state.athlete.dashboard.message.messageList,
    premiumDropdown: state.athlete.dashboard.message.premiumDropdown,
    messageCountList: state.athlete.dashboard.message.messageCountList,
    athleteParentDropdown: state.athlete.dashboard.composeMessage.athleteParentDropdown
  };
};

const mapDispatchToProps = dispatch => {
  return {
    messageListStart: data => dispatch(messageListStart(data)),
    premiumDropdownStart: data => dispatch(premiumDropdownStart(data)),
    messageCountListStart: data => dispatch(messageCountListStart(data)),
    saveMessageReplyListStart: data => dispatch(saveMessageReplyListStart(data)),
    athleteParentDropdownStart: data => dispatch(athleteParentDropdownStart(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorComposeMessage);