import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  UPDATE_USER_COLLEGE_START: "UPDATE_USER_COLLEGE_START",
  UPDATE_USER_COLLEGE_SUCCESS: "UPDATE_USER_COLLEGE_SUCCESS",
  UPDATE_USER_COLLEGE_FAILURE: "UPDATE_USER_COLLEGE_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const updateUserCollegeStart = (data) => ({
  type: actionType.UPDATE_USER_COLLEGE_START,
  payload: data,
});

export const updateUserCollegeSuccess = (data) => ({
  type: actionType.UPDATE_USER_COLLEGE_SUCCESS,
  payload: data,
});

export const updateUserCollegeFailure = (data) => ({
  type: actionType.UPDATE_USER_COLLEGE_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function updateUserCollegeReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.UPDATE_USER_COLLEGE_START:
      return { ...state, loading: true };

    case actionType.UPDATE_USER_COLLEGE_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.UPDATE_USER_COLLEGE_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* updateUserCollegeSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const saveUsercollege = response.data.data
        ? response.data.data.saveUsercollege
          ? response.data.data.saveUsercollege
          : null
        : null;
      if (saveUsercollege) {
        yield put(updateUserCollegeSuccess(saveUsercollege));
        if (action.payload.getCardCount) action.payload.getCardCount();
        if(action.payload.getFavoriteCollegesList) action.payload.getFavoriteCollegesList();
        if(action.payload.nextScreen) action.payload.nextScreen();
      } else {
        messagePopup("", "Update College data Failed", "error");
        yield put(updateUserCollegeFailure("Failed, Error occured"));
        action.payload.getCollegesList();
      }
    } else {
      messagePopup("", "Update College data Failed", "error");
      yield put(updateUserCollegeFailure("Failed, Error occured"));
      action.payload.getCollegesList();
    }
  } catch (error) {
    messagePopup("", "Update College data Failed", "error");
    yield put(updateUserCollegeFailure("Failed, Error occured"));
    // action.payload.getCollegesList();
  }
}

export function* watchUpdateUserCollegeSaga() {
  yield takeEvery(actionType.UPDATE_USER_COLLEGE_START, updateUserCollegeSaga);
}
