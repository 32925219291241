import React, { useState, useEffect, useCallback, useRef } from "react";
import "./assets/css/editProfile.scss";
import CompleteVerification from "./assets/img/complete-verification-icon.svg";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import {getUTCTimeDate} from "../../../../../utils/dateTimeFormatter";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MenuItem, Select, Button } from "@mui/material";
import CameraIcon from "./assets/img/camera-icon.png";
import CloseRedIcon from "./assets/img/close-red-icon.svg";
import Information from "./assets/img/information-icon.svg";
import LeftSideEditProfile from "./LeftSideEditProfile";
import { graduationYearsListStart } from "../../../../../redux/common/graduationYears";
import SubHeader from "./SubHeader";
import { connect, useDispatch } from "react-redux";
import moment from 'moment';
import {NewValidName, NumFieldSymbolArr} from "../../../../../utils/validationUtils";
import { basicUserProfileListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/basicList";
import { sportsListStart } from "../../../../../redux/common/allSports";
import { getAuthToken, getUserInfo } from "../../../../../services/authService";
import {
  getAllBasicDataQuery, getAllVarsityQuery,
  getGpaMappingQuery,
  saveBasicUserProfileUpdateQuery,
  saveGpaMappingQuery,
  saveOnboardingUpdateQuery,
} from "./BasicQuery";
import { gradeListStart } from "../../../../../redux/athlete/onBoarding/gradeList";
import { gpaListStart } from "../../../../../redux/athlete/onBoarding/gpaList";
import {
  getAllGPAScalesQuery,
  getAllGradeQuery,
} from "../../onboarding/academics/AcademicsQuery";
import { getAllSchoolCurrentLevelQuery } from "../../onboarding/schoolInformation/SchoolInformationQuery";
import { updateBasicUserProfileListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/updateBasicUserProfile";
import { saveOnboardingStart } from "../../../../../redux/athlete/onBoarding/onboarding";
import { schoolCurrentLevelListStart } from "../../../../../redux/athlete/onBoarding/schoolCurrentLevel";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { imageDownloadUrl, imageUploadUrl, websiteDefaultUrl } from "../../../../../config";

//image Uploader
import Cropper from "react-easy-crop";
import Modal from "react-modal";
import getCroppedImg from "../../onboarding/profilePhoto/CropImage";
import { Dialog } from "@mui/material";
import axios from "axios";
import {getNcaaAndNaidQuery, NcaaAndNaidSaveQuery, saveAcademicFileQuery} from "./GradesScoresQuery";
import { transcriptAndAcademicsaveStart } from "../../../../../redux/athlete/dashboard/editUserProfile/TranscriptsAndAcademic";
import { useHistory } from "react-router-dom";
import ScreenSwitch from "./ScreenSwitch";
import {
  gpaMappingSaveStart,
  gpaMappingStart,
} from "../../../../../redux/athlete/onBoarding/gpaMapping";
import VerificationModal from "./VerificationModal";
import VerifiedModal from "../VerifiedModal";
import GoTopButton from "./GoTopButton";
import BackdropLoader from "../../../../common/Loader";
import disableNotInHighSchool from "../../../../../utils/disabledNotInHighSchool";
import swal from "sweetalert";
import {
  ncaaNaidListStart,
  ncaaNaidSaveListStart
} from "../../../../../redux/athlete/dashboard/editUserProfile/ncaaAndNaid";
import {curDate} from "../../../../common/currDateCheck";
import {fetchGraphMethod} from "../../../../../services/httpService";
import {getCamelCase} from "../../../../common/camelCaseFunc";
import toastr from "toastr";
import {AutoVerify} from "../../../../common/autoVerify";
import {getAthleteSysID} from "../../../../common/getProfileURLData";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};
const selectStyle = {
  position: "relative",
  fontSize: "0.875rem",
  fontWeight: 400,
  color: "#3e3e3e",
  lineHeight: 1.4,
  marginBottom: "10px"
}
const Basic = (props) => {
  const [fields, setFields] = useState({
    firstname: "",
    lastname: "",
    gender: "",
    sport: "",
    dateOfBirth: "",
    Gpascales: "1",
    gradyear: "",
    grades: "",
    gpa: "",
    gpaUWPlaceHolder:"",
    unweightedgpa: "",
    gpascalevalue: 1,
    gpaScale: "",
    weightedGPA: "",
    highscoolstatus: "",
    aboutMe: "",
    onbordingId: 0,
    currentlevel: "",
    athleteGradYear: 0,
    profileimages: "",
    profileimagename: "",
  });
  const [previousData, setPreviousData] = useState()
  const [errors, setErrors] = useState({});
  const [helpChatMsg, setHelpChatMsg] = useState(true);
  const [isSaved, setIsSaved] = useState(true);
  const [_tooltip, setToolTip] = useState(true);
  const [saveData, setSaveData] = useState(false);
  const [hamburger, setHamburger] = useState(true);
  const [allCurrectSchoolLevel, setAllCurrectSchoolLevel] = useState(null)
  const [filteredCurrectSchoolLevel, setFilteredCurrectSchoolLevel] = useState(null)
  const [allGrades, setAllGrades] = useState(null);
  const [filteredGrades, setAllFilteredGrades] = useState(null);

  const fakeInput = useRef(null);
  //profile image
  const [image, setImage] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showVerificationModalState, setShowVerificationModalState] = useState(false);
  const [showVerifiedModalState, setShowVerifiedModalState] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false)
  //this is for ncaa from grade and scroce
  const [ncaaAndNaidFields, setNcaaAndNaidFields] = useState({
    haveYouNcaaAndNaia: false,
    ncaaNumber: "",
    naiaNuber: "",
  });
  const [isNCCAlength, setIsNCCALength] = useState({ NCAAID:"", NAIA: ""})
  //this is for varsity
  const [allVarsity, setAllvarsity] = useState([])
  const [isProfileChange, setIsProfileChange] = useState(false)
  let userLS =  getUserInfo()
  //for render the left handside comp
  const dispatch = useDispatch()

  let history = useHistory();

  useEffect(() => {
    if (props.location.state) {
      setPreviousData(props.location.state)
    }
  }, [props.location.state])

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    const filedetails = event.target.files[0].name.split(".")
    let fileExtension = event.target.files[0].name.split(".")[filedetails.length - 1]?.toLowerCase();
    let fileSize = parseInt(event.target.files[0].size) / (1024 * 1024);
    if (!(
      fileExtension.toLowerCase() == "jpg" ||
      fileExtension.toLowerCase() == "jpeg" ||
      fileExtension.toLowerCase() == "png" ||
      fileExtension.toLowerCase() == "gif")
    ) {
      // messagePopup(
      //   "",
      //   "Image format must be png, jpeg, jpg, gif.",
      //   "error"
      // );
      toastr.error("Image format must be png, jpeg, jpg, gif.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
      })
      setIsOpen(false);
      setLoadingImage(false);
    } else if (parseFloat(fileSize).toFixed(2) >= 10) {
      // messagePopup("", "Image size should be of maximum 10 MB.", "error");
      toastr.error("Image size should be of maximum 10 MB.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }
    else if (event.target.files && event.target.files.length > 0) {
      const fieldsT = fields;
      const reader = new FileReader();
      if (event.target.files[0]) {
        fieldsT["fileName"] = event.target.files[0].name;
        fieldsT["fileType"] = event.target.files[0].type;
      }
      setFields(fieldsT);
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
        setIsOpen(true);
      });
      setIsProfileChange(true)
    }
  };

  const showCroppedCancelImage = () => {
    setIsOpen(false);
    const fieldsT = fields;
    // fields["selectedFile"] = "";
    fields["fileName"] = fields.profileimages;
    fields["fileType"] = "";
    setFields({
      ...fieldsT,
    });
  };

  const showCroppedImage = useCallback(async () => {
    try {
      setLoadingImage(true);
      const fieldsT = fields;
      const croppedImage = await getCroppedImg(image, croppedArea);
      fieldsT["file"] = URL.createObjectURL(croppedImage);
      var croppedFile = new File([croppedImage], fieldsT["fileName"], {
        lastModified: new Date(),
        type: fieldsT["fileType"],
      });
      fields["selectedFile"] = croppedFile;
      setFields({
        ...fieldsT,
      });
      setIsSaved(false);
      await addFiles(croppedImage);
      setIsOpen(false);
      setLoadingImage(false);
      // if(previousData && previousData.previousPage === "checklist"){
      //  props.history.push("/checklist");
      // }
    } catch (e) {
      console.error(e);
    }
  }, [croppedArea]);

  const addFiles = async (croppedimage) => {
    setLoadingImage(true);
    let formData = new FormData();
    formData.append("file", fields.selectedFile);
    const response =
      fields.selectedFile.name !== ""
        ? await axios({
          url: imageUploadUrl,
          method: "POST",
          data: formData,
          headers: {
            access_token: getAuthToken(),
          },
        })
        : "";
    setLoadingImage(false);
    if (fields.selectedFile.name !== "" ? response && response.status === 200 : true) {
      const uploadedFileName =
        fields.selectedFile.name !== ""
          ? response.data.result?.files?.file[0]?.name
            ? response.data.result.files.file[0].name
            : fields.selectedFile.name
          : fields.selectedFile.name;
      transcriptAndAcademicsaveStart({
        query: saveAcademicFileQuery,
        variables:
          fields.selectedFile.fileid === ""
            ? {
              obj: {
                userid: getUserInfo() ? getUserInfo().id : 0,
                description: fields.selectedFile.description,
                filename: uploadedFileName,
                filetype: parseInt(fields.selectedFile.type),
                // SPOR-989: fileavailableforallcolleges/allowMytracscript
                // doesn't appear to functionally be used anywhere
                fileavailableforallcolleges:
                  fields.selectedFile.allowMytracscript === true ? 1 : 0,
              },
            }
            : {
              obj: {
                id: fields.selectedFile.fileid,
                userid: getUserInfo() ? getUserInfo().id : 0,
                description: fields.selectedFile.description,
                filename: uploadedFileName,
                filetype: parseInt(fields.selectedFile.type),
                // SPOR-989: fileavailableforallcolleges/allowMytracscript
                // doesn't appear to functionally be used anywhere
                fileavailableforallcolleges:
                  fields.selectedFile.allowMytracscript === true ? 1 : 0,
              },
            },
      });
    }
  };
  const handleValidation = () => {
    let errorsT = errors;
    let formIsValid = true;
    if (fields.firstname === "") {
      formIsValid = false;
      errorsT["firstname"] = "Please enter your First Name.";
    }
    if (!NewValidName(fields.firstname)) {
      formIsValid = false;
      errorsT["firstname"] = "Please enter a valid First Name."
    }
    if (fields.lastname === "") {
      formIsValid = false;
      errorsT["lastname"] = "Please enter your Last Name.";
    }
    if (!NewValidName(fields.lastname)) {
      formIsValid = false;
      errorsT["lastname"] = "Please enter a valid Last Name.";
    }
    if (fields.gpascalevalue === "") {
      formIsValid = false;
      errorsT["gpascalevalue"] = "Please enter your Gpa Scale.";
    }
    if (fields.gpa) {
      const regexp = /^\d+(\.\d{1,2})?$/;
      if (!regexp.test(fields.gpa)) {
        formIsValid = false;
        errorsT["gpa"] = "Please enter valid numeric value only";
      }
    }
    if (fields.unweightedgpa) {
      const regexp = /^\d+(\.\d{1,2})?$/;
      if (!regexp.test(fields.unweightedgpa)) {
        formIsValid = false;
        errorsT["unweightedgpa"] = "Please enter valid numeric value only";
      }
    }

    if(!!fields.unweightedgpa && !!fields.gpa){
      const regexp = /^\d+(\.\d{1,2})?$/;
      if(regexp.test(fields.unweightedgpa) && regexp.test(fields.gpa)){
        if(parseFloat(fields.gpa) < parseFloat(fields.unweightedgpa)){
          formIsValid = false;
          errorsT["gpa"] = "GPA (W) should be greater than GPA (UW)";
        }
      }
    }
    if (fields.sport === "") {
      formIsValid = false;
      errorsT["sport"] = "Please select sport";
    }
    if (fields.gradyear === "") {
      formIsValid = false;
      errorsT["gradyear"] = "Please enter your Grad Year.";
    }
    if (fields.dateOfBirth === "") {
      formIsValid = false;
      errorsT["dateOfBirth"] = "Please enter your Date Of Birth.";
    }
    setErrors({
      ...errorsT
    });
    return {
      formIsValid
    }
  }
  useEffect(() => {
    props.graduationYearsListStart();
    props.gradeListStart({ query: getAllGradeQuery, variables: null });
    props.gpaListStart({ query: getAllGPAScalesQuery, variables: null });
    props.gpaMappingListStart({
      query: getGpaMappingQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
    getAllBasicDetails();
    props.schoolCurrentLevelListStart({
      query: getAllSchoolCurrentLevelQuery,
      variables: null,
    });
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);

  useEffect(() => {
    if (props.currentLevel.data) {
      setAllCurrectSchoolLevel(props.currentLevel.data)
    }
  }, [props.currentLevel.data])

  useEffect(() => {
    if (props.grades.data) {
      setAllGrades(props.grades.data)
    }
  }, [props.grades.data])
  useEffect(() => {
    if (fields.gradyear && props.graduationYears.data) {
      athleteGradYearValue()
    }
  }, [fields.gradyear, props.graduationYears.data])

  useEffect(() => {
    if (fields.athleteGradYear && allCurrectSchoolLevel) {
      getCurrentLevelListStart();
    }
  }, [fields.athleteGradYear, allCurrectSchoolLevel])

  useEffect(() => {
    if (allGrades && fields.athleteGradYear) {
      getAllGradesList()
    }
  }, [allGrades, fields.athleteGradYear])

  useEffect(() => {
    if (fields.gender) {
      getSportsList(fields.gender);
    }
  }, [fields.gender]);
  //this for getting NCAA data
  useEffect(()=>{
    getNcaaAndAaidList()
  },[])
 //this for getting varsity
  useEffect(()=>{
    getAllVarsity()
  },[])

  const getAllVarsity = async () =>{
    const _res = await fetchGraphMethod(getAllVarsityQuery,null,true)
    if(_res?.status == 200){
      setAllvarsity(_res?.data?.data?.allVarsities?.Varsities?.length > 0 ?
          _res?.data?.data?.allVarsities?.Varsities : []
      )
    }else{
      console.log("varsity api failed")
    }
  }
  const getNcaaAndAaidList = () => {
    props.ncaaNaidListStart({
      query: getNcaaAndNaidQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  };

  //this for ncaa
  useEffect(() => {
    if (props?.ncaaAndNaidDetails?.data?.length > 0) {
      const fieldT = ncaaAndNaidFields;
      const ncaaAndnaDetails = props.ncaaAndNaidDetails
          .data
          ? props.ncaaAndNaidDetails.data[
          props.ncaaAndNaidDetails.data.length - 1
              ]
          : null;
      if (ncaaAndnaDetails != null) {
        fieldT["ncaaNumber"] = ncaaAndnaDetails.ncaanumber
            ? parseInt(ncaaAndnaDetails.ncaanumber)
            : "";
        fieldT["haveYouNcaaAndNaia"] = ncaaAndnaDetails.havencaaandnadianumber
            ? ncaaAndnaDetails.havencaaandnadianumber == 1
                ? true
                : ncaaAndnaDetails.havencaaandnadianumber == 0
                    ? false
                    : ""
            : "";
        fieldT["naiaNuber"] = ncaaAndnaDetails.nadianumber
            ? parseInt(ncaaAndnaDetails.nadianumber)
            : "";
      }
      setNcaaAndNaidFields({ ...fieldT });
    }
  }, [props.ncaaAndNaidDetails]);

  const getSportsList = (gender) => {
    if (gender)
      props.sportsListStart({ gender });
  };

  const getAllGradesList = () => {
    const allGradesList = allGrades;
    const InHighSchoolOrBelowData = allGradesList.filter(item => item.ishighschool === 1);
    const NotInHighSchoolData = allGradesList.filter(item => item.ishighschool === 0);

    const FilteredGradeDataList = disableNotInHighSchool(fields.athleteGradYear) === true ? NotInHighSchoolData : InHighSchoolOrBelowData;
    setAllFilteredGrades(FilteredGradeDataList);
  }

  const getCurrentLevelListStart = () => {
    const allData = allCurrectSchoolLevel;
    const filteredHighData = allData.filter(item => item.ishighschool === 1);
    const filteredNotHighSchool = allData.filter(item => item.ishighschool === 0);

    const tempFinalFilteredData = disableNotInHighSchool(fields.athleteGradYear) === true ? filteredNotHighSchool : filteredHighData
    setFilteredCurrectSchoolLevel(tempFinalFilteredData);
  }
  // getting athlete grad year value from id
  const athleteGradYearValue = async () => {
    if (fields.gradyear) {
      const FilteredGradYear = props.graduationYears.data.find((item) => item.id === fields.gradyear);
      let fieldsT = fields;
      setFields({
        ...fieldsT,
        athleteGradYear: FilteredGradYear ? FilteredGradYear.yearValue : ""
      })
    }
  }

  const getAllBasicDetails = () => {
    props.basicUserProfileListStart({
      query: getAllBasicDataQuery,
      variables: {
        obj: {
          id: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  };
  useEffect(() => {
    if (props.editUserProfileBasicData && props.editUserProfileBasicData.data && !saveData) {
      const fieldT = fields;
      const { onboardingData, userData } = props.editUserProfileBasicData.data;
      fieldT['yearvarsity'] = !!onboardingData?.yearvarsity ? onboardingData?.yearvarsity : ""
      fieldT["userData"] = userData;
      fieldT["firstname"] = userData.athletefirstname;
      fieldT["lastname"] = userData.athletelastname;
      if (isSaved) {
        fieldT["gender"] = userData.gender;
        fieldT["sport"] = userData.athletesports;
      }
      fields["gradyear"] =
        userData.athleteathletegraduationyearmaprel &&
          userData.athleteathletegraduationyearmaprel.Graduationyears != "" &&
          userData.athleteathletegraduationyearmaprel.Graduationyears !=
          undefined
          ? parseInt(
            userData.athleteathletegraduationyearmaprel.Graduationyears[0].id
          )
          : null;
      fields["athleteGradYear"] =
        userData &&
          userData.athleteathletegraduationyearmaprel &&
          userData.athleteathletegraduationyearmaprel.Graduationyears != "" &&
          userData.athleteathletegraduationyearmaprel.Graduationyears[0] &&
          userData.athleteathletegraduationyearmaprel.Graduationyears[0]
            .yearvalue
          ? parseInt(
            userData.athleteathletegraduationyearmaprel.Graduationyears[0]
              .yearvalue
          )
          : null;
      fields["grades"] =
        onboardingData &&
          onboardingData.onboardinggradvaluemaprel &&
          onboardingData.onboardinggradvaluemaprel.Grades.length != ""
          ? parseInt(onboardingData.onboardinggradvaluemaprel.Grades[0].id)
          : "";
      fields["gpascalevalue"] =
        onboardingData &&
          onboardingData.onboardinggpascalevaluemaprel &&
          onboardingData.onboardinggpascalevaluemaprel.Gpascales.length != ""
          ? parseInt(
            onboardingData.onboardinggpascalevaluemaprel.Gpascales[0].id
          )
          : 1;
      fieldT["gpa"] =
        onboardingData && onboardingData.gpascale
          ? onboardingData.gpascale
          : "";
      fieldT["unweightedgpa"] =
          onboardingData && onboardingData.unweightedgpa
              ? onboardingData.unweightedgpa
              : "";//as of now it is float
      fieldT["currentlevel"] =
        !!onboardingData && onboardingData.currentlevelofschool
          ? parseInt(onboardingData.currentlevelofschool)
          : "";
      fieldT["aboutMe"] =
        onboardingData && onboardingData.aboutme ? onboardingData.aboutme : "";
      fieldT["dateOfBirth"] = onboardingData
        ? onboardingData.dateofbirth
          ? getUTCTimeDate(onboardingData.dateofbirth)
              // getColonSlotFormatedDate(onboardingData.dateofbirth)
          : ""
        : "";
      fields["onbordingId"] = onboardingData ? onboardingData.id : 0;
      fieldT["gpaScale"] = onboardingData
        ? onboardingData.gpascale
          ? onboardingData.gpascale
          : ""
        : "";
      fieldT["weightedGPA"] = onboardingData
        ? onboardingData.weightgpa
          ? onboardingData.weightgpa === 1
            ? true
            : false
          : ""
        : "";
      fieldT["profileimages"] = onboardingData
        ? onboardingData.profileimages
          ? onboardingData.profileimages
          : ""
        : "";

      //profileImage
      fieldT["file"] = onboardingData
        ? onboardingData.profileimages
          ? ` ${imageDownloadUrl + "/" + fields.profileimages}`
          : ""
        : "";
      fieldT["highscoolstatus"] = onboardingData
        ? onboardingData.highscoolstatus
          ? onboardingData.highscoolstatus === 1
            ? true
            : false
          : ""
        : "";
      setFields({ ...fieldT });
    }
  }, [props.editUserProfileBasicData]);

  //this is for updating the state when grade year is chnage
  useEffect(()=>{
    if (props.editUserProfileBasicData && props.editUserProfileBasicData.data) {
      const {onboardingData} = props.editUserProfileBasicData?.data
      const fieldT = fields;
      fieldT["currentlevel"] =
          !!onboardingData && onboardingData.currentlevelofschool
              ? parseInt(onboardingData.currentlevelofschool)
              : "";
      setFields({...fieldT});
    }
  },[props?.editUserProfileBasicData?.data?.userData])

  const handleChange = (field, e) => {
    setErrors({});
    let fieldsT = fields;
    if (field === "gpascale") {
      if (e.target.value === 1) {
        e.target.value = "ex 3.5";
      }
      if (e.target.value === 2) {
        e.target.value = "ex 4.5";
      }
      if (e.target.value === 3) {
        e.target.value = "ex 89";
        fieldsT["weightedGPA"] = false;
      }
    }
    if (field === "gpaUWPlaceHolder") {
      if (e.target.value === 1) {
        e.target.value = "ex 3.5";
      }
      if (e.target.value === 2) {
        e.target.value = "ex 4.5";
      }
      if (e.target.value === 3) {
        e.target.value = "ex 89";
        // fieldsT["weightedGPA"] = false;
      }
    }
    if (field == "unweightedgpa") {
      let val = e.target.value;
      if (fields.gpascalevalue == 1 && val > 4) {
        //earlier it was number now it was string as QA press enter it is passing number number but in db it is string
        e.target.value = "4";
      }
      if (fields.gpascalevalue == 2 && val > 5) {
        //earlier it was number now it was string as QA press enter it is passing number number but in db it is string
        e.target.value = "5";
      }
      if (fields.gpascalevalue == 3 && val > 100) {
        //earlier it was number now it was string as QA press enter it is passing number number but in db it is string
        e.target.value = "100";
      }
    }
    if (field == "gpa") {
      let val = e.target.value;
      if (fields.gpascalevalue == 1 && val > 5) {
        e.target.value = 5;
      }
      if (fields.gpascalevalue == 2 && val > 6) {
        e.target.value = 6;
      }
      if (fields.gpascalevalue == 3 && val > 100) {
        e.target.value = 100;
      }
      if(fields.gpascalevalue === 1 && parseFloat(val) > 4){
        fieldsT["weightedGPA"] = true;
      }
      if(fields.gpascalevalue === 1 && parseFloat(val) < 4){
        fieldsT["weightedGPA"] = false;
      }
      if(fields.gpascalevalue === 2 && val > 5){
        fieldsT["weightedGPA"] = true;
      }
      if(fields.gpascalevalue === 2 && val < 5){
        fieldsT["weightedGPA"] = false;
      }
    }
    if(field == "gradyear"){
      fieldsT[field] = e.target.value;
      fieldsT["currentlevel"] = ""
      fieldsT['grades'] = ""
      setFields({
        ...fieldsT,
      });
    }
    if(field == "firstname" || field == "lastname"){
          const regTest = /^[A-Za-z]+$/,
                testValue = e?.target?.value?.split("")[0]
          if (!regTest.test(testValue)) return
    }

    fieldsT[field] = e.target.value;
    setFields({
      ...fieldsT,
    });
    setIsSaved(false);
  };

  const handleRadioChange = (field, e) => {
    setErrors({
      errorsT: "",
    });

    if (fields.gender) {
      if (fields.gender !== "Male") {
        fields.sport = "";
      } else if (fields.gender !== "Female") {
        fields.sport = "";
      }
    }
    let fieldsT = fields;
    fieldsT["sport"] = ""
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setFields({
      ...fieldsT,
    });
    setIsSaved(false);
  };

  const clearGpaValueOnChangeGpaScale = () => {
    if (fields.gpascalevalue) {
      setFields({
        ...fields,
        gpa: "",
        unweightedgpa:"",
        weightedGPA: ""
      })
    }
  }

  const routeNextPage = () => {
    swal({
      text: "Basic details updated successfully.",
      icon: "success",
    }).then(async (okay) => {
      fakeInput?.current?.focus();
      fakeInput?.current?.select();
      getAllBasicDetails();
      getNcaaAndAaidList();
      setIsProfileChange(false)
      //this check is for controlling conditional routes
      if (previousData && previousData.previousPage === "checklist") {
        // props?.history?.push("/checklist");
        await AutoVerify()
        dispatch({ type: "RENDER_COUNT_START", payload: 1 })
        setTimeout(() => {
          props?.history?.push("/checklist");
          // window.location.href = '/checklist'
        }, 400)
      } else {
        await AutoVerify()
        dispatch({ type: "RENDER_COUNT_START", payload: 1 })
        setTimeout(() => {
          props?.history?.push("/basic");
          // window.location.href = "/basic";
        }, 400)
      }
    });
  }

  const UpdateBasicDataQuery = () => {
    props.updateBasicUserProfileListStart({
      query: saveBasicUserProfileUpdateQuery,
      variables: {
        obj: {
          id: getUserInfo() ? getUserInfo().id : 0,
          athletefirstname: getCamelCase(fields.firstname?.trim() ?? ""),//fields.firstname
          athletelastname: getCamelCase(fields.lastname?.trim() ?? ""),//fields.lastname
          gender: fields.gender,
          athletesports: parseFloat(fields.sport),
          athletegraduationyear: fields.gradyear ? fields.gradyear : null,
        },
      },
    });
    {!!fields.sport && saveAthleteLS(parseFloat(fields.sport))}
  }
  //changing Athlete Sport on LS
  const saveAthleteLS = (sportID) =>{
    const filterSport = props?.sportsList?.data?.length > 0 ?
                         props?.sportsList?.data.filter(el => el?.id == sportID) : []
          if (filterSport.length){
            //this is update the athlete basic info in local storage
            userLS['athleteSportName'] = filterSport[0]?.sportName
            userLS['Athlete_Sports'] = +sportID
            localStorage.setItem("user", JSON.stringify(userLS))
          }
    const updateLS =  fields
         userLS['FirstName'] = updateLS?.firstname?.trim() ?? ""
         userLS['lastName'] = updateLS?.lastname?.trim() ?? ""
         localStorage.setItem("user", JSON.stringify(userLS))
  }

  const SaveOnbordingDataQuery = () => {
    props.saveOnboardingStart({
      query: saveOnboardingUpdateQuery,
      variables: {
        obj: {
          id: fields.onbordingId,
          userid: getUserInfo() ? getUserInfo().id : 0,
          yearvarsity: !!fields.yearvarsity ?  parseInt(fields.yearvarsity) : null, //key as is added as per client
          // changes on april sheet
          dateofbirth: fields.dateOfBirth ? fields.dateOfBirth : null,
          gradvalue: fields.grades == "" ? null : fields.grades,
          unweightedgpa: !!fields.unweightedgpa ? fields.unweightedgpa : null,
          gpascalevalue:
            fields.gpascalevalue == "" ? null : fields.gpascalevalue,
          gpascale: fields.gpa.toString(),
          aboutme: fields.aboutMe,
          weightgpa: fields.weightedGPA == true ? 1 : 0,
          currentlevelofschool: parseInt(fields.currentlevel),
          profileimages: fields.fileName,
          highscoolstatus: disableNotInHighSchool(fields.athleteGradYear) === true ? 1 : 0,
          ...(isProfileChange && { profileupdatedate : new Date()})
        },
      },
      nextScreen: routeNextPage,
    });
  }
  const saveGpaDataQuery = () => {
    let gpaMappingid = null;
    if (
      props.userGpaMapping &&
      props.userGpaMapping.data &&
      props.userGpaMapping.data.gpaMappingData &&
      props.userGpaMapping.data.gpaMappingData.allGpamappings &&
      props.userGpaMapping.data.gpaMappingData.allGpamappings.Gpamappings &&
      props.userGpaMapping.data.gpaMappingData.allGpamappings.Gpamappings[0]
    ) {
      gpaMappingid =
        props.userGpaMapping.data.gpaMappingData.allGpamappings.Gpamappings[0]
          .id;
    } else {
      if (
        props.userGpaMapping &&
        props.userGpaMapping.data &&
        props.userGpaMapping.data.saveGpamapping &&
        props.userGpaMapping.data.saveGpamapping.id
      ) {
        gpaMappingid = props.userGpaMapping.data.saveGpamapping.id;
      }
    }
    props.saveGpaMappingStart({
      query: saveGpaMappingQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          weightedgpa: fields.weightedGPA == true ? 1 : 0,
          gparangevalueid: parseInt(fields.gpascalevalue),
          gpa: parseFloat(fields.gpa),
        },
      },
    });
    setIsSaved(true);
  }
  const NCAAValidation = () =>{
    let status = true,
          errorT = isNCCAlength
    if(!!ncaaAndNaidFields?.ncaaNumber) {
       if ((ncaaAndNaidFields?.ncaaNumber).toString().length != 10){
         errorT["NCAAID"] = "NCAA ID Should Be 10 Digit"
       }else{
         errorT["NCAAID"] = ""
       }
    }else{
      errorT["NCAAID"] = ""
    }
    if(!!ncaaAndNaidFields?.naiaNuber){
      if(ncaaAndNaidFields?.naiaNuber.toString().length != 10){
        errorT["NAIA"] = "NAIA Number Should Be 10 Digit"
      }else{
        errorT["NAIA"] = ""
      }
    }else{
      errorT["NAIA"] = ""
    }
    if(!!isNCCAlength?.NAIA || !!isNCCAlength?.NCAAID){
      setIsNCCALength(errorT)
      if (errorT["NCAAID"] == "" && errorT["NAIA"] == ""){
        status = true
      }else{
        status = false
      }
    }
    return status
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validateForm = handleValidation(),
          validateNCAA = !!ncaaAndNaidFields?.haveYouNcaaAndNaia ?  NCAAValidation() : true;
    if (validateForm.formIsValid === true && validateNCAA) {
      setSaveData(true);
      UpdateBasicDataQuery();
      SaveOnbordingDataQuery();
      saveGpaDataQuery();
      saveNCAA()
      //this check  is for conditional routes
      if (previousData && previousData.previousPage === "checklist") {
        props?.history?.push("/checklist");
        // window.location.href = '/#/checklist'
      }
    }
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };

  const showVerificationModal = () => {
    setShowVerificationModalState(!showVerificationModalState);
  };

  const handleHamburger = () => {
    setHamburger(!hamburger);
  };
  const routeBackToProfile = () => {
    props.history.push(
        {pathname: `${getAthleteSysID()}`,
        state: {isPublic : false}
        }
        // '/profile/1'
    )
    sessionStorage.setItem('athlete_own_profile', true)
  }
  //this func is for saving ncaa data
  const saveNCAA = ()=>{
    props.ncaaNaidSaveListStart({
      query: NcaaAndNaidSaveQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          nadianumber: ncaaAndNaidFields.naiaNuber
              ? ncaaAndNaidFields.naiaNuber.toString()
              : null,
          ncaanumber: ncaaAndNaidFields.ncaaNumber
              ? ncaaAndNaidFields.ncaaNumber.toString()
              : null,
          havencaaandnadianumber: ncaaAndNaidFields.haveYouNcaaAndNaia
              ? ncaaAndNaidFields.haveYouNcaaAndNaia == true
                  ? 1
                  : 0
              : null,
        },
      },
    });
  }
  //handle for ncaa and nina
  const ncaaAndNaiaHandalChange = (field, e) => {
    if(e?.target?.value?.length > 10) return console.log("number restriction")
    let fieldsT = ncaaAndNaidFields;
    //this check is for toggle and empty the state when user when check box is checked
    if(field == "haveYouNcaaAndNaia"){
      fieldsT["ncaaNumber"] = ""
      fieldsT['naiaNuber'] = ""
      setIsNCCALength({ NCAAID:"", NAIA: ""})
    }
    fieldsT[field] = e.target.value;
    setNcaaAndNaidFields({
      ...fieldsT,
    });
    NCAAValidation()
    setIsSaved(false);
    // props.setIsSaved(false);
  };
  return (
    <div>
      <BackdropLoader open={loadingImage || props.updateBasicUserProfile.loading} />
      <div className="pageContent">
        <div className="editProfile">
          <input className="fakeInput" type="text" ref={fakeInput} />
          <div className="container">
            <div
              className={hamburger == true ? "flex" : "flex inactive"}
              id="wrapper"
            >
              <SubHeader
                hamburger={handleHamburger}
                onSave={handleSubmit}
              />
              <div className="sidebar">
                <LeftSideEditProfile isSaved={isSaved} />
              </div>
              <div className="editSection">
                <div className="flexRow">
                  <div className="title">Basic</div>
                  <div className="btnWrap">
                    <button
                      className="btn outlined green rounded medium backprofileBtn"
                      onClick={() => routeBackToProfile()}
                    >
                      Back To Profile
                    </button>
                    <button
                      className="btn outlined green rounded medium"
                      onClick={() => { routeBackToProfile() }}
                    >
                      Preview Profile
                    </button>
                    <button
                      className="btn outlined blue rounded medium"
                      onClick={() => {
                        sessionStorage.setItem('athlete_own_profile', false)
                        setTimeout(()=>{
                          window.open(`${websiteDefaultUrl}athlete/wyatt-johnson-74144936`, "_blank")
                        },500)
                      }
                      }
                    >
                      Sample Profile
                    </button>
                  </div>
                </div>

                <div className="check-list-sec">
                  <div className="profile-upload-main-box">
                    <div className="profile-upload-box">
                      <div className="profile">
                        <div className="pic">
                          <img
                            src={fields.file ? fields.file : CameraIcon}
                            alt=""
                            className="verified new"
                          />
                        </div>
                        <span className="checkIcon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 29.432 29.47"
                          >
                            <g id="blueTick" transform="translate(0 0)">
                              <path
                                id="Subtraction_10"
                                data-name="Subtraction 10"
                                d="M13.51.8a1.311,1.311,0,0,1,2.411,0l0,0a1.311,1.311,0,0,0,2.18.363h0a1.311,1.311,0,0,1,2.281.78v.018a1.311,1.311,0,0,0,1.943,1.049l0,0a1.311,1.311,0,0,1,1.9,1.476l-.008.032a1.311,1.311,0,0,0,1.5,1.621l.018,0a1.311,1.311,0,0,1,1.321,2.014l-.025.037a1.311,1.311,0,0,0,.887,2.017l.033.005a1.311,1.311,0,0,1,.593,2.333l-.038.029a1.311,1.311,0,0,0,.182,2.2l.04.021a1.311,1.311,0,0,1-.2,2.4L28.5,17.2a1.311,1.311,0,0,0-.542,2.135l.032.035a1.311,1.311,0,0,1-.97,2.2h-.026a1.311,1.311,0,0,0-1.208,1.844l.016.037a1.311,1.311,0,0,1-1.635,1.769l-.01,0a1.311,1.311,0,0,0-1.743,1.354l0,.026a1.311,1.311,0,0,1-2.122,1.144h0a1.311,1.311,0,0,0-2.09.716l0,.011a1.311,1.311,0,0,1-2.379.4h0a1.311,1.311,0,0,0-2.21,0h0a1.311,1.311,0,0,1-2.379-.4l0-.011a1.311,1.311,0,0,0-2.09-.716h0A1.311,1.311,0,0,1,7.016,26.6l0-.026a1.311,1.311,0,0,0-1.743-1.354l-.01,0a1.311,1.311,0,0,1-1.635-1.769l.016-.037a1.311,1.311,0,0,0-1.208-1.844H2.412a1.311,1.311,0,0,1-.97-2.2l.032-.035A1.311,1.311,0,0,0,.931,17.2l-.038-.013a1.311,1.311,0,0,1-.2-2.4l.04-.021a1.311,1.311,0,0,0,.182-2.2l-.038-.029a1.311,1.311,0,0,1,.593-2.333L1.5,10.2a1.311,1.311,0,0,0,.887-2.017l-.025-.037A1.311,1.311,0,0,1,3.688,6.136l.018,0A1.311,1.311,0,0,0,5.2,4.518l-.008-.032A1.311,1.311,0,0,1,7.1,3.009l0,0A1.311,1.311,0,0,0,9.045,1.962V1.944a1.311,1.311,0,0,1,2.281-.78h0A1.311,1.311,0,0,0,13.508.8Z"
                                transform="translate(0 0)"
                                fill="#00b9ff"
                              />
                              <path
                                id="Icon_feather-check"
                                data-name="Icon feather-check"
                                d="M16.188,9l-7,7.641L6,13.168"
                                transform="translate(3.622 1.914)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                        </span>

                        <div className="container-cropper">
                          <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={() => setIsOpen(false)}
                            style={customStyles}
                            contentLabel="Example Modal"
                            ariaHideApp={false}
                          >
                            <div>
                              <Cropper
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                              />
                              <Button
                                onClick={showCroppedImage}
                                variant="contained"
                                color="primary"
                              >
                                Save
                              </Button>
                              <Button
                                className="imageCancel"
                                style={{ margin: "5px", background: "#C0321F" }}
                                onClick={showCroppedCancelImage}
                                variant="contained"
                                color="primary"
                              >
                                Cancel
                              </Button>
                            </div>
                          </Modal>
                        </div>
                      </div>
                      <div className="profile-detail">
                        <h3>
                          Profile Picture
                          {JSON.parse(localStorage.getItem('user')).verifiedUserStatus === 1 ?
                            <span className="checkIcon" onClick={e=>setShowVerifiedModalState(!showVerifiedModalState)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 29.432 29.47"
                              >
                                <g id="blueTick" transform="translate(0 0)">
                                  <path
                                    id="Subtraction_10"
                                    data-name="Subtraction 10"
                                    d="M13.51.8a1.311,1.311,0,0,1,2.411,0l0,0a1.311,1.311,0,0,0,2.18.363h0a1.311,1.311,0,0,1,2.281.78v.018a1.311,1.311,0,0,0,1.943,1.049l0,0a1.311,1.311,0,0,1,1.9,1.476l-.008.032a1.311,1.311,0,0,0,1.5,1.621l.018,0a1.311,1.311,0,0,1,1.321,2.014l-.025.037a1.311,1.311,0,0,0,.887,2.017l.033.005a1.311,1.311,0,0,1,.593,2.333l-.038.029a1.311,1.311,0,0,0,.182,2.2l.04.021a1.311,1.311,0,0,1-.2,2.4L28.5,17.2a1.311,1.311,0,0,0-.542,2.135l.032.035a1.311,1.311,0,0,1-.97,2.2h-.026a1.311,1.311,0,0,0-1.208,1.844l.016.037a1.311,1.311,0,0,1-1.635,1.769l-.01,0a1.311,1.311,0,0,0-1.743,1.354l0,.026a1.311,1.311,0,0,1-2.122,1.144h0a1.311,1.311,0,0,0-2.09.716l0,.011a1.311,1.311,0,0,1-2.379.4h0a1.311,1.311,0,0,0-2.21,0h0a1.311,1.311,0,0,1-2.379-.4l0-.011a1.311,1.311,0,0,0-2.09-.716h0A1.311,1.311,0,0,1,7.016,26.6l0-.026a1.311,1.311,0,0,0-1.743-1.354l-.01,0a1.311,1.311,0,0,1-1.635-1.769l.016-.037a1.311,1.311,0,0,0-1.208-1.844H2.412a1.311,1.311,0,0,1-.97-2.2l.032-.035A1.311,1.311,0,0,0,.931,17.2l-.038-.013a1.311,1.311,0,0,1-.2-2.4l.04-.021a1.311,1.311,0,0,0,.182-2.2l-.038-.029a1.311,1.311,0,0,1,.593-2.333L1.5,10.2a1.311,1.311,0,0,0,.887-2.017l-.025-.037A1.311,1.311,0,0,1,3.688,6.136l.018,0A1.311,1.311,0,0,0,5.2,4.518l-.008-.032A1.311,1.311,0,0,1,7.1,3.009l0,0A1.311,1.311,0,0,0,9.045,1.962V1.944a1.311,1.311,0,0,1,2.281-.78h0A1.311,1.311,0,0,0,13.508.8Z"
                                    transform="translate(0 0)"
                                    fill="#00b9ff"
                                  />
                                  <path
                                    id="Icon_feather-check"
                                    data-name="Icon feather-check"
                                    d="M16.188,9l-7,7.641L6,13.168"
                                    transform="translate(3.622 1.914)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </span>
                            : <img
                              className="icon"
                              src={CompleteVerification}
                              alt="Verified"
                              onClick={showVerificationModal}
                            />}
                        </h3>
                        <p>Image size should be of maximum 10 MB.</p>
                        <label htmlFor="uploadpicture" className="btn green">
                          Upload Photo
                        </label>
                        <input
                          type="file"
                          id="uploadpicture"
                          onChange={(e) => {
                            onSelectFile(e);
                            e.currentTarget.value = null;
                          }}
                          accept={'image/png,image/jpeg,image/jpg,image/gif'}
                          style={{ display: modalIsOpen ? "none" : "block" }}
                          hidden
                        />
                      </div>
                    </div>
                    <div
                      className="help-message-box"
                      style={{ display: helpChatMsg ? "block" : "none" }}
                    >
                      <p>
                        Need some help? Call 1.888.978.7084 or use our help chat
                      </p>
                      <button
                        className="close-button"
                        onClick={() => setHelpChatMsg(false)}
                      >
                        <img src={CloseRedIcon} alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="editprofile-common-form">
                    <h4>
                      * Below is the name that will appear on your Athlete
                      Profile.
                    </h4>
                    <form>
                      <div className="fieldBox flexBox">
                        <div className="half">
                          <label>First Name<sup>*</sup></label>

                          <input
                            type="text"
                            value={fields.firstname}
                            placeholder="First name"
                            onChange={(e) => {
                              handleChange("firstname", e);
                            }}
                          />
                          <div className="error">{errors.firstname}</div>
                        </div>
                        <div className="half">
                          <label>Last Name<sup>*</sup></label>
                          <input
                            type="text"
                            value={fields.lastname}
                            placeholder="Last name"
                            onChange={(e) => {
                              handleChange("lastname", e);
                            }}
                          />
                          <div className="error">{errors.lastname}</div>
                        </div>
                      </div>

                      {/* ==================Gender========================= */}
                      <div className="fieldBox flexBox">
                        <div className="half">
                          <label style={{ marginBottom: "0" }}>Gender</label>

                          <div className="radioflexBox sf-radio-global">
                            <FormControl component="fieldset">
                              <FormLabel component="legend"></FormLabel>
                              <RadioGroup
                                name="radioGroup1"
                                row
                                value={fields.gender || ""}
                                onChange={(e) => handleRadioChange("gender", e)}
                              >
                                <FormControlLabel
                                  control={<Radio name="male" />}
                                  className="option"
                                  label="Male"
                                  value="Male"
                                />
                                <FormControlLabel
                                  control={<Radio name="female" />}
                                  className="option"
                                  label="Female"
                                  value="Female"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                        <div className="half">
                          <div style={selectStyle}>Athlete Sport<sup style={{color: "#c0321f"}}>*</sup></div>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              // labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={fields.sport || ""}
                              onChange={(e) => handleChange("sport", e)}
                              // label="Select"
                              // displayEmpty
                            >
                              <MenuItem value="">
                                <em>Select</em>
                              </MenuItem>
                              {props.sportsList.data &&
                                props.sportsList.data.map((item, index) => {
                                 if(item?.id == 1 || item?.id == 4){
                                  return <MenuItem key={index} value={item.id}>
                                     {item.sportName}
                                   </MenuItem>
                                 }
                                })}
                            </Select>
                          </FormControl>
                          <div className="error">{errors.sport}</div>
                        </div>
                      </div>

                      {/* --------date of birth-------------- */}
                      <div className="fieldBox flexBox">
                        <div className="half">
                          <label>
                            Date of Birth<sup style={{color: "#c0321f"}}>*</sup>{" "}
                            <span className="checkIcon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 29.432 29.47"
                              >
                                <g id="blueTick" transform="translate(0 0)">
                                  <path
                                    id="Subtraction_10"
                                    data-name="Subtraction 10"
                                    d="M13.51.8a1.311,1.311,0,0,1,2.411,0l0,0a1.311,1.311,0,0,0,2.18.363h0a1.311,1.311,0,0,1,2.281.78v.018a1.311,1.311,0,0,0,1.943,1.049l0,0a1.311,1.311,0,0,1,1.9,1.476l-.008.032a1.311,1.311,0,0,0,1.5,1.621l.018,0a1.311,1.311,0,0,1,1.321,2.014l-.025.037a1.311,1.311,0,0,0,.887,2.017l.033.005a1.311,1.311,0,0,1,.593,2.333l-.038.029a1.311,1.311,0,0,0,.182,2.2l.04.021a1.311,1.311,0,0,1-.2,2.4L28.5,17.2a1.311,1.311,0,0,0-.542,2.135l.032.035a1.311,1.311,0,0,1-.97,2.2h-.026a1.311,1.311,0,0,0-1.208,1.844l.016.037a1.311,1.311,0,0,1-1.635,1.769l-.01,0a1.311,1.311,0,0,0-1.743,1.354l0,.026a1.311,1.311,0,0,1-2.122,1.144h0a1.311,1.311,0,0,0-2.09.716l0,.011a1.311,1.311,0,0,1-2.379.4h0a1.311,1.311,0,0,0-2.21,0h0a1.311,1.311,0,0,1-2.379-.4l0-.011a1.311,1.311,0,0,0-2.09-.716h0A1.311,1.311,0,0,1,7.016,26.6l0-.026a1.311,1.311,0,0,0-1.743-1.354l-.01,0a1.311,1.311,0,0,1-1.635-1.769l.016-.037a1.311,1.311,0,0,0-1.208-1.844H2.412a1.311,1.311,0,0,1-.97-2.2l.032-.035A1.311,1.311,0,0,0,.931,17.2l-.038-.013a1.311,1.311,0,0,1-.2-2.4l.04-.021a1.311,1.311,0,0,0,.182-2.2l-.038-.029a1.311,1.311,0,0,1,.593-2.333L1.5,10.2a1.311,1.311,0,0,0,.887-2.017l-.025-.037A1.311,1.311,0,0,1,3.688,6.136l.018,0A1.311,1.311,0,0,0,5.2,4.518l-.008-.032A1.311,1.311,0,0,1,7.1,3.009l0,0A1.311,1.311,0,0,0,9.045,1.962V1.944a1.311,1.311,0,0,1,2.281-.78h0A1.311,1.311,0,0,0,13.508.8Z"
                                    transform="translate(0 0)"
                                    fill="#00b9ff"
                                  />
                                  <path
                                    id="Icon_feather-check"
                                    data-name="Icon feather-check"
                                    d="M16.188,9l-7,7.641L6,13.168"
                                    transform="translate(3.622 1.914)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </span>
                          </label>
                          <input
                            type="date"
                            value={fields.dateOfBirth || ""}
                            name="date"
                            id="date"
                            onChange={(e) =>curDate(e.target.value) ?  handleChange("dateOfBirth", e) : ""}
                            // onKeyDown={(e) => e.preventDefault()}
                            max={moment().format("YYYY-MM-DD")}
                          />
                          <div className="error">{errors.dateOfBirth}</div>
                        </div>
                      </div>
                      {/* --------graduationYears-------------- */}
                      <div className="fieldBox flexBox">
                        <div className="half">
                          <div style={selectStyle}>
                            High School Grad Year<sup style={{color: "#c0321f"}}>*</sup> {" "}
                            <span className="checkIcon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 29.432 29.47"
                              >
                                <g id="blueTick" transform="translate(0 0)">
                                  <path
                                    id="Subtraction_10"
                                    data-name="Subtraction 10"
                                    d="M13.51.8a1.311,1.311,0,0,1,2.411,0l0,0a1.311,1.311,0,0,0,2.18.363h0a1.311,1.311,0,0,1,2.281.78v.018a1.311,1.311,0,0,0,1.943,1.049l0,0a1.311,1.311,0,0,1,1.9,1.476l-.008.032a1.311,1.311,0,0,0,1.5,1.621l.018,0a1.311,1.311,0,0,1,1.321,2.014l-.025.037a1.311,1.311,0,0,0,.887,2.017l.033.005a1.311,1.311,0,0,1,.593,2.333l-.038.029a1.311,1.311,0,0,0,.182,2.2l.04.021a1.311,1.311,0,0,1-.2,2.4L28.5,17.2a1.311,1.311,0,0,0-.542,2.135l.032.035a1.311,1.311,0,0,1-.97,2.2h-.026a1.311,1.311,0,0,0-1.208,1.844l.016.037a1.311,1.311,0,0,1-1.635,1.769l-.01,0a1.311,1.311,0,0,0-1.743,1.354l0,.026a1.311,1.311,0,0,1-2.122,1.144h0a1.311,1.311,0,0,0-2.09.716l0,.011a1.311,1.311,0,0,1-2.379.4h0a1.311,1.311,0,0,0-2.21,0h0a1.311,1.311,0,0,1-2.379-.4l0-.011a1.311,1.311,0,0,0-2.09-.716h0A1.311,1.311,0,0,1,7.016,26.6l0-.026a1.311,1.311,0,0,0-1.743-1.354l-.01,0a1.311,1.311,0,0,1-1.635-1.769l.016-.037a1.311,1.311,0,0,0-1.208-1.844H2.412a1.311,1.311,0,0,1-.97-2.2l.032-.035A1.311,1.311,0,0,0,.931,17.2l-.038-.013a1.311,1.311,0,0,1-.2-2.4l.04-.021a1.311,1.311,0,0,0,.182-2.2l-.038-.029a1.311,1.311,0,0,1,.593-2.333L1.5,10.2a1.311,1.311,0,0,0,.887-2.017l-.025-.037A1.311,1.311,0,0,1,3.688,6.136l.018,0A1.311,1.311,0,0,0,5.2,4.518l-.008-.032A1.311,1.311,0,0,1,7.1,3.009l0,0A1.311,1.311,0,0,0,9.045,1.962V1.944a1.311,1.311,0,0,1,2.281-.78h0A1.311,1.311,0,0,0,13.508.8Z"
                                    transform="translate(0 0)"
                                    fill="#00b9ff"
                                  />
                                  <path
                                    id="Icon_feather-check"
                                    data-name="Icon feather-check"
                                    d="M16.188,9l-7,7.641L6,13.168"
                                    transform="translate(3.622 1.914)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              // labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={fields.gradyear || ""}
                              onChange={(e) => handleChange("gradyear", e)}
                              // label="Select"
                              displayEmpty
                            >
                              <MenuItem value="">
                                <em>Select</em>
                              </MenuItem>
                              {props.graduationYears.data &&
                                props.graduationYears.data.map(
                                  (item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                      {item.yearValue}
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          </FormControl>
                          <div className="error">{errors.gradyear}</div>
                        </div>

                        {/* =====================================grades =========================================*/}
                        <div className="half">
                          <div style={selectStyle}>Grade</div>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              // labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={fields.grades || ""}
                              onChange={(e) => handleChange("grades", e)}
                              // label="Select"
                              displayEmpty
                            >
                              <MenuItem value="">
                                <em>Select</em>
                              </MenuItem>
                              {filteredGrades &&
                                filteredGrades.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    {item.gradevalue}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      <div className="fieldBox flexBox">
                        <div className="half">
                          <div style={selectStyle}>What's your GPA Scale? <sup style={{color: "#c0321f"}}>*</sup></div>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              // labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={fields.gpascalevalue || ""}
                              onChange={(e) => {
                                handleChange("gpascalevalue", e);
                                handleChange("gpascale", e);
                                handleChange("gpaUWPlaceHolder", e);
                                clearGpaValueOnChangeGpaScale()
                              }}
                              // label="Select"
                              displayEmpty
                            >
                              {/*earlier it was there but as gpa by default 4.0 on advice of QA is now remove*/}
                              {/*<MenuItem value="">*/}
                              {/*  <em>Select</em>*/}
                              {/*</MenuItem>*/}
                              {props.gpaList.data && props.gpaList.data?.length > 0 ?
                                props.gpaList.data.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    {item.gpascalevalue}
                                  </MenuItem>
                                )):
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                              }
                            </Select>
                          </FormControl>
                          <div className="error">{errors.gpascalevalue}</div>
                        </div>
                        <div className="half" style={{ height: "84px" }}>
                          <div className="fieldBox flexBox">
                            <div className="half">
                              <label>GPA (Unweighted)</label>
                              <TextField
                                placeholder={fields.gpaUWPlaceHolder || ""}
                                variant="outlined"
                                id="gpaUnweighted"
                                type="text"
                                disabled={fields.gpascalevalue === "" ? true : false}
                                value={fields.unweightedgpa || ""}
                                fullWidth
                                onKeyDown={(e) =>
                                  NumFieldSymbolArr.includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => {
                                  handleChange("unweightedgpa", {
                                    target: {
                                      value: e.target.value
                                        .toString()
                                        .split(".")
                                        .map((el, i) =>
                                          i ? el.split("").slice(0, 2).join("") : el
                                        )
                                        .join("."),
                                    },
                                  });
                                }}
                                onBlur={(e) => {
                                  handleChange("unweightedgpa", e);
                                }}
                                inputProps={{
                                  max: 100,
                                  min: 0,
                                  pattern: "/^d+(.d{0,2})?$/",
                                  step: 0.01,
                                }}
                                required
                              />
                              <div className="error">{errors.unweightedgpa}</div>
                            </div>
                            <div className="half">
                              <label>GPA (Weighted)</label>
                              <TextField
                                  placeholder={fields.gpascale || ""}
                                  variant="outlined"
                                  id="gpascale"
                                  type="text"
                                  disabled={fields.gpascalevalue === "" ? true : false}
                                  value={fields.gpa || ""}
                                  fullWidth

                                  onKeyDown={(e) =>
                                      NumFieldSymbolArr.includes(e.key) &&
                                      e.preventDefault()
                                  }
                                  onChange={(e) => {
                                    handleChange("gpa", {
                                      target: {
                                        value: e.target.value
                                            .toString()
                                            .split(".")
                                            .map((el, i) =>
                                                i ? el.split("").slice(0, 2).join("") : el
                                            )
                                            .join("."),
                                      },
                                    });
                                  }}
                                  onBlur={(e) => {
                                    handleChange("gpa", e);
                                  }}
                                  inputProps={{
                                    max: 100,
                                    min: 0,
                                    pattern: "/^d+(.d{0,2})?$/",
                                    step: 0.01,
                                  }}
                                  required
                              />
                              <div className="error">{errors.gpa}</div>
                            </div>

                            {/*<div className="half">*/}
                            {/*  <p className="checkbox-list mt35">*/}
                            {/*    <span>*/}
                            {/*      <input*/}
                            {/*        name="weightedGPA"*/}
                            {/*        type="checkbox"*/}
                            {/*        disabled={*/}
                            {/*          fields.gpascalevalue === 3 ? true : false*/}
                            {/*        }*/}
                            {/*        checked={*/}
                            {/*          fields.gpascalevalue === 1 &&*/}
                            {/*            fields.gpa > 4*/}
                            {/*            ? true*/}
                            {/*            : fields.gpascalevalue === 2 &&*/}
                            {/*              fields.gpa > 5*/}
                            {/*              ? true*/}
                            {/*              : fields.gpascalevalue === 3*/}
                            {/*                ? false*/}
                            {/*                : fields.weightedGPA || false*/}
                            {/*        }*/}
                            {/*        onChange={(e) =>*/}
                            {/*          handleChange("weightedGPA", {*/}
                            {/*            target: { value: e.target.checked },*/}
                            {/*          })*/}
                            {/*        }*/}
                            {/*        id="weightedGPA"*/}
                            {/*      />*/}
                            {/*      <label>Weighted GPA</label>*/}
                            {/*    </span>*/}
                            {/*  </p>*/}
                            {/*</div>*/}
                          </div>
                        </div>
                      </div>
                      <div className="fieldBox flexBox">
                        <div className="half full-on-mobile">
                          <div className="fieldBox flexBox">
                            <div className="half">
                              <p className="checkbox-list">
                                <span>
                                  <input
                                    name="highscoolstatus"
                                    type="checkbox"
                                    disabled={true}
                                    checked={disableNotInHighSchool(fields.athleteGradYear)}
                                    onChange={(e) =>
                                      handleChange("highscoolstatus", {
                                        target: { value: e.target.checked }
                                      })
                                    }
                                    id="highscoolstatus"
                                  />
                                  {/*<label>Not in high school currently?</label>*/}
                                  <label>Post High School</label>
                                </span>
                              </p>
                            </div>
                            <div className="half">
                              <div style={selectStyle}>Select Current level</div>
                              <div className="fieldBox">
                                <FormControl variant="outlined" fullWidth>
                                  <Select
                                    // labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={fields.currentlevel || ""}
                                    onChange={(e) =>
                                      handleChange("currentlevel", e)
                                    }
                                    // label="Select"
                                    displayEmpty
                                  >
                                    <MenuItem value={0}>
                                      <em>Select</em>
                                    </MenuItem>
                                    {filteredCurrectSchoolLevel &&
                                      filteredCurrectSchoolLevel.map(
                                        (item, index) => (
                                          <MenuItem key={index} value={item.id}>
                                            {item.currentlevelvalue}
                                          </MenuItem>
                                        )
                                      )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="half full-on-mobile">
                          <div className="fieldBox flexBox">
                            <div className="half">
                              <div style={selectStyle}>First Year Varsity</div>
                              <div className="fieldBox">
                                <FormControl variant="outlined" fullWidth>
                                  <Select
                                      // labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={fields.yearvarsity || ""}
                                      onChange={(e) =>
                                          handleChange("yearvarsity", e)
                                      }
                                      // label="Select"
                                      displayEmpty
                                  >
                                    <MenuItem value={""}>
                                      <em>Select</em>
                                    </MenuItem>
                                    {allVarsity.length > 0 ? allVarsity.map(
                                        (item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                              {item.varsityname}
                                            </MenuItem>
                                        )
                                    )
                                        :
                                        <MenuItem value={""}>
                                          {/*<em>Error</em>*/}
                                        </MenuItem>}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-info-box">
                        <div className="item">
                          <h5 className="title">NCAA / NAIA Eligibility Center</h5>
                          <div className="fieldBox flexBox full">
                            <p className="checkbox-list">
            <span>
              <input
                  type="checkbox"
                  checked={ncaaAndNaidFields.haveYouNcaaAndNaia}
                  value={ncaaAndNaidFields.haveYouNcaaAndNaia}
                  onChange={(e) => {
                    ncaaAndNaiaHandalChange("haveYouNcaaAndNaia", {
                      target: { value: e.target.checked },
                    });
                  }}
                  onClick={()=>setIsSaved(false)}
              />
              <label>Do you have an NCAA ID or NAIA Eligibility Number?</label>
            </span>
                            </p>
                          </div>
                          <div className="fieldBox flexBox">
                            <div className="half">
                              {ncaaAndNaidFields.haveYouNcaaAndNaia == true ? (
                                  <>
                                    <label>
                                      NCAA ID
                                      {/*this is the changes as per 15 geb doc*/}
                                      {/*NCAA Eligibility Number*/}
                                    </label>
                                    <input
                                        // type="number"
                                        min="0"
                                        placeholder="e.g. 12546"
                                        value={ncaaAndNaidFields.ncaaNumber || ""}
                                        onKeyDown={(e) =>
                                            NumFieldSymbolArr.includes(e.key) &&
                                            e.preventDefault()
                                        }
                                        onChange={(e) => {
                                          ncaaAndNaiaHandalChange("ncaaNumber", {
                                            target: {
                                              value: e.target.value.replace(/[^0-9]/gi, ""),
                                            }
                                          });
                                        }}
                                    />
                                  </>
                              ) : null}
                              <div className="error">{!!isNCCAlength?.NCAAID && isNCCAlength?.NCAAID }</div>
                              <div className="link-text-box">
                                NCAA Link -{" "}
                                <a href="https://web3.ncaa.org/ecwr3/" target="_blank">
                                  https//web3.ncaa.org/ecwr3/
                                </a>
                              </div>
                            </div>
                            <div className="half">
                              {ncaaAndNaidFields.haveYouNcaaAndNaia &&  (
                                  <>
                                    <label>NAIA Eligibility Number</label>
                                    <input
                                        // type="number"
                                        onKeyDown={(e) =>
                                            NumFieldSymbolArr.includes(e.key) &&
                                            e.preventDefault()
                                        }
                                        min="0"
                                        // pattern="[0-9]*"
                                        placeholder="e.g. 12546"
                                        value={ncaaAndNaidFields.naiaNuber || ""}
                                        onChange={(e) => {
                                          ncaaAndNaiaHandalChange("naiaNuber", {
                                            target: {
                                              value: e.target.value.replace(/[^0-9]/gi, ""),
                                            }
                                          });
                                        }}
                                    />
                                  </>
                              ) }
                              <div className="error">{!!isNCCAlength?.NAIA && isNCCAlength?.NAIA }</div>
                              <div className="link-text-box">
                                NAIA Link -{" "}
                                <a href="https://play.mynaia.org/" target="_blank">
                                  https//play.mynaia.org/
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fieldBox">
                        <label>About Me</label>
                        <div className="textarea-with-icon">
                          <textarea
                            placeholder="Add a short personal statement about yourself (Max 800 Characters)"
                            value={fields.aboutMe}
                            maxLength="800"
                            onChange={(e) => {
                              handleChange("aboutMe", e);
                            }}
                          ></textarea>
                          <ul className="tooltip-listing">
                            <li>
                              <Tooltip
                                TransitionComponent={Zoom}
                                placement="top"
                                title={
                                  "Add a short personal statement about yourself"
                                }
                                arrow
                              >
                                <img src={Information} alt="Information" />
                              </Tooltip>
                            </li>
                            <li></li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-button-box with-next-prev">
                        <button
                          className="btn blue"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Save
                        </button>
                        <div className="next-prev-button">
                          <ScreenSwitch
                            prevLink={"/checklist"}
                            nextLink={"/contact"}
                            isSaved={isSaved}
                          />
                        </div>

                      </div>
                    </form>
                    <Dialog open={showVerificationModalState}
                            onClose={(event, reason)=> {
                              if (reason == "backdropClick" || reason == "escapeKeyDown") {
                                showVerificationModal()
                              }
                            }}
                            className="matDialogCustom"
                    >
                      <VerificationModal onClose={showVerificationModal} />
                    </Dialog>
                    <Dialog open={showVerifiedModalState}
                            onClose={(event, reason)=> {
                              if (reason == "backdropClick" || reason == "escapeKeyDown") {
                                setShowVerifiedModalState(false)
                              }
                            }}
                            className="matDialogCustom"
                    >
                      <VerifiedModal
                        setShouldShowVerifiedShieldModal={setShowVerifiedModalState}
                      />
                    </Dialog>
                  </div>
                </div>
              </div>
              <GoTopButton goTop={goTop} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    graduationYears: state.common.graduationYears,
    sportsList: state.common.sportsList,
    grades: state.athlete.onBoarding.grades,
    gpaList: state.athlete.onBoarding.gpaList,
    userGpaMapping: state.athlete.onBoarding.gpaMapping,
    editUserProfileBasicData:
      state.athlete.editUserProfileData.basicUserProfile,
    updateBasicUserProfile:
      state.athlete.editUserProfileData.updateBasicUserProfile,
    currentLevel: state.athlete.onBoarding.schoolCurrentLevel,
    ncaaAndNaidDetails: state.athlete.editUserProfileData.ncaaAndNaid,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    graduationYearsListStart: (data) =>
      dispatch(graduationYearsListStart(data)),
    gradeListStart: (data) => dispatch(gradeListStart(data)),
    gpaListStart: (data) => dispatch(gpaListStart(data)),
    updateBasicUserProfileListStart: (data) =>
      dispatch(updateBasicUserProfileListStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
    basicUserProfileListStart: (data) =>
      dispatch(basicUserProfileListStart(data)),
    schoolCurrentLevelListStart: (data) =>
      dispatch(schoolCurrentLevelListStart(data)),
    transcriptAndAcademicsaveStart: (data) =>
      dispatch(transcriptAndAcademicsaveStart(data)),
    gpaMappingListStart: (data) => dispatch(gpaMappingStart(data)),
    saveGpaMappingStart: (data) => dispatch(gpaMappingSaveStart(data)),
    sportsListStart: (data) => dispatch(sportsListStart(data)),
    //this fir new chnages ncaa
    ncaaNaidListStart: (data) => dispatch(ncaaNaidListStart(data)),
    ncaaNaidSaveListStart: (data) => dispatch(ncaaNaidSaveListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Basic);