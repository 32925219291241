export const getAllContactDataQuery = `
 query allAthletes($obj : JSON){
  allAthletes(where : $obj) {
      Athletes{
        athletephonenumber
        athleteemailid
         athletezipcode
         athletecountryflag
         athleteCountryCodeId
         athleteoutsideusorcanada
         stateid
         homecountryid
         address
         addresscountinue
         homezipcode
         homeoutsideusorcanada
         phonenumberrelation
         city
         isathletemobilenumber
          onBoardingUserIdMap{
           Onboardings{
             id
             twitteraccountname
             instagramaccountname
           }
         }
     }
     }
 } 
 `;

export const allParentQuery = `
query allAthleteparentmappings($obj: JSON) {
  allAthleteparentmappings(where: $obj){
    Athleteparentmappings{
      id
      parentid
      athleteid
      relationshipid
      active
      athleteparentmappingrelationshipidmaprel{
        Relationships{
          id
          relationshipname
        }
      }
      athleteparentmappingmappingparentidmaprel{
        Athleteparents{
          id
        userid
        active
        parentfirstname
        parentlastname
        parentemail
        parentoutsideusorcanada
        parentaddresscountryid
        parentCountryCodeId
        parentzipcode
        relationbetweenparentandathlete
        parentphonenumber
        isaccessallowed
        athleteparentrelationbetweenparentandathletemaprel {
          Relationships {
            relationshipname
          }
        }
        athleteparentparentcountrycodeidmaprel{
          Countries{
            countrycode
            countryflagimage
            countryname
          }
        }
        parentnotificationParentidMap{
          Parentnotifications{
            id
            parentid
            athleteid
            viaemailstatus
            parentnotificationmasterid
            viatextstatus
          }
        }
        }
      }
    }
  }
  }
 `;
export const parentDetailsQuery = `
 query allAthleteParents($obj : JSON){
    allAthleteparents(where: $obj){
      Athleteparents{
          id
          userid
          active
          parentfirstname
          parentlastname
          parentemail
          parentoutsideusorcanada
          parentaddresscountryid
          parentcountrycodeid
          parentzipcode
          relationbetweenparentandathlete
          parentphonenumber
          isaccessallowed
      }
    }
  }
 `

export const updateAthleteDataQuery = `
 mutation saveAthlete($obj:AthleteInput!){
  saveAthlete(obj:$obj){
    id
  }
}
 `;

export const getCountryApiData = `
query allCountries($where: JSON){
  allCountries(where:$where){
    Countries{
      id
      countryname
      countryflagimage
    }
  }
}
`;

export const getAllParentNotifictionMasterQuery = `
query allParentnotificationmasters($where: JSON){
  allParentnotificationmasters(where:$where){
    Parentnotificationmasters{
      id
      notificationlablename
      viaemailstatus
      viatextstatus
    }
  }
}
`;