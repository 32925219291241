import React, { useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import {
  checkPhoneNumberValidation,
  formattedPhoneNumber,
  IsValidEmail,
  IsValidName,
  isValidZipcode,
} from "../../../../utils/validationUtils";
import { countryListStart } from "../../../../redux/common/countriesList";
import { relationshipListStart } from "../../../../redux/common/relationships";
import { connect } from "react-redux";
import { getSignupData, setSignupData } from "../../../../services/authService";
import BackdropLoader from "../../../common/Loader";
import "../../../../assets/css/stylesheet.scss";
import "./assets/css/signUpParentGuradian.scss";
import PhoneNumber from "../../../common/PhoneNumber";
import { Link } from "react-router-dom";
import MuiAutoComplete from "../../../common/AutoComplete";
import { emailExistStart } from "../../../../redux/auth/signup/checkEmailExist";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";
function SignupGaurdianStep2(props) {
  const [fields, setFields] = React.useState({
    isBaseballTeam: "yes",
  });
  const [errors, setErrors] = React.useState({});
  const fakeInput = useRef(null);
  const [teamList, setTeamList] = React.useState([]);

  useEffect(() => {
    const signupData = getSignupData();
    if (
      signupData &&
      signupData.role === "GUARDIAN" &&
      (signupData.completed === "STEP1" || signupData.completed === "STEP2")
    ) {
      props.countryListStart();
      props.relationshipListStart();
    } else {
      props.history.goBack();
    }
  }, []);

  useEffect(() => {
    const signupData = getSignupData();
    if (props.countries.data) {
      const fieldsT = fields;
      fieldsT["countryCode"] = {
        value: props.countries.data ? props.countries.data[0].id : "",
        label: props.countries.data
          ? props.countries.data[0].countryName +
            `(+${props.countries.data[0].countryCode})`
          : "",
        flag: props.countries.data
          ? props.countries.data[0].countryFlagImage
          : "",
        countryCode: props.countries.data
          ? props.countries.data[0].countryCode
          : "",
        countryShortName: props.countries.data
          ? props.countries.data[0].countryShortName
          : "",
      };
      fieldsT["gcountryCode"] = {
        value: props.countries.data ? props.countries.data[0].id : "",
        label: props.countries.data
          ? props.countries.data[0].countryName +
            `(+${props.countries.data[0].countryCode})`
          : "",
        flag: props.countries.data
          ? props.countries.data[0].countryFlagImage
          : "",
        countryCode: props.countries.data
          ? props.countries.data[0].countryCode
          : "",
        countryShortName: props.countries.data
          ? props.countries.data[0].countryShortName
          : "",
      };
      fieldsT["isBaseballTeam"] = "yes";
      setFields({
        ...fieldsT,
      });
    }
    if (signupData.completed === "STEP2") {
      const finalData = {
        firstname: signupData.athleteFirstName
          ? signupData.athleteFirstName
          : "",
        lastname: signupData.athleteLastName ? signupData.athleteLastName : "",
        email: signupData.athleteEmail ? signupData.athleteEmail : "",
        phoneNumber: signupData.athletePhoneNumber
          ? signupData.athletePhoneNumber
          : "",
        countryCode:
          props.countries.data &&
          props.countries.data
            .filter((item) => +item.id === +signupData.athleteCountryCodeId)
            .map((item1) => ({
              value: item1 ? item1.id : "",
              label: item1 ? item1.countryName + `(+${item1.countryCode})` : "",
              flag: item1 ? item1.countryFlagImage : "",
              countryCode: item1.countryCode ? item1.countryCode : 1,
              countryShortName: item1.countryShortName,
            }))[0],
        zipcode: signupData.athleteZipCode ? signupData.athleteZipCode : "",
        outside: signupData.athleteOutsideUsOrCanada === "1" ? true : false,
        isBaseballTeam:
          signupData.memberOfBaseBallClubTeam === "1" ? "yes" : "no",
        teamname: signupData.clubTeamName
          ? teamList
            ? teamList.filter((item) => item.id === +signupData.clubTeamName)[0]
            : null
          : null,
        relationWithAthlete: signupData.relationBetweenParentAndAthlete
          ? signupData.relationBetweenParentAndAthlete
          : "",
        gfirstname: signupData.parentFirstName
          ? signupData.parentFirstName
          : "",
        glastname: signupData.parentLastName ? signupData.parentLastName : "",
        gphoneNumber: signupData.parentPhoneNumber
          ? signupData.parentPhoneNumber
          : "",
        gcountryCode:
          props.countries.data &&
          props.countries.data
            .filter((item) => +item.id === +signupData.parentCountryCodeId)
            .map((item1) => ({
              value: item1 ? item1.id : "",
              label: item1 ? item1.countryName + `(+${item1.countryCode})` : "",
              flag: item1 ? item1.countryFlagImage : "",
              countryCode: item1.countryCode ? item1.countryCode : 1,
              countryShortName: item1.countryShortName,
            }))[0],
        gzipcode: signupData.parentZipCode ? signupData.parentZipCode : "",
        goutside: signupData.parentOutsideUsOrCanada === "1" ? true : false,
        isParentExist: signupData.isParentExist,
        // gemail: signupData.parentEmailId,
      };
      setFields({
        ...fields,
        ...finalData,
      });
    }
    if (signupData.completed === "STEP1" && signupData.isParentExist) {
      const finalData = {
        gfirstname: signupData.parentFirstName
          ? signupData.parentFirstName
          : "",
        glastname: signupData.parentLastName ? signupData.parentLastName : "",
        gphoneNumber: signupData.parentPhoneNumber
          ? signupData.parentPhoneNumber
          : "",
        gcountryCode:
          props.countries.data &&
          props.countries.data
            .filter((item) => +item.id === +signupData.parentCountryCodeId)
            .map((item1) => ({
              value: item1 ? item1.id : "",
              label: item1 ? item1.countryName + `(+${item1.countryCode})` : "",
              flag: item1 ? item1.countryFlagImage : "",
              countryCode: item1.countryCode ? item1.countryCode : 1,
              countryShortName: item1.countryShortName,
            }))[0],
        gzipcode: signupData.parentZipCode ? signupData.parentZipCode : "",
        goutside: signupData.parentOutsideUsOrCanada === "1" ? true : false,
        isParentExist: signupData.isParentExist,
      };
      setFields({
        ...fields,
        ...finalData,
      });
    }
  }, [props.countries.data]);
  //this func is used for scroll the comp when render
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    //firstname validations
    if (fieldname === undefined || fieldname === "firstname") {
      if (fieldname === undefined) {
        fieldValue = fields["firstname"] ? fields["firstname"] : "";
        updatedValue = fieldValue;
      }
      // if (!(fieldValue.length <= 20)) {
      //   formIsValid = false;
      //   updatedValue = fieldValue.substr(0, 20);
      // }
      delete errorsT["firstname"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["firstname"] = "First name is required";
      } else if (!IsValidName(updatedValue)) {
        formIsValid = false;
        errorsT["firstname"] = "Please enter a valid first name";
      } else if (updatedValue.length > 30) {
        formIsValid = false;
        errorsT["firstname"] = "First name maximum length should be 30";
      }
    }
    //lastname validations
    if (fieldname === undefined || fieldname === "lastname") {
      if (fieldname === undefined) {
        fieldValue = fields["lastname"] ? fields["lastname"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["lastname"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["lastname"] = "Last name is required";
      } else if (!IsValidName(updatedValue)) {
        formIsValid = false;
        errorsT["lastname"] = "Please enter a valid last name";
      } else if (updatedValue.length > 30) {
        formIsValid = false;
        errorsT["lastname"] = "Last name maximum length should be 30";
      }
    }
    //phoneNumber validations
    if (fieldname === undefined || fieldname === "phoneNumber") {
      if (fieldname === undefined) {
        fieldValue = fields["phoneNumber"] ? fields["phoneNumber"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["phoneNumber"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["phoneNumber"] = "Athlete Mobile Number is required";
      } else if (updatedValue.length > 10) {
        formIsValid = false;
        errorsT["phoneNumber"] = "Mobile Number should be less than 11 digits";
      } else if (
        !checkPhoneNumberValidation(
          updatedValue,
          fields.countryCode.countryShortName
        )
      ) {
        formIsValid = false;
        errorsT["phoneNumber"] = "Please enter a valid Phone Number";
      }
      updatedValue = formattedPhoneNumber(
        updatedValue,
        fields.countryCode.countryShortName
      );
    }

    //zipcode validations
    if (fieldname === undefined || fieldname === "zipcode") {
      if (fieldname === undefined) {
        fieldValue = fields["zipcode"] ? fields["zipcode"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["zipcode"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["zipcode"] = "Zip code is required";
      }
      // else if (!new RegExp("^[0-9]+$").test(updatedValue)) {
      //   formIsValid = false;
      //   errorsT["zipcode"] = "Please enter valid value";
      // }
      else if (
        !isValidZipcode(updatedValue, fields.countryCode.countryShortName)
      ) {
        formIsValid = false;
        errorsT["zipcode"] = "Please enter valid zipcode";
      }
      // else if (!(updatedValue.length <= 6)) {
      //   formIsValid = false;
      //   errorsT["zipcode"] = "Maximum length should be 6";
      // }
    }
    //teamname validations
    if (
      (fieldname === undefined || fieldname === "teamname") &&
      (fields.isBaseballTeam === "yes" || !fields.isBaseballTeam)
    ) {
      if (fieldname === undefined) {
        fieldValue = fields["teamname"] ? fields["teamname"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["teamname"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["teamname"] = "Club team name is required";
      }
    } else {
      delete errorsT["teamname"];
    }

    //relationWithAthlete validations
    if (fieldname === undefined || fieldname === "relationWithAthlete") {
      if (fieldname === undefined) {
        fieldValue = fields["relationWithAthlete"]
          ? fields["relationWithAthlete"]
          : "";
        updatedValue = fieldValue;
      }
      delete errorsT["relationWithAthlete"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["relationWithAthlete"] =
          " Relationship with Athlete is required";
      }
    }
    //gfirstname validations
    if (fieldname === undefined || fieldname === "gfirstname") {
      if (fieldname === undefined) {
        fieldValue = fields["gfirstname"] ? fields["gfirstname"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["gfirstname"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["gfirstname"] = "First name is required";
      } else if (!IsValidName(updatedValue)) {
        formIsValid = false;
        errorsT["gfirstname"] = "Please enter a valid first name";
      } else if (updatedValue.length > 30) {
        formIsValid = false;
        errorsT["gfirstname"] =
          "First name maximum length should be between 30";
      }
    }
    //glastname validations
    if (fieldname === undefined || fieldname === "glastname") {
      if (fieldname === undefined) {
        fieldValue = fields["glastname"] ? fields["glastname"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["glastname"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["glastname"] = "Last name is required";
      } else if (!IsValidName(updatedValue)) {
        formIsValid = false;
        errorsT["glastname"] = "Please enter a valid last name";
      } else if (updatedValue.length > 30) {
        formIsValid = false;
        errorsT["glastname"] = "Last name maximum length should be 30";
      }
    }
    //gphoneNumber validations
    if (fieldname === undefined || fieldname === "gphoneNumber") {
      if (fieldname === undefined) {
        fieldValue = fields["gphoneNumber"] ? fields["gphoneNumber"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["gphoneNumber"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["gphoneNumber"] = "Mobile Number is required";
      } else if (updatedValue.length > 10) {
        formIsValid = false;
        errorsT["gphoneNumber"] = "Mobile Number should be less than 11 digits";
      } else if (
        !checkPhoneNumberValidation(
          updatedValue,
          fields.gcountryCode.countryShortName
        )
      ) {
        formIsValid = false;
        errorsT["gphoneNumber"] = "Please enter a valid Phone Number";
      }
      updatedValue = formattedPhoneNumber(
        updatedValue,
        fields.gcountryCode.countryShortName
      );
      // else if (updatedValue.length > 10) {
      //   formIsValid = false;
      //   errorsT["gphoneNumber"] = "Please enter a valid Phone Number";
      // }
    }
    //gemail validations
    if (fieldname === undefined || fieldname === "email") {
      if (fieldname === undefined) {
        fieldValue = fields["email"] ? fields["email"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["email"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["email"] = "Email Address is required";
      } else if (!IsValidEmail(updatedValue)) {
        formIsValid = false;
        errorsT["email"] = "Please enter Email in correct format";
      }
    }
    //gzipcode validations
    if (fieldname === undefined || fieldname === "gzipcode") {
      if (fieldname === undefined) {
        fieldValue = fields["gzipcode"] ? fields["gzipcode"] : "";
        updatedValue = fieldValue;
      }
      // if (!(fieldValue.length <= 6)) {
      // 	formIsValid = false;
      // 	updatedValue = fieldValue.substr(0, 6);
      // }
      delete errorsT["gzipcode"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["gzipcode"] = "Zip code is required";
      }
      // else if (!new RegExp("^[0-9]+$").test(updatedValue)) {
      //   formIsValid = false;
      //   errorsT["gzipcode"] = "Please enter valid value";
      // }
      else if (
        !isValidZipcode(updatedValue, fields.gcountryCode.countryShortName)
      ) {
        formIsValid = false;
        errorsT["gzipcode"] = "Please enter valid zipcode";
      }
      // else if (!(updatedValue.length <= 6)) {
      //   formIsValid = false;
      //   errorsT["gzipcode"] = "Maximum length should be 6";
      // }
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const handleChange = (field, e) => {
    //const { formIsValid, updatedValue } = handleValidation(field, e);
    setErrors({
      errorsT: "",
    });

    let fieldsT = fields;
    //this condition is for first letter should be alphabet only
    if(field == "firstname" || field == "lastname"){
      const regTest = /^[A-Za-z]+$/,
          testValue = e?.target?.value?.split("")[0]
      if (!regTest.test(testValue)) return
    }
    if (field == "teamname") {
      fieldsT[field] = e?.target?.value?.teamname ?? "";
      // fieldsT[field] = e?.target?.value?.id ?? "";
      setFields({
        ...fieldsT,
      });
    } else {
      fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
      setFields({
        ...fieldsT,
      });
    }
  };

  const zipCodeBlur = (field, e) => {
    if (field === "gzipcode") {
      let fieldsT = fields;
      if (!fieldsT["zipcode"])
        fieldsT["zipcode"] = e ? (e.target ? e.target.value : "") : "";
      setFields({
        ...fieldsT,
      });
    }
  };

  const phoneNumberOnBlur = (e) => {
    // checkPhoneNumberValidation('2133734253',1,'US')
  };

  const checkNotSameEmail = (email1, email2) => {
    if (email1 === email2) {
      messagePopup("", "Parent and Athlete email id not be same.", "error");
      return false;
    }
    return true;
  };

  const handleSaveSignUpData = () => {
    const finalData = {
      athleteFirstName: fields.firstname,
      athleteLastName: fields.lastname,
      athleteEmail: fields.email,
      athletePhoneNumber: fields.phoneNumber,
      athleteCountryCodeId: fields.countryCode ? +fields.countryCode.value : 1,
      athleteZipCode: fields.zipcode,
      athleteOutsideUsOrCanada: fields.outside ? "1" : "0",
      memberOfBaseBallClubTeam: fields.isBaseballTeam
        ? fields.isBaseballTeam === "yes"
          ? "1"
          : "0"
        : "1",
      clubTeamName: fields.teamname ? `${fields.teamname}` : "",
      relationBetweenParentAndAthlete: `${fields.relationWithAthlete}`,
      parentFirstName: fields.gfirstname,
      parentLastName: fields.glastname,
      parentPhoneNumber: fields.gphoneNumber,
      parentCountryCodeId: fields.gcountryCode ? +fields.gcountryCode.value : 1,
      parentZipCode: fields.gzipcode,
      parentOutsideUsOrCanada: fields.goutside ? "1" : "0",
    };
    let signupData = getSignupData() ? getSignupData() : {};
    if (!checkNotSameEmail(signupData.parentEmailId, finalData.athleteEmail)) {
      return;
    }
    signupData = { ...signupData, ...finalData, completed: "STEP2" };
    setSignupData(signupData);
    props.history.push("/setpassword");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { formIsValid, updatedValue } = handleValidation();
    if (formIsValid) {
      props.emailExistStart({
        data: {
          email: fields.email,
          role: "ATHLETES",
        },

        handleSaveSignUpData: handleSaveSignUpData,
      });
    } else {
      goTop();
    }
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  const handlePhonenumberChange = (field, selectedOption) => {
    const fieldsT = fields;
    fieldsT[field] = selectedOption;
    if (field === "gcountryCode") {
      fieldsT["gphoneNumber"] = "";
      fieldsT["gzipcode"] = "";
    }
    if (field === "countryCode") {
      fieldsT["phoneNumber"] = "";
      fieldsT["zipcode"] = "";
    }
    setFields({ ...fieldsT });
  };

  const handleRadioChange = (field, value) => {
    let fieldsT = fields;
    fieldsT[field] = value;
    if (fieldsT.isBaseballTeam === "no") {
      fieldsT["teamname"] = "";
    }
    setFields({ ...fieldsT });
  };

  const handleClubHandle = async (field, e) => {
    if (e.target.value.toString().length > 1) {
      const res = await fetchMethod(
        `/allTeam/${e.target.value}`,
        "",
        "GET",
        false
      );
      if (res?.status == 200 && res?.statusText == "OK") {
        const teamData = res?.data?.data;
        setTeamList(Array.isArray(teamData) ? teamData : [] || []);
      }
    } else {
      setTeamList([]);
    }
  };

  return (
    <div>
      <BackdropLoader
        open={props.countries.loading || props.relationships.loading}
      />
      <div className="signUpAthleteInfoSection">
        <input className="fakeInput" type="text" ref={fakeInput} />
        <div className="athleteInfoBlock">
          <div className="formSection">
            <form>
              <h1 className="heading">
                Parent / Guardian Information
                <span className="checkIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <style
                        dangerouslySetInnerHTML={{
                          __html: `
                          .a {
                            fill: #00b9ff;
                          }
                          .b {
                            fill: #fafafa;
                          }
                          `,
                        }}
                      />
                    </defs>
                    <g transform="translate(-649.111 -507.111)">
                      <path
                        className="a"
                        d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                        transform="translate(649.111 507.111)"
                      />
                      <path
                        className="b"
                        d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                        transform="translate(511.436 348.256)"
                      />
                    </g>
                  </svg>
                </span>
              </h1>
              <div className="fieldBox">
                <label>
                  Relationship with Athlete <sup>*</sup>
                </label>
                <select
                  id="demo-simple-select-outlined"
                  value={fields.relationWithAthlete || ""}
                  onChange={(e) => handleChange("relationWithAthlete", e)}
                  label="Select"
                >
                  <option value="">Select Relationship</option>
                  {props.relationships.data &&
                    props.relationships.data.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.relationShipName}
                      </option>
                    ))}
                </select>
                <div style={{ color: "red" }}>
                  {errors["relationWithAthlete"]}
                </div>
              </div>
              <div className="fieldBox flexBox">
                <div className="half">
                  <label>
                    First Name <sup>*</sup>
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="gfirstname"
                    placeholder="First name"
                    type="text"
                    value={fields.gfirstname || ""}
                    onChange={(e) => {
                      handleChange("gfirstname", e);
                    }}
                    onBlur={(e) => {
                      handleChange("gfirstname", e);
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: false,
                    }}
                    // disabled={fields.isParentExist ? true : false}
                    required
                  />
                  <div style={{ color: "red" }}>{errors["gfirstname"]}</div>
                  {/* <input type="text" placeholder="First name" /> */}
                </div>
                <div className="half">
                  <label>
                    Last Name <sup>*</sup>
                  </label>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="glastname"
                    placeholder="Last name"
                    type="text"
                    value={fields.glastname || ""}
                    onChange={(e) => {
                      handleChange("glastname", e);
                    }}
                    onBlur={(e) => {
                      handleChange("glastname", e);
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: false,
                    }}
                    // disabled={fields.isParentExist ? true : false}
                    required
                  />
                  <div style={{ color: "red" }}>{errors["glastname"]}</div>
                  {/* <input type="text" placeholder="Last name" /> */}
                </div>
              </div>
              <div className="fieldBox phone">
                <label>
                  Mobile Phone <sup>*</sup>
                </label>
                <PhoneNumber
                  // onMenuOpen={() => setFields({ ...fields, gphoneOpen: true })}
                  // onMenuClose={() => setFields({ ...fields, gphoneOpen: false })}
                  dropdownName="gcountryCode"
                  dropdownOnChange={(e) =>
                    handlePhonenumberChange("gcountryCode", e)
                  }
                  dropdownValue={fields.gcountryCode}
                  dropDownOptions={
                    props.countries.data &&
                    props.countries.data.map((item) => ({
                      value: item.id,
                      label: item.countryName + `(+${item.countryCode})`,
                      flag: item.countryFlagImage,
                      countryCode: item.countryCode,
                      countryShortName: item.countryShortName,
                    }))
                  }
                  formatOptionLabel={(option, { context, selectValue }) => {
                    return context === "menu" ? (
                      option.label
                    ) : (
                      <>
                        <img
                          src={option.flag}
                          alt=""
                          style={{ width: "40px", marginRight: "15px" }}
                        />
                        <span>+{option.countryCode}</span>
                      </>
                    );
                  }}
                  inputOnBlur={phoneNumberOnBlur}
                  inputPlaceholder="Phone Number"
                  inputType="text"
                  inputValue={fields.gphoneNumber || ""}
                  inputOnChange={(e) => {
                    handleChange("gphoneNumber", {
                      target: {
                        value: e.target.value.replace(/[^0-9]/gi, ""),
                      },
                    });
                  }}
                  // disabled={fields.isParentExist ? true : false}
                />
                <div style={{ color: "red" }}>{errors["gphoneNumber"]}</div>
              </div>
              <div className="fieldBox">
                <label>
                  Zip Code <sup>*</sup>
                </label>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="gzipcode"
                  placeholder="Zip code"
                  type="text"
                  value={fields.gzipcode || ""}
                  onChange={(e) => {
                    handleChange("gzipcode", e);
                  }}
                  onBlur={(e) => {
                    zipCodeBlur("gzipcode", e);
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: false,
                  }}
                  // disabled={fields.isParentExist ? true : false}
                  required
                />
                <div style={{ color: "red" }}>{errors["gzipcode"]}</div>
              </div>
              <div className="pleaseCheck">
                <input
                  style={{ width: "20px" }}
                  type="checkbox"
                  checked={fields.goutside || false}
                  onChange={(e) =>
                    handleChange("goutside", {
                      target: { value: e.target.checked },
                    })
                  }
                  disabled={fields.isParentExist ? true : false}
                  // style={{display: fields.gphoneOpen ? 'none' : 'block'}}
                />
                <label>
                  Please check if you live outside of the US or Canada
                </label>
              </div>

              <h1 className="heading">Athlete Information</h1>
              <div className="fieldBox flexBox">
                <div className="half">
                  <label>
                    Athlete First Name<sup>*</sup>
                  </label>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="firstname"
                    placeholder="First name"
                    type="text"
                    value={fields.firstname || ""}
                    onChange={(e) => {
                      handleChange("firstname", e);
                    }}
                    onBlur={(e) => {
                      handleChange("firstname", e);
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: false,
                    }}
                    required
                  />
                  <div style={{ color: "red" }}>{errors["firstname"]}</div>
                </div>
                <div className="half">
                  <label>
                    Athlete Last Name<sup>*</sup>
                  </label>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="lastname"
                    placeholder="Last name"
                    type="text"
                    value={fields.lastname || ""}
                    onChange={(e) => {
                      handleChange("lastname", e);
                    }}
                    onBlur={(e) => {
                      handleChange("lastname", e);
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: false,
                    }}
                    required
                  />
                  <div style={{ color: "red" }}>{errors["lastname"]}</div>
                </div>
              </div>
              <div className="fieldBox phone">
                <label>
                  Athlete Mobile Phone <sup>*</sup>
                </label>
                <PhoneNumber
                  // onMenuOpen={() => setFields({ ...fields, phoneOpen: true })}
                  // onMenuClose={() => setFields({ ...fields, phoneOpen: false })}
                  dropdownName="countryCode"
                  dropdownOnChange={(e) =>
                    handlePhonenumberChange("countryCode", e)
                  }
                  dropdownValue={fields.countryCode}
                  dropDownOptions={
                    props.countries.data &&
                    props.countries.data.map((item) => ({
                      value: item.id,
                      label: item.countryName + `(+${item.countryCode})`,
                      flag: item.countryFlagImage,
                      countryCode: item.countryCode,
                      countryShortName: item.countryShortName,
                    }))
                  }
                  formatOptionLabel={(option, { context, selectValue }) => {
                    return context === "menu" ? (
                      option.label
                    ) : (
                      <>
                        <img
                          src={option.flag}
                          alt=""
                          style={{ width: "40px", marginRight: "15px" }}
                        />
                        <span>+{option.countryCode}</span>
                      </>
                    );
                  }}
                  inputPlaceholder="Phone Number"
                  inputType="text"
                  inputValue={fields.phoneNumber || ""}
                  inputOnChange={(e) => {
                    handleChange("phoneNumber", {
                      target: {
                        value: e.target.value.replace(/[^0-9]/gi, ""),
                      },
                    });
                  }}
                  inputError={errors["phoneNumber"]}
                />
                <div style={{ color: "red" }}>{errors["phoneNumber"]}</div>
              </div>
              <div className="fieldBox">
                <label>
                  Athlete Email Address <sup>*</sup>
                </label>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  placeholder="Athlete Email Address"
                  type="text"
                  value={fields.email || ""}
                  onChange={(e) => {
                    handleChange("email", e);
                  }}
                  onBlur={(e) => {
                    handleChange("email", e);
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: false,
                  }}
                  required
                />
                <div style={{ color: "red" }}>{errors["email"]}</div>
              </div>

              <div className="fieldBox">
                <label>
                  Zip Code <sup>*</sup>
                </label>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="zipcode"
                  placeholder="Zip code"
                  type="text"
                  value={fields.zipcode || ""}
                  onChange={(e) => {
                    handleChange("zipcode", e);
                  }}
                  onBlur={(e) => {
                    handleChange("zipcode", e);
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: false,
                  }}
                  required
                />
                <div style={{ color: "red" }}>{errors["zipcode"]}</div>
              </div>
              <div className="pleaseCheck">
                <input
                  style={{ width: "20px" }}
                  type="checkbox"
                  checked={fields.outside || false}
                  onChange={(e) =>
                    handleChange("outside", {
                      target: { value: e.target.checked },
                    })
                  }
                  // style={{display: fields.phoneOpen ? 'none' : 'block'}}
                />
                <label>
                  Please check if you live outside of the US or Canada
                </label>
              </div>
              <div className="radioBox">
                <h5>
                  <span>
                    Is your athlete currently on a baseball club team?{" "}
                  </span>
                  <sup>*</sup>
                </h5>
                <div className="radioflexBox">
                  <div className="option">
                    <input
                      type="radio"
                      name="isBaseballTeam"
                      checked={fields.isBaseballTeam === "yes"}
                      onChange={(e) =>
                        handleRadioChange("isBaseballTeam", "yes")
                      }
                    />
                    <label>Yes</label>
                  </div>
                  <div className="option">
                    <input
                      type="radio"
                      name="isBaseballTeam"
                      checked={fields.isBaseballTeam === "no"}
                      onChange={(e) =>
                        handleRadioChange("isBaseballTeam", "no")
                      }
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
              {(fields.isBaseballTeam === "yes" || !fields.isBaseballTeam) && (
                <div className="fieldBox">
                  <label>
                    Club Team Name<sup>*</sup>
                  </label>
                  <MuiAutoComplete
                    id="free-solo-demo"
                    clearOnBlur={true}
                    freeSolo={true}
                    options={teamList}
                    getOptionLabel={(option) => option?.teamname ?? ""}
                    // value={fields.teamname || null}
                    onChangeObj={(event, newValue) => {
                      handleChange("teamname", { target: { value: newValue } });
                    }}
                    onChange={(event, newValue) => {
                      handleClubHandle("teamname", {
                        target: { value: newValue },
                      });
                    }}
                    inputPlaceholder="Club team name"
                  />
                  <div style={{ color: "red" }}>{errors["teamname"]}</div>
                </div>
              )}
              <div className="continueBtnBox">
                <Link
                  to="#"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Continue
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    countries: state.common.countries,
    relationships: state.common.relationships,
    isEmailExist: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    countryListStart: (data) => dispatch(countryListStart(data)),
    relationshipListStart: (data) => dispatch(relationshipListStart(data)),
    emailExistStart: (data) => dispatch(emailExistStart(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupGaurdianStep2);
