import { put, takeEvery } from "redux-saga/effects";

import { fetchGraphMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_USER_DATA_START: "ALL_USER_DATA_START",
  ALL_USER_DATA_SUCCESS: "ALL_USER_DATA_SUCCESS",
  ALL_USER_DATA_FAILED: "ALL_USER_DATA_FAILED",
};

// ============================================ Actions ========================================================
export const allUserDataStart = (data) => ({
  type: actionType.ALL_USER_DATA_START,
  payload: data,
});

export const allUserDataSuccess = (data) => ({
  type: actionType.ALL_USER_DATA_SUCCESS,
  payload: data,
});

export const allUserDataFailed = (data) => ({
  type: actionType.ALL_USER_DATA_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allUserDataReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ALL_USER_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_USER_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allUserDataSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allUserDataSuccess({
          userData: response.data.data.allUserdata.Userdata,
        })
      );
    } else {
      messagePopup("", "User Data failed", "error");
      yield put(allUserDataFailed("User Data failed"));
    }
  } catch (error) {
    messagePopup("", "User Data failed", "error");
    yield put(allUserDataFailed("User Data failed"));
  }
}



//Watcher
export function* watchAllUserDataSaga() {
  yield takeEvery(actionType.ALL_USER_DATA_START, allUserDataSaga);
}
