import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";


// ========================================== Action Type =====================================================
export const actionType = {
  CREATE_PAYMENT_SAVE_START: "CREATE_PAYMENT_SAVE_START",
  CREATE_PAYMENT_SUCCESS: "CREATE_PAYMENT_SUCCESS",
  CREATE_PAYMENT_FAILED: "CREATE_PAYMENT_FAILED",
};

// ============================================ Actions ========================================================

export const saveCreatePaymentStart = (data) => ({
  type: actionType.CREATE_PAYMENT_SAVE_START,
  payload: data,
});

export const saveCreatePaymentSuccess = (data) => ({
  type: actionType.CREATE_PAYMENT_SUCCESS,
  payload: data,
});

export const saveCreatePaymentFailed = (data) => ({
  type: actionType.CREATE_PAYMENT_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function saveCreatePaymentReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.CREATE_PAYMENT_SAVE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.CREATE_PAYMENT_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* saveCreatePaymentSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Payments/createPayment`,
      action.payload.data,
      "post",
      true
    );
    let paymentResponse = response && response.data && response.data.response ? response.data.response : null;

    if (paymentResponse && paymentResponse.status === "200") {
       action.payload.saveDataInMembership()
        yield put(saveCreatePaymentSuccess(paymentResponse));
    } else {
      messagePopup("", paymentResponse.msg, "error");
      yield put(saveCreatePaymentFailed("Payment Failed"));
    }
  } catch (error) {
    messagePopup("", "Payment Failed", "error");
    yield put(saveCreatePaymentFailed("Payment Failed"));
  }
}

export function* watchSaveCreatePaymentSaga() {
  yield takeEvery(actionType.CREATE_PAYMENT_SAVE_START, saveCreatePaymentSaga);
}
