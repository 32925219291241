import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  GET_NOTIFICATION_SETTING_START: "GET_NOTIFICATION_SETTING_START",
  GET_NOTIFICATION_SETTING_SUCCESS: " GET_NOTIFICATION_SETTING_SUCCESS",
  GET_NOTIFICATION_SETTING_FAIL: " GET_NOTIFICATION_SETTING_FAIL",
  SAVE_NOTIFICATION_SETTING_START: "SAVE_NOTIFICATION_SETTING_START",
  SAVE_NOTIFICATION_SETTING_SUCCESS: "SAVE_NOTIFICATION_SETTING_SUCCESS",
  SAVE_NOTIFICATION_SETTING_FAIL: "SAVE_NOTIFICATION_SETTING_FAIL",
  SAVE_SETTING_NOTIFICATION_START: "SAVE_SETTING_NOTIFICATION_START",
};

// ============================================ Actions ========================================================
export const notificationSettingStart = (data) => ({
  type: actionType.GET_NOTIFICATION_SETTING_START,
  payload: data,
});

export const notificationSettingFail = (data) => ({
  type: actionType.GET_NOTIFICATION_SETTING_FAIL,
  payload: data,
});

export const notificationSettingSuccess = (data) => ({
  type: actionType.GET_NOTIFICATION_SETTING_SUCCESS,
  payload: data,
});
export const savenotificationSettingStart = (data) => ({
  type: actionType.SAVE_NOTIFICATION_SETTING_START,
  payload: data,
});

export const savenotificationSettingFail = (data) => ({
  type: actionType.SAVE_NOTIFICATION_SETTING_FAIL,
  payload: data,
});

export const savenotificationSettingSuccess = (data) => ({
  type: actionType.SAVE_NOTIFICATION_SETTING_SUCCESS,
  payload: data,
});
export const savesettingNotificationStart = (data) => ({
  type: actionType.SAVE_SETTING_NOTIFICATION_START,
  payload: data,
});
// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function notificationSettingReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_NOTIFICATION_SETTING_START:
      return {
        ...state,
        loading: true,
      };

    case actionType.GET_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case actionType.GET_NOTIFICATION_SETTING_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case actionType.SAVE_NOTIFICATION_SETTING_START:
      return {
        ...state,
        loading: true,
      };

    case actionType.SAVE_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case actionType.SAVE_NOTIFICATION_SETTING_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case actionType.SAVE_SETTING_NOTIFICATION_START:
      return {
        ...state,
        loading: true,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* notificationSettingSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Notificationsettings/notificationSettingValue`,
      action.payload,
      "post",
      true
    );
    if (response && response.status === 200 && response.data.response.data) {
      yield put(
        notificationSettingSuccess({
          profileTimelineAbout: response.data.response.data,
        })
      );
    } else {
      messagePopup("", "Notification Setting failed", "error");
      yield put(notificationSettingFail("Notification Setting failed"));
    }
  } catch (error) {
    messagePopup("", "Notification Setting failed", "error");
    yield put(notificationSettingFail("Notification Setting Status failed"));
  }
}

export function* savenotificationSettingSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Notificationsettings/updateNotificationStatus`,
      action.payload,
      "post",
      true
    );
    if (response && response.status === 200) {
      messagePopup("", "Notification Settings Are Updated", "success");
      yield put(
        savenotificationSettingSuccess(
          "Notification Settings Updated Successfully"
        )
      );
    } else {
      messagePopup("", "Server Error,Try Again", "error");
      yield put(
        savenotificationSettingFail("Notification Settings Are Updated failed")
      );
    }
  } catch (error) {
    messagePopup("", "Server Error, Try Again failed", "error");
    yield put(
      savenotificationSettingFail("Notification Settings Are Updated failed")
    );
  }
}

export function* savesettingNotificationSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      // messagePopup("", "Notification Settings Are Updated", "success");
      yield put(
        savenotificationSettingSuccess(
          "Notification Settings Updated Successfully"
        )
      );
      if(action.payload.refreshPage)
      {
        action.payload.refreshPage();
      }
    } else {
      messagePopup("", "Server Error,Try Again", "error");
      yield put(
        savenotificationSettingFail("Notification Settings Are Updated failed")
      );
    }
  } catch (error) {
    messagePopup("", "Server Error, Try Again failed", "error");
    yield put(
      savenotificationSettingFail("Notification Settings Are Updated failed")
    );
  }
}

//Watcher
export function* watchNotificationSettingSaga() {
  yield takeEvery(
    actionType.GET_NOTIFICATION_SETTING_START,
    notificationSettingSaga
  );
  yield takeEvery(actionType.SAVE_NOTIFICATION_SETTING_START, savenotificationSettingSaga);
  yield takeEvery(actionType.SAVE_SETTING_NOTIFICATION_START, savesettingNotificationSaga);
}
