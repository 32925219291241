import { all, fork } from "redux-saga/effects";
import rootCollegesSagas from "./colleges/rootCollegesSagas";
import rootHomeSagas from "./homepage/rootHomeSagas";
import rootPaymentSagas from "./payment/rootPaymentSagas";
import rootResourcesSagas from "./resources/rootResourcesSagas";
import rootUpgradePlansSagas from "./upgradePlans/rootUpgradePlansSagas";
import rootViewCommitmentsSagas from "./viewCommitment/rootViewCommitmentsSagas";
import rootMessageSagas from "./message/rootMessageSagas";
import rootDashboardCountSagas from './dashboardCount/rootDashboardCountSagas';
import rootMessageReplySagas from './messageReply/rootMessageReplySagas';
import rootComposeMessageSagas from "./composeMessage/rootComposeMessageSagas";
import rootNotificationSagas from "./notification/rootNotificationSagas";

export default function* rootDashboardSagas() {
  yield all([
    fork(rootCollegesSagas),
    fork(rootUpgradePlansSagas),
    fork(rootHomeSagas),
    fork(rootViewCommitmentsSagas),
    fork(rootPaymentSagas),
    fork(rootResourcesSagas),
    fork(rootMessageSagas),
    fork(rootDashboardCountSagas),
    fork(rootMessageReplySagas),
    fork(rootComposeMessageSagas),
    fork(rootNotificationSagas),
  ]);
}
