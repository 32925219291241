import React, { useState, useEffect, useRef } from "react";
import {connect, useDispatch} from "react-redux";
import LeftSideEditProfile from "./LeftSideEditProfile";
import axios from "axios";
import { imageUploadUrl } from "../../../../../config";
import { getUserInfo, getAuthToken } from "../../../../../services/authService";
import { Title } from "./videoPhoto/Component";
import Dialog from "@mui/material/Dialog";
import closeGrayIcon from "./assets/img/close-gray-icon.svg";
import trashCircleIcon from "./assets/img/trash-circle-icon.svg";
import messagePopup from "../../../../../utils/messagePopup";
import { allPressListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/pressList";
import {getAllPress, savePressCreate, deletePressItem, saveAllVidPhotoQuery, savePressUpdateAll} from "./BasicQuery";
import { updateBasicPressListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/UpdatePress";
import { saveAcademicFileQuery } from "./GradesScoresQuery";
import { validURL } from "../../../../../utils/validationUtils";
import BackdropLoader from "../../../../common/Loader";
import {fetchGraphMethod, fetchMethod} from "../../../../../../src/services/httpService";
import ScreenSwitch from "./ScreenSwitch";
import SubHeader from "./SubHeader";
import GoTopButton from "./GoTopButton";
import { ReactSortable } from "react-sortablejs";
import { saveVideoPhotoStart } from "../../../../../redux/athlete/dashboard/editUserProfile/photo-video/saveVideoPhoto";
import { set } from "lodash";
import {getAllVideoPhotoInfoQuery} from "./videoPhoto/VideoPhotoQuery";
import {vidCountIncre} from "../../../../common/userViewCount";
import { sendVideoPhotoNotification } from "../../../../../utils/sendNotificationToAdvisor"
import toastr from "toastr";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const PNGSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g transform="translate(-471 -908)">
        <g transform="translate(448.496 916)">
          <path
            className="a"
            d="M107.475,255.346c0-.62-.43-.99-1.191-.99a2.582,2.582,0,0,0-.629.06v1.989a2.334,2.334,0,0,0,.51.04C106.975,256.445,107.475,256.035,107.475,255.346Z"
            transform="translate(-70.869 -243.093)"
          />
          <path
            className="b"
            d="M51.736,8.724H51.15V5.9a.412.412,0,0,0-.005-.053.469.469,0,0,0-.113-.31L46.33.164l0,0a.474.474,0,0,0-.1-.08L46.2.062A.493.493,0,0,0,46.1.022L46.079.014A.477.477,0,0,0,45.969,0H34.413a.957.957,0,0,0-.956.956V8.724h-.586A1.367,1.367,0,0,0,31.5,10.091V17.2a1.367,1.367,0,0,0,1.367,1.367h.586v4.866a.957.957,0,0,0,.956.956H50.194a.958.958,0,0,0,.956-.956V18.566h.586A1.367,1.367,0,0,0,53.1,17.2V10.091A1.367,1.367,0,0,0,51.736,8.724ZM34.413.957H45.491V5.85a.478.478,0,0,0,.478.478h4.225v2.4H34.413Zm6.073,13.915V16.9h-1.4V10.163h1.779l1.4,2.469a19.453,19.453,0,0,1,1.1,2.309h.03a25.019,25.019,0,0,1-.13-2.809V10.163h1.4V16.9h-1.6l-1.44-2.6a25,25,0,0,1-1.171-2.379l-.029.01C40.466,12.822,40.486,13.772,40.486,14.872ZM33.277,16.9V10.253a12.487,12.487,0,0,1,2.059-.14,3.232,3.232,0,0,1,2.059.54,1.971,1.971,0,0,1,.72,1.559,2.152,2.152,0,0,1-.62,1.6,3.124,3.124,0,0,1-2.189.71,3.879,3.879,0,0,1-.52-.03V16.9h-1.51Zm16.917,6.273H34.413V18.566H50.194v4.607Zm1.419-6.583a7.549,7.549,0,0,1-2.25.38,3.755,3.755,0,0,1-2.739-.91,3.261,3.261,0,0,1-.95-2.449c.01-2.239,1.64-3.519,3.849-3.519a4.635,4.635,0,0,1,1.869.33l-.319,1.22a3.735,3.735,0,0,0-1.57-.29,2.039,2.039,0,0,0-2.229,2.179,2.207,2.207,0,0,0,2.87,2.109v-1.41H49.1v-1.19h2.51v3.549Z"
            transform="translate(0)"
          />
        </g>
      </g>
    </svg>
  );
};

const PDFSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="23"
      viewBox="0 0 21 23"
    >
      <g transform="translate(-31.504)">
        <path
          className="a"
          d="M118.654,259.53c0-.619-.43-.989-1.189-.989a2.6,2.6,0,0,0-.629.06v1.989a2.358,2.358,0,0,0,.509.04C118.155,260.629,118.654,260.22,118.654,259.53Z"
          transform="translate(-81.648 -247.745)"
        />
        <path
          className="a"
          d="M257.046,259.019a3.172,3.172,0,0,0-.69.06v4.406a2.769,2.769,0,0,0,.53.03,2.088,2.088,0,0,0,2.278-2.358A1.931,1.931,0,0,0,257.046,259.019Z"
          transform="translate(-215.125 -248.203)"
        />
        <path
          className="a"
          d="M51.175,8.227h-.569V5.562a.377.377,0,0,0,0-.05.435.435,0,0,0-.11-.292L45.918.154l0,0a.458.458,0,0,0-.093-.075l-.03-.017A.488.488,0,0,0,45.7.021L45.675.013A.478.478,0,0,0,45.568,0H34.332a.917.917,0,0,0-.93.9V8.227h-.569A1.31,1.31,0,0,0,31.5,9.516v6.7a1.31,1.31,0,0,0,1.329,1.289H33.4V22.1a.917.917,0,0,0,.93.9H49.676a.917.917,0,0,0,.93-.9V17.509h.569A1.31,1.31,0,0,0,52.5,16.22v-6.7A1.31,1.31,0,0,0,51.175,8.227ZM34.332.9H45.1V5.517a.458.458,0,0,0,.465.451h4.107V8.227H34.332Zm11.415,12a3.367,3.367,0,0,1-1.155,2.728,5.092,5.092,0,0,1-3.2.869,14.065,14.065,0,0,1-1.782-.1V9.786a14.477,14.477,0,0,1,2.123-.14,4.573,4.573,0,0,1,2.843.719A2.946,2.946,0,0,1,45.748,12.9ZM33.622,16.431V9.786a13.3,13.3,0,0,1,2.122-.14,3.405,3.405,0,0,1,2.122.54,1.945,1.945,0,0,1,.741,1.559,2.116,2.116,0,0,1-.638,1.6,3.283,3.283,0,0,1-2.256.71,4.052,4.052,0,0,1-.535-.03v2.408H33.622Zm16.054,5.422H34.332V17.509H49.676v4.345Zm1.347-10.908H48.354v1.539h2.493v1.239H48.354v2.708H46.779V9.7h4.244Z"
          transform="translate(0)"
        />
      </g>
    </svg>
  );
};

const NOICONSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.279"
      height="18.279"
      viewBox="0 0 18.279 18.279"
    >
      <g transform="translate(6.146 0)">
        <path
          className="a"
          d="M183.037,1.255a4.284,4.284,0,0,0-6.059,0L173.4,4.835a4.325,4.325,0,0,0,0,6.059,4.078,4.078,0,0,0,.861.654l.654-.654a1.471,1.471,0,0,0,.27-1.291,2.068,2.068,0,0,1-.27-.223,2.165,2.165,0,0,1,0-3.029l3.581-3.581A2.142,2.142,0,0,1,181.523,5.8l-2.367,2.367a6.166,6.166,0,0,1,.268,2.755c.01-.01.023-.017.033-.027l3.581-3.581A4.284,4.284,0,0,0,183.037,1.255Z"
          transform="translate(-172.159 0)"
        />
      </g>
      <g transform="translate(0 6.454)">
        <path
          className="a"
          d="M11.172,181.43a4.079,4.079,0,0,0-.861-.654l-.654.654a1.471,1.471,0,0,0-.27,1.291,2.06,2.06,0,0,1,.27.223,2.165,2.165,0,0,1,0,3.029L5.8,189.832A2.142,2.142,0,0,1,2.77,186.8l2.644-2.644a6.165,6.165,0,0,1-.268-2.755c-.01.01-.023.017-.033.027l-3.858,3.858a4.284,4.284,0,0,0,6.059,6.059l3.858-3.858A4.322,4.322,0,0,0,11.172,181.43Z"
          transform="translate(0 -180.776)"
        />
      </g>
    </svg>
  );
};

const DOCSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="25.794"
      viewBox="0 0 21 25.794"
    >
      <g transform="translate(-44.806)">
        <path
          className="a"
          d="M59.286,0H48.926A2.749,2.749,0,0,0,46.18,2.745V12.9h-.268A1.106,1.106,0,0,0,44.806,14v6.709a1.106,1.106,0,0,0,1.106,1.106h.268v1.23a2.748,2.748,0,0,0,2.745,2.745H63.061a2.748,2.748,0,0,0,2.744-2.745V6.5ZM46.636,14.925a9.476,9.476,0,0,1,1.422-.1,3,3,0,0,1,1.906.5,2.06,2.06,0,0,1,.787,1.754,2.359,2.359,0,0,1-.773,1.884,3.34,3.34,0,0,1-2.147.6,9.1,9.1,0,0,1-1.194-.069v-4.57Zm16.425,9.188H48.926a1.065,1.065,0,0,1-1.063-1.064v-1.23H61.04a1.106,1.106,0,0,0,1.106-1.106V14A1.106,1.106,0,0,0,61.04,12.9H47.862V2.745a1.065,1.065,0,0,1,1.063-1.062l9.731-.01v3.6a1.9,1.9,0,0,0,1.9,1.9l3.524-.01.04,15.885A1.064,1.064,0,0,1,63.061,24.113ZM51.229,17.224a2.252,2.252,0,0,1,2.264-2.437A2.169,2.169,0,0,1,55.7,17.141a2.218,2.218,0,0,1-2.285,2.45A2.169,2.169,0,0,1,51.229,17.224Zm7.548,1.491a2.636,2.636,0,0,0,.877-.152l.159.821a2.989,2.989,0,0,1-1.194.2,2.213,2.213,0,0,1-2.437-2.326,2.372,2.372,0,0,1,2.54-2.471,2.753,2.753,0,0,1,1.146.207l-.214.836a2.263,2.263,0,0,0-.884-.173,1.4,1.4,0,0,0-1.484,1.54A1.389,1.389,0,0,0,58.777,18.715Z"
        />
        <path
          className="a"
          d="M100.693,293.753a1.334,1.334,0,0,0-1.464-1.477,2.168,2.168,0,0,0-.476.041v3.044a1.9,1.9,0,0,0,.366.021A1.443,1.443,0,0,0,100.693,293.753Z"
          transform="translate(-51.061 -276.639)"
        />
        <path
          className="a"
          d="M187.891,293.561c0-.835-.4-1.553-1.125-1.553s-1.126.677-1.126,1.581.428,1.553,1.132,1.553S187.891,294.466,187.891,293.561Z"
          transform="translate(-133.3 -276.386)"
        />
      </g>
    </svg>
  );
};

const GIFSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g transform="translate(-471 -908)">
        <g transform="translate(449.496 916)">
          <path
            className="a"
            d="M50.706,8.28h-.556V5.6a.392.392,0,0,0,0-.051.445.445,0,0,0-.108-.294L45.575.155l0,0a.446.446,0,0,0-.09-.076l-.03-.018a.476.476,0,0,0-.09-.038L45.338.013a.46.46,0,0,0-.1-.013H34.265a.909.909,0,0,0-.908.908V8.28H32.8a1.3,1.3,0,0,0-1.3,1.3v6.747a1.3,1.3,0,0,0,1.3,1.3h.556V22.24a.909.909,0,0,0,.908.908H49.243a.909.909,0,0,0,.908-.908V17.621h.556a1.3,1.3,0,0,0,1.3-1.3V9.577A1.3,1.3,0,0,0,50.706,8.28ZM49.243,21.994H34.265V17.621H49.243v4.373ZM38.326,15.255a2.056,2.056,0,0,0,.754-.1V13.737H38.035v-1.2h2.524v3.57a7.589,7.589,0,0,1-2.263.382,3.778,3.778,0,0,1-2.756-.915,3.278,3.278,0,0,1-.955-2.464c.01-2.253,1.649-3.54,3.872-3.54a4.669,4.669,0,0,1,1.88.332l-.322,1.227a3.758,3.758,0,0,0-1.579-.291,2.051,2.051,0,0,0-2.242,2.192A2.037,2.037,0,0,0,38.326,15.255Zm3.43,1.167V9.645h1.538v6.777Zm6.839-3.972V13.7H46.161v2.725H44.623V9.645h4.144V10.9H46.161V12.45h2.434Zm.648-4.17H34.265V.908H44.779V5.552a.454.454,0,0,0,.454.454h4.01V8.28Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </svg>
  );
};

const JPGSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g transform="translate(-471 -668)">
        <g transform="translate(479 674.99)">
          <path
            className="1"
            d="M17.659,1.009H8.22a2.5,2.5,0,0,0-2.5,2.5v.268H1.008A1.008,1.008,0,0,0,0,4.786v5.523a1.008,1.008,0,0,0,1.008,1.008H5.718V22.009a2.5,2.5,0,0,0,2.5,2.5H21.1a2.5,2.5,0,0,0,2.5-2.5V6.929ZM4.5,8.119c0,1.208-.578,1.629-1.509,1.629a2.493,2.493,0,0,1-.7-.1L2.4,8.873a1.585,1.585,0,0,0,.491.075c.4,0,.654-.182.654-.843V5.439H4.5V8.119Zm.8,1.559V5.5a7.9,7.9,0,0,1,1.3-.088,2.033,2.033,0,0,1,1.3.34,1.239,1.239,0,0,1,.453.981,1.355,1.355,0,0,1-.39,1.006,1.966,1.966,0,0,1-1.377.446,2.475,2.475,0,0,1-.327-.018V9.678H5.3Zm15.8,13.3H8.22a.97.97,0,0,1-.969-.969V11.317h7.211a1.008,1.008,0,0,0,1.008-1.008V4.786a1.008,1.008,0,0,0-1.008-1.008H7.251V3.51a.97.97,0,0,1,.969-.968l8.866-.009V5.81A1.735,1.735,0,0,0,18.82,7.545l3.21-.009.036,14.473A.97.97,0,0,1,21.1,22.978Zm-10-14.03a1.27,1.27,0,0,0,.472-.063V8h-.654V7.251h1.579V9.483a4.746,4.746,0,0,1-1.415.239A2.36,2.36,0,0,1,9.355,9.15a2.052,2.052,0,0,1-.6-1.541,2.188,2.188,0,0,1,2.422-2.214,2.935,2.935,0,0,1,1.176.207l-.2.767a2.351,2.351,0,0,0-.988-.182,1.283,1.283,0,0,0-1.4,1.371A1.274,1.274,0,0,0,11.1,8.948Z"
          />
          <path
            className="b"
            d="M210.819,252.873h-8.253a.693.693,0,0,0-.693.692v6.273a.693.693,0,0,0,.693.692h8.253a.693.693,0,0,0,.692-.692v-6.273A.693.693,0,0,0,210.819,252.873Zm-.646,6.47a.368.368,0,0,1-.326.2h-2.8a.367.367,0,0,1-.315-.558l1.3-2.165a.367.367,0,0,1,.3-.178h.012a.368.368,0,0,1,.3.16l1.493,2.165A.368.368,0,0,1,210.173,259.343Zm-.656-5.813a1.218,1.218,0,1,1-1.218,1.218A1.218,1.218,0,0,1,209.517,253.53Zm-6.134,6.008a.368.368,0,0,1-.315-.558l1.965-3.262a.368.368,0,0,1,.618-.019l1.234,1.788-.7,1.162a1,1,0,0,0-.066.888h-2.735Z"
            transform="translate(-192.034 -239.587)"
          />
        </g>
      </g>
    </svg>
  );
};

const DOCXSVG = () =>{
  return(
      <svg
           xmlns="http://www.w3.org/2000/svg"
           width="25" height="25"
           viewBox="0 0 548.291 548.291"
      >
<g>
	<path d="M486.201,196.121h-13.166v-63.525c0-0.399-0.062-0.795-0.115-1.2c-0.021-2.522-0.825-5-2.552-6.96L364.657,3.675
		c-0.033-0.031-0.064-0.042-0.085-0.073c-0.63-0.704-1.364-1.292-2.143-1.796c-0.229-0.157-0.461-0.286-0.702-0.419
		c-0.672-0.365-1.387-0.672-2.121-0.893c-0.2-0.052-0.379-0.134-0.577-0.188C358.23,0.118,357.401,0,356.562,0H96.757
		C84.894,0,75.256,9.649,75.256,21.502v174.613H62.092c-16.971,0-30.732,13.756-30.732,30.73v159.81
		c0,16.966,13.761,30.736,30.732,30.736h13.164V526.79c0,11.854,9.638,21.501,21.501,21.501h354.776
		c11.853,0,21.501-9.647,21.501-21.501V417.392h13.166c16.966,0,30.729-13.764,30.729-30.731v-159.81
		C516.93,209.877,503.167,196.121,486.201,196.121z M96.757,21.507h249.054v110.006c0,5.94,4.817,10.751,10.751,10.751h94.972
		v53.861H96.757V21.507z M367.547,335.847c7.843,0,16.547-1.701,21.666-3.759l3.916,20.301c-4.768,2.376-15.509,4.949-29.493,4.949
		c-39.748,0-60.204-24.73-60.204-57.472c0-39.226,27.969-61.055,62.762-61.055c13.465,0,23.705,2.737,28.31,5.119l-5.285,20.64
		c-5.287-2.226-12.615-4.263-21.832-4.263c-20.641,0-36.663,12.444-36.663,38.027C330.718,321.337,344.362,335.847,367.547,335.847z
		 M291.647,296.97c0,37.685-22.854,60.537-56.444,60.537c-34.113,0-54.066-25.759-54.066-58.495
		c0-34.447,21.995-60.206,55.94-60.206C272.39,238.806,291.647,265.248,291.647,296.97z M67.72,355.124V242.221
		c9.552-1.532,21.999-2.375,35.13-2.375c21.83,0,35.981,3.916,47.055,12.276c11.945,8.863,19.455,23.021,19.455,43.311
		c0,21.994-8.017,37.181-19.105,46.556c-12.111,10.058-30.528,14.841-53.045,14.841C83.749,356.825,74.198,355.968,67.72,355.124z
		 M451.534,520.968H96.757V417.392h354.776V520.968z M471.245,355.627l-10.409-20.804c-4.263-8.012-6.992-13.99-10.231-20.636
		h-0.342c-2.388,6.656-5.28,12.624-8.861,20.636l-9.552,20.804h-29.675l33.254-58.158l-32.054-56.786h29.849l10.058,20.984
		c3.413,6.979,5.963,12.614,8.694,19.092h0.335c2.729-7.332,4.955-12.446,7.843-19.092l9.721-20.984h29.683l-32.406,56.103
		l34.105,58.841H471.245z"/>
  <path d="M141.729,296.277c0.165-23.869-13.814-36.494-36.15-36.494c-5.807,0-9.552,0.514-11.772,1.027v75.2
		c2.226,0.509,5.806,0.509,9.047,0.509C126.388,336.698,141.729,323.743,141.729,296.277z"/>
  <path d="M208.604,298.493c0,22.515,10.575,38.372,27.969,38.372c17.567,0,27.617-16.703,27.617-39.045
		c0-20.641-9.885-38.377-27.801-38.377C218.827,259.448,208.604,276.162,208.604,298.493z"/>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
</svg>
  )
}

const AddEditOverlay = ({
  isEdit = false,
  data = {},
  onAddEditClick,
  onCloseAddEditClick,
  onSave,
}) => {
  let [details, setDetails] = useState(data),
    validationMsg = {
      title: "",
      link: "",
      date: "",
      type: "",
    },
    [academicFilesFields, setAcademicFilesFields] = useState({
      fileid: "",
      fileName: "",
      selectedFile: "",
      type: "",
    }),
    [validation, setValidation] = useState(validationMsg),
    onHandleChange = (field, e) => {
      let fieldsT = details;
      fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
      setDetails({
        ...fieldsT,
      });
      setValidation(false);
    };

  const isValidURL = (string) => {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  };

  const handleValidation = (details) => {
    let validationClone = { ...validationMsg },
      errorFlag = false;
    if (!details["title"]) {
      validationClone["title"] = "Title is required.";
      errorFlag = true;
    }
    if (details["link"] && !validURL(details["link"])) {
      validationClone["link"] = "Press Link is invalid or missing.";
      errorFlag = true;
    }
    if (details["link"] && !isValidURL(details["link"])) {
      validationClone["link"] = "Press Link is invalid or missing.";
      errorFlag = true;
    }
    if (!details["date"]) {
      validationClone["date"] = "Date is required.";
      errorFlag = true;
    }
    errorFlag && setValidation({ ...validationClone });
    !errorFlag && onSave(details, false);
  };

  const selectAcadFiles = (e) => {
    let { files } = e.target;
    const filedetails = e.target.files[0].name.split(".")
    let fileExtension = files[0]?.name?.split(".")[filedetails?.length - 1]?.toLowerCase();
    let fileSize = parseInt(files[0].size) / (1024 * 1024);
    if (
      fileExtension == "pdf" ||
      fileExtension == "jpg" ||
      fileExtension == "png" ||
      fileExtension == "doc" ||
      fileExtension == "docx" ||
      fileExtension == "gif"
    ) {
      if (parseFloat(fileSize) < 10) {
        let fieldsT = academicFilesFields;
        fieldsT["selectedFile"] = files[0];
        fieldsT["fileExtension"] = fileExtension;
        fieldsT["fileName"] = files ? files[0].name : "";
        fieldsT["type"] = files ? files[0].type : "";
        setAcademicFilesFields({
          ...fieldsT,
        });
        details["filename"] = fieldsT["fileName"];
        details["type"] = files ? files[0].type : "";
        details["fileExtension"] = fileExtension;
      } else {
        // messagePopup("", "File size not more than 10 MB.", "error");
        toastr.error("File size not more than 10 MB.", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates:true,
        })
        //this func is commented as it is closing the add/edit popup
        // onCloseAddEditClick();
        // setDetails({ filename: "" });
      }
      addFiles(e);
    } else {
      // messagePopup(
      //   "",
      //   "File type must be .pdf, .doc, .docx, .jpg, .png, .gif",
      //   "error"
      // );
      toastr.error("File type must be .pdf, .doc, .docx, .jpg, .png, .gif", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
      //this func is commented as it is closing the add/edit popup
      // onCloseAddEditClick();
    }
  };

  const addFiles = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", academicFilesFields.selectedFile);
    const response =
      academicFilesFields.selectedFile != ""
        ? await axios({
            url: imageUploadUrl,
            method: "POST",
            data: formData,
            headers: {
              access_token: getAuthToken(),
            },
          })
        : "";
    return response;
  };

  return (
    <div
      className="modal fade common-modal form-modal addEditOverlay addEditPressOverlay show editPressOverlay"
      style={{ display: "block" }}
      id="pressOverlay"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <form className="modal-content">
          <div className="modal-header">
            {data && data.id ? (
              <h5 className="modal-title"> Edit Press Link </h5>
            ) : (
              <h5 className="modal-title"> Add New Press Link</h5>
            )}

            <button
              type="button"
              className="close"
              style={{ color: "#000" }}
              onClick={onCloseAddEditClick}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body bottom-border">
            <div className="form-group">
              <label>
                Title <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="title"
                onChange={(e) => onHandleChange("title", e)}
                value={details["title"] || ""}
                placeholder={"Title here"}
              />
              {validation["title"] && (
                <div style={{ textAlign: "left" }} className="error">
                  {validation["title"]}
                </div>
              )}
            </div>
            <div className="form-group">
              <label>
                Press Link
              </label>
              <input
                type="text"
                name="link"
                onChange={(e) => onHandleChange("link", e)}
                value={details["link"] || ""}
                placeholder={"https://sportsforce.com/"}
              />
              {validation["link"] && (
                <div style={{ textAlign: "left" }} className="error">
                  {validation["link"]}
                </div>
              )}
            </div>
            <div className="form-group mb-0">
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => onHandleChange("date", e)}
                    type="Date"
                    value={details["date"] || ""}
                    name="date"
                    id="date"
                    placeholder="mm/dd/yyyy"
                    max="9999-12-31"
                  />
                  {validation["date"] && (
                    <div style={{ textAlign: "left" }} className="error">
                      {validation["date"]}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group mb-0">
              <div className="uploadFeature uploadList mb-0">
                <label>Upload a File</label>
                <label className="btn green mb-0" htmlFor="uploadFile">
                  Choose File
                </label>
                
                <h5>{details.filename && <span className="uploadfilename">{details.filename}</span>} Maximum size of 10mb. DOC, DOCX, PDF, JPG, GIF, PNG.</h5>
                <input
                  type="file"
                  id="uploadFile"
                  hidden=""
                  onChange={(e) => {
                    selectAcadFiles(e);
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {data && data.id ? (
              <button
                type="button"
                className="btn blue"
                onClick={() => handleValidation(details, false)}
              >
                Update
              </button>
            ) : (
              <button
                type="button"
                className="btn blue"
                onClick={() => handleValidation(details, false)}
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const DeleteItem = ({ onDeleteClick, onClose }) => {
  return (
    <div
      className="modal fade common-modal trash-modal show"
      id="ReferenceTrashModal"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" onClick={onClose}>
              <img src={closeGrayIcon} alt="close Gray Icon" />
            </button>
            <div className="icon-box-first">
              <img src={trashCircleIcon} alt="trash Circle Icon" />
            </div>
            <div className="content-box">
              <h4>Are you sure you want to delete this press?</h4>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              onClick={onDeleteClick}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn gray"
              data-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PressListing = ({ data = [], onSaveEdit, onDelete, handleListChange, handleOnChoose, handleOnChooseDnd }) => {
  const [showAddEditModal, setShowAddEditModal] = useState(false),
    [editData, setEditData] = useState(data),
    [showDeleteModal, setShowDeleteModal] = useState(false);
    const [id, setId] = useState(null);

  const onClickEdit = (e, data) => {
    e.preventDefault();  
    e.stopPropagation();
      setEditData(data);
      setShowAddEditModal(true);
    },
    onSave = async (details, flag) => {
      setShowAddEditModal(false);
      if (Object.keys(details).length !== 0) {
        let handleVisible = await onSaveEdit(details);
      }
    },
    onDeleteClick = (id) => {
      setId(id);
      setShowDeleteModal(!showDeleteModal);
    },
    deletePress = async () => {
      setShowDeleteModal(!showDeleteModal);
      let handleVisible = await onDelete(id);
    };
  const onCloseAddEditClick = (e) => {
   !!e && e.preventDefault();
   !!e && e.stopPropagation();
    setShowAddEditModal(false);
  };

  return (
    <>
    <ul className="press-listing">
            {data?.length > 0 ? (
              // <ReactSortable
              //   list={data}
              //   setList={handleListChange}
              //   onEnd={handleOnChoose}
              //   scrollSpeed={40}
              //     forceFallback={true}
              //     scroll={true}
              //     scrollSensitivity={100}
              //     bubbleScroll={true}
              // >
              <DragDropContext onDragEnd={handleOnChooseDnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                {data.map((element, index) => {
                  let filedetails = element?.filename?.split("."),
                      fileExtension = element?.filename?.split(".")[filedetails?.length - 1]?.toLowerCase() || "noIcon";
                  return (<Draggable key={element.id} draggableId={"item"+element.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                  <li className="item-box">
                  <div className="box-detail drag">
                    <div className="icon-box">
                      {fileExtension === "gif" && <GIFSVG />}
                      {fileExtension === "noIcon" && <NOICONSVG />}
                      {fileExtension === "png" && <PNGSVG />}
                      {(fileExtension === "doc" && <DOCSVG />) ||
                        (fileExtension === "docx" && <DOCXSVG />)}
                      {fileExtension === "pdf" && <PDFSVG />}
                      {fileExtension === "jpg" && <JPGSVG />}
                    </div>
                    <div className="content-box">
                      <h3>{element.title}</h3>
                    </div>
                  </div>
                  <div className="buton-box">
                    <button className="btn green" onClick={(e) => onClickEdit(e, element)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.111"
                        height="20.028"
                        viewBox="0 0 20.111 20.028"
                      >
                        <path
                          className="a"
                          d="M19.594,3.23,16.954.589A1.948,1.948,0,0,0,14.205.7a1.95,1.95,0,0,0-.111,2.751l2.64,2.64a1.95,1.95,0,0,0,2.751-.111,1.949,1.949,0,0,0,.109-2.751ZM2.765,12.139l5.28,5.28,8.58-8.578-5.28-5.28-8.58,8.578ZM0,20.1l6.964-1.394-5.57-5.569Z"
                          transform="translate(0 -0.074)"
                        />
                      </svg>
                    </button>
                    <button className="btn" onClick={(e) => onDeleteClick(element)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.415"
                        height="20.203"
                        viewBox="0 0 16.415 20.203"
                      >
                        <path
                          className="a"
                          d="M62.836,2.525H59.364V1.894A1.894,1.894,0,0,0,57.47,0H54.945a1.894,1.894,0,0,0-1.894,1.894v.631H49.578A1.578,1.578,0,0,0,48,4.1V5.366A.631.631,0,0,0,48.631,6H63.783a.631.631,0,0,0,.631-.631V4.1A1.578,1.578,0,0,0,62.836,2.525Zm-8.523-.631a.632.632,0,0,1,.631-.631H57.47a.632.632,0,0,1,.631.631v.631H54.313Z"
                          transform="translate(-48)"
                        />
                        <path
                          className="a"
                          d="M73.561,184a.2.2,0,0,0-.2.207l.521,10.932a1.892,1.892,0,0,0,1.892,1.8h9.588a1.892,1.892,0,0,0,1.892-1.8l.521-10.932a.2.2,0,0,0-.2-.207Zm9.534,1.578a.631.631,0,1,1,1.263,0v8.207a.631.631,0,1,1-1.263,0Zm-3.157,0a.631.631,0,1,1,1.263,0v8.207a.631.631,0,1,1-1.263,0Zm-3.157,0a.631.631,0,1,1,1.263,0v8.207a.631.631,0,1,1-1.263,0Z"
                          transform="translate(-72.363 -176.74)"
                        />
                      </svg>
                    </button>
                  </div>
                </li>
                </div>
                  )}
                </Draggable>)
            })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
              // </ReactSortable>
            ) : null}
          </ul>
      {showAddEditModal && (
        <Dialog
            open={showAddEditModal}
            onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                setShowAddEditModal(false)
                }
            }}
            className="matDialogCustom"
        >
            <AddEditOverlay
                isEdit={true}
                data={editData}
                onAddEditClick={() => {
                    setShowAddEditModal(true);
                }
            }
                onCloseAddEditClick={onCloseAddEditClick}
                onSave={onSave}
            />
        </Dialog>
      )}
      <Dialog open={showDeleteModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onDeleteClick()
                }
              }}
              className="matDialogCustom"
      >
        <DeleteItem onDeleteClick={deletePress} onClose={onDeleteClick} />
      </Dialog>
    </>
  );
};

const EditSection = ({ props, pressListData, onSaveEdit, onDelete, onClose, onDragDrop }) => {
  const [editData, setEditData] = useState();
  const [isDrag, setIsDrag] = useState(false);
  useEffect(() => {
    if (props?.pressList && props?.pressList?.data) {
      setEditData([...props.pressList.data.parentData]);
    }
  }, [props.pressList]);

  const handleListChange = (items) => {
    let newList = items.map((item, index) => {
      delete item.chosen;
      item.pressrank = index + 1;
      return item;
    });
    setEditData([...newList]);
  };
  const handleOnChoose = (data) => {
    const { oldIndex, newIndex } = data;
    let start = oldIndex > newIndex ? newIndex : oldIndex;
    let end = oldIndex < newIndex ? newIndex : oldIndex;
    
    const updatedCollegeRank = editData.slice(start, end + 1).map((item) => {
      return { id: item.id, pressrank: item.pressrank };
    });
    
    onDragDrop({
      userId: getUserInfo() ? getUserInfo().id : 0,
      tableName: "press",
      updatedCollegeRank,
    });

    setEditData([...editData]);
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  const handleOnChooseDnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      editData,
      result.source.index,
      result.destination.index
    );

    const updatedItems = [];
    const updatedCollegeRank = items.map((item, index) => {
      item['pressrank'] = index+1;
      updatedItems.push(item);
      return { id: item.id, pressrank: index+1 };
    });

    onDragDrop({
      userId: getUserInfo() ? getUserInfo().id : 0,
      tableName: "press",
      updatedCollegeRank,
    });
    //setEditData([...items]);
    setEditData([...updatedItems]);
  };
  return (
    <div className="editSection">
      <Title title={"Press"} />
      <div className="check-list-sec">
        <div className="event-main-wrapper press-main-wrap">
          <div className="event-top-block">
            <div className="short-text">
              <p>
                <button className="btn green" onClick={onClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.758"
                    height="13.758"
                    viewBox="0 0 13.758 13.758"
                  >
                    <g transform="translate(4.626 0)">
                      <path
                        className="a"
                        d="M180.347.944a3.225,3.225,0,0,0-4.56,0l-2.7,2.7a3.255,3.255,0,0,0,0,4.56,3.069,3.069,0,0,0,.648.492l.492-.492a1.108,1.108,0,0,0,.2-.972,1.557,1.557,0,0,1-.2-.168,1.63,1.63,0,0,1,0-2.28l2.695-2.695a1.612,1.612,0,0,1,2.28,2.28l-1.781,1.781a4.641,4.641,0,0,1,.2,2.074c.008-.008.017-.013.025-.02l2.7-2.7A3.225,3.225,0,0,0,180.347.944Z"
                        transform="translate(-172.159 0)"
                      />
                    </g>
                    <g transform="translate(0 4.858)">
                      <path
                        className="a"
                        d="M8.409,181.268a3.069,3.069,0,0,0-.648-.492l-.492.492a1.107,1.107,0,0,0-.2.972,1.552,1.552,0,0,1,.2.168,1.63,1.63,0,0,1,0,2.28l-2.9,2.9a1.612,1.612,0,0,1-2.28-2.28l1.99-1.99a4.64,4.64,0,0,1-.2-2.074c-.008.008-.017.013-.025.02l-2.9,2.9a3.225,3.225,0,0,0,4.56,4.56l2.9-2.9A3.253,3.253,0,0,0,8.409,181.268Z"
                        transform="translate(0 -180.776)"
                      />
                    </g>
                  </svg>
                  Add Press
                </button>
              </p>
              <p>Click and drag the rows below to reorder your Press Links.</p>
            </div>
          </div>
          <div>
            <PressListing 
              data={editData}
              onSaveEdit={onSaveEdit}
              onDelete={onDelete}
              handleListChange={handleListChange}
              handleOnChoose={handleOnChoose}
              handleOnChooseDnd={handleOnChooseDnd}
            />
          </div>
        </div>
        <div className="common-button-box with-next-prev center mt-3">
          <div className="next-prev-button">
            <ScreenSwitch
              prevLink={"/References"}
              nextLink={"/collegeInsights"}
              isSaved={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Press = (props) => {
  const fakeInput = useRef(null);

  const [sideMenuOpen, setSideMenu] = useState(false),
    [showAddEditModal, setShowAddEditModal] = useState(false),
    [rank, setRank] = useState(true),
    [editPressData, setEditPressData] = useState([]),
    [pressListData, setPressData] = useState([]),
     // state for all video and photo data
    [allViPhoto, setAllVidPhoto] = useState([]),
    //for progress bar
    dispatch = useDispatch();

  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };
  useEffect(() => {
    getAllPressDetails();
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);

  useEffect(() => {
    if (props?.pressList && props?.pressList?.data) {
      setPressData([...props.pressList.data.parentData]);
      setEditPressData([...props.pressList.data.parentData]);
      setRank(!rank);
    }
  }, [props.pressList]);
  // used for all video and photo api call
  useEffect(()=>{
    getAllVideoAndPhoto()
  },[])
   //fun for all video and photo api call
  const getAllVideoAndPhoto = async () => {
    const finalObj={
        obj : {
          and:[
            {
              or:[
                {
                  videophotosuploadedtype : "Press",
                }
                ]
            },
            {
              userid : getUserInfo() ? getUserInfo().id : null,
              active : 1,
            }
          ],
          order: "id Desc",
        }
    }
    const resVid = await fetchGraphMethod(getAllVideoPhotoInfoQuery, finalObj, true)
    if(resVid?.status == 200){
      setAllVidPhoto(resVid?.data?.data?.allUploadedvideoandphotos?.Uploadedvideoandphotos?.length > 0 ?
          resVid?.data?.data?.allUploadedvideoandphotos?.Uploadedvideoandphotos :
          []
      )
    }else{
      // messagePopup("","All Video and Photo  Failed","error")
      toastr.error("All Video and Photo  Failed", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
    }
  }

  const getAllPressDetails = () => {
    props.allPressListStart({
      query: getAllPress,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
          order: "pressrank",//earlier it was id DESC
        },
      },
    });
  };

  const onClose = (e, data) => {
    setShowAddEditModal(!showAddEditModal);
  };

  const onDelete = async (press) => {
    // props.updateBasicPressListStart({
    //   query: deletePressItem,
    //   variables: {
    //     id: id
    //   },
    //   afterDeletePress:afterDeletePress
    // });
    if (press?.uploadedvideoandphotoPressidMap?.Uploadedvideoandphotos?.length > 0){
      const lastLength = press?.uploadedvideoandphotoPressidMap?.Uploadedvideoandphotos?.[press?.uploadedvideoandphotoPressidMap?.Uploadedvideoandphotos?.length - 1]
      let finalDelObj = {
        "deletedRank": lastLength?.uploadedrank ?? 0,
        "photosVideosAndLinkId": lastLength?.id ?? 0,
        "photosVideosAndLinkType": "0",
        "postId": 0,
        "postarray": [],
        "userId": getUserInfo()?.id ?? 0,
        "visibleId": 0,
        "isstats":1
      }
      const deleteVidPhotoStatus = await fetchMethod('/api/Uploadedvideoandphotos/removePhotosAndVideos',finalDelObj,'POST',true)
      if (deleteVidPhotoStatus?.status == 200){
        console.log("press vid/photo msg", deleteVidPhotoStatus?.data?.response?.msg ?? "Error Ocurred")
      }
    }
    const _r = await fetchGraphMethod(savePressUpdateAll(press?.id),"",true)
    if(_r?.status == 200){
         afterDeletePress()
    }else{
      // messagePopup("", "Press deleted Failed", "error");
      toastr.error("Press deleted Failed", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
    }
  };

  const afterDeletePress = () => {
    // messagePopup("", "Press deleted successfully", "success");
    toastr.success("Press deleted successfully", "", {
      progressBar : true,timeOut: 2000,
      closeButton: true, positionClass: "toast-top-full-width",
      showEasing: "swing", fadeIn: 40000,
      preventDuplicates:true,
    })
    getAllPressDetails();
    
  };
  const CheckName = (data) => {
    let flag = true;
    for (let i = 0; i < pressListData.length; i++) {
      if (pressListData[i].title.toLowerCase().trim() === data.title.toLowerCase().trim()) {
        if (data.id) {
          if (data.id !== pressListData[i].id) {
            flag = false;
            // messagePopup("", "Press Already Exist", "error");
            toastr.error("Press Already Exist", "", {
              progressBar : true,timeOut: 2000,
              closeButton: true, positionClass: "toast-top-full-width",
              showEasing: "swing", fadeIn: 40000,
              preventDuplicates:true,
            })
            getAllPressDetails();
          }
        } else {
          flag = false;
          // messagePopup("", "Press Already Exist", "error");
          toastr.error("Press Already Exist", "", {
            progressBar : true,timeOut: 2000,
            closeButton: true, positionClass: "toast-top-full-width",
            showEasing: "swing", fadeIn: 40000,
            preventDuplicates:true,
          })
          getAllPressDetails();
        }
      }
    }
    return flag;
  };

  const onSave = async (data, index = 0) => {
    let flag = CheckName(data);
    let originalName = data && data.filename && data.filename.split(".");
    let fileExtension = data?.fileExtension && !["pdf", "doc","docx"].includes(data.fileExtension);
    if (flag) {
      delete data.type;
      delete data.fileExtension;
      delete data?.photovideoid
      delete data?.uploadedvideoandphotoPressidMap
      data.active = 1;
      data.createdby = getUserInfo() ? getUserInfo().id : 0;
      data.createdon = new Date();
      data.updatedby = data.createdby;
      data.updatedon = new Date();
      data.userid = data.createdby;
      data.pressrank =
        pressListData[pressListData.length - 1]?.pressrank + 1 || 1;
      //earlier saga was used but in this we cannot be able to save the if in allvideophoto api
      // so that why we have used fetchmethord
      // props.updateBasicPressListStart({
      //   query: savePressCreate,
      //   variables: {
      //     obj: data,
      //   },
      //   handleSaveMessage: handleSaveMessage,
      //   onSavePressID: setOnSavePressID
      // });
      const resPress = await fetchGraphMethod(savePressCreate, {obj: data},true)
      if(resPress?.status == 200){
        handleSaveMessage()
        //this func is used earlier when  in  alluploaded video and photo table pressid is not there
        if (data?.filename){
          if(fileExtension){
            props.saveVideoPhotoStart({
              variables:{ userId: getUserInfo() ? getUserInfo().id : 0,
                photosAndVideosArray: [
                  {
                    name: data && data.filename,
                    type: data.type,
                    originalname: originalName[0],
                    pressid: resPress?.data?.data?.savePress?.id ?? 0,
                    type: 0,
                    videophotosuploadedtype: "Press",
                  },
                ],
                uploadedfrom: "stats", //this key is added as per change doc in sept task 15/9/2022
                isvisible: 1, //this key is added as per change doc in sept task 15/9/2022
              },
              nextScreen: () => {
                sendVideoPhotoNotification()
              }
            });
          }
        }
      }
      setShowAddEditModal(!showAddEditModal);
      setTimeout(function () {
        setPressData(undefined);
        getAllPressDetails();
        getAllVideoAndPhoto()
      }, 500);
    }
    dispatch({type:"RENDER_COUNT_START", payload: 1})
  };

  const handleSaveMessage = () => {
    // messagePopup("", "Press Links Added Successfully", "success");
    toastr.success("Press Links Added Successfully", "", {
      progressBar : true,timeOut: 2000,
      closeButton: true, positionClass: "toast-top-full-width",
      showEasing: "swing", fadeIn: 40000,
      preventDuplicates:true,
    })
  };

  const updateUserCollegeRank = async (data) => {
    try {
      const response = await fetchMethod(
        `/api/Usercolleges/changeRankOfCollege`,
        data,
        "post",
        true
      );
      if (response && response.status === 200) {
        if (
          response.data &&
          response.data.response &&
          response.data.response.status === 200
        ) {
        }
      } else {
        // messagePopup("", "Press Links Update Failed", "error");
        toastr.error("Press Links Update Failed", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates:true,
        })
        setPressData(props.pressList.data.parentData);
      }
    } catch (error) {
      // messagePopup("", "Press Links Update Failed", "error");
      toastr.error("Press Links Update Failed", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
      setPressData(props.pressList.data.parentData);
    }
  };

  const onSaveEdit = async (data, index = 0) => {
    let flag = CheckName(data);
    let originalName = data && data.filename && data.filename.split(".");
    let fileExtension = data?.fileExtension && !["pdf", "doc","docx"].includes(data.fileExtension);
    if(flag){
      delete data.type;
      delete data.fileExtension;
      delete data?.photovideoid;
      delete data?.uploadedvideoandphotoPressidMap;
      data.updatedby = getUserInfo() ? getUserInfo().id : 0;
      data.updatedon = new Date();
      delete data.userid;
      delete data.userId;
      delete data.selected;
      props.updateBasicPressListStart({
        query: savePressCreate,
        variables: {
          obj: data,
        },
        handleEditPressMessage: handleEditPressMessage,
      });
      if (data?.filename) {
        if(fileExtension){
          const vidID = vidPhotoID(data && data.id)
          if(!!vidID){
            const finalObj = {
              obj: {
                id: +vidID,
                originalname: originalName[0],
                photosname: !!data?.filename && data?.filename
              }
            }
            const resVid = await fetchGraphMethod(saveAllVidPhotoQuery, finalObj, true)
            if(resVid?.status == 200){
              console.log("edit photo press")
              getAllVideoAndPhoto()
            }else{
              console.log("edit save photo press failed")
            }
          }else{
            props.saveVideoPhotoStart({
              variables:{ userId: getUserInfo() ? getUserInfo().id : 0,
                photosAndVideosArray: [
                  {
                    id: data && data?.id,
                    name: data && data?.filename,
                    pressid: data?.id ?? 0,
                    type: !!data?.type ? data?.type : 0,
                    originalname: originalName[0],
                    videophotosuploadedtype: "Press",
                  },
                ],
                isvisible: 0,
              },
              nextScreen: () => {}
            });
          }
      }
      }
      setTimeout(function () {
        setPressData(undefined);
        getAllPressDetails();
      });
    }
    dispatch({type:"RENDER_COUNT_START", payload: 1})
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  const handleEditPressMessage = () => {
    // messagePopup("", "Press Links Updated Successfully", "success");
    toastr.success("Press Links Updated Successfully", "", {
      progressBar : true,timeOut: 2000,
      closeButton: true, positionClass: "toast-top-full-width",
      showEasing: "swing", fadeIn: 40000,
      preventDuplicates:true,
    })
  };

  const vidPhotoID = (value) =>{
    const filData = allViPhoto.filter(el => +el?.pressid == +value)
    if(filData.length > 0 ){
      return filData?.[0]?.id
    }else{
      return undefined
    }
  }

  return (
    <div className="pageContent">
       <BackdropLoader open={props.pressList.loading} />
      <div className="editProfile">
      <input className="fakeInput" type="text" ref={fakeInput} />
        <div className="container">
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
          >
            <SubHeader hamburger={onSideMenuClick} buttonHide={false}/>
            <div className="sidebar">
              <LeftSideEditProfile isSaved={true} />
            </div>
              <EditSection
                props={props}
                pressListData = {pressListData}
                onDragDrop={updateUserCollegeRank}
                onSaveEdit={onSaveEdit}
                onDelete={onDelete}
                onClose={onClose}
              />
          </div>
        </div>
        <GoTopButton goTop={goTop} />
      </div>
      <Dialog open={showAddEditModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setShowAddEditModal(false)
                }
              }}
              className="matDialogCustom"
      >
        <AddEditOverlay
          onAddEditClick={() => {
            setShowAddEditModal(true);
          }}
          onCloseAddEditClick={() => {
            setShowAddEditModal(false);
          }}
          onSave={onSave}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pressList: state.athlete.editUserProfileData.pressList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    allPressListStart: (data) => dispatch(allPressListStart(data)),
    updateBasicPressListStart: (data) =>
      dispatch(updateBasicPressListStart(data)),
    saveVideoPhotoStart: (data) => dispatch(saveVideoPhotoStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Press);
