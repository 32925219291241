import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    TRANSCRIPT_AND_ACADEMIC_FILE_LIST_START: 'TRANSCRIPT_AND_ACADEMIC_FILE_LIST_START',
    TRANSCRIPT_AND_ACADEMIC_FILE_SAVE_START: 'TRANSCRIPT_AND_ACADEMIC_FILE_SAVE_START',
    TRANSCRIPT_AND_ACADEMIC_FILE_SUCCESS: 'TRANSCRIPT_AND_ACADEMIC_FILE_SUCCESS',
    TRANSCRIPT_AND_ACADEMIC_FILE_FAILED: 'TRANSCRIPT_AND_ACADEMIC_FILE_FAILED'
}

// ============================================ Actions ========================================================
export const transcriptAndAcademicListStart = (data) => ({
    type: actionType.TRANSCRIPT_AND_ACADEMIC_FILE_LIST_START,
    payload: data
})

export const transcriptAndAcademicsaveStart = (data) => ({
    type: actionType.TRANSCRIPT_AND_ACADEMIC_FILE_SAVE_START,
    payload: data
})

export const transcriptAndAcademicSuccess = (data) => ({
    type: actionType.TRANSCRIPT_AND_ACADEMIC_FILE_SUCCESS,
    payload: data
})

export const transcriptAndAcademicFailed = (data) => ({
    type: actionType.TRANSCRIPT_AND_ACADEMIC_FILE_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function transcriptAndAcademicReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.TRANSCRIPT_AND_ACADEMIC_FILE_LIST_START:
            return {
                ...state,
                loading: true
            }
        case actionType.TRANSCRIPT_AND_ACADEMIC_FILE_SAVE_START:
            return {
                ...state,
                loading: true
            }
        case actionType.TRANSCRIPT_AND_ACADEMIC_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        case actionType.TRANSCRIPT_AND_ACADEMIC_FILE_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        default: return { ...state }
    }
}

// ===================================================== side effect =============================================

export function* transcriptAndAcademicsaveSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            
            yield put(transcriptAndAcademicSuccess(''));
            if (action.payload.refreshFun) {
                action.payload.refreshFun();
              }
              else if(action.payload.handleTranscript)
              {
                  action.payload.handleTranscript();
              }
        } else {
            messagePopup('', 'Transcript And Academic List failed', 'error')
            yield put(transcriptAndAcademicFailed('Transcript And Academic List failed'))
        }
    } catch (error) {
        messagePopup('', 'Transcript And Academic List failed', 'error')
        yield put(transcriptAndAcademicFailed('Transcript And Academic List failed'))
    }
}

export function* transcriptAndAcademiclistSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            let transcriptAndAcademicData = response.data.data.allTranscriptsandacadmicfiels
            yield put(transcriptAndAcademicSuccess(transcriptAndAcademicData));
        } else {
            messagePopup('', 'Transcript And Academic List failed', 'error')
            yield put(transcriptAndAcademicFailed('Transcript And Academic List failed'))
        }
    } catch (error) {
        messagePopup('', 'Transcript And Academic List failed', 'error')
        yield put(transcriptAndAcademicFailed('Transcript And Academic List List failed'))
    }
}


export function* watchTranscriptAndAcademicsaveSaga() {
    yield takeEvery(actionType.TRANSCRIPT_AND_ACADEMIC_FILE_LIST_START, transcriptAndAcademiclistSaga)
    yield takeEvery(actionType.TRANSCRIPT_AND_ACADEMIC_FILE_SAVE_START, transcriptAndAcademicsaveSaga)

}