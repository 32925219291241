import React, { useState, useEffect, useRef } from "react";
import "../accountSetting/assets/css/account.scss";
import { Dialog } from "@mui/material";
import AddPaymentIcon from "./assets/img/plus-blue-icon.png";
import PaymentIcon from "./assets/img/payments-icon.png";
import CvvIcon from "./assets/img/cvv.png";
import { connect } from "react-redux";
import VisaIcon from "./assets/img/visa-icon.png";
import americanExpressIcon from "./assets/img/american-express-icon.png";
import { IsValidName } from "../../../../../../utils/validationUtils";
import { getUserInfo } from "../../../../../../services/authService";
import messagePopup from "../../../../../../utils/messagePopup";
import warningMessagePopup from "../../../../../../utils/warningMagPop";
import { deleteCardQuery } from "./PaymentInfoQuery";
import { imageDownloadUrl } from "../../../../../../config";
// import {
//   paymentCardListStart,
//   addNewCardStart,
// } from "../../../../../../redux/athlete/dashboard/upgradePlans/PaymentCardList";
import {
  getPaymentCartListQuery,
  saveNewCardDetails,
} from "../../upgradePlans/UpgradePlansQuery";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { addNewCardStart } from "../../../../../../redux/athlete/dashboard/payment/savePaymentCard";
import { paymentCardListStart } from "../../../../../../redux/athlete/dashboard/payment/paymentCardsList";
import BackdropLoader from "../../../../../common/Loader";
import toastr from "toastr";

const PaymentCards = ({
  cardListData,
  saveNewCardPaymentDetails,
  onAddPaymentClick,
  singleCardValue,
  getAllPaymentCards,
  loading,
}) => {
  const handleEdit = (e, data) => {
    e.preventDefault();
    onAddPaymentClick();
    singleCardValue(data);
  };

  const removeCardDetail = async (e, cardId) => {
    e.preventDefault();
    saveNewCardPaymentDetails({
      query: deleteCardQuery,
      variables: {
        id: +cardId,
      },
      refreshFun: afterDeleteCard,
    });
  };

  const handleDeleteCard = async (e, cardId) => {
    e.preventDefault();
    const confirmation = warningMessagePopup(
      "",
      "Are you sure you want to delete your Card Details ?",
      "warning"
    );
    confirmation.then(async (value) => {
      if (value == true) {
        removeCardDetail(e, cardId);
      }
    });
  };

  const afterDeleteCard = () => {
    // messagePopup("", "Your card has been successfully deleted", "success");
    toastr.success("Your card has been successfully deleted", "", {
      progressBar : true,timeOut: 2000,
      closeButton: true, positionClass: "toast-top-full-width",
      showEasing: "swing", fadeIn: 40000,
      preventDuplicates: true,
    })
    getAllPaymentCards();
  };


  return (
    <>
      {cardListData.length ? (
        cardListData.map((item, index) => {
          return (
            <div className="item" key={index}>
              {index === 0 ? <BackdropLoader open={loading} /> : null}
              <div className="payment-info-box">
                <div className="detial-box">
                  <div className="payment-icon">
                    {item.carddetailscardtypemaprel.Cardtypes
                      ? item.carddetailscardtypemaprel.Cardtypes.map((card,idx) => (
                          <img key={idx} src={`${imageDownloadUrl}/${card.cardimage}`} />
                        ))
                      : ""}
                    {/* <img
                      src={item.cardtype == 1 ? VisaIcon : americanExpressIcon}
                      alt="Visa"
                    /> */}
                  </div>
                  <p>
                    <span>****{item.cardnumber.toString().slice(8)}</span>
                    <span>Expires {item.expiryyear}</span>
                  </p>
                  <h4>{item.cardholdername}</h4>
                </div>
                <div className="link-box">
                  <a
                    href={void 0}
                    onClick={(e) => {
                      handleEdit(e, item);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Edit
                  </a>
                  <a
                    href={void 0}
                    onClick={(e) => {
                      handleDeleteCard(e, item.id);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Remove
                  </a>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div>
          {" "}
          <p>No card saved, Please add new card for payment.</p>
        </div>
      )}
    </>
  );
};

const AddEditPaymentModal = (props) => {
  const [addCardFields, setAddCardFields] = useState({
    cardId: props.singleCard ? props.singleCard.id : "",
    cardHolderName: props.singleCard.cardholdername
      ? props.singleCard.cardholdername
      : "",
    cardNumber: props.singleCard.cardnumber ? props.singleCard.cardnumber : "",
    selectedCardType: props.singleCard.cardtype
      ? props.singleCard.cardtype
      : "",
    selectedMonth: props.singleCard.expirymonth
      ? props.singleCard.expirymonth
      : "",
    selectedYear: props.singleCard.expiryyear
      ? props.singleCard.expiryyear
      : "",
    securityNumber: "",
  });

  const [cardError, setCardError] = useState({
    cardId: "",
    cardHolderName: "",
    cardNumber: "",
    selectedCardType: "",
    selectedMonth: "",
    selectedYear: "",
    securityNumber: "",
  });
  const {
    meta,
    getCardNumberProps,
    getCardImageProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const handleValidation = (fieldname, e) => {
    let errorsT = cardError;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    //card holder name validations
    if (fieldname === undefined || fieldname === "cardHolderName") {
      if (fieldname === undefined) {
        fieldValue = addCardFields["cardHolderName"]
          ? addCardFields["cardHolderName"]
          : "";
        updatedValue = fieldValue;
      }
      delete errorsT["cardHolderName"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["cardHolderName"] = "Name is required";
      } else if (!IsValidName(updatedValue)) {
        formIsValid = false;
        errorsT["cardHolderName"] = "Please enter a valid name";
      }
    }
    //card number validation

    if (fieldname === undefined || fieldname === "cardNumber") {
      if (fieldname === undefined) {
        fieldValue = addCardFields["cardNumber"]
          ? addCardFields["cardNumber"]
          : "";
        updatedValue = fieldValue;
      }
      delete errorsT["cardNumber"];
      if (meta.erroredInputs && meta.erroredInputs.cardNumber) {
        if (meta.erroredInputs.cardNumber === "Enter a card number") {
          formIsValid = false;
          errorsT["cardNumber"] = "Card number is required.";
        } else {
          formIsValid = false;
          errorsT["cardNumber"] = meta.erroredInputs.cardNumber;
        }
      }
      //now all card will be accept this below code is for check over jcb card
        // if (meta.cardType) {
        //   if (meta.cardType.type == "jcb") {
        //     formIsValid = false;
        //     errorsT["cardNumber"] = "Card is Invalid";
        //   }
        // }
    }

    if (fieldname === undefined || fieldname === "selectedYear") {
      if (fieldname === undefined) {
        fieldValue = addCardFields["selectedYear"]
          ? addCardFields["selectedYear"]
          : "";
        updatedValue = fieldValue;
      }
      delete errorsT["selectedYear"];
      if (meta.erroredInputs && meta.erroredInputs.expiryDate) {
        if (meta.erroredInputs.expiryDate === "Enter an expiry date") {
          formIsValid = false;
          errorsT["selectedYear"] = "Expiry Date is required";
        } else {
          formIsValid = false;
          errorsT["selectedYear"] = "Expiry date is invalid";
          // meta.erroredInputs.expiryDate;
        }
      }
    }

    setCardError({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const setCardType = () => {
    let fieldsT = addCardFields;
    if (meta.cardType) {
      switch (meta.cardType.type) {
        case "visa":
          fieldsT["selectedCardType"] = 1;
          break;
        case "mastercard":
          fieldsT["selectedCardType"] = 2;
          break;
        case "amex":
          fieldsT["selectedCardType"] = 3;
          break;
        case "discover":
          fieldsT["selectedCardType"] = 4;
          break;
        case "secure":
          fieldsT["selectedCardType"] = 5;
          break;
        case "maestro":
          fieldsT["selectedCardType"] = 6;
          break;
        default:
          fieldsT["selectedCardType"] = 1;
      }
    } else {
      fieldsT["selectedCardType"] = null;
    }
    setAddCardFields({ ...fieldsT });
  };

  const submitCardDetails = async (event) => {
    event.preventDefault();
    let duplicateCard = true,
        isEdit = !!addCardFields?.cardId ? true : false
    if(isEdit){
      let data = props?.cardListData?.length > 0 && props?.cardListData?.filter(crd => crd?.id ==  addCardFields?.cardId)
      if(data[0]?.cardnumber == addCardFields.cardNumber){
        duplicateCard = true
      }else{
      let  isFilCard =  props?.cardListData?.length > 0 && props?.cardListData?.filter(crd => crd?.cardnumber ==  addCardFields.cardNumber)
        if(isFilCard?.length > 0){
          duplicateCard = false
        }else{
          duplicateCard = true
        }
      }
    }else{
      let filCard =  props?.cardListData?.length > 0 && props?.cardListData?.filter(crd => crd?.cardnumber ==  addCardFields.cardNumber)
      if(filCard?.length > 0){
        duplicateCard = false
      }else{
        duplicateCard = true
      }
    }
    const validateData = await handleValidation();
    if(duplicateCard){
    if (validateData.formIsValid == true) {
      if (
        addCardFields.cardNumber != "" ||
        addCardFields.cardHolderName != "" ||
        addCardFields.selectedCardType != "" ||
        addCardFields.selectedYear != "" ||
        addCardFields.selectedMonth != ""
      ) {
        props.saveNewCardPaymentDetails({
          query: saveNewCardDetails,
          variables:
            addCardFields.cardId === ""
              ? {
                  obj: {
                    userid: getUserInfo() ? getUserInfo().id : "",
                    cardholdername: addCardFields.cardHolderName
                      ? addCardFields.cardHolderName
                      : "",
                    cardtype: addCardFields.selectedCardType
                      ? +addCardFields.selectedCardType
                      : null,
                    expiryyear: addCardFields.selectedYear
                      ? addCardFields.selectedYear
                      : "",
                    expirymonth: addCardFields.selectedMonth
                      ? addCardFields.selectedMonth
                      : "",
                    cardnumber: addCardFields.cardNumber
                      ? addCardFields.cardNumber
                      : "",
                    addcarforfuturerefrence: 1,
                  },
                }
              : {
                  obj: {
                    userid: getUserInfo() ? getUserInfo().id : "",
                    id: addCardFields.cardId,
                    cardholdername: addCardFields.cardHolderName
                      ? addCardFields.cardHolderName
                      : "",
                    cardtype: addCardFields.selectedCardType
                      ? +addCardFields.selectedCardType
                      : null,
                    expiryyear: addCardFields.selectedYear
                      ? addCardFields.selectedYear
                      : "",
                    expirymonth: addCardFields.selectedMonth
                      ? addCardFields.selectedMonth
                      : "",
                    cardnumber: addCardFields.cardNumber
                      ? addCardFields.cardNumber
                      : "",
                    addcarforfuturerefrence: 1,
                  },
                },
          refreshFun: () => {
            handleMessage();
            initialStats();
          },
        });
      } else {
        initialStats();
      }
    }
  }
  else{
    // messagePopup("", "Card number already added", "warning");
    toastr.warning("Card number already added", "", {
      progressBar : true,timeOut: 2000,
      closeButton: true, positionClass: "toast-top-full-width",
      showEasing: "swing", fadeIn: 40000,
      preventDuplicates: true,
    })
  }
  };

  const initialStats = () => {
    props.onCloseModal();
    props.getAllPaymentCards();
    setAddCardFields({
      ...addCardFields,
      cardHolderName: "",
      cardNumber: "",
      selectedCardType: "",
      selectedMonth: "",
      selectedYear: "",
    });
  };

  const handleMessage = () => {
    if (props.singleCard.id) {
      // messagePopup("", "Card Updated Successfully", "success");
      toastr.success("Card Updated Successfully", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    } else {
      // messagePopup("", "Card Added Successfully", "success");
      toastr.success("Card Added Successfully", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }
  };

  const inputHandler = async (field, e) => {
    setCardError({});
    let fieldsT = addCardFields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    await setAddCardFields({ ...fieldsT });
    setCardType();
  };

  const handleClosePopup = () => {
    props.onCloseModal();
    setAddCardFields({
      ...addCardFields,
      cardHolderName: "",
      cardNumber: "",
      selectedCardType: "",
      selectedMonth: "",
      selectedYear: "",
    });
  };

  return (
    <div
      className="modal fade account-common-modal add-payment-modal show"
      id="AddPaymentDetailModal"
      style={{ display: "block" }}
    >
      <BackdropLoader open={props.loading} />
      <div
        className="modal-dialog modal-dialog-centered editprofile-common-form"
        role="document"
      >
        <form className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Payment Details</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                e.preventDefault();
                handleClosePopup();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body bottom-border">
            <div className="payment-icons">
              <img src={PaymentIcon} alt="" />
            </div>
            <div className="fieldBox">
              <svg {...getCardImageProps({ images })} />
              <label>Card Number <sup>*</sup></label>
              <input
                {...getCardNumberProps({
                  onChange: (e) => {
                    inputHandler("cardNumber", e);
                  },
                })}
                type="text"
                value={addCardFields.cardNumber}
              />

              <div style={{ color: "red", fontSize: "14px" }}>
                {cardError && cardError.cardNumber ? cardError.cardNumber : ""}
              </div>
            </div>

            <div className="fieldBox flexBox">
              <div className="half">
                <label>Expiry Date <sup>*</sup></label>
                <input
                  {...getExpiryDateProps({
                    onChange: (e) => inputHandler("selectedYear", e),
                  })}
                  value={addCardFields.selectedYear}
                />
                <div style={{ color: "red", fontSize: "14px" }}>
                  {cardError && cardError.selectedYear
                    ? cardError.selectedYear
                    : ""}
                </div>
              </div>
              <div className="half">
                <label>Security Number</label>
                <div className="cvv-flexbox">
                  <div className="cvv-input-box">
                    <input
                      {...getCVCProps({
                        onChange: (e) => inputHandler("securityNumber", e),
                      })}
                      value={addCardFields.securityNumber}
                    />
                  </div>
                  <div className="cvv-icon">
                    <img src={CvvIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="fieldBox">
              <label>Name on Card <sup>*</sup></label>
              <input
                type="text"
                value={addCardFields.cardHolderName || ""}
                onChange={(e) => inputHandler("cardHolderName", e)}
              />
              <div style={{ color: "red", fontSize: "14px" }}>
                {cardError && cardError.cardHolderName
                  ? cardError.cardHolderName
                  : ""}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {props.singleCard && props.singleCard.id ? (
              <button
                onClick={(e) => submitCardDetails(e)}
                type="button"
                className="btn green"
                data-dismiss="modal"
              >
                Update Details
              </button>
            ) : (
              <button
                onClick={(e) => submitCardDetails(e)}
                type="button"
                className="btn green"
                data-dismiss="modal"
              >
                Add Details
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
const PaymentInfo = (props) => {
  const fakeInput = useRef(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [singleCardData, setSingleCardData] = useState([]);
  const [cardListData, setCardListData] = useState(null);
  useEffect(() => {
    getAllPaymentCards();
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);
  const onAddPaymentClick = () => {
    setSingleCardData([])
    setShowPaymentModal(!showPaymentModal);
  };
  const onCloseModal = () => {
    setShowPaymentModal(false);
  };
  const getSingleRowData = (singleCardValue) => {
    setSingleCardData(singleCardValue == undefined ? [] : singleCardValue);
  };

  const getAllPaymentCards = () => {
    props.paymentCardListStart({
      query: getPaymentCartListQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  };

  useEffect(() => {
    if (props.paymentCardList.data) {
      setCardListData(props.paymentCardList.data);
    }
  }, [props.paymentCardList.data]);

  return (
    <div>
      <BackdropLoader open={props.paymentCardList.loading} />
      <input className="fakeInput" type="text" ref={fakeInput} />
      <section className="account-main-sec">
        <div className="container">
          <div className="account-with-sidebar">
            <div className="account-data-box payment">
              <h1 className="title">Payment Settings</h1>
              <div className="payment-detial-box">
                <h3>Payment Method</h3>
                <div className="payment-listing">
                  {cardListData ? (
                    <PaymentCards
                      onAddPaymentClick={onAddPaymentClick}
                      cardListData={cardListData}
                      singleCardValue={getSingleRowData}
                      getAllPaymentCards={getAllPaymentCards}
                      saveNewCardPaymentDetails={
                        props.saveNewCardPaymentDetails
                      }
                      loading={props.newCardsDetails.loading}
                    />
                  ) : null}

                  <div className="item add-new-payment">
                    <a
                      className="addnew-paymentMethod"
                      href={void 0}
                      data-toggle="modal"
                      data-target="#AddPaymentDetailModal"
                      onClick={onAddPaymentClick}
                    >
                      <img src={AddPaymentIcon} alt="" />
                      Add Payment Method
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Dialog open={showPaymentModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onCloseModal()
                }
              }}
              className="matDialogCustom"
      >
        <AddEditPaymentModal
          onCloseModal={onCloseModal}
          key={"add"}
          cardListData={cardListData}
          // onAddPaymentClick={onAddPaymentClick}
          saveNewCardPaymentDetails={props.saveNewCardPaymentDetails}
          getAllPaymentCards={getAllPaymentCards}
          singleCard={singleCardData}
          loading={props.newCardsDetails.loading}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentCardList: state.athlete.dashboard.payment.paymentCardList,
    newCardsDetails: state.athlete.dashboard.payment.newCard,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paymentCardListStart: (data) => dispatch(paymentCardListStart(data)),
    saveNewCardPaymentDetails: (data) => dispatch(addNewCardStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfo);
