import React from "react";
import { Link } from "react-router-dom";
// import "../assets/css/claimProfileStep2Footer.scss";
import "../assets/css/claimProfileStep2Header.scss";
const ClaimProfileStep2Footer = () => {
  return (
    <>
      <footer className="claimProfileStep2Footer">
        <div>
          <nav className="navBar">
            <ul className="lists">
              <li>
                <Link to="#">Help</Link>
              </li>
              <li>
                <Link to="#">Support</Link>
              </li>
              <li>
                <Link to="#">FAQs</Link>
              </li>
              <li>
                <Link to="#">Legal</Link>
              </li>
              <li>
                <Link to="#">Disclaimer</Link>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </>
  );
};

export default ClaimProfileStep2Footer;