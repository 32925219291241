import React from 'react'

function ClaimProfileFooterCoach() {
    return (
        <div>
            <section className="footerSection">
        <ul>
            <li>
                <a href={void 0}>Help</a>
            </li>
            <li>
                <a href={void 0}>Support</a>
            </li>
            <li>
                <a href={void 0}>FAQs</a>
            </li>
            <li>
                <a href={void 0}>Legal</a>
            </li>
            <li>
                <a href={void 0}>Disclaimer</a>
            </li>
        </ul>
    </section>
        </div>
    )
}

export default ClaimProfileFooterCoach
