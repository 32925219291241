import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";


// ========================================== Action Type =====================================================
export const actionType = {
  DELETE_SCHOOL_TEAM_START: "DELETE_SCHOOL_TEAM_START",
  DELETE_SCHOOL_TEAM_SUCCESS: "DELETE_SCHOOL_TEAM_SUCCESS",
  DELETE_SCHOOL_TEAM_FAILED: "DELETE_SCHOOL_TEAM_FAILED",
};

// ============================================ Actions ========================================================

export const deleteSchoolTeamStart = (data) => ({
  type: actionType.DELETE_SCHOOL_TEAM_START,
  payload: data,
});

export const deleteSchoolTeamSuccess = (data) => ({
  type: actionType.DELETE_SCHOOL_TEAM_SUCCESS,
  payload: data,
});

export const deleteSchoolTeamFailed = (data) => ({
  type: actionType.DELETE_SCHOOL_TEAM_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function deleteSchoolTeamReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.DELETE_SCHOOL_TEAM_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.DELETE_SCHOOL_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.DELETE_SCHOOL_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* deleteSchoolTeamSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Teams/deleteTeamAndSchool`,
      action.payload.data,
      "post",
      true
    );
    if (response && response.status === 200) {
      messagePopup("", action.payload.successMsg, "success");
      yield put(deleteSchoolTeamSuccess(action.payload.successMsg));
      if (action.payload.refreshPage) action.payload.refreshPage();
    } else {
      messagePopup("", action.payload.failureMsg, "error");
      yield put(deleteSchoolTeamFailed(action.payload.failureMsg));
    }
  } catch (error) {
    messagePopup("", action.payload.failureMsg, "error");
    yield put(deleteSchoolTeamFailed(action.payload.failureMsg));
  }
}

export function* watchSchoolTeamSaga() {
  yield takeEvery(actionType.DELETE_SCHOOL_TEAM_START, deleteSchoolTeamSaga);
}
