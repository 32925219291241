import React, {useEffect, useRef, useState} from "react";
import {
  getAuthToken,
  getUserInfo,
} from "../../../../../../services/authService";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { imageDownloadUrl } from "../../../../../../config";
import {
  postDateFormat,
  postTimeFormat,
  timeFormatter,
  getUTCTimeDateDash
} from "../../../../../../utils/dateTimeFormatter";
import userImg from "./../assets/img/user.svg";
import globe   from './../assets/img/globe.svg'
import Map from "../../colleges/Map";
import warningMessagePopup from "../../../../../../utils/warningMagPop";
import messagePopup from "../../../../../../utils/messagePopup";
import toastr from "toastr";
import moment from "moment";
import {VideoPop} from "../../../../landingPage/common/videoPopup";
import thumbnail from '../../../../../../assets/img/video-to-link-thumbnail.jpg'
import {getThumbnail, typeConvert} from '../../../../../common/thumbnail'
import { fetchGraphMethod } from "../../../../../../services/httpService";
import { saveViewCollegeQuery } from "../../colleges/searchColleges/SearchCollegesQuery";
import {userProfileApi, vidCountIncre} from "../../../../../common/userViewCount";
import {Swiper, SwiperSlide} from 'swiper/react'
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from "swiper";
SwiperCore.use([Navigation, Pagination]);
export default function PostCard(props) {
  const [showAction, setShowAction] = React.useState(false);
  const [vidID, setVidID] = React.useState(null)
  const [isVidPop, setIsVidPop] = React.useState(false)
  const [isLink, setIsLink] = useState(false)
  const domRef = useRef()

  useEffect(()=>{
    var handler = event => {
      if (!domRef?.current?.contains(event?.target)) {
        setShowAction(false)
      }
    }
    document?.addEventListener("mousedown", handler)
  },[])
  const handleShowAction = (e) => {
    e.preventDefault();
    setShowAction(!showAction);
  };
  const handleRemovePost = (e, post) => {
    e.preventDefault();
    const confirmation = warningMessagePopup(
      "",
      "Are you sure you want to delete this post?",
      "warning"
    );
    confirmation.then((value) => {
      if (value == true) {
        setShowAction(false);
        props.handleRemovePost(post, props?.page);
        // messagePopup("", "Your post is successfully deleted.", "success");
        toastr.success("Your post is successfully deleted.", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      }
    });
  };
  const handlePinPost = (e, post) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAction(false);
    if (post.pinedStatus) {
      props.handleUnpinPost(post);
    } else {
      props.handlePinPost(post);
    }
  };

  const MapWrapper = (lat, long) => {
    return (
      <>
        {lat ? (
          <Map
            markers={[
              { latitude: lat ? +lat : null, longitude: long ? +long : null },
            ]}
          />
        ) : null}
      </>
    );
  };

  const getInitials = (name) => {
    let initial = "";
    initial = name.msgSendByFirstName[0].concat(
      name?.msgSendByLastName ? name?.msgSendByLastName[0] : ""
    );
    return initial;
  };
  const openVidPop = (url, linkType, number, data) =>{
    // alert(number)
    setVidID(url)
    setIsLink(linkType)
    setTimeout(()=>{
      setIsVidPop(true)
    },500)
    if (!!data?.photovideoid){
      const finalObj = {
        id: data?.photovideoid ?? 0,
        userid: props?.post?.userId ?? 0,
        data
      }
      vidCountIncre(finalObj, true)
    }
  }

  const handleCollegeClick = async (collgId) => {
    {/*last moment check over clg over token */}
    if (!!getAuthToken()){
      let variable = {
        obj : {
          athleteid : getUserInfo() ? getUserInfo().id : null,
          collegeid : collgId,
          collegeviewstatus : 1,
        }
      }
      const saveViewCount = await fetchGraphMethod(saveViewCollegeQuery, variable, true);
    }
  }
  const getDisplayStatus = (value) =>{
    let status = []
    if(value?.length > 0){
      value?.map((el, idx)=>{
        if ((el?.typeofpost == 1) || (el?.typeofpost == 2)){
          status?.push(idx)
        }
      })
    }else{
      status = []
    }
    return status?.length > 0 ? true : false
  }
  const openProfile = (data) =>{
    if (props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global"){
      if (getUserInfo()?.id != data?.athleteid){
        userProfileApi(
            {
              obj: {
                userrole: "ATHLETES",
                profileviewedby: getUserInfo()?.id ?? 0,
                profileviewto: +data?.athleteid || 0,
              },
            },
            true,
            false
        );
      }
      sessionStorage.setItem('athlete_own_profile', false)
      setTimeout(()=>{
        window.open(`/athlete/${data?.athleteFirstName?.trim()?.toLowerCase()?.toString()?.split(" ")?.join("-")}-${data?.athleteLastName?.trim()?.toLowerCase()?.toString()?.split(" ")?.join("-")}-${data?.sysGenId}`, "_blank",)
      },500)
    }
  }
  return (
    <>
      {props.post && props.post.postType === "post" ? (
        <div className="item">
          <div className="imgDataFlexBox">
            <div className="imgBox"
                 style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ?
                     {cursor:"pointer"} : {}}
                 onClick={()=>openProfile(props?.post, 'post')}
            >
              {!!props.post.profileImages ? (
                <img
                  src={
                    props.post.profileImages
                      ? props.post.profileImages
                      : userImg
                  }
                  alt="playerPic"
                  className={!props.post.profileImages ? "defaultUserImg" : ""}
                />
              ) : (
                <p>
                  {!!props?.post?.athleteFirstName
                    ? props?.post?.athleteFirstName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                  {!!props?.post?.athleteLastName
                    ? props?.post?.athleteLastName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                </p>
              )}
            </div>
            <div className="dataFlexBox">
              <div>
                <h5 className={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global")
                    ? "bluehighLight" : ""} style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ? {cursor:"pointer", color:"#007bff"} : {}}
                onClick={()=>openProfile(props?.post, 'post')}
                >
                  {props.post.athleteFirstName +
                    " " +
                    props.post.athleteLastName}
                </h5>
                <span>
                  {props.post
                    ? props.post.userActivityUpdatedOn
                      ? getUTCTimeDateDash(props.post.userActivityUpdatedOn) +
                        " at " +
                        timeFormatter(props.post.userActivityUpdatedOn)
                      : ""
                    : ""}
                  <span style={{marginLeft:"5px"}}>{props?.post?.ispublic == 1
                      ? <img style={{height:"12px"}} src={globe} alt={"globe"}/> : ""}
                  </span>
                </span>
              </div>
              {props.tab === "myFeed" && !props?.isTimelineValue ? (
                <div className="action">
                  {props.post && props.post.pinedStatus ? (
                    <a
                      className={
                        props.isTimeline ? "timelinePinIcon" : "pinIcon"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <path
                          className="a"
                          d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                          transform="translate(0 0)"
                        />
                      </svg>
                    </a>
                  ) : null}
                  {!props.isTimeline == false ? (
                    ""
                  ) : (
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      onClick={(e) => {
                        if (!props.isTimeline) handleShowAction(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <g transform="translate(-380 -745)">
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 745)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 751)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 757)"
                          />
                        </g>
                      </svg>
                    </button>
                  )}
                  <div
                    ref={domRef}
                    className={`dropdown-menu ${showAction ? "show " : ""}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                      className="dropdown-item deleteLink"
                      to={props.isTimeline ? "" : "#"}
                      onClick={(e) => handleRemovePost(e, props.post)}
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.11"
                          height="15.511"
                          viewBox="0 0 14.11 15.511"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <g transform="translate(-3.75 -2.25)">
                            <path
                              className="a"
                              d="M4.5,9H17.11"
                              transform="translate(0 -3.198)"
                            />
                            <path
                              className="a"
                              d="M17.308,5.8V15.61a1.4,1.4,0,0,1-1.4,1.4H8.9a1.4,1.4,0,0,1-1.4-1.4V5.8m2.1,0V4.4A1.4,1.4,0,0,1,11,3h2.8a1.4,1.4,0,0,1,1.4,1.4V5.8"
                              transform="translate(-1.599)"
                            />
                          </g>
                        </svg>
                      </span>
                      <span>Delete</span>{" "}
                    </Link>
                    {!props.containPin || props.post.pinedStatus ? (
                      <Link
                        className="dropdown-item"
                        to={props.isTimeline ? "" : "#"}
                        onClick={(e) => handlePinPost(e, props.post)}
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                          >
                            <defs>
                              <style></style>
                            </defs>
                            <path
                              className="a"
                              d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                              transform="translate(0 0)"
                            />
                          </svg>
                        </span>
                        <span>
                          {props.post.pinedStatus
                            ? "Unpin to profile"
                            : "Pin to profile"}
                        </span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {props.post && props.post.postdescription && (
            <p style={{
              justifyContent: "center"
              // lineBreak: "anywhere"
            }} className="description">
              {props.post.postdescription}
            </p>
          )}
          {props.post && props.post.lat && (
            <div className="description">
              {MapWrapper(props.post.lat, props.post.lng)}
            </div>
          )}
          {props.post &&
          props.post.allVideoAudioPost &&
          props.post.allVideoAudioPost.length ? (
            <div className="videoSection">
              <div className="video">
                {props.post.allVideoAudioPost.map((item, index) => (
                  <div style={{ marginBottom: "10px", lineBreak: "anywhere" }} key={index}>
                    {item.typeofpost === 3 && (
                      <a href={item.postimagevideoandlinkurl} target="_blank">
                        {item.postimagevideoandlinkurl}
                      </a>
                    )}
                    {item.typeofpost === 4 && (
                      <a
                      onClick={()=>openVidPop(item.postimagevideoandlinkurl, true, 1, item)}
                          href={void 0}
                          // href={item.postimagevideoandlinkurl}
                          target="_blank"
                      >
                        <video
                        playsInline={true}
                        preload={"auto"}
                        type="video/mp4"
                        id={"postVideo"}
                        poster={ getThumbnail(item.postimagevideoandlinkurl, item, props?.page) }
                            // controls
                        // controlsList={props?.isAuth == 1 ? "" : "nodownload"}
                        >
                          <source
                            src={
                              item.postimagevideoandlinkurl
                                ? item.postimagevideoandlinkurl
                                : null
                            }
                            alt="video"
                          />
                        </video>
                        <span className="video-play-button">
                          <span className="video-play-inner">
                            <button>
                              <svg xmlns="http://www.w3.org/2000/svg" width="10.802" height="16.203"
                                   viewBox="0 0 10.802 16.203">
                                <defs></defs>
                                <path className="a" d="M8.1,0l8.1,10.8H0Z"
                                      transform="translate(10.802) rotate(90)"></path>
                              </svg>
                            </button>
                          </span>
                        </span>
                      </a>
                    )}
                  </div>
                ))}
                <div style={getDisplayStatus(props?.post?.allVideoAudioPost) ? {display:"block"} : {display:"none"}}>
                <Swiper
                    slidesPerView={1}
                    autoHeight={true}
                    effect={'coverflow'}
                    navigation
                >
                  {React.Children.toArray(
                    props.post.allVideoAudioPost.map((item) => (
                      <>
                        {item.typeofpost === 1 && (
                            <SwiperSlide>
                          <div className="videoinnerWrap">
                            <img
                            src={
                              item.postimagevideoandlinkurl
                                ? `${imageDownloadUrl}/${item.postimagevideoandlinkurl}`
                                : null
                            }
                            alt="image"
                            />
                          </div>
                            </SwiperSlide>
                        )}
                        {item.typeofpost === 2 && (
                            <>
                              <SwiperSlide>
                            <div className="videoinnerWrap">
                          <video
                              playsInline={true}
                              preload={"auto"}
                              type="video/mp4"
                              id={"postVideo"}
                              // controls
                          // controlsList={props?.isAuth == 1 ? "" : "nodownload"}
                          >
                            <source
                              src={
                                item.postimagevideoandlinkurl
                                  ? `${imageDownloadUrl}/${typeConvert(item.postimagevideoandlinkurl, props?.page)}`+ "#t=0.3"
                                  : null
                              }
                              alt="video"
                            />
                          </video>
                          
                              <span className="video-play-button"
                              onClick={()=>openVidPop(`${imageDownloadUrl}/${item.postimagevideoandlinkurl}`, false, 2, item)}
                              >
                          <span className="video-play-inner">
                            <button>
                              <svg xmlns="http://www.w3.org/2000/svg" width="10.802" height="16.203"
                                   viewBox="0 0 10.802 16.203">
                                <defs></defs>
                                <path className="a" d="M8.1,0l8.1,10.8H0Z"
                                      transform="translate(10.802) rotate(90)"></path>
                              </svg>
                            </button>
                          </span>
                        </span>
                        </div>
                              </SwiperSlide>
                            </>
                        )}
                      </>
                    ))
                  )}
                </Swiper>
                </div>
              </div>
            </div>
          ) : null}
          {!!getAuthToken() && (
            <div className="shareLinkBox">
              {props.post.isPostLiked ? (
                <>
                  <Link
                    to={props.isTimeline ? "" : "#"}
                    className="fireRed"
                    onClick={(e) => {
                      //if (!props.isTimeline) props.handleUnlike(e, props.post);
                      props.handleUnlike(e, props.post);
                    }}
                  >
                    <span>{props.post.totalLikeCount}</span>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to={props.isTimeline ? "" : "#"}
                    className="fireGray"
                    onClick={(e) => {
                      //if (!props.isTimeline) props.handleLike(e, props.post);
                      if(props.isTimeline){
                        let postData = props.post;
                        delete postData['userId'];
                        props.handleLike(e, postData);
                      }
                      else{
                        props.handleLike(e, props.post);
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.713"
                      height="17.03"
                      viewBox="0 0 12.713 17.03"
                    >
                      <g transform="translate(-10.806 -1.738)">
                        <path
                          className="a"
                          d="M17.552,18.518a5.306,5.306,0,0,1-1.831-.319h0a6.658,6.658,0,0,1-4.171-3.61,7.556,7.556,0,0,1,.26-5.561.236.236,0,0,1,.44.038,3.108,3.108,0,0,0,.967,1.377,7.765,7.765,0,0,1,3.009-4.423c1.526-1.221.28-3.648.267-3.672a.236.236,0,0,1,.357-.293c4.2,3.409,3.96,6.843,3.621,8.254a2.289,2.289,0,0,0,.856-2.117.236.236,0,0,1,.429-.165,10.69,10.69,0,0,1,1.1,2.27,6.448,6.448,0,0,1-1.107,6.221l-.05.062A5.356,5.356,0,0,1,17.552,18.518Zm-1.669-.762a4.835,4.835,0,0,0,1.669.29,4.886,4.886,0,0,0,3.784-1.766l.047-.059a5.979,5.979,0,0,0,1.029-5.767A10.7,10.7,0,0,0,21.778,9a3.174,3.174,0,0,1-1.634,2.088.236.236,0,0,1-.314-.314c.075-.16,1.7-3.8-2.576-7.748A3.051,3.051,0,0,1,16.505,6.4a7.274,7.274,0,0,0-2.887,4.506.236.236,0,0,1-.367.165,4.379,4.379,0,0,1-1.22-1.316,6.869,6.869,0,0,0-.044,4.658,6.211,6.211,0,0,0,3.9,3.342Z"
                          transform="translate(0)"
                        />
                        <path
                          className="a"
                          d="M28.428,41.068a.236.236,0,0,1-.152-.055A4.4,4.4,0,0,1,27.41,35.2a.236.236,0,0,1,.432.117,1.582,1.582,0,0,0,.368.989,4.8,4.8,0,0,1,2.08-4.636.236.236,0,0,1,.328.312,1.638,1.638,0,0,0-.19.8,1.732,1.732,0,0,0,.3.905l.094.144a14.793,14.793,0,0,0,1.015,1.336,4.159,4.159,0,0,1,.67,1.14,3.9,3.9,0,0,1-.869,4.379.236.236,0,1,1-.321-.346,3.362,3.362,0,0,0,.76-3.837.227.227,0,0,1-.011-.026,3.71,3.71,0,0,0-.593-1.009,15.225,15.225,0,0,1-1.047-1.379l-.094-.144a2.2,2.2,0,0,1-.373-1.154,2.123,2.123,0,0,1,.012-.275,4.348,4.348,0,0,0-1.206,4.247.236.236,0,0,1-.3.274,1.611,1.611,0,0,1-.958-1.011,3.739,3.739,0,0,0,1.076,4.623.236.236,0,0,1-.152.416Z"
                          transform="translate(-11.882 -22.646)"
                        />
                      </g>
                    </svg>
                    <span>
                      {props.post.totalLikeCount
                        ? props.post.totalLikeCount
                        : "Fire"}
                    </span>
                  </Link>
                </>
              )}
              {(props?.post?.allVideoAudioPost?.length > 0 && (props?.post?.allVideoAudioPost?.[0]?.typeofpost == 1 ||
                  props?.post?.allVideoAudioPost?.[0]?.typeofpost == 2 || props?.post?.allVideoAudioPost?.[0]?.typeofpost == 4)) &&
              <Link
                  to={props.isTimeline ? "" : "#"}
                  className="shareLink"
                  onClick={(e) => {
                    if (!props.isTimeline) props.handleModal(e, props.post);
                  }}
              >
                <span>Share</span>
                <span className="icon">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.804"
                      height="14"
                      viewBox="0 0 16.804 14"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <path
                        className="a"
                        d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z"
                        transform="translate(-4.502 -6.749)"
                    />
                  </svg>
                </span>
              </Link>
              }
              {/*}*/}
            </div>
          )}
        </div>
      ) : null}

      {props.post && props.post.postType === "event" ? (
        <div className="item">
          <div className="imgDataFlexBox">
            <div className="imgBox"
                 style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ?
                     {cursor:"pointer"} : {}}
                 onClick={()=>openProfile(props.post, 'event')}
            >
              {/*earlier by default image is show by now first and last letter is shown when image is not found*/}
              {!!props.post.profileImages ? (
                <img
                  src={
                    props.post.profileImages
                      ? props.post.profileImages
                      : userImg
                  }
                  alt="playerPic"
                  className={!props.post.profileImages ? "defaultUserImg" : ""}
                />
              ) : (
                <p>
                  {!!props?.post?.athleteFirstName
                    ? props?.post?.athleteFirstName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                  {!!props?.post?.athleteLastName
                    ? props?.post?.athleteLastName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                </p>
              )}
            </div>
            <div className="dataFlexBox">
              <div>
                <h5>
                  <span className={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global")
                      ? "bluehighLight" : ""}
                      style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ? {cursor:"pointer", color:"#007bff"} : {color:"#000"}}
                      onClick={()=>openProfile(props.post, 'event')}
                  >
                  {props.post.athleteFirstName +
                    " " +
                    props.post.athleteLastName}{" "}
                    </span>
                  &nbsp;have added a new event
                </h5>
                <span>
                  {props.post
                    ? props.post.userActivityUpdatedOn
                      ? getUTCTimeDateDash(props.post.userActivityUpdatedOn) +
                        " at " +
                        timeFormatter(props.post.userActivityUpdatedOn)
                      : ""
                    : ""}
                  <span style={{marginLeft:"5px"}}>{props?.post?.ispublic == 1
                      ? <img style={{height:"12px"}} src={globe} alt={"globe"}/> : ""}
                  </span>
                </span>
              </div>
              {props.tab === "myFeed" && !props?.isTimelineValue ? (
                <div className="action">
                  {props.post && props.post.pinedStatus ? (
                    <a
                      className={
                        props.isTimeline ? "timelinePinIcon" : "pinIcon"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <path
                          className="a"
                          d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                          transform="translate(0 0)"
                        />
                      </svg>
                    </a>
                  ) : null}
                  {!props.isTimeline == false ? (
                    ""
                  ) : (
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      onClick={(e) => {
                        if (!props.isTimeline) handleShowAction(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <g transform="translate(-380 -745)">
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 745)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 751)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 757)"
                          />
                        </g>
                      </svg>
                    </button>
                  )}
                  <div
                      ref={domRef}
                    className={`dropdown-menu ${showAction ? "show " : ""}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                      className="dropdown-item deleteLink"
                      to={props.isTimeline ? "" : "#"}
                      onClick={(e) => {
                        if (!props.isTimeline) handleRemovePost(e, props.post);
                      }}
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.11"
                          height="15.511"
                          viewBox="0 0 14.11 15.511"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <g transform="translate(-3.75 -2.25)">
                            <path
                              className="a"
                              d="M4.5,9H17.11"
                              transform="translate(0 -3.198)"
                            />
                            <path
                              className="a"
                              d="M17.308,5.8V15.61a1.4,1.4,0,0,1-1.4,1.4H8.9a1.4,1.4,0,0,1-1.4-1.4V5.8m2.1,0V4.4A1.4,1.4,0,0,1,11,3h2.8a1.4,1.4,0,0,1,1.4,1.4V5.8"
                              transform="translate(-1.599)"
                            />
                          </g>
                        </svg>
                      </span>
                      <span>Delete</span>{" "}
                    </Link>
                    {!props.containPin || props.post.pinedStatus ? (
                      <Link
                        className="dropdown-item"
                        to={props.isTimeline ? "" : "#"}
                        onClick={(e) => {
                          if (!props.isTimeline) handlePinPost(e, props.post);
                        }}
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                          >
                            <defs>
                              <style></style>
                            </defs>
                            <path
                              className="a"
                              d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                              transform="translate(0 0)"
                            />
                          </svg>
                        </span>
                        <span>
                          {props.post.pinedStatus
                            ? "Unpin to profile"
                            : "Pin to profile"}
                        </span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <p
              // style={{ lineBreak: "anywhere" }}//this is commented as per bug 8/11/2022
              className="description">
            {props.post.eventname} scheduled from{" "}
            {props.post.startdate
              ? getUTCTimeDateDash(props.post.startdate) +
                " to " +
                getUTCTimeDateDash(props.post.enddate)
              : ""}
          </p>
          {/*{!!getAuthToken() && (*/}
          {/*  <div className="shareLinkBox">*/}
          {/*    <div>*/}
          {/*      <Link to={props.isTimeline ? "" : "#"} className="shareLink">*/}
          {/*        <span*/}
          {/*          onClick={(e) => {*/}
          {/*            if (!props.isTimeline) props.handleModal(e, props.post);*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Share*/}
          {/*        </span>*/}
          {/*        <span className="icon">*/}
          {/*          <svg*/}
          {/*            xmlns="http://www.w3.org/2000/svg"*/}
          {/*            width="16.804"*/}
          {/*            height="14"*/}
          {/*            viewBox="0 0 16.804 14"*/}
          {/*          >*/}
          {/*            <defs>*/}
          {/*              <style></style>*/}
          {/*            </defs>*/}
          {/*            <path*/}
          {/*              className="a"*/}
          {/*              d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z"*/}
          {/*              transform="translate(-4.502 -6.749)"*/}
          {/*            />*/}
          {/*          </svg>*/}
          {/*        </span>*/}
          {/*      </Link>*/}
          {/*      /!*}*!/*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      ) : null}
      {props.post && props.post.postType === "eventNew" ? (
        <div className="item">
          <div className="imgDataFlexBox">
            <div className="imgBox"
                 style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ?
                     {cursor:"pointer"} : {}}
                 onClick={()=>openProfile(props.post, 'eventNew')}
            >
              {/*earlier by default image is show by now first and last letter is shown when image is not found*/}
              {!!props.post.profileImages ? (
                <img
                  src={
                    props.post.profileImages
                      ? props.post.profileImages
                      : userImg
                  }
                  alt="playerPic"
                  className={!props.post.profileImages ? "defaultUserImg" : ""}
                />
              ) : (
                <p>
                  {!!props?.post?.athleteFirstName
                    ? props?.post?.athleteFirstName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                  {!!props?.post?.athleteLastName
                    ? props?.post?.athleteLastName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                </p>
              )}
            </div>
            <div className="dataFlexBox">
              <div>
                <h5>
                  <span className={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global")
                      ? "bluehighLight" : ""}
                      style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ? {cursor:"pointer", color:"#007bff"} :{color:"#000"}}
                      onClick={()=>openProfile(props.post, 'eventNew')}
                  >
                  {props.post.athleteFirstName +
                    " " +
                    props.post.athleteLastName}{" "}
                    </span>
                  &nbsp;have added{" "}
                  <span className="redhighLight">{props.post.eventname}</span>
                </h5>
                <span>
                  {props.post
                    ? props.post.userActivityUpdatedOn
                      ? getUTCTimeDateDash(props.post.userActivityUpdatedOn) +
                        " at " +
                        timeFormatter(props.post.userActivityUpdatedOn)
                      : ""
                    : ""}
                  <span style={{marginLeft:"5px"}}>{props?.post?.ispublic == 1
                      ? <img style={{height:"12px"}} src={globe} alt={"globe"}/> : ""}
                  </span>
                </span>
              </div>
              {props.tab === "myFeed" && !props?.isTimelineValue ? (
                <div className="action">
                  {props.post && props.post.pinedStatus ? (
                    <a
                      className={
                        props.isTimeline ? "timelinePinIcon" : "pinIcon"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <path
                          className="a"
                          d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                          transform="translate(0 0)"
                        />
                      </svg>
                    </a>
                  ) : null}
                  {!props.isTimeline == false ? (
                    ""
                  ) : (
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      onClick={(e) => {
                        if (!props.isTimeline) handleShowAction(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <g transform="translate(-380 -745)">
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 745)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 751)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 757)"
                          />
                        </g>
                      </svg>
                    </button>
                  )}
                  <div
                      ref={domRef}
                    className={`dropdown-menu ${showAction ? "show " : ""}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                      className="dropdown-item deleteLink"
                      to={props.isTimeline ? "" : "#"}
                      onClick={(e) => {
                        if (!props.isTimeline) handleRemovePost(e, props.post);
                      }}
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.11"
                          height="15.511"
                          viewBox="0 0 14.11 15.511"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <g transform="translate(-3.75 -2.25)">
                            <path
                              className="a"
                              d="M4.5,9H17.11"
                              transform="translate(0 -3.198)"
                            />
                            <path
                              className="a"
                              d="M17.308,5.8V15.61a1.4,1.4,0,0,1-1.4,1.4H8.9a1.4,1.4,0,0,1-1.4-1.4V5.8m2.1,0V4.4A1.4,1.4,0,0,1,11,3h2.8a1.4,1.4,0,0,1,1.4,1.4V5.8"
                              transform="translate(-1.599)"
                            />
                          </g>
                        </svg>
                      </span>
                      <span>Delete</span>{" "}
                    </Link>
                    {!props.containPin || props.post.pinedStatus ? (
                      <Link
                        className="dropdown-item"
                        to={props.isTimeline ? "" : "#"}
                        onClick={(e) => {
                          if (!props.isTimeline) handlePinPost(e, props.post);
                        }}
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                          >
                            <defs>
                              <style></style>
                            </defs>
                            <path
                              className="a"
                              d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                              transform="translate(0 0)"
                            />
                          </svg>
                        </span>
                        <span>
                          {props.post.pinedStatus
                            ? "Unpin to profile"
                            : "Pin to profile"}
                        </span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <p style={{ lineBreak: "anywhere" }} className="description">
            {props.post.eventname} scheduled from{" "}
            {props.post.startdate
              ? getUTCTimeDateDash(props.post.startdate) +
                " to " +
                getUTCTimeDateDash(props.post.enddate)
              : ""}
          </p>
          {/*{!!getAuthToken() && (*/}
          {/*  <div className="shareLinkBox">*/}
          {/*    <div>*/}
          {/*      <Link to={props.isTimeline ? "" : "#"} className="shareLink">*/}
          {/*        <span*/}
          {/*          onClick={(e) => {*/}
          {/*            if (!props.isTimeline) props.handleModal(e, props.post);*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Share*/}
          {/*        </span>*/}
          {/*        <span className="icon">*/}
          {/*          <svg*/}
          {/*            xmlns="http://www.w3.org/2000/svg"*/}
          {/*            width="16.804"*/}
          {/*            height="14"*/}
          {/*            viewBox="0 0 16.804 14"*/}
          {/*          >*/}
          {/*            <defs>*/}
          {/*              <style></style>*/}
          {/*            </defs>*/}
          {/*            <path*/}
          {/*              className="a"*/}
          {/*              d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z"*/}
          {/*              transform="translate(-4.502 -6.749)"*/}
          {/*            />*/}
          {/*          </svg>*/}
          {/*        </span>*/}
          {/*      </Link>*/}
          {/*      /!*}*!/*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      ) : null}
      {props.post && props.post.postType === "favorite" ? (
        <div className="item">
          <div className="imgDataFlexBox">
            <div className="imgBox"
                 style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ?
                     {cursor:"pointer"} : {}}
                 onClick={()=>openProfile(props.post, 'favorite')}
            >
              {/*earlier by default image is show by now first and last letter is shown when image is not found*/}
              {!!props.post.profileImages ? (
                <img
                  src={
                    props.post.profileImages
                      ? props.post.profileImages
                      : userImg
                  }
                  alt="playerPic"
                  className={!props.post.profileImages ? "defaultUserImg" : ""}
                />
              ) : (
                <p>
                  {!!props?.post?.athleteFirstName
                    ? props?.post?.athleteFirstName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                  {!!props?.post?.athleteLastName
                    ? props?.post?.athleteLastName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                </p>
              )}
            </div>
            <div className="dataFlexBox">
              <div>
                <h5>
                  <span className={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global")
                      ? "bluehighLight" : ""}
                      style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ? {cursor:"pointer", color:"#007bff"} : {color:"#000"}}
                      onClick={()=>openProfile(props.post, 'favorite')}
                  >
                  {props.post.athleteFirstName +
                    " " +
                    props.post.athleteLastName}{" "}
                    </span>
                  &nbsp;favorited{" "}
                  {/*last moment check over clg over token */}
                  <span
                      className={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global")
                          ? "redhighLight" : ""}
                      >
                    <Link style={!!getAuthToken() ? {color: '#c0321f', cursor:"pointer"} : {color: '#c0321f', cursor:"text"}}
                       to={!!getAuthToken() ? `/colleges/details/${props.post && props.post.collegename ? props.post.id : null}/${props?.post ? props.post.isfav : 1 }`: void 0}
                       onClick={() => handleCollegeClick(props.post && props.post.collegename ? props.post.id : null)}>
                      {props.post
                        ? props.post.collegename
                          ? props.post.collegename
                          : ""
                        : ""}
                    </Link>
                  </span>
                </h5>
                <span>
                  {props.post
                    ? props.post.userActivityUpdatedOn
                      ? getUTCTimeDateDash(props.post.userActivityUpdatedOn) +
                        " at " +
                        timeFormatter(props.post.userActivityUpdatedOn)
                      : ""
                    : ""}
                  <span style={{marginLeft:"5px"}}>{props?.post?.ispublic == 1
                      ? <img style={{height:"12px"}} src={globe} alt={"globe"}/> :
                      <img style={{height:"12px"}} src={globe} alt={"globe"}/>
                  }
                  </span>
                </span>
              </div>
              {props.tab === "myFeed" && !props?.isTimelineValue ? (
                <div className="action">
                  {props.post && props.post.pinedStatus ? (
                    <a
                      className={
                        props.isTimeline ? "timelinePinIcon" : "pinIcon"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <path
                          className="a"
                          d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                          transform="translate(0 0)"
                        />
                      </svg>
                    </a>
                  ) : null}
                  {!props.isTimeline == false ? (
                    ""
                  ) : (
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      onClick={(e) => {
                        if (!props.isTimeline) handleShowAction(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <g transform="translate(-380 -745)">
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 745)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 751)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 757)"
                          />
                        </g>
                      </svg>
                    </button>
                  )}
                  <div
                      ref={domRef}
                    className={`dropdown-menu ${showAction ? "show " : ""}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                      className="dropdown-item deleteLink"
                      to={props.isTimeline ? "" : "#"}
                      onClick={(e) => {
                        if (!props.isTimeline) handleRemovePost(e, props.post);
                      }}
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.11"
                          height="15.511"
                          viewBox="0 0 14.11 15.511"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <g transform="translate(-3.75 -2.25)">
                            <path
                              className="a"
                              d="M4.5,9H17.11"
                              transform="translate(0 -3.198)"
                            />
                            <path
                              className="a"
                              d="M17.308,5.8V15.61a1.4,1.4,0,0,1-1.4,1.4H8.9a1.4,1.4,0,0,1-1.4-1.4V5.8m2.1,0V4.4A1.4,1.4,0,0,1,11,3h2.8a1.4,1.4,0,0,1,1.4,1.4V5.8"
                              transform="translate(-1.599)"
                            />
                          </g>
                        </svg>
                      </span>
                      <span>Delete</span>{" "}
                    </Link>
                    {!props.containPin || props.post.pinedStatus ? (
                      <Link
                        className="dropdown-item"
                        to={props.isTimeline ? "" : "#"}
                        onClick={(e) => {
                          if (!props.isTimeline) handlePinPost(e, props.post);
                        }}
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                          >
                            <defs>
                              <style></style>
                            </defs>
                            <path
                              className="a"
                              d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                              transform="translate(0 0)"
                            />
                          </svg>
                        </span>
                        <span>
                          {props.post.pinedStatus
                            ? "Unpin to profile"
                            : "Pin to profile"}
                        </span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/*{!!getAuthToken() && (*/}
          {/*  <div className="shareLinkBox">*/}
          {/*    <div>*/}
          {/*      <Link*/}
          {/*        to={props.isTimeline ? "" : "#"}*/}
          {/*        className="shareLink"*/}
          {/*        onClick={(e) => {*/}
          {/*          if (!props.isTimeline) props.handleModal(e, props.post);*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <span>Share</span>*/}
          {/*        <span className="icon">*/}
          {/*          <svg*/}
          {/*            xmlns="http://www.w3.org/2000/svg"*/}
          {/*            width="16.804"*/}
          {/*            height="14"*/}
          {/*            viewBox="0 0 16.804 14"*/}
          {/*          >*/}
          {/*            <defs>*/}
          {/*              <style></style>*/}
          {/*            </defs>*/}
          {/*            <path*/}
          {/*              className="a"*/}
          {/*              d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z"*/}
          {/*              transform="translate(-4.502 -6.749)"*/}
          {/*            />*/}
          {/*          </svg>*/}
          {/*        </span>*/}
          {/*      </Link>*/}
          {/*      /!*}*!/*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      ) : null}

      {props.post && props.post.postType === "team" ? (
        <div className="item">
          <div className="imgDataFlexBox">
            <div className="imgBox"
                 style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ?
                     {cursor:"pointer"} : {}}
                 onClick={()=>openProfile(props.post, 'team')}
            >
              {/*earlier by default image is show by now first and last letter is shown when image is not found*/}
              {!!props.post.profileImages ? (
                <img
                  src={
                    props.post.profileImages
                      ? props.post.profileImages
                      : userImg
                  }
                  alt="playerPic"
                  className={!props.post.profileImages ? "defaultUserImg" : ""}
                />
              ) : (
                <p>
                  {!!props?.post?.athleteFirstName
                    ? props?.post?.athleteFirstName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                  {!!props?.post?.athleteLastName
                    ? props?.post?.athleteLastName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                </p>
              )}
            </div>
            <div className="dataFlexBox">
              <div>
                <h5>
                  <span className={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global")
                      ? "bluehighLight" : ""} style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ? {cursor:"pointer", color:"#007bff"} : {color:"#000"}}
                        onClick={()=>openProfile(props.post, 'team')}>
                  {props.post.athleteFirstName +
                    " " +
                    props.post.athleteLastName}{" "}
                    </span>
                  &nbsp;joined the {props.post.teamname}
                </h5>
                <span>
                  {props.post
                    ? props.post.userActivityUpdatedOn
                      ? getUTCTimeDateDash(props.post.userActivityUpdatedOn) +
                        " at " +
                        timeFormatter(props.post.userActivityUpdatedOn)
                      : ""
                    : ""}
                  <span style={{marginLeft:"5px"}}>{props?.post?.ispublic == 1
                      ? <img style={{height:"12px"}} src={globe} alt={"globe"}/> : ""}
                  </span>
                </span>
              </div>
              {props.tab === "myFeed" && !props?.isTimelineValue ? (
                <div className="action">
                  {props.post && props.post.pinedStatus ? (
                    <a
                      className={
                        props.isTimeline ? "timelinePinIcon" : "pinIcon"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <path
                          className="a"
                          d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                          transform="translate(0 0)"
                        />
                      </svg>
                    </a>
                  ) : null}
                  {!props.isTimeline == false ? (
                    ""
                  ) : (
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      onClick={(e) => {
                        if (!props.isTimeline) handleShowAction(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <g transform="translate(-380 -745)">
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 745)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 751)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 757)"
                          />
                        </g>
                      </svg>
                    </button>
                  )}
                  <div
                      ref={domRef}
                    className={`dropdown-menu ${showAction ? "show " : ""}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                      className="dropdown-item deleteLink"
                      to={props.isTimeline ? "" : "#"}
                      onClick={(e) => {
                        if (!props.isTimeline) handleRemovePost(e, props.post);
                      }}
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.11"
                          height="15.511"
                          viewBox="0 0 14.11 15.511"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <g transform="translate(-3.75 -2.25)">
                            <path
                              className="a"
                              d="M4.5,9H17.11"
                              transform="translate(0 -3.198)"
                            />
                            <path
                              className="a"
                              d="M17.308,5.8V15.61a1.4,1.4,0,0,1-1.4,1.4H8.9a1.4,1.4,0,0,1-1.4-1.4V5.8m2.1,0V4.4A1.4,1.4,0,0,1,11,3h2.8a1.4,1.4,0,0,1,1.4,1.4V5.8"
                              transform="translate(-1.599)"
                            />
                          </g>
                        </svg>
                      </span>
                      <span>Delete</span>{" "}
                    </Link>
                    {!props.containPin || props.post.pinedStatus ? (
                      <Link
                        className="dropdown-item"
                        to={props.isTimeline ? "" : "#"}
                        onClick={(e) => {
                          if (!props.isTimeline) handlePinPost(e, props.post);
                        }}
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                          >
                            <defs>
                              <style></style>
                            </defs>
                            <path
                              className="a"
                              d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                              transform="translate(0 0)"
                            />
                          </svg>
                        </span>
                        <span>
                          {props.post.pinedStatus
                            ? "Unpin to profile"
                            : "Pin to profile"}
                        </span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/*{!!getAuthToken() && (*/}
          {/*  <div className="shareLinkBox">*/}
          {/*    <div>*/}
          {/*      <Link*/}
          {/*        to={props.isTimeline ? "" : "#"}*/}
          {/*        className="shareLink"*/}
          {/*        onClick={(e) => {*/}
          {/*          if (!props.isTimeline) props.handleModal(e, props.post);*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <span>Share</span>*/}
          {/*        <span className="icon">*/}
          {/*          <svg*/}
          {/*            xmlns="http://www.w3.org/2000/svg"*/}
          {/*            width="16.804"*/}
          {/*            height="14"*/}
          {/*            viewBox="0 0 16.804 14"*/}
          {/*          >*/}
          {/*            <path*/}
          {/*              className="a"*/}
          {/*              d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z"*/}
          {/*              transform="translate(-4.502 -6.749)"*/}
          {/*            />*/}
          {/*          </svg>*/}
          {/*        </span>*/}
          {/*      </Link>*/}
          {/*      /!*}*!/*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      ) : null}

      {props.post && props.post.postType === "message" ? (
        <div className="item">
          <div className="imgDataFlexBox">
            <div className="imgBox"
                 style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ?
                     {cursor:"pointer"} : {}}
                 onClick={()=>openProfile(props.post, 'message')}
            >
              {props?.post?.msgSendByProfileImage ? (
                <img
                  src={
                    props.post.msgSendByProfileImage
                      ? props.post.msgSendByProfileImage
                      : userImg
                  }
                  alt="playerPic"
                  className={
                    !props.post.msgSendByProfileImage ? "defaultUserImg" : ""
                  }
                />
              ) : (
                <div
                  className={
                    !props.post.msgSendByProfileImage ? "defaultUserImg" : ""
                  }
                >
                  {getInitials(props.post)}
                </div>
              )}
            </div>
            <div className="dataFlexBox">
              <div>
                <h5>
                    <span className={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global")
                        ? "bluehighLight" : ""}
                        style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ? {cursor:"pointer", color:"#007bff"} : {color:"#000"}}
                        onClick={()=>openProfile(props.post, 'message1')}
                    >
                      {props.post.msgSendByFirstName.concat(
                    props?.post?.msgSendByLastName
                      ? " " + props?.post?.msgSendByLastName
                      : ""
                      )}</span>
                       &nbsp;has sent a message to&nbsp;
                    <span
                        style={{color:"#000"}}
                        // onClick={()=>openProfile(props.post, 'message2')}
                    >{props.post.msgSendToFirstName.concat(
                      props?.post?.msgSendToLastName
                        ? " " + props.post.msgSendToLastName
                        : ""
                      )}
                    </span>
                </h5>
                <span>
                  {props.post
                    ? props.post.userActivityUpdatedOn
                      ? getUTCTimeDateDash(props.post.userActivityUpdatedOn) +
                        " on " +
                        timeFormatter(props.post.userActivityUpdatedOn)
                      : ""
                    : ""}
                  <span style={{marginLeft:"5px"}}>{props?.post?.ispublic == 1
                      ? <img style={{height:"12px"}} src={globe} alt={"globe"}/> : ""}
                  </span>
                </span>
              </div>
              {props.tab === "myFeed" && !props?.isTimelineValue ? (
                <div className="action">
                  {props.post && props.post.pinedStatus ? (
                    <a
                      className={
                        props.isTimeline ? "timelinePinIcon" : "pinIcon"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <path
                          className="a"
                          d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                          transform="translate(0 0)"
                        />
                      </svg>
                    </a>
                  ) : null}
                  {!props.isTimeline == false ? (
                    ""
                  ) : (
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      onClick={(e) => {
                        if (!props.isTimeline) handleShowAction(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <g transform="translate(-380 -745)">
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 745)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 751)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 757)"
                          />
                        </g>
                      </svg>
                    </button>
                  )}
                  <div
                      ref={domRef}
                    className={`dropdown-menu ${showAction ? "show " : ""}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                      className="dropdown-item deleteLink"
                      to={props.isTimeline ? "" : "#"}
                      onClick={(e) => {
                        if (!props.isTimeline) handleRemovePost(e, props.post);
                      }}
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.11"
                          height="15.511"
                          viewBox="0 0 14.11 15.511"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <g transform="translate(-3.75 -2.25)">
                            <path
                              className="a"
                              d="M4.5,9H17.11"
                              transform="translate(0 -3.198)"
                            />
                            <path
                              className="a"
                              d="M17.308,5.8V15.61a1.4,1.4,0,0,1-1.4,1.4H8.9a1.4,1.4,0,0,1-1.4-1.4V5.8m2.1,0V4.4A1.4,1.4,0,0,1,11,3h2.8a1.4,1.4,0,0,1,1.4,1.4V5.8"
                              transform="translate(-1.599)"
                            />
                          </g>
                        </svg>
                      </span>
                      <span>Delete</span>{" "}
                    </Link>
                    {!props.containPin || props.post.pinedStatus ? (
                      <Link
                        className="dropdown-item"
                        to={props.isTimeline ? "" : "#"}
                        onClick={(e) => {
                          if (!props.isTimeline) handlePinPost(e, props.post);
                        }}
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                          >
                            <defs>
                              <style></style>
                            </defs>
                            <path
                              className="a"
                              d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                              transform="translate(0 0)"
                            />
                          </svg>
                        </span>
                        <span>
                          {props.post.pinedStatus
                            ? "Unpin to profile"
                            : "Pin to profile"}
                        </span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <p style={{ lineBreak: "anywhere" }} className="description">
            {props.post.message}
          </p>
          {/* <p className="description">
            Sent by :{" "}
            {props.post.msgSendByFirstName + " " + props.post.msgSendByLastName}
          </p> */}
          {/*{!!getAuthToken() && (*/}
          {/*  <div className="shareLinkBox">*/}
          {/*    <div>*/}
          {/*      <Link to={props.isTimeline ? "" : "#"} className="shareLink">*/}
          {/*        <span*/}
          {/*          onClick={(e) => {*/}
          {/*            if (!props.isTimeline) props.handleModal(e, props.post);*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Share*/}
          {/*        </span>*/}
          {/*        <span className="icon">*/}
          {/*          <svg*/}
          {/*            xmlns="http://www.w3.org/2000/svg"*/}
          {/*            width="16.804"*/}
          {/*            height="14"*/}
          {/*            viewBox="0 0 16.804 14"*/}
          {/*          >*/}
          {/*            <defs>*/}
          {/*              <style></style>*/}
          {/*            </defs>*/}
          {/*            <path*/}
          {/*              className="a"*/}
          {/*              d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z"*/}
          {/*              transform="translate(-4.502 -6.749)"*/}
          {/*            />*/}
          {/*          </svg>*/}
          {/*        </span>*/}
          {/*      </Link>*/}
          {/*      /!*}*!/*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      ) : null}

      {props.post && props.post.postType === "profile" ? (
        <div className="item">
          <div className="imgDataFlexBox">
            <div className="imgBox"
                 style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ?
                     {cursor:"pointer"} : {}}
                 onClick={() => openProfile(props.post, 'profile')}
            >
              {props.post.profileviewedby === getUserInfo().id ? (
                <img
                  src={
                    props.post.profileViewedToImageName
                      ? props.post.profileViewedToImageName
                      : userImg
                  }
                  alt="playerPic"
                  className={
                    !props.post.profileViewedToImageName ? "defaultUserImg" : ""
                  }
                />
              ) : (
                <img
                  src={
                    props.post.profileViewedByImageName
                      ? props.post.profileViewedByImageName
                      : userImg
                  }
                  alt="playerPic"
                  className={
                    !props.post.profileViewedByImageName ? "defaultUserImg" : ""
                  }
                />
              )}
            </div>
            <div className="dataFlexBox">
              <div>
                <h5>
                  {props.post.profileviewedby === getUserInfo().id
                      ?
                      <span className="bluehighLight"
                          style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ? {cursor: "pointer", color: "#007bff"} : {color: "#000"}}
                          onClick={() => openProfile(props.post, 'profile1')}
                      >You have viewed {props.post.profileViewedToFirstName +
                      " " +
                      props.post.profileViewedBToLasrName} profile
                      </span>
                      : 
                      <span className="bluehighLight"
                          style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ? {cursor: "pointer", color: "#007bff"} : {color: "#000"}}
                          onClick={() => openProfile(props.post, 'profile2')}
                      >Your profile viewed by {props.post.profileViewedToFirstName +
                      " " +
                      props.post.profileViewedBToLasrName}
                      </span>
                  }
                </h5>
                <span>
                  {props.post
                    ? props.post.userActivityUpdatedOn
                      ? getUTCTimeDateDash(props.post.userActivityUpdatedOn) +
                        " at " +
                        timeFormatter(props.post.userActivityUpdatedOn)
                      : ""
                    : ""}
                  <span style={{marginLeft:"5px"}}>{props?.post?.ispublic == 1
                      ? <img style={{height:"12px"}} src={globe} alt={"globe"}/> : ""}
                  </span>
                </span>
              </div>
              {props.tab === "myFeed" && !props?.isTimelineValue ? (
                <div className="action">
                  {props.post && props.post.pinedStatus ? (
                    <a
                      className={
                        props.isTimeline ? "timelinePinIcon" : "pinIcon"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <path
                          className="a"
                          d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                          transform="translate(0 0)"
                        />
                      </svg>
                    </a>
                  ) : null}
                  {!props.isTimeline == false ? (
                    ""
                  ) : (
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      onClick={(e) => {
                        if (!props.isTimeline) handleShowAction(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <g transform="translate(-380 -745)">
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 745)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 751)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 757)"
                          />
                        </g>
                      </svg>
                    </button>
                  )}
                  <div
                      ref={domRef}
                    className={`dropdown-menu ${showAction ? "show " : ""}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                      className="dropdown-item deleteLink"
                      to={props.isTimeline ? "" : "#"}
                      onClick={(e) => {
                        if (!props.isTimeline) handleRemovePost(e, props.post);
                      }}
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.11"
                          height="15.511"
                          viewBox="0 0 14.11 15.511"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <g transform="translate(-3.75 -2.25)">
                            <path
                              className="a"
                              d="M4.5,9H17.11"
                              transform="translate(0 -3.198)"
                            />
                            <path
                              className="a"
                              d="M17.308,5.8V15.61a1.4,1.4,0,0,1-1.4,1.4H8.9a1.4,1.4,0,0,1-1.4-1.4V5.8m2.1,0V4.4A1.4,1.4,0,0,1,11,3h2.8a1.4,1.4,0,0,1,1.4,1.4V5.8"
                              transform="translate(-1.599)"
                            />
                          </g>
                        </svg>
                      </span>
                      <span>Delete</span>{" "}
                    </Link>
                    {!props.containPin || props.post.pinedStatus ? (
                      <Link
                        className="dropdown-item"
                        to={props.isTimeline ? "" : "#"}
                        onClick={(e) => {
                          if (!props.isTimeline) handlePinPost(e, props.post);
                        }}
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                          >
                            <defs>
                              <style></style>
                            </defs>
                            <path
                              className="a"
                              d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                              transform="translate(0 0)"
                            />
                          </svg>
                        </span>
                        <span>
                          {props.post.pinedStatus
                            ? "Unpin to profile"
                            : "Pin to profile"}
                        </span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/* <p className="description">{props.post.message}</p> */}
          {/* <p className="description">
            Sent by :{" "}
            {props.post.msgSendByFirstName + " " + props.post.msgSendByLastName}
          </p> */}
          {!!getAuthToken() && (
            <div className="shareLinkBox">
              <div>
                {/*{!props.isTimeline == false ? "" :*/}
                <Link to={props.isTimeline ? "" : "#"} className="shareLink">
                  <span
                    onClick={(e) => {
                      if (!props.isTimeline) props.handleModal(e, props.post);
                    }}
                  >
                    Share
                  </span>
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.804"
                      height="14"
                      viewBox="0 0 16.804 14"
                    >
                      <defs>
                        <style></style>
                      </defs>
                      <path
                        className="a"
                        d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z"
                        transform="translate(-4.502 -6.749)"
                      />
                    </svg>
                  </span>
                </Link>
                {/*}*/}
              </div>
            </div>
          )}
        </div>
      ) : null}

      {props.post && props.post.postType === "commit" ? (
        <div className="item">
          <div className="imgDataFlexBox">
            <div className="imgBox"
                 style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ?
                     {cursor:"pointer"} : {}}
                 onClick={()=>openProfile(props.post, 'commit')}
            >
              {props.post.messagesendby === getUserInfo().id ? (
                <img
                  src={
                    props.post.msgSendToProfileImage
                      ? props.post.msgSendToProfileImage
                      : userImg
                  }
                  alt="playerPic"
                  className={
                    !props.post.msgSendToProfileImage ? "defaultUserImg" : ""
                  }
                />
              ) : (
                <img
                  src={
                    props.post.msgSendByProfileImage
                      ? props.post.msgSendByProfileImage
                      : userImg
                  }
                  alt="playerPic"
                  className={
                    !props.post.msgSendByProfileImage ? "defaultUserImg" : ""
                  }
                />
              )}
            </div>
            <div className="dataFlexBox">
              <div>
                <h5 className={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global")
                    ? "bluehighLight" : ""} style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ? {cursor:"pointer", color:"#007bff"} : {}}
                    onClick={()=>openProfile(props.post, 'commit')}
                >
                  {props.post.athleteFirstName +
                    " " +
                    props.post.athleteLastName}
                </h5>
                <span>
                  {props.post
                    ? props.post.userActivityUpdatedOn
                      ? getUTCTimeDateDash(props.post.userActivityUpdatedOn) +
                        " at " +
                        timeFormatter(props.post.userActivityUpdatedOn)
                      : ""
                    : ""}
                  <span style={{marginLeft:"5px"}}>{props?.post?.ispublic == 1
                      ? <img style={{height:"12px"}} src={globe} alt={"globe"}/> : ""}
                  </span>
                </span>
              </div>
              {props.tab === "myFeed" && !props?.isTimelineValue ? (
                <div className="action">
                  {props.post && props.post.pinedStatus ? (
                    <a
                      className={
                        props.isTimeline ? "timelinePinIcon" : "pinIcon"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <path
                          className="a"
                          d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                          transform="translate(0 0)"
                        />
                      </svg>
                    </a>
                  ) : null}
                  {!props.isTimeline == false ? (
                    ""
                  ) : (
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      onClick={(e) => {
                        if (!props.isTimeline) handleShowAction(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <g transform="translate(-380 -745)">
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 745)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 751)"
                          />
                          <circle
                            className="a"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(380 757)"
                          />
                        </g>
                      </svg>
                    </button>
                  )}
                  <div
                      ref={domRef}
                    className={`dropdown-menu ${showAction ? "show " : ""}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                      className="dropdown-item deleteLink"
                      to={props.isTimeline ? "" : "#"}
                      onClick={(e) => {
                        if (!props.isTimeline) handleRemovePost(e, props.post);
                      }}
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.11"
                          height="15.511"
                          viewBox="0 0 14.11 15.511"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <g transform="translate(-3.75 -2.25)">
                            <path
                              className="a"
                              d="M4.5,9H17.11"
                              transform="translate(0 -3.198)"
                            />
                            <path
                              className="a"
                              d="M17.308,5.8V15.61a1.4,1.4,0,0,1-1.4,1.4H8.9a1.4,1.4,0,0,1-1.4-1.4V5.8m2.1,0V4.4A1.4,1.4,0,0,1,11,3h2.8a1.4,1.4,0,0,1,1.4,1.4V5.8"
                              transform="translate(-1.599)"
                            />
                          </g>
                        </svg>
                      </span>
                      <span>Delete</span>{" "}
                    </Link>
                    {!props.containPin || props.post.pinedStatus ? (
                      <Link
                        className="dropdown-item"
                        to={props.isTimeline ? "" : "#"}
                        onClick={(e) => {
                          if (!props.isTimeline) handlePinPost(e, props.post);
                        }}
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                          >
                            <defs>
                              <style></style>
                            </defs>
                            <path
                              className="a"
                              d="M.073,11.927a.25.25,0,0,0,.354,0L4.75,7.6,6.823,9.677a.251.251,0,0,0,.354,0l.75-.75A.252.252,0,0,0,8,8.75v-1.9L10.25,4.6l.323.324a.25.25,0,0,0,.354,0l1-1a.251.251,0,0,0,0-.354l-3.5-3.5a.251.251,0,0,0-.354,0l-1,1a.251.251,0,0,0,0,.354L7.4,1.75,5.146,4H3.25a.253.253,0,0,0-.177.073l-.75.75a.251.251,0,0,0,0,.354L4.4,7.25.073,11.573A.25.25,0,0,0,.073,11.927Z"
                              transform="translate(0 0)"
                            />
                          </svg>
                        </span>
                        <span>
                          {props.post.pinedStatus
                            ? "Unpin to profile"
                            : "Pin to profile"}
                        </span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <p style={{ lineBreak: "anywhere" }} className="description">
            {props.post.message}
          </p>
          <p style={{ lineBreak: "anywhere" }} className="description">
            Commitment: {props.post.commitment} <br />
            Commitment Date : {props.post.commitmentDate}
          </p>
          {/*{!!getAuthToken() && (*/}
          {/*  <div className="shareLinkBox">*/}
          {/*    <div>*/}
          {/*      <Link to={props.isTimeline ? "" : "#"} className="shareLink">*/}
          {/*        <span*/}
          {/*          onClick={(e) => {*/}
          {/*            if (!props.isTimeline) props.handleModal(e, props.post);*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Share*/}
          {/*        </span>*/}
          {/*        <span className="icon">*/}
          {/*          <svg*/}
          {/*            xmlns="http://www.w3.org/2000/svg"*/}
          {/*            width="16.804"*/}
          {/*            height="14"*/}
          {/*            viewBox="0 0 16.804 14"*/}
          {/*          >*/}
          {/*            <defs>*/}
          {/*              <style></style>*/}
          {/*            </defs>*/}
          {/*            <path*/}
          {/*              className="a"*/}
          {/*              d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z"*/}
          {/*              transform="translate(-4.502 -6.749)"*/}
          {/*            />*/}
          {/*          </svg>*/}
          {/*        </span>*/}
          {/*      </Link>*/}
          {/*      /!*}*!/*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      ) : null}

      {props.post && props.post.postType === "photosVideos" ? (
        <div className="item">
          <div className="imgDataFlexBox">
            <div className="imgBox"
                 style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ?
                     {cursor:"pointer"} : {}}
                 onClick={()=>openProfile(props.post, 'photosVideos')}
            >
              {/*earlier by default image is show by now first and last letter is shown when image is not found*/}
              {!!props.post.profileImages ? (
                <img
                  src={
                    props.post.profileImages
                      ? props.post.profileImages
                      : userImg
                  }
                  alt="playerPic"
                  className={!props.post.profileImages ? "defaultUserImg" : ""}
                />
              ) : (
                <p>
                  {!!props?.post?.athleteFirstName
                    ? props?.post?.athleteFirstName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                  {!!props?.post?.athleteLastName
                    ? props?.post?.athleteLastName
                        ?.split("")[0]
                        .toUpperCase() || ""
                    : ""}
                </p>
              )}
            </div>
            <div className="dataFlexBox">
              <div>
                <h5 className={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global")
                    ? "bluehighLight" : ""} style={(props?.page == "Dashboard/MyFeed" || props?.page == "Dashboard/Global") ? {cursor:"pointer", color:"#007bff"} : {}}
                    onClick={()=>openProfile(props.post, 'photosVideos')}
                >
                  {props.post.athleteFirstName +
                    " " +
                    props.post.athleteLastName}
                </h5>
                <span>
                  {props.post
                    ? props.post.userActivityUpdatedOn
                      ? getUTCTimeDateDash(props.post.userActivityUpdatedOn) +
                        " at " +
                        timeFormatter(props.post.userActivityUpdatedOn)
                      : ""
                    : ""}
                  <span style={{marginLeft:"5px"}}>{props?.post?.ispublic == 1 || props?.post?.isvisible == 1
                      ? <img style={{height:"12px"}} src={globe} alt={"globe"}/> : ""}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className="videoSection">
            <div div className="video">
              {props.post.isphotosorvideosLik === 0 && (
                <img
                  src={
                    props.post.photosname
                      ? `${imageDownloadUrl}/${props.post.photosname}`
                      : null
                  }
                  alt="image"
                />
              )}
              {props.post.isphotosorvideosLik === 1 && (
                  <a href={void 0}>
                <video
                    playsInline={true}
                    preload={"auto"}
                    type="video/mp4"
                    id={"postVideo"}
                    // controls
                // controlsList={props?.isAuth == 1 ? "" : "nodownload"}
                >
                  <source
                    src={
                      props.post.videoname
                        ? `${imageDownloadUrl}/${typeConvert(props.post.videoname, props?.page ?? "")}`+"#t=0.3"
                        : null
                    }
                    alt="video"
                  />
                </video>
                <span className="video-play-button"
                      onClick={()=>openVidPop(`${imageDownloadUrl}/${props.post.videoname}`, false, 3, props)}
                >
                <span className="video-play-inner">
                <button>
                <svg xmlns="http://www.w3.org/2000/svg" width="10.802" height="16.203"
                viewBox="0 0 10.802 16.203">
                <defs></defs>
                <path className="a" d="M8.1,0l8.1,10.8H0Z"
                transform="translate(10.802) rotate(90)"></path>
                </svg>
                </button>
                </span>
                </span>
                  </a>
              )}
              {props.post.isphotosorvideosLik === 2 && props.post.videolink && (
                <a
                    href={void 0}
                    // href={props.post.videolink}
                    target="_blank"
                    onClick={()=>openVidPop(props.post.videolink, true, 4, props)}
                >
                  <video
                      playsInline={true}
                      preload={"auto"}
                      type="video/mp4"
                      id={"postVideo"}
                      // controls
                         poster={ getThumbnail(props.post.videolink, props.post, props?.page) }
                  // controlsList={props?.isAuth == 1 ? "" : "nodownload"}
                  >
                    <source
                      src={props.post.videolink ? props.post.videolink : null}
                      alt="video"
                    />
                  </video>
                  <span className="video-play-button">
                          <span className="video-play-inner">
                            <button>
                              <svg xmlns="http://www.w3.org/2000/svg" width="10.802" height="16.203"
                                   viewBox="0 0 10.802 16.203">
                                <defs></defs>
                                <path className="a" d="M8.1,0l8.1,10.8H0Z"
                                      transform="translate(10.802) rotate(90)"></path>
                              </svg>
                            </button>
                          </span>
                        </span>
                </a>
              )}
            </div>
          </div>
          {!!getAuthToken() && (
            <div className="shareLinkBox">
              {props.post.isPostLiked ? (
                <>
                  <Link
                    to={props.isTimeline ? "" : "#"}
                    className="fireRed"
                    onClick={(e) => {
                      if (!props.isTimeline) props.handleUnlike(e, props.post);
                    }}
                  >
                    <span>{props.post.totalLikeCount}</span>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to={props.isTimeline ? "" : "#"}
                    className="fireGray"
                    onClick={(e) => {
                      if (!props.isTimeline) props.handleLike(e, props.post);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.713"
                      height="17.03"
                      viewBox="0 0 12.713 17.03"
                    >
                      <g transform="translate(-10.806 -1.738)">
                        <path
                          className="a"
                          d="M17.552,18.518a5.306,5.306,0,0,1-1.831-.319h0a6.658,6.658,0,0,1-4.171-3.61,7.556,7.556,0,0,1,.26-5.561.236.236,0,0,1,.44.038,3.108,3.108,0,0,0,.967,1.377,7.765,7.765,0,0,1,3.009-4.423c1.526-1.221.28-3.648.267-3.672a.236.236,0,0,1,.357-.293c4.2,3.409,3.96,6.843,3.621,8.254a2.289,2.289,0,0,0,.856-2.117.236.236,0,0,1,.429-.165,10.69,10.69,0,0,1,1.1,2.27,6.448,6.448,0,0,1-1.107,6.221l-.05.062A5.356,5.356,0,0,1,17.552,18.518Zm-1.669-.762a4.835,4.835,0,0,0,1.669.29,4.886,4.886,0,0,0,3.784-1.766l.047-.059a5.979,5.979,0,0,0,1.029-5.767A10.7,10.7,0,0,0,21.778,9a3.174,3.174,0,0,1-1.634,2.088.236.236,0,0,1-.314-.314c.075-.16,1.7-3.8-2.576-7.748A3.051,3.051,0,0,1,16.505,6.4a7.274,7.274,0,0,0-2.887,4.506.236.236,0,0,1-.367.165,4.379,4.379,0,0,1-1.22-1.316,6.869,6.869,0,0,0-.044,4.658,6.211,6.211,0,0,0,3.9,3.342Z"
                          transform="translate(0)"
                        />
                        <path
                          className="a"
                          d="M28.428,41.068a.236.236,0,0,1-.152-.055A4.4,4.4,0,0,1,27.41,35.2a.236.236,0,0,1,.432.117,1.582,1.582,0,0,0,.368.989,4.8,4.8,0,0,1,2.08-4.636.236.236,0,0,1,.328.312,1.638,1.638,0,0,0-.19.8,1.732,1.732,0,0,0,.3.905l.094.144a14.793,14.793,0,0,0,1.015,1.336,4.159,4.159,0,0,1,.67,1.14,3.9,3.9,0,0,1-.869,4.379.236.236,0,1,1-.321-.346,3.362,3.362,0,0,0,.76-3.837.227.227,0,0,1-.011-.026,3.71,3.71,0,0,0-.593-1.009,15.225,15.225,0,0,1-1.047-1.379l-.094-.144a2.2,2.2,0,0,1-.373-1.154,2.123,2.123,0,0,1,.012-.275,4.348,4.348,0,0,0-1.206,4.247.236.236,0,0,1-.3.274,1.611,1.611,0,0,1-.958-1.011,3.739,3.739,0,0,0,1.076,4.623.236.236,0,0,1-.152.416Z"
                          transform="translate(-11.882 -22.646)"
                        />
                      </g>
                    </svg>
                    <span>
                      {props.post.totalLikeCount
                        ? props.post.totalLikeCount
                        : "Fire"}
                    </span>
                  </Link>
                </>
              )}
              {/*{!props.isTimeline == false ? "" :*/}
              <Link
                to={props.isTimeline ? "" : "#"}
                className="shareLink"
                onClick={(e) => {
                  if (!props.isTimeline) props.handleModal(e, props.post);
                }}
              >
                <span>Share</span>
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.804"
                    height="14"
                    viewBox="0 0 16.804 14"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <path
                      className="a"
                      d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z"
                      transform="translate(-4.502 -6.749)"
                    />
                  </svg>
                </span>
              </Link>
              {/*}*/}
            </div>
          )}
        </div>
      ) : null}
      {/*//this popup is for iframe video change july 2022 client change*/}
      <VideoPop
       setIsTestSingle={()=>setIsVidPop(false)}
       vidID={vidID}
       isTestSingle={isVidPop}
       isToken={ props?.isAuth == 1 ? 1 : 0 }
       isLink={ isLink }
      />
    </>
  );
}
