import React from "react";
import { Link } from "react-router-dom";
import "../../../../../assets/css/stylesheet.scss";
import { getUserInfo } from "../../../../../services/authService";
import "../assets/css/onBoardCollegeRecruit.scss";

export default function AthleteSkip(props) {
  return (
    <div>
      <div className="popUpAthleteSection">
        <div className="popUpAthleteBlock">
          <div className="closeBtn">
            <button onClick={props.onClose} style={{cursor: 'pointer'}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.804"
                height="15.803"
                viewBox="0 0 15.804 15.803"
              >
                <g className="a" transform="translate(-6.776 -6.776)">
                  <path
                    d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                    transform="translate(0 0)"
                  />
                </g>
              </svg>
            </button>
          </div>
          <h1 className="heading">Trust me {getUserInfo() ? getUserInfo().FirstName : ''} it's worth it!</h1>
          <p className="info">
            Filling out your profile helps college coaches & scouts find you.
            Are you sure you want to skip this?
          </p>
          <div className="profileBtn">
            <button onClick={props.onClose}>No, Let's Build My Profile</button>
          </div>
          <p className="skip">
            <Link to="#" onClick={props.nextRoute}>
              Yes, Skip for Now
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
