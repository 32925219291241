import { useEffect, useState, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import OwlCarousel from "react-owl-carousel";
import { Dialog } from "@mui/material";
import { TreeView } from '@mui/lab';
import TreeItem from '@mui/lab/TreeItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useLocation } from "react-router-dom";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";

import { profileStatsDataStart } from "../../../../../../redux/athlete/profile/profileStatsData";
import { getUserInfo } from "../../../../../../services/authService";
import history from "../../../../../../services/history";
import { UploadedVideoReview } from "../../editUserProfile/stats/Components";
import { ProfileStatsGraphModal, WebLinkProfileModal } from './profileStatGraph';
import { profileURLData } from "../../../../../common/getProfileURLData";

const optionCarousel = {
  className: "stats-feature-list owl-theme",
  margin: 10,
  nav: true,
  dots: false,
  responsive: {
    0: {
      items: 1
    },
    
    680: {
      items: 2
    },
    992: {
      items: 3
    }
  }
};

const optionCarouselMobile = {
  className: "owl-carousel owl-theme",
  loop: true,
  autoPlay: false,
  nav: true,
  dots: false,
  items: 1
};

const EditProfileButton = ({ route }) => {
  return (
    <a
      className="edit-link"
      style={{ cursor: "pointer", color: "#c0321f" }}
      onClick={() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
          history.push(route)
        }, 1000)
      }}
    >
      Edit
    </a>
  );
}

export function ProfileStats(props) {
  const location = useLocation()
  const about = useDispatch();
  const fakeInput = useRef(null);
  const [statsList, setStatsList] = useState(null);
  const [opeMenuItem, setMenuItem] = useState(false);
  const [showVideoReviewPopup, setShowVideoReviewPopup] = useState(false);
  const [showGraphPopup, setShowGraphPopup] = useState(false);
  const [videoPopUpData, setVideoPopUpData] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [statmatrixId, setStatMatrixId] = useState(0);
  const [graphCardData, setGraphCardData] = useState([]);
  const [subCardClass, setSubCardClass] = useState([]);
  const [activeClass, setActiveClass] = useState('Personal Records');
  const [activeSubClass, setActiveSubClass] = useState('Overview');
  const [subMenuData, setSubMenuData] = useState(false);
  const [cardTitle, setCardTitle] = useState(undefined);
  const [isWebLinkOpen, setIsWebLinkOpen] = useState(false);
  const [webLinkData, setWebLinkData] = useState(null);
  const [mobileNavMenu, setMobileNavMenu] = useState([]);
  const [tabSel, setTabSel] = useState(1);
  const cardOverViewData = useSelector(profileAbout => profileAbout?.athlete?.editUserProfileData?.getProfileOverviewCardStat?.data?.profileOverviewCardStat ?? null);
  const cardStatData = useSelector(profileAbout => profileAbout?.athlete?.editUserProfileData?.getprofileCardStat?.data?.profileCardStat ?? null);
  const profileStat = useSelector(profileAbout => profileAbout?.athlete?.editUserProfileData?.getprofileTimelineStat?.data?.profileTimelineStat ?? null);
  const state = useSelector(state => state);
  const store = useSelector(store => store);

  const pubViewID = JSON.parse(localStorage.getItem('pubViewID'));
  const Athid = profileURLData(location?.pathname, 'athlete_id');
  const isToken = profileURLData(location.pathname, 'token');

  useEffect(() => {
    const athleteStatusID = !!Athid ? Athid : !!state?.athlete?.dashboard?.home?.athleteProfileStatus?.status ?
      +pubViewID || 0 : !!getUserInfo() ? getUserInfo().id : +pubViewID
    about({ type: "GET_STATS_TIMELINE_DATA_START", payload: { athleteId: athleteStatusID, isAccessToken: isToken } })
    fakeInput.current.focus();
    fakeInput.current.select();
  }, [pubViewID, store?.athlete?.dashboard?.home?.athleteProfileStatus?.status, Athid]);

  useEffect(() => {
    const athleteStatusID = !!Athid ? Athid : !!state?.athlete?.dashboard?.home?.athleteProfileStatus?.status ?
      +pubViewID || 0 : !!getUserInfo() ? getUserInfo().id : +pubViewID
    if (statmatrixId === 0) {
      about({ type: "GET_STATS_OVERVIEW_CARD_DATA_START", payload: { athleteId: athleteStatusID, isAccessToken: isToken } })
    }
    else {
      about({ type: "GET_STATS_CARD_DATA_START", payload: { athleteId: athleteStatusID, isAccessToken: isToken, statmatrixId } })
    }
    // about({type:"GET_STATS_SUB_CARD_DATA_START", payload:{athleteId: athleteStatusID, isAccessToken: tokenStatus, statmatrixmainheaderId: statmatrixId}})
  }, [pubViewID, store?.athlete?.dashboard?.home?.athleteProfileStatus?.status, statmatrixId]);

  useEffect(() => {
    if (statmatrixId == 0 && cardOverViewData?.length > 0) {
      setGraphCardData(cardOverViewData);
    }
    else if(statmatrixId == 0 && !cardOverViewData?.length > 0){
      setGraphCardData([])
    }
    else {
      setGraphCardData([])
    }
  }, [statmatrixId, cardOverViewData?.length, cardStatData?.length])

  useEffect(()=>{
    if (cardStatData?.length > 0){
      const newstate = JSON.parse(JSON.stringify(cardStatData))
      setGraphCardData(newstate);
    }
  },[cardStatData])

  useEffect(()=>{
    if (profileStat?.length > 0){
      getCardDataMobile(profileStat)
    }
  },[profileStat, store?.athlete?.dashboard?.home?.athleteProfileStatus?.status,statmatrixId, pubViewID])

  const openPersonalRecords = value => {
    setActiveClass(value);
    setSubMenuData(false);
    setCardTitle(undefined);
    setMenuItem(!opeMenuItem);
    if (opeMenuItem) {
      setStatsList([]);
    }
    else {
      setStatsList(profileStat);
    }
    setSubCardClass([])
  };

  const openSubDataPersonalRecords = (value, id = 1) => {
    if (value?.statmatrixid == 0 && value?.isPop){
      swal({text: "Coming Soon", icon: "", buttons: {confirm: { text: "Ok" }}})
      return
    }
    setActiveSubClass(value.statmatrixname)
    setStatMatrixId(value.statmatrixid);
    setTabSel(id)
  };

  const openDataPersonalRecords = value => {
    setSubCardClass([])
    setSubMenuData(false);
    setCardTitle(undefined)
    if (value == 0) {
      setActiveSubClass("Overview");
      setStatMatrixId(0);
    }
    else {
      setActiveSubClass(value.name)
      setStatMatrixId(value.id)
    }
  };

  const handleDialogOpen = (value) => {
    setActiveClass(value);
    setSubMenuData(false);
    setCardTitle(undefined)
    const confirmation = swal({
      title: "",
      text: "Coming Soon",
      icon: "",
      buttons: {
        confirm: { text: "Ok" }
      },
    })
    confirmation.then(async (value) => {
      // handleModalDialog();
    });
  };

  const getCardDataMobile = (data) =>{
    if (data?.length > 0){
      const finalArr = [{statmatrixid: 0, statmatrixname : "Overview"}]
      data?.map((outSubNav, idx)=>{
       return  outSubNav?.submenu?.length > 0 ?
           outSubNav?.submenu?.map(innerSub => finalArr?.push(innerSub))
            :
            finalArr?.push(outSubNav)
      })
      finalArr?.push?.apply(finalArr, [
          {statmatrixid : 0, isPop: true,statmatrixname : "Stats"},
          {statmatrixid : 0, isPop: true,statmatrixname : "Metrics"}
      ])
      setMobileNavMenu(JSON.parse(JSON.stringify(finalArr)))
    }
  };

  // const handleExpandCollapse = (e, id) => {
  //   e.preventDefault();
  //   const expandFieldsT = expandFields;
  //   expandFieldsT[id] = !expandFieldsT[id];
  //   setExpandFields({ ...expandFieldsT });
  // };

  const subMenuChange = (item) => {
    setSubMenuData(!subMenuData)
    if(!subMenuData)
    {
      setActiveSubClass(item.name);
      setCardTitle(undefined)
    }
      else
    {
      setActiveSubClass("")
      setCardTitle(item.name)
    }
  };

  const onOpenWebLink = (data, type) =>{
    if (type == "open"){
      setWebLinkData(data)
      setIsWebLinkOpen(true)
    }else{
      setIsWebLinkOpen(false)
      setWebLinkData(null)
    }
  };

  return (
    <div className="TimelineData">
      <input className="fakeInput" type="text" ref={fakeInput} />
      <div className="item pt-0 pb-0 mb-0">
        <div className="about-sec-wrap">
          <div className="sidebar statsSidebar-hide-mobile" id="myScrollspy">
            <TreeView className="menu-links nav">
              <TreeItem nodeId="10" className={`nav-link ${activeClass === "Personal Records" ? "active" : ""}`} key="10" label={
                <ListItem button component="a" onClick={(e) => {
                  openPersonalRecords("Personal Records");
                }}
                >
                  <ListItemText primary={<><span>Personal Records</span>
                    <span className={`icon ${activeClass === "Personal Records" && opeMenuItem ? "collapsed" : ""}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      width="18px"
                      height="18px"
                      viewBox="0 0 451.847 451.847"
                      space="preserve"
                    >
                      <g>
                        <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                      </g>
                    </svg>
                  </span>
                  </>
                  } />
                </ListItem>}>
                <TreeItem nodeId="0" key="0" className={`nav-link ${activeSubClass === "Overview" ? "active" : ""}`} style={{ cursor: "pointer" }} label={
                  <ListItem button component="a" onClick={() => { openDataPersonalRecords(0) }}>
                    <ListItemText primary="Overview" />
                  </ListItem>}>
                </TreeItem>
                {
                  statsList?.length > 0 ?
                    statsList?.map(item => {
                      return <TreeItem nodeId={(item.id).toString()} key={item.id} className={`nav-link ${activeSubClass === item.name || subCardClass?.includes(item.name) ? "active" : ""} ${item?.submenu?.length > 1 ? "" : "cursorClass"}`} label={
                        <ListItem button component="a" onClick={() => { return item?.submenu?.length < 2 ? openDataPersonalRecords(item) : subMenuChange(item) }}>
                          <ListItemText primary={item?.submenu?.length > 1 ? <>
                            <span>{item.name}</span>
                            <span className={`icon ${activeSubClass === item.name ? "" : "collapsed"}`}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                id="Capa_1"
                                x="0px"
                                y="0px"
                                width="18px"
                                height="18px"
                                viewBox="0 0 451.847 451.847"
                                space="preserve"
                              >
                                <g>
                                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                                </g>
                              </svg>
                            </span>
                          </> : item.name} />
                        </ListItem>}>
                        {
                          item?.submenu?.length > 1 ? item?.submenu?.map(itemData => {
                            return <>
                              <TreeItem nodeId={(itemData.statmatrixid).toString()} key={itemData.statmatrixid} className={`nav-link ${activeSubClass === itemData.statmatrixname ? "active" : ""}`} style={{ cursor: "pointer" }} label={
                                <ListItem button component="a" onClick={() => { openSubDataPersonalRecords(itemData) }}>
                                  <ListItemText primary={itemData.statmatrixname} />
                                </ListItem>}>
                              </TreeItem>
                            </>
                          })
                            : ""
                        }
                      </TreeItem>
                    })
                    : ""
                }
              </TreeItem>
              <TreeItem nodeId="20" key="20" className={`nav-link ${activeClass === "Stats" ? "active" : ""}`} style={{ cursor: "pointer" }} label={
                <ListItem button component="a" onClick={() => { handleDialogOpen('Stats') }}>
                  <ListItemText primary="Stats" />
                </ListItem>}>
              </TreeItem>
              <TreeItem nodeId="30" key="30" className={`nav-link ${activeClass === "Matrics" ? "active" : ""}`} style={{ cursor: "pointer" }} label={
                <ListItem button component="a" onClick={() => { handleDialogOpen('Matrics') }}>
                  <ListItemText primary="Metrics" />
                </ListItem>}>
              </TreeItem>
            </TreeView>
          </div>
          <div className="sidebar statsSidebar-show-mobile" id="myScrollspy">
            <ul className="menu-links nav">
              {mobileNavMenu?.length > 0 &&
              <OwlCarousel
                  {...optionCarouselMobile}
                  startPosition={tabSel - 1}
              >
                {mobileNavMenu?.length > 0 &&
                mobileNavMenu?.map((mobStatsNav, idxStatsNav) => {
                  return <li><a
                      onClick={() => openSubDataPersonalRecords(mobStatsNav, idxStatsNav + 1)}
                      className={`nav-link ${activeSubClass == mobStatsNav?.statmatrixname ? "active" : ""}`}
                      href={void 0}>{mobStatsNav?.statmatrixname}</a></li>
                })
                }
              </OwlCarousel>
              }
              </ul>
          </div>
          <div className="about-detail-box">
            <div className="common-item-box">
              <h3 className="title" style={{ display: "flex", justifyContent: "space-between" }}>{activeSubClass || cardTitle}
                {props.isPublicView ? "" : <EditProfileButton route={'/Stats'} />}
              </h3>
            
            <div className="stats-box-item">
              <div className="tab-content">
                <div className="tab-pane active">
                  {graphCardData?.length > 0 ? (
                    <OwlCarousel
                        {...optionCarousel}
                    >
                      {graphCardData.map((item, index) => (
                        <div
                          className="item stats-timeline-item"
                          key={index}
                        >
                          <div className="detial-box">
                            <div className="inner-box">
                              {item.verified === 2 && item?.verifiedbrandlogo ? (
                                <h4>{item?.verifiedbrandlogo}</h4>
                              ) : null}
                              <h5>
                                <span className="text-with-tooltip">
                                  {item?.statsmatrixId == 2 ? (item?.jumpinfeet || item?.jumpininch) ? `${item?.jumpinfeet}' ${item?.jumpininch}"` : "" : item.result}
                                  {item.verified === 1 ? (
                                  <span className="icon-box custom-tooltip">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.449"
                                      height="16.117"
                                      viewBox="0 0 14.449 16.117"
                                    >
                                      <g transform="translate(-2.872 -1.125)">
                                        <path
                                          className="a"
                                          d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                                          transform="translate(0)"
                                        />
                                      </g>
                                    </svg>
                                    <span class="tooltip-data">Coach Verified</span>
                                  </span>
                                ) : item.verified === 2 ? (
                                  <span className="icon-box red custom-tooltip">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.449"
                                      height="16.117"
                                      viewBox="0 0 14.449 16.117"
                                    >
                                      <g transform="translate(-2.872 -1.125)">
                                        <path
                                          className="a"
                                          d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                                          transform="translate(0)"
                                        />
                                      </g>
                                    </svg>
                                    <span class="tooltip-data">SportsForce Verified</span>
                                  </span>
                                ) : item.verified === 3 ? (
                                  <span>
                                    <span className="icon-box custom-tooltip">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.449"
                                        height="16.117"
                                        viewBox="0 0 14.449 16.117"
                                      >
                                        <g transform="translate(-2.872 -1.125)">
                                          <path
                                            className="a"
                                            d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                                            transform="translate(0)"
                                          />
                                        </g>
                                      </svg>
                                      <span class="tooltip-data">Coach Verified</span>
                                    </span>
                                    <span className="icon-box red custom-tooltip">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.449"
                                        height="16.117"
                                        viewBox="0 0 14.449 16.117"
                                      >
                                        <g transform="translate(-2.872 -1.125)">
                                          <path
                                            className="a"
                                            d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                                            transform="translate(0)"
                                          />
                                        </g>
                                      </svg>
                                      <span class="tooltip-data">SportsForce Verified</span>
                                    </span>
                                  </span>
                                ) : null}
                                </span>                                
                              </h5>
                              <h6>{item?.tabName }</h6>
                              <div className="button-box">
                                <button
                                  disabled={!item.videoname}
                                  style={!item.videoname ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                  className={`video-btn ${item.videoname && item?.ispublic == 1 ? "red" : ""
                                    }`}
                                  onClick={() => !!item.videoname && item?.ispublic == 1 && (
                                    setShowVideoReviewPopup(true) ||
                                    setVideoPopUpData(item)
                                  )}
                                >
                                 <svg id="Group_1127" data-name="Group 1127" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <g id="Group_1126" data-name="Group 1126">
                                      <path id="Path_2126" data-name="Path 2126" d="M16.313,0H1.688A1.688,1.688,0,0,0,0,1.688V16.313A1.688,1.688,0,0,0,1.688,18H16.313A1.688,1.688,0,0,0,18,16.313V1.688A1.688,1.688,0,0,0,16.313,0Zm.563,11.019L14.462,8.606a.562.562,0,0,0-.793,0l-2.981,2.975L7.149,8.044a.562.562,0,0,0-.793,0L1.125,13.269V1.688a.563.563,0,0,1,.563-.562H16.313a.563.563,0,0,1,.563.563Z" fill="#3e3e3e"/>
                                      <path id="Path_2127" data-name="Path 2127" d="M226.373,80a2.373,2.373,0,1,0,2.373,2.373A2.373,2.373,0,0,0,226.373,80Zm0,3.56a1.187,1.187,0,1,1,1.187-1.187,1.187,1.187,0,0,1-1.187,1.187Z" transform="translate(-216.269 -77.239)" fill="#3e3e3e"/>
                                    </g>
                                  </svg>
                                </button>
                                <button
                                  className="link-btn"
                                  style={{ cursor: !item.totalCount ? "not-allowed" : "pointer" }}
                                  disabled={!item.totalCount}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    item?.FullstatsData?.length > 0 && (
                                      setShowGraphPopup(true) ||
                                      setGraphData(item))
                                  }}
                                >
                                  {item.totalCount ? (
                                    <span className="number-of-value">{item.totalCount}</span>
                                  ) : null}
                                  <svg id="Component_30_2" data-name="Component 30 – 2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <g id="Rectangle_1230" data-name="Rectangle 1230" fill="#fff" stroke="#3e3e3e" stroke-width="1.5">
                                      <rect width="18" height="18" rx="3" stroke="none"/>
                                      <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="2.25" fill="none"/>
                                    </g>
                                    <g id="stats-chart-sharp" transform="translate(3.185 3.742)">
                                      <path id="Path_2181" data-name="Path 2181" d="M6.48,26.645h-3.1v-5.27h3.1Z" transform="translate(-3.375 -16.012)" fill="#3e3e3e"/>
                                      <path id="Path_2182" data-name="Path 2182" d="M22.23,22.53h-3.1v-7.9h3.1Z" transform="translate(-10.431 -11.897)" fill="#3e3e3e"/>
                                      <path id="Path_2184" data-name="Path 2184" d="M14.355,11.757h-3.1V1.125h3.1Z" transform="translate(-6.903 -1.125)" fill="#3e3e3e"/>
                                    </g>
                                  </svg>
                                </button>
                                <button
                                    className={!!item?.weblink ? "link-btn red" : "link-btn"}
                                    style={{ cursor: !!item?.weblink ? "pointer" : "not-allowed"}}
                                    disabled={!!item?.weblink ? false : true}
                                    onClick={(e) =>onOpenWebLink(item, 'open')}
                                >
                                  <svg id="link" xmlns="http://www.w3.org/2000/svg" width="16" height="16.002" viewBox="0 0 16 16.002">
                                    <g id="Group_2319" data-name="Group 2319" transform="translate(0 5.064)">
                                      <g id="Group_2318" data-name="Group 2318" transform="translate(0)">
                                        {!!item?.weblink ?
                                            <>
                                              <path fill={"#00B9FF"} id="Path_2307" data-name="Path 2307"
                                                    d="M9.873,165.715a3.655,3.655,0,0,0-.631-.508,3.761,3.761,0,0,0-4.687.508l-3.417,3.419a3.761,3.761,0,0,0,5.316,5.321l2.82-2.82a.269.269,0,0,0-.191-.459H8.976a4.522,4.522,0,0,1-1.719-.333.269.269,0,0,0-.293.059l-2.028,2.031a1.613,1.613,0,1,1-2.281-2.281l3.43-3.428a1.612,1.612,0,0,1,2.278,0,1.1,1.1,0,0,0,1.51,0,1.064,1.064,0,0,0,0-1.51Z"
                                                    transform="translate(-0.039 -164.614)"/>
                                              <path fill={"#00B9FF"} id="Path_2307" data-name="Path 2307"
                                                    d="M9.873,165.715a3.655,3.655,0,0,0-.631-.508,3.761,3.761,0,0,0-4.687.508l-3.417,3.419a3.761,3.761,0,0,0,5.316,5.321l2.82-2.82a.269.269,0,0,0-.191-.459H8.976a4.522,4.522,0,0,1-1.719-.333.269.269,0,0,0-.293.059l-2.028,2.031a1.613,1.613,0,1,1-2.281-2.281l3.43-3.428a1.612,1.612,0,0,1,2.278,0,1.1,1.1,0,0,0,1.51,0,1.064,1.064,0,0,0,0-1.51Z"
                                                    transform="translate(-0.039 -164.614)"/>
                                            </>
                                            :
                                            <>
                                              <path fill={"#3e3e3e"} id="Path_2307" data-name="Path 2307"
                                                    d="M9.873,165.715a3.655,3.655,0,0,0-.631-.508,3.761,3.761,0,0,0-4.687.508l-3.417,3.419a3.761,3.761,0,0,0,5.316,5.321l2.82-2.82a.269.269,0,0,0-.191-.459H8.976a4.522,4.522,0,0,1-1.719-.333.269.269,0,0,0-.293.059l-2.028,2.031a1.613,1.613,0,1,1-2.281-2.281l3.43-3.428a1.612,1.612,0,0,1,2.278,0,1.1,1.1,0,0,0,1.51,0,1.064,1.064,0,0,0,0-1.51Z"
                                                    transform="translate(-0.039 -164.614)"/>
                                              <path fill={"#3e3e3e"} id="Path_2307" data-name="Path 2307"
                                                    d="M9.873,165.715a3.655,3.655,0,0,0-.631-.508,3.761,3.761,0,0,0-4.687.508l-3.417,3.419a3.761,3.761,0,0,0,5.316,5.321l2.82-2.82a.269.269,0,0,0-.191-.459H8.976a4.522,4.522,0,0,1-1.719-.333.269.269,0,0,0-.293.059l-2.028,2.031a1.613,1.613,0,1,1-2.281-2.281l3.43-3.428a1.612,1.612,0,0,1,2.278,0,1.1,1.1,0,0,0,1.51,0,1.064,1.064,0,0,0,0-1.51Z"
                                                    transform="translate(-0.039 -164.614)"/>
                                            </>
                                        }
                                      </g>
                                    </g>
                                    <g id="Group_2321" data-name="Group 2321" transform="translate(5.847)">
                                      <g id="Group_2320" data-name="Group 2320" transform="translate(0 0)">
                                        {!!item?.weblink ?
                                            <path fill={"#00B9FF"} id="Path_2308" data-name="Path 2308"
                                                  d="M198.554,1.1a3.761,3.761,0,0,0-5.319,0l-2.818,2.815a.27.27,0,0,0,.2.459h.1a4.513,4.513,0,0,1,1.716.336.269.269,0,0,0,.293-.059l2.023-2.02a1.613,1.613,0,0,1,2.281,2.281l-2.52,2.517-.022.024-.881.876a1.612,1.612,0,0,1-2.278,0,1.1,1.1,0,0,0-1.51,0,1.071,1.071,0,0,0,0,1.515,3.728,3.728,0,0,0,1.074.752c.056.027.113.048.169.073s.115.043.172.064.115.04.172.056l.159.043c.107.027.215.048.325.067a3.739,3.739,0,0,0,.4.038h.2l.161-.019c.059,0,.121-.016.191-.016h.091l.185-.027.086-.016.156-.032h.03a3.76,3.76,0,0,0,1.743-.989l3.42-3.42A3.761,3.761,0,0,0,198.554,1.1Z"
                                                  transform="translate(-189.502 0)"/>
                                            :
                                            <path fill={"#3e3e3e"} id="Path_2308" data-name="Path 2308"
                                                  d="M198.554,1.1a3.761,3.761,0,0,0-5.319,0l-2.818,2.815a.27.27,0,0,0,.2.459h.1a4.513,4.513,0,0,1,1.716.336.269.269,0,0,0,.293-.059l2.023-2.02a1.613,1.613,0,0,1,2.281,2.281l-2.52,2.517-.022.024-.881.876a1.612,1.612,0,0,1-2.278,0,1.1,1.1,0,0,0-1.51,0,1.071,1.071,0,0,0,0,1.515,3.728,3.728,0,0,0,1.074.752c.056.027.113.048.169.073s.115.043.172.064.115.04.172.056l.159.043c.107.027.215.048.325.067a3.739,3.739,0,0,0,.4.038h.2l.161-.019c.059,0,.121-.016.191-.016h.091l.185-.027.086-.016.156-.032h.03a3.76,3.76,0,0,0,1.743-.989l3.42-3.42A3.761,3.761,0,0,0,198.554,1.1Z"
                                                  transform="translate(-189.502 0)"/>
                                        }
                                      </g>
                                    </g>
                                  </svg>

                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  ) : (
                    <div style={{ textAlign: "center" }}>No stats data yet</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <Dialog open={showVideoReviewPopup}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setShowVideoReviewPopup(false)
                }
              }}
              className="matDialogCustom"
      >
        <UploadedVideoReview
          onClose={() => setShowVideoReviewPopup(false)}
          metricData={videoPopUpData}
          isAuth = { isToken }
          page = { "Show_Stats_Profile" }
        />
      </Dialog>
      <Dialog open={showGraphPopup}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setShowGraphPopup(false)
                }
              }}
              className="matDialogCustom"
      >
        <ProfileStatsGraphModal
          onClose={() => setShowGraphPopup(false)}
          tabData={graphData}
        />
      </Dialog>
      <Dialog open={isWebLinkOpen}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onOpenWebLink(null, 'close')
                }
              }}
              className="matDialogCustom"
      >
          <WebLinkProfileModal
          onClose={()=>onOpenWebLink(null, 'close')}
          webLinkData={webLinkData}
          ></WebLinkProfileModal>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profileStatsData: state.athlete.profile.profileStatsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    profileStatsDataStart: (data) => dispatch(profileStatsDataStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileStats);