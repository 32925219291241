import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { IsValidTwitterHandle } from "../../../../../utils/validationUtils";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardTwitter.css";
import "../assets/css/onBoardTwitter.css.map";
// import "../assets/css/onBoardSteeper.scss";
import { Link } from "react-router-dom";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { connect } from "react-redux";
import BackdropLoader from "../../../../common/Loader";
import { getUserInfo } from "../../../../../services/authService";
import { getOnboardingQuery, saveOnboardingQuery } from "../OnboardingQuery";
import usePrevious from "../../../../../utils/usePrevious";
import { InputAdornment } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import Stepper from "../Stepper";

import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import { Dialog } from "@mui/material";

const stepperData = [
    { className: "passed", link: "/coach-onboarding/Profile" },
    { className: "active", link: "/coach-onboarding/Twitter" },
    { className: "" }
];

const Twitter = (props) => {
  const [fields, setFields] = React.useState({
    useTwitter: "yes",
  });
  const [errors, setErrors] = React.useState({});
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    props.history.push("/coach-onboarding/Mobile");
  };
  useEffect(() => {
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = fields;
        const {
          id,
          usetwitterforschool,
          wanttolearnsocialmediaforsportgoal,
          twitteraccountname,
        } = props.onboarding.data;
        fieldT["id"] = id;
        fieldT["useTwitter"] =
          usetwitterforschool !== null
            ? usetwitterforschool === 1
              ? "yes"
              : "no"
            : "yes";
        fieldT["twitterhandle"] = twitteraccountname
          ? twitteraccountname.replace("@", "")
          : null;

        fieldT["learnTwitter"] =
          wanttolearnsocialmediaforsportgoal !== null
            ? wanttolearnsocialmediaforsportgoal === 1
              ? "yes"
              : "no"
            : "yes";
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);

  const handleRadioChange = (field, value) => {
    let fieldsT = fields;
    fieldsT[field] = value;
    setFields({ ...fieldsT });
  };

  const handleSubmit = () => {
    const { formIsValid, updatedValue } = handleValidation();
    if (formIsValid) {
      props.saveOnboardingStart({
        query: saveOnboardingQuery,
        variables: {
          obj: {
            id: fields.id ? fields.id : 0,
            coachid: getUserInfo().id,
            usetwitterforschool: fields.useTwitter === "yes" ? 1 : 0,
            wanttolearnsocialmediaforsportgoal: fields.learnTwitter
              ? fields.learnTwitter === "yes"
                ? 1
                : 0
              : null,
            twitteraccountname: fields.twitterhandle
              ? `${fields.twitterhandle}`
              : null,
          },
        },
        nextScreen: routeNextPage,
      });
    }
  };
  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldvalue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldvalue;

    if (fields["useTwitter"] === "yes") {
      if ("learnTwitter" in fields) {
        delete fields["learnTwitter"];
      }
      if (fieldname === undefined || fieldname === "twitterhandle") {
        if (fieldname === undefined) {
          fieldvalue = fields["twitterhandle"] ? fields["twitterhandle"] : "";
          updatedValue = fieldvalue;
        }

        delete errors["twitterhandle"];
        if (!updatedValue) {
          formIsValid = false;
          errorsT["twitterhandle"] = "Twitter handle is required";
        } else if (!IsValidTwitterHandle(updatedValue)) {
          formIsValid = false;
          errorsT["twitterhandle"] =
            "Please enter Twitter Handle in a correct format";
        }
      }
    } else {
      if ("twitterhandle" in fields) {
        delete fields["twitterhandle"];
      }
    }

    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };
  const handleChange = (field, e) => {
    const { formIsValid, updatedValue } = handleValidation(field, e);
    e.preventDefault();
    let fieldsT = fields;
    fieldsT[field] = updatedValue;
    setFields({ ...fieldsT });
  };

  return (
    <div>
      <BackdropLoader open={props.onboarding.loading} />
      <div className="onBoardTwitterSection">
        <div className="twitterBlock">
          <div className="stepper">
            <Stepper stepperName="twitter" stepperData={stepperData} />
          </div>
          <div className="formSection">
          <div className="fieldBox mt-0">
                        <label>What's your twitter handle?</label>
                        <div className="twitter-itemfield">
                            {/* <span>@</span> */}
                            <TextField
                    fullWidth
                    variant="outlined"
                    id="twitterhandle"
                    placeholder="Enter handle @"
                    type="text"
                    name="twitterhandle"
                    value={fields.twitterhandle || ""}
                    onChange={(e) => handleChange("twitterhandle", e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AlternateEmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                            {/* <input type="text" name="twitterhandle" id="twitterhandle"
                             placeholder="Enter Handle"/> */}
                        </div>
                        <div style={{ color: "red" }}>{errors["twitterhandle"]}</div>
                    </div>
                   
           

           
        
            <p className="skip">
              Don't know?{" "}
              <span className="sikpBtnBox">
                <Link className="skipit-btn" to="#" onClick={handleClickOpen}>
                  Skip it
                </Link>
              </span>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="#" 
             onClick={handleSubmit}
            // onClick={routeNextPage}
            >
              Next
            </Link>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    onboarding: state.athlete.onBoarding.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Twitter);
