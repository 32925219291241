import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    CATEGORIES_LIST_START: "CATEGORIES_LIST_START",
    CATEGORIES_LIST_SUCCESS: "CATEGORIES_LIST_SUCCESS",
    CATEGORIES_LIST_FAILED: "CATEGORIES_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const categoriesListStart = (data) => ({
    type: actionType.CATEGORIES_LIST_START,
    payload: data,
});

export const categoriesListSuccess = (data) => ({
    type: actionType.CATEGORIES_LIST_SUCCESS,
    payload: data,
});

export const categoriesListFailed = (data) => ({
    type: actionType.CATEGORIES_LIST_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function categoriesListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.CATEGORIES_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.CATEGORIES_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* categoriesListSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            const allCATEGORIES =  response.data
            yield put(categoriesListSuccess(allCATEGORIES));
        } else {
            messagePopup("", "Organizations List failed", "error");
            yield put(categoriesListFailed("Organizations List failed"));
        }
    } catch (error) {
        messagePopup("", "Organizations List failed", "error");
        yield put(categoriesListFailed("Organizations List failed"));
    }
}

export function* watchCategoriesListSaga() {
    yield takeEvery(actionType.CATEGORIES_LIST_START, categoriesListSaga);
}
