import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  UPGRADE_PLAN_LIST_START: "UPGRADE_PLAN_LIST_START",
  UPGRADE_PLAN_SAVE_START: "UPGRADE_PLAN_SAVE_START",
  UPGRADE_PLAN_SUCCESS: "UPGRADE_PLAN_SUCCESS",
  UPGRADE_PLAN_FAILED: "UPGRADE_PLAN_FAILED",
};

// ============================================ Actions ========================================================
export const upgradePlanListStart = (data) => ({
  type: actionType.UPGRADE_PLAN_LIST_START,
  payload: data,
});

export const upgradePlansaveStart = (data) => ({
  type: actionType.UPGRADE_PLAN_SAVE_START,
  payload: data,
});

export const upgradePlanSuccess = (data) => ({
  type: actionType.UPGRADE_PLAN_SUCCESS,
  payload: data,
});

export const upgradePlanFailed = (data) => ({
  type: actionType.UPGRADE_PLAN_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function upgradePlanReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.UPGRADE_PLAN_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPGRADE_PLAN_SAVE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPGRADE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.UPGRADE_PLAN_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* upgradePlanSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        upgradePlanSuccess("")
      );
    } else {
      messagePopup("", "UPGRADE_PLAN List failed", "error");
      yield put(upgradePlanFailed("UPGRADE_PLAN List failed"));
    }
  } catch (error) {
    messagePopup("", "UPGRADE_PLAN List failed", "error");
    yield put(upgradePlanFailed("UPGRADE_PLAN List failed"));
  }
}

export function* upgradePlanListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {  
      let upgradePlanData = response.data.data.allSubscriptionplans.Subscriptionplans;
      yield put(upgradePlanSuccess(upgradePlanData));
    } else {
      messagePopup("", "UPGRADE_PLAN List failed", "error");
      yield put(upgradePlanFailed("UPGRADE_PLAN List failed"));
    }
  } catch (error) {
    messagePopup("", "UPGRADE_PLAN List failed", "error");
    yield put(upgradePlanFailed("UPGRADE_PLAN List failed"));
  }
}

export function* watchUpgradePlansSaga() {
  yield takeEvery(actionType.UPGRADE_PLAN_LIST_START, upgradePlanListSaga);
  yield takeEvery(actionType.UPGRADE_PLAN_SAVE_START, upgradePlanSaveSaga);
}
