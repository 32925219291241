import React, { useState, useEffect, memo, useCallback } from "react";
import VerificationIcon from "./assets/img/complete-verification-icon.svg";
import PhotoIcon from "./assets/img/photoIcon.svg";
import BackArrow from "./assets/img/backArrow.svg";
import NotificationIcon from "./assets/img/notification.svg";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import { connect, useSelector } from "react-redux";
import { getAuthToken, getUserInfo } from "../../../../../services/authService";
import {
  getAllProfileSectionQuery,
  getAllCheckListDataQuery,
  getAllAthletesDataQuery,
  getProfileCalculationListQuery,
} from "../../../../common/CommonQuery";
import { Link } from "react-router-dom";
import { imageDownloadUrl, imageUploadUrl } from "../../../../../config";
import { profileSectionListStart } from "../../../../../redux/common/profileSection";
import { saveProfilePhotoStart } from "../../../../../redux/athlete/dashboard/homepage/saveAthleteProfile";
import { saveOnboardingQuery } from "../../onboarding/OnboardingQuery";
import downloadPng from "../homepage/assets/img/download.png";
import { checkListPercentage } from "../../../../../utils/checkListPercentage";
import VerificationModal from "./VerificationModal";
import VerifiedModal from "../VerifiedModal";
import editProfilePopUp from "../../../../../utils/editProfilePopUp";
import { allCheckListDataStart } from "../../../../../redux/common/allCheckListData";
import { athletesDetailsStart } from "../../../../../redux/common/athletesDetails";
import { allProfileCalculationListStart } from "../../../../../redux/common/allProfileCalculationMaster";
import Modal from "react-modal";
import CameraIcon from "./assets/img/camera-icon.png";
import Cropper from "react-easy-crop";
import { Button } from "@mui/material";
import getCroppedImg from "../../onboarding/profilePhoto/CropImage";
import messagePopup from "../../../../../utils/messagePopup";
import BackdropLoader from "../../../../common/Loader";
import swal from "sweetalert";
import toastr from "toastr";
import {AutoVerify} from "../../../../common/autoVerify";
import {getAthleteSysID} from '../../../../common/getProfileURLData'

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

function LeftSideEditProfile(props) {
  let history = useHistory();
  const store = useSelector(store => store)
  const { checkListDataPrepare, getFinalPercentage } = checkListPercentage(
    props.allProfileCalculationData.data
  );

  const [data, setData] = useState({
    finalItems: {},
    finalPercentage: 0,
  });
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [loading, setLoading] = useState(false),
    [initialLetter, setInitialLetter] = useState(null),
    [isProfileChange, setIsProfileChange] = useState(false);

  const [changeState, setChangeState] = useState({
    contact: props.contact,
    allParentData: props.allParentData,
    onBoardingDetails: props.onBoardingDetails,
    allUserAcademicSchoolList: props.allUserAcademicSchoolList,
    allReferences: props.allReferences,
    latestTestScore: props.latestTestScore,
  });
  const [fields, setFields] = useState({
    user: getUserInfo() ? getUserInfo() : 0,
    profileImagename: "",
    profileImage: null,
  });
  const [showVerificationModalState, setShowVerificationModalState] = useState(false);
  const [showVerifiedModalState, setShowVerifiedModalState] = useState(false);

  const [checkListCount, setCheckListCount] = useState(0);

  const showVerificationModal = () => {
    setShowVerificationModalState(!showVerificationModalState);
  };

  useEffect(() => {
    let handler = setTimeout(() => {
      if (
        props.checkListData &&
        props.checkListData.data &&
        props.checkListData.data.allAthletes &&
        props.checkListData.data.allAthletes.Athletes &&
        props.checkListData.data.allAthletes.Athletes[0]
      ) {
        let athleteData = props.checkListData.data.allAthletes.Athletes[0];
        let checkListDataT = checkListDataPrepare(athleteData);

        let finalPercentage = getFinalPercentage(checkListDataT);

        let checklistProfileCount = 0;
        Object.entries(checkListDataT).forEach(item => {
          if(!item[1]['completed']){
            checklistProfileCount++;
          }
        })

        let verificationCount = 0;
        let reviewCount = 0;
        
        if (
          athleteData.onBoardingUserIdMap &&
          athleteData.onBoardingUserIdMap.Onboardings &&
          athleteData.onBoardingUserIdMap.Onboardings[0]
        ) {
          let onboardingData = athleteData.onBoardingUserIdMap.Onboardings[0];
          if(!onboardingData.profileimages){ verificationCount++ }
          if(!onboardingData.primaryposition){ verificationCount++ }
          //if(!athleteData.verifieduser){ verificationCount++ }

          if(!athleteData.athletephonenumber){ reviewCount++ }
          if(!onboardingData.gpascale){ reviewCount++ }
          if(!onboardingData.heightInInches){ reviewCount++ }
          if(!onboardingData.heightinfeet){ reviewCount++ }
          if(!onboardingData.weight){ reviewCount++ }
        }
        if (
          athleteData.athleteparentmappingAthleteIdmap &&
          athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings &&
          athleteData.athleteparentmappingAthleteIdmap
            .Athleteparentmappings[0] &&
          athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings[0]
            .id
        ) {
          if(!athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings[0]['parentid']){ 
            verificationCount++ 
            reviewCount++ 
          }  
        }
        if (
          athleteData.athleteathletegraduationyearmaprel &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears.length
        ) {
          if(!athleteData.athleteathletegraduationyearmaprel.Graduationyears[0].yearvalue){
            verificationCount++ 
          }
          
        }

        if (
          athleteData.onBoardingUserIdMap.Onboardings.length &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears.length &&
          athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings.length &&
          athleteData.onBoardingUserIdMap.Onboardings[0]?.primaryposition &&
          athleteData.onBoardingUserIdMap.Onboardings[0]?.profileimages &&
          athleteData.verifieduser
        ) {
          setCheckListCount(checklistProfileCount)
        }
        else{
          // setCheckListCount(checklistProfileCount+verificationCount+1)verificationCount don't have any meaning
          setCheckListCount(checklistProfileCount)
        }

        setData({
          finalItems: { ...checkListDataT },
          finalPercentage: finalPercentage,
        });
      }
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [props.checkListData]);
  // this useEffect is for render the comp
  useEffect(() => {
    if (
      props.checkListData &&
      props.checkListData.data &&
      props.checkListData.data.allAthletes &&
      props.checkListData.data.allAthletes.Athletes &&
      props.checkListData.data.allAthletes.Athletes[0]
    ) {
      let athleteData = props.checkListData.data.allAthletes.Athletes[0];
      let checkListDataT = checkListDataPrepare(athleteData);

      let finalPercentage = getFinalPercentage(checkListDataT);

      setData({
        finalItems: { ...checkListDataT },
        finalPercentage: finalPercentage,
      });
    }
  }, [store?.athlete?.editUserProfileData?.renderCount?.count]);
  useEffect(() => {
    getAllCheckListData();
    const cloneT = fields
    cloneT.user = getUserInfo()
    setFields({...cloneT})
  }, [
    store?.athlete?.editUserProfileData?.renderCount?.count
  ]);
  // the above useEffect is for render the comp

  const getAllCheckListData = () => {
    if (!props.allProfileCalculationData.data) {
      props.profileCalculationListStart({
        query: getProfileCalculationListQuery,
      });
    }
    props.checkListDataStart({
      query: getAllCheckListDataQuery,
      variables: {
        obj: {
          id: getUserInfo().id,
        },
      },
    });
  };
  useEffect(() => {
    setChangeState({
      contact: props.contact,
      allParentData: props.allParentData,
      onBoardingDetails: props.onBoardingDetails,
      allUserAcademicSchoolList: props.allUserAcademicSchoolList,
      allReferences: props.allReferences,
      latestTestScore: props.latestTestScore,
    });
  }, [
    props.contact.data,
    props.allParentData.data,
    props.onBoardingDetails.data,
    props.allUserAcademicSchoolList.data,
    props.allReferences.data,
    props.latestTestScore.data,
  ]);

  //USE EFFECT FOR GETTING DATA FOR PERCENTAGE CHECK
  useEffect(() => {
    let timeout = setTimeout(() => {
      getAllCheckListData();
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    changeState.contact,
    changeState.allParentData,
    changeState.onBoardingDetails,
    changeState.allUserAcademicSchoolList,
    changeState.allReferences,
    changeState.latestTestScore,
  ]);

  const onClickHandler = (e, link) => {
    if (props.isSaved || props.isSaved == undefined) {
      history.push(link);
    } else {
      editProfilePopUp(
        "Warning",
        "Changes you made may not be saved.",
        "warning"
      ).then((data) => {
        if (!data) {
          history.push(link);
        }
      });
    }
  };

  useEffect(() => {
    if (!props.athleteData.data) {
      props.athleteListStart({
        query: getAllAthletesDataQuery,
        variables: {
          obj: {
            id: getUserInfo() ? getUserInfo().id : 0,
          },
        },
      });
    }
  }, []);

  // the below code is used when any thick in contact page change than api hit and update this comp
  useEffect(()=>{
    props.athleteListStart({
      query: getAllAthletesDataQuery,
      variables: {
        obj: {
          id: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  },[store?.athlete?.editUserProfileData?.renderCount?.count])

  useEffect(() => {
    props.profileSectionListStart({
      query: getAllProfileSectionQuery,
      variables: {
        obj: {
          active: 1,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.athleteData.data) {
      const fieldT = fields;
      const { onboardingData, athletesData } = props.athleteData.data;
      let firstname = athletesData.athletefirstname ? athletesData.athletefirstname[0].split(/\s/).slice(0, 1) : '';
      let lastname = athletesData.athletelastname ? athletesData.athletelastname[0].split(/\s/).slice(0, 1) : '';
      setInitialLetter(firstname && lastname ? firstname[0]?.toUpperCase() + lastname[0]?.toUpperCase() : '');
      fields["athleteData"] = athletesData;
      fieldT["profileImagename"] = onboardingData
        ? onboardingData.profileimages
          ? onboardingData.profileimages
          : ""
        : "";
      fieldT["profileImage"] = onboardingData
        ? onboardingData.profileimages
          ? `${imageDownloadUrl}/${onboardingData.profileimages}`
          : ""
        : "";
      fieldT["onboardingData"] = onboardingData;
      setFields({ ...fieldT });
    }
  }, [props.athleteData.data]);
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const refreshAfterSavePhoto = () => {
    swal({
      text: "Profile Picture updated successfully.",
      icon: "success",
    }).then(async (okay) => {
      if (okay || okay == null) {
        await AutoVerify()
        window.location.reload()
      }
    });
    setIsProfileChange(false)
  }

  const onSelectFile = (event) => {
    const filedetails = event.target.files[0].name.split(".")
    let fileExtension = event.target.files[0].name.split(".")[filedetails.length - 1];
    let fileSize = parseInt(event.target.files[0].size) / (1024 * 1024);

    if (!(
      fileExtension.toLowerCase() == "jpg" ||
      fileExtension.toLowerCase() == "jpeg" ||
      fileExtension.toLowerCase() == "png" ||
      fileExtension.toLowerCase() == "gif")
    ) {
      // messagePopup(
      //   "",
      //   "Image format must be png, jpeg, jpg, gif.",
      //   "error"
      // );
      toastr.error("Image format must be png, jpeg, jpg, gif.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
      setIsOpen(false);
      //setLoadingImage(false);
    } else if (parseFloat(fileSize).toFixed(2) > 10) {
      // messagePopup("", "Image size should be of maximum 10 MB.", "error");
      toastr.error("Image size should be of maximum 10 MB.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
    } else if (event.target.files && event.target.files.length > 0) {
      const fieldsT = fields;
      const reader = new FileReader();
      if (event.target.files[0]) {
        fieldsT["fileName"] = event.target.files[0].name;
        fieldsT["fileType"] = event.target.files[0].type;
      }
      setFields(fieldsT);
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
        setIsOpen(true);
      });
      setIsProfileChange(true)
    }
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const fieldsT = fields;
      const croppedImage = await getCroppedImg(image, croppedArea);
      fieldsT["profileImage"] = URL.createObjectURL(croppedImage);
      const fileArr = fieldsT["fileName"].split(".");
      fileArr[0] = new Date().getTime();
      var croppedFile = new File([croppedImage], fileArr.join("."), {
        lastModified: new Date(),
        type: fieldsT["fileType"],
      });
      setFields({
        ...fieldsT,
      });
      handleNext(croppedFile);
      setIsOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedArea]);

  const handleNext = (selectedFile) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("file", selectedFile);
    fetch(imageUploadUrl, {
      method: "POST",
      mode: "cors",
      body: formData,
      headers: {
        access_token: getAuthToken(),
      },
    })
      .then((resp) => resp.json())
      .then((response) => {
        setLoading(false);
        if (response) {
          const pathUrl = response.result?.files?.file[0]?.name
            ? response.result?.files?.file[0]?.name
            : "";
          props.saveProfilePhotoStart({
            query: saveOnboardingQuery,
            variables: {
              obj: {
                id: fields.onboardingData ? fields.onboardingData.id : 0,
                userid: getUserInfo().id,
                profileimages: pathUrl,
                ...(isProfileChange && { profileupdatedate : new Date() })
              },
            },
            refreshPage: refreshAfterSavePhoto,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const showCroppedCancelImage = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <div className="profileImg">
        <div className="profile">
          <div className="pic">
            {fields.profileImage ? <img src={fields.profileImage} alt="profile" />
              : <div className='initialLetter'><span>{initialLetter}</span></div>}
            {JSON.parse(localStorage.getItem('user')).verifiedUserStatus === 1 ?
              <span className="verified new" onClick={e => setShowVerifiedModalState(!showVerifiedModalState)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 29.432 29.47"
                >
                  <g id="blueTick" transform="translate(0 0)">
                    <path
                      id="Subtraction_10"
                      data-name="Subtraction 10"
                      d="M13.51.8a1.311,1.311,0,0,1,2.411,0l0,0a1.311,1.311,0,0,0,2.18.363h0a1.311,1.311,0,0,1,2.281.78v.018a1.311,1.311,0,0,0,1.943,1.049l0,0a1.311,1.311,0,0,1,1.9,1.476l-.008.032a1.311,1.311,0,0,0,1.5,1.621l.018,0a1.311,1.311,0,0,1,1.321,2.014l-.025.037a1.311,1.311,0,0,0,.887,2.017l.033.005a1.311,1.311,0,0,1,.593,2.333l-.038.029a1.311,1.311,0,0,0,.182,2.2l.04.021a1.311,1.311,0,0,1-.2,2.4L28.5,17.2a1.311,1.311,0,0,0-.542,2.135l.032.035a1.311,1.311,0,0,1-.97,2.2h-.026a1.311,1.311,0,0,0-1.208,1.844l.016.037a1.311,1.311,0,0,1-1.635,1.769l-.01,0a1.311,1.311,0,0,0-1.743,1.354l0,.026a1.311,1.311,0,0,1-2.122,1.144h0a1.311,1.311,0,0,0-2.09.716l0,.011a1.311,1.311,0,0,1-2.379.4h0a1.311,1.311,0,0,0-2.21,0h0a1.311,1.311,0,0,1-2.379-.4l0-.011a1.311,1.311,0,0,0-2.09-.716h0A1.311,1.311,0,0,1,7.016,26.6l0-.026a1.311,1.311,0,0,0-1.743-1.354l-.01,0a1.311,1.311,0,0,1-1.635-1.769l.016-.037a1.311,1.311,0,0,0-1.208-1.844H2.412a1.311,1.311,0,0,1-.97-2.2l.032-.035A1.311,1.311,0,0,0,.931,17.2l-.038-.013a1.311,1.311,0,0,1-.2-2.4l.04-.021a1.311,1.311,0,0,0,.182-2.2l-.038-.029a1.311,1.311,0,0,1,.593-2.333L1.5,10.2a1.311,1.311,0,0,0,.887-2.017l-.025-.037A1.311,1.311,0,0,1,3.688,6.136l.018,0A1.311,1.311,0,0,0,5.2,4.518l-.008-.032A1.311,1.311,0,0,1,7.1,3.009l0,0A1.311,1.311,0,0,0,9.045,1.962V1.944a1.311,1.311,0,0,1,2.281-.78h0A1.311,1.311,0,0,0,13.508.8Z"
                      transform="translate(0 0)"
                      fill="#00b9ff"
                    />
                    <path
                      id="Icon_feather-check"
                      data-name="Icon feather-check"
                      d="M16.188,9l-7,7.641L6,13.168"
                      transform="translate(3.622 1.914)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </span>
              : <img
                src={VerificationIcon}
                alt="Verified"
                className="verified new"
                onClick={showVerificationModal}
              />}
          </div>
          <div className="container-cropper">
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setIsOpen(false)}
              style={customStyles}
              contentLabel="Example Modal"
              ariaHideApp={false}
            >
              <div>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
                <Button
                  onClick={showCroppedImage}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
                <Button
                  className="imageCancel"
                  style={{ margin: "5px", background: "#C0321F" }}
                  onClick={showCroppedCancelImage}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
              </div>
            </Modal>
          </div>
          <div className="browse">
            <label htmlFor="upload">+</label>
            <input type="file" id="upload"
            onChange={(e) => {
              onSelectFile(e);
              e.currentTarget.value = null;
            }}
            accept={'image/png,image/jpeg,image/jpg,image/gif'}
            />
            {/* <img src={PhotoIcon} style={{cursor: "pointer"}} alt="Browse Photo" /> */}
          </div>
        </div>
        <div className="name">
          <h2>
            {`${fields.athleteData ? fields.athleteData.athletefirstname : ""
              }  ${fields.athleteData ? fields.athleteData.athletelastname : ""
              }`}{" "}
          </h2>
          <h3>{" "}Sport Name: {fields.athleteData && fields.athleteData.sportsName ? fields.athleteData.sportsName : ""}</h3>{" "}
          <h3>{" "}Grad Year:  {fields.athleteData && fields.athleteData.graduationYear ? fields.athleteData.graduationYear : ""}</h3>
        </div>
        <div className="back">
          <Link to={{pathname: `${getAthleteSysID()}`,
            state: {isPublic : false}
          }}
          onClick={()=>sessionStorage.setItem('athlete_own_profile', true)}
          >
            <img src={BackArrow} alt="" /> Back to profile
          </Link>
        </div>
      </div>
      <div className="profileCompletion" key={data.finalPercentage}>
        <small>Strengthen Your Profile</small>
        <div className="completion"></div>
        <div className="progressbar">
          <div
            className="progress"
            style={data.finalPercentage != 5 ? { width: `${data.finalPercentage}%` } : { width: `${data.finalPercentage + 3}%` }}
          >{`${data.finalPercentage}%`}</div>
        </div>
      </div>
      <div className="checklist">
        <ul>
          {props.profileSectionDetails &&
            props.profileSectionDetails.data &&
            props.profileSectionDetails.data.allAthleteprofilecategories
            ? props.profileSectionDetails.data.allAthleteprofilecategories.Athleteprofilecategories.map(
              (item, index) => {
                let url = window.location.href.split("/")[3]//earlier it was 4 before BR
                return (
                  <li
                    key={index}
                    className={
                      url == item.redirecturl ? "active" : (url == "add-coaches" && item.redirecturl == "teams") ? "active" : "inAcitve"
                    }
                  >
                    <div className="title">
                      <Link
                        to="#"
                        onClick={(e) => {
                          onClickHandler(e, "/" + item.redirecturl);
                        }}
                      >
                        {item.profilename}
                        {item.profilename == "Checklist" ? (
                            <div className="checkListBox">
                              <div className="checkListNotify">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35.582" height="21.05" viewBox="0 0 35.582 21.05">
                               <g id="Group_51845" data-name="Group 51845" transform="translate(-1004 -289)">
                                 <path id="Path_2196" data-name="Path 2196" d="M6,9A1.5,1.5,0,0,1,7.5,7.5h21a1.5,1.5,0,0,1,0,3H7.5A1.5,1.5,0,0,1,6,9Z" transform="translate(998 281.5)"/>
                                 <path id="Path_2197" data-name="Path 2197" d="M6,27a1.5,1.5,0,0,1,1.5-1.5h21a1.5,1.5,0,0,1,0,3H7.5A1.5,1.5,0,0,1,6,27Z" transform="translate(998 272.5)"/>
                                 <path id="Path_2202" data-name="Path 2202" d="M7.5,16.5a1.5,1.5,0,0,0,0,3h12a1.5,1.5,0,0,0,0-3Z" transform="translate(998 290.5)"/>
                                 <path id="Path_2201" data-name="Path 2201" d="M24.75,12.375,15.3,23.625l-4.05-4.5" transform="translate(1013 285.125)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.6"/>
                               </g>
                            </svg>
                            {/*<img src={NotificationIcon} alt="" />*/}
                            {/*<em style={{background: "white", borderRadius: 0, position: "absolute", top:"-5px", left:"10px"}}>3</em>*/}
                            {checkListCount ? <span className="count"
                            style={{
                              position:"absolute",right: "0",top: "-10px",
                              background:"#c0321f", width: "18px", height: "18px",textAlign: "center",
                              justifyContent: "center",
                              borderRadius:"50px",
                              color:"#fff",
                              fontSize:"13px", padding:"3px", left:"-2px"
                            }}
                            >{checkListCount}</span> : "" }
                          </span>
                            </div>
                            </div>
                        ) : (
                          ""
                        )}
                      </Link>
                    </div>
                    <div className="progressbar small">
                      <div className="progress"></div>
                    </div>
                  </li>
                );
              }
            )
            : ""}
        </ul>
      </div>
      <Dialog open={showVerificationModalState}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  showVerificationModal()
                }
              }}
              className="matDialogCustom"
      >
        <VerificationModal
          onClose={showVerificationModal}
          profileImage={fields.profileimagename}
        />
      </Dialog>
      <Dialog open={showVerifiedModalState}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setShowVerifiedModalState(false)
                }
              }}
              className="matDialogCustom"
      >
        <VerifiedModal
          setShouldShowVerifiedShieldModal={setShowVerifiedModalState}
        />
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    athleteData: state.common.athletesData,
    profileSectionDetails: state.common.profileSection,
    checkListData: state.common.checkListData,

    //DATA for CHECKLIST PERCENTAGE
    contact: state.athlete.editUserProfileData.contactUserProfile,
    onBoardingDetails: state.athlete.onBoarding,
    allParentData: state.athlete.editUserProfileData.parentList,
    allUserAcademicSchoolList:
      state.athlete.editUserProfileData.userAcademicSchool,
    allReferences: state.athlete.editUserProfileData.allReferences,
    latestTestScore: state.athlete.editUserProfileData.testScores,
    allProfileCalculationData: state.common.profileCalculationData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    athleteListStart: (data) => dispatch(athletesDetailsStart(data)),
    profileSectionListStart: (data) => dispatch(profileSectionListStart(data)),
    saveProfilePhotoStart: (data) => dispatch(saveProfilePhotoStart(data)),

    //CheckList Percentage Data
    checkListDataStart: (data) => dispatch(allCheckListDataStart(data)),
    profileCalculationListStart: (data) =>
      dispatch(allProfileCalculationListStart(data)),
  };
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(LeftSideEditProfile)
);
