import { put, takeEvery } from "redux-saga/effects";
import { getCollegesCountsQuery } from "../../../../components/views/athlet/dashboard/colleges/myColleges/CollegeListQuery";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  USER_COLLEGE_CARD_COUNT_START: "USER_COLLEGE_CARD_COUNT_START",
  USER_COLLEGE_CARD_COUNT_SUCCESS: "USER_COLLEGE_CARD_COUNT_SUCCESS",
  USER_COLLEGE_CARD_COUNT_FAILED: "USER_COLLEGE_CARD_COUNT_FAILED",
};

// ============================================ Actions ========================================================
export const userCollegeCardCountStart = (data) => ({
  type: actionType.USER_COLLEGE_CARD_COUNT_START,
  payload: data,
});

export const userCollegeCardCountSuccess = (data) => ({
  type: actionType.USER_COLLEGE_CARD_COUNT_SUCCESS,
  payload: data,
});

export const userCollegeCardCountFailed = (data) => ({
  type: actionType.USER_COLLEGE_CARD_COUNT_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function userCollegeCardCountReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.USER_COLLEGE_CARD_COUNT_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.USER_COLLEGE_CARD_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.USER_COLLEGE_CARD_COUNT_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* userCollegeCardCountSaga(action) {
  try {
    const response = yield fetchGraphMethod(action.payload.query, null, true);
    if (response && response.status === 200) {
      const tempdata = response.data.data;
      let finalData = {};
      finalData['groupCollegeCoachCount'] = tempdata?.groupCollegeCoach?.totalCount ? tempdata?.groupCollegeCoach?.totalCount : 0;
      finalData['searchCount'] = tempdata?.allSearchCount?.totalCount ? tempdata?.allSearchCount?.totalCount : 0;
      finalData['userviewsCount'] = tempdata?.allUserprofileviewsCount?.totalCount ? tempdata?.allUserprofileviewsCount?.totalCount : 0;
      finalData['collegeCount'] = tempdata?.allCollegecoachCount?.totalCount ? tempdata?.allCollegecoachCount?.totalCount : 0; 
      finalData["total"] = tempdata
        ? tempdata.allTotalCollegeCount
          ? tempdata.allTotalCollegeCount.totalCount
          : 0
        : 0;
      finalData["contact"] = tempdata
        ? tempdata.allConnectedCollegeCount
          ? tempdata.allConnectedCollegeCount.totalCount
          : 0
        : 0;
      finalData["interest"] = tempdata
        ? tempdata.allInterestedCollegeCount
          ? tempdata.allInterestedCollegeCount.totalCount
          : 0
        : 0;
      finalData["active"] = tempdata
        ? tempdata.alluserActiveCollegeCount
          ? tempdata.alluserActiveCollegeCount.totalCount
          : 0
        : 0;
      finalData["offer"] = tempdata
        ? tempdata.alloffersCollegeCount
          ? tempdata.alloffersCollegeCount.totalCount
          : 0
        : 0;
      finalData["commit"] = tempdata
        ? tempdata.allCommitCollegeCount
          ? tempdata.allCommitCollegeCount.totalCount
          : 0
        : 0;
      yield put(userCollegeCardCountSuccess(finalData));
    } else {
      messagePopup("", "College count failed", "error");
      yield put(userCollegeCardCountFailed("GPA List failed"));
    }
  } catch (error) {
    messagePopup("", "College count failed", "error");
    yield put(userCollegeCardCountFailed("College count failed"));
  }
}

export function* watchUserCollegeCardCountSaga() {
  yield takeEvery(
    actionType.USER_COLLEGE_CARD_COUNT_START,
    userCollegeCardCountSaga
  );
}
