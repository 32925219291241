import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardDOB.scss";
import "../assets/css/onBoardSteeper.scss";
import { Link } from "react-router-dom";
import { getOnboardingQuery, saveOnboardingQuery } from "../OnboardingQuery";
import { getUserInfo } from "../../../../../services/authService";
import { connect } from "react-redux";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import BackdropLoader from "../../../../common/Loader";
import { getColonSlotFormatedDate, getUTCTimeDate } from "../../../../../utils/dateTimeFormatter";
import usePrevious from "../../../../../utils/usePrevious";
import Stepper from "../Stepper";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import { Dialog } from "@mui/material";
import messagePopup from "../../../../../utils/messagePopup";
import moment from "moment";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "active", link: "/onboarding/dob" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  // { className: "" },
];

function DOB(props) {
  const [fields, setFields] = useState({
    user: getUserInfo() ? getUserInfo() : 0,
    minimumDOB: getColonSlotFormatedDate(new Date()),
  });
  const [errors, setErrors] = useState({});
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    props.history.push("/onboarding/positions");
  };
  useEffect(() => {
    setFields({
      user: getUserInfo() ? getUserInfo() : 0,
      minimumDOB: getUTCTimeDate(new Date()),
    });
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = fields;
        const { id, dateofbirth } = props.onboarding.data;
        fieldT["id"] = id;
        fieldT["DOB"] = dateofbirth
          ? getUTCTimeDate(dateofbirth)
          : "";
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { formIsValid, updatedValue } = handleValidation();
    if (formIsValid) {
      props.saveOnboardingStart({
        query: saveOnboardingQuery,
        variables: {
          obj: {
            id: fields.id ? fields.id : 0,
            userid: getUserInfo().id,
            dateofbirth: fields.DOB
              ? fields.DOB + "T" + "00:00:00" + ".000Z"
              : null,
          },
        },
        nextScreen: routeNextPage,
      });
    }
    // else {
    //   messagePopup("", "Please enter your Date Of Birth.", "error");
    // }
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    //DOB validations
    if (fieldname === undefined || fieldname === "DOB") {
      if (fieldname === undefined) {
        fieldValue = fields["DOB"] ? fields["DOB"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["DOB"];
      const updatedDate = new Date(new Date(updatedValue).toDateString());
      const currentDate = new Date(new Date().toDateString());
      if (updatedDate >= currentDate) {
        formIsValid = false;
        errorsT["DOB"] = "Please enter a valid dob";
      }
      if (fieldValue == "" || fieldValue == undefined) {
        formIsValid = false;
        errorsT["DOB"] = "Please enter your Date Of Birth.";
      }
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const handleChange = (field, e) => {
    const { formIsValid, updatedValue } = handleValidation(field, e);
    e.preventDefault();
    let fieldsT = fields;
    fieldsT[field] = updatedValue;
    setFields({
      ...fieldsT,
    });
  };
  return (
    <div>
      <BackdropLoader open={props.onboarding.loading} />
      <div className="onBoardDOBSection">
        <div className="dobBlock">
          <div className="stepper">
            <Stepper stepperName="dob" stepperData={stepperData} />
          </div>
          <div className="formSection">
            <h1 className="heading">
              Date of Birth{" "}
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <defs>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: `
                          .ab {
                            fill: #00b9ff;
                          }
                          .b{fill:#fafafa;
                          }
                          `,
                      }}
                    />
                  </defs>

                  <g transform="translate(-649.111 -507.111)">
                    <path
                      className="ab"
                      d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                      transform="translate(649.111 507.111)"
                    />
                    <path
                      className="b"
                      d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                      transform="translate(511.436 348.256)"
                    />
                  </g>
                </svg>
              </span>
            </h1>
            <div className="fieldBox">
              <label>Enter Athlete Birthdate</label>
              <TextField
                id="DOB"
                type="date"
                style={{ width: 350, marginTop: "20px" }}
                InputProps={{ inputProps: { max: fields.minimumDOB } }}
                variant="outlined"
                name="DOB"
                // defaultValue="2019-05-24"
                inputProps={{ maxLength: 10 }}
                value={fields?.DOB || null}
                onChange={(e) => {
                  handleChange("DOB", e);
                }}
                required
              />
              <div style={{ color: "red" }}>{errors["DOB"]}</div>
            </div>
            <p className="skip">
              Don't know?{" "}
              <span className="sikpBtnBox">
                <Link className="skipit-btn" to="#" onClick={handleClickOpen}>
                  Skip it
                </Link>
              </span>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="/onBoardPosition" onClick={(e) => handleSubmit(e)}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            handleClose()
          }
        }}
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    onboarding: state.athlete.onBoarding.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DOB);
