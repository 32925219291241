import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchForgotOtpStart } from '../../../../redux/auth/forgotpassword/matchForgotOtp';
import {
  fetchGraphMethod,
  fetchMethod,
} from '../../../../services/httpService';
import messagePopup from '../../../../utils/messagePopup';

function EnterOtp(props) {
  let history = useHistory();
  const [enterOtpModal, setEnterOtpModal] = useState(true);
  const [enterOtp, setEnterOtp] = useState(''); // for otp inputs
  const [enterOtpError, setEnterOtpError] = useState(''); // for otp inputs
  const [isPassCodeExpire, setIsPassCodeExpire] = useState(false);
  // const [secTime, setSecTime] = useState(() => {
  //   const newDate = new Date(Date.now() + 1802000).getTime();
  //   return newDate;
  // });
  const [intvId, setIntvId] = useState(null);
  const phone =
    props?.props?.sendEmailData?.data?.sendEmail?.response?.data?.phonenumber;
  const handleOtp = (otp) => {
    setEnterOtpError('');
    setEnterOtp(otp);
  };
  const changePasswordHandle = () => {
    history.push(`/changePassword/${props.userEmailId}`);
  };

  const handleOnClick = () => {
    if (enterOtp != '') {
      props.matchForgotOtpStart({
        variables: {
          otp: enterOtp,
          emailId: props.userEmailId,
        },
        method: 'POST',
        nextScreen: changePasswordHandle,
      });
    } else {
      setEnterOtpError('Please Enter Passcode.');
    }
  };

  function hideemail() {
    let email = props.userEmailId;
    let hiddenEmail = '';
    for (let i = 0; i < email.length; i++) {
      if (i > 2 && i < email.indexOf('@')) {
        hiddenEmail += '*';
      } else {
        hiddenEmail += email[i];
      }
    }
    return hiddenEmail;
  }
  function hidePhone() {
    let phoneNumber = phone;
    let hiddenPhone = '';
    for (let i = 0; i < phoneNumber.length; i++) {
      if (i < phoneNumber?.length - 3) {
        hiddenPhone += '*';
      } else {
        hiddenPhone += phoneNumber[i];
      }
    }
    return hiddenPhone;
  }

  useEffect(() => {
    startTimer();
  }, []);

  // const setTimer = () =>{
  //     var sec  = secTime,
  //         countDivTime = document.getElementById("timer"),
  //         Timepass,
  //         countDownDiv = setInterval(function () {
  //           Timepass();
  //         }, 1000);
  //     setCountDiv(countDownDiv)
  //     function Timepass() {
  //       var min     = Math.floor(sec / 60),
  //           remSec  = sec % 60;
  //       if (remSec < 10) {
  //         remSec = '0' + remSec;
  //       }
  //       if (min < 10) {
  //         min = '0' + min;
  //       }
  //       countDivTime.innerHTML = min + ":" + remSec;
  //       if (sec > 0) {
  //         sec = sec - 1;
  //       } else {
  //         clearInterval(countDownDiv);
  //         countDivTime.innerHTML = 'countdown done';
  //         setIsPassCodeExpire(true)
  //       }
  //     }
  // }

  const startTimer = () => {
    const countDivTime = document.getElementById('timer');
    const newDate = new Date(Date.now() + 1802000).getTime();
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const diffTime = newDate - currentTime;
      if (diffTime <= 0) {
        clearInterval(intervalId);
        countDivTime.innerHTML = 'countdown done';
        setIsPassCodeExpire(true);
      } else {
        const minute = Math.floor(diffTime / 1000 / 60),
          second = Math.floor((diffTime / 1000) % 60);
        const paddedMinute = String(minute).padStart(2, 0);
        const paddedSecond = String(second).padStart(2, 0);
        countDivTime.innerHTML = paddedMinute + ':' + paddedSecond;
      }
    }, 1000);
    setIntvId(intervalId);
  };

  const resendPasscode = async () => {
    const type = JSON?.parse(localStorage?.getItem('isVerified'));
    const body = {
      emailId: props.userEmailId,
      forverification: !!type ? 1 : 0, //this condition is for email header
    };
    const _res = await fetchMethod(
      '/api/Userdata/sendForgotMail',
      body,
      'POST',
      null
    );
    if (_res?.status === 200) {
      clearInterval(intvId);
      startTimer();
      messagePopup(
        '',
        phone
          ? 'Passcode has been sent to your registered Email ID / Mobile Number.'
          : 'Passcode has been sent to your registered Email ID',
        'success'
      );
    } else {
      messagePopup('', 'Passcode failed', 'error');
    }
  };

  return (
    <div
      className="modal fade common-modal show"
      id="ClaimProfileOtpModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="ClaimProfileOtpLabel"
      aria-hidden="true"
      style={{ display: enterOtpModal ? 'block' : 'none' }}
    >
      <div className="modal-dialog" role="document">
        <form className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Enter Passcode</h5>
            <button
              onClick={() => props.closeButton()}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              {/*An Email with an Passcode has been sent to your registered*/}
              {/*email address*/}
              {/*{" "}*/}A passcode has been sent to your registered
              {!!props.userEmailId
                ? ' ' + 'Email ID' + ' ' + hideemail() + ' '
                : ''}
              {!!phone ? ` / ` + hidePhone() : ''}
              {/*{hideemail()}*/}
            </p>
            <ul className="claimprofile-password-box">
              <OtpInput
                placeholder="******"
                value={enterOtp}
                onChange={handleOtp}
                numInputs={6}
                isInputNum="true"
                renderInput={(inputProps) => (
                  <input
                    {...inputProps}
                    maxLength={1}
                    style={{
                      width: '30px',
                      height: '30px',
                      fontSize: '20px',
                      textAlign: 'center',
                    }}
                  />
                )}
              />
            </ul>
            {enterOtpError != '' ? (
              // <center>
              <div className={'col-md-12'} style={{ textAlign: 'center' }}>
                <span style={{ color: 'red', marginBottom: 0 }}>
                  Please Enter Passcode
                </span>
              </div>
            ) : // </center>
            null}
            <div className={'row'}>
              <div className={'col-md-12'}>
                {isPassCodeExpire ? (
                  <span
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      marginTop: '5px',
                      fontStyle: 'italic',
                    }}
                  >
                    *Passcode Expired
                  </span>
                ) : (
                  <span
                    style={{
                      display: 'block',
                      marginTop: '5px',
                      fontStyle: 'italic',
                    }}
                  >
                    *Passcode will expire in{' '}
                    <span
                      style={{
                        color: 'red',
                        display: 'inline-block',
                        width: '9%',
                        fontStyle: 'italic',
                      }}
                      id={'timer'}
                    ></span>{' '}
                    minutes
                  </span>
                )}
              </div>
              {/*<div className={'col-md-12'}>*/}
              {/*  <span style={{display:"block", textAlign:"center", color:"#4d4df3", textDecoration:"underline", marginTop:"5px", cursor:"pointer"}}*/}
              {/*  onClick={()=>resendPasscode()}*/}
              {/*  >*/}
              {/*    Resend Passcode</span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="modal-footer flex-column">
            <button
              onClick={() => handleOnClick()}
              type="button"
              className="btn blue"
              data-dismiss="modal"
            >
              Verify
            </button>
            <div className={'row'} style={{ marginLeft: '-15px' }}>
              <div className={'col-md-12'}>
                <span
                  style={{
                    display: 'block',
                    textAlign: 'center',
                    fontWeight: '700',
                    color: '#4d4df3',
                    textDecoration: 'underline',
                    marginTop: '12px',
                    cursor: 'pointer',
                  }}
                  onClick={() => resendPasscode()}
                >
                  Resend Passcode
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    matchForgotOtpStart: (data) => dispatch(matchForgotOtpStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterOtp);
