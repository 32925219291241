import { put, takeEvery } from "redux-saga/effects";
import { imageDownloadUrl } from "../../../../config";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  TRENDING_PROFILE_LIST_START: "TRENDING_PROFILE_LIST_START",
  TRENDING_PROFILE_LIST_SUCCESS: "TRENDING_PROFILE_LIST_SUCCESS",
  TRENDING_PROFILE_LIST_FAILED: "TRENDING_PROFILE_LIST_FAILED",
};

// ============================================ Actions ========================================================

export const trendingProfileListStart = (data) => ({
  type: actionType.TRENDING_PROFILE_LIST_START,
  payload: data,
});

export const trendingProfileListSuccess = (data) => ({
  type: actionType.TRENDING_PROFILE_LIST_SUCCESS,
  payload: data,
});

export const trendingProfileListFailed = (data) => ({
  type: actionType.TRENDING_PROFILE_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function trendingProfileListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.TRENDING_PROFILE_LIST_START:
      return {
        ...state,
        loading: true,
      };

    case actionType.TRENDING_PROFILE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.TRENDING_PROFILE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* trendingProfileListSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Userposts/TrendingData?loginUserId=${action.payload.userid}`,
      action.payload.data,
      "get",
      true
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        const finalData = response.data.response.data
          ? response.data.response.data.map((item) => {
              if (item.profileImages) {
                item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
              }
              return item;
            })
          : null;
        yield put(trendingProfileListSuccess(finalData));
      }
    } else {
      messagePopup("", "Trending profile list failed", "error");
      yield put(trendingProfileListFailed("Trending profile list failed"));
    }
  } catch (error) {
    messagePopup("", "Trending profile list failed", "error");
    yield put(trendingProfileListFailed("Trending profile list failed"));
  }
}

export function* watchtrendingProfileListSaga() {
  yield takeEvery(
    actionType.TRENDING_PROFILE_LIST_START,
    trendingProfileListSaga
  );
}
