import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  SAVE_COACH_DETAILS_LIST_START: "SAVE_COACH_DETAILS_LIST_START",
  GET_ALL_COACH_DETAILS_START: "GET_ALL_COACH_DETAILS_START",
  ALL_COACH_DETAILS_SUCCESS: "ALL_COACH_DETAILS_SUCCESS",
  ALL_COACH_DETAILS_FAILURE: "ALL_COACH_DETAILS_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/
export const saveCoachDetailListStart = (data) => ({
  type: actionType.SAVE_COACH_DETAILS_LIST_START,
  payload: data,
});

export const getAllCoachDetailsStart = (data) => ({
  type: actionType.GET_ALL_COACH_DETAILS_START,
  payload: data,
});

export const allCoachDetailsSuccess = (data) => ({
    type: actionType.ALL_COACH_DETAILS_SUCCESS,
    payload: data,
});
  
export const allCoachDetailsFailure = (data) => ({
    type: actionType.ALL_COACH_DETAILS_FAILURE,
    payload: data
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function allCoachDetailsReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_COACH_DETAILS_LIST_START:
      return { ...state, loading: false };

    case actionType.GET_ALL_COACH_DETAILS_START:
      return { ...state, loading: true };
    
    case actionType.ALL_COACH_DETAILS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.ALL_COACH_DETAILS_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* saveCoachDetailSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      if(action.payload.nextScreen) action.payload.nextScreen(response.data.data)
    } else {
      messagePopup("", "Save Coach Data Failed", "error");
      yield put(allCoachDetailsFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Save Onboarding Failed", "error");
    yield put(allCoachDetailsFailure("Failed, Error occured"));
  }
}

function* getAllCoachDetailsSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true)
        if(response.status === 200){
            const allCoachandteamdetails = response.data.data.allCoachandteamdetails.Coachandteamdetails
            yield put(allCoachDetailsSuccess(allCoachandteamdetails));
        } else {
          messagePopup('', 'Fetch All Coach Details Data Failed', 'error');
          yield put(allCoachDetailsFailure('Failed, Error occured'))
        }
       } catch (error) {
         messagePopup('', 'Fetch All Coach Details Data Failed', 'error');
         yield put(allCoachDetailsFailure('Failed, Error occured'))
       }
}

export function* watchAllCoachDetails() {
  yield takeEvery(actionType.SAVE_COACH_DETAILS_LIST_START, saveCoachDetailSaga);
  yield takeEvery(actionType.GET_ALL_COACH_DETAILS_START, getAllCoachDetailsSaga);
}

