import React, { useEffect, useState } from 'react';
import "./assets/css/privacy-policy.scss";
import {Link} from "react-router-dom";

const PrivacyPolicy = () => {
  const [errors, setErrors] = useState({});

  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0,0)
    },500)  
  },[])

  return (
    <div>
      <section className="privacy-policy-content">
        <div className="pagelinks">
          <div className="container">
            <ul className="links">
              <li><Link to={'/Terms-of-Services'}>Terms of Use</Link></li>
              <li><Link className="active" to={'/Privacy-Policy'}>Privacy Policy</Link></li>
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="contentwrap">
            <h1>Privacy</h1>
            <p>Sports Media Network, Inc. doing business at SportsForce (“SportsForce,” “we,” “us,” or “our”) is committed to respecting your (“you”, “your”, or “user”) privacy and taking steps to ensure that your personal information is protected.  We have websites, mobile applications, an online platform, and other programs or services (collectively the “Platform”) that help you organize, assess, and distribute your athletic, academic, and personal information.  Through the production of data insights, based upon your consent to use and access such data, the Platform allows athletes, teams, and coaches to benefit.</p>
            <p>We use your data to provide and improve the Services. By using the Services, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms of Use, accessible from SportsForce.io/terms<br />For the purposes of this Privacy Policy, the terms you, your or a user may be an athlete, a coach or a team, depending on your role on the Platform and the type of services your access and subscribe to.  If you are using the Platform on behalf of another entity, you represent and warrant that you have the full legal authority to bind and consent on behalf of such entity to this Privacy Policy.</p>
            <h2>The goal of this Privacy Policy is to have you understand:</h2>
            <ul>
              <li>The type of personal information we collect about you, how we collect that information, how we use it, when and how we share it, and how we protect it;</li>
              <li>Your rights with respect to your personal information and how you can control certain uses of your personal information; and</li>
              <li>How we use the data that you share with us so that you have a great experience on the Platform.</li>
            </ul>
            <p>This Privacy Policy applies only to the Platform.  Please reviewed  this Privacy Policy before you accept it to understand and agree to our data practices.  The terms governing your use of the Platform’s products and services are contained in Terms.</p>
            <h5>1. WHAT PERSONAL INFORMATION DO WE COLLECT OR SOURCE?</h5>
            <p>In this Privacy Policy your “personal information” means information or pieces of information that could allow you to be personally identified, directly or indirectly, in accordance with applicable laws.  The collection of this personal information may differ by our product offerings and your choices and we may combine this information with other information provided by players, third parties, coaches and teams.  We may collect personal information in a number of manners, including without limitation, when you provide us information, automatically when you visit our website or Platform, or as your computer or device interacts with our systems directly.  We may source such information from our business partners, service providers and other third parties.  Depending on the services or products on the Platform that you choose to participate in, we may collect from you or source the following information for our Platform, for example:</p>
            <ul>
              <li>Contact information that you provide to create an athlete, coach or team account or to use the Platform’s products and services, such as user name, email, password, name, street address, country, zip code, telephone number, weight, height, age, or gender.</li>
              <li>Profile information that you provide when you have the option to complete optional profile fields to have more accurate reporting.</li>
              <li>Information about your language preference.</li>
              <li>Information on your affiliations, such as high school, university, or teams.</li>
              <li>Information on your statistics or performance data and with the appropriate consents, as  a coach  team members statistics/performance data.</li>
              <li>Your videos and photos that you upload with your profile and team videos provided by coaches or teams, with the appropriate consents.  Please note that we do not use facial recognition or voice recognition technology.</li>
              <li>Other information you volunteer to us as part of your account or which you load onto the services.</li>
              <li>Information that helps us develop Inferences about performance and the like, such as data using the other pieces of personal information collected or received about you and which you provide to us. We may draw inferences about a player’s or team’s predictive or projected behavior.</li>
              <li>Information from third party sources to add to and augment our data services and to confirm the accuracy of our data.</li>
              <li>Information about a user’s support and feedback about the Platform, such as support chats or calls, or survey information.</li>
              <li>Information collected through the use of online marketing tools, such as cookies and pixels, through the use of such technology as Google Analytics, such as IP address, device ids, browser type, ISP, operating system, or websites you visited before coming to our Platform, or pages on our sites that you have used.</li>
              <li>Information collected through your mobile device and mobile identifiers, such as your make and carrier of you mobile device, or your unique mobile advertising identifier, such as your Apple IDFA for your Apple device or Google advertising ID for your Android device.</li>
              <li>Information when you engage with us in advertising, including information about your interactions with emails and surveys such as open rates, dates and times of interactions, and your computer or device information.</li>
              <li>Certain operational data about our Platform and the user’s devices that are used to access our Platform, such as services logs, when you used our Platform.</li>
            </ul>
            <h5>2. HOW WE USE YOUR PERSONAL INFORMATION</h5>
            <p>We and our business partners, service providers, and other third parties may use this personal information to provide you with the best Platform experience and to offer the products and services that best meet your needs.  We also may use this personal information that we collect about you for the following purposes:</p>
            <ul>
              <li>To manage, administer, analyze, and improve our Platform and the services we provide by presenting the information that is most interesting to users of the Platform in our data store, such as customizing your experience and responding to support inquiries.</li>
              <li>To update, augment, clean, and modify personal data provided to us on the Platform.</li>
              <li>To perform any automated decision making such as matching or combining your personal information to that which is in our data store for analytical, performance and predictive purposes.</li>
              <li>To send you information regarding our company or business partners (such as marketing promotions and events).</li>
              <li>To send your reports and other data products.</li>
              <li>To evaluate and measure our Platform, your experience as a visitor, and our offerings to our users.</li>
              <li>To send you surveys and feedback requests.</li>
              <li>To administer and monitor your account information and for the safety and security of the Platform, such as to protect our Platform and prevent, investigate and prosecute cyberattacks.</li>
              <li>To generate aggregated and/or deidentified information that is not reasonably linked to or does not allow for the reidentification of a user on the Platform.</li>
              <li>To provide aggregated data to third parties for their products and services.</li>
              <li>To respond to your questions about the Platform.</li>
              <li>To support our company growth and mission by sharing the information with investors, financial institutions and advisors.</li>
            </ul>
            <p>We may use and disclose information as permitted by applicable laws, and we may add to such disclosures and sharing as we notify you from time to time. We will use information on a deidentified and aggregate basis, provided that it does not identify you or allow for the reidentification of you, such as generalized athletic performance data.  We will use and disclose this deidentified and aggregate information for our business purposes.</p>
            <h4>3. HOW WE MAY DISCLOSE, SHARE OR LICENSE YOUR INFORMATION</h4>
            <p>Subject to the controls that you have on the Platform and for the uses set forth above we may disclose, share or license your Personal Information in the following ways:</p>
            <ul>
              <li>With business partners, marketing partners, service providers, and vendors to manage,  improve, provide and support the Platform and  better services to you.</li>
              <li>For other users of the Platform, with your consent, such as coaches and teams.</li>
              <li>With advertising and marketing partners for the Platform’s advertising purposes.</li>
              <li>With law enforcement, government or regulatory bodies or other third parties in order to comply with applicable laws, regulations, court orders, subpoenas or other legal obligations or to defend our rights in our Terms of Use and Privacy Policy, and those of our customers and others.</li>
              <li>In event of an actual sale of the business or its assets or in the event of bankruptcy.</li>
              <li>To provide data services to third parties.</li>
              <li>As part of a transaction, financing or for other business needs, such as to investors, advisors, and financial and strategic partners.</li>
              <li>With other parties provided that we have provided as disclosure to you of such sharing or sale of data to you at the time of collection or otherwise have obtained your consent.
                We may disclose, share, license or sell information as permitted by applicable laws or other reasons as we may describe to you from time to time in the Privacy Policy or any updates to it.  We will disclose, share, license or sell information on a deidentified and aggregate basis, provided that it does not identify you or allow for the reidentification of you.  We will disclose, share or sell this deidentified and aggregate information for our business purposes.
              </li>
            </ul>
            <h3>Your rights and our contact information</h3>
            <p>We collect and process your personal information on the basis that your personal information is required for us to provide our services to you under pursuant to our Terms of Use with you.
              Please note that you always have the right to request that we:</p>
            <ul>
              <li>Provide you information about how we use your data.</li>
              <li>Stop contacting you.</li>
              <li>Correct or update your personal information in our records to improve our services.</li>
              <li>Stop using and delete all of your personal information on our Platform.</li>
            </ul>
            <p>If you would like us to change how we deal with your personal information, you can send us an email at <a href="mailto:team@sportsforceonline.com">team@sportsforceonline.com.</a>  We will respond to your request within a reasonable timeframe. Please note that we may require that you validate your identity prior to processing your request.  Deleted information requests will be processed within 72 hours.</p>
            <p>We use an opt-in standard for sending you marketing communications, which means we will only do so with your express consent.  If you wish to opt out of receiving emails from us, please follow the unsubscribe instructions in any emails you receive from us or contact us as provided below.  Also, even after you opt out of receiving marketing emails from us, we may still send you transactional emails relating to your account with us or the Platform.</p>
            <p>We may use cookies on our website and mobile tracking.  To opt out of mobile identifiers, reset the privacy settings on your mobile device. To find out more about cookies, visit <a target="_blank" href="https://www.aboutcookies.org/">www.aboutcookies.org.</a></p>
            <h3>External Websites</h3>
            <p>The Site may contain links to third-party websites. SportsForce has no control over the privacy practices or the content of any of these websites. As such, we are not responsible for their content or privacy policies. You should check the applicable third-party privacy policy and terms of service when visiting any other websites, and before providing any Personal Information to such external sites.</p>
            <h3>Retention of your data</h3>
            <p>We will keep the data we hold about you for as long as we believe is necessary to provide you the products and services that you requested or as reasonably useful for commercial purposes, unless you tell us you would like us to stop holding your data.  As a reminder, we will use information on a deidentified and aggregate basis, provided that it does not identify you or allow for the reidentification of you, such as generalized athletic performance data.  We will use and disclose this deidentified and aggregate information for our business purposes.</p>
            <h3>Sharing and Transfer Of Data</h3>
            <p>We share the Personal Information we collect and receive with trusted third parties and business partners to help us use such Personal Information to provide the Services to you.</p>
            <p>Your information, including Personal Information, may be stored, transferred to, and maintained on computers in the United States of America where the data protection laws may differ from those from your jurisdiction. If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including Personal Information, to the United States and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
            <p>In the event of a merger, reorganization, dissolution or similar corporate event, or the sale of all or substantially all of our assets, we expect that the information that we have collected, including personal information, would be transferred to the surviving entity in a merger or the acquiring entity. If we are involved in a merger, acquisition or asset sale, or in the unlikely event of a bankruptcy, liquidation or receivership of our business, and your personal information becomes subject to a different privacy policy, we will provide affected users notice through the Site.</p>
            <p>Finally, we may also share such Personal Information where required to do so by law or subpoena or if we reasonably believe that such action is necessary to (a) comply with the law and the reasonable requests of law enforcement; (b) to enforce our terms of use or to protect the security, quality or integrity of our Site and services; and/or (c) to exercise or protect our rights, property, or personal safety and that of the Services, our users, or others.
              Other than for purposes of the Services, we will not rent or sell your Personal Information to third parties without your consent.
            </p>
            <h3>Security of Data</h3>
            <p>We will maintain control over the confidentiality of our customer information, which includes having physical, electronic, and procedural safeguards that comply with federal standards. We will permit only authorized staff, who are trained in the proper handling of customer information, to have access to that information in order to provide you with quality products and superior service. All of our operational and data processing systems are in a secure environment that protects your account information from being accessed by third parties.<br />
              You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to your email communications from us, at all times.
            </p>
            <h3>Service Providers</h3>
            <p>We may employ third party companies and individuals to facilitate our Services ("Service Providers"), to provide the Services on our behalf, to perform Service-related services or to assist us in analyzing how our Services are used.<br />These third parties have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
            <h3>Payments</h3>
            <p>We may provide paid products and/or services within the Services. In that case, we use third-party services for payment processing (e.g. payment processors).<br />These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.<br />The payment processors we work with are:<br />Stripe: Their Privacy Policy can be viewed at <a target="_blank" href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a></p>
            <h3>NOTICE TO USERS OUTSIDE THE UNITED STATES</h3>
            <p>The Site is governed by and operated in, and in accordance with the laws of the United States. We make no representation that the Site is governed by or operated in accordance with the laws of any other nation. If you are located in the European Union, Canada or elsewhere outside of the United States, please be aware that information we collect may be transferred to and processed in the United States. By using the Site, or providing us with any information, you (a) acknowledge that the Site is subject to the laws of the United States, (b) consent to the collection, processing, maintenance and transfer of such information in and to the United States and other applicable territories in which the privacy laws may not be as comprehensive as or equivalent to those in the country where you reside and/or are a citizen, and (c) waive any claims that may arise under those laws.</p>
            <h3>Changes To This Privacy Policy</h3>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. By continuing to use the Services, you agree to such changes.</p>
            <h3>Contact Us</h3>
            <p>If you have any questions about this Privacy Policy or if you would like to make a data request, please contact us:</p>
            <p>By email: <a href="mailto:team@sportsforceonline.com">team@sportsforceonline.com</a> or write us at:<br />
              Sports Media Network, Inc.<br />
              7514 Girard Ave. #1327<br />
              San Diego, CA 92037<br />
            </p>
            <p>We will respond to requests within a reasonable timeframe. We retain and use your information as necessary to comply with our legal and contractual obligations, statute of limitations, resolution of disputes, and enforcement of our agreements.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
