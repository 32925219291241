export const getAllAddEditFormDataQuery = `
query allEvents($obj : JSON){
    allEvents(where : $obj ){
      Events{
        id
        createdby
        categoryid
        eventlogoname
        active
        eventcategoryidrel{
          Eventcategories{
            id
            categoryname
          }
        }
        startdate
        enddate
        eventname
        eventeventorgidrel{
          Organizations{
            id
            eventorgname
            
          }
        }
        eventteamidrel{
          Teams{
            id
            teamname
          }
        }
        eventorgid
        teamid
        divisionorlevel
        location
        city
        
        eventstateidrel{
          States{
            id
            initials
          }
        }
        eventcountryidrel{
          Countries{
            id
            countryname
          }
        }
        eventwebsiteurl
        eventnotes
        isverified
        ispassevent
        ispublic
        eventmappingeventid{
          Eventmappings{
            addedby
            eventid
          }
        }
      }
    }
  }
`;

export const getFilteredAddEventDataQuery = `
query filter($where : JSON){
  allEvents(where: $where){
    Events{
      id
      createdby
      eventname
      eventlogoname
      city
      ispassevent
      isverified
      eventorgid
      eventstateidrel{
        States{
          statename
        }
      }
    }
  }
}
`;

export const saveAddEditEventFormData = `
mutation saveEvent($obj : EventInput!){
  saveEvent(obj : $obj){
    id
  }
} 

`;

export const saveAllOrganizationsData = `
mutation saveOrganization($obj :  OrganizationInput!){
  saveOrganization(obj : $obj){
    id
    eventorgname
  }
  
}
`;

export const saveAllTeamsDataQuery = `
mutation saveTeam($obj :  TeamInput!){
  saveTeam(obj : $obj){
    id
    teamname
  }
}
`;

export const getAllStatesCountries = `
query allStates{
  allStates{
    States{
      statename
      initials
      id
      active
      countryid
    }
  }
}
`;
export const saveAddedEventQuery = `
mutation saveEventmapping($obj :EventmappingInput!){
  saveEventmapping(obj : $obj){
    id
  }
}
`;

export const getAllTeamsNameQuery = `
query allTeams{
  allTeams(where: {isverified: 1}){
    Teams{
      teamname
      id
      isverified
    }
  }
}
`;

export const getAllTeamsRefNameQuery = `
query allTeams($where: JSON){
  allTeams(where: $where){
    Teams{
      teamname
      id
      isverified
    }
  }
}
`;

export const getAllAddedEventsQuery = `
query allEventmappings($where: JSON){
  allEventmappings(where: $where ){
    totalCount
    Eventmappings{
      id
      addedby
      eventid
      createdby
      ispostevent
      eventmappingeventidrel{
        Events{
          id
          categoryid
         eventlogoname
          startdate
          enddate
          eventname
          eventorgid
          teamid
          divisionorlevel
          location
          city
          stateid
          countryid
          eventcategoryidrel{
            Eventcategories{
              id
              categoryname
            }
          }
          eventstateidrel{
            States{
              id
              statename
            }
          }
          eventcountryidrel{
            Countries{
              id
              countryname
            }
          }
          eventwebsiteurl
          eventnotes
          ispassevent
        }
      }
    }
  }
}
`;

export const deleteEventQuery = `
mutation deleteevent($id:JSON){
  EventmappingDeleteById(id:$id)
}
`;

export const deleteEventQueryByID = `
mutation EventDeleteById($id:JSON){
  EventDeleteById(id:$id)
}
`;

export const AddUserActivity = `
mutation saveUseracivity($obj:UseracivityInput!){
  saveUseracivity(obj:$obj){
    id
  }
}
`;

export const fetchUserActivity = `
query AllUseracivities($where:JSON){
  allUseracivities(where:$where){
    Useracivities{
      id
      eventid
      active
    }
  }
}
`;
