import React, { useState, useEffect, useRef } from "react";
import LeftSideEditProfile from "./../LeftSideEditProfile";
import { Title } from "./../videoPhoto/Component";
import eventLogoImg01 from "./../assets/img/event_default.png";
import VerificationIcon from "./../assets/img/complete-verification-icon.svg";
import {connect, useDispatch, useSelector} from "react-redux";
import SubHeader from "../SubHeader";
import { getSlashFormattedDate, getUTCTimeDate, getUTCTimeDateOverall, dbDateSame } from "../../../../../../utils/dateTimeFormatter";
import {
  getAllAddEditFormDataQuery,
  getAllAddedEventsQuery,
  saveAddEditEventFormData,
  deleteEventQuery,
  saveAddedEventQuery,
  AddUserActivity,
  fetchUserActivity,
  deleteEventQueryByID
} from "./AddEditEventFormQuery";
import { getUserInfo } from "../../../../../../services/authService";
import { useHistory } from "react-router-dom";
import {
  addAddedEventFormListStart,
  addAddedEventFormsaveStart,
} from "../../../../../../redux/athlete/dashboard/editUserProfile/addAddedEvent";
import messagePopup from "../../../../../../utils/messagePopup";
import warningMessagePopup from "../../../../../../utils/warningMagPop";
import {fetchGraphMethod, fetchMethod} from "../../../../../../services/httpService";
import BackdropLoader from "../../../../../common/Loader";
import ScreenSwitch from "../ScreenSwitch";
import DeleteItem from "../../../../../common/DeleteModal"
import Dialog from "@mui/material/Dialog";
import GoTopButton from '../GoTopButton';
import { TwitterShareButton, FacebookShareButton } from 'react-share'
import SharePost from "../../homepage/posts/SharePost";
import toastr from "toastr";
import { imageDownloadUrl } from "../../../../../../config";

const Event = (props) => {
  const [usersEvents, setUsersEvents] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null)
  const UpcomingDataList = props.isUpcomingEvent ? props.isUpcomingEvent : "";
  //state for popup
  const [isShare, setIsShare] = useState(false)
  const [modalData, setModalData] = useState([])
  const [eventID, setEventID] = useState(0)

  let history = useHistory();

  // edit events
  const handleEditEvent = (e, data) => {
    e.preventDefault();
    let EventId = data.eventid;
    history.push(`events/event-form/${EventId}`);
    props.singleEventValue(data);
  };

  //  delete events
  const deleteEvents = async (e, eventId) => {
    const body ={
      "userid":getUserInfo()?.id,
      "createdby":!!eventId?.createdby ? getUserInfo().id == eventId.createdby ? 1 : 0 : 0,
      "eventid":eventId?.eventid ?? 0,
      "ispostontimeline":eventId?.ispostevent ?? 0
    }
    const resEv = await fetchMethod('/api/eventmappings/deleteEvent', body,"POST",true)
    if ((resEv?.status == 200) && (resEv?.data?.response?.status == 200)){
      // messagePopup("","Event Deleted Successfully","success")
      toastr.success("Event Deleted Successfully.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
    }else{
      // messagePopup("","Event Deleted Failed","error")
      toastr.error("Event Deleted Failed", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
    }
  };

  const handleDeleteEvent = async (e, eventId) => {
    e.preventDefault();
    const deleteEvent = await deleteEvents(e, deletedItemId);
    setShowDeleteModal(false);
    props.getAllAddedEventsData();
  };

  // post event on timeline
  const postEventOnTimeline = (e, addedEventId) => {
    props.addAddedEventFormsaveStart({
      query: saveAddedEventQuery,
      variables: {
        obj: {
          id: +addedEventId,
          ispostevent: e.target.checked === true ? 1 : 0,
        },
      },
    });
  };

  const handlePostOnTimeline = async (e, addedEventId, eventid) => {
    e.preventDefault();
    const handlePost = await postEventOnTimeline(e, addedEventId);
    let variables;
    if (!e.target.checked) {
      variables = {
        where: {
          userid: getUserInfo().id,
          eventid: eventid,
        },
      };
      let existId = await fetchGraphMethod(fetchUserActivity, variables, true);
      let userActivityid;
      if (
        existId.data &&
        existId.data.data &&
        existId.data.data.allUseracivities &&
        existId.data.data.allUseracivities.Useracivities &&
        existId.data.data.allUseracivities.Useracivities[0]
      ) {
        userActivityid = existId.data.data.allUseracivities.Useracivities[0].id;
      }
      variables = userActivityid == "" ? {
        obj: {
          eventid: eventid,
          userid: getUserInfo().id,
          active: 1,
          publicorprivatestatus: 0,
        },
      } : {
        obj: {
          eventid: eventid,
          userid: getUserInfo().id,
          active: 1,
          publicorprivatestatus: 0,
          id: userActivityid,
        },
      };
    } else {
      variables = {
        where: {
          userid: getUserInfo().id,
          eventid: eventid,
          active: 1
        },
      };
      let res = await fetchGraphMethod(fetchUserActivity, variables, true);
      let userActivityid;
      if (
        res.data &&
        res.data.data &&
        res.data.data.allUseracivities &&
        res.data.data.allUseracivities.Useracivities &&
        res.data.data.allUseracivities.Useracivities[0]
      ) {
        userActivityid = res.data.data.allUseracivities.Useracivities[0].id;
      }
      variables = {
        obj: {
          id: userActivityid,
          eventid: eventid,
          userid: getUserInfo().id,
          active: 0,
          publicorprivatestatus: 0,
        },
      };
    }
    let res = await fetchGraphMethod(AddUserActivity, variables, true);
    props.getAllAddedEventsData();
  };

  const onDeleteClick = () => {
    setShowDeleteModal(false);
    setEventID(0)
  }
  //func used for popup toggle and modal data setup
  const onHandlePopup = value => {
    setModalData(value)
    setIsShare(!isShare)
  }
  return (
    <>
      <Dialog open={showDeleteModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onDeleteClick()
                }
              }}
              className="matDialogCustom"
      >
        <DeleteItem
            onDeleteClick={handleDeleteEvent}
            onCancelClick={onDeleteClick}
            eventName="Event"
        />
      </Dialog>
      {UpcomingDataList
        ? UpcomingDataList.map((value, index) => (
          <li key={index}>
            <div className="logo-box">
              <img src={value.eventmappingeventidrel.Events[0].eventlogoname ? imageDownloadUrl + "/" + value.eventmappingeventidrel.Events[0].eventlogoname : props.eventLogo} alt="event Logo Img 01" />
            </div>
            <div className="detail-box">
              <h3>
                <span>
                  <strong>Event Name:</strong>{" "}
                  {value.eventmappingeventidrel.Events[0].eventname}
                </span>
                <div>
                  <div className="button-box">
                    <button
                      className="trash-button"
                      onClick={(e) => {
                        setShowDeleteModal(true);
                        setDeletedItemId(value)
                        setEventID(value?? 0)
                      }}
                    >
                      <span className="icon-box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.9"
                          height="17.5"
                          viewBox="0 0 15.9 17.5"
                        >
                          <g transform="translate(-3.75 -2.25)">
                            <path
                              className="a"
                              d="M4.5,9H18.9"
                              transform="translate(0 -2.8)"
                            />
                            <path
                              className="a"
                              d="M18.7,6.2V17.4A1.6,1.6,0,0,1,17.1,19h-8a1.6,1.6,0,0,1-1.6-1.6V6.2m2.4,0V4.6A1.6,1.6,0,0,1,11.5,3h3.2a1.6,1.6,0,0,1,1.6,1.6V6.2"
                              transform="translate(-1.4)"
                            />
                            <path
                              className="a"
                              d="M15,16.5v4.8"
                              transform="translate(-4.9 -6.3)"
                            />
                            <path
                              className="a"
                              d="M21,16.5v4.8"
                              transform="translate(-7.7 -6.3)"
                            />
                          </g>
                        </svg>
                      </span>
                      Delete
                    </button>
                    {getUserInfo().id == value.createdby ? (
                      <a
                        className="edit-event"
                        onClick={(e) => handleEditEvent(e, value)}
                      >
                        <span className="icon-box">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.641"
                            height="15.578"
                            viewBox="0 0 15.641 15.578"
                          >
                            <path
                              className="a"
                              d="M15.24,2.528,13.187.475a1.515,1.515,0,0,0-2.138.086A1.517,1.517,0,0,0,10.962,2.7l2.053,2.053a1.516,1.516,0,0,0,2.139-.086,1.516,1.516,0,0,0,.085-2.139ZM2.15,9.458l4.106,4.106L12.93,6.892,8.824,2.786,2.15,9.458ZM0,15.651l5.416-1.085L1.085,10.235Z"
                              transform="translate(0 -0.074)"
                            ></path>
                          </svg>
                        </span>{" "}
                        Edit Event
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </h3>
              <p>
                <span>
                  <strong>Start Date:</strong>{" "}
                  {dbDateSame(value.eventmappingeventidrel.Events[0].startdate)}
                </span>
                <span>
                  <strong>End Date:</strong>{" "}
                  {dbDateSame(value.eventmappingeventidrel.Events[0].enddate)}
                </span>
              </p>
              <p>
                <strong>Category:</strong>{" "}
                {value.eventmappingeventidrel &&
                  value.eventmappingeventidrel.Events[0] &&
                  value.eventmappingeventidrel.Events[0].eventcategoryidrel &&
                  value.eventmappingeventidrel.Events[0].eventcategoryidrel
                    .Eventcategories[0] &&
                  value.eventmappingeventidrel.Events[0].eventcategoryidrel
                    .Eventcategories[0].categoryname
                  ? value.eventmappingeventidrel.Events[0].eventcategoryidrel
                    .Eventcategories[0].categoryname
                  : ""}
              </p>
              <p>
                <strong>City, State / Province, Country:&nbsp; </strong>{" "}
                {value.eventmappingeventidrel.Events[0].city}
                {(!!value?.eventmappingeventidrel?.Events?.[0].eventstateidrel?.States?.[0]?.statename &&
                  value?.eventmappingeventidrel?.Events?.[0]?.city) ? ", "
                  : ""}
                {value.eventmappingeventidrel &&
                  value.eventmappingeventidrel.Events[0] &&
                  value.eventmappingeventidrel.Events[0].eventstateidrel &&
                  value.eventmappingeventidrel.Events[0].eventstateidrel
                    .States[0] &&
                  value.eventmappingeventidrel.Events[0].eventstateidrel
                    .States[0].statename
                  ? value.eventmappingeventidrel.Events[0].eventstateidrel
                    .States[0].statename
                  : ""}
                {(value?.eventmappingeventidrel?.Events?.[0]?.eventcountryidrel?.Countries?.[0]?.countryname &&
                  !!value?.eventmappingeventidrel?.Events?.[0].eventstateidrel?.States?.[0]?.statename)? ", "
                  : ""}
                {value.eventmappingeventidrel &&
                  value.eventmappingeventidrel.Events[0] &&
                  value.eventmappingeventidrel.Events[0].eventcountryidrel &&
                  value.eventmappingeventidrel.Events[0].eventcountryidrel
                    .Countries[0] &&
                  value.eventmappingeventidrel.Events[0].eventcountryidrel
                    .Countries[0].countryname
                  ? value.eventmappingeventidrel.Events[0].eventcountryidrel
                    .Countries[0].countryname
                  : ""}
              </p>
              <p>
                <strong>Location:&nbsp; </strong>{" "}
                {value.eventmappingeventidrel &&
                  value.eventmappingeventidrel.Events[0] &&
                  value.eventmappingeventidrel.Events[0].location
                  ? value.eventmappingeventidrel.Events[0].location
                  : ""}
              </p>
              <p>
                <strong>Website:&nbsp; </strong>{" "}
                <a target={'_blank'} style={{cursor:"pointer"}} href={value?.eventmappingeventidrel?.Events?.[0]?.eventwebsiteurl ?? ""}>
                  {" "}
                  {value.eventmappingeventidrel &&
                    value.eventmappingeventidrel.Events[0] &&
                    value.eventmappingeventidrel.Events[0].eventwebsiteurl
                    ? value.eventmappingeventidrel.Events[0].eventwebsiteurl
                    : ""}
                </a>
              </p>
              <p>
                <strong>Event Notes:</strong>{" "}
                {value.eventmappingeventidrel &&
                  value.eventmappingeventidrel.Events[0] &&
                  value.eventmappingeventidrel.Events[0].eventnotes
                  ? value.eventmappingeventidrel.Events[0].eventnotes
                  : ""}
              </p>
              <div className="share-box-wrap">
                <ul className="social-links">
                  {/*this code is commentes as per future use*/}
                  {/*<li>Share</li>*/}
                  {/*<li>*/}
                  {/*  <a className="twitter" onClick={() => onHandlePopup(value)}>*/}
                  {/*    <svg*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*      width="22.049"*/}
                  {/*      height="17.908"*/}
                  {/*      viewBox="0 0 22.049 17.908"*/}
                  {/*    >*/}
                  {/*      <path*/}
                  {/*        className="a"*/}
                  {/*        d="M19.782,7.844c.014.2.014.392.014.588A12.769,12.769,0,0,1,6.939,21.288,12.77,12.77,0,0,1,0,19.26a9.348,9.348,0,0,0,1.091.056A9.05,9.05,0,0,0,6.7,17.385a4.527,4.527,0,0,1-4.225-3.134,5.7,5.7,0,0,0,.853.07,4.779,4.779,0,0,0,1.189-.154A4.519,4.519,0,0,1,.9,9.732V9.676a4.551,4.551,0,0,0,2.043.574,4.526,4.526,0,0,1-1.4-6.044,12.845,12.845,0,0,0,9.317,4.729A5.1,5.1,0,0,1,10.744,7.9a4.523,4.523,0,0,1,7.821-3.092,8.9,8.9,0,0,0,2.868-1.091,4.507,4.507,0,0,1-1.987,2.49,9.059,9.059,0,0,0,2.6-.7,9.714,9.714,0,0,1-2.266,2.336Z"*/}
                  {/*        transform="translate(0 -3.381)"*/}
                  {/*      />*/}
                  {/*    </svg>*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a className="facebook" onClick={() => onHandlePopup(value)}>*/}
                  {/*    <svg*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*      width="9.447"*/}
                  {/*      height="17.639"*/}
                  {/*      viewBox="0 0 9.447 17.639"*/}
                  {/*    >*/}
                  {/*      <path*/}
                  {/*        className="a"*/}
                  {/*        d="M10.437,9.922l.49-3.192H7.864V4.658a1.6,1.6,0,0,1,1.8-1.725h1.393V.216A16.981,16.981,0,0,0,8.585,0C6.062,0,4.413,1.529,4.413,4.3V6.73h-2.8V9.922h2.8v7.717H7.864V9.922Z"*/}
                  {/*        transform="translate(-1.609)"*/}
                  {/*      />*/}
                  {/*    </svg>*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a className="message" onClick={() => onHandlePopup(value)}>*/}
                  {/*    <svg*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*      width="19.987"*/}
                  {/*      height="20.18"*/}
                  {/*      viewBox="0 0 19.987 20.18"*/}
                  {/*    >*/}
                  {/*      <g transform="translate(0.25 -49.75)">*/}
                  {/*        <path*/}
                  {/*          className="a"*/}
                  {/*          d="M12.788,10.352H6.7a.609.609,0,1,0,0,1.218h6.09a.609.609,0,1,0,0-1.218ZM14.006,6.7H5.481a.609.609,0,1,0,0,1.218h8.525a.609.609,0,1,0,0-1.218ZM9.743,0C4.363,0,0,3.817,0,8.525a8.16,8.16,0,0,0,3.654,6.649v4.312L7.922,16.9a11.174,11.174,0,0,0,1.821.154c5.381,0,9.743-3.817,9.743-8.525S15.125,0,9.743,0Zm0,15.833a9.855,9.855,0,0,1-2.058-.223L4.818,17.332l.038-2.824A7.061,7.061,0,0,1,1.218,8.525c0-4.035,3.817-7.307,8.525-7.307s8.525,3.272,8.525,7.307S14.452,15.833,9.743,15.833Z"*/}
                  {/*          transform="translate(0 50)"*/}
                  {/*        />*/}
                  {/*      </g>*/}
                  {/*    </svg>*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a className="email" onClick={() => onHandlePopup(value)}>*/}
                  {/*    <svg*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*      width="17.668"*/}
                  {/*      height="13.257"*/}
                  {/*      viewBox="0 0 17.668 13.257"*/}
                  {/*    >*/}
                  {/*      <g transform="translate(0 -50)">*/}
                  {/*        <path*/}
                  {/*          className="a"*/}
                  {/*          d="M19.369,6a1.1,1.1,0,0,0-.226,0H3.683a1.1,1.1,0,0,0-.293.044l7.978,7.945Z"*/}
                  {/*          transform="translate(-2.584 44.011)"*/}
                  {/*        />*/}
                  {/*        <path*/}
                  {/*          className="a"*/}
                  {/*          d="M19.532,7.39,11.493,15.4a1.1,1.1,0,0,1-1.557,0L1.969,7.451a1.1,1.1,0,0,0-.039.276V18.769a1.1,1.1,0,0,0,1.1,1.1h15.46a1.1,1.1,0,0,0,1.1-1.1V7.727a1.1,1.1,0,0,0-.066-.337ZM3.791,18.769H3.023v-.79L7.037,14l.779.778Zm14.692,0H17.71l-4.025-3.992L14.463,14l4.014,3.981Z"*/}
                  {/*          transform="translate(-1.93 43.383)"*/}
                  {/*        />*/}
                  {/*      </g>*/}
                  {/*    </svg>*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                </ul>
                <div className="item">
                  <p className="checkbox-list">
                    <span
                      data-toggle="modal"
                      data-target="#VideoPostVIDEOModal"
                    >
                      <input
                        type="checkbox"
                        checked={value.ispostevent === 1 ? true : false}
                        onChange={(e) =>
                          handlePostOnTimeline(e, value.id, value.eventid)
                        }
                      />

                      <label>Check here to post it on your Timeline</label>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))
        : ""}
      <Dialog open={isShare}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setIsShare(!isShare)
                }
              }}
              className="matDialogCustom"
      >
        <SharePost
          handleModal={() => setIsShare(!isShare)}
          modalData={modalData}
          pageType={'Event'}
        />
      </Dialog>
    </>
  );
};

const PastEvents = (props) => {
  const [showDeletePastModal, setShowDeletePastModal] = useState(false);
  const [pastDeletedItemId, setPastDeletedItemId] = useState(null)
  const PastEventsList = props.isPastEvent ? props.isPastEvent : "";
  //state for popup
  const [isShare, setIsShare] = useState(false)
  const [modalData, setModalData] = useState([])
  const [eventID, setEventID] = useState(0)
  let history = useHistory();

  const handleEditEvent = (e, data) => {
    e.preventDefault();
    let EventId = data.eventid;
    history.push(`events/event-form/${EventId}`);
    props.singleEventValue(data);
  };

  const deleteEvents = async (e, eventId) => {
    const body ={
      "userid":getUserInfo()?.id,
      "createdby":!!eventId?.createdby ? getUserInfo().id == eventId.createdby ? 1 : 0 : 0,
      "eventid":eventId?.eventid ?? 0,
      "ispostontimeline":eventId?.ispostevent ?? 0
    }
    const resEv = await fetchMethod('/api/eventmappings/deleteEvent', body,"POST",true)
    if ((resEv?.status == 200) && (resEv?.data?.response?.status == 200)){
      // messagePopup("","Event Deleted Successfully","success")
      toastr.success("Event Deleted Successfully", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
    }else{
      // messagePopup("","Event Deleted Failed","error")
      toastr.error("Event Deleted Failed", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
    }
  };

  const handlePastDeleteEvent = async (e, eventId) => {
    const deleteEvent = await deleteEvents(e, pastDeletedItemId);
    setShowDeletePastModal(false)
    props.getAllAddedEventsData();
  };
  const onDeletePastClick = () => {
    setShowDeletePastModal(false);
    setEventID(0)
  }

  // post event on timeline
  const postEventOnTimeline = (e, addedEventId) => {
    props.addAddedEventFormsaveStart({
      query: saveAddedEventQuery,
      variables: {
        obj: {
          id: +addedEventId,
          ispostevent: e.target.checked === true ? 1 : 0,
        },
      },
    });
  };

  const handlePostOnTimeline = async (e, addedEventId, eventid) => {
    const handlePost = await postEventOnTimeline(e, addedEventId);
    let variables;
    if (!e.target.checked) {
      variables = {
        where: {
          userid: getUserInfo().id,
          eventid: eventid,
        },
      };
      let existId = await fetchGraphMethod(fetchUserActivity, variables, true);
      let userActivityid;
      if (
        existId.data &&
        existId.data.data &&
        existId.data.data.allUseracivities &&
        existId.data.data.allUseracivities.Useracivities &&
        existId.data.data.allUseracivities.Useracivities[0]
      ) {
        userActivityid = existId.data.data.allUseracivities.Useracivities[0].id;
      }
      variables = userActivityid == "" ? {
        obj: {
          eventid: eventid,
          userid: getUserInfo().id,
          active: 1,
          publicorprivatestatus: 0,
        },
      } : {
        obj: {
          eventid: eventid,
          userid: getUserInfo().id,
          active: 1,
          publicorprivatestatus: 0,
          id: userActivityid,
        },
      };
    } else {
      variables = {
        where: {
          userid: getUserInfo().id,
          eventid: eventid,
          active: 1
        },
      };
      let res = await fetchGraphMethod(fetchUserActivity, variables, true);
      let userActivityid;
      if (
        res.data &&
        res.data.data &&
        res.data.data.allUseracivities &&
        res.data.data.allUseracivities.Useracivities &&
        res.data.data.allUseracivities.Useracivities[0]
      ) {
        userActivityid = res.data.data.allUseracivities.Useracivities[0].id;
      }
      variables = {
        obj: {
          id: userActivityid,
          eventid: eventid,
          userid: getUserInfo().id,
          active: 0,
          publicorprivatestatus: 0,
        },
      };
    }
    let res = await fetchGraphMethod(AddUserActivity, variables, true);
    props.getAllAddedEventsData();
  };
  //func used for popup toggle and modal data setup
  const onHandlePopup = value => {
    setModalData(value)
    setIsShare(!isShare)
  }
  return (
    <>
      <>
        <Dialog open={showDeletePastModal}
                onClose={(event, reason)=> {
                  if (reason == "backdropClick" || reason == "escapeKeyDown") {
                    onDeletePastClick()
                  }
                }}
                className="matDialogCustom"
        >
          <DeleteItem
              onDeleteClick={handlePastDeleteEvent}
              onCancelClick={onDeletePastClick}
              eventName="Event"
          />
        </Dialog>
      </>
      {PastEventsList
        ? PastEventsList.map((value, index) => (
          <li key={index}>
            <div className="logo-box">
              <img src={!!value?.eventmappingeventidrel?.Events[0]?.eventlogoname ?
                  `${imageDownloadUrl}/${value?.eventmappingeventidrel?.Events[0]?.eventlogoname}` :
                  props?.eventLogo
              }  alt="event Logo Img 01" />
            </div>
            <div className="detail-box">
              <h3>
                <span>
                  <strong>Event Name:</strong>{" "}
                  {value.eventmappingeventidrel.Events[0].eventname}
                </span>
                <div className="button-box">
                  <button
                    className="trash-button"
                    //onClick={(e) => handleDeleteEvent(e, value.id)}
                    onClick={(e) => {
                      setShowDeletePastModal(true);
                      setPastDeletedItemId(value)
                      setEventID(value ?? 0)
                    }}
                  >
                    <span className="icon-box">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.9"
                        height="17.5"
                        viewBox="0 0 15.9 17.5"
                      >
                        <g transform="translate(-3.75 -2.25)">
                          <path
                            className="a"
                            d="M4.5,9H18.9"
                            transform="translate(0 -2.8)"
                          />
                          <path
                            className="a"
                            d="M18.7,6.2V17.4A1.6,1.6,0,0,1,17.1,19h-8a1.6,1.6,0,0,1-1.6-1.6V6.2m2.4,0V4.6A1.6,1.6,0,0,1,11.5,3h3.2a1.6,1.6,0,0,1,1.6,1.6V6.2"
                            transform="translate(-1.4)"
                          />
                          <path
                            className="a"
                            d="M15,16.5v4.8"
                            transform="translate(-4.9 -6.3)"
                          />
                          <path
                            className="a"
                            d="M21,16.5v4.8"
                            transform="translate(-7.7 -6.3)"
                          />
                        </g>
                      </svg>
                    </span>
                    Delete
                  </button>
                  {getUserInfo().id == value.createdby ? (
                    <a
                      className="edit-event"
                      onClick={(e) => handleEditEvent(e, value)}
                    >
                      <span className="icon-box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.641"
                          height="15.578"
                          viewBox="0 0 15.641 15.578"
                        >
                          <path
                            className="a"
                            d="M15.24,2.528,13.187.475a1.515,1.515,0,0,0-2.138.086A1.517,1.517,0,0,0,10.962,2.7l2.053,2.053a1.516,1.516,0,0,0,2.139-.086,1.516,1.516,0,0,0,.085-2.139ZM2.15,9.458l4.106,4.106L12.93,6.892,8.824,2.786,2.15,9.458ZM0,15.651l5.416-1.085L1.085,10.235Z"
                            transform="translate(0 -0.074)"
                          ></path>
                        </svg>
                      </span>{" "}
                      Edit Event
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </h3>
              <p>
                <span>
                  <strong>Start Date:</strong>{" "}
                  {dbDateSame(value.eventmappingeventidrel.Events[0].startdate)}
                </span>
                <span>
                  <strong>End Date:</strong>{" "}
                  {dbDateSame(value.eventmappingeventidrel.Events[0].enddate)}
                </span>
              </p>
              <p>
                <strong>Category:</strong>{" "}
                {value.eventmappingeventidrel &&
                  value.eventmappingeventidrel.Events[0] &&
                  value.eventmappingeventidrel.Events[0].eventcategoryidrel &&
                  value.eventmappingeventidrel.Events[0].eventcategoryidrel
                    .Eventcategories[0] &&
                  value.eventmappingeventidrel.Events[0].eventcategoryidrel
                    .Eventcategories[0].categoryname
                  ? value.eventmappingeventidrel.Events[0].eventcategoryidrel
                    .Eventcategories[0].categoryname
                  : ""}
              </p>
              <p>
                <strong>City, State / Province, Country:</strong>{" "}
                {value?.eventmappingeventidrel?.Events?.[0]?.city}
                {(!!value?.eventmappingeventidrel?.Events?.[0]?.eventstateidrel?.States?.[0]?.statename &&
                !!value?.eventmappingeventidrel?.Events?.[0]?.city)
                  ? ", "
                  : ""}
                {value.eventmappingeventidrel &&
                  value.eventmappingeventidrel.Events[0] &&
                  value.eventmappingeventidrel.Events[0].eventstateidrel &&
                  value.eventmappingeventidrel.Events[0].eventstateidrel
                    .States[0] &&
                  value.eventmappingeventidrel.Events[0].eventstateidrel
                    .States[0].statename
                  ? value.eventmappingeventidrel.Events[0].eventstateidrel
                    .States[0].statename
                  : ""}
                {(!!value?.eventmappingeventidrel?.Events[0]?.eventstateidrel?.States?.[0]?.statename &&
                  !!value?.eventmappingeventidrel?.Events?.[0].eventcountryidrel?.Countries?.[0]?.countryname)
                  ? ", "
                  : ""}
                {value.eventmappingeventidrel &&
                  value.eventmappingeventidrel.Events[0] &&
                  value.eventmappingeventidrel.Events[0].eventcountryidrel &&
                  value.eventmappingeventidrel.Events[0].eventcountryidrel
                    .Countries[0] &&
                  value.eventmappingeventidrel.Events[0].eventcountryidrel
                    .Countries[0].countryname
                  ? value.eventmappingeventidrel.Events[0].eventcountryidrel
                    .Countries[0].countryname
                  : ""}
              </p>
              <p>
                <strong>Location:</strong>{" "}
                {value.eventmappingeventidrel &&
                  value.eventmappingeventidrel.Events[0] &&
                  value.eventmappingeventidrel.Events[0].location
                  ? value.eventmappingeventidrel.Events[0].location
                  : ""}
              </p>
              <p>
                <strong>Website:</strong>{" "}
                <a target={'_blank'} style={{cursor:"pointer"}} href={value?.eventmappingeventidrel?.Events?.[0]?.eventwebsiteurl ?? ""}>
                  {" "}
                  {value.eventmappingeventidrel &&
                    value.eventmappingeventidrel.Events[0] &&
                    value.eventmappingeventidrel.Events[0].eventwebsiteurl
                    ? value.eventmappingeventidrel.Events[0].eventwebsiteurl
                    : ""}
                </a>
              </p>
              <p>
                <strong>Event Notes:</strong>{" "}
                {value.eventmappingeventidrel &&
                  value.eventmappingeventidrel.Events[0] &&
                  value.eventmappingeventidrel.Events[0].eventnotes
                  ? value.eventmappingeventidrel.Events[0].eventnotes
                  : ""}
              </p>
              <div className="share-box-wrap">
                <ul className="social-links">
                  {/*this code is commented as per future use*/}
                  {/*<li>Share</li>*/}
                  {/*<li>*/}
                  {/*  <a className="twitter"*/}
                  {/*    onClick={() => onHandlePopup(value)}*/}
                  {/*  >*/}
                  {/*    <svg*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*      width="22.049"*/}
                  {/*      height="17.908"*/}
                  {/*      viewBox="0 0 22.049 17.908"*/}
                  {/*    >*/}
                  {/*      <path*/}
                  {/*        className="a"*/}
                  {/*        d="M19.782,7.844c.014.2.014.392.014.588A12.769,12.769,0,0,1,6.939,21.288,12.77,12.77,0,0,1,0,19.26a9.348,9.348,0,0,0,1.091.056A9.05,9.05,0,0,0,6.7,17.385a4.527,4.527,0,0,1-4.225-3.134,5.7,5.7,0,0,0,.853.07,4.779,4.779,0,0,0,1.189-.154A4.519,4.519,0,0,1,.9,9.732V9.676a4.551,4.551,0,0,0,2.043.574,4.526,4.526,0,0,1-1.4-6.044,12.845,12.845,0,0,0,9.317,4.729A5.1,5.1,0,0,1,10.744,7.9a4.523,4.523,0,0,1,7.821-3.092,8.9,8.9,0,0,0,2.868-1.091,4.507,4.507,0,0,1-1.987,2.49,9.059,9.059,0,0,0,2.6-.7,9.714,9.714,0,0,1-2.266,2.336Z"*/}
                  {/*        transform="translate(0 -3.381)"*/}
                  {/*      />*/}
                  {/*    </svg>*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a className="facebook" onClick={() => onHandlePopup(value)}>*/}
                  {/*    <svg*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*      width="9.447"*/}
                  {/*      height="17.639"*/}
                  {/*      viewBox="0 0 9.447 17.639"*/}
                  {/*    >*/}
                  {/*      <path*/}
                  {/*        className="a"*/}
                  {/*        d="M10.437,9.922l.49-3.192H7.864V4.658a1.6,1.6,0,0,1,1.8-1.725h1.393V.216A16.981,16.981,0,0,0,8.585,0C6.062,0,4.413,1.529,4.413,4.3V6.73h-2.8V9.922h2.8v7.717H7.864V9.922Z"*/}
                  {/*        transform="translate(-1.609)"*/}
                  {/*      />*/}
                  {/*    </svg>*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a className="message" onClick={() => onHandlePopup(value)}>*/}
                  {/*    <svg*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*      width="19.987"*/}
                  {/*      height="20.18"*/}
                  {/*      viewBox="0 0 19.987 20.18"*/}
                  {/*    >*/}
                  {/*      <g transform="translate(0.25 -49.75)">*/}
                  {/*        <path*/}
                  {/*          className="a"*/}
                  {/*          d="M12.788,10.352H6.7a.609.609,0,1,0,0,1.218h6.09a.609.609,0,1,0,0-1.218ZM14.006,6.7H5.481a.609.609,0,1,0,0,1.218h8.525a.609.609,0,1,0,0-1.218ZM9.743,0C4.363,0,0,3.817,0,8.525a8.16,8.16,0,0,0,3.654,6.649v4.312L7.922,16.9a11.174,11.174,0,0,0,1.821.154c5.381,0,9.743-3.817,9.743-8.525S15.125,0,9.743,0Zm0,15.833a9.855,9.855,0,0,1-2.058-.223L4.818,17.332l.038-2.824A7.061,7.061,0,0,1,1.218,8.525c0-4.035,3.817-7.307,8.525-7.307s8.525,3.272,8.525,7.307S14.452,15.833,9.743,15.833Z"*/}
                  {/*          transform="translate(0 50)"*/}
                  {/*        />*/}
                  {/*      </g>*/}
                  {/*    </svg>*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a className="email" onClick={() => onHandlePopup(value)}>*/}
                  {/*    <svg*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*      width="17.668"*/}
                  {/*      height="13.257"*/}
                  {/*      viewBox="0 0 17.668 13.257"*/}
                  {/*    >*/}
                  {/*      <g transform="translate(0 -50)">*/}
                  {/*        <path*/}
                  {/*          className="a"*/}
                  {/*          d="M19.369,6a1.1,1.1,0,0,0-.226,0H3.683a1.1,1.1,0,0,0-.293.044l7.978,7.945Z"*/}
                  {/*          transform="translate(-2.584 44.011)"*/}
                  {/*        />*/}
                  {/*        <path*/}
                  {/*          className="a"*/}
                  {/*          d="M19.532,7.39,11.493,15.4a1.1,1.1,0,0,1-1.557,0L1.969,7.451a1.1,1.1,0,0,0-.039.276V18.769a1.1,1.1,0,0,0,1.1,1.1h15.46a1.1,1.1,0,0,0,1.1-1.1V7.727a1.1,1.1,0,0,0-.066-.337ZM3.791,18.769H3.023v-.79L7.037,14l.779.778Zm14.692,0H17.71l-4.025-3.992L14.463,14l4.014,3.981Z"*/}
                  {/*          transform="translate(-1.93 43.383)"*/}
                  {/*        />*/}
                  {/*      </g>*/}
                  {/*    </svg>*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                </ul>
                <div className="item">
                  <p className="checkbox-list">
                    <span
                      data-toggle="modal"
                      data-target="#VideoPostVIDEOModal"
                    >
                      <input
                        type="checkbox"
                        checked={value.ispostevent === 1 ? true : false}
                        onChange={(e) =>
                          handlePostOnTimeline(e, value.id, value.eventid)
                        }
                      />
                      <label>Check here to post it on your Timeline</label>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))
        : ""}
      <Dialog open={isShare}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setIsShare(!isShare)
                }
              }}
              className="matDialogCustom"
      >
        <SharePost
          handleModal={() => setIsShare(!isShare)}
          modalData={modalData}
          pageType={'Event'}
        />
      </Dialog>
    </>
  );
};

const EditSection = (props) => {
  let history = useHistory()
  const [tab, setTab] = React.useState(true);
  const [tab2, setTab2] = React.useState(false);
  const onTabClick = () => {
    setTab2(false);
    setTab(true);
  };
  const onTabClick2 = () => {
    setTab(false);
    setTab2(true);
  };

  const AddedEvents =
    props.data &&
      props.data.addedEvents &&
      props.data.addedEvents.data &&
      props.data.addedEvents.data.data &&
      props.data.addedEvents.data.data.allEventmappings &&
      props.data.addedEvents.data.data.allEventmappings.Eventmappings
      ? props.data.addedEvents.data.data.allEventmappings.Eventmappings
      : "";

  const EventsData = [...AddedEvents].reverse();
  const isPastEvent = EventsData
    ? EventsData.filter(
      (eventsdata) =>
        eventsdata.eventmappingeventidrel.Events[0].ispassevent === 0
    )
    : "";
  const isUpcomingEvent = EventsData
    ? EventsData.filter(
      (eventsdata) =>
        eventsdata.eventmappingeventidrel.Events[0].ispassevent === 1
    )
    : "";
  return (
    <div className="editSection">
      <Title title={"Events"} />
      <div className="check-list-sec">
        <div className="event-main-wrapper">
          <div className="event-top-block">
            <div className="short-text">
              <p>
                Adding your event will help you stay organized, improve your
                exposure and search ranking.
              </p>
              <a className="btn green"
                // href="#/events/add-event"
                onClick={(e) => {
                  e.preventDefault()
                  history.push({
                    pathname: "/events/add-event",
                    previousPage: props?.data?.location?.state?.previousPage || ""
                  })
                }}
                 style={{color:"white"}}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.829"
                  height="21.986"
                  viewBox="0 0 21.829 21.986"
                >
                  <g transform="translate(0 0)">
                    <path
                      className="a"
                      d="M16.856,1.954H15.634V.977a.977.977,0,1,0-1.954,0v.977H5.863V.977a.977.977,0,0,0-1.954,0v.977H2.687A2.689,2.689,0,0,0,0,4.641V15.634a2.935,2.935,0,0,0,2.931,2.931H7.817a.977.977,0,1,0,0-1.954H2.931a.978.978,0,0,1-.977-.977V7.817H17.589a.977.977,0,0,0,1.954,0V4.641a2.689,2.689,0,0,0-2.687-2.687Zm0,0"
                      transform="translate(0 0)"
                    />
                    <path
                      className="a"
                      d="M241.019,234.668a6.351,6.351,0,1,0,6.352,6.351A6.359,6.359,0,0,0,241.019,234.668ZM243.462,242H242v1.466a.977.977,0,0,1-1.954,0V242h-1.466a.977.977,0,0,1,0-1.954h1.466v-1.466a.977.977,0,0,1,1.954,0v1.466h1.466a.977.977,0,0,1,0,1.954Zm0,0"
                      transform="translate(-225.542 -225.385)"
                    />
                  </g>
                </svg>
                Add Event
              </a>
            </div>
          </div>
          <div className="event-data-box">
            <div className="tab-white-box">
              <ul className="tabs">
                <li
                    style={{cursor:"pointer"}}
                  className={tab ? "current" : undefined}
                  data-tab="UpcomingEventsTab"
                >
                  <a onClick={() => onTabClick()}>Upcoming Events</a>
                </li>
                <li
                    style={{cursor:"pointer"}}
                  className={tab2 ? "current" : undefined}
                  data-tab="PastEventsTab"
                >
                  <a onClick={() => onTabClick2()}>Past Events</a>
                </li>
              </ul>
            </div>

            <div className="event-tab-content">
              <div
                id="UpcomingEventsTab"
                className={`tab-content ${tab && "current"}`}
              >
                <ul className="events-list">
                  <Event
                    getAllAddedEventsData={props.getAllAddedEventsData}
                    addAddedEventFormsaveStart={
                      props.data.addAddedEventFormsaveStart
                    }
                    eventLogo={eventLogoImg01}
                    isUpcomingEvent={isUpcomingEvent}
                    singleEventValue={props.getSingleEventData}
                  />
                </ul>
                <div className="form-button-box with-next-prev">
                  <div className="next-prev-button">
                    <ScreenSwitch
                      prevLink={"/Stats"}
                      nextLink={"/academics"}
                      isSaved={true}
                    />
                  </div>
                </div>
              </div>
              <div
                id="PastEventsTab"
                className={`tab-content ${tab2 && "current"}`}
              >
                <ul className="events-list">
                  <PastEvents
                    eventLogo={eventLogoImg01}
                    isPastEvent={isPastEvent}
                    getAllAddedEventsData={props.getAllAddedEventsData}
                    addAddedEventFormsaveStart={
                      props.data.addAddedEventFormsaveStart
                    }
                    singleEventValue={props.getSingleEventData}
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Events = (props) => {
  const store = useSelector(store => store)
  const [sideMenuOpen, setSideMenu] = useState(store?.athlete?.editUserProfileData?.eventSideBarStatus?.status || false);
  // const [sideMenuOpen, setSideMenu] = useState(false);
  const [singleEventData, setSingleEventValue] = useState([]);
  const [events, setEvents] = useState([]);
  const fakeInput = useRef(null);
  const dispatch = useDispatch()

  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
    //func of redux
    dispatch({type:"EVENT_SIDE_BAR_START", payload: {status: !sideMenuOpen}})
  };

  useEffect(() => {
    getAllAddedEventsData();
    setEvents(
      props.addedEvents &&
        props.addedEvents.data &&
        props.addedEvents.data.data &&
        props.addedEvents.data.data.allEventmappings &&
        props.addedEvents.data.data.allEventmappings.Eventmappings
        ? props.addedEvents.data.data.allEventmappings.Eventmappings
        : ""
    );
    window.scrollTo(0,0)
  }, [events]);

  const getAllAddedEventsData = () => {
    props.addAddedEventFormListStart({
      query: getAllAddedEventsQuery,
      variables: {
        where: {
          addedby: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  };
  const getSingleEventData = (eventData) => {
    setSingleEventValue(eventData == undefined ? [] : eventData);
  };

  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  //this useEffect is for scrolling the comp on top when render
  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0,0)
    },1800)
  },[])
  return (
    <div className="pageContent">
      <div className="editProfile">
        <input className="fakeInput" type="text" ref={fakeInput} />
        <div className="container">
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
          >
            <SubHeader hamburger={onSideMenuClick} buttonHide={false} />
            <div className="sidebar">
              <LeftSideEditProfile isSaved={true} />
            </div>
            <EditSection
              data={props}
              getAllAddedEventsData={getAllAddedEventsData}
              getSingleEventData={getSingleEventData}
            />
          </div>
          <GoTopButton goTop={goTop} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allAddEditEventForm: state.athlete.editUserProfileData.addEditEventFormList,
    addedEvents: state.athlete.editUserProfileData.addAddedEvent.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAddedEventFormListStart: (data) =>
      dispatch(addAddedEventFormListStart(data)),

    addAddedEventFormsaveStart: (data) =>
      dispatch(addAddedEventFormsaveStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
