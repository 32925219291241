import React, { useState, useEffect } from "react";
import "../../../../assets/css/stylesheet.scss";
import "../../../../assets/css/signIn.scss";
import "../login/login.css";
import logoVPng from "../../../../assets/img/newLogoV.png";
import {
    TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BackdropLoader from "../../../common/Loader";
import {fetchMethod} from "../../../../services/httpService";
import {setAuthToken, setUserInfo} from "../../../../services/authService";
import history from "../../../../services/history";
import toastr from 'toastr'

function Adminlogin(props) {
    const [isLoading, setIsLoading] = useState(true),
          [emailId, setEmailId] = useState(),
          [isToken, setIsToken] = useState()
    useEffect(()=>{
        setTimeout(()=>{
            setIsLoading(false)
        },1500)
        setEmailId(!!props?.match?.params?.emailid ? props?.match?.params?.emailid : "")
        setIsToken(!!props?.match?.params?.token ? props?.match?.params?.token : "")
        setAuthToken(!!props?.match?.params?.token ? props?.match?.params?.token : "")
    },[])
    const handleSubmit = async () =>{
        const _res = await fetchMethod('/api/Advisors/athleteloginProfiledata',{emailId: emailId },'POST',true)
        if(_res?.status == 200 && _res?.data?.response?.status == 200){
            setUserInfo(_res.data?.response?.data);
            setAuthToken(_res.data?.response?.data?.token);
            history.push("/dashboard");
        }else{
            toastr.error("Admin Login Failed")
        }
    }
    return (
        <div>
            <BackdropLoader open={isLoading} />
            <div className="signIn">
                <div className="container">
                    <div className="flex">
                        <div className="home" style={{marginBottom:0}}>
                            <div>
                                {/*<Link to="/" className="homeLink">*/}
                                {/*    <svg*/}
                                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                                {/*        width="9.337"*/}
                                {/*        height="16.828"*/}
                                {/*        viewBox="0 0 9.337 16.828"*/}
                                {/*    >*/}
                                {/*        <path*/}
                                {/*            id="Path_117"*/}
                                {/*            data-name="Path 117"*/}
                                {/*            d="M6.922,0,0,7l6.922,7"*/}
                                {/*            transform="translate(1 1.414)"*/}
                                {/*            fill="none"*/}
                                {/*            stroke="#c0321f"*/}
                                {/*            strokeLinecap="round"*/}
                                {/*            strokeLinejoin="round"*/}
                                {/*            strokeWidth="2"*/}
                                {/*        />*/}
                                {/*    </svg>*/}
                                {/*    <span>Home</span>*/}
                                {/*</Link>*/}
                            </div>
                            <div className="logo">
                                <a href={void 0}>
                                    <img src={logoVPng} alt="Logo | SportsForce" />
                                </a>
                            </div>
                        </div>

                        <div className="form">
                            <h1>Athlete Profile Access</h1>
                            <ul>
                                <li>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        className="text-field"
                                        id="email"
                                        placeholder="Email*"
                                        type="text"
                                        disabled={true}
                                        value={ emailId }
                                        // onChange={(e) => {
                                        //     handleChange("email", e);
                                        // }}
                                        // onBlur={(e) => {
                                        //     handleChange("email", e);
                                        // }}
                                        // InputProps={{
                                        //     readOnly: false,
                                        // }}
                                        required
                                    />
                                    {/*<div className="error">{errors["email"]}</div>*/}
                                </li>
                                <li>
                                    <button
                                        id="submitButton"
                                        className="btn primary"
                                        onClick={handleSubmit}
                                    >
                                        Sign In
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Adminlogin;