import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_SCHOOL_LIST_START: "ALL_SCHOOL_LIST_START",
  ALL_SCHOOL_LIST_SUCCESS: "ALL_SCHOOL_LIST_SUCCESS",
  ALL_SCHOOL_LIST_FAILED: "ALL_SCHOOL_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const allSchoolListStart = (data) => ({
  type: actionType.ALL_SCHOOL_LIST_START,
  payload: data,
});

export const allSchoolListSuccess = (data) => ({
  type: actionType.ALL_SCHOOL_LIST_SUCCESS,
  payload: data,
});

export const allSchoolListFailed = (data) => ({
  type: actionType.ALL_SCHOOL_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allSchoolListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ALL_SCHOOL_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_SCHOOL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_SCHOOL_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allSchoolListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allSchoolListSuccess({
          allSchoolList: response.data.data.allAthleteschools,
        })
      );
    } else {
      messagePopup("", "High School List failed", "error");
      yield put(allSchoolListFailed("High School List failed"));
    }
  } catch (error) {
    messagePopup("", "High School List failed", "error");
    yield put(allSchoolListFailed("High School List failed"));
  }
}

//Watcher
export function* watchAllSchoolListSaga() {
  yield takeEvery(actionType.ALL_SCHOOL_LIST_START, allSchoolListSaga);
}
