import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    GET_STATS_TIMELINE_DATA_START: "GET_STATS_TIMELINE_DATA_START",
    GET_STATS_TIMELINE_DATA_SUCCESS: "GET_STATS_TIMELINE_DATA_SUCCESS",
    GET_STATS_TIMELINE_DATA_FAIL: "GET_STATS_TIMELINE_DATA_FAIL",
};

// ============================================ Actions ========================================================
export const profileTimelineStatStart = (data) => ({
    type: actionType.GET_STATS_TIMELINE_DATA_START,
    payload: data,
});

export const profileTimelineStatFail = (data) => ({
    type: actionType.GET_STATS_TIMELINE_DATA_FAIL,
    payload: data,
});

export const profileTimelineStatSuccess = (data) => ({
    type: actionType.GET_STATS_TIMELINE_DATA_SUCCESS,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function profileTimelineStatReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.GET_STATS_TIMELINE_DATA_START:
            return {
                ...state,
                loading: true,
            };

        case actionType.GET_STATS_TIMELINE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };

        case actionType.GET_STATS_TIMELINE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };

        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* profileTimelineStatSaga(action) {
    try {
        const response = yield fetchMethod(
            `/api/Publicprofilefieldpreviews/statsMainHeaderForProfile`,
            action.payload,
            "post",
            action?.payload?.isAccessToken
        );
        if (
            response &&
            response.status === 200 &&
            response.data.response.data
        ) {
            yield put(
                profileTimelineStatSuccess({
                    profileTimelineStat: response.data.response.data,
                })
            );
        }
        else if(response && response.status === 200 && response.data.response.status === 201){
            // messagePopup("", `${response.data.response.msg}`, "error");
            yield put(profileTimelineStatFail(`${response.data.response.msg}`));
        }
        else {
            messagePopup("", "Profile Stats failed", "error");
            yield put(profileTimelineStatFail("Profile Timeline Stats failed"));
        }
    } catch (error) {
        messagePopup("", "Profile Timeline Stats failed", "error");
        yield put(profileTimelineStatFail("Profile Timeline Stats failed"));
    }
}

//Watcher
export function* watchProfileTimelineStatSaga() {
    yield takeEvery(actionType.GET_STATS_TIMELINE_DATA_START, profileTimelineStatSaga);
}
