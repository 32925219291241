import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    GET_STATS_SUB_CARD_DATA_START: "GET_STATS_SUB_CARD_DATA_START",
    GET_STATS_SUB_CARD_DATA_SUCCESS: "GET_STATS_SUB_CARD_DATA_SUCCESS",
    GET_STATS_SUB_CARD_DATA_FAIL: "GET_STATS_SUB_CARD_DATA_FAIL",
};

// ============================================ Actions ========================================================
export const profileSubCardStatStart = (data) => ({
    type: actionType.GET_STATS_SUB_CARD_DATA_START,
    payload: data,
});

export const profileSubCardStatFail = (data) => ({
    type: actionType.GET_STATS_SUB_CARD_DATA_FAIL,
    payload: data,
});

export const profileSubCardStatSuccess = (data) => ({
    type: actionType.GET_STATS_SUB_CARD_DATA_SUCCESS,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function profileSubCardStatReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.GET_STATS_SUB_CARD_DATA_START:
            return {
                ...state,
                loading: true,
            };

        case actionType.GET_STATS_SUB_CARD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };

        case actionType.GET_STATS_SUB_CARD_DATA_FAIL:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };

        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* profileSubCardStatSaga(action) {
    try {
        const response = yield fetchMethod(
            `/api/Publicprofilefieldpreviews/statsMatrixForProfile`,
            action.payload,
            "post",
            action?.payload?.isAccessToken
        );
        if (
            response &&
            response.status === 200 &&
            response.data.response.data
        ) {
            yield put(
                profileSubCardStatSuccess({
                    profileSubCardStat: response.data.response.data,
                })
            );
        } else {
            messagePopup("", "Profile Stats Header Data failed", "error");
            yield put(profileSubCardStatFail("Profile Stats Header Data failed"));
        }
    } catch (error) {
        messagePopup("", "Profile Stats Header Data failed", "error");
        yield put(profileSubCardStatFail("Profile Stats Header Data failed"));
    }
}

//Watcher
export function* watchProfileSubCardStatSaga() {
    yield takeEvery(actionType.GET_STATS_SUB_CARD_DATA_START, profileSubCardStatSaga);
}