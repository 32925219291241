import { put, takeEvery } from "redux-saga/effects";
import {
  removeSignupData,
  setAuthToken,
  setUserInfo,
} from "../../../services/authService";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
import history from "../../../services/history";

// ========================================== Action Type =====================================================
export const actionType = {
  EMAIL_EXIST_START: "EMAIL_EXIST_START",
  EMAIL_EXIST_SUCCESS: "EMAIL_EXIST_SUCCESS",
  EMAIL_EXIST_FAILED: "EMAIL_EXIST_FAILED",
  EMAIL_EXIST_STATE: "EMAIL_EXIST_STATE",
};

// ============================================ Actions ========================================================

export const emailExistStart = (data) => ({
  type: actionType.EMAIL_EXIST_START,
  payload: data,
});

export const emailExistState = () => ({
  type: actionType.EMAIL_EXIST_STATE,
});

export const emailExistSuccess = (data) => ({
  type: actionType.EMAIL_EXIST_SUCCESS,
  payload: data,
});

export const emailExistFailed = (data) => ({
  type: actionType.EMAIL_EXIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  // loading: false,
  data: null,
  error: null,
};

export default function emailExistReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.EMAIL_EXIST_STATE:
      return {
        ...state,
        data: null,
        error: null,
      };
    case actionType.EMAIL_EXIST_START:
      return {
        ...state,
        // loading: true,
      };
    case actionType.EMAIL_EXIST_SUCCESS:
      return {
        ...state,
        // loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.EMAIL_EXIST_FAILED:
      return {
        ...state,
        // loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* emailExistSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Userdata/isUserExist`,
      action.payload.data,
      "post",
      false
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        action.payload.handleSaveSignUpData(
          response.data.response.data ? response.data.response.data : null
        );
        // if (action.payload.isValidEmail) action.payload.isValidEmail(true);
      } else if (response.data.response.status === 201) {
        // if (action.payload.isValidEmail) action.payload.isValidEmail(false);
        if (response.data.response.error) {
          // action?.payload?.data?.role == "GUARDIAN"
          //     ? "Email id already exist for parent/guardian."
          //     : response.data.response.msg
          messagePopup("", response.data.response.msg, "error");
        } else {
          action?.payload?.data?.role == "GUARDIAN"
            ? messagePopup(
                "",
                response.data.response.msg,
                // action?.payload?.data?.role == "GUARDIAN"
                //   ? "Email id already exist for parent/guardian."
                //   : response.data.response.msg,
                "error"
              )
            : action.payload.handleEmailModalOpen
            ? action.payload.handleEmailModalOpen()
            : messagePopup("", "Email id already exist for athlete.", "error");
        }
      }
    } else {
      messagePopup("", "Email validation failed", "error");
      yield put(emailExistFailed("Email validation failed"));
    }
  } catch (error) {
    messagePopup("", "Email validation failed", "error");
    yield put(emailExistFailed("Email validation failed"));
  }
}

export function* watchchaeckEmailExistSaga() {
  yield takeEvery(actionType.EMAIL_EXIST_START, emailExistSaga);
}
