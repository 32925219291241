import React, { useEffect } from "react";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { Button, IconButton, InputAdornment } from "@mui/material";
import "./signup.scss";
import { getSignupData, setSignupData } from "../../../../services/authService";
import { resetState, signupStart } from "../../../../redux/auth/signup/signup";
import { connect } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import swal from "sweetalert";
import { alphaNumericValidation } from "../../../../utils/validationUtils";
function CreatePassword(props) {
  const [fields, setFields] = React.useState({
    showPassword: false,
  });
  const [errors, setErrors] = React.useState({});

  useEffect(() => {
    const signupData = getSignupData();
    props.resetState();
    if (signupData && signupData.completed === "STEP2") {
    } else {
      //below code is creating problem
      // props.history.goBack();
      window.history.back();
    }
  }, []);

  let role = getSignupData() ? getSignupData().role : "";

  let athleteEmailId = getSignupData() ? getSignupData().athleteEmail : "";

  let parentEmailId = getSignupData() ? getSignupData().parentEmailId : "";

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    let regularExp = new RegExp(
      /^(?=.*\d)(?=.*[a-zA-Z])/
    );
    let blankSpaceValidation = stringVal => {
      return /\s/g.test(stringVal);
    };
    //password validations
    if (fieldname === undefined || fieldname === "password") {
      if (fieldname === undefined) {
        fieldValue = fields["password"] ? fields["password"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["password"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["password"] = "Password is required";
      } else if (!(updatedValue.length >= 6)) {
        formIsValid = false;
        errorsT["password"] = "Password must be at least 6 characters";
      }
      else if (updatedValue.length > 32) {
        formIsValid = false;
        errorsT["password"] = "Maximum length should be 32 characters";
      }
      else if (regularExp.test(updatedValue) == false && updatedValue !== "") {
        formIsValid = false;
        errorsT["password"] = "Password must contain 1 alphabetical letter and 1 numeral";
      }
      //else if (updatedValue.search(/[0-9]/) < 0) {
      //   formIsValid = false;
      //   errorsT["password"] = "Your password must contain at least one digit";
      // }
      // else if (alphaNumericValidation(updatedValue)) {
      //   formIsValid = false;
      //   errorsT["password"] = "Your password must contain alpha numeric characters";
      // } 
      if (blankSpaceValidation(updatedValue)) {
        formIsValid = false;
        errorsT["password"] = "Blank spaces are not allowed"
      }
      else if (role === "ATHLETES") {
        if (updatedValue === athleteEmailId) {
          formIsValid = false;
          errorsT["password"] = "Email id can’t be set as your password";
        }
      } else if (role === "GUARDIAN") {
        if (updatedValue === parentEmailId) {
          formIsValid = false;
          errorsT["password"] = "Email id can’t be set as your password";
        }
      }
    }

    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };
  const handleChange = (field, e) => {
    const { formIsValid, updatedValue } = handleValidation(field, e);
    let fieldsT = fields;
    fieldsT[field] = updatedValue;
    setFields({
      ...fieldsT,
    });
  };

  const checkPrevID = () => {
    let getValue = !!getSignupData() ? getSignupData() : "",
      check = true;
    if (!!getValue) {
      if (getValue?.athleteEmail == getValue?.parentEmailId) check = false;
      else check = true;
    } else check = true;
    return check;
  };

  const getClubTeamName = (clubTeamName) => { 
    if(!!clubTeamName){
      let teamName = JSON.parse(clubTeamName)?.['teamname'] ?? "";
      if(!!teamName){
        let team = teamName.split("[")?.[0] ?? "";
        return team;
      }
      else{
        return "";
      }
    }
    else{
      return "";
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const { formIsValid, updatedValue } = handleValidation();
    if (formIsValid) {
      if (!checkPrevID()) {
        swal({
          text: "Parent and Athlete email id not be same.",
          icon: "error",
        });
        return;
      }

      let signupData = getSignupData() ? getSignupData() : {};
      signupData = {
        ...signupData,
        clubTeamName: getClubTeamName(signupData?.clubTeamName),
        password: fields.password,
      };
      //setSignupData(signupData);
      const { completed, ...restData } = signupData;
      props.signupStart(restData);
    } else {
    }
  };
  const handleClickShowPassword = () => {
    const fieldsT = fields;
    fieldsT["showPassword"] = !fieldsT["showPassword"];
    setFields({ ...fieldsT });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      {/* <BackdropLoader open={props.signup.loading} /> */}
      <div className="createPasswordSection">
        <div className="formSection">
          <form>
            <div className="heading">
              <h1>
                {getSignupData()
                  ? getSignupData().isParentExist
                    ? "Validate the password"
                    : "Set your password"
                  : "Set your password"}
              </h1>
            </div>
            <div className="subHeading">
              <h2>
                {getSignupData()
                  ? getSignupData().isParentExist
                    ? ""
                    : "We are ready to create your account"
                  : "We are ready to create your account"}
              </h2>
            </div>
            <div className="passfieldBox">
              <FormLabel>
                <p>
                  Password <sup>*</sup>
                </p>
              </FormLabel>
              <div className="fieldBox">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="password"
                  placeholder="Password"
                  type={fields.showPassword ? "text" : "password"}
                  value={fields.password || ""}
                  onChange={(e) => {
                    handleChange("password", e);
                  }}
                  onBlur={(e) => {
                    handleChange("password", e);
                  }}
                  InputLabelProps={{ shrink: true }}
                  // InputProps={{
                  //   readOnly: false,
                  // }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword("showPassword")
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large">
                          {fields.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
                <div className="error">{errors["password"]}</div>
              </div>
            </div>
            {/* <p className="hint">
              Minimun of 6 characters including letters and a number
            </p> */}

            <div className="submitBtnBox">
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  handleSubmit(e);
                }}
                id="continue"
                size="large"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    signup: state.auth.signup,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signupStart: (data) => dispatch(signupStart(data)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
