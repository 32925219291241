import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import InterestLevelModal from "../InterestLevelModal";
import { getAllOfferQuery, saveOffersQuery} from "../../editUserProfile/OfferQuery";
import {fetchGraphMethod} from "../../../../../../services/httpService";
import { getUserInfo } from "../../../../../../services/authService";
import {getCamelCase} from "../../../../../common/camelCaseFunc";
import {imageDownloadUrl} from "../../../../../../config";
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";
import { saveViewCollegeQuery } from "../../colleges/searchColleges/SearchCollegesQuery";

export default function SuggestedCollegeCard(props) {
  const [showInterestModal, setShowInterestModal] = useState(false);
  const [collegeIsAddedToOffer, setCollegeIsAddedToOffer] = useState(null);

  useEffect( async () => {
    await fetchGraphMethod(getAllOfferQuery, {obj:{
      userid: getUserInfo() ? getUserInfo().id : 0,
      active: 1,
      collegeid:props.data.id
    }}, true).then(res => {
      if(res?.data?.data?.allOffers?.totalCount){
        setCollegeIsAddedToOffer(res?.data?.data?.allOffers?.Offers);
      }
    })
  }, [])

  const onCloseInterestModal = () => {
    setShowInterestModal(false);
  }

  const onSelectHandler = async (interestLevelId) => {
    setShowInterestModal(false);
    props.handleAddCollege(null, props.data, interestLevelId);
    //this below code is added to update the suggested list when user add any clg
    setTimeout(()=>{
      props?.updateList()
    },1800)
  }

  const onAddCollage = () => {
    setShowInterestModal(true);
  }

  const handleCollegeClick = async (collgId) => {
    await fetchGraphMethod(saveViewCollegeQuery, {obj:{
      athleteid : getUserInfo() ? getUserInfo().id : null,
      collegeid : collgId,
      collegeviewstatus : 1,
    }}, true).then(res => { })
  }
  
  return (
    <>
      <div className="item">
        <div className="imgDataFlexBox">
          <div className="imgBox">
            <img src={!!props.data.collegelogo ? `${imageDownloadUrl}/${props.data.collegelogo}` : TeamLogo} alt="playerPic" />
          </div>
          <div className="dataFlexBox">
            <div>
              <h5>
                <Link to={`/colleges/details/${props.data.id}/${props.data.isfav == undefined ? 0 : 1}`} onClick={() => handleCollegeClick(props.data.id)}>
                {props.data ? props.data.collegename : ""}
                </Link>
              </h5>
              <ul>
                {!!props?.data?.conferencename &&
                <li>
                  <span>{props.data ? props.data.conferencename == null ? "" : props.data.conferencename : ""}</span>
                </li>
                }
                {/* <li>
                  <span>{props.data ? props.data.collegename : ''}</span>
                </li> */}                
              </ul>
              <ul>
                <li>
                  <span>{props.data ? `${props.data.city == null ? " " : getCamelCase(props.data.city)}, ${props.data.initials == null ? "" : props.data.initials}` : " "}</span>
                </li>
              </ul>
              <ul>
                <li>
                  <span>
                    {props.data ? props.data.collegedivisionname == null ? "" : props.data.collegedivisionname : ""}
                  </span>
                </li>
              </ul>
              {/* <p>Let's talk about baseball tournament !</p> */}
            </div>
            <div className="action">
              <Link
                to="#"
                className="followerLink"
                //onClick={(e) => props.handleAddCollege(e, props.data)}
                onClick={onAddCollage}
              >
                + Add College
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={showInterestModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            onCloseInterestModal()
          }
        }}
        className="matDialogCustom"
      >
        <InterestLevelModal
          interestValueList={props.interestLevelList}
          onClose={onCloseInterestModal}
          onSelect={onSelectHandler}
          collegeDetails={{'id' : props.data.id}}
        />
      </Dialog>
    </>
  );
}
