export const getMessageQuery = `
query allMessages($where:JSON,$first:Int,$last:Int){
  allMessages(where:$where,first:$first,last:$last){
    totalCount
    Messages{
      active
      id
      createdon
      message
      messagesendby
      messagesendto
      messagesendbyadmin
      messagesendtoadmin
      messagesendbyadvisor
      messagesendbycoachid
      messagesendtocoachid
      messagesendtoadvisorid
      sendmsgrole
      subject
      userrole
      istrash
      isread
      messagemessagesendtoadvisoridmaprel{
        Userdata{
          advisorUseridMap{
            Advisors{
              id
              firstname
              lastname
            }
          }
        }
      }
      messagereplyMsgidMap {
        Messagereplies{
          msg
          createdon
          deletebyathlete
          parentnamevalue
          messagereplymsgsenderidrel{
            Athletes{
              athletefirstname
              athletelastname
            }
          }
        }
      }
      messagemessagesendbymaprel{
        Athletes{
          id
          athletefirstname
          athletelastname
          athleteemailid
          onBoardingUserIdMap{
            Onboardings{
              profileimages
            }
          }
          
        }
      }
      messagemessagesendbyadminmaprel{
        Userdata{
          email
          userfullname
          id
          fullname
        }
      }
      messagemessagesendbyadvisormaprel{
        Userdata{
            email
            fullname
            id
            advisorUseridMap{
              Advisors{
                id
                firstname
                lastname
              }
            }
        }
      }
      messagemessagesendtomaprel{
        Athletes{
          athletefirstname
          athletelastname
          onBoardingUserIdMap{
            Onboardings{
              profileimages
            }
          }
        }
      }
      messagemessagesendtoadminrel{
        Userdata{
          fullname
        }
      }
    }
  }
}`;

export const saveMessageApi = `
mutation saveMessage($obj: MessageInput!){
  saveMessage(obj: $obj){
    id
  }
}
`;

export const getMessageReplyQuery = `
query allMessagereplies($where:JSON,$first:Int,$last:Int){
  allMessagereplies(where:$where,first:$first,last:$last){
    totalCount
    Messagereplies{
      active
      id
      createdon
      msg
      msgid
      msgreceiverid
      msgsenderid
      collegeid
      sendmsgrolevalue
      subject
      parentnamevalue
      archivebyathlete
      parentid
      messagereplyparentidmaprel{
        Userdata{
          athleteParentUserIdMap{
            Athleteparents{
              parentfirstname
              parentlastname
            }
          }
        }
      }
      messagereplymsgreceiveradminidmaprel{
        Userdata{
          id
          userfullname
          email
        }
      }
      messagereplymsgreceiveradvisoridmaprel{
        Userdata{
          id
          fullname
          email
        }
      }
      messagereplymsgreceivercoachidrel{
        Coachandteamdetails{
          id
          coachfirstname
          coachlastname
          coachemail
          isathletesendmsgtocoach
            coachandteamdetailscollegeidmaprel{
            Colleges{
              collegename
              allowtosendmsg
            }
          }
        }
      }
      messagereplymsgreceiveridmaprel{
        Athletes{
          athletelastname
          athletefirstname
          athleteemailid
          id
        }
      }
      messagereplymsgsenderadminidmaprel{
        Userdata{
          userfullname
          fullname
          email
          id
        }
      }
      messagereplymsgsenderadvisoridmaprel{
        Userdata{
          fullname
          email
          id
          advisorUseridMap{
            Advisors{
              firstname
              lastname
            }
          }
        }
      }
      messagereplymsgsendercoachidmaprel{
        Coachandteamdetails{
          id
          coachfirstname
          coachlastname
          coachemail
					isathletesendmsgtocoach
          coachandteamdetailscollegeidmaprel{
            Colleges{
              collegename
              allowtosendmsg
            }
          }
        }
      }
      messagereplymsgsenderidrel{
        Athletes{
            athletelastname
            athletefirstname
            athleteemailid
            id
            onBoardingUserIdMap {
              Onboardings{
                profileimages
              }
            }
        }
      }
    }
  }
}
`;

export const saveMessageReplyApi = `
mutation saveMessagereply($obj: MessagereplyInput!){
  saveMessagereply(obj: $obj){
    id
  }
}
`;

export const universityApiData = `
query allColleges($where: JSON){
  allColleges(where: $where){
    Colleges{
      id
      collegename
      allowtosendmsg
    }
  }
}
`;

export const coachApiData = `
query allCoachandteamdetails($where: JSON){
  allCoachandteamdetails(where: $where){
    Coachandteamdetails{
          id
          coachfirstname
          coachlastname
          userid
          isathletesendmsgtocoach
      		coachuserid
        }
      }
}
`;

export const messageReplyUpdate = `
mutation MessagereplyUpdateAll($where: JSON,$data: JSON){
  MessagereplyUpdateAll(where: $where, data: $data)
}
`;

export const premiumApiData = `
query allMemberships($where: JSON){
  allMemberships(where: $where){
   Memberships{
    subscriptionplanid
    id
  }
  }
}
`;

export const checkFollowApiData = `
query allFollowers($where: JSON){
  allFollowers(where:$where){
    totalCount
  }
}
`;

export const athleteParentQuery = `
query allAthleteparents($where: JSON){
  allAthleteparents(where:$where){
    Athleteparents{
      id
      parentfirstname
      parentlastname
      parentemail
    }
  }
}
`;

export const AddUserActivity = `
mutation saveUseracivity($obj:UseracivityInput!){
  saveUseracivity(obj:$obj){
    id
  }
}
`;

export const getAdvisorData = `
query allAdvisors($where: JSON){
  allAdvisors(where:$where){
    Advisors{
      id
      firstname
      lastname
      emailid
      userid
    }
  }
}
`;

export const getUserData = `
query AllUserdata($obj:JSON){
    allUserdata(where:$obj){
      Userdata{
        email
        id
      }
    }
  }
`;

export const getMessageUnreadCount = `
query allMessagereplies($where:JSON){
  allMessagereplies(where:$where){
    totalCount
  }
}`;

export const getAdvisorAthletes = `
query allAdvisorathletemappings($where:JSON){
  allAdvisorathletemappings(where:$where){
    Advisorathletemappings{
      advisorathletemappingadvisoridmaprel{
        Advisors{
          id
          userid
          firstname
          lastname
          emailid
        }
      }
    }
  }
}`;