import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  USER_LIST_START: "USER_LIST_START",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAILED: "USER_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const userListStart = (data) => ({
  type: actionType.USER_LIST_START,
  payload: data,
});

export const userListSuccess = (data) => ({
  type: actionType.USER_LIST_SUCCESS,
  payload: data,
});

export const userListFailed = (data) => ({
  type: actionType.USER_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function userListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.USER_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.USER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* userListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const userData = response.data.data.allUserdata.Userdata
        ? response.data.data.allUserdata.Userdata[0]
        : null;
      let tempUserinfo = userData ? userData : {};
      let onboardingData = null;

      const sportsName = tempUserinfo
        ? tempUserinfo.userdataathletesportsmaprel
          ? tempUserinfo.userdataathletesportsmaprel.Athletesports
            ? tempUserinfo.userdataathletesportsmaprel.Athletesports[0]
                .sportname
            : ""
          : ""
        : "";

      const graduationYear = tempUserinfo
        ? tempUserinfo.userdataathletegraduationyearmaprel
          ? tempUserinfo.userdataathletegraduationyearmaprel.Graduationyears
            ? tempUserinfo.userdataathletegraduationyearmaprel
                .Graduationyears[0].yearvalue
            : ""
          : ""
        : "";
      onboardingData = tempUserinfo
        ? tempUserinfo.onBoardingUserIdMap
          ? tempUserinfo.onBoardingUserIdMap.Onboardings
            ? tempUserinfo.onBoardingUserIdMap.Onboardings[0]
            : null
          : null
        : null;
      const followers = tempUserinfo
        ? tempUserinfo.followersFollowingIdMap
          ? tempUserinfo.followersFollowingIdMap.totalCount
          : 0
        : 0;
      const followings = tempUserinfo
        ? tempUserinfo.followersFollowerIdMap
          ? tempUserinfo.followersFollowerIdMap.totalCount
          : 0
        : 0;
      if (tempUserinfo.followersFollowingIdMap) {
        delete tempUserinfo.followersFollowingIdMap;
      }
      if (tempUserinfo.followersFollowerIdMap) {
        delete tempUserinfo.followersFollowerIdMap;
      }
      if (tempUserinfo.userdataathletegraduationyearmaprel) {
        delete tempUserinfo.userdataathletegraduationyearmaprel;
      }
      if (tempUserinfo.userdataathletesportsmaprel) {
        delete tempUserinfo.userdataathletesportsmaprel;
      }
      if (tempUserinfo.onBoardingUserIdMap) {
        delete tempUserinfo.onBoardingUserIdMap;
      }
      tempUserinfo["sportsName"] = sportsName;
      tempUserinfo["graduationYear"] = graduationYear;
      tempUserinfo["followers"] = followers;
      tempUserinfo["followings"] = followings;
      yield put(userListSuccess({ userData: tempUserinfo, onboardingData }));
    } else {
      messagePopup("", "User List failed", "error");
      yield put(userListFailed("User List failed"));
    }
  } catch (error) {
    messagePopup("", "User List failed", "error");
    yield put(userListFailed("User List failed"));
  }
}

export function* watchUserListSaga() {
  yield takeEvery(actionType.USER_LIST_START, userListSaga);
}
