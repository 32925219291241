import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  GPA_MAPPING_START: "GPA_MAPPING_START",
  GPA_MAPPING_SAVE_START: "GPA_MAPPING_SAVE_START",
  GPA_MAPPING_SUCCESS: "GPA_MAPPING_SUCCESS",
  GPA_MAPPING_FAILED: "GPA_MAPPING_FAILED",
};

// ============================================ Actions ========================================================
export const gpaMappingStart = (data) => ({
  type: actionType.GPA_MAPPING_START,
  payload: data,
});
export const gpaMappingSaveStart = (data) => ({
  type: actionType.GPA_MAPPING_SAVE_START,
  payload: data,
});

export const gpaMappingSuccess = (data) => ({
  type: actionType.GPA_MAPPING_SUCCESS,
  payload: data,
});

export const gpaMappingFailed = (data) => ({
  type: actionType.GPA_MAPPING_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function gpaMappingReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GPA_MAPPING_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.GPA_MAPPING_SAVE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.GPA_MAPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.GPA_MAPPING_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* gpaMappingListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const allGpaMapping = response.data.data;
      yield put(gpaMappingSuccess({ gpaMappingData: allGpaMapping }));
    } else {
      messagePopup("", "GPA MAPPING failed", "error");
      yield put(gpaMappingFailed("GPA MAPPING failed"));
    }
  } catch (error) {
    messagePopup("", "GPA MAPPING failed", "error");
    yield put(gpaMappingFailed("GPA MAPPING failed"));
  }
}
export function* gpaMappingSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const allGpaMapping = response.data.data;
      yield put(gpaMappingSuccess({ saveGpaMapping: allGpaMapping }));
    } else {
      messagePopup("", "GPA Save failed", "error");
      yield put(gpaMappingFailed("GPA Save failed"));
    }
  } catch (error) {
    messagePopup("", "GPA Save failed", "error");
    yield put(gpaMappingFailed("GPA Save failed"));
  }
}

export function* watchGpaMappingSaga() {
  yield takeEvery(actionType.GPA_MAPPING_START, gpaMappingListSaga);
  yield takeEvery(actionType.GPA_MAPPING_SAVE_START, gpaMappingSaveSaga);
}
