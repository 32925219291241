import React from "react";
import Verification from "./Verification";
import ScoutRate from "./ScoutRate";
import RecruitAdvisor from "./RecruitAdvisor";
import ImportantNotice from "./ImportantNotice";
import VerificationProcess from "./VerificationProcess";
import Checklist from "./Checklist";
import SuggestedCollegeBox from "./SuggestedCollegeBox";
import TrandingProfileBox from "./TrandingProfileBox";
import { useRouteMatch } from "react-router-dom";
import "./RightSideBar.scss";
import {getUserInfo, setUserInfo} from "../../../../../../services/authService";
import {useDispatch} from "react-redux";
import {fetchGraphMethod} from "../../../../../../services/httpService";
import {saveBasicUserProfileUpdateQuery} from "../../editUserProfile/BasicQuery";

function RightSideBar(props) {
  const user = getUserInfo() ? getUserInfo() : null;
  let { path, url } = useRouteMatch();
  const [verifiedsteps, setVerifiedSteps] = React.useState();
  const dispatch = useDispatch()
  const completedSteps = async (steps) => {
    if (steps >= 4 && user?.verifiedUserStatus == 0){
      const res = await fetchGraphMethod(saveBasicUserProfileUpdateQuery,{
        obj: {
          id: getUserInfo() ? getUserInfo().id : 0,
          verifieduser : 1
        }
      }, true)
      if (res?.status == 200){
        const cloneLS = JSON.parse(JSON.stringify(getUserInfo()))
        cloneLS.verifiedUserStatus = 1
        setUserInfo(cloneLS)
        dispatch({ type: "RENDER_COUNT_START", payload: 1 })
      }
    }
    setVerifiedSteps(steps);
  }
  return (
    <>
      {/* !path.includes("/trandingProfile") && !path.includes("/suggestedcolleges") this rendering done because in verfified profile we have to sticky the
      suggested college box only.*/}
      {user && user.verifiedUserStatus ? !path.includes("/trandingProfile") && !path.includes("/suggestedcolleges") ? (
        <>
        <Checklist />

        {!path.includes("/trendingProfile") ? <TrandingProfileBox /> : null}

        {!path.includes("/suggestedcolleges") ? (
          <div className="sticky-box">
            <SuggestedCollegeBox />
          </div>  
        ) : null}
      </>
      ) :(
        <>
          <Checklist />

          {!path.includes("/trandingProfile") ? (
            <div className="sticky-box">
              <TrandingProfileBox />
            </div>
          ) : null}

          {!path.includes("/suggestedcolleges") ? (
            <div className="sticky-box">
              <SuggestedCollegeBox />
            </div>
          ) : null}
        </>
      ) : null}
      {!user?.verifiedUserStatus ? (
        <>
          <Verification props={props} completedSteps={completedSteps}/>

          <ScoutRate />

          <RecruitAdvisor />

          {/*<ImportantNotice />*/}

          <VerificationProcess verifiedsteps={verifiedsteps}/>
        </>
      ) : null}
    </>
  );
}

export default RightSideBar;
