import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod, fetchMethod } from "../../services/httpService"
import messagePopup from "../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    ALLSPORTS_LIST_START: 'ALLSPORTS_LIST_START',
    ALLSPORTS_LIST_SUCCESS: 'ALLSPORTS_LIST_SUCCESS',
    ALLSPORTS_LIST_FAILED: 'ALLSPORTS_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const allsportsListStart = (data) => ({
    type: actionType.ALLSPORTS_LIST_START,
    payload: data
})

export const allsportsListSuccess = (data) => ({
    type: actionType.ALLSPORTS_LIST_SUCCESS,
    payload: data
})

export const allsportsListFailed = (data) => ({
    type: actionType.ALLSPORTS_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function allsportsListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.ALLSPORTS_LIST_START:
            return {
                ...state,
                loading: true
            }
        case actionType.ALLSPORTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        case actionType.ALLSPORTS_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        default: return { ...state }
    }
}

// ===================================================== side effect =============================================

export function* allsportsListSaga(action) {
    try {
        const response = yield fetchMethod(`/allAthleteSports`, null, 'get', false);
        if (response && response.status === 200) {
            let sportsArray = []
            if (response.data.data) {
                response.data.data.map(sports => {
                    sportsArray.push({ "id": sports.id, "sportName": sports.sportforgender == "Male" ? "Mens's " + sports.sportName : "women's " + sports.sportName })
                })
            }
            const Athletesports = response.data.data ? sportsArray : null
            yield put(allsportsListSuccess(Athletesports));
        } else {
            messagePopup('', 'Sports List failed', 'error')
            yield put(allsportsListFailed('Sports List failed'))
        }
    } catch (error) {
        messagePopup('', 'Sports List failed', 'error')
        yield put(allsportsListFailed('Sports List failed'))
    }
}

export function* watchAllSportsListSaga() {
    yield takeEvery(actionType.ALLSPORTS_LIST_START, allsportsListSaga)
}