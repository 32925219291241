import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../../../../../../assets/css/stylesheet.scss';
import '../assets/css/myColleges.scss';
import CollegeCard from './CollegeCard';
import CountCard from './CountCard';
import CollegesTabs from '../CollegesTabs';
import { connect } from 'react-redux';
import BackdropLoader from '../../../../../common/Loader';
import nothing from '../assets/img/nothingFound.svg';
import {
  getCategoryListQuery,
  getCollegesCountsQuery,
  getCollegesListQuery,
  saveUserCollegeQuery,
} from './CollegeListQuery';
import { getAllOfferQuery } from '../../editUserProfile/OfferQuery';
import { getUserInfo } from '../../../../../../services/authService';
import { Link, useLocation } from 'react-router-dom';
import {
  fetchMethod,
  fetchGraphMethod,
} from '../../../../../../services/httpService';
import { categoryListStart } from '../../../../../../redux/athlete/dashboard/colleges/categoryList';
import { offersListStart } from '../../../../../../redux/athlete/dashboard/editUserProfile/offers';
import { updateUserCollegeStart } from '../../../../../../redux/athlete/dashboard/colleges/updateUserCollege';
import { userCollegeCardCountStart } from '../../../../../../redux/athlete/dashboard/colleges/userCollegeCardCount';
import { interestLevelListStart } from '../../../../../../redux/common/interestLevelList';
import { getAllInterestLevelQuery } from '../../../../../common/CommonQuery';
import toastr from 'toastr';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SortBy } from '../../../../../../constants';
import {
  getMyCommitmentQuery,
  getMySignedCommitmentQuery,
} from '../../homepage/leftSideBar/query';
import {
  getCommitmentStart,
  addMyCommitmentStart,
} from '../../../../../../redux/athlete/profile/commitment';

function Colleges(props) {
  const [cardCount, setCardCount] = useState();
  const [sortBy, setSortBy] = useState(4); // 4 means default sort will be by Rank
  const [numColleges, setNumColleges] = useState(10);
  const [collegesList, setCollegesList] = useState([]); // props.searchCollegeList.data?.colleges
  const [loading, setLoading] = useState(false);
  const [mySignedCommitment, setMySignedCommitment] = useState([]);
  const observer = useRef();

  // Passing state from dashboard route
  const { state } = useLocation();
  const [fields, setFields] = useState({
    openFilter: false,
    pageCount: 0,
    offset: 1,
    page: '',
    getPage: 0,
    searchPage: 0,
    perPage: 10,
    go: 1,
    currentCard: 'target',
  });
  const [filters, setFilters] = useState({
    collegeName: '',
    favorite: 'any',
    promotions: 'any',
    Level: [],
    athleticSelectivity: [],
    AcademicSelectivity: [],
    StateRegion: [],
    CollegeSize: [],
    TuitionCost: [],
    Settting: [],
    Type: [],
    AreaStudy: [],
    conference: [],
    searchStateRegion: '',
    contact: false,
  });
  const [countCardFilters, setCountCardFilters] = useState({
    total: true,
    contact: false,
    interest: false,
    active: false,
    offer: false,
    commit: false,
  });

  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer?.current) observer?.current?.disconnect();
      observer.current = new IntersectionObserver(
        (entires) => {
          if (entires[0].isIntersecting && cardCount.total > numColleges) {
            setNumColleges((prevNumColleges) => prevNumColleges + 10);
          }
        },
        { threshold: 0.1 }
      );
      if (node) observer.current.observe(node);
    },
    [loading, cardCount, numColleges]
  );

  const getSearchCollegesData = async () => {
    if (fields.page < 0 || fields.perPage <= 0) {
      toastr.warning('Invalid data', '', {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: 'toast-top-full-width',
        showEasing: 'swing',
        fadeIn: 40000,
        preventDuplicates: true,
      });
    } else {
      try {
        const { collegeName, Type } = filters;
        const variables = {
          collegeNameAndCity: collegeName,
          state: [],
          academicsSelectivity: [],
          collegeSize1: [],
          level: [],
          athleticselectivity: [],
          setting: [],
          type: Type,
          favoriteValue: 'any',
          areaOfStudy: [],
          collegeTutionFee1: [],
          first: 0,
          last: numColleges,
          collegeConference: [],
          sortby: sortBy,
          userId: getUserInfo() ? getUserInfo().id : null,
          ...countCardFilters,
        };
        setLoading(true);
        const response = await fetchMethod(
          `/api/Colleges/searchCollegeDetails`,
          variables,
          'post',
          true
        );
        setLoading(false);
        setCollegesList(response.data.response.data.data);
      } catch (e) {
        setLoading(false);
        toastr.error('Failed to get My Colleges list', '', {
          progressBar: true,
          timeOut: 2000,
          closeButton: true,
          positionClass: 'toast-top-full-width',
          showEasing: 'swing',
          fadeIn: 40000,
          preventDuplicates: true,
        });
      }
    }
  };

  useEffect(() => {
    getSearchCollegesData();
  }, [numColleges, sortBy, countCardFilters]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleOnChooseDnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      collegesList,
      result.source.index,
      result.destination.index
    );

    const updatedCollegeRank = items.map((item, index) => {
      return { userCollegeId: item.userCollegeId, rank: index + 1 };
    });

    await updateUserCollegeRank({
      userId: getUserInfo() ? getUserInfo().id : 0,
      tableName: 'usercollege',
      updatedCollegeRank,
    });

    getSearchCollegesData();
  };

  const updateUserCollegeRank = async (data) => {
    try {
      setLoading(true);
      const response = await fetchMethod(
        `/api/Usercolleges/changeRankOfCollege`,
        data,
        'post',
        true
      );
      setLoading(false);
      if (response && response.status !== 200) {
        toastr.error('College update failed', '', {
          progressBar: true,
          timeOut: 2000,
          closeButton: true,
          positionClass: 'toast-top-full-width',
          showEasing: 'swing',
          fadeIn: 40000,
          preventDuplicates: true,
        });
      }
    } catch (error) {
      toastr.error('College update failed', '', {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: 'toast-top-full-width',
        showEasing: 'swing',
        fadeIn: 40000,
        preventDuplicates: true,
      });
    }
  };

  const handleSearchCollege = () => {
    props.history.push('/colleges/search');
  };

  const getAllOfferList = () => {
    props.offersListStart({
      query: getAllOfferQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };

  const getAllCommitmentList = () => {
    props.getCommitmentStart({
      query: getMyCommitmentQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
          order: 'id DESC',
        },
      },
    });
  };

  const getMySignedCommitment = async () => {
    await fetchGraphMethod(
      getMySignedCommitmentQuery,
      {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
      true
    ).then((res) => {
      if (res?.data?.data?.allSignedcommitments?.totalCount) {
        setMySignedCommitment(
          res?.data?.data?.allSignedcommitments?.Signedcommitments
        );
      } else {
        setMySignedCommitment([]);
      }
    });
  };

  useEffect(() => {
    props.categoryListStart({
      query: getCategoryListQuery,
    });
    props.interestLevelListStart({
      query: getAllInterestLevelQuery,
      variables: null,
    });
    getCardCount();
    if (!!props?.location?.state?.active) {
      filterOnCardClick(props?.location?.state?.active);
    }
    getAllOfferList();
    getAllCommitmentList();
    getMySignedCommitment();

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  useEffect(() => {
    if (props.userCollegeCardCount.data) {
      const cardCountT = props.userCollegeCardCount.data;
      setCardCount(cardCountT);
    }
  }, [props.userCollegeCardCount.data]);

  const getCollegesList = () => {
    props.collegesListStart({
      query: getCollegesListQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          order: 'collegerank ASC',
          active: 1,
          isfav: 1,
        },
        first: fields.getPage * fields.perPage,
        last: +fields.perPage,
      },
    });
    getCardCount();
  };

  const getCardCount = () => {
    props.userCollegeCardCountStart({
      query: getCollegesCountsQuery(getUserInfo().id),
    });
  };

  const handleStepperUpdate = (userCollegeId, step) => {
    const variables = {
      obj: {
        userid: getUserInfo() ? getUserInfo().id : 0,
        id: userCollegeId,
        ...setStepperValue(step),
      },
    };
    props.updateUserCollegeStart({
      query: saveUserCollegeQuery,
      variables,
      getCardCount: getCardCount,
      getCollegesList,
    });
  };

  const handleUpdateCategory = (userCollegeId, categoryId) => {
    const variables = {
      obj: {
        userid: getUserInfo() ? getUserInfo().id : 0,
        id: userCollegeId,
        categoryid: Number(categoryId),
      },
    };
    props.updateUserCollegeStart({
      query: saveUserCollegeQuery,
      variables,
      getCollegesList,
    });
  };

  const handleUpdateInterest = (data, value) => {
    const variables = {
      obj: {
        userid: data.userid ? data.userid : null,
        id: data.id ? data.id : null,
        collegeinterestlevel: +value,
      },
    };
    props.updateUserCollegeStart({
      query: saveUserCollegeQuery,
      variables,
      getCollegesList,
    });
  };

  const filterOnCardClick = async (value, isScroll) => {
    let data = {
      userid: getUserInfo() ? getUserInfo().id : 0,
      isfav: 1,
      active: 1,
    };
    if (value === 'target') {
      data = {
        userid: getUserInfo() ? getUserInfo().id : 0,
        target: 1,
        active: 1,
        isfav: 1,
      };
    } else if (value === 'contact') {
      data = {
        userid: getUserInfo() ? getUserInfo().id : 0,
        contact: 1,
        active: 1,
        isfav: 1,
      };
    } else if (value === 'interest') {
      data = {
        userid: getUserInfo() ? getUserInfo().id : 0,
        interest: 1,
        active: 1,
        isfav: 1,
      };
    } else if (value === 'collegeactive') {
      data = {
        userid: getUserInfo() ? getUserInfo().id : 0,
        collegeactive: 1,
        active: 1,
        isfav: 1,
      };
    } else if (value === 'offer') {
      data = {
        userid: getUserInfo() ? getUserInfo().id : 0,
        offer: 1,
        active: 1,
        isfav: 1,
      };
    } else if (value === 'commit') {
      data = {
        userid: getUserInfo() ? getUserInfo().id : 0,
        commit: 1,
        active: 1,
        isfav: 1,
      };
    }
    const variables = isScroll
      ? {
          obj: data,
          first: fields.getPage * (fields.perPage + 10),
          last: +fields.perPage + 10,
        }
      : {
          obj: data,
          first: fields.getPage * fields.perPage,
          last: +fields.perPage,
        };
    props.collegesListStart({
      query: getCollegesListQuery,
      variables,
    });
    const fieldsT = fields;
    fieldsT['perPage'] = fields.perPage + 10;

    fieldsT['currentCard'] = value;
    setFields(fieldsT);
  };

  return (
    <div className="pageContent">
      <div className="collegeListing" id={'clgListingID'}>
        <div className="container">
          <div className="headerFlex">
            <CollegesTabs active="list" />
          </div>
          <div className="dataSection">
            <div className="dataTabsHead">
              <CountCard
                cardCount={cardCount}
                setCountCardFilters={setCountCardFilters}
                activeTab={state?.active ? state.active : ''}
              />
            </div>
            {collegesList ? (
              <div className="sortFilter">
                <form>
                  <label>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.163"
                        height="15.204"
                        viewBox="0 0 12.163 15.204"
                      >
                        <path
                          id="bx-sort"
                          d="M9.041,13.643H6L10.561,18.2V3H9.041ZM13.6,5.281V18.2h1.52V7.561h3.041L13.6,3Z"
                          transform="translate(-6 -3)"
                          fill="#3e3e3e"
                        />
                      </svg>
                    </span>
                    <span>Sort by</span>
                  </label>
                  <select
                    value={sortBy}
                    onChange={(e) => setSortBy(Number(e.target.value))}
                  >
                    <option value={SortBy.RANK}>Rank</option>
                    <option value={SortBy.CATEGORY}>Category</option>
                    <option value={SortBy.LAST_UPDATED}>Last Updated</option>
                    <option value={SortBy.INTEREST_DESC}>
                      Most Interested In Me
                    </option>
                    <option value={SortBy.INTEREST_ASC}>
                      Least Interested In Me
                    </option>
                  </select>
                </form>
              </div>
            ) : (
              ''
            )}
            <div className="dataBox">
              {collegesList ? (
                <DragDropContext onDragEnd={handleOnChooseDnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {collegesList.map((item, index) => {
                          return (
                            <Draggable
                              key={item?.userCollegeId}
                              draggableId={'item' + item?.userCollegeId}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    ref={
                                      index === collegesList.length - 3
                                        ? lastBookElementRef
                                        : null
                                    }
                                  >
                                    <CollegeCard
                                      data={item}
                                      collageIndex={index + 1}
                                      route={props}
                                      key={index}
                                      collegeId={item.collegeId}
                                      userCollegeId={item.userCollegeId}
                                      categories={
                                        props.categories
                                          ? props.categories.data
                                          : null
                                      }
                                      interestLevels={
                                        props.interestLevelDetails
                                          ? props.interestLevelDetails.data
                                            ? props.interestLevelDetails.data
                                            : null
                                          : null
                                      }
                                      logo={item.collegelogo}
                                      name={item.collegename}
                                      rank={item.collegerank}
                                      conference={item.conferencename}
                                      city={item.city}
                                      stateInitials={item.initials}
                                      state={item.state}
                                      division={item.collegedivisionname}
                                      stepperSteps={getFormattedStepperValue(
                                        item
                                      )}
                                      categoryId={item.categoryId}
                                      interestLevelId={
                                        item.collegeinterestlevel
                                      }
                                      updatedOn={item.updatedOn}
                                      academicSelectivity={
                                        item.acadamicSelectivityName
                                      }
                                      schoolSize={item.collegeStrength}
                                      tuitionInCost={
                                        item.collegetutioninsidecost
                                      }
                                      tuitionOutCost={
                                        item.collegetutionoutsidecost
                                      }
                                      lat={item.collegelat}
                                      lng={item.collegelng}
                                      isTagged={item.istagged}
                                      commit={item.commit}
                                      offer={item.offer}
                                      collegesList={collegesList}
                                      sortBy={sortBy}
                                      commitmentList={props.commitmentList}
                                      mySignedCommitment={mySignedCommitment}
                                      myOffers={
                                        props.allOffersList?.data?.Offers
                                      }
                                      handleStepperUpdate={handleStepperUpdate}
                                      handleUpdateCategory={
                                        handleUpdateCategory
                                      }
                                      handleUpdateInterest={
                                        handleUpdateInterest
                                      }
                                      getSearchCollegesData={
                                        getSearchCollegesData
                                      }
                                      getCardCount={getCardCount}
                                      getMySignedCommitment={
                                        getMySignedCommitment
                                      }
                                      getAllCommitmentList={
                                        getAllCommitmentList
                                      }
                                      getAllOfferList={getAllOfferList}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div className="blankList">
                  <div className="notFound">
                    <img src={nothing} alt="Nothing found" />
                  </div>
                  <h2>Add your dream college to your list</h2>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    onClick={handleSearchCollege}
                  >
                    Search college
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BackdropLoader open={loading} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allOffersList: state.athlete.editUserProfileData.offersData,
    categories: state.athlete.dashboard.colleges.categories,
    updateUserCollege: state.athlete.dashboard.colleges.updateUserCollege,
    userCollegeCardCount: state.athlete.dashboard.colleges.userCollegeCardCount,
    interestLevelDetails: state.common.interestLevelList,
    commitmentList: state.athlete.profile.commitments,
    myCommitment: state.athlete.profile.commitments,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    offersListStart: (data) => dispatch(offersListStart(data)),
    categoryListStart: (data) => dispatch(categoryListStart(data)),
    updateUserCollegeStart: (data) => dispatch(updateUserCollegeStart(data)),
    userCollegeCardCountStart: (data) =>
      dispatch(userCollegeCardCountStart(data)),
    interestLevelListStart: (data) => dispatch(interestLevelListStart(data)),
    getCommitmentStart: (data) => dispatch(getCommitmentStart(data)),
    addMyCommitmentStart: (data) => dispatch(addMyCommitmentStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Colleges);

const getFormattedStepperValue = (data) => {
  let finalvalue = 1;
  if (data.target) {
    finalvalue = 1;
  }
  if (data.contact) {
    finalvalue = 2;
  }
  if (data.interest) {
    finalvalue = 3;
  }
  if (data.collegeactive) {
    finalvalue = 4;
  }
  if (data.offer) {
    finalvalue = 5;
  }
  if (data.commit) {
    finalvalue = 6;
  }
  if (data.signed) {
    finalvalue = 7;
  }
  return finalvalue;
};

const setStepperValue = (value) => {
  let finalvalue = {
    target: 0,
    contact: 0,
    interest: 0,
    collegeactive: 0,
    offer: 0,
    commit: 0,
    signed: 0,
  };
  switch (value) {
    case 1:
      finalvalue = { ...finalvalue, target: 1 };
      break;
    case 2:
      finalvalue = { ...finalvalue, target: 1, contact: 1 };
      break;
    case 3:
      finalvalue = { ...finalvalue, target: 1, contact: 1, interest: 1 };
      break;
    case 4:
      finalvalue = {
        ...finalvalue,
        target: 1,
        contact: 1,
        interest: 1,
        collegeactive: 1,
      };
      break;
    case 5:
      finalvalue = {
        ...finalvalue,
        target: 1,
        contact: 1,
        interest: 1,
        collegeactive: 1,
        offer: 1,
      };
      break;
    case 6:
      finalvalue = {
        ...finalvalue,
        target: 1,
        contact: 1,
        interest: 1,
        collegeactive: 1,
        offer: 1,
        commit: 1,
      };
      break;
    case 7:
      finalvalue = {
        ...finalvalue,
        target: 1,
        contact: 1,
        interest: 1,
        collegeactive: 1,
        offer: 1,
        commit: 1,
        signed: 1,
      };
      break;
    default:
      finalvalue = { ...finalvalue, target: 1 };
  }
  return finalvalue;
};
