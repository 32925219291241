export const getAllBattingPreferencesQuery = `
  query allBattingpreferences($obj: JSON){
        allBattingpreferences(where: $obj){
            Battingpreferences{
                battingpreferencevalue
                id
                active
          }
        }
      }
  `;

export const getAllThrowingPreferencesQuery = `
  query allThrowingpreferences($obj: JSON){
        allThrowingpreferences(where: $obj){
            Throwingpreferences{
                throwingpreferencevalue
                id
                active
          }
        }
      }
  `;