import { all, fork } from "redux-saga/effects";
import { watchHowToVideo } from "./howToVideo";
import { watchResourceLibrary } from "./resourceLibrary";
import { watchResourceHeader } from "./resourcesHeader";
import { saveViewVideo } from './viewVideo'
import {saveAthleteViewVideo} from './saveAthleteIsViewVideo'
export default function* rootResourcesSagas() {
  yield all([
    fork(watchResourceHeader),
    fork(watchHowToVideo),
    fork(watchResourceLibrary),
    fork(saveViewVideo),
    fork(saveAthleteViewVideo),
  ]);
}
