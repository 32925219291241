import { combineReducers } from "redux";
import coachActValueReducer from "./myRecruits/allCoachActValues";
import coachGpaRangeListReducer from "./myRecruits/allCoachgparanges";
import coachSatValueReducer from "./myRecruits/allCoachSatValue";
import getRequirementReducer from "./myRecruits/allCoachRequirements";
import getRecruitingReducer from "./myRecruits/recruitingList";
import getRecruitingPhilosophyReducer from "./myRecruits/recruitingPhilosophy";
import updateAllRecruitingReducer from "./myRecruits/updateAllRecruitingPhilosophy";
import getClaimProfileCoachReducer from "./searchClaimprofileCoach";
import getCollegeCoachReducer from "./searchCoachBySportIdCollegeId";
import coachCatchersTimeRangesReducer from "./myRecruits/allCoachCatchersTimeRanges";
import coachProgramsReducer from "./myRecruits/allCoachPrograms";
import geographicListReducer from "./myRecruits/geographic";

export default combineReducers({
    claimProfileCoach : getClaimProfileCoachReducer,
    collegeCoaches : getCollegeCoachReducer,
    recruitingList : getRecruitingReducer,
    recruitingPhilosophy : getRecruitingPhilosophyReducer,
    updateAllRecruiting : updateAllRecruitingReducer,
    coachGpaRange : coachGpaRangeListReducer,
    coachSatValue : coachSatValueReducer,
    coachActValues : coachActValueReducer,
    coachRequirements : getRequirementReducer,
    coachCatchersTimeRanges :coachCatchersTimeRangesReducer,
    coachPrograms : coachProgramsReducer,
    geographicList: geographicListReducer
  });