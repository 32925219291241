import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  BASIC_USER_PROFILE_LIST_START: "BASIC_USER_PROFILE_LIST_START",
  BASIC_USER_PROFILE_LIST_SUCCESS: "BASIC_USER_PROFILE_LIST_SUCCESS",
  BASIC_USER_PROFILE_LIST_FAILED: "BASIC_USER_PROFILE_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const basicUserProfileListStart = (data) => ({
  type: actionType.BASIC_USER_PROFILE_LIST_START,
  payload: data,
});

export const basicUserProfileListSuccess = (data) => ({
  type: actionType.BASIC_USER_PROFILE_LIST_SUCCESS,
  payload: data,
});

export const basicUserProfileListFailed = (data) => ({
  type: actionType.BASIC_USER_PROFILE_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function basicUserProfileListReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.BASIC_USER_PROFILE_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.BASIC_USER_PROFILE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.BASIC_USER_PROFILE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* basicUserProfileListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const userData = response.data.data.allAthletes.Athletes
        ? response.data.data.allAthletes.Athletes[0]
        : null;
        if(action.payload.routeNextPage){
          action.payload.routeNextPage()
        }
      let tempUserinfo = userData ? userData : {};
      let onboardingData = null;
      onboardingData = tempUserinfo
        ? tempUserinfo.onBoardingUserIdMap
          ? tempUserinfo.onBoardingUserIdMap.Onboardings
            ? tempUserinfo.onBoardingUserIdMap.Onboardings[0]
            : ""
          : ""
        : "";
      if (tempUserinfo.onBoardingUserIdMap) {
        delete tempUserinfo.onBoardingUserIdMap;
      }

      //   tempUserinfo["sportsName"] = sportsName;

      yield put(
        basicUserProfileListSuccess({ userData: tempUserinfo, onboardingData })
      );
    } else {
      messagePopup("", "Basic User Profile List failed", "error");
      yield put(basicUserProfileListFailed("Basic User Profile List failed"));
    }
  } catch (error) {
    messagePopup("", "Basic List failed", "error");
    yield put(basicUserProfileListFailed("Basic User Profile List failed"));
  }
}

export function* watchBasicUserProfileListSaga() {
  yield takeEvery(
    actionType.BASIC_USER_PROFILE_LIST_START,
    basicUserProfileListSaga
  );
}
