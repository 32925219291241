import { Dialog } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../../../../assets/css/stylesheet.scss";
import { battingPreferenceListStart } from "../../../../../redux/athlete/onBoarding/battingPreferences";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { throwingPreferenceListStart } from "../../../../../redux/athlete/onBoarding/throwingPreferences";
import { getUserInfo } from "../../../../../services/authService";
import BackdropLoader from "../../../../common/Loader";
import "../assets/css/onBoardBatThrow.scss";
import "../assets/css/onBoardSteeper.scss";
import { getOnboardingQuery, saveOnboardingQuery } from "../OnboardingQuery";
import {
  getAllBattingPreferencesQuery,
  getAllThrowingPreferencesQuery,
} from "./BatThrowQuery";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import "../SkitPopup/SkipScreen.scss";
import usePrevious from "../../../../../utils/usePrevious";

import Stepper from "../Stepper";
import messagePopup from "../../../../../utils/messagePopup";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "passed", link: "/onboarding/positions" },
  { className: "passed", link: "/onboarding/physicalMeasurements" },
  { className: "passed", link: "/onboarding/personalRecords" },
  { className: "passed", link: "/onboarding/firstYearVarsity" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  // { className: "" },
];

function BatThrow(props) {
  const [fields, setFields] = React.useState({});
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    props.history.push("/onboarding/schoolInfo");
  };

  useEffect(() => {
    setFields({});
    props.battingPreferenceListStart({
      query: getAllBattingPreferencesQuery,
      variables: { obj: { active: 1 } },
    });
    props.throwingPreferenceListStart({
      query: getAllThrowingPreferencesQuery,
      variables: { obj: { active: 1 } },
    });
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = fields;
        const { id, battingprefencevalue, throwingprefencevalue } =
          props.onboarding.data;
        fieldT["id"] = id;
        fieldT["battingPreference"] = battingprefencevalue;
        fieldT["throwingPreference"] = throwingprefencevalue;
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);

  const handleNext = () => {
    if (fields?.battingPreference || fields?.throwingPreference) {
      props.saveOnboardingStart({
        query: saveOnboardingQuery,
        variables: {
          obj: {
            id: fields.id ? fields.id : 0,
            userid: getUserInfo() ? getUserInfo().id : 0,
            battingprefencevalue: fields.battingPreference
              ? +fields.battingPreference
              : null,
            throwingprefencevalue: fields.throwingPreference
              ? +fields.throwingPreference
              : null,
          },
        },
        nextScreen: routeNextPage,
      });
    } else {
      messagePopup("", "Please Select The Bat/Throw", "error");
    }
  };

  const handleChange = (field, e) => {
    let fieldsT = fields;
    fieldsT[field] = e.target.value;
    setFields({
      ...fieldsT,
    });
  };

  return (
    <div>
      <BackdropLoader
        open={
          props.battingPreferences.loading ||
          props.throwingPreferences.loading ||
          props.onboarding.loading
        }
      />
      <div className="onBoardBatThrowSection">
        <div className="batThrowBlock">
          <div className="stepper">
            <Stepper stepperName="batthrow" stepperData={stepperData} />
          </div>
          <div className="formSection">
            <h1 className="heading">Bat / Throw</h1>
            <div className="fieldBox">
              <label>Batting Preference</label>
              <select
                value={fields.battingPreference || ""}
                onChange={(e) => handleChange("battingPreference", e)}
              >
                <option value="">Select batting</option>
                {props.battingPreferences.data &&
                  props.battingPreferences.data.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.battingpreferencevalue}
                    </option>
                  ))}
              </select>
            </div>
            <div className="fieldBox">
              <label>Throwing Preference</label>
              <select
                value={fields.throwingPreference || 0}
                onChange={(e) => handleChange("throwingPreference", e)}
              >
                <option value="">Select Throw</option>
                {props.throwingPreferences.data &&
                  props.throwingPreferences.data.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.throwingpreferencevalue}
                    </option>
                  ))}
              </select>
            </div>
            <p className="skip">
              Don't know?{" "}
              <Link to="#" onClick={handleClickOpen}>
                Skip it
              </Link>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="#" onClick={handleNext}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            handleClose()
          }
        }}
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    battingPreferences: state.athlete.onBoarding.battingPreferences,
    throwingPreferences: state.athlete.onBoarding.throwingPreferences,
    onboarding: state.athlete.onBoarding.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    battingPreferenceListStart: (data) =>
      dispatch(battingPreferenceListStart(data)),
    throwingPreferenceListStart: (data) =>
      dispatch(throwingPreferenceListStart(data)),
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatThrow);
