import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchMethod } from "../../../../services/httpService";
import { imageDownloadUrl } from "../../../../config";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_POSTS_START: "GET_POSTS_START",
  GET_POSTS_SUCCESS: "GET_POSTS_SUCCESS",
  GET_POSTS_FAILURE: "GET_POSTS_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const getPostStart = (data) => ({
  type: actionType.GET_POSTS_START,
  payload: data,
});

export const getPostSuccess = (data) => ({
  type: actionType.GET_POSTS_SUCCESS,
  payload: data,
});

export const getPostFailure = (data) => ({
  type: actionType.GET_POSTS_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function postReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_POSTS_START:
      return { ...state, loading: true };

    case actionType.GET_POSTS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.GET_POSTS_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state, loading: false };
  }
}

/* ======================================= Side Effects ==========================================*/


function* getPostSaga(action) {
  const {
    loginUserId,
    firstDropDownValue,
    secondDropDownValue,
    limit,
    offset,
  } = action.payload;
  try {
    const response = yield fetchMethod(
      `/api/Userposts/myFeed?loginUserId=${loginUserId}&firstDropDownValue=${firstDropDownValue}&secondDropDownValue=${secondDropDownValue}&limit=${limit}&offset=${offset}`,
      action.payload.data,
      "get",
      true
    );
    if (
      response.data &&
      response.data.response &&
      response.data.response.status === 200
    ) {
      const data = response.data.response.myFeedData;
      let finalData = [];
      if (data.allFavCollege && data.allFavCollege.length) {
        finalData = [
          ...finalData,
          ...data.allFavCollege.map((item) => {
            item["postType"] = "favorite";
            if (item.profileImages) {
              item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.Event && data.Event.length) {
        finalData = [
          ...finalData,
          ...data.Event.map((item) => {
            item["postType"] = "event";
            if (item.profileImages) {
              item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.allEvent && data.allEvent.length) {
        finalData = [
          ...finalData,
          ...data.allEvent.map((item) => {
            item["postType"] = "eventNew";
            if (item.profileImages) {
              item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.allMessage && data.allMessage.length) {
        finalData = [
          ...finalData,
          ...data.allMessage.map((item) => {
            item["postType"] = "message";
            if (item.msgSendByProfileImage) {
              item.msgSendByProfileImage = `${imageDownloadUrl}/${item.msgSendByProfileImage}`;
            }
            if (item.msgSendToProfileImage) {
              item.msgSendToProfileImage = `${imageDownloadUrl}/${item.msgSendToProfileImage}`;
            }
            return item;
          }),
        ];
      }
      if (data.allPost && data.allPost.length) {
        finalData = [
          ...finalData,
          ...data.allPost.map((item) => {
            item["postType"] = "post";
            if (item.profileImages) {
              item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.allProfile && data.allProfile.length) {
        finalData = [
          ...finalData,
          ...data.allProfile.map((item) => {
            item["postType"] = "profile";
            if (item.profileViewedByImageName) {
              item.profileViewedByImageName = `${imageDownloadUrl}/${item.profileViewedByImageName}`;
            }
            if (item.profileViewedToImageName) {
              item.profileViewedToImageName = `${imageDownloadUrl}/${item.profileViewedToImageName}`;
            }
            return item;
          }),
        ];
      }
      // const sortedData = finalData.sort((a, b) => {
      //   return b.serialNo - a.serialNo;
      // });
      
      const sortedData = finalData.sort((a, b) => {
        // return b.serialNo - a.serialNo;
        return (
          new Date(b.userActivityUpdatedOn) -
          new Date(a.userActivityUpdatedOn)
        );
      });

      yield put(
        getPostSuccess({ allData: sortedData, totalCount: data.totalCount })
      );
    }else if (
      response.data &&
      response.data.response &&
      response.data.response.status === 201
    ){
      //this is commented as res is coming "no data found"
      yield put(getPostFailure("Failed, Error occured"));
    }else if( response.data &&
      response.data.response &&
      response.data.response.status === 401){
      //this is commented as res is coming "no data found"
        yield put(getPostFailure("Failed, Error occured"));
      }else {
      messagePopup("", "Fetch Post Data Failed", "error");
      yield put(getPostFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch Post Data Failed", "error");
    yield put(getPostFailure("Failed, Error occured"));
  }
}

export function* watchPost() {
  yield takeEvery(actionType.GET_POSTS_START, getPostSaga);
}
