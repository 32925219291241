import React, { useState } from "react";
import "../../../../../assets/css/stylesheet.scss";
import { Modal } from "@mui/material";
import "../assets/css/areYouSure.scss";
import { useHistory } from 'react-router-dom';

function AreYouSure(props) {
  let history = useHistory();
  const [sureModal, setSureModal] = useState(true);

  const handleYes = () => {
    history.push('/sportforceAthleteSignup/step1')
  }
  const closeButton = () => {
    window.location.reload();
    setSureModal(false)
    history.push(`/publicView/0/${props?.athleteID}`)
  }
  return (
    <div>
      <Modal open={sureModal}>
        <div className="popUpSection">
          <div className="popUpBlock">
            <div className="closeButton">
              <button
                // onClick={() => props.closeButton()}
                  onClick={()=>props.confirmModal(false)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.804"
                    height="15.803"
                    viewBox="0 0 15.804 15.803"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g className="a" transform="translate(-6.776 -6.776)">
                      <path
                        d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </div>
            <div className="circle">
              <div className="icon2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.563"
                  height="34.004"
                  viewBox="0 0 20.563 34.004"
                >
                  <defs>
                    <style>.a{`fill:#3e3e3e;`}</style>
                  </defs>
                  <g transform="translate(-11.813 -7.875)">
                    <path
                      className="a"
                      d="M11.813,17.47h5c0-2.958,1.734-5.243,5.152-5.243,2.595,0,4.974,1.3,4.974,4.425,0,2.406-1.418,3.512-3.655,5.194-2.549,1.852-4.568,4.016-4.425,7.528l.027,1.754h4.952V29.783c0-2.719,1.034-3.512,3.825-5.63,2.307-1.754,4.713-3.7,4.713-7.789,0-5.725-4.834-8.489-10.126-8.489-5.024,0-10.555,2.451-10.434,9.6Zm5.916,20.9a3.568,3.568,0,0,0,3.825,3.512,3.553,3.553,0,1,0,0-7.073,3.562,3.562,0,0,0-3.822,3.561Z"
                      transform="translate(0)"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <p className="info">Are you sure this is not you?</p>
            <div className="btns">
              <button
                onClick={() => handleYes()}
                className="btn blue-light"
                style={{
                  background: "#00B9FF",
                  marginLeft: "155px",
                  padding: "8px 28px",
                }}
              >
                Yes
              </button>
              <button
                onClick={() => closeButton()}
                className="btn primary"
                style={{ marginLeft: "30px", padding: "8px 28px" }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AreYouSure;
