export function unixTimestampToDate(UNIX_timestamp) {
  const a = new Date(UNIX_timestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  return date + " " + month + " " + year;
}

// Both gives same time
export function unixTimestampToTime(UNIX_timestamp) {
  const a = new Date(UNIX_timestamp);
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  return hour + ":" + min + ":" + sec;
}
//get same DB date
export const dbDateSame = (value) => {
  if (!!value) {
    const day = value?.split("-")?.[2] ?? "1",
      months = value?.split("-")?.[1] ?? "1",
      yr = value?.split("-")?.[0] ?? "2023";
    return `${months}/${day}/${yr}`;
  } else {
    return `1/1/${value?.getUTCFullYear() ?? "2023"}`;
  }
};
// mm/dd/yyyy formatted Date
export const getSlashFormattedDate = (date) => {
  if (!date) {
    return null;
  }
  return new Date(date).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
//func independent of time zone
export const getUTCTimeDate = (value) => {
  const date = new Date(`${value}`),
    day = date?.getUTCDate(),
    month = date?.getUTCMonth() + 1,
    year = date?.getUTCFullYear();
  const newDate = `${year}-${
    month?.toString()?.length > 1 ? `${month}` : `0${month}`
  }-${day?.toString()?.length > 1 ? `${day}` : `0${day}`}`;
  return newDate;
};

//func independent of time zone
export const getUTCTimeDateOverall = (value) => {
  const date = new Date(`${value}`),
    day = date?.getUTCDate(),
    month = date?.getUTCMonth() + 1,
    year = date?.getUTCFullYear();
  return `${
    month?.toString()?.length > 1 ? `${month}` : `0${month}`
  }/${day}/${year}`;
};
//func independent of time zone
export const getUTCTimeDateDash = (value) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(`${value}`),
    day = date?.getUTCDate(),
    month = months[date.getMonth()],
    year = date?.getUTCFullYear();
  return `${month} ${day}, ${year}`;
};

// yyyy-mm-dd formatted Date
export const getColonSlotFormatedDate = (date) => {
  const tempdate = new Date(`${date}`);
  let currentdate = tempdate.getDate();
  let month = tempdate.getMonth() + 1;
  const year = tempdate.getFullYear();
  currentdate = currentdate < 10 ? `0${currentdate}` : currentdate;
  month = month < 10 ? `0${month}` : month;
  return `${year}-${month}-${currentdate}`;
};

//mm--dd--yyyy
export const getColonFormatedDate = (date) => {
  const tempdate = new Date(date);
  let currentdate = tempdate.getDate();
  let month = tempdate.getMonth() + 1;
  let year = tempdate.getFullYear();
  currentdate = currentdate < 10 ? `0${currentdate}` : currentdate;
  month = month < 10 ? `0${month}` : month;
  return `${month}-${currentdate}-${year}`;
};
// mm dd, yyyy
export function postDateFormat(UNIX_timestamp) {
  const a = new Date(UNIX_timestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  return month + " " + date + ", " + year;
}

// AM PM formate
export function postTimeFormat(date) {
  const timeString12hr = new Date(date).toLocaleTimeString(
    {},
    { timeZone: "UTC", hour12: true, hour: "numeric", minute: "numeric" }
  );
  return timeString12hr;
}

export function timeFormatter(date) {
  const time = new Date(date).toLocaleString().split(",")[1];
  return time;
}

export function getSqlTimeStamp(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Construct the formatted string
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

export default {
  unixTimestampToDate,
  unixTimestampToTime,
  getSlashFormattedDate,
  getColonSlotFormatedDate,
  postDateFormat,
  postTimeFormat,
  getSqlTimeStamp,
};


