import React, {useState, useEffect} from 'react';
import swal from 'sweetalert';
import { getUserInfo } from '../../../../../services/authService';
import { fetchGraphMethod, fetchMethod } from '../../../../../services/httpService';
import messagePopup from '../../../../../utils/messagePopup';
import BackdropLoader from '../../../../common/Loader';
import { getAllGraduationYearsQuery } from '../../../auth/signup/SignupQuery';
import { saveCoachPostData, updatePositionValueQuery } from './MyRecruitsQuery';
import PermissionModal from './PermissionModal';
import ScreenSwitch from './ScreenSwitch';

function NeedsTab(props) {
    const currentYear = new Date().getFullYear();
    const [positionValues, setPositionValues] = useState();
    const [gradYearValues, setGradYearValues] = useState();
    const [isSaved, setIsSaved] = useState(true);
    const currentYearIdInDropdown = gradYearValues ? gradYearValues.filter((year)=> {
        return year.yearvalue == currentYear
    }): null;
    const currentYearId = currentYearIdInDropdown && currentYearIdInDropdown[0] && currentYearIdInDropdown[0].id;
    const [gradYear, setGradYear] = useState(13);
    const [gradYearValue, setGradYearValue] = useState();
    const [permissionModal, setPermissionModal] = useState(false);
    const [position, setPosition] = useState([]);
    const [positionIds, setPositionIds] = useState([]);
    const [openPosts, setOpenPosts] = useState();
    const [loading, setLoading] = useState(false);
    const [currentYearid, setCurrentYearid] = useState();

    useEffect(()=> {
        getAllGraduationYearData();
        getAllPositionValues();
    }, [])
    useEffect(()=> {
        getAllPositionValues();
    }, [gradYear])

    useEffect(()=>{
        props.handleCheckSaved(isSaved)
    },[isSaved])

    const getAllGraduationYearData = async() => {
        setLoading(true);
        const variables = {
            obj : {
                yearvalue : {gte : currentYear },
                and : [{yearvalue :  {lte : currentYear + 3}}]
            }
        }
        const res = await fetchGraphMethod(
            getAllGraduationYearsQuery,
            variables,
            true
        )
        setLoading(false);
        let yearValues = res?.data?.data?.allGraduationyears?.Graduationyears;
        if(yearValues){
           await setGradYearValues(yearValues);
        }

    }

    const getAllPositionValues = async () => {
        setLoading(true);        
        const data = {
            coachid : getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
            year : gradYear ? Number(gradYear) : 0
        }
            const positionResponse = await fetchMethod(
                "/api/recruitingneeds/recruitingneedsValues",
                data,
                "post",
                true
            )
        setLoading(false);
        let positionValuesArray = positionResponse.data.response.data;
        if(positionValuesArray){
            setPositionValues(positionValuesArray)
        }
    }
    useEffect(()=> {
        const isOpenPost = positionValues ? positionValues.filter((value)=> {
            return value.isopen == 1 && value.ispost == 1
        }): null;
        setOpenPosts(isOpenPost);
    }, [positionValues])

    const handlePostAll = () => {
        setPermissionModal(true);
        setIsSaved(false);
        let array = [];
        const openPostsValues = openPosts ? openPosts.map((posts, index)=> {
            array.push(posts.selectionField)
            positionIds.push(posts.id)
        }): null;
        if(array){
            setPosition(array);
        }
    }

    const openPermissionModal = (value) => {
        setPermissionModal(true);
        setIsSaved(false);
        setPosition(value.selectionField);
        positionIds.push(value.id)
    }
    const closePermissionModal = () => {
        setPermissionModal(false)
        setPositionIds([]);
    }

    const updateSinglePositionValue = async (item, value) => {
        setLoading(true);
        setIsSaved(false);
        const variables = 
        {
            where: {
                id: item.id
            },
              data: {
                ispost: value == true ? 0 : 1,
                isopen: value == true ? 0 : 1,
              }
            } 
            if(item.isopen == 1 && item.ispost == 1 || item.isopen == 0 && item.ispost == 0){
                const singleResponse = await fetchGraphMethod(
                    updatePositionValueQuery,
                    variables,
                    true
                )
                setLoading(false);
                getAllPositionValues();
            }
    }

    const setAllPositionsOpenOrClose = async (value) => {
        setLoading(true);
        setIsSaved(false);
        const data = {
            coachid : getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
            year : gradYear ? Number(gradYear) : 0,
            isopen : value == true ? 1 : 0,
            ispost : value == true ? 1 : 0
        }
        const res = await fetchMethod(
            "/api/recruitingneeds/updateRecruitingneedsStatus",
            data,
            "post",
            true
        )
        setLoading(false);
        getAllPositionValues();
    }
    const handleGradYearChange = (e) => {
        setGradYear(e.target.value);
        const value = gradYearValues ? gradYearValues.filter((year)=> {
            return year.id == e.target.value
        }): null;
        let yearvalue = value && value[0] && value[0].yearvalue;
        if(yearvalue){
            setGradYearValue(yearvalue);
        }
        setIsSaved(false);
    }
const handleDisabledPositionClick = (item,value ) => {
    swal({
        text: "Position is closed, Would you like to open the position?",
        icon: "warning",
        buttons: {
            confirm: { text: "Open" },
            cancel: "Cancel",
          } 
    }).then((okay) => {
        if (okay) {
            setIsSaved(false);
            updateSinglePositionValue(item, value);
        }
    })
}

  return <div>
      <BackdropLoader open ={loading}/>
       <div className="top-box">
            <h2>Current Recruiting Needs</h2>
            <p>
                Set your program's recruiting needs to help our prospect
                matching system improve your athlete recommendations.
            </p>
            </div>
            <div className="recruiting-detail-box">
            <div className="field-box-item">
                <div className="fieldBox flexBox">
                <div className="column">
                    <select
                    value={gradYear ? gradYear : currentYearId}
                    onChange={(e)=>handleGradYearChange(e)}>
                    {gradYearValues ? gradYearValues.map((year, index)=> {
                        return (
                            <option value={year.id}>{year.yearvalue}</option>
                        )
                    }): null}
                    </select>
                </div>
                </div>
            </div>
            <div className="table-recruiting second">
                <table className="table">
                <thead>
                    <tr>
                    <th>&nbsp;</th>
                    <th>{gradYearValue ? gradYearValue : currentYear}</th>
                    <th>Post Need</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>Position</td>
                    <td>
                        <span className="icon" onClick={()=>setAllPositionsOpenOrClose(true)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                        >
                            <path
                            id="check"
                            d="M5.077,14.681l9.685-9.525L16,6.415,5.078,17.156,0,12.163,1.28,10.9Z"
                            transform="translate(0 -5.156)"
                            fill="#1cb79e"
                            ></path>
                        </svg>
                        OPEN
                        </span>
                        <span className="icon" onClick={()=>setAllPositionsOpenOrClose(false)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.254"
                            height="13.254"
                            viewBox="0 0 13.254 13.254"
                        >
                            <path
                            id="close"
                            d="M22.254,10.16,21.095,9l-5.467,5.467L10.16,9,9,10.16l5.467,5.467L9,21.095l1.16,1.16,5.467-5.467,5.467,5.467,1.16-1.16-5.467-5.467Z"
                            transform="translate(-9 -9)"
                            fill="#c0321f"
                            />
                        </svg>
                        CLOSED
                        </span>
                    </td>
                    <td>
                        <button 
                        className="btn green" 
                        disabled={openPosts && openPosts.length == 0 ? true : false} 
                        onClick={()=>handlePostAll()}>
                            {openPosts && openPosts.length == 0 ? "Posted" : "Post All"}
                            </button>
                    </td>
                    </tr>
                    {positionValues && positionValues.length !== 0 ? positionValues.map((values, index)=> {
                        return (
                    <tr key = {index}>
                    <td>{values.selectionField}</td>
                    <td>
                        {values.isopen == 1 ? (
                        <span className="icon" onClick={()=>updateSinglePositionValue(values,true)}>
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            >
                            <path
                                id="check"
                                d="M5.077,14.681l9.685-9.525L16,6.415,5.078,17.156,0,12.163,1.28,10.9Z"
                                transform="translate(0 -5.156)"
                                fill="#1cb79e"
                            ></path>
                            </svg>
                        </span>
                        ): (
                        <span className="icon" onClick={()=>updateSinglePositionValue(values,false)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.254"
                            height="13.254"
                            viewBox="0 0 13.254 13.254"
                        >
                            <path
                            id="close"
                            d="M22.254,10.16,21.095,9l-5.467,5.467L10.16,9,9,10.16l5.467,5.467L9,21.095l1.16,1.16,5.467-5.467,5.467,5.467,1.16-1.16-5.467-5.467Z"
                            transform="translate(-9 -9)"
                            fill="#c0321f"
                            />
                        </svg>
                        </span>
                        )}
                    </td>
                    {values.isopen == 1 && values.ispost == 1 ? (
                    <td>
                        <button
                        onClick={()=>openPermissionModal(values)}
                        className="btn green"
                        data-toggle="modal"
                        data-target="#RecrutingPostModal"
                        >
                        Post
                        </button>
                    </td>
                    ) : 
                    values.isopen == 1 && values.ispost == 0 ? (
                        <td>
                        <button
                        className="btn"
                        data-toggle="modal"
                        data-target="#RecrutingPostModal"
                        >
                        POSTED
                        </button>
                        </td>
                    ) : (
                    <td>
                        <button 
                        className="btn" 
                        onClick={()=>handleDisabledPositionClick(values , false)}>CLOSED</button>
                    </td>
                    )}
                    </tr>
                )}): null}
                </tbody>
                </table>
            </div>
            <div className="btn-wrap">
            <div className="next-prev-button">
                          <ScreenSwitch
                            prevLink={()=>props.handleSwitchTabData("recruiting")}
                            nextLink={()=>props.handleSwitchTabData("requirements")}
                            isSaved={isSaved}
                          />
                        </div>
            </div>
            </div>
            <>
            {permissionModal && (
            <PermissionModal
            gradYear={gradYearValue}
            currentYear={currentYear}
            closePermissionModal={closePermissionModal}
            position={position}
            positionIds={positionIds}
            getAllPositionValues={getAllPositionValues}
            />
            )}
            </>
        </div>;
}

export default NeedsTab;
