import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import history from './services/history'
import {Router} from 'react-router-dom'

const store = configureStore();

window.store = store;

ReactDOM.render(
  <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
