export const checkListPercentage = (allProfileCalculationData) => {
  const data = { ...allProfileCalculationData };
  return {
    checkListDataPrepare: (athleteData) => {
      if (athleteData != null && Object.keys(data).length) {
        data.Athlete_mobile.completed = athleteData.athletephonenumber
          ? true
          : false;

        data.Athlete_address.completed = athleteData.address ? true : false;
        if (
          athleteData.onBoardingUserIdMap &&
          athleteData.onBoardingUserIdMap.Onboardings &&
          athleteData.onBoardingUserIdMap.Onboardings[0]
        ) {
          let onboardingData = athleteData.onBoardingUserIdMap.Onboardings[0];
          data.About_Me.completed = onboardingData.aboutme ? true : false;

          data.Profile_picture.completed = onboardingData.profileimages
            ? true
            : false;

          data.D_O_B.completed = onboardingData.dateofbirth ? true : false;

          data.GPA.completed = (onboardingData.gpascale || onboardingData?.unweightedgpa) ? true : false;

          data.height.completed = onboardingData.heightinfeet ? true : false;

          data.Weight.completed = onboardingData.weight ? true : false;

          data.Primary_position.completed = onboardingData.primaryposition
            ? true
            : false;
        } else {
          data.About_Me.completed = false;

          data.Profile_picture.completed = false;

          data.D_O_B.completed = false;

          data.GPA.completed = false;

          data.height.completed = false;

          data.Weight.completed = false;

          data.Primary_position.completed = false;
        }

        if (
          athleteData.athleteathletegraduationyearmaprel &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears[0] &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears[0]
            .yearvalue
        ) {
          data.High_School_Grad.completed = true;
        } else {
          data.High_School_Grad.completed = false;
        }

        if (
          athleteData.athleteparentmappingAthleteIdmap &&
          athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings &&
          athleteData.athleteparentmappingAthleteIdmap
            .Athleteparentmappings[0] &&
          athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings[0]
            .id
        ) {
          data.Parent_guardian.completed = true;
        } else {
          data.Parent_guardian.completed = false;
        }

        if (
          athleteData.referenceUserIdMap &&
          athleteData.referenceUserIdMap.References &&
          athleteData.referenceUserIdMap.References[0] &&
          athleteData.referenceUserIdMap.References[0].id
        ) {
          data.Reference.completed = true;
        } else {
          data.Reference.completed = false;
        }
        if (
          athleteData.testScoreUserIdMap &&
          athleteData.testScoreUserIdMap.Testscores &&
          athleteData.testScoreUserIdMap.Testscores[0]
        ) {
          let testScore = athleteData.testScoreUserIdMap.Testscores[athleteData.testScoreUserIdMap.Testscores.length - 1];
          data.ACT_SAT.completed =
            testScore.satmathid ||
            testScore.satreadingandwritingid ||
            testScore.actcompletescoreid
              ? true
              : false;
        } else {
          data.ACT_SAT.completed = false;
        }
        if (athleteData?.schoolTeamusermappingUserIdMap?.Schoolteamusermappings?.length > 0) {
          let testScore = athleteData?.schoolTeamusermappingUserIdMap?.Schoolteamusermappings[0];
          data.Team.completed = true;
        } else {
          data.Team.completed = false;
        }
        if (athleteData?.eventmappingaddedby?.Eventmappings?.length > 0) {
          let testScore = athleteData?.eventmappingaddedby?.Eventmappings[0];
          data.Events.completed = true;
        } else {
          data.Events.completed = false;
        }
        if (
            athleteData?.athletemoreschoolUseridMap?.Athletemoreschools?.length > 0
          // athleteData.athleteschoolUserIdMap &&
          // athleteData.athleteschoolUserIdMap.Athleteschools &&
          // athleteData.athleteschoolUserIdMap.Athleteschools[0]
        ) {
          let complete = false;
          let athleteSchool = athleteData?.athletemoreschoolUseridMap?.Athletemoreschools;
          athleteSchool.map((highSchool) => {
            if (
              highSchool.highschoolname &&
              highSchool.division &&
              highSchool.highschooltype
            ) {
              complete = true;
              return;
            }
          });
          data.High_School_name_type_Division.completed = complete;
        }else{
          //this else check isnot there earlier duw to which on deleted school high.. is not coming
          data.High_School_name_type_Division.completed = false
        }
      }
      return data;
    },

    getFinalPercentage: (finalItems) => {
      let percentage = 0;
      for (let key in finalItems) {
        if (finalItems[key].completed) {
          percentage += parseInt(finalItems[key].percentage);
        }
      }
      return percentage;
    },
  };
};
