import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    HONORS_LIST_START: " HONORS_LIST_START",
    HONORS_SAVE_LIST_START: " HONORS_SAVE_LIST_START",
    HONORS_LIST_SUCCESS: " HONORS_LIST_SUCCESS",
    HONORS_LIST_FAILED: " HONORS_LIST_FAILED",
    HONORS_SAVE_SUCCESS: " HONORS_SAVE_SUCCESS",
    HONORS_SAVE_FAILED: " HONORS_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const honorsListStart = (data) => ({
    type: actionType.HONORS_LIST_START,
    payload: data,
});
export const honorsSaveListStart = (data) => ({
    type: actionType.HONORS_SAVE_LIST_START,
    payload: data,
});

export const honorsListSuccess = (data) => ({
    type: actionType.HONORS_LIST_SUCCESS,
    payload: data,
});

export const honorsListFailed = (data) => ({
    type: actionType.HONORS_LIST_FAILED,
    payload: data,
});
export const honorsSaveSuccess = (data) => ({
    type: actionType.HONORS_SAVE_SUCCESS,
    payload: data,
});

export const honorsSaveFailed = (data) => ({
    type: actionType.HONORS_SAVE_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function honorsListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.HONORS_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.HONORS_SAVE_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.HONORS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.HONORS_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        case actionType.HONORS_SAVE_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        case actionType.HONORS_SAVE_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* honorsListSaga(action) {
    try {
        const response = yield fetchGraphMethod(
            action.payload.query,
            action.payload.variables,
            true
        );
        if (response && response.status === 200) {
            yield put(honorsListSuccess(response.data.data.allAcademicaccomplishments.Academicaccomplishments));
        } else {
            messagePopup("", "Honors List failed", "error");
            yield put(honorsListFailed("Honors List failed"));
        }
    } catch (error) {
        messagePopup("", "Honors List failed", "error");
        yield put(honorsListFailed("Honors List failed"));
    }
}

export function* honorsSaveListSaga(action) {
    try {
        const response = yield fetchGraphMethod(
            action.payload.query,
            action.payload.variables,
            true
        );
        if (response && response.status === 200) {
            yield put(honorsSaveSuccess({ honorsData: response.data.data }));
        } else {
            messagePopup("", "Honors List failed", "error");
            yield put(honorsSaveFailed(" Honors List failed"));
        }
    } catch (error) {
        messagePopup("", "Honors List failed", "error");
        yield put(honorsSaveFailed("Honors List failed"));
    }
}


export function* watchhonorsListSaga() {
    yield takeEvery(actionType.HONORS_LIST_START, honorsListSaga);
    yield takeEvery(actionType.HONORS_SAVE_LIST_START, honorsSaveListSaga);
}
