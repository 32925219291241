import { Dialog } from "@mui/material";
import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom'
import swal from "sweetalert";
import { allMetricsListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/stats/allMetrics";
import { allStatsOverviewStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/stats/allStatsOverview";
import { saveStatsMatrixDetailsStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/stats/saveStatsMatrixDetails";
import { scoutingRequestStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/stats/scoutingRequest";
import { getUserInfo } from "../../../../../../services/authService";
import toastr from "toastr";
import usePrevious from "../../../../../../utils/usePrevious";
import {
  AddYardModal,
  StatsPersonalRecordCard,
  UploadVideoModal,
  UploadedVideoReview,
  AddEditModal,
  EditStatWarningModal,
  StatsGraphModal,
  ScoutingRequest, DeleteVideoModal,
} from "./Components";
import {
  fetchAllMetricsWithDetailsQuery,
  fetchPositionalMetricsWithDetailsQuery,
  saveStatmatrixdetails,
} from "./statsQuery";
import { fetchMethod } from "../../../../../../services/httpService";
import {allStatMatricDetailsListSave} from "../../../../../../redux/athlete/dashboard/editUserProfile/stats/allStatmatrixdetails";
import InlineLoader from "../../../../../common/InlineLoader";

const optionCarousel = {
  className: "stats-feature-list owl-carousel owl-theme",
  loop: false,
  margin: 10,
  nav: true,
  dots:false,
  responsive:{
    0:{
      items: 1
    },
    480:{
      items: 2
    },
    768:{
      items: 3
    },
    1200:{
      items: 4
    }
  }
}

const getMetricLabel = (tabSelect, metricData) => {
  switch (tabSelect.statsmatrixid) {
    case 1:
      return `${metricData.result} `;
    case 2:
      return `${
        metricData.statmatrixdetailsjumbidinfeetmaprel?.Feet[0]
          ? metricData.statmatrixdetailsjumbidinfeetmaprel?.Feet[0]?.feetvalue + ''
          : ""
      }' ${
        metricData.statmatrixdetailsjumbidininchesmaprel?.Inches[0]
          ? metricData.statmatrixdetailsjumbidininchesmaprel?.Inches[0]
              ?.inchvalue + ""
          : ""
      }"`;

    case 3:
      return `${metricData.result} `;
    default:
      return `${metricData.result} `;
  }
};

const PersonalRecords = (props) => {
  const [allMatrixList, setAllMatrixList] = useState(null),
    [matrixList, setMatrixList] = useState(null),
    [statsOverviewList, setStatsOverviewList] = useState(null),
    [activeTab, setActiveTab] = useState({
      name: "Overview",
      id: 0,
    }),
    [cardData, setCardData] = useState(null),
    [showMenu, setShowMenu] = useState(false),
    [refreshCount, setRefreshCount] = useState(0),
    [showVideoPopup, setShowVideoPopup] = useState(false),
    [showVideoReviewPopup, setShowVideoReviewPopup] = useState(false),
    [showAddEditPopup, setShowAddEditPopup] = useState(false),
    [showEditWarningPopup, setShowEditWarningPopup] = useState(false),
    [showGraphPopup, setShowGraphPopup] = useState(false),
    [scoutingPopup, setScoutingPopup] = useState(false),
    [showMore, setShowMore] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [metricSelect, setMetricSelect] = useState({});
  const [tabSelect, setTabSelect] = useState({
    id: "",
    tabname: "",
    tabId: "",
    tabData: null,
  });
  const [ isVidPhotoDelPop, setIsVidPhotoDelPop ] = useState(false)//state for delete popup
  const preMetrixData = usePrevious(props.allMetricList.data?.allMetrics);
  const history = useHistory()
  useEffect(() => {
    fetchOverviewData();
    fetMatrixList();
  }, []);
  const fetMatrixList = () => {
    props.allMetricListStart({
      query: fetchAllMetricsWithDetailsQuery({
        userid: getUserInfo() ? getUserInfo().id : 0,
      }),
    });
  };

  const fetchOverviewData = () => {
    const user = getUserInfo();
    props.allStatsOverviewStart({
      primaryPositionQuery: fetchPositionalMetricsWithDetailsQuery({
        userid: user ? user.id : 0,
        primarypositionid: user ? user.primaryPosition : null,
      }),
      secondaryPositionQuery: fetchPositionalMetricsWithDetailsQuery({
        userid: user ? user.id : 0,
        secondarypositionid: user ? user.secondaryPosition : null,
      }),
    });
  };

  const filteredDataForCard = (data) => {
    let finalDataList = [];
    if (data && data.Statmatrixmappings && data.Statmatrixmappings.length) {
      for (const item of data.Statmatrixmappings) {
        if (item.statsmatrixid === 2) {
          const sortedList = [
            ...item.statmatrixdetailsstatMatrixmappingidMap.Statmatrixdetails,
          ];
          sortedList.sort((a, b) => {
            if (a.jumbidinfeet > b.jumbidinfeet) return -1;
            if (a.jumbidinfeet < b.jumbidinfeet) return 1;
            if (a.jumbidinfeet == b.jumbidinfeet) {
              if (a.jumbidininches > b.jumbidininches) return -1;
              if (a.jumbidininches < b.jumbidininches) return 1;
            }
            return 0;
          });
          finalDataList = [
            ...finalDataList,
            {
              ...item,
              bestStatmatrixdetails: sortedList[0],
            },
          ];
        } else if (item.statsmatrixid === 3) {
          const sortedList = [
            ...item.statmatrixdetailsstatMatrixmappingidMap.Statmatrixdetails,
          ];
          sortedList.sort((a, b) => {
            const arrA = a.result.split("X");
            const arrB = b.result.split("X");
            const keyA1 = parseFloat(arrA[0]),
              keyA2 = parseFloat(arrA[1]);
            const keyB1 = parseFloat(arrB[0]),
              keyB2 = parseFloat(arrB[1]);
            if (keyA1 < keyB1) return 1;
            if (keyA1 > keyB1) return -1;
            if (keyA2 < keyB2) return 1;
            if (keyA2 > keyB2) return -1;
            return 0;
          });
          finalDataList = [
            ...finalDataList,
            {
              ...item,
              bestStatmatrixdetails: sortedList[0],
            },
          ];
        } else {
          const sortedList = [
            ...item.statmatrixdetailsstatMatrixmappingidMap.Statmatrixdetails,
          ];
          sortedList.sort((a, b) => {
            const keyA1 = parseFloat(a.result);
            const keyB1 = parseFloat(b.result);
            if (item.whichoneisbest === "lower") {
              if (keyA1 < keyB1) return -1;
              if (keyA1 > keyB1) return 1;
            } else {
              if (keyA1 < keyB1) return 1;
              if (keyA1 > keyB1) return -1;
            }
            return 0;
          });
          finalDataList = [
            ...finalDataList,
            {
              ...item,
              bestStatmatrixdetails: sortedList[0],
            },
          ];
        }
      }
    }
    return { Statmatrixmappings: finalDataList };
  };

  useEffect(() => {
    if (
      props.allMetricList.data &&
      preMetrixData !== props.allMetricList.data.allMetrics
    ) {
      setRefreshCount(refreshCount + 1);
      const finalData = [...props.allMetricList.data.allMetrics];
      const newFinalData = finalData.map((item) => {
        return {
          ...item,
          statmatrixmappingStatsmatrixIdMap: filteredDataForCard(
            item.statmatrixmappingStatsmatrixIdMap
          ),
        };
      });
      setAllMatrixList(newFinalData);
      setMatrixList([...newFinalData].splice(0, 4));
    }
  }, [props.allMetricList.data]);

  useEffect(() => {
    if (
      props.allStatsOverview.data &&
      props.allStatsOverview.data.allStatsoverviews
    ) {
      setRefreshCount(refreshCount + 1);
      const finalData = [...props.allStatsOverview.data.allStatsoverviews];
   
      const cardDataT = finalData
        ? [...finalData].map((item) => {
            return item.statsoverviewstatematrixmappingidrel
              ? filteredDataForCard(item.statsoverviewstatematrixmappingidrel)
                  .Statmatrixmappings[0]
              : null;
          })
        : null;
      if (activeTab.name === "Overview") {
        setCardData(cardDataT);
      }
      setStatsOverviewList(cardDataT);
    }
  }, [props.allStatsOverview.data]);

  const refreshAfterSave = () => {
    window.location.reload();
  };

  const refreshAfterSaveAnother = () => {
    fetMatrixList();
    setRefreshCount(refreshCount + 1);
    setisEdit(false);
    setMetricSelect(null);
    setShowAddModal(true);
  };

  const closeAddModalHandler = () => {
    setShowAddModal(false);
  };

  const showMoreClick = () => {
    setRefreshCount(refreshCount + 1);
    if (!showMore) {
      setMatrixList(allMatrixList);
      setShowMore(!showMore);
    } else {
      const tempData = [...allMatrixList];
      setMatrixList(tempData.splice(0, 4));
      setShowMore(!showMore);
      setActiveTab({
        name: "Overview",
        id: 0,
      });
      setCardData(statsOverviewList);
    }
  };

  const handleTabsClick = (data) => {
    setRefreshCount(refreshCount + 1);
    if (data === "Overview") {
      setActiveTab({
        name: "Overview",
        id: 0,
      });
      setCardData(statsOverviewList);
    } else {
      setActiveTab({
        name: data.matrixname,
        id: data.id,
      });
      setCardData(
        data.statmatrixmappingStatsmatrixIdMap
          ? data.statmatrixmappingStatsmatrixIdMap.Statmatrixmappings
          : null
      );
    }
  };

  const onEditClick = (matrixDetails, tabData) => {
    const obj = {
      id: activeTab.id,
      tabId: tabData.id ? tabData.id : "",
      tabData,
    };
    setTabSelect(obj);
    setMetricSelect(matrixDetails);
    if (!matrixDetails) {
      setisEdit(false);
      setShowAddModal(true);
    } else {
      setShowAddEditPopup(true);
    }
  };

  const onClickGraphIcon = (matrixDetails, tabData) => {
    const obj = {
      id: activeTab.id,
      tabId: tabData.id ? tabData.id : "",
      tabData,
    };
    setTabSelect(obj);
    setMetricSelect(matrixDetails);
    setShowGraphPopup(true);
  };

  const onClickVideoIcon = (matrixDetails, tabData) => {
    const obj = {
      id: activeTab.id,
      tabId: tabData.id ? tabData.id : "",
      tabData,
    };
    setTabSelect(obj);
    setMetricSelect(matrixDetails);
    if (!matrixDetails.videoname) {
      setisEdit(false);
      setShowVideoPopup(true);
    } else {
      setisEdit(true);
      setShowVideoReviewPopup(true);
    }
  };

  const onClickUpdatePopReview = () =>{
    setisEdit(false);
    setShowVideoReviewPopup(false);
    setShowVideoPopup(true);
  }

  const openSaveVideoAfterDataSave = async(matrixDetails, tabData) => {
    // let variables = {
    //   where: {
    //     userid: getUserInfo().id,
    //   },
    //   data: {
    //     verifiedby: "Not Verified",
    //     isscoutingrequestsent: 0,
    //     verified: 0,
    //     isscoutingrequestupdateOn :null
    //   },
    // };
    // const UpdatestatsRequestData = await fetchGraphMethod(updateScoutingRequestQuery, variables, true)
    const obj = {
      id: activeTab.id,
      tabId: tabData.id ? tabData.id : "",
      tabData,
    };
    setTabSelect(obj);
    setMetricSelect(matrixDetails);
    setisEdit(false);
    //setShowVideoPopup(true);
  };

  const onCloseVideoPopup = () => {
    setShowVideoPopup(false);
    setShowVideoPopup(false);
  };

  const editHandler = () => {
    setisEdit(!isEdit);
  };

  const onClickHandler = (flag, tabSelect) => {
    if (flag === "edit") {
      setShowEditWarningPopup(true);
    } else if(tabSelect.tabData && tabSelect.tabData.statmatrixdetailsstatMatrixmappingidMap && 
      tabSelect.tabData.statmatrixdetailsstatMatrixmappingidMap.totalCount &&
      tabSelect.tabData.statmatrixdetailsstatMatrixmappingidMap.totalCount >= 5 ){
      // messagePopup("", "You can't add more than 5 records", "error")
      toastr.error("You can't add more than 5 records", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
    }
    else{
      setisEdit(false);
      setMetricSelect(null);
      setShowAddModal(true);
    }
    setShowAddEditPopup(false);
  };

  const onClickEditWarning = () => {
    setisEdit(true);
    setShowEditWarningPopup(false);
    setShowAddModal(true);
  };

  const onClickScoutingRequest = (e) => {
    e.preventDefault();
    const user = getUserInfo();
    props.scoutingRequestStart({
      data: {
        emailId: user ? user.Email : "",
        athleteName: user
          ? (user.FirstName ? user.FirstName : "") +
            (user.lastName ? user.lastName : "")
          : "",
      },
      successMsg:
        "Your request to verify the metrics that you have added currently have been sent to the admin",
      // openScoutingRequestPopup: openScoutingRequestPopup,
    });
  };
  const onReportRoute = () =>{
    const hisData = history
    if (hisData?.pathname?.includes('/Stats')){
      history?.push('/Stats/Reports')
    }else{
      history?.push('/St_view/Reports')
    }
  }
  const onClickDeleteVidPhoto = () =>{
    setIsVidPhotoDelPop(true)
  }
  const onDeleteVideoHandler = async () => {
    const variables = {
      obj: {
        id: metricSelect.id,
        userid: getUserInfo().id,
        active: 1,
        videoname: null,
        videodescription: null,
        isvideoorlink: null,
      },
    };
    const finalDelObj = {
      "deletedRank": metricSelect?.uploadedvideoandphotoStatidMap?.Uploadedvideoandphotos?.
          [metricSelect?.uploadedvideoandphotoStatidMap?.Uploadedvideoandphotos?.length - 1]?.uploadedrank ?? 0,
      "photosVideosAndLinkId": metricSelect?.photovideoid ?? 0,
      "photosVideosAndLinkType": "0",
      "postId": 0,
      "postarray": [],
      "userId": getUserInfo()?.id ?? 0,
      "visibleId": 0,
      "isstats": 1
    }
    props.statMetricDetailsSave({
      query: saveStatmatrixdetails,
      variables,
      successMsg: successMsg
    });
    
    const deleteuploadPhotoVideo = {
      statid: metricSelect.id,
    }
    await fetchMethod('/api/Statmatrixdetails/deleteStatPhotoVideo', deleteuploadPhotoVideo, 'post', true)
    
    await fetchMethod('/api/Uploadedvideoandphotos/removePhotosAndVideos', finalDelObj, 'POST', true)
    
    setIsVidPhotoDelPop(false)
    setShowVideoReviewPopup(false)
  };
  const successMsg = () => {
    swal({
      text: "Photo / Video Deleted Successfully.",
      icon: "success",
    }).then((okay) => {
      if (okay || okay == null) {
        refreshAfterSave()
      }
    });
  }

  return (
    <div id="PersonalRecordsSection" className="stats-box-item">
      <h4 className="title with-button">
        <span>Personal Records</span>{" "}
        <div className="button-box">
          <button
            className="btn lightblue"
            onClick={(e) => {
              e.preventDefault();
              onClickScoutingRequest(e);
            }}
          >
            <span className="icon-box">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                <g id="sf-shield-check" transform="translate(-45.451 0)">
                  <g id="Group_51188" data-name="Group 51188" transform="translate(45.451 0)">
                    <path id="Path_2138" data-name="Path 2138" d="M59.174,3.672,52.741.075a.6.6,0,0,0-.58,0l-6.433,3.6a.528.528,0,0,0-.277.458V6.184a10.383,10.383,0,0,0,6.8,9.78.6.6,0,0,0,.41,0,10.381,10.381,0,0,0,6.8-9.78V4.131A.528.528,0,0,0,59.174,3.672ZM55.254,6.8,51.7,10.145a.593.593,0,0,1-.8,0L49.648,8.972a.511.511,0,0,1,0-.754.593.593,0,0,1,.8,0l.847.8,3.155-2.966a.593.593,0,0,1,.8,0A.511.511,0,0,1,55.254,6.8Z" transform="translate(-45.451 0)" fill="#fff"/>
                  </g>
                </g>
              </svg>
            </span>
            Verify Stats
          </button>
          <button
              className="btn btnReport"
              onClick={(e) =>onReportRoute()}
          >
            <span className="icon-box">
             <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="16" viewBox="0 0 13.75 16">
  <g id="file" transform="translate(2.668 0)">
    <path id="Path_2315" data-name="Path 2315" d="M416.735,13.814h2.835l-2.984-2.931v2.782a.149.149,0,0,0,.149.149Zm0,0" transform="translate(-408.764 -10.612)" fill="#1cb79e"/>
    <path id="Path_2316" data-name="Path 2316" d="M7.963,4.148A1.088,1.088,0,0,1,6.876,3.062V0H-.919A1.751,1.751,0,0,0-2.668,1.749v12.5A1.751,1.751,0,0,0-.919,16H9.333a1.751,1.751,0,0,0,1.749-1.749V4.148ZM6.891,12.125H.854a.469.469,0,1,1,0-.937H6.891a.469.469,0,1,1,0,.938ZM.385,9.781a.469.469,0,0,1,.469-.469H6.241a.469.469,0,1,1,0,.938H.854A.469.469,0,0,1,.385,9.781ZM7.461,8.375H.854a.469.469,0,0,1,0-.938H7.461a.469.469,0,0,1,0,.938Zm0,0" fill="#1cb79e"/>
  </g>
</svg>

            </span>
            Report Stats
          </button>
        </div>
      </h4>
      <div className="tabBox">
        <nav className={showMenu ? "show" : ""}>
          <ul className="nav nav-tabs">
            {showMenu ? (
              <li className="closeBtnBox">
                <button
                  className="closeBtn"
                  onClick={() => {
                    setShowMenu(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.804"
                    height="15.803"
                    viewBox="0 0 15.804 15.803"
                  >
                    <g className="a" transform="translate(-6.776 -6.776)">
                      <path
                        d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                        transform="translate(0 0)"
                      ></path>
                    </g>
                  </svg>
                </button>
              </li>
            ) : null}
            <li>
              <a
                className={activeTab.name === "Overview" ? "active" : ""}
                data-toggle="tab"
                href={void 0}
                onClick={() =>{
                  handleTabsClick("Overview")
                  setShowMenu(false);
                }}
                style={{ cursor: "pointer" }}
              >
                Overview
              </a>
            </li>
            {matrixList &&
              matrixList.map((item, index) => (
                <li key={index}>
                  <a
                    className={
                      activeTab.name === item.matrixname ? "active" : ""
                    }
                    data-toggle="tab"
                    href={void 0}
                    onClick={() =>{
                      handleTabsClick(item)
                      setShowMenu(false)
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {item.matrixname}
                  </a>
                </li>
              ))}
            <li>
              <a
                data-toggle="tab"
                href={void 0}
                onClick={() => showMoreClick()}
                style={{ cursor: "pointer" }}
              >
                {showMore ? "Show Less" : "More +"}
              </a>
            </li>
          </ul>
        </nav>
        <div className="flexBox">
          <div className="menuBtnBox">
            <button
              className="tabMenuBtn"
              onClick={() => {
                setShowMenu(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="12"
                viewBox="0 0 16 12"
              >
                <defs>
                  <style></style>
                </defs>
                <g transform="translate(-284.664 -20.498)">
                  <rect
                    className="a"
                    width="16"
                    height="2"
                    rx="1"
                    transform="translate(284.664 20.498)"
                  ></rect>
                  <rect
                    className="a"
                    width="16"
                    height="2"
                    rx="1"
                    transform="translate(284.664 25.498)"
                  ></rect>
                  <rect
                    className="a"
                    width="16"
                    height="2"
                    rx="1"
                    transform="translate(284.664 30.498)"
                  ></rect>
                </g>
              </svg>
            </button>
          </div>
          <div>
            <p>Stats Menu</p>
          </div>
        </div>
      </div>
      <div className="tab-content">
        <InlineLoader open={props.allStatsOverview.loading && props.activeTab === 'personalRecord' && activeTab.name === 'Overview'} />
        <div className="tab-pane active" id="Overview">
          {cardData?.length > 0 ? (
            <OwlCarousel
                {...optionCarousel}
              // className="stats-feature-list owl-carousel owl-theme"
              key={refreshCount}
              // loop={false}
              // margin={5}
              // items="4"
              // nav
            >
              {cardData.map((item, index) => {
                if (item.statmatrixdetailsstatMatrixmappingidMap?.totalCount) {
                  return (
                    <StatsPersonalRecordCard
                      key={index}
                      tabData={item}
                      brandlogo={item.brandlogo}
                      totalCount={
                        item.statmatrixdetailsstatMatrixmappingidMap?.totalCount
                      }
                      matrixDetails={item.bestStatmatrixdetails}
                      onEditClick={onEditClick}
                      onClickVideoIcon={onClickVideoIcon}
                      getMetricLabel={getMetricLabel}
                      onClickGraphIcon={onClickGraphIcon}
                    />
                  );
                } else {
                  return (
                    <StatsPersonalRecordCard
                      key={index}
                      tabData={item}
                      brandlogo={item.brandlogo}
                      totalCount={
                        item.statmatrixdetailsstatMatrixmappingidMap?.totalCount
                      }
                      matrixDetails={null}
                      onEditClick={onEditClick}
                      onClickVideoIcon={onClickVideoIcon}
                      onClickGraphIcon={onClickGraphIcon}
                    />
                  );
                }
              })}
            </OwlCarousel>
          ) : null}
        </div>
      </div>
      <Dialog open={showAddModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  closeAddModalHandler()
                }
              }}
              className="matDialogCustom matDialogAddYard"
      >
        <AddYardModal
          onClose={closeAddModalHandler}
          tabData={tabSelect}
          isEdit={isEdit}
          handleEdit={editHandler}
          metricData={metricSelect}
          saveStatsMatrixDetailsStart={props.saveStatsMatrixDetailsStart}
          loading={props.saveStatsMatrixDetails.loading}
          refreshAfterSave={refreshAfterSave}
          refreshAfterSaveAnother={refreshAfterSaveAnother}
          openSaveVideoAfterDataSave={openSaveVideoAfterDataSave}
        />
      </Dialog>
      <Dialog open={showVideoPopup}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onCloseVideoPopup()
                }
              }}
              className="matDialogCustom"
      >
        <UploadVideoModal
          onClose={onCloseVideoPopup}
          metricData={metricSelect}
          tabData={tabSelect}
          saveStatsMatrixDetailsStart={props.saveStatsMatrixDetailsStart}
          loading={props.saveStatsMatrixDetails.loading}
          refreshAfterSave={refreshAfterSave}
        />
      </Dialog>
      <Dialog open={showVideoReviewPopup}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setShowVideoReviewPopup(false)
                }
              }}
              className="matDialogCustom"
      >
        <UploadedVideoReview
          onClose={() => setShowVideoReviewPopup(false)}
          metricData={metricSelect}
          isAuth = { 1 }
          page = {"Show_Stats_PR"} //this is added as to insure which page is
            // modal is open vimeo change
          onClickUpdatePopReview={onClickUpdatePopReview}
          onClickDeletePersonal={()=>onClickDeleteVidPhoto()}
        />
      </Dialog>
      <Dialog open={showAddEditPopup}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setShowAddEditPopup(false)
                }
              }}
              className="matDialogCustom"
      >
        <AddEditModal
          onClose={() => setShowAddEditPopup(false)}
          onClickHandler={(e)=>onClickHandler(e, tabSelect)}
        />
      </Dialog>
      <Dialog open={showEditWarningPopup}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setShowEditWarningPopup(false)
                }
              }}
              className="matDialogCustom"
      >
        <EditStatWarningModal
          onClose={() => setShowEditWarningPopup(false)}
          onClickEditWarning={onClickEditWarning}
          metricData={metricSelect}
        />
      </Dialog>

      <Dialog open={showGraphPopup}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setShowGraphPopup(false)
                }
              }}
      >
        <StatsGraphModal
          onClose={() => setShowGraphPopup(false)}
          tabData={tabSelect ? tabSelect.tabData : null}
        />
      </Dialog>
      <Dialog open={scoutingPopup}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setScoutingPopup(false)
                }
              }}
              className="matDialogCustom"
      >
        <ScoutingRequest onClose={() => setScoutingPopup(false)} />
      </Dialog>

      <Dialog open={isVidPhotoDelPop}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setIsVidPhotoDelPop(false)
                }
              }}
              className="matDialogCustom"
      >
        <DeleteVideoModal
        onClose={()=>setIsVidPhotoDelPop(false)}
        onDelete={()=>onDeleteVideoHandler()}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allMetricList: state.athlete.editUserProfileData.allMetrics,
    saveStatsMatrixDetails:
      state.athlete.editUserProfileData.saveStatsMatrixDetails,
    allStatsOverview: state.athlete.editUserProfileData.allStatsOverview,
    scoutingRequest: state.athlete.editUserProfileData.scoutingRequest,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    allMetricListStart: (data) => dispatch(allMetricsListStart(data)),
    saveStatsMatrixDetailsStart: (data) =>
      dispatch(saveStatsMatrixDetailsStart(data)),
    allStatsOverviewStart: (data) => dispatch(allStatsOverviewStart(data)),
    scoutingRequestStart: (data) => dispatch(scoutingRequestStart(data)),
    statMetricDetailsSave: (data) =>
        dispatch(allStatMatricDetailsListSave(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalRecords);
