import AWS from "aws-sdk";
import { awsRegion, awsVideoSourceAccessKeyId, awsVideoSourceSecretAccessKey } from "../config";

const s3 = new AWS.S3({
    accessKeyId: awsVideoSourceAccessKeyId,
    secretAccessKey: awsVideoSourceSecretAccessKey,
    region: awsRegion,
  });

const uploadToS3 = async (params) => {
    return new Promise((resolve, reject) => {
      s3.upload(params, function(err, data) {
        if (err) {
          reject(err);
          console.log("Error uploading data: ", err);
        } else {
          resolve(data);
        }
      });
    });
  };

  export default uploadToS3;