import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SAVE_STATS_LIST_START: "SAVE_STATS_LIST_START",
  SAVE_STATS_LIST_FAIL: "SAVE_STATS_LIST_FAIL",
  SAVE_STATS_LIST_SUCCESS: "SAVE_STATS_LIST_SUCCESS",
};

// ============================================ Actions ========================================================
export const saveStatsListStart = (data) => ({
  type: actionType.SAVE_STATS_LIST_START,
  payload: data,
});

export const saveStatsListSuccess = (data) => ({
  type: actionType.SAVE_STATS_LIST_SUCCESS,
  payload: data,
});

export const saveStatsListFail = (data) => ({
  type: actionType.SAVE_STATS_LIST_FAIL,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function saveStatsLIstReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_STATS_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SAVE_STATS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case actionType.SAVE_STATS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* saveStatsListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(saveStatsListSuccess(response.data.data.saveStat));
      messagePopup("", "Stats deleted successfully", "success");
    } else {
      messagePopup("", "Save Stats failed", "error");
      yield put(saveStatsListFail("Save Stats failed"));
    }
  } catch (error) {
    messagePopup("", "Save Stats failed", "error");
    yield put(saveStatsListFail("Save Stats failed"));
  }
}

//Watcher
export function* watchsaveStatsListSaga() {
  yield takeEvery(actionType.SAVE_STATS_LIST_START, saveStatsListSaga);
}
