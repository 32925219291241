import React, { useState, useEffect, useRef } from "react";
import "../assets/css/Header.scss";
import { useHistory } from "react-router-dom";
import logoH from "../assets/Img/newLogo.png";
import mobileLogo from "../assets/Img/Mobile-logo.png";
import Home from "../assets/Img/home.svg";
import search from "../assets/Img/search.svg";
import userImg from "../assets/Img/user.svg";
import email from "../assets/Img/email.svg";
import notification from "../assets/Img/notification.svg";
import "../../../assets/css/stylesheet.scss";
import { Link } from "react-router-dom";
import { fetchMethod, fetchGraphMethod } from "../../../services/httpService";
import { imageDownloadUrl } from "../../../config";
import { userProfileApi } from "../../common/userViewCount";
import {
  getUserInfo,
  logout,
  getAuthToken,
  setUserInfo,
} from "../../../services/authService";
import { connect, useDispatch, useSelector } from "react-redux";
import { dashboardCountStart } from "../../../redux/athlete/dashboard/dashboardCount/dashboardCount";
import ProfileSetting from "./profileSettings";
import {
  getNotificationUnreadQuery,
  getMessageUnreadCount,
} from "./DashboardQuery";
import { notificationUnreadCountListStart } from "../../../redux/athlete/dashboard/notification/notificationUnreadCount";
import { getAllAthletesDataVerifyQuery } from "../../common/CommonQuery";
const menuItem = [
  {
    text: "",
    link: "homepage",
    img: Home,
    className: "icon",
  },
  {
    text: "Profile",
    link: `athlete/${getUserInfo()
      ?.FirstName?.toLowerCase()
      ?.toString()
      ?.split(" ")
      .join(".")}.${getUserInfo()
      ?.lastName?.toLowerCase()
      ?.toString()
      ?.split(" ")
      .join(".")}-10${getUserInfo()?.id}1`,
    routeLink: [
      "profile",
      "basic",
      "checklist",
      "contact",
      "videoPhotos",
      "vitals",
      "teams",
      "add-coaches",
      "Stats",
      "events",
      "academics",
      "gradesScores",
      "addtionalSports",
      "References",
      "press",
      "collegeInsights",
      "Offers",
      "edit",
      "commitmentstory",
      "viewCommitment",
    ],
  },
  {
    text: "Colleges",
    link: "colleges/search",
  },
  {
    text: "Stats",
    link: "St_view",
  },
  // {
  //   text: "Activity",
  //   link: "activityPage",
  // },
  {
    text: "Resources",
    link: "resources/HowToVideo",
  },
  // {
  //   text: "Commits",
  //   link: "commitment",
  // },
  {
    text: "Plans",
    link: "programAndPricingDetail",
  },
];
const menuItemCoach = [
  {
    text: "",
    link: "coach-homepage",
    img: Home,
    className: "icon",
  },
  {
    text: "Profile",
    link: "",
    routeLink: [
      "profile",
      "basic",
      "checklist",
      "contact",
      "videoPhotos",
      "vitals",
      "teams",
      "add-coaches",
      "Stats",
      "events",
      "academics",
      "gradesScores",
      "addtionalSports",
      "References",
      "press",
      "collegeInsights",
      "Offers",
      "edit",
      "commitmentstory",
    ],
  },
  {
    text: "Search",
    link: "",
  },
  {
    text: "Recruiting Needs",
    link: "myRecruits",
  },
  {
    text: "My Recruits",
    link: "",
  },
  {
    text: "Activity",
    link: "",
  },
  {
    text: "Commits",
    link: "commitment",
  },
  {
    text: "Programs",
    link: "",
  },
];

const Menu = ({ handleOpenMenu, setIsProfileClose }) => {
  const { location } = useHistory();
  const route = location.pathname.split("/")[1];
  const [activeClass, setActiveClass] = React.useState(route);
  const [profileClass, setProfileClass] = React.useState();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const history = useHistory();
  const toggleButton = (route) => {
    setActiveClass(route);
  };

  useEffect(() => {
    if (route == "Stats") {
      setProfileClass("St_view");
      setActiveClass("St_view");
    } else if (location?.pathname?.includes("/athlete/")) {
      setProfileClass("profile");
      setActiveClass("profile");
    } else {
      setProfileClass(route);
      setActiveClass(route);
    }
  }, [route, store?.athlete?.editUserProfileData?.renderCount?.count]);

  const redirectToProfile = () => {
    const value = `/athlete/${getUserInfo()
      ?.FirstName?.toLowerCase()
      ?.toString()
      ?.split(" ")
      .join("-")}-${getUserInfo()
      ?.lastName?.trim()
      ?.toLowerCase()
      ?.toString()
      ?.split(" ")
      .join("-")}-${getUserInfo()?.sysGenId}`;
    return value;
  };

  return (
    <>
      {getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ? (
        <>
          {menuItemCoach.map((item, index) => {
            return (
              <li key={index} className={item.className ? item.className : "d"}>
                <Link
                  to={"/" + item.link}
                  className={
                    (
                      item.routeLink
                        ? item.routeLink.includes(activeClass)
                        : item.link.includes(activeClass)
                    )
                      ? "active"
                      : "inAcitve"
                  }
                  onClick={() => {
                    toggleButton(item.link) ||
                      dispatch({
                        type: "CHANGE_ATHLETE_PROFILE_VIEW_STATE",
                        payload: {
                          id: 0,
                          status: false,
                        },
                      });
                  }}
                >
                  {item.img && <img src={item.img} alt="" />}
                  {item.text && item.text}
                </Link>
              </li>
            );
          })}
        </>
      ) : (
        <>
          {menuItem.map((item, index) => {
            return (
              <li key={index} className={item.className ? item.className : "d"}>
                <Link
                  to={
                    item?.link?.includes("athlete/")
                      ? {
                          pathname: `${redirectToProfile()}`,
                          state: { isPublic: false },
                        }
                      : `/${item.link}`
                  }
                  className={
                    (
                      item.routeLink
                        ? item.routeLink.includes(activeClass)
                        : item.link.includes(profileClass)
                    )
                      ? "active"
                      : "inAcitve"
                  }
                  onClick={(e) => {
                    toggleButton(item.link) ||
                      dispatch({
                        type: "CHANGE_ATHLETE_PROFILE_VIEW_STATE",
                        payload: {
                          id: 0,
                          status: false,
                        },
                      });
                    dispatch({ type: "RENDER_COUNT_START", payload: 1 });
                    localStorage.setItem("showWithSetting", false);
                    sessionStorage.setItem("athlete_own_profile", true);
                    handleOpenMenu(e);
                    setIsProfileClose();
                  }}
                >
                  {item.img && <img src={item.img} alt="" />}
                  {item.text && item.text}
                </Link>
              </li>
            );
          })}
        </>
      )}
    </>
  );
};

const DashboardHeader = (props) => {
  const handleOpenMenu = (e) => {
    // e.preventDefault();
    setDropDown(false);
    const ht = window?.innerWidth;
    if (ht < 993) {
      setTimeout(() => {
        document.body.classList.toggle("menu-open");
      }, 500);
    }
  };

  const { location } = useHistory();
  const route = location.pathname.split("/")[1];
  const handleLogout = () => {
    logout();
  };

  const [_show, setShow] = React.useState("false");
  const [_count, setCount] = useState(2);
  const [msgCount, setMsgCount] = useState("");
  const [openModal, setopenModal] = React.useState(false);
  const [isReadCount, setIsReadCount] = useState("");
  const handleOpenModal = () => setopenModal(true);
  const handleCloseModal = () => setopenModal(false);
  const [DropDown, setDropDown] = useState(false);
  const [searchByAthleteName, setSearchByAthleteName] = useState("");
  const [searchAthleteData, setSearchAthleteData] = useState([]);
  const [searchMobByAthleteName, setSearchMobByAthleteName] = useState("");
  const [searchMobAthleteData, setSearchMobAthleteData] = useState([]);
  const [_activeClass, setActiveClass] = React.useState(route);
  const profileStatus = useDispatch();
  const history = useHistory();
  const [isProfileClose, setIsProfileClose] = useState(false);
  const domRef = useRef();
  const modalRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    getCountData();
    getVeriyStatus();
  }, []);
  const getCountData = async () => {
    props.dashboardCountStart({
      query: getMessageUnreadCount,
      variables: {
        where: {
          isread: 0,
          deletebyathlete: 0,
          msgreceiverid: getUserInfo()?.id,
        },
      },
    });
    /* await fetchGraphMethod(getMessageUnreadCount, {
          where: {
            isread:0,
            deletebyathlete:0,
            msgreceiverid:{neq:null},
            //and:[{or:[{msgsenderadminid:{neq:null}}, {msgsendercoachid:{neq:null}}, {msgsenderadvisorid:{neq:null}}]}]
          }
        },
        true
    ).then(res => {
      setMsgCount(res?.data?.data?.allMessagereplies?.totalCount);
    }) */
  };
  const getVeriyStatus = async () => {
    const _resVer = await fetchGraphMethod(
      getAllAthletesDataVerifyQuery,
      {
        obj: {
          id: getUserInfo() ? getUserInfo().id : 0,
        },
      },
      true
    );
    if (_resVer?.status == 200) {
      const verifyStatus =
          _resVer?.data?.data?.allAthletes?.Athletes?.length > 0
            ? _resVer?.data?.data?.allAthletes?.Athletes?.[0]?.verifieduser
            : 0,
        cloneLS = JSON.parse(JSON.stringify(getUserInfo()));
      if (verifyStatus != cloneLS?.verifiedUserStatus) {
        cloneLS.verifiedUserStatus = verifyStatus;
        setUserInfo(cloneLS);
        dispatch({ type: "RENDER_COUNT_START", payload: 1 });
      }
    }
  };

  useEffect(() => {
    setMsgCount(props?.dashboardCount?.data?.totalCount);
  }, [props.dashboardCount.data]);

  useEffect(() => {
    setActiveClass(route);
    //On route change close dropdown of athlete search and value to be empty of searchbox.
    setDropDown(false);
    setIsProfileClose(false); //closing profile setting when route change
    setSearchByAthleteName("");
    setSearchMobByAthleteName("");
  }, [route]);

  useEffect(() => {
    if (getUserInfo() && getUserInfo().id) getNotitificationUnreadList();
  }, []);
  const getNotitificationUnreadList = () => {
    props.notificationUnreadCountListStart({
      query: getNotificationUnreadQuery,
      variables: {
        where: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          issee: 0,
        },
      },
    });
  };

  const getAthleteList = async (name) => {
    if (name.length > 0) {
      const obj = {
        url: "/api/Athletes/searchAthlete",
        method: "post",
        data: { athleteName: name, role: getUserInfo()?.role ?? "" },
        headers: true,
      };
      const res = await fetchMethod(
        obj?.url,
        obj?.data,
        obj?.method,
        obj?.headers
      );
      if (res?.data?.response?.status == 200) {
        setSearchAthleteData(res?.data?.response?.data);
        setSearchMobAthleteData(res?.data?.response?.data);
      } else {
        setSearchAthleteData([]);
        setSearchMobAthleteData("");
      }
    }
  };

  const notificationCount =
    props.notificationUnreadList && props.notificationUnreadList.data
      ? props.notificationUnreadList.data
      : null;
  useEffect(() => {
    setIsReadCount(
      notificationCount && notificationCount.length
        ? notificationCount.filter((item) => item.isread === 0).length
        : ""
    );
  }, [props.notificationUnreadList.data]);
  const setOpenCloseDropDown = (e) => {
    setSearchByAthleteName(e.target.value);
    setSearchMobByAthleteName(e.target.value);
    if (e.target.value.length > 2) {
      getAthleteList(e?.target?.value?.toUpperCase() || "");
      setDropDown(true);
    } else {
      setSearchAthleteData([]);
      setSearchMobAthleteData([]);
      setDropDown(false);
    }
  };
  const handalCloseDropDown = async (value) => {
    await setDropDown(false);
    await setSearchByAthleteName("");
    await setSearchMobByAthleteName("");
  };
  const getNameInitial = (data) => {
    const Name = data?.athleteFullName?.split(" ");
    return `${Name?.[0]?.split("")?.[0]?.toUpperCase() ?? ""}${
      Name?.[Name?.length - 1]?.split("")?.[0]?.toUpperCase() ?? ""
    }`;
  };
  //this check is for athlete how has not done onboarding has to done onboarding first
  useEffect(() => {
    if (route === "Privacy-Policy" || route === "Terms-of-Services") {
    } else if (
      getUserInfo()?.role == "ATHLETES" &&
      getUserInfo()?.isonboardingdone == 0 &&
      getAuthToken() &&
      getUserInfo()?.isadmin == 0
    ) {
      history.push("/onboarding/profile");
    }
  }, []);
  //func for closing when click outside
  useEffect(() => {
    const w = window.innerWidth;
    let handler;
    if (w > 992) {
      handler = (event) => {
        if (!domRef?.current?.contains(event?.target)) {
          setSearchAthleteData([]);
          setSearchByAthleteName("");
        }
      };
    }
    if (w <= 992) {
      handler = (event) => {
        if (!modalRef?.current?.contains(event?.target)) {
          setSearchMobAthleteData([]);
          setSearchMobByAthleteName("");
          setopenModal(false);
        }
      };
    }
    document?.addEventListener("mousedown", handler);
    return () => {
      document?.removeEventListener("mousedown", handler);
    };
  });
  return (
    <>
      <header className="dashboard">
        <div className="flex">
          <div className="logoPart">
            <a
              href={void 0}
              onClick={() => localStorage.setItem("showWithSetting", false)}
            >
              <img src={logoH} alt="logoH" />
              <img src={mobileLogo} alt="logo" className="mobile" />
            </a>
          </div>
          {/* <div className="searchBar">
            <div className="searchBox">
              <input type="text" placeholder="Search Athletes" />
            </div>
          </div> */}

          <div className="searchBar" data-toggle="dropdown">
            <input
              type="text"
              value={searchByAthleteName}
              placeholder="Search Athletes"
              onChange={(e) => {
                setOpenCloseDropDown(e);
              }}
            />
            {searchAthleteData.length > 0 ? (
              <div
                ref={domRef}
                className={
                  DropDown == true
                    ? "dropdown-menu dropdownopen"
                    : "dropdown-menu"
                }
              >
                <div className="title">Search Results</div>
                <ul className="search-list">
                  {searchAthleteData.length > 0 &&
                    searchAthleteData.map((athlete) => (
                      <li id={athlete.id}>
                        <Link
                          // to={`/publicProfile/${athlete.id}`}
                          // to={`/publicProfile/1/${+athlete?.id}`}
                          to={`/athlete/${athlete?.athleteFullName
                            ?.trim()
                            ?.toLowerCase()
                            ?.toString()
                            ?.split(" ")
                            .join("-")}-${athlete.sysGenId}`}
                          onClick={() => {
                            handalCloseDropDown();
                            profileStatus({
                              type: "CHANGE_ATHLETE_PROFILE_VIEW_STATE",
                              payload: {
                                id: athlete?.id || 0,
                                status: true,
                              },
                            });
                            dispatch({
                              type: "RENDER_COUNT_START",
                              payload: 1,
                            });
                            localStorage.setItem("pubViewID", athlete.id || 0);
                            sessionStorage.setItem(
                              "athlete_own_profile",
                              false
                            );
                            userProfileApi(
                              {
                                obj: {
                                  userrole: "ATHLETES",
                                  profileviewedby: getUserInfo()?.id ?? 0,
                                  profileviewto: +athlete?.id || 0,
                                },
                              },
                              true,
                              false
                            );
                            // setTimeout(()=>{
                            //   window.open(`${prod}publicProfile`,'_blank','noopener')
                            // },400)
                          }}
                        >
                          <div className="pic">
                            {!!athlete.profileImages ? (
                              <img
                                src={
                                  athlete.profileImages != null
                                    ? imageDownloadUrl +
                                      "/" +
                                      athlete.profileImages
                                    : userImg
                                }
                              />
                            ) : !!athlete?.athleteFullName ? (
                              <span
                                style={{
                                  textAlign: "center",
                                  display: "block",
                                }}
                              >
                                {getNameInitial(athlete)}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="desgination">
                            <p>
                              <strong>{athlete.athleteFullName}</strong>
                              {athlete.sportName}-{athlete.yearValue}
                              <br />
                              {athlete.city}{" "}
                              {!!athlete.city && !!athlete.countryShortName
                                ? ","
                                : ""}{" "}
                              {athlete.countryShortName}
                            </p>
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            ) : null}
          </div>
          {getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ? (
            <nav>
              <ul>
                <Menu />
                {/* <li>
                <Link 
                // to="/programAndPricing"
                 className="upgradeLink">
                  Upgrade
                </Link>
              </li> */}
              </ul>
              <ul>
                <li className="mobileSearchIcon icon">
                  <button
                    // onClick={handleOpenModal}
                    type="button"
                    className="btn searchBtn"
                  >
                    <img src={search} alt="Search Icon" />
                  </button>
                </li>
                <li className="emailLink icon">
                  <Link
                    // to={{
                    //   pathname: "/message/coach",
                    //   state: { count: msgCount },
                    // }}
                    className={route == "message" ? "active" : ""}
                  >
                    {/* {msgCount && msgCount !== 0 ? ( */}
                    <span
                      className={`count ${route == "message" ? "active" : ""}`}
                    >
                      {msgCount}
                    </span>
                    {/* ) : (
                    ""
                  )} */}
                    <img src={email} alt="Email Icon" />
                  </Link>
                </li>
                <li className="notifyLink icon">
                  <Link
                    to={{
                      pathname: "/notification",
                      // state: { count: isReadCount },
                    }}
                    className={route == "notification" ? "active" : ""}
                  >
                    {isReadCount ? (
                      <span
                        className={`count ${
                          route == "notification" ? "active" : ""
                        }`}
                      >
                        {isReadCount}
                      </span>
                    ) : (
                      ""
                    )}

                    <img src={notification} alt="Notification Icon" />
                  </Link>
                </li>
                {getAuthToken() ? (
                  <ProfileSetting handleLogout={handleLogout} />
                ) : null}
                <li
                  className="sideBarMenu"
                  id="sideBarMenu"
                  onClick={handleOpenMenu}
                >
                  <button>
                    <span></span>
                  </button>
                </li>
              </ul>
            </nav>
          ) : (
            <nav>
              <ul>
                <Menu
                  handleOpenMenu={handleOpenMenu}
                  isProfileClose={isProfileClose}
                  setIsProfileClose={() => setIsProfileClose(false)}
                />
                {/*<li>*/}
                {/*  <Link*/}
                {/*    to="/programAndPricing"*/}
                {/*    className="upgradeLink"*/}
                {/*    onClick={() => {*/}
                {/*      handalCloseDropDown();*/}
                {/*      localStorage.setItem("showWithSetting", false);*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Upgrade*/}
                {/*  </Link>*/}
                {/*</li>*/}
              </ul>
              <ul>
                <li className="mobileSearchIcon icon">
                  <button
                    onClick={() => {
                      handleOpenModal();
                      setIsProfileClose(false);
                    }}
                    type="button"
                    className="btn searchBtn"
                  >
                    <img src={search} alt="Search Icon" />
                  </button>
                </li>
                <li className="emailLink icon">
                  <Link
                    to={{
                      pathname: "/message/support/replyMessage",
                      state: { count: msgCount },
                    }}
                    onClick={() => {
                      setDropDown(false);
                      localStorage.setItem("showWithSetting", false);
                      setIsProfileClose(false);
                    }}
                    className={route == "message" ? "active" : ""}
                  >
                    {msgCount && msgCount !== 0 ? (
                      <span
                        className={`count ${
                          route == "message" ? "active" : ""
                        }`}
                      >
                        {msgCount}
                      </span>
                    ) : (
                      ""
                    )}
                    <img src={email} alt="Email Icon" />
                  </Link>
                </li>
                <li className="notifyLink icon">
                  <Link
                    to={{
                      pathname: "/notification",
                      state: { count: isReadCount },
                    }}
                    onClick={() => {
                      handalCloseDropDown();
                      localStorage.setItem("showWithSetting", false);
                      setIsProfileClose(false);
                    }}
                    className={route == "notification" ? "active" : ""}
                  >
                    {isReadCount ? (
                      <span
                        className={`count ${
                          route == "notification" ? "active" : ""
                        }`}
                      >
                        {isReadCount}
                      </span>
                    ) : (
                      ""
                    )}

                    <img src={notification} alt="Notification Icon" />
                  </Link>
                </li>
                {getAuthToken() ? (
                  <ProfileSetting
                    handleLogout={handleLogout}
                    isProfileClose={isProfileClose}
                    setIsProfileClose={() => setIsProfileClose(!isProfileClose)}
                  />
                ) : null}
                <li
                  className="sideBarMenu"
                  id="sideBarMenu"
                  onClick={() => {
                    handleOpenMenu();
                    setIsProfileClose(false);
                  }}
                >
                  <button>
                    <span></span>
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </header>

      <div
        ref={modalRef}
        className={openModal ? "modal fade show" : "modal fade"}
        id="searchModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="searchModal"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <a
                type="button"
                className="close"
                onClick={() => {
                  handleCloseModal();
                  setSearchByAthleteName("");
                  setSearchAthleteData([]);
                  setSearchMobAthleteData([]);
                  setSearchMobByAthleteName("");
                }}
              >
                <span aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.001"
                    height="7.001"
                    viewBox="0 0 8.001 7.001"
                  >
                    <path
                      id="arrow-left-short"
                      d="M12,8a.5.5,0,0,1-.5.5H5.707l2.147,2.146a.5.5,0,1,1-.708.708l-3-3a.5.5,0,0,1,0-.708l3-3a.5.5,0,0,1,.708.708L5.707,7.5H11.5A.5.5,0,0,1,12,8Z"
                      transform="translate(-3.999 -4.499)"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </svg>
                </span>
              </a>
              <input
                type="search"
                name=""
                value={searchMobByAthleteName}
                placeholder="Search Athletes"
                onChange={(e) => {
                  setOpenCloseDropDown(e);
                }}
              />

              {searchMobAthleteData?.length > 0 ? (
                <div
                  // ref={modalRef}
                  className={
                    DropDown == true
                      ? "dropdown-menu dropdownopen"
                      : "dropdown-menu"
                  }
                >
                  <div className="title">Search Results</div>
                  <ul className="search-list">
                    {searchMobAthleteData.length > 0 &&
                      searchMobAthleteData.map((athlete) => (
                        <li id={athlete.id}>
                          <Link
                            // to={`/publicProfile/${athlete.id}`}
                            // to={`/publicProfile/1/${+athlete?.id}`}//old route
                            to={`/athlete/${athlete?.athleteFullName
                              ?.trim()
                              ?.toLowerCase()
                              ?.toString()
                              ?.split(" ")
                              ?.join("-")}-${athlete.sysGenId}`}
                            onClick={() => {
                              handalCloseDropDown();
                              profileStatus({
                                type: "CHANGE_ATHLETE_PROFILE_VIEW_STATE",
                                payload: {
                                  id: athlete?.id || 0,
                                  status: true,
                                },
                              });
                              dispatch({
                                type: "RENDER_COUNT_START",
                                payload: 1,
                              });
                              localStorage.setItem(
                                "pubViewID",
                                athlete.id || 0
                              );
                              sessionStorage.setItem(
                                "athlete_own_profile",
                                false
                              );
                              userProfileApi(
                                {
                                  obj: {
                                    userrole: "ATHLETES",
                                    profileviewedby: getUserInfo()?.id ?? 0,
                                    profileviewto: +athlete?.id || 0,
                                  },
                                },
                                true,
                                false
                              );
                              setopenModal(false);
                              setSearchAthleteData([]);
                              setSearchByAthleteName(null);
                              setSearchMobByAthleteName(null);
                              setSearchMobAthleteData([]);
                              // setTimeout(()=>{
                              //   window.open(`${prod}publicProfile`,'_blank','noopener')
                              // },400)
                            }}
                          >
                            <div className="pic">
                              {!!athlete?.profileImages ? (
                                <img
                                  src={
                                    athlete.profileImages != null
                                      ? imageDownloadUrl +
                                        "/" +
                                        athlete.profileImages
                                      : userImg
                                  }
                                />
                              ) : !!athlete?.athleteFullName ? (
                                <span
                                  style={{
                                    textAlign: "center",
                                    display: "block",
                                  }}
                                >
                                  {getNameInitial(athlete)}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="desgination">
                              <p>
                                <strong>{athlete.athleteFullName}</strong>
                                {athlete.sportName}-{athlete.yearValue}
                                <br />
                                {athlete.city}{" "}
                                {!!athlete.city && !!athlete.countryShortName
                                  ? ","
                                  : ""}{" "}
                                {athlete.countryShortName}
                              </p>
                            </div>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardCount: state.athlete.dashboard.dashboardCount.dashboardCount,
    notificationUnreadList:
      state.athlete.dashboard.notification.notificationUnreadCount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dashboardCountStart: (data) => dispatch(dashboardCountStart(data)),
    notificationUnreadCountListStart: (data) =>
      dispatch(notificationUnreadCountListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
