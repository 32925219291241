import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ADD_NEW_CARD_START: "ADD_NEW_CARD_START",
  ADD_NEW_CARD_FAIL: "ADD_NEW_CARD_FAIL",
  ADD_NEW_CARD_SUCCESS: "ADD_NEW_CARD_SUCCESS",
};

// ============================================ Actions ========================================================

export const addNewCardStart = (data) => ({
  type: actionType.ADD_NEW_CARD_START,
  payload: data,
});

export const addNewCardFail = (data) => ({
  type: actionType.ADD_NEW_CARD_FAIL,
  payload: data,
});
export const addNewCardSuccess = (data) => ({
  type: actionType.ADD_NEW_CARD_SUCCESS,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function addNewCardReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ADD_NEW_CARD_START:
      return {
        ...state,
        loading: true,
      };

    case actionType.ADD_NEW_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ADD_NEW_CARD_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* addNewCardSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(addNewCardSuccess(response.data.data.saveCarddetails));
      if (action.payload.refreshFun) {
        action.payload.refreshFun();
      }
      if(action.payload.saveInMembership) {
        action.payload.saveInMembership()
      }
      if(action.payload.refresh) {
        action.payload.refresh()
      }
    } else {
      messagePopup("", "Add new Card failed", "error");
      yield put(addNewCardFail("Payment Card List failed"));
    }
  } catch (error) {
    messagePopup("", "Payment Card List failed", "error");
    yield put(addNewCardFail("Payment Card List failed"));
  }
}

export function* watchAddNewCardSaga() {
  yield takeEvery(actionType.ADD_NEW_CARD_START, addNewCardSaga);
}
