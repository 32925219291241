import React from "react";

const PlayButton = (props) => {
  return (
    <button className="btn" onClick={() => props.open()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="17"
        viewBox="0 0 12 17"
      >
        <path
          id="Polygon_15"
          data-name="Polygon 15"
          d="M8.5,0,17,12H0Z"
          transform="translate(12) rotate(90)"
          fill="#fff"
        ></path>
      </svg>
      Play Tutorial
    </button>
  );
};

export default PlayButton;
