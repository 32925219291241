import React, { useEffect } from "react";

function VerificationProcess(props) {
  const [activeClass, setActiveClass] = React.useState(false);
  const onClick = () => {
    setActiveClass(!activeClass);
  };
  // const scrollToTop = () => {
  //   // window.scrollTo({
  //   //   top: 0,
  //   //   behavior: "smooth",
  //   // });
  // };
  useEffect(() => {
    const timer = setTimeout(() => {
      setActiveClass(true);
    }, 2000);
    const interval = activeClass
      ? setInterval(() => setActiveClass(true), 120000)
      : null;
    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, []);
  return (
    <div className={`verificationProcess  ${activeClass && "active"}`}>
      <div className="head">
        <h5 className="title">
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24.007"
              height="23.998"
              viewBox="0 0 24.007 23.998"
            >
              <defs>
                <style></style>
                <linearGradient
                  id="a"
                  x1="0.249"
                  y1="0.579"
                  x2="1.386"
                  y2="-0.558"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stopColor="#ffb92d" />
                  <stop offset="1" stopColor="#f59500" />
                </linearGradient>
                <linearGradient
                  id="b"
                  x1="0.4"
                  y1="0.428"
                  x2="0.743"
                  y2="0.085"
                  xlinkHref="#a"
                />
                <linearGradient
                  id="c"
                  x1="-0.001"
                  y1="0.508"
                  x2="0.996"
                  y2="0.508"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stopColor="#fff465" />
                  <stop offset="1" stopColor="#ffe600" />
                </linearGradient>
                <linearGradient
                  id="d"
                  x1="0.677"
                  y1="-0.098"
                  x2="-0.333"
                  y2="0.668"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stopColor="#be3f45" stopOpacity="0" />
                  <stop offset="1" stopColor="#be3f45" />
                </linearGradient>
                <linearGradient
                  id="e"
                  x1="0.599"
                  y1="-2.731"
                  x2="0.05"
                  y2="-2.008"
                  xlinkHref="#d"
                />
              </defs>
              <g transform="translate(0 -0.097)">
                <path
                  className="a"
                  d="M20.5,24.095H3.5A3.5,3.5,0,0,1,.374,19.032l8.5-17a3.5,3.5,0,0,1,6.258,0l8.5,17A3.5,3.5,0,0,1,20.5,24.095Z"
                  transform="translate(0 0)"
                />
                <path
                  className="b"
                  d="M20.5,24.095H3.5A3.5,3.5,0,0,1,.374,19.032l8.5-17a3.5,3.5,0,0,1,6.258,0l8.5,17A3.5,3.5,0,0,1,20.5,24.095Z"
                  transform="translate(0 0)"
                />
                <path
                  className="c"
                  d="M41.972,38.589l-8.5-17a2.557,2.557,0,0,0-4.574,0l-8.5,17a2.557,2.557,0,0,0,2.287,3.7h17a2.557,2.557,0,0,0,2.287-3.7Zm-.913,1.993a1.605,1.605,0,0,1-1.374.766h-17a1.615,1.615,0,0,1-1.445-2.338l8.5-17a1.615,1.615,0,0,1,2.89,0l8.5,17A1.605,1.605,0,0,1,41.059,40.582Z"
                  transform="translate(-19.181 -19.137)"
                />
                <path
                  className="d"
                  d="M240.568,158.057h-1.339l-7.738-7.738a.6.6,0,0,1-.344-.582c0-1.044-.043-1.789-.129-4.152s-.129-2.848-.129-3.892a.93.93,0,0,1,.34-.771,1.364,1.364,0,0,1,.881-.272,1.013,1.013,0,0,1,.794.283c.033.036,7.087,7.088,9.522,9.522l1.27,2.54A3.5,3.5,0,0,1,240.568,158.057Z"
                  transform="translate(-220.064 -133.963)"
                />
                <path
                  className="e"
                  d="M236.067,389.633h-3.5l-4.014-4.014a1.058,1.058,0,0,1,0-1.658,1.444,1.444,0,0,1,.972-.343,1.233,1.233,0,0,1,.871.343Z"
                  transform="translate(-217.453 -365.538)"
                />
                <path
                  className="f"
                  d="M228.154,153.217a1.06,1.06,0,0,1,.4-.834,1.444,1.444,0,0,1,.973-.344,1.232,1.232,0,0,1,.871.344,1.118,1.118,0,0,1,0,1.658,1.213,1.213,0,0,1-.871.353,1.419,1.419,0,0,1-.973-.353A1.07,1.07,0,0,1,228.154,153.217Zm.128-11.526a.931.931,0,0,1,.34-.772,1.367,1.367,0,0,1,.881-.272,1.015,1.015,0,0,1,.794.283,1.1,1.1,0,0,1,.253.761c0,1.044-.018,1.529-.053,3.892s-.053,3.108-.053,4.151a.571.571,0,0,1-.306.511,1.213,1.213,0,0,1-.635.185q-.964,0-.964-.7c0-1.044-.043-1.788-.128-4.151S228.282,142.736,228.282,141.692Z"
                  transform="translate(-217.456 -133.961)"
                />
              </g>
            </svg>
          </span>
          <span>Verification Process</span>
        </h5>
        <button className="closeBtn" onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <defs>
              <style></style>
            </defs>
            <path
              className="a"
              d="M23.5,9.111,21.889,7.5,15.5,13.889,9.111,7.5,7.5,9.111,13.889,15.5,7.5,21.889,9.111,23.5,15.5,17.111,21.889,23.5,23.5,21.889,17.111,15.5Z"
              transform="translate(-7.5 -7.5)"
            />
          </svg>
        </button>
      </div>
      <div className="stepInfo">
        {/* <span className="passed"></span>
        <span className="passed"></span>
        <span className="passed"></span>
        <span className="passed"></span>
        <span></span> */}
            <div className="steps">
            <span
              className={
                props.verifiedsteps > 0
                  ? "passed" + " " + (props.verifiedsteps === 1 ? "active" : "")
                  : ""
              }
            ></span>
            <span
              className={
                props.verifiedsteps > 1
                  ? "passed" + " " + (props.verifiedsteps === 2 ? "active" : "")
                  : ""
              }
            ></span>
            <span
              className={
                props.verifiedsteps > 2
                  ? "passed" + " " + (props.verifiedsteps === 3 ? "active" : "")
                  : ""
              }
            ></span>
            <span
              className={
                props.verifiedsteps > 3
                  ? "passed" + " " + (props.verifiedsteps === 4 ? "active" : "")
                  : ""
              }
            ></span>
            {/*<span*/}
            {/*  className={*/}
            {/*    props.verifiedsteps > 4*/}
            {/*      ? "passed" + " " + (props.verifiedsteps === 5 ? "active" : "")*/}
            {/*      : ""*/}
            {/*  }*/}
            {/*></span>*/}
          </div>
      </div>
      <span className="setpsInfo">{props.verifiedsteps} out of 4 steps completed!</span>
      <p className="info">
      We verify profiles to ensure that the SportsForce community can be trusted by all athletes, college coaches, and professional scouts.
      </p>
      {/*<a className="verifyProfileLink" */}
      {/*//onClick={scrollToTop}*/}
      {/*onClick={()=>{   window.open('https://calendly.com/sportsforce/recruiting-assessment-round-robin','_blank')}}*/}
      {/*   >*/}
      {/*  Verify your profile*/}
      {/*</a>*/}
    </div>
  );
}

export default VerificationProcess;
