import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod, fetchMethod } from "../../services/httpService"
import messagePopup from "../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    REGIONS_LIST_START: 'REGIONS_LIST_START',
    REGIONS_LIST_SUCCESS: 'REGIONS_LIST_SUCCESS',
    REGIONS_LIST_FAILED: 'REGIONS_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const regionsListStart = (data) => ({
   type: actionType.REGIONS_LIST_START,
   payload: data
})

export const regionsListSuccess = (data) => ({
    type: actionType.REGIONS_LIST_SUCCESS,
    payload: data
})

export const regionsListFailed = (data) => ({
    type: actionType.REGIONS_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function regionsListReducer(state = INIT_STATE,action){
  switch(action.type){
      case actionType.REGIONS_LIST_START: 
        return {
            ...state,
            loading: true
        }
      case actionType.REGIONS_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            data: action.payload,
            error: null
        }  
      case actionType.REGIONS_LIST_FAILED:
        return {
            ...state,
            loading: false,
            data: null,
            error: action.payload
        }       
      default: return {...state}  
  }
} 

// ===================================================== side effect =============================================

export function* regionsListSaga(action){
   try {
    const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
    if(response && response.status === 200){
       const allRegions = response.data.data.allRegions.Regions ? response.data.data.allRegions.Regions : null
       yield put(regionsListSuccess(allRegions));
    } else {
      messagePopup('','Regions List failed','error')   
      yield put(regionsListFailed('Regions List failed')) 
    }
   } catch (error) {
     messagePopup('','Regions List failed','error')   
     yield put(regionsListFailed('Regions List failed'))
   }
}

export function* watchRegionsListSaga(){
    yield takeEvery(actionType.REGIONS_LIST_START, regionsListSaga)
}