import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ADD_USER_COLLEGE_START: "ADD_USER_COLLEGE_START",
  REMOVE_USER_COLLEGE_START: "REMOVE_USER_COLLEGE_START",
  USER_COLLEGE_SUCCESS: "USER_COLLEGE_SUCCESS",
  USER_COLLEGE_FAILED: "USER_COLLEGE_FAILED",
};

// ============================================ Actions ========================================================

export const addUserCollegeStart = (data) => ({
  type: actionType.ADD_USER_COLLEGE_START,
  payload: data,
});

export const removeUserStart = (data) => ({
  type: actionType.REMOVE_USER_COLLEGE_START,
  payload: data,
});

export const userCollegeSuccess = (data) => ({
  type: actionType.USER_COLLEGE_SUCCESS,
  payload: data,
});

export const userCollegeFailed = (data) => ({
  type: actionType.USER_COLLEGE_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function addRemoveUserCollegeReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.REMOVE_USER_COLLEGE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ADD_USER_COLLEGE_START:
      return {
        ...state,
        loading: true,
      };

    case actionType.USER_COLLEGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.USER_COLLEGE_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* addUserCollegeSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Usercolleges/addCollege`,
      action.payload.data,
      "post",
      true
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        yield put(userCollegeSuccess("College added successfully"));
        action.payload.getUserCollegeList();
      }
    } else {
      messagePopup("", "Add to favorite college failed", "error");
      yield put(userCollegeFailed("Add to favorite college failed"));
      action.payload.getUserCollegeList();
    }
  } catch (error) {
    messagePopup("", "Add to favorite college failed", "error");
    yield put(userCollegeFailed("Add to favorite college failed"));
    action.payload.getUserCollegeList();
  }
}

export function* removeUserCollegeSaga(action) {
  const { data, getCollegesList } = action.payload;
  try {
    const response = yield fetchMethod(
      `/api/Usercolleges/removeCollege`,
      data,
      "post",
      true
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        yield put(userCollegeSuccess(response.data.response.Msg));
        getCollegesList();
      } else {
        messagePopup("", "Remove college failed", "error");
        yield put(userCollegeFailed("Remove college failed"));
        getCollegesList();
      }
    } else {
      messagePopup("", "Remove college failed", "error");
      yield put(userCollegeFailed("Remove college failed"));
      getCollegesList();
    }
  } catch (error) {
    messagePopup("", "Remove college failed", "error");
    yield put(userCollegeFailed("Remove college failed"));
    getCollegesList();
  }
}

export function* watchAddRemoveUserCollegeSaga() {
  yield takeEvery(actionType.ADD_USER_COLLEGE_START, addUserCollegeSaga);
  yield takeEvery(actionType.REMOVE_USER_COLLEGE_START, removeUserCollegeSaga);
}
