export const getNotificationQuery = `query allNotifications($where : JSON, $first: Int, $last:Int){
    allNotifications(where: $where, first: $first , last: $last)
    {
        totalCount
      Notifications
    {
      id
      msg
      createdon
      isread
      userid
      coachid
      active
      notificationuseridmaprel{
         
              Athletes{
          id
          
          onBoardingUserIdMap{
            Onboardings{
              profileimages
            }
          }  
          }
      }
    }
    }
  }`;


  export const saveNotificationQuery = `
mutation saveNotification($obj: NotificationInput!){
    saveNotification(obj: $obj){
    id
    isread
    userid
  }
}
`;

export const markAllReadQuery = `mutation($where:JSON ,$data:JSON){
  NotificationUpdateAll(where:$where,data:$data)
}`