export const getDashboardQuery = `
query allMessages($where:JSON){
  allMessages(where:$where){
    totalCount
  }
}`;

export const getMessageUnreadCount = `
query allMessagereplies($where:JSON){
  allMessagereplies(where:$where){
    totalCount
  }
}`;

export const getNotificationUnreadQuery = `query allNotifications($where : JSON, $first: Int, $last:Int){
  allNotifications(where: $where, first: $first , last: $last)
  {
    Notifications
  {
    id
    isread
    userid
   
  }
  }
}`;