import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_STATS_FIELD_WITH_POSITION_START: "ALL_STATS_FIELD_WITH_POSITION_START",
  ALL_STATS_FIELD_WITH_POSITION_FAIL: "ALL_STATS_FIELD_WITH_POSITION_FAIL",
  ALL_STATS_FIELD_WITH_POSITION_SUCCESS:
    "ALL_STATS_FIELD_WITH_POSITION_SUCCESS",
};

// ============================================ Actions ========================================================
export const allStatsFieldWithPositionStart = (data) => ({
  type: actionType.ALL_STATS_FIELD_WITH_POSITION_START,
  payload: data,
});

export const allStatsFieldWithPositionSuccess = (data) => ({
  type: actionType.ALL_STATS_FIELD_WITH_POSITION_SUCCESS,
  payload: data,
});

export const allStatsFieldWithPositionFail = (data) => ({
  type: actionType.ALL_STATS_FIELD_WITH_POSITION_FAIL,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allStatsFieldWithPositionReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.ALL_STATS_FIELD_WITH_POSITION_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_STATS_FIELD_WITH_POSITION_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case actionType.ALL_STATS_FIELD_WITH_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allStatsFieldWithPositionSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allStatsFieldWithPositionSuccess(
          response.data.data.allStatsfieldbasedonpositions
        )
      );
    } else {
      messagePopup("", "Stats Field List failed", "error");
      yield put(allStatsFieldWithPositionFail("Stats Field List failed"));
    }
  } catch (error) {
    messagePopup("", "Stats Field list failed", "error");
    yield put(allStatsFieldWithPositionFail("Stats Field List failed"));
  }
}

//Watcher
export function* watchAllStatsFieldWithPosition() {
  yield takeEvery(
    actionType.ALL_STATS_FIELD_WITH_POSITION_START,
    allStatsFieldWithPositionSaga
  );
}
