import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
    CLG_ACTIVITY_LIST_START: "CLG_ACTIVITY_LIST_START",
    CLG_ACTIVITY_LIST_SUCCESS: "CLG_ACTIVITY_LIST_SUCCESS",
    CLG_ACTIVITY_LIST_FAILURE: "CLG_ACTIVITY_LIST_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const getClgActivityListStart = (data) => ({
    type: actionType.CLG_ACTIVITY_LIST_START,
    payload: data,
});
export const getClgActivityListSuccess = (data) => ({
    type: actionType.CLG_ACTIVITY_LIST_SUCCESS,
    payload: data,
});

export const getClgActivityListFailure = (data) => ({
    type: actionType.CLG_ACTIVITY_LIST_FAILURE,
    payload: data
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};
export default function getClgActivityReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.CLG_ACTIVITY_LIST_START:
            return { ...state, loading: true };

        case actionType.CLG_ACTIVITY_LIST_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: null };

        case actionType.CLG_ACTIVITY_LIST_FAILURE:
            return { ...state, loading: false, data: null, error: action.payload };

        default:
            return { ...state };
    }
}

/* ======================================= Side Effects ==========================================*/
function* getClgActivitySaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true)
        if(response.status === 200){
            const allClgActivity =  response?.data?.data?.allColleges?.Colleges ?? [] ;
            yield put(getClgActivityListSuccess(allClgActivity));
        } else {
            messagePopup('', 'Fetch College Activity Data Failed', 'error');
            yield put(getClgActivityListFailure('Failed, Error occurred'))
        }
    } catch (error) {
        messagePopup('', 'Fetch College Activity Data Failed', 'error');
        yield put(getClgActivityListFailure('Failed, Error occurred'))
    }
}

export function* watchClgActivitySaga() {
    yield takeEvery(actionType.CLG_ACTIVITY_LIST_START, getClgActivitySaga);
}

