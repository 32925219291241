import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  apiKey,
  geocodeJson,
  imageDownloadUrl,
} from '../../../../../../config';
import { allCollegeDetailsListStart } from '../../../../../../redux/common/allCollegeData';
import '../assets/css/collegeDetails.scss';
import collegeBanner from '../assets/img/college-detail-banner.png';
import InstagramIcon from '../../profile/assets/img/instagram.png';
import TeamLogoPng from '../assets/img/TeamLogo.png';
import { useHistory, useLocation } from 'react-router-dom';
import {
  collegeDetailsQuery,
  getCollegesFavListQuery,
  getAllFollowersQuery,
  getAllOfferQuery,
  getAllProgresses,
  getAllUserCollegesQuery,
  getCoachDetailsQuery,
  getViewCollegesQuery,
  saveProgressQuery,
} from './CollegeDetailsQuery';
import imageSettingGray from '../assets/img/settings-gray-icon.svg';
import substractionIcon from '../assets/img/subtraction-icon.svg';
import motarboard from '../assets/img/mortarboard.svg';
import analyticsIcon from '../assets/img/analytics-icon.svg';
import lockIcon from '../assets/img/lock-icon.svg';
import filterIcon from '../assets/img/filter-icon.svg';
import researchSchoolIcon from '../assets/img/research-school-icon.svg';
import emailIcon from '../assets/img/email-icon.svg';
import downloadIcon from '../assets/img/download-icon.svg';
import recievedMessage from '../assets/img/received-message.svg';
import transcriptRequest from '../assets/img/transcript-request.svg';
import followersIcon from '../assets/img/followers-icon.svg';
import videoCamera from '../assets/img/video-camera.svg';
import releaseProfile from '../assets/img/release-profile-icon.svg';
import liveEvaluationIcon from '../assets/img/live-evaluation-icon.svg';
import digitalConversationIcon from '../assets/img/digital-conversation-icon.svg';
import phoneConversationIcon from '../assets/img/phone-conversation.svg';
import offCampusContact from '../assets/img/off-campus-contact.svg';
import unofficialVisitIcon from '../assets/img/unofficial-visit-icon.svg';
import languageOutlineIcon from '../assets/img/language-outline-icon.svg';
import badgedIcon from '../assets/img/badged-icon.svg';
import newspaperIcon from '../assets/img/newspaper-icon.svg';
import bxWalk from '../assets/img/bx-walk1.svg';
import walkIcon from '../assets/img/walk-icon.svg';
import documentTextIcon from '../assets/img/document-text-icon.svg';
import userSpeakingIcon from '../assets/img/user-speaking-icon.svg';
import contractSignedIcon from '../assets/img/contract-signed-icon.svg';
import handshakeIcon from '../assets/img/handshake-icon.svg';
import { collegesListStart } from '../../../../../../redux/athlete/dashboard/colleges/collegeList';
import { getUserInfo } from '../../../../../../services/authService';
import {
  addMyCommitmentStart,
  getCommitmentStart,
} from '../../../../../../redux/athlete/profile/commitment';
import {
  getMyCommitmentQuery,
  getMySignedCommitmentQuery,
  saveSignedCommitment,
} from '../../homepage/leftSideBar/query';
import {
  offersListsaveStart,
  offersListStart,
} from '../../../../../../redux/athlete/dashboard/editUserProfile/offers';
import { updateUserCollegeStart } from '../../../../../../redux/athlete/dashboard/colleges/updateUserCollege';
import {
  saveUserCollegeQuery,
  saveCollegepopupQuery,
  getCollegepopupQuery,
} from '../myColleges/CollegeListQuery';
import { Dialog } from '@mui/material';
import { Link } from 'react-router-dom';
import messagePopup from '../../../../../../utils/messagePopup';
import DeleteItem from '../../../../../common/DeleteModal';
import {
  getAllCommitmentType,
  getAllSignedCommitmentType,
  saveMyCommitmentQuery,
} from '../../profile/ProfileEdit/profileQuery';
import { saveOffersQuery } from '../../editUserProfile/OfferQuery';
import { getSlashFormattedDate } from '../../../../../../utils/dateTimeFormatter';
import { getAllCoachDetailsStart } from '../../../../../../redux/athlete/dashboard/editUserProfile/allCoachesDetails';
import { getFollowingListStart } from '../../../../../../redux/athlete/dashboard/homepage/followingsList';
import Offer from '../../editUserProfile/Offer';
import Map from '../Map';
import { viewCollegeListStart } from '../../../../../../redux/athlete/dashboard/colleges/viewCollege';
import {
  myProgressListStart,
  myProgressSaveStart,
} from '../../../../../../redux/athlete/dashboard/colleges/myProgress';
import { membershipListStart } from '../../../../../../redux/athlete/dashboard/account/membership';
import { getAllMembershipsQuery } from '../../account/myAccounts/membershipQuery';
import VideoPopup from '../../resources/screens/Utils/VideoPopup';
import Academics from './Academics';
import Admissions from './Admissions';
import { athletesDetailsStart } from '../../../../../../redux/common/athletesDetails';
import CaampusLife from './CaampusLife';
import { offerListStart } from '../../../../../../redux/common/offerList';
import {
  getAllOfferListQuery,
  getAllInterestLevelQuery,
} from '../../../../../common/CommonQuery';
import { commitmentStatusListStart } from '../../../../../../redux/common/commitmentStatus';
import Recruiting from './Recruiting';
import Commits from './Commits';
import BackdropLoader from '../../../../../common/Loader';
import warningMessagePopup from '../../../../../../utils/warningMagPop';
import {
  fetchGraphMethod,
  fetchMethod,
} from '../../../../../../services/httpService';
import InterestLevelModal from '../../homepage/InterestLevelModal';
import { getCamelCase } from '../../../../../common/camelCaseFunc';
import {
  sendRemoveCommitmentNotification,
  sendRemoveOfferNotification,
  sendRemoveSignedCommitmentNotification,
  sendAddSignedCommitmentNotification,
} from '../../../../../../utils/sendNotificationToAdvisor';
import toastr from 'toastr';

const Progress = ({
  getCommitmentStart,
  commitmentType,
  commitmentStatusListStart,
  offerListStart,
  offerListTypes,
  collegeFollowAthlete,
  allMemberships,
  getOffersListStart,
  updateUserCollegeStart,
  allUserColleges,
  allOffersList,
  commitmentListData,
  collegeid,
  getFavoriteCollegesList,
  collegeData,
  addMyCommitmentStart,
  isFav,
  offersListsaveStart,
  collegeStatusUpdate,
  myProgressSaveStart,
  myProgressListStart,
  myProgress,
  openIntrestModalFromTarget,
}) => {
  const [targetTab, setTargetTab] = useState(true);
  const [contactTab, setContactTab] = useState(false);
  const [interestTab, setInterestTab] = useState(false);
  const [activeTab, setActiveTab] = useState(false);
  const [visitTab, setVisitTab] = useState(false);
  const [offerTab, setOfferTab] = useState(false);
  const [commitTab, setCommitTab] = useState(false);
  const [signedTab, setSignedTab] = useState(false);
  const [alumniTab, setAlumniTab] = useState(false);
  const [collegeState, setCollegeState] = useState();
  const [isAddedInCommitment, setIsAddedInCommitment] = useState(null);
  const [isAddedInOffer, setIsAddedInOffer] = useState(null);
  const [offerModal, setOfferModal] = useState(false);
  const [commitModal, setCommitModal] = useState(false);
  const [deleteCommitment, setDeleteCommitment] = useState(false);
  const [deleteOfferModal, setDeleteOfferModal] = useState(false);
  const [deleteCommitOffer, setDeleteCommitOffer] = useState(false);
  const [progressId, setProgressId] = useState(null);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [offerTypes, setOfferTypes] = useState(null);
  const [allFavColleges, setAllFavColleges] = useState(null);
  const [allOffers, setOffers] = useState(null);
  const [commitmentList, setcommitmentList] = useState(null);
  const currentDate = new Date().toISOString().split('T')[0];
  const [progressInfoModal, setProgressInfoModal] = useState(false);
  const [progressInfoText, setProgressInfoText] = useState('');
  const [infoModalCheckboxClicked, setInfoModalCheckboxClicked] =
    useState(false);
  const [signedModal, setSignedModal] = useState(false);
  const [signedCommitmentType, setSignedCommitmentType] = useState([]);
  const [signedList, setSignedList] = useState([]);
  const [deleteSignedAndCommitment, setdeleteSignedAndCommitment] =
    useState(false);
  const [deleteSignedCommitOffer, setDeleteSignedCommitOffer] = useState(false);
  const [signedListByUser, setSignedListByUser] = useState([]);
  const [unFavProgressModal, setUnFavProgressModal] = useState(false);
  const [deleteSigned, setDeleteSigned] = useState(false);
  const [decommitEarlierCollege, setDecommitEarlierCollege] = useState(false);
  const [decommitEarlierSigned, setDecommitEarlierSigned] = useState(false);
  const [decommitSignedFromOtherCollege, setDecommitSignedFromOtherCollege] =
    useState(false);
  const [fields, setFields] = useState({
    collegepreference: false,
    categorized: false,
  });
  let history = useHistory();

  useEffect(async () => {
    await getCommitmentData();
    await getSignedListByUser();
    await getOffersListData();
    await getAllProgressData();
    await getOffersTypeData();
    await getCommitmentStatusData();
    await getAllFavColleges();
    await getCollegePopup();
    await getSignedCommitmentType();
    await getSignedCommitmentList();
  }, []);
  useEffect(() => {
    if (offerListTypes) {
      setOfferTypes(offerListTypes);
    }
  }, [offerListTypes]);
  useEffect(() => {
    const ifDataAddedToMyProgress = myProgress
      ? myProgress.filter((progress) => {
          return collegeid == progress.collegeid;
        })
      : null;
    if (
      ifDataAddedToMyProgress &&
      ifDataAddedToMyProgress[0] &&
      ifDataAddedToMyProgress[0].id
    )
      setProgressId(ifDataAddedToMyProgress && ifDataAddedToMyProgress[0]);
  }, [myProgress]);
  useEffect(() => {
    const collegeStatus = allUserColleges
      ? allUserColleges.find((college) => {
          return college.collegeid == collegeid && college.active == 1;
        })
      : null;
    if (collegeStatus) {
      setCollegeState(collegeStatus);
    } else {
      setCollegeState(allUserColleges[0]);
    }
    const isInOffer = allOffers
      ? allOffers.find((college) => {
          return college.collegeid == collegeid;
        })
      : null;
    setIsAddedInOffer(isInOffer);
    const isInCommitment = commitmentList
      ? commitmentList.find((college) => {
          return college.collegeid == collegeid;
        })
      : null;
    setIsAddedInCommitment(isInCommitment);
  }, [allUserColleges, allOffers, commitmentList, fields.selectedValue]);

  const getSignedCommitmentType = async () => {
    await fetchGraphMethod(getAllSignedCommitmentType, null, true).then(
      (res) => {
        if (res?.data?.data?.allSignedcommitmenttypes) {
          setSignedCommitmentType(
            res?.data?.data?.allSignedcommitmenttypes?.Signedcommitmenttypes
          );
        }
      }
    );
  };

  const getSignedCommitmentList = async () => {
    if (collegeid) {
      await fetchGraphMethod(
        getMySignedCommitmentQuery,
        {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            active: 1,
            collegeid: +collegeid,
          },
        },
        true
      ).then((res) => {
        if (res?.data?.data?.allSignedcommitments?.totalCount) {
          setSignedList(
            res?.data?.data?.allSignedcommitments?.Signedcommitments
          );
        } else {
          setSignedList([]);
        }
      });
    }
  };

  const getSignedListByUser = async () => {
    await fetchGraphMethod(
      getMySignedCommitmentQuery,
      {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
      true
    ).then((res) => {
      if (res?.data?.data?.allSignedcommitments?.totalCount) {
        setSignedListByUser(
          res?.data?.data?.allSignedcommitments?.Signedcommitments
        );
      } else {
        setSignedListByUser([]);
      }
    });
  };

  const deleteSignedCommitment = async (commitId) => {
    await fetchGraphMethod(
      saveSignedCommitment,
      {
        obj: {
          id: commitId,
          active: 0,
        },
      },
      true
    ).then((res) => {
      // console.log(res);
    });
  };

  const allStatesDisabled = () => {
    if (
      (collegeState && collegeState.active == 1) ||
      isAddedInOffer ||
      isAddedInCommitment
    ) {
      return false;
    } else {
      return true;
    }
  };
  const getAllFavColleges = async () => {
    const getCollegeId = window.location.href.split('/');
    const variables = {
      where: {
        userid: getUserInfo() ? getUserInfo().id : null,
        active: 1,
        collegeid:
          commitmentList &&
          commitmentList[0] &&
          commitmentList[0].collegeid != null
            ? commitmentList[0].collegeid
            : getCollegeId != ''
            ? getCollegeId[getCollegeId.length - 2]
            : null,
      },
    };
    const res = await fetchGraphMethod(
      getAllUserCollegesQuery,
      variables,
      true
    );
    let data = res.data.data.allUsercolleges.Usercolleges;
    setAllFavColleges(data);
  };

  const getCollegePopup = async () => {
    const getCollegeId = window.location.href.split('/');
    const variables = {
      where: {
        userid: getUserInfo() ? getUserInfo().id : null,
        // collegeid: getCollegeId != "" ? getCollegeId[getCollegeId.length - 2] : null
      },
    };
    const res = await fetchGraphMethod(getCollegepopupQuery, variables, true);
    const popupData = res.data.data?.allCollegepopups?.Collegepopups;
    //It means that don't show popup was clicked.
    if (popupData.length > 0) {
      setInfoModalCheckboxClicked(true);
    }
  };

  const updateFavCollege = async () => {
    const getCollegeId = window.location.href.split('/');
    const variables =
      getCollegeId != '' && getCollegeId[getCollegeId.length - 1] == 0
        ? {
            where: {
              userid: getUserInfo() ? getUserInfo().id : null,
              active: 0,
              collegeid:
                commitmentList &&
                commitmentList[0] &&
                commitmentList[0].collegeid != null
                  ? commitmentList[0].collegeid
                  : getCollegeId != ''
                  ? getCollegeId[getCollegeId.length - 2]
                  : null,
              order: 'id DESC',
            },
            first: 1,
            last: 1,
          }
        : {
            where: {
              userid: getUserInfo() ? getUserInfo().id : null,
              collegeid:
                commitmentList &&
                commitmentList[0] &&
                commitmentList[0].collegeid != null
                  ? commitmentList[0].collegeid
                  : getCollegeId != ''
                  ? getCollegeId[getCollegeId.length - 2]
                  : null,
            },
          };
    const res = await fetchGraphMethod(
      getAllUserCollegesQuery,
      variables,
      true
    );
    let data = res.data.data.allUsercolleges.Usercolleges;
    let userCollegeVar = {
      obj: {
        userid: getUserInfo() ? getUserInfo().id : null,
        id: data ? data[0].id : null,
        target: 1,
        contact: 1,
        interest: 1,
        collegeactive: 1,
        visit: 1,
        offer: 1,
        commit: 0,
        signed: 0,
        alumni: 0,
      },
    };

    const respon = await fetchGraphMethod(
      saveUserCollegeQuery,
      userCollegeVar,
      true
    );
    // updateUserCollegeStart({
    //   query: saveUserCollegeQuery,
    //   variables: {
    //     obj: {
    //       userid: getUserInfo() ? getUserInfo().id : null,
    //       id: allFavColleges && allFavColleges[0] && allFavColleges[0].id,
    //       target: 1,
    //       contact: 1,
    //       interest: 1,
    //       collegeactive: 1,
    //       visit: 1,
    //       offer: 1,
    //       commit: 0,
    //       signed: 0,
    //       alumni: 0
    //     }
    //   },
    //   getFavoriteCollegesList: getFavoriteCollegesList,
    // })
  };

  const getOffersTypeData = () => {
    offerListStart({
      query: getAllOfferListQuery,
    });
  };
  const getCommitmentStatusData = () => {
    commitmentStatusListStart({
      query: getAllCommitmentType,
    });
  };
  const getCommitmentData = async () => {
    let collegeDetailsId = window.location.href.split('/');
    // await getCommitmentStart({
    //   query: getMyCommitmentQuery,
    //   variables: {
    //     obj: {
    //       userid: getUserInfo() ? getUserInfo().id : null,
    //       active: 1,
    //       collegeid: +collegeDetailsId[collegeDetailsId.length - 2]
    //     }
    //   }
    // })
    const variables = {
      obj: {
        userid: getUserInfo() ? getUserInfo().id : null,
        active: 1,
      },
    };
    const commitres = await fetchGraphMethod(
      getMyCommitmentQuery,
      variables,
      true
    );
    let commitdata =
      commitres.data.data.allCollegecommitments.Collegecommitments;
    setcommitmentList(commitdata);
    if (commitdata.length == 0) {
      return false;
    } else if (commitdata.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (progressId) {
      let fieldsT = fields;
      fieldsT['collegepreference'] =
        progressId.collegepreference == 1 ? true : false;
      fieldsT['categorized'] = progressId.categorized == 1 ? true : false;
      fieldsT['researchSchool'] =
        progressId.researchcollege == 1 ? true : false;
      fieldsT['matchAnalysis'] =
        progressId.matchanalysisconducted == 1 ? true : false;
      fieldsT['recieveView'] = progressId.receiveview == 1 ? true : false;
      fieldsT['sentMessage'] = progressId.sendmessage == 1 ? true : false;
      fieldsT['recievedMessage'] =
        progressId.receivedmessage == 1 ? true : false;
      fieldsT['videoMetricsRequest'] =
        progressId.videometricsrequest == 1 ? true : false;
      fieldsT['transcriptRequest'] =
        progressId.transcriptrequest == 1 ? true : false;
      fieldsT['releaseProfile'] = progressId.releaseprofile == 1 ? true : false;
      fieldsT['callTextRequest'] =
        progressId.calltextrequest == 1 ? true : false;
      fieldsT['introductionRequest'] =
        progressId.introductionrequest == 1 ? true : false;
      fieldsT['liveEvaluation'] = progressId.liveevaluation == 1 ? true : false;
      fieldsT['digitalConversation'] =
        progressId.digitalconversation == 1 ? true : false;
      fieldsT['phoneConversation'] =
        progressId.phonezoomconversation == 1 ? true : false;
      fieldsT['offCampusContact'] =
        progressId.offcampushcontact == 1 ? true : false;
      fieldsT['unOfficialVisit'] =
        progressId.unofficialvisit == 1 ? true : false;
      fieldsT['officialVisit'] = progressId.officialvisit == 1 ? true : false;
      fieldsT['lookAtFlSrLanguage'] =
        progressId.lookatflsrlanguage == 1 ? true : false;
      fieldsT['selectedValue'] = progressId.offertypeid;
      fieldsT['commitTypeValue'] = progressId.commitmentid
        ? progressId.commitmentid
        : null;
      fieldsT['signcommitmenttopalybaseball'] =
        progressId.signcommitmenttopalybaseball == 1 ? true : false;
      fieldsT['alumni'] = progressId.alumniloremipsum == 1 ? true : false;

      setFields({
        ...fieldsT,
      });
    }
  }, [progressId]);
  useEffect(() => {
    if (collegeStatusUpdate() == 'Target') {
      setTargetTab(true);
    } else if (collegeStatusUpdate() == 'Contact') {
      setContactTab(true);
      setTargetTab(false);
    } else if (collegeStatusUpdate() == 'Interest') {
      setInterestTab(true);
      setTargetTab(false);
    } else if (collegeStatusUpdate() == 'Active') {
      setActiveTab(true);
      setTargetTab(false);
    } else if (collegeStatusUpdate() == 'Visit') {
      setVisitTab(true);
      setTargetTab(false);
    } else if (collegeStatusUpdate() == 'Offer') {
      setOfferTab(true);
      setTargetTab(false);
    } else if (collegeStatusUpdate() == 'Commit') {
      setCommitTab(true);
      setTargetTab(false);
    } else if (collegeStatusUpdate() == 'Signed') {
      setSignedTab(true);
      setTargetTab(false);
    } else if (collegeStatusUpdate() == 'Alumni') {
      setAlumniTab(true);
      setTargetTab(false);
    } else {
      return;
    }
  }, [allUserColleges]);

  useEffect(() => {
    let collageStatusTab = collegeStatusUpdate();
    if (collageStatusTab == 'Target') {
      setTargetTab(true);
      setContactTab(false);
      setInterestTab(false);
      setActiveTab(false);
      setVisitTab(false);
      setOfferTab(false);
      setCommitTab(false);
      setSignedTab(false);
      setAlumniTab(false);
    } else if (collageStatusTab == 'Contact') {
      setTargetTab(false);
      setContactTab(true);
      setInterestTab(false);
      setActiveTab(false);
      setVisitTab(false);
      setOfferTab(false);
      setCommitTab(false);
      setSignedTab(false);
      setAlumniTab(false);
    } else if (collageStatusTab == 'Interest') {
      setTargetTab(false);
      setContactTab(false);
      setInterestTab(true);
      setActiveTab(false);
      setVisitTab(false);
      setOfferTab(false);
      setCommitTab(false);
      setSignedTab(false);
      setAlumniTab(false);
    } else if (collageStatusTab == 'Active') {
      setTargetTab(false);
      setContactTab(false);
      setInterestTab(false);
      setActiveTab(true);
      setVisitTab(false);
      setOfferTab(false);
      setCommitTab(false);
      setSignedTab(false);
      setAlumniTab(false);
    } else if (collageStatusTab == 'Visit') {
      setTargetTab(false);
      setContactTab(false);
      setInterestTab(false);
      setActiveTab(true);
      setVisitTab(false);
      setOfferTab(false);
      setCommitTab(false);
      setSignedTab(false);
      setAlumniTab(false);
    } else if (collageStatusTab == 'Offer') {
      setTargetTab(false);
      setContactTab(false);
      setInterestTab(false);
      setActiveTab(false);
      setVisitTab(false);
      setOfferTab(true);
      setCommitTab(false);
      setSignedTab(false);
      setAlumniTab(false);
    } else if (collageStatusTab == 'Commit') {
      setTargetTab(false);
      setContactTab(false);
      setInterestTab(false);
      setActiveTab(false);
      setVisitTab(false);
      setOfferTab(false);
      setCommitTab(true);
      setSignedTab(false);
      setAlumniTab(false);
    } else if (collageStatusTab == 'Signed') {
      setTargetTab(false);
      setContactTab(false);
      setInterestTab(false);
      setActiveTab(false);
      setVisitTab(false);
      setOfferTab(false);
      setCommitTab(false);
      setSignedTab(true);
      setAlumniTab(false);
    } else if (collageStatusTab == 'Alumni') {
      setTargetTab(false);
      setContactTab(false);
      setInterestTab(false);
      setActiveTab(false);
      setVisitTab(false);
      setOfferTab(false);
      setCommitTab(false);
      setSignedTab(false);
      setAlumniTab(true);
    } else {
      setTargetTab(false);
      setContactTab(false);
      setInterestTab(false);
      setActiveTab(false);
      setVisitTab(false);
      setOfferTab(false);
      setCommitTab(false);
      setSignedTab(false);
      setAlumniTab(false);
    }
  }, [collegeState]);

  const getOffersListData = async () => {
    let collegeDetailsId = window.location.href.split('/');
    const variables = {
      where: {
        userid: getUserInfo() ? getUserInfo().id : null,
        active: 1,
        collegeid: +collegeDetailsId[collegeDetailsId.length - 2],
      },
    };
    const res = await fetchGraphMethod(getAllOfferQuery, variables, true);
    let data = res.data.data.allOffers.Offers;
    setOffers(data);
    if (data.length == 0) {
      return false;
    } else if (data.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleTargetTab = async () => {
    const isCommitment = await getCommitmentData();
    const isOffer = await getOffersListData();

    if (isFav === 0 || isFav === '0') {
      openIntrestModalFromTarget();
      // setTargetTab(false);
      // setContactTab(false);
      // setInterestTab(false);
      // setActiveTab(false);
      // setVisitTab(false);
      // setOfferTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
      // setAlumniTab(false);
      return;
    }

    if (allStatesDisabled() == true) {
      return;
    } else if (isCommitment == true && isOffer == true) {
      if (signedList && signedList.length > 0) {
        setDeleteSignedCommitOffer(true);
      } else {
        setDeleteCommitOffer(true);
        // setTargetTab(true);
        // setContactTab(false);
        // setInterestTab(false);
        // setActiveTab(false);
        // setVisitTab(false);
        // setOfferTab(false);
        // setCommitTab(false);
        // setSignedTab(false);
        // setAlumniTab(false);
      }
    } else if (isOffer == true) {
      setDeleteOfferModal(true);
      // setTargetTab(true);
      // setContactTab(false);
      // setInterestTab(false);
      // setActiveTab(false);
      // setVisitTab(false);
      // setOfferTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
      // setAlumniTab(false);
    } else {
      if (collegeState && collegeState.id !== null) {
        updateUserCollegeStart({
          query: saveUserCollegeQuery,
          variables: {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : null,
              id: collegeState && collegeState.id ? collegeState.id : null,
              target: 1,
              contact: 0,
              interest: 0,
              collegeactive: 0,
              visit: 0,
              offer: 0,
              commit: 0,
              signed: 0,
              alumni: 0,
            },
          },
          getFavoriteCollegesList: getFavoriteCollegesList,
        });
      }
      if (progressId) {
        myProgressSaveStart({
          query: saveProgressQuery,
          variables: {
            obj: {
              id: progressId?.id,
              unofficialvisit: 0,
              officialvisit: 0,
              digitalconversation: 0,
              offcampushcontact: 0,
              phonezoomconversation: 0,
              receivedmessage: 0,
              followedbycollege: 0,
              releaseprofile: 0,
              transcriptrequest: 0,
              videometricsrequest: 0,
              calltextrequest: 0,
              introductionrequest: 0,
              liveevaluation: 0,
              sendmessage: 0,
              receiveview: 0,
            },
          },
          getAllProgressData: getAllProgressData,
        });
      }
    }
  };
  const handleContactTab = async () => {
    let commitdata = await getCommitmentData();
    let offerdata = await getOffersListData();
    if (allStatesDisabled() == true || isFav === 0 || isFav === '0') {
      /* toastr.warning("To progress further, please add college as favorite","",{
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-college-progress",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      }); */
      setUnFavProgressModal(true);
      return;
    } else if (commitdata == true && offerdata == true) {
      if (signedList && signedList.length > 0) {
        setDeleteSignedCommitOffer(true);
      } else {
        setDeleteCommitOffer(true);
        // setContactTab(true);
        // setTargetTab(false);
        // setInterestTab(false);
        // setActiveTab(false);
        // setVisitTab(false);
        // setOfferTab(false);
        // setCommitTab(false);
        // setSignedTab(false);
        // setAlumniTab(false);
      }
    } else if (offerdata == true) {
      setDeleteOfferModal(true);
      // setContactTab(true);
      // setTargetTab(false);
      // setInterestTab(false);
      // setActiveTab(false);
      // setVisitTab(false);
      // setOfferTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
      // setAlumniTab(false);
    } else {
      if (collegeState && collegeState.id !== null) {
        updateUserCollegeStart({
          query: saveUserCollegeQuery,
          variables: {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : null,
              id: collegeState && collegeState.id ? collegeState.id : null,
              target: 1,
              contact: 1,
              interest: 0,
              collegeactive: 0,
              visit: 0,
              offer: 0,
              commit: 0,
              signed: 0,
              alumni: 0,
            },
          },
          getFavoriteCollegesList: getFavoriteCollegesList,
        });
      }
      if (progressId) {
        myProgressSaveStart({
          query: saveProgressQuery,
          variables: {
            obj: {
              id: progressId?.id,
              unofficialvisit: 0,
              officialvisit: 0,
              digitalconversation: 0,
              offcampushcontact: 0,
              phonezoomconversation: 0,
              receivedmessage: 0,
              followedbycollege: 0,
              releaseprofile: 0,
              transcriptrequest: 0,
              videometricsrequest: 0,
              calltextrequest: 0,
              introductionrequest: 0,
              liveevaluation: 0,
            },
          },
          getAllProgressData: getAllProgressData,
        });
      }
      // setContactTab(true);
      // setTargetTab(false);
      // setInterestTab(false);
      // setActiveTab(false);
      // setVisitTab(false);
      // setOfferTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
      // setAlumniTab(false);

      if (!infoModalCheckboxClicked) {
        //Dynamic text when click on progress steps.
        const textInfo = `<p>Congratulations on getting contacted by <strong>'${collegeState?.collegeInfo?.collegename}'</strong></p><p>Your status will be updated on <strong>'My Progress'</strong> and viewed by athlete, coaches and SportsForce team.</p>`;
        setProgressInfoText(textInfo);
        setProgressInfoModal(true);
      }
    }
  };
  const handleInterestTab = async () => {
    const isCommitValue = await getCommitmentData();
    const isOfferValue = await getOffersListData();
    if (allStatesDisabled() == true || isFav === 0 || isFav === '0') {
      /* toastr.warning("To progress further, please add college as favorite","",{
        progressBar : true,timeOut: 5000,
        closeButton: true, positionClass: "toast-top-college-progress",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      }); */
      setUnFavProgressModal(true);
      return;
    } else if (isCommitValue == true && isOfferValue == true) {
      if (signedList && signedList.length > 0) {
        setDeleteSignedCommitOffer(true);
      } else {
        setDeleteCommitOffer(true);
        // setInterestTab(true);
        // setTargetTab(false);
        // setContactTab(false);
        // setActiveTab(false);
        // setVisitTab(false);
        // setOfferTab(false);
        // setCommitTab(false);
        // setSignedTab(false);
        // setAlumniTab(false);
      }
    } else if (isOfferValue == true) {
      setDeleteOfferModal(true);
      // setInterestTab(true);
      // setTargetTab(false);
      // setContactTab(false);
      // setActiveTab(false);
      // setVisitTab(false);
      // setOfferTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
      // setAlumniTab(false);
    } else {
      if (collegeState && collegeState.id !== null) {
        updateUserCollegeStart({
          query: saveUserCollegeQuery,
          variables: {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : null,
              id: collegeState && collegeState.id ? collegeState.id : null,
              target: 1,
              contact: 1,
              interest: 1,
              collegeactive: 0,
              visit: 0,
              offer: 0,
              commit: 0,
              signed: 0,
              alumni: 0,
            },
          },
          getFavoriteCollegesList: getFavoriteCollegesList,
        });
      }
      if (progressId) {
        myProgressSaveStart({
          query: saveProgressQuery,
          variables: {
            obj: {
              id: progressId?.id,
              unofficialvisit: 0,
              officialvisit: 0,
              digitalconversation: 0,
              offcampushcontact: 0,
              phonezoomconversation: 0,
            },
          },
          getAllProgressData: getAllProgressData,
        });
      }
      //setInterestTab(true);
      // setTargetTab(false);
      // setContactTab(false);
      // setActiveTab(false);
      // setVisitTab(false);
      // setOfferTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
      // setAlumniTab(false);

      if (!infoModalCheckboxClicked) {
        //Dynamic text when click on progress steps.
        const textInfo = `<p>Congratulations on showing interest in <strong>'${collegeState?.collegeInfo?.collegename}'</strong></p><p>Your status will be updated on <strong>'My Progress'</strong> and viewed by athlete, coaches and SportsForce team.</p>`;
        setProgressInfoText(textInfo);
        setProgressInfoModal(true);
      }
    }
  };

  const handleActiveTab = async () => {
    const isCollegeCommitValue = await getCommitmentData();
    const isCollegeOfferValue = await getOffersListData();
    if (allStatesDisabled() == true || isFav === 0 || isFav === '0') {
      /* toastr.warning("To progress further, please add college as favorite","",{
        progressBar : true,timeOut: 5000,
        closeButton: true, positionClass: "toast-top-college-progress",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      }); */
      setUnFavProgressModal(true);
      return;
    } else if (isCollegeCommitValue == true && isCollegeOfferValue == true) {
      if (signedList && signedList.length > 0) {
        setDeleteSignedCommitOffer(true);
      } else {
        setDeleteCommitOffer(true);
        // setActiveTab(true);
        // setTargetTab(false);
        // setContactTab(false);
        // setInterestTab(false);
        // setVisitTab(false);
        // setOfferTab(false);
        // setCommitTab(false);
        // setSignedTab(false);
        // setAlumniTab(false);
      }
    } else if (isCollegeOfferValue == true) {
      setDeleteOfferModal(true);
      // setActiveTab(true);
      // setTargetTab(false);
      // setContactTab(false);
      // setInterestTab(false);
      // setVisitTab(false);
      // setOfferTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
      // setAlumniTab(false);
    } else {
      if (collegeState && collegeState.id !== null) {
        updateUserCollegeStart({
          query: saveUserCollegeQuery,
          variables: {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : null,
              id: collegeState && collegeState.id ? collegeState.id : null,
              target: 1,
              contact: 1,
              interest: 1,
              collegeactive: 1,
              visit: 0,
              offer: 0,
              commit: 0,
              signed: 0,
              alumni: 0,
            },
          },
          getFavoriteCollegesList: getFavoriteCollegesList,
        });
      }
      if (progressId) {
        myProgressSaveStart({
          query: saveProgressQuery,
          variables: {
            obj: {
              id: progressId?.id,
              unofficialvisit: 0,
              officialvisit: 0,
            },
          },
          getAllProgressData: getAllProgressData,
        });
      }
      // setActiveTab(true);
      // setTargetTab(false);
      // setContactTab(false);
      // setInterestTab(false);
      // setVisitTab(false);
      // setOfferTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
      // setAlumniTab(false);

      if (!infoModalCheckboxClicked) {
        //Dynamic text when click on progress steps.
        const textInfo = `<p>Congratulations on forwarding one step closer to your favorite <strong>'${collegeState?.collegeInfo?.collegename}'</strong></p><p>Your status will be updated on <strong>'My Progress'</strong> and viewed by athlete, coaches and SportsForce team.</p>`;
        setProgressInfoText(textInfo);
        setProgressInfoModal(true);
      }
    }
  };

  const handleVisitTab = async () => {
    const getCommitStaus = await getCommitmentData();
    const getOfferStatus = await getOffersListData();
    if (allStatesDisabled() == true || isFav === 0 || isFav === '0') {
      /* toastr.warning("To progress further, please add college as favorite","",{
        progressBar : true,timeOut: 5000,
        closeButton: true, positionClass: "toast-top-college-progress",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      }); */
      setUnFavProgressModal(true);
      return;
    } else if (getOfferStatus == true && getCommitStaus == true) {
      if (signedList && signedList.length > 0) {
        setDeleteSignedCommitOffer(true);
      } else {
        setDeleteCommitOffer(true);
        // setVisitTab(true);
        // setTargetTab(false);
        // setContactTab(false);
        // setInterestTab(false);
        // setActiveTab(false);
        // setOfferTab(false);
        // setCommitTab(false);
        // setSignedTab(false);
        // setAlumniTab(false);
      }
    } else if (getOfferStatus == true) {
      setDeleteOfferModal(true);
      // setVisitTab(true);
      // setTargetTab(false);
      // setContactTab(false);
      // setInterestTab(false);
      // setActiveTab(false);
      // setOfferTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
      // setAlumniTab(false);
    } else {
      if (collegeState && collegeState.id !== null) {
        updateUserCollegeStart({
          query: saveUserCollegeQuery,
          variables: {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : null,
              id: collegeState && collegeState.id ? collegeState.id : null,
              target: 1,
              contact: 1,
              interest: 1,
              collegeactive: 1,
              visit: 1,
              offer: 0,
              commit: 0,
              signed: 0,
              alumni: 0,
            },
          },
          getFavoriteCollegesList: getFavoriteCollegesList,
        });
      }
      // setVisitTab(true);
      // setTargetTab(false);
      // setContactTab(false);
      // setInterestTab(false);
      // setActiveTab(false);
      // setOfferTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
      // setAlumniTab(false);

      if (!infoModalCheckboxClicked) {
        //Dynamic text when click on progress steps.
        const textInfo = `<p>Congratulations on visitng <strong>'${collegeState?.collegeInfo?.collegename}'</strong></p><p>Your status will be updated on <strong>'My Progress'</strong> and viewed by athlete, coaches and SportsForce team.</p>`;
        setProgressInfoText(textInfo);
        setProgressInfoModal(true);
      }
    }
  };
  const closeDeleteCommitment = () => {
    setDeleteCommitment(false);
  };
  const closeDeleteSignedAndCommitment = () => {
    setdeleteSignedAndCommitment(false);
  };
  const closeDeleteSignedOfferCommitmentModal = () => {
    setDeleteSignedCommitOffer(false);
  };
  const closeDecommitSignedFromOtherCollege = () => {
    setDecommitSignedFromOtherCollege(false);
  };
  const closeDeleteSignedModal = () => {
    setDeleteSigned(false);
  };
  const closeDecommitEarlierCollege = () => {
    setDecommitEarlierCollege(false);
  };
  const closeDecommitEarlierSigned = () => {
    setDecommitEarlierSigned(false);
  };
  const closeDeleteOfferModal = () => {
    setDeleteOfferModal(false);
  };
  const closeDeleteOfferCommitmentModal = () => {
    setDeleteCommitOffer(false);
  };
  const deleteNextScreen = () => {
    setDeleteCommitment(false);
    getCommitmentData();
    // messagePopup("", "Commitment deleted successfully", "success");
    toastr.success('Commitment deleted successfully', '', {
      progressBar: true,
      timeOut: 2000,
      closeButton: true,
      positionClass: 'toast-top-full-width',
      showEasing: 'swing',
      fadeIn: 40000,
      preventDuplicates: true,
    });
    let collegeRowId =
      allUserColleges &&
      allUserColleges.filter((colleges) => {
        return colleges.collegeid == collegeid;
      });
    const variables = {
      obj: {
        userid: getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
        id:
          collegeRowId && collegeRowId[0] && collegeRowId[0].id
            ? collegeRowId[0].id
            : null,
        target: 1,
        contact: 1,
        interest: 1,
        collegeactive: 1,
        visit: 1,
        offer: 1,
        commit: 0,
        signed: 0,
      },
    };
    if (collegeid) {
      updateUserCollegeStart({
        query: saveUserCollegeQuery,
        variables,
        getFavoriteCollegesList: getFavoriteCollegesList,
      });
    }
  };
  const handelDeleteCommitment = () => {
    addMyCommitmentStart({
      query: saveMyCommitmentQuery,
      variables: {
        obj: {
          id:
            isAddedInCommitment && isAddedInCommitment.id
              ? isAddedInCommitment.id
              : null,
          active: 0,
        },
      },
      nextScreen: deleteNextScreen,
    });

    myProgressSaveStart({
      query: saveProgressQuery,
      variables: {
        obj: {
          id: progressId?.id,
          commitmentid: null,
          signcommitmenttopalybaseball: 0,
        },
      },
      getAllProgressData: getAllProgressData,
    });
  };
  const deleteProgressData = () => {
    setDeleteSignedCommitOffer(false);
    getCommitmentData();
    getOffersListData();
    messagePopup(
      '',
      'Offer , Commitment And Signed deleted successfully.',
      'success'
    );
    updateUserCollegeStart({
      query: saveUserCollegeQuery,
      variables:
        isFav == 1
          ? {
              obj: {
                userid: getUserInfo() ? getUserInfo().id : null,
                id: collegeState && collegeState.id ? collegeState.id : null,
                target: 1,
                contact: 0,
                interest: 0,
                collegeactive: 0,
                visit: 0,
                offer: 0,
                commit: 0,
                signed: 0,
                alumni: 0,
              },
            }
          : {
              obj: {
                userid: getUserInfo() ? getUserInfo().id : null,
                id: collegeState && collegeState.id ? collegeState.id : null,
                target: 0,
                contact: 0,
                interest: 0,
                collegeactive: 0,
                visit: 0,
                offer: 0,
                commit: 0,
                signed: 0,
                alumni: 0,
              },
            },
      getFavoriteCollegesList: getFavoriteCollegesList,
    });
    sendRemoveOfferNotification(
      collegeState && collegeState?.collegeInfo
        ? collegeState?.collegeInfo?.collegename
        : ''
    );
    sendRemoveCommitmentNotification(
      collegeState && collegeState?.collegeInfo
        ? collegeState?.collegeInfo?.collegename
        : ''
    );
    sendRemoveSignedCommitmentNotification(
      collegeState && collegeState?.collegeInfo
        ? collegeState?.collegeInfo?.collegename
        : ''
    );
    setContactTab(false);
    setInterestTab(false);
    setActiveTab(false);
    setVisitTab(false);
    setCommitTab(false);
    setSignedTab(false);
    setAlumniTab(false);
  };

  const deleteNextData = () => {
    setdeleteSignedAndCommitment(false);
    messagePopup('', 'Signed And Commitment deleted successfully', 'success');
    updateUserCollegeStart({
      query: saveUserCollegeQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : null,
          id: collegeState && collegeState.id ? collegeState.id : null,
          target: 1,
          contact: 1,
          interest: 1,
          collegeactive: 1,
          visit: 1,
          offer: 1,
          commit: 0,
          signed: 0,
          alumni: 0,
        },
      },
      getFavoriteCollegesList: getFavoriteCollegesList,
    });
    // setOfferTab(true);
    // setTargetTab(false);
    // setContactTab(false);
    // setInterestTab(false);
    // setActiveTab(false);
    // setVisitTab(false);
    // setCommitTab(false);
    // setSignedTab(false);
    // setAlumniTab(false);
  };

  const handelDeleteSignedAndCommitment = async () => {
    await deleteSignedCommitment(signedList[0].id);
    await getSignedCommitmentList();
    addMyCommitmentStart({
      query: saveMyCommitmentQuery,
      variables: {
        obj: {
          id:
            isAddedInCommitment && isAddedInCommitment.id
              ? isAddedInCommitment.id
              : null,
          active: 0,
        },
      },
      nextScreen: deleteNextData,
    });
    myProgressSaveStart({
      query: saveProgressQuery,
      variables: {
        obj: {
          id: progressId?.id,
          commitmentid: null,
          signcommitmenttopalybaseball: 0,
        },
      },
      getAllProgressData: getAllProgressData,
    });
  };

  const handleDecommitSignedFromOtherCollege = async () => {
    await getAllFavColleges();
    addMyCommitmentStart({
      query: saveMyCommitmentQuery,
      variables: {
        obj: {
          id: commitmentList && commitmentList[0] && commitmentList[0].id,
          active: 0,
        },
      },
      nextScreen: deleteCommitScreen,
      collegeId: collegeState && collegeState.collegeInfo,
    });
    await deleteSignedCommitment(signedListByUser[0].id);
    messagePopup('', 'Signed And Commitment Deleted Successfully.', 'success');
    history.push({
      pathname: '/viewCommitment',
      state: {
        id: collegeData && collegeData.id ? collegeData.id : null,
        collegename:
          collegeData && collegeData.collegename ? collegeData.collegename : '',
        isSigned: true,
      },
    });
  };

  const handleDecommitEarlierSigned = async () => {
    await deleteSignedCommitment(signedList[0].id);
    await getSignedCommitmentList();
    setDecommitEarlierSigned(false);
    updateUserCollegeStart({
      query: saveUserCollegeQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : null,
          id: collegeState && collegeState.id ? collegeState.id : null,
          target: 1,
          contact: 1,
          interest: 1,
          collegeactive: 1,
          visit: 1,
          offer: 1,
          commit: 1,
          signed: 0,
          alumni: 0,
        },
      },
    });
    history.push({
      pathname: '/viewCommitment',
      state: {
        id: collegeData && collegeData.id ? collegeData.id : null,
        collegename:
          collegeData && collegeData.collegename ? collegeData.collegename : '',
        isSigned: true,
      },
    });
    messagePopup('', 'Signed commitment deleted successfully.', 'success');
  };

  const handleDecommitEarlierCollege = async () => {
    sendRemoveCommitmentNotification(
      collegeData && collegeData.collegename ? collegeData.collegename : ''
    );
    sendRemoveSignedCommitmentNotification(
      collegeData && collegeData.collegename ? collegeData.collegename : ''
    );
    await getAllFavColleges();
    addMyCommitmentStart({
      query: saveMyCommitmentQuery,
      variables: {
        obj: {
          id: commitmentList && commitmentList[0] && commitmentList[0].id,
          active: 0,
        },
      },
      nextScreen: deleteCommitScreen,
      collegeId: collegeState && collegeState.collegeInfo,
    });
    if (signedListByUser && signedListByUser.length > 0) {
      await deleteSignedCommitment(signedListByUser[0].id);
      messagePopup(
        '',
        'Signed And Commitment Deleted Successfully.',
        'success'
      );
    } else {
      messagePopup('', 'Commitment Deleted Successfully.', 'success');
    }
    history.push({
      pathname: '/viewCommitment',
      state: {
        id: collegeData && collegeData.id ? collegeData.id : null,
        collegename:
          collegeData && collegeData.collegename ? collegeData.collegename : '',
        isSigned: false,
      },
    });
  };

  const deleteSignedCommit = async () => {
    await deleteSignedCommitment(signedList[0].id);
    await getSignedCommitmentList();
    await getSignedListByUser();
    setDeleteSigned(false);
    messagePopup('', 'Signed commitment deleted successfully.', 'success');
    updateUserCollegeStart({
      query: saveUserCollegeQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : null,
          id: collegeState && collegeState.id ? collegeState.id : null,
          target: 1,
          contact: 1,
          interest: 1,
          collegeactive: 1,
          visit: 1,
          offer: 1,
          commit: 1,
          signed: 0,
          alumni: 0,
        },
      },
      getFavoriteCollegesList: getFavoriteCollegesList,
    });
    // setCommitTab(true);
    // setTargetTab(false);
    // setContactTab(false);
    // setInterestTab(false);
    // setActiveTab(false);
    // setVisitTab(false);
    // setOfferTab(false);
    // setSignedTab(false);
    // setAlumniTab(false);
  };

  const deleteSignedCommitmentOffer = async () => {
    await deleteSignedCommitment(signedList[0].id);
    await getSignedCommitmentList();
    offersListsaveStart({
      query: saveOffersQuery,
      variables: {
        obj: {
          id: isAddedInOffer && isAddedInOffer.id ? isAddedInOffer.id : null,
          active: 0,
        },
      },
      noSuccessMsg: 1,
    });
    addMyCommitmentStart({
      query: saveMyCommitmentQuery,
      variables: {
        obj: {
          id:
            isAddedInCommitment && isAddedInCommitment.id
              ? isAddedInCommitment.id
              : null,
          active: 0,
        },
      },
      nextScreen: deleteProgressData,
    });
    await removeAllSelectionFromProgress();
    await getCommitmentData();
    await getOffersListData();
    await getAllProgressData();
    await getOffersTypeData();
    await getCommitmentStatusData();
    await getAllFavColleges();
  };

  const closeModal = async () => {
    setDeleteOfferModal(false);
    setContactTab(false);
    setInterestTab(false);
    setActiveTab(false);
    setVisitTab(false);
    setOfferTab(false);
    setCommitTab(false);
    setSignedTab(false);
    setAlumniTab(false);
    await getCommitmentData();
    await getOffersListData();
    await getAllProgressData();
    await getOffersTypeData();
    await getCommitmentStatusData();
    await getAllFavColleges();
    let collegeRowId =
      allUserColleges &&
      allUserColleges.filter((colleges) => {
        return colleges.collegeid == collegeid;
      });

    updateUserCollegeStart({
      query: saveUserCollegeQuery,
      variables: isFav
        ? {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : null,
              id:
                collegeRowId && collegeRowId[0] && collegeRowId[0].id
                  ? collegeRowId[0].id
                  : null,
              target: 1,
              contact: 0,
              interest: 0,
              collegeactive: 0,
              visit: 0,
              offer: 0,
              commit: 0,
              signed: 0,
            },
          }
        : {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : null,
              id: collegeid,
              target: 0,
              contact: 0,
              interest: 0,
              collegeactive: 0,
              visit: 0,
              offer: 0,
              commit: 0,
              signed: 0,
            },
          },
      getFavoriteCollegesList: getFavoriteCollegesList,
    });
  };
  const handleDeleteOffer = () => {
    offersListsaveStart({
      query: saveOffersQuery,
      variables: {
        obj: {
          id: isAddedInOffer && isAddedInOffer.id ? isAddedInOffer.id : null,
          active: 0,
        },
      },
      closeOfferModal: closeModal,
    });
    // myProgressSaveStart({
    //   query : saveProgressQuery,
    //   variables : {
    //     obj : {
    //       id : progressId?.id,
    //       offertypeid: null,
    //     }
    //   },
    //   getAllProgressData : getAllProgressData
    // })
    sendRemoveOfferNotification(
      collegeData && collegeData.collegename ? collegeData.collegename : ''
    );
    removeAllSelectionFromProgress();
  };

  const successMsg = () => {
    setDeleteCommitOffer(false);
    getCommitmentData();
    getOffersListData();
    // messagePopup("", "Offer and Commitment deleted successfully.", "success");
    toastr.success('Offer and Commitment deleted successfully.', '', {
      progressBar: true,
      timeOut: 2000,
      closeButton: true,
      positionClass: 'toast-top-full-width',
      showEasing: 'swing',
      fadeIn: 40000,
      preventDuplicates: true,
    });
    updateUserCollegeStart({
      query: saveUserCollegeQuery,
      variables:
        isFav == 1
          ? {
              obj: {
                userid: getUserInfo() ? getUserInfo().id : null,
                id: collegeState && collegeState.id ? collegeState.id : null,
                target: 1,
                contact: 0,
                interest: 0,
                collegeactive: 0,
                visit: 0,
                offer: 0,
                commit: 0,
                signed: 0,
                alumni: 0,
              },
            }
          : {
              obj: {
                userid: getUserInfo() ? getUserInfo().id : null,
                id: collegeState && collegeState.id ? collegeState.id : null,
                target: 0,
                contact: 0,
                interest: 0,
                collegeactive: 0,
                visit: 0,
                offer: 0,
                commit: 0,
                signed: 0,
                alumni: 0,
              },
            },
      getFavoriteCollegesList: getFavoriteCollegesList,
    });
    setContactTab(false);
    setInterestTab(false);
    setActiveTab(false);
    setVisitTab(false);
    setCommitTab(false);
    setSignedTab(false);
    setAlumniTab(false);
  };
  const removeAllSelectionFromProgress = () => {
    myProgressSaveStart({
      query: saveProgressQuery,
      variables: {
        obj: {
          id: progressId?.id,
          signcommitmenttopalybaseball: 0,
          commitmentid: null,
          offertypeid: null,
          unofficialvisit: 0,
          officialvisit: 0,
          digitalconversation: 0,
          offcampushcontact: 0,
          phonezoomconversation: 0,
          receivedmessage: 0,
          followedbycollege: 0,
          releaseprofile: 0,
          transcriptrequest: 0,
          videometricsrequest: 0,
          calltextrequest: 0,
          introductionrequest: 0,
          liveevaluation: 0,
          sendmessage: 0,
          receiveview: 0,
          collegepreference: 0,
          matchanalysisconducted: 0,
          researchcollege: 0,
          followingfavaritecollege: 0,
          categorized: 0,
        },
      },
      getAllProgressData: getAllProgressData,
    });
  };
  const handleDeleteOfferCommitment = () => {
    offersListsaveStart({
      query: saveOffersQuery,
      variables: {
        obj: {
          id: isAddedInOffer && isAddedInOffer.id ? isAddedInOffer.id : null,
          active: 0,
        },
      },
      noSuccessMsg: 1,
    });
    addMyCommitmentStart({
      query: saveMyCommitmentQuery,
      variables: {
        obj: {
          id:
            isAddedInCommitment && isAddedInCommitment.id
              ? isAddedInCommitment.id
              : null,
          active: 0,
        },
      },
    });
  };

  const deleteCommitmentOffer = async () => {
    const deleteOfferCommit = await handleDeleteOfferCommitment();
    sendRemoveCommitmentNotification(
      collegeData && collegeData.collegename ? collegeData.collegename : ''
    );
    sendRemoveOfferNotification(
      collegeData && collegeData.collegename ? collegeData.collegename : ''
    );
    await successMsg();
    await removeAllSelectionFromProgress();
    await getCommitmentData();
    await getOffersListData();
    await getAllProgressData();
    await getOffersTypeData();
    await getCommitmentStatusData();
    await getAllFavColleges();
  };
  const handleOfferTab = async () => {
    await getCommitmentData();
    await getOffersListData();
    if (allStatesDisabled() == true || isFav === 0 || isFav === '0') {
      /* toastr.warning("To progress further, please add college as favorite","",{
        progressBar : true,timeOut: 5000,
        closeButton: true, positionClass: "toast-top-college-progress",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      }); */
      setUnFavProgressModal(true);
      return;
    } else if (isAddedInCommitment) {
      if (signedList && signedList.length > 0) {
        setdeleteSignedAndCommitment(true);
      } else {
        setDeleteCommitment(true);
        // setOfferTab(true);
        // setTargetTab(false);
        // setContactTab(false);
        // setInterestTab(false);
        // setActiveTab(false);
        // setVisitTab(false);
        // setCommitTab(false);
        // setSignedTab(false);
        // setAlumniTab(false);
      }
    } else {
      setOfferModal(true);
      // setOfferTab(true);
      // setTargetTab(false);
      // setContactTab(false);
      // setInterestTab(false);
      // setActiveTab(false);
      // setVisitTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
      // setAlumniTab(false);
    }
  };

  const handleCommitTab = async () => {
    let allowToEditData = window.location.href.split('/');
    if (allStatesDisabled() == true || isFav === 0 || isFav === '0') {
      /* toastr.warning("To progress further, please add college as favorite","",{
        progressBar : true,timeOut: 5000,
        closeButton: true, positionClass: "toast-top-college-progress",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      }); */
      setUnFavProgressModal(true);
      return;
    }
    //If the signed commitment exist then decomit it.
    if (signedList && signedList.length > 0) {
      setDeleteSigned(true);
      return;
    }
    if (
      collegeState.commit == 1 ||
      allowToEditData[allowToEditData.length - 1] == 1
    ) {
      await getCommitmentData();
      await getOffersListData();
      if (allStatesDisabled() == true) {
        return;
      } else if (collegeState && collegeState.signed == 1) {
        if (commitmentList && commitmentList.length !== 0) {
          const collegeStatus = allFavColleges
            ? allFavColleges.find((college) => {
                return college.collegeid == commitmentList[0].collegeid;
              })
            : null;
          setDecommitEarlierCollege(true);
        } else {
          // setCommitModal(true);
          // setCommitTab(true);
          // setTargetTab(false);
          // setContactTab(false);
          // setInterestTab(false);
          // setActiveTab(false);
          // setVisitTab(false);
          // setOfferTab(false);
          // setSignedTab(false);
          // setAlumniTab(false);
          updateUserCollegeStart({
            query: saveUserCollegeQuery,
            variables: {
              obj: {
                userid: getUserInfo() ? getUserInfo().id : null,
                id: collegeState && collegeState.id ? collegeState.id : null,
                target: 1,
                contact: 1,
                interest: 1,
                collegeactive: 1,
                visit: 1,
                offer: 1,
                commit: 1,
                signed: 0,
                alumni: 0,
              },
            },
          });
          myProgressSaveStart({
            query: saveProgressQuery,
            variables: {
              obj: {
                id: progressId?.id,
                signcommitmenttopalybaseball: null,
              },
            },
            getAllProgressData: getAllProgressData,
          });
        }
      } else if (commitmentList && commitmentList.length !== 0) {
        const collegeStatus = allFavColleges
          ? allFavColleges.find((college) => {
              return college.collegeid == commitmentList[0].collegeid;
            })
          : null;
        setDecommitEarlierCollege(true);
      } else {
        setCommitModal(true);
        // setCommitTab(true);
        // setTargetTab(false);
        // setContactTab(false);
        // setInterestTab(false);
        // setActiveTab(false);
        // setVisitTab(false);
        // setOfferTab(false);
        // setSignedTab(false);
        // setAlumniTab(false);
      }
    }
  };

  const deleteCommitScreen = async () => {
    setDecommitEarlierCollege(false);
    setDecommitSignedFromOtherCollege(false);
    let collegeDetailsId = window.location.href.split('/');
    const updateCollegeDetaisl = await updateFavCollege();
    /* history.push({
      pathname: "/viewCommitment",
      state: {
        id: collegedata && collegedata.id ? collegedata.id : (collegeDetailsId != "" ? collegeDetailsId[collegeDetailsId.length - 2] : null),
        collegename: collegedata && collegedata.collegename ? collegedata.collegename : ""
      }
    }); */
    await getSignedListByUser();
  };
  const allCollegeDetails = async () => {
    await getCommitmentData();
    await getOffersListData();
    await getAllProgressData();
    await getOffersTypeData();
    await getCommitmentStatusData();
    await getAllFavColleges();
  };
  const updateSignedTab = async () => {
    updateUserCollegeStart({
      query: saveUserCollegeQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : null,
          id: collegeState && collegeState.id ? collegeState.id : null,
          target: 1,
          contact: 1,
          interest: 1,
          collegeactive: 1,
          visit: 1,
          offer: 1,
          commit: 1,
          signed: 1,
          alumni: 0,
        },
      },
      getFavoriteCollegesList: getFavoriteCollegesList,
    });
    allCollegeDetails();
    getFavoriteCollegesList();
  };
  const addCollegeToCommitment = () => {
    addMyCommitmentStart({
      query: saveMyCommitmentQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : null,
          commitment:
            progressId && progressId.commitmentid
              ? progressId.commitmentid
              : null,
          collegeid: +collegeid,
          collegename:
            collegeData && collegeData.collegename
              ? collegeData.collegename
              : '',
          commitmentdate: currentDate,
        },
      },
    });
  };
  const addOfferIfNotAddedBeforeSigned = () => {
    offersListsaveStart({
      query: saveOffersQuery,
      variables: {
        obj: {
          userid: getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
          collegeid: +collegeid,
          offerdate: currentDate,
          interestlevelid:
            collegeState && collegeState.collegeinterestlevel
              ? collegeState.collegeinterestlevel
              : '',
          offerid:
            progressId && progressId.offertypeid
              ? progressId.offertypeid
              : null,
        },
      },
    });
  };
  const handleSignedTab = async () => {
    //let selectedCollegeId = window.location.href.split('/')
    await getSignedCommitmentList();
    await getSignedListByUser();
    if (allStatesDisabled() == true || isFav === 0 || isFav === '0') {
      /* toastr.warning("To progress further, please add college as favorite","",{
        progressBar : true,timeOut: 5000,
        closeButton: true, positionClass: "toast-top-college-progress",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      }); */
      setUnFavProgressModal(true);
      return;
    }
    if (signedList && signedList.length > 0) {
      setDecommitEarlierSigned(true);
    } else if (
      signedListByUser &&
      signedListByUser.length > 0 &&
      commitmentList &&
      commitmentList.length !== 0
    ) {
      setDecommitSignedFromOtherCollege(true);
    } else {
      setSignedModal(true);
    }
    /* if (collegeState.signed == 1 || selectedCollegeId[selectedCollegeId.length - 1] == 1) {
      const anyCollegeCommit = await getCommitmentData()
      await getOffersListData()
      if (allStatesDisabled() == true) {
        return;
      } else if (collegeState && collegeState.id !== null && collegeState.offer == 1 && collegeState.commit == 1) {
        updateSignedTab();
      } else if (collegeState && collegeState.commit == 0 && commitmentList?.length !== 0, anyCollegeCommit == true) {
        // messagePopup("", "You are already committed with a college , Please decommit that commitment if you want to add commitment of this college.", "warning");
        toastr.warning("You are already committed with a college , Please decommit that commitment if you want to add commitment of this college.", "", {
          progressBar : true,timeOut: 5000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates:true,
        })
        
      }
      else if (!isAddedInOffer && !isAddedInCommitment) {
        await updateSignedTab();
        await addOfferIfNotAddedBeforeSigned();
        await addCollegeToCommitment();
        getCommitmentData();
        getOffersListData();
      } else if (isAddedInOffer && !isAddedInCommitment) {
        addCollegeToCommitment();
        updateSignedTab();
      }
    } */
  };
  const handleAlumniTab = async () => {
    await getCommitmentData();
    await getOffersListData();
    if (allStatesDisabled() == true) {
      return;
    } else {
      // setAlumniTab(true);
      // setTargetTab(false);
      // setContactTab(false);
      // setInterestTab(false);
      // setActiveTab(false);
      // setVisitTab(false);
      // setOfferTab(false);
      // setCommitTab(false);
      // setSignedTab(false);
    }
  };
  const closeOfferModal = () => {
    setOfferModal(false);
  };
  const closeProgressInfoModal = () => {
    setProgressInfoModal(false);
  };
  const closeCommitModal = () => {
    setCommitModal(false);
  };

  const closeSignedCommitModal = () => {
    setSignedModal(false);
  };

  const closeUnFavProgressModal = () => {
    setUnFavProgressModal(false);
  };

  const getAllProgressData = async () => {
    myProgressListStart({
      query: getAllProgresses,
      variables: {
        where: {
          userid: getUserInfo() ? getUserInfo().id : null,
          collegeid: collegeid,
        },
      },
    });
  };
  const handleOpenVideo = () => {
    if (allMemberships && allMemberships.length == 0) {
      setShowVideoPopup(true);
    } else {
      setShowVideoPopup(false);
    }
  };
  const handleCloseVideo = () => {
    setShowVideoPopup(false);
  };
  const handleCommitTabChange = (e, commitTypeId) => {
    let fieldsT = fields;
    if (e.target.checked == false) {
      fieldsT['commitTypeValue'] = null;
    } else {
      fieldsT['commitTypeValue'] = commitTypeId;
    }
    setFields({ ...fieldsT });

    myProgressSaveStart({
      query: saveProgressQuery,
      variables:
        progressId && progressId.id
          ? {
              obj: {
                id: progressId.id,
                userid: getUserInfo() ? getUserInfo().id : null,
                collegeid: +collegeid,
                commitmentid: fields.commitTypeValue,
              },
            }
          : {
              obj: {
                userid: getUserInfo() ? getUserInfo().id : null,
                collegeid: +collegeid,
                commitmentid: fields.commitTypeValue,
              },
            },
      getAllProgressData: updateCommitData,
    });
  };
  const updateCommitData = () => {
    if (isAddedInCommitment) {
      addMyCommitmentStart({
        query: saveMyCommitmentQuery,
        variables: {
          obj: {
            id: isAddedInCommitment?.id,
            commitment: fields.commitTypeValue,
          },
        },
      });
    }
    getAllProgressData();
  };
  const handleOfferTabChange = (e, offerTypeId) => {
    let fieldsT = fields;
    if (e.target.checked == false) {
      fieldsT['selectedValue'] = null;
    } else {
      fieldsT['selectedValue'] = offerTypeId;
    }
    setFields({ ...fieldsT });
    myProgressSaveStart({
      query: saveProgressQuery,
      variables:
        progressId && progressId.id
          ? {
              obj: {
                id: progressId.id,
                userid: getUserInfo() ? getUserInfo().id : null,
                collegeid: +collegeid,
                offertypeid: fields.selectedValue,
              },
            }
          : {
              obj: {
                userid: getUserInfo() ? getUserInfo().id : null,
                collegeid: +collegeid,
                offertypeid: fields.selectedValue,
              },
            },
      getAllProgressData: updateOfferData,
    });
  };
  const updateOfferData = () => {
    if (isAddedInOffer) {
      offersListsaveStart({
        query: saveOffersQuery,
        variables: {
          obj: {
            id: isAddedInOffer?.id,
            offerid: fields.selectedValue,
          },
        },
      });
    }
    getAllProgressData();
  };
  const allowedToSentMsg = () => {
    /* let collegeId = window.location.href.split("/")[6];
    if (collegeData.allowtosendmsg == 1) {
      history.push(`/message/coach/composeMessage/0/${collegeId}`)
    } */

    warningMessagePopup(
      '',
      'The message center is coming soon. You are being redirected to the recruiting tab to contact college coaches directly',
      'warning'
    ).then(async (res) => {
      if (res) {
        window.location.reload(false);
      }
    });
  };
  const handleChange = (e, field) => {
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.checked : e) : e;

    //If official check box is checked then un check the unofficaial one and vice versa.
    if (e && e?.target) {
      if (field === 'unOfficialVisit' && e.target.checked) {
        fieldsT['officialVisit'] = false;
      } else if (field === 'officialVisit' && e.target.checked) {
        fieldsT['unOfficialVisit'] = false;
      }
    }

    setFields({
      ...fieldsT,
    });
    myProgressSaveStart({
      query: saveProgressQuery,
      variables:
        progressId && progressId.id
          ? {
              obj: {
                id: progressId.id,
                userid: getUserInfo() ? getUserInfo().id : null,
                collegeid: +collegeid,
                collegepreference: fields.collegepreference == true ? 1 : 0,
                categorized: fields.categorized == true ? 1 : 0,
                researchcollege: fields.researchSchool == true ? 1 : 0,
                matchanalysisconducted: fields.matchAnalysis == true ? 1 : 0,
                receiveview: fields.recieveView == true ? 1 : 0,
                sendmessage: fields.sentMessage == true ? 1 : 0,
                receivedmessage: fields.recievedMessage == true ? 1 : 0,
                videometricsrequest: fields.videoMetricsRequest == true ? 1 : 0,
                transcriptrequest: fields.transcriptRequest == true ? 1 : 0,
                releaseprofile: fields.releaseProfile == true ? 1 : 0,
                calltextrequest: fields.callTextRequest == true ? 1 : 0,
                introductionrequest: fields.introductionRequest == true ? 1 : 0,
                liveevaluation: fields.liveEvaluation == true ? 1 : 0,
                digitalconversation: fields.digitalConversation == true ? 1 : 0,
                phonezoomconversation: fields.phoneConversation == true ? 1 : 0,
                offcampushcontact: fields.offCampusContact == true ? 1 : 0,
                unofficialvisit: fields.unOfficialVisit == true ? 1 : 0,
                officialvisit: fields.officialVisit == true ? 1 : 0,
                lookatflsrlanguage: fields.lookAtFlSrLanguage ? 1 : 0,
                offertypeid: fields.selectedValue,
                signcommitmenttopalybaseball:
                  fields.signcommitmenttopalybaseball ? 1 : 0,
                alumniloremipsum: fields.alumni ? 1 : 0,
              },
            }
          : {
              obj: {
                userid: getUserInfo() ? getUserInfo().id : null,
                collegeid: +collegeid,
                collegepreference: fields.collegepreference == true ? 1 : 0,
                categorized: fields.categorized == true ? 1 : 0,
                researchcollege: fields.researchSchool == true ? 1 : 0,
                matchanalysisconducted: fields.matchAnalysis == true ? 1 : 0,
                receiveview: fields.recieveView == true ? 1 : 0,
                sendmessage: fields.sentMessage == true ? 1 : 0,
                receivedmessage: fields.recievedMessage == true ? 1 : 0,
                videometricsrequest: fields.videoMetricsRequest == true ? 1 : 0,
                transcriptrequest: fields.transcriptRequest == true ? 1 : 0,
                releaseprofile: fields.releaseProfile == true ? 1 : 0,
                calltextrequest: fields.callTextRequest == true ? 1 : 0,
                introductionrequest: fields.introductionRequest == true ? 1 : 0,
                liveevaluation: fields.liveEvaluation == true ? 1 : 0,
                digitalconversation: fields.digitalConversation == true ? 1 : 0,
                phonezoomconversation: fields.phoneConversation == true ? 1 : 0,
                offcampushcontact: fields.offCampusContact == true ? 1 : 0,
                unofficialvisit: fields.unOfficialVisit == true ? 1 : 0,
                officialvisit: fields.officialVisit == true ? 1 : 0,
                lookatflsrlanguage: fields.lookAtFlSrLanguage == true ? 1 : 0,
                offertypeid: fields.selectedValue,
                signcommitmenttopalybaseball:
                  fields.signcommitmenttopalybaseball ? 1 : 0,
                alumniloremipsum: fields.alumni ? 1 : 0,
              },
            },
      getAllProgressData: getAllProgressData,
    });
  };

  const handleCheckProgress = async () => {
    let allPopupInfo = {
      obj: {
        userid: getUserInfo() ? getUserInfo().id : null,
        collegeid: +collegeid,
        status: 1,
      },
    };
    const respon = await fetchGraphMethod(
      saveCollegepopupQuery,
      allPopupInfo,
      true
    );
    if (respon?.status == 200) {
      setProgressInfoModal(false);
      setInfoModalCheckboxClicked(true);
    }
  };

  return (
    <div>
      <ul className="tabs stepperBox">
        <li
          className={`item ${
            collegeState && (collegeState.active || collegeState.target == 1)
              ? 'passed current'
              : ''
          }`}
          data-tab="Target"
        >
          <span className="circle" onClick={() => handleTargetTab()}></span>
          <span>Target</span>
        </li>
        <li
          className={`item ${
            collegeState && collegeState.contact == 1 ? 'passed current' : ''
          }`}
          data-tab="Contact"
        >
          <span className="circle" onClick={() => handleContactTab()}></span>
          <span>Contact</span>
        </li>
        <li
          className={`item ${
            collegeState && collegeState.interest == 1 ? 'passed current' : ''
          }`}
          data-tab="Interest"
        >
          <span className="circle" onClick={() => handleInterestTab()}></span>
          <span>Interest</span>
        </li>
        <li
          className={`item ${
            collegeState && collegeState.collegeactive == 1
              ? 'passed current'
              : ''
          }`}
          data-tab="Active"
        >
          <span className="circle" onClick={() => handleActiveTab()}></span>
          <span>Active</span>
        </li>
        <li
          className={`item ${
            collegeState && collegeState.visit == 1 ? 'passed current' : ''
          }`}
          data-tab="Visit"
        >
          <span className="circle" onClick={() => handleVisitTab()}></span>
          <span>Visit</span>
        </li>
        <li
          className={`item ${
            collegeState && collegeState.offer ? 'passed current' : ''
          }`}
          data-tab="Offer"
        >
          <span className="circle" onClick={() => handleOfferTab()}></span>
          <span>Offer</span>
        </li>

        <li
          className={`item ${
            collegeState && collegeState.commit ? 'passed current' : ''
          }`}
          data-tab="Commit"
        >
          <span className="circle" onClick={() => handleCommitTab()}></span>
          <span>Commit</span>
        </li>
        <li
          className={`item ${
            collegeState && collegeState.signed == 1 ? 'passed current' : ''
          }`}
          data-tab="Signed"
        >
          <span className="circle" onClick={() => handleSignedTab()}></span>
          <span>Signed</span>
        </li>
        {/* <li className={`item ${collegeState && collegeState.alumni == 1 ? "passed current" : ""}`} data-tab="Alumni">
          <span className="circle" onClick={() => handleAlumniTab()}></span>
          <span>Alumni</span>
        </li> */}
      </ul>
      <div className="college-tabs-content">
        {targetTab && (
          <div id="Target" className="tab-content current">
            <ul className="college-info-list">
              {/* <li className={`${fields.collegepreference == true ? "active " : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={imageSettingGray} />
                  </span>
                  <a href="/#/colleges/preferences">
                    Set College Preferences
                  </a>
                  <span className="icon-box circle ml-8">
                    <input
                      type="checkbox"
                      checked={fields.collegepreference || ""}
                      onChange={(e) => handleChange(e, "collegepreference")}
                    />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li> */}
              <li>
                {isFav == 1 ? (
                  <div className="info-flex">
                    <span className="icon-box mr-8">
                      <img src={motarboard} />
                    </span>
                    <Link to="/colleges/list">
                      Following / Favorite College
                    </Link>
                    <span className="icon-box circle ml-8">
                      {/* <input type="checkbox" checked={isFav == 1 ? true : false} /> */}
                      <input
                        type="checkbox"
                        checked={isFav == 1 ? true : false}
                        disabled
                      />
                      <img src={substractionIcon} />
                    </span>
                  </div>
                ) : (
                  <div className="info-flex underline-none">
                    <span className="icon-box mr-8">
                      <img src={motarboard} />
                    </span>
                    <strong href="/colleges/list">
                      Following / Favorite College
                    </strong>
                    <span className="icon-box circle ml-8">
                      {/* <input type="checkbox" checked={isFav == 1 ? true : false} /> */}
                      <input
                        type="checkbox"
                        checked={isFav == 1 ? true : false}
                        disabled
                      />
                      <img src={substractionIcon} />
                    </span>
                  </div>
                )}
              </li>
              {/* <li className={`${fields.matchAnalysis == true ? "active" : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={analyticsIcon} />
                  </span>
                  <a>
                    Match Analysis Conducted
                  </a>
                  <span className={`${allMemberships && allMemberships.length == 0 ? "icon-box mr-8" : "icon-box circle ml-8"}`} style={{ right: "10px", position: "absolute" }}>
                    {allMemberships && allMemberships.length !== 0 ? (
                      <input type="checkbox" checked={fields.matchAnalysis || ""} onChange={(e) => handleChange(e, "matchAnalysis")} />
                    ) : null}
                    <img src={allMemberships && allMemberships.length == 0 ? lockIcon : substractionIcon} onClick={() => handleOpenVideo()} />
                  </span>
                </div>
              </li> */}
              <li className={`${fields.categorized == true ? 'active' : ''}`}>
                {isFav == 1 ? (
                  <div className="info-flex">
                    <span className="icon-box mr-8">
                      <img src={filterIcon} />
                    </span>
                    <Link to="/colleges/list">
                      Categorized (Dream, Reach, Fit, Safety)
                    </Link>
                    <span className="icon-box circle ml-8">
                      {/* <input type="checkbox" checked={fields.categorized || ""} onChange={(e) => handleChange(e, "categorized")} /> */}
                      <input
                        type="checkbox"
                        checked={
                          collegeState && collegeState.categoryid
                            ? collegeState.categoryid !== 5
                              ? true
                              : false
                            : false
                        }
                        disabled
                      />
                      <img src={substractionIcon} />
                    </span>
                  </div>
                ) : (
                  <div className="info-flex underline-none">
                    <span className="icon-box mr-8">
                      <img src={filterIcon} />
                    </span>
                    <strong>Categorized (Dream, Reach, Fit, Safety)</strong>
                    <span className="icon-box circle ml-8">
                      {/* <input type="checkbox" checked={fields.categorized || ""} onChange={(e) => handleChange(e, "categorized")} /> */}
                      <input
                        type="checkbox"
                        checked={
                          collegeState && collegeState.categoryid
                            ? collegeState.categoryid !== 5
                              ? true
                              : false
                            : false
                        }
                        disabled
                      />
                      <img src={substractionIcon} />
                    </span>
                  </div>
                )}
              </li>
              {/* <li className={`${fields.researchSchool == true ? "active" : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={researchSchoolIcon} />
                  </span>
                  <a>
                    Research College
                  </a>
                  <span className="icon-box circle ml-8">
                    <input type="checkbox" checked={fields.researchSchool || ""} onChange={(e) => handleChange(e, "researchSchool")} />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li> */}
            </ul>
          </div>
        )}
        {contactTab && (
          <div id="Contact" className="tab-content current">
            <ul className="college-info-list">
              <li className={`${fields.sentMessage == true ? 'active' : ''}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={emailIcon} />
                  </span>
                  <a onClick={() => allowedToSentMsg()}>Sent Message</a>
                  {collegeData.allowtosendmsg == 0 ? (
                    <span className="icon-box ml-8">
                      <img src={lockIcon} />
                    </span>
                  ) : (
                    <span className="icon-box circle ml-8">
                      <input
                        type="checkbox"
                        checked={fields.sentMessage || ''}
                        onChange={(e) => handleChange(e, 'sentMessage')}
                      />
                      <img src={substractionIcon} />
                    </span>
                  )}
                </div>
              </li>
              {/* <li className={`${fields.recieveView == true ? "active" : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={downloadIcon} />
                  </span>
                  <a href="/#/colleges/activity">
                    Receive View
                  </a>
                  <span className="icon-box circle ml-8">
                    <input type="checkbox" checked={fields.recieveView || ""} onChange={(e) => handleChange(e, "recieveView")} />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li> */}
            </ul>
          </div>
        )}
        {interestTab == true && (
          <div id="Interest" className="tab-content current">
            <ul className="college-info-list">
              {/* <li className={`${fields.recievedMessage == true ? "active" : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={recievedMessage} />
                  </span>
                  <a href="/#/message/coach">
                    Received Message
                  </a>
                  <span className="icon-box circle ml-8">
                    <input type="checkbox" checked={fields.recievedMessage || ""} onChange={(e) => handleChange(e, "recievedMessage")} />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li> */}
              {/* <li className={`${fields.transcriptRequest == true ? "active" : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={transcriptRequest} />
                  </span>
                  <a href="/#/checklist">
                    Transcript Request
                  </a>
                  <span className="icon-box circle ml-8">
                    <input type="checkbox" checked={fields.transcriptRequest || ""} onChange={(e) => handleChange(e, "transcriptRequest")} />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li> */}
              {/* <li>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={followersIcon} />
                  </span>
                  <a>
                    Followed by College
                  </a>
                  <span className="icon-box circle ml-8">
                    <input type="checkbox" checked={collegeFollowAthlete && collegeFollowAthlete.length !== 0 ? true : false} />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li> */}
              {/* <li className={`${fields.videoMetricsRequest == true ? "active" : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={videoCamera} />
                  </span>
                  <a href="/#/checklist">
                    Video/Metrics Request
                  </a>
                  <span className="icon-box circle ml-8">
                    <input type="checkbox" checked={fields.videoMetricsRequest || ""} onChange={(e) => handleChange(e, "videoMetricsRequest")} />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li> */}
              {/* <li className={`${fields.releaseProfile == true ? "active" : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={releaseProfile} />
                  </span>
                  <a href="/#/checklist">
                    Release Profile
                  </a>
                  <span className="icon-box circle ml-8">
                    <input type="checkbox" checked={fields.releaseProfile || ""} onChange={(e) => handleChange(e, "releaseProfile")} />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li> */}
              {/* <li className={`${fields.callTextRequest == true ? "active" : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={releaseProfile} />
                  </span>
                  <a href="/#/checklist">
                    Call / Text Request
                  </a>
                  <span className="icon-box circle ml-8">
                    <input type="checkbox" checked={fields.callTextRequest || ""} onChange={(e) => handleChange(e, "callTextRequest")} />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li> */}
              {/* <li className={`${fields.introductionRequest == true ? "active" : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={releaseProfile} />
                  </span>
                  <a href="/#/checklist">
                    Introduction Request
                  </a>
                  <span className="icon-box circle ml-8">
                    <input type="checkbox" checked={fields.introductionRequest || ""} onChange={(e) => handleChange(e, "introductionRequest")} />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li> */}
              <li
                className={`${fields.liveEvaluation == true ? 'active' : ''}`}
              >
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={liveEvaluationIcon} />
                  </span>
                  <a>Live Evaluation</a>
                  <span className="icon-box circle ml-8">
                    <input
                      type="checkbox"
                      checked={fields.liveEvaluation || ''}
                      onChange={(e) => handleChange(e, 'liveEvaluation')}
                    />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )}
        {activeTab == true && (
          <div id="Active" className="tab-content current">
            <ul className="college-info-list">
              <li
                className={`${
                  fields.digitalConversation == true ? 'active' : ''
                }`}
              >
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={digitalConversationIcon} />
                  </span>
                  <a>Digital Conversation</a>
                  <span className="icon-box circle ml-8">
                    <input
                      type="checkbox"
                      checked={fields.digitalConversation || ''}
                      onChange={(e) => handleChange(e, 'digitalConversation')}
                    />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li>
              <li
                className={`${
                  fields.phoneConversation == true ? 'active' : ''
                }`}
              >
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={phoneConversationIcon} />
                  </span>
                  <a>Phone Conversation / ZOOM</a>
                  <span className="icon-box circle ml-8">
                    <input
                      type="checkbox"
                      checked={fields.phoneConversation || ''}
                      onChange={(e) => handleChange(e, 'phoneConversation')}
                    />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li>
              <li
                className={`${fields.offCampusContact == true ? 'active' : ''}`}
              >
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={offCampusContact} />
                  </span>
                  <a>Off-Campus Contact</a>
                  <span className="icon-box circle ml-8">
                    <input
                      type="checkbox"
                      checked={fields.offCampusContact || ''}
                      onChange={(e) => handleChange(e, 'offCampusContact')}
                    />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )}
        {visitTab == true && (
          <div id="Visit" className="tab-content current">
            <ul className="college-info-list">
              <li
                className={`${fields.unOfficialVisit == true ? 'active' : ''}`}
              >
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={unofficialVisitIcon} />
                  </span>
                  <a>Unofficial Visit</a>
                  <span className="icon-box circle ml-8">
                    <input
                      type="checkbox"
                      checked={fields.unOfficialVisit || ''}
                      onChange={(e) => handleChange(e, 'unOfficialVisit')}
                    />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li>
              <li className={`${fields.officialVisit == true ? 'active' : ''}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={unofficialVisitIcon} />
                  </span>
                  <a>Official Visit</a>
                  <span className="icon-box circle ml-8">
                    <input
                      type="checkbox"
                      checked={fields.officialVisit || ''}
                      onChange={(e) => handleChange(e, 'officialVisit')}
                    />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )}
        {offerTab && (
          <div id="Offer" className="tab-content current">
            <ul className="college-info-list">
              {offerTypes
                ? offerTypes.map((offer) => (
                    <li
                      className={`${
                        fields.selectedValue === offer.id ? 'active' : ''
                      }`}
                    >
                      <div className="info-flex">
                        <span className="icon-box mr-8">
                          <img
                            src={imageDownloadUrl + '/' + offer?.imagename}
                          />
                        </span>
                        <a>{offer.offeresvalue}</a>
                        <span className="icon-box circle ml-8">
                          <input
                            type="checkbox"
                            checked={fields.selectedValue === offer.id}
                            onChange={(e) => handleOfferTabChange(e, offer.id)}
                          />
                          <img src={substractionIcon} />
                        </span>
                      </div>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        )}
        {commitTab && (
          <div id="Commit" className="tab-content current">
            <ul className="college-info-list">
              {commitmentType && commitmentType.data
                ? commitmentType.data.map((commitStatus, index) => (
                    <li
                      className={`${
                        fields.commitTypeValue == commitStatus.id
                          ? 'active'
                          : ''
                      }`}
                      key={index}
                    >
                      <div className="info-flex">
                        <span className="icon-box mr-8">
                          <img
                            src={
                              imageDownloadUrl + '/' + commitStatus.imagename
                            }
                          />
                        </span>
                        <a>{commitStatus.commitmentstatusvalue}</a>
                        <span className="icon-box circle ml-8">
                          <input
                            type="checkbox"
                            checked={
                              fields.commitTypeValue == commitStatus.id || null
                            }
                            onChange={(e) =>
                              handleCommitTabChange(e, commitStatus.id)
                            }
                          />
                          <img src={substractionIcon} />
                        </span>
                      </div>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        )}
        {signedTab && (
          <div id="Signed" className="tab-content current">
            <ul className="college-info-list">
              {signedCommitmentType && signedCommitmentType.length > 0
                ? signedCommitmentType.map((commitStatus, index) => (
                    <li
                      className={`${
                        fields.commitTypeValue == commitStatus.id
                          ? 'active'
                          : ''
                      }`}
                      key={index}
                    >
                      <div className="info-flex">
                        <span className="icon-box mr-8">
                          {/* <img src={imageDownloadUrl + "/" + commitStatus.imagename} /> */}
                        </span>
                        <a>{commitStatus.name}</a>
                        <span className="icon-box circle ml-8">
                          <input
                            type="checkbox"
                            checked={
                              signedList && signedList.length > 0
                                ? signedList[0].commitmenttype ==
                                    commitStatus.id || null
                                : null
                            }
                            disabled={true}
                          />
                          <img src={substractionIcon} />
                        </span>
                      </div>
                    </li>
                  ))
                : null}
              {/* <li className={`${fields.signcommitmenttopalybaseball == true ? "active" : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={contractSignedIcon} />
                  </span>
                  <a>
                    Signed Commitment to Play Baseball
                  </a>
                  <span className="icon-box circle ml-8">
                    <input type="checkbox" checked={fields.signcommitmenttopalybaseball || ""} onChange={(e) => handleChange(e, "signcommitmenttopalybaseball")} />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li> */}
            </ul>
          </div>
        )}
        {/* {alumniTab && (
          <div id="Alumni" className="tab-content current">
            <ul className="college-info-list">
              <li className={`${fields.alumni == true ? "active" : ""}`}>
                <div className="info-flex">
                  <span className="icon-box mr-8">
                    <img src={contractSignedIcon} />
                  </span>
                  <a>
                    Alumni Lorem Ipsum
                  </a>
                  <span className="icon-box circle ml-8">
                    <input type="checkbox" checked={fields.alumni || ""} onChange={(e) => handleChange(e, "alumni")} />
                    <img src={substractionIcon} />
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )} */}
        <div className="back-button">
          <Link className="btn green" to="/colleges/search">
            Back
          </Link>
        </div>
      </div>

      <Dialog
        open={progressInfoModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeProgressInfoModal();
          }
        }}
        className="matDialogCustom"
      >
        <div
          className="modal myprogressmodal fade show"
          style={{ display: 'block' }}
          id="MyProgressModal"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => closeProgressInfoModal()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="form-group flexbox">
                  <div className="logobox">
                    {collegeData.collegelogo ? (
                      <img
                        src={imageDownloadUrl + '/' + collegeData.collegelogo}
                        alt=""
                      />
                    ) : (
                      <img src={TeamLogoPng} />
                    )}
                  </div>
                  <h3 className="title">
                    {collegeState?.collegeInfo?.collegename}
                  </h3>
                </div>
                <div className="form-group">
                  <span
                    dangerouslySetInnerHTML={{ __html: progressInfoText }}
                  ></span>
                  <p className="checkbox-group" style={{ marginTop: '15px' }}>
                    <span className="checkbox">
                      <input type="checkbox" onClick={handleCheckProgress} />
                      <label>Don't show this again</label>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={offerModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeOfferModal();
          }
        }}
        className="matDialogCustom"
      >
        <div
          className="modal fade commitment-modal show"
          style={{ display: 'block' }}
          id="OfferModal"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isAddedInOffer ? 'Edit Offer' : 'Add Offer'}
                </h5>
                <button
                  onClick={() => closeOfferModal()}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="img-box">
                  {collegeData.collegelogo ? (
                    <img
                      src={imageDownloadUrl + '/' + collegeData.collegelogo}
                      alt=""
                    />
                  ) : (
                    <img src={TeamLogoPng} />
                  )}
                </div>
                <h3>
                  {collegeData && collegeData.collegename
                    ? collegeData.collegename
                    : ''}
                </h3>
                <p>
                  {collegeData?.collegeconferenceidmaprel
                    ? collegeData?.collegeconferenceidmaprel
                        ?.Collegeconferences[0]?.conferencenmae
                    : ' '}
                  {!!collegeData?.collegeconferenceidmaprel?.[0]
                    ?.conferencenmae ? (
                    <br />
                  ) : (
                    ''
                  )}
                  {collegeData
                    ? `${
                        collegeData.city === null ||
                        collegeData.city === undefined
                          ? ' '
                          : getCamelCase(collegeData.city)
                      }
                          ${
                            collegeData
                              ? (collegeData.city === null ||
                                  collegeData.city === undefined) &&
                                (collegeData?.collegestateidmaprel?.States[0]
                                  ?.initials === null ||
                                  collegeData?.collegestateidmaprel?.States[0]
                                    ?.initials === undefined)
                                ? ' '
                                : ','
                              : ' '
                          }
                          ${
                            collegeData?.collegestateidmaprel
                              ? collegeData?.collegestateidmaprel?.States[0]
                                  ?.initials === null ||
                                collegeData?.collegestateidmaprel?.States[0]
                                  ?.initials === undefined
                                ? ' '
                                : collegeData?.collegestateidmaprel?.States[0]
                                    ?.initials
                              : ' '
                          }`
                    : ' '}
                  <br />
                  {collegeData
                    ? `${
                        collegeData?.collegecollegedevisionidmaprel
                          ? collegeData?.collegecollegedevisionidmaprel
                              ?.Collegedivisions[0]?.collegedivisionname ===
                              null ||
                            collegeData?.collegecollegedevisionidmaprel
                              ?.Collegedivisions[0]?.collegedivisionname ===
                              undefined
                            ? ' '
                            : collegeData?.collegecollegedevisionidmaprel
                                ?.Collegedivisions[0]?.collegedivisionname
                          : ' '
                      }`
                    : ' '}
                </p>
                {/* <ul>
                  <li>{collegeData && collegeData.collegelevel ? collegeData.collegelevel : ""}</li>
                  <li>
                    {collegeData && collegeData.state ? getCamelCase(collegeData.state) : ""}, {collegeData && collegeData.city ? getCamelCase(collegeData.city) : ""}
                  </li>
                </ul> */}
                <div className="add-commitment-button">
                  <Link
                    to={{
                      pathname: '/Offers',
                      state: {
                        collegeOfferId:
                          isAddedInOffer && isAddedInOffer.id
                            ? isAddedInOffer.id
                            : null,
                        id: collegeData && collegeData.id ? collegeData.id : '',
                        collegename:
                          collegeData && collegeData.collegename
                            ? collegeData.collegename
                            : '',
                        interestLevel:
                          collegeState && collegeState.collegeinterestlevel
                            ? collegeState.collegeinterestlevel
                            : '',
                        offerType: isAddedInOffer
                          ? progressId && progressId.offertypeid
                            ? progressId.offertypeid
                            : null
                          : '',
                        offerDate:
                          isAddedInOffer && isAddedInOffer.offerdate
                            ? isAddedInOffer.offerdate
                            : null,
                      },
                    }}
                  >
                    {isAddedInOffer
                      ? 'Edit Offer for this college'
                      : 'Add Offer for this college'}
                  </Link>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => closeOfferModal()}
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={unFavProgressModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeUnFavProgressModal();
          }
        }}
        className="matDialogCustom"
      >
        <div
          className="modal fade unfavourite-progress-modal show"
          style={{ display: 'block' }}
          id="UnfavouriteProgressModal"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => closeUnFavProgressModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-body">
                <p>
                  To update your College Progress you must click the{' '}
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="42.302"
                      height="38.479"
                      viewBox="0 0 42.302 38.479"
                    >
                      <defs>
                        <style></style>
                        <filter
                          id="a"
                          x="0"
                          y="0"
                          width="42.302"
                          height="38.479"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="3" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="3" result="b" />
                          <feFlood floodOpacity="0.161" />
                          <feComposite operator="in" in2="b" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g transform="translate(9.751 17.752)">
                        <g
                          className="b"
                          transform="matrix(1, 0, 0, 1, -9.75, -17.75)"
                        >
                          <path
                            className="a"
                            d="M20.586,3.537a6.347,6.347,0,0,0-8.309.572l-.877.854-.877-.854a6.347,6.347,0,0,0-8.309-.572,5.817,5.817,0,0,0-.441,8.747l8.617,8.406a1.453,1.453,0,0,0,2.017,0l8.617-8.406a5.813,5.813,0,0,0-.436-8.747Z"
                            transform="translate(9.75 4.5)"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>{' '}
                  to favorite the college first.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn"
                  data-dismiss="modal"
                  data-toggle="modal"
                  onClick={() => closeUnFavProgressModal()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={signedModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeSignedCommitModal();
          }
        }}
        className="matDialogCustom"
      >
        <div
          className="modal fade commitment-modal show"
          style={{ display: 'block' }}
          id="signedModal"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Signed Commitment</h5>
                <button
                  onClick={() => closeSignedCommitModal()}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="img-box">
                  {collegeData.collegelogo ? (
                    <img
                      src={imageDownloadUrl + '/' + collegeData.collegelogo}
                      alt=""
                    />
                  ) : (
                    <img src={TeamLogoPng} />
                  )}
                </div>
                <h3>
                  {collegeData && collegeData.collegename
                    ? collegeData.collegename
                    : ''}
                </h3>
                {/* <ul>
                  <li>{collegeData && collegeData.collegelevel ? collegeData.collegelevel : ""}</li>
                  <li>{collegeData && collegeData.state ? getCamelCase(collegeData.state) : ""}, {collegeData && collegeData.city ? getCamelCase(collegeData.city) : ""}</li>
                </ul> */}
                <p>
                  {collegeData?.collegeconferenceidmaprel
                    ? collegeData?.collegeconferenceidmaprel
                        ?.Collegeconferences[0]?.conferencenmae
                    : ' '}
                  <br />
                  {collegeData
                    ? `${
                        collegeData.city === null ||
                        collegeData.city === undefined
                          ? ' '
                          : getCamelCase(collegeData.city)
                      }
                          ${
                            collegeData
                              ? (collegeData.city === null ||
                                  collegeData.city === undefined) &&
                                (collegeData?.collegestateidmaprel?.States[0]
                                  ?.initials === null ||
                                  collegeData?.collegestateidmaprel?.States[0]
                                    ?.initials === undefined)
                                ? ' '
                                : ','
                              : ' '
                          }
                          ${
                            collegeData?.collegestateidmaprel
                              ? collegeData?.collegestateidmaprel?.States[0]
                                  ?.initials === null ||
                                collegeData?.collegestateidmaprel?.States[0]
                                  ?.initials === undefined
                                ? ' '
                                : collegeData?.collegestateidmaprel?.States[0]
                                    ?.initials
                              : ' '
                          }`
                    : ' '}
                  <br />
                  {collegeData
                    ? `${
                        collegeData?.collegecollegedevisionidmaprel
                          ? collegeData?.collegecollegedevisionidmaprel
                              ?.Collegedivisions[0]?.collegedivisionname ===
                              null ||
                            collegeData?.collegecollegedevisionidmaprel
                              ?.Collegedivisions[0]?.collegedivisionname ===
                              undefined
                            ? ' '
                            : collegeData?.collegecollegedevisionidmaprel
                                ?.Collegedivisions[0]?.collegedivisionname
                          : ' '
                      }`
                    : ' '}
                </p>
                <div className="add-commitment-button">
                  <Link
                    to={{
                      pathname: '/viewCommitment',
                      state: {
                        id:
                          collegeData && collegeData.id ? collegeData.id : null,
                        collegename:
                          collegeData && collegeData.collegename
                            ? collegeData.collegename
                            : '',
                        isSigned: true,
                      },
                    }}
                  >
                    Add Signed Commitment
                  </Link>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => closeSignedCommitModal()}
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={commitModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeCommitModal();
          }
        }}
        className="matDialogCustom"
      >
        <div
          className="modal fade commitment-modal show"
          style={{ display: 'block' }}
          id="CommitmentModal"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Commitment</h5>
                <button
                  onClick={() => closeCommitModal()}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="img-box">
                  {collegeData.collegelogo ? (
                    <img
                      src={imageDownloadUrl + '/' + collegeData.collegelogo}
                      alt=""
                    />
                  ) : (
                    <img src={TeamLogoPng} />
                  )}
                </div>
                <h3>
                  {collegeData && collegeData.collegename
                    ? collegeData.collegename
                    : ''}
                </h3>
                <p>
                  {collegeData?.collegeconferenceidmaprel
                    ? collegeData?.collegeconferenceidmaprel
                        ?.Collegeconferences[0]?.conferencenmae
                    : ' '}
                  <br />
                  {collegeData
                    ? `${
                        collegeData.city === null ||
                        collegeData.city === undefined
                          ? ' '
                          : getCamelCase(collegeData.city)
                      }
                          ${
                            collegeData
                              ? (collegeData.city === null ||
                                  collegeData.city === undefined) &&
                                (collegeData?.collegestateidmaprel?.States[0]
                                  ?.initials === null ||
                                  collegeData?.collegestateidmaprel?.States[0]
                                    ?.initials === undefined)
                                ? ' '
                                : ','
                              : ' '
                          }
                          ${
                            collegeData?.collegestateidmaprel
                              ? collegeData?.collegestateidmaprel?.States[0]
                                  ?.initials === null ||
                                collegeData?.collegestateidmaprel?.States[0]
                                  ?.initials === undefined
                                ? ' '
                                : collegeData?.collegestateidmaprel?.States[0]
                                    ?.initials
                              : ' '
                          }`
                    : ' '}
                  <br />
                  {collegeData
                    ? `${
                        collegeData?.collegecollegedevisionidmaprel
                          ? collegeData?.collegecollegedevisionidmaprel
                              ?.Collegedivisions[0]?.collegedivisionname ===
                              null ||
                            collegeData?.collegecollegedevisionidmaprel
                              ?.Collegedivisions[0]?.collegedivisionname ===
                              undefined
                            ? ' '
                            : collegeData?.collegecollegedevisionidmaprel
                                ?.Collegedivisions[0]?.collegedivisionname
                          : ' '
                      }`
                    : ' '}
                </p>
                {/* <ul>
                  <li>{collegeData && collegeData.collegelevel ? collegeData.collegelevel : ""}</li>
                  <li>{collegeData && collegeData.state ? getCamelCase(collegeData.state) : ""}, {collegeData && collegeData.city ? getCamelCase(collegeData.city) : ""}</li>
                </ul> */}
                <div className="add-commitment-button">
                  <Link
                    to={{
                      pathname: '/viewCommitment',
                      state: {
                        id:
                          collegeData && collegeData.id ? collegeData.id : null,
                        collegename:
                          collegeData && collegeData.collegename
                            ? collegeData.collegename
                            : '',
                        commitmentTypeId:
                          progressId && progressId.commitmentid
                            ? progressId.commitmentid
                            : null,
                        isSigned: false,
                      },
                    }}
                  >
                    Add Commitment
                  </Link>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => closeCommitModal()}
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={deleteCommitment}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeDeleteCommitment();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteItem
          onCancelClick={closeDeleteCommitment}
          eventName="Commitment "
          onDeleteClick={handelDeleteCommitment}
        />
      </Dialog>
      <Dialog
        open={deleteOfferModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeDeleteOfferModal();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteItem
          onCancelClick={closeDeleteOfferModal}
          onDeleteClick={handleDeleteOffer}
          eventName="Offer "
        />
      </Dialog>
      <Dialog
        open={deleteCommitOffer}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeDeleteOfferCommitmentModal();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteItem
          onCancelClick={closeDeleteOfferCommitmentModal}
          onDeleteClick={deleteCommitmentOffer}
          eventName="Offer And Commitment"
        />
      </Dialog>
      <Dialog
        open={deleteSignedAndCommitment}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeDeleteSignedAndCommitment();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteItem
          onCancelClick={closeDeleteSignedAndCommitment}
          eventName="Signed And Commitment "
          onDeleteClick={handelDeleteSignedAndCommitment}
        />
      </Dialog>
      <Dialog
        open={deleteSignedCommitOffer}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeDeleteSignedOfferCommitmentModal();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteItem
          onCancelClick={closeDeleteSignedOfferCommitmentModal}
          onDeleteClick={deleteSignedCommitmentOffer}
          eventName="Offer , Commitment And Signed"
        />
      </Dialog>
      <Dialog
        open={decommitSignedFromOtherCollege}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeDecommitSignedFromOtherCollege();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteItem
          onCancelClick={closeDecommitSignedFromOtherCollege}
          onDeleteClick={handleDecommitSignedFromOtherCollege}
          eventName="Are you sure you want to decommit from the college that you have added to your list earlier?"
          deleteMessage={false}
        />
      </Dialog>
      <Dialog
        open={deleteSigned}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeDeleteSignedModal();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteItem
          onCancelClick={closeDeleteSignedModal}
          onDeleteClick={deleteSignedCommit}
          eventName="signed commitment for this college"
        />
      </Dialog>
      <Dialog
        open={decommitEarlierCollege}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeDecommitEarlierCollege();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteItem
          onCancelClick={closeDecommitEarlierCollege}
          onDeleteClick={handleDecommitEarlierCollege}
          eventName="Are you sure you want to decommit from the college that you have added to your list earlier?"
          deleteMessage={false}
        />
      </Dialog>
      <Dialog
        open={decommitEarlierSigned}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeDecommitEarlierSigned();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteItem
          onCancelClick={closeDecommitEarlierSigned}
          onDeleteClick={handleDecommitEarlierSigned}
          eventName="Are you sure you want to decommit from the college that you have added to your list earlier?"
          deleteMessage={false}
        />
      </Dialog>
      <VideoPopup
        show={showVideoPopup}
        close={handleCloseVideo}
        videoId="1mnYUP8IWz10"
      />
    </div>
  );
};

function CollegeDetails(props) {
  const collegeId = window.location.href.split('/')[5]; //earlier it was 6
  const [progressTab, setProgressTab] = useState(false);
  const [matchTab, setMatchTab] = useState(false);
  const [admissionsTab, setAdmissionsTab] = useState(false);
  const [academicstab, setAcademicsTab] = useState(false);
  const [campusLifeTab, setCampusLifeTab] = useState(false);
  const [recruitingTab, setRecruitingTab] = useState(true);
  const [commitsTab, setCommitsTab] = useState(false);
  const [collegeData, setCollegeData] = useState([]);
  const [collegeid, setCollegeid] = useState();
  const [isFav, setIsFav] = useState();
  const [interestLevel, setInterestLevel] = useState();
  const [collegeRank, setCollegeRank] = useState();
  const [followersData, setFollowersData] = useState();
  const [followerCount, setFollowerCount] = useState();
  const [followingCount, setFollowingCount] = useState();
  const [openMap, setOpenMap] = useState(false);
  const [viewsOfCollege, setViewsOfCollege] = useState();
  const [collegeFollowAthlete, setCollegeFollowAthlete] = useState();
  const [userCollegedata, setUserCollegeData] = useState();
  const [showInterestModal, setShowInterestModal] = useState(false);
  const [interesrLevelList, setInteresrLevelList] = useState([]);
  const [collegeIsAddedToOffer, setCollegeIsAddedToOffer] = useState(null);
  const fakeInput = useRef();
  //This state only used for keep track of similar college select from admission , academics and campus life.
  const [clgId, setclgId] = useState();
  const [isClickedFromTarget, setIsClickedFromTarget] = useState(false);
  //this is state for favclg
  const [favCount, setFavCount] = useState(0);
  const location = useLocation();
  const [isShowMore, setAIsShowMore] = useState(true); // show more ui changes
  useEffect(() => {
    let data =
      collegeData &&
      collegeData.userCollegeCollegeIdMap &&
      collegeData.userCollegeCollegeIdMap.Usercolleges[0];
    if (data && data.collegerank) {
      setCollegeRank(data.collegerank);
    }
    if (data && data.collegeinterestlevel) {
      if (
        data.usercollegecollegeinterestlevelmaprel &&
        data.usercollegecollegeinterestlevelmaprel.Interestlevels &&
        data.usercollegecollegeinterestlevelmaprel.Interestlevels
      ) {
        setInterestLevel(
          data.usercollegecollegeinterestlevelmaprel.Interestlevels[0]
            .interestlevelvalue
        );
      }
    }
  }, [collegeData]);

  useEffect(() => {
    if (
      props.viewCollege &&
      props.viewCollege.data &&
      props.viewCollege.data.data &&
      props.viewCollege.data.data.data &&
      props.viewCollege.data.data.data.allViewcolleges
    ) {
      setViewsOfCollege(
        props.viewCollege.data.data.data.allViewcolleges.totalCount
      );
    }
  }, [props.viewCollege.data]);

  useEffect(() => {
    getFavoriteCollegesList();
  }, [isFav]);

  useEffect(async () => {
    await getCollegeDetails();
    await getAllCoachDetailsList();
    await getFollowersList();
    await viewCollegeList();
    await getMembershipData();
    let favStatus = window.location.href.split('/')[6]; //earlir it was 7
    setIsFav(favStatus);
    fakeInput?.current?.focus();
    fakeInput?.current?.select();
  }, [clgId, location]);

  const getCollegeDetails = () => {
    let collegeId = window.location.href.split('/')[5]; //earlier it was 6
    setCollegeid(collegeId);
    props.allCollegeDetailsListStart({
      query: collegeDetailsQuery(getUserInfo().id),
      variables: {
        where: {
          id: collegeId,
        },
      },
    });
  };

  const viewCollegeList = () => {
    let collegeId = window.location.href.split('/')[5]; //earlier it was 6
    props.viewCollegeListStart({
      query: getViewCollegesQuery,
      variables: {
        where: {
          collegeid: collegeId,
        },
      },
    });
  };
  const getAllCoachDetailsList = () => {
    props.getAllCoachDetailsStart({
      query: getCoachDetailsQuery,
      variables: {
        where: {
          collegeid: window.location.href.split('/')[5], //earlier it was 6
          order: 'priority desc', //this key is added as pe preeti 12/10/2022
          active: 1,
        },
      },
    });
  };
  const getFollowersList = async () => {
    const variables = {
      where: {
        collegeid: window.location.href.split('/')[5], //earlier it was 6
      },
    };
    const res = await fetchGraphMethod(getAllFollowersQuery, variables, true);
    let data = res?.data?.data?.allFollowers?.Followers;
    setFollowersData(data);
    const followers =
      data &&
      data.filter((follower) => {
        return follower.followerid !== null;
      });
    setFollowerCount(followers?.length);
    const followings =
      data &&
      data.filter((following) => {
        return following.followingid !== null;
      });
    setFollowingCount(followings?.length);

    const collegeFollowsAthlete =
      data &&
      data.filter((follow) => {
        return follow.followingid == getUserInfo().id;
      });
    setCollegeFollowAthlete(collegeFollowsAthlete);
  };
  const getMembershipData = () => {
    props.membershipListStart({
      query: getAllMembershipsQuery,
      variables: {
        where: {
          userid: getUserInfo() ? getUserInfo().id : null,
        },
      },
    });
  };

  const getFavoriteCollegesList = () => {
    let collegeId = window.location.href.split('/')[5]; //earlier it was 6

    const variables =
      isFav == 1
        ? {
            where: {
              userid: getUserInfo() ? getUserInfo().id : null,
              collegeid: collegeId,
              active: 1,
            },
          }
        : {
            where: {
              userid: getUserInfo() ? getUserInfo().id : null,
              collegeid: collegeId,
              order: 'id DESC',
            },
            first: 1,
            last: 1,
          };
    props.collegesListStart({
      query: getAllUserCollegesQuery,
      variables,
    });
    // //server request for fav count 10/6/2022(MM/DD/YYYY)
    getFavClgCount();
  };
  //server request for fav count 10/6/2022(MM/DD/YYYY)
  const getFavClgCount = async () => {
    const finalObj = {
        obj: {
          collegeid: collegeId,
          active: 1,
          isfav: 1,
        },
      },
      resFav = await fetchGraphMethod(getCollegesFavListQuery, finalObj, true);
    if (resFav?.status == 200) {
      const favCountRes = resFav?.data?.data?.allUsercolleges?.totalCount ?? 0;
      setFavCount(favCountRes);
    } else {
      setFavCount(0);
    }
  };

  useEffect(() => {
    if (
      props.allCollegeDetails &&
      props.allCollegeDetails.data &&
      props.allCollegeDetails.data[0]
    ) {
      setCollegeData(props.allCollegeDetails.data[0]);
    }
  }, [props.allCollegeDetails.data]);

  const openWebsiteUrl = (url) => {
    window.open(url, '_blank');
  };
  const collegeStatus =
    props.allUserColleges && props.allUserColleges.data
      ? props.allUserColleges.data.find((college) => {
          return college.collegeid == collegeid;
          // && college.active == 1
        })
      : null;

  const collegeStatusUpdate = () => {
    let status = '';
    if (
      collegeStatus &&
      collegeStatus.target == 1 &&
      collegeStatus.contact == 0 &&
      collegeStatus.interest == 0 &&
      collegeStatus.collegeactive == 0 &&
      collegeStatus.visit == 0 &&
      collegeStatus.offer == 0 &&
      collegeStatus.signed == 0 &&
      collegeStatus.alumni == 0
    ) {
      status = 'Target';
    } else if (
      collegeStatus &&
      collegeStatus.active == 1 &&
      collegeStatus.contact == 0 &&
      collegeStatus.interest == 0 &&
      collegeStatus.collegeactive == 0 &&
      collegeStatus.visit == 0 &&
      collegeStatus.offer == 0 &&
      collegeStatus.signed == 0 &&
      collegeStatus.alumni == 0
    ) {
      status = 'Target';
    } else if (
      collegeStatus &&
      collegeStatus.target == 1 &&
      collegeStatus.contact == 1 &&
      collegeStatus.interest == 0 &&
      collegeStatus.collegeactive == 0 &&
      collegeStatus.visit == 0 &&
      collegeStatus.offer == 0 &&
      collegeStatus.signed == 0 &&
      collegeStatus.alumni == 0
    ) {
      status = 'Contact';
    } else if (
      collegeStatus &&
      collegeStatus.target == 1 &&
      collegeStatus.contact == 1 &&
      collegeStatus.interest == 1 &&
      collegeStatus.collegeactive == 0 &&
      collegeStatus.visit == 0 &&
      collegeStatus.offer == 0 &&
      collegeStatus.signed == 0 &&
      collegeStatus.alumni == 0
    ) {
      status = 'Interest';
    } else if (
      collegeStatus &&
      collegeStatus.target == 1 &&
      collegeStatus.contact == 1 &&
      collegeStatus.interest == 1 &&
      collegeStatus.collegeactive == 1 &&
      collegeStatus.visit == 0 &&
      collegeStatus.offer == 0 &&
      collegeStatus.signed == 0 &&
      collegeStatus.alumni == 0
    ) {
      status = 'Active';
    } else if (
      collegeStatus &&
      collegeStatus.target == 1 &&
      collegeStatus.contact == 1 &&
      collegeStatus.interest == 1 &&
      collegeStatus.collegeactive == 1 &&
      collegeStatus.visit == 1 &&
      collegeStatus.offer == 0 &&
      collegeStatus.signed == 0 &&
      collegeStatus.alumni == 0
    ) {
      status = 'Visit';
    } else if (
      collegeStatus &&
      collegeStatus.target == 1 &&
      collegeStatus.contact == 1 &&
      collegeStatus.interest == 1 &&
      collegeStatus.collegeactive == 1 &&
      collegeStatus.visit == 1 &&
      collegeStatus.offer == 1 &&
      collegeStatus.commit == 0 &&
      collegeStatus.signed == 0 &&
      collegeStatus.alumni == 0
    ) {
      status = 'Offer';
    } else if (
      collegeStatus &&
      collegeStatus.target == 1 &&
      collegeStatus.contact == 1 &&
      collegeStatus.interest == 1 &&
      collegeStatus.collegeactive == 1 &&
      collegeStatus.visit == 1 &&
      collegeStatus.offer == 1 &&
      collegeStatus.commit == 1 &&
      collegeStatus.signed == 0 &&
      collegeStatus.alumni == 0
    ) {
      status = 'Commit';
    } else if (
      collegeStatus &&
      collegeStatus.target == 1 &&
      collegeStatus.contact == 1 &&
      collegeStatus.interest == 1 &&
      collegeStatus.collegeactive == 1 &&
      collegeStatus.visit == 1 &&
      collegeStatus.offer == 1 &&
      collegeStatus.signed == 1 &&
      collegeStatus.alumni == 0
    ) {
      status = 'Signed';
    } else if (
      collegeStatus &&
      collegeStatus.target == 1 &&
      collegeStatus.contact == 1 &&
      collegeStatus.interest == 1 &&
      collegeStatus.collegeactive == 1 &&
      collegeStatus.visit == 1 &&
      collegeStatus.offer == 1 &&
      collegeStatus.signed == 1 &&
      collegeStatus.alumni == 1
    ) {
      status = 'Alumni';
    } else {
      status = 'NA';
    }
    return status;
  };
  const handleProgressTab = () => {
    setProgressTab(true);
    setAdmissionsTab(false);
    setAcademicsTab(false);
    setCampusLifeTab(false);
    setMatchTab(false);
    setRecruitingTab(false);
    setCommitsTab(false);
  };
  const handleAdmissionsTab = () => {
    setAdmissionsTab(true);
    setProgressTab(false);
    setAcademicsTab(false);
    setCampusLifeTab(false);
    setMatchTab(false);
    setRecruitingTab(false);
    setCommitsTab(false);
  };
  const handleAcademicsTab = () => {
    setAcademicsTab(true);
    setProgressTab(false);
    setAdmissionsTab(false);
    setCampusLifeTab(false);
    setMatchTab(false);
    setRecruitingTab(false);
    setCommitsTab(false);
  };
  const handleCampusLifeTab = () => {
    setCampusLifeTab(true);
    setProgressTab(false);
    setAdmissionsTab(false);
    setAcademicsTab(false);
    setMatchTab(false);
    setRecruitingTab(false);
    setCommitsTab(false);
  };
  const handleMatchTab = () => {
    setMatchTab(true);
    setProgressTab(false);
    setAdmissionsTab(false);
    setAcademicsTab(false);
    setCampusLifeTab(false);
    setRecruitingTab(false);
    setCommitsTab(false);
  };
  const handleRecruitingTab = () => {
    setRecruitingTab(true);
    setProgressTab(false);
    setAdmissionsTab(false);
    setAcademicsTab(false);
    setCampusLifeTab(false);
    setMatchTab(false);
    setCommitsTab(false);
  };
  const handleCommmitsTab = () => {
    setCommitsTab(true);
    setProgressTab(false);
    setAdmissionsTab(false);
    setAcademicsTab(false);
    setCampusLifeTab(false);
    setMatchTab(false);
    setRecruitingTab(false);
  };

  const openTwitterLogin = (url) => {
    if (!!url) {
      window.open(`https://twitter.com/${url}`, '_blank');
    }
  };
  const handleMapModal = () => {
    setOpenMap(true);
  };
  const onCloseMap = () => {
    setOpenMap(false);
  };
  useEffect(() => {
    if (
      props.allUserColleges &&
      props.allUserColleges.data &&
      props.allUserColleges.data[0]
    ) {
      setUserCollegeData(props.allUserColleges.data[0]);
    }
  }, [props.allUserColleges.data]);

  useEffect(async () => {
    await fetchGraphMethod(
      getAllOfferQuery,
      {
        where: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
          collegeid: parseInt(window.location.href.split('/')[5]), //earlier it was 6
        },
      },
      true
    ).then((res) => {
      if (res?.data?.data?.allOffers?.totalCount) {
        setCollegeIsAddedToOffer(res?.data?.data?.allOffers?.Offers);
      }
    });
    getFavCollegesList();
    getInterestLevelList();
  }, [clgId]);

  const getFavCollegesList = () => {
    let collegeId = window.location.href.split('/')[5]; //earlier it was 6

    const variables =
      isFav == 1
        ? {
            where: {
              userid: getUserInfo() ? getUserInfo().id : null,
              collegeid: collegeId,
              active: 1,
            },
          }
        : {
            where: {
              userid: getUserInfo() ? getUserInfo().id : null,
              collegeid: collegeId,
            },
          };
    props.collegesListStart({
      query: getAllUserCollegesQuery,
      variables,
    });
  };

  const onCloseInterestModal = () => {
    setShowInterestModal(false);
  };

  const getSignedCommitmentList = async () => {
    let collegeId = window.location.href.split('/')[5]; //earlier it was 6
    let count = 0;
    if (collegeId !== '') {
      await fetchGraphMethod(
        getMySignedCommitmentQuery,
        {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            active: 1,
            collegeid: Number(collegeId),
          },
        },
        true
      ).then((res) => {
        if (res?.data?.data?.allSignedcommitments?.totalCount) {
          count = Number(res?.data?.data?.allSignedcommitments?.totalCount);
        } else {
          count = 0;
        }
        return count;
      });
    }
    return count;
  };

  const onSelectHandler = async (interestLevelId) => {
    setIsFav(1);
    setShowInterestModal(false);
    if (isClickedFromTarget) {
      // messagePopup("", "Congrats! College has been Favorited / Targeted.", "success");
      toastr.success('Congrats! College has been Favorited / Targeted.', '', {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: 'toast-top-full-width',
        showEasing: 'swing',
        fadeIn: 40000,
        preventDuplicates: true,
      });
    }
    await getCollegeDetails();
    //Change the existing url on college favorite.(Make bit from 0 to 1)
    const collId = window.location.href.split('/')[5]; //earlier it was 6
    if (collId !== '') {
      window.history.replaceState({}, null, `/colleges/details/${collId}/1`);
    }
  };

  const sentMessage = () => {
    warningMessagePopup(
      '',
      'The message center is coming soon. You are being redirected to the recruiting tab to contact college coaches directly',
      'warning'
    ).then(async (res) => {
      if (res) {
        window.location.reload(false);
      }
    });
  };

  const favoriteClickHandler = async (e) => {
    const signedCount = await getSignedCommitmentList();
    if (isFav == 1) {
      let warningMessage = '';
      if (userCollegedata?.offer === 1 && userCollegedata?.commit === 1) {
        if (signedCount > 0) {
          warningMessage =
            'Are you sure you want to unfollow this college? Offers , commitments and signed commitment will be deleted as well.';
        } else {
          warningMessage =
            'Are you sure you want to unfollow this college? Offers and commitments will be deleted as well.';
        }
      } else if (
        userCollegedata?.offer === 1 &&
        userCollegedata?.commit === 0
      ) {
        warningMessage =
          'Are you sure you want to unfollow this college? Offers will be deleted as well.';
      } else {
        warningMessage = 'Are you sure you want to unfollow this college?';
      }

      warningMessagePopup('', warningMessage, 'warning').then(async (res) => {
        if (res) {
          let collegeId = window.location.href.split('/')[5]; //earlier it was 6
          await fetchMethod(
            '/api/Usercolleges/removeCollege',
            {
              userId: getUserInfo().id,
              collegeRank: collegeRank ? collegeRank : 0,
              collegeid: collegeId ? Number(collegeId) : 0,
            },
            'post',
            true
          ).then((res) => {
            setIsFav(0);
            // messagePopup("", "School / College  has been removed as Favorite!", "success");
            toastr.success(
              'School / College  has been removed as Favorite!',
              '',
              {
                progressBar: true,
                timeOut: 2000,
                closeButton: true,
                positionClass: 'toast-top-full-width',
                showEasing: 'swing',
                fadeIn: 40000,
              }
            );
          });
          //Change the existing url on college Unfavorite.(Make bit from 1 to 0)
          window.history.replaceState(
            {},
            null,
            `/colleges/details/${collegeId}/0`
          );
          //server request for fav clg count 10/6/2022(MM/DD/YYYY)
          getFavClgCount();
        }
        setIsClickedFromTarget(false);
      });
    } else {
      setShowInterestModal(true);
    }
  };

  const getInterestLevelList = async () => {
    await fetchGraphMethod(getAllInterestLevelQuery, {}, true).then((res) => {
      if (res.status == 200) {
        setInteresrLevelList(
          res?.data?.data?.allInterestlevels?.Interestlevels
        );
      }
    });
  };

  const setClgIdOnChildClick = (colgId) => {
    if (colgId !== null) {
      setclgId(colgId);
      //Close other tabs and let page refresh as default and open recruiting tab as default tab.
      setAdmissionsTab(false);
      setAcademicsTab(false);
      setCampusLifeTab(false);
      setRecruitingTab(true);
    }
  };

  const openIntrestModalFromTarget = () => {
    setIsClickedFromTarget(true);
    setShowInterestModal(true);
  };

  return (
    <div>
      <BackdropLoader
        open={
          props.myProgress.loading ||
          props.allOffersList.loading ||
          props.commitmentList.loading ||
          props.allUserColleges.loading ||
          props.allCollegeDetails.loading ||
          props.offerListTypes.loading
        }
      />
      <section
        className="college-detail-banner-main"
        style={{
          background: `url(${
            collegeData.collegebanner
              ? imageDownloadUrl + '/' + collegeData.collegebanner
              : collegeBanner
          })`,
        }}
      >
        <input className="fakeInput" type="text" ref={fakeInput} />
        <div className="container">
          <div className="banner-detail-box">
            <div className="banner-detail-text">
              <div className="college-banner-logo">
                {collegeData.collegelogo ? (
                  <img
                    src={imageDownloadUrl + '/' + collegeData.collegelogo}
                    alt=""
                  />
                ) : (
                  <img src={TeamLogoPng} />
                )}
                {isFav == 1 && collegeRank ? (
                  <span className="college-count">{'#' + collegeRank}</span>
                ) : null}
                {collegeFollowAthlete && collegeFollowAthlete.length !== 0 ? (
                  <button className="followBtn">Follows You</button>
                ) : null}
              </div>
              <div className="college-banner-content">
                <h1 className="title">
                  {collegeData && collegeData.collegename
                    ? collegeData.collegename
                    : ''}
                </h1>
                <p>
                  {collegeData &&
                  collegeData.collegeconferenceidmaprel?.Collegeconferences[0]
                    ?.conferencenmae
                    ? collegeData.collegeconferenceidmaprel
                        ?.Collegeconferences[0]?.conferencenmae
                    : ' '}
                </p>
                <div className="collegeButtonWrap">
                  {/* Change incorporated (removed Button and replaced it with text) */}
                  {/* <button className="btn baseballButton"> */}
                  <p>
                    {props.athleteData &&
                    props.athleteData.athletesData &&
                    props.athleteData.athletesData.sportsName
                      ? props.athleteData.athletesData.sportsName
                      : ''}
                  </p>
                  {/* </button> */}
                  <button
                    onClick={(e) => favoriteClickHandler(e)}
                    className={`${
                      isFav == 1
                        ? 'btn bg-none ml-0'
                        : 'btn bg-none powerLinkUnfilled ml-0'
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31.2"
                      height="26.199"
                      viewBox="0 0 31.2 26.199"
                    >
                      <path
                        id="heart"
                        d="M18.374,29.5a2.485,2.485,0,0,1-1.3-.363c-5.667-3.473-8.122-5.855-9.474-7.344-2.885-3.174-4.265-6.432-4.226-9.961C3.42,7.789,7.014,4.5,11.385,4.5a9.112,9.112,0,0,1,6.662,2.963.464.464,0,0,0,.653,0A9.107,9.107,0,0,1,25.364,4.5c4.371,0,7.965,3.289,8.01,7.333.039,3.53-1.344,6.788-4.226,9.961-1.354,1.489-3.807,3.87-9.474,7.344a2.486,2.486,0,0,1-1.3.362Z"
                        transform="translate(-2.774 -3.9)"
                        fill="#C0321F"
                      />
                    </svg>
                  </button>
                  <button
                    className="btn bg-none emailLink"
                    onClick={() => sentMessage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.006"
                      height="19.205"
                      viewBox="0 0 24.006 19.205"
                    >
                      <g id="email" transform="translate(-1 -4)">
                        <path
                          id="Path_369"
                          data-name="Path 369"
                          d="M24.973,6.512A2.948,2.948,0,0,0,22.066,4H3.963A2.948,2.948,0,0,0,1.056,6.512L13.014,14.25Z"
                          transform="translate(-0.011)"
                          fill="#fff"
                        />
                        <path
                          id="Path_370"
                          data-name="Path 370"
                          d="M13.438,16.971a.8.8,0,0,1-.869,0L1,9.486V21.349A2.955,2.955,0,0,0,3.952,24.3h18.1a2.955,2.955,0,0,0,2.952-2.952V9.485Z"
                          transform="translate(0 -1.096)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
                <ul className="address-info">
                  <li>
                    <a onClick={() => handleMapModal()}>
                      <svg
                        className="location-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.29"
                        height="21.284"
                        viewBox="0 0 15.29 21.284"
                      >
                        <g transform="translate(-110.1 -49.084)">
                          <path
                            className="a"
                            d="M118.745,48A6.589,6.589,0,0,0,112,54.417c0,4.075,4.5,10.533,6.148,12.763a.739.739,0,0,0,1.194,0c1.651-2.23,6.148-8.685,6.148-12.763A6.589,6.589,0,0,0,118.745,48Z"
                            transform="translate(-1 1.984)"
                          />
                          <ellipse
                            className="a"
                            cx="2.308"
                            cy="2.052"
                            rx="2.308"
                            ry="2.052"
                            transform="translate(115.256 56.619)"
                          />
                        </g>
                      </svg>{' '}
                      {!!collegeData.city ? getCamelCase(collegeData.city) : ''}
                      {!!collegeData.state
                        ? `, ${getCamelCase(collegeData.state)}`
                        : ''}
                    </a>
                  </li>
                  <li>
                    {collegeData && collegeData.collegewebsite ? (
                      <a
                        onClick={() =>
                          openWebsiteUrl(collegeData.collegewebsite)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18.7"
                          height="18.7"
                          viewBox="0 0 18.7 18.7"
                        >
                          <g
                            id="Group_1235"
                            data-name="Group 1235"
                            transform="translate(-354.4 -206.4)"
                          >
                            <path
                              id="Path_389"
                              data-name="Path 389"
                              d="M56.75,48a8.75,8.75,0,1,0,8.75,8.75A8.75,8.75,0,0,0,56.75,48Z"
                              transform="translate(307 159)"
                              fill="none"
                              stroke="#fff"
                              strokeMiterlimit="10"
                              strokeWidth="1.2"
                            />
                            <path
                              id="Path_390"
                              data-name="Path 390"
                              d="M148.07,48c-2.443,0-4.74,3.918-4.74,8.75s2.3,8.75,4.74,8.75,4.74-3.918,4.74-8.75S150.513,48,148.07,48Z"
                              transform="translate(215.68 159)"
                              fill="none"
                              stroke="#fff"
                              strokeMiterlimit="10"
                              strokeWidth="1.2"
                            />
                            <path
                              id="Path_391"
                              data-name="Path 391"
                              d="M117.33,117.33a10.244,10.244,0,0,0,11.667,0M129,129a10.244,10.244,0,0,0-11.667,0"
                              transform="translate(240.586 92.586)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.2"
                            />
                            <path
                              id="Path_392"
                              data-name="Path 392"
                              d="M56.75,48V65.5m8.75-8.75H48"
                              transform="translate(307 159)"
                              fill="none"
                              stroke="#fff"
                              strokeMiterlimit="10"
                              strokeWidth="1.2"
                            />
                          </g>
                        </svg>
                        {collegeData.collegewebsite?.length > 35
                          ? collegeData.collegewebsite?.slice(0, 35) + '..'
                          : collegeData.collegewebsite}
                      </a>
                    ) : (
                      ''
                    )}
                  </li>
                </ul>
                {collegeData && collegeData.collegetwittername ? (
                  <p className="social">
                    <span
                      className="icon"
                      onClick={() =>
                        openTwitterLogin(collegeData?.collegetwittername)
                      }
                    >
                      <svg
                        height="512"
                        viewBox="0 0 152 152"
                        width="512"
                        style={{ height: '40' }}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="_02.twitter" data-name="02.twitter">
                            <circle
                              id="background"
                              cx="76"
                              cy="76"
                              fill="#00a6de"
                              r="76"
                            />
                            <path
                              id="icon"
                              d="m113.85 53a32.09 32.09 0 0 1 -6.51 7.15 2.78 2.78 0 0 0 -1 2.17v.25a45.58 45.58 0 0 1 -2.94 15.86 46.45 46.45 0 0 1 -8.65 14.5 42.73 42.73 0 0 1 -18.75 12.39 46.9 46.9 0 0 1 -14.74 2.29 45 45 0 0 1 -22.6-6.09 1.3 1.3 0 0 1 -.62-1.44 1.25 1.25 0 0 1 1.22-.94h1.9a30.24 30.24 0 0 0 16.94-5.14 16.42 16.42 0 0 1 -13-11.16.86.86 0 0 1 1-1.11 15.08 15.08 0 0 0 2.76.26h.35a16.43 16.43 0 0 1 -9.57-15.11.86.86 0 0 1 1.27-.75 14.44 14.44 0 0 0 3.74 1.45 16.42 16.42 0 0 1 -2.65-19.92.86.86 0 0 1 1.41-.12 42.93 42.93 0 0 0 29.51 15.78h.08a.62.62 0 0 0 .6-.67 17.36 17.36 0 0 1 .38-6 15.91 15.91 0 0 1 10.7-11.44 17.59 17.59 0 0 1 5.19-.8 16.36 16.36 0 0 1 10.84 4.09 2.12 2.12 0 0 0 1.41.54 2.15 2.15 0 0 0 .5-.07 30 30 0 0 0 8-3.31.85.85 0 0 1 1.25 1 16.23 16.23 0 0 1 -4.31 6.87 30.2 30.2 0 0 0 5.24-1.77.86.86 0 0 1 1.05 1.24z"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32.544"
                        height="26.437"
                        viewBox="0 0 32.544 26.437"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <path
                          className="a"
                          d="M32.544,3.131a13.52,13.52,0,0,1-3.336,3.448q.02.382.02.864a19.178,19.178,0,0,1-3.166,10.5A20.284,20.284,0,0,1,22.24,22.29a17.038,17.038,0,0,1-5.332,3.02,19.474,19.474,0,0,1-6.674,1.126A18.584,18.584,0,0,1,0,23.436a14.437,14.437,0,0,0,1.593.092,13.09,13.09,0,0,0,8.293-2.86,6.478,6.478,0,0,1-3.877-1.332,6.562,6.562,0,0,1-2.357-3.3,6.674,6.674,0,0,0,3.015-.112,6.507,6.507,0,0,1-3.833-2.3A6.46,6.46,0,0,1,1.312,9.376V9.294a6.589,6.589,0,0,0,3.025.835A6.632,6.632,0,0,1,2.167,7.75a6.515,6.515,0,0,1-.8-3.175,6.56,6.56,0,0,1,.9-3.355A19.01,19.01,0,0,0,8.355,6.148,18.553,18.553,0,0,0,16.027,8.2a6.529,6.529,0,0,1-.175-1.52,6.432,6.432,0,0,1,1.956-4.72,6.679,6.679,0,0,1,9.594.149A13.3,13.3,0,0,0,31.641.485,6.455,6.455,0,0,1,28.708,4.18a13.343,13.343,0,0,0,3.836-1.049Z"
                        ></path>
                      </svg> */}
                    </span>
                    <span>
                      {collegeData && collegeData.collegeinstagram ? (
                        <a
                          style={{ color: 'white' }}
                          target={'_blank'}
                          href={`https://www.instagram.com/${collegeData.collegeinstagram}`}
                        >
                          {/* <img src={InstagramIcon} className="instagramIcon" style={{ width: "22px", height: "auto" }} alt="" /> */}
                          <svg
                            id="Layer_1"
                            enableBackground="new 0 0 512 512"
                            height="39"
                            viewBox="0 0 512 512"
                            width="40"
                            style={{ height: '39px' }}
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <linearGradient
                              id="SVGID_1_"
                              gradientUnits="userSpaceOnUse"
                              x1="84.679"
                              x2="404.429"
                              y1="427.321"
                              y2="107.571"
                            >
                              <stop offset="0" stopColor="#fee411" />
                              <stop offset=".05184586" stopColor="#fedb16" />
                              <stop offset=".1381" stopColor="#fec125" />
                              <stop offset=".2481" stopColor="#fe983d" />
                              <stop offset=".3762" stopColor="#fe5f5e" />
                              <stop offset=".5" stopColor="#fe2181" />
                              <stop offset="1" stopColor="#9000dc" />
                            </linearGradient>
                            <g>
                              <circle
                                cx="256"
                                cy="256"
                                fill="url(#SVGID_1_)"
                                r="225"
                              />
                              <g fill="#fff">
                                <path d="m303.8 131h-95.5c-42.6 0-77.2 34.6-77.2 77.2v95.5c0 42.6 34.6 77.2 77.2 77.2h95.5c42.6 0 77.2-34.6 77.2-77.2v-95.5c0-42.6-34.6-77.2-77.2-77.2zm49.3 172.8c0 27.2-22.1 49.4-49.4 49.4h-95.5c-27.2 0-49.4-22.1-49.4-49.4v-95.5c0-27.2 22.1-49.4 49.4-49.4h95.5c27.2 0 49.4 22.1 49.4 49.4z" />
                                <path d="m256 192.1c-35.2 0-63.9 28.7-63.9 63.9s28.7 63.9 63.9 63.9 63.9-28.7 63.9-63.9-28.7-63.9-63.9-63.9zm0 102.7c-21.4 0-38.8-17.4-38.8-38.8s17.4-38.8 38.8-38.8 38.8 17.4 38.8 38.8-17.4 38.8-38.8 38.8z" />
                                <ellipse
                                  cx="323.1"
                                  cy="188.4"
                                  rx="10.8"
                                  ry="10.8"
                                  transform="matrix(.987 -.16 .16 .987 -26.005 54.191)"
                                />
                              </g>
                            </g>
                          </svg>
                        </a>
                      ) : null}
                    </span>
                    <span>
                      {collegeData && collegeData.collegefacebookid ? (
                        <a
                          style={{ color: 'white' }}
                          target={'_blank'}
                          href={`https://www.facebook.com/${collegeData.collegefacebookid}`}
                        >
                          {/* <img src={InstagramIcon} className="instagramIcon" style={{ width: "22px", height: "auto" }} alt="" /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="30"
                            viewBox="0 0 152 152"
                            width="30"
                            style={{
                              marginLeft: '10px',
                              height: '35px',
                              width: '35px',
                            }}
                          >
                            <g id="Layer_2" data-name="Layer 2">
                              <g id="_01.facebook" data-name="01.facebook">
                                <circle
                                  id="background"
                                  cx="76"
                                  cy="76"
                                  fill="#334c8c"
                                  r="76"
                                />
                                <path
                                  id="icon"
                                  d="m95.26 68.81-1.26 10.58a2 2 0 0 1 -2 1.78h-11v31.4a1.42 1.42 0 0 1 -1.4 1.43h-11.21a1.42 1.42 0 0 1 -1.4-1.44l.06-31.39h-8.33a2 2 0 0 1 -2-2v-10.58a2 2 0 0 1 2-2h8.28v-10.26c0-11.87 7.06-18.33 17.4-18.33h8.47a2 2 0 0 1 2 2v8.91a2 2 0 0 1 -2 2h-5.19c-5.62.09-6.68 2.78-6.68 6.8v8.85h12.31a2 2 0 0 1 1.95 2.25z"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                          </svg>
                        </a>
                      ) : null}
                    </span>
                    <a>
                      {/* requirement changed */}
                      {/* {collegeData?.collegetwittername} */}
                    </a>
                  </p>
                ) : null}
                <ul className="collegefollowersInfo">
                  <li className="likes">
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.089"
                        height="15.981"
                        viewBox="0 0 21.089 15.981"
                      >
                        <defs>
                          <style></style>
                        </defs>
                        <g transform="translate(-364.759 -164)">
                          <g transform="translate(364.759 101.997)">
                            <g transform="translate(8.98 68.387)">
                              <g transform="translate(0 0)">
                                <circle
                                  className="a"
                                  cx="1.606"
                                  cy="1.606"
                                  r="1.606"
                                ></circle>
                              </g>
                            </g>
                            <g transform="translate(5.89 65.298)">
                              <g transform="translate(0 0)">
                                <path
                                  className="a"
                                  d="M147.709,142a4.7,4.7,0,1,0,4.7,4.7A4.7,4.7,0,0,0,147.709,142Zm0,7.743a3.048,3.048,0,1,1,3.048-3.048A3.051,3.051,0,0,1,147.709,149.745Z"
                                  transform="translate(-143.014 -142.002)"
                                ></path>
                              </g>
                            </g>
                            <g transform="translate(0 62.003)">
                              <g transform="translate(0 0)">
                                <path
                                  className="a"
                                  d="M20.555,68.2a15.923,15.923,0,0,0-3.68-3.9A10.48,10.48,0,0,0,10.563,62a10.534,10.534,0,0,0-6.318,2.288,16.038,16.038,0,0,0-3.7,3.893,3.29,3.29,0,0,0,0,3.619,16.037,16.037,0,0,0,3.7,3.893,10.534,10.534,0,0,0,6.318,2.288,11.425,11.425,0,0,0,7.618-3.392.824.824,0,1,0-1.127-1.2,9.824,9.824,0,0,1-6.491,2.946c-4,0-7.315-3.419-8.644-5.439a1.644,1.644,0,0,1,0-1.808c1.329-2.02,4.645-5.439,8.644-5.439s7.3,3.422,8.612,5.444a1.648,1.648,0,0,1,0,1.8.824.824,0,0,0,1.381.9A3.3,3.3,0,0,0,20.555,68.2Z"
                                  transform="translate(0 -62.003)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span>
                      {viewsOfCollege
                        ? viewsOfCollege.toLocaleString('en-US', {
                            maximumFractionDigits: 2,
                          })
                        : 0}
                    </span>
                  </li>
                  {/*code is commented as per BA change task 25/7/2022*/}
                  {/*<li className="follower">*/}
                  {/*  <span>{followerCount ? followerCount : 0} </span>*/}
                  {/*  <span className="label">followers</span>*/}
                  {/*</li>*/}
                  {/*<li className="following">*/}
                  {/*  <span>{followingCount ? followingCount : 0} </span>*/}
                  {/*  <span className="label">following</span>*/}
                  {/*</li>*/}
                  {/*follower is added of fav college(no of athlete fav the clg)*/}
                  <li className="follower">
                    <span>{favCount ? favCount : 0} </span>
                    <span className="label">followers</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={isShowMore ? 'infoBoxFlex' : 'infoBoxFlex hide'}>
              <div className="item">
                <div className="item-detail">
                  <span className="label">Division</span>
                  <span>
                    {collegeData?.collegecollegedevisionidmaprel
                      ?.Collegedivisions[0]?.collegedivisionname || ''}
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <span className="label">College / Type</span>
                  <span className="text red">
                    {collegeData.collegetype ? collegeData.collegetype : '   '}
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <span className="label">Setting</span>
                  <span>
                    {collegeData.collegesetting
                      ? collegeData.collegecollegesettingrel
                        ? collegeData.collegecollegesettingrel
                            .Collegesettings[0].name
                        : ''
                      : ''}
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <span className="label">Enrollment</span>
                  <span>
                    {collegeData.collegestrength
                      ? collegeData.collegestrength.toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                        })
                      : ''}
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <span className="label">Academics</span>
                  <span className="text red">
                    {collegeData.collegeacademicsselectivitymaprel &&
                    collegeData.collegeacademicsselectivitymaprel
                      .Acadamicselectivities != ''
                      ? collegeData.collegeacademicsselectivitymaprel
                          .Acadamicselectivities[0].name
                      : ''}
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <span
                    className="label"
                    // style={
                    //   (!!collegeData?.collegeathleticselectivityidrel?.Athleticselectivities?.[0]?.name &&
                    //       collegeData?.collegeathleticselectivityidrel?.Athleticselectivities?.[0]?.name?.length > 15
                    //   ) ?
                    //       {marginBottom: "-4px"} : {}
                    // }
                  >
                    Athletic
                  </span>
                  <span className="text red">
                    {!!collegeData.collegeathleticselectivityidrel
                      ?.Athleticselectivities?.[0]?.name
                      ? collegeData.collegeathleticselectivityidrel
                          ?.Athleticselectivities?.[0]?.name ?? ''
                      : '--'}
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <span className="label">Tuition (In-State)</span>
                  <span>{` ${
                    collegeData.collegetutioninsidecost
                      ? '$' +
                        Number(
                          collegeData.collegetutioninsidecost
                        ).toLocaleString('en-US', { maximumFractionDigits: 2 })
                      : '--'
                  }`}</span>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <span className="label">Tuition (Out)</span>
                  <span>{`${
                    collegeData.collegetutionoutsidecost
                      ? '$' +
                        Number(
                          collegeData.collegetutionoutsidecost
                        ).toLocaleString('en-US', { maximumFractionDigits: 2 })
                      : '--'
                  }`}</span>
                </div>
              </div>
              <div className="showhideBox">
                <a onClick={() => setAIsShowMore(!isShowMore)}>
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="4"
                      viewBox="0 0 8 4"
                    >
                      <defs>
                        <style></style>
                      </defs>
                      <path
                        className="a"
                        d="M4,0,8,4H0Z"
                        transform="translate(8 4) rotate(180)"
                      ></path>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="collegeStripWrap">
        <div className="container">
          <ul>
            <li>
              <span>Last Update:</span>{' '}
              {userCollegedata && userCollegedata.updatedon
                ? getSlashFormattedDate(userCollegedata.updatedon.split('T')[0])
                : 'NA'}
            </li>
            <li>
              <span>Status:</span> {collegeStatusUpdate()}
            </li>
            <li>
              <span>Activity: </span>
              {isFav == 1 &&
              userCollegedata &&
              userCollegedata.usercollegecollegeinterestlevelmaprel &&
              userCollegedata.usercollegecollegeinterestlevelmaprel
                .Interestlevels &&
              userCollegedata.usercollegecollegeinterestlevelmaprel
                .Interestlevels[0] &&
              userCollegedata.usercollegecollegeinterestlevelmaprel
                .Interestlevels[0].interestlevelvalue
                ? userCollegedata.usercollegecollegeinterestlevelmaprel
                    .Interestlevels[0].interestlevelvalue
                : 'NA'}
            </li>
            <li>
              <span>Match:</span> NA
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.867"
                height="19.867"
                viewBox="0 0 19.867 19.867"
              >
                <g
                  id="Group_1367"
                  data-name="Group 1367"
                  transform="translate(-566 -1073)"
                >
                  <path
                    id="barchartalt"
                    d="M9.584,10.215H8.945a.609.609,0,0,1-.454-.189.621.621,0,0,1-.185-.449V.639A.621.621,0,0,1,8.491.19.609.609,0,0,1,8.945,0h.638a.613.613,0,0,1,.449.189.613.613,0,0,1,.189.449V9.577a.647.647,0,0,1-.638.638Zm-2.553,0H6.392a.609.609,0,0,1-.454-.189.621.621,0,0,1-.185-.449V3.193a.621.621,0,0,1,.185-.449.609.609,0,0,1,.454-.189H7.03a.647.647,0,0,1,.638.638V9.577a.647.647,0,0,1-.638.638Zm-2.553,0H3.838a.609.609,0,0,1-.454-.189A.621.621,0,0,1,3.2,9.577V5.746a.63.63,0,0,1,.638-.638h.638a.621.621,0,0,1,.449.185.609.609,0,0,1,.189.454v3.83a.647.647,0,0,1-.638.638ZM1.923,7.661a.647.647,0,0,1,.638.643.6.6,0,0,1-.189.449.623.623,0,0,1-.449.185H1.285v.638a.613.613,0,0,1-.189.449.619.619,0,0,1-.454.189.6.6,0,0,1-.449-.189.623.623,0,0,1-.185-.449V.639A.621.621,0,0,1,.193.19.609.609,0,0,1,.646,0H1.923A.613.613,0,0,1,2.372.19a.619.619,0,0,1,.189.454.6.6,0,0,1-.189.449.623.623,0,0,1-.449.185H1.285V2.554h.638a.647.647,0,0,1,.638.643.6.6,0,0,1-.189.449.623.623,0,0,1-.449.185H1.285V5.108h.638a.621.621,0,0,1,.449.185.6.6,0,0,1,.189.449.62.62,0,0,1-.189.454.612.612,0,0,1-.449.189H1.285V7.661h.638Z"
                    transform="translate(569.703 1079.427)"
                    fill="#333"
                    stroke="#fff"
                    strokeWidth="0.4"
                  />
                  <g id="new-tab" transform="translate(566 1073)">
                    <path
                      id="Path_680"
                      data-name="Path 680"
                      d="M21.311,21.311H6.028V6.028h6.877V4.5H6.028A1.528,1.528,0,0,0,4.5,6.028V21.311a1.528,1.528,0,0,0,1.528,1.528H21.311a1.528,1.528,0,0,0,1.528-1.528V14.434H21.311Z"
                      transform="translate(-4.5 -2.972)"
                      fill="#333"
                    />
                    <path
                      id="Path_681"
                      data-name="Path 681"
                      d="M27.085,5.306V2.25H25.556V5.306H22.5V6.835h3.056V9.891h1.528V6.835h3.056V5.306Z"
                      transform="translate(-10.274 -2.25)"
                      fill="#333"
                    />
                  </g>
                </g>
              </svg>
            </li>
          </ul>
        </div>
      </div>
      <div className="college-main-section">
        <div className="container">
          <div className="college-tabs-wrapper">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    recruitingTab == true ? 'active' : ''
                  }`}
                  data-toggle="tab"
                  onClick={() => handleRecruitingTab()}
                >
                  Recruiting
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${progressTab == true ? 'active' : ''}`}
                  data-toggle="tab"
                  onClick={() => handleProgressTab()}
                >
                  My Progress
                </a>
              </li>

              {/*<li className="nav-item">*/}
              {/*  <a className={`nav-link ${commitsTab == true ? "active" : ""}`} data-toggle="tab" onClick={() => handleCommmitsTab()}>*/}
              {/*    Commits*/}
              {/*  </a>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <a className={`nav-link ${matchTab == true ? "active" : ""}`} data-toggle="tab" onClick={() => handleMatchTab()}>*/}
              {/*    Match*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    admissionsTab == true ? 'active' : ''
                  }`}
                  data-toggle="tab"
                  onClick={() => handleAdmissionsTab()}
                >
                  Admissions
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${academicstab == true ? 'active' : ''}`}
                  data-toggle="tab"
                  onClick={() => handleAcademicsTab()}
                >
                  Academics
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    campusLifeTab == true ? 'active' : ''
                  }`}
                  data-toggle="tab"
                  onClick={() => handleCampusLifeTab()}
                >
                  Campus Life
                </a>
              </li>
            </ul>
            <div className="tab-content">
              {recruitingTab && (
                <Recruiting
                  collegeid={collegeid}
                  collegeName={collegeData?.collegename}
                  collegeFollowAthlete={collegeFollowAthlete}
                />
              )}
              {progressTab && (
                <Progress
                  getCommitmentStart={props.getCommitmentStart}
                  getOffersListStart={props.offersListStart}
                  updateUserCollegeStart={props.updateUserCollegeStart}
                  allOffersList={
                    props.allOffersList &&
                    props.allOffersList.data &&
                    props.allOffersList.data.Offers
                      ? props.allOffersList.data.Offers
                      : null
                  }
                  commitmentList={
                    props.commitmentList && props.commitmentList.data
                      ? props.commitmentList.data
                      : null
                  }
                  allUserColleges={
                    props.allUserColleges && props.allUserColleges.data
                      ? props.allUserColleges.data
                      : null
                  }
                  collegeid={collegeid}
                  isFav={isFav}
                  getFavoriteCollegesList={getFavoriteCollegesList}
                  collegeData={collegeData}
                  addMyCommitmentStart={props.addMyCommitmentStart}
                  offersListsaveStart={props.offersListsaveStart}
                  collegeStatusUpdate={collegeStatusUpdate}
                  myProgressSaveStart={props.myProgressSaveStart}
                  myProgressListStart={props.myProgressListStart}
                  myProgress={
                    props.myProgress && props.myProgress.data
                      ? props.myProgress.data
                      : null
                  }
                  collegeFollowAthlete={collegeFollowAthlete}
                  allMemberships={
                    props.allMemberships &&
                    props.allMemberships.data &&
                    props.allMemberships.data.allMemberships
                      ? props.allMemberships.data.allMemberships
                      : ''
                  }
                  commitmentStatusListStart={props.commitmentStatusListStart}
                  commitmentType={props.commitmentType}
                  offerListStart={props.offerListStart}
                  offerListTypes={
                    props.offerListTypes && props.offerListTypes.data
                      ? props.offerListTypes.data
                      : null
                  }
                  openIntrestModalFromTarget={openIntrestModalFromTarget}
                />
              )}

              {commitsTab && <Commits collegeid={collegeid} />}
              {matchTab && <div id="Match">Coming Soon</div>}
              {admissionsTab && (
                <Admissions
                  collegeid={collegeid}
                  handleSimilarCollgClick={setClgIdOnChildClick}
                />
              )}
              {academicstab && (
                <Academics
                  collegeid={collegeid}
                  handleSimilarCollgClick={setClgIdOnChildClick}
                />
              )}
              {campusLifeTab && (
                <CaampusLife
                  collegeid={collegeid}
                  handleSimilarCollgClick={setClgIdOnChildClick}
                />
              )}
            </div>
          </div>
        </div>
        <Dialog
          open={openMap}
          onClose={(event, reason) => {
            if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
              onCloseMap();
            }
          }}
        >
          <div className="map-modal-wrap">
            <div className="mapclose-button">
              <button onClick={() => onCloseMap()}>&times;</button>
            </div>
            <Map
              markers={[
                {
                  latitude: +collegeData.collegelat,
                  longitude: +collegeData.collegelng,
                },
              ]}
            />
          </div>
        </Dialog>
      </div>
      <Dialog
        open={showInterestModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            onCloseInterestModal();
          }
        }}
        className="matDialogCustom"
      >
        <InterestLevelModal
          interestValueList={interesrLevelList}
          onClose={onCloseInterestModal}
          onSelect={onSelectHandler}
          collegeDetails={{ id: window.location.href.split('/')[5] }} //earlier it was 6
        />
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    athleteData: state.common.athletesData.data,
    allCollegeDetails: state.common.collegeDetails,
    allUserColleges: state.athlete.dashboard.colleges.collegesList,
    allOffersList: state.athlete.editUserProfileData.offersData,
    commitmentList: state.athlete.profile.commitments,
    coachesList: state.athlete.editUserProfileData.allCoachDetails,
    followersList: state.athlete.dashboard.home.followersList,
    followingsList: state.athlete.dashboard.home.followingsList,
    viewCollege: state.athlete.dashboard.colleges.viewCollege,
    myProgress: state.athlete.dashboard.colleges.myProgress,
    allMemberships: state.athlete.account.allMemberships,
    offerListTypes: state.common.offerList,
    commitmentType: state.common.commitmentStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    allCollegeDetailsListStart: (data) =>
      dispatch(allCollegeDetailsListStart(data)),
    collegesListStart: (data) => dispatch(collegesListStart(data)),
    getCommitmentStart: (data) => dispatch(getCommitmentStart(data)),
    offersListStart: (data) => dispatch(offersListStart(data)),
    updateUserCollegeStart: (data) => dispatch(updateUserCollegeStart(data)),
    addMyCommitmentStart: (data) => dispatch(addMyCommitmentStart(data)),
    offersListsaveStart: (data) => dispatch(offersListsaveStart(data)),
    getAllCoachDetailsStart: (data) => dispatch(getAllCoachDetailsStart(data)),
    getFollowingListStart: (data) => dispatch(getFollowingListStart(data)),
    viewCollegeListStart: (data) => dispatch(viewCollegeListStart(data)),
    myProgressSaveStart: (data) => dispatch(myProgressSaveStart(data)),
    myProgressListStart: (data) => dispatch(myProgressListStart(data)),
    membershipListStart: (data) => dispatch(membershipListStart(data)),
    offerListStart: (data) => dispatch(offerListStart(data)),
    commitmentStatusListStart: (data) =>
      dispatch(commitmentStatusListStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CollegeDetails);
