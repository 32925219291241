import { Backdrop } from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getRecruitingListStart } from "../../../../../redux/coach/dashboard/myRecruits/recruitingList";
import { getRecruitingPhilosophyListStart, saveRecruitingPhilosophy } from "../../../../../redux/coach/dashboard/myRecruits/recruitingPhilosophy";
import { updateAllRecruitingListStart } from "../../../../../redux/coach/dashboard/myRecruits/updateAllRecruitingPhilosophy";
import { getUserInfo } from "../../../../../services/authService";
import BackdropLoader from "../../../../common/Loader";
import "./assets/css/coach-recruiting.scss";
import GeographicTab from "./GeographicTab";
import { getAllRecruitingDataQuery, getAllRecruitsListQuery, saveRecruitingQuery } from "./MyRecruitsQuery";
import NeedsTab from "./NeedsTab";
import ProgramsTab from "./ProgramsTab";
import RequirementsTab from "./RequirementsTab";
import CompleteVerification from "./assets/img/complete-verification-icon.svg";
import ScreenSwitch from "./ScreenSwitch";
import editProfilePopUp from "../../../../../utils/editProfilePopUp";

function MyRecruits(props) {
  const [recruitingTab, setRecruitingtab] = useState(true);
  const [needsTab, setNeedsTab] = useState(false);
  const [requirementsTab, setRequirementsTab] = useState(false);
  const [geographicTab, setGeographicTab] = useState(false);
  const [programsTab, setProgramsTab] = useState(false);
  const [recruitsData, setRecruitsData] = useState();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [needUpdated, setNeedUpdated] = useState(false);

  useEffect(() => {
    getRecruitsListData();
  }, []);

  useEffect(() => {
    if (props.recruitingList.data) {
      setRecruitsData(props.recruitingList.data);
    }
  }, [props.recruitingList.data]);

  const getRecruitsListData = () => {
      setLoading(false);
    props.getRecruitingListStart({
      data: {
        coachid: getUserInfo() ? getUserInfo().id : null,
      },
    });
  };
  const handleRecruitingTab = () => {
    if(!isSaved){
      editProfilePopUp(
        "Warning",
        "Changes you made may not be saved.",
        "warning"
      ).then((data) => {
        if (data) {
          setRecruitingtab(true);
          setNeedsTab(false);
          setRequirementsTab(false);
          setGeographicTab(false);
          setProgramsTab(false);
          setIsSaved(true);
        }
      });
    } else {
      setRecruitingtab(true);
      setNeedsTab(false);
      setRequirementsTab(false);
      setGeographicTab(false);
      setProgramsTab(false);
      setIsSaved(true);
    }
  };
  const handleNeedsTab = () => {
    if(!isSaved){
      editProfilePopUp(
        "Warning",
        "Changes you made may not be saved.",
        "warning"
      ).then((data) => {
        if (data) {
          setNeedsTab(true);
          setRecruitingtab(false);
          setRequirementsTab(false);
          setGeographicTab(false);
          setProgramsTab(false);
          setIsSaved(true);
        }
      });
    } else {
      setNeedsTab(true);
    setRecruitingtab(false);
    setRequirementsTab(false);
    setGeographicTab(false);
    setProgramsTab(false);
    setIsSaved(true);
    }
  };
  const handleRequirementsTab = () => {
    if(!isSaved){
      editProfilePopUp(
        "Warning",
        "Changes you made may not be saved.",
        "warning"
      ).then((data) => {
        if (data) {
          setRequirementsTab(true);
    setRecruitingtab(false);
    setNeedsTab(false);
    setGeographicTab(false);
    setProgramsTab(false);
    setIsSaved(true);
        }
      });
    } else {
      setRequirementsTab(true);
    setRecruitingtab(false);
    setNeedsTab(false);
    setGeographicTab(false);
    setProgramsTab(false);
    setIsSaved(true);
    }
  };
  const handleGeoGraphicTab = () => {
    if(!isSaved){
      editProfilePopUp(
        "Warning",
        "Changes you made may not be saved.",
        "warning"
      ).then((data) => {
        if (data) {
          setGeographicTab(true);
          setRecruitingtab(false);
          setNeedsTab(false);
          setRequirementsTab(false);
          setProgramsTab(false);
          setIsSaved(true);
        }
      });
    } else {
      setGeographicTab(true);
    setRecruitingtab(false);
    setNeedsTab(false);
    setRequirementsTab(false);
    setProgramsTab(false);
    setIsSaved(true);
    }
  };
  const handleProgramTab = () => {
    if(!isSaved){
      editProfilePopUp(
        "Warning",
        "Changes you made may not be saved.",
        "warning"
      ).then((data) => {
        if (data) {
          setProgramsTab(true);
          setRecruitingtab(false);
          setNeedsTab(false);
          setRequirementsTab(false);
          setGeographicTab(false);
          setIsSaved(true);
        }
      });
    } else {
      setProgramsTab(true);
      setRecruitingtab(false);
      setNeedsTab(false);
      setRequirementsTab(false);
      setGeographicTab(false);
      setIsSaved(true);
    }
  };
  const updateRecruitingPhilosophyData = () => {
    props.getRecruitingPhilosophyListStart({
        query : getAllRecruitingDataQuery,
        variables : {
            obj : {
                coachid : getUserInfo() ? getUserInfo().id : null,
            }
        }
    });
  };

  const handleCheckSaved = (value) => {    
    setIsSaved(value);
  }

  const handleSwitchTabData = (name) => {
    if(name === "geographic"){
    setProgramsTab(false);
    setRecruitingtab(false);
    setNeedsTab(false);
    setRequirementsTab(false);
    setGeographicTab(true);
    }
    if(name === "recruiting"){
      setProgramsTab(false);
      setRecruitingtab(true);
      setNeedsTab(false);
      setRequirementsTab(false);
      setGeographicTab(false);
    }
    if(name === "needs"){
      setProgramsTab(false);
      setRecruitingtab(false);
      setNeedsTab(true);
      setRequirementsTab(false);
      setGeographicTab(false);
    }
    if(name === "requirements"){
      setProgramsTab(false);
      setRecruitingtab(false);
      setNeedsTab(false);
      setRequirementsTab(true);
      setGeographicTab(false);
    }
    if(name === "program"){
      setProgramsTab(true);
      setRecruitingtab(false);
      setNeedsTab(false);
      setRequirementsTab(false);
      setGeographicTab(false);
    }
  }

  const updateAllRecruitingValues = (e, value) => {
     setLoading(true);   
     setIsSaved(false);   
      props.updateAllRecruitingListStart({
          data : {
            coachid : getUserInfo() ? getUserInfo().id : null,
            isyes : value == 1 ? 1 : 0  
          },
          getRecruitsListData : getRecruitsListData,
      })
  }

  const updateRecruitingValue = (e, recruits) => {
      setLoading(true);
      setIsSaved(false);
      props.saveRecruitingPhilosophy({
          query : saveRecruitingQuery,
          variables : {
              obj : {
                  id : recruits ? recruits.id : null,
                  coachid : getUserInfo() ? getUserInfo().id : null,
                  isyes : recruits.isyes == 1 ? 0 : 1
              }
          },
          getRecruitsListData: getRecruitsListData
      })
  }

  const handleNeedCondition = value => {
    setNeedUpdated(value)
  }

  return (
    <div>
        <BackdropLoader open={loading}/>
      <div className="pageContent">
        <div className="container">
          <div className="my-recruiting-box">
            <div className="top-block">
              <h1>
                Your Recruiting Needs{" "}
                {/* <span
                  className="save-button"
                  data-toggle="modal"
                  data-target="#UnsupportModal"
                >
                  Click to Save
                </span> */}
              </h1>
              <p>
                Lorem ipsum dolor simply dummy text of the printing and
                typesetting industry.
              </p>
            </div>
            <div className="tabBox">    
              <nav>
                <ul className="tabs">
                  <li className="closeBtnBox">
                    <button className="closeBtn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.804"
                        height="15.803"
                        viewBox="0 0 15.804 15.803"
                      >
                        <g className="a" transform="translate(-6.776 -6.776)">
                          <path
                            d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                            transform="translate(0 0)"
                          ></path>
                        </g>
                      </svg>
                    </button>
                  </li>
                  <li
                    className={`tab-link ${
                      recruitingTab == true ? "current" : ""
                    } `}
                    data-tab="tab1"
                  >
                    <a onClick={handleRecruitingTab}>
                      Recruiting
                     {/* {recruitingUpdated ?  */}
                     <span className="icon-box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18.002"
                          viewBox="0 0 18 18.002"
                        >
                          <g
                            id="Group_674"
                            data-name="Group 674"
                            transform="translate(-1013.019 -658.999)"
                          >
                            <path
                              id="Subtraction_10"
                              data-name="Subtraction 10"
                              d="M8.262.487a.8.8,0,0,1,1.476,0v0a.8.8,0,0,0,1.334.222h0a.8.8,0,0,1,1.4.478V1.2a.8.8,0,0,0,1.189.642h0a.8.8,0,0,1,1.165.9l0,.014a.8.8,0,0,0,.915.993h.008a.8.8,0,0,1,.808,1.233L16.542,5a.8.8,0,0,0,.543,1.235l.015,0a.8.8,0,0,1,.363,1.429l-.017.013a.8.8,0,0,0,.112,1.344l.017.009a.8.8,0,0,1-.122,1.469l-.017.006a.8.8,0,0,0-.332,1.308l.014.015a.8.8,0,0,1-.594,1.349h-.011a.8.8,0,0,0-.739,1.129l.007.016a.8.8,0,0,1-1,1.084l0,0a.8.8,0,0,0-1.067.829v.011a.8.8,0,0,1-1.3.7h0a.8.8,0,0,0-1.279.438v0a.8.8,0,0,1-1.456.242h0a.8.8,0,0,0-1.352,0h0a.8.8,0,0,1-1.456-.242v0a.8.8,0,0,0-1.279-.438h0a.8.8,0,0,1-1.3-.7v-.011a.8.8,0,0,0-1.067-.829l0,0a.8.8,0,0,1-1-1.084l.007-.016a.8.8,0,0,0-.739-1.129H1.475a.8.8,0,0,1-.594-1.349L.9,11.813a.8.8,0,0,0-.332-1.308L.547,10.5A.8.8,0,0,1,.425,9.031l.017-.009A.8.8,0,0,0,.554,7.677L.538,7.665A.8.8,0,0,1,.9,6.236l.015,0A.8.8,0,0,0,1.458,5l-.011-.017a.8.8,0,0,1,.808-1.233h.008a.8.8,0,0,0,.915-.993l0-.014a.8.8,0,0,1,1.165-.9h0A.8.8,0,0,0,5.531,1.2V1.189a.8.8,0,0,1,1.4-.478h0A.8.8,0,0,0,8.261.489Z"
                              transform="translate(1013.019 658.999)"
                              fill="#00b9ff"
                            />
                            <path
                              id="Icon_feather-check"
                              data-name="Icon feather-check"
                              d="M13.171,9l-4.93,5.378L6,11.934"
                              transform="translate(1012.434 656.311)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                      </span>
                        {/* :
                        <img
                              className="icon"
                              src={CompleteVerification}
                              alt="Verified"
                            />
                      } */}
                    </a>
                  </li>
                  <li
                    className={`tab-link ${needsTab == true ? "current" : ""}`}
                    data-tab="tab2"
                  >
                    <a onClick={handleNeedsTab}>
                      Needs
                     {/* {needUpdated ?  */}
                     <span className="icon-box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18.002"
                          viewBox="0 0 18 18.002"
                        >
                          <g
                            id="Group_674"
                            data-name="Group 674"
                            transform="translate(-1013.019 -658.999)"
                          >
                            <path
                              id="Subtraction_10"
                              data-name="Subtraction 10"
                              d="M8.262.487a.8.8,0,0,1,1.476,0v0a.8.8,0,0,0,1.334.222h0a.8.8,0,0,1,1.4.478V1.2a.8.8,0,0,0,1.189.642h0a.8.8,0,0,1,1.165.9l0,.014a.8.8,0,0,0,.915.993h.008a.8.8,0,0,1,.808,1.233L16.542,5a.8.8,0,0,0,.543,1.235l.015,0a.8.8,0,0,1,.363,1.429l-.017.013a.8.8,0,0,0,.112,1.344l.017.009a.8.8,0,0,1-.122,1.469l-.017.006a.8.8,0,0,0-.332,1.308l.014.015a.8.8,0,0,1-.594,1.349h-.011a.8.8,0,0,0-.739,1.129l.007.016a.8.8,0,0,1-1,1.084l0,0a.8.8,0,0,0-1.067.829v.011a.8.8,0,0,1-1.3.7h0a.8.8,0,0,0-1.279.438v0a.8.8,0,0,1-1.456.242h0a.8.8,0,0,0-1.352,0h0a.8.8,0,0,1-1.456-.242v0a.8.8,0,0,0-1.279-.438h0a.8.8,0,0,1-1.3-.7v-.011a.8.8,0,0,0-1.067-.829l0,0a.8.8,0,0,1-1-1.084l.007-.016a.8.8,0,0,0-.739-1.129H1.475a.8.8,0,0,1-.594-1.349L.9,11.813a.8.8,0,0,0-.332-1.308L.547,10.5A.8.8,0,0,1,.425,9.031l.017-.009A.8.8,0,0,0,.554,7.677L.538,7.665A.8.8,0,0,1,.9,6.236l.015,0A.8.8,0,0,0,1.458,5l-.011-.017a.8.8,0,0,1,.808-1.233h.008a.8.8,0,0,0,.915-.993l0-.014a.8.8,0,0,1,1.165-.9h0A.8.8,0,0,0,5.531,1.2V1.189a.8.8,0,0,1,1.4-.478h0A.8.8,0,0,0,8.261.489Z"
                              transform="translate(1013.019 658.999)"
                              fill="#00b9ff"
                            />
                            <path
                              id="Icon_feather-check"
                              data-name="Icon feather-check"
                              d="M13.171,9l-4.93,5.378L6,11.934"
                              transform="translate(1012.434 656.311)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                      </span> 
                      {/* : 
                       <img
                       className="icon"
                       src={CompleteVerification}
                       alt="Verified"
                     />} */}
                    </a>
                  </li>
                  <li
                    className={`tab-link ${
                      requirementsTab == true ? "current" : ""
                    }`}
                    data-tab="tab3"
                  >
                    <a onClick={handleRequirementsTab}>Requirements</a>
                  </li>
                  <li
                    className={`tab-link ${
                      geographicTab == true ? "current" : ""
                    }`}
                    data-tab="tab4"
                  >
                    <a onClick={handleGeoGraphicTab}>Geographic</a>
                  </li>
                  <li
                    className={`tab-link ${
                      programsTab == true ? "current" : ""
                    }`}
                    data-tab="tab5"
                  >
                    <a onClick={handleProgramTab}>Program</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="tabs-contents">
              {recruitingTab && (
                <div
                  id="tab1"
                  className={`tab-content ${
                    recruitingTab == true ? "current" : ""
                  } `}
                >
                  <div className="top-box">
                    <h2>Recruiting Philosophy</h2>
                    <p>
                      Set your program's preferences to help our prospect
                      matching system improve your athlete recommendations.
                    </p>
                  </div>
                  <div className="recruiting-detail-box">
                    <div className="recruiting-info-box">
                      <div className="item">
                        <h4 onClick={(e)=> updateAllRecruitingValues(e, 1)}>
                          <span className="icon-box">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="12"
                              viewBox="0 0 16 12"
                            >
                              <path
                                id="check"
                                d="M5.077,14.681l9.685-9.525L16,6.415,5.078,17.156,0,12.163,1.28,10.9Z"
                                transform="translate(0 -5.156)"
                                fill="#1cb79e"
                              />
                            </svg>
                          </span>
                          
                          YES
                        </h4>
                        <p>
                          Yes, I'm currently recruiting this Grade className
                        </p>
                      </div>
                      <div className="item">
                        <h4 onClick={(e)=>updateAllRecruitingValues(e, 0)}>
                          <span className="icon-box">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="15.963"
                              viewBox="0 0 16 15.963"
                            >
                              <path
                                id="block"
                                d="M8,.25a7.709,7.709,0,0,1,5.658,2.342A7.687,7.687,0,0,1,16,8.231a7.687,7.687,0,0,1-2.342,5.64A7.709,7.709,0,0,1,8,16.213a7.709,7.709,0,0,1-5.658-2.342A7.689,7.689,0,0,1,0,8.231a7.685,7.685,0,0,1,2.342-5.64A7.71,7.71,0,0,1,8,.25ZM1.611,8.231A6.211,6.211,0,0,0,2.96,12.166l8.956-8.993A6.211,6.211,0,0,0,7.981,1.824,6.133,6.133,0,0,0,3.485,3.7,6.176,6.176,0,0,0,1.611,8.231Zm6.37,6.408a6.175,6.175,0,0,0,4.534-1.874,6.178,6.178,0,0,0,1.874-4.534A6.211,6.211,0,0,0,13.04,4.3L4.084,13.29A6.158,6.158,0,0,0,7.981,14.639Z"
                                transform="translate(0 -0.25)"
                                fill="#c0321f"
                              />
                            </svg>
                          </span>
                          NEVER
                        </h4>
                        <p>I never recruit this Grade Class</p>
                      </div>
                    </div>
                    <div className="table-recruiting">
                      <table className="table">
                        {recruitsData
                          ? recruitsData.map((recruits, index) => {
                              return (
                                <tr key={index}>
                                  <td>{recruits.name}</td>
                                  <td>
                                    <button className="btn-recruiting" onClick={(e)=>updateRecruitingValue(e, recruits)}>
                                    {recruits.isyes == 1 ? (
                                        <>
                                      <span className="icon-box">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="12"
                                          viewBox="0 0 16 12"
                                        >
                                          <path
                                            id="check"
                                            d="M5.077,14.681l9.685-9.525L16,6.415,5.078,17.156,0,12.163,1.28,10.9Z"
                                            transform="translate(0 -5.156)"
                                            fill="#1cb79e"
                                          ></path>
                                        </svg>
                                      </span>
                                          YES 
                                          </>
                                          ) 
                                          :
                                           (
                                               <>
                                            <span className="icon-box">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="15.963"
                                              viewBox="0 0 16 15.963"
                                            >
                                              <path
                                                id="block"
                                                d="M8,.25a7.709,7.709,0,0,1,5.658,2.342A7.687,7.687,0,0,1,16,8.231a7.687,7.687,0,0,1-2.342,5.64A7.709,7.709,0,0,1,8,16.213a7.709,7.709,0,0,1-5.658-2.342A7.689,7.689,0,0,1,0,8.231a7.685,7.685,0,0,1,2.342-5.64A7.71,7.71,0,0,1,8,.25ZM1.611,8.231A6.211,6.211,0,0,0,2.96,12.166l8.956-8.993A6.211,6.211,0,0,0,7.981,1.824,6.133,6.133,0,0,0,3.485,3.7,6.176,6.176,0,0,0,1.611,8.231Zm6.37,6.408a6.175,6.175,0,0,0,4.534-1.874,6.178,6.178,0,0,0,1.874-4.534A6.211,6.211,0,0,0,13.04,4.3L4.084,13.29A6.158,6.158,0,0,0,7.981,14.639Z"
                                                transform="translate(0 -0.25)"
                                                fill="#c0321f"
                                              />
                                            </svg>
                                          </span>
                                          NEVER
                                          </>
                                          ) }
                                      
                                      <span className="icon-arrow">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="11.996"
                                          height="6.036"
                                          viewBox="0 0 11.996 6.036"
                                        >
                                          <path
                                            id="arrow-ios-forward-outline"
                                            d="M14.354,19.451a.855.855,0,0,1-.658-1.4l3.83-4.583L13.832,8.875A.874.874,0,1,1,15.209,7.8l4.13,5.13a.855.855,0,0,1,0,1.086l-4.275,5.13a.855.855,0,0,1-.71.308Z"
                                            transform="translate(19.452 -13.497) rotate(90)"
                                            fill="#d9d9d9"
                                          />
                                        </svg>
                                      </span>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </table>
                    </div>
                    <div className="btn-wrap">
                      {/* <div className="item">
                        <button className="btn blue">Save</button>
                      </div> */}
                      <div className="next-prev-button">
                          <ScreenSwitch
                            prevLink={()=>{}}
                            nextLink={()=>handleSwitchTabData("needs")}
                            prevLinkDisabled={true}
                            isSaved={isSaved}
                          />
                        </div>
                    </div>
                  </div>
                </div>
              )}
              {needsTab && (
                <div
                  id="tab2"
                  className={`tab-content ${needsTab == true ? "current" : ""}`}
                >
                 <NeedsTab handleSwitchTabData={handleSwitchTabData} handleCheckSaved={handleCheckSaved} handleNeedCondition={handleNeedCondition}/>
                </div>
              )}
              {requirementsTab && (
                <div
                  id="tab3"
                  className={`tab-content ${
                    requirementsTab == true ? "current" : ""
                  }`}
                >
                  <RequirementsTab handleSwitchTabData={handleSwitchTabData} handleCheckSaved={handleCheckSaved}/>
                </div>
              )}
              {geographicTab && (
                <div
                  id="tab4"
                  className={`tab-content ${
                    geographicTab == true ? "current" : ""
                  }`}
                >
                  <GeographicTab handleSwitchTabData={handleSwitchTabData} handleCheckSaved={handleCheckSaved}/>
                </div>
              )}
              <div
                id="tab5"
                className={`tab-content ${
                  programsTab == true ? "current" : ""
                }`}
              >
                <ProgramsTab 
                geographicTab={geographicTab}
                handleSwitchTabData={handleSwitchTabData}
                handleCheckSaved={handleCheckSaved}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    recruitingList: state.coach.coachDashboard.recruitingList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRecruitingListStart: (data) => dispatch(getRecruitingListStart(data)),
    getRecruitingPhilosophyListStart: (data) =>
      dispatch(getRecruitingPhilosophyListStart(data)),
      saveRecruitingPhilosophy : (data) => dispatch(saveRecruitingPhilosophy(data)),
      updateAllRecruitingListStart : (data) => dispatch(updateAllRecruitingListStart(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyRecruits);
