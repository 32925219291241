export const saveMyCommitmentQuery = `
mutation saveCollegeCommitment($obj:CollegecommitmentInput!){
    saveCollegecommitment(obj:$obj){
      id
      
    }
  }
  `;

export const getAllCommitmentType = `
  query allCommitmentstatuses($obj:JSON){
    allCommitmentstatuses(where:$obj){
      Commitmentstatuses{
      id
      commitmentstatusvalue
      imagename
      }
    }
  }

  `;

export const getAllCollegeQuery = `
  query allColleges($obj:JSON){
    allColleges(where:$obj){
      Colleges{
        id
        collegename
        city
        state
        active
      }
    }
  }
  `;

export const getAllUserDataProfileQuery = `
query allUserdata($obj: JSON){
  allUserdata(where: $obj){
    Userdata{
      athletecountrycodeid
      athletecountryflag
      athleteemail
      athletefirstname
      athletegraduationyear
      athletelastname
      athleteoutsideusorcanada
      athletephonenumber
      athletesports
      athletezipcode
      clubteamname
      email
      emailverified
      gender
      graduationmonthvalue
      id
      memberofbaseballclubteam
      role
      username
      verifieduser
      athleteSports
     userdataathletesportsmaprel {
        Athletesports {
          sportname
        }
      }
      userdataathletegraduationyearmaprel {
        Graduationyears {
          yearvalue
        }
      }
      onBoardingUserIdMap {
        Onboardings {
					profileimages
        }
      }
      followersFollowerIdMap(where: {isfollowed: 1}){
        totalCount
      }
      followersFollowingIdMap(where: {isfollowed: 1}){
        totalCount
      }
    }
  }
} 
`;
//query for profile checkbox
export const saveProfileStatus = `
mutation savePublicprofilefieldpreview($obj:PublicprofilefieldpreviewInput!){
    savePublicprofilefieldpreview(obj:$obj){
      id
    }
  }
`;

export const savePostLikeQuery = `
  mutation saveUserpostlike($obj: UserpostlikeInput!){
    saveUserpostlike(obj: $obj){
      active
      id
      postid
      userid
    }
  } 
  `;

 export const coachApiData = `
 query allCoachandteamdetails($where: JSON){
  allCoachandteamdetails(where: $where){
    Coachandteamdetails{
          id
          coachfirstname
          coachlastname
          userid
          coachemail
      		coachuserid
        }
      }
  }
 `;

 export const getAllSignedCommitmentType = `
 query allsignedcommitmenttypes($obj:JSON){
  allSignedcommitmenttypes(where: $obj){  
    Signedcommitmenttypes{
        name
        id
      }
    }
  }
`;

export const userCollegeUpdateAll = `
mutation($where:JSON,$data:JSON){
    UsercollegeUpdateAll(where:$where,data:$data)
  }
`;

export const checkAccountStatus = `
mutation($userid:Int){
  AthleteCheckAccountStatus(userid:$userid)
}
`;