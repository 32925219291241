import { all } from "redux-saga/effects";
import rootCommonSagas from "./common/rootCommonSagas";
import rootAthleteSagas from "./athlete/rootAthleteSagas";
import rootCoachSagas from "./coach/rootCoachSagas";
import rootAuthSagas from "./auth/rootAuthSagas";
import rootOnBoardingSagas from './athlete/onBoarding/rootOnBoardingSagas'
export default function* rootSaga() {
  yield all([
    rootAuthSagas(),
    rootAthleteSagas(),
    rootCommonSagas(),
    rootCoachSagas(),
  ]);
}
