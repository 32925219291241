import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  UPDATE_BASIC_USER_PROFILE_LIST_START: "UPDATE_BASIC_USER_PROFILE_LIST_START",
  UPDATE_BASIC_USER_PROFILE_LIST_SUCCESS:
    "UPDATE_BASIC_USER_PROFILE_LIST_SUCCESS",
  UPDATE_BASIC_USER_PROFILE_LIST_FAILED:
    "UPDATE_BASIC_USER_PROFILE_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const  updateBasicUserProfileListStart = (data) => ({
  type: actionType.UPDATE_BASIC_USER_PROFILE_LIST_START,
  payload: data,
});

export const updateBasicUserProfileListSuccess = (data) => ({
  type: actionType.UPDATE_BASIC_USER_PROFILE_LIST_SUCCESS,
  payload: data,
});

export const updateBasicUserProfileListFailed = (data) => ({
  type: actionType.UPDATE_BASIC_USER_PROFILE_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function updateBasicUserProfileListReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.UPDATE_BASIC_USER_PROFILE_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPDATE_BASIC_USER_PROFILE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.UPDATE_BASIC_USER_PROFILE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* updateBasicUserProfileListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(updateBasicUserProfileListSuccess(response));
      if (action.payload.nextScreen != undefined) {
        action.payload.nextScreen();
      }
    } else {
      messagePopup("", "Update Basic User Profile List failed", "error");
      yield put(
        updateBasicUserProfileListFailed(
          "Update Basic User Profile List failed"
        )
      );
    }
  } catch (error) {
    messagePopup("", "Update Basic User Profile List failed", "error");
    yield put(
      updateBasicUserProfileListFailed("Update Basic User Profile List failed")
    );
  }
}

export function* watchUpdateBasicUserProfileListSaga() {
  yield takeEvery(
    actionType.UPDATE_BASIC_USER_PROFILE_LIST_START,
    updateBasicUserProfileListSaga
  );
}
