import React, { useState, useEffect } from "react";
import "../viewCommitments/assets/css/viewCommitments.scss";
import ncaa from "./assets/img/ncaa.png";
import { connect } from "react-redux";
import { graduationYearsListStart } from "../../../../../redux/common/graduationYears";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { stateListStart } from "../../../../../redux/common/allStates";
import { getAllStatesQuery } from "../../../../common/CommonQuery";
import { sportsListStart } from "../../../../../redux/common/allSports";
import FormControl from "@mui/material/FormControl";
import { imageDownloadUrl, imageUploadUrl } from "../../../../../config";
import { getUserInfo } from "../../../../../services/authService";
import {
  getCollegeLevelQuery,
  getViewCommitmentQuery,
  saveViewCommitmentSearchQuery,
  getCollegeDivisionQuery,
  getSchoolTeamsState,
  allCollegeConferencesQuery,
  isfavCollege
} from "./viewCommitmentsQueries";
import { viewCommitmentListStart } from "../../../../../redux/athlete/profile/viewCommitments";
import downloadPng from "../homepage/assets/img/download.png";
import ReactPaginate from "react-paginate";
import { collegeLevelListStart } from "../../../../../redux/athlete/profile/collegeLevel";
import { collegeDivisionListStart } from "../../../../../redux/athlete/profile/collegeDivisionLevel";
import { allTeamsListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/allTeams";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { allCollegeConferencesListStart } from "../../../../../redux/common/allCollegeConferences";
import { commitmentStatusListStart } from "../../../../../redux/common/commitmentStatus";
import { getAllCollegeQuery, getAllCommitmentType } from "../profile/ProfileEdit/profileQuery";
import { filterCollegeNameQuery, getCollegeLevels, getSchoolLevels } from "../editUserProfile/AcademicsQuery";
import { allLevelsSchoolStart } from "../../../../../redux/athlete/dashboard/editUserProfile/academicLevels";
import { primaryListStart } from "../../../../../redux/athlete/onBoarding/primaryPositionList";
import { getAllPrimaryPositionsQuery } from "../../onboarding/positions/PositionsQuery";
import { fetchGraphMethod } from "../../../../../services/httpService";
import MuiAutoComplete from "../../../../common/AutoComplete";
import messagePopup from "../../../../../utils/messagePopup";
import { allsportsListStart } from "../../../../../redux/common/allSportWithOutUsingGender";
import TeamLogo from "../../../../../assets/img/TeamLogo.png";
import swal from 'sweetalert';
import history from "../../../../../services/history";
import {userProfileApi} from "../../../../common/userViewCount";
import {getCamelCase} from "../../../../common/camelCaseFunc";
const initialValues = {
  athleteActive: true,
  email: "",
  gender: "Male",
  graduation: "",
  graduationMonthValue: "",
  sport: "",
  openFilter: false,
  pageCount: 0,
  offset: 1,
  perPage: 10,
  page: 0,
  totalCollegeData: null,
  getPage: 0,
  go: 1
};

const SignedCommitmentList = (data) => {
  const signedCommitmentDate = new Date(data.SignedCommitmentList);
  const currentDate = new Date();
  const [days, setDays] = useState();

  function timeDiffCalc(signedCommitmentDate, currentDate) {
    let diffInMilliSeconds =
      Math.abs(currentDate - signedCommitmentDate) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    const months = Math.floor(days % 365 / 30);

    const years = Math.floor(days / 365)
    return { days, hours, minutes, months, years };
  }

  let signedSignature = "";
  if (data.SignedCommitmentList != null) {
    let abc = timeDiffCalc(signedCommitmentDate, currentDate);
    signedSignature =
      abc.years > 1
        ? abc.years + "years"
        : abc.years == 1
          ? abc.years + "year"
          : abc.months > 1
            ? abc.months + " months"
            : abc.months == 1
              ? `${abc.months} month`
              : abc.days == 1
                ? abc.days + " day"
                : abc.days > 0
                  ? abc.days + " days "
                  : abc.hours > 0
                    ? abc.hours + " hours "
                    : abc.minutes > 0
                      ? abc.minutes + " mins "
                      : abc.minutes == 1
                        ? abc.minutes + "min"
                        : "just now";

  } else {
    return (
      <>
        <div className="note"></div>
      </>
    );
  }

  return (
    <>
      <div className="note" style={{ width: "95%" }}>{data.commitmentType.commitmentStatusValue}
        <br />{signedSignature !== "just now" ? `${signedSignature} ago` : signedSignature} </div>
    </>
  );
};

const ViewCommitmentsList = (data) => {
  const [fields, setFields] = useState({
    profileImagename: "",
    profileImage: null,
  });
  const profileStatus = useDispatch()

  useEffect(() => {
    //by this check the profile pic is not updating on search
    // if (data.data.profileImages) {
    const fieldT = fields;
    const { userData } = data?.data?.profileImages ?? "";
    fields["userData"] = userData;
    fieldT["profileImagename"] = data?.data?.profileImages ?? ""
      ? data?.data?.profileImages ?? ""
        ? data?.data?.profileImages ?? ""
        : ""
      : "";
    fieldT["profileImage"] = data?.data?.profileImages ?? ""
      ? data?.data?.profileImages ?? ""
        ? `${imageDownloadUrl}/${data?.data?.profileImages ?? ""}`
        : ""
      : "";
    setFields({ ...fieldT });
    // }
  }, [data]);

  const getInitials = () => {
    let initial = "";
    if (data?.data?.athletefirstname) {
      initial = data.data.athletefirstname[0]?.toUpperCase().concat(data?.data?.athletelastname[0] ? data?.data?.athletelastname[0]?.toUpperCase() ?? "" : "");
    }
    return initial;
  };

  const redirectToCollege = async (collegeid) => {
    let variable = {
      "where": {
        "userId": +getUserInfo().id,
        "collegeId": +collegeid,
        "isfav": 1,
        "active": 1
      }
    }
    const getIsFav = await fetchGraphMethod(isfavCollege, variable, true)

    if (getIsFav?.data?.data?.allUsercolleges) {
      let favCollegeValue = getIsFav?.data?.data?.allUsercolleges
      if (favCollegeValue.Usercolleges != "") {
        history.push({ pathname: `/colleges/details/${collegeid}/1` })

      } else {
        history.push({ pathname: `/colleges/details/${collegeid}/0` })
      }
    }
  }
const viewFunc = (value) =>{
    if(value != getUserInfo()?.id ?? 0){
      userProfileApi(
          {
            obj: {
              userrole: "ATHLETES",
              profileviewedby: getUserInfo()?.id ?? 0,
              profileviewto: +data?.data?.athleteid ?? 0,
            },
          },
          true,
          false
      )
    }
}

  return (
    <div className="dataTable">
      <div className="data">
        <div className="flex">
          <div className="col">
            <div className="pic">
              <Link to={`/publicProfile/1/${+data?.data?.athleteid}`} onClick={() => {
                profileStatus({
                  type: "CHANGE_ATHLETE_PROFILE_VIEW_STATE", payload: {
                    id: data?.data?.athleteid || 0,
                    status: true
                  }
                });
                localStorage.setItem("pubViewID", +data?.data?.athleteid)
                viewFunc(+data?.data?.athleteid ?? 0)
              }}>
                {fields.profileImage ? (
                  <img src={fields.profileImage} alt="profile" />
                ) : (
                  <div className='initialLetter' style={{border: "1px solid #cfcfcf"}}><span>{getInitials()}</span></div>
                )}
              </Link>
            </div>
          </div>
          <div className="col">
            <div className="title">
              <Link to={`/publicProfile/1/${+data?.data?.athleteid}`} onClick={() => {
                profileStatus({
                  type: "CHANGE_ATHLETE_PROFILE_VIEW_STATE", payload: {
                    id: data?.data?.athleteid || 0,
                    status: true
                  }
                });
                localStorage.setItem("pubViewID", +data?.data?.athleteid)
                viewFunc(+data?.data?.athleteid ?? 0)
              }}>
                {data.data.athletefirstname} {" "} {data.data.athletelastname}
              </Link>
            </div>
            <div className="gen">
              HS {data.data.gradyear} / {data.data.primaryfield}
            </div>
            <div className="gen">
              {data.data.sportName}
            </div>
            {/*<div className="gen">*/}
            {/* {data.data.conferencename}*/}
            {/*</div>*/}
            <div className="gen">
              {data.data.currentLevelValue}
            </div>
            {getUserInfo().role == "COLLEGECOUCHES" ?
              <div className="location">{data.data.statename}{getCamelCase(data?.data?.city) ? ", " + getCamelCase(data?.data?.city) : ''}</div>
              :
              <div className="location">{data?.data?.city ? getCamelCase(data?.data?.city) + ", " : ''}{data?.data?.athletestate}</div>
            }
            {/*// earlier it was  ===>>>createdOn*/}
            <SignedCommitmentList SignedCommitmentList={data.data.updatedOn} commitmentType={data?.data} />
          </div>
        </div>
        <div className="flex">
          <div className="col">
            <div className="pic">
              <Link to="#" onClick={(e) => { redirectToCollege(data?.data?.id) }}>
                <img src={data.data && data.data.collegelogo ? imageDownloadUrl + "/" + data.data.collegelogo : TeamLogo} alt="" />
              </Link>
            </div>
          </div>
          <div className="col">
            <div className="title">
              <Link to="#" onClick={(e) => { redirectToCollege(data?.data?.id) }}>{data?.data?.collegename}</Link>
              {/* <Link to={`/colleges/details/${data?.data?.id}/${(getUserInfo()?.id == data?.data?.athleteid) ? data?.data?.isfav : 0}`}>{data?.data?.collegename}</Link> */}
            </div>
            <div className="location">{getCamelCase(data?.data?.locationaaddress)}{!!data?.data?.initials && `, ${data?.data?.initials}`}</div>
            <div className="gen">{data?.data?.collegelevel}</div>
            <div className="gen">{data.data.conferencename}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommitmentList = (viewCommitments) => {
  const commitmentData = [];
  if (
    viewCommitments.viewCommitments &&
    viewCommitments.viewCommitments.data &&
    viewCommitments.viewCommitments.data.length
  ) {
    viewCommitments.viewCommitments.data.map((data, index) => {

      commitmentData.push(<ViewCommitmentsList data={data} />);
    });
  }
  return <>{commitmentData.length !== 0 ? commitmentData : "No data found"}</>;
};

const ViewCommitments = (props) => {
  const [fields, setFields] = useState({
    ...initialValues,
  });
  const [errors, setErrors] = React.useState({});
  const [allConferences, setAllConferences] = useState([]);
  const [allCommitmentTypes, setAllCommitmentTypes] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [allPrimaryPositions, setAllPrimaryPositions] = useState([]);
  const [allColleges, setAllColleges] = useState([]);
  const [queryFields, setQueryFields] = useState({
    name: "",
    sportId: 0,
    gradId: 0,
    levelId: 0,
    athleteStateId: 0,
    teamStateId: 0,
    teamConferenceId: 0,
    commitmentStatusid: 0,
    athelteLevelid: 0,
    collegeid: 0,
    positionid: 0,
    gender:"Male"
  });
  const [isShowSlide, setIsShowSlide] = useState(false)

  const handlePageClick = (e) => {
    const fieldsT = fields;
    if (e.selected < fields["getPage"]) fieldsT["getPage"] = e.selected + 1;
    else fieldsT["getPage"] = e.selected - 1
    fieldsT["getPage"] = e.selected;
    fieldsT["page"] = e.selected;
    fieldsT["offset"] = (e.selected + 1) * fieldsT["perPage"];
    setFields(fieldsT);
    handlenextPage();
  };

  const handleChange = (field, e) => {
    setErrors({
      errorsT: "",
    });
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setFields({
      ...fieldsT,
    });
  };

  const handlenextPage = () => {
    const variables = {
      data: {
        offset: fields.page * fields.perPage,
        limit: fields.perPage,
      },
    };
    props.viewCommitmentListStart({
      query: saveViewCommitmentSearchQuery(queryFields, variables.data),
    });
    //earlier below code is there was there
    // props.viewCommitmentListStart({
    //   query: getViewCommitmentQuery(variables.data),
    //   variables,
    // });
  };
  const handleDropDownChange = async (collegeName) => {
    const variables = {
      where: {
        active: 1,
        and: [
          {
            or: [
              {
                collegename: {
                  like: `%${collegeName.target.value}%`,
                  options: "i",
                },
              },
            ],
          },
        ],
      },
    };
    if (collegeName.target.value.toString().length > 2) {
      let res = await fetchGraphMethod(getAllCollegeQuery, variables, true);
      if (res?.status == 200 && res?.statusText == "OK") {
        setAllColleges(res?.data?.data?.allColleges?.Colleges ?? []);
      }
    }
  };
  const handleSearch = (field, e) => {
    let fieldsT = queryFields;
    if(field == "gender"){
      fieldsT[field] = e?.target?.value
      fieldsT['sportId'] = 0
    }else{
      fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    }
    fieldsT["collegeid"] = queryFields.collegeName && queryFields.collegeName.id ? queryFields.collegeName.id : 0;
    setQueryFields({
      ...fieldsT,
    });
    const variables = {
      data: {
        // offset: fields.page * fields.perPage,
        offset: 0,
        limit: fields.perPage,
        go: 1,
        getPage: 0
      },
    };
    props.viewCommitmentListStart({
      query: saveViewCommitmentSearchQuery(queryFields, variables.data),
    });
  };

  useEffect(() => {
    props?.allsportsListStart();
    getSportsList(fields.gender);
  }, [fields.gender]);

  useEffect(() => {
    props.stateListStart({
      query: getAllStatesQuery,
    });
    props.collegeDivisionListStart({
      query: getCollegeDivisionQuery
    });
    // props.collegeLevelStart({
    //   query: getCollegeLevels,
    // });
    props.primaryListStart({
      query: getAllPrimaryPositionsQuery,
    });
    props.allCollegeConferencesListStart({
      query: allCollegeConferencesQuery
    })
    props.commitmentStatusListStart({
      query: getAllCommitmentType
    })
    props.schoolLevelListStart({
      query: getSchoolLevels,
    });
    // props.allCollegeDetailsListStart({
    //   query: getAllCollegeQuery,
    //   variables: null,
    // });
    props.allTeamsListStart({
      query: getSchoolTeamsState,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
    props.graduationYearsListStart();
    //this used for scroll the page on top when user landon this page
    window.scrollTo(0, 0)
  }, []);
  useEffect(() => {
    const variables = {
      data: {
        offset: fields.page * fields.perPage,
        limit: fields.perPage,
      },
    };
    props.viewCommitmentListStart({
      query: getViewCommitmentQuery(variables.data),
      variables,
    });
  }, []);
  useEffect(() => {
    if (props.viewCommitments) {
      if (props.viewCommitments.data) {
        const fieldsT = fields;
        fieldsT["pageCountT"] = props.viewCommitments.data.totalcount
          ? Math.ceil(
            +props.viewCommitments.data.totalcount / fieldsT["perPage"]
          )
          : 0;
        setFields({ ...fieldsT });
      }
    }
    //used for scrolling on top
    window.scrollTo(0, 0)
  }, [props.viewCommitments]);

  useEffect(() => {
    if (props.collegeConferences.data) {
      props.collegeConferences.data?.sort((a,b)=>{
       return  a.conferencenmae > b.conferencenmae ? 1 : b.conferencenmae > a.conferencenmae ? -1 : 0
      })
      setAllConferences(props.collegeConferences.data);
    }
  }, [props.collegeConferences.data])

  useEffect(() => {
    if (props.schoolLevelList && props.schoolLevelList.data && props.schoolLevelList.data.allSchoolcurrentlevels && props.schoolLevelList.data.allSchoolcurrentlevels.Schoolcurrentlevels) {
      setAllLevels(props.schoolLevelList.data.allSchoolcurrentlevels.Schoolcurrentlevels);
    }
  }, [props.schoolLevelList.data])

  useEffect(() => {
    if (props.commitmentType.data) {
      setAllCommitmentTypes(props.commitmentType.data);
    }
  }, [props.commitmentType.data])

  useEffect(() => {
    if (props.primaryPosition && props.primaryPosition.primaryPositionList && props.primaryPosition.primaryPositionList.data !== null) {
      setAllPrimaryPositions(props.primaryPosition.primaryPositionList.data);
    }
  }, [props.primaryPosition.primaryPositionList.data])

  useEffect(() => {
    //this used for scroll the page on top when user landon this page
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1500)
  }, [])

  const getSportsList = (gender) => {
    props.sportsListStart({ gender });
  };


  let TeamState =
    props.allTeamList &&
      props.allTeamList.data &&
      props.allTeamList.data.allTeamsList ?
      props.allTeamList.data.allTeamsList : "";
  //getting the unique Team State
  var TeamStateArray = [];
  TeamState && TeamState.filter(function (item) {
    var i = TeamStateArray.findIndex(x => (x.state == item.state));
    if (i <= -1) {
      TeamStateArray.push(item);
    }
    return null;
  });

  const handleGoToPage = (e) => {
    // if (props?.viewCommitments?.data && fields.go !== "") {
    const variables = {
      data: {
        offset: ((fields.go - 1) * fields?.perPage),
        limit: fields.perPage,
      },
    };
    props.viewCommitmentListStart({
      query: saveViewCommitmentSearchQuery(queryFields, variables.data),
    });
    //earlier below code is there
    // props.viewCommitmentListStart({
    //   query: getViewCommitmentQuery(variables.data),
    //   variables,
    // });
    // to set the indexes incase we click go to page
    setFields({ ...fields, getPage: (fields.go - 1) });
    // }
    //this used for scroll the page on top when user landon this page
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  };

  const clearAllFilter = () => {
    let fieldsT = queryFields;
    fieldsT.name = "";
    fieldsT.gender = "Male";
    fieldsT.sportId = 0;
    fieldsT.gradId = 0;
    fieldsT.levelId = 0;
    fieldsT.athleteStateId = 0;
    fieldsT.teamStateId = 0;
    fieldsT.teamConferenceId = 0;
    fieldsT.commitmentStatusid = 0;
    fieldsT.athelteLevelid = 0;
    fieldsT.collegeid = 0;
    fieldsT.positionid = 0;
    fieldsT.collegeName = {};
    setQueryFields({
      ...fieldsT,
    });
    let datafields = fields;
    datafields.pageCount = 0;
    datafields.offset = 1;
    datafields.perPage = 10;
    datafields.page = 0;
    datafields.getPage = 0;
    datafields.go = 1;
    setFields({ ...datafields });
    const variables = {
      data: {
        offset: 0,
        limit: 10,
      },
    };
    props.viewCommitmentListStart({
      query: getViewCommitmentQuery(variables.data),
      variables,
    });
  }

  const count = props.viewCommitments.data && props.viewCommitments.data.data && props.viewCommitments.data.data.length;
  const viewCommitments =
    props.viewCommitments.data && props.viewCommitments.data;
  return (
    <div>
      <div className="pageContent">
        <div className="container">
          <div className="viewCommitment">
            <div className="flex">
              <div className="filterBtn">
                <button data-target="#sidebar" onClick={()=>setIsShowSlide(!isShowSlide)} data-toggle="modal">
                  Filters
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                    >
                      <g
                        id="Group_1865"
                        data-name="Group 1865"
                        transform="translate(-611 -109)"
                      >
                        <rect
                          id="Rectangle_481"
                          data-name="Rectangle 481"
                          width="18"
                          height="2"
                          rx="1"
                          transform="translate(611 111)"
                          fill="#3e3e3e"
                        />
                        <rect
                          id="Rectangle_482"
                          data-name="Rectangle 482"
                          width="18"
                          height="2"
                          rx="1"
                          transform="translate(611 118)"
                          fill="#3e3e3e"
                        />
                        <rect
                          id="Rectangle_483"
                          data-name="Rectangle 483"
                          width="18"
                          height="2"
                          rx="1"
                          transform="translate(611 125)"
                          fill="#3e3e3e"
                        />
                        <g
                          id="Ellipse_75"
                          data-name="Ellipse 75"
                          transform="translate(614 109)"
                          fill="#fff"
                          stroke="#3e3e3e"
                          strokeWidth="2"
                        >
                          <circle cx="3" cy="3" r="3" stroke="none" />
                          <circle cx="3" cy="3" r="2" fill="none" />
                        </g>
                        <g
                          id="Ellipse_76"
                          data-name="Ellipse 76"
                          transform="translate(614 123)"
                          fill="#fff"
                          stroke="#3e3e3e"
                          strokeWidth="2"
                        >
                          <circle cx="3" cy="3" r="3" stroke="none" />
                          <circle cx="3" cy="3" r="2" fill="none" />
                        </g>
                        <g
                          id="Ellipse_77"
                          data-name="Ellipse 77"
                          transform="translate(619 116)"
                          fill="#fff"
                          stroke="#3e3e3e"
                          strokeWidth="2"
                        >
                          <circle cx="3" cy="3" r="3" stroke="none" />
                          <circle cx="3" cy="3" r="2" fill="none" />
                        </g>
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
              <div className={isShowSlide ? "sidebar show" : "sidebar"}>
                {isShowSlide &&
                  <div className="closebtn-wrap">
                    <span className="closebtn" onClick={()=>setIsShowSlide(!isShowSlide)}>×</span>
                  </div>
                }
                <ul>
                  <li className="sideClearWrap">
                    <input
                      type="text"
                      name=""
                      placeholder="Athlete name"
                      onChange={(e) => handleSearch("name", e)}
                      value={queryFields?.name ? queryFields?.name : ""}
                    />
                    <div className="btnWrap">
                      <button className="btn outlined" onClick={() => clearAllFilter()}>Clear Filter</button>
                    </div>
                  </li>
                  <li>
                    <label htmlFor="">Gender</label>
                    <div className="fieldBox">
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Select
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(e) => handleSearch("gender", e)}
                            value={!!queryFields?.gender ? queryFields?.gender : ""}
                            label="Select"
                        >
                          {/*<MenuItem value={""}>Select</MenuItem>*/}
                          <MenuItem  value={'Male'}>Male</MenuItem>
                          <MenuItem  value={'Female'}>Female</MenuItem>
                        </Select>
                      </FormControl>
                      <div className="error">{errors["graduation"]}</div>
                    </div>
                  </li>
                  <li>
                    <label htmlFor="">All Sports</label>
                    <div className="fieldBox">
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={(e) => handleSearch("sportId", e)}
                          value={queryFields?.sportId ? queryFields?.sportId : ""}
                          label="Select"
                        >
                          <MenuItem value="0">
                            Select
                          </MenuItem>
                          {props.sportsList.data &&
                            props.sportsList.data.map((item, index) => {
                             if(!!queryFields?.gender){
                             if (queryFields?.gender == "Male" && item?.id == 1){
                               return <MenuItem key={index} value={item.id}>{item.sportName?.split(" ")?.[1] ?? "Error"}</MenuItem>
                             }
                             else if (queryFields?.gender == "Female" && item?.id == 4){
                               return <MenuItem key={index} value={item.id}>{item.sportName?.split(" ")?.[1] ?? "Error"}</MenuItem>
                             }
                             }
                             else{
                             return <MenuItem key={index} value={item.id}>{item.sportName?.split(" ")?.[1] ?? "Error"}</MenuItem>
                             }
                            })}
                        </Select>
                      </FormControl>
                      <div className="error">{errors["graduation"]}</div>
                    </div>
                  </li>
                  <li>
                    <label htmlFor="">Year</label>
                    <div className="fieldBox">
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={(e) => handleSearch("gradId", e)}
                          value={queryFields?.gradId ? queryFields?.gradId : ""}
                          label="Select"
                        >
                          <MenuItem value="0">
                            Select
                          </MenuItem>
                          {props.graduationYears.data &&
                            props.graduationYears.data.map((item, index) => (
                              <MenuItem key={index} value={item.id}>
                                {item.yearValue}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <div className="error">{errors["graduation"]}</div>
                    </div>
                  </li>
                  <li>
                    <label htmlFor="">Athlete State</label>
                    <div className="fieldBox">
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={(e) => handleSearch("athleteStateId", e)}
                          value={queryFields?.athleteStateId ? queryFields?.athleteStateId : ""}
                          label="Select"
                        >
                          <MenuItem value="0">
                            Select
                          </MenuItem>
                          {props.states.data &&
                            props.states.data.map((item, index) => (
                              <MenuItem key={index} value={item.id}>
                                {item.statename}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <div className="error">{errors["graduation"]}</div>
                    </div>
                  </li>
                  <li>
                    <label htmlFor="">
                      {/*Level*/}
                      Division
                    </label>
                    <div className="fieldBox">
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Select
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={(e) => handleSearch("athelteLevelid", e)}
                          value={queryFields?.athelteLevelid ? queryFields?.athelteLevelid : ""}
                          label="Select"
                        >
                          <MenuItem value="0">
                            Select
                          </MenuItem>
                          {allLevels ?
                            allLevels.map((item, index) => (
                              <MenuItem key={index} value={item.id}>
                                {item.currentlevelvalue}
                              </MenuItem>
                            )) : null}
                        </Select>
                      </FormControl>
                      <div className="error">{errors["graduation"]}</div>
                    </div>
                  </li>

                  <li>
                    <label htmlFor=""> Conference</label>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Select
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) => handleSearch("teamConferenceId", e)}
                        value={queryFields?.teamConferenceId ? queryFields?.teamConferenceId : ""}
                        label="Select"
                      >
                        <MenuItem value="0">
                          Select
                        </MenuItem>
                        {allConferences ?
                          allConferences.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.conferencenmae}
                            </MenuItem>
                          )) : null}
                      </Select>
                    </FormControl>
                  </li>
                  {/*below code is commented as we don't take team state at the time of commitment*/}
                  {getUserInfo() && getUserInfo().role !== "COLLEGECOUCHES" ? (
                    <li>
                      {/*<label htmlFor="">Team State</label>*/}
                      {/*<FormControl variant="outlined" fullWidth>*/}
                      {/*    <InputLabel id="demo-simple-select-outlined-label">*/}
                      {/*      Select*/}
                      {/*    </InputLabel>*/}
                      {/*    <Select*/}
                      {/*      labelId="demo-simple-select-outlined-label"*/}
                      {/*      id="demo-simple-select-outlined"*/}
                      {/*      onChange={(e) => handleSearch("teamStateId", e)}*/}
                      {/*      value={queryFields?.teamStateId ? queryFields?.teamStateId : ""}*/}
                      {/*      label="Select"*/}
                      {/*    >*/}
                      {/*      <MenuItem value="0">*/}
                      {/*           Select*/}
                      {/*      </MenuItem>*/}
                      {/*     {props.states.data &&*/}
                      {/*        props.states.data.map((item, index) => (*/}
                      {/*          <MenuItem key={index} value={item.id}>*/}
                      {/*            {item.statename}*/}
                      {/*          </MenuItem>*/}
                      {/*        ))}*/}
                      {/*    </Select>*/}
                      {/*  </FormControl>*/}
                    </li>

                  ) : null}
                  {getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ? (
                    <>
                      <li>
                        <label htmlFor=""> Division</label>
                        <div className="fieldBox">
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label">
                              Select
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              onChange={(e) => handleSearch("levelId", e)}
                              value={queryFields?.levelId ? queryFields?.levelId : ""}
                              label="Select"
                            >
                              <MenuItem value="0">
                                Select
                              </MenuItem>
                              {props.collegeDivision.data &&
                                props.collegeDivision.data.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    {item.collegedivisionname}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          <div className="error">{errors["graduation"]}</div>
                        </div>
                      </li>
                      <li>
                        <label htmlFor=""> Position</label>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="demo-simple-select-outlined-label">
                            Select
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(e) => handleSearch("positionid", e)}
                            value={queryFields?.positionid ? queryFields?.positionid : ""}
                            label="Select"
                          >
                            <MenuItem value="0">
                              Select
                            </MenuItem>
                            {allPrimaryPositions ?
                              allPrimaryPositions.map((item, index) => (
                                <MenuItem
                                  key={index} value={item.id}
                                >
                                  {item.selectionfield}
                                </MenuItem>
                              )) : null}
                          </Select>
                        </FormControl>
                      </li>
                      <li>
                        <label htmlFor=""> College</label>
                        <MuiAutoComplete
                          id="free-solo-demo"
                          freeSolo={false}
                          options={allColleges}
                          // disabled={props.previousData.length !== 0 || addOffers.collegeOfferId !== "" ? true : false}
                          getOptionLabel={(option) =>
                            option.collegename ? option.collegename : ""
                          }
                          value={queryFields.collegeName}
                          onChange={(event, newValue) => {
                            handleDropDownChange(
                              { target: { value: newValue } },
                              "collegeName"
                            );
                          }}
                          onChangeObj={(event, newValue) => {
                            handleChange({ target: { value: newValue } }, "collegeName");
                            handleSearch("collegeName", { target: { value: newValue } })

                          }}
                          inputPlaceholder="Search College"
                        />
                      </li>
                      <li>
                        <label htmlFor=""> Type of Commitment</label>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="demo-simple-select-outlined-label">
                            Select
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(e) => handleSearch("commitmentStatusid", e)}
                            value={queryFields?.commitmentStatusid ? queryFields?.commitmentStatusid : ""}
                            label="Select"
                          >
                            <MenuItem value="0">
                              Select
                            </MenuItem>
                            {allCommitmentTypes ?
                              allCommitmentTypes.map((item, index) => (
                                <MenuItem
                                  key={index} value={item.id}
                                >
                                  {item.commitmentstatusvalue}
                                </MenuItem>
                              )) : null}
                          </Select>
                        </FormControl>
                      </li>
                    </>
                  ) : null}
                </ul>
              </div>
              <div className="commitments">
                <div className="mainTitle">
                  <h1>
                    Commitments
                    {/*change as on 7/2/202*/}
                    {/*{getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ? "Coach Commitments" : "Commitments"}*/}
                  </h1>
                  <div className="count">
                    {/*below code is not updating so it has been changed*/}
                    {/*{count} */}
                    {viewCommitments?.data?.length > 0 ? viewCommitments?.data?.length.toLocaleString('en-US', {maximumFractionDigits:2}) : 0}&nbsp;
                    results</div>
                </div>
                {viewCommitments?.data?.length > 0 ?
                  <CommitmentList viewCommitments={viewCommitments} />
                  : <p>No Record Found</p>}

                <div className="searchDataSection commitmentPagination">
                  <div className="searchDataFlexBox">
                    <div className="searchDataBox">
                      <div className="dataSection">
                        <div className="paginationBox">
                          <div className="perPage">
                            <input
                              type="number"
                              // defaultValue={fields.perPage}
                              placeholder={'10'}
                              onChange={(e) => ((parseInt(e.target.value) <= 0) || (e.target.value == "")) ? console.log("invalid number") :
                                setFields({ ...fields, perPage: +e.target.value })
                              }
                              value={fields?.perPage}
                              style={{ textAlign: "center", width: "45px" }}
                            />
                            <label>Per Page</label>
                          </div>
                          <div className="pageNav">
                            <ReactPaginate
                              previousLabel={"<<"}
                              nextLabel={">>"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={fields.pageCountT || 0}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={4}
                              onPageChange={handlePageClick}
                              forcePage={fields.getPage}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                          <div className="goBox">
                            <input
                              placeholder={'1'}
                              style={{ width: "45px" }}
                              value={fields.go}
                              onChange={(e) => ((parseInt(e.target.value) <= 0) || (e.target.value == "")) ? console.log("inter valid number") :
                                setFields({ ...fields, go: +e.target.value })
                              }
                              type="number"
                            />
                            <a onClick={(e) => !!fields.go ? handleGoToPage(e) : swal({ text: "Please Select The Page", icon: "error" })}>
                              <span>Go </span>
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="4.568"
                                  height="7.99"
                                  viewBox="0 0 4.568 7.99"
                                >
                                  <defs>
                                    <style></style>
                                  </defs>
                                  <path
                                    className="a"
                                    d="M1.377,3.993,4.4.972a.569.569,0,0,0,0-.806.576.576,0,0,0-.809,0L.166,3.589a.57.57,0,0,0-.017.787l3.44,3.447A.571.571,0,0,0,4.4,7.017Z"
                                    transform="translate(4.568 7.99) rotate(180)"
                                  ></path>
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  console.log("state in commit", state)
  return {
    graduationYears: state.common.graduationYears,
    states: state.common.states,
    sportsList: state.common.allSportList,
    viewCommitments: state.athlete.profile.viewCommitments,
    allTeamList: state.athlete.editUserProfileData.teamsList,
    collegeDivision: state.athlete.profile.collegeDivision,
    collegeConferences: state.common.collegeConferences,
    commitmentType: state.common.commitmentStatus,
    schoolLevelList: state.athlete.editUserProfileData.academiclevelsList,
    primaryPosition: state.athlete.onBoarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    graduationYearsListStart: () => dispatch(graduationYearsListStart()),
    stateListStart: (data) => dispatch(stateListStart(data)),
    sportsListStart: (data) => dispatch(sportsListStart(data)),
    viewCommitmentListStart: (data) => dispatch(viewCommitmentListStart(data)),
    collegeDivisionListStart: (data) => dispatch(collegeDivisionListStart(data)),
    allTeamsListStart: (data) => dispatch(allTeamsListStart(data)),
    allCollegeConferencesListStart: (data) => dispatch(allCollegeConferencesListStart(data)),
    commitmentStatusListStart: (data) => dispatch(commitmentStatusListStart(data)),
    schoolLevelListStart: (data) => dispatch(allLevelsSchoolStart(data)),
    primaryListStart: (data) => dispatch(primaryListStart(data)),
    // this is use for all sport mens and woman
    allsportsListStart: () => dispatch(allsportsListStart()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewCommitments);
