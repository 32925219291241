export const getAllStatLinks = `
  query getAllAthleteStatLink($obj: JSON) {
    allAthleteStatLinks(where: $obj) {
      AthleteStatLinks {
        id
        link
        platform
      }
    }
  }
`;

export const createStatLink = `
  mutation createAthleteStatLink($obj: JSON) {
    AthleteStatLinkCreate(data: $obj) {
      id
      link
      platform
    }
  }
`;

export const updateStatLink = `
  mutation updateAthleteStatLink($where: JSON, $obj: JSON) {
    AthleteStatLinkUpsertWithWhere(where: $where, data: $obj) {
      id
      link
      platform
    }
  }
`;

export const deleteStatLink = `
  mutation updateAthleteStatLink($where: JSON, $obj: JSON) {
    AthleteStatLinkUpsertWithWhere(where: $where, data: $obj) {
      id
    }
  }
`;
