import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SAVE_STATS_MATRIX_DETAILS_START: "SAVE_STATS_MATRIX_DETAILS_START",
  SAVE_STATS_MATRIX_DETAILS_SUCCESS: "SAVE_STATS_MATRIX_DETAILS_SUCCESS",
  SAVE_STATS_MATRIX_DETAILS_FAILED: "SAVE_STATS_MATRIX_DETAILS_FAILED",
};

// ============================================ Actions ========================================================
export const saveStatsMatrixDetailsStart = (data) => ({
  type: actionType.SAVE_STATS_MATRIX_DETAILS_START,
  payload: data,
});

export const saveStatsMatrixDetailsSuccess = (data) => ({
  type: actionType.SAVE_STATS_MATRIX_DETAILS_SUCCESS,
  payload: data,
});

export const saveStatsMatrixDetailsFailed = (data) => ({
  type: actionType.SAVE_STATS_MATRIX_DETAILS_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function saveStatsMatrixDetailsReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.SAVE_STATS_MATRIX_DETAILS_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SAVE_STATS_MATRIX_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SAVE_STATS_MATRIX_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* saveStatsMatrixDetailsSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        saveStatsMatrixDetailsSuccess({
          saveStatMetricsDetails: response.data.data.saveStatmatrixdetails,
        })
      );
      // if (action.payload.successMsg) {
      //   messagePopup("", action.payload.successMsg, "success");
      // } else {
      //   messagePopup("", "Stat Saved Successfully", "success");
      // }
      if (action.payload.successUpdatedMsg) action.payload.successUpdatedMsg();
      if (action.payload.videoUploadedMsg) action.payload.videoUploadedMsg(response, action?.payload);
      if (action.payload.successMsg) action.payload.successMsg();
      if (action.payload.successDeletedMsg) action.payload.successDeletedMsg();
      if (action.payload.onCloseVideoDesc) action.payload.onCloseVideoDesc();
      if (action.payload.refreshAfterSave) action.payload.refreshAfterSave();
      if (action.payload.openSaveVideoAfterDataSave)
        action.payload.openSaveVideoAfterDataSave(response.data.data.saveStatmatrixdetails);
    } else {
      messagePopup("", "Stat Metrics Save failed", "error");
      yield put(saveStatsMatrixDetailsFailed("Stat Metrics Save failed"));
    }
  } catch (error) {
    messagePopup("", "Stat Metrics Save failed", "error");
    yield put(saveStatsMatrixDetailsFailed("Stat Metrics Save failed"));
  }
}
//Watcher
export function* watchSaveStatsMatrixDetailsSaga() {
  yield takeEvery(
    actionType.SAVE_STATS_MATRIX_DETAILS_START,
    saveStatsMatrixDetailsSaga
  );
}
