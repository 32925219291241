import { put, takeEvery } from "redux-saga/effects";
import { getAuthToken, getUserInfo } from "../../../services/authService";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
// import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  PROFILE_STATS_DATA_START: "PROFILE_STATS_DATA_START",
  PROFILE_STATS_DATA_SUCCESS: "PROFILE_STATS_DATA_SUCCESS",
  PROFILE_STATS_DATA_FAILED: "PROFILE_STATS_DATA_FAILED",
};

// ============================================ Actions ========================================================
export const profileStatsDataStart = (data) => ({
  type: actionType.PROFILE_STATS_DATA_START,
  payload: data,
});

export const profileStatsDataSuccess = (data) => ({
  type: actionType.PROFILE_STATS_DATA_SUCCESS,
  payload: data,
});

export const profileStatsDataFailed = (data) => ({
  type: actionType.PROFILE_STATS_DATA_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function profileStatsDataReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.PROFILE_STATS_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.PROFILE_STATS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.PROFILE_STATS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* profileStatsDataSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Publicprofilefieldpreviews/getAthletestats`,
      action.payload.data,
      "post",
      action.payload.data.isAccessToken ? true : false
    );
    if (response && response.status === 200) {
      let finalData = [];
      finalData = response?.data?.response?.data;
      yield put(
        profileStatsDataSuccess({
          statsData: finalData,
        })
      );
    } else {
      messagePopup("", "Stats Data failed", "error");
      yield put(profileStatsDataFailed("Stats Data failed"));
    }
  } catch (error) {
    messagePopup("", "Stats Data failed", "error");
    yield put(profileStatsDataFailed("Stats Data failed"));
  }
}

export function* watchProfileStatsDataSaga() {
  yield takeEvery(actionType.PROFILE_STATS_DATA_START, profileStatsDataSaga);
}
