import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_START: "FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_START",
  FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_SUCCESS: "FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_SUCCESS",
  FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_FAILED: "FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_FAILED"
};

// ============================================ Actions ========================================================

export const followerCollegeCoachActivityListStart = data => ({
  type: actionType.FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_START,
  payload: data
});

export const followerCollegeCoachActivityListSuccess = data => ({
  type: actionType.FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_SUCCESS,
  payload: data
});

export const followerCollegeCoachActivityListFailed = data => ({
  type: actionType.FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function follwerCollegeCoachActivityListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_START:
      return {
        ...state,
        loading: true
      };

    case actionType.FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* followerCollegeCoachActivityListSaga(action) {
  try {
    const response = yield fetchMethod(`/api/useracivities/coachActivityDetails`,action.payload.variables,'post',true);
    if (response && response.status === 200) {
      yield put(
        followerCollegeCoachActivityListSuccess({data: response.data.response.data})
      );
    } else {
      messagePopup("", "Follower College Coach Activity Api failed", "error");
      yield put(followerCollegeCoachActivityListFailed("Follower College Coach Activity Api failed"));
    }
  } catch (error) {
    messagePopup("", "Follower College Coach Activity Api failed", "error");
    yield put(followerCollegeCoachActivityListFailed("Follower College Coach Activity Api failed"));
  }
}

export function* watchFollowerCollegeCoachActivityListSaga() {
  yield takeEvery(actionType.FOLLOWER_COLLEGE_COACH_ACTIVITY_LIST_START, followerCollegeCoachActivityListSaga);
}