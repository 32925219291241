import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  SAVE_NOTIFICATIONS_START: "SAVE_NOTIFICATIONS_START",
  GET_NOTIFICATION_START: "GET_NOTIFICATION_START",
  NOTIFICATION_SUCCESS: "NOTIFICATION_SUCCESS",
  NOTIFICATION_FAILURE: "NOTIFICATION_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const saveNotificationStart = (data) => ({
  type: actionType.SAVE_NOTIFICATIONS_START,
  payload: data,
});

export const getNotificationStart = (data) => ({
  type: actionType.GET_NOTIFICATION_START,
  payload: data,
});

export const OnNotificationSuccess = (data) => ({
  type: actionType.NOTIFICATION_SUCCESS,
  payload: data,
});

export const OnNotificationFailure = (data) => ({
  type: actionType.NOTIFICATION_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function notificationReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_NOTIFICATIONS_START:
      return { ...state, loading: true };

    case actionType.GET_NOTIFICATION_START:
      return { ...state, loading: true };

    case actionType.NOTIFICATION_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.NOTIFICATION_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

export function* notificationsaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(OnNotificationSuccess(""));
      if (action.payload.getLatestList) {
        action.payload.getLatestList();
      }
    } else {
      messagePopup("", "Notification List failed", "error");
      yield put(OnNotificationFailure("Notification List failed"));
    }
  } catch (error) {
    messagePopup("", "Notification List failed", "error");
    yield put(OnNotificationFailure("Notification List failed"));
  }
}

function* getNotificationSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200) {
      const allNotifications =
        response &&
        response.data &&
        response.data.data &&
        response.data.data.allNotifications
          ? response.data.data.allNotifications
          : null;
      yield put(OnNotificationSuccess(allNotifications));
    } else {
      messagePopup("", "Notification List Failed", "error");
      yield put(OnNotificationFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Notification List Failed", "error");
    yield put(OnNotificationFailure("Failed, Error occured"));
  }
}

export function* watchNotification() {
  yield takeEvery(actionType.SAVE_NOTIFICATIONS_START, notificationsaveSaga);
  yield takeEvery(actionType.GET_NOTIFICATION_START, getNotificationSaga);
}
