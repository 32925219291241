import React, { useState } from "react";
import { useHistory } from "react-router";
import { Dialog } from "@mui/material";

import "../welcomeScreenMessage/assets/css/weclomeScreenModal.scss";
import WelcomeScreenCarousel from "./WelcomeScreenCarousel";

const WelcomeScreenMessage = () => {
  const history = useHistory();
  const [isModal, setModal] = useState(true);
  // const handleCloseModal = () => setModal(false);
  const closePopup = (e) => {
    e.preventDefault();
    setModal(false);
    history.push("/dashboard");
  };

  return (
    <div>
      {isModal && (
        <Dialog open={isModal}
            onClose={(event, reason)=> {
            if (reason == "backdropClick" || reason == "escapeKeyDown") {
                closePopup(event);
            }
            }}
        >
        <div className="welcomeModal">
          <div className="modalWrapper">
            <div className="modalContent">
              <button className="cross" onClick={closePopup}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="15.999"
                  viewBox="0 0 16 15.999"
                >
                  <g
                    id="close"
                    transform="translate(-6.776 -6.777)"
                    opacity="0.6"
                  >
                    <path
                      id="Path_1"
                      data-name="Path 1"
                      d="M8.6,7.09A1.069,1.069,0,1,0,7.089,8.6l6.175,6.175L7.089,20.95A1.069,1.069,0,1,0,8.6,22.462l6.175-6.174,6.175,6.175a1.069,1.069,0,1,0,1.512-1.512l-6.175-6.175L22.462,8.6A1.069,1.069,0,1,0,20.95,7.09l-6.175,6.175L8.6,7.089Z"
                    />
                  </g>
                </svg>
              </button>

              <WelcomeScreenCarousel />
            </div>
          </div>
        </div>
        </Dialog>
      )}
    </div>
  );
};

export default WelcomeScreenMessage;
