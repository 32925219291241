import { put, takeEvery } from "redux-saga/effects";
import history from "../../../services/history";
import { fetchGraphMethod, fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
import swal from "sweetalert";
import { setAuthToken, setUserInfo } from "../../../services/authService";

// ========================================== Action Type =====================================================
export const actionType = {
    CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
    PASSWORD_CHANGE_SAVE_START: "PASSWORD_CHANGE_SAVE_START",
};

// ============================================ Actions ========================================================
export const matchChangePasswordStart = (data) => ({
    type: actionType.CHANGE_PASSWORD_START,
    payload: data,
});

export const matchChangePasswordSuccess = (data) => ({
    type: actionType.CHANGE_PASSWORD_SUCCESS,
    payload: data,
});

export const matchChangePasswordFailed = (data) => ({
    type: actionType.CHANGE_PASSWORD_FAILED,
    payload: data,
});
export const passwordChangesaveStart = (data) => ({
    type: actionType.PASSWORD_CHANGE_SAVE_START,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function matchChangePasswordReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.CHANGE_PASSWORD_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        case actionType.PASSWORD_CHANGE_SAVE_START:
            return {
                ...state,
                loading: true,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* matchChangePasswordSaga(action) {
    try {
        const response = yield fetchMethod(
            "/api/Userdata/resetPassword",
            action.payload.variables,
            action.payload.method,
            false
        );

        if (response && response.data && response.data.response && response.data.response.statusCode == 200) {
            if (response.data.response?.token && response.data.response?.token.length) {
                setAuthToken(response.data.response.token)
            }
            if(response?.data?.response?.data){
                setUserInfo(response.data.response.data);
                setAuthToken(response.data.response.data.token);
            }
            action.payload.nextScreen();
            yield put(matchChangePasswordSuccess(response.data.response.msg));
        } else {
            messagePopup("" ,!!response?.data?.response?.msg ? response?.data?.response?.msg
               : "Something went wrong." , "error");
            yield put(matchChangePasswordFailed("Something went wrong."));
        }
    } catch (error) {
        messagePopup("", "Something went wrong.", "error");
        yield put(matchChangePasswordFailed("Something went wrong."));
    }
}
export function* passwordChangeSaveSaga(action) {
    const response = yield fetchGraphMethod(
        action.payload.query,
        action.payload.variables,
        true
    );

    if (response && response.status === 200) {
        if (response && response.data && response.data.errors) {
            messagePopup("", response.data.errors[0].message, "error");
        } else {
            swal({
                text: "Password changed successfully.Please login with your new password",
                icon: "success",
                dangerMode: true,
            }).then((okay) => {
                if (okay) {
                    action.payload.handleLogout();
                }
            });
        }
    } else {
        messagePopup("", "Change Password failed", "error");
        yield put(matchChangePasswordFailed("Change Password failed"));
    }
}

//Watcher
export function* watchChangePasswordSaga() {
    yield takeEvery(actionType.CHANGE_PASSWORD_START, matchChangePasswordSaga);
    yield takeEvery(actionType.PASSWORD_CHANGE_SAVE_START, passwordChangeSaveSaga);
}
