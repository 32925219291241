import { combineReducers } from "redux";
import rootViewCommitmentsReducer from "./viewCommitment/rootViewCommitmentsReducer";
import rootCollegesReducer from "./colleges/rootCollegesReducer";
import rootHomeReducer from "./homepage/rootHomeReducer";
import rootUpgradePlansReducers from "./upgradePlans/rootUpgradePlansReducers";
import rootPaymentReducers from "./payment/rootPaymentReducers";
import rootResourcesReducer from "./resources/rootResourcesReducer";
import rootMessageReducer from "./message/rootMessageReducer";
import rootDashboardCountReducer from "./dashboardCount/rootDashboardCountReducer";
import rootMessageReplyReducer from './messageReply/rootMessageReplyReducer';
import rootComposeMessageReducer from "./composeMessage/rootComposeMessageReducer";
import rootNotificationReducer from "./notification/rootNotificationReducer";

export default combineReducers({
  colleges: rootCollegesReducer,
  home: rootHomeReducer,
  commitments: rootViewCommitmentsReducer,
  upgradePlans: rootUpgradePlansReducers,
  payment: rootPaymentReducers,
  resources: rootResourcesReducer,
  message: rootMessageReducer,
  dashboardCount: rootDashboardCountReducer,
  messageReply: rootMessageReplyReducer,
  composeMessage: rootComposeMessageReducer,
  notification: rootNotificationReducer,
});
