import React, { useEffect, useState } from 'react';
import './assets/css/evaluation-greenrope.scss';
import ClaimProfileHeader from '../auth/claimProfile/claimProfileStep1/ClaimProfileHeader';
import DashboardHeader from '../../layouts/header/DashboardHeader';
import { getAuthToken } from '../../../services/authService';
import NCAA2IMG02 from './assets/img/ncaa-logo-02.png';
import NAIAIMG from './assets/img/naia-logo.png';
import NJCAAIMG01 from './assets/img/njcaa-logo01.png';
import CCCAA from './assets/img/cccaa-logo01.png';
import AthleteTestimonials from '../../common/AthleteTestimonials';
import CoachTestimonials from '../../common/CoachTestimonials';

export const WebFormLP = (props) => {
  const [headerType, setHeaderType] = useState(true);
  useEffect(() => {
    getStatus(props);
    //for scrolling the page on top
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);
  const getStatus = (props) => {
    if (!!getAuthToken()) {
      setHeaderType(true);
    } else {
      setHeaderType(true);
    }
  };
  return (
    <>
      {headerType ? <ClaimProfileHeader /> : <DashboardHeader />}
      <section className="evaluation-greenrope-sec">
        <div className="container">
          <div className="top-block">
            <div className="title">College Recruiting Assessment</div>
            <div className="short-text">
              <p>
                We are in active communication with hundreds of NCAA, NAIA, and
                JC college coaches each month. We scout, advise, and advocate
                for a select group of qualified college prospects that range
                from mid to high-level NCAA DI and D2, to high academic D3
                level, to smaller NAIA, and Junior College prospects.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sfwebfrom-brand-lp-sec">
        <div className="container">
          <div className="brand-listing">
            <div className="item">
              <img src={NCAA2IMG02} alt="" />
            </div>
            <div className="item">
              <img src={NAIAIMG} alt="" />
            </div>
            <div className="item">
              <img src={NJCAAIMG01} alt="" />
            </div>
            <div className="item">
              <img src={CCCAA} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="sfwebfrom-interview-survey-sec">
        <div className="container">
          <div className="top-block">
            <div className="sec-title">
              Complete our Scouting Questionnaire for our
              <br /> Coaching Staff to Evaluate You.
            </div>
          </div>
          <div className="survey-form-box">
            {/*{htmlString}*/}
            <iframe
              src={'https://cdn.sfrglobal.com/newUpdateWebFormFinal.html'}
              height={'800px'}
              width={'100%'}
            ></iframe>
          </div>
          {/*<div className="fieldBox text-center">*/}
          {/*    <button className="btn">Submit</button>*/}
          {/*</div>*/}
        </div>
      </section>

      <AthleteTestimonials />
      <CoachTestimonials whiteBackground />
    </>
  );
};
