import React from 'react';
import logoH from "../../../../layouts/assets/Img/newLogo.png";

function ClaimProfileHeaderCoach() {
    return (
        <div>
             <section className="headerSection">
        <div className="logoPart">
            <a href={void 0}>
                <img src={logoH} alt="logo"/>
            </a>
        </div>
    </section>
        </div>
    )
}

export default ClaimProfileHeaderCoach
