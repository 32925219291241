export const getSchoolDetailsQuery = `
query AllAthleteschools($obj:JSON)
{
  allAthleteschools(where:$obj)
  {
    totalCount
    Athleteschools
    {
      id
      highschoolname
      isverified
      city
      islatest
      state
      typeid
      athleteschooltypeidmaprel
      {
        Schoolteamtypes
        {
          name
          type
        }
      }
    }
  }
}
`;

export const getLatestSchoolQuery = `
query allAthleteschools($obj:JSON)
{
  allAthleteschools(where:$obj)
  {
    Athleteschools
    {
      id
      islatest
      highschoolname
      active
    }
  }
}
`;

export const filterSchoolNameQuery = `
query filter($obj:JSON){
    allAthleteschools(where:$obj){
        Athleteschools{
            id
            highschoolname
      }
    }
  }
`;

export const saveSchoolQuery = `
mutation SaveAthleteschool($obj:AthleteschoolInput!){
  saveAthleteschool(obj:$obj){
    id
    highschoolname
     city
     state
     typeid
  }
}
`;

export const saveTeamQuery = `
mutation saveTeam($obj:TeamInput!)
{
  saveTeam(obj:$obj)
  {
    id
  }
}
`;

export const getSchoolAndTeamTypesQuery = `
query allSchoolteamtypes($where: JSON){
  allSchoolteamtypes(where:$where){
      Schoolteamtypes{
                        id
                        name
                        type
                     }
                    }
    }
`;

export const getLatestTeam = `
query allTeams($obj:JSON)
{
  allTeams(where:$obj)
  {
    totalCount
    Teams
    {
      id
      islatest
      active
      teamname
      teamlogo
    }
  }
}    
`;
export const getTeamQuery = `

query allTeams($obj:JSON){
  allTeams(where:$obj)
  {
    
    Teams
    {
      id
      teamname
      teamlogo
      isverified
      teamtypeid
      city
      state
      islatest
      
      teamteamtypeidmaprel
      {
        Schoolteamtypes
        {
          id
          type
          name
        }
      }
    }
  }

}
`;

export const filterTeamSchoolNameQuery = `
query allTeams($where:JSON){
  allTeams(where:$where){
    Teams{
      id
      teamname
      teamlogo
      state
      city
      teamteamtypemaprel{
        Teamtypes{
        id
          teamtypename
        }
      }
    }
  }
}`;

export const getSchoolTeamUserMappingForTeams = `
query AllSchoolteamusermappings($obj:JSON)
{allSchoolteamusermappings(where:$obj){
    Schoolteamusermappings{
      id
      active
      userid
      teamid
      schoolid
      primaryteamstatus
      secondaryteamstatus
      coatchid
      createdby
      schoolteamusermappingteamidmaprel{
        Teams{
          id
          teamname
          teamlogo
          city
      		state
          isverified
          islatest
          teamteamtypeidmaprel
      {
        Schoolteamtypes
        {
          id
          type
          name
        }
      }
        }
      }
      schoolteamusermappingschoolidmaprel{
        Athleteschools{
          id
          highschoolname
          state
          city
          isverified
          islatest
          athleteschoolschooltypeidmaprel{
            Highschooltypes{
              id
              type
            }
          }
        }
      }
      schoolteamusermappingcoatchidmaprel {
        Coachandteamdetails {
          id
          isverifiedcoach
          coachfirstname
          coachlastname
          coachemail
          createdby
          coachandteamdetailscoachtypeidmaprel{
            Coachtypes{
              coachtypename
            }
          }
          coachandteamdetailsonboardingidmaprel{
            Onboardings{
              profileimages
            }
          }
        }
      }
    }
  }}
`;

export const getSchoolTeamUserMappingForCoaches = `
query AllSchoolteamusermappings($obj:JSON)
{allSchoolteamusermappings(where:$obj){
    Schoolteamusermappings{
      id
      teamid
      schoolid
      coatchid
      createdby
      primaryteamstatus
      secondaryteamstatus
      createdby
      schoolteamusermappingcoatchidmaprel{
        Coachandteamdetails{
          id
          coachfirstname
          coachlastname
          coachemail
          createdby
          createdon
          coachandteamdetailscoachtypeidmaprel{
            Coachtypes{
              coachtypename
            }
          }
        }
      }
    }
  }}
`;

export const saveSchoolTeamMapping = `
mutation SaveSchoolteamusermapping($obj:SchoolteamusermappingInput!){
  saveSchoolteamusermapping(obj:$obj){
    id
  }
}
`;

export const updateSchoolTeamMapping = `
mutation SchoolteamusermappingUpdateAll($where:JSON,$data:JSON){
  SchoolteamusermappingUpdateAll(where:$where,data:$data)
}
`;
