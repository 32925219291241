export const getAllBasicDataQuery = `
query allAthletes($obj: JSON)
{
  allAthletes(where: $obj)
    {
      Athletes
  {
    athletefirstname,
    athletelastname,
    gender,
    verifieduser,
    athletesports
    id,
    athleteathletesportsmaprel{
      Athletesports{
        sportname
      }
    }
    athleteathletegraduationyearmaprel
    {
      Graduationyears
      {
        id
        yearvalue
      }
    },
    onBoardingUserIdMap
    {
      Onboardings
      {
        id
        weightgpa,
        highscoolstatus,
        dateofbirth,
        profileimages,
        gpascale,
        unweightedgpa
        aboutme,
        classrank1,
        yearvarsity,
        outof,
        top
        credits
        currentlevelofschool,
        onboardinggpascalevaluemaprel
        {
          Gpascales
          {
            id
            gpascalevalue
          }
        },
        onboardinggradvaluemaprel
        {
          Grades
          {
            id
            gradevalue
          }
        }
      }
    }
  }
    }
}
`;

export const saveBasicUserProfileUpdateQuery = `
mutation saveAthlete($obj: AthleteInput!){
  saveAthlete(obj:$obj){
    id
  }
}`;

export const saveOnboardingUpdateQuery = `
mutation saveOnboarding($obj: OnboardingInput!){
  saveOnboarding(obj: $obj){
  id
  }
}

`;

export const getAllPress = `
query allPresses($obj: JSON){
  allPresses(where: $obj){
      Presses{
        createdby
        createdon
        date
        filename
        id
        link
        title
        updatedby
        updatedon
        userid
        userId
        pressrank
        photovideoid
        uploadedvideoandphotoPressidMap{
        Uploadedvideoandphotos{
          id
          userid
          uploadedrank
          isphotosorvideoslik
          videophotosuploadedtype
        }
      }
    }
  }
}
`;

export const savePressCreate = `
mutation savePress($obj: PressInput!){
  savePress(obj: $obj){
    id
  }
}`;

export const savePressUpdateAll = (id) => `
mutation{
  PressUpdateAll(where:{id: ${id}},data:{active:0})
}`;

export const deletePressItem = `
mutation PressDeleteById($id: JSON){
  PressDeleteById(id: $id) 
}`;

export const saveGpaMappingQuery = `
mutation saveGpamapping($obj: GpamappingInput!) {
  saveGpamapping(obj: $obj){
    id
  }
}
`;

export const getGpaMappingQuery = `
query allGpamappings($obj: JSON){
  allGpamappings(where: $obj){
    Gpamappings {
      id
      userid
      gpa
      gparangevalueid
      weightedgpa
    }
  }
}
`;

export const saveAllVidPhotoQuery = `
mutation saveUploadedvideoandphoto($obj: UploadedvideoandphotoInput!) {
  saveUploadedvideoandphoto(obj: $obj){
    id
  }
}
`;
export const getAllVarsityQuery = `
{
  allVarsities{
    Varsities{
      id
      varsityname
    }
    totalCount
  }
}
`