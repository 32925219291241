import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_ATHLETES_DETAILS_START: "ALL_ATHLETES_DETAILS_START",
  ALL_ATHLETES_DETAILS_SUCCESS: "ALL_ATHLETES_DETAILS_SUCCESS",
  ALL_ATHLETES_DETAILS_FAILED: "ALL_ATHLETES_DETAILS_FAILED",
};

// ============================================ Actions ========================================================
export const athletesDetailsStart = (data) => ({
  type: actionType.ALL_ATHLETES_DETAILS_START,
  payload: data,
});

export const athletesDetailsSuccess = (data) => ({
  type: actionType.ALL_ATHLETES_DETAILS_SUCCESS,
  payload: data,
});

export const athletesDetailsFailed = (data) => ({
  type: actionType.ALL_ATHLETES_DETAILS_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function athletesDetailsReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ALL_ATHLETES_DETAILS_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_ATHLETES_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_ATHLETES_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* athletesDetailsSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const userData = response.data.data.allAthletes.Athletes
        ? response.data.data.allAthletes.Athletes[0]
        : null;
      let tempUserinfo = userData ? userData : {};
      let onboardingData = null;

      const user = tempUserinfo
        ? tempUserinfo.athleteuseridmaprel
          ? tempUserinfo.athleteuseridmaprel.Userdata
            ? tempUserinfo.athleteuseridmaprel.Userdata[0]
            : ""
          : ""
        : "";

      const sportsName = tempUserinfo
        ? tempUserinfo.athleteathletesportsmaprel
          ? tempUserinfo.athleteathletesportsmaprel.Athletesports
            ? tempUserinfo.athleteathletesportsmaprel.Athletesports[0].sportname
            : ""
          : ""
        : "";

      const graduationYear = tempUserinfo
        ? tempUserinfo.athleteathletegraduationyearmaprel
          ? tempUserinfo.athleteathletegraduationyearmaprel.Graduationyears
            ? tempUserinfo.athleteathletegraduationyearmaprel.Graduationyears[0]
                .yearvalue
            : ""
          : ""
        : "";
      onboardingData = tempUserinfo
        ? tempUserinfo.onBoardingUserIdMap
          ? tempUserinfo.onBoardingUserIdMap.Onboardings
            ? tempUserinfo.onBoardingUserIdMap.Onboardings[0]
            : null
          : null
        : null;
      const followers = tempUserinfo
        ? tempUserinfo.followersFollowingIdMap
          ? tempUserinfo.followersFollowingIdMap.totalCount
          : 0
        : 0;
      const followings = tempUserinfo
        ? tempUserinfo.followersFollowerIdMap
          ? tempUserinfo.followersFollowerIdMap.totalCount
          : 0
        : 0;

      if (tempUserinfo.athleteuseridmaprel) {
        delete tempUserinfo.athleteuseridmaprel;
      }
      if (tempUserinfo.followersFollowingIdMap) {
        delete tempUserinfo.followersFollowingIdMap;
      }
      if (tempUserinfo.followersFollowerIdMap) {
        delete tempUserinfo.followersFollowerIdMap;
      }
      if (tempUserinfo.athleteathletegraduationyearmaprel) {
        delete tempUserinfo.athleteathletegraduationyearmaprel;
      }
      if (tempUserinfo.athleteathletesportsmaprel) {
        delete tempUserinfo.athleteathletesportsmaprel;
      }
      if (tempUserinfo.onBoardingUserIdMap) {
        delete tempUserinfo.onBoardingUserIdMap;
      }
      
      tempUserinfo["sportsName"] = sportsName;
      tempUserinfo["graduationYear"] = graduationYear;
      tempUserinfo["followers"] = followers;
      tempUserinfo["followings"] = followings;
      tempUserinfo["email"] = user.email;
      tempUserinfo["emailverified"] = user.emailverified;
      tempUserinfo["username"] = user.username;
      tempUserinfo["role"] = user.role;

      yield put(
        athletesDetailsSuccess({ athletesData: tempUserinfo, onboardingData })
      );
    } else {
      messagePopup("", "User List failed", "error");
      yield put(athletesDetailsFailed("User List failed"));
    }
  } catch (error) {
    messagePopup("", "User List failed", "error");
    yield put(athletesDetailsFailed("User List failed"));
  }
}

export function* watchAthletesDetailsSaga() {
  yield takeEvery(actionType.ALL_ATHLETES_DETAILS_START, athletesDetailsSaga);
}
