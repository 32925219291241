import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_PARENT_ATHLETE_LIST_START: "GET_PARENT_ATHLETE_LIST_START",
  GET_PARENT_ATHLETE_LIST_SUCCESS: "GET_PARENT_ATHLETE_LIST_SUCCESS",
  GET_PARENT_ATHLETE_LIST_FAILURE: "GET_PARENT_ATHLETE_LIST_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const getParentAthleteListStart = (data) => ({
  type: actionType.GET_PARENT_ATHLETE_LIST_START,
  payload: data,
});

export const getParentAthleteListSuccess = (data) => ({
  type: actionType.GET_PARENT_ATHLETE_LIST_SUCCESS,
  payload: data,
});

export const getParentAthleteListFailure = (data) => ({
  type: actionType.GET_PARENT_ATHLETE_LIST_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function parentAthleteListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_PARENT_ATHLETE_LIST_START:
      return { ...state, loading: true };

    case actionType.GET_PARENT_ATHLETE_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.GET_PARENT_ATHLETE_LIST_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* getParentAthleteListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200) {
      const athleteparentmappings =
        response?.data?.data?.allAthleteparents?.Athleteparents[0]
          ?.athleteparentmappingmappingParentIdMap?.Athleteparentmappings;
          const parentData = response?.data?.data?.allAthleteparents?.Athleteparents;
      let finalData = [];
      if (athleteparentmappings) {
        for (const data of athleteparentmappings) {
          const athleteData =
            data?.athleteparentmappingathleteidmaprel?.Athletes[0];
          if (athleteData) {
            finalData = [
              ...finalData,
              {
                id: athleteData.id,
                fullname:
                  (athleteData.athletefirstname
                    ? athleteData.athletefirstname
                    : "") +
                  " " +
                  (athleteData.athletelastname
                    ? athleteData.athletelastname
                    : ""),
                isRemoved: athleteData?.athleteuseridmaprel?.Userdata.length ? athleteData?.athleteuseridmaprel?.Userdata[0]['issoftdeleted'] : 0    
              },
            ];
          }
        }
      }
      yield put(getParentAthleteListSuccess([finalData, ...parentData]));
    } else {
      messagePopup("", "Fetch Parent Athlete list Failed", "error");
      yield put(getParentAthleteListFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch Parent Athlete list Failed", "error");
    yield put(getParentAthleteListFailure("Failed, Error occured"));
  }
}

export function* watchParentAthleteList() {
  yield takeEvery(
    actionType.GET_PARENT_ATHLETE_LIST_START,
    getParentAthleteListSaga
  );
}
