import {fetchMethod} from "../services/httpService";

export const sendVideoPhotoNotification = () => {
    fetchMethod(`/api/Notificationadvisorsettings/sendnotificationtoadvisor`, {
        userId: JSON.parse(localStorage.getItem("user")).id, 
        title: "videoupload", 
        role: JSON.parse(localStorage.getItem("user")).role, 
        msgrecieverids:[]
    }, "post", true).then(res => {

    })
}
export const sendStatsNotification = () => {
    fetchMethod(`/api/Notificationadvisorsettings/sendnotificationtoadvisor`, {
        userId: JSON.parse(localStorage.getItem("user")).id, 
        title: "statsupload", 
        role: JSON.parse(localStorage.getItem("user")).role, 
        msgrecieverids:[]
    }, "post", true).then(res => {

    })
}
export const sendAddCommitmentNotification = (collageName) => {
    fetchMethod(`/api/Notificationadvisorsettings/sendnotificationtoadvisor`, {
        userId: JSON.parse(localStorage.getItem("user")).id, 
        title: "addcommitment", 
        role: JSON.parse(localStorage.getItem("user")).role, 
        msgrecieverids:[],
        collegename: collageName
    }, "post", true).then(res => {

    })
}
export const sendRemoveCommitmentNotification = (collageName) => {
    fetchMethod(`/api/Notificationadvisorsettings/sendnotificationtoadvisor`, {
        userId: JSON.parse(localStorage.getItem("user")).id, 
        title: "removecommitment", 
        role: JSON.parse(localStorage.getItem("user")).role, 
        msgrecieverids:[],
        collegename: collageName
    }, "post", true).then(res => {

    })
}
export const sendAddOfferNotification = (collageName) => {
    fetchMethod(`/api/Notificationadvisorsettings/sendnotificationtoadvisor`, {
        userId: JSON.parse(localStorage.getItem("user")).id, 
        title: "addoffer", 
        role: JSON.parse(localStorage.getItem("user")).role, 
        msgrecieverids:[],
        collegename: collageName
    }, "post", true).then(res => {

    })
}
export const sendRemoveOfferNotification = (collageName) => {
    fetchMethod(`/api/Notificationadvisorsettings/sendnotificationtoadvisor`, {
        userId: JSON.parse(localStorage.getItem("user")).id, 
        title: "removeoffer", 
        role: JSON.parse(localStorage.getItem("user")).role, 
        msgrecieverids:[],
        collegename: collageName
    }, "post", true).then(res => {

    })
}
export const sendAddSignedCommitmentNotification = (collageName) => {
    fetchMethod(`/api/Notificationadvisorsettings/sendnotificationtoadvisor`, {
        userId: JSON.parse(localStorage.getItem("user")).id, 
        title: "addsignedcommitment", 
        role: JSON.parse(localStorage.getItem("user")).role, 
        msgrecieverids:[],
        collegename: collageName
    }, "post", true).then(res => {

    })
}
export const sendRemoveSignedCommitmentNotification = (collageName) => {
    fetchMethod(`/api/Notificationadvisorsettings/sendnotificationtoadvisor`, {
        userId: JSON.parse(localStorage.getItem("user")).id, 
        title: "removesignedcommitment", 
        role: JSON.parse(localStorage.getItem("user")).role, 
        msgrecieverids:[],
        collegename: collageName
    }, "post", true).then(res => {

    })
}


