import React, { useEffect, useState } from "react";
import "./assets/css/account.scss";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  getUserInfo,
  logout,
} from "../../../../../../services/authService";

import { passwordChangesaveStart } from "../../../../../../redux/auth/forgotpassword/ChangePassword";
import { savePasswordChangeQuery } from "./PasswordChangeQuery";
import AccountSettingLeftNavigation from "./AccountSettingLeftNavigation";
import { alphaNumericValidation } from "../../../../../../utils/validationUtils";

const PasswordChange = (props) => {
  const [changePasswordData, setChangePasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const inputHandler = (field, e) => {
    setErrors({});
    let fieldsT = changePasswordData;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setChangePasswordData({ ...fieldsT });
  };

  const formvalidation = () => {
    let errorsT = errors;
    let formIsValid = true;

    if (changePasswordData.currentPassword === "") {
      formIsValid = false;
      errorsT["currentPassword"] = "Please enter current password.";
    } else if (
      changePasswordData.currentPassword == changePasswordData.newPassword &&
      changePasswordData.currentPassword ===
      changePasswordData.confirmNewPassword
    ) {
      formIsValid = false;
      errorsT["newPassword"] =
        "New password and confirm password should not be same as current password.";
    }

    let regularExp = new RegExp(
      /^(?=.*\d)(?=.*[a-zA-Z])/
    );
    let blankSpaceValidation = stringVal => {
      return /\s/g.test(stringVal);
    };
    if (changePasswordData.newPassword == "") {
      formIsValid = false;
      errorsT["newPassword"] = "Please enter new password.";
      errorsT["confirmNewPassword"] = "";
    }
    else if (changePasswordData.newPassword.length < 6) {
      formIsValid = false;
      errorsT["newPassword"] = "Password must be at least 6 characters.";
    }
    else if (changePasswordData.newPassword.length > 32) {
      formIsValid = false;
      errorsT["newPassword"] = "Maximum length should be 32 characters.";
    }
    else if (regularExp.test(changePasswordData.newPassword) == false && changePasswordData.newPassword !== "") {
      formIsValid = false;
      errorsT["newPassword"] =
        "Password must contain 1 alphabetical letter and 1 numeral.";
    }
    if (changePasswordData.newPassword === getUserInfo().Email) {
      formIsValid = false;
      errorsT["newPassword"] = "Email id can’t be set as your password.";
    }
    if (blankSpaceValidation(changePasswordData.newPassword)) {
      formIsValid = false;
      errorsT["newPassword"] = "Blank spaces are not allowed"
    }
    if (changePasswordData.confirmNewPassword == "") {
      formIsValid = false;
      errorsT["confirmNewPassword"] = "Please enter confirm password.";
    } else if (
      changePasswordData.newPassword.localeCompare(
        changePasswordData.confirmNewPassword
      ) != 0 && changePasswordData.newPassword !== ""
    ) {
      formIsValid = false;
      errorsT["confirmNewPassword"] =
        "New password and confirm new password must be same.";
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
    };
  };

  const handleLogout = () => {
    logout();
  };

  const saveChangePassword = async (event) => {
    event.preventDefault();
    const validateData = await formvalidation();
    if (validateData.formIsValid == true) {
      props.passwordChangesaveStart({
        query: savePasswordChangeQuery,
        variables: {
          id: getUserInfo() ? getUserInfo().athleteLoggedInId : null,
          oldPassword: changePasswordData.currentPassword,
          newPassword: changePasswordData.newPassword,
        },
        handleLogout: handleLogout,
      });
    } else {
    }
  };
  //this for scroll the page on the top
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1500)
  }, [])

  return (
    <div>
      <section className="account-main-sec">
        <div className="container">
          <div className="account-with-sidebar">
            <AccountSettingLeftNavigation />
            <div className="account-data-box">
              <h1 className="title">Change Password</h1>
              <div className="account-form-box">
                <form>
                  <div className="form-group">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Email Id
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      id="password"
                      className="text-field"
                      fullWidth
                      disabled={true}
                      value={getUserInfo().Email}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Current Password
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      id="password"
                      placeholder="Current Password*"
                      type={showCurrentPassword ? "text" : "password"}
                      className="text-field"
                      fullWidth
                      name="password"
                      value={changePasswordData.currentPassword || ""}
                      onChange={(e) => {
                        inputHandler("currentPassword", e);
                      }}
                      onBlur={(e) => {
                        inputHandler("currentPassword", e);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleShowCurrentPassword()}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large">
                              {showCurrentPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                    {errors.currentPassword != "" ? (
                      <div className="error">{errors.currentPassword}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <InputLabel htmlFor="outlined-adornment-password">
                      New Password
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      id="password"
                      placeholder="New Password*"
                      type={showNewPassword ? "text" : "password"}
                      className="text-field"
                      fullWidth
                      name="password"
                      value={changePasswordData.newPassword || ""}
                      onChange={(e) => {
                        inputHandler("newPassword", e);
                      }}
                      onBlur={(e) => {
                        inputHandler("newPassword", e);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleShowNewPassword()}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large">
                              {showNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                    {errors.newPassword != "" ? (
                      <div className="error">{errors.newPassword}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Confirm New Password
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      id="password"
                      placeholder="Confirm New Password*"
                      type={showConfirmPassword ? "text" : "password"}
                      className="text-field"
                      fullWidth
                      name="password"
                      value={changePasswordData.confirmNewPassword || ""}
                      onChange={(e) => {
                        inputHandler("confirmNewPassword", e);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleShowConfirmPassword()}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large">
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                    {errors.confirmNewPassword != "" ? (
                      <div className="error">{errors.confirmNewPassword}</div>
                    ) : null}
                  </div>

                  <div className="form-group mb-0">
                    <button
                      className="btn green"
                      type="button"
                      onClick={saveChangePassword}
                    >
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    passwordChangesaveStart: (data) => dispatch(passwordChangesaveStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
