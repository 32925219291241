import { put, takeEvery } from "redux-saga/effects";

import { fetchGraphMethod, fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SEND_EMAIL_START: "SEND_EMAIL_START",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILED: "SEND_EMAIL_FAILED",
};

// ============================================ Actions ========================================================
export const sendEmailStart = (data) => ({
  type: actionType.SEND_EMAIL_START,
  payload: data,
});

export const sendEmailSuccess = (data) => ({
  type: actionType.SEND_EMAIL_SUCCESS,
  payload: data,
});

export const sendEmailFailed = (data) => ({
  type: actionType.SEND_EMAIL_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function sendEmailReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SEND_EMAIL_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SEND_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* sendEmailSaga(action) {
  try {
    const response = yield fetchMethod(
      "/api/Userdata/sendForgotMail",
      action.payload.variables,
      action.payload.method,
      false
    );
    let mailResponse = response.data.response;
    if (mailResponse && mailResponse.status == 200) {
      yield put(
        sendEmailSuccess({
          sendEmail: response.data,
        })
      );
      action.payload.nextScreen(response);
    }
    else if(mailResponse && mailResponse.status == 201 && mailResponse.data && mailResponse.data[0] && mailResponse.data[0].verifiedUserStatus == 0){
      messagePopup("", `${mailResponse.error}`, "");
    }else if(mailResponse && mailResponse.status == 201){
      messagePopup("", `${mailResponse.msg}`, "warning");
      action.payload.removeAthleteScreen(response);
    }
     else {
      messagePopup("", "Send Email failed", "error");
      yield put(sendEmailFailed("Send Email failed"));
      if (!!action?.payload?.isForgotPass){
        action.payload.nextScreen(response);
      }
    }
  } catch (error) {
    messagePopup("", "Send Email failed", "error");
    yield put(sendEmailFailed("Send Email failed"));
    if (!!action?.payload?.isForgotPass){
      action.payload.nextScreen(400);
    }
  }
}

//Watcher
export function* watchSendEmailSaga() {
  yield takeEvery(actionType.SEND_EMAIL_START, sendEmailSaga);
}
