export const getViewCommitmentQuery = (variable) => `
mutation {
        CollegecommitmentSearchByFilters (offset: ${variable.offset} , limit :${variable.limit} )
}
`;

export const getCollegeDivisionQuery = `
query{
  allCollegedivisions{
    Collegedivisions{
      id
      collegedivisionname
    }
  }
}
`;

export const getCollegeLevelQuery = `
/colleges/details/
`;

export const getSchoolTeamsState = `
query AllTeams($obj:JSON)
{
  allTeams(where:$obj)
  {
    Teams
    {
      id
      state
    }
  }
}`;

export const allCollegeConferencesQuery = `
query allCollegeconferences{
  allCollegeconferences{
    Collegeconferences{
      id
      conferencenmae
    }
  }
}
`

export const saveViewCommitmentSearchQuery = (queryFields, variable) => `
mutation {
        CollegecommitmentSearchByFilters(name : "${queryFields.name}" , gender : "${queryFields?.gender}", sportId : ${queryFields.sportId} , gradId : ${queryFields.gradId} , athleteStateId: ${queryFields.athleteStateId} ,levelId: ${queryFields.levelId}, teamStateId :${queryFields.teamStateId}, positionid : ${queryFields.positionid}, commitmentStatusid : ${queryFields.commitmentStatusid},teamConferenceId : ${queryFields.teamConferenceId}, athelteLevelid: ${queryFields.athelteLevelid}, collegeid : ${queryFields.collegeid}, offset: ${variable.offset} , limit :${variable.limit} )   
}
`;

export const isfavCollege = `
query allUsercolleges($where : JSON){
  allUsercolleges(where: $where){
    Usercolleges{
      id
    }
  }
}
`
