import { combineReducers } from "redux";
import notificationReducer from "./notificationList";
import notificationUnreadListReducer from "./notificationUnreadCount";
import parentNotificationListReducer from "./parentNotification"


export default combineReducers({
    notificationList: notificationReducer,
    notificationUnreadCount: notificationUnreadListReducer,
    parentNotificationMaterList: parentNotificationListReducer
});
