import React from 'react';
import '../assets/css/account.scss';
import {Link} from 'react-router-dom'

function LeftNavigation() {
  const route = !!window.location.href ? window.location.href?.split("/") : []
    return (
      
      <div className="account-sidebar">
        <ul>
          <li className={`${route[route?.length - 1] ==  "manageAccess" ? "active " : ""}`}>
            <Link to="/manageAccess">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.355"
                  height="20.342"
                  viewBox="0 0 20.355 20.342"
                >
                  <path
                    id="key-outline_1_"
                    data-name="key-outline (1)"
                    d="M55.914,53.545a5.255,5.255,0,0,0,.191,1.74c-2.005,2.354-7.3,8.575-7.793,9.05a.938.938,0,0,0-.312.7,1.552,1.552,0,0,0,.447.991c.307.321,1.619,1.535,1.861,1.3.717-.7.861-.884,1.154-1.173.442-.433-.047-1.317.107-1.675a.679.679,0,0,1,.582-.484,8.7,8.7,0,0,1,1.1.14,1.164,1.164,0,0,0,.884-.428.979.979,0,0,0,.4-.726c.009-.419-.6-.972-.144-1.414s1.1.288,1.582.233A1.757,1.757,0,0,0,57.1,60.794c.06-.284-.544-1.014-.451-1.428a.971.971,0,0,1,.53-.512c.214-.047,1.163.321,1.377.275a3.271,3.271,0,0,0,.81-.484,5.24,5.24,0,0,0,2.219.437,5.545,5.545,0,1,0,0-11.082A5.575,5.575,0,0,0,55.914,53.545Zm8.463-1.078a1.489,1.489,0,1,1-1.489-1.489A1.489,1.489,0,0,1,64.378,52.467Z"
                    transform="translate(-47.5 -47.5)"
                    fill="none"
                    stroke="#333"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </svg>
              </span>
              Manage Access
            </Link>
          </li>
          <li className={`${route[route?.length - 1] == "membership" ? "active " : ""}`}>
            <Link to="/membership">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="19"
                  height="17"
                  viewBox="0 0 19 17"
                >
                  <g
                    id="Mask_Group_32"
                    data-name="Mask Group 32"
                    transform="translate(-118.154 -72.924)"
                    clipPath="url(#clip-path)"
                  >
                    <g
                      id="person-add-outline"
                      transform="translate(119.504 73.578)"
                    >
                      <path
                        id="Path_375"
                        data-name="Path 375"
                        d="M98.978,27.333c.143,1.954,1.607,3.547,3.213,3.547s3.073-1.593,3.213-3.547a3.229,3.229,0,1,0-6.426,0Z"
                        transform="translate(-95.18 -23.786)"
                        fill="none"
                        stroke="#333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_376"
                        data-name="Path 376"
                        d="M54.577,150.643c3.177,0,6.4,1.774,7,5.121a.619.619,0,0,1-.571.791H48.151a.619.619,0,0,1-.571-.791C48.176,152.416,51.4,150.643,54.577,150.643Z"
                        transform="translate(-47.566 -141.184)"
                        fill="none"
                        stroke="#333"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_377"
                        data-name="Path 377"
                        d="M17.9,87.214v4.138m-2.045-2.069h4.09"
                        transform="translate(-3.588 -82.485)"
                        fill="none"
                        stroke="#333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              Membership
            </Link>
          </li>
        </ul>
      </div> 
    )
}

export default LeftNavigation
