export const getResourceLibraryQuery = `
query allResoursselibraries($where : JSON, $first: Int, $last:Int){
  allResourselibraries(where: $where, first: $first, last: $last){
    totalCount
    Resourselibraries{
      id
      active
      linkname
      description
    }
  }
}
`;
