import { all, fork } from "redux-saga/effects";
import { watchComposeMessageListSaga } from "./composeMessage";
import { watchCoachDropdownListSaga } from './coachDropdown';
import { watchAthleteParentDropdownListSaga } from './athleteParentDropdown';
import { watchGetComposeByIdListSaga } from "./getComposeHomeMessage";

export default function* rootComposeMessageSagas() {
    yield all([
       fork(watchComposeMessageListSaga),
       fork(watchCoachDropdownListSaga),
       fork(watchAthleteParentDropdownListSaga),
       fork(watchGetComposeByIdListSaga)
    ])
}