import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_LEVELS_SCHOOL_START: "ALL_LEVELS_SCHOOL_START",
  ALL_LEVELS_COLLEGE_START: "ALL_LEVELS_COLLEGE_START",
  ALL_LEVELS_SCHOOL_SUCCESS: "ALL_LEVELS_SCHOOL_SUCCESS",
  ALL_LEVELS_SCHOOL_FAILED: "ALL_LEVELS_SCHOOL_FAILED",
};

// ============================================ Actions ========================================================
export const allLevelsSchoolStart = (data) => ({
  type: actionType.ALL_LEVELS_SCHOOL_START,
  payload: data,
});
export const allLevelsCollegeStart = (data) => ({
  type: actionType.ALL_LEVELS_COLLEGE_START,
  payload: data,
});
export const allLevelsSchoolSuccess = (data) => ({
  type: actionType.ALL_LEVELS_SCHOOL_SUCCESS,
  payload: data,
});

export const allLevelsSchoolFailed = (data) => ({
  type: actionType.ALL_LEVELS_SCHOOL_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allLevelsSchoolReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ALL_LEVELS_SCHOOL_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_LEVELS_COLLEGE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_LEVELS_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_LEVELS_SCHOOL_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* allLevelsCollegeListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allLevelsSchoolSuccess({
          allCollegeLevels: response.data.data.allCollegelevels,
        })
      );
    } else {
      messagePopup("", "College Level List failed", "error");
      yield put(allLevelsSchoolFailed("College Level List failed"));
    }
  } catch (error) {
    messagePopup("", "College Level List failed", "error");
    yield put(allLevelsSchoolFailed("College Level List failed"));
  }
}

export function* allLevelsSchoolListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allLevelsSchoolSuccess({
          allSchoolcurrentlevels: response.data.data.allSchoolcurrentlevels,
        })
      );
    } else {
      messagePopup("", "School Level List failed", "error");
      yield put(allLevelsSchoolFailed("School Level List failed"));
    }
  } catch (error) {
    messagePopup("", "School Level List failed", "error");
    yield put(allLevelsSchoolFailed("School Level List failed"));
  }
}

//Watcher
export function* watchAllLevelsListSaga() {
  yield takeEvery(
    actionType.ALL_LEVELS_COLLEGE_START,
    allLevelsCollegeListSaga
  );
  yield takeEvery(actionType.ALL_LEVELS_SCHOOL_START, allLevelsSchoolListSaga);
}
