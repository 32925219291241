import { combineReducers } from "redux";
import loginReducer from "./login/login";
import CoachloginReducer from "./login/coachLogin";
import signupReducer from "./signup/signup";
import searchUserReducer from "./signup/claimProfile";
import userClaimProfileReducer from "./signup/userClaimProfile";
import matchOtpReducer from "./signup/matchOtp";
import chaeckEmailReducer from "./signup/checkEmailExist";
import previewProfileReducer from "./signup/previewProfile";
import allUserDataReducer from "./forgotpassword/forgotPassword";
import sendEmailReducer from "./forgotpassword/sendEmail";
import matchForgotOtpReducer from "./forgotpassword/matchForgotOtp"
import changePasswordReducer from "./forgotpassword/ChangePassword"
export default combineReducers({
  login: loginReducer,
  Coachlogin: CoachloginReducer,
  signup: signupReducer,
  searchUser: searchUserReducer,
  claimProfile: userClaimProfileReducer,
  matchOtp: matchOtpReducer,
  chaeckEmail: chaeckEmailReducer,
  previewProfile: previewProfileReducer,
  userData: allUserDataReducer,
  sendEmail: sendEmailReducer,
  matchForgotOtp: matchForgotOtpReducer,
  changePassword: changePasswordReducer,
});
