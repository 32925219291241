import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod } from "../../../services/httpService"
import messagePopup from "../../../utils/messagePopup"
import {getCollegeLevelQuery} from "../../../components/views/athlet/dashboard/viewCommitments/viewCommitmentsQueries";

// ========================================== Action Type =====================================================
export const actionType = {
    COMMITMENT_STORY_START: 'COMMITMENT_STORY_START',
    COMMITMENT_STORY_SUCCESS: 'COMMITMENT_STORY_SUCCESS',
    COMMITMENT_STORY_FAILED: 'COMMITMENT_STORY_FAILED'
}

// ============================================ Actions ========================================================
export const commitmentStoryStart = (data) => ({
    type: actionType.COMMITMENT_STORY_START,
    payload: data
})

export const commitmentStorySuccess = (data) => ({
    type: actionType.COMMITMENT_STORY_SUCCESS,
    payload: data
})

export const commitmentStoryFailed = (data) => ({
    type: actionType.COMMITMENT_STORY_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function commitmentStoryReducer(state = INIT_STATE,action){
    switch(action.type){
        case actionType.COMMITMENT_STORY_START:
            return {
                ...state,
                loading: true
            }
        case actionType.COMMITMENT_STORY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        case actionType.COMMITMENT_STORY_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        default: return {...state}
    }
}

// ===================================================== side effect =============================================

export function* commitmentStorySaga(action){
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true);
        if(response && response.status === 200){
            const commitmentStoryList = response.data && response.data.data
            yield put(commitmentStorySuccess(commitmentStoryList));
            if(action.payload.nextRoute) action.payload.nextRoute();
        } else {
            messagePopup('','Commitment Story List failed','error')
            yield put(commitmentStoryFailed('College Level List failed'))
        }
    } catch (error) {
        messagePopup('',' Commitment Story List failed','error')
        yield put(commitmentStoryFailed('Commitment Story List failed'))
    }
}

export function* watchCommitmentStorySaga(){
    yield takeEvery(actionType.COMMITMENT_STORY_START, commitmentStorySaga)
}