export const getAllRegionsQuery = `
  query allRegions($where: JSON){
    allRegions(where: $where){
      Regions{
        id
        regionname
        stateregionidMap{
          States{
            id
            statename
            regionid
          }
        }
        regionstateregionstateMap{
          Regionstates{
            id
            statename
            regionid
          }
        }
      }
    }
  }
  `
export const getAllTutionCostsQuery = `
  query allTutioncosts{
    allTutioncosts{
      Tutioncosts{
        id
        name
        
      }
    }
  }
  `
  export const collegeSizesQuery = `
  query allCollegesizes{
    allCollegesizes{
      Collegesizes{
        id 
        name
      }
    }  
    }`

    export const academicSelectivitiesQuery = `
    query allAcadamicselectivities{
      allAcadamicselectivities{
        Acadamicselectivities{
          id
          name
        }
      }
    }
    `
    export const allCollegeSettingsQuery= `
    query allCollegesettings{
      allCollegesettings{
        Collegesettings{
          id
          name
        }
      }
    }
    `
    export const getAreaOfStudiesQuery = `
    query allAreaofstudies{
      allAreaofstudies{
        Areaofstudies{
          id
          areaofstudyname
        }
      }
    }
    `

export const getAllSearchFiltersQuery = `
query allSearchfilters($obj: JSON) {
      allSearchfilters(where: $obj) {
        totalCount
        Searchfilters{
          active
          id
          filtername
          filteronsearch
          serchfiltermappingserachfilterIdMap{
            Serchfiltermappings{
              appliedfiltername
              appliedfiltervalue
            }
          }
        }
      }
  }
  `;

export const getAllAreaofstudyQuery = `
  query allAreaofstudies{
    allAreaofstudies{
      Areaofstudies{
        areaofstudyname
        id
      }
    }
  } 
  `;

export const saveSearchFilterQuery = `
mutation saveSearchfilter($obj: SearchfilterInput!){
  saveSearchfilter(obj: $obj){
    active
    filtername
    filteronsearch
    id
    searchcondition
    userid
  }
} 
`;

export const saveViewCollegeQuery = `
mutation saveViewcollege($obj : ViewcollegeInput!){
  saveViewcollege(obj :$obj){
    id
  }
}`

export const getCollegesListQuery = `
  query allUsercolleges($obj: JSON){
    allUsercolleges(where: $obj){
        Usercolleges{
            id
            userid
            active
            collegeid
            collegerank
            isfav
            collegeinterestlevel
      }
    }
  } 
  `;
