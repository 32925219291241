import React from "react";
import { useState, useEffect } from "react";
import "./css/notification.css";
import { Menu, MenuItem, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import {connect} from 'react-redux';
import messagePopup from "../../../../../utils/messagePopup";
import toastr from "toastr";
import {universityApiData, athleteParentQuery, coachApiData, AddUserActivity, saveMessageApi, saveMessageReplyApi, getMessageQuery, checkFollowApiData} from './MessageQuery';
import { messageCountListStart } from '../../../../../redux/athlete/dashboard/message/messageCount';
import {coachDropdownStart} from '../../../../../redux/athlete/dashboard/composeMessage/coachDropdown';
import {checkFollowDropdownStart} from '../../../../../redux/athlete/dashboard/message/checkFollowStatus';
import {athleteParentDropdownStart} from '../../../../../redux/athlete/dashboard/composeMessage/athleteParentDropdown';
import {composeMessageListStart, saveMessageReplyListStart} from '../../../../../redux/athlete/dashboard/composeMessage/composeMessage'
const ITEM_HEIGHT = 48;

const ComposeMessage = (props) => {
    let history = useHistory();
    const [anchorE1, setAnchorE1] = React.useState(null);
  const open = Boolean(anchorE1),
  [coachCount, setCoachCount] = useState(""),
  [advisorCount, setAdvisorCount] = useState(""),
  [subject, setSubject] = useState(undefined),
  [message, setMessage] = useState(undefined),
  [universityid, setUniersityId] = useState(undefined),
  [universityData, setUniversityData] = useState([]),
  [coachid, setCoachId] = useState(undefined),
  [toName, setToName] = useState({}),
  [allowMsg, setAllowMsg] = useState(0),
  [parentData, setParentData] = useState([]),
  [coachData, setCoachData] = useState([]),
  [supportCount, setSupportCount] = useState("");
  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };
  const handleCoachMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/coach/composeMessage" });
  }
  const handleAdvisorMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/advisor/composeMessage" });
  }
  const handleSupportMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/support/composeMessage" });
  }
  const handleSelect = e => {
    if(e.target.name === 'universityid'){
        setUniersityId(e.target.value);
        let data = universityData.filter(item=> item.id === parseFloat(e.target.value));
        if(data[0].allowtosendmsg === 1){
        getCoachDataDetails(e.target.value);
        setAllowMsg(data[0].allowtosendmsg);
        }
        else{
          setAllowMsg(data[0].allowtosendmsg)
        }
        setToName({});
        setCoachId('');
        setCoachData([])
    }
    else{
        if(e.target.value){
        let data = coachData.filter(item=> item.coachuserid === parseFloat(e.target.value));
        if(data?.length > 0){
        checkFollowCoachApi(data[0].id);
        setAllowMsg(data[0].isathletesendmsgtocoach);
        setToName(data);
        setCoachId(e.target.value);
        }
        }
    }
  }

 const checkFollowCoachApi = value => {
  props.checkFollowDropdownStart({
    query: checkFollowApiData,
    variables: {
      where: {
        active: 1,
        order: "id desc",
        followingid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
        followercoachid: parseFloat(value)
      }
    }
  });
 }

  const getCoachDataDetails = value => {
    props.coachDropdownStart({
      query: coachApiData,
      variables: {
        where: {
          active: 1,
          order: "id desc",
          collegeid: parseFloat(value)
        }
      }
    });
  }
  const sendClickBtn = (e) => {
    e.preventDefault();
      if(toName?.length > 0){
      if(subject){
        if(message){
          props.saveMessageReplyListStart({
            query: saveMessageApi,
            variables: {
              obj: {
               active: 1,
               message: message,
               isread: 0,
               messagesendby: parseFloat(JSON.parse(localStorage.getItem('user')).id),
               messagesendtocoachid: toName?.length > 0 ? parseFloat(toName[0].id) : 0,
               subject: subject,
               userrole: 'COLLEGECOUCHES'
            }
            },
            nextScreen: (data)=>{
              props.saveMessageReplyListStart({
                query: saveMessageReplyApi,
                variables: {
                  obj: {
                    msgid: parseFloat(data?.saveMessage?.id),
                    msg: message,
                    msgreceivercoachid: toName?.length > 0 ? parseFloat(toName[0].id) : 0,
                    msgsenderid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
                    collegeid: parseFloat(universityid),
                    subject: subject,
                    active: 1,
                    parentnamevalue: JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN' && parentData?.length > 0 ? parentData[0].name : null,
                    sendmsgrolevalue: JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN' ? 'Parent/Guardian' : null
                }
                },
                nextScreen: (data)=>{
                  props.saveMessageReplyListStart({
                    query: AddUserActivity,
                    variables: {
                      obj: {
                        messageid: parseFloat(data?.saveMessagereply?.id),
                        userid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
                        coachid: toName?.length > 0 ? parseFloat(toName[0].id) : 0,
                    }
                    },
                    nextScreen: (data)=>{
                      // messagePopup("", "Message send successfully", "success");
                      toastr.success("Message send successfully", "", {
                        progressBar : true,timeOut: 2000,
                        closeButton: true, positionClass: "toast-top-full-width",
                        showEasing: "swing", fadeIn: 40000,
                        preventDuplicates: true,
                      })
                        history.push('/message/coach');
                    }
                  });
                }
              });
            }
          });
        }
        else{
        // messagePopup("", "Please enter message", "warning")
        toastr.warning("Please enter message", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
        }
      }
      else{
        // messagePopup("", "Please enter subject", "warning")
        toastr.warning("Please enter subject", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      }
    }
    else{
        // messagePopup("", "Please specify atleast one recipient", "warning")
        toastr.warning("Please specify atleast one recipient", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
    }
  }

  useEffect(()=>{
    getUniversityData();
},[]);

const getUniversityData = () => {
  props.composeMessageListStart({
    query: universityApiData,
    variables: {
      where: {
        active: 1,
        order: "id desc"
      }
    }
  });
  props.messageCountListStart({
    query: getMessageQuery,
    variables: {
      where:{
            order: 'id desc',
            messagesendto: parseFloat(JSON.parse(localStorage.getItem("user")).id),
            active: 1, 
            istrash: 1,
        }
    }
  });  
  if(JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN'){
    props.athleteParentDropdownStart({
      query: athleteParentQuery,
      variables: {
        where:{
              order: 'id desc',
              userid: parseFloat(JSON.parse(localStorage.getItem("user")).athleteLoggedInId),
              active: 1,
          }
      }
    });  
  }
 }

 useEffect(()=>{
  if(props?.composeMessageList?.data?.data?.allColleges?.Colleges?.length > 0){
    setUniversityData(props?.composeMessageList?.data?.data?.allColleges?.Colleges);
  }
 },[props?.composeMessageList?.data?.data]);

 useEffect(()=> {
  if(props?.coachDropdown?.data?.data?.allCoachandteamdetails?.Coachandteamdetails?.length > 0){
    let data = props?.coachDropdown?.data?.data?.allCoachandteamdetails?.Coachandteamdetails.map(item=> {
      return {id: item.id, userid: item.userid, coachuserid: item.coachuserid, isathletesendmsgtocoach: item.isathletesendmsgtocoach, name: item.coachfirstname ? item.coachfirstname.concat(item.coachlastname ?" " + item.coachlastname : "") : ""}
    })
    setCoachData(data);
  }
 }, [props?.coachDropdown?.data?.data])

 useEffect(()=>{
  let coachData = [];
  let supportData = [];
  let advisorData = [];
  let data = props?.messageCountList?.data?.data?.length > 0 ? props?.messageCountList?.data?.data?.map(item=>{
    if(item.userrole === 'ADVISORS'){
      advisorData.push(item);
  }
  else if(item.userrole === 'ADMIN'){
      supportData.push(item)
  }
  else if(item.userrole === 'COLLEGECOUCHES'){
      coachData.push(item);
  }
  }) : [];
  setCoachCount(coachData && coachData.length ? coachData.filter(item => item.isread === 0).length : "");
  setAdvisorCount(advisorData && advisorData.length ? advisorData.filter(item => item.isread === 0).length : "");
  setSupportCount(supportData && supportData.length ? supportData.filter(item => item.isread === 0).length : "");
},[props.messageCountList.data]);

useEffect(()=>{
if(props?.checkFollowStatus?.data?.totalCount && props?.checkFollowStatus?.data?.totalCount > 0){
  setAllowMsg(1)
}
else{
  setAllowMsg(0)
}
},[props.checkFollowStatus.data])

useEffect(()=>{
if(JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN'){
  if(props?.athleteParentDropdown?.data?.data?.length > 0){
    let data = props?.athleteParentDropdown?.data?.data?.map(item=>{
      return {id: item.id, name: item.parentfirstname.concat(item.parentlastname ? ' ' + item.parentlastname : '')}
    });
    setParentData(data)
  }
}
},[props.athleteParentDropdown.data])

  return (
    <div className="pageContent">
        <div className="container">
            <div className="notification-with-sidebar">
                <nav className="notification-sidebar">
                    <ul>
                        <li className="button-link dropdown">
                         <Button
                          className="btn lightblue dropdown-toggle"
                          onClick={e => handleClick(e)}
                          >
                           Compose
                          </Button>
                          <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button"
                  }}
                  anchorEl={anchorE1}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch"
                    }
                  }}
                >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  <MenuItem onClick={handleCoachMenu}>Coach</MenuItem>
                  <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem>
                  <MenuItem onClick={handleSupportMenu}>Support</MenuItem>
                </Menu>
             </li>
             <li onClick={() => history.push({ pathname: "/message/coach"})}>
                <div className = "link-item">
                  <span>Coaches</span> <span>{coachCount ? coachCount : ""}</span>
                </div>
              </li>
              <li onClick={() => history.push({ pathname: "/message/advisor"})}>
                <div className = "link-item">
                  <span>Advisor</span> <span>{advisorCount ? advisorCount : ""}</span>
                </div>
              </li>
              <li onClick={() => history.push({ pathname: "/message/support"})}>
                <div className = "link-item">
                  <span>Support</span> <span>{supportCount ? supportCount : ""}</span>
                </div>
              </li>
              <li onClick={() => history.push({ pathname: "/message/trash"})}>
                <div  className = "link-item">
                  <span>Trash</span>
                </div>
              </li>
                    </ul>
                </nav>
                <div className="notification-detail-box">
                    <div className="notification-search-box">
                        <div className="search-box">
                            <h1>New Message</h1>
                        </div>
                    </div>
                    <div className="notification-messageDetail">
                        <div className="message-hereInfo newMessage">
                            <div className="message-replyInfo">
                                <form>
                                    <div className="form-group flexbox d-block">
                                        <div className="two-column-flex">
                                            <div className="half">
                                                <label>University</label>
                                                <select
                                                 name="universityid"
                                                 value={universityid ? universityid : ""} 
                                                 onChange = {e=> handleSelect(e)}>
                                                 <option value="">Select</option>
                                                 {universityData?.length > 0 ? universityData?.map((ed,ex)=>{
                                                  return <option value={ed.id} key={ex}>{ed.collegename}</option>
                                                  }):<option value="">No Result</option>}
                                                 </select>
                                            </div>
                                            <div className="half">
                                                <label>Coach</label>
                                                <select
                                                 name="coachid"
                                                 value={coachid ? coachid : ""} 
                                                 onChange = {e=> handleSelect(e)}>
                                                 <option value="">Select</option>
                                                 {coachData?.length > 0 ? coachData?.map((ed,ex)=>{
                                                  return <option value={ed.coachuserid} key={ex}>{ed.name}</option>
                                                  }):<option value="">No Result</option>}
                                                 </select>
                                            </div>
                                        </div>
                                       {!allowMsg ? toName?.length > 0 ? <p className="error">Your are not allowed to send message to {`"${toName[0].name}"`}. Please contact your <span>admin</span></p> : 
                                       universityid ? <p className="error">Your are not allowed to send message to selected university. Please contact your <span>admin</span></p> : ''
                                       : ""}
                                    </div>
                                    <div className="form-group flexbox">
                                        <label>To:</label>
                                        {toName?.length > 0 && allowMsg ? <span className="toName">{toName[0].name}</span> : ''}
                                    </div>
                                    <div className="form-group flexbox">
                                        <label>Subject:</label>
                                        <input 
                                        type="text" 
                                        value={subject ? subject : ""} 
                                        disabled={!allowMsg ? true : false}
                                        onChange={(e)=>{setSubject(e.target.value)}}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Message</label>
                                        <textarea 
                                        placeholder="Enter Your message here..." 
                                        value={message ? message : ""} 
                                        disabled={!allowMsg ? true : false}
                                        onChange={(e)=>setMessage(e.target.value)}>
                                        </textarea>
                                    </div>
                                    <div className="form-group">
                                        <button 
                                        type="submit" 
                                        className="btn blue" 
                                        disabled={!allowMsg ? true : false}
                                        onClick={(e)=>{sendClickBtn(e)}}>Send</button>
                                    </div>
                                </form>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    composeMessageList: state.athlete.dashboard.composeMessage.composeMessageList,
    coachDropdown: state.athlete.dashboard.composeMessage.coachDropdown,
    messageCountList: state.athlete.dashboard.message.messageCountList,
    checkFollowStatus: state.athlete.dashboard.message.checkFollowStatus,
    athleteParentDropdown: state.athlete.dashboard.composeMessage.athleteParentDropdown
  };
};

const mapDispatchToProps = dispatch => {
    return {
      composeMessageListStart: data => dispatch(composeMessageListStart(data)),
      coachDropdownStart: data => dispatch(coachDropdownStart(data)),
      saveMessageReplyListStart: data => dispatch(saveMessageReplyListStart(data)),
      messageCountListStart: data => dispatch(messageCountListStart(data)),
      checkFollowDropdownStart: data => dispatch(checkFollowDropdownStart(data)),
      athleteParentDropdownStart: data => dispatch(athleteParentDropdownStart(data)) 
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(ComposeMessage);