import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  FOLLOWER_ATHLETE_VIEW_COUNT_START: "FOLLOWER_ATHLETE_VIEW_COUNT_START",
  FOLLOWER_ATHLETE_VIEW_COUNT_SUCCESS: "FOLLOWER_ATHLETE_VIEW_COUNT_SUCCESS",
  FOLLOWER_ATHLETE_VIEW_COUNT_FAILED: "FOLLOWER_ATHLETE_VIEW_COUNT_FAILED"
};

// ============================================ Actions ========================================================

export const followerAthleteViewCountStart = data => ({
  type: actionType.FOLLOWER_ATHLETE_VIEW_COUNT_START,
  payload: data
});

export const followerAthleteViewCountSuccess = data => ({
  type: actionType.FOLLOWER_ATHLETE_VIEW_COUNT_SUCCESS,
  payload: data
});

export const followerAthleteViewCountFailed = data => ({
  type: actionType.FOLLOWER_ATHLETE_VIEW_COUNT_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function follwerAthleteViewCountReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.FOLLOWER_ATHLETE_VIEW_COUNT_START:
      return {
        ...state,
        loading: true
      };

    case actionType.FOLLOWER_ATHLETE_VIEW_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.FOLLOWER_ATHLETE_VIEW_COUNT_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* followerAthleteViewCountSaga(action) {
  try {
    const response = yield fetchMethod(`/api/useracivities/followersViews`,action.payload.variables,'post',true);
    if (response && response.status === 200) {
      yield put(
        followerAthleteViewCountSuccess({data: response.data.response.data})
      );
    } else {
      messagePopup("", "Follower View Api failed", "error");
      yield put(followerAthleteViewCountFailed("Follower View Api failed"));
    }
  } catch (error) {
    messagePopup("", "Follower View Api failed", "error");
    yield put(followerAthleteViewCountFailed("Follower View Api failed"));
  }
}

export function* watchFollowerAthleteViewCountSaga() {
  yield takeEvery(actionType.FOLLOWER_ATHLETE_VIEW_COUNT_START, followerAthleteViewCountSaga);
}
