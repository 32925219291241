import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup"
import toastr from "toastr";

// ========================================== Action Type =====================================================
export const actionType = {
    OFFERS_LIST_START: 'OFFERS_LIST_START',
    OFFERS_LIST_SAVE_START: 'OFFERS_LIST_SAVE_START',
    OFFERS_LIST_SUCCESS: 'OFFERS_LIST_SUCCESS',
    OFFERS_LIST_FAILED: 'OFFERS_LIST_FAILED',
    ALL_OFFERS_LIST_SUCCESS: 'ALL_OFFERS_LIST_SUCCESS',
    ALL_OFFERS_LIST_FAILED: 'ALL_OFFERS_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const offersListStart = (data) => ({
    type: actionType.OFFERS_LIST_START,
    payload: data
})

export const offersListsaveStart = (data) => ({
    type: actionType.OFFERS_LIST_SAVE_START,
    payload: data
})

export const offersListSuccess = (data) => ({
    type: actionType.OFFERS_LIST_SUCCESS,
    payload: data
})

export const offersListFailed = (data) => ({
    type: actionType.OFFERS_LIST_FAILED,
    payload: data
})
export const allOffersListSuccess = (data) => ({
    type: actionType.OFFERS_LIST_SUCCESS,
    payload: data
})

export const allOffersListFailed = (data) => ({
    type: actionType.OFFERS_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function offersListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.OFFERS_LIST_START:
            return {
                ...state,
                loading: true
            }
        case actionType.OFFERS_LIST_SAVE_START:
            return {
                ...state,
                loading: true
            }
        case actionType.OFFERS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        case actionType.OFFERS_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        case actionType.ALL_OFFERS_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        case actionType.ALL_OFFERS_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        default: return { ...state }
    }
}

// ===================================================== side effect =============================================

export function* offersListSaveSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        const offersResponse = response.data.data.saveOffer;
        if (response && response.status === 200) {
            if(action.payload.listOfAllOffers) action.payload.listOfAllOffers();
            if(offersResponse && offersResponse.active === 0) {
              if(action.payload.closeOfferModal)  action.payload.closeOfferModal();
              if(!action.payload.noSuccessMsg){
                //   messagePopup("", "Offer deleted successfully.", "success");
                toastr.success("Offer deleted successfully..", "", {
                    progressBar : true,timeOut: 2000,
                    closeButton: true, positionClass: "toast-top-full-width",
                    showEasing: "swing", fadeIn: 40000,
                    preventDuplicates:true,
                  })
              }else{
                  // console.log("noSuccessMsg");
              }
            }
            if(action.payload.nextScreen){
                action.payload.nextScreen(response.data);
            }
            yield put(offersListSuccess(''))
        } else {
            messagePopup('', 'OFFERS List failed', 'error')
            yield put(offersListFailed('OFFERS List failed'))
        }
    } catch (error) {
        messagePopup('', 'OFFERS List failed', 'error')
        yield put(offersListFailed('OFFERS List failed'))
    }
}

export function* offerslistSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            let allOffers = response.data.data.allOffers
            yield put(allOffersListSuccess(allOffers));
        } else {
            messagePopup('', 'OFFERS List failed', 'error')
            yield put(allOffersListFailed('OFFERS List failed'))
        }
    } catch (error) {
        messagePopup('', 'OFFERS List failed', 'error')
        yield put(allOffersListFailed('OFFERS List failed'))
    }
}


export function* watchOffersListSaveSaga() {
    yield takeEvery(actionType.OFFERS_LIST_START, offerslistSaga)
    yield takeEvery(actionType.OFFERS_LIST_SAVE_START, offersListSaveSaga)

}