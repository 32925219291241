import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    REQ_TO_ADVISOR_MAIL_START: "REQ_TO_ADVISOR_MAIL_START",
    REQ_TO_ADVISOR_MAIL_SUCCESS: "REQ_TO_ADVISOR_MAIL_SUCCESS",
    REQ_TO_ADVISOR_MAIL_FAIL: "REQ_TO_ADVISOR_MAIL_FAIL",
};

// ============================================ Actions ========================================================
export const reqToAdvisorStart = (data) => ({
    type: actionType.REQ_TO_ADVISOR_MAIL_START,
    payload: data,
});

export const reqToAdvisorFail = (data) => ({
    type: actionType.REQ_TO_ADVISOR_MAIL_FAIL,
    payload: data,
});

export const reqToAdvisorSuccess = (data) => ({
    type: actionType.REQ_TO_ADVISOR_MAIL_SUCCESS,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function reqToAdvisorReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.REQ_TO_ADVISOR_MAIL_START:
            return {
                ...state,
                loading: true,
            };

        case actionType.REQ_TO_ADVISOR_MAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };

        case actionType.REQ_TO_ADVISOR_MAIL_FAIL:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };

        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* reqToAdvisorSaga(action) {
    try {
        const response = yield fetchMethod(
            `/api/Athletes/requestForAdvisor`,
            action.payload,
            "post",
            true
        );
        if (
            response &&
            response.status === 200
        ) {
            yield put(
                reqToAdvisorSuccess({
                    reqToAdvisor: response.data.response.msg || "",
                })
        );
        messagePopup("", "Request to assign advisor have been sent to Admin", "success");
        } else {
            messagePopup("", "Request to assign advisor have been sent to Admin Failed", "error");
            yield put(reqToAdvisorFail("Request to assign advisor have been sent to Admin Failed"));
        }
    } catch (error) {
        messagePopup("", "Request to assign advisor have been sent to Admin Failed", "error");
        yield put(reqToAdvisorFail("Request to assign advisor have been sent to Admin Failed"));
    }
}

//Watcher
export function* watchReqToAdvisorSaga() {
    yield takeEvery(actionType.REQ_TO_ADVISOR_MAIL_START, reqToAdvisorSaga);
}
