import React, { useState, useEffect, useRef } from "react";
import "../../../../../../assets/css/stylesheet.css";
import "../assets/css/athleteProfileFinalDark.scss";
import {connect, useSelector} from "react-redux";
import {
  getAuthToken,
  getUserInfo,
} from "../../../../../../services/authService";
import { savePostLikeQuery } from "./profileQuery";
import { profileTimelineStart } from "../../../../../../redux/athlete/profile/timeline";
import { postLikeUnlikeStart } from "../../../../../../redux/athlete/dashboard/homepage/postLikeUnlike";
import usePrevious from "../../../../../../utils/usePrevious";
import MyFeed from "../../homepage/posts/MyFeed";
import {Link, useLocation} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import SharePost from "../../homepage/posts/SharePost";
import {flameAPI} from "../../../../../common/userViewCount";
import {updatePostsQuery} from "../../homepage/posts/PostsQuery";
import swal from "sweetalert";
import {
  pinPostStart,
  removePostStart,
  unpinPostStart
} from "../../../../../../redux/athlete/dashboard/homepage/savePost";
import {fetchGraphMethod} from "../../../../../../services/httpService";
import {saveAddedEventQuery} from "../../editUserProfile/events/AddEditEventFormQuery";
import {getPostStart} from "../../../../../../redux/athlete/dashboard/homepage/posts";
import {profileURLData} from "../../../../../common/getProfileURLData";

const EditTimeline = (props) => {
  const location = useLocation()
  const Athid = profileURLData(location?.pathname, 'athlete_id')
      // +props?.props?.match?.params?.id
  let isToken = profileURLData(location?.pathname, 'token')
      // props?.props?.match?.params?.isToken == 1 ? 1 : 0;
  const isPublicView = profileURLData(location?.pathname, 'public_profile')
      // !!props.isPublicView;
  const prevTimelineData = usePrevious(props.profileTimeline.data);
  const [totalCount, setTotalCount] = useState(0);
  const [myPostList, setMyPostList] = useState(null);
  const [containPin, setContainPin] = useState(false);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  });
  const fakeInput = useRef(null);
  const store = useSelector(store => store)
  const pubViewID = JSON.parse(localStorage.getItem('pubViewID'))
  const [modalData, setModalData] =React.useState(null)
  const [showModal, setShowModal] = useState(false);
  const [isLike, setIsLike] = React.useState(false);
  useEffect(() => {
    getTimelineData(pagination.limit, pagination.offset);
    fakeInput.current.focus();
    fakeInput.current.select();
  }, [pubViewID, store?.athlete?.dashboard?.home?.athleteProfileStatus?.status, Athid]);

  useEffect(()=>{
    getTimelineData(pagination.limit, pagination.offset);
  },[isLike])

  const getTimelineData = (limit, offset = 0) => {
    props.profileTimelineStart({
      data: {
        athleteId: !!Athid ? Athid :  store?.athlete?.dashboard?.home?.athleteProfileStatus?.status ?
            +pubViewID || 0 : !!getUserInfo()? getUserInfo().id : +pubViewID,
        isAccessToken:  isToken ,
        ViewUserId: !!getUserInfo()? getUserInfo().id : 0,    
        limit: limit,
        offset: offset,
      },
    });
  };

  const handlePagination = () => {
    let totalLimit = +pagination.limit + 10;
    setPagination({
      limit: totalLimit,
      offset: 0,
    });
    getTimelineData(totalLimit);
  };

  const handleModal = (e, value) => {
    setModalData(value)
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (props.profileTimeline.data) {
      let finalData = [];
      for (const post of props.profileTimeline.data.allData) {
        if (post.pinedStatus) {
          finalData = [
            {
              ...post,
              isPostLiked:
                post.userpostLikeStatus && post.userpostLikeStatus[0]
                  ? post.userpostLikeStatus[0].isLike
                  : null,
              postLikeId:
                post.userpostLikeStatus && post.userpostLikeStatus[0]
                  ? post.userpostLikeStatus[0].id
                  : null,
              typeofPost: post?.allVideoAudioPost?.length > 0 
                  ? post?.allVideoAudioPost?.[0]?.typeofpost
                  : null,
              postimagevideoandlinkurl :  post?.allVideoAudioPost?.length > 0 
              ? post?.allVideoAudioPost?.[0]?.postimagevideoandlinkurl
              : null
            },
            ...finalData,
          ];
          setContainPin(true);
        } else {
          finalData = [
            ...finalData,
            {
              ...post,
              isPostLiked:
                post.userpostLikeStatus && post.userpostLikeStatus[0]
                  ? post.userpostLikeStatus[0].isLike
                  : null,
              postLikeId:
                post.userpostLikeStatus && post.userpostLikeStatus[0]
                  ? post.userpostLikeStatus[0].id
                  : null,
              typeofPost: post?.allVideoAudioPost?.length > 0 
                  ? post?.allVideoAudioPost?.[0]?.typeofpost
                  : null,
              postimagevideoandlinkurl :  post?.allVideoAudioPost?.length > 0 
              ? post?.allVideoAudioPost?.[0]?.postimagevideoandlinkurl
              : null
            },
          ];
        }
      }
      setMyPostList(finalData);
      setTotalCount(props.profileTimeline.data.totalCount);
    }else{
      //else is used for updating the ui when there is no share dta from api
      setMyPostList([])
    }
  }, [props.profileTimeline.data, isLike]);

  const refreshApiData = () => {
    setIsLike(!isLike)
  }

  const handleLike = (e, post) => {
    e.preventDefault();
    props.postLikeUnlikeStart({
      query: savePostLikeQuery,
      variables: {
        obj: {
          active: 1,
          postactivityid: post.serialNo,
          userid: post.userId ? post.userId : getUserInfo().id,
          id: post.postLikeId ? post.postLikeId : 0,
        },
      },
      refreshPage: refreshApiData,
      type: "like",
    });
    let flameUserRole = null
    if (getUserInfo()?.role == "GUARDIAN"){
      flameUserRole = "ATHLETES"
    }else{
      flameUserRole = getUserInfo()?.role
    }
    if ((post?.userid || post?.userId) != getUserInfo()?.id){
      flameAPI('flamefromprofile',post?.userid || post?.userId ,getUserInfo()?.id,false, flameUserRole)
    }
  };

  const handleUnlike = (e, post) => {
    e.preventDefault();
    props.postLikeUnlikeStart({
      query: savePostLikeQuery,
      variables: {
        obj: {
          active: 0,
          postactivityid: post.serialNo,
          userid: post.userId ? post.userId : getUserInfo().id,
          id: post.postLikeId ? post.postLikeId : 0,
        },
      },
      refreshPage: refreshApiData,
      type: "unlike",
    });
  };
  const handleUnpinPost = (post) => {
    props.unpinPostStart({
      query: updatePostsQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
          id: post.serialNo,
          ispined: 0,
        },
      },
      getPosts,
      afterUnPinningPost,
    });
    // window.location.reload();
  };
  const getPosts = () => {
    props.getPostStart({
      offset: pagination.offset,
      limit: pagination.limit,
      loginUserId: getUserInfo().id,
      firstDropDownValue: 2,
      secondDropDownValue: 1,
    });
  };
  const afterUnPinningPost = () => {
    swal({
      text: " Your post has been unpinned.",
      icon: "success",
    }).then((okay) => {
      if (okay) {
        window.location.reload();
      }
    });
  };


  const handlePinPost = (post) => {
    props.pinPostStart({
      query: updatePostsQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
          id: post.serialNo,
          ispined: 1,
        },
      },
      getPosts,
    });
    let filteredPost = [];
    for (const data of myPostList) {
      if (data.serialNo === post.serialNo) {
        filteredPost = [{ ...data, pinedStatus: 1 }, ...filteredPost];
      } else {
        filteredPost = [...filteredPost, data];
      }
    }
    setMyPostList(filteredPost.length ? filteredPost : null);
    afterPinningPost();
  };
  const afterPinningPost = () => {
    swal({
      text: " Your post has been pinned to the top.",
      icon: "success",
    }).then((okay) => {
      if (okay) {
        window.location.reload();
      }
    });
  };
  const removeMyPostPage = (page) =>{
    if (page == "Dashboard/MyFeed" || page == "Timeline"){
      console.log("remove api hit")
    }
  }
  const handleRemovePost = async (post, page) => {
    props.removePostStart({
      query: updatePostsQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
          id: post.serialNo,
          active: 0,
        },
      },
      uploadVideoAndPhotoId: post.uploadVideoAndPhotoId
          ? post.uploadVideoAndPhotoId
          : null,
      getPosts,
      deletePostId: post.id,
      page: page,//this is done for fixing the carousel auto-hieght issue
      removeMyPostPage,//this is done for fixing the carousel auto-hieght issue
    });
    const filteredPost = [...myPostList].filter(
        (item) => item.serialNo !== post.serialNo
    );

    // after event delete uncheck 'Check here to post it on your Timeline' checkbox on event section
    if (!!post?.eventmappingid) {
      const res = await fetchGraphMethod(
          saveAddedEventQuery,
          {
            obj: {
              id: post?.eventmappingid ?? null,
              ispostevent: 0,
            },
          },
          true
      );
    }
    // end

    setMyPostList(filteredPost);
  };
  return (
    <div className="TimelineData">
      <input className="fakeInput" type="text" ref={fakeInput} />
      <div className="item">
        <div className="athleteDashSection" style={{ marginTop: "0" }}>
          <div className="container">
            <div className="flexBox" style={{ display: "block" }}>
              <div className="dataBox" style={{ width: "100%" }}>
                <div className="tabsBox">
                  <MyFeed
                    postList={myPostList}
                    containPin={containPin}
                    isTimeline={ isPublicView }
                    // isTimelineValue={ true }
                    isPublicView={props.isPublicView}
                    handlePinPost={handlePinPost}
                    handleUnpinPost={handleUnpinPost}
                    handleModal={handleModal}
                    handleLike={handleLike}
                    handleUnlike={handleUnlike}
                    handleRemovePost={handleRemovePost}
                    isAuth={ isToken }
                    page = { "Timeline" }
                  />
                  {myPostList?.length > 0 ?
                   myPostList.length < totalCount ? (
                    <div className="loadMoreBox">
                      <Link to="#" onClick={(e) => handlePagination(e)}>
                        Load More
                      </Link>
                    </div>
                  ) : "" : <p>No Record Found</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={showModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  handleModal()
                }
              }}
              className="matDialogCustom"
      >
        <SharePost
            handleModal={handleModal}
            modalData={modalData}
            pageType={'Home'}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profileTimeline: state.athlete.profile.profileTimeline,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    profileTimelineStart: (data) => dispatch(profileTimelineStart(data)),
    postLikeUnlikeStart: (data) => dispatch(postLikeUnlikeStart(data)),
    pinPostStart: (data) => dispatch(pinPostStart(data)),
    unpinPostStart: (data) => dispatch(unpinPostStart(data)),
    removePostStart: (data) => dispatch(removePostStart(data)),
    getPostStart: (data) => dispatch(getPostStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTimeline);
