import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    LANDING_PAGE_START: "LANDING_PAGE_START",
    LANDING_PAGE_SUCCESS: "LANDING_PAGE_SUCCESS",
    LANDING_PAGE_FAILED: "LANDING_PAGE_FAILED",
};

// ============================================ Actions ========================================================
export const landingPageStart = (data) => ({
    type: actionType.LANDING_PAGE_START,
    payload: data,
});
export const landingPageSuccess = (data) => ({
    type: actionType.LANDING_PAGE_SUCCESS,
    payload: data,
});

export const landingPageFailed = (data) => ({
    type: actionType.LANDING_PAGE_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    lpType: null,
    error: null,
};

export default function landingPageReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.LANDING_PAGE_START:
            return { ...state, loading: true };

        case actionType.LANDING_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                lpType: action.payload?.lpType,
                error: null,
            };
        case actionType.LANDING_PAGE_FAILED:
            return {
                ...state,
                loading: false,
                lpType: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================
export function* landingPageSaga(action) {
    try {
        yield put(landingPageSuccess({ lpType : action?.payload?.lpType}));
    } catch (error) {
        messagePopup("", "Landing Page Type failed", "error");
        yield put(landingPageFailed("Landing Page Type failed"));
    }
}


export function* watchLandingPageSagaSaga() {
    yield takeEvery(actionType.LANDING_PAGE_START, landingPageSaga);
}
