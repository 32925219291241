import React, { useState, useEffect } from 'react';
// import "../../../../../assets/css/stylesheet.scss";
import { connect } from 'react-redux';
import { graduationYearsListStart } from '../../../../../redux/common/graduationYears';
import { stateListStart } from '../../../../../redux/common/allStates';
import { searchUserStart } from '../../../../../redux/auth/signup/claimProfile';
import { claimProfileStart } from '../../../../../redux/auth/signup/userClaimProfile';
import { matchotpStart } from '../../../../../redux/auth/signup/matchOtp';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ReactPaginate from 'react-paginate';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { allsportsListStart } from '../../../../../redux/common/allSportWithOutUsingGender';
import { useHistory } from 'react-router-dom';
import '../assets/css/claimProfile.scss';
import {
  checkPhoneNumberValidation,
  formatPhoneNumber,
  IsValidEmail,
  NumFieldSymbolArr,
} from '../../../../../utils/validationUtils';
import OtpInput from 'react-otp-input';
import Dialog from '@mui/material/Dialog';
import { imageDownloadUrl } from '../../../../../config';
import messagePopup from '../../../../../utils/messagePopup';
import { countryListStart } from '../../../../../redux/common/countriesList';
import { getAllCountriesQuery } from '../../../../common/CommonQuery';
import PhoneNumber from '../../../../common/PhoneNumber';
import { fetchMethod } from '../../../../../services/httpService';
import swal from 'sweetalert';
import { element } from 'prop-types';

const selectStyle = {
  display: 'block',
  fontSize: '.875rem',
  fontWight: 400,
  color: '#3e3e3e',
  marginBottom: '0.5em',
};

function ClaimProfileStep2(props) {
  let history = useHistory();
  const [fields, setFields] = useState({
    //for search inputs
    lastname: '',
    state: '',
    gradyear: '',
    sport: '',
    pageCount: 0,
    offset: 1,
    perPage: 5,
    page: 0,
    go: 1,
    totalUserCount: 0,
  });
  const [inputModal, setInputModal] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false); //to show email Modal
  const [enterOtpModal, setEnterOtpModal] = useState(false); //to show otp modal
  const [enterOtp, setEnterOtp] = useState(''); // for otp input
  const [isSearch, setIsSearch] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const [athleteClaim, setAthleteClaim] = useState({});
  const [inputData, setInputData] = useState({});
  const [allCountryList, setAllCountryList] = useState([]);
  const [fQ, setFQ] = useState(1);
  useEffect(() => {
    //redux function is for all country
    props.countryListStart({
      query: getAllCountriesQuery,
    });
    props.graduationYearsListStart();
    props.stateListStart();
    props.allsportsListStart();
    if (props.userList && props.userList.searchUserData) {
      props.userList.searchUserData = [];
    }
    setFields({ ...fields });
  }, []);

  //Effect To Map All Countries List
  useEffect(() => {
    if (props.allCountries && props.allCountries.data) {
      setAllCountryList(props.allCountries.data);
    }
  }, [props.allCountries]);

  const getMyCountryDetails = (id) => {
    let countryT = null;
    allCountryList?.map((country) => {
      if (country.id === id) {
        countryT = {
          value: country.id,
          label: country.countryName + `(+${country.countryCode})`,
          flag: country.countryFlagImage,
          countryCode: country.countryCode,
          countryShortName: country.countryShortName,
        };
      }
    });
    if (!!countryT) {
      const cloneState = { ...inputData };
      cloneState['countryDetails'] = countryT;
      setInputData(cloneState);
    }
  };

  const openModal = (userDeails) => {
    setShowModal(!showModal);
    setInputModal({
      fullName: userDeails.athleteFirstName + ' ' + userDeails.athleteLastName,
      email: userDeails.athleteEmailId,
    });
    setAthleteClaim(userDeails);
    getMyCountryDetails(1);
  };
  useEffect(() => {
    if (props.userList) {
      if (props.userList.searchUserData) {
        const fieldsT = fields;
        fieldsT['pageCountT'] = props.userList.totalCount
          ? Math.ceil(+props.userList.totalCount / fieldsT['perPage'])
          : 0;
        fieldsT['totalUserCountT'] = props.userList.totalCount;
        setFields({ ...fieldsT });
      }
    }
  }, [props.userList]);

  const closeModal = () => {
    setShowModal(false);
    // setFields({
    //   lastname: "",
    //   state: "",
    //   gradyear: "",
    //   sport: "",
    // });
    history.push('/claimProfileSearch');
    //earlier it was redirecting to claimProfileStep2
    setEnterOtp('');
    setAthleteClaim({});
    setInputData({});
    setErrors({});
    // window.location.reload();
  };

  const handleOtpModal = () => {
    setEnterOtpModal(true);
    setShowModal(false);
  };

  const handleSave = async (e) => {
    const { formIsValid } = handleValidation();
    if (formIsValid) {
      await props.claimProfileStart({
        data: {
          email: inputModal.email,
        },
        handleOtpModal: handleOtpModal,
      });
    }
  };

  const checkModal = () => {
    let cloneState = { ...inputData },
      cloneError = { ...errors },
      status = true;
    if (!!cloneState?.email) {
      if (!IsValidEmail(cloneState?.email ?? '')) {
        cloneError['email'] = 'Please enter valid email ID';
        setErrors(cloneError);
        return (status = false);
      }
    } else if (!!cloneState?.athleteNumber) {
      if (
        !checkPhoneNumberValidation(
          cloneState?.athleteNumber,
          cloneState?.countryDetails?.countryShortName ?? 'US'
        )
      ) {
        cloneError['athleteNumber'] = 'Please enter valid phonenumber';
        setErrors(cloneError);
        return (status = false);
      }
    }
    return status;
  };

  const onSaveModal = async () => {
    if (!!inputData?.email || !!inputData?.athleteNumber) {
      const valStatus = checkModal();
      if (valStatus) {
        const body = {
          email: inputData?.email ?? '',
          athleteid: athleteClaim?.id,
          phonenumber: inputData?.athleteNumber?.toString() ?? '',
          countrycode:
            inputData?.countryDetails?.countryCode?.toString() ?? '1',
        };
        const _res = await fetchMethod(
          '/api/UserData/claimProfileCase1',
          body,
          'POST',
          null
        );
        if (_res?.status == 200) {
          if (_res?.data?.response?.status == 401) {
            swal({
              text: !!_res?.data?.response?.msg
                ? _res?.data?.response?.msg
                : 'Temporary password cannot send',
              icon: 'error',
            });
          } else {
            swal({
              text: 'Thank you for your submission. If your profile verification is successful we will send you a temporary password to your Email ID and / or Mobile number to finalize claiming your profile.',
              icon: 'success',
            }).then((okay) => {
              props?.history?.push('/login');
            });
          }
        }
      }
    } else {
      const errorT = { ...errors };
      errorT['required'] = '* Please enter either email or mobile number';
      setErrors(errorT);
    }
  };

  const handelOptModal = () => {
    setEnterOtpModal(false);
    setEnterOtp('');
  };

  const closeOtp = () => {
    props.matchotpStart({
      data: {
        otp: enterOtp,
        useFor: 'login',
        otpOrId: 'otp',
      },
      handelOptModal: handelOptModal,
      nextScreen: '/login',
    });
    setTimeout(() => {
      setEnterOtp('');
    }, 1000);
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;

    //phoneNumber validations
    if (fieldname === undefined || fieldname === 'phoneNumber') {
      if (fieldname === undefined) {
        fieldValue = inputModal['phoneNumber'] ? inputModal['phoneNumber'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['phoneNumber'];
      if (!updatedValue) {
        formIsValid = true;
        errorsT['phoneNumber'] = '';
      } else if (updatedValue.length !== 10) {
        formIsValid = false;
        errorsT['phoneNumber'] = 'Please enter a valid phone number';
      }
    }

    //email validations
    if (fieldname === undefined || fieldname === 'email') {
      if (fieldname === undefined) {
        fieldValue = inputModal['email'] ? inputModal['email'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['email'];
      if (!updatedValue) {
        formIsValid = false;
        errorsT['email'] = 'Email is required';
      } else if (!IsValidEmail(updatedValue)) {
        formIsValid = false;
        errorsT['email'] = 'Please enter a valid email';
      }
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const handleChange = (field, e) => {
    let fieldsT = fields;
    fieldsT[field] = e.target.value;
    setFields({
      ...fieldsT,
    });
    setErrors({
      errorsT: '',
    });
  };
  //handle is for modal input country dropdown field
  const handleCountryChange = (option) => {
    const fieldsT = { ...inputData };
    setErrors({});
    fieldsT['countryDetails'] = option;
    setInputData({ ...fieldsT, athleteNumber: '' });
  };
  //handle is for modal input field
  const handleChangeModal = (field, e) => {
    const cloneState = { ...inputData };
    setErrors({});
    if (field == 'email') {
      cloneState[field] = e?.target?.value;
      setInputData(cloneState);
    } else {
      if (e.target.value?.length > 10) return;
      cloneState[field] = formatPhoneNumber(e?.target?.value);
      setInputData(cloneState);
    }
  };

  const handleModalChange = (field, e) => {
    const { formIsValid, updatedValue } = handleValidation(field, e);
    setErrors({
      errorsT: '',
    });
    let fieldsT = inputModal;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setInputModal({
      ...fieldsT,
    });
  };

  const handelSearchValidation = () => {
    let errorsT = errors,
      formIsValid = true,
      lastVal = !!fields.lastname ? true : false;
    if (lastVal == false) {
      errorsT['lastname'] = 'Please Select a Valid Last Name.';
    } else if (
      fields.lastname != '' ||
      fields.state != '' ||
      fields.sport != '' ||
      fields.gradyear != ''
    ) {
      formIsValid = true;
    } else {
      formIsValid = false;
      errorsT['search'] = 'Please enter at least one Value For search.';
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
    };
  };

  // useEffect(()=> {
  //   if(fields.page){
  //     let fieldsT = fields;
  //     fieldsT["page"]= fields.page - 1;
  //     setFields({...fieldsT});
  //   }
  // }, [fields.page])

  const handleSearch = () => {
    const { formIsValid } = handelSearchValidation();
    if (!!fields.lastname) {
      if (formIsValid == true) {
        props.searchUserStart({
          data: {
            lastName: fields.lastname.toString(),
            stateId: fields.state == '' ? 0 : fields.state,
            gradId: fields.gradyear == '' ? 0 : fields.gradyear,
            sportId: fields.sport == '' ? 0 : fields.sport,
            offset: fields.page * fields.perPage,
            limit: Number(fields.perPage),
          },
          isSearch: setIsSearch,
        });
        //this is used for scrolling the page on top
        setTimeout(() => {
          window.scrollTo(0, 0);
          //this is added as on initial render search result not to be shown
          setIsSearch(true);
        }, 500);
        setIsFirst(true);
      }
    }
    // else{
    //   messagePopup("", "Please Select a Valid Last Name.", "warning");
    // }
  };

  const handleGoClick = () => {
    if (!!fields.lastname == false) return handelSearchValidation();
    const fieldsT = fields;
    fieldsT['page'] = fieldsT['go'] - 1;
    setFields({ ...fieldsT });
    props.searchUserStart({
      data: {
        lastName: fields.lastname.toString(),
        stateId: fields.state == '' ? 0 : fields.state,
        gradId: fields.gradyear == '' ? 0 : fields.gradyear,
        sportId: fields.sport == '' ? 0 : fields.sport,
        offset: fields.go ? (fields.go - 1) * fields.perPage : 0,
        limit: Number(fields.perPage),
      },
    });
    setIsFirst(true);
    //this is used for scrolling the page in top
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const getInitials = (data) => {
    let firstname = data.athleteFirstName;
    let lastname = data.athleteLastName;
    const initials = firstname[0] + lastname[0];
    return initials.toUpperCase();
  };
  const handleOtp = (otp) => {
    setEnterOtp(otp);
  };
  const handlePageClick = (e) => {
    const fieldsT = fields;
    fieldsT['page'] = e.selected;
    setFields({ ...fieldsT });
    handleSearch();
  };

  function hideemail() {
    if (!inputModal.email) {
      return '';
    }
    let email = inputModal && inputModal.email;
    let hiddenEmail = '';
    for (let i = 0; i < email.length; i++) {
      if (i > 2 && i < email.indexOf('@')) {
        hiddenEmail += '*';
      } else {
        hiddenEmail += email[i];
      }
    }
    return hiddenEmail;
  }

  function hidePhoneNumber() {
    if (!inputModal.phoneNumber) {
      return '';
    }
    let phoneNumber = inputModal && inputModal.phoneNumber;
    let hiddenMobileNumber = '';
    for (let i = 0; i < phoneNumber.length; i++) {
      if (i > 2) {
        hiddenMobileNumber += '*';
      } else {
        hiddenMobileNumber += phoneNumber[i];
      }
    }
    return hiddenMobileNumber;
  }
  useEffect(() => {
    //this is used for scrollin ght epage in top
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, [props?.allStates?.data?.length, props?.allsports?.data?.length]);
  return (
    <>
      <section
        className="pageContent"
        // claimprofile-main-sec"
      >
        <div className="claimprofile-sec">
          <div className="container">
            <h1 className="sec-title" style={{ textAlign: 'center' }}>
              Claim Your Profile
            </h1>
            <h3>Ranked Players</h3>
            <ul className="list-type-bullet">
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor ncididunt ut labore et dolore magna aliqua.
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor ncididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </li>
            </ul>
            <h3>Non-Ranked Players</h3>
            <ul className="list-type-bullet">
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor ncididunt ut labore et dolore magna aliqua.
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor ncididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </li>
            </ul>
            <div className="claimprofile-box">
              <div className="claimprofile-title">Find your profile</div>
              <div className="claimprofile-detail">
                <ul className="flexbox">
                  <li>
                    <div className="form-group">
                      <label>
                        Last Name<span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        onChange={(e) => handleChange('lastname', e)}
                        value={fields.lastname || ''}
                        className="form-control"
                        type="text"
                        name="lastname"
                        id="lastname"
                        placeholder="Last Name"
                      />
                      <div
                        style={{ position: 'absolute', color: 'red' }}
                        className="error"
                      >
                        {errors['lastname']}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      {/*<FormLabel>*/}
                      <span style={{ display: 'block', marginBottom: '3px' }}>
                        <span style={selectStyle}>State / Province</span>
                      </span>
                      {/*</FormLabel>*/}
                      <div className="fieldBox">
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            // labelId="demo-simple-select-outlined-label"
                            // id="demo-simple-select-outlined"
                            value={fields.state}
                            onChange={(e) => handleChange('state', e)}
                            // label="Select"
                            displayEmpty
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            {props.allStates.data &&
                              props.allStates.data.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                  {item.statename}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      {/*<FormLabel>*/}
                      {/*  <label>Grad year</label>*/}
                      {/*</FormLabel>*/}
                      <span style={{ display: 'block', marginBottom: '3px' }}>
                        <span style={selectStyle}>Grad year</span>
                      </span>
                      <div className="fieldBox">
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            // labelId="demo-simple-select-outlined-label"
                            // id="demo-simple-select-outlined"
                            value={fields.gradyear}
                            onChange={(e) => handleChange('gradyear', e)}
                            // label="Select"
                            displayEmpty
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            {props.graduationYears.data &&
                              props.graduationYears.data.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                  {item.yearValue}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </li>
                  <li style={!!errors?.lastname ? {} : {}}>
                    <div className="form-group">
                      {/*<FormLabel>*/}
                      {/*  <label>Sport</label>*/}
                      {/*</FormLabel>*/}
                      <span style={{ display: 'block', marginBottom: '3px' }}>
                        <span style={selectStyle}>Sport</span>
                      </span>
                      <div className="fieldBox">
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            // labelId="demo-simple-select-outlined-label"
                            // id="demo-simple-select-outlined"
                            value={fields.sport}
                            onChange={(e) => handleChange('sport', e)}
                            // label="Select"
                            displayEmpty
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            {props.allsports.data &&
                              props.allsports.data.map((item, index) => {
                                if (item?.id == 1) {
                                  return (
                                    <MenuItem key={index} value={item.id}>
                                      {item.sportName?.split(' ')?.[1] ??
                                        'Baseball'}
                                    </MenuItem>
                                  );
                                }
                              })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <button
                        onClick={() => handleSearch()}
                        className="btn blue"
                      >
                        Search
                      </button>
                    </div>
                    <div className="error">{errors['search']}</div>
                  </li>
                </ul>
                <div className="claim-profile-filter-data">
                  {props.userList &&
                  props.userList.searchUserData.length !== 0 ? (
                    <h3 style={{ color: 'red' }}>
                      {fields['totalUserCountT']} Athlete Profile found.
                    </h3>
                  ) : (
                    <h3 style={{ color: 'red' }}>
                      {isSearch && props.userList?.searchUserData?.length == 0
                        ? 'No Athlete Found'
                        : ''}
                    </h3>
                  )}
                  {props.userList && props.userList.searchUserData
                    ? props.userList.searchUserData.map((item, index) => (
                        <ul className="athlete-profile-item" key={index}>
                          <li>
                            <div className="athlete-profile-info">
                              <div
                                className="img-box"
                                style={
                                  !!item.profileImages
                                    ? {}
                                    : {
                                        padding: '18px',
                                        border: '1px solid black',
                                        borderRadius: '50%',
                                      }
                                }
                              >
                                {!!item.profileImages ? (
                                  <img
                                    src={
                                      imageDownloadUrl +
                                      '/' +
                                      item.profileImages
                                    }
                                  />
                                ) : (
                                  getInitials(item)
                                )}
                              </div>
                              <div className="content-box">
                                {item.athleteFirstName +
                                  ' ' +
                                  item.athleteLastName}
                                , {item.statename ? item.statename + ',' : ''}{' '}
                                {item.yearValue ? item.yearValue + ',' : ''}{' '}
                                {item.sportName}
                              </div>
                            </div>
                            <div className="button-box">
                              <button
                                onClick={() => openModal(item)}
                                className="btn blue"
                                data-toggle="modal"
                                data-target="#ClaimProfileModal"
                              >
                                Claim Profile
                              </button>
                            </div>
                          </li>
                        </ul>
                      ))
                    : null}
                  {/*{isFirst && <>*/}
                  {
                    // isSearch || props.userList &&
                    props?.userList?.searchUserData?.length > 0 ? (
                      <div className="paginationBox">
                        <div className="perPage">
                          <input
                            type="number"
                            style={{ width: '45px', textAlign: 'center' }}
                            value={fields.perPage}
                            onChange={(e) =>
                              parseInt(e.target.value) == 0 ||
                              e.target.value == ''
                                ? console.log('inter valid number')
                                : handleChange('perPage', e)
                            }
                            onKeyDown={(e) =>
                              NumFieldSymbolArr.includes(e.key) &&
                              e.preventDefault()
                            }
                            defaultValue={fields.perPage}
                          />
                          <label>Per Page</label>
                        </div>
                        <div className="pageNav">
                          <ReactPaginate
                            previousLabel={'<<'}
                            nextLabel={'>>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={fields.pageCountT || 0}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={4}
                            onPageChange={handlePageClick}
                            // containerClassName={"pagination"}
                            forcePage={fields.page}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                          />
                        </div>
                        <div className="goBox">
                          <input
                            type="number"
                            style={{ width: '45px' }}
                            value={fields.go}
                            onKeyDown={(e) =>
                              NumFieldSymbolArr.includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) =>
                              parseInt(e.target.value) == 0 ||
                              e.target.value == ''
                                ? console.log('inter valid number')
                                : handleChange('go', e)
                            }
                          />
                          <a onClick={() => handleGoClick()}>
                            <span>Go </span>
                            <span className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="4.568"
                                height="7.99"
                                viewBox="0 0 4.568 7.99"
                              >
                                <defs>
                                  <style></style>
                                </defs>
                                <path
                                  className="a"
                                  d="M1.377,3.993,4.4.972a.569.569,0,0,0,0-.806.576.576,0,0,0-.809,0L.166,3.589a.57.57,0,0,0-.017.787l3.44,3.447A.571.571,0,0,0,4.4,7.017Z"
                                  transform="translate(4.568 7.99) rotate(180)"
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    ) : (
                      ''
                    )
                  }
                  {/*</>*/}
                  {/*}*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-faq-sec">
        <div className="container">
          <div className="faq-box-wrapper">
            <h1 className="sec-title">Frequently Asked Questions</h1>
            <ul className="faq-listing">
              <li className="active">
                <div
                  className={fQ == 1 ? 'faq-title active' : 'faq-title'}
                  onClick={() => setFQ(1)}
                >
                  Lorem Ipsum is placeholder text commonly used in the
                  graphic,print?
                </div>
                <div
                  className="faq-data"
                  style={fQ == 1 ? { display: 'block' } : { display: 'none' }}
                >
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.{' '}
                  </p>
                </div>
              </li>
              <li>
                <div
                  className={fQ == 2 ? 'faq-title active' : 'faq-title'}
                  onClick={() => setFQ(2)}
                >
                  Lorem Ipsum is placeholder text commonly used in the
                  graphic,print?
                </div>
                <div
                  className="faq-data"
                  style={fQ == 2 ? { display: 'block' } : { display: 'none' }}
                >
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.{' '}
                  </p>
                </div>
              </li>
              <li>
                <div
                  className={fQ == 3 ? 'faq-title active' : 'faq-title'}
                  onClick={() => setFQ(3)}
                >
                  Lorem Ipsum is placeholder text commonly used in the
                  graphic,print?
                </div>
                <div
                  className="faq-data"
                  style={fQ == 3 ? { display: 'block' } : { display: 'none' }}
                >
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.{' '}
                  </p>
                </div>
              </li>
              <li>
                <div
                  className={fQ == 4 ? 'faq-title active' : 'faq-title'}
                  onClick={() => setFQ(4)}
                >
                  Lorem Ipsum is placeholder text commonly used in the
                  graphic,print?
                </div>
                <div
                  className="faq-data"
                  style={fQ == 4 ? { display: 'block' } : { display: 'none' }}
                >
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.{' '}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Dialog
        open={showModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeModal();
          }
        }}
        className="matDialogCustom"
      >
        <div
          className="modal fade common-modal show"
          id="ClaimProfileModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ClaimProfileLabel"
          aria-hidden="true"
          style={{ display: showModal ? 'block' : 'none' }}
        >
          <div className="modal-dialog" role="document">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Claim your profile</h5>
                <button
                  onClick={() => closeModal()}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Full Name :</label>
                  <label style={{ fontWeight: 'bold' }}>
                    {inputModal.fullName}
                  </label>
                  {/* <div className="name-text">{inputModal.fullName}</div> */}
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    onChange={(e) => handleChangeModal('email', e)}
                    // value={inputModal.email }
                    value={inputData?.email}
                    // disabled
                    className="form-control"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email ID"
                  />
                  <div style={{ color: 'red', textAlign: 'left' }}>
                    {errors?.['email'] ?? ''}
                  </div>
                </div>
                <div className="form-group">
                  {/*<label>Mobile Number(Highly Recommended)</label>*/}
                  <div className="half">
                    <label>Mobile Number (Highly Recommended)</label>
                    <PhoneNumber
                      dropdownName="countryCode"
                      dropdownOnChange={handleCountryChange}
                      dropdownValue={inputData?.countryDetails ?? 1}
                      dropDownOptions={allCountryList?.map((item) => ({
                        value: item.id,
                        label: item.countryName + `(+${item.countryCode})`,
                        flag: item.countryFlagImage,
                        countryCode: item.countryCode,
                        countryShortName: item.countryShortName,
                      }))}
                      formatOptionLabel={(option, { context, selectValue }) => {
                        return context === 'menu' ? (
                          <>
                            <img
                              src={option.flag}
                              alt=""
                              style={{ width: '40px', marginRight: '15px' }}
                            />
                            <span>{option.label}</span>
                          </>
                        ) : (
                          <>
                            <img
                              src={option.flag}
                              alt=""
                              style={{ width: '40px', marginRight: '15px' }}
                            />
                            <span>+{option.countryCode}</span>
                          </>
                        );
                      }}
                      inputPlaceholder="Phone Number"
                      inputType="phonenumber"
                      inputValue={inputData?.athleteNumber || ''}
                      inputOnChange={(e) => {
                        handleChangeModal('athleteNumber', {
                          target: {
                            value: e.target.value.replace(/[^0-9]/gi, ''),
                          },
                        });
                      }}
                    />
                  </div>
                  {/*<input*/}
                  {/*  onChange={(e) => handleModalChange("phoneNumber", e)}*/}
                  {/*  value={inputModal.phoneNumber || ""}*/}
                  {/*  className="form-control"*/}
                  {/*  type="number"*/}
                  {/*  name="phoneNumber"*/}
                  {/*  id="phoneNumber"*/}
                  {/*  placeholder="Enter your mobile number."*/}
                  {/*/>*/}
                  <div style={{ color: 'red', textAlign: 'left' }}>
                    {errors?.['athleteNumber'] ?? ''}
                  </div>
                </div>
                <div style={{ color: 'red', textAlign: 'left' }}>
                  {errors?.['required'] ?? ''}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => onSaveModal()}
                  type="button"
                  className="btn blue"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#ClaimProfileOtpModal"
                >
                  Save
                </button>

                <button
                  onClick={() => closeModal()}
                  type="button"
                  className="btn primary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={enterOtpModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            handelOptModal();
          }
        }}
        className="matDialogCustom"
      >
        <div
          className="modal fade common-modal show"
          id="ClaimProfileOtpModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ClaimProfileOtpLabel"
          aria-hidden="true"
          style={{ display: enterOtpModal ? 'block' : 'none' }}
        >
          <div className="modal-dialog" role="document">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Enter Passcode</h5>
                <button
                  onClick={handelOptModal}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  A text message with your Passcode has been sent to registered
                  mobile number {hidePhoneNumber()} and email address
                  {''} {hideemail()}
                </p>
                <ul className="claimprofile-password-box">
                  <OtpInput
                    placeholder="******"
                    value={enterOtp}
                    onChange={handleOtp}
                    numInputs={6}
                    isInputNum="true"
                    renderInput={(inputProps) => (
                      <input
                        {...inputProps}
                        maxLength={1}
                        style={{
                          width: '30px',
                          height: '30px',
                          fontSize: '20px',
                          textAlign: 'center',
                        }}
                      />
                    )}
                  />
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => closeOtp()}
                  type="button"
                  className="btn blue"
                  data-dismiss="modal"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    allCountries: state.common.countries,
    graduationYears: state.common.graduationYears,
    allStates: state.common.states,
    allsports: state.common.allSportList,
    userList: state.auth.searchUser.data,
    validUserStatus: state.auth.claimProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    countryListStart: (data) => dispatch(countryListStart(data)),
    graduationYearsListStart: () => dispatch(graduationYearsListStart()),
    stateListStart: () => dispatch(stateListStart()),
    searchUserStart: (data) => dispatch(searchUserStart(data)),
    allsportsListStart: () => dispatch(allsportsListStart()),
    claimProfileStart: (data) => dispatch(claimProfileStart(data)),
    matchotpStart: (data) => dispatch(matchotpStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimProfileStep2);
