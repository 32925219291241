import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";
import toastr from "toastr";

// ========================================== Action Type =====================================================
export const actionType = {
  REFERENCES_LIST_START: "REFERENCES_LIST_START",
  REFERENCES_SAVE_START: "REFERENCES_SAVE_START",
  REFERENCES_SUCCESS: "REFERENCES_SUCCESS",
  REFERENCES_FAILED: "REFERENCES_FAILED",
};

// ============================================ Actions ========================================================
export const referencesListStart = (data) => ({
  type: actionType.REFERENCES_LIST_START,
  payload: data,
});

export const referencessaveStart = (data) => ({
  type: actionType.REFERENCES_SAVE_START,
  payload: data,
});

export const referencesSuccess = (data) => ({
  type: actionType.REFERENCES_SUCCESS,
  payload: data,
});

export const referencesFailed = (data) => ({
  type: actionType.REFERENCES_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function referencesReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.REFERENCES_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.REFERENCES_SAVE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.REFERENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.REFERENCES_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* referencesSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      if(action.payload.refreshList) action.payload.refreshList(action.payload.variables.obj, action?.payload?.variables);
      const referenceResponse = response.data.data.saveReference;
      if(action.payload.handleReferenceMessage){
        action.payload.handleReferenceMessage(response.data)
      } 
      else if(referenceResponse.active === 0){
        // messagePopup("", "Reference Removed successfully", "success");
        toastr.success("Reference Removed successfully.", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      }
      yield put(
        referencesSuccess({
          saveReferenceData: referenceResponse,
        })
      );
    } else {
      messagePopup("", "REFERENCES List failed", "error");
      yield put(referencesFailed("REFERENCES List failed"));
    }
  } catch (error) {
    messagePopup("", "REFERENCES List failed", "error");
    yield put(referencesFailed("REFERENCES List failed"));
  }
}

export function* referencesListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      let referencesData = response.data.data.allReferences;
      yield put(referencesSuccess({ allreferencedata: referencesData }));
    } else {
      messagePopup("", "REFERENCES List failed", "error");
      yield put(referencesFailed("REFERENCES List failed"));
    }
  } catch (error) {
    messagePopup("", "REFERENCES List failed", "error");
    yield put(referencesFailed("REFERENCES List failed"));
  }
}

export function* watchReferencesSaga() {
  yield takeEvery(actionType.REFERENCES_LIST_START, referencesListSaga);
  yield takeEvery(actionType.REFERENCES_SAVE_START, referencesSaveSaga);
}
