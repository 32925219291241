import React from "react";
import { useState, useEffect } from "react";
import "./css/notification.css";
import { Menu, MenuItem, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import messagePopup from '../../../../../utils/messagePopup';
import toastr from "toastr";
import alertIcon from './img/alert.png';
import closeGrayIcon from "./img/close-gray-icon.svg";
import Dialog from "@mui/material/Dialog";
import {connect} from 'react-redux';
import {getMessageQuery, getAdvisorData, saveMessageApi, premiumApiData, AddUserActivity, saveMessageReplyApi, athleteParentQuery} from './MessageQuery';
import { getUserdataListStart }from "../../../../../redux/athlete/dashboard/message/getUserData";
import { premiumDropdownStart } from '../../../../../redux/athlete/dashboard/message/premiumDropdown';
import { messageListStart } from "../../../../../redux/athlete/dashboard/message/message";
import { messageCountListStart } from '../../../../../redux/athlete/dashboard/message/messageCount';
import {athleteParentDropdownStart} from '../../../../../redux/athlete/dashboard/composeMessage/athleteParentDropdown';
import {saveMessageReplyListStart} from '../../../../../redux/athlete/dashboard/composeMessage/composeMessage';

const ITEM_HEIGHT = 48;

const AdvisorReportComposeMessage = (props) => {
  let history = useHistory();
  const [anchorE1, setAnchorE1] = React.useState(null);
  const open = Boolean(anchorE1),
  [coachCount, setCoachCount] = useState(0),
  [advisorCount, setAdvisorCount] = useState(0),
  [subject, setSubject] = useState(undefined),
  [message, setMessage] = useState(undefined),
  [advisorData, setAdvisorData] = useState([]),
  [openPremiumModal, setOpenPremiumModal] = useState(false),
  [ispremium, setIsPremium] = useState(0),
  [parentData, setParentData] = useState([]),
  [supportCount, setSupportCount] = useState("");
  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };
  const handleCoachMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/coach/composeMessage" });
  };
  const handleAdvisorMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/advisor/composeMessage" });
  }
  const handleSupportMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/support/composeMessage" });
  }
  const cancelBtnClick = () => {
    setOpenPremiumModal(!openPremiumModal);
  }
  const sendClickBtn = (e) => {
    e.preventDefault();
      if(subject){
        if(message){
          props.saveMessageReplyListStart({
            query: saveMessageApi,
            variables: {
              obj: {
               active: 1,
               message: message,
               isread: 0,
               messagesendby: parseFloat(JSON.parse(localStorage.getItem('user')).id),
               messagesendtoadvisorid: advisorData?.length > 0 ? parseFloat(advisorData[0].senderId) : 0,
               subject: subject,
               istrash: 1,
               userrole: 'ATHLETE'
            }
            },
            nextScreen: (data)=>{
              props.saveMessageReplyListStart({
                query: saveMessageReplyApi,
                variables: {
                  obj: {
                    msgid: parseFloat(data?.saveMessage?.id),
                    msg: message,
                    msgreceiveradvisorid: advisorData?.length > 0 ? parseFloat(advisorData[0].senderId) : 0,
                    msgsenderid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
                    subject: subject,
                    active: 1,
                    parentnamevalue: JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN' && parentData?.length > 0 ? parentData[0].name : null,
                    sendmsgrolevalue: JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN' ? 'Parent/Guardian' : null
                }
                },
                nextScreen: (data)=>{
                  props.saveMessageReplyListStart({
                    query: AddUserActivity,
                    variables: {
                      obj: {
                        messageid: parseFloat(data?.saveMessagereply?.id),
                        userid: parseFloat(JSON.parse(localStorage.getItem('user')).id),
                        advisorid: advisorData?.length > 0 ? parseFloat(advisorData[0].senderId) : 0,
                    }
                    },
                    nextScreen: (data)=>{
                      // messagePopup("", "Message send successfully", "success");
                      toastr.success("Message send successfully", "", {
                        progressBar : true,timeOut: 2000,
                        closeButton: true, positionClass: "toast-top-full-width",
                        showEasing: "swing", fadeIn: 40000,
                        preventDuplicates: true,
                      })
                        history.push('/message/coach');
                    }
                  });
                }
              });
            }
          });
        }
        else{
        // messagePopup("", "Please enter message", "warning")
        toastr.warning("Please enter message", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
        }
      }
      else{
        // messagePopup("", "Please enter subject", "warning")
        toastr.warning("Please enter subject", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      }
  }

  const PremiumModal = ({ onActivateClick, onCancelClick }) => {
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img className='imgName' src={alertIcon} alt="Alert Circle Icon" />
              </div>
              <div className="content-box">
                <h4>
                Sending message to advisor is a premium feature 
                <br />Activate your plan to unlock messaging system.
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn blue"
                data-dismiss="modal"
                onClick={onActivateClick}
              >
                Activate
              </button>
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
 
  const onActivateBtn = e => {
    history.push({ pathname: "/programAndPricing" });
    setOpenPremiumModal(!openPremiumModal);
  }

  useEffect(() => {
    getMessageData()
  }, []);

  const getMessageData = () =>{
    props.getUserdataListStart({
        query: getAdvisorData,
        variables: {
          where: {
            order: 'id desc',
            id: parseFloat(props?.match?.params?.advisorid),
            active: 1
          }
        }
      });
    props.messageCountListStart({
        query: getMessageQuery,
        variables: {
          where:{
                order: 'id desc',
                messagesendto: parseFloat(JSON.parse(localStorage.getItem("user")).id),
                active: 1, 
                istrash: 1,
            }
        }
      });  
      if(JSON.parse(localStorage.getItem('user')).role === 'ATHLETES' || JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN'){
        props.premiumDropdownStart({
          query: premiumApiData,
          variables: {
            where:{
                  order: 'id desc',
                  userid: parseFloat(JSON.parse(localStorage.getItem("user")).id),
                  active: 1,
              }
          }
        });
       } 
       if(JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN'){
        props.athleteParentDropdownStart({
          query: athleteParentQuery,
          variables: {
            where:{
                  order: 'id desc',
                  userid: parseFloat(JSON.parse(localStorage.getItem("user")).athleteLoggedInId),
                  active: 1,
              }
          }
        });  
      }
  }

  useEffect(() => {
    let advisorData = [];
    let data = props?.getUserdata?.data?.data?.length > 0 ? props?.getUserdata?.data?.data?.map(item=>{
      item['senderId'] = item?.userid ?  item?.userid : "";
      item['senderEmail'] = item?.emailid ? item?.emailid : "";
      item['senderName'] = item?.firstname ? item?.firstname.concat(item?.lastname ? " " + item?.lastname : "") : "";
      advisorData.push(item)
    }) : [];
    setAdvisorData(advisorData);
  }, [props.messageList.data]);

  useEffect(()=>{
    let coachData = [];
    let supportData = [];
    let advisorData = [];
    let data = props?.messageCountList?.data?.data?.length > 0 ? props?.messageCountList?.data?.data?.map(item=>{
      if(item.userrole === 'ADVISORS'){
          advisorData.push(item);
      }
      else if(item.userrole === 'ADMIN'){
          supportData.push(item)
      }
      else if(item.userrole === 'COLLEGECOUCHES'){
          coachData.push(item);
      }
    }) : [];
    setCoachCount(coachData && coachData.length ? coachData.filter(item => item.isread === 0).length : "");
    setAdvisorCount(advisorData && advisorData.length ? advisorData.filter(item => item.isread === 0).length : "");
    setSupportCount(supportData && supportData.length ? supportData.filter(item => item.isread === 0).length : "");
  },[props.messageCountList.data]);

  useEffect(()=>{
    if(JSON.parse(localStorage.getItem('user')).role === 'ATHLETES' || JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN'){
      if(props?.premiumDropdown?.data?.data?.length > 0 && props?.premiumDropdown?.data?.data[0]?.subscriptionplanid){
          setIsPremium(1);
          setOpenPremiumModal(false)
      }
      else if(props?.premiumDropdown?.data?.data?.length === 0){
        setOpenPremiumModal(true);
        setIsPremium(0);
      }
    }
  },[props.premiumDropdown.data]);

  useEffect(()=>{
    if(JSON.parse(localStorage.getItem('user')).role === 'GUARDIAN'){
      if(props?.athleteParentDropdown?.data?.data?.length > 0){
        let data = props?.athleteParentDropdown?.data?.data?.map(item=>{
          return {id: item.id, name: item.parentfirstname.concat(item.parentlastname ? ' ' + item.parentlastname : '')}
        });
        setParentData(data)
      }
    }
    },[props.athleteParentDropdown.data])

  return (
    <div className="pageContent">
        <div className="container">
            <div className="notification-with-sidebar">
                <nav className="notification-sidebar">
                    <ul>
                        <li className="button-link dropdown">
                         <Button
                          className="btn lightblue dropdown-toggle"
                          onClick={e => handleClick(e)}
                          >
                           Compose
                          </Button>
                          <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button"
                          }}
                          anchorEl={anchorE1}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "20ch"
                            }
                          }}
                       >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  <MenuItem onClick={handleCoachMenu}>Coach</MenuItem>
                  <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem>
                  <MenuItem onClick={handleSupportMenu}>Support</MenuItem>
                </Menu>
             </li>
             <li onClick={() => history.push({ pathname: "/message/coach"})}>
                <div className = "link-item">
                  <span>Coaches</span> <span>{coachCount ? coachCount : ""}</span>
                </div>
              </li>
              <li onClick={() => history.push({ pathname: "/message/advisor"})}>
                <div className = "link-item">
                  <span>Advisor</span> <span>{advisorCount ? advisorCount : ""}</span>
                </div>
              </li>
              <li onClick={() => history.push({ pathname: "/message/support"})}>
                <div className = "link-item">
                  <span>Support</span> <span>{supportCount ? supportCount : ""}</span>
                </div>
              </li>
              <li onClick={() => history.push({ pathname: "/message/trash"})}>
                <div className = "link-item">
                  <span>Trash</span>
                </div>
              </li>
                    </ul>
                </nav>
                <div className="notification-detail-box">
                    <div className="notification-search-box">
                        <div className="search-box">
                            <h1>New Message</h1>
                        </div>
                    </div>
                    <div className="notification-messageDetail">
                        <div className="message-hereInfo newMessage">
                            <div className="message-replyInfo">
                                <form>
                                    <div className="form-group flexbox">
                                        <label>To:</label>
                                        {advisorData?.length > 0 ?  <span className="toName">{advisorData?.[0]?.senderEmail}</span> : ''}
                                    </div>
                                    <div className="form-group flexbox">
                                        <label>Subject:</label>
                                        <input 
                                        type="text" 
                                        value={subject ? subject : ""} 
                                        disabled = {advisorData?.length > 0 ? ispremium === 1 ? false : ispremium === 0 ? true : '' : true}
                                        onChange={(e)=>{setSubject(e.target.value)}}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Message</label>
                                        <textarea 
                                        placeholder="Enter Your message here..." 
                                        value={message ? message : ""} 
                                        disabled = {advisorData?.length > 0 ? ispremium === 1 ? false : ispremium === 0 ? true : '' : true}
                                        onChange={(e)=>setMessage(e.target.value)}></textarea>
                                    </div>
                                    <div className="form-group">
                                        <button 
                                        type="submit" 
                                        className="btn blue" 
                                        disabled = {advisorData?.length > 0 ? ispremium === 1 ? false : ispremium === 0 ? true : '' : true}
                                        onClick={(e)=>{sendClickBtn(e)}}>Send</button>
                                    </div>
                                </form>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog open = {openPremiumModal}
                onClose={(event, reason)=> {
                    if (reason == "backdropClick" || reason == "escapeKeyDown") {
                        cancelBtnClick()
                    }
                }}
                className="matDialogCustom"
        >
      <PremiumModal 
        onActivateClick={(e)=>onActivateBtn(e)}
        onCancelClick={cancelBtnClick}
      />
    </Dialog>
    </div>
  );
};


const mapStateToProps = state => {
  return {
    getUserdata: state.athlete.dashboard.message.getUserdata,
    messageList: state.athlete.dashboard.message.messageList,
    premiumDropdown: state.athlete.dashboard.message.premiumDropdown,
    messageCountList: state.athlete.dashboard.message.messageCountList,
    athleteParentDropdown: state.athlete.dashboard.composeMessage.athleteParentDropdown
  };
};

const mapDispatchToProps = dispatch => {
    return {
      getUserdataListStart: data => dispatch(getUserdataListStart(data)),
      messageListStart: data => dispatch(messageListStart(data)),
      premiumDropdownStart: data => dispatch(premiumDropdownStart(data)),
      messageCountListStart: data => dispatch(messageCountListStart(data)),
      saveMessageReplyListStart: data => dispatch(saveMessageReplyListStart(data)),
      athleteParentDropdownStart: data => dispatch(athleteParentDropdownStart(data)) 
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorReportComposeMessage);