import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    RENDER_COUNT_START: "RENDER_COUNT_START",
    RENDER_COUNT_SUCCESS: "RENDER_COUNT_SUCCESS",
    RENDER_COUNT_FAILED: "RENDER_COUNT_FAILED",
};

// ============================================ Actions ========================================================
export const renderCountStart = (data) => ({
    type: actionType.RENDER_COUNT_START,
    payload: data,
});
export const renderCountSuccess = (data) => ({
    type: actionType.RENDER_COUNT_SUCCESS,
    payload: data,
});

export const renderCountFailed = (data) => ({
    type: actionType.RENDER_COUNT_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    count: 0,
    error: null,
};

export default function renderCountReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.RENDER_COUNT_START:
            return { ...state, loading: true };

        case actionType.RENDER_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                count: state.count + 1,
                error: null,
            };
        case actionType.RENDER_COUNT_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================
export function* renderCountSaga(action) {
    try {
            yield put(renderCountSuccess({ count: 1}));
    } catch (error) {
        messagePopup("", "Test And Score List failed", "error");
        yield put(renderCountSuccess("Test And Score List failed"));
    }
}


export function* watchRenderCountSaga() {
    yield takeEvery(actionType.RENDER_COUNT_START, renderCountSaga);
}
