import { put, takeEvery } from "redux-saga/effects";
import {
    removeSignupData,
    setAuthToken,
    setUserInfo,
} from "../../../services/authService";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
import history from "../../../services/history";

// ========================================== Action Type =====================================================
export const actionType = {
    SEARCH_USER_START: "SEARCH_USER_START",
    SEARCH_USER_SUCCESS: "SEARCH_USER_SUCCESS",
    SEARCH_USER_FAILED: "SEARCH_USER_FAILED",
    SEARCH_USER_STATE: "SEARCH_USER_STATE",
};

// ============================================ Actions ========================================================

export const searchUserStart = (data) => ({
    type: actionType.SEARCH_USER_START,
    payload: data,
});

export const searchUserState = () => ({
    type: actionType.SEARCH_USER_STATE,
});

export const searchUserSuccess = (data) => ({
    type: actionType.SEARCH_USER_SUCCESS,
    payload: data,
});

export const searchUserFailed = (data) => ({
    type: actionType.SEARCH_USER_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    // loading: false,
    data: null,
    error: null,
};

export default function searchUserReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.SEARCH_USER_STATE:
            return {
                ...state,
                data: null,
                error: null,
            };
        case actionType.SEARCH_USER_START:
            return {
                ...state,
                // loading: true,
            };
        case actionType.SEARCH_USER_SUCCESS:
            return {
                ...state,
                // loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.SEARCH_USER_FAILED:
            return {
                ...state,
                // loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================
export function* searchUserSaga(action) {
    try {
        const response = yield fetchMethod(
            `/api/Userdata/searchForClaim`,
            action.payload.data,
            "post",
            false
        );
        if (response && response.status === 200) {
            if (
                response.data &&
                response.data.response &&
                response.data.response.status === 200
            ) {
                let searchUserData = response.data.response.data
                let totalCount = response.data.response.totalcount
                // action?.payload?.isSearch(true)
                yield put(searchUserSuccess({searchUserData, totalCount}));
            } else if (response.data.response.status === 201) {
                let searchUserData = ''
                let totalCount = 0;
                // action?.payload?.isSearch(true)
                //this code has been commented as per QA bugs in claimProfile 750 in sheet
                // messagePopup("", response.data.response.msg, "warning");
                yield put(searchUserSuccess({ searchUserData, totalCount }));
            }
        } else {
            messagePopup("", "Claim failed", "error");
            yield put(searchUserFailed("Claim failed"));
        }
    } catch (error) {
        messagePopup("", "Claim failed", "error");
        yield put(searchUserFailed("Claim failed"));
    }
}

export function* watchsearchUserSaga() {
    yield takeEvery(actionType.SEARCH_USER_START, searchUserSaga);
}
