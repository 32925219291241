import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  FOLLOWER_COACH_LEVEL_START: "FOLLOWER_COACH_LEVEL_START",
  FOLLOWER_COACH_LEVEL_SUCCESS: "FOLLOWER_COACH_LEVEL_SUCCESS",
  FOLLOWER_COACH_LEVEL_FAILED: "FOLLOWER_COACH_LEVEL_FAILED"
};

// ============================================ Actions ========================================================

export const followerCoachLevelStart = data => ({
  type: actionType.FOLLOWER_COACH_LEVEL_START,
  payload: data
});

export const followerCoachLevelSuccess = data => ({
  type: actionType.FOLLOWER_COACH_LEVEL_SUCCESS,
  payload: data
});

export const followerCoachLevelFailed = data => ({
  type: actionType.FOLLOWER_COACH_LEVEL_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function follwerCoachLevelReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.FOLLOWER_COACH_LEVEL_START:
      return {
        ...state,
        loading: true
      };

    case actionType.FOLLOWER_COACH_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.FOLLOWER_COACH_LEVEL_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* followerCoachLevelSaga(action) {
  try {
    const response = yield fetchMethod(`/api/useracivities/coachfollowersByLevel`,action.payload.variables,'post',true);
    if (response && response.status === 200) {
      yield put(
        followerCoachLevelSuccess({data: response.data.response.data})
      );
    } else {
      messagePopup("", "Follower Coach Level Api failed", "error");
      yield put(followerCoachLevelFailed("Follower Coach Level Api failed"));
    }
  } catch (error) {
    messagePopup("", "Follower Coach Level Api failed", "error");
    yield put(followerCoachLevelFailed("Follower Coach Level Api failed"));
  }
}

export function* watchFollowerCoachLevelSaga() {
  yield takeEvery(actionType.FOLLOWER_COACH_LEVEL_START, followerCoachLevelSaga);
}
