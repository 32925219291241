import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    MATCH_FORGOT_OTP_START: "MATCH_FORGOT_OTP_START",
    MATCH_FORGOT_OTP_SUCCESS: "MATCH_FORGOT_OTP_SUCCESS",
    MATCH_FORGOT_OTP_FAILED: "MATCH_FORGOT_OTP_FAILED",
};

// ============================================ Actions ========================================================
export const matchForgotOtpStart = (data) => ({
    type: actionType.MATCH_FORGOT_OTP_START,
    payload: data,
});

export const matchForgotOtpSuccess = (data) => ({
    type: actionType.MATCH_FORGOT_OTP_SUCCESS,
    payload: data,
});

export const matchForgotOtpFailed = (data) => ({
    type: actionType.MATCH_FORGOT_OTP_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function matchForgotOtpReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.MATCH_FORGOT_OTP_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.MATCH_FORGOT_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.MATCH_FORGOT_OTP_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* matchForgotOtpSaga(action) {
    try {
        const response = yield fetchMethod(
            "/api/Userdata/matchForgotOtp",
            action.payload.variables,
            action.payload.method,
            false
        );
        if (response && response.data && response.data.response && response.data.response.status == 200) {
            action.payload.nextScreen();
            yield put(matchForgotOtpSuccess({data:response.data.response.msg}));
        } else {
            messagePopup("", "Please enter your valid passcode.", "error");
            yield put(matchForgotOtpFailed("Please enter your valid passcode."));
        }
    } catch (error) {
        messagePopup("", "Please enter your valid passcode.", "error");
        yield put(matchForgotOtpFailed("Please enter your valid passcode."));
    }
}

//Watcher
export function* watchMatchForgotOtpSaga() {
    yield takeEvery(actionType.MATCH_FORGOT_OTP_START, matchForgotOtpSaga);
}
