import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod } from "../../../services/httpService"
import messagePopup from "../../../utils/messagePopup"
import {getCollegeLevelQuery} from "../../../components/views/athlet/dashboard/viewCommitments/viewCommitmentsQueries";

// ========================================== Action Type =====================================================
export const actionType = {
    COLLEGE_LEVEL_LIST_START: 'COLLEGE_LEVEL_LIST_START',
    COLLEGE_LEVEL_LIST_SUCCESS: 'COLLEGE_LEVEL_LIST_SUCCESS',
    COLLEGE_LEVEL_LIST_FAILED: 'COLLEGE_LEVEL_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const collegeLevelListStart = (data) => ({
    type: actionType.COLLEGE_LEVEL_LIST_START,
    payload: data
})

export const collegeLevelListSuccess = (data) => ({
    type: actionType.COLLEGE_LEVEL_LIST_SUCCESS,
    payload: data
})

export const collegeLevelListFailed = (data) => ({
    type: actionType.COLLEGE_LEVEL_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function collegeLevelListReducer(state = INIT_STATE,action){
    switch(action.type){
        case actionType.COLLEGE_LEVEL_LIST_START:
            return {
                ...state,
                loading: true
            }
        case actionType.COLLEGE_LEVEL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        case actionType.COLLEGE_LEVEL_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        default: return {...state}
    }
}

// ===================================================== side effect =============================================

export function* collegeLevelListSaga(action){
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true);
        if(response && response.status === 200){
            const collegeLevelList = response.data.data.allColleges.Colleges ? response.data.data.allColleges.Colleges : null
            yield put(collegeLevelListSuccess(collegeLevelList));
        } else {
            messagePopup('','College Level List failed','error')
            yield put(collegeLevelListFailed('College Level List failed'))
        }
    } catch (error) {
        messagePopup('',' College Level List failed','error')
        yield put(collegeLevelListFailed('College Level List failed'))
    }
}

export function* watchCollegeLevelListSaga(){
    yield takeEvery(actionType.COLLEGE_LEVEL_LIST_START, collegeLevelListSaga)
}