import React, { useState, useEffect } from "react";
import NcaaIcon from "../assets/img/event_default.png";
import { connect } from "react-redux";
import { getUserInfo } from "../../../../../../services/authService";
import { useHistory } from "react-router-dom";
import { allTeamsListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/allTeams";
import {
  getSchoolTeamUserMappingForTeams,
  getTeamQuery,
} from "../../editUserProfile/teams/TeamQuery";
import { schoolTeamUserMappingStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/schoolTeamUserMapping";

function MyTeams(props) {
  let history = useHistory();
  const [teamData, setTeamData] = useState(null);
  const [twoTeams, setTwoTeams] = useState(null);
  useEffect(() => {
    props.schoolTeamUserMappingStart({
      query: getSchoolTeamUserMappingForTeams,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  }, []);


  useEffect(() => {
    if (
      props.schoolTeamUserMapping.data &&
      props.schoolTeamUserMapping.data.schoolTeamUserMapping &&
      props.schoolTeamUserMapping.data.schoolTeamUserMapping
        .Schoolteamusermappings
    ) {
      let teamArrayT = [];
      for (const school of props.schoolTeamUserMapping.data
        .schoolTeamUserMapping.Schoolteamusermappings) {
        if (
          school.schoolteamusermappingschoolidmaprel &&
          school.schoolteamusermappingschoolidmaprel.Athleteschools &&
          school.schoolteamusermappingschoolidmaprel.Athleteschools[0]
        ) {
          teamArrayT.push({
            ...school.schoolteamusermappingschoolidmaprel.Athleteschools[0],
            userMappingid: school.id,
            isSchool: true,
            isPrimary: school.primaryteamstatus ? true : false,
            isSecondary: school.secondaryteamstatus ? true : false,
          });
        }
        if (
          school.schoolteamusermappingteamidmaprel &&
          school.schoolteamusermappingteamidmaprel.Teams &&
          school.schoolteamusermappingteamidmaprel.Teams[0]
        ) {
          teamArrayT.push({
            ...school.schoolteamusermappingteamidmaprel.Teams[0],
            userMappingid: school.id,
            isSchool: false,
            isPrimary: school.primaryteamstatus ? true : false,
            isSecondary: school.secondaryteamstatus ? true : false,
          });
        }
      }

      let prevTeamData = {};
      let prevSchoolData = {};
      let finalData = [];
      for (const data of teamArrayT) {
        if (!data.isSchool) {
          if (!prevTeamData[data.id]) {
            prevTeamData[data.id] = { ...data, name: data.teamname };
          }
        } else {
          if (!prevSchoolData[data.id]) {
            prevSchoolData[data.id] = { ...data, name: data.highschoolname };
          }
        }
      }

      for (const [_, value] of Object.entries(prevTeamData)) {
        finalData = [...finalData, value];
      }
      for (const [_, value] of Object.entries(prevSchoolData)) {
        finalData = [...finalData, value];
      }
      finalData.sort((a, b) => {
        return b.userMappingid - a.userMappingid;
      });

      const teamDataList = [...finalData];
      setTeamData(teamDataList);
      const topTwoTeam = teamDataList.slice(0, 2);
      setTwoTeams(topTwoTeam);
    }
  }, [props.schoolTeamUserMapping.data]);

  const routeToPage = (e, route) => {
    e.preventDefault();
    history.push(route);
  };

  return (
    <div>
      <div className="myTeam">
        <div className="head">
          <h5 className="title">My Teams</h5>
          <a
            href={void 0}
            onClick={(e) => {
              routeToPage(e, "/teams");
            }}
            className="editLink"
          >
            {twoTeams && twoTeams.length ? "Edit" : "Add"}
          </a>
        </div>
        <ul>
          {twoTeams && twoTeams.length ? (
            twoTeams.map((item, index) => (
              <li key={index}>
                <div className="imgBox">
                  <img src={!!item?.teamlogo ? item?.teamlogo : NcaaIcon} alt="ncaa" />
                </div>
                <div className="info d-block">
                  <span>
                    {item.name} <br /> {item.city} {!!item.state ? " - "+ item?.state : ""} 
                  </span>
                </div>
              </li>
            ))
          ) : (
            <div
              style={{
                textAlign: "left",
                fontSize: "14px",
                padding: "0px 6px",
              }}
            >
              No team is added yet
            </div>
          )}
        </ul>
        {teamData && teamData.length > 2 ? (
          <p className="viewMore">
            <a
              href={void 0}
              onClick={(e) => {
                routeToPage(e, "/teams");
              }}
            >
              View More
            </a>
          </p>
        ) : null}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    schoolTeamUserMapping:
      state.athlete.editUserProfileData.schoolTeamUserMapping,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    schoolTeamUserMappingStart: (data) =>
      dispatch(schoolTeamUserMappingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTeams);
