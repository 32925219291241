import {Dialog, FormControl, ListSubheader, Tooltip} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  checkFilterStatusStart,
  resetCheckFilterStart,
} from "../../../../../../redux/athlete/dashboard/colleges/checkSearchFilter";
import { saveSearchFilterStart } from "../../../../../../redux/athlete/dashboard/colleges/saveSearchFilter";
import { getUserInfo } from "../../../../../../services/authService";
import BackdropLoader from "../../../../../common/Loader";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import { regionsListStart } from "../../../../../../redux/common/allRegions";
import { academicSelectivitiesQuery, allCollegeSettingsQuery, collegeSizesQuery, getAllRegionsQuery, getAllTutionCostsQuery } from "./SearchCollegesQuery";
import { searchStateByRegionStart } from "../../../../../../redux/athlete/dashboard/colleges/searchStateByRegion";
import { data } from "jquery";
import { select } from "@redux-saga/core/effects";
import { allCollegeDetailsListStart } from "../../../../../../redux/common/allCollegeData";
import { getAllCollegeQuery } from "../../profile/ProfileEdit/profileQuery";
import messagePopup from "../../../../../../utils/messagePopup";
import { getCollegeDivisions } from "../../editUserProfile/AcademicsQuery";
import { allAcademicDivisionsListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/academicDivisions";
import { collegeDivisionListStart } from "../../../../../../redux/athlete/profile/collegeDivisionLevel";
import { fetchGraphMethod } from "../../../../../../services/httpService";
import { allCollegeConferencesQuery } from "../../viewCommitments/viewCommitmentsQueries";
import { allCollegeConferencesListStart } from "../../../../../../redux/common/allCollegeConferences";
import toastr from "toastr";
import Swal from 'sweetalert2'

const checkboxData = {
  type: ["Public", "Private"],
};

function Filter(props) {
  const [fields, setFields] = useState({
    filterName: "",
  });
  const [states, setStates] = useState();
  const [allStates, setAllStates] = useState();
  const [expandFields, setExpandFields] = useState({});
  const [isModal, setModal] = useState(false);
  const [timeout, settimeout] = useState(0);
  const [regions, setRegions] = useState();
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [statesByRegions, setStateByRegions] = useState();
  const [stateNamesArray, setStateNamesArray] = useState();
  const [selectAllStates, setSelectAllStates] = useState(false);
  const [collegeDivisions, setCollegeDivisions] = useState();
  const [tuitionCosts, setTuitionCosts] = useState();
  const [collegeSizes, setCollegeSizes] = useState();
  const [collegesettings, setCollegeSettings] = useState();
  const [academicSelectivities, setAcademicSelectivities] = useState();
  const [allCollegeConference, setAllCollegeConference] = useState([]);
  const [levelItemArray, setSubLevelItemArray] = useState([])
  const [subDivisionLevel, setSubDivisionLevel] = useState([])
  const [level, setLevel] = useState([])
  useEffect(() => {
    if (props.allRegions.data) {
      setRegions(props.allRegions.data);
    }
  }, [props.allRegions.data])
  useEffect(() => {
    if (props?.fields?.leveliddata) {
      let arrayOfStrings = props.fields.leveliddata
      setLevel(arrayOfStrings.map(Number));
    }
  }, [props.fields.leveliddata])

  useEffect(() => {
    if (props?.fields?.athleticselectivityid && levelItemArray != "") {
      props.fields.athleticselectivityid.map(selectedId => {
        levelItemArray.map(item => {
          if (item.id == selectedId.label) {
            item.selected = selectedId.subLevel
          }
        })
      })

    }
  }, [props.fields.athleticselectivityid])

  useEffect(() => {
    if (props?.fields?.selectedRegions && props?.fields?.selectedRegions?.length) {
      setSelectedRegions(props?.fields?.selectedRegions)
    }
    if(props?.fields?.selectAllStates){
      setSelectAllStates(props?.fields?.selectAllStates)
    }
  }, [props.fields.selectedRegions])

  useEffect(() => {
    if (props.collegeDivision.data) {
      setCollegeDivisions(props.collegeDivision.data);
    }
  }, [props.collegeDivision.data])

  useEffect(() => { 
    setSelectAllStates(props?.fields?.selectAllStates)
  }, [props.fields.selectAllStates])

  //fun cused to return !D array only
 const getSelectedData = (item) =>{
    let selData = []
    if(props?.fields && props?.fields?.athleticselectivityid?.length > 0){
      props?.fields.athleticselectivityid?.map(itemSubLevel => {
          return itemSubLevel?.subLevel?.length > 0 ? itemSubLevel?.subLevel.map((sel, idx)=>{
         if (itemSubLevel.label == item.id) {
           selData.push(sel)
         }
        }) : []
      })
    }
    return selData
  }

  useEffect(() => {
    if (props?.collegeDivision?.data?.length > 0) {
      let data = props?.collegeDivision?.data?.map(item => {
        item["id"] = item.id;
        item["subLevelArray"] = item?.divisionathleticselectivitymappingCollegedivisionidMap?.Divisionathleticselectivitymappings?.map(subItem => {
          subItem?.divisionathleticselectivitymappingathleticselectivityidmaprel?.Athleticselectivities?.map(subitem1 => subitem1["parent"] = item.id)
          return subItem?.divisionathleticselectivitymappingathleticselectivityidmaprel?.Athleticselectivities?.[0];
        })
        item["selected"] = getSelectedData(item)//this fun cis use to return 1D array as earlier it return 2D array
        // props.fields && props.fields.athleticselectivityid ? props.fields.athleticselectivityid.map(itemSubLevel => {
        //   if (itemSubLevel.label == item.id) {
        //     return itemSubLevel?.subLevel
        //   }
        // }) : []
        return item;
      })
      setSubLevelItemArray(data)
    }
  }, [props?.collegeDivision?.data])

  const getAllDivisionsData = () => {
    props.collegeDivisionListStart({
      query: getCollegeDivisions,
    });
  }
  const getAllTuitionCostData = async () => {
    const variables = null;
    const res = await fetchGraphMethod(
      getAllTutionCostsQuery,
      variables,
      true
    )
    let data = res?.data?.data?.allTutioncosts?.Tutioncosts;
    setTuitionCosts(data);
  }
  const getAllCollegeSettingsData = async () => {
    const variables = null;
    const res = await fetchGraphMethod(
      allCollegeSettingsQuery,
      variables,
      true
    )
    let data = res?.data?.data?.allCollegesettings?.Collegesettings;
    setCollegeSettings(data);
  }
  const handleExpandCollapse = (e, flag) => {
    e.preventDefault();
    const expandFieldsT = expandFields;
    expandFieldsT[flag] = !expandFieldsT[flag];
    setExpandFields({ ...expandFieldsT });
  };
  const getALlCollegeSizesData = async () => {
    const variables = null;
    const res = await fetchGraphMethod(
      collegeSizesQuery,
      variables,
      true
    )
    let data = res?.data?.data?.allCollegesizes?.Collegesizes;
    setCollegeSizes(data);
  }
  const getAllAcademicSelectivities = async () => {
    const variables = null;
    const res = await fetchGraphMethod(
      academicSelectivitiesQuery,
      variables,
      true
    )
    const data = res?.data?.data?.allAcadamicselectivities?.Acadamicselectivities;
    setAcademicSelectivities(data);
  }
  useEffect(() => {
    getAllRegionsList();
    getAllDivisionsData();
    getAllTuitionCostData();
    getALlCollegeSizesData();
    getAllAcademicSelectivities();
    getAllCollegeSettingsData();
    getAllCollegeConferenceList();
  }, [])
  useEffect(() => {
    let states = [];
    const regionStates = statesByRegions ? statesByRegions.map((state) => {
      if (selectedRegions) {
        states.push(state.stateName)
      }
    }) : null;
    setStateNamesArray(states)
    // handleStateSelect({
    //   target: { value: states, name: "StateRegion" },
    // })

  }, [statesByRegions, selectedRegions])

  useEffect(() => {
    if (props.stateByRegionList.data !== undefined) {
      setStateByRegions(props.stateByRegionList.data)
    }
    else {
      setStateByRegions([]);
      props.fields.StateRegion = [];
    }
  }, [props.stateByRegionList.data])
  useEffect(() => {
    if (props.checkSearchFilter.data && props.checkSearchFilter.data.status) {
      const fieldsT = fields;
      fieldsT["filterStatus"] = props.checkSearchFilter.data.status;
      setFields({ ...fieldsT });
    }
  }, [props.checkSearchFilter.data]);

  const openSaveFilter = () => {
    if (props?.allFilters?.length >= 10) {
      // messagePopup("", "Only 10 filters can be saved at a time", "warning");
      toastr.warning("Only 10 filters can be saved at a time", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    } else {
      setModal(true);
      props.resetCheckFilterStart();
    }
  };
  const getAllRegionsList = () => {
    props.regionsListStart({
      query: getAllRegionsQuery
    })
  }
  const handleChange = (e, field) => {
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setFields({ ...fieldsT });

    props.searchOnCollegeCityName();
  };
  useEffect(() => {
    if (props.collegeConferences.data) {
      if (props?.collegeConferences?.data?.length > 0){
        //done as per change doc aug 2022
        props?.collegeConferences?.data?.sort((a, b)=>{
          return  a.conferencenmae?.split(" ")?.[0]?.toLowerCase() > b.conferencenmae?.split(" ")?.[0]?.toLowerCase() ? 1
              : b.conferencenmae?.split(" ")?.[0]?.toLowerCase() > a.conferencenmae?.split(" ")?.[0]?.toLowerCase() ? -1 : 0
        })
      }
      setAllCollegeConference(props.collegeConferences.data)
    }
  }, [props.collegeConferences.data])

  const getAllCollegeConferenceList = () => {
    props.allCollegeConferencesListStart({
      query: allCollegeConferencesQuery
    })
  }

  const searchConference = (search) => {
    if (timeout) clearTimeout(timeout);
    const timeoutT = setTimeout(() => {
      const conference = props.collegeConferences.data ? props.collegeConferences.data.filter((item) => {
        if (item.conferencenmae?.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        return false;
      }) : [];
      setAllCollegeConference([...conference]);
    }, 300);
    settimeout(timeoutT);
  }
  const handleSelectConferences = (e, conferenceid, field) => {
    if (props.fields.conference.length >= 10 && e.target.checked == true) {
      // messagePopup("", "You can select 10 conferences at a time.", "warning");
      toastr.warning("You can select 10 conferences at a time.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    } else {
      props.handleMultiCheckbox(e, conferenceid, field);
    }
  }

  const handleDropDownChange = async (conference) => {
    const variables = {
      obj: {
        conferencename: {
          like: `%${conference.target.value}%`
        }
      }
    }

    if (conference.target.value.toString().length > 2) {
      await props.allCollegeDetailsListStart({
        query: getAllCollegeQuery,
        variables
      });
      setAllCollegeConference(props.collegeList.data)

    }
    props.handleInputFields(conference);
  };
  const handleSelectAll = (e) => {
    setSelectAllStates(e.target.checked)
    let stateData = [];
    if (props.stateList) {
      if (e.target.checked == true) {
        const states = props.stateList ? props.stateList.map((state) => {
          stateData.push(state.statename);
        }) : null;
        setSelectedRegions([]);
        setStateNamesArray([]);
        setStateByRegions([]);
        props.fields.StateRegion = stateData;

      } else {
        stateData = [];
        setStateNamesArray([]);
        props.fields.StateRegion = [];
        setSelectedRegions([]);
      }
      handleStateSelect({
        target: { value: stateData, name: "StateRegion" },
      })
    }
  }

  const handleSelectState = (e, data) => {
    let stateData = [];
    if (selectAllStates == true) {
      const states = props.stateList ? props.stateList.map((state) => {
        stateData.push(state.statename);
      }) : null;
      props.fields.StateRegion = stateData;
    }
    if (props.fields.StateRegion?.length == 0) {
      props.fields.StateRegion.push(data.statename);
    } else {
      const isAlreadyExistState = props.fields.StateRegion ? props.fields.StateRegion.includes(data.statename) : null;
      if (isAlreadyExistState == true) {
        const stateIndex = props.fields.StateRegion.indexOf(data.statename);
        props.fields.StateRegion.splice(stateIndex, 1);
      } else {
        props.fields.StateRegion.push(data.statename);
      }
      setSelectAllStates(false);
      setStateNamesArray(props.fields.StateRegion);
    }
    if (props.fields.StateRegion.length == props.stateList.length) {
      setSelectAllStates(true);
    }
    if (e.target.checked && props.fields.searchStateRegion) {
      props.fields.searchStateRegion = "";
    }
    if (e.target.checked == false) { // uncheck the region if any of its state is deselected
      const ifRegionIsSelected = statesByRegions ? statesByRegions.filter((region) => {
        return region.id == data.id
      }) : null;
      let regionId = ifRegionIsSelected && ifRegionIsSelected[0] && ifRegionIsSelected[0].regionid;

      const regionIndex = selectedRegions.indexOf(regionId);
      selectedRegions.splice(regionIndex, 1);
    }
    handleStateSelect({
      target: { value: props.fields.StateRegion, name: "StateRegion" },
    })
  }
  const getStatesByRegion = async (e, data) => {
    if (e.target.checked && props.fields.searchStateRegion) {
      props.fields.searchStateRegion = "";
    }
    if (selectedRegions?.length == 0) {
      selectedRegions.push(data.id)
    } else {
      const isAlreadyExist = selectedRegions ? selectedRegions.includes(data.id) : null;
      if (isAlreadyExist == true) {
        const regionIndex = selectedRegions.indexOf(data.id);
        selectedRegions.splice(regionIndex, 1);
      } else {
        selectedRegions.push(data.id)
      }
    }
    
    if(selectedRegions.length){
      props.searchStateByRegionStart({
        data: {
          regionId: selectedRegions
        },
        nextRoute: nextRoute,
        checked: e.target.checked,
      })
    }
    else{
      props.fields.StateRegion = []
      setStateNamesArray([])
      handleStateSelect({
        target: { value: [], name: "StateRegion" },
      })
    }
  }

  const nextRoute = (checked, states) => {
    if(states.length){
      //messagePopup("", ` Your search is applied to following states: ${states} `, "success")
      handleStateSelect({
        target: { value: states, name: "StateRegion" },
      })
    }
  }

  const handleStateSelect = (e, customState) => {
    props.handleInputFields(e, customState);
  }

  const closeSaveFilter = () => {
    setModal(false);
    setFields({ filterName: "" });
  };

  const handleInputChange = (e) => {
    const fieldsT = fields;
    fieldsT["filterName"] = e.target.value;
    setFields({ ...fieldsT });
    const filterName = e.target.value;
    if (filterName) {
      if (timeout) clearTimeout(timeout);
      const timeoutT = setTimeout(() => {
        props.checkFilterStatusStart({
          userId: getUserInfo() ? getUserInfo().id : 0,
          filterName,
        });
      }, 300);
      settimeout(timeoutT);
    }
  };

  useEffect(() => {
    if (!props.fields.searchStateRegion || props.fields.searchStateRegion.length == 0) {
      const newStates = states
        ? states.filter((item) => {
          if (
            item.statename?.toLowerCase().includes(props.fields.searchStateRegion.toLowerCase())
          ) {
            return true;
          }
          return false;
        })
        : [];
      if (props.fields.searchStateRegion && newStates?.length !== 0) {
        setStates([...newStates]);
      } else if (props.fields.searchStateRegion?.length == 0 || props.fields.searchStateRegion == "") {
        setStates(props.stateList);
        setAllStates(props.stateList)
      } else if (newStates?.length == 0) {
        setStates([...newStates]);
      }
      const newRegions = regions
        ? regions.filter((item) => {
          if (
            item.regionname?.toLowerCase().includes(props.fields.searchStateRegion.toLowerCase())
          ) {
            return true;
          }
          return false;
        })
        : [];
      if (props.fields.searchStateRegion && newRegions?.length !== 0) {
        setRegions([...newRegions]);
      } else if (props.fields.searchStateRegion?.length == 0) {
        setRegions(props.allRegions.data);
      } else if (newRegions?.length == 0) {
        setRegions([...newRegions]);
      }
    }
    if(props?.fields?.selectAllStates){
      setSelectAllStates(props?.fields?.selectAllStates)
    }
  }, [props.fields.searchStateRegion, props.stateList])

  const searchStateRegion = (search) => {
    props.fields.searchStateRegion = search;
    const newStates = props.stateList
      ? props.stateList.filter((item) => {
        if (
          item.statename?.toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        }
        return false;
      })
      : [];
    if (search && newStates?.length !== 0) {
      setStates([...newStates]);
    } else if (search?.length == 0) {
      setStates(props.stateList);
    } else if (newStates?.length == 0) {
      setStates([...newStates]);
    }
    const newRegions = props.allRegions.data
      ? props.allRegions.data.filter((item) => {
        if (
          item.regionname?.toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        }
        return false;
      })
      : [];
    if (search && newRegions?.length !== 0) {
      setRegions([...newRegions]);
    } else if (search?.length == 0) {
      setRegions(props.allRegions.data);
    } else if (newRegions?.length == 0) {
      setRegions([...newRegions]);
    }
  };

  const getFinalFilterData = () => {
    let finaldata = [];
    for (const [key, value] of Object.entries(props.fields)) {
      if (value) {
        if (key !== "collegeName") {
          finaldata = [
            ...finaldata,
            {
              searchFilterName: `${key}`.toLowerCase(),
              searchFilterValue: value,
            },
          ];
        }
      }
    }
    return finaldata;
  };

  const saveFilter = () => {
    if (fields.filterName) {
      props.saveSearchFilterStart({
        userId: getUserInfo() ? getUserInfo().id : 0,
        filterName: fields.filterName,
        searchCondition: getFinalFilterData(),
        filterOnsearch: "",
        closeSaveFilter: closeSaveFilter,
        refreshFilterList: props.refreshFilterList,
      });
    } else {
      // messagePopup("", "Please enter a filter name.", "warning");
      toastr.warning("Please enter a filter name.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }
  };

  const handleCheckDivisionBox = (e, type, label, item) => {
    if (e.target.checked) {
      let newValue = levelItemArray?.map(newValue => {
        if (parseFloat(newValue.id) === parseFloat(item.id)) {
          return { ...newValue, selected: [...newValue.selected, label] }
        }
        return newValue;
      })
      setSubLevelItemArray([...newValue]);
      let data = subDivisionLevel?.map(newValue => {
        if (parseFloat(newValue.label) === parseFloat(item.id)) {
          return { ...newValue, subLevel: [...newValue.subLevel, label] }
        }
        return newValue;
      })
      setSubDivisionLevel([...data]);
      props.handleSubLavelFields('athleticselectivityid', [...data])
    }
    else {
      let newValue = levelItemArray?.map(newValue => {
        if (parseFloat(newValue.id) === parseFloat(item.id)) {
          return { ...newValue, selected: newValue.selected.filter(newlabel => newlabel != label) }
        }
        return newValue;
      })
      setSubLevelItemArray([...newValue]);
      let data = subDivisionLevel?.map(newValue => {
        if (parseFloat(newValue.label) === parseFloat(item.id)) {
          return { ...newValue, subLevel: newValue.subLevel.filter(newlabel => newlabel != label) }
        }
        return newValue;
      })
      setSubDivisionLevel([...data]);
      props.handleSubLavelFields('athleticselectivityid', [...data])
    }
  }

  const handleCheckBox = (e, type, label, item) => {
    if (e.target.checked) {
      setLevel(level.concat([label]))
      subDivisionLevel.push({ label: label, subLevel: [] })
      props.handleLavelFields('levelidData', level.concat([label]))
      const expandFieldsT = expandFields;
      expandFieldsT[label] = true;
      setExpandFields({ ...expandFieldsT });
    } else {
      const arr = level.filter(function (item) {
        return item !== label
      });
      const newArray = subDivisionLevel.filter(item => item.label !== label);
      const newLevelItems = levelItemArray.map(item => {
          if(item.id == label){
              item.selected = []
          }
          return item;
      })
      setSubLevelItemArray(newLevelItems)
      setSubDivisionLevel(newArray)
      setLevel(arr)
      props.handleLavelFields('levelidData', arr)
    }

  }
const getPop = () =>{
  Swal.fire({
    text:"",
    html: 'Based NCES Locale Classification index -' +
        '<a download="pdf" target="_blank" href="https://nces.ed.gov/programs/edge/docs/LOCALE_CLASSIFICATIONS.pdf">https://nces.ed.gov/programs/edge/docs/LOCALE_CLASSIFICATIONS.pdf</a>',
    confirmButtonColor: "#00B9FF"
  })
}
  return (
    <div>
      <div className="closeBtnBox">
        <h5>Filter</h5>
        <button onClick={props.closeFilter}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.804"
            height="15.803"
            viewBox="0 0 15.804 15.803"
          >
            <g className="a" transform="translate(-6.776 -6.776)">
              <path
                d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                transform="translate(0 0)"
              ></path>
            </g>
          </svg>
        </button>
      </div>
      <div className="searchBox">
        <input
          type="text"
          name="collegeName"
          placeholder="College Name or City"
          value={props.fields.collegeName}
          onChange={props.handleInputFields}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              props.searchOnCollegeCityName()
            }
          }}
        />
        <div className="searchBtn">
          <button onClick={props.searchOnCollegeCityName}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.811"
              height="13.811"
              viewBox="0 0 13.811 13.811"
            >
              <g className="a" transform="translate(-3.75 -3.75)">
                <path
                  className="b"
                  d="M15.167,9.833A5.333,5.333,0,1,1,9.833,4.5,5.333,5.333,0,0,1,15.167,9.833Z"
                />
                <path
                  className="b"
                  d="M27.875,27.875l-2.9-2.9"
                  transform="translate(-11.375 -11.375)"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div className="filterActionBox">
        <div className="flexBox">
          <button
            type="button"
            data-toggle="modal"
            data-target="#saveFilterModal"
            className="saveFilterBtn"
            onClick={openSaveFilter}
          >
            Save Filters
          </button>
          <div
            className={`filterBox ${expandFields["dropdownMenuButton"] ? "show" : ""
              }`}
          >
            <button className="filterBtn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.399"
                height="19.4"
                viewBox="0 0 19.399 19.4"
              >
                <path
                  className="a"
                  d="M19.142,0H1.258A.758.758,0,0,0,.5.758,7.4,7.4,0,0,0,2.982,6.285L6.3,9.235a2.7,2.7,0,0,1,.906,2.017v7.39a.758.758,0,0,0,1.178.63l4.471-2.981a.758.758,0,0,0,.337-.631V11.251A2.7,2.7,0,0,1,14.1,9.235l3.319-2.95A7.4,7.4,0,0,0,19.9.758.758.758,0,0,0,19.142,0ZM16.41,5.152,13.092,8.1a4.217,4.217,0,0,0-1.414,3.149v4l-2.955,1.97V11.252A4.217,4.217,0,0,0,7.308,8.1L3.989,5.152A5.887,5.887,0,0,1,2.065,1.515h16.27A5.885,5.885,0,0,1,16.41,5.152Zm0,0"
                  transform="translate(-0.5 0)"
                />
              </svg>
            </button>
            <button
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={(e) => handleExpandCollapse(e, "dropdownMenuButton")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="451.847px"
                height="451.847px"
                viewBox="0 0 451.847 451.847"
                space="preserve"
              >
                <g>
                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </g>
              </svg>
            </button>
            <div
              className={`dropdown-menu ${expandFields["dropdownMenuButton"] ? "show" : ""
                }`}
              aria-labelledby="dropdownMenuButton"
            >
              <ul>
                <li>
                  <h5>List of Filters</h5>
                </li>
                {props.filterList &&
                  props.filterList.map((val, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to="#"
                          onClick={(e) => props.handleFilterClick(e, val)}
                        >
                          {val.filtername}{" "}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*//below code is commented by akhtar 9/5/2022*/}
      {/* <div className="lockedBox">
        <h5 className="heading">
          College Matches{" "}
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.286"
              height="15.5"
              viewBox="0 0 13.286 15.5"
            >
              <g transform="translate(-4.5 -2.25)">
                <path
                  className="a"
                  d="M15.571,8.893a2.214,2.214,0,0,1,2.214,2.214v4.429a2.214,2.214,0,0,1-2.214,2.214H6.714A2.214,2.214,0,0,1,4.5,15.536V11.107A2.214,2.214,0,0,1,6.714,8.893V6.679a4.429,4.429,0,1,1,8.857,0ZM11.143,3.726A2.952,2.952,0,0,1,14.1,6.679V8.893H8.19V6.679a2.952,2.952,0,0,1,2.952-2.952Zm4.429,6.643H6.714a.738.738,0,0,0-.738.738v4.429a.738.738,0,0,0,.738.738h8.857a.738.738,0,0,0,.738-.738V11.107A.738.738,0,0,0,15.571,10.369Z"
                />
              </g>
            </svg>
          </span>
        </h5>
        <span>Unlock with Premium</span>
      </div> */}
      {/* <div className="lockedBox">
        <h5 className="heading">
          Colleges Following{" "}
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.286"
              height="15.5"
              viewBox="0 0 13.286 15.5"
            >
              <g transform="translate(-4.5 -2.25)">
                <path
                  className="a"
                  d="M15.571,8.893a2.214,2.214,0,0,1,2.214,2.214v4.429a2.214,2.214,0,0,1-2.214,2.214H6.714A2.214,2.214,0,0,1,4.5,15.536V11.107A2.214,2.214,0,0,1,6.714,8.893V6.679a4.429,4.429,0,1,1,8.857,0ZM11.143,3.726A2.952,2.952,0,0,1,14.1,6.679V8.893H8.19V6.679a2.952,2.952,0,0,1,2.952-2.952Zm4.429,6.643H6.714a.738.738,0,0,0-.738.738v4.429a.738.738,0,0,0,.738.738h8.857a.738.738,0,0,0,.738-.738V11.107A.738.738,0,0,0,15.571,10.369Z"
                />
              </g>
            </svg>
          </span>
        </h5>
        <span>Unlock with Premium</span>
      </div> */}

      <ul>
        <li>
          <button
            className={`btn btn-primary ${expandFields["favorite"] ? "" : "collapsed"
              }`}
            type="button"
          >
            <h5>Favorites</h5>
            <span className="icon"
                  onClick={(e) => handleExpandCollapse(e, "favorite")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="451.847px"
                height="451.847px"
                viewBox="0 0 451.847 451.847"
                space="preserve"
              >
                <g>
                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </g>
              </svg>
            </span>
          </button>
          <div
            className={expandFields["favorite"] ? "show" : "collapse"}
            id="Favorites"
          >
            <div className="card card-body">
              <ul className="radioGroup">
                <li>
                  <span>
                    <input
                      type="radio"
                      name="favorite"
                      onChange={(e) => props.handleInputFields(e)}
                      checked={props.fields.favorite === "any"}
                      value="any"
                    />
                    <label>Any</label>
                  </span>
                </li>
                <li>
                  <span>
                    <input
                      type="radio"
                      name="favorite"
                      onChange={(e) => props.handleInputFields(e)}
                      checked={props.fields.favorite === "true"}
                      value="true"
                    />
                    <label>Is a Favorite</label>
                  </span>
                </li>
                <li>
                  <span>
                    <input
                      type="radio"
                      name="favorite"
                      onChange={(e) => props.handleInputFields(e)}
                      checked={props.fields.favorite === "false"}
                      value="false"
                    />
                    <label>Not a Favorite</label>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </li>
        {/*//below code is commented by akhtar 9/5/2022 when take pull*/}
        {/* <li>
          <button
            className={`btn btn-primary ${expandFields["promotion"] ? "" : "collapsed"
              }`}
            type="button"
            onClick={(e) => handleExpandCollapse(e, "promotion")}
          >
            <h5>Promotions</h5>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="451.847px"
                height="451.847px"
                viewBox="0 0 451.847 451.847"
                space="preserve"
              >
                <g>
                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </g>
              </svg>
            </span>
          </button>
          <div
            className={expandFields["promotion"] ? "show" : "collapse"}
            id="Promotions"
          >
            <div className="card card-body">
              <ul className="radioGroup">
                <li>
                  <span>
                    <input
                      type="radio"
                      name="promotions"
                      onChange={(e) => props.handleInputFields(e)}
                      checked={props.fields.promotions === "any"}
                      value="any"
                    />
                    <label>Any</label>
                  </span>
                </li>
                <li>
                  <span>
                    <input
                      type="radio"
                      name="promotions"
                      onChange={(e) => props.handleInputFields(e)}
                      checked={props.fields.promotions === "true"}
                      value="true"
                    />
                    <label>Coach Has Promoted</label>
                  </span>
                </li>
                <li>
                  <span>
                    <input
                      type="radio"
                      name="promotions"
                      onChange={(e) => props.handleInputFields(e)}
                      checked={props.fields.promotions === "false"}
                      value="false"
                    />
                    <label>Coach Has Not Promoted</label>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </li> */}
        <li>
          <button
              className={`btn btn-primary ${expandFields["Level"] ? "" : "collapsed"
              }`}
              type="button"
          >
            <span>
              <h5>Athletic Selectivity
                <span
                style={{marginLeft:"5px"}}
                onClick={()=>props?.toolTipPop('Selectivity based on a combination of strength of RPI, ranking, conference, and expert evaluation.')}
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                >
                  <path
                      className="a"
                      d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                      transform="translate(-3 -3)"
                  />
                </svg>
              </span>
              </h5>
            </span>
            <span className="icon"
                  onClick={(e) => handleExpandCollapse(e, "Level")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="451.847px"
                height="451.847px"
                viewBox="0 0 451.847 451.847"
                space="preserve"
              >
                <g>
                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </g>
              </svg>
            </span>
          </button>
          <div
            className={expandFields["Level"] ? "show" : "collapse"}
            id="Level"
          >
            <div className="card card-body">
              <ul className="checkbox-group">
                {
                  levelItemArray?.length > 0 ? levelItemArray.map(
                    (ele, index) => {
                      return (
                        <li key={index}>

                          <div className={`level checkgroup ${expandFields[ele.id] ? "" : "collapsed"
                            }`}
                            // onClick={(e) => {
                            //   handleExpandCollapse(e, ele.id);
                            // }}
                          >
                            <span className="checkBox">
                              <input
                                type="checkbox"
                                id={`level${index}`}
                                checked={level.includes(ele.id)}
                                onChange={(e) => handleCheckBox(e, "level", ele.id, null)}
                              />
                              <label>{ele.collegedivisionname}</label>
                            </span>

                            <span className="icon" onClick={(e) => { handleExpandCollapse(e, ele.id); }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                id="Capa_1"
                                x="0px"
                                y="0px"
                                width="451.847px"
                                height="451.847px"
                                viewBox="0 0 451.847 451.847"
                                space="preserve"
                              >
                                <g>
                                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div
                            className={expandFields[ele.id] ? "show" : "collapse"}
                            id="Level"
                          >
                            <div className="card card-body">
                              <ul className="checkGroup">
                                {ele?.subLevelArray?.length > 0 ? ele?.subLevelArray?.map((subItem, key) => {
                                  return (
                                    <li key={key}>
                                      <span>
                                        <input
                                          type="checkbox"
                                          id={`subDivisionLevel${key}`}
                                          onClick={(e) =>
                                            handleCheckDivisionBox(e, "subDivisionLevel", subItem?.id, ele)
                                          }
                                          disabled={level.includes(ele.id) ? false : true}
                                          checked={ele?.id == subItem?.parent && ele?.selected?.includes(subItem?.id)}
                                        />
                                        <label>{subItem?.name}</label>
                                      </span>
                                    </li>
                                  );
                                }) : "Loading.."}
                              </ul>
                            </div>
                          </div>
                        </li>
                      )
                    }
                  )
                    : "Loading.."}
              </ul>
            </div>
          </div>
        </li>
        <li>
          <button
              className={`btn btn-primary ${expandFields["Academic"] ? "" : "collapsed"
              }`}
              type="button"
          >
            <span>
              <h5>Academic Selectivity
                <span
                      style={{marginLeft:"5px"}}
                      onClick={()=>props?.toolTipPop('Selectivity based on acceptance rate. Extremely Selective: < 15%, Very Selective 15% - 30%, Selective 30% - 60%, Somewhat Selective 60% - 90%, Not Selective > 90%.')}
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                >
                  <path
                      className="a"
                      d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                      transform="translate(-3 -3)"
                  />
                </svg>
              </span>
              </h5>
            </span>
            <span className="icon"
                  onClick={(e) => handleExpandCollapse(e, "Academic")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="451.847px"
                height="451.847px"
                viewBox="0 0 451.847 451.847"
                space="preserve"
              >
                <g>
                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </g>
              </svg>
            </span>
          </button>
          <div
            className={expandFields["Academic"] ? "show" : "collapse"}
            id="Academic"
          >
            <div className="card card-body">
              <ul className="checkGroup">
                {academicSelectivities && academicSelectivities.map((item, index) => {
                  return (
                    <li key={index}>
                      <span>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            props.handleMultiCheckbox(
                              e,
                              item.id,
                              "AcademicSelectivity"
                            )
                          }
                          checked={
                            props.fields && props.fields.AcademicSelectivity != "" ? ((props?.fields?.AcademicSelectivity).map(Number).includes(item.id)
                              ? true
                              : false)
                              : false
                          }
                        />
                        <label>{item.name}</label>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </li>
        <li>
          <button
            className={`btn btn-primary ${expandFields["Location"] ? " " : "collapsed"
              }`}
            type="button"
          >
            <h5 className="">Location</h5>
            <span className="icon"
                  onClick={(e) => handleExpandCollapse(e, "Location")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="451.847px"
                height="451.847px"
                viewBox="0 0 451.847 451.847"
                space="preserve"
              >
                <g>
                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </g>
              </svg>
            </span>
          </button>
          <div
            className={expandFields["Location"] ? "show" : "collapse"}
            id="Location"
          >
            <h1>{selectAllStates == true ? "All States selected" : props?.fields?.StateRegion?.length ? props.fields.StateRegion.join(' , ') : null}</h1>
            <div className="searchBox">
              <input
                type="text"
                placeholder="Search by State/Region"
                name="study"
                value={props.fields.searchStateRegion || ""}
                onChange={(e) => searchStateRegion(e.target.value)}
              />
              <div className="searchBtn">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.811"
                    height="13.811"
                    viewBox="0 0 13.811 13.811"
                  >
                    <g className="a" transform="translate(-3.75 -3.75)">
                      <path
                        className="b"
                        d="M15.167,9.833A5.333,5.333,0,1,1,9.833,4.5,5.333,5.333,0,0,1,15.167,9.833Z"
                      />
                      <path
                        className="b"
                        d="M27.875,27.875l-2.9-2.9"
                        transform="translate(-11.375 -11.375)"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <ul className="checkGroup study">
              <li>
                <span>
                  <input
                    type="checkbox"
                    checked={selectAllStates == true ? true : false}
                    onChange={(e) => handleSelectAll(e)}
                  />
                  <label>All States </label>
                </span>
              </li>
              <h1>Regions</h1>
              <br />
              {regions ?
                regions.map((item, index) => (
                  <li key={index}>
                    <span>
                      <input
                        type="checkbox"
                        disabled={selectAllStates == true ? true : false}
                        onChange={(e) => getStatesByRegion(e, item)}
                        checked={selectedRegions.indexOf(item.id) > -1 ? true : false }
                      />
                      <label>{item.regionname}
                      </label>
                    </span>
                  </li>
                )) : "No results found."}
              <h1> State / Provinces </h1>
              <br />
              {states ?
                states.map((item, index) => (
                  <li key={index}>
                    <span>
                      <input
                        type="checkbox"
                        onChange={(e) => handleSelectState(e, item)}
                        checked={
                          props.fields.StateRegion.indexOf(item.statename) >
                            -1
                          || props.fields?.selectedStateId && props.fields.selectedStateId.indexOf(item.id) >
                            -1
                            || stateNamesArray && stateNamesArray.indexOf(item.statename) > -1
                            || selectAllStates == true ? true : false
                        }
                      />
                      <label>{item.statename}
                      </label>
                    </span>
                  </li>
                )) : "No results found."}
            </ul>
          </div>
        </li>
        <li>
          <button
              className={`btn btn-primary ${expandFields["College"] ? " " : "collapsed"
              }`}
              type="button"
          >
            <span>
              <h5>College Size
                <span className=""
                      style={{marginLeft:"5px"}}
                      onClick={()=>props?.toolTipPop('Based on total undergraduate enrollment.')}
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                >
                  <path
                      className="a"
                      d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                      transform="translate(-3 -3)"
                  />
                </svg>
              </span>
              </h5>
            </span>
            <span className="icon"
                  onClick={(e) => handleExpandCollapse(e, "College")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="451.847px"
                height="451.847px"
                viewBox="0 0 451.847 451.847"
                space="preserve"
              >
                <g>
                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </g>
              </svg>
            </span>
          </button>
          <div
            className={expandFields["College"] ? "show" : "collapse"}
            id="College"
          >
            <div className="card card-body">
              <ul className="checkGroup">
                {collegeSizes && collegeSizes.map((item, index) => {
                  return (
                    <li key={index}>
                      <span>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            props.handleMultiCheckbox(
                              e,
                              item.id,
                              "CollegeSize"
                            )
                          }
                          checked={
                            props.fields && props.fields.CollegeSize != "" ? ((props.fields.CollegeSize).map(Number).includes(item.id) ? true : false) : false
                          }
                        />
                        <label>{item.name}</label>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </li>
        <li>
          <button
              className={`btn btn-primary ${expandFields["Tuition"] ? " " : "collapsed"
              }`}
              type="button"
          >
            <span
            >
              <h5>Tuition Cost
                <span className=""
                      style={{marginLeft:"5px"}}
                      onClick={()=>props?.toolTipPop('Based on cost of tuition (in-state default)')}
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                >
                  <path
                      className="a"
                      d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                      transform="translate(-3 -3)"
                  />
                </svg>
              </span>
              </h5>
            </span>
            <span className="icon"
                  onClick={(e) => handleExpandCollapse(e, "Tuition")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="451.847px"
                height="451.847px"
                viewBox="0 0 451.847 451.847"
                space="preserve"
              >
                <g>
                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </g>
              </svg>
            </span>
          </button>
          <div
            className={expandFields["Tuition"] ? "show" : "collapse"}
            id="Tuition"
          >
            <div className="card card-body">
              <ul className="checkGroup">
                {tuitionCosts && tuitionCosts.map((item, index) => {
                  return (
                    <li key={index}>
                      <span>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            props.handleMultiCheckbox(
                              e,
                              item.id,
                              "TuitionCost"
                            )
                          }
                          checked={
                            props.fields && props.fields.TuitionCost != "" ? ((props.fields.TuitionCost).map(Number).includes(item.id) ? true : false) : false
                          }
                        />
                        <label>{item.name}</label>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </li>
        <li>
          <button
              className={`btn btn-primary ${expandFields["Setting"] ? " " : "collapsed"
              }`}
              type="button"
          >
            <span>
              <h5>College Setting
                <span
                      style={{marginLeft:"5px"}}
                      // onClick={()=>props?.toolTipPop('Based NCES Locale Classification index - https://nces.ed.gov/programs/edge/docs/LOCALE_CLASSIFICATIONS.pdf')}
                      onClick={()=>getPop()}
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                >
                  <path
                      className="a"
                      d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                      transform="translate(-3 -3)"
                  />
                </svg>
              </span>
              </h5>
            </span>
            <span className="icon"
                  onClick={(e) => handleExpandCollapse(e, "Setting")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="451.847px"
                height="451.847px"
                viewBox="0 0 451.847 451.847"
                space="preserve"
              >
                <g>
                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </g>
              </svg>
            </span>
          </button>
          <div
            className={expandFields["Setting"] ? "show" : "collapse"}
            id="Setting"
          >
            <div className="card card-body">
              <ul className="checkGroup">
                {collegesettings && collegesettings.map((item, index) => {
                  return (
                    <li key={index}>
                      <span>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            props.handleMultiCheckbox(e, item.id, "Settting")
                          }
                          checked={
                            props.fields && props.fields.Settting != "" ? ((props?.fields?.Settting).map(Number).includes(item.id) ? true : false) : false
                          }
                        />
                        <label>{item.name}</label>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </li>
        <li>
          <button
            className={`btn btn-primary ${expandFields["Type"] ? " " : "collapsed"
              }`}
            type="button"
          >
            <h5>Type</h5>
            <span className="icon"
                  onClick={(e) => handleExpandCollapse(e, "Type")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="451.847px"
                height="451.847px"
                viewBox="0 0 451.847 451.847"
                space="preserve"
              >
                <g>
                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </g>
              </svg>
            </span>
          </button>
          <div className={expandFields["Type"] ? "show" : "collapse"} id="Type">
            <div className="card card-body">
              <ul className="checkGroup">
                {checkboxData.type.map((item, index) => {
                  return (
                    <li key={index}>
                      <span>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            props.handleMultiCheckbox(e, item, "Type")
                          }
                          checked={
                            props.fields && props.fields.Type != "" ? (props?.fields?.Type.includes(item) ? true : false) : false
                          }
                        />
                        <label>{item}</label>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </li>
        <li>
          <button
            className={`btn btn-primary ${expandFields["conference"] ? "" : "collapsed"
              }`}
            type="button"
          >
            <h5>Search Conference</h5>
            <span className="icon"
                  onClick={(e) => handleExpandCollapse(e, "conference")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="451.847px"
                height="451.847px"
                viewBox="0 0 451.847 451.847"
                space="preserve"
              >
                <g>
                  <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                </g>
              </svg>
            </span>
          </button>
          <div
            className={expandFields["conference"] ? "show" : "collapse"}
            id="stateRegions"
          >
            <div className="card card-body confrence-search">
              <div className="selectBox">
              </div>
            </div>
            <div className="searchBox">
              <input
                type="text"
                placeholder="Search Conference"
                name="study"
                onChange={(e) => searchConference(e.target.value)}
              />
            </div>
            <ul className="checkGroup study">
              {allCollegeConference ? allCollegeConference.map((conference, index) => {
                return (
                  <li key={index}>
                    <span>
                      <input
                        type="checkbox"
                        onChange={(e) => handleSelectConferences(e, conference.id, "conference")
                        }
                        checked={
                          props.fields && props.fields.conference != "" ? ((props?.fields?.conference).map(Number).includes(conference.id)
                            ? true
                            : false)
                            : false
                        }
                      />
                      <label>{conference.conferencenmae}</label>
                    </span>
                  </li>
                );
              }) : ""}
            </ul>
          </div>
        </li>
        <li>
          <span
          // title={'Filter your colleges based on different Area of Study and Majors.'}
          //     change done as of oct task jira 190
          >
            <h5 className="heading" style={{cursor:"pointer"}}>
              {/*Area of Study & Majors*/}
              {/*change done as of oct task jira 190*/}
              College Majors
              <span className="icon"
                    style={{marginLeft:"5px"}}
                    onClick={()=>props?.toolTipPop('Select a major to filter schools that only offer degrees in that specific major or program.')}
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                >
                  <path
                      className="a"
                      d="M9,15a6,6,0,1,1,6-6A6,6,0,0,1,9,15Zm0-1.2A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8Zm-.6-2.4H9.6v1.2H8.4Zm0-1.2s1.2,0,1.2,0c0-.6,1.8-1.2,1.8-2.4a2.4,2.4,0,1,0-4.8,0H7.8a1.2,1.2,0,1,1,2.4,0C10.2,8.34,8.4,9.22,8.4,10.2Z"
                      transform="translate(-3 -3)"
                  />
                </svg>
              </span>
            </h5>
          </span>
          <div className="searchBox">
             {/*placeholder="Search by study"*/}
             {/*change done as of oct task jira 190*/}
            <input
              type="text"
              placeholder="Search by Majors"
              name="study"
              onChange={(e) => props.searchAreaOfStudy(e.target.value)}
            />
            <div className="searchBtn">
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.811"
                  height="13.811"
                  viewBox="0 0 13.811 13.811"
                >
                  <g className="a" transform="translate(-3.75 -3.75)">
                    <path
                      className="b"
                      d="M15.167,9.833A5.333,5.333,0,1,1,9.833,4.5,5.333,5.333,0,0,1,15.167,9.833Z"
                    />
                    <path
                      className="b"
                      d="M27.875,27.875l-2.9-2.9"
                      transform="translate(-11.375 -11.375)"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <ul className="checkGroup study">
            {props.areaOfStudyList &&
              props.areaOfStudyList.map((item, index) => {
                return (
                  <li key={index}>
                    <span>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          props.handleMultiCheckbox(
                            e,
                            item.id,
                            "AreaStudy"
                          )
                        }
                        checked={
                          props.fields && props.fields.AreaStudy != "" ? ((props?.fields?.AreaStudy).map(Number).includes(item.id)
                            ? true
                            : false)
                            : false
                        }
                      />
                      <label>{item.areaofstudyname}</label>
                    </span>
                  </li>
                );
              })}
          </ul>
        </li>

      </ul>
      <Dialog
        open={isModal}
        onClose={closeSaveFilter}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            closeSaveFilter()
          }
        }}
        className="matDialogCustom"
      >
        <BackdropLoader open={props.saveSearchFilter.loading} />
        <div
          className={`modal saveFilerModal show fade`}
          id="saveFilterModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Save as Filter
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeSaveFilter}
                >
                  <span aria-hidden="true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.804"
                      height="15.803"
                      viewBox="0 0 15.804 15.803"
                    >
                      <g className="a" transform="translate(-6.776 -6.776)">
                        <path
                          d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                          transform="translate(0 0)"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <p>Save the current search criteria.</p>
                <p>After saving, they can be accessed from saved filters</p>
                <div className="formSection">
                  <form>
                    <label>Filter name <sup style={{ color: "red" }}>*</sup></label>
                    <input
                      type="text"
                      placeholder="Filter name here"
                      name="filterName"
                      value={fields.filterName}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </form>
                </div>
                {fields.filterStatus && fields.filterStatus === "old" && (
                  <div className="exist">
                    <p>
                      Filter with the same name exist. Do you want to replace
                      the filter?
                    </p>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary saveBtn"
                  onClick={() => saveFilter()}
                >
                  {fields.filterStatus && fields.filterStatus === "old"
                    ? "Replace"
                    : "Save"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary cancelBtn "
                  data-dismiss="modal"
                  onClick={closeSaveFilter}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    saveSearchFilter: state.athlete.dashboard.colleges.saveSearchFilter,
    checkSearchFilter: state.athlete.dashboard.colleges.checkSearchFilter,
    allRegions: state.common.regionsList,
    stateByRegionList: state.athlete.dashboard.colleges.serachStateByRegion,
    collegeList: state.common.collegeDetails,
    collegeConferences: state.common.collegeConferences,
    collegeDivision: state.athlete.profile.collegeDivision,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetCheckFilterStart: () => dispatch(resetCheckFilterStart()),
    checkFilterStatusStart: (data) => dispatch(checkFilterStatusStart(data)),
    saveSearchFilterStart: (data) => dispatch(saveSearchFilterStart(data)),
    regionsListStart: (data) => dispatch(regionsListStart(data)),
    searchStateByRegionStart: (data) => dispatch(searchStateByRegionStart(data)),
    allCollegeDetailsListStart: (data) => dispatch(allCollegeDetailsListStart(data)),
    collegeDivisionListStart: (data) => dispatch(collegeDivisionListStart(data)),
    allCollegeConferencesListStart: (data) => dispatch(allCollegeConferencesListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
