import {combineReducers} from 'redux'
import rootAuthReducers from './auth/rootAuthReducers'
import rootCommonReducers from './common/rootCommonReducers'
import rootAthleteReducers from './athlete/rootAthleteReducers';
import rootCoachReducers from './coach/rootCoachReducers';
export default combineReducers({
  auth: rootAuthReducers,
  common: rootCommonReducers,
  athlete: rootAthleteReducers,
  coach : rootCoachReducers
})
