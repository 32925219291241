import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SAVE_MESSAGE_LIST_START: "SAVE_MESSAGE_LIST_START",
  COMPOSE_MESSAGE_LIST_START: "COMPOSE_MESSAGE_LIST_START",
  COMPOSE_MESSAGE_LIST_SUCCESS: "COMPOSE_MESSAGE_LIST_SUCCESS",
  COMPOSE_MESSAGE_LIST_FAILED: "COMPOSE_MESSAGE_LIST_FAILED"
};

// ============================================ Actions ========================================================
export const saveMessageReplyListStart = (data) => ({
    type: actionType.SAVE_MESSAGE_LIST_START,
    payload: data,
  });

export const composeMessageListStart = data => ({
  type: actionType.COMPOSE_MESSAGE_LIST_START,
  payload: data
});

export const composeMessageListSuccess = data => ({
  type: actionType.COMPOSE_MESSAGE_LIST_SUCCESS,
  payload: data
});

export const composeMessageListFailed = data => ({
  type: actionType.COMPOSE_MESSAGE_LIST_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function composeMessageListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_MESSAGE_LIST_START:
        return { ...state, loading: true };

      case actionType.COMPOSE_MESSAGE_LIST_START:
      return {
        ...state,
        loading: true
      };

    case actionType.COMPOSE_MESSAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.COMPOSE_MESSAGE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
function* saveMessageListSaga(action) {
    try {
      const response = yield fetchGraphMethod(
        action.payload.query,
        action.payload.variables,
        true
      );
      if (response && response.status === 200) {
        action.payload.nextScreen(response.data.data)
      } else {
        messagePopup("", "Save Message Failed", "error");
        yield put(composeMessageListFailed("Failed, Error occured"));
      }
    } catch (error) {
      messagePopup("", "Save Message Failed", "error");
      yield put(composeMessageListFailed("Failed, Error occured"));
    }
  }

export function* composeMessageListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        composeMessageListSuccess({data: response.data.data})
      );
    } else {
      messagePopup("", "University Api failed", "error");
      yield put(composeMessageListFailed("University Api failed"));
    }
  } catch (error) {
    messagePopup("", "University Api failed", "error");
    yield put(composeMessageListFailed("University Api failed"));
  }
}

export function* watchComposeMessageListSaga() {
  yield takeEvery(actionType.SAVE_MESSAGE_LIST_START, saveMessageListSaga);
  yield takeEvery(actionType.COMPOSE_MESSAGE_LIST_START, composeMessageListSaga);
}