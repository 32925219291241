import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  RESET_CHECK_FILTER_START: "RESET_CHECK_FILTER_START",
  CHECK_FILTER_STATUS_START: "CHECK_FILTER_STATUS_START",
  CHECK_SEARCH_FILTER_SUCCESS: "CHECK_SEARCH_FILTER_SUCCESS",
  CHECK_SEARCH_FILTER_FAILED: "CHECK_SEARCH_FILTER_FAILED",
};

// ============================================ Actions ========================================================
export const resetCheckFilterStart = () => ({
  type: actionType.RESET_CHECK_FILTER_START,
});

export const checkFilterStatusStart = (data) => ({
  type: actionType.CHECK_FILTER_STATUS_START,
  payload: data,
});

export const searchFilterSuccess = (data) => ({
  type: actionType.CHECK_SEARCH_FILTER_SUCCESS,
  payload: data,
});

export const searchFilterFailed = (data) => ({
  type: actionType.CHECK_SEARCH_FILTER_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function checkSearchFilterReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.RESET_CHECK_FILTER_START:
      return {
        loading: false,
        data: null,
        error: null,
      };
    case actionType.CHECK_FILTER_STATUS_START:
      return {
        ...state,
        // loading: true,
      };

    case actionType.CHECK_SEARCH_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.CHECK_SEARCH_FILTER_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* checkSearchFilterStatusSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Searchfilters/checkFilter`,
      action.payload,
      "post",
      true
    );
    if (response && response.status === 200) {
      if (response.data && response.data.response) {
        if (response.data.response.status === 200) {
          yield put(
            searchFilterSuccess({
              status: "new",
            })
          );
        }
        if (response.data.response.status === 201) {
          yield put(
            searchFilterSuccess({
              status: "old",
            })
          );
        }
      } else {
        messagePopup("", "Check filter status failed", "error");
        yield put(searchFilterFailed("Check filter status failed"));
      }
      //
    } else {
      messagePopup("", "Check filter status failed", "error");
      yield put(searchFilterFailed("Check filter status failed"));
    }
  } catch (error) {
    messagePopup("", "Check filter status failed", "error");
    yield put(searchFilterFailed("Check filter status failed"));
  }
}

export function* watchCheckSearchFilterSaga() {
  yield takeEvery(
    actionType.CHECK_FILTER_STATUS_START,
    checkSearchFilterStatusSaga
  );
}
