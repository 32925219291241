import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import '../../../../../assets/css/stylesheet.scss';
import '../assets/css/claimProfile.scss';
import { useHistory } from 'react-router-dom';
import { matchotpStart } from '../../../../../redux/auth/signup/matchOtp';
import { connect } from 'react-redux';
import { getUserInfo } from '../../../../../services/authService';
import verifySelection from '../../../coach/dashboard/ClaimProfile/verifySelection';
import { Dialog } from '@mui/material';
import { prod } from '../../../../../config';
import { imageDownloadUrl } from '../../../../../config';

function EnterOtp(props) {
  let history = useHistory();
  const [enterOtpModal, setEnterOtpModal] = useState(true);
  const [enterOtp, setEnterOtp] = useState(''); // for otp inputs
  const [verifySelectionModal, setVerifySelectionModal] = useState(false);

  function hideemail() {
    let email = props.userEmail;
    let hiddenEmail = '';
    for (let i = 0; i < email.length; i++) {
      if (i > 2 && i < email.indexOf('@')) {
        hiddenEmail += '*';
      } else {
        hiddenEmail += email[i];
      }
    }
    return hiddenEmail;
  }

  function hidePhoneNumber() {
    let phoneNumber = props.athleteMobileNumber;
    let hiddenMobileNumber = '';
    for (let i = 0; i < phoneNumber.length; i++) {
      if (i > 2) {
        hiddenMobileNumber += '*';
      } else {
        hiddenMobileNumber += phoneNumber[i];
      }
    }
    return hiddenMobileNumber;
  }
  const handleOtp = (otp) => {
    setEnterOtp(otp);
  };

  const nextRoute = () => {
    if (props.handleContinue) {
      props.handleContinue();
    } else {
      setVerifySelectionModal(true);
      setEnterOtpModal(false);
    }
  };
  const closeVerificationModal = () => {
    setVerifySelectionModal(false);
    history.push('/coach-login');
  };
  const handleOnClick = () => {
    if (props.role == 'COLLEGECOUCHES') {
      props.matchotpStart({
        data: {
          otp: enterOtp,
          useFor: 'login',
          otpOrId: 'otp',
          role: 'COLLEGECOUCHES',
        },
        nextRoute: nextRoute,
      });
    } else {
      props.matchotpStart({
        data: {
          otp: enterOtp,
          useFor: 'signUp',
          otpOrId: 'otp',
        },
        publicProfileView: publicProfileView,
        // nextScreenRoute: `/publicView`,
      });
    }
  };
  const publicProfileView = () => {};
  return (
    <>
      <div
        className="modal fade common-modal show"
        id="ClaimProfileOtpModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ClaimProfileOtpLabel"
        aria-hidden="true"
        style={{ display: enterOtpModal ? 'block' : 'none' }}
      >
        <div className="modal-dialog" role="document">
          <form className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Enter Passcode</h5>
              <button
                onClick={() => props.closeButton()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                A text message with your Passcode has been sent to registered
                mobile number {hidePhoneNumber()} and email address{' '}
                {hideemail()}
              </p>
              <ul className="claimprofile-password-box">
                <OtpInput
                  placeholder="******"
                  value={enterOtp}
                  onChange={handleOtp}
                  numInputs={6}
                  isInputNum="true"
                  renderInput={(inputProps) => (
                    <input
                      {...inputProps}
                      maxLength={1}
                      style={{
                        width: '30px',
                        height: '30px',
                        fontSize: '20px',
                        textAlign: 'center',
                      }}
                    />
                  )}
                />
              </ul>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => handleOnClick()}
                type="button"
                className="btn blue"
                data-dismiss="modal"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
      {verifySelectionModal == true && (
        <div
          className="modal fade common-modal show"
          style={{ display: 'block' }}
          id="ClaimProfileVerifyModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="ClaimProfileOtpLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Verify Selection</h5>
                <button
                  onClick={() => closeVerificationModal()}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="claimProfileVerifyData">
                  <div className="VerifyProfile">
                    <div className="profileImg">
                      <img
                        src={
                          imageDownloadUrl + '/' + props.coachData &&
                          props.coachData[0] &&
                          props.coachData[0].profileImages
                            ? props.coachData[0].profileImages
                            : ''
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="name">
                      {props.coachData &&
                      props.coachData[0] &&
                      props.coachData[0].coachFirstName
                        ? props.coachData[0].coachFirstName
                        : ''}{' '}
                      {props.coachData &&
                      props.coachData[0] &&
                      props.coachData[0].coachLastName
                        ? props.coachData[0].coachLastName
                        : ''}
                    </h3>
                    <div className="infotext">
                      {props.coachData &&
                      props.coachData[0] &&
                      props.coachData[0].collegename
                        ? props.coachData[0].collegename
                        : ''}
                    </div>
                  </div>
                  <p>
                    Please ensure you are claming the correct profile. Every
                    claimed profile is reviewed by an administrator. We take the
                    security of the athlete information in our database very
                    seriously.{' '}
                    <strong>
                      Anyone submitting registration for a fake account or under
                      an assumed identity faces banishment from our website
                    </strong>
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => closeVerificationModal()}
                  type="button"
                  className="btn blue"
                  data-dismiss="modal"
                >
                  Claim Profile
                </button>
                <button
                  onClick={() => closeVerificationModal()}
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    matchotpStart: (data) => dispatch(matchotpStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterOtp);
