import { all, fork } from "redux-saga/effects";
import { watchCommitmentSaga } from "./commitment";
import {watchViewCommitmentsListSaga} from "./viewCommitments";
import {watchCollegeLevelListSaga} from "./collegeLevel";
import { watchProfileVideoPhotoListSaga } from "./profilePhotoVideoList";
import { watchProfileTimelineSaga } from "./timeline";
import { watchAthleteProfileDataSaga } from "./athleteProfileData";
import { watchProfileStatsDataSaga } from "./profileStatsData";
import { watchCommitmentStorySaga } from "./commitmentStory";
import { watchCollegeDivisionListSaga } from "./collegeDivisionLevel";

export default function* rootProfileSagas() {
  yield all([
    fork(watchCommitmentSaga),
    fork(watchViewCommitmentsListSaga),
    fork(watchCollegeLevelListSaga),
    fork(watchProfileVideoPhotoListSaga),
    fork(watchProfileTimelineSaga),
    fork(watchAthleteProfileDataSaga),
    fork(watchProfileStatsDataSaga),
    fork(watchCommitmentStorySaga),
    fork(watchCollegeDivisionListSaga),
  ]);
}