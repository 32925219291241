import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  COACH_PROGRAMS_LIST_START: "COACH_PROGRAMS_LIST_START",
  SAVE_COACH_PROGRAMS: "SAVE_COACH_PROGRAMS",
  COACH_PROGRAMS_SUCCESS: "COACH_PROGRAMS_SUCCESS",
  COACH_PROGRAMS_FAILED: "COACH_PROGRAMS_FAILED",
};

// ============================================ Actions ========================================================
export const getCoachProgramsListStart = (data) => ({
  type: actionType.COACH_PROGRAMS_LIST_START,
  payload: data,
});

export const saveCoachProgramsData = (data) => ({
    type: actionType.SAVE_COACH_PROGRAMS,
    payload: data,
  });

export const getCoachProgramsSuccess = (data) => ({
  type: actionType.COACH_PROGRAMS_SUCCESS,
  payload: data,
});

export const getCoachProgramsFailed = (data) => ({
  type: actionType.COACH_PROGRAMS_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function coachProgramsReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.COACH_PROGRAMS_LIST_START:
      return {
        ...state,
        loading: true,
      };
      case actionType.SAVE_COACH_PROGRAMS:
        return {
          ...state,
          loading: true,
        };
    case actionType.COACH_PROGRAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.COACH_PROGRAMS_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* coachProgramsListSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            const allCoachPrograms =  response?.data?.data?.allPrograms?.Programs;
            yield put(getCoachProgramsSuccess(allCoachPrograms));
        } else {
            messagePopup("", "CoachPrograms List failed", "error");
            yield put(getCoachProgramsFailed("CoachPrograms List failed"));
        }
    } catch (error) {
        messagePopup("", "CoachPrograms List failed", "error");
        yield put(getCoachProgramsFailed("CoachPrograms List failed"));
    }
}

function* saveCoachProgramsSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true)
        if(response && response.status == 200) {
            const saveCoachPrograms = response;
            if(action.payload.nextRoute){
              action.payload.nextRoute();
            }
            yield put(getCoachProgramsSuccess(saveCoachPrograms));
            
        } else {
          messagePopup('', 'Save CoachPrograms failed', 'error');
          yield put(getCoachProgramsFailed('Failed, Error occured'))
        }
    } catch (error) {
        messagePopup('', 'Save CoachPrograms failed', 'error');
        yield put(getCoachProgramsFailed('Failed, Error occured'))
    }
}

export function* watchSaveCoachProgramsListSaga() {
  yield takeEvery(actionType.COACH_PROGRAMS_LIST_START, coachProgramsListSaga);
  yield takeEvery(actionType.SAVE_COACH_PROGRAMS, saveCoachProgramsSaga);

}
