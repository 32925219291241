import {Dialog, MenuItem} from '@mui/material';
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { imageDownloadUrl } from '../../../../../config';
import { getClaimProfileCoachListStart } from '../../../../../redux/coach/dashboard/searchClaimprofileCoach';
import { getCollegeCoachListStart } from '../../../../../redux/coach/dashboard/searchCoachBySportIdCollegeId';
import { allsportsListStart } from '../../../../../redux/common/allSportWithOutUsingGender';
import { fetchGraphMethod, fetchMethod } from '../../../../../services/httpService';
import messagePopup from '../../../../../utils/messagePopup';
import MuiAutoComplete from '../../../../common/AutoComplete';
import { getCoachDetailsQuery } from '../../../athlet/dashboard/colleges/searchColleges/CollegeDetailsQuery';
import { filterCollegeNameQuery } from '../../../athlet/dashboard/editUserProfile/OfferQuery';
import EnterOtp from '../../../auth/claimProfile/claimProfileStep2/EnterOtp';
import "./assets/css/CoachclaimProfile.scss";
import ClaimProfileModal from './ClaimProfileModal';
import verifySelection from './verifySelection';
import {getCamelCase} from "../../../../common/camelCaseFunc";

function ClaimProfileStep1Coach(props) {
    const [fields, setFields] = useState({
        coachname: "",
        athleteMobileNumber: ""
    })
    const [claimProfileModal, setClaimProfileModal] = useState(false);
    const [enterPasscodeModal, setEnterPasscodeModal] = useState(false);
    const [verifySelectionModal, setVerifySelectionModal] = useState(false);
    const [allSports, setAllSports] = useState();
    const [collegeArray, setCollegeArray] = useState([]);
    const [allCoaches, setAllCoaches] = useState([]);
    const [matchingProfiles, setMatchingProfiles] = useState([]);
    const [singleProfileData, setSingleProfileData] = useState();
    const [isSearchFound, setIsSearchFound] = useState(false)


    useEffect(()=> {
     props.allsportsListStart();
     getAllCollege()
    }, [])

    useEffect(()=> {
        if(props.collegeCoaches && props.collegeCoaches.data && props.collegeCoaches.data[0]){
            setAllCoaches(props.collegeCoaches.data);
        }
    }, [props.collegeCoaches.data])
    useEffect(()=> {
    if(props.claimProfileCoachList.data !== undefined){
        setMatchingProfiles(props.claimProfileCoachList.data)
        setIsSearchFound(false)
    }else{
        setMatchingProfiles([])
        setIsSearchFound(true)
    }
    }, [props.claimProfileCoachList.data])

    useEffect(()=> {
    if(props.allsports.data){
        setAllSports(props.allsports.data)
    }
    }, [props.allsports.data])
    const getAllCollege = async () =>{
            let res = await fetchMethod(`/allCollegeList/0`, '', 'GET', false);
            if (res?.status == 200 && res?.statusText == "OK") {
                if (typeof res?.data?.data !="string" && res?.data?.data?.length > 0){
                    res?.data?.data?.map(cl => cl['collegename'] = `${!!cl?.collegename ? getCamelCase(cl?.collegename) : ""} - [${!!cl?.city ? getCamelCase(cl?.city) + ", " : ""} ${!!cl?.state ? getCamelCase(cl?.state) : ""}`)
                    setCollegeArray(res?.data?.data);
                }else{
                    setCollegeArray([])
                }
            }
    }
    const openClaimProfileModal = (data) => {
        setSingleProfileData(data);
        setClaimProfileModal(true);
    };
    const closeClaimProfileModal = () => {
        setClaimProfileModal(false)
    }
    const handleSaveCoachData = () => {
        setEnterPasscodeModal(true);
        setClaimProfileModal(false);
    }
    const closeEnterPasscodeModal = () => {
        setEnterPasscodeModal(false);
    }
    const handleContinue = () => {
        setVerifySelectionModal(true);
         setEnterPasscodeModal(false);
    }
    const closeVerificationModal = () => {
        setVerifySelectionModal(false);
        props.history.push("/coach-login");
    }
    const onClickClaimProfile = () => {
        setVerifySelectionModal(false);
        props.history.push("/coach-login");
    }
    const handleCoachDropdownChange = async(coachName) => {
        if(coachName.target.value.toString().length > 2){
            props.getCollegeCoachListStart({
                data : {
                    sportsId : fields.sport ? parseInt(fields.sport) : 0,
                    collegeId : fields.collegeName && fields.collegeName.id ? +(fields.collegeName.id) : 0,
                    coachName : coachName.target.value ? coachName.target.value : null
                }
            })
        }else {
            const clgName = {target:{ value : "null"}}
            handleCoachDropdownChange(clgName)
        }
    }
    const handleDropDownChange = async (collegeName) => {
        if (collegeName.target.value.toString().length > 2) {
            let res = await fetchMethod(`/allCollegeList/${collegeName.target.value}`, '', 'GET', false);
            if (res?.status == 200 && res?.statusText == "OK") {
                if (typeof res?.data?.data !="string" && res?.data?.data?.length > 0){
                    res?.data?.data?.map(cl => cl['collegename'] = `${!!cl?.collegename ? getCamelCase(cl?.collegename) : ""} - [${!!cl?.city ? getCamelCase(cl?.city) + ", " : ""} ${!!cl?.state ? getCamelCase(cl?.state) : ""}`)
                    setCollegeArray(res?.data?.data);
                }else{
                    setCollegeArray([])
                }
            }
        }else{
            getAllCollege()
        }
    }
    const handleChange = (e, field) =>{
        let fieldsT = fields;
        fieldsT[field] = e.target.value;
        setFields({
            ...fieldsT
        })
        if (field == "collegeName" && !!e?.target?.value){
            const clgName = {target:{ value : "null"}}
            handleCoachDropdownChange(clgName)
        }
    }
    const handleSearchClick = () => {
        if(fields.coachname && fields.coachname.coachFirstName !== ""){
            props.getClaimProfileCoachListStart({
              data : {
                sportId : fields.sport ? parseInt(fields.sport) : 0,
                collegeid : fields.collegeName && fields.collegeName.id ? fields.collegeName.id : 0,
                firstName : fields.coachname !== "" ? fields?.coachname?.coachFirstName : null,
                lastName : fields.coachname !== "" ? fields?.coachname?.coachLastName : null
              }
            })
        }else{
            messagePopup("", "Please select college name and coach full name.", "warning");
            setMatchingProfiles([]);
        }
    }
    return (
        <div>
            <section className="pageContent mt-0">
        <div className="claimprofile-sec">
            <div className="container">
                <div className="claimprofile-box">
                    <div className="claimprofile-title">Claim your profile</div>
                    <div className="coach-claimprofile-detail">
                        <ul className="flexbox">
                            <li>
                                <div className="form-group">
                                    <label style={{marginBottom:"24px"}}>Sport</label>
                                    <select className="form-control custom-select" value={fields.sport} onChange={(e)=>handleChange(e,"sport")}>
                                    <option  value="0">Select</option>
                                        {allSports ? allSports.map((sport, index)=>{
                                            if(sport?.id == 1){
                                                return <option key={index} value={sport.id}>
                                                    {sport.sportName?.split(" ")?.[1] ?? "Baseball"}
                                                </option>
                                            }
                                        }) : null}
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label>College Name</label>
                                    <MuiAutoComplete
                                        id="free-solo-demo"
                                        freeSolo={false}
                                        options={collegeArray}
                                        getOptionLabel={(option) =>
                                            option.collegename ? option.collegename : ""
                                        }
                                        value={fields.collegeName || ""}
                                        onChange={(event, newValue) => {
                                            handleDropDownChange(
                                                { target: { value: newValue } },
                                                "collegeName"
                                            );
                                        }}
                                        onChangeObj={(event, newValue) => {
                                            handleChange({ target: { value: newValue } }, "collegeName");
                                            
                                        }}
                                        inputPlaceholder="Search College"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label>Coach Full Name</label>
                                    <MuiAutoComplete
                                        id="free-solo-demo"
                                        freeSolo={false}
                                        options={allCoaches}
                                        getOptionLabel={(option) =>
                                            option.coachFullName ?  option.coachFullName : ""
                                        }
                                        value={fields.coachname || ""}
                                        onChange={(event, newValue) => {
                                            handleCoachDropdownChange(
                                                { target: { value: newValue } },
                                                "coachname"
                                            );
                                        }}
                                        onChangeObj={(event, newValue) => {
                                            handleChange({ target: { value: newValue } }, "coachname");
                                            
                                        }}
                                        inputPlaceholder="Search Coach Name"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group" style={{marginTop:"17px"}}>
                                    <button onClick={()=> handleSearchClick()} className="btn blue">Search</button>
                                </div>
                            </li>
                        </ul>
                        <div className="claim-profile-filter-data">
                            {/*<h3>{matchingProfiles?.length || "No"} Coach Profiles found.</h3>*/}
                            <ul className="athlete-profile-item">
                                {matchingProfiles?.length > 0 ? matchingProfiles.map((profile)=> {

                                    return (
                                <li>
                                    <div className="athlete-profile-info">
                                        <div className="img-box"><img src={imageDownloadUrl + "/" + profile.profileImages } alt=""/></div>
                                        <div className="content-box">{getCamelCase(profile.sportName)}, {getCamelCase(profile.collegename)}, {getCamelCase(profile.coachFirstName)} {getCamelCase(profile.coachLastName)}                                        </div>
                                    </div>
                                    <div className="button-box">
                                        <button onClick={()=>openClaimProfileModal(profile)} className="btn blue" data-toggle="modal" data-target="#CoachClaimProfileModal">Claim Profile</button>
                                    </div>
                                </li>

                                    )
                                }) : null}
                                {isSearchFound && <h3 style={{color:"red"}}>No Coach Profiles Found</h3>}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {claimProfileModal && (
    <ClaimProfileModal
    closeClaimProfileModal={closeClaimProfileModal}
    handleSaveCoachData={handleSaveCoachData}
    singleProfileData={singleProfileData}
    />
    )}
    {console.log("singleProfileData", singleProfileData)}
{enterPasscodeModal && (
    <Dialog open={enterPasscodeModal} className="matDialogCustom">
          <EnterOtp
            closeButton={closeEnterPasscodeModal}
            userEmail={singleProfileData?.coachEmail}
             athleteMobileNumber={fields.athleteMobileNumber}
             role="COLLEGECOUCHES"
             handleContinue={handleContinue}

          />
        </Dialog>
)}
{verifySelectionModal && (
    <div className="modal fade common-modal show" style={{display: "block"}} id="ClaimProfileVerifyModal" tabindex="-1" role="dialog"
        aria-labelledby="ClaimProfileOtpLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Verify Selection</h5>
                    <button onClick={()=>closeVerificationModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="claimProfileVerifyData">
                        <div className="VerifyProfile">
                            <div className="profileImg">
                                <img src={imageDownloadUrl + "/" + singleProfileData.profileImages} alt=""/>
                            </div>
                            <h3 className="name">{singleProfileData.coachFirstName} {singleProfileData.coachLastName}</h3>
                            <div className="infotext">{singleProfileData.collegename}</div>
                        </div>
                        <p>Please ensure you are claming the correct profile. Every claimed profile is reviewed by an administrator. We take the security of the athlete information in our database very seriously. <strong>Anyone submitting registration for a fake account or under an assumed identity faces banishment from our website</strong></p>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={()=>onClickClaimProfile()} type="button" className="btn blue" data-dismiss="modal">Claim Profile</button>
                    <button onClick={()=>closeVerificationModal()} type="button" className="btn" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
)}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        allsports: state.common.allSportList,
        collegeList: state.common.collegeDetails,
        claimProfileCoachList : state.coach.coachDashboard.claimProfileCoach,
        collegeCoaches : state.coach.coachDashboard.collegeCoaches
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        allsportsListStart : (data) => dispatch(allsportsListStart(data)),
        getClaimProfileCoachListStart : (data) => dispatch(getClaimProfileCoachListStart(data)),
        getCollegeCoachListStart : (data) => dispatch(getCollegeCoachListStart(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClaimProfileStep1Coach);