import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_ADDITIONAL_SPORTS_LIST_START: "ALL_ADDITIONAL_SPORTS_LIST_START",
  ALL_ADDITIONAL_SPORTS_LIST_SUCCESS: "ALL_ADDITIONAL_SPORTS_LIST_SUCCESS",
  ALL_ADDITIONAL_SPORTS_LIST_FAILED: "ALL_ADDITIONAL_SPORTS_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const allAdditionalSportsListStart = (data) => ({
  type: actionType.ALL_ADDITIONAL_SPORTS_LIST_START,
  payload: data,
});

export const allAdditionalSportsListSuccess = (data) => ({
  type: actionType.ALL_ADDITIONAL_SPORTS_LIST_SUCCESS,
  payload: data,
});

export const allAdditionalSportsListFailed = (data) => ({
  type: actionType.ALL_ADDITIONAL_SPORTS_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allAdditionalSportsListReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.ALL_ADDITIONAL_SPORTS_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_ADDITIONAL_SPORTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_ADDITIONAL_SPORTS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allAdditionalSportsListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allAdditionalSportsListSuccess({
          allAdditionalsports:
            response.data.data.allAdditionalsports.Additionalsports,
        })
      );
    } else {
      messagePopup("", "Additional Sports List failed", "error");
      yield put(allAdditionalSportsListFailed("Additional Sports List failed"));
    }
  } catch (error) {
    messagePopup("", "Additional Sports List failed", "error");
    yield put(allAdditionalSportsListFailed("Additional Sports List failed"));
  }
}

//Watcher
export function* watchAllAdditionalSportsListSaga() {
  yield takeEvery(
    actionType.ALL_ADDITIONAL_SPORTS_LIST_START,
    allAdditionalSportsListSaga
  );
}
