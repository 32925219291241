import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";
import toastr from "toastr";

// ========================================== Action Type =====================================================
export const actionType = {
  DELETE_VIDEO_PHOTO_START: "DELETE_DELETE_VIDEO_PHOTO_START",
  DELETE_VIDEO_PHOTO_SUCCESS: "DELETE_VIDEO_PHOTO_SUCCESS",
  DELETE_VIDEO_PHOTO_FAILED: "DELETE_VIDEO_PHOTO_FAILED",
};

// ============================================ Actions ========================================================

export const deleteVideoPhotoStart = (data) => ({
  type: actionType.DELETE_VIDEO_PHOTO_START,
  payload: data,
});

export const deleteVideoPhotoSuccess = (data) => ({
  type: actionType.DELETE_VIDEO_PHOTO_SUCCESS,
  payload: data,
});

export const deleteVideoPhotoFailed = (data) => ({
  type: actionType.DELETE_VIDEO_PHOTO_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function deleteVideoPhotoReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.DELETE_VIDEO_PHOTO_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.DELETE_VIDEO_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.DELETE_VIDEO_PHOTO_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* deleteVideoPhotoSaga(action) {
  try {
    const { userActivityId = null, refreshPage, ...restData } = action.payload;
    const response = yield fetchMethod(
      `/api/Uploadedvideoandphotos/removePhotosAndVideos`,
      restData,
      "post",
      true
    );
    if (response && response.status === 200) {
      // messagePopup("", "Video or photo deleted successfully.", "success");
      toastr.success("Video or photo deleted successfully.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
      yield put(deleteVideoPhotoSuccess({ data: action.payload }));
      action.payload.refreshPage();
      action?.payload?.getAthleteData()
    } else {
      messagePopup("", "Delete Video Photo Failed", "error");
      yield put(deleteVideoPhotoFailed("Delete Video Photo Failed"));
    }
  } catch (error) {
    // messagePopup("", "Delete Video Photo Failed", "error"); //this is commented as saga is going in ctach by server req 19/10/2022
    yield put(deleteVideoPhotoFailed("Delete Video Photo Failed"));
  }
}

export function* watchDeleteVideoPhotoSaga() {
  yield takeEvery(actionType.DELETE_VIDEO_PHOTO_START, deleteVideoPhotoSaga);
}
