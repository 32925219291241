import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_RESOURCE_HEADER_START: "GET_RESOURCE_HEADER_START",
  RESOURCE_HEADER_SUCCESS: "RESOURCE_HEADER_SUCCESS",
  RESOURCE_HEADER_FAILURE: "RESOURCE_HEADER_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const getResourceHeaderStart = (data) => ({
  type: actionType.GET_RESOURCE_HEADER_START,
  payload: data,
});

export const ResourceHeaderSuccess = (data) => ({
  type: actionType.RESOURCE_HEADER_SUCCESS,
  payload: data,
});

export const ResourceHeaderFailure = (data) => ({
  type: actionType.RESOURCE_HEADER_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function resourceHeaderReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_RESOURCE_HEADER_START:
      return { ...state, loading: true };

    case actionType.RESOURCE_HEADER_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.RESOURCE_HEADER_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* getResourceHeaderSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200) {
      const allResourceLibrary = response.data.data.allResourceheaders
        ? response.data.data.allResourceheaders
          : null;
      yield put(ResourceHeaderSuccess(allResourceLibrary));
    } else {
      messagePopup("", "Fetch Resource Header Failed", "error");
      yield put(ResourceHeaderFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch Resource Header Failed", "error");
    yield put(ResourceHeaderFailure("Failed, Error occured"));
  }
}

export function* watchResourceHeader() {
  yield takeEvery(actionType.GET_RESOURCE_HEADER_START, getResourceHeaderSaga);
}
