export const getAllCoachTypesQuery = `
  query allCoachtype{
        allCoachtypes{
          Coachtypes{
            id
            coachtypename
          }
        }
      }
  `;

export const getAllCoachDetailsQuery = `
  query allCoachandteamdetails($obj: JSON){
    allCoachandteamdetails(where: $obj){
          Coachandteamdetails{
            id
            userid
            coachemail
            coachfirstname
            coachlastname
            coachphonenumber
            coachtypeid
            typeofprimaryteam
            teamname
            wanttoconnectschoolcoachtosportforcecoach
            coachcountrycode
            onboardingid
          }
        }
      }
  `;

  export const saveCoachDetailsQuery = `
  mutation saveCoachandteamdetails($obj: CoachandteamdetailsInput!){
    saveCoachandteamdetails(obj: $obj){
      id
      userid
      coachemail
      coachfirstname
      coachlastname
      coachphonenumber
      coachtypeid
      typeofprimaryteam
      teamname
      wanttoconnectschoolcoachtosportforcecoach
      coachcountrycode
      onboardingid
    }
  } 
  `;

  export const saveAllReferenceDataQuery = `
 mutation saveReference ($obj : ReferenceInput!){
         saveReference (obj :$obj)
         {
            id
            active
            reffirstname
            reflastname
            team
            refemailid
          }
        }
`;

  export const getAllTeamQuery =
  `query allTeams ($where: JSON){
       allTeams(where: $where) {
         Teams{
             id
             active
            teamname
            isverified
            city
            state
          }
        }
      }
  `


export const getAllAthleteSchoolQuery =
    `query allAthleteschools ($where: JSON){
      allAthleteschools(where: $where){
        Athleteschools{
          id
          highschoolname
          isverified
        }
      }
    }
  `