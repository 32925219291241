import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  SAVE_PROFILE_PHOTO_START: "SAVE_PROFILE_PHOTO_START",
  PROFILE_PHOTO_SUCCESS: "PROFILE_PHOTO_SUCCESS",
  PROFILE_PHOTO_FAILURE: "PROFILE_PHOTO_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const saveProfilePhotoStart = (data) => ({
  type: actionType.SAVE_PROFILE_PHOTO_START,
  payload: data,
});

export const ProfilePhotoSuccess = (data) => ({
  type: actionType.PROFILE_PHOTO_SUCCESS,
  payload: data,
});

export const ProfilePhotoFailure = (data) => ({
  type: actionType.PROFILE_PHOTO_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function saveProfilePhotoReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_PROFILE_PHOTO_START:
      return { ...state, loading: true };

    case actionType.PROFILE_PHOTO_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.PROFILE_PHOTO_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* saveProfilePhotoSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      messagePopup("", "Profile Photo uploaded successfully", "success");
      yield put(ProfilePhotoSuccess("Profile Photo uploaded successfully"));
      action.payload.refreshPage();
    } else {
      messagePopup("", "Update profile photo failed ", "error");
      yield put(ProfilePhotoFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Update profile photo failed ", "error");
    yield put(ProfilePhotoFailure("Failed, Error occured"));
  }
}

export function* watchSaveProfilePhotoSaga() {
  yield takeEvery(actionType.SAVE_PROFILE_PHOTO_START, saveProfilePhotoSaga);
}
