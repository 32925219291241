import React, { useEffect, useRef, useState } from "react";
import '../assets/css/claimProfileHeader.scss';
import { useLocation, useRouteMatch, Link, useHistory, withRouter } from "react-router-dom";
import logoH from '../assets/img/newLogo.png';
import mobileLogo from "../../../../layouts/assets/Img/Mobile-logo.png";
import Home from "../../../../layouts/assets/Img/home.svg";
import search from "../../../../layouts/assets/Img/search.svg";
import email from "../../../../layouts/assets/Img/email.svg";
import notification from "../../../../layouts/assets/Img/notification.svg";
import "../../../../../assets/css/stylesheet.scss";
import CallIcon from '../assets/img/callIcon.png';
import { userProfileApi } from "../../../../common/userViewCount";
import { getUserInfo, getAuthToken } from "../../../../../services/authService";
import { imageDownloadUrl } from "../../../../../config";
import userImg from "../../../../layouts/assets/Img/user.svg";
import { fetchMethod } from "../../../../../services/httpService";
import { websiteDefaultUrl } from "../../../../../config";

const ClaimProfileHeader = () => {
  const [isSignIn, setIsSignIn] = useState(false)
  //search bar
  const [searchByAthleteName, setSearchByAthleteName] = useState(null);
  const [searchAthleteData, setSearchAthleteData] = useState([]);
  const [searchMobByAthleteName, setSearchMobByAthleteName] = useState(null);
  const [searchMobAthleteData, setSearchMobAthleteData] = useState([]);
  const [DropDown, setDropDown] = useState(false);
  const [openModal, setopenModal] = React.useState(false);
  const handleCloseModal = () => setopenModal(false);
  const handleOpenModal = () => setopenModal(true);
  const domRef = useRef();
  const modalRef = useRef()
  const history = useHistory()
  const setOpenCloseDropDown = (e) => {
    setSearchByAthleteName(e.target.value);
    setSearchMobByAthleteName(e.target.value)
    if (e.target.value.length > 2) {
      getAthleteList(e?.target?.value?.toUpperCase() || "");
      setDropDown(true);
    } else {
      setSearchAthleteData([])
      setSearchMobAthleteData([])
      setDropDown(false);
    }
  };
  const handalCloseDropDown = async (value) => {
    await setDropDown(false);
    await setSearchByAthleteName("");
  };
  const getAthleteList = async (name) => {
    if (name.length > 0) {
      const obj = {
        url: "/api/Athletes/searchAthlete",
        method: "post",
        data: { athleteName: name, role: getUserInfo()?.role ?? "" },
        headers: !!getAuthToken() ? true : false,
      };
      const res = await fetchMethod(
        obj?.url,
        obj?.data,
        obj?.method,
        obj?.headers
      );
      if (res?.data?.response?.status == 200) {
        setSearchAthleteData(res?.data?.response?.data);
        setSearchMobAthleteData(res?.data?.response?.data)
      } else {
        setSearchAthleteData([]);
        setSearchMobAthleteData([])
      }
    }
  };
  const handleOpenMenu = (e) => {
    // e.preventDefault();
    const ht = window?.innerWidth;
    if (ht < 993) {
      setTimeout(() => {
        document.body.classList.toggle("menu-open");
      }, 500)
    }
  };

  const handleLogout = () => {
    window.location = "/login";
  };
  const getNameInitial = (data) => {
    const Name = data?.athleteFullName?.split(" ")
    return `${Name?.[0]?.split("")?.[0]?.toUpperCase() ?? ""}${Name?.[Name?.length - 1]?.split("")?.[0]?.toUpperCase() ?? ""}`
  }
  //func for web view closing when click outside
  useEffect(() => {
    let w = window.innerWidth
    //func for web closing when click outside
    if (w > 992) {
      var handler = event => {
        if (!domRef?.current?.contains(event?.target)) {
          setSearchAthleteData([])
          setSearchByAthleteName("")
        }
      }
    }
    //func for mobile closing when click outside
    if (w <= 992) {
      var handler = event => {
        if (!modalRef?.current?.contains(event?.target)) {
          setSearchMobAthleteData([])
          setSearchMobByAthleteName("")
          setopenModal(false)
        }
      }
    }
    document?.addEventListener("mousedown", handler)
    return () => {
      document?.removeEventListener("mousedown", handler)
    }
  })
  const routeLink = "athletes"

  return (
    <>
      <header className="dashboard claimProfileHead">
        <div className="flex">
          <div className="logoPart">
            <Link to="/"
              onClick={() => setSearchAthleteData([])}
            >
              <img src={logoH} alt="logoH" />
              <img src={mobileLogo} alt="logo" className="mobile" />
            </Link>
          </div>
          <div className="searchBar" data-toggle="dropdown">
            <input
              type="text"
              value={searchByAthleteName}
              placeholder="Search Athletes"
              onChange={(e) => {
                setOpenCloseDropDown(e);
              }}
              style={{ width: "200px" }}
            />
            {searchAthleteData.length > 0 ? (
              <div
                ref={domRef}
                className={
                  DropDown == true
                    ? "dropdown-menu dropdownopen"
                    : "dropdown-menu"
                }
              >
                <div className="title">Search Results</div>
                <ul className="search-list">
                  {searchAthleteData.length > 0 &&
                    searchAthleteData.map((athlete, key) => (
                      <li id={athlete.id} key={key}>
                        <Link
                          target={"_blank"}
                          // to={`/publicView/0/${+athlete?.userId}`}
                          to={`/athlete/${athlete?.athleteFullName?.trim()?.toLowerCase()?.toString()?.split(" ").join("-")}-${+athlete?.sysGenId}`}
                          onClick={() => {
                            handalCloseDropDown();
                            localStorage.setItem("pubViewID", athlete.userId || 0);
                          }}
                        >
                          <div className="pic">
                            {!!athlete.profileImages ?
                              <img
                                src={
                                  athlete.profileImages != null
                                    ? imageDownloadUrl +
                                    "/" +
                                    athlete.profileImages
                                    : userImg
                                }
                              />
                              :
                              !!athlete?.athleteFullName ?
                                <span style={{ textAlign: "center", display: "block" }}>{getNameInitial(athlete)}
                                </span>
                                : ""}
                          </div>
                          <div className="desgination">
                            <p>
                              <strong>{athlete.athleteFullName}</strong>
                              {athlete.sportName}-{athlete.yearValue}
                              <br />
                              {athlete.city} {(!!athlete.city && !!athlete.countryShortName) ? "," : ""} {athlete.countryShortName}
                            </p>
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            ) : null}
            <a className="viewProfileLink" style={{ cursor: "pointer" }} href={void 0} onClick={() => { window.open(`${websiteDefaultUrl}athlete/wyatt-johnson-74144936`, "_blank") }}>View Athlete Profile</a>
          </div>
          <nav>
            {/* <li>
                <Link to={"/" + routeLink}
                  className={
                    window.location.href.includes("athletes")
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    setSearchAthleteData([])
                    setSearchByAthleteName("")
                    handleOpenMenu()
                  }}>Athletes</Link>
              </li> */}
            <ul>
              <li>
                <Link
                    className ={window.location.href.includes("webformlp") ? "active" : ""}
                    to={"/webformlp"}
                      onClick={()=>{
                        setSearchAthleteData([])
                        setSearchByAthleteName("")
                        handleOpenMenu()
                      }}
                >Get Scouted</Link>
              </li>
              <li>
                <Link
                  to="/athletes"
                  className={
                    window.location.href.includes("athletes")
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    setSearchAthleteData([])
                    setSearchByAthleteName("")
                    handleOpenMenu()
                  }}
                >
                  Athletes
                </Link>
              </li>
              <li>
                <Link
                  to="/partners"
                  className={
                    window.location.href.includes("partners")
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    setSearchAthleteData([])
                    setSearchByAthleteName("")
                    handleOpenMenu()
                  }}
                >
                  Partners
                </Link>
              </li>
              <li>
                <Link to={"/plans"}
                  className={
                    window.location.href.includes("plans")
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    setSearchAthleteData([])
                    setSearchByAthleteName("")
                    handleOpenMenu()
                  }}
                >Plans
                </Link>
              </li>
              <li className="viewProfileLink">
                <a href={void 0} onClick={() => { window.open(`${websiteDefaultUrl}athlete/wyatt-johnson-74144936`, "_blank") }}>View Athlete Profile</a>
              </li>
              {/*<li>*/}
              {/*  <Link*/}
              {/*    to="#"*/}
              {/*    onClick={()=>{*/}
              {/*      setSearchAthleteData([])*/}
              {/*      setSearchByAthleteName("")*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    Team*/}
              {/*  </Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to="#"*/}
              {/*        onClick={()=>{*/}
              {/*          setSearchAthleteData([])*/}
              {/*          setSearchByAthleteName("")*/}
              {/*        }}*/}
              {/*  >Success Stories</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to="#"*/}
              {/*        onClick={()=>{*/}
              {/*          setSearchAthleteData([])*/}
              {/*          setSearchByAthleteName("")*/}
              {/*        }}*/}
              {/*  >More+*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li className="sign-in-link">
                <li className={isSignIn ? "sign-in-link dropdown" : "sign-in-link"} data-toggle="dropdown">
                  <Link to={!!getAuthToken() ? "/homepage" :  "/login"} onClick={() => {
                    setIsSignIn(!isSignIn)
                    setSearchAthleteData([])
                    setSearchByAthleteName("")
                    setSearchMobAthleteData([])
                    setSearchMobByAthleteName("")
                  }} className="upgradeLink icon">LOGIN</Link>
                  {/* <ul className="dropdown-menu" style={{ padding: 0, width: "200%", top: 0, height: "50px" }}>
                    <li><Link to="/login"
                      onClick={() => {
                        setSearchAthleteData([])
                        setSearchByAthleteName("")
                        setSearchMobAthleteData([])
                        setSearchMobByAthleteName("")
                      }}
                    >Athlete Profile Login</Link></li>
                    {/*<li>*/}
                    {/*  <Link to="/coach-login"*/}
                    {/*          onClick={()=>{*/}
                    {/*            setSearchAthleteData([])*/}
                    {/*            setSearchByAthleteName("")*/}
                    {/*          }}*/}
                    {/*>College Coach Login*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                  {/* </ul> */}
                </li>
                {/*<Link className="upgradeLink">*/}
                {/*  SIGN IN*/}
                {/*</Link>*/}
              </li>
              {/*<li className="phone-link">*/}
              {/*  <Link to="#" >*/}
              {/*    <img src={CallIcon} alt="callIcon"></img>*/}
              {/*  </Link>*/}
              {/*</li>*/}
            </ul>
            <ul className="claimprofile-mobile-menu">
              <li className="mobileSearchIcon icon" style={{ display: "block" }}>
                <button
                  onClick={() => {
                    handleOpenModal()
                    setSearchMobByAthleteName("")
                  }}
                  type="button"
                  className="btn searchBtn"
                >
                  <img src={search} alt="Search Icon" />
                </button>
              </li>
              <li className="sign-in-link dropdown" data-toggle="dropdown">
                <Link to={!!getAuthToken() ? "/homepage" :  "/login"} className="upgradeLink icon"
                  onClick={() => {
                    setSearchAthleteData([])
                    setSearchMobAthleteData([])
                  }}
                >LOGIN</Link>
                {/* <ul className="dropdown-menu">
                  <li><Link to={!!getAuthToken() ? "/homepage" :  "/login"}>Athlete Profile Login</Link></li> */}
                  {/*<li><Link to="/coach-login">College Coach Login</Link></li>*/}
                {/* </ul> */}
              </li>
              {/*<li>*/}
              {/*  <Link to="/login" className="upgradeLink">*/}
              {/*    SIGN IN*/}
              {/*  </Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to="#" >*/}
              {/*    <img src={CallIcon} alt="callIcon"*/}
              {/*         onClick={()=>setSearchAthleteData([])}*/}
              {/*    ></img>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li
                className="sideBarMenu"
                id="sideBarMenu"
                onClick={handleOpenMenu}
              >
                <button><span></span></button>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <div
        ref={modalRef}
        className={openModal ? "modal fade show" : "modal fade"}
        id="searchModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="searchModal"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <a type="button" className="close" onClick={() => {
                handleCloseModal()
                setSearchByAthleteName("")
                setSearchAthleteData([])
                setSearchMobAthleteData([])
                setSearchMobByAthleteName("")
              }}>
                <span aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.001"
                    height="7.001"
                    viewBox="0 0 8.001 7.001"
                  >
                    <path
                      id="arrow-left-short"
                      d="M12,8a.5.5,0,0,1-.5.5H5.707l2.147,2.146a.5.5,0,1,1-.708.708l-3-3a.5.5,0,0,1,0-.708l3-3a.5.5,0,0,1,.708.708L5.707,7.5H11.5A.5.5,0,0,1,12,8Z"
                      transform="translate(-3.999 -4.499)"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </svg>
                </span>
              </a>
              <input
                type="search"
                name=""
                value={searchMobByAthleteName}
                placeholder="Search Athletes"
                onChange={(e) => {
                  setOpenCloseDropDown(e);
                }}
              />

              {searchMobAthleteData.length > 0 ? (
                <div
                  // ref={modalRef}
                  className={
                    DropDown == true
                      ? "dropdown-menu dropdownopen"
                      : "dropdown-menu"
                  }
                >
                  <div className="title">Search Results</div>
                  <ul className="search-list">
                    {searchMobAthleteData.length > 0 &&
                      searchMobAthleteData.map((athlete) => (
                        <li id={athlete.id}>
                          <Link
                            // to={`/publicProfile/${athlete.id}`}
                            // to={`/publicView/0/${+athlete?.userId}`}
                              to={`/athlete/${athlete?.athleteFullName?.trim()?.toLowerCase()?.toString()?.split(" ").join("-")}-${+athlete?.sysGenId}`}
                            onClick={() => {
                              handalCloseDropDown();
                              localStorage.setItem("pubViewID", athlete.userId || 0);
                              setopenModal(false)
                              setSearchAthleteData([])
                              setSearchByAthleteName(null)
                              setSearchMobByAthleteName(null)
                              setSearchMobAthleteData([])
                            }}
                          >
                            <div className="pic">
                              {!!athlete?.profileImages ?
                                <img
                                  src={
                                    athlete.profileImages != null
                                      ? imageDownloadUrl +
                                      "/" +
                                      athlete.profileImages
                                      : userImg
                                  }
                                />
                                : !!athlete?.athleteFullName ?
                                  <span style={{ textAlign: "center", display: "block" }}>{getNameInitial(athlete)}
                                  </span>
                                  : ""}
                            </div>
                            <div className="desgination">
                              <p>
                                <strong>{athlete.athleteFullName}</strong>
                                {athlete.sportName}-{athlete.yearValue}
                                <br />
                                {athlete.city} {!!athlete.city && !!athlete.countryShortName ? "," : ""} {athlete.countryShortName}
                              </p>
                            </div>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimProfileHeader;
