import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod } from "../../../../services/httpService"
import messagePopup from "../../../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    ATHLETESPORT_LIST_START: 'ATHLETESPORT_LIST_START',
    ATHLETESPORT_LIST_SUCCESS: 'ATHLETESPORT_LIST_SUCCESS',
    ATHLETESPORT_LIST_FAILED: 'ATHLETESPORT_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const athleteSportListStart = (data) => ({
   type: actionType.ATHLETESPORT_LIST_START,
   payload: data
})

export const athleteSportListSuccess = (data) => ({
    type: actionType.ATHLETESPORT_LIST_SUCCESS,
    payload: data
})

export const athleteSportListFailed = (data) => ({
    type: actionType.ATHLETESPORT_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function athleteSportListReducer(state = INIT_STATE,action){
  switch(action.type){
      case actionType.ATHLETESPORT_LIST_START: 
        return {
            ...state,
            loading: true
        }
      case actionType.ATHLETESPORT_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            data: action.payload,
            error: null
        }  
      case actionType.ATHLETESPORT_LIST_FAILED:
        return {
            ...state,
            loading: false,
            data: null,
            error: action.payload
        }       
      default: return {...state}  
  }
} 

// ===================================================== side effect =============================================

export function* athleteSportListSaga(action){
   try {
    const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true);
    if(response && response.status === 200){
       const allAthletesports = response.data.data.allAthletesports.Athletesports ? response.data.data.allAthletesports.Athletesports : null
       yield put(athleteSportListSuccess(allAthletesports));
    } else {
      messagePopup('','Athlete Sport List failed','error')   
      yield put(athleteSportListFailed('Atlete Sport List failed')) 
    }
   } catch (error) {
     messagePopup('','Athlete Sport List failed','error')   
     yield put(athleteSportListFailed('Athlete Sport List failed')) 
   }
}

export function* watchAthleteSportListSaga(){
    yield takeEvery(actionType.ATHLETESPORT_LIST_START, athleteSportListSaga)
}