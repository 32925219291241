import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { allUserDataStart } from "../../../../redux/auth/forgotpassword/forgotPassword";
import { IsValidEmail } from "../../../../utils/validationUtils";
import "./assets/css/forgotPassword.scss";
import { getUserEmail } from "./forgotPasswordQuery";
import { Dialog } from "@mui/material";
import EnterOTP from "./EnterOTP";
import { sendEmailStart } from "../../../../redux/auth/forgotpassword/sendEmail";
import messagePopup from "../../../../utils/messagePopup";
import logoVPng from "../../../../assets/img/newLogoV.png";
import {fetchMethod} from "../../../../services/httpService";
import toastr from 'toastr'
import BackdropLoader from "../../../common/Loader";
const ForgotPassword = (props) => {
  const [userEmail, setUserEmail] = useState("");
  const [error, setError] = useState({});
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  // useEffect(() => {
  //   if (props.userData && props.userData.data && props.userData.data.userData) {
  //     if (
  //       props.userData.data.userData[0] &&
  //       props.userData.data.userData[0].email
  //     ) {
  //       if (!props.sendEmailData.error) {
  //         sendOTP(
  //           props.userData.data.userData[0].email,
  //           props.userData.data.userData[0].id
  //         );
  //       }
  //       setOpenOtpModal(true);
  //     } else {
  //       setError({ ...error, registeredEmailError: true });
  //     }
  //   }
  // }, [props.userData.loading]);

  // const sendOTP = (email, id) => {
  //   props.sendEmailStart({
  //     variables: {
  //       emailId: email,
  //     },
  //     method: "POST",
  //   });
  // };
  const inputHandler = (event) => {
    setUserEmail(event.target.value);
    setError({ ...error, emailError: false, registeredEmailError: false });
  };
  const onClose = () => {
    setOpenOtpModal(false);
  };

  const openModal = async (res) => {
    if (res.data.response.status === 201) {
      setError({ ...error, registeredEmailError: true });
      setIsLoading(false)
    } else {
      setOpenOtpModal(true);
      setError({})
      setIsLoading(false)
    }
  }
  const removeAthleteScreen = (res) => {
    setUserEmail("")
    setIsLoading(false)
  }
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (!IsValidEmail(userEmail)) {
      setError({ ...error, emailError: true });
      return;
    }
    setIsLoading(true)
    const type = JSON?.parse(localStorage?.getItem('isVerified'))
    const _res = await fetchMethod('/api/Userdata/getUserRole',{"email": userEmail},'POST',false)
    if(_res?.status == 200 && _res?.data?.response?.status == 200){
      if (_res?.data?.response?.data?.role != "ADMIN" && _res?.data?.response?.data?.role != "ADVISORS"){
        props.sendEmailStart({
          variables: {
            emailId: userEmail,
            forverification: !!type ? 1: 0 //this condition is for email header
          },
          method: "POST",
          isForgotPass : true,
          nextScreen: openModal,
          removeAthleteScreen: removeAthleteScreen,
        });
      }else{
      messagePopup('','Access Denied','error')
        setIsLoading(false)
      }
    }
    else if (_res?.status == 200 && _res?.data?.response?.status == 401){//last moment change 25/11/2022
      toastr.error(" Please enter your registered email id","",{
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width forget-error-toast",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
      setIsLoading(false)
    }
    else{
      console.log("api api")
      setIsLoading(false)
    }
  };
  return (
      <>
        <BackdropLoader open={isLoading}/>
    <section className="forgot-pass-sec">
      <div className="container">
        <div className="forgot-box">
          <div className="back-link-box">
            <a onClick={() => props.history.goBack()}
              //styling this as to fix the bug
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.337"
                height="16.828"
                viewBox="0 0 9.337 16.828"
              >
                <path
                  id="Path_117"
                  data-name="Path 117"
                  d="M6.922,0,0,7l6.922,7"
                  transform="translate(1 1.414)"
                  fill="none"
                  stroke="#c0321f"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
              Log In
            </a>
          </div>
          <div className="detail-text-box">
            <div className="logo-box">
              <img src={logoVPng} alt="" />
            </div>
            {sessionStorage.getItem("ispasswordStatus") == "0"? <h2>Verify your email ID</h2> : <h1>Forgot Your Password ?</h1>}
            {sessionStorage.getItem("ispasswordStatus") == "0"? "" : <p>
              You 'll receive a mail on your registered email address containing a temporary passcode to reset your password.
            </p>}
          </div>
          <form className="form-box">
            <div className="form-group">
              <input
                type="email"
                placeholder="Registered email address"
                onChange={inputHandler}
                value={userEmail}
              />
            </div>
            {error["emailError"] && (
              <p style={{ color: "red", textAlign: "left" }}>Please enter your registered email id.</p>
            )}
            {error["registeredEmailError"] && (
              <p style={{ color: "red", textAlign: "left" }}>
                Please enter a registered email id
              </p>
            )}
            <div className="form-group">
              <button className="btn" type="submit" onClick={onSubmitHandler}>
                Send me the passcode
              </button>
            </div>
          </form>
          <div className="bottom-text-box">
            {sessionStorage.getItem("ispasswordStatus") ?
              "" :
              <p>
                {/* Don't have an account? <a href="/#signupstep1">Sign Up</a> */}
              </p>
            }
          </div>
        </div>
      </div>
      <div>
        <Dialog open={openOtpModal} className="matDialogCustom">
          <EnterOTP closeButton={onClose} userEmailId={userEmail} props={props} />
        </Dialog>
      </div>
    </section>
      </>
        );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
    sendEmailData: state.auth.sendEmail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userDataStart: (data) => dispatch(allUserDataStart(data)),
    sendEmailStart: (data) => dispatch(sendEmailStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
