import React, { useEffect } from "react";
import { useState } from "react";
//import "./css/notification.css";
import "./css/message-trash.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem, IconButton, Button, Tooltip } from "@mui/material";
import { getMessageQuery, saveMessageApi, messageReplyUpdate, getMessageReplyQuery, getMessageUnreadCount} from "./MessageQuery";
import moment from "moment";
import { messageListStart, saveMessageListStart } from "../../../../../redux/athlete/dashboard/message/message";
import { messageCountListStart } from '../../../../../redux/athlete/dashboard/message/messageCount';
import { connect } from "react-redux";
import {imageDownloadUrl} from '../../../../../config';
import closeGrayIcon from "./img/close-gray-icon.svg";
import trashCircleIcon from "./img/trash-circle-icon.svg";
import Dialog from "@mui/material/Dialog";
import messagePopup from "../../../../../utils/messagePopup";
import { fetchGraphMethod, fetchMethod } from "../../../../../services/httpService";
import { getUserInfo } from "../../../../../services/authService";
import { dashboardCountStart } from "../../../../../redux/athlete/dashboard/dashboardCount/dashboardCount";
import {
  NavigateBefore,
  NavigateNext
} from "@mui/icons-material";
import toastr from 'toastr';

const ITEM_HEIGHT = 48;

const Pagination = ({
  dataPerPage,
  totalData,
  paginate,
  initialPage,
  dataRange
}) => {
  const rows = parseInt(localStorage.getItem("rows"));
  const [defaultRows, setRows] = useState(rows);
  const [pageNumber, setPageNumber] = useState(initialPage);
  const pageNumbers = [];
  const NumberOfPages = totalData / defaultRows;

  for (let i = 1; i <= Math.ceil(NumberOfPages); i++) {
    pageNumbers.push(i);
  }

  const handlePageNumber = number => {
    setPageNumber(number);
    paginate(number * rows, defaultRows);
  };

  return (
    <div className="PaginationFlexWrap">
      <div className="PaginationNumberText">
        <span>
          {dataRange.first} - {dataRange.last}
        </span>
         of <span> {totalData}</span>
      </div>
      <nav className="paginationNav">
        <ul className="pagination">
          <li className="page-item">
            <span
              className="page-link"
              aria-label="Previous"
              onClick={() =>
                pageNumber !== 1 ? handlePageNumber(pageNumber - 1) : ""
              }
            >
              <NavigateBefore />
            </span>
          </li>
          <li className="page-item">
            <span
              className="page-link"
              aria-label="Next"
              onClick={() =>
                pageNumber < NumberOfPages
                  ? handlePageNumber(pageNumber + 1)
                  : ""
              }
            >
              <NavigateNext />
            </span>
          </li>
        </ul>
      </nav>
      </div>
  );
}

const AdvisorMessage = props => {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const openMenu = Boolean(anchorE2),
    [perPage, setPerPage] = useState(20),
    [offset, setOffSet] = useState(20),
    [id, setId] = useState(null),
    [showDeleteModal, setShowDeleteModal] = useState(false),
    [advisorData, setAdvisorData] = useState([]),
    [count, setCount] = useState(0),
    [supportCount, setSupportCount] = useState(""),
    [archiveCount, setArchiveCount] = useState(""),
    [advisorCount, setAdvisorCount] = useState(0),
    [ischecked, setIsChecked] = useState('20');
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClose = value => {
    setAnchorEl(null);
    setIsChecked(value);
    setPerPage(parseFloat(value));
    setOffSet(parseFloat(value))
  }
  const openComposeMenu = event => {
    setAnchorE2(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorE2(null);
  };
  const handleAdvisorMenu = () => {
    setAnchorE2(null);
    history.push({ pathname: "/message/advisor/replyMessage", state: {data: {isComposeAdvisor:true}} });
    //history.push({ pathname: "/message/advisor/composeMessage" });
  }
  const handleSupportMenu = () => {
    setAnchorE2(null);
    history.push({ pathname: "/message/support/replyMessage" });
  }
  const formatDateTime = newDate => {
      let dateTime = null;
      if(moment(newDate).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')){
        dateTime = moment(newDate).format('LT');
      }
      else{
        dateTime = moment(newDate).format('DD/MM/YYYY');
      }
      return dateTime;
  }
  const messageReadData = async (item) => {
    if(item.isread === 0){
      await fetchGraphMethod(saveMessageApi, { obj: { id: item.id, isread:1 } }, true).then(async (res) => { 
        await fetchGraphMethod(messageReplyUpdate, {
          where: { msgid: parseFloat(item.id) },
          data: { isread: 1 }
        }, true).then(async (res) => {
          props.dashboardCountStart({
            query: getMessageUnreadCount,
            variables: {
              where: {
                isread: 0,
                deletebyathlete: 0,
                msgreceiverid: getUserInfo()?.id,
              }
            }
          });
          history.push({ pathname: "/message/advisor/replyMessage", state: {data: item} });
        })
      })
    }
    else{
      history.push({ pathname: "/message/advisor/replyMessage", state: {data: item} });
    }
  }
  const onDeleteClick = () => {
      setShowDeleteModal(!showDeleteModal)
  }
  const DeleteItem = ({ onDeleteClick, onCancelClick }) => {
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img src={trashCircleIcon} alt="trash Circle Icon" />
              </div>
              <div className="content-box">
                <h4>
                  Are you sure you want to
                  <br /> delete this message?
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn blue"
                data-dismiss="modal"
                onClick={onDeleteClick}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const onDeleteHandler = async () => {
    await fetchMethod('/api/Messages/deleteOrMovetoInbox', {
      role: "ATHLETES",
      isdelete: 1,
      msgids: [id]
    }, "POST", true).then(res => {
      if (res?.data?.response?.status == 200) {
        getMessageData(perPage, offset)
        toastr.success("Message deleted successfully").css({"width":"auto"})
      } else {
        // messagePopup("", "Message not deleted. Try again!", "error")
        toastr.error("Message not deleted. Try again!").css({"width":"auto"})
        
      } 
    })
    
    setShowDeleteModal(!showDeleteModal)
  }

  const deleteMessageData = (e, item) => {
      e.stopPropagation();
      setShowDeleteModal(!showDeleteModal);
      setId(item.id)
  }

  useEffect(async () => {
    getUnreadRepliesCount()
    getMessageData(perPage, offset)
    window.scroll(0,0)
  }, []);

  const getUnreadRepliesCount = async () => {
    await fetchGraphMethod(getMessageReplyQuery, {
      where: {
        isread: 0,
        deletebyathlete: 0,
        msgreceiverid: getUserInfo()?.id
      }
    }, true).then(res => {
      let supportData = [],
          advisorData = [],
          archiveData = [];
      res?.data?.data?.allMessagereplies?.Messagereplies.map(item => { 
        if (item.archivebyathlete) {
          archiveData.push(item)
        }
        else if(item?.sendmsgrolevalue == "Admin" && !item.archivebyathlete){
          supportData.push(item)
        }
        else if(item?.sendmsgrolevalue == "Advisor" && !item.archivebyathlete){
          advisorData.push(item)
        }
      })
      setSupportCount(supportData.length);
      setArchiveCount(archiveData.length);
      setAdvisorCount(advisorData.length);
    })
  }

  const getMessageData = async (first, last) =>{
      await fetchGraphMethod(
        getMessageQuery,
        {
          where: {
            order: 'updatedon desc',
            archivebyathlete:0,
            deletebyathlete:0,
            and: [{or: [{messagesendbyadvisor: {neq: null}},{messagesendtoadvisorid: {neq: null}}]},{or:[{messagesendto:parseFloat(JSON.parse(localStorage.getItem("user")).id)}, {messagesendby:parseFloat(JSON.parse(localStorage.getItem("user")).id)}]}]
          }, first:first, last:last
        },
        true
      ).then(async (res) => { 
          let athleteData = [];
          if(res.status == 200){
            res?.data?.data?.allMessages?.Messages.map(item => {
              let messageReplyData = item?.messagereplyMsgidMap?.Messagereplies.filter(item=>item.deletebyathlete==0);
              if(messageReplyData.length){
                item['messagereplyMsgidMap'] = messageReplyData;
                item['message'] = messageReplyData.length ? messageReplyData[messageReplyData.length-1]['msg'] : item?.message;
                item['senderName'] = item?.messagemessagesendbyadvisormaprel?.Userdata?.length ? item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.advisorUseridMap?.Advisors[0]['firstname']+" "+item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.advisorUseridMap?.Advisors[0]['lastname'] : 
                                      item?.messagemessagesendtoadvisoridmaprel?.Userdata?.length ? item?.messagemessagesendtoadvisoridmaprel?.Userdata[0]['advisorUseridMap']['Advisors'][0]['firstname']+" "+item?.messagemessagesendtoadvisoridmaprel?.Userdata[0]['advisorUseridMap']['Advisors'][0]['lastname'] : "";
                item['advisorId'] = item?.messagemessagesendbyadvisormaprel?.Userdata?.length && item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.advisorUseridMap?.Advisors.length ? item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.advisorUseridMap?.Advisors[0]['id'] :
                                    item?.messagemessagesendtoadvisoridmaprel?.Userdata?.length && item?.messagemessagesendtoadvisoridmaprel?.Userdata[0]['advisorUseridMap']['Advisors'].length ? item?.messagemessagesendtoadvisoridmaprel?.Userdata[0]['advisorUseridMap']['Advisors'][0]['id'] : null;
                item['senderProfileImage'] = "";
                item['senderInitials'] = item['senderName'].split(" ")[0][0].toUpperCase() + item['senderName'].split(" ")[1][0].toUpperCase();
                item['dateTime'] = messageReplyData.length ? formatDateTime(messageReplyData[messageReplyData.length-1]['createdon']) : item.createdon ? formatDateTime(item.createdon) : "";
                athleteData.push(item)
              }
            })
          }
          setAdvisorData(athleteData);
          setCount(res?.data?.data?.allMessages?.totalCount);
      })
      
  }

  const archiveMessageData = async (e, item) => {
    e.stopPropagation();
    await fetchGraphMethod(saveMessageApi, { obj: { id: item.id, archivebyathlete:1 } }, true).then(async (res) => { 
      await fetchGraphMethod(messageReplyUpdate, {
        where: { msgid: parseFloat(item.id) },
        data: { archivebyathlete: 1 }
      }, true).then(async (res) => { 
        getUnreadRepliesCount();
        getMessageData(perPage, offset);
        // messagePopup("", "Chat archived successfully", "success");
        toastr.success("Chat archived successfully", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      })
    })
  }

/*
  useEffect(() => {
    let advisorData = [];
    let data = props?.messageList?.data?.data?.length > 0 ? props?.messageList?.data?.data?.map(item=>{
      item['senderId'] = item?.messagemessagesendbyadvisormaprel?.Userdata?.length > 0 ?  item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.id : "";
      item['senderEmail'] = item?.messagemessagesendbyadvisormaprel?.Userdata?.length > 0 ? item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.email : "";
      item['senderName'] = item?.messagemessagesendbyadvisormaprel?.Userdata?.length > 0 ? item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.userfullname : "";
      item['senderProfileImage'] = item?.messagemessagesendbymaprel?.Userdata?.length > 0 && item?.messagemessagesendbymaprel?.Userdata[0]?.coachAndTeamDetailsUserIdMap?.Coachandteamdetails[0]?.length > 0 && item?.messagemessagesendbymaprel?.Userdata[0]?.coachAndTeamDetailsUserIdMap?.Coachandteamdetails[0]?.coachandteamdetailsonboardingidmaprel?.Onboardings?.length > 0 ? item?.messagemessagesendbymaprel?.Userdata[0]?.coachAndTeamDetailsUserIdMap?.Coachandteamdetails[0]?.coachandteamdetailsonboardingidmaprel?.Onboardings[0]?.profileimages : "";
      item['dateTime'] = item.createdon ? formatDateTime(item.createdon) : "";
      let firstname = item?.messagemessagesendbyadvisormaprel?.Userdata?.length > 0 && item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.userfullname ? item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.userfullname[0].split(/\s/)[0].slice(0, 1) : '';
      let lastname = item?.messagemessagesendbyadvisormaprel?.Userdata?.length > 0 && item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.userfullname ? item?.messagemessagesendbyadvisormaprel?.Userdata[0]?.userfullname.split(/\s/)[1].slice(0, 1) : '';
      item['senderInitials'] = firstname && lastname ? firstname[0] + lastname[0] : '';
      advisorData.push(item);
    }) : [];
    setAdvisorData(advisorData);
    setCount(advisorData.length);
 }, [props.messageList.data]);

  useEffect(()=>{
    let coachData = [];
    let supportData = [];
    let advisorData = [];
    let data = props?.messageCountList?.data?.data?.length > 0 ? props?.messageCountList?.data?.data?.map(item=>{
      if(item.active === 1 && item.istrash === 1){
      if(item.userrole === 'ADVISORS'){
          advisorData.push(item);
      }
      else if(item.userrole === 'ADMIN'){
          supportData.push(item)
      }
      else if(item.userrole === 'COLLEGECOUCHES'){
          coachData.push(item);
      }
    }
    }) : [];
    setCoachCount(coachData && coachData.length ? coachData.filter(item => item.isread === 0).length : "");
    setAdvisorCount(advisorData && advisorData.length ? advisorData.filter(item => item.isread === 0).length : "");
    setSupportCount(supportData && supportData.length ? supportData.filter(item => item.isread === 0).length : "");
  },[props.messageCountList.data]);
*/
const handlePagination = async (pageNumber, size) => {
  setPerPage(pageNumber);
  setOffSet(size);
  getMessageData(pageNumber, size)
}

let dataRange = {
  first:
    perPage - offset + 1,
  last:
    perPage -
    offset +
    advisorData.length,
};
  return (
    <div className="pageContent">
      <div className="container">
        <div className="notification-with-sidebar">
          <nav className="notification-sidebar">
            <ul>
              <li className="button-link dropdown">
                <Button
                  className="btn lightblue dropdown-toggle"
                  onClick={e => openComposeMenu(e)}
                >
                  Compose
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button"
                  }}
                  anchorEl={anchorE2}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch"
                    }
                  }}
                >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  {/* <MenuItem onClick={handleCoachMenu}>Coach</MenuItem>*/}
                  <MenuItem onClick={handleSupportMenu}>Support</MenuItem>
                  <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem>
                </Menu>
              </li>
              <li
                className={window.location.href.includes('/support') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/support/replyMessage" })}
              >
                <div className="link-item">
                  <span>Support</span> <span>{supportCount ? supportCount : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/advisor') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor" })}
              >
                <div className="link-item">
                  <span>Advisor</span> <span>{advisorCount ? advisorCount : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/archive') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/archive" })}
              >
                <div className="link-item">
                  <span>Archive</span> <span>{archiveCount ? archiveCount : ""}</span>
                </div>
              </li>
            </ul>
          </nav>
         <div className="notification-detail-box">
            <div className="notification-search-box">
              <div className="search-box">
                <label>Advisor</label>
              </div>
              <div className="notification-pagination">
                <ul>
                  <li>
                  {advisorData?.length > 0 ?<Pagination 
                   dataPerPage={parseFloat(ischecked)}
                   totalData={count}
                   paginate={handlePagination}
                   initialPage={perPage / offset}
                   dataRange={dataRange}
                  />: ""}</li>
                  <li>
                    <div className="notification-dropdown-info">
                      {advisorData?.length > 0 ?<IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls="long-menu"
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        size="large">
                        <MoreVertIcon />
                      </IconButton> : ""}
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button"
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch"
                          }
                        }}
                      >
                        <MenuItem>
                          <strong>Show up to</strong>
                        </MenuItem>
                        <MenuItem onClick={()=>handleMenuClose('20')}>
                         {ischecked === '20' ? <span className="icon-box" style={{marginRight: '10px'}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          20 items
                        </MenuItem>
                        <MenuItem onClick={()=>handleMenuClose('50')}>
                        {ischecked === '50' ? <span className="icon-box" style={{marginRight: '10px'}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          50 items
                          </MenuItem>
                        <MenuItem onClick={()=>handleMenuClose('75')}>
                        {ischecked === '75' ? <span className="icon-box" style={{marginRight: '10px'}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          75 items
                          </MenuItem>
                        <MenuItem onClick={()=>handleMenuClose('100')}>
                        {ischecked === '100' ? <span className="icon-box" style={{marginRight: '10px'}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          100 items
                        </MenuItem>
                      </Menu>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
      {advisorData && advisorData.length > 0 ? (
        advisorData.map(item => {
          return (
            <div 
            className={`item notification-item ${
            !item.isread ? "active" : ""
            }`} onClick={()=>{messageReadData(item)}}>
              <div className="user-profile">
                {item.senderProfileImage ? <img src={imageDownloadUrl + `/${item.senderProfileImage}`} alt="" /> : <div className="initial">
                    {item.senderInitials}
                </div>}
              </div>
              <div className="user-detial-box">
                <div className="item">
                  {/* <h4 className="name">{item.senderName}</h4> */}
                  <div className="text-box">
                    <span className="subject-line">{item.senderName}</span>
                    <p>{item.message}</p>
                  </div>
                </div>
                <div className="item">
                  <div className="time-text">{item.dateTime}</div>
                  <div className="button-box">
                    <Tooltip placement="bottom" title={"Reply"} arrow>
                    <button
                      className="button"
                      onClick={() => {
                        history.push({ pathname: "/message/advisor/replyMessage", state: {data: item} });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.2"
                        height="16"
                        viewBox="0 0 19.2 16"
                      >
                        <path
                          id="Icon_material-reply"
                          data-name="Icon material-reply"
                          d="M11.967,11.767V7.5L4.5,14.967l7.467,7.467V18.06c5.333,0,9.067,1.707,11.733,5.44C22.633,18.167,19.433,12.833,11.967,11.767Z"
                          transform="translate(-4.5 -7.5)"
                          fill="#3e3e3e"
                        />
                      </svg>
                    </button>
                    </Tooltip>
                    <Tooltip placement="bottom" title={"Archive"} arrow>
                      <button className="button" onClick={(e) => archiveMessageData(e, item)}>
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="19.2" 
                          height="16" 
                          viewBox="0 0 29.981 29.982">
                            <path 
                              id="archive-30_30" 
                              data-name="archive-30*30" 
                              d="M2,10.547a3,3,0,0,1,.657-1.873L6.646,3.688A4.5,4.5,0,0,1,10.158,2H23.824a4.5,4.5,0,0,1,3.512,1.688l3.989,4.986a3,3,0,0,1,.657,1.873V27.484a4.5,4.5,0,0,1-4.5,4.5H6.5a4.5,4.5,0,0,1-4.5-4.5ZM10.158,5H23.824a1.5,1.5,0,0,1,1.171.563l2.661,3.326a.375.375,0,0,1-.293.609H6.619a.375.375,0,0,1-.293-.609L8.987,5.561A1.5,1.5,0,0,1,10.158,5Zm8.335,10.493a1.5,1.5,0,1,0-3,0v7.625L14.3,21.924a1.5,1.5,0,0,0-2.12,2.12l2.691,2.69a3,3,0,0,0,4.24,0L21.8,24.05a1.5,1.5,0,1,0-2.12-2.12l-1.186,1.186Z" transform="translate(-2 -2)" fill="#3e3e3e" 
                              fill-rule="evenodd"
                            />
                        </svg>
                      </button>
                    </Tooltip>
                    <Tooltip placement="bottom" title={"Delete"} arrow>
                    <button className="button" onClick={(e)=>deleteMessageData(e,item)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                      >
                        <path
                          id="Icon_awesome-trash-alt"
                          data-name="Icon awesome-trash-alt"
                          d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z"
                          fill="#3e3e3e"
                        />
                      </svg>
                    </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="noMsg">
          <span>No new message</span>
        </div>
      )}
    </div>
  </div> 
</div>
      </div>
      <Dialog open={showDeleteModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onDeleteClick()
                }
              }}
              className="matDialogCustom"
      >
        <DeleteItem
          onDeleteClick={onDeleteHandler}
          onCancelClick={onDeleteClick}
        />
      </Dialog>
    </div>
  );
};


const mapStateToProps = state => {
  return {
    dashboardCount: state.athlete.dashboard.dashboardCount.dashboardCount
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dashboardCountStart: (data) => dispatch(dashboardCountStart(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorMessage);
//export default AdvisorMessage;