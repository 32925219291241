import React, { useEffect, useState } from 'react';
import NcaaImg from './assets/img/ncaa-logo.png';
import NaiaImg from './assets/img/naia-logo.png';
import NjcaaImg from './assets/img/njcaa-logo.png';
import ActivityFeedbackMockup from './assets/img/activity-feed-mockup-img-01.png';
import DataDrivenProfileMockup from './assets/img/data-driven-profile-mockup-img-01.png';
import CollageSearchSystemMockup from './assets/img/college-search-system-mockup-img-01.png';
import GetVerifiedMockup from './assets/img/get-verified-mockup-img-01.png';
import ResourceCenterMockup from './assets/img/resource-center-mockup-img-01.png';
import PersolizedChecklistMockup from './assets/img/personalized-checklist-mockup-img-01.png';
import HomeMapImg from './assets/img/home-map-img.jpg';
import HighlightReelsBanner from './assets/img/highlight-reels-banner.png';
import SuccessStory1 from './assets/img/home-success-img-01.png';
import SuccessStory2 from './assets/img/home-success-img-02.png';
import SuccessStoryFooter2 from './assets/img/home-success-footer-img-02.png';
import CalStateFullertonTitans from './assets/img/Bb_Davidson_Wildcats.png';
import SuccessStory3 from './assets/img/home-success-img-03.png';
import SuccessStoryFooter3 from './assets/img/home-success-footer-img-03.png';
import SuccessStory4 from './assets/img/home-success-img-04.png';
import SuccessStoryFooter4 from './assets/img/home-success-footer-img-04.png';
import SuccessStory5 from './assets/img/home-success-img-05.png';
import SuccessStoryFooter5 from './assets/img/home-success-footer-img-05.png';
import SuccessStory6 from './assets/img/home-success-img-06.png';
import SuccessStoryFooter6 from './assets/img/home-success-footer-img-06.png';
import BrandSliderLogo1 from './assets/img/IVY-league-logo.png';
import BrandSliderLogo3 from './assets/img/pac12-logo.png';
import CCCAAL from './assets/img/california-community-college-athletic-association-logo.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/owl.carousel.min.js';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import { IsValidEmail } from '../../../utils/validationUtils';
import { fetchMethod } from '../../../services/httpService';
import { VideoPop } from './common/videoPopup';
import { websiteDefaultUrl } from '../../../config';
import AthleteTestimonials from '../../common/AthleteTestimonials';

const helpSlide = {
  loop: true,
  nav: true,
  dots: false,
  margin: 30,

  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 3,
    },
  },
};

const NewHomePage = () => {
  const [vidID, setVidID] = useState(null),
    [isVideoPop, setIsVideoPop] = useState(false);

  const initialValues = {
    email: '',
    athleteSport: '',
  };

  const [fields, setFields] = useState({ ...initialValues });
  const [errors, setErrors] = useState({});

  const handleClaimProfile = (e) => {
    e.preventDefault();
    //  history.push('/claimProfileStep1');
  };

  const handleCreateMyProfile = (e) => {
    e.preventDefault();
    const { formIsValid, updatedValue } = handleValidation();
    if (formIsValid) {
      console.log('e', fields);
    }
  };

  const handleChange = (field, e) => {
    //const { formIsValid, updatedValue } = handleValidation(field, e);
    setErrors({
      errorsT: '',
    });
    const fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setFields({
      ...fieldsT,
    });
  };

  useEffect(async () => {
    const obj = {
      url: '/api/Visitors/addtotalVisitor',
      method: 'post',
      body: null,
      headers: false,
    };
    const res = await fetchMethod(
      obj?.url,
      obj?.data,
      obj?.method,
      obj?.headers
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    //email validations
    if (fieldname === undefined || fieldname === 'email') {
      if (fieldname === undefined) {
        fieldValue = fields['email'] ? fields['email'] : '';
        updatedValue = fieldValue;
      }
      delete errorsT['email'];
      if (!updatedValue) {
        formIsValid = false;
        errorsT['email'] = 'Email Address is required';
      } else if (!IsValidEmail(updatedValue)) {
        formIsValid = false;
        errorsT['email'] = 'Please enter Email in correct format';
      }
    }

    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const openVideoModal = () => {
    // setVidID("https://www.youtube.com/embed/lg_19hBWcL8")
    setVidID('https://www.youtube.com/embed/6a82JWaXzPo');
    setIsVideoPop(true);
  };

  const handleClick = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSd4sU_JqVdscrl9_2W6fq4cZ_AWiYfOw0_cYvlVXiB_cTQuTA/viewform?usp=sf_link',
      '_blank'
    );
  };
  const handleRedirectToClub = () => {
    history.push('/partners');
  };
  const handleRedirectToAthlete = () => {
    history.push('/athletes');
  };

  return (
    <div>
      <section
        className="banner-lp-sec"
        style={{ background: `"url("${HighlightReelsBanner})"` }}
      >
        <div className="container">
          <div className="banner-flexbox">
            <div className="banner-content">
              <h1 className="title">
                Your Next-Generation College Recruiting Platform
              </h1>
              <div className="short-text">
                <p>
                  Empowering athletes, club teams, high schools, and college
                  coaches.
                </p>
              </div>
              <div className="button-wrap">
                <a
                  className="btn"
                  href="https://sportsforce.io/webformlp"
                  target="_blank"
                >
                  Athlete Waiting List
                </a>
                <a
                  class="btn lightblue"
                  href={void 0}
                  onClick={() => {
                    window.open(
                      `${websiteDefaultUrl}athlete/wyatt-johnson-74144936`,
                      '_blank'
                    );
                  }}
                >
                  View Athlete Profile
                </a>
              </div>
            </div>
            <div className="video-box-wrap">
              <div className="video-img">
                <img
                  src={
                    'https://i.ytimg.com/vi/6a82JWaXzPo/maxresdefault.jpg'
                    // AthleteVideoThumbnail
                  }
                  alt=""
                />
                <div
                  className="video-play-button"
                  data-toggle="modal"
                  data-target="#VideoModal"
                >
                  <div className="video-play-inner">
                    <button
                      className="play-btn"
                      onClick={() => openVideoModal()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.191"
                        height="22.92"
                        viewBox="0 0 20.191 22.92"
                      >
                        <path
                          id="play"
                          d="M9.086,27.982a2.255,2.255,0,0,1-1.1-.291,2.442,2.442,0,0,1-1.228-2.138V7.492A2.442,2.442,0,0,1,7.982,5.353a2.242,2.242,0,0,1,2.257.028l15.641,9.24a2.226,2.226,0,0,1,0,3.8L10.236,27.663a2.262,2.262,0,0,1-1.151.319Z"
                          transform="translate(-6.754 -5.063)"
                          fill="#c0321f"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sports-rol-sec">
        <div className="container">
          <h3 className="sec-title">Select Your Sports Role</h3>
          <div className="button-wrap">
            <button className="btn" onClick={handleRedirectToAthlete}>
              Athletes & Families
            </button>
            <button className="btn" onClick={handleRedirectToClub}>
              Club Teams
            </button>
            <button className="btn" onClick={handleRedirectToClub}>
              High Schools
            </button>
            <button className="btn" onClick={handleClick}>
              College Coaches
            </button>
          </div>
        </div>
      </section>
      <section className="athlete-common-sec athlete-home-ls">
        <div className="common-listing">
          <div className="common-listing-item">
            <div className="container">
              <div className="item-row">
                <div className="content-box">
                  <div className="top-block">
                    <h3 className="title">Data-Driven Profile</h3>
                  </div>
                  <div className="detail-text">
                    <ul>
                      <li>Complete Athletic & Academic Profile</li>
                      <li>Add Your Stats (Blast, Trackman, Rapsodo)</li>
                      <li>Get Your Stats Verified</li>
                    </ul>
                    <div className="success-info-box">
                      <div className="pick-box">
                        <img
                          className="pick-img"
                          src={SuccessStory1}
                          alt="Patrick Herrera - Northwestern"
                        />
                        <span className="icon-box">
                          <img
                            src={CalStateFullertonTitans}
                            alt="Patrick Herrera - Northwestern"
                          />
                        </span>
                      </div>
                      <div className="detail-info-box">
                        <h4 className="name">Patrick Herrera - Northwestern</h4>
                        <p>
                          SportsForce got my name out there and helped me
                          eventually find my perfect school
                        </p>
                        <p className="star-rating-icons">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img-box">
                  <img
                    src={DataDrivenProfileMockup}
                    alt="Data-Driven Profile"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="common-listing-item">
            <div className="container">
              <div className="item-row">
                <div className="content-box">
                  <div className="top-block">
                    <h3 className="title">College Search System</h3>
                  </div>
                  <div className="detail-text">
                    <ul>
                      <li>Proprietary Athletic & Academic Grouping</li>
                      <li>Easily Search & Compare Colleges</li>
                      <li>Find Your Top College Matches</li>
                    </ul>
                    <div className="success-info-box">
                      <div className="pick-box">
                        <img
                          className="pick-img"
                          src={SuccessStory2}
                          alt="Jake Dunagan - Davidson"
                        />
                        <span className="icon-box">
                          <img
                            src={SuccessStoryFooter2}
                            alt="Jake Dunagan - Davidson"
                          />
                        </span>
                      </div>
                      <div className="detail-info-box">
                        <h4 className="name">Jake Dunagan - Davidson</h4>
                        <p>
                          Jake received recruiting active interest from more
                          than 8 different division 1 programs
                        </p>
                        <p className="star-rating-icons">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img-box">
                  <img
                    src={CollageSearchSystemMockup}
                    alt="College Search System"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="common-listing-item">
            <div className="container">
              <div className="item-row">
                <div className="content-box">
                  <div className="top-block">
                    <h3 className="title">Get Verified</h3>
                  </div>
                  <div className="detail-text">
                    <ul>
                      <li>
                        Submit Your Stats (Blast, Trackman, Rapsodo, etc..)
                      </li>
                      <li>Get Verified by Our Staff</li>
                      <li>Promote Yourself to College Coaches</li>
                    </ul>
                    <div className="success-info-box">
                      <div className="pick-box">
                        <img
                          className="pick-img"
                          src={SuccessStory3}
                          alt="Jamir Gibson - Grambling"
                        />
                        <span className="icon-box">
                          <img
                            src={SuccessStoryFooter3}
                            alt="Jamir Gibson - Grambling"
                          />
                        </span>
                      </div>
                      <div className="detail-info-box">
                        <h4 className="name">Jamir Gibson - Grambling</h4>
                        <p>
                          If you’re serious about college baseball SportsForce
                          is who you want on your side!!!
                        </p>
                        <p className="star-rating-icons">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img-box">
                  <img src={GetVerifiedMockup} alt="Get Verified" />
                </div>
              </div>
            </div>
          </div>
          <div className="common-listing-item">
            <div className="container">
              <div className="item-row">
                <div className="content-box">
                  <div className="top-block">
                    <h3 className="title">Resource Center</h3>
                  </div>
                  <div className="detail-text">
                    <ul>
                      <li>World-Class Educational Library</li>
                      <li>How-to-Videos & FAQ’s</li>
                      <li>Masterclasses (Athletic & Academic)</li>
                    </ul>
                    <div className="success-info-box">
                      <div className="pick-box">
                        <img
                          className="pick-img"
                          src={SuccessStory4}
                          alt="Ethan Ezor - Colorado Mesa"
                        />
                        <span className="icon-box">
                          <img
                            src={SuccessStoryFooter4}
                            alt="Ethan Ezor - Colorado Mesa"
                          />
                        </span>
                      </div>
                      <div className="detail-info-box">
                        <h4 className="name">Ethan Ezor - Colorado Mesa</h4>
                        <p>
                          SportsForce helped me get on the map with various
                          schools and get my name out there
                        </p>
                        <p className="star-rating-icons">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img-box">
                  <img src={ResourceCenterMockup} alt="Resource Center" />
                </div>
              </div>
            </div>
          </div>
          <div className="common-listing-item">
            <div className="container">
              <div className="item-row">
                <div className="content-box">
                  <div className="top-block">
                    <h3 className="title">Activity Feed</h3>
                  </div>
                  <div className="detail-text">
                    <ul>
                      <li>
                        Find schools that fit your athletic and academic goals.
                      </li>
                      <li>
                        Filter schools by location, division, test scores, etc.
                      </li>
                      <li>
                        Build your target list of schools with the click of a
                        button.
                      </li>
                    </ul>
                    <div className="success-info-box">
                      <div className="pick-box">
                        <img
                          className="pick-img"
                          src={SuccessStory5}
                          alt="Matt McNaney - Trinity"
                        />
                        <span className="icon-box">
                          <img
                            src={SuccessStoryFooter5}
                            alt="Matt McNaney - Trinity"
                          />
                        </span>
                      </div>
                      <div className="detail-info-box">
                        <h4 className="name">Matt McNaney - Trinity</h4>
                        <p>
                          SportsForce was so helpful tracking which schools were
                          answering and watching my video
                        </p>
                        <p className="star-rating-icons">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img-box">
                  <img src={ActivityFeedbackMockup} alt="Activity Feed" />
                </div>
              </div>
            </div>
          </div>
          <div className="common-listing-item">
            <div className="container">
              <div className="item-row">
                <div className="content-box">
                  <div className="top-block">
                    <h3 className="title">Personalized Checklist</h3>
                  </div>
                  <div className="detail-text">
                    <ul>
                      <li>Easily Get-on-Track</li>
                      <li>Stay Accountable</li>
                      <li>Complete Key Action Items</li>
                    </ul>
                    <div className="success-info-box">
                      <div className="pick-box">
                        <img
                          className="pick-img"
                          src={SuccessStory6}
                          alt="Vincent Venverloh - USD"
                        />
                        <span className="icon-box">
                          <img
                            src={SuccessStoryFooter6}
                            alt="Vincent Venverloh - USD"
                          />
                        </span>
                      </div>
                      <div className="detail-info-box">
                        <h4 className="name">Vincent Venverloh - USD</h4>
                        <p>
                          The coaches helping are very knowledgeable, have many
                          useful college connections
                        </p>
                        <p className="star-rating-icons">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.658"
                            height="16.923"
                            viewBox="0 0 17.658 16.923"
                          >
                            <path
                              id="__TEMP__SVG__"
                              d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                              transform="translate(-1.746 -2.432)"
                              fill="#ffce30"
                            />
                          </svg>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img-box">
                  <img
                    src={PersolizedChecklistMockup}
                    alt="Personalized Checklist"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-coaches-sec bg-white">
        <div className="container">
          <div className="sec-title">What College Coaches Have to Say</div>

          <OwlCarousel
            className="coaches-list coaches-slider owl-carousel owl-theme"
            {...helpSlide}
          >
            <div className="item">
              <div className="icon-box">
                <img src={BrandSliderLogo1} alt="" />
              </div>
              <div className="short-text">
                <p>
                  Your program is a HUGE help because we can’t see every
                  potential recruit nationwide that might be a great fit for us.
                  And your program gives us the type of scouting reports,
                  videos, & metrics we need.
                </p>
              </div>
              <h4>NCAA DI - Head Coach</h4>
              <h5>&nbsp;</h5>
            </div>
            <div className="item">
              <div className="icon-box">
                <img src={NcaaImg} alt="" />
              </div>
              <div className="short-text">
                <p>
                  The SportsForce platform makes it easy for me to watch quality
                  video and get verified stats on players which saves me time so
                  I can focus on the right kids to recruit.
                </p>
              </div>
              <h4>NCAA DII - Head Coach</h4>
              <h5>&nbsp;</h5>
            </div>
            <div className="item">
              <div className="icon-box">
                <img src={BrandSliderLogo3} alt="" />
              </div>
              <div className="short-text">
                <p>
                  I trust and listen to your coaching staff’s recommendations
                  because they are honest, accurate, and the players you
                  recommend are the right type of kids for our program.
                </p>
              </div>
              <h4>NCAA DI - Recruiting Coordinator</h4>
              <h5>PAC 12</h5>
            </div>
            <div className="item">
              <div className="icon-box">
                <img src={NcaaImg} alt="" />
              </div>
              <div className="short-text">
                <p>
                  Having all the video, stats, grades, contact info, and
                  academic transcript in ONE PLACE makes our job much easier to
                  recruit the athletes on your platform.
                </p>
              </div>
              <h4>NCAA DIII - Head Coach</h4>
              <h5>&nbsp;</h5>
            </div>
          </OwlCarousel>
        </div>
      </section>
      <section className="lp-map-sec">
        <div className="container">
          <h3 className="sec-title">
            We carefully maintain a Trusted Network of College Coaches at the
            NCAA DI, DII, DIII, NAIA & JC
          </h3>
        </div>
        <div className="map-box">
          <img
            src={HomeMapImg}
            alt="We carefully maintain a Trusted Network of College Coaches at the NCAA DI, DII, DIII, NAIA & JC"
          />
        </div>
      </section>
      <section className="get-started-sec">
        <div className="container">
          <div className="get-started-flexbox">
            <h3 className="sec-title">
              Join Other Athletes On Our Waiting List.
            </h3>
            <div className="button-wrap">
              <a
                className="btn"
                href="https://sportsforce.io/webformlp"
                target="_blank"
              >
                Join Waiting List
              </a>
              <a
                class="btn lightblue"
                href={void 0}
                onClick={() => {
                  window.open(
                    `${websiteDefaultUrl}athlete/wyatt-johnson-74144936`,
                    '_blank'
                  );
                }}
              >
                View Athlete Profile
              </a>
            </div>
          </div>
        </div>
      </section>
      <AthleteTestimonials grayBackground />
      <section className="athlete-team-sec-outer">
        <div className="container">
          <h3 className="sec-title">
            Built for Athletes & Coaches, By Athletes & Coaches
          </h3>
        </div>
        <div className="athlete-team-sec">
          <div className="container">
            <div className="content-box">
              <h3 className="title">We're on Your Team</h3>
              <div className="short-text">
                <p>
                  As former college athletes, our team understands the
                  recruiting process can be stressful. We're on your team and
                  we're ready to help.
                </p>
                <p>
                  SportsForce provides trusted and accurate data around your
                  recruiting process. Student-athletes shouldn’t have to wonder
                  if activity from college coaches is real.
                </p>
              </div>
              <div className="button-wrap">
                <button
                  className="btn blue"
                  onClick={() =>
                    window.open(
                      'https://www.sportsforceonline.com/baseball/team/',
                      '_blank'
                    )
                  }
                >
                  Meet Our Team
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="brand-lp-sec mb-1">
        <div className="container">
          <div className="brand-listing">
            <div className="item">
              <img src={NcaaImg} alt="" />
            </div>
            <div className="item">
              <img src={NaiaImg} alt="" />
            </div>
            <div className="item">
              <img src={NjcaaImg} alt="" />
            </div>
            <div className="item">
              <img src={CCCAAL} alt="" />
            </div>
          </div>
        </div>
      </section>

      <footer id="footer"></footer>

      <div className="modal fade video-modal" id="VideoModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <iframe
                height="315"
                src="https://www.youtube.com/embed/aqz-KE-bpKQ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen=""
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <VideoPop
        isTestSingle={isVideoPop}
        setIsTestSingle={() => setIsVideoPop(false)}
        vidID={vidID}
      />
    </div>
  );
};

export default NewHomePage;
