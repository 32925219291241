import { all, fork } from "redux-saga/effects";
import { watchContactUserProfileListSaga } from "./contact";
import { watchBasicUserProfileListSaga } from "./basicList";
import { watchUpdateBasicUserProfileListSaga } from "./updateBasicUserProfile";
import { watchallParentListSaga } from "./allParentList";
import { watchupdateParentListSaga } from "./updateParentData";
import { watchCollegeInsightssaveSaga } from "./collegeInsights";
import { watchtestScoreListSaga } from "./testScores";
import { watchTranscriptAndAcademicsaveSaga } from "./TranscriptsAndAcademic";
import { watchApIpListSaga } from "./apIpclassDetails";
import { watchncaaNaidListSaga } from "./ncaaAndNaid";
import { watchhonorsListSaga } from "./academicHonors";
import { watchAllCoachDetails } from "./allCoachesDetails";
import { watchAllAdditionalSportsListSaga } from "./allAdditionalSports";
import { watchAllLevelMasterListSaga } from "./allLevelMaster";
import { watchUserAdditionalSportSaga } from "./userAdditionalSport";
import { watchAllTeamsListSaga } from "./allTeams";
import { watchallPressListSaga } from "./pressList";
import { watchUpdateBasicPressListSaga } from "./UpdatePress";
import { watchOffersListSaveSaga } from "./offers";
import { watchUserAcademicCollegeSaga } from "./academicCollege";
import { watchUserAcademicSchoolSaga } from "./academicSchool";
import { watchAllLevelsListSaga } from "./academicLevels";
import { watchAllAcademicDivisionsListSaga } from "./academicDivisions";
import { watchAllSchoolTypeListSaga } from "./allSchoolType";
import { watchAllSchoolListSaga } from "./allHighSchool";
import { watchAllCoachDetailsSaga } from "./allCoacheDetails";
import { watchReferencesSaga } from "./allReferences";
import { watchAddEditEventFormListSaveSaga } from "./addEditEventForm";
import { watchSchoolAndTeamListSaga } from "./schoolAndTeamType";
import { watchAddAddedEventFormListSaveSaga } from "./addAddedEvent";
import { watchSaveVideoPhotoSaga } from "./photo-video/saveVideoPhoto";
import { watchAllVideoPhotoListSaga } from "./photo-video/getVideoPhotoInfo";
import { watchDeleteVideoPhotoSaga } from "./photo-video/deleteVideoPhoto";
import { watchupdateVideoPhotoInfoSaga } from "./photo-video/saveVideoPhotoInfo";
import { watchAllAlbumTagsListSaga } from "./allAlbumTags";
import { watchVideoDraftSaga } from "./videosandPhotos";
import { watchMasterPlanSaga } from "./photo-video/allMasterPlan";
import { watchSchoolTeamUserMappingSaga } from "./schoolTeamUserMapping";
import { watchSaveVideoOrderSaga } from "./photo-video/saveVideoOrder";
import { watchgetVideoOrderSaga } from "./photo-video/getVideoOrder";
import { watchgetVideoDraftSaga } from "./photo-video/getVideoDraft";
import { watchAllMetricsListSaga } from "./stats/allMetrics";
import { watchAllStatMatricDetailsListSaga } from "./stats/allStatmatrixdetails";
import { watchAllJumpDataListSaga } from "./stats/allJumpData";
import { watchSaveStatsMatrixDetailsSaga } from "./stats/saveStatsMatrixDetails";
import { watchAllStatsOverviewSaga } from "./stats/allStatsOverview";
import { watchScoutingRequestSaga } from "./stats/scoutingRequest";
import { watchallStatsListSaga } from "./stats/allStats";
import { watchallSeasonStatsListSaga } from "./stats/allSeasonStats";
import { watchsaveStatsListSaga } from "./stats/saveAllStats";
import { watchAllStatsFieldWithPosition } from "./stats/allStatsfieldbasedonpositions";
import { watchDeleteAlbumTagSaga } from "./photo-video/deleteAlbumTag";
import { watchSchoolTeamSaga } from "./deleteSchoolTeam";
import { watchPubliPrivateStatusSaga } from "./photo-video/publicPrivateStatus";
import { watchProfileTimelineAboutSaga } from './profileAboutTimeline'
import { watchProfileTimelineStatusSaga } from './profileTimelineTabsStatus'
import { watchReqToAdvisorSaga } from './reqToAdvisor'
import { watchRenderCountSaga } from './renderCount'
import { watchTeamSideBarSaga } from './teamSideHeader'
import { watchEventSideBarSaga } from './eventSideHeader';
import { watchProfileTimelineReportSaga } from './profileReportTimeline';
import { watchProfileTimelineStatSaga } from './profileStatsTimeline';
import { watchProfileSubCardStatSaga } from './profileStatSubCardData';
import { watchProfileCardStatSaga } from './profileStatsDataTimeline';
import { watchProfileOverviewCardStatSaga } from "./profileStatsOverviewDataTimeline";
export default function* rootEditUserProfileSagas() {
  yield all([
    fork(watchContactUserProfileListSaga),
    fork(watchBasicUserProfileListSaga),
    fork(watchUpdateBasicUserProfileListSaga),
    fork(watchUpdateBasicPressListSaga),
    fork(watchallParentListSaga),
    fork(watchallPressListSaga),
    fork(watchupdateParentListSaga),
    fork(watchCollegeInsightssaveSaga),
    fork(watchtestScoreListSaga),
    fork(watchTranscriptAndAcademicsaveSaga),
    fork(watchApIpListSaga),
    fork(watchncaaNaidListSaga),
    fork(watchhonorsListSaga),
    fork(watchAllCoachDetails),
    fork(watchAllAdditionalSportsListSaga),
    fork(watchAllLevelMasterListSaga),
    fork(watchUserAdditionalSportSaga),
    fork(watchAllTeamsListSaga),
    fork(watchOffersListSaveSaga),
    fork(watchUserAcademicCollegeSaga),
    fork(watchUserAcademicSchoolSaga),
    fork(watchAllLevelsListSaga),
    fork(watchAllAcademicDivisionsListSaga),
    fork(watchAllSchoolTypeListSaga),
    fork(watchAllSchoolListSaga),
    fork(watchAllCoachDetailsSaga),
    fork(watchReferencesSaga),
    fork(watchAddEditEventFormListSaveSaga),
    fork(watchSchoolAndTeamListSaga),
    fork(watchAddAddedEventFormListSaveSaga),
    fork(watchSaveVideoPhotoSaga),
    fork(watchAllVideoPhotoListSaga),
    fork(watchDeleteVideoPhotoSaga),
    fork(watchupdateVideoPhotoInfoSaga),
    fork(watchAllAlbumTagsListSaga),
    fork(watchVideoDraftSaga),
    fork(watchMasterPlanSaga),
    fork(watchSchoolTeamUserMappingSaga),
    fork(watchSaveVideoOrderSaga),
    fork(watchgetVideoOrderSaga),
    fork(watchgetVideoDraftSaga),
    fork(watchAllMetricsListSaga),
    fork(watchAllStatMatricDetailsListSaga),
    fork(watchAllJumpDataListSaga),
    fork(watchSaveStatsMatrixDetailsSaga),
    fork(watchAllStatsOverviewSaga),
    fork(watchScoutingRequestSaga),
    fork(watchallStatsListSaga),
    fork(watchallSeasonStatsListSaga),
    fork(watchsaveStatsListSaga),
    fork(watchAllStatsFieldWithPosition),
    fork(watchDeleteAlbumTagSaga),
    fork(watchSchoolTeamSaga),
    fork(watchPubliPrivateStatusSaga),
    fork(watchProfileTimelineAboutSaga),
    fork(watchReqToAdvisorSaga),
    fork(watchProfileTimelineStatusSaga),
    fork(watchRenderCountSaga),
    fork(watchTeamSideBarSaga),
    fork(watchEventSideBarSaga),
    fork(watchProfileTimelineReportSaga),
    fork(watchProfileTimelineStatSaga),
    fork(watchProfileCardStatSaga),
    fork(watchProfileSubCardStatSaga),
    fork(watchProfileOverviewCardStatSaga)
  ]);
}
