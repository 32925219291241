import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  COACH_DROPDOWN_START: "COACH_DROPDOWN_START",
  COACH_DROPDOWN_SUCCESS: "COACH_DROPDOWN_SUCCESS",
  COACH_DROPDOWN_FAILED: "COACH_DROPDOWN_FAILED"
};

// ============================================ Actions ========================================================
export const coachDropdownStart = data => ({
  type: actionType.COACH_DROPDOWN_START,
  payload: data
});

export const coachDropdownSuccess = data => ({
  type: actionType.COACH_DROPDOWN_SUCCESS,
  payload: data
});

export const coachDropdownFailed = data => ({
  type: actionType.COACH_DROPDOWN_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function coachDropdownReducer(state = INIT_STATE, action) {
  switch (action.type) {
      case actionType.COACH_DROPDOWN_START:
      return {
        ...state,
        loading: true
      };

    case actionType.COACH_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.COACH_DROPDOWN_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* coachDropdownSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        coachDropdownSuccess({data: response.data.data})
      );
    } else {
      messagePopup("", "Coach Api failed", "error");
      yield put(coachDropdownFailed("Coach Api failed"));
    }
  } catch (error) {
    messagePopup("", "Coach Api failed", "error");
    yield put(coachDropdownFailed("Coach Api failed"));
  }
}

export function* watchCoachDropdownListSaga() {
  yield takeEvery(actionType.COACH_DROPDOWN_START, coachDropdownSaga);
}