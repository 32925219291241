import {fetchGraphMethod} from "../../services/httpService";
import {getAllAthletesDataQuery} from "./CommonQuery";
import {getUserInfo, setUserInfo} from "../../services/authService";
import {allParentQuery} from "../views/athlet/dashboard/editUserProfile/ContactQuery";
import React from "react";
import {saveBasicUserProfileUpdateQuery} from "../views/athlet/dashboard/editUserProfile/BasicQuery";

export const AutoVerify = async () =>{
    // let dispatch = useDispatch()
    // if (getUserInfo()?.verifiedUserStatus == 0) {
        const _resAthleteInfo = await fetchGraphMethod(getAllAthletesDataQuery, {obj: {id: getUserInfo() ? getUserInfo().id : 0,},}, true)
        if (_resAthleteInfo?.status == 200) {
            const _resAthleteParentInfo = await fetchGraphMethod(allParentQuery, {
                obj: {
                    athleteid: getUserInfo() ? getUserInfo().id : 0,
                    active: 1,
                },
            }, true)
            if (_resAthleteParentInfo?.status == 200) {
                let verifiedStepsT = {};
                let completedStepsT = 0;
                if (_resAthleteParentInfo?.data?.data.allAthleteparentmappings.Athleteparentmappings?.length > 0) {
                        verifiedStepsT["parent"] = true;
                        completedStepsT += 1;
                }
                if (_resAthleteInfo.data?.data?.allAthletes?.Athletes?.length > 0) {
                    const onboardingData = _resAthleteInfo.data?.data?.allAthletes?.Athletes?.[0]?.onBoardingUserIdMap?.Onboardings?.[0],
                        athletesData = _resAthleteInfo.data?.data?.allAthletes?.Athletes?.[0];

                    if (onboardingData) {
                        if (onboardingData.profileimages) {
                            verifiedStepsT["profilePhoto"] = true;
                            completedStepsT += 1;
                        }
                        const positions = onboardingData.primaryposition
                            ? true
                            : onboardingData.secondaryposition
                                ? true
                                : onboardingData.otherposition
                                    ? true
                                    : false;
                        if (positions) {
                            verifiedStepsT["positions"] = true;
                            completedStepsT += 1;
                        }
                    }
                    if (!!athletesData?.athleteathletegraduationyearmaprel?.Graduationyears?.[0]?.yearvalue) {
                        verifiedStepsT["graduationYear"] = true;
                        completedStepsT += 1;
                    }
                }
                if (completedStepsT >= 4){
                    const res = await fetchGraphMethod(saveBasicUserProfileUpdateQuery,{
                        obj: {
                            id: getUserInfo() ? getUserInfo().id : 0,
                            verifieduser : 1
                        }
                    }, true)
                    if (res?.status){
                        const cloneLS = JSON.parse(JSON.stringify(getUserInfo()))
                        cloneLS.verifiedUserStatus = 1
                        setUserInfo(cloneLS)
                    }
                }
            }
        }
    // }
}