import logoH from '../claimProfile/assets/img/newLogo.png';
import mobileLogo from "../../../layouts/assets/Img/mobileLogo.png";
import RecruitsStandards from '../../../layouts/assets/Img/recruits-standards-img.png'
import PromoteImg from '../../../layouts/assets/Img/promote-team-network-img.png'
import FilterImg from '../../../layouts/assets/Img/filter-to-find-fits-icon.png'
import TransparencyImg from '../../../layouts/assets/Img/total-transparency-icon.png'
import EvaluateImg from '../../../layouts/assets/Img/evaluate-prospects-icon.png'
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {IsValidEmail} from "../../../../utils/validationUtils";
import swal from 'sweetalert'
import {useDispatch, useSelector} from "react-redux";
// import "../../../layouts/assets/css/Coachheader.css";

export const LoginCoach = (props) =>{
    const [loginData, setLoginData] = useState({}),
          [coachEmail, setCoachEmail] = useState(),
           store = useSelector(store => store),
           dispatch = useDispatch()
    useEffect(()=>{
        window.scroll(0,0)
    })
    const onHandleChange = e =>{
    const clone = {...loginData}
    clone[e.target.name] = e.target.value
        setLoginData(clone)
    },
    onSubmit = () =>{
        if(!!loginData?.email && !!loginData?.password && (!!loginData?.email
            && IsValidEmail(loginData?.email)
        )){
            dispatch({type:'COACH_LOGIN_START', payload:{email:loginData?.email, password:loginData?.password}})
        }else{
            swal("","validation failed","")
        }
    },
    createAcc = value => {
        if (!!coachEmail && (!!coachEmail && IsValidEmail(coachEmail))) {
            props.history.push({
                pathname: `/coach-signup/`,
                state: coachEmail,
            })
        }else{
            swal("","Valid Email Required","")
        }
    }
  return(
      <>
          <header className="Coachdashboard" id="header">
              <div className="flex">
                  <div className="logoPart">
                      <a href="#">
                          <img src={logoH} alt="logo"/>
                              <img className="mobile" src={mobileLogo} alt="logo"/>
                      </a>
                  </div>
                  <nav>
                      <ul>
                          <li>
                              <div className="infoDetailsformBox">
                                  <div className="signBtn-onMobile">
                                      <button className="btn"  >Sign in</button>
                                  </div>
                                  <form className="infoDetailform">
                                      <div className="item">
                                          <label>Email</label>
                                          <input type="email" name={"email"} onChange={(e)=>onHandleChange(e)} placeholder="Email"/>
                                      </div>
                                      <div className="item">
                                          <label>Password <Link to="/forgotpassword">Forget password</Link></label>
                                          <input type="password" name={'password'} onChange={(e)=>onHandleChange(e)} placeholder="Password"/>
                                      </div>
                                      <div className="item">
                                          <button type="button" onClick={()=>onSubmit()} className="btn">Sign in</button>
                                      </div>
                                  </form>
                              </div>
                          </li>
                          <li><Link to={{
                              pathname: `/coach-signup/`,
                              state: "",
                          }}  className="upgradeLink second" >Sign up</Link></li>
                      </ul>
                  </nav>
              </div>
          </header>
          <section className="coach-banner-sec">
              <div className="container">
                  <div className="banner-flex-wrapper">
                      <div className="banner-content-box">
                          <h1 className="banner-title">lorem ipsum dolor sit amet</h1>
                          <p>Lorem Ipsum is simply dummy text of the <br/>printing and typesetting industry.</p>
                          <div className="buttonWrap">
                              <a className="btn outlined" 
                              href="/ClaimProfileCoach">Claim Profile</a>
                          </div>
                      </div>
                      <div className="banner-form-box">
                          <h2 className="title">First Time Here?</h2>
                          <p>Enter your .edu email address below to get started!</p>
                          <form>
                              <div className="form-item">
                                  <input type="text" placeholder="Enter your email here" onChange={(e)=>setCoachEmail(e.target.value)}/>
                              </div>
                              <div className="form-item">
                                  <button className="btn" type="button" onClick={()=>createAcc()}>Create an Account</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </section>
          <section className="coach-main-sec">
              <div className="container">
                  <div className="content-with-img-listing">
                      <div className="item">
                          <div className="img-box">
                              <img src={RecruitsStandards} alt=""/>
                          </div>
                          <div className="content-box">
                              <h3 className="title">Find Recruits That Fit <br/>Your Standards</h3>
                              <div className="short-text">
                                  <p>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem
                                      Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An
                                      Unknown Printer Took A Galley.</p>
                              </div>
                              <div className="button-box">
                                  <a className="btn" href="#">Get Started Free</a>
                              </div>
                          </div>
                      </div>
                      <div className="item">
                          <div className="img-box">
                              <img src={PromoteImg}
                                   alt="Promote Your Team To The Sportsforce Network"/>
                          </div>
                          <div className="content-box">
                              <h3 className="title">Promote Your Team To The <br/>Sportsforce Network</h3>
                              <div className="short-text">
                                  <p>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem
                                      Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An
                                      Unknown Printer Took A Galley.</p>
                              </div>
                              <div className="button-box">
                                  <a className="btn" href="#">Get Started Free</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="coach-get-started-sec">
              <div className="container">
                  <div className="content-box-inner">
                      <h2 className="title">Get started with Sportsforce today</h2>
                      <p>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been
                          The Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley.</p>
                      <div className="button-box">
                          <a className="btn outlined" href="#">Get Started Free </a>
                      </div>
                  </div>
              </div>
          </section>
          <section className="coach-feature-sec">
              <div className="container">
                  <div className="top-block">
                      <h3 className="sec-title">Lorem Ipsum Is Simply</h3>
                      <p>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been
                          The Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley.</p>
                  </div>
                  <ul className="feature-listing">
                      <li className="item">
                          <div className="icon-box">
                              <img src={FilterImg} alt="Filter To Find Fits"/>
                          </div>
                          <h4 className="title">Filter To Find <br/>Fits</h4>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                              been</p>
                      </li>
                      <li className="item">
                          <div className="icon-box">
                              <img src={TransparencyImg} alt="Total Transparency"/>
                          </div>
                          <h4 className="title">Total <br/>Transparency</h4>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                              been</p>
                      </li>
                      <li className="item">
                          <div className="icon-box">
                              <img src={EvaluateImg} alt="Evaluate Prospects From Anywhere"/>
                          </div>
                          <h4 className="title">Evaluate Prospects From <br/>Anywhere</h4>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                              been</p>
                      </li>
                  </ul>
              </div>
          </section>
      </>
  )}