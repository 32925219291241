import { all, fork } from "redux-saga/effects";
import { watchOnboarding } from "./onboarding";


export default function* rootOnBoardingSagas() {
  yield all([
    fork(watchOnboarding),
   
  ]);
}
