import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const menuItem = [
  {
    text: "MasterClasses",
    link: "MasterClasses",
  },
  {
    text: "How To Videos",
    link: "HowToVideo",
  },
  {
    text: "Webinars",
    link: "Webinars",
  },
  {
    text: "Resource Library",
    link: "ResourceLibrary",
  },
];

const ResourcesMenu = () => {
  let { location } = useHistory();
  let route = location.pathname.split("/")[2];


  const [activeclass, setActiveclass] = React.useState(route);
  const toggleButton = (route) => {
    setActiveclass(route);
  };
  return (
    <>
      {menuItem.map((item, index) => {
        return (
          <li key={index}>
            <Link
              to={"/resources/" + item.link}
              className={activeclass === item.link ? "active" : "inAcitve"}
              onClick={() => toggleButton(item.link)}
            >
              {item.text && item.text}
            </Link>
          </li>
        );
      })}
    </>
  );
};
function ResourcesNavbar() {
  const [isResView, setIsResView] = React.useState(false);
  return (
    // <ResourcesContainer>
    <div className="tabBox first">
      <nav className={isResView ? "show" : ""}>
        <ul className="nav nav-tabs">
          <li className="closeBtnBox">
            <button className="closeBtn" onClick={()=>{setIsResView(false)}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.804"
                height="15.803"
                viewBox="0 0 15.804 15.803"
              >
                <g className="a" transform="translate(-6.776 -6.776)">
                  <path
                    d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                    transform="translate(0 0)"
                  ></path>
                </g>
              </svg>
            </button>
          </li>
          <ResourcesMenu />
        </ul>
      </nav>
      <div className="flexBox">
        <div className="menuBtnBox">
          <button onClick={()=>{setIsResView(!isResView)}} className="tabMenuBtn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="12"
              viewBox="0 0 16 12"
            >
              <defs>
                <style></style>
              </defs>
              <g transform="translate(-284.664 -20.498)">
                <rect
                  className="a"
                  width="16"
                  height="2"
                  rx="1"
                  transform="translate(284.664 20.498)"
                ></rect>
                <rect
                  className="a"
                  width="16"
                  height="2"
                  rx="1"
                  transform="translate(284.664 25.498)"
                ></rect>
                <rect
                  className="a"
                  width="16"
                  height="2"
                  rx="1"
                  transform="translate(284.664 30.498)"
                ></rect>
              </g>
            </svg>
          </button>
        </div>
        <div>
          <p>Resource Menu</p>
        </div>
      </div>
    </div>
    // </ResourcesContainer>
  );
}

export default ResourcesNavbar;
