import React, {useEffect, useState} from 'react'
import "../assets/css/claimProfile.scss";
import {imageDownloadUrl} from "../../../../../config";
import {Link} from "react-router-dom";
import AB from '../../../landingPage/assets/img/andrew-beinbrink.png'
import {VideoPop} from "../../../landingPage/common/videoPopup";

export const ClaimProfileStep1 = (props) =>{
    const [isPop, setIsPop] = useState(false)
    const [fQ, setFQ] = useState(1)
    //func used for scrolling the page in top
    useEffect(()=>{
        setTimeout(()=>{
            window.scrollTo(0,0)
        },1000)
    })
    return(
       <>
           <section className="banner-claim-profile"
                    style={{backgroundImage:`url(${imageDownloadUrl}/banner-claim-profile.png)`}}
           >
               <div className="container">
                   <div className="banner-flexbox">
                       <div className="banner-content">
                           <h1 className="title">Getting Recruited <br/>Starts Here.</h1>
                           <div className="short-text">
                               <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                   has been the industry's standard dummy</p>
                           </div>
                           <div className="button-wrap">
                               {/*code commented as per task 262*/}
                               {/*<Link className="btn" to={'/claimProfileSearch'}>Claim Your Profile! It's FREE</Link>*/}
                           </div>
                       </div>
                       <div className="video-box-wrap">
                           <div className="video-img">
                               <img src={`${imageDownloadUrl}/athlete-video-thumbnail-01.png`} alt=""/>
                               <div className="video-play-button" data-toggle="modal" data-target="#VideoModal">
                                   <div className="video-play-inner">
                                       <button className="play-btn"
                                       type={'button'}
                                               onClick={()=>setIsPop(true)}
                                       >
                                           <svg xmlns="http://www.w3.org/2000/svg" width="20.191" height="22.92"
                                                viewBox="0 0 20.191 22.92">
                                               <path id="play"
                                                     d="M9.086,27.982a2.255,2.255,0,0,1-1.1-.291,2.442,2.442,0,0,1-1.228-2.138V7.492A2.442,2.442,0,0,1,7.982,5.353a2.242,2.242,0,0,1,2.257.028l15.641,9.24a2.226,2.226,0,0,1,0,3.8L10.236,27.663a2.262,2.262,0,0,1-1.151.319Z"
                                                     transform="translate(-6.754 -5.063)" fill="#c0321f"/>
                                           </svg>
                                       </button>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>

           <section className="claim-profile-sec">
               <div className="container">
                   <div className="top-block">
                       <h1 className="title">What is the Claim Profile?</h1>
                   </div>
                   <div className="flex-box">
                       <div className="infobox">
                           <div className="pick-box">
                               <img src={AB} alt=""/>
                           </div>
                           <h3 className="name">Paul Robles <span>6'4" 265 lbs | DL</span> <span>Centennial - Corona | 2021</span>
                           </h3>
                           <div className="about-info">
                               <div className="item">
                                   <span className="text">State Rank</span>
                                   <span className="text">#1</span>
                               </div>
                               <div className="item">
                                   <span className="text">State Rank</span>
                                   <span className="text">#1</span>
                               </div>
                           </div>
                       </div>
                       <div className="detail-info">
                           <h3>About</h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncididunt
                               ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                               ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                               reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                               sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                               est laborum. From its medieval origins to the digital era, learn everything there is to
                               know about the ubiquitous lorem ipsum passage.</p>
                       </div>
                   </div>
               </div>
           </section>

           <section className="get-started-sec">
               <div className="container">
                   <h3 className="sec-title">Can't wait to see you on board !</h3>
                   <div className="short-text">
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                           labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation </p>
                   </div>
                   <div className="button-wrap">
                       {/*code commented as per task 262*/}
                       {/*<Link className="btn" style={{color:"white", background:"#c0321f"}} to={'/claimProfileSearch'}>Claim Your Profile!</Link>*/}
                   </div>
               </div>
           </section>

           <section className="claim-profile-benefit">
               <div className="container">
                   <div className="top-block">
                       <h1 className="title">The Benefits</h1>
                       <div className="short-text">
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                               ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                               ullamco </p>
                       </div>
                   </div>
                   <ul className="benefit-list">
                       <li>
                           <div className="benefit-info">
                               <div className="info-flexbox">
                                   <div className="icon-box">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="55.996" height="56"
                                            viewBox="0 0 55.996 56">
                                           <g id="Target" transform="translate(0 0.005)">
                                               <path id="Path_83968" data-name="Path 83968"
                                                     d="M55.943,9.554a1.745,1.745,0,0,0-1.216-1.242L49.25,6.754,47.684,1.268A1.752,1.752,0,0,0,44.761.507L39.416,5.861a1.748,1.748,0,0,0-.446,1.715L40.518,13l-4.366,4.366a22.062,22.062,0,1,0,2.467,2.476l4.366-4.366,5.433,1.549a1.778,1.778,0,0,0,.481.07,1.75,1.75,0,0,0,1.234-.516l5.346-5.346a1.755,1.755,0,0,0,.464-1.68ZM40.378,34.06a18.468,18.468,0,1,1-6.711-14.209l-3.115,3.123a14.107,14.107,0,1,0,2.467,2.467l3.115-3.123A18.328,18.328,0,0,1,40.378,34.06Zm-7.874,0a10.61,10.61,0,1,1-4.453-8.592l-3.158,3.158A6.185,6.185,0,1,0,27.368,31.1l3.158-3.158A10.418,10.418,0,0,1,32.5,34.06Zm-7.874,0a2.69,2.69,0,1,1-2.695-2.686,2.647,2.647,0,0,1,1.75.665c.044.052.079.1.122.149s.1.079.149.122a2.637,2.637,0,0,1,.674,1.75ZM48.392,13.377,43.9,12.1,42.627,7.611,45.138,5.1l1.015,3.552A1.748,1.748,0,0,0,47.36,9.86l3.552,1.015Z"
                                                     transform="translate(0 0)" fill="#fff"/>
                                           </g>
                                       </svg>
                                   </div>
                                   <h3>Accurate Information</h3>
                               </div>
                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                   ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                   exercitation ullamco</p>
                           </div>
                       </li>
                       <li>
                           <div className="benefit-info">
                               <div className="info-flexbox">
                                   <div className="icon-box">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="56.007" height="56"
                                            viewBox="0 0 56.007 56">
                                           <g id="recruitment" transform="translate(-1.999 -2.002)">
                                               <g id="Group_57809" data-name="Group 57809"
                                                  transform="translate(1.999 2.003)">
                                                   <path id="Path_83982" data-name="Path 83982"
                                                         d="M38.876,34.444a20.388,20.388,0,1,0-2.042,2.385,20.4,20.4,0,0,0,2.042-2.385ZM35.01,32.057v-.575a6.721,6.721,0,0,0-6.73-6.72H26.1l-3.649,6.964c-.018.045-.09.045-.1,0l-3.649-6.964H16.519a6.719,6.719,0,0,0-6.72,6.72v.569A15.88,15.88,0,0,1,33.637,11.166a15.785,15.785,0,0,1,1.6,20.6C35.164,31.869,35.083,31.96,35.01,32.057Z"
                                                         transform="translate(-1.999 -2.003)" fill="#fff"/>
                                                   <path id="Path_83983" data-name="Path 83983"
                                                         d="M60.174,53.185,47.88,40.909l-1.274,1.265L43.77,39.337a19.529,19.529,0,0,1-2.05,2.376,21.533,21.533,0,0,1-2.376,2.069l2.827,2.827L40.9,47.874l12.294,12.3a4.941,4.941,0,1,0,6.982-6.991Z"
                                                         transform="translate(-5.61 -5.613)" fill="#fff"/>
                                                   <path id="Path_83984" data-name="Path 83984"
                                                         d="M23.912,9.812a6.331,6.331,0,0,0-6.331,6.331c0,3.5,2.835,8.8,6.331,8.8s6.331-5.3,6.331-8.8a6.332,6.332,0,0,0-6.331-6.331Z"
                                                         transform="translate(-3.506 -2.758)" fill="#fff"/>
                                               </g>
                                           </g>
                                       </svg>
                                   </div>
                                   <h3>Boost your Recruitment</h3>
                               </div>
                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                   ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                   exercitation ullamco</p>
                           </div>
                       </li>
                       <li>
                           <div className="benefit-info">
                               <div className="info-flexbox">
                                   <div className="icon-box">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56"
                                            viewBox="0 0 56 56">
                                           <g id="podium" transform="translate(0 -0.001)">
                                               <path id="Path_83985" data-name="Path 83985" d="M0,482H56v3.281H0Z"
                                                     transform="translate(0 -429.282)" fill="#fff"/>
                                               <path id="Path_83986" data-name="Path 83986"
                                                     d="M345.669,311.426h13.052V326.8H345.669Z"
                                                     transform="translate(-307.862 -277.363)" fill="#fff"/>
                                               <path id="Path_83987" data-name="Path 83987"
                                                     d="M184.048,15.623l5.076-1.647,5.076,1.647,0-5.336,3.135-4.319-5.075-1.651L189.124,0l-3.139,4.316-5.075,1.651,3.135,4.319Z"
                                                     transform="translate(-161.124)" fill="#fff"/>
                                               <path id="Path_83988" data-name="Path 83988"
                                                     d="M196.337,217.9h13.052V243.5H196.337Z"
                                                     transform="translate(-174.863 -194.062)" fill="#fff"/>
                                               <path id="Path_83989" data-name="Path 83989"
                                                     d="M47,275.486H60.056v19.306H47Z"
                                                     transform="translate(-41.863 -245.354)" fill="#fff"/>
                                           </g>
                                       </svg>
                                   </div>
                                   <h3>Context from Rankings</h3>
                               </div>
                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                   ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                   exercitation ullamco</p>
                           </div>
                       </li>
                       <li>
                           <div className="benefit-info">
                               <div className="info-flexbox">
                                   <div className="icon-box">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="55.981" height="56"
                                            viewBox="0 0 55.981 56">
                                           <g id="radar" transform="translate(-0.087)">
                                               <g id="Group_57810" data-name="Group 57810"
                                                  transform="translate(0.087 0)">
                                                   <path id="Path_83990" data-name="Path 83990"
                                                         d="M47.917,8.153a28.2,28.2,0,0,0-36.084-3,4.85,4.85,0,0,0-6.65,6.673A28.039,28.039,0,0,0,44.251,50.89a4.9,4.9,0,0,0,6.722-6.731A28.21,28.21,0,0,0,47.917,8.153ZM26.436,52.659A24.559,24.559,0,0,1,3.412,29.634h6.617a18.41,18.41,0,0,0,2.267,7.3,4.921,4.921,0,0,0,6.824,6.849,18.389,18.389,0,0,0,7.316,2.243v6.631Zm0-9.924A15.175,15.175,0,0,1,21.063,41.1a4.841,4.841,0,0,0-1.219-4.872,4.975,4.975,0,0,0-4.832-1.143,15.176,15.176,0,0,1-1.677-5.45h10.12a4.917,4.917,0,0,0,2.98,2.98V42.735Zm0-19.364a4.917,4.917,0,0,0-2.98,2.98H13.313A14.767,14.767,0,0,1,26.436,13.276V23.371Zm0-13.378a18.015,18.015,0,0,0-16.42,16.358H3.428A24.8,24.8,0,0,1,7.713,13.926a4.891,4.891,0,0,0,6.3-6.3A24.8,24.8,0,0,1,26.436,3.342V9.993Zm3.283,3.283a14.962,14.962,0,0,1,7.636,3.118l-7.168,7.168a4.853,4.853,0,0,0-.468-.191Zm0-3.283V3.325A24.883,24.883,0,0,1,44.381,9.367l-4.705,4.705A18.186,18.186,0,0,0,29.719,9.993Zm0,22.621a4.935,4.935,0,0,0,2.98-2.991H42.794A14.781,14.781,0,0,1,29.719,42.758ZM48.411,42.1a4.856,4.856,0,0,0-6.267,6.254,24.8,24.8,0,0,1-12.426,4.285v-6.66a17.873,17.873,0,0,0,16.357-16.36h6.651A24.836,24.836,0,0,1,48.411,42.1Z"
                                                         transform="translate(-0.087 0)" fill="#fff"/>
                                               </g>
                                           </g>
                                       </svg>
                                   </div>
                                   <h3>Get on our Radar</h3>
                               </div>
                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                   ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                   exercitation ullamco</p>
                           </div>
                       </li>
                   </ul>
               </div>
           </section>

           <section className="how-it-work-sec">
               <div className="container">
                   <div className="top-block">
                       <h3 className="title">How it Works</h3>
                       <div className="short-text">
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                               ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                       </div>
                   </div>
                   <ul className="benefit-list">
                       <li>
                           <div className="icon-box">
                               <svg xmlns="http://www.w3.org/2000/svg" width="76.5" height="122.223"
                                    viewBox="0 0 76.5 122.223">
                                   <g id="Group_57812" data-name="Group 57812" transform="translate(-441.5 -3285.777)">
                                       <g id="user-avatar" transform="translate(439.75 3284.027)">
                                           <path id="Path_83991" data-name="Path 83991"
                                                 d="M40,2.25A37.75,37.75,0,1,0,77.75,40,37.75,37.75,0,0,0,40,2.25ZM23.821,67.988V62.595a8.684,8.684,0,0,1,8.089-9.113H48.089a8.684,8.684,0,0,1,8.089,9.142v5.393a32.141,32.141,0,0,1-32.357,0Zm37.75-3.938V62.406A14.048,14.048,0,0,0,48.089,48.089H31.911A14.021,14.021,0,0,0,18.429,62.408V64a32.357,32.357,0,1,1,43.143,0Z"
                                                 transform="translate(0 0)" fill="#fff" stroke="#28bbdd"
                                                 strokeWidth="1"/>
                                           <path id="Path_83992" data-name="Path 83992"
                                                 d="M25.857,7.875A13.482,13.482,0,1,0,39.339,21.357,13.482,13.482,0,0,0,25.857,7.875Zm0,21.571a8.089,8.089,0,1,1,8.089-8.089A8.089,8.089,0,0,1,25.857,29.446Z"
                                                 transform="translate(14.143 7.857)" fill="#fff" stroke="#28bbdd"
                                                 strokeWidth="1"/>
                                       </g>
                                       <rect id="Rectangle_12154" data-name="Rectangle 12154" width="72" height="6"
                                             rx="3" transform="translate(444 3376)" fill="#fff"/>
                                       <rect id="Rectangle_12155" data-name="Rectangle 12155" width="64" height="6"
                                             rx="3" transform="translate(444 3389)" fill="#fff"/>
                                       <rect id="Rectangle_12156" data-name="Rectangle 12156" width="50" height="6"
                                             rx="3" transform="translate(444 3402)" fill="#fff"/>
                                   </g>
                               </svg>
                           </div>
                           <div className="info-detail">
                               <h3>Step 1: Update your information</h3>
                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                   incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                   exercitation</p>
                           </div>
                       </li>
                       <li>
                           <div className="icon-box">
                               <svg xmlns="http://www.w3.org/2000/svg" width="100.828" height="91.819"
                                    viewBox="0 0 100.828 91.819">
                                   <g id="contact-mail" transform="translate(-5.88 -8.138)">
                                       <path id="Path_83993" data-name="Path 83993"
                                             d="M40.149,29.2H99.442L67.053,54.635a4.332,4.332,0,0,1-2.244.873,4.41,4.41,0,0,1-2.259-.873L39.4,36.452a2.473,2.473,0,0,0-.827-.441,9.032,9.032,0,0,0,1.679-5.4,12.98,12.98,0,0,0-.106-1.411ZM25.071,12.843a2.257,2.257,0,0,1,.464.183A8.274,8.274,0,0,1,27.72,14.82a28.837,28.837,0,0,1,4.16,6.326c.027.053.054.111.08.171,2.432,4.173,3.481,7.364,3.513,9.366a3.637,3.637,0,0,1-2.211,3.857l-4.4,2.536a9.645,9.645,0,0,0-4.556,7.364A12.2,12.2,0,0,0,25.694,52L36.552,70.714a12.329,12.329,0,0,0,5.9,4.979,9.73,9.73,0,0,0,8.685-.246l4.4-2.531A3.689,3.689,0,0,1,60,72.947c1.736,1.031,3.989,3.523,6.411,7.7.034.062.067.114.106.173a28.974,28.974,0,0,1,3.427,6.74,8.21,8.21,0,0,1,.469,2.777c-.052.58-.064.521-.217.612l-3.347,1.919a25.629,25.629,0,0,1-23-.748c-6.9-3.587-12.9-10.065-17.08-17.671-.014-.015-.02-.034-.032-.044L17.572,58.617a.242.242,0,0,1-.034-.049C13.007,51.16,10.366,42.751,10.7,35.014a25.3,25.3,0,0,1,10.85-20.188L24.9,12.913c.077-.044.1-.078.174-.071Zm.034-4.7a4.993,4.993,0,0,0-2.6.643l-3.541,2.036c-.08.046-.153.092-.225.138A30.485,30.485,0,0,0,5.913,34.815c-.383,8.942,2.6,18.17,7.535,26.239l9.129,15.731-.021-.047c4.557,8.281,11.087,15.464,19.067,19.6a30.818,30.818,0,0,0,27.182.881,1.814,1.814,0,0,0,.233-.116l3.546-2.036a5.531,5.531,0,0,0,2.6-4.333,11.725,11.725,0,0,0-.674-4.634A31.645,31.645,0,0,0,70.5,78.2c-2.608-4.486-5.126-7.6-8.035-9.34a9.166,9.166,0,0,0-9.325-.072l-4.4,2.536a4.826,4.826,0,0,1-4.418-.015,8.441,8.441,0,0,1-3.613-2.977L29.849,49.616A8.393,8.393,0,0,1,29.064,45a4.75,4.75,0,0,1,2.2-3.8l4.274-2.457a2.365,2.365,0,0,0,.9,1.441L59.589,58.378a9.092,9.092,0,0,0,5.085,1.945,2.527,2.527,0,0,0,.277,0,9.082,9.082,0,0,0,5.079-1.945l31.881-25.046V74.709c0,.629-.718,1.459-2.126,1.459H77.714a2.384,2.384,0,1,0,0,4.768H99.785c3.608,0,6.923-2.608,6.923-6.227V30.659c0-3.621-3.316-6.228-6.923-6.228H38.836a42.475,42.475,0,0,0-2.689-5.42,31.749,31.749,0,0,0-4.887-7.4,11.812,11.812,0,0,0-3.691-2.9v.006A6.149,6.149,0,0,0,25.1,8.139Z"
                                             transform="translate(0)" fill="#fff"/>
                                   </g>
                               </svg>
                           </div>
                           <div className="info-detail">
                               <h3>Step 2: Add your contact information</h3>
                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                   incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                   exercitation</p>
                           </div>
                       </li>
                       <li>
                           <div className="icon-box">
                               <svg xmlns="http://www.w3.org/2000/svg" width="70.237" height="98.332"
                                    viewBox="0 0 70.237 98.332">
                                   <g id="GPA" transform="translate(10642 13362.521)">
                                       <text id="GPA-2" data-name="GPA" transform="translate(-10631.071 -13279.677)"
                                             fill="#fff" fontSize="27" fontFamily="Poppins" fontWeight="600">
                                           <tspan x="0" y="0">GPA</tspan>
                                       </text>
                                       <g id="Group_2105" data-name="Group 2105"
                                          transform="translate(-10642 -13362.521)">
                                           <g id="chart-custom">
                                               <g id="Group_2104" data-name="Group 2104">
                                                   <path id="Path_2227" data-name="Path 2227"
                                                         d="M20.25,29.8V15.75h7.024V29.8Z"
                                                         transform="translate(28.92 26.396)" fill="#fff"/>
                                                   <path id="Path_2228" data-name="Path 2228"
                                                         d="M15.75,34.573V13.5h7.024V34.573Z"
                                                         transform="translate(19.373 21.623)" fill="#fff"/>
                                                   <path id="Path_2229" data-name="Path 2229"
                                                         d="M11.25,44.121V9h7.024V44.121Z"
                                                         transform="translate(9.823 12.073)" fill="#fff"/>
                                                   <path id="Path_2230" data-name="Path 2230"
                                                         d="M39.977,100.582H11.524A7.03,7.03,0,0,1,4.5,93.566V9.274A7.03,7.03,0,0,1,11.524,2.25h56.19a7.03,7.03,0,0,1,7.024,7.024V66.526h-7.02V9.274H11.524V93.558H39.977Z"
                                                         transform="translate(-4.5 -2.25)" fill="#fff"/>
                                               </g>
                                           </g>
                                       </g>
                                   </g>
                               </svg>
                           </div>
                           <div className="info-detail">
                               <h3>Step 3: Add your academic information</h3>
                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                   incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                   exercitation</p>
                           </div>
                       </li>
                       <li>
                           <div className="icon-box">
                               <svg xmlns="http://www.w3.org/2000/svg" width="56.007" height="56"
                                    viewBox="0 0 56.007 56">
                                   <g id="recruitment" transform="translate(-1.999 -2.002)">
                                       <g id="Group_57809" data-name="Group 57809" transform="translate(1.999 2.003)">
                                           <path id="Path_83982" data-name="Path 83982"
                                                 d="M38.876,34.444a20.388,20.388,0,1,0-2.042,2.385,20.4,20.4,0,0,0,2.042-2.385ZM35.01,32.057v-.575a6.721,6.721,0,0,0-6.73-6.72H26.1l-3.649,6.964c-.018.045-.09.045-.1,0l-3.649-6.964H16.519a6.719,6.719,0,0,0-6.72,6.72v.569A15.88,15.88,0,0,1,33.637,11.166a15.785,15.785,0,0,1,1.6,20.6C35.164,31.869,35.083,31.96,35.01,32.057Z"
                                                 transform="translate(-1.999 -2.003)" fill="#fff"/>
                                           <path id="Path_83983" data-name="Path 83983"
                                                 d="M60.174,53.185,47.88,40.909l-1.274,1.265L43.77,39.337a19.529,19.529,0,0,1-2.05,2.376,21.533,21.533,0,0,1-2.376,2.069l2.827,2.827L40.9,47.874l12.294,12.3a4.941,4.941,0,1,0,6.982-6.991Z"
                                                 transform="translate(-5.61 -5.613)" fill="#fff"/>
                                           <path id="Path_83984" data-name="Path 83984"
                                                 d="M23.912,9.812a6.331,6.331,0,0,0-6.331,6.331c0,3.5,2.835,8.8,6.331,8.8s6.331-5.3,6.331-8.8a6.332,6.332,0,0,0-6.331-6.331Z"
                                                 transform="translate(-3.506 -2.758)" fill="#fff"/>
                                       </g>
                                   </g>
                               </svg>
                           </div>
                           <div className="info-detail">
                               <h3>Step 4: Update your recruitment</h3>
                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                   incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                   exercitation</p>
                           </div>
                       </li>
                   </ul>
                   <div className="button-wrap text-center">
                       {/*code commented as per task 262 8/11/2022*/}
                       {/*<button className="btn" onClick={()=>props?.history?.push('/claimProfileSearch')} >Claim Your Profile!</button>*/}
                       {/*earlier it was redirecting to claimProfileStep2*/}
                   </div>
               </div>
           </section>

           <section className="common-faq-sec">
               <div className="container">
                   <div className="faq-box-wrapper">
                       <h1 className="sec-title">Frequently Asked Questions</h1>
                       <ul className="faq-listing">
                           <li className="active">
                               <div
                                   className={fQ == 1 ?  "faq-title active" : "faq-title"}
                                   onClick={()=>setFQ(1)}
                               >Lorem Ipsum is placeholder text commonly used in the
                                   graphic,print?
                               </div>
                               <div className="faq-data"
                                    style={fQ == 1 ? {display : "block"} : {display : "none"}}
                               >
                                   <p
                                   >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                       the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                       type and scrambled it to make a type specimen book. </p>
                               </div>
                           </li>
                           <li>
                               <div
                                   className={fQ == 2 ?  "faq-title active" : "faq-title"}
                                   onClick={()=>setFQ(2)}
                               >Lorem Ipsum is placeholder text commonly used in the graphic,print?</div>
                               <div className="faq-data"
                                    style={fQ == 2 ? {display : "block"} : {display : "none"}}
                               >
                                   <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                       the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                       type and scrambled it to make a type specimen book. </p>
                               </div>
                           </li>
                           <li>
                               <div
                                   className={fQ == 3 ?  "faq-title active" : "faq-title"}
                                   onClick={()=>setFQ(3)}
                               >Lorem Ipsum is placeholder text commonly used in the graphic,print?</div>
                               <div className="faq-data"
                                    style={fQ == 3 ? {display : "block"} : {display : "none"}}
                               >
                                   <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                       the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                       type and scrambled it to make a type specimen book. </p>
                               </div>
                           </li>
                           <li>
                               <div
                                   className={fQ == 4 ?  "faq-title active" : "faq-title"}
                                   onClick={()=>setFQ(4)}
                               >Lorem Ipsum is placeholder text commonly used in the graphic,print?</div>
                               <div className="faq-data"
                                    style={fQ ==4 ? {display : "block"} : {display : "none"}}
                               >
                                   <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                       the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                       type and scrambled it to make a type specimen book. </p>
                               </div>
                           </li>
                       </ul>
                   </div>
               </div>
           </section>
           <VideoPop
           isTestSingle={isPop}
           setIsTestSingle={()=>setIsPop(false)}
           />
       </>
    )
}