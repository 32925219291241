import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";
import { imageDownloadUrl } from "../../../../config";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_SUGGESTED_COLLEGES_START: "GET_SUGGESTED_COLLEGES_START",
  GET_SUGGESTED_COLLEGES_SUCCESS: "GET_SUGGESTED_COLLEGES_SUCCESS",
  GET_SUGGESTED_COLLEGES_FAILURE: "GET_SUGGESTED_COLLEGES_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const getSuggestedCollegesStart = (data) => ({
  type: actionType.GET_SUGGESTED_COLLEGES_START,
  payload: data,
});

export const getSuggestedCollegesSuccess = (data) => ({
  type: actionType.GET_SUGGESTED_COLLEGES_SUCCESS,
  payload: data,
});

export const getSuggestedCollegesFailure = (data) => ({
  type: actionType.GET_SUGGESTED_COLLEGES_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function suggestedCollegesReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_SUGGESTED_COLLEGES_START:
      return { ...state, loading: true };

    case actionType.GET_SUGGESTED_COLLEGES_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.GET_SUGGESTED_COLLEGES_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* getSuggestedCollegesSaga(action) {
  try {
    const response = yield fetchGraphMethod(action.payload.query, null, true);
    if (response.status === 200) {
      const allColleges = response.data.data.allColleges.Colleges
        ? response.data.data.allColleges.Colleges
        : null;
      //   const totalCount = response.data.data.allColleges
      //     ? response.data.data.allColleges.totalCount
      //     : 0;
      yield put(getSuggestedCollegesSuccess(allColleges));
    } else {
      messagePopup("", "Fetch Suggested Colleges Data Failed", "error");
      yield put(getSuggestedCollegesFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch Suggested Colleges Data Failed", "error");
    yield put(getSuggestedCollegesFailure("Failed, Error occured"));
  }
}

export function* watchSuggestedColleges() {
  yield takeEvery(
    actionType.GET_SUGGESTED_COLLEGES_START,
    getSuggestedCollegesSaga
  );
}
