import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  VIEW_COLLEGE_LIST_START: "VIEW_COLLEGE_LIST_START",
  VIEW_COLLEGE_SAVE_START: "VIEW_COLLEGE_SAVE_START",
  VIEW_COLLEGE_SUCCESS: "VIEW_COLLEGE_SUCCESS",
  VIEW_COLLEGE_FAILURE: "VIEW_COLLEGE_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const viewCollegeListStart = (data) => ({
  type: actionType.VIEW_COLLEGE_LIST_START,
  payload: data,
});

export const viewCollegeSaveStart = (data) => ({
  type: actionType.VIEW_COLLEGE_SAVE_START,
  payload: data,
});

export const viewCollegeSuccess = (data) => ({  
    type: actionType.VIEW_COLLEGE_SUCCESS,
    payload: data,
});
  
export const viewCollegeFailure = (data) => ({
    type: actionType.VIEW_COLLEGE_FAILURE,
    payload: data
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function viewCollegeReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.VIEW_COLLEGE_LIST_START:
      return { ...state, loading: true };

    case actionType.VIEW_COLLEGE_SAVE_START:
      return { ...state, loading: true };
    
    case actionType.VIEW_COLLEGE_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.VIEW_COLLEGE_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* saveViewCollegeSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true)
        if(response && response.status === 200) {
           const saveViewCollege = response;
           yield put(viewCollegeSuccess(saveViewCollege));
        } else {
          messagePopup('', 'Save View College Failed', 'error');
          yield put(viewCollegeFailure('Failed, Error occured'))
        }
    } catch (error) {
        messagePopup('', 'Save View College Failed', 'error');
        yield put(viewCollegeFailure('Failed, Error occured'))
    }
}

function* getViewCollegeSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true)
        if(response.status === 200){
            const allViewCollege = response;
            yield put(viewCollegeSuccess(allViewCollege));
        } else {
          messagePopup('', 'Fetch View College Data Failed', 'error');
          yield put(viewCollegeFailure('Failed, Error occured'))
        }
       } catch (error) {
         messagePopup('', 'Fetch View College Data Failed', 'error');
         yield put(viewCollegeFailure('Failed, Error occured'))
       }
}

export function* watchViewCollegeSaga() {
  yield takeEvery(actionType.VIEW_COLLEGE_SAVE_START, saveViewCollegeSaga);
  yield takeEvery(actionType.VIEW_COLLEGE_LIST_START, getViewCollegeSaga);
}

