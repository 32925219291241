import React, { useState, useEffect } from "react";
import "../assets/css/athleteDashUnverified.scss";
import "../../../../../../assets/css/stylesheet.scss";
import PostUpdate from "./PostUpdate";
import MyFeed from "./MyFeed";
import LeftSideBar from "../leftSideBar/LeftSideBar";
import RightSideBar from "../rightSideBar/RightSideBar";
import { connect } from "react-redux";
import { getPostStart } from "../../../../../../redux/athlete/dashboard/homepage/posts";
import LinearProgress from "@mui/material/LinearProgress";
import {
  getAllPostsQuery,
  getAllUserActivity,
  savePostLikeQuery,
  savePostQuery,
  updatePostsQuery,
} from "./PostsQuery";
import { getUserInfo, setUserInfo } from "../../../../../../services/authService";
import GlobalFeed from "./GlobalFeed";
import SharePost from "./SharePost";
import Dialog from "@mui/material/Dialog";
import { Link } from "react-router-dom";
import {
  pinPostStart,
  removePostStart,
  unpinPostStart,
  updatePostStart,
} from "../../../../../../redux/athlete/dashboard/homepage/savePost";
import { messageListStart } from "../../../../../../redux/athlete/dashboard/message/message";
import { globalFeedListStart } from "../../../../../../redux/athlete/dashboard/homepage/globalFeeds";
import { postLikeUnlikeStart } from "../../../../../../redux/athlete/dashboard/homepage/postLikeUnlike";
import swal from "sweetalert";
import GoTopButton from "../../editUserProfile/GoTopButton";
import {flameAPI} from "../../../../../common/userViewCount";
import {GetHelmet} from "../../../../../common/helmet";

import { fetchGraphMethod } from "../../../../../../services/httpService";
import { saveAddedEventQuery } from "../../../dashboard/editUserProfile/events/AddEditEventFormQuery"

const HomePage = (props) => {
  const Role = getUserInfo()?.role
  const [showModal, setShowModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoader] = useState(false);
  const [feeds, setFeeds] = useState("global-feed");
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  });
  const initialLimit = 10;
  const [myPostList, setMyPostList] = useState(null);
  const [globalPostList, setGlobalPostList] = useState(null);
  const [firstDropDown, setFirstDropdown] = useState(1);
  const [secondDropDown, setSecondDropdown] = useState(1);
  const [containPin, setContainPin] = useState(false);
  //state is for share popup
  const [modalData, setModalData] = React.useState(null);
  

  //this func is called when share popup is open
  const handleModal = (e, value) => {
    // e.preventDefault()
    setModalData(value);
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (feeds === "global-feed") {
      getAllGlobalFeeds();
    }
  }, []);

  useEffect(() => {
    getFinalListData();
  }, [firstDropDown, secondDropDown]);

  const getFinalListData = () => {
    if (feeds === "my-feed") {
      getPosts();
    }
    if (feeds === "global-feed") {
      getAllGlobalFeeds();
    }
  };

  useEffect(() => {
    if (pagination && pagination.limit !== initialLimit) {
      if (feeds === "my-feed") {
        getPosts();
      } else {
        getAllGlobalFeeds();
      }
    }
  }, [pagination]);

  useEffect(() => {
    if (props?.posts?.data) {
      if (feeds === "my-feed") {
        let finalData = [];
        for (const post of props.posts.data.allData) {
          if (post.pinedStatus) {
            finalData = [
              {
                ...post,
                isPostLiked:
                  post.userpostLikeStatus && post.userpostLikeStatus[0]
                    ? post.userpostLikeStatus[0].isLike
                    : null,
                postLikeId:
                  post.userpostLikeStatus && post.userpostLikeStatus[0]
                    ? post.userpostLikeStatus[0].id
                    : null,
              },
              ...finalData,
            ];
            setContainPin(true);
          } else {
            finalData = [
              ...finalData,
              {
                ...post,
                isPostLiked:
                  post.userpostLikeStatus && post.userpostLikeStatus[0]
                    ? post.userpostLikeStatus[0].isLike
                    : null,
                postLikeId:
                  post.userpostLikeStatus && post.userpostLikeStatus[0]
                    ? post.userpostLikeStatus[0].id
                    : null,
              },
            ];
          }
        }
        setMyPostList(finalData);
        setTotalCount(props.posts.data.totalCount);
      }
    } else {
      setMyPostList([]);
      setTotalCount(0);
    }
  }, [props.posts.data]);

  useEffect(() => {
    if (props.globalFeeds.data) {
      let finalData = [];
      for (const post of props.globalFeeds.data.allData) {
        finalData = [
          ...finalData,
          {
            ...post,
            isPostLiked:
              post.userpostLikeStatus && post.userpostLikeStatus[0]
                ? post.userpostLikeStatus[0].isLike
                : null,
            postLikeId:
              post.userpostLikeStatus && post.userpostLikeStatus[0]
                ? post.userpostLikeStatus[0].id
                : null,
          },
        ];
      }
      if (feeds === "global-feed") {
        setGlobalPostList(finalData);
        setTotalCount(props.globalFeeds.data.totalCount);
      }
    } else {
      setGlobalPostList([]);
      setTotalCount(0);
    }
  }, [props.globalFeeds.data]);

  //used for scroll the page on top
  useEffect( () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  const getPosts = () => {
    props.getPostStart({
      offset: pagination.offset,
      limit: pagination.limit,
      loginUserId: getUserInfo().id,
      firstDropDownValue: firstDropDown,
      secondDropDownValue: secondDropDown,
    });
  };

  const handleTabClick = (e, tab) => {
    e.preventDefault();
    if (tab === "my-feed") {
      getPosts();
    } else {
      getAllGlobalFeeds();
    }
    setFeeds(tab);
  };

  const getAllGlobalFeeds = () => {
    const { Athlete_Sports, id } = getUserInfo();
    props.globalFeedListStart({
      sportid: Athlete_Sports,
      userid: id,
      limit: pagination.limit,
      offset: pagination.offset,
    });
  };

  const afterPinningPost = async () => {
   const res = await  swal({
      text: " Your post has been pinned to the top.",
      icon: "success",
    })
   if(res){
        window.location.reload();
    }else{
     window.location.reload();
   }
  };

  const afterUnPinningPost = async () => {
    const res = await swal({
      text: " Your post has been unpinned.",
      icon: "success",
    })
     if(res){
        window.location.reload();
      }else{
       window.location.reload()
     }
  };

  const handleUnpinPost = (post) => {
    props.unpinPostStart({
      query: updatePostsQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
          id: post.serialNo,
          ispined: 0,
          // updatedon: post?.updatedOn ?? new Date()
        },
      },
      getPosts,
      afterUnPinningPost,
    });
    // window.location.reload();
  };

  const handlePinPost = (post) => {
    props.pinPostStart({
      query: updatePostsQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
          id: post.serialNo,
          ispined: 1,
          // updatedon: post?.updatedOn ?? new Date()
        },
      },
      getPosts,
    });
    let filteredPost = [];
    for (const data of myPostList) {
      if (data.serialNo === post.serialNo) {
        filteredPost = [{ ...data, pinedStatus: 1 }, ...filteredPost];
      } else {
        filteredPost = [...filteredPost, data];
      }
    }
    setMyPostList(filteredPost.length ? filteredPost : null);
    afterPinningPost();
  };
  const removeMyPostPage = (page) =>{
      if (page == "Dashboard/MyFeed" || page == "Timeline"){
        // window.location.reload()
      }
  }
  const handleRemovePost = async (post, page) => {
    props.removePostStart({
      query: updatePostsQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
          id: post.serialNo,
          active: 0,
        },
      },
      uploadVideoAndPhotoId: post.uploadVideoAndPhotoId
        ? post.uploadVideoAndPhotoId
        : null,
      getPosts,
      deletePostId: post.id,
      page: page,//this is done for fixing the carousel auto-hieght issue
      removeMyPostPage,//this is done for fixing the carousel auto-hieght issue
    });
    const filteredPost = [...myPostList].filter(
      (item) => item.serialNo !== post.serialNo
    );

    // after event delete uncheck 'Check here to post it on your Timeline' checkbox on event section 
    if (!!post?.eventmappingid) {
      const res = await fetchGraphMethod(
        saveAddedEventQuery,
        {
          obj: {
            id: post?.eventmappingid ?? null,
            ispostevent: 0,
          },
        },
        true
      );
    }
    // end

    setMyPostList(filteredPost);
    //below api is for doing active 0 on allvideophoto table due to this when user go to video and section
    // video and photo will delete but id is not match to the allvideophoto table need to corrected.
    // const status =  (post?.id && post.allVideoAudioPost?.length > 0) ? true : false
    // if(status){
    //   const userAct = {
    //     where:{
    //       postid: post?.allVideoAudioPost[0]?.postId,
    //       userid: !!getUserInfo()?.id ? getUserInfo()?.id : 0,
    //       active:  1
    //     }
    //   }
    //   const resUser = await fetchGraphMethod(getAllUserActivity, userAct,true)
    // }
    // post.allVideoAudioPost.map(async (itm, idx)=>{
    //   const finalObj = {
    //         obj:{
    //           userid : !!getUserInfo()?.id ? getUserInfo()?.id : 0,
    //           id: +itm?.id,
    //           active: 0
    //         }
    //       },
    //
    //       resVP = await fetchGraphMethod(saveAllVideoPhotoInfoQuery,finalObj,true)
    //   if(resVP?.status != 200) return swal({text : "all video saving failed", icon: "error" })
    // })
  };

  const handleUnlike = (e, post) => {
    e.preventDefault();
    props.postLikeUnlikeStart({
      query: savePostLikeQuery,
      variables: {
        obj: {
          active: 0,
          // postid: post.id,
          postactivityid: post.serialNo,
          userid: getUserInfo().id,
          id: post.postLikeId ? post.postLikeId : 0,
        },
      },
      refreshPage: feeds === "my-feed" ? getPosts : getAllGlobalFeeds,
      type: "unlike",
    });
    if (feeds === "my-feed") {
      const modifiedData = myPostList.map((item) => {
        if (item.id === post.id) {
          item.isPostLiked = 0;
          item.totalLikeCount = item.totalLikeCount - 1;
        }
        return item;
      });
      setMyPostList(modifiedData);
    } else {
      const modifiedData = globalPostList.map((item) => {
        if (item.id === post.id) {
          item.isPostLiked = 0;
          item.totalLikeCount = item.totalLikeCount - 1;
        }
        return item;
      });
      setGlobalPostList(modifiedData);
    }
  };

  const handleLike = (e, post) => {
    e.preventDefault();
    props.postLikeUnlikeStart({
      query: savePostLikeQuery,
      variables: {
        obj: {
          active: 1,
          // postid: post.id,
          postactivityid: post.serialNo,
          userid: getUserInfo().id,
          id: post.postLikeId ? post.postLikeId : 0,
        },
      },
      refreshPage: feeds === "my-feed" ? getPosts : getAllGlobalFeeds,
      setPostData: feeds === "my-feed" ? setMyPostList : setGlobalPostList,
      initialData: feeds === "my-feed" ? myPostList : globalPostList,
      type: "like",
    });
    let flameUserRole = null
    if (getUserInfo()?.role == "GUARDIAN"){
      flameUserRole = "ATHLETES"
    }else{
      flameUserRole = getUserInfo()?.role
    }
    if ((post?.userid || post?.userId) != getUserInfo()?.id){
      flameAPI('flamefromprofile',post?.userid || post?.userId ,getUserInfo()?.id,false, flameUserRole)
    }
    };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstDropDown") {
      if (+value === 1) {
        setFeeds("global-feed");
      }
      if (+value === 2 || +value === 3) {
        setFeeds("my-feed");
      }
      setFirstDropdown(+value);
    }
    if (name === "secondDropDown") {
      setSecondDropdown(+value);
    }
  };

  const handlePagination = () => {
    let totalLimit = +pagination.limit + 10;
    setPagination({
      limit: totalLimit,
      offset: 0,
    });
  };

  const goTop=()=>{
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

   useEffect(()=>{
     setTimeout(()=>{
       window.scrollTo(0,0)
     },1200)
   },[])
  return (
    <>
      {/*<GetHelmet*/}
      {/*title={"SportsForce"}*/}
      {/*content={"Athlete Site"}*/}
      {/*/>*/}
      <div className="athleteDashSection">
        <div className="container ">
          <div className="flexBox">
            <div className="leftDataBox">
              <LeftSideBar />
            </div>
            <div className="dataBox">
              <PostUpdate setLoader={setLoader} getPosts={getPosts} firstDropDown={firstDropDown}/>
              <div style={{ height: "4px" }}>
                {(loading ||
                  props.savePost.loading ||
                  props.posts.loading ||
                  props.globalFeeds.loading) && <LinearProgress />}
              </div>
              <div className="tabsBox">
                <div className="tabFlex">
                  <div
                    // className={`tab ${feeds === "my-feed" ? "selected" : ""}`}
                    className="tab selected"
                  >
                    <Link
                      to="#"
                      // onClick={(e) => handleTabClick(e, "my-feed")}
                    >
                      ACTIVITY FEED
                    </Link>
                  </div>
                  {/* Global Feed section removed */}
                  {/* <div
                    className={`tab ${
                      feeds === "global-feed" ? "selected" : ""
                    }`}
                  >
                    <Link
                      style={{ cursor: "not-allowed" }}
                      to="#"
                      onClick={(e) => handleTabClick(e, "global-feed")}
                    >
                      comment this text as per said dated 9/2/222
                      Global Feed
                    </Link>
                  </div> */}
                </div>
                <div className="filterBox">
                  <div className="half">
                    <select
                      value={firstDropDown}
                      name="firstDropDown"
                      onChange={handleChange}
                    >
                      <option value={1}>Everyone</option>
                      <option value={2}>My Activity</option>
                      <option value={3}>College</option>
                    </select>
                  </div>
                  <div className="half">
                    <select
                      value={secondDropDown}
                      name="secondDropDown"
                      onChange={handleChange}
                    >
                      {firstDropDown == 2 ? (
                        <>
                          <option value={1}>All Activity</option>
                          <option value={2}>Message</option>
                          <option value={4}>Favorite</option>
                        </>
                      ) :
                      firstDropDown == 3 ?
                      <>
                        <option value={1}>All Activity</option>
                        <option value={4}>Favorite</option>
                      </>
                        :
                      <>
                        <option value={1}>All Activity</option>
                      </>
                      }
                    </select>
                  </div>
                </div>
                {feeds === "my-feed" ? (
                  <MyFeed
                    postList={myPostList}
                    handleModal={handleModal}
                    handleRemovePost={handleRemovePost}
                    handlePinPost={handlePinPost}
                    handleUnpinPost={handleUnpinPost}
                    handleLike={handleLike}
                    handleUnlike={handleUnlike}
                    containPin={containPin}
                    isAuth = { 1 }
                    page = {"Dashboard/MyFeed"}
                  />
                ) : (
                  <GlobalFeed
                    postList={globalPostList}
                    handleModal={handleModal}
                    handleLike={handleLike}
                    handleUnlike={handleUnlike}
                    isAuth = { 1 }
                    page = {"Dashboard/Global"}
                  />
                )}
                {feeds === "my-feed" ? (
                  (myPostList?.length > 0 ?? true) &&
                  (myPostList && myPostList.length) != totalCount ? (
                    <div className="loadMoreBox">
                      <Link to="#" onClick={(e) => handlePagination(e)}>
                        Load More
                      </Link>
                    </div>
                  ) : null
                ) : feeds === "global-feed" ? (
                  (globalPostList?.length > 0 ?? true) &&
                  (globalPostList && globalPostList.length) != totalCount ? (
                    <div className="loadMoreBox">
                      <Link to="#" onClick={(e) => handlePagination(e)}>
                        Load More
                      </Link>
                    </div>
                  ) : null
                ) : null}
                {/* {(feeds === "my-feed" && myPostList && myPostList.length) != totalCount  &&
                (feeds === "global-feed" &&
                  globalPostList &&
                  globalPostList.length) != totalCount  ? (
                  <div className="loadMoreBox">
                    <Link to="#">Load more feeds</Link>
                  </div>
                ) : null} */}
              </div>
            </div>

            <div className="rightDataBox">
              <RightSideBar />
            </div>
          </div>
        </div>
      </div>
      <Dialog open={showModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  handleModal()
                }
              }}
              className="matDialogCustom"
      >
        <SharePost
          handleModal={handleModal}
          modalData={modalData}
          pageType={"Home"}
        />
      </Dialog>
      <div style={{position: "relative",zIndex: 99}}>
      <GoTopButton  goTop={goTop}/>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    savePost: state.athlete.dashboard.home.savePost,
    posts: state.athlete.dashboard.home.posts,
    globalFeeds: state.athlete.dashboard.home.globalFeeds,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPostStart: (data) => dispatch(getPostStart(data)),
    removePostStart: (data) => dispatch(removePostStart(data)),
    pinPostStart: (data) => dispatch(pinPostStart(data)),
    unpinPostStart: (data) => dispatch(unpinPostStart(data)),
    globalFeedListStart: (data) => dispatch(globalFeedListStart(data)),
    postLikeUnlikeStart: (data) => dispatch(postLikeUnlikeStart(data)),
    messageListStart: (data) => dispatch(messageListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
