import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    GET_PROFILE_TIMELINE_ABOUT_START: "GET_PROFILE_TIMELINE_ABOUT_START",
    GET_PROFILE_TIMELINE_ABOUT_SUCCESS: "GET_PROFILE_TIMELINE_ABOUT_SUCCESS",
    GET_PROFILE_TIMELINE_ABOUT_FAIL: "GET_PROFILE_TIMELINE_ABOUT_FAIL",
};

// ============================================ Actions ========================================================
export const profileTimelineAboutStart = (data) => ({
    type: actionType.GET_PROFILE_TIMELINE_ABOUT_START,
    payload: data,
});

export const profileTimelineAboutFail = (data) => ({
    type: actionType.GET_PROFILE_TIMELINE_ABOUT_FAIL,
    payload: data,
});

export const profileTimelineAboutSuccess = (data) => ({
    type: actionType.GET_PROFILE_TIMELINE_ABOUT_SUCCESS,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function profileTimelineAboutReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.GET_PROFILE_TIMELINE_ABOUT_START:
            return {
                ...state,
                loading: true,
            };

        case actionType.GET_PROFILE_TIMELINE_ABOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };

        case actionType.GET_PROFILE_TIMELINE_ABOUT_FAIL:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };

        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* profileTimelineAboutSaga(action) {
    try {
        const response = yield fetchMethod(
            `/api/Publicprofilefieldpreviews/allAbout`,
            action.payload,
            "post",
            action?.payload?.isAccessToken
        );
        if (
            response &&
            response.status === 200 &&
            response.data.response.About_data
        ) {
            yield put(
                profileTimelineAboutSuccess({
                    profileTimelineAbout: response.data.response.About_data[0],
                })
            );
        } else {
            messagePopup("", "Profile About failed", "error");
            yield put(profileTimelineAboutFail("Profile Timeline About failed"));
        }
    } catch (error) {
        messagePopup("", "Profile Timeline About failed", "error");
        yield put(profileTimelineAboutFail("Profile Timeline About failed"));
    }
}

//Watcher
export function* watchProfileTimelineAboutSaga() {
    yield takeEvery(actionType.GET_PROFILE_TIMELINE_ABOUT_START, profileTimelineAboutSaga);
}
