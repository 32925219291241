export const getAllAcadamicSelectivities = `
query allAcadamicselectivities{
    allAcadamicselectivities{
      totalCount
      Acadamicselectivities{
        name
        id
      }
    }
}
`

export const getAllTutionCosts = `
query allTutioncosts{
  allTutioncosts{
    totalCount
    Tutioncosts{
      id
      name
    }
  }
}`

export const getAllCollegeSizes = `
query allCollegesizes{
  allCollegesizes{
    totalCount
    Collegesizes{
      id
      name
    }
  }
}`


export const getAllCollegeSettings = `
query allCollegesettings{
  allCollegesettings{
    totalCount
    Collegesettings{
      id
      name
    }
  }
}`

export const getAllCollegedivisions = `
query allCollegedivisions{
  allCollegedivisions{
    totalCount
    Collegedivisions{
      id
      collegedivisionname
      divisionathleticselectivitymappingCollegedivisionidMap{
        Divisionathleticselectivitymappings{
          athleticselectivityid
          divisionathleticselectivitymappingathleticselectivityidmaprel{
            Athleticselectivities{
              id
              name
            }
          }
        }
      }
    }
  }
}`;

export const getAllRegion = `
query allRegions($where: JSON){
  allRegions(where: $where){
    Regions{
      id
      regionname
      stateregionidMap{
        States{
          id
          statename
          regionid
          initials
        }
      }
    }
  }
}`

export const getAllState = `
query allStates{
  allStates(where:null){
    totalCount
    States{
      id
      statename
      regionid
    }
  }
}`

export const destroyAllCollegeSetting = `
mutation CollegepreferencesettingDestroyAll($data:JSON){
  CollegepreferencesettingDestroyAll(where:$data)
}
`
export const saveCollegesetting = `
mutation collegesetting($data:JSON){
  CollegepreferencesettingCreate(data:$data){
    id
  }
}
`

export const saveCollegePreferenceSettingQuery = `
mutation saveCollegepreferencesetting($obj:CollegepreferencesettingInput!){
    saveCollegepreferencesetting(obj: $obj){
      id
    } 
  }
`;

export const allCollegepreferencesettings = `
query allCollegepreferencesettings($data:JSON){
  allCollegepreferencesettings(where:$data){
    totalCount
    Collegepreferencesettings{
      id
      acadamicselectivityid
      athleticselectivityid
      collegesetting
      areaofstudy
      collegesize
      createdby
      isallstate
      levelid
      postgradschoolinterest
      regionid
      stateid
      tutioncost
      updatedby
      userid
    }
  }
}`

export const getAllActiveState = `
query allStates($where:JSON){
  allStates(where:$where){
    totalCount
    States{
      id
      statename
      regionid
      countryid
    }
  }
}`