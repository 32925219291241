export const previewProfileQuery = `
 query allUserdata($obj: JSON){
    allUserdata(where: $obj){
    Userdata{
      athletefirstname
      athletelastname
      athleteemail
      athletephonenumber
      clubteamname
      userdataathletecountrycodeidemaprel{
        Countries{
          countrycode
        }
      }
      userdataathletesportsmaprel{
        Athletesports{
          sportname
        }
      }
      userdataathletegraduationyearmaprel{
        Graduationyears{
          yearvalue
        }
      }onBoardingUserIdMap{
        Onboardings{
        profileimages
        highschoolname
        twitteraccountname
        heightincentimeter
        weightinkilogram
        onboardingprimarypositionmaprel{
          Primarypositions{
            selectionfield
          }
        }
        onboardingsecondarypositionmaprel{
          Secondarypositions{
            selectionfield
          }
        }
        onboardingbattingprefencevaluemaprel{
          Battingpreferences{
            battingpreferencevalue
        }
        }
        onboardingthrowingprefencevaluemaprel{
          Throwingpreferences{
            throwingpreferencevalue
        }
        }
        }
    } 
    }
    }
}
  `;