import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../services/httpService";
import { imageDownloadUrl } from "../../../../config";
import {askAQuestionSuccess} from "./askAQuestion";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
    CHANGE_ATHLETE_PROFILE_VIEW_START: "CHANGE_ATHLETE_PROFILE_VIEW_START",
    CHANGE_ATHLETE_PROFILE_VIEW_STATE: "CHANGE_ATHLETE_PROFILE_VIEW_STATE",
    CHANGE_ATHLETE_PROFILE_VIEW_FAILURE: "CHANGE_ATHLETE_PROFILE_VIEW_FAILURE",
};

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
    loading: false,
    status: false,
    error: null,
    athleteId: null
};
export default function getAthleteProfileStatusReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.CHANGE_ATHLETE_PROFILE_VIEW_START:
            return { ...state, loading: true };

        case actionType.CHANGE_ATHLETE_PROFILE_VIEW_STATE:
            return { ...state, loading: false, status: action.payload.status, athleteId: action?.payload?.id || 0, error: null };

        case actionType.CHANGE_ATHLETE_PROFILE_VIEW_FAILURE:
            return { ...state, loading: false, data: null, error: action.payload };

        default:
            return { ...state };
    }
}

/* ======================================= Side Effects ==========================================*/

function* getAthleteProfileStatusSaga(action) {
    yield put({type:action.type, payload : action.payload})
}

export function* watchAthleteProfileStatus() {
    yield takeEvery(actionType.CHANGE_ATHLETE_PROFILE_VIEW_START, getAthleteProfileStatusSaga);
}
