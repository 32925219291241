export const getFollowersQuery = `
query allFollowers($obj: JSON){
  allFollowers(where: $obj){
    totalCount
    Followers {
      isfollowed
      isbothfollow
      id
      followerid
      followingid
      followersfolloweridmaprel {
        Athletes {
          id
          athletefirstname
          athletelastname
          verifieduser
          clubteamname
          athleteathletesportsmaprel {
            Athletesports {
              sportname
            }
          }
          athleteathletegraduationyearmaprel {
            Graduationyears {
              yearvalue
            }
          }
          onBoardingUserIdMap {
            Onboardings {
              profileimages
              onboardingprimarypositionmaprel {
                Primarypositions {
                  selectionfield
                }
              }
              onboardingsecondarypositionmaprel {
                Secondarypositions {
                  selectionfield
                }
              }
            }
          }
        }
      }
    }
  }
} 
`;

export const getFollowingsQuery = `
query allFollowers($obj: JSON){
  allFollowers(where: $obj){
    totalCount
    Followers {
      isfollowed
      isbothfollow
      id
      followingid
      followerid
      followersfollowingidmaprel {
        Athletes {
          id
          athletefirstname
          athletelastname
          verifieduser
          clubteamname
          athleteathletesportsmaprel {
            Athletesports {
              sportname
            }
          }
          athleteathletegraduationyearmaprel {
            Graduationyears {
              yearvalue
            }
          }
          onBoardingUserIdMap {
            Onboardings {
              profileimages
              onboardingprimarypositionmaprel {
                Primarypositions {
                  selectionfield
                }
              }
              onboardingsecondarypositionmaprel {
                Secondarypositions {
                  selectionfield
                }
              }
            }
          }
        }
      }
    }
  }
} 
`;
