import { put, takeEvery } from "redux-saga/effects";
import {
    removeSignupData,
    setAuthToken,
    setUserInfo,
} from "../../../services/authService";
import { fetchMethod, fetchGraphMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
import history from "../../../services/history";

// ========================================== Action Type =====================================================
export const actionType = {
    PREVIEW_PROFILE_START: "PREVIEW_PROFILE_START",
    PREVIEW_PROFILE_SUCCESS: "PREVIEW_PROFILE_SUCCESS",
    PREVIEW_PROFILE_FAILED: "PREVIEW_PROFILE_FAILED",
    PREVIEW_PROFILE_STATE: "PREVIEW_PROFILE_STATE",
};

// ============================================ Actions ========================================================

export const previewProfileStart = (data) => ({
    type: actionType.PREVIEW_PROFILE_START,
    payload: data,
});

export const previewProfileState = () => ({
    type: actionType.PREVIEW_PROFILE_STATE,
});

export const previewProfileSuccess = (data) => ({
    type: actionType.PREVIEW_PROFILE_SUCCESS,
    payload: data,
});

export const previewProfileFailed = (data) => ({
    type: actionType.PREVIEW_PROFILE_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    // loading: false,
    data: null,
    error: null,
};

export default function previewProfileReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.PREVIEW_PROFILE_STATE:
            return {
                ...state,
                data: null,
                error: null,
            };
        case actionType.PREVIEW_PROFILE_START:
            return {
                ...state,
                // loading: true,
            };
        case actionType.PREVIEW_PROFILE_SUCCESS:
            return {
                ...state,
                // loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.PREVIEW_PROFILE_FAILED:
            return {
                ...state,
                // loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================
export function* previewProfileSaga(action) {
    try {
        const response = yield fetchGraphMethod(
            action.payload.query,
            action.payload.variables,
            true
        );
        if (response && response.status === 200) {
            if (
                response.data &&
                response.data.data &&
                response.data.data.allUserdata
            ) {
                if (response.data.data.allUserdata.Userdata) {
                    
                    yield put(previewProfileSuccess(response.data.data.allUserdata.Userdata));

                } else {
                    yield put(previewProfileSuccess(response.data.data.allUserdata.Userdata));
                }
            } else if (response.data.response.status === 201) {
                messagePopup("", response.data.response.msg, "error");
            }
        } else {
            messagePopup("", "Wrong otp", "error");
            yield put(previewProfileFailed("Wrong otp"));
        }
    } catch (error) {
        messagePopup("", "Wrong otp", "error");
        yield put(previewProfileFailed("Wrong otp"));
    }
}

export function* watchPreviewProfileSaga() {
    yield takeEvery(actionType.PREVIEW_PROFILE_START, previewProfileSaga);
}
