import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { lastContactListStart } from "../../../../../redux/athlete/dashboard/colleges/messageSent";
import { photoVideoViewsListStart } from "../../../../../redux/common/allPhotoVideoViews";
import { getSlashFormattedDate } from "../../../../../utils/dateTimeFormatter";
import { getMessageRecievedQuery, getVideoViewsQuery } from "./myColleges/CollegeListQuery";

function ActivityOverview(props) {
  const [expand, setExpand] = React.useState(false);
  const [msgRecievedDate, setMsgRecievedDate] = useState();
  const [lastContactdata, setLastContactData] = useState();
  const [lastContactDate, setLastContactDate] = useState();
  const [profileViews, setProfileViews] = useState();
  const [videoViewsData, setVideoViewsData] = useState();
  const [videoViews, setVideoViews] = useState();
  const [collegeId, setCollegeId] = useState();
  const handleExpandCollapseDetails = () => {
    setExpand(!expand);
  };
  useEffect(()=> {
    // getMessageReplyList();
    // getVideoViewsData();
  }, [])
  useEffect(()=> {
   getMessageRecievedDate();
   getLastContactDate();
   if(props.collegeid.collegeid){
     setCollegeId(props.collegeid.collegeid)
   }else if(props.collegeid.id){
     setCollegeId(props.collegeid.id)
   }
  }, [props.msgReplyArray, props.collegeid, lastContactdata])

  useEffect(()=> {
  if(props.lastContactData.data){
    setLastContactData(props.lastContactData.data)
  }
  }, [props.lastContactData.data])

  useEffect(()=> {
    const views = props?.profileViews ? props?.profileViews.map((view)=> {
    const collegeViews =   view && view.filter((viewcollege)=> {
        return ( collegeId == viewcollege.collegeid) 
      })
      const viewCount = collegeViews && collegeViews.map((data)=> {
        if(data.collegeid == collegeId){
          setProfileViews( collegeViews.length)
        }
      })
    }) : null;
  }, [props.profileViews, props.collegeid, props.viewdata])

const getMessageRecievedDate = () => {
    const collegeMsgRecievedDate = props.msgReplyArray && props.msgReplyArray.map((data)=> {
      if(collegeId ==  data[0]?.collegeid ){
        setMsgRecievedDate(data[0].updatedon.split("T")[0])
      }
    });
  }
  const getVideoViewsData = () => {
    props.photoVideoViewsListStart({
      query : getVideoViewsQuery
    })
  }
  useEffect(()=> {
  if(props.videoViews.data){
    setVideoViewsData(props.videoViews.data)
  }
  const views = videoViewsData ? videoViewsData.map((videoview)=> {
    if( videoview?.collegeid == collegeId){
      setVideoViews(videoview?.videoview)
    }
  }) : null;
  }, [props.videoViews.data])

  const getLastContactDate = () => {
    const lastContact = lastContactdata && lastContactdata.map((data)=> {
      if(collegeId == data.collegeid){
        setLastContactDate(data?.updatedon.split("T")[0])
      }
    })
  }
  const getMessageReplyList = () => {
    props.lastContactListStart({
      query: getMessageRecievedQuery,
      variables: {
        where: {
          msgreceivercoachid : {neq: null},
          collegeid : props?.collegeid?.collegeid,
          order: "id desc"
        },
        first : 1,
        last : 1
      }
    });
  }
  return (
    <div>
      <button
        className={`btn ${expand ? "expand" : "collapse"}`}
        type="button"
        onClick={handleExpandCollapseDetails}
      >
        {/* <h5>Activity / College Overview</h5> */}
        <h5>College Overview</h5>
        <div className="closeIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4.568"
            height="7.99"
            viewBox="0 0 4.568 7.99"
          >
            <defs>
              <style></style>
            </defs>
            <path
              className="a"
              d="M1.377,3.993,4.4.972a.569.569,0,0,0,0-.806.576.576,0,0,0-.809,0L.166,3.589a.57.57,0,0,0-.017.787l3.44,3.447A.571.571,0,0,0,4.4,7.017Z"
              transform="translate(4.568 7.99) rotate(180)"
            />
          </svg>
        </div>
      </button>
      <div className={`collapse ${expand ? "show" : ""}`} id="collapseExample">
        <div className="card card-body">
          {/* Activity overview commented for alpha1.0  */}
          {/* <div className="actionOverView">
            <h4 className="heading">Activity Overview</h4>
            <div className="flexBox">
              <ul>
              <li>
                  <label>Last Contact</label>
                  <span>{msgRecievedDate ? getSlashFormattedDate(msgRecievedDate) : 'N/A' }</span>
                </li>
                <li>
                  <label>Messages Sent</label>
                  <span>{lastContactDate ? getSlashFormattedDate(lastContactDate) : "N/A"}</span>
                </li>
                <li>
                  <label>Messages Recieved</label>
                  <span>{msgRecievedDate ? getSlashFormattedDate(msgRecievedDate) : 'N/A' }</span>
                </li>
                <li>
                  <label>Profile Views</label>
                  <span>{profileViews  ? profileViews : "N/A"}</span>
                </li>
                <li>
                  <label>Video Views</label>
                  <span>{videoViews ? videoViews : "N/A"}</span>
                </li>
                <li>
                  <label>Document Views</label>
                  <span>N/A</span>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="collegeOverView">
            {/* <h4 className="heading">College Overview</h4> */}
            <div className="flexBox">
              <div className="academicData">
                <ul>
                  <li>
                    <label>Athletic Conference</label>
                    <span>{
                    props?.collegeInfo?.conferencenmae 
                    || props?.collegeInfo?.collegeconferenceidmaprel?.Collegeconferences[0]?.conferencenmae
                  }
                    </span>
                  </li>
                  <li>
                    <label>Academic Selectivity</label>
                    <span>{props?.collegeInfo?.acadamicSelectivityName || props?.collegeInfo?.collegeacademicsselectivitymaprel?.Acadamicselectivities[0]?.name}</span>
                  </li>
                </ul>
              </div>
              <div className="tutionData">
                <ul>
                  <li>
                    <label>School Size</label>
                    <span>{props?.collegeInfo?.collegestrength ? props?.collegeInfo?.collegestrength.toLocaleString('en-US', {maximumFractionDigits:2}) : 0}</span>
                  </li>
                  <li>
                    <label>Tuition In</label>
                    <span>{`$${props?.collegeInfo?.collegetutioninsidecost ? Number(props?.collegeInfo?.collegetutioninsidecost).toLocaleString('en-US', {maximumFractionDigits:2}) : 0}`}</span>
                  </li>
                  <li>
                    <label>Tuition Out</label>
                    <span>{`$${props?.collegeInfo?.collegetutionoutsidecost ? Number(props?.collegeInfo?.collegetutionoutsidecost).toLocaleString('en-US', {maximumFractionDigits:2}) : 0}`}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
   lastContactData: state.athlete.dashboard.colleges.lastcontact,
   videoViews : state.common.photoVideoViews
  }
}
const mapDispatchToProps = (dispatch) => {
  return{
    lastContactListStart: data => dispatch(lastContactListStart(data)),
    photoVideoViewsListStart : (data) => dispatch(photoVideoViewsListStart(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivityOverview)

