import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../../utils/messagePopup";
import history from "../../../../../services/history";
import { fetchGraphMethod } from "../../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_VIDEO_DRAFT_START: "GET_VIDEO_DRAFT_START",
  GET_VIDEO_DRAFT_FAIL: "GET_VIDEO_DRAFT_FAIL",
  GET_VIDEO_DRAFT_SUCCESS: "GET_VIDEO_DRAFT_SUCCESS",
};

/* ======================================= Actions Creator ==========================================*/

export const getVideoDraftStart = (data) => ({
  type: actionType.GET_VIDEO_DRAFT_START,
  payload: data,
});

export const getVideoDraftSuccess = (data) => ({
  type: actionType.GET_VIDEO_DRAFT_SUCCESS,
  payload: data,
});

export const getVideoDraftFail = (data) => ({
  type: actionType.GET_VIDEO_DRAFT_FAIL,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function getVideoDraftReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_VIDEO_DRAFT_START:
      return { ...state, loading: true };

    case actionType.GET_VIDEO_DRAFT_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.GET_VIDEO_DRAFT_FAIL:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* getVideoDraftSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );

    if (response.status === 200 && response.data.data) {
      yield put(
        getVideoDraftSuccess(
          response.data.data.allVideoedittingdetails.Videoedittingdetails
        )
      );
    } else {
      messagePopup("", "GET VIDEO ORDER FAILED", "error");
      yield put(getVideoDraftFail("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "GET VIDEO ORDER FAILED", "error");
    yield put(getVideoDraftFail("Failed, Error occured"));
  }
}

export function* watchgetVideoDraftSaga() {
  yield takeEvery(actionType.GET_VIDEO_DRAFT_START, getVideoDraftSaga);
}
