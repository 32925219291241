import { all, fork } from "redux-saga/effects";
import { watchAddMyCollegeSaga } from "./addMyColleges";
import { watchFollowersList } from "./followersList";
import { watchFollowingList } from "./followingsList";
import { watchFollowUnfollowSaga } from "./followUnfollow";
import { watchglobalFeedListSaga } from "./globalFeeds";
import { watchPostLikeUnlike } from "./postLikeUnlike";
import { watchPost } from "./posts";
import { watchSavePost } from "./savePost";
import { watchSuggestedColleges } from "./suggestedColleges";
import { watchtrendingProfileListSaga } from "./trendingProfileList";
import { watchAskAQuestionSaga } from "./askAQuestion";
import { watchSaveProfilePhotoSaga } from "./saveAthleteProfile";
import { watchParentAthleteList } from "./parentAllAthlete";
import { watchSwitchAthleteSaga } from "./switchAthlete";
import { watchAthleteProfileStatus } from './athleteProfileViewState'
export default function* rootHomeSagas() {
  yield all([
    fork(watchPost),
    fork(watchSavePost),
    fork(watchSuggestedColleges),
    fork(watchAddMyCollegeSaga),
    fork(watchFollowersList),
    fork(watchFollowingList),
    fork(watchFollowUnfollowSaga),
    fork(watchtrendingProfileListSaga),
    fork(watchglobalFeedListSaga),
    fork(watchPostLikeUnlike),
    fork(watchAskAQuestionSaga),
    fork(watchSaveProfilePhotoSaga),
    fork(watchParentAthleteList),
    fork(watchSwitchAthleteSaga),
  ]);
}
