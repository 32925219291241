import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../../utils/messagePopup";
import history from "../../../../../services/history";
import { fetchGraphMethod } from "../../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_ALL_MASTER_PLAN: "GET_ALL_MASTER_PLAN",
  MASTER_PLAN_SUCCESS: "MASTER_PLAN_SUCCESS",
  MASTER_PLAN_FAIL: "MASTER_PLAN_FAIL",
};

/* ======================================= Actions Creator ==========================================*/

export const getMasterPlanStart = (data) => ({
  type: actionType.GET_ALL_MASTER_PLAN,
  payload: data,
});

export const getMasterPlanSuccess = (data) => ({
  type: actionType.MASTER_PLAN_SUCCESS,
  payload: data,
});

export const getMasterPlanFail = (data) => ({
  type: actionType.MASTER_PLAN_FAIL,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function getMasterPlanReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_ALL_MASTER_PLAN:
      return { ...state, loading: true };

    case actionType.MASTER_PLAN_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.MASTER_PLAN_FAIL:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* getMasterPlanSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200 && response.data.data.allVideoedittingmasters) {
      const allPlans =
        response.data.data.allVideoedittingmasters.Videoedittingmasters;
      yield put(getMasterPlanSuccess(allPlans));
    } else {
      messagePopup("", "Fetch All Master Plan Failed", "error");
      yield put(getMasterPlanFail("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch All Master Plan Failed", "error");
    yield put(getMasterPlanFail("Failed, Error occured"));
  }
}

export function* watchMasterPlanSaga() {
  yield takeEvery(actionType.GET_ALL_MASTER_PLAN, getMasterPlanSaga);
}
