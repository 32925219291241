import React, {useEffect, useState} from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/owl.carousel.min.js';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import './assets/css/pricing-plan-checkout.scss'
import {getAuthToken, getUserInfo} from "../../../../../../services/authService";
import {fetchGraphMethod, fetchMethod} from "../../../../../../services/httpService";
import SuccessStory5 from  '../../../../landingPage/assets/img/home-success-img-05.png';
import SuccessStoryFooter5 from '../../../../landingPage/assets/img/home-success-footer-img-05.png';
import SuccessStory1 from '../../../../landingPage/assets/img/home-success-img-01.png';
import SuccessStoryFooter1 from '../../../../landingPage/assets/img/Bb_Davidson_Wildcats.png';
import SuccessStory2 from '../../../../landingPage/assets/img/home-success-img-02.png';
import SuccessStoryFooter2 from '../../../../landingPage/assets/img/home-success-footer-img-02.png';
import SuccessStory3 from '../../../../landingPage/assets/img/home-success-img-03.png';
import SuccessStoryFooter3 from '../../../../landingPage/assets/img/home-success-footer-img-03.png';
import SuccessStory4 from '../../../../landingPage/assets/img/home-success-img-04.png';
import SuccessStoryFooter4 from '../../../../landingPage/assets/img/home-success-footer-img-04.png';
import SuccessStory6 from '../../../../landingPage/assets/img/home-success-img-06.png';
import SuccessStoryFooter6 from '../../../../landingPage/assets/img/home-success-footer-img-06.png';
import {getAllAthletesDataQuery, getAllCountriesQuery} from "../../../../../common/CommonQuery";
import {allParentQuery} from "../../editUserProfile/ContactQuery";
import swal from "sweetalert";
import {AplhaNumericInput, IsValidEmail} from "../../../../../../utils/validationUtils";
import toastr from 'toastr'
import {Link} from 'react-router-dom'
import BackdropLoader from "../../../../../common/Loader";
const successStoriesSlide = {
    loop:true,
    autoplay:true,
    autoHeight: true,
    margin:10,
    nav:true,
    dots:false,
    autoplayTimeout:2000,
    autoplayHoverPause:true,
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 2
        },
        992: {
            items: 2.4,
            margin: 30,
        }
    }
};
export const PricingCheckout = (props) =>{
    const [planDetail, setPlanDetail] = useState(null),
          [field, setField] = useState({}),
          [gradeYr, setGradeYr] = useState([]),
          [allCountryList, setAllCountryList] = useState([]),
          [error, setError] = useState({}),
          [isLoading, setIsLoading] = useState(false),
          priceID = props?.match?.params?.priceID ?? "ERR",
          promoValue = localStorage.getItem('promoCode');
    const dollarUSLocale = Intl.NumberFormat('en-US');
    const dollarUSLocaleDec = Intl.NumberFormat('en-US',{maximumFractionDigits:2, minimumFractionDigits:2})
    useEffect(()=>{
        getAllCountry()
        getAthleteData()
        getGradeYear()
        getPlanDetail()
        setTimeout(()=>{
            window.scrollTo(0,0)
        },800)
    },[])
    const getPlanDetail = async () =>{
        setIsLoading(true)
      const _resPriceID = await fetchMethod(`/api/Payments/getPlanById?priceId=${priceID}&promoCode=${!!promoValue
      ? promoValue : ""}`,null,'GET',true)
        if (_resPriceID?.status == 200){
            setPlanDetail(_resPriceID?.data?.response?.data)
            setIsLoading(false)
        }else{
            setPlanDetail(null)
            setIsLoading(false)
        }
    }
    const getAllCountry = async () =>{
        const _resCoun = await fetchMethod('/allCountry',null,"GET",false)
        if (_resCoun?.status == 200){
            const countryData = _resCoun?.data?.data ?? []
            setAllCountryList(countryData)
        }else{
            setAllCountryList([])
        }
    }
    const getAthleteData = async () =>{
            let cloneState = field
        const _resAth = await fetchGraphMethod(getAllAthletesDataQuery,
            {
            obj: {
                id: getUserInfo() ? getUserInfo().id : 0,
            },
        },true)
        if (_resAth?.status == 200){
            const _resPath = await fetchGraphMethod(allParentQuery,{
                obj: {
                    athleteid: getUserInfo() ? getUserInfo().id : 0,
                    isaccessallowed: 1,
                    active: 1
                },
            }, true)
            if (_resPath?.status == 200){
                cloneState['athleteFirstName'] = _resAth?.data?.data?.allAthletes?.Athletes?.[0]?.athletefirstname
                cloneState['athletelastname'] = _resAth?.data?.data?.allAthletes?.Athletes?.[0]?.athletelastname
                cloneState['emailID'] = _resAth?.data?.data?.allAthletes?.Athletes?.[0]?.athleteemailid
                cloneState['gradeYear'] = getUserInfo()?.graduationyearValue
                cloneState['state'] = _resAth?.data?.data?.allAthletes?.Athletes?.[0]?.athletestateidmaprel?.States?.[0]?.statename
                cloneState['countryID'] = _resAth?.data?.data?.allAthletes?.Athletes?.[0]?.homecountryid
                cloneState['gradeYearID'] = _resAth?.data?.data?.allAthletes?.Athletes?.[0]?.athletegraduationyear
                cloneState['city'] = _resAth?.data?.data?.allAthletes?.Athletes?.[0]?.city
                cloneState['zipcode'] = _resAth?.data?.data?.allAthletes?.Athletes?.[0]?.athletezipcode
                cloneState['pgEmailID'] = _resPath?.data?.data?.allAthleteparentmappings?.Athleteparentmappings?.[0]?.
                    athleteparentmappingmappingparentidmaprel?.Athleteparents?.[0]?.parentemail ?? ""
                cloneState['sportsId'] = _resAth?.data?.data?.allAthletes?.Athletes?.[0]?.athletesports
                cloneState['address'] = _resAth?.data?.data?.allAthletes?.Athletes?.[0]?.address ?? ""
                cloneState['tcStatus'] = false
                setField({...cloneState})
            }
        }
    }
    const getGradeYear = async () =>{
        const _res = await fetchMethod('/allGraduationYears',null,'GET')
        if (_res?.status == 200){
            const _data = _res?.data?.data
            setGradeYr(_data)
        }else{
            setGradeYr([])
        }
    }
    const onHandleChnage = (e) =>{
        const cloneFiled = field
        cloneFiled[e?.target?.name] = e?.target?.name == "tcStatus" ? e?.target?.checked : e?.target?.value
        setField({...cloneFiled})
        setError({})
    }
    const regValidation = () =>{
        let cloneState = field,
              errorT = error,
              status = true
        if (!cloneState?.athleteFirstName){
            errorT['athleteFirstName'] = 'please enter first name'
            status = false
            setError({...errorT})
            return status
        }
        if (!cloneState?.athletelastname){
            errorT['athletelastname'] = 'please enter last name'
            status = false
            setError({...errorT})
            return status
        }
        if ('emailID' in cloneState){
            if (!!cloneState?.emailID){
                if (!IsValidEmail(cloneState?.emailID)){
                    errorT['emailID'] = 'please enter valid email id '
                    status = false
                    setError({...errorT})
                    return status
                }
            }else{
                errorT['emailID'] = 'please enter email id '
                status = false
                setError({...errorT})
                return status
            }
        }
        if ('pgEmailID' in cloneState){
            if (!!cloneState?.pgEmailID){
                if (!IsValidEmail(cloneState?.pgEmailID)){
                    errorT['pgEmailID'] = 'please enter valid email id '
                    status = false
                    setError({...errorT})
                    return status
                }
            }else{
                errorT['pgEmailID'] = 'please enter email id '
                status = false
                setError({...errorT})
                return status
            }
        }
        if (!cloneState?.state){
            errorT['state'] = 'please enter state'
            status = false
            setError({...errorT})
            return status
        }
        if (!cloneState?.city){
            errorT['city'] = 'please enter city'
            status = false
            setError({...errorT})
            return status
        }
        if (!cloneState?.countryID){
            errorT['countryID'] = 'please select country'
            status = false
            setError({...errorT})
            return status
        }
        if ('zipcode' in cloneState){
            if (!!cloneState?.zipcode){
                if (cloneState?.zipcode?.length > 2 && cloneState?.zipcode?.length < 13){
                    if (!AplhaNumericInput(cloneState?.zipcode)){
                        errorT['zipcode'] = 'please enter valid zipcode'
                        status = false
                        setError({...errorT})
                        return status
                    }
                }else{
                    errorT['zipcode'] = 'please enter valid zipcode'
                    status = false
                    setError({...errorT})
                    return status
                }
            } else{
                    errorT['zipcode'] = 'please enter zipcode'
                    status = false
                    setError({...errorT})
                    return status
            }
        }
        if (!cloneState?.countryID){
            errorT['countryID'] = 'please enter email id'
            status = false
            setError({...errorT})
            return status
        }
        return status
    }
    const onSubmitReg = async () =>{
        const validate = regValidation()
        if (validate){
            if (!!priceID){
                setIsLoading(true)
                const body = {
                    "access_token": getAuthToken(),
                    "priceId": priceID,
                    "athleteId": getUserInfo()?.id,
                    "athleteFirstName": field?.athleteFirstName,
                    "athleteLastName": field?.athletelastname,
                    "athleteEmail": field?.emailID,
                    "parentEmail": field?.pgEmailID,
                    "sport": field?.sportsId,
                    "gradYear": field?.gradeYearID,
                    "address": field?.address ?? "",
                    "city": field?.city,
                    "state": field?.state,
                    "country": +field?.countryID,
                    "zipcode": field?.zipcode ?? "",
                    "promoCode": !!promoValue ? promoValue : ""
                }
                const _resSub = await fetchMethod('/api/Payments/generateStripeSessionWithAthleteInfo',
                    body,'POST',!!getAuthToken() ? true : false)
                if (_resSub?.status == 200){
                    const stripeURL = _resSub?.data?.response?.data?.url ?? ''
                    if (!!stripeURL){
                        setIsLoading(false)
                        window.open(stripeURL, '_self')
                    }else{
                        setIsLoading(false)
                        toastr.error('Failed To Redirect To Stripe')
                    }
                }else{
                    toastr.error('Something Wrong')
                    setIsLoading(false)
                }
            }else{
                swal({text:"Please Select The Plan", icon:"error"})
            }
        }
    }
    const getValueDec = (value) =>{
        const valueSplit = value?.toString()?.includes(".")
        if (valueSplit){
            return dollarUSLocaleDec.format(value)
        }else{
            return dollarUSLocale.format(value)
        }
    }
return(
    <>
        <BackdropLoader open={isLoading}/>
        <section className="plan-checkout-sec">
            <div className="container">
                <div className="plan-checkout-row">
                    <h1 className="title">Membership Summary</h1>
                    <div className="plan-checkout-card plan-game-card">
                        <h4 className="title">{planDetail?.planName ?? ""}</h4>
                        <div className="planInfo-flexbox">
                            <ul className="checklist">
                                {planDetail?.features?.length > 0 &&
                                planDetail?.features?.map(ft => <li>{ft}</li>)
                                }
                            </ul>
                            <div className="plan-typeInfo">
                                <div className="plan-monthly-type">
                                    <span className="plan-title">{planDetail?.months == 0 ? 1 : planDetail?.months}</span>
                                    {planDetail?.recurring ? 'Month Payment Plan' : 'Time Payment Plan'}
                                </div>
                                <div className="plan-info">
                                    <div className="price">${getValueDec(planDetail?.totalAmount ?? 0)}</div>
                                    {planDetail?.recurring ?
                                    <div className="plan-total-price">
                                        ${getValueDec(planDetail?.prices?.[0]?.priceInDollarsString ?? 0)}<span> /month</span></div>
                                        :
                                        planDetail?.months != 0 && <div className="plan-total-price"><span> /month</span></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plan-checkout-row">
                    <h1 className="title">Registration</h1>
                    <div className="plan-checkout-card">
                        <form className="form">
                            <div className="fieldBox flexBox">
                                <div className="column">
                                    <label>Athlete First Name <sup>*</sup></label>
                                    <input value={field?.athleteFirstName} name={'athleteFirstName'}
                                           onChange={(e)=>onHandleChnage(e)}
                                           type="text" placeholder="Enter athlete first name"/>
                                    {error?.athleteFirstName && <div className={'error'}>{error?.athleteFirstName ?? "ER"}</div>}

                                </div>
                                <div className="column">
                                    <label>Athlete Last Name <sup>*</sup></label>
                                    <input value={field?.athletelastname}
                                           onChange={(e)=>onHandleChnage(e)}
                                           name={'athletelastname'} type="text" placeholder="Enter athlete last name"/>
                                    {error?.athletelastname && <div className={'error'}>{error?.athletelastname ?? "ER"}</div>}
                                </div>
                                <div className="column">
                                    <label>Athlete Email ID <sup>*</sup></label>
                                    <input value={field?.emailID} name={'emailID'}
                                           onChange={(e)=>onHandleChnage(e)}
                                           type="email" placeholder="Enter athlete email"/>
                                    {error?.emailID && <div className={'error'}>{error?.emailID ?? "ER"}</div>}
                                </div>
                                <div className="column">
                                    <label>Parent / Guardian Email ID <sup>*</sup></label>
                                    <input value={field?.pgEmailID} name={'pgEmailID'}
                                           onChange={(e)=>onHandleChnage(e)}
                                           type="email" placeholder="Enter parent / guardian email"/>
                                    {error?.pgEmailID && <div className={'error'}>{error?.pgEmailID ?? "ER"}</div>}
                                </div>
                            </div>
                            <div className="fieldBox flexBox">
                                <div className="column">
                                    <label>Sport <sup>*</sup></label>
                                    <select disabled={true} style={{opacity:"0.6"}}>
                                        <option value="1">Baseball</option>
                                    </select>
                                </div>
                                <div className="column">
                                    <label>Grad Year <sup>*</sup></label>
                                    <input value={field?.gradeYear} disabled={true} style={{background:"#ffff",opacity:"0.6"}} type="text" placeholder="Enter grad year"/>
                                </div>
                                <div className="column">
                                    <label>Address</label>
                                    <input value={field?.address} name={'address'}
                                           onChange={(e)=>onHandleChnage(e)}
                                           type="text" placeholder="Enter address"/>
                                    {error?.address && <div className={'error'}>{error?.address ?? "ER"}</div>}
                                </div>
                                <div className="column">
                                    <label>City <sup>*</sup></label>
                                    <input value={field?.city} name={'city'}
                                           onChange={(e)=>onHandleChnage(e)}
                                           type="text" placeholder="Enter city"/>
                                    {error?.city && <div className={'error'}>{error?.city ?? "ER"}</div>}
                                </div>
                            </div>
                            <div className="fieldBox flexBox">
                                <div className="column">
                                    <label>State / Province <sup>*</sup></label>
                                    <input value={field?.state} name={'state'}
                                           onChange={(e)=>onHandleChnage(e)}
                                           type="text" placeholder="Enter state / province"/>
                                    {error?.state && <div className={'error'}>{error?.state ?? "ER"}</div>}
                                </div>
                                <div className="column">
                                    <label>Country <sup>*</sup></label>
                                    <select value={field?.countryID} name={'countryID'}
                                            onChange={(e)=>onHandleChnage(e)}
                                    >
                                        <option value={""}>Select</option>
                                        {allCountryList?.length > 0 && allCountryList?.map(ct =><option value={ct?.id}>{ct?.countryName ?? ""}</option>)}
                                    </select>
                                    {error?.countryID && <div className={'error'}>{error?.countryID ?? "ER"}</div>}
                                </div>
                                <div className="column">
                                    <label>Zip Code <sup>*</sup></label>
                                    <input value={field?.zipcode ?? ""} name={'zipcode'}
                                           onChange={(e)=>onHandleChnage(e)}
                                           type="text" placeholder="Enter zip code"/>
                                    {error?.zipcode && <div className={'error'}>{error?.zipcode ?? "ER"}</div>}
                                </div>
                            </div>
                            <div className="fieldBox">
                                <ul className="checkbox-list">
                                    <li>
                                        <span class="checkbox-sf">
                                           <input checked={field?.tcStatus} type="checkbox"
                                                  name={'tcStatus'}
                                                  onChange={(e)=>onHandleChnage(e)}
                                           />
                                            <label>&nbsp;</label>
                                        </span>
                                        I reviewed the payment structure, &nbsp;<Link
                                        to={'/termsCancellationPolicy'}
                                    target={'_blank'}
                                    > terms of use and cancellation policy</Link>&nbsp; for my plan and consent to its terms.
                                    </li>

                                </ul>
                            </div>
                            <div className="fieldBox">
                                <button className="btn blue"
                                type={'button'}
                                        disabled={!field?.tcStatus}
                                        onClick={()=>onSubmitReg()}
                                >Yes, I accept</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="checkout-testimonial">
                    <div className="sec-title">Testimonials</div>
                    <OwlCarousel className="checkout-testimonial-list checkout-testimonial-slide owl-carousel owl-theme"
                                 {...successStoriesSlide}
                    >
                        <div className="item">
                            <div className="success-info-box">
                                <div className="pick-box">
                                    <img className="pick-img" src={SuccessStory5} alt="Matt McNaney - Trinity"/>
                                <span className="icon-box">
                                    <img src={SuccessStoryFooter5} alt="Matt McNaney - Trinity"/>
                                </span>
                                </div>
                                <div className="detail-info-box">
                                    <h4 className="name">Matt McNaney - Trinity</h4>
                                    <p>SportsForce was so helpful tracking which schools were answering and watching my
                                        video</p>
                                    <p className="star-rating-icons">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="success-info-box">
                                <div className="pick-box">
                                    <img className="pick-img" src={SuccessStory1}
                                         alt="Patrick Herrera - Northwestern"/>
                                <span className="icon-box">
                                    <img src={SuccessStoryFooter1} alt="Patrick Herrera - Northwestern"/>
                                </span>
                                </div>
                                <div className="detail-info-box">
                                    <h4 className="name">Patrick Herrera - Northwestern</h4>
                                    <p>SportsForce got my name out there and helped me eventually find my perfect
                                        school</p>
                                    <p className="star-rating-icons">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="success-info-box">
                                <div className="pick-box">
                                    <img className="pick-img" src={SuccessStory2} alt="Jake Dunagan - Davidson"/>
                                <span className="icon-box">
                                    <img src={SuccessStoryFooter2} alt="Jake Dunagan - Davidson"/>
                                </span>
                                </div>
                                <div className="detail-info-box">
                                    <h4 className="name">Jake Dunagan - Davidson</h4>
                                    <p>Jake received recruiting active interest from more than 8 different division 1
                                        programs</p>
                                    <p className="star-rating-icons">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="success-info-box">
                                <div className="pick-box">
                                    <img className="pick-img" src={SuccessStory3} alt="Jamir Gibson - Grambling"/>
                                <span className="icon-box">
                                    <img src={SuccessStoryFooter3} alt="Jamir Gibson - Grambling"/>
                                </span>
                                </div>
                                <div className="detail-info-box">
                                    <h4 className="name">Jamir Gibson - Grambling</h4>
                                    <p>If you’re serious about college baseball SportsForce is who you want on your
                                        side!!!</p>
                                    <p className="star-rating-icons">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="success-info-box">
                                <div className="pick-box">
                                    <img className="pick-img" src={SuccessStory4} alt="Ethan Ezor - Colorado Mesa"/>
                                <span className="icon-box">
                                    <img src={SuccessStoryFooter4} alt="Ethan Ezor - Colorado Mesa"/>
                                </span>
                                </div>
                                <div className="detail-info-box">
                                    <h4 className="name">Ethan Ezor - Colorado Mesa</h4>
                                    <p>SportsForce helped me get on the map with various schools and get my name out
                                        there </p>
                                    <p className="star-rating-icons">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="success-info-box">
                                <div className="pick-box">
                                    <img className="pick-img" src={SuccessStory6}
                                         alt="Vincent Venverloh - USD"/>
                                <span className="icon-box">
                                    <img src={SuccessStoryFooter6} alt="Vincent Venverloh - USD"/>
                                </span>
                                </div>
                                <div className="detail-info-box">
                                    <h4 className="name">Vincent Venverloh - USD</h4>
                                    <p>The coaches helping are very knowledgeable, have many useful college
                                        connections</p>
                                    <p className="star-rating-icons">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.658" height="16.923"
                                             viewBox="0 0 17.658 16.923">
                                            <path id="__TEMP__SVG__"
                                                  d="M18.928,10.107a1.188,1.188,0,0,0-.687-2.14l-4.794-.182a.123.123,0,0,1-.107-.08L11.683,3.212a1.181,1.181,0,0,0-2.221,0L7.811,7.722A.123.123,0,0,1,7.7,7.8L2.91,7.984a1.188,1.188,0,0,0-.687,2.14l3.761,2.969a.123.123,0,0,1,.043.128L4.731,17.827a1.184,1.184,0,0,0,1.8,1.311l3.974-2.675a.117.117,0,0,1,.133,0l3.974,2.675a1.169,1.169,0,0,0,1.8-1.284l-1.305-4.622a.118.118,0,0,1,.043-.128Z"
                                                  transform="translate(-1.746 -2.432)" fill="#ffce30"></path>
                                        </svg>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </section>
    </>
)
}