import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SCHOOL_AND_TEAM_LIST_START: "SCHOOL_AND_TEAM_LIST_START",
  SCHOOL_AND_TEAM_LIST_SUCCESS: "SCHOOL_AND_TEAM_LIST_SUCCESS",
  SCHOOL_AND_TEAM_LIST_FAILED: "SCHOOL_AND_TEAM_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const schoolAndTeamListStart = (data) => ({
  type: actionType.SCHOOL_AND_TEAM_LIST_START,
  payload: data,
});

export const schoolAndTeamListSuccess = (data) => ({
  type: actionType.SCHOOL_AND_TEAM_LIST_SUCCESS,
  payload: data,
});

export const schoolAndTeamListFailed = (data) => ({
  type: actionType.SCHOOL_AND_TEAM_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function schoolAndTeamListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SCHOOL_AND_TEAM_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SCHOOL_AND_TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SCHOOL_AND_TEAM_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* schoolAndTeamListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        schoolAndTeamListSuccess({
          schoolAndTeams: response.data.data.allSchoolteamtypes.Schoolteamtypes,
        })
      );
    } else {
      messagePopup("", "School Type List failed", "error");
      yield put(schoolAndTeamListFailed("School Type List failed"));
    }
  } catch (error) {
    messagePopup("", "School Type List failed", "error");
    yield put(schoolAndTeamListFailed("School Type List failed"));
  }
}

//Watcher
export function* watchSchoolAndTeamListSaga() {
  yield takeEvery(actionType.SCHOOL_AND_TEAM_LIST_START, schoolAndTeamListSaga);
}
