import { all, fork } from "redux-saga/effects";
import { watchSportsListSaga } from "./allSports";
import { watchStateListSaga } from "./allStates";
import { watchCountryListSaga } from "./countriesList";
import { watchFGraduationMonthsListSaga } from "./graduationMonth";
import { watchFGraduationYearsListSaga } from "./graduationYears";
import { watchRelationshipListSaga } from "./relationships";
import { watchUserListSaga } from "./userData";
import { watchAllSportsListSaga } from "./allSportWithOutUsingGender";
import { watchProfileSectionListSaga } from "./profileSection";
import { watchCommitmentStatusListSaga } from "./commitmentStatus";
import { watchAllCollegeDetailsListSaga } from "./allCollegeData";
import { watchAnnualHouseholdIncomeListSaga } from "./allAnnualhouseholdincomes";
import { watchRacialEthnicHeritageListSaga } from "./allRacialethnicheritages";
import { watchSatMathListSaga } from "./satMathList";
import { watchSatReadingAndWritingListSaga } from "./satReadingAndWritingList";
import { watchPsatMathListSaga } from "./psatMathList";
import { watchPsatReadingAndWritingListSaga } from "./psatReadingAndWritingList";
import { watchActCompositeScoresListSaga } from "./actCompositeScores";
import { watchAcademicNotesListSaga } from "./allAcademicNotes";
import { watchReportCardListSaga } from "./reportCard";
import { watchApIbClassesTimePeroidListSaga } from "./apIbTimePeriod";
import { watchOfferListSaga } from "./offerList";
import { watchInterestLevelListSaga } from "./interestLevelList";
import { watchOrganizationsListSaga } from "./allOrganizationsList";
import { watchCategoriesListSaga } from "./allCatergoriesList";
import { watchAthletesDetailsSaga } from "./athletesDetails";
import { watchAllCheckListDataSaga } from "./allCheckListData";
import { watchAllProfileCalculationListSaga } from "./allProfileCalculationMaster";
import { watchSaveCreatePaymentSaga } from "./savePayments";
import { watchCountryDependentListSaga } from './countryDependentList';
import { watchAllAthletesSaga } from "./allAthleteData";
import { watchPhotosVideosViewsListSaga } from "./allPhotoVideoViews";
import { watchRegionsListSaga } from "./allRegions";
import { watchAllCollegeConferencesSaga } from "./allCollegeConferences";
import { watchLandingPageSagaSaga } from "./landingPage";

export default function* rootCommonSagas() {
  yield all([
    fork(watchCountryListSaga),
    fork(watchFGraduationYearsListSaga),
    fork(watchSportsListSaga),
    fork(watchRelationshipListSaga),
    fork(watchFGraduationMonthsListSaga),
    fork(watchUserListSaga),
    fork(watchStateListSaga),
    fork(watchStateListSaga),
    fork(watchUserListSaga),
    fork(watchAllSportsListSaga),
    fork(watchProfileSectionListSaga),
    fork(watchCommitmentStatusListSaga),
    fork(watchAllCollegeDetailsListSaga),
    fork(watchAnnualHouseholdIncomeListSaga),
    fork(watchRacialEthnicHeritageListSaga),
    fork(watchSatMathListSaga),
    fork(watchSatReadingAndWritingListSaga),
    fork(watchPsatMathListSaga),
    fork(watchPsatReadingAndWritingListSaga),
    fork(watchActCompositeScoresListSaga),
    fork(watchAcademicNotesListSaga),
    fork(watchReportCardListSaga),
    fork(watchApIbClassesTimePeroidListSaga),
    fork(watchOfferListSaga),
    fork(watchInterestLevelListSaga),
    fork(watchOrganizationsListSaga),
    fork(watchCategoriesListSaga),
    fork(watchAthletesDetailsSaga),
    fork(watchAllCheckListDataSaga),
    fork(watchAllProfileCalculationListSaga),
    fork(watchSaveCreatePaymentSaga),
    fork(watchCountryDependentListSaga),
    fork(watchAllAthletesSaga),
    fork(watchPhotosVideosViewsListSaga),
    fork(watchRegionsListSaga),
    fork(watchAllCollegeConferencesSaga),
    fork(watchLandingPageSagaSaga)
  ]);
}
