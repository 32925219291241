import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardSchoolInfo.scss";
import "../assets/css/onBoardSteeper.scss";
import { connect } from "react-redux";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { schoolCurrentLevelListStart } from "../../../../../redux/athlete/onBoarding/schoolCurrentLevel";
import BackdropLoader from "../../../../common/Loader";
import { getOnboardingQuery, saveOnboardingQuery } from "../OnboardingQuery";
import {
  getAllSchoolCurrentLevelQuery,
  saveUserSchool,
} from "./SchoolInformationQuery";
import { getUserInfo } from "../../../../../services/authService";
import Dialog from "@mui/material/Dialog";
import ParentSkip from "../SkitPopup/ParentSkip";
import "../SkitPopup/SkipScreen.scss";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import usePrevious from "../../../../../utils/usePrevious";
import { isValidZipcode } from "../../../../../utils/validationUtils";
import Stepper from "../Stepper";
import {
  getAllAthleteMoreSchoolQuery,
  getAllAthleteMoreSchoolQuery1,
  getSchoolNameQuery,
  saveAllAthleteMoreSchoolQuery,
} from "../../dashboard/editUserProfile/AcademicsQuery";
import Autocomplete from '@mui/material/Autocomplete';
import { allSchoolListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/allHighSchool";
import MuiAutoComplete from "../../../../common/AutoComplete";
import disableNotInHighSchool from "../../../../../utils/disabledNotInHighSchool";
import {
  userAcademicSchoolSave,
  userAcademicSchoolStart,
} from "../../../../../redux/athlete/dashboard/editUserProfile/academicSchool";
import { fetchGraphMethod } from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "passed", link: "/onboarding/positions" },
  { className: "passed", link: "/onboarding/physicalMeasurements" },
  { className: "passed", link: "/onboarding/personalRecords" },
  { className: "passed", link: "/onboarding/firstYearVarsity" },
  { className: "active", link: "/onboarding/schoolInfo" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  // { className: "" },
  // { className: "" },
];

function SchoolInformation(props) {
  const [fields, setFields] = useState({
    user: getUserInfo() ? getUserInfo() : 0,
  });
  const [errors, setErrors] = React.useState({});

  const [schoolArray, setSchoolArray] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [filteredCurrectSchoolLevel, setFilteredCurrectSchoolLevel] =
    React.useState(null);

  const prevOnboardData = usePrevious(props.onboarding.data);
  const [isLoading, setIsLoading] = useState(false)
  const handleClickOpen = () => {
    let getHighYear = disableNotInHighSchool(
      getUserInfo()?.graduationyearValue ?? 2022
    );
    const finalObj = {
      obj: {
        userid: fields.user.id,
        schoolzipcode: fields.zipCode ? fields.zipCode : null,
        highschoolname: fields.highschoolname ? fields.highschoolname : null,
        highscoolstatus: getHighYear ? 1 : 0,
        currentlevelofschool: fields.notInHighSchool
          ? fields.schoolCurrentLevel
            ? +fields.schoolCurrentLevel
            : null
          : null,
      },
    };
    if (!!fields.id) finalObj["obj"]["id"] = fields.id;
    const res = fetchGraphMethod(saveOnboardingQuery, finalObj, true);
    if (res?.status == 200) {
      console.log("save on boarding skip success");
    } else {
      console.log("save on boarding skip failed");
    }

    setOpen(true);
  };
  const routeNextDummyRoute = () => {
    // console.log("on boarding save");
  };

  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    props.history.push("/onboarding/academics");
    setIsLoading(false)
  };

  useEffect(() => {
    setFields({ user: getUserInfo() ? getUserInfo() : 0 });
    props.schoolCurrentLevelListStart({
      query: getAllSchoolCurrentLevelQuery,
      variables: null,
    });
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: fields.user.id,
        },
      },
    });
    getUserSchoolList();
    // props.schoolListStart({
    //   query: getSchoolName,
    // });
  }, []);

  const getUserSchoolList = () => {
    props.userAcademicSchoolListStart({
      // getAllUserSchool
      query: getAllAthleteMoreSchoolQuery1,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : null,
          active: 1,
          // isuserschool: "1"
        },
      },
      isAcademic: true,
    });
  };

  useEffect(() => {
    if (props.allUserAcademicSchoolList.data && props.onboarding.data) {
      const schoolDataArr = props.allUserAcademicSchoolList.data
        .allUserAcademicSchool
        ? props.allUserAcademicSchoolList.data.allUserAcademicSchool
          .Athletemoreschools
        : null;
      const fieldT = fields;
      const { highschoolname } = props.onboarding.data;
      if (schoolDataArr && schoolDataArr.length && highschoolname) {
        const finalSchool = schoolDataArr.find(
          (item) => item.highschoolname === highschoolname
        );
        if (finalSchool) {
          fieldT["existedSchoolId"] = finalSchool.id;
          fieldT['schoolid'] = finalSchool?.schoolid ?? 0 //$
          setFields({ ...fieldT });
        }
      }
    }
  }, [props.allUserAcademicSchoolList.data, props.onboarding.data]);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = fields;
        const {
          id,
          schoolzipcode,
          highschoolname,
          highscoolstatus,
          currentlevelofschool,
        } = props.onboarding.data;
        fieldT["id"] = id;
        fieldT["zipCode"] = schoolzipcode
          ? schoolzipcode
          : getUserInfo()
            ? getUserInfo().ZipCode
            : "";
        fieldT["highschoolname"] = highschoolname;
        fieldT["notInHighSchool"] = highscoolstatus ? true : false;
        fieldT["schoolCurrentLevel"] = currentlevelofschool;
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;

    //gzipcode validations
    // if (fieldname === undefined || fieldname === "zipCode") {
    //   if (fieldname === undefined) {
    //     fieldValue = fields["zipCode"] ? fields["zipCode"] : "";
    //     updatedValue = fieldValue;
    //   }
    //   delete errorsT["zipCode"];
    //   if (!isValidZipcode(updatedValue, fields.user.countryShortName)) {
    //     formIsValid = false;
    //     errorsT["zipCode"] = "Please enter valid zipcode";
    //   }
    // }

    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  useEffect(() => {
    if (props.schoolCurrentLevel.data) {
      getCurrentLevelListStart();
    }
  }, [props.schoolCurrentLevel.data]);

  const getCurrentLevelListStart = () => {
    const allData = props.schoolCurrentLevel.data
      ? props.schoolCurrentLevel.data
      : [];
    const filteredHighData = allData.filter((item) => item.ishighschool === 1);
    const filteredNotHighSchool = allData.filter(
      (item) => item.ishighschool === 0
    );

    const tempFinalFilteredData =
      disableNotInHighSchool(fields.user.graduationyearValue) === true
        ? filteredNotHighSchool
        : filteredHighData;
    setFilteredCurrectSchoolLevel(tempFinalFilteredData);
  };

  const handleChange = (field, e) => {
    const { formIsValid, updatedValue } = handleValidation(field, e);
    let fieldsT = fields;
    fieldsT[field] = updatedValue;
    if (fields === "notInHighSchool") {
      if (!e.target.value) {
        fieldsT["schoolCurrentLevel"] = "";
      }
    }
    setFields({
      ...fieldsT,
    });
  };
const getHighStatus = (value) =>{//$
  const getStatus =   disableNotInHighSchool(fields.user.graduationyearValue)
  if (getStatus){
    return 1
  }else{
    return 0
  }
}
  const handleSubmit = async () => {
    const { formIsValid, updatedValue } = handleValidation();
    if (formIsValid) {
      //this func is used for storing data in athelete school table and it will show in edit profile academic
      // props?.userAcademicSchoolSave({
      //   query: saveUserSchool,
      //   variables: {
      //     obj: {
      //       userid: getUserInfo() ? getUserInfo().id : null,
      //       active: 1,
      //       isuserschool: 1,
      //       highschoolname: fields?.highschoolname ?? "",
      //       // zipcode: !!fields.zipCode ? fields.zipCode : ""
      //     },
      //   },
      // })
      setIsLoading(true)
      if (fields?.highschoolname) {
        const finalObj = {
          obj: {
            id: fields.existedSchoolId ? fields.existedSchoolId : 0,
            active: fields?.highschoolname ? 1 : 0,
            userid: getUserInfo() ? getUserInfo().id : null,
            highschoolname: fields?.highschoolname ?? null,
            zipcode: fields?.zipCode ?? null,
            schoolcurrentleveloption: +fields?.schoolCurrentLevel ?? null,
            currentltnotinhighschool: getHighStatus(fields.notInHighSchool),//$
                // !!fields.notInHighSchool ? 0 : 1,//$
            schoolid: fields?.schoolid ?? 0,
          },
        };
        //this query added as on academic allAthletemoreSchool quey is for listing
        const res = await fetchGraphMethod(
          saveAllAthleteMoreSchoolQuery,
          finalObj,
          true
        );
        if (res?.status == 200) {
          props.saveOnboardingStart({
            query: saveOnboardingQuery,
            variables: {
              obj: {
                id: fields.id ? fields.id : 0,
                userid: fields.user.id,
                schoolzipcode: fields.zipCode ? fields.zipCode : null,
                highschoolname: fields.highschoolname
                  ? fields.highschoolname
                  : null,
                highscoolstatus: getHighStatus(fields.notInHighSchool),
                currentlevelofschool: fields.schoolCurrentLevel
                  ? +fields.schoolCurrentLevel
                  : null,
              },
            },
            nextScreen: routeNextPage,
          });
        } else {
          messagePopup("", "School Save Failed", "error");
          setIsLoading(false)
        }
      } else {
        messagePopup("", "Please enter school name.", "error");
        setIsLoading(false)
      }
    }
  };
  const handleObjChange = (field, e) => {
    let fieldsT = fields;
    fieldsT[field] = e
      ? e.target
        ? e.target.value
          ? e.target.value[field]
            ? e.target.value[field]
            : null
          : null
        : null
      : null;
    fieldsT["schoolid"] = e?.target?.value?.id ?? 0;
    console.log(e.target.value, field, fieldsT)
    setFields({
      ...fieldsT,
    });
  };
  const handleSchoolChange = async (field, e) => {
    const variables = {
      where: {
        active: 1,
        and: [
          {
            or: [
              { highschoolname: { like: `%${e.target.value}%` } },
            ],
          },
        ],
      },
    };
    if (e.target.value.toString().length > 2) {
      let res = await fetchGraphMethod(
        getSchoolNameQuery,
        variables,
        true
      );
      if (res?.status == 200) {
        let distinctSchoolNames = [];
        let schoolArrayT = res?.data?.data?.allAthleteschools?.Athleteschools?.length > 0 ?
          res?.data?.data?.allAthleteschools?.Athleteschools.filter((school) => {
            if (school.highschoolname) {
              if (!distinctSchoolNames.includes(school.highschoolname)) {
                distinctSchoolNames.push(school.highschoolname);
                school['highschoolname'] = `${school.highschoolname} - [${!!school?.city ? school?.city?.charAt(0)?.toUpperCase() + school?.city?.slice(1)?.toLowerCase() : ""}, ${!!school.state ? school?.state?.charAt(0)?.toUpperCase() + school?.state?.slice(1)?.toLowerCase() : ""}]`//this key is updated
                // as we there are several clg with same name
                return school;
              }
            }
          }) : []
        setSchoolArray(schoolArrayT);
      }
      else {
        setSchoolArray([]);
      }
    }
  };

  return (
    <div>
      <BackdropLoader
        open={props.schoolCurrentLevel.loading || props.onboarding.loading || isLoading}
      />
      <div className="onBoardSchoolInfoSection">
        <div className="schoolInfoBlock">
          <div className="stepper">
            <Stepper
              stepperName="schoolInformation"
              stepperData={stepperData}
            />
          </div>
          <div className="formSection">
            <h1 className="heading">School Information</h1>
            {/* <div className="fieldBox">
              <label>What's your zip code?</label>
              <TextField
                variant="outlined"
                fullWidth
                id="text_input_A512C40B04644B41AFCB74EDAE27430B"
                type="number"
                value={fields.zipCode || ""}
                onChange={(e) => handleChange("zipCode", e)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: false,
                }}
              />
              <div style={{ color: "red" }}>{errors["zipCode"]}</div>
            </div> */}
            <div className="fieldBox">
              <label>High School Name</label>

              <div className="autocomplete-class">
                <Autocomplete
                  id="combo-box-demo"
                  freeSolo={true}
                  clearOnBlur={true}
                  options={schoolArray}
                  onChange={(e, data) => {
                    handleObjChange('highschoolname',
                      { target: { value: data ? data : ""} }
                    );
                  }}
                  value={fields.highschoolname || null}
                  getOptionLabel={(option) =>
                    option.highschoolname ? option.highschoolname : option
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={fields.highschoolname || null}
                      onChange={(e) => {
                        handleSchoolChange("highschoolname", {
                          target: { value: e.target.value ? e.target.value : "" },
                        });
                        // handleObjChange("highschoolname", {
                        //   target: {
                        //     value: e.target.value ? e.target.value : "",
                        //   },
                        // });
                      }}
                    />
                  )}
                />
                {/* <MuiAutoComplete
                  id="free-solo-demo"
                  clearOnBlur={true}
                  freeSolo={true}
                  options={schoolArray}
                  getOptionLabel={(option) =>
                    option.highschoolname ? option.highschoolname : option
                  }
                  value={fields.highschoolname || null}
                  onChangeObj={(event, newValue) => {
                    handleSchoolChange("highschoolname", {
                      target: { value: newValue },
                    });
                  }}
                /> */}
              </div>
            </div>
            <div className="checkbox">
              <span>
                <input
                  name="notInHighSchool"
                  type="checkbox"
                  // checked={fields.notInHighSchool || false}
                  onChange={(e) =>
                    handleChange("notInHighSchool", {
                      target: { value: e.target.checked },
                    })
                  }
                  id="notInHighSchool"
                  disabled={true}
                  checked={disableNotInHighSchool(
                    fields.user.graduationyearValue
                  )}
                // disabled={disableNotInHighSchool(
                //   fields.user.graduationyearValue
                // )}
                />
                {/*<label htmlFor="notInHighSchool">Not in high school</label>*/}
                <label htmlFor="notInHighSchool">Post High School</label>
              </span>
            </div>
            <div className="fieldBox dynamicBox">
              {/* {fields.notInHighSchool ? ( */}
              <div className="fieldBox">
                {/*<label>Enter Current Grade</label>*/}
                <label>Enter Current Level</label>
                <select
                  value={fields.schoolCurrentLevel || ""}
                  onChange={(e) => handleChange("schoolCurrentLevel", e)}
                >
                  <option value="">Select</option>
                  {filteredCurrectSchoolLevel &&
                    filteredCurrectSchoolLevel.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.currentlevelvalue}
                      </option>
                    ))}
                </select>
              </div>
              {/* ) : null} */}
            </div>
            <p className="skip">
              Don't know?{" "}
              <Link to="#" className="skipit-btn" onClick={handleClickOpen}>
                Skip it
              </Link>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="#" onClick={() => handleSubmit()}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            handleClose()
          }
        }}
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    onboarding: state.athlete.onBoarding.onboarding,
    schoolCurrentLevel: state.athlete.onBoarding.schoolCurrentLevel,
    schoolList: state.athlete.editUserProfileData.highSchoolList,
    allUserAcademicSchoolList:
      state.athlete.editUserProfileData.userAcademicSchool,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
    schoolListStart: (data) => dispatch(allSchoolListStart(data)),
    userAcademicSchoolSave: (data) => dispatch(userAcademicSchoolSave(data)),
    schoolCurrentLevelListStart: (data) =>
      dispatch(schoolCurrentLevelListStart(data)),
    userAcademicSchoolListStart: (data) =>
      dispatch(userAcademicSchoolStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolInformation);
