import { combineReducers } from "redux";
import commitmentReducer from "./commitment";
import viewCommitmentsListReducer from "./viewCommitments";
import collegeLevelListReducer from "./collegeLevel";
import profileVideoPhotoListReducer from "./profilePhotoVideoList";
import profileTimelineReducer from "./timeline";
import athleteProfileDataReducer from "./athleteProfileData";
import profileStatsDataReducer from "./profileStatsData";
import commitmentStoryReducer from "./commitmentStory";
import collegeDivisionListReducer from "./collegeDivisionLevel";

export default combineReducers({
  commitments: commitmentReducer,
  viewCommitments: viewCommitmentsListReducer,
  collegeLevel: collegeLevelListReducer,
  collegeDivision: collegeDivisionListReducer,
  profileVideosPhotos: profileVideoPhotoListReducer,
  profileTimeline: profileTimelineReducer,
  athleteProfileData: athleteProfileDataReducer,
  profileStatsData: profileStatsDataReducer,
  commitmentStory: commitmentStoryReducer
});
