import React, { useState } from "react";
import AutoCompleteCitySearch from "../../../../../common/AutoCompleteCitySearch";

const CheckInModal = (props) => {
  const handleCloseModal = () => props.setCheckShowModal(false);

  return (
    // <Dialog open={setCheckShowModal} className="checkInModalOverlay">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="heading">Search for location</h3>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleCloseModal()}
          >
            <span aria-hidden="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.804"
                height="15.803"
                viewBox="0 0 15.804 15.803"
              >
                <defs>
                  <style></style>
                </defs>
                <g className="a" transform="translate(-6.776 -6.776)">
                  <path
                    d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                    transform="translate(0 0)"
                  />
                </g>
              </svg>
            </span>
          </button>
        </div>

        <div className="form-info-box">
          <div className="item">
            <AutoCompleteCitySearch
              addressDetails={props.setLocation}
              cityName={props.locationName}
            />
          </div>
        </div>
      </div>
    </div>
    // </Dialog>
  );
};
export default CheckInModal;
