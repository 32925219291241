import {combineReducers} from 'redux'
import membershipListReducer from "./membership";
import askedQuestionsReducer from "./frequentlyAskedQuestions";
import searchQuestionsReducer from "./searchFAQ";
import notificationSettingReducer from './notificationSetting';

export default combineReducers({
    allMemberships: membershipListReducer,
    allAskedQuestions: askedQuestionsReducer,
    searchQuestions: searchQuestionsReducer,
    notificationSetting: notificationSettingReducer,
})