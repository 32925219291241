import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_VIDEO_PHOTO_LIST_START: "ALL_VIDEO_PHOTO_LIST_START",
  ALL_VIDEO_PHOTO_LIST_SUCCESS: "ALL_VIDEO_PHOTO_LIST_SUCCESS",
  ALL_VIDEO_PHOTO_LIST_FAILED: "ALL_VIDEO_PHOTO_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const allVideoPhotoListStart = (data) => ({
  type: actionType.ALL_VIDEO_PHOTO_LIST_START,
  payload: data,
});

export const allVideoPhotoListSuccess = (data) => ({
  type: actionType.ALL_VIDEO_PHOTO_LIST_SUCCESS,
  payload: data,
});

export const allVideoPhotoListFailed = (data) => ({
  type: actionType.ALL_VIDEO_PHOTO_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allVideoPhotoListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ALL_VIDEO_PHOTO_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_VIDEO_PHOTO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_VIDEO_PHOTO_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allVideoPhotoListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      let finalData = [];
      if (
        response.data.data.allUploadedvideoandphotos?.Uploadedvideoandphotos
      ) {
        for (const data of response.data.data.allUploadedvideoandphotos
          ?.Uploadedvideoandphotos) {
          if (
            data.useracivityUploadedphotosandvideosid &&
            data.useracivityUploadedphotosandvideosid.Useracivities &&
            data.useracivityUploadedphotosandvideosid.Useracivities.length
          ) {
            let tempObj = {};
            for (const item of data.useracivityUploadedphotosandvideosid
              .Useracivities) {
              if (item.postid) {
                tempObj = {
                  ...tempObj,
                  userActivityWithPost: item.id,
                  videoPostId: item.postid,
                };
              } else {
                tempObj = {
                  ...tempObj,
                  userActivityId: item.id,
                };
              }
            }
            finalData = [
              ...finalData,
              {
                ...tempObj,
                ...data,
              },
            ];
          } else {
            finalData = [...finalData, data];
          }
        }
      }
      yield put(
        allVideoPhotoListSuccess({
          allUploadedvideoandphotos: [...finalData],
        })
      );
    } else {
      messagePopup("", "Video Photo List failed", "error");
      yield put(allVideoPhotoListFailed("Video Photo List failed"));
    }
  } catch (error) {
    messagePopup("", "Video Photo List failed", "error");
    yield put(allVideoPhotoListFailed("Video Photo List failed"));
  }
}

export function* watchAllVideoPhotoListSaga() {
  yield takeEvery(actionType.ALL_VIDEO_PHOTO_LIST_START, allVideoPhotoListSaga);
}
