import React from "react";

const CardDetailsBox = (props) => {
  return (
    <div
      className="payment-cart-box"
      style={{ display: `${props.showPaymentBox ? "block" : "none"} ` }}
    >
      <div className="payment-cart-inner">
        <div className="title-box">
          <span className="add-payment-button">+ Add New Card</span>
          <span className="close-payment-button">
            <img src="img/close-gray-icon.svg" alt="" />
          </span>
        </div>
        <div className="payment-detail">
          <div className="flexbox full">
            <label>Card Number</label>
            <input type="text" />
          </div>
          <div className="flexbox">
            <div className="half">
              <label>Card Expires</label>
              <input type="text" />
            </div>
            <div className="half security-code">
              <label>Security Code</label>
              <input type="text" />
            </div>
          </div>
          <div className="flexbox full">
            <label>Cardholder Name</label>
            <input type="text" />
          </div>
        </div>
      </div>
      <div className="payment-button">
        <button
          type="button"
          className="btn green"
          onClick={props.paymentStart ? () => props.paymentStart() : null}
        >
          Add Card and Process Payment
        </button>
        <br />
        <button className="cancel-link" onClick={() => props.onCancel()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CardDetailsBox;
