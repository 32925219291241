// export const getUpgradePlansQuery = `
// query allSubscriptionplans($where: JSON)
// {
//     allSubscriptionplans(where: $where){
//       Subscriptionplans{
//         id
//         plantype
//         plansectiontype
//         planname
//         monthlyprice
//         yearlyprice
//         profile
//         videoorphoto
//         fullmatrics
//         collegeviews
//         collegematch
//         collegedirectory
//         description
//         helporsupport
        
//        subscriptionplanincludessubscriptionplanidMap{
//         Subscriptionplanincludes{
//           id
//           includeplanname
//         }
//       }
//       }
//     }
//   }
// `;
export const getUpgradePlansQuery = `
query allSubscriptionplans($where: JSON){
  allSubscriptionplans(where: $where){
    Subscriptionplans{
      id
      plantype
      plansectiontype
      planname
      monthlyprice
      yearlyprice
      description
      subscriptionplanfeaturesmappingplanidMap{
        Subscriptionplanfeaturesmappings{
          availability
          subscriptionplanfeaturesmappingfeatureidmaprel{
            Subscriptionfeatures{
              featurename
            }
          }
        }
      }
      
     subscriptionplanincludessubscriptionplanidMap{
      Subscriptionplanincludes{
        id
        includeplanname
      }
    }
    }
  }
}




`

export const getPaymentCartListQuery = `
query allPaymentCartList($obj:JSON) 
{
  allCarddetails(where:$obj){
    Carddetails {
      id
      cardholdername
      cardtype
      expiryyear
      expirymonth
      cardnumber
      carddetailscardtypemaprel{
        Cardtypes{
          id
            cardtypename
            cardimage
        }
    }
    }
  }
}
`;

export const getCardListQuery = `
query allCardtypes 
{
  allCardtypes{
    Cardtypes {
      id
      cardtypename
    }
  }
}
`;

export const saveNewCardDetails = `
mutation saveCarddetails($obj:CarddetailsInput!){
  saveCarddetails(obj:$obj){
      id
      cardholdername
  }
}
`;

export const saveMembershipQuery = `
mutation saveMembership($obj:MembershipInput!){
  saveMembership(obj:$obj){
      id
      userid
      planprice
      planstartdate
      planexpirydate
      plantimeperiod
      planrenewaldate
      subscriptionplanid
      active
      createdby
      createdon
      updatedby
      updatedon
      videoandphotoplanid
      videoeditingorderplanid
  }
}
`;
