import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../../services/httpService";
import { getUserInfo } from "../../../../../services/authService";
import history from "../../../../../services/history";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  SAVE_VIDEO_ORDER_START: "SAVE_VIDEO_ORDER_START",
  SAVE_VIDEO_ORDER_FAIL: "SAVE_VIDEO_ORDER_FAIL",
  SAVE_VIDEO_ORDER_SUCCESS: "SAVE_VIDEO_ORDER_SUCCESS",
};

/* ======================================= Actions Creator ==========================================*/

export const saveVideoOrderStart = (data) => ({
  type: actionType.SAVE_VIDEO_ORDER_START,
  payload: data,
});

export const saveVideoOrderSuccess = (data) => ({
  type: actionType.SAVE_VIDEO_ORDER_SUCCESS,
  payload: data,
});

export const saveVideoOrderFail = (data) => ({
  type: actionType.SAVE_VIDEO_ORDER_FAIL,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function saveVideoOrderReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_VIDEO_ORDER_START:
      return { ...state, loading: true };

    case actionType.SAVE_VIDEO_ORDER_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.SAVE_VIDEO_ORDER_FAIL:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* saveVideoOrderSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query.videoOrder,
      action.payload.variables,
      true
    );
    if (response.status === 200 && response.data.data.saveVideoedittingorder) {
      const allOrder = response.data.data.saveVideoedittingorder;
      if (allOrder.id) {
        const response1 = yield fetchGraphMethod(
          action.payload.query.membership,
          {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : 0,
              videoeditingorderplanid: allOrder.id,
            },
          },
          true
        );
        if (response1 && response1.status === 200) {
          history.push(action.payload.nextRoute);
          messagePopup("", "PLAN PURCHASED", "success");
          yield put(saveVideoOrderSuccess(allOrder));
        } else {
          yield fetchGraphMethod(
            action.payload.query.videoOrder,
            { obj: { id: allOrder.id, active: 0 } },
            true
          );
          messagePopup("", "SAVE VIDEO ORDER FAILED", "error");
          yield put(saveVideoOrderFail("Failed, Error occured"));
        }
      } else {
        messagePopup("", "SAVE VIDEO ORDER FAILED", "error");
        yield put(saveVideoOrderFail("Failed, Error occured"));
      }
    } else {
      messagePopup("", "SAVE VIDEO ORDER FAILED", "error");
      yield put(saveVideoOrderFail("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "SAVE VIDEO ORDER FAILED", "error");
    yield put(saveVideoOrderFail("Failed, Error occured"));
  }
}

export function* watchSaveVideoOrderSaga() {
  yield takeEvery(actionType.SAVE_VIDEO_ORDER_START, saveVideoOrderSaga);
}
