import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  TutionCosts_LIST_START: "TutionCosts_LIST_START",
  TutionCosts_LIST_SUCCESS: "TutionCosts_LIST_SUCCESS",
  TutionCosts_LIST_FAILED: "TutionCosts_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const TutionCostsListStart = (data) => ({
  type: actionType.TutionCosts_LIST_START,
  payload: data,
});

export const TutionCostsListSuccess = (data) => ({
  type: actionType.TutionCosts_LIST_SUCCESS,
  payload: data,
});

export const TutionCostsListFailed = (data) => ({
  type: actionType.TutionCosts_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function TutionCostsListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.TutionCosts_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.TutionCosts_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.TutionCosts_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* TutionCostsListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const allTutionCosts = response.data.data.allTutioncosts?.Tutioncosts
        ? response.data.data.allTutioncosts.Tutioncosts
        : null;
      let finalData = [];
      yield put(TutionCostsListSuccess(allTutionCosts));
    } else {
      messagePopup("", "COLLEGE List failed", "error");
      yield put(TutionCostsListFailed("TutionCosts List failed"));
    }
  } catch (error) {
    messagePopup("", "TutionCosts List failed", "error");
    yield put(TutionCostsListFailed("TutionCosts List failed"));
  }
}

export function* watchTutionCostsListSaga() {
  yield takeEvery(actionType.TutionCosts_LIST_START, TutionCostsListSaga);
}
