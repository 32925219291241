import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  COLLEGE_CAMPUSES_LIST_START: "COLLEGE_CAMPUSES_LIST_START",
  COLLEGE_CAMPUSES_SUCCESS: "COLLEGE_CAMPUSES_SUCCESS",
  COLLEGE_CAMPUSES_FAILURE: "COLLEGE_CAMPUSES_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const collegeCampusesListStart = (data) => ({
  type: actionType.COLLEGE_CAMPUSES_LIST_START,
  payload: data,
});

export const collegeCampusesSuccess = (data) => ({  
    type: actionType.COLLEGE_CAMPUSES_SUCCESS,
    payload: data,
});
  
export const collegeCampusesFailure = (data) => ({
    type: actionType.COLLEGE_CAMPUSES_FAILURE,
    payload: data
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function collegeCampusesReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.COLLEGE_CAMPUSES_LIST_START:
      return { ...state, loading: true };
    
    case actionType.COLLEGE_CAMPUSES_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.COLLEGE_CAMPUSES_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/


function* getCollegeCampusesSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true);
        if(response.status === 200){
            const allCollegeCampuses = response.data?.data?.allCollegecampushes?.Collegecampushes;

            yield put(collegeCampusesSuccess(allCollegeCampuses));
        } else {
          messagePopup('', 'College Campuses Data Failed', 'error');
          yield put(collegeCampusesFailure('Failed, Error occured'))
        }
       } catch (error) {
         messagePopup('', 'College Campuses Data Failed', 'error');
         yield put(collegeCampusesFailure('Failed, Error occured'))
       }
}

export function* watchCollegeCampusesSaga() {
  yield takeEvery(actionType.COLLEGE_CAMPUSES_LIST_START, getCollegeCampusesSaga);
}

