import logoH from "../assets/Img/logoH.png";
import mobileLogo from "../assets/Img/mobileLogo.png";
import "../../../assets/css/stylesheet.scss";
import '../assets/css/coach-profile-home.css'
import '../assets/css/Coachheader.css'
export const CoachHeader = () =>{
    return(
        <div className="flex">
            <div className="logoPart">
                <a href="#">
                    <img src={logoH} alt="logo"/>
                        <img src={mobileLogo} alt="logo" className="mobile"/>
                </a>
            </div>
            <nav>
                <ul>
                    <li><a href="#" className="upgradeLink icon">Sign Up</a></li>
                    <li><a href="#" className="upgradeLink icon">Sign In</a></li>
                </ul>
            </nav>
        </div>
)
}