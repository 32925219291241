export const getAllTeams = `
query {
    allTeams {
      Teams {
        id
        teamname
      }
    }
  }
`;

export const getSchoolLevels = `
query {
  allSchoolcurrentlevels{
    Schoolcurrentlevels{
      currentlevelvalue
      id
      ishighschool
    }
  }
}
`;

export const getCollegeLevels = `
query{
  allCollegelevels{
    Collegelevels{
      id
      collegelevelname
    }
  }
  }
`;
export const getCollegeDivisions = `
query allCollegedivisions{
  allCollegedivisions{
    totalCount
    Collegedivisions{
      id
      collegedivisionname
      divisionathleticselectivitymappingCollegedivisionidMap{
        Divisionathleticselectivitymappings{
          athleticselectivityid
          divisionathleticselectivitymappingathleticselectivityidmaprel{
            Athleticselectivities{
              id
              name
            }
          }
        }
      }
    }
  }
}
`;

export const getAllUserCollege = `
query AllAthletemorecolleges($obj:JSON){
  allAthletemorecolleges(where:$obj){
    Athletemorecolleges{
      id
      active
      collegename
      userid
      startdateyear
      startdatemonth
      enddateyear
      enddatemonth
      collegeteam
      athletemorecollegecollegedivisonmaprel{
        Collegedivisions{
          collegedivisionname
          id
        }
      }
      athletemorecollegecollegecurrentlevelmaprel{
        Collegelevels{
					id
          collegelevelname
        }
      }
    }
  }
}
`;

export const saveUserCollege = `
mutation SaveAthletemorecollege($obj:AthletemorecollegeInput!){
  saveAthletemorecollege(obj:$obj){
      id
      active
      collegename
      userid
      startdateyear
      startdatemonth
      enddateyear
      enddatemonth
      collegeteam
      athletemorecollegecollegedivisonmaprel{
        Collegedivisions{
          collegedivisionname
          id
        }
      }
      athletemorecollegecollegecurrentlevelmaprel{
        Collegelevels{
					id
          collegelevelname
        }
      }
  }
}
`;

export const getSchoolDivisions = `
query {
  allSchooldivisions {
    Schooldivisions {
      id
      division
    }
  }
}
`;
export const getSchoolType = `
query{
  allSchooltypes{
    Schooltypes{
      id
      schooltypename
    }
  }
}`;

export const filterSchoolNameQuery = `
query allAthleteschools($where:JSON){
  allAthleteschools(where:$where){
    Athleteschools{
      id
      highschoolname
      city
      state
    }
  }
}
`;

export const filterCollegeNameQuery = `query AllAthletemorecolleges($where:JSON){
  allAthletemorecolleges(where:$where){
    Athletemorecolleges{
      id
      collegename
    }
  }
}`;

export const getSchoolName = `
query{
  allAthleteschools{
    Athleteschools{
      id
      highschoolname
      isuserschool
    }
  }
}
`;

export const getSchoolNameQuery = `
query allAthleteschools($where: JSON){
  allAthleteschools(where:$where){
    Athleteschools{
      id
      highschoolname
      city
      state
      isuserschool
    }
  }
}
`;

export const saveUserSchool = `
mutation SaveAthleteschool($obj:AthleteschoolInput!){
  saveAthleteschool(obj:$obj){
    id
    active
    userid
    highschoolname
    currentltnotinhighschool
    enddateyear
    enddatemonth
    startdateyear
    startdatemonth
    zipcode
    athleteschooldivisionmaprel{
      Schooldivisions{
        id
        division
      }
    }
    athleteschoolschoolcurrentleveloptionmaprel{
      Schoolcurrentlevels{
        id
        currentlevelvalue
        ishighschool
      }
    }
    athleteschoolhighschooltypemaprel{
      Schooltypes{
        id
        schooltypename
      }
    }
  }
}
`;

export const getAllUserSchool = `
query AllAthleteschools($obj:JSON){
  allAthleteschools(where:$obj){
    Athleteschools{
      id
      active
    userid
    highschoolname
    highschooltype
    division
    currentltnotinhighschool
    enddateyear
    enddatemonth
    startdateyear
    startdatemonth
    zipcode
    athleteschooldivisionmaprel{
      Schooldivisions{
        id
        division
      }
    }
    athleteschoolschoolcurrentleveloptionmaprel{
      Schoolcurrentlevels{
        id
        currentlevelvalue
        ishighschool
      }
    }
    athleteschoolhighschooltypemaprel{
      Schooltypes{
        id
        schooltypename
      }
    }
    }
  }
}
`;

export const getUserSchoolForCheckList = `
query AllAthleteschools($obj:JSON){
  allAthleteschools(where:$obj){
    Athleteschools{
      id
      userid
      highschooltype
      division
      highschoolname
    }
  }
}

`;
export const getAllAthleteMoreSchoolQuery = `
query allAthletemoreschools($obj:JSON){
  allAthletemoreschools(where:$obj){
    Athletemoreschools{
      id
      active
      userid
      highschoolname
      highschooltype
      division
      currentltnotinhighschool
      enddateyear
      enddatemonth
      startdateyear
      startdatemonth
      zipcode
      athletemoreschooldivisionmaprel{
        Schooldivisions{
          id
          division
        }
      }
      athletemoreschoolcurrentleveloptionmaprel{
        Schoolcurrentlevels{
          id
          currentlevelvalue
          ishighschool
        }
      }
      athletemoreschoolhighschooltypemaprel{
        Schooltypes{
          id
          schooltypename
        }
      }
    }
  }
  }
`

export const getAllAthleteMoreSchoolQuery1 = `
 query allAthletemoreschools($obj:JSON){
  allAthletemoreschools(where:$obj){
    Athletemoreschools{
      id
      schoolid
    active
    userid
    highschoolname
    highschooltype
    division
    currentltnotinhighschool
    enddateyear
    enddatemonth
    startdateyear
    startdatemonth
    zipcode
    athletemoreschooldivisionmaprel{
      Schooldivisions{
        id
        division
      }
    }
    athletemoreschoolcurrentleveloptionmaprel{
      Schoolcurrentlevels{
        id
        currentlevelvalue
        ishighschool
      }
    }
    athletemoreschoolhighschooltypemaprel{
      Schooltypes{
        id
        schooltypename
      }
    }
    }
  }
}
`

export const saveAllAthleteMoreSchoolQuery = `
  mutation saveAthletemoreschool($obj:AthletemoreschoolInput!){
    saveAthletemoreschool(obj:$obj){
      id
      active
      userid
      highschoolname
      highschooltype
      division
      currentltnotinhighschool
      enddateyear
      enddatemonth
      startdateyear
      startdatemonth
      zipcode
      athletemoreschooldivisionmaprel{
        Schooldivisions{
          id
          division
        }
      }
      athletemoreschoolcurrentleveloptionmaprel{
        Schoolcurrentlevels{
          id
          currentlevelvalue
          ishighschool
        }
      }
      athletemoreschoolhighschooltypemaprel{
        Schooltypes{
          id
          schooltypename
        }
      }
    }
  }
`