import React, { useEffect, useState} from 'react'
import ClaimProfileHeader from "../auth/claimProfile/claimProfileStep1/ClaimProfileHeader";
import DashboardHeader from "../../layouts/header/DashboardHeader";
import {getAuthToken} from "../../../services/authService";
import TD from './assets/img/tyler-dyson.png'
import UODLogo from './assets/img/university-of-delaware-logo.png'
import './assets/css/lading-page.scss'

const CommitsLP = (props) =>{
    const [headerType, setHeaderType] = useState(true),
        isToken = props?.match?.params?.isToken == 1 ? true : false;
    useEffect(()=>{
        getStatus(props)
        //for scrolling the page on top
        setTimeout(()=>{
            window.scrollTo(0,0)
        },1000)
    },[])
    const getStatus = (props) =>{
        if (!!getAuthToken() || isToken){
            setHeaderType(false)
        }
    }
    return(
        <>
            {
                headerType ?
                    <ClaimProfileHeader/>
                    :
                    <DashboardHeader/>
            }
            <section className="banner-lp-sec">
                <div className="container">
                    <div className="commit-banner-content">
                        <h1 className="title">SportsForce Members Get It Done</h1>
                        <h2 className="sub-title">111,000+</h2>
                        <div className="short-text">
                            <p>Commitments and Counting</p>
                        </div>
                        <div className="button-wrap">
                            <a className="btn lightblue" href={void 0}>Get Going</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="lp-team-sec">
                <div className="container">
                    <div className="team-list">
                        <div className="item">
                            <div className="team-infobox">
                                <div className="pic-box">
                                    <img src={TD} alt="Tyler Dyson \"/>
                                    <span className="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">
                                      <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">
                                        <circle id="background" cx="15" cy="15" r="15"
                                                transform="translate(0.003 0.002)" fill="#00a6de"/>
                                        <path id="icon"
                                              d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                              transform="translate(-30.461 -35.611)" fill="#fff"/>
                                      </g>
                                    </g>
                                  </svg>
                            </span>
                                </div>
                                <div className="detail-info">
                                    <h4 className="name">
                                        Tyler Dyson
                                        <span className="position">Baseball</span>
                                        <span className="text">University Of Houston - Victoria, NAIA</span>
                                    </h4>
                                    <h5 className="commited-text">COMMITTED TO</h5>
                                    <div className="university-logo">
                                        <img src={UODLogo} alt=""/>
                                    </div>
                                    <p>Veterans Memorial High School</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="team-infobox">
                                <div className="pic-box">
                                    <img src={TD} alt="Tyler Dyson \"/>
                                    <span className="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">
                                      <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">
                                        <circle id="background" cx="15" cy="15" r="15"
                                                transform="translate(0.003 0.002)" fill="#00a6de"/>
                                        <path id="icon"
                                              d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                              transform="translate(-30.461 -35.611)" fill="#fff"/>
                                      </g>
                                    </g>
                                  </svg>
                            </span>
                                </div>
                                <div className="detail-info">
                                    <h4 className="name">
                                        Tyler Dyson
                                        <span className="position">Baseball</span>
                                        <span className="text">University Of Houston - Victoria, NAIA</span>
                                    </h4>
                                    <h5 className="commited-text">COMMITTED TO</h5>
                                    <div className="university-logo">
                                        <img src={UODLogo} alt=""/>
                                    </div>
                                    <p>Veterans Memorial High School</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="team-infobox">
                                <div className="pic-box">
                                    <img src={TD} alt="Tyler Dyson \"/>
                                    <span className="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">
                                      <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">
                                        <circle id="background" cx="15" cy="15" r="15"
                                                transform="translate(0.003 0.002)" fill="#00a6de"/>
                                        <path id="icon"
                                              d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                              transform="translate(-30.461 -35.611)" fill="#fff"/>
                                      </g>
                                    </g>
                                  </svg>
                            </span>
                                </div>
                                <div className="detail-info">
                                    <h4 className="name">
                                        Tyler Dyson
                                        <span className="position">Baseball</span>
                                        <span className="text">University Of Houston - Victoria, NAIA</span>
                                    </h4>
                                    <h5 className="commited-text">COMMITTED TO</h5>
                                    <div className="university-logo">
                                        <img src={UODLogo} alt=""/>
                                    </div>
                                    <p>Veterans Memorial High School</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="team-infobox">
                                <div className="pic-box">
                                    <img src={TD} alt="Tyler Dyson \"/>
                                    <span className="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">
                                      <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">
                                        <circle id="background" cx="15" cy="15" r="15"
                                                transform="translate(0.003 0.002)" fill="#00a6de"/>
                                        <path id="icon"
                                              d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                              transform="translate(-30.461 -35.611)" fill="#fff"/>
                                      </g>
                                    </g>
                                  </svg>
                            </span>
                                </div>
                                <div className="detail-info">
                                    <h4 className="name">
                                        Tyler Dyson
                                        <span className="position">Baseball</span>
                                        <span className="text">University Of Houston - Victoria, NAIA</span>
                                    </h4>
                                    <h5 className="commited-text">COMMITTED TO</h5>
                                    <div className="university-logo">
                                        <img src={UODLogo} alt=""/>
                                    </div>
                                    <p>Veterans Memorial High School</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="team-infobox">
                                <div className="pic-box">
                                    <img src={TD} alt="Tyler Dyson \"/>
                                    <span className="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">
                                      <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">
                                        <circle id="background" cx="15" cy="15" r="15"
                                                transform="translate(0.003 0.002)" fill="#00a6de"/>
                                        <path id="icon"
                                              d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                              transform="translate(-30.461 -35.611)" fill="#fff"/>
                                      </g>
                                    </g>
                                  </svg>
                            </span>
                                </div>
                                <div className="detail-info">
                                    <h4 className="name">
                                        Tyler Dyson
                                        <span className="position">Baseball</span>
                                        <span className="text">University Of Houston - Victoria, NAIA</span>
                                    </h4>
                                    <h5 className="commited-text">COMMITTED TO</h5>
                                    <div className="university-logo">
                                        <img src={UODLogo} alt=""/>
                                    </div>
                                    <p>Veterans Memorial High School</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="team-infobox">
                                <div className="pic-box">
                                    <img src={TD} alt="Tyler Dyson \"/>
                                    <span className="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">
                                      <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">
                                        <circle id="background" cx="15" cy="15" r="15"
                                                transform="translate(0.003 0.002)" fill="#00a6de"/>
                                        <path id="icon"
                                              d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                              transform="translate(-30.461 -35.611)" fill="#fff"/>
                                      </g>
                                    </g>
                                  </svg>
                            </span>
                                </div>
                                <div className="detail-info">
                                    <h4 className="name">
                                        Tyler Dyson
                                        <span className="position">Baseball</span>
                                        <span className="text">University Of Houston - Victoria, NAIA</span>
                                    </h4>
                                    <h5 className="commited-text">COMMITTED TO</h5>
                                    <div className="university-logo">
                                        <img src={UODLogo} alt=""/>
                                    </div>
                                    <p>Veterans Memorial High School</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="team-infobox">
                                <div className="pic-box">
                                    <img src={TD} alt="Tyler Dyson \"/>
                                    <span className="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">
                                      <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">
                                        <circle id="background" cx="15" cy="15" r="15"
                                                transform="translate(0.003 0.002)" fill="#00a6de"/>
                                        <path id="icon"
                                              d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                              transform="translate(-30.461 -35.611)" fill="#fff"/>
                                      </g>
                                    </g>
                                  </svg>
                            </span>
                                </div>
                                <div className="detail-info">
                                    <h4 className="name">
                                        Tyler Dyson
                                        <span className="position">Baseball</span>
                                        <span className="text">University Of Houston - Victoria, NAIA</span>
                                    </h4>
                                    <h5 className="commited-text">COMMITTED TO</h5>
                                    <div className="university-logo">
                                        <img src={UODLogo} alt=""/>
                                    </div>
                                    <p>Veterans Memorial High School</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="team-infobox">
                                <div className="pic-box">
                                    <img src={TD} alt="Tyler Dyson \"/>
                                    <span className="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">
                                      <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">
                                        <circle id="background" cx="15" cy="15" r="15"
                                                transform="translate(0.003 0.002)" fill="#00a6de"/>
                                        <path id="icon"
                                              d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                              transform="translate(-30.461 -35.611)" fill="#fff"/>
                                      </g>
                                    </g>
                                  </svg>
                            </span>
                                </div>
                                <div className="detail-info">
                                    <h4 className="name">
                                        Tyler Dyson
                                        <span className="position">Baseball</span>
                                        <span className="text">University Of Houston - Victoria, NAIA</span>
                                    </h4>
                                    <h5 className="commited-text">COMMITTED TO</h5>
                                    <div className="university-logo">
                                        <img src={UODLogo} alt=""/>
                                    </div>
                                    <p>Veterans Memorial High School</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="team-infobox">
                                <div className="pic-box">
                                    <img src={TD} alt="Tyler Dyson \"/>
                                    <span className="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">
                                      <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">
                                        <circle id="background" cx="15" cy="15" r="15"
                                                transform="translate(0.003 0.002)" fill="#00a6de"/>
                                        <path id="icon"
                                              d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                              transform="translate(-30.461 -35.611)" fill="#fff"/>
                                      </g>
                                    </g>
                                  </svg>
                            </span>
                                </div>
                                <div className="detail-info">
                                    <h4 className="name">
                                        Tyler Dyson
                                        <span className="position">Baseball</span>
                                        <span className="text">University Of Houston - Victoria, NAIA</span>
                                    </h4>
                                    <h5 className="commited-text">COMMITTED TO</h5>
                                    <div className="university-logo">
                                        <img src={UODLogo} alt=""/>
                                    </div>
                                    <p>Veterans Memorial High School</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="team-infobox">
                                <div className="pic-box">
                                    <img src={TD} alt="Tyler Dyson \"/>
                                    <span className="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">
                                      <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">
                                        <circle id="background" cx="15" cy="15" r="15"
                                                transform="translate(0.003 0.002)" fill="#00a6de"/>
                                        <path id="icon"
                                              d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                              transform="translate(-30.461 -35.611)" fill="#fff"/>
                                      </g>
                                    </g>
                                  </svg>
                            </span>
                                </div>
                                <div className="detail-info">
                                    <h4 className="name">
                                        Tyler Dyson
                                        <span className="position">Baseball</span>
                                        <span className="text">University Of Houston - Victoria, NAIA</span>
                                    </h4>
                                    <h5 className="commited-text">COMMITTED TO</h5>
                                    <div className="university-logo">
                                        <img src={UODLogo} alt=""/>
                                    </div>
                                    <p>Veterans Memorial High School</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-wrap">
                        <button className="btn lightblue">Load more</button>
                    </div>
                </div>
            </section>

            <section className="get-started-sec mb-2">
                <div className="container">
                    <h3 className="sec-title">Get started for free today!</h3>
                    <div className="short-text">
                        <p>We can't wait to have you on board!</p>
                    </div>
                    <div className="button-wrap">
                        <a className="btn" href={void 0}>Join Waiting List</a>
                    </div>
                </div>
            </section>

        </>
    )
}
export default CommitsLP
