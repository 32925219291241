import { put, takeEvery } from "redux-saga/effects";
import { getUserInfo, setUserInfo } from "../../../../services/authService";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";
import history from '../../../../services/history'

// ========================================== Action Type =====================================================
export const actionType = {
  SWITCH_ATHLETE_START: "SWITCH_ATHLETE_START",
  SWITCH_ATHLETE_SUCCESS: "SWITCH_ATHLETE_SUCCESS",
  SWITCH_ATHLETE_FAILED: "SWITCH_ATHLETE_FAILED",
};

// ============================================ Actions ========================================================

export const switchAthleteStart = (data) => ({
  type: actionType.SWITCH_ATHLETE_START,
  payload: data,
});

export const switchAthleteSuccess = (data) => ({
  type: actionType.SWITCH_ATHLETE_SUCCESS,
  payload: data,
});

export const switchAthleteFailed = (data) => ({
  type: actionType.SWITCH_ATHLETE_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function switchAthleteReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SWITCH_ATHLETE_START:
      return {
        ...state,
        loading: true,
      };

    case actionType.SWITCH_ATHLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SWITCH_ATHLETE_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* switchAthleteSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Userdata/switchAthlete`,
      action.payload.data,
      "post",
      true
    );
    if (response && response.status === 200) {
      if (response.data.response?.status == 200) {
        yield put(switchAthleteSuccess({ ...response.data.response }));
        if (action.payload.refreshPage) action.payload.refreshPage();
        const userData = getUserInfo();
        const finalData = { ...userData, ...response.data.response };
        setUserInfo(finalData);
        history?.push('/homepage')
        setTimeout(()=>{
          window.location.reload();
        },200)
      }
      else if(response.data.response?.status == 401){
        yield put(switchAthleteSuccess({ ...response.data.response }));
        messagePopup("",!!response.data.response?.msg ? response.data.response?.msg : "Access Denied","error")
      }
      else {
        messagePopup("", "Switch athlete failed", "error");
        yield put(switchAthleteFailed("Switch athlete failed"));
      }
    } else {
      messagePopup("", "Switch athlete failed", "error");
      yield put(switchAthleteFailed("Switch athlete failed"));
    }
  } catch (error) {
    messagePopup("", "Switch athlete failed", "error");
    yield put(switchAthleteFailed("Switch athlete failed"));
  }
}

export function* watchSwitchAthleteSaga() {
  yield takeEvery(actionType.SWITCH_ATHLETE_START, switchAthleteSaga);
}
