import messagePopup from "../utils/messagePopup";
import history from "./history";
import { fetchMethod } from "./httpService";

export async function logout(parentUserId) {
  const user = getUserInfo();
  try {
    const response = await fetchMethod(
      "/api/Userdata/logoutAccount",
      {
        loggedInId: parentUserId ? parentUserId : user.athleteLoggedInId,
        userId: user.id,
        role: parentUserId ? "GUARDIAN" : user.role,
      },
      "post",
      true
    );
    if (response && response.status === 200) {
      if(user.role == "COLLEGECOUCHES"){
        history.push("/coach-login");
      }else if(parentUserId){
        // console.log("parent logout");
      }
      else{
        history.push("/logout");
      }
    } else {
      messagePopup("", "Logout failed", "error");
    }
  } catch (error) {
    messagePopup("", "Logout failed", "error");
  }
}

export function setAuthToken(token) {
  localStorage.setItem("authToken", token);
}

export function getAuthToken() {
  return localStorage.getItem("authToken");
}

export function getUserInfo() {
  return JSON.parse(localStorage.getItem("user"));
}

export function setUserInfo(data) {
  localStorage.setItem("user", JSON.stringify(data));
}

export function getSignupData() {
  return sessionStorage.getItem("signup")
    ? JSON.parse(sessionStorage.getItem("signup"))
    : null;
}

export function setSignupData(data) {
  sessionStorage.setItem("signup", JSON.stringify(data));
}

export function removeSignupData() {
  sessionStorage.removeItem("signup");
}

export default {
  logout,
  setAuthToken,
  getAuthToken,
  setUserInfo,
  getUserInfo,
  getSignupData,
  setSignupData,
  removeSignupData,
};
