import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    NCAA_NAID_LIST_START: " NCAA_NAID_LIST_START",
    NCAA_NAID_SAVE_LIST_START: " NCAA_NAID_SAVE_LIST_START",
    NCAA_NAID_LIST_SUCCESS: " NCAA_NAID_LIST_SUCCESS",
    NCAA_NAID_LIST_FAILED: " NCAA_NAID_LIST_FAILED",
    NCAA_NAID_SAVE_SUCCESS: " NCAA_NAID_SAVE_SUCCESS",
    NCAA_NAID_SAVE_FAILED: " NCAA_NAID_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const   ncaaNaidListStart = (data) => ({
    type: actionType.NCAA_NAID_LIST_START,
    payload: data,
});
export const ncaaNaidSaveListStart = (data) => ({
    type: actionType.NCAA_NAID_SAVE_LIST_START,
    payload: data,
});

export const ncaaNaidListSuccess = (data) => ({
    type: actionType.NCAA_NAID_LIST_SUCCESS,
    payload: data,
});

export const ncaaNaidListFailed = (data) => ({
    type: actionType.NCAA_NAID_LIST_FAILED,
    payload: data,
});
export const ncaaNaidSaveSuccess = (data) => ({
    type: actionType.NCAA_NAID_SAVE_SUCCESS,
    payload: data,
});

export const ncaaNaidSaveFailed = (data) => ({
    type: actionType.NCAA_NAID_SAVE_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function ncaaNaidListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.NCAA_NAID_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.NCAA_NAID_SAVE_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.NCAA_NAID_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.NCAA_NAID_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        case actionType.NCAA_NAID_SAVE_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        case actionType.NCAA_NAID_SAVE_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* ncaaNaidListSaga(action) {
    try {
        const response = yield fetchGraphMethod(
            action.payload.query,
            action.payload.variables,
            true
        );
        if (response && response.status === 200) {
            yield put(ncaaNaidListSuccess(response.data.data.allNcaaandnaids.Ncaaandnaids));
        } else {
            messagePopup("", "Ncaa and naid List failed", "error");
            yield put(ncaaNaidListFailed(" Ncaa and naid List failed"));
        }
    } catch (error) {
        messagePopup("", "Ncaa and naid List failed", "error");
        yield put(ncaaNaidListFailed("Ncaa and naid List failed"));
    }
}

export function* ncaaNaidSaveListSaga(action) {
    try {
        const response = yield fetchGraphMethod(
            action.payload.query,
            action.payload.variables,
            true
        );
        if (response && response.status === 200) {
            yield put(ncaaNaidSaveSuccess({ ncaaNaidData: response.data.data }));
        } else {
            messagePopup("", "Ncaa and naid List failed", "error");
            yield put(ncaaNaidSaveFailed(" Ncaa and naid List failed"));
        }
    } catch (error) {
        messagePopup("", "Ncaa and naid List failed", "error");
        yield put(ncaaNaidSaveFailed("Ncaa and naid List failed"));
    }
}


export function* watchncaaNaidListSaga() {
    yield takeEvery(actionType.NCAA_NAID_LIST_START, ncaaNaidListSaga);
    yield takeEvery(actionType.NCAA_NAID_SAVE_LIST_START, ncaaNaidSaveListSaga);
}
