import React, { useEffect, useState, useCallback } from "react";
import "../assets/css/onBoardAddProfile.css"
// import "../assets/css/onBoardAddProfile.scss"
import "../assets/css/onBoardSteeper.css";
import { Link } from "react-router-dom";
import cameraBlue from "../assets/img/cameraBlue.png";
import messagePopup from "../../../../../utils/messagePopup";
import toastr from "toastr";
import { getAuthToken, getUserInfo } from "../../../../../services/authService";
import { getOnboardingQuery, saveOnboardingQuery } from "../OnboardingQuery";
import { connect } from "react-redux";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/coach/onBoarding/onboarding";
import BackdropLoader from "../../../../common/Loader";
import usePrevious from "../../../../../utils/usePrevious";
import Cropper from "react-easy-crop";
import Modal from "react-modal";
import getCroppedImg from "./CropImage";
import { Button } from "@mui/material";
import { imageDownloadUrl, imageUploadUrl } from "../../../../../config";
import { sportsListStart } from "../../../../../redux/common/allSports";
import Stepper from "../Stepper";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import { Dialog } from "@mui/material";
import { fetchGraphMethod } from "../../../../../services/httpService";

const stepperData = [
  { className: "active", link: "/coach-onboarding/Profile" },
  { className: "" },

  { className: "" },



];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    //right                 : 'auto',
    //bottom                : 'auto',
    //marginRight           : '-50%',
    transform: "translate(-50%, -50%)",
  },
};

function CoachProfilePhoto(props) {
  const [fields, setFields] = useState({
    user: getUserInfo() ? getUserInfo() : 0,
    loading: false,
    profileimagename: "",
  });

  const prevOnboardData = usePrevious(props.onboarding.data);
  const [sportName, setSportName] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [pathURL, setPathUrl] = useState();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    props.history.push("/coach-onboarding/Twitter");
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    const filedetails = event.target.files[0].name.split(".")
    let fileExtension = event.target.files[0].name.split(".")[filedetails.length - 1];
    let fileSize = parseInt(event.target.files[0].size) / (1024 * 1024);
    if (!(
      fileExtension == "jpg" ||
      fileExtension == "jpeg" ||
      fileExtension == "png" ||
      fileExtension == "gif")
    ) {
      // messagePopup(
      //   "",
      //   "Image format must be png, jpg, jpeg, gif",
      //   "error"
      // );
      toastr.error("Image format must be png, jpg, jpeg, gif", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
      setIsOpen(false);
    }

    else if (fileSize < 0.7) {
      // messagePopup("", "Image size should be of minimum 0.7 MB.", "error");
      toastr.error("Image size should be of minimum 0.7 MB.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }

    else if (event.target.files && event.target.files.length > 0) {
      const fieldsT = fields;
      const reader = new FileReader();
      if (event.target.files[0]) {
        fieldsT["fileName"] = event.target.files[0].name;
        fieldsT["fileType"] = event.target.files[0].type;
      }
      setFields(fieldsT);
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
        setIsOpen(true);
      });
    }
  };

  const showCroppedCancelImage = () => {
    setIsOpen(false);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const fieldsT = fields;
      const croppedImage = await getCroppedImg(image, croppedArea);
      fieldsT["file"] = URL.createObjectURL(croppedImage);
      const fileArr = fieldsT["fileName"].split(".");
      fileArr[0] = new Date().getTime();
      var croppedFile = new File([croppedImage], fileArr.join("."), {
        lastModified: new Date(),
        type: fieldsT["fileType"],
      });
      fields["selectedFile"] = croppedFile;
      setFields({
        ...fieldsT,
      });
      setIsOpen(false);
      handleNext();
    } catch (e) {
      console.error(e);
    }
  }, [croppedArea]);

  const handleChange = (field, e) => {
    let fieldsT = fields;
    fieldsT[field] = URL.createObjectURL(e.target.files[0]);
    fields["selectedFile"] = e.target.files[0];
    setFields({
      ...fieldsT,
    });
  };

  useEffect(() => {
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          coachid: getUserInfo() && getUserInfo().id ? getUserInfo().id : null,

        },
      },
    });
  }, []);



  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = fields;
        const { id, profileimages } = props.onboarding.data;
        fieldT["id"] = id;
        fieldT["profileimagename"] = profileimages ? profileimages : "";
        fieldT["file"] = profileimages
          ? `${imageDownloadUrl}/${profileimages}`
          : "";
        setFields({ ...fieldT });
      }
    }


  }, [props]);

  const handleNext = () => {
    let fieldsT = fields;
    if (fields.selectedFile) {
      fields["loading"] = true;
      setFields({
        ...fieldsT,
      });
      let formData = new FormData();
      formData.append("file", fields.selectedFile);
      fetch(imageUploadUrl, {
        method: "POST",
        mode: "cors",
        body: formData,
        headers: {
          access_token: getAuthToken(),
        },
      })
        .then((resp) => resp.json())
        .then((response) => {
          fields["loading"] = false;
          setFields({
            ...fieldsT,
          });
          if (response) {
            const pathUrl = response.result?.files?.file[0]?.name
              ? response.result?.files?.file[0]?.name
              : "";
            setPathUrl(pathUrl);
            fields["loading"] = false;
            setFields({
              ...fieldsT,
            });
          }
        })
        .catch((err) => {
          fields["loading"] = false;
          setFields({
            ...fieldsT,
          });
        });
    } else if (fields.profileimagename) {
      fields["loading"] = false;
      setFields({
        ...fieldsT,
      });
      props.saveOnboardingStart({
        query: saveOnboardingQuery,
        variables: {
          obj: {
            id: fields.id ? fields.id : 0,
            coachid: getUserInfo().id,
            profileimages: fields.profileimagename,
          },
        },
        nextScreen: () => {
          // messagePopup("", "Profile photo added successfully", "success");
          toastr.success("Profile photo added successfully", "", {
            progressBar : true,timeOut: 2000,
            closeButton: true, positionClass: "toast-top-full-width",
            showEasing: "swing", fadeIn: 40000,
            preventDuplicates: true,
          })
        },
      });
    } else {
      // messagePopup("", "Please add a profile photo.", "error");
      toastr.error("Please add a profile photo.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }
  };

  const onClickNext = async () => {
    const variables = {
      obj: {
        id: fields.id ? fields.id : 0,
        coachid: getUserInfo().id,
        profileimages: pathURL,
      },
    }
    if (pathURL) {
      const res = await fetchGraphMethod(
        saveOnboardingQuery,
        variables,
        true
      )
      if (res) {
        // messagePopup("", "Profile photo added successfully", "success");
        toastr.success("Profile photo added successfully", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
        props.history.push("/coach-onboarding/Twitter");
      }
    } else {
      // messagePopup("", "Please add a profile photo.", "error");
      toastr.error("Please add a profile photo.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }

  }

  return (
    <div>
      <BackdropLoader open={props.onboarding.loading || fields.loading} />
      <div className="onBoardAddProfileSection">
        <div className="addProfileBlock">
          <div className="stepper">
            <Stepper stepperName="profilePhoto" stepperData={stepperData} />
          </div>
          <div className="formSection">
            <h1 className="heading">
              Add Profile Photo
              <span className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <g id="Group_844" data-name="Group 844" transform="translate(-649.111 -507.111)">
                    <path id="Path_640" data-name="Path 640" d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0" transform="translate(649.111 507.111)" fill="#00b9ff" />
                    <path id="Path_641" data-name="Path 641" d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0" transform="translate(511.436 348.256)" fill="#fafafa" />
                  </g>
                </svg>
              </span>
            </h1>
            <div className="imageBox">
              <div className="detailBox">
                <div className="imgUploadBox">
                  <div className="container-cropper">
                    <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={() => setIsOpen(false)}
                      style={customStyles}
                      contentLabel="Example Modal"
                      ariaHideApp={false}
                    >
                      <div>
                        <Cropper
                          image={image}
                          crop={crop}
                          zoom={zoom}
                          aspect={1}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                        <Button
                          onClick={showCroppedImage}
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                        <Button
                          className="imageCancel"
                          style={{ margin: "5px", background: "#C0321F" }}
                          onClick={showCroppedCancelImage}
                          variant="contained"
                          color="primary"
                        >
                          Cancel
                        </Button>
                      </div>
                    </Modal>
                  </div>
                  <div className="imgBlock">
                    <img
                      src={fields.file ? fields.file : cameraBlue}
                      className={fields.file ? "uploadedImg" : ""}
                      alt="cameraIcon"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      name="file"
                      onChange={(e) => {
                        onSelectFile(e);
                        e.currentTarget.value = null;
                      }}
                      style={{ display: modalIsOpen ? "none" : "block" }}
                    />
                  </div>

                  <span className="checkIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44.239"
                      height="44.239"
                      viewBox="0 0 44.239 44.239"
                    >
                      <defs>
                        <style></style>
                      </defs>
                      <g transform="translate(-648.611 -508.611)">
                        <path
                          className="a"
                          d="M21.62,0a21.62,21.62,0,1,0,21.62,21.62A21.644,21.644,0,0,0,21.62,0Zm0,0"
                          transform="translate(649.111 509.111)"
                        />
                        <path
                          className="b"
                          d="M164,168.706l-12.862,12.862a1.977,1.977,0,0,1-2.8,0l-6.431-6.431a1.978,1.978,0,1,1,2.8-2.8l5.032,5.032L161.2,165.909a1.978,1.978,0,0,1,2.8,2.8Zm0,0"
                          transform="translate(517.777 356.993)"
                        />
                      </g>
                    </svg>
                  </span>

                </div>
                <h5>
                  {fields.user
                    ? fields.user.FirstName + " " + fields.user.lastName
                    : ""}
                </h5>
                <p>{fields.countryShortName ? fields.countryShortName : ""}</p>

              </div>
            </div>
            <p className="skip">Don't know? &nbsp; 
              <a href="javascript:void(0);" data-toggle="modal" data-target="#SkipModal" onClick={handleClickOpen}>Skip it </a></p>

          </div>
          <div className="continueBtnBox">
            <Link to="#" onClick={onClickNext}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    onboarding: state.athlete.onBoarding.onboarding,
    sportsList: state.common.sportsList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    sportsListStart: (data) => dispatch(sportsListStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachProfilePhoto);
