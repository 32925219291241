import React, { useEffect ,useState} from "react";
import TextField from "@mui/material/TextField";
import {
    checkPhoneNumberValidation,
    formattedPhoneNumber,
  } from "../../../../../utils/validationUtils";

import "../../../../../assets/css/stylesheet.scss";
import { countryListStart } from "../../../../../redux/common/countriesList";
import "../assets/css/onBoardCoachInfo.css";
import "../assets/css/onBoardCoachInfo.css.map";
// import "../assets/css/onBoardCoachInfo.css.map";
// import "../assets/css/onBoardSteeper.scss";
import { Link } from "react-router-dom";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { connect } from "react-redux";
import BackdropLoader from "../../../../common/Loader";
import { getUserInfo } from "../../../../../services/authService";
import { getOnboardingQuery, saveOnboardingQuery,saveCoachPhoneNumber } from "../OnboardingQuery";
import usePrevious from "../../../../../utils/usePrevious";
import { InputAdornment } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import Stepper from "../Stepper";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import { Dialog } from "@mui/material";

import PhoneNumber from "../../../../common/PhoneNumber";
import { BookOutlined } from "@mui/icons-material";

const stepperData = [
    { className: "passed",  link: "/coach-onboarding/Profile" },
    { className: "passed",  link: "/coach-onboarding/Twitter" },
    { className: "active", link: "/coach-onboarding/Mobile" },
];

const Mobile = (props) => {
    const [fields, setFields] = useState({
        user: getUserInfo() ? getUserInfo() : 0,
        phoneNumber: "",
        countryCodeId: "",
        textmeimportantnotification:""

     
      });
     const [isChecked, setIsChecked] = React.useState(0);
  const [errors, setErrors] = React.useState({});
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
 props.history.push("/coach-onboarding/congratulatecoach");
  };
  useEffect(() => {
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          coachid:getUserInfo().id,
        },
      },
    })
   props.countryListStart();
  }, []);

  useEffect(() => {
    if (props.allCountries.data) {
      const fieldsT = fields;
      fieldsT["countryCodeId"] = {
        value: props.allCountries.data ? props.allCountries.data[0].id : "",
        label: props.allCountries.data
          ? props.allCountries.data[0].countryName +
          `(+${props.allCountries.data[0].countryCode})`
          : "",
        flag: props.allCountries.data
          ? props.allCountries.data[0].countryFlagImage
          : "",
        countryCode: props.allCountries.data
          ? props.allCountries.data[0].countryCode
          : "",
        countryShortName: props.allCountries.data
          ? props.allCountries.data[0].countryShortName
          : "",
      };
      setFields({
        ...fieldsT,
      });
    }
  }, [props.allCountries.data]);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = fields;
        const {
          id,
          usetwitterforschool,
          wanttolearnsocialmediaforsportgoal,
          twitteraccountname,
          coachandteamdetailsOnBoardingIdMap
        } = props.onboarding.data;
        fieldT["id"] = id;
        fieldT["useTwitter"] =
          usetwitterforschool !== null
            ? usetwitterforschool === 1
              ? "yes"
              : "no"
            : "yes";
        fieldT["twitterhandle"] = twitteraccountname
          ? twitteraccountname.replace("@", "")
          : null;

        fieldT["learnTwitter"] =
          wanttolearnsocialmediaforsportgoal !== null
            ? wanttolearnsocialmediaforsportgoal === 1
              ? "yes"
              : "no"
            : "yes";
                fieldT["phoneNumber"]= coachandteamdetailsOnBoardingIdMap&&
              coachandteamdetailsOnBoardingIdMap.Coachandteamdetails&& 
              coachandteamdetailsOnBoardingIdMap.Coachandteamdetails[0]&&
              coachandteamdetailsOnBoardingIdMap.Coachandteamdetails[0].coachphonenumber? 
              coachandteamdetailsOnBoardingIdMap.Coachandteamdetails[0].coachphonenumber:''
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);

  const handleRadioChange = (field, value) => {
    let fieldsT = fields;
    fieldsT[field] = value;
    setFields({ ...fieldsT });
  };

  const handleSubmit = () => {
    const { formIsValid, updatedValue } = handleValidation();
    if (formIsValid) {
      props.saveOnboardingStart({
        query: saveCoachPhoneNumber,
        variables: {
            where: {
                id: getUserInfo().id,     //coach id hai ye
            },
            data: {
                coachphonenumber:fields.phoneNumber,
                isdoneonboardingprocess:1,
                textmeimportantnotification:isChecked,
                onboardingid: fields.id ? fields.id : 0,
            },
          },
        nextScreen: routeNextPage,
      });
    }
  };

 
const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    //phone  validations
   
    if (fieldname === undefined || fieldname === "phoneNumber") {
        if (fieldname === undefined) {
          fieldValue = fields["phoneNumber"] ? fields["phoneNumber"] : "";
          updatedValue = fieldValue;
        }
        delete errorsT["phoneNumber"];
        if (!updatedValue) {
          formIsValid = false;
          errorsT["phoneNumber"] = "Mobile Number is required";
        }
        else if (updatedValue.length > 10) {
          formIsValid = false;
          errorsT["phoneNumber"] = 'Mobile Number should be less than 11 digits';
        }
        else if (
          !checkPhoneNumberValidation(
            updatedValue,
            fields.countryCode?.countryShortName
          )
        ) {
          formIsValid = false;
          errorsT["phoneNumber"] = "Please enter a valid phone number";
        }
        updatedValue = formattedPhoneNumber(
          updatedValue,
          fields.countryCodeId?.countryShortName
        );
      }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

//   const handleChange = (field, e) => {
//     // const { formIsValid, updatedValue } = handleValidation(field, e);
//     // e.preventDefault();
//     let fieldsT = fields;
//     fieldsT[field] = updatedValue;
//     setFields({
//       ...fieldsT,
//     });
//   };

 
  const onhandleCheckBoxChange=(field,e)=>{
   if(isChecked==0){
       setIsChecked(1)
   }
   else{
    setIsChecked(0)    
   }
    let fieldsT = fields;
    fieldsT[field] = isChecked
    setFields({
      ...fieldsT,
    });
    // setIsSaved(false);
  }
  const handleChange=(field,e)=>{
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setFields({
      ...fieldsT,
    });
    // setIsSaved(false);
  }

  const handlePhonenumberChange = (selectedOption) => {
    const fieldsT = fields;
    fieldsT["countryCodeId"] = selectedOption;
    setFields({ ...fieldsT, phoneNumber: "" });
  };
  return (
    <div>
      <BackdropLoader open={props.onboarding.loading} />
      <div className="onBoardCoachTeamSection">
        <div className="coachTeamBlock">
          <div className="stepper">
            <Stepper stepperName="mobile" stepperData={stepperData} />
          </div>
          <div className="formSection">
          <div className="coachFormSeciton">
          <form>
<div className="fieldBox mobile">
<label>Coach Mobile Phone <sup>*</sup></label>

<div>

<PhoneNumber
                            dropdownName="countryCode"
                            dropdownOnChange={handlePhonenumberChange}
                            dropdownValue={fields.countryCodeId}
                            dropDownOptions={
                              props.allCountries.data &&
                              props.allCountries.data.map((item) => ({
                                value: item.id,
                                label:
                                  item.countryName + `(+${item.countryCode})`,
                                flag: item.countryFlagImage,
                                countryCode: item.countryCode,
                                countryShortName: item.countryShortName,
                              }))
                            }
                            formatOptionLabel={(
                              option,
                              { context, selectValue }
                            ) => {
                              return context === "menu" ? (
                                option.label
                              ) : (
                                <>
                                  <img
                                    src={option.flag}
                                    alt=""
                                    style={{
                                      width: "40px",
                                      marginRight: "15px",
                                    }}
                                  />
                                  <span>+{option.countryCode}</span>
                                </>
                              );
                            }}
                            inputPlaceholder="Phone Number"
                            inputType="text"
                            inputValue={fields.phoneNumber || ""}
                            inputOnChange={(e) => {
                              handleChange("phoneNumber", {
                                target: {
                                  value: e.target.value.replace(/[^0-9]/gi, ""),
                                },
                              });
                            }}

                          />
                            
                            </div>
                            <div style={{ color: "red" }}>{errors["phoneNumber"]}</div>
                              
</div>

<div className="fieldBox">
                            <p>Receive text alerts on top prospects.</p>
                        </div>
                        <div className="checkbox">
                            <span>
                                <input type="checkbox" id="NotificationsCheck"
                                 name={"textmeimportantnotification"} 
                                 value={fields.textmeimportantnotification || ""}
                                 onChange={(e)=>onhandleCheckBoxChange()}
                                />
                                <label for="NotificationsCheck">Text me important notifications</label>
                            </span>
                        </div>
        
                        </form>
                      
                    </div>
                
     
            <p className="skip">
              Don't know?{" "}
              <span className="sikpBtnBox">
                <Link className="skipit-btn" to="#" onClick={handleClickOpen}>
                  Skip it
                </Link>
              </span>
            </p>
          </div>
          
          <div className="continueBtnBox">
            <Link to="#" 
             onClick={handleSubmit}
            // onClick={routeNextPage}
            >
              Next
            </Link>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    onboarding: state.athlete.onBoarding.onboarding,
    allCountries: state.common.countries,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
    countryListStart: (data) => dispatch(countryListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mobile);
