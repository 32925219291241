import React, { useState, useEffect, useRef } from 'react';
import LeftSideEditProfile from './LeftSideEditProfile';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import {
  imageDownloadUrl,
  imageUploadUrl,
  websiteDefaultUrl,
} from '../../../../../config';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { MenuItem, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import { connect, useDispatch } from 'react-redux';
import { gpaListStart } from '../../../../../redux/athlete/onBoarding/gpaList';
import { getAllGPAScalesQuery } from '../../onboarding/academics/AcademicsQuery';
import moment from 'moment';
import { basicUserProfileListStart } from '../../../../../redux/athlete/dashboard/editUserProfile/basicList';
import {
  getAllSatMathQuery,
  getAllSatReadingAndWritingQuery,
  getAllPstMathQuery,
  getAllPstReadingAndWriting,
  getAllActCompositeScoresQuery,
  getAllAcademicNotesQuery,
  getAllReportCardTypeQuery,
  getAllApIbClassesQuery,
} from '../../../../common/CommonQuery';
import { satMathListStart } from '../../../../../redux/common/satMathList';
import { satReadingAndWritingListStart } from '../../../../../redux/common/satReadingAndWritingList';
import { psatMathListStart } from '../../../../../redux/common/psatMathList';
import { psatReadingAndWritingListStart } from '../../../../../redux/common/psatReadingAndWritingList';
import { actCompositeScoresListStart } from '../../../../../redux/common/actCompositeScores';
import { academicNotesListStart } from '../../../../../redux/common/allAcademicNotes';
import { saveOnboardingStart } from '../../../../../redux/athlete/onBoarding/onboarding';
import { areaOfStudyListStart } from '../../../../../redux/athlete/dashboard/colleges/areaOfStudyList';
import { getAllAreaofstudyQuery } from '../../dashboard/colleges/searchColleges/SearchCollegesQuery';
import { getAllBasicDataQuery, saveOnboardingUpdateQuery } from './BasicQuery';
import { getUserInfo, getAuthToken } from '../../../../../services/authService';
import {
  saveTestScoresQuery,
  saveGpaMappingQuery,
  saveAcademicFileQuery,
  getallTranscriptsandacadmicfielsQuery,
  getLatestTestScoreQuery,
  apIbClassesSaveQuery,
  getApIpClassDetailsQuery,
  getNcaaAndNaidQuery,
  NcaaAndNaidSaveQuery,
  getAllHonorsQuery,
  HonorsSaveQuery,
  AllApIbTakenClasses,
} from './GradesScoresQuery';
import {
  testScoreListStart,
  testScoreSaveListStart,
} from '../../../../../redux/athlete/dashboard/editUserProfile/testScores';
import { reportCardListStart } from '../../../../../redux/common/reportCard';
import { apIbClassesListStart } from '../../../../../redux/common/apIbTimePeriod';
import messagePopup from '../../../../../utils/messagePopup';
import toastr from 'toastr';
import {
  transcriptAndAcademicsaveStart,
  transcriptAndAcademicListStart,
} from '../../../../../redux/athlete/dashboard/editUserProfile/TranscriptsAndAcademic';
import warningMessagePopup from '../../../../../utils/warningMagPop';
import {
  apIpSaveListStart,
  apIpListStart,
} from '../../../../../redux/athlete/dashboard/editUserProfile/apIpclassDetails';
import {
  ncaaNaidListStart,
  ncaaNaidSaveListStart,
} from '../../../../../redux/athlete/dashboard/editUserProfile/ncaaAndNaid';
import { NumFieldSymbolArr } from '../../../../../utils/validationUtils';
import {
  honorsListStart,
  honorsSaveListStart,
} from '../../../.././../redux/athlete/dashboard/editUserProfile/academicHonors';
import ScreenSwitch from './ScreenSwitch';
import SubHeader from './SubHeader';
import GoTopButton from './GoTopButton';
import BackdropLoader from '../../../../common/Loader';
import closeGrayIcon from './assets/img/close-gray-icon.svg';
import trashCircleIcon from './assets/img/trash-circle-icon.svg';
import swal from 'sweetalert';
import { curDate } from '../../../../common/currDateCheck';
import { fetchGraphMethod } from '../../../../../services/httpService';
import history from '../../../../../services/history';
import { getAthleteSysID } from '../../../../common/getProfileURLData';
const selectStyle = {
  position: 'relative',
  fontSize: '.875rem',
  fontWeight: 400,
  color: '#3e3e3e',
  lineHeight: 1.4,
  marginBottom: '10px',
};

const Title = () => {
  const routeBackToProfile = () => {
    history?.push(
      { pathname: `${getAthleteSysID()}`, state: { isPublic: false } }
      // '/profile/1'
    );
    sessionStorage.setItem('athlete_own_profile', true);
    // window.location.href = '/profile/1';
  };
  return (
    <div className="flexRow">
      <div className="title">Grades & Scores</div>
      <div className="btnWrap">
        <button
          className="btn outlined green rounded medium backprofileBtn"
          onClick={() => routeBackToProfile()}
        >
          Back To Profile
        </button>
        <button
          className="btn outlined green rounded medium"
          onClick={() => routeBackToProfile()}
        >
          Preview Profile
        </button>
        <button
          className="btn outlined blue rounded medium"
          onClick={() => {
            sessionStorage.setItem('athlete_own_profile', false);
            setTimeout(() => {
              window.open(
                `${websiteDefaultUrl}athlete/wyatt-johnson-74144936`,
                '_blank'
              );
            }, 500);
          }}
        >
          Sample Profile
        </button>
      </div>
    </div>
  );
};

const DeleteItem = ({ onDeleteClick, onClose }) => {
  return (
    <div
      className="modal fade common-modal trash-modal show"
      id="ReferenceTrashModal"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" onClick={onClose}>
              <img src={closeGrayIcon} alt="close Gray Icon" />
            </button>
            <div className="icon-box-first">
              <img src={trashCircleIcon} alt="trash Circle Icon" />
            </div>
            <div className="content-box">
              <h4>Are you sure you want to delete this academic file? </h4>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              onClick={onDeleteClick}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn gray"
              data-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AcademicStatus = (props) => {
  const [fields, setFields] = useState({
    gpaId: '1', //change request
    gpascalevalue: '',
    gpaScale: '',
    weightedGPA: '',
    unweightedgpa: '',
    classRank1: '',
    classRank2: '',
    topRank: '',
    credits: '',
    onbordingId: '',
  });

  useEffect(() => {
    if (
      props.academicData &&
      props.academicData.data &&
      props.academicData.data.editUserProfileBasicData &&
      props.academicData.data.editUserProfileBasicData.data
    ) {
      const { onboardingData } =
        props.academicData.data.editUserProfileBasicData.data;

      const fieldT = fields;

      fieldT['gpascalevalue'] =
        onboardingData &&
        onboardingData.onboardinggpascalevaluemaprel &&
        onboardingData.onboardinggpascalevaluemaprel.Gpascales.length != ''
          ? parseInt(
              onboardingData.onboardinggpascalevaluemaprel.Gpascales[0].id
            )
          : '1'; //change request
      fieldT['unweightedgpa'] =
        onboardingData && !!onboardingData.unweightedgpa
          ? onboardingData.unweightedgpa
          : ''; //for now it is float
      fieldT['gpa'] =
        onboardingData && onboardingData.gpascale
          ? onboardingData.gpascale
          : '';
      fields['onbordingId'] = onboardingData ? onboardingData.id : 0;
      fields['classRank1'] = onboardingData
        ? onboardingData.classrank1
          ? parseInt(onboardingData.classrank1)
          : ''
        : '';
      fields['classRank2'] = onboardingData
        ? onboardingData.outof
          ? parseInt(onboardingData.outof)
          : ''
        : '';
      fields['topRank'] = onboardingData
        ? onboardingData.top
          ? parseFloat(onboardingData.top)
          : ''
        : '';
      fields['credits'] = onboardingData
        ? onboardingData.credits
          ? onboardingData.credits
          : ''
        : '';
      fieldT['weightedGPA'] = onboardingData
        ? onboardingData.weightgpa
          ? onboardingData.weightgpa === 1
            ? true
            : false
          : ''
        : '';
    }
  }, [props.academicData.data.editUserProfileBasicData.data]);

  const handleChange = (field, e) => {
    let fieldsT = fields;
    //this check is for not entering the user zero
    if (field == 'classRank1') {
      if (parseFloat(e.target.value) == 0) {
        return;
      }
    }
    //this check is for not entering the user zero
    if (field == 'classRank2') {
      if (parseFloat(e.target.value) == 0) {
        return;
      }
    }
    if (field == 'topRank') {
      if (!fields['classRank1'] || !fields['classRank2']) {
        fieldsT[field] = 0;
      } else if (field === 'classRank2') {
      } else {
        fieldsT[field] = e.target.value;
      }
    }
    if (field == 'topRank') {
      if (!fields['classRank1'] || !fields['classRank2']) {
        fieldsT[field] = 0;
      } else {
        fieldsT[field] = e.target.value;
      }
    }
    if (field == 'gpa') {
      let val = e.target.value;

      if (fieldsT.gpascalevalue == 1 && val > 5) {
        e.target.value = 5;
      }
      if (fieldsT.gpascalevalue == 2 && val > 6) {
        e.target.value = 6;
      }
      if (fieldsT.gpascalevalue == 3 && val > 100) {
        e.target.value = 100;
      }
      if (fields.gpascalevalue === 1 && parseFloat(val) > 4) {
        fieldsT['weightedGPA'] = true;
      }
      if (fields.gpascalevalue === 1 && parseFloat(val) < 4) {
        fieldsT['weightedGPA'] = false;
      }
      if (fields.gpascalevalue === 2 && val > 5) {
        fieldsT['weightedGPA'] = true;
      }
      if (fields.gpascalevalue === 2 && val < 5) {
        fieldsT['weightedGPA'] = false;
      }
      fieldsT[field] = e.target.value;
    }
    if (field == 'unweightedgpa') {
      let val = e.target.value;
      if (fields.gpascalevalue == 1 && val > 4) {
        e.target.value = 4;
      }
      if (fields.gpascalevalue == 2 && val > 5) {
        e.target.value = 5;
      }
      if (fields.gpascalevalue == 3 && val > 100) {
        e.target.value = 100;
      }
    }
    fieldsT[field] = e.target.value;
    setFields({
      ...fieldsT,
    });
    props.setErrors('');
    props.setGpaScaleError('');
    props.setGpaError('');
    props.setGpaUWError('');
    props.setCreditErrors('');
    props.setIsSaved(false);
  };

  useEffect(() => {
    props.acadmincData(fields);
  }, [fields]);

  const clearGpaValueOnChangeGpaScale = () => {
    if (fields.gpascalevalue) {
      setFields({
        ...fields,
        gpa: '',
        unweightedgpa: '',
        weightedGPA: '',
      });
    }
  };
  return (
    <div className="form-info-box">
      <div className="item">
        <h5 className="title">Academic Status</h5>
        <div className="fieldBox flexBox">
          <div className="half">
            {/*<FormLabel>*/}
            <div style={selectStyle}>
              GPA Range
              <span style={{ color: '#c0321f', fontWeight: '600' }}>*</span>
            </div>
            {/*</FormLabel>*/}
            <FormControl variant="outlined" fullWidth>
              <Select
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                value={fields.gpascalevalue || ''}
                onChange={(e) => {
                  handleChange('gpascalevalue', e);
                  handleChange('gpascale', e);
                  handleChange('gpaUWPlaceHolder', e);
                  clearGpaValueOnChangeGpaScale();
                }}
                // label="Select"
                displayEmpty
              >
                {/*earlier it was there but as 4.0 is selected by default so on discussion it is been removed*/}
                {/*<MenuItem value="">*/}
                {/*  <em>Select GPA Range</em>*/}
                {/*</MenuItem>*/}
                {props.academicData &&
                props.academicData.data &&
                props.academicData.data.gpaListDetails &&
                props.academicData.data.gpaListDetails.data ? (
                  props.academicData.data.gpaListDetails.data.map(
                    (item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.gpascalevalue}
                      </MenuItem>
                    )
                  )
                ) : (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <div className="error">{props.gpaScaleError}</div>
          </div>
          <div className={'half'}>
            <div className={'fieldBox flexBox mb-0'}>
              <div className="half">
                <label>GPA (Unweighted)</label>
                <TextField
                  placeholder={
                    fields.gpascalevalue == 1
                      ? 'ex 3.5'
                      : fields.gpascalevalue == 2
                      ? 'ex 4.5'
                      : fields.gpascalevalue == 3
                      ? 'ex 89'
                      : ''
                  }
                  variant="outlined"
                  id="gpaUnweighted"
                  type="text"
                  disabled={fields.gpascalevalue === '' ? true : false}
                  value={fields.unweightedgpa || ''}
                  fullWidth
                  onKeyDown={(e) =>
                    NumFieldSymbolArr.includes(e.key) && e.preventDefault()
                  }
                  // onMouseUp={(e)=>e.preventDefault()}
                  // onMouseDown={(e)=>e.preventDefault()}
                  onChange={(e) => {
                    handleChange('unweightedgpa', {
                      target: {
                        value: e.target.value
                          .toString()
                          .split('.')
                          .map((el, i) =>
                            i ? el.split('').slice(0, 2).join('') : el
                          )
                          .join('.'),
                      },
                    });
                  }}
                  onBlur={(e) => {
                    handleChange('unweightedgpa', e);
                  }}
                  inputProps={{
                    max: 100,
                    min: 0,
                    pattern: '/^d+(.d{0,2})?$/',
                    step: 0.01,
                  }}
                  required
                />
                <div className="error">{props.gpaUWError}</div>
              </div>
              <div className="half">
                <label>GPA (Weighted)</label>
                <TextField
                  variant="outlined"
                  id="gpascale"
                  type="text"
                  value={fields.gpa || ''}
                  fullWidth
                  onKeyDown={(e) =>
                    NumFieldSymbolArr.includes(e.key) && e.preventDefault()
                  }
                  placeholder={
                    fields.gpascalevalue == 1
                      ? 'ex 3.5'
                      : fields.gpascalevalue == 2
                      ? 'ex 4.5'
                      : fields.gpascalevalue == 3
                      ? 'ex 89'
                      : ''
                  }
                  disabled={fields.gpascalevalue === '' ? true : false}
                  onChange={(e) => {
                    handleChange('gpa', {
                      target: {
                        value: e.target.value
                          .toString()
                          .split('.')
                          .map((el, i) =>
                            i ? el.split('').slice(0, 2).join('') : el
                          )
                          .join('.'),
                      },
                    });
                  }}
                  onBlur={(e) => {
                    handleChange('gpa', e);
                  }}
                  inputProps={{
                    max: 100,
                    min: 0,
                    pattern: '/^d+(.d{0,2})?$/',
                    step: 0.01,
                  }}
                  required
                />
                <div className="error">{props.gpaError}</div>
              </div>
              {/*<div className="half">*/}
              {/*  <label className="hide-on-mobile">&nbsp;</label>*/}
              {/*  <p className="checkbox-list">*/}
              {/*    <span>*/}
              {/*      <input*/}
              {/*        name="weightedGPA"*/}
              {/*        type="checkbox"*/}
              {/*        disabled={fields.gpascalevalue === 3 ? true : false}*/}
              {/*        checked={*/}
              {/*          fields.gpascalevalue === 1 && fields.gpa > 4*/}
              {/*            ? true*/}
              {/*            : fields.gpascalevalue === 2 && fields.gpa > 5*/}
              {/*            ? true*/}
              {/*            : fields.gpascalevalue === 3*/}
              {/*            ? false*/}
              {/*            : fields.weightedGPA*/}
              {/*        }*/}
              {/*        onChange={(e) =>*/}
              {/*          handleChange("weightedGPA", {*/}
              {/*            target: { value: e.target.checked },*/}
              {/*          })*/}
              {/*        }*/}
              {/*        id="weightedGPA"*/}
              {/*      />*/}
              {/*      <label>Weighted GPA</label>*/}
              {/*    </span>*/}
              {/*  </p>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <div className="fieldBox flexBox">
          <div className="half full-on-mobile">
            <ul className="flex-inline-items">
              <li>
                <label>Class Rank</label>
                <input
                  // type="number"
                  value={fields.classRank1}
                  onKeyDown={(e) =>
                    NumFieldSymbolArr.includes(e.key) && e.preventDefault()
                  }
                  min="1"
                  onChange={(e) => {
                    handleChange('classRank1', {
                      target: {
                        value: e.target.value
                          .replace(/[^0-9]/gi, '')
                          .substr(0, 4),
                      },
                    });
                    handleChange('topRank', {
                      target: {
                        value:
                          (parseInt(fields.classRank1) /
                            parseInt(fields.classRank2)) *
                          100,
                      },
                    });
                  }}
                  placeholder="e.g. 10"
                />
              </li>
              <li className="flexbox">
                <label>Out Of</label>
                <input
                  // type="number"
                  min="1"
                  value={fields.classRank2}
                  onKeyDown={(e) =>
                    NumFieldSymbolArr.includes(e.key) && e.preventDefault()
                  }
                  onChange={(e) => {
                    handleChange('classRank2', {
                      target: {
                        value: e.target.value
                          .replace(/[^0-9]/gi, '')
                          .substr(0, 4),
                      },
                    });
                    handleChange('topRank', {
                      target: {
                        value:
                          (parseInt(fields.classRank1) /
                            parseInt(fields.classRank2)) *
                          100,
                      },
                    });
                  }}
                  placeholder="e.g. 100"
                />
                <label>=</label>
              </li>
              <li>
                <label>Top %</label>
                <input
                  type="text"
                  value={
                    fields?.topRank
                      ? !isNaN(fields.topRank) && isFinite(fields.topRank)
                        ? parseFloat(fields.topRank).toFixed(2)
                        : 0
                      : ''
                  }
                  placeholder="e.g. 10%"
                />
              </li>
            </ul>
            <div className="error">{props.errors}</div>
          </div>
        </div>
        <div className="fieldBox flexBox">
          <div className="half full-on-mobile">
            <label>College Credits</label>
            <input
              min="0"
              max="250"
              onKeyDown={(e) =>
                NumFieldSymbolArr.includes(e.key) && e.preventDefault()
              }
              value={fields.credits}
              onChange={(e) => {
                handleChange('credits', {
                  target: {
                    value: e.target.value.replace(/[^0-9]/gi, '').substr(0, 3),
                  },
                });
              }}
              placeholder="Credits"
            />
            <div className="error">{props.creditErrors}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddFileAcademic = (props) => {
  const [academicFilesFields, setAcademicFilesFields] = useState({
    fileid: '',
    description: '',
    type: '',
    allowMytracscript: true,
    fileName: '',
    selectedFile: '',
  });
  const [loadingImage, setLoadingImage] = useState(false);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (props.singleFileDetails != null && props.singleFileDetails != '') {
      const fieldT = academicFilesFields;
      fieldT['fileid'] = props.singleFileDetails.id
        ? props.singleFileDetails.id
        : '';
      fieldT['description'] = props.singleFileDetails.description
        ? props.singleFileDetails.description
        : '';
      fieldT['type'] = props.singleFileDetails.filetype
        ? parseInt(props.singleFileDetails.filetype)
        : '';
      // SPOR-989: fileavailableforallcolleges/allowMytracscript
      // doesn't appear to functionally be used anywhere
      fieldT['allowMytracscript'] =
        props.singleFileDetails &&
        props.singleFileDetails.fileavailableforallcolleges === 1
          ? true
          : false;
      fieldT['fileName'] =
        props.singleFileDetails && props.singleFileDetails.filename
          ? props.singleFileDetails.filename
          : '';
      setAcademicFilesFields({ ...fieldT });
    }
  }, [props.singleFileDetails, props.toggle]);

  const handleChange = (field, e) => {
    let fieldsT = academicFilesFields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setAcademicFilesFields({
      ...fieldsT,
    });
    setErrors({
      errorsT: '',
    });
  };
  const handleValidation = () => {
    let errorsT = {};
    let formIsValid = true;
    if (!academicFilesFields.fileName) {
      formIsValid = false;
      errorsT['fileName'] = 'File is required';
    }
    if (!academicFilesFields.type) {
      formIsValid = false;
      errorsT['type'] = 'Type is required';
    }
    if (!academicFilesFields.description) {
      formIsValid = false;
      errorsT['description'] = 'Description is required';
    }

    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
    };
  };

  const selectAcadFiles = (e) => {
    let { files } = e.target;
    const filedetails = e.target.files[0].name.split('.');
    let fileExtension = files[0].name
      .split('.')
      [filedetails?.length - 1]?.toLowerCase();
    let fileSize = parseInt(files[0].size) / (1024 * 1024);
    if (
      fileExtension == 'pdf' ||
      fileExtension == 'jpg' ||
      fileExtension == 'png' ||
      fileExtension == 'doc' ||
      fileExtension == 'docx' ||
      fileExtension == 'gif'
    ) {
      if (parseFloat(fileSize) < 10) {
        let fieldsT = academicFilesFields;
        fieldsT['selectedFile'] = files[0];
        fieldsT['fileName'] = files?.length > 0 ? files[0].name : '';
        setAcademicFilesFields({
          ...fieldsT,
        });
      } else {
        // messagePopup("", "File size not more than 10 MB.", "error");
        toastr.error('File size not more than 10 MB.', '', {
          progressBar: true,
          timeOut: 2000,
          closeButton: true,
          positionClass: 'toast-top-full-width',
          showEasing: 'swing',
          fadeIn: 40000,
          preventDuplicates: true,
        });
        setAcademicFilesFields({
          ...academicFilesFields,
          fileName: '',
        });
      }
    } else {
      // messagePopup(
      //   "",
      //   "File type must be .pdf, .doc, .docx, .jpg, .png, .gif",
      //   "error"
      // );
      toastr.error(
        'File type must be .pdf, .doc, .docx, .jpg, .png, .gif.',
        '',
        {
          progressBar: true,
          timeOut: 2000,
          closeButton: true,
          positionClass: 'toast-top-full-width',
          showEasing: 'swing',
          fadeIn: 40000,
          preventDuplicates: true,
        }
      );
    }
    setErrors(false);
  };

  const addFiles = async (e) => {
    e.preventDefault();
    const { formIsValid } = handleValidation();
    if (formIsValid) {
      let formData = new FormData();
      formData.append('file', academicFilesFields.selectedFile);
      setLoadingImage(true);
      const response =
        academicFilesFields.selectedFile != ''
          ? await axios({
              url: imageUploadUrl,
              method: 'POST',
              data: formData,
              headers: {
                access_token: getAuthToken(),
              },
            })
          : '';
      if (
        academicFilesFields.selectedFile != ''
          ? response && response.status === 200
          : true
      ) {
        setLoadingImage(false);
        const uploadedFileName =
          academicFilesFields.selectedFile != ''
            ? response && response.data.result?.files?.file[0]?.name
              ? response && response.data.result.files.file[0].name
              : ''
            : academicFilesFields.fileName;

        props.AddFileAcademic &&
          props.AddFileAcademic.data.transcriptAndAcademicsaveStart({
            query: saveAcademicFileQuery,
            variables:
              academicFilesFields.fileid == ''
                ? {
                    obj: {
                      userid: getUserInfo() ? getUserInfo().id : 0,
                      description: academicFilesFields.description,
                      filename: uploadedFileName,
                      filetype: parseInt(academicFilesFields.type),
                      // SPOR-989: fileavailableforallcolleges/allowMytracscript
                      // doesn't appear to functionally be used anywhere
                      fileavailableforallcolleges:
                        academicFilesFields.allowMytracscript == true ? 1 : 0,
                    },
                  }
                : {
                    obj: {
                      id: academicFilesFields.fileid,
                      userid: getUserInfo() ? getUserInfo().id : 0,
                      description: academicFilesFields.description,
                      filename: uploadedFileName,
                      filetype: parseInt(academicFilesFields.type),
                      // SPOR-989: fileavailableforallcolleges/allowMytracscript
                      // doesn't appear to functionally be used anywhere
                      fileavailableforallcolleges:
                        academicFilesFields.allowMytracscript == true ? 1 : 0,
                    },
                  },
            handleTranscript: () => {
              handleMessage();
              initialStats();
            },
          });
      }
    }
  };
  const initialStats = () => {
    props.closeAddFileModal();
    props.AddFileAcademic.getTranscriptAndAcademic();
    setAcademicFilesFields({
      ...academicFilesFields,
      description: '',
      type: '',
      allowMytracscript: true,
      fileName: '',
      selectedFile: '',
    });
  };

  const handleMessage = () => {
    if (academicFilesFields.fileid == '') {
      // messagePopup(
      //   "",
      //   "Transcript & Academic Files uploaded successfully.",
      //   "success"
      // );
      toastr.success('Transcript & Academic Files uploaded successfully.', '', {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: 'toast-top-full-width',
        showEasing: 'swing',
        fadeIn: 40000,
        preventDuplicates: true,
      });
    } else {
      // messagepopup changed to toastr
      toastr.success('Transcript & Academic Files Updated successfully.', '', {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: 'toast-top-full-width',
        showEasing: 'swing',
        fadeIn: 40000,
        preventDuplicates: true,
      });
    }
  };
  const closeModal = (e) => {
    e.preventDefault();
    props.closeAddFileModal(e);
  };
  return (
    <>
      <div className="fieldBox flexBox">
        <BackdropLoader open={loadingImage} />
        <div className="half">
          <label>
            Title<sup>*</sup>
          </label>
          <input
            type="text"
            value={academicFilesFields.description}
            onChange={(e) => handleChange('description', e)}
            placeholder="e.g. 12546"
          />
          <div className="error">{errors['description']}</div>
        </div>
        <div className="half">
          <label>
            Type <sup>*</sup>
          </label>
          <FormControl variant="outlined" fullWidth>
            <Select
              // labelId="demo-simple-select-outlined-label"
              // id="demo-simple-select-outlined"
              value={academicFilesFields.type || ''}
              onChange={(e) => handleChange('type', e)}
              // label="Select"
              displayEmpty
            >
              <MenuItem value="">
                <em>Select Type</em>
              </MenuItem>
              {props.AddFileAcademic &&
              props.AddFileAcademic.data &&
              props.AddFileAcademic.data.reportCardDetails &&
              props.AddFileAcademic.data.reportCardDetails.data
                ? props.AddFileAcademic.data.reportCardDetails.data.map(
                    (reportValue) => (
                      <MenuItem value={reportValue.id} key={reportValue.id}>
                        <em>{reportValue.type}</em>
                      </MenuItem>
                    )
                  )
                : null}
            </Select>
          </FormControl>
          <div className="error">{errors['type']}</div>
        </div>
      </div>
      <div className="fieldBox full">
        <label>
          Choose File <sup>*</sup>
        </label>
        <div className="file-button-box">
          <label className="btn green gradeScore" htmlFor="uploadFile">
            Choose File
            <input
              className="uploadFileGrade"
              type="file"
              id="uploadFile"
              onChange={(e) => {
                selectAcadFiles(e);
              }}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
          </label>

          <div>
            {academicFilesFields?.fileName && (
              <div className="fileName">{academicFilesFields?.fileName}</div>
            )}
            <p style={{ color: '#FDB944', marginLeft: '10px' }}>
              Maximum size of 10mb. DOC, DOCX, PDF, JPG, GIF, PNG.
            </p>
          </div>
        </div>
        <div className="error">{errors['fileName']}</div>
      </div>
      <div className="form-button-box text-right">
        {academicFilesFields && academicFilesFields.fileid ? (
          <button
            className="btn blue"
            onClick={(e) => {
              addFiles(e);
            }}
          >
            Update
          </button>
        ) : (
          <button
            className="btn blue"
            onClick={(e) => {
              addFiles(e);
            }}
          >
            Add
          </button>
        )}
        <button
          className="btn"
          onClick={(e) => {
            closeModal(e);
          }}
        >
          cancel
        </button>
      </div>
    </>
  );
};
const AcademicFiles = (props) => {
  const [addNewToggle, setAddNewToggle] = useState(false);
  const [editToggle, setEditToggle] = useState(false);
  const [singleFileData, setsingleFileData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [transcriptId, setTranscriptId] = useState();

  const onAddNewClick = (e) => {
    e.preventDefault();
    setEditToggle(false);
    setAddNewToggle(!addNewToggle);
  };
  const onEditClick = (e, singleData) => {
    e.preventDefault();
    setAddNewToggle(false);
    setEditToggle(!editToggle);
    setsingleFileData(singleData);
  };
  const onDeleteClick = (e, singleId) => {
    setTranscriptId(singleId);
    setShowDeleteModal(!showDeleteModal);
  };

  const cancleAddModal = () => {
    setAddNewToggle(false);
  };
  const cancleEditModal = () => {
    setEditToggle(false);
  };

  const removeTranscriptDetail = async (e) => {
    e.preventDefault();
    props.academicFilesData.data.transcriptAndAcademicsaveStart({
      query: saveAcademicFileQuery,
      variables: {
        obj: {
          id: transcriptId,
          active: 0,
        },
      },
      refreshFun: afterDeleteCard,
    });
  };

  const afterDeleteCard = () => {
    setShowDeleteModal(!showDeleteModal);
    // messagePopup(
    //   "",
    //   "Transcript & Academic Files deleted successfully.",
    //   "success"
    // );
    toastr.success('Transcript & Academic Files deleted successfully.', '', {
      progressBar: true,
      timeOut: 2000,
      closeButton: true,
      positionClass: 'toast-top-full-width',
      showEasing: 'swing',
      fadeIn: 40000,
      preventDuplicates: true,
    });
    props.academicFilesData.getTranscriptAndAcademic();
  };

  return (
    <div className="form-info-box">
      <div className="item">
        <h5 className="title">Transcript & Academic Files</h5>
        <div className="sub-text">
          Note: All uploaded files are publicly visible
        </div>
        <div className="fieldBox flexBox">
          <button
            className="btn green transcripts-toggle-button"
            onClick={(e) => {
              onAddNewClick(e);
            }}
          >
            Add File
          </button>
        </div>
        <div className={`address-box-wrap file ${!addNewToggle ? 'hide' : ''}`}>
          <AddFileAcademic
            toggle={addNewToggle}
            AddFileAcademic={props.academicFilesData}
            closeAddFileModal={cancleAddModal}
            singleFileDetails={null}
          />
        </div>
        <div className="academic-detail-listing">
          {props.academicFilesData &&
          props.academicFilesData.data &&
          props.academicFilesData.data.transscriptAndAcademicFile &&
          props.academicFilesData.data.transscriptAndAcademicFile.data
            ? props.academicFilesData.data.transscriptAndAcademicFile.data.Transcriptsandacadmicfiels.map(
                (fileDetails) => (
                  <div className="item-row" key={fileDetails.id}>
                    <div className="item-row-inner">
                      <div className="item">
                        {fileDetails.filetype
                          ? fileDetails.transcriptsandacadmicfielsfiletypemaprel
                              .Reportcardtypes[0].type
                          : ''}
                      </div>
                      <div className="item">
                        {moment(fileDetails.createdon).format('MMM DD, YYYY')}
                        {/*{new Date(fileDetails.createdon)*/}
                        {/*  .toDateString()*/}
                        {/*  .slice(4)}*/}
                      </div>
                      <div className="item button-wrapper">
                        <a
                          onClick={(e) => {
                            onEditClick(e, fileDetails);
                          }}
                          className="edit-button"
                          href="#javascript"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18.322"
                            height="17.666"
                            viewBox="0 0 18.322 17.666"
                          >
                            <g
                              id="Icon_feather-edit-3"
                              data-name="Icon feather-edit-3"
                              transform="translate(-3.75 -3.402)"
                            >
                              <path
                                id="Path_2240"
                                data-name="Path 2240"
                                d="M18,30h8.411"
                                transform="translate(-5.089 -9.682)"
                                fill="none"
                                stroke="#fdb944"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_2241"
                                data-name="Path 2241"
                                d="M17.116,4.9a1.982,1.982,0,0,1,2.8,2.8L8.238,19.384,4.5,20.318l.935-3.738Z"
                                transform="translate(0 0)"
                                fill="none"
                                stroke="#fdb944"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </a>
                        <a
                          onClick={(e) => {
                            onDeleteClick(e, fileDetails.id);
                          }}
                          className="delete-button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.9"
                            height="17.5"
                            viewBox="0 0 15.9 17.5"
                          >
                            <g
                              id="Icon_feather-trash-2"
                              data-name="Icon feather-trash-2"
                              transform="translate(-3.75 -2.25)"
                            >
                              <path
                                id="Path_2236"
                                data-name="Path 2236"
                                d="M4.5,9H18.9"
                                transform="translate(0 -2.8)"
                                fill="none"
                                stroke="#c0321f"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_2237"
                                data-name="Path 2237"
                                d="M18.7,6.2V17.4A1.6,1.6,0,0,1,17.1,19h-8a1.6,1.6,0,0,1-1.6-1.6V6.2m2.4,0V4.6A1.6,1.6,0,0,1,11.5,3h3.2a1.6,1.6,0,0,1,1.6,1.6V6.2"
                                transform="translate(-1.4)"
                                fill="none"
                                stroke="#c0321f"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_2238"
                                data-name="Path 2238"
                                d="M15,16.5v4.8"
                                transform="translate(-4.9 -6.3)"
                                fill="none"
                                stroke="#c0321f"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_2239"
                                data-name="Path 2239"
                                d="M21,16.5v4.8"
                                transform="translate(-7.7 -6.3)"
                                fill="none"
                                stroke="#c0321f"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </a>
                      </div>
                    </div>
                    {parseInt(fileDetails.id) == parseInt(singleFileData.id) ? (
                      <div
                        className={`address-box-wrap file ${
                          !editToggle ? 'hide' : ''
                        }`}
                      >
                        <AddFileAcademic
                          toggle={editToggle}
                          closeAddFileModal={cancleEditModal}
                          AddFileAcademic={props.academicFilesData}
                          singleFileDetails={singleFileData}
                          getTranscriptAndAcademicData={
                            props.getTranscriptAndAcademic
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                )
              )
            : null}
        </div>
      </div>
      <Dialog
        open={showDeleteModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            onDeleteClick();
          }
        }}
        className="matDialogCustom"
      >
        <DeleteItem
          onDeleteClick={removeTranscriptDetail}
          onClose={onDeleteClick}
        />
      </Dialog>
    </div>
  );
};

const TestScores = (props) => {
  const [testScores, setTestScores] = useState({
    satMathValue: '',
    satReadingAndWriting: '',
    satTestDate: '',
    satNotes: '',
    psatMathValue: '',
    psatReadingAndWriting: '',
    actCompositeScore: '',
    actTestDate: '',
    actNotes: '',
    total: '',
    psatTotalScore: '',
  });
  const [MathData, setMathData] = useState(null);

  useEffect(() => {
    if (
      props.testScoreData &&
      props.testScoreData.data &&
      props.testScoreData.data.latestTestScore &&
      props.testScoreData.data.latestTestScore.data
    ) {
      const fieldT = testScores;
      const satData =
        props.testScoreData.data.latestTestScore.data[
          props.testScoreData.data.latestTestScore.data.length - 1
        ];
      if (satData != undefined) {
        fieldT['satMathValue'] =
          satData.satmathid == null ? '' : satData.satmathid;
        fieldT['satReadingAndWriting'] =
          satData.satreadingandwritingid == null
            ? ''
            : satData.satreadingandwritingid;
        fieldT['satTestDate'] =
          satData.sattestdate == null ? '' : satData.sattestdate.split('T')[0];
        fieldT['satNotes'] = satData.satnotes == null ? '' : satData.satnotes;
        fieldT['psatMathValue'] =
          satData.psatmathid == null ? '' : satData.psatmathid;
        fieldT['psatReadingAndWriting'] =
          satData.pstreadingandwritingid == null
            ? ''
            : satData.pstreadingandwritingid;
        fieldT['actCompositeScore'] =
          satData.actcompletescoreid == null ? '' : satData.actcompletescoreid;
        fieldT['actTestDate'] =
          satData.acttestdate == null ? '' : satData.acttestdate.split('T')[0];
        fieldT['actNotes'] =
          satData.actnotesid == null ? '' : satData.actnotesid;
        fieldT['total'] =
          satData.sattotalnumber == null ? '' : satData.sattotalnumber;
        fieldT['psatTotalScore'] =
          satData.psatotalnumer == null ? '' : satData.psatotalnumer;

        setTestScores({ ...fieldT });
      }
    }
  }, [props.testScoreData.data]);

  const handleChange = (field, e) => {
    let fieldsT = testScores;

    if (field === 'satMathValue') {
      const valueObj =
        props.testScoreData &&
        props.testScoreData.data &&
        props.testScoreData.data.satMathListDetails &&
        props.testScoreData.data.satMathListDetails.data
          ? props.testScoreData.data.satMathListDetails.data.find(
              (item) => item.id === +e.target.value
            )
          : null;
      const valueObj1 =
        props.testScoreData &&
        props.testScoreData.data &&
        props.testScoreData.data.satReadingAndWritingDetails &&
        props.testScoreData.data.satReadingAndWritingDetails.data
          ? props.testScoreData.data.satReadingAndWritingDetails.data.find(
              (item) => item.id === +fieldsT['satReadingAndWriting']
            )
          : null;

      let math = 0;
      let satReadingAndWriting = 0;
      if (valueObj && valueObj !== undefined) {
        math = valueObj.mathvalue ? valueObj.mathvalue : 0;
      }
      if (valueObj1 && valueObj1 !== undefined) {
        satReadingAndWriting = valueObj1.readingandwritingvalue
          ? valueObj1.readingandwritingvalue
          : 0;
      }
      fieldsT['total'] = math + satReadingAndWriting;
    }

    if (field === 'satReadingAndWriting') {
      const valueObj1 = props.testScoreData.data.satMathListDetails
        ? props.testScoreData.data.satMathListDetails.data.find(
            (item) => item.id === +fieldsT['satMathValue']
          )
        : null;

      const valueObj = props.testScoreData.data.satReadingAndWritingDetails
        ? props.testScoreData.data.satReadingAndWritingDetails.data.find(
            (item) => item.id === +e.target.value
          )
        : null;
      if (valueObj) {
        fieldsT['total'] = valueObj1
          ? +valueObj.readingandwritingvalue + +valueObj1.mathvalue
          : valueObj.readingandwritingvalue;
      } else if (valueObj === undefined && valueObj1 === undefined) {
        fieldsT['total'] = '';
      } else if (valueObj === undefined) {
        fieldsT['total'] = valueObj1 && valueObj1.mathvalue;
      } else if (valueObj1 === undefined) {
        fieldsT['total'] = valueObj && valueObj.readingandwritingvalue;
      }
    }
    if (field === 'psatMathValue') {
      const valueObj =
        props.testScoreData &&
        props.testScoreData.data &&
        props.testScoreData.data.psatMathDetails
          ? props.testScoreData.data.psatMathDetails.data.find(
              (item) => item.id === +e.target.value
            )
          : null;

      const valueObj1 =
        props.testScoreData &&
        props.testScoreData.data &&
        props.testScoreData.data.psatReadingWritingDetails
          ? props.testScoreData.data.psatReadingWritingDetails.data.find(
              (item) => item.id === +fieldsT['psatReadingAndWriting']
            )
          : null;

      let math = 0;
      let psatreadingandwriting = 0;
      if (valueObj && valueObj !== undefined) {
        math = valueObj.psatmath ? valueObj.psatmath : 0;
      }
      if (valueObj1 && valueObj1 !== undefined) {
        psatreadingandwriting = valueObj1.psatreadingandwritingvalue
          ? valueObj1.psatreadingandwritingvalue
          : 0;
      }
      fieldsT['psatTotalScore'] = math + psatreadingandwriting;
    }
    if (field === 'psatReadingAndWriting') {
      const valueObj1 = props.testScoreData.data.psatMathDetails
        ? props.testScoreData.data.psatMathDetails.data.find(
            (item) => item.id === +fieldsT['psatMathValue']
          )
        : null;
      const valueObj = props.testScoreData.data.psatReadingWritingDetails
        ? props.testScoreData.data.psatReadingWritingDetails.data.find(
            (item) => item.id === +e.target.value
          )
        : null;
      if (valueObj) {
        fieldsT['psatTotalScore'] = valueObj1
          ? +valueObj.psatreadingandwritingvalue + +valueObj1.psatmath
          : valueObj.psatreadingandwritingvalue;
      } else if (valueObj1 === undefined && valueObj === undefined) {
        fieldsT['psatTotalScore'] = '';
      } else if (valueObj1 === undefined) {
        fieldsT['psatTotalScore'] = valueObj && valueObj.readingandwritingvalue;
      } else if (valueObj == undefined) {
        fieldsT['psatTotalScore'] = valueObj1 && valueObj1.psatmath;
      }
    }

    fieldsT[field] = e.target.value;
    setTestScores({
      ...fieldsT,
    });
    props.setIsSaved(false);
  };

  useEffect(() => {
    props.testscoreData(testScores);
  }, [testScores]);
  return (
    <div className="form-info-box">
      <div className="item">
        <h5 className="title">Test Scores</h5>
        <div className="fieldBox">
          <div className="flex-three-col-row">
            <h4>SAT</h4>
            <div className="flex-three-column">
              <div className="item">
                <div className="inner-item">
                  <label>Math</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      // labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={testScores.satMathValue || ''}
                      onChange={(e) => {
                        handleChange('satMathValue', e);
                      }}
                      displayEmpty
                      // label="Select"
                    >
                      <MenuItem value="">
                        <em>Select Range</em>
                      </MenuItem>
                      {props.testScoreData &&
                      props.testScoreData.data &&
                      props.testScoreData.data.satMathListDetails &&
                      props.testScoreData.data.satMathListDetails.data
                        ? props.testScoreData.data.satMathListDetails.data.map(
                            (satItem) => (
                              <MenuItem value={satItem.id} key={satItem.id}>
                                <em>{satItem.mathvalue}</em>
                              </MenuItem>
                            )
                          )
                        : null}
                    </Select>
                  </FormControl>
                </div>
                <div className="inner-item">
                  <label>Test Date</label>
                  <input
                    type="date"
                    value={testScores.satTestDate}
                    name="date"
                    id="date"
                    // onKeyDown={(e) => e.preventDefault()}
                    max={moment().format('YYYY-MM-DD')}
                    //this curDate fun is a check over current year $
                    onChange={(e) =>
                      curDate(e.target.value)
                        ? handleChange('satTestDate', e)
                        : ''
                    }
                  />
                </div>
              </div>
              <div className="item">
                <div className="inner-item">
                  <label>Reading & Writing</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      // labelId="demo-simple-select-outlined-label"
                      // id="demo-simple-select-outlined"
                      value={testScores.satReadingAndWriting}
                      onChange={(e) => {
                        handleChange('satReadingAndWriting', e);
                      }}
                      // label="Select"
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Select Range</em>
                      </MenuItem>
                      {props.testScoreData &&
                      props.testScoreData.data &&
                      props.testScoreData.data.satReadingAndWritingDetails &&
                      props.testScoreData.data.satReadingAndWritingDetails.data
                        ? props.testScoreData.data.satReadingAndWritingDetails.data.map(
                            (satValue) => (
                              <MenuItem value={satValue.id} key={satValue.id}>
                                <em>{satValue.readingandwritingvalue}</em>
                              </MenuItem>
                            )
                          )
                        : null}
                    </Select>
                  </FormControl>
                </div>
                <div className="inner-item">
                  <label>Notes</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      // labelId="demo-simple-select-outlined-label"
                      // id="demo-simple-select-outlined"
                      value={testScores.satNotes}
                      onChange={(e) => handleChange('satNotes', e)}
                      // label="Select"
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Select Comments</em>
                      </MenuItem>
                      {props.testScoreData &&
                      props.testScoreData.data &&
                      props.testScoreData.data.academicNotesDetails &&
                      props.testScoreData.data.academicNotesDetails.data
                        ? props.testScoreData.data.academicNotesDetails.data.map(
                            (notesValue) => (
                              <MenuItem
                                value={notesValue.id}
                                key={notesValue.id}
                              >
                                <em>{notesValue.notevalue}</em>
                              </MenuItem>
                            )
                          )
                        : null}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="item">
                <label>Total Score</label>
                <input
                  type="text"
                  value={
                    testScores.total !== ''
                      ? testScores.total.toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                        })
                      : ''
                  }
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="flex-three-col-row">
            <h4>PSAT</h4>
            <div className="flex-three-column">
              <div className="item">
                <div className="inner-item">
                  <label>Math</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      // labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={testScores.psatMathValue}
                      onChange={(e) => {
                        handleChange('psatMathValue', e);
                      }}
                      displayEmpty
                      // label="Select"
                    >
                      <MenuItem value="">
                        <em>Select Range</em>
                      </MenuItem>
                      {props.testScoreData &&
                      props.testScoreData.data &&
                      props.testScoreData.data.psatMathDetails &&
                      props.testScoreData.data.psatMathDetails.data
                        ? props.testScoreData.data.psatMathDetails.data.map(
                            (psatValue) => (
                              <MenuItem value={psatValue.id} key={psatValue.id}>
                                <em>{psatValue.psatmath}</em>
                              </MenuItem>
                            )
                          )
                        : null}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="item">
                <div className="inner-item">
                  <label>Reading & Writing</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      // labelId="demo-simple-select-outlined-label"
                      // id="demo-simple-select-outlined"
                      value={testScores.psatReadingAndWriting}
                      onChange={(e) => {
                        handleChange('psatReadingAndWriting', e);
                      }}
                      // label="Select"
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Select Range</em>
                      </MenuItem>
                      {props.testScoreData &&
                      props.testScoreData.data &&
                      props.testScoreData.data.psatReadingWritingDetails &&
                      props.testScoreData.data.psatReadingWritingDetails.data
                        ? props.testScoreData.data.psatReadingWritingDetails.data.map(
                            (psatValue) => (
                              <MenuItem value={psatValue.id} key={psatValue.id}>
                                <em>{psatValue.psatreadingandwritingvalue}</em>
                              </MenuItem>
                            )
                          )
                        : null}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="item">
                <label>Total Score</label>
                <input
                  type="text"
                  value={
                    testScores.psatTotalScore !== ''
                      ? testScores.psatTotalScore.toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                        })
                      : ''
                  }
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="flex-three-col-row">
            <h4>ACT</h4>
            <div className="flex-three-column">
              <div className="item">
                <div className="inner-item">
                  <label>ACT Composite Score</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      // labelId="demo-simple-select-outlined-label"
                      // id="demo-simple-select-outlined"
                      value={testScores.actCompositeScore}
                      onChange={(e) => handleChange('actCompositeScore', e)}
                      // label="Select"
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Select Range</em>
                      </MenuItem>
                      {props.testScoreData &&
                      props.testScoreData.data &&
                      props.testScoreData.data.actCompositeScoresDetails &&
                      props.testScoreData.data.actCompositeScoresDetails.data
                        ? props.testScoreData.data.actCompositeScoresDetails.data.map(
                            (actCompositeValue) => (
                              <MenuItem
                                value={actCompositeValue.actcompositescorevalue}
                                key={actCompositeValue.actcompositescorevalue}
                              >
                                <em>
                                  {actCompositeValue.actcompositescorevalue}
                                </em>
                              </MenuItem>
                            )
                          )
                        : null}
                    </Select>
                  </FormControl>
                </div>
                <div className="inner-item">
                  <label>Notes</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      // labelId="demo-simple-select-outlined-label"
                      // id="demo-simple-select-outlined"
                      value={testScores.actNotes}
                      onChange={(e) => handleChange('actNotes', e)}
                      // label="Select"
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Select Comments</em>
                      </MenuItem>
                      {props.testScoreData &&
                      props.testScoreData.data &&
                      props.testScoreData.data.academicNotesDetails &&
                      props.testScoreData.data.academicNotesDetails.data
                        ? props.testScoreData.data.academicNotesDetails.data.map(
                            (notesValue) => (
                              <MenuItem
                                value={notesValue.id}
                                key={notesValue.id}
                              >
                                <em>{notesValue.notevalue}</em>
                              </MenuItem>
                            )
                          )
                        : null}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="item">
                <div className="inner-item">
                  <label>Test Date</label>
                  <input
                    type="date"
                    value={testScores.actTestDate}
                    //this curDate fun is a check over current year $
                    onChange={(e) =>
                      curDate(e.target.value)
                        ? handleChange('actTestDate', e)
                        : ''
                    }
                    placeholder="mm/dd/yyyy"
                    // onKeyDown={(e) => e.preventDefault()}
                    max={moment().format('YYYY-MM-DD')}
                  />
                </div>
              </div>
              <div className="item"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HonorsClasses = (props) => {
  const [honorsClasses, setHonorsClasses] = useState({
    AreYouInHonorClasses: '',
    classdetails: '',
    honorclassestimeperiod: '',
    honorclassestotaltimeperiod: '',
  });
  const [allApIbTakenClasses_Honor, setAllApIbTakenClasses_Honor] = useState(
    []
  );
  useEffect(() => {
    if (
      props.honorsStatus &&
      props.honorsStatus.data &&
      props.honorsStatus.data.allHonorsData &&
      props.honorsStatus.data.allHonorsData.data != ''
    ) {
      const fieldT = honorsClasses;
      const honousClassDetails = props.honorsStatus.data.allHonorsData.data
        ? props.honorsStatus.data.allHonorsData.data[
            props.honorsStatus.data.allHonorsData.data.length - 1
          ]
        : null;
      if (honousClassDetails != null) {
        fieldT['AreYouInHonorClasses'] = honousClassDetails.havehonors
          ? honousClassDetails.havehonors == 1
            ? 'Yes'
            : honousClassDetails.havehonors == 2
            ? 'No'
            : ''
          : '';
        fieldT['classdetails'] = honousClassDetails?.classdetails ?? '';
        fieldT['honorclassestimeperiod'] =
          honousClassDetails?.honorclassestimeperiod ?? '';
        fieldT['honorclassestotaltimeperiod'] =
          honousClassDetails?.honorclassestotaltimeperiod ?? '';
      }
      setHonorsClasses({ ...fieldT });
    }
  }, [props.honorsStatus.data.allHonorsData]);

  useEffect(async () => {
    const res = await fetchGraphMethod(AllApIbTakenClasses, null, true);
    const {
      data: {
        data: {
          allApanibclassestotaltimeperoids: {
            Apanibclassestotaltimeperoids = [],
          } = {},
        } = {},
      } = {},
    } = res;
    setAllApIbTakenClasses_Honor(Apanibclassestotaltimeperoids);
  }, []);

  const handleChange = (field, e) => {
    let fieldsT = honorsClasses;
    fieldsT[field] = e.target.value;
    if (field == 'AreYouInHonorClasses' && e?.target?.value == 'No') {
      fieldsT['classdetails'] = '';
    }
    setHonorsClasses({
      ...fieldsT,
    });
    props.setIsSaved(false);
  };

  useEffect(() => {
    props.honorsStatusData(honorsClasses);
  }, [honorsClasses]);

  return (
    <div className="form-info-box">
      <div className="item">
        <h5 className="title">Honors Classes</h5>
        <div className="fieldBox flexBox">
          <div className="half">
            <label>Are you in honors classes?</label>
            <FormControl variant="outlined" fullWidth>
              <Select
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                value={honorsClasses?.AreYouInHonorClasses ?? ''}
                onChange={(e) => handleChange('AreYouInHonorClasses', e)}
                // label="Select"
                displayEmpty
              >
                <MenuItem value="">
                  <em>Select Option</em>
                </MenuItem>
                <MenuItem value="Yes">
                  <em>Yes</em>
                </MenuItem>
                <MenuItem value="No">
                  <em>No</em>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="half">
            <label>How many honors classes do you currently take?</label>
            <FormControl variant="outlined" fullWidth>
              <Select
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                value={honorsClasses?.honorclassestimeperiod ?? ''}
                onChange={(e) => handleChange('honorclassestimeperiod', e)}
                // label="Select"
                displayEmpty
              >
                <MenuItem value="">
                  <em>Select Option</em>
                </MenuItem>
                {props.apIbClassesData &&
                props.apIbClassesData.data &&
                props.apIbClassesData.data.apIbClassesDetails &&
                props.apIbClassesData.data.apIbClassesDetails.data &&
                props.apIbClassesData.data.apIbClassesDetails.data
                  ? props.apIbClassesData.data.apIbClassesDetails.data.map(
                      (apIbValue) => (
                        <MenuItem value={apIbValue.id} key={apIbValue.id}>
                          <em>{apIbValue.timeperiodforcources}</em>
                        </MenuItem>
                      )
                    )
                  : null}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="fieldBox flexBox">
          <>
            {honorsClasses.AreYouInHonorClasses == 'Yes' && (
              <div className="half">
                <label>honors Class Details</label>
                <textarea
                  maxLength="800"
                  placeholder="Honors Class Details (Max 800 Characters)"
                  value={honorsClasses?.classdetails ?? ''}
                  onChange={(e) => handleChange('classdetails', e)}
                ></textarea>
              </div>
            )}
            <div className="half">
              <label>How many total honors classes have you taken?</label>
              <FormControl variant="outlined" fullWidth>
                <Select
                  // labelId="demo-simple-select-outlined-label"
                  // id="demo-simple-select-outlined"
                  value={honorsClasses?.honorclassestotaltimeperiod ?? ''}
                  onChange={(e) =>
                    handleChange('honorclassestotaltimeperiod', e)
                  }
                  // label="Select"
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select Option</em>
                  </MenuItem>
                  {allApIbTakenClasses_Honor.length &&
                    allApIbTakenClasses_Honor.map((apIbValue) => (
                      <MenuItem value={apIbValue.id} key={apIbValue.id}>
                        <em>{apIbValue.totaltimeperiodforcources}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

const APIBClasses = (props) => {
  const [apIbClassesFields, setApIbClassesFields] = useState({
    areYouIn: '',
    classes: '',
    classDetails: '',
    totalClassesTaken: '',
  });
  const [allApIbTakenClasses, setAllApIbTakenClasses] = useState([]);

  useEffect(() => {
    if (
      props.apIbClassesData &&
      props.apIbClassesData.data &&
      props.apIbClassesData.data.apIpData &&
      props.apIbClassesData.data.apIpData.data
    ) {
      const fieldT = apIbClassesFields;
      const reportCardDetails =
        props.apIbClassesData &&
        props.apIbClassesData.data &&
        props.apIbClassesData.data.apIpData &&
        props.apIbClassesData.data.apIpData.data
          ? props.apIbClassesData.data.apIpData.data[
              props.apIbClassesData.data.apIpData.data.length - 1
            ]
          : null;
      if (reportCardDetails != null) {
        fieldT['classDetails'] = reportCardDetails.classdetails
          ? reportCardDetails.classdetails
          : '';
        fieldT['areYouIn'] =
          reportCardDetails && reportCardDetails.areyoutakeapbpclass
            ? reportCardDetails.areyoutakeapbpclass === 1
              ? 'Yes'
              : reportCardDetails.areyoutakeapbpclass === 2
              ? 'No'
              : null
            : '';
        fieldT['classes'] = reportCardDetails.apandibtimeperiod
          ? parseInt(reportCardDetails.apandibtimeperiod)
          : '';
        fieldT['totalClassesTaken'] = reportCardDetails.apandibTotalTimePeriod
          ? parseInt(reportCardDetails.apandibTotalTimePeriod)
          : '';

        setApIbClassesFields({ ...fieldT });
      }
    }
  }, [props.apIbClassesData.data.apIpData]);

  useEffect(() => {
    props.getAbIpDetails(apIbClassesFields);
  }, [apIbClassesFields]);

  useEffect(async () => {
    const res = await fetchGraphMethod(AllApIbTakenClasses, null, true);
    const {
      data: {
        data: {
          allApanibclassestotaltimeperoids: {
            Apanibclassestotaltimeperoids = [],
          } = {},
        } = {},
      } = {},
    } = res;
    setAllApIbTakenClasses(Apanibclassestotaltimeperoids);
  }, []);

  const handleApIBClassesChange = (field, e) => {
    let fieldsT = apIbClassesFields;
    if (field == 'areYouIn') {
      fieldsT['classDetails'] = '';
    }
    fieldsT[field] = e.target.value;
    setApIbClassesFields({
      ...fieldsT,
    });
    props.setIsSaved(false);
  };

  return (
    <div className="form-info-box">
      <div className="item">
        <h5 className="title">AP / IB Classes</h5>
        <div className="fieldBox flexBox">
          <div className="half">
            <label>Are you in AP / IB classes?</label>
            <FormControl variant="outlined" fullWidth>
              <Select
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                value={apIbClassesFields.areYouIn}
                onChange={(e) => handleApIBClassesChange('areYouIn', e)}
                // label="Select"
                displayEmpty
              >
                <MenuItem value="">
                  <em>Select Option</em>
                </MenuItem>
                <MenuItem value="Yes">
                  <em>Yes</em>
                </MenuItem>
                <MenuItem value="No">
                  <em>No</em>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="half">
            <label>How many AP / IB classes do you currently take?</label>
            <FormControl variant="outlined" fullWidth>
              <Select
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                value={apIbClassesFields.classes}
                onChange={(e) => handleApIBClassesChange('classes', e)}
                // label="Select"
                displayEmpty
              >
                <MenuItem value="">
                  <em>Select Option</em>
                </MenuItem>
                {props.apIbClassesData &&
                props.apIbClassesData.data &&
                props.apIbClassesData.data.apIbClassesDetails &&
                props.apIbClassesData.data.apIbClassesDetails.data &&
                props.apIbClassesData.data.apIbClassesDetails.data
                  ? props.apIbClassesData.data.apIbClassesDetails.data.map(
                      (apIbValue) => (
                        <MenuItem value={apIbValue.id} key={apIbValue.id}>
                          <em>{apIbValue.timeperiodforcources}</em>
                        </MenuItem>
                      )
                    )
                  : null}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="fieldBox flexBox">
          {apIbClassesFields.areYouIn != 'Yes' ? (
            <div className="half">
              <label>How many total AP / IB classes have you taken?</label>
              <FormControl variant="outlined" fullWidth>
                <Select
                  // labelId="demo-simple-select-outlined-label"
                  // id="demo-simple-select-outlined"
                  value={apIbClassesFields.totalClassesTaken}
                  onChange={(e) =>
                    handleApIBClassesChange('totalClassesTaken', e)
                  }
                  // label="Select"
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select Option</em>
                  </MenuItem>
                  {allApIbTakenClasses.length
                    ? allApIbTakenClasses.map((apIbValue) => (
                        <MenuItem value={apIbValue.id} key={apIbValue.id}>
                          <em>{apIbValue.totaltimeperiodforcources}</em>
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>
          ) : (
            ''
          )}
          {apIbClassesFields.areYouIn == 'Yes' ? (
            <>
              <div className="half">
                <label>AP / IB Class Details</label>
                <textarea
                  maxLength="800"
                  placeholder="AP / IB Class Details (Max 800 Characters)"
                  value={apIbClassesFields.classDetails}
                  onChange={(e) => handleApIBClassesChange('classDetails', e)}
                ></textarea>
              </div>
              <div className="half">
                <label>How many total AP / IB classes have you taken?</label>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    // labelId="demo-simple-select-outlined-label"
                    // id="demo-simple-select-outlined"
                    value={apIbClassesFields.totalClassesTaken}
                    onChange={(e) =>
                      handleApIBClassesChange('totalClassesTaken', e)
                    }
                    // label="Select"
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Select Option</em>
                    </MenuItem>
                    {allApIbTakenClasses.length
                      ? allApIbTakenClasses.map((apIbValue) => (
                          <MenuItem value={apIbValue.id} key={apIbValue.id}>
                            <em>{apIbValue.totaltimeperiodforcources}</em>
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

const NcaaNaidEligibility = (props) => {
  const [ncaaAndNaidFields, setNcaaAndNaidFields] = useState({
    haveYouNcaaAndNaia: '',
    ncaaNumber: '',
    naiaNuber: '',
  });
  const [isNCCAlength, setIsNCCALength] = useState({ NCAAID: '', NAIA: '' });
  useEffect(() => {
    if (
      props.ncaaAndnaidData &&
      props.ncaaAndnaidData.data &&
      props.ncaaAndnaidData.data.ncaaAndNaidDetails &&
      props.ncaaAndnaidData.data.ncaaAndNaidDetails.data != ''
    ) {
      const fieldT = ncaaAndNaidFields;
      const ncaaAndnaDetails = props.ncaaAndnaidData.data.ncaaAndNaidDetails
        .data
        ? props.ncaaAndnaidData.data.ncaaAndNaidDetails.data[
            props.ncaaAndnaidData.data.ncaaAndNaidDetails.data.length - 1
          ]
        : null;
      if (ncaaAndnaDetails != null) {
        fieldT['ncaaNumber'] = ncaaAndnaDetails.ncaanumber
          ? parseInt(ncaaAndnaDetails.ncaanumber)
          : '';
        fieldT['haveYouNcaaAndNaia'] = ncaaAndnaDetails.havencaaandnadianumber
          ? ncaaAndnaDetails.havencaaandnadianumber == 1
            ? true
            : ncaaAndnaDetails.havencaaandnadianumber == 0
            ? false
            : ''
          : '';
        fieldT['naiaNuber'] = ncaaAndnaDetails.nadianumber
          ? parseInt(ncaaAndnaDetails.nadianumber)
          : '';
      }
      setNcaaAndNaidFields({ ...fieldT });
    }
  }, [props.ncaaAndnaidData.data.ncaaAndNaidDetails]);

  const ncaaAndNaiaHandalChange = (field, e) => {
    if (e?.target?.value?.length > 10) return console.log('number restriction');
    let fieldsT = ncaaAndNaidFields;
    //this check is for toggle and empty the state when user when check box is checked
    if (field == 'haveYouNcaaAndNaia') {
      fieldsT['ncaaNumber'] = '';
      fieldsT['naiaNuber'] = '';
      setIsNCCALength({ NCAAID: '', NAIA: '' });
    }
    fieldsT[field] = e.target.value;
    setNcaaAndNaidFields({
      ...fieldsT,
    });
    const valStatus = NCAAValidation();
    props.setIsNCCA(valStatus);
    props.setIsSaved(false);
  };
  const NCAAValidation = () => {
    let status = true,
      errorT = isNCCAlength;
    if (!!ncaaAndNaidFields?.ncaaNumber) {
      if ((ncaaAndNaidFields?.ncaaNumber).toString().length != 10) {
        errorT['NCAAID'] = 'NCAA ID Should Be 10 Digit';
      } else {
        errorT['NCAAID'] = '';
      }
    } else {
      errorT['NCAAID'] = '';
    }
    if (!!ncaaAndNaidFields?.naiaNuber) {
      if (ncaaAndNaidFields?.naiaNuber.toString().length != 10) {
        errorT['NAIA'] = 'NAIA Number Should Be 10 Digit';
      } else {
        errorT['NAIA'] = '';
      }
    } else {
      errorT['NAIA'] = '';
    }
    if (!!isNCCAlength?.NAIA || !!isNCCAlength?.NCAAID) {
      setIsNCCALength(errorT);
      if (errorT['NCAAID'] == '' && errorT['NAIA'] == '') {
        status = true;
      } else {
        status = false;
      }
    }
    return status;
  };

  useEffect(() => {
    props.getNcAndNaDetails(ncaaAndNaidFields);
  }, [ncaaAndNaidFields]);

  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };
  return (
    <div className="form-info-box">
      <div className="item">
        <h5 className="title">NCAA / NAIA Eligibility Center</h5>
        <div className="fieldBox flexBox full">
          <p className="checkbox-list">
            <span>
              <input
                type="checkbox"
                checked={ncaaAndNaidFields.haveYouNcaaAndNaia}
                value={ncaaAndNaidFields.haveYouNcaaAndNaia}
                onChange={(e) => {
                  ncaaAndNaiaHandalChange('haveYouNcaaAndNaia', {
                    target: { value: e.target.checked },
                  });
                }}
              />
              <label>Do you have an NCAA or NAIA Eligibility Number?</label>
            </span>
          </p>
        </div>
        <div className="fieldBox flexBox">
          <div className="half">
            {ncaaAndNaidFields.haveYouNcaaAndNaia == true ? (
              <>
                <label>
                  NCAA ID
                  {/*this is the changes as per 15 geb doc*/}
                  {/*NCAA Eligibility Number*/}
                </label>
                <input
                  // type="number"
                  min="0"
                  placeholder="e.g. 12546"
                  value={ncaaAndNaidFields.ncaaNumber || ''}
                  onKeyDown={(e) =>
                    NumFieldSymbolArr.includes(e.key) && e.preventDefault()
                  }
                  onChange={(e) => {
                    ncaaAndNaiaHandalChange('ncaaNumber', {
                      target: {
                        value: e.target.value.replace(/[^0-9]/gi, ''),
                      },
                    });
                  }}
                />
              </>
            ) : null}
            <div className="error">
              {!!isNCCAlength?.NCAAID && isNCCAlength?.NCAAID}
            </div>
            <div className="link-text-box">
              NCAA Link -{' '}
              <a href="https://web3.ncaa.org/ecwr3/" target="_blank">
                https//web3.ncaa.org/ecwr3/
              </a>
            </div>
          </div>
          <div className="half">
            {ncaaAndNaidFields.haveYouNcaaAndNaia == true ? (
              <>
                <label>NAIA Eligibility Number</label>
                <input
                  // type="number"
                  onKeyDown={(e) =>
                    NumFieldSymbolArr.includes(e.key) && e.preventDefault()
                  }
                  min="0"
                  // pattern="[0-9]*"
                  placeholder="e.g. 12546"
                  value={ncaaAndNaidFields.naiaNuber || ''}
                  onChange={(e) => {
                    ncaaAndNaiaHandalChange('naiaNuber', {
                      target: {
                        value: e.target.value.replace(/[^0-9]/gi, ''),
                      },
                    });
                  }}
                />
              </>
            ) : null}
            <div className="error">
              {!!isNCCAlength?.NAIA && isNCCAlength?.NAIA}
            </div>
            <div className="link-text-box">
              NAIA Link -{' '}
              <a href="https://play.mynaia.org/" target="_blank">
                https//play.mynaia.org/
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Academic = (props) => {
  const [honorsAcademicClasses, setHonorsAcademicClasses] = useState({
    academicHonors: '',
    extraActivity: '',
  });

  useEffect(() => {
    if (
      props.honorsData &&
      props.honorsData.data &&
      props.honorsData.data.allHonorsData &&
      props.honorsData.data.allHonorsData.data != ''
    ) {
      const fieldT = honorsAcademicClasses;
      const honorsAcademicDetails = props.honorsData.data.allHonorsData.data
        ? props.honorsData.data.allHonorsData.data[
            props.honorsData.data.allHonorsData.data.length - 1
          ]
        : null;
      if (honorsAcademicDetails != null) {
        fieldT['academicHonors'] = honorsAcademicDetails.acadhonors
          ? honorsAcademicDetails.acadhonors
          : '';
        fieldT['extraActivity'] = honorsAcademicDetails.extraactivity
          ? honorsAcademicDetails.extraactivity
          : '';
      }
      setHonorsAcademicClasses({ ...fieldT });
    }
  }, [props.honorsData.data.allHonorsData]);

  const handleChange = (field, e) => {
    let fieldsT = honorsAcademicClasses;
    fieldsT[field] = e.target.value;
    setHonorsAcademicClasses({
      ...fieldsT,
    });
    props.setIsSaved(false);
  };

  useEffect(() => {
    props.honorsAcadData(honorsAcademicClasses);
  }, [honorsAcademicClasses]);

  return (
    <div className="form-info-box">
      <div className="item">
        <h5 className="title">Academic Accomplishments</h5>
        <div className="fieldBox flexBox">
          <div className="half">
            <label>Academic Honors</label>
            <textarea
              placeholder="Academic Honors"
              value={honorsAcademicClasses.academicHonors}
              onChange={(e) => {
                handleChange('academicHonors', e);
              }}
            ></textarea>
          </div>
          <div className="half">
            <label>Extracurricular Activities</label>
            <textarea
              placeholder="Extracurricular Activities"
              value={honorsAcademicClasses.extraActivity}
              onChange={(e) => {
                handleChange('extraActivity', e);
              }}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};
const IntendedMajor = (props) => {
  const [areaofstudyClass, setAreaofstudyClass] = useState({
    selectedAreaOfStudy: '',
  });

  useEffect(() => {
    if (
      props.areaOfStudyData &&
      props.areaOfStudyData.data &&
      props.areaOfStudyData.data.allHonorsData &&
      props.areaOfStudyData.data.allHonorsData.data != ''
    ) {
      const fieldT = areaofstudyClass;
      const areaOfStudyDetails = props.areaOfStudyData.data.allHonorsData.data
        ? props.areaOfStudyData.data.allHonorsData.data[
            props.areaOfStudyData.data.allHonorsData.data.length - 1
          ]
        : null;
      if (areaOfStudyDetails != null) {
        fieldT['selectedAreaOfStudy'] = areaOfStudyDetails.areaofstudy
          ? parseInt(areaOfStudyDetails.areaofstudy)
          : '';
      }
      setAreaofstudyClass({ ...fieldT });
    }
  }, [props.areaOfStudyData.data.allHonorsData.data]);

  const handleAreaOfStudtChange = (field, e) => {
    let fieldsT = areaofstudyClass;
    fieldsT[field] = e.target.value;
    setAreaofstudyClass({
      ...fieldsT,
    });
    props.setIsSaved(false);
  };

  useEffect(() => {
    props.areaOfStudyDetails(areaofstudyClass);
  }, [areaofstudyClass]);

  return (
    <div className="form-info-box">
      <div className="item">
        <h5 className="title">Intended Major / Area of Study</h5>
        <div className="fieldBox full">
          <FormControl variant="outlined" fullWidth>
            <Select
              // labelId="demo-simple-select-outlined-label"
              // id="demo-simple-select-outlined"
              value={areaofstudyClass.selectedAreaOfStudy}
              placeholder="Intended Major / Area of Study"
              onChange={(e) =>
                handleAreaOfStudtChange('selectedAreaOfStudy', e)
              }
              // label="Select"
              displayEmpty
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              {props.areaOfStudyData &&
              props.areaOfStudyData.data &&
              props.areaOfStudyData.data.areaOfStudyData &&
              props.areaOfStudyData.data.areaOfStudyData.data &&
              props.areaOfStudyData.data.areaOfStudyData.data
                ? props.areaOfStudyData.data.areaOfStudyData.data.map(
                    (apIbValue) => (
                      <MenuItem value={apIbValue.id} key={apIbValue.id}>
                        <em>{apIbValue.areaofstudyname}</em>
                      </MenuItem>
                    )
                  )
                : null}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

const EditSection = (props) => {
  const [academicDetails, setAcademicDetails] = useState([]);
  const [testscore, setTestScore] = useState([]);
  const [abIpData, setAbIpData] = useState([]);
  const [ncaaAndNaidDetails, setNcaaAndNaidDetails] = useState([]);
  const [honorsStatusDetails, setHonorsStatusDetails] = useState([]);
  const [honorsAcadDetails, setHonorsAcadDetails] = useState([]);
  const [aearOfDetails, setAearOfDetails] = useState([]);

  useEffect(() => {
    props.saveGradeState({
      academicDetails,
      testscore,
      abIpData,
      ncaaAndNaidDetails,
      honorsStatusDetails,
      honorsAcadDetails,
      aearOfDetails,
    });
  }, [
    academicDetails,
    testscore,
    abIpData,
    ncaaAndNaidDetails,
    honorsStatusDetails,
    honorsAcadDetails,
    aearOfDetails,
  ]);
  const updateAcademicStatus = (academicDetails) => {
    setAcademicDetails(academicDetails);
  };
  const testScore = (testScoreVale) => {
    setTestScore(testScoreVale);
  };
  const getAbIpData = (abIpValue) => {
    setAbIpData(abIpValue);
  };
  const getNcaaAndAaidData = (ncAndNaData) => {
    setNcaaAndNaidDetails(ncAndNaData);
  };
  const getHonorsSatus = (honorsValue) => {
    setHonorsStatusDetails(honorsValue);
  };
  const getHonorsAcadDetails = (honorsAcadData) => {
    setHonorsAcadDetails(honorsAcadData);
  };
  const getAreaOfStudyData = (studyData) => {
    setAearOfDetails(studyData);
  };

  const saveGradesAndScoreData = (e) => {
    e.preventDefault();
    props.onSave();
  };

  return (
    <div className="editSection">
      <Title />
      <div className="check-list-sec">
        <div className="editprofile-common-form">
          <form>
            <AcademicStatus
              academicData={props}
              acadmincData={updateAcademicStatus}
              setIsSaved={props.setIsSaved}
              errors={props.errors}
              setErrors={props.setErrors}
              gpaScaleError={props.gpaScaleError}
              setGpaScaleError={props.setGpaScaleError}
              gpaError={props.gpaError}
              setGpaError={props.setGpaError}
              gpaUWError={props.gpaUWError}
              setGpaUWError={props.setGpaUWError}
              creditErrors={props.creditErrors}
              setCreditErrors={props.setCreditErrors}
            />
            <AcademicFiles
              academicFilesData={props}
              setIsSaved={props.setIsSaved}
              getTranscriptAndAcademic={props.getTranscriptAndAcademic}
            />
            <TestScores
              testScoreData={props}
              testscoreData={testScore}
              setIsSaved={props.setIsSaved}
            />
            <HonorsClasses
              honorsStatus={props}
              apIbClassesData={props}
              honorsStatusData={getHonorsSatus}
              setIsSaved={props.setIsSaved}
            />
            <APIBClasses
              apIbClassesData={props}
              getAbIpDetails={getAbIpData}
              setIsSaved={props.setIsSaved}
            />
            <NcaaNaidEligibility
              ncaaAndnaidData={props}
              getNcAndNaDetails={getNcaaAndAaidData}
              setIsSaved={props.setIsSaved}
              setIsNCCA={props.setIsNCCA}
            />
            <Academic
              honorsData={props}
              honorsAcadData={getHonorsAcadDetails}
              setIsSaved={props.setIsSaved}
            />
            <IntendedMajor
              areaOfStudyData={props}
              areaOfStudyDetails={getAreaOfStudyData}
              setIsSaved={props.setIsSaved}
            />
            <div className="form-button-box with-next-prev">
              <button
                className="btn blue"
                onClick={(e) => saveGradesAndScoreData(e)}
              >
                Save
              </button>
              <div className="next-prev-button">
                <ScreenSwitch
                  prevLink={'/academics'}
                  nextLink={'/addtionalSports'}
                  isSaved={props.isSaved}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const GradesScores = (props) => {
  const [sideMenuOpen, setSideMenu] = useState(false);
  const [gradeScoreData, setGradeScoreData] = useState({
    academicDetails: [],
    testscore: [],
    abIpData: [],
    ncaaAndNaidDetails: [],
    honorsStatusDetails: [],
    honorsAcadDetails: [],
    aearOfDetails: [],
  });
  const [isSaved, setIsSaved] = useState(true);
  const [errors, setErrors] = useState('');
  const [gpaScaleError, setGpaScaleError] = useState('');
  const [gpaError, setGpaError] = useState('');
  const [gpaUWError, setGpaUWError] = useState('');
  const [creditErrors, setCreditErrors] = useState('');
  //this state is for NCCA/N
  const [isNCCA, setIsNCCA] = useState(true);
  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };
  const fakeInput = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    props.gpaListStart({ query: getAllGPAScalesQuery, variables: null });
    props.basicUserProfileListStart({
      query: getAllBasicDataQuery,
      variables: {
        obj: {
          id: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
    props.satMathListStart({ query: getAllSatMathQuery, variables: null });
    props.satReadingAndWritingListStart({
      query: getAllSatReadingAndWritingQuery,
      variables: null,
    });
    props.psatMathListStart({ query: getAllPstMathQuery, variables: null });
    props.psatReadingAndWritingListStart({
      query: getAllPstReadingAndWriting,
      variables: null,
    });
    props.actCompositeScoresListStart({
      query: getAllActCompositeScoresQuery,
      variables: null,
    });
    props.academicNotesListStart({
      query: getAllAcademicNotesQuery,
      variables: null,
    });

    props.reportCardListStart({
      query: getAllReportCardTypeQuery,
      variables: null,
    });

    props.apIbClassesListStart({
      query: getAllApIbClassesQuery,
      variables: null,
    });

    props.areaOfStudyListStart({
      query: getAllAreaofstudyQuery,
    });

    getApIbClassesList();
    getTestTranscript();
    getTranscriptAndAcademic();
    getHonorsAcadList();
    getNcaaAndAaidList();
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);

  //this is use when user save the input than it will scroll on the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const savegradeScoreState = (stateData) => {
    setGradeScoreData(stateData);
  };

  const getHonorsAcadList = () => {
    props.honorsListStart({
      query: getAllHonorsQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  };

  const getNcaaAndAaidList = () => {
    props.ncaaNaidListStart({
      query: getNcaaAndNaidQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  };
  const getApIbClassesList = () => {
    props.apIpListStart({
      query: getApIpClassDetailsQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  };
  const getTestTranscript = () => {
    props.testScoreListStart({
      query: getLatestTestScoreQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };
  const getTranscriptAndAcademic = () => {
    props.transcriptAndAcademicListStart({
      query: getallTranscriptsandacadmicfielsQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };
  const routeNextPage = async () => {
    getTestTranscript();
    getApIbClassesList();
    getHonorsAcadList();
    getNcaaAndAaidList();
    fakeInput.current.focus();
    fakeInput.current.select();
    const status = await swal({
      text: 'Grades And Scores updated successfully.',
      icon: 'success',
    });
    if (
      props &&
      props?.location?.state?.previousPage === 'checklist' &&
      status
    ) {
      props?.history?.push('/checklist');
      // window.location.href='/checklist'
    }
    dispatch({ type: 'RENDER_COUNT_START', payload: 1 });
    //this is for scrolling the page on top $
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 400);
  };

  const checkClassRank = (data) => {
    let flag = true;
    let rank1 = data?.academicDetails?.classRank1;
    let rank2 = data?.academicDetails?.classRank2;
    let gpaScaleValue = data?.academicDetails?.gpascalevalue;
    let gpa = data?.academicDetails?.gpa;
    let unweightedgpa = data?.academicDetails?.unweightedgpa;
    let credits = data?.academicDetails?.credits;
    if (!rank1 && rank2) {
      flag = false;
      setErrors('Please enter ClassRank1');
    }
    if (rank1 && !rank2) {
      flag = false;
      setErrors('Please enter ClassRank2');
    }
    if (parseFloat(rank1) > parseFloat(rank2)) {
      flag = false;
      setErrors('ClassRank1 should be smaller than ClassRank2 ');
    }
    if (gpaScaleValue === '') {
      flag = false;
      setGpaScaleError('Gpa Range is required');
    }
    if (credits) {
      if (parseFloat(credits) > 250) {
        flag = false;
        setCreditErrors('Please enter college credits range between 0-250');
      }
    }
    if (gpa) {
      let value = parseFloat(+gpa);
      var regexp = /^\d+(\.\d{1,2})?$/;
      // if (!value) {
      //   flag = false;
      //   setGpaError("Please enter numeric value");
      // }
      if (!regexp.test(gpa)) {
        flag = false;
        setGpaError('Please enter valid numeric value only');
      }
    }
    if (unweightedgpa) {
      let value = parseFloat(+gpa);
      var regexp = /^\d+(\.\d{1,2})?$/;
      // if (!value) {
      //   flag = false;
      //   setGpaUWError("Please enter numeric value");
      // }
      if (!regexp.test(unweightedgpa)) {
        flag = false;
        setGpaUWError('Please enter valid numeric value only');
      }
    }
    if (!!gpa && !!unweightedgpa) {
      const regexp = /^\d+(\.\d{1,2})?$/;
      if (regexp.test(unweightedgpa) && regexp.test(gpa)) {
        if (parseFloat(gpa) < parseFloat(unweightedgpa)) {
          flag = false;
          setGpaError('GPA (W) should be greater than GPA (UW)');
        }
      }
    }

    return flag;
  };

  const onSaveHandler = () => {
    let flag = checkClassRank(gradeScoreData);
    //this isNCCA check is for NCCA/NIAA Validation
    if (flag && isNCCA) {
      let {
        academicDetails,
        testscore,
        abIpData,
        ncaaAndNaidDetails,
        honorsStatusDetails,
        honorsAcadDetails,
        aearOfDetails,
      } = gradeScoreData;

      props.testScoreSaveListStart({
        query: saveTestScoresQuery,
        variables: {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            actcompletescoreid: parseInt(testscore.actCompositeScore),
            actnotesid: parseInt(testscore.actNotes),
            acttestdate: testscore.actTestDate ? testscore.actTestDate : null,
            psatmathid: parseInt(testscore.psatMathValue),
            psatotalnumer: parseFloat(testscore.psatTotalScore),
            pstreadingandwritingid: parseInt(testscore.psatReadingAndWriting),
            satmathid: parseInt(testscore.satMathValue),
            satnotes: parseInt(testscore.satNotes),
            satreadingandwritingid: parseInt(testscore.satReadingAndWriting),
            sattestdate: testscore.satTestDate ? testscore.satTestDate : null,
            sattotalnumber: parseFloat(testscore.total),
          },
        },
      });
      props.apIpSaveListStart({
        query: apIbClassesSaveQuery,
        variables: {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            classdetails: abIpData.classDetails,
            apandibTimePeriod: parseInt(abIpData.classes),
            apandibTotalTimePeriod: parseInt(abIpData.totalClassesTaken),
            areyoutakeapbpclass:
              abIpData && abIpData.areYouIn
                ? abIpData && abIpData.areYouIn === 'Yes'
                  ? 1
                  : abIpData && abIpData.areYouIn === 'No'
                  ? 2
                  : null
                : null,
          },
        },
      });
      props.ncaaNaidSaveListStart({
        query: NcaaAndNaidSaveQuery,
        variables: {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            nadianumber: ncaaAndNaidDetails.naiaNuber
              ? ncaaAndNaidDetails.naiaNuber.toString()
              : null,
            ncaanumber: ncaaAndNaidDetails.ncaaNumber
              ? ncaaAndNaidDetails.ncaaNumber.toString()
              : null,
            havencaaandnadianumber: ncaaAndNaidDetails.haveYouNcaaAndNaia
              ? ncaaAndNaidDetails.haveYouNcaaAndNaia == true
                ? 1
                : 0
              : null,
          },
        },
      });
      props.honorsSaveListStart({
        query: HonorsSaveQuery,
        variables: {
          obj: {
            userid: getUserInfo() ? getUserInfo().id : 0,
            havehonors: honorsStatusDetails.AreYouInHonorClasses
              ? honorsStatusDetails.AreYouInHonorClasses == 'Yes'
                ? 1
                : honorsStatusDetails.AreYouInHonorClasses == 'No'
                ? 2
                : null
              : null,
            areaofstudy: aearOfDetails.selectedAreaOfStudy
              ? parseInt(aearOfDetails.selectedAreaOfStudy)
              : null,
            acadhonors: honorsAcadDetails.academicHonors
              ? honorsAcadDetails.academicHonors
              : null,
            extraactivity: honorsAcadDetails.extraActivity
              ? honorsAcadDetails.extraActivity
              : null,
            classdetails:
              honorsStatusDetails.AreYouInHonorClasses == 'Yes'
                ? honorsStatusDetails?.classdetails ?? ''
                : '',
            honorclassestimeperiod:
              honorsStatusDetails?.honorclassestimeperiod || null,
            honorclassestotaltimeperiod:
              honorsStatusDetails?.honorclassestotaltimeperiod || null,
          },
        },
      });
      props.saveOnboardingStart({
        query: saveOnboardingUpdateQuery,
        variables: {
          obj: {
            id: parseInt(academicDetails.onbordingId),
            gpascalevalue: parseInt(academicDetails.gpascalevalue),
            gpascale: academicDetails.gpa,
            weightgpa: academicDetails.weightedGPA == true ? 1 : 0,
            unweightedgpa: !!academicDetails?.unweightedgpa
              ? academicDetails?.unweightedgpa
              : '', //new changes
            credits: academicDetails.credits,
            classrank1: parseFloat(academicDetails.classRank1),
            outof: parseFloat(academicDetails.classRank2),
            top: parseFloat(academicDetails.topRank),
          },
        },
        nextScreen: routeNextPage,
      });
    } else {
      fakeInput.current.focus();
      fakeInput.current.select();
    }
    setIsSaved(true);
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };

  return (
    <div className="pageContent">
      <div className="editProfile">
        <input className="fakeInput" type="text" ref={fakeInput} />
        <BackdropLoader
          open={
            props.latestTestScore.loading ||
            props.areaOfStudyData.loading ||
            props.transscriptAndAcademicFile.loading ||
            props.actCompositeScoresDetails.loading ||
            props.psatMathDetails.loading
          }
        />
        <div className="container">
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? 'inactive' : ''}`}
          >
            <SubHeader hamburger={onSideMenuClick} onSave={onSaveHandler} />
            <div className="sidebar">
              <LeftSideEditProfile isSaved={isSaved} />
            </div>
            <EditSection
              data={props}
              saveGradeState={savegradeScoreState}
              onSave={onSaveHandler}
              isSaved={isSaved}
              setIsSaved={setIsSaved}
              getTranscriptAndAcademic={getTranscriptAndAcademic}
              errors={errors}
              setErrors={setErrors}
              gpaScaleError={gpaScaleError}
              setGpaScaleError={setGpaScaleError}
              gpaError={gpaError}
              gpaUWError={gpaUWError}
              setGpaUWError={setGpaUWError}
              setGpaError={setGpaError}
              creditErrors={creditErrors}
              setCreditErrors={setCreditErrors}
              setIsNCCA={setIsNCCA}
            />
          </div>
          <GoTopButton goTop={goTop} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    gpaListDetails: state.athlete.onBoarding.gpaList,
    satMathListDetails: state.common.satMathList,
    satReadingAndWritingDetails: state.common.satReadingAndWritingList,
    psatMathDetails: state.common.psatMathList,
    psatReadingWritingDetails: state.common.psatReadingAndWritingList,
    actCompositeScoresDetails: state.common.actCompositeScoresList,
    academicNotesDetails: state.common.academicNotesList,
    onboarding: state.athlete.onBoarding.onboarding,
    reportCardDetails: state.common.reportCardList,
    apIbClassesDetails: state.common.apIbClassesTimePeroidList,
    editUserProfileBasicData:
      state.athlete.editUserProfileData.basicUserProfile,
    transscriptAndAcademicFile:
      state.athlete.editUserProfileData.TranscriptsAndAcademic,
    latestTestScore: state.athlete.editUserProfileData.testScores,
    apIpData: state.athlete.editUserProfileData.apIp,
    ncaaAndNaidDetails: state.athlete.editUserProfileData.ncaaAndNaid,
    areaOfStudyData: state.athlete.dashboard.colleges.areOfStudyList,
    allHonorsData: state.athlete.editUserProfileData.honorsData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    gpaListStart: (data) => dispatch(gpaListStart(data)),
    satMathListStart: (data) => dispatch(satMathListStart(data)),
    satReadingAndWritingListStart: (data) =>
      dispatch(satReadingAndWritingListStart(data)),
    psatMathListStart: (data) => dispatch(psatMathListStart(data)),
    psatReadingAndWritingListStart: (data) =>
      dispatch(psatReadingAndWritingListStart(data)),
    actCompositeScoresListStart: (data) =>
      dispatch(actCompositeScoresListStart(data)),
    academicNotesListStart: (data) => dispatch(academicNotesListStart(data)),
    apIbClassesListStart: (data) => dispatch(apIbClassesListStart(data)),
    basicUserProfileListStart: (data) =>
      dispatch(basicUserProfileListStart(data)),
    reportCardListStart: (data) => dispatch(reportCardListStart(data)),
    testScoreListStart: (data) => dispatch(testScoreListStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
    transcriptAndAcademicsaveStart: (data) =>
      dispatch(transcriptAndAcademicsaveStart(data)),
    transcriptAndAcademicListStart: (data) =>
      dispatch(transcriptAndAcademicListStart(data)),
    testScoreSaveListStart: (data) => dispatch(testScoreSaveListStart(data)),
    apIpSaveListStart: (data) => dispatch(apIpSaveListStart(data)),
    apIpListStart: (data) => dispatch(apIpListStart(data)),
    ncaaNaidListStart: (data) => dispatch(ncaaNaidListStart(data)),
    ncaaNaidSaveListStart: (data) => dispatch(ncaaNaidSaveListStart(data)),
    areaOfStudyListStart: (data) => dispatch(areaOfStudyListStart(data)),
    honorsListStart: (data) => dispatch(honorsListStart(data)),
    honorsSaveListStart: (data) => dispatch(honorsSaveListStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GradesScores);
