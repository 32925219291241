export const getUpgradePlansQuery = `
query allSubscriptionplans($where: JSON){
  allSubscriptionplans(where: $where){
    Subscriptionplans{
      id
      plantype
      planname
      monthlyprice
      yearlyprice
      description
      shortdescription
      plansectiontype
      subscriptionplanfeaturesmappingplanidMap{
        Subscriptionplanfeaturesmappings{
          availability
          subscriptionplanfeaturesmappingfeatureidmaprel{
            Subscriptionfeatures{
              id
              featurename
            }
          }
        }
      }
      
     subscriptionplanincludessubscriptionplanidMap{
      Subscriptionplanincludes{
        id
        includeplanname
      }
    }
    }
  }
}
`
export const getAllSubscriptionFeatures = `
query allSubscriptionfeatures{
  allSubscriptionfeatures{
    Subscriptionfeatures{
      featurename
    }
  }
}
`

export const getAllPhotosVideosQuery = ` 
query allUploadedvideoandphotos($where : JSON){
  allUploadedvideoandphotos(where: $where){
    Uploadedvideoandphotos{
      id
      photosname
      videoname
      videophotosuploadedtype
      videolink
      videodescription
    }
  }
}
`
