import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_SEASON_STATS_LIST_START: "ALL_SEASON_STATS_LIST_START",
  ALL_SEASON_STATS_LIST_SAVE: "ALL_SEASON_STATS_LIST_SAVE",
  ALL_SEASON_STATS_LIST_FAIL: "ALL_SEASON_STATS_LIST_FAIL",
  ALL_SEASON_STATS_LIST_SUCCESS: "ALL_SEASON_STATS_LIST_SUCCESS",
};

// ============================================ Actions ========================================================
export const allSeasonStatsListStart = (data) => ({
  type: actionType.ALL_SEASON_STATS_LIST_START,
  payload: data,
});
export const allSeasonStatsListSave = (data) => ({
  type: actionType.ALL_SEASON_STATS_LIST_SAVE,
  payload: data,
});

export const allSeasonStatsListSuccess = (data) => ({
  type: actionType.ALL_SEASON_STATS_LIST_SUCCESS,
  payload: data,
});

export const allSeasonStatsListFail = (data) => ({
  type: actionType.ALL_SEASON_STATS_LIST_FAIL,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allSeasonStatsLIstReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ALL_SEASON_STATS_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_SEASON_STATS_LIST_SAVE:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_SEASON_STATS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case actionType.ALL_SEASON_STATS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allSeasonStatsListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allSeasonStatsListSuccess({
          allSeasonList: response.data.data,
        })
      );
    } else {
      messagePopup("", "Stats List failed", "error");
      yield put(allSeasonStatsListFail("Stats List failed"));
    }
  } catch (error) {
    messagePopup("", "Stats list failed", "error");
    yield put(allSeasonStatsListFail("Stats List failed"));
  }
}
export function* allSeasonStatsSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allSeasonStatsListSuccess({
          saveSeasonData: response.data.data,
        })
      );
      if (action.payload.afterSaveSeasonFieldsStats) action.payload.afterSaveSeasonFieldsStats();
    } else {
      messagePopup("", "Stats List failed", "error");
      yield put(allSeasonStatsListFail("Stats List failed"));
    }
  } catch (error) {
    messagePopup("", "Stats list failed", "error");
    yield put(allSeasonStatsListFail("Stats List failed"));
  }
}

//Watcher
export function* watchallSeasonStatsListSaga() {
  yield takeEvery(
    actionType.ALL_SEASON_STATS_LIST_START,
    allSeasonStatsListSaga
  );
  yield takeEvery(
    actionType.ALL_SEASON_STATS_LIST_SAVE,
    allSeasonStatsSaveSaga
  );
}
