import React, { useEffect, useState} from 'react'
import ClaimProfileHeader from "../auth/claimProfile/claimProfileStep1/ClaimProfileHeader";
import DashboardHeader from "../../layouts/header/DashboardHeader";
import {getAuthToken} from "../../../services/authService";
import NAIAIMG from './assets/img/naia-logo.png'
import NCAAIMG from './assets/img/ncaa-logo.png'
import NJCAAIMG from './assets/img/njcaa-logo.png'
import CCCAA from './assets/img/california-community-college-athletic-association-logo.png'
import CSFT from './assets/img/cal-state-fullerton-titans-logo.png'
import SSI from './assets/img/success-stories-img-01.png'
import SCYG from './assets/img/show-coaches-your-game-img.png'
import TT from './assets/img/total-transparency-img.png'
import FTRF from './assets/img/find-the-right-fit-img.png'
import AVT from './assets/img/athlete-video-thumbnail.png'
import OwlCarousel from "react-owl-carousel";
import './assets/css/lading-page.scss'
import {fetchMethod} from "../../../services/httpService";

const helpSlide = {
    loop: true,
    nav: true,
    dots: false,
    items: 1,
};


const ClubLP = (props) =>{
    const [headerType, setHeaderType] = useState(true),
          [apiData, setApiData] = useState({}),
          [faqIdx, setFaqIdx] = useState(0),
        isToken = props?.match?.params?.isToken == 1 ? true : false;
    useEffect(()=>{
        getStatus(props)
        //for scrolling the page on top
        setTimeout(()=>{
            window.scrollTo(0,0)
        },1000)
        //api call for data
        apiCall()
    },[])
    const getStatus = (props) =>{
        if (!!getAuthToken() || isToken){
            setHeaderType(false)
        }
    }
    const apiCall = async () =>{
        const res = await fetchMethod('/api/landingpages/getlandingpagesclubteams',null,'GET',false)
        if(res?.status == 200 && res?.data?.response?.status == 200){
            const data = res?.data?.response?.data ?? {}
            setApiData(data)
        }else{
            setApiData({})
        }
    }
    return(
        <>
            {
                headerType ?
                    <ClaimProfileHeader/>
                    :
                    <DashboardHeader/>
            }
            <section className="banner-lp-sec">
                <div className="container">
                    <div className="banner-flexbox">
                        <div className="banner-content">
                            <h1 className="title">Anti-Recruiting <br/>Service</h1>
                            <div className="short-text">
                                <p>
                                    {apiData?.antirecruitingservice?.content ?? ""}
                                    {/*Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an*/}
                                    {/*unknown printer took a galley of type and scrambled it to make a type specimen*/}
                                    {/*book.*/}
                                </p>
                            </div>
                            <div className="button-wrap">
                                <a className="btn" href="#">Join Waiting List</a>
                            </div>
                        </div>
                        <div className="video-box-wrap">
                            <div className="video-img">
                                <img src={AVT} alt=""/>
                                <div className="video-play-button">
                                    <div className="video-play-inner">
                                        <button className="play-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.191" height="22.92"
                                                 viewBox="0 0 20.191 22.92">
                                                <path id="play"
                                                      d="M9.086,27.982a2.255,2.255,0,0,1-1.1-.291,2.442,2.442,0,0,1-1.228-2.138V7.492A2.442,2.442,0,0,1,7.982,5.353a2.242,2.242,0,0,1,2.257.028l15.641,9.24a2.226,2.226,0,0,1,0,3.8L10.236,27.663a2.262,2.262,0,0,1-1.151.319Z"
                                                      transform="translate(-6.754 -5.063)" fill="#c0321f"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="collage-coache-sec">
                <div className="container">
                    <div className="top-block">
                        <h2 className="title">Empower Your Student-Athletes with a <br/>Next Generation Platform</h2>
                        <p>
                            {apiData?.nextgenplatformArr?.[0]?.content ?? ""}
                            {/*Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown*/}
                            {/*printer took a galley of type and scrambled it to make a type specimen book.*/}
                        </p>
                    </div>
                    <div className="collage-coach-list">
                        <div className="item">
                            <div className="detail-box">
                                <div className="info-box">
                                    <div className="icon-box">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="43.5" height="43.5"
                                             viewBox="0 0 43.5 43.5">
                                            <path id="_005-video-tutorial" data-name="005-video-tutorial"
                                                  d="M29.667,13.01,16.761,8.2a1.641,1.641,0,0,0-2.214,1.537v9.625A1.642,1.642,0,0,0,16.761,20.9l12.906-4.813A1.642,1.642,0,0,0,29.667,13.01ZM17.828,17V12.1l6.57,2.45ZM37.078,0H4.922A4.927,4.927,0,0,0,0,4.922v19.25a4.927,4.927,0,0,0,4.922,4.922H37.078A4.927,4.927,0,0,0,42,24.172V4.922A4.927,4.927,0,0,0,37.078,0Zm1.641,24.172a1.642,1.642,0,0,1-1.641,1.641H4.922a1.642,1.642,0,0,1-1.641-1.641V4.922A1.642,1.642,0,0,1,4.922,3.281H37.078a1.642,1.642,0,0,1,1.641,1.641Zm-1.586,11.32h-5.1a4.867,4.867,0,0,0-9.164,0h-18a1.641,1.641,0,0,0,0,3.281h18a4.867,4.867,0,0,0,9.164,0h5.1a1.641,1.641,0,0,0,0-3.281Zm-9.68,3.227a1.586,1.586,0,1,1,1.586-1.586A1.588,1.588,0,0,1,27.453,38.719Z"
                                                  transform="translate(0.75 0.75)" stroke="#e5f8ff"
                                                  strokeWidth="1.5"></path>
                                        </svg>
                                    </div>
                                    Stay Organized
                                </div>
                                <p>{apiData?.nextgenplatformArr?.[1]?.content ?? ""}
                                    {/*Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when*/}
                                    {/*an*/}
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="detail-box">
                                <div className="info-box">
                                    <div className="icon-box">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="42.712" height="42.712"
                                             viewBox="0 0 42.712 42.712">
                                            <path id="_001-send" data-name="001-send"
                                                  d="M41.64.361A1.231,1.231,0,0,0,40.416.052L2.773,11.345a3.869,3.869,0,0,0,.017,7.427l15.9,4.542,4.542,15.9a3.869,3.869,0,0,0,7.427.017L41.948,1.584A1.23,1.23,0,0,0,41.64.361ZM2.461,15.072A1.422,1.422,0,0,1,3.48,13.7l32.968-9.89L19.324,20.936,3.466,16.405a1.392,1.392,0,0,1-1.005-1.333ZM28.3,38.52a1.422,1.422,0,0,1-1.371,1.019,1.392,1.392,0,0,1-1.332-1.005L21.064,22.676,38.189,5.552Z"
                                                  transform="translate(0.35 0.361)" stroke="#fff8ec"
                                                  strokeWidth="0.7"/>
                                        </svg>
                                    </div>
                                    Showcase your athletes
                                </div>
                                <p>
                                    {apiData?.nextgenplatformArr?.[2]?.content ?? ""}
                                    {/*Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when*/}
                                    {/*an*/}
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="detail-box">
                                <div className="info-box">
                                    <div className="icon-box">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42"
                                             viewBox="0 0 42 42">
                                            <g id="_002-search" data-name="002-search" transform="translate(0 0)">
                                                <path id="Path_83970" data-name="Path 83970"
                                                      d="M16.534,30.067a13.533,13.533,0,0,0,9.57-23.1A13.534,13.534,0,1,0,6.964,26.1,13.447,13.447,0,0,0,16.534,30.067ZM7.833,7.833a12.3,12.3,0,1,1-3.6,8.7,12.225,12.225,0,0,1,3.6-8.7Z"
                                                      transform="translate(-0.034 -0.033)"/>
                                                <path id="Path_83971" data-name="Path 83971"
                                                      d="M16.406,32.811a16.279,16.279,0,0,0,10.123-3.5l2.078,2.078-.464.464a.656.656,0,0,0,0,.928L36.4,41.039a3.281,3.281,0,1,0,4.64-4.638h0l-8.256-8.258a.656.656,0,0,0-.928,0l-.465.465-2.078-2.078a16.4,16.4,0,1,0-12.906,6.283Zm23.7,7.3a2.014,2.014,0,0,1-2.783,0l-7.792-7.792L30,31.856h0L31.852,30l0,0,0,0,.462-.463,7.792,7.794h0a1.966,1.966,0,0,1,0,2.783ZM30.462,29.535l-.927.929-2-2c.16-.148.32-.3.476-.452s.3-.315.452-.476ZM5.733,5.733A15.094,15.094,0,1,1,27.079,27.078,15.094,15.094,0,0,1,5.733,5.733Z"/>
                                                <path id="Path_83972" data-name="Path 83972"
                                                      d="M13.611,26.572a1.983,1.983,0,0,0,1.98,1.98h7.92a1.984,1.984,0,0,0,1.981-1.981V21.988l3.3-1.1v3.043a.66.66,0,1,0,1.32,0V19.972h0s0,0,0,0a.672.672,0,0,0-.046-.227.685.685,0,0,0-.038-.073.615.615,0,0,0-.083-.123.666.666,0,0,0-.064-.065.646.646,0,0,0-.117-.079.59.59,0,0,0-.084-.043c-.007,0-.013-.009-.02-.011l-9.9-3.3a.665.665,0,0,0-.418,0l-9.9,3.3a.659.659,0,0,0,0,1.251l4.169,1.389v4.585Zm10.561,0a.662.662,0,0,1-.659.661H15.591a.66.66,0,0,1-.66-.66V22.428L19.343,23.9a.651.651,0,0,0,.417,0l4.412-1.471Zm-4.621-9.2L25.38,19.31H19.551a.66.66,0,1,0,0,1.32h5.837l-5.837,1.946-7.813-2.6Z"
                                                      transform="translate(-3.056 -5.53)"/>
                                            </g>
                                        </svg>
                                    </div>
                                    Unlimited video for your athletes
                                </div>
                                <p>
                                    {apiData?.nextgenplatformArr?.[3]?.content ?? ""}
                                    {/*Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when*/}
                                    {/*an*/}
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="detail-box">
                                <div className="info-box">
                                    <div className="icon-box">
                                        <svg id="_003-target" data-name="003-target" xmlns="http://www.w3.org/2000/svg"
                                             width="42" height="42" viewBox="0 0 42 42">
                                            <path id="Path_83973" data-name="Path 83973"
                                                  d="M15.61,21.331a14.3,14.3,0,0,1-7.373-2.189A2.576,2.576,0,0,1,7,16.929V11.861a.861.861,0,0,1,1.722,0v5.068a.863.863,0,0,0,.394.735,11.763,11.763,0,0,0,12.985,0,.86.86,0,0,0,.4-.735V11.861a.861.861,0,0,1,1.722,0v5.068a2.576,2.576,0,0,1-1.236,2.214A14.3,14.3,0,0,1,15.61,21.331Z"
                                                  transform="translate(5.39 8.353)"/>
                                            <path id="Path_83974" data-name="Path 83974"
                                                  d="M17.39,19.054a.907.907,0,0,1-.372-.079L5.513,13.809a.852.852,0,0,1,0-1.563L17.019,7.08a.9.9,0,0,1,.743,0l11.505,5.166a.852.852,0,0,1,0,1.563L17.762,18.975a.907.907,0,0,1-.372.079ZM8,13.027l9.394,4.217,9.394-4.217L17.39,8.81Z"
                                                  transform="translate(3.61 5.331)"/>
                                            <path id="Path_83975" data-name="Path 83975"
                                                  d="M19.917,38.334A18.417,18.417,0,1,1,38.334,19.917,18.437,18.437,0,0,1,19.917,38.334Zm0-35.08A16.663,16.663,0,1,0,36.58,19.917,16.683,16.683,0,0,0,19.917,3.254Z"
                                                  transform="translate(1.083 1.083)"/>
                                            <path id="Path_83976" data-name="Path 83976"
                                                  d="M12.361,6.888a.861.861,0,0,1-.861-.861V.861a.861.861,0,0,1,1.722,0V6.027A.861.861,0,0,1,12.361,6.888Z"
                                                  transform="translate(8.639)"/>
                                            <path id="Path_83977" data-name="Path 83977"
                                                  d="M12.361,26.888a.861.861,0,0,1-.861-.861V20.861a.861.861,0,1,1,1.722,0v5.166A.861.861,0,0,1,12.361,26.888Z"
                                                  transform="translate(8.639 15.112)"/>
                                            <path id="Path_83978" data-name="Path 83978"
                                                  d="M26.027,13.222H20.861a.861.861,0,1,1,0-1.722h5.166a.861.861,0,0,1,0,1.722Z"
                                                  transform="translate(15.112 8.639)"/>
                                            <path id="Path_83979" data-name="Path 83979"
                                                  d="M6.027,13.222H.861a.861.861,0,0,1,0-1.722H6.027a.861.861,0,0,1,0,1.722Z"
                                                  transform="translate(0 8.639)"/>
                                        </svg>
                                    </div>
                                    Total Transparency
                                </div>
                                <p>
                                    {apiData?.nextgenplatformArr?.[4]?.content ?? ""}
                                    {/*Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when*/}
                                    {/*an*/}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-started-sec">
                <div className="container">
                    <h3 className="sec-title">Ready to take your club to the next level?</h3>
                    <div className="short-text">
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                    <div className="button-wrap">
                        <a className="btn" href="#">Join waiting list</a>
                    </div>
                </div>
            </section>

            <section className="athlete-common-sec">
                <div className="container">
                    <div className="common-listing">
                        <div className="item-row">
                            <div className="content-box">
                                <div className="top-block">
                                    <div className="tagline">ADVANCED SCHOOL SEARCH</div>
                                    <h3 className="title">Find the Right Fit</h3>
                                </div>
                                <div className="detail-text">
                                    <p>
                                        {apiData?.rightfitArr?.[0]?.content ?? ""}
                                        {/*Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,*/}
                                        {/*when an unknown*/}
                                    </p>
                                    <ul>
                                        {apiData?.rightfitArr?.slice(1,4)?.map(el =><li>{el?.content ?? ""}</li>)}
                                        {/*<li>Search by location, zip, division, conference, etc.</li>*/}
                                        {/*<li>Filter schools by majors offered.</li>*/}
                                        {/*<li>Every college and university at your fingertips.</li>*/}
                                    </ul>
                                    <div className="button-wrap">
                                        <button className="btn blue">Join Waiting List</button>
                                    </div>
                                </div>
                            </div>
                            <div className="img-box">
                                <img src={FTRF} alt=""/>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="content-box">
                                <div className="top-block">
                                    <div className="tagline">ACTIVITY FEED + NOTIFICATIONS</div>
                                    <h3 className="title">Total Transparency</h3>
                                </div>
                                <div className="detail-text">
                                    <p>
                                        {apiData?.totaltransparency?.[0]?.content ?? ""}
                                        {/*Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,*/}
                                        {/*when an unknown printer took a galley of type and scrambled it to make a type*/}
                                        {/*specimen book.*/}
                                    </p>
                                    <ul>
                                        {apiData?.totaltransparency?.slice(1,4)?.map(el =><li>{el?.content ?? ""}</li>)}
                                        {/*<li>Lorem been the industry's standard dummy</li>*/}
                                        {/*<li>Lorem been the industry's standard dummy</li>*/}
                                        {/*<li>Lorem been the industry's standard dummy</li>*/}
                                    </ul>
                                    <div className="button-wrap">
                                        <button className="btn blue">Join Waiting List</button>
                                    </div>
                                </div>
                            </div>
                            <div className="img-box">
                                <img src={TT} alt="TT"/>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="content-box">
                                <div className="top-block">
                                    <div className="tagline">VIDEO + HIGHLIGHT REELS</div>
                                    <h3 className="title">Unlimited Video for Your Athletes</h3>
                                </div>
                                <div className="detail-text">
                                    <p>
                                        {apiData?.unlimitedvideo?.[0]?.content ?? ""}
                                        {/*Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,*/}
                                        {/*when an unknown printer took a galley of type and scrambled it to make a type*/}
                                        {/*specimen book.*/}
                                    </p>
                                    <ul>
                                        {apiData?.unlimitedvideo?.slice(1,4)?.map(el =><li>{el?.content ?? ""}</li>)}
                                        {/*<li>Lorem been the industry's standard dummy</li>*/}
                                        {/*<li>Lorem Ipsum has been the industry</li>*/}
                                        {/*<li>Ipsum has been the industry's dummy</li>*/}
                                    </ul>
                                    <div className="button-wrap">
                                        <button className="btn blue">Join Waiting List</button>
                                    </div>
                                </div>
                            </div>
                            <div className="img-box">
                                <img src={SCYG} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="success-stories-sec">
                <div className="container">
                    <div className="sec-title">Success Stories</div>
                    <OwlCarousel className={'success-stories-list success-stories-slide owl-carousel owl-theme'}
                        {...helpSlide}
                    >
                        <div className="item">
                            <div className="pick-box">
                                <img className="pick-img" src={SSI} alt=""/>
                                <span className="icon-box">
                            <img src={CSFT} alt=""/>
                        </span>
                            </div>
                            <div className="detail-info-box">
                                <div className="short-text">
                                    <p>I highly recommend getting involved with Andrew and his talented team as early on
                                        in the process as you can. It will save you a lot of frustration and money in
                                        the long run. His team is very knowledgeable and they can help in many ways. It
                                        was money well spent and we thank you guys for everything you have done for our
                                        son as well as our family. Jenna is next!" - Parents of Austin Ruiz</p>
                                </div>
                                <div className="info-box">
                                    <h4>Austin Ruiz</h4>
                                    <p>Azusa Pacific University</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="pick-box">
                                <img className="pick-img" src={SSI} alt=""/>
                                <span className="icon-box">
                            <img src={CSFT} alt=""/>
                        </span>
                            </div>
                            <div className="detail-info-box">
                                <div className="short-text">
                                    <p>I highly recommend getting involved with Andrew and his talented team as early on
                                        in the process as you can. It will save you a lot of frustration and money in
                                        the long run. His team is very knowledgeable and they can help in many ways. It
                                        was money well spent and we thank you guys for everything you have done for our
                                        son as well as our family. Jenna is next!" - Parents of Austin Ruiz</p>
                                </div>
                                <div className="info-box">
                                    <h4>Austin Ruiz</h4>
                                    <p>Azusa Pacific University</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="pick-box">
                                <img className="pick-img" src={SSI} alt=""/>
                                <span className="icon-box">
                            <img src={CSFT} alt=""/>
                        </span>
                            </div>
                            <div className="detail-info-box">
                                <div className="short-text">
                                    <p>I highly recommend getting involved with Andrew and his talented team as early on
                                        in the process as you can. It will save you a lot of frustration and money in
                                        the long run. His team is very knowledgeable and they can help in many ways. It
                                        was money well spent and we thank you guys for everything you have done for our
                                        son as well as our family. Jenna is next!" - Parents of Austin Ruiz</p>
                                </div>
                                <div className="info-box">
                                    <h4>Austin Ruiz</h4>
                                    <p>Azusa Pacific University</p>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                    {/*<div className="success-stories-list success-stories-slide owl-carousel owl-theme">*/}
                    {/*</div>*/}
                </div>
            </section>

            <section className="athlete-faq-sec">
                <div className="container">
                    <div className="faq-box-wrapper">
                        <h1 className="sec-title">Frequently Asked Questions</h1>
                        <ul className="faq-listing">
                            {apiData?.Faq?.length > 0 ?
                                apiData?.Faq?.map((faqItem, idx)=>{
                                    return <li className={idx == faqIdx ? "active" : ""}>
                                        <div className={idx == faqIdx ?  "faq-title active" : "faq-title"}
                                        onClick={()=>setFaqIdx(idx)}
                                        >
                                            {faqItem?.question ?? ""}
                                            {/*Lorem Ipsum is placeholder text commonly used in the*/}
                                            {/*graphic,print?*/}
                                        </div>
                                        <div className="faq-data" style={idx == faqIdx ?{display: "block"} : {display : "none"}}>
                                            <p>
                                                {faqItem?.answer ?? ""}
                                                {/*Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem*/}
                                                {/*Ipsum has been the industry's standard dummy text ever since the 1500s, when an*/}
                                                {/*unknown printer took a galley of type and scrambled it to make a type specimen*/}
                                                {/*book.*/}
                                            </p>
                                        </div>
                                    </li>
                                })
                                :
                                ""}
                            {/*<li>*/}
                            {/*    <div className="faq-title">Lorem Ipsum is placeholder text commonly used in the*/}
                            {/*        graphic,print?*/}
                            {/*    </div>*/}
                            {/*    <div className="faq-data">*/}
                            {/*        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem*/}
                            {/*            Ipsum has been the industry's standard dummy text ever since the 1500s, when an*/}
                            {/*            unknown printer took a galley of type and scrambled it to make a type specimen*/}
                            {/*            book. </p>*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <div className="faq-title">Lorem Ipsum is placeholder text commonly used in the*/}
                            {/*        graphic,print?*/}
                            {/*    </div>*/}
                            {/*    <div className="faq-data">*/}
                            {/*        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem*/}
                            {/*            Ipsum has been the industry's standard dummy text ever since the 1500s, when an*/}
                            {/*            unknown printer took a galley of type and scrambled it to make a type specimen*/}
                            {/*            book. </p>*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <div className="faq-title">Lorem Ipsum is placeholder text commonly used in the*/}
                            {/*        graphic,print?*/}
                            {/*    </div>*/}
                            {/*    <div className="faq-data">*/}
                            {/*        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem*/}
                            {/*            Ipsum has been the industry's standard dummy text ever since the 1500s, when an*/}
                            {/*            unknown printer took a galley of type and scrambled it to make a type specimen*/}
                            {/*            book. </p>*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </section>

            <section className="brand-lp-sec">
                <div className="container">
                    <div className="brand-listing">
                        <div className="item">
                            <img src={NCAAIMG} alt=""/>
                        </div>
                        <div className="item">
                            <img src={NAIAIMG} alt=""/>
                        </div>
                        <div className="item">
                            <img src={NJCAAIMG} alt=""/>
                        </div>
                        <div className="item">
                            <img src={CCCAA} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default ClubLP
