import { put, takeEvery } from "redux-saga/effects";
import {
    removeSignupData,
    setAuthToken,
    setUserInfo,
} from "../../../services/authService";
import { fetchMethod, fetchGraphMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
import history from "../../../services/history";

// ========================================== Action Type =====================================================
export const actionType = {
    MATCH_OTP_START: "MATCH_OTP_START",
    MATCH_OTP_SUCCESS: "MATCH_OTP_SUCCESS",
    MATCH_OTP_FAILED: "MATCH_OTP_FAILED",
    MATCH_OTP_STATE: "MATCH_OTP_STATE",
};

// ============================================ Actions ========================================================

export const matchotpStart = (data) => ({
    type: actionType.MATCH_OTP_START,
    payload: data,
});

export const matchotpState = () => ({
    type: actionType.MATCH_OTP_STATE,
});

export const matchotpSuccess = (data) => ({
    type: actionType.MATCH_OTP_SUCCESS,
    payload: data,
});

export const matchotpFailed = (data) => ({
    type: actionType.MATCH_OTP_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    // loading: false,
    data: null,
    error: null,
};

export default function matchotpReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.MATCH_OTP_STATE:
            return {
                ...state,
                data: null,
                error: null,
            };
        case actionType.MATCH_OTP_START:
            return {
                ...state,
                // loading: true,
            };
        case actionType.MATCH_OTP_SUCCESS:
            return {
                ...state,
                // loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.MATCH_OTP_FAILED:
            return {
                ...state,
                // loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================
export function* matchotpSaga(action) {
    try {
        const response = yield fetchMethod(
            `/api/Userdata/matchOtp`,
            action.payload.data,
            "post",
            false
        );
        if (response && response.status == 200) {
            if (
                response.data &&
                response.data.response &&
                response.data.response.status === 200
            ) {

                if (action.payload.data.useFor == "login" && action.payload.data.otpOrId == "otp") {
                    messagePopup("",action.payload.data.useFor == "login" ? "Email has been sent to sportsforce " +
                        "team and you will be notified once verification done" :
                        response.data.response.msg, "success");
                    if(action.payload.handelOptModal){
                        action.payload.handelOptModal()
                    } 
                    if(action.payload.nextRoute){
                        action.payload.nextRoute();
                    }
                    if(action.payload.nextScreen){
                        history.push(action.payload.nextScreen)
                    }
                } else {
                    if (action.payload.data.useFor == "login" && action.payload.data.otpOrId == "id") {
                        //text chnage as per QA
                        messagePopup("",
                            "Email has been sent to sportsforce team and you will be notified once verification done"
                            // response.data.response.msg
                            ,
                            "success");
                        if(action.payload.nextScreen) history.push(action.payload.nextScreen)
                    } else {
                        let userId = response.data.response.data[0].userId;
                        let id = response.data.response.data[0].id;
                        if(action.payload.nextScreen){
                            history.push(action.payload.nextScreen)
                        }
                        if(action.payload.publicProfileView){
                            localStorage.setItem('pubViewID',userId || 0)
                            localStorage.setItem('athleteId',id || 0)
                            localStorage.setItem('claimProfileKey',1 || 0)
                            history.push(`/claimProfile/0/${userId}/1`);
                        }
                    }

                }
            } else if (response.data.response.status === 201) {
                messagePopup("", response.data.response.msg, "error");
            }
        } else {
            messagePopup("", "Please enter your valid passcode.", "error");
            yield put(matchotpFailed("Please enter your valid passcode."));
        }
    } catch (error) {
        messagePopup("", "Please enter your valid passcode.", "error");
        yield put(matchotpFailed("Please enter your valid passcode."));
    }
}

export function* watchMatchOtpSaga() {
    yield takeEvery(actionType.MATCH_OTP_START, matchotpSaga);
}
