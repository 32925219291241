import { put, takeEvery } from "redux-saga/effects";
// import messagePopup from "../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    TEAM_SIDE_BAR_START: "TEAM_SIDE_BAR_START",
    TEAM_SIDE_BAR_SUCCESS: "TEAM_SIDE_BAR_SUCCESS",
    TEAM_SIDE_BAR_FAILED: "TEAM_SIDE_BAR_FAILED",
};

// ============================================ Actions ========================================================
export const teamSideBarStart = (data) => ({
    type: actionType.TEAM_SIDE_BAR_START,
    payload: data,
});

export const teamSideBarSuccess = (data) => ({
    type: actionType.TEAM_SIDE_BAR_SUCCESS,
    payload: data,
});

export const teamSideBarFailed = (data) => ({
    type: actionType.TEAM_SIDE_BAR_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    status: false,
    error: null,
};

export default function teamSideBarReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.TEAM_SIDE_BAR_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.TEAM_SIDE_BAR_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action.payload.status,
                error: null,
            };
        case actionType.TEAM_SIDE_BAR_FAILED:
            return {
                ...state,
                loading: false,
                status: null,
                error: action.payload.status,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* teamSideBarSaga(action) {
    try {
        yield put(teamSideBarSuccess(action?.payload));
    } catch (error) {
        yield put(teamSideBarFailed("Team Header Toggle failed"));
    }
}

export function* watchTeamSideBarSaga() {
    yield takeEvery(actionType.TEAM_SIDE_BAR_START, teamSideBarSaga);
}
