export const getAthleteQuery = `
query allAthletes($where: JSON){
    allAthletes(where: $where){
      Athletes{
        id
        active
        address
        city
        stateid
        athletecountrycodeid
        athletezipcode
        athletestateidmaprel{
          States{
            id
            statename
          }
        }
        athleteathletecountrycodeidmaprel{
          Countries{
            id
            countryname
          }
        }
      }
    }
  }
`;