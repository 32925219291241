import React, { useState, useEffect, useRef, useCallback } from "react";

import "./css/notification-markread.scss";
import { connect } from "react-redux";
import {
  getNotificationStart,
  saveNotificationStart,
} from "../../../.././../redux/athlete/dashboard/notification/notificationList";
import ReactPaginate from "react-paginate";
import { getUserInfo } from "../../../../../services/authService";
import {
  getNotificationQuery,
  saveNotificationQuery,
  markAllReadQuery,
} from "./NotificationQuery";
import { imageDownloadUrl } from "../../../../../config";
import {
  postDateFormat,
  postTimeFormat,
} from "../../../../../utils/dateTimeFormatter";
import BackdropLoader from "../../../../common/Loader";
import GoTopButton from "../editUserProfile/GoTopButton";

import { getNotificationUnreadQuery } from "../../../../layouts/header/DashboardQuery";
import { notificationUnreadCountListStart } from "../../../../../redux/athlete/dashboard/notification/notificationUnreadCount";
import messagePopup from "../../../../../utils/messagePopup";
import swal from "sweetalert";
import moment from 'moment'
import bell from './img/notification.jpg'
const SignedCommitmentList = (data) => {
  const signedCommitmentDate = new Date(data.notificationDate);
  const currentDate = new Date();
  const [days, setDays] = useState();

  function timeDiffCalc(signedCommitmentDate, currentDate) {
    let diffInMilliSeconds =
        Math.abs(currentDate - signedCommitmentDate) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    const months = Math.floor(days % 365 / 30);

    const years = Math.floor(days / 365)
    return { days, hours, minutes, months, years };
  }

  let signedSignature = "";
  if (data.notificationDate != null) {
    let abc = timeDiffCalc(signedCommitmentDate, currentDate);
    signedSignature =
        abc.years > 1
            ? abc.years + "years"
            : abc.years == 1
            ? abc.years + "year"
            : abc.months > 1
                ? abc.months + " months"
                : abc.months == 1
                    ? `${abc.months} month`
                    : abc.days == 1
                        ? abc.days + " day"
                        : abc.days > 0
                            ? abc.days + " days "
                            : abc.hours > 0
                                ? abc.hours + " hours "
                                : abc.minutes > 0
                                    ? abc.minutes + " mins "
                                    : abc.minutes == 1
                                        ? abc.minutes + "min"
                                        : "just now";

  } else {
    return (
        <>
          <div className="note"></div>
        </>
    );
  }

  return (
      <>
        {/*<div className="note" style={{ width: "95%" }}>*/}
          <span>{signedSignature !== "just now" ? `${signedSignature} ago` : signedSignature}</span>
        {/*</div>*/}
       </>
  );
};

const Notification = (props) => {

  const [fields, setFields] = useState({
    getPage: 0,
    searchPage: 0,
    pageCount: 0,
    perPage: 10,
    go: 1,
  });
  const [count, setCount] = useState(0);
  const [isRead, setIsRead] = useState("");
  const [notificationValue, setNotificationValue] = useState(0);
  const fakeInput = useRef(null);
  const [isReadCount, setIsReadCount] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [isUnreadSelected, setIsUnreadSelected] = useState(false),
        [hasMore, setHasMore] = useState(false),
        [loading, setLoading] = useState(false),
  observer = useRef(),
      lastBookElementRef = useCallback(node => {
        if (loading) return
        if (observer?.current) observer?.current?.disconnect()
        observer.current = new IntersectionObserver(entires => {
          if (entires[0].isIntersecting && hasMore) {
            getNotifyData(isAllSelected)
          }
        })
        if (node) observer.current.observe(node)
      }, [hasMore, isAllSelected, isUnreadSelected]);
  useEffect(() => {
    getNotitificationList(notificationValue);
    getNotitificationUnreadList();
    saveMarkAllRead("null", "see")
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);
  const [notificationData, setNotificationData] = useState([]);
  const NotificationList =
    props.notificationList &&
      props.notificationList.notificationList &&
      props.notificationList.notificationList.data
      ? props.notificationList.notificationList.data
      : null;
  useEffect(() => {
    if (NotificationList) {
      let fieldsT = fields;
      fieldsT["pageCountT"] = NotificationList.totalCount
        ? Math.ceil(+NotificationList.totalCount / fieldsT["perPage"])
        : "";
      setFields({
        ...fieldsT,
      });
    }
  }, [NotificationList]);

  const getNotifyData = (type) =>{
    const cloneT = fields
    cloneT['perPage'] = cloneT['perPage'] + 10
    setFields(cloneT)
      if(type){
        getNotitificationList(0)
      }else{
        getNotitificationList(1)
      }
  }

  const getNotitificationUnreadList = () => {
    props.notificationUnreadCountListStart({
      query: getNotificationUnreadQuery,
      variables: getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ? 
      {
        where: {
          coachid: getUserInfo() ? getUserInfo().id : 0,
          issee: 0,
        },
      }
      :
      {
        where: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          issee: 0,
        },
      },
    });
  };

  useEffect(() => {
    setIsReadCount(
      props.notificationUnreadList.data &&
        props.notificationUnreadList.data.length
        ? props.notificationUnreadList.data.filter((item) => item.issee === 0)
          .length
        : ""
    );
  }, [props.notificationUnreadList.data]);
  const getNotitificationList = (getDataValue) => {
    if(getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ){
      let variable = getDataValue == 0 ? {
        where: {
          coachid: getUserInfo() ? getUserInfo().id : 0,
          order: "id desc",
        },
        first: fields.getPage * fields.perPage,
        last: parseInt(fields.perPage),
      } : {
        where: {
          coachid: getUserInfo() ? getUserInfo().id : 0,
          order: "id desc",
          isread: 0
        },
        first: fields.getPage * fields.perPage,
        last: parseInt(fields.perPage),
      }
      props.getNotificationStart({
        query: getNotificationQuery,
        variables: variable,
      });
    }else{
      let variable = getDataValue == 0 ? {
        where: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          order: "id desc",
        },
        first: parseInt(fields.pageCount == 0 ? 1 : fields.pageCount) * fields.perPage,
        last: parseInt(fields.perPage),
      } : {
        where: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          order: "id desc",
          isread: 0
        },
        first: parseInt(fields.pageCount == 0 ? 1 : fields.pageCount) * fields.perPage,
        last: parseInt(fields.perPage),
      }
      props.getNotificationStart({
        query: getNotificationQuery,
        variables: variable,
      });
      
    }
    //code is for scroll on top when api hit
    setTimeout(()=>{
      window.scrollTo(0,0)
    },200)
  };

  useEffect(() => {
    let notificationListData = [];
    if (!!props?.notificationList?.notificationList?.data) {
      let data =
          props?.notificationList?.notificationList?.data?.Notifications &&
          props?.notificationList?.notificationList?.data?.Notifications.length > 0
              ? props?.notificationList?.notificationList?.data?.Notifications.map(
              (item) => {
                item["id"] = item && item.id ? item.id : "";
                item["msg"] = item && item.msg ? item.msg : "";
                item["time"] = item.createdon
                    ? moment(item.createdon).format('hh:mm a')
                    : "";
                item["date"] = item.createdon
                    ? postDateFormat(item.createdon)
                    : "";
                item["profilePic"] =
                    item.notificationuseridmaprel?.Athletes?.length > 0 &&
                    item.notificationuseridmaprel?.Athletes[0]?.onBoardingUserIdMap
                        ?.Onboardings.length > 0
                        ? item.notificationuseridmaprel?.Athletes[0]
                            .onBoardingUserIdMap?.Onboardings[0].profileimages
                        : "";
                notificationListData.push(item);
              }
              )
              : [];
      setNotificationData(notificationListData);
      setHasMore(props?.notificationList?.notificationList?.data?.totalCount > notificationListData?.length ?? 0 ? true : false)
      setCount(notificationListData.length);
      setIsRead(
          notificationListData && notificationListData.length
              ? notificationListData.filter((item) => item.isread === 0).length
              : ""
      );
    }
  }, [props.notificationList.notificationList.data]);

  const handleGoToPage = (e) => {
    if (NotificationList) {
      const fieldsT = fields;
      fieldsT["getPage"] = +fields.go - 1;
      fieldsT['pageCount'] = +fields.go
      setFields(fieldsT);
        handlenextPage();
    }
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  const handlePageClick = (e) => {
    let fieldsT = fields;
    // if(e.selected <= fields["getPage"]){
    //   fieldsT["getPage"] = e.selected + 1;
    // } else{
      fieldsT["getPage"] = e.selected
      fieldsT["pageCount"] = e.selected + 1
    // }
    // fieldsT["getPage"] = e.selected
    setFields(fieldsT);
    handlenextPage();
  };

  const handlenextPage = () => {
    getNotitificationList(notificationValue);
  };

  const saveMarkAllRead = (e, readStatus) => {
    if (readStatus == "see") {
      props.saveNotificationStart({
        query: markAllReadQuery,
        variables: getUserInfo() && getUserInfo().role == "COLLEGECOUCHES"  ? 
        {
          where: {
            coachid: getUserInfo() ? getUserInfo().id : 0,
          },
          data: {
            issee: 1,
          },
        }
        :
        {
          where: {
            userid: getUserInfo() ? getUserInfo().id : 0,
          },
          data: {
            issee: 1,
          },
        },
        getLatestList: getLatestList,
      });

    } else {
      e.preventDefault();
      props.saveNotificationStart({
        query: markAllReadQuery,
        variables: getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ?
        {
          where: {
            coachid: getUserInfo() ? getUserInfo().id : 0,
          },
          data: {
            isread: 1,
          },
        }
        :
        {
          where: {
            userid: getUserInfo() ? getUserInfo().id : 0,
          },
          data: {
            isread: 1,
          },
        },
        getLatestList: getLatestList,
      });
    }
  };

  const saveAsReadById = (e, item, seeCondition) => {
    e.preventDefault();
    if (item.isread === 0) {
      props.saveNotificationStart({
        query: saveNotificationQuery,
        variables:  getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ?
        {
          obj: {
            id: item.id,
            coachid: getUserInfo() ? getUserInfo().id : 0,
            isread: 1,
          },
        }
        :
        {
          obj: {
            id: item.id,
            userid: getUserInfo() ? getUserInfo().id : 0,
            isread: 1,
          },
        },
        getLatestList: getLatestList,
      });
    }
  };

  const getLatestList = () => {
    // getNotitificationList(notificationValue);
    const type = isAllSelected == true ? 0 : 1
    getNotitificationList(type);
    getNotitificationUnreadList();
    fakeInput.current.focus();
    fakeInput.current.select();
  };

  const notificationList = (e, notificationSeeStatus) => {
    e?.preventDefault();
    const fieldsT = fields;
    fieldsT["getPage"] = 0;
    fieldsT["pageCount"] = 0;
    setFields(fieldsT);
    setNotificationValue(notificationSeeStatus)
    getNotitificationList(notificationSeeStatus);
  }
  const lazzySel = (e,type) =>{
    const cloneT = fields
    cloneT['perPage'] = 10
    setFields(cloneT)
    notificationList(e, type)
  }
  return (
    <div className="pageContent pt-0 notification-pagecontent">
      <BackdropLoader
        open={
          props.notificationList &&
          props.notificationList.notificationList.loading
        }
      />
      <div className="notification-read-box">
        <input className="fakeInput" type="text" ref={fakeInput} />
        <div className="notification-title">
          Notifications
          {isReadCount ? `(${isReadCount})` : ""}
          {/*<a onClick={saveMarkAllRead} className="notification-read">*/}
          {/*  Mark all read*/}
          {/*</a>*/}
        </div>
      </div>
      <div className="container full-with">
        <div className="notification-markread-main-box">
          <div className="tab-wrap">
            <ul className="tabs">
              <li>
                <button
                className="btn blue"
                style={{ background: (isAllSelected === true ? "#2177D6" : "none"),
                color:(isAllSelected === true ? "#fff" : "#2177D6")
                }}
                onClick={(e) => {
                  lazzySel(e,0)
                  // notificationList(e, 0);
                  setIsAllSelected(true);
                  setIsUnreadSelected(false) }}
              >
                All
              </button>
              </li>
              <li>
                <button
                  className="btn blue"
                  style={{
                    background: (isUnreadSelected === true ? "#2177D6" : "none"),
                    color:(isUnreadSelected === true ? "#fff" : "#2177D6"),
                  }}
                  onClick={(e) => {
                    // notificationList(e, 1);
                    lazzySel(e,1)
                    setIsUnreadSelected(true);
                    setIsAllSelected(false) }}
                >
                Unread
                </button>
              </li>
              <li>
                <button
                  className="btn blue"
                  style={{color: "#2177D6", background:"none", }}
                  onClick={(e) => saveMarkAllRead(e)}
                >
                Mark all read
              </button>
                </li>
            </ul>
           
          </div>
          <div style={notificationData?.length > 4 ? {height:"50vh", overflowY:"scroll"} : {}}>
          {notificationData && notificationData.length > 0 ?
            notificationData.map((item, index) => {
              if(notificationData?.length == index + 1){
                return (
                    <div
                        className={`item ${item.isread === 0 ? "active" : ""}`}
                        key={index}
                        ref={lastBookElementRef}
                    >
                      <div className="notification-date-item">
                        <div className="date-text">
                          <span>{item.date}</span>
                          <span>{item.time}</span>
                        </div>
                      </div>
                      <div className={item.isread === 0 ? "notification-item" : "notificationread"}>
                        <div className="user-profile" style={!!item.profilePic ? {} :
                            {border: "1px solid #BABABA",}}>
                          {item.profilePic ? (
                              <img
                                  src={imageDownloadUrl + `/${item.profilePic}`}
                                  alt=""
                              />
                          ) : (
                              (getUserInfo() && getUserInfo()?.FirstName.split("")[0]?.toUpperCase() || "") +
                              (getUserInfo() && getUserInfo()?.lastName.split("")[0]?.toUpperCase())
                          )}
                        </div>
                        <div className="user-detial-box">
                          <div
                              className="item"
                              onClick={(e) => {
                                saveAsReadById(e, item);
                              }}
                          >
                            <h4 dangerouslySetInnerHTML={{__html: item.msg.length > 100
                                  ? `${item.msg.slice(0, 100)}...`
                                  : item.msg}}
                                className={`${item.isread === 0 ? "name" : "read"}`}
                            >
                            </h4>
                            <div className="text-box">
                              <a>{item.messageLink}</a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="time-text">
                              <SignedCommitmentList notificationDate = {item.createdon} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                );
              }else{
                return (
                    <div
                        className={`item ${item.isread === 0 ? "active" : ""}`}
                        key={index}
                    >
                      <div className="notification-date-item">
                        <div className="date-text">
                          <span>{item.date}</span>
                          <span>{item.time}</span>
                        </div>
                      </div>
                      <div className={item.isread === 0 ? "notification-item" : "notificationread"}>
                        <div className="user-profile" style={!!item.profilePic ? {} :
                            {border: "1px solid #BABABA",}}>
                          {item.profilePic ? (
                              <img
                                  src={imageDownloadUrl + `/${item.profilePic}`}
                                  alt=""
                              />
                          ) : (
                              (getUserInfo() && getUserInfo()?.FirstName.split("")[0]?.toUpperCase() || "") +
                              (getUserInfo() && getUserInfo()?.lastName.split("")[0]?.toUpperCase())
                          )}
                        </div>
                        <div className="user-detial-box">
                          <div
                              className="item"
                              onClick={(e) => {
                                saveAsReadById(e, item);
                              }}
                          >
                            <h4 dangerouslySetInnerHTML={{__html: item.msg.length > 100
                                  ? `${item.msg.slice(0, 100)}...`
                                  : item.msg}}
                                className={`${item.isread === 0 ? "name" : "read"}`}
                            >
                    
                            </h4>
                            <div className="text-box">
                              <a>{item.messageLink}</a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="time-text">
                              <SignedCommitmentList notificationDate = {item.createdon} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                );
              }
            }) : <p style={{textAlign:"center"}}>
                {/*{(!!fields.pageCountT || notificationData.length > 0) && */}
                {/*No Record Found*/}
                <img src={bell}/>
                {/*}*/}
          </p>}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    notificationList: state.athlete.dashboard.notification,
    notificationUnreadList:
      state.athlete.dashboard.notification.notificationUnreadCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationStart: (data) => dispatch(getNotificationStart(data)),
    saveNotificationStart: (data) => dispatch(saveNotificationStart(data)),
    notificationUnreadCountListStart: (data) =>
      dispatch(notificationUnreadCountListStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
