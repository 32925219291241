import React, { useState, useEffect, useRef } from 'react';
import '../../../../../../assets/css/stylesheet.scss';
import '../assets/css/search.scss';
import CollegeItemCard from './CollegeItemCard';
import Filter from './Filter';
import ReactPaginate from 'react-paginate';
import CollegesTabs from '../CollegesTabs';
import { connect } from 'react-redux';
import { searchCollegeListAPIStart } from '../../../../../../redux/athlete/dashboard/colleges/collegeSearchList';
import BackdropLoader from '../../../../../common/Loader';
import {
  getAllAreaofstudyQuery,
  getAllSearchFiltersQuery,
  getCollegesListQuery,
  saveSearchFilterQuery,
} from './SearchCollegesQuery';
import SavedFilterModal from './SavedFilterModal';
import { Dialog } from '@mui/material';
import { searchFiltersListStart } from '../../../../../../redux/athlete/dashboard/colleges/searchFilterList';
import { getUserInfo } from '../../../../../../services/authService';
import { stateListStart } from '../../../../../../redux/common/allStates';
import { areaOfStudyListStart } from '../../../../../../redux/athlete/dashboard/colleges/areaOfStudyList';
import {
  getAllAthletesDataQuery,
  getAllInterestLevelQuery,
  getAllStatesQuery,
} from '../../../../../common/CommonQuery';
import usePrevious from '../../../../../../utils/usePrevious';
import { deleteSearchFilterStart } from '../../../../../../redux/athlete/dashboard/colleges/saveSearchFilter';
import { collegesListStart } from '../../../../../../redux/athlete/dashboard/colleges/collegeList';
import {
  addUserCollegeStart,
  removeUserStart,
} from '../../../../../../redux/athlete/dashboard/colleges/addRemoveUserCollege';
import { interestLevelListStart } from '../../../../../../redux/common/interestLevelList';
import InterestLevelModal from './InterestLevelModal';
import '../assets/css/myColleges.scss';
import {
  offersListsaveStart,
  offersListStart,
} from '../../../../../../redux/athlete/dashboard/editUserProfile/offers';
import {
  getAllOfferQuery,
  saveOffersQuery,
} from '../../editUserProfile/OfferQuery';
import { getCommitmentStart } from '../../../../../../redux/athlete/profile/commitment';
import { getMyCommitmentQuery } from '../../homepage/leftSideBar/query';
import GoTopButton from '../../editUserProfile/GoTopButton';
import {
  fetchGraphMethod,
  fetchMethod,
} from '../../../../../../services/httpService';
import swal from 'sweetalert';
import history from '../../../../../../services/history';
import toastr from 'toastr';
import { allCollegepreferencesettings } from '../preferences/collegesPreferencesQuery';
import Swal from 'sweetalert2';

function SearchColleges(props) {
  const fakeInput = useRef();
  const [fields, setFields] = useState({
    openFilter: false,
    offset: 1,
    page: '',
    totalCollegeData: null,
    getPage: 0,
    searchPage: 0,
    pageCount: 0,
    perPage: 10,
    go: 1,
  });

  const [filters, setFilters] = useState({
    collegeName: '',
    favorite: 'any',
    promotions: 'any',
    Level: [],
    athleticSelectivity: [],
    AcademicSelectivity: [],
    StateRegion: [],
    CollegeSize: [],
    TuitionCost: [],
    Settting: [],
    Type: [],
    AreaStudy: [],
    conference: [],
    searchStateRegion: '',
    sortby: 0,
  });

  const [collegesList, setCollegeList] = useState(null);
  const [areaOfStudyList, setAreaOfStudyList] = useState(null);
  const [searchFiltersList, setSearchFiltersList] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [timeout, settimeout] = useState(0);
  const prevCollegeCityName = usePrevious(filters.collegeName);
  const [showInterestModal, setShowInterestModal] = useState(false);
  const [collegeId, setCollegeId] = useState(0);
  const [collegeIsAddedToOffer, setCollegeIsAddedToOffer] = useState(null);
  const [commitmentWithCollege, setCommitmentWithCollege] = useState(null);
  const [athDetail, setAthDetail] = useState({}); //state for athlete detail
  const [sortedStates, setSortedStates] = useState([]);
  const refreshFilterList = () => {
    props.searchFiltersListStart({
      query: getAllSearchFiltersQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };

  useEffect(() => {
    getUserCollegeList();
    refreshFilterList();
    props.areaOfStudyListStart({
      query: getAllAreaofstudyQuery,
    });
    props.stateListStart({
      query: getAllStatesQuery,
    });
    props.interestLevelListStart({
      query: getAllInterestLevelQuery,
      variables: null,
    });
    fakeInput?.current?.focus();
    fakeInput?.current?.select();
  }, []);
  // useEffect(()=> {
  // filters.Level.push(filters.athleticSelectivity);
  // }, [filters.Level])

  useEffect(() => {
    if (props.areOfStudyList.data) {
      // change done as of oct task jira 190
      props?.areOfStudyList?.data?.sort((a, b) => {
        return a.areaofstudyname?.split(' ')?.[0]?.toLowerCase() >
          b.areaofstudyname?.split(' ')?.[0]?.toLowerCase()
          ? 1
          : b.areaofstudyname?.split(' ')?.[0]?.toLowerCase() >
            a.areaofstudyname?.split(' ')?.[0]?.toLowerCase()
          ? -1
          : 0;
      });
      const tempData = props.areOfStudyList.data;
      setAreaOfStudyList(tempData);
    }
  }, [props]);

  useEffect(() => {
    if (props.searchFilters.data) {
      const tempData = props.searchFilters.data;
      setSearchFiltersList(tempData);
    }
  }, [props.searchFilters.data]);
  useEffect(() => {
    if (props.searchCollegeList.data) {
      if (props.collegesList.data) {
        const fieldsT = fields;
        let collegesListT = [];
        if (props.searchCollegeList.data.colleges) {
          for (const data of props.searchCollegeList.data.colleges) {
            let isFavorite = false;
            let rank = 0;
            let collegeRowId = 0;
            for (const iterator of props.collegesList.data) {
              if (iterator.collegeid === data.id) {
                isFavorite = iterator.isfav;
                rank = iterator.collegerank;
                collegeRowId = iterator.id;
                break;
              }
            }

            collegesListT = [
              ...collegesListT,
              { ...data, isFavorite, rank, collegeRowId },
            ];
          }
        }
        setCollegeList(collegesListT);
        fieldsT['pageCountT'] = props.searchCollegeList.data.totalCount
          ? Math.ceil(
              +props.searchCollegeList.data.totalCount / fieldsT['perPage']
            )
          : 0;
        setFields(fieldsT);
      }
    }
    // fakeInput?.current?.focus();
    // fakeInput?.current?.select();
  }, [props.searchCollegeList.data, props.collegesList]);
  useEffect(() => {
    getAllOfferList();
    getAthleteDetail();
  }, []);
  const getAllOfferList = () => {
    props.offersListStart({
      query: getAllOfferQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };
  useEffect(() => {
    props.getCommitmentStart({
      query: getMyCommitmentQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
          order: 'id DESC',
        },
      },
    });
  }, []);
  const getUserCollegeList = () => {
    props.collegesListStart({
      query: getCollegesListQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };

  const searchAreaOfStudy = (search) => {
    if (timeout) clearTimeout(timeout);
    const timeoutT = setTimeout(() => {
      const newAreaOfStudy = props.areOfStudyList.data
        ? props.areOfStudyList.data.filter((item) => {
            if (
              item.areaofstudyname?.toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
            return false;
          })
        : [];
      setAreaOfStudyList([...newAreaOfStudy]);
    }, 300);
    settimeout(timeoutT);
  };

  const getSearchCollegesData = (data = null, value) => {
    // const { collegeSize1, collegeSizeEvaluationValue, collegeSize2 } =
    //   getCollegeSizeData(data ? (data.CollegeSize ? data.CollegeSize : 0) : 0);
    // const {
    //   collegeTutionFee1,
    //   collegeTutionFeeEvaluationValue,
    //   collegeTutionFee2,
    // } =
    // getTutionFeeData(data ? (data.TuitionCost ? data.TuitionCost : 0) : 0);
    if (value == true) {
      fakeInput.current.focus();
      fakeInput.current.select();
      const fieldsT = fields;
      fieldsT['page'] = fields.go - 1;
      setFields({ ...fieldsT });
    }
    if (fields.page < 0 || fields.perPage <= 0) {
      // messagePopup("", "Invalid data", "warning");
      toastr.warning('Invalid data', '', {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: 'toast-top-full-width',
        showEasing: 'swing',
        fadeIn: 40000,
        preventDuplicates: true,
      });
    } else {
      props.searchCollegeListAPIStart({
        variables: {
          collegeNameAndCity: data ? data.collegeName : '',
          state: data ? data.StateRegion : [],
          academicsSelectivity: data ? data.AcademicSelectivity : [],
          collegeSize1: data.CollegeSize ? data.CollegeSize : [],
          level: data.levelidData
            ? data.levelidData
            : data.leveliddata
            ? data.leveliddata
            : [],
          athleticselectivity:
            data.levelidData && data.levelidData.length == 0
              ? []
              : data.athleticselectivityid
              ? data.athleticselectivityid
              : [],
          // collegeSizeEvaluationValue,
          // collegeSize2,
          setting: data ? data.Settting : [],
          type: data ? data.Type : [],
          userId: getUserInfo() ? getUserInfo().id : null,
          favoriteValue: data ? data.favorite : 'any',
          areaOfStudy: data ? data.AreaStudy : [],
          collegeTutionFee1: data.TuitionCost ? data.TuitionCost : [],
          // athleticselectivity:[],
          // collegeTutionFee2,
          // collegeTutionFeeEvaluationValue,
          first: fields.getPage * fields.perPage,
          last: +fields.perPage,
          // first: parseInt(fields.pageCount == 0 ? 1 : fields.pageCount) * fields.perPage,
          // last: parseInt(fields.perPage),
          collegeConference: data.conference ? data.conference : [],
          sortby: data?.sortby ?? 0,
        },
        nextFunc,
      });
      goTop();
    }
  };
  const nextFunc = (action) => {
    if (action?.payload?.variables?.sortby == 2) {
      swal({
        text: 'You have not set your preferences yet',
        icon: 'warning',
        buttons: {
          confirm: { text: 'Set my preference' },
          cancel: 'Do it later',
        },
      }).then((res) => {
        if (res) {
          history.push('/colleges/preferences');
        } else {
          const initialValues = {
            collegeName: '',
            favorite: 'any',
            promotions: 'any',
            Level: [],
            athleticSelectivity: [],
            AcademicSelectivity: [],
            StateRegion: [],
            CollegeSize: [],
            TuitionCost: [],
            Settting: [],
            Type: [],
            AreaStudy: [],
            conference: [],
            searchStateRegion: '',
            sortby: 0,
          };
          setFilters(initialValues);
        }
      });
    } else if (action?.payload?.variables?.sortby == 4) {
      swal({
        text: 'No target colleges',
        icon: 'warning',
        buttons: {
          confirm: { text: 'OK' },
        },
      }).then((res) => {
        if (res) {
          const cloneFil = filters;
          cloneFil['sortby'] = 0;
          setFilters({ ...cloneFil });
        }
      });
    }
  };

  const handlePageClick = async (e) => {
    await goTop();
    const fieldsT = fields;
    fieldsT['getPage'] = e.selected;
    fieldsT['pageCount'] = e.selected + 1;

    // fieldsT["getPage"] = e.selected;
    setFields(fieldsT);
    handlenextPage();
  };

  const handlenextPage = () => {
    getSearchCollegesData(filters);
  };

  useEffect(() => {
    if (!!props?.location?.state) {
      const tempFilter = filters,
        prefData = JSON.parse(props?.location?.state);
      tempFilter['AcademicSelectivity'] = prefData?.acadamicselectivityidData;
      tempFilter['athleticselectivityid'] = prefData?.athleticselectivityid;
      // tempFilter['Level'] = prefData?.levelidData;
      tempFilter['leveliddata'] = prefData?.levelidData;
      tempFilter['selectAllStates'] = prefData?.isallstate ? 1 : 0;
      tempFilter['selectedRegions'] = prefData?.regionidData ?? [];
      tempFilter['StateRegion'] = prefData?.selStateName ?? [];
      tempFilter['CollegeSize'] = prefData?.collegesizeData;
      tempFilter['TuitionCost'] = prefData?.tutioncostData;
      tempFilter['Settting'] = prefData?.collegesettingData;
      tempFilter['AreaStudy'] = prefData?.areaOfStudy;
      setFilters({ ...filters, tempFilter });
    }
  }, [props?.location?.state]);

  useEffect(() => {
    if (props?.states?.data?.length) {
      props.states.data.sort((a, b) => a.countryid - b.countryid);
      setSortedStates(props.states.data);
    }
  }, [props.states.data]);

  useEffect(() => {
    if (prevCollegeCityName === filters.collegeName || !filters.collegeName)
      getSearchCollegesData(filters);
  }, [filters]);
  useEffect(() => {
    getInitialSortBy();
  }, []);
  //this func is used for setting the value in sortby key on the basic of athlete address
  const getInitialSortBy = async () => {
    //this below check is someone land from pref view my match btn click than pref sort will be selected
    const cloneFilter = filters;
    if (!!props?.location?.state) {
      cloneFilter['sortby'] = 2;
      setFilters({ ...cloneFilter });
    } else {
      if (getUserInfo().id) {
        let sortBy = 0;
        if (
          cloneFilter['athleticSelectivity'].length ||
          cloneFilter['AcademicSelectivity'].length ||
          cloneFilter['AreaStudy'].length ||
          cloneFilter['CollegeSize'].length ||
          cloneFilter['Settting'].length ||
          cloneFilter['StateRegion'].length ||
          cloneFilter['TuitionCost'].length
        ) {
          sortBy = 2;
        }
        cloneFilter['sortby'] = sortBy;
        setFilters({ ...cloneFilter });
      } else {
        cloneFilter['sortby'] = 0;
        setFilters({ ...cloneFilter });
      }
    }
  };

  const handleChange = (field, e) => {
    const updatedValue = parseInt(e.target.value);
    const fieldsT = filters;
    if (field == 'sortby' && e?.target?.value == 1) {
      if (athDetail?.city == false || athDetail?.homeoutsideusorcanada == 1) {
        swal({
          text: 'You need to set your US address to avail this functionality',
          icon: 'warning',
          buttons: {
            confirm: { text: 'Set my US address' },
            cancel: 'Do it later',
          },
        }).then((res) => {
          if (res) {
            history.push('/contact');
          } else {
            const filterInitialValues = {
              collegeName: '',
              favorite: 'any',
              promotions: 'any',
              Level: [],
              athleticSelectivity: [],
              AcademicSelectivity: [],
              StateRegion: [],
              CollegeSize: [],
              TuitionCost: [],
              Settting: [],
              Type: [],
              AreaStudy: [],
              conference: [],
              searchStateRegion: '',
              selectAllStates: 0,
              selectedStateId: [],
              selectedRegions: [],
              sortby: 0,
            };
            setFilters(filterInitialValues);
            setFields({
              openFilter: false,
              offset: 1,
              page: '',
              totalCollegeData: null,
              getPage: 0,
              searchPage: 0,
              pageCount: 0,
              perPage: 10,
              go: 1,
            });
            getSearchCollegesData(filterInitialValues);
          }
        });
      } else {
        const filterInitialValues = {
          collegeName: '',
          favorite: 'any',
          promotions: 'any',
          Level: [],
          athleticSelectivity: [],
          AcademicSelectivity: [],
          StateRegion: [],
          CollegeSize: [],
          TuitionCost: [],
          Settting: [],
          Type: [],
          AreaStudy: [],
          conference: [],
          searchStateRegion: '',
          sortby: updatedValue,
        };
        setFilters(filterInitialValues);
        setFields({
          openFilter: false,
          offset: 1,
          page: '',
          totalCollegeData: null,
          getPage: 0,
          searchPage: 0,
          pageCount: 0,
          perPage: 10,
          go: 1,
        });
        getSearchCollegesData(fieldsT);
      }
    } else if (field == 'sortby' && e?.target?.value == 2) {
      const variables = {
        data: { userid: getUserInfo() ? getUserInfo().id : 0 },
      };
      fetchGraphMethod(allCollegepreferencesettings, variables, true).then(
        (res) => {
          if (res.status == 200) {
            setFields({
              openFilter: false,
              offset: 1,
              page: '',
              totalCollegeData: null,
              getPage: 0,
              searchPage: 0,
              pageCount: 0,
              perPage: 10,
              go: 1,
            });
            if (res?.data?.data?.allCollegepreferencesettings?.totalCount) {
              const PreferenceData =
                res?.data?.data?.allCollegepreferencesettings
                  ?.Collegepreferencesettings[0];
              if (
                !(
                  !PreferenceData?.acadamicselectivityid &&
                  !PreferenceData?.levelid &&
                  !PreferenceData?.regionid &&
                  !PreferenceData?.stateid &&
                  !PreferenceData?.isallstate &&
                  !PreferenceData?.collegesize &&
                  !PreferenceData?.collegesetting &&
                  !PreferenceData?.tutioncost
                )
              ) {
                fieldsT['AcademicSelectivity'] =
                  PreferenceData?.acadamicselectivityid
                    ? JSON.parse(PreferenceData?.acadamicselectivityid)
                    : [];
                fieldsT['athleticselectivityid'] =
                  PreferenceData?.athleticselectivityid
                    ? JSON.parse(PreferenceData?.athleticselectivityid)
                    : [];
                fieldsT['leveliddata'] = PreferenceData?.levelid
                  ? JSON.parse(PreferenceData?.levelid)
                  : [];
                fieldsT['selectAllStates'] = PreferenceData?.isallstate;
                fieldsT['selectedRegions'] = JSON.parse(
                  PreferenceData?.regionid
                );
                fieldsT['selectedStateId'] = JSON.parse(
                  PreferenceData?.stateid
                );
                fieldsT['CollegeSize'] = PreferenceData?.collegesize
                  ? JSON.parse(PreferenceData?.collegesize)
                  : [];
                fieldsT['TuitionCost'] = PreferenceData?.tutioncost
                  ? JSON.parse(PreferenceData?.tutioncost)
                  : [];
                fieldsT['Settting'] = PreferenceData?.collegesetting
                  ? JSON.parse(PreferenceData?.collegesetting)
                  : [];
                fieldsT['AreaStudy'] = PreferenceData?.areaofstudy
                  ? JSON.parse(PreferenceData?.areaofstudy)
                  : [];
              }
            }
            fieldsT[field] = updatedValue;
            setFilters({
              ...fieldsT,
            });
            getSearchCollegesData(fieldsT);
          }
        }
      );
    } else if (
      field == 'sortby' &&
      (e?.target?.value == 0 || e?.target?.value == 4 || e?.target?.value == 5)
    ) {
      const filterInitialValues = {
        collegeName: '',
        favorite: 'any',
        promotions: 'any',
        Level: [],
        athleticSelectivity: [],
        AcademicSelectivity: [],
        StateRegion: [],
        CollegeSize: [],
        TuitionCost: [],
        Settting: [],
        Type: [],
        AreaStudy: [],
        conference: [],
        searchStateRegion: '',
        selectAllStates: 0,
        selectedStateId: [],
        selectedRegions: [],
        sortby: parseInt(e?.target?.value),
      };
      setFilters(filterInitialValues);
      setFields({
        openFilter: false,
        offset: 1,
        page: '',
        totalCollegeData: null,
        getPage: 0,
        searchPage: 0,
        pageCount: 0,
        perPage: 10,
        go: 1,
      });
      getSearchCollegesData(filterInitialValues);
    } else {
      fieldsT[field] = updatedValue;
      setFilters({
        ...fieldsT,
      });
      setFields({
        openFilter: false,
        offset: 1,
        page: '',
        totalCollegeData: null,
        getPage: 0,
        searchPage: 0,
        pageCount: 0,
        perPage: 10,
        go: 1,
      });
      getSearchCollegesData(fieldsT);
    }
  };

  const handleCloseFilter = () => {
    setFields({
      ...fields,
      openFilter: false,
    });
  };

  const handleOpenFilter = () => {
    setFields({
      ...fields,
      openFilter: true,
    });
  };

  const handleDeleteFilter = (data) => {
    const tempData = searchFiltersList.filter((item) => item.id !== data.id);
    setSearchFiltersList(tempData);
    props.deleteSearchFilterStart({
      query: saveSearchFilterQuery,
      variables: {
        obj: {
          userid: data.userid,
          active: 0,
          id: data.id,
        },
      },
      refreshFilterList: refreshFilterList,
    });
  };

  const searchOnCollegeCityName = () => {
    setFields({
      ...fields,
      openFilter: false,
    });
    getSearchCollegesData(filters);
  };

  const handleInputFields = (e) => {
    const { name, value } = e.target;
    setFields({
      openFilter: true,
      offset: 1,
      page: '',
      totalCollegeData: null,
      getPage: 0,
      searchPage: 0,
      pageCount: 0,
      perPage: 10,
      go: 1,
    });

    setFilters({ ...filters, [name]: value });
  };

  const handleLavelFields = (fieldName, value) => {
    setFields({
      openFilter: fields.openFilter,
      offset: 1,
      page: '',
      totalCollegeData: null,
      getPage: 0,
      searchPage: 0,
      pageCount: 0,
      perPage: 10,
      go: 1,
    });
    setFilters({ ...filters, [`${fieldName}`]: value });
  };
  const handleSubLavelFields = (fieldName, value) => {
    setFields({
      openFilter: fields.openFilter,
      offset: 1,
      page: '',
      totalCollegeData: null,
      getPage: 0,
      searchPage: 0,
      pageCount: 0,
      perPage: 10,
      go: 1,
    });
    setFilters({ ...filters, [`${fieldName}`]: value });
  };
  const handleMultiCheckbox = (e, data, field) => {
    const filtersT = filters;
    if (e.target.checked) {
      filtersT[field] = [...filtersT[field], data];
    } else {
      filtersT[field] = filtersT[field].filter((item) => item !== data);
    }
    setFields({
      openFilter: fields.openFilter,
      offset: 1,
      page: '',
      totalCollegeData: null,
      getPage: 0,
      searchPage: 0,
      pageCount: 0,
      perPage: 10,
      go: 1,
    });
    setFilters({ ...filtersT });
  };

  const handleSingleCheckbox = (e, data, field) => {
    const filtersT = filters;
    if (e.target.checked) {
      filtersT[field] = [data];
    } else {
      filtersT[field] = [];
    }
    setFilters({ ...filtersT });
  };
  const handleAddToFavorite = (e, data) => {
    e.preventDefault();
    const offerData =
      props.allOffersList &&
      props.allOffersList.data &&
      props.allOffersList.data.Offers
        ? props.allOffersList.data.Offers
        : null;
    const collegeAddedToOffer =
      offerData &&
      offerData.filter((offer) => {
        return offer.collegeid == data.id;
      });
    if (collegeAddedToOffer) {
      setCollegeIsAddedToOffer(collegeAddedToOffer);
    }
    const commitmentData =
      props.commitmentList && props.commitmentList.data
        ? props.commitmentList.data
        : null;
    const isCommittedToCollege =
      commitmentData &&
      commitmentData.filter((commitment) => {
        return commitment.collegeid == data.id;
      });
    if (isCommittedToCollege) {
      setCommitmentWithCollege(isCommittedToCollege);
    }
    if (!data.isFavorite) {
      const tempData = collegesList.map((item) => {
        if (item.id === data.id) {
          item['isFavorite'] = true;
        }
        return item;
      });
      setCollegeList(tempData);
      setShowInterestModal(true);
      setCollegeId(data.id);
    }
  };
  const handleRemoveCollege = (e, data) => {
    e.preventDefault();
    if (data.isFavorite) {
      const tempData = collegesList.map((item, index) => {
        if (item.id === data.id) {
          item['isFavorite'] = false;
        }
        return item;
      });
      setCollegeList(tempData);
      props.removeUserStart({
        data: {
          collegeRowId: data.collegeRowId,
          userId: getUserInfo().id,
          collegeRank: data.rank ? data.rank : 0,
          collegeid: data.id,
        },
        getCollegesList: getUserCollegeList,
      });
    }
  };
  const onSelectHandler = (interestLevelId) => {
    props.addUserCollegeStart({
      data: {
        collegeId: collegeId,
        userId: getUserInfo() ? getUserInfo().id : 0,
        interestLevel: parseInt(interestLevelId),
        isOffered: collegeIsAddedToOffer.length !== 0 ? 1 : 0,
        isCommitment: commitmentWithCollege.length !== 0 ? 1 : 0,
      },
      getUserCollegeList,
    });
    if (collegeIsAddedToOffer && collegeIsAddedToOffer.length !== 0) {
      props.offersListsaveStart({
        query: saveOffersQuery,
        variables: {
          obj: {
            id: collegeIsAddedToOffer[0] && collegeIsAddedToOffer[0].id,
            interestlevelid: parseInt(interestLevelId),
            userid: getUserInfo() ? getUserInfo().id : null,
          },
        },
      });
    }
    setShowInterestModal(false);
  };
  const onCloseInterestModal = () => {
    const tempData = collegesList.map((item) => {
      if (item.id === collegeId) {
        item['isFavorite'] = false;
      }
      return item;
    });
    setCollegeList(tempData);
    setShowInterestModal(false);
  };
  const clearFilters = (e) => {
    e.preventDefault();
    window.location.reload();
    // setFilters({...filterInitialValues})
  };
  const handleFilterClick = (e, filter) => {
    if (filter.filterData) {
      const tempFilter = {};
      for (const data of filter.filterData) {
        switch (data.appliedfiltername) {
          case 'favorite':
            tempFilter['favorite'] = data.appliedfiltervalue;
            break;
          case 'promotions':
            tempFilter['promotions'] = data.appliedfiltervalue;
            break;
          case 'leveliddata':
            tempFilter['leveliddata'] = data.appliedfiltervalue
              ? data.appliedfiltervalue.split(',')
              : [];
            break;
          case 'academicselectivity':
            tempFilter['AcademicSelectivity'] = data.appliedfiltervalue
              ? data.appliedfiltervalue.split(',')
              : [];
            break;
          case 'stateregion':
            tempFilter['StateRegion'] = data.appliedfiltervalue
              ? data.appliedfiltervalue.split(',')
              : [];
            break;
          case 'collegesize':
            tempFilter['CollegeSize'] = data.appliedfiltervalue
              ? data.appliedfiltervalue.split(',')
              : [];
            break;
          case 'tuitioncost':
            tempFilter['TuitionCost'] = data.appliedfiltervalue
              ? data.appliedfiltervalue.split(',')
              : [];
            break;
          case 'settting':
            tempFilter['Settting'] = data.appliedfiltervalue
              ? data.appliedfiltervalue.split(',')
              : [];
            break;
          case 'type':
            tempFilter['Type'] = data.appliedfiltervalue
              ? data.appliedfiltervalue.split(',')
              : [];
            break;
          case 'areastudy':
            tempFilter['AreaStudy'] = data.appliedfiltervalue
              ? data.appliedfiltervalue.split(',')
              : [];
            break;
          case 'conference':
            tempFilter['conference'] = data.appliedfiltervalue
              ? data.appliedfiltervalue.split(',')
              : '';
            break;
          case 'athleticselectivityid':
            tempFilter['athleticselectivityid'] = data.appliedfiltervalue
              ? JSON.parse(data.appliedfiltervalue)
              : '';
            break;
        }
      }
      setFilters({ ...filters, ...tempFilter });
    }
  };

  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  const handleGoToPage = async (e) => {
    goTop();
    if (collegesList) {
      const fieldsT = fields;
      fieldsT['getPage'] = +fields.go - 1;
      fieldsT['pageCount'] = +fields.go;
      setFields(fieldsT);
      handlenextPage();
    }
  };
  const getAthleteDetail = async () => {
    const _res = await fetchGraphMethod(
      getAllAthletesDataQuery,
      { obj: { id: getUserInfo() ? getUserInfo().id : 0 } },
      true
    );
    if (_res?.status == 200) {
      setAthDetail(_res?.data?.data?.allAthletes?.Athletes?.[0] ?? {});
    } else {
      setAthDetail({});
    }
  };
  const toolTipPop = (tool_data) => {
    Swal.fire({
      text: tool_data,
      confirmButtonColor: '#00B9FF',
    });
    // messagePopup("",tool_data,"")
  };
  return (
    <div>
      <BackdropLoader open={props.searchCollegeList.loading} />
      <div className="searchDataSection">
        <div className="container">
          <input className="fakeInput" type="text" ref={fakeInput} />
          <div className="searchDataFlexBox">
            <div
              className={`searchSideMenu ${
                fields.openFilter ? 'slideMenuFilter' : ''
              }`}
            >
              <div className="backDrop" onClick={handleCloseFilter}></div>
              <Filter
                closeFilter={handleCloseFilter}
                fields={filters}
                handleInputFields={handleInputFields}
                handleMultiCheckbox={handleMultiCheckbox}
                handleSingleCheckbox={handleSingleCheckbox}
                searchOnCollegeCityName={searchOnCollegeCityName}
                handleFilterClick={handleFilterClick}
                handleLavelFields={handleLavelFields}
                handleSubLavelFields={handleSubLavelFields}
                filterList={
                  searchFiltersList
                    ? searchFiltersList.length
                      ? searchFiltersList.slice(0, 3)
                      : []
                    : []
                }
                allFilters={searchFiltersList}
                searchAreaOfStudy={searchAreaOfStudy}
                refreshFilterList={refreshFilterList}
                //stateList={props.states.data ? props.states.data : null}
                stateList={sortedStates?.length ? sortedStates : null}
                areaOfStudyList={areaOfStudyList}
                collegeData={props.collegesList.data}
                toolTipPop={toolTipPop}
              />
            </div>

            <div className="searchDataBox">
              <div className="headerFlex">
                <CollegesTabs active="search" />
                <div className="filterbtnBox">
                  <button className="btn" onClick={handleOpenFilter}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="21.989"
                      viewBox="0 0 24 21.989"
                    >
                      <g transform="translate(-11.391 -14.238)">
                        <path
                          className="a"
                          d="M87.328,82.345h-14.3a2.949,2.949,0,0,0-5.592,0H65.206a.939.939,0,1,0,0,1.878h2.232a2.949,2.949,0,0,0,5.592,0h14.3a.939.939,0,1,0,0-1.878ZM70.234,84.356a1.072,1.072,0,1,1,1.072-1.072A1.074,1.074,0,0,1,70.234,84.356Z"
                          transform="translate(-52.876 -66.096)"
                        />
                        <path
                          className="a"
                          d="M87.328,210.878H85.1a2.949,2.949,0,0,0-5.592,0h-14.3a.939.939,0,1,0,0,1.878H79.5a2.949,2.949,0,0,0,5.592,0h2.232a.939.939,0,1,0,0-1.878ZM82.3,212.889a1.072,1.072,0,1,1,1.072-1.072A1.074,1.074,0,0,1,82.3,212.889Z"
                          transform="translate(-52.876 -186.584)"
                        />
                        <path
                          className="a"
                          d="M87.328,339.411H77.052a2.949,2.949,0,0,0-5.592,0H65.206a.939.939,0,0,0,0,1.878H71.46a2.949,2.949,0,0,0,5.592,0H87.328a.939.939,0,1,0,0-1.878Zm-13.072,2.011a1.072,1.072,0,1,1,1.072-1.072A1.074,1.074,0,0,1,74.256,341.422Z"
                          transform="translate(-52.876 -307.073)"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              </div>

              <div className="dataSection">
                <div className="sortFilter">
                  <form>
                    <ul>
                      <li className="total">
                        <span>
                          {props?.searchCollegeList?.data?.totalCount
                            ? props?.searchCollegeList?.data?.totalCount.toLocaleString(
                                'en-US',
                                { maximumFractionDigits: 2 }
                              )
                            : 0}{' '}
                          Colleges
                        </span>
                      </li>
                      <li>
                        <button onClick={(e) => clearFilters(e)}>
                          Clear Filters
                        </button>
                      </li>
                      <li>
                        {searchFiltersList && searchFiltersList.length != 0 ? (
                          <span className="saveFilterCount">
                            {searchFiltersList?.length || null}
                          </span>
                        ) : null}
                        <button
                          className="saveFilterBtn"
                          type="button"
                          data-toggle="modal"
                          data-target="#savedFilersModal"
                          onClick={() => setIsOpen(true)}
                        >
                          Saved Filters
                        </button>
                      </li>
                      <li>
                        <select
                          // sortOnRankOrDistance
                          value={filters.sortby ?? 0}
                          onChange={(e) => handleChange('sortby', e)}
                        >
                          <option value={4}>Ranking</option>
                          <option value={1}>Distance</option>
                          <option value={2}>Preference</option>
                          <option value={0}>Alphabetically</option>
                          <option value={5}>Tagged</option>
                        </select>
                      </li>
                    </ul>
                  </form>
                </div>
                <div className="dataBox">
                  {collegesList && collegesList.length ? (
                    collegesList.map((item, index) => (
                      <CollegeItemCard
                        key={index}
                        data={item}
                        handleAddToFavorite={handleAddToFavorite}
                        handleRemoveCollege={handleRemoveCollege}
                      />
                    ))
                  ) : (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                      <span>No colleges found</span>
                    </div>
                  )}
                </div>
                {/*{fields.pageCountT != "" ?*/}
                <div className="paginationBox">
                  <div className="perPage">
                    <input
                      type="number"
                      placeholder={'10'}
                      value={fields.perPage}
                      onChange={(e) =>
                        parseInt(e.target.value) <= 0 || e.target.value == ''
                          ? console.log('invalid number')
                          : setFields({ ...fields, perPage: +e.target.value })
                      }
                    />
                    <label>Per Page</label>
                  </div>

                  <div className="pageNav">
                    <ReactPaginate
                      previousLabel={'<<'}
                      nextLabel={'>>'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={fields.pageCountT || 0}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      forcePage={fields.getPage}
                      // containerClassName={"pagination"}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  </div>
                  <div className="goBox">
                    <input
                      type="number"
                      placeholder={'1'}
                      value={fields.go}
                      onChange={(e) =>
                        parseInt(e.target.value) <= 0 || e.target.value == ''
                          ? console.log('inter valid number')
                          : setFields({ ...fields, go: +e.target.value })
                      }
                    />
                    <a onClick={(e) => handleGoToPage(e)}>
                      <span>Go </span>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="4.568"
                          height="7.99"
                          viewBox="0 0 4.568 7.99"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <path
                            className="a"
                            d="M1.377,3.993,4.4.972a.569.569,0,0,0,0-.806.576.576,0,0,0-.809,0L.166,3.589a.57.57,0,0,0-.017.787l3.44,3.447A.571.571,0,0,0,4.4,7.017Z"
                            transform="translate(4.568 7.99) rotate(180)"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                {/*// : <p style={{ marginTop: "50px", textAlign: "center" }}></p>}*/}
                <GoTopButton goTop={goTop} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={modalIsOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            setIsOpen(false);
          }
        }}
        className="matDialogCustom matCustomSize"
      >
        <SavedFilterModal
          onClose={() => setIsOpen(false)}
          filterList={searchFiltersList ? searchFiltersList : []}
          handleDeleteFilter={handleDeleteFilter}
          handleFilterClick={handleFilterClick}
        />
      </Dialog>
      <Dialog
        open={showInterestModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            onCloseInterestModal();
          }
        }}
        className="matDialogCustom"
      >
        <InterestLevelModal
          interestValueList={props.interestLevelDetails.data}
          onClose={onCloseInterestModal}
          onSelect={onSelectHandler}
          collegeIsAddedToOffer={collegeIsAddedToOffer}
        />
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    searchCollegeList: state.athlete.dashboard.colleges.searchCollegeList,
    searchFilters: state.athlete.dashboard.colleges.searchFilters,
    states: state.common.states,
    areOfStudyList: state.athlete.dashboard.colleges.areOfStudyList,
    collegesList: state.athlete.dashboard.colleges.collegesList,
    interestLevelDetails: state.common.interestLevelList,
    allOffersList: state.athlete.editUserProfileData.offersData,
    commitmentList: state.athlete.profile.commitments,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchCollegeListAPIStart: (data) =>
      dispatch(searchCollegeListAPIStart(data)),
    searchFiltersListStart: (data) => dispatch(searchFiltersListStart(data)),
    stateListStart: (data) => dispatch(stateListStart(data)),
    areaOfStudyListStart: (data) => dispatch(areaOfStudyListStart(data)),
    deleteSearchFilterStart: (data) => dispatch(deleteSearchFilterStart(data)),
    collegesListStart: (data) => dispatch(collegesListStart(data)),
    addUserCollegeStart: (data) => dispatch(addUserCollegeStart(data)),
    interestLevelListStart: (data) => dispatch(interestLevelListStart(data)),
    removeUserStart: (data) => dispatch(removeUserStart(data)),
    offersListStart: (data) => dispatch(offersListStart(data)),
    getCommitmentStart: (data) => dispatch(getCommitmentStart(data)),
    offersListsaveStart: (data) => dispatch(offersListsaveStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchColleges);

// const getCollegeSizeData = (id) => {
//   switch (id) {
//     case 1:
//       return {
//         collegeSize1: "3000",
//         collegeSizeEvaluationValue: "L",
//         collegeSize2: "",
//       };
//     case 2:
//       return {
//         collegeSize1: "3000",
//         collegeSizeEvaluationValue: "B",
//         collegeSize2: "10000",
//       };
//     case 3:
//       return {
//         collegeSize1: "10000",
//         collegeSizeEvaluationValue: "B",
//         collegeSize2: "20000",
//       };
//     case 4:
//       return {
//         collegeSize1: "20000",
//         collegeSizeEvaluationValue: "M",
//         collegeSize2: "",
//       };
//     default:
//       return {
//         collegeSize1: "",
//         collegeSizeEvaluationValue: "",
//         collegeSize2: "",
//       };
//   }
// };

// const getTutionFeeData = (id) => {
//   switch (id) {
//     case 1:
//       return {
//         collegeTutionFee1: "10000",
//         collegeTutionFee2: "",
//         collegeTutionFeeEvaluationValue: "L",
//       };
//     case 2:
//       return {
//         collegeTutionFee1: "10000",
//         collegeTutionFee2: "25000",
//         collegeTutionFeeEvaluationValue: "B",
//       };
//     case 3:
//       return {
//         collegeTutionFee1: "25000",
//         collegeTutionFee2: "",
//         collegeTutionFeeEvaluationValue: "M",
//       };

//     default:
//       return {
//         collegeSize1: "",
//         collegeSizeEvaluationValue: "",
//         collegeSize2: "",
//       };
//   }
// };
