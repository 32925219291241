import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { collegeCampusesListStart } from '../../../../../../redux/athlete/dashboard/colleges/collegeCampuses'
import { getAllCollegeCampusesQuery } from './CollegeDetailsQuery'
import SideBarAthleteColleges from './SideBarAthleteColleges'
import BackdropLoader from "../../../../../common/Loader";
import { Dialog } from "@mui/material";
import Map from "../Map";
import { getCamelCase } from '../../../../../common/camelCaseFunc'
import {Link} from "react-router-dom";

const CaampusLife = (props) => {
  const [campusData, setCampusData] = useState(null);
  const [openMapModal, setOpenMapModal] = useState(false)

  useEffect(() => {
    getCampusesData();
  }, [])

  useEffect(() => {
    if (props.collegeCampuses && props.collegeCampuses.data && props.collegeCampuses.data[0]) {
      setCampusData(props.collegeCampuses.data[0]);
    }
  }, [props.collegeCampuses.data])

  const getCampusesData = () => {
    props.collegeCampusesListStart({
      query: getAllCollegeCampusesQuery,
      variables: {
        where: {
          collegeid: props?.collegeid
        }
      }
    })
  }
  const onCloseMap = () => {
    setOpenMapModal(false)
  }
  return (
    <>
      <div id="CampusLife">
        <BackdropLoader open={props.collegeCampuses.loading} />
        <div className="page-with-sidebar">
          <SideBarAthleteColleges handleSimilarCollgClick={props?.handleSimilarCollgClick ? props.handleSimilarCollgClick : () => console.log('FuncClicked')}/>
          <div className="admissions-data">
            <div className="item">
              <h3 className="box-title">Demographics</h3>
              <ul>
                <li>
                  <span>Code:</span> <span>{campusData?.coed == 1 ? "Yes" : "--"}</span>
                </li>
                <li>
                  <span>African American:</span> <span>{campusData && campusData.africanamerica ? campusData.africanamerica + "%" : "--"}</span>
                </li>
                <li>
                  <span>International (Non-Citizen):</span>{" "}
                  <span>{campusData && campusData.international ? campusData.international + "%" : "--"}</span>
                </li>
                <li>
                  <span>Native American:</span> <span>{campusData && campusData.nativeamerica ? campusData.nativeamerica + "%" : "--"}</span>
                </li>
                <li>
                  <span>Pacific Islander:</span> <span>{campusData && campusData.pacificislander ? campusData.pacificislander + "%" : "--"}</span>
                </li>
                <li>
                  <span>Asian:</span> <span>{campusData && campusData.asian ? campusData.asian + "%" : "--"}</span>
                </li>
                <li>
                  <span>Hispanic:</span> <span>{campusData && campusData.hispanic ? campusData.hispanic + "%" : "--"}</span>
                </li>
                <li>
                  <span>Multiracial:</span> <span>{campusData && campusData.multiracial ? campusData.multiracial + "%" : "--"}</span>
                </li>
                <li>
                  <span>Unknown:</span> <span>{campusData && campusData.unknown ? campusData.unknown + "%" : "--"}</span>
                </li>
                <li>
                  <span>White:</span> <span>{campusData && campusData.white ? campusData.white + "%" : "--"}</span>
                </li>
                <li>
                  <span>Female Varsity Athletes:</span> <span>{campusData && campusData.femalevarsityathletes ? campusData.femalevarsityathletes + "%" : "--"}</span>
                </li>
                <li>
                  <span>Male Varsity Athletes:</span> <span>{campusData && campusData.malevarsityathletes ? campusData.malevarsityathletes + "%" : "--"}</span>
                </li>
                <li>
                  <span>Male:</span> <span>{campusData && campusData.male ? campusData.male + "%" : "--"}</span>
                </li>
                <li>
                  <span>Female:</span> <span>{campusData && campusData.female ? campusData.female + "%" : "--"}</span>
                </li>
                <li>
                  <span>HBCU:</span> <span>{campusData && campusData?.hbcu == 1 ? "True" : "--" }</span>
                </li>
                <li>
                  <span>Religious affiliation:</span> <span>{campusData?.religiousaffiliation ?? "--"}</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <h3 className="box-title">Location</h3>
              <ul>
                <li>
                  <span>County:</span> {campusData && campusData.county ? campusData.county : "--"}<span></span>
                </li>
                <li>
                  <span>Metro area:</span> <span>{campusData && campusData.metroarea ? campusData.metroarea : "--"}</span>
                </li>
                <li>

                  <span>Address:</span>
                  <span>{campusData != null ? (getCamelCase(campusData?.collegecampushcollegeidmaprel?.Colleges?.[0]?.locationaaddress ?? "")) : " "}
                    {!!campusData?.collegecampushcollegeidmaprel?.Colleges?.[0]?.city ? `, ${getCamelCase(campusData?.collegecampushcollegeidmaprel?.Colleges?.[0]?.city)}` : ""}
                    {!!campusData?.collegecampushcollegeidmaprel?.Colleges?.[0]?.state ? `, ${getCamelCase(campusData?.collegecampushcollegeidmaprel?.Colleges?.[0]?.state)}` : ""}
                  </span>
                </li>
                <li>
                  <div className="map-wrap">
                    <img src="img/campus-life-map.png" alt="" />
                    <div className="map-data">
                      <span>
                      </span>{" "}
                      <span>
                        <a onClick={(e) => { setOpenMapModal(true) }}>View on Google Maps</a>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <Dialog open={openMapModal}
                    onClose={(event, reason)=> {
                      if (reason == "backdropClick" || reason == "escapeKeyDown") {
                        onCloseMap()
                      }
                    }}
            >
              <div className="map-modal-wrap">
                <div className="mapclose-button">
                  <button
                    onClick={() => onCloseMap()}
                  >&times;</button>
                </div>
                <Map
                  markers={[
                    {
                      latitude: campusData != null ? (campusData.collegecampushcollegeidmaprel.Colleges[0].collegelat) : " ",
                      longitude: campusData != null ? (campusData.collegecampushcollegeidmaprel.Colleges[0].collegelng) : " "
                    },
                  ]}
                />
              </div>
            </Dialog>
            <div className="item">
              <h3 className="box-title">Housing</h3>
              <ul>
                <li>
                  <span>Housing Types:</span> <span>{campusData && campusData.housingtype ? campusData.housingtype : "--"}</span>
                </li>
                <li>
                  <span>On Campus Housing Available:</span>{" "}
                  <span>{campusData && campusData.oncampushousingavailable == 1 ? "Yes" : campusData && campusData.oncampushousingavailable == 0 ? "No" : "--"}</span>
                </li>
                {/* <li>
                  <span>Talk to Alex:</span>{" "}
                  <span className="link">
                    <a href={campusData && campusData.talktoalex ? campusData.talktoalex : "#"} target="_blank" >{campusData && campusData.talktoalex ? campusData.talktoalex : "--"}</a>
                  </span>
                </li> */}
              </ul>
            </div>
            <div className="item">
              <h3 className="box-title">ROTC</h3>
              <ul>
                <li>
                  <span>Air Force:</span>{campusData && campusData.airforce == 1 ? "Yes" : campusData && campusData.airforce == 0 ? "No" : "--"}<span></span>
                </li>
                <li>
                  <span>Army:</span> <span>{campusData && campusData.army == 1 ? "Yes" : campusData && campusData.army == 0 ? "No" : "--"}</span>
                </li>
                <li>
                  <span>Navy:</span> <span>{campusData && campusData.navy == 1 ? "Yes" : campusData && campusData.navy == 0 ? "No" : "--"}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div className="back-button text-right pt-3">
        <Link className="btn green" to="/colleges/search">Back</Link>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    collegeCampuses: state.athlete.dashboard.colleges.collegeCampuses,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    collegeCampusesListStart: (data) => dispatch(collegeCampusesListStart(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CaampusLife);