import { connect } from "react-redux";
import { offersListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/offers";
import { getUserInfo } from "../../../../../../services/authService";
import { getAllOfferForDashboardQuery } from "../../editUserProfile/OfferQuery";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";

import TeamLogoNew from "../assets/img/TeamLogoNew.png";
import { getSlashFormattedDate, getUTCTimeDate, getUTCTimeDateOverall } from "../../../../../../utils/dateTimeFormatter";
import { Link } from "react-router-dom";
import Mcc from "../assets/img/mcc.png";
import { viewCollegeSaveStart } from "../../../../../../redux/athlete/dashboard/colleges/viewCollege";
import { saveViewCollegeQuery } from "../../colleges/searchColleges/SearchCollegesQuery";

import {imageDownloadUrl} from "../../../../../../config";
const MyOffers = (props) => {
  let history = useHistory();

  const [offerList, setOfferList] = useState(null);
  const [actualOfferList, setActualOfferList] = useState(null);

  useEffect(() => {
    getCollegeOffers();
  }, []);

  useEffect(() => {
    if (props.allOffersData.data) {
      const temOfferData = props.allOffersData.data.Offers
        ? [...props.allOffersData.data.Offers]
        : [];
      const topTwoOffer = temOfferData.slice(0, 2);
      setOfferList(topTwoOffer);
      setActualOfferList(temOfferData);
    }
  }, [props.allOffersData.data]);

  const getCollegeOffers = (condition) => {
    props.offersListStart({
      query: getAllOfferForDashboardQuery(getUserInfo() ? getUserInfo().id : 0),
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
          order: "id DESC",
        },
      },
    });
  };
  const routeToPage = (e, route) => {
    e.preventDefault();
    history.push(route);
  };

  const handleCollegeClick = (collgId) => {
    props.viewCollegeSaveStart({
      query : saveViewCollegeQuery,
      variables : {
        obj : {
          athleteid : getUserInfo() ? getUserInfo().id : null,
          collegeid : collgId,
          collegeviewstatus : 1,
        }
      }
    })
  }

  return (
    <div className="offer">
      <div className="head">
        <h5 className="title">
          Offers {actualOfferList && actualOfferList.length ? ` (${actualOfferList.length})` : ""}
        </h5>
        <a
          href={void 0}
          className="editLink"
          onClick={(e) => {
            routeToPage(e, "/Offers");
          }}
        >
          {actualOfferList && actualOfferList.length ? "Edit" : "Add"}
        </a>
      </div>
      <ul>
        {offerList && offerList.length ? (
          offerList.map((offerData, index) => (
            <li key={index}>
              <div className="imgBox">
                <img src={!!offerData?.offercollegeidmaprel?.Colleges?.[0]?.collegelogo ?
                    `${imageDownloadUrl}/${offerData?.offercollegeidmaprel?.Colleges?.[0]?.collegelogo}` : TeamLogo
                } alt="Logo" />
              </div>
              <div className="info">
                <Link
                  to={`/colleges/details/${
                    offerData.offercollegeidmaprel
                      ? offerData.offercollegeidmaprel.Colleges[0] &&
                        offerData.offercollegeidmaprel.Colleges[0].id
                      : 0
                  }/${
                    offerData.offercollegeidmaprel &&
                    offerData.offercollegeidmaprel.Colleges[0] &&
                    offerData.offercollegeidmaprel.Colleges[0]
                      .userCollegeCollegeIdMap != undefined &&
                    offerData.offercollegeidmaprel.Colleges[0]
                      .userCollegeCollegeIdMap.Usercolleges != ""
                      ? offerData.offercollegeidmaprel.Colleges[0]
                          .userCollegeCollegeIdMap.Usercolleges[0].isfav
                      : 0
                  }`}
                  onClick={() => handleCollegeClick(offerData.offercollegeidmaprel.Colleges[0].id)}
                >
                  <h5 className="title">
                    {offerData.offercollegeidmaprel
                      ? offerData.offercollegeidmaprel.Colleges[0] &&
                        offerData.offercollegeidmaprel.Colleges[0].collegename
                      : ""}
                  </h5>
                </Link>
                <p className="date">
                  <label>Offer Date:</label>
                  <span>
                    {offerData.offerdate
                      ? getUTCTimeDateOverall(offerData.offerdate)
                      : ""}
                  </span>
                </p>
              </div>
            </li>
          ))
        ) : (
          <div
            style={{
              textAlign: "left",
              fontSize: "14px",
              padding: "0px 6px",
            }}
          >
            No offer is received yet
          </div>
        )}
      </ul>
      {actualOfferList && actualOfferList.length > 2 ? (
        <p className="viewMore">
          <a
            href={void 0}
            onClick={(e) => {
              routeToPage(e, "/Offers");
            }}
          >
            View More
          </a>
        </p>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allOffersData: state.athlete.editUserProfileData.offersData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    offersListStart: (data) => dispatch(offersListStart(data)),
    viewCollegeSaveStart: (data) => dispatch(viewCollegeSaveStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyOffers);
