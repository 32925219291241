import {combineReducers} from 'redux'
import messageListReducer from './message';
import premiumDropdownReducer from './premiumDropdown';
import messageCountListReducer from './messageCount';
import checkFollowDropdownReducer from './checkFollowStatus';
import getUserdataListReducer from "./getUserData";

export default combineReducers({
    messageList: messageListReducer,
    premiumDropdown: premiumDropdownReducer,
    messageCountList: messageCountListReducer,
    checkFollowStatus: checkFollowDropdownReducer,
    getUserdata: getUserdataListReducer
})