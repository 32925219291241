import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../../services/httpService";

// ========================================== Action Type =====================================================
export const actionType = {
  NOTIFICATION_UNREAD_COUNT_LIST_START: "NOTIFICATION_UNREAD_COUNT_LIST_START",
  NOTIFICATION_UNREAD_COUNT_LIST_SUCCESS: "NOTIFICATION_UNREAD_COUNT_LIST_SUCCESS",
  NOTIFICATION_UNREAD_COUNT_LIST_FAILED: "NOTIFICATION_UNREAD_COUNT_LIST_FAILED"
};

// ============================================ Actions ========================================================

export const notificationUnreadCountListStart = data => ({
  type: actionType.NOTIFICATION_UNREAD_COUNT_LIST_START,
  payload: data
});

export const notificationUnreadListSuccess = data => ({
  type: actionType.NOTIFICATION_UNREAD_COUNT_LIST_SUCCESS,
  payload: data
});

export const notificationUnreadListFailed = data => ({
  type: actionType.NOTIFICATION_UNREAD_COUNT_LIST_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function notificationUnreadListReducer(state = INIT_STATE, action) {
  switch (action.type) {    
    case actionType.NOTIFICATION_UNREAD_COUNT_LIST_START:
      return {
        ...state,
        loading: true
      };

    case actionType.NOTIFICATION_UNREAD_COUNT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.NOTIFICATION_UNREAD_COUNT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* notificationUnreadListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
        const allNotifications =
        response &&
        response.data &&
        response.data.data &&
        response.data.data.allNotifications &&
        response.data.data.allNotifications.Notifications
          ? response.data.data.allNotifications.Notifications
          : null;
      yield put(
        notificationUnreadListSuccess(allNotifications)
      );
    } else {
      messagePopup("", "Notification failed", "error");
      yield put(notificationUnreadListFailed("Notification failed"));
    }
  } catch (error) {
    messagePopup("", "Notification failed", "error");
    yield put(notificationUnreadListFailed("Notification failed"));
  }
}

export function* watchMessageCountListSaga() {
  yield takeEvery(actionType.NOTIFICATION_UNREAD_COUNT_LIST_START, notificationUnreadListSaga);
}