import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    COLLEGE_INSIGHTS_LIST_START: 'COLLEGE_INSIGHTS_LIST_START',
    COLLEGE_INSIGHTS_SAVE_START: 'COLLEGE_INSIGHTS_SAVE_START',
    COLLEGE_INSIGHTS_SUCCESS: 'COLLEGE_INSIGHTS_SUCCESS',
    COLLEGE_INSIGHTS_FAILED: 'COLLEGE_INSIGHTS_FAILED'
}

// ============================================ Actions ========================================================
export const collegeInsightsListStart = (data) => ({
    type: actionType.COLLEGE_INSIGHTS_LIST_START,
    payload: data
})

export const collegeInsightssaveStart = (data) => ({
    type: actionType.COLLEGE_INSIGHTS_SAVE_START,
    payload: data
})

export const collegeInsightsSuccess = (data) => ({
    type: actionType.COLLEGE_INSIGHTS_SUCCESS,
    payload: data
})

export const collegeInsightsFailed = (data) => ({
    type: actionType.COLLEGE_INSIGHTS_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function collegeInsightsReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.COLLEGE_INSIGHTS_LIST_START:
            return {
                ...state,
                loading: true
            }
        case actionType.COLLEGE_INSIGHTS_SAVE_START:
            return {
                ...state,
                loading: true
            }
        case actionType.COLLEGE_INSIGHTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        case actionType.COLLEGE_INSIGHTS_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        default: return { ...state }
    }
}

// ===================================================== side effect =============================================

export function* collegeInsightssaveSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            //this if else condition is not needed
            // if (action.payload.nextScreen !== null) {
            //     action?.payload?.nextScreen()
            // } else {
                yield put(collegeInsightsSuccess(''));
            // }
        } else {
            messagePopup('', 'COLLEGE_INSIGHTS List failed', 'error')
            yield put(collegeInsightsFailed('COLLEGE_INSIGHTS List failed'))
        }
    } catch (error) {
        messagePopup('', 'COLLEGE_INSIGHTS List failed', 'error')
        yield put(collegeInsightsFailed('COLLEGE_INSIGHTS List failed'))
    }
}

export function* collegeInsightslistSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            let athleteCollegeInsightData = response.data.data.allCollegeinsights
            yield put(collegeInsightsSuccess(athleteCollegeInsightData));
        } else {
            messagePopup('', 'COLLEGE_INSIGHTS List failed', 'error')
            yield put(collegeInsightsFailed('COLLEGE_INSIGHTS List failed'))
        }
    } catch (error) {
        messagePopup('', 'COLLEGE_INSIGHTS List failed', 'error')
        yield put(collegeInsightsFailed('COLLEGE_INSIGHTS List failed'))
    }
}


export function* watchCollegeInsightssaveSaga() {
    yield takeEvery(actionType.COLLEGE_INSIGHTS_LIST_START, collegeInsightslistSaga)
    yield takeEvery(actionType.COLLEGE_INSIGHTS_SAVE_START, collegeInsightssaveSaga)

}