import React, { useState, useEffect, useRef } from "react";
import closeGrayIcon from "./assets/img/close-gray-icon.svg";
import trashCircleIcon from "./assets/img/trash-circle-icon.svg";
import LeftSideEditProfile from "./LeftSideEditProfile";
import FormControl from "@mui/material/FormControl";
import { MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import MuiAutoComplete from "../../../../common/AutoComplete";
import { Title } from "./videoPhoto/Component";
import Dialog from "@mui/material/Dialog";
import moment from 'moment';
import { connect, useDispatch } from "react-redux";
import {
  filterSchoolNameQuery,
  filterCollegeNameQuery,
  getAllAthleteMoreSchoolQuery,
  saveAllAthleteMoreSchoolQuery
} from './AcademicsQuery';
import { fetchGraphMethod } from '../../../../../services/httpService';
import { allCollegeDetailsListStart } from "../../../../../redux/common/allCollegeData";
import { getAllCollegeQuery } from "../profile/ProfileEdit/profileQuery";
import { allTeamsListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/allTeams";
import {
  getAllTeams,
  getAllUserCollege,
  getAllUserSchool,
  getCollegeDivisions,
  getCollegeLevels,
  getSchoolDivisions,
  getSchoolLevels,
  getSchoolName,
  getSchoolType,
  saveUserCollege,
  saveUserSchool,
} from "./AcademicsQuery";
import { graduationMonthsListStart } from "../../../../../redux/common/graduationMonth";
import { graduationYearsListStart } from "../../../../../redux/common/graduationYears";
import {
  allLevelsCollegeStart,
  allLevelsSchoolStart,
} from "../../../../../redux/athlete/dashboard/editUserProfile/academicLevels";
import {
  allAcademicDivisionsListStart,
  allAcademicDivisionsSchoolListStart,
} from "../../../../../redux/athlete/dashboard/editUserProfile/academicDivisions";

import { getUserInfo } from "../../../../../services/authService";
import {
  userAcademicCollegeStart,
  userAcademicCollegeSave,
  userAcademicRestCollegeSave
} from "../../../../../redux/athlete/dashboard/editUserProfile/academicCollege";
import messagePopup from "../../../../../utils/messagePopup";
import { allSchoolTypeListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/allSchoolType";
import { allSchoolListStart } from "../../../../../redux/athlete/dashboard/editUserProfile/allHighSchool";
import { AplhaNumericInput, isValidZipcode } from "../../../../../utils/validationUtils";
import {
  userAcademicSchoolSave,
  userAcademicSchoolStart,
  userAcademicRestSchoolSave
} from "../../../../../redux/athlete/dashboard/editUserProfile/academicSchool";
import BackdropLoader from "../../../../common/Loader";
import Autocomplete from '@mui/material/Autocomplete';
import SubHeader from "./SubHeader";
import ScreenSwitch from "./ScreenSwitch";
import GoTopButton from "./GoTopButton";
import swal from "sweetalert";
import { getCamelCase } from "../../../../common/camelCaseFunc";
import toastr from "toastr";

const selectStyle = {
  display: "block",
  fontSize: ".875rem",
  fontWeight: 400,
  color: "#3e3e3e",
  marginBottom: "10px"
}

const selectStyleEndClg = {
  display: "block",
  fontSize: ".875rem",
  fontWeight: 400,
  color: "#3e3e3e",
  marginBottom: "10px",
  marginTop: "6px"
}
const SelectBox = ({
  dataList,
  keyValue,
  onChangeObj,
  selectedValue,
  getValue = "id",
  defaultText,
}) => {
  const onChange = (event) => {
    onChangeObj(event, event.target.value);
  };

  const getMenuItems = () => {
    return dataList.map((item) => {
      return (
        <MenuItem key={item.id} value={keyValue == "monthvalue" ? item?.id?.toString() : item[getValue]}>
          {item[keyValue]}
        </MenuItem>
      );
    });
  };
  return (
    <FormControl variant="outlined" fullWidth>
      <Select
        onChange={onChange}
        // id="select1"
        // label="Select"
        placeholder="Date"
        value={selectedValue}
        displayEmpty
      >
        <MenuItem value="">{defaultText}</MenuItem>
        {getMenuItems()}
      </Select>
    </FormControl>
  );
};

const SchoolBox = ({ Name, onEditClick, onDeleteClick, userSchoolData }) => {
  const onEditHandler = () => {
    onEditClick(userSchoolData);
  };
  const onDeleteHandler = () => {
    onDeleteClick(userSchoolData.id);
  };
  return (
    <li>
      <div className="title-box">
        <h3>{getCamelCase(Name)}</h3>
        <div className="button-box">
          <button className="trash-button" onClick={onDeleteHandler}>
            <span className="icon-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.9"
                height="17.5"
                viewBox="0 0 15.9 17.5"
              >
                <g transform="translate(-3.75 -2.25)">
                  <path
                    className="a"
                    d="M4.5,9H18.9"
                    transform="translate(0 -2.8)"
                  />
                  <path
                    className="a"
                    d="M18.7,6.2V17.4A1.6,1.6,0,0,1,17.1,19h-8a1.6,1.6,0,0,1-1.6-1.6V6.2m2.4,0V4.6A1.6,1.6,0,0,1,11.5,3h3.2a1.6,1.6,0,0,1,1.6,1.6V6.2"
                    transform="translate(-1.4)"
                  />
                  <path
                    className="a"
                    d="M15,16.5v4.8"
                    transform="translate(-4.9 -6.3)"
                  />
                  <path
                    className="a"
                    d="M21,16.5v4.8"
                    transform="translate(-7.7 -6.3)"
                  />
                </g>
              </svg>
            </span>
            Delete
          </button>
          <button className="edit-button" onClick={onEditHandler}>
            <span className="icon-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.641"
                height="15.578"
                viewBox="0 0 15.641 15.578"
              >
                <path
                  className="a"
                  d="M15.24,2.528,13.187.475a1.515,1.515,0,0,0-2.138.086A1.517,1.517,0,0,0,10.962,2.7l2.053,2.053a1.516,1.516,0,0,0,2.139-.086,1.516,1.516,0,0,0,.085-2.139ZM2.15,9.458l4.106,4.106L12.93,6.892,8.824,2.786,2.15,9.458ZM0,15.651l5.416-1.085L1.085,10.235Z"
                  transform="translate(0 -0.074)"
                />
              </svg>
            </span>
            Edit
          </button>
        </div>
      </div>
      <ul className="reference-detail-box">
        <li>
          <span>Zip Code</span> <span>{userSchoolData.zipcode == "null" ? "" : userSchoolData.zipcode}</span>
        </li>
        <li>
          <span>High School Type</span>
          <span>{userSchoolData.schooltypes.schooltypename}</span>
        </li>
        <li>
          <span>Division</span>
          <span>{userSchoolData.schooldivisions.division}</span>
        </li>
        <li>
          <span>
            {/*Not in high school currently?*/}
            Post High School?
          </span>
          <span>
            <p className="checkbox-list">
              <span>
                <input
                  checked={userSchoolData.currentltnotinhighschool || ""}
                  type="checkbox"
                  readOnly
                  style={{ cursor: "default" }}
                />
                <label>&nbsp;</label>
              </span>
            </p>
          </span>
        </li>
        {userSchoolData.currentltnotinhighschool !== 0 && (
          <li>
            <span>Option</span>
            <span>{userSchoolData.schoolcurrentlevels.currentlevelvalue}</span>
          </li>
        )}
        <li>
          <span>Start Date</span>
          <span>
            {/*{!!userSchoolData?.startdatemonth ?*/}
            {/*    moment().month(userSchoolData?.startdatemonth - 1).format("MMM")*/}
            {/*:""*/}
            {/*}*/}
            {userSchoolData.startdatemonth}
            {userSchoolData.startdatemonth &&
              userSchoolData.startdateyear &&
              "/"}
            {userSchoolData.startdateyear}
          </span>
        </li>
        <li>
          <span>End Date</span>
          <span>
            {/*{!!userSchoolData?.enddatemonth ?*/}
            {/*    moment().month(userSchoolData?.enddatemonth - 1).format("MMM")*/}
            {/*:*/}
            {/*    ""}*/}
            {userSchoolData.enddatemonth}
            {userSchoolData.enddatemonth && userSchoolData.enddateyear && "/"}
            {userSchoolData.enddateyear}
          </span>
        </li>
      </ul>
    </li>
  );
};
const CollegeBox = ({
  Name,
  onAddEditCollegeClick,
  onDeleteClick,
  userCollegeData,
}) => {
  const onEditHandler = () => {
    onAddEditCollegeClick(userCollegeData);
  };
  const onDeleteHandler = () => {
    onDeleteClick(userCollegeData.id);
  };
  return (
    <li>
      <div className="title-box">
        <h3>{getCamelCase(Name)}</h3>
        <div className="button-box">
          <button className="trash-button" onClick={onDeleteHandler}>
            <span className="icon-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.9"
                height="17.5"
                viewBox="0 0 15.9 17.5"
              >
                <g transform="translate(-3.75 -2.25)">
                  <path
                    className="a"
                    d="M4.5,9H18.9"
                    transform="translate(0 -2.8)"
                  />
                  <path
                    className="a"
                    d="M18.7,6.2V17.4A1.6,1.6,0,0,1,17.1,19h-8a1.6,1.6,0,0,1-1.6-1.6V6.2m2.4,0V4.6A1.6,1.6,0,0,1,11.5,3h3.2a1.6,1.6,0,0,1,1.6,1.6V6.2"
                    transform="translate(-1.4)"
                  />
                  <path
                    className="a"
                    d="M15,16.5v4.8"
                    transform="translate(-4.9 -6.3)"
                  />
                  <path
                    className="a"
                    d="M21,16.5v4.8"
                    transform="translate(-7.7 -6.3)"
                  />
                </g>
              </svg>
            </span>
            Delete
          </button>
          <button className="edit-button" onClick={onEditHandler}>
            <span className="icon-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.641"
                height="15.578"
                viewBox="0 0 15.641 15.578"
              >
                <path
                  className="a"
                  d="M15.24,2.528,13.187.475a1.515,1.515,0,0,0-2.138.086A1.517,1.517,0,0,0,10.962,2.7l2.053,2.053a1.516,1.516,0,0,0,2.139-.086,1.516,1.516,0,0,0,.085-2.139ZM2.15,9.458l4.106,4.106L12.93,6.892,8.824,2.786,2.15,9.458ZM0,15.651l5.416-1.085L1.085,10.235Z"
                  transform="translate(0 -0.074)"
                />
              </svg>
            </span>
            Edit
          </button>
        </div>
      </div>
      <ul className="reference-detail-box">
        <li>
          <span>Division</span>
          <span>{userCollegeData.collegedivison.collegedivisionname}</span>
        </li>
        <li>
          <span>Current Level</span>
          <span>{userCollegeData.collegelevels.collegelevelname}</span>
        </li>
        <li>
          <span>Start Date</span>
          <span>
            {/*{moment().month(userCollegeData?.startdatemonth - 1).format("MMM")}*/}
            {userCollegeData.startdatemonth}
            {userCollegeData.startdatemonth &&
              userCollegeData.startdateyear &&
              "/"}
            {userCollegeData.startdateyear}
          </span>
        </li>
        <li>
          <span>End Date</span>
          <span>
            {/*{moment().month(userCollegeData?.enddatemonth - 1).format("MMM")}*/}
            {userCollegeData.enddatemonth}
            {userCollegeData.enddatemonth && userCollegeData.enddateyear && "/"}
            {userCollegeData.enddateyear}
          </span>
        </li>
      </ul>
    </li>
  );
};

const AddEditOverlay = ({
  onAddEditClick,
  months,
  years,
  onSave,
  schoolLevels,
  schoolDivisions,
  schoolType,
  schools,
  editValues,
  onSchoolChange,
}) => {
  const [zipError, setZipError] = React.useState(true);
  const [errors, setErrors] = useState({});
  const [schoolData, setSchoolData] = useState([]);
  const [fields, setFields] = React.useState({
    id: editValues?.id ? editValues.id : null,
    highschoolname: editValues.highschoolname ? editValues.highschoolname : "",
    highschooltype: editValues.schooltypes
      ? editValues.schooltypes.id
        ? editValues.schooltypes.id
        : null
      : null,
    division: editValues.schooldivisions
      ? editValues.schooldivisions.id
        ? editValues.schooldivisions.id
        : null
      : null,
    schoolcurrentleveloption: editValues.schoolcurrentlevels
      ? editValues.schoolcurrentlevels.id
        ? editValues.schoolcurrentlevels.id
        : null
      : null,
    startdatemonth: editValues.startdatemonth ? editValues.startdatemonth : "",
    startdateyear: editValues.startdateyear ? editValues.startdateyear : null,
    enddatemonth: editValues.enddatemonth ? editValues.enddatemonth : "",
    enddateyear: editValues.enddateyear ? editValues.enddateyear : null,
    currentltnotinhighschool: editValues.currentltnotinhighschool
      ? editValues.currentltnotinhighschool
      : false,
    zipcode: editValues.zipcode ? editValues.zipcode : "",
  });
  const dispatch = useDispatch()
  //for map it should be empty array not null $
  const [FilteredCurrectSchoolLevel, setFilteredCurrectSchoolLevel] =
    useState([]);
  useEffect(() => {
    onSchoolChange(fields);
  }, [fields]);

  useEffect(() => {
    getCurrentLevelListStart();
  }, []);

  const getCurrentLevelListStart = () => {
    const allData = schoolLevels;
    const filteredNotHighSchool = allData.filter(
      //showing all the data without any flag
      (item) => item
      //this showing all the school which is having ishighschool flag 0
      // (item) => item.ishighschool === 0
    );
    setFilteredCurrectSchoolLevel(filteredNotHighSchool);
  };
  const onSaveHandler = () => {
    const { formIsValid, updatedValue } = handleValidation();
    if (formIsValid && zipError) {
      if (
        (fields.highschoolname && fields.highschooltype) ||
        fields.division ||
        fields.schoolcurrentleveloption ||
        fields.startdatemonth ||
        fields.startdateyear ||
        fields.enddatemonth ||
        fields.enddateyear ||
        fields.currentltnotinhighschool ||
        fields.zipcode
      ) {
        onSave(fields);
      }
      onAddEditClick();
    }
  };

  const changeHighSchoolValue = () => {
    let fieldsT = fields;
    fieldsT.currentltnotinhighschool = !fieldsT.currentltnotinhighschool;
    fieldsT['schoolcurrentleveloption'] = ""
    setFields({
      ...fieldsT,
    });
  };
  const onChangeHandler = (event) => {
    setFields({
      ...fields,
      zipcode: event.target.value,
    });
    if (!!event?.target?.value) {
      if (event?.target.value?.length > 2 && event?.target.value?.length < 13) {
        if (AplhaNumericInput(event?.target?.value)) {
          setZipError(true)
        } else {
          setZipError(false)
        }
      } else {
        setZipError(false);
      }
    } else {
      setZipError(true)
    }
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    //School Name validations
    if (fieldname === undefined || fieldname === "highschoolname") {
      if (fieldname === undefined) {
        fieldValue = fields["highschoolname"] ? fields["highschoolname"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["highschoolname"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["highschoolname"] = "Please select High School Name from the List"
        // "Please Select High School Name";
      }
    }

    let startmonth = 0,
      endmonth = 0;
    for (let month of months) {
      if (month.monthvalue === fields.startdatemonth) {
        startmonth = month.id;
      }
      if (month.monthvalue === fields.enddatemonth) {
        endmonth = month.id;
      }
    }
    if (
      fieldname === undefined ||
      (fieldname === "startdateyear" && fieldname === "startdatemonth")
    ) {
      if (fields["startdateyear"] == null && fields["startdatemonth"] == "") {
        formIsValid = false;
        errorsT["startdateyear"] = "Start Date is required";
      } else if (fields["startdateyear"] == null) {
        formIsValid = false;
        errorsT["startdateyear"] = "Start Date is required";
      } else if (fields["startdatemonth"] == "") {
        formIsValid = false;
        errorsT["startdateyear"] = "Start Date is required";
      } else if (fields["startdateyear"] == "") {
        formIsValid = false;
        errorsT["startdateyear"] = "Start Date is required";
      }
    }

    if (
      fieldname === undefined ||
      (fieldname === "enddateyear" && fieldname === "enddatemonth")
    ) {
      if (fields["enddateyear"] == null && fields["enddatemonth"] == "") {
        formIsValid = false;
        errorsT["enddateyear"] = "End Date is required";
      } else if (fields["enddateyear"] == null) {
        formIsValid = false;
        errorsT["enddateyear"] = "End Date is required";
      } else if (fields["enddatemonth"] == "") {
        formIsValid = false;
        errorsT["enddateyear"] = "End Date is required";
      } else if (fields["enddateyear"] == "") {
        formIsValid = false;
        errorsT["enddateyear"] = "End Date is required";
      }
    }

    if (
      fieldname === undefined ||
      (fieldname === "startdateyear" && fieldname === "startdatemonth")
    ) {
      if (fields["startdateyear"] !== null && fields["startdatemonth"] !== "") {
        if (
          (fields["enddateyear"] !== null &&
            fields["enddatemonth"] !== "" &&
            fields["enddateyear"] < fields["startdateyear"]) ||
          (fields["enddateyear"] === fields["startdateyear"] &&
            startmonth > endmonth)
        ) {
          formIsValid = false;
          errorsT["enddateyear"] = "End Date should be greater than Start Date";
        }
      }
    }
    // if(zipError){
    //   formIsValid = false
    // }else{
    //   formIsValid = false
    // }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const handleChange = (field, e) => {
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    // if(["startdateyear", "startdatemonth", "enddateyear", "enddatemonth"].includes(field)){
    //   setIsEdit(false);
    // }
    setFields({
      ...fieldsT,
    });
    setErrors({
      errorsT: "",
    });
  };
  const handleSchoolChange = async (field, e) => {
    setErrors({
      errorsT: "",
    });
    const variables = {
      where: {
        active: 1,
        and: [
          {
            or: [
              { highschoolname: { like: `%${e.target.value}%` } },
              { city: { like: `%${e?.target?.value ?? ""}%` }, },
              { state: { like: `%${e?.target?.value ?? ""}%` }, },
            ],
          },
        ],
      },
    };
    if (e.target.value.toString().length > 2) {
      let res = await fetchGraphMethod(
        filterSchoolNameQuery,
        variables,
        true
      );
      if (res?.status == 200) {
        let schoolTeamlist = res?.data?.data?.allAthleteschools?.Athleteschools?.length > 0 ?
          res?.data?.data?.allAthleteschools?.Athleteschools?.map(schlName => schlName['highschoolname'] = `${schlName.highschoolname} - [${!!schlName.city ? getCamelCase(schlName?.city ?? "") : ""}, ${!!schlName.state ? getCamelCase(schlName.state) : ""}]`) : [];
        setSchoolData([...schoolTeamlist]);
      } else {
        console.log("")
      }
    }
  };

  const handleObjChange = (field, e) => {
    let fieldsT = fields;
    if (field === "level") {
      fieldsT["schoolcurrentleveloption"] = e
        ? e.target
          ? e.target.value
          : e
        : e;
    } else if (field === "schooltypename") {
      fieldsT["highschooltype"] = e ? (e.target ? e.target.value : e) : e;
    } else {
      fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    }
    setFields({
      ...fieldsT,
    });
  };
  return (
    <div
      className="modal fade common-modal form-modal show"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered editprofile-common-form"
        role="document"
      >
        <form className="modal-content">
          <div className="modal-header">
            {fields && fields.id ? (
              <h5 className="modal-title">Edit School</h5>
            ) : (
              <h5 className="modal-title">Add School</h5>
            )}

            <button type="button" className="close">
              <span aria-hidden="true" onClick={onAddEditClick}>&times;</span>
            </button>
          </div>
          <div className="modal-body bottom-border">
            <div className="fieldBox flexBox">
              <div className="half">
                <label>
                  High School Name <sup>*</sup>
                </label>
                <div className="autocomplete-class">
                  <Autocomplete
                    id="combo-box-demo"
                    freeSolo={true}
                    options={schoolData}
                    getOptionLabel={(option) =>
                      option.highschoolname ? option.highschoolname : option
                    }
                    onChange={(e, data) => {
                      handleChange("highschoolname", {
                        target: { value: data ? data : "" },
                      });
                    }}
                    value={fields.highschoolname || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="School Name"
                        value={fields.highschoolname || ""}
                        onChange={(e) => {
                          handleSchoolChange("highschoolname", {
                            target: {
                              value: e.target.value ? e.target.value : "",
                            },
                          });
                          //earlier thi below code is used but as client requirement it has commented 30/4/2022
                          // handleChange("highschoolname", {
                          //   target: {
                          //     value: e.target.value ? e.target.value : "",
                          //   },
                          // });
                        }}
                      />
                    )}
                  />
                  <div className="error">{errors["highschoolname"]}</div>
                </div>
              </div>
              <div className="half">
                {/*<label>High School Type</label>*/}
                <span style={selectStyle}>High School Type</span>
                <SelectBox
                  defaultText="Select School Type"
                  dataList={schoolType}
                  keyValue="schooltypename"
                  selectedValue={fields.highschooltype || ""}
                  onChangeObj={(e, data) => {
                    handleObjChange("schooltypename", {
                      target: { value: data == "" ? null : data },
                    });
                  }}
                />
              </div>
            </div>
            <div className="fieldBox flexBox">
              <div className="half">
                <label>Zip Code</label>
                <input
                  type="text"
                  placeholder="Zip Code"
                  onChange={onChangeHandler}
                  value={fields.zipcode == "null" ? "" : fields.zipcode}
                />
                {zipError == false && (
                  <div className="error">
                    Please Enter Valid Zipcode
                  </div>
                )}
              </div>

              <div className="half">
                {/*<label>Division</label>*/}
                <span style={selectStyle}>Division</span>
                <SelectBox
                  defaultText="Select Division"
                  dataList={schoolDivisions}
                  keyValue="division"
                  getValue="id"
                  selectedValue={fields.division || ""}
                  onChangeObj={(e, data) => {
                    handleChange("division", { target: { value: data } });
                  }}
                />
              </div>
            </div>
            <div className="fieldBox flexBox">
              <div className="half">
                <p className="checkbox-list text-left mb-0">
                  <span>
                    <input
                      type="checkbox"
                      onChange={changeHighSchoolValue}
                      checked={fields.currentltnotinhighschool || ""}
                    />
                    <label className="mb-0">
                      {/*Not in high school currently?*/}
                      Post High School?
                    </label>
                  </span>
                </p>
              </div>
              <div className="half">
                {fields.currentltnotinhighschool && (
                  <>
                    {/*<label>Option</label>*/}
                    <span style={selectStyle}>Option</span>
                    <SelectBox
                      defaultText="Select Option"
                      dataList={FilteredCurrectSchoolLevel}
                      keyValue="currentlevelvalue"
                      selectedValue={fields.schoolcurrentleveloption || ""}
                      onChangeObj={(e, data) => {
                        handleObjChange("level", {
                          target: { value: data },
                        });
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="fieldBox flexBox mb-0">
              <div className="half">
                {/*<label>*/}
                <span style={selectStyle}>Start Date (Ex. Graduation Year){" "}
                  <span style={{ color: "#c0321f", fontWeight: "600" }}>*</span></span>
                {/*</label>*/}
                <div className="fieldBox flexBox">
                  <div className="half">
                    <SelectBox
                      defaultText="Select Month"
                      dataList={months}
                      keyValue="monthvalue"
                      getValue="monthvalue"
                      selectedValue={fields.startdatemonth || ""}
                      onChangeObj={(e, data) => {
                        handleChange("startdatemonth", {
                          target: { value: data },
                        });
                      }}
                    />
                  </div>

                  <div className="half">
                    <SelectBox
                      defaultText="Select Year"
                      dataList={years}
                      keyValue="yearValue"
                      getValue="yearValue"
                      selectedValue={fields.startdateyear || ""}
                      onChangeObj={(e, data) => {
                        handleChange("startdateyear", {
                          target: { value: data },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="error">{errors["startdateyear"]}</div>
              </div>
              <div className="half">
                {/*<label>*/}
                <span style={selectStyle}>
                  End Date (Ex. Graduation Year) <span style={{ color: "#c0321f", fontWeight: "600" }}>*</span>
                </span>
                {/*</label>*/}
                <div className="fieldBox flexBox">
                  <div className="half">
                    <SelectBox
                      defaultText="Select Month"
                      dataList={months}
                      keyValue="monthvalue"
                      getValue="monthvalue"
                      selectedValue={fields.enddatemonth || ""}
                      onChangeObj={(e, data) => {
                        handleChange("enddatemonth", {
                          target: { value: data },
                        });
                      }}
                    />
                  </div>
                  <div className="half">
                    <SelectBox
                      defaultText="Select Year"
                      dataList={years}
                      keyValue="yearValue"
                      getValue="yearValue"
                      selectedValue={fields.enddateyear || ""}
                      onChangeObj={(e, data) => {
                        handleChange("enddateyear", {
                          target: { value: data },
                        });
                      }}
                    />
                  </div>
                </div>
                {errors && (
                  <div className="error">{errors["enddateyear"]}</div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {fields && fields.id ? (
              <button
                type="button"
                className="btn green"
                onClick={onSaveHandler}
              >
                Update
              </button>
            ) : (
              <button
                type="button"
                className="btn green"
                onClick={onSaveHandler}
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const AddEditCollegeOverlay = ({
  onAddEditCollegeClick,
  allCollegeList,
  allTeamList,
  months,
  years,
  onSave,
  collegeLevels,
  collegeDivisions,
  editValues,
  onCollegeChange,
}) => {
  const [errors, setErrors] = useState({});
  const [collegeData, setCollegeData] = useState([]);
  const [fields, setFields] = useState({
    id: editValues.id ? editValues.id : null,
    collegename: editValues.collegename ? editValues.collegename : "",
    collegedivison: editValues.collegedivison
      ? editValues.collegedivison.id
        ? editValues.collegedivison.id
        : null
      : null,
    collegecurrentlevel: editValues.collegelevels
      ? editValues.collegelevels.id
        ? editValues.collegelevels.id
        : null
      : null,
    startdatemonth: editValues.startdatemonth ? editValues.startdatemonth : "",
    startdateyear: editValues.startdateyear ? editValues.startdateyear : null,
    enddatemonth: editValues.enddatemonth ? editValues.enddatemonth : "",
    enddateyear: editValues.enddateyear ? editValues.enddateyear : null,
  });
  //for progress bar
  const dispatch = useDispatch()

  useEffect(() => {
    onCollegeChange(fields);
  }, [fields]);

  const handleChange = (field, e) => {
    let fieldsT = fields;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setFields({
      ...fieldsT,
    });
    setErrors({
      errorsT: "",
    });
  };

  const handleCollegeChange = async (field, e) => {
    setErrors({
      errorsT: "",
    });
    const variables = {
      // where: {
      //   // active: 1,
      //   and: [
      //     {
      //       or: [
      //         { collegename: { like: `%${e.target.value}%` } },
      //       ],
      //     },
      //   ],
      // },
      obj: {
        // active: 1,
        and: [
          {

            or: [
              { collegename: { like: `%${e.target.value ?? ""}%` } },
              { city: { like: `%${e?.target?.value ?? ""}%` }, },
              { state: { like: `%${e?.target?.value ?? ""}%` }, },
            ],
          },
        ],
      },
    };
    if (e.target.value.toString().length > 2) {
      let res = await fetchGraphMethod(
        // filterCollegeNameQuery,
        getAllCollegeQuery,
        variables,
        true
      );
      // let collegeTeamlist = res?.data?.data?.allAthletemorecolleges?.Athletemorecolleges?.length > 0 ? res?.data?.data?.allAthletemorecolleges?.Athletemorecolleges : [];
      let collegeTeamlist = res?.data?.data?.allColleges?.Colleges?.length > 0 ?
        res?.data?.data?.allColleges?.Colleges?.map(clgName => clgName['collegename'] = `${clgName.collegename} - [${!!clgName.city ? getCamelCase(clgName.city ?? "") : ""}, ${!!clgName.state ? getCamelCase(clgName.state) : ""}]`) : []
      setCollegeData([...collegeTeamlist]);
    }
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;

    //College Name validations
    if (fieldname === undefined || fieldname === "collegename") {
      if (fieldname === undefined) {
        fieldValue = fields["collegename"] ? fields["collegename"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["collegename"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["collegename"] = "Please select College Name from the List"
        // "Please Select College Name";
      }
    }
    let startmonth = 0,
      endmonth = 0;
    for (let month of months) {
      if (month.monthvalue === fields.startdatemonth) {
        startmonth = month.id;
      }
      if (month.monthvalue === fields.enddatemonth) {
        endmonth = month.id;
      }
    }
    if (
      fieldname === undefined ||
      (fieldname === "startdateyear" && fieldname === "startdatemonth")
    ) {
      if (fields["startdateyear"] == null && fields["startdatemonth"] == "") {
        formIsValid = false;
        errorsT["startdateyear"] = "Start Date is required";
      } else if (fields["startdateyear"] == null) {
        formIsValid = false;
        errorsT["startdateyear"] = "Start Date is required";
      } else if (fields["startdatemonth"] == "") {
        formIsValid = false;
        errorsT["startdateyear"] = "Start Date is required";
      } else if (fields["startdateyear"] == "") {
        formIsValid = false;
        errorsT["startdateyear"] = "Start Date is required";
      }
    }

    if (
      fieldname === undefined ||
      (fieldname === "startdateyear" && fieldname === "startdatemonth")
    ) {
      if (fields["startdateyear"] !== null && fields["startdatemonth"] !== "") {
        if (
          (fields["enddateyear"] !== null &&
            fields["enddatemonth"] !== "" &&
            fields["enddateyear"] < fields["startdateyear"]) ||
          (fields["enddateyear"] === fields["startdateyear"] &&
            startmonth > endmonth)
        ) {
          formIsValid = false;
          errorsT["enddateyear"] = "End Date should be greater than Start Date";
        }
      }
    }
    if (
      fieldname === undefined ||
      (fieldname === "enddateyear" && fieldname === "enddatemonth")
    ) {
      if (fields["enddateyear"] == null && fields["enddatemonth"] == "") {
        formIsValid = false;
        errorsT["enddateyear"] = "End Date is required";
      } else if (fields["enddateyear"] == null) {
        formIsValid = false;
        errorsT["enddateyear"] = "End Date is required";
      } else if (fields["enddatemonth"] == "") {
        formIsValid = false;
        errorsT["enddateyear"] = "End Date is required";
      } else if (fields["enddateyear"] == "") {
        formIsValid = false;
        errorsT["enddateyear"] = "End Date is required";
      }
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };
  const onSaveHandler = () => {
    const { formIsValid, updatedValue } = handleValidation();
    if (formIsValid) {
      if (
        fields.collegename ||
        fields.collegedivison ||
        fields.collegecurrentlevel ||
        fields.startdatemonth !== "" ||
        fields.startdateyear !== null ||
        fields.enddatemonth ||
        fields.enddateyear
      ) {
        onSave(fields);
      }
      onAddEditCollegeClick();
    }
  };
  return (
    <div
      className="modal fade common-modal form-modal show"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered editprofile-common-form">
        <form className="modal-content">
          <div className="modal-header">
            {fields && fields.id ? (
              <h5 className="modal-title">Edit College</h5>
            ) : (
              <h5 className="modal-title">Add College</h5>
            )}

            <button
              type="button"
              className="close"
            >
              <span aria-hidden="true"
                onClick={onAddEditCollegeClick}
              >&times;</span>
            </button>
          </div>
          <div className="modal-body bottom-border">
            <div className="fieldBox flexBox">
              <div className="half">
                <label>
                  College Name <sup>*</sup>
                </label>
                <div className="autocomplete-class">
                  <Autocomplete
                    id="combo-box-demo"
                    freeSolo={true}
                    options={collegeData}
                    getOptionLabel={(option) =>
                      option.collegename ? option.collegename : option
                    }
                    onChange={(e, data) => {
                      handleChange('collegename',
                        { target: { value: data ? data : "" } }
                      );
                    }}
                    value={fields.collegename || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="College Name"
                        value={fields.collegename || ""}
                        onChange={(e) => {
                          handleCollegeChange("collegename", {
                            target: { value: e.target.value ? e.target.value : "" },
                          });
                          //     handleChange('collegename',
                          //   { target: { value: e.target.value ? e.target.value : "" }, }
                          // );
                        }}
                      />
                    )}
                  />
                  <div className="error">{errors["collegename"]}</div>
                </div>
              </div>
              <div className="half">
                <label>Division</label>
                <SelectBox
                  defaultText="Select Division"
                  dataList={collegeDivisions}
                  keyValue="collegedivisionname"
                  onChangeObj={(e, data) => {
                    handleChange("collegedivison", { target: { value: data } });
                  }}
                  selectedValue={fields.collegedivison || ""}
                />
              </div>
            </div>
            <div className="fieldBox flexBox">
              <div className="half">
                <label>Current Level</label>
                <SelectBox
                  defaultText="Select Current Level"
                  dataList={collegeLevels}
                  keyValue="collegelevelname"
                  onChangeObj={(e, data) => {
                    handleChange("collegecurrentlevel", {
                      target: { value: data },
                    });
                  }}
                  selectedValue={fields.collegecurrentlevel || ""}
                />
              </div>
              <div className="half"></div>
            </div>
            <div className="fieldBox flexBox mb-0">
              <div className="half">
                <label>
                  Start Date (Ex. Graduation Year){" "}
                  <sup>*</sup>
                </label>
                <div className="fieldBox flexBox">
                  <div className="half">
                    <SelectBox
                      defaultText="Select Month"
                      dataList={months}
                      keyValue="monthvalue"
                      getValue="monthvalue"
                      onChangeObj={(e, data) => {
                        handleChange("startdatemonth", {
                          target: { value: data },
                        });
                      }}
                      selectedValue={fields.startdatemonth || ""}
                    />
                  </div>
                  <div className="half">
                    <SelectBox
                      defaultText="Select Year"
                      dataList={years}
                      keyValue="yearValue"
                      getValue="yearValue"
                      onChangeObj={(e, data) => {
                        handleChange("startdateyear", {
                          target: { value: data },
                        });
                      }}
                      selectedValue={fields.startdateyear || ""}
                    />
                  </div>
                </div>
                <div className="error">{errors["startdateyear"]}</div>
              </div>
              <div className="half">
                {/*<label>*/}
                <span style={selectStyleEndClg}>
                  End Date (Ex. Graduation Year)
                  <span style={{ color: "#c0321f", fontWeight: "600" }}>*</span>
                </span>
                {/*</label>*/}
                <div className="fieldBox flexBox">
                  <div className="half">
                    <SelectBox
                      defaultText="Select Month"
                      dataList={months}
                      keyValue="monthvalue"
                      getValue="monthvalue"
                      onChangeObj={(e, data) => {
                        handleChange("enddatemonth", {
                          target: { value: data },
                        });
                      }}
                      selectedValue={fields.enddatemonth || ""}
                    />
                  </div>
                  <div className="half">
                    <SelectBox
                      defaultText="Select Year"
                      dataList={years}
                      keyValue="yearValue"
                      getValue="yearValue"
                      onChangeObj={(e, data) => {
                        handleChange("enddateyear", {
                          target: { value: data },
                        });
                      }}
                      selectedValue={fields.enddateyear || ""}
                    />
                  </div>
                </div>
                <div className="error">{errors["enddateyear"]}</div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {fields && fields.id ? (
              <button
                type="button"
                className="btn green"
                onClick={onSaveHandler}
              >
                Update
              </button>
            ) : (
              <button
                type="button"
                className="btn green"
                onClick={onSaveHandler}
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const DeleteItem = ({
  onCollegeDeleteClick,
  collegeId,
  onCancelClick,
  onSchoolDeleteClick,
  schoolId,
}) => {
  const onDeleteHandler = () => {
    //typeof is done because when deleting we want id in collegeid which will be number
    if (typeof collegeId == "number" && collegeId !== 0) {
      onCollegeDeleteClick();
    }
    //typeof is done becaouse we are getting obj in schoolId when delete college
    if (typeof schoolId == "number" && schoolId !== 0) {
      onSchoolDeleteClick();
    }
  };
  return (
    <div
      className="modal fade common-modal trash-modal show"
      id="ReferenceTrashModal"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" onClick={onCancelClick}>
              <img src={closeGrayIcon} alt="close Gray Icon" />
            </button>
            <div className="icon-box-first">
              <img src={trashCircleIcon} alt="trash Circle Icon" />
            </div>
            <div className="content-box">
              <h4>Are you sure you want to delete this ?</h4>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              onClick={onDeleteHandler}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn gray"
              data-dismiss="modal"
              onClick={onCancelClick}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditSection = ({
  onAddEditClick,
  onAddEditCollegeClick,
  onDeleteClick,
  userCollegeList,
  userSchoolList,
}) => {
  const onCollegeEditHandler = (userCollege) => {
    onAddEditCollegeClick(userCollege);
  };
  const onCollegeDeleteHandler = (id) => {
    onDeleteClick(id, true);
  };
  const onSchoolDeleteHandler = (id) => {
    onDeleteClick(id, false);
  };
  const onSchoolEditHandler = (userSchool) => {
    onAddEditClick(userSchool);
  };
  const getAllCollegeBox = () => {
    return userCollegeList.map((userCollege) => {
      if (userCollege.id === 0) {
        return;
      }
      return (
        <CollegeBox
          key={userCollege.id}
          Name={userCollege.collegename}
          onAddEditCollegeClick={onCollegeEditHandler}
          onDeleteClick={onCollegeDeleteHandler}
          userCollegeData={userCollege}
        />
      );
    });
  };
  const getAllSchoolBox = () => {
    return userSchoolList.map((userSchool) => {
      if (userSchool.id === 0) {
        return;
      }
      return (
        <SchoolBox
          key={userSchool.id}
          Name={userSchool.highschoolname}
          onDeleteClick={onSchoolDeleteHandler}
          onEditClick={onSchoolEditHandler}
          userSchoolData={userSchool}
        />
      );
    });
  };
  return (
    <div className="editSection">
      <Title title={"Academics"} />
      <div className="check-list-sec p-0">
        <div className="event-main-wrapper">
          <div className="academic-list">
            <div className="academic-box-item">
              <h5 className="title">School Information</h5>
              <div className="academic-button-box">
                <button className="btn green" onClick={onAddEditClick}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.75"
                    height="14.75"
                    viewBox="0 0 14.75 14.75"
                  >
                    <path
                      className="a"
                      d="M18.074,10.646H13.1V5.676a1.23,1.23,0,0,0-2.458,0v4.97H5.676a1.23,1.23,0,0,0,0,2.458h4.97v4.97a1.23,1.23,0,0,0,2.458,0V13.1h4.97a1.23,1.23,0,0,0,0-2.458Z"
                      transform="translate(-4.5 -4.5)"
                    ></path>
                  </svg>
                  Add School
                </button>
              </div>
              <div className="reference-data-box">
                <ul className="reference-list">{getAllSchoolBox()}</ul>
              </div>
            </div>
            <div className="academic-box-item">
              <h5 className="title">College Information</h5>
              <div className="academic-button-box">
                <button className="btn green" onClick={userSchoolList?.length <= 0 ? () => swal({ text: "Please Add School First", icon: "error" }) : onAddEditCollegeClick}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.75"
                    height="14.75"
                    viewBox="0 0 14.75 14.75"
                  >
                    <path
                      className="a"
                      d="M18.074,10.646H13.1V5.676a1.23,1.23,0,0,0-2.458,0v4.97H5.676a1.23,1.23,0,0,0,0,2.458h4.97v4.97a1.23,1.23,0,0,0,2.458,0V13.1h4.97a1.23,1.23,0,0,0,0-2.458Z"
                      transform="translate(-4.5 -4.5)"
                    ></path>
                  </svg>
                  Add College
                </button>
              </div>
              <div className="reference-data-box">
                <ul className="reference-list">{getAllCollegeBox()}</ul>
              </div>
            </div>
          </div>
        </div>
        <div className="common-button-box with-next-prev center">
          <div className="next-prev-button">
            <ScreenSwitch
              prevLink={"/events"}
              nextLink={"/gradesScores"}
              isSaved={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Academics = (props) => {
  const [sideMenuOpen, setSideMenu] = useState(false),
    [showAddEditModal, setShowAddEditModal] = useState(false),
    [addEditCollegeModal, setAddEditCollegeModal] = useState(false),
    [showDeleteModal, setShowDeleteModal] = useState(false),
    [previousData, setPreviousData] = useState();

  //All list states
  const [collegeArray, setCollegeArray] = useState([]);
  const [schoolArray, setSchoolArray] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [schoolLevelList, setSchoolLevelList] = useState([]);
  const [collegeLevelList, setCollegeLevelList] = useState([]);
  const [collegeDivisionList, setCollegeDivisionList] = useState([]);
  const [schoolDivisionList, setSchoolDivisionList] = useState([]);
  const [schoolTypeList, setSchoolTypeList] = useState([]);
  const fakeInput = useRef(null);

  //User School List State
  const [userSchoolIdDel, setUserSchoolIdDel] = useState(0);
  const [userSchoolList, setUserSchoolList] = useState([
    {
      id: 0,
      highschoolname: "",
      currentltnotinhighschool: "",
      enddateyear: "",
      enddatemonth: "",
      startdateyear: "",
      startdatemonth: "",
      zipcode: "",
      schooldivisions: {
        id: 0,
        division: "",
      },
      schooltypes: {
        id: 0,
        currentlevelvalue: "",
      },
      schoolcurrentlevels: {
        id: 0,
        schooltypename: "",
      },
    },
  ]);
  const [userSchoolEditValue, setUserSchoolEditValue] = useState({
    id: 0,
    highschoolname: "",
    currentltnotinhighschool: "",
    enddateyear: "",
    enddatemonth: "",
    startdateyear: "",
    startdatemonth: "",
    zipcode: "",
    schooldivisions: {
      id: 0,
      division: "",
    },
    schooltypes: {
      id: 0,
      currentlevelvalue: "",
    },
    schoolcurrentlevels: {
      id: 0,
      schooltypename: "",
    },
  });
  //User Collges List state
  const [userCollegeIdDel, setUserCollegeIdDel] = useState(0);
  const [saveButtonHide] = useState(false);
  const [userCollegeList, setUserCollegeList] = useState([
    {
      id: 0,
      collegename: "",
      startdateyear: "",
      startdatemonth: "",
      enddateyear: "",
      enddatemonth: "",
      collegeteam: "",
      collegedivisions: {
        id: 0,
        collegedivisionname: "",
      },
      collegelevels: {
        id: 0,
        collegelevelname: "",
      },
    },
  ]);
  const [userCollegeEditValue, setUserCollegeEditValues] = useState({
    id: 0,
    collegename: "",
    startdateyear: null,
    startdatemonth: "",
    enddateyear: null,
    enddatemonth: "",
    collegeteam: "",
    collegedivisions: {
      id: 0,
      collegedivisionname: "",
    },
    collegelevels: {
      id: 0,
      collegelevelname: "",
    },
  });
  //for progress bar
  const dispatch = useDispatch()

  useEffect(() => {
    props.graduationYearsListStart();
    props.graduationMonthsListStart();
    props.allCollegeDetailsListStart({
      query: getAllCollegeQuery,
      variables: null,
    });
    props.allTeamListStart({
      query: getAllTeams,
      variables: null,
    });
    props.schoolLevelListStart({
      query: getSchoolLevels,
      variables: null,
    });
    props.collegeLevelListStart({
      query: getCollegeLevels,
    });
    props.collegeDivisionListStart({
      query: getCollegeDivisions,
    });
    props.schoolDivisionListStart({
      query: getSchoolDivisions,
    });

    getCollegeList();
    getSchoolList();
    props.schoolTypeListStart({
      query: getSchoolType,
    });
    //this api has too much data duw to which ui is getting crash
    // props.schoolListStart({
    //   query: getSchoolName,
    // });
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);
  useEffect(() => {
    if (props.location.state) {
      setPreviousData(props.location.state);
    }
  }, [props.location.state]);

  useEffect(() => {
    if (
      props.allUserAcademicCollegeList &&
      props.allUserAcademicCollegeList.data &&
      props.allUserAcademicCollegeList.data.allUserAcademicCollege &&
      props.allUserAcademicCollegeList.data.allUserAcademicCollege
        .Athletemorecolleges
    ) {
      let userCollegeListT = [];
      props.allUserAcademicCollegeList.data.allUserAcademicCollege.Athletemorecolleges.map(
        (userCollege) => {
          userCollegeListT = [
            ...userCollegeListT,
            {
              id: userCollege.id,
              collegename: userCollege.collegename,
              startdateyear: userCollege.startdateyear,
              startdatemonth: userCollege.startdatemonth,
              enddateyear: userCollege.enddateyear,
              enddatemonth: userCollege.enddatemonth,
              collegelevels: {
                id: userCollege.athletemorecollegecollegecurrentlevelmaprel
                  ? userCollege.athletemorecollegecollegecurrentlevelmaprel
                    .Collegelevels[0]
                    ? userCollege.athletemorecollegecollegecurrentlevelmaprel
                      .Collegelevels[0].id
                      ? userCollege.athletemorecollegecollegecurrentlevelmaprel
                        .Collegelevels[0].id
                      : 0
                    : 0
                  : 0,
                collegelevelname:
                  userCollege.athletemorecollegecollegecurrentlevelmaprel
                    ? userCollege.athletemorecollegecollegecurrentlevelmaprel
                      .Collegelevels[0]
                      ? userCollege.athletemorecollegecollegecurrentlevelmaprel
                        .Collegelevels[0].collegelevelname
                        ? userCollege
                          .athletemorecollegecollegecurrentlevelmaprel
                          .Collegelevels[0].collegelevelname
                        : ""
                      : ""
                    : "",
              },
              collegeteam: userCollege.collegeteam,
              collegedivison: {
                id: userCollege.athletemorecollegecollegedivisonmaprel
                  ? userCollege.athletemorecollegecollegedivisonmaprel
                    .Collegedivisions[0]
                    ? userCollege.athletemorecollegecollegedivisonmaprel
                      .Collegedivisions[0].id
                      ? userCollege.athletemorecollegecollegedivisonmaprel
                        .Collegedivisions[0].id
                      : 0
                    : 0
                  : 0,
                collegedivisionname:
                  userCollege.athletemorecollegecollegedivisonmaprel
                    ? userCollege.athletemorecollegecollegedivisonmaprel
                      .Collegedivisions[0]
                      ? userCollege.athletemorecollegecollegedivisonmaprel
                        .Collegedivisions[0].collegedivisionname
                        ? userCollege.athletemorecollegecollegedivisonmaprel
                          .Collegedivisions[0].collegedivisionname
                        : ""
                      : ""
                    : "",
              },
            },
          ];
        }
      );
      setUserCollegeList(userCollegeListT);
    }
  }, [props.allUserAcademicCollegeList]);

  //Effect to Load data after saving
  useEffect(() => {
    if (
      props.saveAllUserAcademicCollege &&
      props.saveAllUserAcademicCollege.data &&
      props.saveAllUserAcademicCollege.data.saveUserAcademicCollege &&
      props.saveAllUserAcademicCollege.data.saveUserAcademicCollege.active !== 0
    ) {
      let userCollegeListT = [...userCollegeList];
      userCollegeListT = userCollegeListT.filter((userCollege) => {
        if (
          userCollege.id !==
          props.saveAllUserAcademicCollege.data.saveUserAcademicCollege.id
        ) {
          return userCollege;
        }
      });
      let userCollegeObj = {
        id: props.saveAllUserAcademicCollege.data.saveUserAcademicCollege.id,
        collegename:
          props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
            .collegename,
        startdateyear:
          props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
            .startdateyear,
        startdatemonth:
          props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
            .startdatemonth,
        enddateyear:
          props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
            .enddateyear,
        enddatemonth:
          props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
            .enddatemonth,
        collegelevels: {
          id: props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
            .athletemorecollegecollegecurrentlevelmaprel
            ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
              .athletemorecollegecollegecurrentlevelmaprel.Collegelevels[0]
              ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
                .athletemorecollegecollegecurrentlevelmaprel.Collegelevels[0]
                .id
                ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
                  .athletemorecollegecollegecurrentlevelmaprel
                  .Collegelevels[0].id
                : 0
              : 0
            : 0,
          collegelevelname: props.saveAllUserAcademicCollege.data
            .saveUserAcademicCollege.athletemorecollegecollegecurrentlevelmaprel
            ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
              .athletemorecollegecollegecurrentlevelmaprel.Collegelevels[0]
              ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
                .athletemorecollegecollegecurrentlevelmaprel.Collegelevels[0]
                .collegelevelname
                ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
                  .athletemorecollegecollegecurrentlevelmaprel
                  .Collegelevels[0].collegelevelname
                : ""
              : ""
            : "",
        },
        collegeteam:
          props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
            .collegeteam,
        collegedivison: {
          id: props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
            .athletemorecollegecollegedivisonmaprel
            ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
              .athletemorecollegecollegedivisonmaprel.Collegedivisions[0]
              ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
                .athletemorecollegecollegedivisonmaprel.Collegedivisions[0].id
                ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
                  .athletemorecollegecollegedivisonmaprel.Collegedivisions[0]
                  .id
                : 0
              : 0
            : 0,
          collegedivisionname: props.saveAllUserAcademicCollege.data
            .saveUserAcademicCollege.athletemorecollegecollegedivisonmaprel
            ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
              .athletemorecollegecollegedivisonmaprel.Collegedivisions[0]
              ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
                .athletemorecollegecollegedivisonmaprel.Collegedivisions[0]
                .collegedivisionname
                ? props.saveAllUserAcademicCollege.data.saveUserAcademicCollege
                  .athletemorecollegecollegedivisonmaprel.Collegedivisions[0]
                  .collegedivisionname
                : ""
              : ""
            : "",
        },
      };
      userCollegeListT.push(userCollegeObj);
      setUserCollegeList(userCollegeListT);
    }
    if (
      props.saveAllUserAcademicCollege &&
      props.saveAllUserAcademicCollege.data &&
      props.saveAllUserAcademicCollege.data.saveUserAcademicCollege &&
      props.saveAllUserAcademicCollege.data.saveUserAcademicCollege.active === 0
    ) {
      let userCollegeListT = [...userCollegeList];
      userCollegeListT = userCollegeListT.filter((userCollege) => {
        if (
          userCollege.id !==
          props.saveAllUserAcademicCollege.data.saveUserAcademicCollege.id
        ) {
          return userCollege;
        }
      });

      setUserCollegeList(userCollegeListT);
    }
  }, [props.saveAllUserAcademicCollege]);

  useEffect(() => {
    if (
      // props.allUserAcademicSchoolList &&
      // props.allUserAcademicSchoolList.data &&
      // props.allUserAcademicSchoolList.data.allUserAcademicSchool &&
      // props.allUserAcademicSchoolList.data.allUserAcademicSchool
      //   .Athleteschools &&
      // props.allUserAcademicSchoolList.data.allUserAcademicSchool.Athleteschools
      //   .active !== 0
      props?.allUserAcademicSchoolList?.data?.allUserAcademicSchool?.Athletemoreschools?.length > 0
    ) {
      let userSchoolListT = [];
      props.allUserAcademicSchoolList.data.allUserAcademicSchool.Athletemoreschools.map(
        (athleteSchool) => {
          //AS DEV IS NOT GETTING ACTIVE 0 ON WHERE CONDITION
          if (athleteSchool?.active) {
            let userSchoolObj = {
              id: athleteSchool.id,
              highschoolname: athleteSchool.highschoolname,
              currentltnotinhighschool: athleteSchool.currentltnotinhighschool,
              enddateyear: athleteSchool.enddateyear,
              enddatemonth: athleteSchool.enddatemonth,
              startdateyear: athleteSchool.startdateyear,
              startdatemonth: athleteSchool.startdatemonth,
              zipcode: athleteSchool.zipcode,
              schooldivisions: {
                id: athleteSchool?.athletemoreschooldivisionmaprel
                  ?.Schooldivisions?.[0]?.id ?? 0,
                division: athleteSchool?.athletemoreschooldivisionmaprel
                  ?.Schooldivisions?.[0]?.division ?? "",
              },
              schoolcurrentlevels: {
                id: athleteSchool?.athletemoreschoolcurrentleveloptionmaprel
                  ?.Schoolcurrentlevels[0]?.id ?? 0,
                currentlevelvalue: athleteSchool
                  ?.athletemoreschoolcurrentleveloptionmaprel
                  ?.Schoolcurrentlevels[0]?.currentlevelvalue
                  ?? "",
              },
              schooltypes: {
                id: athleteSchool?.athletemoreschoolhighschooltypemaprel
                  ?.Schooltypes[0]?.id
                  ?? 0,
                schooltypename: athleteSchool?.athletemoreschoolhighschooltypemaprel
                  ?.Schooltypes?.[0]?.schooltypename
                  ?? "",
              },
            };
            userSchoolListT = [...userSchoolListT, userSchoolObj];
          }
        }
      );
      setUserSchoolList(userSchoolListT);
    } else {
      setUserSchoolList([])
    }
  }, [props.allUserAcademicSchoolList]);
  useEffect(() => {
    if (
      props.saveAllUserAcademicSchool &&
      props.saveAllUserAcademicSchool.data &&
      props.saveAllUserAcademicSchool.data.saveUserAcademicSchool &&
      props.saveAllUserAcademicSchool.data.saveUserAcademicSchool.active !== 0
    ) {
      let userSchoolListT = userSchoolList.filter((userSchool) => {
        if (
          userSchool.id !==
          props.saveAllUserAcademicSchool.data.saveUserAcademicSchool.id
        ) {
          return userSchool;
        }
      });
      let userSchoolObj = {
        id: props.saveAllUserAcademicSchool.data.saveUserAcademicSchool.id,
        highschoolname:
          props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
            .highschoolname,
        currentltnotinhighschool:
          props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
            .currentltnotinhighschool,
        enddateyear:
          props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
            .enddateyear,
        enddatemonth:
          props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
            .enddatemonth,
        startdateyear:
          props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
            .startdateyear,
        startdatemonth:
          props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
            .startdatemonth,
        zipcode:
          props.saveAllUserAcademicSchool.data.saveUserAcademicSchool.zipcode,
        schooldivisions: {
          id: props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
            .athleteschooldivisionmaprel
            ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
              .athleteschooldivisionmaprel
              ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                .athleteschooldivisionmaprel.Schooldivisions[0]
                ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                  .athleteschooldivisionmaprel.Schooldivisions[0].id
                  ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                    .athleteschooldivisionmaprel.Schooldivisions[0].id
                  : 0
                : 0
              : 0
            : 0,
          division: props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
            .athleteschooldivisionmaprel
            ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
              .athleteschooldivisionmaprel
              ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                .athleteschooldivisionmaprel.Schooldivisions[0]
                ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                  .athleteschooldivisionmaprel.Schooldivisions[0].division
                  ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                    .athleteschooldivisionmaprel.Schooldivisions[0].division
                  : ""
                : ""
              : ""
            : "",
        },
        schooltypes: {
          id: props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
            .athleteschoolhighschooltypemaprel
            ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
              .athleteschoolhighschooltypemaprel.Schooltypes
              ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                .athleteschoolhighschooltypemaprel.Schooltypes[0]
                ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                  .athleteschoolhighschooltypemaprel.Schooltypes[0].id
                  ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                    .athleteschoolhighschooltypemaprel.Schooltypes[0].id
                  : 0
                : 0
              : 0
            : 0,
          schooltypename: props.saveAllUserAcademicSchool.data
            .saveUserAcademicSchool.athleteschoolhighschooltypemaprel
            ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
              .athleteschoolhighschooltypemaprel.Schooltypes
              ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                .athleteschoolhighschooltypemaprel.Schooltypes[0]
                ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                  .athleteschoolhighschooltypemaprel.Schooltypes[0]
                  .schooltypename
                  ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                    .athleteschoolhighschooltypemaprel.Schooltypes[0]
                    .schooltypename
                  : ""
                : ""
              : ""
            : "",
        },
        schoolcurrentlevels: {
          id: props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
            .athleteschoolschoolcurrentleveloptionmaprel
            ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
              .athleteschoolschoolcurrentleveloptionmaprel.Schoolcurrentlevels
              ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                .athleteschoolschoolcurrentleveloptionmaprel
                .Schoolcurrentlevels[0]
                ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                  .athleteschoolschoolcurrentleveloptionmaprel
                  .Schoolcurrentlevels[0].id
                  ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                    .athleteschoolschoolcurrentleveloptionmaprel
                    .Schoolcurrentlevels[0].id
                  : 0
                : 0
              : 0
            : 0,
          currentlevelvalue: props.saveAllUserAcademicSchool.data
            .saveUserAcademicSchool.athleteschoolschoolcurrentleveloptionmaprel
            ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
              .athleteschoolschoolcurrentleveloptionmaprel.Schoolcurrentlevels
              ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                .athleteschoolschoolcurrentleveloptionmaprel
                .Schoolcurrentlevels[0]
                ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                  .athleteschoolschoolcurrentleveloptionmaprel
                  .Schoolcurrentlevels[0].currentlevelvalue
                  ? props.saveAllUserAcademicSchool.data.saveUserAcademicSchool
                    .athleteschoolschoolcurrentleveloptionmaprel
                    .Schoolcurrentlevels[0].currentlevelvalue
                  : ""
                : ""
              : ""
            : "",
        },
      };
      userSchoolListT.push(userSchoolObj);
      setUserSchoolList(userSchoolListT);
    }
    if (
      props.saveAllUserAcademicSchool &&
      props.saveAllUserAcademicSchool.data &&
      props.saveAllUserAcademicSchool.data.saveUserAcademicSchool &&
      props.saveAllUserAcademicSchool.data.saveUserAcademicSchool.active === 0
    ) {
      let userSchoolListT = userSchoolList.filter((userSchool) => {
        if (
          userSchool.id !==
          props.saveAllUserAcademicSchool.data.saveUserAcademicSchool.id
        ) {
          return userSchool;
        }
      });
      setUserSchoolList(userSchoolListT);
    }
  }, [props.saveAllUserAcademicSchool]);
  useEffect(() => {
    if (
      props.schoolTypeList.data &&
      props.schoolTypeList.data.allSchoolTypes &&
      props.schoolTypeList.data.allSchoolTypes.Schooltypes
    ) {
      setSchoolTypeList(props.schoolTypeList.data.allSchoolTypes.Schooltypes);
    }
  }, [props.schoolTypeList]);
  useEffect(() => {
    if (
      props.schoolList &&
      props.schoolList.data &&
      props.schoolList.data.allSchoolList &&
      props.schoolList.data.allSchoolList.Athleteschools
    ) {
      let distinctSchoolNames = [];
      let schoolArrayT =
        props.schoolList.data.allSchoolList.Athleteschools.filter((school) => {
          if (school.highschoolname) {
            if (!distinctSchoolNames.includes(school.highschoolname)) {
              distinctSchoolNames.push(school.highschoolname);
              return school;
            }
          }
        });

      setSchoolArray(schoolArrayT);
    }
  }, [props.schoolList]);

  useEffect(() => {
    if (
      props.collegeDivisionsList.data &&
      props.collegeDivisionsList.data.allCollegedivisions &&
      props.collegeDivisionsList.data.allCollegedivisions.Collegedivisions
    ) {
      setCollegeDivisionList(
        props.collegeDivisionsList.data.allCollegedivisions.Collegedivisions
      );
    }
  }, [props.collegeDivisionsList]);

  useEffect(() => {
    if (
      props.schoolDivisionsList.data &&
      props.schoolDivisionsList.data.allSchoolDivision &&
      props.schoolDivisionsList.data.allSchoolDivision.Schooldivisions
    ) {
      setSchoolDivisionList(
        props.schoolDivisionsList.data.allSchoolDivision.Schooldivisions
      );
    }
  }, [props.schoolDivisionsList]);

  useEffect(() => {
    if (
      props.schoolLevelList.data &&
      props.schoolLevelList.data.allSchoolcurrentlevels &&
      props.schoolLevelList.data.allSchoolcurrentlevels.Schoolcurrentlevels
    ) {
      setSchoolLevelList(
        props.schoolLevelList.data.allSchoolcurrentlevels.Schoolcurrentlevels
      );
    }
  }, [props.schoolLevelList]);
  useEffect(() => {
    if (
      props.collegeLevelList.data &&
      props.collegeLevelList.data.allCollegeLevels &&
      props.collegeLevelList.data.allCollegeLevels.Collegelevels
    ) {
      setCollegeLevelList(
        props.collegeLevelList.data.allCollegeLevels.Collegelevels
      );
    }
  }, [props.collegeLevelList]);

  useEffect(() => {
    if (props.collegeList.data) {
      let collegeArrayT = props.collegeList.data.filter((college) => {
        if (college.collegename) {
          return college;
        }
      });

      setCollegeArray(collegeArrayT);
    }
  }, [props.collegeList]);

  useEffect(() => {
    if (props.teamList.data) {
      setTeamsList(props.teamList.data.allTeamsList);
    }
  }, [props.teamList]);

  const getCollegeList = () => {
    props.userAcademicCollegeList({
      query: getAllUserCollege,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  };

  const getSchoolList = () => {
    props.userAcademicSchoolListStart({
      // getAllUserSchool
      query: getAllAthleteMoreSchoolQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : null,
          active: 1,
          // isuserschool: "1"
        },
      },
      isAcademic: true
    });
  };
  const handleCollegeSaveMessage = () => {
    if (userCollegeEditValue.id) {
      // messagePopup("", "College Updated Successfully", "success");
      toastr.success("College Updated Successfully", "", {
        progressBar: true, timeOut: 2000,
        closeButton: true, positionClass: "toast-top-college-progress",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      });
    } else {
      // messagePopup("", "College Added Successfully", "success");
      toastr.success("College Added Successfully", "", {
        progressBar: true, timeOut: 2000,
        closeButton: true, positionClass: "toast-top-college-progress",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      });
    }
  };

  const initialStats = () => {
    setUserCollegeEditValues({});
    getCollegeList();
  };

  const isExistCollege = (data) => {
    let flag = true;
    for (let i = 0; i < userCollegeList.length; i++) {
      // var collegeName = userCollegeList[i].collegename;
      // if (collegeName.toLowerCase() === data.collegename.toLowerCase()) {
      //   if (data.id) {
      //     if (data.id !== userCollegeList[i].id) {
      //       flag = false;
      //       messagePopup("", "College Already Exist", "error");
      //     }
      //   } else {
      //     flag = false;
      //     messagePopup("", "College Already Exist", "error");
      //   }
      // }

      let oldStartMonth = moment().month(userCollegeList[i].startdatemonth).format("MM");
      let oldStartYear = moment().year(userCollegeList[i].startdateyear).format("YYYY");
      let startDate = moment(`01/${oldStartMonth}/${oldStartYear}`, "DD/MM/YYYY").unix();
      let oldEndMonth = moment().month(userCollegeList[i].enddatemonth).format("MM");
      let oldEndYear = moment().year(userCollegeList[i].enddateyear).format("YYYY");
      let endDate = moment(`01/${oldEndMonth}/${oldEndYear}`, "DD/MM/YYYY").unix();
      let newStartMonth = moment().month(data.startdatemonth).format("MM");
      let newStartYear = moment().year(data.startdateyear).format("YYYY");
      let newStartDate = moment(`01/${newStartMonth}/${newStartYear}`, "DD/MM/YYYY").unix();
      let newEndMonth = moment().month(data.enddatemonth).format("MM");
      let newEndYear = moment().year(data.enddateyear).format("YYYY");
      let newEndDate = moment(`01/${newEndMonth}/${newEndYear}`, "DD/MM/YYYY").unix();
      if ((startDate <= newStartDate && newStartDate <= endDate) || (startDate <= newEndDate && newEndDate <= endDate)) {
        flag = false;
        // messagePopup("", "College with same start & end date already exist.", "error");
        toastr.error("College with same start & end date already exist.", "", {
          progressBar: true, timeOut: 2000,
          closeButton: true, positionClass: "toast-top-college-progress toast-college-error",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        });
      }
    }
    return flag;
  };
  const onCollegeSaveHandler = (data) => {
    let flag = data?.id ? true : isExistCollege(data);
    if (flag) {
      if (userCollegeEditValue) {
        if (
          !userCollegeEditValue.collegename &&
          !userCollegeEditValue.collegedivison &&
          !userCollegeEditValue.collegecurrentlevel &&
          !userCollegeEditValue.startdatemonth &&
          !userCollegeEditValue.startdateyear &&
          !userCollegeEditValue.enddatemonth &&
          !userCollegeEditValue.enddateyear
        ) {
          return;
        }
        userCollegeEditValue.collegename = userCollegeEditValue?.collegename?.collegename
          ? userCollegeEditValue?.collegename?.collegename
          : userCollegeEditValue?.collegename;
        userCollegeEditValue.id = !!userCollegeEditValue?.id ? userCollegeEditValue?.id : 0;
        userCollegeEditValue.startdateyear = !!userCollegeEditValue?.startdateyear ? userCollegeEditValue?.startdateyear.toString() : "null"
        userCollegeEditValue.enddateyear = !!userCollegeEditValue?.enddateyear ? userCollegeEditValue?.enddateyear.toString() : "null"
        userCollegeEditValue.collegecurrentlevel = !!userCollegeEditValue?.collegecurrentlevel ? userCollegeEditValue?.collegecurrentlevel.toString() : "null"
        userCollegeEditValue.collegedivison = !!userCollegeEditValue?.collegedivison ? userCollegeEditValue?.collegedivison.toString() : "null"

        //this is not in need as this is for month date check
        // userCollegeEditValue.isCollegeDateEdit  = isEditStatus('clg' ,data)
        props.userAcademicRestCollegeSave({
          query: saveUserCollege,
          variables: {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : null,
              // active: 1,
              ...userCollegeEditValue,
            },
          },
          handleCollegeSaveMessage: () => {
            handleCollegeSaveMessage();
            initialStats();
          },
          nextRoute,
          getAllCollegeListAPI
        });
      }
    }
    // setTimeout(()=>{
    //   getCollegeList()
    // },400)
    dispatch({ type: "RENDER_COUNT_START", payload: 1 })
  };
  //this func is used for call the listing update operation
  const getAllCollegeListAPI = () => {
    getCollegeList()
  }
  const onEditHandler = (userCollegeEditValues) => {
    setUserCollegeEditValues(userCollegeEditValues);
    setAddEditCollegeModal(!addEditCollegeModal);
  };

  const onSchoolEditHandler = (userSchoolEditValues) => {
    setUserSchoolEditValue(userSchoolEditValues);
    setShowAddEditModal(!showAddEditModal);
  };

  const handleSchoolMessage = () => {
    if (userSchoolEditValue.id) {
      // messagePopup("", "School Updated Successfully.", "success");
      toastr.success("School Updated Successfully.", "", {
        progressBar: true, timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    } else {
      // messagePopup("", "School Added Successfully.", "success");
      toastr.success("School Added Successfully", "", {
        progressBar: true, timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }
  };

  const initialSet = () => {
    if (previousData && previousData.previousPage === "checklist") {
      props.history.push("/checklist");
      setUserSchoolEditValue({
        id: 0,
        highschoolname: "",
        currentltnotinhighschool: "",
        enddateyear: "",
        enddatemonth: "",
        startdateyear: "",
        startdatemonth: "",
        zipcode: "",
        schooldivisions: {
          id: 0,
          division: "",
        },
        schooltypes: {
          id: 0,
          currentlevelvalue: "",
        },
        schoolcurrentlevels: {
          id: 0,
          schooltypename: "",
        },
      });
      getSchoolList();
    } else {
      setUserSchoolEditValue({
        id: 0,
        highschoolname: "",
        currentltnotinhighschool: "",
        enddateyear: "",
        enddatemonth: "",
        startdateyear: "",
        startdatemonth: "",
        zipcode: "",
        schooldivisions: {
          id: 0,
          division: "",
        },
        schooltypes: {
          id: 0,
          currentlevelvalue: "",
        },
        schoolcurrentlevels: {
          id: 0,
          schooltypename: "",
        },
      });
      getSchoolList();
    }
  };

  const existSchoolName = (data) => {
    let flag = true;
    for (let i = 0; i < userSchoolList.length; i++) {
      // var schoolName = userSchoolList[i].highschoolname;
      // if (schoolName.toLowerCase() === data.highschoolname.toLowerCase()) {
      //   if (data.id) {
      //     if (data.id !== userSchoolList[i].id) {
      //       flag = false;
      //       messagePopup("", "School Already Exist", "error");
      //     }
      //   } else {
      //     flag = false;
      //     messagePopup("", "School Already Exist", "error");
      //   }
      // }
      let oldStartMonth = moment().month(userSchoolList[i].startdatemonth).format("MM");
      let oldStartYear = moment().year(userSchoolList[i].startdateyear).format("YYYY");
      let startDate = moment(`01/${oldStartMonth}/${oldStartYear}`, "DD/MM/YYYY").unix();
      let oldEndMonth = moment().month(userSchoolList[i].enddatemonth).format("MM");
      let oldEndYear = moment().year(userSchoolList[i].enddateyear).format("YYYY");
      let endDate = moment(`01/${oldEndMonth}/${oldEndYear}`, "DD/MM/YYYY").unix();
      let newStartMonth = moment().month(data.startdatemonth).format("MM");
      let newStartYear = moment().year(data.startdateyear).format("YYYY");
      let newStartDate = moment(`01/${newStartMonth}/${newStartYear}`, "DD/MM/YYYY").unix();
      let newEndMonth = moment().month(data.enddatemonth).format("MM");
      let newEndYear = moment().year(data.enddateyear).format("YYYY");
      let newEndDate = moment(`01/${newEndMonth}/${newEndYear}`, "DD/MM/YYYY").unix();
      if ((startDate <= newStartDate && newStartDate <= endDate) || (startDate <= newEndDate && newEndDate <= endDate)) {
        flag = false;
        // messagePopup("", "School with same start & end date already exist.", "error");
        toastr.error("School with same start & end date already exist", "", {
          progressBar: true, timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      }
    }
    return flag;
  };
  const isEditStatus = (value, postData) => {
    const data = value == "clg" ? userCollegeList : userSchoolList,
      filData = data?.filter(el => el?.id == postData?.id)
    if (filData?.length > 0) {
      if (
        filData[0]?.startdatemonth == postData?.startdatemonth &&
        filData[0]?.startdateyear == postData?.startdateyear &&
        filData[0]?.enddatemonth == postData?.enddatemonth &&
        filData[0]?.enddateyear == postData?.enddateyear
      ) return false
      return true
    }
  }
  const onSchoolSaveHandler = (data) => {
    //this length start and end year check is when user
    // come from onboarding and start and end year is null
    let flag = (
      userSchoolList?.length == 1 || (userSchoolList?.[0]?.startdateyear == null
        && userSchoolList?.[0]?.enddateyear == null)
    ) ? true
      :
      !!data?.id ? true : existSchoolName(data);
    if (flag) {
      if (userSchoolEditValue) {
        if (
          !userSchoolEditValue &&
          !userSchoolEditValue.highschoolname &&
          !userSchoolEditValue.highschooltype &&
          !userSchoolEditValue.division &&
          !userSchoolEditValue.schoolcurrentleveloption &&
          !userSchoolEditValue.startdatemonth &&
          !userSchoolEditValue.startdateyear &&
          !userSchoolEditValue.enddatemonth &&
          !userSchoolEditValue.enddateyear &&
          !userSchoolEditValue.currentltnotinhighschool &&
          !userSchoolEditValue.zipcode
        ) {
          // messagePopup("", "School Save Unsuccessful.", "warning");
          toastr.warning("School Save Unsuccessful", "", {
            progressBar: true, timeOut: 2000,
            closeButton: true, positionClass: "toast-top-full-width",
            showEasing: "swing", fadeIn: 40000,
            preventDuplicates: true,
          })
          return;
        }
        //schoolcurrentleveloption
        userSchoolEditValue.currentltnotinhighschool =
          userSchoolEditValue.currentltnotinhighschool ? "1" : "0";
        userSchoolEditValue.enddateyear = userSchoolEditValue.enddateyear
          ? userSchoolEditValue.enddateyear.toString()
          //now it is string as asked
          // parseInt(userSchoolEditValue.enddateyear)
          : null;
        userSchoolEditValue.startdateyear = userSchoolEditValue.startdateyear
          ? userSchoolEditValue.startdateyear.toString()
          //now it is string as asked
          // parseInt(userSchoolEditValue.startdateyear)
          : null;
        userSchoolEditValue.id = !!userSchoolEditValue.id
          ? userSchoolEditValue.id
          : 0;
        userSchoolEditValue.schoolcurrentleveloption = !!userSchoolEditValue.schoolcurrentleveloption
          ? userSchoolEditValue.schoolcurrentleveloption.toString()
          : "null";
        userSchoolEditValue.highschoolname = userSchoolEditValue?.highschoolname?.highschoolname
          ? userSchoolEditValue?.highschoolname?.highschoolname
          : userSchoolEditValue?.highschoolname;
        userSchoolEditValue.division = !!userSchoolEditValue?.division?.division
          ? userSchoolEditValue?.division?.division.toString()
          : userSchoolEditValue?.division?.toString() || "null";
        userSchoolEditValue.zipcode = !!userSchoolEditValue?.zipcode?.zipcode
          ? userSchoolEditValue?.zipcode?.zipcode.toString() || "null"
          : userSchoolEditValue?.zipcode?.toString() || "null";
        userSchoolEditValue.highschooltype = !!userSchoolEditValue?.highschooltype?.highschooltype
          ? userSchoolEditValue?.highschooltype?.highschooltype.toString()
          : userSchoolEditValue?.highschooltype?.toString() || "null";
        userSchoolEditValue.schoolcurrentleveloption = !!userSchoolEditValue?.schoolcurrentleveloption?.schoolcurrentleveloption
          ? userSchoolEditValue?.schoolcurrentleveloption?.schoolcurrentleveloption.toString() || "null"
          : userSchoolEditValue?.schoolcurrentleveloption?.toString() || "null";
        //this is not in need as this is for month date check
        // userSchoolEditValue.isSchoolDateEdit = isEditStatus('school',data)
        props.userAcademicRestSchoolSave({
          query: saveUserSchool,
          variables: {
            obj: {
              userid: getUserInfo() ? getUserInfo().id : null,
              //not asked for now
              // active: 1,
              isuserschool: "1",
              ...userSchoolEditValue,
            },
          },
          handleSchoolMessage: () => {
            handleSchoolMessage();
            initialSet();
          },
          nextRoute,
          getAllSchoolListAPI
        });
      }
    }
    // setTimeout(()=>{
    //   getSchoolList()
    // },400)
    dispatch({ type: "RENDER_COUNT_START", payload: 1 })
  };
  //this func is used for call the listing update operation
  const getAllSchoolListAPI = () => {
    getSchoolList()
  }
  //this fun cis for check when user come from checklist
  const nextRoute = () => {
    if (props?.location?.state?.previousPage) return props.history.push(`/${props?.location?.state?.previousPage}`)
  }
  const onSchoolInput = (schoolData) => {
    setUserSchoolEditValue(schoolData);
  };
  const onCollegeInput = (collegeData) => {
    setUserCollegeEditValues(collegeData);
  };
  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };
  const onAddEditClick = () => {
    setShowAddEditModal(!showAddEditModal);
  };

  const onAddEditCollegeClick = () => {
    setAddEditCollegeModal(!addEditCollegeModal);
  };
  const onDeleteClick = (objectId, isCollege) => {
    setShowDeleteModal(!showDeleteModal);
    if (isCollege) {
      setUserCollegeIdDel(objectId);
    } else {
      setUserSchoolIdDel(objectId);
    }
  };
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };

  const onCollegeDeleteHandler = () => {
    props.userAcademicCollegeSave({
      query: saveUserCollege,
      variables: {
        obj: {
          id: userCollegeIdDel,
          active: 0,
        },
      },
    });
    setShowDeleteModal(!showDeleteModal);
    setUserCollegeIdDel(0);
  };

  const onSchoolDeleteHandler = () => {
    props.userAcademicSchoolSave({
      //earllier this query was used
      // saveUserSchool
      query: saveAllAthleteMoreSchoolQuery,
      variables: {
        obj: {
          id: userSchoolIdDel,
          active: 0,
        },
      },
      isAcademic: true
    });
    setShowDeleteModal(!showDeleteModal);
    setUserSchoolIdDel(0);
  };
  return (
    <div className="pageContent">
      <div className="editProfile">
        <input className="fakeInput" type="text" ref={fakeInput} />
        <BackdropLoader
          open={
            props.allUserAcademicCollegeList.loading ||
            props.saveAllUserAcademicCollege.loading ||
            props.allUserAcademicSchoolList.loading ||
            props.saveAllUserAcademicSchool.loading
          }
        />
        <div className="container">
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
          >
            <SubHeader
              hamburger={onSideMenuClick}
              buttonHide={saveButtonHide}
            // onSave={() => {
            //   onSchoolSaveHandler();
            //   onCollegeSaveHandler();
            // }}
            />
            <div className="sidebar">
              <LeftSideEditProfile isSaved={true} />
            </div>
            <EditSection
              data={props}
              onAddEditClick={onSchoolEditHandler}
              onAddEditCollegeClick={onEditHandler}
              onDeleteClick={onDeleteClick}
              userCollegeList={userCollegeList}
              userSchoolList={userSchoolList}
            />
          </div>
        </div>
        <GoTopButton goTop={goTop} />
      </div>
      <Dialog open={showAddEditModal} maxWidth={"lg"}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onAddEditClick()
                }
              }}
              className="matDialogCustom"
      >
        <AddEditOverlay
          onAddEditClick={onAddEditClick}
          months={props.graduationMonths.data}
          years={props.graduationYears.data}
          onSave={onSchoolSaveHandler}
          schools={schoolArray}
          schoolLevels={schoolLevelList}
          schoolDivisions={schoolDivisionList}
          schoolType={schoolTypeList}
          editValues={userSchoolEditValue}
          onSchoolChange={onSchoolInput}
        />
      </Dialog>
      <Dialog open={addEditCollegeModal} maxWidth={"lg"}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onAddEditCollegeClick()
                }
              }}
              className="matDialogCustom"
      >
        <AddEditCollegeOverlay
          onAddEditCollegeClick={onAddEditCollegeClick}
          allCollegeList={collegeArray}
          allTeamList={teamsList}
          months={props.graduationMonths.data}
          years={props.graduationYears.data}
          collegeLevels={collegeLevelList}
          collegeDivisions={collegeDivisionList}
          onSave={onCollegeSaveHandler}
          editValues={userCollegeEditValue}
          onCollegeChange={onCollegeInput}
        />
      </Dialog>
      <Dialog open={showDeleteModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onDeleteClick()
                }
              }}
              className="matDialogCustom"
      >
        <DeleteItem
          onCollegeDeleteClick={onCollegeDeleteHandler}
          collegeId={userCollegeIdDel}
          schoolId={userSchoolIdDel}
          onSchoolDeleteClick={onSchoolDeleteHandler}
          onCancelClick={onDeleteClick}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    collegeList: state.common.collegeDetails,
    teamList: state.athlete.editUserProfileData.teamsList,
    graduationYears: state.common.graduationYears,
    graduationMonths: state.common.graduationMonths,
    schoolTypeList: state.athlete.editUserProfileData.schoolTypeList,
    schoolLevelList: state.athlete.editUserProfileData.academiclevelsList,
    collegeLevelList: state.athlete.editUserProfileData.academiclevelsList,
    collegeDivisionsList:
      state.athlete.editUserProfileData.academicDivisionsList,
    schoolList: state.athlete.editUserProfileData.highSchoolList,
    schoolDivisionsList:
      state.athlete.editUserProfileData.academicDivisionsList,
    allUserAcademicCollegeList:
      state.athlete.editUserProfileData.userAcademicCollege,
    saveAllUserAcademicCollege:
      state.athlete.editUserProfileData.userAcademicCollege,
    allUserAcademicSchoolList:
      state.athlete.editUserProfileData.userAcademicSchool,
    saveAllUserAcademicSchool:
      state.athlete.editUserProfileData.userAcademicSchool,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    allCollegeDetailsListStart: (data) =>
      dispatch(allCollegeDetailsListStart(data)),
    allTeamListStart: (data) => dispatch(allTeamsListStart(data)),
    graduationYearsListStart: () => dispatch(graduationYearsListStart()),
    graduationMonthsListStart: () => dispatch(graduationMonthsListStart()),
    schoolLevelListStart: (data) => dispatch(allLevelsSchoolStart(data)),
    schoolTypeListStart: (data) => dispatch(allSchoolTypeListStart(data)),
    collegeLevelListStart: (data) => dispatch(allLevelsCollegeStart(data)),
    collegeDivisionListStart: (data) =>
      dispatch(allAcademicDivisionsListStart(data)),
    schoolDivisionListStart: (data) =>
      dispatch(allAcademicDivisionsSchoolListStart(data)),
    userAcademicCollegeSave: (data) => dispatch(userAcademicCollegeSave(data)),
    userAcademicRestCollegeSave: (data) => dispatch(userAcademicRestCollegeSave(data)),
    userAcademicCollegeList: (data) => dispatch(userAcademicCollegeStart(data)),
    schoolListStart: (data) => dispatch(allSchoolListStart(data)),
    userAcademicSchoolSave: (data) => dispatch(userAcademicSchoolSave(data)),
    userAcademicRestSchoolSave: (data) => dispatch(userAcademicRestSchoolSave(data)),
    userAcademicSchoolListStart: (data) =>
      dispatch(userAcademicSchoolStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Academics);
