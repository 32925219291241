export const getAllMembershipsQuery = `
query allMemberships($where : JSON){
    allMemberships(where: $where){
      Memberships{
        id
        userid
        isUE
        createdon
        subscriptionplanid
        videoandphotoplanid
        videoeditingorderplanid
        issubscriptioncancelled
        planstartdate
        planexpirydate
        plantimeperiod
        planprice
        planrenewaldate
        installmentnumber
        totalinstallments
        installmentremaining
        nextinstallmentamount
        remainingamount
        issubscriptioncancelled
        totalinvestment
        membershipvideoandphotoplanidmaprel{
          Uploadedvideoandphotos{
            videoname
          }
        }
        membershipvideoeditingorderplanidmaprel{
          Videoedittingorders{
            allusededittngcount
            videoedittingorderpalnidrel{
              Videoedittingmasters{
                videofor
                description
                videoedittingplanprice
                videoedittingtotalcount
                createdon
                updatedon
              }
            }
          }
        }
        
        membershipsubscriptionplantypemaprel{
        Subscriptionplantypes{
          plantype
        }
      }
      
        membershipsubscriptionplanidmaprel{
          Subscriptionplans{
            planname
            description
            plantype
            subscriptionplanincludessubscriptionplanidMap{
                Subscriptionplanincludes{
                  subscriptionplanid
                  includeplanname                
                }
              }
          } 
        }
      }
    }
  }
`