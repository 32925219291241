import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Prompt, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import CollegesTabs from "../CollegesTabs";
import "../assets/css/collegePreferences.scss";
import "../assets/css/collegePreferences.css";
import { getAllAreaofstudyQuery } from "../searchColleges/SearchCollegesQuery";
import { areaOfStudyListStart } from "../../../../../../redux/athlete/dashboard/colleges/areaOfStudyList";
import {
    getAllAcadamicSelectivities,
    getAllCollegeSettings,
    getAllTutionCosts,
    getAllCollegeSizes,
    getAllCollegedivisions,
    getAllRegion,
    getAllState,
    destroyAllCollegeSetting,
    saveCollegesetting,
    allCollegepreferencesettings,
    saveCollegePreferenceSetting, saveCollegePreferenceSettingQuery, getAllActiveState
} from "./collegesPreferencesQuery";
import { academicSelectivityListStart } from "../../../../../../redux/athlete/dashboard/colleges/academicSelectivityList";
import { CollegeSettingsListStart } from "../../../../../../redux/athlete/dashboard/colleges/collegeSettingsList";
import { collegeSizeListStart } from "../../../../../../redux/athlete/dashboard/colleges/collegeSizeList";
import { TutionCostsListStart } from "../../../../../../redux/athlete/dashboard/colleges/tutionCostsList";
import { CollegedivisionsListStart } from "../../../../../../redux/athlete/dashboard/colleges/collegedivisionsList";
import { AllRegionListStart } from "../../../../../../redux/athlete/dashboard/colleges/allRegionList";
import { AllStateListStart } from "../../../../../../redux/athlete/dashboard/colleges/allStateList";
import { fetchGraphMethod } from "../../../../../../services/httpService";
import swal from "sweetalert";
import {getUserInfo} from "../../../../../../services/authService";
import toastr from 'toastr';

function CollegesPreferences(props) {

    const location = useLocation();
    const history = useHistory();
    const [lastLocation, setLastLocation] = useState(location);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [academicSelectivity, setAcademicSelectivity] = useState([])
    const [level, setLevel] = useState([])
    const [allState, setAllState] = useState(false)
    const [isChangeState, setIsChangeState] = useState(false)
    const [isMatchView, setIsMatchView] = useState(false)
    const [regionList, setRegionList] = useState([])
    const [stateList, setStateList] = useState([])
    const [collegeSetting, setCollegeSetting] = useState([])
    const [collegeSize, setCollegeSize] = useState([])
    const [tutionCost, setTutionCost] = useState([])
    const [areaOfStudy, setAreaOfStudy] = useState([])
    const [timeout, settimeout] = useState(0)
    const [areaOfStudyList, setAreaOfStudyList] = useState(null);
    const [postgradschoolinterest, setPostgradschoolinterest] = useState([])
    const [searchText, setSearchText] = useState("")
    const [expandFields, setExpandFields] = useState({})
    const [stateSelected, setStateSelected] = useState([])
    const [subDivisionLevel, setSubDivisionLevel] = useState([])
    const [levelItemArray, setSubLevelItemArray] = useState([])
    const [editableData, setEditableData] = useState([])
    const [filterData, setFilterData] = useState({})
    const [selectAfterReset, setSelectAfterReset] = useState(false)
    const [isReset, setIsReset] = useState(false)
    const [moreStates, setMoreStates] = useState(false)

    const handleIsMore = (e) => {
        setMoreStates(!moreStates)        
    }

    const showModal = async(nextLocation) => {
      const willSave = await swal({
        text: "Changes you made, may not be saved",
        icon: 'warning',
        buttons: {
            confirm: { text: "Stay" },
            cancel: "Leave",
        },
    })
    if(!willSave){
        setConfirmedNavigation(true);
    }
      setLastLocation(nextLocation);
    };
  
    const handleBlockedRoute = (nextLocation) => {
      if (!confirmedNavigation && isChangeState) {
        showModal(nextLocation);
        return false;
      }
      return true;
    };
  
    useEffect(() => {
      if (confirmedNavigation && lastLocation) {
        history.push(lastLocation.pathname);
      }
    }, [confirmedNavigation, lastLocation, history]);


    useEffect(async () => {
        // set academicSelectivity
        handleAcademicSelectivity()
        //set level
        handleLevel()

        // set collegeSetting
        handleCollegeSetting()

        // set collegeSize
        handleCollegeSize()
        //set tutionCost
        handletutionCost()

        // set all Region
        handleAllRegion()

        //set all area of study list
        props.areaOfStudyListStart({
            query: getAllAreaofstudyQuery,
          });

        //set all State
        await handleAllState()
        //earlier it was json.parse due to which it is creating error and user cannot able to click
        if (!!getUserInfo()?.id) {
            allCollegePreferenceSettings(getUserInfo()?.id ?? 0)
        }
    }, [])

    useEffect(() => {
        if (props?.areOfStudyList?.data?.length > 0) {
            // change done as of oct task jira 190
            props?.areOfStudyList?.data?.sort((a, b)=>{
                return  a.areaofstudyname?.split(" ")?.[0]?.toLowerCase() > b.areaofstudyname?.split(" ")?.[0]?.toLowerCase() ? 1
                    : b.areaofstudyname?.split(" ")?.[0]?.toLowerCase() > a.areaofstudyname?.split(" ")?.[0]?.toLowerCase() ? -1 : 0
            })
            const tempData = props.areOfStudyList.data;
            
          setAreaOfStudyList(tempData);
        }
      }, [props?.areOfStudyList?.data]);

    useEffect(() => {
        if(props?.collegedivisionsList?.data?.length > 0){
            let data = props?.collegedivisionsList?.data?.map(item=>{
                item["id"] = item.id;
                item["subLevelArray"] = item?.divisionathleticselectivitymappingCollegedivisionidMap?.Divisionathleticselectivitymappings?.map(subItem=>{
                    subItem?.divisionathleticselectivitymappingathleticselectivityidmaprel?.Athleticselectivities?.map(subitem1=> subitem1["parent"]=item.id)
                    return subItem?.divisionathleticselectivitymappingathleticselectivityidmaprel?.Athleticselectivities?.[0];
                })
                if(editableData?.length > 0){
                    editableData?.map(newValue=>{
                        if(parseFloat(newValue.label) === item.id){
                            item["selected"] = newValue.subLevel
                        }
                    })
                }
                else{
                    item["selected"]=[];
                }
                return item;
            })
            setSubLevelItemArray(data)
        }
    }, [props?.collegedivisionsList?.data])

    useEffect(()=>{
        if(editableData?.length > 0 && props?.collegedivisionsList?.data?.length > 0){
            let data = props?.collegedivisionsList?.data?.map(item=>{
                item["id"] = item.id;
                item["subLevelArray"] = item?.divisionathleticselectivitymappingCollegedivisionidMap?.Divisionathleticselectivitymappings?.map(subItem=>{
                    subItem?.divisionathleticselectivitymappingathleticselectivityidmaprel?.Athleticselectivities?.map(subitem1=> subitem1["parent"]=item.id)
                    return subItem?.divisionathleticselectivitymappingathleticselectivityidmaprel?.Athleticselectivities?.[0];
                })
                if(editableData?.length > 0){
                    editableData?.map(newValue=>{
                        if(parseFloat(newValue.label) === item.id){
                            item["selected"] = newValue.subLevel
                        }
                    })
                }
                else{
                    item["selected"]=[];
                }
                return item;
            })
            setSubLevelItemArray(data)
        }
        if(selectAfterReset && props?.collegedivisionsList?.data?.length > 0){
            let data = props?.collegedivisionsList?.data?.map(item=>{
                item["selected"]=[];
                return item;
            })
            setSubLevelItemArray(data)
        }
    }, [editableData])

    const allCollegePreferenceSettings = async (userId) => {
        const variables = {data:{userid: userId }}
        const res = await fetchGraphMethod(
            allCollegepreferencesettings,
            variables,
            true
        )
        if (res.status == 200 && res?.data?.data?.allCollegepreferencesettings?.Collegepreferencesettings) {
            var acadamicselectivityidData = []
            var collegesettingData = []
            var collegesizeData = []
            var athleticselectivityid = []
            // isallstate: 1
            var levelidData = []
            // postgradschoolinterest: "Yes"
            var regionidData = []
            var stateidData = []
            var tutioncostData = []
            var areaOfStudy = []
            var clgPreID = 0
            let filteredData = filterData;
            if(res?.data?.data?.allCollegepreferencesettings?.totalCount){
                let PreferenceData = res?.data?.data?.allCollegepreferencesettings?.Collegepreferencesettings[0]
                
                if(!PreferenceData?.acadamicselectivityid && !PreferenceData?.levelid && !PreferenceData?.regionid && !PreferenceData?.stateid && !PreferenceData?.isallstate && !PreferenceData?.collegesize && !PreferenceData?.collegesetting && !PreferenceData?.tutioncost){
                    setIsReset(true)
                    setIsMatchView(true)
                }
            }else{
                setIsReset(true)
                setIsMatchView(true)
            }
            var data = res?.data?.data?.allCollegepreferencesettings?.Collegepreferencesettings?.length > 0 ?
                res?.data?.data?.allCollegepreferencesettings?.Collegepreferencesettings.map(ele => {
                    if(ele.athleticselectivityid) athleticselectivityid = JSON.parse(ele.athleticselectivityid);
                    if (ele.acadamicselectivityid) acadamicselectivityidData = JSON.parse(ele.acadamicselectivityid)
                        // acadamicselectivityidData.push(parseFloat(ele.acadamicselectivityid))
                    if (ele.collegesetting) collegesettingData = JSON.parse(ele.collegesetting)
                        // collegesettingData.push(parseFloat(ele.collegesetting))
                    if (ele.collegesize) collegesizeData = JSON.parse(ele.collegesize)
                        // collegesizeData.push(parseFloat(ele.collegesize))
                    if (ele.isallstate)
                    { 
                        filteredData['isallstate'] = parseFloat(ele.isallstate)
                        setAllState(parseFloat(ele.isallstate))
                    }
                    if (ele.levelid) levelidData = JSON.parse(ele.levelid)
                        // levelidData.push(parseFloat(ele.levelid))
                    if (ele.postgradschoolinterest) {
                        filteredData['postgradschoolinterest'] = JSON.parse(ele.postgradschoolinterest)
                            // [parseFloat(ele.postgradschoolinterest)];
                        setPostgradschoolinterest(filteredData['postgradschoolinterest']
                            // [parseFloat(ele.postgradschoolinterest)]
                        )
                    }
                    if (ele.regionid) regionidData = JSON.parse(ele.regionid)
                        // regionidData.push(parseFloat(ele.regionid))
                    if (ele.stateid)  stateidData = JSON.parse(ele.stateid)
                        // stateidData.push(parseFloat(ele.stateid));
                    if (ele.tutioncost) tutioncostData = JSON.parse(ele.tutioncost)
                        // tutioncostData.push(parseFloat(ele.tutioncost))
                    if (ele.areaofstudy) areaOfStudy = JSON.parse(ele.areaofstudy)
                        // areaOfStudy.push(parseFloat(ele.areaofstudy))
                    if (ele?.id) clgPreID = ele?.id
                })
                : null
                filteredData['acadamicselectivityidData'] = acadamicselectivityidData;
                filteredData['athleticselectivityid'] = athleticselectivityid?.length > 0 ?
                    athleticselectivityid :[];
                filteredData['collegesettingData'] = collegesettingData;
                filteredData['collegesizeData'] = collegesizeData;
                filteredData['levelidData'] = levelidData;
                filteredData['regionidData'] = regionidData;
                filteredData['tutioncostData'] = tutioncostData;
                filteredData['areaOfStudy'] = areaOfStudy;
                filteredData['id'] = clgPreID;
            setAcademicSelectivity(acadamicselectivityidData)
            setSubDivisionLevel(athleticselectivityid?.length > 0 ? athleticselectivityid :[])
            setEditableData(athleticselectivityid?.length > 0 ? athleticselectivityid : [])
            if (allState && selectAfterReset) {
                var statesId = props.allStateList.data.map(
                    ele => ele.id
                )
                var stateName = props.allStateList.data.map(ele => ele.statename)
                setStateList(statesId)
                filteredData['stateidData'] = statesId;
                setStateSelected(stateName)
            } else {
                filteredData['stateidData'] = statesId;
                setStateList(stateidData)
            }
            if(areaOfStudy.length){
                setIsReset(false)
                setIsMatchView(false)
            }
            setAreaOfStudy(areaOfStudy)
            setCollegeSize(collegesizeData)
            setCollegeSetting(collegesettingData)
            setLevel(levelidData)
            setRegionList(regionidData)
            setTutionCost(tutioncostData)
            setFilterData(filteredData)
        }
        return res
    }

    useEffect(()=>{
        if(stateList?.length > 0){
            let statNameData = []
            const data = props?.allStateList?.data?.length > 0 ? 
                    props?.allStateList?.data?.map(item=>{
                        stateList?.map(ele=> {
                            if(parseFloat(ele) === item.id){
                                statNameData.push(item.statename)
                            }
                        })
                    })
                    : []
            setStateSelected(statNameData);
            const cloneFilterData = filterData
            cloneFilterData['selStateName'] = statNameData
            setFilterData(cloneFilterData)
        }
    },[stateList, props.allStateList.data])

    useEffect(()=>{
        if(props?.allStateList?.data?.length){
            props.allStateList.data.sort((a, b)=> {
                if (a.countryid === b.countryid){
                  return a.statename < b.statename ? -1 : 1
                } else {
                  return a.countryid < b.countryid ? -1 : 1
                }
            })
        }
    }, [props.allStateList.data])

    const handleAcademicSelectivity = async () => {
        props.academicSelectivityListStart({
            query: getAllAcadamicSelectivities
        });
    }

    const handleCheckBox = async (e, type, label, item) => {
        setIsChangeState(true)
        setIsMatchView(true)
        if (type === "academicSelectivity") {
            if (e.target.checked) {
                setAcademicSelectivity(academicSelectivity.concat([label]))
            } else {
                const arr = academicSelectivity.filter(function (item) {
                    return item !== label
                })
                setAcademicSelectivity(arr)
            }
        }
        if (type === "level") {
            //handleExpandCollapse(e, label)
            if (e.target.checked) {
                setLevel(level.concat([label]))
                subDivisionLevel.push({label: label, subLevel: []})
                const expandFieldsT = expandFields;
                expandFieldsT[label] = true;
                setExpandFields({ ...expandFieldsT });
            } else {
                const arr = level.filter(function (item) {
                    return item !== label
                });
                const newArray = subDivisionLevel.filter(item=> item.label !== label);
                const newLevelItems = levelItemArray.map(item => {
                    if(item.id == label){
                        item.selected = []
                    }
                    return item;
                })
                setSubLevelItemArray(newLevelItems)
                setSubDivisionLevel(newArray)
                setLevel(arr)
            }
        }
        if (type === "allState") {
            if (e.target.checked) {
                if (props.allStateList.data) {
                    var statesId = props.allStateList.data.map(
                        ele => ele.id
                    )
                    var stateName = props.allStateList.data.map(
                        ele => ele.statename
                    )
                    setStateSelected(stateName)
                    setStateList(statesId)
                }
                setAllState(true)
                setRegionList([])
            } else {
                setStateSelected([])
                setAllState(false)
                setStateList([])
                setRegionList([])
            }
            setMoreStates(false)
        }
        if (type === "regionList") {
            if (e.target.checked) {
                if (props.allStateList.data) {
                    var statesId = props.allStateList.data.map(
                        ele => {
                            if (ele.regionid == label) return ele.id
                        }
                    )
                    setStateList(stateList.concat(statesId))
                }
                setRegionList(regionList.concat([label]))
            } else {
                const arr = regionList.filter(function (item) {
                    return item !== label
                })
                if (props.allStateList.data) {
                    var statesId = props.allStateList.data.map(
                        ele => {
                            if (ele.regionid == label) return ele.id
                        }
                    )
                    var stateListIds = stateList.map(ele => {
                        if (!statesId.includes(ele)) return ele
                    })
                    setStateList(stateListIds)
                }
                setRegionList(arr)
            }
            setMoreStates(false)
        }
        if (type === "stateList") {
            if (e.target.checked) {
                setStateList(stateList.concat([label]))
            } else {
                const arr = stateList.filter(function (item) {
                    return item !== label
                })
                setStateList(arr)
                setAllState(false)
                setRegionList([])
            }
        }

        if (type === "collegeSetting") {
            if (e.target.checked) {
                setCollegeSetting(collegeSetting.concat([label]))
            } else {
                const arr = collegeSetting.filter(function (item) {
                    return item !== label
                })
                setCollegeSetting(arr)
            }
        }
        if (type === "collegeSize") {
            if (e.target.checked) {
                setCollegeSize(collegeSize.concat([label]))
            } else {
                const arr = collegeSize.filter(function (item) {
                    return item !== label
                })
                setCollegeSize(arr)
            }
        }
        if (type === "tutionCost") {
            if (e.target.checked) {
                setTutionCost(tutionCost.concat([label]))
            } else {
                const arr = tutionCost.filter(function (item) {
                    return item !== label
                })
                setTutionCost(arr)
            }
        }

        if (type === "postgradschoolinterest") {
            if (e.target.checked) {
                setPostgradschoolinterest([label])
            } else {
                const arr = postgradschoolinterest.filter(function (item) {
                    return item !== label
                })
                setPostgradschoolinterest(arr)
            }
        }

        if(type === "areaOfStudy"){
            if(e.target.checked) {
                setAreaOfStudy(areaOfStudy.concat([label]))
            }
            else{
                const arr = areaOfStudy.filter(function (item) {
                    return item !== label
                })
                setAreaOfStudy(arr)
            }
        }

    }
    const handleCollegeSetting = async () => {
        props.CollegeSettingsListStart({
            query: getAllCollegeSettings
        });
    }

    const handleCollegeSize = async () => {
        props.collegeSizeListStart({
            query: getAllCollegeSizes
        });
    }
    const handletutionCost = async () => {
        props.TutionCostsListStart({
            query: getAllTutionCosts
        });
    }

    const handleLevel = async () => {
        props.CollegedivisionsListStart({
            query: getAllCollegedivisions
        });
    }

    const handleAllRegion = async () => {
        props.AllRegionListStart({
            query: getAllRegion
        });
    }

    const handleAllState = async () => {
        props.AllStateListStart({
            query: getAllActiveState,
            variables: {where:{active:1}}
        });
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value.toLowerCase())
    }

    const destroyCollegePre = async (userid) => {
        const variables = {
            userid: userid
        }
        const res = await fetchGraphMethod(
            destroyAllCollegeSetting,
            variables,
            true
        )
        return res
    }

    const saveCollegeSetting = async (arrayOfdata) => {
        const variables = {
            obj: arrayOfdata
        }
        const res = await fetchGraphMethod(
            // saveCollegesetting,
            saveCollegePreferenceSettingQuery,
            variables,
            true
        )
        setIsChangeState(false)
        setIsMatchView(false)
        if (res.status == 200) {
            toastr.success("College preferences saved successfully", "", {
                progressBar : true,timeOut: 2000,
                closeButton: true, positionClass: "toast-top-full-width",
                showEasing: "swing", fadeIn: 40000,
              })
        }
        return res
    }

    const getStateId = (stateArray) =>{
        const stateID = []
            stateArray?.length > 0 && stateArray.map(el => el != null && stateID.push(el))
        return stateID?.length > 0 ? JSON.stringify(stateID) : null
    }

    const handleSaveData = async () => {
        try {
            if (academicSelectivity.length > 0 || collegeSetting.length > 0 || collegeSize.length > 0 ||
                allState.length > 0 || level.length > 0 || postgradschoolinterest.length > 0 || regionList.length > 0 ||
                stateList.length > 0 || tutionCost.length > 0 || areaOfStudy?.length > 0 || subDivisionLevel?.length > 0
            ) {
                // const willSave = await swal({
                //     text: "Do you want to save your college preferences",
                //     icon: 'warning',
                //     buttons: {
                //         confirm: { text: "Ok" },
                //         cancel: "Cancel",
                //     },
                //     dangerMode: false,
                // })
                // if (willSave) {
                    if (JSON.parse(localStorage.getItem('user')).id) {
                        //this is not in use as per preeti said
                        // const destroyCollegePreData = await destroyCollegePre(JSON.parse(localStorage.getItem('user')).id)
                        setIsChangeState(false)
                        const userId = JSON.parse(localStorage.getItem('user')).id
                        let filteredData = filterData;
                        filteredData['acadamicselectivityidData'] = academicSelectivity;
                        filteredData['athleticselectivityid'] = subDivisionLevel;
                        filteredData['areaOfStudy'] = areaOfStudy;
                        filteredData['collegesettingData'] = collegeSetting;
                        filteredData['collegesizeData'] = collegeSize;
                        filteredData['levelidData'] = level;
                        filteredData['regionidData'] = regionList;
                        filteredData['tutioncostData'] = tutionCost;
                        filteredData['postgradschoolinterest'] = postgradschoolinterest;
                        if (allState) {
                            // var iterator = [academicSelectivity, level, collegeSetting, collegeSize, tutionCost, postgradschoolinterest, areaOfStudy, subDivisionLevel]
                            // var iteratorLength = [academicSelectivity.length, level.length, collegeSetting.length, collegeSize.length, tutionCost.length, postgradschoolinterest.length, areaOfStudy.length, subDivisionLevel.length]
                            // var newIterator = iterator[iteratorLength.indexOf(Math.max(...iteratorLength))]
                            filteredData['isallstate'] = 1;
                            // filteredData['stateidData'] = null;
                            // var finalArrayOfObjetct = newIterator.map((ele, index) => {
                            //     var userObject = {
                            //         acadamicselectivityid: academicSelectivity.length > index ? academicSelectivity[index] : null,
                            //         collegesetting: collegeSetting.length > index ? collegeSetting[index] : null,
                            //         collegesize: collegeSize.length > index ? collegeSize[index] : null,
                            //         createdby: userId,
                            //         isallstate: 1,
                            //         levelid: level.length > index ? level[index] : null,
                            //         postgradschoolinterest: postgradschoolinterest.length > index ? postgradschoolinterest[index] : null,
                            //         regionid: null,
                            //         stateid: null,
                            //         tutioncost: tutionCost.length > index ? tutionCost[index] : null,
                            //         updatedby: userId,
                            //         userid: userId,
                            //         areaofstudy: areaOfStudy.length > index ? areaOfStudy[index] : null,
                            //         athleticselectivityid: subDivisionLevel.length > 0 ? JSON.stringify(subDivisionLevel) : null
                            //     }
                            //     return userObject
                            // })
                            var userObject = {
                                acadamicselectivityid: academicSelectivity.length > 0 ? JSON.stringify(academicSelectivity) : null,
                                collegesetting: collegeSetting.length > 0 ? JSON.stringify(collegeSetting) : null,
                                collegesize: collegeSize.length > 0 ? JSON.stringify(collegeSize) : null,
                                createdby: userId,
                                isallstate: 1,
                                levelid: level.length > 0 ? JSON.stringify(level) : null,
                                postgradschoolinterest: postgradschoolinterest.length > 0 ? JSON.stringify(postgradschoolinterest) : null,
                                regionid: null,
                                stateid: stateList.length > 0 ? getStateId(stateList) : null,
                                tutioncost: tutionCost.length > 0 ? JSON.stringify(tutionCost) : null,
                                updatedby: userId,
                                userid: userId,
                                id: filteredData?.id,
                                areaofstudy: areaOfStudy.length > 0 ? JSON.stringify(areaOfStudy) : null,
                                athleticselectivityid: subDivisionLevel.length > 0 ? JSON.stringify(subDivisionLevel) : null
                            }
                            await saveCollegeSetting(
                                // finalArrayOfObjetct
                                userObject
                            )
                        } else {
                            // var iterator = [academicSelectivity, stateList, level, collegeSetting, collegeSize, tutionCost, postgradschoolinterest, areaOfStudy, subDivisionLevel]
                            // var iteratorLength = [academicSelectivity.length, stateList.length, level.length, collegeSetting.length, collegeSize.length, tutionCost.length, postgradschoolinterest.length, areaOfStudy.length, subDivisionLevel.length]
                            // var newIterator = iterator[iteratorLength.indexOf(Math.max(...iteratorLength))]
                            // filteredData['isallstate'] = 0;
                            // filteredData['stateidData'] = stateList;
                            // var finalArrayOfObjetct = newIterator.map((ele, index) => {
                            //     var userObject = {
                            //         acadamicselectivityid: academicSelectivity.length > index ? academicSelectivity[index] : null,
                            //         collegesetting: collegeSetting.length > index ? collegeSetting[index] : null,
                            //         collegesize: collegeSize.length > index ? collegeSize[index] : null,
                            //         createdby: userId,
                            //         isallstate: 0,
                            //         levelid: level.length > index ? level[index] : null,
                            //         postgradschoolinterest: postgradschoolinterest.length > index ? postgradschoolinterest[index] : null,
                            //         regionid: regionList.length > index ? regionList[index] : null,
                            //         stateid: stateList.length > index ? stateList[index] : null,
                            //         tutioncost: tutionCost.length > index ? tutionCost[index] : null,
                            //         updatedby: userId,
                            //         userid: userId,
                            //         areaofstudy: areaOfStudy.length > index ? areaOfStudy[index] : null,
                            //         athleticselectivityid: subDivisionLevel?.length > 0 ? JSON.stringify(subDivisionLevel) : null
                            //     }
                            //     return userObject
                            // })
                                var userObject = {
                                    acadamicselectivityid: academicSelectivity.length > 0 ? JSON.stringify(academicSelectivity) : null,
                                    collegesetting: collegeSetting.length > 0 ? JSON.stringify(collegeSetting) : null,
                                    collegesize: collegeSize.length > 0 ? JSON.stringify(collegeSize) : null,
                                    createdby: userId,
                                    isallstate: 0,
                                    levelid: level.length > 0 ? JSON.stringify(level) : null,
                                    postgradschoolinterest: postgradschoolinterest.length > 0 ? JSON.stringify(postgradschoolinterest) : null,
                                    regionid: regionList.length > 0 ? JSON.stringify(regionList) : null,
                                    stateid: stateList.length > 0 ? getStateId(stateList) : null,
                                    tutioncost: tutionCost.length > 0 ? JSON.stringify(tutionCost) : null,
                                    updatedby: userId,
                                    userid: userId,
                                    id: filteredData?.id,
                                    areaofstudy: areaOfStudy.length > 0 ? JSON.stringify(areaOfStudy) : null,
                                    athleticselectivityid: subDivisionLevel?.length > 0 ? JSON.stringify(subDivisionLevel) : null
                                }
                            await saveCollegeSetting(
                                // finalArrayOfObjetct
                                userObject
                            )
                        }
                        setFilterData(filteredData)
                        setIsReset(false)
                    } else {
                        props.history.push("/")
                    }
                // }
            }

        } catch (error) {
            console.log(error)
        }
    }

    const searchAreaOfStudy = (search) => {
        if (timeout) clearTimeout(timeout);
        const timeoutT = setTimeout(() => {
          const newAreaOfStudy = props.areOfStudyList.data
            ? props.areOfStudyList.data.filter((item) => {
                if (
                  item.areaofstudyname?.toLowerCase().includes(search.toLowerCase())
                ) {
                  return true;
                }
                return false;
              })
            : [];
          setAreaOfStudyList([...newAreaOfStudy]);
        }, 300);
        settimeout(timeoutT);
    }

    const handleExpandCollapse = (e, id) => {
        // e.preventDefault();
        const expandFieldsT = expandFields;
        expandFieldsT[id] = !expandFieldsT[id];
        setExpandFields({ ...expandFieldsT });
      };

    const handleCheckDivisionBox = (e, type, label, item)=>{
        setIsChangeState(true)
        setIsMatchView(true)
        if(e.target.checked){
            let newValue = levelItemArray?.map(newValue => {
                if(parseFloat(newValue.id) === parseFloat(item.id)){
                    return {...newValue, selected: [...newValue.selected,label]}
                }
                return newValue;
            })
            setSubLevelItemArray([...newValue]);
            let data = subDivisionLevel?.map(newValue => {
                if(parseFloat(newValue.label) === parseFloat(item.id)){
                    return {...newValue, subLevel: [...newValue.subLevel,label]}
                }
                return newValue;
            })
            setSubDivisionLevel([...data]);
        }
        else{
            let newValue = levelItemArray?.map(newValue => {
                if(parseFloat(newValue.id) === parseFloat(item.id)){
                    return {...newValue, selected: newValue.selected.filter(newlabel=> newlabel != label)}
                }
                return newValue;
            })
            setSubLevelItemArray([...newValue]);
            let data = subDivisionLevel?.map(newValue => {
                if(parseFloat(newValue.label) === parseFloat(item.id)){
                    return {...newValue, subLevel: newValue.subLevel.filter(newlabel=> newlabel != label)}
                }
                return newValue;
            })
            setSubDivisionLevel([...data]);
        }
    }
    
    //Earlier there is disabled functionality but now it will check if there is any non-saved values
    //Then it will show alert before redirecting to save the data.
    const handleViewMatchingSchool = () => {
        if(isMatchView) {
            swal({
               text: "Please save your preference to view matching schools",
               icon: 'warning',
           }).then(willSave=>{
           });
       } else {
           const data = Object?.keys(filterData)?.length > 0 ? JSON.stringify(filterData) : ""
           history.push({pathname: "/colleges/search", state: data})
       }
    }

    const fakeInput = useRef(null);
    //Below Code is use to Scroll the page on top
    useEffect(()=>{
        setTimeout(()=>{
            window.scroll(0,0)
        },1000)
    },[])

    const handleReset = async () =>{
       if(isReset && !isChangeState){
            swal({
                text: "You have no saved preferences to reset.",
                icon: 'warning',
            })
       } 
       else{
            const res = await swal({
                text: "You want to reset your prefereces?",
                icon: 'warning',
                buttons: {
                    confirm: { text: "Yes" },
                    cancel: "Cancel",
                },
            })
            if (res){
                var userObject = {
                    acadamicselectivityid: null,
                    collegesetting: null,
                    collegesize: null,
                    createdby: getUserInfo()?.id,
                    isallstate: 0,
                    levelid: null,
                    postgradschoolinterest: null,
                    regionid: null,
                    stateid: null,
                    tutioncost: null,
                    updatedby: getUserInfo()?.id,
                    userid: getUserInfo()?.id,
                    id: filterData?.id ?? 0,
                    areaofstudy: null,
                    athleticselectivityid: null
                }
                const resClg = await fetchGraphMethod(saveCollegePreferenceSettingQuery,{obj : userObject},true)
                if (resClg?.status == 200){
                    toastr.success("Preferences deleted successfully", "", {
                        progressBar : true,timeOut: 2000,
                        closeButton: true, positionClass: "toast-top-full-width",
                        showEasing: "swing", fadeIn: 40000,
                      })
                    allCollegePreferenceSettings(getUserInfo()?.id ?? 0)
                    setAllState(false)
                    setSelectAfterReset(true)
                    setIsChangeState(false)
                    setIsMatchView(true)
                    setStateSelected([])
                    setStateList([])
                }else {
                    console.log("allCollegePreferenceSettings error")
                }
            }
       }
    }
    return (
        <div>
            <Prompt
                when={isChangeState}
                message={handleBlockedRoute}
            ></Prompt>
            <div className="preference-data-sec">
                <div className="container">
                    <div className="headerFlex">
                        <CollegesTabs active="preferences" />
                    </div>
                    <div className="preference-box-wrap">
                        <div className="top-block-wrap">
                            <h1 className="title">College Preference</h1>
                            <p style={{marginTop:"15px"}}>Set your college preference to help our college matching system improve your college <br />recommendations.</p>
                            <div className="btn-wrap">
                                <button className="btn blue" type={'button'}
                                        disabled={!isChangeState ? true : false} onClick={handleSaveData}>Save</button>
                                <button className="btn green" type={'button'}
                                        onClick={handleViewMatchingSchool}>View Matching Schools</button>
                                <button className="btn" type={'button'}
                                        //disabled={isChangeState ? true : false}
                                        onClick={handleReset}>Reset</button>        
                            </div>
                        </div>
                        <div className="preference-list">
                            <div className="field-box-item">
                                <div className="fieldBox">
                                    <h4>Academic Selectivity</h4>
                                    <ul className="checkbox-group">
                                        {
                                            props.academicSelectivity.data ? props.academicSelectivity.data.map(
                                                (ele, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <span className="checkbox"><input
                                                                id={`academicSelectivity${index}`}
                                                                checked={academicSelectivity.includes(ele.id)}
                                                                onChange={(e) => handleCheckBox(e, "academicSelectivity", ele.id, null)}
                                                                type="checkbox" /><label>{ele.name}</label></span>
                                                        </li>
                                                    )
                                                }
                                            )
                                                : "No Data Found.."}
                                    </ul>
                                </div>
                            </div>
                            <div className="field-box-item">
                                <div className="fieldBox">
                                    <h4>Athletic Selectively</h4>
                                    <ul className="checkbox-group">
                                        {
                                           levelItemArray?.length > 0 ? levelItemArray.map(
                                                (ele, index) => {
                                                    return (
                                                        <li key={index}>
                                                             <span className="checkbox" style={{margin:"2px"}}>
                                                            <input
                                                                type="checkbox"
                                                                id={`level${index}`}
                                                                checked={level.includes(ele.id)}
                                                                onChange={(e) => handleCheckBox(e, "level", ele.id, null)}
                                                                />
                                                                <label>{ele.collegedivisionname}</label>
                                                                </span>
                                                            <div className={`level ${
                                                                 expandFields[ele.id] ? "" : "collapsed"
                                                                     }`}
                                                                     onClick={(e) => {
                                                                        handleExpandCollapse(e, ele.id);
                                                                        }}
                                                            >
                                                                {/*<label>{ele.collegedivisionname}</label>*/}
                                                                <span className="icon">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                        version="1.1"
                                                                        id="Capa_1"
                                                                        x="0px"
                                                                        y="0px"
                                                                        width="451.847px"
                                                                        height="451.847px"
                                                                        viewBox="0 0 451.847 451.847"
                                                                        space="preserve"
                                                                    >
                                                                        <g>
                                                                        <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                                                                        </g>
                                                                    </svg>
                                                                    </span>
                                                                    </div>
                                                                    <div
                                                                        className={expandFields[ele.id] ? "show" : "collapse"}
                                                                        id="Level"
                                                                    >
                                                                        <div className="card card-body">
                                                                        <ul className="checkbox-group">
                                                                            {ele?.subLevelArray?.length > 0 ? ele?.subLevelArray?.map((subItem, key) => {
                                                                             return (
                                                                                <li key={key} style={level.includes(ele.id) ? {} : {opacity:"0.6"}}>
                                                                                <span class="checkbox">
                                                                                    <input
                                                                                    style={{margin:"2px"}}
                                                                                    type="checkbox"
                                                                                    id={`subDivisionLevel${key}`}
                                                                                    onClick={(e) =>
                                                                                        handleCheckDivisionBox(e, "subDivisionLevel", subItem?.id, ele) 
                                                                                    }
                                                                                    disabled={level.includes(ele.id) ? false : true}
                                                                                    checked={ele.id == subItem.parent && ele?.selected && ele.selected.includes(subItem?.id)}
                                                                                    />
                                                                                    <label>{subItem?.name}</label>
                                                                                </span>
                                                                                </li>
                                                                            );
                                                                            }) : "Loading.." }
                                                                        </ul>
                                                                        </div>
                                                                    </div>
                                                        </li>
                                                    )
                                                }
                                            )
                                               : "Loading.."}
                                    </ul>
                                </div>
                            </div>
                            <div className="field-box-item">
                                <div className="fieldBox">
                                <h4>Location</h4>
                                    <div className="fieldBox" style={{width:"30%"}}>
                                        {/*<input className="inputfield" type="text" value={stateSelected ? stateSelected : ""}/>*/}
                                        <p>
                                            {
                                            stateSelected?.length > 0 ? stateSelected?.length == 161 ? "All States selected"
                                            : !moreStates && stateSelected?.length > 5 ? <span>{stateSelected.join(" , ")?.slice(0,40)}<span className="more-text" onClick={e=>handleIsMore(e)}>...More</span></span>
                                                :   stateSelected.join(" , ") : stateSelected.join(" , ") 
                                            }
                                        </p>

                                    </div>
                                    <div className="search-box-wrap">
                                        <input type="text"
                                            onChange={handleSearch}
                                            placeholder="Search by State / Region" />
                                    </div>
                                </div>
                                <div className="fieldBox">
                                    <ul className="checkbox-group">
                                        <li>
                                            {/*earlier there is only allState var but now as per QA stateSelected var is added*/}
                                            <span className="checkbox"><input
                                                checked={stateSelected?.length == 161 ? true :  allState}
                                                value={searchText}
                                                onChange={(e) => handleCheckBox(e, "allState", null, null)}
                                                type="checkbox" /><label>All State</label></span>
                                        </li>
                                    </ul>
                                </div>
                                <div style={allState ? { "pointerEvents": "none", "opacity": "0.7" } : {}} className="fieldBox">
                                    <h5>Region</h5>
                                    <ul style={{ maxHeight: "300px", overflow: "auto" }} className="checkbox-group">
                                        {
                                            props.allRegionList.data ? props.allRegionList.data.filter(ele => ele.regionname.toLowerCase().startsWith(searchText)).map(
                                                ele => {
                                                    let newArray = [];
                                                    let stateInitialLetter = ele?.stateregionidMap?.States?.length > 0 ? ele?.stateregionidMap?.States?.map(initail=>{
                                                        newArray.push(initail.initials)
                                                    }) : ""
                                                    return (
                                                        <li>
                                                            <span className="checkbox"><input
                                                                checked={regionList.includes(ele.id)}
                                                                onChange={(e) => handleCheckBox(e, "regionList", ele.id, null)}
                                                                type="checkbox" /><label>{ele.regionname}<span className="initialData">{newArray?.length > 0 ? ` (${newArray.join(', ')})` : ""}</span></label></span>
                                                        </li>
                                                    )
                                                }
                                            )
                                                : "Loading.."}
                                    </ul>
                                </div>
                                <div className="fieldBox">
                                    <h5>State / Provinces</h5>
                                    <ul style={{ maxHeight: "300px", overflow: "auto" }} className="checkbox-group">
                                        {
                                            props.allStateList.data ? props.allStateList.data.filter(ele => ele.statename.toLowerCase().startsWith(searchText)).map(
                                                ele => {
                                                    return (
                                                        <li>
                                                            <span className="checkbox"><input
                                                                checked={allState ? allState : stateList.includes(ele.id)}
                                                                onChange={(e) => handleCheckBox(e, "stateList", ele.id, null)}
                                                                type="checkbox" /><label>{ele.statename}</label></span>
                                                        </li>
                                                    )
                                                }
                                            )
                                                : "Loading.."}
                                    </ul>
                                </div>
                            </div>
                            <div className="field-box-item">
                                <div className="fieldBox">
                                    <h4>College Setting</h4>
                                    <ul className="checkbox-group">
                                        {
                                            props.CollegeSettingsList.data ? props.CollegeSettingsList.data.map(
                                                ele => {
                                                    return (
                                                        <li>
                                                            <span className="checkbox"><input
                                                                checked={collegeSetting.includes(ele.id)}
                                                                onChange={(e) => handleCheckBox(e, "collegeSetting", ele.id, null)}
                                                                type="checkbox" /><label>{ele.name}</label></span>
                                                        </li>
                                                    )
                                                }
                                            )
                                                : "Loading.."}
                                    </ul>
                                </div>
                            </div>
                            <div className="field-box-item">
                                <div className="fieldBox">
                                    <h4>College Size</h4>
                                    <ul className="checkbox-group">
                                        {
                                            props.collegeSizeList.data ? props.collegeSizeList.data.map(
                                                ele => {
                                                    return (
                                                        <li>
                                                            <span className="checkbox"><input
                                                                checked={collegeSize.includes(ele.id)}
                                                                onChange={(e) => handleCheckBox(e, "collegeSize", ele.id, ele)}
                                                                type="checkbox" /><label>{ele.name}</label></span>
                                                        </li>
                                                    )
                                                }
                                            )
                                                : "Loading.."}
                                    </ul>
                                </div>
                            </div>
                            <div className="field-box-item">
                                <div className="fieldBox">
                                    <h4>Tution Cost</h4>
                                    <ul className="checkbox-group">
                                        {
                                            props.tutionCostsList.data ? props.tutionCostsList.data.map(
                                                ele => {
                                                    return (
                                                        <li>
                                                            <span className="checkbox"><input
                                                                checked={tutionCost.includes(ele.id)}
                                                                onChange={(e) => handleCheckBox(e, "tutionCost", ele.id, null)}
                                                                type="checkbox" /><label>{ele.name}</label></span>
                                                        </li>
                                                    )
                                                }
                                            )
                                                : "Loading.."}
                                    </ul>
                                </div>
                            </div>
                            <div className="field-box-item">
                                <div className="fieldBox">
                                <h4>
                                    {/*Area of Study & Majors*/}
                                    {/*change done as of oct task jira 190*/}
                                    College majors
                                </h4>
                                <div className="search-box-wrap">
                                    <input
                                    type="text"
                                    placeholder="Search by Majors"
                                    name="study"
                                    onChange={(e) => searchAreaOfStudy(e.target.value)}
                                    />
                                    {/* <div className="searchBtn">
                                        <button>
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13.811"
                                            height="13.811"
                                            viewBox="0 0 13.811 13.811"
                                            >
                                            <g className="a" transform="translate(-3.75 -3.75)">
                                                <path
                                                className="b"
                                                d="M15.167,9.833A5.333,5.333,0,1,1,9.833,4.5,5.333,5.333,0,0,1,15.167,9.833Z"
                                                />
                                                <path
                                                className="b"
                                                d="M27.875,27.875l-2.9-2.9"
                                                transform="translate(-11.375 -11.375)"
                                                />
                                            </g>
                                            </svg>
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="fieldBox">
                            <ul className="checkbox-group study" style={{ maxHeight: "200px", overflow: "auto" }}>
                                    {areaOfStudyList?.length > 0 ?
                                    areaOfStudyList.map((item, index) => {
                                        return (
                                        <li key={index}>
                                            <span className="checkbox">
                                            <input
                                                type="checkbox"
                                                onChange={(e) =>
                                                    handleCheckBox(e, "areaOfStudy", item.id, null)
                                                }
                                                checked={
                                                    areaOfStudy.includes(item.id)
                                                    ? true
                                                    : false
                                                }
                                            />
                                            <label>{item.areaofstudyname}</label>
                                            </span>
                                        </li>
                                        );
                                    }) : "No Data Found.."}
                                </ul>
                            </div>
                                {/* <div className="fieldBox">
                                    <h4>Post Grad School Interest (Optional)</h4>
                                    <ul className="checkbox-group">

                                        <li>
                                            <span className="checkbox"><input
                                                checked={postgradschoolinterest.includes('Yes')}
                                                onChange={(e) => handleCheckBox(e, "postgradschoolinterest", 'Yes')}
                                                type="checkbox" /><label>Yes</label></span>
                                        </li>
                                        <li>
                                            <span className="checkbox"><input
                                                checked={postgradschoolinterest.includes('No')}
                                                onChange={(e) => handleCheckBox(e, "postgradschoolinterest", 'No')}
                                                type="checkbox" /><label>No</label></span>
                                        </li>
                                        <li>
                                            <span className="checkbox"><input
                                                checked={postgradschoolinterest.includes('Maybe')}
                                                onChange={(e) => handleCheckBox(e, "postgradschoolinterest", 'Maybe')}
                                                type="checkbox" /><label>Maybe</label></span>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="btn-wrap">
                                    <button onClick={handleSaveData} disabled={!isChangeState ? true : false}
                                            className="btn blue">Save</button>
                                    <button className="btn green" onClick={handleViewMatchingSchool}>View Matching Schools</button>
                                    <button onClick={handleReset}
                                            // disabled={!isChangeState ? true : false}
                                            className="btn">Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        academicSelectivity: state.athlete.dashboard.colleges.academicSelectivityList,
        CollegeSettingsList: state.athlete.dashboard.colleges.collegeSettingsList,
        areOfStudyList: state.athlete.dashboard.colleges.areOfStudyList,
        collegeSizeList: state.athlete.dashboard.colleges.collegeSizeList,
        tutionCostsList: state.athlete.dashboard.colleges.tutionCostsList,
        collegedivisionsList: state.athlete.dashboard.colleges.collegedivisionsList,
        allRegionList: state.athlete.dashboard.colleges.allRegionList,
        allStateList: state.athlete.dashboard.colleges.allStateList,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        areaOfStudyListStart: (data) => dispatch(areaOfStudyListStart(data)),
        academicSelectivityListStart: (data) => dispatch(academicSelectivityListStart(data)),
        CollegeSettingsListStart: (data) => dispatch(CollegeSettingsListStart(data)),
        collegeSizeListStart: (data) => dispatch(collegeSizeListStart(data)),
        TutionCostsListStart: (data) => dispatch(TutionCostsListStart(data)),
        CollegedivisionsListStart: (data) => dispatch(CollegedivisionsListStart(data)),
        AllRegionListStart: (data) => dispatch(AllRegionListStart(data)),
        AllStateListStart: (data) => dispatch(AllStateListStart(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollegesPreferences);
