import React from "react";
import Link from "@mui/material/Link";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardCongratAthlete.scss";
import "../assets/css/onBoardSteeper.scss";
import medal from "../assets/img/medal.png";
import axios from "axios";
import { saveUserDataQuery } from "../OnboardingQuery";
import { getUserInfo, setUserInfo } from "../../../../../services/authService";
import { GRAPHQL_URL } from "../../../../../config";

function CongratulateAthelete(props) {
  const moveToWelcomeScreen = async () => {
    if(getUserInfo()?.isonboardingdone == 0 && getUserInfo().role === "ATHLETES"){
      let query = {
        query: saveUserDataQuery,
        variables: {
          obj: {
            "id": parseFloat(getUserInfo()?.athleteLoggedInId),
            "isonboardingdone": parseFloat(1),
            "athleteinvitestatus" : 3 //this is a bugs as key is not correct chnage done on 29/9/2022
          },
        },
      }
      let res = await axios.post(GRAPHQL_URL, query, {
        headers: {
            access_token: localStorage.getItem("authToken"),
        },
    });
    if (res && res?.status == 200) {
      let data = getUserInfo();
      data.isonboardingdone = 1;
      setUserInfo(data)
      props.history.push("/welcomeScreen")
    }
    }
    else{
      props.history.push("/welcomeScreen")
    }
  }
  return (
    <div>
      <div className="onBoardCongratAthleteSection">
        <div className="congratAthleteBlock">
          <div className="medalImg">
            <img src={medal} alt="medalImg" />
          </div>
          <div className="data">
            <h1 className="heading">
              Great Job {getUserInfo() ? getUserInfo().FirstName : ""}!
            </h1>
            <p className="info">
              Now it's time to join other athletes like you and take your game
              to the next level!
            </p>
            <div className="btnBox">
              <Link to="#" onClick={() => moveToWelcomeScreen()}>
                Let's Go!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CongratulateAthelete;
