import { put, takeEvery } from "redux-saga/effects";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ALL_STATMATRICDETAILS_LIST_START: "ALL_STATMATRICDETAILS_LIST_START",
  ALL_STATMATRICDETAILS_LIST_SAVE: "ALL_STATMATRICDETAILS_LIST_SAVE",
  ALL_STATMATRICDETAILS_LIST_SUCCESS: "ALL_STATMATRICDETAILS_LIST_SUCCESS",
  ALL_STATMATRICDETAILS_LIST_FAILED: "ALL_STATMATRICDETAILS_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const allStatMatricDetailsListStart = (data) => ({
  type: actionType.ALL_STATMATRICDETAILS_LIST_START,
  payload: data,
});
export const allStatMatricDetailsListSave = (data) => ({
  type: actionType.ALL_STATMATRICDETAILS_LIST_SAVE,
  payload: data,
});

export const allStatMatricDetailsListSuccess = (data) => ({
  type: actionType.ALL_STATMATRICDETAILS_LIST_SUCCESS,
  payload: data,
});

export const allStatMatricDetailsListFailed = (data) => ({
  type: actionType.ALL_STATMATRICDETAILS_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function allStatMatricDetailsListReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.ALL_STATMATRICDETAILS_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_STATMATRICDETAILS_LIST_SAVE:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_STATMATRICDETAILS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ALL_STATMATRICDETAILS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* allStatMatricDetailsListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allStatMatricDetailsListSuccess({
          allStatMetricsDetails:
            response.data.data.allStatmatrixdetails.Statmatrixdetails,
        })
      );
    } else {
      messagePopup("", "Stat Metrics List failed", "error");
      yield put(allStatMatricDetailsListFailed("Stat Metrics List failed"));
    }
  } catch (error) {
    messagePopup("", "Stat Metrics List failed", "error");
    yield put(allStatMatricDetailsListFailed("Stat Metrics List failed"));
  }
}
export function* allStatMatricDetailsListSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        allStatMatricDetailsListSuccess({
          saveStatMetricsDetails: response.data.data,
        })
      );
      if (action.payload.successSavedMsg) action.payload.successSavedMsg();
      if (action.payload.successUpdatedMsg) action.payload.successUpdatedMsg();
      if (action.payload.videoUploadedMsg) action.payload.videoUploadedMsg();
      if (action.payload.successMsg) action.payload.successMsg();
      if (action.payload.successDeletedMsg) action.payload.successDeletedMsg();
      if (action.payload.afterDeleteMetrics) action.payload.afterDeleteMetrics();

    } else {
      messagePopup("", "Stat Metrics Save failed", "error");
      yield put(allStatMatricDetailsListFailed("Stat Metrics Save failed"));
    }
  } catch (error) {
    messagePopup("", "Stat Metrics Save failed", "error");
    yield put(allStatMatricDetailsListFailed("Stat Metrics Save failed"));
  }
}
//Watcher
export function* watchAllStatMatricDetailsListSaga() {
  yield takeEvery(
    actionType.ALL_STATMATRICDETAILS_LIST_START,
    allStatMatricDetailsListSaga
  );

  yield takeEvery(
    actionType.ALL_STATMATRICDETAILS_LIST_SAVE,
    allStatMatricDetailsListSaveSaga
  );
}
