import thumbnail from '../../assets/img/video-to-link-thumbnail.jpg'
import hudlThumbnail from '../../assets/img/hudl-video-thumb.jpg'
import axios from 'axios'
export const getThumbnail = (vidURL, data, page) =>{
    if(vidURL?.includes('youtube')){
        const dataSplit = vidURL?.split('/'),
            url = dataSplit[dataSplit?.length - 1]
        return `https://img.youtube.com/vi/${url}/mqdefault.jpg`
    }
    else if (vidURL?.includes('vimeo')){
        if (page == "Vid/Photo" ||
            page == "ProfileView" ||
            page == "Profile" ||
            page == "ProfileVP" ||
            page == "ProfileABT"
        ){
            if(!!data?.thumbnail){
                return  data?.thumbnail
            }else{
                return thumbnail
            }
        }
        else if (page == "Save/Update_StatsVP"){
            if (data?.vidThumbnail){
              return data?.vidThumbnail
            }else {
                return thumbnail
            }
        }
        else if (page == "Show_Stats_Profile" || page == "Show_Stats_PR"){
            if (data?.thumbnail){
                return data?.thumbnail
            }else {
                return thumbnail
            }
        }
        else if(page == "Dashboard/MyFeed" || page == "Dashboard/Global" || page == "Timeline"){
            if (data?.thumbnail){
                return  data?.thumbnail
            }else{
                return thumbnail
            }
        }
        return thumbnail
    }
    else if (vidURL?.includes('hudl')){
        return hudlThumbnail
    }else {
        return thumbnail
    }
}

export const getVimeoThumb = async (id) =>{
    if (id){
        try {
            const response = await axios.get(`https://vimeo.com/api/v2/video/${id}.json`);
            if (response?.status == 200){
                return response?.data?.[0]?.thumbnail_medium
            }
        }
        catch (error) {
            // console.log(error);
        }
    }else{
        return thumbnail
    }
}

export const typeConvert = (data, page) =>{
    var repStr = ""
    if (page == "video/photo" || page == "Timeline" || page == "vidPop" || page == "Show_Stats_PR" ||
        page == "Show_Stats_Profile" || page == "Dashboard/MyFeed" || page == "Dashboard/Global" || page == "Timeline" ||
        page == "Athlete_Info" || page == "Athlete_About" || page == "Dashboard/MyFeed" || page == "Dashboard/MyFeed" ||
        page == "v/p_download"
    ){
        const dataLower = data?.toLowerCase()
        if (dataLower?.includes('3g2') || dataLower?.includes('3gp') || dataLower?.includes('avi') ||
         dataLower?.includes('flv') || dataLower?.includes('m4v') || dataLower?.includes('mkv') ||
           dataLower?.includes('mov') || dataLower?.includes('mp4') || dataLower?.includes('mpg') ||
         dataLower?.includes('mts') || dataLower?.includes('m2ts') || dataLower?.includes('mpeg') ||
            dataLower?.includes('wmv')){
            const splitData = data?.split("."),
              videoExt = splitData?.[splitData?.length - 1]
               repStr = data?.replace(videoExt,'mp4')
        }else{
         repStr = data
        }
    }else{
        repStr = data
    }
    return repStr
}

export const vidURL = (data) =>{
    if(data?.includes('youtu.be')){
        const dataSplit = data?.split('/'),
            url = dataSplit[dataSplit?.length - 1]
        return `https://youtube.com/embed/${url}`
    }
    else if(data?.includes('youtube')){
        const dataSplit = data?.split('='),
            url = dataSplit[dataSplit?.length - 1]
        return `https://youtube.com/embed/${url}`
    }
    else if (data?.includes('vimeo')){
        const dataSplit = data?.split('/'),
            url = dataSplit[dataSplit?.length - 1]
        return `https://player.vimeo.com/video/${url}`
    }
    else if (data?.includes('hudl')){
        const dataSplit = data?.split('video'),
            url = dataSplit[dataSplit?.length - 1]
        return `https://www.hudl.com/embed/video${url}`
    }
    else{
        return data
    }
}