import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ASK_A_QUESTION_LIST_START: "ASK_A_QUESTION_LIST_START",
  ASK_A_QUESTION_SAVE_START: "ASK_A_QUESTION_SAVE_START",
  ASK_A_QUESTION_SUCCESS: "ASK_A_QUESTION_SUCCESS",
  ASK_A_QUESTION_FAILED: "ASK_A_QUESTION_FAILED",
};

// ============================================ Actions ========================================================
export const askAQuestionListStart = (data) => ({
  type: actionType.ASK_A_QUESTION_LIST_START,
  payload: data,
});

export const askAQuestionSaveStart = (data) => ({
  type: actionType.ASK_A_QUESTION_SAVE_START,
  payload: data,
});

export const askAQuestionSuccess = (data) => ({
  type: actionType.ASK_A_QUESTION_SUCCESS,
  payload: data,
});

export const askAQuestionFailed = (data) => ({
  type: actionType.ASK_A_QUESTION_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function askAQuestionReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.ASK_A_QUESTION_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ASK_A_QUESTION_SAVE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ASK_A_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ASK_A_QUESTION_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* askAQuestionSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      if(action.payload.successMessage() ){
        action.payload.successMessage()
      }
      yield put(
        askAQuestionSuccess({})
      );
    } else {
      messagePopup("", "ASK A QUESTION List failed", "error");
      yield put(askAQuestionFailed("ASK A QUESTION List failed"));
    }
  } catch (error) {
    messagePopup("", "ASK A QUESTION List failed", "error");
    yield put(askAQuestionFailed("ASK A QUESTION List failed"));
  }
}

export function* askAQuestionListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      let askAQuestion = response;
      yield put(askAQuestionSuccess({ allAskedQuestions: askAQuestion }));
    } else {
      messagePopup("", "ASK A QUESTION List failed", "error");
      yield put(askAQuestionFailed("ASK A QUESTION List failed"));
    }
  } catch (error) {
    messagePopup("", "ASK A QUESTION List failed", "error");
    yield put(askAQuestionFailed("ASK A QUESTION List failed"));
  }
}

export function* watchAskAQuestionSaga() {
  yield takeEvery(actionType.ASK_A_QUESTION_LIST_START, askAQuestionListSaga);
  yield takeEvery(actionType.ASK_A_QUESTION_SAVE_START, askAQuestionSaveSaga);
}
