const SortBy = {
  RANK: 4,
  CATEGORY: 6,
  LAST_UPDATED: 7,
  INTEREST_DESC: 8,
  INTEREST_ASC: 9,
};

const CollegeProgressSteps = {
  TARGET: 'Target',
  CONTACT: 'Contact',
  INTEREST: 'Interest',
  ACTIVE: 'Active',
  OFFER: 'Offer',
  COMMIT: 'Commit',
  SIGNED: 'Signed',
};

const PlatformImg = {
  GameChanger: 'gamechanger_logo.jpeg',
  MaxPreps: 'maxpreps_logo.png',
  Hudl: 'hudl_logo.jpeg',
  PerfectGame: 'perfectgame_logo.jpeg',
  PrepBaseballReport: 'prepbaseballreport_logo.jpeg',
  ProspectWire: 'prospectwire_logo.jpeg',
  FiveTool: 'fivetool_logo.jpeg',
  ProspectSelect: 'prospectselect_logo.webp',
  College: 'otherplatform_logo.png',
  Other: 'otherplatform_logo.png',
};

module.exports = { SortBy, CollegeProgressSteps, PlatformImg };
