import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    ORGANIZATIONS_LIST_START: "ORGANIZATIONS_LIST_START",
    ORGANIZATIONS_SAVE_START: "ORGANIZATIONS_SAVE_START",   
    ORGANIZATIONS_LIST_SUCCESS: "ORGANIZATIONS_LIST_SUCCESS",
    ORGANIZATIONS_LIST_FAILED: "ORGANIZATIONS_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const organizationsListStart = (data) => ({
    type: actionType.ORGANIZATIONS_LIST_START,
    payload: data,
});

export const organizationsSaveStart = (data) => ({
    type: actionType.ORGANIZATIONS_SAVE_START,
    payload: data
})

export const organizationsListSuccess = (data) => ({
    type: actionType.ORGANIZATIONS_LIST_SUCCESS,
    payload: data,
});

export const organizationsListFailed = (data) => ({
    type: actionType.ORGANIZATIONS_LIST_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function organizationsListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.ORGANIZATIONS_LIST_START:
            return {
                ...state,
                loading: true,
            };
            case actionType.ORGANIZATIONS_SAVE_START:
                return {
                    ...state,
                    loading: true,
                };
        case actionType.ORGANIZATIONS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.ORGANIZATIONS_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* organizationsListSaga(action) {
    try {
        // const response = yield fetchGraphMethod(action.payload.query, null, true);
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            const allORGANIZATIONS_LEVEL =  response.data
            yield put(organizationsListSuccess(allORGANIZATIONS_LEVEL));
        } else {
            messagePopup("", "Organizations List failed", "error");
            yield put(organizationsListFailed("Organizations List failed"));
        }
    } catch (error) {
        messagePopup("", "Organizations List failed", "error");
        yield put(organizationsListFailed("Organizations List failed"));
    }
}

export function* organizationSaveSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            if (action.payload.nextScreen == null) {
                yield put(organizationsListSuccess(''));
            } else {
                action.payload.nextScreen()
            }
        } else {
            messagePopup('', 'ORGANIZATION List failed', 'error')
            yield put(organizationsListFailed('ORGANIZATION List failed'))
        }
    } catch (error) {
        messagePopup('', 'ORGANIZATION List failed', 'error')
        yield put(organizationsListFailed('ORGANIZATION List failed'))
    }
    
}

export function* watchOrganizationsListSaga() {
    yield takeEvery(actionType.ORGANIZATIONS_LIST_START, organizationsListSaga);
    yield takeEvery(actionType.ORGANIZATIONS_SAVE_START, organizationSaveSaga);
}
