import React, { useEffect, useState} from 'react'
import ClaimProfileHeader from "../auth/claimProfile/claimProfileStep1/ClaimProfileHeader";
import DashboardHeader from "../../layouts/header/DashboardHeader";
import {getAuthToken} from "../../../services/authService";
import NAIAIMG from './assets/img/naia-logo.png'
import NCAAIMG from './assets/img/ncaa-logo.png'
import NJCAAIMG from './assets/img/njcaa-logo.png'
import CCCAA from './assets/img/california-community-college-athletic-association-logo.png'
import FTRF from './assets/img/find-the-right-fit-img.png'
import KWI from './assets/img/know-who-interested-img.png'
import DDPM from './assets/img/data-driven-profile-mockup-img.png'
import './assets/css/lading-page.scss'
import {imageDownloadUrl} from "../../../config";

const CollegeLP = (props) =>{
    const [headerType, setHeaderType] = useState(true),
        isToken = props?.match?.params?.isToken == 1 ? true : false;
    useEffect(()=>{
        getStatus(props)
        //for scrolling the page on top
        setTimeout(()=>{
            window.scrollTo(0,0)
        },1000)
    },[])
    const getStatus = (props) =>{
        if (!!getAuthToken() || isToken){
            setHeaderType(false)
        }
    }
    return(
        <>
            {
                headerType ?
                    <ClaimProfileHeader/>
                    :
                    <DashboardHeader/>
            }
                <section className={"banner-lp-sec"} style={{backgroundImage:`url(${imageDownloadUrl}/clgBanner.png)`}}>
                <div className="container">
                    <div className="college-banner-content">
                        <div className="tagline">Looking for</div>
                        <h1 className="title">Your Next Recruit ?</h1>
                        <div className="short-text">
                            <p>Enter your .edu email address below to get started.</p>
                            <div className="field-box">
                                <input type="text" placeholder="Email here"/>
                            </div>
                        </div>
                        <div className="button-wrap">
                            <a className="btn" href="#">Join Waiting List</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="athlete-common-sec">
                <div className="container">
                    <div className="top-block">
                        <h2 className="title">Tools to help you build your team</h2>
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                    <div className="common-listing">
                        <div className="item-row">
                            <div className="content-box">
                                <div className="top-block">
                                    <div className="tagline">STUDENT-ATHLETE PROFILES</div>
                                    <h3 className="title">See the full picture</h3>
                                </div>
                                <div className="detail-text">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown</p>
                                    <ul>
                                        <li>Athlete profiles include everything from athletic, academic and contact info
                                            to game film and highlight reels
                                        </li>
                                        <li>Stay up to date with your prospects' latest information</li>
                                    </ul>
                                    <div className="button-wrap">
                                        <button className="btn blue">Join Waiting List</button>
                                    </div>
                                </div>
                            </div>
                            <div className="img-box">
                                <img src={DDPM} alt=""/>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="content-box">
                                <div className="top-block">
                                    <div className="tagline">ACTIVITY FEED + NOTIFICATIONS</div>
                                    <h3 className="title">Total transparency</h3>
                                </div>
                                <div className="detail-text">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type
                                        specimen book.</p>
                                    <ul>
                                        <li>Neque porro quisquam est qui dolorem ipsum quia dolor sit</li>
                                        <li>Neque porro quisquam est qui dolorem ipsum</li>
                                        <li>Lorem Ipsum has been the industry's standard</li>
                                    </ul>
                                    <div className="button-wrap">
                                        <button className="btn blue">Join Waiting List</button>
                                    </div>
                                </div>
                            </div>
                            <div className="img-box">
                                <img src={KWI} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-started-sec">
                <div className="container">
                    <h3 className="sec-title">Get started for free today!</h3>
                    <div className="short-text">
                        <p>We can't wait to have you on board!</p>
                    </div>
                    <div className="button-wrap">
                        <a className="btn" href="#">Join waiting list</a>
                    </div>
                </div>
            </section>

            <section className="athlete-common-sec">
                <div className="container">
                    <div className="common-listing">
                        <div className="item-row">
                            <div className="content-box">
                                <div className="top-block">
                                    <div className="tagline">STUDENT-ATHLETE VIDEO</div>
                                    <h3 className="title">Evaluate prospects from anywhere</h3>
                                </div>
                                <div className="detail-text">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown</p>
                                    <ul>
                                        <li>Neque porro quisquam est qui dolorem ipsum quia dolor sit</li>
                                        <li>Neque porro quisquam est qui dolorem ipsum</li>
                                        <li>Lorem Ipsum has been the industry's standard</li>
                                    </ul>
                                    <div className="button-wrap">
                                        <button className="btn blue">Join Waiting List</button>
                                    </div>
                                </div>
                            </div>
                            <div className="img-box">
                                <img src={FTRF} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="brand-lp-sec">
                <div className="container">
                    <div className="brand-listing">
                        <div className="item">
                            <img src={NCAAIMG} alt=""/>
                        </div>
                        <div className="item">
                            <img src={NAIAIMG} alt=""/>
                        </div>
                        <div className="item">
                            <img src={NJCAAIMG} alt=""/>
                        </div>
                        <div className="item">
                            <img src={CCCAA} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CollegeLP
