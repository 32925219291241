import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../utils/messagePopup";
import { fetchGraphMethod } from "../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  SAVE_COACH_ONBOARDING_START: "SAVE_COACH_ONBOARDING_START",
  GET_COACH_ONBOARDING_START: "GET_COACH_ONBOARDING_START",
  ONBOARDING_COACH_SUCCESS: "ONBOARDING_COACH_SUCCESS",
  ONBOARDING_COACH_FAILURE: "ONBOARDING_COACH_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const saveOnboardingStart = (data) => ({
  type: actionType.SAVE_COACH_ONBOARDING_START,
  payload: data,
});

export const getOnboardingStart = (data) => ({
  type: actionType.GET_COACH_ONBOARDING_START,
  payload: data,
});

export const OnboardingSuccess = (data) => ({
  type: actionType.ONBOARDING_COACH_SUCCESS,
  payload: data,
});

export const OnboardingFailure = (data) => ({
  type: actionType.ONBOARDING_COACH_FAILURE,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function onboardingReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.SAVE_COACH_ONBOARDING_START:
      return { ...state, loading: false };

    case actionType.GET_COACH_ONBOARDING_START:
      return { ...state, loading: true };

    case actionType.ONBOARDING_COACH_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.ONBOARDING_COACH_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* saveOnboardingSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      if (action.payload.currentScreen === "vital") {
        let localData = JSON.parse(localStorage.getItem("user"));
        localData.primaryPosition =
          action.payload.variables.obj.primaryposition;
        localData.secondaryPosition =
          action.payload.variables.obj.secondaryposition;
        console.log(" i am calling ", localData);
        localStorage.setItem("user", JSON.stringify(localData));
      }
      action.payload.nextScreen();
    } else {
      messagePopup("", "Save Onboarding Failed", "error");
      yield put(OnboardingFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Save Onboarding Failed", "error");
    yield put(OnboardingFailure("Failed, Error occured"));
  }
}

function* getOnboardingSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200) {
      const allOnboardings = response.data.data.allOnboardings.Onboardings
        ? response.data.data.allOnboardings.Onboardings[0]
          ? response.data.data.allOnboardings.Onboardings[0]
          : null
        : null;
      yield put(OnboardingSuccess(allOnboardings));
    } else {
      messagePopup("", "Fetch Onboarding Data Failed", "error");
      yield put(OnboardingFailure("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "Fetch Onboarding Data Failed", "error");
    yield put(OnboardingFailure("Failed, Error occured"));
  }
}

export function* watchOnboarding() {
  yield takeEvery(actionType.SAVE_COACH_ONBOARDING_START, saveOnboardingSaga);
  yield takeEvery(actionType.GET_COACH_ONBOARDING_START, getOnboardingSaga);
}
