import React, { useState } from "react";
import messagePopup from "../../../../../utils/messagePopup";
import { getUserInfo } from "../../../../../services/authService";
import {fetchGraphMethod, fetchMethod} from "../../../../../services/httpService";
import { getAllOfferQuery, saveOffersQuery } from "../editUserProfile/OfferQuery";
import { getMyCommitmentQuery } from "../homepage/leftSideBar/query";
import toastr from "toastr";

const InterestLevelModal = (props) => {
  const [interestId, setInterestId] = useState(0);
  const [offerDetails, setOfferDetails] = useState([]);
  const [commitmentDetails, setCommitmentDetails] = useState([]);
  React.useEffect(async () => {
    await fetchGraphMethod(getAllOfferQuery, {obj:{
      userid: getUserInfo() ? getUserInfo().id : 0,
      active: 1,
      collegeid: parseInt(props.collegeDetails?.id)
    }}, true).then(res => { 
      if(res?.data?.data?.allOffers?.totalCount){
        setOfferDetails(res?.data?.data?.allOffers?.Offers);
        setInterestId(res?.data?.data?.allOffers?.Offers[0]['interestlevelid']);
      }
    })
    
    await fetchGraphMethod(getMyCommitmentQuery, {obj:{
      userid: getUserInfo() ? getUserInfo().id : 0,
      active: 1,
      collegeid: parseInt(props.collegeDetails?.id)
    }}, true).then(res => {
      if(res?.data?.data?.allCollegecommitments?.totalCount){
        setCommitmentDetails(res?.data?.data?.allCollegecommitments?.Collegecommitments);
      }
    })
  }, [])
  const onChangeHandler = (event) => {
    if (event.target.checked) {
      setInterestId(event.target.id);
    } else {
      setInterestId(0);
    }
  };

  const onSaveHandler = async () => {
    if(interestId == 0){
      // messagePopup("", "Please select your interest level", "warning");
      toastr.warning("Please select your interest level", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }else{
      if(offerDetails.length && parseInt(interestId) != offerDetails[0]['interestlevelid']){
        await fetchGraphMethod(saveOffersQuery, {obj:{
          id: offerDetails[0]['id'],
          interestlevelid: parseInt(interestId)
        }}, true).then(res => {})
      }
      
      await fetchMethod('/api/Usercolleges/addCollege', {
        collegeId: parseInt(props.collegeDetails?.id),
        userId: getUserInfo() ? getUserInfo().id : 0,
        interestLevel: parseInt(interestId),
        isOffered: offerDetails.length ? 1 : 0,
        isCommitment: commitmentDetails.length ? 1 : 0,
      }, "post", true).then(res => {
        // messagePopup("", "Congrats! school / college  has been listed as Favorite!", "success");
        toastr.success("Congrats! College has been Favorited / Targeted.", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
        props.onSelect(interestId);
      });
    }
  };
  const getInterestLevelList = () => {
    let itemList = [];
    props.interestValueList.map((interest) => {
      let val = interest.id == interestId ? true : false;
      itemList.push(
        <div className="item" key={interest.id}>
          <input
            type="checkbox"
            id={interest.id}
            onChange={onChangeHandler}
            checked={val}
          />
          <label htmlFor="strong">
            <span className="icon">
              {val ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.862"
                  height="16.586"
                  viewBox="0 0 17.862 16.586"
                >
                  <g id="check2-circle" transform="translate(-3.375 -3.373)">
                    <path
                      id="Path_2146"
                      data-name="Path 2146"
                      d="M23.972,5.81a.638.638,0,0,1,0,.9l-8.931,8.931a.638.638,0,0,1-.9,0L10.31,11.817a.639.639,0,0,1,.9-.9L14.59,14.29l8.479-8.48a.638.638,0,0,1,.9,0Z"
                      transform="translate(-2.922 -0.974)"
                      fill="#c0321f"
                      fillRule="evenodd"
                    />
                    <path
                      id="Path_2147"
                      data-name="Path 2147"
                      d="M11.668,4.65a7.017,7.017,0,1,0,7.017,7.017.638.638,0,1,1,1.276,0,8.293,8.293,0,1,1-4.147-7.183.638.638,0,1,1-.638,1.1A6.981,6.981,0,0,0,11.668,4.65Z"
                      transform="translate(0 0)"
                      fill="#c0321f"
                      fillRule="evenodd"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                </svg>
              )}
            </span>
            {interest.interestlevelvalue}
          </label>
        </div>
      );
    });
    return itemList;
  };

  return (
    <div
      className="modal fade interest-modal show"
      id="FavoriteInterestLavelModal"
      style={{ display: "Block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <form className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Interest Level</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body bottom-border">
            <div className="interest-lavel-list">{getInterestLevelList()}</div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn green"
              data-dismiss="modal"
              onClick={onSaveHandler}
            >
              Select
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InterestLevelModal;
