import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod,fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  STATE_LIST_START: "STATE_LIST_START",
  STATE_LIST_SUCCESS: "STATE_LIST_SUCCESS",
  STATE_LIST_FAILED: "STATE_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const stateListStart = (data) => ({
  type: actionType.STATE_LIST_START,
  payload: data,
});

export const stateListSuccess = (data) => ({
  type: actionType.STATE_LIST_SUCCESS,
  payload: data,
});

export const stateListFailed = (data) => ({
  type: actionType.STATE_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function stateListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.STATE_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.STATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.STATE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* stateListSaga(action) {
  try {
    // const response = yield fetchGraphMethod(action.payload.query, null, true);
    const response = yield fetchMethod(`/allState`,null,'get',false);
    if (response && response.status === 200) {
      let allStates = response.data.data
        ? response.data.data
        : null;
      //this check and fun cis used for sorting alphabatically state
      if(allStates?.length > 0){
        const sorted = response.data.data.sort((a,b) =>{
         return (a?.statename?.toLowerCase() < b.statename?.toLowerCase()) ? -1 : (a > b) ? 1 : 0
        })
        allStates = sorted
      }
      yield put(stateListSuccess(allStates));
    } else {
      messagePopup("", "State List failed", "error");
      yield put(stateListFailed("GPA List failed"));
    }
  } catch (error) {
    messagePopup("", "State List failed", "error");
    yield put(stateListFailed("State List failed"));
  }
}

export function* watchStateListSaga() {
  yield takeEvery(actionType.STATE_LIST_START, stateListSaga);
}
