import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";
import toastr from "toastr";

// ========================================== Action Type =====================================================
export const actionType = {
    UPDATE_PARENT_LIST_START: 'UPDATE_PARENT_LIST_START',
    UPDATE_PARENT_LIST_SUCCESS: 'UPDATE_PARENT_LIST_SUCCESS',
    UPDATE_PARENT_LIST_FAILED: 'UPDATE_PARENT_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const updateParentListStart = (data) => ({
    type: actionType.UPDATE_PARENT_LIST_START,
    payload: data
})

export const updateParentListSuccess = (data) => ({
    type: actionType.UPDATE_PARENT_LIST_SUCCESS,
    payload: data
})

export const updateParentListFailed = (data) => ({
    type: actionType.UPDATE_PARENT_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function updateParentListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.UPDATE_PARENT_LIST_START:
            return {
                ...state,
                loading: true
            }
        case actionType.UPDATE_PARENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        case actionType.UPDATE_PARENT_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        default: return { ...state }
    }
}
const getMSG = (action) =>{
   let msg = ""
    if (action.payload.isParentAdd == true && action.payload.isContactStatus != true && action.payload.notificationStatus != true){
        msg = "Invite has been sent to newly added parent / guardian and information has been saved successfully";
    }
   else if(action.payload.changeParentStaus == true){
        msg = "Parent / Guardian information has been saved successfully";
   }
   else if(action.payload.isFromManageAccess == "true"){
      msg =  "Access to operate account have been provided & information have been sent on their registered email id."
   }
   else if (action.payload.notificationStatus == true){
      msg =  "Notification Setting Updated successfully."
   }
   else if (action.payload.isContactStatus == true){
      msg =  "Contact Detail Updated Successfully"
   }
    // messagePopup('', msg, 'success')
    toastr.success(msg , "", {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: "toast-top-full-width",
        showEasing: "swing",
        fadeIn: 40000,
        preventDuplicates: true,
      });
    // return msg
}

// ===================================================== side effect =============================================

export function* updateParentListSaga(action) {
    try {
        let queryUrl = action.payload.status == "add" ? `/api/Userdata/updateAthleteAndParentData` : `/api/Userdata/removeParentAnduardian`
        const response = yield fetchMethod(
            queryUrl,
            action.payload,
            "post",
            true
        );
        if (response && response.data.response.status == 201) {
            messagePopup('', !!response?.data?.response?.msg ?
                   response?.data?.response?.msg
                : "Email Id already exist for athlete."
                , 'error')
        }
        else if (response && response.data.response.status === 200 && action.payload.status == "add") {
            // let msg = (action.payload.changeParentStaus == true &&
            // action.payload.notificationStatus == true) || action.payload.isFromManageAccess == "true" ?
            //     "Access to operate account have been provided & information have been sent on their registered email id." :
            //     (action.payload.changeParentStaus == false && action.payload.notificationStatus == true ?
            //         "Notification Setting Updated successfully.":
            //         // "Access to operate account have been provided & information have been sent on their registered email id."
            //     "Contact Detail Updated Successfully"
            //     )
            getMSG(action)
            yield put(updateParentListSuccess(response));
            if(action.payload.refreshPage) {
                action.payload.refreshPage()
         }
    
        }else if (response && response.data.response.status === 200){
            yield put(updateParentListSuccess(response));
            if(action.payload.refreshPage) {
                action.payload.refreshPage()
         }
        }
         else {
            messagePopup('', 'Update parent data failed', 'error')
            yield put(updateParentListFailed('Update parentvvvvvvv data failed'))
        }
    } catch (error) {
        messagePopup('', 'Update parent data failed', 'error')
        yield put(updateParentListFailed('Update parent data failed'))
    }
}

export function* watchupdateParentListSaga() {
    yield takeEvery(actionType.UPDATE_PARENT_LIST_START, updateParentListSaga)
}
