import { put, takeEvery } from 'redux-saga/effects';
import { fetchGraphMethod } from '../../../services/httpService';
import messagePopup from '../../../utils/messagePopup';

// ========================================== Action Type =====================================================
export const actionType = {
  STAT_LINK_START: 'STAT_LINK_START',
  STAT_LINK_SUCCESS: 'STAT_LINK_SUCCESS',
  STAT_LINK_FAILED: 'STAT_LINK_FAILED',
  CREATE_STAT_LINK_START: 'CREATE_STAT_LINK_START',
  CREATE_STAT_LINK_SUCCESS: 'CREATE_STAT_LINK_SUCCESS',
  CREATE_STAT_LINK_FAILED: 'CREATE_STAT_LINK_FAILED',
  UPDATE_STAT_LINK_START: 'UPDATE_STAT_LINK_START',
  UPDATE_STAT_LINK_SUCCESS: 'UPDATE_STAT_LINK_SUCCESS',
  UPDATE_STAT_LINK_FAILED: 'UPDATE_STAT_LINK_FAILED',
  DELETE_STAT_LINK_START: 'DELETE_STAT_LINK_START',
  DELETE_STAT_LINK_SUCCESS: 'DELETE_STAT_LINK_SUCCESS',
  DELETE_STAT_LINK_FAILED: 'DELETE_STAT_LINK_FAILED',
};

// ============================================ Actions ========================================================
export const statLinkStart = (data) => ({
  type: actionType.STAT_LINK_START,
  payload: data,
});

export const statLinkSuccess = (data) => ({
  type: actionType.STAT_LINK_SUCCESS,
  payload: data,
});

export const statLinkFailed = (data) => ({
  type: actionType.STAT_LINK_FAILED,
  payload: data,
});

export const createStatLinkStart = (data) => ({
  type: actionType.CREATE_STAT_LINK_START,
  payload: data,
});

export const createStatLinkSuccess = (data) => ({
  type: actionType.CREATE_STAT_LINK_SUCCESS,
  payload: data,
});

export const createStatLinkFailed = (data) => ({
  type: actionType.CREATE_STAT_LINK_FAILED,
  payload: data,
});

export const updateStatLinkStart = (data) => ({
  type: actionType.UPDATE_STAT_LINK_START,
  payload: data,
});

export const updateStatLinkSuccess = (data) => ({
  type: actionType.UPDATE_STAT_LINK_SUCCESS,
  payload: data,
});

export const updateStatLinkFailed = (data) => ({
  type: actionType.UPDATE_STAT_LINK_FAILED,
  payload: data,
});
export const deleteStatLinkStart = (data) => ({
  type: actionType.DELETE_STAT_LINK_START,
  payload: data,
});

export const deleteStatLinkSuccess = (data) => ({
  type: actionType.DELETE_STAT_LINK_SUCCESS,
  payload: data,
});

export const deleteStatLinkFailed = (data) => ({
  type: actionType.DELETE_STAT_LINK_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function statLinkReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.STAT_LINK_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.STAT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.STAT_LINK_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case actionType.CREATE_STAT_LINK_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.CREATE_STAT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...(state.data || []), action.payload],
        error: null,
      };
    case actionType.CREATE_STAT_LINK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionType.UPDATE_STAT_LINK_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPDATE_STAT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.map(item => item.id === action.payload.id ? action.payload : item),
        error: null,
      };
    case actionType.UPDATE_STAT_LINK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionType.DELETE_STAT_LINK_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.DELETE_STAT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(item => item.id !== action.payload.id),
        error: null,
      };
    case actionType.DELETE_STAT_LINK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* listStatLinksSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      const allStatLinks =
        response.data.data.allAthleteStatLinks.AthleteStatLinks.length > 0
          ? response.data.data.allAthleteStatLinks.AthleteStatLinks
          : null;
      yield put(statLinkSuccess(allStatLinks));
    } else {
      messagePopup('', 'Stat Link List failed', 'error');
      yield put(statLinkFailed('Stat Link List failed'));
    }
  } catch (error) {
    messagePopup('', 'Stat Link List failed', 'error');
    yield put(statLinkFailed('Stat Link List failed'));
  }
}

export function* createStatLinkSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      // Add logic here to add new stat link to state
      const statLink = response.data.data.AthleteStatLinkCreate;
      yield put(createStatLinkSuccess(statLink));
    } else {
      messagePopup('', 'Stat Link Create failed', 'error');
      yield put(createStatLinkFailed('Stat Link Create failed'));
    }
  } catch (error) {
    messagePopup('', 'Stat Link Create failed', 'error');
    yield put(createStatLinkFailed('Stat Link Create failed'));
  }
}

export function* updateStatLinkSaga(action) {
    try {
      const response = yield fetchGraphMethod(
        action.payload.query,
        action.payload.variables,
        true
      );
      if (response && response.status === 200) {
        // Add logic here to add new stat link to state
        const statLink = response.data.data.AthleteStatLinkUpsertWithWhere;
        yield put(updateStatLinkSuccess(statLink));
      } else {
        messagePopup('', 'Stat Link Update failed', 'error');
        yield put(updateStatLinkFailed('Stat Link Update failed'));
      }
    } catch (error) {
      messagePopup('', 'Stat Link Update failed', 'error');
      yield put(updateStatLinkFailed('Stat Link Update failed'));
    }
  }

export function* deleteStatLinkSaga(action) {
    try {
    const response = yield fetchGraphMethod(
        action.payload.query,
        action.payload.variables,
        true
    );
    if (response && response.status === 200) {
        // Add logic here to add new stat link to state
        const statLink = response.data.data.AthleteStatLinkUpsertWithWhere;
        yield put(deleteStatLinkSuccess(statLink));
    } else {
        messagePopup('', 'Stat Link Delete failed', 'error');
        yield put(updateStatLinkFailed('Stat Link Update failed'));
    }
    } catch (error) {
    messagePopup('', 'Stat Link Delete failed', 'error');
    yield put(updateStatLinkFailed('Stat Link Update failed'));
    }
}

export function* watchStatLinkSaga() {
  yield takeEvery(actionType.STAT_LINK_START, listStatLinksSaga);
  yield takeEvery(actionType.CREATE_STAT_LINK_START, createStatLinkSaga);
  yield takeEvery(actionType.UPDATE_STAT_LINK_START, updateStatLinkSaga);
  yield takeEvery(actionType.DELETE_STAT_LINK_START, deleteStatLinkSaga);
}

