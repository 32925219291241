import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import {
  IsValidInstagramHandle,
  IsValidName,
  IsValidTwitterHandle,
} from "../../../../../utils/validationUtils";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardTwitter.scss";
import "../assets/css/onBoardSteeper.scss";
import { Link } from "react-router-dom";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { connect } from "react-redux";
import BackdropLoader from "../../../../common/Loader";
import { getUserInfo } from "../../../../../services/authService";
import { getOnboardingQuery, saveOnboardingQuery } from "../OnboardingQuery";
import usePrevious from "../../../../../utils/usePrevious";
import { InputAdornment } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import Stepper from "../Stepper";

import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import { Dialog } from "@mui/material";
import messagePopup from "../../../../../utils/messagePopup";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "passed", link: "/onboarding/positions" },
  { className: "passed", link: "/onboarding/physicalMeasurements" },
  { className: "passed", link: "/onboarding/personalRecords" },
  { className: "passed", link: "/onboarding/firstYearVarsity" },
  { className: "passed", link: "/onboarding/schoolInfo" },
  { className: "passed", link: "/onboarding/academics" },
  { className: "passed", link: "/onboarding/recruiting" },
  { className: "passed", link: "/onboarding/recruitingprocess" },
  { className: "active", link: "/onboarding/instagram" },
  { className: "" },
  // { className: "" },
  // { className: "" },
];

const Twitter = (props) => {
  const [fields, setFields] = React.useState({
    useTwitter: "yes",
  });
  const [errors, setErrors] = React.useState({});
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    //setOpen(true);
    routeNextPage();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    // props.history.push("/onboarding/baseballLevel");
    props.history.push('/onboarding/primaryCoach')
  };
  useEffect(() => {
    setFields({ useTwitter: "yes" });
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = fields;
        const {
          id,
          usetwitterforschool,
          wanttolearnsocialmediaforsportgoal,
          twitteraccountname,
          instagramaccountname,
        } = props.onboarding.data;
        fieldT["id"] = id;
        fieldT["useTwitter"] =
          usetwitterforschool !== null
            ? usetwitterforschool === 1
              ? "yes"
              : "no"
            : "yes";
        fieldT["twitterhandle"] = twitteraccountname
          ? twitteraccountname.replace("@", "")
          : null;

        fieldT["instagramhandle"] = instagramaccountname
          ? instagramaccountname.replace("@", "")
          : null;

        fieldT["learnTwitter"] =
          wanttolearnsocialmediaforsportgoal !== null
            ? wanttolearnsocialmediaforsportgoal === 1
              ? "yes"
              : "no"
            : "yes";
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);

  const handleRadioChange = (field, value) => {
    let fieldsT = fields;
    fieldsT[field] = value;
    if (field === "useTwitter" && value === "no") {
      fieldsT["learnTwitter"] = "yes";
    }
    setFields({ ...fieldsT });
  };

  const handleSubmit = () => {
    if (!!fields?.instagramhandle || !!fields?.twitterhandle) {
    const { formIsValid, updatedValue } = handleValidation();
    if (formIsValid) {
      props.saveOnboardingStart({
        query: saveOnboardingQuery,
        variables: {
          obj: {
            id: fields.id ? fields.id : 0,
            userid: getUserInfo().id,
            // usetwitterforschool: fields.useTwitter === "yes" ? 1 : 0,
            // wanttolearnsocialmediaforsportgoal:
            //   fields.useTwitter !== "yes"
            //     ? fields.learnTwitter
            //       ? fields.learnTwitter === "yes"
            //         ? 1
            //         : 0
            //       : null
            //     : null,
            twitteraccountname: fields.twitterhandle
              ? `${fields.twitterhandle}`
              : null,
            instagramaccountname: fields?.instagramhandle ?? null,
          },
        },
        nextScreen: routeNextPage,
      });
    } else {
    }
    } else {
      messagePopup("", "Please enter twitter or instagram handle", "error");
    }
  };
  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldvalue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldvalue;

    if (fields["useTwitter"] === "yes") {
      if ("learnTwitter" in fields) {
        delete fields["learnTwitter"];
      }
      if (fieldname === undefined || fieldname === "twitterhandle") {
        if (fieldname === undefined) {
          fieldvalue = fields["twitterhandle"] ? fields["twitterhandle"] : "";
          updatedValue = fieldvalue;
        }

        
        if (!updatedValue) {
          delete errors["twitterhandle"];
        } else if (!IsValidTwitterHandle(updatedValue)) {
          formIsValid = false;
          errorsT["twitterhandle"] =
            "Please enter Twitter Handle in a correct format";
        }
      }
    } else {
      if ("twitterhandle" in fields) {
        delete fields["twitterhandle"];
        delete errorsT["twitterhandle"];
      }
    }
    if (e?.target?.name == "instagramhandle") {
      if (!!e.target.value) {
        delete errorsT["instagramhandle"];
        // const status = IsValidInstagramHandle(e?.target?.value);
        if (!IsValidInstagramHandle(e?.target?.value)) {
          errorsT["instagramhandle"] =
            "Please enter Instagram Handle in a correct format";
          formIsValid = false;
        }
      } else {
        errorsT["instagramhandle"] = "";
        formIsValid = true;
      }
    }
    if (!!errorsT?.twitterhandle || !!errorsT?.instagramhandle)
    // if(!!errorsT?.instagramhandle)
      formIsValid = false;
    else formIsValid = true;
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };
  const handleChange = (field, e) => {
    const { formIsValid, updatedValue } = handleValidation(field, e);
    e.preventDefault();
    let fieldsT = fields;
    fieldsT[field] = updatedValue;
    setFields({ ...fieldsT });
  };

  return (
    <div>
      <BackdropLoader open={props.onboarding.loading} />
      <div className="onBoardTwitterSection">
        <div className="twitterBlock">
          <div className="stepper">
            <Stepper stepperName="twitter" stepperData={stepperData} />
          </div>
          <div className="formSection">
            {/* <h1 className="heading">Do you use Twitter for your sports? </h1> */}

            {/* <div className="recruitProcessBox">
              <ul>
                <li>
                  <span>
                    <input
                      type="radio"
                      name="useTwitter"
                      checked={fields.useTwitter === "yes"}
                      className="yesBlock"
                      onChange={() => handleRadioChange("useTwitter", "yes")}
                    />
                    <label>Yes</label>
                  </span>
                </li>
                <li>
                  <span>
                    <input
                      type="radio"
                      name="useTwitter"
                      checked={fields.useTwitter === "no"}
                      className="noBlock"
                      onChange={() => handleRadioChange("useTwitter", "no")}
                    />
                    <label>No</label>
                  </span>
                </li>
              </ul>
            </div> */}
            <div className="fieldBox ">
              {fields.useTwitter === "yes" && (
                <div className="twitter-itemfield">
                  <label>What's your Twitter handle?</label>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="twitterhandle"
                    placeholder="Enter handle"
                    type="text"
                    name="twitterhandle"
                    value={fields.twitterhandle || ""}
                    onChange={(e) => handleChange("twitterhandle", e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AlternateEmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors["twitterhandle"] ? (
                    <div
                      style={{
                        color: "red",
                        marginTop: "-15px",
                        marginBottom: "15px",
                      }}
                    >
                      {errors["twitterhandle"]}
                    </div>
                  ) : null}
                </div>
              )}
              <div className="twitter-itemfield">
                <label>What's your Instagram handle?</label>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="Instagramhandle"
                  placeholder="Enter handle"
                  type="text"
                  name="instagramhandle"
                  value={fields.instagramhandle || ""}
                  onChange={(e) => handleChange("instagramhandle", e)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ color: "red" }}>{errors["instagramhandle"]}</div>
              </div>
            </div>
            {/* {fields.useTwitter === "no" && (
              <div className="sportGoals ">
                <h4>
                  Would you like to learn how to use social media for sports
                  goals?
                </h4>
                <ul>
                  <li>
                    <span>
                      <input
                        type="radio"
                        name="learnTwitter"
                        checked={fields.learnTwitter === "yes"}
                        onChange={() =>
                          handleRadioChange("learnTwitter", "yes")
                        }
                      />
                      <label>Yes</label>
                    </span>
                  </li>
                  <li>
                    <span>
                      <input
                        type="radio"
                        name="learnTwitter"
                        checked={fields.learnTwitter === "no"}
                        onChange={() => handleRadioChange("learnTwitter", "no")}
                      />
                      <label>No</label>
                    </span>
                  </li>
                </ul>
              </div>
            )} */}
            <p className="skip">
              Don't know?{" "}
              <span className="sikpBtnBox">
                <Link className="skipit-btn" to="#" onClick={handleClickOpen}>
                  Skip it
                </Link>
              </span>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="#" onClick={handleSubmit}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            handleClose()
          }
        }}
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    onboarding: state.athlete.onBoarding.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Twitter);
