import { all, fork } from "redux-saga/effects";
import { watchMembershipListSaga } from './membership';
import { watchAskedQuestionsSaga } from './frequentlyAskedQuestions';
import { watchSearchQuestionsSaga } from "./searchFAQ";
import { watchNotificationSettingSaga } from "./notificationSetting";

export default function* rootAccountSagas () {

    yield all([
        fork(watchMembershipListSaga),
        fork(watchAskedQuestionsSaga),
        fork(watchSearchQuestionsSaga),
        fork(watchNotificationSettingSaga),
    ])
}