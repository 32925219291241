import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../../utils/messagePopup";
import history from "../../../../../services/history";
import { fetchGraphMethod } from "../../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  GET_VIDEO_ORDER_START: "GET_VIDEO_ORDER_START",
  GET_VIDEO_ORDER_FAIL: "GET_VIDEO_ORDER_FAIL",
  GET_VIDEO_ORDER_SUCCESS: "GET_VIDEO_ORDER_SUCCESS",
};

/* ======================================= Actions Creator ==========================================*/

export const getVideoOrderStart = (data) => ({
  type: actionType.GET_VIDEO_ORDER_START,
  payload: data,
});

export const getVideoOrderSuccess = (data) => ({
  type: actionType.GET_VIDEO_ORDER_SUCCESS,
  payload: data,
});

export const getVideoOrderFail = (data) => ({
  type: actionType.GET_VIDEO_ORDER_FAIL,
  payload: data,
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function getVideoOrderReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GET_VIDEO_ORDER_START:
      return { ...state, loading: true };

    case actionType.GET_VIDEO_ORDER_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.GET_VIDEO_ORDER_FAIL:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/

function* getVideoOrderSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response.status === 200 && response.data.data) {
      yield put(
        getVideoOrderSuccess(
          response.data.data.allVideoedittingorders.Videoedittingorders
        )
      );
    } else {
      messagePopup("", "GET VIDEO ORDER FAILED", "error");
      yield put(getVideoOrderFail("Failed, Error occured"));
    }
  } catch (error) {
    messagePopup("", "GET VIDEO ORDER FAILED", "error");
    yield put(getVideoOrderFail("Failed, Error occured"));
  }
}

export function* watchgetVideoOrderSaga() {
  yield takeEvery(actionType.GET_VIDEO_ORDER_START, getVideoOrderSaga);
}
