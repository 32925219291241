import { put, takeEvery } from "redux-saga/effects"
import { fetchMethod } from "../../services/httpService"
import messagePopup from "../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    GRADUATION_MONTH_LIST_START: 'GRADUATION_MONTH_LIST_START',
    GRADUATION_MONTH_LIST_SUCCESS: 'GRADUATION_MONTH_LIST_SUCCESS',
    GRADUATION_MONTH_LIST_FAILED: 'GRADUATION_MONTH_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const graduationMonthsListStart = (data) => ({
   type: actionType.GRADUATION_MONTH_LIST_START,
   payload: data
})

export const graduationMonthsListSuccess = (data) => ({
    type: actionType.GRADUATION_MONTH_LIST_SUCCESS,
    payload: data
})

export const graduationMonthsListFailed = (data) => ({
    type: actionType.GRADUATION_MONTH_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function graduationMonthsListReducer(state = INIT_STATE,action){
  switch(action.type){
      case actionType.GRADUATION_MONTH_LIST_START: 
        return {
            ...state,
            loading: true
        }
      case actionType.GRADUATION_MONTH_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            data: action.payload,
            error: null
        }  
      case actionType.GRADUATION_MONTH_LIST_FAILED:
        return {
            ...state,
            loading: false,
            data: null,
            error: action.payload
        }       
      default: return {...state}  
  }
} 

// ===================================================== side effect =============================================

export function* graduationMonthsListSaga(action){
   try {
    const response = yield fetchMethod(`/allGraduatioMonth`,null,'get',false);
    if(response && response.status === 200){
       const allGraduationMonths = response.data.data ? response.data.data : null
       yield put(graduationMonthsListSuccess(allGraduationMonths));
    } else {
      messagePopup('','Graduation Months List failed','error')   
      yield put(graduationMonthsListFailed('Graduation Months List failed')) 
    }
   } catch (error) {
     messagePopup('','Graduation Months List failed','error')   
     yield put(graduationMonthsListFailed('Graduation Months List failed')) 
   }
}

export function* watchFGraduationMonthsListSaga(){
    yield takeEvery(actionType.GRADUATION_MONTH_LIST_START, graduationMonthsListSaga)
}