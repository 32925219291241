import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SEARCH_CLAIMPROFILE_COACH_LIST_START: "SEARCH_CLAIMPROFILE_COACH_LIST_START",
  SEARCH_CLAIMPROFILE_COACH_SUCCESS: "SEARCH_CLAIMPROFILE_COACH_SUCCESS",
  SEARCH_CLAIMPROFILE_COACH_FAILED: "SEARCH_CLAIMPROFILE_COACH_FAILED",
};

// ============================================ Actions ========================================================
export const getClaimProfileCoachListStart = (data) => ({
  type: actionType.SEARCH_CLAIMPROFILE_COACH_LIST_START,
  payload: data,
});

export const getClaimProfileCoachSuccess = (data) => ({
  type: actionType.SEARCH_CLAIMPROFILE_COACH_SUCCESS,
  payload: data,
});

export const getClaimProfileCoachFailed = (data) => ({
  type: actionType.SEARCH_CLAIMPROFILE_COACH_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function getClaimProfileCoachReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.SEARCH_CLAIMPROFILE_COACH_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SEARCH_CLAIMPROFILE_COACH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SEARCH_CLAIMPROFILE_COACH_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* getClaimProfileCoachListSaga(action) {
  try {
    const response = yield fetchMethod(`/api/UserData/searchForCoachClaim`, action.payload.data, "post", false);
    let allCoaches = response.data.response.data;
    if (response && response.status === 200) {
        yield put(getClaimProfileCoachSuccess(allCoaches));
    } else {
      messagePopup("", "Claim Profile Coach List failed", "error");
      yield put(getClaimProfileCoachFailed("Claim Profile Coach List failed"));
    }
  } catch (error) {
    messagePopup("", "Claim Profile Coach List failed", "error");
    yield put(getClaimProfileCoachFailed("Claim Profile Coach List failed"));
  }
}

export function* watchClaimProfileCoachListSaga() {
  yield takeEvery(actionType.SEARCH_CLAIMPROFILE_COACH_LIST_START, getClaimProfileCoachListSaga);
}
