import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  AREA_OF_STUDY_LIST_START: "AREA_OF_STUDY_LIST_START",
  AREA_OF_STUDY_LIST_SUCCESS: "AREA_OF_STUDY_LIST_SUCCESS",
  AREA_OF_STUDY_LIST_FAILED: "AREA_OF_STUDY_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const areaOfStudyListStart = (data) => ({
  type: actionType.AREA_OF_STUDY_LIST_START,
  payload: data,
});

export const areaOfStudyListSuccess = (data) => ({
  type: actionType.AREA_OF_STUDY_LIST_SUCCESS,
  payload: data,
});

export const areaOfStudyListFailed = (data) => ({
  type: actionType.AREA_OF_STUDY_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function areaOfStudyListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.AREA_OF_STUDY_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.AREA_OF_STUDY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.AREA_OF_STUDY_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* areaOfStudyListSaga(action) {
  try {
    const response = yield fetchGraphMethod(action.payload.query, null, true);
    if (response && response.status === 200) {
      const allAreaofstudies = response.data.data.allAreaofstudies.Areaofstudies
        ? response.data.data.allAreaofstudies.Areaofstudies
        : null;
      allAreaofstudies.sort((a, b) =>
        a.areaofstudyname
          .toLowerCase()
          .localeCompare(b.areaofstudyname.toLowerCase())
      );
      yield put(areaOfStudyListSuccess(allAreaofstudies));
    } else {
      messagePopup("", "Area of study list failed", "error");
      yield put(areaOfStudyListFailed("GPA List failed"));
    }
  } catch (error) {
    messagePopup("", "Area of study list failed", "error");
    yield put(areaOfStudyListFailed("Area of study list failed"));
  }
}

export function* watchAreaOfStudyListSaga() {
  yield takeEvery(actionType.AREA_OF_STUDY_LIST_START, areaOfStudyListSaga);
}
