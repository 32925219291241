import PersonalRecords from "./stats/PersonalRecords";
import "./assets/css/stats.scss";
import "./assets/css/editProfile.scss";
import SubHeader from "./SubHeader";
import LeftSideEditProfile from "./LeftSideEditProfile";
import { useEffect, useState, useRef } from "react";
import { Dialog } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import StatsSection from "./stats/StatsSection";
import GoTopButton from './GoTopButton';
import ScreenSwitch from './ScreenSwitch'
import {websiteDefaultUrl} from "../../../../../config";
import {getAthleteSysID} from "../../../../common/getProfileURLData";

const Stats = (props) => {
  const [sideMenuOpen, setSideMenu] = useState(false);
  const [activeTab, setActiveTab] = useState("personalRecord");
  const [videoOpen, setVideoOpen] = useState(false);
  const [videoData, setVideoData] = useState(null);
  const fakeInput = useRef(null);

  const onChangeTabs = (flag) => {
    if (flag === "personalRecord") {
      scrollAdjusted(document.getElementById("personalRecordSection"));
    }
    if (flag === "stats") {
      scrollAdjusted(document.getElementById("statsSection"));
    }
    setActiveTab(flag);
  };

  useEffect(() => {
    scrollAdjusted(document.getElementById("personalRecordSection"));
  }, []);

  const scrollAdjusted = (element) => {
    const headerOffset = 55;
    const elementPosition = element.getBoundingClientRect().top;
    const top = elementPosition - headerOffset;
    window.scrollTo({
      top,
      behavior: "smooth",
    });
  };

  const onSideMenuClick = () => {
    setSideMenu(!sideMenuOpen);
  };
  const handalPreviewProfile = () => {
    props.history.push(
        {
          pathname: `${getAthleteSysID()}`,
          state: {isPublic: false}
        }
        // "/profile/1"
    );
    sessionStorage.setItem('athlete_own_profile', true)
  };

  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };

  return (
    <div className="pageContent">
      <div className="editProfile">
      <input className="fakeInput" type="text" ref={fakeInput} />
        <div className="container">
          <div
            id="wrapper"
            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
          >
            <SubHeader hamburger={onSideMenuClick} buttonHide={false} />
            <div className="sidebar">
              <LeftSideEditProfile />
            </div>
            <div className="editSection">
              <div className="flexRow">
                <div className="title">Stats</div>
                <div className="btnWrap">
                <button
                      className="btn outlined green rounded medium backprofileBtn"
                      onClick={() => handalPreviewProfile()}
                    >
                      Back To Profile
                    </button>
                  <button
                    className="btn outlined green rounded medium"
                    onClick={() => {
                      handalPreviewProfile();
                    }}
                  >
                    Preview Profile
                  </button>
                  <button className="btn outlined blue rounded medium"
                    onClick={() => {
                      sessionStorage.setItem('athlete_own_profile', false)
                      setTimeout(() => {
                        window.open(`${websiteDefaultUrl}athlete/wyatt-johnson-74144936`, "_blank")
                      }, 500)
                    }}
                  >
                    Sample Profile
                  </button>
                </div>
              </div>
              <div className="check-list-sec">
                <div className="stats-main-sec">
                  <div className="stats-tab-with-button">
                    <div className="tabBox">
                      <ul className="nav nav-tabs">
                        <li>
                          <a
                            href={void 0}
                            className={
                              activeTab === "personalRecord" ? "active" : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              onChangeTabs("personalRecord");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Personal Records
                          </a>
                        </li>
                        <li>
                          <a
                            href={void 0}
                            className={activeTab === "stats" ? "active" : ""}
                            onClick={(e) => {
                              e.preventDefault();
                              onChangeTabs("stats");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Stats
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="button-box">
                      <button
                          style={{cursor:"not-allowed"}}
                        className="btn"
                        disabled
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   handlePlayTutorials();
                        // }}
                      >
                        <span className="icon-box">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="17"
                            viewBox="0 0 12 17"
                          >
                            <path
                              id="Polygon_15"
                              data-name="Polygon 15"
                              d="M8.5,0,17,12H0Z"
                              transform="translate(12) rotate(90)"
                              fill="#fff"
                            ></path>
                          </svg>
                        </span>
                        Play Tutorial
                      </button>
                    </div>
                  </div>
                  <div id="personalRecordSection">
                    <PersonalRecords activeTab={activeTab} />
                  </div>
                  <div id="statsSection">
                    <StatsSection activeTab={activeTab} />
                  </div>
                </div>
              </div>
              <div className="stats-with-next-prev">
                  <div className="next-prev-button">
                    <ScreenSwitch
                      prevLink={"/teams"}
                      nextLink={"/events"}
                      isSaved={true}
                    />
                  </div>
                </div>
            </div>
          </div>
        </div>
        <GoTopButton goTop={goTop} />
      </div>
      <Dialog open={videoOpen} maxWidth={"lg"}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setVideoOpen(false)
                }
              }}
              className="videoOverlayWrap matDialogCustom"
      >
        <VideoOverlay
          onVideoClose={() => setVideoOpen(false)}
          videoData={videoData}
        />
      </Dialog>
    </div>
  );
};

export default Stats;

const VideoOverlay = ({ onVideoClose, videoData = null }) => {
  const sourceURL = isNaN(videoData)
    ? `https://www.youtube.com/embed/${videoData ? videoData : "aqz-KE-bpKQ"}`
    : `https://player.vimeo.com/video/${videoData && videoData}`;
  return <>
    <IconButton
      aria-label="close"
      className={"closeBtnVideo"}
      onClick={onVideoClose}
      size="large">
      <CloseIcon />
    </IconButton>
    <iframe
      width="700"
      height="400"
      src={sourceURL}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </>;
};
