import { combineReducers } from "redux";
import howToVideoReducer from "./howToVideo";
import resourceLibraryReducer from "./resourceLibrary";
import resourceHeaderReducer from "./resourcesHeader";
import saveViewVideoReducer from './viewVideo'
import saveAthleteViewVideoReducer from './viewVideo'

export default combineReducers({
  resourcesHeader: resourceHeaderReducer,
  howToVideoList: howToVideoReducer,
  resourceLibrary: resourceLibraryReducer,
  resourceViewVideo: saveViewVideoReducer,
  resourceAthleteViewVideo: saveAthleteViewVideoReducer
});
