import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { getCoachProgramsListStart, saveCoachProgramsData } from '../../../../../redux/coach/dashboard/myRecruits/allCoachPrograms';
import { getUserInfo } from '../../../../../services/authService';
import messagePopup from '../../../../../utils/messagePopup';
import { validURL } from '../../../../../utils/validationUtils';
import { getAllProgramsDataQuery, saveProgramsDataQuery } from './MyRecruitsQuery';
import ScreenSwitch from './ScreenSwitch';
import toastr from 'toastr';


function ProgramsTab(props) {
const [fields, setFields] = useState({
    athleticScholarship : "",
    finaccialPackage : "",
    aboutProgram : "",
    questionaire : "",
    brochure : "",
    programmeVideo : ""
});
const [isSaved, setIsSaved] = useState(true);
const [errors, setErrors] = useState({});


useEffect(()=> {
  getAllProgramsData();
}, [])

useEffect(()=>{
  props.handleCheckSaved(isSaved)
},[isSaved])

  useEffect(()=> {
    if(props.coachProgramsData.data){
    let data = props.coachProgramsData.data && props.coachProgramsData.data[0];
    let fieldsT = fields;
    fieldsT["id"] = data?.id;
    fieldsT["aboutProgram"] = data?.about;
    fieldsT["athleticScholarship"] = data?.athleticscholarship;
    fieldsT["finaccialPackage"] = data?.financialpackagedetails;
    fieldsT["questionaire"] = data?.questionaire;
    fieldsT["programmeVideo"] = data?.programevideo;
    fieldsT["brochure"] = data?.broucher;
    setFields({
      ...fieldsT
    });
  }
  }, [props.coachProgramsData.data])
    const handleChange = (e, field) => {
      let errorsT = errors;
      setErrors({});
        let fieldsT = fields;
        fieldsT[field] = e ? (e.target ? e.target.value : e) : e ;
        setFields({
            ...fieldsT
        })
      setIsSaved(false);
    }
    const handleValidation = () => {
      let errorsT = errors;
      let formIsValid = true;
      if(fields.questionaire && !validURL(fields.questionaire)){
        formIsValid  = false;
        errorsT["questionaire"] = "Please enter a valid link."
      }
      if(fields.brochure && !validURL(fields.brochure)){
        formIsValid = false;
        errorsT["brochure"] = "Please enter a valid link."
      }
      if(fields.programmeVideo && !validURL(fields.programmeVideo)){
        formIsValid = false;
        errorsT["programmeVideo"] = "Please enter a valid link."
      }
      setErrors({
        ...errorsT
      });
      return formIsValid
    }
    const getAllProgramsData = () => {  
      props.getCoachProgramsListStart({
        query : getAllProgramsDataQuery,
        variables : {
          where : {
            coachid : getUserInfo() && getUserInfo().id ? getUserInfo().id : null
          }
        }
      })
    }
    const saveProgramsData = () => {
      const formIsValid = handleValidation();
      if(formIsValid == true) {
        props.saveCoachProgramsData({
            query : saveProgramsDataQuery,
            variables : fields.id ? {
                obj : {
                id : fields.id ,
                coachid : getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
                athleticscholarship : fields.athleticScholarship ? fields.athleticScholarship : "",
                financialpackagedetails : fields.finaccialPackage ? fields.finaccialPackage : "",
                about : fields.aboutProgram ? fields.aboutProgram : "",
                questionaire : fields.questionaire ? fields.questionaire : "",
                broucher : fields.brochure ? fields.brochure : "",
                programevideo : fields.programmeVideo ? fields.programmeVideo : ""
                }
            }
            : 
            {
              obj : {
              coachid : getUserInfo() && getUserInfo().id ? getUserInfo().id : null,
              athleticscholarship : fields.athleticScholarship ? fields.athleticScholarship : "",
              financialpackagedetails : fields.finaccialPackage ? fields.finaccialPackage : "",
              about : fields.aboutProgram ? fields.aboutProgram : "",
              questionaire : fields.questionaire ? fields.questionaire : "",
              broucher : fields.brochure ? fields.brochure : "",
              programevideo : fields.programmeVideo ? fields.programmeVideo : ""
              }
          },
          nextRoute : nextRoute
        })
      }
    }
    const nextRoute = () => {
      getAllProgramsData();
      setIsSaved(true);
      // messagePopup("", "Your details are saved successfully", "success")
      toastr.success("Your details are saved successfully", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates:true,
      })
    }
  return <div>
      <div className="top-box">
                  <p>
                    Set your program's geographic preferences to help improve
                    your potential athlete recommendations.
                  </p>
                </div>
                <div className="recruiting-detail-box">
                  <div className="field-box-item">
                    <h5>Athletic Scholarship</h5>
                    <div className="fieldBox flexBox">
                      <div className="half">
                        <select
                        value={fields.athleticScholarship || ""}
                        onChange={(e)=>handleChange(e, "athleticScholarship")}>
                          <option selected="" value="">
                           Select
                          </option>
                          <option value="Yes">Yes </option>
                          <option value="fullyFunded"> Fully funded </option>
                          <option value="yesNotFullyFunded">Yes Not fully funded </option>
                          <option value="NA">NA</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field-box-item">
                    <h4>Financial Package Details:</h4>
                    <div className="fieldBox">
                      <label>
                        How does your school determine financial aid,
                        academic/merit based scholarship(GPA, test scores),
                        etc...?
                      </label>
                      <textarea 
                      placeholder="Academic scholarship money starts at 3.0+ 10K, 3.5+$14K tough to get financial aid"
                      value={fields.finaccialPackage || ""}
                      onChange={(e)=>handleChange(e, "finaccialPackage")}
                      ></textarea>
                    </div>
                  </div>
                  <div className="field-box-item">
                    <h4>Tell Us About Your Program?</h4>
                    <div className="fieldBox">
                      <label>
                        (Ex. coaching philosophy, athletes you recruits, top
                        majors, ets..)
                      </label>
                      <textarea 
                      placeholder=""
                      value={fields.aboutProgram || ""}
                      onChange={(e)=>handleChange(e, "aboutProgram")}
                      ></textarea>
                    </div>
                  </div>
                  <div className="field-box-item">
                    <h4>Questionaire</h4>
                    <div className="fieldBox">
                      <input
                        type="text"
                        value={fields.questionaire || ""}
                        onChange={(e)=>handleChange(e, "questionaire")}
                        placeholder=""
                      />
                      <div className="error">{errors["questionaire"]}</div>
                    </div>
                  </div>
                  <div className="field-box-item">
                    <h4>Brochure</h4>
                    <div className="fieldBox">
                      <input
                        type="text"
                        value={fields.brochure || ""}
                        onChange={(e)=>handleChange(e,"brochure" )}
                        placeholder=""
                      />
                      <div className="error">{errors["brochure"]}</div>
                    </div>
                  </div>
                  <div className="field-box-item">
                    <h4>Programe Video</h4>
                    <div className="fieldBox">
                      <input
                        value={fields.programmeVideo || ""}
                        onChange={(e)=>handleChange(e, "programmeVideo" )}
                        type="text"
                        placeholder=""
                      />
                      <div className="error">{errors["programmeVideo"]}</div>
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="item">
                      <button className="btn blue" onClick={saveProgramsData}>Save</button>
                    </div>
                    <div className="next-prev-button">
                          <ScreenSwitch
                            prevLink={()=>props.handleSwitchTabData("geographic")}
                            nextLink={()=>{}}
                            nextLinkDisabled={true}
                            isSaved={isSaved}
                          />
                        </div>
                  </div>
                </div>
            </div>;
}

const mapStateToProps = (state) => {
    return {
     coachProgramsData : state.coach.coachDashboard.coachPrograms
    }
}
const mapDispatchToProps = (dispatch) => {
    return{
        saveCoachProgramsData : (data) => dispatch(saveCoachProgramsData(data)),
        getCoachProgramsListStart : (data) => dispatch(getCoachProgramsListStart(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProgramsTab);
