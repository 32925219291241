import { put, takeEvery } from "redux-saga/effects";
import { imageDownloadUrl } from "../../../../config";
import { fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  GLOBAL_FEED_LIST_START: "GLOBAL_FEED_LIST_START",
  GLOBAL_FEED_LIST_SUCCESS: "GLOBAL_FEED_LIST_SUCCESS",
  GLOBAL_FEED_LIST_FAILED: "GLOBAL_FEED_LIST_FAILED",
};

// ============================================ Actions ========================================================

export const globalFeedListStart = (data) => ({
  type: actionType.GLOBAL_FEED_LIST_START,
  payload: data,
});

export const globalFeedListSuccess = (data) => ({
  type: actionType.GLOBAL_FEED_LIST_SUCCESS,
  payload: data,
});

export const globalFeedListFailed = (data) => ({
  type: actionType.GLOBAL_FEED_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function globalFeedListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.GLOBAL_FEED_LIST_START:
      return {
        ...state,
        loading: true,
      };

    case actionType.GLOBAL_FEED_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.GLOBAL_FEED_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* globalFeedListSaga(action) {
  try {
    const { sportid, userid, offset, limit } = action.payload;
    const response = yield fetchMethod(
      `/api/Userposts/globalFeedData?sportsId=${sportid}&loginUserId=${userid}&limit=${limit}&offset=${offset}`,
      action.payload.data,
      "get",
      true
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        const data = response.data.response.data;
        let finalData = [];
        if (data.Event && data.Event.length) {
          finalData = [
            ...finalData,
            ...data.Event.map((item) => {
              item["postType"] = "event";
              if (item.profileImages) {
                item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
              }
              return item;
            }),
          ];
        }
        if (data.allEvent && data.allEvent.length) {
          finalData = [
            ...finalData,
            ...data.allEvent.map((item) => {
              item["postType"] = "eventNew";
              if (item.profileImages) {
                item.profileImages = `${item.profileImages}`;
              }
              return item;
            }),
          ];
        }
        if (data.allFavouriteColloge && data.allFavouriteColloge.length) {
          finalData = [
            ...finalData,
            ...data.allFavouriteColloge.map((item) => {
              item["postType"] = "favorite";
              if (item.profileImages) {
                item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
              }
              return item;
            }),
          ];
        }
        if (data.allGlobalPost && data.allGlobalPost.length) {
          finalData = [
            ...finalData,
            ...data.allGlobalPost.map((item) => {
              item["postType"] = "post";
              if (item.profileImages) {
                item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
              }
              return item;
            }),
          ];
        }
        if (data.commit && data.commit.length) {
          finalData = [
            ...finalData,
            ...data.commit.map((item) => {
              item["postType"] = "commit";
              if (item.profileImages) {
                item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
              }
              return item;
            }),
          ];
        }
        if (data.team && data.team.length) {
          finalData = [
            ...finalData,
            ...data.team.map((item) => {
              item["postType"] = "team";
              if (item.profileImages) {
                item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
              }
              return item;
            }),
          ];
        }

        if (data.photosVideos && data.photosVideos.length) {
          finalData = [
            ...finalData,
            ...data.photosVideos.map((item) => {
              item["postType"] = "photosVideos";
              if (item.profileImages) {
                item.profileImages = `${imageDownloadUrl}/${item.profileImages}`;
              }
              return item;
            }),
          ];
        }
        // userActivityUpdatedOn
        const sortedData = finalData.sort((a, b) => {
          // return b.serialNo - a.serialNo;
          return (
            new Date(b.userActivityUpdatedOn) -
            new Date(a.userActivityUpdatedOn)
          );
        });
        yield put(
          globalFeedListSuccess({
            allData: sortedData,
            totalCount: data.totalCount,
          })
        );
      }
    } else {
      messagePopup("", "Fetch global feeds failed", "error");
      yield put(globalFeedListFailed("Fetch global feeds failed"));
    }
  } catch (error) {
    messagePopup("", "Fetch global feeds failed", "error");
    yield put(globalFeedListFailed("Fetch global feeds failed"));
  }
}

export function* watchglobalFeedListSaga() {
  yield takeEvery(actionType.GLOBAL_FEED_LIST_START, globalFeedListSaga);
}
