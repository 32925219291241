import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  POSITIONAL_FIELDS_LIST_START: "POSITIONAL_FIELDS_LIST_START",
  POSITIONAL_FIELDS_LIST_SUCCESS: "POSITIONAL_FIELDS_LIST_SUCCESS",
  POSITIONAL_FIELDS_LIST_FAILED: "POSITIONAL_FIELDS_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const getPositionalFieldsListStart = (data) => ({
  type: actionType.POSITIONAL_FIELDS_LIST_START,
  payload: data,
});

export const getPositionalFieldsListSuccess = (data) => ({
  type: actionType.POSITIONAL_FIELDS_LIST_SUCCESS,
  payload: data,
});

export const getPositionalFieldsListFailed = (data) => ({
  type: actionType.POSITIONAL_FIELDS_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function getPositionalFieldsListReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.POSITIONAL_FIELDS_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.POSITIONAL_FIELDS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.POSITIONAL_FIELDS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* getPositionalFieldsListSaga(action) {
  try {
    const response = yield fetchMethod(`/api/Positionmappings/getPositionField`, action.payload, "get", false);
    if (response && response.status === 200) {
    } else {
      messagePopup("", "Postional Fields List failed", "error");
      yield put(getPositionalFieldsListFailed("Postional Fields List failed"));
    }
  } catch (error) {
    messagePopup("", "Postional Fields List failed", "error");
    yield put(getPositionalFieldsListFailed("Postional Fields List failed"));
  }
}

export function* watchGetPositionalFieldsListSaga() {
  yield takeEvery(actionType.POSITIONAL_FIELDS_LIST_START, getPositionalFieldsListSaga);
}
