import React, { useEffect } from "react";
import { Link } from "@mui/material";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardCongratParents.scss";
import "../assets/css/onBoardSteeper.scss";
import medal from "../assets/img/medal.png";
import { getUserInfo } from "../../../../../services/authService";
import { connect } from "react-redux";
import { athleteParentMappingStart } from "../../../../../redux/athlete/onBoarding/athleteParentMapping";
import { parentNameQuery } from "./CongrationsParentQuery";

function CongratulateParents(props) {
  useEffect(() => {
    getAllParentData();
  }, []);
  const getAllParentData = () => {
    props.allParentListStart({
      query: parentNameQuery,
      variables: {
        obj: {
          athleteid: getUserInfo() ? getUserInfo().id : 0,
          isaccessallowed: 1,
        },
      },
    });
  };
  
  const parentFirstName =
    props.allParentData &&
    props.allParentData.data &&
    props.allParentData.data.parentData
      ? props.allParentData.data.parentData[0].parentfirstname
      : "";
  return (
    <div>
      <div className="onBoardCongratAthleteSection">
        <div className="congratAthleteBlock">
          <div className="medalImg">
            <img src={medal} alt="medalImg" />
          </div>
          <div className="data">
            <h1 className="heading">
              Great Job {parentFirstName }!
            </h1>
            <p className="info">
              Now it's time to help{" "}
              <b>{getUserInfo() ? getUserInfo().FirstName : ""}</b> take their
              game to the next level!
            </p>
            <div className="btnBox">
              <Link to="#" onClick={() => props.history.push("/welcomeScreen")}>
                Let's Go!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    allParentData: state.athlete.onBoarding.athleteParentMapping,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    allParentListStart: (data) => dispatch(athleteParentMappingStart(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CongratulateParents);
