import { combineReducers } from "redux";
import addRemoveFavoriteCollegeReducer from "./addRemoveUserCollege";
import areaOfStudyListReducer from "./areaOfStudyList";
import categoryListReducer from "./categoryList";
import checkSearchFilterReducer from "./checkSearchFilter";
import collegeAcademicsReducer from "./collegeAcademics";
import collegeAdmissionsReducer from "./collegeAdmissions";
import collegeCampusesReducer from "./collegeCampuses";
import collegesListReducer from "./collegeList";
import academicSelectivityListReducer from "./academicSelectivityList";
import CollegeSettingsListReducer from "./collegeSettingsList";
import collegeSizeListReducer from "./collegeSizeList";
import TutionCostsListReducer from "./tutionCostsList";
import CollegedivisionsListReducer from "./collegedivisionsList";
import AllRegionListReducer from "./allRegionList";
import AllStateListReducer from "./allStateList";



import searchCollegeListReducer from "./collegeSearchList";
import myProgressReducer from "./myProgress";
import saveSearchFilterReducer from "./saveSearchFilter";
import searchFiltersListReducer from "./searchFilterList";
import updateUserCollegeReducer from "./updateUserCollege";
import userCollegeCardCountReducer from "./userCollegeCardCount";
import viewCollegeReducer from "./viewCollege";
import followRecruitCountListReducer from './followRecruitCount';
import getClgActivityReducer from "./activity";
import collegeActivityReducer from "./collegeActivity";
import searchCollegeCountListReducer from "./searchCollegeCount";
import userProfileViewListReducer from "./userProfileView";
import lastContactReducer from "./messageSent";
import searchStateByRegionReducer from "./searchStateByRegion";
export default combineReducers({
  searchCollegeList: searchCollegeListReducer,
  collegesList: collegesListReducer,
  academicSelectivityList: academicSelectivityListReducer,
  collegeSettingsList: CollegeSettingsListReducer,
  collegeSizeList: collegeSizeListReducer,
  tutionCostsList: TutionCostsListReducer,
  collegedivisionsList: CollegedivisionsListReducer,
  allRegionList: AllRegionListReducer,
  allStateList:AllStateListReducer,

  searchFilters: searchFiltersListReducer,
  saveSearchFilter: saveSearchFilterReducer,
  checkSearchFilter: checkSearchFilterReducer,
  addRemoveFavoriteCollege: addRemoveFavoriteCollegeReducer,
  categories: categoryListReducer,
  updateUserCollege: updateUserCollegeReducer,
  userCollegeCardCount: userCollegeCardCountReducer,
  areOfStudyList: areaOfStudyListReducer,
  viewCollege : viewCollegeReducer,
  myProgress : myProgressReducer,
  followRecruitCountData : followRecruitCountListReducer,
  clgActivity: getClgActivityReducer,
  collegeAdmissions : collegeAdmissionsReducer,
  collegeCampuses : collegeCampusesReducer,
  collegeAcademics : collegeAcademicsReducer,
  collegeActivity : collegeActivityReducer,
  searchCollege : searchCollegeCountListReducer,
  userprofileView : userProfileViewListReducer,
  lastcontact : lastContactReducer,
  serachStateByRegion : searchStateByRegionReducer
});
