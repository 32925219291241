import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";
import history from "../../../services/history";
import { setAuthToken, setUserInfo } from "../../../services/authService";
import swal from 'sweetalert'
import {loginFailed} from "./login";
// ========================================== Action Type =====================================================
export const actionType = {
    COACH_LOGIN_START: "COACH_LOGIN_START",
    COACH_LOGIN_SUCCESS: "COACH_LOGIN_SUCCESS",
    COACH_LOGIN_FAILED: "COACH_LOGIN_FAILED",
};

// ============================================ Actions ========================================================
export const CoachloginStart = (data) => ({
    type: actionType.COACH_LOGIN_START,
    payload: data,
});

export const CoachloginSuccess = (data) => ({
    type: actionType.COACH_LOGIN_SUCCESS,
    payload: data,
});

export const CoachloginFailed = (data) => ({
    type: actionType.COACH_LOGIN_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function CoachloginReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.COACH_LOGIN_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.COACH_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.COACH_LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// =========================================== side effect =================================================

export function* CoachloginSaga(action) {
    try {
        const response = yield fetchMethod(
            `/login?email=${action.payload.email}&password=${action.payload.password}`,
            {email: action.payload.email, password: action.payload.password},
            "post",
            false
        );
        if (response && response.status === 200) {
            if (
                response.data &&
                response.data.statusCode &&
                response.data.statusCode === 401
            ) {
                messagePopup("", 'Invalid Email ID or Password', "error");
                yield put(CoachloginFailed('Invalid Email ID or Password'));
            }
            //this check is for athlete advisor login restrict
            if(!!response?.data?.length > 0 && (response?.data?.[0]?.role == "ADMIN" || response?.data?.[0]?.role == "ADVISORS" )){
                messagePopup('',"Access Denied",'error')
                yield put(loginFailed('Access Denied'));
            }
            if (response.data && response.data.token) {
                //this check is for athlete advisor admin login restrict
                if((response?.data?.role == "ATHLETES" )){
                    messagePopup('',"Access Denied",'error')
                    yield put(loginFailed('Access Denied'));
                }
                else if(response.data.verifiedUserStatus == 0){
                    yield put(CoachloginSuccess(response.data));
                  return   swal('Request have been sent to admin for verification, you will be redirected on the next process once you are verified',"")
                }
                else if (response.data.ispasswordStatus == 0 && response.data.isdoneonboardingprocess==0) {
                    yield put(CoachloginSuccess(response.data));
    
                    // swal({text: 'Please Reset Your Temporary Password', icon: "warning"})
                    setUserInfo(response.data);
                     setAuthToken(response.data.token);
                    history.push(`/coach-changePassword/${action.payload.email}`);
                }
                else if(response.data.isdoneonboardingprocess==0){
                    setUserInfo(response.data);
                    setAuthToken(response.data.token);
                   history.push("/coach-onboarding/Profile");
                }
                else if (response.data.ispasswordStatus == 1&& response.data.isdoneonboardingprocess==1) {
                    yield put(CoachloginSuccess(response.data));
                    // swal({text: 'Please Reset Your Temporary Password', icon: "warning"})
                    setUserInfo(response.data);
                    setAuthToken(response.data.token);
                    history.push("/coach-homepage");
                }

                else if (response.data.ispasswordStatus == 1&& response.data.isdoneonboardingprocess==0) {
                    yield put(CoachloginSuccess(response.data));
    
                    // swal({text: 'Please Reset Your Temporary Password', icon: "warning"})
                    setUserInfo(response.data);
                    // setAuthToken(response.data.token);
                    // localStorage.setItem("authToken", response.data.token);
                    history.push("/coach-onboarding/Profile");
                }

            else {
                    setUserInfo(response.data);
                    setAuthToken(response.data.token);
                    yield put(CoachloginSuccess(response.data));
                    messagePopup("", 'Login successfully', "success");
                    history.push("/coach-homepage");
                }
            }
            if (response.data && response.data.error) {
                messagePopup("", response.data.error, "error");
                yield put(CoachloginFailed(response.error));
            }
        } else {
            messagePopup("", "Login failed", "error");
            yield put(CoachloginFailed("Login failed"));
        }
    } catch (error) {
        messagePopup("", "Login failed", "error");
        yield put(CoachloginFailed("Login failed"));
    }
}

export function* watchCoachLoginSaga() {
    yield takeEvery(actionType.COACH_LOGIN_START, CoachloginSaga);
}
