import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";
import {API_URL, imageDownloadUrl} from "../../config";

// ========================================== Action Type =====================================================
export const actionType = {
  COUNTRY_DEPENDENT_LIST_START: "COUNTRY_DEPENDENT_LIST_START",
  COUNTRY_DEPENDENT_LIST_SUCCESS: "COUNTRY_DEPENDENT_LIST_SUCCESS",
  COUNTRY_DEPENDENT_LIST_FAILED: "COUNTRY_DEPENDENT_LIST_FAILED"
};

// ============================================ Actions ========================================================

export const countryDependentListStart = data => ({
  type: actionType.COUNTRY_DEPENDENT_LIST_START,
  payload: data
});

export const countryDependentListSuccess = data => ({
  type: actionType.COUNTRY_DEPENDENT_LIST_SUCCESS,
  payload: data
});

export const countryDependentListFailed = data => ({
  type: actionType.COUNTRY_DEPENDENT_LIST_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function countryDependentListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.COUNTRY_DEPENDENT_LIST_START:
      return {
        ...state,
        loading: true
      };

    case actionType.COUNTRY_DEPENDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.COUNTRY_DEPENDENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* countryDependentListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
        const allCountries = response?.data?.data?.allCountries?.Countries?.length > 0
        ? response?.data?.data?.allCountries?.Countries.map((item) => {
            if (item.countryflagimage) {
              item.countryflagimage = `${imageDownloadUrl}/${item.countryflagimage}`;
            }
            return item;
          })
        : null;
      yield put(
        countryDependentListSuccess({data: allCountries})
      );
    } else {
      messagePopup("", "Country Api failed", "error");
      yield put(countryDependentListFailed("Country Api failed"));
    }
  } catch (error) {
    messagePopup("", "Country Api failed", "error");
    yield put(countryDependentListFailed("Country Api failed"));
  }
}

export function* watchCountryDependentListSaga() {
  yield takeEvery(actionType.COUNTRY_DEPENDENT_LIST_START, countryDependentListSaga);
}
