import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  VIEW_COMMITMENT_LIST_START: "VIEW_COMMITMENT_LIST_START",
  VIEW_COMMITMENT_LIST_SUCCESS: "VIEW_COMMITMENT_LIST_SUCCESS",
  VIEW_COMMITMENT_LIST_FAILED: "VIEW_COMMITMENT_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const viewCommitmentListStart = (data) => ({
  type: actionType.VIEW_COMMITMENT_LIST_START,
  payload: data,
});

export const viewCommitmentListSuccess = (data) => ({
  type: actionType.VIEW_COMMITMENT_LIST_SUCCESS,
  payload: data,
});

export const viewCommitmentListFailed = (data) => ({
  type: actionType.VIEW_COMMITMENT_LIST_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function viewCommitmentsListReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.VIEW_COMMITMENT_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.VIEW_COMMITMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.VIEW_COMMITMENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* viewCommitmentsListSaga(action) {
  try {
    const response = yield fetchGraphMethod(action.payload.query, null, true);
    if (response && response.status === 200) {
      const viewCommitmentsList = response.data.data
        .CollegecommitmentSearchByFilters
        ? response.data.data.CollegecommitmentSearchByFilters
        : null;
      yield put(viewCommitmentListSuccess(viewCommitmentsList));
    } else {
      messagePopup("", "View Commitment List failed", "error");
      yield put(viewCommitmentListFailed("View Commitment List failed"));
    }
  } catch (error) {
    messagePopup("", " View Commitment List failed", "error");
    yield put(viewCommitmentListFailed("View Commitment List failed"));
  }
}

export function* watchViewCommitmentsListSaga() {
  yield takeEvery(
    actionType.VIEW_COMMITMENT_LIST_START,
    viewCommitmentsListSaga
  );
}
