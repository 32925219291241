export default function disableNotInHighSchool(graduationyearValue) {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;
  const athleteYear = graduationyearValue;
  // August month
  const athleteMonth = 8; //fields.user.graduationMonthValue;
  if (currentYear > athleteYear) {
    return true;
  } else if (currentYear === athleteYear) {
    if (currentMonth > athleteMonth) {
      return true;
    } else {
      return false;
    }
  }else{
    return false;
  }
}
