export const saveUserProfile = `
mutation saveUserprofileview($obj: UserprofileviewInput!){
saveUserprofileview(obj:$obj){
    id
}
}
`
export const saveUserActProfile = `
mutation saveUseracivity($obj: UseracivityInput!){
saveUseracivity(obj:$obj){
    id
}
}
`