import React, { useEffect, useState } from 'react';
import ClaimProfileHeader from '../auth/claimProfile/claimProfileStep1/ClaimProfileHeader';
import DashboardHeader from '../../layouts/header/DashboardHeader';
import { getAuthToken } from '../../../services/authService';
import logo from './assets/img/logo.png';
import AVT from './assets/img/athlete-video-thumbnail.png';
import SSI from './assets/img/success-stories-img-01.png';
import CSFT from './assets/img/cal-state-fullerton-titans-logo.png';
import AB from './assets/img/andrew-beinbrink.png';
import DK from './assets/img/dani-kabat.png';
import MVT from './assets/img/meet-video-thumbnail.png';
import UODLogo from './assets/img/university-of-delaware-logo.png';
import OwlCarousel from 'react-owl-carousel';
import './assets/css/lading-page.scss';
import { imageDownloadUrl } from '../../../config';
import { useSelector } from 'react-redux';
import {
  lpSectionCall,
  lpSuccessCall,
  lpTypeCall,
} from '../../common/landingPageApi';
import { VideoPop } from './common/videoPopup';
import SuccessStories from '../../common/SuccessStories';
const helpSlide = {
  loop: true,
  nav: true,
  dots: false,
  items: 1,
};

const AboutLP = (props) => {
  const [headerType, setHeaderType] = useState(true),
    [aboutVideo, setAboutVideo] = useState([]),
    [vidID, setVidID] = useState(null),
    [isVideoPop, setIsVideoPop] = useState(false),
    [lpDataMission, setLpDataMission] = useState({}),
    [lpDataCoa, setLpDataCoa] = useState({}),
    [lpDataManage, setLpDataManage] = useState({}),
    [lpSuccessData, setLpSuccessData] = useState([]),
    store = useSelector((store) => store),
    isToken = props?.match?.params?.isToken == 1 ? true : false;
  useEffect(() => {
    getStatus(props);
    //for scrolling the page on top
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);
  useEffect(async () => {
    const type = !!localStorage.getItem('lpType')
        ? localStorage.getItem('lpType')
        : !!store?.common?.landingPageType?.lpType
        ? store?.common?.landingPageType?.lpType
        : null,
      lpRes = await lpTypeCall(type),
      lpSuccess = await lpSuccessCall();
    setLpSuccessData(lpSuccess);
    setAboutVideo(lpRes);
    getContent();
  }, []);
  const getContent = async () => {
    const apiCall = ['ourmission', 'coaching', 'management'];
    apiCall?.map(async (indxName, idx) => {
      if (indxName == 'ourmission') {
        const _resData = await lpSectionCall(indxName);
        setLpDataMission(_resData);
        // cloneData['ourmission'] = _resData
      } else if (indxName == 'coaching') {
        const _resData = await lpSectionCall(indxName);
        setLpDataCoa(_resData);
        // cloneData['coaching'] = _resData
      } else if (indxName == 'management') {
        const _resData = await lpSectionCall(indxName);
        setLpDataManage(_resData);
        // cloneData['management'] = _resData
      }
    });
  };
  const getStatus = (props) => {
      if (!!getAuthToken() || isToken) {
        setHeaderType(false);
      }
    },
    openModal = (type) => {
      const getVid =
        aboutVideo?.length > 0
          ? aboutVideo?.filter((el) => el?.title == type)
          : null;
      setVidID(getVid?.length > 0 ? getVid?.[0]?.videolink ?? '' : getVid);
      setIsVideoPop(true);
    };
  return (
    <>
      {headerType ? <ClaimProfileHeader /> : <DashboardHeader />}
      <section className="banner-lp-sec">
        <div className="container">
          <div className="banner-flexbox">
            <div className="banner-content">
              <h1 className="title">We Are</h1>
              <div className="brand-logo">
                <a href={void 0}>
                  <img src={logo} alt="SportsForce" />
                </a>
              </div>
              <div className="short-text">
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took
                </p>
              </div>
              <div className="button-wrap">
                <a className="btn" href={void 0}>
                  Join Waiting List
                </a>
              </div>
            </div>
            <div className="video-box-wrap">
              <div className="video-img">
                <img src={AVT} alt="" />
                <div className="video-play-button">
                  <div
                    className="video-play-inner"
                    onClick={() => openModal('joinwaitinglist')}
                  >
                    <button className="play-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.191"
                        height="22.92"
                        viewBox="0 0 20.191 22.92"
                      >
                        <path
                          id="play"
                          d="M9.086,27.982a2.255,2.255,0,0,1-1.1-.291,2.442,2.442,0,0,1-1.228-2.138V7.492A2.442,2.442,0,0,1,7.982,5.353a2.242,2.242,0,0,1,2.257.028l15.641,9.24a2.226,2.226,0,0,1,0,3.8L10.236,27.663a2.262,2.262,0,0,1-1.151.319Z"
                          transform="translate(-6.754 -5.063)"
                          fill="#c0321f"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-mission-sec">
        <div className="container">
          <h3 className="sec-title">Our Mission</h3>
          <div className="short-text">
            <p>
              {lpDataMission?.content ?? 'No Data'}
              {/*Our mission is to maximize college offers and scholarship opportunities for our families.*/}
              {/*Founded by former NCAA college student-athletes and coaches, we help student-athletes find*/}
              {/*the college that's the best all-around fit for them and their family. We evaluate and only*/}
              {/*partner with qualified student-athletes to execute a personalized and comprehensive college*/}
              {/*recruiting game plan.*/}
            </p>
          </div>
        </div>
      </section>

      <section className="about-mission-sec bg-gray">
        <div className="container">
          <div className="flexbox">
            <div className="content-box">
              <h3 className="sec-title">Meet our CEO</h3>
              <h4 className="postion-info">
                Andrew Beinbrink <span>CEO & Founder</span>
              </h4>
              <div className="short-text">
                <p>
                  Over the last 10+ years we have helped over 1,000
                  student-athletes and families successfully navigate the
                  college recruiting and athletic scholarship process while
                  saving families on average, a reported $80,000 in college
                  expenses.
                </p>
              </div>
            </div>
            <div className="video-box-wrap">
              <div className="video-img">
                <img src={MVT} alt="" />
                <div className="video-play-button">
                  <div className="video-play-inner">
                    <button
                      className="play-btn"
                      onClick={() => openModal('meetourceo')}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.191"
                        height="22.92"
                        viewBox="0 0 20.191 22.92"
                      >
                        <path
                          id="play"
                          d="M9.086,27.982a2.255,2.255,0,0,1-1.1-.291,2.442,2.442,0,0,1-1.228-2.138V7.492A2.442,2.442,0,0,1,7.982,5.353a2.242,2.242,0,0,1,2.257.028l15.641,9.24a2.226,2.226,0,0,1,0,3.8L10.236,27.663a2.262,2.262,0,0,1-1.151.319Z"
                          transform="translate(-6.754 -5.063)"
                          fill="#c0321f"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="lp-team-sec">
        <div className="container">
          <div className="top-block">
            <h2 className="title">Management Team</h2>
            <p>
              {lpDataManage?.content?.[0]?.content ?? ''}
              {/*Over the last 10+ years we have helped over 1,000 student-athletes and families successfully*/}
              {/*navigate the college recruiting and athletic scholarship process while saving families on*/}
              {/*average, a reported $80,000 in college expenses.*/}
            </p>
          </div>
          <div className="team-list">
            {lpDataManage?.team?.length > 0
              ? lpDataManage?.team?.map((mane, idx) => {
                  return (
                    <div className="item">
                      <div className="team-infobox">
                        <div className="pic-box">
                          <img
                            src={
                              !!mane?.profileimage
                                ? `${imageDownloadUrl}/${mane?.profileimage}`
                                : AB
                            }
                            alt="Andrew Beinbrink"
                          />
                          <span className="social-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                            >
                              <g
                                id="Layer_2"
                                data-name="Layer 2"
                                transform="translate(-0.077 -0.076)"
                              >
                                <g
                                  id="_02.twitter"
                                  data-name="02.twitter"
                                  transform="translate(0.074 0.074)"
                                >
                                  <circle
                                    id="background"
                                    cx="15"
                                    cy="15"
                                    r="15"
                                    transform="translate(0.003 0.002)"
                                    fill="#00a6de"
                                  />
                                  <path
                                    id="icon"
                                    d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                    transform="translate(-30.461 -35.611)"
                                    fill="#fff"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                        </div>
                        <div className="detail-info">
                          <h4 className="name">
                            {mane?.fullname ?? ''}
                            <span className="position">
                              {mane?.designation ?? ''}
                            </span>
                          </h4>
                          <div className="university-logo">
                            <img
                              src={
                                !!mane?.collegelogo
                                  ? `${imageDownloadUrl}/${mane?.collegelogo}`
                                  : UODLogo
                              }
                              alt=""
                            />
                          </div>
                          <p>
                            {mane?.collegename ?? ''}
                            {/*University Of Delaware <br/>Baseball*/}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ''}
            {/*<div className="item"`>*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={CG} alt="Clare Gaskell"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Clare Gaskell <span*/}
            {/*                className="position">Insights Analyst</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={MB} alt="Mallory Bory"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Mallory Bory <span*/}
            {/*                className="position">Creative Director</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={DK} alt="Dani Kabat"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Dani Kabat <span className="position">CS Specialist</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={ANDY} alt="Andy Breen"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Andy Breen <span*/}
            {/*                className="position">Sr. Digital Designer</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>

      <section className="lp-team-sec bg-gray">
        <div className="container">
          <div className="top-block">
            <h2 className="title">Coaching Staff</h2>
            <p>
              {lpDataCoa?.content?.[0]?.content ?? ''}
              {/*Over the last 10+ years we have helped over 1,000 student-athletes and families successfully*/}
              {/*navigate the college recruiting and athletic scholarship process while saving families on*/}
              {/*average, a reported $80,000 in college expenses.*/}
            </p>
          </div>
          <div className="team-list">
            {lpDataCoa?.team?.length > 0
              ? lpDataCoa?.team?.map((mane, idx) => {
                  return (
                    <div className="item">
                      <div className="team-infobox">
                        <div className="pic-box">
                          <img
                            src={
                              !!mane?.profileimage
                                ? `${imageDownloadUrl}/${mane?.profileimage}`
                                : DK
                            }
                            alt="Andrew Beinbrink"
                          />
                          <span className="social-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                            >
                              <g
                                id="Layer_2"
                                data-name="Layer 2"
                                transform="translate(-0.077 -0.076)"
                              >
                                <g
                                  id="_02.twitter"
                                  data-name="02.twitter"
                                  transform="translate(0.074 0.074)"
                                >
                                  <circle
                                    id="background"
                                    cx="15"
                                    cy="15"
                                    r="15"
                                    transform="translate(0.003 0.002)"
                                    fill="#00a6de"
                                  />
                                  <path
                                    id="icon"
                                    d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"
                                    transform="translate(-30.461 -35.611)"
                                    fill="#fff"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                        </div>
                        <div className="detail-info">
                          <h4 className="name">
                            {mane?.fullname ?? ''}
                            <span className="position">
                              {mane?.designation ?? ''}
                            </span>
                          </h4>
                          <div className="university-logo">
                            <img
                              src={
                                !!mane?.collegelogo
                                  ? `${imageDownloadUrl}/${mane?.collegelogo}`
                                  : UODLogo
                              }
                              alt=""
                            />
                          </div>
                          <p>
                            {mane?.collegename ?? ''}
                            {/*University Of Delaware <br/>Baseball*/}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ''}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={DK} alt="Dani Kabat"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Dani Kabat <span className="position">CS Specialist</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={ANDY} alt="Andy Breen"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Andy Breen <span*/}
            {/*                className="position">Sr. Digital Designer</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={AB} alt="Andrew Beinbrink"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Andrew Beinbrink <span*/}
            {/*                className="position">Founder & CEO</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={CG} alt="Clare Gaskell"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Clare Gaskell <span*/}
            {/*                className="position">Insights Analyst</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={MB} alt="Mallory Bory"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Mallory Bory <span*/}
            {/*                className="position">Creative Director</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={DK} alt="Dani Kabat"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Dani Kabat <span className="position">CS Specialist</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={ANDY} alt="Andy Breen"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Andy Breen <span*/}
            {/*                className="position">Sr. Digital Designer</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={AB} alt="Andrew Beinbrink"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Andrew Beinbrink <span*/}
            {/*                className="position">Founder & CEO</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*    <div className="team-infobox">*/}
            {/*        <div className="pic-box">*/}
            {/*            <img src={CG} alt="Clare Gaskell"/>*/}
            {/*            <span className="social-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*/}
            {/*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*/}
            {/*                <circle id="background" cx="15" cy="15" r="15"*/}
            {/*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*/}
            {/*                <path id="icon"*/}
            {/*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*/}
            {/*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*/}
            {/*              </g>*/}
            {/*            </g>*/}
            {/*          </svg>*/}
            {/*    </span>*/}
            {/*        </div>*/}
            {/*        <div className="detail-info">*/}
            {/*            <h4 className="name">Clare Gaskell <span*/}
            {/*                className="position">Insights Analyst</span></h4>*/}
            {/*            <div className="university-logo">*/}
            {/*                <img src={UODLogo} alt=""/>*/}
            {/*            </div>*/}
            {/*            <p>University Of Delaware <br/>Baseball</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*/!*<div className="item">*!/*/}
            {/*/!*    <div className="team-infobox">*!/*/}
            {/*//         <div className="pic-box">*/}
            {/*//             <img src={MB} alt="Mallory Bory"/>*/}
            {/*//             <span className="social-icon">*/}
            {/*//         <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">*/}
            {/*/!*            <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.076)">*!/*/}
            {/*/!*              <g id="_02.twitter" data-name="02.twitter" transform="translate(0.074 0.074)">*!/*/}
            {/*/!*                <circle id="background" cx="15" cy="15" r="15"*!/*/}
            {/*/!*                        transform="translate(0.003 0.002)" fill="#00a6de"/>*!/*/}
            {/*/!*                <path id="icon"*!/*/}
            {/*/!*                      d="M52.9,46.1a6.3,6.3,0,0,1-1.278,1.4.546.546,0,0,0-.2.426v.049a8.95,8.95,0,0,1-.577,3.114,9.121,9.121,0,0,1-1.7,2.847,8.391,8.391,0,0,1-3.682,2.433,9.209,9.209,0,0,1-2.894.45,8.835,8.835,0,0,1-4.437-1.2.255.255,0,0,1-.123-.283.245.245,0,0,1,.24-.185h.373a5.938,5.938,0,0,0,3.326-1.009A3.224,3.224,0,0,1,39.4,51.956a.169.169,0,0,1,.2-.218,2.961,2.961,0,0,0,.542.051H40.2a3.226,3.226,0,0,1-1.879-2.967.169.169,0,0,1,.249-.147,2.836,2.836,0,0,0,.734.285,3.224,3.224,0,0,1-.52-3.912.169.169,0,0,1,.277-.024,8.43,8.43,0,0,0,5.795,3.1h.016a.122.122,0,0,0,.118-.132,3.409,3.409,0,0,1,.075-1.178,3.124,3.124,0,0,1,2.1-2.246,3.454,3.454,0,0,1,1.019-.157,3.213,3.213,0,0,1,2.129.8.416.416,0,0,0,.277.106.422.422,0,0,0,.1-.014,5.891,5.891,0,0,0,1.571-.65.167.167,0,0,1,.245.2,3.187,3.187,0,0,1-.846,1.349,5.93,5.93,0,0,0,1.029-.348.169.169,0,0,1,.206.243Z"*!/*/}
            {/*/!*                      transform="translate(-30.461 -35.611)" fill="#fff"/>*!/*/}
            {/*/!*              </g>*!/*/}
            {/*/!*            </g>*!/*/}
            {/*/!*          </svg>*!/*/}
            {/*/!*    </span>*!/*/}
            {/*/!*        </div>*!/*/}
            {/*/!*        <div className="detail-info">*!/*/}
            {/*/!*            <h4 className="name">Mallory Bory <span*!/*/}
            {/*/!*                className="position">Creative Director</span></h4>*!/*/}
            {/*/!*            <div className="university-logo">*!/*/}
            {/*/!*                <img src={UODLogo} alt=""/>*!/*/}
            {/*/!*            </div>*!/*/}
            {/*/!*            <p>University Of Delaware <br/>Baseball</p>*!/*/}
            {/*/!*        </div>*!/*/}
            {/*/!*    </div>*!/*/}
            {/*/!*</div>*!/*/}
          </div>
        </div>
      </section>

      <SuccessStories />

      <section className="lp-map-sec">
        <div className="container">
          <h3 className="sec-title">Map of CAA Player Commitments</h3>
        </div>
        <div className="map-box">
          <img
            src={`${imageDownloadUrl}/about-map-img.png`}
            alt="Map of CAA Player Commitments"
          />
        </div>
      </section>
      <VideoPop
        isTestSingle={isVideoPop}
        setIsTestSingle={() => setIsVideoPop(false)}
        vidID={vidID}
      />
    </>
  );
};
export default AboutLP;
