import closeGrayIcon from "../assets/img/close-gray-icon.svg";
import trashCircleIcon from "../assets/img/trash-circle-icon.svg";

const DeleteVideoPhotoPopup = (props) => {
  return (
    <div
      className="modal fade common-modal trash-modal show"
      id="ReferenceTrashModal"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" onClick={props.onClose}>
              <img src={closeGrayIcon} alt="close Gray Icon" />
            </button>
            <div className="icon-box-first">
              <img src={trashCircleIcon} alt="trash Circle Icon" />
            </div>
            <div className="content-box">
              {/*<h4>Are you sure you want to delete this? </h4>*/}
              <h4>Deleting this Photo / Video will also delete the respective post on Dashboard.</h4>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              onClick={props.onDeleteClick}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn gray"
              data-dismiss="modal"
              onClick={props.onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteVideoPhotoPopup;