import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../services/httpService";
import messagePopup from "../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
   RACIAL_ETHNIC_HERITAGE_LIST_START: "RACIAL_ETHNIC_HERITAGE_LIST_START",
   RACIAL_ETHNIC_HERITAGE_LIST_SUCCESS: "RACIAL_ETHNIC_HERITAGE_LIST_SUCCESS",
   RACIAL_ETHNIC_HERITAGE_LIST_FAILED: "RACIAL_ETHNIC_HERITAGE_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const racialEthnicHeritageListStart = (data) => ({
    type: actionType.RACIAL_ETHNIC_HERITAGE_LIST_START,
    payload: data,
});

export const racialEthnicHeritageListSuccess = (data) => ({
    type: actionType.RACIAL_ETHNIC_HERITAGE_LIST_SUCCESS,
    payload: data,
});

export const racialEthnicHeritageListFailed = (data) => ({
    type: actionType.RACIAL_ETHNIC_HERITAGE_LIST_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function racialEthnicHeritageListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.RACIAL_ETHNIC_HERITAGE_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.RACIAL_ETHNIC_HERITAGE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.RACIAL_ETHNIC_HERITAGE_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* racialEthnicHeritageListSaga(action) {
    try {
        // const response = yield fetchGraphMethod(action.payload.query, null, true);
        const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
        if (response && response.status === 200) {
            const allcommitmentType =  response.data.data.allRacialethnicheritages.Racialethnicheritages
            yield put(racialEthnicHeritageListSuccess(allcommitmentType));
        } else {
            messagePopup("", "Annual Household Income List failed", "error");
            yield put(racialEthnicHeritageListFailed("Racial Ethnic Heritage List failed"));
        }
    } catch (error) {
        messagePopup("", "Annual Household Income", "error");
        yield put(racialEthnicHeritageListFailed("Racial Ethnic Heritage List failed"));
    }
}

export function* watchRacialEthnicHeritageListSaga() {
    yield takeEvery(actionType.RACIAL_ETHNIC_HERITAGE_LIST_START, racialEthnicHeritageListSaga);
}
