import { combineReducers } from "redux";
import rootOnBoardingReducers from "./onBoarding/rootOnBoardingReducers";
import rootDashboardReducer from "./dashboard/rootDashboardReducers";
import rootProfileReducer from "./profile/rootProfileReducer";
import rootEditUserProfileReducers from "./dashboard/editUserProfile/rootEditUserProfileReducers";
import rootAccountReducer from "./dashboard/account/rootAccountReducer";
import rootFollowerAthleteReducer from "./dashboard/activity/rootFollowerAthleteReducer";
export default combineReducers({
  onBoarding: rootOnBoardingReducers,
  dashboard: rootDashboardReducer,
  profile: rootProfileReducer,
  editUserProfileData: rootEditUserProfileReducers,
  account: rootAccountReducer,
  activity: rootFollowerAthleteReducer
});
