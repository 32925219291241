import React, { useState, useEffect } from "react";
import "../../../../../assets/css/stylesheet.scss";
import "../assets/css/onBoardPosition.scss";
import "../assets/css/onBoardSteeper.scss";
import { Link } from "react-router-dom";
import {
  getAllPrimaryPositionsQuery,
  getAllSecondaryPositionsQuery,
} from "./PositionsQuery";
import { connect } from "react-redux";
import { primaryListStart } from "../../../../../redux/athlete/onBoarding/primaryPositionList";
import { secondaryListStart } from "../../../../../redux/athlete/onBoarding/secondaryPositionList";
import {
  getOnboardingStart,
  saveOnboardingStart,
} from "../../../../../redux/athlete/onBoarding/onboarding";
import { getOnboardingQuery, saveOnboardingQuery } from "../OnboardingQuery";
import { getUserInfo } from "../../../../../services/authService";
import BackdropLoader from "../../../../common/Loader";
import usePrevious from "../../../../../utils/usePrevious";
import Stepper from "../Stepper";
import ParentSkip from "../SkitPopup/ParentSkip";
import AthleteSkip from "../SkitPopup/AthleteSkip";
import { Dialog } from "@mui/material";
import messagePopup from "../../../../../utils/messagePopup";

const stepperData = [
  { className: "passed", link: "/onboarding/profile" },
  { className: "passed", link: "/onboarding/dob" },
  { className: "active", link: "/onboarding/positions" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  { className: "" },
  // { className: "" },
];

function Positions(props) {
  const [fields, setFields] = useState({});
  const prevOnboardData = usePrevious(props.onboarding.data);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    if (false && props.onboarding?.data?.primaryposition) setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const routeNextPage = () => {
    props.history.push("/onboarding/physicalMeasurements");
    if (fields?.primaryPosition) {
      let localData = JSON.parse(localStorage.getItem("user"));
      localData.primaryPosition = fields?.primaryPosition;
      // localData.secondaryPosition = action.payload.variables.obj.secondaryposition
      localStorage.setItem("user", JSON.stringify(localData));
    }
  };
  useEffect(() => {
    setFields({});
    props.primaryListStart({
      query: getAllPrimaryPositionsQuery,
      variables: null,
    });
    props.secondaryListStart({
      query: getAllSecondaryPositionsQuery,
      variables: null,
    });
    props.getOnboardingStart({
      query: getOnboardingQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.onboarding.data && prevOnboardData !== props.onboarding.data) {
      if (props.onboarding.data.id) {
        const fieldT = fields;
        const { id, primaryposition, secondaryposition } =
          props.onboarding.data;
        fieldT["id"] = id;
        fieldT["primaryPosition"] = primaryposition;
        // fieldT["secondaryPosition"] = secondaryposition;
        setFields({ ...fieldT });
      } else {
        props.history.push("/onboarding/profile");
      }
    }
  }, [props]);

  const handleNext = () => {
    if (fields && fields.primaryPosition) {
      props.saveOnboardingStart({
        query: saveOnboardingQuery,
        variables: {
          obj: {
            id: fields.id ? fields.id : 0,
            userid: getUserInfo() ? getUserInfo().id : 0,
            primaryposition: fields.primaryPosition
              ? +fields.primaryPosition
              : null,
            // secondaryposition: fields.secondaryPosition
            //   ? +fields.secondaryPosition
            //   : null,
          },
        },
        nextScreen: routeNextPage,
      });
    } else {
      messagePopup("", "Please select your primary position.", "error");
    }
  };

  const handleChange = (field, e) => {
    let fieldsT = fields;
    fieldsT[field] = e.target.value;
    setFields({
      ...fieldsT,
    });
  };
  return (
    <div>
      <BackdropLoader
        open={
          props.primaryPositionList.loading ||
          // props.secondaryPositionList.loading ||
          props.onboarding.loading
        }
      />
      <div className="onBoardBatThrowSection">
        <div className="batThrowBlock">
          <div className="stepper">
            <Stepper stepperName="positions" stepperData={stepperData} />
          </div>
          <div className="formSection">
            <h1 className="heading">Your Positions</h1>
            <div className="fieldBox">
              <label>
                <span>What's your primary position? </span>
                <span className="checkIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <style />
                    </defs>
                    <g transform="translate(-649.111 -507.111)">
                      <path
                        className="a"
                        d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                        transform="translate(649.111 507.111)"
                      />
                      <path
                        className="b"
                        d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                        transform="translate(511.436 348.256)"
                      />
                    </g>
                  </svg>
                </span>
              </label>
              <select
                id="primarypositions"
                label="Select position"
                value={fields.primaryPosition || ""}
                onChange={(e) => handleChange("primaryPosition", e)}
              >
                <option value="">Select position</option>
                {props.primaryPositionList.data &&
                  props.primaryPositionList.data.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.shortform}
                    </option>
                  ))}
              </select>
            </div>
            {/* <div className="fieldBox">
              <label>What's your secondary position?</label>
              <select
                id="secondarypositions"
                label="Select position"
                value={fields.secondaryPosition || ""}
                onChange={(e) => handleChange("secondaryPosition", e)}
              >
                <option value="">Select position</option>
                {props.secondaryPositionList.data &&
                  props.secondaryPositionList.data.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.selectionfield}
                    </option>
                  ))}
              </select>
            </div> */}
            <p className="skip">You can add more positions later.</p>
            <p className="skip">
              Don't know?{" "}
              <span className="sikpBtnBox">
                <Link className="skipit-btn" to="#" onClick={handleClickOpen}>
                  Skip it
                </Link>
              </span>
            </p>
          </div>
          <div className="continueBtnBox">
            <Link to="#" onClick={(e) => handleNext(e)}>
              Next
            </Link>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="parentSkipModal matDialogCustom"
        onClose={(event, reason)=> {
          if (reason == "backdropClick" || reason == "escapeKeyDown") {
            handleClose()
          }
        }}
      >
        {getUserInfo() ? (
          getUserInfo().role === "GUARDIAN" ? (
            <ParentSkip onClose={handleClose} nextRoute={routeNextPage} />
          ) : (
            <AthleteSkip onClose={handleClose} nextRoute={routeNextPage} />
          )
        ) : null}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    primaryPositionList: state.athlete.onBoarding.primaryPositionList,
    secondaryPositionList: state.athlete.onBoarding.secondaryPositionList,
    onboarding: state.athlete.onBoarding.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    primaryListStart: (data) => dispatch(primaryListStart(data)),
    secondaryListStart: (data) => dispatch(secondaryListStart(data)),
    getOnboardingStart: (data) => dispatch(getOnboardingStart(data)),
    saveOnboardingStart: (data) => dispatch(saveOnboardingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Positions);
