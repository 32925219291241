import React, { useEffect, useState} from 'react'
import ClaimProfileHeader from "../auth/claimProfile/claimProfileStep1/ClaimProfileHeader";
import DashboardHeader from "../../layouts/header/DashboardHeader";
import {getAuthToken} from "../../../services/authService";
import {imageDownloadUrl} from "../../../config";
import {Dialog} from "@mui/material";
import './assets/css/lading-page.scss'
import {VideoPop} from "./common/videoPopup";
import {useSelector} from "react-redux";
import {lpTypeCall} from "../../common/landingPageApi";
import {fetchMethod} from "../../../services/httpService";

const helpSlide = {
    loop: true,
    nav: true,
    dots: false,
    items: 1,
};

const TestinomialsLP = (props) =>{
    const [headerType, setHeaderType] = useState(true),
          [isVideoPop, setIsVideoPop] = useState(false),
          [testVideo, setTestVideo] = useState([]),
          [vidID, setVidID] = useState(null),
          store = useSelector(store => store),
          isToken = props?.match?.params?.isToken == 1 ? true : false;
    useEffect(()=>{
        getStatus(props)
        //for scrolling the page on top
        setTimeout(()=>{
            window.scrollTo(0,0)
        },1000)
    },[])
    useEffect(async ()=>{
        const type = !!localStorage.getItem("lpType") ? localStorage.getItem("lpType") :
            !!store?.common?.landingPageType?.lpType ? store?.common?.landingPageType?.lpType : null,
            lpRes = await lpTypeCall(type)
        setTestVideo(lpRes)
    },[])
    const getStatus = (props) =>{
        if (!!getAuthToken() || isToken){
            setHeaderType(false)
        }
    },
        openModal = async (type, isVidLink) =>{
            if(isVidLink){
                    setVidID(type?.videolink)
            }else{
                const getVid = testVideo?.length > 0 ? testVideo?.filter(el => el?.title == type) : null
                setVidID(getVid?.length > 0 ? getVid?.[0]?.videolink ?? "" : getVid)
            }
            setIsVideoPop(true)
        }
    return(
        <>
            {
                headerType ?
                    <ClaimProfileHeader/>
                    :
                    <DashboardHeader/>
            }
            <section className="testimonial-main-sec" style={{backgroundImage:`url(${imageDownloadUrl}/Testimonial Banner.png)`}}>
                <div className="container">
                    <div className="content-box">
                        <div className="banner-content">
                            <div className="text-box">
                                <h1 className="title">Club Staff's Stories</h1>
                                <div className="short-text">
                                    <p>Interviews with club staff about how they empower their student-athletes to
                                        pursue their dreams using SportsForce.</p>
                                </div>
                            </div>
                        </div>
                        <div className="video-box-wrap">
                            <div className="video-img">
                                <img src={`${imageDownloadUrl}/testimonial-main-video-thum.png`} alt=""/>
                                <div className="video-play-button" data-toggle="modal" data-target="#VideoTopModal">
                                    <div className="video-play-inner">
                                        <button className="play-btn"
                                                 type={'button'}
                                                onClick={(e)=>openModal("clubstafstories",false)
                                                }
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.191" height="22.92"
                                                 viewBox="0 0 20.191 22.92">
                                                <path id="play"
                                                      d="M9.086,27.982a2.255,2.255,0,0,1-1.1-.291,2.442,2.442,0,0,1-1.228-2.138V7.492A2.442,2.442,0,0,1,7.982,5.353a2.242,2.242,0,0,1,2.257.028l15.641,9.24a2.226,2.226,0,0,1,0,3.8L10.236,27.663a2.262,2.262,0,0,1-1.151.319Z"
                                                      transform="translate(-6.754 -5.063)" fill="#c0321f"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="common-testimonial-sec">
                <div className="container">
                    <div className="top-block">
                        <h2 className="title">Recruiting During Covid</h2>
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                    <ul className="videos-listing">
                        {testVideo?.length > 0 && testVideo?.map((tes, idx)=>{
                            if(tes?.title == "recruitingduringcovid"){
                                return(
                                    <li className="video-box"
                                        // data-toggle="modal"
                                        // data-target="#VEDIOMAINModal"
                                    >
                                        <div className="img-box"
                                             onClick={()=>openModal(tes,true)}
                                             data-id="aqz-KE-bpKQ"
                                            // href="#aqz-KE-bpKQ"
                                        >
                                            <video poster={`${imageDownloadUrl}/recruiting-during-covid-video-thum01.png`}>
                                                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>
                                                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>
                                            </video>
                                            <div className="video-play-button">
                                                <div className="video-play-inner">
                                                    <button>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="62.25" height="62.25"
                                                             viewBox="0 0 62.25 62.25">
                                                            <g id="play-circle-outline" transform="translate(-3.375 -3.375)">
                                                                <path id="Path_84041" data-name="Path 84041"
                                                                      d="M64.5,34.5a30,30,0,1,0-30,30A30.008,30.008,0,0,0,64.5,34.5Z"
                                                                      transform="translate(0 0)" fill="none" stroke="#fff"
                                                                      strokeWidth="2.25"/>
                                                                <path id="Path_84042" data-name="Path 84042"
                                                                      d="M16.644,37.44,34.752,26.5a1.724,1.724,0,0,0,0-2.943L16.644,12.619a1.706,1.706,0,0,0-2.581,1.474V35.968a1.706,1.706,0,0,0,2.581,1.474Z"
                                                                      transform="translate(11.541 9.47)" fill="#fff"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <span className="video-times">1 MIN</span>
                                        </div>
                                        <div className="video-detail-box">Why Use SportsForce?</div>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                    <div className="btn-wrap">
                        <button className="btn lightblue">Load more</button>
                    </div>
                </div>
            </section>

            <section className="get-started-sec mb-2">
                <div className="container">
                    <h3 className="sec-title">Ready to take your club to the next level?</h3>
                    <div className="short-text">
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                    <div className="button-wrap">
                        <a className="btn" href={void 0}>Join waiting list</a>
                    </div>
                </div>
            </section>

            <section className="common-testimonial-sec">
                <div className="container">
                    <div className="top-block">
                        <h2 className="title">Full Interview</h2>
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                    <ul className="videos-listing">
                        {testVideo?.length > 0 && testVideo?.map((tes, idx)=>{
                            if(tes?.title == "fullinterview"){
                                return(
                                    <li className="video-box"
                                        // data-toggle="modal"
                                        // data-target="#VEDIOMAINModal"
                                    >
                                        <div className="img-box"
                                             onClick={()=>openModal(tes,true)}
                                             data-id="aqz-KE-bpKQ"
                                            // href="#aqz-KE-bpKQ"
                                        >
                                            <video poster={`${imageDownloadUrl}/recruiting-during-covid-video-thum02.png`}>
                                                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>
                                                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>
                                            </video>
                                            <div className="video-play-button">
                                                <div className="video-play-inner">
                                                    <button>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="62.25" height="62.25"
                                                             viewBox="0 0 62.25 62.25">
                                                            <g id="play-circle-outline" transform="translate(-3.375 -3.375)">
                                                                <path id="Path_84041" data-name="Path 84041"
                                                                      d="M64.5,34.5a30,30,0,1,0-30,30A30.008,30.008,0,0,0,64.5,34.5Z"
                                                                      transform="translate(0 0)" fill="none" stroke="#fff"
                                                                      strokeWidth="2.25"/>
                                                                <path id="Path_84042" data-name="Path 84042"
                                                                      d="M16.644,37.44,34.752,26.5a1.724,1.724,0,0,0,0-2.943L16.644,12.619a1.706,1.706,0,0,0-2.581,1.474V35.968a1.706,1.706,0,0,0,2.581,1.474Z"
                                                                      transform="translate(11.541 9.47)" fill="#fff"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <span className="video-times">1 MIN</span>
                                        </div>
                                        <div className="video-detail-box">Why Use SportsForce?</div>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                    <div className="btn-wrap">
                        <button className="btn lightblue">Load more</button>
                    </div>
                </div>
            </section>

            <section className="common-testimonial-sec bg-gray">
                <div className="container">
                    <div className="top-block">
                        <h2 className="title">Demostrating Value to Parents</h2>
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                    <ul className="videos-listing">
                        {testVideo?.length > 0 && testVideo?.map((tes, idx)=>{
                            if(tes?.title == "ValuetoParents"){
                                return(
                                    <li className="video-box"
                                        // data-toggle="modal"
                                        // data-target="#VEDIOMAINModal"
                                    >
                                        <div className="img-box"
                                             onClick={()=>openModal(tes,true)}
                                             data-id="aqz-KE-bpKQ"
                                            // href="#aqz-KE-bpKQ"
                                        >
                                            <video poster={`${imageDownloadUrl}/recruiting-during-covid-video-thum03.png`}>
                                                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/mp4"/>
                                                <source src="https://www.youtube.com/embed/aqz-KE-bpKQ" type="video/ogg"/>
                                            </video>
                                            <div className="video-play-button">
                                                <div className="video-play-inner">
                                                    <button>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="62.25" height="62.25"
                                                             viewBox="0 0 62.25 62.25">
                                                            <g id="play-circle-outline" transform="translate(-3.375 -3.375)">
                                                                <path id="Path_84041" data-name="Path 84041"
                                                                      d="M64.5,34.5a30,30,0,1,0-30,30A30.008,30.008,0,0,0,64.5,34.5Z"
                                                                      transform="translate(0 0)" fill="none" stroke="#fff"
                                                                      strokeWidth="2.25"/>
                                                                <path id="Path_84042" data-name="Path 84042"
                                                                      d="M16.644,37.44,34.752,26.5a1.724,1.724,0,0,0,0-2.943L16.644,12.619a1.706,1.706,0,0,0-2.581,1.474V35.968a1.706,1.706,0,0,0,2.581,1.474Z"
                                                                      transform="translate(11.541 9.47)" fill="#fff"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <span className="video-times">1 MIN</span>
                                        </div>
                                        <div className="video-detail-box">Why Use SportsForce?</div>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                    <div className="btn-wrap">
                        <button type={"button"} className="btn lightblue">Load more</button>
                    </div>
                </div>
            </section>
            <VideoPop
            isTestSingle={isVideoPop}
            setIsTestSingle={()=>setIsVideoPop(false)}
            vidID={vidID}
            />
        </>
    )
}
export default TestinomialsLP
