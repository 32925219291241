import { put, takeEvery } from "redux-saga/effects";
import messagePopup from "../../../../utils/messagePopup";
import history from "../../../../services/history";
import { fetchGraphMethod } from "../../../../services/httpService";

/* ======================================= Actions Type ==========================================*/
export const actionType = {
  COLLEGE_ADMISSIONS_LIST_START: "COLLEGE_ADMISSIONS_LIST_START",
  COLLEGE_ADMISSIONS_SUCCESS: "COLLEGE_ADMISSIONS_SUCCESS",
  COLLEGE_ADMISSIONS_FAILURE: "COLLEGE_ADMISSIONS_FAILURE",
};

/* ======================================= Actions Creator ==========================================*/

export const collegeAdmissionsListStart = (data) => ({
  type: actionType.COLLEGE_ADMISSIONS_LIST_START,
  payload: data,
});

export const collegeAdmissionsSuccess = (data) => ({  
    type: actionType.COLLEGE_ADMISSIONS_SUCCESS,
    payload: data,
});
  
export const collegeAdmissionsFailure = (data) => ({
    type: actionType.COLLEGE_ADMISSIONS_FAILURE,
    payload: data
});

/* ======================================= Reducer ==========================================*/
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};
export default function collegeAdmissionsReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.COLLEGE_ADMISSIONS_LIST_START:
      return { ...state, loading: true };
    
    case actionType.COLLEGE_ADMISSIONS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };

    case actionType.COLLEGE_ADMISSIONS_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return { ...state };
  }
}

/* ======================================= Side Effects ==========================================*/


function* getCollegeAdmissionsSaga(action) {
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true);
        if(response.status === 200){
            const allCollegeAdmissions = response.data?.data?.allCollegeadmissions?.Collegeadmissions;

            yield put(collegeAdmissionsSuccess(allCollegeAdmissions));
        } else {
          messagePopup('', 'College Admissions Data Failed', 'error');
          yield put(collegeAdmissionsFailure('Failed, Error occured'))
        }
       } catch (error) {
         messagePopup('', 'College Admissions Data Failed', 'error');
         yield put(collegeAdmissionsFailure('Failed, Error occured'))
       }
}

export function* watchCollegeAdmissionsSaga() {
  yield takeEvery(actionType.COLLEGE_ADMISSIONS_LIST_START, getCollegeAdmissionsSaga);
}

