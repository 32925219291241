import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  CHECK_FOLLOW_STATUS_START: "CHECK_FOLLOW_STATUS_START",
  CHECK_FOLLOW_STATUS_SUCCESS: "CHECK_FOLLOW_STATUS_SUCCESS",
  CHECK_FOLLOW_STATUS_FAILED: "CHECK_FOLLOW_STATUS_FAILED"
};

// ============================================ Actions ========================================================
export const checkFollowDropdownStart = data => ({
  type: actionType.CHECK_FOLLOW_STATUS_START,
  payload: data
});

export const checkFollowDropdownSuccess = data => ({
  type: actionType.CHECK_FOLLOW_STATUS_SUCCESS,
  payload: data
});

export const checkFollowDropdownFailed = data => ({
  type: actionType.CHECK_FOLLOW_STATUS_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function checkFollowDropdownReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.CHECK_FOLLOW_STATUS_START:
      return {
        ...state,
        loading: true
      };

    case actionType.CHECK_FOLLOW_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.CHECK_FOLLOW_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* checkFollowDropdownSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        checkFollowDropdownSuccess({totalCount: response.data.data.allFollowers.totalCount})
      );
    } else {
      messagePopup("", "Follow Api failed", "error");
      yield put(checkFollowDropdownFailed("Follow Api failed"));
    }
  } catch (error) {
    messagePopup("", "Follow Api failed", "error");
    yield put(checkFollowDropdownFailed("Follow Api failed"));
  }
}

export function* watchCheckFollowDropdownSaga() {
  yield takeEvery(actionType.CHECK_FOLLOW_STATUS_START, checkFollowDropdownSaga);
}