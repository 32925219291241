import { React, useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import { fetchMethod } from "../../../../../../services/httpService";
import swal from 'sweetalert'
import { IsValidEmail, IsValidName } from "../../../../../../utils/validationUtils";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getUserInfo } from "../../../../../../services/authService";
import { fetchGraphMethod } from "../../../../../../services/httpService";
import { getAllCollegeQuery, coachApiData } from "./profileQuery";
import {websiteDefaultUrl} from "../../../../../../config";

export default function SharePost({ handleModal, modalData }) {
  const [copied, setCopied] = useState(false);
  const [isMailText, setIsMailText] = useState(false)
  const [collegeData, setCollegeData] = useState([]);
  const [coachData, setCoachData] = useState([]);
  const [inputMail, setInputMail] = useState({
    collegename: "",
    coachname: ""
  })
  const copyToClipboard = () => {
    setCopied(true);
  }

  const handleCollegeChange = async (field, e) => {
    if (field === "collegename") {
      const variables = {
        obj: {
          and: [
            {
              or: [
                { collegename: { like: `%${e.target.value}%` } },
              ],
            },
          ],
        },
      };
      if (e.target.value.toString().length > 2) {
        let res = await fetchGraphMethod(
          getAllCollegeQuery,
          variables,
          true
        );
        let collegeTeamlist = res?.data?.data?.allColleges?.Colleges?.length > 0 ? res?.data?.data?.allColleges?.Colleges : []
        setCollegeData([...collegeTeamlist]);
      }
    }
  };

  const handleChange = (field, e) => {
    let fieldsT = inputMail;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    if (field === "collegename") {
      if (!e.target.value) {
        setCollegeData([])
        setCoachData([])
        fieldsT["coachname"] = ""
        fieldsT["emailId"] = ""
      } else {
        getCoachData(e.target.value)
      }
    }
    if (field === "coachname") {
      if (!e.target.value) {
        fieldsT["emailId"] = ""
      }
      else {
        fieldsT["emailId"] = e?.target?.value ? e.target.value.coachemail : ""
      }
    }
    setInputMail({
      ...fieldsT,
    });
  };

  const getCoachData = async value => {
    if (value?.id) {
      let variables = {
        where: {
          active: 1,
          order: "id desc",
          collegeid: parseFloat(value?.id)
        }
      }
      let res = await fetchGraphMethod(
        coachApiData,
        variables,
        true
      );
      let coachList = res?.data?.data?.allCoachandteamdetails?.Coachandteamdetails?.length > 0 ? res?.data?.data?.allCoachandteamdetails?.Coachandteamdetails?.map(item => {
        item["coachname"] = item?.coachfirstname ? item?.coachfirstname?.concat(item?.coachlastname ? " " + item?.coachlastname : null) : null
        return item
      }) : []
      setCoachData([...coachList]);
    }
  }

  const shareTextQuote = () => {
    let quote = `Sharing ${getUserInfo()?.FirstName || ""} ${getUserInfo()?.lastName || ""} public profile 
    ${`${websiteDefaultUrl}publicView/0/` + modalData?.athleteid}`;
    return quote
  }
  const shareTextURL = () => {
    let url = `${websiteDefaultUrl}publicView/0/` + modalData?.athleteid;
    return url
  }
  
  const onHandleChange = e => {
    const { name, value } = e.target
    const clone = { ...inputMail }
    clone[name] = value
    setInputMail(clone)
  }

  const onSubmit = async () => {
    if (!!inputMail?.emailId && !!inputMail?.coachname && inputMail?.collegename) {
      const obj = {
        emailId: inputMail?.emailId,
        firstName: getUserInfo()?.FirstName,
        lastName: getUserInfo()?.lastName,
        sharedData: `${shareTextQuote()}`
      }
      const res = await fetchMethod('/api/Userposts/sendPostOnEmail', obj, 'post', null)
      if (res?.status == 200) {
        swal({ text: "Mail send successfully", icon: "success" })
        handleModal()
      } else {
        swal("", "mail didn't send", "")
      }
    }
    else {
      swal("", "All Field Are Mandatory", "")
    }
  }
  return (
    <>
      <div className={`modal fade shareUpdateModal show`} id="shareUpdateModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={isMailText ? () => {
                  setIsMailText(false) ||
                    setInputMail({})
                  // setIsText(false)
                } : handleModal}
              >
                <span aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.804"
                    height="15.803"
                    viewBox="0 0 15.804 15.803"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g className="a" transform="translate(-6.776 -6.776)">
                      <path
                        d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </div>
            <div className="modal-body">
              <h1 className="heading">Share</h1>
              <div className="linkBox">
                <p style={{ overflowX: "hidden" }}>{shareTextURL()}</p>
                <CopyToClipboard
                  text={shareTextURL()}
                  onCopy={() => copyToClipboard()}
                >
                  <button type={'button'} className="copyBtn">
                    {!copied ? "Copy link" : "Copied!"}
                  </button>
                </CopyToClipboard>
              </div>
              <p className="info">Copy the link above or click below to share directly.</p>
              <ul className="share-post-modal-info">
                <li>
                  <label>College Name</label>
                  <div className="field-wrap">
                    <Autocomplete
                      id="combo-box-demo"
                      freeSolo={true}
                      options={collegeData}
                      getOptionLabel={(option) =>
                        option.collegename ? option.collegename : option
                      }
                      onChange={(e, data) => {
                        handleChange('collegename',
                          { target: { value: data ? data : "" } }
                        );
                      }}
                      value={inputMail.collegename || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="College Name"
                          value={inputMail?.collegename || ""}
                          onChange={(e) => {
                            handleCollegeChange("collegename", {
                              target: { value: e.target.value ? e.target.value : "" },
                            });
                            handleChange('collegename',
                              { target: { value: e.target.value ? e.target.value : "" }, }
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                </li>
                <li>
                  <label>Coach Name</label>
                  <div className="field-wrap">
                    <Autocomplete
                      style={{ width: "100%" }}
                      name="coachname"
                      freeSolo={true}
                      value={inputMail?.coachname || ""}
                      options={coachData}
                      getOptionLabel={(option) =>
                        option?.coachname ? option.coachname : option
                      }
                      onChange={(e, data) => {
                        handleChange('coachname',
                          { target: { value: data ? data : "" } }
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Coach Name" />
                      )}
                    />
                  </div>
                </li>
                <li>
                  <label>Email ID</label>
                  <div className="field-wrap">
                    <input
                      type={"text"}
                      value={inputMail?.emailId ?? ""}
                      name={"emailId"}
                      placeholder={"Email Id"}
                      disabled={true}
                      onChange={(e) => onHandleChange(e)}
                    />
                  </div>
                </li>
              </ul>
              <div className="share-btn-wrap">
                <button type={'button'} className={"btn lightblue"} onClick={() => onSubmit()}>Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}