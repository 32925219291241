import { React, useState, useEffect, useRef } from "react";
import "../assets/css/account.scss";
import LeftNavigation from "./LeftNavigation";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import { connect } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import {
  getAllAthletesDataQuery,
  getAllRelationshipsQuery,
} from "../../../../../common/CommonQuery";
import { relationshipListStart } from "../../../../../../redux/common/relationships";
import { getUserInfo, logout } from "../../../../../../services/authService";
import {
  fetchGraphMethod,
  fetchMethod,
} from "../../../../../../services/httpService";
import {
  saveParentGuardianDataQuery,
  saveAthleteParentMapping,
} from "./manageAccessQuery";
import { athleteParentMappingStart } from "../../../../../../redux/athlete/onBoarding/athleteParentMapping";
import {
  allParentQuery,
  parentDetailsQuery,
} from "../../editUserProfile/ContactQuery";
import {
  referencesListStart,
  referencessaveStart,
} from "../../../../../../redux/athlete/dashboard/editUserProfile/allReferences";
import {
  getAllReferenceDataQuery,
  saveAllReferenceDataQuery,
} from "../../editUserProfile/ReferencesQuery";
import warningMessagePopup from "../../../../../../utils/warningMagPop";
import messagePopup from "../../../../../../utils/messagePopup";
import PhoneNumber from "../../../../../common/PhoneNumber";
import { countryListStart } from "../../../../../../redux/common/countriesList";
import {
  checkPhoneNumberValidation,
  formatPhoneNumber,
  formattedPhoneNumber,
  IsValidEmail,
  IsValidName,
} from "../../../../../../utils/validationUtils";
import { updateParentListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/updateParentData";
import { getParentAthleteListStart } from "../../../../../../redux/athlete/dashboard/homepage/parentAllAthlete";
import swal from "sweetalert";
import toastr from "toastr";

const styleSheet = {
  display: "block",
  fontSize: ".875rem",
  fontWeight: 400,
  color: "#3e3e3e",
  marginBottom: "10px",
};
const AddParent = ({
  onAddParentClick,
  closeModalAddParent,
  onGrantAccess,
  relationshipListStart,
  relationshipData,
  countryListStart,
  countriesData,
  allParentsDataList,
  updateParentListStart,
  getParentAthleteListStart,
  parentAthleteList,
  // singleParentData
}) => {
  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    relationship: "",
    countryCodeId: {
      value: countriesData.data ? countriesData.data[0].id : "",
      label: countriesData.data
        ? countriesData.data[0].countryName +
          `(+${countriesData.data[0].countryCode})`
        : "",
      flag: countriesData.data ? countriesData.data[0].countryFlagImage : "",
      countryCode: countriesData.data ? countriesData.data[0].countryCode : "",
      countryShortName: countriesData.data
        ? countriesData.data[0].countryShortName
        : "",
    },
    phoneNumber: "",
  });
  const [errors, setErrors] = useState({});
  const [parentData, setParentData] = useState([]);

  useEffect(() => {
    relationshipListStart({
      query: getAllRelationshipsQuery,
      variables: null,
    });
    countryListStart();
    getAllParentListData();
  }, []);

  useEffect(() => {
    if (countriesData.data) {
      const fieldsT = fields;
      fieldsT["countryCode"] = {
        value: countriesData.data ? countriesData.data[0].id : "",
        label: countriesData.data
          ? countriesData.data[0].countryName +
            `(+${countriesData.data[0].countryCode})`
          : "",
        flag: countriesData.data ? countriesData.data[0].countryFlagImage : "",
        countryCode: countriesData.data
          ? countriesData.data[0].countryCode
          : "",
        countryShortName: countriesData.data
          ? countriesData.data[0].countryShortName
          : "",
      };
    }
  }, [countriesData.data]);

  useEffect(() => {
    if (
      parentAthleteList &&
      parentAthleteList.data &&
      parentAthleteList.data[1]
    ) {
      let data =
        parentAthleteList && parentAthleteList.data && parentAthleteList.data[1]
          ? parentAthleteList.data[1]
          : null;
      setParentData(
        parentAthleteList && parentAthleteList.data && parentAthleteList.data[1]
      );
      if (data) {
        let fieldsT = fields;
        fieldsT["firstName"] =
          data && data.parentfirstname ? data.parentfirstname : "";
        fieldsT["lastName"] =
          data && data.parentlastname ? data.parentlastname : "";
        fieldsT["phoneNumber"] =
          data && data.parentphonenumber ? data.parentphonenumber : "";
        fieldsT["countryCodeId"] =
          countriesData.data &&
          countriesData.data
            .filter((item) => +item.id === data.parentcountrycodeid)
            .map((item1) => ({
              value: item1 ? item1.id : "",
              label: item1 ? item1.countryName + `(+${item1.countryCode})` : "",
              flag: item1 ? item1.countryFlagImage : "",
              countryCode: item1.countryCode ? item1.countryCode : 1,
              //this key is added as by default us is shown
              countryShortName: item1?.countryShortName
                ? item1.countryShortName
                : "US",
            }))[0];
        setFields({
          ...fields,
        });
      }
    } else {
      let fieldsT = fields;
      fieldsT["firstName"] = "";
      fieldsT["lastName"] = "";
      fieldsT["phoneNumber"] = "";
      setFields({
        ...fields,
      });
    }
  }, [parentAthleteList.data]);
  const getAllParentListData = () => {
    allParentsDataList({
      query: allParentQuery,
      variables: {
        obj: {
          athleteid: getUserInfo() ? getUserInfo().id : null,
          isaccessallowed: 1,
          active: 1, //this key is added as on 8/4/2022 changes
        },
      },
    });
  };
  const parentAlreadyExist = async (e) => {
    e.preventDefault();
    await checkIfParentAlreadyExist();
  };
  const checkIfParentAlreadyExist = () => {
    getParentAthleteListStart({
      query: parentDetailsQuery,
      variables: {
        obj: {
          parentemail: fields.email,
        },
      },
    });
  };

  const handleChange = (e, field) => {
    setErrors({
      errorsT: "",
    });
    let fieldsT = fields;
    //this check is for phone number length validation
    
    // if (field == "phoneNumber") {
    //   if (e?.target?.value?.length > 10) {
    //     return;
    //   } else {
    //     fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    //     setFields({
    //       ...fieldsT,
    //     });
    //   }
    // }
    // fieldsT[field] = e ? (e.target ? e.target.value : e) : e;

    if(field == "phoneNumber"){
      if(e?.target?.value?.length > 10) return
      fieldsT[field]=formatPhoneNumber(e.target.value)
    }else{
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    }
    setFields({
      ...fieldsT,
    });
  };

  const handlePhonenumberChange = (selectedOption) => {
    const fieldsT = fields;
    fieldsT["countryCodeId"] = selectedOption;
    setFields({ ...fieldsT, phoneNumber: "" });
  };

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    //firstname validations
    if (fieldname === undefined || fieldname === "firstName") {
      if (fieldname === undefined) {
        fieldValue = fields["firstName"] ? fields["firstName"] : "";
        updatedValue = fieldValue;
        delete errorsT["firstName"];
      }
      if (!updatedValue) {
        formIsValid = false;
        errorsT["firstName"] = "First name is required";
      } else if (!IsValidName(updatedValue)) {
        formIsValid = false;
        errorsT["firstName"] = "Please enter a valid first name";
      } else if (updatedValue.length > 30) {
        formIsValid = false;
        errorsT["firstName"] = "First name maximum length should be 30";
      }
    }
    //lastname validations
    if (fieldname === undefined || fieldname === "lastName") {
      if (fieldname === undefined) {
        fieldValue = fields["lastName"] ? fields["lastName"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["lastName"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["lastName"] = "Last name is required";
      } else if (!IsValidName(updatedValue)) {
        formIsValid = false;
        errorsT["lastName"] = "Please enter a valid last name";
      } else if (updatedValue.length > 30) {
        formIsValid = false;
        errorsT["lastName"] = "Last name maximum length should be 30";
      }
    }
    // email validations
    if (fieldname === undefined || fieldname === "email") {
      if (fieldname === undefined) {
        fieldValue = fields["email"] ? fields["email"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["email"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["email"] = "Email is required";
      } else if (!IsValidEmail(updatedValue)) {
        formIsValid = false;
        errorsT["email"] = "Please enter a valid email";
      }
    }
    //phoneNumber validations
    if (fieldname === undefined || fieldname === "phoneNumber") {
      if (fieldname === undefined) {
        fieldValue = fields["phoneNumber"] ? fields["phoneNumber"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["phoneNumber"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["phoneNumber"] = "Mobile Number is required";
        //earlier in phone country validation it was countrycode
      } else if (
        !checkPhoneNumberValidation(
          updatedValue,
          fields.countryCodeId.countryShortName
        )
      ) {
        formIsValid = false;
        errorsT["phoneNumber"] = "Please enter a valid Phone Number";
      }
      // updatedValue = formattedPhoneNumber(
      //   updatedValue,
      //   fields.countryCode.countryShortName
      // );
    }
    //relationWithAthlete validations
    if (fieldname === undefined || fieldname === "relationship") {
      if (fieldname === undefined) {
        fieldValue = fields["relationship"] ? fields["relationship"] : "";
        updatedValue = fieldValue;
      }
      delete errorsT["relationship"];
      if (!updatedValue) {
        formIsValid = false;
        errorsT["relationship"] = "Relationship is required";
      }
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  };

  const SaveParentData = () => {
    let parentAthleteData = parentAthleteList;
    if (parentAthleteData) {
      parentAthleteData.data = null;
    }
    let addParent = {
      userId: getUserInfo() ? getUserInfo().id : null,
      athleteFirstName: getUserInfo() ? getUserInfo().FirstName : "",
      parentDetails: [
        {
          id: 0,
          firstName: fields.firstName,
          lastName: fields.lastName,
          primaryEmail: fields.email,
          phoneNumber: fields.phoneNumber,
          countryCodeValue: fields?.countryCodeId?.value,
          relationshipWithAthlete: fields.relationship
            ? fields.relationship
            : null,
          notificationAccess: []//this key is added as api is going into pending 7/11/2022 P
        },
      ],
      role: getUserInfo() ? getUserInfo().role : 0,
      status: "add",
      isFromManageAccess: "true",
    };
    updateParentListStart({ ...addParent, refreshPage });
  };

  // save Parent Data for Access Management
  const grantAccess = async (e) => {
    e.preventDefault();
    const { formIsValid } = handleValidation();
    if (formIsValid) {
      await SaveParentData();
    }
  };
  const closeModal = () => {
    closeModalAddParent();
    setParentData([]);
    let parentAthleteData = parentAthleteList;
    if (parentAthleteData) {
      parentAthleteData.data = null;
    }
  };
  const refreshPage = () => {
    getAllParentListData();
    onGrantAccess();
  };

  return (
    <div
      className="modal fade account-common-modal show"
      style={{ display: "block" }}
      id="AddParentGuardianModal"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <form className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add parent / guardian</h5>
            <button
              onClick={closeModal}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="fieldBox">
              <label>
                Email <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="email"
                placeholder="Email"
                onChange={(e) => handleChange(e, "email")}
                onBlur={(e) => parentAlreadyExist(e)}
              />
              <div className="error">{errors["email"]}</div>
            </div>
            <div className="fieldBox">
              <label>
                First Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                placeholder="Firstname"
                onChange={(e) => handleChange(e, "firstName")}
                value={fields.firstName}
                disabled={
                  fields.email == "" ||
                  (parentAthleteList.data && parentAthleteList.data[1])
                    ? true
                    : false
                }
              />
              <div className="error">{errors["firstName"]}</div>
            </div>
            <div className="fieldBox">
              <label>
                Last Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                placeholder="Lastname"
                onChange={(e) => handleChange(e, "lastName")}
                value={fields.lastName}
                disabled={
                  fields.email == "" ||
                  (parentAthleteList.data && parentAthleteList.data[1])
                    ? true
                    : false
                }
              />
              <div className="error">{errors["lastName"]}</div>
            </div>
            <div className="fieldBox">
              <label>
                Mobile Number <span style={{ color: "red" }}>*</span>
              </label>
              <PhoneNumber
                dropdownName="countryCode"
                dropdownOnChange={(e) => handlePhonenumberChange(e)}
                dropdownValue={fields.countryCode}
                dropDownOptions={
                  countriesData.data &&
                  countriesData.data.map((item) => ({
                    value: item.id,
                    label: item.countryName + `(+${item.countryCode})`,
                    flag: item.countryFlagImage,
                    countryCode: item.countryCode,
                    countryShortName: item.countryShortName,
                  }))
                }
                formatOptionLabel={(option, { context, selectValue }) => {
                  return context === "menu" ? (
                    <>
                      <img
                        src={option.flag}
                        alt=""
                        style={{
                          width: "40px",
                          marginRight: "15px",
                        }}
                      />
                      <span>{option.label}</span>
                    </>
                  ) : (
                    <>
                      <img
                        src={option.flag}
                        alt=""
                        style={{
                          width: "40px",
                          marginRight: "15px",
                        }}
                      />
                      <span>+{option.countryCode}</span>
                    </>
                  );
                }}
                inputPlaceholder="Phone Number"
                inputType="text"
                inputValue={fields.phoneNumber || ""}
                inputOnChange={(e) => {
                  handleChange({
                      target: {
                          value: e.target.value.replace(/[^0-9]/gi, ""),
                      },
                  },"phoneNumber");
              }}
                inputOnBlur={(e) => {
                  handleChange({
                      target: {
                          value: e.target.value.replace(/[^0-9]/gi, ""),
                      },
                  },"phoneNumber");
              }}
                disabled={
                  fields.email == "" ||
                  (parentAthleteList.data && parentAthleteList.data[1])
                    ? true
                    : false
                }
              />
              <div className="error">{errors["phoneNumber"]}</div>
            </div>
            <div className="fieldBox">
              <span style={styleSheet}>
                Relationship <span style={{ color: "red" }}>*</span>
              </span>
              <FormControl variant="outlined" fullWidth>
                <Select
                  onChange={(e) => handleChange(e, "relationship")}
                  // labelId="relationship"
                  // id="relationship"
                  name="relationship"
                  value={fields.relationship}
                  placeholder="Select Relationship"
                  // label="Select"
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>

                  {relationshipData
                    ? relationshipData.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.relationShipName}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </FormControl>
              <div className="error">{errors["relationship"]}</div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={(e) => grantAccess(e)}
              type="submit"
              className="btn green"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#AddParentGuardianSuccessModal"
            >
              Grant Access
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// coach access listing
const CoachAccess = ({
  referencesListStart,
  referencesData,
  referencessaveStart,
}) => {
  useEffect(() => {
    getAllRefernceListData();
  }, [referencesListStart]);

  const getAllRefernceListData = () => {
    referencesListStart({
      query: getAllReferenceDataQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : null,
          active: 1,
          reftype: 1,
        },
      },
    });
  };
  const refreshList = async (data, refData) => {
    getAllRefernceListData();
    if (data.isaccessallowed == 0) {
      // messagePopup("", "Access has been successfully removed.", "success");
      toastr.success("Access has been successfully removed", "", {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: "toast-top-full-width",
        showEasing: "swing",
        fadeIn: 40000,
        preventDuplicates: true,
      });
    } else {
      // messagePopup("", "Access has been successfully granted.", "success");
      toastr.success("Access has been successfully granted", "", {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: "toast-top-full-width",
        showEasing: "swing",
        fadeIn: 40000,
        preventDuplicates: true,
      });
    }
    //this is for sending the mail to the caoch
    const mailObj = {
      coachFirstName: refData?.refData?.reffirstname ?? "",
      athleteFirstName: getUserInfo()?.FirstName ?? "",
      athleteLastName: getUserInfo()?.lastName ?? "",
      coachEmailId: refData?.refData?.refemailid ?? "",
      athleteId: getUserInfo()?.id ?? 0,
      isAccessAllowed: data?.isaccessallowed ?? 0,
    };
    const resMail = await fetchMethod(
      "/api/Coachandteamdetails/AccessForCoachEmail",
      mailObj,
      "POST",
      true
    );
    if (resMail?.status == 200) {
    } else {
    }
  };

  const removeAccessOfCoach = (e, CoachId, isAccessAllowed, refData) => {
    e.preventDefault();
    referencessaveStart({
      query: saveAllReferenceDataQuery,
      variables:
        isAccessAllowed == 1
          ? {
              obj: {
                id: CoachId,
                isaccessallowed: 0,
              },
              refData,
            }
          : {
              obj: {
                id: CoachId,
                isaccessallowed: 1,
              },
              refData,
            },
      refreshList: refreshList,
    });
  };

  const handleRemoveAccesOfCoach = (e, CoachId, isAccessAllowed, refData) => {
    e.preventDefault();
    const confirmationForCoach =
      isAccessAllowed == 1
        ? swal({
            text: "Are you sure you want to remove access for this coach ?",
            icon: "warning",
            buttons: {
              confirm: { text: "Ok" },
              cancel: "Cancel",
            },
            dangerMode: true,
          })
        : swal({
            text: "Are you sure you want to grant access to this coach ?",
            icon: "warning",
            buttons: {
              confirm: { text: "Ok" },
              cancel: "Cancel",
            },
            dangerMode: true,
          });
    confirmationForCoach.then(async (value) => {
      if (value == true) {
        const removeAccess = await removeAccessOfCoach(
          e,
          CoachId,
          isAccessAllowed,
          refData
        );
        // getAllRefernceListData();
        // if (isAccessAllowed == 1) {
        //   messagePopup("", "Access has been successfully removed.", "success");
        // } else {
        //   messagePopup("", "Access has been successfully granted.", "success");
        // }
      }
    });
  };

  return (
    <div className="manage-access-item">
      <h3>Manage Coach</h3>
      <div className="table-reponsive manage-access-table">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Team</th>
            </tr>
          </tbody>
          {referencesData && referencesData.References
            ? referencesData.References.map((references, index) => (
                <tbody key={index}>
                  <tr>
                    <td>{references.reffirstname}</td>
                    <td>{references.reflastname}</td>
                    <td>
                      <a href="mailto:elmerathelete@gmail.com">
                        {references.refemailid}
                      </a>
                    </td>
                    <td>{references.team}</td>
                    {references.isaccessallowed == 1 ? (
                      <td>
                        <button
                          onClick={(e) =>
                            handleRemoveAccesOfCoach(
                              e,
                              references.id,
                              references.isaccessallowed,
                              references
                            )
                          }
                          className="close-button"
                        >
                          <svg
                            id="close"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.871"
                            height="16.87"
                            viewBox="0 0 16.871 16.87"
                          >
                            <path
                              id="Path_2105"
                              data-name="Path 2105"
                              d="M8.7,7.107A1.127,1.127,0,1,0,7.106,8.7l6.511,6.51-6.511,6.51A1.127,1.127,0,1,0,8.7,23.315l6.511-6.51,6.511,6.511a1.127,1.127,0,1,0,1.594-1.594l-6.511-6.51L23.316,8.7a1.127,1.127,0,1,0-1.594-1.594l-6.51,6.511L8.7,7.106Z"
                              transform="translate(-6.776 -6.777)"
                              fill="#c0321f"
                            />
                          </svg>
                        </button>
                      </td>
                    ) : (
                      <td>
                        <button
                          className="check-button"
                          onClick={(e) =>
                            handleRemoveAccesOfCoach(
                              e,
                              references.id,
                              references.isaccessallowed,
                              references
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="300"
                            height="229.719"
                            viewBox="0 0 300 229.719"
                          >
                            <path
                              id="ios-checkmark"
                              d="M308.836,39.072l-24.507-25.2a5.291,5.291,0,0,0-3.9-1.664,5.078,5.078,0,0,0-3.9,1.664L106.576,185.066,44.723,123.213a5.409,5.409,0,0,0-7.806,0l-24.8,24.8a5.538,5.538,0,0,0,0,7.945l78.019,78.019c5.012,5.012,11.154,7.945,16.305,7.945,7.39,0,13.789-5.428,16.167-7.667h.139L308.994,47.036a5.981,5.981,0,0,0-.139-7.945Z"
                              transform="translate(-10.434 -12.207)"
                              fill="#1CB79E"
                            />
                          </svg>
                        </button>
                      </td>
                    )}
                  </tr>
                </tbody>
              ))
            : null}
        </table>
      </div>
    </div>
  );
};
// parent access listing
const ParentAccess = ({
  allParentsDataList,
  allParentData,
  updateParentListStart,
  age,
}) => {
  useEffect(() => {
    getAllParentDataList();
  }, [allParentsDataList]);

  const getAllParentDataList = () => {
    allParentsDataList({
      query: allParentQuery,
      variables: {
        obj: {
          athleteid: getUserInfo() ? getUserInfo().id : null,
          isaccessallowed: 1,
          active: 1, //this key is added on 8/4/2022 as per the new modification
        },
      },
    });
  };
  const refreshPage = () => {
    getAllParentDataList();
  };
  const removeAccessOfParent = (e, parentId, active, parentUserId) => {
    let removeParent =
      active === 1
        ? {
            parentId: parentId,
            status: "remove",
            athleteId: getUserInfo().id,
            removeAccessFrom: "manage",
            isAccessStatus: 0,
          }
        : {
            parentId: parentId,
            status: "remove",
            athleteId: getUserInfo().id,
            removeAccessFrom: "manage",
            isAccessStatus: 1,
          };
    if (active == 1) {
      logout(parentUserId);
    }
    updateParentListStart({ ...removeParent, refreshPage });
  };

  const handleRemoveAccessOfParent = async (
    e,
    parentId,
    active,
    parentUserId
  ) => {
    e.preventDefault();
    const confirmation =
      active === 1
        ? swal({
            text: "Are you sure you want to disable your Parent / Guardian access to your profile?",
            //Are you sure you want to remove access of this Parent / Guardian? earlier this text was there
            icon: "warning",
            buttons: {
              confirm: { text: "Yes" },
              cancel: "Cancel",
            },
            dangerMode: true,
          })
        : swal({
            text: "Are you sure you want to grant access of this Parent / Guardian?",
            icon: "warning",
            buttons: {
              confirm: { text: "Ok" },
              cancel: "Cancel",
            },
            dangerMode: true,
          });
    confirmation.then(async (value) => {
      if (value == true) {
        const removeAccess = await removeAccessOfParent(
          e,
          parentId,
          active,
          parentUserId
        );
        // getAllParentDataList();
        if (active == 1) {
          toastr.success("Access has been successfully removed", "", {
            progressBar: true,
            timeOut: 2000,
            closeButton: true,
            positionClass: "toast-top-full-width",
            showEasing: "swing",
            fadeIn: 40000,
            preventDuplicates: true,
          });
        } else {
          // messagePopup("", "Access has been successfully granted", "success");
          toastr.success("Access has been successfully granted", "", {
            progressBar: true,
            timeOut: 2000,
            closeButton: true,
            positionClass: "toast-top-full-width",
            showEasing: "swing",
            fadeIn: 40000,
            preventDuplicates: true,
          });
        }
      }
    });
  };

  return (
    <div className="table-reponsive manage-access-table">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Relationship</th>
            <th>Email</th>
            <th>Phone</th>
            <th>&nbsp;</th>
          </tr>
        </tbody>
        {allParentData && allParentData.parentData
          ? allParentData.parentData.map((parentData, index) => (
              <tbody key={index}>
                <tr>
                  <td>{parentData.parentfirstname}</td>
                  <td>{parentData.parentlastname}</td>
                  <td>
                    {parentData &&
                    parentData.athleteparentmappingrelationshipidmaprel &&
                    parentData.athleteparentmappingrelationshipidmaprel
                      .Relationships &&
                    parentData.athleteparentmappingrelationshipidmaprel
                      .Relationships[0] &&
                    parentData.athleteparentmappingrelationshipidmaprel
                      .Relationships[0].relationshipname
                      ? parentData.athleteparentmappingrelationshipidmaprel
                          .Relationships[0].relationshipname
                      : null}
                  </td>
                  <td>
                    <a href="mailto:elmerathelete@gmail.com">
                      {parentData.parentemail}
                    </a>
                  </td>
                  <td>
                    <a href="tel:9856567677">{parentData.parentphonenumber}</a>
                  </td>
                  <td>
                    {parentData.isActive === 1 ? (
                      <button
                        onClick={(e) =>
                          +age < 18 && allParentData?.parentData?.length == 1
                            ? messagePopup(
                                "",
                                "Operation can't be performed." +
                                  "Please saved At least one or more parent / guardian.",
                                "warning"
                              )
                            : handleRemoveAccessOfParent(
                                e,
                                parentData.id,
                                parentData.isActive,
                                parentData.userid
                              )
                        }
                        className="close-button"
                      >
                        <svg
                          id="close"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.871"
                          height="16.87"
                          viewBox="0 0 16.871 16.87"
                        >
                          <path
                            id="Path_2105"
                            data-name="Path 2105"
                            d="M8.7,7.107A1.127,1.127,0,1,0,7.106,8.7l6.511,6.51-6.511,6.51A1.127,1.127,0,1,0,8.7,23.315l6.511-6.51,6.511,6.511a1.127,1.127,0,1,0,1.594-1.594l-6.511-6.51L23.316,8.7a1.127,1.127,0,1,0-1.594-1.594l-6.51,6.511L8.7,7.106Z"
                            transform="translate(-6.776 -6.777)"
                            fill="#c0321f"
                          />
                        </svg>
                      </button>
                    ) : (
                      <button
                        className="check-button"
                        onClick={(e) =>
                          handleRemoveAccessOfParent(
                            e,
                            parentData.id,
                            parentData.isActive,
                            parentData.userid
                          )
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="300"
                          height="229.719"
                          viewBox="0 0 300 229.719"
                        >
                          <path
                            id="ios-checkmark"
                            d="M308.836,39.072l-24.507-25.2a5.291,5.291,0,0,0-3.9-1.664,5.078,5.078,0,0,0-3.9,1.664L106.576,185.066,44.723,123.213a5.409,5.409,0,0,0-7.806,0l-24.8,24.8a5.538,5.538,0,0,0,0,7.945l78.019,78.019c5.012,5.012,11.154,7.945,16.305,7.945,7.39,0,13.789-5.428,16.167-7.667h.139L308.994,47.036a5.981,5.981,0,0,0-.139-7.945Z"
                            transform="translate(-10.434 -12.207)"
                            fill="#1CB79E"
                          />
                        </svg>
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            ))
          : null}
      </table>
    </div>
  );
};

const ManageAccess = (props) => {
  const [showParentModal, setShowParentModal] = useState(false),
    [showSuccessModal, setShowSuccessModal] = useState(false);
  const fakeInput = useRef();
  const [parentData, setParentData] = useState(props.parentAthleteList);
  const [age, setAge] = useState(0);
  const onAddParentClick = () => {
    let parentDataList =
      props.allParentData &&
      props.allParentData.data &&
      props.allParentData.data.parentData
        ? props.allParentData.data.parentData
        : "";
    if (parentDataList.length >= 5) {
      toastr.warning("Parent / Guardian Limit Exceeded", "", {
        progressBar: true,
        timeOut: 2000,
        closeButton: true,
        positionClass: "toast-top-full-width",
        showEasing: "swing",
        fadeIn: 40000,
        preventDuplicates: true,
      });
    } else {
      setShowParentModal(!showParentModal);
    }
  };

  useEffect(() => {
    fakeInput.current.focus();
    fakeInput.current.select();
  }, []);
  const closeModalAddParent = () => {
    setShowParentModal(false);
  };
  useEffect(() => {
    getAthleteData();
  }, []);
  const onGrantAccess = () => {
    setShowParentModal(false);
    setShowSuccessModal(!showSuccessModal);
  };

  useEffect(() => {
    goTop();
  }, []);

  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  const getAthleteData = async () => {
    const finalObj = {
      obj: {
        id: getUserInfo() ? getUserInfo().id : 0,
      },
    };
    const resAth = await fetchGraphMethod(
      getAllAthletesDataQuery,
      finalObj,
      true
    );
    if (resAth?.status == 200) {
      const dob =
        resAth?.data?.data?.allAthletes?.Athletes?.[0]?.onBoardingUserIdMap
          ?.Onboardings?.[0]?.dateofbirth ?? new Date();
      getUserAge(dob);
    } else {
      getUserAge(new Date());
    }
  };
  const getUserAge = (value) => {
    let dob = new Date(value);
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var age = Math.abs(year - 1970);
    // return age;
    setAge(age);
  };
  return (
    <section className="account-main-sec">
      <div className="container">
        <input className="fakeInput" type="text" ref={fakeInput} />
        <div className="account-with-sidebar">
          <LeftNavigation />
          <input className="fakeInput" type="text" ref={fakeInput} />
          <div className="account-data-box">
            <h1 className="title">Manage Access</h1>
            <div className="manage-access-box">
              <div className="manage-access-item">
                <h3>Manage Parent / Guardian</h3>
                <div className="manage-access-top-block">
                  <div className="item">
                    Providing access to people to operate your account
                  </div>
                  <div className="item">
                    <button
                      onClick={(e) => onAddParentClick(e)}
                      className="btn green"
                      data-toggle="modal"
                      data-target="#AddParentGuardianModal"
                    >
                      + Add parent / guardian
                    </button>
                  </div>
                </div>
                <ParentAccess
                  allParentsDataList={props.athleteParentMappingStart}
                  allParentData={props.allParentData.data}
                  updateParentListStart={props.updateParentListStart}
                  age={age}
                />
              </div>
              <CoachAccess
                referencesListStart={props.referencesListStart}
                referencessaveStart={props.referencessaveStart}
                referencesData={
                  props &&
                  props.allReferences &&
                  props.allReferences.data &&
                  props.allReferences.data.allreferencedata
                    ? props.allReferences.data.allreferencedata
                    : ""
                }
              />
            </div>
          </div>
          <Dialog open={showParentModal}
                  onClose={(event, reason)=> {
                    if (reason == "backdropClick" || reason == "escapeKeyDown") {
                      closeModalAddParent()
                    }
                  }}
                  className="matDialogCustom matDialogCustomOverlay"
          >
            <AddParent
              relationshipData={props.allRelationships.data}
              relationshipListStart={props.relationshipListStart}
              countriesData={props.allCountries}
              countryListStart={props.countryListStart}
              onAddParentClick={onAddParentClick}
              closeModalAddParent={closeModalAddParent}
              onGrantAccess={onGrantAccess}
              allParentsDataList={props.athleteParentMappingStart}
              updateParentListStart={props.updateParentListStart}
              getParentAthleteListStart={props.getParentAthleteListStart}
              parentAthleteList={props.parentAthleteList}
            />
          </Dialog>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    allRelationships: state.common.relationships,
    allParentData: state.athlete.onBoarding.athleteParentMapping,
    allReferences: state.athlete.editUserProfileData.allReferences,
    parentAthleteList: state.athlete.dashboard.home.parentAthleteList,
    allCountries: state.common.countries,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    relationshipListStart: (data) => dispatch(relationshipListStart(data)),
    athleteParentMappingStart: (data) =>
      dispatch(athleteParentMappingStart(data)),
    referencesListStart: (data) => dispatch(referencesListStart(data)),
    referencessaveStart: (data) => dispatch(referencessaveStart(data)),
    countryListStart: (data) => dispatch(countryListStart(data)),
    getParentAthleteListStart: (data) =>
      dispatch(getParentAthleteListStart(data)),
    updateParentListStart: (data) => dispatch(updateParentListStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageAccess);
