export default [
  {
    studentName: 'Connor Markl',
    studentPhoto: 'connor-markl.png',
    schoolName: 'Grand Canyon University',
    text: 'My family will be paying very little for my college education. I would recommend SportsForce because of their hands-on approach.',
  },
  {
    studentName: 'Jimmy Chatfield',
    studentPhoto: 'jimmy-chatfield.jpeg',
    schoolName: 'Yale University',
    text: 'The communications directly with coaches, and knowledge of the coaches’ needs & interest was paramount! Coach Thompson made all the difference!',
  },
  {
    studentName: 'Jamir Gibson',
    studentPhoto: 'jamir-gibson.jpeg',
    schoolName: 'Grambling State',
    text: 'They know all the ins & outs, a huge network of college connections. If you are serious about college baseball, they are whom you want on your side!',
  },
  {
    studentName: 'Charles Beilenson',
    studentPhoto: 'charles-beilenson.webp',
    schoolName: 'Brown University',
    text: 'My advisor kept me on task, helped me talk to coaches, and told me everything I needed to do. I would 100% recommend SportsForce.',
  },
  {
    studentName: 'Bennett Markinson',
    studentPhoto: 'bennett-markinson.png',
    schoolName: 'Northwestern University',
    text: 'I am super excited for the next 4 years at Northwestern! SportsForce and my advisor Ryan Thompson, were super helpful!',
  },
];
