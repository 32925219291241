import React, { useEffect, useState } from 'react';
import "./assets/css/privacy-policy.scss";
import {Link} from "react-router-dom";

const TermsOfServices = () => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setTimeout(()=>{
      window.scroll(0,0)
    },500)
  }, [])

  return (
    <div>
      <section className="privacy-policy-content">
        <div className="pagelinks">
          <div className="container">
            <ul className="links">
              <li><Link className="active" to={'/Terms-of-Services'}>Terms of Use</Link></li>
              <li><Link to={'/Privacy-Policy'}>Privacy Policy</Link></li>
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="contentwrap">
            <h3>Terms</h3>
            <p>These Terms of Use (“Terms of Use” or “Terms”) and our Privacy Policy (our, “Privacy Policy (collectively, the “Agreement”), govern your access and use of the SportsForce platform, website (<a href="https://sportsforce.io/">https://sportsforce.io</a>), and any other services, applications (including mobile applications), or data products and services made available by SportsForce from time to time (collectively, “Services” and/or the “Platform”).  The Platform is owned and operated by Sports Media Network, Inc. doing business at SportsForce (“SportsForce,” “we,” “us,” or “our”). By accessing the services (as defined below), you agree to be bound by the terms and conditions below. If you do not agree to any of the terms and conditions below, do not access these services.</p>
            <p>THESE TERMS AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS AND INCLUDE AN AGREEMENT TO ARBITRATE, A JURY TRIAL WAIVER AND CLASS ACTION WAIVER.
              By using the Platform or accepting these Terms, you accept and agree to be bound and abide by these Terms and our Privacy Policy. If you do not agree to these Terms or our Privacy Policy, please do not use the Platform.</p>
            <p>We reserve the right to modify, amend, or change the Terms at any time. Notice of any material change will be posted on this page with an updated date.  In certain circumstances, we may notify you of a change to the Terms via email or other means; however, you are responsible for regularly checking this page for notice of any changes.  Your continued use of the Platform constitutes your acceptance of any change, and you will be legally bound by the updated Terms. If you do not accept a change to these Terms, you should stop using the Platform immediately.</p>
            <h3>Use of Information and Materials</h3>
            <p>The information and materials contained in these pages, and the terms, conditions, and descriptions that appear, are subject to change at any time and without any notice by posting a revised Terms of Use on the platform. Any such revision will become effective immediately upon posting. Unauthorized use of the platform and systems including but not limited to unauthorized entry into SportsForce systems, misuse of passwords, or misuse of any information posted on the platform are strictly prohibited. Your eligibility for particular products and services is subject to final determination by SportsForce and its affiliates.</p>
            <h3>Age Limitation</h3>
            <p>The platform is not directed at children, but children may access and browse the platform without disclosing any personal information. SportsForce does not knowingly collect personally identifiable information online from children who are under 13 years of age without guardian oversight. Please visit www.ftc.gov/privacy/privacyinitiatives/childrens.html for more information about protecting children’s online privacy. If you become aware of anyone using the Platform who is under the age of 13, please report this to: <a href="mailto:team@sportsforceonline.com">team@sportsforceonline.com.</a> We may, in our sole discretion, refuse to offer the Platform to any person or entity for any reason. We may also change this eligibility criteria at any time, in our sole discretion.</p>
            <h4>1. The Platform</h4>
            <p>SportsForce is a platform used to collect, use, disclose, and share certain sports statistics, performance data and related personal information and materials (“Data”) from sports players (“Athletes”) and third party data sources and allows sports coaches (“Coaches”) and potentially other relevant parties to buy, access, and utilize the Data, leveraging machine learning, artificial intelligence and predictive analytics. After your acceptance of these Terms and the SportsForce Privacy Policy, SportsForce has the right to aggregate and anonymize all Data and sell such data to including but not limited to Athletes and Coaches.</p>
            <ul className="list-type-alphabetic">
              <li>Athletes must consent to share Data with Coaches.</li>
              <li><strong>For Coaches.</strong> When Coaches create Accounts, Coaches can upload team data (comprised of individual Athlete’s Data) to the Platform and may access an Athlete’s Data that has been shared with them.</li>
            </ul>
            <h4>2. Your Account.</h4>
            <ul className="list-type-alphabetic">
              <li><strong>Account Creation.</strong> To access the Platform, you will need to provide your name, date of birth, home address, phone number, and email address (“Account Data”) to create an account (“Account”). If you are an Athlete, you will also need to provide other information for statistical purposes. You may only upload logos or trademarks from your team’s organization with permission from your team’s organization.</li>
              <li><strong>Emails/Push Notifications.</strong> By registering for an Account, you authorize SportsForce to send you emails or push notifications regarding the Services or newsletters, marketing, or promotional materials relating thereto. You may opt-out of emails by clicking on the “unsubscribe” link in each email or by emailing <a href="mailto:team@sportsforceonline.com">team@sportsforceonline.com.</a> You may opt-out of push notifications by changing your preferences on your device.</li>
              <li><strong>Account Deletion.</strong> You may delete your Account at any time under the “Profile” section of the Platform. If you delete your Account, your Account Data will be deleted within ten (10) days. Any Data or personal information provided may remain on the Services on a deidentified basis for inclusion in our products and services.</li>
            </ul>
            <h4>3. Privacy</h4>
            <p>Our Privacy Policy explains what information may be collected through the Platform, how that information may be used and/or shared with others, how we safeguard that information, and how you may access or control its use in connection with our marketing communications and business activities.</p>
            <h4>4. Duration and Termination</h4>
            <p>These Terms apply to you the moment that you first use the Service and shall continue to apply for as long you have an 	Account with the Service or otherwise use the Service. We may terminate these Terms, or any portion of them, at any time, without notice to you, for any reason. However, the portions dealing with intellectual property rights, indemnification, disclaimers, limitation of liability, and governing law and any other portions which by their nature would continue beyond the termination of these Terms, will survive the termination of these Terms. We may suspend, restrict, or terminate all or part of your Account and/or access to and/or use of the Service or Platform, or remove any or all of Your Content (as defined herein) from the Service, at any time, for any reason, at our sole discretion, without prior warning or notice to you.</p>
            <h4>5. Purchases.</h4>
            <p>SportsForce utilizes Stripe (https://stripe.com/payments) to process online credit card payments and bank transfers. SportsForce does not store or share any credit card information.</p>
            <ul className="list-type-alphabetic">
              <li>
                <strong>Subscriptions.</strong> You may purchase access to the Platform (“Subscription”) from SportsForce by paying a subscription fee in advance on a yearly basis, unless a shorter period is permitted as indicated on the Platform, as agreed to by you prior to your purchase.
                <ul className="list-type-point">
                  <li><strong>Fee Changes.</strong> SportsForce may, in its sole discretion, change the Subscription price or any other price for the Services at any time. Subscription price changes will take effect at the start of the next subscription period following the date of the price change. Subject to applicable law, you accept the new price after we receive your authorization to charge your credit card. If you do not agree with a price change, you have the right to reject the change by unsubscribing from the Subscription prior to the price change going into effect.</li>
                  <li><strong>Refunds.</strong> SportsForce may, in its sole discretion, initiate refunds to you for Platform or Service access unless you have breached the terms of this Agreement. We do not provide refunds or credits for any partial subscription periods.</li>
                  <li><strong>Term/Termination/Cancellations.</strong> Your payment to SportsForce will automatically renew at the end of the applicable Subscription period, unless you cancel your Subscription before the end of the then-current subscription period. We will notify you at least thirty (30) days before your Subscription is set to renew. The cancellation will take effect the day after the last day of the current Subscription period.</li>
                  <li><strong>Trials.</strong> From time to time, SportsForce may offer trials of Subscriptions for a specified period without payment or at a reduced rate (a “Trial”). SportsForce may determine your eligibility for a Trial, and withdraw or modify a Trial at any time without prior notice and with no liability, to the extent permitted under applicable law. SportsForce may, in its sole discretion, offer a five (5) month seasonal Trial for a predetermined price as agreed to by you prior to your purchase.</li>
                </ul>
              </li>
            </ul>
            <h4>6. Intellectual Property Rights.</h4>
            <ul className="list-type-alphabetic">
              <li>
                <strong>SportsForce Content.</strong>
                <ul className="list-type-point">
                  <li>The Platform is owned and operated by SportsForce. By registering for an Account or by otherwise using the Platform, you agree that all content, trademarks, and other proprietary materials and/or information on the Platform, including, without limitation, aggregated, deidentified, or third party data, SportsForce’s logos, visual interfaces, graphics, design, compilation, information, software, computer code (including source code or object code), services, text, pictures, photos, video, graphics, music, information, data, sound files, other files and the selection and arrangement thereof and all other materials (collectively, the “SportsForce Content”) are protected by copyright, trademark, patent, trade secret, and other laws, and, as between you and SportsForce (and/or third-party licensors), SportsForce owns and retains all rights, title, and interest in the SportsForce Content and the Platform.</li>
                </ul>
              </li>
              <li>
                <strong>Your Content.</strong>
                <ul className="list-type-point">
                  <li>SportsForce allows you to upload Data to the Platform or access Data. You are solely responsible and liable for Account Data, Data that you upload and/or have access to, and other content you that you upload, provide, or have access to on the Platform, including photos, photograph(s), footage, illustrations, videos, messages, information, and/or other types of content (“Your Content”).</li>
                  <li>SportsForce uses reasonable security measures in order to attempt to protect Your Content against unauthorized copying and distribution. However, SportsForce makes no guarantee that any third parties will not use, distribute, or copy Your Content in an unauthorized manner.</li>
                  <li>To the furthest extent permitted by applicable law, you hereby agree that SportsForce shall not be liable for any unauthorized copying, use, or distribution of Your Content by third parties, and you release and forever waive any claims you may have against SportsForce for any such unauthorized copying, distribution, or usage of Your Content, under any theory.
                    THE SECURITY MEASURES TO PROTECT YOUR CONTENT USED BY SportsForce HEREIN ARE PROVIDED AND USED “AS-IS” AND WITH NO WARRANTIES, GUARANTEES, CONDITIONS, ASSURANCES, OR OTHER TERMS THAT SUCH SECURITY MEASURES WILL WITHSTAND ATTEMPTS TO EVADE SECURITY MECHANISMS OR THAT THERE WILL BE NO DISABLEMENTS, OR OTHER CIRCUMVENTION OF SUCH SECURITY MEASURES.
                  </li>
                  <li>If you provide us with any reviews, feedback, suggestions, data, or other information or content about the Service or otherwise in connection with these Terms, any SportsForce Content, SportsForce marketing materials or your participation in the Service (collectively, “Feedback”), you irrevocably assign to SportsForce all right, title, and interest in and to Feedback.</li>
                </ul>
              </li>
              <li>
                <strong>License Grant.</strong>
                <ul className="list-type-point">
                  <li>By submitting Your Content through the Services, you hereby do and shall grant us and our sublicensees, to the furthest extent and for the maximum duration permitted by applicable law (including in perpetuity if permitted under applicable law), a worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to use, sell, edit, modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit Your Content in connection with the Services and our (and our successors’ and assigns’) businesses, including without limitation for promoting and redistributing part or all of the Services (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party websites and feeds), and the Services. If you terminate your Subscription, we terminate these Terms of Use, or you ask us to delete your Data or personal information, you understand and agree that we will continue to use your Data or personal information on an aggregated and deidentified basis, as permitted by our Privacy Policy and applicable laws.</li>
                  <li>To the extent any of Your Content submitted includes any biographical information, including your name, likeness, voice, or photograph, you acknowledge and agree that the licensed granted to us in Section 7(c)(i) shall apply to the same. For clarity, the foregoing license granted to us and our users does not affect your other ownership or license rights in Your Content, including the right to grant additional licenses to Your Content, unless otherwise agreed in writing. You represent and warrant that you have all rights to grant such licenses to us without infringement or violation of any third party rights, including without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights. Should Your Content contain the name, identity, likeness, trademark, logo or voice (or other biographical information) of third parties, you represent and warrant that you have obtained the appropriate consents and/or licenses for your use of such features and that SportsForce and its sub-licensees are allowed to use them to the extent indicated in these Terms.</li>
                  <li>Except where prohibited by applicable law, by submitting Your Content through the Services, you are waiving and agreeing not to assert any copyright, right of publicity or “moral” rights or claims resulting from our alteration of Your Content or any photograph(s), footage, illustrations, statements or other work contained in Your Content.</li>
                  <li>SportsForce may, but has no obligation to, monitor, review, or edit Your Content. In all cases, SportsForce reserves the right to remove or disable access to any of Your Content for any or no reason, including Your Content that, in SportsForce’s sole discretion, violates the Agreement. SportsForce may take these actions without prior notification to you or any third party. Removal or disabling of access to Your Content shall be at our sole discretion, and we do not promise to remove or disable access to any specific part of Your Content.</li>
                </ul>
              </li>
              <li>
                <strong>Third Party Applications, Devices, and Links.</strong>
                <ul className="list-type-point">
                  <li>Applications and Devices. The Platform and Service may be integrated with or may otherwise interact with third party applications, websites, and services (“Third Party Applications”) and third party wearable devices or other devices (“Third Party Devices”) to make the Platform or Service available to you. These Third Party Applications and Devices may have their own terms and conditions of use and privacy policies and your use of these Third Party Applications and Devices will be governed by and subject to such terms and conditions and privacy policies. You understand and agree that SportsForce does not endorse and is not responsible or liable for the behavior, features, or content of any Third Party Application or Device or for any transaction you may enter into with the provider of any such Third Party Applications and Devices, nor does SportsForce warrant the compatibility or continuing compatibility of the Third Party Applications and Devices with the Service.</li>
                  <li>Links. The platform may contain links to other websites controlled or offered by third parties (non-affiliates of SportsForce). SportsForce disclaims liability for, any information, materials, products or services posted or offered at any of the third party websites linked to the platform. By providing any links to third party websites, SportsForce does not endorse or recommend any products or services offered or information contained therein, nor is SportsForce liable for any failure of products or services offered or advertised at those third party websites. Such third party website may have a privacy policy different from SportsForce and the third party website may provide less security than the platform.</li>
                </ul>
              </li>
              <li>
                <strong>User Conduct</strong>
                <p>These content standards apply to any and all User Content. User Content must comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Content must not:</p>
                <p>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.<br />Promote sexually explicit material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</p>
                <p>Infringe copyright, or other intellectual property or other rights of any other person, or otherwise contain any content, materials, data or other information that is not lawfully provided to us.
                  Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Service and our Privacy Policy.
                  Be likely to deceive any person.</p>
                <p>Promote any illegal activity, or advocate, promote, or assist any unlawful act.<br />Be likely to upset, embarrass, alarm, or annoy any other person.<br />Impersonate any person, or misrepresent your identity or affiliation with any person or organization.<br />Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</p>
              </li>
            </ul>
            <h3>Copyright Compliance</h3>
            <p>We will respond to notices of alleged copyright infringement that comply with applicable law. It is our policy in appropriate circumstances to disable and/or terminate the accounts of users who are repeat infringers. If you believe any materials accessible on or from the platform infringe your copyright, you may request removal of those materials (or access to them) from the platform by submitting written notification to our copyright agent designated below. To be valid in accordance with the Digital Millennium Copyright Act (17 U.S.C. § 512) (“DMCA”), the written notice (the “DMCA Notice”) must include substantially the following:</p>
            <ul>
              <li>Your physical or electronic signature.</li>
              <li>Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the platform, a representative list of such works.</li>
              <li>Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.</li>
              <li>Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).</li>
              <li>A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.</li>
              <li>A statement that the information in the written notice is accurate.</li>
              <li>A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
            </ul>
            <p>Our designated copyright agent to receive DMCA Notices is: <a href="mailto:team@sportsforceonline.com">team@sportsforceonline.com.</a> If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be effective. Please be aware that if you knowingly materially misrepresent that material or activity on the platform is infringing your copyright, you may be held liable for damages (including costs and attorneys’ fees) under Section 512(f) of the DMCA.</p>
            <h3>No Warranty</h3>
            <p>The information and materials contained in the platform, including text, graphics, information, links or other items are provided “as is”, “as available”. SportsForce does not warrant the accuracy, adequacy or completeness of this information and materials and expressly disclaims liability for errors or omissions in this information and materials. No warranty of any kind, implied, expressed or statutory including but not limited to the warranties of non-infringement of third party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given in conjunction with the information and materials.</p>
            <h3>Limitation of Liability</h3>
            <p>In no event will SportsForce be liable for any damages, including without limitation direct or indirect, special, incidental, or consequential damages, losses or expenses arising in connection with the platform, any information received from the platform, any email distributed to any user or any linked website or use thereof or inability to use by any party, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if SportsForce and its affiliates, or representatives thereof, are advised of the possibility of such damages, losses or expenses. Without limiting the generality of the foregoing, the liability of SportsForce, if any, to any user of the platform shall not exceed the amount actually paid by such user in the preceding twelve months.</p>
            <h3>Membership</h3>
            <p>SportsForce may change the price for the paid memberships and programs, including recurring membership fees, from time to time and will communicate any price changes to you in advance and, if applicable, how to accept those changes. Price changes will take effect at the start of the next subscription period following the date of the price change. Subject to applicable law, you accept the new price by continuing to use the SportsForce services after the price change takes effect. If you do not agree with a price change, you have the right to reject the change by canceling your SportsForce service prior to the price change going into effect.</p>
            <h4>Cancellation</h4>
            <p>The cardholder is required to pay out the contract in full. Whether the package is paid on an upfront or monthly installment basis, there is no opt out. The cardholder assumes the risk of any changes in the student-athlete’s recruiting process including but not limited to: loss of interest in pursuing college sports, a change of team/organization, lack of interest from targeted schools, and a verbal or official commitment to a college. The student-athlete’s SportsForce profile may be canceled and removed from the SportsForce platform at any time upon request, but this will have no effect on the requirement to complete any future payments.</p>
            <h4>Annual Membership:</h4>
            <p>Annual memberships automatically renew every year and can be canceled on the SportsForce website or can be canceled by contacting <a href="mailto:team@sportsforceonline.com">team@sportsforceonline.com.</a> SportsForce is not responsible for refunding memberships that automatically renew.</p>
            <h4>Monthly Membership:</h4>
            <p>Monthly memberships automatically renew every 30 days and can be canceled on the website or can be canceled by contacting <a href="mailto:team@sportsforceonline.com">team@sportsforceonline.com.</a> SportsForce is not responsible for refunding memberships that automatically renew.</p>
            <h4>Organization Membership:</h4>
            <p>An organization entering into a contract with SportsForce is subject to the agreement per the contract for the particular organization. For student-athletes that have memberships provided through an organization, SportsForce does not provide refunds for dues paid to an organization for the use of SportsForce.</p>
            <p>Organization Integration Policy: If the athlete’s organization joins SportsForce at the Organization Membership Level, his/her individual membership may change if desired. The policy goes into effect the date the individual membership is transferred to the organization’s account.</p>
            <p>Exception - Career Ending Injuries: Career ending injuries sustained after this agreement will be considered for justification to cancel future payments. A valid and dated doctor’s note must be provided in order to be considered for this exception.</p>
            <h4>Refunds</h4>
            <p>All SportsForce memberships, products and services are non-refundable.</p>
            <h3>Limitations on Use</h3>
            <p>Only one individual may access SportsForce.io at the same time using the same email or password, unless we agree otherwise. SportsForce.io reserve the right, at our sole discretion, to immediately revoke your account for scraping jobs, copying copyrighted text, or otherwise mis-using or mis-appropriating site content, including but not limited to, use on a "mirrored", competitive, or third party site. The use of automated programming on SportsForce.io, including but not limited to the use of a script to apply to jobs or to communicate with college coaches (e.g. the sending of profiles through SportsForce.io database), is contrary to the nature of our services and not permitted. In addition to the foregoing, you agree that you may not use the platform.<br />In any way that violates any applicable federal, state, local, or international law or regulation.
              For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</p>
            <p>To transmit, or procure the sending of, any mass advertising or promotional material, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation.
              To impersonate or attempt to impersonate another user, or any other person or entity (including, without limitation, by using email addresses or user names associated with such person or entity).
            </p>
            <p>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the platform, or which, as determined by us, may harm us or users of the platform, or expose them to liability.</p>
            <h3>Valid Email Address</h3>
            <p>You must supply and maintain a valid and verified email address in order to use this service. Invalid email addresses and those that have not been verified through the verification email sent upon sign-up will be deleted from our system and you will be denied access to the platform.</p>
            <h3>Additional Terms</h3>
            <p>Certain sections or pages on the platform may contain separate terms and conditions, which are in addition to these terms and conditions. In the event of a conflict, the additional terms and conditions will govern those sections or pages.</p>
            <h3>Governing Law & Forum</h3>
            <p>Use of the platform shall be governed by all applicable Federal laws of the United States of America and the laws of the State of California.<br />The exclusive forum & venue of any legal actions arising out of this contract shall be the State of California. Such actions shall neither be commenced in nor removed to federal court. This section shall not apply to subsequent actions to enforce a judgment entered in actions heard pursuant to this section.</p>
            <h3>Transferability</h3>
            <p>SportsForce may freely transfer all or any portion of their rights under this Agreement without notice.</p>
            <h3>Paid Video Services</h3>
            <p>All distribution of event video without consent is considered to be intellectual property rights theft. If an individual intends to distribute event film amongst a team, a team film order or organization integration must be purchased. Team film purchases grant film access to all rostered players and team coaches only for the associated event team. Film provided to a user through a premium subscription can be:</p>
            <ul className="list-type-point">
              <li>published directly to the user profile;</li>
              <li>used to create clips in the video editor; and</li>
              <li>used to create clips in the professional highlight built by the SportsForce post-production team.</li>
            </ul>
            <h3>Cancellations and Refunds for Film Packages</h3>
            <p>The following applies only to those events for which individual film packages are sold separately. These policies do not apply to events participating in other events. Orders may be canceled and fully refunded for any reason up until 1:00 PM EST the day prior to the start of the event. After the event, refunds will not be issued due to an inability to attend the event, lack of playing time, or weather delays/cancellations. If an athlete is injured, a full refund will be issued upon providing a doctor’s note. If your game schedule is changed, we will do our best to accommodate those schedules changes. If we do not film your games due to scheduling errors or changes, a refund will be issued for the cost of each game that was missed.</p>
            <p>If a premium membership is canceled, any video from the purchased section library will no longer be visible on the user’s profile.</p>
            <p>Highlight Video Edits<br />Redemption</p>
            <p>Highlight video edits may be redeemed directly through the platform or by contacting the SportsForce team.</p>
            <h4>Refunds</h4>
            <p>Highlight video edits purchased outside of a membership can only be refunded within 30 days of purchase. Redeemed edits are non-refundable.</p>
            <p>Highlight video edits added as part of a membership are subject to the refund conditions established by the membership.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsOfServices;
