import React from "react";
import OwlCarousel from "react-owl-carousel";

const Menu = ({tabApiCall, tabSel, resourceHeaderData}) => {
  // let { location } = useHistory();
  // let route = location.pathname.split("/")[2];

  // const [activeClass, setActiveClass] = React.useState("");
  // const toggleButton = (route) => {
  //   setActiveClass(route);
  // };
// useEffect(()=>{
//   setActiveClass(props.ResourceHeaderData?.length > 0 ? props.ResourceHeaderData?.[0]?.headername : "Stats")
// },[props.ResourceHeaderData])
  return (
    <>
      {resourceHeaderData &&
      resourceHeaderData.map((item, index) => {
          return (
            <li
              key={index}
              data-tabs={item.tab}
              className={
                tabSel == item?.id ? "current" : "inAcitve"
              }
            >
                <a
                  // to={`/resources/${route}/${item.headername}`}
                  onClick={() => {
                    tabApiCall && tabApiCall(item.id)
                  }}
                >
                  {item.headername && item.headername}
                </a>
            </li>
          );
        })}
    </>
  );
};
const options = {
  items: 4,
  margin: 10,
  responsiveClass: true,
  nav: true,
  dots: false,
  responsive: {
    0: {
      items: 1,
      center: true,
    },
    415: {
      items: 1,
      center: true,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 3,
    },
    1366: {
      items: 4,
    },
  },
};

const SlideTab = ({resourcesHeaderData, tabApiCall, tabSel}) => {
  return (
    <>
      <OwlCarousel className="owl-theme" {...options} startPosition={tabSel - 1}>
        <Menu
            resourceHeaderData={resourcesHeaderData}
              tabApiCall={tabApiCall}
              tabSel={tabSel}
        />
      </OwlCarousel>
    </>
  );
};

export default SlideTab;
