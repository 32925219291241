import { all, fork } from "redux-saga/effects";

import {watchAthleteSportListSaga} from './athleteSportList';

export default function* rootOnBoardingSagas() {
    yield all([

        fork(watchAthleteSportListSaga),

    ]);
}
