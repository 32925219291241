import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { athleteParentMappingStart } from "../../../../../../redux/athlete/onBoarding/athleteParentMapping";
import { athletesDetailsStart } from "../../../../../../redux/common/athletesDetails";
import { getUserInfo } from "../../../../../../services/authService";
import { getAllAthletesDataQuery } from "../../../../../common/CommonQuery";
import { allParentQuery } from "../../editUserProfile/ContactQuery";
import messagePopup from "../../../../../../utils/messagePopup";
import toastr from "toastr";

function Checklist(props) {
  const [completedSteps, setCompletedSteps] = useState(0);
  const [verifiedSteps, setVerifiedSteps] = useState(null);

  const history = useHistory();

  useEffect(() => {
    props.allParentListStart({
      query: allParentQuery,
      variables: {
        obj: {
          athleteid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.athleteData.data || props.athleteParentMapping.data) {
      let verifiedStepsT = {};
      let completedStepsT = 0;

      const { onboardingData, athletesData } = props.athleteData.data;
      verifiedStepsT["contactInfo"] = athletesData
        ? athletesData.athleteemailid || athletesData.athletephonenumber
          ? true
          : false
        : false;

      completedStepsT = verifiedStepsT["contactInfo"]
        ? completedStepsT + 1
        : completedStepsT;

      if (props.athleteParentMapping.data) {
        const { parentData } = props.athleteParentMapping.data;
        if (parentData && parentData.length) {
          verifiedStepsT["parent"] = true;
          completedStepsT += 1;
        }
      }

      verifiedStepsT["video"] = props.allVideoPhotoInfo.data
        ? props.allVideoPhotoInfo.data.allUploadedvideoandphotos.length
          ? true
          : false
        : false;
      completedStepsT = verifiedStepsT["video"]
        ? completedStepsT + 1
        : completedStepsT;

      verifiedStepsT["height"] = onboardingData
        ? onboardingData.heightinfeet
          ? true
          : onboardingData.heightininches
          ? true
          : false
        : false;

      // completedStepsT = verifiedStepsT["height"]
      //   ? completedStepsT + 1
      //   : completedStepsT;

      verifiedStepsT["weight"] = onboardingData
          ? !!onboardingData.weight ? true : false : false

      completedStepsT = verifiedStepsT["weight"] && verifiedStepsT["height"]//if both h/w is fill than only step will be completed
          ? completedStepsT + 1
          : completedStepsT;


      verifiedStepsT["gpa"] = onboardingData
        ? onboardingData.gpascale
          ? true
          : onboardingData.unweightedgpa
            ? true
            : false 
        : false;

      completedStepsT = verifiedStepsT["gpa"]
        ? completedStepsT + 1
        : completedStepsT;

      setCompletedSteps(completedStepsT);
      setVerifiedSteps(verifiedStepsT);
    }
  }, [
    props.athleteData.data,
    props.athleteParentMapping.data,
    props.allVideoPhotoInfo.data,
  ]);
  const changeRoutes = (e, route) => {
    e.preventDefault();
    history.push(route);
  };
  const openRequestProfilePopup = () => {
    //messagePopup("", "Congrats your request has been sent to the administrator, now you are ready to be recruited", "success")
    window.open('https://calendly.com/sportsforce/recruiting-assessment-round-robin','_blank');
  }
  return (
    <>
      <div className="verificationBox">
        <div className="head">
          <h5 className="title">Checklist for Profile Review</h5>
        </div>
        <div className="stepInfo">
          <div className="steps">
            <span
              className={
                completedSteps > 0
                  ? "passed" + " " + (completedSteps === 1 ? "active" : "")
                  : ""
              }
            ></span>
            <span
              className={
                completedSteps > 1
                  ? "passed" + " " + (completedSteps === 2 ? "active" : "")
                  : ""
              }
            ></span>
            <span
              className={
                completedSteps > 2
                  ? "passed" + " " + (completedSteps === 3 ? "active" : "")
                  : ""
              }
            ></span>
            <span
              className={
                completedSteps > 3
                  ? "passed" + " " + (completedSteps === 4 ? "active" : "")
                  : ""
              }
            ></span>
            <span
              className={
                completedSteps > 4
                  ? "passed" + " " + (completedSteps === 5 ? "active" : "")
                  : ""
              }
            ></span>
            {/* <span
              className={
                completedSteps > 5
                  ? "passed" + " " + (completedSteps === 6 ? "active" : "")
                  : ""
              }
            ></span> */}
          </div>
          <div className="flexBox"></div>
        </div>
        <div className="collapse show" id="collapseExample">
          <div className="card card-body">
            <ul className="verificationSteps">
              <li
                className={
                  verifiedSteps && verifiedSteps["contactInfo"]
                    ? "active"
                    : "unactive"
                }
                style={{ cursor: "pointer" }}
                onClick={(e) => changeRoutes(e, "/contact")}
              >
                <span>Athlete contact info</span>
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g transform="translate(-649.111 -507.111)">
                      <path
                        className="a"
                        d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                        transform="translate(649.111 507.111)"
                      />
                      <path
                        className="b"
                        d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                        transform="translate(511.436 348.256)"
                      />
                    </g>
                  </svg>
                </span>
              </li>
              <li
                className={
                  verifiedSteps && verifiedSteps["parent"]
                    ? "active"
                    : "unactive"
                }
                style={{ cursor: "pointer" }}
                onClick={(e) => changeRoutes(e, "/contact")}
              >
                <span>Parent / Guardian Contact Info</span>
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g transform="translate(-649.111 -507.111)">
                      <path
                        className="a"
                        d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                        transform="translate(649.111 507.111)"
                      />
                      <path
                        className="b"
                        d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                        transform="translate(511.436 348.256)"
                      />
                    </g>
                  </svg>
                </span>
              </li>
              <li
                className={
                  verifiedSteps && verifiedSteps["video"]
                    ? "active"
                    : "unactive"
                }
                style={{ cursor: "pointer" }}
                onClick={(e) => changeRoutes(e, "/videoPhotos")}
              >
                <span>Add Video</span>
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g transform="translate(-649.111 -507.111)">
                      <path
                        className="a"
                        d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                        transform="translate(649.111 507.111)"
                      />
                      <path
                        className="b"
                        d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                        transform="translate(511.436 348.256)"
                      />
                    </g>
                  </svg>
                </span>
              </li>
              <li
                className={
                  (verifiedSteps && verifiedSteps["height"] && verifiedSteps["weight"])
                    ? "active"
                    : "unactive"
                }
                style={{ cursor: "pointer" }}
                onClick={(e) => changeRoutes(e, "/vitals")}
              >
                <span>Height / Weight</span>
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g transform="translate(-649.111 -507.111)">
                      <path
                        className="a"
                        d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                        transform="translate(649.111 507.111)"
                      />
                      <path
                        className="b"
                        d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                        transform="translate(511.436 348.256)"
                      />
                    </g>
                  </svg>
                </span>
              </li>
              <li
                className={
                  verifiedSteps && verifiedSteps["gpa"] ? "active" : "unactive"
                }
                style={{ cursor: "pointer" }}
                onClick={(e) => changeRoutes(e, "/basic")}
              >
                <span>Add GPA</span>
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g transform="translate(-649.111 -507.111)">
                      <path
                        className="a"
                        d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                        transform="translate(649.111 507.111)"
                      />
                      <path
                        className="b"
                        d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                        transform="translate(511.436 348.256)"
                      />
                    </g>
                  </svg>
                </span>
              </li>
              {/* <li className="unactive" onClick={() => openRequestProfilePopup()} style={{ cursor: "pointer" }}>
                <span>Request Profile Review</span>
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g transform="translate(-649.111 -507.111)">
                      <path
                        className="a"
                        d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,0"
                        transform="translate(649.111 507.111)"
                      />
                      <path
                        className="b"
                        d="M152.6,167.008l-6.4,6.4a.983.983,0,0,1-1.391,0l-3.2-3.2a.984.984,0,1,1,1.391-1.391l2.5,2.5,5.7-5.7a.984.984,0,1,1,1.391,1.391Zm0,0"
                        transform="translate(511.436 348.256)"
                      />
                    </g>
                  </svg>
                </span>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    athleteData: state.common.athletesData,
    athleteParentMapping: state.athlete.onBoarding.athleteParentMapping,
    allVideoPhotoInfo: state.athlete.editUserProfileData.allVideoPhotoInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // athleteListStart: (data) => dispatch(athletesDetailsStart(data)),
    allParentListStart: (data) => dispatch(athleteParentMappingStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
