import React, { useEffect, useState } from 'react'
import ClaimProfileHeader from "../auth/claimProfile/claimProfileStep1/ClaimProfileHeader";
import DashboardHeader from "../../layouts/header/DashboardHeader";
import { getAuthToken } from "../../../services/authService";
import SportForceLogo from "./assets/img/logo-red-on-black.png";
import AmericanFlag from "../../layouts/assets/Img/americanFlag.png";
import UODLogo from './assets/img/university-of-delaware-logo.png';
import AthleteThumbnail from "./assets/img/athlete-video-thumbnail.png";
import AndrewBeinbrink from "./assets/img/andrew-beinbrink.png";
import DaniKabat from "./assets/img/dani-kabat.png";
import AndyBreen from "./assets/img/andy-breen.png";
import ClareGaskell from "./assets/img/clare-gaskell.png";
import MalloryBory from "./assets/img/mallory-bory.png";
import { Link } from "react-router-dom";
import './assets/css/lading-page.scss';

const InterviewSurvey = (props) => {
    const [headerType, setHeaderType] = useState(true),
        isToken = props?.match?.params?.isToken == 1 ? true : false;
    useEffect(() => {
        getStatus(props)
        //for scrolling the page on top
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 1000)
    })
    const getStatus = (props) => {
        if (!!getAuthToken() || isToken) {
            setHeaderType(false)
        }
    }
    return (
        <>
            {
                headerType ?
                    <ClaimProfileHeader />
                    :
                    <DashboardHeader />
            }

            <section class="interview-survey-sec">
                <div class="container">
                    <div class="top-block">
                        <div class="brand-logo">
                            <a href={void 0}>
                                <img src={SportForceLogo} alt="SportsForce" />
                            </a>
                        </div>
                        <div class="sec-title">Interview Survey</div>
                    </div>
                    <div class="survey-form-box">
                        <form>
                            <div class="fieldBox flexBox">
                                <div class="half">
                                    <label>Email <sup>*</sup></label>
                                    <input type="email" placeholder="youremail@domain.com" />
                                </div>
                                <div class="half">
                                    <label>First name <sup>*</sup></label>
                                    <input type="text" placeholder="patrick" />
                                </div>
                            </div>
                            <div class="fieldBox flexBox phone">
                                <div class="half">
                                    <label>Last name <sup>*</sup></label>
                                    <input type="text" placeholder="patrick" />
                                </div>
                                <div class="half">
                                    <label>Mobile phone <sup>*</sup></label>
                                    <div class="flexBox">
                                        <div class="countryFlag">
                                            <img src={AmericanFlag} alt="countryFlag" />
                                        </div>
                                        <select>
                                            <option>+1</option>
                                            <option>+91</option>
                                            <option>+92</option>
                                        </select>
                                        <input type="text" placeholder="Mobile phone" />
                                    </div>
                                </div>
                            </div>
                            <div class="fieldBox flexBox">
                                <div class="half">
                                    <label>City <sup>*</sup></label>
                                    <input type="text" placeholder="enter city" />
                                </div>
                                <div class="half">
                                    <label>State <sup>*</sup></label>
                                    <input type="text" placeholder="enter state" />
                                </div>
                            </div>
                            <div class="fieldBox flexBox">
                                <div class="half">
                                    <label>Did you play college baseball? <sup>*</sup></label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                                <div class="half">
                                    <label>What is highest level of college you played? <sup>*</sup></label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="fieldBox flexBox">
                                <div class="half">
                                    <label>Did you play professionally? <sup>*</sup></label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                                <div class="half">
                                    <label>Have you coached in college? <sup>*</sup></label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="fieldBox">
                                <label>What levels have you coached or scouted? <sup>*</sup></label>
                                <ul class="checkbox-list">
                                    <li>
                                        <input type="checkbox" />
                                        <label>Professional</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>College</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>High School</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Travel Ball</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Private Instruction</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Youth</label>
                                    </li>
                                </ul>
                            </div>
                            <div class="fieldBox">
                                <label>If yes, please describe your coaching / scouting experience?</label>
                                <textarea></textarea>
                            </div>
                            <div class="fieldBox">
                                <label>Are you currently coaching for a college, high school, travel team, facility or private instruction or scouting for a professional team/league?</label>
                                <textarea></textarea>
                            </div>
                            <div class="fieldBox flexBox">
                                <div class="half">
                                    <label>What level of knowledge do you have about the college recruiting process, recruiting timeline, NCAA rules, etc?</label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="fieldBox">
                                <label>Do you have experience recruiting players as a college coach or experience recruiting for a sports team (ex. Club/Travel, Summer, Professional team)? <sup>*</sup></label>
                                <textarea></textarea>
                            </div>
                            <div class="fieldBox">
                                <label>Why do you want to work in the college recruiting / sports industry? <sup>*</sup></label>
                                <textarea></textarea>
                            </div>
                            <div class="fieldBox flexBox">
                                <div class="half">
                                    <label>What type of income opportunity are you looking for?</label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                                <div class="half">
                                    <label>What would you like your monthly income to be from Sportsforce?</label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="fieldBox flexBox">
                                <div class="half">
                                    <label>How much time per week would you be able to commit to work? <sup>*</sup></label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                                <div class="half">
                                    <label>Are you seriously looking for remote work opportunities? <sup>*</sup></label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="fieldBox">
                                <label>What levels have you coached or scouted? <sup>*</sup></label>
                                <ul class="checkbox-list">
                                    <li>
                                        <input checked type="checkbox" />
                                        <label>Email</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>MS Office</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Google suite</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Powerpoint / Google suite</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>CRM software (ex. SalesForce,HubSpot etc)</label>
                                    </li>
                                </ul>
                            </div>
                            <div class="fieldBox flexBox">
                                <div class="half">
                                    <label>Are you comfortable using Social Media? <sup>*</sup></label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="fieldBox">
                                <label>Do you have any professional sales experience? <sup>*</sup></label>
                                <ul class="checkbox-list">
                                    <li>
                                        <input checked type="checkbox" />
                                        <label>No sales experience</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Inside / Field Sales</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Phone Sales</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Client account / customer support</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>B2B sales</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Group presentation sales</label>
                                    </li>
                                </ul>
                            </div>
                            <div class="fieldBox flexBox">
                                <div class="half">
                                    <label>Have you done any virtual presentations with Zoom, etc. <sup>*</sup></label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                                <div class="half">
                                    <label>Have you done any group college recruiting talks in person or online?</label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="fieldBox">
                                <label>Have you worked for yourself in the past? <sup>*</sup></label>
                                <ul class="checkbox-list">
                                    <li>
                                        <input checked type="checkbox" />
                                        <label>Contractor (1099)</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Owned a business</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Independent sales</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>Commission only</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" />
                                        <label>No, I haven't</label>
                                    </li>
                                </ul>
                            </div>
                            <div class="fieldBox">
                                <label>If yes please share some quick details <sup>*</sup></label>
                                <textarea></textarea>
                            </div>
                            <div class="fieldBox flexBox">
                                <div class="half">
                                    <label>In previous jobs have you had to ask someone for payment information over the phone or in person? <sup>*</sup></label>
                                    <select>
                                        <option selected="" value="1">Choose option</option>
                                        <option value="2">option 2</option>
                                        <option value="3">option 3</option>
                                        <option value="4">option 4</option>
                                        <option value="5">option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="fieldBox">
                                <label>Do you have any current or upcoming time commitment issues or concerns if this role is a great fit for you? (ex. please share details) <sup>*</sup></label>
                                <textarea></textarea>
                            </div>
                            <div class="fieldBox">
                                <label>Do you have any additional comments, questions or concerns about the potential role at SportsForce? (share details) <sup>*</sup></label>
                                <textarea></textarea>
                            </div>
                            <div class="fieldBox text-center">
                                <button class="btn" disabled={true}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default InterviewSurvey