import React, { useEffect } from "react";
import { useHistory } from "react-router";

export default function Logout() {
  const history = useHistory();
  useEffect(() => {
    history.push({
      pathname: "/login",
      state: {
        reload: true,
      },
    });
    if(localStorage.getItem('rememberMe')){
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      localStorage.removeItem('validUserId');
      localStorage.removeItem('fileName');
      localStorage.removeItem('URL');
    }
    else{
      localStorage.clear();
    }
  }, []);
  return null;
}
