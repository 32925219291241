import React from 'react'
import { connect } from 'react-redux';
import { claimProfileStart } from '../../../../../redux/auth/signup/userClaimProfile';

function ClaimProfileModal(props) {
    const onSave = () => {
        props.handleSaveCoachData()
        props.claimProfileStart({
            data: {
              email: props.singleProfileData.coachEmail,
            },
             handleOtpModal: handlepreviewModal,
          });
    }
    const handlepreviewModal = () => {
        props.closeClaimProfileModal()
    }
    return (
        <div>
            <div className="modal fade common-modal show" style={{display:"block"}} id="CoachClaimProfileModal" tabindex="-1" role="dialog"
        aria-labelledby="ClaimProfileLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <form className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Claim your profile</h5>
                    <button onClick={()=>props.closeClaimProfileModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label>Full Name</label>
                        <div className="name-text"
                        style={{display:"block", textAlign:"left", fontWeight:"bold"}}
                        >{props?.singleProfileData?.coachFirstName} {props?.singleProfileData?.coachLastName}</div>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input className="form-control" disabled={true} value={props?.singleProfileData?.coachEmail} type="email" name="email" id="email"
                            placeholder="jamesdavid@gmail.com"/>
                    </div>
                    <div className="form-group">
                        <label>Mobile Number</label>
                        <input className="form-control" type="text" name="phone" id="phone" placeholder="Enter your phone number"/>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={()=>onSave()} type="button" className="btn blue" data-dismiss="modal" data-toggle="modal"
                        data-target="#ClaimProfileOtpModal">Save</button>
                    <button onClick={()=>props.closeClaimProfileModal()} type="button" className="btn primary" data-dismiss="modal">Cancel</button>
                </div>
            </form>
        </div>
    </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      claimProfileData: state.auth.claimProfile,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      claimProfileStart: (data) => dispatch(claimProfileStart(data)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ClaimProfileModal);
