import {fetchGraphMethod, fetchMethod} from "../../services/httpService";
import {saveUserActProfile, saveUserProfile} from './userProfileQuery'
import swal from 'sweetalert'
import {getAuthToken, getUserInfo} from "../../services/authService";
import toastr from 'toastr'
export const userProfileApi = async (variable, isAuth, isMsgShow)=>{
    if(variable?.obj?.profileviewto != getUserInfo()?.id){
        const res = await fetchGraphMethod(saveUserProfile, variable, isAuth)
        if(res?.status == 200){
            const _r = await fetchGraphMethod(saveUserActProfile, {obj:{viewprofileid :res?.data?.data?.saveUserprofileview?.id ?? 0}}, true )
            if(_r && _r?.status == 200 && !!isMsgShow){
                swal({text:"Athlete Followed Successfully", icon:"success"})
            }
        }
    }
}

export const athleteFollowApi = async (followerId, followingId, isMsgShow, showData) =>{
    const body ={
            followerId : !!followerId ? followerId.toString() : "",
            followingId: !!followingId ? followingId.toString() : ""
    },
    res =await fetchMethod('/api/Followers/FollowUnfollow', body, "POST",true)
    if(res && res?.status == 200 && isMsgShow && showData === "Follow"){
        swal({text:"Athlete Followed Successfully", icon:"success"})
    }
    if(res && res?.status == 200 && isMsgShow && showData === "Unfollow"){
        swal({text:"Athlete Unfollowed Successfully", icon:"success"})
    }
}

export const flameAPI = async (title, athleteID, userID, isPopShow = false, role) =>{
    const body = {
        title: !!title ? title : "flamefromprofile",
        athleteId: !!athleteID ? athleteID : 0,   // jisse hum flames kr rhe hai
        userId: userID,   // jo flame kr raha hai,
        role: role
    }
    const res = await fetchMethod('/api/Notificationsettingmasters/sendnotification', body, 'POST', true)
    if (res?.data?.response?.status == 200 && isPopShow){
        swal({text: "Notfication send", icon: "success"})
    }else{
        console.log("flame timeline", res)
    }
}

export const vidCountIncre = async (data, isAthlete = false) =>{
    if ((data?.userid) != getUserInfo()?.id){
        const body = {
            "videoid": data?.id ?? 0,
            "userid": (isAthlete && !!getAuthToken()) ? getUserInfo()?.id : 0,
            "adminorAdvisorid": !isAthlete ? getUserInfo()?.id : 0
        }
        const _res = await fetchMethod('/api/Viewvideos/IncreaseCount', body,'POST',null)
        if (_res?.status == 200){
            console.log("count increase successfully")
        }else {
            toastr.error('Failed to increase video count')
        }
    }
}

export const visitorViewCount = async (auth, athId, visitorType, isMSG) =>{
    const body ={
        profileViewTo:+athId,
        "isAccessToken": auth,
        "profileViewby": 0,
        "profileViewedByAdvisorId": 0,
        "userRole": visitorType
    }
    const resIcre = await fetchMethod('/api/userprofileviews/increaseProfileCount', body, "POST", false)
    if (resIcre?.status == 200){
        if (isMSG) toastr.success("profile view successfully")
        return true
    }else{
        console.log("error", resIcre)
        return false
    }
}