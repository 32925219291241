import React from 'react';
import closeGrayIcon from "../views/athlet/dashboard/Message/img/close-gray-icon.svg";
import trashCircleIcon from "../views/athlet/dashboard/Message/img/trash-circle-icon.svg";


export default function DeleteItem({ onDeleteClick, onCancelClick ,eventName, deleteMessage = true }){
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img src={trashCircleIcon} alt="trash Circle Icon" />
              </div>
              <div className="content-box">
                {deleteMessage ? <h4>
                  Are you sure you want to
                  <br /> delete this {eventName}?
                </h4> : <h4> {eventName} </h4>
                }
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn blue"
                data-dismiss="modal"
                onClick={onDeleteClick}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
