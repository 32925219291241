import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod, fetchMethod } from "../../services/httpService"
import messagePopup from "../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    ALL_COLLEGE_CONFERENCES_LIST_START: 'ALL_COLLEGE_CONFERENCES_LIST_START',
    ALL_COLLEGE_CONFERENCES_SUCCESS: 'ALL_COLLEGE_CONFERENCES_SUCCESS',
    ALL_COLLEGE_CONFERENCES_FAILED: 'ALL_COLLEGE_CONFERENCES_FAILED'
}

// ============================================ Actions ========================================================
export const allCollegeConferencesListStart = (data) => ({
   type: actionType.ALL_COLLEGE_CONFERENCES_LIST_START,
   payload: data
})

export const allCollegeConferencesSuccess = (data) => ({
    type: actionType.ALL_COLLEGE_CONFERENCES_SUCCESS,
    payload: data
})

export const allCollegeConferencesFailed = (data) => ({
    type: actionType.ALL_COLLEGE_CONFERENCES_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function allCollegeConferencesReducer(state = INIT_STATE,action){
  switch(action.type){
      case actionType.ALL_COLLEGE_CONFERENCES_LIST_START: 
        return {
            ...state,
            loading: true
        }
      case actionType.ALL_COLLEGE_CONFERENCES_SUCCESS:
        return {
            ...state,
            loading: false,
            data: action.payload,
            error: null
        }  
      case actionType.ALL_COLLEGE_CONFERENCES_FAILED:
        return {
            ...state,
            loading: false,
            data: null,
            error: action.payload
        }       
      default: return {...state}  
  }
} 

// ===================================================== side effect =============================================

export function* allCollegeConferencesSaga(action){
   try {
    const response = yield fetchGraphMethod(action.payload.query, action.payload.variables, true);
    if(response && response.status === 200){
       const allCollegeConferences = response.data.data.allCollegeconferences.Collegeconferences ? response.data.data.allCollegeconferences.Collegeconferences : null
       yield put(allCollegeConferencesSuccess(allCollegeConferences));
    } else {
      messagePopup('','College Conferences List failed','error')   
      yield put(allCollegeConferencesFailed('College Conferences List failed')) 
    }
   } catch (error) {
     messagePopup('','College Conferences List failed','error')   
     yield put(allCollegeConferencesFailed('College Conferences List failed'))
   }
}

export function* watchAllCollegeConferencesSaga(){
    yield takeEvery(actionType.ALL_COLLEGE_CONFERENCES_LIST_START, allCollegeConferencesSaga)
}