import { put, takeEvery } from "redux-saga/effects"
import { fetchGraphMethod } from "../../../services/httpService"
import messagePopup from "../../../utils/messagePopup"

// ========================================== Action Type =====================================================
export const actionType = {
    COLLEGE_DIVISION_LIST_START: 'COLLEGE_DIVISION_LIST_START',
    COLLEGE_DIVISION_LIST_SUCCESS: 'COLLEGE_DIVISION_LIST_SUCCESS',
    COLLEGE_DIVISION_LIST_FAILED: 'COLLEGE_DIVISION_LIST_FAILED'
}

// ============================================ Actions ========================================================
export const collegeDivisionListStart = (data) => ({
    type: actionType.COLLEGE_DIVISION_LIST_START,
    payload: data
})

export const collegeDivisionListSuccess = (data) => ({
    type: actionType.COLLEGE_DIVISION_LIST_SUCCESS,
    payload: data
})

export const collegeDivisionListFailed = (data) => ({
    type: actionType.COLLEGE_DIVISION_LIST_FAILED,
    payload: data
})

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null
}

export default function collegeDivisionListReducer(state = INIT_STATE,action){
    switch(action.type){
        case actionType.COLLEGE_DIVISION_LIST_START:
            return {
                ...state,
                loading: true
            }
        case actionType.COLLEGE_DIVISION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        case actionType.COLLEGE_DIVISION_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        default: return {...state}
    }
}

// ===================================================== side effect =============================================

export function* collegeDivisionListSaga(action){
    try {
        const response = yield fetchGraphMethod(action.payload.query,action.payload.variables,true);
        if(response && response.status === 200){
            const collegeLevelList = response.data.data.allCollegedivisions.Collegedivisions ? response.data.data.allCollegedivisions.Collegedivisions : null
            yield put(collegeDivisionListSuccess(collegeLevelList));
        } else {
            messagePopup('','College Division List failed','error')
            yield put(collegeDivisionListFailed('College Division List failed'))
        }
    } catch (error) {
        messagePopup('',' College Division List failed','error')
        yield put(collegeDivisionListFailed('College Division List failed'))
    }
}

export function* watchCollegeDivisionListSaga(){
    yield takeEvery(actionType.COLLEGE_DIVISION_LIST_START, collegeDivisionListSaga)
}