import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { searchStateByRegionStart } from '../../../../../redux/athlete/dashboard/colleges/searchStateByRegion';
import { regionsListStart } from '../../../../../redux/common/allRegions';
import { geographicListStart } from "../../../../../redux/coach/dashboard/myRecruits/geographic";
import { stateListStart } from '../../../../../redux/common/allStates';
import { fetchGraphMethod } from '../../../../../services/httpService';
import { getAllStatesQuery } from '../../../../common/CommonQuery';
import { getAllRegionsQuery } from '../../../athlet/dashboard/colleges/searchColleges/SearchCollegesQuery';
import { saveGeographicsDataQuery, deleteQueryData, getAllGeographicQuery } from './MyRecruitsQuery';
import ScreenSwitch from './ScreenSwitch';
import messagePopup from '../../../../../utils/messagePopup';
import toastr from 'toastr';


function GeographicTab(props) {
    const [regionsData, setRegionsData] = useState();
    const [statesData, setStatesData] = useState();
    const [regionArray, setRegionArray] = useState([]);
    const [selected, setSelected] = useState({});
    const [regionSelected, setRegionSelected] = useState({});
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [statesByRegions, setStatesByRegions] = useState([]);
    const [isSaved, setIsSaved] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [stateRegionSearch, setStateRegionSearch] = useState(undefined);
    const [selectedState, setSelectedState] = useState([]);
    const [selectAllStates, setSelectAllStates] = useState(false);
    useEffect(()=> {
    getAllGeographicData();
    getAllregionsData();
    getAllStatesData();
    },[])

    useEffect(()=>{
      if(props?.geographicList?.data?.data?.length > 0){
        props?.geographicList?.data?.data?.map(item=>{
          if(item.isallstate){
            let states = [];
            let consolidateArray = [];
            statesData.map(newItem=>{
              consolidateArray[newItem.id] = true;
              states.push({id: newItem.id})
            });
            setSelectAllStates(true);
            setSelected(consolidateArray);
            setSelectedState(states)
          }
          else{
            if(item.regionid){
            let regionArrayData = [];
            regionArrayData[item.regionid] = true;
            setRegionSelected(regionArrayData);
            setSelectedRegion([{id: item.regionid}])
            let consolidateArray = [];
            consolidateArray[item.stateid] = true;
            setSelected(consolidateArray);
            setSelectedState([{id: item.stateid}])
            }
            else{
            let consolidateArray = [];
            consolidateArray[item.stateid] = true;
            setSelected(consolidateArray);
            setSelectedState([{id: item.stateid}])
            }
          }
        })
      }
    },[props.geographicList.data])

    useEffect(()=> {
    if(props.regionsList.data ){
        setRegionsData(props.regionsList.data);
    }
    }, [props.regionsList.data])

    useEffect(()=> {
    if(props.statesList.data){
        setStatesData(props.statesList.data);
        setTotalCount(props.statesList.data.length)
    }
    }, props.statesList.data)

    useEffect(()=> {
    let states = [];
    if(props.stateByRegionList.data ){
        const regionStates = props.stateByRegionList.data && props.stateByRegionList.data.map((state)=> {
            states.push(state.id)
        })
    }
    if(regionArray && regionArray.length !== 0){
        setStatesByRegions(states);
    }else{
        setStatesByRegions([]);
    }

    }, [props.stateByRegionList.data])
    
    const getAllregionsData = () => {
        props.regionsListStart({
            query : getAllRegionsQuery
        })
    }
    const getAllStatesData = () => {
        props.stateListStart({
            query: getAllStatesQuery
          });
    }
    const getAllGeographicData = () => {
      props.geographicListStart({
        query: getAllGeographicQuery,
        variables:{
          where:{
            coachid: parseFloat(JSON.parse(localStorage.getItem('user')).id)
          }
        }
      });
    }
    const saveGeoGraphicsData = async () => {
      let data = [];
      if(selectedRegion.length === 0 && selectedState.length === 0){
        // messagePopup("", "Please select any region/state", "warning");
        toastr.warning("Please select any region/state", "", {
          progressBar : true,timeOut: 2000,
          closeButton: true, positionClass: "toast-top-full-width",
          showEasing: "swing", fadeIn: 40000,
          preventDuplicates: true,
        })
      }
      else{
      if(selectedRegion?.length > 0){
        let regionData = [];
        selectedRegion?.map(item=>{
          if(item?.stateregionidMap?.States?.length > 0){
            item?.stateregionidMap?.States?.map(newData=>{
              regionData.push({regionid: parseFloat(item.id), stateid: parseFloat(newData.id), coachid: parseFloat(JSON.parse(localStorage.getItem('user')).id)})
            })
          }
          else{
          regionData.push({regionid: parseFloat(item.id), coachid: parseFloat(JSON.parse(localStorage.getItem('user')).id)})
          }
        })
        data = regionData;
      }
      else if(selectedState?.length > 0){
        let stateData = [];
        selectedState?.map(item=>{
          stateData.push({stateid: parseFloat(item.id), coachid: parseFloat(JSON.parse(localStorage.getItem('user')).id)})
        })
        data = stateData;
      }
      if(data?.length > 0){
        let variables = selectAllStates === true ? 
        {
            data : {
                isallstate : 1,
                coachid: parseFloat(JSON.parse(localStorage.getItem('user')).id)
            }
        } : {
            data: data
          }
        let testObj = {
          where: {
            coachid: parseFloat(JSON.parse(localStorage.getItem('user')).id)
          }
        }
        const resData = await fetchGraphMethod(deleteQueryData, testObj, true);
        if(resData?.status === 200 && resData?.data?.data?.GeographicDestroyAll){
        const res = await fetchGraphMethod(
            saveGeographicsDataQuery,
            variables,
            true
        )
        if(res?.status === 200 && res?.data?.errors){
          // messagePopup("", "Geographic data not saved", "error")
          toastr.error("Geographic data not saved", "", {
            progressBar : true,timeOut: 2000,
            closeButton: true, positionClass: "toast-top-full-width",
            showEasing: "swing", fadeIn: 40000,
            preventDuplicates: true,
          })
        }
        else if(res?.status === 200 && res?.data?.data?.GeographicCreate?.id === null){
          // messagePopup("", "Geographic data saved successfully", "success");
          toastr.success("Geographic data saved successfully", "", {
            progressBar : true,timeOut: 2000,
            closeButton: true, positionClass: "toast-top-full-width",
            showEasing: "swing", fadeIn: 40000,
          })
          // setSelectedRegion([]);
          // setSelected({});
          // setSelectedState([]);
          // setRegionSelected({});
          // setStateRegionSearch(undefined);
          // setSelectAllStates(false);
          setIsSaved(true);
          getAllregionsData();
          getAllStatesData();
        }
        }
        else{
          // messagePopup("", "Delete geographic data not saved", "error")
          toastr.error("Delete geographic data not saved", "", {
            progressBar : true,timeOut: 2000,
            closeButton: true, positionClass: "toast-top-full-width",
            showEasing: "swing", fadeIn: 40000,
            preventDuplicates: true,
          })
        }
      }
      }
    }
    
  const handleRegionCheckboxes = (id, region, e) => {
    const newSelected = Object.assign({}, regionSelected);
  newSelected[id] = !regionSelected[id];
  setRegionSelected(newSelected);
  const selectedRegionList = [...selectedRegion];
  let arr = selectedRegionList.filter((val) => val.id !== id);
    if(e.target.checked){
  if (!arr.length) {
    setSelectedRegion([]);
  }
  if (arr.length === selectedRegionList.length) {
    setSelectedRegion([...selectedRegion,region])
    let consolidateArray = selected;
    let selectedArr = selectedState;
    let data = region?.stateregionidMap?.States?.length > 0 ? region?.stateregionidMap?.States?.map(item=>{
      consolidateArray[item.id] = true;
      selectedArr.push({id: item.id})
    }) : [];
    setSelected(consolidateArray);
    setSelectedState(selectedArr);
  } else {
    setSelectedRegion(arr);
    let consolidateArray = selected;
    let selectedArr = selectedState;
    let data = region?.stateregionidMap?.States?.length > 0 ? region?.stateregionidMap?.States?.map(item=>{
      consolidateArray[item.id] = true;
      selectedArr.push({id: item.id})
    }) : [];
    setSelected(consolidateArray);
    setSelectedState(selectedArr);
  }
  }
  else{
    setSelectedRegion(arr);
    let consolidateArray = [];
    let selectedArr = [];
    let data = arr?.[0]?.stateregionidMap?.States?.length > 0 ? arr?.[0]?.stateregionidMap?.States?.map(item=>{
      consolidateArray[item.id] = true;
      selectedArr.push({id: item.id})
    }) : [];
    setSelected(consolidateArray);
    setSelectedState(selectedArr);
  }
  setIsSaved(false);
  }
const handleselectedAllState = () => {
  let states = [];
  const allStatesData = statesData?.length > 0 ? statesData.map((state)=> {
   states.push({id: state.id});
  }) : null;
    let consolidateArray = [];
    states.map(item=>{
      consolidateArray[item.id] = true;
    });
    setSelectAllStates(true);
    setSelected(consolidateArray);
    setSelectedState(states)
}

const handleChange = e => {
  const { value } = e.target;
  setStateRegionSearch(value)
  setIsSaved(false);
  if(value?.length > 2){
    const filterState = statesData.filter(i=>{
      if(i?.statename?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase())){
        return true
      }
      return false;
    });
    const filterRegion = regionsData.filter(i=>{
      if(i?.regionname?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase())){
        return true
      }
      return false;
    });
    if(filterState?.length > 0){
      return setStatesData(filterState);
    }
    if(filterRegion?.length > 0){
      return setRegionsData(filterRegion);
    }
  }
  else{
    getAllregionsData();
    getAllStatesData();
  }
}

const handleSelectAll = (e) => {
    if(e.target.checked){
       handleselectedAllState();
    }else{
        setSelectAllStates(false);
        setSelectedState([]);
        setSelected({});
    }
    setIsSaved(false);
}

const onRowSelected = (sarray) => {
  if (sarray.length === totalCount) {
    setSelectAllStates(true)
  } else {
    setSelectAllStates(false)
  }
};

useEffect(()=>{
  if(selectedState?.length > 0)
    onRowSelected(selectedState)
},[selectedState])

useEffect(()=>{
  props.handleCheckSaved(isSaved)
},[isSaved])

const handleStatesSelect = (id, state, e) => {
  const newSelected = Object.assign({}, selected);
  newSelected[id] = !selected[id];
  setSelected(newSelected);
  const selectedStatesList = [...selectedState];
  let arr = selectedStatesList.filter((val) => val.id !== id);
  if (!arr.length) {
    setSelectedState([]);
  }
  if (arr.length === selectedStatesList.length) {
   setSelectedState([...selectedState,state])
  } else {
    setSelectedState(arr);
  }
  if(!e.target.checked){
     return selectedRegion?.length > 0 ? selectedRegion?.map(item=>{
       return item?.stateregionidMap?.States?.length > 0 ? item?.stateregionidMap?.States?.map(newItem=>{
          if(newItem.id === id){
            const newSelected = Object.assign({}, regionSelected);
            newSelected[item.id] = !regionSelected[item.id];
            setRegionSelected(newSelected);
            delete regionSelected.regionid;
            let arr = selectedRegion.filter((val) => {
             return val.id !== item.id});
            setSelectedRegion(arr);
          }
        }) : ""
      }) : "";
  }
  setIsSaved(false);
}

return <div>
      <div className="top-box">
                    <p>
                      Set your program's geographic preferences to help improve
                      your potential athlete recommendations.
                    </p>
                  </div>
                  <div className="recruiting-detail-box">
                    <div className="field-box-item">
                      <div className="fieldBox">
                        <div className="search-box-wrap">
                          <input
                            type="text"
                            placeholder="Search by State / Region"
                            value={stateRegionSearch || ""}
                            disabled={selectAllStates ? true : false}
                            onChange={(event)=>{handleChange(event)}}
                          />
                        </div>
                      </div>
                      <div className="fieldBox">
                        <ul className="checkbox-group">
                          <li>
                            <span className="checkbox">
                              <input type="checkbox" checked={selectAllStates} onChange={(e)=>handleSelectAll(e)}/>
                              <label>All State</label>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="fieldBox">
                        <h5>Region</h5>
                        <ul className="checkbox-group">
                            {regionsData?.length > 0 ? regionsData.map((region, index)=> {
                                return (
                          <li key={index}>
                            <span className="checkbox">
                              <input type="checkbox" 
                              disabled={selectAllStates ? true : false}
                              onChange={(e)=>handleRegionCheckboxes(region.id, region, e)} 
                              checked={regionSelected[region.id] === true} 
                              />
                              <label>{region.regionname}</label>
                            </span>
                          </li>
                        )
                            }): <span>No data found</span>}
                        </ul>
                      </div>
                      <div className="fieldBox">
                        <h5>State/Province</h5>
                        <ul className="checkbox-group">
                            {statesData?.length > 0 ? statesData.map((states, index)=> {
                                return (
                                <li key ={index}>
                                    <span className="checkbox">
                                    <input type="checkbox" 
                                    onChange={(e)=>handleStatesSelect(states.id, states, e)}
                                    checked={selected[states.id] === true}
                                    />
                                    <label>{states.statename}</label>
                                    </span>
                                </li>
                                )
                            }) : <span>No record found</span>}
                        </ul>
                      </div>
                      <div className="btn-wrap">
                        <div className="item">
                          <button className="btn blue" onClick={()=>saveGeoGraphicsData()}>Save</button>
                        </div>
                          <div className="next-prev-button">
                          <ScreenSwitch
                            prevLink={()=>props.handleSwitchTabData("requirements")}
                            nextLink={()=>props.handleSwitchTabData("program")}
                            isSaved={isSaved}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
  </div>;
}
const mapStateToProps = (state) => {
  return {
    regionsList : state.common.regionsList,
    statesList : state.common.states,
    geographicList: state.coach.coachDashboard.geographicList,
    stateByRegionList : state.athlete.dashboard.colleges.serachStateByRegion,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        regionsListStart : (data) => dispatch(regionsListStart(data)),
        stateListStart: (data) => dispatch(stateListStart(data)),
        geographicListStart: (data)=> dispatch(geographicListStart(data)),
        searchStateByRegionStart: (data) => dispatch(searchStateByRegionStart(data)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GeographicTab);
