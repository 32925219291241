import { all, fork } from "redux-saga/effects";
import { watchCoachActValueListSaga } from "./myRecruits/allCoachActValues";
import { watchCoachGpaRangeListSaga } from "./myRecruits/allCoachgparanges";
import { watchCoachSatValueListSaga } from "./myRecruits/allCoachSatValue";
import { watchRequirementListSaga } from "./myRecruits/allCoachRequirements";
import { watchRecruitingListSaga } from "./myRecruits/recruitingList";
import { watchRecruitingPhilosophyListSaga } from "./myRecruits/recruitingPhilosophy";
import { watchUpdateAllRecruitingListSaga } from "./myRecruits/updateAllRecruitingPhilosophy";
import { watchClaimProfileCoachListSaga } from "./searchClaimprofileCoach";
import { watchCollegeCoachListSaga } from "./searchCoachBySportIdCollegeId";
import { watchCatchersTimeRangesListSaga } from "./myRecruits/allCoachCatchersTimeRanges";
import { watchSaveCoachProgramsListSaga } from "./myRecruits/allCoachPrograms";
import { watchGeographicListSaga } from "./myRecruits/geographic";

export default function* rootCoachDashboardsagas() {
    yield all([
      fork(watchClaimProfileCoachListSaga),
      fork(watchCollegeCoachListSaga),
      fork(watchRecruitingListSaga),
      fork(watchRecruitingPhilosophyListSaga),
      fork(watchUpdateAllRecruitingListSaga),
      fork(watchCoachGpaRangeListSaga),
      fork(watchCoachSatValueListSaga),
      fork(watchCoachActValueListSaga),
      fork(watchRequirementListSaga),
      fork(watchCatchersTimeRangesListSaga),
      fork(watchSaveCoachProgramsListSaga),
      fork(watchGeographicListSaga)
    ]);
  }