import React, { useEffect, useState } from 'react'
import ClaimProfileHeader from "../auth/claimProfile/claimProfileStep1/ClaimProfileHeader";
import DashboardHeader from "../../layouts/header/DashboardHeader";
import { getAuthToken } from "../../../services/authService";
import RightFitImage from "./assets/img/find-the-right-fit-img.png";
import TotalTransparencyImage from "./assets/img/total-transparency-img.png";
import DataDrivenImage from "./assets/img/data-driven-profile-mockup-img.png";
import ShowCoachImage from "./assets/img/show-coaches-your-game-img.png";
import SuccessStoriesImage from "./assets/img/success-stories-img-01.png";
import CallStateImage from "./assets/img/cal-state-fullerton-titans-logo.png";
import AthleteThumbnail from "./assets/img/athlete-video-thumbnail.png";
import OwlCarousel from "react-owl-carousel";
import { fetchMethod } from '../../../services/httpService';
import './assets/css/lading-page.scss';
const helpSlide = {
    loop: true,
    nav: true,
    dots: false,
    items: 1,
};
const BaseballScholarship = (props) => {
    const [headerType, setHeaderType] = useState(true),
        isToken = props?.match?.params?.isToken == 1 ? true : false;
    useEffect(() => {
        getStatus(props)
        //for scrolling the page on top
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 1000)
    })
    const getStatus = (props) => {
        if (!!getAuthToken() || isToken) {
            setHeaderType(false)
        }
    }
    return (
        <>
            {
                headerType ?
                    <ClaimProfileHeader />
                    :
                    <DashboardHeader />
            }
       <section class="scholarship-banner-lp">
        <div class="container">
            <div class="banner-content">
                <h1 class="title">College Men's <span>Baseball Scholarships</span></h1>
                <div class="short-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                </div>
            </div>
            <div class="form-box">
                <div class="form-title">Start your athlete profile for FREE right now!</div>
                <div class="flex-box">
                    <div class="item">
                        <select>
                            <option selected="" value="1">I'm an Athlete</option>
                            <option value="2">Athlete 2</option>
                            <option value="3">Athlete 3</option>
                            <option value="4">Athlete 4</option>
                            <option value="5">Athlete 5</option>
                        </select>
                    </div>
                    <div class="item">
                        <select>
                            <option selected="" value="1">Email here</option>
                            <option value="2">Email 2</option>
                            <option value="3">Email 3</option>
                            <option value="4">Email 4</option>
                            <option value="5">Email 5</option>
                        </select>
                    </div>
                    <div class="item">
                        <select>
                            <option selected="" value="1">Select Sport</option>
                            <option value="2">Sport 2</option>
                            <option value="3">Sport 3</option>
                            <option value="4">Sport 4</option>
                            <option value="5">Sport 5</option>
                        </select>
                    </div>
                    <div class="item">
                        <button class="btn">Join waiting list</button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="common-scholarship-sec">
        <div class="container">
            <h2 class="sec-title">How to Get Recruited for a Men's <br />Baseball Scholarship </h2>
            <div class="short-text">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. From its medieval origins to the digital era, learn everything there is to know about the ubiquitous lorem ipsum passage.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</p>
            </div>
        </div>
    </section>

    <section class="division-lp-sec bg-gray">
        <div class="container">
            <div class="division-number-list">
                <div class="item">
                    <div class="info-box">
                        <h4>334</h4>
                        <p>DIVISION I</p>
                    </div>
                </div>
                <div class="item">
                    <div class="info-box">
                        <h4>227</h4>
                        <p>DIVISION II</p>
                    </div>
                </div>
                <div class="item">
                    <div class="info-box">
                        <h4>404</h4>
                        <p>DIVISION III</p>
                    </div>
                </div>
                <div class="item">
                    <div class="info-box">
                        <h4>203</h4>
                        <p>NAIA</p>
                    </div>
                </div>
                <div class="item">
                    <div class="info-box">
                        <h4>439</h4>
                        <p>NJCAA</p>
                    </div>
                </div>
                <div class="item">
                    <div class="info-box">
                        <h4>77</h4>
                        <p>CCCAA</p>
                    </div>
                </div>
            </div>
            <div class="division-listing">
                <div class="item">
                    <h4 class="title second">Division I</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerci co laboris nisi ut aliquip ex ea commodo cons aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
                <div class="item">
                    <h4 class="title second">Division II</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerci co laboris nisi ut aliquip ex ea commodo cons aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
                <div class="item">
                    <h4 class="title second">Division III</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerci co laboris nisi ut aliquip ex ea commodo cons aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
                <div class="item">
                    <h4 class="title second">NAIA</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerci co laboris nisi ut aliquip ex ea commodo cons aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
                <div class="item">
                    <h4 class="title second">NJCAA</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerci co laboris nisi ut aliquip ex ea commodo cons aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
                <div class="item">
                    <h4 class="title second">CCCAA</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerci co laboris nisi ut aliquip ex ea commodo cons aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
            </div>
        </div>
    </section>
 
    <section class="athletic-dream-sec mb-1">
        <div class="container">
            <div class="flex-box">
                <div class="content-box">
                    <h3 class="title">Find Your <span>Dream School.</span></h3>
                    <div class="short-text">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                    </div>
                </div>
                <div class="form-box">
                    <div class="item">
                        <select>
                            <option selected="" value="1">I'm an Athlete</option>
                            <option value="2">Athlete 2</option>
                            <option value="3">Athlete 3</option>
                            <option value="4">Athlete 4</option>
                            <option value="5">Athlete 5</option>
                        </select>
                    </div>
                    <div class="item">
                        <select>
                            <option selected="" value="1">Email here</option>
                            <option value="2">Email 2</option>
                            <option value="3">Email 3</option>
                            <option value="4">Email 4</option>
                            <option value="5">Email 5</option>
                        </select>
                    </div>
                    <div class="item">
                        <select>
                            <option selected="" value="1">Select Sport</option>
                            <option value="2">Sport 2</option>
                            <option value="3">Sport 3</option>
                            <option value="4">Sport 4</option>
                            <option value="5">Sport 5</option>
                        </select>
                    </div>
                    <div class="item">
                        <button class="btn">Join waiting list</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    )
}
export default BaseballScholarship