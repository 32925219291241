import { React, useState, useEffect } from 'react';
import './assets/css/pricing-plan.scss';
import helpIMG from './assets/img/help_img.png';
import videoThumb from './assets/img/Family-video-thum.png';
import { VideoPop } from '../../../../landingPage/common/videoPopup';
import { fetchMethod } from '../../../../../../services/httpService';
import {
  getAuthToken,
  getUserInfo,
  setUserInfo,
} from '../../../../../../services/authService';
import { useHistory } from 'react-router-dom';
import toastr from 'toastr';
import BackdropLoader from '../../../../../common/Loader';
import AthleteTestimonials from '../../../../../common/AthleteTestimonials';

const InitialPricingPlan = [
  {
    OneMonth: {
      GamePlanPro: {
        price: '$1,495',
        totalPrice: '$2,495 Value',
      },
      Premier: {
        price: '$3,995',
        totalPrice: '$4,995 Value',
      },
      PremierPro: {
        price: '$5,995',
        totalPrice: '$7,995 Value',
      },
    },
    ThreeMonth: {
      GamePlanPro: {
        price: '$523.25',
        totalPrice: '$1,569.75',
        value: '$2,495 Value',
      },
      Premier: {
        price: '$1,398.25',
        totalPrice: '$4,194.75',
        value: '$4,995 Value',
      },
      PremierPro: {
        price: '$2,098.25',
        totalPrice: '$6,294.75',
        value: '$7,995 Value',
      },
    },
    SixMonth: {
      GamePlanPro: {
        price: '$274.08',
        totalPrice: '$1,644.50',
        value: '$2,495 Value',
      },
      Premier: {
        price: '$732.42',
        totalPrice: '$4,394.50',
        value: '$4,995 Value',
      },
      PremierPro: {
        price: '$1,099.08',
        totalPrice: '$6,594.50',
        value: '$7,995 Value',
      },
    },
    TwelveMonth: {
      GamePlanPro: {
        price: '$143.27',
        totalPrice: '$1,719.25',
        value: '$2,495 Value',
      },
      Premier: {
        price: '$382.85',
        totalPrice: '$4,594.25',
        value: '$4,995 Value',
      },
      PremierPro: {
        price: '$574.52',
        totalPrice: '$6,894.25',
        value: '$7,995 Value',
      },
    },
  },
];

const InitialUnlimitedPricingPlan = [
  {
    OneMonth: {
      GamePlanPro: {
        price: '$1,995',
        totalPrice: '$3,495 Value',
      },
      Premier: {
        price: '$4,495',
        totalPrice: '$5,995 Value',
      },
      PremierPro: {
        price: '$5,995',
        totalPrice: '$7,995 Value',
      },
    },
    ThreeMonth: {
      GamePlanPro: {
        price: '$698.25',
        totalPrice: '$2,094.75',
        value: '$3,495 Value',
      },
      Premier: {
        price: '$1,573.25',
        totalPrice: '$4,719.75',
        value: '$5,995 Value',
      },
      PremierPro: {
        price: '$2,098.25',
        totalPrice: '$6,294.75',
        value: '$7,995 Value',
      },
    },
    SixMonth: {
      GamePlanPro: {
        price: '$365.75',
        totalPrice: '$2,194.50',
        value: '$3,495 Value',
      },
      Premier: {
        price: '$824.08',
        totalPrice: '$4,944.50',
        value: '$5,995 Value',
      },
      PremierPro: {
        price: '$1,099.08',
        totalPrice: '$6,594.50',
        value: '$7,995 Value',
      },
    },
    TwelveMonth: {
      GamePlanPro: {
        price: '$191.19',
        totalPrice: '$2,294.25',
        value: '$3,495 Value',
      },
      Premier: {
        price: '$430.77',
        totalPrice: '$5,169.25',
        value: '$5,995 Value',
      },
      PremierPro: {
        price: '$574.52',
        totalPrice: '$6,894.25',
        value: '$7,995 Value',
      },
    },
  },
];

function PricingPlan3(props) {
  const history = useHistory();
  const [expandIndex, setExpandIndex] = useState(1);
  const [vidID, setVidID] = useState(null);
  const [isVideoPop, setIsVideoPop] = useState(false);
  const [paymentType, setPaymentType] = useState('OneMonth');
  const [UnlimitedPlan, setUnlimitedPlan] = useState(false);
  const [PricingPlan, setPricingPlan] = useState(InitialPricingPlan);
  const [showRefferal, setShowRefferalCode] = useState(false);
  const [checkRefferalClass, setCheckRefferalClass] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [isAthPurchase, setIsAthPurchase] = useState(false);
  const dollarUSLocale = Intl.NumberFormat('en-US');
  const dollarUSLocaleDec = Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const [promoCodeText, setPromoCodeText] = useState('');
  const [isPromoValid, setIsPromoValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isText, setIsText] = useState(false);
  //this is used for scroll on top
  useEffect(() => {
    getPlanData(false);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 800);
  }, []);
  const getPlanData = async (shouldRemovePromoCode) => {
    setIsLoading(true);
    const _res = await fetchMethod(
      '/api/Payments/getPlans',
      null,
      'GET',
      !!getAuthToken() ? true : false
    );
    if (_res?.status == 200) {
      setIsPromoValid(true);
      setIsCouponApplied(false);
      setPromoCodeText('');
      setErrorMsg('');
      localStorage.removeItem('promoCode');
      getAllPlanFormat(_res);
      setIsLoading(false);
      if (shouldRemovePromoCode) {
        setIsText(false);
        setCheckRefferalClass('');
        toastr.success('Promo code removed successfully');
      }
    } else {
      setPlanData([]);
      setIsLoading(false);
    }
  };
  const getAllPlanFormat = (data) => {
    const planPur = data?.data?.response?.data?.isMembershipPresent;
    setIsAthPurchase(planPur);
    const totalPlan = data?.data?.response?.data?.plans;
    const finalPlanData = [];
    for (let key in totalPlan) {
      console.log(key, totalPlan[key]);
      const obj = {
        planName: totalPlan[key]?.[0]?.planName,
        planFeatures: totalPlan[key]?.[0]?.features,
        planDescription: totalPlan[key]?.[0]?.description,
        planArr: totalPlan[key],
        additionalDes: totalPlan[key]?.[0]?.statement,
      };
      finalPlanData.push(obj);
    }
    setPlanData(finalPlanData?.reverse());
  };
  const openVideoModal = () => {
    setVidID('https://www.youtube.com/embed/pzXiI7Oyyp8');
    setIsVideoPop(true);
  };

  const setCollapseAndExpand = (index) => {
    if (expandIndex == index) {
      setExpandIndex(0);
    } else {
      setExpandIndex(index);
    }
  };

  const handlePaymentType = (paymentType) => {
    setPaymentType(paymentType);
  };

  const handleUnlimitedPlan = () => {
    // if (UnlimitedPlan) {
    //   setPricingPlan(InitialPricingPlan);
    // } else {
    //   setPricingPlan(InitialUnlimitedPricingPlan);
    // }
    setUnlimitedPlan(!UnlimitedPlan);
  };

  const checkReferral = async (value) => {
    if (
      value?.length > 2
      // !!e?.target?.value && e?.target?.value?.toString()?.length > 2
    ) {
      setIsLoading(true);
      setPromoCodeText(value);
      let _res = await fetchMethod(
        '/api/Payments/checkPromoCode',
        { promoCode: value },
        'POST',
        true
      );
      if (_res?.status == 200) {
        const isValidCode = _res?.data?.response?.status;
        if (isValidCode == 200) {
          setIsText(true);
          setIsPromoValid(false);
          setCheckRefferalClass(true);
          setIsLoading(false);
          setErrorMsg('');
          return true;
        } else if (isValidCode == 400) {
          setIsText(true);
          setIsLoading(false);
          setIsPromoValid(true);
          setCheckRefferalClass(false);
          setErrorMsg(
            !!_res?.data?.response?.message
              ? _res?.data?.response?.message
              : 'promo code error'
          );
          return false;
        } else {
          setIsText(true);
          setIsPromoValid(true);
          setCheckRefferalClass(false);
          setIsLoading(false);
          setErrorMsg('');
          return false;
        }
      } else {
        setIsText(false);
        // setCheckRefferalClass("")
        setIsLoading(false);
        setErrorMsg('');
        return false;
      }
    } else {
      setIsText(false);
      setIsPromoValid(true);
      setPromoCodeText(value);
      setCheckRefferalClass('');
      setIsLoading(false);
      setErrorMsg('');
    }
  };
  const redirectToCheckout = (pcID) => {
    if (!!getAuthToken()) {
      if (isAthPurchase) {
        window.open(
          'https://calendly.com/sportsforce/college-recruiting-assessment-call-upgrade',
          '_blank'
        );
      } else {
        if (!!pcID?.prices?.[0]?.id) {
          if (!isPromoValid) {
            if (pcID?.isDiscountApplied) {
              localStorage.setItem('promoCode', promoCodeText);
            }
          }
          setTimeout(() => {
            history?.push(
              `/programAndPricingCheckout/${pcID?.prices?.[0]?.id}`
            );
          }, 500);
        }
      }
    }
  };
  const getValueDec = (value) => {
    const valueSplit = value?.toString()?.includes('.');
    if (valueSplit) {
      return dollarUSLocaleDec.format(value);
    } else {
      return dollarUSLocale.format(value);
    }
  };
  const onSubmitPromo = async () => {
    const resCheckPromo = await checkReferral(promoCodeText);
    if (resCheckPromo) {
      setIsLoading(true);
      const finalObj = {
        promoCode: promoCodeText,
      };
      const _res = await fetchMethod(
        '/api/Payments/applyPromoCode',
        finalObj,
        'POST',
        true
      );
      if (_res?.status == 200) {
        getAllPlanFormat(_res);
        setIsLoading(false);
        setIsCouponApplied(true);
        toastr.success('Promo code applied successfully');
      } else {
        setIsLoading(false);
      }
    }
  };
  const onClickPromo = () => {
    if (isCouponApplied) {
      setShowRefferalCode(!showRefferal);
    } else {
      setShowRefferalCode(!showRefferal);
      setCheckRefferalClass('');
      setPromoCodeText('');
      setIsPromoValid(true);
      setErrorMsg('');
      setIsText(false);
    }
  };
  return (
    <>
      <BackdropLoader open={isLoading} />
      <section className="sf-plan-first-sec">
        <div className="container">
          <div className="top-block">
            <h1 className="sec-title">
              Your Next-Generation College Recruiting Platform
            </h1>
            <p>Give Your Child The College Recruiting Advantage</p>
            <ul className="pricing-unlock-feature">
              <li>
                <a href="javascript:void(0);" style={{ cursor: 'default' }}>
                  <span className="icon-box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="111.883"
                      height="110.344"
                      viewBox="0 0 111.883 110.344"
                    >
                      <g
                        id="Group_2331"
                        data-name="Group 2331"
                        transform="translate(-397.555 -411.656)"
                      >
                        <g
                          id="Group_2330"
                          data-name="Group 2330"
                          transform="translate(-80)"
                        >
                          <path
                            id="Path_1182"
                            data-name="Path 1182"
                            d="M107.159,32.98A2.819,2.819,0,1,1,104.34,35.8a2.819,2.819,0,0,1,2.819-2.819"
                            transform="translate(465.027 391.778)"
                            fill="none"
                            stroke="#00b9ff"
                            stroke-width="2.8"
                          />
                          <path
                            id="Path_1183"
                            data-name="Path 1183"
                            d="M108.874,42h-3.6a2.226,2.226,0,0,0-2.226,2.226V48.18h8.035V44.226A2.226,2.226,0,0,0,108.874,42"
                            transform="translate(465.359 389.451)"
                            fill="none"
                            stroke="#00b9ff"
                            stroke-width="2.8"
                          />
                          <path
                            id="Path_1184"
                            data-name="Path 1184"
                            d="M97.537,20.13a4.927,4.927,0,1,1-4.927,4.927,4.927,4.927,0,0,1,4.927-4.927"
                            transform="translate(465.945 395.094)"
                            fill="none"
                            stroke="#00b9ff"
                            stroke-width="2.8"
                          />
                          <path
                            id="Path_1185"
                            data-name="Path 1185"
                            d="M114.231,17.17a5.416,5.416,0,1,1-5.417,5.416,5.416,5.416,0,0,1,5.417-5.416"
                            transform="translate(466.191 395.886)"
                            fill="none"
                            stroke="#00b9ff"
                            stroke-width="2.8"
                          />
                          <path
                            id="Path_1186"
                            data-name="Path 1186"
                            d="M98.951,36.59a4.255,4.255,0,0,1,.164-1.19H93.653a3.873,3.873,0,0,0-3.872,3.872v7.249h8.035V42.367a3.923,3.923,0,0,1,1.892-3.324,4.442,4.442,0,0,1-.743-2.463Z"
                            transform="translate(466.675 391.154)"
                            fill="none"
                            stroke="#00b9ff"
                            stroke-width="2.8"
                          />
                          <path
                            id="Path_1187"
                            data-name="Path 1187"
                            d="M118.667,33.7h-5.572a4.535,4.535,0,0,1-.1,4.771,4.006,4.006,0,0,1,1.9,3.383v4.2h8.035v-8.1a4.259,4.259,0,0,0-4.259-4.251"
                            transform="translate(465.113 391.621)"
                            fill="none"
                            stroke="#00b9ff"
                            stroke-width="2.8"
                          />
                        </g>
                        <g
                          id="Group_2315"
                          data-name="Group 2315"
                          transform="translate(397.557 429.742)"
                        >
                          <g
                            id="Group_2314"
                            data-name="Group 2314"
                            transform="translate(0)"
                          >
                            <path
                              id="Path_2240"
                              data-name="Path 2240"
                              d="M89.721,158.065l-4.107-12.324a12.42,12.42,0,0,0-9.561-8.291L64.2,135.293v-.919a17.856,17.856,0,0,0,5.289-4.7h3.582a5.329,5.329,0,0,0,5.323-5.323v-21.29a23.065,23.065,0,0,0-46.129,0v12.419a7.108,7.108,0,0,0,5.658,6.95,17.775,17.775,0,0,0,8.536,11.94v.919L34.595,137.45a12.417,12.417,0,0,0-9.559,8.291l-.71,2.131-6.043-6.043a1.778,1.778,0,0,1,0-2.51l7.008-7.006L12.137,119.158l-7.92,7.92a12.419,12.419,0,0,0,0,17.563L28.2,168.619a12.413,12.413,0,0,0,17.508.053l8.642-7.918-2.695-2.69Zm-14.88-33.71a1.776,1.776,0,0,1-1.774,1.774H71.574a17.622,17.622,0,0,0,1.157-3.7,7.028,7.028,0,0,0,2.11-.839Zm-1.774-5.816v-6.11a3.518,3.518,0,0,1,0,6.11Zm-35.484,0a3.518,3.518,0,0,1,0-6.11Zm0-11.926v2.026a7.037,7.037,0,0,0-1.774.735v-6.309a19.516,19.516,0,1,1,39.032,0v6.309a6.973,6.973,0,0,0-1.774-.735v-2.026H71.292a19.52,19.52,0,0,1-13.89-5.754l-2.078-2.078-2.078,2.078a19.52,19.52,0,0,1-13.89,5.754Zm3.548,12.419v-8.938a23,23,0,0,0,14.194-6.305,22.993,22.993,0,0,0,14.194,6.305v8.938a14.087,14.087,0,0,1-1.92,7.1H55.325v3.548h9.357a14.171,14.171,0,0,1-23.551-10.645Zm19.516,16.924v.083l-5.323,5.323L50,136.04v-.083a17.728,17.728,0,0,0,10.645,0ZM28.4,146.862a8.874,8.874,0,0,1,6.829-5.924l12.4-2.253,7.695,7.695,7.693-7.693,12.4,2.253a8.871,8.871,0,0,1,6.829,5.924l2.553,7.652H48.1l-6.91-6.91-7.679,6.946a1.812,1.812,0,0,1-2.509,0l-3.875-3.875ZM12.135,124.176l8.136,8.136-1.927,1.927L10.209,126.1Zm31.11,41.938a8.873,8.873,0,0,1-12.545,0L6.722,142.134a8.873,8.873,0,0,1,0-12.545l.976-.976,8.136,8.136-.064.064a5.332,5.332,0,0,0,0,7.528l12.723,12.723a5.3,5.3,0,0,0,3.765,1.56,5.224,5.224,0,0,0,3.7-1.5l.133-.119,8.174,8.174Zm3.637-3.335-8.156-8.156,2.344-2.118,8.142,8.142Z"
                              transform="translate(-0.58 -80)"
                              fill="#00b9ff"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_2325"
                          data-name="Group 2325"
                          transform="translate(397.555 417.323)"
                        >
                          <g id="Group_2324" data-name="Group 2324">
                            <path
                              id="Path_2242"
                              data-name="Path 2242"
                              d="M55.318,24A49.646,49.646,0,0,0,6.977,62.4L3.615,56.8.572,58.623,6.808,69.015,17.2,62.779l-1.826-3.043-4.808,2.885A46.141,46.141,0,0,1,68.987,29.606l1.05-3.389A49.645,49.645,0,0,0,55.318,24Z"
                              transform="translate(-0.572 -24)"
                              fill="#00b9ff"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_2327"
                          data-name="Group 2327"
                          transform="translate(488.142 445.048)"
                        >
                          <g id="Group_2326" data-name="Group 2326">
                            <path
                              id="Path_2243"
                              data-name="Path 2243"
                              d="M427.333,155.252l-10.391-6.236-6.235,10.393,3.043,1.826,3.1-5.175a46.168,46.168,0,0,1-7.819,43.952l2.755,2.235a49.684,49.684,0,0,0,8.5-47.08l5.214,3.128Z"
                              transform="translate(-409.036 -149.016)"
                              fill="#00b9ff"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>
                  Personalized Recruiting Guidance
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" style={{ cursor: 'default' }}>
                  <span className="icon-box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="98.562"
                      height="114.446"
                      viewBox="0 0 98.562 114.446"
                    >
                      <g
                        id="Group_57835"
                        data-name="Group 57835"
                        transform="translate(-942.75 -353.711)"
                      >
                        <g
                          id="Group_2332"
                          data-name="Group 2332"
                          transform="translate(944 441.386)"
                        >
                          <path
                            id="Path_1169"
                            data-name="Path 1169"
                            d="M37.434,83.4h76.341a2.282,2.282,0,0,1,2.234,2.249v21.022a2.282,2.282,0,0,1-2.234,2.249H37.434a2.283,2.283,0,0,1-2.234-2.249V85.649A2.226,2.226,0,0,1,37.434,83.4"
                            transform="translate(-35.2 -83.4)"
                            fill="#fff"
                            stroke="#00b9ff"
                            stroke-width="2.5"
                          />
                          <path
                            id="Path_1171"
                            data-name="Path 1171"
                            d="M112.971,95.677H63.852a1.513,1.513,0,0,1,0-3.026h49.119a1.513,1.513,0,1,1,0,3.026"
                            transform="translate(-39.034 -84.648)"
                            fill="#00b9ff"
                          />
                          <path
                            id="Path_1172"
                            data-name="Path 1172"
                            d="M112.971,102.977H63.852a1.513,1.513,0,1,1,0-3.026h49.119a1.513,1.513,0,0,1,0,3.026"
                            transform="translate(-39.034 -85.632)"
                            fill="#00b9ff"
                          />
                          <path
                            id="Path_1173"
                            data-name="Path 1173"
                            d="M50.264,103.5l-.311,1.254h.622v2.192H47v-2.192h.777L50.264,93.8H52.44l2.487,10.956H55.7v2.192H52.13v-2.192h.622L52.44,103.5Zm1.71-2.19L51.352,98.5l-.622,2.817Z"
                            transform="translate(-38.814 -87.744)"
                            fill="#00b9ff"
                          />
                        </g>
                        <g id="e-mail" transform="translate(961.288 354)">
                          <g
                            id="Layer_2_00000147935678081845046720000006946314409726428092_"
                            transform="translate(0 0)"
                          >
                            <g
                              id="Layer_1-2_00000031195242420224951830000005702998532892433805_"
                              transform="translate(0)"
                            >
                              <path
                                id="e-mail_marketing"
                                d="M94.089,33.385H80.67c-.091-.485.2-8.209-.155-8.471a1.66,1.66,0,0,0-.341-.417l-10.238-7.94V9.975a1.289,1.289,0,0,0-1.289-1.289H59.781L48.925.264a1.308,1.308,0,0,0-1.577,0L36.489,8.685H27.627a1.289,1.289,0,0,0-1.285,1.289v6.582c-.023.03-10.26,7.928-10.264,7.97a1.17,1.17,0,0,0-.319.379,1.357,1.357,0,0,0-.159.61V57.985c0,1.043,1.138,1.289,1.285,1.289h13.43V74.627A1.289,1.289,0,0,0,31.6,75.913h9.453v7.735a1.292,1.292,0,0,0,1.983,1.1L56.8,75.928H94.089a1.285,1.285,0,0,0,1.285-1.285V34.67A1.281,1.281,0,0,0,94.1,33.385ZM60.539,41.753,78.084,28.141V55.358ZM77.284,25.517,69.932,31.2V19.81ZM48.137,2.915,55.58,8.686H40.69Zm19.216,8.342V33.214l-8.907,6.909-9.521-7.386a1.285,1.285,0,0,0-1.577,0l-9.525,7.386-8.907-6.909V11.26Zm-31.63,30.5-17.548,13.6V28.141ZM26.342,19.81V31.216l-7.356-5.7ZM48.137,35.383,75.627,56.7H68.642a1.289,1.289,0,0,0-1.289,1.289v6.666l-12.1-7.75a1.277,1.277,0,0,0-.69-.2H20.647ZM92.815,73.338h-36.4a1.294,1.294,0,0,0-.7.2l-12.1,7.746V74.627a1.285,1.285,0,0,0-1.282-1.289H32.894V59.275H54.188l13.76,8.812a1.331,1.331,0,0,0,1.376,0A1.282,1.282,0,0,0,69.932,67V59.275h9.453a1.357,1.357,0,0,0,1.285-1.289V35.956H92.8ZM35.981,24.364H60.3a1.289,1.289,0,0,1,0,2.575H35.981a1.289,1.289,0,0,1,0-2.575Zm0-7.553H52.539a1.285,1.285,0,1,1,0,2.571H35.981a1.285,1.285,0,1,1,0-2.571Z"
                                transform="translate(-15.6 0.001)"
                                fill="#00b9ff"
                                stroke="#00b9ff"
                                stroke-width="0.5"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>
                  Direct Outreach to Colleges
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" style={{ cursor: 'default' }}>
                  <span className="icon-box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="93.894"
                      height="110.908"
                      viewBox="0 0 93.894 110.908"
                    >
                      <g
                        id="Group_57836"
                        data-name="Group 57836"
                        transform="translate(-1218.75 -354.746)"
                      >
                        <g
                          id="Group_2335"
                          data-name="Group 2335"
                          transform="translate(1246.705 381.636)"
                        >
                          <path
                            id="Path_1198"
                            data-name="Path 1198"
                            d="M103.09,83.717,97.366,86.2a5.485,5.485,0,0,1-3.789,0l-5.724-2.48L83.5,81.832l-.113-.045a11.516,11.516,0,0,0-.22,2.364,11.359,11.359,0,0,0,3.585,8.6,11.729,11.729,0,0,0,8.6,3.584,11.357,11.357,0,0,0,8.6-3.585,11.73,11.73,0,0,0,3.585-8.6,11.313,11.313,0,0,0-.173-2.283Z"
                            transform="translate(-76.194 -61.516)"
                            fill="#fff"
                            stroke="#00b9ff"
                            stroke-width="3"
                          />
                          <path
                            id="Path_1199"
                            data-name="Path 1199"
                            d="M110.228,65.377c1.04-.453,1.04-1.19,0-1.641L94.846,57.08a5.478,5.478,0,0,0-3.788,0L75.677,63.736c-1.041.453-1.041,1.189,0,1.641l1.165.5-.1.782a.946.946,0,0,0-.131,1.833l-.707,9.911a4.54,4.54,0,0,0,.183,1.467l.576,1.722c.064.2.151.3.238.3s.171-.1.237-.3l.576-1.722a4.52,4.52,0,0,0,.184-1.467L77.192,68.5a.945.945,0,0,0-.134-1.833l-.09-.729L79.659,67.1l1.319.573v5.086l4.356,1.884,5.725,2.479a5.483,5.483,0,0,0,3.788,0l5.725-2.479,4.355-1.886V67.672l1.318-.573Z"
                            transform="translate(-73.914 -56.742)"
                            fill="#fff"
                            stroke="#00b9ff"
                            stroke-width="3"
                          />
                          <path
                            id="Path_1200"
                            data-name="Path 1200"
                            d="M73.54,114.822a26.033,26.033,0,0,0,8.9,6.123A28.393,28.393,0,0,0,92.7,123.081a23.852,23.852,0,0,0,10.255-2.136,26.055,26.055,0,0,0,9.614-6.9c-1.441-2.611-5.794-9.055-13.658-9.055H86.721a11.491,11.491,0,0,0-8.125,2.629,13.459,13.459,0,0,0-5.02,6.93Z"
                            transform="translate(-73.54 -65.774)"
                            fill="#fff"
                            stroke="#00b9ff"
                            stroke-width="3"
                          />
                        </g>
                        <g id="shield" transform="translate(1220 356)">
                          <g
                            id="Group_2334"
                            data-name="Group 2334"
                            transform="translate(0)"
                          >
                            <path
                              id="Path_2244"
                              data-name="Path 2244"
                              d="M129.3,12.89,86.778.134a3.174,3.174,0,0,0-1.825,0L42.431,12.89a3.176,3.176,0,0,0-2.263,3.042V62.706c0,6.084,2.463,12.311,7.32,18.51a75.675,75.675,0,0,0,15.254,14.13,136.848,136.848,0,0,0,21.825,12.78,3.174,3.174,0,0,0,2.6,0,136.851,136.851,0,0,0,21.825-12.78,75.68,75.68,0,0,0,15.254-14.13c4.857-6.2,7.32-12.426,7.32-18.51V15.932A3.175,3.175,0,0,0,129.3,12.89Zm-4.089,49.815c0,10.767-10.8,20.862-19.865,27.436a134.472,134.472,0,0,1-19.48,11.577,134.471,134.471,0,0,1-19.48-11.577C57.323,83.568,46.52,73.473,46.52,62.706V18.3l39.346-11.8,39.345,11.8Z"
                              transform="translate(-40.168 0)"
                              fill="#00b9ff"
                              stroke="#fff"
                              stroke-width="2.5"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>
                  Find the Right College Fit
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="sf-plans-list-sec">
        <div className="container">
          <div className="top-block">
            <h3 className="title">1-On-1 Recruiting Plans</h3>
            <div className="plan-top-infoBox">
              <div className="flexbox">
                {paymentType === 'OneMonth' ? (
                  <button className="offer-save-btn">Save up to 15%</button>
                ) : null}
                <ul className="number-payment-type">
                  <li
                    className={
                      paymentType === 'OneMonth' ? 'item active' : 'item'
                    }
                    onClick={(e) => {
                      handlePaymentType('OneMonth');
                    }}
                  >
                    1 Payment
                  </li>
                  <li
                    className={
                      paymentType === 'ThreeMonth' ? 'item active' : 'item'
                    }
                    onClick={(e) => {
                      handlePaymentType('ThreeMonth');
                    }}
                  >
                    3 Month
                  </li>
                  <li
                    className={
                      paymentType === 'SixMonth' ? 'item active' : 'item'
                    }
                    onClick={(e) => {
                      handlePaymentType('SixMonth');
                    }}
                  >
                    6 Month
                  </li>
                  <li
                    className={
                      paymentType === 'TwelveMonth' ? 'item active' : 'item'
                    }
                    onClick={(e) => {
                      handlePaymentType('TwelveMonth');
                    }}
                  >
                    12 Month
                  </li>
                </ul>
              </div>
              <div className="info-detailBox">
                <div className="checkbox-group">
                  <input
                    id="plancheck"
                    type="checkbox"
                    checked={UnlimitedPlan}
                    onClick={(e) => {
                      handleUnlimitedPlan();
                    }}
                  />
                  <label for="plancheck">Unlimited Video Editing ($500)</label>
                </div>
                <p>* Available for Game Plan Pro & Premier</p>
                <div
                  className={
                    showRefferal
                      ? 'plan-referral-box show'
                      : 'plan-referral-box'
                  }
                >
                  <button
                    disabled={!!getAuthToken() ? false : true}
                    className="btn-referral"
                    onClick={() => onClickPromo()}
                  >
                    Have a promo code?
                  </button>
                  <div className="plan-referral-flex show">
                    <div
                      className={
                        isCouponApplied
                          ? 'form-group coupon-applied'
                          : 'form-group'
                      }
                    >
                      <input
                        onKeyPress={(e) => {
                          if (
                            e?.key == 'Enter' &&
                            promoCodeText?.length > 2
                            // && !isPromoValid
                          )
                            onSubmitPromo();
                        }}
                        style={isCouponApplied ? { opacity: '0.7' } : {}}
                        // className={!!promoCodeText ? (checkRefferalClass ? "vaild-referral-code"
                        //     : "invalid-referral-code") : ""}
                        className={
                          isText
                            ? checkRefferalClass
                              ? 'vaild-referral-code'
                              : 'invalid-referral-code'
                            : ''
                        }
                        type="text"
                        // id="referralcode"
                        disabled={
                          isCouponApplied
                          //this is true to disable this functionality which is done
                          // isCouponApplied
                        }
                        value={promoCodeText}
                        // this is commented to disable this functionality which is done
                        placeholder="Enter referral code here"
                        onChange={(e) => {
                          setPromoCodeText(e?.target?.value);
                          setErrorMsg('');
                          setIsText(false);
                          // checkReferral(e)
                        }}
                        // this is commented to disable this functionality which is done
                      />
                      {!!errorMsg && (
                        <p
                          style={{
                            color: 'red',
                            textAlign: 'center',
                            marginTop: '5px',
                          }}
                        >
                          {errorMsg}
                        </p>
                      )}
                      <button
                        style={isPromoValid ? {} : {}}
                        type={'button'}
                        // disabled={isPromoValid}
                        className="apply-btn"
                        onClick={() =>
                          isCouponApplied ? getPlanData(true) : onSubmitPromo()
                        }
                        // this is commented to disable this functionality which is done
                      >
                        {isCouponApplied ? 'Remove' : 'Apply'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="plans-listing">
            {planData?.length > 0 &&
              planData?.map((pl, idx) => {
                return (
                  <div className="item">
                    <div className="plan-detail">
                      <div className="icon-box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="39.69"
                          height="42"
                          viewBox="0 0 39.69 42"
                        >
                          <path
                            id="users"
                            d="M22.845,31.5c-5.48,0-9.922-4.7-9.922-10.5s4.442-10.5,9.922-10.5,9.922,4.7,9.922,10.5S28.325,31.5,22.845,31.5Zm0,7A25.789,25.789,0,0,1,42.69,47.978V52.5H3V47.978A25.789,25.789,0,0,1,22.845,38.5Z"
                            transform="translate(-3 -10.5)"
                            fill="#3e3e3e"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div className="plan-title height-auto">
                        {pl?.planName}
                      </div>
                      <div className="plan-type height55">
                        {pl?.planDescription}
                        <span>{pl?.additionalDes ?? ''}</span>
                      </div>
                      <div className="short-text">
                        <ul className="check-type-list">
                          {pl?.planFeatures?.length > 0 &&
                            pl?.planFeatures?.map((ft) => <li>{ft}</li>)}
                        </ul>
                      </div>
                      <div className="plan-bottom-info">
                        <div className="plan-bottom-info-inner">
                          {pl?.planArr?.length > 0 &&
                            pl?.planArr?.map((plAr) => {
                              const planTypeNum =
                                paymentType == 'OneMonth'
                                  ? '0'
                                  : paymentType == 'ThreeMonth'
                                  ? '3'
                                  : paymentType == 'SixMonth'
                                  ? '6'
                                  : '12';
                              if (
                                planTypeNum == '0' &&
                                plAr?.months == '0' &&
                                plAr?.isUE == UnlimitedPlan &&
                                plAr?.planName != 'Premier Pro'
                              ) {
                                return (
                                  <>
                                    <div className="plan-price">
                                      ${getValueDec(plAr?.totalAmount ?? 0)}
                                    </div>
                                    <div className="plan-total-price">
                                      ${getValueDec(plAr?.actualAmount ?? 0)}{' '}
                                      Value
                                    </div>
                                    <div className="plan-button">
                                      <button
                                        className="btn"
                                        disabled={
                                          getUserInfo()?.isadmin == 0
                                            ? false
                                            : true
                                        }
                                        type={'button'}
                                        onClick={() => redirectToCheckout(plAr)}
                                      >
                                        {isAthPurchase ? 'Upgrade' : 'Select'}
                                      </button>
                                    </div>
                                  </>
                                );
                              } else if (
                                planTypeNum == '0' &&
                                plAr?.months == '0' &&
                                plAr?.planName == 'Premier Pro'
                              ) {
                                return (
                                  <>
                                    <div className="plan-price">
                                      ${getValueDec(plAr?.totalAmount ?? 0)}
                                    </div>
                                    <div className="plan-total-price">
                                      ${getValueDec(plAr?.actualAmount ?? 0)}{' '}
                                      Value
                                    </div>
                                    <div className="plan-button">
                                      <button
                                        className="btn"
                                        disabled={
                                          getUserInfo()?.isadmin == 0
                                            ? false
                                            : true
                                        }
                                        type={'button'}
                                        onClick={() => redirectToCheckout(plAr)}
                                      >
                                        {isAthPurchase ? 'Upgrade' : 'Select'}
                                      </button>
                                    </div>
                                  </>
                                );
                              } else if (
                                planTypeNum != '0' &&
                                plAr?.months != '0' &&
                                planTypeNum == plAr?.months &&
                                plAr?.isUE == UnlimitedPlan &&
                                plAr?.planName != 'Premier Pro'
                              ) {
                                return (
                                  <>
                                    <div className="plan-price">
                                      $
                                      {getValueDec(
                                        plAr?.prices?.[0]?.priceInDollars ?? 0
                                      )}{' '}
                                      <sub>/month</sub>
                                    </div>
                                    <div className="plan-price-seccond">
                                      ${getValueDec(plAr?.totalAmount ?? 0)}{' '}
                                      <sub>total</sub>
                                    </div>
                                    <div className="plan-total-price">
                                      ${getValueDec(plAr?.actualAmount ?? 0)}{' '}
                                      Value
                                    </div>
                                    <div className="plan-button">
                                      <button
                                        className="btn"
                                        disabled={
                                          getUserInfo()?.isadmin == 0
                                            ? false
                                            : true
                                        }
                                        type={'button'}
                                        onClick={() => redirectToCheckout(plAr)}
                                      >
                                        {isAthPurchase ? 'Upgrade' : 'Select'}
                                      </button>
                                    </div>
                                  </>
                                );
                              } else if (
                                planTypeNum != '0' &&
                                plAr?.months != '0' &&
                                planTypeNum == plAr?.months &&
                                plAr?.planName == 'Premier Pro'
                              ) {
                                return (
                                  <>
                                    <div className="plan-price">
                                      $
                                      {getValueDec(
                                        plAr?.prices?.[0]?.priceInDollars ?? 0
                                      )}{' '}
                                      <sub>/month</sub>
                                    </div>
                                    <div className="plan-price-seccond">
                                      ${getValueDec(plAr?.totalAmount ?? 0)}{' '}
                                      <sub>total</sub>
                                    </div>
                                    <div className="plan-total-price">
                                      ${getValueDec(plAr?.actualAmount ?? 0)}{' '}
                                      Value
                                    </div>
                                    <div className="plan-button">
                                      <button
                                        className="btn"
                                        disabled={
                                          getUserInfo()?.isadmin == 0
                                            ? false
                                            : true
                                        }
                                        type={'button'}
                                        onClick={() => redirectToCheckout(plAr)}
                                      >
                                        {isAthPurchase ? 'Upgrade' : 'Select'}
                                      </button>
                                    </div>
                                  </>
                                );
                              }
                            })}
                          {/*<div className="plan-price">*/}
                          {/*{PricingPlan[0][paymentType]["GamePlanPro"]["price"]}{" "}*/}
                          {/*{pl?.planArr?.length > 0 &&*/}
                          {/*pl?.planArr?.map(plAr =>{*/}
                          {/*  const planTypeNum = paymentType == "OneMonth" ? "0" :*/}
                          {/*      paymentType == "ThreeMonth" ? '3' : paymentType == "SixMonth" ? '6' : '12'*/}
                          {/*  if ((plAr?.isUE == UnlimitedPlan) && planTypeNum == plAr?.months){*/}
                          {/*    return <>{paymentType === "OneMonth" ? plAr?.totalAmount : plAr?.prices?.[0]?.priceInDollars}*/}
                          {/*      {paymentType === "OneMonth" ? "" : <sub>/month</sub>}*/}
                          {/*    </>*/}
                          {/*  }*/}
                          {/*})*/}
                          {/*}*/}
                          {/*</div>*/}
                          {/*{paymentType === "OneMonth" ? (*/}
                          {/*    <div className="plan-total-price">*/}
                          {/*      {*/}
                          {/*        PricingPlan[0][paymentType]["GamePlanPro"][*/}
                          {/*            "totalPrice"*/}
                          {/*            ]*/}
                          {/*      }{" "}*/}
                          {/*    </div>*/}
                          {/*) : (*/}
                          {/*    <>*/}
                          {/*      <div className="plan-price-seccond">*/}
                          {/*        {*/}
                          {/*          PricingPlan[0][paymentType]["GamePlanPro"][*/}
                          {/*              "totalPrice"*/}
                          {/*              ]*/}
                          {/*        }{" "}*/}
                          {/*        <sub>total</sub>*/}
                          {/*      </div>*/}
                          {/*      <div className="plan-total-price">*/}
                          {/*        {PricingPlan[0][paymentType]["GamePlanPro"]["value"]}*/}
                          {/*      </div>*/}
                          {/*    </>*/}
                          {/*)}*/}
                          {/*<div className="plan-button">*/}
                          {/*  <button className="btn"*/}
                          {/*          type={'button'}*/}
                          {/*          onClick={()=>redirectToCheckout()}*/}
                          {/*  >Select</button>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {/*<div className="item">*/}
            {/*  <div className="plan-detail">*/}
            {/*    <div className="icon-box">*/}
            {/*      <svg*/}
            {/*        xmlns="http://www.w3.org/2000/svg"*/}
            {/*        width="39.69"*/}
            {/*        height="42"*/}
            {/*        viewBox="0 0 39.69 42"*/}
            {/*      >*/}
            {/*        <path*/}
            {/*          id="users"*/}
            {/*          d="M22.845,31.5c-5.48,0-9.922-4.7-9.922-10.5s4.442-10.5,9.922-10.5,9.922,4.7,9.922,10.5S28.325,31.5,22.845,31.5Zm0,7A25.789,25.789,0,0,1,42.69,47.978V52.5H3V47.978A25.789,25.789,0,0,1,22.845,38.5Z"*/}
            {/*          transform="translate(-3 -10.5)"*/}
            {/*          fill="#3e3e3e"*/}
            {/*          fill-rule="evenodd"*/}
            {/*        />*/}
            {/*      </svg>*/}
            {/*    </div>*/}
            {/*    <div className="plan-title height-auto">Premier</div>*/}
            {/*    <div className="plan-type height55">*/}
            {/*      Tools, Guidance + Advocacy <span>Additional Benefits</span>*/}
            {/*    </div>*/}
            {/*    <div className="short-text">*/}
            {/*      <ul className="check-type-list">*/}
            {/*        <li>College Marketing Campaigns (3)</li>*/}
            {/*        <li>Feature Tweets (3)</li>*/}
            {/*        <li>Dedicated Advisor</li>*/}
            {/*        <li>College Coach Outreach</li>*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*    <div className="plan-bottom-info">*/}
            {/*      <div className="plan-bottom-info-inner">*/}
            {/*        <div className="plan-price">*/}
            {/*          {PricingPlan[0][paymentType]["Premier"]["price"]}{" "}*/}
            {/*          {paymentType === "OneMonth" ? "" : <sub>/month</sub>}*/}
            {/*        </div>*/}
            {/*        {paymentType === "OneMonth" ? (*/}
            {/*          <div className="plan-total-price">*/}
            {/*            {PricingPlan[0][paymentType]["Premier"]["totalPrice"]}*/}
            {/*          </div>*/}
            {/*        ) : (*/}
            {/*          <>*/}
            {/*            <div className="plan-price-seccond">*/}
            {/*              {PricingPlan[0][paymentType]["Premier"]["totalPrice"]}{" "}*/}
            {/*              <sub>total</sub>*/}
            {/*            </div>*/}
            {/*            <div className="plan-total-price">*/}
            {/*              {PricingPlan[0][paymentType]["Premier"]["value"]}*/}
            {/*            </div>*/}
            {/*          </>*/}
            {/*        )}*/}
            {/*        <div className="plan-button">*/}
            {/*          <button className="btn">Select</button>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*  <div className="plan-detail">*/}
            {/*    <div className="icon-box">*/}
            {/*      <svg*/}
            {/*        xmlns="http://www.w3.org/2000/svg"*/}
            {/*        width="39.69"*/}
            {/*        height="42"*/}
            {/*        viewBox="0 0 39.69 42"*/}
            {/*      >*/}
            {/*        <path*/}
            {/*          id="users"*/}
            {/*          d="M22.845,31.5c-5.48,0-9.922-4.7-9.922-10.5s4.442-10.5,9.922-10.5,9.922,4.7,9.922,10.5S28.325,31.5,22.845,31.5Zm0,7A25.789,25.789,0,0,1,42.69,47.978V52.5H3V47.978A25.789,25.789,0,0,1,22.845,38.5Z"*/}
            {/*          transform="translate(-3 -10.5)"*/}
            {/*          fill="#3e3e3e"*/}
            {/*          fill-rule="evenodd"*/}
            {/*        />*/}
            {/*      </svg>*/}
            {/*    </div>*/}
            {/*    <div className="plan-title height-auto">Premier Pro</div>*/}
            {/*    <div className="plan-type height55">*/}
            {/*      Tools, Guidance + Advocacy <span>Additional Benefits</span>*/}
            {/*    </div>*/}
            {/*    <div className="short-text">*/}
            {/*      <ul className="check-type-list">*/}
            {/*        <li>Unlimited Video Editing</li>*/}
            {/*        <li>College Marketing Campaigns (6)</li>*/}
            {/*        <li>Feature Tweets (6)</li>*/}
            {/*        <li>Unlimited Advising Support</li>*/}
            {/*        <li>College Coach Outreach</li>*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*    <div className="plan-bottom-info">*/}
            {/*      <div className="plan-bottom-info-inner">*/}
            {/*        <div className="plan-price">*/}
            {/*          {PricingPlan[0][paymentType]["PremierPro"]["price"]}{" "}*/}
            {/*          {paymentType === "OneMonth" ? "" : <sub>/month</sub>}*/}
            {/*        </div>*/}
            {/*        {paymentType === "OneMonth" ? (*/}
            {/*          <div className="plan-total-price">*/}
            {/*            {*/}
            {/*              PricingPlan[0][paymentType]["PremierPro"][*/}
            {/*                "totalPrice"*/}
            {/*              ]*/}
            {/*            }{" "}*/}
            {/*          </div>*/}
            {/*        ) : (*/}
            {/*          <>*/}
            {/*            <div className="plan-price-seccond">*/}
            {/*              {*/}
            {/*                PricingPlan[0][paymentType]["PremierPro"][*/}
            {/*                  "totalPrice"*/}
            {/*                ]*/}
            {/*              }{" "}*/}
            {/*              <sub>total</sub>*/}
            {/*            </div>*/}
            {/*            <div className="plan-total-price">*/}
            {/*              {PricingPlan[0][paymentType]["PremierPro"]["value"]}*/}
            {/*            </div>*/}
            {/*          </>*/}
            {/*        )}*/}
            {/*        <div className="plan-button">*/}
            {/*          <button className="btn">Select</button>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className="plan-bottom-box">
            <p>
              <strong>
                This is NOT an annual investment (You do not have to pay this
                every year)
              </strong>
            </p>
          </div>
        </div>
      </section>
      <section className="sf-plan-feature-sec">
        <div className="container">
          <h4 className="sec-title">Plan Features</h4>
          <div className="plan-feature-table">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th className="title">College Recruiting Tools</th>
                  <th>Game Plan Pro</th>
                  <th>Premier</th>
                  <th>Premier Pro</th>
                </tr>
                <tr>
                  <td>Personal Recruiting Profile</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Unlimited Video & Photos</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Advanced College Search System</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>College Coach Contact Database</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="title">Custom Video Editing</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Step-by-Step Instructions for Skills Video</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Recruiting Expert Video Review</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Professional Video Sequencing</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Custom Video Editing</td>
                  <td>2 Highlight Videos</td>
                  <td>2 Highlight Videos</td>
                  <td>Unlimited Videos</td>
                </tr>
                <tr>
                  <td className="title">Direct To College Coach Marketing</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Recruiting Email Letter Templates</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Email Assistance & Proofreading</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Direct to Coach Marketing Campaigns</td>
                  <td>&nbsp;</td>
                  <td>Up to 3 per year</td>
                  <td>Up to 6 per year</td>
                </tr>
                <tr>
                  <td>Coach Viewership Reports</td>
                  <td>&nbsp;</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="title">Social Media Exposure</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Feature Athlete Tweets</td>
                  <td>&nbsp;</td>
                  <td>Up to 3</td>
                  <td>Up to 6</td>
                </tr>
                <tr>
                  <td>Social Media Exposure Masterclass</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="title">College Recruiting Resources</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                {/* <tr>
                  <td>6 Week College Recruiting Masterclass</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr> */}
                <tr>
                  <td>Complete Resource Library & FAQ's</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Group ZOOM Coaching Sessions w/ Q&A</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Live Chat/Email Support (M-F 9-5pm PST)</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="title">College Academic Resources</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Academic & Scholarship Masterclasses</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="title">Personalized Guidance</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Custom Scouting Evaluation Session</td>
                  <td>1</td>
                  <td>Annual</td>
                  <td>Every 6 month</td>
                </tr>
                <tr>
                  <td>Individualized Strategy Sessions</td>
                  <td>2</td>
                  <td>90 Days</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>Camp/Showcase Selection Strategy</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Premium Client Support Access</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>College Target List Recommendations</td>
                  <td>&nbsp;</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Dedicated Personal College Advisor</td>
                  <td>&nbsp;</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="title">College Coach Advocacy & Outreach</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Personal College Coach Engagement</td>
                  <td>&nbsp;</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Recruiting Needs Timeline & Analysis</td>
                  <td>&nbsp;</td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                  <td>
                    <span className="check-icon">
                      <svg
                        id="Component_15_4"
                        data-name="Component 15 – 4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="Path_2234"
                          data-name="Path 2234"
                          d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM15.037,6.213a.938.938,0,0,0-1.35.027L9.346,11.771,6.73,9.154A.938.938,0,0,0,5.4,10.479l3.307,3.308a.938.938,0,0,0,1.349-.025l4.99-6.238a.938.938,0,0,0-.012-1.313Z"
                          fill="#1cb79e"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="title">Programs</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Program Investment</td>
                  {planData?.length > 0 &&
                    planData?.map((pl, idx) => {
                      {
                        return (
                          pl?.planArr?.length > 0 &&
                          pl?.planArr?.map((plAr) => {
                            const planTypeNum =
                              paymentType == 'OneMonth'
                                ? '0'
                                : paymentType == 'ThreeMonth'
                                ? '3'
                                : paymentType == 'SixMonth'
                                ? '6'
                                : '12';
                            if (
                              planTypeNum == '0' &&
                              plAr?.months == '0' &&
                              plAr?.isUE == UnlimitedPlan &&
                              plAr?.planName != 'Premier Pro'
                            ) {
                              return (
                                <>
                                  <th>
                                    ${getValueDec(plAr?.totalAmount ?? 0)}
                                    <span className="value-price">
                                      ${getValueDec(plAr?.actualAmount ?? 0)}{' '}
                                      Value
                                    </span>
                                  </th>
                                </>
                              );
                            } else if (
                              planTypeNum == '0' &&
                              plAr?.months == '0' &&
                              plAr?.planName == 'Premier Pro'
                            ) {
                              return (
                                <>
                                  <th>
                                    ${getValueDec(plAr?.totalAmount ?? 0)}
                                    <span className="value-price">
                                      ${getValueDec(plAr?.actualAmount ?? 0)}{' '}
                                      Value
                                    </span>
                                  </th>
                                </>
                              );
                            } else if (
                              planTypeNum != '0' &&
                              plAr?.months != '0' &&
                              planTypeNum == plAr?.months &&
                              plAr?.isUE == UnlimitedPlan &&
                              plAr?.planName != 'Premier Pro'
                            ) {
                              return (
                                <>
                                  <th>
                                    <div className="table-plan-price">
                                      $
                                      {getValueDec(
                                        plAr?.prices?.[0]?.priceInDollars ?? 0
                                      )}
                                      &nbsp;<sub>/month</sub>
                                    </div>
                                    <div className="table-plan-price-seccond">
                                      ${getValueDec(plAr?.totalAmount ?? 0)}
                                      &nbsp;<sub>total</sub>
                                    </div>
                                    <span className="value-price">
                                      ${getValueDec(plAr?.actualAmount ?? 0)}
                                    </span>
                                  </th>
                                </>
                              );
                            } else if (
                              planTypeNum != '0' &&
                              plAr?.months != '0' &&
                              planTypeNum == plAr?.months &&
                              plAr?.planName == 'Premier Pro'
                            ) {
                              return (
                                <>
                                  <th>
                                    <div className="table-plan-price">
                                      $
                                      {getValueDec(
                                        plAr?.prices?.[0]?.priceInDollars ?? 0
                                      )}
                                      &nbsp;<sub>/month</sub>
                                    </div>
                                    <div className="table-plan-price-seccond">
                                      ${getValueDec(plAr?.totalAmount ?? 0)}
                                      &nbsp;<sub>total</sub>
                                    </div>
                                    <span className="value-price">
                                      ${getValueDec(plAr?.actualAmount ?? 0)}
                                    </span>
                                  </th>
                                </>
                              );
                            }
                          })
                        );
                      }
                    })}
                  {/*<th>*/}
                  {/*  {paymentType === "OneMonth" ? (*/}
                  {/*    <>*/}
                  {/*      {PricingPlan[0][paymentType]["GamePlanPro"]["price"]}*/}
                  {/*      <span className="value-price">*/}
                  {/*        {*/}
                  {/*          PricingPlan[0][paymentType]["GamePlanPro"][*/}
                  {/*            "totalPrice"*/}
                  {/*          ]*/}
                  {/*        }*/}
                  {/*      </span>*/}
                  {/*    </>*/}
                  {/*  ) : (*/}
                  {/*    <>*/}
                  {/*      <div className="table-plan-price">*/}
                  {/*        {PricingPlan[0][paymentType]["GamePlanPro"]["price"]}{" "}*/}
                  {/*        <sub>/month</sub>*/}
                  {/*      </div>*/}
                  {/*      <div className="table-plan-price-seccond">*/}
                  {/*        {*/}
                  {/*          PricingPlan[0][paymentType]["GamePlanPro"][*/}
                  {/*            "totalPrice"*/}
                  {/*          ]*/}
                  {/*        }{" "}*/}
                  {/*        <sub>total</sub>*/}
                  {/*      </div>*/}
                  {/*      <span className="value-price">*/}
                  {/*        {PricingPlan[0][paymentType]["GamePlanPro"]["value"]}*/}
                  {/*      </span>*/}
                  {/*    </>*/}
                  {/*  )}*/}
                  {/*</th>*/}
                  {/*<th>*/}
                  {/*  {paymentType === "OneMonth" ? (*/}
                  {/*    <>*/}
                  {/*      {PricingPlan[0][paymentType]["Premier"]["price"]}*/}
                  {/*      <span className="value-price">*/}
                  {/*        {PricingPlan[0][paymentType]["Premier"]["totalPrice"]}*/}
                  {/*      </span>*/}
                  {/*    </>*/}
                  {/*  ) : (*/}
                  {/*    <>*/}
                  {/*      <div className="table-plan-price">*/}
                  {/*        {PricingPlan[0][paymentType]["Premier"]["price"]}{" "}*/}
                  {/*        <sub>/month</sub>*/}
                  {/*      </div>*/}
                  {/*      <div className="table-plan-price-seccond">*/}
                  {/*        {PricingPlan[0][paymentType]["Premier"]["totalPrice"]}{" "}*/}
                  {/*        <sub>total</sub>*/}
                  {/*      </div>*/}
                  {/*      <span className="value-price">*/}
                  {/*        {PricingPlan[0][paymentType]["Premier"]["value"]}*/}
                  {/*      </span>*/}
                  {/*    </>*/}
                  {/*  )}*/}
                  {/*</th>*/}
                  {/*<th>*/}
                  {/*  {paymentType === "OneMonth" ? (*/}
                  {/*    <>*/}
                  {/*      {PricingPlan[0][paymentType]["PremierPro"]["price"]}*/}
                  {/*      <span className="value-price">*/}
                  {/*        {*/}
                  {/*          PricingPlan[0][paymentType]["PremierPro"][*/}
                  {/*            "totalPrice"*/}
                  {/*          ]*/}
                  {/*        }*/}
                  {/*      </span>*/}
                  {/*    </>*/}
                  {/*  ) : (*/}
                  {/*    <>*/}
                  {/*      <div className="table-plan-price">*/}
                  {/*        {PricingPlan[0][paymentType]["PremierPro"]["price"]}{" "}*/}
                  {/*        <sub>/month</sub>*/}
                  {/*      </div>*/}
                  {/*      <div className="table-plan-price-seccond">*/}
                  {/*        {*/}
                  {/*          PricingPlan[0][paymentType]["PremierPro"][*/}
                  {/*            "totalPrice"*/}
                  {/*          ]*/}
                  {/*        }{" "}*/}
                  {/*        <sub>total</sub>*/}
                  {/*      </div>*/}
                  {/*      <span className="value-price">*/}
                  {/*        {PricingPlan[0][paymentType]["PremierPro"]["value"]}*/}
                  {/*      </span>*/}
                  {/*    </>*/}
                  {/*  )}*/}
                  {/*</th>*/}
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  {planData?.length > 0 &&
                    planData?.map((pl, idx) => {
                      {
                        return (
                          pl?.planArr?.length > 0 &&
                          pl?.planArr?.map((plAr) => {
                            const planTypeNum =
                              paymentType == 'OneMonth'
                                ? '0'
                                : paymentType == 'ThreeMonth'
                                ? '3'
                                : paymentType == 'SixMonth'
                                ? '6'
                                : '12';
                            if (
                              planTypeNum == '0' &&
                              plAr?.months == '0' &&
                              plAr?.isUE == UnlimitedPlan &&
                              plAr?.planName != 'Premier Pro'
                            ) {
                              return (
                                <>
                                  <td>
                                    <button
                                      type={'button'}
                                      disabled={
                                        getUserInfo()?.isadmin == 0
                                          ? false
                                          : true
                                      }
                                      onClick={() => redirectToCheckout(plAr)}
                                      className="btn"
                                    >
                                      {isAthPurchase ? 'Upgrade' : 'Select'}
                                    </button>
                                  </td>
                                </>
                              );
                            } else if (
                              planTypeNum == '0' &&
                              plAr?.months == '0' &&
                              plAr?.planName == 'Premier Pro'
                            ) {
                              return (
                                <>
                                  <td>
                                    <button
                                      type={'button'}
                                      disabled={
                                        getUserInfo()?.isadmin == 0
                                          ? false
                                          : true
                                      }
                                      onClick={() => redirectToCheckout(plAr)}
                                      className="btn"
                                    >
                                      {isAthPurchase ? 'Upgrade' : 'Select'}
                                    </button>
                                  </td>
                                </>
                              );
                            } else if (
                              planTypeNum != '0' &&
                              plAr?.months != '0' &&
                              planTypeNum == plAr?.months &&
                              plAr?.isUE == UnlimitedPlan &&
                              plAr?.planName != 'Premier Pro'
                            ) {
                              return (
                                <>
                                  <td>
                                    <button
                                      type={'button'}
                                      disabled={
                                        getUserInfo()?.isadmin == 0
                                          ? false
                                          : true
                                      }
                                      onClick={() => redirectToCheckout(plAr)}
                                      className="btn"
                                    >
                                      {isAthPurchase ? 'Upgrade' : 'Select'}
                                    </button>
                                  </td>
                                </>
                              );
                            } else if (
                              planTypeNum != '0' &&
                              plAr?.months != '0' &&
                              planTypeNum == plAr?.months &&
                              plAr?.planName == 'Premier Pro'
                            ) {
                              return (
                                <>
                                  <td>
                                    <button
                                      type={'button'}
                                      disabled={
                                        getUserInfo()?.isadmin == 0
                                          ? false
                                          : true
                                      }
                                      onClick={() => redirectToCheckout(plAr)}
                                      className="btn"
                                    >
                                      {isAthPurchase ? 'Upgrade' : 'Select'}
                                    </button>
                                  </td>
                                </>
                              );
                            }
                          })
                        );
                      }
                    })}
                </tr>
                {/*<tr>*/}
                {/*  <td>&nbsp;</td>*/}
                {/*  <td>*/}
                {/*    <button className="btn">Select</button>*/}
                {/*  </td>*/}
                {/*  <td>*/}
                {/*    <button className="btn">Select</button>*/}
                {/*  </td>*/}
                {/*  <td>*/}
                {/*    <button className="btn">Select</button>*/}
                {/*  </td>*/}
                {/*</tr>*/}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <AthleteTestimonials />
      <section className="sf-plan-recruiting-sec">
        <div className="container">
          <div className="content-with-video-sec">
            <div className="help-box">
              <div className="item">
                <div className="help-img">
                  <img src={helpIMG} alt="" />
                </div>
                <h3>We're here to help.</h3>
                <p>
                  Schedule a call with one of our expert coaches to give your
                  family honest recruiting advice.
                </p>
                <div className="button-box">
                  <a
                    className="btn green"
                    href="https://calendly.com/sportsforce/recruiting-assessment"
                    target={'_blank'}
                  >
                    Schedule Call
                  </a>
                </div>
              </div>
            </div>
            <div className="video-box">
              <h3>Hear Why Families Trust Our Program</h3>
              <div className="img-box">
                <img
                  src={videoThumb}
                  alt="Hear Why Families Trust Our Program"
                />
                <div
                  data-toggle="modal"
                  onClick={() => openVideoModal()}
                  data-target="#VideoModal"
                  className="video-play-button"
                >
                  <div className="video-play-inner">
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.802"
                        height="26.203"
                        viewBox="0 0 10.802 16.203"
                      >
                        <defs>
                          <style>{/* .a {fill: #fff;} */}</style>
                        </defs>
                        <path
                          className="a"
                          d="M8.1,0l8.1,10.8H0Z"
                          transform="translate(10.802) rotate(90)"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sf-plan-faq-sec">
        <div className="container">
          <div className="faq-box-wrapper">
            <h1 className="sec-title">Frequently Asked Questions</h1>
            <ul className="faq-listing">
              <li className={expandIndex === 1 ? 'active' : ''}>
                <div
                  className={
                    expandIndex === 1 ? 'faq-title active' : 'faq-title'
                  }
                  onClick={(e) => {
                    setCollapseAndExpand(1);
                  }}
                >
                  How do I know which program is the right fit?
                </div>
                <div
                  className="faq-data"
                  style={expandIndex === 1 ? { display: 'block' } : {}}
                >
                  <p>
                    Every student-athlete and family’s college goals are unique.
                    Before we consider offering one of our premium programs, we
                    conduct a complete college recruiting evaluation and
                    consultation with a family before a hands-on premium program
                    is offered. Depending on how the consultation goes, our
                    expert recruiting advisor has an option to offer a program
                    that fits your needs and the results you want. Schedule your
                    consultation with one of our recruiting experts to get an
                    honest and complete data-driven college recruiting
                    consultation.
                  </p>
                </div>
              </li>
              <li className={expandIndex === 2 ? 'active' : ''}>
                <div
                  className={
                    expandIndex === 2 ? 'faq-title active' : 'faq-title'
                  }
                  onClick={(e) => {
                    setCollapseAndExpand(2);
                  }}
                >
                  How is SportsForce different than other recruiting groups or
                  services?
                </div>
                <div
                  className="faq-data"
                  style={expandIndex === 2 ? { display: 'block' } : {}}
                >
                  <p>
                    For the last 14 years, we have been in the recruiting
                    trenches every day. As a nationwide group of former college
                    coaches, athletes, and professional scouts, we know what
                    college coaches are looking for. We combine the right
                    technology, the right recruiting game plan, and the right
                    relationships to maximize our families’ college recruiting
                    opportunities. Finally for our Premium Programs we limit the
                    number of student-athletes and families that we are willing
                    to advise to maintain the proper level of personalization
                    and support.
                  </p>
                </div>
              </li>
              <li className={expandIndex === 3 ? 'active' : ''}>
                <div
                  className={
                    expandIndex === 3 ? 'faq-title active' : 'faq-title'
                  }
                  onClick={(e) => {
                    setCollapseAndExpand(3);
                  }}
                >
                  What are the qualifications for the 1-on-1 Premium Program
                  that include college coach outreach and utilizing our network
                  of trusted college coach relationships?
                </div>
                <div
                  className="faq-data"
                  style={expandIndex === 3 ? { display: 'block' } : {}}
                >
                  <p>
                    In addition to an evaluation of talent, our Advisors also
                    evaluate prospective clients on a number of other factors
                    including (but not limited to):
                  </p>
                  <ol>
                    <li>1. Quality Academic Standing</li>
                    <li>2. Qualified College Baseball Prospect</li>
                    <li>3. Open & Coachable Student-Athlete</li>
                    <li>4. Open & Coachable Parents</li>
                    <li>5. Character Commitment On & Off the Field</li>
                  </ol>
                </div>
              </li>
              <li className={expandIndex === 4 ? 'active' : ''}>
                <div
                  className={
                    expandIndex === 4 ? 'faq-title active' : 'faq-title'
                  }
                  onClick={(e) => {
                    setCollapseAndExpand(4);
                  }}
                >
                  Do all of the programs include personal college coach outreach
                  and advocacy on behalf of the player?
                </div>
                <div
                  className="faq-data"
                  style={expandIndex === 4 ? { display: 'block' } : {}}
                >
                  <p>
                    No, you have to qualify for our hands-on premium programs
                    that include college coach outreach and advocacy from our
                    recruiting advisory staff. We do offer a do-it-yourself
                    program that gives outstanding tools and resources, but it
                    does not include personal college coach outreach and
                    advocacy on our behalf.
                  </p>
                </div>
              </li>
              <li className={expandIndex === 5 ? 'active' : ''}>
                <div
                  className={
                    expandIndex === 5 ? 'faq-title active' : 'faq-title'
                  }
                  onClick={(e) => {
                    setCollapseAndExpand(5);
                  }}
                >
                  Is the program worth the money?
                </div>
                <div
                  className="faq-data"
                  style={expandIndex === 5 ? { display: 'block' } : {}}
                >
                  <p>
                    To put it simply, SportsForce is an investment in your
                    child's future. College is arguably the largest decision and
                    biggest investment in your child's life. It is important to
                    play the field strategically to generate the most
                    opportunities possible. We enable you to make sure that you
                    are doing everything you can to help your child reach their
                    full potential and sign with the best possible college. We
                    also save you thousands of dollars by helping you choose
                    where to spend your money during the process. With us, you
                    will not end up paying for showcases and events that do not
                    move your son toward his college goals.
                  </p>
                </div>
              </li>
              <li className={expandIndex === 6 ? 'active' : ''}>
                <div
                  className={
                    expandIndex === 6 ? 'faq-title active' : 'faq-title'
                  }
                  onClick={(e) => {
                    setCollapseAndExpand(6);
                  }}
                >
                  What is the difference between my club team coach and
                  SportsForce?
                </div>
                <div
                  className="faq-data"
                  style={expandIndex === 6 ? { display: 'block' } : {}}
                >
                  <p>
                    Firstly, SportsForce is not a club team. We partner with and
                    work with many different clubs and club coaches to empower
                    and ensure student-athletes and families are supported to
                    maximize their recruiting offers. Most club coaches do a
                    great job coaching players and getting them prepared for the
                    future. However, most club coaches have a number of
                    responsibilities and do not have the time to guide each
                    family through a personalized recruiting game plan. Usually
                    club coaches have some strong college coach connections, but
                    are often limited to certain area of schools or type of
                    colleges. In our experience, the majority of
                    student-athletes need more ongoing and personalized
                    guidance, resources, and college coach outreach to help
                    maximize their recruiting efforts.
                  </p>
                </div>
              </li>
              <li className={expandIndex === 7 ? 'active' : ''}>
                <div
                  className={
                    expandIndex === 7 ? 'faq-title active' : 'faq-title'
                  }
                  onClick={(e) => {
                    setCollapseAndExpand(7);
                  }}
                >
                  Is it legal for prospective college athletes to use a college
                  recruiting advisor or college athletic recruiting company?
                </div>
                <div
                  className="faq-data"
                  style={expandIndex === 7 ? { display: 'block' } : {}}
                >
                  <p>
                    It is completely legal to use a college athletic advisory
                    service or college athletic recruiting company to help
                    market and promote your son to college coaches. The NCAA
                    governs these services according to strict ethical standards
                    to ensure that no one gains an unfair advantage. Our company
                    is approved and fully compliant and in good standing with
                    the NCAA, NAIA, and NJCAA.
                  </p>
                </div>
              </li>
              <li className={expandIndex === 8 ? 'active' : ''}>
                <div
                  className={
                    expandIndex === 8 ? 'faq-title active' : 'faq-title'
                  }
                  onClick={(e) => {
                    setCollapseAndExpand(8);
                  }}
                >
                  Have any other questions?
                </div>
                <div
                  className="faq-data"
                  style={expandIndex === 8 ? { display: 'block' } : {}}
                >
                  <p>Contact us and we will get in touch shortly.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <VideoPop
        isTestSingle={isVideoPop}
        setIsTestSingle={() => setIsVideoPop(false)}
        vidID={vidID}
      />
    </>
  );
}

export default PricingPlan3;
