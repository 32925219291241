import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { ScrollTo } from "react-scroll-to/dist";
import image from "../../assets/img/video-bg-img.png";
import CardDetailsBox from "./CardDetailsBox";
import VideoPopup from "./VideoPopup";
import { useDispatch, useSelector } from "react-redux";
import { getHowToVideoListQuery, saveViewVideo } from "../HowToVideo/HowtoVideoQuery";
import swal from "sweetalert";
import { getUserInfo } from "../../../../../../../services/authService";
import GoTopButton from "../../../editUserProfile/GoTopButton";

const ResourceDetails = (props) => {
  const id = +props?.match?.params?.id
  const fakeInput = useRef(null);
  const dispatch = useDispatch()
  const msClass = useSelector(state => state),
  [isVideo, setIsVideo] = useState(false),
  [vidData, setVidData] = useState([]),
  userLS = JSON.parse(localStorage.getItem('user'));
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [showPaymentBox, setShowPaymentBox] = useState(false);

  useEffect(() => {
    dispatch({
      type: "GET_HOW_TO_VIDEO_START", payload: {
        query: getHowToVideoListQuery,
        variables: {
          where: {
            isuploadedbyadmin: 1,
            id: id
          },
        }
      }
    })
    fakeInput.current.focus();
    fakeInput.current.select();
  }, [])
  const handleClose = () => {
    setShowVideoPopup(false);
  };
  const handleOpen = () => {
    setShowVideoPopup(true);
  };
  const srcollToPrice = value => {
    const elm = document.getElementById(value)
    elm.scrollIntoView()
  }
  const goTop = () => {
    fakeInput.current.focus();
    fakeInput.current.select();
  };
  const { goBack } = useHistory();
  const checkMembership = (value) =>{
    let isMember = []
    const checkValue = value,
          cloneState = [...msClass?.athlete?.dashboard?.resources.howToVideoList?.data?.dataSection]
          if(cloneState.length > 0){
            cloneState.map((otr, idxOt)=>{
              otr?.data.map((inr, idxIn)=>{
                    inr?.membershipvideoandphotoplanidMap?.Memberships.map((inMem, indxMem)=>{
                      if(inMem?.videoandphotoplanid === checkValue?.id && inMem?.userid == +getUserInfo()?.id){
                        isMember.push(inMem)
                      }
                    })
              })
            })
          }else{
            return false
          }
          if(isMember?.length > 0) return true
    return false
  },
  isVideoPop = (vid) => {
        setVidData(vid)
        setIsVideo(true)
      }
  return (
    <div className="resources-main-sec">
      <input className="fakeInput" type="text" ref={fakeInput} />
      {/* <VideoPopup show={showVideoPopup} close={handleClose} videoId="" /> */}
      <div className="container">
        <div className="back-link-box">
          <a style={{ cursor: "pointer" }} onClick={() => goBack()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.39"
              height="15"
              viewBox="0 0 17.39 15"
            >
              <path
                id="Icon_awesome-arrow-left"
                data-name="Icon awesome-arrow-left"
                d="M8.843,16.643l-.763.763a.821.821,0,0,1-1.164,0L.239,10.731a.821.821,0,0,1,0-1.164L6.916,2.889a.821.821,0,0,1,1.164,0l.763.763A.825.825,0,0,1,8.829,4.83L4.69,8.773H16.562a.822.822,0,0,1,.824.824v1.1a.822.822,0,0,1-.824.824H4.69l4.139,3.943A.819.819,0,0,1,8.843,16.643Z"
                transform="translate(0.004 -2.647)"
                fill="#3e3e3e"
              />
            </svg>
            Back to Homepage
          </a>
        </div>
        <div className="resources-overview-detail">
          <div className="video-overview-box">
            <h1 className="sec-title">{msClass?.athlete?.dashboard?.resources?.howToVideoList?.data?.dataSection[0]?.data[0]?.videoname || ""}</h1>
            <div className="video-box">
              <div
                className="img-box"
                // onClick="myvideopopup($(this).attr('data-id'))"
                data-id="aqz-KE-bpKQ"
                href="#aqz-KE-bpKQ"
              >
                <video poster={msClass?.athlete?.dashboard?.resources?.howToVideoList?.data?.dataSection[0]?.data[0]?.thumbnail ?? image}>
                  <source
                    src="https://www.youtube.com/embed/aqz-KE-bpKQ"
                    type="video/mp4"
                  />
                  <source
                    src="https://www.youtube.com/embed/aqz-KE-bpKQ"
                    type="video/ogg"
                  />
                </video>
                <div
                  className="video-play-button"
                  data-toggle="modal"
                  data-target="#ResourceVideoModal"
                >
                  <div className="video-play-inner">
                    <button onClick={() =>
                    //  checkMembership(vid) ?
                    //                                   (isVideoPop(vid) || dispatch({type:"SAVE_VIEW_VIDEO_START",payload:{
                    //                                   query: saveViewVideo,
                    //                                   variables: {
                    //                                     obj:{
                    //                                       // id:1,
                    //                                       userid: userLS?.id || 0,
                    //                                       viewstatus: 1,
                    //                                       videoid : vid?.id
                    //                                     }
                    //                                   }
                    //                                 }})) 
                    //                                 : 
                                                    swal("", "Video is not purchased",'')}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10.802"
                        height="16.203"
                        viewBox="0 0 10.802 16.203"
                      >
                        <path
                          className="a"
                          d="M8.1,0l8.1,10.8H0Z"
                          transform="translate(10.802) rotate(90)"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="overview-flex-box">
              <div className="overview-content">
                <h3>Overview</h3>
                <p>
                  {msClass?.athlete?.dashboard?.resources?.howToVideoList?.data?.dataSection[0].data[0].videodescription || ""}
                </p>
                {/*<p></p>*/}
              </div>
              <div className="overview-price">
                <h4 className="price" >
                  <span>$</span>{msClass?.athlete?.dashboard?.resources?.howToVideoList?.data?.dataSection[0].data[0].videoprice || 0}
                </h4>
                {/*<ScrollTo>*/}
                {/*{({ scroll }) => (*/}
                <button
                  type={'button'}
                  onClick={() => srcollToPrice('purchase-button123')}
                  className="btn green"
                // onClick={() => scroll({ x: 20, y: 1000 })}
                >
                  Purchase
                </button>
                {/*//   )}*/}
                {/*// </ScrollTo>*/}
              </div>
            </div>
          </div>
          <div className="overview-quote-box">
            <div className="quote-text">
              <p>
                "I don't know how many clients my Insights Analyst has, but I
                always feel that my son is their
                <br /> only client because of the personal attention he
                receives."
              </p>
            </div>
            <div className="quote-name" id="purchase-button123">
              MIKE ARENS, FATHER OF 2019 MEN'S LACROSSE STUDENT-ATHLETE
            </div>
          </div>
          <div className="overview-bottom-box">
            <p>
              Get started with this course today and become an expert on the
              recruiting process
            </p>
            <button
              className="btn green"
              // id=""
              type={'button'}
              onClick={() => {
                // setShowPaymentBox(true)
                props.history.push({
                  pathname: `/payment/MasterClasses`,
                  state: {
                    selectedPlan: {
                      id: id,
                      monthlyprice: msClass?.athlete?.dashboard?.resources?.howToVideoList?.data?.dataSection[0].data[0].videoprice || 12
                    },
                    toggle: { isChecked: true },
                    previousPage: "video-and-photo",
                  }
                })
              }}
              style={{ display: `${!showPaymentBox ? "" : "none"} ` }}
            >
              Purchase Now For ${msClass?.athlete?.dashboard?.resources?.howToVideoList?.data?.dataSection[0].data[0].videoprice || 0}
            </button>
            <CardDetailsBox
              showPaymentBox={showPaymentBox}
              onCancel={() => setShowPaymentBox(false)}
            />
          </div>
        </div>
      </div>
      <GoTopButton goTop={goTop} />
    </div>
  );
};

export default ResourceDetails
