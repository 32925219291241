export const getMyCommitmentQuery = `
query allCollegecommitments($obj: JSON, $first : Int, $last : Int){
    allCollegecommitments(where: $obj, first : $first, last : $last){
      totalCount
       Collegecommitments{
        id
        active
        collegeid
        commitment
        collegename
        isvisible
        commitmentdate
        collegecommitmentuseridmaprel{
          Athletes{
            id
            athletefirstname
            athletelastname
            athleteSports
            verifieduser
            athleteathletesportsmaprel{
              Athletesports{
                sportname
              }
            }
            athletegraduationyear
            athleteGraduationYear
            athleteathletegraduationyearmaprel{
              Graduationyears{
                yearvalue
              }
            }
            onBoardingUserIdMap{
              Onboardings{
                profileimages
              }
            }
          }
        }
        collegecommitmentcommitmentmaprel{
        Commitmentstatuses{
          commitmentstatusvalue
        }
      }
      collegecommitmentcollegeidmaprel{
        Colleges{
        collegename
          city
          state
        }
      }
      }
    }
  } 
  `;

export const getMyCommitmentForDashboardQuery = (userId) => `
query allCollegecommitments($obj: JSON, $first : Int, $last : Int){
    allCollegecommitments(where: $obj, first : $first, last : $last){
      totalCount
       Collegecommitments{
        id
        active
        collegeid
        commitment
        collegename
        isvisible
        commitmentdate
        collegecommitmentcollegeidmaprel{
          Colleges{
          collegelogo
            userCollegeCollegeIdMap(where:{active:1,userid:${userId}}){
              Usercolleges{
                isfav
              }
            }
          }
        }
        collegecommitmentuseridmaprel{
          Athletes{
            id
            athletefirstname
            athletelastname
            athleteSports
            verifieduser
            athleteathletesportsmaprel{
              Athletesports{
                sportname
              }
            }
            athletegraduationyear
            athleteGraduationYear
            athleteathletegraduationyearmaprel{
              Graduationyears{
                yearvalue
              }
            }
            onBoardingUserIdMap{
              Onboardings{
                profileimages
              }
            }
          }
        }
        collegecommitmentcommitmentmaprel{
        Commitmentstatuses{
          commitmentstatusvalue
        }
      }
      }
    }
  } 
  `;

export const getAllCollegeOfferQuery = `
query allOffers($obj: JSON) {
    allOffers(where: $obj,first:1,last:1){
      totalCount
      Offers{
        id
        offerdate
        offercollegeidmaprel{
          Colleges{
            id
            collegename
            city 
            state
          }
        }
      }
    }
  } 
`;

export const getParentAthleteListQuery = `
query allAthleteparents($obj: JSON){
    allAthleteparents(where: $obj){
      Athleteparents{
        athleteparentmappingmappingParentIdMap(where : { active: 1,isaccessallowed:1}) {
          Athleteparentmappings {
            parentid
            athleteid
            athleteparentmappingathleteidmaprel {
              Athletes {
                athleteemailid
                athletefirstname
                athletelastname
                id
                athletesports
                athleteemailid
                athleteuseridmaprel{
                  Userdata{
                    issoftdeleted
                    active
                  }
                }      
              }
            }
          }
        }
      }
    }
  } 
  `;
export const getViewAthQuery = `
query allUserprofileviews($obj: JSON){
     allUserprofileviews(where: $obj){
     totalCount
     Userprofileviews{
      id
    }
  }
}
`;

export const saveSignedCommitment = `
mutation($obj:SignedcommitmentInput!){
   saveSignedcommitment(obj:$obj){
      id
    }
  }
`;

export const getMySignedCommitmentQuery = `
  query allSignedcommitments($obj: JSON, $first : Int, $last : Int){
    allSignedcommitments(where: $obj, first : $first, last : $last){
      totalCount
      Signedcommitments{
        id
        active
        collegeid
        commitmenttype
        commitmentdate
        signedcommitmentuseridmaprel{
          Athletes{
            id
            athletefirstname
            athletelastname
            athleteSports
            verifieduser
            athleteathletesportsmaprel{
              Athletesports{
                sportname
              }
            }
            athletegraduationyear
            athleteGraduationYear
            athleteathletegraduationyearmaprel{
              Graduationyears{
                yearvalue
              }
            }
            onBoardingUserIdMap{
              Onboardings{
                profileimages
              }
            }
          }
        }
        signedcommitmentcommitmenttypemaprel{
          Signedcommitmenttypes{
            name
            id
          }
        }
      signedcommitmentcollegeidmaprel{
        Colleges{
          id
          collegelogo
          collegename
          city
          state
          userCollegeCollegeIdMap{
            Usercolleges{
              isfav
            }
          }
        }
      }       
    }
  }
}
`;
