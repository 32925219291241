import React from "react";
import "../assets/css/resources.scss";
import ResourcesNavbar from "./navbar/ResourcesNavbar";
// import "../assets/css/owl.carousel.min.css";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";

const ResourcesContainer = (props) => {
  return (
    <div className="resources-main-sec">
      <div className="container">
        <ResourcesNavbar />
        {props.children}
      </div>
    </div>
  );
};

export default ResourcesContainer;
