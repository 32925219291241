import {Link, useHistory} from "react-router-dom";
import {imageDownloadUrl} from "../../../../../../config";
import TeamLogo from "../../../../../../assets/img/TeamLogo.png";
import {useEffect, useState} from "react";
import {fetchGraphMethod} from "../../../../../../services/httpService";
import {getMySignedCommitmentQuery} from "./query";
import {getUserInfo} from "../../../../../../services/authService";
import {saveViewCollegeQuery} from "../../colleges/searchColleges/SearchCollegesQuery";

export const MySignedCommitment = () =>{
    const [signedCommit, setSignedCommit] = useState([])
    const history = useHistory()
    useEffect(()=>{
        getSignedCommit()
    },[])
    const getSignedCommit = async () =>{
        const res =  await fetchGraphMethod(
            getMySignedCommitmentQuery,
            {
                obj: {
                    userid: getUserInfo() ? getUserInfo().id : 0,
                    active: 1,
                },
            },
            true
        )
        if (res?.status == 200){
            if (res?.data?.data?.allSignedcommitments?.Signedcommitments?.length > 0) {
                setSignedCommit(
                    res?.data?.data?.allSignedcommitments?.Signedcommitments
                );
            } else {
                setSignedCommit([]);
            }
        }
    }
    const handleCollegeClick = async (collgId) => {
        const res = await fetchGraphMethod(
            saveViewCollegeQuery,
            {variables : {
                    obj : {
                        athleteid : getUserInfo() ? getUserInfo().id : null,
                        collegeid : collgId,
                        collegeviewstatus : 1,
                    }
                }
            },
            true
        )
    }
    return(
        <div>
            <div className="commitment">
                <div className="head">
                    <h5 className="title">Signed Commitment</h5>
                    <Link className="editLink" to="/viewCommitment">
                        {signedCommit && signedCommit.length > 0 ? "Edit" : "Add"}
                    </Link>
                </div>
                <ul>
                    {signedCommit && signedCommit.length > 0 ? (
                        signedCommit.map((item, index) => (
                            <li key={index}>
                                {console.log("college logo ==>>", item)}
                                <div className="imgBox">
                                    <img src={
                                        !!item?.signedcommitmentcollegeidmaprel?.Colleges?.[0]?.collegelogo ?
                                            `${imageDownloadUrl}/${item?.signedcommitmentcollegeidmaprel?.Colleges?.[0]?.collegelogo}`
                                            : TeamLogo} />
                                </div>
                                <div className="info">
                                    <Link
                                        to={`/colleges/details/${item?.collegeid}/${
                                            item?.signedcommitmentcollegeidmaprel?.Colleges?.[0]?.
                                                userCollegeCollegeIdMap?.Usercolleges?.[0]?.
                                                isfav ?? 0
                                        }`}
                                        onClick={() => handleCollegeClick(item?.collegeid)}
                                    >
                                        {!!item?.signedcommitmentcollegeidmaprel?.Colleges?.[0]?.collegename &&
                                        <h5 className="title">
                                            {!!item?.signedcommitmentcollegeidmaprel?.Colleges?.[0]?.collegename ?
                                                item?.signedcommitmentcollegeidmaprel?.Colleges?.[0].collegename : ""}
                                        </h5>
                                        }
                                    </Link>
                                    <span>
                                        {!!item.signedcommitmentcommitmenttypemaprel.Signedcommitmenttypes?.[0]?.name &&
                                        item.signedcommitmentcommitmenttypemaprel.Signedcommitmenttypes?.[0]?.name}
                  </span>
                                </div>
                            </li>
                        ))
                    ) : (
                        <div
                            style={{
                                textAlign: "left",
                                fontSize: "14px",
                                padding: "0px 6px",
                            }}
                        >
                            No Signed commitment is made yet
                        </div>
                    )}
                </ul>
            </div>
        </div>
    )
}
