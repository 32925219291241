import React, {useState, useEffect} from "react";
import { getUserInfo } from "../../../../../../services/authService";
import { fetchGraphMethod, fetchMethod } from "../../../../../../services/httpService";
import BackdropLoader from "../../../../../common/Loader";
import AccountSettingLeftNavigation from "./AccountSettingLeftNavigation";
import { saveCoachNotificationSettingQuery } from "./NotificationSettingCoachQuery";
import "./assets/css/CoachProfileNotification.scss";

function NotificationSettingsCoach() {
    const [notificationLabelData, setNotificationLabelData] = useState();
    const [isCheckedValue, setIsCheckedValue] = useState({
        asItHappens: 0,
        weekly : 0,
        never : 0
    });
    const [notificationMasterID, setNotificationMasterID] = useState();
    const [toggleStatus, setToggleStatus] = useState(1);
    const [loading, setLoading] = useState(false);
    useEffect(()=> {
    getNotificationSettingValue();
    }, [])


const getNotificationStatus = async (status) => {
    const variables = {
        notificationsettingId : notificationMasterID && notificationMasterID.coachnotificationsettingmasterid ? +notificationMasterID.coachnotificationsettingmasterid : 0,
        notificationsettingStatus : status == true ? 1 : 0,
        coachid : getUserInfo() ? getUserInfo().id : null,
    }
    await fetchMethod(
        "/api/Coachnotificationsettings/updateNotificationStatus",
        variables,
        "post",
        true
    )
    await getNotificationSettingValue();
    setLoading(false);

}
const handleToggleOnOff = (e) => {
    setLoading(true);
if(e.target.checked){
    setToggleStatus(1);
    getNotificationStatus(true);
}else{
    setToggleStatus(0);
    getNotificationStatus(false);
}
}

const updateNotificationSettingsValue = async (items) => {
    const variables = {
        obj : {
            id : items.id,
            coachid : getUserInfo() ? getUserInfo().id : null,
            asithappens : isCheckedValue.asItHappens,
            weekly : isCheckedValue.weekly,
            never : isCheckedValue.never
        }
    }
    await fetchGraphMethod(
        saveCoachNotificationSettingQuery,
        variables,
        true
    )
    await getNotificationSettingValue();
    setLoading(false);
}

  const handleAsItHappensChange = (e, items) => {
    setLoading(true);
      const fieldsT = isCheckedValue;
      if(e.target.checked){
          fieldsT["asItHappens"] = 1;
          fieldsT["weekly"] = 0;
          fieldsT["never"] = 0;
      }else{
          fieldsT["asItHappens"] = 0;
      }
      setIsCheckedValue({
          ...fieldsT
      })
      updateNotificationSettingsValue(items)
  }

  const handleWeeklyChange = (e, items) => {
    setLoading(true);
  const fieldsT = isCheckedValue;

    if(e.target.checked){
        fieldsT["weekly"] = 1;
        fieldsT["asItHappens"] = 0;
        fieldsT["never"] = 0;
    }else{
        fieldsT["weekly"] = 0;
    }
    setIsCheckedValue({
        ...fieldsT
    })
    updateNotificationSettingsValue(items)
    }

    const handleNeverChange = (e, items) => {
        setLoading(true);
        const fieldsT = isCheckedValue;
          if(e.target.checked){
              fieldsT["never"] = 1;
              fieldsT["weekly"] = 0;
              fieldsT["asItHappens"] = 0;

          }else{
              fieldsT["never"] = 0;
          }
          setIsCheckedValue({
              ...fieldsT
          })
          updateNotificationSettingsValue(items)
    }

 const getNotificationSettingValue =async () => {
     const variables = {
             coachid : getUserInfo() ? getUserInfo().id : null
     }
     const settingValue = await fetchMethod(
         "/api/Coachnotificationsettings/notificationSettingValue",
         variables,
         "post",
         true
     )
     const settingValueArray = settingValue?.data?.response?.data;
     
     const notificationsLabel =  settingValueArray && settingValueArray.reduce(
         function(r, a) {
            r[a.mainLable] = r[a.mainLable] || [];
            r[a.mainLable].push(a);
            return r;
        },
        {}
     )
     const finalList = Object.keys(notificationsLabel).map(function (key) {
        return { mainLable: key, subList: notificationsLabel[key] };
      });
      const filteredArray = finalList && finalList.filter((list)=> {
          return list.mainLable !== "null" 
      })
      setNotificationLabelData(filteredArray);
      const notificationSettingMasterId =  finalList && finalList.filter((list)=> {
        return list.mainLable == "null" 
    })
   const masterId = notificationSettingMasterId && notificationSettingMasterId[0] && notificationSettingMasterId[0].subList && notificationSettingMasterId[0].subList[0] && notificationSettingMasterId[0].subList[0];
   setToggleStatus(masterId.notificationstatus);
   setNotificationMasterID(masterId); 
}
  return (
      <div>
    <BackdropLoader open={loading}/>
    <section className="account-main-sec">
      <div className="container">
        <div className="account-with-sidebar">
          <AccountSettingLeftNavigation />
          <div className="pageContent">
            <div className="coach-notification-setting">
              <div className="container">
                <div className="notification-wrap">
                  <div className="title-box">
                    <div className="item">
                      <h1 className="title">Notification</h1>
                    </div>
                    <div className="item form-check form-switch">
                        <span>OFF</span>
                      <input
                        className="form-check-input"
                         checked={toggleStatus == 1 ? true : false}
                        onChange={(e)=>handleToggleOnOff(e)}
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                      />
                       <span>ON</span>
                    </div>
                  </div>
                  <div className="notification-list-row">
                      {notificationLabelData && notificationLabelData.map((label, index)=> {
                          return (
                    <div className="notification-item">
                      <h4>
                          {label.mainLable}
                          </h4>
                              {label.subList && label.subList.map((sublist)=> {
                                  return (
                      <ul className="flexbox-row">
                        <li>
                            {sublist.fieldname}
                            </li>
                        <li>
                          <div className="radioflexBox">
                            <div className="option">
                              <input checked = {sublist.asithappens == 1 ? true : false} disabled={toggleStatus == 0} id="notification1" onChange={(e)=>handleAsItHappensChange(e, sublist)}  type="radio" />
                              <label for="notification1">As it happens</label>
                            </div>
                            <div className="option">
                              <input checked = {sublist.weekly == 1 ? true : false}  disabled={toggleStatus == 0} id="notification2" type="radio" onChange={(e)=>handleWeeklyChange(e, sublist)} />
                              <label for="notification2">Weekly</label>
                            </div>
                            <div className="option">
                              <input checked = {sublist.never == 1 ? true : false}  disabled={toggleStatus == 0} id="notification3" type="radio" onChange={(e)=>handleNeverChange(e, sublist)} />
                              <label for="notification3">Never</label>
                            </div>
                          </div>
                        </li>
                      </ul>
                    )})}
                    </div>    
                    )})} 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}

export default NotificationSettingsCoach;
