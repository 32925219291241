export const getCollegesListQuery = `
  query allUsercolleges($obj: JSON,$first: Int,$last: Int){
    allUsercolleges(where: $obj, first:$first, last:$last){
      totalCount
        Usercolleges{
            id
            userid
            updatedon
            active
            categoryid
            collegeid
            collegerank
            target
            istag
            contact
            interest
            collegeinterestlevel
            collegeactive
            offer
            commit
            signed
            isfav
            updatedon
            usercollegecollegeidmaprel{
              Colleges{
                active
                collegestrength
                academicsselectivity
                conferenceid
                collegeconferenceidmaprel{
                  Collegeconferences{
                    id
                    conferencenmae
                  }
                }
                collegeacademicsselectivitymaprel{
                  Acadamicselectivities{
                    id
                    name
                  }
                }
                campuslife
                city
                collegefllower
                collegeinfo
                collegelevel
                collegedevisionid
                collegename
                collegesetting
                collegestrength
                collegetutioncost
                collegetutioninsidecost
                collegetutionoutsidecost
                collegetype
                collegelogo
                id
                locationaaddress
                collegelat
                collegelng
                state
                collegestateidmaprel{
                  States{
                    id
                    statename
                    initials
                  }
                }
                collegecollegedevisionidmaprel{
                  Collegedivisions{
                    collegedivisionname
                  }
                }
              }
            }
      }
    }
  } 
  `;

export const followRecruitCount = `
query allFollowers($where: JSON){
  allFollowers: allFollowers(where:$where){
   totalCount
 }
 }
`;

export const getMessageRecievedQuery = `
query allMessagereplies($where:JSON, $first : Int, $last : Int){
  allMessagereplies(where:$where, first : $first, last : $last){
    totalCount
    Messagereplies{
      active
      id
      msgreceiverid
      collegeid
      updatedon
      msgreceivercoachid
    }
  }
}`;

export const getVideoViewsQuery = `
query allPhotovideoviews($where : JSON){
  allPhotovideoviews(where : $where){
    Photovideoviews{
      id
      collegeid
      videoview
    }
  }
}
`;
export const getCollegesCountsQuery = (userId) => `
  query {
    allTotalCollegeCount: allUsercolleges(where: {userid:${userId}, active: 1,isfav:1}) {
      totalCount
    }
    allConnectedCollegeCount: allUsercolleges(where: {contact: 1, userid: ${userId}, active: 1,isfav:1}) {
      totalCount
    }
    allInterestedCollegeCount: allUsercolleges(where: {interest: 1, userid: ${userId}, active: 1,isfav:1}) {
      totalCount
    }
    alluserActiveCollegeCount: allUsercolleges(where: {collegeactive: 1, userid: ${userId}, active: 1 ,isfav:1}) {
      totalCount
    }
    alloffersCollegeCount: allUsercolleges(where: {offer: 1, userid: ${userId}, active: 1 ,isfav:1}) {
      totalCount
    }
    allCommitCollegeCount: allUsercolleges(where: {commit: 1, userid: ${userId},active: 1 ,isfav:1}) {
      totalCount
    }
    allCollegecoachCount: allCollegecoachactivities(where: {viewathleteid: ${userId}, active: 1}){
      totalCount
    }
    allUserprofileviewsCount: allUserprofileviews(where: {active: 1, profileviewto: ${userId}}){
      totalCount
    }
    allSearchCount: allSearchbycolleges(where:{active: 1, athleteid: ${userId}}){
      totalCount
    }
    groupCollegeCoach: allCoachcollegeinterests(where:{active: 1, athleteid: ${userId}}){
      totalCount
    }
  } 
  `;

export const getCategoryListQuery = `
  query allCategories{
    allCategories(where: {active: 1,order:"id desc"}){
      Categories{
        id
        categoryname   
      }
    }
  } 
  `;

export const saveUserCollegeQuery = `
  mutation saveUsercollege($obj: UsercollegeInput!){
    saveUsercollege(obj: $obj){
        id
        userid
    }
  } 
  `;

export const saveCollegepopupQuery = `
    mutation saveCollegepopup($obj: CollegepopupInput!){
      saveCollegepopup(obj: $obj){
          id
      }
    } 
  `;

export const getCollegepopupQuery = `
    query allCollegepopups($where : JSON){
      allCollegepopups(where : $where){
        Collegepopups{
          id
          status
        }
      }
    }
  `;
