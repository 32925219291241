import { all, fork } from "redux-saga/effects";
import { watchCardTypeListSaga } from "./CardTypeList";
import { watchSaveToMembershipSaga } from "./saveToMembership";
import { watchUpgradePlansSaga } from "./upgradePlans";
import { watchFeaturesSaga } from "./featuresList";

export default function* rootUpgradePlansSagas() {
  yield all([
    fork(watchUpgradePlansSaga),
    fork(watchCardTypeListSaga),
    fork(watchSaveToMembershipSaga),
    fork(watchFeaturesSaga),
  ]);
}
