import { put, takeEvery } from "redux-saga/effects";

import { fetchGraphMethod } from "../../../services/httpService";
import messagePopup from "../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  ATHLETE_PARENT_MAPPING_START: "ATHLETE_PARENT_MAPPING_START",
  ATHLETE_PARENT_MAPPING_SUCCESS: "ATHLETE_PARENT_MAPPING_SUCCESS",
  ATHLETE_PARENT_MAPPING_FAILED: "ATHLETE_PARENT_MAPPING_FAILED",
  ATHLETE_PARENT_MAPPING_SAVE_START: "ATHLETE_PARENT_MAPPING_SAVE_START",
  ATHLETE_PARENT_MAPPING_SAVE_SUCCESS: "ATHLETE_PARENT_MAPPING_SAVE_SUCCESS",
  ATHLETE_PARENT_MAPPING_SAVE_FAILED: "ATHLETE_PARENT_MAPPING_SAVE_FAILED",
};

// ============================================ Actions ========================================================
export const athleteParentMappingStart = (data) => ({
  type: actionType.ATHLETE_PARENT_MAPPING_START,
  payload: data,
});
export const athleteParentMappingSuccess = (data) => ({
  type: actionType.ATHLETE_PARENT_MAPPING_SUCCESS,
  payload: data,
});

export const athleteParentMappingFailed = (data) => ({
  type: actionType.ATHLETE_PARENT_MAPPING_FAILED,
  payload: data,
});

export const athleteParentMappingSaveStart = (data) => ({
  type: actionType.ATHLETE_PARENT_MAPPING_SAVE_START,
  payload: data,
});
export const athleteParentMappingSaveSuccess = (data) => ({
  type: actionType.ATHLETE_PARENT_MAPPING_SAVE_SUCCESS,
  payload: data,
});

export const athleteParentMappingSaveFailed = (data) => ({
  type: actionType.ATHLETE_PARENT_MAPPING_SAVE_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function athleteParentMappingReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.ATHLETE_PARENT_MAPPING_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ATHLETE_PARENT_MAPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.ATHLETE_PARENT_MAPPING_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case actionType.ATHLETE_PARENT_MAPPING_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionType.ATHLETE_PARENT_MAPPING_SAVE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionType.ATHLETE_PARENT_MAPPING_SAVE_START:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================
export function* athleteParentMappingSaveSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        athleteParentMappingSaveSuccess({
          saveAthleteParentMapping: response.data.data,
        })
      );
      messagePopup("", "Athlete Parent Save Successfull", "success");
    } else {
      messagePopup("", "Athlete Parent Save failed", "error");
      yield put(athleteParentMappingSaveFailed("Athlete Parent Save failed"));
    }
    if (response && response.status === 201) {
      messagePopup("", response.message, "warning");
    }
  } catch (error) {
    messagePopup("", "Athlete Parent Save failed", "error");
    yield put(athleteParentMappingSaveFailed("Athlete Parent Save failed"));
  }
}

export function* athleteParentMappingListSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      let athleteParent = [];
      response.data.data.allAthleteparentmappings.Athleteparentmappings.map(
        (parentData) => {
          if (
            parentData.athleteparentmappingmappingparentidmaprel &&
            parentData.athleteparentmappingmappingparentidmaprel
              .Athleteparents &&
            parentData.athleteparentmappingmappingparentidmaprel
              .Athleteparents[0]
          ) {
            athleteParent.push({
              ...parentData.athleteparentmappingmappingparentidmaprel
                .Athleteparents[0],
              isActive: parentData.active,
              relationshipWithAthlete: parentData.relationshipid,
              athleteparentmappingrelationshipidmaprel:parentData.athleteparentmappingrelationshipidmaprel
            });
          }
        }
      );


      yield put(
        athleteParentMappingSuccess({
          parentData: athleteParent,
        })
      );
    } else {
      messagePopup("", "Athlete Parent List failed", "error");
      yield put(athleteParentMappingFailed("Athlete Parent List failed"));
    }
  } catch (error) {
    messagePopup("", "Athlete Parent List failed", "error");
    yield put(athleteParentMappingFailed("Athlete Parent List failed"));
  }
}

//Watcher
export function* watchathleteParentMappingSaga() {
  yield takeEvery(
    actionType.ATHLETE_PARENT_MAPPING_START,
    athleteParentMappingListSaga
  );
  yield takeEvery(
    actionType.ATHLETE_PARENT_MAPPING_SAVE_START,
    athleteParentMappingSaveSaga
  );
}
