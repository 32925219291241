import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod, fetchMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
    ALL_PRESS_LIST_START: "ALL_PRESS_LIST_START",
    ALL_PRESS_LIST_SUCCESS: "ALL_PRESS_LIST_SUCCESS",
    ALL_PRESS_LIST_FAILED: "ALL_PRESS_LIST_FAILED",
};

// ============================================ Actions ========================================================
export const allPressListStart = (data) => ({
    type: actionType.ALL_PRESS_LIST_START,
    payload: data,
});

export const allPressListSuccess = (data) => ({
    type: actionType.ALL_PRESS_LIST_SUCCESS,
    payload: data,
});

export const allPressListFailed = (data) => ({
    type: actionType.ALL_PRESS_LIST_FAILED,
    payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
};

export default function allPressListReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actionType.ALL_PRESS_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case actionType.ALL_PRESS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case actionType.ALL_PRESS_LIST_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return { ...state };
    }
}

// ===================================================== side effect =============================================

export function* allPressListSaga(action) {    
    try {
        const response = yield fetchGraphMethod(
            action.payload.query,
            action.payload.variables,
            true
        );       
        if (response && response.status === 200) {
            yield put(allPressListSuccess({ parentData: response.data.data.allPresses.Presses }));
        } else {
            messagePopup("", "Press List failed", "error");
            yield put(allPressListFailed("Press List failed"));
        }
    } catch (error) {
        messagePopup("", "Press failed", "error");
        yield put(allPressListFailed("Press List failed"));
    }
}

export function* watchallPressListSaga() {
    yield takeEvery(actionType.ALL_PRESS_LIST_START, allPressListSaga);
}
