import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { imageDownloadUrl } from '../../../config';
import coachTestimonialData from './coachTestimonialData';

const carouselOptions = {
  loop: true,
  nav: true,
  dots: false,
  autoplay: true,
  autoHeight: true,
  margin: 30,
  responsive: {
    0: {
      items: 1,
      margin: 0,
    },
    768: {
      items: 2,
    },
    992: {
      items: 3,
    },
  },
};

const CoachTestimonials = ({ whiteBackground }) => {
  return (
    <section className={whiteBackground ? 'about-coaches-sec bg-white' : 'about-coaches-sec'}>
      <div className="container">
        <div className="sec-title">Why College Coaches Trust Us...</div>
        <OwlCarousel className="coaches-list coaches-slider owl-carousel owl-theme" {...carouselOptions}>
          {coachTestimonialData.map((testimonial, index) => (
            <div className="item" key={index}>
              <div className="icon-box">
                <img src={`${imageDownloadUrl}/${testimonial.conferenceLogo}`} alt="Conference Logo" />
              </div>
              <div className="short-text">
                <p>{testimonial.text}</p>
              </div>
              <h4>{testimonial.coachPositionText}</h4>
              <h5>{testimonial.coachConferenceText}</h5>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default CoachTestimonials;
