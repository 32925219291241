import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  SAVE_VIDEO_PHOTO_START: "SAVE_VIDEO_PHOTO_START",
  UPDATE_VISIBLE_STATUS_START: "UPDATE_VISIBLE_STATUS_START",
  SAVE_VIDEO_PHOTO_SUCCESS: "SAVE_VIDEO_PHOTO_SUCCESS",
  SAVE_VIDEO_PHOTO_FAILED: "SAVE_VIDEO_PHOTO_FAILED",
};

// ============================================ Actions ========================================================
export const updateVideoPhotoInfoStart = (data) => ({
  type: actionType.SAVE_VIDEO_PHOTO_START,
  payload: data,
});

export const updateVisibleStatusStart = (data) => ({
  type: actionType.UPDATE_VISIBLE_STATUS_START,
  payload: data,
});

export const updateVideoPhotoInfoSuccess = (data) => ({
  type: actionType.SAVE_VIDEO_PHOTO_SUCCESS,
  payload: data,
});

export const updateVideoPhotoInfoFailed = (data) => ({
  type: actionType.SAVE_VIDEO_PHOTO_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function updateVideoPhotoInfoReducer(
  state = INIT_STATE,
  action
) {
  switch (action.type) {
    case actionType.UPDATE_VISIBLE_STATUS_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SAVE_VIDEO_PHOTO_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.SAVE_VIDEO_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.SAVE_VIDEO_PHOTO_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* updateVideoPhotoInfoSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      if (response.data.data.saveUploadedvideoandphoto.id) {
        if (action.payload.successMsg) {
          messagePopup("", action.payload.successMsg, "success");
        }
        if (action?.payload?.isVidPhotoShare){
          action?.payload?.isSharePop()
        }
        yield put(updateVideoPhotoInfoSuccess("Data updated successfully"));
        if (action.payload.refreshPage) action.payload.refreshPage();
      } else {
        messagePopup("", "Data update successfully failed", "error");
        yield put(
          updateVideoPhotoInfoFailed("Data update successfully failed")
        );
      }
    } else {
      messagePopup("", "Data update successfully failed", "error");
      yield put(updateVideoPhotoInfoFailed("Data update successfully failed"));
    }
  } catch (error) {
    messagePopup("", "Data update successfully failed", "error");
    yield put(updateVideoPhotoInfoFailed("Data update successfully failed"));
  }
}

export function* updateVisibleStatusSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query.updateVideoPhoto,
      action.payload.variables.updateVideoPhoto,
      true
    );
    if (response && response.status === 200) {
      if (response.data.data.saveUploadedvideoandphoto.id) {
        const response1 = yield fetchGraphMethod(
          action.payload.query.userActivity,
          action.payload.variables.userActivity,
          true
        );
        if (response1 && response1.status === 200) {
          if (response1.data.data.saveUseracivity.id) {
            if (action.payload.successMsg) {
              messagePopup("", action.payload.successMsg, "success");
            }
            yield put(updateVideoPhotoInfoSuccess("Data updated successfully"));
            action.payload.refreshPage();
          } else {
            messagePopup("", "Data update failed", "error");
            yield put(updateVideoPhotoInfoFailed("Data update failed"));
          }
        } else {
          messagePopup("", "Data update failed", "error");
          yield put(updateVideoPhotoInfoFailed("Data update failed"));
        }
      } else {
        messagePopup("", "Data update failed", "error");
        yield put(updateVideoPhotoInfoFailed("Data update failed"));
      }
    } else {
      messagePopup("", "Data update failed", "error");
      yield put(updateVideoPhotoInfoFailed("Data update failed"));
    }
  } catch (error) {
    messagePopup("", "Data update failed", "error");
    yield put(updateVideoPhotoInfoFailed("Data update failed"));
  }
}

export function* watchupdateVideoPhotoInfoSaga() {
  yield takeEvery(actionType.SAVE_VIDEO_PHOTO_START, updateVideoPhotoInfoSaga);
  yield takeEvery(
    actionType.UPDATE_VISIBLE_STATUS_START,
    updateVisibleStatusSaga
  );
}
