import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { userCollegeCardCountStart } from "../../../../../../redux/athlete/dashboard/colleges/userCollegeCardCount";
import { allVideoPhotoListStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/getVideoPhotoInfo";
import { allCheckListDataStart } from "../../../../../../redux/common/allCheckListData";
import { allProfileCalculationListStart } from "../../../../../../redux/common/allProfileCalculationMaster";
import { athletesDetailsStart } from "../../../../../../redux/common/athletesDetails";
import { getUserInfo } from "../../../../../../services/authService";
import { checkListPercentage } from "../../../../../../utils/checkListPercentage";
import {
  getAllAthletesDataQuery,
  getAllCheckListDataQuery,
  getProfileCalculationListQuery,
} from "../../../../../common/CommonQuery";
import { getAllVideoPhotoInfoQuery } from "../../editUserProfile/videoPhoto/VideoPhotoQuery";

const MyProfile = (props) => {
  const { checkListDataPrepare, getFinalPercentage } = checkListPercentage(
    props.allProfileCalculationData.data
  );
  const [passedLink, setPassedLink] = useState({});
  const history = useHistory();
  const [data, setData] = useState({
    finalItems: {},
    finalPercentage: 0,
  });
  const [checkListCount, setCheckListCount] = useState(0);
  const getAllCheckListData = () => {
    if (!props.allProfileCalculationData.data) {
      props.profileCalculationListStart({
        query: getProfileCalculationListQuery,
      });
    }

    props.checkListDataStart({
      query: getAllCheckListDataQuery,
      variables: {
        obj: {
          id: getUserInfo().id,
        },
      },
    });
  };

  useEffect(() => {
    let handler = setTimeout(() => {
      if (
        props.checkListData &&
        props.checkListData.data &&
        props.checkListData.data.allAthletes &&
        props.checkListData.data.allAthletes.Athletes &&
        props.checkListData.data.allAthletes.Athletes[0]
      ) {
        let athleteData = props.checkListData.data.allAthletes.Athletes[0];
        let checkListDataT = checkListDataPrepare(athleteData);

        let finalPercentage = getFinalPercentage(checkListDataT);

        let athleteParentData = athleteData.athleteparentmappingAthleteIdmap
          .Athleteparentmappings.length
          ? athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings
          : [];
        let vCount = 0;
        let rCount = 0;
        let athleteOnboardingData = [];
        let checklistProfileCount = 0;
        Object.entries(checkListDataT).forEach((item) => {
          if (!item[1]["completed"]) {
            checklistProfileCount++;
          }
        });

        // if(!athleteData.verifieduser){
        //   vCount++
        // }
        if (!athleteData.athletephonenumber) {
          rCount++;
        }
        if (
          athleteData.onBoardingUserIdMap &&
          athleteData.onBoardingUserIdMap.Onboardings &&
          athleteData.onBoardingUserIdMap.Onboardings[0]
        ) {
          let onboardingData = athleteData.onBoardingUserIdMap.Onboardings[0];
          athleteOnboardingData =
            athleteData.onBoardingUserIdMap.Onboardings[0];
          if (!onboardingData.profileimages) {
            vCount++;
          }
          if (!onboardingData.primaryposition) {
            vCount++;
          }
          if (!onboardingData.gpascale) {
            rCount++;
          }
          if (!onboardingData.heightinfeet) {
            rCount++;
          }
          if (!onboardingData.heightInInches) {
            rCount++;
          }
          if (!onboardingData.weight) {
            rCount++;
          }
        }

        if (
          athleteData.athleteparentmappingAthleteIdmap &&
          athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings &&
          athleteData.athleteparentmappingAthleteIdmap
            .Athleteparentmappings[0] &&
          athleteData.athleteparentmappingAthleteIdmap.Athleteparentmappings[0]
            .id
        ) {
          if (athleteParentData.length) {
            if (
              !athleteParentData[0]?.athleteparentmappingmappingparentidmaprel
                ?.Athleteparents[0]?.parentphonenumber
            ) {
              vCount++;
              rCount++;
            }
          }
        }

        if (
          athleteData.athleteathletegraduationyearmaprel &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears.length
        ) {
          if (
            !athleteData.athleteathletegraduationyearmaprel.Graduationyears[0][
              "yearvalue"
            ]
          ) {
            vCount++;
          }
        }

        if (
          athleteData.athleteathletegraduationyearmaprel.Graduationyears
            .length &&
          athleteData.athleteathletegraduationyearmaprel.Graduationyears[0]
            ?.id &&
          athleteParentData.length &&
          athleteParentData[0]?.athleteparentmappingmappingparentidmaprel
            ?.Athleteparents[0]?.id &&
          athleteOnboardingData?.primaryposition &&
          athleteOnboardingData?.profileimages &&
          athleteData.verifieduser
        ) {
          setCheckListCount(parseInt(checklistProfileCount));
        } else {
          // setCheckListCount(parseInt(checklistProfileCount)+parseInt(vCount)+1)//vCount don't have any meaning
          setCheckListCount(checklistProfileCount);
        }

        setData({
          finalItems: { ...checkListDataT },
          finalPercentage: finalPercentage,
        });
      }
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [props.checkListData]);

  //USE EFFECT FOR GETTING DATA FOR PERCENTAGE CHECK
  useEffect(() => {
    getAllCheckListData();
    props.athleteListStart({
      query: getAllAthletesDataQuery,
      variables: {
        obj: {
          id: getUserInfo() ? getUserInfo().id : 0,
        },
      },
    });
    props.allVideoPhotoListStart({
      query: getAllVideoPhotoInfoQuery,
      variables: {
        obj: {
          userid: getUserInfo() ? getUserInfo().id : 0,
          active: 1,
        },
      },
    });
  }, []);
  useEffect(() => {
    if (props.athleteData.data || props.allVideoPhotoInfo.data) {
      let configData = {};
      if (props.athleteData.data) {
        const { onboardingData, athletesData } = props.athleteData.data;
        configData["contactInfo"] = athletesData
          ? athletesData.athleteemailid || athletesData.athletephonenumber
            ? true
            : false
          : false;
        configData["gpa"] = onboardingData
          ? onboardingData.gpascale
            ? true
            : onboardingData.unweightedgpa
            ? true
            : false
          : false;
      }

      configData["video"] = props.allVideoPhotoInfo.data
        ? props.allVideoPhotoInfo.data.allUploadedvideoandphotos.length
          ? true
          : false
        : false;
      setPassedLink(configData);
    }
  }, [props.athleteData.data, props.allVideoPhotoInfo.data]);

  const routeToPage = (e, route) => {
    e.preventDefault();
    history.push(route);
  };
  return (
    <div>
      <div className="head">
        <h5 className="title">My Profile</h5>
        <a
          href={void 0}
          onClick={(e) => {
            routeToPage(e, "/basic");
          }}
          className="editLink"
        >
          Edit
        </a>
      </div>
      <div className="porgress">
        <label>{`${data.finalPercentage}%`}</label>
        <div className="bar" id="pBar">
          <div
            className="barprogress"
            style={{ width: `${data.finalPercentage}%` }}
          ></div>
        </div>
      </div>
      <ul className="level">
        <li className="passed">
          <a
            href={void 0}
            className="checkList"
            onClick={(e) => {
              routeToPage(e, "/checklist");
            }}
          >
            <span className="icon">
              <div className="checkListBox">
                <div className="checkListNotify">
                  <span>
                    <svg
                      style={{ width: "22px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="35.582"
                      height="21.05"
                      viewBox="0 0 35.582 21.05"
                    >
                      <g
                        id="Group_51845"
                        data-name="Group 51845"
                        transform="translate(-1004 -289)"
                      >
                        <path
                          fill="#1CB79E"
                          id="Path_2196"
                          data-name="Path 2196"
                          d="M6,9A1.5,1.5,0,0,1,7.5,7.5h21a1.5,1.5,0,0,1,0,3H7.5A1.5,1.5,0,0,1,6,9Z"
                          transform="translate(998 281.5)"
                        />
                        <path
                          fill="#1CB79E"
                          id="Path_2197"
                          data-name="Path 2197"
                          d="M6,27a1.5,1.5,0,0,1,1.5-1.5h21a1.5,1.5,0,0,1,0,3H7.5A1.5,1.5,0,0,1,6,27Z"
                          transform="translate(998 272.5)"
                        />
                        <path
                          fill="#1CB79E"
                          id="Path_2202"
                          data-name="Path 2202"
                          d="M7.5,16.5a1.5,1.5,0,0,0,0,3h12a1.5,1.5,0,0,0,0-3Z"
                          transform="translate(998 290.5)"
                        />
                        <path
                          fill="#1CB79E"
                          id="Path_2201"
                          data-name="Path 2201"
                          d="M24.75,12.375,15.3,23.625l-4.05-4.5"
                          transform="translate(1013 285.125)"
                          stroke="#1CB79E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2.6"
                        />
                      </g>
                    </svg>
                    {/*<img src={NotificationIcon} alt="" />*/}
                    {/*<em style={{background: "white", borderRadius: 0, position: "absolute", top:"-5px", left:"10px"}}>3</em>*/}
                  </span>
                  <span
                    className="count"
                    style={{
                      top: "-3px",
                    }}
                  >
                    {checkListCount}
                  </span>
                </div>
              </div>
              {/*<svg*/}
              {/*  xmlns="http://www.w3.org/2000/svg"*/}
              {/*  width="16.76"*/}
              {/*  height="18.252"*/}
              {/*  viewBox="0 0 16.76 18.252"*/}
              {/*>*/}
              {/*  <defs>*/}
              {/*    <style></style>*/}
              {/*  </defs>*/}
              {/*  <ellipse*/}
              {/*    className="a"*/}
              {/*    cx="2.05"*/}
              {/*    cy="2.05"*/}
              {/*    rx="2.05"*/}
              {/*    ry="2.05"*/}
              {/*    transform="translate(6.33 14.151)"*/}
              {/*  />*/}
              {/*  <path*/}
              {/*    className="a"*/}
              {/*    d="M200.781,396.975a2.05,2.05,0,0,0-2.041,1.859l2.232,2.232a2.05,2.05,0,0,0-.191-4.091Z"*/}
              {/*    transform="translate(-192.401 -382.823)"*/}
              {/*  />*/}
              {/*  <path*/}
              {/*    className="a"*/}
              {/*    d="M36.019,354.192H22.583a1.662,1.662,0,0,0,0,3.323H36.019a1.662,1.662,0,1,0,0-3.323Z"*/}
              {/*    transform="translate(-20.921 -341.566)"*/}
              {/*  />*/}
              {/*  <path*/}
              {/*    className="a"*/}
              {/*    d="M70.718,11.373V8.042a5.758,5.758,0,0,0-4.349-5.583,1.633,1.633,0,1,0-2.815,0,5.758,5.758,0,0,0-4.349,5.583v3.331A1.306,1.306,0,0,1,57.9,12.678H72.024A1.305,1.305,0,0,1,70.718,11.373ZM64.962,2.285a.653.653,0,1,1,.653-.653A.653.653,0,0,1,64.962,2.285Z"*/}
              {/*    transform="translate(-56.582)"*/}
              {/*  />*/}
              {/*  <path*/}
              {/*    className="a"*/}
              {/*    d="M21.5,410.067a1.536,1.536,0,0,0,1.641,1.325H36.578a1.536,1.536,0,0,0,1.641-1.325Z"*/}
              {/*    transform="translate(-21.479 -395.449)"*/}
              {/*  />*/}
              {/*  <path*/}
              {/*    className="a"*/}
              {/*    d="M348.326,234.858v1.92l-2.556-2.556h.009a.571.571,0,0,1,.366.111,2.932,2.932,0,0,0,1.729.56A2.989,2.989,0,0,0,348.326,234.858Z"*/}
              {/*    transform="translate(-334.19 -225.871)"*/}
              {/*  />*/}
              {/*</svg>*/}
            </span>
            <span className="label">Checklist</span>
          </a>
        </li>
        {/*//earlier in false condition there "failed" class which not present in the project that it creating a bug*/}
        <li
          className={!!passedLink["gpa"] ? "passed" : "failed"}
          style={{ color: "none" }}
        >
          <a
            href={void 0}
            className="Gpa"
            onClick={(e) => {
              routeToPage(e, "/basic");
            }}
          >
            {passedLink["gpa"] ? (
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.022"
                  height="17.779"
                  viewBox="0 0 18.022 17.779"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <g transform="translate(-2.039 -2.071)">
                    <path
                      className="a"
                      d="M19,10.26V11a8,8,0,1,1-4.744-7.312"
                      transform="translate(0 0)"
                      fill="#1CB79E"
                    />
                    <path
                      className="a"
                      d="M23.917,6,15.9,14.021l-2.4-2.4"
                      transform="translate(-4.918 -1.402)"
                      fill="#1CB79E"
                    />
                  </g>
                </svg>
              </span>
            ) : (
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.711"
                  height="17.779"
                  viewBox="0 0 17.711 17.779"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <g transform="translate(-158.039 -590.081)">
                    <g transform="translate(156 588.009)">
                      <path
                        // className="a"
                        d="M19,10.26V11a8,8,0,1,1-4.744-7.312"
                        transform="translate(0 0)"
                        fill="#fff"
                        stroke={"#000000"}
                      />
                    </g>
                    <g transform="translate(-2.5 0.5)">
                      <line
                        // className="a"
                        y2="8"
                        transform="translate(169.5 594.5)"
                        stroke="#000000"
                      />
                      <line
                        // className="a"
                        y2="8"
                        transform="translate(173.5 598.5) rotate(90)"
                        stroke="#000000"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            )}
            <span className="label">GPA</span>
          </a>
        </li>
        <li className={passedLink["contactInfo"] ? "passed" : "failed"}>
          <a
            href={void 0}
            className="contactInfo"
            onClick={(e) => {
              routeToPage(e, "/contact");
            }}
          >
            {passedLink["contactInfo"] ? (
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.022"
                  height="17.779"
                  viewBox="0 0 18.022 17.779"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <g transform="translate(-2.039 -2.071)">
                    <path
                      className="a"
                      d="M19,10.26V11a8,8,0,1,1-4.744-7.312"
                      transform="translate(0 0)"
                    />
                    <path
                      className="a"
                      d="M23.917,6,15.9,14.021l-2.4-2.4"
                      transform="translate(-4.918 -1.402)"
                    />
                  </g>
                </svg>
              </span>
            ) : (
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.711"
                  height="17.779"
                  viewBox="0 0 17.711 17.779"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <g transform="translate(-158.039 -590.081)">
                    <g transform="translate(156 588.009)">
                      <path
                        className="a"
                        d="M19,10.26V11a8,8,0,1,1-4.744-7.312"
                        transform="translate(0 0)"
                      />
                    </g>
                    <g transform="translate(-2.5 0.5)">
                      <line
                        className="b"
                        y2="8"
                        transform="translate(169.5 594.5)"
                      />
                      <line
                        className="b"
                        y2="8"
                        transform="translate(173.5 598.5) rotate(90)"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            )}
            <span className="label">Contact Information</span>
          </a>
        </li>
        {/*//earlier in false condition there "failed" class which not present in the project that it creating a bug*/}
        <li className={passedLink["video"] ? "passed" : "failed"}>
          <a
            href={void 0}
            className="video"
            onClick={(e) => {
              routeToPage(e, "/videoPhotos");
            }}
          >
            {passedLink["video"] ? (
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.022"
                  height="17.779"
                  viewBox="0 0 18.022 17.779"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <g transform="translate(-2.039 -2.071)">
                    <path
                      // className="a"
                      d="M19,10.26V11a8,8,0,1,1-4.744-7.312"
                      transform="translate(0 0)"
                      fill="#fff"
                      stroke={"#1CB79E"}
                      strokeWidth={"1.5"}
                    />
                    <path
                      // className="a"
                      d="M23.917,6,15.9,14.021l-2.4-2.4"
                      transform="translate(-4.918 -1.402)"
                      fill="#fff"
                      stroke={"#1CB79E"}
                      strokeWidth={"1.5"}
                    />
                  </g>
                </svg>
              </span>
            ) : (
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.711"
                  height="17.779"
                  viewBox="0 0 17.711 17.779"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <g transform="translate(-158.039 -590.081)">
                    <g transform="translate(156 588.009)">
                      <path
                        // className="a"
                        d="M19,10.26V11a8,8,0,1,1-4.744-7.312"
                        transform="translate(0 0)"
                        fill="#fff"
                        stroke={"#000000"}
                      />
                    </g>
                    <g transform="translate(-2.5 0.5)">
                      <line
                        // className="a"
                        y2="8"
                        transform="translate(169.5 594.5)"
                        stroke="#000000"
                      />
                      <line
                        // className="a"
                        y2="8"
                        transform="translate(173.5 598.5) rotate(90)"
                        stroke="#000000"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            )}
            <span className="label">Video</span>
          </a>
        </li>
      </ul>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userCollegeCardCount: state.athlete.dashboard.colleges.userCollegeCardCount,

    //CheckList Percentage Data
    checkListData: state.common.checkListData,
    allProfileCalculationData: state.common.profileCalculationData,
    athleteData: state.common.athletesData,
    allVideoPhotoInfo: state.athlete.editUserProfileData.allVideoPhotoInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userCollegeCardCountStart: (data) =>
      dispatch(userCollegeCardCountStart(data)),

    //CheckList Percentage Data
    checkListDataStart: (data) => dispatch(allCheckListDataStart(data)),
    profileCalculationListStart: (data) =>
      dispatch(allProfileCalculationListStart(data)),
    athleteListStart: (data) => dispatch(athletesDetailsStart(data)),
    allVideoPhotoListStart: (data) => dispatch(allVideoPhotoListStart(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
