import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  COACH_ACT_VALUE_LIST_START: "COACH_ACT_VALUE_LIST_START",
  COACH_ACT_VALUE_SUCCESS: "COACH_ACT_VALUE_SUCCESS",
  COACH_ACT_VALUE_FAILED: "COACH_ACT_VALUE_FAILED",
};

// ============================================ Actions ========================================================
export const coachActValueListStart = (data) => ({
  type: actionType.COACH_ACT_VALUE_LIST_START,
  payload: data,
});

export const coachActValueSuccess = (data) => ({
  type: actionType.COACH_ACT_VALUE_SUCCESS,
  payload: data,
});

export const coachActValueFailed = (data) => ({
  type: actionType.COACH_ACT_VALUE_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function coachActValueReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.COACH_ACT_VALUE_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.COACH_ACT_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.COACH_ACT_VALUE_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* coachActValueListSaga(action) {
  try {
    const response = yield fetchGraphMethod(action.payload.query, null, true);
    if (response && response.status === 200) {
      const allCoachActValue = response.data.data.allCoachactranges.Coachactranges
        ? response.data.data.allCoachactranges.Coachactranges
        : null;
      yield put(coachActValueSuccess(allCoachActValue));
    } else {
      messagePopup("", "Coach Act Value List failed", "error");
      yield put(coachActValueFailed("Coach Act Value List failed"));
    }
  } catch (error) {
    messagePopup("", "Coach Act Value List failed", "error");
    yield put(coachActValueFailed("Coach Act Value List failed"));
  }
}

export function* watchCoachActValueListSaga() {
  yield takeEvery(actionType.COACH_ACT_VALUE_LIST_START, coachActValueListSaga);
}
