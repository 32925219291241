import { put, takeEvery } from "redux-saga/effects";
import { fetchGraphMethod } from "../../../../services/httpService";
import messagePopup from "../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  PREMIUM_DROPDOWN_START: "PREMIUM_DROPDOWN_START",
  PREMIUM_DROPDOWN_SUCCESS: "PREMIUM_DROPDOWN_SUCCESS",
  PREMIUM_DROPDOWN_FAILED: "PREMIUM_DROPDOWN_FAILED"
};

// ============================================ Actions ========================================================
export const premiumDropdownStart = data => ({
  type: actionType.PREMIUM_DROPDOWN_START,
  payload: data
});

export const premiumDropdownSuccess = data => ({
  type: actionType.PREMIUM_DROPDOWN_SUCCESS,
  payload: data
});

export const premiumDropdownFailed = data => ({
  type: actionType.PREMIUM_DROPDOWN_FAILED,
  payload: data
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null
};

export default function premiumDropdownReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.PREMIUM_DROPDOWN_START:
      return {
        ...state,
        loading: true
      };

    case actionType.PREMIUM_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case actionType.PREMIUM_DROPDOWN_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* premiumDropdownSaga(action) {
  try {
    const response = yield fetchGraphMethod(
      action.payload.query,
      action.payload.variables,
      true
    );
    if (response && response.status === 200) {
      yield put(
        premiumDropdownSuccess({data: response.data.data.allMemberships.Memberships})
      );
    } else {
      messagePopup("", "Premium Api failed", "error");
      yield put(premiumDropdownFailed("Premium Api failed"));
    }
  } catch (error) {
    messagePopup("", "Premium Api failed", "error");
    yield put(premiumDropdownFailed("Premium Api failed"));
  }
}

export function* watchPremiumDropdownSaga() {
  yield takeEvery(actionType.PREMIUM_DROPDOWN_START, premiumDropdownSaga);
}
