import {getUserInfo} from "../../../../../../../services/authService";
import swal from 'sweetalert'
export const updateLS = (key, value) =>{
    const LS = !!getUserInfo()
    if(LS){
        const getLS = !!getUserInfo() ? getUserInfo() : {}
        getLS[key] = value
        localStorage.setItem('user',JSON.stringify(getLS))
    }else{
        swal({text:"LocalStorage Doesn't Exist", icon:'error'})
    }
}